import { useDispatch } from "react-redux";
import ODUClient from "../../network/httpclient/odu/ODUClient";
import ODUDetails from "./ODUDetails";
import ODUWirelessInfo from "./ODUWirelessInfo";
import ReloadData from "../reusable/ReloadData";
import DiagApiCallCont from "../reusable/DiagApiCallCont";

const ODUSummaryInfo = (props: any) => {
  const {
    selectedDevice,
    oduSummaryInfo,
    oduSummaryStatus,
    oduLoading,
    oduWirelessInfo,
    oduWirelessStatus,
  } = props;
  const dispatch = useDispatch();

  const refreshData = () => {
    dispatch(
      ODUClient.getODUSummary(selectedDevice.oui, selectedDevice.serialNumber)
    );
    dispatch(
      ODUClient.getODUWirelessInfo(
        selectedDevice.oui,
        selectedDevice.serialNumber
      )
    );
  };

  return (
    <>
      <div className="refreshCont">
        <ReloadData refreshData={refreshData} />
      </div>
      <div className="cmSummaryCont">
        <DiagApiCallCont
          data={oduSummaryInfo}
          status={oduSummaryStatus}
          sectionName="ODU Summary"
          handleRefresh={() => refreshData()}
        >
          <ODUDetails selDevice={selectedDevice} deviceData={oduSummaryInfo} />
        </DiagApiCallCont>
        <DiagApiCallCont
          isLoading={oduLoading}
          data={oduWirelessInfo}
          sectionName="ODU WirelessInfo"
          status={oduWirelessStatus}
          handleRefresh={() => refreshData()}
        >
          <ODUWirelessInfo deviceData={oduWirelessInfo} />
        </DiagApiCallCont>
      </div>
    </>
  );
};

export default ODUSummaryInfo;
