import { Button } from "react-bootstrap";

const AttachedFiles = (props: any) => {
  const deleteAttachment = (index: number) => {
    props.setFile(() => {
      const temp = [...props.file];
      temp.splice(index, 1);
      return temp;
    });
  };

  return (
    <div className="attached-file-container">
      {props.file?.map((image: any, index: any) => {
        if (typeof image == "object")
          return (
            <div key={index} className="attachment">
              <a
                href={image[1]}
                download={image[0]?.name}
                className="attached-file"
              >
                {image[0]?.name}
              </a>
              <Button onClick={() => deleteAttachment(index)}>✕</Button>
            </div>
          );
      })}
    </div>
  );
};

export default AttachedFiles;
