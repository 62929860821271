import { useDispatch } from "react-redux";

import appConst from "../../../constants/appConst";
import CustomPopOver from "../../../../components/reusable/CustomPopOver";
import TopologyLabels from "../../../constants/topologyLabels";
import TopologySymbols from "../../reusable/TopologySymbols";
import colors from "../../../config/colors";
import { usePowertraceStates } from "../../../util/reduxFunctions/getTopologyState";
import {
  getPowertraceElements,
  setPowertraceIsActive,
} from "../../../store/slices/mapItems/powertrace/powertrace";
import "../../../styles/drawer/powertrace/Powertrace.scss";

interface Props {
  activeMapElement: any;
}

export default function PowerTraceBtn({ activeMapElement }: Props) {
  const dispatch = useDispatch();
  const labels = TopologyLabels();
  const { rensNumber, elements, isActive } = usePowertraceStates();
  const isPowertraceSelected =
    isActive && activeMapElement?.data?.rensNumber === rensNumber;

  const onPowertracePress = () => {
    if (isPowertraceSelected) {
      dispatch(setPowertraceIsActive(false));
    } else if (activeMapElement?.data?.rensNumber !== rensNumber) {
      dispatch(setPowertraceIsActive(true));
      dispatch(getPowertraceElements(activeMapElement));
    } else if (
      activeMapElement?.data?.rensNumber === rensNumber &&
      elements !== undefined
    ) {
      dispatch(setPowertraceIsActive(true));
    }
  };

  return (
    <>
      {activeMapElement?.type === appConst.powerSupply && (
        <CustomPopOver
          popOverData={labels.powertrace}
          index="popOver"
          originComp={
            <span
              onClick={onPowertracePress}
              className={isPowertraceSelected ? "activePT" : "inActivePT"}
            >
              <TopologySymbols
                name={"bulb"}
                size={18}
                color={
                  isPowertraceSelected ? colors.primary : colors.greyMedium
                }
              />
              <span style={{ fontSize: "13px" }}>BETA</span>
            </span>
          }
        />
      )}
    </>
  );
}
