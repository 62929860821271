import { RecoEngineState } from "../../../states/account/troubleshoot/RecoEngineState";
import {
  RECO_ENGINE_BEGIN,
  RECO_ENGINE_SUCCESS,
  RECO_ENGINE_FAILURE,
  RecoEngineActionTypes,
} from "../../../types/account/troubleshoot/RecoEngineProfile";

const initialState: RecoEngineState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function RecoEngineReducer(
  state = initialState,
  action: RecoEngineActionTypes
): RecoEngineState {
  switch (action.type) {
    case RECO_ENGINE_BEGIN:
      return {
        isLoading: true,
      };
    case RECO_ENGINE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case RECO_ENGINE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}
