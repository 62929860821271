import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import AppLabels from "../../constants/App_Labels";
import ProfileManagementClient from "../../network/httpclient/profileManagement/PofileManagementClient";
import LoaderGif from "../../resources/images/loader.webp";
import ExceptionBox from "../reusable/ExceptionBox";
import { isNull } from "../reusable/Util";

const LoginFixModal = (props: any) => {
  const dispatch = useDispatch();
  const labels = AppLabels();
  const [resultDisplay, setResultDisplay] = useState(false);
  const handleClose = () => {
    props.setShow(false);
    setResultDisplay(false);
  };
  const { status, isLoading, data, selSA, error } = useSelector(
    (state: any) => ({
      data: state.AccountState.ActiveAccountDetails.data,
      selSA: state.AccountState.ActiveAccountDetails.selectedSA,
      status: state.AccountState.LoginFixInfo.status,
      error: state.AccountState.LoginFixInfo.error,
      isLoading: state.AccountState.LoginFixInfo.isLoading,
    }),
    shallowEqual
  );
  const handleClick = () => {
    if (
      data?.hasOwnProperty("cbpId") &&
      selSA !== undefined &&
      selSA?.hasOwnProperty("samKey")
    ) {
      dispatch(ProfileManagementClient.getLoginFix(data?.cbpId, selSA?.samKey));
      setResultDisplay(true);
    }
  };

  return (
    <>
      <Modal dialogClassName="modalExternalAppMenu" size="lg" show={props.show}>
        <Modal.Header className="searchPopHead">
          <header>{labels.LOGIN_FIX.toUpperCase()}</header>
          <button className="sPopUpClose" onClick={handleClose}>
            <IoClose color="white" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="loginFixContainer">
            <span className="warningTitle">{labels.WARNING}</span>
            <span className="warningText">{labels.MOBILE_APP_LOGIN_TEXT}</span>
            <hr
              style={{
                width: "100%",
                alignSelf: "center",
                color: "black",
                paddingBottom: "10px",
              }}
            />
            <div className="loginFixButtonContainer">
              <Button
                className="loginFixButtons"
                id="cancel"
                onClick={handleClose}
              >
                {labels.CANCEL}
              </Button>
              <Button className="loginFixButtons" onClick={handleClick}>
                {labels.OKAY}
              </Button>
            </div>
            {resultDisplay ? (
              <div className="mobileLogInResult">
                {isLoading ? (
                  <img style={{ width: "20%" }} src={LoaderGif} />
                ) : status === "OK" ? (
                  <span className="succes">{labels.LOGIN_FIX_SUCCESS}</span>
                ) : status === "ERROR" ? (
                  error?.errorCode === "404" ? (
                    <p className="warnState">{isNull(error?.errorMessage)}</p>
                  ) : (
                    <div className="diagErrorCont">
                      <ExceptionBox
                        exBoxType="error"
                        errSecName="Login Fix"
                        errCode={isNull(error?.errorCode)}
                        ErrorMSG={isNull(error?.errorMessage)}
                        srcSystem={
                          error?.hasOwnProperty("sourceSystem")
                            ? error?.sourceSystem
                            : "Request"
                        }
                        primaryBtn={false}
                        secBtn={false}
                      />
                    </div>
                  )
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginFixModal;
