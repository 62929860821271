import { Fragment, useEffect } from "react";
import { GroundOverlay, Marker, Polyline } from "@react-google-maps/api";
import { renderToString } from "react-dom/server";
import { FaBullseye } from "react-icons/fa6";
import { useDispatch } from "react-redux";

import appConst from "../../../../../constants/appConst";
import {
  GetGoogleMaps,
  useFiberUpstreamTraceState,
} from "../../../../../util/reduxFunctions/getTopologyState";
import { mapElementStyles } from "../../../../../config/mapItemsConfig";
import {
  convertFiberTreeDataToArray,
  findOtdrByLatLngAndDistance,
  getAllPossibleOtdrs,
  getFiberPointsData,
  getMapEleImageObj,
  toFixedWithoutZeros,
  toKms,
} from "../../../../../util/utilFuncs";
import DraggableComp from "../../../../../../components/reusable/DraggableComp";
import OtdrPopup from "./OtdrPopup";
import TopologyLabels from "../../../../../constants/topologyLabels";
import {
  getFiberUptreamTraceElements,
  setFiberUpstreamTraceIsActive,
  setFiberUpstreamTraceOtdrDistance,
  setFiberUpstreamTraceOtdrInfo,
  setFiberUpstreamTraceShowPopup,
} from "../../../../../store/slices/mapItems/trace/fiber/fiberUpstreamTrace";
import { paths } from "../../../../../../routes/Routes";
import colors from "../../../../../config/colors";
import { setMapCenter } from "../../../../../store/slices/mapItems/mapDetails";
import { useTracePointsData } from "../../../../../util/customHooks/topologyHooks";

interface Props {
  allNodeData?: any;
  pngImages: any;
}

export default function FiberUpstreamTraceElements({
  allNodeData,
  pngImages,
}: Props) {
  const dispatch = useDispatch();
  const labels = TopologyLabels();
  const { currentViewBDY } = GetGoogleMaps();
  const {
    data: traceData,
    status,
    isLoading,
    isActive,
    showPopup,
    clickedEleNetworkId,
    networkId,
    rootNodeSiteId,
    type,
    elementInfo,
    otdrDistance,
    otdrDistanceUnit,
    otdrInfo,
  } = useFiberUpstreamTraceState();

  const pointsData = useTracePointsData(
    allNodeData,
    getFiberPointsData(traceData),
    currentViewBDY
  );

  const onDistanceSubmit = (distance: number) => {
    /*  dispatch(setFiberUpstreamTraceShowPopup(false));
    dispatch(setFiberUpstreamTraceOtdrDistance(distance));
    dispatch(setFiberUpstreamTraceIsActive(true));
    if (clickedEleNetworkId === networkId && status === appConst.OK) {
      displayOtdrAndOpenNewTab(traceData, distance);
    } else {
      dispatch(
        getFiberUptreamTraceElements(
          clickedEleNetworkId,
          rootNodeSiteId,
          type,
          elementInfo
        )
      );
    } */
  };

  /* //TODO enable this when enabling otdr
  const displayOtdrAndOpenNewTab = (data: any, distance: number) => {
    const otdr = findOtdrByLatLngAndDistance(data, distance);
    const allOtdrs = getAllPossibleOtdrs(data, distance);
    dispatch(setFiberUpstreamTraceOtdrInfo(allOtdrs));

    const url = window?.open(`/#` + paths.fiberTableView);
    url?.addEventListener("load", () => {
      url.document.title = paths.fiberTableView;
      url.postMessage(
        {
          traceType: appConst.upstream,
          networkId: networkId,
          fiberData: otdr?.updatedData,
          otdrInfo: { allOtdrs, otdrDistanceUnit },
        },
        url.origin
      );
    });
  }; */

  /*  useEffect(() => {
    if (isLoading === false && status === appConst.OK) {
      displayOtdrAndOpenNewTab(traceData, otdrDistance);
    }
  }, [status]); */

  /*  useEffect(() => {
    if (otdrInfo && otdrInfo?.length > 0) {
      dispatch(
        setMapCenter({
          lat: otdrInfo?.[0]?.currentLatLng?.lat,
          lng: otdrInfo?.[0]?.currentLatLng?.lng,
        })
      );
    }
  }, [otdrInfo]); */

  return (
    <>
      {isActive && (
        <Fragment>
          {traceData !== undefined &&
            traceData?.map((data: any) => {
              return data?.segments?.map((segment: any) => {
                if (segment?.geometry?.type === appConst.line) {
                  let lineLatLngArray: any = [];
                  segment?.geometry?.coordinates?.map((cord: any) => {
                    lineLatLngArray.push({ lat: cord?.[1], lng: cord?.[0] });
                  });
                  return (
                    <Polyline
                      key={`${segment?.networkId}fiberUpstreamTraceLine`}
                      path={lineLatLngArray}
                      options={mapElementStyles?.trace?.upstream?.line}
                    />
                  );
                }
              });
            })}

          {otdrInfo !== undefined &&
            otdrInfo?.map((otdr: any) => {
              if (otdr !== undefined) {
                return (
                  <Marker
                    key={`otdrMarker${otdr?.networkId}`}
                    visible={otdr?.currentLatLng !== null}
                    zIndex={99999}
                    clickable={false}
                    position={{
                      lat: otdr?.currentLatLng?.lat,
                      lng: otdr?.currentLatLng?.lng,
                    }}
                    label={{
                      text:
                        otdr?.markerDistance < 1000
                          ? `${toFixedWithoutZeros(otdr?.markerDistance)} M`
                          : `${toFixedWithoutZeros(
                              toKms(otdr?.markerDistance)
                            )} KM`,
                      className: "otdrMarkerDistanceTxt",
                      color: `var(--pureBlack)`,
                      fontSize: "11px",
                    }}
                    icon={{
                      url:
                        "data:image/svg+xml;charset=utf-8," +
                        encodeURIComponent(
                          renderToString(
                            <FaBullseye size={30} color={colors.danger} />
                          )
                        ),
                      anchor: new google.maps.Point(15, 15),
                      labelOrigin: new google.maps.Point(60, 15),
                    }}
                  />
                );
              }
            })}

          {pointsData !== undefined &&
            Object.keys(pointsData)?.map((e) => {
              return pointsData?.[e]?.map((pointData: any) => {
                if (
                  pointData?.geometry?.type === appConst.Point &&
                  pointData?.symbolName
                ) {
                  const eleImageOverlay: any = getMapEleImageObj(
                    pngImages?.[pointData?.type],
                    pointData?.symbolName,
                    pointData?.rotation,
                    pointData?.type === appConst.node
                      ? mapElementStyles?.nodeSite
                      : mapElementStyles?.[pointData?.type],
                    pointData?.geometry?.coordinates?.[1],
                    pointData?.geometry?.coordinates?.[0]
                  );

                  if (eleImageOverlay?.symbol !== undefined) {
                    return (
                      <GroundOverlay
                        key={`${pointData?.networkId}fiberUpstreamTracePoint`}
                        url={`${appConst.base64StringPrefix}${eleImageOverlay?.symbol}`}
                        bounds={
                          new google.maps.LatLngBounds(
                            new google.maps.LatLng(
                              eleImageOverlay?.southBound,
                              eleImageOverlay?.westBound
                            ),
                            new google.maps.LatLng(
                              eleImageOverlay?.northBound,
                              eleImageOverlay?.eastBound
                            )
                          )
                        }
                      />
                    );
                  }
                }
              });
            })}
        </Fragment>
      )}
      <DraggableComp style={{ top: 5, left: 5 }} isDraggable={false}>
        <div className="otdrPopUpCont">
          <OtdrPopup
            cardLabel={`${labels.Fiber} ${labels.upstream} ${labels.trace}`.toUpperCase()}
            showPopup={showPopup}
            onHidePopup={() => dispatch(setFiberUpstreamTraceShowPopup(false))}
            onDistanceSubmit={onDistanceSubmit}
          />
        </div>
      </DraggableComp>
    </>
  );
}
