import { useEffect, useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TablePagination,
  Badge,
} from "@material-ui/core";
import { useDispatch } from "react-redux";

import FormsHistoryTableHead from "./FormsHistoryTableHead";
import "../../../design-tokens/certification/CertificationTab.scss";
import { changeTimeFormat, getJson, isNull } from "../../reusable/Util";
import ExceptionBox from "../../reusable/ExceptionBox";
import LoaderGif from "../../../resources/images/loader.webp";
import FullScreenPopUp from "../../reusable/FullScreenPopUp";
import { SavePJIReset } from "../../../store/actions/account/forms/PJIFormAction";
import { SaveFormReset } from "../../../store/actions/account/forms/FormAction";
import ConfigConst from "../../../constants/ConfigConst";
import AppLabels from "../../../constants/App_Labels";
import ViewLetterOfPermissionScreen from "../../forms/LOPForm/ViewLetterOfPermissionScreen";
import ViewFeedBackScreen from "../../forms/FBForm/ViewFeedBackScreen";
import ViewPreJobInspectionScreen from "../../forms/PJIForm/VIewPreJobInspectionScreen";
import ViewSeniorReferral from "../../forms/SRForm/ViewSeniorReferral";
import ViewRRFScreen from "../../forms/RRForm/ViewRRFScreen";
import ViewEquipmentPickupReceiptScreen from "../../../components/forms/EPRForm/ViewEquipmentPickupReceiptScreen";
import ViewNoiseScreen from "../../../components/forms/NoiseForm/ViewNoiseScreen";
import ViewASPScreen from "../../forms/ASPForm/ViewASPScreen";
import PrepareCECEmailFormat from "../../forms/CECForm/PrepareCECEmailFormat";
import { GetTechInfo } from "../../../get-api/GetResponse";
import { FaRegBell } from "react-icons/fa";

const HistoryTable = (props: any) => {
  const { allForms, techData, isLoading, totalElements } = props;
  const labels = AppLabels();
  const dispatch = useDispatch();
  const emptyRows = 1;
  const currentTechID = techData?.techId;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [activeForm, setActiveForm] = useState<any>({});
  const [openForm, setOpenForm] = useState(false);

  useEffect(() => {
    setPage(0);
  }, [props.page]);

  const formData = useMemo(() => {
    let computedCertis = allForms;

    if (search && searchBy === "id") {
      computedCertis = computedCertis.filter((certi: any) =>
        certi?.id?.toLowerCase()?.includes(search?.toLowerCase())
      );
    } else if (search && searchBy === "workOrderId") {
      computedCertis = computedCertis.filter((certi: any) =>
        certi?.workOrderId?.toLowerCase()?.includes(search?.toLowerCase())
      );
    } else if (search && searchBy === "techId") {
      computedCertis = computedCertis.filter((certi: any) =>
        certi?.technicianId?.toLowerCase()?.includes(search?.toLowerCase())
      );
    }

    //Sorting Certies
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      if (sorting.field === "techId") {
        computedCertis = computedCertis.sort((a: any, b: any) => {
          return a.technicianId || b.technicianId
            ? !a.technicianId
              ? -1
              : !b.technicianId
              ? 1
              : reversed * a.technicianId.localeCompare(b.technicianId)
            : 0;
        });
      } else {
        computedCertis = computedCertis.sort((a: any, b: any) => {
          return reversed * a[sorting.field].localeCompare(b[sorting.field]);
        });
      }
    }
    return computedCertis;
  }, [search, searchBy, sorting, allForms]);

  const onItemClick = (id: string, formName: String) => {
    let seleForm = allForms.filter(
      (c: any) => c.id === id && c.formName === formName
    );
    const data = seleForm?.[0];

    if (typeof data?.formData === "string") {
      data["formData"] = JSON.parse(data?.formData);
    }
    setActiveForm(data);
    setOpenForm(true);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    props.handleChangePage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const compToRenderOnItemClick = () => {
    switch (activeForm?.formName) {
      case ConfigConst.PRE_JOB_INSPECTION:
        return {
          childComp: <ViewPreJobInspectionScreen data={activeForm} />,
          pageTitle: labels.PreJob_Inspection_Form,
        };
      case ConfigConst.LETTER_OF_PERMISSION:
        return {
          childComp: <ViewLetterOfPermissionScreen data={activeForm} />,
          pageTitle: labels.Letter_of_Permission_Form,
        };
      case ConfigConst.SENIOR_REFERRAL:
        return {
          childComp: (
            <ViewSeniorReferral data={activeForm} setOpenForm={setOpenForm} />
          ),
          pageTitle: labels.Senior_Referral_Form,
        };
      case ConfigConst.COACH_BACK:
        return {
          childComp: <ViewFeedBackScreen data={activeForm} />,
          pageTitle: labels.Coachback_Form,
        };
      case ConfigConst.CUSTOMER_EXPERIENCE_CERTIFICATION:
        return {
          childComp: (
            <PrepareCECEmailFormat
              activeAccData={true}
              custType={""}
              body={activeForm}
            />
          ),
          pageTitle: "Rogers Service Experience Certification",
        };
      case ConfigConst.RISER_REPAIR_FORM:
        return {
          childComp: <ViewRRFScreen data={activeForm} />,
          pageTitle: labels.Riser_Repair_Referral,
        };
      case ConfigConst.EQUIPMENT_PICKUP_RECEIPT:
        return {
          childComp: <ViewEquipmentPickupReceiptScreen data={activeForm} />,
          pageTitle: labels.Equipment_Pickup_Receipt,
        };
      case ConfigConst.NOISE_REFERRAL:
        return {
          childComp: <ViewNoiseScreen data={activeForm} />,
          pageTitle: labels.Noise_Referral,
        };
        case ConfigConst.RPA_ADDPODORSTB:
        return {
          childComp: <ViewASPScreen data={activeForm} />,
          pageTitle: labels.Add_Ignite_STBs_or_Pods,
        };
      default:
        return {
          childComp: <></>,
          pageTitle: "Form Type Not Found",
        };
    }
  };

  const WOIDCell = ({ e }: any) => {
    const formData = getJson(e?.formData);
    const isComplete = formData?.referralStatus; //is form complete by senior tech
    const workOrderId =
      !e.workOrderId || e.workOrderId === ConfigConst.NO_WORK_ORDER
        ? labels.No_Work_Order
        : isNull(e.workOrderId);

    return (
      <>
        {currentTechID === formData?.referredSeniorTechID && !isComplete ? (
          <Badge variant="dot" color={"primary"}>
            <a
              className="idLink"
              style={{ marginRight: "5px" }}
              onClick={() => onItemClick(e.id, e.formName)}
            >
              {workOrderId}
            </a>
          </Badge>
        ) : (
          <a className="idLink" onClick={() => onItemClick(e.id, e.formName)}>
            {workOrderId}
          </a>
        )}
      </>
    );
  };
  const RPACell = ({ e }: any) => {
    const formData = getJson(e?.formData);
    // console.log(formData)

    return (
      <>
        {e?.formName === ConfigConst.NOISE_REFERRAL && formData?.rpaStatus && (
          <Badge variant="dot" color={"primary"}></Badge>
        )}
      </>
    );
  };
  return (
    <>
      <div className="certiTableCont">
        <Paper className="deviceTablePaper">
          <TableContainer>
            <Table className="deviceTable">
              <FormsHistoryTableHead
                onSearch={(value: any, by: string) => {
                  setSearch(value);
                  setSearchBy(by);
                }}
                onSorting={(field: any, order: any) =>
                  setSorting({ field, order })
                }
              />
              <TableBody className="dTabBody">
                {isLoading ? (
                  <div className="loader">
                    <img style={{ height: "60px" }} src={LoaderGif} />
                  </div>
                ) : formData?.length === 0 ? (
                  <TableRow tabIndex={-1} key="exception">
                    <TableCell colSpan={8}>
                      <div className="diagErrorCont">
                        <ExceptionBox
                          headerText="No Forms to display."
                          bodyText="The ones submitted on this account will reflect here. Please contact the Tech Insight admin if data is missing."
                          primaryBtn={false}
                          secBtn={false}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  formData?.map((e: any) => {
                    return (
                      <TableRow tabIndex={-1} key={e.id}>
                        <TableCell align="left">
                          <WOIDCell e={e} />
                        </TableCell>
                        <TableCell align="left">{isNull(e.formName)}</TableCell>
                        <TableCell>
                          <span className="techName">
                            {isNull(e.technicianId)}
                          </span>
                        </TableCell>
                        <TableCell align="left">
                          {isNull(e.accountNumber)}
                        </TableCell>
                        <TableCell align="left">
                          {getJson(e?.formData)?.referredSeniorTechID}
                        </TableCell>
                        <TableCell align="left">
                          <span>
                            {e.hasOwnProperty("updatedDate")
                              ? changeTimeFormat(isNull(e.updatedDate))
                              : e.hasOwnProperty("createdDate")
                              ? changeTimeFormat(isNull(e.createdDate))
                              : "-"}
                          </span>
                        </TableCell>
                        {/* <TableCell align="left">
                          <RPACell e={e} />
                        </TableCell> */}
                      </TableRow>
                    );
                  })
                )}
                {formData?.length !== 0 && emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={8} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="certiPageCont">
            <p>TOTAL FORMS : {formData?.length}</p>
            <TablePagination
              rowsPerPageOptions={[25]}
              component="div"
              count={totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </Paper>
      </div>
      <FullScreenPopUp
        open={openForm}
        handleClose={() => {
          setOpenForm(false);
          dispatch(SaveFormReset);
          dispatch(SavePJIReset);
        }}
        childComponent={compToRenderOnItemClick()?.childComp}
        pageName={compToRenderOnItemClick()?.pageTitle}
      />
    </>
  );
};

export default HistoryTable;
