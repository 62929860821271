import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";

import { CMSummary } from "../../../store/models/account/diagnostics/cm/CMSummary";
import { DocsisInfo } from "../../../store/models/account/diagnostics/cm/CMDocsisInfo";
import { CMNodeInfo } from "../../../store/models/account/diagnostics/cm/CMNodeInfo";
import { formatMacAddress } from "../../../components/reusable/Util";
import {
  DecrementApiCallCounter,
  IncrementApiCallCounter,
  UpdateActiveCertiDevices,
} from "../../../store/actions/account/certifications/ActiveCertiDevicesAction";
import {
  DecrementDiagApiCallCounter,
  IncrementDiagApiCallCounter,
  UpdateActiveDiagDevices,
} from "../../../store/actions/account/diagnostics/ActiveDiagDevicesActions";
import { CMDocsisEventsState } from "../../../store/states/account/diagnostics/cm/CMDocsisEventsState";
import {
  cmDocsisEventInfoBegin,
  cmDocsisEventInfoFailure,
  cmDocsisEventInfoSuccess,
} from "../../../store/actions/account/diagnostics/cm/CMDocsisEventsAction";
import { CMFlaps } from "../../../store/models/account/diagnostics/cm/CMFlaps";
import { CMFlapsState } from "../../../store/states/account/diagnostics/cm/CMFlapsState";
import {
  cmFlapsInfoBegin,
  cmFlapsInfoFailure,
  cmFlapsInfoSuccess,
} from "../../../store/actions/account/diagnostics/cm/CMFlapsAction";

export default class CableModemClient {
  static getCMSummary =
    (
      serialNum: string,
      macAddress: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let mac = formatMacAddress(macAddress);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "diag/cablemodem/" +
          mac +
          "/summary",
      };

      dispatch(IncrementDiagApiCallCounter(serialNum));
      HttpClient.get<any>(httpParameters)
        .then((cmSummary) => {
          dispatch(UpdateActiveDiagDevices(serialNum, "summary", cmSummary));
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return cmSummary;
        })
        .catch((error) => {
          dispatch(UpdateActiveDiagDevices(serialNum, "summary", error));
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return error;
        });
    };

  static getCMNodeDetails =
    (
      serialNum: string,
      sHub: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL + "diag/network/nodetype/" + sHub,
      };

      dispatch(IncrementDiagApiCallCounter(serialNum));
      HttpClient.get<any>(httpParameters)
        .then((cmNodeInfo) => {
          dispatch(UpdateActiveDiagDevices(serialNum, "nodeInfo", cmNodeInfo));
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return cmNodeInfo;
        })
        .catch((error) => {
          dispatch(UpdateActiveDiagDevices(serialNum, "nodeInfo", error));
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return error;
        });
    };
  static getCMDocsisInfo =
    (
      serialNum: string,
      macAddress: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let mac = formatMacAddress(macAddress);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "diag/cablemodem/" +
          mac +
          "/docsis",
      };

      dispatch(IncrementDiagApiCallCounter(serialNum));
      HttpClient.get<any>(httpParameters)
        .then((cmDocsisInfo) => {
          dispatch(UpdateActiveDiagDevices(serialNum, "docsis", cmDocsisInfo));
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return cmDocsisInfo;
        })
        .catch((error) => {
          dispatch(UpdateActiveDiagDevices(serialNum, "docsis", error));
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return error;
        });
    };
  static getCMFlapsData =
    (
      macAddress: string
    ): ThunkAction<void, CMFlapsState, unknown, Action<string>> =>
    async (dispatch) => {
      let mac = formatMacAddress(macAddress);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "diag/cablemodem/" +
          mac +
          "/flaps",
      };

      dispatch(cmFlapsInfoBegin());
      HttpClient.get<CMFlaps>(httpParameters)
        .then((cmFlapsData) => {
          dispatch(cmFlapsInfoSuccess(cmFlapsData));
          return cmFlapsData;
        })
        .catch((error) => {
          dispatch(cmFlapsInfoFailure(error));
          return error;
        });
    };

  static getCMDocsisEvents =
    (
      macAddress: string
    ): ThunkAction<void, CMDocsisEventsState, unknown, Action<string>> =>
    async (dispatch) => {
      let mac = formatMacAddress(macAddress);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "diag/cablemodem/" +
          mac +
          "/docsisevents",
      };

      dispatch(cmDocsisEventInfoBegin());
      HttpClient.get<any>(httpParameters)
        .then((cmDocsisEvents) => {
          dispatch(cmDocsisEventInfoSuccess(cmDocsisEvents));
          return cmDocsisEvents;
        })
        .catch((error) => {
          dispatch(cmDocsisEventInfoFailure(error));
          return error;
        });
    };

  static getCMCertiSummary =
    (
      serialNum: string,
      macAddress: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let mac = formatMacAddress(macAddress);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "diag/cablemodem/" +
          mac +
          "/summary",
      };

      dispatch(IncrementApiCallCounter(serialNum));

      HttpClient.get<CMSummary>(httpParameters)
        .then((cmSummary) => {
          dispatch(UpdateActiveCertiDevices(serialNum, "summary", cmSummary));
          dispatch(DecrementApiCallCounter(serialNum));
          return cmSummary;
        })
        .catch((error) => {
          dispatch(UpdateActiveCertiDevices(serialNum, "summary", error));
          dispatch(DecrementApiCallCounter(serialNum));
          return error;
        });
    };

  static getCMCertiNodeDetails =
    (
      serialNum: string,
      sHub: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL + "diag/network/nodetype/" + sHub,
      };

      dispatch(IncrementApiCallCounter(serialNum));

      HttpClient.get<CMNodeInfo>(httpParameters)
        .then((cmNodeInfo) => {
          dispatch(UpdateActiveCertiDevices(serialNum, "nodeInfo", cmNodeInfo));
          dispatch(DecrementApiCallCounter(serialNum));
          return cmNodeInfo;
        })
        .catch((error) => {
          dispatch(UpdateActiveCertiDevices(serialNum, "nodeInfo", error));
          dispatch(DecrementApiCallCounter(serialNum));
          return error;
        });
    };
  static getCMCertiDocsisInfo =
    (
      serialNum: string,
      macAddress: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let mac = formatMacAddress(macAddress);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "diag/cablemodem/" +
          mac +
          "/docsis",
      };

      dispatch(IncrementApiCallCounter(serialNum));

      HttpClient.get<DocsisInfo>(httpParameters)
        .then((cmDocsisInfo) => {
          dispatch(UpdateActiveCertiDevices(serialNum, "docsis", cmDocsisInfo));
          dispatch(DecrementApiCallCounter(serialNum));
          return cmDocsisInfo;
        })
        .catch((error) => {
          dispatch(UpdateActiveCertiDevices(serialNum, "docsis", error));
          dispatch(DecrementApiCallCounter(serialNum));
          return error;
        });
    };

  static getManagedRouterInfo =
    (cmMac: string): ThunkAction<Promise<any>, any, unknown, Action<string>> =>
    async () => {
      return new Promise((resolve, reject) => {
        let httpParameters: IHttpClientRequestParameters<any> = {
          url:
            NetworkConfig.NETWORK_SERVICE_URL +
            "diag/cablemodem/" +
            "managedRouter/summary?cmMac=" +
            cmMac,
        };

        HttpClient.get<any>(httpParameters)
          .then((managedRouterInfo) => {
            resolve(managedRouterInfo);
          })
          .catch((error) => {
            reject(error);
          });
      });
    };
}
