import { useEffect, useState } from "react";
import AppLabels from "../../constants/App_Labels";
import "../../design-tokens/workorder/WorkOrderPopUp.scss";
import { getByKey } from "../reusable/Util";
import { codesList, codesObject } from "./WODropdownConfig";


const CompletedTabData = (props: any) => {
    const labels = AppLabels();
    // const codesObj = codesObject;
    const [codesObj, setCodesObj] = useState<any>(codesObject);
    const {
        faultCode,
        networkCode,
        activityCode,
        causeCode,
        preventionCode,
        additCode,
        comment,
    } = codesObj;

    const {
        fCodesList,
        nCodesList,
        aCodesList,
        pCodesList,
        addCodesList,
        cCodesList,
    } = codesList();

    const wo = props?.woData;
    const completionCodes = wo?.completionCodes;
    let additCodes = []
    if (completionCodes !== undefined && completionCodes !== null) {
        additCodes = completionCodes?.filter((e: any) => e?.type === "additionalCode");
    }
    const newAdditCodes = additCodes?.map((e: any) => { return getByKey(addCodesList, e?.code) });

    useEffect(() => {
        setCodesObj((codesObj: any) => ({
            ...codesObj,
            faultCode: getByKey(fCodesList, completionCodes?.find((e: any) => e?.type === "faultCode")?.code),
            networkCode: getByKey(nCodesList, completionCodes?.find((e: any) => e?.type === "networkCode")?.code),
            activityCode: getByKey(aCodesList, completionCodes?.find((e: any) => e?.type === "activityCode")?.code),
            causeCode: getByKey(cCodesList, completionCodes?.find((e: any) => e?.type === "causeCode")?.code),
            preventionCode: getByKey(pCodesList, completionCodes?.find((e: any) => e?.type === "preventionCode")?.code),
            additCode: newAdditCodes,
            comment: wo?.comments
        }))
    }, [props]);


    // codesObj.faultCode = getByKey(fCodesList, completionCodes?.find((e: any) => e?.type === "faultCode")?.code);
    // codesObj.networkCode = getByKey(nCodesList, completionCodes?.find((e: any) => e?.type === "networkCode")?.code);
    // codesObj.activityCode = getByKey(aCodesList, completionCodes?.find((e: any) => e?.type === "activityCode")?.code);
    // codesObj.causeCode = getByKey(cCodesList, completionCodes?.find((e: any) => e?.type === "causeCode")?.code);
    // codesObj.preventionCode = getByKey(pCodesList, completionCodes?.find((e: any) => e?.type === "preventionCode")?.code);
    // const additCodes = completionCodes?.filter((e: any) => e?.type === "additionalCode");

    // codesObj.additCode = newAdditCodes;
    // codesObj.comment = wo?.comments;

    let errorTable: any[] = [
        faultCode,
        networkCode,
        activityCode,
        causeCode,
        preventionCode,
        ...additCode,
    ];

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    width: "70%",
                    marginLeft: "10px",
                }}
            >
                <div
                    className="WODetailRow"
                    style={{
                        justifyContent: "flex-start",
                    }}
                >
                    <div className="WODetailColumnInfo">
                        <label className="titleLabel">
                            <label>{labels.Fault_Code}</label>
                        </label>
                        <p className="midMargin">{faultCode?.key ? faultCode?.key : labels.NA}</p>
                    </div>
                    <div className="WODetailColumnInfo">
                        <label className="titleLabel">
                            <label>{labels.Network_Code}</label>
                        </label>
                        <p className="midMargin">{networkCode?.key ? networkCode?.key : labels.NA}</p>
                    </div>
                    <div className="WODetailColumnInfo">
                        <label className="titleLabel">
                            <label>{labels.Activity_Code}</label>
                        </label>
                        <p className="midMargin">{activityCode?.key ? activityCode?.key : labels.NA}</p>
                    </div>
                </div>
                <div
                    className="WODetailRow"
                    style={{
                        justifyContent: "flex-start",
                    }}
                >
                    <div className="WODetailColumnInfo">
                        <label className="titleLabel">
                            <label>{labels.Cause_Code}</label>
                        </label>
                        <p className="midMargin">{causeCode?.key ? causeCode?.key : labels.NA}</p>
                    </div>
                    <div className="WODetailColumnInfo">
                        <label className="titleLabel">
                            <label>{labels.Prevention_Code}</label>
                        </label>
                        <p className="midMargin">{preventionCode?.key ? preventionCode?.key : labels.NA}</p>
                    </div>
                    <div className="WODetailColumnInfo">
                        <label className="titleLabel">{labels.Additional_Codes}</label>
                        <p className="midMargin">{!additCode.length
                            ? labels.NA
                            : additCode
                                ?.map((a: any) => a?.key)
                                ?.sort((a: any, b: any) => (a > b ? 1 : -1))
                                ?.join(", ")}</p>
                    </div>
                </div>
                <div className="WODetailRow">
                    <div className="WODetailColumnInfo">
                        <p id="headerP">Code Table</p>
                        <table className="dataTableDetails">
                            <thead>
                                <tr>
                                    <th>{labels.Name}</th>
                                    <th>{labels.Description}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {errorTable
                                    ?.sort((a: any, b: any) => (a?.key > b?.key ? 1 : -1))
                                    ?.filter((obj: any) => obj?.key !== "")
                                    ?.map((item: any, index: number) => {
                                        return (
                                            item !== undefined &&
                                            item?.key !== "" && (
                                                <tr key={index * index - 786}>
                                                    <td>{item?.key + " - " + item?.shortDesc}</td>
                                                    <td className="errorTableRow">
                                                        <span className="tableText">
                                                            {item?.description}
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="WODetailRow">
                    <div className="WODetailColumnInfo" style={{ width: "100%" }}>
                        <label className="titleLabel">
                            {labels.COMMENTS}
                        </label>
                        <p className="midMargin">{codesObj?.comment}</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CompletedTabData;
