
import { NetworkInterfacesState } from "../../../../states/account/diagnostics/cm/NetworkInterfacesState";

import {
    NETWORK_INTERFACES_BEGIN,
    NETWORK_INTERFACES_SUCCESS,
    NETWORK_INTERFACES_FAILURE,
    NetworkInterfacesActionTypes,
} from "../../../../types/account/diagnostics/cm/Ethernet";

const initialState: NetworkInterfacesState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function NetworkInterfacesReducer(
  state = initialState,
  action: NetworkInterfacesActionTypes
): NetworkInterfacesState {
  switch (action.type) {
    case NETWORK_INTERFACES_BEGIN:
      return {
        isLoading: true,
      };
    case NETWORK_INTERFACES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case NETWORK_INTERFACES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}