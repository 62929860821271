import {createSlice} from '@reduxjs/toolkit';
import {
  NODE_TAP_ADDRESS_URI,
  NODE_TAP_URI,
} from '../../../../middleware/apiEndpoints';
import {apiCallBegan} from '../../../../middleware/apiActions';
import storeConst from '../../../../storeConst';

const slice = createSlice({
  name: 'nodeTapAddress',
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: '',
  },
  reducers: {
    // action => action handler
    getNodeTapAddressBegin: nodeTapAddress => {
      nodeTapAddress.isLoading = true;
      nodeTapAddress.status = '';
    },
    getNodeTapAddressSuccess: (nodeTapAddress, action) => {
      nodeTapAddress.isLoading = false;
      nodeTapAddress.data = action.payload;
      nodeTapAddress.status = storeConst.OK;
    },
    getNodeTapAddressFailure: (nodeTapAddress, action) => {
      nodeTapAddress.isLoading = false;
      nodeTapAddress.error = action.payload;
      nodeTapAddress.data = undefined;
      nodeTapAddress.status = storeConst.ERROR;
    },
  },
});

//should not be exposed to outside
const {
  getNodeTapAddressBegin,
  getNodeTapAddressSuccess,
  getNodeTapAddressFailure,
} = slice.actions;
export default slice.reducer;

//action creators
export const getNodeTapAddress = (tapId: any) =>
  apiCallBegan({
    url: `${NODE_TAP_URI}/${tapId}${NODE_TAP_ADDRESS_URI}`,
    onStart: getNodeTapAddressBegin.type,
    onSuccess: getNodeTapAddressSuccess.type,
    onError: getNodeTapAddressFailure.type,
  });
