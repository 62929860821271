import { Arrow, Transformer } from "react-konva";
import { KonvaEventObject } from "konva/lib/Node";
import { useRef, useEffect } from "react";

import {
  ICommonDrawingProps,
  IDrawPosition,
  IGeoEditShape,
  getOpacity,
} from "../GeoEditCanvas";

export interface IGeoArrowShape extends IGeoEditShape {
  points: number[];
  strokeWidth: number;
}

interface Props {
  arrows: IGeoArrowShape[];
  setArrows: (arrows: IGeoArrowShape[]) => void;
  cdp: ICommonDrawingProps;
  onClick: ({ id }: { id: string }) => void;
}

export default function Arrows({ arrows, setArrows, cdp, onClick }: Props) {
  const { color, strokeWidth, drawStartPosi, drawEndPosi } = cdp;

  return (
    <>
      {arrows.map((ar) => (
        <Shape
          key={ar.id}
          shape={ar}
          onClick={onClick}
          shapes={arrows}
          setShapes={setArrows}
          cdp={cdp}
        />
      ))}

      {cdp.drawingType === "arrow" && drawStartPosi && drawEndPosi && (
        <Arrow
          points={calcArrowPoints(drawStartPosi, drawEndPosi)}
          x={drawStartPosi.x}
          y={drawStartPosi.y}
          // fill={cdp.fill}
          opacity={getOpacity(cdp.opacity)}
          stroke={color}
          strokeWidth={strokeWidth}
        />
      )}
    </>
  );
}

interface ShapeProps {
  shape: IGeoArrowShape;
  shapes: IGeoArrowShape[];
  setShapes: (rect: IGeoArrowShape[]) => void;
  cdp: ICommonDrawingProps;
  onClick: ({ id }: { id: string }) => void;
}
const Shape = ({ shape, shapes, setShapes, cdp, onClick }: ShapeProps) => {
  const shapeRef = useRef<any>(null);
  const trRef = useRef<any>(null);
  const { selectedShape, isSelected: isSSelected } = cdp;
  const isSelected = isSSelected({ id: shape.id, shape: "arrow" });

  const handleDragStart = (e: KonvaEventObject<DragEvent>) => {
    cdp.setDrawingType("none");
    const id = e.target.id();
    setShapes(shapes.map((s) => ({ ...s, isDragging: s.id === id })));
  };

  const handleDragEnd = () =>
    setShapes(shapes.map((s) => ({ ...s, isDragging: false })));

  useEffect(() => {
    if (isSelected && trRef.current) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
    if (!isSelected && shapeRef.current) {
      shapeRef.current.scaleX(1);
      shapeRef.current.scaleY(1);
    }
  }, [isSelected]);

  const handleTREnd = () => {
    const node = shapeRef.current;
    if (node) {
      const scaleX = node.scaleX();
      const scaleY = node.scaleY();
      node.scaleX(1);
      node.scaleY(1);
      let points = node.points();

      if (selectedShape?.id && selectedShape.shape === "arrow") {
        setShapes(
          shapes.map((s) =>
            s.id === selectedShape.id
              ? {
                  ...s,
                  x: node.x(),
                  y: node.y(),
                  points: points.map((p: any, i: number) =>
                    i % 2 === 0 ? p * scaleX : p * scaleY
                  ),
                  rotation: node.rotation(),
                }
              : s
          )
        );
      }

      trRef.current.getLayer().batchDraw();
    }
  };

  return (
    <>
      <Arrow
        ref={shapeRef}
        points={shape.points}
        x={shape.x}
        y={shape.y}
        // fill={shape.stroke}
        stroke={shape.stroke}
        strokeWidth={shape.strokeWidth}
        rotation={shape.rotation}
        opacity={getOpacity(shape.opacity)}
        draggable
        scaleX={shape.isDragging ? 1.1 : 1}
        scaleY={shape.isDragging ? 1.1 : 1}
        onMouseDown={handleDragStart}
        onDragEnd={handleDragEnd}
        onClick={() => onClick({ id: shape.id })}
      />
      {selectedShape && isSelected && (
        <Transformer
          ref={trRef as any}
          onTransformEnd={handleTREnd}
          enabledAnchors={[
            "top-left",
            "top-right",
            "bottom-left",
            "bottom-right",
          ]}
        />
      )}
    </>
  );
};

export const calcArrowPoints = (
  startPosi: IDrawPosition,
  endPosi: IDrawPosition
) => {
  return [0, 0, endPosi.x - startPosi.x, endPosi.y - startPosi.y];
};
