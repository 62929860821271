import { combineReducers } from "redux";
import fileUpload from "./fileUpload";
import fileDownload from "./fileDownload";
import fileFetch from "./fileFetch";

const fileManagerReducer = combineReducers({
    fileUploadState: fileUpload,
    fileDownloadState: fileDownload,
    fileFetchState: fileFetch,
});

export default fileManagerReducer;
