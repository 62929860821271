import { createSlice } from "@reduxjs/toolkit";
import { changeTimeFormat } from "../../../components/reusable/Util";
import appConst from "../../../../../sqv-web/src/topology/constants/appConst";
import {
  convertStreamDataToJson,
  isCoordsAvaileable,
  isNumeric,
  newTeleData,
  mergeDatasets,
  combinePONData
} from "../../../../../sqv-web/src/topology/util/utilFuncs";
import { TELE_ABORT_KEY } from "../../../../../sqv-web/src/topology/store/middleware/abortKeys";
import { apiCallBegan } from "../../../../../sqv-web/src/topology/store/middleware/apiActions";
import { NetworkConfig } from "../NetworkConfig";
import { DiagExecutionType } from "../../../../../sqv-web/src/topology/store/reduxTypes";
import storeConst from "../../../../../sqv-web/src/topology/store/storeConst";
import { UniversalSearchTypes } from "../../../../../sqv-web/src/topology/store/reduxTypes";
import { setActiveMainSearchType } from "../../../../../sqv-web/src/topology/store/slices/topoGeneral";

let telemetryData: any = [];
const batchSize = 1;
const slice = createSlice({
  name: "PONFluxtelemetry",
  initialState: {
    oltIsLoading: false, //ml = modemsList
    oltData: undefined,
    oltError: undefined,
    oltStatus: "",
    nodeId: undefined,
    advFCond: appConst.or.toUpperCase(),
    activeProfile: appConst.default.toUpperCase(),
    advancedFilters: undefined,
    allIsLoading: false,
    isDevicesVisible: false,
    isAborted: false,
    apiCallCounter: 0, //used for stop telemetry button
    diagStartTime: undefined,
    diagEndTime: undefined,
    diagType: storeConst.all as DiagExecutionType, //diagnostics type, eg (all, summary)
    viewType: storeConst.all,
    allDiagStatus: "",
    allDiagError: undefined,
    
  },
  reducers: {
    //only set flux loading status
    getPONFluxListBegin: (tl) => {
      // tl.apiCallCounter++;
      tl.oltStatus = "";
      tl.isAborted = false;
   
      tl.diagEndTime = undefined;
      tl.allDiagStatus = "";
      tl.allIsLoading= true;
    },
    getPONFluxListSuccess: (tl, { payload }) => {    
      let currMlData: any = JSON.parse(JSON.stringify(tl.oltData));

      let dataChunk = convertStreamDataToJson(payload);

      if (dataChunk?.length > 0 && currMlData?.length > 0) {
        telemetryData = newTeleData(currMlData, dataChunk);

        let combinedData = [];
        if (telemetryData?.length >= Number(batchSize)) {
          combinedData = combinePONData(currMlData, telemetryData);

          if (combinedData) {
            tl.oltData = combinedData;
            telemetryData = [];
          }
        }
        if (currMlData?.length === dataChunk?.length && combinedData) {
          let endTime: any = changeTimeFormat(Date.now());
          tl.allIsLoading = false;
          tl.apiCallCounter--;
          tl.diagEndTime = endTime;
          if (telemetryData?.length > 0)
            tl.oltData = combinePONData(currMlData, telemetryData);
          tl.allDiagStatus = storeConst.OK;
          tl.allDiagError = undefined;
        }
      }
    },
    getPONFluxListFailure: (tl, { payload }) => {
      let endTime: any = changeTimeFormat(Date.now());
      tl.allIsLoading = false;
      tl.apiCallCounter--;
      tl.diagEndTime = endTime;
      tl.allDiagStatus = storeConst.ERROR;
      tl.allDiagError = payload;
    },
    setNodeId: (tl, { payload }) => {
      tl.nodeId = payload;
    },
   
   
    setAllDiagLoading: (tl, { payload }) => {
      let currMlData: any = JSON.parse(JSON.stringify(tl.oltData));
      tl.allIsLoading = payload;
      if (!payload && telemetryData?.length > 0) {
        tl.oltData = mergeDatasets(currMlData, telemetryData);
        telemetryData = [];
      }
    },
    getONTListBegin: (tl) => {
        let startTime: any = changeTimeFormat(Date.now());
        tl.diagStartTime = startTime;
        tl.apiCallCounter++;
        tl.oltIsLoading = true;
        tl.oltStatus = "";
        tl.isAborted = false;
      
        tl.diagEndTime = undefined;
        tl.allDiagStatus = "";
      },
      getONTListSuccess: (tl, { payload }) => {    
        let endTime: any = changeTimeFormat(Date.now());
        tl.allIsLoading = true; 
              tl.oltIsLoading = false;
              tl.apiCallCounter--;
              tl.diagEndTime = endTime;
              tl.oltData = payload;
              // tl.allDiagStatus = storeConst.OK;
              tl.allDiagError = undefined;
      
  
  },
  //If this api fails-> no need to make other api calls
      getONTListFailure: (tl, { payload }) => {
        let endTime: any = changeTimeFormat(Date.now());
        tl.oltIsLoading = false;
        tl.apiCallCounter--;
        tl.oltError = payload.data;
        tl.oltData = undefined;
        tl.diagEndTime = endTime;
        tl.allIsLoading=false;
        tl.allDiagStatus=storeConst.ERROR;
        tl.oltStatus = storeConst.ERROR;
      }, 
  },
});

export const {
  setAllDiagLoading,
  setNodeId,

} = slice.actions;

const {
    getPONFluxListBegin,
    getPONFluxListSuccess,
    getPONFluxListFailure,
    getONTListBegin,
    getONTListSuccess,
    getONTListFailure,
 
} = slice.actions; //should not be exposed to outside
export default slice.reducer;


interface DiagRequestType {
    oltName?: String;
  node?: string;
  accType?: string;
  ponPort?: String;
  oltData?: any;
}


  
  
  export const getOLTListData =
  ({
    oltName,
    ponPort,

  }: DiagRequestType) =>
  (dispatch: any) => {
    
    const diagUrl = "diag/network/olt/" +
          oltName + '/'+
          ponPort;

       dispatch(
        setActiveMainSearchType({
          type: appConst.GPON_NODE as UniversalSearchTypes,
        })
      );
    dispatch(
      apiCallBegan({
        baseURL: NetworkConfig.NETWORK_SERVICE_URL,
        url: diagUrl,
        method: "get",    
        // responseType: "stream",
        abortKey: TELE_ABORT_KEY,
        onStart: getONTListBegin.type,
        onSuccess: getONTListSuccess.type,
        onError: getONTListFailure.type,
      })
    );
  };

  export const getONTSummaryData =
  ({
    oltData,
 
  }: DiagRequestType) =>
  (dispatch: any) => {
    
    const diagUrl = "diag/network/olt/flux"  ;
      //  dispatch(
      //   setActiveMainSearchType({
      //     type: appConst.GPON_NODE as UniversalSearchTypes,
      //   })
      // );
      if(oltData!=undefined || oltData?.length>0)
    dispatch(
      apiCallBegan({
        baseURL: NetworkConfig.NETWORK_SERVICE_URL,
        url: diagUrl,
        method: "post",
        data: oltData,   
         responseType: "stream",
        abortKey: TELE_ABORT_KEY,
        onStart: getPONFluxListBegin.type,
        onSuccess: getPONFluxListSuccess.type,
        onError: getPONFluxListFailure.type,
      })
    );
  };