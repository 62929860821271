import { PONOLTInfo } from "../../models/gpon/PONOLTInfo";

export const PON_OLT_LIST_BEGIN = "PON_OLT_LIST_BEGIN";
export const PON_OLT_LIST_SUCCESS = "PON_OLT_LIST_SUCCESS";
export const PON_OLT_LIST_FAILURE = "PON_OLT_LIST_FAILURE";
export const RESET_PON_OLT_INFO = "RESET_PON_OLT_INFO";

interface PonOltInfoBegin {
  type: typeof PON_OLT_LIST_BEGIN;
}

interface PonOltInfoSuccess {
  type: typeof PON_OLT_LIST_SUCCESS;
  payload: PONOLTInfo;
}

interface PonOltInfoFailure {
  type: typeof PON_OLT_LIST_FAILURE;
  payload: Error;
}

interface PonOltInfoReset {
  type: typeof RESET_PON_OLT_INFO;
}

export type PonOltInfoActionTypes =
  | PonOltInfoBegin
  | PonOltInfoSuccess
  | PonOltInfoFailure
  | PonOltInfoReset;
