import { Divider } from "@material-ui/core";
import AppLabels from "../../constants/App_Labels";
import CmViewData from "../../data/cablemodem/CmViewData";
import { convertCMData, getValueByStrMapping, isNull } from "../reusable/Util";
interface Props {
  cmData: any;
  nodeData?: any;
}

const CMSummaryInfoFull = ({ cmData, nodeData }: Props) => {
  const labels = AppLabels();
  const cmSummaryDataConfig = CmViewData();
  const summaryData = cmData?.deviceDiag?.data?.summary;
  return (
    <div className="cmFullInfoCont">
      <section className="cmBasicDetails">
        <p className="cmMacID">
          <span>{labels.Mac_Id.toUpperCase()}:</span>
          <span>{cmData?.macAddress}</span>
        </p>
        <p>
          <span>{labels.NODE_ID.toUpperCase()}:</span>
          <span>{nodeData?.nodeId}</span>
        </p>
        <p>
          <section>
            <span>{labels.PHUB.toUpperCase()}:</span>
            <span>{cmData?.phub}</span>
          </section>
          <span className="divideSpan">|</span>
          <section>
            <span className="secSpan">{labels.SMT.toUpperCase()}:</span>
            <span>{cmData?.serviceArea}</span>
          </section>
        </p>
        <p>
          <section>
            <span>{labels.CMTS_NAME.toUpperCase()}:</span>
            <span> {summaryData?.cmtsName}</span>
          </section>
          <span className="divideSpan">|</span>
          <section>
            <span className="secSpan">{labels.MAC_DOMAIN.toUpperCase()}:</span>
            <span>{nodeData?.macDomain}</span>
          </section>
        </p>
      </section>
      <Divider orientation="horizontal" />
      <div className="cmSummaryDetailCont">
        <div>
          {cmSummaryDataConfig?.cmSummary?.dataSet1?.map((attr: any) => {
            let attValue: any = getValueByStrMapping(cmData, attr?.dataKey);
            let computedValue = convertCMData(
              attr?.label?.toLowerCase(),
              attValue
            );
            return (
              <p>
                <span>{attr?.label?.toUpperCase()}:</span>
                <span>{isNull(computedValue)}</span>
              </p>
            );
          })}
        </div>
        <Divider orientation="vertical" />
        <div>
          {cmSummaryDataConfig?.cmSummary?.dataSet2?.map((attr: any) => {
            let attValue: any = getValueByStrMapping(cmData, attr?.dataKey);
            let computedValue = convertCMData(
              attr?.label?.toLowerCase(),
              attValue
            );
            return (
              <p>
                <span>{attr?.label?.toUpperCase()}:</span>
                <span>{isNull(computedValue)}</span>
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CMSummaryInfoFull;
