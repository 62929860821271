export const CMTS_RACK_ID_BEGIN = "CMTS_RACK_ID_BEGIN";
export const CMTS_RACK_ID_SUCCESS = "CMTS_RACK_ID_SUCCESS";
export const CMTS_RACK_ID_FAILURE = "CMTS_RACK_ID_FAILURE";
export const RESET_CMTS_RACK_ID = "RESET_CMTS_RACK_ID";

interface CMTSRackIDBegin {
  type: typeof CMTS_RACK_ID_BEGIN;
}

interface CMTSRackIDSuccess {
  type: typeof CMTS_RACK_ID_SUCCESS;
  payload: any;  // Replaced with 'any'
}

interface CMTSRackIDFailure {
  type: typeof CMTS_RACK_ID_FAILURE;
  payload: any;  // Replaced with 'any'
}

interface ResetCMTSRackID {
  type: typeof RESET_CMTS_RACK_ID;
}

export type CMTSRackIDActionTypes =
  | CMTSRackIDBegin
  | CMTSRackIDSuccess
  | CMTSRackIDFailure
  | ResetCMTSRackID;
