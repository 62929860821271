import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { FaRegPlayCircle, FaRegStopCircle } from "react-icons/fa";
import { IoIosArrowDropup } from "react-icons/io";
import { useDispatch } from "react-redux";
import CustomDropDown from "../../components/reusable/CustomDropDown";

import {
  getNodeTeleGloabalStatus,
  getTelemetryInfo,
} from "../../components/reusable/NodeUtil";
import SearchByNodeUrl from "../../components/search/SearchByNodeUrl";
import AppLabels from "../../constants/App_Labels";
import ConfigConst from "../../constants/ConfigConst";
import { emtaDataSetType } from "../../constants/EmtaTelemetryConfig";
import {
  GetNodeInfoDetails,
  GetTelemetryDetails,
} from "../../get-api/GetResponse";
import { TELE_ABORT_KEY } from "../../topology/store/middleware/abortKeys";
import { addAbortKey } from "../../topology/store/middleware/apiClient";
import {
  getTeleModemsList,
  setAllDiagLoading,
  setDiagType,
  setIsCWEnabled,
  setModemType,
  setViewType,
  stopDiagExecution,
} from "../../topology/store/slices/mapItems/diagnostics/telemetry";
import storeConst from "../../topology/store/storeConst";
import { GetTelemetryProps } from "../../topology/util/reduxFunctions/getTopologyState";
import { EmtaTopBanner } from "./EmtaBanner";
import { EmtaNodeTelemetryTab } from "./EmtaNodeTelemetryTab";
import { isNull, openNewWindow } from "../../components/reusable/Util";
import Loader from "../../resources/images/loader.webp";
import CMTSClient from "../../network/httpclient/cmts/CMTSClient";

interface IKeyValue {
  text: String;
  value: string | number | JSX.Element;
}

export const EmtaMainPage = (props: any) => {
  const { location: search } = props;
  const dispatch = useDispatch();
  const labels = AppLabels();
  const searchParams = new URLSearchParams(search.search);
  const nodeId = searchParams.get("nodeId");
  const smtId = searchParams.get("smtId");

  const { ducksConfigData } = GetTelemetryDetails();
  const { currNodeState, currNodedata } = GetNodeInfoDetails();
  const {
    allDiagIsLoading,
    allDiagStatus,
    isAborted,
    mlData,
    viewType,
    diagStartTime,
    diagEndTime,
  } = GetTelemetryProps();
  const nodeTeleStatus = getNodeTeleGloabalStatus(
    allDiagStatus,
    allDiagIsLoading,
    isAborted
  );
  const [RackIDData, setRackIDData] = useState<any>({});
  const [dataSetName, setDataSetName] = React.useState("");
  const [bannerInfo, setBannerInfo] = React.useState<any>({
    nodeId: currNodedata?.nodeId ? currNodedata?.nodeId : "",
    smtId: currNodedata?.smtId ? currNodedata?.smtId : "",
    isSmt: currNodedata?.smt,
    phub: currNodedata?.phub || "",
    active: "",
    assigned: "",
    responsive: "",
    unresponsive: "",
    startTime: "",
    endTime: "",
  });
  const [expand, setExpand] = React.useState(true);
  const [tableAttributes, setTableAttributes] = React.useState<any>([]);

  let dataSetNames: any = emtaDataSetType?.map((e: any) => {
    return e?.dataSetName;
  });

  const handleToggle = () => {
    setExpand((prev) => !prev);
  };

  const runTelemetry = () => {
    addAbortKey(TELE_ABORT_KEY);
    setBannerInfo({
      nodeId: currNodedata?.nodeId ? currNodedata?.nodeId : "",
      smtId: currNodedata?.smtId ? currNodedata?.smtId : "",
      isSmt: currNodedata?.smt,
      phub: currNodedata?.phub || "",
      active: undefined,
      assigned: undefined,
      responsive: undefined,
      unresponsive: undefined,
      startTime: undefined,
      endTime: undefined,
    });
    if (
      nodeTeleStatus === ConfigConst.complete.toLowerCase() ||
      nodeTeleStatus === ConfigConst.stopped.toLowerCase()
    ) {
      const activeId = currNodedata?.smt
        ? currNodedata?.smtId
        : currNodedata?.nodeId;
      const type = currNodedata?.smt ? "smtid" : "shubid";
      dispatch(setIsCWEnabled(false));
      dispatch(
        getTeleModemsList({
          value: activeId,
          type: type,
          diagnoseDevices: true,
          modemType: "emta",
        })
      );
    } else dispatch(setAllDiagLoading(true));
  };

  const stopTelemetry = () => {
    dispatch(stopDiagExecution());
  };

  const apiCmtsIPCall = useCallback(async () => {
    if (currNodedata) {
      try {
        const returnedData = await dispatch(
          CMTSClient.getCMTSRackID(currNodedata?.cmtsIp)
        );
        setRackIDData(returnedData);
      } catch (error) {
        console.error(error);
      }
    }
  }, [currNodedata]);

  useEffect(() => {
    if (currNodedata) {
      apiCmtsIPCall();
    }
  }, [currNodedata, apiCmtsIPCall]);

  React.useLayoutEffect(() => {
    // remove the navbar from the DOM
    const navbar = document.getElementsByClassName("drawerCloseSec")[0];
    const header = document.getElementsByClassName(
      "headerSec"
    )[0] as HTMLElement;
    const main = document.getElementsByClassName(
      "main-section"
    )[0] as HTMLElement;
    if (navbar && header && main) {
      // remove the navbar from the DOM completely and adjust the header and main sections margin-left to 0
      if (header?.firstChild) {
        const headerFirstChild = header?.firstChild as HTMLElement;
        headerFirstChild.style.width = "100%";
      }
      header.style.marginLeft = "auto";
      main.style.marginLeft = "auto";
      navbar.remove();
      dispatch(setIsCWEnabled(false));
      dispatch(setModemType("emta"));
    }
    if (search) {
      const dataSetType = searchParams.get("dataSet");
      const viewTypeParam = searchParams.get("viewType");
      dispatch(setIsCWEnabled(false));
      if (dataSetType && dataSetType !== "") {
        dispatch(setDiagType(dataSetType?.toLowerCase()?.trim()));
        setDataSetName(
          dataSetType?.toLowerCase()?.trim() === storeConst.all ||
            dataSetType?.toLowerCase()?.trim() === storeConst.all_no_cw
            ? dataSetNames[0]
            : dataSetNames[1]
        );
      } else {
        dispatch(setDiagType(storeConst.all_no_cw));
        setDataSetName(dataSetNames[0]);
      }
      if (viewTypeParam && viewTypeParam !== "") {
        dispatch(setViewType(viewTypeParam?.toUpperCase()?.trim()));
      }
    }
  }, []);

  React.useEffect(() => {
    if (viewType !== undefined && viewType !== "") {
      ducksConfigData?.userPreferences?.node?.telemetry?.views?.map(
        (v: any) => {
          if (v?.viewName?.toLowerCase() === viewType?.toLowerCase()) {
            setTableAttributes(v?.attributes);
          }
        }
      );
    }
  }, [viewType]);

  React.useEffect(() => {
    if (
      mlData?.length > 0 &&
      nodeTeleStatus === ConfigConst.complete &&
      !isAborted
    ) {
      const modemListInfo = getTelemetryInfo(mlData);
      setBannerInfo({
        nodeId: currNodedata?.nodeId,
        smtId: currNodedata?.smtId,
        isSmt: currNodedata?.smt,
        phub: currNodedata?.phub,
        active: modemListInfo?.active,
        assigned: modemListInfo?.assigned,
        responsive: modemListInfo?.responsive,
        unresponsive: modemListInfo?.unresponsive,
        startTime: diagStartTime,
        endTime: diagEndTime,
      });
    } else if (allDiagIsLoading) {
      setBannerInfo({
        nodeId: currNodedata?.nodeId ? currNodedata?.nodeId : "",
        smtId: currNodedata?.smtId ? currNodedata?.smtId : "",
        isSmt: currNodedata?.smt,
        phub: currNodedata?.phub || "",
        active: undefined,
        assigned: undefined,
        responsive: undefined,
        unresponsive: undefined,
        startTime: undefined,
        endTime: undefined,
      });
    }
  }, [mlData]);

  const flexContainerStyle = {
    display: "flex",
    flexDirection: "column" as "column",
    flexWrap: "wrap" as "wrap",
    alignItems: "flex-start" as "flex-start",
    paddingleft: "15px" as "15px",
  };

  const flexItemStyle = {
    flexBasis: "50%",
  };

  const KeyValuesTitle = (props: IKeyValue) => {
    const { text, value } = props;
    return (
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          alignItems: "center",
        }}
      >
        <span style={{ fontWeight: 600 }}>{text}:</span>
        <span style={{ marginLeft: "5px" }}>{value}</span>
      </span>
    );
  };

  return (
    <div>
      {!currNodedata && <SearchByNodeUrl {...props} />}
      {currNodedata && (
        <div className="emtaLandingPage">
          <div className="stbBasicDetails">
            <section className="nodeDetailsCont">
              <div style={flexContainerStyle}>
                <p style={flexItemStyle}>
                  <span>
                    {currNodedata?.smt
                      ? labels.SMT_ID.toUpperCase()
                      : labels.NODE_ID.toUpperCase()}
                    :
                  </span>

                  <span>
                    {currNodedata?.smt
                      ? isNull(currNodedata?.smtId)
                      : isNull(currNodedata?.nodeId)}
                  </span>

                  <span className="accTopology">{"EMTA".toUpperCase()}</span>

                  <p style={flexItemStyle}>
                    <span>{labels.PHUB.toUpperCase()}:</span>
                    <span>{isNull(currNodedata?.phub)}</span>
                  </p>
                </p>
              </div>

              <div style={flexContainerStyle}>
                <p style={flexItemStyle}>
                  <span>{labels.CMTS_IP.toUpperCase()}:</span>
                  <span
                    className="nodeLink"
                    onClick={() => {
                      openNewWindow(
                        `/#/node/cmts?nodeId=${currNodedata?.nodeId}&mac=${currNodedata?.macDomain}&usPort=${currNodedata?.usPort}&cmtsIp=${currNodedata?.cmtsIp}`
                      );
                    }}
                  >
                    {isNull(currNodedata?.cmtsIp)}
                  </span>
                </p>
                <p style={flexItemStyle}>
                  <span>{labels.CMTS_NAME.toUpperCase()}:</span>
                  <span>
                    {" "}
                    {isNull(currNodedata?.cmtsName)} /{" "}
                    {RackIDData?.cmtsLocation}
                  </span>
                </p>
              </div>
              <div style={flexContainerStyle}>
                <p style={flexItemStyle}>
                  <span>{labels.US_PORT.toUpperCase()}:</span>
                  <span> {isNull(currNodedata?.usPort)}</span>
                </p>
                <p style={flexItemStyle}>
                  <span>{labels.DS_PORT.toUpperCase()}:</span>
                  <span> {isNull(currNodedata?.dsPort)}</span>
                </p>
              </div>

              <div style={flexContainerStyle}>
                <p style={flexItemStyle}>
                  <span>{labels.MAC_DOMAIN.toUpperCase()}:</span>
                  <span> {isNull(currNodedata?.macDomain)}</span>
                </p>

                <p style={flexItemStyle}>
                  <span>{"US PEERS".toUpperCase()}:</span>
                  <span> {isNull(currNodedata?.usPeers)}</span>
                </p>
              </div>

              {/* <Divider orientation="vertical" /> */}
              <div className="teleScores">
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                    paddingLeft: "10px",
                  }}
                >
                  <KeyValuesTitle
                    text="Active"
                    value={
                      bannerInfo?.active === undefined ? (
                        <img src={Loader} alt="loading" height="20px" />
                      ) : (
                        `${bannerInfo?.active}%`
                      )
                    }
                  />
                  <KeyValuesTitle
                    text="Assigned"
                    value={
                      bannerInfo?.assigned === undefined ? (
                        <img src={Loader} alt="loading" height="20px" />
                      ) : (
                        `${bannerInfo?.assigned}`
                      )
                    }
                  />
                </span>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                    paddingLeft: "10px",
                  }}
                >
                  <KeyValuesTitle
                    text="Responsive"
                    value={
                      bannerInfo?.responsive === undefined ? (
                        <img src={Loader} alt="loading" height="20px" />
                      ) : (
                        `${bannerInfo?.responsive}`
                      )
                    }
                  />
                  <KeyValuesTitle
                    text="Unresponsive"
                    value={
                      bannerInfo?.unresponsive === undefined ? (
                        <img src={Loader} alt="loading" height="20px" />
                      ) : (
                        `${bannerInfo?.unresponsive}`
                      )
                    }
                  />
                </span>
              </div>

              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                  paddingLeft: "10px",
                }}
              >
                <KeyValuesTitle
                  text="Start"
                  value={
                    diagStartTime === undefined ? (
                      <img src={Loader} alt="loading" height="20px" />
                    ) : (
                      `${diagStartTime}`
                    )
                  }
                />
                <KeyValuesTitle
                  text="End"
                  value={
                    diagEndTime === undefined ? (
                      <img src={Loader} alt="loading" height="20px" />
                    ) : (
                      `${diagEndTime}`
                    )
                  }
                />
              </span>
            </section>

            {/* 

          <div style={flexContainerStyle}>
          <p style={flexItemStyle}>
            <span>
              {currNodedata?.smt
                ? labels.SMT_ID.toUpperCase()
                : labels.NODE_ID.toUpperCase()}
              :
            </span>

            <span>
              {currNodedata?.smt
                ? isNull(currNodedata?.smtId)
                : isNull(currNodedata?.nodeId)}
            </span>

            <span className="accTopology">{"EMTA".toUpperCase()}</span>

            <p style={flexItemStyle}>
              <span>{labels.PHUB.toUpperCase()}:</span>
              <span>{isNull(currNodedata?.phub)}</span>
            </p>
          </p>
        </div> */}

            {/* <EmtaTopBanner data={bannerInfo} expand={expand} /> */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
                marginLeft: "auto",
                marginRight: "2%",
              }}
            >
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                {labels.DATA_SET?.toUpperCase()}
              </span>
              &nbsp;
              <CustomDropDown
                selectedOption={dataSetName}
                options={dataSetNames}
                searchFlag={false}
                checkboxFlag={false}
                setSelectedOption={(value: any) => {
                  setDataSetName(value);
                  //dispatch(SetTeleDatasetType(value));
                  dispatch(
                    setDiagType(
                      value === ConfigConst.FULL_NO_CW.toUpperCase()
                        ? storeConst.all_no_cw
                        : storeConst.summary
                    )
                  );
                }}
              />
              <div style={{ padding: "0px 20px" }}>
                {mlData !== undefined && mlData?.length !== 0 && (
                  <>
                    {!allDiagIsLoading ? (
                      <button
                        className="refreshCertiBtn"
                        onClick={runTelemetry}
                      >
                        {labels.START?.toUpperCase()} <FaRegPlayCircle />
                      </button>
                    ) : (
                      <>
                        <button
                          className="refreshCertiBtn"
                          onClick={() => stopTelemetry()}
                        >
                          {labels.STOP?.toUpperCase()} <FaRegStopCircle />
                        </button>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="gponBannerIconsContainer">
              <div
                className="gponCollapseContainer"
                style={{
                  rotate: expand ? "0deg" : "180deg",
                }}
              >
                <IoIosArrowDropup
                  color="black"
                  size={25}
                  onClick={handleToggle}
                />
              </div>
            </div>
          </div>
          <div className="gponBodyContainer" style={{ padding: "4px" }}>
            <EmtaNodeTelemetryTab
              tableAttr={tableAttributes}
              data={currNodeState}
              dataSetType={emtaDataSetType}
              dataSetNames={dataSetNames}
              nodeId={currNodedata?.nodeId}
            />
          </div>
        </div>
      )}
    </div>
  );
};
