import { useEffect, useState, createElement } from "react";

import ExceptionBox from "../../../../components/reusable/ExceptionBox";
import TopologyLabels from "../../../constants/topologyLabels";
import PaginatedTable from "../table/PaginatedTable";
import Table from "../table/Table";

export default function ExpandedTable(props: any) {
  const labels = TopologyLabels();
  const [tableProps, setTableProps] = useState<any>(null);

  useEffect(() => {
    const message = (e: any) => {
      if (e?.data?.data && e?.data?.title) {
        const msgData = e?.data;
        setTableProps({
          ...e?.data,
          data: getJsx(msgData?.data, msgData?.columns),
          fullData: getJsx(msgData?.fullData, msgData?.columns),
        });
      }
    };
    window.addEventListener("message", message);
    return () => {
      window.removeEventListener("message", message);
    };
  }, []);

  return (
    <div>
      {tableProps ? (
        tableProps?.fullData ? (
          <PaginatedTable
            dataPerScreen={100}
            {...tableProps}
            data={tableProps?.fullData}
            useTabDataContClass={true}
          />
        ) : (
          <Table
            {...tableProps}
            useTabDataContClass={true}
            tableStyle={{
              ...tableProps?.tableStyle,
              maxWidth: "none",
              maxHeight: "none",
            }}
          />
        )
      ) : (
        <div className="diagErrorCont">
          <ExceptionBox
            headerText={labels.noDataFound}
            bodyText={labels.passProperDataMsg}
          />
        </div>
      )}
    </div>
  );
}

const convertToJSX = (value: any) => {
  if (
    typeof value === "string" &&
    value.startsWith("<") &&
    value.endsWith(">")
  ) {
    // Value is a string of JSX element, convert it to an actual JSX element
    return createElement("div", {
      dangerouslySetInnerHTML: { __html: value },
    });
  } else return value;
};

const getJsx = (data: any, columns: any) => {
  const tempData = data?.map((d: any) => {
    const tr = columns?.reduce(
      (acc: any, column: any) => ({
        ...acc,
        [column.tdName]: convertToJSX(d[column.tdName]),
      }),
      {}
    );
    return { id: d.id, ...tr };
  });

  return tempData;
};
