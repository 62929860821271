import DataDisplayTable from "../reusable/DataDisplayTable";

const ODUCellInfo = (props: any) => {
  const { cellInfo } = props;
  return (
    <div className="ofdmChannelCont">
      <div className="ofdmChSummary">
        {cellInfo !== undefined ? (
          <DataDisplayTable dataObject={cellInfo} tableName="cellInfo" />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ODUCellInfo;
