import "date-fns";
import { format, addDays } from "date-fns";
import { Form, DropdownButton, Dropdown } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Card } from "@material-ui/core";
import { DateRange } from "react-date-range";

import ErrorBox from "../../reusable/ErrorBox";
import { getFormType } from "../../reusable/Util";
import CustomDialog from "../../reusable/CustomDialog";
import { MdDateRange } from "react-icons/md";
import ConfigConst from "../../../constants/ConfigConst";
import configConst from "../../../topology/config/configConst";

interface Props {
  techData: any;
  accountID?: string;
  cbpid?: string;
  onApplyFilters: any;
  fromAccount?: boolean;
  filterParams?: any;
}

const HistoryFilters = (props: Props) => {
  const [techId, setTechId] = useState("");
  const onNodeNumberSubmit = (e: any) => setNodeNumber(e.target.value);
  const [nodeNumber, setNodeNumber] = useState("");
  const [techLanId, setTechLanId] = useState("");
  const [workOrderId, setWorkOrderId] = useState("");
  const [accountId, setAccountId] = useState(props.accountID);
  const [cbpid, setCbpid] = useState(props.cbpid);
  const [seleTestType, setSeleTestType] = useState("All Forms");

  const onTechIdSubmit = (e: any) => setTechId(e.target.value);
  const onTechLanIdSubmit = (e: any) => setTechLanId(e.target.value);
  const onWorkOrderIdSubmit = (e: any) => {
    setWorkOrderId(e.target?.value?.replace(/\s+/g, "")?.toUpperCase());
  };
  const onAccountIdSubmit = (e: any) => setAccountId(e.target.value);

  const [open, setOpen] = useState(false);
  const [error, setError] = useState("This is Error Message");
  const handleClose = () => {
    setOpen(false);
  };
  const [showDateRange, setShowDateRange] = useState(false);
  const [dateRangeSelection, setDateRangeSelection] = useState([
    {
      startDate: addDays(new Date(), -1),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const handleDateRangeSelect = (ranges: any) => {
    setDateRangeSelection([ranges.selection]);
  };

  const handleApplyFilters = () => {
    var datesDiff = 0;
    if (
      dateRangeSelection?.[0]?.startDate != null &&
      dateRangeSelection?.[0]?.endDate != null
    ) {
      const diffInMs = Math.abs(
        dateRangeSelection?.[0]?.endDate?.getTime() -
        dateRangeSelection?.[0]?.startDate.getTime()
      );
      datesDiff = Math.round(diffInMs / (1000 * 60 * 60 * 24));
    }

    if (
      techId != undefined &&
      techId.length > 0 &&
      (techId.length != 4 || isNaN(Number(techId)))
    ) {
      setError("Please enter valid 4 digit Technician ID");
      setOpen(true);
    } else if (datesDiff > 30) {
      setError("Dates difference should not be more than 30 days");
      setOpen(true);
    } else if (
      dateRangeSelection?.[0]?.startDate?.getTime() >
      dateRangeSelection?.[0]?.endDate.getTime()
    ) {
      setError("From date should be equal or lesser than To date");
      setOpen(true);
    } else {
      props.onApplyFilters(
        techId,
        techLanId,
        workOrderId,
        accountId,
        cbpid,
        getFormType(seleTestType),
        format(
          new Date(
            Number(dateRangeSelection?.[0]?.startDate?.getFullYear()),
            Number(dateRangeSelection?.[0]?.startDate?.getMonth()),
            Number(dateRangeSelection?.[0]?.startDate?.getDate())
          ),
          "dd/MM/yyyy"
        ),
        format(
          new Date(
            Number(dateRangeSelection?.[0]?.endDate?.getFullYear()),
            Number(dateRangeSelection?.[0]?.endDate?.getMonth()),
            Number(dateRangeSelection?.[0]?.endDate?.getDate())
          ),
          "dd/MM/yyyy"
        ),
        nodeNumber,
      );
    }
  };

  useEffect(() => {
    if (seleTestType === ConfigConst.COACH_BACK) {
      setTechLanId(
        props?.filterParams?.techLanId ? props?.filterParams?.techLanId : ""
      );
    } else setTechLanId("");
  }, [seleTestType]);

  return (
    <>
      <Card className="filterRootContainer">
        <div className="filterContainer">
          <span className="filterText">Filters</span>
          <div className="filterRow">
            <div className="inputContainer">
              <Form.Group>
                <Form.Label>Technician ID</Form.Label>
                <Form.Control
                  name="inputField"
                  onChange={onTechIdSubmit}
                  className="inputField"
                  id="inputField"
                  value={techId || ""}
                  placeholder="Type Tech ID.."
                />
              </Form.Group>
            </div>
            <div className="inputContainer">
              <Form.Group>
                <Form.Label>Technician Lan ID</Form.Label>
                <Form.Control
                  name="inputField"
                  onChange={onTechLanIdSubmit}
                  className="inputField"
                  id="inputField"
                  value={techLanId || ""}
                  placeholder="Type Tech Lan ID.."
                />
              </Form.Group>
            </div>
            <div className="inputContainer">
              <Form.Group>
                <Form.Label>Work Order ID</Form.Label>
                <Form.Control
                  name="inputField"
                  onChange={onWorkOrderIdSubmit}
                  className="inputField"
                  id="inputField"
                  value={workOrderId || ""}
                  placeholder="Type Order ID.."
                />
              </Form.Group>
            </div>
          </div>
          <div className="filterRow">
            <div className="inputContainer">
              <Form.Group>
                <Form.Label>Account ID</Form.Label>
                <Form.Control
                  name="inputField"
                  onChange={onAccountIdSubmit}
                  className="inputField"
                  id="inputField"
                  value={accountId || ""}
                  placeholder="Type Account ID.."
                  disabled={props.fromAccount ? props.fromAccount : false}
                />
              </Form.Group>
            </div>
            <div className="testTypeCont">
              <Form.Label>Form Type</Form.Label>
              <DropdownButton
                title={seleTestType}
                onSelect={(e: any) => {
                  setSeleTestType(e);
                }}
                size="sm"
                drop="up"
                className="testTypeDropd"
                id="testTypeDd"
              >
                <Dropdown.Item key={0} eventKey="All Forms">
                  All Forms
                </Dropdown.Item>
                <Dropdown.Item
                  key={1}
                  eventKey={ConfigConst.PRE_JOB_INSPECTION}
                >
                  {ConfigConst.PRE_JOB_INSPECTION}
                </Dropdown.Item>
                {/* <Dropdown.Item
                  key={2}
                  eventKey={ConfigConst.LETTER_OF_PERMISSION}
                >
                  {ConfigConst.LETTER_OF_PERMISSION}
                </Dropdown.Item> */}
                <Dropdown.Item key={2} eventKey={ConfigConst.SENIOR_REFERRAL}>
                  {ConfigConst.SENIOR_REFERRAL}
                </Dropdown.Item>
                <Dropdown.Item key={3} eventKey={ConfigConst.COACH_BACK}>
                  {ConfigConst.COACH_BACK}
                </Dropdown.Item>
                <Dropdown.Item
                  key={4}
                  eventKey={ConfigConst.EQUIPMENT_PICKUP_RECEIPT}
                >
                  {ConfigConst.EQUIPMENT_PICKUP_RECEIPT}
                </Dropdown.Item>
                {/* <Dropdown.Item key={6} eventKey={ConfigConst.RISER_REPAIR_FORM}>
                  {ConfigConst.RISER_REPAIR_FORM}
                </Dropdown.Item> */}
                <Dropdown.Item key={5} eventKey={ConfigConst.NOISE_REFERRAL}>
                  {ConfigConst.NOISE_REFERRAL}
                </Dropdown.Item>
                <Dropdown.Item
                  key={6}
                  eventKey={ConfigConst.CUSTOMER_EXPERIENCE_CERTIFICATION}
                >
                  {ConfigConst.IEC}
                </Dropdown.Item>

                <Dropdown.Item
                  key={7}
                  eventKey={ConfigConst.RPA_ADDPODORSTB}
                >
                  {ConfigConst.RPA_ADDPODORSTB}
                </Dropdown.Item>
              </DropdownButton>
            </div>
            <div className="inputContainer">
              <Form.Group>
                <Form.Label>Date Range</Form.Label>
                <div
                  className="fromToDate"
                  onClick={() => setShowDateRange(true)}
                >
                  <Form.Text className="fromToDateLabel">
                    {format(
                      new Date(
                        Number(
                          dateRangeSelection?.[0]?.startDate?.getFullYear()
                        ),
                        Number(dateRangeSelection?.[0]?.startDate?.getMonth()),
                        Number(dateRangeSelection?.[0]?.startDate?.getDate())
                      ),
                      "MM/dd/yyyy"
                    )}{" "}
                    -{" "}
                    {format(
                      new Date(
                        Number(dateRangeSelection?.[0]?.endDate?.getFullYear()),
                        Number(dateRangeSelection?.[0]?.endDate?.getMonth()),
                        Number(dateRangeSelection?.[0]?.endDate?.getDate())
                      ),
                      "MM/dd/yyyy"
                    )}
                  </Form.Text>
                  <MdDateRange fill={"#808284"} size={20} />
                </div>
              </Form.Group>
            </div>
          </div>
          {seleTestType === ConfigConst.NOISE_REFERRAL && (
            <div className="filterRow">
              <div className="inputContainer">
                <Form.Group>
                  <Form.Label>Node Number</Form.Label>
                  <Form.Control
                    name="inputField"
                    onChange={onNodeNumberSubmit}
                    className="inputField"
                    id="inputField"
                    value={nodeNumber || ""}
                    placeholder="Type Node Number.."
                  />
                </Form.Group>
              </div>
            </div>
          )}
        </div>
        <div className="filterBtn">
          <button className="applyFilersBtn" onClick={handleApplyFilters}>
            Apply Filters
          </button>
        </div>
      </Card>
      <ErrorBox
        open={open}
        error={error}
        setOpen={setOpen}
        handleClose={handleClose}
      />
      <div>
        <CustomDialog
          open={showDateRange}
          handleClose={() => {
            setShowDateRange(false);
          }}
          childComponent={
            <div className="fromToRootContainer">
              <DateRange
                onChange={handleDateRangeSelect}
                moveRangeOnFirstSelection={false}
                ranges={dateRangeSelection}
                rangeColors={["#da291c", "#da291c", "#da291c"]}
              />
            </div>
          }
          heading="Select Date Range"
        />
      </div>
    </>
  );
};
export default HistoryFilters;
