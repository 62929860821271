import React, { useState } from "react";
import { BiDownload } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { format } from "date-fns";
import DeleteAttachments from "./DeleteAttachments";
import { deleteFile } from "../../../store/slices/mapItems/elementAttachments";
import appConst from "../../../constants/appConst";
interface props {
  attachment: any;
  handleDownload: any;
  dispatch: any;
  networkId: any;
}

const ElementAttachmentList = ({
  attachment,
  handleDownload,
  dispatch,
  networkId,
}: props) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const onDeleteClick = (attachmentData: any) => {
    dispatch(deleteFile(attachmentData?.attachmentId, networkId));
    setDeleteModal(false);
  };
  return (
    <React.Fragment key={attachment?.attachmentId}>
      <tr>
        <td
          style={{
            textAlign: "center",
            verticalAlign: "middle",
          }}
        >
          <button
            className="blueHollowBtn"
            onClick={() => handleDownload(attachment)}
            title="Download"
          >
            <BiDownload />
          </button>
        </td>
        <td>{attachment?.fileName}</td>
        <td>
          {format(attachment?.updatedDate, appConst.dateTimeFormatForComments)}
        </td>
        <td>{attachment?.description}</td>
        <td
          key={attachment?.attachmentId}
          style={{
            textAlign: "center",
            verticalAlign: "middle",
          }}
        >
          <button className="deleteButton" onClick={() => setDeleteModal(true)}>
            <MdDelete />
          </button>
        </td>
      </tr>
      {deleteModal && (
        <DeleteAttachments
          attachmentData={attachment}
          setDeleteModal={setDeleteModal}
          onDeleteClick={onDeleteClick}
        />
      )}
    </React.Fragment>
  );
};

export default ElementAttachmentList;
