export const BAN_SEARCH_BEGIN = "BAN_SEARCH_BEGIN";
export const BAN_SEARCH_SUCCESS = "BAN_SEARCH_SUCCESS";
export const BAN_SEARCH_FAILURE = "BAN_SEARCH_FAILURE";
export const LOGIN_FIX_BEGIN = "LOGIN_FIX_BEGING";
export const LOGIN_FIX_SUCCESS = "LOGIN_FIX_SUCCESS";
export const LOGIN_FIX_FAILURE = "LOGIN_FIX_FAILURE";
export const LINK_BAN_BEGIN = "LINK_BAN_BEGIN";
export const LINK_BAN_SUCCESS = "LINK_BAN_SUCCESS";
export const LINK_BAN_FAILURE = "LINK_BAN_FAILURE";
export const UNLINK_BAN_BEGIN = "UNLINK_BAN_BEGIN";
export const UNLINK_BAN_SUCCESS = "UNLINK_BAN_SUCCESS";
export const UNLINK_BAN_FAILURE = "UNLINK_BAN_FAILURE";

interface BanSearchBegin {
  type: typeof BAN_SEARCH_BEGIN;
}
interface BanSearchSuccess {
  type: typeof BAN_SEARCH_SUCCESS;
  payload: any;
}
interface BanSearchFailure {
  type: typeof BAN_SEARCH_FAILURE;
  payload: Error;
}

interface LoginFixBegin {
  type: typeof LOGIN_FIX_BEGIN;
}
interface LoginFixSuccess {
  type: typeof LOGIN_FIX_SUCCESS;
}
interface LoginFixFailure {
  type: typeof LOGIN_FIX_FAILURE;
  payload: Error;
}

interface LinkBanBegin {
  type: typeof LINK_BAN_BEGIN;
}
interface LinkBanSuccess {
  type: typeof LINK_BAN_SUCCESS;
}
interface LinkBanFailure {
  type: typeof LINK_BAN_FAILURE;
  payload: Error;
}

interface UnlinkBanBegin {
  type: typeof UNLINK_BAN_BEGIN;
}
interface UnlinkBanSuccess {
  type: typeof UNLINK_BAN_SUCCESS;
}
interface UnlinkBanFailure {
  type: typeof UNLINK_BAN_FAILURE;
  payload: Error;
}

export type BanActionTypes =
  | BanSearchBegin
  | BanSearchSuccess
  | BanSearchFailure;
export type LoginFixActionTypes =
  | LoginFixBegin
  | LoginFixSuccess
  | LoginFixFailure;
export type LinkBanActionTypes = LinkBanBegin | LinkBanSuccess | LinkBanFailure;
export type UnlinkBanActionTypes =
  | UnlinkBanBegin
  | UnlinkBanSuccess
  | UnlinkBanFailure;
