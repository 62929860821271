import { IoClose } from "react-icons/io5";
import SignaturePad from "react-signature-canvas";
import { Button, Form, Modal } from "react-bootstrap";
import { useRef, useState } from "react";

import "../../design-tokens/ReusableComp.scss";
import CustomIcons from "./CustomIcons";
import AppLabels from "../../constants/App_Labels";

interface Props {
  signatureImage: any;
  onSignedImageChange?: any;
  title?: string;
}

export default function SignatureCapture(props: Props) {
  const labels = AppLabels();
  const sigCanvas: any = useRef({});
  const [modalVisible, setModalVisible] = useState(false);

  function clearSignature() {
    sigCanvas.current.clear();
    props.onSignedImageChange("");
  }

  function confirmSignature() {
    if (sigCanvas.current.isEmpty()) {
      props.onSignedImageChange("");
    } else {
      props.onSignedImageChange(
        sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
      );
    }
    setModalVisible(false);
  }

  return (
    <>
      <Form.Group>
        {props.title && <label className="titleLabel">{props.title}</label>}
        <Button bsPrefix="signatureBox" onClick={() => setModalVisible(true)}>
          {props.signatureImage !== "" ? (
            <img
              src={props.signatureImage}
              style={{ height: "90px" }}
              alt={labels.Signature_Capture}
            />
          ) : (
            <>
              <CustomIcons
                name="signature"
                color={`var(--oceanBlue)`}
                size={40}
              />
              <div className="dashedLine" />
            </>
          )}
        </Button>
      </Form.Group>
      <Modal
        show={modalVisible}
        onHide={() => setModalVisible(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header className="searchPopHead">
          <header>{labels.Signature_Capture}</header>
          <button
            className="sPopUpClose"
            onClick={() => setModalVisible(false)}
          >
            <IoClose color="white" />
          </button>
        </Modal.Header>
        <Modal.Body bsPrefix="modalSignatureBody">
          <SignaturePad
            ref={sigCanvas}
            penColor="black"
            backgroundColor="rgb(217,240,248)"
            canvasProps={{
              width: 460,
              height: 200,
              className: "sigCanvas",
            }}
          />
        </Modal.Body>
        <div className="dashedLineContainer">
          <div className="modalDashedLine" />
          <label>{labels.Sign_in_the_box_above}</label>
        </div>
        <Modal.Footer bsPrefix="signatureBoxFooter">
          <Button bsPrefix="signatureBtn " onClick={clearSignature}>
            {labels.Clear}
          </Button>
          <Button bsPrefix="signatureBtn" onClick={confirmSignature}>
            {labels.Confirm}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
