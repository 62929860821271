import { shallowEqual, useDispatch, useSelector } from "react-redux";
import DiagContainer from "../reusable/DiagContainer";
import { isNull, breakLongStrings } from "../reusable/Util";
import ReloadData from "../reusable/ReloadData";
import ExceptionBox from "../reusable/ExceptionBox";
import CableModemClient from "../../network/httpclient/cablemodem/CableModemClient";
import CustomAccordion from "../reusable/CustomAccordion";
import IHPClient from "../../network/httpclient/ihp/IHPClient";
// import CMFlapsDetails from "./CMFlapsDetails";
const RHPDocsisEvents = (props: any) => {
    const { selectedDevice } = props;
    const dispatch = useDispatch();

    const { dELoading, dEInfo, dEError } =
        useSelector(
            (state: any) => ({
                dEInfo: state.AccountState?.RHPDocsisEventsInfo?.data,
                dELoading: state.AccountState?.RHPDocsisEventsInfo?.isLoading,
                dEError: state.AccountState?.RHPDocsisEventsInfo?.error,
                // flapsInfo: state.AccountState.cmFlapsDetails.data,
                // flapsLoading: state.AccountState.cmFlapsDetails.isLoading,
                // flapsError: state.AccountState.cmFlapsDetails.error,
            }),
            shallowEqual
        );
    const refreshData = () => {
        // dispatch(CableModemClient.getCMFlapsData(selectedDevice.macAddress));
        dispatch(IHPClient.getRHPDocsisEvents(selectedDevice.macAddress));
    };

    return (
        <>
            {" "}
            <div className="refreshCont">
                <ReloadData refreshData={refreshData} />
            </div>
            <div className="cmSummaryCont">
                {/* <DiagContainer
                    isLoading={flapsLoading}
                    data={flapsInfo}
                    sectionName="Docsis Events"
                    error={flapsError}
                    handleRefresh={() => refreshData()}
                >
                    <CustomAccordion
                        accTitle={"Flaps Details".toUpperCase()}
                        accBodyElement={
                            <CMFlapsDetails flapsInfo={flapsInfo} refreshData={refreshData} />
                        }
                    />
                </DiagContainer> */}
                <DiagContainer
                    isLoading={dELoading}
                    data={dEInfo}
                    sectionName="Docsis Events"
                    error={dEError}
                    handleRefresh={() => refreshData()}
                >
                    {dEInfo !== undefined ? (
                        <div className="docsisEventCont">
                            <table className="dataTableDetails">
                                {dEInfo?.length !== 0 ? (
                                    <>
                                        <thead>
                                            <th className="smallCol">{"Id".toUpperCase()}</th>
                                            <th>{"First Time".toUpperCase()}</th>
                                            <th>{"Last Time".toUpperCase()}</th>
                                            <th>{"Count".toUpperCase()}</th>
                                            <th className="smallCol">
                                                {"Level".toUpperCase()}
                                            </th>
                                            <th>{"Description".toUpperCase()}</th>
                                        </thead>
                                        <tbody>
                                            {dEInfo?.map((event: any, index: number) => {
                                                return (
                                                    <tr>
                                                        <td>{isNull(event?.eventId)}</td>
                                                        <td>{isNull(event?.firstOccurTime)}</td>
                                                        <td>{isNull(event?.lastOccurTime)}</td>
                                                        <td>{isNull(event?.noOfOccurance)}</td>
                                                        <td>{isNull(event?.level)}</td>
                                                        <td>
                                                            {isNull(
                                                                breakLongStrings(event?.description, ";")
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </>
                                ) : (
                                    <div className="diagErrorCont">
                                        <ExceptionBox
                                            headerText="No Data to display in Docsis Events."
                                            bodyText="Please try refreshing."
                                            primaryBtn={false}
                                            secBtn={false}
                                        />
                                    </div>
                                )}
                            </table>
                        </div>
                    ) : (
                        <></>
                    )}
                </DiagContainer>
            </div>
        </>
    );
};

export default RHPDocsisEvents;
