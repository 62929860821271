import { isNull } from "../reusable/Util";
import AppLabels from "../../constants/App_Labels";
import ConfigConst from "../../constants/ConfigConst";

const NeighPartialService = (props: any) => {
  const { partialServiceData , ofdmDownStreamChannelsData } = props;
  const labels = AppLabels();
  return (
    <>
      <table className="dataTableDetails">
        <thead>
          <th id="nameCol">{labels.NAME.toUpperCase()}</th>
          <th id="valueCol">{labels.VALUE.toUpperCase()}</th>
        </thead>
        <tbody>
          {partialServiceData?.casaDsExcluded && (
            <tr>
              <td>Excluded DS Channels</td>
              <td>
                {partialServiceData.excludedChannels &&
                  partialServiceData.casaDsExcluded
                    .split(", ")
                    .map((channel : any) => (
                      <div key={channel}>
                        {partialServiceData.excludedChannels[channel]}
                      </div>
                    ))}
              </td>
            </tr>
          )}
          {partialServiceData?.casaUsExcluded && (
            <tr>
              <td>Excluded US Channels</td>
              <td>
                {partialServiceData.excludedChannels &&
                  partialServiceData.casaUsExcluded
                    .split(", ")
                    .map((channel : any) => (
                      <div key={channel}>
                        {partialServiceData.excludedChannels[channel]}
                      </div>
                    ))}
              </td>
            </tr>
          )}
          
          {partialServiceData?.d31PartialChanState.toLowerCase() === 'none' ? (
  <tr>
    <td>OFDM Partial Channel Issues</td>
    <td>None</td>
  </tr>
) : (
  <tr>
    <td className="valueColumn">OFDM Partial Channel Issues</td>
    <td>
      {ofdmDownStreamChannelsData.map((channel: any, index: number) => (
          <span key={channel.channelId}>
            Channel ID: {channel.channelId}, 
            {channel.profiles.map((profile: any, index: number) => (
              <span key={profile.profileId}>
                {profile.active ? <strong>*[{index}] {profile.modulation}*</strong> : `[${index}] ${profile.modulation}`}
                {index !== channel.profiles.length - 1 ? ', ' : ''}
              </span>
            ))}
         {index !== ofdmDownStreamChannelsData.length - 1 && <br />}
                    </span>
                  ))}
    </td>
  </tr>
)}
<tr>
            <td>Partial Service Status</td>
            <td>{isNull(partialServiceData?.d31PartialSvcState)}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};


export default NeighPartialService;
