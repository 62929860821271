import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import { isNull, isUpdated, extractW3IdOfHSI } from "../../reusable/Util";
import AppLabels from "../../../constants/App_Labels";
import { DropdownButton, Dropdown, Form } from "react-bootstrap";
import ImageUpload from "../../reusable/ImageUpload";
import {
  GetActiveAccountDetails,
  GetTechInfo,
} from "../../../get-api/GetResponse";

import "../../../design-tokens/forms/FeedBack.scss";
import RedAsterisk from "../../reusable/RedAsterisk";
import React from "react";
interface Props {
  setDetails: any;
}

export default function FeedBackBody({ setDetails }: Props) {
  const profileInfo = GetTechInfo()?.data;
  const accountDetails = GetActiveAccountDetails()?.data;

  const formData = accountDetails?.formData;
  const labels = AppLabels();

  const [menuItem, setmenuItem] = useState(labels.COACHBACK_TYPE);
  const [menuItem2, setmenuItem2] = useState(labels.Coachback_Group);
  const menuOptions = [
    labels.Compliment,
    labels.Compliance,
    labels.Education,
    labels.Quality,
    labels.Misinformation,
  ];
  const menuOptions2 = [labels.CareSales, labels.TechSupport,labels.FSC, labels.Technician];
  const [files, setFiles] = useState<File[]>(
    formData?.images ? formData?.images : ""
  );
  const [feedBackMsg, setFeedBackMsg] = useState("");
  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  useEffect(() => {
    const isDetailsComplete =
      menuItem !== labels.COACHBACK_TYPE &&
      feedBackMsg !== "" &&
      menuItem2 !== labels.Coachback_Group;

    if (isDetailsComplete) {
      setDetails({
        menuItem2,
        menuItem,
        files,
        feedBackMsg,
      });
    } else setDetails(undefined);
  }, [menuItem2, menuItem, files, feedBackMsg]);
  return (
    <div className="FeedBackContainer">
      <p>
        <span>{labels.ACCOUNT_NUMBER + ": "}</span>
        <span>{isNull(accountDetails?.accountNumber)}</span>
      </p>

      <p>
        <span>{labels.Your_Tech_ID}: </span>
        <span>{isNull(profileInfo?.techId)}</span>
      </p>
      <p>
        <span>{labels.Coachback_Date}: </span>
        <span> {date} </span>
      </p>
      <p>
        <span className="FeedBackBtnContainer">
          <span>
            {" "}
            {labels.Coachback_Group}:<RedAsterisk />{" "}
          </span>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              {menuItem2}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {menuOptions2.map((m: any, i: number) => {
                return (
                  <Dropdown.Item onSelect={() => setmenuItem2(m)}>
                    {m}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </span>
      </p>
      <p>
        <span className="FeedBackBtnContainer">
          <span>
            {" "}
            {labels.COACHBACK_TYPE}:<RedAsterisk />{" "}
          </span>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              {menuItem}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {menuOptions.map((n: any, i: number) => {
                return (
                  <Dropdown.Item onSelect={() => setmenuItem(n)}>
                    {n}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </span>
      </p>
      <Form.Group>
        <label className="txtFieldLabel">
          {labels.Coachback_details}:<RedAsterisk />
        </label>
        <Form.Control
          onChange={(e) => setFeedBackMsg(e.target.value)}
          as="textarea"
          rows={4}
          value={feedBackMsg}
          placeholder={labels.Free_Text_Box_for_Tech_to_enter_feedback} //add this in labels
        />
      </Form.Group>
      <span>
        <ImageUpload
          mandatoryTitle={
            menuItem === labels.Quality && menuItem2 === labels.Technician ? (
              <React.Fragment>
                {labels.Attach_Images} only : {labels.Attach_Img_MaxSize}
                <span style={{ color: "red" }}>*</span>
              </React.Fragment>
            ) : (
              `${labels.Attach_Images} only : ${labels.Attach_Img_MaxSize}`
            )
          }
          image={files}
          onImageChange={setFiles}
        />
      </span>
    </div>
  );
}
