import { useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import LoaderGif from "../../resources/images/loader.webp";
import ErrorIcon from "../../resources/images/icons/ErrorIcon.svg";
import NeighborhoodTableHead from "./NeighbourhoodTableHead";
import {
  secToTime,
  isNull,
  getChannelsCountSpan,
  checkPartialService,
} from "../reusable/Util";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import NeighbourhoodClient from "../../network/httpclient/neighbourhood/NeighbourhoodClient";
import CustomPopUp from "../reusable/CustomPopUp";
import CMSummary from "../cable-modem/CmSummary";
import CertiExceptionCont from "../certification/CertiExceptionCont";
import DocsisUpChData from "../cable-modem/upstream/DocsisUpChData";
import DocsisChInfo from "../cable-modem/downstream/DocsisChInfo";
import NeighCmDSCh from "./NeighCmDSCh";
import NeighCmUSCh from "./NeighCmUSCh";
import NeighPartialService from "./NeighPartialService";
import CMFlapsDetails from "../cable-modem/CMFlapsDetails";

const NeighbourhoodTable = (props: any) => {
  const { neighboursDevices } = useSelector(
    (state: any) => ({
      neighboursDevices:
        state.AccountState.acctSlice.neighbourhood.neighDataList,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const [neighDevicesFlag, setNeighDevicesFlag] = useState(true);
  const [popUpFlag, setPopUpFlag] = useState(false);
  const [popUpHeader, setPopUpHeader] = useState("");
  const [popUpBody, setPopUpBody] = useState(<div></div>);
  useEffect(() => {
    if (
      neighboursDevices !== undefined &&
      neighboursDevices?.length !== 0 &&
      neighDevicesFlag
    ) {
      neighboursDevices?.map((d: any) => {
        if (d?.cmData?.summary?.data === undefined) {
          dispatch(
            NeighbourhoodClient.getNeighCMSummary(d?.deviceKey, d?.cmMac)
          );
        }
        if (d?.cmData?.docsis?.data === undefined) {
          dispatch(
            NeighbourhoodClient.getNeighCMDocsisInfo(d?.deviceKey, d?.cmMac)
          );
        }
        if (d?.cmData?.flaps?.data === undefined) {
          dispatch(NeighbourhoodClient.getNeighFlaps(d?.deviceKey, d?.cmMac));
        }
      });
      setNeighDevicesFlag(false);
    }
  }, [neighboursDevices]);
  const handleSummaryClick = (summaryData: any) => {
    setPopUpHeader("CM SUMMARY");
    setPopUpBody(
      <CertiExceptionCont dataObj={summaryData} sectionName="CM Summary">
        <CMSummary cmSummarydata={summaryData} />
      </CertiExceptionCont>
    );
    setPopUpFlag(true);
  };
  const handleFlapsClick = (flapsData: any) => {
    setPopUpHeader("CM FLAPS DETAILS");
    setPopUpBody(
      <CertiExceptionCont dataObj={flapsData} sectionName="CM Flaps">
        <CMFlapsDetails flapsInfo={flapsData} />
      </CertiExceptionCont>
    );
    setPopUpFlag(true);
  };
  const handleDocsisClick = (docsisData: any, channelType: string) => {
    switch (channelType) {
      case "upStream30":
        if (
          docsisData?.docsis30?.upstream !== undefined &&
          docsisData?.docsis30?.upstream?.length !== 0
        ) {
          setPopUpHeader("CM DOCSIS 3.0 UPSTREAM");
          setPopUpBody(
            <CertiExceptionCont dataObj={docsisData} sectionName="CM Docsis">
              <DocsisUpChData
                docsis30UsChannels={docsisData?.docsis30?.upstream}
              />
            </CertiExceptionCont>
          );
          setPopUpFlag(true);
        }
        break;
      case "downStream30":
        if (
          docsisData?.docsis30?.downstream !== undefined &&
          docsisData?.docsis30?.downstream?.length !== 0
        ) {
          setPopUpHeader("CM DOCSIS 3.0 DOWNSTREAM");
          setPopUpBody(
            <CertiExceptionCont dataObj={docsisData} sectionName="CM Docsis">
              <DocsisChInfo
                docsis30dsChannels={docsisData?.docsis30?.downstream}
              />
            </CertiExceptionCont>
          );
          setPopUpFlag(true);
        }
        break;
      case "upStream31":
        if (
          docsisData?.ofdm?.upstream !== undefined &&
          docsisData?.ofdm?.upstream?.length !== 0
        ) {
          setPopUpHeader("CM OFDM UPSTREAM");
          setPopUpBody(
            <CertiExceptionCont dataObj={docsisData} sectionName="CM Docsis">
              <NeighCmUSCh cmDocsisInfo={docsisData} />
            </CertiExceptionCont>
          );
          setPopUpFlag(true);
        }
        break;
      case "downStream31":
        if (
          docsisData?.ofdm?.downstreamChannels !== undefined &&
          docsisData?.ofdm?.downstreamChannels?.length !== 0
        ) {
          setPopUpHeader("CM OFDM DOWNSTREAM");
          setPopUpBody(
            <CertiExceptionCont dataObj={docsisData} sectionName="CM Docsis">
              <NeighCmDSCh cmDocsisInfo={docsisData} />
            </CertiExceptionCont>
          );
          setPopUpFlag(true);
        }
        break;
      case "ERROR":
        setPopUpHeader("CM DOCSIS");
        setPopUpBody(
          <CertiExceptionCont dataObj={docsisData} sectionName="CM Docsis">
            <></>
          </CertiExceptionCont>
        );
        setPopUpFlag(true);
        break;
    }
  };
  const handlePartialClick = (data: any) => {
    setPopUpHeader("CM PARTIAL SERVICE");
    setPopUpBody(
      <CertiExceptionCont dataObj={data} sectionName="CM Docsis">
        <NeighPartialService partialServiceData={data?.partialService} ofdmDownStreamChannelsData={data?.ofdm?.downstreamChannels} />
      </CertiExceptionCont>
    );
    setPopUpFlag(true);
  };

  const getCellComponent = (
    loading: boolean,
    data: any,
    status: string,
    cellType: string,
    apiType: string
  ) => {
    if (!loading && status === "OK") {
      switch (cellType) {
        case "upTime":
          return <span>{secToTime(isNull(data?.uptime))}</span>;
          break;
        case "cmtsUptime":
          return <span>{secToTime(isNull(data?.cmtsCmRegisteredTime))}</span>;
          break;
        case "upStream30":
          if (data?.docsis30?.upstream) {
            return (
              <a
                className="neighLinkCell"
                onClick={() => handleDocsisClick(data, cellType)}
              >
                {getChannelsCountSpan(data?.docsis30?.upstream, cellType)}
              </a>
            );
          } else {
            return <span>-</span>;
          }
          break;
        case "downStream30":
          if (data?.docsis30?.downstream) {
            return (
              <a
                className="neighLinkCell"
                onClick={() => handleDocsisClick(data, cellType)}
              >
                {getChannelsCountSpan(data?.docsis30?.downstream, cellType)}
              </a>
            );
          } else {
            return <span>-</span>;
          }
          break;
        case "upStream31":
          if (data?.ofdm?.upstream) {
            return (
              <a
                className="neighLinkCell"
                onClick={() => handleDocsisClick(data, cellType)}
              >
                {getChannelsCountSpan(data?.ofdm?.upstream, cellType)}
              </a>
            );
          } else {
            return <span>-</span>;
          }
          break;
        case "downStream31":
          if (data?.ofdm?.downstreamChannels) {
            return (
              <a
                className="neighLinkCell"
                onClick={() => handleDocsisClick(data, cellType)}
              >
                {getChannelsCountSpan(data?.ofdm?.downstreamChannels, cellType)}
              </a>
            );
          } else {
            return <span>-</span>;
          }
          break;
        case "partialService":
          if (data?.partialService) {
            let partialServiceFlag = checkPartialService(
              data?.partialService,
              status
            );
            return (
              <a
                className="neighLinkCell"
                onClick={() => handlePartialClick(data)}
              >
                {partialServiceFlag ? (
                  <FaCheckCircle color="#00985f" />
                ) : (
                  <img style={{ height: "14px" }} src={ErrorIcon} />
                )}
              </a>
            );
          } else {
            return <span>-</span>;
          }
          break;
      }
    } else if (!loading && status === "ERROR") {
      switch (apiType) {
        case "summary":
          return <span className="failState">ERROR</span>;
          break;
        case "docsis":
          return (
            <a
              className="neighLinkCell"
              onClick={() => handleDocsisClick(data, "ERROR")}
            >
              <span className="failLinkState">ERROR</span>
            </a>
          );
          break;
      }
    } else {
      return <img style={{ width: "20%" }} src={LoaderGif} />;
    }
  };

  return (
    <div className="neighTableCont">
      <table className="neighTable">
        <NeighborhoodTableHead />
        <tbody className="nTabBody">
          {neighboursDevices?.map((d: any, i: number) => {
            return (
              <tr key={i}>
                <td>{d.homeAddress}</td>
                <td>
                  {d?.cmData?.summary?.isLoading ? (
                    <img style={{ width: "20%" }} src={LoaderGif} />
                  ) : (
                    <a
                      className="neighLinkCell"
                      onClick={() =>
                        handleSummaryClick(d?.cmData?.summary?.data)
                      }
                    >
                      {d?.cmData?.summary?.status === "OK" ? (
                        <>
                          {d?.cmData?.summary?.data?.uptime > 0 ? (
                            <>
                              <span className="passLinkState">{d.cmMac}</span>
                              <FaCheckCircle color="#00985f" />
                            </>
                          ) : (
                            <>
                              <span className="failLinkState">{d.cmMac}</span>
                              <img style={{ height: "14px" }} src={ErrorIcon} />
                            </>
                          )}
                        </>
                      ) : d?.cmData?.summary?.status === "ERROR" ? (
                        <>
                          <span className="failLinkState">{d.cmMac}-ERROR</span>
                          {/*  <img style={{ height: "14px" }} src={ErrorIcon} /> */}
                        </>
                      ) : (
                        <img style={{ width: "20%" }} src={LoaderGif} />
                      )}
                    </a>
                  )}
                </td>
                <td>{d.cmModel}</td>
                <td>
                  {getCellComponent(
                    d?.cmData?.summary?.isLoading,
                    d?.cmData?.summary?.data,
                    d?.cmData?.summary?.status,
                    "upTime",
                    "summary"
                  )}
                </td>
                <td>
                  {getCellComponent(
                    d?.cmData?.summary?.isLoading,
                    d?.cmData?.summary?.data,
                    d?.cmData?.summary?.status,
                    "cmtsUptime",
                    "summary"
                  )}
                </td>
                <td>
                  {getCellComponent(
                    d?.cmData?.docsis?.isLoading,
                    d?.cmData?.docsis?.data,
                    d?.cmData?.docsis?.status,
                    "upStream30",
                    "docsis"
                  )}
                </td>
                <td>
                  {getCellComponent(
                    d?.cmData?.docsis?.isLoading,
                    d?.cmData?.docsis?.data,
                    d?.cmData?.docsis?.status,
                    "downStream30",
                    "docsis"
                  )}
                </td>
                <td>
                  {getCellComponent(
                    d?.cmData?.docsis?.isLoading,
                    d?.cmData?.docsis?.data,
                    d?.cmData?.docsis?.status,
                    "upStream31",
                    "docsis"
                  )}
                </td>
                <td>
                  {getCellComponent(
                    d?.cmData?.docsis?.isLoading,
                    d?.cmData?.docsis?.data,
                    d?.cmData?.docsis?.status,
                    "downStream31",
                    "docsis"
                  )}
                </td>
                <td>
                  {getCellComponent(
                    d?.cmData?.docsis?.isLoading,
                    d?.cmData?.docsis?.data,
                    d?.cmData?.docsis?.status,
                    "partialService",
                    "docsis"
                  )}
                </td>
                <td>
                  {d?.cmData?.flaps?.isLoading ? (
                    <img style={{ width: "20%" }} src={LoaderGif} />
                  ) : (
                    <span>
                      {d?.cmData?.flaps?.status === "OK" ? (
                        <span>
                          {d?.cmData?.flaps?.data?.insertionFails !== undefined
                            ? d?.cmData?.flaps?.data?.insertionFails
                            : "-"}
                        </span>
                      ) : d?.cmData?.flaps?.status === "ERROR" ? (
                        <span className="failLinkState" onClick={() => handleFlapsClick(d?.cmData?.flaps?.data)}>ERROR</span>
                      ) : (
                        <></>
                      )
                      }
                      </span> 
                      )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <CustomPopUp
        showVal={popUpFlag}
        headerText={popUpHeader}
        bodyElement={popUpBody}
        handleClose={() => setPopUpFlag(false)}
      />
    </div>
  );
};

export default NeighbourhoodTable;
