import React, { CSSProperties } from "react";

import LoaderGif from "../../resources/images/loader.webp";

interface Props {
  style?: CSSProperties;
  animationAlignement?: "center" | "none";
}

export default function ActivityIndicator(props: Props) {
  switch (props.animationAlignement) {
    case "center":
      return (
        <div className="diagAPICont">
          <img
            className="loaderImg"
            src={LoaderGif}
            style={props.style}
            alt="Activity Indicator GIF"
          />
        </div>
      );
    case "none":
      return (
        <img
          className="loaderImg"
          src={LoaderGif}
          style={props.style}
          alt="Activity Indicator GIF"
        />
      );
    default:
      return (
        <div className="diagAPICont">
          <img
            className="loaderImg"
            src={LoaderGif}
            style={props.style}
            alt="Activity Indicator GIF"
          />
        </div>
      );
  }
}
