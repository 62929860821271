import React from "react";
import { Divider } from "@material-ui/core";

import "../../../../design-tokens/forms/SR.scss";
import AppLabels from "../../../../constants/App_Labels";
import { changeTimeFormat } from "../../../reusable/Util";
import ConfigConst from "../../../../constants/ConfigConst";
import srFormLabels from "../srFormLabels";

interface Props {
  data: any;
}

export default function ViewTechDetails({ data }: Props) {
  const formData = data?.formData;
  const labels = AppLabels() as any;
  const srLabels = srFormLabels() as any;
  const workOrderID =
    data?.workOrderId === ConfigConst.NO_WORK_ORDER
      ? labels.No_Work_Order
      : data?.workOrderId;

  return (
    <div className="viewSRContainer">
      <div>
        <label className="titleLabel">{labels.ACCOUNT_NUMBER}:</label>
        <label className="textLabel">{data?.accountNumber}</label>
      </div>
      <div>
        <label className="titleLabel">{labels.Work_Order_ID}:</label>
        <label className="textLabel">{workOrderID}</label>
      </div>
      {formData?.orderType && (
        <div>
          <label className="titleLabel">{labels.Order_Type}:</label>
          <label className="textLabel">{formData?.orderType}</label>
        </div>
      )}
      <div>
        <label className="titleLabel">{"Order Date"}:</label>
        <label className="textLabel">
          {changeTimeFormat(data?.updatedDate)}
        </label>
      </div>
      <div>
        <label className="titleLabel">{"Created By"}:</label>
        <label className="textLabel">{data?.createdBy}</label>
      </div>
      <Divider style={{ margin: "10px 0px 20px 0px" }} />
      <div>
        <label className="titleLabel">{"Impacted Services"}:</label>
      </div>
      {formData?.impactedServices?.map((e: any, i: number) => (
        <div key={i}>
          <label className="textLabel">{labels?.[e]}</label>
        </div>
      ))}
      <Divider style={{ margin: "15px 0px 20px 0px" }} />
      {formData?.tapDetails && (
        <div>
          <label className="titleLabel">{labels.TAP}:</label>
          <label className="textLabel">{formData?.tapDetails}</label>
        </div>
      )}
      {formData?.cseDetails && (
        <div>
          <label className="titleLabel">{labels.CSE}:</label>
          <label className="textLabel">{formData?.cseDetails}</label>
        </div>
      )}
      {formData?.humDetails && (
        <div>
          <label className="titleLabel">{labels.HUM}:</label>
          <label className="textLabel">{formData?.humDetails}</label>
        </div>
      )}
      {formData?.snrDetails && (
        <div>
          <label className="titleLabel">{labels.SNR.toUpperCase()}:</label>
          <label className="textLabel">{formData?.snrDetails}</label>
        </div>
      )}
      <div>
        <label className="titleLabel">{labels.Senior_Order_Details}:</label>
        <label className="textLabel">{formData?.seniorOrderDetails}</label>
      </div>
      <Divider style={{ margin: "15px 0px 20px 0px" }} />
      <div>
        <label className="titleLabel">{labels.Customer_Name}:</label>
        <label className="textLabel">{formData?.customerName}</label>
      </div>
      <div>
        <label className="titleLabel">{srLabels.CONTACT_NUM}:</label>
        <label className="textLabel">{formData?.contactNumber}</label>
      </div>
      <Divider style={{ margin: "15px 0px 20px 0px" }} />
      <div>
        <label className="titleLabel">{srLabels.TECHNICIAN_EMAIL}:</label>
        <label className="textLabel">{data?.technicianId}</label>
      </div>
      <div>
        <label className="titleLabel">
          {srLabels.SENIOR_TECHNICIAN_EMAIL}:
        </label>
        <label className="textLabel">{formData?.seniorTechnician}</label>
      </div>
      <div>
        <label className="titleLabel">
          {srLabels.TECHNICIAN_MANAGER_EMAIL}:
        </label>
        <label className="textLabel">{formData?.techManager}</label>
      </div>
    </div>
  );
}
