import { useState } from "react";
import AppLabels from "../../constants/App_Labels";

import FullScreenPopUp from "../reusable/FullScreenPopUp";
import { NodeTeleTab } from "../../components/telemetry/NodeTeleTab";

import { GetTelemetryDetails } from "../../get-api/GetResponse";
import { MdFullscreen } from "react-icons/md";
import { GetTelemetryProps } from "../../topology/util/reduxFunctions/getTopologyState";
import { Fullscreen } from "react-bootstrap-icons";

export function NodeTelemetryTab(props: any) {
  const { data, selectdSmt } = props;
  const labels = AppLabels();

  const { mlData } = GetTelemetryProps();

  const [fullScreen, setFullScreen] = useState(false);

  const handleFullScreen = () => {
    setFullScreen(true);
  };

  return (
    <>
      <div className="nodeTelemetryCont">
        <div>
          <FullScreenPopUp
            open={fullScreen}
            handleClose={() => {
              setFullScreen(false);
            }}
            childComponent={
              <>
                <NodeTeleTab
                  data={data}
                  fullScreen={true}
                  selectdSmt={selectdSmt}
                />
              </>
            }
            pageName={labels.NODE_TELE_DETAILS}
          />
        </div>
        <div>
          <NodeTeleTab
            data={data}
            selectdSmt={selectdSmt}
            handleFullScreen={handleFullScreen}
          />
        </div>
      </div>
    </>
  );
}
