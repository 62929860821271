import { AllCertificationState } from "../../../states/account/certifications/AllCertificationState";
import {
  GET_ALL_CERTIFICATIONS_BEGIN,
  GET_ALL_CERTIFICATIONS_FAILURE,
  GET_ALL_CERTIFICATIONS_SUCCESS,
  GetAllCertificationsActionTypes,
} from "../../../types/account/certifications/AllCertification";

const initialState: AllCertificationState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function AllCertificationReducer(
  state = initialState,
  action: GetAllCertificationsActionTypes
): AllCertificationState {
  switch (action.type) {
    case GET_ALL_CERTIFICATIONS_BEGIN:
      return {
        isLoading: true,
      };
    case GET_ALL_CERTIFICATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case GET_ALL_CERTIFICATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}
