import { Marker } from "@react-google-maps/api";
import { useMemo, useState } from "react";

import { mapElementStyles } from "../../../../config/mapItemsConfig";
import { GetClickOrdersProps } from "../../../../util/reduxFunctions/getTopologyState";
import "../../../../styles/drawer/Click.scss";
import appConst from "../../../../constants/appConst";
import {
  getTransparentSymbol,
  markerToString,
} from "../../../../util/utilFuncs";
import TopologySymbols from "../../../reusable/TopologySymbols";
import colors from "../../../../config/colors";
import ActiveClickClusterTable from "./ActiveClickClusterTable";

interface Props {
  onMarkerPress: (arg0: any, arg1: string) => void;
}

export default function ClickOrdersMarker({ onMarkerPress }: Props) {
  const { data, clickStatusFlt, isOrdersVisible } = GetClickOrdersProps();

  const [showCluster, setShowCluster] = useState(false);
  const [activeClusterData, setActiveClusterData] = useState<any>();

  const onMarkerClick = (data: any) => {
    onMarkerPress(data, appConst.click);
    setShowCluster(false);
  };

  const onClusterIconClick = (data: any) => {
    setActiveClusterData(data);
    setShowCluster(true);
  };

  const clickMarkersData = useMemo(() => {
    let groupedDataList: any = [];
    if (data) {
      data?.features?.map((f: any) => {
        if (
          clickStatusFlt.filter(
            (s: any) => s.label === f?.properties?.data?.status && s.checked
          )?.[0]?.label
        ) {
          const coords = f?.geometry?.coordinates;
          let groupIndex = groupedDataList?.findIndex(
            (group: any) =>
              group?.lat === coords?.[1] && group?.lng === coords?.[0]
          );
          if (groupIndex >= 0) {
            groupedDataList[groupIndex].clusterData.push(f);
          } else {
            groupedDataList.push({
              lat: coords?.[1],
              lng: coords?.[0],
              clusterData: [f],
            });
          }
        }
      });
    }
    return groupedDataList;
  }, [data, clickStatusFlt]);

  return (
    <>
      {isOrdersVisible &&
        clickMarkersData &&
        clickMarkersData?.length > 0 &&
        clickMarkersData?.map((groupData: any) => {
          if (groupData && groupData?.clusterData?.length === 1) {
            let data = groupData?.clusterData?.[0];
            return (
              <Marker
                key={data.properties?.featureId}
                position={{
                  lat: data?.geometry?.coordinates?.[1],
                  lng: data?.geometry?.coordinates?.[0],
                }}
                label={{
                  text: data?.properties?.data?.orderTypeName,
                  className: "clickMarkerLabel",
                  color: `var(--white)`,
                  fontSize: "12px",
                }}
                icon={{
                  url: getTransparentSymbol(),
                  anchor: new google.maps.Point(
                    mapElementStyles?.common?.anchor?.x,
                    mapElementStyles?.common?.anchor?.y
                  ),
                }}
                onClick={() => onMarkerClick(data)}
              />
            );
          } else {
            return (
              <Marker
                key={`clickClusterMarker${groupData?.lat}${groupData?.lng}`}
                position={{
                  lat: groupData?.lat,
                  lng: groupData?.lng,
                }}
                label={{
                  text: `${groupData?.clusterData?.length}`,
                  className: "clickClusterMarkerLabel",
                  color: `var(--white)`,
                  fontSize: "12px",
                }}
                icon={{
                  url: markerToString(
                    <TopologySymbols
                      name={"rectangle"}
                      size={35}
                      color={colors.clickServiceShadow}
                    />
                  ),
                  anchor: new google.maps.Point(
                    mapElementStyles?.common?.anchor?.x,
                    mapElementStyles?.common?.anchor?.y
                  ),
                }}
                onClick={() => onClusterIconClick(groupData)}
              />
            );
          }
        })}

      <ActiveClickClusterTable
        activeClusterData={activeClusterData}
        showCluster={showCluster}
        setShowCluster={setShowCluster}
        onMarkerPress={(d: any) => onMarkerPress(d, appConst.click)}
      />
    </>
  );
}
