import { Card } from "react-bootstrap";
import { FiRefreshCw } from "react-icons/fi";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ONTClient from "../../network/httpclient/ont/ONTClient";
import DiagContainer from "../reusable/DiagContainer";
import ExceptionBox from "../reusable/ExceptionBox";
import { isNull } from "../reusable/Util";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const ONTProfileInfo = (props: any) => {
  const { selectedDevice } = props;
  const dispatch = useDispatch();
  const { ontProfileLoading, ontProfileInfo, ontProfileError } = useSelector(
    (state: any) => ({
      ontProfileLoading: state.AccountState.ONTProfileInfo.isLoading,
      ontProfileInfo: state.AccountState.ONTProfileInfo.data,
      ontProfileError: state.AccountState.ONTProfileInfo.error,
    }),
    shallowEqual
  );
  const refreshData = () => {
    dispatch(ONTClient.getONTProfile(selectedDevice.serialNumber));
  };

  return (
    <>
      <div className="refreshCont">
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip-2">Refresh</Tooltip>}
        >
          <button className="refreshBtn" onClick={() => refreshData()}>
            <FiRefreshCw />
          </button>
        </OverlayTrigger>
      </div>
      <DiagContainer
        isLoading={ontProfileLoading}
        data={ontProfileInfo}
        error={ontProfileError}
        handleRefresh={() => refreshData()}
      >
        {ontProfileInfo !== undefined ? (
          <div className="gwDevicesCont">
            <Card className="dataCard">
              <Card.Body>
                <table className="dataTableDetails">
                  {ontProfileInfo !== undefined &&
                  ontProfileInfo?.length !== 0 ? (
                    <>
                      <tr>
                        <th>CLASS OF SERVICE</th>

                        <td>{isNull(ontProfileInfo.classOfService)}</td>
                      </tr>
                      <tr>
                        <th>DHCP CRITERIA</th>

                        <td>{isNull(ontProfileInfo.dhcpCriteria)}</td>
                      </tr>
                      <tr>
                        <th>MODEL</th>
                        <td>{isNull(ontProfileInfo.model)}</td>
                      </tr>
                      <tr>
                        <th>MAKE</th>
                        <td>{isNull(ontProfileInfo.make)}</td>
                      </tr>
                      <tr>
                        <th>CUSTOMER ID</th>
                        <td>{isNull(ontProfileInfo.customerId)}</td>
                      </tr>
                      <tr>
                        <th>SERIAL NUMBER</th>
                        <td>{isNull(ontProfileInfo.serialNumber)}</td>
                      </tr>
                      <tr>
                        <th>MODIFIED BY</th>
                        <td>{isNull(ontProfileInfo.modifiedBy)}</td>
                      </tr>
                      <tr>
                        <th>MODIFIED DATE</th>
                        <td>{isNull(ontProfileInfo.modifiedDate)}</td>
                      </tr>
                      <tr>
                        <th>ME NAME</th>
                        <td>{isNull(ontProfileInfo.meName)}</td>
                      </tr>
                      <tr>
                        <th>DOWN BAND</th>
                        <td>{isNull(ontProfileInfo.DOWN_BAND)}</td>
                      </tr>
                      <tr>
                        <th>TIER</th>
                        <td>{isNull(ontProfileInfo.TIER)}</td>
                      </tr>
                      <tr>
                        <th>UP BAND</th>
                        <td>{isNull(ontProfileInfo.UP_BAND)}</td>
                      </tr>
                      <tr>
                        <th>IPTV ENABLED</th>
                        <td>{isNull(ontProfileInfo.IPTV_ENABLED)}</td>
                      </tr>
                      <tr>
                        <th>RHP ENABLED</th>
                        <td>{isNull(ontProfileInfo.RHP_ENABLED)}</td>
                      </tr>
                    </>
                  ) : (
                    <div className="diagErrorCont">
                      <ExceptionBox
                        headerText="No Data to display"
                        bodyText="Please try refreshing if data is missing."
                        primaryBtn={true}
                        secBtn={false}
                        handleRefresh={() => refreshData()}
                      />
                    </div>
                  )}
                </table>
              </Card.Body>
            </Card>
          </div>
        ) : (
          <div className="diagErrorCont">
            <ExceptionBox
              headerText="No Data to display"
              bodyText="Please try refreshing if data is missing."
              primaryBtn={true}
              secBtn={false}
              handleRefresh={() => refreshData()}
            />
          </div>
        )}
      </DiagContainer>
    </>
  );
};

export default ONTProfileInfo;
