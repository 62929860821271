import { GWDevice } from "../../../../models/account/diagnostics/cm/GWDevice";

import {
  GW_DEVICES_BEGIN,
  GW_DEVICES_SUCCESS,
  GW_DEVICES_FAILURE,
  GWDeviceActionTypes,
} from "../../../../types/account/diagnostics/cm/GWDevices";

export function gwDeviceBegin(): GWDeviceActionTypes {
  return {
    type: GW_DEVICES_BEGIN,
  };
}

export function gwDeviceSuccess(gwDevice: GWDevice): GWDeviceActionTypes {
  return {
    type: GW_DEVICES_SUCCESS,
    payload: gwDevice,
  };
}

export function gwDeviceFailure(error: Error): GWDeviceActionTypes {
  return {
    type: GW_DEVICES_FAILURE,
    payload: error,
  };
}
