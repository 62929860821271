import React, { useEffect, useState } from "react";
import { AppBar, Tabs, Tab, Box } from "@material-ui/core";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import OverviewTab from "../../components/overview/OverviewTab";
import DiagnosticsTab from "../../components/diagnostics/DiagnosticsTab";
import CertificationTab from "../../components/certification/CertificationTab";
import AppLabels from "../../constants/App_Labels";
import { ResetActiveCerti } from "../../store/actions/account/certifications/ActiveCertificationAction";
import ConfirmPopUp from "../../components/reusable/ConfirmPopUp";
import NeighbourhoodTab from "../../components/neighbourhood/NeighbourhoodTab";
import NodeTab from "../../components/node/NodeTab";
import TowerViewTab from "../../components/towerview/TowerViewTab";
import { SetActiveSubTabIndex } from "../../store/actions/account/accountInfo/ActiveAccountAction";
import HistoryTab from "../../components/account/historyTab/HistoryTab";
import { GetTechInfo } from "../../get-api/GetResponse";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function AccountPageTabs(props: any) {
  const {
    serviceAddress,
    handleAddressSelect,
    data,
    refreshPods,
    macAddress,
    device,
    isPodRequired,
  } = props;
  const labels = AppLabels();
  const dispatch = useDispatch();
  const { isMaintenanceRoleAvailable } = GetTechInfo();

  const { certiData, accType, acSubTabIndex } = useSelector(
    (state: any) => ({
      certiData: state.AccountState.Certification.ActiveCertiInfo.certiInfo,
      accType: state.AccountState.ActiveAccountDetails.accountType,
      acSubTabIndex: state.AccountState.ActiveAccountDetails.activeSubTabIndex,
    }),
    shallowEqual
  );
  const [tempIndex, setTempIndex] = useState(0);
  const [showPOPUp, setShowPOPUp] = useState(false);
  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (value === 2 && certiData !== undefined) {
      setTempIndex(newValue);
      setShowPOPUp(true);
    } else {
      setValue(newValue);
    }
    dispatch(SetActiveSubTabIndex(newValue));
  };

  useEffect(() => {
    if (acSubTabIndex !== undefined) {
      setValue(acSubTabIndex);
    }
    // if (isMaintenanceRoleAvailable && acSubTabIndex && acSubTabIndex === 1) {
    //   dispatch(SetActiveSubTabIndex(acSubTabIndex));
    // }
  }, [acSubTabIndex, data]);

  const handleConfirm = () => {
    setValue(tempIndex);
    dispatch(ResetActiveCerti());
    setShowPOPUp(false);
  };

  return (
    <div>
      <AppBar position="static" className="accTabsBar">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label={labels.OVERVIEW.toUpperCase()} {...a11yProps(0)} />
          <Tab label={labels.DIAGNOSTICS.toUpperCase()} {...a11yProps(1)} />
          <Tab label="CERTIFICATIONS" {...a11yProps(2)} />
          {accType === "DOCSIS" ? (
            <Tab label="NEIGHBOURHOOD" {...a11yProps(3)} />
          ) : accType === "FWA" ? (
            <Tab label="TOWER VIEW" {...a11yProps(3)} />
          ) : accType === "GPON" ? (
            <Tab label="NODE" {...a11yProps(3)} />
          ) : null}
          <Tab label={labels.HISTORY} {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <OverviewTab
          serviceAddress={serviceAddress}
          handleAddressSelect={handleAddressSelect}
          refreshPods={refreshPods}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DiagnosticsTab
          macAddress={macAddress}
          device={device}
          isPodRequired={isPodRequired}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <CertificationTab />
      </TabPanel>
      {accType === "DOCSIS" ? (
        <TabPanel value={value} index={3}>
          <NeighbourhoodTab />
        </TabPanel>
      ) : accType === "FWA" ? (
        <TabPanel value={value} index={3}>
          <TowerViewTab />
        </TabPanel>
      ) : accType === "GPON" ? (
        <TabPanel value={value} index={3}>
          <NodeTab />
        </TabPanel>
      ) : null}
      <TabPanel value={value} index={4}>
        <HistoryTab />
      </TabPanel>
      <ConfirmPopUp
        showVal={showPOPUp}
        headerText="WARNING"
        bodyHeadText={labels.ARE_YOU_SURE_TO_PROCEED}
        bodyText="You are trying to abort midway, the certification will stay in the InProgress status in the list. You can continue later from your last saved step."
        cancelText="No"
        confirmText="Yes"
        handleConfirm={() => handleConfirm()}
        handleClose={() => setShowPOPUp(false)}
      />
    </div>
  );
}
