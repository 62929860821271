import { AccountDetails } from "../../../models/account/accountInfo/AccountDetails";
import { Device } from "../../../models/account/accountInfo/Device";
import {
  SET_ACTIVE_ACCOUNT_DETAILS,
  SET_SELECTED_SERVICE_ADDRESS,
  SORTED_DEVICE_LIST,
  SET_ACCOUNT_TYPE,
  SET_ACTIVE_SUB_TAB_INDEX,
  SET_GOOGLE_API_KEY,
  SET_WHT_FLAG,
  ENABLE_WHT_FLAG,
  SET_FRIENDLYNAME,
  SET_FAILED_CODEWORDS,
  SET_DEVICE_STATUS,
  SET_SCQAM_FLAG,
  RESET_APP,
  RESET_ACC,
  SET_SELECTED_SA,
  SET_CODEWORD_VALUES,
  SET_ACTIVE_ACC_SEARCH_VALUE,
  SET_DEVICE_SEARCH_VALUE,
  SET_DEVICE_MAC_ADDRESS_SEARCH_VALUE,
  ActiveAccountActionTypes,
} from "../../../types/account/accountInfo/ActiveAccDetails";

export function SetActiveAccountDetails(
  AccountDetails: AccountDetails
): ActiveAccountActionTypes {
  return {
    type: SET_ACTIVE_ACCOUNT_DETAILS,
    payload: AccountDetails,
  };
}
export function SetAccountType(accountType: String): ActiveAccountActionTypes {
  return {
    type: SET_ACCOUNT_TYPE,
    payload: accountType,
  };
}
export function SetActiveSubTabIndex(
  subTabIndex: number
): ActiveAccountActionTypes {
  return {
    type: SET_ACTIVE_SUB_TAB_INDEX,
    payload: subTabIndex,
  };
}
export function SetFriendlyName(
  devkey: string,
  dcateg: string,
  friendname: string
): ActiveAccountActionTypes {
  return {
    type: SET_FRIENDLYNAME,
    devicekey: devkey,
    deviceCategory: dcateg,
    friendlyName: friendname,
  };
}
export function SetGApiKey(gKey: string): ActiveAccountActionTypes {
  return {
    type: SET_GOOGLE_API_KEY,
    payload: gKey,
  };
}
export function SetWHTFlag(whtFlag: boolean): ActiveAccountActionTypes {
  return {
    type: SET_WHT_FLAG,
    payload: whtFlag,
  };
}
export function EnableWHTFlag(whtFlag: boolean): ActiveAccountActionTypes {
  return {
    type: ENABLE_WHT_FLAG,
    payload: whtFlag,
  };
}
export function SetSelectedServiceAddress(
  serviceAddress: String
): ActiveAccountActionTypes {
  return {
    type: SET_SELECTED_SERVICE_ADDRESS,
    payload: serviceAddress,
  };
}
export function SetSelectedSA(serviceAddress: any): ActiveAccountActionTypes {
  return {
    type: SET_SELECTED_SA,
    payload: serviceAddress,
  };
}
export function AccountSortedDevices(
  SortedDevices: Device[]
): ActiveAccountActionTypes {
  return {
    type: SORTED_DEVICE_LIST,
    payload: SortedDevices,
  };
}
export function SetDeviceStatus(
  devicekey: string,
  dCategory: string,
  status: string
): ActiveAccountActionTypes {
  return {
    type: SET_DEVICE_STATUS,
    devicekey: devicekey,
    deviceCategory: dCategory,
    deviceStatus: status,
  };
}

export function SetFailedCodewords(
  failedAttributes: any
): ActiveAccountActionTypes {
  return {
    type: SET_FAILED_CODEWORDS,
    payload: failedAttributes,
  };
}
export function SetCodewordValues(codeword: any): ActiveAccountActionTypes {
  return {
    type: SET_CODEWORD_VALUES,
    payload: codeword,
  };
}
export function SetSCQAMFLAG(scqam: boolean): ActiveAccountActionTypes {
  return {
    type: SET_SCQAM_FLAG,
    payload: scqam,
  };
}
export function AppReset(): ActiveAccountActionTypes {
  return {
    type: RESET_APP,
  };
}
export function AccReset(): ActiveAccountActionTypes {
  return {
    type: RESET_ACC,
  };
}

export function SetAccountSearchValue(
  accSearchValue: String
): ActiveAccountActionTypes {
  return {
    type: SET_ACTIVE_ACC_SEARCH_VALUE,
    payload: accSearchValue,
  };
}
export function SetDeviceSearchValue(
  deviceSearchValue: String
): ActiveAccountActionTypes {
  return {
    type: SET_DEVICE_SEARCH_VALUE,
    payload: deviceSearchValue,
  };
}
export function SetDeviceMacAddressSearchValue(
  deviceMacAddressSearchValue: String
): ActiveAccountActionTypes {
  return {
    type: SET_DEVICE_MAC_ADDRESS_SEARCH_VALUE,
    payload: deviceMacAddressSearchValue,
  };
}
