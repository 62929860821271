import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { STBSummaryState } from "../../../store/states/account/diagnostics/stb/STBSummaryState";
import {
  stbSummaryBegin,
  stbSummaryFailure,
  stbSummarySuccess,
} from "../../../store/actions/account/diagnostics/stb/STBSummaryAction";
import { STBSummary } from "../../../store/models/account/diagnostics/stb/STBSummary";
import { STBDocsis } from "../../../store/models/account/diagnostics/stb/STBDocsis";
import { STBCertiSummaryState } from "../../../store/states/account/certifications/stb/STBCertiSummaryState";
import {
  stbCertiSummaryBegin,
  stbCertiSummaryFailure,
  stbCertiSummarySuccess,
} from "../../../store/actions/account/certifications/stb/STBCertiSummaryAction";

import { formatMacAddress } from "../../../components/reusable/Util";
import {
  DecrementApiCallCounter,
  IncrementApiCallCounter,
  UpdateActiveCertiDevices,
} from "../../../store/actions/account/certifications/ActiveCertiDevicesAction";
import {
  DecrementDiagApiCallCounter,
  IncrementDiagApiCallCounter,
  UpdateActiveDiagDevices,
} from "../../../store/actions/account/diagnostics/ActiveDiagDevicesActions";

export default class STBClient {
  static getSTBSummary =
    (
      serialNum: string,
      stbMac: string
    ): ThunkAction<void, STBSummaryState, unknown, Action<string>> =>
    async (dispatch) => {
      let stbMAC = formatMacAddress(stbMac);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL + "diag/stb/" + stbMAC + "/summary",
      };

      dispatch(stbSummaryBegin());
      dispatch(IncrementDiagApiCallCounter(serialNum));
      HttpClient.get<STBSummary>(httpParameters)
        .then((stbSummary) => {
          dispatch(stbSummarySuccess(stbSummary));
          dispatch(UpdateActiveDiagDevices(serialNum, "summary", stbSummary));
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return stbSummary;
        })
        .catch((error) => {
          dispatch(stbSummaryFailure(error));
          dispatch(UpdateActiveDiagDevices(serialNum, "summary", error));
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return error;
        });
    };

  static getSTBCMSummary =
    (
      serialNum: string,
      stbCmMac: string
    ): ThunkAction<void, STBSummaryState, unknown, Action<string>> =>
    async (dispatch) => {
      let stbCmMAC = formatMacAddress(stbCmMac);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "diag/cablemodem/" +
          stbCmMAC +
          "/summary",
      };

      dispatch(IncrementDiagApiCallCounter(serialNum));
      HttpClient.get<any>(httpParameters)
        .then((stbSummary) => {
          dispatch(UpdateActiveDiagDevices(serialNum, "cmSummary", stbSummary));
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return stbSummary;
        })
        .catch((error) => {
          dispatch(UpdateActiveDiagDevices(serialNum, "cmSummary", error));
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return error;
        });
    };

  static getSTBInterfaces =
    (
      serialNum: string,
      stbCmMac: string
    ): ThunkAction<void, STBSummaryState, unknown, Action<string>> =>
    async (dispatch) => {
      let stbCmMAC = formatMacAddress(stbCmMac);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "diag/cablemodem/" +
          stbCmMAC +
          "/interface",
      };

      dispatch(IncrementDiagApiCallCounter(serialNum));
      HttpClient.get<any>(httpParameters)
        .then((stbSummary) => {
          dispatch(
            UpdateActiveDiagDevices(serialNum, "cmInterface", stbSummary)
          );
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return stbSummary;
        })
        .catch((error) => {
          dispatch(UpdateActiveDiagDevices(serialNum, "cmInterface", error));
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return error;
        });
    };

  static getSTBDocsis =
    (
      serialNum: string,
      cmMac: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let cmMAC = formatMacAddress(cmMac);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL + "diag/stb/" + cmMAC + "/docsis",
      };

      dispatch(IncrementDiagApiCallCounter(serialNum));
      HttpClient.get<STBDocsis>(httpParameters)
        .then((stbDocsis) => {
          dispatch(UpdateActiveDiagDevices(serialNum, "docsis", stbDocsis));
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return stbDocsis;
        })
        .catch((error) => {
          dispatch(UpdateActiveDiagDevices(serialNum, "docsis", error));
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return error;
        });
    };

  static getSTBCMDocsisEvents =
    (
      serialNum: string,
      cmMac: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let cmMAC = formatMacAddress(cmMac);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "diag/cablemodem/" +
          cmMAC +
          "/docsisevents",
      };

      dispatch(IncrementDiagApiCallCounter(serialNum));
      HttpClient.get<any>(httpParameters)
        .then((stbDocsisEvents) => {
          dispatch(
            UpdateActiveDiagDevices(serialNum, "docsisEvents", stbDocsisEvents)
          );
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return stbDocsisEvents;
        })
        .catch((error) => {
          dispatch(UpdateActiveDiagDevices(serialNum, "docsisEvents", error));
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return error;
        });
    };

  static getCertiSTBSummary =
    (
      serialNum: string,
      stbMac: string
    ): ThunkAction<void, STBCertiSummaryState, unknown, Action<string>> =>
    async (dispatch) => {
      let stbMAC = formatMacAddress(stbMac);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL + "diag/stb/" + stbMAC + "/summary",
      };

      dispatch(stbCertiSummaryBegin());
      dispatch(IncrementApiCallCounter(serialNum));

      HttpClient.get<STBSummary>(httpParameters)
        .then((stbSummary) => {
          dispatch(stbCertiSummarySuccess(stbSummary));
          dispatch(UpdateActiveCertiDevices(serialNum, "summary", stbSummary));
          dispatch(DecrementApiCallCounter(serialNum));
          return stbSummary;
        })
        .catch((error) => {
          dispatch(stbCertiSummaryFailure(error));
          dispatch(UpdateActiveCertiDevices(serialNum, "summary", error));
          dispatch(DecrementApiCallCounter(serialNum));
          return error;
        });
    };

  static getCertiSTBDocsis =
    (
      serialNum: string,
      cmMac: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let cmMAC = formatMacAddress(cmMac);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL + "diag/stb/" + cmMAC + "/docsis",
      };

      dispatch(IncrementApiCallCounter(serialNum));

      HttpClient.get<STBDocsis>(httpParameters)
        .then((stbDocsis) => {
          dispatch(UpdateActiveCertiDevices(serialNum, "docsis", stbDocsis));
          dispatch(DecrementApiCallCounter(serialNum));
          return stbDocsis;
        })
        .catch((error) => {
          dispatch(UpdateActiveCertiDevices(serialNum, "docsis", error));
          dispatch(DecrementApiCallCounter(serialNum));
          return error;
        });
    };
}
