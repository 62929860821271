import { Divider, Card } from "@material-ui/core";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Fragment } from "react";
import { FiPhoneCall } from "react-icons/fi";
import { GrMapLocation } from "react-icons/gr";

import { SetSelectedServiceAddress } from "../../store/actions/account/accountInfo/ActiveAccountAction";
import { isNull } from "../reusable/Util";
import AppLabels from "../../constants/App_Labels";

const CustOverView = (props: any) => {
  const dispatch = useDispatch();
  const {
    serviceAddress,
    handleAddressSelect,
    selectedSA,
    selectedServiceAddress,
  } = props;
  const labels = AppLabels();
  const { serviceAreaInfo } = useSelector(
    (state: any) => ({
      serviceAreaInfo: state.AccountState.ServiceAreaInfo.data,
    }),
    shallowEqual
  );
  let homeNum: any[] = [];

  selectedSA?.subscription?.map((sub: any) => {
    if (sub?.hasOwnProperty("deviceInfo")) {
      sub.deviceInfo?.map((deviceInfo: any) => {
        if (
          deviceInfo?.deviceCategory === "EMTA" ||
          deviceInfo?.deviceCategory === "IEMTA"
        )
          if (deviceInfo?.hasOwnProperty("phoneNumber")) {
            homeNum.push(deviceInfo.phoneNumber);
          }
      });
    }
  });
  const handleAddressChange = (e: React.MouseEvent, sa: String) => {
    e.preventDefault();
    dispatch(SetSelectedServiceAddress(sa));
    handleAddressSelect(sa);
  };

  const renderServiceAreaInfo = () => {
    if (
      selectedSA !== undefined &&
      selectedSA?.length !== 0 &&
      selectedSA?.hasOwnProperty("topology")
    ) {
      if (selectedSA.topology === "DOCSIS") {
        return (
          <section>
            <span>
              {labels.SMT} - {isNull(selectedSA.smtId)}
            </span>
            <span>
              {labels.SHUB} - {isNull(selectedSA.shubId)}
            </span>
            <span>
              {labels.PHUB} - {isNull(selectedSA.phubId)}
            </span>
          </section>
        );
      } else if (selectedSA.topology === "GPON") {
        if (
          serviceAreaInfo !== undefined &&
          serviceAreaInfo?.hasOwnProperty("serviceAddressInfo") &&
          serviceAreaInfo.serviceAddressInfo?.hasOwnProperty(
            "generalAddressInfo"
          )
        ) {
          return (
            <section>
              <span>
                {labels.HUB} {labels.HYPHEN}{" "}
                {isNull(
                  serviceAreaInfo.serviceAddressInfo.generalAddressInfo.gponHub
                )}
              </span>
              <span>
                {labels.NAP} {labels.HYPHEN}{" "}
                {isNull(
                  serviceAreaInfo.serviceAddressInfo.generalAddressInfo.gponNap
                )}
              </span>
              <span>
                {labels.NODE} {labels.HYPHEN}{" "}
                {isNull(
                  serviceAreaInfo.serviceAddressInfo.generalAddressInfo.gponNode
                )}
              </span>
            </section>
          );
        }
      }
      return (
        <section>
          <span>{labels.NA}</span>
        </section>
      );
    }
  };

  const otherAddress = serviceAddress.filter(
    (sa: any) => sa !== selectedServiceAddress
  );
  return (
    <Card className="custInfoCard">
      <div className="custBasicInfo">
        <div className="custTeleNum">
          <header>{labels.CUSTOMER_TELEPHONE_NUMBER}</header>
          <Divider orientation="horizontal" />
          <p>
            <FiPhoneCall />
            <span>{labels.HOME_PHONE.toUpperCase()}</span>
            {homeNum?.length !== 0 ? (
              homeNum?.map((hp: any, i: number) => {
                return (
                  <Fragment key={i}>
                    <span>
                      {hp.substr(0, 3)}-{hp.substr(3, 3)}-{hp.substr(6)}
                    </span>
                    <br />
                  </Fragment>
                );
              })
            ) : (
              <span>{labels.NA}</span>
            )}
          </p>
        </div>
        <div className="custServiceArea">
          <header>{labels.CUSTOMER_SERVICE_AREA}</header>
          <Divider orientation="horizontal" />
          <div style={{ margin: "15px 0px 15px 20px" }}>
            <GrMapLocation />
            {renderServiceAreaInfo()}
          </div>
        </div>
      </div>
      <div className="custBasicInfo">
        <div className="custServiceAddressCurr">
          <header>{labels.CURREENT_SERVICE_ADDRESS}</header>
          <Divider orientation="horizontal" />
          <div style={{ margin: "15px 0px 15px 20px" }}>
            <GrMapLocation />
            <section>
              <span>
                {selectedSA.apartmentNumber !== "" ? (
                  selectedSA.apartmentNumber + "-"
                ) : (
                  <></>
                )}
                {selectedSA.streetNumber} {selectedSA.streetName}{" "}
                {selectedSA.streetType},
              </span>
              <span>
                {selectedSA.city}, {selectedSA.province}
              </span>
              <span>{selectedSA.postalCode}</span>
            </section>
          </div>
        </div>
        <div className="custServiceAddressOth">
          <header>{labels.OTHER_SERVICE_ADDRESS}</header>
          <Divider orientation="horizontal" />
          <p>
            {otherAddress?.length !== 0 ? (
              otherAddress?.map((sa: any, index: number) => {
                return (
                  <span key={index}>
                    {sa}
                    <a href="" onClick={(e) => handleAddressChange(e, sa)}>
                      (Switch)
                    </a>
                  </span>
                );
              })
            ) : (
              <span>{labels.NA}</span>
            )}
          </p>
        </div>
      </div>
    </Card>
  );
};

export default CustOverView;
