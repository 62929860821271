import { WIFISettings } from "../../../../models/account/diagnostics/cm/WIFISettings";

export const WIFI_SETTINGS_BEGIN = "WIFI_SETTINGS_BEGIN";
export const WIFI_SETTINGS_SUCCESS = "WIFI_SETTINGS_SUCCESS";
export const WIFI_SETTINGS_FAILURE = "WIFI_SETTINGS_FAILURE";

interface WIFISettingsBegin {
  type: typeof WIFI_SETTINGS_BEGIN;
}

interface WIFISettingsSuccess {
  type: typeof WIFI_SETTINGS_SUCCESS;
  payload: WIFISettings;
}

interface WIFISettingsFailure {
  type: typeof WIFI_SETTINGS_FAILURE;
  payload: Error;
}

export type WIFISettingsActionTypes =
  | WIFISettingsBegin
  | WIFISettingsSuccess
  | WIFISettingsFailure;
