import { elementDataConfig } from "../../../../config/mapItemsConfig";
import appConst from "../../../../constants/appConst";
import { GetMapDetailsProps } from "../../../../util/reduxFunctions/getTopologyState";
import MduActiveEquipment from "./drawer/elements/MduActiveEquipment";
import MduFiberEquipment from "./drawer/elements/MduFiberEquipment";
import MduPowerSupply from "./drawer/elements/MduPowerSupply";
import MduTapDetails from "./drawer/elements/MduTapDetails";

const MDUDrawerContent = () => {
  const { activeMapElement } = GetMapDetailsProps();
  const getActiveEleContent = (eleType: string) => {
    let eleDataConfig: any;
    elementDataConfig?.map((o, i) => {
      if (eleType === o.name) {
        eleDataConfig = o;
      }
    });

    switch (eleType) {
      case appConst?.mduActive:
        return (
          <MduActiveEquipment
            eleConfig={eleDataConfig}
            eleData={activeMapElement?.properties?.data}
          />
        );
      case appConst?.mduPowerSupply:
        return (
          <MduPowerSupply
            eleConfig={eleDataConfig}
            eleData={activeMapElement?.properties?.data}
          />
        );
      case appConst?.mduFiberEquipment:
        return (
          <MduFiberEquipment
            eleConfig={eleDataConfig}
            eleData={activeMapElement?.properties?.data}
          />
        );
      case appConst?.mduTap:
        return (
          <MduTapDetails
            eleConfig={eleDataConfig}
            eleData={activeMapElement?.properties?.data}
          />
        );
    }
  };

  return (
    <div className="tabDataCont">
      {getActiveEleContent(activeMapElement?.properties?.type)}
    </div>
  );
};

export default MDUDrawerContent;
