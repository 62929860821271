import { Marker } from "@react-google-maps/api";
import { renderToString } from "react-dom/server";

import { mapElementStyles } from "../../../config/mapItemsConfig";
import { GetMicroReflectionsGroupedIssuesProps } from "../../../util/reduxFunctions/getTopologyState";
import TopologySymbols from "../../reusable/TopologySymbols";

export default function SamKeyAddressesMarkers() {
  const { selectedData } = GetMicroReflectionsGroupedIssuesProps();

  return (
    <>
      {selectedData &&
        selectedData?.map((data: any) => {
          const cmMarker = renderToString(
            <TopologySymbols
              name={mapElementStyles?.samkeyAddress?.iconName}
              color={mapElementStyles?.samkeyAddress?.color}
              size={mapElementStyles?.samkeyAddress?.iconSize}
            />
          );
          if (
            data?.address !== undefined &&
            data?.address?.geometry?.coordinates?.[1] !== 0.0 &&
            data?.address?.geometry?.coordinates?.[0] !== 0.0
          ) {
            return (
              <Marker
                key={data?.samKey}
                position={{
                  lat: data?.address?.geometry?.coordinates?.[1],
                  lng: data?.address?.geometry?.coordinates?.[0],
                }}
                icon={{
                  url:
                    "data:image/svg+xml;charset=utf-8," +
                    encodeURIComponent(cmMarker),
                  anchor: new google.maps.Point(
                    mapElementStyles?.samkeyAddress?.anchor?.x,
                    mapElementStyles?.samkeyAddress?.anchor?.y
                  ),
                }}
                onClick={() => {
                  // handleCpatMarkerClick(samKeyAddress, appConst.samkeyAddress);
                }}
              />
            );
          }
        })}
    </>
  );
}
