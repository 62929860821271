import { WorkOrdersState } from "../../states/technician/WorkOrdersState";
import {
  WORKORDERS_BEGIN,
  WORKORDERS_FAILURE,
  WORKORDERS_SUCCESS,
  WorkOrderActionTypes,
} from "../../types/technicians/Workorders";

const initialState: WorkOrdersState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function WorkOrdersReducer(
  state = initialState,
  action: WorkOrderActionTypes
): WorkOrdersState {
  switch (action.type) {
    case WORKORDERS_BEGIN:
      return {
        isLoading: true,
      };
    case WORKORDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case WORKORDERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}
