import { CMFlaps } from "../../../../models/account/diagnostics/cm/CMFlaps";
import {
  CM_FLAPS_INFO_BEGIN,
  CM_FLAPS_INFO_FAILURE,
  CMFlapsInfoActionTypes,
  CM_FLAPS_INFO_SUCCESS,
} from "../../../../types/account/diagnostics/cm/CMFlapsData";

export function cmFlapsInfoBegin(): CMFlapsInfoActionTypes {
  return {
    type: CM_FLAPS_INFO_BEGIN,
  };
}

export function cmFlapsInfoSuccess(flapsInfo: CMFlaps): CMFlapsInfoActionTypes {
  return {
    type: CM_FLAPS_INFO_SUCCESS,
    payload: flapsInfo,
  };
}

export function cmFlapsInfoFailure(error: Error): CMFlapsInfoActionTypes {
  return {
    type: CM_FLAPS_INFO_FAILURE,
    payload: error,
  };
}
