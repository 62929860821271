import { useEffect } from "react";
import { useDispatch } from "react-redux";
import CustomAccordion from "../../../../../components/reusable/CustomAccordion";
import appConst from "../../../../constants/appConst";
import TopologyLabels from "../../../../constants/topologyLabels";
import { getNodeTapAddress } from "../../../../store/slices/mapItems/node/tap/nodeTapAddress";
import {
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  TableContainer,
} from "@material-ui/core";
import {
  GetMapDetailsProps,
  GetNodeTapAddressProps,
} from "../../../../util/reduxFunctions/getTopologyState";
import ExceptionBox from "../../../../../components/reusable/ExceptionBox";
import { isNull } from "../../../../../components/reusable/Util";

const TapAddress = () => {
  const labels = TopologyLabels();
  const dispatch = useDispatch();
  const { activeMapElement } = GetMapDetailsProps();
  const { data } = GetNodeTapAddressProps();
  const tableData = getAddressData();

  useEffect(() => {
    if (activeMapElement?.properties?.data?.tap?.networkId) {
      dispatch(
        getNodeTapAddress(activeMapElement?.properties?.data?.tap?.networkId)
      );
    }
  }, [activeMapElement?.properties?.data?.tap?.networkId]);

  function getAddressData() {
    const addressData: any = [];
    const sortedArray: any = [];
    const sortedData =
      data?.features != undefined &&
      data?.features?.length !== 0 &&
      [...data.features]?.sort((a: any, b: any) => {
        const portSequenceA: number = parseInt(
          a.properties.data.tap.PORTSEQUENCE
        );
        const portSequenceB: number = parseInt(
          b.properties.data.tap.PORTSEQUENCE
        );

        if (portSequenceA < portSequenceB) {
          return -1;
        } else if (portSequenceA > portSequenceB) {
          return 1;
        } else {
          return 0;
        }
      });
    sortedArray.push(sortedData);
    sortedData !== false &&
      sortedArray?.length !== 0 &&
      sortedArray?.[0]?.map((feature: any) => {
        const tap = feature?.properties?.data?.tap;
        const tapAddress = feature?.properties?.data?.tapAddress;
        tap?.tapAddress !== null &&
          addressData.push({
            portSequence: tap?.PORTSEQUENCE,
            streetNum: tapAddress?.streetNum,
            streetType: tapAddress?.streetType,
            streetName: tapAddress?.streetName,
            city: tapAddress?.city,
            state: tapAddress?.state,
          });
      });

    return addressData;
  }
  return (
    <>
      {tableData && (
        <CustomAccordion
          accTitle={appConst?.addresses?.toUpperCase()}
          accBodyElement={
            <>
              <TableContainer>
                <Table>
                  <TableHead className="dataTableDetails">
                    <TableRow>
                      <th>{labels.port}</th>
                      <th style={{ minWidth: 40 }}>{labels.street}#</th>
                      <th>{labels.streetName}</th>
                      <th>{labels.streetType}</th>
                      <th style={{ minWidth: 100 }}>{labels.city}</th>
                      <th>{labels.province}</th>
                    </TableRow>
                  </TableHead>
                  {tableData.length === 0 ? (
                    <TableRow tabIndex={-1} key="exception">
                      <TableCell colSpan={6}>
                        <div className="diagErrorCont">
                          <ExceptionBox
                            headerText={labels.no_address_info}
                            primaryBtn={false}
                            secBtn={false}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableBody className="tableData">
                      {tableData?.map((port: any, index: any) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            tabIndex={-1}
                            key={port.portSequence}
                            className="portDataRow"
                          >
                            <TableCell>{isNull(port?.portSequence)}</TableCell>
                            <TableCell>{isNull(port?.streetNum)}</TableCell>
                            <TableCell>{isNull(port?.streetName)}</TableCell>
                            <TableCell>{isNull(port?.streetType)}</TableCell>
                            <TableCell>{isNull(port?.city)}</TableCell>
                            <TableCell>{isNull(port?.state)}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </>
          }
        />
      )}
    </>
  );
};

export default TapAddress;
