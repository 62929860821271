import { CMTSNodeDetails } from "../../../models/node/nodeinfo/CMTSNodeDetails";
import {
  CMTSNodeActionTypes,
  CMTS_NODE_BEGIN,
  CMTS_NODE_SUCCESS,
  CMTS_NODE_FAILURE,
  SET_CMTS_NODE_DETAILS,
  RESET_CMTS_NODE,
} from "../../../types/node/nodeinfo/CMTSNodeDetails";

export function CMTSNodeBegin(): CMTSNodeActionTypes {
  return {
    type: CMTS_NODE_BEGIN,
  };
}

export function CMTSNodeSuccess(
  CMTSNodeDetails: CMTSNodeDetails
): CMTSNodeActionTypes {
  return {
    type: CMTS_NODE_SUCCESS,
    payload: CMTSNodeDetails,
  };
}

export function CMTSNodeFailure(CMTSNodeFailure: any): CMTSNodeActionTypes {
  return {
    type: CMTS_NODE_FAILURE,
    payload: CMTSNodeFailure,
  };
}

export function SetCMTSNodeDetails(
  CMTSNodeDetails: CMTSNodeDetails
): CMTSNodeActionTypes {
  return {
    type: SET_CMTS_NODE_DETAILS,
    payload: CMTSNodeDetails,
  };
}

export function CMTSNodeReset(): CMTSNodeActionTypes {
  return {
    type: RESET_CMTS_NODE,
  };
}
