export const commonAttr = [
  {
    columnKey: "address",
    columnName: "Address",
    jsonDataMapping: "address",
    getDataFrom: "listOfModems",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "cm_mac",
    columnName: "CM MAC",
    jsonDataMapping: "cmMac",
    getDataFrom: "listOfModems",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "model",
    columnName: "Model",
    jsonDataMapping: "model",
    getDataFrom: "listOfModems",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "smt",
    columnName: "SMT",
    jsonDataMapping: "smt",
    getDataFrom: "listOfModems",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "uptime",
    columnName: "Uptime",
    jsonDataMapping: "cmUptime",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];
export const commonAttrSTB = [
  {
    columnKey: "address",
    columnName: "Address",
    jsonDataMapping: "address",
    getDataFrom: "listOfModems",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "cm_mac",
    columnName: "STB MAC",
    jsonDataMapping: "cmMac",
    getDataFrom: "listOfModems",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ip_address",
    columnName: "IP Address",
    jsonDataMapping: "stbIP",
    getDataFrom: "SummaryStb",
    isPinned: false,
    isVisible: true,
    position: 0,
  },

  {
    columnKey: "smt",
    columnName: "SMT",
    jsonDataMapping: "smt",
    getDataFrom: "listOfModems",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "qam_status",
    columnName: "QAM Status",
    jsonDataMapping: "qamChannelStatus",
    getDataFrom: "SummaryStb",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "qam_ub",
    columnName: "QAM UB",
    jsonDataMapping: "qamUncorrected",
    getDataFrom: "SummaryStb",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "qam_freq",
    columnName: "QAM Freq",
    jsonDataMapping: "qamFreq",
    getDataFrom: "SummaryStb",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "qam_time",
    columnName: "QAM Time",
    jsonDataMapping: "qamTime",
    getDataFrom: "SummaryStb",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "uptime",
    columnName: "Uptime",
    jsonDataMapping: "stbUptime",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];
export const commonAttrECM = [
  {
    columnKey: "address",
    columnName: "Address",
    jsonDataMapping: "address",
    getDataFrom: "listOfModems",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "cm_mac",
    columnName: "ECM MAC",
    jsonDataMapping: "cmMac",
    getDataFrom: "listOfModems",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "model",
    columnName: "Model",
    jsonDataMapping: "model",
    getDataFrom: "listOfModems",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "smt",
    columnName: "SMT",
    jsonDataMapping: "smt",
    getDataFrom: "listOfModems",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "uptime",
    columnName: "Uptime",
    jsonDataMapping: "cmUptime",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const summaryCommonAttr = [
  {
    columnKey: "cmtsIP",
    columnName: "CM IPv4 Address",
    jsonDataMapping: "cmtsIP",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "wanIPv6",
    columnName: "CM IPv6 Address",
    jsonDataMapping: "wanIPv6",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "mtaIP",
    columnName: "MTA Address",
    jsonDataMapping: "mtaIP",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const summaryAttr = [
  {
    columnKey: "reg_status",
    columnName: "Reg Status",
    jsonDataMapping: "cmtsCmOnlineStatus",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const summaryAttrECM = [
  {
    columnKey: "cmOnlineStatus",
    columnName: "Status",
    jsonDataMapping: "cmOnlineStatus",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "mgmtIPv6",
    columnName: "IP Address",
    jsonDataMapping: "mgmtIPv6",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const docsisAttr = [
  {
    columnKey: "us_interfaces",
    columnName: "US Interface",
    jsonDataMapping: "usInterface",
    displayAttribute: "usInterfaceDisplay",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "lines",
    columnName: "Line Status",
    jsonDataMapping: "lines",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];
export const flapsAttr = [
  // {
  //   columnKey: "flap",
  //   columnName: "Flap",
  //   jsonDataMapping: "flapsCount",
  //   getDataFrom: "flaps",
  //   isPinned: false,
  //   isVisible: true,
  //   position: 0,
  // },
  {
    columnKey: "inf",
    columnName: "Inf",
    jsonDataMapping: "insertionFails",
    getDataFrom: "flaps",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  // {
  //   columnKey: "hits",
  //   columnName: "Hits",
  //   jsonDataMapping: "hits",
  //   getDataFrom: "flaps",
  //   isPinned: false,
  //   isVisible: true,
  //   position: 0,
  // },
  // {
  //   columnKey: "miss",
  //   columnName: "Miss",
  //   jsonDataMapping: "miss",
  //   getDataFrom: "flaps",
  //   isPinned: false,
  //   isVisible: true,
  //   position: 0,
  // },
  {
    columnKey: "pwr",
    columnName: "Pwr",
    jsonDataMapping: "powerAdjustments",
    getDataFrom: "flaps",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  // {
  //   columnKey: "first_flap_time",
  //   columnName: "First Flap Time",
  //   jsonDataMapping: "createTime",
  //   getDataFrom: "flaps",
  //   isPinned: false,
  //   isVisible: true,
  //   position: 0,
  // },
  // {
  //   columnKey: "last_flap_time",
  //   columnName: "Last Flap Time",
  //   jsonDataMapping: "lastFlapTime",
  //   getDataFrom: "flaps",
  //   isPinned: false,
  //   isVisible: true,
  //   position: 0,
  // },
];

export const upStreamSubAttr1 = [
  {
    columnKey: "us_snr_min",
    columnName: "US SNR min",
    jsonDataMapping: "usMinSnr",
    displayAttribute: "usMinSnrDisplay",
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "us_snr_max",
    columnName: "US SNR max",
    jsonDataMapping: "usMaxSnr",
    displayAttribute: "usMaxSnrDisplay",
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const upStreamSubAttr2 = [
  {
    columnKey: "us_tx_min",
    columnName: "US Tx min",
    jsonDataMapping: "usMinTxPower",
    displayAttribute: "usMinTxPowerDisplay",
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "us_tx_max",
    columnName: "US Tx max",
    jsonDataMapping: "usMaxTxPower",
    displayAttribute: "usMaxTxPowerDisplay",
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const upStream1 = [
  {
    columnKey: "us_ps",
    columnName: "US PS",
    jsonDataMapping: "us31PartialSvcState",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  ...upStreamSubAttr2,

  {
    columnKey: "us_tx_delta",
    columnName: "US Tx (Δ)",
    jsonDataMapping: "usTxPowerDelta",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  ...upStreamSubAttr1,
  {
    columnKey: "us_snr_delta",
    columnName: "US SNR (Δ)",
    jsonDataMapping: "usSNRDelta",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const upStreamECM = [
  {
    columnKey: "usMod",
    columnName: "US Mod",
    jsonDataMapping: "usModulation",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "usChCount",
    columnName: "US #",
    jsonDataMapping: "usChannelsCount",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  ...upStreamSubAttr2,

  {
    columnKey: "us_tx_delta",
    columnName: "US Tx (Δ)",
    jsonDataMapping: "usTxPowerDelta",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  ...upStreamSubAttr1,
  {
    columnKey: "us_snr_delta",
    columnName: "US SNR (Δ)",
    jsonDataMapping: "usSNRDelta",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const upstreamSubAttr3 = [
  {
    columnKey: "us_tx",
    columnName: "US Tx",
    jsonDataMapping: "usTx",
    //exportcsvAttribute: "usTxCsv",
    displayAttribute: "usTxDisplay",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "us_snr",
    columnName: "US SNR",
    jsonDataMapping: "usSnr",
    displayAttribute: "usSnrDisplay",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const upStream2 = [
  {
    columnKey: "us_freq",
    columnName: "US Freq",
    jsonDataMapping: "usFreq",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  ...upstreamSubAttr3,
];
export const downstream30SubAttr1 = [
  {
    columnKey: "ds_rx_min",
    columnName: "DS Rx min",
    jsonDataMapping: "minRxPower",
    displayAttribute: "minRxPowerDisplay",
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ds_rx_max",
    columnName: "DS Rx max",
    jsonDataMapping: "maxRxPower",
    displayAttribute: "maxRxPowerDisplay",
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];
export const downstream30SubAttr2 = [
  {
    columnKey: "ds_snr_min",
    columnName: "DS SNR min",
    jsonDataMapping: "dsMinSnr",
    displayAttribute: "dsMinSnrDisplay",
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ds_snr_max",
    columnName: "DS SNR max",
    jsonDataMapping: "dsMaxSnr",
    displayAttribute: "dsMaxSnrDisplay",
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];
export const downStream30Attr = [
  {
    columnKey: "ds_ps",
    columnName: "DS PS",
    jsonDataMapping: "ds31PartialSvcState",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  ...downstream30SubAttr1,
  {
    columnKey: "ds_rx_delta",
    columnName: "DS Rx (Δ)",
    jsonDataMapping: "rxPowerDelta",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  ...downstream30SubAttr2,
  {
    columnKey: "ds_snr_delta",
    columnName: "DS SNR (Δ)",
    jsonDataMapping: "dsSnrDeltaValue",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const downstream30AttrECM = [
  {
    columnKey: "dsChCount",
    columnName: "DS#",
    jsonDataMapping: "dsChannelsCount",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  ...downstream30SubAttr1,
  {
    columnKey: "ds_rx_delta",
    columnName: "DS Rx (Δ)",
    jsonDataMapping: "rxPowerDelta",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  ...downstream30SubAttr2,
  {
    columnKey: "ds_snr_delta",
    columnName: "DS SNR (Δ)",
    jsonDataMapping: "dsSnrDeltaValue",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const downstream30SubAttr3 = [
  {
    columnKey: "ds_rx",
    columnName: "DS Rx",
    jsonDataMapping: "dsRx",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    displayAttribute: "dsRxDisplay",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ds_snr",
    columnName: "DS SNR",
    jsonDataMapping: "dsSnr",
    haveMultipleValues: true,
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    displayAttribute: "dsSnrDisplay",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const downstream30Attr2 = [
  {
    columnKey: "ds_freq",
    columnName: "DS Freq",
    jsonDataMapping: "dsFreq",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  ...downstream30SubAttr3,
];

export const upStream31SubAttr1 = [
  {
    columnKey: "ofdma_tx",
    columnName: "OFDMA TX",
    jsonDataMapping: "txPowerValue",
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    displayAttribute: "txPowerValueDisplay",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];
export const upStream31SubAttr2 = [
  {
    columnKey: "ofdma_pct",
    columnName: "OFDMA 2 PCT",
    jsonDataMapping: "merThrValValue",
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    displayAttribute: "merThrValValueDisplay",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];
export const upStream31Attr = [
  ...upStream31SubAttr1,
  {
    columnKey: "ofdma_mean",
    columnName: "OFDMA mean",
    jsonDataMapping: "merMean",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ofdma_std",
    columnName: "OFDMA STD",
    jsonDataMapping: "merStdDev",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  ...upStream31SubAttr2,
];

export const upStream31Attr2 = [
  {
    columnKey: "ofdma_status",
    columnName: "OFDMA Status",
    jsonDataMapping: "usChannelStatus",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];
export const downStream31SubAttr1 = [
  {
    columnKey: "ofdm_rx_min",
    columnName: "OFDM Rx min",
    jsonDataMapping: "dsMinRxPowerValue",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    displayAttribute: "dsMinRxPowerValueDisplay",
    isPinned: false,
    isVisible: true,
    position: 0,
    styleClassName: "medValCol",
  },
  {
    columnKey: "ofdm_rx_max",
    columnName: "OFDM Rx max",
    jsonDataMapping: "dsMaxRxPowerValue",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    displayAttribute: "dsMaxRxPowerValueDisplay",
    isPinned: false,
    isVisible: true,
    position: 0,
    styleClassName: "medValCol",
  },
];
export const downStream31SubAttr2 = [
  {
    columnKey: "ofdm_pct",
    columnName: "OFDM 2 PCT",
    jsonDataMapping: "dsThrValValue",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    displayAttribute: "dsThrValValueDisplay",
    isPinned: false,
    isVisible: true,
    position: 0,
    styleClassName: "medValCol",
  },
];

export const downStream31SubAttr3 = [
  {
    columnKey: "ofdm_1_rx_min",
    columnName: "OFDM Rx min",
    jsonDataMapping: "dsMinRxPowerValue1",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    displayAttribute: "dsMinRxPowerValue1Display",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ofdm_1_rx_max",
    columnName: "OFDM Rx max",
    jsonDataMapping: "dsMaxRxPowerValue1",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    displayAttribute: "dsMaxRxPowerValue1Display",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const downStream31SubAttr4 = [
  {
    columnKey: "ofdm_1_pct",
    columnName: "OFDM 2 PCT",
    jsonDataMapping: "dsThrValValue1",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    displayAttribute: "dsThrValValue1Display",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];
export const downStream31Attr = [
  {
    columnKey: "ofdm_channel_ID",
    columnName: "OFDM Channel ID",
    jsonDataMapping: "ofdmChannelID",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
    styleClassName: "medValCol",
  },
  ...downStream31SubAttr1,
  {
    columnKey: "ofdm_rx_delta",
    columnName: "OFDM Rx ∆",
    jsonDataMapping: "dsRxPowerDelta",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
    styleClassName: "medValCol",
  },
  {
    columnKey: "ofdm_mean",
    columnName: "OFDM mean",
    jsonDataMapping: "dsMerMean",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
    styleClassName: "medValCol",
  },
  {
    columnKey: "ofdm_std",
    columnName: "OFDM STD",
    jsonDataMapping: "dsMerStdDev",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
    styleClassName: "medValCol",
  },
  ...downStream31SubAttr2,
];

export const downStream31Attr1 = [
  ...downStream31SubAttr3,
  {
    columnKey: "ofdm_1_rx_delta",
    columnName: "OFDM Rx ∆",
    jsonDataMapping: "dsRxPowerDelta1",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ofdm_1_mean",
    columnName: "OFDM mean",
    jsonDataMapping: "dsMerMean1",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ofdm_1_std",
    columnName: "OFDM STD",
    jsonDataMapping: "dsMerStdDev1",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  ...downStream31SubAttr4,
];

export const downStream31Attr2 = [
  {
    columnKey: "ofdm_status",
    columnName: "OFDM Status",
    jsonDataMapping: "dsStatusValue",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const fullAttr = [
  {
    columnKey: "cmtsCmRegisteredTime",
    columnName: "Reg Time",
    jsonDataMapping: "cmtsUptime",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  ...summaryAttr,
];

export const fullAttrSTB = [
  {
    columnKey: "osVersion",
    columnName: "OS Version",
    jsonDataMapping: "osVersion",
    getDataFrom: "SummaryStb",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "type",
    columnName: "STB Type",
    jsonDataMapping: "way",
    getDataFrom: "SummaryStb",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "rdc_tx",
    columnName: "RDC TX",
    jsonDataMapping: "rdcPower",
    getDataFrom: "SummaryStb",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "qam_snr",
    columnName: "QAM SNR",
    jsonDataMapping: "qamSnr",
    getDataFrom: "SummaryStb",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "avDisc",
    columnName: "AV Disc",
    jsonDataMapping: "mpegDiscontinuityCount",
    getDataFrom: "SummaryStb",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "fdcRx",
    columnName: "FDC Rx",
    jsonDataMapping: "fdcPower",
    getDataFrom: "SummaryStb",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "fdcUb",
    columnName: "FDC UB",
    jsonDataMapping: "fdcUncorrected",
    getDataFrom: "SummaryStb",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const exclusiveAttr = [
  {
    columnKey: "onlineTimes",
    columnName: "Online Times",
    jsonDataMapping: "onlineTimes",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "onlinePercentage",
    columnName: "Online Percentage",
    jsonDataMapping: "onlinePercentage",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const exclusiveAttrSTB = [
  {
    columnKey: "qamModel",
    columnName: " QAM Model",
    jsonDataMapping: "qamModel",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const neighbourhoodTeleViewConfig = (istechRole:any)=> { 
  console.log(istechRole,"istechRole");
   return [
  {
    viewTypeKey: "full",
    viewName: "ALL",
    specAvailAttr: [
      ...upStreamSubAttr1,
      ...upStreamSubAttr2,
      ...downstream30SubAttr1,
      ...downstream30SubAttr2,
    ],
    attributes: [
      ...commonAttr,
      ...fullAttr,
      ...upStream1,
      ...downStream30Attr,
      ...exclusiveAttr,
      ...(istechRole ? subflapsAttr : flapsAttr),
      ...summaryCommonAttr,
      ...docsisAttr,
    ],
  },
  {
    viewTypeKey: "flaps",
    viewName: "FLAPS",
    attributes: [...commonAttr,  ...(istechRole ? subflapsAttr : flapsAttr)],
  },
  {
    viewTypeKey: "basic",
    viewName: "BASIC",
    attributes: [
      ...commonAttr,
      ...fullAttr,
      ...summaryCommonAttr,
      {
        columnKey: "lines",
        columnName: "Line Status",
        jsonDataMapping: "lines",
        getDataFrom: "summary",
        isPinned: false,
        isVisible: true,
        position: 0,
      },
    ],
  },
  {
    viewTypeKey: "full_no_cw_us_sc_qam",
    viewName: "US SC QAM",
    specAvailAttr: [
      ...upStreamSubAttr1,
      ...upStreamSubAttr2,
      ...upstreamSubAttr3,
    ],
    attributes: [...commonAttr, ...upStreamECM, ...upStream2],
  },
  {
    viewTypeKey: "full_no_cw_ds_sc_qam",
    viewName: "DS SC QAM",
    specAvailAttr: [
      ...downstream30SubAttr1,
      ...downstream30SubAttr2,
      ...downstream30SubAttr3,
    ],
    attributes: [...commonAttr, ...downStream30Attr, ...downstream30Attr2],
  },
  {
    viewTypeKey: "full_no_cw_ofdma",
    viewName: "OFDMA",
    specAvailAttr: [
      ...upStream31SubAttr1,
      ...upStream31SubAttr2,
      //  ...ofdmaCodeWordsSubAttr,
    ],
    attributes: [
      ...commonAttr,
      ...summaryCommonAttr,
      ...upStream31Attr,
      ...upStream31Attr2,
      // ...ofdmaCodeWordsAttr,
    ],
  },
  {
    viewTypeKey: "full_no_cw_ofdm",
    viewName: "OFDM",
    specAvailAttr: [
      ...downStream31SubAttr1,
      ...downStream31SubAttr3,
      ...downStream31SubAttr4,
      // ...ofdmCodeWordsSubAttr,
    ],
    attributes: [
      ...commonAttr,
      ...summaryCommonAttr,
      ...downStream31Attr1,
      ...downStream31Attr2,
      //   ...ofdmCodeWordsAttr,
    ],
  },
];
}

export const specOptions = [
  { columnName: "IN-SPEC", columnKey: "inSpec" },
  { columnName: "OUT-SPEC", columnKey: "outSpec" },
  { columnName: "MARGINAL", columnKey: "marginal" },
  { columnName: "OTHERS", columnKey: "others" },
];
export const neighbourhoodTeleViewConfigSTB = [
  {
    viewTypeKey: "full",
    viewName: "ALL",
    specAvailAttr: [
      ...upStreamSubAttr1,
      ...upStreamSubAttr2,
      ...downstream30SubAttr1,
      ...downstream30SubAttr2,
    ],
    attributes: [...commonAttrSTB, ...fullAttrSTB],
  },
  {
    viewTypeKey: "basic",
    viewName: "BASIC",
    attributes: [...commonAttrSTB, ...exclusiveAttrSTB],
  },
];
export const subflapsAttr = [
  {
    columnKey: "flap",
    columnName: "Flap",
    jsonDataMapping: "flapsCount",
    getDataFrom: "flaps",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "inf",
    columnName: "Inf",
    jsonDataMapping: "insertionFails",
    getDataFrom: "flaps",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "hits",
    columnName: "Hits",
    jsonDataMapping: "hits",
    getDataFrom: "flaps",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "miss",
    columnName: "Miss",
    jsonDataMapping: "miss",
    getDataFrom: "flaps",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "misses",
    columnName: "Miss %",
    jsonDataMapping: "misses",
    getDataFrom: "flaps",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "pwr",
    columnName: "Pwr",
    jsonDataMapping: "powerAdjustments",
    getDataFrom: "flaps",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "first_flap_time",
    columnName: "First Flap Time",
    jsonDataMapping: "createTime",
    getDataFrom: "flaps",
    isPinned: false,
    isVisible: true,
    position: 0,
    styleClassName: "largeValCol",
  },
  {
    columnKey: "last_flap_time",
    columnName: "Last Flap Time",
    jsonDataMapping: "lastFlapTime",
    getDataFrom: "flaps",
    isPinned: false,
    isVisible: true,
    position: 0,
    styleClassName: "largeValCol",
  },
];
export const neighbourhoodTeleViewConfigECM = (istechRole:any)=> {
  console.log(istechRole,"istechRole") ;
  return[
  {
    viewTypeKey: "full",
    viewName: "ALL",
    specAvailAttr: [
      ...upStreamSubAttr1,
      ...upStreamSubAttr2,
      ...downstream30SubAttr1,
      ...downstream30SubAttr2,
    ],
    attributes: [
      ...commonAttrECM,
      ...fullAttr,
      ...upStreamECM,
      ...downstream30AttrECM,
      ...exclusiveAttr,
      ...summaryAttrECM,
      {
        columnKey: "us_interfaces",
        columnName: "US Interface",
        jsonDataMapping: "usInterface",
        displayAttribute: "usInterfaceDisplay",
        getDataFrom: "docsis",
        isPinned: false,
        isVisible: true,
        position: 0,
      },
    ],
  },
  {
    viewTypeKey: "flaps",
    viewName: "FLAPS",
    attributes: [...commonAttrECM,   ...(istechRole ? subflapsAttr : flapsAttr)],
  },
  {
    viewTypeKey: "basic",
    viewName: "BASIC",
    attributes: [...commonAttrECM,  ...(istechRole ? subflapsAttr : flapsAttr), ...summaryAttrECM],
  },
  {
    viewTypeKey: "full_no_cw_us_sc_qam",
    viewName: "US SC QAM",
    specAvailAttr: [
      ...upStreamSubAttr1,
      ...upStreamSubAttr2,
      ...upstreamSubAttr3,
    ],
    attributes: [...commonAttrECM, ...upStreamECM, ...upStream2],
  },
  {
    viewTypeKey: "full_no_cw_ds_sc_qam",
    viewName: "DS SC QAM",
    specAvailAttr: [
      ...downstream30SubAttr1,
      ...downstream30SubAttr2,
      ...downstream30SubAttr3,
    ],
    attributes: [
      ...commonAttrECM,
      ...downstream30AttrECM,
      ...downstream30Attr2,
    ],
  },
];
}
export const neighbourhoodDataSetType = [
  {
    dataSetName: "FULL W/O CW",
    subDataSetViews: [
      { columnName: "ALL", columnKey: "full", isVisible: true },
      { columnKey: "flaps", columnName: "FLAPS", isVisible: true },
      {
        columnKey: "full_no_cw_us_sc_qam",
        columnName: "US SC QAM",
        isVisible: true,
      },
      {
        columnKey: "full_no_cw_ds_sc_qam",
        columnName: "DS SC QAM",
        isVisible: true,
      },
      { columnKey: "full_no_cw_ofdma", columnName: "OFDMA", isVisible: true },
      { columnKey: "full_no_cw_ofdm", columnName: "OFDM", isVisible: true },
    ],
  },
  {
    dataSetName: "BASIC",
    subDataSetViews: [
      { columnKey: "basic", columnName: "BASIC", isVisible: true },
    ],
  },
];

export const neighbourhoodDataSetTypeEcm = [
  {
    dataSetName: "FULL W/O CW",
    subDataSetViews: [
      { columnName: "ALL", columnKey: "full", isVisible: true },
      { columnKey: "flaps", columnName: "FLAPS", isVisible: true },
      {
        columnKey: "full_no_cw_us_sc_qam",
        columnName: "US SC QAM",
        isVisible: true,
      },
      {
        columnKey: "full_no_cw_ds_sc_qam",
        columnName: "DS SC QAM",
        isVisible: true,
      },
    ],
  },
  {
    dataSetName: "BASIC",
    subDataSetViews: [
      { columnKey: "basic", columnName: "BASIC", isVisible: true },
    ],
  },
];
export const neighbourhoodDataSetTypeStb = [
  {
    dataSetName: "FULL W/O CW",
    subDataSetViews: [
      { columnName: "ALL", columnKey: "full", isVisible: true },
    ],
  },
  {
    dataSetName: "BASIC",
    subDataSetViews: [
      { columnKey: "basic", columnName: "BASIC", isVisible: true },
    ],
  },
];
