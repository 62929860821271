import {ONTBandwidth} from '../../../../models/account/diagnostics/ont/ONTBandwidth';

export const ONT_BANDWIDTH_BEGIN = 'ONT_BANDWIDTH_BEGIN';
export const ONT_BANDWIDTH_SUCCESS = 'ONT_BANDWIDTH_SUCCESS';
export const ONT_BANDWIDTH_FAILURE = 'ONT_BANDWIDTH_FAILURE';

interface ONTBandwidthBegin {
  type: typeof ONT_BANDWIDTH_BEGIN;
}

interface ONTBandwidthSuccess {
  type: typeof ONT_BANDWIDTH_SUCCESS;
  payload: ONTBandwidth;
}

interface ONTBandwidthFailure {
  type: typeof ONT_BANDWIDTH_FAILURE;
  payload: Error;
}

export type ONTBandwidthActionTypes =
  | ONTBandwidthBegin
  | ONTBandwidthSuccess
  | ONTBandwidthFailure;
