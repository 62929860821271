import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import appConst from "../../../../constants/appConst";
import TopologyLabels from "../../../../constants/topologyLabels";
import { getGponSpliceMap } from "../../../../store/slices/drawer/gponSpliceMap";
import { useGponSpliceMapStates } from "../../../../util/reduxFunctions/getTopologyState";
import GponSpliceMapTable from "./GponSpliceMapTable";
import ExceptionHandlerWrapper from "../../../reusable/ExceptionHandlerWrapper";

interface Props {
  elementData: any;
}

export default function GponSpliceMap({ elementData }: Props) {
  const labels = TopologyLabels();
  const dispatch = useDispatch();
  const { allData } = useGponSpliceMapStates();
  const nodeSiteId = elementData?.rootNodeSiteId;
  const [enclIdsList, setEnclIdsList] = useState([]);
  const [selectEnclId, setSelectEnclId] = useState(enclIdsList?.[0]);
  const [currSplice, setCurrSplice] = useState<any>(undefined);

  //set current data
  useEffect(() => {
    const sIndex = allData.findIndex(
      (sMap) =>
        sMap?.data?.nodeSiteId === nodeSiteId &&
        sMap?.data?.enclosureNetworkId === selectEnclId
    );
    setCurrSplice(allData?.[sIndex]);
  }, [selectEnclId, allData]);

  //create list of enclosure ids
  useEffect(() => {
    const tempEnclIdsList = elementData?.data?.enclosure?.map(
      (encl: any) => encl.networkId
    );
    setEnclIdsList(tempEnclIdsList);
  }, [elementData?.data?.enclosure]);

  //reset selected dropdown if list of enclosure ids change
  useEffect(() => setSelectEnclId(enclIdsList?.[0]), [enclIdsList]);

  const onGetSpliceMap = () => {
    if (currSplice?.status != appConst.OK)
      dispatch(
        getGponSpliceMap({ nodeSiteId, enclosureNetworkId: selectEnclId })
      );
  };

  return (
    <div>
      <div className="flexedRowDiv" style={{ marginTop: 15 }}>
        {enclIdsList.length > 1 && (
          <DropdownButton
            title={selectEnclId}
            onSelect={(e: any) => setSelectEnclId(e)}
            id="selectDropdownBtn"
            style={{ marginRight: 10 }}
          >
            {enclIdsList?.map((e: string, i: number) => (
              <Dropdown.Item key={i} eventKey={e}>
                {e}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        )}
        {!(currSplice?.data?.payload?.length > 0) && (
          <Button
            onClick={onGetSpliceMap}
            className="stepperSubmitBtn"
            style={{
              height: 35,
              borderRadius: 5,
              width: "100%",
            }}
            disabled={!selectEnclId || currSplice?.isLoading}
          >
            {labels.GetSpliceMap}
          </Button>
        )}
      </div>
      {!selectEnclId && <label>{labels.NoEnclosureIDExists}</label>}
      <ExceptionHandlerWrapper
        status={currSplice?.status}
        isLoading={currSplice?.isLoading}
        error={currSplice?.error}
        data={currSplice?.data?.payload}
        failContStyle={{ marginTop: 10 }}
      >
        <GponSpliceMapTable
          data={currSplice?.data}
          designation={elementData?.data?.designation}
        />
      </ExceptionHandlerWrapper>
    </div>
  );
}
