import { useState } from "react";
import { Button, Dropdown, DropdownButton, Form } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import AppLabels from "../../../../constants/App_Labels";
import "../../../../design-tokens/forms/Noise.scss";
import CustomPopUp from "../../../reusable/CustomPopUp";
import CustomRadioGroup from "../../../reusable/CustomRadioGroup";
import EmailEntry from "../../../reusable/EmailEntry";
import ImageUpload from "../../../reusable/ImageUpload";
import RedAsterisk from "../../../reusable/RedAsterisk";

interface Props {
  detailsObj: any;
  setDetailsObj: any;
}

const BottomHalf = ({ detailsObj, setDetailsObj }: Props) => {
  const labels = AppLabels();
  const checkboxArr = [labels?.Yes, labels?.No];

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setDetailsObj((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRadioDropdownChange = (name: any, value: number) => {
    setDetailsObj({ ...detailsObj, [name]: value });
  };

  const handleImageChange = (imageList: any) => {
    setDetailsObj({ ...detailsObj, screenShots: imageList });
  };

  const setUnitInList = (index: any, value: any) => {
    detailsObj.above10UnitsList[index] = value;
    setDetailsObj({
      ...detailsObj,
      above10UnitsList: [...detailsObj?.above10UnitsList],
    });
  };

  const onRemove = (index: number) => {
    const newUnitsList = detailsObj?.above10UnitsList.filter(
      (e: any, i: number) => index !== i
    );
    setDetailsObj({ ...detailsObj, above10UnitsList: [...newUnitsList] });
  };

  return (
    <div>
      <Form.Group>
        <label className="txtFieldLabel">
          {"Managers Name"}
          <RedAsterisk />
        </label>
        <Form.Control
          onChange={handleChange}
          value={detailsObj?.managerName}
          name="managerName"
        />
      </Form.Group>
      <CustomRadioGroup
        label={"Hot To Cold*"}
        selectedValueIndex={checkboxArr?.indexOf(detailsObj?.hotToCold)}
        data={checkboxArr}
        onClick={(value: number) =>
          handleRadioDropdownChange("hotToCold", value)
        }
      />
      {/* <CustomRadioGroup
        label={"Bell Modem*"}
        selectedValueIndex={1}//{checkboxArr?.indexOf(detailsObj?.bellModem)}
        data={checkboxArr}
        onClick={() =>
          handleRadioDropdownChange("bellModem", 1)
        }
      /> */}
      <Form.Group>
        <label className="txtFieldLabel">
          {"F2 Information (Pre-Padding)"}
        </label>
        <Form.Control
          onChange={handleChange}
          value={detailsObj?.f2Information_PrePadding}
          name="f2Information_PrePadding"
        />
      </Form.Group>
      <CustomRadioGroup
        label={"Filtered or Padded*"}
        selectedValueIndex={checkboxArr?.indexOf(detailsObj?.filteredOrPadded)}
        data={checkboxArr}
        onClick={(value: number) =>
          handleRadioDropdownChange("filteredOrPadded", value)
        }
      />
      {detailsObj?.filteredOrPadded === labels?.Yes && (
        <Form.Group>
          <label className="txtFieldLabel">
            {"Filtered or Padded Information"}
            <RedAsterisk />
          </label>
          <Form.Control
            onChange={handleChange}
            value={detailsObj?.filterOrPadInfo}
            name="filterOrPadInfo"
          />
        </Form.Group>
      )}
      <Form.Group>
        <label className="txtFieldLabel">
          {"F2 Information (Post-Padding)"}
        </label>
        <Form.Control
          onChange={handleChange}
          value={detailsObj?.f2Information_PostPadding}
          name="f2Information_PostPadding"
        />
      </Form.Group>
      <CustomRadioGroup
        label={"Above 10 Units*"}
        selectedValueIndex={checkboxArr?.indexOf(detailsObj?.above10Units)}
        data={checkboxArr}
        onClick={(value: number) =>
          handleRadioDropdownChange("above10Units", value)
        }
      />
      <Form.Group>
        {detailsObj?.above10Units === labels?.Yes &&
          detailsObj?.above10UnitsList?.map((element: any, index: any) => (
            <>
              {index === 0 && (
                <label className="txtFieldLabel">
                  {"Units"}
                  {detailsObj?.above10UnitsComments === "" &&
                    detailsObj?.above10UnitsList.length > 0 && <RedAsterisk />}
                </label>
              )}
              <div className="inlineUnitContainer" key={index}>
                <Form.Control
                  className="unitInput"
                  onChange={(v) => setUnitInList(index, v.target.value)}
                  value={element}
                />
                <button
                  type="button"
                  className="unitRemoveBtn"
                  onClick={() => onRemove(index)}
                >
                  <MdClose color="black" />
                </button>
              </div>
            </>
          ))}
        {detailsObj?.above10Units === labels?.Yes && (
          <>
            {detailsObj?.above10UnitsList.length <= 0 && (
              <Button
                type="button"
                className="unitBtn"
                onClick={() =>
                  setDetailsObj({
                    ...detailsObj,
                    above10UnitsList: [...detailsObj?.above10UnitsList, ""],
                  })
                }
              >
                {"Add Units"}
              </Button>
            )}
            {detailsObj?.above10UnitsList.length > 0 && (
              <button
                type="button"
                className="addUnitBtn"
                onClick={() =>
                  setDetailsObj({
                    ...detailsObj,
                    above10UnitsList: [...detailsObj?.above10UnitsList, ""],
                  })
                }
              >
                + {"Add More Units"}
              </button>
            )}
          </>
        )}
      </Form.Group>
      <Form.Group>
        {detailsObj?.above10Units === labels?.Yes && (
          <>
            <label className="txtFieldLabel">
              {"Additional Comments"}
              {detailsObj?.above10UnitsList.length <= 0 && <RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              rows={3}
              maxLength={200}
              onChange={handleChange}
              value={detailsObj?.above10UnitsComments}
              name="above10UnitsComments"
            />
            <p>
              {labels.Total_Characters_Left}:{" "}
              {200 - detailsObj?.above10UnitsComments.length}
            </p>
          </>
        )}
      </Form.Group>
      <span className="DropdownContainer">
        <label className="txtFieldLabel">
          {"Priority"}
          <RedAsterisk />
        </label>
        <DropdownButton
          title={detailsObj?.priority}
          onSelect={(v: any) => handleRadioDropdownChange("priority", v)}
          size="sm"
          id="dropdownBtn"
          style={{ width: "30px" }}
        >
          <Dropdown.Item key={0} eventKey={"Normal"}>
            Normal
          </Dropdown.Item>
          <Dropdown.Item key={1} eventKey={"Urgent"}>
            Urgent
          </Dropdown.Item>
        </DropdownButton>
      </span>
      <span className="DropdownContainer">
        <label className="txtFieldLabel">
          {"Legacy / Ignite / Fido"}
          <RedAsterisk />
        </label>
        <DropdownButton
          title={detailsObj?.legacyOrIgniteOrFide}
          onSelect={(v: any) =>
            handleRadioDropdownChange("legacyOrIgniteOrFide", v)
          }
          size="sm"
          id="dropdownBtn"
          style={{ width: "30px" }}
          disabled={true}
        >
          <Dropdown.Item key={0} eventKey={"Legacy"}>
            Legacy
          </Dropdown.Item>
          <Dropdown.Item key={1} eventKey={"Ignite"}>
            Ignite
          </Dropdown.Item>
          <Dropdown.Item key={2} eventKey={"Fido"}>
            Fido
          </Dropdown.Item>
        </DropdownButton>
      </span>
      <ImageUpload
        title={"Screenshots"}
        image={detailsObj?.screenShots}
        onImageChange={handleImageChange}
      />
      {/* <Form.Group>
        <label className="txtFieldLabel">{"Customer Contact Number"}</label>
        <Form.Control
          onChange={(v) => props.setCustomerContactNumber(v.target.value)}
          value={props.customerContactNumber}
        />
      </Form.Group> */}
      <Form.Group>
        <label className="txtFieldLabel">{"Comments"}</label>
        <Form.Control
          as="textarea"
          rows={3}
          maxLength={200}
          onChange={handleChange}
          value={detailsObj?.comments}
          name="comments"
        />
        <p>
          {labels.Total_Characters_Left}: {200 - detailsObj?.comments.length}
        </p>
      </Form.Group>
    </div>
  );
};

export default BottomHalf;
