import { useDispatch } from "react-redux";

import CustomAccordion from "../../../../components/reusable/CustomAccordion";
import { getHomeAddress } from "../../../../components/reusable/Util";
import AppLabels from "../../../../constants/App_Labels";
import { mapElementStyles } from "../../../config/mapItemsConfig";
import appConst from "../../../constants/appConst";
import { setSelectedMapElement } from "../../../store/slices/mapItems/mapDetails";
import { GetTelemetryProps } from "../../../util/reduxFunctions/getTopologyState";
import {
  applyDiagAdvancedFilters,
  getCMStatusProps,
} from "../../../util/utilFuncs";
import SimpleDataTable from "../../reusable/SimpleDataTable";
import TopologySymbols from "../../reusable/TopologySymbols";

interface Props {
  cmList: any;
}

export default function CMAdvancedFilterResults({ cmList }: Props) {
  const labels = AppLabels();
  const dispatch = useDispatch();
  const { advancedFilters, advFCond } = GetTelemetryProps();
  const headers = [labels?.cmMac, labels?.Address, labels?.STATUS];

  const handleDiagDeviceClick = (deviceData: any) => {
    if (deviceData) {
      const activeElement = {
        geometry: deviceData?.coordinate,
        properties: { type: appConst.CM, data: deviceData },
      };
      dispatch(setSelectedMapElement(activeElement));
    }
  };

  const getResultsDetails = () => {
    let filtered = 0;
    let active = "0";
    const total = cmList?.length;

    cmList?.map((d: any) => {
      if (
        d?.deviceDiag &&
        applyDiagAdvancedFilters(d?.deviceDiag?.data, advancedFilters, advFCond)
      )
        filtered += 1;
    });

    if (filtered > 0 && total) active = ((filtered / total) * 100).toFixed(1);

    return { filtered, active, total };
  };

  const tableTitle = `${labels.filteredResults}: ${
    getResultsDetails().filtered
  }/${getResultsDetails().total} = ${
    getResultsDetails().active
  }%`.toUpperCase();

  return (
    <>
      {cmList && (
        <CustomAccordion
          key="dsSummaryAcc"
          accTitle={tableTitle}
          accBodyElement={
            <div
              className="unplottedCMCont"
              style={{ margin: 0, boxShadow: "none", padding: 0 }}
            >
              <SimpleDataTable
                headers={headers}
                tBody={cmList?.map((cm: any, i: number) => {
                  const iconSize = mapElementStyles?.cableModem?.iconSize;
                  const cmProps = getCMStatusProps(cm?.deviceDiag);
                  if (
                    cm?.deviceDiag &&
                    applyDiagAdvancedFilters(
                      cm?.deviceDiag?.data,
                      advancedFilters,
                      advFCond
                    )
                  ) {
                    return (
                      <tr key={i}>
                        <td
                          className="cmMacLink"
                          onClick={() => handleDiagDeviceClick(cm)}
                        >
                          {cm?.macAddress}
                        </td>
                        <td>{getHomeAddress(cm?.serviceAddress)}</td>
                        <td>
                          <span>
                            <TopologySymbols
                              name={cmProps.iconName}
                              color={cmProps.color}
                              size={iconSize}
                            />
                          </span>
                        </td>
                      </tr>
                    );
                  }
                })}
              />
            </div>
          }
        />
      )}
    </>
  );
}
