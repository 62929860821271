import { useDispatch } from "react-redux";
import IHPClient from "../../network/httpclient/ihp/IHPClient";
import DiagApiCallCont from "../reusable/DiagApiCallCont";
import ReloadData from "../reusable/ReloadData";
import IHPDeviceInfo from "./IHPDeviceInfo";
import RHPdocsisInfo from "./RHPdocsisInfo";
import RHPInterfaceInfo from "./RHPInterfaceInfo";
import CableModemClient from "../../network/httpclient/cablemodem/CableModemClient";
import { isNull } from "../reusable/Util";

const IHPSummary = (props: any) => {
  const {
    selectedDevice,
    ihpLoading,
    summaryInfo,
    summaryStatus,
    docsisInfo,
    interfaceInfo,
    cmSummaryInfo,
    cmNodeDetails,
    selectedSA,
  } = props;
  const dispatch = useDispatch();

  const refreshData = () => {
    if (selectedDevice.deviceType !== "IMTA") {
      dispatch(
        IHPClient.getIHPSummary(
          selectedDevice.deviceCategory,
          selectedDevice.serialNumber,
          selectedDevice.macAddress,
          ""
        )
      );
      dispatch(
        IHPClient.getIHPDocsis(
          selectedDevice.serialNumber,
          selectedDevice.macAddress
        )
      );
      dispatch(
        IHPClient.getRHPInterface(
          selectedDevice.serialNumber,
          selectedDevice.macAddress
        )
      );
      dispatch(
        IHPClient.getCMSummary(
          selectedDevice.serialNumber,
          selectedDevice.macAddress
        )
      );
      dispatch(
        CableModemClient.getCMNodeDetails(
          selectedDevice.serialNumber,
          isNull(selectedSA.shubId)
        )
      );
    } else {
      dispatch(
        IHPClient.getIHPSummary(
          selectedDevice.deviceCategory,
          selectedDevice.serialNumber,
          selectedDevice.cmmac,
          selectedDevice.atamac
        )
      );
      dispatch(
        IHPClient.getCMSummary(
          selectedDevice.serialNumber,
          selectedDevice.cmmac
        )
      );
      dispatch(
        CableModemClient.getCMNodeDetails(
          selectedDevice.serialNumber,
          isNull(selectedSA.shubId)
        )
      );
    }
  };

  return (
    <>
      <div className="refreshCont">
        <ReloadData refreshData={refreshData} />
      </div>
      <div className="cmSummaryCont">
        <DiagApiCallCont
          isLoading={ihpLoading}
          data={summaryInfo}
          sectionName="EMTA Summary"
          status={summaryStatus}
          handleRefresh={() => refreshData()}
        >
          {selectedDevice.deviceType !== "IMTA" && (
            <>
              <RHPdocsisInfo
                docsisInfo={docsisInfo}
                docsisError={docsisInfo}
                handleRefresh={() => refreshData()}
              />
            </>
          )}
          <IHPDeviceInfo
            summaryData={summaryInfo}
            cmSummaryData={cmSummaryInfo}
            cmNodeDetails={cmNodeDetails}
          />
          {selectedDevice.deviceType !== "IMTA" && (
            <>
              <RHPInterfaceInfo
                interfaceInfo={interfaceInfo}
                interfaceError={interfaceInfo}
                handleRefresh={() => refreshData()}
              />
            </>
          )}
        </DiagApiCallCont>
      </div>
    </>
  );
};

export default IHPSummary;
