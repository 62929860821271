import { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa";
import ConfigConst from "../../../constants/ConfigConst";
import { IoAlertCircle } from "react-icons/io5";
import AppLabels from "../../../constants/App_Labels";
// import IECLabels from "../../../constants/IEC_Labels";
import {
  GetTechInfo,
  // GetWorkOrdersList,
  // GetActiveAccountDetails,
} from "../../../get-api/GetResponse";
import PreJobInspectionScreen from "../../forms/PJIForm/PreJobInspectionScreen";
// import CustomerExperinceCertificationScreen from "../../forms/CECForm/CustomerExperinceCertificationScreen";
// import FormClient from "../../../network/httpclient/form/FormClient";
// import { isIgniteAccount, isLegacyAccount } from "../../reusable/Util";

interface Props {
  isPJISubmitted: boolean;
  workOrderId: any;
  //isIECSubmitted: boolean;
  formHistoryData?: any;
}

export default function FormSection({
  isPJISubmitted,
  workOrderId,
  //isIECSubmitted,
  formHistoryData,
}: Props) {
  const labels = AppLabels();
  // const iec_labels = IECLabels();
  const {
    isMaintenanceRoleAvailable,
    isTechnicianRoleAvailable
  } = GetTechInfo();
  // const accType = GetActiveAccountDetails()?.data;
  const [showModal, setShowModal] = useState(false);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  // const orderCategory = GetWorkOrdersList()?.activeOrderCategory
  //   ? GetWorkOrdersList()?.activeOrderCategory
  //   : GetWorkOrdersList()?.workOrderDetails?.orderCategory;
  // const woType = GetWorkOrdersList()?.activeWorkType
  //   ? GetWorkOrdersList()?.activeWorkType
  //   : GetWorkOrdersList()?.workOrderDetails?.workType;
  // const brand = GetActiveAccountDetails()?.brand;
  // const testType = GetActiveAccountDetails()?.testType;
  // const workOrderDetails = GetWorkOrdersList()?.workOrderDetails;
  // const isLegacyAcc = isLegacyAccount();

  // const productList: string[] = workOrderDetails?.productList
  //   ? workOrderDetails.productList
  //   : workOrderDetails?.error ?? [];
  // const indexWithWSTV = productList.findIndex((item) =>
  //   item.includes("PRD: WSTV")
  // );
  // const hasWSTV =
  //   indexWithWSTV !== -1 && (orderCategory === "SO" || orderCategory === "WO");

  const itemData = [
    (isTechnicianRoleAvailable || isMaintenanceRoleAvailable) && {
      title: labels.PJI,
      headerTitle: ConfigConst.PRE_JOB_INSPECTION,
      isSubmitted: isPJISubmitted,
      compToRender: (
        <PreJobInspectionScreen
          setShowModal={setShowModal}
          isFromCertfication={true}
          workOrderId={workOrderId}
          formHistoryData={formHistoryData}
        />
      ),
    },
    // ((hasWSTV === false &&
    //   brand !== "Fido" &&
    //   (woType === "IGPO" ||
    //     woType === "DPSI" ||
    //     woType === "DPSM" ||
    //     orderCategory === "SO") &&
    //   testType === "WHT" && !isLegacyAcc &&
    //   accType?.accountNumber?.length === 9) ||
    //   (hasWSTV === false &&
    //     isIgniteAccount(accType) && 
    //     (orderCategory === "SO" || orderCategory === "WO"))) && {
    //     title: iec_labels.CEC,
    //     headerTitle: ConfigConst.CUSTOMER_EXPERIENCE_CERTIFICATION,
    //     isSubmitted: isIECSubmitted,
    //     compToRender: (
    //       <CustomerExperinceCertificationScreen
    //         setShowModal={setShowModal}
    //         isFromCertification={true}
    //         workOrderId={workOrderId}
    //       />
    //     ),
    //   },
  ];

  const onItemPress = (index: number, headerTitle: string) => {
    setActiveItemIndex(index);
    setShowModal(true);
    // dispatch(FormClient.getForm(workOrderId, headerTitle));
  };

  const onClosePopup = () => setShowModal(false);

  return (
    <section>
      {itemData?.map(
        (e, i) =>
          e && (
            <Fragment key={i}>
              <div style={{ marginTop: 20 }}>
                <div className="pjiCont">
                  <div className="pjiReviewLogo">
                    {e?.isSubmitted ? (
                      <FaCheckCircle color="#28A745" />
                    ) : (
                      <IoAlertCircle color="#BF371F" />
                    )}
                  </div>
                  <div style={{ marginTop: 20 }}>{e?.headerTitle}*</div>
                  <i
                    style={{
                      marginTop: 0,
                      fontSize: 14,
                      alignSelf: "center",
                    }}
                    onClick={() => onItemPress(i, e?.headerTitle)}
                  >
                    {e?.isSubmitted ? labels.Edit : labels.Add}
                  </i>
                </div>
              </div>
              {i === activeItemIndex && (
                <Modal
                  show={showModal}
                  onHide={onClosePopup}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header className="searchPopHead">
                    <header>{e?.headerTitle}</header>
                    <button className="sPopUpClose" onClick={onClosePopup}>
                      <IoClose color="white" />
                    </button>
                  </Modal.Header>
                  <Modal.Body className="confirmBody">
                    {e?.compToRender}
                  </Modal.Body>
                </Modal>
              )}
            </Fragment>
          )
      )}
    </section>
  );
}
