import { useEffect, useState } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { Divider } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { MdRefresh } from "react-icons/md";

import "../../design-tokens/forms/WorkOrderSelection.scss";
import {
  GetActiveAccountDetails,
  GetFormHistory,
  GetTechInfo,
  GetWorkOrdersList,
} from "../../get-api/GetResponse";
import AppLabels from "../../constants/App_Labels";
import ConfigConst from "../../constants/ConfigConst";
import { getAccessLevelByRoles, isLegacyAccount } from "../reusable/Util";
import InlineErrorMessage from "../reusable/InlineErrorMessage";
import { SaveFormReset } from "../../store/actions/account/forms/FormAction";
import FormClient from "../../network/httpclient/form/FormClient";
import WorkOrdersClient from "../../network/httpclient/workorders/WorkOrdersClient";
import ActivityIndicator from "../reusable/ActivityIndicator";
import RedAsterisk from "../reusable/RedAsterisk";
import { HistoryReset } from "../../store/actions/account/forms/FormDataAction";
import CustomerTypeSelection from "./LOPForm/CustomerTypeSelection";

interface Props {
  selectedWOID: any;
  setSelectedWOID: any;
  formName: string;
  isWOSelectionComplete: boolean;
  setIsWOSelectionComplete: any;
  setWOData: any;
  workOrderData?: any;
  selectedCustType?: string;
  setSelectedCustType?: any;
  isFromCertification?: boolean;
}

export default function WorkOrderSelection(props: Props) {
  const labels = AppLabels();
  const dispatch = useDispatch();
  const workOrderList = GetWorkOrdersList();
  const accountDetails = GetActiveAccountDetails();
  const activeWO = GetWorkOrdersList()?.activeWO;
  const formData = GetFormHistory()?.formData;
  const techData = GetTechInfo()?.data;

  const [WOIDData, setWOIDData] = useState(
    props.workOrderData ? [...props.workOrderData] : [labels.Manual_Entry]
  );
  const [manualWorkorderIDEntry, setManualWorkorderIDEntry] = useState("");
  const orderTypeData = props.formName === ConfigConst.PRE_JOB_INSPECTION ?
  [labels.Installation, labels.Service, labels.Maintenance]:
  [labels.Installation, labels.Service];
  const [errorMessage, setErrorMessage] = useState("");
  const acceptedWOFirstChar = isLegacyAccount() ? "C" : "W";

  const changeOrderTypeValue = (orderCategory: string) => {
    if(activeWO?.orderType==="MA" && props.formName === ConfigConst.PRE_JOB_INSPECTION ){
      return labels.Maintenance;
    }
    if (orderCategory === ConfigConst.WO) {
      return labels.Installation;
    } else if (orderCategory === ConfigConst.SO) {
      return labels.Service;
    } else return orderCategory;
  };

  const [orderType, setOrderType] = useState(
    changeOrderTypeValue(activeWO?.orderCategory)
  );

  useEffect(() => {
    //clear form data if no work orders available in the dropdown
    if (WOIDData?.length === 1) {
      dispatch(SaveFormReset());
    }

    //get work order list if api call hasnt been initiated before
    if (workOrderList?.data?.length === 0) {
      dispatch(
        WorkOrdersClient.getTechWorkOrders(
          techData?.techId,
          techData?.name,
          getAccessLevelByRoles(techData?.roles).includes(ConfigConst.MT)
        )
      );
    }
  }, []);

  //set initial data
  useEffect(() => {
    if (workOrderList?.activeWO?.id) {
      props.setSelectedWOID(activeWO?.id);
      setWOIDData([...WOIDData, activeWO?.id]);
    }
  }, [workOrderList?.data]);

  useEffect(() => {
    if (
      props.selectedWOID !== labels.Select
      //&&      props.selectedWOID !== labels.Manual_Entry
    ) {
      if (props.selectedWOID === labels.Select) {
        dispatch(HistoryReset());
      } else if (props.isFromCertification !== true) {
        dispatch(FormClient.getForm(props.selectedWOID, props.formName));
      }
    }
  }, [props.selectedWOID]);

  //set existing api data
  useEffect(() => {
    if (formData?.orderCategory && orderType !== formData?.orderCategory) {
      setOrderType(changeOrderTypeValue(formData?.orderCategory));
    }
  }, [formData]);

  //add list of WOs to the dropdown list if available
  useEffect(() => {
    if (workOrderList?.data?.length > 0) {
      const listOfWOID = workOrderList?.data
        ?.map(
          (e: any) =>
            (accountDetails?.accountNumber || accountDetails?.cbpId) ===
              e.accountNumber && e.id
        )
        .filter((item: any) => item);

      setWOIDData([...WOIDData, ...listOfWOID]);
    }
  }, [workOrderList?.data]);

  //setIsWOSelectionComplete
  useEffect(() => {
    if (
      props?.selectedWOID &&
      props?.selectedWOID !== labels.Select &&
      //props.selectedWOID !== labels.Manual_Entry &&
      orderType !== ""
    ) {
      props.setIsWOSelectionComplete(true);
    } else if (props.selectedWOID === labels.No_Work_Order) {
      props.setIsWOSelectionComplete(true);
    } else {
      props.setIsWOSelectionComplete(false);
    }
  }, [props.selectedWOID, orderType]);

  //generate data
  useEffect(() => {
    props.setWOData({
      orderType,
    });
  }, [orderType]);

  const onWorkOrderIDChange = (value: any) => {
    props.setSelectedWOID(value);

    //set order type
    if (value === labels.Manual_Entry) {
      setOrderType(labels.Installation);
    } else if (value === labels.No_Work_Order) {
      setOrderType("");
    } else if (workOrderList?.data?.length > 0) {
      const index = workOrderList?.data?.findIndex((e: any) => e.id === value);
      setOrderType(
        changeOrderTypeValue(workOrderList?.data?.[index]?.orderCategory)
      );
    }
  };

  const onConfirm = () => {
    if (
      manualWorkorderIDEntry?.length !== 13 ||
      [acceptedWOFirstChar, acceptedWOFirstChar?.toLowerCase()].indexOf(
        manualWorkorderIDEntry.charAt(0)
      ) === -1
    ) {
      setErrorMessage(
        `${labels.Work_Order_ID_should_be_alphanumeric_characters_starting_with} '${acceptedWOFirstChar}'`
      );
    } else {
      setErrorMessage("");
      // add typed workorder ID to the list of work order drop down and auto select it
      props.setSelectedWOID(manualWorkorderIDEntry);
      props.setSelectedWOID(manualWorkorderIDEntry);
      if (WOIDData.indexOf(manualWorkorderIDEntry) === -1) {
        setWOIDData([...WOIDData, manualWorkorderIDEntry]);
      }
      setManualWorkorderIDEntry("");
    }
  };

  const onRefresh = () => {
    dispatch(
      WorkOrdersClient.getTechWorkOrders(
        techData?.techId,
        techData?.name,
        getAccessLevelByRoles(techData?.roles).includes(ConfigConst.MT)
      )
    );
    onWorkOrderIDChange(activeWO?.id);
  };

  const RenderWorkOrderIDDropDown = () => (
    <div className="itemContainer">
      <label className="labelTxt">
        {labels.Select_Work_Order_ID}
        <RedAsterisk />
      </label>
      <div className="workOrderIDContainer">
        {workOrderList.isLoading ? (
          <ActivityIndicator
            style={{ width: "20%", height: "20%" }}
            animationAlignement="none"
          />
        ) : (
          <MdRefresh className="refreshIcon" onClick={onRefresh} />
        )}
        <DropdownButton
          title={props.selectedWOID}
          onSelect={(v: any) => onWorkOrderIDChange(v)}
          size="sm"
          id="dropdownBtn"
          style={{ width: "100%" }}
        >
          {WOIDData.map((e, i) => (
            <Dropdown.Item key={i} eventKey={e}>
              {e}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>
    </div>
  );

  return (
    <div className="container">
      <div className="centeredOptionsContainer">
        <RenderWorkOrderIDDropDown />
        {props.selectedWOID === labels.Manual_Entry && (
          <>
            <div
              className="itemContainer"
              style={{ marginTop: "15px", marginBottom: "15px" }}
            >
              <label className="labelTxt">
                {labels.Enter_Work_Order_ID}
                <RedAsterisk />
              </label>
              <input
                type="text"
                placeholder={labels.Enter_Work_Order_ID + "..."}
                value={manualWorkorderIDEntry}
                onChange={(e) => {
                  setManualWorkorderIDEntry(
                    e.target?.value?.replace(/\s+/g, "")?.toUpperCase()
                  );
                }}
              />
            </div>
            <div className="itemContainer">
              <label className="labelTxt">
                {labels.Select_Order_Type}
                <RedAsterisk />
              </label>
              <DropdownButton
                title={orderType}
                onSelect={(v: any) => setOrderType(v)}
                size="sm"
                id="dropdownBtn"
              >
                {orderTypeData.map((e, i) => (
                  <Dropdown.Item key={i} eventKey={e}>
                    {e}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
            <InlineErrorMessage
              errorMessage={errorMessage}
              style={{ margin: "20px" }}
            />
            <button className="confirmBtn" onClick={onConfirm}>
              {labels.Confirm}
            </button>
          </>
        )}
      </div>
      {props.selectedWOID !== labels.No_Work_Order && (
        <div style={{ marginTop: "20px" }}>
          <label className="labelTxt">{labels.Order_Type}:&nbsp;</label>
          <label>{orderType}</label>
        </div>
      )}
      {props.formName === ConfigConst.LETTER_OF_PERMISSION && (
        <CustomerTypeSelection
          selectedCustType={props.selectedCustType}
          setSelectedCustType={props.setSelectedCustType}
        />
      )}
      {props.formName === ConfigConst.EQUIPMENT_PICKUP_RECEIPT && (
        <CustomerTypeSelection
          selectedCustType={props.selectedCustType}
          setSelectedCustType={props.setSelectedCustType}
        />
      )}
      {props.formName === ConfigConst.NOISE_REFERRAL &&
      props.selectedWOID === labels.No_Work_Order ? (
        <div style={{ marginTop: "20px" }}>
          <CustomerTypeSelection
            selectedCustType={props.selectedCustType}
            setSelectedCustType={props.setSelectedCustType}
            formName={props.formName}
          />
        </div>
      ) : (
        props.formName === ConfigConst.NOISE_REFERRAL && (
          <CustomerTypeSelection
            selectedCustType={props.selectedCustType}
            setSelectedCustType={props.setSelectedCustType}
            formName={props.formName}
          />
        )
      )}
      <Divider style={{ margin: "20px 0px 20px 0px" }} />
    </div>
  );
}
