import { Marker } from "@react-google-maps/api";
import React, { Fragment } from "react";
import { mapElementStyles } from "../../../config/mapItemsConfig";
import appConst from "../../../constants/appConst";
import {
  GetNodeSiteDetailsProps,
  GetVhubSearchProps,
} from "../../../util/reduxFunctions/getTopologyState";
import {
  getBase64ScaledImageAnchor,
  getMapElementClickableImage,
  getPngImagesForElement,
} from "../../../util/utilFuncs";

interface Props {
  pngImages: any;
  handleElementClick: any;
  zoomLevel: number;
}

const VHUBMarker = ({ pngImages, handleElementClick, zoomLevel }: Props) => {
  const vhubPng = pngImages?.[appConst.vhub];
  const { data } = GetVhubSearchProps();
  const feature = data?.features?.[0];
  const vhubData = data?.features?.[0]?.properties?.data;
  const searchedMarkerLat = data?.features?.[0]?.geometry?.coordinates?.[1];
  const searchedMarkerLng = data?.features?.[0]?.geometry?.coordinates?.[0];
  const anchor = getBase64ScaledImageAnchor(
    pngImages,
    appConst.vhub,
    vhubData?.symbolName,
    vhubData?.rotation,
    mapElementStyles?.vhub,
    zoomLevel
  );
  const { allNodeData } = GetNodeSiteDetailsProps();
  return (
    <>
      {allNodeData.map((site: any) => {
        const feature = site?.vhub?.data?.features?.[0];
        const pData = feature?.properties?.data;
        const markerLat = feature?.geometry?.coordinates?.[1];
        const markerLng = feature?.geometry?.coordinates?.[0];
        const anchor = getBase64ScaledImageAnchor(
          pngImages,
          appConst.vhub,
          pData?.symbolName,
          pData?.rotation,
          mapElementStyles?.vhub,
          zoomLevel
        );

        return (
          markerLat &&
          markerLng && (
            <Fragment key={feature?.featureId}>
              <Marker
                position={{ lat: markerLat, lng: markerLng }}
                icon={getPngImagesForElement(
                  vhubPng,
                  pData?.symbolName,
                  pData?.rotation,
                  mapElementStyles?.vhub,
                  zoomLevel
                )}
                clickable={false}
              />

              <Marker
                position={{ lat: markerLat, lng: markerLng }}
                onClick={() => {
                  handleElementClick(feature, appConst.vhub);
                }}
                icon={{
                  url:
                    appConst.imageStringPrefix +
                    encodeURIComponent(
                      getMapElementClickableImage(
                        zoomLevel,
                        anchor?.clickIconSize
                      )
                    ),
                  anchor: new google.maps.Point(
                    anchor ? anchor?.x : mapElementStyles?.common.anchor.x,
                    anchor ? anchor?.y : mapElementStyles?.common.anchor.y
                  ),
                }}
              />
            </Fragment>
          )
        );
      })}
      {searchedMarkerLat && searchedMarkerLng && (
        <Fragment key={feature?.featureId}>
          <Marker
            position={{ lat: searchedMarkerLat, lng: searchedMarkerLng }}
            icon={getPngImagesForElement(
              vhubPng,
              vhubData?.symbolName,
              vhubData?.rotation,
              mapElementStyles?.vhub,
              zoomLevel
            )}
            clickable={false}
          />
          <Marker
            position={{ lat: searchedMarkerLat, lng: searchedMarkerLng }}
            onClick={() => {
              handleElementClick(feature, appConst.vhub);
            }}
            icon={{
              url:
                appConst.imageStringPrefix +
                encodeURIComponent(
                  getMapElementClickableImage(zoomLevel, anchor?.clickIconSize)
                ),
              anchor: new google.maps.Point(
                anchor ? anchor?.x : mapElementStyles?.common.anchor.x,
                anchor ? anchor?.y : mapElementStyles?.common.anchor.y
              ),
            }}
          />
        </Fragment>
      )}
    </>
  );
};

export default React.memo(VHUBMarker);
