import { Line } from "react-konva";
import { KonvaEventObject } from "konva/lib/Node";
import { ICommonDrawingProps, getOpacity } from "../GeoEditCanvas";

export interface IGeoPenLineShape {
  id: string;
  points: number[];
  stroke: string;
  strokeWidth: number;
  isDragging: boolean;
  opacity: number;
}
interface Props {
  cdp: ICommonDrawingProps;
  lines: IGeoPenLineShape[];
  setLines: (lines: IGeoPenLineShape[]) => void;
  penPoints: number[];
  onClick: ({ id }: { id: string }) => void;
}

export default function PenLines({
  lines,
  cdp,
  penPoints,
  setLines,
  onClick,
}: Props) {
  const handleDragStart = (e: KonvaEventObject<DragEvent>) => {
    cdp.setDrawingType("none");
    const id = e.target.id();
    setLines(lines.map((ln) => ({ ...ln, isDragging: ln.id === id })));
  };
  const handleDragEnd = () => {
    setLines(lines.map((ln) => ({ ...ln, isDragging: false })));
  };

  return (
    <>
      {lines.map((l) => (
        <Line
          key={l.id}
          id={l.id}
          points={l.points}
          stroke={l.stroke}
          strokeWidth={l.isDragging ? l.strokeWidth + 2 : l.strokeWidth}
          opacity={getOpacity(l.opacity)}
          onClick={() => onClick({ id: l.id })}
          dash={cdp.getDash({ id: l.id, shape: "pen" })}
          // draggable
          // onMouseDown={handleDragStart}
          // onMouseUp={handleDragEnd}
          // onDragEnd={handleDragEnd}
        />
      ))}
      {cdp.drawingType === "pen" && cdp.drawStartPosi && cdp.drawEndPosi && (
        <Line
          points={penPoints}
          stroke={cdp.color}
          strokeWidth={cdp.strokeWidth}
          fill={cdp.fill}
          opacity={getOpacity(cdp.opacity)}
        />
      )}
    </>
  );
}
