import {
  DEVICE_DIAG_LIST_BEGIN,
  DEVICE_DIAG_LIST_FAILURE,
  DEVICE_DIAG_LIST_SUCCESS,
  DEVICE_DIAG_LIST_RESET,
  DeviceDiagListActionTypes,
} from "../../../types/account/precheck/PCDeviceDiag";
import { PCDeviceDiagState } from "../../../states/account/precheck/PCDeviceDiagState";

const initialState: PCDeviceDiagState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function PCDeviceDiagReducer(
  state = initialState,
  action: DeviceDiagListActionTypes
): PCDeviceDiagState {
  switch (action.type) {
    case DEVICE_DIAG_LIST_BEGIN:
      return {
        isLoading: true,
      };
    case DEVICE_DIAG_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case DEVICE_DIAG_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };
    case DEVICE_DIAG_LIST_RESET:
      return {
        ...state,
        isLoading: false,
        data: undefined,
        error: undefined,
        status: "",
      };
    default:
      return state;
  }
}
