import {
  ADD_ZAP_STB_REQUEST,
  RESET_ZAP_STB,
  UPDATE_ZAP_STB_REQUEST,
  ZapSTBsActionTypes,
} from "../../../types/account/deviceActions/ZapAllSTB";

export function AddZapSTBRequest(deviceKey: any): ZapSTBsActionTypes {
  return {
    type: ADD_ZAP_STB_REQUEST,
    devicekey: deviceKey,
  };
}

export function UpdateZapSTBRequest(
  devicekey: string,
  requestStatus: string
): ZapSTBsActionTypes {
  return {
    type: UPDATE_ZAP_STB_REQUEST,
    devicekey: devicekey,
    requestStatus: requestStatus,
  };
}

export function ResetZapSTB(): ZapSTBsActionTypes {
  return {
    type: RESET_ZAP_STB,
  };
}
