import { useState } from "react";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { isNull } from "../reusable/Util";
import DataDisplayTable from "../reusable/DataDisplayTable";
import AppLabels from "../../constants/App_Labels";
import CustomAccordion from "../reusable/CustomAccordion";

const ODUDetails = (props: any) => {
  const { selDevice, deviceData } = props;
  const [simAcc, setSimAcc] = useState(true);
  const [oduAcc, setOduAcc] = useState(true);
  const [apnAcc, setAPNAcc] = useState(true);
  const handleAccToggle = (accName: string) => {
    if (accName === "sim") setSimAcc(!simAcc);
    else if (accName === "odu") setOduAcc(!oduAcc);
    else if (accName === "apn") setAPNAcc(!apnAcc);
  };

  const deviceDetail: any = {
    iccid: selDevice?.iccId,
    msisdn: selDevice?.msisdn,
    siteId: selDevice?.sectorId,
    eci: selDevice?.cellId,
    azimuthAngle: selDevice?.azimuthAngle,
  };

  const labels = AppLabels();
  return (
    <CustomAccordion
      accTitle={labels.SUMMARY.toUpperCase()}
      accBodyElement={
        <>
          <div className="dsChDataAcc" onClick={() => handleAccToggle("sim")}>
            <p style={{ marginBottom: "0px" }}>SIM DETAILS</p>
            {simAcc ? (
              <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
            ) : (
              <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
            )}
          </div>
          {simAcc ? (
            <DataDisplayTable
              dataObject={deviceDetail}
              tableName="oduSummary"
            />
          ) : (
            <></>
          )}
          <div className="ofdmChDetails">
            <div className="dsChDataAcc" onClick={() => handleAccToggle("odu")}>
              <p style={{ marginBottom: "0px" }}>ODU/IDU DETAILS</p>
              {oduAcc ? (
                <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
              ) : (
                <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
              )}
            </div>
            {oduAcc ? (
              <DataDisplayTable
                dataObject={deviceData}
                tableName="oduSummary"
              />
            ) : (
              <></>
            )}
          </div>
          {deviceData?.hasOwnProperty("apn") && deviceData.apn?.length !== 0 ? (
            <div className="ofdmChDetails">
              <div
                className="dsChDataAcc"
                onClick={() => handleAccToggle("apn")}
              >
                <p style={{ marginBottom: "0px" }}>APN(S) DETAILS</p>
                {apnAcc ? (
                  <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
                ) : (
                  <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
                )}
              </div>
              {apnAcc ? (
                <table className="dataTableDetails">
                  <thead style={{ marginTop: "10px" }}>
                    <tr>
                      <th>NAME</th>
                      <th>STATUS</th>
                      <th>IPV4 ADDRESS</th>
                      <th>IPV6 ADDRESS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {deviceData.apn?.map((apn: any, i: number) => {
                      return (
                        <tr key={i}>
                          <td>{isNull(apn.name)}</td>
                          <td>{isNull(apn.status)}</td>
                          <td>{isNull(apn.ipV4Address)}</td>
                          <td>{isNull(apn.ipV6Address)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </>
      }
    />
  );
};

export default ODUDetails;
