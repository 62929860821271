import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import {
  GetClickOrdersProps,
  GetTopoConfigsProps,
} from "../../../../util/reduxFunctions/getTopologyState";
import SelectionChip from "../../../reusable/SelectionChip";
import { setClickStatusFlt } from "../../../../store/slices/mapItems/clickOrders";
import TopologyLabels from "../../../../constants/topologyLabels";
import ToggleSwitch from "../../../reusable/ToggleSwitch";
import {
  clickStatusToLocalStorage,
  getClickStatusConfig,
} from "../../../../util/utilFuncs";

export default function Status() {
  const labels = TopologyLabels();
  const dispatch = useDispatch();
  const { clickStatusFlt } = GetClickOrdersProps();
  const { data: FD } = GetTopoConfigsProps();
  const [statusToggle, setStatusToggle] = useState(true);

  useEffect(() => {
    if (FD?.clickConfig?.status && !clickStatusFlt)
      dispatch(
        setClickStatusFlt(getClickStatusConfig(FD?.clickConfig?.status))
      );
  }, [FD]);

  useEffect(() => {
    if (clickStatusFlt) clickStatusToLocalStorage(clickStatusFlt);
  }, [clickStatusFlt]);

  const onChipsPress = (checked: boolean, index: number) => {
    let orderStatus = JSON.parse(JSON.stringify(clickStatusFlt)); //create deep copy
    orderStatus[index].checked = checked;
    dispatch(setClickStatusFlt(orderStatus));
  };

  const handleToggleSwitch = (toggle: boolean) => {
    let orderStatus = JSON.parse(JSON.stringify(clickStatusFlt)); //create deep copy
    orderStatus?.map((e: any) => (e.checked = toggle));
    dispatch(setClickStatusFlt(orderStatus));
    setStatusToggle(toggle);
  };

  return (
    <div className="filterGroupCont">
      <div className="filterSecHeader">
        <label className="nikaRadiusLabel">{labels.Status}</label>
        <ToggleSwitch
          switchValue={statusToggle}
          setSwitchValue={handleToggleSwitch}
        />
      </div>
      <div className="selectionChipsCont">
        {clickStatusFlt?.map((e: any, i: number) => (
          <SelectionChip
            key={e.label}
            style={{ marginRight: 10, marginBottom: 10 }}
            label={e.label}
            checked={e.checked}
            setChecked={(checked) => onChipsPress(checked, i)}
          />
        ))}
      </div>
    </div>
  );
}

const getStatusConfig = (status: Array<any>) => {
  const statusData = [] as any;
  const uncheckedVals = ["Cancelled"];

  const returnCheckedVal = (label: string) => {
    if (uncheckedVals.includes(label)) {
      return false;
    } else return true;
  };

  status?.map((e) => {
    statusData.push({ label: e, checked: returnCheckedVal(e) });
  });
  return statusData;
};
