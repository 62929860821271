import { changeTimeFormat, isNull } from "../reusable/Util";
import ExceptionBox from "../reusable/ExceptionBox";

const CMFlapsDetails = (props: any) => {
  const { flapsInfo } = props;

  return (
    <>
      {flapsInfo !== undefined ? (
        <table className="dataTableDetails">
          {flapsInfo?.length !== 0 ? (
            <>
              <thead>
                <th>{"First Flap".toUpperCase()}</th>
                <th>{"Last Flap".toUpperCase()}</th>
                <th>{"Flap Count".toUpperCase()}</th>
                <th>{"Inf".toUpperCase()}</th>
                <th>{"Hits".toUpperCase()}</th>
                <th>{"Misses".toUpperCase()}</th>
                <th>{"Power".toUpperCase()}</th>
              </thead>
              <tbody>
                <tr>
                  <td>{isNull(changeTimeFormat(flapsInfo?.createTime))}</td>
                  <td>{isNull(changeTimeFormat(flapsInfo?.lastFlapTime))}</td>
                  <td>{isNull(flapsInfo?.counts)}</td>
                  <td>{isNull(flapsInfo?.insertionFails)}</td>
                  <td>{isNull(flapsInfo?.hits)}</td>
                  <td>{isNull(flapsInfo?.miss)}</td>
                  <td>{isNull(flapsInfo?.powerAdjustments)}</td>
                </tr>
              </tbody>
            </>
          ) : (
            <div className="diagErrorCont">
              <ExceptionBox
                headerText="No Data to display for flaps."
                bodyText="Please try refreshing."
                primaryBtn={false}
                secBtn={false}
              />
            </div>
          )}
        </table>
      ) : (
        <></>
      )}
    </>
  );
};

export default CMFlapsDetails;
