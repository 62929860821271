import { secToTime } from "../reusable/Util";
import { isNull } from "../reusable/Util";
import AppLabels from "../../constants/App_Labels";
import CustomAccordion from "../reusable/CustomAccordion";
const CMSummary = (props: any) => {
  const { cmSummarydata, managedRouterdata, managedRouterFlag } = props;
  const labels = AppLabels();

  // console.log(cmSummarydata, managedRouterdata);

  return (
    <CustomAccordion
      accTitle={labels.SUMMARY.toUpperCase()}
      accBodyElement={
        <>
          <table className="dataTableDetails">
            <thead>
              <tr>
                <th id="nameCol">{labels.NAME.toUpperCase()}</th>
                <th id="valueCol">{labels.VALUE.toUpperCase()}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{labels.MODEL.toUpperCase()}</td>
                <td>{isNull(cmSummarydata.model)}</td>
              </tr>
              <tr>
                <td>{labels.MAC.toUpperCase()}</td>
                <td>{isNull(cmSummarydata.cmMac)}</td>
              </tr>
              <tr>
                <td>{labels.SERIAL_NUMBER.toUpperCase()}</td>
                <td>{isNull(cmSummarydata.serialNumber)}</td>
              </tr>
              <tr>
                <td>{labels.FIRMWARE_VERSION.toUpperCase()}</td>
                <td>
                  {cmSummarydata?.hasOwnProperty("firmware") ? (
                    isNull(cmSummarydata.firmware.fwVersion)
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
              <tr>
                <td>{labels.ONLINE_PERCENTAGE.toUpperCase()}</td>
                <td>{isNull(cmSummarydata.onlinePercentage)}</td>
              </tr>
              <tr>
                <td>{labels.CM_STATUS.toUpperCase()}</td>
                <td>{isNull(cmSummarydata.cmOnlineStatus?.value)}</td>
              </tr>
              <tr>
                <td>{labels.ONLINE_TIMES.toUpperCase()}</td>
                <td>{isNull(cmSummarydata.onlineTimes)}</td>
              </tr>
              <tr>
                <td>{labels.MGMT_IPV4.toUpperCase()}</td>
                <td>{isNull(cmSummarydata.mgmtIP)}</td>
              </tr>
              <tr>
                <td>{labels.MGMT_IPV6.toUpperCase()}</td>
                <td>{isNull(cmSummarydata.mgmtIPv6)}</td>
              </tr>
              <tr>
                <td>{labels.WAN_IP.toUpperCase()}</td>
                <td>{isNull(cmSummarydata.wanIP)}</td>
              </tr>
              <tr>
                <td>{labels.WAN_IPV6.toUpperCase()}</td>
                <td>{isNull(cmSummarydata.wanIPv6)}</td>
              </tr>
              <tr>
                <td>{labels.CM_POWER_UPTIME.toUpperCase()}</td>
                <td>{isNull(secToTime(cmSummarydata.uptime))}</td>
              </tr>
              <tr>
                <td>{labels.CM_REGISTRATION_TIME.toUpperCase()}</td>
                <td>{isNull(secToTime(cmSummarydata.cmtsCmRegisteredTime))}</td>
              </tr>
              <tr>
                <td>{labels.BRIDGE_MODE_STATUS.toUpperCase()}</td>
                <td>
                  {cmSummarydata?.hasOwnProperty("bridgeMode") ? (
                    isNull(cmSummarydata.bridgeMode.toString())
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
              <tr>
                <td>{labels.TR069_STATUS.toUpperCase()}</td>
                <td>
                  {cmSummarydata?.hasOwnProperty("tr69") ? (
                    isNull(cmSummarydata.tr69.enabled.toString())
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
              {managedRouterFlag && (
                <>
                  <tr>
                    <td>{labels.Managed_LANIP.toUpperCase()}</td>
                    <td>{isNull(managedRouterdata?.lanIp)}</td>
                  </tr>
                  <tr>
                    <td>{labels.LAN_Subnet.toUpperCase()}</td>
                    <td>{isNull(managedRouterdata?.lanSm)}</td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </>
      }
    />
  );
};

export default CMSummary;
