import { IndividualIssueModel } from "../../models/feedback/IndividualIssue";

export const ISSUE_BEGIN = "ISSUE_BEGIN";
export const ISSUE_SUCCESS = "ISSUE_SUCCESS";
export const ISSUE_FAILURE = "ISSUE_FAILURE";

interface issueBegin {
  type: typeof ISSUE_BEGIN;
}

interface issueSuccess {
  type: typeof ISSUE_SUCCESS;
  payload:IndividualIssueModel;
}

interface issueFailure {
  type: typeof ISSUE_FAILURE;
  payload: Error;
}

export type IssueActionTypes =
  | issueBegin
  | issueSuccess
  | issueFailure;
