import { Fragment, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";

import "../../design-tokens/account-page/AccountLevelSection.scss";
import CustomPopOver from "../reusable/CustomPopOver";
import thriveLogo from "../../resources/images/thriveLogo.png";
import CustomIcons, { IconInterface } from "../reusable/CustomIcons";
import AppLabels from "../../constants/App_Labels";
import {
  GetTechInfo,
  GetActiveAccountDetails,
} from "../../get-api/GetResponse";
import PreJobInspectionScreen from "../forms/PJIForm/PreJobInspectionScreen";
import LetterOfPermissionScreen from "../forms/LOPForm/LetterOfPermissionScreen";
import SeniorReferralScreen from "../forms/SRForm/SeniorReferralScreen";
import FeedBack from "../forms/FBForm/FeedBack";
import ConfigConst from "../../constants/ConfigConst";
import RiserRepairScreen from "../forms/RRForm/RiserRepairScreen";
import EquipmentPickupReceiptScreen from "../forms/EPRForm/EquipmentPickupReceiptScreen";
import NoiseScreen from "../forms/NoiseForm/NoiseScreen";
import ASPScreen from "../forms/ASPForm/ASPScreen";
import { GetConfigsProps } from "../../topology/util/reduxFunctions/getTopologyState";

interface RenderIconInterface {
  name: IconInterface["name"];
}

const RenderIcon = (props: RenderIconInterface) => {
  return (
    <CustomIcons
      name={props.name}
      style={{ margin: "5px" }}
      height="25px"
      width="35px"
    />
  );
};

export default function AccountLevelSection() {
  const labels = AppLabels();
  const {
    isMaintenanceRoleAvailable,
    isTechnicianRoleAvailable
  } = GetTechInfo();
  const { data: configData } = GetConfigsProps();
  const accTypeInfo = GetActiveAccountDetails()?.accType;
  const brand = GetActiveAccountDetails()?.brand;
  const techRoles = GetTechInfo().roles;

  const [showModal, setShowModal] = useState(false);
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const itemData = [
    (isTechnicianRoleAvailable || isMaintenanceRoleAvailable) && {
      title: labels.PJI,
      headerTitle: labels.PreJob_Inspection_Form.toUpperCase(),
      popoverText: labels.PreJob_Inspection_Form,
      icon: <img src={thriveLogo} className="img" alt={labels.PJI} />,
      compToRender: <PreJobInspectionScreen setShowModal={setShowModal} />,
    },
    {
      title: labels.LOP,
      headerTitle: labels.Letter_of_Permission_Form.toUpperCase(),
      popoverText: labels.Letter_of_Permission_Form,
      icon: <RenderIcon name="signature" />,
      compToRender: <LetterOfPermissionScreen setShowModal={setShowModal} />,
    },
    {
      title: labels.SR,
      headerTitle: labels.Senior_Referral_Form.toUpperCase(),
      popoverText: labels.SeniorReferralPopupMsg,
      icon: <RenderIcon name="referral" />,
      compToRender: <SeniorReferralScreen setShowModal={setShowModal} />,
    },

    {
      title: labels.CB,
      headerTitle: labels.Coachback_Form,
      popoverText: labels.FeedBackPopupMsg,
      icon: <RenderIcon name="feedback" />,
      compToRender: <FeedBack setShowModal={setShowModal} />,
    },
    {
      title: labels.EPR,
      headerTitle: labels.Equipment_Pickup_Receipt_Form.toUpperCase(),
      popoverText: labels.Equipment_Pickup_Receipt,
      icon: <RenderIcon name="receipt" />,
      compToRender: (
        <EquipmentPickupReceiptScreen setShowModal={setShowModal} />
      ),
    },
    // {
    //   title: labels.RRF,
    //   headerTitle: labels.Riser_Repair_Referral,
    //   popoverText: labels.SeniorReferralPopupMsg,
    //   icon: <RenderIcon name="riser" />,
    //   compToRender: <RiserRepairScreen setShowModal={setShowModal} />,
    // },
    accTypeInfo === "DOCSIS" && {
      title: labels.NRF,
      headerTitle: labels.Noise_Referral_Form.toUpperCase(),
      popoverText: labels.Noise_Referral,
      icon: <RenderIcon name="noise" />,
      compToRender: <NoiseScreen setShowModal={setShowModal} />,
    },

    brand === "Rogers" &&
    configData?.ASP_FORM_SWITCH && {
      title: labels.ASP,
      headerTitle: labels.Add_Ignite_STBs_or_Pods.toUpperCase(),
      popoverText: labels.Add_Ignite_STBs_or_Pods,
      icon: <RenderIcon name="riser" />,
      compToRender: (
        <ASPScreen
          setShowModal={setShowModal}
          techType={ConfigConst?.ROGERS}
        />
      ),
    },

    // brand !== "Fido" &&
    //   (woType === "IGPO" || woType === "DPSI" || orderCategory === "SO") &&
    //   testType === "WHT" &&
    //   accType?.brand !== "Fido" &&
    //   accType?.accountNumber?.length === 9 && {
    //     title: labels.CEC,
    //     headerTitle: labels.Customer_Experience_Certification.toUpperCase(),
    //     popoverText: labels.CECPopupMsg,
    //     icon: <RenderIcon name="iec" />,
    //     compToRender: (
    //       <CustomerExperinceCertificationScreen setShowModal={setShowModal} />
    //     ),
    //   },
  ];

  const onItemPress = (index: number) => {
    setActiveItemIndex(index);
    setShowModal(true);
  };

  const onClosePopup = () => setShowModal(false);

  return (
    <section>
      {itemData?.map(
        (e, i) =>
          e && (
            <Fragment key={i}>
              <CustomPopOver
                position="bottom"
                popOverData={e?.popoverText}
                index="popOver"
                originComp={
                  <button
                    className="itemBoxWide"
                    onClick={() => onItemPress(i)}
                  >
                    {e?.icon}
                    <p className="title">{e?.title}</p>
                  </button>
                }
              />
              {i === activeItemIndex && (
                <Modal
                  show={showModal}
                  onHide={onClosePopup}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header className="searchPopHead">
                    <header>{e?.headerTitle}</header>
                    <button className="sPopUpClose" onClick={onClosePopup}>
                      <IoClose color="white" />
                    </button>
                  </Modal.Header>
                  <Modal.Body className="confirmBody">
                    {e?.compToRender}
                  </Modal.Body>
                </Modal>
              )}
            </Fragment>
          )
      )}
    </section>
  );
}
