import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import NodeInfoDetailsClient from "../../network/httpclient/nodeinfo/NodeInfoDetailsClient";
import FullPageLoader from "../reusable/FullPageLoader";
import SearchBoxMini, { setHistoryDisplay } from "./SearchBoxMini";
import { formatCamelCase } from "../reusable/Util";
//Images Import
import ExceptionBox from "../reusable/ExceptionBox";
import SearchPopUp from "./SearchPopUp";
import { ResetNodeInfoState } from "../../store/actions/node/nodeinfo/NodeInfoDetailsAction";
import { useGponNodeClient } from "../../network/httpclient/gpon/GponNodeClient";
import { getOltNameSearchDetails } from "../../../src/store/slices/gpon/oltNameSearchDetails";
import {
  GetGponNodeDetails,
  GetNodeInfoDetails,
  GetOltNameSearchDetails,
} from "../../get-api/GetResponse";
import { getTeleModemsList } from "../../topology/store/slices/mapItems/diagnostics/telemetry";
import { getActiveEleMarker } from "../../topology/util/utilFuncs";
import { setSearchedAddress } from "../../topology/store/slices/mapItems/mapDetails";
import appConst from "../../topology/constants/appConst";

const SearchByNodeUrl = (props: any) => {
  const {
    location: { search },
  } = props;
  const dispatch = useDispatch();
  const nodeInfoClient = useGponNodeClient();
  const { currNodedata } = GetNodeInfoDetails();
  const { nodeInfo } = GetGponNodeDetails();
  const { oltResultData } = GetOltNameSearchDetails();
  const [modalShow, setModalShow] = useState(false);
  const query = new URLSearchParams(search);
  const [nodeSearchValue, setNodeSearchValue] = useState<string | null>("");
  const [nodeSearchType, setNodeSearchType] = useState("");
  const [searchHistory, setSearchHistory] = useState([]);
  const [coords, setCoords] = useState<any>();

  useEffect(() => {
    if (search && search.includes("nodeId")) {
      const lat = query.get("lat");
      const lng = query.get("lng");
      if (lat && lng) setCoords({ lat: Number(lat), lng: Number(lng) });
      setNodeSearchType("Shub");
      setNodeSearchValue(query.get("nodeId"));
    } else if (search && search.includes("postalId")) {
      setNodeSearchType("Postal Code");
      setNodeSearchValue(query.get("postalId"));
    } else if (search && search.includes("smtId")) {
      setNodeSearchType("Smt");
      setNodeSearchValue(query.get("smtId"));
    } else if (search && search.includes("gponId")) {
      setNodeSearchType(appConst.NODE);
      setNodeSearchValue(query.get("gponId"));
    } else if (search && search.includes("oltName")) {
      setNodeSearchType(appConst.OLT);
      setNodeSearchValue(query.get("oltName"));
    }
  }, [query]);

  useEffect(() => {
    if (
      (nodeSearchType == "Shub" || nodeSearchType == "Smt") &&
      nodeSearchValue !== null &&
      currNodedata === undefined
    ) {
      //const modemType = search && search.includes("/node/emta") ? "emta" : "";

      // console.log(
      //   "search && search.includes",

      //   modemType
      // );
      // if (modemType === "emta") {
      //   dispatch(
      //     getTeleModemsList({
      //       value: nodeSearchValue,
      //       type: nodeSearchType?.toLowerCase() === "shub" ? "shubid" : "smtid",
      //       diagnoseDevices: true,
      //       modemType: modemType,
      //     })
      //   );
      // } else {
      //   console.log("modemType esle");
      dispatch(NodeInfoDetailsClient.getInfoNodeDetails(nodeSearchValue));
      if (coords) dispatch(setSearchedAddress(getActiveEleMarker(coords)));
      // }
    }
    if (nodeSearchType == "Postal Code" && nodeSearchValue !== null) {
      dispatch(
        NodeInfoDetailsClient.getNodeDetailsByPostalCode(nodeSearchValue)
      );
    }
    if (
      nodeSearchType.trim().toLowerCase() === "node" &&
      nodeSearchValue !== null &&
      nodeSearchValue?.trim() !== "" &&
      nodeSearchValue !== undefined &&
      nodeSearchValue?.trim() !== "undefined" &&
      nodeInfo?.data === undefined
    ) {
      (async () => {
        await nodeInfoClient
          .getNodesInfo(nodeSearchValue, "Maestro")
          .then((res) => {
            // add to search history if api call is successful
            setHistoryDisplay(nodeSearchValue);
          });
      })();
    }
    if (
      nodeSearchType.trim().toLowerCase() === "olt" &&
      nodeSearchValue !== null &&
      nodeSearchValue?.trim() !== "" &&
      nodeSearchValue !== undefined &&
      nodeSearchValue?.trim() !== "undefined" &&
      oltResultData === undefined
    ) {
      dispatch(getOltNameSearchDetails(nodeSearchValue));
      setHistoryDisplay(nodeSearchValue);
    }
  }, [nodeSearchValue]);

  useEffect(() => {
    let suggestions: any = localStorage.getItem("searchHistory");
    if (suggestions === null) {
      localStorage.setItem("searchHistory", JSON.stringify([]));
    } else {
      suggestions = JSON.parse(suggestions);
      setSearchHistory(suggestions);
    }
    if (nodeSearchValue === null || nodeSearchValue === "") {
      setModalShow(true);
    }
  }, []);

  const handleSearchClose = () => {
    setModalShow(false);
    //dispatch(ResetSearchNodeState());
    dispatch(ResetNodeInfoState());
  };

  return (
    <div className="searchContainer">
      {/* <picture>
        <source media="(max-width: 376px)" srcSet={techImgMob} />
        <source media="(max-width: 769px)" srcSet={techImgTab} />
        <source media="(max-width: 1025px)" srcSet={techImgMed} />
        <img className="searchImage" src={techImgLarge} alt="techImage" />
      </picture> */}
      {nodeSearchValue === null || nodeSearchValue === "" ? (
        <>
          <div className="diagErrorCont">
            <ExceptionBox
              headerText="Node data is not available."
              bodyText="Once you search a Node it will reflect here."
              primaryBtn={false}
              secBtn={false}
            />
          </div>
          <SearchPopUp show={modalShow} onHide={handleSearchClose} />
        </>
      ) : (
        <SearchBoxMini
          selectedValue={formatCamelCase(nodeSearchType)}
          searchValue={nodeSearchValue}
          searchHistory={searchHistory}
          errorBoxStyle={{ position: "relative", top: 10 }}
        />
      )}
      <FullPageLoader />
    </div>
  );
};
export default SearchByNodeUrl;
