import React, { useState } from "react";
import { Divider } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { Form } from "react-bootstrap";
import { CSVLink } from "react-csv";

type Props = {
  show: boolean;
  handleClose: () => void;
  headerText?: string;
  cancelText?: string;
  popUpType?: string;
  columnData?: any;
  rowData?: any;
  handleRowData?: (rowData: any) => any;
  handleColumnData?: (columnData: any) => any;
  handleCustom?: boolean;
  handleExport?: (fileName: string) => void;
  getDefaultLibFileName?: () => string;
};

export const ExportCsv = (props: Props) => {
  const {
    show,
    handleClose,
    headerText,
    cancelText,
    popUpType,
    columnData,
    rowData,
    handleCustom = false,
    handleRowData,
    handleColumnData,
    handleExport,
    getDefaultLibFileName = () => "export.csv",
  } = props;

  const [fileName, setFileName] = useState("");
  const [header, setHeader] = useState([]);
  const [inputVal, setInputVal] = useState("");
  const [data, setData] = useState([]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={() => handleClose()}
        backdrop="static"
        keyboard={false}
        centered
        id="sAPopUp"
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header className="searchPopHead">
            <header>{headerText}</header>
            <button className="sPopUpClose" onClick={() => handleClose()}>
              <IoClose color="white" />
            </button>
          </Modal.Header>
          <Modal.Body className="confirmBody">
            <div className="confirmBodyCont">
              <div>
                <b>
                  {" "}
                  <h6>File Name :</h6>
                </b>
                <input
                  style={{
                    minHeight: "25px",
                    minWidth: "250px",
                    width: "100%",
                  }}
                  type="text"
                  className="csvfilenameinputbox"
                  defaultValue={getDefaultLibFileName()}
                  name="csvFileName"
                  placeholder={getDefaultLibFileName()}
                  onChange={(e) => {
                    setInputVal(e.target.value.toString());
                  }}
                />
                <br />
                <br />
              </div>
            </div>
          </Modal.Body>
          <Divider orientation="horizontal" />
          <Modal.Footer>
            {popUpType !== "error" && (
              <button className="sPopCloseBtn" onClick={() => handleClose()}>
                {cancelText}
              </button>
            )}
            <CSVLink
              data={data}
              headers={header}
              filename={fileName + ".csv"}
              className="btn btn-primary btn-xl"
              onClick={async () => {
                if (handleExport) {
                  handleExport(fileName);
                  handleClose();
                } else {
                  if (inputVal?.trim() !== "" && inputVal !== undefined) {
                    setFileName(inputVal);
                  } else {
                    setFileName(getDefaultLibFileName());
                  }
                  if (handleCustom) {
                    if (handleRowData && handleColumnData) {
                      const processedRowData = await handleRowData(rowData);
                      const processedColumnData = await handleColumnData(
                        columnData
                      );
                      setHeader(processedColumnData);
                      setData(processedRowData);

                      handleClose();
                    }
                  } else {
                    setHeader(columnData);
                    setData(rowData);
                    handleClose();
                  }
                }
              }}
              target="_blank"
            >
              Export
            </CSVLink>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};
