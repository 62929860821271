import { createSlice } from "@reduxjs/toolkit";
import { changeTimeFormat } from "../../../components/reusable/Util";
import { apiCallBegan } from "../../../topology/store/middleware/apiActions";
import {
  MultiDeviceONT,
  NETWORK_SERVICE_URL,
} from "../../../topology/store/middleware/apiEndpoints";
import storeConst from "../../../topology/store/storeConst";
import {
  convertToJson,
  customParserForSummary,
} from "../../../topology/util/utilFuncs";

const slice = createSlice({
  name: "multiONT",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
    startTime: undefined,
    endTime: undefined,
  },
  reducers: {
    getMultipleONTBegin: (tl) => {
      let startTime: any = changeTimeFormat(Date.now());
      tl.status = "";
      tl.error = undefined;
      tl.startTime = startTime;
      tl.isLoading = true;
    },
    getMultipleONTSuccess: (tl, { payload }) => {
      // let dataChunkArray = convertStreamDataToJson(payload);

      // dataChunkArray.forEach((dataChunk: any) => {
      //   const accountData = dataChunk.ONTResponse?.data;

      //   if (accountData && typeof accountData.summary === "string") {
      //     try {
      //       const dataFormat1 = '{"key": "value"}';
      //       accountData.summary = convertToJson(
      //         dataFormat1,
      //         accountData.summary
      //       );
      //       const customParserForFormat2 = (data: string) => {
      //         return data.split(",");
      //       };
      //       console.log("Parsed Item.Summary:", accountData.summary);
      //     } catch (error) {
      //       console.error("Failed to parse summary:", error);
      //     }
      //   }
      // });
      const jsonData = convertToJson(payload, customParserForSummary);

      tl.isLoading = false;
      tl.data = jsonData;
      let endTime: any = changeTimeFormat(Date.now());
      tl.endTime = endTime;
      tl.status = storeConst.OK;
      tl.error = undefined;
    },
    getMultipleONTFailure: (tl, { payload }) => {
      let endTime: any = changeTimeFormat(Date.now());
      tl.isLoading = false;
      tl.endTime = endTime;
      tl.status = storeConst.ERROR;
      tl.error = payload;
    },
  },
});

export const {
  getMultipleONTBegin,
  getMultipleONTSuccess,
  getMultipleONTFailure,
} = slice.actions;

export default slice.reducer;

export const getMultipleONTData = (deviceONT: any) => (dispatch: any) => {
  dispatch(
    apiCallBegan({
      baseURL: NETWORK_SERVICE_URL + "diag/network",
      url: MultiDeviceONT,
      method: "post",
      data: deviceONT,
      responseType: "stream",
      onStart: getMultipleONTBegin.type,
      onSuccess: getMultipleONTSuccess.type,
      onError: getMultipleONTFailure.type,
    })
  );
};
