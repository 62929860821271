import AppLabels from "../../constants/App_Labels";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import "../../design-tokens/account-page/FailedAttributes.scss";
import { formatCamelCase } from "../reusable/Util";
import { isNull } from "../reusable/Util";
const FailedAttributesIptv = (props: any) => {
  const { ethernetData, wifiData } = props;

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "#000000",
      boxShadow: theme.shadows[4],
      fontSize: 12,
    },
  }))(Tooltip);

  const labels = AppLabels();

  const arr: any = [];
  const data: any = ethernetData;

  if (data !== "" && data !== undefined) {
    const ethernetDataArray = [];
    if (data?.ethernetStatus?.status === "Fail") {
      ethernetDataArray.push({
        name: formatCamelCase("ethernetStatus"),
        value: isNull(data?.ethernetStatus?.value),
      });
    }
    if (ethernetDataArray?.length > 0) {
      arr.push({
        name: "ETHERNET_DATA",
        value: ethernetDataArray,
        labels: [labels.NAME.toUpperCase(), labels.VALUE.toUpperCase()],
      });
    }
  }
  if (wifiData !== "" && wifiData !== undefined) {
    const WifiDataArray = [];
    if (wifiData?.signalStrength?.status == "Fail") {
      WifiDataArray.push({
        name: formatCamelCase("signalStrength"),
        value: isNull(wifiData?.signalStrength?.value),
      });
    }
    if (WifiDataArray?.length > 0) {
      arr.push({
        name: "WIFI_DATA",
        value: WifiDataArray,
        labels: [labels.NAME.toUpperCase(), labels.VALUE.toUpperCase()],
      });
    }
  }
  return (
    <div className="faCmGW">
      <p>
        <span style={{ fontWeight: 600 }}>{labels.failedAttributes?.toUpperCase()} : </span>
        {arr ? (
          arr?.map((el: any, index: any) => {
            return (
              <LightTooltip
                title={
                  <table className="tableTypeTwo">
                    <thead>
                      <tr>
                        <th>{labels.Name?.toUpperCase()}</th>
                        <th>{labels.VALUE?.toUpperCase()}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {el?.value?.map((v: any, i: any) => {
                        return (
                          <tr key={i}>
                            <td>{v?.name}</td> <td>{v?.value}</td>{" "}
                          </tr>
                        );
                      })}{" "}
                    </tbody>
                  </table>
                }
              >
                <span className="tooltipText" style={{ fontWeight: 500 }}>
                  {el?.name},{" "}
                </span>
              </LightTooltip>
            );
          })
        ) : (
          <></>
        )}
      </p>
    </div>
  );
};

export default FailedAttributesIptv;
