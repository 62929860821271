export const SAVE_GPON_NODES = 'SAVE_GPON_NODES';
export const UPDATE_GPON_NODES = 'UPDATE_GPON_NODES';
export const RESET_GPON_NODES = 'RESET_GPON_NODES';
export const INCREMENT_GPON_NODE_API_CALL_COUNTER =
    'INCREMENT_GPON_NODE_API_CALL_COUNTER';
export const DECREMENT_GPON_NODE_API_CALL_COUNTER =
    'DECREMENT_GPON_NODE_API_CALL_COUNTER';

interface SaveGponNodes {
    type: typeof SAVE_GPON_NODES;
    payload: any;
}

interface UpdateGponNodeDevices {
    type: typeof UPDATE_GPON_NODES;
    snNumber: string;
    subdataKey: string;
    data: any;
    status: string;
}
interface ResetGponNodes {
    type: typeof RESET_GPON_NODES;
}
interface IncrementGponNodeApiCallCounter {
    type: typeof INCREMENT_GPON_NODE_API_CALL_COUNTER;
    snNumber: string;
    subDataKey: string;
}
interface DecrementGponNodeApiCallCounter {
    type: typeof DECREMENT_GPON_NODE_API_CALL_COUNTER;
    snNumber: string;
    subDataKey: string;
}

export type GponNodesActionTypes =
    | SaveGponNodes
    | UpdateGponNodeDevices
    | ResetGponNodes
    | IncrementGponNodeApiCallCounter
    | DecrementGponNodeApiCallCounter;
