import { Divider } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import { BiErrorCircle } from "react-icons/bi";
import { BsInfoCircleFill } from "react-icons/bs";
import { ImWarning } from "react-icons/im";
import { IoClose } from "react-icons/io5";

interface popUpProps {
  showVal: any;
  headerText: string;
  bodyHeadText?: string;
  bodyText?: string;
  popUpType?: string;
  handleConfirm: any;
  handleClose: any;
  confirmText: string;
  cancelText?: string;
}

const ConfirmPopUp = (props: popUpProps) => {
  const {
    showVal,
    headerText,
    bodyHeadText,
    bodyText,
    handleConfirm,
    handleClose,
    confirmText,
    cancelText,
    popUpType,
  } = props;
  return (
    <div>
      <Modal
        show={showVal}
        onHide={() => handleClose()}
        backdrop="static"
        keyboard={false}
        centered
        id="sAPopUp"
      >
        <Modal.Header className="searchPopHead">
          <header>{headerText}</header>
          <button className="sPopUpClose" onClick={() => handleClose()}>
            <IoClose color="white" />
          </button>
        </Modal.Header>
        <Modal.Body className="confirmBody">
          {popUpType === "info" ? (
            <BsInfoCircleFill className="confirmIcon" color="#1A86E9" />
          ) : popUpType === "error" ? (
            <BiErrorCircle className="confirmIcon" />
          ) : (
            <ImWarning className="confirmIcon" color="#ffbf3f" />
          )}
          <div className="confirmBodyCont">
            <h5>{bodyHeadText}</h5>
            <p>{bodyText}</p>
          </div>
        </Modal.Body>
        <Divider orientation="horizontal" />
        <Modal.Footer>
          {popUpType === "error" ? (
            <></>
          ) : (
            <button className="sPopCloseBtn" onClick={() => handleClose()}>
              {cancelText}
            </button>
          )}
          <button className="sPopBtn" onClick={() => handleConfirm()}>
            {confirmText}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfirmPopUp;
