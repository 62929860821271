import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import {
  isNull,
  getThreshold,
  getStatusOfCheck,
  getCheckValue,
  isEmptyObject,
  isObject,
  getStatusOfCheckHeader,
} from "../../reusable/Util";
import { useState } from "react";
import AppLabels from "../../../constants/App_Labels";
import CustomPopOver from "../../reusable/CustomPopOver";
const DocsisUpChData = (props: any) => {
  const labels = AppLabels();
  const { docsis30UsChannels } = props;
  const [summaryAcc, setSummaryAcc] = useState(true);
  const [detailsAcc, setDetailsAcc] = useState(false);

  const handleAccToggle = (accName: string) => {
    if (accName === "summary") setSummaryAcc(!summaryAcc);
    else if (accName === "details") setDetailsAcc(!detailsAcc);
  };

  return (
    <div className="ofdmChannelCont">
      <div className="ofdmChSummary">
        <div className="dsChDataAcc" onClick={() => handleAccToggle("summary")}>
          <p>{labels.SUMMARY.toUpperCase()}</p>
          {summaryAcc ? (
            <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
          ) : (
            <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
          )}
        </div>
        {summaryAcc ? (
          <table className="dataTableDetails">
            <thead>
              <tr>
                <th>{labels.NAME.toUpperCase()}</th>
                <th>{labels.VALUE.toUpperCase()}</th>
                <th>{labels.STATUS.toUpperCase()}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {" "}
                  {getStatusOfCheckHeader(
                    docsis30UsChannels?.maxTxPower,
                    "UP TX MAX"
                  )}
                </td>
                {docsis30UsChannels?.hasOwnProperty("maxTxPower") ? (
                  <>
                    <td>
                      {isObject(docsis30UsChannels.maxTxPower)
                        ? isNull(getCheckValue(docsis30UsChannels.maxTxPower))
                        : ""}
                    </td>
                    <CustomPopOver
                      popOverData={
                        isObject(docsis30UsChannels.maxTxPower)
                          ? isNull(getThreshold(docsis30UsChannels.maxTxPower))
                          : ""
                      }
                      originComp={
                        isObject(docsis30UsChannels.maxTxPower)
                          ? isNull(
                            getStatusOfCheck(docsis30UsChannels.maxTxPower)
                          )
                          : ""
                      }
                      index="popOver"
                    />
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td>
                  {" "}
                  {getStatusOfCheckHeader(
                    docsis30UsChannels?.minTxPower,
                    "UP TX MIN"
                  )}
                </td>
                {docsis30UsChannels?.hasOwnProperty("minTxPower") ? (
                  <>
                    <td>
                      {isObject(docsis30UsChannels.minTxPower)
                        ? isNull(getCheckValue(docsis30UsChannels.minTxPower))
                        : ""}
                    </td>
                    <CustomPopOver
                      popOverData={
                        isObject(docsis30UsChannels.minTxPower)
                          ? isNull(getThreshold(docsis30UsChannels.minTxPower))
                          : ""
                      }
                      originComp={
                        isObject(docsis30UsChannels.minTxPower)
                          ? isNull(
                            getStatusOfCheck(docsis30UsChannels.minTxPower)
                          )
                          : ""
                      }
                      index="popOver"
                    />
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td>
                  {" "}
                  {getStatusOfCheckHeader(
                    docsis30UsChannels?.txPowerDelta,
                    "UP TX DELTA"
                  )}
                </td>
                <td>{isNull(docsis30UsChannels.txPowerDelta)}</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  {" "}
                  {getStatusOfCheckHeader(
                    docsis30UsChannels?.averageTxPower,
                    "UP TX AVG"
                  )}
                </td>
                {docsis30UsChannels?.hasOwnProperty("averageTxPower") ? (
                  <>
                    <td>
                      {isObject(docsis30UsChannels.averageTxPower)
                        ? isNull(
                          getCheckValue(docsis30UsChannels.averageTxPower)
                        )
                        : ""}
                    </td>

                    <CustomPopOver
                      popOverData={
                        isObject(docsis30UsChannels.averageTxPower)
                          ? isNull(
                            getThreshold(docsis30UsChannels.averageTxPower)
                          )
                          : ""
                      }
                      originComp={
                        isObject(docsis30UsChannels.averageTxPower)
                          ? isNull(
                            getStatusOfCheck(
                              docsis30UsChannels.averageTxPower
                            )
                          )
                          : ""
                      }
                      index="popOver"
                    />
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td>{labels.MODULATION.toUpperCase()}</td>
                <td>{isNull(docsis30UsChannels.modulation)}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        ) : (
          <></>
        )}
      </div>
      <div className="ofdmChDetails">
        <div className="dsChDataAcc" onClick={() => handleAccToggle("details")}>
          <p>{labels.CHANNELS.toUpperCase()}</p>
          {detailsAcc ? (
            <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
          ) : (
            <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
          )}
        </div>
        {detailsAcc ? (
          <table className="dataTableDetails">
            <thead>
              <tr>
                <th id="nameCol">{labels.FREQUENCY}</th>
                <th id="valueCol">{labels.TX_POWER.toUpperCase()}</th>
                <th id="valueCol">{labels.SNR.toUpperCase()}</th>
                <th id="valueCol">{labels.STATUS.toUpperCase()}</th>
              </tr>
            </thead>
            <tbody>
              {docsis30UsChannels?.hasOwnProperty("channels") &&
                docsis30UsChannels.channels?.length >= 0 ? (
                docsis30UsChannels.channels
                  ?.slice()
                  .sort((a: any, b: any) =>
                    a.frequency > b.frequency ? 1 : -1
                  )
                  ?.map((cb: any, index: number) => {
                    return (
                      <tr>
                        <td>{isNull(cb.frequency)}</td>
                        {cb?.hasOwnProperty("txPower") ? (
                          <td>
                            {isNull(getCheckValue(isEmptyObject(cb.txPower)))}
                          </td>
                        ) : (
                          <td></td>
                        )}
                        {cb?.hasOwnProperty("snr") ? (
                          <td>
                            {isNull(getCheckValue(isEmptyObject(cb.snr)))}
                          </td>
                        ) : (
                          <td></td>
                        )}
                        {cb?.hasOwnProperty("status") ? (
                          <td>
                            {isNull(getCheckValue(isEmptyObject(cb.status)))}
                          </td>
                        ) : (
                          <td></td>
                        )}
                      </tr>
                    );
                  })
              ) : (
                <></>
              )}
            </tbody>
          </table>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default DocsisUpChData;
