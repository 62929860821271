import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useEffect, useState } from "react";
import CustomDropDown from "../../../../../components/reusable/CustomDropDown";
import TopologyLabels from "../../../../constants/topologyLabels";
import DataCard from "../../../reusable/DataCard";
import AdvFilterData from "../../../../data/AdvFilterData";
import NumberStepper from "../../../reusable/NumberInputBox";
import { GetTelemetryProps } from "../../../../util/reduxFunctions/getTopologyState";
interface Props {
  filtersData: any;
  setState: any;
}
const Summaryfilters = ({ filtersData, setState }: Props) => {
  const tlabels = TopologyLabels();
  let advFilterData = AdvFilterData();
  const { advancedFilters } = GetTelemetryProps();
  const summaryAdvFData = advancedFilters?.regtimeFlaps;
  const [summaryAdvFilters, setSummaryAdvFilters] = useState<any>(filtersData);
  const [advFilterFlag, setAdvFilterFlag] = useState<boolean>(true);
  var now = new Date();
  const updateFilter = (filterKey: string, value: any) => {
    if (filterKey) {
      let filter = {
        label: summaryAdvFilters?.[filterKey]?.label,
        operator: summaryAdvFilters?.[filterKey]?.operator,
        value: value,
      };
      setSummaryAdvFilters({
        ...summaryAdvFilters,
        ...{ [filterKey]: filter },
      });
    }
  };
  useEffect(() => {
    if (summaryAdvFilters) {
      let summFilterObj: any = {};
      Object?.keys(summaryAdvFilters)?.map((fKey: any) => {
        summFilterObj = {
          ...summFilterObj,
          ...{ [fKey]: summaryAdvFilters?.[fKey]?.value },
        };
      });
      setState(summFilterObj);
    }
  }, [summaryAdvFilters]);

  useEffect(() => {
    if (summaryAdvFData && advFilterFlag) {
      let summaryFilterObj: any = {};
      let summaryFObjKeys = Object.keys(filtersData);
      summaryFObjKeys?.map((fKey: any) => {
        let filterObj = {
          ...filtersData[fKey],
          ...{ value: summaryAdvFData?.[fKey] },
        };
        summaryFilterObj = {
          ...summaryFilterObj,
          ...{ [fKey]: filterObj },
        };
      });
      setSummaryAdvFilters(summaryFilterObj);
      setAdvFilterFlag(false);
    }
  }, [advancedFilters]);

  useEffect(() => {
    if (!summaryAdvFData || !advFilterFlag) {
      setSummaryAdvFilters(filtersData);
    }
  }, [filtersData]);

  return (
    <div className="summaryAdvFilters">
      <DataCard
        cardHeader={tlabels.regFlaps}
        cardBody={
          <div className="advFilterBody">
            <p className="advfilterRow">
              <span className="filterGroup">
                <span className="filterLabel">
                  {summaryAdvFilters?.regTimeCompOperator?.label?.toUpperCase()}
                </span>
                <span className="filterComparator">:</span>
                <CustomDropDown
                  selectedOption={summaryAdvFilters?.regTimeCompOperator?.value}
                  options={advFilterData?.compOperatorList}
                  searchFlag={false}
                  checkboxFlag={false}
                  setSelectedOption={(value: any) => {
                    updateFilter("regTimeCompOperator", value);
                  }}
                />
                <CustomDropDown
                  ddTitle={summaryAdvFilters?.day?.label?.toUpperCase()}
                  selectedOption={summaryAdvFilters?.day?.value}
                  options={advFilterData?.regTimeDOptions}
                  searchFlag={false}
                  checkboxFlag={false}
                  setSelectedOption={(value: any) => {
                    updateFilter("day", value);
                  }}
                />

                <CustomDropDown
                  ddTitle={summaryAdvFilters?.hour?.label?.toUpperCase()}
                  selectedOption={summaryAdvFilters?.hour?.value}
                  options={advFilterData?.regTimeHOptions}
                  searchFlag={false}
                  checkboxFlag={false}
                  setSelectedOption={(value: any) => {
                    updateFilter("hour", value);
                  }}
                />
              </span>
              <span className="filterGroup">
                <span className="filterLabel">
                  {summaryAdvFilters?.firstFlap?.label?.toUpperCase()}
                </span>
                <span className="filterComparator">
                  {summaryAdvFilters?.firstFlap?.operator}
                </span>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    value={
                      summaryAdvFilters?.firstFlap?.value
                        ? summaryAdvFilters?.firstFlap?.value
                        : now
                    }
                    onChange={(date: any) => updateFilter("firstFlap", date)}
                    format="yyyy/MM/dd hh:mm a"
                    disableFuture={true}
                  />
                </MuiPickersUtilsProvider>
              </span>
              <span className="filterGroup">
                <span className="filterLabel">
                  {summaryAdvFilters?.lastFlap?.label?.toUpperCase()}
                </span>
                <span className="filterComparator">
                  {summaryAdvFilters?.lastFlap?.operator}
                </span>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    value={
                      summaryAdvFilters?.lastFlap?.value
                        ? summaryAdvFilters?.lastFlap?.value
                        : now
                    }
                    onChange={(date: any) => updateFilter("lastFlap", date)}
                    format="yyyy/MM/dd hh:mm a"
                    disableFuture={true}
                  />
                </MuiPickersUtilsProvider>
              </span>
            </p>

            <p className="advfilterRow">
              <span className="filterLabel">
                {summaryAdvFilters?.flapCount?.label?.toUpperCase()}
              </span>
              <span className="filterComparator">
                {summaryAdvFilters?.flapCount?.operator}
              </span>
              <NumberStepper
                value={summaryAdvFilters?.flapCount?.value}
                setValue={(v: any) => updateFilter("flapCount", v)}
              />
              <span className="filterComparator"></span>
              <span className="filterLabel">
                {summaryAdvFilters?.inf?.label?.toUpperCase()}
              </span>
              <span className="filterComparator">
                {summaryAdvFilters?.inf?.operator}
              </span>
              <NumberStepper
                value={summaryAdvFilters?.inf?.value}
                setValue={(v: any) => updateFilter("inf", v)}
              />
              <span className="filterComparator"></span>
              <span className="filterLabel">
                {summaryAdvFilters?.pwr?.label?.toUpperCase()}
              </span>
              <span className="filterComparator">
                {summaryAdvFilters?.pwr?.operator}
              </span>
              <NumberStepper
                value={summaryAdvFilters?.pwr?.value}
                setValue={(v: any) => updateFilter("pwr", v)}
              />
            </p>
          </div>
        }
      />
    </div>
  );
};

export default Summaryfilters;
