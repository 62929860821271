import { Divider } from "@material-ui/core";

interface Props {
  cardHeader: string;
  cardBody: any;
}
const DataCard = ({ cardHeader, cardBody }: Props) => {
  return (
    <section className="channelCard">
      <div className="cmChDetailCont">
        <p className="cmChDetailHead">
          <span>
            <b>{cardHeader.toUpperCase()}</b>
          </span>
        </p>
        <Divider />
      </div>
      <>{cardBody}</>
    </section>
  );
};

export default DataCard;
