import { useEffect, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { BsCheckCircle } from "react-icons/bs";
import { IoAlertCircle } from "react-icons/io5";
import { useDispatch } from "react-redux";
import CertificationClient from "../../../network/httpclient/certification/CertificationClient";
import ConfirmPopUp from "../../reusable/ConfirmPopUp";
import { TESTTYPE_CONFIG } from "../../../constants/CertificationsConfig";
import { isNull } from "../../reusable/Util";
import {
  GetTechInfo,
  GetActiveAccountDetails,
  GetWorkOrdersList,
  GetWorkOrderDetails,
} from "../../../get-api/GetResponse";
import { getWorkOrderDetails } from "../../../store/slices/workOrder/workOrderDetails";

const CustInfoStep = (props: any) => {
  const {
    currentStep,
    handleCancel,
    clearActiveCerti,
    disableStepClicks,
    handlePrev,
    handleNext,
    enableStepClicks,
  } = props;

  const dispatch = useDispatch();
  const [stepCompletedFlag, setStepCompletedFlag] = useState(false);
  const [showPOPUp, setShowPOPUp] = useState(false);
  const [valErrorFlag, setValErrorFlag] = useState(false);
  const [errorMSG, setErrorMSG] = useState("");
  const [showWarnPOPUp, setShowWarnPOPUp] = useState(false);
  const [showSPOPUp, setShowSPOPUp] = useState(false);
  const [resCode, setResCode] = useState("");
  const [productType, setProductType] = useState(false);
  const [legacyproductType, setLegacyproductType] = useState(false);

  const { data: techData, activeWorkOrder: wOID } = GetTechInfo();
  const workOrdersList = GetWorkOrdersList();
  const { data: wodData } = GetWorkOrderDetails();
  const { brand, data, selectedSA, accType: accountTopology, certiData } = GetActiveAccountDetails();

  const [wOrderId, setWOrderID] = useState(certiData?.data?.workOrderId);
  const orderCategory = workOrdersList?.activeOrderCategory
    ? workOrdersList?.activeOrderCategory
    : workOrdersList?.workOrderDetails?.orderCategory;
  const woType = workOrdersList?.activeWorkType
    ? workOrdersList?.activeWorkType
    : workOrdersList?.workOrderDetails?.workType;
  const productList = wodData?.productList;

  useEffect(() => {
    if (wOID !== undefined && wOID !== "") setWOrderID(wOID);
    productList?.some((po: any) => {
      if (po?.includes("PRD: WPIN") || po?.includes("PRD: FMDR")) {
        return setProductType(true);
      } else if (
        po?.includes("PRD: WFUL") ||
        po?.includes("PRD: TIFH") ||
        po?.includes("PRD: TRBK")
      ) {
        return setLegacyproductType(true);
      }
    });
  }, [
    wOID,
    legacyproductType,
    productList,
    brand,
    woType,
    productType,
    orderCategory,
  ]);

  const createCertiObject = (workOrderID: string): any => {
    let testCode = TESTTYPE_CONFIG.WHT.testTypeCode;
    let certiObject = {
      status: "INPROGRESS",
      accountNumber: data.accountNumber,
      testType: testCode,
      workOrderId: workOrderID,
      brand: data.brand,
      topology: accountTopology,
      technician: {
        lanId: isNull(techData?.lanId),
        techId: isNull(techData?.techId),
        companyNumber: isNull(techData?.companyId),
        contactNumber: isNull(techData?.MobilePhone),
      },
      address: {
        samKey: selectedSA.samKey,
        streetNumber: selectedSA.streetNumber,
        streetName: selectedSA.streetName,
        streetType: selectedSA.streetType,
        streetCompass: selectedSA.direction,
        aptNumber: selectedSA.apartmentNumber,
        cityName: selectedSA.city,
        province: selectedSA.province,
        postalCode: selectedSA.postalCode,
      },

      workflow: {
        status: "INPROGRESS",
        completedSteps: [1],
      },
    };
    return certiObject;
  };
  const isValidWID = (woid: string): boolean => {
    let wOrderID = /^([0-9a-zA-Z]{13})$/;
    if (woid !== undefined && woid.match(wOrderID)) {
      if (data?.accountNumber?.length === 12 || data?.accountType === "R") {
        if (woid?.toLowerCase()?.startsWith("c")) return true;
        else return false;
      } else if (data?.accountNumber?.length === 9) {
        if (woid?.toLowerCase()?.startsWith("w")) return true;
        else return false;
      } else return false;
    } else return false;
  };
  const completeStep1 = () => {
    if (isValidWID(wOrderId)) {
      if (
        wOrderId !== "" &&
        wOrderId !== null &&
        resCode !== "409" &&
        resCode !== "201" &&
        resCode !== "200"
      ) {
        let newCerti = createCertiObject(wOrderId);
        const base64EncObj = { data: btoa(JSON.stringify(newCerti)) };
        dispatch(CertificationClient.createCerti(base64EncObj));
        dispatch(getWorkOrderDetails(wOID));
      } else if (
        wOrderId !== "" &&
        wOrderId !== null &&
        certiData?.data?.workOrderId !== wOrderId
      ) {
        if (resCode === "409" || resCode === "201" || resCode === "200") {
          setErrorMSG(
            "Changing workorder ID for an inprogress certification, will replace previous workorder ID. Are you sure to proceed?"
          );
          setShowWarnPOPUp(true);
        }
      }
    }
  };
  const handleResume = () => {
    if (certiData !== undefined && certiData?.hasOwnProperty("data")) {
      let completeStepArray = certiData?.data?.workflow?.completedSteps;
      setStepCompletedFlag(true);
      setShowPOPUp(false);
      enableStepClicks();
      if (completeStepArray?.length === 2) {
        if (completeStepArray?.indexOf(2) > -1) handleNext(2);
        else if (completeStepArray?.indexOf(3) > -1) handleNext(1);
      } else {
        handleNext(completeStepArray?.length);
      }
    }
  };
  const handleProceed = () => {
    let testCode = TESTTYPE_CONFIG.WHT.testTypeCode;
    let updateActiveObj = {};
    if (
      certiData?.data?.workOrderId !== wOrderId &&
      certiData?.data?.testType !== testCode
    ) {
      updateActiveObj =
      {
        workOrderId: wOrderId,
        technician: {
          lanId: isNull(techData?.lanId),
          techId: isNull(techData?.techId),
          companyNumber: isNull(techData?.companyId),
          contactNumber: isNull(techData?.MobilePhone),
        },
        testType: testCode,
        workflow: {
          completedSteps: [1],
        },
        hsiCertificate: {
          isApplicable: true,
        },
        wifiCertificate: {
          isApplicable: true,
        },
      }
    } else if (
      certiData?.data?.workOrderId === wOrderId &&
      certiData?.data?.testType !== testCode
    ) {
      updateActiveObj =
      {
        technician: {
          lanId: isNull(techData?.lanId),
          techId: isNull(techData?.techId),
          companyNumber: isNull(techData?.companyId),
          contactNumber: isNull(techData?.MobilePhone),
        },
        testType: testCode,
        workflow: {
          completedSteps: [1],
        },
        hsiCertificate: {
          isApplicable: true,
        },
        wifiCertificate: {
          isApplicable: true,
        },
      }
    } else if (
      certiData?.data?.workOrderId !== wOrderId &&
      certiData?.data?.testType === testCode
    ) {
      updateActiveObj = {
        workOrderId: wOrderId,
        technician: {
          lanId: isNull(techData?.lanId),
          techId: isNull(techData?.techId),
          companyNumber: isNull(techData?.companyId),
          contactNumber: isNull(techData?.MobilePhone),
        },
      };
    }
    const base64EncObj = { data: btoa(JSON.stringify(updateActiveObj)) };
    dispatch(CertificationClient.updateCerti(certiData?.id, base64EncObj));
    setShowWarnPOPUp(false);
    enableStepClicks();
  };
  useEffect(() => {
    if (certiData !== undefined && certiData?.hasOwnProperty("code")) {
      let tempresCode = certiData?.code;
      if (tempresCode === "201") {
        setResCode(tempresCode);
        setStepCompletedFlag(true);
        enableStepClicks();
      } else if (tempresCode === "409") {
        if (certiData?.data?.status !== "COMPLETE") {
          setResCode(tempresCode);
          setShowPOPUp(true);
        } else {
          setErrorMSG(
            "There is already a certification submitted for this account number and workorder combination. You cannot submit another one."
          );
          setShowSPOPUp(true);
        }
      } else if (tempresCode === "301") {
        setErrorMSG(
          "There is already a certificate in progress for this work order created by another technician. You cannot create another one."
        );
        disableStepClicks();
        setResCode("");
        setStepCompletedFlag(false);
        setShowSPOPUp(true);
      } else if (tempresCode === "300") {
        setErrorMSG(
          "There is already a certification submitted for this workorder. You cannot submit another one."
        );
        disableStepClicks();
        setResCode("");
        setStepCompletedFlag(false);
        setShowSPOPUp(true);
      } else if (tempresCode === "302") {
        setErrorMSG(
          "There is already a certificate in progress for this work order in another account. Please check the workorder and try again."
        );
        disableStepClicks();
        setResCode("");
        setStepCompletedFlag(false);
        setShowSPOPUp(true);
      } else {
        setResCode(tempresCode);
        setStepCompletedFlag(true);
      }
    }
    if (
      certiData !== undefined &&
      certiData?.hasOwnProperty("data") &&
      certiData?.data?.hasOwnProperty("workOrderId")
    ) {
      setWOrderID(certiData?.data?.workOrderId);
    }
  }, [certiData]);
  return (
    <div className="certiStepCont">
      <div className="certiCustInfo">
        <h4>Review Customer Information</h4>
        <p>
          This is important to ensure that the certification is submitted for
          the right customer and work order ID as per the service call.
        </p>
        <div className="certiInputCont">
          <div className="accNumCont">
            <label>Customer Account Number</label>
            <input type="text" value={data.accountNumber} disabled />
          </div>
          <div className="woIDCont">
            <label>Customer Work Order ID</label>
            <input
              type="text"
              placeholder="Type Work Order Id"
              value={wOrderId}
              onChange={(e) => {
                setWOrderID(
                  e.target?.value?.replace(/\s+/g, "")?.toUpperCase()
                );
                setValErrorFlag(false);
                setStepCompletedFlag(false);
              }}
            />
            {valErrorFlag ? (
              <span className="errorMsg">
                {data?.accountNumber?.length === 12 || data?.accountType === "R"
                  ? "Work Order ID should be 13 alphanumeric characters starting with 'C'."
                  : "Work Order ID should be 13 alphanumeric characters starting with 'W'."}
              </span>
            ) : (
              <></>
            )}
          </div>
          <button
            className="confirmBtn"
            onClick={() => {
              completeStep1();
            }}
          >
            Confirm
          </button>
          {stepCompletedFlag ? <BsCheckCircle color="#2DC071" /> : <></>}
          {resCode === "409" ? <IoAlertCircle color="#BF371F" /> : <></>}
        </div>
      </div>
      <div className="certiStepBtnCont">
        <button className="certiCancelBtn" onClick={() => handleCancel()}>
          Cancel
        </button>
        <button
          className="certiPrevBtn"
          onClick={() => handlePrev()}
          disabled={currentStep == 1 ? true : false}
        >
          <FaArrowLeft />
          <span>Previous</span>
        </button>
        <button
          className="certiNextBtn"
          onClick={() => handleNext(1)}
          disabled={!stepCompletedFlag}
        >
          <span>Next</span> <FaArrowRight />
        </button>
      </div>
      <ConfirmPopUp
        showVal={showPOPUp}
        headerText="INFORMATION"
        popUpType="info"
        bodyHeadText=""
        bodyText="There is already a certificate in progress for this work order. It will continue from where you left off. Would you like to resume the same?"
        cancelText="Cancel"
        confirmText="Resume"
        handleConfirm={() => handleResume()}
        handleClose={() => {
          clearActiveCerti();
          setResCode("");
          setShowPOPUp(false);
          disableStepClicks();
        }}
      />
      <ConfirmPopUp
        showVal={showWarnPOPUp}
        headerText="WARNING"
        bodyHeadText=""
        bodyText={errorMSG}
        cancelText="Cancel"
        confirmText="Proceed"
        handleConfirm={() => handleProceed()}
        handleClose={() => {
          setShowWarnPOPUp(false);
          disableStepClicks();
        }}
      />
      <ConfirmPopUp
        showVal={showSPOPUp}
        headerText="ERROR"
        popUpType="error"
        bodyHeadText=""
        bodyText={errorMSG}
        confirmText="Close"
        handleConfirm={() => {
          clearActiveCerti();
          setShowSPOPUp(false);
        }}
        handleClose={() => {
          clearActiveCerti();
          setShowSPOPUp(false);
        }}
      />
    </div>
  );
};

export default CustInfoStep;
