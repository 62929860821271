import { Snackbar } from "@material-ui/core";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { useCallback, useEffect, useRef, useState } from "react";
import { MdMyLocation } from "react-icons/md";
import { useDispatch } from "react-redux";

import NodeTopologySwitch from "../../../components/node/NodeTopologySwitch";
import ActivityIndicator from "../../../components/reusable/ActivityIndicator";
import ExceptionBox from "../../../components/reusable/ExceptionBox";
import AppLabels from "../../../constants/App_Labels";
import LoaderGif from "../../../resources/images/loader.webp";
import { defaultZoom, TopologyMapOptions } from "../../config/mapConfig";
import { DrawerViewType } from "../../store/reduxTypes";
import { setPressedCoord } from "../../store/slices/googleMaps";
import { setDrawerView } from "../../store/slices/mapDrawer";
import {
  setMapCenter,
  setShowRightClickMenu,
} from "../../store/slices/mapItems/mapDetails";
import {
  handleOnIdle,
  handleZoomChange,
} from "../../util/customHooks/mapEventHandlers";
import {
  GetGoogleMaps,
  GetMapDetailsProps,
  GetMapDrawerProps,
  GetNodeSiteDetailsProps,
  GetSettingsProps,
  GetSymbolsProps,
} from "../../util/reduxFunctions/getTopologyState";
import MapItems from "./MapItems";
import MapStyles from "./MapStyles";
import ElementRightClickMenu from "./mapUtility/ElementRightClickMenu";
import InfoWindowMenu from "./mapUtility/InfoWindowMenu";
import AddMemo from "./memo/AddMemo";
import ImageEditor from "./redline/ImageEditor";

interface Props {
  defaultMapCenter: any;
  gKey: string;
  isFromTopoStandaloneView?: Boolean;
}

const MapView = ({
  defaultMapCenter,
  gKey,
  isFromTopoStandaloneView = false,
}: Props) => {
  const labels = AppLabels();
  const dispatch = useDispatch();
  const mapContRef = useRef(null);

  const { zoomLevel, mapStyle } = GetGoogleMaps();
  const { isTopoEleLoading } = GetNodeSiteDetailsProps();
  const { isTopoEleImgLoading, pngImages } = GetSymbolsProps();
  const { mapIsLoading, mapCenter } = GetMapDetailsProps();
  const { drawerView } = GetMapDrawerProps();
  const { drawerAutoClose } = GetSettingsProps();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: gKey,
  });

  const [map, setMap] = useState<google.maps.Map>();
  const [mapLoading, setMapLoading] = useState(false);
  const [showInfoWin, setShowInfoWin] = useState(false);

  const onLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
  }, []);

  const onMapClick = useCallback((...args) => {
    setShowInfoWin(false);
    dispatch(setShowRightClickMenu(false));
    // dispatch(setShowChart(false));
  }, []);

  const onMapRigthClick = useCallback((...args) => {
    dispatch(
      setPressedCoord({
        lat: args?.[0]?.latLng?.lat(),
        lng: args?.[0]?.latLng?.lng(),
      })
    );
    setShowInfoWin(true);
  }, []);

  useEffect(() => {
    if (!isTopoEleLoading && !isTopoEleImgLoading && !mapIsLoading) {
      setMapLoading(false);
    } else if (isTopoEleLoading || isTopoEleImgLoading || mapIsLoading) {
      setMapLoading(true);
    }
  }, [isTopoEleLoading, isTopoEleImgLoading, mapIsLoading]);

  if (loadError) {
    return (
      <div className="diagErrorCont">
        <ExceptionBox
          headerText={labels.mapExceptionHeaderMsg}
          bodyText={labels.Google_map_key_is_not_available}
        />
      </div>
    );
  }

  const locateMe = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        dispatch(
          setMapCenter({
            lat: position?.coords?.latitude,
            lng: position?.coords?.longitude,
          })
        );
      },
      () => null //error handling
    );
  };

  const onDragStart = () => {
    if (drawerAutoClose && drawerView != "close")
      dispatch(setDrawerView("close" as DrawerViewType));
  };

  return (
    <>
      {isLoaded ? (
        <>
          <div ref={mapContRef} style={{ height: "100%" }}>
            <GoogleMap
              id="topologyMap"
              mapContainerClassName="googleMapCont"
              center={mapCenter ? mapCenter : defaultMapCenter}
              zoom={zoomLevel ? zoomLevel : defaultZoom}
              options={{ ...TopologyMapOptions, styles: mapStyle }}
              onLoad={onLoad}
              onIdle={() => handleOnIdle(map, dispatch)}
              onZoomChanged={() => handleZoomChange(map?.getZoom(), dispatch)}
              onClick={onMapClick}
              onRightClick={onMapRigthClick}
              onDragStart={onDragStart}
            >
              <MapItems
                gMap={map}
                pngImages={pngImages}
                zoomLevel={map?.getZoom()}
                handleClick={onMapClick}
                handleRightClick={onMapRigthClick}
              />
              <InfoWindowMenu
                showInfoWin={showInfoWin}
                setShowInfoWin={setShowInfoWin}
              />
              <ElementRightClickMenu />
              <div className="userLocationCont" onClick={locateMe}>
                <MdMyLocation />
              </div>
              <ImageEditor mapContRef={mapContRef} />
              <AddMemo />
              {!isFromTopoStandaloneView && <NodeTopologySwitch />}
            </GoogleMap>
          </div>
          <Snackbar
            className="mapLoaderCont"
            open={mapLoading}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            message={
              <>
                <span>Loading...</span>
                <img className="mapLoading" src={LoaderGif} />
              </>
            }
          />
          <MapStyles />
        </>
      ) : (
        <ActivityIndicator
          style={{ width: "15%", height: "10%" }}
          animationAlignement="center"
        />
      )}
    </>
  );
};

export default MapView;
