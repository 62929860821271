import AppLabels from "../../constants/App_Labels";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import "../../design-tokens/account-page/FailedAttributes.scss";
import { isNull } from "../reusable/Util";
const FailedAttributesStb = (props: any) => {
  const { stbDocsisInfo, stbSummaryInfo, type } = props;

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "#000000",
      boxShadow: theme.shadows[4],
      fontSize: 12,
    },
  }))(Tooltip);
  const labels = AppLabels();
  const arr: any = [];
  const data = stbDocsisInfo;
  const stbDocsisArray: any = [];
  if (
    data &&
    data !== undefined &&
    (type === "stbDocsisInfo" || type === "both")
  ) {
    const upstreamChannel = data?.upstreamChannels?.[0];
    const upstreamSNR = upstreamChannel?.snr;
    const upstreamTxPower = upstreamChannel?.tx_power;
    const downstreamChannel = data?.downstreamChannels?.[0];
    const downstreamSNR = downstreamChannel?.snr;
    const downstreamRxPower = downstreamChannel?.rx_power;

    if (upstreamTxPower?.status === "Fail") {
      stbDocsisArray.push({
        name: labels.CM_TRANSMIT_POWER,
        value: isNull(upstreamTxPower?.value),
      });
    }

    if (upstreamSNR?.status == "Fail") {
      stbDocsisArray.push({
        name: labels.UPSTREAM_SNR,
        value: isNull(upstreamSNR?.value),
      });
    }
    if (downstreamRxPower?.status == "Fail") {
      stbDocsisArray.push({
        name: labels.CM_RECEIVE_POWER,
        value: isNull(downstreamRxPower?.value),
      });
    }
    if (downstreamSNR?.status == "Fail") {
      stbDocsisArray.push({
        name: labels.DOWNSTREAM_SNR,
        value: isNull(downstreamSNR?.value),
      });
    }
    if (stbDocsisArray?.length > 0) {
      arr.push({
        name: "STB_DOCSIS",
        value: stbDocsisArray,
        labels: ["NAME", "VALUE"],
      });
    }
  }
  if (
    stbSummaryInfo &&
    stbSummaryInfo !== undefined &&
    (type === "stbSummaryInfo" || type === "both")
  ) {
    const stbDocsisSummaryArr: any = [];
    if (stbSummaryInfo && Object.keys(stbSummaryInfo).length > 0) {
      if (
        stbSummaryInfo?.hasOwnProperty("qamChannelStatus") &&
        stbSummaryInfo.qamChannelStatus?.hasOwnProperty("status")
      ) {
        if (stbSummaryInfo.qamChannelStatus.status === "Fail") {
          stbDocsisSummaryArr.push({
            name: "QAM CHANNEL STATUS",
            value: isNull(stbSummaryInfo.qamChannelStatus?.value),
          });
        }
      }
      if (
        stbSummaryInfo?.hasOwnProperty("qamSnr") &&
        stbSummaryInfo.qamSnr?.hasOwnProperty("status")
      ) {
        if (stbSummaryInfo.qamSnr.status === "Fail") {
          stbDocsisSummaryArr.push({
            name: "QAM SNR",
            value: isNull(stbSummaryInfo.qamSnr?.value),
          });
        }
      }
    }
    if (stbDocsisSummaryArr?.length > 0) {
      arr.push({
        name: "STB_SUMMARY",
        value: stbDocsisSummaryArr,
        labels: ["NAME", "VALUE"],
      });
    }
  }
  return (
    <div className="faCmGW">
      <p>
        <span style={{ fontWeight: 600 }}> {labels.failedAttributes?.toUpperCase()} : </span>
        {arr ? (
          arr.map((el: any, index: any) => {
            return (
              <LightTooltip
                title={
                  <table className="tableTypeTwo">
                    <thead>
                      <tr>
                        <th>{labels.Name?.toUpperCase()}</th>
                        <th>{labels.VALUE?.toUpperCase()}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {el?.value?.map((v: any, i: any) => {
                        return (
                          <tr key={i}>
                            <td>{v?.name}</td> <td>{v?.value}</td>{" "}
                          </tr>
                        );
                      })}{" "}
                    </tbody>
                  </table>
                }
              >
                <span className="tooltipText" style={{ fontWeight: 500 }}>
                  {el?.name},{" "}
                </span>
              </LightTooltip>
            );
          })
        ) : (
          <></>
        )}
      </p>
    </div>
  );
};

export default FailedAttributesStb;
