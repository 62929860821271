import { KonvaEventObject } from "konva/lib/Node";
import { RegularPolygon, Transformer } from "react-konva";
import { useRef, useEffect } from "react";

import {
  ICommonDrawingProps,
  IDrawPosition,
  IGeoEditShape,
  getOpacity,
} from "../GeoEditCanvas";

export interface IGeoTriangleShape extends IGeoEditShape {
  sides: number;
  radius: number;
  strokeWidth: number;
  fill: string | undefined;
}

interface Props {
  triangles: IGeoTriangleShape[];
  setTriangles: (triangles: IGeoTriangleShape[]) => void;
  cdp: ICommonDrawingProps;
  onClick: ({ id }: { id: string }) => void;
}

export default function Triangles({
  triangles,
  setTriangles,
  cdp,
  onClick,
}: Props) {
  const { drawStartPosi, drawEndPosi } = cdp;

  return (
    <>
      {triangles?.map((tr) => (
        <Shape
          key={tr.id}
          shape={tr}
          onClick={onClick}
          shapes={triangles}
          setShapes={setTriangles}
          cdp={cdp}
        />
      ))}
      {cdp.drawingType === "triangle" && drawStartPosi && drawEndPosi && (
        <RegularPolygon
          sides={3}
          radius={calcTriangleRadius(drawStartPosi, drawEndPosi)}
          x={drawStartPosi.x}
          y={drawStartPosi.y}
          stroke={cdp.color}
          strokeWidth={cdp.strokeWidth}
          fill={cdp.fill}
          opacity={getOpacity(cdp.opacity)}
        />
      )}
    </>
  );
}

interface ShapeProps {
  shape: IGeoTriangleShape;
  shapes: IGeoTriangleShape[];
  setShapes: (rect: IGeoTriangleShape[]) => void;
  cdp: ICommonDrawingProps;
  onClick: ({ id }: { id: string }) => void;
}
const Shape = ({ shape, shapes, setShapes, cdp, onClick }: ShapeProps) => {
  const shapeRef = useRef<any>(null);
  const trRef = useRef<any>(null);
  const { selectedShape, isSelected: isSSelected } = cdp;
  const isSelected = isSSelected({ id: shape.id, shape: "triangle" });

  const handleDragStart = (e: KonvaEventObject<DragEvent>) => {
    cdp.setDrawingType("none");
    const id = e.target.id();
    setShapes(shapes.map((s) => ({ ...s, isDragging: s.id === id })));
  };

  const handleDragEnd = () =>
    setShapes(shapes.map((s) => ({ ...s, isDragging: false })));

  useEffect(() => {
    if (isSelected && trRef.current) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
    if (!isSelected && shapeRef.current) {
      shapeRef.current.scaleX(1);
      shapeRef.current.scaleY(1);
    }
  }, [isSelected]);

  const handleTREnd = () => {
    const node = shapeRef.current;
    if (node) {
      const scaleX = node.scaleX();
      node.scaleX(1);
      node.scaleY(1);

      if (selectedShape?.id && selectedShape.shape === "triangle") {
        setShapes(
          shapes.map((s) =>
            s.id === selectedShape.id
              ? {
                  ...s,
                  x: node.x(),
                  y: node.y(),
                  radius: Math.round((node.width() * scaleX) / 2),
                  rotation: node.rotation(),
                }
              : s
          )
        );
      }

      trRef.current.getLayer().batchDraw();
    }
  };

  return (
    <>
      <RegularPolygon
        ref={shapeRef}
        sides={shape.sides}
        radius={shape.radius}
        x={shape.x}
        y={shape.y}
        stroke={shape.stroke}
        strokeWidth={shape.strokeWidth}
        fill={shape.fill}
        rotation={shape.rotation}
        opacity={getOpacity(shape.opacity)}
        draggable
        scaleX={shape.isDragging ? 1.1 : 1}
        scaleY={shape.isDragging ? 1.1 : 1}
        onMouseDown={handleDragStart}
        onDragEnd={handleDragEnd}
        onClick={() => onClick({ id: shape.id })}
      />
      {selectedShape && isSelected && (
        <Transformer
          ref={trRef as any}
          onTransformEnd={handleTREnd}
          enabledAnchors={[
            "top-left",
            "top-right",
            "bottom-left",
            "bottom-right",
          ]}
        />
      )}
    </>
  );
};

export const calcTriangleRadius = (
  startPosi: IDrawPosition,
  endPosi: IDrawPosition
) => {
  return Math.round(
    Math.sqrt(
      Math.pow(endPosi.x - startPosi.x, 2) +
        Math.pow(endPosi.y - startPosi.y, 2)
    )
  );
};
