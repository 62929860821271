import { useState, useMemo, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Divider,
  Paper,
} from "@material-ui/core";
import { DEVICE_CATEGORY } from "../../constants/CustomerDeviceTypes";
import DeviceListTableHead from "./DeviceListTableHead";
import { Device } from "../../store/models/account/accountInfo/Device";
import { getDStatusSpan, isEqualDevices, isNull } from "../reusable/Util";
import DeviceActionMenu from "../reusable/DeviceActionMenu";
import AppLabels from "../../constants/App_Labels";
import ExceptionBox from "../reusable/ExceptionBox";
import PerformWHT from "../../components/diagnostics/PerformWHT";
import { SetWHTFlag } from "../../store/actions/account/accountInfo/ActiveAccountAction";

const DeviceOverView = (props: any) => {
  const { tabName } = props;
  const { sortedDevices, WHTFlag, accType, enableWHTFlag,podList } = useSelector(
    (state: any) => ({
      WHTFlag: state.AccountState.ActiveAccountDetails.wHTFlag,
      enableWHTFlag: state.AccountState.ActiveAccountDetails.enableWHT,
      sortedDevices: state.AccountState.ActiveAccountDetails.sortedDevices,
      accType: state.AccountState.ActiveAccountDetails.accountType,
      podList: state.AccountState.PodInfo.data,
    }),
    shallowEqual
  );
  const labels = AppLabels();
  const dispatch = useDispatch();
  const sortList = DEVICE_CATEGORY?.map((item, key) => item.category);
  const [provisionedDevices, setProvisionedDevices] = useState<Device[]>([]);
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  
  let WHTResponse = PerformWHT(WHTFlag);
  useEffect(() => {
    if (
      WHTResponse?.devices !== undefined &&
      WHTResponse?.devices?.length !== 0
    ) {
      let sortDevices = WHTResponse?.devices.sort((a, b) => {
        return (
          sortList?.indexOf(a.deviceCategory) -
          sortList?.indexOf(b.deviceCategory)
        );
      });
      let matchDevices = isEqualDevices(sortDevices, sortedDevices);
      if (matchDevices) setProvisionedDevices(sortDevices);
    }
  }, [WHTResponse]);
  const convertDevices = (devices: Device[]): Device[] => {
    let changedDevices: Device[] = [];
    devices?.map((device: Device) => {
      DEVICE_CATEGORY?.map((d: any, i) => {
        if (
          device.deviceCategory === d.category &&
          d?.topology?.indexOf(accType) > -1
        ) {
          device.deviceName = d.name;
          changedDevices.push(device);
        }
      });
    });
    return changedDevices;
  };
  const filterDevices = (devices: Device[], searchBy: string): Device[] => {
    let filteredDeviceList: Device[] = [];
    devices?.map((device: Device) => {
      if (device?.hasOwnProperty(searchBy)) {
        filteredDeviceList.push(device);
      }
    });
    return filteredDeviceList;
  };
  const handleDeviceAction=(deviceActions: any) => {
    const actionExits=deviceActions?.some((e: any) => 
     e?.action ==="Reboot");
    if(!actionExits)
    deviceActions.push({action: "Reboot", label: "Reboot" });
  // [{action: "Reboot", label: "Reboot" }]
    return deviceActions;
  
  };
  useEffect(() => {
    if (sortedDevices !== undefined && sortedDevices?.length !== 0) {
      setProvisionedDevices(convertDevices(sortedDevices));
      if (enableWHTFlag) {
        dispatch(SetWHTFlag(true));
      }
    }
  }, [sortedDevices]);
  const deviceData = useMemo(() => {
    let computedDevices = provisionedDevices;
    if (search && searchBy === "type") {
      computedDevices = filterDevices(computedDevices, "deviceName").filter(
        (device) =>
          device?.deviceName?.toLowerCase()?.includes(search?.toLowerCase())
      );
    } else if (search && searchBy === "macAddress") {
      computedDevices = filterDevices(computedDevices, "macAddress").filter(
        (device) =>
          device?.macAddress?.toLowerCase()?.includes(search?.toLowerCase())
      );
    } else if (search && searchBy === "serialNum") {
      computedDevices = filterDevices(computedDevices, "serialNumber").filter(
        (device) =>
          device?.serialNumber?.toLowerCase()?.includes(search?.toLowerCase())
      );
    } else if (search && searchBy === "model") {
      computedDevices = filterDevices(computedDevices, "deviceModel").filter(
        (device) =>
          device?.deviceModel?.toLowerCase()?.includes(search?.toLowerCase())
      );
    }
    //Sorting Devices
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      if (sorting.field === "deviceModel") {
        computedDevices = computedDevices.sort((a: any, b: any) => {
          return a[sorting.field] || b[sorting.field]
            ? !a[sorting.field]
              ? -1
              : !b[sorting.field]
                ? 1
                : reversed * a[sorting.field].localeCompare(b[sorting.field])
            : 0;
        });
      } else {
        computedDevices = computedDevices.sort((a: any, b: any) => {
          return reversed * a[sorting.field].localeCompare(b[sorting.field]);
        });
      }
    }
    return computedDevices;
  }, [search, searchBy, sorting, provisionedDevices]);
  const handleRunWHT = () => {
    dispatch(SetWHTFlag(true));
  };
  const deviceState = (status: any) => {
    if (isNull(status) === "") return ""
    switch (status) {
      case "fail":
      case "failed":
        return "failState"
      case "pass":
        return "passState"
      case "marginal pass":
        return "marginalPassState"
      case "skip":
      case "skipped":
        return "skipState"
      default:
        return ""
    }
  }
  const containsONT=  deviceData?.some((item:any, key:any) => item.deviceCategory==="ONT");

  return (
    <Paper className="deviceTablePaper">
      <div className="deviceTabLabel">
        <span>{labels.CUSTOMER_PROVISIONED_DEVICES.toUpperCase()}</span>
        {tabName !== "diagnostics" ? (
          <button
            className="blueHollowBtn"
            onClick={() => handleRunWHT()}
            disabled={WHTFlag}
          >
            RUN WHOLE HOME TEST
          </button>
        ) : (
          <></>
        )}
      </div>
      <Divider orientation="horizontal" />
      <TableContainer>
        <Table className="deviceTable">
          <DeviceListTableHead
            onSearch={(value: any, by: string) => {
              setSearch(value);
              setSearchBy(by);
            }}
            devices={provisionedDevices}
            onSorting={(field, order) => setSorting({ field, order })}
          />
          {deviceData?.length === 0 ? (
            <TableBody className="dTabBody">
              <TableRow tabIndex={-1} key="exception">
                <TableCell colSpan={5}>
                  <div className="diagErrorCont">
                    <ExceptionBox
                      headerText="No Devices to display."
                      bodyText="The ones provisioned to this account will reflect here."
                      primaryBtn={false}
                      secBtn={true}
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody className="dTabBody">
              {deviceData?.map((device, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                const state = deviceState(device.deviceStatus);
                return (
                  <TableRow tabIndex={-1} key={device.macAddress}>
                    <TableCell
                      id={labelId}
                      scope="row"
                      padding="none"
                      className={state}
                    >
                      {device.deviceName}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={state}
                    >
                      {device.deviceModel !== undefined &&
                        device.deviceModel !== "" ? (
                        <>{isNull(device.deviceModel)}</>
                      ) : (
                        <> - </>
                      )}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={state}
                    >
                      {device.macAddress !== undefined &&
                        device.macAddress !== "" ? (
                        <span className="deviceMAC">
                          {isNull(device.macAddress)}
                        </span>
                      ) : (
                        <> - </>
                      )}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={state}
                    >
                      {isNull(device.serialNumber)}
                    </TableCell>
                    <TableCell align="left">
                      {getDStatusSpan(isNull(device.deviceStatus))}
                    </TableCell>
                    <TableCell align="center">
                    {DEVICE_CATEGORY?.map((d: any, i) =>
                        device.deviceCategory === d.category &&
                          d?.topology?.indexOf(accType) > -1 &&
                          d?.hasOwnProperty("deviceActions") ? (
                            <DeviceActionMenu
                          
                            key={i}
                            deviceName={device.deviceCategory}
                            deviceActions={
                        d.deviceActions} 
                            deviceInfo={device}
                            menuType="horizontal"
                          />
                        ):  device.deviceCategory === d.category &&
                        d?.topology?.indexOf(accType) > -1 &&
                        d.name ==="GW" &&  containsONT 
                       ? (
                          <DeviceActionMenu
                          
                          key={i}
                          deviceName={d.name ==="GW" &&  containsONT &&device.deviceName
                          }
                          deviceActions={d.name ==="GW" &&  containsONT &&
                      [{action: "Reboot", label: "Reboot" }]
                      // handleDeviceAction( d.deviceActions)
                    } 
                          deviceInfo={device}
                          menuType="horizontal"
                        />
                        )
                        : null
                      )}
					  
                    </TableCell>
                  </TableRow>
                );
              })}
                 {podList
  ?.filter((item: any) => item?.deviceFriendlyName === "XLE")
  ?.map((item: any, index: number) => {
    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow tabIndex={-1} key={item?.serialNumber}>
                        <TableCell id={labelId}>
                          {item?.deviceFriendlyName?.toUpperCase()}
                        </TableCell>
                        <TableCell>{isNull(item?.model)}</TableCell>
                        <TableCell>
                          <span className="deviceMAC">
                            {isNull(item?.deviceId)}
                          </span>
                        </TableCell>
                        <TableCell>{isNull(item?.serialNumber)}</TableCell>
                        <TableCell>
                          <span>
                            {isNull(item?.connectionState)?.toUpperCase()}
                          </span>
                        </TableCell>
                        <TableCell>
                          {/*   {pod?.hasOwnProperty("health")
                              ? isNull(pod.health.status?.toUpperCase())
                              : ""} */}
                          
                        </TableCell>
                      </TableRow>
                    );
})}

            </TableBody>
          )}
          <caption></caption>
        </Table>
      </TableContainer>
    </Paper >
  );
};

export default DeviceOverView;
