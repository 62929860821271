import { ChangeEvent, FormEvent, useState } from "react";
import AppLabels from "../../constants/App_Labels";
import { Button, Card } from "@material-ui/core";
import ReloadButton from "../../components/reusable/ReloadButton";
import { MdRefresh } from "react-icons/md";
import colors from "../../topology/config/colors";
import { Form } from "react-bootstrap";
import React from "react";
import { ExportCsv } from "../gpon-page/components/ExportCsv";
import { BiDownload } from "react-icons/bi";
import { IoRefreshOutline } from "react-icons/io5";
import { AnyAsyncThunk } from "@reduxjs/toolkit/dist/matchers";

const RfCalculator = (props: any) => {

  let tapValue = 0;
  let signalLevelNineEight = 0;
  let signalLevelNineEightActual = 0;
  let signalLevelOneThreeFour = 0;
  let signalLevelOneThreeFourActual = 0;
  let cseNineEightActual = 0;
  let cseOneThreeFourActual = 0;
  let htdNineEightActual = 0;
  let htdOneThreeFourActual = 0;
  let dropGauge = 0;
  let dropLength = 0;
  let riserGauge = 0;
  let riserLength = 0;
  let lossOne = 0;
  let lossTwo = 0;
  let lossThree = 0;
  let ampGain = 0;
  let cableGauge = 0;
  let shortedResistance = 0;
  let openResistance = 0;

  const handleReset = () => {
    setTapSignal98("0");
    settapsignal98Actual("0");
    setTapValue("0");
    setTapSignal134("0");
    settapsignal134Actual("0");
    setCse98("0");
    setCse134("0");
    setHtd98("0");
    setHtd134("0");
    setDropGauge("0");
    setRiserGauge("0");
    setDropLength("0");
    setRiserLength("0");
    setLoss1("0");
    setLoss2("0");
    setLoss3("0");
    setAmpGain("0");
    setCableGauge("0");
    setShortedResistance("0");
    setOpenResistance("0");
  };

  const [tapsignal98Actual = '0', settapsignal98Actual] = useState<string>();
  const [tapsignal134Actual = '0', settapsignal134Actual] = useState<string>();
  const [tapval = '0', setTapValue] = useState<string>();
  const [tapsignal98 = '0', setTapSignal98] = useState<string>();
  const [tapsignal134 = '0', setTapSignal134] = useState<string>();
  const [cse98 = '0', setCse98] = useState<string>();
  const [cse134 = '0', setCse134] = useState<string>();
  const [htd98 = '0', setHtd98] = useState<string>();
  const [htd134 = '0', setHtd134] = useState<string>();
  const [dgauge = '0', setDropGauge] = useState<string>();
  const [rgauge = '0', setRiserGauge] = useState<string>();
  const [cgauge = '0', setCableGauge] = useState<string>();
  const [dlength = '0', setDropLength] = useState<string>();
  const [rlength = '0', setRiserLength] = useState<string>();
  const [loss1 = '0', setLoss1] = useState<string>();
  const [loss2 = '0', setLoss2] = useState<string>();
  const [loss3 = '0', setLoss3] = useState<string>();
  const [ampgainvalue = '0', setAmpGain] = useState<string>();
  const [sresistance = '0', setShortedResistance] = useState<string>();
  const [oresistance = '0', setOpenResistance] = useState<string>();

  const [open, setOpen] = useState(false); //for modal for export
 

  const labels = AppLabels();

  const processOutput = (val: any) => {
    if (isNaN(parseInt(val))) {
      return 0;
    } else {
      return parseInt(val);
    }
  };

  cableGauge = processOutput(cgauge);
  shortedResistance = processOutput(sresistance);
  openResistance = processOutput(oresistance);
  tapValue = processOutput(tapval);
  signalLevelNineEight = processOutput(tapsignal98Actual);
  signalLevelNineEightActual = processOutput(tapsignal98Actual);
  signalLevelOneThreeFour = processOutput(tapsignal134Actual);
  signalLevelOneThreeFourActual = processOutput(tapsignal134Actual);
  cseNineEightActual = processOutput(cse98);
  cseOneThreeFourActual = processOutput(cse134);
  htdNineEightActual = processOutput(htd98);
  htdOneThreeFourActual = processOutput(htd134);
  dropGauge = processOutput(dgauge);
  dropLength = processOutput(dlength);
  riserGauge = processOutput(rgauge);
  riserLength = processOutput(rlength);
  lossOne = processOutput(loss1);
  lossTwo = processOutput(loss2);
  lossThree = processOutput(loss3);
  ampGain = processOutput(ampgainvalue);
  tapValue = processOutput(tapval);
  cableGauge = processOutput(cgauge);
  shortedResistance = processOutput(sresistance);
  openResistance = processOutput(oresistance);

  function cseValue(channel: string) {
    if (channel == "98") {
      if (dropGauge == 59) {
        return signalLevelNineEight - dropLength * 0.0329;
      } else if (dropGauge == 11) {
        return signalLevelNineEight - dropLength * 0.0128;
      } else if (dropGauge == 6) {
        return signalLevelNineEight - dropLength * 0.0225;
      } else if (dropGauge == 0) {
        return 0;
      } else {
        return -1; // this should just return an error basically
      }
    } else {
      if (dropGauge == 59) {
        return signalLevelOneThreeFour - dropLength * 0.0992;
      } else if (dropGauge == 11) {
        return signalLevelOneThreeFour - dropLength * 0.0394;
      } else if (dropGauge == 6) {
        return signalLevelOneThreeFour - dropLength * 0.0601;
      } else if (dropGauge == 0) {
        return 0;
      } else {
        return -1; // this should just return an error basically
      }
    }
  }

  function lossValue(channel: string) {
    if (channel == "98") {
      if (riserGauge == 59) {
        return riserLength * 0.0329;
      } else if (riserGauge == 11) {
        return riserLength * 0.0128;
      } else if (riserGauge == 6) {
        return riserLength * 0.0225;
      } else if (riserGauge == 0) {
        return 0;
      } else {
        return -1; // this should just return an error basically
      }
    } else {
      if (riserGauge == 59) {
        return riserLength * 0.0992;
      } else if (riserGauge == 11) {
        return riserLength * 0.0394;
      } else if (riserGauge == 6) {
        return riserLength * 0.0601;
      } else if (riserGauge == 0) {
        return 0;
      } else {
        return -1; // this should just return an error basically
      }
    }
  }


  let cseNineEight = cseValue("98");
  let cseOneThreeFour = cseValue("134");
  let htdNineEight =
    cseNineEightActual -
    lossOne -
    lossTwo -
    lossThree +
    ampGain -
    lossValue("98");
  let htdOneThreeFour =
    cseOneThreeFourActual -
    lossOne -
    lossTwo -
    lossThree +
    ampGain -
    lossValue("134");
  let signalDeltaNineEight = signalLevelNineEight - signalLevelNineEightActual;
  let signalDeltaOneThreeFour =
    signalLevelOneThreeFour - signalLevelOneThreeFourActual;
  let cseDeltaNineEight = cseNineEight - cseNineEightActual;
  let cseDeltaOneThreeFour = cseOneThreeFour - cseOneThreeFourActual;
  let htdDeltaNineEight = htdNineEight - htdNineEightActual;
  let htdDeltaOneThreeFour = htdOneThreeFour - htdOneThreeFourActual;

 const htdValue = (channel: string) => {
  if (channel === '98') {
    return cseNineEightActual - lossOne - lossTwo - lossThree + ampGain - lossValue('98');
  } else {
    return cseOneThreeFourActual - lossOne - lossTwo - lossThree + ampGain - lossValue('134');
  }
}

 const outputValues = {
  tapValue: tapval,
  cse98: cseValue('98'),
  cse134: cseValue('134'),
  htd98: htdValue('98'),
  htd134: htdValue('134'),
  cseDelta98: cseValue('98') - cseNineEightActual,
  cseDelta134: cseValue('134') - cseOneThreeFourActual,
  htdDelta98: htdValue('98') - htdNineEightActual,
  htdDelta134: htdValue('134') - htdOneThreeFourActual,
};

const csvData = [
  [ 'Tap Signal', 98, signalLevelNineEight, signalLevelNineEight ],
  [ 'Tap Signal', 134, signalLevelOneThreeFour, signalLevelOneThreeFour ],
  ['CSE', 98, outputValues.cse98.toFixed(3), cseNineEightActual ],
  [ 'CSE', 134, outputValues.cse134.toFixed(3), cseOneThreeFourActual ],
  [ 'HTD', 98, outputValues.htd98.toFixed(3), htdNineEightActual ],
  [ 'HTD', 134, outputValues.htd134.toFixed(3), htdOneThreeFourActual ],
  [ 'Drop Length', '', '', dropLength ],
  [ 'Drop Gauge', '', '', dropGauge ],
  [ 'Riser Length', '', '', riserLength ],
  [ 'Riser Gauge', '', '', riserGauge ],
  [ 'Loss Split 1', '', '', loss1 ],
  [ 'Loss Split 2', '', '', loss2 ],
  [ 'Loss Split 3', '', '', loss3 ],
  [ 'Amp Gain', '', '', ampGain ],
  [ 'Shorted Resistance', '', '', shortedResistance ],
  [ 'Open Resistance', '', '', openResistance ],
  [ 'Cable Gauge', '', '', cableGauge ],
];
const csvHeaders = [
  'Name', 'Channel', 'Expected', 'Actual'
];

  return (
    <div className="filemanager">
      <div className="filemanager-header">
        <h4>{labels.RF_CALCULATOR}</h4>
      </div>
      <div>
        <Button
          style={{
            marginLeft: "5px",
            width: "110px",
            borderRadius: "7px",
            border: "1px solid blue",
          }}
          className="blueHollowBtn"
          onClick={handleReset}
          title="Reset"
        >
          <IoRefreshOutline />
        </Button>
        <Button
          style={{
            marginLeft: "5px",
            width: "110px",
            borderRadius: "7px",
            border: "1px solid blue",
          }}
          className="blueHollowBtn"
          onClick={() => setOpen(true)}
          title="Export"
        >
          <BiDownload />
        </Button>
        <ExportCsv
          show={open}
          handleClose={() => setOpen(false)}
          headerText={labels.Export}
          cancelText={labels.CANCEL}
          rowData={csvData}
          columnData={csvHeaders}
        />
      </div>
      <div className="filemanager-content">
        <Card className="custInfoCard" style={{ marginTop: "0px" }}>
          <div className="filemanager-header">
            <strong style={{ marginLeft: "-21px", marginBottom: "-15px" }}>
              {"Signal Levels"}
            </strong>
          </div>
          <hr></hr>
          <div className="filemanager-header">
            <span style={{ marginLeft: "85px" }}>{"Tap: CH98"}</span>
            <span style={{ marginLeft: "97px" }}>{"Tap: CH134"}</span>
          </div>
          <form
            className="calculator-form"
            id="inputs"
            onSubmit={(e: FormEvent) => {
              e.preventDefault();
            }}
          >
            <label>
              <input
                type={"textfield"}
                name="Tap: CH98"
                id="signalLevelNineEight"
                value={tapsignal98Actual}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  settapsignal98Actual(e.target.value)
                }
                style={{
                  width: "105px",
                  marginLeft: "105px",
                  borderRadius: "5px",
                  borderWidth: "1px",
                }}
              />
            </label>
            <label>
              <input
                type={"textfield"}
                name="Tap: CH134"
                id="signalLevelOneThreeFour"
                value={tapsignal134Actual}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  settapsignal134Actual(e.target.value)
                }
                style={{
                  width: "105px",
                  marginLeft: "70px",
                  borderRadius: "5px",
                  borderWidth: "1px",
                }}
              />
            </label>
            <br></br>
            <div>
              <span style={{ marginLeft: "105px" }}>{"Drop Gauge"}</span>
              <span style={{ marginLeft: "83px" }}>{"Drop Length"}</span>
            </div>
            <label>
              <select
                name="Drop Gauge"
                id="dropGauge"
                value={dgauge}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  setDropGauge(e.target.value)
                }
                style={{
                  width: "105px",
                  marginLeft: "105px",
                  borderRadius: "5px",
                  borderWidth: "1px",
                }}
              >
                <option value="0">0</option>
                <option value="6">6</option>
                <option value="11">11</option>
                <option value="59">59</option>
              </select>
            </label>
            <label>
              <input
                type={"textfield"}
                name="Drop Length"
                id="dropLength"
                value={dlength}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setDropLength(e.target.value)
                }
                style={{
                  width: "105px",
                  marginLeft: "70px",
                  borderRadius: "5px",
                  borderWidth: "1px",
                }}
              />
            </label>
            <br></br>
            <br></br>
            <div className="filemanager-header">
              <strong style={{ marginLeft: "-21px", marginBottom: "-15px" }}>
                {"CSE"}
              </strong>
            </div>
            <hr></hr>
            <div className="filemanager-header">
              <span style={{ marginLeft: "85px" }}>{"CH98"}</span>
              <span style={{ marginLeft: "133px" }}>{"CH134"}</span>
              <span style={{ marginLeft: "232px" }}>{"CH98"}</span>
              <span style={{ marginLeft: "68px" }}>{"CH134"}</span>
              <span style={{ marginLeft: "124px" }}>{"CH98"}</span>
              <span style={{ marginLeft: "66px" }}>{"CH134"}</span>
            </div>
            <label>
              <strong>Actual:</strong>
              <input
                type={"textfield"}
                name="CSE Actual: CH98"
                id="cseNineEight"
                value={cse98}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setCse98(e.target.value)
                }
                style={{
                  width: "105px",
                  marginLeft: "52px",
                  borderRadius: "5px",
                  borderWidth: "1px",
                }}
              />
            </label>
            <label>
              <input
                type={"textfield"}
                name="CSE Actual: CH134"
                id="cseOneThreeFour"
                value={cse134}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setCse134(e.target.value)
                }
                style={{
                  width: "105px",
                  marginLeft: "70px",
                  borderRadius: "5px",
                  borderWidth: "1px",
                }}
              />
            </label>
            <label
              style={{
                marginLeft: "40px",
              }}
            >
              <strong>Expected:</strong>
              <input
                type={"textfield"}
                name="CSE Expected: CH98"
                id="cseNineEight"
                value={outputValues.cse98.toFixed(3)}
                style={{
                  width: "100px",
                  marginLeft: "60px",
                  borderRadius: "5px",
                  borderWidth: "2px",
                  color: colors.blue,
                  borderColor: colors.blue,
                }}
              />
            </label>
            <label>
              <input
                type={"textfield"}
                name="CSE Expected: CH134"
                id="cseOneThreeFour"
                value={outputValues.cse134.toFixed(3)}
                style={{
                  width: "100px",
                  marginLeft: "10px",
                  borderRadius: "5px",
                  borderWidth: "2px",
                  color: colors.blue,
                  borderColor: colors.blue,
                }}
              />
            </label>
            <label
              style={{
                marginLeft: "10px",
              }}
            >
              <strong>Delta:</strong>
              <input
                type={"textfield"}
                name="CSE Delta: CH98"
                id="cseNineEight"
                value={outputValues.cseDelta98.toFixed(3)}
                style={{
                  width: "100px",
                  marginLeft: "18px",
                  borderRadius: "5px",
                  borderWidth: "2px",
                  color: colors.blue,
                  borderColor: colors.blue,
                }}
              />
            </label>
            <label>
              <input
                type={"textfield"}
                name="CSE Delta: CH134"
                id="cseOneThreeFour"
                value={outputValues.cseDelta134.toFixed(3)}
                style={{
                  width: "100px",
                  marginLeft: "10px",
                  borderRadius: "5px",
                  borderWidth: "2px",
                  color: colors.blue,
                  borderColor: colors.blue,
                }}
              />
            </label>
            <br></br>
            <div>
              <span style={{ marginLeft: "105px" }}>Loss, Split 1</span>
              <span style={{ marginLeft: "93px" }}>Loss, Split 2</span>
              <span style={{ marginLeft: "197px" }}>Loss, Split 3</span>
              <span style={{ marginLeft: "25px" }}>Amp Gain</span>
              <span style={{ marginLeft: "103px" }}>Riser Gauge</span>
              <span style={{ marginLeft: "20px" }}>Riser Length(ft)</span>
            </div>
            <label>
              <input
                type={"textfield"}
                name="Loss, Split 1"
                id="lossOne"
                value={loss1}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setLoss1(e.target.value)
                }
                style={{
                  width: "105px",
                  marginLeft: "106px",
                  borderRadius: "5px",
                  borderWidth: "1px",
                }}
              />
            </label>
            <label>
              <input
                type={"textfield"}
                name="Loss, Split 2"
                id="lossTwo"
                value={loss2}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setLoss2(e.target.value)
                }
                style={{
                  width: "105px",
                  marginLeft: "72px",
                  borderRadius: "5px",
                  borderWidth: "1px",
                }}
              />
            </label>
            <label>
              <input
                type={"textfield"}
                name="Loss, Split 3"
                id="lossThree"
                value={loss3}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setLoss3(e.target.value)
                }
                style={{
                  width: "100px",
                  marginLeft: "176px",
                  borderRadius: "5px",
                  borderWidth: "1px",
                }}
              />
            </label>
            <label>
              <input
                type={"textfield"}
                name="AMP Gain"
                id="ampGain"
                value={ampgainvalue}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setAmpGain(e.target.value)
                }
                style={{
                  width: "100px",
                  marginLeft: "10px",
                  borderRadius: "5px",
                  borderWidth: "1px",
                }}
              />
            </label>
            <label>
              <select
                id="riserGauge"
                name="Riser Gauge"
                value={rgauge}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  setRiserGauge(e.target.value)
                }
                style={{
                  width: "100px",
                  marginLeft: "77px",
                  borderRadius: "5px",
                  borderWidth: "1px",
                }}
              >
                <option value="0">0</option>
                <option value="6">6</option>
                <option value="11">11</option>
                <option value="59">59</option>
              </select>
            </label>
            <label>
              <input
                type={"textfield"}
                name="Riser Length(ft)"
                id="riserLength"
                value={rlength}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setRiserLength(e.target.value)
                }
                style={{
                  width: "100px",
                  marginLeft: "10px",
                  borderRadius: "5px",
                  borderWidth: "1px",
                }}
              />
            </label>
            <br></br>
            <div className="filemanager-header">
              <strong style={{ marginLeft: "-21px", marginBottom: "-15px" }}>
                {"HTD Device"}
              </strong>
            </div>
            <hr></hr>
            <div className="filemanager-header">
              <span style={{ marginLeft: "83px" }}>{"CH98"}</span>
              <span style={{ marginLeft: "136px" }}>{"CH134"}</span>
              <span style={{ marginLeft: "232px" }}>{"CH98"}</span>
              <span style={{ marginLeft: "68px" }}>{"CH134"}</span>
              <span style={{ marginLeft: "124px" }}>{"CH98"}</span>
              <span style={{ marginLeft: "66px" }}>{"CH134"}</span>
            </div>
            <label>
              <strong>Actual:</strong>
              <input
                type={"textfield"}
                name="HTD Actual: CH98"
                id="htdNineEight"
                value={htd98}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setHtd98(e.target.value)
                }
                style={{
                  width: "105px",
                  marginLeft: "50px",
                  borderRadius: "5px",
                  borderWidth: "1px",
                }}
              />
            </label>
            <label>
              <input
                type={"textfield"}
                name="HTD Actual: CH134"
                id="htdOneThreeFour"
                value={htd134}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setHtd134(e.target.value)
                }
                style={{
                  width: "105px",
                  marginLeft: "72px",
                  borderRadius: "5px",
                  borderWidth: "1px",
                }}
              />
            </label>
            <label
              style={{
                marginLeft: "40px",
              }}
            >
              <strong>Expected:</strong>
              <input
                type={"textfield"}
                name="HTD Expected: CH98"
                id="htdNineEight"
                value={outputValues.htd98.toFixed(3)}
                style={{
                  width: "100px",
                  marginLeft: "60px",
                  borderRadius: "5px",
                  borderWidth: "2px",
                  color: colors.blue,
                  borderColor: colors.blue,
                }}
              />
            </label>
            <label>
              <input
                type={"textfield"}
                name="HTD Expected: CH134"
                id="htdOneThreeFour"
                value={outputValues.htd134.toFixed(3)}
                style={{
                  width: "100px",
                  marginLeft: "10px",
                  borderRadius: "5px",
                  borderWidth: "2px",
                  color: colors.blue,
                  borderColor: colors.blue,
                }}
              />
            </label>
            <label
              style={{
                marginLeft: "10px",
              }}
            >
              <strong>Delta:</strong>
              <input
                type={"textfield"}
                name="HTD Delta: CH98"
                id="htdNineEight"
                value={outputValues.htdDelta98.toFixed(3)}
                style={{
                  width: "100px",
                  marginLeft: "18px",
                  borderRadius: "5px",
                  borderWidth: "2px",
                  color: colors.blue,
                  borderColor: colors.blue,
                }}
              />
            </label>
            <input
              type={"textfield"}
              name="HTD Delta: CH134"
              id="htdDeltaOneThreeFour"
              value={outputValues.htdDelta134.toFixed(3)}
              style={{
                width: "100px",
                marginLeft: "10px",
                borderRadius: "5px",
                borderWidth: "2px",
                color: colors.blue,
                borderColor: colors.blue,
              }}
            />
            <br></br>
            <div className="filemanager-header">
              <strong style={{ marginLeft: "-21px", marginBottom: "-15px" }}>
                {"Resistance/Gauge"}
              </strong>
            </div>
            <hr></hr>
            <div className="filemanager-header">
              <span style={{ marginLeft: "85px" }}>{"Cable Gauge"}</span>
              <span style={{ marginLeft: "77px" }}>{"Shorted Resistance"}</span>
              <span style={{ marginLeft: "147px" }}>{"Open Resistance"}</span>
            </div>
            <label>
              <select
                id="cableGauge"
                name="Cable Gauge"
                value={cgauge}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  setCableGauge(e.target.value)
                }
                style={{
                  width: "105px",
                  marginLeft: "105px",
                  borderRadius: "5px",
                  borderWidth: "1px",
                }}
              >
                <option value="0">0</option>
                <option value="6">6</option>
                <option value="11">11</option>
                <option value="59">59</option>
              </select>
            </label>
            <label>
              <input
                type={"textfield"}
                name="Shorted Resistance"
                id="shortedResistance"
                value={sresistance}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setShortedResistance(e.target.value)
                }
                style={{
                  width: "105px",
                  marginLeft: "70px",
                  borderRadius: "5px",
                  borderWidth: "1px",
                }}
              />
            </label>
            <label>
              <input
                type={"textfield"}
                name="Open Resistance"
                id="openResistance"
                value={oresistance}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setOpenResistance(e.target.value)
                }
                style={{
                  width: "100px",
                  marginLeft: "180px",
                  borderRadius: "5px",
                  borderWidth: "1px",
                }}
              />
            </label>
            <span
              style={{
                marginLeft: "70px",
                color: colors.blue,
              }}
            >
              Resistance per 100': 59=8 ohms; 6u=4 ohms.
            </span>
            <br></br>
            <span
              style={{
                marginLeft: "735px",
                color: colors.blue,
              }}
            >
              If ∞, enter 100000.
            </span>
            <br></br>
            <span
              style={{
                marginLeft: "735px",
                color: colors.blue,
              }}
            >
              Potentially defective? Calculate Length in feet.
            </span>
          </form>
        </Card>
      </div>
    </div>
  );
};

export default RfCalculator;
