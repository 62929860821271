import { useEffect, useState } from "react";

import "../styles/drawer/rf/RfLogicalView.scss";
import appConst from "../constants/appConst";
import TopologyLabels from "../constants/topologyLabels";
import FiberTableData from "../components/drawer/trace/fiber/FiberTableData";

export default function FiberTableScreen(props: any) {
  const labels = TopologyLabels();

  const [title, setTitle] = useState("");
  const [data, setData] = useState<Array<any>>([]);
  const [otdrInfo, setOtdrInfo] = useState(undefined);

  useEffect(() => {
    const handleReceiveMessage = (event: MessageEvent) => {
      if (event.origin !== window.location.origin) return;

      if (
        event.data &&
        event.data.traceType &&
        event.data.networkId &&
        event.data.fiberData
      ) {
        setTitle(
          `${labels.Fiber} ${
            event.data.traceType === appConst.downstream
              ? labels.downstream
              : labels.upstream
          } - ${event.data.networkId}`
        );
        setOtdrInfo(event.data.otdrInfo);
        setData(event.data.fiberData);
      }
    };

    window.addEventListener("message", handleReceiveMessage);
    return () => {
      window.removeEventListener("message", handleReceiveMessage);
    };
  }, []);

  return (
    <div className="tabDataCont">
      <FiberTableData
        title={title}
        data={data}
        otdrInfo={otdrInfo}
        showExpandBtn={false}
        tableStyle={{}}
      />
    </div>
  );
}
