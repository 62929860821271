import { CSSProperties } from "react";
import { IconInterface } from "../components/reusable/TopologySymbols";
import appConst from "../constants/appConst";
import colors from "./colors";

export const supportStrucElem = [
  appConst.cableDip,
  appConst.detailMark,
  appConst.pole,
  appConst.poleStrand,
];

export const mapElementStyles: any = {
  common: {
    iconSize: 20,
    anchor: {
      x: 10,
      y: 10,
    },
    height: 500,
    width: 500,
  },
  polygon: {
    nonActive: {
      strokeColor: colors.primary,
      strokeOpacity: 0.5,
      strokeWeight: 1.5,
      fillColor: colors.white,
      fillOpacity: 0,
    },
    active: {
      fillColor: colors.primary10,
      strokeColor: colors.primary,
      strokeOpacity: 1,
      strokeWeight: 2.5,
      fillOpacity: 0.7,
    },
  },
  measureDistance: {
    standard: {
      geodesic: true,
      strokeOpacity: 0,
      strokeWeight: 2,
      strokeColor: colors.pureBlack,
      icons: [
        {
          icon: {
            path: "M 0,0 0,1",
            strokeOpacity: 1,
            strokeWeight: 2,
            scale: 3,
          },
          repeat: "10px",
        },
      ],
    },
    others: {
      geodesic: true,
      strokeOpacity: 0,
      strokeWeight: 2,
      strokeColor: colors.white,
      icons: [
        {
          icon: {
            path: "M 0,0 0,1",
            strokeOpacity: 1,
            strokeWeight: 2,
            scale: 3,
          },
          repeat: "10px",
        },
      ],
    },
  },
  nodeSite: {
    color: colors.gold,
    defaultIconSize: 90,
    iconSize: {
      18: 90,
      19: 90,
      20: 90,
      21: 90,
    },
  },
  siteComponents: {
    color: colors.orange,
    defaultIconSize: 90,
    iconSize: {
      18: 90,
      19: 90,
      20: 90,
      21: 90,
    },
  },
  node: {
    strokeColor: colors.primary,
    strokeWidth: 1,
    fillColor: colors.primary10,
  },
  fiberCableGeometry: {
    strokeColor: colors.tBlue,
    strokeOpacity: 2,
    strokeWeight: 1.5,
    zIndex: 1000,
  },
  fiberSpliceCase: {
    color: colors.tBlue,
    iconName: "redline_splice" as IconInterface["name"],
    iconSize: 30,
  },
  fiberStorageAndRiser: {
    color: colors.tBlue,
    iconSize: 30,
    iconOpacity: 1,
  },
  fiberAddrConnectionPoint: {
    color: colors.tBlue,
    iconSize: 150,
    iconOpacity: 1,
    iconName: "circle" as IconInterface["name"],
  },
  tap: {
    color: colors.gold,
    tapIconName: "redline_circle" as IconInterface["name"],
    tap2IconName: "redline_circle" as IconInterface["name"],
    tap4IconName: "redline_square" as IconInterface["name"],
    tap8IconName: "Octagon" as IconInterface["name"],
    iconSize: 30,
    fontSize: "10px",
    iconOpacity: 0.5,
  },
  rfDrops: {
    lineStyle: {
      strokeColor: colors.americanSilver,
      strokeOpacity: 3,
      strokeWeight: 1,
      zIndex: 1000,
    },
    color: colors.white,
    iconSize: 20,
    fontSize: "10px",
    classname: "numMarker",
  } as CSSProperties,
  splitter: {
    color: colors.green,
    iconSize: 30,
    iconOpacity: 0.5,
  },
  opticalTap: {
    color: colors.tBlue,
    iconSize: 30,
    iconOpacity: 0.5,
  },
  powerBlock: {
    color: colors.green,
    iconSize: 30,
    iconOpacity: 0.5,
  },
  terminator: {
    color: colors.green,
    iconSize: 30,
    iconOpacity: 0.5,
  },
  powerInserter: {
    color: colors.green,
    iconSize: 30,
    iconOpacity: 0.5,
  },
  powerSupply: {
    color: colors.gold,
    iconSize: 30,
    iconOpacity: 1,
    classname: "powerSupplyLabel",
    fontSize: "10px",
  },
  powertrace: {
    iconName: "bulb" as IconInterface["name"],
    color: colors.sunsetOrange,
    iconSize: 20,
    line: {
      geodesic: true,
      strokeColor: colors.sunsetOrange,
      strokeWeight: 3,
      zIndex: 3000,
      clickable: false,
      draggable: false,
    },
  },
  trace: {
    downstream: {
      line: {
        geodesic: true,
        strokeColor: colors.brightBlue,
        strokeWeight: 3,
        zIndex: 4000,
        clickable: false,
        draggable: false,
      },
    },
    upstream: {
      line: {
        geodesic: true,
        strokeColor: colors.orange,
        strokeWeight: 3,
        zIndex: 4000,
        clickable: false,
        draggable: false,
      },
    },
  },
  olt: {
    color: colors.gold,
    defaultIconSize: 90,
    iconSize: {
      18: 90,
      19: 90,
      20: 90,
      21: 90,
    },
  },
  [appConst.LCP]: {
    color: colors.gold,
    defaultIconSize: 90,
    iconSize: {
      18: 90,
      19: 90,
      20: 90,
      21: 90,
    },
  },
  ispSite: {
    color: colors.tBlue,
    iconSize: 30,
    iconOpacity: 0.5,
  },
  mdu: {
    color: colors.lightBlue,
    iconSize: 30,
    iconOpacity: 0.5,
    anchor: {
      x: 20,
      y: 40,
    },
  },
  //Support Structure
  detailMark: {
    color: colors.lightBlue,
    iconSize: 30,
    iconOpacity: 0.5,
  },
  //Support Structure
  cableDip: {
    color: colors.lightBlue,
    iconSize: 30,
    iconOpacity: 1,
  },
  //Support Structure
  pole: {
    color: colors.lightBlue,
    iconSize: 30,
    iconOpacity: 0.5,
  },
  //Support Structure
  poleStrand: {
    lineStyle: {
      strokeColor: colors.lightBlue,
      strokeOpacity: 3,
      strokeWeight: 1,
      zIndex: 1000,
    },
    color: colors.lightBlue,
    classname: "poleStrandLabel",
    iconSize: 20,
    fontSize: "10px",
  },
  rfSplice: {
    color: colors.green,
    iconSize: 30,
    iconOpacity: 0.5,
  },
  samkeyAddress: {
    iconName: "CM-CIRCLE" as IconInterface["name"],
    color: colors.orange,
    iconSize: 20,
    strokeWidth: 0.5,
    anchor: {
      x: 0.5,
      y: 0.5,
    },
  },
  rfCable: {
    lineStyle: {
      strokeWeight: 2,
      strokeOpacity: 1,
      zIndex: 1000,
    },
    cableTypes: {
      "625": {
        strokeColor: colors.green,
        strokeWeight: 2,
      },
      "875": {
        strokeColor: colors.electricGreen,
        strokeWeight: 3,
      },
      "750": {
        strokeColor: colors.electricGreen,
        strokeWeight: 3,
      },
      "500": {
        strokeColor: colors.green,
        strokeWeight: 2,
      },
      "412": {
        strokeColor: colors.green,
        strokeWeight: 2,
      },
      TX15: {
        strokeColor: colors.green,
        strokeWeight: 2,
      },
      "715": {
        strokeColor: colors.green,
        strokeWeight: 2,
      },
      "540": {
        strokeColor: colors.green,
        strokeWeight: 2,
      },
      "860": {
        strokeColor: colors.electricGreen,
        strokeWeight: 3,
      },
      other: {
        strokeColor: colors.green,
        strokeWeight: 2,
      },
    },
  },
  activeEquipment: {
    color: colors.gold,
    iconSize: 37,
    iconOpacity: 0.9,
  },
  annotationBlock: {
    color: colors.denim,
    iconSize: 30,
    iconOpacity: 0.5,
  },
  cableModem: {
    iconSize: 20,
    iconName: {
      d30: "CM-CIRCLE",
      d31: "CM-SQUARE",
      other: "CM-CIRCLE",
      unknown: "CM-UNKNOWN",
    },
    color: {
      onLine: colors.success,
      offLine: colors.danger,
      upStream: colors.orange,
      downStream: colors.aqua,
      dualChannel: colors.greyMedium,
      unknown: colors.pink,
    },
  },
  cpatEvent: {
    iconName: "CPAT",
    iconSize: [35, 45, 55, 75, 95],
    opacity: 0.6,
    anchor: {
      x: 0.5,
      y: 0.5,
    },
  },
  vhub: {
    color: colors.gold,
    defaultIconSize: 90,
    iconSize: {
      18: 90,
      19: 90,
      20: 90,
      21: 90,
    },
  },
  wirelessSite: {
    color: colors.gold,
    defaultIconSize: 90,
    iconSize: {
      18: 90,
      19: 90,
      20: 90,
      21: 90,
    },
  },
  smallCell: {
    color: colors.gold,
    defaultIconSize: 90,
    iconSize: {
      18: 90,
      19: 90,
      20: 90,
      21: 90,
    },
  },
  clamshell: {
    color: colors.gold,
    defaultIconSize: 90,
    iconSize: {
      18: 90,
      19: 90,
      20: 90,
      21: 90,
    },
  },
  rbCircuit: {
    color: colors.gold,
    defaultIconSize: 90,
    iconSize: {
      18: 90,
      19: 90,
      20: 90,
      21: 90,
    },
  },
  mduPolyline: {
    strokeColor: colors.white,
    strokeOpacity: 1.5,
    strokeWeight: 1,
  },
  mduActive: {
    color: colors.gold,
    iconSize: 37,
    iconOpacity: 0.9,
  },
  mduFiberEquipment: {
    color: colors.lightBlue,
    iconSize: 30,
    iconOpacity: 0.5,
  },
  mduPassive: {
    color: colors.green,
    iconSize: 30,
    iconOpacity: 0.5,
  },
  mduFiberCable: {
    strokeColor: colors.tBlue,
    strokeWeight: 1,
    strokeOpacity: 1.5,
  },
  mduRFCable: {
    strokeColor: colors.green,
    strokeOpacity: 1.5,
    strokeWeight: 1,
  },
  mduPowerSupply: {
    color: colors.gold,
    iconSize: 30,
    iconOpacity: 0.8,
  },
  mduTap: {
    color: colors.gold,
    iconSize: 30,
    fontSize: "10px",
    iconOpacity: 0.5,
  },
  mduStrandCable: {
    strokeColor: colors.lightBlue,
    strokeOpacity: 1.5,
    strokeWeight: 1,
  },
  mduStrandSpecialCable: {
    strokeColor: colors.lightBlue,
    strokeOpacity: 1.5,
    strokeWeight: 1,
  },
  mduStrandPoint: {
    color: colors.lightBlue,
    iconSize: 30,
    iconOpacity: 0.5,
  },
  mduRaster: {
    strokeColor: colors.lightBlue,
    strokeOpacity: 1.5,
    strokeWeight: 1,
  },
  clusterCM: {
    iconSize: 30,
    iconName: "clusterMarker",
    color: colors.mikadoYellow,
    fontSize: "12px",
    fontColor: colors.white,
  },
  mduCADHatch: {
    strokeColor: colors.maroonRed,
    strokeOpacity: 1.5,
    strokeWeight: 1,
  },
  mduCADSymbol: {
    color: colors.orange,
    iconSize: 30,
    iconOpacity: 0.8,
  },
  mduCADCircle: {
    color: colors.white,
    iconSize: 30,
    iconOpacity: 0.8,
  },
  memoComment: {
    iconName: "comment",
    color: colors.purple,
    iconSize: {
      zoom19: 20,
      zoom20: 24,
      zoom21: 30,
    },
    opacity: 1,
    zoomLevelRenderMarker: 18,
    anchor: {
      zoom19: {
        x: 14,
        y: 20,
      },
      zoom20: {
        x: 16,
        y: 24,
      },
      zoom21: {
        x: 26,
        y: 32,
      },
    },
  },
  mduCADAttribute: {
    color: colors.lightBlue,
    iconSize: 30,
    fontSize: "12px",
    iconOpacity: 0.5,
  },
  mduCADText: {
    color: colors.greyMedium,
    fontSize: "12px",
  },
};

export const elementDataConfig = [
  {
    name: "nodeBoundary",
    title: "Polygon",
    headers: ["name", "value"],
    dataKeys: {
      id: "id",
      jobId: "jobId",
      polygonName: "polygonName",
    },
  },
  {
    name: "fiberCableGeometry",
    title: "Fiber Cable",
    headers: ["name", "value"],
    dataKeys: {
      cableName: "fiberCable.cableName",
      fibreCount: "fiberCable.fiberCount",
      cableType: "fiberCable.cableType",
      owner: "fiberCable.owner",
      constructionType: "cableSegment.constructionType",
      legacyOwner: "fiberCable.legacyOwner",
      legacyId: "fiberCable.legacyId",
      installDate: "fiberCable.installDate",
      "rpats/project": "fiberCable.rpats",
      constructionStatus: "fiberCable.construcStatus",
      modelLength: "cableSegment.modelLength",
      cableDesignLength: "fiberCable.designLength",
      segmentNetworkId: "cableSegment.segmentNetworkId",
      cableNetworkId: "fiberCable.cableNetworkId",
    },
  },
  {
    name: "fiberSpliceCase",
    title: "FOSC",
    headers: ["name", "value"],
    dataKeys: {
      networkId: "networkId",
      designation: "designation",
      type: "siteType",
      address: "address1",
      town: "town",
      location: "location",
      legacyOwner: "legacyOwner",
      symbol: "symbolName",
    },
  },
  {
    name: "fiberStorageAndRiser",
    title: "Fiber Storage & Riser",
    headers: ["name", "value"],
    dataKeys: {
      networkId: "networkId",
      designation: "designation",
      type: "type",
      address: "address1",
      location: "location",
      legacyOwner: "legacyOwner",
      symbol: "symbolName",
    },
  },
  {
    name: "fiberAddrConnectionPoint",
    title: "Fiber ACP",
    headers: ["name", "value"],
    dataKeys: {
      networkId: "networkId",
      designation: "designation",
      type: "stype",
      layerName: "layerName",
      parentId: "parentNetworkId",
      parentType: "parentType",
      location: "location",
      legacyOwner: "legacyOwner",
      symbol: "symbolName",
    },
  },
  {
    name: "tap",
    title: "Tap",
    headers: ["name", "value"],
    dataKeys: {
      "tap Id": "id",
      "tap Value": "tapValue",
      "equipment Type": "deviceType",
      "freq1 (50Mhz)": "freq1",
      "freq2 (102Mhz)": "freq2",
      "freq3 (450Mhz)": "freq3",
      "freq4 (499Mhz)": "freq4",
      "freq5 (550Mhz)": "freq5",
      "freq6 (625Mhz)": "freq6",
      "freq7 (750Mhz)": "freq7",
      "freq8 (860Mhz)": "freq8",
      "freq9 (1000Mhz)": "freq9",
      "freq10 (1218Mhz)": "freq10",
      "freq11 (258Mhz)": "freq11",
      "freq12 (0Mhz)": "freq12",
      "rtnF1 (5Mhz)": "rtnF1",
      "rtnF2 (42Mhz)": "rtnF2",
      "rtnF3 (85Mhz)": "rtnF3",
      "rtnF4 (0Mhz)": "rtnF4",
      symbol: "symbolName",
    },
  },
  {
    name: "splitter",
    title: "Splitters",
    headers: ["name", "value"],
    dataKeys: {
      rfDeviceType: "rfDeviceType",
      numberOfPorts: "numberOfPorts",
      rfSystemType: "systemType",
      networkId: "networkId",
      symbol: "symbolName",
    },
  },
  {
    name: "opticalTap",
    title: "Optical Tap",
    headers: ["name", "value"],
    dataKeys: {
      networkId: "networkId",
      location: "location",
      designation: "designation",
      constructionStatus: "constructionStatus",
      address1: "address1",
      address2: "address2",
      town: "town",
      province: "state",
      zipcode: "zipcode",
      siteType: "siteType",
      stype: "stype",
      symbol: "symbolName",
    },
  },
  {
    name: "powerBlock",
    title: "PowerBlock",
    headers: ["name", "value"],
    dataKeys: {
      rfDeviceType: "rfDeviceType",
      numberOfPorts: "numberOfPorts",
      rfSystemType: "systemType",
      networkId: "networkId",
      symbol: "symbolName",
    },
  },
  {
    name: "rfCable",
    title: "RF Cable",
    headers: ["name", "value"],
    dataKeys: {
      networkId: "networkId",
      length: "length",
      cableType: "cableType",
      resistance: "resistance",
    },
  },
  {
    name: "terminator",
    title: "Terminator",
    headers: ["name", "value"],
    dataKeys: {
      networkId: "networkId",
      rfEquipmentType: "rfDeviceType",
      rfSystemType: "sysType",
      numberOfPorts: "ports",
      symbol: "symbolName",
    },
  },
  {
    name: "powerSupply",
    title: "PowerSupply",
    headers: ["name", "value"],
    dataKeys: {
      // psRensNumber: "rfDeviceId",
      psRensNumber: "rensNumber",
      rfDeviceType: "rfDeviceType",
      profileName: "profileName",
      maxAmps: "maxAmps",
      volts: "volts",
      amps: "amps",
      networkId: "networkId",
      symbol: "symbolName",
    },
  },
  {
    name: "powerInserter",
    title: "Power Inserter",
    headers: ["name", "value"],
    dataKeys: {
      profileName: "profileName",
      networkId: "networkId",
      passCurrent: "passCurrent",
      sysType: "sysType",
      isPblock: "isPblock",
      deviceDesc: "deviceDesc",
      manufacturer: "manufacturer",
      distPrevActive: "distPrevActive",
      flipX: "flipX",
      isTerm: "isTerm",
      deviceType: "deviceType",
      isSplitter: "isSplitter",
      rfDeviceType: "rfDeviceType",
      maxAmps: "maxamps",
      volts: "volts",
      watts: "watts",
      ohms: "ohms",
      numPorts: "numPorts",
      symbol: "symbolName",
    },
  },
  {
    name: "rfDrop",
    title: "RF Drop",
    headers: ["name", "value"],
    dataKeys: {
      streetName: "streetName",
      streetNumber: "streetNumber",
      networkId: "networkId",
      symbol: "symbolName",
    },
  },
  {
    name: "mdu",
    title: "MDU",
    headers: ["name", "value"],
    dataKeys: {
      networkId: "networkId",
      streetNumber: "streetNumber",
      recStatus: "recStatus",
      blockW: "blockW",
      attr2: "attr2",
      attr1: "attr1",
      attr4: "attr4",
      attr3: "attr3",
      buildingName: "buildingName",
      addressCount: "addressCount",
      blockShape: "blockShape",
      addrAnnoType: "addrAnnoType",
      blockH: "blockH",
      buildingType: "buildingType",
      symbol: "symbolName",
    },
  },
  {
    name: "detailMark",
    title: "Detail Mark",
    headers: ["name", "value"],
    dataKeys: {
      networkId: "networkId",
      miscType: "miscType",
      sType: "sType",
      miscDesc: "miscDesc",
      designation: "designation",
      layerName: "layerName",
      symbol: "symbolName",
    },
  },
  {
    name: "cableDip",
    title: "Cable Dip",
    headers: ["name", "value"],
    dataKeys: {
      networkId: "networkId",
      datePublished: "datePublished",
      jobId: "jobId",
      sType: "sType",
      symbol: "symbolName",
    },
  },
  {
    name: "pole",
    title: "Pole",
    headers: ["name", "value"],
    dataKeys: {
      networkId: "networkId",
      nhType: "nhType",
      hCountRes: "hCountRes",
      annoText: "annoText",
      hCountMisc: "hCountMisc",
      attachmentTolerance: "attachmentTolerance",
      attachmentClasses: "attachmentClasses",
      description: "description",
      blockUniformScale: "blockUniformScale",
      blockW: "blockW",
      environment: "environment",
      blockShape: "blockShape",
      annoTypes: "annoTypes",
      subType: "subType",
      layerName: "layerName",
      blockH: "blockH",
      symbol: "symbolName",
    },
  },
  {
    name: "poleStrand",
    title: "Pole Strand",
    headers: ["name", "value"],
    dataKeys: {
      networkId: "networkId",
      endNodec: "endNodec",
      annoText: "annoText",
      lhsType: "lhsType",
      endNode: "endNode",
      datePublished: "datePublished",
      segLength: "segLength",
      ownerName: "ownerName",
      yearAdded: "yearAdded",
      startNode: "startNode",
      startNodec: "startNodec",
      trenchSize: "trenchSize",
      startDepth: "startDepth",
      subType: "subType",
      endDepth: "endDepth",
    },
  },
  {
    name: "rfSplice",
    title: "RF Splice",
    headers: ["name", "value"],
    dataKeys: {
      networkId: "networkId",
      deviceType: "deviceType",
      numPorts: "numPorts",
      sysType: "sysType",
      symbol: "symbolName",
    },
  },
  {
    name: "activeEquipment",
    title: "Active Equipment",
    headers: ["name", "value"],
    dataKeys: {
      rfActive: "rfActive",
      systemDescription: "systemDescription",
      networkId: "networkId",
      equipmentName: "equipmentName",
      rfDeviceType: "rfDeviceType",
      numberOfPorts: "numberOfPorts",
      cascade: "cascade",
      nodeId: "nodeId",
      distanceToPreviousActive: "distanceToPreviousActive",
      profileName: "profileName",
      rfSystemType: "rfSystemType",
      constructionType: "constructionType",
      powerSupplyId: "powerSupplyId",
      powerDirection: "powerDirection",
      volts: "volts",
      amps: "amps",
      powerConsumption: "wattage",
      symbol: "symbolName",
    },
  },
  {
    name: "annotationBlock",
    title: "Annotation Block",
    headers: ["name", "value"],
    dataKeys: {
      networkId: "networkId",
      tag: "tag",
      text: "text",
      symbol: "symbol",
    },
  },
  {
    name: "cpatEvent",
    title: "CPAT Event",
    headers: ["name", "value"],
    dataKeys: {
      "Event#": "id",
      "Node ": "descSector",
      "Address ": "adress",
      "Detection Date": "dateLastDetected",
      "Status ": "status",
      "Mid Leak (μV/m)": "leakMid",
      "Mid Freq (MHz)": "freqLkMid",
      "LTE Leak (μV/m)": "leakLTE",
      "LTE Freq (MHz)": "freqLkLTE",
      "Ingress (dBmV)": "ingress",
      "Ingress Freq (MHz)": "freqIngress",
    },
  },
  {
    name: "localConvergencePoint",
    title: "LCP",
    headers: ["name", "value"],
    dataKeys: {
      "Network Id": "networkId",
      "Designation ": "designation",
      "S Type": "sType",
      "Site Type": "siteType",
      Status: "constructionStatus",
      Attr14: "attr14",
      Attr30: "attr30",
      Location: "location",
      Address1: "address1",
      Address2: "address2",
      Town: "town",
      Province: "state",
      "Zip Code": "zipcode",
      symbol: "symbolName",
    },
  },
  {
    name: "mduActive",
    title: "MDU Active Equipment",
    headers: ["name", "value"],
    dataKeys: {
      rfActive: "rfActive",
      systemDescription: "systemDescription",
      networkId: "networkId",
      equipmentName: "equipmentName",
      rfDeviceType: "rfDeviceType",
      numberOfPorts: "numberOfPorts",
      cascade: "cascade",
      nodeId: "nodeId",
      distanceToPreviousActive: "distanceToPreviousActive",
      profileName: "profileName",
      rfSystemType: "rfSystemType",
      constructionType: "constructionType",
      powerSupplyId: "powerSupplyId",
      powerDirection: "powerDirection",
      volts: "volts",
      amps: "amps",
      powerConsumption: "wattage",
      symbol: "symbolName",
    },
  },
  {
    name: "mduPowerSupply",
    title: "MDU Power Supply",
    headers: ["name", "value"],
    dataKeys: {
      psRensNumber: "rfDeviceId",
      rfDeviceType: "rfDeviceType",
      profileName: "profileName",
      maxAmps: "maxAmps",
      volts: "volts",
      amps: "amps",
      networkId: "networkId",
      symbol: "symbolName",
    },
  },
  {
    name: "mduFiberEquipment",
    title: "MDU Fiber Equipment",
    headers: ["name", "value"],
    dataKeys: {
      ownerName: "ownerName",
      networkId: "networkId",
      designation: "designation",
      layerName: "layerName",
      siteType: "siteType",
      town: "town",
      address: "address1",
      constructionStatus: "constructionStatus",
      description: "description",
      type: "sType",
      symbol: "symbolName",
    },
  },
  {
    name: "mduTap",
    title: "MDU Tap",
    headers: ["name", "value"],
    dataKeys: {
      "tap Id": "networkId",
      "tap Value": "tapVal",
      "equipment Type": "rfDeviceType",
      "freq1 (50Mhz)": "freq1",
      "freq2 (102Mhz)": "freq2",
      "freq3 (450Mhz)": "freq3",
      "freq4 (499Mhz)": "freq4",
      "freq5 (550Mhz)": "freq5",
      "freq6 (625Mhz)": "freq6",
      "freq7 (750Mhz)": "freq7",
      "freq8 (860Mhz)": "freq8",
      "freq9 (1000Mhz)": "freq9",
      "freq10 (1218Mhz)": "freq10",
      "freq11 (258Mhz)": "freq11",
      "freq12 (0Mhz)": "freq12",
      "rtnF1 (5Mhz)": "rtnF1",
      "rtnF2 (42Mhz)": "rtnF2",
      "rtnF3 (85Mhz)": "rtnF3",
      "rtnF4 (0Mhz)": "rtnF4",
      symbol: "symbolName",
    },
  },
  {
    name: "rensElement",
    title: "RENS Locator Equipment",
    headers: ["name", "value"],
    dataKeys: {
      clamshell: "clamshell",
      jobId: "jobId",
      journal: "journal",
      networkId: "networkId",
      returnSegment: "returnSegment",
      rfDeviceType: "rfDeviceType",
      sType: "sType",
      symbol: "symbolName",
    },
  },
  {
    name: "wirelessSite",
    title: "Wireless Site",
    headers: ["name", "value"],
    dataKeys: {
      networkId: "networkId",
      name: "name",
      ownerContact: "ownerContact",
      ownerName: "ownerName",
      projectId: "projectId",
      type: "type",
      attr1: "attr1",
      attr3: "attr3",
      clli: "clli",
      ispCode: "ispCode",
      address1: "address1",
      address2: "address2",
      state: "state",
      town: "town",
      zipcode: "zipcode",
      symbol: "symbolName",
    },
  },
  {
    name: "smallCell",
    title: "Small Cell Site",
    headers: ["name", "value"],
    dataKeys: {
      siteId: "siteId",
      siteType: "siteType",
      sType: "sType",
      siteCode: "siteCode",
      siteDesc: "siteDesc",
      designation: "designation",
      address: "address",
      attr1: "attr1",
      attr2: "attr2",
      town: "town",
      zipcode: "zipcode",
      layerName: "layerName",
      location: "location",
      manufacturer: "manufacturer",
      symbol: "symbolName",
    },
  },
  {
    name: "clamshell",
    title: "Clamshell Site",
    headers: ["name", "value"],
    dataKeys: {
      siteId: "siteId",
      siteType: "siteType",
      sType: "sType",
      siteCode: "siteCode",
      siteDesc: "siteDesc",
      designation: "designation",
      address: "address",
      attr1: "attr1",
      attr2: "attr2",
      town: "town",
      zipcode: "zipcode",
      layerName: "layerName",
      location: "location",
      manufacturer: "manufacturer",
      symbol: "symbolName",
    },
  },
  {
    name: "rbCircuit",
    title: "RB Circuit",
    headers: ["name", "value"],
    dataKeys: {
      buildingId: "buildingId",
      circuitId: "circuitId",
      ringName: "ringName",
      usageDesc: "usageDesc",
      symbol: "symbolName",
    },
  },
  {
    name: "ispSite",
    title: "ISP Site",
    headers: ["name", "value"],
    dataKeys: {
      networkId: "networkId",
      name: "name",
      ispCode: "ispCode",
      address: "address1",
      clli: "clli",
      description: "description",
      type: "type",
      attr2: "attr2",
      attr1: "attr1",
      attr3: "attr3",
      zipcode: "zipcode",
      sType: "sType",
      ownerName: "ownerName",
    },
  },
];

export const NIKA_CONFIG = {
  incDecBy: 100, //increase or decrease radius search by meters
  minRadius: 100,
  maxRadius: 2000,
};

export const CLICK_CONFIG = {
  incDecBy: 100, //increase or decrease radius search by meters
  minRadius: 100,
  maxRadius: 2000,
  dfltRadiusVal: 500,
  dfltDaysBack: 30,
  maxDaysBack: 90,
};

export const CPAT_CONFIG = {
  midLeakageIncDecBy: 100,
  minMidLeakage: 1,
  maxMidLeakage: 1200,
  lteLeakageIncDecBy: 100,
  minLteLeakage: 1,
  maxLteLeakge: 1200,
  ingressIncDecBy: 10,
  minIngress: -99,
  maxIngress: 99,
  minCode: 1000,
  maxCode: 4000,
  incDecBy: 100,
  radiusIncDecBy: 100,
  minRadius: 100,
  maxRadius: 2000,
  dfltDaysBack: 365,
  maxDaysBack: 730,
};

export const RENS_Search_Keys = ["powerSupplyId"];

export const REDLINE_CONFIG = { dfltDaysBack: 365, maxDaysBack: 1825 };
