import CustomAccordion from "../reusable/CustomAccordion";
import CustomPopOver from "../reusable/CustomPopOver";
import {
  getOFDMBaselineRatios,
  getOFDMcorrectedDelta,
  getOFDMTotalCodewordsDelta,
  getOFDMUncorrectablesDelta,
  isNull,
} from "../reusable/Util";
const CwOFDMA = (props: any) => {
  const { cwOFDMAData, currSeconds, firstCWInfo } = props;

  return (
    <>
      {cwOFDMAData !== undefined && cwOFDMAData?.length !== 0 ? (
        <CustomAccordion
          accTitle={"OFDMA Channels".toUpperCase()}
          accBodyElement={
            <>
              <table className="dataTableDetails">
                <thead>
                  <tr>
                    <th>{"channel Id".toUpperCase()}</th>
                    <CustomPopOver
                      position="top"
                      popOverData="Modulation Profile"
                      originComp={<th>{"Iuc".toUpperCase()}</th>}
                      index="popOver"
                    />
                    <CustomPopOver
                      position="top"
                      popOverData="Total uncorrectable codewords/total codewords prior to the current polling session"
                      originComp={<th>{"Baseline Errors".toUpperCase()}</th>}
                      index="popOver"
                    />
                    <CustomPopOver
                      position="top"
                      popOverData="Count of errored codewords that have been corrected during the interval"
                      originComp={<th>{"corrected delta".toUpperCase()}</th>}
                      index="popOver"
                    />
                    <CustomPopOver
                      position="top"
                      popOverData="Count of errored codewords that were not corrected during the interval"
                      originComp={<th>{"uncorrectables delta".toUpperCase()}</th>}
                      index="popOver"
                    />
                    <CustomPopOver
                      position="top"
                      popOverData="Count of all codewords received during the interval"
                      originComp={<th>{"total delta".toUpperCase()}</th>}
                      index="popOver"
                    />
                  </tr>
                </thead>
                {cwOFDMAData?.map((ch: any, index: number) => {
                  return (
                    <tbody key={index}>
                      {ch?.iucList !== undefined &&
                        ch?.iucList?.length !== 0 ? (
                        ch?.iucList?.map((p: any, i: number) => {
                          return (
                            <tr key={i}>
                              {i === 0 ? (
                                <td rowSpan={ch?.iucList?.length}>
                                  {isNull(ch?.channelId)}
                                </td>
                              ) : (
                                <></>
                              )}
                              <td>
                                [{isNull(p?.iuc)}]{isNull(p?.modulation)}
                              </td>
                              <td>
                                {isNull(
                                  getOFDMBaselineRatios(
                                    p?.unreliableCodewords,
                                    p?.totalCodewords
                                  )
                                )}
                              </td>
                              <td>
                                {isNull(
                                  getOFDMcorrectedDelta(
                                    firstCWInfo?.[index],
                                    currSeconds,
                                    p,
                                    "OFDMA"
                                  )
                                )}
                              </td>
                              <td>
                                {isNull(
                                  getOFDMUncorrectablesDelta(
                                    firstCWInfo?.[index],
                                    currSeconds,
                                    p,
                                    "OFDMA"
                                  )
                                )}
                              </td>
                              <td>
                                {isNull(
                                  getOFDMTotalCodewordsDelta(
                                    firstCWInfo?.[index],
                                    currSeconds,
                                    p,
                                    "OFDMA"
                                  )
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </tbody>
                  );
                })}
              </table>
            </>
          }
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default CwOFDMA;
