import { useDispatch } from "react-redux";
import STBClient from "../../network/httpclient/stb/STBClient";
import DiagContainer from "../reusable/DiagContainer";
import { isNull, breakLongStrings } from "../reusable/Util";
import ReloadData from "../reusable/ReloadData";
import ExceptionBox from "../reusable/ExceptionBox";
import CustomAccordion from "../reusable/CustomAccordion";

const STBDocsisEventLogs = (props: any) => {
  const {
    selectedDevice,
    stbLoading,
    stbCmMac,
    stbDocsisEventsDetails,
    stbDocsisEventStatus,
  } = props;
  const dispatch = useDispatch();

  const refreshData = () => {
    dispatch(
      STBClient.getSTBCMDocsisEvents(selectedDevice?.serialNumber, stbCmMac)
    );
  };

  return (
    <>
      <div className="refreshCont">
        <ReloadData refreshData={refreshData} />
      </div>
      <div className="cmSummaryCont">
        <DiagContainer
          isLoading={stbLoading}
          data={stbDocsisEventsDetails}
          sectionName="Docsis Events"
          error={stbDocsisEventStatus}
          handleRefresh={() => refreshData()}
        >
          <CustomAccordion
            accTitle={"Docsis Event Logs".toUpperCase()}
            accBodyElement={
              <>
                {stbDocsisEventsDetails !== undefined ? (
                  <div className="docsisEventCont">
                    <table className="dataTableDetails">
                      {stbDocsisEventsDetails?.length !== 0 ? (
                        <>
                          <thead>
                            <th className="smallCol">{"Id".toUpperCase()}</th>
                            <th>{"First Time".toUpperCase()}</th>
                            <th>{"Last Time".toUpperCase()}</th>
                            <th>{"Count".toUpperCase()}</th>
                            <th className="smallCol">
                              {"Level".toUpperCase()}
                            </th>
                            <th>{"Description".toUpperCase()}</th>
                          </thead>
                          <tbody>
                            {stbDocsisEventsDetails?.map(
                              (event: any, index: number) => {
                                return (
                                  <tr key={index}>
                                    <td>{isNull(event?.eventId)}</td>
                                    <td>{isNull(event?.firstOccurTime)}</td>
                                    <td>{isNull(event?.lastOccurTime)}</td>
                                    <td>{isNull(event?.noOfOccurance)}</td>
                                    <td>{isNull(event?.level)}</td>
                                    <td>
                                      {isNull(
                                        breakLongStrings(
                                          event?.description,
                                          ";"
                                        )
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </>
                      ) : (
                        <div className="diagErrorCont">
                          <ExceptionBox
                            headerText="No Data to display in Docsis Events."
                            bodyText="Please try refreshing."
                            primaryBtn={false}
                            secBtn={false}
                          />
                        </div>
                      )}
                    </table>
                  </div>
                ) : (
                  <></>
                )}
              </>
            }
          />
        </DiagContainer>
      </div>
    </>
  );
};

export default STBDocsisEventLogs;
