import { useState, useEffect } from "react";

import GeoEditCanvas from "../components/map/redline/GeoEditCanvas";
import ExceptionBox from "../../components/reusable/ExceptionBox";

export default function ImageEditorScreen(props: any) {
  const [bgImage, setBgImage] = useState<string | null>(null);
  const [latLng, setLatLng] = useState<{
    latitude: number;
    longitude: number;
  }>();
  const [nodeId, setNodeId] = useState("");
  const [techData, setTechData] = useState();
  const [owningDrawing, setOwningDrawing] = useState("");

  useEffect(() => {
    const handleReceiveMessage = (event: MessageEvent) => {
      // Ensure the message is coming from the expected origin for security
      if (event.origin !== window.location.origin) return;

      if (event.data && event.data.bgImage) setBgImage(event.data.bgImage);
      if (event.data && event.data.latLng) setLatLng(event.data.latLng);
      if (event.data && event.data.nodeId) setNodeId(event.data.nodeId);
      if (event.data && event.data.techData) setTechData(event.data.techData);
      if (event.data && event.data.owningDrawing)
        setOwningDrawing(event.data.owningDrawing);
    };

    window.addEventListener("message", handleReceiveMessage);
    return () => {
      window.removeEventListener("message", handleReceiveMessage);
    };
  }, []);

  return (
    <>
      {bgImage ? (
        <GeoEditCanvas
          bgImage={bgImage}
          nodeId={nodeId}
          latLng={latLng}
          techData={techData}
          owningDrawing={owningDrawing}
        />
      ) : (
        <div className="diagErrorCont">
          <ExceptionBox
            headerText={"Image Loading..."}
            bodyText={
              "Background Image Loading.../ Or proper background image is not passed"
            }
          />
        </div>
      )}
    </>
  );
}
