import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { CWMonitorState } from "../../../store/states/account/diagnostics/cm/CWMonitoringState";
import {
  CWMonitoringBegin,
  CWMonitoringFailure,
  CWMonitoringSuccess,
  CWMonitoringFailures,
  CWMonitoringCertificationSuccess,
} from "../../../store/actions/account/diagnostics/cm/CWMonitoringAction";
import { CWMonitor } from "../../../store/models/account/diagnostics/cm/CWMonitor";

export default class CodeWordsClient {
  
  static getCodewords =
    (
      macAddress: string,
      flag: boolean
    ): ThunkAction<void, CWMonitorState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "diag/cablemodem/" +
          macAddress +
          "/codewords",
      };
      dispatch(CWMonitoringBegin());
      if(flag){
      HttpClient.get<CWMonitor>(httpParameters)
        .then((cwMonitor) => {
          dispatch(CWMonitoringCertificationSuccess(cwMonitor));
          return cwMonitor;
        })
        .catch((error) => {
          dispatch(CWMonitoringFailures(error));
          dispatch(CWMonitoringFailure(error));
          return error;
        });
      }
      else{
        HttpClient.get<CWMonitor>(httpParameters)
        .then((cwMonitor) => {
          dispatch(CWMonitoringSuccess(cwMonitor));
          return cwMonitor;
        })
        .catch((error) => {
          dispatch(CWMonitoringFailures(error));
          dispatch(CWMonitoringFailure(error));
          return error;
        });
      }
    };
}
