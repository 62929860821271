import { shallowEqual, useSelector } from "react-redux";
import { useState } from "react";

import AppLabels from "../../constants/App_Labels";
import "../../design-tokens/account-page/FailedAttributes.scss";
import { renderAttrTable } from "../reusable/Util";
import { getCmFailedAttributes } from "../../config/FailedAttributes";
import CustomAccordion from "../reusable/CustomAccordion";
import CustomPopUp from "../reusable/CustomPopUp";
import NextBestAction from "../diagnostics/NextBestAction";

const FailedAttributesCm = (props: any) => {
  const { cmDocsisInfo } = props;
  const labels = AppLabels();
  const [popUpFlag, setPopUpFlag] = useState(false);
  const [renderedItem, setRenderedItem] = useState<{
    name: any;
    value: any;
    label: any;
  } | null>(null); // array of flags to open the pop up for each different failing attributes
  const { failedAttributes } = useSelector(
    (state: any) => ({
      failedAttributes:
        state.AccountState.ActiveAccountDetails.failedAttributes,
    }),
    shallowEqual
  );
  let arr = getCmFailedAttributes(cmDocsisInfo, failedAttributes);

  const renderPopUp = (el: { name: string; value: any; label: any }) => {
    return (
      <>
        <CustomAccordion
          accTitle={labels.failedAttributes?.toUpperCase()}
          accBodyElement={renderAttrTable(el, "Fail")}
        />
        <CustomAccordion
          accTitle={labels.nextBestActions?.toUpperCase()}
          accBodyElement={<NextBestAction data={el} status={"Fail"} />}
        />
      </>
    );
  };
  return (
    <div className="faCmLink">
      <p>
        <span style={{ fontWeight: 600 }}>
          {labels.failedAttributes?.toUpperCase()} :
        </span>
        {arr &&
          arr?.map((el, index) => {
            return (
              <span
                key={index}
                className="tooltipText"
                onClick={() => {
                  setRenderedItem(el);
                  setPopUpFlag(true);
                }}
              >
                {el?.name},{" "}
              </span>
            );
          })}
      </p>
      <CustomPopUp
        className="nbaPopUp"
        showVal={popUpFlag}
        headerText={labels.failedAttributes}
        bodyElement={renderedItem ? renderPopUp(renderedItem) : <></>}
        handleClose={() => {
          setPopUpFlag(false);
        }}
        buttonDisabled={true}
      />
    </div>
  );
};

export default FailedAttributesCm;
