import { Card } from "react-bootstrap";
import { useState } from "react";
import CustomTab from "../../reusable/CustomTab";
import { TabPanel } from "../../../screens/account-page/AccountPageTabs";
import OFDMUpChannelData from "./OFDMUpChannelData";
import DocsisUpChData from "./DocsisUpChData";
import AppLabels from "../../../constants/App_Labels";
import CustomAccordion from "../../reusable/CustomAccordion";
import CmUSTab from "../../../components/modem/CmUSTab";

const CmUpStreamCh = (props: any) => {
  const { cmDocsisInfo } = props;

  const [tabSelIndex, setTabSelIndex] = useState(0);
  let ofdmUpStreamChannels: any = [];
  const labels = AppLabels();
  if (
    cmDocsisInfo?.hasOwnProperty("ofdm") &&
    cmDocsisInfo.ofdm?.hasOwnProperty("upstream") &&
    cmDocsisInfo.ofdm?.upstream?.hasOwnProperty("channels")
  ) {
    cmDocsisInfo.ofdm?.upstream?.channels?.map((usC: any) => {
      ofdmUpStreamChannels.push(usC);
    });
  }
  const [count, setIndex] = useState(
    cmDocsisInfo.docsisVersion === "DOCSIS31"
      ? ofdmUpStreamChannels?.length + 1
      : 1
  );
  return (
    <CustomAccordion
      accTitle={labels.UPSTREAM_CHANNELS.toUpperCase()}
      accBodyElement={
        <>
          {cmDocsisInfo !== undefined ? (
            <>
              <Card className="dsChTabsCard">
                <Card.Header>
                  <CustomTab
                    tabType="horizontal"
                    index={0}
                    value={tabSelIndex}
                    handleSelect={(i: any) => setTabSelIndex(i)}
                    label="DOCSIS 3.0"
                  />
                  {cmDocsisInfo?.docsisVersion === "DOCSIS31" &&
                    ofdmUpStreamChannels?.length !== 0 ? (
                    ofdmUpStreamChannels?.map((ch: any, index: number) => {
                      return (
                        <CustomTab
                          tabType="horizontal"
                          index={index + 1}
                          value={tabSelIndex}
                          handleSelect={(i: any) => setTabSelIndex(i)}
                          label={`OFDMA`}
                        />
                      );
                    })
                  ) : (
                    <></>
                  )}
                  <CustomTab
                    tabType="horizontal"
                    key={count}
                    index={count}
                    value={tabSelIndex}
                    handleSelect={(i: any) => setTabSelIndex(i)}
                    label="SPECTRUM"
                  />
                </Card.Header>
                <Card.Body>
                  {cmDocsisInfo?.docsisVersion === "DOCSIS31" &&
                    ofdmUpStreamChannels?.length !== 0 ? (
                    ofdmUpStreamChannels?.map((ch: any, index: number) => {
                      return (
                        <TabPanel value={tabSelIndex} index={index + 1}>
                          <OFDMUpChannelData
                            ofdmUpChannel={ch}
                            totalChannel={ofdmUpStreamChannels.length}
                          />
                        </TabPanel>
                      );
                    })
                  ) : (
                    <></>
                  )}
                  {cmDocsisInfo?.hasOwnProperty("docsis30") &&
                    cmDocsisInfo?.docsis30?.hasOwnProperty("upstream") ? (
                    <TabPanel value={tabSelIndex} index={0}>
                      <DocsisUpChData
                        docsis30UsChannels={cmDocsisInfo.docsis30.upstream}
                      />
                    </TabPanel>
                  ) : (
                    <></>
                  )}
                  <TabPanel value={tabSelIndex} index={count}>
                    <CmUSTab cmData={cmDocsisInfo} flag={true} />
                  </TabPanel>
                </Card.Body>
              </Card>
            </>
          ) : (
            <>NO DATA AVAILABLE...</>
          )}
        </>
      }
    />
  );
};

export default CmUpStreamCh;
