import { NewCommentState } from "../../states/feedback/NewCommentState";
import {
  SAVE_NEW_COMMENT_BEGIN,
  SAVE_NEW_COMMENT_SUCCESS,
  SAVE_NEW_COMMENT_FAILURE,
  NewCommentActionTypes,
} from "../../types/feedback/FeedbackCommentCreation";

const initialState: NewCommentState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function CommentCreationReducer(
  state = initialState,
  action: NewCommentActionTypes
): NewCommentState {
  switch (action.type) {
    case SAVE_NEW_COMMENT_BEGIN:
      return {
        isLoading: true,
      };
    case SAVE_NEW_COMMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case SAVE_NEW_COMMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}
