export const CW_MONITORING_BEGIN = "CW_MONITORING_BEGIN";
export const CW_MONITORING_SUCCESS = "CW_MONITORING_SUCCESS";
export const CW_MONITORING_FAILURE = "CW_MONITORING_FAILURE";
export const CW_MONITORING_RESET = "CW_MONITORING_RESET";
export const CW_MONITORING_INDEX ="CW_MONITORING_INDEX"
export const CW_Monitoring_Certification_Success="CW_Monitoring_Certification_Success";
export const CW_Monitoring_Tracer="CW_Monitoring_Tracer";
export const SET_CODEWORD_FAILURE = 'SET_CODEWORD_FAILURE';
interface CWMonitorBegin {
  type: typeof CW_MONITORING_BEGIN;
}
interface CWMonitorSuccess {
  type: typeof CW_MONITORING_SUCCESS;
  payload: any;
}
interface CWMonitorFailure {
  type: typeof CW_MONITORING_FAILURE;
  payload: any;
}
interface CWMonitoringFailures {
  type: typeof SET_CODEWORD_FAILURE;
  payload: any;
}
interface CWMonitoringCertificationSuccess{
  type: typeof CW_Monitoring_Certification_Success;
  payload: any;
}
interface CWMonitoringIndex {
  type: typeof CW_MONITORING_INDEX;
  payload: any;
}
interface CWMonitoringTracer{
  type: typeof CW_Monitoring_Tracer;
  payload: boolean;
}
interface CWMonitorReset {
  type: typeof CW_MONITORING_RESET;
}
export type CWMonitorActionTypes =
  | CWMonitorBegin
  | CWMonitorSuccess
  | CWMonitorFailure
  | CWMonitoringFailures
  | CWMonitoringIndex
  | CWMonitoringCertificationSuccess
  | CWMonitoringTracer
  | CWMonitorReset;
