export const CLEAR_FLAPS_BEGIN = "CLEAR_FLAPS_BEGIN";
export const CLEAR_FLAPS_SUCCESS = "CLEAR_FLAPS_SUCCESS";
export const CLEAR_FLAPS_FAILURE = "CLEAR_FLAPS_FAILURE";

interface ClearFlapsBegin {
  type: typeof CLEAR_FLAPS_BEGIN;
}

interface ClearFlapsSuccess {
  type: typeof CLEAR_FLAPS_SUCCESS;
  payload: any;
}

interface ClearFlapsFailure {
  type: typeof CLEAR_FLAPS_FAILURE;
  payload: Error;
}

export type ClearFlapsActionTypes = ClearFlapsBegin | ClearFlapsSuccess | ClearFlapsFailure;
