import { ThunkAction } from "redux-thunk";
import { Action } from "redux";

import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import {
  techProfileBegin,
  techProfileFailure,
  techProfileSuccess,
} from "../../../store/ducksPattern/TechProfile";

export default class TECHClient {
  static getTechniciansProfile =
    (): ThunkAction<void, any, unknown, Action<string>> => async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "technicians/profile",
      };
      dispatch(techProfileBegin());

      HttpClient.get<any>(httpParameters)
        .then((techniciansProfile) => {
          dispatch(techProfileSuccess(techniciansProfile));
          return techniciansProfile;
        })
        .catch((error) => {
          dispatch(techProfileFailure(error));
          return error;
        });
    };

  static captureEvent =
    (
      techId: string,
      browserName: string,
      agent: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "technicians/capture",
        params: {
          techId: techId,
          browserName: browserName,
          agent: agent,
        },
      };
      // dispatch(techniciansProfileBegin());

      HttpClient.post<any>(httpParameters, null)
        .then((resp) => {
          // dispatch(techniciansProfileSuccess(techniciansProfile));
          return resp;
        })
        .catch((error) => {
          // dispatch(techniciansProfilFailure(error));
          return error;
        });
    };
}
