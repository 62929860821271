import React, { useRef, useState } from 'react';
import { IoClose } from 'react-icons/io5';

import "../../design-tokens/file-manager-page/FileManagerPage.scss";
import FileTypeIcons from './FileTypeIcons';
import CustomIcons from './CustomIcons';
import { formatBytesToSize } from './Util';
import ErrorBox from './ErrorBox';
import AppLabels from '../../constants/App_Labels';
interface Props {
    title?: string;
    sizeLimit?: string;
    mandatoryTitle?: React.ReactNode;
    onFileChange: any;
    file: any;
    allowedTypes?: string[];
    multiple?: boolean;
}

const DropFileInput = (props: Props) => {
    const labels = AppLabels();
    const { multiple = false, sizeLimit = "5 MBs" } = props;

    const [fileList, setFileList] = useState<any[]>(props.file);
    const [fileTypeFlag, setFileTypeFlag] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const allowedTypes = props?.allowedTypes ? props?.allowedTypes : ["pdf", "xlsx"]
    const notAllowedTypeError = `${labels.File_Type_Not_Allowed} ${allowedTypes}`;

    const getFileExtension = (filename: string) => {
        const parts = filename.split(".");
        const extension = parts[parts.length - 1];
        return extension.toLowerCase();
    }

    function isFileTypeAllowed(file: File, allowedTypes: string[]) {
        return allowedTypes.includes(getFileExtension(file.name));
    }

    const onDragEnter = () => wrapperRef.current?.classList?.add('dragover');
    const onDragLeave = () => wrapperRef.current?.classList?.remove('dragover');
    const onDrop = () => wrapperRef.current?.classList?.remove('dragover');


    const onFileDrop = (e: any) => {
        setFileTypeFlag(false);
        let updatedList: any[] = [];
        if (e.target.files) {
            Array.from(e.target.files).forEach((file: any) => {
                if (file && isFileTypeAllowed(file, allowedTypes)) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        if (!multiple) {
                            updatedList = [{ file: reader.result, name: file.name, size: file.size }];
                        } else {
                            updatedList.push({ file: reader.result, name: file.name, size: file.size });
                        }
                        setFileList(updatedList);
                        props.onFileChange(updatedList);
                    };
                    reader.readAsDataURL(file);

                } else {
                    setFileTypeFlag(true);
                }

            })

        }
    }

    const fileRemove = (file: any) => {
        const updatedList = [...fileList];
        updatedList.splice(fileList.indexOf(file), 1);
        setFileList(updatedList);
        props.onFileChange(updatedList);
    }


    return (
        <>
            <div
                ref={wrapperRef}
                className="drop-file-input"
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
            >
                <div className="drop-file-input__label">
                    <CustomIcons name="upload" size={70} />
                    <span><br />{labels.Drag_Drop}</span>
                    <span><br />{labels.or}</span>
                    <span><br />{labels.Browse_Files}</span>
                </div>
                <input type="file" value="" onChange={onFileDrop} />
            </div>
            {
                fileList.length > 0 ? (
                    <div className="drop-file-preview">
                        <p className="drop-file-preview__title">
                            {labels.Ready_to_Upload}
                        </p>
                        <p style={{ fontSize: "14px" }}>
                            {labels.File_Upload_Warning_Msg} {sizeLimit}
                        </p>
                        {
                            fileList.map((item: any, index) => (
                                <div key={index} className="drop-file-preview__item">
                                    <FileTypeIcons
                                        name={getFileExtension(item.name)}
                                        size={60} />
                                    <div className="drop-file-preview__item__info">
                                        <p>{item.name}</p>
                                        <p>{formatBytesToSize(item.size)}</p>
                                    </div>
                                    <span className="drop-file-preview__item__del">
                                        <IoClose
                                            color={`var(--pureBlack)`}
                                            onClick={() => fileRemove(item)}
                                        /></span>
                                </div>
                            ))
                        }
                    </div>
                ) : null
            }
            <ErrorBox
                open={fileTypeFlag}
                error={notAllowedTypeError}
                handleClose={() => setFileTypeFlag(false)}
            />
        </>
    );
}

export default DropFileInput;