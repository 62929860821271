import * as React from "react";
import { FaRegPlayCircle, FaRegStopCircle } from "react-icons/fa";
import { IoIosArrowDropup } from "react-icons/io";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import CustomDropDown from "../../components/reusable/CustomDropDown";
import { GetTechInfo } from "../../get-api/GetResponse";
import {FlapVisibility} from "../../components/reusable/RoleConfig";
import {
  getNodeTeleGloabalStatus,
  getTelemetryInfo,
} from "../../components/reusable/NodeUtil";
import AppLabels from "../../constants/App_Labels";
import ConfigConst from "../../constants/ConfigConst";
import { GetTelemetryDetails } from "../../get-api/GetResponse";
import { useRemoveNavBar } from "../../hooks/reusableHooks";
import { TELE_ABORT_KEY } from "../../topology/store/middleware/abortKeys";
import { addAbortKey } from "../../topology/store/middleware/apiClient";
import {
  getTeleModemsList,
  setAllDiagLoading,
  setDiagType,
  setIsCWEnabled,
  setIsNeighbourhood,
  setModemType,
  setNodeId,
  setViewType,
  stopDiagExecution,
} from "../../topology/store/slices/mapItems/diagnostics/telemetry";

import storeConst from "../../topology/store/storeConst";

import { GetTelemetryProps } from "../../topology/util/reduxFunctions/getTopologyState";
import { NeighbourhoodTopBanner } from "./Banner";
import { EmtaNodeTelemetryTab } from "../emta-page/EmtaNodeTelemetryTab";
import { saveConfigData } from "../../store/ducksPattern/userPrefConfig";
import {
  neighbourhoodDataSetType,
  neighbourhoodDataSetTypeEcm,
  neighbourhoodDataSetTypeStb,
  neighbourhoodTeleViewConfig,
  neighbourhoodTeleViewConfigECM,
  neighbourhoodTeleViewConfigSTB,
} from "../../constants/NeighbourhoodTelemetryConfig";
import NodeModemsClient from "../../network/httpclient/telemetry/NodeModemsClient";
import { useHistory } from "react-router-dom";

export const NeighbourhoodPage = (props: any) => {
  // route params from react router
  const { id } = useParams<{ id: string }>();
  const { location: search } = props;
  const searchParams = new URLSearchParams(search.search);
  const cmmac = searchParams.get("cmmac");

  useRemoveNavBar();
  const labels = AppLabels();
  const dispatch = useDispatch();
  const { ducksConfigData } = GetTelemetryDetails();
  const history = useHistory();

  const {
    allDiagIsLoading,
    allDiagStatus,
    isAborted,
    mlData,
    viewType,
    modemType,
    diagStartTime,
    diagEndTime,
    nodeId,
  } = GetTelemetryProps();
  const nodeTeleStatus = getNodeTeleGloabalStatus(
    allDiagStatus,
    allDiagIsLoading,
    isAborted
  );
  const node_id = React.useRef<boolean>(false);
  const [dataSetName, setDataSetName] = React.useState("");
  const [bannerInfo, setBannerInfo] = React.useState<any>({
    active: "",
    assigned: "",
    responsive: "",
    unresponsive: "",
    startTime: "",
    endTime: "",
    nodeId: "",
  });
  const [expand, setExpand] = React.useState(true);
  const [tableAttributes, setTableAttributes] = React.useState<any>([]);

  const addToSearchHistory = (newSearchTerm: string) => {
    let existingHistory = localStorage.getItem("searchHistory");
    let parsedHistory: string[];
    if (existingHistory !== null) {
      parsedHistory = JSON.parse(existingHistory);
    } else {
      parsedHistory = [];
    }

    const historySet = new Set(parsedHistory);

    if (historySet.has(newSearchTerm)) {
      parsedHistory = parsedHistory.filter((item) => item !== newSearchTerm);
    }

    parsedHistory.push(newSearchTerm);
    localStorage.setItem("searchHistory", JSON.stringify(parsedHistory));
  };

  const neighbourhoodDataSet = React.useMemo(() => {
    switch (id?.toLowerCase()?.trim()) {
      case "ecm":
        return neighbourhoodDataSetTypeEcm;
      case "stb":
        return neighbourhoodDataSetTypeStb;
      default:
        return neighbourhoodDataSetType;
    }
  }, [id]);

  useEffect(() => {
    if (cmmac) {
      addToSearchHistory(cmmac);
    }
  }, []);

  let dataSetNames: any = neighbourhoodDataSet?.map((e: any) => {
    return e?.dataSetName;
  });

  const handleToggle = () => {
    setExpand((prev) => !prev);
  };

  const handleRefreshNodeModemList = () => {
    dispatch(
      getTeleModemsList({
        value: cmmac ?? "",
        type: "cmmac",
        diagnoseDevices: true,
        modemType: modemType,
      })
    );
  };

  const handleFlapHistory = () => {
    if (mlData && mlData[0]?.returnSegment) {
      dispatch(
        NodeModemsClient.getFlapHistoryByNodeId(mlData[0]?.returnSegment)
      );
    }
  };

  const runTelemetry = () => {
    addAbortKey(TELE_ABORT_KEY);
    setBannerInfo({
      active: undefined,
      assigned: undefined,
      responsive: undefined,
      unresponsive: undefined,
      startTime: undefined,
      endTime: undefined,
      nodeId: nodeId,
    });
    if (
      nodeTeleStatus === ConfigConst.complete.toLowerCase() ||
      nodeTeleStatus === ConfigConst.stopped.toLowerCase()
    ) {
      dispatch(
        getTeleModemsList({
          value: cmmac ?? "",
          type: "cmmac",
          diagnoseDevices: true,
          modemType: modemType,
        })
      );
    } else dispatch(setAllDiagLoading(true));
  };
  const techData = GetTechInfo()?.data;
  const techRole = () => {
    if (techData && techData.hasOwnProperty("roles")) {
      const roleExists = techData.roles.some((e: any) => {
        return FlapVisibility?.some((roleVisible: any) => {
          return e === roleVisible;
        });
      });
      
      return roleExists; 
    }
    
    return false; // Return false if techData or roles are missing
  };
  const isTechRole = techRole();
  const stopTelemetry = () => {
    dispatch(stopDiagExecution());
  };

  const filterNeighTeleViewConfigAtt = () => {
 const  neighbourhoodTeleView=  neighbourhoodTeleViewConfig(isTechRole)?.map((viewObj: any) => {
      if (viewObj?.viewTypeKey === "full" || viewObj?.viewTypeKey === "basic") {
        return {
          ...viewObj,
          attributes: viewObj?.attributes?.filter(
            (attr: any) =>  attr?.columnKey !== "lines" && attr?.columnKey !== "ofdm_channel_ID" && attr?.columnKey !== "ofdma_channel_ID"
          ),
        };
      } else return viewObj;
    });
    dispatch(saveConfigData(neighbourhoodTeleView));
  };

  const neighTeleViewConfig = React.useMemo(() => {
    switch (id?.toLowerCase()?.trim()) {
      case "cm":
        return filterNeighTeleViewConfigAtt();
      case "ecm":
        return neighbourhoodTeleViewConfigECM(isTechRole);
      case "stb":
        return neighbourhoodTeleViewConfigSTB;
      default:
        return neighbourhoodTeleViewConfig(isTechRole);
    }
  }, [techData]);

  React.useEffect(() => {
    if (mlData !== undefined && node_id.current === false) {
      dispatch(setNodeId(mlData[0]?.returnSegment));
      node_id.current = true;
    }
  }, [mlData]);

  React.useEffect(() => {
    dispatch(
      setModemType(id === "ecm" ? "ecmstb" : id === "stb" ? "oldstb" : id)
    );
    dispatch(setIsNeighbourhood(true));
    dispatch(saveConfigData(neighTeleViewConfig));
    const dataSetType = searchParams.get("dataSet");
    const viewTypeParam = searchParams.get("viewType");
    if (dataSetType && dataSetType !== "") {
      dispatch(setDiagType(dataSetType?.toLowerCase()?.trim()));
      setDataSetName(
        dataSetType?.toLowerCase()?.trim() === storeConst.all ||
          dataSetType?.toLowerCase()?.trim() === storeConst.all_no_cw
          ? dataSetNames[0]
          : dataSetNames[1]
      );
    } else {
      dispatch(setDiagType(storeConst.all_no_cw));
      setDataSetName(dataSetNames[0]);
    }
    if (viewTypeParam && viewTypeParam !== "") {
      dispatch(setViewType(viewTypeParam?.toUpperCase()?.trim()));
    }
    dispatch(setIsCWEnabled(false));
  }, [techData]);

  React.useEffect(() => {
    if (viewType !== undefined && viewType !== "") {
      ducksConfigData?.userPreferences?.node?.telemetry?.views?.forEach(
        (v: any) => {
          if (v?.viewName?.toLowerCase() === viewType?.toLowerCase()) {
            const filteredAttributes = v?.attributes.filter(
              (attr: any) =>  attr?.columnKey !== "ofdm_Channel_ID" && attr?.columnKey !== "ofdma_channel_ID"
            );
            setTableAttributes(filteredAttributes?.attributes);
            // setTableAttributes(v?.attributes);
          }
        }
      );
    }
  }, [viewType]);

  React.useEffect(() => {
    if (
      modemType !== undefined &&
      modemType !== "" &&
      id !== undefined &&
      id !== ""
    ) {
      dispatch(
        getTeleModemsList({
          value: cmmac ?? "",
          type: "cmmac",
          diagnoseDevices: true,
          modemType: id === "ecm" ? "ecmstb" : id === "stb" ? "oldstb" : id,
        })
      );
    }
  }, [id]);

  React.useEffect(() => {
    if (
      mlData?.length > 0 &&
      (nodeTeleStatus === ConfigConst.complete ||
        nodeTeleStatus === ConfigConst.stopped)
    ) {
      const modemListInfo = getTelemetryInfo(mlData);
      setBannerInfo({
        active: modemListInfo?.active,
        assigned: modemListInfo?.assigned,
        responsive: modemListInfo?.responsive,
        unresponsive: modemListInfo?.unresponsive,
        startTime: diagStartTime,
        endTime: diagEndTime,
        nodeId: nodeId,
      });
    } else if (allDiagIsLoading) {
      setBannerInfo({
        active: undefined,
        assigned: undefined,
        responsive: undefined,
        unresponsive: undefined,
        startTime: undefined,
        endTime: undefined,
        nodeId: nodeId,
      });
    }
  }, [mlData]);

  return (
    <div>
      <div className="emtaLandingPage">
        <div className="gponBannerContainer">
          <NeighbourhoodTopBanner
            data={bannerInfo}
            expand={expand}
            cmMacSearchValue={cmmac}
            modemType={modemType}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              alignItems: "center",
              marginLeft: "auto",
              marginRight: "2%",
            }}
          >
            <span
              style={{
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              {labels.DATA_SET?.toUpperCase()}
            </span>
            &nbsp;
            <CustomDropDown
              selectedOption={dataSetName}
              options={dataSetNames}
              searchFlag={false}
              checkboxFlag={false}
              setSelectedOption={(value: any) => {
                setDataSetName(value);
                //dispatch(SetTeleDatasetType(value));
                dispatch(
                  setDiagType(
                    value === ConfigConst.FULL_NO_CW.toUpperCase()
                      ? storeConst.all_no_cw
                      : storeConst.summary
                  )
                );
              }}
            />
            <div style={{ padding: "0px 20px" }}>
              {mlData !== undefined && mlData?.length !== 0 && (
                <>
                  {!allDiagIsLoading ? (
                    <button className="refreshCertiBtn" onClick={runTelemetry}>
                      {labels.START?.toUpperCase()} <FaRegPlayCircle />
                    </button>
                  ) : (
                    <>
                      <button
                        className="refreshCertiBtn"
                        onClick={() => stopTelemetry()}
                      >
                        {labels.STOP?.toUpperCase()} <FaRegStopCircle />
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="gponBannerIconsContainer">
            <div
              className="gponCollapseContainer"
              style={{
                rotate: expand ? "0deg" : "180deg",
              }}
            >
              <IoIosArrowDropup
                color="black"
                size={25}
                onClick={handleToggle}
              />
            </div>
          </div>
        </div>
        <div className="gponBodyContainer" style={{ padding: "4px" }}>
          <EmtaNodeTelemetryTab
            tableAttr={tableAttributes}
            startDiag={runTelemetry}
            dataSetType={neighbourhoodDataSet}
            dataSetNames={dataSetNames}
            exportDeviceId={cmmac}
            exportDeviceType={id}
            refreshNodeModemList={handleRefreshNodeModemList}
            handleFlapHistory={handleFlapHistory}
            selectedMac={cmmac ?? ""}
          />
        </div>
      </div>
    </div>
  );
};
