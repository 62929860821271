export const ATTACHMENT_BEGIN = "ATTACHMENT_BEGIN";
export const ATTACHMENT_SUCCESS = "ATTACHMENT_SUCCESS";
export const ATTACHMENT_FAILURE = "ATTACHMENT_FAILURE";

interface AttachmentBegin {
    type: typeof ATTACHMENT_BEGIN;
  }
  interface AttachmentSuccess {
    type: typeof ATTACHMENT_SUCCESS;
    payload: any;
  }
  interface AttachmentFailure {
    type: typeof ATTACHMENT_FAILURE;
    payload: Error;
  }

  export type AttachmentActionTypes =
  | AttachmentBegin
  | AttachmentSuccess
  | AttachmentFailure