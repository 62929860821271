import { RecoLegend } from "../../../models/account/troubleshoot/RecoLegend";

export const RECO_LEGEND_BEGIN = "RECO_LEGEND_BEGIN";
export const RECO_LEGEND_SUCCESS = "RECO_LEGEND_SUCCESS";
export const RECO_LEGEND_FAILURE = "RECO_LEGEND_FAILURE";

interface recoLegendBegin {
  type: typeof RECO_LEGEND_BEGIN;
}

interface recoLegendSuccess {
  type: typeof RECO_LEGEND_SUCCESS;
  payload: RecoLegend;
}

interface recoLegendFailure {
  type: typeof RECO_LEGEND_FAILURE;
  payload: Error;
}

export type RecoLegendActionTypes =
  | recoLegendBegin
  | recoLegendSuccess
  | recoLegendFailure;
