import {
  BAN_SEARCH_BEGIN,
  BAN_SEARCH_FAILURE,
  BAN_SEARCH_SUCCESS,
  BanActionTypes,
} from "../../../types/account/accountInfo/ProfileManagement";
import {
  LOGIN_FIX_BEGIN,
  LOGIN_FIX_FAILURE,
  LOGIN_FIX_SUCCESS,
  LoginFixActionTypes,
} from "../../../types/account/accountInfo/ProfileManagement";
import {
  LINK_BAN_BEGIN,
  LINK_BAN_FAILURE,
  LINK_BAN_SUCCESS,
  LinkBanActionTypes,
} from "../../../types/account/accountInfo/ProfileManagement";
import {
  UNLINK_BAN_BEGIN,
  UNLINK_BAN_FAILURE,
  UNLINK_BAN_SUCCESS,
  UnlinkBanActionTypes,
} from "../../../types/account/accountInfo/ProfileManagement";

export function banSearchBegin(): BanActionTypes {
  return {
    type: BAN_SEARCH_BEGIN,
  };
}

export function banSearchSuccess(data: any): BanActionTypes {
  return {
    type: BAN_SEARCH_SUCCESS,
    payload: data,
  };
}

export function banSearchFailure(error: Error): BanActionTypes {
  return {
    type: BAN_SEARCH_FAILURE,
    payload: error,
  };
}
export function loginFixBegin(): LoginFixActionTypes {
  return {
    type: LOGIN_FIX_BEGIN,
  };
}

export function loginFixSuccess(): LoginFixActionTypes {
  return {
    type: LOGIN_FIX_SUCCESS,
  };
}

export function loginFixFailure(error: Error): LoginFixActionTypes {
  return {
    type: LOGIN_FIX_FAILURE,
    payload: error,
  };
}

export function linkBanBegin(): LinkBanActionTypes {
  return {
    type: LINK_BAN_BEGIN,
  };
}

export function linkBanSuccess(): LinkBanActionTypes {
  return {
    type: LINK_BAN_SUCCESS,
  };
}

export function linkBanFailure(error: Error): LinkBanActionTypes {
  return {
    type: LINK_BAN_FAILURE,
    payload: error,
  };
}

export function unlinkBanBegin(): UnlinkBanActionTypes {
  return {
    type: UNLINK_BAN_BEGIN,
  };
}

export function unlinkBanSuccess(): UnlinkBanActionTypes {
  return {
    type: UNLINK_BAN_SUCCESS,
  };
}

export function unlinkBanFailure(error: Error): UnlinkBanActionTypes {
  return {
    type: UNLINK_BAN_FAILURE,
    payload: error,
  };
}
