import { Marker } from "@react-google-maps/api";
import { useMemo, useState } from "react";
import { BsDiamondFill } from "react-icons/bs";

import { mapElementStyles } from "../../../../config/mapItemsConfig";
import {
  GetConfigsProps,
  GetNikaOrderProps,
} from "../../../../util/reduxFunctions/getTopologyState";
import "../../../../styles/drawer/nika/Nika.scss";
import TopologyLabels from "../../../../constants/topologyLabels";
import appConst from "../../../../constants/appConst";
import { HoverOverInter } from "../../MapItems";
import {
  getTransparentSymbol,
  markerToString,
} from "../../../../util/utilFuncs";
import ActiveNikaClusterTable from "./ActiveNikaClusterTable";
import TopologySymbols from "../../../reusable/TopologySymbols";
import colors from "../../../../config/colors";

const NIKA_MANAGER_URL = (window as any).config?.NIKA_MANAGER_URL;

interface Props {
  setHoverOverProps: (arg0: HoverOverInter | null) => void;
}

export default function NikaMarkers({ setHoverOverProps }: Props) {
  const labels = TopologyLabels();
  const { data, nikaFilters, isNikaMarkersVisible } = GetNikaOrderProps();
  const { data: configData } = GetConfigsProps();

  const [showCluster, setShowCluster] = useState(false);
  const [activeClusterData, setActiveClusterData] = useState<any>();

  const getMarkerLabel = (type: string) => {
    switch (type) {
      case appConst.networkMaintenance:
        return labels.NM;
      case appConst.construction:
        return labels.CP;
      case appConst.buriedDrop:
        return labels.BD;
      default:
        return labels.EE;
    }
  };

  const onMarkerPress = (data: any) => {
    if (data?.id && data?.orderKey) {
      window.open(
        `${NIKA_MANAGER_URL}${configData?.nikaManagerKey}&OrderID=${data?.id}&OrderKey=${data?.orderKey}`,
        "_blank",
        "noopener,noreferrer"
      );
    }
  };

  const onClusterIconClick = (data: any) => {
    setActiveClusterData(data);
    setShowCluster(true);
  };

  const setHoverOverData = (element: any) => {
    const data = element?.properties?.data;
    let comp = <span>{data?.projectType}</span>;

    const compStructure = ({
      firstLabel,
      firstContent,
      secondLabel,
      secondContent,
    }: any) => (
      <>
        <div>
          <span style={{ fontWeight: 600 }}>{firstLabel}: </span>
          <span>{firstContent}</span>
        </div>
        {secondLabel && (
          <div>
            <span style={{ fontWeight: 600 }}>{secondLabel}: </span>
            <span>{secondContent}</span>
          </div>
        )}
      </>
    );

    switch (element?.properties?.type) {
      case appConst.networkMaintenance:
        comp = compStructure({
          firstLabel: labels.RepairType,
          firstContent: data?.repairType,
          // secondLabel: labels.Comment,
          // secondContent: data?.comment,
        });
        break;
      case appConst.construction:
        comp = compStructure({
          firstLabel: labels.ProjectType,
          firstContent: data?.projectType,
          secondLabel: "Description",
          secondContent: data?.description,
        });
        break;
      case appConst.buriedDrop:
        comp = compStructure({
          firstLabel: labels.ID,
          firstContent: data?.id,
          secondLabel: labels.Address,
          secondContent: `${data?.streetNumber} ${data?.streetName} ${data?.streetType}`,
        });
        break;
    }
    setHoverOverProps({
      position: {
        lat: element?.geometry?.coordinates?.[1],
        lng: element?.geometry?.coordinates?.[0],
      },
      compToRender: comp,
      options: { pixelOffset: new google.maps.Size(1, -12) },
    });
  };

  const nikaMarkersData = useMemo(() => {
    let groupedNikaList: any = [];
    if (data) {
      data?.map((allTypes: any) =>
        allTypes?.features?.map((f: any) => {
          if (
            nikaFilters?.includes(allTypes?.features?.[0]?.properties?.type)
          ) {
            const coords = f?.geometry?.coordinates;
            let groupIndex = groupedNikaList?.findIndex(
              (nikaGroup: any) =>
                nikaGroup?.lat === coords?.[1] && nikaGroup?.lng === coords?.[0]
            );
            if (groupIndex >= 0) {
              groupedNikaList[groupIndex].nikaTypeData.push(f);
            } else {
              groupedNikaList.push({
                lat: coords?.[1],
                lng: coords?.[0],
                nikaTypeData: [f],
              });
            }
          }
        })
      );
    }
    return groupedNikaList;
  }, [data, nikaFilters]);

  return (
    <>
      {isNikaMarkersVisible &&
        nikaMarkersData &&
        nikaMarkersData?.length > 0 &&
        nikaMarkersData?.map((nikaGroup: any) => {
          if (nikaGroup && nikaGroup?.nikaTypeData?.length === 1) {
            let typeData = nikaGroup?.nikaTypeData?.[0];
            return (
              <Marker
                key={typeData.properties?.featureId}
                onMouseOver={() => setHoverOverData(typeData)}
                onMouseOut={() => setHoverOverProps(null)}
                position={{
                  lat: typeData?.geometry?.coordinates?.[1],
                  lng: typeData?.geometry?.coordinates?.[0],
                }}
                label={{
                  text: `${getMarkerLabel(typeData?.properties?.type)}`,
                  className: "nikaMarkerLabel",
                  color: `var(--white)`,
                  fontSize: "11px",
                }}
                icon={{
                  url: markerToString(
                    <BsDiamondFill color={colors.nikaShadow} size={31} />
                  ),
                  anchor: new google.maps.Point(
                    mapElementStyles?.common?.anchor?.x,
                    mapElementStyles?.common?.anchor?.y
                  ),
                }}
                onClick={() => {
                  onMarkerPress(typeData.properties?.data);
                }}
              />
            );
          } else {
            return (
              <Marker
                key={`nikaClusterMarker${nikaGroup?.lat}${nikaGroup?.lng}`}
                position={{
                  lat: nikaGroup?.lat,
                  lng: nikaGroup?.lng,
                }}
                label={{
                  text: `${nikaGroup?.nikaTypeData?.length}`,
                  className: "nikaClusterMarkerLabel",
                  color: `var(--white)`,
                  fontSize: "11px",
                }}
                icon={{
                  url: markerToString(
                    <BsDiamondFill color={colors.nika70} size={30} />
                  ),
                  anchor: new google.maps.Point(
                    mapElementStyles?.common?.anchor?.x,
                    mapElementStyles?.common?.anchor?.y
                  ),
                }}
                onClick={() => onClusterIconClick(nikaGroup)}
              />
            );
          }
        })}

      <ActiveNikaClusterTable
        activeClusterData={activeClusterData}
        showCluster={showCluster}
        setShowCluster={setShowCluster}
        onMarkerPress={onMarkerPress}
      />
    </>
  );
}
