import DataDisplayTable from "../reusable/DataDisplayTable";
import CustomAccordion from "../reusable/CustomAccordion";
import AppLabels from "../../constants/App_Labels";
import ExceptionBox from "../reusable/ExceptionBox";
import { isNull } from "../reusable/Util";

const IPTVEthernetInfo = (props: any) => {
  const { ethernetData, ethernetError, refreshData } = props;
  const labels = AppLabels();
  return (
    <CustomAccordion
      accTitle={labels.ETHERNET.toUpperCase()}
      accBodyElement={
        <>
          {ethernetData !== undefined &&
          !ethernetData?.hasOwnProperty("errorCode") ? (
            <DataDisplayTable
              dataObject={ethernetData}
              tableName="iptvEthernet"
            />
          ) : (
            <div className="diagErrorCont">
              <ExceptionBox
                exBoxType="error"
                errSecName="IPTV Ethernet"
                errCode={isNull(ethernetError?.errorCode)}
                srcSystem={
                  ethernetError?.hasOwnProperty("sourceSystem")
                    ? ethernetError?.sourceSystem
                    : "Request"
                }
                ErrorMSG={isNull(ethernetError?.errorMessage)}
                primaryBtn={true}
                secBtn={true}
                handleRefresh={() => refreshData()}
              />
            </div>
          )}
        </>
      }
    />
  );
};

export default IPTVEthernetInfo;
