import { Card } from "react-bootstrap";
import { useState } from "react";
import CustomTab from "../reusable/CustomTab";
import { TabPanel } from "../../screens/account-page/AccountPageTabs";
import { isNull, getCheckValue, isEmptyObject } from "../reusable/Util";
import OFDMUpChannelData from "../cable-modem/upstream/OFDMUpChannelData";

const NeighCmUSCh = (props: any) => {
  const { cmDocsisInfo } = props;
  const [tabSelIndex, setTabSelIndex] = useState(0);
  let ofdmUpStreamChannels: any = [];
  if (
    cmDocsisInfo?.hasOwnProperty("ofdm") &&
    cmDocsisInfo.ofdm?.hasOwnProperty("upstream") &&
    cmDocsisInfo.ofdm?.upstream?.hasOwnProperty("channels")
  ) {
    cmDocsisInfo.ofdm?.upstream?.channels?.map((usC: any) => {
      ofdmUpStreamChannels.push(usC);
    });
  }
  return (
    <>
      {cmDocsisInfo !== undefined ? (
        <>
          <Card className="dsChTabsCard">
            <Card.Header>
              {cmDocsisInfo?.docsisVersion === "DOCSIS31" &&
              ofdmUpStreamChannels?.length !== 0 ? (
                ofdmUpStreamChannels?.map((ch: any, index: number) => {
                  return (
                    <CustomTab
                      tabType="horizontal"
                      index={index}
                      value={tabSelIndex}
                      handleSelect={(i: any) => setTabSelIndex(i)}
                      label={`OFDMA`}
                    />
                  );
                })
              ) : (
                <></>
              )}
            </Card.Header>
            <Card.Body>
              {cmDocsisInfo?.docsisVersion === "DOCSIS31" &&
              ofdmUpStreamChannels?.length !== 0 ? (
                ofdmUpStreamChannels?.map((ch: any, index: number) => {
                  return (
                    <TabPanel value={tabSelIndex} index={index}>
                      <OFDMUpChannelData
                        ofdmUpChannel={ch}
                        totalChannel={ofdmUpStreamChannels.length}
                      />
                    </TabPanel>
                  );
                })
              ) : (
                <></>
              )}
            </Card.Body>
          </Card>
        </>
      ) : (
        <>NO DATA AVAILABLE...</>
      )}
    </>
  );
};

export default NeighCmUSCh;
