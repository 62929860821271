import appConst from "../constants/appConst";
import languageLabels from "../constants/topologyLabels";
import { hasLimitedTopologyFeatureView } from "../../components/reusable/RoleConfig";

export default function NikaData() {
  const labels = languageLabels();

  return [
    {
      key: appConst.networkMaintenance,
      label: labels.NetMaint,
      checked: hasLimitedTopologyFeatureView,
    },
    {
      key: appConst.construction,
      label: labels.Construction,
      checked: hasLimitedTopologyFeatureView,
    },
    {
      key: appConst.buriedDrop,
      label: labels.BuriedDrops,
      checked: false,
    },
  ];
}
