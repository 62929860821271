import { ZapAllSTBState } from "../../../states/account/deviceActions/ZapAllSTBState";

import {
  ADD_ZAP_STB_REQUEST,
  RESET_ZAP_STB,
  UPDATE_ZAP_STB_REQUEST,
  ZapSTBsActionTypes,
} from "../../../types/account/deviceActions/ZapAllSTB";

const initialState: ZapAllSTBState = {
  stbDevices: [],
};

export function ZapAllSTBReducer(
  state = initialState,
  action: ZapSTBsActionTypes
): ZapAllSTBState {
  switch (action.type) {
    case ADD_ZAP_STB_REQUEST:
      if (state.stbDevices.length === 0) {
        state.stbDevices = [
          {
            deviceKey: action.devicekey,
            requestStatus: "",
          },
        ];
      } else {
        state.stbDevices.push({
          deviceKey: action.devicekey,
          requestStatus: "",
        });
      }
      return {
        ...state,
        stbDevices: state.stbDevices,
      };
    case UPDATE_ZAP_STB_REQUEST:
      return {
        ...state,
        stbDevices: state.stbDevices.map((d: any) => {
          if (d.deviceKey === action.devicekey) {
            return { ...d, ...{ requestStatus: action.requestStatus } };
          } else return d;
        }),
      };
    case RESET_ZAP_STB:
      return {
        ...state,
        stbDevices: [],
      };
    default:
      return state;
  }
}
