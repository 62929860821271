import { GrDocumentPdf, GrDocumentMissing, GrDocumentTxt, GrDocumentExcel, GrDocumentCsv, GrDocumentWord } from "react-icons/gr";
import { CSSProperties } from "react";
import { IconContext } from "react-icons";

export interface FileTypeIconInterface {
    color?: string;
    width?: string | number;
    height?: string | number;
    size?: string | number;
    opacity?: number;
    style?: CSSProperties;
    className?: string;
    name:
    | "doc"
    | "docx"
    | "pdf"
    | "csv"
    | "xls"
    | "xlsx"
    | "txt"
    | "jpg"
    | "jpeg"
    | "png"
    | string;
}

const FileTypeIcons = ({
    color,
    width,
    height,
    size,
    opacity,
    name,
    style,
    className,
}: FileTypeIconInterface,
    props: any) => {

    const iconColor = color ? color : `current`;
    const iconWidth = width
        ? width
        : style?.width
            ? style?.width
            : size
                ? size
                : 32;
    const iconHeight = height
        ? height
        : style?.height
            ? style?.height
            : size
                ? size
                : 32;
    const iconOpacity = opacity ? opacity : 0.6;

    if (name) {
        if (name === 'pdf') {
            return <IconContext.Provider
                value={{ color: color, size: `${size}px`, className: className }}
            >
                <div>
                    <GrDocumentPdf />
                </div>
            </IconContext.Provider>
        }
        else if (name === "doc") {
            return <IconContext.Provider
                value={{ color: color, size: `${size}px`, className: className }}
            >
                <div>
                    <GrDocumentWord />
                </div>
            </IconContext.Provider>
        }
        else if (name === "docx") {
            return <IconContext.Provider
                value={{ color: color, size: `${size}px`, className: className }}
            >
                <div>
                    <GrDocumentWord />
                </div>
            </IconContext.Provider>
        }
        // else if (icon === "png") {
        //     return <BsFiletypePng />
        // }
        // else if (icon === "jpeg" || icon === "jpg") {
        //     return <BsFiletypeJpg />
        // }
        else if (name === "csv") {
            return <IconContext.Provider
                value={{ color: color, size: `${size}px`, className: className }}
            >
                <div>
                    <GrDocumentCsv />
                </div>
            </IconContext.Provider>
        }
        else if (name === "xls") {
            return <IconContext.Provider
                value={{ color: color, size: `${size}px`, className: className }}
            >
                <div>
                    <GrDocumentExcel />
                </div>
            </IconContext.Provider>
        }
        else if (name === "xlsx") {
            return <IconContext.Provider
                value={{ color: color, size: `${size}px`, className: className }}
            >
                <div>
                    <GrDocumentExcel />
                </div>
            </IconContext.Provider>
        }
        else if (name === "txt") {
            return <IconContext.Provider
                value={{ color: color, size: `${size}px`, className: className }}
            >
                <div>
                    <GrDocumentTxt />
                </div>
            </IconContext.Provider>
        }
    }
    return <IconContext.Provider
        value={{ color: color, size: `${size}px`, className: className }}
    >
        <div>
            <GrDocumentMissing />
        </div>
    </IconContext.Provider>
}

export default FileTypeIcons;