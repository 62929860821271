import { Link } from "react-router-dom";
import CustomPopOver from "../reusable/CustomPopOver";

const SideTab = (props: any) => {
  const {
    drawerToggle,
    activePath,
    tabPath,
    tabIcon,
    tabName,
    tabQueryParams,
  } = props;
  return (
    <Link
      to={
        tabQueryParams !== undefined && tabQueryParams !== ""
          ? tabPath + tabQueryParams
          : tabPath
      }
    >
      {drawerToggle ? (
        <div
          className={
            activePath === tabPath
              ? "active-sideTabLinkCont"
              : "sideTabLinkCont"
          }
        >
          {tabIcon} <span>{tabName}</span>
        </div>
      ) : (
        <CustomPopOver
          position="right"
          popOverData={tabName}
          originComp={
            <div
              className={
                activePath === tabPath
                  ? "active-sideTabLinkCont"
                  : "sideTabLinkCont"
              }
            >
              {tabIcon}
            </div>
          }
          index="popOver"
        />
      )}
    </Link>
  );
};

export default SideTab;
