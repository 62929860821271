interface Props {
  textToRight?: string;
  textToLeft?: string;
}
export default function RedAsterisk({ textToRight, textToLeft }: Props) {
  return (
    <>
      {textToLeft && textToLeft}
      <span style={{ color: `var(--danger)` }}>*</span>
      {textToRight && textToRight}
    </>
  );
}
