import { useState } from "react";
import FeedbackForm from "./FeedbackForm";
import Tabs from "./Tabs";
import { Button, Modal } from "react-bootstrap";
import EditModal from "./edit/EditModal";
import CommentsModal from "./comments/CommentsModal";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import FeedbackClient from "../../network/httpclient/feedback/FeedbackClient";
import AppLabels from "../../constants/App_Labels";
import DiagContainer from "../reusable/DiagContainer";
import Feedback from "../../resources/images/icons/feedback.svg";
import { useLocation } from "react-router";
import CustomPopOver from "../reusable/CustomPopOver";
import { GetTechInfo } from "../../get-api/GetResponse";

const FeedbackContainer = (props:any) => {
  const [showModal, setShowModal] = useState(props.openModal || false);
  const labels = AppLabels();

  let location = useLocation();
  const { data, isLoading, error } = GetTechInfo();

  const [showEdit, setEdit] = useState(false);
  const [showComments, setComments] = useState(false);
  const [idComments, setIdComments] = useState("");
  const [issueToBeEditted, setIssueToBeEditted] = useState("");
  const dispatch = useDispatch();

  const closeModal = () => {
    setShowModal(false);
  };

  function handleFeedBackOpen(){
     window.open(
      `/#/#?isOpenModal=true`,
      "_blank"
    )!;
  }

  return (
    <div className={location.pathname === "/" ? "search" : ""}>
      <CustomPopOver
        position="bottom"
        popOverData="Feedback"
        originComp={
          <button className="headerIcon sBtn" >
           <img alt="Feedback" className="feedbackSrcImg" src={Feedback} onClick={()=>handleFeedBackOpen()}/>
          </button>
        }
        index="popOver"
      />
      <Modal
        id="feedback-container"
        onHide={closeModal}
        show={showModal}
        dialogClassName="modal-90w"
      >
        <div>
          <Modal.Header bsPrefix="fbHeader">
            {labels.SHARE_FEEDBACK}
            <Button bsPrefix="close" onClick={closeModal}>
              &#10005;
            </Button>
          </Modal.Header>
          <DiagContainer
            isLoading={isLoading}
            data={data}
            error={error}
            sectionName="Feedback"
            handleRefresh={() => {
              dispatch(FeedbackClient.getTechnicianFeedback(data.lanId));
              dispatch(FeedbackClient.getFeedbackProfile());
            }}
          >
            <FeedbackForm />
            <Tabs
              setIdComments={setIdComments}
              setIssueToBeEditted={setIssueToBeEditted}
              showForm={setShowModal}
              setEdit={setEdit}
              setComments={setComments}
            />
          </DiagContainer>
        </div>
      </Modal>

      <EditModal
        issueToBeEditted={issueToBeEditted}
        showEdit={showEdit}
        setEdit={setEdit}
        setModal={setShowModal}
      ></EditModal>

      <CommentsModal
        idComments={idComments}
        showComments={showComments}
        setComments={setComments}
        setModal={setShowModal}
      ></CommentsModal>
    </div>
  );
};

export default FeedbackContainer;
