import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { IHPInsideWiringState } from "../../../store/states/account/diagnostics/ihp/IHPInsideWiringState";
import {
  ihpInsideWiringBegin,
  ihpInsideWiringFailure,
  ihpInsideWiringSuccess,
} from "../../../store/actions/account/diagnostics/ihp/IHPInsideWiringAction";
import { IHPQrlnDataState } from "../../../store/states/account/diagnostics/ihp/IHPQrlnDataState";
import {
  ihpQrlnDataBegin,
  ihpQrlnDataFailure,
  ihpQrlnDataSuccess,
} from "../../../store/actions/account/diagnostics/ihp/IHPQrlnDataAction";
import { IHPQsipDataState } from "../../../store/states/account/diagnostics/ihp/IHPQsipDataState";
import {
  ihpQsipDataBegin,
  ihpQsipDataFailure,
  ihpQsipDataSuccess,
} from "../../../store/actions/account/diagnostics/ihp/IHPQsipDataAction";
import { IHPFeaturesState } from "../../../store/states/account/diagnostics/ihp/IHPFeaturesState";
import {
  ihpFeaturesBegin,
  ihpFeaturesSuccess,
  ihpFeaturesFailure,
} from "../../../store/actions/account/diagnostics/ihp/IHPFeaturesAction";
import { IHPFeatures } from "../../../store/models/account/diagnostics/ihp/IHPFeatures";
import { IHPSummary } from "../../../store/models/account/diagnostics/ihp/IHPSummary";
import { IHPDocsis } from "../../../store/models/account/diagnostics/ihp/IHPDocsis";
import { IHPInsideWiring } from "../../../store/models/account/diagnostics/ihp/IHPInsideWiring";
import { formatMacAddress } from "../../../components/reusable/Util";
import { IHPQrlnData } from "../../../store/models/account/diagnostics/ihp/IHPQrlnData";
import { IHPQsipData } from "../../../store/models/account/diagnostics/ihp/IHPQsipData";
import {
  DecrementApiCallCounter,
  IncrementApiCallCounter,
  UpdateActiveCertiDevices,
} from "../../../store/actions/account/certifications/ActiveCertiDevicesAction";
import {
  DecrementDiagApiCallCounter,
  IncrementDiagApiCallCounter,
  UpdateActiveDiagDevices,
} from "../../../store/actions/account/diagnostics/ActiveDiagDevicesActions";
import {
  rhpDocsisEventInfoBegin,
  rhpDocsisEventInfoSuccess,
  rhpDocsisEventInfoFailure,
} from "../../../store/actions/account/diagnostics/ihp/RHPDocsisEventsAction";
import { RHPDocsisEventsState } from "../../../store/states/account/diagnostics/ihp/RHPDocsisEventsState";
import { RHPInterface } from "../../../store/models/account/diagnostics/ihp/RHPInterface";

export default class IHPClient {
  static getIHPSummary =
    (
      dCategory: string,
      serialNum: string,
      cmMac: string,
      emtaMac: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let IHPUrl = "";
      let cmMAC = formatMacAddress(cmMac);
      let emtaMAC = formatMacAddress(emtaMac);
      if (emtaMac === "") IHPUrl = "diag/emta/summary/" + cmMAC;
      else IHPUrl = "diag/emta/summary/" + cmMAC + "/" + emtaMAC;

      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + IHPUrl,
      };

      dispatch(
        IncrementDiagApiCallCounter(
          dCategory !== "IEMTA" ? serialNum : dCategory + serialNum
        )
      );

      HttpClient.get<IHPSummary>(httpParameters)
        .then((ihpSummary) => {
          dispatch(
            UpdateActiveDiagDevices(
              dCategory !== "IEMTA" ? serialNum : dCategory + serialNum,
              "summary",
              ihpSummary
            )
          );
          dispatch(
            DecrementDiagApiCallCounter(
              dCategory !== "IEMTA" ? serialNum : dCategory + serialNum
            )
          );
          return ihpSummary;
        })
        .catch((error) => {
          dispatch(
            UpdateActiveDiagDevices(
              dCategory !== "IEMTA" ? serialNum : dCategory + serialNum,
              "summary",
              error
            )
          );
          dispatch(
            DecrementDiagApiCallCounter(
              dCategory !== "IEMTA" ? serialNum : dCategory + serialNum
            )
          );
          return error;
        });
    };

  static getIHPDocsis =
    (
      serialNum: string,
      cmMac: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let cmMAC = formatMacAddress(cmMac);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL + "diag/emta/" + cmMAC + "/docsis",
      };

      dispatch(IncrementDiagApiCallCounter(serialNum));

      HttpClient.get<IHPDocsis>(httpParameters)
        .then((ihpDocsis) => {
          dispatch(UpdateActiveDiagDevices(serialNum, "docsis", ihpDocsis));
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return ihpDocsis;
        })
        .catch((error) => {
          dispatch(UpdateActiveDiagDevices(serialNum, "docsis", error));
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return error;
        });
    };

  static getRHPDocsisEvents =
    (
      macAddress: string
    ): ThunkAction<void, RHPDocsisEventsState, unknown, Action<string>> =>
    async (dispatch) => {
      let mac = formatMacAddress(macAddress);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "diag/cablemodem/" +
          mac +
          "/docsisevents",
      };

      dispatch(rhpDocsisEventInfoBegin());
      HttpClient.get<any>(httpParameters)
        .then((cmDocsisEvents) => {
          dispatch(rhpDocsisEventInfoSuccess(cmDocsisEvents));
          return cmDocsisEvents;
        })
        .catch((error) => {
          dispatch(rhpDocsisEventInfoFailure(error));
          return error;
        });
    };

  static getIHPInsideWiring =
    (
      cmMac: string,
      emtaMac: string,
      portNum: string
    ): ThunkAction<void, IHPInsideWiringState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "diag/emta/insidewiring/" +
          cmMac +
          "/" +
          emtaMac +
          "/" +
          portNum,
      };
      dispatch(ihpInsideWiringBegin());

      HttpClient.get<IHPInsideWiring>(httpParameters)
        .then((ihpInsideWiring) => {
          dispatch(ihpInsideWiringSuccess(ihpInsideWiring));
          return ihpInsideWiring;
        })
        .catch((error) => {
          dispatch(ihpInsideWiringFailure(error));
          return error;
        });
    };
  static getIHPQrlnData =
    (
      tnNum: string
    ): ThunkAction<void, IHPQrlnDataState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/emta/qlrn/" + tnNum,
      };
      dispatch(ihpQrlnDataBegin());

      HttpClient.get<IHPQrlnData>(httpParameters)
        .then((ihpQrlnData) => {
          dispatch(ihpQrlnDataSuccess(ihpQrlnData));
          return ihpQrlnData;
        })
        .catch((error) => {
          dispatch(ihpQrlnDataFailure(error));
          return error;
        });
    };
  static getIHPQsipData =
    (
      tnNum: string
    ): ThunkAction<void, IHPQsipDataState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "diag/emta/qsip/" +
          tnNum +
          "?parsed=true",
      };
      dispatch(ihpQsipDataBegin());

      HttpClient.get<IHPQsipData>(httpParameters)
        .then((ihpQsipData) => {
          dispatch(ihpQsipDataSuccess(ihpQsipData));
          return ihpQsipData;
        })
        .catch((error) => {
          dispatch(ihpQsipDataFailure(error));
          return error;
        });
    };

  static getIHPCertiSummary =
    (
      dCategory: string,
      serialNum: string,
      cmMac: string,
      emtaMac: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let IHPUrl = "";
      let cmMAC = formatMacAddress(cmMac);
      let emtaMAC = formatMacAddress(emtaMac);
      if (dCategory !== "IEMTA") IHPUrl = "diag/emta/summary/" + cmMAC;
      else IHPUrl = "diag/emta/summary/" + cmMAC + "/" + emtaMAC;

      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + IHPUrl,
      };

      dispatch(
        IncrementApiCallCounter(
          dCategory !== "IEMTA" ? serialNum : dCategory + serialNum
        )
      );

      HttpClient.get<IHPSummary>(httpParameters)
        .then((ihpSummary) => {
          dispatch(
            UpdateActiveCertiDevices(
              dCategory !== "IEMTA" ? serialNum : dCategory + serialNum,
              "summary",
              ihpSummary
            )
          );
          dispatch(
            DecrementApiCallCounter(
              dCategory !== "IEMTA" ? serialNum : dCategory + serialNum
            )
          );
          return ihpSummary;
        })
        .catch((error) => {
          dispatch(
            UpdateActiveCertiDevices(
              dCategory !== "IEMTA" ? serialNum : dCategory + serialNum,
              "summary",
              error
            )
          );
          dispatch(
            DecrementApiCallCounter(
              dCategory !== "IEMTA" ? serialNum : dCategory + serialNum
            )
          );
          return error;
        });
    };

  static getIHPCertiDocsis =
    (
      serialNum: string,
      cmMac: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let cmMAC = formatMacAddress(cmMac);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL + "diag/emta/" + cmMAC + "/docsis",
      };

      dispatch(IncrementApiCallCounter(serialNum));

      HttpClient.get<IHPDocsis>(httpParameters)
        .then((ihpDocsis) => {
          dispatch(UpdateActiveCertiDevices(serialNum, "docsis", ihpDocsis));
          dispatch(DecrementApiCallCounter(serialNum));
          return ihpDocsis;
        })
        .catch((error) => {
          dispatch(UpdateActiveCertiDevices(serialNum, "docsis", error));
          dispatch(DecrementApiCallCounter(serialNum));
          return error;
        });
    };

  static getIHPFeatures =
    (
      tn: string
    ): ThunkAction<void, IHPFeaturesState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/emta/features/" + tn,
      };
      dispatch(ihpFeaturesBegin());

      HttpClient.get<IHPFeatures>(httpParameters)
        .then((ihpFeatures) => {
          dispatch(ihpFeaturesSuccess(ihpFeatures));
          return ihpFeatures;
        })
        .catch((error) => {
          dispatch(ihpFeaturesFailure(error));
          return error;
        });
    };

  static getRHPInterface =
    (
      serialNum: string,
      cmMac: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let cmMAC = formatMacAddress(cmMac);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "diag/cablemodem/" +
          cmMAC +
          "/interface",
      };

      dispatch(IncrementDiagApiCallCounter(serialNum));

      HttpClient.get<RHPInterface>(httpParameters)
        .then((rhpInterface) => {
          dispatch(
            UpdateActiveDiagDevices(serialNum, "interfaceSummary", rhpInterface)
          );
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return rhpInterface;
        })
        .catch((error) => {
          dispatch(
            UpdateActiveDiagDevices(serialNum, "interfaceSummary", error)
          );
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return error;
        });
    };

  static getCMSummary =
    (
      serialNum: string,
      macAddress: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let mac = formatMacAddress(macAddress);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "diag/cablemodem/" +
          mac +
          "/summary",
      };

      dispatch(IncrementDiagApiCallCounter(serialNum));
      HttpClient.get<any>(httpParameters)
        .then((cmSummary) => {
          dispatch(UpdateActiveDiagDevices(serialNum, "cmSummary", cmSummary));
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return cmSummary;
        })
        .catch((error) => {
          dispatch(UpdateActiveDiagDevices(serialNum, "cmSummary", error));
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return error;
        });
    };

  static getPCEventLog =
    (
      serialNum: string,
      macAddress: string,
      emtaMac: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let cmmac = formatMacAddress(macAddress);
      let emtamac = formatMacAddress(emtaMac);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/cablemodem/pceventlog",
        params: {
          cmmac: cmmac,
          emtamac: emtamac,
        },
      };
      dispatch(IncrementDiagApiCallCounter(serialNum));

      HttpClient.get<any>(httpParameters)
        .then((pcEventLog) => {
          dispatch(
            UpdateActiveDiagDevices(serialNum, "pceventlog", pcEventLog)
          );
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return pcEventLog;
        })
        .catch((error) => {
          dispatch(UpdateActiveDiagDevices(serialNum, "pceventlog", error));
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return error;
        });
    };
}
