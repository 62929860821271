import axios, { Method, ResponseType } from "axios";

import { TOPOLOGY_SERVICE_URL } from "./apiEndpoints";
import * as actions from "./apiActions";
import { HttpClient } from "../../../network/httpclient/HttpClient";
import storeConst from "../storeConst";

let apiAbortControllerObj = { default: new AbortController() } as any;

export interface AI {
  type: any;
  payload: {
    baseURL?: string;
    url: string;
    method?: Method;
    data?: any;
    params?: any;
    onStart: any;
    onSuccess: any;
    onError: any;
    onStartDispatch?: any;
    onSuccessDispatch?: any;
    onErrorDispatch?: any;
    props?: object;
    responseType?: ResponseType; // for streaming API
    abortKey?: any; // abort key to cancel request if needed
  };
}

interface RetryRequestProps {
  payload?: any;
  signal?: any;
  dispatch?: any;
  maxRetries?: number;
  retries?: number;
}

//add or replace abort key in the abort object
export const addAbortKey = (keyToAdd: string) => {
  if (keyToAdd) {
    if (apiAbortControllerObj?.[keyToAdd]) {
      apiAbortControllerObj[keyToAdd] = new AbortController();
    } else
      apiAbortControllerObj = {
        ...apiAbortControllerObj,
        [keyToAdd]: new AbortController(),
      };
  }
};
//abort api call for given key
export const abortRequest = (key: string) => {
  if (apiAbortControllerObj?.[key]) apiAbortControllerObj?.[key]?.abort();
};

const apiClient = (store: any) => (next: any) => async (action: AI) => {
  if (action) {
    //if action is not an api call then return it to the next(reducer) middleware
    if (action?.type !== actions.apiCallBegan.type) return next(action);

    const { dispatch } = store;
    const { onStart, onStartDispatch, props, abortKey } = action.payload;

    const signal = apiAbortControllerObj?.[abortKey]
      ? apiAbortControllerObj[abortKey].signal
      : apiAbortControllerObj.default.signal;
    dispatch(
      props ? { type: onStart, payload: { ...props } } : { type: onStart }
    );
    if (onStartDispatch) dispatch({ type: onStartDispatch });

    retryRequest({ payload: action.payload, dispatch, signal });
  }
};

const retryRequest = async ({
  dispatch,
  payload,
  signal,
  maxRetries = 3,
  retries = 0,
}: RetryRequestProps) => {
  const {
    url,
    method,
    data,
    params,
    onSuccess,
    onError,
    baseURL,
    onSuccessDispatch,
    onErrorDispatch,
    props,
    responseType,
  } = payload;
  HttpClient.acquireAccessToken(HttpClient.msalInstance).then(async (token) => {
    const headers: any = {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };
    try {
      const response = await axios.request({
        baseURL: baseURL ? baseURL : TOPOLOGY_SERVICE_URL,
        url,
        method,
        data,
        headers,
        params,
        responseType,
        signal,
        onDownloadProgress: (progressEvent) => {
          //dataChunk contains the data that have been obtained so far (the whole data so far)..
          const dataChunk = progressEvent.currentTarget.response;
          if (responseType === "stream") {
            dispatch({
              type: onSuccess,
              payload: props ? { ...props, data: dataChunk } : dataChunk,
            });
          }
        },
      });
      if (responseType !== "stream") {
        dispatch({
          type: onSuccess,
          payload: props ? { ...props, data: response.data } : response.data,
        });
        if (onSuccessDispatch) dispatch({ type: onSuccessDispatch });
      }
    } catch (error: any) {
      let errorObj = error;

      if (axios.isCancel(error)) {
        errorObj = {
          errorMessage: storeConst.requestCanceled + ": " + error?.message,
        };
      } else if (error?.config) {
        //if error is from axios then only use error.massage to avoid non-serializable values
        errorObj = error?.response?.data;
      }

      //if token fails, initialize authentication
      if (error?.response?.status === 401 && maxRetries !== retries + 1) {
        HttpClient.isTokenExpired = true;
        if (retries > 1) {
          sessionStorage.clear();
        }
        retries++;
        retryRequest({ payload, dispatch, signal, retries });
      } else {
        dispatch({
          type: onError,
          payload: props ? { ...props, data: errorObj } : errorObj,
        });
        if (onErrorDispatch) dispatch({ type: onErrorDispatch });
      }
    }
  });
};

export default apiClient;
