import { Box } from "@material-ui/core";
import { format } from "date-fns";
import { useLayoutEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import { BsCheckCircle } from "react-icons/bs";
import { FaCheckCircle, FaCircle } from "react-icons/fa";
import { TESTTYPE_CONFIG } from "../../constants/CertificationsConfig";
import {FlapVisibility} from "../../components/reusable/RoleConfig";
import {
  DOCSIS_DEVICES,
  FWA_DEVICES,
  GPON_DEVICES,
} from "../../constants/CustomerDeviceTypes";
import SearchConfig from "../../../src/config/SearchConfig";
import AppLabels from "../../constants/App_Labels";
import ConfigConst from "../../constants/ConfigConst";
import { GLOBAL_CONST } from "../../constants/Global";
import { GetActiveAccountDetails,GetTechInfo } from "../../get-api/GetResponse";
import { NetworkConfig } from "../../network/httpclient/NetworkConfig";
import ErrorIcon from "../../resources/images/icons/ErrorIcon.svg";
import LoaderGif from "../../resources/images/loader.webp";
import { Device } from "../../store/models/account/accountInfo/Device";
import colors from "../../topology/config/colors";
import appConst from "../../topology/constants/appConst";
import CustomPopOver from "./CustomPopOver";
import { processAccessRoleConfig, RoleConfigType } from "./RoleConfig";
import React from "react";
import { FiAlertTriangle } from "react-icons/fi";

export function formatBitsToSize(bits: number, bitSize = "bits", decimals = 8) {
  if (bits === 0) return "0 bits";
  const sizes = [];
  const removeFromArray = (numOfElementsToRemove: number) => {
    var items = [
      "bits",
      "Kbps",
      "Mbps",
      "Gbps",
      "Tbps",
      "Pbps",
      "Ebps",
      "Zbps",
      "Ybps",
    ];
    for (let i = 0; i < numOfElementsToRemove; i++) {
      items.shift();
    }
    return items;
  };

  switch (bitSize?.toUpperCase()) {
    case "BITS":
      sizes.push(...removeFromArray(0));
      break;
    case "KB":
      sizes.push(...removeFromArray(1));
      break;
    case "MB":
      sizes.push(...removeFromArray(2));
      break;
    case "GB":
      sizes.push(...removeFromArray(3));
      break;
    case "TB":
      sizes.push(...removeFromArray(4));
      break;
    case "PB":
      sizes.push(...removeFromArray(5));
      break;
    case "EB":
      sizes.push(...removeFromArray(6));
      break;
    case "ZB":
      sizes.push(...removeFromArray(7));
      break;
    case "YB":
      sizes.push(...removeFromArray(9));
      break;
    default:
      sizes.push(...removeFromArray(0));
  }

  //Change k = 1024 or sizes = ["..."] as you want (bits or bytes)
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const i = Math.floor(Math.log(bits) / Math.log(k));

  return parseFloat((bits / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function formatBytesToSize(
  bytes: number,
  byteSize = "bytes",
  decimals = 1
) {
  if (bytes === 0) return "0 bytes";
  const sizes = [];
  const removeFromArray = (numOfElementsToRemove: number) => {
    var items = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    for (let i = 0; i < numOfElementsToRemove; i++) {
      items.shift();
    }
    return items;
  };

  switch (byteSize?.toUpperCase()) {
    case "BYTES":
      sizes.push(...removeFromArray(0));
      break;
    case "KB":
      sizes.push(...removeFromArray(1));
      break;
    case "MB":
      sizes.push(...removeFromArray(2));
      break;
    case "GB":
      sizes.push(...removeFromArray(3));
      break;
    case "TB":
      sizes.push(...removeFromArray(4));
      break;
    case "PB":
      sizes.push(...removeFromArray(5));
      break;
    case "EB":
      sizes.push(...removeFromArray(6));
      break;
    case "ZB":
      sizes.push(...removeFromArray(7));
      break;
    case "YB":
      sizes.push(...removeFromArray(9));
      break;
    default:
      sizes.push(...removeFromArray(0));
  }

  //Change k = 1000 or 1024 and sizes = ["..."] as you want (bits or bytes)
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export const getBase64Size = (base64Img: string) => {
  var stringLength = base64Img.length - "data:image/png;base64,".length;
  var sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
  return formatBytesToSize(sizeInBytes, "bytes", 0);
};

export const secToTime = (duration: any) => {
  if (duration !== undefined) {
    let seconds = Number(duration);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    //var s = Math.floor(seconds % 60);

    // var dDisplay = d > 0 ? d + (d == 1 ? " Day, " : " Days, ") : "0 Days, ";
    var dDisplay = d > 0 ? d + "d:" : "0d:";
    var hDisplay = h > 9 ? h : "0" + h;
    var mDisplay = m > 9 ? m : "0" + m;
    //var sDisplay = s > 9 ? s : "0" + s;
    if (d === 0 && h === 0) {
      return mDisplay + "m"; // + ":" + sDisplay
    } else if (d === 0 && h !== 0) {
      return hDisplay + "h:" + mDisplay + "m"; // + ":" + sDisplay
    } else {
      return dDisplay + hDisplay + "h:" + mDisplay + "m"; // + ":" + sDisplay
    }
    //return dDisplay + hDisplay + "h:" + mDisplay + "m"; // + ":" + sDisplay
  }
};
export const secToTime2 = (duration: any) => {
  if (duration !== undefined) {
    let seconds = Math.floor(Number(duration) / 10);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d + "d:" : "0d:";
    var hDisplay = h > 9 ? h : "0" + h;
    var mDisplay = m > 9 ? m : "0" + m;

    if (d === 0 && h === 0) {
      return mDisplay + "m";
    } else if (d === 0 && h !== 0) {
      return hDisplay + "h:" + mDisplay + "m";
    } else {
      return dDisplay + hDisplay + "h:" + mDisplay + "m";
    }
  }
};
export const hzToMHz = (hz: number) => {
  var MHz = hz / 1000000;
  return MHz;
};

export const isEmptyObject = (checkProperty: any) => {
  if (Object.entries(checkProperty)?.length === 0) return null;
  else return checkProperty;
};
export const isObject = (checkProperty: any) => {
  if (typeof checkProperty === "object") return true;
  else return false;
};
export const isNull = (data: any): string => {
  if (data === "null" || data === null || data === "" || data === " ")
    return "";
  else if (data === undefined) return "N/A";
  else return data;
};
export const isBoolValue = (data: any): string => {
  if (data === true || data === false) return data.toString();
  else return data;
};
export const getAttributeValue = (checkdata: any, attribute: any) => {
  if (checkdata !== undefined) {
    return checkdata[attribute];
  } else {
    return undefined;
  }
};
export const checkWiFiFailCondition = (wifiCertiData: any) => {
  return (
    wifiCertiData?.status?.includes("Fail") &&
    wifiCertiData?.summary?.failed > 0
  );
};
export const checkHSIFailCondition = (hsiCertiData: any) => {
  return (
    hsiCertiData?.status?.includes("Fail") &&
    hsiCertiData?.summary?.failed?.count > 0
  );
};

export const ccAddress = (companyId: string) => {
  const saCompanies = ["INTEK", "LINKON", "WIRECOMM", "DHT", "CABLE 72"];
  const hcmCompanies = [
    "CABLEWORKS",
    "FRONTIER",
    "PROTECH",
    "ROBITAILLE TELECOM",
  ];
  if (saCompanies.includes(companyId?.toUpperCase())) {
    return "sa.contractorsupport@rci.rogers.com";
  } else if (hcmCompanies.includes(companyId?.toUpperCase())) {
    return "randy.clarke@rci.rogers.com";
  } else {
    return "";
  }
};
export const getOUIW3IDChecked = (
  serialNum: string,
  hhID: any,
  mac: any
): any => {
  let qParam = [];
  if (hhID !== undefined || hhID !== "" || hhID !== null) {
    qParam.push({
      serialNumber: serialNum,
      hhid: hhID,
      macAddress: mac,
    });
  } else {
    if (mac !== undefined || mac !== "" || mac !== null) {
      qParam.push({ serialNumber: serialNum, macAddress: mac });
    } else {
      qParam.push({ serialNumber: serialNum });
    }
  }
  return qParam?.[0];
};

export const getOUIChecked = (
  oui: string,
  serialNum: string,
  hhID?: any,
  mac?: any
): any => {
  let qParam = [];
  if (hhID !== undefined) {
    if (oui === undefined || oui === "" || oui === null) {
      qParam.push({
        serialNumber: serialNum,
        hhid: hhID,
        macAddress: mac,
      });
    } else {
      qParam.push({
        oui: oui,
        serialNumber: serialNum,
        hhid: hhID,
        macAddress: mac,
      });
    }
  } else {
    if (oui === undefined || oui === "" || oui === null) {
      if (mac !== undefined) {
        qParam.push({ serialNumber: serialNum, macAddress: mac });
      } else {
        qParam.push({ serialNumber: serialNum });
      }
    } else {
      qParam.push({
        oui: oui,
        serialNumber: serialNum,
      });
    }
  }
  return qParam?.[0];
};
export function getCheckValue(
  checkData: any,
  colorFlag?: any,
  inlineStyle?: boolean
) {
  if (checkData !== null && checkData?.hasOwnProperty("value")) {
    if (
      checkData?.hasOwnProperty("status") &&
      checkData?.status?.toLowerCase() === "fail"
    ) {
      return (
        <span
          className="failState"
          style={
            inlineStyle
              ? {
                  color: "#dc3545 !important",
                  display: "block",
                }
              : {}
          }
        >
          {" "}
          {checkData.value}
        </span>
      );
    } else if (
      checkData?.hasOwnProperty("status") &&
      checkData?.status?.toLowerCase() === "marginal pass"
    )
      return (
        <span
          className="marginalPassState"
          style={
            inlineStyle
              ? {
                  color: "#ffbf3f !important",
                  display: "block",
                }
              : {}
          }
        >
          {checkData.value}
        </span>
      );
    else {
      if (colorFlag) {
        return (
          <span
            className="passState"
            style={
              inlineStyle
                ? {
                    color: "#00985f !important",
                    display: "block",
                  }
                : {}
            }
          >
            {checkData.value}
          </span>
        );
      } else {
        return <span>{checkData.value}</span>;
      }
    }
  } else {
    return "";
  }
}
export const formatModProfiles = (ofdmChannel: any) => {
  if (
    ofdmChannel === undefined ||
    ofdmChannel === null ||
    ofdmChannel?.profiles === undefined
  ) {
    return "N/A";
  }

  const formattedProfiles = ofdmChannel?.hasOwnProperty("profiles")
    ? ofdmChannel?.profiles?.map((profile: any) => {
        const formattedProfile = profile?.active ? (
          <span style={{ fontWeight: "bold" }}>
            *[{profile?.profileId}] {profile?.modulation}*
          </span>
        ) : (
          `[${profile?.profileId}] ${profile?.modulation}`
        );
        return formattedProfile;
      })
    : [];

  return (
    <span>
      {formattedProfiles.map((profile: any, index: number) => (
        <React.Fragment key={index}>
          {profile}
          {index !== formattedProfiles.length - 1 && ", "}
        </React.Fragment>
      ))}
    </span>
  );
};
export const getDelta = (value: any, precheckValue: any) => {
  let numericValue = null;
  let numericPrecheckValue = null;
  // Check for undefined input values
  if (value === undefined || precheckValue === undefined) {
    return "N/A";
  }

  if (typeof value === "object" && value?.hasOwnProperty("value")) {
    numericValue =
      typeof value?.value === "string" ? parseFloat(value?.value) : value;
    numericPrecheckValue =
      typeof precheckValue?.value === "string"
        ? parseFloat(precheckValue?.value)
        : precheckValue;
  } else {
    // Convert input values to numbers if they are strings or can be converted to numbers
    numericValue = typeof value === "string" ? parseFloat(value) : value;
    numericPrecheckValue =
      typeof precheckValue === "string"
        ? parseFloat(precheckValue)
        : precheckValue;
  }
  // Check if either of the values is not a valid number
  if (isNaN(numericValue) || isNaN(numericPrecheckValue)) {
    return "N/A";
  }

  // Perform the subtraction
  let delta =
    (numericValue - numericPrecheckValue) % 1 !== 0
      ? (numericValue - numericPrecheckValue).toFixed(1)
      : numericValue - numericPrecheckValue;

  return delta;
};
export const getThreshold = (checkProperty: any): JSX.Element => {
  if (checkProperty !== null && checkProperty?.hasOwnProperty("threshold")) {
    return (
      <td>
        <span>Pass: </span>
        {checkProperty.threshold.pass ? checkProperty.threshold.pass : ""}
        <br />
        {checkProperty.threshold.marginalPass ? (
          <>
            <span>Marginal Pass: </span>
            {checkProperty.threshold.marginalPass}
          </>
        ) : (
          <></>
        )}
      </td>
    );
  } else {
    return <td></td>;
  }
};

export const getStatusOfCheck = (checkProperty: any): JSX.Element => {
  if (checkProperty !== null && checkProperty?.hasOwnProperty("status")) {
    if (checkProperty?.status?.toLowerCase() === "fail")
      return (
        <td>
          <span className="failState">{checkProperty.status}</span>
        </td>
      );
    else if (checkProperty?.status?.toLowerCase() === "pass")
      return (
        <td>
          <span className="passState">{checkProperty.status}</span>
        </td>
      );
    else if (checkProperty?.status?.toLowerCase() === "marginal pass")
      return (
        <td>
          <span className="marginalPassState">{checkProperty.status}</span>
        </td>
      );
    else if (checkProperty?.status?.toLowerCase() === "skipped")
      return (
        <td>
          <span className="skipState">{checkProperty.status}</span>
        </td>
      );
    else return <td></td>;
  } else {
    return <td></td>;
  }
};

export const popOverCell = (data: any) => {
  return (
    <CustomPopOver
      popOverData={isObject(data) ? isNull(getThreshold(data)) : ""}
      originComp={isObject(data) ? isNull(getCheckValue(data, true)) : ""}
      index="popOver"
    />
  );
};

export const getMultiStatusOfCheck = (
  dataList: any,
  propName: string
): JSX.Element => {
  return (
    <td>
      {dataList?.map((data: any) => {
        if (
          data !== null &&
          data?.hasOwnProperty(propName) &&
          data[propName]?.hasOwnProperty("status")
        ) {
          if (data?.[propName]?.status?.toLowerCase() === "fail")
            return <span className="failState">{data[propName]?.status}</span>;
          else if (data?.[propName]?.status?.toLowerCase() === "pass")
            return <span className="passState">{data[propName]?.status}</span>;
          else return <td></td>;
        }
      })}
    </td>
  );
};
export const getStatusOfCheckHeader = (checkProperty: any, colName: any) => {
  if (checkProperty && checkProperty !== null && checkProperty !== undefined) {
    if (checkProperty?.status?.toLowerCase() === "fail") {
      return <span className="failState">{colName}</span>;
    } else if (checkProperty?.status?.toLowerCase() === "pass") {
      return <span>{colName}</span>;
    } else if (checkProperty?.status?.toLowerCase() === "marginal pass") {
      return <span className="">{colName}</span>;
    } else {
      return <span>{colName}</span>;
    }
  } else {
    return <span>{colName}</span>;
  }
};
export const renderPodsStatus = (podStatus: string) => {
  if (podStatus !== "N/A" && podStatus !== "") {
    let rssi_val = parseInt(podStatus);
    if (rssi_val <= -55 && rssi_val >= -70)
      return (
        <span style={{ color: "#28A745" }}>
          <FaCircle />
          <span style={{ fontWeight: 700 }}>Pass</span>
        </span>
      );
    else
      return (
        <span style={{ color: "#BF371F" }}>
          <FaCircle />
          <span style={{ fontWeight: 700 }}>Fail</span>
        </span>
      );
  }
};
export const checkRSSIValue = (checkProperty: any): JSX.Element => {
  if (checkProperty !== "N/A" && checkProperty !== "" && checkProperty > -55) {
    return (
      <td>
        <span>Pod is too close to the gateway </span>
      </td>
    );
  } else if (
    checkProperty !== "N/A" &&
    checkProperty !== "" &&
    checkProperty <= -55 &&
    checkProperty >= -70
  ) {
    return (
      <td>
        <span>Pod is optimally placed </span>
      </td>
    );
  } else if (
    checkProperty !== "N/A" &&
    checkProperty !== "" &&
    checkProperty < -70
  ) {
    return (
      <td>
        <span>Pod is too far from gateway </span>
      </td>
    );
  } else {
    return <td></td>;
  }
};
export function getRSSIValue(checkData: any) {
  let rssi_val = parseInt(checkData);
  if (rssi_val <= -55 && rssi_val >= -70)
    return <span style={{ color: "#00985f" }}>{checkData}</span>;
  else return <span style={{ color: "#dc3545" }}>{checkData}</span>;
}

export const getStatusColor = (status: string) => {
  switch (status?.toLowerCase()) {
    case "pass":
      return colors.success;
    case "fail":
    case "failed":
      return colors.danger;
    case "marginal pass":
      return colors.warning;
    case "skip":
    case "skipped":
      return colors.skip;
    default:
      return colors.skip; // Default to "PASS" color
  }
};

export const renderStatus = (color: string, text: any) => (
  <span className="deviceStatusTxtCont" style={{ color }}>
    <FaCircle />
    <span style={{ fontWeight: 700 }}>{text?.toUpperCase()}</span>
  </span>
);

export const renderDeviceStatus = (checkData: any, apiCallStatus: string) => {
  if (
    checkData &&
    checkData.hasOwnProperty("overallStatus") &&
    apiCallStatus === "OK"
  ) {
    const status = checkData?.overallStatus?.toLowerCase();
    return renderStatus(getStatusColor(status), status);
  } else if (
    checkData?.hasOwnProperty("errorCode") &&
    apiCallStatus?.toLowerCase() === "error"
  ) {
    return renderStatus(colors.skip, "SKIPPED");
  } else if (
    (!checkData && apiCallStatus?.toLowerCase() === "error") ||
    apiCallStatus?.toLowerCase() === "skipped"
  ) {
    return renderStatus(colors.skip, "SKIPPED");
  } else if (!checkData || apiCallStatus?.toLowerCase() === "fail") {
    return renderStatus(colors.danger, "FAIL");
  } else {
    return renderStatus(colors.success, "PASS");
  }
};

export const getDeviceStatusFlag = (status: string) => {
  if (status?.toLowerCase() === "pass") {
    return renderStatus(colors.success, "PASS");
  } else if (status?.toLowerCase() === "fail") {
    return renderStatus(colors.danger, "FAIL");
  } else {
    return renderStatus(getStatusColor(status), status);
  }
};

export const toProperCase = (inputString: string) => {
  if (!inputString || typeof inputString !== "string") {
    return "";
  }

  const words = inputString.toLowerCase().split(/\s+/);
  const properCaseWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  return properCaseWords.join(" ");
};

export const formatCamelCase = (inputString: any): String => {
  return inputString
    ? inputString
        .replace(/([A-Z]+)/g, " $1")
        .replace(/([A-Z][a-z])/g, " $1")
        .toUpperCase()
    : "";
};

export const formatMacAddress = (macAddress: string): string => {
  return macAddress ? macAddress.replace(/[^a-zA-Z0-9]/g, "") : "";
};

export const formatNPTNCTIMT = (nptId: string): string => {
  return nptId ? nptId.replace(/\s+/g, "").trim() : "";
};

export const macAddress = (inputtxt: any) => {
  let mac = formatMacAddress(inputtxt);
  // /^([0-9a-zA-Z]{2}[:-]){5}([0-9a-zA-Z]){2}$/;
  if (mac?.length === 12) {
    return "";
  } else {
    return `MAC Address can only have 12 Hexadecimal characters.`;
  }
};

export const nodeId = (inputtxt: any) => {
  if (inputtxt?.length > 0) {
    return "";
  } else {
    return `Please enter valid node id`;
  }
};

export const getDistanceStrValue = (dValue: number) => {
  return <span style={{ color: "#A72856" }}>~{dValue}</span>;
};

export const getSignalStrValue = (sgValue: number) => {
  if (sgValue >= -65) {
    return <span style={{ color: "#00985f" }}>{sgValue} dBm</span>;
  } else {
    return <span style={{ color: "#dc3545" }}>{sgValue} dBm</span>;
  }
};
export const getUploadValue = (uValue: number) => {
  let uploadValue = (uValue / 1000).toFixed(2);
  if (parseFloat(uploadValue) > 4) {
    return <span style={{ color: "#00985f" }}>{uploadValue} Mbps</span>;
  } else {
    return <span style={{ color: "#dc3545" }}>{uploadValue} Mbps</span>;
  }
};
export const getDownloadValue = (dValue: number) => {
  let downloadValue = (dValue / 1000).toFixed(2);
  if (parseFloat(downloadValue) >= 25) {
    return <span style={{ color: "#00985f" }}>{downloadValue} Mbps</span>;
  } else {
    return <span style={{ color: "#dc3545" }}>{downloadValue} Mbps</span>;
  }
};
export const changeTimeFormat = (inputTime: any): string => {
  if (inputTime && inputTime > 0) {
    const convertDate = new Date(parseInt(inputTime));
    let hours = convertDate.getHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutes =
      convertDate.getMinutes() < 10
        ? "0" + convertDate.getMinutes()
        : convertDate.getMinutes();
    const seconds =
      convertDate.getSeconds() < 10
        ? "0" + convertDate.getSeconds()
        : convertDate.getSeconds();

    let date =
      convertDate.toLocaleDateString() +
      ", " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds +
      " " +
      ampm;

    return date;
  } else return "";
};

export const changeTimeFormatToUTC = (inputTime: any): string => {
  if (inputTime && inputTime > 0) {
    const convertDate = new Date(parseInt(inputTime));
    let hours = convertDate.getUTCHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutes =
      convertDate.getUTCMinutes() < 10
        ? "0" + convertDate.getUTCMinutes()
        : convertDate.getUTCMinutes();
    const seconds =
      convertDate.getUTCSeconds() < 10
        ? "0" + convertDate.getUTCSeconds()
        : convertDate.getUTCSeconds();

    let date =
      convertDate.getUTCMonth() +
      1 +
      "/" +
      convertDate.getUTCDate() +
      "/" +
      convertDate.getUTCFullYear() +
      ", " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds +
      " " +
      ampm;

    return date;
  } else return "";
};

export const isBeforeDH = (firstDH: any, secDH: any) => {
  if (firstDH && secDH) {
    if (firstDH?.day > secDH?.day) return false;
    else if (firstDH?.day === secDH?.day && firstDH?.hour > secDH?.hour)
      return false;
    else return true;
  }
  return false;
};
export const isAfterDH = (firstDH: any, secDH: any) => {
  if (firstDH && secDH) {
    if (firstDH?.day < secDH?.day) return false;
    else if (firstDH?.day === secDH?.day && firstDH?.hour < secDH?.hour)
      return false;
    else return true;
  }
  return false;
};

export const getAccType = (accNum: string): string => {
  let accType = "";
  if (accNum !== undefined && accNum !== "") {
    accType =
      accNum?.length === 9 ? "Maestro" : accNum?.length === 12 ? "SS" : "";
  }
  return accType;
};
export const dataConverter = (dataToConv: any, typeOfConv: string): any => {
  let convertedData: any = "";
  if (dataToConv !== undefined && dataToConv !== "") {
    switch (typeOfConv) {
      case "upTime":
        convertedData = secToTime(dataToConv);
        break;
      case "secToTime":
        convertedData = secToTime2(dataToConv);
        break;
      case "hzToMhz":
        convertedData = hzToMHz(dataToConv);
        break;
      case "checkUSPartial":
        convertedData = getPartialServiceFlag(dataToConv, "US");
        break;
      case "checkDSPartial":
        convertedData = getPartialServiceFlag(dataToConv, "DS");
        break;
      case "changeTimeFormat":
        convertedData = changeTimeFormat(dataToConv);
        break;
      case "changeTimeFormatToUTC":
        convertedData = changeTimeFormatToUTC(dataToConv);
        break;
    }
  }
  return convertedData;
};

export const getPartialServiceFlag = (
  dataToCheck: any,
  channelType: string
) => {
  let psFlag = "";

  if (channelType === "US") {
    psFlag =
      dataToCheck === "partialSvcDsAndUsImpaired" ||
      dataToCheck === "partialSvcUsOnlyImpaired"
        ? "Yes"
        : "";
  } else if (channelType === "DS") {
    psFlag =
      dataToCheck === "partialSvcDsAndUsImpaired" ||
      dataToCheck === "partialSvcDsOnlyImpaired"
        ? "Yes"
        : "";
  }
  return psFlag;
};
export const performEllipsis = (
  inputString: string,
  outputLength: number
): string => {
  let slicedString = "";
  if (inputString !== undefined && inputString?.length > 0) {
    slicedString = inputString.slice(0, outputLength);
  }
  return slicedString;
};

export const parseDate = (date: String) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  if (date !== undefined) {
    const month = months[parseInt(date[5] + date[6]) - 1];
    const day = date[8] + date[9];
    const year = date.slice(0, 4);
    let time = date.slice(11, 16);
    let timePeriod = "AM";
    if (parseInt(time.slice(0, 2)) > 11) {
      timePeriod = "PM";
    }
    if (parseInt(time.slice(0, 2)) > 12) {
      time = parseInt(time.slice(0, 2)) - 12 + time.slice(2, 5);
    }
    return day + " " + month + " " + year + ", " + time + " " + timePeriod;
  } else return "N/A";
};

export const dataURItoBlob = (dataURI: any) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(",")?.[0]?.indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")?.[0]?.split(":")?.[1]?.split(";")?.[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString?.length);
  for (var i = 0; i < byteString?.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const getDeviceLoading = (deviceKey: string, diagData: any) => {
  let loadingFlag = undefined;
  diagData?.map((d: any) => {
    if (d.deviceKey === deviceKey) {
      loadingFlag = d.isLoading;
    }
  });
  return loadingFlag;
};
export const replacePatternData= (input:any, replacement:any)=>{
  const pattern ='\\d+(?:/\\d+)*';
  // const replacement ='-';
  try{
    const replacePonFormat= input?.replace(new RegExp(pattern,'g'),(match:any)=>{
  return match.replace(/\//g,replacement );
  });
  return replacePonFormat;
  }catch(error) {return null}};
export const getDeviceDetails = (
  deviceKey: string,
  detailsType: string,
  diagData: any
) => {
  let deviceDetails: any = [];

  diagData?.map((d: any) => {
    if (d.deviceKey === deviceKey) {
      if (d.data !== undefined && d.data?.hasOwnProperty(detailsType)) {
        deviceDetails = d.data[detailsType];
      }
    }
  });
  return deviceDetails;
};

export const getDeviceAPIStatus = (
  deviceKey: string,
  detailsType: string,
  diagData: any
) => {
  let deviceDetails: any = [];

  diagData?.map((d: any) => {
    if (d.deviceKey === deviceKey) {
      if (d.data !== undefined && d.data?.hasOwnProperty(detailsType)) {
        deviceDetails = d.data[detailsType];
      }
    }
  });
  return deviceDetails;
};

export function isStringNumber(value: string) {
  if (value?.toLowerCase() === value?.toUpperCase()) {
    return true;
  } else return false;
}
export const getSearchType = (inputText: string) => {
  if (/^(\d{1,3}\.){3}\d{1,3}$/.test(inputText)) {
    return "ipv4";
  } else if (
    (inputText?.length === 9 || inputText?.length === 12) &&
    isStringNumber(inputText)
  ) {
    return "accountNumber";
  } else if (inputText?.length === 12 && !isStringNumber(inputText)) {
    return "macAddress";
  } else if (inputText?.length === 10 && isStringNumber(inputText)) {
    return "tn";
  }
  // changes by node team for checking shub and postal code length start
  else if (inputText.length === 6 && isShub(inputText)) {
    return "shub";
  } else if (
    inputText.length === 6 &&
    !isStringNumber(inputText) &&
    isPostalCode(inputText)
  ) {
    return "postalcode";
  }
  // changes by node team end
  else {
    return `Invalid Entry`;
  }
};
export const getSearchTypeName = (inputText: string) => {
  if (inputText) {
    switch (inputText) {
      case ConfigConst.accNum:
        return "Account Number";

      case ConfigConst.macAddress:
        return "Mac Address";

      case ConfigConst.tn:
        return "IHP Number";

      case ConfigConst.ontSN:
        return "ONT SN";
      case ConfigConst.shub:
        return "Shub";
    }
  }

  return inputText;
};
export const getSearchTypeConfig = (deviceType: string) => {
  let uri = "";
  let neighUri = "";
  let prefix = "";
  if (deviceType) {
    switch (deviceType) {
      case ConfigConst.cm:
        uri = SearchConfig.nodeUri;
        prefix = SearchConfig.nodePrefix;
        break;
      case ConfigConst.ont:
        uri = SearchConfig.gponNodeUri;
        prefix = SearchConfig.gNodePrefix;
        break;
      case ConfigConst.emta:
        uri = SearchConfig.emtaUri;
        prefix = SearchConfig.emtaPrefix;
        break;
      case "nodeMultiUri":
        uri = SearchConfig.nodeMultiUri;
        prefix = SearchConfig.nodePrefix;
        break;
      case "neighbourhoodMultiUri":
        uri = SearchConfig.neighbourhoodMultiUri;
        prefix = SearchConfig.neighbourhoodPrefix;
        break;
      case ConfigConst.ecm:
        uri = SearchConfig.ecmUri;
        neighUri = SearchConfig.ecmNeighUri;
        prefix = SearchConfig.ecmPrefix;
        break;
      case ConfigConst.stb:
        uri = SearchConfig.stbUri;
        neighUri = SearchConfig.stbNeighUri;
        prefix = SearchConfig.stbPrefix;
        break;
      case ConfigConst.olt:
        uri = SearchConfig.oltUri;
        prefix = SearchConfig.oltPrefix;
        break;
      case ConfigConst.neighbourhood:
        uri = SearchConfig.neighbourhoodUri;
        prefix = SearchConfig.neighbourhoodPrefix;
        break;
    }
  }

  return { uri: uri, neighUri: neighUri, prefix: prefix };
};
// changes added by node team
export const isShub = (inputText: string) => {
  var regexShub = new RegExp(/^([a-zA-Z0-9]{1})([0-9]{5})$/i);

  return regexShub.test(inputText);
};

export const isPostalCode = (inputText: string) => {
  var regexPostalCode = new RegExp(/^([A-Za-z]{1}[0-9]{1}){3}$/i);
  return regexPostalCode.test(inputText);
};
// changes by node team end
export const getDStatusSpan = (dStat: string) => {
  if (dStat?.toLowerCase() === "fail")
    return (
      <span className="failState">
        <img style={{ height: "20px" }} src={ErrorIcon} />
      </span>
    );
  else if (dStat?.toLowerCase() === "pass")
    return (
      <span className="passState">
        <BsCheckCircle style={{ fontSize: "20px" }} color="#2DC071" />
      </span>
    );
  else if (dStat?.toLowerCase() === "marginal pass")
    return (
      <span className="marginalPassState">
        <BsCheckCircle style={{ fontSize: "20px" }} color="#ffbf3f" />
      </span>
    );
  else if (dStat?.toLowerCase()?.includes("skip"))
    return (
      <span className="skipState">
        <FiAlertTriangle style={{ fontSize: "20px" }} color="#E77C40" />
      </span>
    );
  else if (dStat?.toLowerCase() === "loading")
    return (
      <span>
        <img style={{ width: "20%" }} src={LoaderGif} />
      </span>
    );
  else return <span></span>;
};

export const removeDeviceStatus = (devices: Device[]): Device[] => {
  let computedDevices: Device[] = [];
  if (devices !== undefined && devices?.length !== 0) {
    devices?.map((d: Device) => {
      if (d?.hasOwnProperty("deviceStatus")) {
        delete d.deviceStatus;
        computedDevices.push(d);
      } else {
        computedDevices.push(d);
      }
    });
  }
  return computedDevices;
};

export const filterDevicesByTopology = (
  devices: Device[],
  topology: string
) => {
  let computedDevices: Device[] = [];
  let skippedDevices: Device[] = [];
  if (devices !== undefined && devices?.length !== 0) {
    devices?.map((device: Device) => {
      switch (topology) {
        case "DOCSIS":
          if (DOCSIS_DEVICES?.indexOf(device.deviceCategory) > -1) {
            computedDevices.push(device);
          } else {
            skippedDevices.push(device);
          }
          break;
        case "GPON":
          if (GPON_DEVICES?.indexOf(device.deviceCategory) > -1) {
            computedDevices.push(device);
          } else {
            skippedDevices.push(device);
          }
          break;
        case "FWA":
          if (FWA_DEVICES?.indexOf(device.deviceCategory) > -1) {
            computedDevices.push(device);
          } else {
            skippedDevices.push(device);
          }
          break;
      }
    });
  }
  return { filteredDevices: computedDevices, skippedDevices: skippedDevices };
};
export const getFormatedHomeAddress = (serviceAddress: any) => {
  let homeAdd = "";

  if (serviceAddress !== undefined) {
    let haveApartment =
      serviceAddress?.aptNumber !== undefined &&
      serviceAddress?.aptNumber !== null &&
      serviceAddress?.aptNumber !== ""
        ? serviceAddress?.aptNumber + " - "
        : "";
    homeAdd =
      haveApartment +
      serviceAddress?.streetNumber +
      " " +
      serviceAddress?.streetName +
      " " +
      serviceAddress?.streetType +
      ", " +
      serviceAddress?.cityName +
      ", " +
      serviceAddress?.province;

    if (serviceAddress?.postalCode)
      homeAdd = homeAdd + " " + serviceAddress?.postalCode;
  }
  return homeAdd;
};
export const getHomeAddress = (serviceAddress: any) => {
  let homeAdd = "";

  if (serviceAddress !== undefined) {
    let haveApartment =
      serviceAddress?.aptNumber !== undefined &&
      serviceAddress?.aptNumber !== null &&
      serviceAddress?.aptNumber !== ""
        ? serviceAddress?.aptNumber + " - "
        : "";

    let haveStreetType =
      serviceAddress?.streetType !== undefined &&
      serviceAddress?.streetType !== null &&
      serviceAddress?.streetType !== ""
        ? " " + serviceAddress?.streetType
        : "";

    homeAdd =
      haveApartment +
      serviceAddress?.streetNumber +
      " " +
      serviceAddress?.streetName +
      " " +
      haveStreetType +
      ", " +
      serviceAddress?.postalCode;
  }
  return homeAdd;
};
export const getFormatedAddress = (serviceAddress: any) => {
  let homeAdd = "";
  let streetType = serviceAddress?.streetType ? serviceAddress?.streetType : "";
  let streetCompass =
    serviceAddress?.streetCompass && serviceAddress?.streetCompass !== ""
      ? " " + serviceAddress?.streetCompass
      : "";
  let city = serviceAddress?.cityName ? serviceAddress?.cityName : "";
  let province = serviceAddress?.province ? serviceAddress?.province : "";
  let postalCode = serviceAddress?.postalCode
    ? "" +
      serviceAddress?.postalCode?.substr(0, 3) +
      " " +
      serviceAddress?.postalCode?.substr(3, 3)
    : "";
  switch (streetType) {
    case "CS":
      streetType = "Crescent";
      break;
    case "BL":
      streetType = "Blvd";
      break;
    case "ST":
      streetType = "Street";
      break;
    case "RD":
      streetType = "Rd";
      break;
    case "AV":
      streetType = "Ave";
      break;
  }
  if (serviceAddress !== undefined) {
    homeAdd =
      serviceAddress?.streetNumber +
      " " +
      serviceAddress?.streetName +
      " " +
      streetType +
      streetCompass +
      ", " +
      city +
      ", " +
      province +
      " " +
      postalCode;
  }

  return homeAdd;
};
export const getChStatusSpan = (totalChCount: any, failedChCount: any) => {
  if (totalChCount !== 0 && failedChCount > 0) {
    return (
      <>
        <span className="failLinkState">
          {failedChCount}/{totalChCount}
        </span>
        <img style={{ height: "14px" }} src={ErrorIcon} />
      </>
    );
  } else {
    return (
      <>
        <span className="passLinkState">{totalChCount}</span>
        <FaCheckCircle color="#00985f" />
      </>
    );
  }
};
export const getChannelsCountSpan = (data: any, cellType: string) => {
  if (data !== undefined && data?.length !== 0) {
    switch (cellType) {
      case "upStream30":
        let totalUS30ChCount = data?.channels?.length;
        let failedUS30ChCount = 0;
        if (totalUS30ChCount > 0) {
          data?.channels?.map((ch: any) => {
            if (
              ch?.snr?.status?.toLowerCase() === "fail" ||
              ch?.txPower?.status?.toLowerCase() === "fail"
            ) {
              failedUS30ChCount += 1;
            }
          });
        }
        return <>{getChStatusSpan(totalUS30ChCount, failedUS30ChCount)}</>;
      case "downStream30":
        let totalDS30ChCount = data?.channels?.length;
        let failedDS30ChCount = 0;
        if (totalDS30ChCount > 0) {
          data?.channels?.map((ch: any) => {
            if (
              ch?.snr?.status?.toLowerCase() === "fail" ||
              ch?.rx_power?.status?.toLowerCase() === "fail"
            ) {
              failedDS30ChCount += 1;
            }
          });
        }
        return <>{getChStatusSpan(totalDS30ChCount, failedDS30ChCount)}</>;
      case "upStream31":
        let totalUS31ChCount = data?.channels?.length;
        let failedUS31ChCount = 0;
        if (totalUS31ChCount > 0) {
          data?.channels?.map((ch: any) => {
            if (
              ch?.snr?.status?.toLowerCase() === "fail" ||
              ch?.txPower?.status?.toLowerCase() === "fail"
            ) {
              failedUS31ChCount += 1;
            }
          });
        }
        return <>{getChStatusSpan(totalUS31ChCount, failedUS31ChCount)}</>;
      case "downStream31":
        let totalDS31ChCount = data?.length;
        let failedDS31ChCount = 0;
        if (totalDS31ChCount > 0) {
          data?.map((ch: any) => {
            if (
              ch?.mer?.thrVal?.status?.toLowerCase() === "fail" ||
              ch?.rxPower?.status?.toLowerCase() === "fail" ||
              ch?.maxRxPower?.status?.toLowerCase() === "fail" ||
              ch?.minRxPower?.status?.toLowerCase() === "fail" ||
              ch?.averageRxPower?.status?.toLowerCase() === "fail"
            ) {
              failedDS31ChCount += 1;
            }
          });
        }
        return <>{getChStatusSpan(totalDS31ChCount, failedDS31ChCount)}</>;
    }
  } else {
    return <span>-</span>;
  }
};

export const checkPartialService = (data: any, status: any) => {
  let overallCondition = true;
  const conditions = (value: any) => {
    if (
      value === "" ||
      value === "none" ||
      value === "other" ||
      value === "Other" ||
      value === null ||
      value === undefined
    ) {
      return true;
    } else return false;
  };

  if (status !== "ERROR") {
    if (
      !conditions(data?.casaDsExcluded) ||
      !conditions(data?.casaUsExcluded) ||
      !conditions(data?.d31PartialChanState) ||
      !conditions(data?.d31PartialSvcState)
    ) {
      return false;
    }
  } else overallCondition = false;
  return overallCondition;
};

export const extractW3IdOfHSI = (serviceAddress: any) => {
  let w3ID = "";
  if (
    serviceAddress?.hasOwnProperty("subscription") &&
    serviceAddress?.subscription !== ""
  ) {
    serviceAddress?.subscription?.map((sub: any) => {
      if (
        sub?.hasOwnProperty("planInfoList") &&
        sub?.planInfoList?.hasOwnProperty("planInfo")
      ) {
        sub?.planInfoList?.planInfo?.map((pInfo: any) => {
          if (pInfo.productName === "HSI") w3ID = sub.w3Id;
        });
      }
    });
  }
  return w3ID;
};
export function planInfoList(selectedServiceAddress: any) {
  let planInfo: any = [];
  if (
    selectedServiceAddress?.hasOwnProperty("subscription") &&
    selectedServiceAddress?.subscription !== ""
  ) {
    selectedServiceAddress?.subscription?.map((sub: any) => {
      if (
        sub?.hasOwnProperty("planInfoList") &&
        sub?.planInfoList?.hasOwnProperty("planInfo")
      ) {
        sub?.planInfoList?.planInfo?.map((pInfo: any) => {
          if (pInfo.productName === "HSI" || pInfo.productName === "I")
            planInfo = [].concat.apply(pInfo);
        });
      }
    });
  }
  return planInfo;
}
export const isEmailFormatValid = (inputText: string) => {
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
  return reg.test(inputText);
};

export const isValidSwVersion = (inputText: string) => {
  let reg = /^.*\.(img)$/;
  return reg.test(inputText);
};

export const validateEmail = (email: any) => {
  const validate = String(email)
    ?.toLowerCase()
    ?.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

  if (validate) {
    return true;
  } else return false;
};
export const isAccountNumberFormatValid = (inputText: string) => {
  if (
    (inputText?.length === 9 || inputText?.length === 12) &&
    isStringNumber(inputText)
  ) {
    return true;
  } else if (inputText?.length === 12 && !isStringNumber(inputText)) {
    return true;
  } else if (inputText?.length === 10 && isStringNumber(inputText)) {
    return true;
  } else {
    return false;
  }
};

export const aspSnValidation = (
  aspDetails: any,
  inputSerialNumbers: any,
  quantity: any
) => {
  let isAllSnValidated = true;
  const newArr = inputSerialNumbers.slice(0, Number(quantity));
  for (let i = 0; i < newArr?.length; i++) {
    const item = newArr[i];
    const isSnNotMatched =
      aspDetails?.[item?.key2] === "" ||
      aspDetails?.[item?.key1] === "" ||
      aspDetails?.[item?.key2] !== aspDetails?.[item?.key1];
    const isSnVerified =
      !isSnNotMatched &&
      aspDetails?.[item?.key1] === item.lastVerifiedSn &&
      item?.isSnValid;

    if (!isSnVerified) {
      isAllSnValidated = false;
      break;
    }
  }
  return isAllSnValidated;
};

export const aspFreePodValidation = (
  aspDetails: any,
  freePodInputSerialNumbers: any,
  quantity: any
) => {
  if (
    aspDetails?.freepodreasoning === "Other" &&
    aspDetails?.freepodreasoningcomments === ""
  ) {
    return false;
  }

  return aspSnValidation(aspDetails, freePodInputSerialNumbers, quantity);
};

export const aspStbPaidFreePodValidation = (
  aspDetails: any,
  stbInputSerialNumbers: any,
  freePodInputSerialNumbers: any,
  paidPodInputSerialNumbers: any
) => {
  if (
    aspDetails?.isSTBchecked ||
    aspDetails?.isFreePodChecked ||
    aspDetails?.isPaidPodChecked
  ) {
    if (
      aspDetails?.isSTBchecked &&
      aspDetails?.isFreePodChecked &&
      aspDetails?.isPaidPodChecked
    ) {
      return (
        aspSnValidation(
          aspDetails,
          stbInputSerialNumbers,
          aspDetails?.stbquantity
        ) &&
        aspFreePodValidation(
          aspDetails,
          freePodInputSerialNumbers,
          aspDetails?.freepodquantity
        ) &&
        aspSnValidation(
          aspDetails,
          paidPodInputSerialNumbers,
          aspDetails?.paidpodquantity
        )
      );
    } else if (aspDetails?.isSTBchecked && aspDetails?.isPaidPodChecked) {
      return (
        aspSnValidation(
          aspDetails,
          stbInputSerialNumbers,
          aspDetails?.stbquantity
        ) &&
        aspSnValidation(
          aspDetails,
          paidPodInputSerialNumbers,
          aspDetails?.paidpodquantity
        )
      );
    } else if (aspDetails?.isSTBchecked && aspDetails?.isFreePodChecked) {
      return (
        aspSnValidation(
          aspDetails,
          stbInputSerialNumbers,
          aspDetails?.stbquantity
        ) &&
        aspFreePodValidation(
          aspDetails,
          freePodInputSerialNumbers,
          aspDetails?.freepodquantity
        )
      );
    } else if (aspDetails?.isFreePodChecked && aspDetails?.isPaidPodChecked) {
      return (
        aspFreePodValidation(
          aspDetails,
          freePodInputSerialNumbers,
          aspDetails?.freepodquantity
        ) &&
        aspSnValidation(
          aspDetails,
          paidPodInputSerialNumbers,
          aspDetails?.paidpodquantity
        )
      );
    } else if (aspDetails?.isSTBchecked) {
      return aspSnValidation(
        aspDetails,
        stbInputSerialNumbers,
        aspDetails?.stbquantity
      );
    } else if (aspDetails?.isFreePodChecked) {
      return aspFreePodValidation(
        aspDetails,
        freePodInputSerialNumbers,
        aspDetails?.freepodquantity
      );
    } else if (aspDetails?.isPaidPodChecked) {
      return aspSnValidation(
        aspDetails,
        paidPodInputSerialNumbers,
        aspDetails?.paidpodquantity
      );
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const updateAspSnData = (snTextInputArray: any, formHistory: any) => {
  return snTextInputArray?.map((data: any) => {
    if (formHistory?.[data?.key1] === formHistory?.[data?.key1]) {
      return {
        ...data,
        isSnValid: true,
        lastVerifiedSn: formHistory?.[data?.key1],
      };
    } else {
      return data;
    }
  });
};

export const getEquipmentMenuAndPodQuantity = (
  aspDetails: any,
  aspFormInitialData: any
) => {
  let menu = "";
  let podOrStbQuantity = "";
  let combinedSerialNumbers = "";
  let stbSerialNumbers = "";
  let freePodSerialNumbers = "";
  let paidPodSerialNumbers = "";

  if (aspDetails?.isPaidPodChecked) {
    const paidPod = "Paid Pod";
    menu = menu + paidPod;
    podOrStbQuantity =
      podOrStbQuantity + paidPod + ":" + aspDetails?.paidpodquantity;
    aspFormInitialData?.paidPodSerialNumbers
      ?.slice(0, Number(aspDetails?.paidpodquantity))
      ?.map((data: any) => {
        if (paidPodSerialNumbers !== "")
          paidPodSerialNumbers = paidPodSerialNumbers + ",";
        paidPodSerialNumbers =
          paidPodSerialNumbers + paidPod + ":" + aspDetails?.[data?.key1];

        if (combinedSerialNumbers !== "")
          combinedSerialNumbers = combinedSerialNumbers + ",";
        combinedSerialNumbers =
          combinedSerialNumbers + paidPod + ":" + aspDetails?.[data?.key1];
      });
  }
  if (aspDetails?.isSTBchecked) {
    const stb = "STB";
    if (menu !== "") menu = menu + ",";
    menu = menu + stb;

    if (podOrStbQuantity !== "") podOrStbQuantity = podOrStbQuantity + ",";
    podOrStbQuantity = podOrStbQuantity + stb + ":" + aspDetails?.stbquantity;

    aspFormInitialData?.stbSerialNumbers
      ?.slice(0, Number(aspDetails?.stbquantity))
      ?.map((data: any) => {
        if (stbSerialNumbers !== "") stbSerialNumbers = stbSerialNumbers + ",";
        stbSerialNumbers =
          stbSerialNumbers + stb + ":" + aspDetails?.[data?.key1];

        if (combinedSerialNumbers !== "")
          combinedSerialNumbers = combinedSerialNumbers + ",";
        combinedSerialNumbers =
          combinedSerialNumbers + stb + ":" + aspDetails?.[data?.key1];
      });
  }
  if (aspDetails?.isFreePodChecked) {
    const freePod = "Free Pod";
    if (menu !== "") menu = menu + ",";
    menu = menu + freePod;

    if (podOrStbQuantity !== "") podOrStbQuantity = podOrStbQuantity + ",";
    podOrStbQuantity =
      podOrStbQuantity + freePod + ":" + aspDetails?.freepodquantity;

    aspFormInitialData?.freePodSerialNumbers
      ?.slice(0, Number(aspDetails?.freepodquantity))
      ?.map((data: any) => {
        if (freePodSerialNumbers !== "")
          freePodSerialNumbers = freePodSerialNumbers + ",";
        freePodSerialNumbers =
          freePodSerialNumbers + freePod + ":" + aspDetails?.[data?.key1];

        if (combinedSerialNumbers !== "")
          combinedSerialNumbers = combinedSerialNumbers + ",";
        combinedSerialNumbers =
          combinedSerialNumbers + freePod + ":" + aspDetails?.[data?.key1];
      });
  }

  return {
    menu,
    podOrStbQuantity,
    combinedSerialNumbers,
    stbSerialNumbers,
    paidPodSerialNumbers,
    freePodSerialNumbers,
  };
};

export const getTestTypeCode = (testType: string): String => {
  let testCode =
    testType === "Whole Home Test"
      ? TESTTYPE_CONFIG.WHT.testTypeCode
      : testType === "Drop & Stay Test"
      ? TESTTYPE_CONFIG.DNS.testTypeCode
      : testType === "EDBA Test"
      ? TESTTYPE_CONFIG.DNG.testTypeCode
      : "";
  return testCode;
};
export const getFormType = (formType: string): String => {
  let formCode = formType === "All Forms" ? "" : formType;
  return formCode;
};

export const getUnerroredsDelta = (firstData: any, seconds: any, ch: any) => {
  let firstResult = firstData?.find((c: any) => c?.frequency === ch?.frequency);
  return `${ch?.unerroreds - firstResult?.unerroreds} @${seconds}s`;
};
export const getCorrectedsDelta = (firstData: any, seconds: any, ch: any) => {
  let firstResult = firstData?.find((c: any) => c?.frequency === ch?.frequency);
  return `${ch?.correcteds - firstResult?.correcteds} @${seconds}s`;
};
export const getOFDMUncorrectablesDelta = (
  firstData: any,
  seconds: any,
  ch: any,
  chType: string
) => {
  let firstResult: any = [];
  if (chType === "OFDM") {
    firstResult = firstData?.profiles?.find(
      (c: any) => c?.profileId === ch?.profileId
    );
    return `${
      ch?.uncorrectableCodewords - firstResult?.uncorrectableCodewords
    } @${seconds}s`;
  } else if (chType === "OFDMA") {
    firstResult = firstData?.iucList?.find((c: any) => c?.iuc === ch?.iuc);
    return `${
      ch?.unreliableCodewords - firstResult?.unreliableCodewords
    } @${seconds}s`;
  }
};

export const checkDeltaStatus = (firstData: any, seconds: any, ch: any) => {
  if (firstData !== null && seconds !== null && ch !== null) {
    let firstResult = firstData?.find(
      (c: any) => c?.frequency === ch?.frequency
    );
    let data = Number(ch?.uncorrectables - firstResult?.uncorrectables)
      ? ch?.uncorrectables - firstResult?.uncorrectables
      : 0;
    if (data > 0)
      return (
        <span className="failState">{`${
          ch?.uncorrectables - firstResult?.uncorrectables
        } @${seconds}s`}</span>
      );
    else
      return `${ch?.uncorrectables - firstResult?.uncorrectables} @${seconds}s`;
  }
};

export function isIgniteSTBAccount() {
  const accountDetailsData = GetActiveAccountDetails()?.data;

  if (
    accountDetailsData?.accountNumber?.length === 9 &&
    accountDetailsData?.brand == !"Fido"
  ) {
    return true;
  } else return false;
}
export const checkOFDMUncorrectablesStatus = (
  firstData: any,
  seconds: any,
  ch: any,
  chType: string
) => {
  let firstResult: any = [];
  let data: any;
  if (chType === "OFDM") {
    firstResult = firstData?.profiles?.find(
      (c: any) => c?.profileId === ch?.profileId
    );
    data = Number(
      ch?.uncorrectableCodewords - firstResult?.uncorrectableCodewords
    )
      ? ch?.uncorrectableCodewords - firstResult?.uncorrectableCodewords
      : 0;
    if (data > 0)
      return (
        <span className="failState">{`${
          ch?.uncorrectableCodewords - firstResult?.uncorrectableCodewords
        } @${seconds}s`}</span>
      );
    else
      return `${
        ch?.uncorrectableCodewords - firstResult?.uncorrectableCodewords
      } @${seconds}s`;
  } else if (chType === "OFDMA") {
    firstResult = firstData?.iucList?.find((c: any) => c?.iuc === ch?.iuc);
    data = Number(ch?.unreliableCodewords - firstResult?.unreliableCodewords)
      ? ch?.unreliableCodewords - firstResult?.unreliableCodewords
      : 0;
    if (data > 0)
      return (
        <span className="failState">{`${
          ch?.unreliableCodewords - firstResult?.unreliableCodewords
        } @${seconds}s`}</span>
      );
    else
      return `${
        ch?.unreliableCodewords - firstResult?.unreliableCodewords
      } @${seconds}s`;
  }
};
export const getOFDMcorrectedDelta = (
  firstData: any,
  seconds: any,
  ch: any,
  chType: string
) => {
  let firstResult: any = [];
  if (chType === "OFDM") {
    firstResult = firstData?.profiles?.find(
      (c: any) => c?.profileId === ch?.profileId
    );
  } else {
    firstResult = firstData?.iucList?.find((c: any) => c?.iuc === ch?.iuc);
  }
  return `${
    ch?.correctedCodewords - firstResult?.correctedCodewords
  } @${seconds}s`;
};
export const getOFDMTotalCodewordsDelta = (
  firstData: any,
  seconds: any,
  ch: any,
  chType: string
) => {
  let firstResult: any = [];
  if (chType === "OFDM") {
    firstResult = firstData?.profiles?.find(
      (c: any) => c?.profileId === ch?.profileId
    );
  } else {
    firstResult = firstData?.iucList?.find((c: any) => c?.iuc === ch?.iuc);
  }
  return `${ch?.totalCodewords - firstResult?.totalCodewords} @${seconds}s`;
};

export const getBaselineRatios = (
  unerroreds: any,
  correcteds: any,
  uncorrectables: any
) => {
  const ratio =
    (uncorrectables * 100) / (unerroreds + correcteds + uncorrectables);

  return ratio?.toFixed(3) + "%";
};
export const getOFDMBaselineRatios = (
  uncorrectables: any,
  totalCodewords: any
) => {
  const ratio = uncorrectables / totalCodewords;

  return ratio?.toFixed(3) + "%";
};

//Changes added by node Team started
export const getMTWorkOrderStatusColor = (jobCode: string, status: string) => {
  let colorName = "greyLight";
  const jobGreenArray = [
    "AST",
    "CLT",
    "CON",
    "DPC",
    "DPO",
    "PAT",
    "PBP",
    "PDS",
  ];

  const jobYellowArray = ["DGP", "DGR", "EPM", "LOC", "M2", "M3", "RTH", "SPS"];

  const jobRedArray = ["DEM", "M1"];
  const jobDarkRedArray = ["OG", "POG"];
  var jobCodeColor = "";

  if (jobGreenArray.indexOf(jobCode.toUpperCase()) > -1) {
    jobCodeColor = "green";
  } else if (jobYellowArray.indexOf(jobCode.toUpperCase()) > -1) {
    jobCodeColor = "yellow";
  } else if (jobRedArray.indexOf(jobCode.toUpperCase()) > -1) {
    jobCodeColor = "red";
  } else if (jobDarkRedArray.indexOf(jobCode.toUpperCase()) > -1) {
    jobCodeColor = "darkRed";
  }

  switch (jobCodeColor.toUpperCase()) {
    case "GREEN":
      colorName = "lightGreen";
      break;
    case "YELLOW":
      colorName = "warning";
      break;
    case "RED":
      colorName = "danger";
      break;
    case "DARKRED":
      colorName = "maroonRed";
      break;
    default:
      colorName = "greyLight";
      break;
  }
  return (
    <p
      className="woCardStatus"
      style={{ backgroundColor: `var(--${colorName})` }}
    >
      {status}
      {/* <img
        style={{ height: "16px", float: "right", marginTop: "4px" }}
        src={EditIcon}
      /> */}
    </p>
  );
};
//Changes added by node Team ended

export const getWOStatusSpan = (status: string) => {
  let colorName = "greyLight";

  switch (status?.toUpperCase()) {
    case "PENDING":
    case "RESERVED":
    case "NOTUSED":
      colorName = "greyLight";
      break;
    case "TENTATIVE":
      colorName = "warning";
      break;
    case "ENROUTE":
      colorName = "success";
      break;
    case "DISPATCHED":
      colorName = "lightGreen";
      break;
    case "ONSITE":
      colorName = "infoCurtain";
      break;
    case "CANCELLED":
    case "SUSPENDED":
      colorName = "danger";
      break;
    case "ACKNOWLEDGED":
    case "PENDING RESTORAL":
      colorName = "skyBlue";
      break;
    case "RECALL":
    case "RESOLVED":
    case "COMPLETED":
      colorName = "purple";
      break;
    case "MODIFIED":
      colorName = "pink";
      break;
  }
  return (
    <p
      className="woCardStatus"
      style={{ backgroundColor: `var(--${colorName})` }}
    >
      {status}
    </p>
  );
};

export function filterArrayOfObjects(
  value: string,
  data: any,
  filterKeys: any
) {
  return filterKeys

    ?.map((e: any) =>
      data?.filter((data: any) =>
        data?.[e]?.toUpperCase()?.includes(value?.toUpperCase())
      )
    )

    .filter((f: any) => f?.length > 0)?.[0];
}

export const breakLongStrings = (value: any, char: any) => {
  let splitStrings = value.split(char);
  return (
    <>
      {splitStrings?.length !== 0 ? (
        splitStrings?.map((s: any) => {
          return <span className="blockSpan">{s}</span>;
        })
      ) : (
        <></>
      )}
    </>
  );
};
export const randomString = (length: number) => {
  return Math.round(
    Math.pow(36, length + 1) - Math.random() * Math.pow(36, length)
  )
    .toString(36)
    .slice(1);
};
export const checkCMStatus = (neighData: any) => {
  let neighSummaryStatus = neighData?.cmData?.summary?.status;
  let neighSummaryData = neighData?.cmData?.summary?.data;
  let neighDocsisStatus = neighData?.cmData?.docsis?.status;
  let neighDocsisData = neighData?.cmData?.docsis?.data;
  let CMStatus = "";
  if (neighData !== undefined && neighData?.hasOwnProperty("cmData")) {
    if (neighSummaryData !== undefined && neighSummaryStatus === "OK") {
      if (neighDocsisData !== undefined && neighDocsisStatus === "OK") {
        CMStatus = neighDocsisData?.overallStatus?.toLowerCase();
      } else if (
        neighDocsisData !== undefined &&
        neighDocsisStatus === "ERROR"
      ) {
        CMStatus = "fail";
      }
    } else if (
      neighSummaryData !== undefined &&
      neighSummaryStatus === "ERROR"
    ) {
      CMStatus = "fail";
    }
  }
  return CMStatus;
};

export const isAccAvailable = (accounts: any, activeAcc: string) => {
  let availableFlag = false;
  if (accounts !== undefined && accounts?.length !== 0) {
    accounts?.map((acc: any) => {
      if (acc?.accNum === activeAcc) {
        availableFlag = true;
      }
    });
  }
  return availableFlag;
};
export const isNodeAvailable = (nodes: any, activekey: string) => {
  let availableFlag = false;
  if (nodes !== undefined && nodes?.length !== 0) {
    nodes.map((a: any) => {
      if (a?.entityNum === activekey) {
        availableFlag = true;
      }
    });
  }
  return availableFlag;
};
export const getAccountState = (accounts: any, accNum: string) => {
  let accState: any = {};
  if (accounts !== undefined && accounts?.length !== 0) {
    accounts?.map((acc: any) => {
      if (acc?.accNum === accNum) {
        accState = acc?.accState;
      }
    });
  }
  return accState;
};

export const getNodeState = (nodes: any, entityNum: string) => {
  let newNodeState: any = {};
  if (nodes !== undefined && nodes?.length !== 0) {
    nodes.map((a: any) => {
      if (a?.entityNum === entityNum) {
        newNodeState = a?.nodeState;
      }
    });
  }
  return {
    newNodeState,
  };
};

export const isUpdated = (accounts: any, activeAcc: string) => {
  let updatedFlag = false;
  if (accounts !== undefined && accounts?.length !== 0) {
    accounts?.map((acc: any) => {
      if (acc?.accNum === activeAcc && acc?.isUpdated) {
        updatedFlag = true;
      }
    });
  }
  return updatedFlag;
};
export const hasWXLEorWXBB = (sortedDevices: any) => {
  return sortedDevices?.some(
    (d: any) => d.deviceType === "WXLE" || d.deviceType === "WXBB"
  ) || false;
};
export const techRole =()=>{
  const techData = GetTechInfo()?.data;
  if (techData && techData?.hasOwnProperty("roles")) {
    const roleExists = techData?.roles?.some((e: any) => {
      return FlapVisibility?.some((roleVisible: any) => {
        return e === roleVisible;
      });
    });
    
    return roleExists; 
  }
  
  return false; // Return false if techData or roles are missing
}
export const isEqualDevices = (WHTdevices: any, sortedDevices: any) => {
  let comparedFlag = false;
  if (WHTdevices !== undefined && sortedDevices !== undefined) {
    let deviceCounter = 0;
    WHTdevices?.map((WHTDevice: any) => {
      sortedDevices?.map((device: any) => {
        if (
          WHTDevice?.deviceCategory === device?.deviceCategory &&
          WHTDevice?.serialNumber === device?.serialNumber
        ) {
          deviceCounter = deviceCounter + 1;
        }
      });
    });
    if (deviceCounter === sortedDevices?.length) {
      comparedFlag = true;
    }
  }
  return comparedFlag;
};

export const isValidWID = (
  woid: string,
  accountNumber: String,
  accountType: String
): boolean => {
  let wOrderID = /^([0-9a-zA-Z]{13})$/;
  if (woid !== undefined && woid.match(wOrderID)) {
    if (accountNumber.length === 12 || accountType === "R") {
      if (woid?.toLowerCase()?.startsWith("c")) return true;
      else return false;
    } else if (accountNumber.length === 9) {
      if (woid?.toLowerCase()?.startsWith("w")) return true;
      else return false;
    } else return false;
  } else return false;
};

export function chunk(dataList: any, size: number) {
  const chunks = [];
  dataList = [].concat(...dataList);

  while (dataList.length) {
    chunks.push(dataList.splice(0, size));
  }

  return chunks;
}

export const getValueByStrMapping = (object: any, path: string) => {
  let propValue: any = "";
  path = path.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  path = path.replace(/^\./, ""); // strip a leading dot
  let a = path.split(".");

  if (a !== undefined && a?.length !== 0 && object !== undefined) {
    let searchObj: any = object;
    a?.map((prop: any) => {
      searchObj = searchObj?.hasOwnProperty(prop) ? searchObj[prop] : "";
    });
    propValue = searchObj;
  }
  return propValue;
};

export function isLegacyAccount() {
  const accountDetailsData = GetActiveAccountDetails()?.data;

  if (
    accountDetailsData?.accountNumber?.length === 12 ||
    accountDetailsData?.accountType === "R"
  ) {
    return true;
  } else return false;
}

export function isNumeric(number: any) {
  const regex = /^[0-9\b]+$/;
  if (regex.test(number)) {
    return number;
  } else return "";
}

export function getDate(day = "now") {
  const now = new Date();
  const yesterday = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - 1
  );
  let activeDay = now;

  switch (day.toUpperCase()) {
    case "NOW":
      activeDay = now;
      break;
    case "YESTERDAY":
      activeDay = yesterday;
      break;
    default:
      activeDay = now;
      break;
  }

  return format(
    new Date(
      Number(activeDay?.getFullYear()),
      Number(activeDay?.getMonth()),
      Number(activeDay?.getDate())
    ),
    "dd/MM/yyyy"
  );
}

export function getISODate(date: Date) {
  let month =
    date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
  let todayDate = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
  return date.getFullYear() + "-" + month + "-" + todayDate;
}

export const getJson = (object: any) => {
  if (typeof object === "string") {
    return JSON.parse(object);
  } else return object;
};

export const sortObject = (obj: any) => {
  let sortedObj = Object.entries(obj)
    .sort((a, b) => {
      if (a[0] < b[0]) {
        return -1;
      } else if (a[0] > b[0]) {
        return 1;
      }
      return 0;
    })
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
  return sortedObj;
};

export const sortWOJobCodeList = [
  "OG",
  "POG",
  "DEM",
  "M1",
  "M2",
  "M3",
  "DGP",
  "DGR",
  "EPM",
  "LOC",
  "RTH",
  "SPS",
  "PDS",
  "AST",
  "CLT",
  "CON",
  "DPC",
  "DPO",
  "PAT",
  "PBP",
  "EOL",
  "LCP",
  "MMS",
  "PHA",
  "PM",
  "PS",
  "PWR",
  "RTC",
  "RTP",
  "RTS",
  "SHC",
  "SMT",
];
//custom hook to detect window size change and return width and height of the changed screen
export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

//get distance of two different points in map
export function getMapPointsDistance(
  mk1: any,
  mk2: any,
  pastDistance: number,
  decimal = 2,
  unit = "KM"
) {
  var R = unit === "KM" ? 6371.071 : unit === "MI" ? 3958.8 : 6371.071; // Radius of the Earth
  var rlat1 = Number(mk1.lat) * (Math.PI / 180); // Convert degrees to radians
  var rlat2 = Number(mk2.lat) * (Math.PI / 180); // Convert degrees to radians
  var difflat = rlat2 - rlat1; // Radian difference (latitudes)
  var difflon = (Number(mk2.lng) - Number(mk1.lng)) * (Math.PI / 180); // Radian difference (longitudes)

  var d =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) *
            Math.cos(rlat2) *
            Math.sin(difflon / 2) *
            Math.sin(difflon / 2)
      )
    );

  // let getUnit;
  // if (d < 1) {
  //   getUnit = (d * 1000)?.toFixed(decimal) + " M";
  // } else {
  //   getUnit = d?.toFixed(decimal) + " KM";
  // }

  return pastDistance + d;
}

export const copyToClipboard = (data: any) => {
  window.navigator.clipboard
    .writeText(data)
    .then(() => {
      //success
    })
    .catch((err) => {
      console.log("Something went wrong" + err);
    });
};

const getPolygonCenter = (maps: any, polygonCoords: Array<{}>) => {
  const bounds = new maps.LatLngBounds();
  for (let i = 0; i < polygonCoords?.length; i++) {
    bounds.extend(polygonCoords[i]);
  }
  return bounds?.getCenter()?.toJSON();
};

export const isInsideViewPort = (
  maps: any,
  nePoint: any,
  swPoint: any,
  viewPortPoly: any
) => {
  let newViewPortPoly = [];
  let nwPoint = { lat: nePoint?.lat, lng: swPoint?.lng };
  let sePoint = { lat: swPoint?.lat, lng: nePoint?.lng };
  let isNEPointWVP = false;
  let isSWPointWVP = false;
  let isNWPointWVP = false;
  let isSEPointWVP = false;
  const currentVP = [
    { lat: nePoint?.lat, lng: swPoint?.lng },
    { lat: nePoint?.lat, lng: nePoint?.lng },
    { lat: swPoint?.lat, lng: nePoint?.lng },
    { lat: swPoint?.lat, lng: swPoint?.lng },
  ];
  const centerPoint = getPolygonCenter(maps, currentVP);
  let isCenterPointWVP = false;

  for (let i = 0; i < viewPortPoly.length; i++) {
    const viewPortPolyRef = new maps.Polygon({ paths: viewPortPoly[i] });
    if (!isNEPointWVP) {
      isNEPointWVP = maps?.geometry?.poly?.containsLocation(
        nePoint,
        viewPortPolyRef
      );
    }
    if (!isSWPointWVP) {
      isSWPointWVP = maps?.geometry?.poly?.containsLocation(
        swPoint,
        viewPortPolyRef
      );
    }
    if (!isNWPointWVP) {
      isNWPointWVP = maps?.geometry?.poly?.containsLocation(
        nwPoint,
        viewPortPolyRef
      );
    }
    if (!isSEPointWVP) {
      isSEPointWVP = maps?.geometry?.poly?.containsLocation(
        sePoint,
        viewPortPolyRef
      );
    }
    if (!isCenterPointWVP) {
      isCenterPointWVP = maps?.geometry?.poly?.containsLocation(
        centerPoint,
        viewPortPolyRef
      );
    }
    if (
      isNEPointWVP &&
      isSWPointWVP &&
      isNWPointWVP &&
      isSEPointWVP &&
      isCenterPointWVP
    ) {
      break;
    }
  }

  const isWVP =
    isNEPointWVP &&
    isSWPointWVP &&
    isNWPointWVP &&
    isSEPointWVP &&
    isCenterPointWVP
      ? true
      : false;

  if (isWVP) {
    newViewPortPoly.push(...viewPortPoly);
  } else {
    newViewPortPoly.push(currentVP);
    viewPortPoly?.map((e: any) => {
      if (isPolyWithinPoly(maps, e, currentVP) === false) {
        newViewPortPoly.push(e);
      }
    });
  }

  return { isWVP, newViewPortPoly };
};

const isPolyWithinPoly = (maps: any, innerPoly: any, outerPoly: any) => {
  let isWVP = true;

  for (let i = 0; i < innerPoly.length; i++) {
    const outerViewPortPoly = new maps.Polygon({ paths: outerPoly });
    isWVP = maps?.geometry?.poly?.containsLocation(
      innerPoly[i],
      outerViewPortPoly
    );
    if (!isWVP) break;
  }

  return isWVP;
};

export const getCenterOfPoly = (polycords: any) => {
  let center = [0.0, 0.0];
  let totalCords = polycords?.length;

  if (polycords !== undefined && totalCords > 0) {
    polycords?.map((cord: any, i: number) => {
      center[0] += cord?.[1];
      center[1] += cord?.[0];
    });
    center[0] = center[0] / totalCords;
    center[1] = center[1] / totalCords;
  }
  return { lat: center[0], lng: center[1] };
};

export const getUniqueMapData = (currData: any, newData: any) => {
  let combinedData: any;
  if (newData !== undefined && newData?.features?.length > 0) {
    if (currData !== undefined && currData?.features?.length > 0) {
      let filteredList: any = [];
      newData?.features?.map((nF: any) => {
        let isFPresent = currData?.features?.map((cF: any) => {
          if (cF?.properties?.featureId === nF?.properties?.featureId) {
            return true;
          } else {
            return false;
          }
        });
        if (isFPresent?.indexOf(true) === -1) {
          filteredList?.push(nF);
        }
      });
      let combinedFeatures = [...currData?.features, ...filteredList];
      combinedData = {
        ...currData,
        ...{ features: combinedFeatures },
      };
    } else {
      combinedData = newData;
    }
  }
  return combinedData;
};

export const getPolyAreaCords = (cords: any) => {
  let northEastPoly: any;
  let southWestPoly: any;

  if (cords !== undefined && cords?.length > 0) {
    let highLat = cords?.[0]?.[1];
    let highLng = cords?.[0]?.[0];
    let lowLat = cords?.[0]?.[1];
    let lowLng = cords?.[0]?.[0];
    cords?.map((c: any) => {
      if (c?.[0] > highLng) highLng = c?.[0];
      if (c?.[0] < lowLng) lowLng = c?.[0];
      if (c?.[1] > highLat) highLat = c?.[1];
      if (c?.[1] < lowLat) lowLat = c?.[1];
    });
    northEastPoly = { longitude: highLng, latitude: highLat };
    southWestPoly = { longitude: lowLng, latitude: lowLat };
  }
  return { topRight: northEastPoly, bottomLeft: southWestPoly };
};

export const getTapIconName = (tapType: number, markerImg: any): any => {
  switch (tapType) {
    case 2:
      return markerImg?.[0]?.tapImage2;
    case 4:
      return markerImg?.[0]?.tapImage4;
    case 8:
      return markerImg?.[0]?.tapImage8;

    default:
      return markerImg?.[0]?.tapImage2;
  }
};

export const getSplitterIconName = (splitterType: any, markerImg: any): any => {
  switch (splitterType) {
    case "LLS103":
      return markerImg?.[0]?.splitterLLS103;
    case "LLS102":
      return markerImg?.[0]?.splitter2WayImage;
    case "LDC108":
      return markerImg?.[0]?.splitter3WayImage;

    default:
      return markerImg?.[0]?.markerImg;
  }
};

export const getMarkerDataWithinViewPort = (
  mapMarkerData: any,
  existingFeaturesWVP: Array<any>,
  northEast: any,
  southWest: any,
  maps: any
) => {
  const currentVP = [
    [
      { lat: northEast?.lat, lng: southWest?.lng },
      { lat: northEast?.lat, lng: northEast?.lng },
      { lat: southWest?.lat, lng: northEast?.lng },
      { lat: southWest?.lat, lng: southWest?.lng },
    ],
  ];
  let featuresWVP = [...existingFeaturesWVP] as Array<any>;
  mapMarkerData?.features?.map((e: any) => {
    const index = featuresWVP?.findIndex((object) => {
      return object.properties?.featureId === e.properties?.featureId;
    });

    if (e?.geometry?.type === "Point" && index === -1) {
      const point = {
        lat: e?.geometry?.coordinates?.[1],
        lng: e?.geometry?.coordinates?.[0],
      };

      const viewPortPolyRef = new maps.Polygon({ paths: currentVP });
      const isFeaturesWVP = maps?.geometry?.poly?.containsLocation(
        point,
        viewPortPolyRef
      );
      if (isFeaturesWVP) featuresWVP.push(e);
    }
  });

  return featuresWVP;
};

export function openNewWindow(url: string) {
  window.open(url);
}
export function setCurrentWindowName(name: string) {
  window.document.title = name;
  window.name = name;
}
export const getEmailFormatDate = () => {
  const now = new Date();
  const options: any = { month: "long" };
  const month = new Intl.DateTimeFormat("en-US", options).format(now);
  const day = now.getDate();
  const year = now.getFullYear();

  return month + " " + day + ", " + year;
};

export function convertHTMLtoStr(htmlData: any) {
  let htmlStr = renderToString(htmlData);

  return String(htmlStr)
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;");
}

export const convertPascalCase = (str: string) => {
  let convertedStr = str.replace(/\w+/g, function (w) {
    return w[0].toUpperCase() + w.slice(1).toLowerCase();
  });
  return convertedStr;
};

export const changeDateFormat = (dateInput: any): string => {
  if (dateInput) {
    const convertDate = new Date(dateInput);

    const month = convertDate.getMonth() + 1;
    const day = convertDate.getDate();
    const year = convertDate.getFullYear();

    const date = month + "/" + day + "/" + year;

    return date;
  } else return "";
};

export const getCMW3ID = (selectedSA: any,brandName = ""): string => {
  let w3id = "";
  selectedSA?.subscription?.map((sub: any) => {
    if (sub?.hasOwnProperty("deviceInfo")) {
      sub?.deviceInfo?.map((deviceInfo: any) => {
        if (deviceInfo?.deviceCategory === "CM" ) {
          if (
            brandName != undefined &&
            brandName?.toUpperCase() === ConfigConst?.ROGERS.toUpperCase() &&
            sub?.w3Id != undefined &&
            sub?.w3Id === ""
          )
            w3id = ConfigConst?.dummy_w3id;
          //adding dummy value 10001 for brand Rogers
          else w3id = sub?.w3Id;
        
        
        }
      });
    }
  });

  return w3id;
};


export const getCMW3ID4Brand = (selectedSA: any, brandName = ""): string => {
  let w3id = "";
  selectedSA?.subscription?.map((sub: any) => {
    if (sub?.hasOwnProperty("deviceInfo")) {
      sub?.deviceInfo?.map((deviceInfo: any) => {
        if (deviceInfo?.deviceCategory === "CM") {
          if (
            brandName != undefined &&
            brandName?.toUpperCase() === ConfigConst?.ROGERS.toUpperCase() &&
            sub?.w3Id != undefined &&
            sub?.w3Id === ""
          )
            w3id = ConfigConst?.dummy_w3id;
          //adding dummy value 10001 for brand Rogers
          else w3id = sub?.w3Id;
        }
      });
    }
  });

  return w3id;
};

export const faultCodes = () => {
  const labels = AppLabels();
  return [
    "1A - " + labels.Og_Activity_Nff,
    "1B - " + labels.Service_Ref_Nff,
    "1C - " + labels.Dm_Mtce_Nff,
    "1D - " + labels.Pm_Mtce_Nff,
    "1E - " + labels.No_Fwd_Signal,
    "1F - " + labels.No_Rtn_Signal,
    "1G - " + labels.No_Fwd_Ret_Signal,
    "1H - " + labels.Fwd_Signal_Level_Oos,
    "1I - " + labels.Rtn_Sigan_Level_Oos,
    "1J - " + labels.Fwd_Ret_Signal_Level_Oos,
    "1K - " + labels.Impair_Dst_Fwd_S,
    "1L - " + labels.Signal_Leak_Oos,
    "1M - " + labels.Rtn_Noise_Ingress,
    "1N - " + labels.Gen_Equip_Hous_Dmg,
    "1O - " + labels.Smt_Alarm,
    "1P - " + labels.He_Provi_Source,
    "1Q - " + labels.Power_Supply_In_Standby,
    "1R - " + labels.Power_Supply_Fault,
    "1Z - " + labels.Other_Fault_Fnd,
  ];
};

export const networkCodes = () => {
  const labels = AppLabels();
  return [
    "2A - " + labels.Head_End,
    "2B - " + labels.Fibre_Able,
    "2C - " + labels.Fiber_Connector,
    "2D - " + labels.Node_Rf_I_F_Cable,
    "2E - " + labels.Node_Optical_Rec,
    "2F - " + labels.Node_Optical_Trans,
    "2G - " + labels.Node_Rf_Mod,
    "2H - " + labels.Node_Housing,
    "2I - " + labels.Trunk_Cable,
    "2J - " + labels.Trunk_Amplifier,
    "2K - " + labels.Trunk_Cn_Cu_Sp_Pi,
    "2L - " + labels.Trunk_Prm_Pwr_Sply,
    "2M - " + labels.Trunk_Upd_Pwr_Sply,
    "2N - " + labels.Dist_Cable,
    "2O - " + labels.Dist_Line_Extender,
    "2P - " + labels.Dist_Connec_Cplrs_Split,
    "2Q - " + labels.Mulittap_Plate,
    "2R - " + labels.Multitap_Housing,
    "2S - " + labels.Drop_Cable,
    "2T - " + labels.Drop_Amplifier,
    "2U - " + labels.Drop_Connector,
    "2V - " + labels.Cse,
    "2W - " + labels.Int_Cab_Spltter_Wallp,
    "2X - " + labels.Rogers_Htd,
    "2Y - " + labels.Customer_Equip,
    "2Z - " + labels.Other_Fault_Loc,
    "6A - " + labels.Lobby_Cam_R_O,
    "6B - " + labels.Lobby_Cam_C_O,
    "6C - " + labels.Traffic_Light,
  ];
};

export const activityCodes = () => {
  const labels = AppLabels();
  return [
    "3A - " + labels.Replaced_Net_Asset,
    "3B - " + labels.Repaired_Net_Asset,
    "3C - " + labels.Tightened_Equip,
    "3D - " + labels.Ran_Temp_Line,
    "3E - " + labels.Adj_Signal_Level,
    "3F - " + labels.ReSetup_ReAlign,
    "3G - " + labels.Cable_Spliced,
    "3H - " + labels.Inst_Noise_Filter,
    "3I - " + labels.Installed_Lkg_Pad,
    "3J - " + labels.Inst_Drop_Amp,
    "3K - " + labels.OnSite_Verify,
    "3L - " + labels.Cable_Located,
    "3M - " + labels.Worked_On_S_P,
    "3N - " + labels.Assisted_Tech,
    "3O - " + labels.Pwrg_Pwr_Supply,
    "3P - " + labels.Met_W_Contractor,
    "3Q - " + labels.Id_Esc_Fault_Fnd,
    "3R - " + labels.Exchange_Transponder,
    "3S - " + labels.Installed_Portable_Generator,
    "3T - " + labels.Generator_Missing_Or_Vandalized,
    "3Z - " + labels.Oth_Activity_Perf,
  ];
};

export const causeCodes = () => {
  const labels = AppLabels();
  return [
    "4A - " + labels.Acc_Pprt_Damage,
    "4B - " + labels.Vandalism,
    "4C - " + labels.Env_Conditions,
    "4D - " + labels.Ex_Equip_Degrad,
    "4E - " + labels.Moisture,
    "4F - " + labels.Plant_Install_Nts,
    "4G - " + labels.Plant_Locate_Nts,
    "4H - " + labels.Plant_Funct_Nts,
    "4I - " + labels.Plant_Design_Nts,
    "4J - " + labels.Pre_Plnt_Repr_Nts,
    "4K - " + labels.Recent_Consnts,
    "4L - " + labels.Recent_Rebuild_Nts,
    "4M - " + labels.Rec_Inst_Equip_Fail,
    "4N - " + labels.Comp_Alignmnt_Oos,
    "4O - " + labels.Loose_Conn,
    "4P - " + labels.Corroded_Conn,
    "4Q - " + labels.Grdng_Short_Fault,
    "4R - " + labels.Cut_Cable,
    "4S - " + labels.Crack_Damaged_Cable,
    "4T - " + labels.Cable_Bending_Oos,
    "4U - " + labels.Prim_Pwr_Og_Oos,
    "4V - " + labels.Bkup_Pwr_Og_Oos,
    "4W - " + labels.Blow_Fuse_Ac_Brkr,
    "4X - " + labels.Cause_Unknown,
    "4Z - " + labels.Oth_Cause_Determ,
  ];
};

export const preventionCodes = () => {
  const labels = AppLabels();
  return [
    "5A - " + labels.Bet_Prot_Of_Equip,
    "5B - " + labels.Waterproof_Equip,
    "5C - " + labels.Early_Prev_Diagnos,
    "5D - " + labels.Accurate_Loc_Activ,
    "5E - " + labels.Bet_Dsgn_Of_Plant,
    "5F - " + labels.Better_Grounding,
    "5G - " + labels.Better_Maint_Batt,
    "5H - " + labels.Bet_Loc_Of_Equip,
    "5I - " + labels.Ftr_Service_Tech,
    "5J - " + labels.Ftr_By_Constr,
    "5K - " + labels.Ftr_By_Maint,
    "5L - " + labels.Ftr_By_Maint_Contr,
    "5M - " + labels.Not_A_Mtce_Issue,
    "5Z - " + labels.Other_Prevention,
  ];
};

export const additionalCodes = () => {
  const labels = AppLabels();
  return [
    "RE  - " + labels.Re,
    "RS - " + labels.Rs,
    "RH - " + labels.Rh,
    "RC - " + labels.Rc,
    "RR - " + labels.Rr,
    "NS - " + labels.Rn,
    "Y1 - " + labels.Tracking_Code_1,
    "Y2 - " + labels.Tracking_Code_2,
    "Y3 - " + labels.Tracking_Code_3,
    "Y4 - " + labels.Tracking_Code_4,
    "Y5 - " + labels.Tracking_Code_5,
    "OC - " + labels.Recep_Prob_OutageCanc_Via_Mdi,
    "ON - " + labels.Recep_Prob_Outage_Not_Verif,
    "OV - " + labels.Recep_Prob_Outage_Verified,
  ];
};

export const getPolylineCoordinates = (featuresData: any) => {
  let tempCoordinates: any;
  let filteredCoordinates: any = [];

  featuresData?.geometry?.coordinates?.map((coordinates: any) => {
    tempCoordinates = { lat: coordinates[1], lng: coordinates[0] };
    filteredCoordinates.push(tempCoordinates);
  });
  return filteredCoordinates;
};

export const getByKey = (arr: any[], key: string | number) => {
  return arr?.find((m: any) => m?.key === key);
};
export interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export const convertCMData = (key: any, value: any) => {
  let computedValue: any = value;

  if (key && value) {
    switch (key) {
      case "timestamp":
      case "firstFlap":
      case "lastFlap":
        computedValue = changeTimeFormat(value);
        break;
      case "uptime":
      case "registered time":
      case "cmts sys uptime":
        computedValue = secToTime(value);
        break;
      case "address":
        computedValue = getHomeAddress(value);
        break;
      case "upstream bitrate (mbps)":
      case "downstream bitrate (mbps)":
        computedValue = formatBitsToSize(value);
        break;
      case "usPs":
        computedValue = getPartialServiceFlag(value, "US");
        break;
      case "dsPs":
        computedValue = getPartialServiceFlag(value, "DS");
        break;
    }
  }
  return computedValue;
};
export const convertHealthData = (key: any, value: any, labels: any) => {
  // const labels = AppLabels();
  if (key && value) {
    switch (key) {
      case labels.ACTIVE + " / " + labels.ASSIGNED:
      case labels.RESPONSIVE + " / " + labels.UNRESPONSIVE:
      case labels.Start_Time:
      case labels.End_Time:
        return value;
      case labels.Overall:
      case labels.US:
      case labels.DS:
        return Array.isArray(value)
          ? value[0] === undefined
            ? labels.NA
            : getHealthColors(value)
          : value === undefined
          ? labels.NA
          : getHealthColors(value);

      case labels.Breakdown:
        return value[0] === undefined ? labels.NA : getBreakdownColors(value);
    }
  }
  return value;
};
export const odmScoreChips = (key: any, value: any) => {
  var style = "";
  if (key !== undefined && value !== undefined) {
    switch (key) {
      case "US":
      case "DS":
      case "OFDM":
      case "OFDMA":
        style = "score";
        return value === undefined ? (
          "N/A"
        ) : (
          <span
            key={`${Math.random()}chip_${key}`}
            className={"scoreChip " + style}
          >
            {value}
          </span>
        );

      case "Failed CM":
        const failedCMValue = value !== undefined ? value?.split("/")?.[0] : 0;
        style = "odmChip";
        style += failedCMValue > 4 ? " failChip" : " passChip";
        return value === undefined ? (
          "N/A"
        ) : (
          <span
            key={`${Math.random()}chip_${key}`}
            className={"scoreChip " + style}
          >
            {value}
          </span>
        );
      case "ODM":
        style = "odmChip";
        style += value > 90 ? " passChip" : " failChip";
        return value === undefined ? (
          "N/A"
        ) : (
          <span
            key={`${Math.random()}chip_${key}`}
            className={"scoreChip " + style}
          >
            {value}%
          </span>
        );
    }
  }
  return value;
};
export const getBreakdownColors = (value: any) => {
  if (value !== null && value !== undefined) {
    return value?.map((val: any, index: number) => {
      var style = "";
      switch (true) {
        case index === 0:
          style = "passChip";
          break;
        case index === 1:
          style = "marginalChip";
          break;
        case index === 2:
          style = "failChip";
          break;
      }
      return (
        <span key={val} className={"scoreChip " + style}>
          {val}%
        </span>
      );
    });
  } else {
    return "";
  }
};

export const getHealthColors = (value: any) => {
  if (value !== null && value !== undefined && Array.isArray(value)) {
    return value?.map((val: any, i: number) => {
      var style = "";
      switch (true) {
        case val >= 90:
          style = "passChip";
          break;
        case 90 > val:
          style = "failChip";
          break;
      }
      return (
        <span
          key={`${Math.random()}chip_${i}`}
          className={"scoreChip " + style}
        >
          {val}%
        </span>
      );
    });
  } else if (value !== null && value !== undefined && !Array.isArray(value)) {
    var style = "";
    switch (true) {
      case value >= 90:
        style = "passChip";
        break;
      case 90 > value:
        style = "failChip";
        break;
    }
    return (
      <span key={value} className={"scoreChip " + style}>
        {value}%
      </span>
    );
  } else {
    return "";
  }
};
export function getThrValueSpan(checkData: any) {
  if (checkData !== null && checkData?.hasOwnProperty("value")) {
    if (
      checkData?.hasOwnProperty("status") &&
      checkData?.status?.toLowerCase() === appConst.fail
    ) {
      return <span className="failSpan">{checkData.value}</span>;
    } else if (
      checkData?.hasOwnProperty("status") &&
      checkData?.status === appConst.marginal
    ) {
      return <span className="marginalSpan">{checkData.value}</span>;
    } else return <span>{checkData.value}</span>;
  } else {
    return "";
  }
}

export const getChCWValue = (cwData: any, chName: string) => {
  if (cwData && chName) {
    return cwData?.find((ch: any) => ch?.frequency === chName);
  }
};

export const getShapeNameForChartDot = (chAttName: any) => {
  let dotShape: any = appConst.circle;
  if (chAttName) {
    switch (chAttName) {
      case ConfigConst?.ofdmaTx?.toUpperCase():
      case ConfigConst?.ofdmaPct?.toUpperCase():
      case ConfigConst?.ofdm33Rx?.toUpperCase():
      case ConfigConst?.ofdm34Rx?.toUpperCase():
      case ConfigConst?.ofdm33Pct?.toUpperCase():
      case ConfigConst?.ofdm34Pct?.toUpperCase():
        dotShape = appConst.diamond;
        break;
    }
  }
  return dotShape;
};

export const getColorForChartDot = (chAttName: any, chData: any) => {
  let dotColor: any = colors.black70;
  if (chAttName && chData) {
    let attStatus = "";
    switch (chAttName) {
      case ConfigConst?.scQamTx?.toUpperCase():
        attStatus = chData?.txPower?.status;
        break;
      case ConfigConst?.scQamRx?.toUpperCase():
        attStatus = chData?.rx_power?.status;
        break;
      case ConfigConst?.scQamSnr?.toUpperCase():
        attStatus = chData?.snr?.status;
        break;
      case ConfigConst?.ofdmaTx?.toUpperCase():
        attStatus = chData?.ofdmaTx?.status;
        break;
      case ConfigConst?.ofdmaPct?.toUpperCase():
        attStatus = chData?.ofdmaPct?.status;
        break;
      case ConfigConst?.ofdm33Rx?.toUpperCase():
        attStatus = chData?.ofdm33Rx?.status;
        break;
      case ConfigConst?.ofdm34Rx?.toUpperCase():
        attStatus = chData?.ofdm34Rx?.status;
        break;
      case ConfigConst?.ofdm33Pct?.toUpperCase():
        attStatus = chData?.ofdm33Pct?.status;
        break;
      case ConfigConst?.ofdm34Pct?.toUpperCase():
        attStatus = chData?.ofdm34Pct?.status;
        break;
    }
    if (attStatus !== "") {
      switch (attStatus?.toLowerCase()) {
        case appConst.pass:
          dotColor = colors.green;
          break;
        case appConst.marginal.toLowerCase():
          dotColor = colors.marginal;
          break;
        case appConst.fail:
          dotColor = colors.danger;
          break;
      }
    }
  }
  return dotColor;
};

export const getThrColoredTableCell = (
  cellValue: any,
  cellKey: any,
  colorFlag = false
) => {
  return (
    <td
      key={cellKey}
      className={
        cellValue?.status?.toLowerCase() === appConst.fail
          ? "failedText"
          : cellValue?.status === appConst.marginal
          ? "marginalText"
          : colorFlag
          ? "passText"
          : ""
      }
      style={{ fontWeight: Number(`var(--topoTDFN)`) }}
    >
      {typeof cellValue === "object"
        ? isNull(cellValue?.value)
        : isNull(cellValue)}
    </td>
  );
};

export const getMinMaxRange = (data: any, chName: string) => {
  if (data && !data?.errorCode && chName) {
    switch (chName) {
      case "us":
        let d30UsData = data?.docsis30?.upstream;
        let d31UsData = data?.ofdm?.upstream?.channels;
        let minUsVals = {
          freq: parseFloat(d30UsData?.channels?.[0].frequency),
          power: parseFloat(d30UsData?.minTxPower?.value),
          noise: parseFloat(d30UsData?.minSnr?.value),
        };
        let maxUSVals = {
          freq: parseFloat(d30UsData?.channels?.[0].frequency),
          power: parseFloat(d30UsData?.maxTxPower?.value),
          noise: parseFloat(d30UsData?.maxSnr?.value),
        };
        d30UsData?.channels?.map((usCh: any) => {
          if (parseFloat(usCh?.frequency) < minUsVals?.freq) {
            minUsVals.freq = parseFloat(usCh?.frequency);
          }
          if (parseFloat(usCh?.frequency) > maxUSVals?.freq) {
            maxUSVals.freq = parseFloat(usCh?.frequency);
          }
        });
        d31UsData?.map((ch: any) => {
          if (parseFloat(ch?.freqLowerBoundary) < minUsVals?.freq) {
            minUsVals.freq = parseFloat(ch?.freqLowerBoundary);
          }
          if (parseFloat(ch?.freqUpperBoundary) > maxUSVals?.freq) {
            maxUSVals.freq = parseFloat(ch?.freqUpperBoundary);
          }
          if (parseFloat(ch?.txPower?.value) < minUsVals?.power) {
            minUsVals.power = parseFloat(ch?.txPower?.value);
          } else if (parseFloat(ch?.txPower?.value) > maxUSVals?.power) {
            maxUSVals.power = parseFloat(ch?.txPower?.value);
          }
          if (parseFloat(ch?.mer?.thrVal?.value) < minUsVals?.noise) {
            minUsVals.noise = parseFloat(ch?.mer?.thrVal?.value);
          } else if (parseFloat(ch?.mer?.thrVal?.value) > maxUSVals?.noise) {
            maxUSVals.noise = parseFloat(ch?.mer?.thrVal?.value);
          }
        });
        return { minVals: minUsVals, maxVals: maxUSVals };
        break;
      case "ds":
        let d30DsData = data?.docsis30?.downstream;
        let d31DsData = data?.ofdm?.downstreamChannels;
        let minDsVals = {
          freq: parseFloat(d30DsData?.minFrequency),
          power: parseFloat(d30DsData?.minRxPower?.value),
          noise: parseFloat(d30DsData?.minSnr?.value),
        };
        let maxDsVals = {
          freq: parseFloat(d30DsData?.maxFrequency),
          power: parseFloat(d30DsData?.maxRxPower?.value),
          noise: parseFloat(d30DsData?.maxSnr?.value),
        };
        d31DsData?.map((ch: any) => {
          if (hzToMHz(ch?.lowerFreq) < minDsVals?.freq) {
            minDsVals.freq = hzToMHz(ch?.lowerFreq);
          }
          if (hzToMHz(ch?.upperFreq) > maxDsVals?.freq) {
            maxDsVals.freq = hzToMHz(ch?.upperFreq);
          }
          if (parseFloat(ch?.minRxPower?.value) < minDsVals?.power) {
            minDsVals.power = parseFloat(ch?.minRxPower?.value);
          }
          if (parseFloat(ch?.maxRxPower?.value) > maxDsVals?.power) {
            maxDsVals.power = parseFloat(ch?.maxRxPower?.value);
          }
          if (parseFloat(ch?.mer?.thrVal?.value) < minDsVals?.noise) {
            minDsVals.noise = parseFloat(ch?.mer?.thrVal?.value);
          } else if (parseFloat(ch?.mer?.thrVal?.value) > maxDsVals?.noise) {
            maxDsVals.noise = parseFloat(ch?.mer?.thrVal?.value);
          }
        });
        return { minVals: minDsVals, maxVals: maxDsVals };
        break;
    }
  }
};

export const checkRoles = (roles: any): boolean => {
  if (roles && roles.length > 0) {
    return getAccessLevelByRoles(roles).includes(ConfigConst.MT);
  }
  return false;
};

export const usInfterfaceDisplay = (
  usInt: any,
  phub: string,
  cmtsIp: string
) => {
  let init = -1;
  let fin = -1;

  let channels = [];
  let prefix = "";
  let postfix = "";
  const corrFormat = usInt?.indexOf("[") !== -1;
  if (usInt) {
    if (corrFormat) {
      // More than 1 channels
      init = usInt?.indexOf("[");
      fin = usInt?.indexOf("]");

      prefix = usInt?.substr(0, init);
      channels = usInt?.substr(init + 1, fin - init - 1)?.split(",");
      postfix = usInt?.substr(fin + 1, 2);
    } else {
      // Just 1 channel
      init = usInt?.indexOf(".");
      fin = init + 2;

      prefix = usInt?.substr(0, init) + "/";
      channels?.push(usInt?.substr(init + 1, 1));
      postfix = "/" + usInt?.substr(init + 3, 1);
    }

    const ofdmaLink =
      GLOBAL_CONST.f2Link +
      "/make_graph_bonded_profile_v3.cgi" +
      "?phub=" +
      phub +
      "&ip=" +
      cmtsIp +
      "&port=c" +
      prefix.slice(0, -1) +
      "&upstream=c" +
      prefix +
      usInt?.slice(-1);

    return (
      <div key={"usInterfaceDisp"}>
        <span>{usInt?.substr(0, init + 1)}</span>
        {channels?.map(function (ch: any, index: number) {
          const scqamLink =
            GLOBAL_CONST.f2Link +
            "/make_graph_v3.cgi" +
            "?phub=" +
            phub +
            "&ip=" +
            cmtsIp +
            "&upstream=c" +
            prefix +
            ch +
            postfix;
          return (
            <span key={ch + index}>
              <a
                key={ch + index * index + 55}
                title="F2 Graph SCQAM Interface"
                target="_blank"
                href={scqamLink}
                rel="noreferrer"
              >
                {(index ? "," : "") + ch}
              </a>
            </span>
          );
        })}
        <span>
          {corrFormat ? usInt?.substr(fin, 2) : usInt?.substr(fin, 1)}
        </span>
        <span>
          <a
            title="F2 Graph OFDMA Interface"
            target="_blank"
            href={ofdmaLink}
            rel="noreferrer"
          >
            {usInt?.slice(-1)}
          </a>
        </span>
      </div>
    );
  } else return "";
};

export const isMacAddressValid = (inputtxt: any) => {
  let mac = formatMacAddress(inputtxt);
  if (mac?.length === 12) {
    return true;
  } else {
    return false;
  }
};

export const isRPDMacAddressValid = (inputtxt: any) => {
  let mac = formatMacAddress(inputtxt);
  let newmac = mac.replace(/[^a-fA-F0-9]/g, "");
  if (newmac?.length === 12) {
    return true;
  } else {
    return false;
  }
};

export const formatRPDMacAddress = (inputtxt: any) => {
  let mac = formatMacAddress(inputtxt);
  if (mac?.length === 12) {
    var stringMacAdd = "";
    for (var counter: number = 0; counter < 12; counter++) {
      if (counter == 4 || counter == 8) stringMacAdd = stringMacAdd + ".";
      stringMacAdd = stringMacAdd + mac[counter];
    }
    return stringMacAdd;
  } else {
    return "";
  }
};

export const formathexMacAddress = (inputtxt: any) => {
  let mac = formatMacAddress(inputtxt);
  if (mac?.length === 12) {
    var stringMacAdd = "";
    for (var counter: number = 0; counter < 12; counter++) {
      if (
        counter == 2 ||
        counter == 4 ||
        counter == 6 ||
        counter == 8 ||
        counter == 10
      )
        stringMacAdd = stringMacAdd + ":";
      stringMacAdd = stringMacAdd + mac[counter];
    }
    return stringMacAdd;
  } else {
    return "";
  }
};

export const isValidNodeId = (inputtxt: any) => {
  if (inputtxt !== undefined && inputtxt?.length === 6) {
    return true;
  } else {
    return false;
  }
};

export const formatNodeId = (nodeId: string): string => {
  return nodeId ? nodeId.replace(/[^0-9]/g, "") : "";
};
export function debounce(fn: Function, ms: number) {
  let timer: any;
  return (_: any, ...args: any) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(Function, args);
    }, ms);
  };
}
export function capitalize(word: any) {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

export function getCMMac(sortedDevices: any) {
  let mac: any[] = [];
  sortedDevices?.map((device: any) => {
    if (device?.deviceCategory === "CM") mac.push(device?.macaddress);
  });
  return mac;
}

export function hasOnlySTB(sortedDevices: any[]): any {
  let isOnly = true;
  sortedDevices?.forEach((device: any) => {
    if (
      device?.deviceCategory !== "STB" &&
      device?.deviceCategory !== "DESTB" &&
      device?.deviceCategory !== "GSTB" &&
      device?.deviceCategory !== "HSTB" &&
      device?.deviceCategory !== "IPSTB" &&
      device?.deviceCategory !== "ASTB"
    ) {
      isOnly = false;
    }
  });
  return isOnly;
}

export function getSTBMac(sortedDevices: any) {
  let mac: any[] = [];
  sortedDevices?.map((device: any) => {
    if (
      device?.deviceCategory === "STB" ||
      device?.deviceCategory === "DESTB" ||
      device?.deviceCategory === "GSTB" ||
      device?.deviceCategory === "HSTB" ||
      device?.deviceCategory === "IPSTB" ||
      device?.deviceCategory === "ASTB"
    )
      mac.push(device?.macaddress);
  });
  return mac;
}

export function hasOnlyEMTA(sortedDevices: any[]): any {
  let isOnly = true;
  sortedDevices?.forEach((device: any) => {
    if (device?.deviceCategory !== "EMTA") {
      isOnly = false;
    }
  });
  return isOnly;
}

export function getEMTAMac(sortedDevices: any) {
  let mac: any[] = [];
  sortedDevices?.map((device: any) => {
    if (device?.deviceCategory === "EMTA") mac.push(device?.macaddress);
  });
  return mac;
}

// export function noiseEncryption(data: any) {
//   const key = "gUkXp2r5u8x/A?D(G+KbPeShVmYq3t6v";
//   const iv = CryptoJS.enc.Hex.parse("01020304050606050403020107070707");

//   const enc = CryptoJS.AES.encrypt(data, key, {
//     iv: iv,
//     mode: CryptoJS.mode.CBC,
//     padding: CryptoJS.pad.Pkcs7,
//   });

//   return enc.ciphertext.toString(CryptoJS.enc.Base64);
// }

export const checkWOStatusUpdateAllowed = (
  woID: string,
  status: string,
  woList: any
) => {
  let allowedFlag = true;
  if (status === ConfigConst.Enroute || status === ConfigConst.Onsite) {
    const activeWO = woList?.filter(
      (wo: any) =>
        wo?.status === ConfigConst.Enroute || wo?.status === ConfigConst.Onsite
    );
    if (activeWO?.length > 0 && activeWO[0]?.id !== woID) {
      allowedFlag = false;
    }
  }
  return allowedFlag;
};

export const getDeviceData = (deviceType: string) => {
  const labels = AppLabels();
  if (deviceType) {
    switch (deviceType) {
      case labels.gpon_ont_query:
        return [
          {
            ONT: "Optical Network Terminal, ONT at the customer premise converts the optical signal to IP",
            "Serial Number":
              "ONT Serial Number, it is a unique identifier assigned  to an ONT",
            Node: "Splitter RENS#",
            "Street Number and Name": "Subscriber address information​",
            "Company and Account Number​": "Subscriber account information​",
            "Equipment ID​": "ONT ID",
            "SW Version​": "ONT software version​",
            Model: "ONT model type​",
            Vendor: "Vendor for the ONT​",
          },
        ];
      case labels.ont_topology_data:
        return [
          {
            Name: "Subscriber name (Not available, at this moment)​",
            Account: "Subscriber account information​",
            Address: "Subscriber address information​",
            PHUB: "Site ID in Remedy​​",
            Node: "Splitter RENS#",
          },
        ];
      case labels.ont_oper_data:
        return [
          {
            "ONT Number​":
              "ONT number, Rack/Subrack/LT/PON/ONT number, e.g: 1/1/4/5/1, Rack 1/Subrack 1/LT 4/PON 5/ONT 1​​",
            "Optical Distance (km)​": "Subscriber account information​",
            "Optical Tx (dBm)​":
              "ONT Tx Optical Signal Level; +3dBm to +9dBm​​",
            "Optical Rx (dBm)​":
              "ONT Rx Optical Signal Level; -14dBm to -26dBm​​",
            "Reporting State​": [
              "ONT Reporting status",
              "none - no issue​",
              "dg - dyeing gasp - no power​",
              "inact - inactive​",
              "los - loss of signal​",
              "SD - Signal Degrade​",
            ],
            "Operational Status​": [
              "ONT operational status or running status ​",
              "up - ONT is up online​",
              "down - ONT is offline​",
            ],
            "Admin Status​": [
              "ONT uplink port to OLT admin status;​",
              "up - ONT configured state is up, manually enabled​",
              "down - ONT configured state is down, manually shutdown​",
            ],
            "Last Change Time​": "ONT Status, Last Change Date​",
          },
        ];
      case labels.ont_ethernet_port_oper_data:
        return [
          {
            "Ethernet Port Current Status​":
              "ONT Ethernet port current state; e.g. No Defect​​",
            "Ethernet Port Operational Status​": [
              "ONT Ethernet port operational status or running status;​​",
              "up - ONT ethernet port facing to RG is up​",
              "down - ONT ethernet port facing to RG is down​",
            ],
            "Ethernet Port Admin Status​": [
              "ONT Ethernet port facing to RG administrative status;​​",
              "up - ONT ethernet port configured state is up, manually enabled​",
              "down - ONT ethernet port configured state is down, manually shutdown​",
            ],
            "Ethernet Port Config Indicator​":
              "ONT Ethernet port configuration Status; e.g. gigEthFD = 1000 Base-T (Full Duplex)​​​",
          },
        ];
      case labels.pon_port_oper_data:
        return [
          {
            "PON Port​":
              "OLT PON port, Rack/Subrack/LT/PON; e.g: 1/1/3/8, Rack 1/Subrack 1/LT 3/PON 8​",
            "Operational Status​":
              "PON port Operational State or PON port running status; the PON port could be Up or Down​​",
            "Admin Status​": [
              "PON port Administrative State or PON port configured status;​​",
              "up -  PON port configured state is up, manually enable​",
              "down - PON port configured state is down, manually shutdown​",
            ],
            "Port Tx Optical Signal Level (dBm)​":
              "PON port OLT-Side Optical Signal Level(Tx);  0dB to +4dB​​​",
            "Port Rx Optical Signal Level (dBm)​":
              'Not available.  Rx level at PON port are calculated uniquely for each ONT configured on single PON port in a separate table. This table can be checked via CLI access using " show equipment ont status pon " command;  - 26dB  to -18dB,​',
            "Last Operational Status Change​":
              "PON port status: Last Change Date​",
          },
        ];
      case labels.olt_oper_data:
        return [
          {
            OLT: "Optical Line Terminal, the OLT will be used to provide PON services and traffic between the network and the XGS-PON ONT. In addition, the OLT is a network access device that provides IP services(voice, data, and video) to subscribers using the XGS Passive Optical Network (XGS-PON) technology​​",
            "OLT Name​": "OLT device name​​",
            "Slot Name​":
              "OLT LT(Line Termination) card info, e.g. 1/1/3, Rack 1/Subrack 1/LT 3​​",
            "LT Card Admin Status​": [
              "OLT LT card admin(configured) status​​​",
              "unlock - LT card has been manually configured up​",
              "Locked - LT card is in manually shutdown status​",
            ],
            "OLT LT card operational status​": [
              "OLT LT card operational(running) status​",
              "enable  - LT card is up​",
              "down - LT card is down​",
            ],
            "LT Card Operational Error​": [
              "OLT LT card error-status​​",
              "no-error means card clean without errors​",
            ],
            "LT Card Last Restart Cause​": [
              "OLT LT card last time restart cause;​",
              "Warm Reset​",
              "Power on​",
            ],
            "LT Card Available Status​": [
              "OLT LT card available status; ​",
              "Available - LT card installed​",
              "not-installed  - no LT card​",
            ],
            "LT Card Power Status​": [
              "OLT LT card  Power Status;​",
              "Power up​",
              "Power down​",
            ],
            "LT Card Last Restart Time​": "OLT LT card last restart time​",
          },
        ];
    }
  }
  return undefined;
};

export const isAccDiaDeviceEligible = (selectedSA: any): boolean => {
  const deviceType = ["EMTA", "IEMTA"];
  let isEMTAdevice: boolean = false;
  selectedSA?.subscription?.map((sub: any) => {
    if (sub?.hasOwnProperty("deviceInfo")) {
      sub?.deviceInfo?.map((deviceInfo: any) => {
        if (deviceType.includes(deviceInfo?.deviceCategory)) {
          isEMTAdevice = true;
        }
      });
    }
  });

  return isEMTAdevice;
};

export const getDiagTypeByLevel = (levelVal: string) => {
  let diagType = "full";
  // levelVal === "full" ? "all" : levelVal === "basic" ? "summary" : undefined;
  if (levelVal) {
    switch (levelVal) {
      case "full":
        diagType = "all";
        break;
      case "full w/o cw":
        diagType = "all w/o cw";
        break;
      case "basic":
        diagType = "summary";
        break;
    }
  }
  return diagType;
};

export const getModemTypeForTele = (searchURI: string) => {
  let modemType = "";
  let screenType = "";
  if (searchURI) {
    switch (searchURI) {
      case "/node/emta":
        modemType = ConfigConst.emta;
        screenType = ConfigConst.emta;
        break;
      case "/node/stb":
        modemType = ConfigConst.oldstb;
        screenType = ConfigConst.stb;
        break;
      case "/node/ecm":
        modemType = ConfigConst.ecmstb;
        screenType = ConfigConst.ecm;
        break;
    }
  }
  return {
    modemType: modemType,
    screenType: screenType,
  };
};

export const isIgniteAccount = (account: any) => {
  return (
    account?.brand?.toUpperCase() == ConfigConst?.ROGERS?.toUpperCase() &&
    account?.accountNumber?.length === 9
  );
};

export const getChartTicks = (minVal: any, maxVal: any, divGap: number) => {
  let ticks: any = [];
  const tickCount = Math.ceil((maxVal - minVal) / divGap);

  for (let i = 0; i <= tickCount; i++) {
    if (i === 0) {
      ticks.push(Math.floor(minVal));
    } else {
      ticks.push(Math.floor(minVal) + divGap * i);
    }
  }

  if (ticks?.length > 0 && ticks[ticks.length - 1] < maxVal) {
    ticks.push(ticks[ticks.length - 1] + divGap);
  }
  return ticks;
};

interface GetAvailableTRType {
  name: string;
  value: string;
}

export const GetAvailableTR = ({ name, value }: GetAvailableTRType) => {
  if (value)
    return (
      <tr>
        <td>{name}</td>
        <td>{value}</td>
      </tr>
    );
  return null;
};

export const getOverlayBounds = (eleImageOverlay: any) => {
  return new google.maps.LatLngBounds(
    new google.maps.LatLng(
      eleImageOverlay?.southBound,
      eleImageOverlay?.westBound
    ),
    new google.maps.LatLng(
      eleImageOverlay?.northBound,
      eleImageOverlay?.eastBound
    )
  );
};

export const UTCtoLocalTime = (UTCTime: any) => {
  const dateOptions = {
    month: "short",
    day: "2-digit",
    year: "numeric",
  } as const;
  const timeOptions = {
    timeStyle: "short",
  } as const;

  const myDate = new Date(UTCTime).toLocaleDateString("en-US", dateOptions);
  const myTime = new Date(UTCTime).toLocaleTimeString("en-US", timeOptions);
  return { myTime, myDate };
};

//Returns array of RoleConfigType
export const getRoleConfig = (roles: any): Array<RoleConfigType> => {
  return processAccessRoleConfig(roles);
};

export const getRolesByType = (roles: any, roleType: string) => {
  const roleConfig = processAccessRoleConfig(roles);
  const accessRoles = roleConfig?.map((role) => {
    if (role?.type?.toLowerCase() === roleType?.toLowerCase()) {
      return role?.roles;
    }
  });
  return [...new Set(accessRoles?.flat())];
};

//returns list of access levels strings in an array
export const getAccessLevelByRoles = (roles: any) => {
  const accessRoles = processAccessRoleConfig(roles);
  const accessLevel = accessRoles?.map((role) => {
    if (role?.accessLevel?.length > 0) {
      return role?.accessLevel;
    }
  });
  return [...new Set(accessLevel?.flat())];
};

export const changeStatusToSkipped = (data: any) => {
  let diagResult = data?.diagResult;
  if (data && diagResult) {
    // change Legacy status to Skipped
    if (diagResult?.summary) {
      diagResult = {
        ...diagResult,
        summary: {
          ...diagResult?.summary,
          overallStatus: "Skipped",
        },
      };
    }
    if (diagResult?.docsis) {
      diagResult = {
        ...diagResult,
        docsis: {
          ...diagResult?.docsis,
          overallStatus: "Skipped",
        },
      };
    }
    // change Ignite status to Skipped
    if (diagResult?.wifi) {
      diagResult = {
        ...diagResult,
        wifi: {
          ...diagResult?.wifi,
          signalStrength: {
            ...diagResult?.wifi?.signalStrength,
            status: "Skipped",
          },
        },
      };
    }
    if (diagResult?.ethernet) {
      diagResult = {
        ...diagResult,
        ethernet: {
          ...diagResult?.ethernet,
          ethernetStatus: {
            ...diagResult?.ethernet?.ethernetStatus,
            status: "Skipped",
          },
        },
      };
    }
  }
  return { ...data, diagResult };
};

export const validateStatus = (data: any, validArr: Array<string>): any => {
  if (data === "null" || data === null || data === "" || data === " ")
    return "";
  else if (data === undefined) {
    return "N/A";
  } else if (validArr.includes(data.toLowerCase())) {
    return <span className="succes">{isNull(data)}</span>;
  } else {
    return <span className="error">{isNull(data)}</span>;
  }
};

export const getTechRoles = (techData: any) => {
  return (
    techData?.roles?.includes("admin") ||
    techData?.roles?.includes("business_admin") ||
    getAccessLevelByRoles(techData?.roles).includes(ConfigConst.TOPOLOGY)
  );
};

export const openElectroline = (serverName: String) => {
  let url = NetworkConfig.ELECTROLINE_URL?.replace(
    "<electrolineServer>",
    serverName
  );
  url = window.navigator.userAgent.includes("Edg/")
    ? url
    : "microsoft-edge:" + url;
  window.open(url, "_blank");
};

export const renderAttrTable = (
  el: {
    name: string;
    value: any;
    label: any;
  },
  type?: any
): JSX.Element => {
  let colNames: any = null;
  if (type === "Fail") {
    colNames = getFailCMTableStats(el);
  } else {
    colNames = getMarginalCMTableStats(el);
  }

  const headers = colNames?.map((name: string, i: number) => (
    <th key={i}>{name}</th>
  ));
  if (el?.label?.length === 1 && el?.label?.[0] === "Uncorrectables") {
    el = {
      ...el,
      label: ["name", "value"],
    };
  }
  const rows = el?.value?.map((v: any, i: any) => (
    <tr key={i}>
      {el?.label?.map((attr: any, j: any) => (
        <td key={j}>{v[attr]}</td>
      ))}
    </tr>
  ));
  return (
    <table className={`dataTableDetails`}>
      <thead>
        <tr>{headers}</tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};
export const getFailCMTableStats = (el: {
  name: string;
  value?: any;
  label?: any;
}) => {
  let colNames: string[];
  switch (el?.name) {
    case "UPSTREAM_SCQAM_CHANNELS":
    case "DOWNSTREAM_SCQAM_CHANNELS":
      colNames = ["Frequency", "Uncorrectables Delta"];
      break;
    case "DS_DOCSIS3.0_CHANNELS":
      colNames = ["FREQ (MHz)", "RX POWER", "SNR", "STATUS"];
      break;
    case "US_DOCSIS3.0_CHANNELS":
      colNames = ["FREQ (MHz)", "TX POWER", "SNR", "STATUS"];
      break;
    case "DS_OFDM1_CHANNELS":
    case "DS_OFDM2_CHANNELS":
      colNames = ["FREQ (MHz)", "RX POWER"];
      break;
    default:
      colNames = ["Name", "Value"];
      break;
  }
  return colNames;
};

export const getMarginalCMTableStats = (el: {
  name: string;
  value?: any;
  label?: any;
}) => {
  let colNames: string[];
  switch (el?.name) {
    case "DS_DOCSIS3.0_CHANNELS":
    case "DS_OFDM1_CHANNELS":
    case "DS_OFDM2_CHANNELS":
      colNames = ["FREQ (MHz)", "RX POWER"];
      break;
    case "US_DOCSIS3.0_CHANNELS":
      colNames = ["FREQ (MHz)", "TX POWER"];
      break;
    default:
      colNames = ["Name", "Value"];
      break;
  }
  return colNames;
};

export const isSpeedBitrateLow = (selectedSA: any) => {
  let hsiPlanInfo: any = [];
  selectedSA?.subscription?.map((sub: any) => {
    if (sub?.planInfoList?.planInfo !== undefined) {
      sub.planInfoList.planInfo?.map((pInfo: any) => {
        if (pInfo?.productType === "HSI" || pInfo?.productType === "I")
          hsiPlanInfo = pInfo;
      });
    }
  });
  return (
    hsiPlanInfo?.orderedDownstream <= 5 || hsiPlanInfo?.orderedUpstream <= 5
  );
};

export const isDeviceOffline = (apiResponse: any) => {
  const offlineCodes = ["1009", "1205"];
  return offlineCodes?.includes(apiResponse?.errorCode);
};

export const getDeviceType = (deviceCode: string) => {
  switch (deviceCode.toLowerCase()) {
    case "casa-rpd-100":
      return "DA2000";
    case "casa-rpd-200":
      return "DA2200";
    default:
      return deviceCode;
  }
};