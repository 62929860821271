import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { InfoBox } from "@react-google-maps/api";
import React from "react";

interface Props {
  nodeBlockData: any;
}
const MduNodeBlock = React.memo(({ nodeBlockData }: Props) => {
  const latLng = new google.maps.LatLng(
    nodeBlockData?.geometry?.coordinates?.[1],
    nodeBlockData?.geometry?.coordinates?.[0]
  );

  return (
    <>
      <InfoBox
        position={latLng}
        options={{
          closeBoxURL: "",
          disableAutoPan: true,
          boxStyle: {
            width: "auto",
            padding: "5px",
          },
        }}
      >
        <Table className="annotationBlock">
          <TableBody>
            <TableRow>
              <TableCell>
                {nodeBlockData?.properties?.data?.tagText?.RF_DVC_ID}
              </TableCell>
              <TableCell>
                {nodeBlockData?.properties?.data?.tagText?.RF_PART_NO}
              </TableCell>
              <TableCell>
                {nodeBlockData?.properties?.data?.tagText?.RF_DGN_FREQ3}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {nodeBlockData?.properties?.data?.tagText?.FIB_DVC_ID}
              </TableCell>
              <TableCell>
                {nodeBlockData?.properties?.data?.tagText?.FIB_PART_NO}
              </TableCell>
              <TableCell>
                {nodeBlockData?.properties?.data?.tagText?.FIB_DGN_FREQ3}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                {nodeBlockData?.properties?.data?.tagText?.PS_ID}
              </TableCell>
              <TableCell>
                {nodeBlockData?.properties?.data?.tagText?.VOLTS}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={2}>
                <div>{nodeBlockData?.properties?.data?.tagText?.FIB_LINK}</div>
                <div>{nodeBlockData?.properties?.data?.tagText?.FIB_TYPE}</div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </InfoBox>
    </>
  );
});

export default MduNodeBlock;
