import { FormControl, InputGroup } from "react-bootstrap";
import { BiDownload } from "react-icons/bi";
import SearchIcon from "@material-ui/icons/Search";
import { useEffect, useState } from "react";

import { formatBytesToSize, isNull, UTCtoLocalTime } from "../../components/reusable/Util";
import FileTypeIcons from "../../components/reusable/FileTypeIcons";
import AppLabels from "../../constants/App_Labels";

interface Props {
    headers: string[];
    files: any[];
    className: string;
    handleDownload: any;
}
const SimpleListView = ({ headers, files, className, handleDownload }: Props) => {
    const labels = AppLabels();
    const [searchValue, setSearchValue] = useState("");
    const [fileList, setFileList] = useState<any[]>(files || []);

    const handleFileSearch = (e: any) => {
        setSearchValue(e.target.value);
        if (files !== undefined && files?.length !== 0) {
            const filteredList = files &&
                files?.filter((file: any) => file?.fileName?.toLowerCase()?.includes(e.target.value?.toLowerCase()));
            setFileList(filteredList);
        }
    };

    const searchBar = () => {
        return <div className="fileSearch">
            <InputGroup>
                <button className="searchWOBtn">
                    <SearchIcon />
                </button>
                <FormControl
                    placeholder={labels.Filter_By_File_Name}
                    className="searchInput-WO"
                    value={searchValue}
                    onChange={(e) => handleFileSearch(e)}
                />
            </InputGroup>
        </div>
    }

    const getFileExtension = (filename: string) => {
        const parts = filename.split(".");
        const extension = parts[parts.length - 1];
        return extension.toLowerCase();
    }

    useEffect(() => {
        if (files && files.length > 0) {
            setFileList(files);
        }
    }, [files]);
    return (
        <div className="simpleListView">
            <table className={className}>
                <thead>
                    <tr>
                        {headers?.map((h: any, i: number) => {
                            return h?.toUpperCase() === "NAME" ?
                                <th
                                    className="nameHeader"
                                    key={i}>
                                    <span style={{ alignSelf: "center" }}> {h?.toUpperCase()}</span>
                                    {searchBar()}
                                </th> :
                                <th key={i}>{h?.toUpperCase()}</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {[...fileList]
                        ?.sort((a, b) => b?.updatedDate - a?.updatedDate)
                        ?.map((file: any, i: number) => {
                            return (
                                <tr key={i}>
                                    <td>
                                        <FileTypeIcons
                                            name={getFileExtension(file?.fileName)}
                                            style={{ color: "red" }}
                                            color={`var(--oceanBlue)`}
                                            size={25}
                                        />
                                    </td>
                                    <td className="filemanager-file-row"> {decodeURI(file?.fileName)} </td>
                                    <td className="filemanager-file-row">{isNull(formatBytesToSize(file?.fileSize, "bytes", 2))}</td>
                                    <td className="filemanager-file-row">{isNull(UTCtoLocalTime(file?.updatedDate).myDate)}, {isNull(UTCtoLocalTime(file?.updatedDate).myTime)}</td>
                                    <td className="filemanager-file-row">
                                        <button
                                            className="blueHollowBtn"
                                            onClick={() => handleDownload(file?.fileName)}
                                            title="Download"
                                        >
                                            <BiDownload />
                                        </button>
                                    </td>
                                </tr>
                            );
                        }
                        )}
                </tbody>
            </table>
        </div>
    );
};

export default SimpleListView;
