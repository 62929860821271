import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "userPrefConfig",
  initialState: {
    configData: { userPreferences: { node: { telemetry: { views: [{}] } } } },
  },
  reducers: {
    saveConfigData: (state, action) => {
      state.configData = {
        userPreferences: {
          node: { telemetry: { views: [...action.payload] } },
        },
      };
    },
   
 updateConfigDataCheckbox:(state, action) => {
  var data = JSON.parse(JSON.stringify(state.configData));
  let tempOthViews = data?.userPreferences?.node?.telemetry?.views;
  const idx = tempOthViews.findIndex((obj: any) => {
    return (
      obj?.viewTypeKey?.toUpperCase() ===
      action?.payload?.viewVal?.toUpperCase()
    );
  });
  let tempViews = tempOthViews[idx];

  if (action.payload.selectAll) {
    const allVisible = tempViews?.attributes?.every((col: any) => col.isVisible);
    let tempFields = tempViews?.attributes?.map((col: any) => {
      if (col.columnKey === 'address') {
        return col; 
      }
     
      return { ...col, isVisible: !allVisible };
    });
    tempViews.attributes = tempFields;
  } else {
    let tempFields = tempViews?.attributes?.map((col: any) => {
      let colObj = col;
      if (action.payload?.option === colObj.columnKey) {
        colObj = { ...colObj, isVisible: !colObj.isVisible };
      }
      return colObj;
    });

    tempViews.attributes = tempFields;

    const allOptionsSelected = tempFields
      .filter((col: any) => col.columnKey !== "selectAll")
      .every((col: any) => col.isVisible);

    tempFields = tempFields.map((col: any) => {
      if (col.columnKey === "selectAll") {
        return { ...col, isVisible: allOptionsSelected };
      }
      return col;
    });

    tempViews.attributes = tempFields;
  }

  tempOthViews[idx] = tempViews;
  state.configData = {
    userPreferences: { node: { telemetry: { views: [...tempOthViews] } } },
  };
},
    updateBasicConfigDataCheckbox: (state, action) => {
      var data = JSON.parse(JSON.stringify(state.configData));
      let tempOthViews = data?.userPreferences?.node?.telemetry?.views;
      // const idx = tempOthViews.findIndex((obj: any) => {
      //   return obj?.toUpperCase() === action?.payload?.viewVal?.toUpperCase();
      // });

      let tempViews = tempOthViews[0];

      let tempFields = tempViews?.attributes?.map((col: any) => {
        let colObj = col;
        if (action.payload?.option === colObj.columnKey) {
          if (colObj.isVisible) {
            colObj = { ...colObj, isVisible: false };
          } else {
            colObj = { ...colObj, isVisible: true };
          }
          return { ...colObj };
        } else {
          return { ...colObj };
        }
      });

      tempViews.attributes = tempFields;
      tempOthViews[0] = tempViews;
      state.configData = {
        userPreferences: { node: { telemetry: { views: [...tempOthViews] } } },
      };
    },
    updateConfigDataReposition: (state, action) => {
      var data = JSON.parse(JSON.stringify(state.configData));
      let tempOthViews = data?.userPreferences?.node?.telemetry?.views;
      const idx = tempOthViews.findIndex((obj: any) => {
        return (
          obj?.viewTypeKey?.toUpperCase() ===
          action?.payload?.viewVal?.toUpperCase()
        );
      });
      let tempViews = tempOthViews[idx];
      tempViews.attributes = action?.payload?.tempCols;

      tempOthViews[idx] = tempViews;
      state.configData = {
        userPreferences: { node: { telemetry: { views: [...tempOthViews] } } },
      };
    },
    updateConfigSpecCheckbox: (state, action) => {
      var data = JSON.parse(JSON.stringify(state.configData));
      let tempOthViews = data?.userPreferences?.node?.telemetry?.views;
      const idx = tempOthViews.findIndex((obj: any) => {
        return (
          obj?.viewTypeKey?.toUpperCase() ===
          action?.payload?.viewVal?.toUpperCase()
        );
      });
      let tempViews = tempOthViews[idx];
      let tempFields = tempViews?.specAvailAttr?.map((col: any) => {
        let colObj = col;
        if (
          action.payload?.jsonDataMapping.indexOf(colObj.jsonDataMapping) !== -1
        ) {
          let tempSpecInfo = colObj.specInfo;
          let flag = tempSpecInfo[action.payload?.option];
          switch (action.payload?.option) {
            case "inSpec":
              tempSpecInfo = { ...tempSpecInfo, ...{ inSpec: !flag } };
              break;
            case "outSpec":
              tempSpecInfo = { ...tempSpecInfo, ...{ outSpec: !flag } };
              break;
            case "marginal":
              tempSpecInfo = { ...tempSpecInfo, ...{ marginal: !flag } };
              break;
            case "others":
              tempSpecInfo = { ...tempSpecInfo, ...{ others: !flag } };
              break;
          }

          colObj = { ...colObj, ...{ specInfo: tempSpecInfo } };
          return { ...colObj };
        } else {
          return { ...colObj };
        }
      });
      tempViews.specAvailAttr = tempFields;
      tempOthViews[idx] = tempViews;
      state.configData = {
        userPreferences: { node: { telemetry: { views: [...tempOthViews] } } },
      };
    },
  },
});

export const {
  saveConfigData,
  updateConfigDataCheckbox,
  updateBasicConfigDataCheckbox,
  updateConfigDataReposition,
  updateConfigSpecCheckbox,
} = slice.actions;

export default slice.reducer;
