
export const REBOOT_BEGIN = "REBOOT_BEGIN";
export const REBOOT_SUCCESS = "REBOOT_SUCCESS";
export const REBOOT_FAILURE = "REBOOT_FAILURE";
export const REBOOT_RESET_STATUS = "REBOOT_RESET_STATUS";
interface RebootBegin {
    type: typeof REBOOT_BEGIN
}
interface RebootSuccess {
    type: typeof REBOOT_SUCCESS
};

interface RebootFailure {
    type: typeof REBOOT_FAILURE
    payload: Error
};
interface RebootReset {
    type: typeof REBOOT_RESET_STATUS
};
export type RebootActionTypes = RebootBegin | RebootSuccess | RebootFailure | RebootReset