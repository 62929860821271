export const SAVE_NEW_COMMENT_BEGIN = "SAVE_NEW_COMMENT__BEGIN";
export const SAVE_NEW_COMMENT_SUCCESS = "SAVE_NEW_COMMENT__SUCCESS";
export const SAVE_NEW_COMMENT_FAILURE = "SAVE_NEW_COMMENT__FAILURE";

interface SaveNewCommentBegin {
    type: typeof SAVE_NEW_COMMENT_BEGIN;
  }
  interface SaveNewCommentSuccess {
    type: typeof SAVE_NEW_COMMENT_SUCCESS;
    payload: any;
  }
  interface SaveNewCommentFailure {
    type: typeof SAVE_NEW_COMMENT_FAILURE;
    payload: Error;
  }

  export type NewCommentActionTypes =
  | SaveNewCommentBegin
  | SaveNewCommentSuccess
  | SaveNewCommentFailure