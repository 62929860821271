import { useDispatch } from "react-redux";
import { HiArrowUp, HiArrowDown } from "react-icons/hi";
import { FaNetworkWired } from "react-icons/fa";

import appConst from "../../../../constants/appConst";
import CustomPopOver from "../../../../../components/reusable/CustomPopOver";
import TopologyLabels from "../../../../constants/topologyLabels";
import colors from "../../../../config/colors";
import {
  useRfDownstreamTraceState,
  useRfUpstreamTraceState,
} from "../../../../util/reduxFunctions/getTopologyState";
import { mapElementStyles } from "../../../../config/mapItemsConfig";
import {
  getRfDownstreamTraceElements,
  setRfDownstreamTraceIsActive,
} from "../../../../store/slices/mapItems/trace/rf/rfDownstreamTrace";
import {
  getRfUptreamTraceElements,
  setRfUpstreamTraceIsActive,
} from "../../../../store/slices/mapItems/trace/rf/rfUpstreamTrace";
import { setShowRightClickMenu } from "../../../../store/slices/mapItems/mapDetails";
import {
  setDrawerTabIndex,
  setLogicalViewTabIndex,
} from "../../../../store/slices/mapDrawer";

interface Props {
  activeMapElement: any;
}

export default function RfTraceButtons({ activeMapElement }: Props) {
  const dispatch = useDispatch();
  const labels = TopologyLabels();
  const {
    data: rfDsData,
    isActive: isRfDsActive,
    networkId: rfDsNetworkId,
  } = useRfDownstreamTraceState();
  const {
    data: rfUsData,
    isActive: isRfUsActive,
    networkId: rfUsNetworkId,
  } = useRfUpstreamTraceState();
  const activeNetworkId = activeMapElement?.data?.networkId;
  const isRfDsSelected = isRfDsActive && activeNetworkId === rfDsNetworkId;
  const isRfUsSelected = isRfUsActive && activeNetworkId === rfUsNetworkId;

  const onDownstreamClick = () => {
    dispatch(setShowRightClickMenu(false));

    if (isRfDsSelected) {
      dispatch(setRfDownstreamTraceIsActive(false));
    } else if (activeNetworkId !== rfDsNetworkId) {
      dispatch(setRfDownstreamTraceIsActive(true));
      dispatch(
        getRfDownstreamTraceElements(
          activeNetworkId,
          activeMapElement?.rootNodeSiteId,
          activeMapElement?.type,
          activeMapElement?.data
        )
      );
    } else if (activeNetworkId === rfDsNetworkId && rfDsData !== undefined) {
      dispatch(setRfDownstreamTraceIsActive(true));
    }
  };

  const onUpstreamClick = () => {
    dispatch(setShowRightClickMenu(false));

    if (isRfUsSelected) {
      dispatch(setRfUpstreamTraceIsActive(false));
    } else if (activeNetworkId !== rfUsNetworkId) {
      dispatch(setRfUpstreamTraceIsActive(true));
      dispatch(
        getRfUptreamTraceElements(
          activeNetworkId,
          activeMapElement?.rootNodeSiteId,
          activeMapElement?.type,
          activeMapElement?.data
        )
      );
    } else if (activeNetworkId === rfUsNetworkId && rfUsData !== undefined) {
      dispatch(setRfUpstreamTraceIsActive(true));
    }
  };

  const onRfTraceLogicalViewClick = (traceType: any) => {
    dispatch(setLogicalViewTabIndex(traceType === appConst.upstream ? 1 : 0));
    dispatch(setDrawerTabIndex(8));
  };

  return (
    <>
      {activeMapElement?.type !== appConst.powerSupply &&
        appConst.enabledElementsForTrace?.includes(activeMapElement?.type) &&
        activeMapElement?.coord && (
          <span>
            {isRfDsSelected && (
              <CustomPopOver
                popOverData={`${labels.downstream} ${labels.logicalView}`}
                index="popOver"
                originComp={
                  <span
                    onClick={() =>
                      onRfTraceLogicalViewClick(appConst.downstream)
                    }
                  >
                    <FaNetworkWired
                      style={{ marginRight: 2 }}
                      color={
                        mapElementStyles?.trace?.downstream?.line?.strokeColor
                      }
                      size={18}
                    />
                  </span>
                }
              />
            )}
            <CustomPopOver
              popOverData={`${labels.downstream} ${labels.trace}`}
              index="popOver"
              originComp={
                <span onClick={onDownstreamClick}>
                  <HiArrowDown
                    style={{ marginRight: 2 }}
                    color={
                      isRfDsSelected
                        ? mapElementStyles?.trace?.downstream?.line?.strokeColor
                        : colors.greyMedium
                    }
                    size={18}
                  />
                </span>
              }
            />
            <CustomPopOver
              popOverData={`${labels.upstream} ${labels.trace}`}
              index="popOver"
              originComp={
                <span onClick={onUpstreamClick}>
                  <HiArrowUp
                    style={{ marginRight: 2 }}
                    color={
                      isRfUsSelected
                        ? mapElementStyles?.trace?.upstream?.line?.strokeColor
                        : colors.greyMedium
                    }
                    size={18}
                  />
                </span>
              }
            />
            {isRfUsSelected && (
              <CustomPopOver
                popOverData={`${labels.upstream} ${labels.logicalView}`}
                index="popOver"
                originComp={
                  <span
                    onClick={() => onRfTraceLogicalViewClick(appConst.upstream)}
                  >
                    <FaNetworkWired
                      style={{ marginRight: 2 }}
                      color={
                        mapElementStyles?.trace?.upstream?.line?.strokeColor
                      }
                      size={18}
                    />
                  </span>
                }
              />
            )}
          </span>
        )}
    </>
  );
}
