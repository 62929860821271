import { useEffect, useState } from "react";
import { FaMapMarkedAlt, FaNetworkWired } from "react-icons/fa";
import { useDispatch } from "react-redux";

import CustomIconsHoverable from "../../../components/reusable/CustomIconsHoverable";
import SideBarTabs from "../../../components/reusable/SideBarTabs";
import ActiveMapElement from "./ActiveMapElement";
import MapDataFilter from "./filters/MapDataFilter";
import {
  GetClickOrdersProps,
  GetCommentsProps,
  GetCpatEventsProps,
  GetGoogleMaps,
  GetMapDetailsProps,
  GetNikaOrderProps,
  GetTelemetryProps,
  GetTopoConfigsProps,
  GetTopoGeneralProps,
  useFetchElementFiles,
  useRfDownstreamTraceState,
  useRfUpstreamTraceState,
} from "../../util/reduxFunctions/getTopologyState";
import TopologyLabels from "../../constants/topologyLabels";
import NikaMainScreen from "./nika/NikaMainScreen";
import DiagMainScreen from "./diagnostics/DiagMainScreen";
import "../../styles/drawer/DrawerContent.scss";
import appConst from "../../constants/appConst";
import CpatMainScreen from "./cpat/CpatMainScreen";
import ClickOrdersScreen from "./click/ClickOrdersScreen";
import MapElementSearch from "./search/MapElementSearch";
import NodeDetailsView from "./node/NodeDetailsView";
import { useTopologySymbolsFilters } from "../../util/customHooks/topologyHooks";
import { getTopologyFilters } from "../../data/FiltersData";
import NodeCommentsView from "./nodeComments/NodeCommentsView";
import CustomTabs from "../reusable/CustomTabs";
import SelectedMemo from "./memo/SelectedMemo";
import { getComments } from "../../store/slices/mapItems/comments/comments";
import CMInfoTabs from "./diagnostics/CMInfoTabs";
import ElementAttachments from "./attachments/ElementAttachments";
import { getAllFiles } from "../../store/slices/mapItems/elementAttachments";
import {
  getTeleModemsList,
  setAllDiagLoading,
  setDevicesVisible,
} from "../../store/slices/mapItems/diagnostics/telemetry";
import {
  getCpatEvents,
  setEventsVisible,
} from "../../store/slices/mapItems/cpat/cpatEvents";
import {
  getClickOrders,
  setClickOrderFlt,
  setClickStatusFlt,
  setOrdersVisible,
} from "../../store/slices/mapItems/clickOrders";
import {
  getNikaOrder,
  setNikaFilters,
  setNikaMarkersVisible,
} from "../../store/slices/mapItems/nika/nikaOrder";
import {
  nikaFiltersDefaultSelection,
  cpatFiltersDefaultSelection,
  getClickOrderCategoryConfig,
  getClickStatusConfig,
  clickFiltersDefaultSelection,
} from "../../util/utilFuncs";
import NikaData from "../../data/NikaData";
import { addAbortKey } from "../../store/middleware/apiClient";
import { TELE_ABORT_KEY } from "../../store/middleware/abortKeys";
import { setDrawerTabIndex } from "../../store/slices/mapDrawer";
import TraceLogicalView from "./trace/rf/TraceLogicalView";
import RedlineTab from "./redline/RedlineTab";
import { hasLimitedTopologyFeatureView } from "../../../components/reusable/RoleConfig";

interface Props {
  activeTabIndex: number;
  SetActiveTabIndex: (arg0: number) => void;
  isFromTopoStandaloneView?: Boolean;
}

export default function DrawerContent({
  activeTabIndex,
  SetActiveTabIndex,
  isFromTopoStandaloneView = false,
}: Props) {
  const locatorTabIndex = 6;
  const activeElementIndex = 7;
  const labels = TopologyLabels();
  const dispatch = useDispatch();
  const nikaChipsInitialItems = NikaData();
  const { data: FD } = GetTopoConfigsProps();
  const { eleCommentsData } = GetCommentsProps();
  const { isDevicesVisible, diagStartTime } = GetTelemetryProps();
  const { isEventsVisible, data: cpatData } = GetCpatEventsProps();
  const {
    isOrdersVisible,
    clickOrderFlt,
    clickStatusFlt,
    data: clickData,
  } = GetClickOrdersProps();
  const {
    isNikaMarkersVisible,
    data: nikaData,
    nikaFilters,
  } = GetNikaOrderProps();
  const { activeMapElement } = GetMapDetailsProps();
  const { activeNodeId } = GetTopoGeneralProps();
  const { currentMapCenter } = GetGoogleMaps();
  const activeElementProps = {
    ...activeMapElement?.properties,
    coord: activeMapElement?.geometry,
  };
  const { isActive: isRfDsActive } = useRfDownstreamTraceState();
  const { isActive: isRfUsActive } = useRfUpstreamTraceState();

  const [switchTab, setSwitchTab] = useState(false);
  const [topoEleFilters, setTopoEleFilters] = useState(
    getTopologyFilters(labels)
  );
  const { fetchData } = useFetchElementFiles();
  const attachmentTabFlag =
    activeMapElement?.properties?.type === appConst?.node ||
    activeMapElement?.properties?.type === appConst?.powerSupply ||
    activeMapElement?.properties?.type === appConst?.olt ||
    activeMapElement?.properties?.type === appConst?.LCP ||
    activeMapElement?.properties?.type === appConst?.vhub;
  const data =
    eleCommentsData?.[activeMapElement?.properties?.data?.networkId]?.data;
  const handleSelect = (key: any) => {
    if (key == appConst?.comment) {
      if (data === undefined && activeMapElement?.properties?.data) {
        dispatch(getComments(activeMapElement?.properties?.data?.networkId));
      }
    }
  };

  useTopologySymbolsFilters(topoEleFilters, setTopoEleFilters);

  const onVisiblityClick = (type: any) => {
    switch (type) {
      case appConst.diagnostics:
        dispatch(setDevicesVisible(!isDevicesVisible));
        if (diagStartTime === undefined) {
          dispatch(
            getTeleModemsList({ value: activeNodeId, diagnoseDevices: true })
          );

          addAbortKey(TELE_ABORT_KEY);
          dispatch(setAllDiagLoading(true));
        }
        break;
      case appConst.cpat:
        dispatch(setEventsVisible(!isEventsVisible));
        if (!cpatData) {
          dispatch(
            getCpatEvents(cpatFiltersDefaultSelection(labels, currentMapCenter))
          );
        }
        break;
      case appConst.click:
        dispatch(setOrdersVisible(!isOrdersVisible));

        if (!clickData) {
          let clickOrderFltCpy: any = clickOrderFlt;
          if (FD?.clickConfig?.orderCategory && !clickOrderFlt) {
            clickOrderFltCpy = getClickOrderCategoryConfig(
              FD?.clickConfig?.orderCategory
            );
            dispatch(setClickOrderFlt(clickOrderFltCpy));
          }
          let clickStatusFltCpy: any = clickStatusFlt;
          if (FD?.clickConfig?.status && !clickStatusFlt) {
            clickStatusFltCpy = getClickStatusConfig(FD?.clickConfig?.status);
            dispatch(setClickStatusFlt(clickStatusFltCpy));
          }
          dispatch(
            getClickOrders(
              clickFiltersDefaultSelection(
                currentMapCenter,
                clickStatusFltCpy,
                clickOrderFltCpy
              )
            )
          );
        }
        break;
      case appConst.nika:
        dispatch(setNikaMarkersVisible(!isNikaMarkersVisible));
        if (!nikaData) {
          let filters = [] as any;
          if (!nikaFilters) {
            nikaChipsInitialItems.map((e) => e.checked && filters.push(e.key));
            dispatch(setNikaFilters(filters));
          }
          dispatch(
            getNikaOrder(
              nikaFiltersDefaultSelection(
                nikaFilters ? nikaFilters : filters,
                currentMapCenter
              )
            )
          );
        }
        break;

      default:
        break;
    }
  };

  const isSectionDisabled = hasLimitedTopologyFeatureView();

  useEffect(() => {
    if (activeMapElement !== undefined && switchTab) {
      dispatch(setDrawerTabIndex(activeElementIndex));
    } else if (activeMapElement !== undefined) setSwitchTab(true);
  }, [activeMapElement]);

  useEffect(() => {
    if (isFromTopoStandaloneView) {
      setSwitchTab(true);
      dispatch(setDrawerTabIndex(locatorTabIndex));
    }
  }, [isFromTopoStandaloneView]);

  useEffect(() => {
    if (cpatData) dispatch(setEventsVisible(true));
  }, [cpatData]);

  useEffect(() => {
    if (nikaData) dispatch(setNikaMarkersVisible(true));
  }, [nikaData]);

  useEffect(() => {
    if (clickData) dispatch(setOrdersVisible(true));
  }, [clickData]);

  useEffect(() => {
    if (diagStartTime !== undefined && !isFromTopoStandaloneView)
      dispatch(setDevicesVisible(true));
  }, [diagStartTime]);

  const tabsForNode = [
    {
      eventKey: appConst.info,
      title: labels.info,
      compToRend: <ActiveMapElement elementData={activeElementProps} />,
    },
    {
      eventKey: appConst.comment,
      title: labels.comments,
      compToRend: (
        <NodeCommentsView activeMapElement={activeMapElement?.properties} />
      ),
    },
    attachmentTabFlag && {
      eventKey: appConst.attach,
      title: labels.files,
      compToRend: (
        <ElementAttachments activeMapElement={activeMapElement?.properties} />
      ),
    },
  ];

  const tabsItems = [
    {
      icon: <CustomIconsHoverable name="nodeOutline" />,
      popupText: labels.NodeOverview,
      compToRend: <NodeDetailsView />,
      isTabHidden: isFromTopoStandaloneView,
    },
    {
      icon: <CustomIconsHoverable name="filter" />,
      popupText: "Map Elements Filters",
      compToRend: (
        <MapDataFilter
          topoEleFilters={topoEleFilters}
          setTopoEleFilters={setTopoEleFilters}
        />
      ),
    },
    {
      icon: <CustomIconsHoverable name="diagnostics" />,
      popupText: "Diagnostics",
      compToRend: <DiagMainScreen />,
      isVisible: isDevicesVisible,
      type: appConst.diagnostics,
      isTabHidden: isFromTopoStandaloneView,
    },
    {
      icon: <CustomIconsHoverable name="CPAT" color={`var(--CPAT)`} />,
      popupText: "CPAT",
      compToRend: <CpatMainScreen />,
      isVisible: isEventsVisible,
      type: appConst.cpat,
      isDisabled: isSectionDisabled,
    },
    {
      icon: <CustomIconsHoverable name="nika" />,
      popupText: labels.NikaOrders,
      compToRend: <NikaMainScreen />,
      isVisible: isNikaMarkersVisible,
      type: appConst.nika,
    },
    {
      icon: (
        <CustomIconsHoverable
          name="clickService"
          color={`var(--clickService)`}
        />
      ),
      popupText: labels.ClickOrder,
      compToRend: <ClickOrdersScreen />,
      isVisible: isOrdersVisible,
      type: appConst.click,
    },
    /* {
      icon: <CustomIconsHoverable name="MR" />,
      popupText: labels.microReflections,
      compToRend: <MicroReflectionGroupedIssues />,
    }, */
    {
      icon: <CustomIconsHoverable name="elementSearch" />,
      popupText: "Locator",
      compToRend: <MapElementSearch />,
    },
    {
      icon: <FaMapMarkedAlt />,
      popupText: labels.activeItem,
      compToRend:
        activeMapElement?.properties?.type === appConst.CM ? (
          <CMInfoTabs deviceData={activeMapElement} />
        ) : attachmentTabFlag ? (
          <CustomTabs
            defaultKey={appConst.info}
            tabs={tabsForNode}
            onSelect={handleSelect}
          />
        ) : activeMapElement?.properties?.type === appConst.memoComment ? (
          <SelectedMemo elementData={activeMapElement} />
        ) : (
          <ActiveMapElement elementData={activeElementProps} />
        ),
    },
    {
      icon: <FaNetworkWired />,
      popupText: labels.logicalView,
      compToRend: <TraceLogicalView />,
      isTabHidden: !(isRfUsActive || isRfDsActive),
    },
    {
      icon: <CustomIconsHoverable name="editMap" />,
      popupText: "Redline",
      compToRend: <RedlineTab />,
      isDisabled: isSectionDisabled,
    },
  ];

  return (
    <SideBarTabs
      tabsItems={tabsItems}
      activetabIndex={activeTabIndex}
      SetActiveTabIndex={(i: number) => dispatch(setDrawerTabIndex(i))}
      onVisiblityClick={onVisiblityClick}
    />
  );
}
