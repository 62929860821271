import { combineReducers } from "redux";
import rpd from "./rpd";
import rpdRegistration from "./rpdRegistration";
import rpdSwap from "./rpdSwap";
import rpdActivation from "./rpdActivation";
import nptSummary from "./nptSummary";
import rpdOrderInfo from "./rpdOrderInfo";
import rpdProvisioning from "./rpdProvisioning";
import rpdSWUpgrade from "./rpdSWUpgrade";
import vccapCASAVideo from "./vccapCASAVideo";
import vccapSWUpgrade from "./vccapSWUpgrade";
import vccapHarmonicConfig from "./vccapHarmonicConfig";
import vccapUATPortConfig from "./vccapUATPortConfig";
import rpdSWUpgradeMac from "./rpdSWUpgradeMac";
import rpdMac from "./rpdMac";
import nptSummaryMac from "./nptSummaryMac";
import rpdDecomm from "./rpdDecomm";
import rpdDecommMac from "./rpdDecommMac";
import rpdOptionsList from "./rpdOptionsList";
import rpdSwVersion from "./rpdSwVersion";

const rpdReducer = combineReducers({
  rpd,
  rpdRegistration,
  rpdSwap,
  rpdActivation,
  nptSummary,
  rpdOrderInfo,
  rpdProvisioning,
  rpdSWUpgrade,
  vccapCASAVideo,
  vccapSWUpgrade,
  vccapHarmonicConfig,
  vccapUATPortConfig,
  rpdSWUpgradeMac,
  rpdMac,
  nptSummaryMac,
  rpdDecomm,
  rpdOptionsList,
  rpdDecommMac,
  rpdSwVersion,
});

export default rpdReducer;
