import { NodeInfoDetails } from "../../../models/node/nodeinfo/NodeInfoDetails";
import { ActiveNodePage } from "../../../states/node/nodeinfo/ActiveNodeInfoState";

export const SET_ACTIVE_NODE_INFO_DETAILS = "SET_ACTIVE_NODE_INFO_DETAILS";
export const RESET_ACTIVE_NODE_INFO = "RESET_ACTIVE_NODE_INFO";
export const SET_ACTIVE_NODE_SUB_TAB_INDEX = "SET_ACTIVE_NODE_SUB_TAB_INDEX";
export const RESET_NODE = "RESET_NODE";
export const SET_ACTIVE_NODE_PAGE = "SET_ACTIVE_NODE_PAGE";

interface SetActiveNodeInfoDetails {
  type: typeof SET_ACTIVE_NODE_INFO_DETAILS;
  payload: NodeInfoDetails;
}
interface ResetActiveNodeInfo {
  type: typeof RESET_ACTIVE_NODE_INFO;
}

interface SetActiveSubTabIndex {
  type: typeof SET_ACTIVE_NODE_SUB_TAB_INDEX;
  payload: number;
}

interface ResetNode {
  type: typeof RESET_NODE;
}

interface SetActiveNodePage {
  type: typeof SET_ACTIVE_NODE_PAGE;
  payload: ActiveNodePage;
}

export type ActiveNodeInfoActionTypes =
  | SetActiveNodeInfoDetails
  | SetActiveSubTabIndex
  | SetActiveNodePage
  | ResetNode
  | ResetActiveNodeInfo;
