import { useEffect, useState } from "react";

import "../styles/drawer/rf/RfLogicalView.scss";
import appConst from "../constants/appConst";
import TopologyLabels from "../constants/topologyLabels";
import {
  convertRfTraceDataToTreeFormat,
  getTypeTitle,
} from "../util/utilFuncs";
import NetworkTraceTreeView from "../components/reusable/NetworkTraceTreeView";

const RfTraceLogicalView = (props: any) => {
  const labels = TopologyLabels();

  const [title, setTitle] = useState("");
  const [data, setData] = useState<any>({});

  useEffect(() => {
    const handleReceiveMessage = (event: MessageEvent) => {
      if (event.origin !== window.location.origin) return;

      if (
        event.data &&
        event.data.traceType &&
        event.data.deviceType &&
        event.data.networkId &&
        event.data.traceData &&
        event.data.elementInfo
      ) {
        setTitle(
          `${
            event.data.traceType === appConst.downstream
              ? labels.downstream
              : labels.upstream
          } ${labels.logicalView} - ${event.data.networkId}`
        );
        setData({
          name: event.data.networkId,
          type: event.data.deviceType,
          attributes: {
            deviceType: getTypeTitle(event.data.deviceType)?.title,
            deviceModel:
              event.data.deviceType === appConst.taps
                ? event.data.elementInfo?.tap?.deviceType
                : event.data.elementInfo?.rfDeviceType,
            activeDeviceRens: event.data.elementInfo?.powerSupplyId,
            tapValue: event.data.elementInfo?.tap?.tapValue,
          },
          children: convertRfTraceDataToTreeFormat(event.data.traceData),
        });
      }
    };

    window.addEventListener("message", handleReceiveMessage);
    return () => {
      window.removeEventListener("message", handleReceiveMessage);
    };
  }, []);

  return (
    <>
      <span className="label">{title}</span>
      <NetworkTraceTreeView data={data} />
    </>
  );
};

export default RfTraceLogicalView;
