import * as React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { PonTableConfig } from "../../constants/TelePrefConfig";
import { useGponNodeClient } from "../../network/httpclient/gpon/GponNodeClient";
import { getTelemetryHomeAddress } from "../../components/reusable/NodeUtil";
import { GetPONONTDetails,GetGponNodeDetails } from "../../get-api/GetResponse";
import { GetTopoGeneralProps} from "../../../src/topology/util/reduxFunctions/getTopologyState";
import { GponNodeSummary } from "../../store/models/gpon/GponNodeSummary";
import { PONTelemetryTable } from "./components/PONTelemetryTable";
import AppLabels from "../../constants/App_Labels";
import { ExportCsv } from "./components/ExportCsv";
import CustomIcons from "../../components/reusable/CustomIcons";

type Props = {
  children?: React.ReactNode;
  nodeSummary?: any;
  ponNode?: any;
  open?: boolean;
  ponOltName?: any;
  setOpen?: (open: boolean) => void;
};

export const PONOLTTable = (props: Props) => {
  const { open, setOpen } = props;
  const labels = AppLabels();
  const nodeInfoApi = useGponNodeClient();
  const { ponInfo, nodeSummaryList,allIsLoading } = GetPONONTDetails();
  const {status,subdbEndTime,nodeInfo}= GetGponNodeDetails();
  const {
    queryPonNode,
  } = useSelector(
    (state: any) => ({
      queryPonNode: state.slices.topologyState.topoGeneral.queryPonNode,
    }),
    shallowEqual
  );

  const [tableData, setTableData] = React.useState<GponNodeSummary[]>([]);
  const [filteredData, setFilteredData] = React.useState<any[]>([]);
  const [disabled, setDisabled] = React.useState(true);

  const onClickExternalLink = (rowValue: any, column: string) => {
    if (
      column?.trim()?.toLowerCase() === "ontSerialNumber".trim().toLowerCase()
    ) {
      let newWindow = window.open(`${window.location.origin}#/`, "_blank")!;
      newWindow?.addEventListener("load", () => {
        newWindow?.postMessage(
          { rowValue, column: "ontSerialNumber" },
          newWindow.origin
        );
      });
    }

    if (column?.trim()?.toLowerCase() === "oltName".trim().toLowerCase()) {
      let newWindow = window.open(
        `${window.location.origin}#/` + `${"node?oltName=" + rowValue}`,
        "_blank"
      )!;
      newWindow.onload = () => {
        newWindow.document.title = "OLT-" + rowValue;
      };
      newWindow?.addEventListener("load", () => {
        newWindow?.postMessage(
          { rowValue, column: "oltName" },
          newWindow.origin
        );
      });
    }
  };

  const isNodeSummaryLoaded = (responseList:any, oltData:any, search: string) => {
    const found = responseList?.find((p:any) => {
      if (oltData?.serialNumber === p?.ontSerialNumber) {
        return true;
      }
      return false;
    });
   if( search==="address" ) return found ? getTelemetryHomeAddress(found.servicedAddress) : "";
   if(search==="vendor") return found? found.ontVendor : "";
   if(search ==="model") return found? found.ontModel : "";
  };
  interface ApiResponse {
    [key: string]: any; 
       resultList: any[];
  }
  const mergeApiResponses = async (nodes: any[]): Promise<ApiResponse> => {
    try {
      const promises = nodes.map((i: any) => nodeInfoApi.getNodesInfo(i, "Maestro"));
      const responses = await Promise.all(promises);

      const mergedResult: ApiResponse = {
        resultList: []
      };

      responses.forEach(response => {
        if (typeof response === 'object' && response !== null && Array.isArray(response.resultList)) {
          mergedResult.resultList = mergedResult.resultList.concat(response.resultList);
        } else {
          console.warn("Skipping invalid response:", response);
        }
      });
  
      return mergedResult;
    } catch (error) {
      console.error("Error merging API responses:", error);
      throw error;
    }
  };
  
  
  const getTableData = React.useCallback(async () => {
    if (nodeSummaryList !== undefined ) {

        let node= props.ponNode?.split(',');
    
    let  apiResponse=await(mergeApiResponses(node));

      let appendToTableData = await nodeSummaryList?.map(
        (d: any) => {
          return {
            address: apiResponse !=undefined ?  isNodeSummaryLoaded(apiResponse?.resultList,d,"address"): undefined,
            ontSerialNumber: d.serialNumber,
            ontVendor: apiResponse!=undefined? isNodeSummaryLoaded(apiResponse?.resultList,d,"vendor"):undefined,
            ontModel: apiResponse!=undefined? isNodeSummaryLoaded(apiResponse?.resultList,d,"model"):undefined,
            // allIsLoading:allIsLoading,
            // servicedAddress: d.servicedAddress,
            // uptime: d.uptime,
            // serviceProfile: d.serviceProfile,
            // opticalTx: d.opticalTx,
            // opticalRx: d.opticalRx,
            // operStatus: d.operStatus,
            // adminStatus: d.adminStatus,
            // reportingState: d.reportingState,
            // lastChange: d.lastChange,
            // swVersion: d.swVersion,
            // opticalDistance: d.opticalDistance,
            // etherPortCurrentStatus: d.etherPortCurrentStatus,
            // etherPortOperStatus: d.etherPortOperStatus,
            // etherPortAdminStatus: d.etherPortAdminStatus,
            // etherPortConfigIndicator: d.etherPortConfigIndicator,
            // etherPortDetectedMac: d.etherPortDetectedMac,
            // etherPortDetectedIp: d.etherPortDetectedIp,
            // ponSplitterNode: d.ponSplitterNode,
            // location: d.location,
            // ontNumber: d.ontNumber,
            // slotName: d.slotName,
            // ponPort: d.ponPort,
            // AMSID: d.AMSID,
            // spread the summary data to the node object to be used in the table
            ...d,
          };
        }
      );

      setTableData(appendToTableData);
      setFilteredData(appendToTableData);

    }
  }, [nodeSummaryList]);

  React.useEffect(() => {
    if (nodeSummaryList !== undefined) {
     
        setDisabled(false);
    }
  }, [nodeSummaryList]);

  const handleExportColumnData = (columnData: any) => {
    let headers: any = [];
    columnData.forEach((column: any) => {
      headers.push({
        label: column.title,
        key: column.attribute,
      });
    });
    return headers;
  };

  const handleExportRowData = (rowData: any) => {
    rowData.forEach((row: any) => {
        PonTableConfig.forEach((column: any) => {
        let key = column.attribute;
        if (row[key] instanceof Object) {
          row[key] = row[key].value;
        } else if (
          row?.hasOwnProperty("errorCode") &&
          (row[key] === undefined || row[key] === null)
        ) {
          row[key] = "ERROR";
        } else if (key?.trim()?.toLowerCase() === "lastchange") {
          const date = new Date(row[key]) ?? "";
          row[key] =
            date?.toISOString()?.split("T")?.[0] +
            ", " +
            date?.toLocaleTimeString();
        } else {
          row[key] = row[key] === undefined ? "" : row[key]?.toString();
        }
      });
    });
    return rowData;
  };

  const getDefaultFileName = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    let time = today.toLocaleTimeString().split(":");

    return (
      "PON" +
      "_" +
      props?.ponOltName +
      "_" +
      mm +
      "-" +
      dd +
      "-" +
      yyyy +
      "_" +
      time[0] +
      "_" +
      time[1]
    );
  };

  React.useEffect(() => {
    getTableData();
  }, [getTableData]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {open && !disabled && (
        <div className="abc">
          <ExportCsv
            show={open}
            headerText={labels.Export}
            cancelText={labels.CANCEL}
            handleClose={() => {
              if (setOpen) {
                setOpen(false);
              }
            }}
            rowData={JSON.parse(JSON.stringify(filteredData))}
            columnData={PonTableConfig}
            handleCustom={true}
            handleColumnData={handleExportColumnData}
            handleRowData={handleExportRowData}
            getDefaultLibFileName={getDefaultFileName}
          />
        </div>
      )}
      <PONTelemetryTable
        tableHeader={PonTableConfig}
        tableData={tableData.length > 0 ? tableData : []}
        onClickExternalLink={onClickExternalLink}
        setFilterData={(data: any) => setFilteredData(data)}
      />
    </div>
  );
};