import "../../design-tokens/rpd-page/RPDPage.scss";
import RPDDecommTop from "../../components/rpd/rpdDecommision/RPDDecommTop";
import RPDDecommDetails from "../../components/rpd/rpdDecommision/RPDDecommDetails";
import { GetRPDDecommInfo } from "../../get-api/GetResponse";
import LoaderGif from "../../resources/images/loader.webp";
import RPDExceptionBox from "../../components/reusable/RPDExceptionBox";
import AppLabels from "../../constants/App_Labels";
import { GetTechInfo } from "../../get-api/GetResponse";
import OrderSuccessBox from "../../components/rpd/OrderSuccessBox";

const RPDDecommissionTab = () => {
  const rpdDecommInfo = GetRPDDecommInfo();
  const techData = GetTechInfo()?.data;
  const labels = AppLabels();
  return (
    <>
      <div>
        <RPDDecommTop
          macAdd={rpdDecommInfo?.macAddress}
          isLoading={rpdDecommInfo?.isLoading}
        />
      </div>
      <div className="historyResultCont">
        {rpdDecommInfo.isLoading || rpdDecommInfo?.isMacRPDLoading ? (
          <div className="loader">
            <img style={{ height: "60px" }} src={LoaderGif} />
          </div>
        ) : rpdDecommInfo?.data === undefined &&
          rpdDecommInfo?.error !== undefined ? (
          <div className="diagErrorCont">
            <RPDExceptionBox
              exBoxType="error"
              headerText={labels.rpdSWUpgradeError}
              errCode={rpdDecommInfo?.error.errorCode}
              ErrorMSG={rpdDecommInfo?.error.errorMessage}
              srcSystem={rpdDecommInfo?.error.sourceSystem}
              bodyText={rpdDecommInfo?.error.responseFromSource}
              primaryBtn={false}
              secBtn={false}
            />
          </div>
        ) : rpdDecommInfo?.data !== undefined &&
          rpdDecommInfo?.error === undefined ? (
          <div className="diagErrorCont">
            <OrderSuccessBox
              headerText={labels.rpdCreateOrderDecommSuccess}
              bodyText={labels.orderStatusCheck + rpdDecommInfo?.data.orderid}
              primaryBtn={false}
              secBtn={false}
              orderid={rpdDecommInfo?.data.orderid}
              rpdOrderInfo={rpdDecommInfo}
            />
          </div>
        ) : rpdDecommInfo?.macRPDData !== undefined ? (
          <RPDDecommDetails
            macAdd={rpdDecommInfo?.macAddress}
            rpdDecommData={rpdDecommInfo}
            isLoading={rpdDecommInfo?.isLoading}
            techData={techData}
          />
        ) : rpdDecommInfo?.macRPDError !== undefined ? (
          <div className="diagErrorCont">
            <RPDExceptionBox
              exBoxType="error"
              headerText={labels.noNPTData}
              errCode={rpdDecommInfo?.macRPDError?.errorCode}
              ErrorMSG={rpdDecommInfo?.macRPDError?.errorMessage}
              srcSystem={rpdDecommInfo?.macRPDError?.sourceSystem}
              bodyText={rpdDecommInfo?.macRPDError?.responseFromSource}
              primaryBtn={false}
              secBtn={false}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default RPDDecommissionTab;
