import { zoomLevelRenderAnnotationBlock } from "../../../../config/mapConfig";
import appConst from "../../../../constants/appConst";
import {
  GetGoogleMaps,
  GetMapDetailsProps,
  GetNodeSiteDetailsProps,
} from "../../../../util/reduxFunctions/getTopologyState";
import "../../../../styles/map/AnnotationBlock.scss";
import CommonAnnotationBlocks from "./CommonAnnotationBlocks";
import RfAmpBlocks from "./RfAmpBlocks";
import RFNodeBlocks from "./RFNodeBlocks";
import RFEOLBlocks from "./RFEOLBlocks";
import { useEffect, useState } from "react";
import React from "react";
import { isMarkersInsideViewPort } from "../../../../util/utilFuncs";
import RFAmpBlocks16 from "./RfAmpBlocks16";
import RFNodeBlocks15 from "./RFNodeBlocks15";
import RFNodeBlocks16 from "./RFNodeBlocks16";

const AnnotationBlocks = React.memo(() => {
  const { mapFilters } = GetMapDetailsProps();
  const { zoomLevel, currentViewBDY } = GetGoogleMaps();
  const [featuresWM, setFeaturesWM] = useState<Array<any>>([]);
  const { activeNode, allNodeData } = GetNodeSiteDetailsProps() as any;
  const [featuresAll, setFeaturesAll] = useState<Array<any>>([]);
  const [click, setClick] = useState(false);

  useEffect(() => {
    if (allNodeData?.length > 0) {
      let tempFeatures = [] as any;
      allNodeData?.map(
        (e: any) =>
          e?.[appConst.annotationBlock]?.data?.features &&
          tempFeatures.push(...e?.[appConst.annotationBlock]?.data?.features)
      );
      setFeaturesAll(tempFeatures);
    }
  }, [activeNode?.[appConst.annotationBlock]?.data]);

  useEffect(() => {
    if (
      zoomLevel >= zoomLevelRenderAnnotationBlock &&
      featuresAll?.length > 0
    ) {
      const currentNewFeatures = isMarkersInsideViewPort(
        featuresWM,
        currentViewBDY,
        featuresAll
      );
      setFeaturesWM([...featuresWM, ...currentNewFeatures]);
    }
  }, [currentViewBDY, featuresAll]);
  return (
    <>
      {zoomLevel >= zoomLevelRenderAnnotationBlock &&
        mapFilters?.[appConst.annotationBlock] &&
        featuresWM?.map((e: any, i: any) => {
          return (
            <div key={e?.featureId}>
              {e?.properties?.data?.sType === appConst.rfCommonBlock ? (
                <CommonAnnotationBlocks
                  commonAnnotationData={e}
                  click={click}
                  setClick={setClick}
                />
              ) : e?.properties?.data?.symbolName === appConst.rfAmpBlockDwg ||
                e?.properties?.data?.symbolName === appConst.info13 ? (
                <RfAmpBlocks
                  ampBlockData={e}
                  click={click}
                  setClick={setClick}
                />
              ) : e?.properties?.data?.symbolName ===
                appConst.rfAmpBlock16Dwg ? (
                <RFAmpBlocks16
                  ampBlock16Data={e}
                  click={click}
                  setClick={setClick}
                />
              ) : e?.properties?.data?.symbolName ===
                appConst.rfNodeBlockDwg ? (
                <RFNodeBlocks
                  nodeBlockData={e}
                  click={click}
                  setClick={setClick}
                />
              ) : e?.properties?.data?.symbolName ===
                appConst.rfNodeBlock15Dwg ? (
                <RFNodeBlocks15
                  nodeBlock15Data={e}
                  click={click}
                  setClick={setClick}
                />
              ) : e?.properties?.data?.symbolName ===
                appConst.rfNodeBlock16Dwg ? (
                <RFNodeBlocks16
                  nodeBlocks16Data={e}
                  click={click}
                  setClick={setClick}
                />
              ) : e?.properties?.data?.sType === appConst.rfEOLBlock ? (
                <RFEOLBlocks
                  eolBlockData={e}
                  click={click}
                  setClick={setClick}
                />
              ) : null}
            </div>
          );
        })}
    </>
  );
});
{
}

export default AnnotationBlocks;
