import {
  NEIGH_DIAG_LIST_BEGIN,
  NEIGH_DIAG_LIST_FAILURE,
  NEIGH_DIAG_LIST_SUCCESS,
  NEIGH_DIAG_LIST_RESET,
  NeighDiagListActionTypes,
} from "../../../types/account/precheck/PCNeighDiag";
import { PCNeighDiagState } from "../../../states/account/precheck/PCNeighDiagState";

const initialState: PCNeighDiagState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function PCNeighDiagReducer(
  state = initialState,
  action: NeighDiagListActionTypes
): PCNeighDiagState {
  switch (action.type) {
    case NEIGH_DIAG_LIST_BEGIN:
      return {
        isLoading: true,
      };
    case NEIGH_DIAG_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case NEIGH_DIAG_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };
    case NEIGH_DIAG_LIST_RESET:
      return {
        ...state,
        isLoading: false,
        data: undefined,
        error: undefined,
        status: "",
      };
    default:
      return state;
  }
}
