import "date-fns";
import { format, addDays } from "date-fns";
import { Form, DropdownButton, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useEffect, useState } from "react";
import { Card } from "@material-ui/core";
import ErrorBox from "../../reusable/ErrorBox";
import appLabels from "../../../constants/App_Labels";
import ChipGroup from "../../reusable/ChipGroup";
import {
  setMacAddress,
  rpdSwapReset,
} from "../../../store/ducksPattern/rpd/rpdSwap";
import {
  setDeviceName,
  vccapHarmonicConfigReset,
} from "../../../store/ducksPattern/rpd/vccapHarmonicConfig";
import {
  formatMacAddress,
  isMacAddressValid,
  formatRPDMacAddress,
} from "../../reusable/Util";
import RPDClient from "../../../network/httpclient/rpd/RPDClient";
import { TAF_ORDER_CONST } from "../../../constants/Global";
import EmailEntry from "../../reusable/EmailEntry";
import { hostname } from "os";
import CASAVideoExecTopComponent from "../../../components/rpd/casaVideoExecution/CASAVideoExecTopComponent";
import VCCAPRPDConfigTopComponent from "../../../components/rpd/vccapRPDConfig/VCCAPRPDConfigTopComponent";

const VCCAPRPDConfigTop = (props: any) => {
  const { oldMacAdd, techData, isLoading, rpdNumber } = props;
  const [oldMacAd, setOldMacAd] = useState(formatMacAddress(oldMacAdd));
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("This is Error Message");
  const [newMacAd, setNewMacAd] = useState("");
  const [nptId, setNPTId] = useState("");
  const [version, setVersion] = useState("");
  const [macdomain, setMacDomain] = useState("");
  const [hostname, setHostname] = useState("");
  const [ip, setIp] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [vc, setVC] = useState("");
  const [vs, setVS] = useState("");
  const [address, setAddress] = useState("");
  const [ptpDomain, setPtpDomain] = useState("");
  const [primaryIp, setPrimaryIp] = useState("");
  const [gatewayIp, setGatewayIp] = useState("");
  const [secIp, setSecIp] = useState("");
  const [rpdHw, setRPDHw] = useState("");
  const [dschannel, setDSChannel] = useState("");
  const [dsprimary, setDSPrimary] = useState("");
  const [dsnonprimary, setDSNonPrimary] = useState("");
  const [usChannel, setUSChannel] = useState("");
  const [plcblkfreq, setPlcblkFreq] = useState("");
  const [subcarrier, setSubcarrier] = useState("");
  const [auxCores, setAuxCores] = useState("");
  const [dsCSV, setDSCsv] = useState("");
  const [usCSV, setUSCsv] = useState("");
  const [videoserviceCSV, setVideoServiceCsv] = useState("");
  const botTypeList = ["Generation", "Execution"];
  const [botType, setBotType] = useState("Generation");
  const labels = appLabels();
  const [activeCHipIndex, setActiveCHipIndex] = useState(0);
  const searchTypeChips = [
    { title: labels.botGeneration, description: labels.botTypeGeneration },
    { title: labels.botExecution, description: labels.botTypeExecution },
  ];
  const changeActiveIndex = (data: number) => {
    setActiveCHipIndex(data);
    if (data === 1) {
      dispatch(vccapHarmonicConfigReset());
      setBotType(labels.botExecution);
    } else {
      dispatch(vccapHarmonicConfigReset());
      setBotType(labels.botGeneration);
    }

    //dispatch(rpdRegistrationReset());
  };
  const [device, setDevice] = useState("Harmonic");
  const [deviceCHipIndex, setDeviceCHipIndex] = useState(0);
  const deviceTypeChips = [
    { title: labels.devHarmonic, description: labels.devTypeHarmonic },
    { title: labels.devCasa, description: labels.devTypeCasa },
  ];
  const changeDeviceIndex = (data: number) => {
    setDeviceCHipIndex(data);
    if (data === 1) {
      dispatch(vccapHarmonicConfigReset());
      dispatch(setDeviceName(labels.devCasa));
      setDevice(labels.devCasa);
    } else {
      dispatch(vccapHarmonicConfigReset());
      dispatch(setDeviceName(labels.devHarmonic));
      setDevice(labels.devHarmonic);
    }

    //dispatch(rpdRegistrationReset());
  };

  const onIpSubmit = (e: any) => {
    setIp(e.target.value);
  };
  const onHostnameSubmit = (e: any) => {
    setHostname(e.target.value);
  };
  const onvideoserviceCSVSubmit = (e: any) => {
    setVideoServiceCsv(e.target.value);
  };
  const onusCSVSubmit = (e: any) => {
    setUSCsv(e.target.value);
  };
  const ondsCSVSubmit = (e: any) => {
    setDSCsv(e.target.value);
  };
  const onAuxCoresSubmit = (e: any) => {
    setAuxCores(e.target.value);
  };
  const onVCSubmit = (e: any) => {
    setVC(e.target.value);
  };
  const onVSSubmit = (e: any) => {
    setVS(e.target.value);
  };
  const onAddressSubmit = (e: any) => {
    setAddress(e.target.value);
  };
  const onPTPDomainSubmit = (e: any) => {
    setPtpDomain(e.target.value);
  };

  const onPrimaryIpSubmit = (e: any) => {
    setPrimaryIp(e.target.value);
  };
  const onGatewayIpSubmit = (e: any) => {
    setGatewayIp(e.target.value);
  };
  const onSecIpSubmit = (e: any) => {
    setSecIp(e.target.value);
  };
  const onRPDHWSubmit = (e: any) => {
    setRPDHw(e.target.value);
  };
  const onDSChannelSubmit = (e: any) => {
    setDSChannel(e.target.value);
  };
  const onDSprimarySubmit = (e: any) => {
    setDSPrimary(e.target.value);
  };
  const onDSNonprimarySubmit = (e: any) => {
    setDSNonPrimary(e.target.value);
  };
  const onUSChannelSubmit = (e: any) => {
    setUSChannel(e.target.value);
  };
  const onPlcBlkSubmit = (e: any) => {
    setPlcblkFreq(e.target.value);
  };
  const onSubcarrierSubmit = (e: any) => {
    setSubcarrier(e.target.value);
  };

  const [emails, setEmails] = useState([
    techData?.lanId !== null ? techData?.lanId : "",
  ]);
  const dispatch = useDispatch();

  const onNptIdSubmit = (e: any) => {
    dispatch(rpdSwapReset());
    setNPTId(e.target.value);
  };

  const onOldMacAdSubmit = (e: any) => {
    dispatch(rpdSwapReset());
    let sv = formatMacAddress(e.target.value);
    if (sv?.length <= 12) setOldMacAd(sv);
  };

  const onVersionSubmit = (e: any) => {
    setVersion(e.target.value);
  };

  const onMacDomainSubmit = (e: any) => {
    if (e.target.value?.length <= 12) setMacDomain(e.target.value);
  };

  const createInputProvHarmonicObject = (
    oldMac: string,
    newMac: string,
    nptId: string,
    emails: string
  ): any => {
    let inputObject = {
      hostname: hostname,
      ip: ip,
      VC: Number(vc),
      VS: Number(vs),
      macadd: formatRPDMacAddress(oldMacAd),
      address: address,
      RPD_HW_type: rpdHw,
      "MAIL-ID": emails,
      // ptp_domain_no: ptpDomain,
      // primaryip: primaryIp,
      // "gateway-ip": gatewayIp,
      // secip: secIp,
      // DS_channel: dschannel,
      // DS_primary: dsprimary,
      // DS_Non_primary: dsnonprimary,
      // US_channel: usChannel,
      // "plc-blk-freq": plcblkfreq,
      // "subcarrier-spacing": subcarrier,
      // "aux-cores": auxCores,
      // DS_CSV_Name: dsCSV,
      // US_CSV_Name: usCSV,
      VideoService_CSV_Name: "",
    };
    return inputObject;
  };

  const createInputProvHarmonicGenerationObject = (
    oldMac: string,
    newMac: string,
    nptId: string,
    emails: string
  ): any => {
    let inputObject = {
      VC: Number(vc),
      VS: Number(vs),
      macadd: formatRPDMacAddress(oldMacAd),
      address: address,
      RPD_HW_type: rpdHw,
      "MAIL-ID": emails,
      // ptp_domain_no: ptpDomain,
      // primaryip: primaryIp,
      // "gateway-ip": gatewayIp,
      // secip: secIp,
      // DS_channel: dschannel,
      // DS_primary: dsprimary,
      // DS_Non_primary: dsnonprimary,
      // US_channel: usChannel,
      // "plc-blk-freq": plcblkfreq,
      // "subcarrier-spacing": subcarrier,
      // "aux-cores": auxCores,
      // DS_CSV_Name: dsCSV,
      // US_CSV_Name: usCSV,
      VideoService_CSV_Name: "",
    };
    return inputObject;
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleApplySwap = (oldMacAd: string) => {
    if (oldMacAd?.length === 12) {
      let stringMacAdd = formatRPDMacAddress(oldMacAd);
      dispatch(setMacAddress(stringMacAdd));

      if (botType === "Generation" || hostname?.length > 0) {
        if (botType === "Generation" || ip?.length > 0) {
          if (vc?.length > 0) {
            if (vs?.length > 0) {
              if (address?.length > 0) {
                if (rpdHw?.length > 0) {
                  if (emails[0] !== "") {
                    handleSwap(stringMacAdd, newMacAd, nptId);
                  } else {
                    setOpen(true);
                    setError(labels.emailEmptyError);
                  }
                } else {
                  setOpen(true);
                  setError(labels.enter_hw_type);
                }
              } else {
                setOpen(true);
                setError(labels.enter_address);
              }
            } else {
              setOpen(true);
              setError(labels.enter_vs);
            }
          } else {
            setOpen(true);
            setError(labels.enter_vc);
          }
        } else {
          setOpen(true);
          setError(labels.enter_ip);
        }
      } else {
        setOpen(true);
        setError(labels.enter_hostname);
      }
    } else {
      setOpen(true);
      setError(labels.macIdEmptyError);
    }
  };

  const handleSwap = (oldMacAdd: string, newMacAd: string, nptId: string) => {
    if (botType === "Execution") {
      dispatch(
        RPDClient.createVccapHarmonicConfig(
          TAF_ORDER_CONST.vccapHarmonicConfigBotName,
          TAF_ORDER_CONST.vccapHarmonicConfigSuiteName,
          createInputProvHarmonicObject(
            oldMacAdd,
            newMacAd,
            nptId,
            emails.toString()
          )
        )
      );
    } else {
      dispatch(
        RPDClient.createVccapHarmonicConfig(
          TAF_ORDER_CONST.vccapHarmonicConfigGenerationBotName,
          TAF_ORDER_CONST.vccapHarmonicConfigSuiteName,
          createInputProvHarmonicGenerationObject(
            oldMacAdd,
            newMacAd,
            nptId,
            emails.toString()
          )
        )
      );
    }
    dispatch(setMacAddress(undefined));
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleApplySwap(oldMacAd);
    }
  };

  const onNewMacAdSubmit = (e: any) => {
    dispatch(rpdSwapReset());
    let sv = formatMacAddress(e.target.value);
    if (sv?.length <= 12) setNewMacAd(sv);
  };

  const DropdownMenu = ({ label, data, value, onSelect }: any) => (
    <div className="inputContainer">
      <Form.Label>{label}</Form.Label>
      <DropdownButton
        title={value}
        onSelect={(v: any) => onSelect(v)}
        size="sm"
        id="dropdownBtn"
      >
        {data.map((e: any, i: number) => (
          <Dropdown.Item key={i} eventKey={e}>
            {e}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );

  return (
    <>
      <Card className="filterRootContainer">
        <div className="filterContainer">
          <div className="filterRow">
            <div className="inputContainer">
              <Form.Group>
                <Form.Label>{labels.deviceType}</Form.Label>
                <div className="titleChipCard">
                  <ChipGroup
                    chips={deviceTypeChips}
                    activeChipIndex={deviceCHipIndex}
                    onIndexChange={changeDeviceIndex}
                  />
                </div>
              </Form.Group>
            </div>
            <div className="inputContainer">
              <Form.Group>
                <Form.Label>{labels.botType}</Form.Label>
                <div className="titleChipCard">
                  <ChipGroup
                    chips={searchTypeChips}
                    activeChipIndex={activeCHipIndex}
                    onIndexChange={changeActiveIndex}
                  />
                </div>
              </Form.Group>
            </div>
          </div>
          {device === "CASA" && (
            <CASAVideoExecTopComponent
              nptId={oldMacAdd}
              techData={techData}
              botTypeNew={botType}
              isLoading={isLoading}
            />
          )}
          {device === "Harmonic" && (
            <VCCAPRPDConfigTopComponent
              oldMacAdd={oldMacAdd}
              techData={techData}
              botTypeNew={botType}
              isLoading={isLoading}
            />
          )}
        </div>
      </Card>
      <ErrorBox
        open={open}
        error={error}
        setOpen={setOpen}
        handleClose={handleClose}
      />
    </>
  );
};
export default VCCAPRPDConfigTop;
