import { Divider } from "@material-ui/core";
import { format } from "date-fns";
import { useState } from "react";
import { MdModeEdit, MdDelete } from "react-icons/md";

import colors from "../../../config/colors";
import appConst from "../../../constants/appConst";
import TopologyLabels from "../../../constants/topologyLabels";
import { updateDeleteComment } from "../../../store/slices/mapItems/comments/comments";
import DeleteCommentsModal from "./DeleteCommentsModal";
import EditCommentsModal from "./EditCommentsModal";

interface props {
  techData: any;
  filteredData: any;
  dispatch: any;
}

const NodeCommentsList = ({ techData, filteredData, dispatch }: props) => {
  const labels = TopologyLabels();
  const contentLengthToShow = 250;
  const [showFullContent, setShowFullContent] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const onDeleteClick = (filteredData: any) => {
    dispatch(
      updateDeleteComment(filteredData?.networkId, filteredData?.id, {})
    );
  };

  return (
    <>
      <div className="nodeCommentCardDetail">
        <div className="commentsHeader">
          <span>{filteredData.lanId?.split("@")?.[0]}</span>
          <span>
            <span>
              {format(
                filteredData?.updateDate,
                appConst.dateTimeFormatForComments
              )}
            </span>
          </span>
        </div>
        <div className="commentsDescription" id={`view-${filteredData?.id}`}>
          <span
            style={{
              WebkitLineClamp: showFullContent ? "unset" : 3,
            }}
          >
            {filteredData?.comment}
          </span>
          {filteredData?.comment?.length > contentLengthToShow && (
            <div
              className="textView"
              onClick={() => setShowFullContent(!showFullContent)}
            >
              <a style={{ color: colors.link }}>
                {showFullContent ? labels.viewLess : labels.viewMore}
              </a>
            </div>
          )}
        </div>

        <div className="commentsFeatures">
          {techData?.lanId?.toUpperCase() ===
            filteredData?.lanId?.toUpperCase() && (
            <div className="editDeleteButton">
              <button
                className="editButton"
                onClick={() => {
                  setEditModal(true);
                }}
              >
                <MdModeEdit />
              </button>
              <button
                className="deleteButton"
                onClick={() => {
                  setDeleteModal(true);
                }}
              >
                <MdDelete />
              </button>
            </div>
          )}
        </div>

        <Divider style={{ marginTop: "10px" }} />
        {editModal && (
          <EditCommentsModal
            filteredData={filteredData}
            setEditModal={setEditModal}
            dispatch={dispatch}
          />
        )}
        {deleteModal && (
          <DeleteCommentsModal
            filteredData={filteredData}
            setDeleteModal={setDeleteModal}
            onDeleteClick={onDeleteClick}
          />
        )}
      </div>
    </>
  );
};

export default NodeCommentsList;
