import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AppLabels from "../../../constants/App_Labels";
import ConfigConst from "../../../constants/ConfigConst";
import {
  GetActiveAccountDetails,
  GetFormHistory,
  GetTechInfo,
} from "../../../get-api/GetResponse";
import {
  capitalize,
  extractW3IdOfHSI,
  getCMMac,
  getEMTAMac,
  getSTBMac,
  hasOnlyEMTA,
  hasOnlySTB,
  isAccountNumberFormatValid,
  isLegacyAccount,
} from "../../reusable/Util";
import BottomHalf from "./sections/BottomHalf";
import TopHalf from "./sections/TopHalf";

interface Props {
  setDetails: any;
  selectedCustType: any;
  setShowPreview: any;
}

const NoiseDetails = ({
  setDetails,
  selectedCustType,
  setShowPreview,
}: Props) => {
  const labels = AppLabels();
  const dispatch = useDispatch();
  const techData = GetTechInfo()?.data;
  const techRoles = GetTechInfo()?.data?.roles;
  const technicianId = GetTechInfo()?.techId;
  const activeAccData = GetActiveAccountDetails()?.data;
  const accountNumber = GetActiveAccountDetails()?.accountNumber;
  const selectedSA = GetActiveAccountDetails()?.selectedSA;
  const cbpId = GetActiveAccountDetails()?.cbpId;
  const sortedDevices = GetActiveAccountDetails()?.sortedDevices;
  const form = GetFormHistory()?.data;
  const formData = GetFormHistory()?.formData;
  const legacyAccount = isLegacyAccount();

  const indexWithConstructionTech = techRoles.findIndex(
    (item: string | string[]) => item.includes("construction_tech")
  );
  const hasConstructionTechRole = indexWithConstructionTech !== -1;

  const obj = {
    CMMac: getCMMac(sortedDevices),
    STBMac: legacyAccount
      ? hasOnlySTB(sortedDevices)
        ? getSTBMac(sortedDevices)
        : ([] as any)
      : ([] as any),
    EMTAMac: legacyAccount
      ? hasOnlyEMTA(sortedDevices)
        ? getEMTAMac(sortedDevices)
        : ([] as any)
      : ([] as any),
    accountNumber: accountNumber,
    hhid: !legacyAccount ? extractW3IdOfHSI(selectedSA) : "",
    techName: techData?.fullName
      ? techData?.fullName.replace(/[^a-zA-Z ]/g, "").trim()
      : "",
    technicianId: technicianId ? technicianId : "",
    streetNumber: selectedSA?.streetNumber ? selectedSA?.streetNumber : "",
    streetName:
      (selectedSA?.streetName ? capitalize(selectedSA?.streetName) : "") +
      " " +
      (selectedSA?.streetType ? selectedSA?.streetType : ""),
    streetDirection: selectedSA?.direction ? selectedSA?.direction : "",
    unitOrSuiteNumber: selectedSA?.apartmentNumber
      ? selectedSA?.apartmentNumber
      : "",
    city: selectedSA?.city ? capitalize(selectedSA?.city) : "",
    nodeNumber: selectedSA?.shubId ? selectedSA?.shubId : "",
    cbpId: cbpId ? cbpId : "",
    managerName: techData?.supervisorName ? techData?.supervisorName : "",
    hotToCold: "",
    bellModem: labels?.No,//"",
    f2Information_PrePadding: "",
    filteredOrPadded: "",
    filterOrPadInfo: "",
    f2Information_PostPadding: "",
    above10Units: "",
    above10UnitsList: [] as any,
    above10UnitsComments: "",
    priority: labels.Select,
    legacyOrIgniteOrFide:
      activeAccData?.brand === ConfigConst.ROGERS ||
        activeAccData?.brand === "Legacy"
        ? legacyAccount
          ? "Legacy"
          : "Ignite"
        : activeAccData?.brand === ConfigConst.FIDO
          ? "Fido"
          : labels.Select,
    screenShots: [] as File[],
    comments: "",
    distroEmails: [] as any,
  };

  const [formSubmissionNo, setFormSubmissionNo] = useState("");
  const [tpia, setTpia] = useState(
    selectedCustType === ConfigConst.TPIA ? labels?.Yes : labels?.No
  );
  const [detailsObj, setDetailsObj] = useState(obj);
  const [formHistory, setFormHistory] = useState({} as any);

  useEffect(() => {
    if (formData && formData != "") {
      if (form?.formName === ConfigConst.NOISE_REFERRAL) {
        setFormSubmissionNo(formData?.formSubmissionNo);
        setTpia(formData?.tpia);
        setFormHistory(formData);
      } else resetData();
    } else resetData();
  }, [formData]);

  useEffect(() => {
    if (formHistory) setDetailsObj(formHistory);
  }, [formHistory]);

  const resetData = () => {
    setFormSubmissionNo("");
    setTpia(selectedCustType === ConfigConst.TPIA ? labels?.Yes : labels?.No);
    setDetailsObj(obj);
  };

  useEffect(() => {
    setShowPreview(
      selectedCustType === ConfigConst.TPIA ||
      detailsObj?.hotToCold === labels?.Yes ||
      detailsObj?.bellModem === labels?.Yes ||
      detailsObj?.above10Units === labels?.Yes
    );
  }, [
    selectedCustType,
    detailsObj?.hotToCold,
    detailsObj?.bellModem,
    detailsObj?.above10Units,
  ]);

  useEffect(() => {
    setTpia(selectedCustType === ConfigConst.TPIA ? labels?.Yes : labels?.No);
    if (selectedCustType === ConfigConst.TPIA) {
      if (!detailsObj?.distroEmails.includes("csspoc@rci.rogers.com"))
        setDetailsObj({
          ...detailsObj,
          distroEmails: [
            ...detailsObj?.distroEmails,
            "csspoc@rci.rogers.com",
            "tpia.inquiries@rci.rogers.com",
          ],
        });
      // if (!detailsObj?.distroEmails.includes("tpia.inquiries@rci.rogers.com"))
      //   setDetailsObj({
      //     ...detailsObj,
      //     distroEmails: [
      //       ...detailsObj?.distroEmails,
      //       "tpia.inquiries@rci.rogers.com",
      //     ],
      //   });
    } else if (selectedCustType !== ConfigConst.TPIA) {
      let firstFilteredEmails = detailsObj?.distroEmails.filter((val: any) => {
        return (
          val !== "csspoc@rci.rogers.com" &&
          val !== "tpia.inquiries@rci.rogers.com"
        );
      });
      setDetailsObj({ ...detailsObj, distroEmails: firstFilteredEmails });
    }
  }, [selectedCustType]);

  useEffect(() => {
    if (
      detailsObj?.hotToCold === labels?.Yes ||
      detailsObj?.bellModem === labels?.Yes ||
      detailsObj?.above10Units === labels?.Yes
    ) {
      if (!detailsObj?.distroEmails.includes("Noise.Hotline@rci.rogers.com"))
        setDetailsObj({
          ...detailsObj,
          distroEmails: [
            ...detailsObj?.distroEmails,
            "Noise.Hotline@rci.rogers.com",
          ],
        });
    }
  }, [detailsObj?.hotToCold, detailsObj?.bellModem, detailsObj?.above10Units]);

  const macCheck = (mac: any) => {
    if (mac.length > 0) {
      for (let i = 0; i < mac.length; i++) {
        if (mac[i] === "") return false;
      }
    }
    return true;
  };

  const accountCheck = () => {
    if (legacyAccount) {
      if (
        detailsObj?.accountNumber !== "" &&
        isAccountNumberFormatValid(detailsObj?.accountNumber)
      ) {
        return true;
      } else return false;
    } else {
      if (detailsObj?.hhid === "") {
        if (
          detailsObj?.accountNumber !== "" &&
          isAccountNumberFormatValid(detailsObj?.accountNumber)
        ) {
          return true;
        } else return false;
      } else if (
        (detailsObj?.accountNumber !== "" &&
          isAccountNumberFormatValid(detailsObj?.accountNumber)) ||
        detailsObj?.hhid !== ""
      ) {
        return true;
      } else return false;
    }
  };

  const filterCheck = () => {
    if (detailsObj?.filteredOrPadded === labels.Yes) {
      if (detailsObj?.filterOrPadInfo !== "") {
        return true;
      } else return false;
    }
    return true;
  };

  const above10UnitsCheck = () => {
    if (detailsObj?.above10Units === labels.Yes) {
      if (
        detailsObj?.above10UnitsList.length > 0 ||
        detailsObj?.above10UnitsComments !== ""
      ) {
        for (let i = 0; i < detailsObj?.above10UnitsList?.length; i++) {
          if (detailsObj?.above10UnitsList[i] === "") return false;
        }
      } else return false;
    }
    return true;
  };

  const checkFlags = () => {
    if (
      tpia !== "" &&
      macCheck(detailsObj?.CMMac) &&
      macCheck(detailsObj?.STBMac) &&
      macCheck(detailsObj?.EMTAMac) &&
      accountCheck() &&
      detailsObj?.techName !== "" &&
      detailsObj?.streetNumber !== "" &&
      detailsObj?.streetName !== "" &&
      detailsObj?.city !== "" &&
      detailsObj?.nodeNumber !== "" &&
      detailsObj?.managerName !== "" &&
      detailsObj?.hotToCold !== "" &&
      detailsObj?.bellModem !== "" &&
      detailsObj?.filteredOrPadded !== "" &&
      filterCheck() &&
      detailsObj?.above10Units !== "" &&
      above10UnitsCheck() &&
      detailsObj?.priority !== labels.Select &&
      detailsObj?.legacyOrIgniteOrFide !== labels.Select &&
      (hasConstructionTechRole !== false || detailsObj?.technicianId !== "")
    ) {
      return true;
    } else return false;
  };

  useEffect(() => {
    if (checkFlags()) {
      setDetails({
        tpia: tpia,
        ...detailsObj,
      });
    } else {
      setDetails(undefined);
    }
  }, [detailsObj]);

  useEffect(() => {
    if (detailsObj?.filteredOrPadded === labels.No)
      setDetailsObj({ ...detailsObj, filterOrPadInfo: "" });
  }, [detailsObj?.filteredOrPadded]);

  useEffect(() => {
    if (detailsObj?.above10Units === labels.No) {
      setDetailsObj({
        ...detailsObj,
        above10UnitsList: [],
        above10UnitsComments: "",
      });
    }
  }, [detailsObj?.above10Units]);
  useEffect(() => {
    if (
      detailsObj?.hotToCold === labels?.No &&
      detailsObj?.bellModem === labels?.No &&
      detailsObj?.above10Units === labels?.No
    ) {
      let secondFilteredEmails = detailsObj?.distroEmails.filter((val: any) => {
        return val !== "Noise.Hotline@rci.rogers.com";
      });
      setDetailsObj({ ...detailsObj, distroEmails: secondFilteredEmails });
    }
  }, [detailsObj?.hotToCold, detailsObj?.bellModem, detailsObj?.above10Units]);

  return (
    <>
      <TopHalf
        formSubmissionNo={formSubmissionNo}
        detailsObj={detailsObj}
        setDetailsObj={setDetailsObj}
      />
      {/* <Divider style={{ margin: "30px 0px 30px 0px" }} /> */}
      <BottomHalf detailsObj={detailsObj} setDetailsObj={setDetailsObj} />
    </>
  );
};

export default NoiseDetails;
