import { useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import AppLabels from "../../constants/App_Labels";
import { dataSetType } from "../../constants/TelePrefConfig";
import NodeInfoDetailsClient from "../../network/httpclient/nodeinfo/NodeInfoDetailsClient";
import {
  updateConfigDataCheckbox,
  updateConfigDataReposition,
} from "../../store/ducksPattern/userPrefConfig";
import DiagContainer from "../reusable/DiagContainer";
import {
  getNodeTeleGloabalStatus,
  calculatingNodeAveragesData,
} from "../reusable/NodeUtil";
import NodeTeleDevicesTable from "./NodeTeleDevicesTable";
import CustomDropDown from "../reusable/CustomDropDown";
import ColumnDropDown from "./ColumnDropDown";

import "../../design-tokens/telemetry/NodeTelemetryTab.scss";
import ClearFlaps from "./ClearFlaps";
import TelemetryActionMenu from "./TelemetryActionMenu";
import { isNull } from "../reusable/Util";
import {
  GetTelemetryDetails,
  GetTelemetryAverages,
  GetNodeInfoDetails,
} from "../../get-api/GetResponse";
import CustomPopUp from "../reusable/CustomPopUp";
import { SetNodeDevicesError } from "../../store/actions/node/telemetry/NodeDevicesInfoActions";

import {
  getTeleModemsList,
  stopDiagExecution,
  setViewType,
  setAllDiagLoading
} from "../../topology/store/slices/mapItems/diagnostics/telemetry";
import { MultiSelectDropdown } from "../reusable/MultiSelectDropdown";
import storeConst from "../../topology/store/storeConst";
import ConfigConst from "../../constants/ConfigConst";
import { GetTelemetryProps } from "../../topology/util/reduxFunctions/getTopologyState";
import NodeAverages from "./NodeAverages";
import { MdFullscreen } from "react-icons/md";
import { isFullDiagPresent } from "../../topology/util/utilFuncs";

export function NodeTeleTab(props: any) {
  const { data, selectdSmt, fullScreen, handleFullScreen } = props;
  const dispatch = useDispatch();
  const labels = AppLabels();
  const address = ConfigConst.address.toLowerCase();

  const [dataSetName, setDataSetName] = useState("");
  const [viewTypeOptions, setViewTypeOptions] = useState<any>([]);
  const [viewType, setTeleViewType] = useState("");
  const [tableAttr, setTableAttr] = useState<any>();

  const [selectAttrVal, setSelectAttrVal] = useState<any>();
  const [tableData, setTableData] = useState();
  const [dragOver, setDragOver] = useState("");

  const [popUpFlag, setPopUpFlag] = useState(false);
  const [popUpHeader, setPopUpHeader] = useState("");
  const [popUpBody, setPopUpBody] = useState(<div></div>);
  const [averagesPopUp, setAveragesPopUp] = useState(false);
  const [avgPopUpBody, setAvgPopUpBody] = useState(<div></div>);
  const [nodeAvgData, setNodeAvgData] = useState({});

  const { ducksConfigData } = GetTelemetryDetails();
  const { currNodedata } = GetNodeInfoDetails();

  const {
    mlData,
    mlIsLoading,
    mlError,
    allDiagIsLoading,
    allDiagStatus,
    isAborted,
    allDiagError,
    diagType,
    modemType,
  } = GetTelemetryProps();
  const nodeTeleStatus = getNodeTeleGloabalStatus(
    allDiagStatus,
    allDiagIsLoading,
    isAborted
  );

  const { nodeAveragesStatusData, nodeAveragesStatusLoading } =
    GetTelemetryAverages();

  const [teleData, setTeleData] = useState(mlData);
  const isFullDiagAvail = isFullDiagPresent(teleData);

  const handleDragStart = (event: any) => {
    const { id } = event.target;
    const idx = tableAttr.findIndex((obj: any) => obj.jsonDataMapping === id);
    event.dataTransfer.setData("colIdx", idx);
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };
  const handleDragEnter = (event: any) => {
    const { id } = event.target;
    const identifier =
      id === "null" || id === null || id === "" ? dragOver : id;
    setDragOver((state) => {
      state = identifier;
      return state;
    });
  };

  const handleOnDrop = (event: any) => {
    var viewVal = "";
    if (viewType?.toUpperCase() === labels.ALL.toUpperCase()) {
      viewVal = labels.ALL.toUpperCase();
    } else if (viewType?.toUpperCase() === labels.ALL_NO_CW.toUpperCase()) {
      viewVal = labels.ALL_NO_CW.toUpperCase();
    } else {
      viewVal = viewType?.toUpperCase();
    }

    const { id } = event.target;
    const identifier =
      id === "null" || id === null || id === "" ? dragOver : id;
    const droppedColIdx = tableAttr.findIndex(
      (obj: any) => obj.jsonDataMapping == identifier
    );
    const draggedColIdx = event.dataTransfer.getData("colIdx");
    const tempCols = [...tableAttr];
    const tempCols1 = [...tableAttr];
    if (droppedColIdx !== -1 && draggedColIdx !== -1) {
      if (droppedColIdx < draggedColIdx) {
        tempCols[droppedColIdx] = tempCols1[draggedColIdx];
        tempCols[droppedColIdx + 1] = tempCols1[droppedColIdx];

        tempCols1.splice(draggedColIdx, 1);
        for (let i = droppedColIdx + 2; i <= tempCols1.length; i++) {
          tempCols[i] = tempCols1[i - 1];
        }
      } else if (draggedColIdx < droppedColIdx) {
        tempCols[droppedColIdx] = tempCols1[draggedColIdx];
        tempCols[droppedColIdx - 1] = tempCols1[droppedColIdx];
        tempCols1.splice(draggedColIdx, 1);
        for (let i = droppedColIdx - 2; i >= 0; i--) {
          tempCols[i] = tempCols1[i];
        }
      }
      dispatch(updateConfigDataReposition({ viewVal, tempCols }));
    }
    setDragOver((state) => {
      state = "";
      return state;
    });
  };

  const stopTelemetry = () => {
    dispatch(stopDiagExecution());
  };

  const handleNodeModemListRefresh = () => {
    const activeId = currNodedata?.smt
      ? currNodedata?.smtId
      : currNodedata?.nodeId;
    const type = currNodedata?.smt ? "smtid" : "shubid";
    dispatch(getTeleModemsList({ value: activeId, type: type }));
  };


const handleCheckboxClick = (option: string) => {
  var viewVal = "";
  if (viewType?.toUpperCase() === labels.ALL.toUpperCase()) {
    viewVal = labels.ALL.toUpperCase();
  } else if (viewType?.toUpperCase() === labels.ALL_NO_CW.toUpperCase()) {
    viewVal = labels.ALL_NO_CW.toUpperCase();
  } else {
    viewVal = viewType?.toUpperCase();
  }
  const currentView = ducksConfigData?.userPreferences?.node?.telemetry?.views?.find((view:any) => 
    view.viewTypeKey.toUpperCase() === viewVal
  );
  
  const isVisible = currentView.attributes.some((attr:any) => attr?.columnKey=="selectAll"
  && attr.isVisible);
    
 
  if ((option === "selectAll" && isVisible) ) {
    const selectAll = currentView.attributes.every((attr:any) => attr.isVisible);
    dispatch(updateConfigDataCheckbox({ viewVal, option, selectAll }));
  } 
  else   if (option === "selectAll" && !isVisible) {
      const selectAll = !currentView.attributes.every((attr:any)=> attr.isVisible);
    dispatch(updateConfigDataCheckbox({ viewVal, option, selectAll }));
  }
  else {
    dispatch(updateConfigDataCheckbox({ viewVal, option }));
  }
};
  

  useEffect(() => {
    if (mlData === undefined) {
      const activeId = currNodedata?.smt
        ? currNodedata?.smtId
        : currNodedata?.nodeId;
      const type = currNodedata?.smt ? "smtid" : "shubid";
      if (modemType?.trim()?.toLowerCase() === "emta") {
        dispatch(
          getTeleModemsList({
            value: activeId,
            type: type,
            diagnoseDevices: true,
            modemType: modemType,
          })
        );
      } else {
        dispatch(
          getTeleModemsList({
            value: activeId,
            type: type,
            diagnoseDevices: true,
          })
        );
      }
    }
  }, [modemType]);

  useEffect(() => {
    if (dataSetName !== undefined && dataSetName !== "") {
      dataSetType?.map((v: any) => {
        if (dataSetName === v?.dataSetName) {
          setViewTypeOptions(v?.subDataSetViews);
          dispatch(setViewType(v?.subDataSetViews?.[0]?.columnKey));
          setTeleViewType(v?.subDataSetViews?.[0]?.columnKey);
        }
      });
    }
  }, [dataSetName]);

  useEffect(() => {
    if (viewType !== undefined && viewType !== "") {
      ducksConfigData?.userPreferences?.node?.telemetry?.views?.map(
        (v: any) => {
          if (v?.viewTypeKey === viewType) {
            setTableAttr(v?.attributes);
            setSelectAttrVal(v?.attributes);
          }
        }
      );
    }
  }, [viewType, ducksConfigData]);

  useEffect(() => {
    if (!isAborted && allDiagError) {
      setPopUpFlag(true);
      setPopUpHeader(labels.ERROR.toUpperCase());
      setPopUpBody(
        <span className="telemertyinfoBoxSpan">{labels.NO_DATA}</span>
      );

      stopTelemetry();
    }
  }, [allDiagError]);

  const filterBySMT = (filterKey: string) => {
    if (filterKey?.length > 0 && filterKey !== labels.ALL?.toUpperCase()) {
      return mlData
        ?.filter((d: any) => isNull(d))
        .filter(
          (d: any) =>
            isNull(d.serviceArea) &&
            d.serviceArea
              ?.toLowerCase()
              ?.includes(filterKey?.trim()?.toLowerCase())
        );
    }
    return mlData;
  };

  useEffect(() => {
    let filterData;
    if (currNodedata?.smt) {
      filterData = filterBySMT(selectdSmt);
    } else {
      filterData = filterBySMT(data);
    }

    setTeleData(filterData);
  }, [data, selectdSmt, mlData]);

  useEffect(() => {
    if (
      nodeTeleStatus === ConfigConst.complete.toLowerCase() &&
      isFullDiagAvail
    ) {
      calculatingNodeAveragesData(teleData, setNodeAvgData);
    }
  }, [nodeTeleStatus, teleData]);

  useEffect(() => {
    if (
      nodeAvgData !== undefined &&
      Object.keys(nodeAvgData)?.length > 0 &&
      dataSetName?.toLowerCase() !== ConfigConst.BASIC?.toLowerCase()
    ) {
      dispatch(
        NodeInfoDetailsClient.getNodeAvergesStatus(
          nodeAvgData,
          currNodedata?.smt ? currNodedata?.smtId : currNodedata?.nodeId
        )
      );
    }
  }, [nodeAvgData]);

  //Setting Dataset dropdown with proper value when changed in topology
  useEffect(() => {
    let diagTypeName = "";
    if (
      diagType === null ||
      diagType?.toLowerCase()?.trim() === storeConst.all
    ) {
      diagTypeName = ConfigConst.FULL;
    } else if (diagType?.toLowerCase()?.trim() === storeConst.all_no_cw) {
      diagTypeName = ConfigConst.FULL_NO_CW;
    } else {
      diagTypeName = ConfigConst.BASIC;
    }
    setDataSetName(diagTypeName);
  }, [diagType, dataSetName]);
  useEffect(()=>{
    if(!mlIsLoading && allDiagStatus==="")
  {
    dispatch(setAllDiagLoading(true))
  }
  },[mlIsLoading]);
  return (
    <div>
      <div className="telemetryActionCont">
        <div className="nodeFilterCont">
          <div className="nodeFilters nodeFilterContainer">
            {/* {viewTypeOptions?.length !== 0 && (
              <CustomDropDown
                disabled={dataSetName === labels.BASIC?.toUpperCase()}
                ddTitle={labels.VIEW_TYPE?.toUpperCase()}
                selectedOption={viewType}
                options={viewTypeOptions}
                searchFlag={false}
                checkboxFlag={false}
                setSelectedOption={(value: any) => {
                  setTeleViewType(value);
                  //dispatch(SetTeleViewType(value));
                  dispatch(setViewType(value));
                }}
              />
            )} */}
            {viewTypeOptions?.length !== 0 && (
              <ColumnDropDown
                disabled={
                  dataSetName?.toUpperCase() === labels.BASIC?.toUpperCase()
                }
                ddTitle={labels.VIEW_TYPE?.toUpperCase()}
                selectedOption={
                  viewTypeOptions?.filter(
                    (obj: any) =>
                      obj?.columnKey?.toUpperCase() === viewType?.toUpperCase()
                  )?.[0]?.columnName
                }
                options={viewTypeOptions}
                searchFlag={false}
                checkboxFlag={false}
                setSelectedOption={(value: any) => {
                  setTeleViewType(value);
                  dispatch(setViewType(value));
                }}
              />
            )}

            {viewType !== labels.CUSTOM?.toUpperCase() && (
              <ColumnDropDown
                ddTitle={labels.fields?.toUpperCase()}
                selectedOption=""
                options={
                  currNodedata?.smt
                    ? tableAttr?.filter(
                        (e: any) =>
                          e?.columnKey !== address && e?.columnKey !== "smt"
                      )
                    : tableAttr?.filter((e: any) => e?.columnKey !== address)
                }
                // options={filterListAttr}
                searchFlag={true}
                checkboxFlag={true}
                setSelectedOption={(value: any) => {}}
                selectAttrVal={selectAttrVal}
                handleCheckBox={handleCheckboxClick}
                handleDragStart={handleDragStart}
                handleDragOver={handleDragOver}
                handleOnDrop={handleOnDrop}
                handleDragEnter={handleDragEnter}
                dragOver={dragOver}
              />
            )}
          </div>
          {dataSetName?.toLowerCase() !== labels.BASIC?.toLowerCase() && (
            <NodeAverages
              setAveragesPopUp={setAveragesPopUp}
              setAvgPopUpBody={setAvgPopUpBody}
              nodeTeleStatus={nodeTeleStatus}
              nodeAvgStatusData={nodeAveragesStatusData}
              nodeAveragesStatusLoading={nodeAveragesStatusLoading}
              isFullDiagPresent={isFullDiagAvail}
            />
          )}
          <div className="designMaintainer" />
          {viewType?.toUpperCase() === labels.FLAPS?.toUpperCase() && (
            <ClearFlaps
              deviceType={modemType ? modemType?.toUpperCase() : "CM"}
            />
          )}
          {!fullScreen && (
            <button
              disabled={mlData?.length > 0 ? false : true}
              className="fullScreenBtn"
              onClick={() => handleFullScreen()}
            >
              <MdFullscreen />
            </button>
          )}
          {mlData !== undefined && mlData?.length !== 0 && (
            <TelemetryActionMenu
              menuType="vertical"
              viewDropdown={viewType === "full" ? "All" : viewType}
              columnData={tableAttr}
              rowData={tableData}
              nodeDevicesList={teleData}
              nodeId={currNodedata?.nodeId}
              deviceType={modemType ? modemType?.toUpperCase() : "CM"}
              isMultiDevice={false}
            />
          )}
        </div>
      </div>
      <div>
        <DiagContainer
          data={mlData}
          isLoading={mlIsLoading}
          error={mlError}
          sectionName={labels.NODE_TELEMETRY_INFO?.toUpperCase()}
          handleRefresh={handleNodeModemListRefresh}
        >
          <NodeTeleDevicesTable
            tableAttributes={tableAttr}
            nodeDevicesList={teleData}
            onDataChange={setTableData}
            handleDragStart={handleDragStart}
            handleDragOver={handleDragOver}
            handleOnDrop={handleOnDrop}
            handleDragEnter={handleDragEnter}
            dragOver={dragOver}
          />
        </DiagContainer>
        <CustomPopUp
          showVal={popUpFlag}
          headerText={popUpHeader}
          bodyElement={popUpBody}
          handleClose={() => {
            setPopUpFlag(false);
            dispatch(SetNodeDevicesError(false));
          }}
        />
        <CustomPopUp
          showVal={averagesPopUp}
          headerText={labels.NODE_AVERAGES}
          bodyElement={avgPopUpBody}
          handleClose={() => {
            setAveragesPopUp(false);
          }}
        />
      </div>
    </div>
  );
}
