import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import "../../design-tokens/node-page/NodePage.scss";
import { getOLTListData,getONTSummaryData } from "../../network/httpclient/gpon/PONFluxClient";
import { GetPONONTDetails,GetGponNodeDetails } from "../../get-api/GetResponse";
import { PONOLTLandingPage } from "../gpon-page/PONOLTLandingPage";
import { SaveGponNodes } from "../../store/actions/gpon/GponNodeSummaryAction";
import { setActiveQueryPonNode } from "../../topology/store/slices/topoGeneral";

export const PONONTPage = (props: any) => {
  const { location: search } = props;
  const searchParams = new URLSearchParams(search?.search);
const ponOltName = searchParams.get("ponOltName");
const ponPort=searchParams.get("ponPort");
const ponNode=searchParams.get("ponNode");

  const dispatch = useDispatch();
  const { ponInfo, nodeSummaryList,ponstatus,allIsLoading } = GetPONONTDetails();
  const {status,subdbEndTime,nodeInfo}= GetGponNodeDetails();

  const [nodeInfoData, setNodeInfoData] = useState<any>(null);
  const [nodeSummaryData, setNodeSummaryData] = useState<any>({
    active: 0,
    assigned: 0,
    responsive: 0,
    unresponsive: 0,
    ponPort: ponPort,
    oltName: ponOltName,
    phub: nodeInfo?.data?.phub,
    diagStartTime: ponInfo?.diagStartTime,
    diagEndTime:  ponInfo?.diagEndTime,
  });
  
useEffect(()=>{
    if(ponPort && ponOltName)
     dispatch(
      getOLTListData({
        oltName: ponOltName,
    ponPort ,
      })
    );
},[])
  const calculateNodeSummary = useCallback(() => {

    let value: any = [];
   
    nodeSummaryList?.forEach((d: any) => {
      if (d?.operStatus?.status?.toUpperCase() === "PASS") {
        value.push(d?.operStatus?.status);
      } else 
        value.push(d?.operStatus?.status);
       
    });
       
    let error = value.filter((d: any) => d?.toUpperCase() === "FAIL").length;
    let ok = nodeSummaryList?.length -error;
  
    let total = ok + error ;
    let okPercentage = ((ok / total) * 100)?.toFixed(2);
    let responsive = ok ;
    let unresponsive = error;

    return {
      active: okPercentage,
      assigned: total,
      responsive: responsive,
      unresponsive: unresponsive,
      ponPort: ponPort,
      oltName: ponOltName,
      phub: nodeInfo?.data?.phub,
      diagStartTime: ponInfo?.diagStartTime,
      // diagEndTime: status =="OK" ? subdbEndTime: 
      // status =="ERROR" ? ponInfo?.diagEndTime: undefined,
      diagEndTime:  ponInfo?.diagEndTime,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeSummaryList,nodeInfo?.data]);

  useEffect(() => {
    if (
      nodeSummaryList?.length>0
      )
     {
      setNodeSummaryData(calculateNodeSummary());
    //   nodeInfoDataHandler();
    }
    // if (
    //   nodeSummaryList?.some(
    //     (d: any) => d?.data?.summary?.isLoading === true
    //   )
    // ) {
    //   setNodeSummaryData({
    //     active: undefined,
    //     assigned: undefined,
    //     responsive: undefined,
    //     unresponsive: undefined,
    //     ponPort: ponPort,
    //     oltName: ponOltName,
    //     diagStartTime: undefined,
    //     diagEndTime: undefined,
    //   });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeSummaryList,nodeInfo?.data]);

  const nodeInfoDataHandler = useCallback(async () => {
    // dispatch(setActiveQueryPonNode(qPonNode)); // save pon node in redux

    if (ponInfo?.oltData !== undefined) {
      let nodesList = ponInfo?.oltData;
      if (nodesList !== undefined && nodesList?.length !== 0) {
        let nodesDevices = await nodesList?.map((d: any) => {
          let nDevice = {
            ...d,
            address: undefined,
            serialNumber: d.serialNumber,
            ontModel: undefined,
            oltName: d.oltName,
            ontVendor: undefined,
            uptime: d.uptime,
            serviceProfile: d.serviceProfile,
            opticalTx: d.opticalTx,
            opticalRx: d.opticalRx,
            operStatus: d.operStatus,
            adminStatus: d.adminStatus,
            // allIsLoading:allIsLoading,
            reportingState: d.reportingState,
            lastChange: d.lastChange,
            swVersion: d.swVersion,
            opticalDistance: d.opticalDistance,
            etherPortCurrentStatus: d.etherPortCurrentStatus,
            etherPortOperStatus: d.etherPortOperStatus,
            etherPortAdminStatus: d.etherPortAdminStatus,
            etherPortConfigIndicator: d.etherPortConfigIndicator,
            etherPortDetectedMac: d.etherPortDetectedMac,
            etherPortDetectedIp: d.etherPortDetectedIp,
            ponSplitterNode: d.ponSplitterNode,
            location: d.location,
            ontNumber: d.ontNumber,
            slotName: d.slotName,
            ponPort: d.ponPort,
            AMSID: d.AMSID
          };
          return nDevice;
        });
        if (
          nodesDevices !== undefined &&
          nodeSummaryList?.length === 0
        ) {
          dispatch(SaveGponNodes(nodesDevices));
        }
       
        setNodeInfoData(nodesDevices);
    
      }
    } else {
      setNodeInfoData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allIsLoading]);

  useEffect(() => {
    if (
        nodeSummaryList !== undefined &&
        nodeSummaryList?.length > 0 && 
        allIsLoading 
      ) {
      
        dispatch(SaveGponNodes(nodeSummaryList));
        // dispatch(getONTSummaryData({oltData: nodeSummaryList}))
        nodeInfoDataHandler();
      }
     
      setNodeInfoData(nodeSummaryList);
      dispatch(setActiveQueryPonNode(ponNode?.split(',')[0].trim()));
      // dispatch(setActiveQueryPonNode(ponNode));
      // dispatch(setActiveQueryPonNode(ponOltName));
    //   nodeInfoDataHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allIsLoading]);

  return (
    <div className="accTabsCont">
      <>
        <PONOLTLandingPage
          tableData={nodeInfoData}
          nodeSummaryData={nodeSummaryData}
          ponNode={ponNode}
          ponOltName={ponOltName}
          {...props}
        />
      </>
    </div>
  );
};