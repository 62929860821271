import { RiRadioButtonFill } from "react-icons/ri";
import { FaCheckCircle } from "react-icons/fa";
import { CgRadioCheck } from "react-icons/cg";

const CustomStepper = (props: any) => {
  const { stepId, stepDesc, activeStatus, completedStatus, handleStepClick } =
    props;
  return (
    <div
      role="tab"
      id={`horizontal-step-${stepId}`}
      aria-labelledby={`horizontal-step-${stepId}`}
      className="stepperCont"
      onClick={() => handleStepClick(stepId)}
    >
      <span
        className={
          activeStatus
            ? "step-Active-ConnecterLine"
            : completedStatus
            ? "step-Completed-ConnecterLine"
            : "stepConnecterLine"
        }
      ></span>
      <div
        className={
          activeStatus
            ? "step-Active-Info"
            : completedStatus
            ? "step-Completed-Info"
            : "stepInfo"
        }
      >
        {activeStatus ? (
          <RiRadioButtonFill color="#00000080" />
        ) : completedStatus ? (
          <FaCheckCircle color="#28A745" />
        ) : (
          <CgRadioCheck color="#C1C9D2" />
        )}
        <span>{stepDesc}</span>
      </div>
    </div>
  );
};

export default CustomStepper;
