import { useDispatch } from "react-redux";
import { useEffect } from "react";

import {
  GetClickOrdersProps,
  GetTopoConfigsProps,
} from "../../../../util/reduxFunctions/getTopologyState";
import SelectionChip from "../../../reusable/SelectionChip";
import { setClickOrderFlt } from "../../../../store/slices/mapItems/clickOrders";
import ToggleSwitch from "../../../reusable/ToggleSwitch";
import {
  clickOrdersToLocalStorage,
  getClickOrderCategoryConfig,
} from "../../../../util/utilFuncs";

export default function Orders() {
  const dispatch = useDispatch();
  const { clickOrderFlt } = GetClickOrdersProps();
  const { data: FD } = GetTopoConfigsProps();

  useEffect(() => {
    if (FD?.clickConfig?.orderCategory && !clickOrderFlt)
      dispatch(
        setClickOrderFlt(
          getClickOrderCategoryConfig(FD?.clickConfig?.orderCategory)
        )
      );
  }, [FD]);

  useEffect(() => {
    if (clickOrderFlt) clickOrdersToLocalStorage(clickOrderFlt);
  }, [clickOrderFlt]);

  const onChipsPress = (checked: boolean, oi: number, oti: number) => {
    let orderTypes = JSON.parse(JSON.stringify(clickOrderFlt)); //create deep copy
    if (orderTypes?.[oi]?.orderTypes?.[oti]) {
      orderTypes[oi].orderTypes[oti].checked = checked;
      dispatch(setClickOrderFlt(orderTypes));
    }
  };

  const handleToggleSwitch = (toggle: boolean, oi: number) => {
    let orderC = JSON.parse(JSON.stringify(clickOrderFlt)); //create deep copy
    if (orderC?.[oi]) {
      orderC[oi].allChecked = toggle;
      orderC[oi]?.orderTypes?.map((e: any) => (e.checked = toggle));
      dispatch(setClickOrderFlt(orderC));
    }
  };

  return (
    <>
      {clickOrderFlt?.map((oe: any, oi: number) => (
        <div className="filterGroupCont" key={oe.label}>
          <div className="filterSecHeader">
            <label className="nikaRadiusLabel">{oe?.label}</label>
            <ToggleSwitch
              switchValue={oe.allChecked}
              setSwitchValue={(t: boolean) => handleToggleSwitch(t, oi)}
            />
          </div>
          <div className="selectionChipsCont">
            {oe?.orderTypes?.map((ote: any, oti: number) => (
              <SelectionChip
                key={ote.desc}
                style={{ marginRight: 10, marginBottom: 10 }}
                label={ote.desc}
                checked={ote.checked}
                setChecked={(checked) => onChipsPress(checked, oi, oti)}
              />
            ))}
          </div>
        </div>
      ))}
    </>
  );
}

const getStatusConfig = (order: Array<any>) => {
  const orderData = [] as any;
  const getCatLabel = (label: string) => {
    if (label === "Service") return "Order Type";
    return label;
  };

  order?.map((oe, oi) => {
    orderData.push({
      label: getCatLabel(oe?.label),
      allChecked: true,
      orderTypes: [],
    });
    oe?.orderTypes?.map((e: any) => {
      orderData[oi].orderTypes.push({
        label: e.name,
        desc: e.desc,
        checked: true,
      });
    });
  });
  return orderData;
};
