import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../middleware/apiActions";
import { OLT_URI } from "../../middleware/apiEndpoints";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "oltSearch",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    getOLTSearchBegin: (ws) => {
      ws.isLoading = true;
      ws.status = "";
    },
    getOLTSearchSuccess: (ws, { payload }) => {
      ws.isLoading = false;
      ws.data = payload;
      ws.status = storeConst.OK;
    },
    getOLTSearchFailure: (ws, { payload }) => {
      ws.isLoading = false;
      ws.error = payload;
      ws.data = undefined;
      ws.status = storeConst.ERROR;
    },
  },
});

const { getOLTSearchBegin, getOLTSearchSuccess, getOLTSearchFailure } =
  slice.actions;
export default slice.reducer;

export const getOLTSearch = (oltName: string) => (dispatch: any) => {
  dispatch(
    apiCallBegan({
      url: OLT_URI + oltName,
      onStart: getOLTSearchBegin.type,
      onSuccess: getOLTSearchSuccess.type,
      onError: getOLTSearchFailure.type,
    })
  );
};
