import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { WIFISettingsState } from "../../../store/states/account/diagnostics/cm/WIFISettingsState";
import { WIFIRadiosState } from "../../../store/states/account/diagnostics/cm/WIFIRadiosState";
import {
  wifiSettingsBegin,
  wifiSettingsFailure,
  wifiSettingsSuccess,
} from "../../../store/actions/account/diagnostics/cm/WIFISettingsAction";
import {
  wifiRadiosBegin,
  wifiRadiosFailure,
  wifiRadiosSuccess,
} from "../../../store/actions/account/diagnostics/cm/WIFIRadiosAction";
import { WIFIRadios } from "../../../store/models/account/diagnostics/cm/WIFIRadios";
import { WIFISettings } from "../../../store/models/account/diagnostics/cm/WIFISettings";
import { getOUIChecked, getOUIW3IDChecked } from "../../../components/reusable/Util";

export default class WIFIInfoClient {
  static getWIFISettings =
    (
      macAddress: string,
      serialNum: string,
      hhid: string
    ): ThunkAction<void, WIFISettingsState, unknown, Action<string>> =>
    async (dispatch) => {
      let qParam = getOUIW3IDChecked(serialNum, hhid, macAddress);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/gateway/wifisettings",
        params: qParam,
      };

      dispatch(wifiSettingsBegin());

      HttpClient.get<WIFISettings>(httpParameters)
        .then((wifiSettings) => {
          dispatch(wifiSettingsSuccess(wifiSettings));
          return wifiSettings;
        })
        .catch((error) => {
          dispatch(wifiSettingsFailure(error));
          return error;
        });
    };

  static getWIFIRadios =
    (
      macAddress: string,
      serialNum: string,
      hhid: string
    ): ThunkAction<void, WIFIRadiosState, unknown, Action<string>> =>
    async (dispatch) => {
      let qParam = getOUIW3IDChecked(serialNum, hhid, macAddress);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/gateway/wifiradios",
        params: qParam,
      };

      dispatch(wifiRadiosBegin());

      HttpClient.get<WIFIRadios>(httpParameters)
        .then((wifiRadios) => {
          dispatch(wifiRadiosSuccess(wifiRadios));
          return wifiRadios;
        })
        .catch((error) => {
          dispatch(wifiRadiosFailure(error));
          return error;
        });
    };
}
