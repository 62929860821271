import AppLabels from "../../constants/App_Labels";
import CmViewData from "../../data/cablemodem/CmViewData";
import DataCard from "../../topology/components/reusable/DataCard";
import SimpleDataTable from "../../topology/components/reusable/SimpleDataTable";
import CertiExceptionCont from "../certification/CertiExceptionCont";

import {
  convertCMData,
  getChCWValue,
  getMinMaxRange,
  getThrColoredTableCell,
  getValueByStrMapping,
  isNull,
} from "../reusable/Util";
import CmUsChart from "./CmUsChart";

interface Props {
  cmData: any;
  flag?: any;
}

const CmUSTab = ({ cmData, flag }: Props) => {
  const labels = AppLabels();
  let cmDataConfig = CmViewData();
  let usSummaryConfig = cmDataConfig?.upStream?.summary;
  let usScQAMConfig = cmDataConfig?.upStream?.scQam;
  let usScQAMData =
    flag === true
      ? cmData?.docsis30?.upstream
      : cmData?.docsis?.docsis30?.upstream;
  let usCWData = cmData?.codewords;
  let ofdmaConfig = cmDataConfig?.upStream?.ofdma;
  let ofdmaData =
    flag === true
      ? cmData?.ofdm?.upstream?.channels
      : cmData?.docsis?.ofdm?.upstream?.channels;
  let usChartRanges = getMinMaxRange(
    flag === true ? cmData : cmData?.docsis,
    "us"
  );
  return (
    <div>
      <CertiExceptionCont
        dataObj={flag === true ? cmData : cmData?.docsis}
        sectionName={labels.UPSTREAM}
      >
        <DataCard
          cardHeader={labels.SUMMARY}
          cardBody={
            <div className="cmChDetailBody">
              <SimpleDataTable
                headers={usSummaryConfig?.individual?.headers}
                tBody={Object.keys(usSummaryConfig?.individual?.dataKeys)?.map(
                  (key: any, j: number) => {
                    let summaryConfig: any =
                      usSummaryConfig?.individual?.dataKeys;

                    if (summaryConfig[key]?.dataSource === "ofdm") {
                      return ofdmaData?.map((ch: any) => {
                        let attValue = getValueByStrMapping(
                          ch,
                          summaryConfig[key]?.dataKey
                        );
                        let computedValue = convertCMData(key, attValue);
                        return (
                          <tr key={j}>
                            <td>{summaryConfig[key]?.label}</td>
                            {getThrColoredTableCell(computedValue, j)}
                          </tr>
                        );
                      });
                    } else {
                      let attValue = getValueByStrMapping(
                        cmData?.docsis,
                        summaryConfig[key]?.dataKey
                      );

                      let computedValue = convertCMData(key, attValue);
                      return (
                        <tr key={j}>
                          <td>{summaryConfig[key]?.label}</td>
                          {getThrColoredTableCell(computedValue, j)}
                        </tr>
                      );
                    }
                  }
                )}
              />
              <SimpleDataTable
                headers={usSummaryConfig?.common?.headers}
                tBody={usSummaryConfig?.common?.dataSet?.map(
                  (ch: any, j: number) => {
                    return (
                      <tr key={j}>
                        {Object.keys(ch)?.map((key: string, k: number) => {
                          let attObj =
                            key === "level"
                              ? ch[key]?.toUpperCase()
                              : getValueByStrMapping(usScQAMData, ch[key]);
                          return getThrColoredTableCell(attObj, k);
                        })}
                      </tr>
                    );
                  }
                )}
              />
            </div>
          }
        />
      </CertiExceptionCont>
      <CmUsChart
        chartRanges={usChartRanges}
        scQamChs={usScQAMData?.channels}
        ofdmaChs={ofdmaData}
      />
      {usScQAMData && flag === false && (
        <DataCard
          cardHeader={labels.SCQAM}
          cardBody={
            <SimpleDataTable
              headers={usScQAMConfig?.headers}
              tBody={usScQAMData?.channels?.map((ch: any, j: number) => {
                return (
                  <tr key={j}>
                    {Object.keys(usScQAMConfig?.dataKeys?.docsis)?.map(
                      (key: string, k: number) => {
                        let chConfig: any = usScQAMConfig?.dataKeys?.docsis;
                        let attObj = getValueByStrMapping(ch, chConfig[key]);
                        return getThrColoredTableCell(attObj, k);
                      }
                    )}
                    <td>{usCWData?.timeDelta}</td>
                    {Object.keys(usScQAMConfig?.dataKeys?.codeword)?.map(
                      (key: string, k: number) => {
                        let chConfig: any = usScQAMConfig?.dataKeys?.codeword;
                        let chCwData = getChCWValue(
                          usCWData?.upstreamChannels,
                          ch?.frequency
                        );
                        let attObj = getValueByStrMapping(
                          chCwData,
                          chConfig[key]
                        );
                        return getThrColoredTableCell(attObj, k);
                      }
                    )}
                  </tr>
                );
              })}
            />
          }
        />
      )}
      {ofdmaData && flag === false && (
        <DataCard
          cardHeader={labels.OFDMA}
          cardBody={
            <SimpleDataTable
              headers={ofdmaConfig?.headers}
              tBody={ofdmaData?.map((ch: any, j: number) => {
                return (
                  <tr key={j}>
                    {Object.keys(ofdmaConfig?.dataKeys?.docsis)?.map(
                      (key: string, k: number) => {
                        let chConfig: any = ofdmaConfig?.dataKeys?.docsis;
                        let attObj = getValueByStrMapping(ch, chConfig[key]);
                        return getThrColoredTableCell(attObj, k);
                      }
                    )}
                    <td>{usCWData?.timeDelta}</td>
                    {Object.keys(ofdmaConfig?.dataKeys?.codeword)?.map(
                      (key: string, k: number) => {
                        let chConfig: any = ofdmaConfig?.dataKeys?.codeword;
                        let attValue: any = "";
                        usCWData?.ofdmaUsChannels?.[j]?.iucList?.map(
                          (icu: any) => {
                            let attObj = getValueByStrMapping(
                              icu,
                              chConfig[key]
                            );
                            attValue += `${typeof attObj === "object"
                              ? isNull(attObj?.value)
                              : isNull(attObj)
                              }\n`;
                          }
                        );
                        return (
                          <td className="multiLineValue" key={k}>
                            {attValue}
                          </td>
                        );
                      }
                    )}
                  </tr>
                );
              })}
            />
          }
        />
      )}
    </div>
  );
};

export default CmUSTab;
