import { createSlice } from "@reduxjs/toolkit";
import {
  NETWORK_SERVICE_URL,
  UTILITY_TOOL_URI,
} from "../../../middleware/apiEndpoints";
import { apiCallBegan } from "../../../middleware/apiActions";
import storeConst from "../../../storeConst";

const slice = createSlice({
  name: "utilityToolAppConfig",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    // action => action handler
    getUtilToolAppConfigBegin: (utilAppConfig) => {
      utilAppConfig.isLoading = true;
      utilAppConfig.status = "";
    },
    getUtilToolAppConfigSuccess: (utilAppConfig, action) => {
      utilAppConfig.isLoading = false;
      utilAppConfig.data = action.payload;
      utilAppConfig.status = storeConst.OK;
    },
    getUtilToolAppConfigFailure: (utilAppConfig, action) => {
      utilAppConfig.isLoading = false;
      utilAppConfig.error = action.payload;
      utilAppConfig.data = undefined;
      utilAppConfig.status = storeConst.ERROR;
    },
  },
});

//should not be exposed to outside
const {
  getUtilToolAppConfigBegin,
  getUtilToolAppConfigSuccess,
  getUtilToolAppConfigFailure,
} = slice.actions;
export default slice.reducer;

//action creators
export const getUtilToolAppConfig = () =>
  apiCallBegan({
    baseURL: `${NETWORK_SERVICE_URL}`,
    url: `${UTILITY_TOOL_URI}`,
    onStart: getUtilToolAppConfigBegin.type,
    onSuccess: getUtilToolAppConfigSuccess.type,
    onError: getUtilToolAppConfigFailure.type,
  });
