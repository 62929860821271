import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { FormState } from "../../../store/states/account/forms/FormState";

import {
  SaveFormSuccess,
  SaveFormBegin,
  SaveFormFailure,
  SaveFormReset,
} from "../../../store/actions/account/forms/FormAction";
import {
  GetFormBegin,
  GetFormFailure,
  GetFormSuccess,
} from "../../../store/actions/account/forms/FormDataAction";

export default class FormClient {
  static postForm =
    (data: any): ThunkAction<void, FormState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "workorders/" + data?.workorderId + "/forms",
      };
      dispatch(SaveFormBegin());
      HttpClient.post<any>(httpParameters, data)
        .then((res) => {
          dispatch(SaveFormSuccess(res));
          return res;
        })
        .catch((error) => {
          dispatch(SaveFormFailure(error));
          return error;
        });
    };

  static updateForm =
    (
      data: any,
      id: String
    ): ThunkAction<void, FormState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "workorders/" +
          data?.workorderId +
          "/forms/" +
          id,
      };
      dispatch(SaveFormBegin());
      HttpClient.post<any>(httpParameters, data)
        .then((res) => {
          dispatch(SaveFormSuccess(res));
          return res;
        })
        .catch((error) => {
          dispatch(SaveFormFailure(error));
          return error;
        });
    };

  static getForm =
    (
      workorderId: String,
      formName: String
    ): ThunkAction<void, FormState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "workorders/" +
          workorderId +
          "/forms?formType=" +
          formName,
      };
      dispatch(GetFormBegin());
      HttpClient.get<any>(httpParameters)
        .then((formInfo) => {
          dispatch(GetFormSuccess(formInfo));
          return formInfo;
        })
        .catch((error) => {
          dispatch(GetFormFailure(error));
          return error;
        });
    };
}
