import Divider from "@material-ui/core/Divider";
import { useState } from "react";

import TopologyLabels from "../../../constants/topologyLabels";

export default function ClickLegends() {
  const labels = TopologyLabels();
  const [showLegends, setShowLegends] = useState(false);

  const tableData = [
    {
      label: "TC",
      def: "Service Orders",
    },
    {
      label: "MA",
      def: "Maintenance Orders",
    },
    {
      label: "OUTG",
      def: "Outage",
    },

    {
      label: "CONN",
      def: "New Connect",
    },
    {
      label: "CSRV",
      def: "Change of Service",
    },
    {
      label: "RECN",
      def: "Reconnect",
    },
    {
      label: "DISC",
      def: "Disconnect",
    },
    {
      label: "OC",
      def: "MA Order Task Code",
    },
    {
      label: "EQPU",
      def: "Equipment Pick UP",
    },
    {
      label: "MISC",
      def: "Miscellaneous",
    },
    {
      label: "PHYD",
      def: "Physical Disconnect",
    },
    {
      label: "HTCD",
      def: "Unscheduled Order",
    },
    {
      label: "N/A",
      def: "Not Available",
    },
  ];

  return (
    <div>
      <div className="legendBtnCont">
        <button
          className="legendBtn"
          onClick={() => setShowLegends(!showLegends)}
        >
          {showLegends ? labels.Hide : labels.Show} {labels.Legends}
        </button>
      </div>
      <div className={showLegends ? "legendTableOpen" : "legendTableClose"}>
        <table className="dataTableDetails">
          <thead>
            <tr>
              <th>{labels.Icon.toUpperCase()}</th>
              <th>{labels.Definition.toUpperCase()}</th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((e: any) => (
              <tr key={e.def}>
                <td style={{ height: 35, verticalAlign: "middle" }}>
                  <span className="clickMarkerLabel">{e.label}</span>
                </td>
                <td style={{ verticalAlign: "middle" }}>{e.def}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Divider style={{ margin: "20px 0px" }} />
      </div>
    </div>
  );
}
