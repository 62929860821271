import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import AppLabels from "../../../constants/App_Labels";
import { useState } from "react";
import CmViewData from "../../../data/cablemodem/CmViewData";
import DataCard from "../../../topology/components/reusable/DataCard";
import CertiExceptionCont from "../../certification/CertiExceptionCont";
import CmSpecSummary from "../CmSpecSummary";
import { getMinMaxRange } from "../../reusable/Util";
import CmDsChart from "../CmDsChart";
import CmSpecChart from "../CmSpecChart";

interface Props {
  cmData?: any;
  flag?: any;
  preCmData?: any;
}

const CmDSPrechTab = ({ cmData, flag, preCmData }: Props) => {
  const labels = AppLabels();
  const [summaryAcc, setSummaryAcc] = useState(false);
  const [chartAcc, setChartAcc] = useState(false);
  const [scqamAcc, setScqamAcc] = useState(false);
  const [ofdmAcc, setOfdmAcc] = useState(false);

  const handleAccToggle = (accName: string) => {
    switch (accName) {
      case "summary":
        setSummaryAcc(!summaryAcc);
        break;
      case "chart":
        setChartAcc(!chartAcc);
        break;
      case "scqam":
        setScqamAcc(!scqamAcc);
        break;
      case "ofdm":
        setOfdmAcc(!ofdmAcc);
        break;
    }
  };
  let cmDataConfig = CmViewData();
  let dsSummaryConfig = cmDataConfig?.downStream?.summary;

  let dsScQAMData =
    flag === true
      ? preCmData
        ? preCmData?.docsis30?.downstream
        : cmData?.docsis30?.downstream
      : cmData?.docsis?.docsis30?.downstream;
  let ofdmData =
    flag === true
      ? preCmData
        ? preCmData?.ofdm?.downstreamChannels
        : cmData?.ofdm?.downstreamChannels
      : cmData?.docsis?.ofdm?.downstreamChannels;
  let dsChartRanges = getMinMaxRange(
    flag === true ? (preCmData ? preCmData : cmData) : cmData?.docsis,
    "ds"
  );
  return (
    <div>
      {preCmData && (
        <CertiExceptionCont
          dataObj={flag === true ? cmData : cmData?.docsis}
          sectionName={labels.DOWNSTREAM}
        >
          <div
            className="dsChDataAcc"
            onClick={() => handleAccToggle("summary")}
          >
            <p>{labels.SUMMARY.toUpperCase()}</p>
            {summaryAcc ? (
              <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
            ) : (
              <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
            )}
          </div>
          {summaryAcc ? (
            <DataCard
              cardHeader={""}
              cardBody={
                <div>
                  {/* display the comparative table   */}
                  <CmSpecSummary
                    cmConfig={dsSummaryConfig?.individual}
                    cmData={cmData}
                    preCmData={preCmData}
                  />
                  {/* display precheck certificate values  */}
                  <CmSpecSummary
                    cmConfig={dsSummaryConfig?.common}
                    cmData={preCmData}
                    isPrecheck={true}
                  />
                  {/* display postcheck certificate values  */}
                  <CmSpecSummary
                    cmConfig={dsSummaryConfig?.common}
                    cmData={cmData}
                  />
                </div>
              }
            />
          ) : (
            <></>
          )}
        </CertiExceptionCont>
      )}

      <div className="dsChDataAcc" onClick={() => handleAccToggle("chart")}>
        <p>
          {preCmData
            ? labels.PRECHECK_CHART.toUpperCase()
            : labels.chart.toUpperCase()}
        </p>
        {chartAcc ? (
          <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
        ) : (
          <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
        )}
      </div>
      {chartAcc ? (
        <CmDsChart
          cardLabel={""}
          chartRanges={dsChartRanges}
          scQamChs={dsScQAMData?.channels}
          ofdmChs={ofdmData}
        />
      ) : (
        <></>
      )}
      {dsScQAMData && flag === false && (
        <>
          <div className="dsChDataAcc" onClick={() => handleAccToggle("scqam")}>
            <p>
              {preCmData
                ? labels.PRECHECK_SCQAM.toUpperCase()
                : labels.SCQAM.toUpperCase()}
            </p>
            {scqamAcc ? (
              <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
            ) : (
              <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
            )}
          </div>
          {scqamAcc ? (
            <CmSpecChart
              cmConfig={cmDataConfig}
              cmData={cmData}
              scQamFlag={true}
            />
          ) : (
            <></>
          )}
        </>
      )}
      {ofdmData && flag === false && (
        <>
          <div className="dsChDataAcc" onClick={() => handleAccToggle("ofdm")}>
            <p>
              {preCmData ? labels.PRECHECK_OFDM : labels.OFDM.toUpperCase()}
            </p>
            {ofdmAcc ? (
              <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
            ) : (
              <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
            )}
          </div>
          {ofdmAcc ? (
            <CmSpecChart cmConfig={cmDataConfig} cmData={cmData} />
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};

export default CmDSPrechTab;
