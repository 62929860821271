import { memo, useCallback, useEffect, useState } from "react";
import NodePageTabs from "./NodePageTabs";
import NodeBasicInfo from "../../components/nodeinfo/NodeBasicInfo";
import NodeBasicInfoSM from "../../components/nodeinfo/NodeBasicInfoSM";
import { useDispatch } from "react-redux";
import { ResetNodeInfoState } from "../../store/actions/node/nodeinfo/NodeInfoDetailsAction";
import { SetWorkOrderId } from "../../store/ducksPattern/TechProfile";
import "../../design-tokens/node-page/NodePage.scss";
import { AddNode } from "../../store/actions/node/AllNodesAction";
import { isNodeAvailable } from "../../components/reusable/Util";
import { GetNodeInfoDetails, GetTechInfo } from "../../get-api/GetResponse";
import ExternalAppMenu from "../../components/reusable/ExternalAppMenu";
import {
  GetTelemetryProps,
  GetTopoGeneralProps,
} from "../../topology/util/reduxFunctions/getTopologyState";
import ConfigConst from "../../constants/ConfigConst";
import { getNodeTeleGloabalStatus } from "../../components/reusable/NodeUtil";
import AppLabels from "../../constants/App_Labels";
import NodeViewData from "../../components/nodeinfo/NodeViewData";
import {
  getTeleModemsList,
  setAllDiagLoading,
  stopDiagExecution,
  setModemType,
  setDiagType,
  setIsCWEnabled,
} from "../../topology/store/slices/mapItems/diagnostics/telemetry";
import { addAbortKey } from "../../topology/store/middleware/apiClient";
import { TELE_ABORT_KEY } from "../../topology/store/middleware/abortKeys";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { dataSetType } from "../../constants/TelePrefConfig";
import storeConst from "../../topology/store/storeConst";
import { emtaSwitchDisplay } from "../../topology/util/utilFuncs";
import { setSmtSearchFlag } from "../../topology/store/slices/topoGeneral";
import axios from "axios";
import { useVhubDetailsClient } from "../../network/httpclient/nodeinfo/VhubDetailsClient";
import CMTSClient from "../../network/httpclient/cmts/CMTSClient";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";

const MODEM_TYPE_LIST = [
  {
    label: "CM",
    checked: true,
  },
  {
    label: "EMTA",
    checked: false,
  },
  {
    label: "ECM",
    checked: false,
  },
  {
    label: "STB",
    checked: false,
  },
];

const NodePageDetails = (props: any) => {
  const labels = AppLabels();
  const techData = GetTechInfo()?.data;
  const {
    location: { search },
  } = props;
  const { currNodedata, allNodes, currNodeState, nodedata } =
    GetNodeInfoDetails();
  const { smtSearchFlag } = GetTopoGeneralProps();
  const query = new URLSearchParams(search);
  const dispatch = useDispatch();
  const [workOrderID, setWorkOrderID] = useState<string | null>("");
  const [expand, setExpand] = useState(false);
  const [selectedSmt, setSelectedSmt] = useState("");
  const [errorText, setErrorText] = useState<string | undefined>(undefined);
  const [smtSelectedId, setSmtSelectedId] = useState("");
  const [vhubNumber, setVhubNumber] = useState("");
  let dataSetNames: any = dataSetType?.map((e: any) => {
    return e?.dataSetName;
  });

  const [dataSetName, setDataSetName] = useState(dataSetNames[0]);
  const { getVhubDetails } = useVhubDetailsClient();

  const [RackIDData, setRackIDData] = useState<any>({});

  const {
    allDiagIsLoading,
    allDiagStatus,
    isAborted,
    mlData,
    diagStartTime,
    diagEndTime,
    diagType,
    viewType,
  } = GetTelemetryProps();

  const MODEM_TYPES = MODEM_TYPE_LIST;

  const [selectDropdown, setSelectDropdown] = useState(
    MODEM_TYPES.map((item) => {
      return {
        ...item,
        checked: item.label === "CM",
      };
    })
  );

  const nodeTeleStatus = getNodeTeleGloabalStatus(
    allDiagStatus,
    allDiagIsLoading,
    isAborted
  );

  const isLoading = () => {
    return nodeTeleStatus === ConfigConst.loading.toLowerCase();
  };
  const nodeScoreDataConfig = NodeViewData({
    currNodedata,
    nodeTeleStatus,
    mlData,
    diagStartTime,
    diagEndTime,
  });

  const stopTelemetry = () => {
    dispatch(stopDiagExecution());
  };

  const openEmtaWindow = (eventKey: any) => {
    if (eventKey?.label?.toLowerCase() === "emta") {
      let url: string = "";
      if (currNodedata?.smt) {
        url = `/#/node/emta?smtId=${currNodedata?.smtId}&dataSet=${diagType}&viewType=${viewType}`;
      } else {
        url = `/#/node/emta?nodeId=${currNodedata?.nodeId}&dataSet=${diagType}&viewType=${viewType}`;
      }
      let newWindow = window.open(url, "_blank")!;
      newWindow.focus();
    }
  };
  const openEcmWindow = (eventKey: any) => {
    if (eventKey?.label?.toLowerCase() === "ecm") {
      let url: string = "";
      if (currNodedata?.smt) {
        url = `/#/node/ecm?smtId=${currNodedata?.smtId}&dataSet=${diagType}&viewType=${viewType}`;
      } else {
        url = `/#/node/ecm?nodeId=${currNodedata?.nodeId}&dataSet=${diagType}&viewType=${viewType}`;
      }
      let newWindow = window.open(url, "_blank")!;
      newWindow.focus();
    }
  };
  const openStbWindow = (eventKey: any) => {
    if (eventKey?.label?.toLowerCase() === "stb") {
      let url: string = "";

      url = `/#/node/stb?nodeId=${currNodedata?.nodeId}&dataSet=${diagType}&viewType=${viewType}`;

      let newWindow = window.open(url, "_blank")!;
      newWindow.focus();
    }
  };

  const handleStartButton = () => {
    if (selectDropdown?.length > 0) {
      if (selectDropdown.every((item) => !item.checked)) {
        setErrorText("Please select atleast one modem type");
        return;
      }
      selectDropdown.forEach((item) => {
        switch (item.label?.toUpperCase()) {
          case "CM":
            if (item.checked) {
              runTelemetry();
              dispatch(setModemType("CM"));
            }
            if (dataSetName?.toUpperCase() === ConfigConst.FULL) {
              dispatch(setIsCWEnabled(true));
            } else if (dataSetName?.toUpperCase() === ConfigConst.FULL_NO_CW) {
              dispatch(setIsCWEnabled(false));
            }
            break;
          case "EMTA":
            if (item.checked) {
              openEmtaWindow(item);
            }
            break;
          case "ECM":
            if (item.checked) {
              openEcmWindow(item);
            }
            break;
          case "STB":
            if (item.checked) {
              openStbWindow(item);
            }
            break;
          default:
            break;
        }
      });
    }
  };

  const handleSelectedDataSet = (name: string) => {
    setDataSetName(name);
    let diagType = "";
    if (name === ConfigConst.FULL.toUpperCase()) {
      diagType = storeConst.all;
    } else if (name === ConfigConst.FULL_NO_CW.toUpperCase()) {
      diagType = storeConst.all_no_cw;
    } else {
      diagType = storeConst.summary;
    }
    dispatch(setDiagType(diagType));
  };

  const runTelemetry = () => {
    addAbortKey(TELE_ABORT_KEY);
    if (
      nodeTeleStatus === ConfigConst.complete.toLowerCase() ||
      nodeTeleStatus === ConfigConst.stopped.toLowerCase()
    ) {
      const activeId = currNodedata?.smt
        ? currNodedata?.smtId
        : currNodedata?.nodeId;
      const type = currNodedata?.smt ? "smtid" : "shubid";
      dispatch(
        getTeleModemsList({
          value: activeId,
          type: type,
          diagnoseDevices: true,
          modemType: "CM",
        })
      );
      dispatch(setAllDiagLoading(true));
    } else dispatch(setAllDiagLoading(true));
  };
  useEffect(() => {
    let tempExpand = localStorage.getItem(ConfigConst.NodeDetailsKey);
    if (tempExpand !== null) {
      let parsedVal: boolean = JSON.parse(tempExpand);
      setExpand(parsedVal);
    } else {
      localStorage.setItem(ConfigConst.NodeDetailsKey, JSON.stringify(false));
    }
  }, []);

  useEffect(() => {
    if (search && search.includes("workorderId")) {
      setWorkOrderID(query.get("workorderId"));
    }
  }, [query]);
  useEffect(() => {
    if (workOrderID !== "" && workOrderID !== null) {
      dispatch(SetWorkOrderId(workOrderID));
    }
  }, [workOrderID]);
  const handleToggle = () => {
    setExpand((prev) => !prev);
    localStorage.setItem(ConfigConst.NodeDetailsKey, JSON.stringify(!expand));
  };

  useEffect(() => {
    if (diagType)
      setDataSetName(
        diagType === storeConst.all
          ? ConfigConst.FULL.toUpperCase()
          : ConfigConst.BASIC.toUpperCase()
      );
  }, [diagType]);

  useEffect(() => {
    let id = currNodedata?.smt ? currNodedata?.smtId : currNodedata?.nodeId;
    let entityType = currNodedata?.smt ? "smt" : "shub";
    if (nodedata !== undefined) {
      dispatch(ResetNodeInfoState());
    }
    if (!isNodeAvailable(allNodes, id)) {
      dispatch(AddNode(id, currNodeState, entityType));
    }
    if (currNodedata?.smt && smtSearchFlag === undefined)
      dispatch(setSmtSearchFlag(true));
  }, [currNodedata]);

  useEffect(() => {
    if (diagType && diagType !== "") {
      let datSetName = "";
      if (dataSetNames?.length === 3) {
        if (diagType?.toLowerCase()?.trim() === storeConst.all) {
          datSetName = dataSetNames[0];
        } else if (diagType?.toLowerCase()?.trim() === storeConst.all_no_cw) {
          datSetName = dataSetNames[1];
        } else {
          datSetName = dataSetNames[2];
        }
      } else {
        if (diagType?.toLowerCase()?.trim() === storeConst.all) {
          datSetName = dataSetNames[0];
        } else {
          datSetName = dataSetNames[1];
        }
      }
      setDataSetName(datSetName);
    }
  }, [diagType, dataSetName]);

  useEffect(() => {
    if (currNodedata?.vhub === "true") {
      getVhubDetails({ nodeId: currNodedata?.nodeId, nodeType: labels?.DOCSIS })
        .then((data: string) => {
          setVhubNumber(data);
        })
        .catch(console.error);
    }
  }, [getVhubDetails, currNodedata?.nodeId]);

  const apiCmtsIPCall = useCallback(async () => {
    if (currNodedata) {
      try {
        const returnedData = await dispatch(
          CMTSClient.getCMTSRackID(currNodedata?.cmtsIp)
        );
        setRackIDData(returnedData);
      } catch (error) {
        console.error(error);
      }
    }
  }, [currNodedata]);

  useEffect(() => {
    if (currNodedata) {
      apiCmtsIPCall();
    }
  }, [currNodedata, apiCmtsIPCall]);

  //NodeTelemetryBatchHandler();
  return (
    <>
      <div className="nodeBasicDetailsCont">
        {expand ? (
          <div className="nodeContainer">
            <NodeBasicInfo
              modemTypeList={MODEM_TYPES}
              data={currNodedata}
              rackidData={RackIDData}
              vhubNumber={vhubNumber}
              mlData={mlData}
              allDiagIsLoading={allDiagIsLoading}
              onSelectedSMT={setSelectedSmt}
              smtSearchId={setSmtSelectedId}
              isLoading={isLoading}
              stopDiag={stopTelemetry}
              startDiag={handleStartButton}
              dataSetName={dataSetName}
              dataSetNames={dataSetNames}
              setDataSetName={handleSelectedDataSet}
              nodeScoreDataConfig={nodeScoreDataConfig}
              selectedModemType={selectDropdown}
              onSelectedModemType={(eventKey: any, e: any) => {
                setErrorText("");
                setSelectDropdown(eventKey);
              }}
            />
          </div>
        ) : (
          <div className="nodeContainerSm">
            <NodeBasicInfoSM
              modemTypeList={MODEM_TYPES}
              data={currNodedata}
              rackidData={RackIDData}
              vhubNumber={vhubNumber}
              mlData={mlData}
              allDiagIsLoading={allDiagIsLoading}
              dataSetName={dataSetName}
              dataSetNames={dataSetNames}
              setDataSetName={handleSelectedDataSet}
              onSelectedSMT={setSelectedSmt}
              smtSearchId={setSmtSelectedId}
              isLoading={isLoading}
              stopDiag={stopTelemetry}
              startDiag={handleStartButton}
              errorText={errorText}
              nodeScoreDataConfig={nodeScoreDataConfig}
              selectedModemType={selectDropdown}
              onSelectedModemType={(eventKey: any, e: any) => {
                setErrorText("");
                setSelectDropdown(eventKey);
              }}
            />
          </div>
        )}
        <div className={expand ? "nodeTglBtnCont" : "nodeTglBtnContSm"}>
          <ExternalAppMenu />
          <span onClick={handleToggle}>
            {expand ? (
              <IoIosArrowDropup size={25} />
            ) : (
              <IoIosArrowDropdown size={25} />
            )}
          </span>
        </div>
      </div>
      <NodePageTabs
        data={currNodedata}
        selectedSmt={selectedSmt}
        smtSelected={smtSelectedId}
      />
    </>
  );
};
export default memo(NodePageDetails);
