import { useEffect } from "react";
import { GoPin } from "react-icons/go";
import { useDispatch } from "react-redux";

import { setDrawerAutoClose } from "../../../store/slices/drawer/settings";
import { GetSettingsProps } from "../../../util/reduxFunctions/getTopologyState";
import { TOGGLE_DRAWER_AUTO_CLOSE_KEY } from "../../../constants/storageConst";
import TopologyLabels from "../../../constants/topologyLabels";

interface Props {
  showPopover: boolean;
  setShowPopover: (arg0: boolean) => void;
}

export default function DrawerAutoClose({
  showPopover,
  setShowPopover,
}: Props) {
  const labels = TopologyLabels();
  const dispatch = useDispatch();
  const { drawerAutoClose } = GetSettingsProps();

  const storedDrawerToggle = localStorage.getItem(TOGGLE_DRAWER_AUTO_CLOSE_KEY);
  const drawerToggle =
    storedDrawerToggle === "true"
      ? true
      : storedDrawerToggle === "false"
      ? false
      : undefined;

  useEffect(() => {
    if (drawerToggle != undefined) dispatch(setDrawerAutoClose(drawerToggle));
  }, []);

  const onDrawerAutoCloseClick = () => {
    dispatch(setDrawerAutoClose(!drawerAutoClose));
    setShowPopover(!showPopover);
    localStorage.setItem(
      TOGGLE_DRAWER_AUTO_CLOSE_KEY,
      String(!drawerAutoClose)
    );
  };

  return (
    <section
      onClick={onDrawerAutoCloseClick}
      className="popoverItemsCont"
      style={{ color: drawerAutoClose && `var(--link)` }}
    >
      {`${labels.DrawerAutoClose} (${
        drawerAutoClose ? labels.ON : labels.OFF
      })`}
      {!drawerAutoClose && <GoPin />}
    </section>
  );
}
