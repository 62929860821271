export default Object.freeze({
  WO: "WO",
  SO: "SO",
  ROGERS: "Rogers",
  FIDO: "Fido",
  TPIA: "TPIA",
  LETTER_OF_PERMISSION: "LETTER_OF_PERMISSION",
  LOP_SUBJECT: "Your letter of permission",
  ASP_SUBJECT: "Add STB POD",
  LOP_SUBJECT_ROGERS: "Rogers Communications",
  LOP_SUBJECT_FIDO: "Fido",
  LOP_LANGUAGE: "EN",
  LOP_FILENAME: "Letter of Permission",
  ASP_FILENAME: "Add STB POD",
  RRF_FILENAME: "Riser Repair Referral",
  CEC_FILENAME: "Ignite Experience Certification Form",
  FB_LANGUAGE: "EN",
  FB_FILENAME: "Feedback",
  FB_SUBJECT: "New submission from Feedback-Coach back - Account #",
  SR_LANGUAGE: "EN",
  SR_FILENAME: "Senior Referral",
  PRE_JOB_INSPECTION: "PRE_JOB_INSPECTION",
  SENIOR_REFERRAL: "SENIOR_REFERRAL",
  COACH_BACK: "COACH_BACK",
  CUSTOMER_EXPERIENCE_CERTIFICATION: "Ignite Experience Certification",
  EQUIPMENT_PICKUP_RECEIPT: "EQUIPMENT_PICKUP_RECEIPT",
  NOISE_REFERRAL: "NOISE_REFERRAL",
  RPA_ADDPODORSTB: "RPA_ADDPODORSTB",
  EPR_SUBJECT: "Your equipment pickup receipt",
  EPR_SUBJECT_ROGERS: "Rogers Communications",
  EPR_SUBJECT_FIDO: "Fido",
  EPR_LANGUAGE: "EN",
  RISER_REPAIR_FORM: "RISER_REPAIR_FORM",
  TECHNICIAN: "technician",
  EXTERNAL_TECHNICIAN: "external-technician",
  MANAGER: "manager",
  ADMIN: "admin",
  Resolved: "Resolved",
  Unresolved: "Unresolved",
  OK: "OK",
  ERROR: "ERROR",
  Yes: "Yes",
  No: "No",
  topology: "topology",
  FECERRORSDSPROFILE: "FECERRORSDSPROFILE",
  account: "account",
  network: "network",
  history: "history",
  node: "node",
  gnode: "gnode",
  fileManager: "file-manager",
  rfCalculator: "rf-calculator",
  rfMaintenanceCalculator: "rf-mCalculator",
  NO_WORK_ORDER: "NO_WORK_ORDER",
  open: "open",
  close: "close",
  fullScreen: "fullScreen",
  standard: "standard",
  IEC: "IEC",
  modem: "modem",
  cableModem: "cablemodem",
  scQamTx: "scqam-tx",
  scQamRx: "scqam-rx",
  scQamSnr: "scqam-snr",
  ofdmaTx: "ofdma-tx",
  ofdmaPct: "ofdma-2pct",
  ofdm33Rx: "ofdm-33-rx",
  ofdm34Rx: "ofdm-34-rx",
  ofdm33Pct: "ofdm-33-2pct",
  ofdm34Pct: "ofdm-34-2pct",
  smtid: "smtid",
  shubid: "shubid",
  address: "address",
  complete: "complete",
  stopped: "stopped",
  loading: "loading",
  BASIC: "BASIC",
  FULL: "FULL",
  ALL: "ALL",
  FULL_NO_CW: "FULL W/O CW",
  ALL_NO_CW: "ALL W/O CW",
  rpd: "rpd",
  vccap: "vccap",

  ST: "ST",
  MT: "MT",
  EXT_MT: "EXT_MT",
  PT: "PT",
  PROVISION_T: "PROVISION_T",
  HET: "HET",
  EXT_HET: "EXT_HET",
  NW_OP: "NW_OP",
  NOC: "NOC",
  CONST: "CONST",
  EXT_CONST: "EXT_CONST",
  TIER2: "TIER2",
  SUPPORT: "SUPPORT",
  FSC: "FSC",
  OMD: "OMD",
  TTM: "TTM",
  TOPOLOGY: "TOPOLOGY",
  TOPOLOGY_EXCEPT_INT_SERV_TECH: "TOPOLOGY_EXCEPT_INT_SERV_TECH", //used for switch to allow/disallow topology view from internal service techs
  IT_OSS: "IT_OSS",

  SERVICE_TECH: "technician",
  SERVICE_EXT_TECH: "external-technician",
  SERVICE_OTHER_USER_TECH: "other_user",
  SERVICE_TECH_MANAGER: "manager",
  SERVICE_TECH_READ_ONLY: "read_only",

  MAINTENANCE_TECH: "maintenance_tech",
  MAINTENANCE_TECH_MANAGER: "maintenance_tech_manager",
  EXT_MAINTENANCE_TECH: "ext_maint_tech",
  EXT_MAINTENANCE_TECH_MANAGER: "ext_maint_tech_manager",

  PLATFORM_TECH: "platform_tech",
  PLATFORM_TECH_MANAGER: "platform_tech_manager",
  EXT_PLATFORM_TECH: "ext_platform_tech",
  EXT_PLATFORM_TECH_MANAGER: "ext_platform_tech_manager",

  HEADEND_TECH: "headend_tech",
  HEADEND_TECH_MANAGER: "headend_tech_manager",

  EXT_HEADEND_TECH: "ext_headend_tech",
  EXT_HEADEND_TECH_MANAGER: "ext_headend_tech_manager",

  NETWORK_OPERATIONS_TECH: "network_operations_tech",
  NOC_TECH: "noc_tech",

  CONSTRUCTION_TECH: "construction_tech",
  CONSTRUCTION_TECH_MANAGER: "construction_tech_manager",

  EXT_CONSTRUCTION_TECH: "ext_construction_tech",
  EXT_CONSTRUCTION_TECH_MANAGER: "ext_construction_tech_manager",

  TIER2_TECH: "tier2_tech",
  SUPPORT_ENGINEERING_TECH: "support_eng_tech",
  FSC_TECH: "fsc_tech",
  OMD_TECH: "omd_tech",
  TTM_TECH: "ttm_tech",
  IPNCM_Tech: "ipncm_tech",
  IPNCM_Tech_one: "ipcnm_tech",

  TOPOLOGY_TECH: "topology_tech",
  IT_OSS_ADMIN: "admin",
  IT_OSS_BUSINESS_ADMIN: "business_admin",
  LAW_FULL_ACCESS: "lawful-access-response",

  PROVISIONING_TECH: "provisioning_tech",
  PROVISIONING_TECH_MANAGER: "provisioning_tech_manager",
  Enroute: "Enroute",
  Onsite: "Onsite",
  accNum: "accountNumber",
  macAddress: "macAddress",
  tn: "tn",
  ont: "ont",
  cm: "cm",
  emta: "emta",
  ecm: "ecm",
  stb: "stb",
  ipv4: "ipv4",
  ecmstb: "ecmstb",
  oldstb: "oldstb",
  ontSN: "ontSerialNumber",
  invalidEntry: "Invalid Entry",
  olt: "olt",
  neighbourhood: "neighbourhood",
  household: "household",
  f2: "f2",
  shub: "shub",
  NodeDetailsKey: "nodeDetailsDrawerKey",
  operational: "operational",
  registrationComplete: "registrationComplete",
  onlineTekAssigned: "onlineTekAssigned",
  pass: "pass",
  passWithWarnings: "passWithWarnings",
  True: "True",
  unavailable: "unavailable",
  normal: "normal",
  AC_Restored: "AC Restored",
  Battery_Good: "Battery Good",
  Battery_Present: "Battery Present",
  Battery_Not_Low: "Battery Not Low",
  UP: "Up",
  isnr: "isnr",
  onHook: "onHook",
  unknown: "unknown",
  docsis: "docsis",
  gpon: "gpon",
  fwa: "fwa",
  pon: "pon",
  electroline: "electroline",
  cmts: "cmts",
  dummy_w3id: "10001",
});
