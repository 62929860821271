import { GetActiveAccountDetails } from "../../../get-api/GetResponse";
import FormsHistoryTab from "../../../screens/history-page/FormsHistoryTab";
import CustomIcons from "../../reusable/CustomIcons";
import SideTabs from "../../reusable/SideTabs";

export default function HistoryTab() {
  const accountNumber = GetActiveAccountDetails()?.accountNumber;
  const cbpid = GetActiveAccountDetails()?.cbpId;
  const tabsItems = [
    {
      label: "Forms",
      compToRend: (
        <FormsHistoryTab
          accountID={accountNumber}
          cbpid={cbpid}
          fromAccount={true}
        />
      ),
      icon: <CustomIcons name="forms" />,
    },
  ];

  return <SideTabs tabsItems={tabsItems} childrenStyle={{ marginRight: 25 }} />;
}
