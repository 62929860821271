import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@material-ui/core";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";

type Props = {
  tableData: any;
  tableHeader: any[];
  searchAttributes?: any;
  sortAttributes?: any;
  sortingField?: string;
};

enum Colors {
  WARNING = "var(--warning50)",
  CRITICAL = "var(--danger50)",
  NORMAL = "var(--success50)",
}

const status = new Map<string, string>([
  ["warning", Colors.WARNING],
  ["critical", Colors.CRITICAL],
  ["normal", Colors.NORMAL],
]);

export const DataTable: React.FC<Props> = (props: Props) => {
  const { tableData, tableHeader } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPageValue, setRowsPerPageValue] = React.useState(500);
  const [sortingOrder, setSortingOrder] = React.useState("asc");
  const [tableRowData, setTableRowData] = React.useState(tableData || []);
  const [sortingFieldState, setSortingFieldState] = React.useState("");
  const mainTableRef = React.useRef<HTMLTableElement>(null);
  const topScrollRef = React.useRef<HTMLDivElement>(null);
  const [scrollWidth, setScrollWidth] = React.useState<number | undefined>(
    mainTableRef.current?.scrollWidth
  );

  // break the long string into two equal parts and add a line break
  const breakLongString = React.useCallback((str?: string) => {
    if (str) {
      const strLength = str?.trim().split("\n");
      if (strLength.length > 1) {
        // split into four equal
        const firstHalf = strLength?.slice(0, strLength.length / 4);
        const secondHalf = strLength?.slice(
          firstHalf.length,
          firstHalf.length * 2
        );
        const thirdHalf = strLength?.slice(
          secondHalf.length + firstHalf.length,
          secondHalf.length + firstHalf.length * 2
        );
        const fourthHalf = strLength?.slice(
          thirdHalf.length + secondHalf.length + firstHalf.length,
          thirdHalf.length + secondHalf.length + firstHalf.length * 2
        );

        return (
          <span className="certificationTableCell">
            <span
              className="certifyTableCellForDs"
              style={{
                borderRight: "1px solid #6c757d7a",
              }}
            >
              {React.Children.toArray(
                firstHalf?.map((item: string) => (
                  <span className="certificationTableCellForSpace">{item}</span>
                ))
              )}
            </span>
            <br />
            <span
              className="certifyTableCellForDs"
              style={{ borderRight: "1px solid #6c757d7a" }}
            >
              {React.Children.toArray(
                secondHalf?.map((item: string) => (
                  <span className="certificationTableCellForSpace">{item}</span>
                ))
              )}
            </span>
            <br />
            {thirdHalf && (
              <span
                className="certifyTableCellForDs"
                style={{ borderRight: "1px solid #6c757d7a" }}
              >
                {React.Children.toArray(
                  thirdHalf?.map((item: string) => (
                    <span className="certificationTableCellForSpace">
                      {item}
                    </span>
                  ))
                )}
              </span>
            )}
            <br />
            {fourthHalf && (
              <span className="certifyTableCellForDs">
                {React.Children.toArray(
                  fourthHalf?.map((item: string) => (
                    <span className="certificationTableCellForSpace">
                      {item}
                    </span>
                  ))
                )}
              </span>
            )}
          </span>
        );
      }
    }
    return str;
  }, []);

  const onSortingChange = (field: string) => {
    // if the same field is clicked again, then toggle the sorting order
    if (sortingFieldState === field) {
      setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
    }
    // sort the table data
    if (field.trim().toLowerCase() === "address") {
      const reversed = sortingOrder === "asc" ? 1 : -1;
      const sortedData = tableRowData?.sort((a: any, b: any) => {
        const addressA = a?.serviceAddress;
        const addressB = b?.serviceAddress;
        // first compare the street number and then street name and then apartment number
        const streetNumberA = addressA?.streetNumber;
        const streetNumberB = addressB?.streetNumber;
        const streetNameA = addressA?.streetName;
        const streetNameB = addressB?.streetName;
        const apartmentNumberA = addressA?.aptNumber;
        const apartmentNumberB = addressB?.aptNumber;
        const collator = new Intl.Collator(undefined, {
          numeric: true,
          sensitivity: "base",
        });

        if (collator.compare(streetNumberA, streetNumberB) === 0) {
          if (collator.compare(streetNameA, streetNameB) === 0) {
            return (
              collator.compare(apartmentNumberA, apartmentNumberB) * reversed
            );
          }
          return collator.compare(streetNameA, streetNameB) * reversed;
        }
        return collator.compare(streetNumberA, streetNumberB) * reversed;

        // return addressA?.streetName?.localeCompare(addressB?.streetName) === 0
        //   ? addressA?.streetNumber?.localeCompare(addressB?.streetNumber) === 0
        //     ? reversed * addressA?.aptNumber?.localeCompare(addressB?.aptNumber)
        //     : reversed *
        //       addressA?.streetNumber?.localeCompare(addressB?.streetNumber)
        //   : reversed *
        //       addressA?.streetName?.localeCompare(addressB?.streetName);
      });
      setTableRowData(sortedData);
    } else if (field.trim().toLowerCase() === "certstatus") {
      // order the status based on the colors
      const sortedData = tableRowData?.sort((a: any, b: any) => {
        const statusA = a[field];
        const statusB = b[field];
        const reversed = sortingOrder === "asc" ? 1 : -1;
        const statusOrder = ["NORMAL", "WARNING", "CRITICAL"];

        if (statusOrder.indexOf(statusA) === -1) {
          return 1;
        } else if (statusOrder.indexOf(statusB) === -1) {
          return -1;
        } else {
          return statusOrder.indexOf(statusA) > statusOrder.indexOf(statusB)
            ? reversed
            : -1 * reversed;
        }
      });
      setTableRowData(sortedData);
    } else {
      const sortedData = tableRowData?.sort((a: any, b: any) => {
        const reversed = sortingOrder === "asc" ? 1 : -1;
        return reversed * a[field].localeCompare(b[field]);
      });
      setTableRowData(sortedData);
    }
    setSortingFieldState(field);
    setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
  };

  const handleSearch = (searchContent: any, columnKey: string) => {
    if (searchContent) {
      const filteredData = tableRowData.filter((row: any) => {
        // if the search content is a number, then search for the number in the row
        if (!isNaN(searchContent)) {
          return row[columnKey]?.includes(searchContent);
        } else {
          // if the search content is a string, then search for the string in the row
          return row[columnKey]
            ?.toLowerCase()
            .includes(searchContent.trim().toLowerCase());
        }
      });
      setTableRowData(filteredData);
    } else {
      setTableRowData(tableData);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPageValue(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleScroll = (event: React.UIEvent<React.ReactNode>) => {
    const targetDiv: HTMLDivElement = event.target as HTMLDivElement;
    if (targetDiv === topScrollRef.current && mainTableRef.current) {
      mainTableRef.current.scrollLeft = targetDiv.scrollLeft;
    } else if (targetDiv === mainTableRef.current && topScrollRef.current) {
      topScrollRef.current.scrollLeft = targetDiv.scrollLeft;
    }
  };

  React.useEffect(() => {
    if (mainTableRef.current) {
      const debouncedHandler = () =>
        setScrollWidth(mainTableRef.current?.scrollWidth);

      ["resize", "click"].forEach((event) => {
        window.addEventListener(event, debouncedHandler, false);
      });
      setScrollWidth(mainTableRef.current?.scrollWidth);
      return () => {
        ["resize", "click"].forEach((event) => {
          window.removeEventListener(event, debouncedHandler, false);
        });
      };
    }
  }, [mainTableRef.current?.scrollWidth]);

  React.useEffect(() => {
    setTableRowData(tableData);
  }, [tableData]);

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <div
        ref={topScrollRef}
        className="nodeTopScroll"
        style={{
          overflowY:
            mainTableRef.current &&
            mainTableRef.current?.offsetHeight >=
              mainTableRef.current?.scrollHeight
              ? "hidden"
              : "auto",
        }}
        onScroll={handleScroll}
      >
        <div
          style={{
            height: "16px",
            width: scrollWidth,
          }}
        />
      </div>
      <TableContainer
        component={Paper}
        className="nodeTeleTableCont nodeTopScroll"
        style={{ maxHeight: "80vh" }}
        ref={mainTableRef}
        onScroll={handleScroll}
      >
        <Table aria-label="simple table" className="data-table-container">
          <TableHead className="tableHeaderContainerCertification">
            <TableRow>
              {React.Children.toArray(
                tableHeader?.map((header: any, index: number) => (
                  <TableCell
                    align="left"
                    style={{
                      width: header?.width
                        ? `${header?.width} !important`
                        : "auto",
                    }}
                  >
                    <span className="tableHeaderCertification">
                      <span className="tableHeaderTextCertification">
                        {header?.title?.toUpperCase()}

                        {/* if the column is sortable, then show the sort icon */}
                        <span
                          hidden={header?.disableSort}
                          onClick={() => onSortingChange(header?.attribute)}
                        >
                          {sortingFieldState === header?.attribute ? (
                            <button
                              style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                              className="deviceSortBtn"
                            >
                              {sortingOrder === "desc" ? (
                                <FaSortUp color="#000000bf" />
                              ) : (
                                <FaSortDown color="#000000bf" />
                              )}
                            </button>
                          ) : (
                            <button
                              style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                              className="deviceSortBtn"
                            >
                              <FaSort color="#c4c4c4" />
                            </button>
                          )}
                        </span>
                      </span>
                      {/* search bar */}
                      <input
                        type="search"
                        disabled={header?.disableSearch}
                        hidden={header?.disableSearch}
                        placeholder="Search"
                        className="certifyTableSearchBar"
                        onChange={(e) => {
                          handleSearch(e.target.value, header?.attribute);
                        }}
                      />
                    </span>
                  </TableCell>
                ))
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {React.Children.toArray(
              tableRowData
                ?.slice(
                  page * rowsPerPageValue,
                  page * rowsPerPageValue + rowsPerPageValue
                )
                .map((row: any, index: number) => (
                  <TableRow>
                    {React.Children.toArray(
                      tableHeader?.map((header: any, index: number) => {
                        const rowStatus = row[header?.statusAttr] ?? "";
                        if (
                          (header?.attribute === "dsFreq" && row?.dsFreq) ||
                          (header?.attribute === "dsRx" && row?.dsRx) ||
                          (header?.attribute === "dsSnr" && row?.dsSnr)
                        ) {
                          return (
                            <TableCell
                              align="left"
                              padding="none"
                              style={{
                                verticalAlign: "baseline",
                                backgroundColor: rowStatus
                                  ? status.get(rowStatus?.trim()?.toLowerCase())
                                  : "transparent",
                                outline: rowStatus
                                  ? "1px solid lightgray"
                                  : "none",
                                fontWeight: 600,
                              }}
                            >
                              {breakLongString(row[header?.attribute])}
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell
                              align="left"
                              style={{
                                verticalAlign: "baseline",
                                backgroundColor: rowStatus
                                  ? status.get(
                                      rowStatus?.trim()?.toLowerCase()
                                    ) ?? "transparent"
                                  : "transparent",
                                outline: rowStatus
                                  ? "1px solid lightgray"
                                  : "none",
                                fontWeight: 600,
                                whiteSpace: "pre",
                              }}
                            >
                              {row[header?.attribute]}
                            </TableCell>
                          );
                        }
                      })
                    )}
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100, 500]}
        component="div"
        className="tablePaginationCertification"
        count={tableRowData?.length}
        rowsPerPage={rowsPerPageValue}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
};
