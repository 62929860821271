import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../middleware/apiActions";
import {
  CREATE_REDLINE_URI,
  GET_REDLINES_URI,
  REDLINE_URI,
  REDLINE_CONFIGS_URI,
  REDLINE_ATTACHMENTS_URI,
} from "../middleware/apiEndpoints";
import { IAPIStatus } from "../reduxTypes";

const initialState: IRedline = {
  crData: undefined,
  crIsLoading: false,
  crError: undefined,
  crStatus: "",

  updateData: undefined,
  updateIsLoading: false,
  updateError: undefined,
  updateStatus: "",

  redlineData: undefined,
  redlineIsLoading: false,
  redlineError: undefined,
  redlineStatus: "",

  redlinesData: undefined,
  redlinesIsLoading: false,
  redlinesError: undefined,
  redlinesStatus: "",

  configsData: undefined,
  configsIsLoading: false,
  configsError: undefined,
  configsStatus: "",

  attachmentsData: undefined,
  attachmentsIsLoading: false,
  attachmentsError: undefined,
  attachmentsStatus: "",
};

const slice = createSlice({
  name: "redline",
  initialState,
  reducers: {
    crBegin: (state) => {
      state.crIsLoading = true;
      state.crError = undefined;
      state.crData = undefined;
      state.crStatus = "";
    },
    crSuccess: (state, { payload }) => {
      state.crIsLoading = false;
      state.crData = payload;
      state.crStatus = "OK";
    },
    crFailure: (state, { payload }) => {
      state.crIsLoading = false;
      state.crError = payload;
      state.crData = undefined;
      state.crStatus = "ERROR";
    },
    updateBegin: (state) => {
      state.updateIsLoading = true;
      state.updateError = undefined;
      state.updateStatus = "";
    },
    updateSuccess: (state, { payload }) => {
      state.updateIsLoading = false;
      state.updateData = payload;
      state.updateStatus = "OK";
    },
    updateFailure: (state, { payload }) => {
      state.updateIsLoading = false;
      state.updateError = payload;
      state.updateData = undefined;
      state.updateStatus = "ERROR";
    },
    redlineBegin: (state) => {
      state.redlineIsLoading = true;
      state.redlineError = undefined;
      state.redlineStatus = "";
    },
    redlineSuccess: (state, { payload }) => {
      state.redlineIsLoading = false;
      state.redlineData = payload;
      state.redlineStatus = "OK";
    },
    redlineFailure: (state, { payload }) => {
      state.redlineIsLoading = false;
      state.redlineError = payload;
      state.redlineData = undefined;
      state.redlineStatus = "ERROR";
    },
    redlinesBegin: (state) => {
      state.redlinesIsLoading = true;
      state.redlinesError = undefined;
      state.redlinesStatus = "";
    },
    redlinesSuccess: (state, { payload }) => {
      state.redlinesIsLoading = false;
      state.redlinesData = payload;
      state.redlinesStatus = "OK";
    },
    redlinesFailure: (state, { payload }) => {
      state.redlinesIsLoading = false;
      state.redlinesError = payload;
      state.redlinesData = undefined;
      state.redlinesStatus = "ERROR";
    },
    configsBegin: (state) => {
      state.configsIsLoading = true;
      state.configsError = undefined;
      state.configsStatus = "";
    },
    configsSuccess: (state, { payload }) => {
      state.configsIsLoading = false;
      state.configsData = payload;
      state.configsStatus = "OK";
    },
    configsFailure: (state, { payload }) => {
      state.configsIsLoading = false;
      state.configsError = payload;
      state.configsData = undefined;
      state.configsStatus = "ERROR";
    },
    attachmentsBegin: (state) => {
      state.attachmentsIsLoading = true;
      state.attachmentsError = undefined;
      state.attachmentsStatus = "";
    },
    attachmentsSuccess: (state, { payload }) => {
      state.attachmentsIsLoading = false;
      state.attachmentsData = payload;
      state.attachmentsStatus = "OK";
    },
    attachmentsFailure: (state, { payload }) => {
      state.attachmentsIsLoading = false;
      state.attachmentsError = payload;
      state.attachmentsData = undefined;
      state.attachmentsStatus = "ERROR";
    },
  },
});

const {
  crBegin,
  crSuccess,
  crFailure,
  updateBegin,
  updateSuccess,
  updateFailure,
  redlineBegin,
  redlineSuccess,
  redlineFailure,
  redlinesBegin,
  redlinesSuccess,
  redlinesFailure,
  configsBegin,
  configsSuccess,
  configsFailure,
  attachmentsBegin,
  attachmentsSuccess,
  attachmentsFailure,
} = slice.actions;
export default slice.reducer;

/**OPERATIONS */

//createRedline OP
export interface ICreateRedlineInput {
  nodeId?: string;
  projectDesc?: string;
  marketId: string;
  latitude?: any;
  longitude?: any;
  streetAddress: string;
  city?: string;
  cityName?: string;
  province?: string;
  postalCode?: string;
  attachments: { fileName: string; data: string }[];
}
export const createRedline = (data: ICreateRedlineInput): any =>
  apiCallBegan({
    url: CREATE_REDLINE_URI,
    onStart: crBegin.type,
    onSuccess: crSuccess.type,
    onError: crFailure.type,
    data,
    method: "post",
  });

//updateRedline OP
export interface IUpdateRedlineInput {
  trackerKey: string;
  data: {
    comments?: string;
    attachments: { fileName: string; data: string }[];
  };
}
export const updateRedline = ({ trackerKey, data }: IUpdateRedlineInput): any =>
  apiCallBegan({
    url: REDLINE_URI(trackerKey),
    onStart: updateBegin.type,
    onSuccess: updateSuccess.type,
    onError: updateFailure.type,
    data,
    method: "post",
  });

//getRedline OP
export const getRedline = (trackerKey: string): any =>
  apiCallBegan({
    url: REDLINE_URI(trackerKey),
    onStart: redlineBegin.type,
    onSuccess: redlineSuccess.type,
    onError: redlineFailure.type,
  });

//getRedlines OP
export interface IGetRedlinesInput {
  nodeId: string;
  startDate: string;
  endDate: string;
  trackorKey: string;
  lanId: string;
}
export const getRedlines = (data: IGetRedlinesInput): any =>
  apiCallBegan({
    url: GET_REDLINES_URI,
    onStart: redlinesBegin.type,
    onSuccess: redlinesSuccess.type,
    onError: redlinesFailure.type,
    data,
    method: "post",
  });

export const getRedlineConfigs = (): any => (dispatch: any, getState: any) => {
  const { configsData } = getState().slices.topologyState.redlineSlice;

  if (!configsData) {
    dispatch(
      apiCallBegan({
        url: REDLINE_CONFIGS_URI,
        onStart: configsBegin.type,
        onSuccess: configsSuccess.type,
        onError: configsFailure.type,
      })
    );
  }
};

export const getRedlineAttachments = (trackerKey: string): any =>
  apiCallBegan({
    url: REDLINE_ATTACHMENTS_URI(trackerKey),
    onStart: attachmentsBegin.type,
    onSuccess: attachmentsSuccess.type,
    onError: attachmentsFailure.type,
  });

/**INTERFACES */
interface ICRData {
  trackorId: string;
  trackorKey: string;
  errors?: {
    createRedline?: string;
    address?: string;
    attachments?: { fileName: string; message: string }[];
  };
}
interface IUpdateData {
  trackorId: string;
  trackorKey: string;
  errors?: {
    comments?: string;
    attachments?: { fileName: string; message: string }[];
  };
}
interface IConfigsData {
  provinceCities: { [key: string]: { name: string; id: string }[] }[];
  marketIds: string[];
}

interface IAttachmentsData {
  trackorId: string;
  attachments: { fileName: string; data: string }[];
}

export interface IRedline {
  /**crData = create redline data*/
  crData?: ICRData;
  crIsLoading: boolean;
  crError: any;
  crStatus: IAPIStatus;

  redlinesData?: any[];
  redlinesIsLoading: boolean;
  redlinesError: any;
  redlinesStatus: IAPIStatus;

  redlineData?: any;
  redlineIsLoading: boolean;
  redlineError: any;
  redlineStatus: IAPIStatus;

  updateData?: IUpdateData;
  updateIsLoading: boolean;
  updateError: any;
  updateStatus: IAPIStatus;

  configsData?: IConfigsData;
  configsIsLoading: boolean;
  configsError: any;
  configsStatus: IAPIStatus;

  attachmentsData?: IAttachmentsData;
  attachmentsIsLoading: boolean;
  attachmentsError: any;
  attachmentsStatus: IAPIStatus;
}
