import { createSlice } from "@reduxjs/toolkit";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "vccapSWUpgrade",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
    macAddress: undefined,
  },
  reducers: {
    vccapSWUpgradeBegin: (rpd) => {
      rpd.isLoading = true;
    },
    vccapSWUpgradeSuccess: (rpd, action) => {
      rpd.isLoading = false;
      rpd.data = action.payload;
      rpd.error = undefined;
      rpd.status = storeConst.OK;
    },
    vccapSWUpgradeFailure: (rpd, action) => {
      rpd.isLoading = false;
      rpd.error = action.payload;
      rpd.data = undefined;
      rpd.status = storeConst.ERROR;
    },
    vccapSWUpgradeReset: (rpd) => {
      rpd.isLoading = false;
      rpd.error = undefined;
      rpd.data = undefined;
      rpd.status = "";
      rpd.macAddress = undefined;
    },
    setMacAddress: (rpd, action) => {
      rpd.macAddress = action.payload;
      rpd.error = undefined;
      rpd.data = undefined;
      rpd.status = "";
    },
  },
});

export const {
  vccapSWUpgradeBegin,
  vccapSWUpgradeSuccess,
  vccapSWUpgradeFailure,
  vccapSWUpgradeReset,
  setMacAddress,
} = slice.actions;
export default slice.reducer;
