import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useGponSplittersStates } from "../../../../../util/reduxFunctions/getTopologyState";
import TopologyLabels from "../../../../../constants/topologyLabels";
import { getGponSplitter } from "../../../../../store/slices/drawer/passive/gponSplitters";
import ExceptionHandlerWrapper from "../../../../reusable/ExceptionHandlerWrapper";
import Table, { TCTypes, TDTypes } from "../../../../reusable/table/Table";

interface Props {
  elementData: any;
}

export default function GponSplitters({ elementData }: Props) {
  const labels = TopologyLabels();
  const dispatch = useDispatch();
  const { allData } = useGponSplittersStates();
  const lcpNetworkId = elementData?.data?.networkId;
  const sIndex = allData.findIndex(
    (sMap) => sMap?.data?.lcpNetworkId === lcpNetworkId
  );
  const currSplitter = allData?.[sIndex];

  useEffect(() => {
    if (!currSplitter) dispatch(getGponSplitter({ lcpNetworkId }));
  }, [currSplitter]);

  const tableData = currSplitter?.data?.payload?.map((d: any) => {
    return {
      id: d?.networkId,
      name: d?.name,
      equipType: d?.equipType,
    } as TDTypes;
  });

  const tableColumns = [
    { th: labels.name.toUpperCase(), tdName: "name" },
    { th: labels.type.toUpperCase(), tdName: "equipType" },
  ] as TCTypes[];

  return (
    <ExceptionHandlerWrapper
      isLoading={currSplitter?.isLoading}
      status={currSplitter?.status}
      data={tableData}
      onRefresh={() => dispatch(getGponSplitter({ lcpNetworkId }))}
      sectionName={labels.Splitters.toUpperCase()}
    >
      <Table
        title={labels.Splitters.toUpperCase()}
        data={tableData}
        columns={tableColumns}
        newTabTitle={labels.Splitters + ": " + lcpNetworkId}
      />
    </ExceptionHandlerWrapper>
  );
}
