import { Menu, MenuItem } from "@material-ui/core";
import { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MultiONTIPSession from "../../components/ont/MultiONTIPSession";
import {
  UTCtoLocalTime,
  getNodeTeleGloabalStatus,
} from "../../components/reusable/NodeUtil";
import AppLabels from "../../constants/App_Labels";
import { GetFlapsHistory } from "../../get-api/GetResponse";
import ONTClient from "../../network/httpclient/ont/ONTClient";
import NodeModemsClient from "../../network/httpclient/telemetry/NodeModemsClient";
import LoaderGif from "../../resources/images/loader.webp";
import { GetTelemetryProps } from "../../topology/util/reduxFunctions/getTopologyState";
import CertiExceptionCont from "../certification/CertiExceptionCont";
import TelemetryExportCsvPopUp from "./TelemetryExportCsvPopUp";
import TelemetryPopUp from "./TelemetryPopUp";
const TelemetryActionMenu = (props: any) => {
  const {
    menuType,
    viewDropdown,
    rowData,
    columnData,
    lastRefreshView,
    lastRefreshTime,
    nodeId,
    deviceType,
    isNeighbourhood,
    isMultiDevice,
  } = props;
  const { allDiagStatus, allDiagIsLoading, isAborted } = GetTelemetryProps();
  const nodeTeleStatus = getNodeTeleGloabalStatus(
    allDiagStatus,
    allDiagIsLoading,
    isAborted
  );
  const {
    flapHistoryData,
    flapHistoryIsLoading,
    flapHistoryStatus,
    flapHistoryError,
  } = GetFlapsHistory();
  const { ontIPLoading, ontIPInfo, ontIPError } = useSelector(
    (state: any) => ({
      ontIPLoading: state.AccountState.ONTIPInfo.isLoading,
      ontIPInfo: state.AccountState.ONTIPInfo.data,
      ontIPError: state.AccountState.ONTIPInfo.error,
    }),
    shallowEqual
  );
  const labels = AppLabels();
  const dispatch = useDispatch();
  const [showConfirm, setShowConfirm] = useState(false);
  const [open, setOpen] = useState(false);
  const [requestType, setRequestType] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popUpFlag, setPopUpFlag] = useState(false);
  const [popUpPrcrfFlag, setPopUpPcrfFlag] = useState(false);
  const [popUpPcrfBody, setPopUpPcrfBody] = useState(<div>hello</div>);
  const [popUpBody, setPopUpBody] = useState(<div>hello</div>);
  const [headerText, setHeaderText] = useState("");
  const [headerPcrfText, setHeaderPcrfText] = useState("");
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const handleConfirmClose = () => setShowConfirm(false);
  const [aOrigin, setaOrigin] = useState({
    vertical: 15,
    horizontal: 10,
  });
  const [historyData, setHistoryData] = useState();
  const [historyFlag, setHistoryFlag] = useState(false);
  const [pcrfFlag, setPcrfFlag] = useState(false);
  const handleFlapHistory = () => {
    if (props?.handleFlapHistory) {
      props.handleFlapHistory();
    } else {
      dispatch(NodeModemsClient.getFlapHistoryByNodeId(nodeId));
    }
  };
  const handlePcrfSession = () => {
    dispatch(ONTClient.getONTIpSessions(nodeId));
  };

  const telemetryVerticalMenu = isNeighbourhood
    ? [labels.Export_to_CSV]
    : deviceType === "OLDSTB" || deviceType === "STB"
    ? [labels.Export_to_CSV]
    : deviceType === "ONT"
    ? [labels.PCRF]
    : [labels.Flap_History, labels.Export_to_CSV];

  useEffect(() => {
    if (menuType === "vertical") {
      setaOrigin({
        vertical: 40,
        horizontal: 15,
      });
    }
  }, [menuType]);

  useEffect(() => {
    if (flapHistoryStatus !== "") {
      if (flapHistoryStatus === labels.OK) {
        setPopUpBody(
          <>
            <span className="telemertyinfoBox">{labels.History}</span>
            <div className="telemetryInfopopbox">{historyData}</div>
          </>
        );
      } else if (flapHistoryStatus === labels.ERROR) {
        setPopUpBody(
          <CertiExceptionCont
            dataObj={flapHistoryError}
            sectionName="CM Diagnostics"
          />
        );
      }
    }
  }, [flapHistoryStatus, historyData]);

  useEffect(() => {
    if (flapHistoryIsLoading) {
      setPopUpBody(
        <img className="nodeTeleDataLoader" alt="Loader Gif" src={LoaderGif} />
      );
    } else {
      if (flapHistoryData && flapHistoryData?.length > 0) {
        setHistoryData(
          flapHistoryData?.map((flap: any, i: number) => (
            <div key={`flap${flap?.flapHistoryIdDTO?.resetOn + i}`}>
              <div className="flapHistoryDiv">
                <div>
                  <span className="telemertyinfoBox">{labels.ClearedBy}: </span>
                  <span className="telemertyinfoBoxSpan">{flap?.lanId}</span>
                </div>
                <div>
                  <span className="telemertyinfoBox">
                    {labels.Time_and_Date}:{" "}
                  </span>
                  <span className="telemertyinfoBoxSpan">
                    {UTCtoLocalTime(flap?.flapHistoryIdDTO?.resetOnTimeStamp)}
                  </span>
                </div>
                {flap?.device_type && (
                  <div>
                    <span className="telemertyinfoBox">
                      {labels.deviceType}:{" "}
                    </span>
                    <span className="telemertyinfoBoxSpan">
                      {flap?.device_type}
                    </span>
                  </div>
                )}
                {flap?.status === "Success" ? (
                  <div>
                    <span className="telemertyinfoBox">{labels.STATUS}: </span>
                    <span className="telemertyinfoBoxSpan">
                      <span className="successText">{flap?.status}</span>
                    </span>
                  </div>
                ) : (
                  <>
                    <span className="telemertyinfoBox">{labels.STATUS}: </span>
                    <span className="telemertyinfoBoxSpan">
                      <span className="failureText">{flap?.status}</span>
                    </span>
                  </>
                )}
              </div>
            </div>
          ))
        );
      } else {
        setPopUpBody(
          <span className="telemertyinfoBoxSpan">{labels.NO_DATA}</span>
        );
      }
    }

    setHistoryFlag(false);
  }, [flapHistoryData, flapHistoryStatus]);

  useEffect(() => {
    if (ontIPLoading) {
      setPopUpPcrfBody(
        <img className="nodeTeleDataLoader" alt="Loader Gif" src={LoaderGif} />
      );
    } else {
      setPopUpPcrfBody(
        <>
          <MultiONTIPSession selectedDevice={nodeId} />
        </>
      );
    }

    setHistoryFlag(false);
  }, [ontIPLoading, ontIPInfo, pcrfFlag]);

  const handleActionClick = (
    event: React.MouseEvent<HTMLElement>,
    actionType: string
  ) => {
    if (
      /* actionType === "Saved Views" */
      actionType === labels.Export_to_CSV ||
      actionType === labels.Flap_History
    ) {
      setRequestType(actionType);
      setShowConfirm(true);
      handleMenuClose();
      if (actionType === labels.Flap_History) {
        handleFlapHistory();
      }
    }

    if (actionType === labels.Flap_History) {
      setHistoryFlag(true);
      setHeaderText(labels.Flap_History);
      setPopUpFlag(true);
      handleMenuClose();
    }
    if (actionType === labels.PCRF) {
      setPcrfFlag(true);
      setHeaderPcrfText(labels.PCRF);
      setPopUpPcrfFlag(true);
      handlePcrfSession();
      handleMenuClose();
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirm = () => {
    setShowConfirm(false);
  };

  return (
    <div className="deviceActionCont">
      <div className="telemetrydots">
        <button className="nodeTeleActionMenu" onClick={handleMenuClick}>
          <BsThreeDotsVertical />
        </button>
      </div>
      <Menu
        className="telemetryDeviceActionMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={aOrigin}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {telemetryVerticalMenu.length !== 0 ? (
          telemetryVerticalMenu.map((a: any, i: number) => {
            return (
              <MenuItem
                key={i}
                className={`runAction ${
                  a === labels.Export_to_CSV &&
                  nodeTeleStatus === "loading" &&
                  "disabledMenuItem"
                }`}
                onClick={(e) => handleActionClick(e, a)}
              >
                {a}
              </MenuItem>
            );
          })
        ) : (
          <>{labels.NO_ACTIONS}</>
        )}
      </Menu>
      {requestType === labels.Export_to_CSV && (
        <div className="abc">
          <TelemetryExportCsvPopUp
            showVal={showConfirm}
            headerText={labels.Export}
            bodyHeadText={labels.Export_Details}
            bodyText={labels.ARE_YOU_SURE_TO_PROCEED}
            cancelText={labels.CANCEL}
            confirmText={labels.PROCEED}
            handleConfirm={handleConfirm}
            handleClose={handleConfirmClose}
            viewDropdown={viewDropdown}
            rowData={rowData}
            columnData={columnData}
            deviceType={deviceType}
            deviceId={nodeId}
            isMultiDevice={isMultiDevice}
          />
        </div>
      )}
      <TelemetryPopUp
        showVal={popUpFlag}
        headerText={headerText}
        bodyElement={popUpBody}
        handleClose={() => setPopUpFlag(false)}
        requestType={requestType}
      />
      {popUpPrcrfFlag == true && (
        <TelemetryPopUp
          showVal={popUpPrcrfFlag}
          headerText={headerPcrfText}
          bodyElement={popUpPcrfBody}
          handleClose={() => setPopUpPcrfFlag(false)}
          requestType={requestType}
        />
      )}
    </div>
  );
};

export default TelemetryActionMenu;
