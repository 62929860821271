import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import AppLabels from "../../../constants/App_Labels";
import ConfigConst from "../../../constants/ConfigConst";
import {
  GetActiveAccountDetails,
  GetFormHistory,
  GetFormInfo,
  GetTechInfo,
} from "../../../get-api/GetResponse";
import EmailAuditClient from "../../../network/httpclient/EmailAudit/EmailAuditClient";
import FormClient from "../../../network/httpclient/form/FormClient";
import FormsHistoryClient from "../../../network/httpclient/history/FormsHistoryClient";
import ExceptionBox from "../../reusable/ExceptionBox";
import { getDate } from "../../reusable/Util";
import FormBoilerplate from "../FormBoilerplate";
import FeedBackBody from "./FeedBackBody";
import { convertHTMLtoStr, isNull } from "../../reusable/Util";
import PrepareFBEmailFormat from "./PrepareFBEmailFormat";
import EmailClient from "../../../network/httpclient/notification/EmailClient";
import CustomPopUp from "../../reusable/CustomPopUp";
interface Props {
  setShowModal: any;
}

export default function FeedBack({ setShowModal }: Props) {
  const labels = AppLabels();
  const dispatch = useDispatch();
  const formHistory = GetFormHistory();
  const accountNumber = GetActiveAccountDetails()?.accountNumber;
  const formInfo = GetFormInfo();
  const isFormComplete = formHistory?.formData?.referralStatus;
  const techid = GetTechInfo()?.techId;
  const [isWOSelectionComplete, setIsWOSelectionComplete] = useState(false);
  const [woData, setWOData] = useState({});
  const [selectedWOID, setSelectedWOID] = useState(labels.Select);
  const [techDetails, setTechDetails] = useState({} as any);
  const workOrderData = [labels.No_Work_Order, labels.Manual_Entry];
  const profileInfo = GetTechInfo()?.data;
  const [showFlag, setShowFlag] = useState(false);
  const activeAccData = GetActiveAccountDetails()?.data;
  const {
    isLoading,
    status,
    formStatus,
    isFormLoading,
    refKey,
    EmailFormData,
    sentby,
  } = useSelector(
    (state: any) => ({
      isLoading: state.AccountState.sendEmailData.isLoading,
      status: state.AccountState.sendEmailData.status,
      formStatus: state.AccountState.FormInfo.status,
      isFormLoading: state.AccountState.FormInfo.isLoading,
      refKey: state.AccountState.FormInfo?.data?.id,
      EmailFormData: state.AccountState.FormInfo?.data?.formData,
      sentby: state.AccountState.FormInfo?.data?.technicianId,
    }),
    shallowEqual
  );
  const onSubmit = () => {
    const generateData = {
      formName: ConfigConst.COACH_BACK,
      workorderId:
        selectedWOID === labels.No_Work_Order
          ? ConfigConst.NO_WORK_ORDER
          : selectedWOID,
      accountNumber,
      formData: JSON.stringify({ ...woData, ...techDetails }),
    };
    // if (formHistory?.data?.id) {
    // dispatch(FormClient.updateForm(generateData, formHistory?.data?.id));
    //}
    dispatch(FormClient.postForm(generateData));
    sendEmail();
  };

  const onPreview = () => {
    setShowFlag(true);
  };
  const sendEmail = () => {
    const emailSub = ConfigConst.FB_SUBJECT + accountNumber;
    const htmlMessage = convertHTMLtoStr(
      <PrepareFBEmailFormat
        techId={profileInfo?.techId}
        feedbackGroup={techDetails?.menuItem2}
        feedbackType={techDetails?.menuItem}
        accountNumber={accountNumber}
        woID={isNull(selectedWOID)}
        feedbackDetails={techDetails}
        previewFlag={false}
      />
    );
    let emailPayload = {
      accountNumber: accountNumber,
      franchise: "R",
      firstName: activeAccData?.firstName,
      lastName: activeAccData?.lastName,
      subject: emailSub,
      htmlMessage: htmlMessage,
      language: ConfigConst.FB_LANGUAGE,
      attachFileName: ConfigConst.FB_FILENAME,
      // addresses: ["ontariofieldescalati@rci.rogers.com"],
      // addresses:"",
      formName: ConfigConst.FB_FILENAME.toUpperCase()
      // addresses: ["lakshmi.narayanan1@rci.rogers.ca"],
    };
    const base64EncObj = { data: btoa(JSON.stringify(emailPayload)) };
    dispatch(EmailClient.sendEmailNoti(labels.CbEmailMSG, base64EncObj));
  };

  const disableSubmit = () => {
    if (
      isWOSelectionComplete &&
      techDetails &&
      !formInfo.isLoading &&
      techDetails?.menuItem !== undefined &&
      techDetails?.menuItem2 !== undefined
    ) {
      if (
        techDetails.menuItem === labels.Quality &&
        techDetails.menuItem2 === labels.Technician
      ) {
        return !(techDetails.files && techDetails.files.length !== 0);
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const onBannerSuccess = () => {
    //get latest forms history
    dispatch(
      FormsHistoryClient.getHistory(
        "",
        "",
        accountNumber,
        "",
        "",
        getDate("yesterday"),
        getDate("now"),
        "",
        25,
        0,
        ""
      )
    );
  };
  useEffect(() => {
    const generateEmailData = {
      componentType: ConfigConst.COACH_BACK,
      refKey: refKey,
      sentTo: "ontariofieldescalati@rci.rogers.com",
      sentby: sentby,
      techid: techid,
    };

    // sendEmail();
    if (
      isLoading === false &&
      status === "OK" &&
      formStatus === "OK" &&
      isFormLoading === false &&
      EmailFormData !== undefined
    ) {
      dispatch(EmailAuditClient.postForm(generateEmailData));
    }
  }, [status, formStatus, isFormLoading]);
  return (
    <>
      <FormBoilerplate //Do we need to make our own boiler Plate?
        isWOSelectionComplete={isWOSelectionComplete}
        setIsWOSelectionComplete={setIsWOSelectionComplete}
        setWOData={setWOData}
        formLabel={labels.CB}
        formName={ConfigConst.COACH_BACK}
        onSubmit={onSubmit}
        onPreview={onPreview}
        disableSubmit={disableSubmit}
        selectedWOID={selectedWOID}
        setSelectedWOID={setSelectedWOID}
        setShowEntireModal={setShowModal}
        disableFormUpdate={false}
        onBannerSuccess={onBannerSuccess}
        hideSubmitButton={isFormComplete}
        workOrderData={workOrderData}
      >
        {isFormComplete ? (
          <div className="exceptionBoxCont">
            <ExceptionBox
              headerText={labels.Completed_Feed_Back}
              bodyText={labels.FeedbackExceptionMsg}
            />
          </div>
        ) : (
          <FeedBackBody setDetails={setTechDetails} />
        )}
      </FormBoilerplate>
      <CustomPopUp
        showVal={showFlag}
        headerText={labels.Email_Preview.toUpperCase()}
        bodyElement={
          <PrepareFBEmailFormat
            techId={profileInfo?.techId}
            feedbackGroup={techDetails?.menuItem2}
            feedbackType={techDetails?.menuItem}
            accountNumber={accountNumber}
            woID={isNull(selectedWOID)}
            feedbackDetails={techDetails}
            previewFlag={false}
          />
        }
        handleClose={() => setShowFlag(false)}
      />
    </>
  );
}
