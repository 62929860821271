import { NodeSummary } from "../../../models/account/node/NodeSummary";
import { NodeSummaryState } from "../../../states/account/node/NodeSummaryState";
import {
  RESET_NODES,
  SAVE_NODES,
  SET_NODE_GEOCODE,
  UPDATE_NODES,
  INCREMENT_NODE_API_CALL_COUNTER,
  DECREMENT_NODE_API_CALL_COUNTER,
  NodesActionTypes,
} from "../../../types/account/node/NodesSummary";

const initialState: NodeSummaryState = {
  nodeSummaryList: [],
};

export function NodeSummaryReducer(
  state = initialState,
  action: NodesActionTypes
): NodeSummaryState {
  switch (action.type) {
    case SAVE_NODES:
      return {
        ...state,
        nodeSummaryList: action.payload,
      };
    case SET_NODE_GEOCODE:
      return {
        ...state,
        nodeSummaryList: state.nodeSummaryList.map((d: NodeSummary) => {
          if (d.serialNumber === action.snNumber) {
            let geoResponse: any = action.geoCode;
            let geoData = "";
            if (
              geoResponse !== undefined &&
              geoResponse?.results?.[0]?.hasOwnProperty("geometry")
            ) {
              geoData = geoResponse?.results?.[0]?.geometry?.location;
            }
            return {
              ...d,
              ...{ location: geoData },
            };
          } else return d;
        }),
      };
    case INCREMENT_NODE_API_CALL_COUNTER:
      return {
        ...state,
        nodeSummaryList: state.nodeSummaryList.map((d: NodeSummary) => {
          if (d.serialNumber === action.snNumber) {
            let tempData: any = d.data;
            if (action.subDataKey === "summary") {
              tempData = {
                ...tempData,
                ...{
                  summary: {
                    isLoading: true,
                    data: undefined,
                    status: "",
                  },
                },
              };
            }
            return {
              ...d,
              ...{ data: tempData },
            };
          } else return d;
        }),
      };
    case DECREMENT_NODE_API_CALL_COUNTER:
      return {
        ...state,
        nodeSummaryList: state.nodeSummaryList.map((d: NodeSummary) => {
          if (d.serialNumber === action.snNumber) {
            let tempData: any = d.data;
            if (action.subDataKey === "summary") {
              let tempSummaryData = tempData?.summary;
              tempSummaryData = {
                ...tempSummaryData,
                ...{
                  isLoading: false,
                },
              };
              tempData = { ...tempData, ...{ summary: tempSummaryData } };
            }
            return {
              ...d,
              ...{ data: tempData },
            };
          } else return d;
        }),
      };
    case UPDATE_NODES:
      return {
        ...state,
        nodeSummaryList: state.nodeSummaryList.map((d: NodeSummary) => {
          if (d.serialNumber === action.snNumber) {
            let tempData: any = d.data;
            if (action.subdataKey === "summary") {
              let tempSummaryData = tempData?.summary;
              tempSummaryData = {
                ...tempSummaryData,
                ...{
                  data: action.data,
                  status: action.status,
                },
              };
              tempData = { ...tempData, ...{ summary: tempSummaryData } };
            }
            return { ...d, ...{ data: tempData } };
          } else {
            return d;
          }
        }),
      };
    case RESET_NODES:
      return {
        ...state,
        nodeSummaryList: [],
      };
    default:
      return state;
  }
}
