import { STBSummary } from "../../../../models/account/diagnostics/stb/STBSummary";

export const STB_SUMMARY_BEGIN = "STB_SUMMARY_BEGIN";
export const STB_SUMMARY_SUCCESS = "STB_SUMMARY_SUCCESS";
export const STB_SUMMARY_FAILURE = "STB_SUMMARY_FAILURE";

interface StbSummaryBegin {
  type: typeof STB_SUMMARY_BEGIN;
}

interface StbSummarySuccess {
  type: typeof STB_SUMMARY_SUCCESS;
  payload: STBSummary;
}

interface StbSummaryFailure {
  type: typeof STB_SUMMARY_FAILURE;
  payload: Error;
}

export type StbSummaryActionTypes =
  | StbSummaryBegin
  | StbSummarySuccess
  | StbSummaryFailure;
