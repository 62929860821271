import { useEffect, useMemo, useRef, useState } from "react";

import { Checkbox, FormControlLabel } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { FaAngleDown, FaCheckSquare } from "react-icons/fa";
import { ImCheckboxUnchecked } from "react-icons/im";

interface Props {
  ddType?: string;
  ddTitle?: string;
  selectedOption?: string;
  options: any;
  searchFlag: boolean;
  checkboxFlag: boolean;
  setSelectedOption?: any;
  getAttrVisible?: any;
  handleCheckBox?: any;
  selectAttrVal?: any;
  getAttributeFlag?: any;
  columnName?: any;
  placement?: string;
  disabled?: boolean;
}

const CustomDropDown = (props: Props) => {
  const {
    ddType,
    ddTitle,
    selectedOption,
    options,
    searchFlag,
    checkboxFlag,
    setSelectedOption,
    handleCheckBox,
    getAttributeFlag,
    selectAttrVal,
    columnName,
    disabled = false,
  } = props;

  const [searchValue, setSearchValue] = useState("");
  const [optionsDisplay, setOptionsDisplay] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const wrapperRef: any = useRef([]);

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    setOptionsDisplay(false);
  };

  const getAttrVisible = (option: string) => {
    return selectAttrVal?.[option];
  };

  const handleClickOutside = (event: MouseEvent) => {
    const { current: wrap } = wrapperRef;
    if (wrap && wrap?.length !== 0 && !wrap?.contains(event.target)) {
      setOptionsDisplay(false);
    }
  };

  const optionData = useMemo(() => {
    let computedOptions = options;
    if (searchValue !== "") {
      computedOptions = computedOptions.filter((option: any) => {
        if (option?.columnName != undefined)
          return option?.columnName
            ?.toLowerCase()
            .includes(searchValue?.toLowerCase());
        else return option?.toLowerCase()?.includes(searchValue?.toLowerCase());
      });
    }
    return computedOptions;
  }, [searchValue, options]);

  useEffect(() => {
    if (optionsDisplay) {
      setSearchValue("");
    }
  }, [optionsDisplay]);

  useEffect(() => {
    if (selectAttrVal !== null && selectAttrVal !== undefined) {
      const filterApplied = Object.keys(selectAttrVal)?.some(
        (key) => selectAttrVal?.[key] === false
      );
      setIsSelected(filterApplied);
    }
  }, [selectAttrVal]);

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="custDDCont">
      <button
        disabled={disabled}
        className={isSelected ? "custDDBtn filterApplied" : "custDDBtn"}
        onClick={() => setOptionsDisplay(true)}
      >
        <span>
          {ddTitle && <b>{ddTitle}:</b>}
          {selectedOption}
        </span>
        <FaAngleDown />
      </button>
      {optionsDisplay ? (
        <div
          ref={wrapperRef}
          className="custDDOptionsCont"
          style={
            props?.placement?.toLowerCase().trim() === "right"
              ? { right: 0 }
              : undefined
          }
        >
          {searchFlag ? (
            <div className="searchContHead">
              <input
                type="text"
                className="custDDSearch"
                placeholder="Search Here.."
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <span>
                <SearchIcon />
              </span>
            </div>
          ) : (
            <></>
          )}
          <div className="optionsList">
            {optionData !== undefined && optionData?.length !== 0 ? (
              optionData?.map((o: any, index: any) => {
                return (
                  <div
                    className="custDDOption"
                    onClick={
                      checkboxFlag ? undefined : () => handleOptionClick(o)
                    }
                    key={index}
                  >
                    {checkboxFlag ? (
                      <FormControlLabel
                        className="WHTCheck"
                        id={o.columnName}
                        control={
                          <Checkbox
                            checkedIcon={<FaCheckSquare color="#da291c" />}
                            icon={<ImCheckboxUnchecked color="#da291c" />}
                            checked={getAttrVisible(o.columnKey)}
                            onChange={() => handleCheckBox(o.columnKey)}
                          />
                        }
                        label={o.columnName}
                      />
                    ) : (
                      <>{o}</>
                    )}
                  </div>
                );
              })
            ) : (
              <div className="option">
                <span className="historyVal">No Value Found...</span>
              </div>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CustomDropDown;
