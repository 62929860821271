import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Tab, Tabs } from "@material-ui/core";
import AppLabels from "../../constants/App_Labels";
import {
  GetTechInfo,
  GetUtilToolAppConfigProps,
} from "../../get-api/GetResponse";
import { TabPanel } from "../../components/reusable/Util";
import { getUtilToolAppConfig } from "../../topology/store/slices/drawer/utiltool/UtilityToolAppConfig";
import UtilityToolBanner from "./UtilityToolBanner";
import UtilityPageInfo from "./UtilityPageInfo";

const UtilityPage = (props: any) => {
  const { data, isLoading, error, status } = GetUtilToolAppConfigProps();
  const dispatch = useDispatch();
  const labels = AppLabels();
  const techData = GetTechInfo()?.data;
  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    if (techData) {
      dispatch(getUtilToolAppConfig());
    }
  }, [techData]);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <div className="cmtsMainPage">
        <div className="cmtsBannerSec">
          <UtilityToolBanner tabIndex={value} />
        </div>

        <Tabs
          value={value}
          onChange={(event: any, newValue: any) => setValue(newValue)}
          aria-label="simple tabs example"
          className="cmtsTabSec"
        >
          <Tab label="APP TOOL" {...a11yProps(0)} />
        </Tabs>
        <div className="cmtsMainPageSec">
          <TabPanel value={value} index={0}>
            <UtilityPageInfo
              counterTime={undefined}
              utilAppData={data}
              utilAppDataLoading={isLoading}
              error={error}
              status={status}
            />
          </TabPanel>
        </div>
      </div>
    </>
  );
};

export default UtilityPage;
