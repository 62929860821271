import { useEffect, useLayoutEffect, useState } from "react";
import MduMapView from "../components/map/elements/mdu/MduMapView";
import "../../topology/styles/map/MduMap/MDUMap.scss";
import { useLocation } from "react-router-dom";
import { useOnTopologyModuleMount } from "../util/customHooks/topologyHooks";
import {
  GetConfigsProps,
  GetGoogleMaps,
  GetMapDetailsProps,
} from "../util/reduxFunctions/getTopologyState";
import Drawer from "../../components/reusable/Drawer";
import ExceptionHandlerWrapper from "../components/reusable/ExceptionHandlerWrapper";
import AppLabels from "../../constants/App_Labels";
import { getConfigs } from "../store/slices/configs";
import { useDispatch } from "react-redux";
import { setCurrentWindowName } from "../../components/reusable/Util";
import { AiOutlineZoomIn } from "react-icons/ai";
import { setDrawerView } from "../store/slices/mapDrawer";
import ConfigConst from "../../constants/ConfigConst";
import { DrawerViewType } from "../store/reduxTypes";
import MDUDrawerContent from "../components/map/elements/mdu/MDUDrawerContent";
import { GetTechInfo } from "../../get-api/GetResponse";
import TECHClient from "../../network/httpclient/technician/TECHClient";

const MDUMapScreen = (props: any) => {
  const location = useLocation();
  const labels = AppLabels();
  const dispatch = useDispatch();
  const query = new URLSearchParams(location?.search);
  const { gKey, isLoading, status, error } = GetConfigsProps();
  const techData = GetTechInfo()?.data;
  const { zoomLevel } = GetGoogleMaps();
  const { activeMapElement } = GetMapDetailsProps();
  const [owningDrawing, setOwningDrawing] = useState("");

  useEffect(() => {
    let owningDrawNum = query?.get("owningDrawing");
    if (owningDrawNum) {
      setOwningDrawing(owningDrawNum);
      setCurrentWindowName("MDU-" + owningDrawNum);
      dispatch(setDrawerView(ConfigConst.close as DrawerViewType));
      if (techData === undefined) {
        dispatch(TECHClient.getTechniciansProfile());
      }
    }
  }, []);

  useOnTopologyModuleMount();

  useLayoutEffect(() => {
    // remove the navbar from the DOM
    const navbar = document.getElementsByClassName("drawerCloseSec")[0];
    const header = document.getElementsByClassName(
      "headerSec"
    )[0] as HTMLElement;
    const main = document.getElementsByClassName(
      "main-section"
    )[0] as HTMLElement;
    const drawerCont = document.getElementsByClassName(
      "drawerContainer"
    )[0] as HTMLElement;
    if (navbar && header && main) {
      // remove the navbar from the DOM completely and adjust the header and main sections margin-left to 0
      if (header?.firstChild) {
        const headerFirstChild = header?.firstChild as HTMLElement;
        headerFirstChild.style.width = "100%";
      }
      header.style.marginLeft = "auto";
      main.style.marginLeft = "auto";
      drawerCont.style.width = "100%";
      navbar.remove();
    }
  }, []);

  return (
    <>
      <Drawer
        content={<MDUDrawerContent />}
        headerContent={
          <div className="topoSearchCont">
            <div className="flexedRowDiv">
              <div className="zoomCont">
                <label>{zoomLevel}</label>
                <AiOutlineZoomIn />
              </div>
            </div>
          </div>
        }
        isDrawerAccessible={activeMapElement ? true : false}
      >
        <ExceptionHandlerWrapper
          headerMsg={labels.mapExceptionHeaderMsg}
          bodyMsg={labels.Google_map_key_is_not_available}
          isLoading={isLoading}
          status={status}
          error={error}
          data={gKey}
          onRefresh={() => dispatch(getConfigs())}
          failContStyle={{ position: "relative", margin: 10 }}
        >
          <MduMapView owningDrawing={owningDrawing} gKey={gKey} />
        </ExceptionHandlerWrapper>
      </Drawer>
    </>
  );
};

export default MDUMapScreen;
