import { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import "../../design-tokens/header/HeaderBar.scss";
import AppLabels from "../../constants/App_Labels"
import { GetNotificationInfo } from "../../get-api/GetResponse"
import { BsFillCircleFill } from "react-icons/bs";
const NotificationScrollingBanner = (props: any) => {
  
  const dispatch = useDispatch();
  const labels = AppLabels();
  const { openNoti } = props
  const [displayElement, setDisplayElement] = useState(labels.LOADING_NOTIFICATIONS);
  const [allNotiRead, setAllNotiRead] = useState(false)
  const notificationList = GetNotificationInfo()?.notiList

  let index = 0;
  useEffect(() => {
    const interval = setInterval(() => {
      setAllNotiRead(false)

      if (notificationList === undefined) {
        setDisplayElement(labels.LOADING_NOTIFICATIONS);
      }

      else {
        index = incIndex(index, notificationList?.length)
        index === notificationList?.length + 1 ? setAllNotiRead(true) : setDisplayElement(notificationList?.[index]?.title);
        index += 1;
      }
      if (index >= notificationList?.length) {
        index = 0;
      }
    }, 15000);
    return () => {
      setAllNotiRead(true)
      clearInterval(interval);
    };
  }, [notificationList]);

  const handleClick = () => {
    openNoti()
  }
  const incIndex = (index: any, lenOfNoti: any): any => {
    if (lenOfNoti <= 0) {
      return notificationList?.length + 1;
    }
    if (index >= notificationList?.length)
      index = 0

    if (notificationList?.[index]?.isRead === true) {
      return incIndex(index + 1, lenOfNoti - 1)
    }
    return index;
    
  }


  return (
    <div className="notificationbar-container" onClick={handleClick}>

      {allNotiRead ? null :
        <p><BsFillCircleFill color="##FFFFFF"  style={{ height: "9px" }}/><u>{displayElement} </u></p>
      }
    </div>
  );
}

export default NotificationScrollingBanner;
