import { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Button, Modal, Form } from "react-bootstrap";

import "../../../design-tokens/feedback/Feedback.scss";
import CommentItem from "./CommentItem";
import CreateComment from "./CreateComment";
import FeedbackClient from "../../../network/httpclient/feedback/FeedbackClient";
import DiagContainer from "../../reusable/DiagContainer";
import AppLabels from "../../../constants/App_Labels";
import ExceptionBox from "../../reusable/ExceptionBox";
import { GetTechInfo } from "../../../get-api/GetResponse";

const CommentsModal = (props: any) => {
  const labels = AppLabels();
  const dispatch = useDispatch();
  const techData = GetTechInfo()?.data;

  const [newComment, setNewComment] = useState("");
  const onCommentChange = (e: any) => setNewComment(e.target.value);
  const goBack = () => {
    props.setModal(1);
    setNewComment("");
    props.setComments(0);
  };

  const { data, isLoading, error, creationStatus } = useSelector(
    (state: any) => ({
      data: state.FeedbackCommentsInfo.data,
      status: state.FeedbackCommentsInfo.status,
      isLoading: state.FeedbackCommentsInfo.isLoading,
      error: state.FeedbackCommentsInfo.error,
      creationStatus: state.CommentCreationInfo.status,
    }),
    shallowEqual
  );

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const commentToSend = newComment + "-//" + techData?.lanId;
    const body = JSON.stringify({ body: commentToSend });
    dispatch(FeedbackClient.postFeedbackComment(props.idComments, body));
    setNewComment("");
  };

  useEffect(() => {
    if (creationStatus === "OK") {
      dispatch(FeedbackClient.getFeedbackComments(props.idComments));
    }
  }, [creationStatus]);

  const CreateCommentElements = () => {
    if (data[props.idComments]?.comments?.length > 0)
      return data[props.idComments]?.comments?.map((item: any) => (
        <CommentItem key={item.key} comment={item} />
      ));
    else
      return (
        <ExceptionBox
          headerText={labels.NO_COMMENTS}
          bodyText={labels.PROVIDE_COMMENT}
          primaryBtn={false}
          secBtn={true}
        />
      );
  };

  return (
    <div>
      <Modal
        id="comments-feedback-container"
        onHide={() => {}}
        show={props.showComments === 1 ? true : false}
        dialogClassName="modal-90w-feedback"
      >
        <Modal.Header bsPrefix="fbHeader">
          {labels.COMMENTS}
          <Button bsPrefix="close" onClick={() => goBack()}>
            ✕
          </Button>
        </Modal.Header>
        <Modal.Body bsPrefix="modal-body">
          <Button bsPrefix="back-button" onClick={() => goBack()}>
            {labels.BACK}
          </Button>
          <DiagContainer
            data={data}
            isLoading={false}
            error={error}
            sectionName="Comments"
            handleRefresh={() => {
              dispatch(FeedbackClient.getFeedbackComments(props.idComments));
            }}
          >
            <Form className="feedback-container">
              <div className="comments-container">
                <CreateCommentElements />
              </div>
            </Form>

            <CreateComment
              onCommentChange={onCommentChange}
              handleSubmit={handleSubmit}
              comment={newComment}
            />
          </DiagContainer>
        </Modal.Body>
        {creationStatus === "ERROR" ? (
          <p className="errorComment">{labels.ERROR_COMMENT}</p>
        ) : (
          <></>
        )}
      </Modal>
    </div>
  );
};

export default CommentsModal;
