import { AccountDetails } from "../../../models/account/accountInfo/AccountDetails";

export const ACCOUNT_DETAILS_BEGIN = "ACCOUNT_DETAILS_BEGIN";
export const ACCOUNT_DETAILS_SUCCESS = "ACCOUNT_DETAILS_SUCCESS";
export const ACCOUNT_DETAILS_FAILURE = "ACCOUNT_DETAILS_FAILURE";
export const ACCOUNT_RESET_STATUS = "ACCOUNT_RESET_STATUS";
export const RESET_SEARCH_ACC_STATE = "RESET_SEARCH_ACC_STATE";

interface AccountDetailsBegin {
  type: typeof ACCOUNT_DETAILS_BEGIN;
}
interface AccountDetailsSuccess {
  type: typeof ACCOUNT_DETAILS_SUCCESS;
  payload: AccountDetails;
}
interface AccountDetailsFailure {
  type: typeof ACCOUNT_DETAILS_FAILURE;
  payload: Error;
}
interface AccountResetStatus {
  type: typeof ACCOUNT_RESET_STATUS;
}
interface ResetSearchAccState {
  type: typeof RESET_SEARCH_ACC_STATE;
}

export type AccountDetailsActionTypes =
  | AccountDetailsBegin
  | AccountDetailsSuccess
  | AccountDetailsFailure
  | AccountResetStatus
  | ResetSearchAccState;
