import DataDisplayTable from "../reusable/DataDisplayTable";
import CustomAccordion from "../reusable/CustomAccordion";
import AppLabels from "../../constants/App_Labels";
import ExceptionBox from "../reusable/ExceptionBox";
import { isNull } from "../reusable/Util";

const IPTVWIFIInfo = (props: any) => {
  const { wifiData, wifiError, refreshData } = props;
  const labels = AppLabels();
  return (
    <CustomAccordion
      accTitle={labels.IPTV_WIFI.toUpperCase()}
      accBodyElement={
        <>
          {wifiData !== undefined && !wifiData?.hasOwnProperty("errorCode") ? (
            <DataDisplayTable dataObject={wifiData} tableName="iptvWIFI" />
          ) : (
            <div className="diagErrorCont">
              <ExceptionBox
                exBoxType="error"
                errSecName="IPTV WiFi"
                errCode={isNull(wifiError?.errorCode)}
                srcSystem={
                  wifiError?.hasOwnProperty("sourceSystem")
                    ? wifiError?.sourceSystem
                    : "Request"
                }
                ErrorMSG={isNull(wifiError?.errorMessage)}
                primaryBtn={true}
                secBtn={true}
                handleRefresh={() => refreshData()}
              />
            </div>
          )}
        </>
      }
    />
  );
};

export default IPTVWIFIInfo;
