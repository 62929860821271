import "../../design-tokens/account-page/FailedAttributes.scss";

import { nbaData } from "./NextBestActionConfig";
import CustomAccordion from "../reusable/CustomAccordion";
interface propsNBA {
  data: { name: string; value?: any };
  status: string;
}

const NextBestAction = (props: propsNBA) => {
  const { data, status } = props;

  function findPreCheckAction(data: any) {
    const { name, value, label } = data;
    const foundItems: any[] = [];
    // Check This: When 2 attrs displayed in the table and one fails, both NBAs are shown
    const findNBA = (label: string) => {
      const elementSubAttr = label?.toUpperCase().replace(/[^a-zA-Z]/g, "");
      const elementChannel = name?.toUpperCase().replace(/[^a-zA-Z]/g, "");
      const elementStatus = status;
      return nbaData?.find(
        (item) => {
          const nbaItemSubAttr = item.SubAttribute?.toUpperCase()?.replace(/[^a-zA-Z]/g, "");
          const nbaItemStatus = item?.State;
          const nbaItemChannel = item.Channel?.toUpperCase()?.replace(/[^a-zA-Z]/g, "");
          return elementSubAttr === nbaItemSubAttr &&
            elementChannel?.includes(nbaItemChannel) &&
            elementStatus === nbaItemStatus
        }
      );
    };
    if (label[0] == "name" && label[1] == "value") {
      value?.map((v: any, i: any) => {
        const foundItem = findNBA(v["name"]);
        if (foundItem && !foundItems?.includes(foundItem)) {
          foundItems?.push(foundItem);
        }
      });
    } else {
      label?.map((label: string) => {
        const foundItem = findNBA(label);
        if (foundItem && !foundItems?.includes(foundItem)) {
          foundItems?.push(foundItem);
        }
      });
    }

    return <>{foundItems?.map((item) => renderPreCheckAction(item))}</>;
  }

  const openNewTab = (target: string) => {
    window.open(target, "_blank");
  };

  function renderPreCheckAction(item: any) {
    return (
      <CustomAccordion
        accTitle={`${item?.ParentAttribute}  -  ${item?.ChildAttribute} - ${item?.SubAttribute}`}
        initialOpenState={false}
        key={`` + Math.random()}
        accBodyElement={
          <>
            <span className="tableVlue">
              {item?.PreCheckAction?.map((action: any, index: any) => (
                <span className="action" key={index}>
                  - {action}
                  {action?.includes("drop loss") && (
                    <span
                      className="tooltipText"
                      onClick={() => openNewTab("/#/rf-calculator")}
                    >
                      Use RF Calculator
                    </span>
                  )}
                </span>
              ))}
            </span>
          </>
        }
      />
    );
  }
  return <>{findPreCheckAction(data)}</>;
};
export default NextBestAction;
