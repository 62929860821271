import { DeviceActionState } from "../../../states/account/deviceActions/DeviceActionState";

import {
  FACTORY_RESET_BEGIN,
  FACTORY_RESET_SUCCESS,
  FACTORY_RESET_FAILURE,
  FACTORY_RESET_STATUS,
  FactoryResetActionTypes,
} from "../../../types/account/deviceActions/FactoryReset";

const initialState: DeviceActionState = {
  isLoading: false,
  error: undefined,
  status: "",
};
export function FactoryResetReducer(
  state = initialState,
  action: FactoryResetActionTypes
): DeviceActionState {
  switch (action.type) {
    case FACTORY_RESET_BEGIN:
      return {
        isLoading: true,
      };
    case FACTORY_RESET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: "OK",
      };
    case FACTORY_RESET_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        status: "ERROR",
      };
    case FACTORY_RESET_STATUS:
      return {
        ...state,
        status: "",
      };
    default:
      return state;
  }
}
