import errImg from "../../resources/images/icons/404Error.png";
import timeout from "../../resources/images/icons/timeOutError.svg";
import emptyData from "../../resources/images/icons/EmptyDataError.svg";
const ExceptionBox = (props: any) => {
  const {
    exBoxType,
    errSecName,
    headerText,
    errorDeviceSkip,
    ErrorMSG,
    bodyText,
    srcSystem,
    errCode,
    primaryBtn,
    handleRefresh,
  } = props;

  return (
    <div className="exceptionCont">
      {exBoxType === "error" ? (
        <img src={errImg} />
      ) : exBoxType === "timeout" ? (
        <img src={timeout} />
      ) : (
        <img src={emptyData} />
      )}
      {exBoxType === "error" ? (
        <h4>
          Sorry! We are unable to pull info for <b>{errSecName}</b> section at
          this time. {errorDeviceSkip}
        </h4>
      ) : exBoxType === "timeout" ? (
        <h4>Sorry! Something just went wrong. {errorDeviceSkip}</h4>
      ) : (
        <h4>{headerText}</h4>
      )}
      {exBoxType === "error" ? (
        <p className="errorMsgCont">
          This is due to the reason - Error Code: {errCode} - “{ErrorMSG}” from{" "}
          {srcSystem}. You can try to refresh or try again after sometime.
        </p>
      ) : exBoxType === "timeout" ? (
        <p>Please try refreshing if data is missing.</p>
      ) : (
        <p>{bodyText}</p>
      )}

      {primaryBtn ? (
        <div>
          <button className="refreshExcBtn" onClick={() => handleRefresh()}>
            Refresh
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ExceptionBox;
