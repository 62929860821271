import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Drawer from "../../components/reusable/Drawer";
import DrawerContent from "../components/drawer/DrawerContent";
import DrawerHeader from "../components/drawer/DrawerHeader";
import AppLabels from "../../constants/App_Labels";
import {
  GetConfigsProps,
  GetMapDrawerProps,
  GetTopoGeneralProps,
} from "../util/reduxFunctions/getTopologyState";
import MapView from "../components/map/MapView";
import { GetMapDetailsProps } from "../util/reduxFunctions/getTopologyState";
import MapItemsErrorHandling from "../components/map/MapItemsErrorHandling";
import "../styles/TopologyPage.scss";
import SearchByNodeUrl from "../../components/search/SearchByNodeUrl";
import NodeTopologySwitch from "../../components/node/NodeTopologySwitch";
import { useOnTopologyModuleMount } from "../util/customHooks/topologyHooks";
import { getConfigs } from "../store/slices/configs";
import ExceptionHandlerWrapper from "../components/reusable/ExceptionHandlerWrapper";

const TopologyHome = (props: any) => {
  const labels = AppLabels();
  const dispatch = useDispatch();
  const { gKey, isLoading, status, error } = GetConfigsProps();
  const mapC = GetMapDetailsProps()?.mapCenter;
  const { activeNodeId } = GetTopoGeneralProps();
  const { drawerTabIndex } = GetMapDrawerProps();
  const [activeTabIndex, SetActiveTabIndex] = useState(0);

  useOnTopologyModuleMount();

  useEffect(() => SetActiveTabIndex(drawerTabIndex), [drawerTabIndex]);

  return (
    <>
      {!activeNodeId ? (
        <SearchByNodeUrl {...props} />
      ) : (
        <>
          <Drawer
            content={
              <DrawerContent
                activeTabIndex={activeTabIndex}
                SetActiveTabIndex={SetActiveTabIndex}
              />
            }
            headerContent={<DrawerHeader />}
          >
            <ExceptionHandlerWrapper
              headerMsg={labels.mapExceptionHeaderMsg}
              bodyMsg={labels.Google_map_key_is_not_available}
              isLoading={isLoading}
              status={status}
              error={error}
              data={gKey}
              onRefresh={() => dispatch(getConfigs())}
              compWithFail={<NodeTopologySwitch />}
              failContStyle={{ position: "relative", margin: 10 }}
            >
              <MapView defaultMapCenter={mapC} gKey={gKey} />
            </ExceptionHandlerWrapper>
          </Drawer>
          <MapItemsErrorHandling />
        </>
      )}
    </>
  );
};

export default TopologyHome;
