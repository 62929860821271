import { useState } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme } from "@material-ui/core/styles";

import AppLabels from "../../../../../constants/App_Labels";
import InitialChecksView from "../inner/InitialChecksView";
import InspectionPhysicalWorkView from "../inner/InspectionPhysicalWorkView";
import GeneralPPEView from "../inner/GeneralPPEView";
import HazardsView from "../inner/HazardsView";
import CommentsView from "../inner/CommentsView";

interface Props {
  initialChecks: any;
  setInitialChecks: any;
  inspectionPhysicalWork: any;
  setInspectionPhysicalWork: any;
  ppe: any;
  setPPE: any;
  hazardsSituations: any;
  setHazardsSituations: any;
  workingfromHeight: any;
  setWorkingfromHeight: any;
  fallProtectionSystem: any;
  setFallProtectionSystem: any;
  anchorage: any;
  setAnchorage: any;
  liveElectricity: any;
  setLiveElectricity: any;
  electricityControls: any;
  setElectricityControls: any;
  confinedSpace: any;
  setConfinedSpace: any;
  confinedSpaceControls: any;
  setConfinedSpaceControls: any;
  comments: any;
  setComments: any;
}

export default function HighRiskView(props: Props) {
  const labels = AppLabels();
  const styles = useStyles();
  const steps = [
    labels.INITIAL_CHECKS_COMPLETED,
    labels.HAZARDS_PRESENT,
    labels.GENERAL_PPE,
    labels.MED_TO_HIGH_HAZARD_ACTIVITIES,
    labels.COMMENTS,
  ];

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{ [k: number]: boolean }>({});

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <InitialChecksView
            initialChecks={props.initialChecks}
            setInitialChecks={props.setInitialChecks}
          />
        );
      case 1:
        return (
          <InspectionPhysicalWorkView
            inspectionPhysicalWork={props.inspectionPhysicalWork}
            setInspectionPhysicalWork={props.setInspectionPhysicalWork}
          />
        );
      case 2:
        return <GeneralPPEView ppe={props.ppe} setPpe={props.setPPE} />;
      case 3:
        return (
          <HazardsView
            hazardsSituations={props.hazardsSituations}
            setHazardsSituations={props.setHazardsSituations}
            workingfromHeight={props.workingfromHeight}
            setWorkingfromHeight={props.setWorkingfromHeight}
            fallProtectionSystem={props.fallProtectionSystem}
            setFallProtectionSystem={props.setFallProtectionSystem}
            anchorage={props.anchorage}
            setAnchorage={props.setAnchorage}
            liveElectricity={props.liveElectricity}
            setLiveElectricity={props.setLiveElectricity}
            electricityControls={props.electricityControls}
            setElectricityControls={props.setElectricityControls}
            confinedSpace={props.confinedSpace}
            setConfinedSpace={props.setConfinedSpace}
            confinedSpaceControls={props.confinedSpaceControls}
            setConfinedSpaceControls={props.setConfinedSpaceControls}
          />
        );
      case 4:
        return (
          <CommentsView
            comments={props.comments}
            setComments={props.setComments}
          />
        );
      default:
        return "";
    }
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    const completedCopy = completed;
    completedCopy[activeStep] = true;
    setCompleted(completedCopy);
  };

  const onStepClick = (step: number) => () => {
    setActiveStep(step);
    const completedCopy = completed;
    completedCopy[step] = true;
    setCompleted(completedCopy);
  };

  const isNextDisabled = (step: number) => {
    const selectedInitialChecks = props.initialChecks?.filter(
      (item: any) => item.value != null
    );
    const selectedinspectionPhysicalWork = props.inspectionPhysicalWork?.filter(
      (item: any) => item.value != null
    );
    if (
      step === 0 &&
      props.initialChecks?.length != selectedInitialChecks?.length
    ) {
      return true;
    } else if (
      step === 1 &&
      props.inspectionPhysicalWork?.length !=
        selectedinspectionPhysicalWork?.length
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="overallPJI">
      <Stepper
        non-linear
        activeStep={activeStep}
        orientation="vertical"
        className={styles.root}
      >
        {steps?.map((label: any, index: any) => (
          <Step key={label} completed={completed[index]}>
            <StepLabel onClick={onStepClick(index)}>{label}</StepLabel>
            <StepContent>
              <div className={styles.actionsContainer}>
                <Typography className="overallPJI">
                  {getStepContent(index)}
                </Typography>
                <div className="btnContainerPJI">
                  {activeStep !== steps?.length - 1 ? (
                    <button
                      onClick={handleNext}
                      className="stepNextBtn"
                      disabled={isNextDisabled(activeStep)}
                    >
                      <span>{labels.Next}</span>
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    border: "4px",
    padding: 0,
    margin: 0,
    marginTop: "20px",
    "& .MuiStepIcon-completed": { color: "var(--good) !important" },
    "& .MuiStepIcon-active": { color: "grey" },
    "& .Mui-disabled .MuiStepIcon-root": { color: "grey" },
  },
  button: {
    marginTop: theme.spacing(5),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));
