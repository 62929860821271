import TopologyLabels from "../../constants/topologyLabels";

interface Props {
  switchValue: boolean;
  setSwitchValue: any;
  popoverMsg?: string;
}

const ToggleSwitch = ({ switchValue, setSwitchValue, popoverMsg }: Props) => {
  const topoLabels = TopologyLabels();
  const popupMsg = popoverMsg
    ? popoverMsg
    : !switchValue
    ? topoLabels?.Show
    : topoLabels?.Hide;

  return (
    <div className="toggleSwitch">
      <label title={popupMsg} className="toggleSwCon">
        <input
          type="checkbox"
          checked={switchValue}
          onChange={() => setSwitchValue(!switchValue)}
        />
        <span className="toggleSwSlider"></span>
      </label>
    </div>
  );
};

export default ToggleSwitch;
