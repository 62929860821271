import {
  FORMS_HISTORY_BEGIN,
  FORMS_HISTORY_SUCCESS,
  FORMS_HISTORY_RESET,
  FORMS_HISTORY_FAILURE,
  FormsHistoryActionTypes,
} from '../../types/history/FormsHistory';

export function formsHistoryBegin(): FormsHistoryActionTypes {
  return {
    type: FORMS_HISTORY_BEGIN,
  };
}

export function formsHistorySuccess(history: any): FormsHistoryActionTypes {
  return {
    type: FORMS_HISTORY_SUCCESS,
    payload: history,
  };
}

export function formsHistoryReset(history: any): FormsHistoryActionTypes {
  return {
    type: FORMS_HISTORY_RESET,
    payload: history,
  };
}

export function formsHistoryFailure(error: Error): FormsHistoryActionTypes {
  return {
    type: FORMS_HISTORY_FAILURE,
    payload: error,
  };
}
