import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../middleware/apiActions";
import { CONFIG_TOPOLOGY_URI } from "../middleware/apiEndpoints";
import { IAPIStatus } from "../reduxTypes";

export interface ITopoConfigData {
  clickConfig: { status: string[]; orderCategory: any };
  redlineConfig: { marketIds: string[] };
  batchConfig: any;
}

export interface ITopoConfig {
  data?: ITopoConfigData;
  isLoading: boolean;
  error: any;
  status: IAPIStatus;
}

const slice = createSlice({
  name: "topologyConfig",
  initialState: {
    data: undefined,
    isLoading: false,
    error: undefined,
    status: "",
  } as ITopoConfig,
  reducers: {
    configsBegin: (configs) => {
      configs.isLoading = true;
    },
    configsSuccess: (configs, action) => {
      configs.isLoading = false;
      configs.data = action.payload;
      configs.status = "OK";
    },
    configsFailure: (configs, action) => {
      configs.isLoading = false;
      configs.error = action.payload;
      configs.data = undefined;
      configs.status = "ERROR";
    },
  },
});

const { configsBegin, configsSuccess, configsFailure } = slice.actions;
export default slice.reducer;

export const getTopoConfig = (): any => (dispatch: any, getState: any) => {
  const { data } = getState().slices.topologyState.topologyConfig;

  if (!data) {
    dispatch(
      apiCallBegan({
        url: CONFIG_TOPOLOGY_URI,
        onStart: configsBegin.type,
        onSuccess: configsSuccess.type,
        onError: configsFailure.type,
      })
    );
  }
};
