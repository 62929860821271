import { CSSProperties } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";

import "../../styles/ReusableComp.scss";

interface Props {
  inputValue: string;
  setInputValue: (arg0: any) => void;
  onKeyPress?: (arg0: any) => void;
  onIncPress: () => void;
  onDecPress: () => void;
  placeholder: string;
  style?: CSSProperties;
  minDec?: number;
  maxInc?: number;
}

export default function NumberInputStepper({
  inputValue,
  setInputValue,
  placeholder,
  onIncPress,
  onDecPress,
  style,
  maxInc,
  minDec,
  onKeyPress,
}: Props) {
  let minBtnDisable = false;
  if (minDec && minDec >= Number(inputValue)) minBtnDisable = true;
  let maxBtnDisable = false;
  if (maxInc && maxInc <= Number(inputValue)) maxBtnDisable = true;

  return (
    <div style={style}>
      <InputGroup className="stepperInput">
        <Button
          disabled={minBtnDisable}
          id={minBtnDisable ? "stepperMapEleBtnDis" : "stepperMapEleBtn"}
          onClick={onDecPress}
        >
          —
        </Button>
        <FormControl
          placeholder={placeholder}
          className="stepperInput-Text"
          id="stepperEleInput"
          value={inputValue}
          onChange={setInputValue}
          autoComplete="off"
          onKeyPress={onKeyPress}
        />
        <Button
          disabled={maxBtnDisable}
          id={maxBtnDisable ? "stepperMapEleBtnDis" : "stepperMapEleBtn"}
          onClick={onIncPress}
        >
          +
        </Button>
      </InputGroup>
    </div>
  );
}
