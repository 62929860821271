import { Card } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import GWDevicesClient from "../../network/httpclient/gwDevices/GWDevicesClient";
import DiagContainer from "../reusable/DiagContainer";
import {
  isNull,
  isBoolValue,
  getCheckValue,
  isEmptyObject,
  getCMW3ID,
  getCMW3ID4Brand,
} from "../reusable/Util";
import ReloadData from "../reusable/ReloadData";
import AppLabels from "../../constants/App_Labels";
import { useEffect, useState } from "react";
import ExceptionBox from "../reusable/ExceptionBox";
import { GetActiveAccountDetails } from "../../get-api/GetResponse";

const GWDeviceSummaryInfo = (props: any) => {
  const labels = AppLabels();
  const { selectedDevice, selectedSA } = props;
  const dispatch = useDispatch();
  const [sortedHosts, setSortedHost] = useState([]);
  const brandName = GetActiveAccountDetails()?.brand;

  const { gwLoading, gwDeviceInfo, gwDeviceError,status } = useSelector(
    (state: any) => ({
      gwDeviceInfo: state.AccountState.gwDeviceSummaryInfo.data,
      gwLoading: state.AccountState.gwDeviceSummaryInfo.isLoading,
      gwDeviceError: state.AccountState.gwDeviceSummaryInfo.error,
      status: state.AccountState.gwDeviceSummaryInfo.status,
    }),
    shallowEqual
  );

 
  const refreshData = () => {
    dispatch(
        GWDevicesClient.getGWDeviceSummary(
          selectedDevice.macAddress,
          selectedDevice.serialNumber,
          getCMW3ID4Brand(selectedSA, brandName),
          selectedDevice.oui
        )
      );
  };

  return (
    <>
      <div className="refreshCont">
        <ReloadData refreshData={refreshData} />
      </div>
      <DiagContainer
        isLoading={gwLoading}
        data={gwDeviceInfo}
        sectionName="GW Device Summary"
        error={gwDeviceError}
        handleRefresh={() => refreshData()}
      >
        {gwDeviceInfo !== undefined ? (
          <div className="gwDevicesCont">
            <Card className="dataCard">
              <Card.Body>
                <table className="dataTableDetails">
                {status!=="ERROR"? (
                    <>
                     <thead>
              <tr>
                <th id="nameCol">{labels.NAME.toUpperCase()}</th>
                <th id="valueCol">{labels.VALUE.toUpperCase()}</th>
              </tr>
            </thead>
                      <tbody>
                  
                <tr>
                  <td>From WPIL</td>
                  <td>{isBoolValue(gwDeviceInfo?.fromWpil)}</td>
                </tr>
                <tr>
                  <td>CPU Usage</td>
                  <td>{isNull(gwDeviceInfo?.cpuUsage)}</td>
                </tr>
                <tr>
                  <td>Current Local Time</td>
                  <td>{isNull(gwDeviceInfo?.currentLocalTime)}</td>
                </tr>
                <tr>
                  <td>Firmware File</td>
                  <td>{isNull(gwDeviceInfo?.firmwareFile)}</td>
                </tr>
                <tr>
                  <td>HW Version</td>
                  <td>{isNull(gwDeviceInfo?.hwVersion)}</td>
                </tr>
                <tr>
                  <td>Local Time Zone</td>
                  <td>{isNull(gwDeviceInfo?.localTimeZone)}</td>
                </tr>
                <tr>
                  <td>memFree</td>
                  <td>{isNull(gwDeviceInfo?.memFree)}</td>
                </tr>
                <tr>
                  <td>memTotal</td>
                  <td>{isNull(gwDeviceInfo?.memTotal)}</td>
                </tr>
                <tr>
                  <td>Model Name</td>
                  <td>{isNull(gwDeviceInfo?.modelName)}</td>
                </tr>
                
                <tr>
                  <td>Manufacturer Name</td>
                  <td>{isNull(gwDeviceInfo?.manufacturerName)}</td>
                </tr>
                <tr>
                  <td>ntpEnable</td>
                  <td>{isNull(gwDeviceInfo?.ntpEnable)}</td>
                </tr>
                <tr>
                  <td>ntpServer1</td>
                  <td>{isNull(gwDeviceInfo?.ntpServer1)}</td>
                </tr>
                <tr>
                  <td>ntpServer2</td>
                  <td>{isNull(gwDeviceInfo?.ntpServer2)}</td>
                </tr>
                <tr>
                  <td>ntpStatus</td>
                  <td>{isNull(gwDeviceInfo?.ntpStatus)}</td>
                </tr>
                <tr>
                  <td>OUI</td>
                  <td>{isNull(gwDeviceInfo?.oui)}</td>
                </tr>
                <tr>
                  <td>Serial Number</td>
                  <td>{isNull(gwDeviceInfo?.serialNumber)}</td>
                </tr>
                <tr>
                  <td>swVersion</td>
                  <td>{isNull(gwDeviceInfo?.swVersion)}</td>
                </tr>
                <tr>
                  <td>Uptime</td>
                  <td>{isNull(gwDeviceInfo?.uptime)}</td>
                </tr>
                <tr>
                  <td>Router MAC</td>
                  <td>{isNull(gwDeviceInfo?.routerMAC)}</td>
                </tr>
                <tr>
                  <td>Router IP</td>
                  <td>{isNull(gwDeviceInfo?.routerIP)}</td>
                </tr>
                <tr>
                  <td>Cable MAC</td>
                  <td>{isNull(gwDeviceInfo?.cableMAC)}</td>
                </tr>
                <tr>
                  <td>Cable IP</td>
                  <td>{isNull(gwDeviceInfo?.cableIP)}</td>
                </tr>
                <tr>
                  <td>mtaIP</td>
                  <td>{isNull(gwDeviceInfo?.mtaIP)}</td>
                </tr>
                <tr>
                  <td>ewan</td>
                  <td>{isNull(gwDeviceInfo?.ewan)}</td>
                </tr>
                <tr>
                  <td>mtaMAC</td>
                  <td>{isNull(gwDeviceInfo?.mtaMAC)}</td>
                </tr>
                <tr>
                  <td>Friendly Name</td>
                  <td>{isNull(gwDeviceInfo?.friendlyName)}</td>
                </tr>




                      </tbody>
                    </>
                   ) : (
                    <div className="diagErrorCont">
                      <ExceptionBox
                        headerText="No Data to display in GW Device Summary."
                        bodyText="Please try refreshing or contact the Tech Insight admin if data is missing."
                        primaryBtn={true}
                        secBtn={false}
                        handleRefresh={() => refreshData()}
                      />
                    </div>
                     )}
              </table>
</Card.Body>
</Card>
</div>
) : (
<></>
)}
      </DiagContainer>
    </>
  );
};

export default GWDeviceSummaryInfo;
