import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Card } from "@material-ui/core";
import { Form, DropdownButton, Dropdown } from "react-bootstrap";

import ErrorBox from "../../reusable/ErrorBox";
import AppLabels from "../../../constants/App_Labels";
import ChipGroup from "../../reusable/ChipGroup";
import { TAF_ORDER_CONST } from "../../../constants/Global";
import RPDClient from "../../../network/httpclient/rpd/RPDClient";
import {
  formatMacAddress,
  isMacAddressValid,
  formatRPDMacAddress,
  formathexMacAddress,
} from "../../reusable/Util";
import {
  vccapCASAVideoReset,
  setNPTId,
  setcasaQpsk,
  setcasaAux,
  setcasaDocsisip,
  setcasaVideoip,
  setcasaMapping,
  setcasaVideohostname,
  setcasaDocsishostname,
  setcasaMacdomain,
  setcasaRpdmac,
  setcasaVideoCore,
  setBotType,
} from "../../../store/ducksPattern/rpd/vccapCASAVideo";
import EmailEntry from "../../reusable/EmailEntry";

const CASAVideoExecTopComponent = (props: any) => {
  const { nptIdn, botTypeNew, techData, isLoading } = props;
  const labels = AppLabels();
  const [nptID, setNptID] = useState("");
  //const [qpsk, setQpsk] = useState("");
  const qpskList = ["PONK128"];
  const [qpsk, setQpsk] = useState("");
  const [aux, setAux] = useState("1");
  const [docsisip, setDocsisIp] = useState("");
  //const [videoip, setVideoIp] = useState("");
  const videoIpList = ["66.185.94.254"];
  const [videoip, setVideoIp] = useState("");
  //const [mapping, setMapping] = useState("");
  const builtTypeList = ["FTTC"];
  const [mapping, setMapping] = useState("");
  const [macdomain, setMacDomain] = useState("");
  const [macAddress, setMacAddress] = useState("");
  const [docsishostname, setDocsishostname] = useState("");
  const [videohostname, setVideohostname] = useState("");
  const [videoCore, setVideoCore] = useState("");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("This is Error Message");
  const botTypeList = ["Generation", "Execution"];
  const [botType, setLocalBotType] = useState("Generation");
  const [activeCHipIndex, setActiveCHipIndex] = useState(0);
  const searchTypeChips = [
    { title: labels.botGeneration, description: labels.botTypeGeneration },
    { title: labels.botExecution, description: labels.botTypeExecution },
  ];
  const mdTypeChips = [
    { title: labels.rpdMDType1, description: labels.rpdMDType1 },
    { title: labels.rpdMDType2, description: labels.rpdMDType2 },
  ];
  const lobbyCameraTypeChips = [
    { title: labels.rpdLobbyCamera1, description: labels.rpdLobbyCamera1 },
    { title: labels.rpdLobbyCamera2, description: labels.rpdLobbyCamera2 },
  ];
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [emails, setEmails] = useState([
    techData?.lanId !== null ? techData?.lanId : "",
  ]);
  const [activeLobbyCameraCHipIndex, setActiveLobbyCameraCHipIndex] =
    useState(0);
  const [activeMdTypeCHipIndex, setActiveMdTypeCHipIndex] = useState(0);
  const changeActiveIndex = (data: number) => {
    setActiveCHipIndex(data);

    if (data === 1) setLocalBotType(labels.botExecution);
    else setLocalBotType(labels.botGeneration);

    //dispatch(rpdRegistrationReset());
  };
  const dispatch = useDispatch();

  const onMacDomainSubmit = (e: any) => {
    setMacDomain(e.target.value);
  };
  const onmacAddressSubmit = (e: any) => {
    let sv = formatMacAddress(e.target.value);
    if (sv?.length <= 12) setMacAddress(sv);
  };
  const onDocsishostnameSubmit = (e: any) => {
    setDocsishostname(e.target.value);
  };
  const onVideohostnameSubmit = (e: any) => {
    setVideohostname(e.target.value);
  };

  const onNptIdSubmit = (e: any) => {
    setNptID(e.target.value);
  };
  const onQpskSubmit = (e: any) => {
    setQpsk(e.target.value);
  };
  const onAuxSubmit = (e: any) => {
    setAux(e.target.value);
  };
  const onDocsisipSubmit = (e: any) => {
    setDocsisIp(e.target.value);
  };
  const onVideoipSubmit = (e: any) => {
    setVideoIp(e.target.value);
  };
  const onMappingSubmit = (e: any) => {
    setMapping(e.target.value);
  };

  const onVideoCoreSubmit = (e: any) => {
    setVideoCore(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSubmit(nptID);
    }
  };

  const createInputCASAVideoExecObject = (
    mailId: string,
    npt: string,
    lobbyCam: string,
    mdType: string
  ): any => {
    let inputObject = {
      macDomain: macdomain,
      "RPD-MAC": formathexMacAddress(macAddress),
      "MD-Type": mdType,
      "LOBBY-CAMERA": lobbyCam,
      "MAIL-ID": mailId,
      docsis_ip: docsisip,
      video_ip: videoip,
      QPSK: qpsk,
      docsis_hostname: docsishostname,
      video_hostname: videohostname,
      node_id: nptID,
      "auxiliary-core-list": aux,
      "built-type": mapping,
      video_core_RPD_No: videoCore,
    };
    return inputObject;
  };

  const handleSubmitNew = () => {
    if (nptID?.length > 0) {
      if (emails[0] !== "") {
        var lobbyCam = activeLobbyCameraCHipIndex === 0 ? "Y" : "N";
        var mdtype = activeMdTypeCHipIndex === 0 ? "BDR" : "Non-BDR";

        if (botTypeNew === "Execution") {
          dispatch(
            RPDClient.createCASAVideoExec(
              TAF_ORDER_CONST.vccapCasaConfigBotName,
              TAF_ORDER_CONST.vccapCasaConfigSuiteName,
              createInputCASAVideoExecObject(
                emails.toString(),
                nptID + "",
                lobbyCam,
                mdtype
              )
            )
          );
        } else {
          dispatch(
            RPDClient.createCASAVideoExec(
              TAF_ORDER_CONST.vccapCasaConfigGenerationBotName,
              TAF_ORDER_CONST.vccapCasaConfigSuiteName,
              createInputCASAVideoExecObject(
                emails.toString(),
                nptID + "",
                lobbyCam,
                mdtype
              )
            )
          );
        }
        dispatch(setNPTId(undefined));
      } else {
        setOpen(true);
        setError(labels.sendEmail);
      }
    } else {
      setOpen(true);
      setError(labels.enter_node_id);
    }
  };

  const handleSubmit = (nptID: string) => {
    if (nptID?.length === 6) {
      if (qpsk?.length > 0) {
        if (aux?.length > 0) {
          if (docsisip?.length > 0) {
            if (videoip?.length > 0) {
              if (mapping?.length > 0) {
                if (docsishostname?.length > 0) {
                  if (videohostname?.length > 0) {
                    if (macdomain?.length > 0) {
                      if (macAddress?.length === 12) {
                        if (videoCore?.length > 0) {
                          dispatch(setNPTId(nptID));
                          dispatch(setcasaQpsk(qpsk));
                          dispatch(setcasaAux(aux));
                          dispatch(setcasaDocsisip(docsisip));
                          dispatch(setcasaVideoip(videoip));
                          dispatch(setcasaMapping(mapping));
                          dispatch(setcasaVideohostname(videohostname));
                          dispatch(setcasaDocsishostname(docsishostname));
                          dispatch(setcasaMacdomain(macdomain));
                          dispatch(setcasaRpdmac(macAddress));
                          dispatch(setcasaVideoCore(videoCore));
                          dispatch(setBotType(botType));
                          handleSubmitNew();
                        } else {
                          setOpen(true);
                          setError(labels.enter_valid_core);
                        }
                      } else {
                        setOpen(true);
                        setError(labels.macIdEmptyError);
                      }
                    } else {
                      setOpen(true);
                      setError(labels.enter_mac_domain);
                    }
                  } else {
                    setOpen(true);
                    setError(labels.enter_video_hostname);
                  }
                } else {
                  setOpen(true);
                  setError(labels.enter_docsis_hostname);
                }
              } else {
                setOpen(true);
                setError(labels.enter_built_type);
              }
            } else {
              setOpen(true);
              setError(labels.enter_video_ip);
            }
          } else {
            setOpen(true);
            setError(labels.enter_docsis_ip);
          }
        } else {
          setOpen(true);
          setError(labels.enter_aux_core);
        }
      } else {
        setOpen(true);
        setError(labels.enter_qpsk);
      }
    } else {
      setOpen(true);
      setError(labels.enter_node_id);
    }
  };

  const DropdownMenu = ({ label, data, value, onSelect }: any) => (
    <div className="testTypeCont">
      <Form.Label className="rpdFormLabel">{label}</Form.Label>
      <DropdownButton
        title={value}
        onSelect={(v: any) => onSelect(v)}
        size="sm"
        className="testTypeDropd"
        id="testTypeDd"
      >
        {data.map((e: any, i: number) => (
          <Dropdown.Item key={i} eventKey={e}>
            {e}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );

  return (
    <>
      <div>
        <div className="filterContainer">
          {/* <div className="filterRow">
            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">{labels.botType}</Form.Label>
                <div className="titleChipCard">
                  <ChipGroup
                    chips={searchTypeChips}
                    activeChipIndex={activeCHipIndex}
                    onIndexChange={changeActiveIndex}
                  />
                </div>
              </Form.Group>
            </div>
          </div> */}
          <div className="filterRow">
            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.NODE_ID}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="inputField"
                  onChange={onNptIdSubmit}
                  className="inputField"
                  id="inputField"
                  value={nptID}
                  onKeyPress={(e: any) => handleKeyPress(e)}
                  placeholder={labels.type_node_id}
                />
              </Form.Group>
            </div>
            {/* <div className="inputContainer">
              <Form.Group>
                <DropdownMenu
                  label={labels.qpsk}
                  data={qpskList}
                  value={qpsk}
                  onSelect={setQpsk}
                />
              </Form.Group>
            </div> */}
            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">{labels.qpsk}</Form.Label>
                <Form.Control
                  disabled={false}
                  name="inputField"
                  onChange={onQpskSubmit}
                  className="inputField"
                  id="inputField"
                  value={qpsk}
                  onKeyPress={(e: any) => handleKeyPress(e)}
                  placeholder={labels.type_qpsk}
                />
              </Form.Group>
            </div>

            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.aux_core}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="inputField"
                  onChange={onAuxSubmit}
                  className="inputField"
                  id="inputField"
                  value={aux}
                  onKeyPress={(e: any) => handleKeyPress(e)}
                  placeholder={labels.type_aux_core}
                />
              </Form.Group>
            </div>
          </div>
          <div className="filterRow">
            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.docsis_ip}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="inputField"
                  onChange={onDocsisipSubmit}
                  className="inputField"
                  id="inputField"
                  value={docsisip}
                  onKeyPress={(e: any) => handleKeyPress(e)}
                  placeholder={labels.type_docsis_ip}
                />
              </Form.Group>
            </div>
            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.video_ip}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="inputField"
                  onChange={onVideoipSubmit}
                  className="inputField"
                  id="inputField"
                  value={videoip}
                  onKeyPress={(e: any) => handleKeyPress(e)}
                  placeholder={labels.type_video_ip}
                />
              </Form.Group>
            </div>
            {/* <div className="inputContainer">
              <Form.Group>
                <DropdownMenu
                  label={labels.video_ip}
                  data={videoIpList}
                  value={videoip}
                  onSelect={setVideoIp}
                />
              </Form.Group>
            </div> */}
            {/* <div className="inputContainer">
              <Form.Group>
                <DropdownMenu
                  label={labels.built_type}
                  data={builtTypeList}
                  value={mapping}
                  onSelect={setMapping}
                />
              </Form.Group>
            </div> */}
            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.built_type}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="inputField"
                  onChange={onMappingSubmit}
                  className="inputField"
                  id="inputField"
                  value={mapping}
                  onKeyPress={(e: any) => handleKeyPress(e)}
                  placeholder={labels.type_built_type}
                />
              </Form.Group>
            </div>
          </div>
          <div className="filterRow">
            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.docsis_hostname}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="inputField"
                  onChange={onDocsishostnameSubmit}
                  className="inputField"
                  id="inputField"
                  value={docsishostname}
                  onKeyPress={(e: any) => handleKeyPress(e)}
                  placeholder={labels.type_docsis_hostname}
                />
              </Form.Group>
            </div>
            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.video_hostname}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="inputField"
                  onChange={onVideohostnameSubmit}
                  className="inputField"
                  id="inputField"
                  value={videohostname}
                  onKeyPress={(e: any) => handleKeyPress(e)}
                  placeholder={labels.type_video_hostname}
                />
              </Form.Group>
            </div>
            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.mac_domain}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="inputField"
                  onChange={onMacDomainSubmit}
                  className="inputField"
                  id="inputField"
                  value={macdomain}
                  onKeyPress={(e: any) => handleKeyPress(e)}
                  placeholder={labels.type_mac_domain}
                />
              </Form.Group>
            </div>
          </div>
          <div className="filterRow">
            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.mac_address}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="inputField"
                  onChange={onmacAddressSubmit}
                  className="inputField"
                  id="inputField"
                  value={macAddress}
                  onKeyPress={(e: any) => handleKeyPress(e)}
                  placeholder={labels.typeRpdMacAddress}
                />
              </Form.Group>
            </div>
            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.valid_core}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="inputField"
                  onChange={onVideoCoreSubmit}
                  className="inputField"
                  id="inputField"
                  value={videoCore}
                  onKeyPress={(e: any) => handleKeyPress(e)}
                  placeholder={labels.type_valid_core}
                />
              </Form.Group>
            </div>
          </div>
          <div className="filterRow">
            <div className="inputContainer">
              <div className="boxTitleContainer">
                <span>{labels.rpdLobbyCamera}</span>
              </div>
              <div className="boxTitleContainer">
                <ChipGroup
                  chips={lobbyCameraTypeChips}
                  activeChipIndex={activeLobbyCameraCHipIndex}
                  onIndexChange={setActiveLobbyCameraCHipIndex}
                />
              </div>
            </div>
            <div className="inputContainer">
              <div className="boxTitleContainer">
                <span>{labels.rpdMDType}</span>
              </div>
              <div className="boxTitleContainer">
                <ChipGroup
                  chips={mdTypeChips}
                  activeChipIndex={activeMdTypeCHipIndex}
                  onIndexChange={setActiveMdTypeCHipIndex}
                />
              </div>
            </div>
          </div>
          <div className="filterRootContainer">
            <div className="inputContainer">
              <EmailEntry
                title={labels.rpdEmail}
                email={emails}
                setEmail={setEmails}
                setIsEmailValid={setIsEmailValid}
                titleStyle={{
                  fontSize: "12px",
                  fontWeight: 500,
                  margin: "0px",
                  color: "#000",
                }}
                buttonStyle={{
                  fontSize: "12px",
                  background: "none",
                  border: "none",
                  padding: "0px",
                  color: "#da291c",
                }}
              />

              <button
                disabled={isLoading}
                className="applyFilersBtn"
                onClick={() => handleSubmit(nptID)}
              >
                {labels.SUBMIT}
              </button>
            </div>
          </div>
          {/* <div className="filterRow">
            <button
              className="applyFilersBtn"
              onClick={() => handleSubmit(nptID)}
            >
              {labels.SUBMIT}
            </button>
          </div> */}
        </div>
        {/* <div className="filterSwapBtn">
          <button
            className="applyFilersBtn"
            onClick={() => handleSubmit(nptID)}
          >
            {labels.SUBMIT}
          </button>
        </div> */}
      </div>
      <ErrorBox
        open={open}
        error={error}
        setOpen={setOpen}
        handleClose={handleClose}
      />
    </>
  );
};
export default CASAVideoExecTopComponent;
