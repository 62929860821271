import { FormEvent, useState, useEffect, ChangeEventHandler } from "react";
import { Button, Form } from "react-bootstrap";
import { DateRange } from "react-date-range";
import { MdDateRange } from "react-icons/md";
import { format } from "date-fns";
import { useDispatch } from "react-redux";

import TopologyLabels from "../../../constants/topologyLabels";
import { REDLINE_CONFIG as RC } from "../../../config/mapItemsConfig";
import CustomDialog from "../../../../components/reusable/CustomDialog";
import { getISODateString, isNumeric } from "../../../util/utilFuncs";
import colors from "../../../config/colors";
import {
  GetTopoGeneralProps,
  useRedlineStates,
} from "../../../util/reduxFunctions/getTopologyState";
import InlineErrorMessage from "../../../../components/reusable/InlineErrorMessage";
import { getRedlines } from "../../../store/slices/redlineSlice";

export default function Filters() {
  const labels = TopologyLabels();
  const { redlinesIsLoading } = useRedlineStates();
  const now = new Date();
  const { activeNodeId } = GetTopoGeneralProps();
  const dispatch = useDispatch();
  const [daysBack, setDaysBack] = useState(RC.dfltDaysBack);
  const [dateRange, setDateRange] = useState(getDateRangeFormat(now, daysBack));
  const [showDateRange, setShowDateRange] = useState(false);
  const [form, setForm] = useState({
    nodeId: "",
    trackorKey: "",
    lanId: "",
  });
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    if (activeNodeId) setForm((pv) => ({ ...pv, nodeId: activeNodeId }));
  }, [activeNodeId]);

  const onDaysBackChange = (e: FormEvent<HTMLInputElement>) => {
    const val = isNumeric(e?.currentTarget?.value);
    if (val <= RC.maxDaysBack) {
      setDaysBack(val);
      setDateRange(getDateRangeFormat(now, val));
    }
  };

  const handleDateRangeSelect = (ranges: any) => {
    const dates = ranges.selection;
    const timeDiff = dates?.endDate?.getTime() - dates?.startDate?.getTime();
    const days = Math.ceil(timeDiff / (1000 * 3600 * 24));
    setDateRange([dates]);
    setDaysBack(days);
  };

  const getDRFormat = (dateStart = "startDate") => {
    const date =
      dateStart === "startDate"
        ? dateRange?.[0]?.startDate
        : dateRange?.[0]?.endDate;

    if (dateRange) {
      return format(
        new Date(
          Number(date?.getFullYear()),
          Number(date?.getMonth()),
          Number(date?.getDate())
        ),
        "yyyy-MM-dd"
      );
    } else return null;
  };

  const getTimeInISO = () => {
    const startDateString = getDRFormat("startDate");
    const endDateString = getDRFormat("endDate");

    if (!startDateString || !endDateString) {
      setErrorMsg("Start or end date is not available");
      return;
    }

    const startDateISO = getISODateString(startDateString, "00:00:00");
    const endDateISO = getISODateString(endDateString, "23:59:59");

    return { startDate: startDateISO, endDate: endDateISO };
  };

  const handleTFChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    // If "@" exists in lanId, split and take the part before "@", otherwise use the full lanId
    const sanitizedLanId = form?.lanId?.includes("@")
      ? form.lanId.split("@")[0]
      : form.lanId;

    const startDate = getTimeInISO()?.startDate;
    const endDate = getTimeInISO()?.endDate;

    if (startDate && endDate)
      dispatch(
        getRedlines({ ...form, startDate, endDate, lanId: sanitizedLanId })
      );
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="clickFltInputCont">
          <div className="inputItemsCont">
            <label className="nikaRadiusLabel">{labels.DaysBack}</label>
            <input
              value={daysBack}
              placeholder={labels.DaysBack}
              onChange={onDaysBackChange}
              style={{ textAlign: "center" }}
            />
          </div>
          <div className="inputItemsCont">
            <label className="nikaRadiusLabel">{labels.DateRange}</label>
            <div className="fromToDate" onClick={() => setShowDateRange(true)}>
              <Form.Text style={{ margin: 2 }}>
                {`${getDRFormat("startDate")} - ${getDRFormat("endDate")}`}
              </Form.Text>
              <MdDateRange fill="var(--greyMedium)" size={20} />
            </div>
          </div>
          <div className="inputItemsCont">
            <label className="nikaRadiusLabel">{"Node ID"}</label>
            <input
              value={form.nodeId}
              name={"nodeId"}
              onChange={handleTFChange}
            />
          </div>
          <div className="inputItemsCont">
            <label className="nikaRadiusLabel">{"Tech (Lan ID)"}</label>
            <input
              value={form.lanId}
              name={"lanId"}
              onChange={handleTFChange}
            />
          </div>
          <div className="inputItemsCont">
            <label className="nikaRadiusLabel">{"Tracker Key"}</label>
            <input
              value={form.trackorKey}
              name={"trackorKey"}
              onChange={handleTFChange}
            />
          </div>
        </div>
        <Button
          type="submit"
          className="stepperSubmitBtn"
          style={{ height: 30, borderRadius: 5, width: "100%" }}
          disabled={redlinesIsLoading}
        >
          Get Redline(s)
        </Button>
      </form>
      <InlineErrorMessage errorMessage={errorMsg} />
      <CustomDialog
        className="CustomBox"
        open={showDateRange}
        handleClose={() => setShowDateRange(false)}
        childComponent={
          <div className="fromToRootContainer">
            <DateRange
              onChange={handleDateRangeSelect}
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
              rangeColors={[colors.primary, colors.primary, colors.primary]}
            />
          </div>
        }
        heading={labels.SelectDateRange}
      />
    </>
  );
}

const getDateRangeFormat = (now: any, intervalInDays: number) => [
  {
    startDate: new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - intervalInDays
    ),
    endDate: new Date(),
    key: "selection",
  },
];
