import {
  changeTimeFormat,
  getFormatedHomeAddress,
  isNull,
} from "../../components/reusable/Util";
import { Divider } from "@material-ui/core";
import { AppBar, Tabs, Tab } from "@material-ui/core";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { CERTIFICATION_CONFIG } from "../../constants/CertificationsConfig";
import { useEffect, useState } from "react";
import { DeviceDiag } from "../../components/diagnostics/DiagnosticsTab";
import HSICertiDetails from "./HSICertiDetails";
import { a11yProps, TabPanel } from "../account-page/AccountPageTabs";
import WiFiViewCertiDetails from "./WiFiViewCertiDetails";
import { CWMonitoringTracer } from "../../store/actions/account/diagnostics/cm/CWMonitoringAction";

const ViewFullCertificate = (props: any) => {
  const { certiData } = props;
  const dispatch = useDispatch();
  const { selectedSA } = useSelector(
    (state: any) => ({
      selectedSA: state.AccountState.ActiveAccountDetails.selectedSA,
    }),
    shallowEqual
  );

  let accType = certiData?.data?.topology;
  let certiConfig: any = [];
  if (accType === "DOCSIS") certiConfig = CERTIFICATION_CONFIG.DOCSIS;
  else if (accType === "GPON") certiConfig = CERTIFICATION_CONFIG.GPON;
  else if (accType === "FWA") certiConfig = CERTIFICATION_CONFIG.FWA;
  let hsiCertiData = certiData?.data?.hsiCertificate;
  let techData = certiData?.data?.technician;
  let certiDevices = hsiCertiData?.devices;
  let precheckDevices = hsiCertiData?.precheckDevices;
  let skippedDevices = hsiCertiData?.summary?.skipped?.devices;
  let remarkDetails = certiData?.data?.remark;
  const [selDValue, setSelDValue] = useState<number | undefined>(0);
  const [selDevice, setSelDevice] = useState<any>();
  const [deviceDiagActions, setDeviceDiagActions] = useState<DeviceDiag[]>([]);
  useEffect(() => {
    if (
      selDValue !== undefined &&
      certiDevices !== undefined &&
      certiDevices?.length !== 0
    ) {
      setSelDevice(certiDevices[selDValue]);
      let deviceDiag = getDeviceDiag(certiDevices[selDValue].deviceCategory);
      setDeviceDiagActions(deviceDiag);
    }
  }, [selDValue]);
  const getDeviceDiag = (deviceName: string): DeviceDiag[] => {
    let diagArr: DeviceDiag[] = [];
    certiConfig?.map((d: any) => {
      if (
        deviceName === d.category &&
        d?.hasOwnProperty("deviceCertification")
      ) {
        diagArr = d.deviceCertification;
      }
    });
    return diagArr;
  };
  useEffect(() => {
    dispatch(CWMonitoringTracer(true));
  }, []);
  let wifiCertiData = certiData?.data?.wifiCertificate;
  let certiLocations = wifiCertiData?.locations;
  const [selLValue, setSelLValue] = useState<number | undefined>(0);
  const [selLocation, setSelLocation] = useState<any>();

  useEffect(() => {
    if (
      selLValue !== undefined &&
      certiLocations !== undefined &&
      certiLocations?.length !== 0
    ) {
      setSelLocation(certiLocations[selLValue]);
    }
  }, [selLValue]);
  const [value, setValue] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div className="fullCertiCont">
      <div className="viewFullCertiInfoCont">
        <section className="certiDetailsSec">
          <p>
            <span>Test Type: </span>
            <span>
              {certiData?.data?.testType === "WHT"
                ? "Whole Home Test"
                : certiData?.data?.testType === "DNS"
                  ? "Drop & Stay Test"
                  : certiData?.data?.testType === "DNG"
                    ? "EDBA Test"
                    : "N/A"}
            </span>
          </p>
          <p>
            <span>Submitted by: </span>
            <span>
              {techData?.lanId} ({techData?.techId})
            </span>
          </p>
          <p>
            <span>Submitted on: </span>
            <span>
              {changeTimeFormat(isNull(certiData?.data?.updatedDate))}
            </span>
          </p>
          <p>
            <span>Account Number: </span>
            <span>{isNull(certiData?.data?.accountNumber)}</span>
          </p>
          <p>
            <span>Work Order ID: </span>
            <span>{certiData?.data?.workOrderId}</span>
          </p>
          <p>
            <span>Service Address: </span>
            <span>{getFormatedHomeAddress(certiData?.data?.address)}</span>
          </p>
        </section>
        <Divider orientation="vertical" style={{ height: "170px" }} />
        {certiData?.data?.testType !== "DNG" ? (
          <div className="statusCont">
            {hsiCertiData?.isApplicable ? (
              <section className="deviceStatusCount">
                <h5>Whole Home Summary</h5>
                <div>
                  <p className="passCount">
                    <span>
                      {hsiCertiData?.summary?.marginalPass?.count !== undefined
                        ? hsiCertiData?.summary?.passed?.count +
                          hsiCertiData?.summary?.marginalPass?.count <
                          10
                          ? "0" +
                          (hsiCertiData?.summary?.marginalPass?.count +
                            hsiCertiData?.summary?.passed?.count)
                          : hsiCertiData?.summary?.passed?.count +
                          hsiCertiData?.summary?.marginalPass?.count
                        : hsiCertiData?.summary?.passed?.count !== undefined &&
                          hsiCertiData?.summary?.passed?.count < 10
                          ? "0" + hsiCertiData?.summary?.passed?.count
                          : hsiCertiData?.summary?.passed?.count}
                    </span>
                    <span>Passed</span>
                  </p>
                  <p className="failCount">
                    <span>
                      {hsiCertiData?.summary?.failed?.count < 10
                        ? "0" + hsiCertiData?.summary?.failed?.count
                        : hsiCertiData?.summary?.failed?.count}
                    </span>
                    <span>Failed</span>
                  </p>
                  <p className="skipCount">
                    <span>
                      {hsiCertiData?.summary?.skipped?.count < 10
                        ? "0" + hsiCertiData?.summary?.skipped?.count
                        : hsiCertiData?.summary?.skipped?.count}
                    </span>
                    <span>Skipped</span>
                  </p>
                </div>
              </section>
            ) : (
              <></>
            )}
            {wifiCertiData?.isApplicable ? (
              <section className="deviceStatusCount">
                <h5>WiFi Summary</h5>
                <div>
                  <p className="normCount">
                    <span>
                      {wifiCertiData?.summary?.total < 10
                        ? "0" + wifiCertiData?.summary?.total
                        : wifiCertiData?.summary?.total}
                    </span>
                    <span>Tested</span>
                  </p>
                  <p className="passCount">
                    <span>
                      {wifiCertiData?.summary?.passed < 10
                        ? "0" + wifiCertiData?.summary?.passed
                        : wifiCertiData?.summary?.passed}
                    </span>
                    <span>Passed</span>
                  </p>
                  <p className="failCount">
                    <span>
                      {wifiCertiData?.summary?.failed < 10
                        ? "0" + wifiCertiData?.summary?.failed
                        : wifiCertiData?.summary?.failed}
                    </span>
                    <span>Failed</span>
                  </p>
                </div>
              </section>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div>
        <AppBar position="static" className="accTabsBar">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Whole Home Certification" {...a11yProps(0)} />
            <Tab label="WiFi Certification" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <HSICertiDetails
            certiDevices={certiDevices}
            precheckDevices={precheckDevices}
            hsiCertiData={hsiCertiData}
            certiConfig={certiConfig}
            selDValue={selDValue}
            setSelDValue={setSelDValue}
            isApplicable={hsiCertiData?.isApplicable}
            skippedDevices={skippedDevices}
            selDevice={selDevice}
            selectedSA={selectedSA}
            deviceDiagActions={deviceDiagActions}
            remarkDetails={remarkDetails}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <WiFiViewCertiDetails
            remarkInfo={remarkDetails}
            isApplicable={wifiCertiData?.isApplicable}
            certiLocations={certiLocations}
            selLValue={selLValue}
            setSelLValue={setSelLValue}
            selLocation={selLocation}
            oduData={wifiCertiData?.oduDetails}
          />
        </TabPanel>
      </div>
    </div>
  );
};

export default ViewFullCertificate;
