export const TAP_LIST_BEGIN = "TAP_LIST_BEGIN";
export const TAP_LIST_SUCCESS = "TAP_LIST_SUCCESS";
export const TAP_LIST_FAILURE = "TAP_LIST_FAILURE";

interface TapListBegin {
  type: typeof TAP_LIST_BEGIN;
}

interface TapListSuccess {
  type: typeof TAP_LIST_SUCCESS;
  payload: any;
}

interface TapListFailure {
  type: typeof TAP_LIST_FAILURE;
  payload: Error;
}

export type TapListActionTypes = TapListBegin | TapListSuccess | TapListFailure;
