export const ADD_ZAP_STB_REQUEST = "ADD_ZAP_STB_REQUEST";
export const UPDATE_ZAP_STB_REQUEST = "UPDATE_ZAP_STB_REQUEST";
export const RESET_ZAP_STB = "RESET_ZAP_STB";

interface AddZapSTBRequest {
  type: typeof ADD_ZAP_STB_REQUEST;
  devicekey: string;
}
interface UpdateZapSTBRequest {
  type: typeof UPDATE_ZAP_STB_REQUEST;
  devicekey: string;
  requestStatus: string;
}
interface ResetZapSTB {
  type: typeof RESET_ZAP_STB;
}

export type ZapSTBsActionTypes =
  | AddZapSTBRequest
  | UpdateZapSTBRequest
  | ResetZapSTB;
