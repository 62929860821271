import { BsCheckCircle } from "react-icons/bs";
import { FiAlertTriangle } from "react-icons/fi";
import ErrorIcon from "../../resources/images/icons/ErrorIcon.svg";
import DisplayIcon from "../reusable/DeviceMenuCollapse";
import DeviceActionMenu from "./DeviceActionMenu";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useEffect, useState } from "react";
import LoaderGif from "../../resources/images/loader.webp";

interface TabProps {
  index: number;
  label: string;
  tabType: string;
  value: any;
  subLabel?: string;
  handleSelect: any;
  disabled?: boolean;
  friendlyName?: string;
  handleActionClick?: any;
  deviceStatus?: string;
  deviceDiagActions?: any;
  diagView?: string;
  deviceInfo?: any;
  deviceActions?: any;
}

const CustomTab = (props: TabProps) => {
  const {
    index,
    label,
    subLabel,
    friendlyName,
    handleSelect,
    value,
    tabType,
    disabled,
    deviceStatus,
    deviceDiagActions,
    handleActionClick,
    diagView,
    deviceInfo,
    deviceActions,
  } = props;
  let isSel = "";
  if (index === value) isSel = "selectedDeviceTab";
  const renderDeviceStatus = () => {
    const status = deviceStatus?.toLowerCase();
    if (status === "pass") return <BsCheckCircle color="#2DC071" title="Pass" />;
    else if (status === "marginal pass")
      return <BsCheckCircle color="#ffbf3f" title="Marginal Pass" />;
    else if (status?.includes("skip"))
      return <FiAlertTriangle color="#E77C40" title="Skip" />;
    else if (status === "fail")
      return <img style={{ height: "16px" }} src={ErrorIcon} title="Fail" />;
    else if (status === "loading")
      return <img style={{ width: "20%" }} src={LoaderGif} alt="Loading" title="Loading" />;
    else if (deviceStatus === undefined)
      return <img style={{ width: "20%" }} src={LoaderGif} alt="Loading" title="Loading" />;
    else return <></>;
  };

  useEffect(() => {
    renderDeviceStatus();
  }, [deviceStatus]);
  if (tabType === "horizontal") {
    return (
      <div
        role="tab"
        className={`custom-${isSel}-Tab`}
        id={`horizontal-tab-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
      >
        {!disabled ? (
          <div
            onClick={() => handleSelect(index)}
            className="horizontalDeviceTab"
          >
            <div className="tabCont">
              <span className="tabLabel">{label}</span>
              <p className="tabSubLabel">{subLabel}</p>
            </div>
          </div>
        ) : (
          <div className="horizontalDeviceTab">
            <div className="tabCont">
              <span className="tabLabel">{label}</span>
              <p className="tabSubLabel">{subLabel}</p>
            </div>
          </div>
        )}
      </div>
    );
  } else if (tabType === "vertical-Toggle") {
    return (
      <div
        role="tab"
        className={`custom-${isSel}-Tab`}
        id={`vertical-tab-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
      >
        <OverlayTrigger
          key={index}
          placement="right"
          overlay={
            <Tooltip id={`tooltip-${index}`}>
              <span>{label}</span>
              <br />
              <span>{subLabel}</span>
            </Tooltip>
          }
        >
          <button
            onClick={() => handleSelect(index)}
            className="verticalDeviceTab"
          >
            <div className="leftTabCont">
              <div className="deviceIcons">
                <DisplayIcon devicetype={label} />
              </div>
            </div>
          </button>
        </OverlayTrigger>
      </div>
    );
  } else if (tabType === "vertical-certi") {
    return (
      <div
        role="tab"
        className={`custom-${isSel}-Tab`}
        id={`vertical-tab-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
      >
        <button
          onClick={() => handleSelect(index)}
          className="verticalDeviceTab"
          disabled={deviceStatus === undefined ? true : false}
        >
          <div className="leftTabCont">
            <div className="deviceIcons">
              <DisplayIcon devicetype={label} />
            </div>
            <div>
              <span className="tabLabel">{label}</span>
            </div>
          </div>
          {renderDeviceStatus()}
        </button>
      </div>
    );
  } else if (tabType === "vertical-certi-Locations") {
    return (
      <div
        role="tab"
        className={`custom-${isSel}-Tab`}
        id={`vertical-tab-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
      >
        <button
          onClick={() => handleSelect(index)}
          className="verticalLocationTab"
        >
          <div className="leftTabCont">
            <div>
              <span className="tabLabel">{label}</span>
            </div>
          </div>
          {renderDeviceStatus()}
        </button>
      </div>
    );
  }
  return (
    <>
      <div
        role="tab"
        className={`custom-${isSel}-Tab`}
        id={`vertical-tab-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
      >
        <button
          onClick={() => handleSelect(index)}
          className="verticalDeviceTab"
        >
          <div className="leftTabCont">
            <div className="deviceIcons">
              <DisplayIcon devicetype={label} />
            </div>
            <div>
              <span className="tabLabel">
                {renderDeviceStatus()}
                {label}
              </span>

              <p className="tabSubLabel">{friendlyName}</p>
              <p className="tabSubSubLabel">{subLabel}</p>
            </div>
          </div>
        </button>
        {diagView === "tabView" && deviceActions !== undefined ? (
            <DeviceActionMenu
            deviceName={deviceInfo.deviceName=="GW" ? deviceInfo.deviceName:deviceInfo.deviceCategory}
            deviceActions={ deviceActions}       
            deviceInfo={deviceInfo}
            menuType="vertical"
          />
        ) : (
          <></>
        )}
      </div>
      {index === value ? (
        <div
          id={`vertical-tab-action-${index}`}
          className="diagActionsListCont"
        >
          <ul>
            {deviceDiagActions?.map((action: any, index: number) => {
              return (
                <li
                  id={`${action.tab}-action`}
                  key={index}
                  className={`device-${action.active}-Action`}
                >
                  <button onClick={() => handleActionClick(action.tab)}>
                    {action.label}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default CustomTab;
