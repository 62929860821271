import React from "react";
import appConst from "../../../../constants/appConst";
import MduStrandCable from "./MduStrandCable";
import MduStrandPoint from "./MduStrandPoint";
import MduStrandSpecialCable from "./MduStrandSpecialCable";
interface Props {
  gMap?: google.maps.Map;
  mduSupportStructureData?: any;
  pngImages?: any;
  zoomLevel?: any;
}

const MduSupportStructure = React.memo(
  ({ gMap, mduSupportStructureData, pngImages, zoomLevel }: Props) => {
    return (
      <>
        {mduSupportStructureData?.features?.map((e: any) => (
          <div key={e?.featureId}>
            {e?.properties?.type === appConst.mduStrandCable ? (
              <MduStrandCable mduStrandCableData={e} gMap={gMap} />
            ) : e?.properties?.type === appConst.mduStrandSpecialCable ? (
              <MduStrandSpecialCable
                mduStrandSpecialCableData={e}
                gMap={gMap}
              />
            ) : e?.properties?.type === appConst.mduStrandPoint ? (
              <MduStrandPoint
                mduStrandPointData={e}
                pngImages={pngImages}
                zoomLevel={zoomLevel}
              />
            ) : null}
          </div>
        ))}
      </>
    );
  }
);

export default MduSupportStructure;
