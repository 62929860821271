import { AccountDetails } from "../../../models/account/accountInfo/AccountDetails";

import {
  ACCOUNT_DETAILS_BEGIN,
  ACCOUNT_DETAILS_SUCCESS,
  ACCOUNT_DETAILS_FAILURE,
  ACCOUNT_RESET_STATUS,
  RESET_SEARCH_ACC_STATE,
  AccountDetailsActionTypes,
} from "../../../types/account/accountInfo/AccountDetails";

export function AccountDetailsBegin(): AccountDetailsActionTypes {
  return {
    type: ACCOUNT_DETAILS_BEGIN,
  };
}

export function AccountDetailsSuccess(
  AccountDetails: AccountDetails
): AccountDetailsActionTypes {
  return {
    type: ACCOUNT_DETAILS_SUCCESS,
    payload: AccountDetails,
  };
}

export function AccountDetailsFailure(error: Error): AccountDetailsActionTypes {
  return {
    type: ACCOUNT_DETAILS_FAILURE,
    payload: error,
  };
}

export function AccountResetStatus(): AccountDetailsActionTypes {
  return {
    type: ACCOUNT_RESET_STATUS,
  };
}

export function ResetSearchAccState(): AccountDetailsActionTypes {
  return {
    type: RESET_SEARCH_ACC_STATE,
  };
}
