import { useState, useEffect } from "react";
import Table, { TableProps } from "./Table";

interface Props extends TableProps {
  dataPerScreen?: number;
}

export default function PaginatedTable(props: Props) {
  const [currentScreen, setCurrentScreen] = useState(1);
  const [screenNumbers, setScreenNumbers] = useState([] as Array<number>);
  const dataPerScreen = props.dataPerScreen ? props.dataPerScreen : 50;

  //get current data
  const indexOfLastData = currentScreen * dataPerScreen;
  const indexOfFirstData = indexOfLastData - dataPerScreen;
  const currentData = props.data?.slice(indexOfFirstData, indexOfLastData);
  const totalData = props.data?.length;
  const currentDataTotal = currentData?.length;

  useEffect(() => {
    const screenNums = [];
    for (let i = 1; i <= Math.ceil(totalData / dataPerScreen); i++) {
      screenNums.push(i);
    }
    setScreenNumbers(screenNums);
  }, [totalData, dataPerScreen]);

  useEffect(() => {
    setCurrentScreen(1);
  }, [props.data]);

  const getIndexes = () => {
    return `Showing ${indexOfFirstData + 1}-${
      indexOfFirstData + currentDataTotal
    } of ${totalData}`;
  };

  return (
    <Table
      {...props}
      data={currentData}
      fullData={props.data} //used for export as CSV
      footerComp={
        <div className="flexedRowDiv paginatedTableCont">
          <section>
            <span>{getIndexes()}</span>
          </section>
          <section>
            <div className="pagingNumCont">
              {screenNumbers?.map((num) => (
                <span
                  key={num}
                  className={num === currentScreen ? "activeSpan" : ""}
                  onClick={() => setCurrentScreen(num)}
                >
                  {num}
                </span>
              ))}
            </div>
          </section>
        </div>
      }
    />
  );
}
