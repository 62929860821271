import { createSlice } from "@reduxjs/toolkit";
import appConst from "../../../../constants/appConst";
import { apiCallBegan } from "../../../middleware/apiActions";
import {
  CREATE_MEMO_URI,
  MEMO_URI,
  UPDATE_DELETE_MEMO_URI,
} from "../../../middleware/apiEndpoints";
import storeConst from "../../../storeConst";

const slice = createSlice({
  name: "memo",
  initialState: {
    crudIsLoading: false,
    crudData: undefined,
    crudError: undefined,
    crudStatus: "",
    crudType: "",
  },
  reducers: {
    crudMemoBegin: (crudMemo) => {
      crudMemo.crudIsLoading = true;
      crudMemo.crudStatus = "";
      crudMemo.crudType = "";
    },
    crudMemoSuccess: (crudMemo, { payload }) => {
      crudMemo.crudIsLoading = false;
      crudMemo.crudData = payload;
      crudMemo.crudError = undefined;
      crudMemo.crudStatus = storeConst.OK;
      crudMemo.crudType = payload.type;
    },
    crudMemoFailure: (crudMemo, { payload }) => {
      crudMemo.crudIsLoading = false;
      crudMemo.crudError = payload;
      crudMemo.crudData = undefined;
      crudMemo.crudStatus = storeConst.ERROR;
      crudMemo.crudType = payload.type;
    },
    resetCrudData: (crudMemo) => {
      crudMemo.crudIsLoading = false;
      crudMemo.crudStatus = "";
      crudMemo.crudData = undefined;
      crudMemo.crudError = undefined;
      crudMemo.crudType = "";
    },
  },
});

export const { resetCrudData } = slice.actions;
//should not be exposed to outside
const { crudMemoBegin, crudMemoSuccess, crudMemoFailure } = slice.actions;
export default slice.reducer;

export const createMemo = (networkId: any, props: any) => (dispatch: any) => {
  dispatch(
    apiCallBegan({
      url: CREATE_MEMO_URI(networkId),
      data: props,
      method: "post",
      onStart: crudMemoBegin.type,
      onSuccess: crudMemoSuccess.type,
      onError: crudMemoFailure.type,
      props: { type: appConst.create },
    })
  );
};

export const updateDeleteMemo =
  (type: any, networkId: any, commentId: any, props: any) =>
  (dispatch: any) => {
    dispatch(
      apiCallBegan({
        url: UPDATE_DELETE_MEMO_URI(networkId, commentId),
        data: props,
        method: "post",
        onStart: crudMemoBegin.type,
        onSuccess: crudMemoSuccess.type,
        onError: crudMemoFailure.type,
        props: { type },
      })
    );
  };
