export const ADD_ACCOUNT = "ADD_ACCOUNT";
export const SET_ACTIVE_ACCOUNT_KEY = "SET_ACTIVE_ACCOUNT_KEY";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const RESET_ALL_ACCOUNTS = "RESET_ALL_ACCOUNTS";
export const SWITCH_ACCOUNT = "SWITCH_ACCOUNT";
interface AddAccount {
  type: typeof ADD_ACCOUNT;
  accNum: string;
  payload: any;
}
interface SetActiveAccountKey {
  type: typeof SET_ACTIVE_ACCOUNT_KEY;
  accNum: string;
}
interface DeleteAccount {
  type: typeof DELETE_ACCOUNT;
  accNum: string;
}
interface UpdateAccount {
  type: typeof UPDATE_ACCOUNT;
  accNum: string;
  payload: any;
}
interface ChangeAccount {
  type: typeof SWITCH_ACCOUNT;
  payload: any;
}
interface ResetAllAccState {
  type: typeof RESET_ALL_ACCOUNTS;
}

export type AllAccountActionTypes =
  | AddAccount
  | SetActiveAccountKey
  | DeleteAccount
  | ChangeAccount
  | UpdateAccount
  | ResetAllAccState;
