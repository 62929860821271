import React, { useState } from "react";
import FullPageLoader from "../reusable/FullPageLoader";
import SearchBoxMini from "./SearchBoxMini";

//Images Import
import techImgLarge from "../../resources/images/Laptop-L.png";
import techImgMed from "../../resources/images/Laptop.png";
import techImgTab from "../../resources/images/Tablet.png";
import techImgMob from "../../resources/images/Mobile.png";

import SearchBar from "./SearchBar";
import SearchTabs from "./SearchTabs";
import { getAccessLevelByRoles } from "../reusable/Util";
import ConfigConst from "../../constants/ConfigConst";

interface Props {
  techData: any;
}

const SearchBox = ({ techData }: Props) => {
  const [wHTFlag, setWHTFlag] = useState(false);
  const [currSearchValue, setCurrSearchValue] = useState("");
  const [searchType, setSearchType] = useState("accountNumber");
  const handleWHTChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWHTFlag(event.target.checked);
  };
  return (
    <div className="searchContainerMT">
      <div className="searchBox">
        <SearchTabs
          searchType={searchType}
          setSearchType={setSearchType}
          setCurrSearchValue={setCurrSearchValue}
        />

        <SearchBoxMini
          selectedValue={searchType}
          handleWHTChecked={handleWHTChecked}
          wHTFlag={wHTFlag}
          searchTypeChange={(stype: string) => setSearchType(stype)}
          searchValue={currSearchValue}
        />
      </div>
      <FullPageLoader />
    </div>
  );
};
export default SearchBox;
