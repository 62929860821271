export const ADD_NODE = "ADD_NODE";
export const SET_ACTIVE_KEY = "SET_ACTIVE_KEY";
export const DELETE_NODE = "DELETE_NODE";
export const UPDATE_NODE = "UPDATE_NODE";
export const RESET_ALL_NODE = "RESET_ALL_NODE";
export const SWITCH_NODE = "SWITCH_NODE";
interface AddNode {
  type: typeof ADD_NODE;
  entityNum: string;
  entityType: string;
  payload: any;
}
interface SetActiveKey {
  type: typeof SET_ACTIVE_KEY;
  entityNum: string;
}
interface DeleteNode {
  type: typeof DELETE_NODE;
  entityNum: string;
}
interface UpdateNode {
  type: typeof UPDATE_NODE;
  entityNum: string;
  payload: any;
}
interface ChangeNode {
  type: typeof SWITCH_NODE;
  payload: any;
}
interface ResetAllNodeState {
  type: typeof RESET_ALL_NODE;
}

export type AllNodeActionTypes =
  | AddNode
  | SetActiveKey
  | DeleteNode
  | ChangeNode
  | UpdateNode
  | ResetAllNodeState;
