import { changeTimeFormat, isNull } from "../../components/reusable/Util";
import { Divider } from "@material-ui/core";
import { useEffect, useState } from "react";
import WiFiViewCertiDetails from "./WiFiViewCertiDetails";

const ViewWifiCertificationPage = (props: any) => {
  const { certiData } = props;
  let wifiCertiData = certiData?.data?.wifiCertificate;
  let techData = certiData?.data?.technician;
  let remarkInfo = certiData?.data?.remark;
  let certiLocations = wifiCertiData?.locations;
  const [selLValue, setSelLValue] = useState<number | undefined>(0);
  const [selLocation, setSelLocation] = useState<any>();

  useEffect(() => {
    if (
      selLValue !== undefined &&
      certiLocations !== undefined &&
      certiLocations?.length !== 0
    ) {
      setSelLocation(certiLocations[selLValue]);
    }
  }, [selLValue]);

  return (
    <div className="hsiCertiCont">
      <div className="viewCertiInfoCont">
        <section>
          <h4>WiFi Certification</h4>
          <p>
            <span>Test Type: </span>
            <span>
              {certiData?.data?.testType === "WHT"
                ? "Whole Home Test"
                : certiData?.data?.testType === "DNS"
                ? "Drop & Stay Test"
                : "N/A"}
            </span>
          </p>
          <p>
            <span>Generated by: </span>
            <span>
              {techData?.lanId} ({techData?.techId})
            </span>
          </p>
          <p>
            <span>Generated on: </span>
            <span>
              {changeTimeFormat(isNull(certiData?.data?.updatedDate))}
            </span>
          </p>
          <p>
            <span>Account Number: </span>
            <span>{isNull(certiData?.data?.accountNumber)}</span>
          </p>
          <p>
            <span>Work Order ID: </span>
            <span>{certiData?.data?.workOrderId}</span>
          </p>
        </section>
        <Divider orientation="vertical" style={{ height: "170px" }} />
        <section className="deviceStatusCount">
          <h5>WiFi Summary</h5>
          <div>
            <p className="normCount">
              <span>
                {wifiCertiData?.summary?.total < 10
                  ? "0" + wifiCertiData?.summary?.total
                  : wifiCertiData?.summary?.total}
              </span>
              <span>Tested</span>
            </p>
            <p className="passCount">
              <span>
                {wifiCertiData?.summary?.passed < 10
                  ? "0" + wifiCertiData?.summary?.passed
                  : wifiCertiData?.summary?.passed}
              </span>
              <span>Passed</span>
            </p>
            <p className="failCount">
              <span>
                {wifiCertiData?.summary?.failed < 10
                  ? "0" + wifiCertiData?.summary?.failed
                  : wifiCertiData?.summary?.failed}
              </span>
              <span>Failed</span>
            </p>
          </div>
        </section>
      </div>
      <WiFiViewCertiDetails
        remarkInfo={remarkInfo}
        certiLocations={certiLocations}
        isApplicable={wifiCertiData?.isApplicable}
        selLValue={selLValue}
        setSelLValue={setSelLValue}
        selLocation={selLocation}
      />
    </div>
  );
};

export default ViewWifiCertificationPage;
