import { createSlice } from "@reduxjs/toolkit";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "rpdSwap",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
    macAddress: undefined,
    oldDeviceType: undefined,
    newDeviceType: undefined,
    nptId: undefined,
    emails: undefined,
  },
  reducers: {
    rpdSwapBegin: (rpd) => {
      rpd.isLoading = true;
    },
    rpdSwapSuccess: (rpd, action) => {
      rpd.isLoading = false;
      rpd.data = action.payload;
      rpd.error = undefined;
      rpd.status = storeConst.OK;
    },
    rpdSwapFailure: (rpd, action) => {
      rpd.isLoading = false;
      rpd.error = action.payload;
      rpd.data = undefined;
      rpd.status = storeConst.ERROR;
    },
    rpdSwapReset: (rpd) => {
      rpd.isLoading = false;
      rpd.error = undefined;
      rpd.data = undefined;
      rpd.status = "";
      rpd.macAddress = undefined;
    },
    setMacAddress: (rpd, action) => {
      rpd.macAddress = action.payload;
      rpd.error = undefined;
      rpd.data = undefined;
      rpd.status = "";
    },
    setNptId: (rpd, action) => {
      rpd.nptId = action.payload;
      rpd.error = undefined;
      rpd.data = undefined;
      rpd.status = "";
    },
    setOldDeviceType: (rpd, action) => {
      rpd.oldDeviceType = action.payload;
      rpd.error = undefined;
      rpd.data = undefined;
      rpd.status = "";
    },
    setNewDeviceType: (rpd, action) => {
      rpd.newDeviceType = action.payload;
      rpd.error = undefined;
      rpd.data = undefined;
      rpd.status = "";
    },
    setRPDEmails: (rpd, action) => {
      rpd.emails = action.payload;
    },
  },
});

export const {
  rpdSwapBegin,
  rpdSwapSuccess,
  rpdSwapFailure,
  rpdSwapReset,
  setMacAddress,
  setNptId,
  setOldDeviceType,
  setNewDeviceType,
  setRPDEmails,
} = slice.actions;
export default slice.reducer;
