import { Tab, Tabs } from "react-bootstrap";
import { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getDeviceDetails,
  getDeviceLoading,
  isNull,
  renderDeviceStatus,
} from "../reusable/Util";
import AstbClient from "../../network/httpclient/astb/AstbClient";
import { SetDeviceStatus } from "../../store/actions/account/accountInfo/ActiveAccountAction";
import ASTBSummaryInfo from "./ASTBSummaryInfo";

const ASTBDiagnostics = (props: any) => {
  const dispatch = useDispatch();
  const { selectedDevice, selDiagAction, handleActionClick } = props;
  const [key, setKey] = useState(selDiagAction);
  const { diagDevicesData } = useSelector(
    (state: any) => ({
      diagDevicesData: state.AccountState.DiagDevicesInfo.diagDevices,
    }),
    shallowEqual
  );
  let astbLoading = getDeviceLoading(
    selectedDevice.serialNumber,
    diagDevicesData
  );
  let astbSummaryDetails = getDeviceDetails(
    selectedDevice.serialNumber,
    "summary",
    diagDevicesData
  );
  let astbSummaryStatus =
    astbSummaryDetails?.hasOwnProperty("errorCode") &&
    astbSummaryDetails?.errorCode != 1009
      ? "ERROR"
      : astbSummaryDetails?.hasOwnProperty("errorCode") &&
        astbSummaryDetails?.errorCode == 1009
      ? "Fail"
      : "OK";
  useEffect(() => {
    setKey(selDiagAction);
  }, [selDiagAction]);
  useEffect(() => {
    if (astbSummaryDetails !== undefined && astbSummaryDetails?.length === 0) {
      dispatch(
        AstbClient.getASTBSummary(
          selectedDevice.serialNumber,
          selectedDevice.macAddress
        )
      );
    }
  }, [selectedDevice]);

  useEffect(() => {
    if (astbSummaryDetails?.length !== 0) {
      dispatch(
        SetDeviceStatus(
          selectedDevice?.serialNumber,
          selectedDevice?.deviceCategory,
          astbSummaryDetails?.hasOwnProperty("overallStatus")
            ? astbSummaryDetails?.overallStatus?.toLowerCase()
            : "fail"
        )
      );
    }
  }, [astbLoading]);
  return (
    <div className="detailDiagCont">
      <section className="deviceData">
        <div className="deviceStatusCont">
          <p>
            <span>MODEL : </span>
            <span>{isNull(selectedDevice?.deviceModel)}</span>
          </p>
          <p className="deviceOverallStat">
            <span>STATUS : </span>
            {!astbLoading ? (
              renderDeviceStatus(astbSummaryDetails, astbSummaryStatus)
            ) : (
              <span style={{ fontStyle: "italic" }}>Fetching...</span>
            )}
          </p>
        </div>
        <p>
          <span>SERIAL NUMBER : </span>
          <span>{isNull(selectedDevice?.serialNumber)}</span>
        </p>
      </section>
      <section className="detailDiagTabs">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k: any) => handleActionClick(k)}
        >
          <Tab eventKey="astbSUMMARY" title="ASTB SUMMARY">
            <ASTBSummaryInfo
              selectedDevice={selectedDevice}
              astbSummaryLoading={astbLoading}
              astbSummaryInfo={astbSummaryDetails}
              astbSummaryStatus={astbSummaryStatus}
            />
          </Tab>
        </Tabs>
      </section>
    </div>
  );
};

export default ASTBDiagnostics;
