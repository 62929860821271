import { IHPFeaturesState } from "../../../../states/account/diagnostics/ihp/IHPFeaturesState";
import {
  IHP_FEATURES_BEGIN,
  IHP_FEATURES_SUCCESS,
  IHP_FEATURES_FAILURE,
  IhpFeaturesActionTypes,
} from "../../../../types/account/diagnostics/ihp/IHPFeatures";

const initialState: IHPFeaturesState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function IHPFeaturesReducer(
  state = initialState,
  action: IhpFeaturesActionTypes
): IHPFeaturesState {
  switch (action.type) {
    case IHP_FEATURES_BEGIN:
      return {
        isLoading: true,
      };
    case IHP_FEATURES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case IHP_FEATURES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}
