import ConfigConst from "../constants/ConfigConst";

interface TableAttr {
  columnKey: string;
  columnName: string;
  jsonDataMapping: string;
  haveMultipleValues?: boolean;
  displayAttribute?: string;
  specInfo?: any;
  getDataFrom: string;
  isPinned: boolean;
  isVisible: boolean;
  position: number;
  allowedLoading?: boolean;
  allowedViewType: string[];
}

export const stbViewTypes = [
  { viewTypeKey: "all", viewName: "ALL" },
  { viewTypeKey: "basic", viewName: "BASIC" },
];
export const ecmViewTypes = [
  { viewTypeKey: "all", viewName: "ALL" },
  { viewTypeKey: "basic", viewName: "BASIC" },
  { viewTypeKey: "flaps", viewName: "FLAPS" },
  { viewTypeKey: "full_no_cw_us_sc_qam", viewName: "US SC QAM" },
  { viewTypeKey: "full_no_cw_ds_sc_qam", viewName: "DS SC QAM" },
];

export const stbDataSetType = [
  {
    dataSetName: "FULL W/O CW",
    subDataSetViews: [
      {
        columnKey: "all",
        columnName: "ALL",
        isVisible: true,
      },
    ],
    fieldSelectionAllowed: true,
    averagesAllowed: true,
  },
  {
    dataSetName: "BASIC",
    subDataSetViews: [
      {
        columnKey: "basic",
        columnName: "BASIC",
        isVisible: true,
      },
    ],
    fieldSelectionAllowed: true,
    averagesAllowed: false,
  },
];

export const ecmDataSetType = [
  {
    dataSetName: "FULL W/O CW",
    subDataSetViews: [
      {
        columnKey: "all",
        columnName: "ALL",
        isVisible: true,
      },
      {
        columnKey: "flaps",
        columnName: "FLAPS",
        isVisible: true,
      },
      {
        columnKey: "full_no_cw_us_sc_qam",
        columnName: "US SC QAM",
        isVisible: true,
      },
      {
        columnKey: "full_no_cw_ds_sc_qam",
        columnName: "DS SC QAM",
        isVisible: true,
      },
      // {
      //   columnKey: "ALL",
      //   columnName: "ALL",
      //   isVisible: true,
      // },
      // {
      //   columnKey: "ALL",
      //   columnName: "ALL",
      //   isVisible: true,
      // },
    ],
    fieldSelectionAllowed: true,
    averagesAllowed: true,
  },
  {
    dataSetName: "BASIC",
    subDataSetViews: [
      {
        columnKey: "BASIC",
        columnName: "BASIC",
        isVisible: true,
      },
    ],
    fieldSelectionAllowed: true,
    averagesAllowed: false,
  },
];

export const specOptions = [
  { columnName: "IN-SPEC", columnKey: "inSpec" },
  { columnName: "OUT-SPEC", columnKey: "outSpec" },
  { columnName: "MARGINAL", columnKey: "marginal" },
  { columnName: "OTHERS", columnKey: "others" },
];

export const stbAttrList: TableAttr[] = [
  {
    columnKey: "address",
    columnName: "Address",
    jsonDataMapping: "address",
    getDataFrom: "listOfModems",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "BASIC"],
  },
  {
    columnKey: "cm_mac",
    columnName: "STB MAC",
    jsonDataMapping: "cmMac",
    getDataFrom: "listOfModems",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "BASIC"],
  },
  /* {
    columnKey: "ecm_mac",
    columnName: "CM MAC",
    jsonDataMapping: "ecmMac",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "BASIC"],
  }, */
  {
    columnKey: "stbIp",
    columnName: "IP Address",
    jsonDataMapping: "stbIP",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedLoading: true,
    allowedViewType: ["ALL", "BASIC"],
  },
  {
    columnKey: "osVersion",
    columnName: "OS Version",
    jsonDataMapping: "osVersion",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL"],
  },
  {
    columnKey: "type",
    columnName: "STB Type",
    jsonDataMapping: "type",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL"],
  },
  {
    columnKey: "qamModel",
    columnName: " QAM Model",
    jsonDataMapping: "qamModel",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["BASIC"],
  },
  {
    columnKey: "smt",
    columnName: "SMT",
    jsonDataMapping: "smt",
    getDataFrom: "listOfModems",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "BASIC"],
  },
  {
    columnKey: "rdcTx",
    columnName: "RDC TX",
    jsonDataMapping: "rdcPower",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL"],
  },
  {
    columnKey: "qamStatus",
    columnName: "QAM Status",
    jsonDataMapping: "qamChannelStatus",
    displayAttribute: "qamChStatusDisplay",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "BASIC"],
  },
  {
    columnKey: "qamFreq",
    columnName: "QAM Freq",
    jsonDataMapping: "qamFreq",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "BASIC"],
  },
  {
    columnKey: "qamSnr",
    columnName: "QAM SNR",
    jsonDataMapping: "qamSnr",
    displayAttribute: "qamSnrDisplay",
    getDataFrom: "summary",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL"],
  },
  {
    columnKey: "avDisc",
    columnName: "AV Disc",
    jsonDataMapping: "mpegDiscontinuityCount",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL"],
  },
  {
    columnKey: "qamUb",
    columnName: "QAM UB",
    jsonDataMapping: "qamUncorrected",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "BASIC"],
  },
  {
    columnKey: "qamTime",
    columnName: "QAM Time",
    jsonDataMapping: "qamTime",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "BASIC"],
  },
  {
    columnKey: "fdcRx",
    columnName: "FDC Rx",
    jsonDataMapping: "fdcPower",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL"],
  },
  {
    columnKey: "fdcUb",
    columnName: "FDC UB",
    jsonDataMapping: "fdcUncorrected",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL"],
  },
  {
    columnKey: "uptime",
    columnName: "Uptime",
    jsonDataMapping: "stbUptime",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "BASIC"],
  },
];

export const ecmAttrList: TableAttr[] = [
  {
    columnKey: "address",
    columnName: "Address",
    jsonDataMapping: "address",
    getDataFrom: "listOfModems",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "BASIC", "FLAPS", "US SC QAM", "DS SC QAM"],
  },
  {
    columnKey: "ecm_mac",
    columnName: "ECM MAC",
    jsonDataMapping: "ecmMac",
    getDataFrom: "listOfModems",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "BASIC", "FLAPS", "US SC QAM", "DS SC QAM"],
  },
  {
    columnKey: "model",
    columnName: "Model",
    jsonDataMapping: "model",
    getDataFrom: "listOfModems",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "BASIC", "FLAPS", "US SC QAM", "DS SC QAM"],
  },
  {
    columnKey: "smt",
    columnName: "SMT",
    jsonDataMapping: "smt",
    getDataFrom: "listOfModems",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "BASIC", "FLAPS", "US SC QAM", "DS SC QAM"],
  },
  {
    columnKey: "uptime",
    columnName: "Uptime",
    jsonDataMapping: "cmUptime",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedLoading: true,
    allowedViewType: ["ALL", "BASIC", "FLAPS", "US SC QAM", "DS SC QAM"],
  },
  {
    columnKey: "regTime",
    columnName: "Reg Time",
    jsonDataMapping: "cmtsUptime",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "BASIC"],
  },
  {
    columnKey: "regStatus",
    columnName: "Reg Status",
    jsonDataMapping: "cmOnlineStatus",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "BASIC"],
  },
  {
    columnKey: "usMod",
    columnName: "US Mod",
    jsonDataMapping: "usModulation",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedLoading: true,
    allowedViewType: ["ALL", "US SC QAM"],
  },
  {
    columnKey: "usChCount",
    columnName: "US#",
    jsonDataMapping: "usChannels",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "US SC QAM"],
  },
  {
    columnKey: "us_tx_min",
    columnName: "US Tx min",
    jsonDataMapping: "usMinTxPower",
    displayAttribute: "usMinTxPowerDisplay",
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "US SC QAM"],
  },
  {
    columnKey: "us_tx_max",
    columnName: "US Tx max",
    jsonDataMapping: "usMaxTxPower",
    displayAttribute: "usMaxTxPowerDisplay",
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "US SC QAM"],
  },
  {
    columnKey: "usTxDelta",
    columnName: "US Tx (Δ)",
    jsonDataMapping: "usTxPowerDelta",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "US SC QAM"],
  },
  {
    columnKey: "us_snr_min",
    columnName: "US SNR min",
    jsonDataMapping: "usMinSnr",
    displayAttribute: "usMinSnrDisplay",
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "US SC QAM"],
  },
  {
    columnKey: "us_snr_max",
    columnName: "US SNR max",
    jsonDataMapping: "usMaxSnr",
    displayAttribute: "usMaxSnrDisplay",
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "US SC QAM"],
  },
  {
    columnKey: "usSnrDelta",
    columnName: "US SNR (Δ)",
    jsonDataMapping: "usSNRDelta",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "US SC QAM"],
  },
  {
    columnKey: "usFreq",
    columnName: "US Freq",
    jsonDataMapping: "usFreq",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["US SC QAM"],
  },
  {
    columnKey: "us_tx",
    columnName: "US Tx",
    jsonDataMapping: "usTx",
    displayAttribute: "usTxDisplay",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["US SC QAM"],
  },
  {
    columnKey: "us_snr",
    columnName: "US SNR",
    jsonDataMapping: "usSnr",
    displayAttribute: "usSnrDisplay",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["US SC QAM"],
  },
  {
    columnKey: "dsChCount",
    columnName: "DS#",
    jsonDataMapping: "dsChannels",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "DS SC QAM"],
  },
  {
    columnKey: "ds_rx_min",
    columnName: "DS Rx min",
    jsonDataMapping: "minRxPower",
    displayAttribute: "minRxPowerDisplay",
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "DS SC QAM"],
  },
  {
    columnKey: "ds_rx_max",
    columnName: "DS Rx max",
    jsonDataMapping: "maxRxPower",
    displayAttribute: "maxRxPowerDisplay",
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "DS SC QAM"],
  },
  {
    columnKey: "dsRxDelta",
    columnName: "DS Rx (Δ)",
    jsonDataMapping: "rxPowerDelta",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "DS SC QAM"],
  },
  {
    columnKey: "ds_snr_min",
    columnName: "DS SNR min",
    jsonDataMapping: "dsMinSnr",
    displayAttribute: "dsMinSnrDisplay",
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "DS SC QAM"],
  },
  {
    columnKey: "ds_snr_max",
    columnName: "DS SNR max",
    jsonDataMapping: "dsMaxSnr",
    displayAttribute: "dsMaxSnrDisplay",
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "DS SC QAM"],
  },
  {
    columnKey: "dsSnrDelta",
    columnName: "DS SNR (Δ)",
    jsonDataMapping: "dsSnrDeltaValue",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "DS SC QAM"],
  },
  {
    columnKey: "dsFreq",
    columnName: "DS Freq",
    jsonDataMapping: "dsFreq",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["DS SC QAM"],
  },
  {
    columnKey: "ds_rx",
    columnName: "DS Rx",
    jsonDataMapping: "dsRx",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    displayAttribute: "dsRxDisplay",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["DS SC QAM"],
  },
  {
    columnKey: "ds_snr",
    columnName: "DS SNR",
    jsonDataMapping: "dsSnr",
    haveMultipleValues: true,
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    displayAttribute: "dsSnrDisplay",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["DS SC QAM"],
  },
  {
    columnKey: "status",
    columnName: "Status",
    jsonDataMapping: "cmOnlineStatus",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL"],
  },
  {
    columnKey: "onlineTimes",
    columnName: "Online Times",
    jsonDataMapping: "onlineTimes",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL"],
  },
  {
    columnKey: "onlinePer",
    columnName: "Online %",
    jsonDataMapping: "onlinePercentage",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL"],
  },
  {
    columnKey: "ipAddress",
    columnName: "IP Address",
    jsonDataMapping: "mgmtIP",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL", "BASIC"],
  },
  {
    columnKey: "usInterface",
    columnName: "US Interfaces",
    jsonDataMapping: "usInterface",
    displayAttribute: "usInterfaceDisplay",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["ALL"],
  },
  {
    columnKey: "flap",
    columnName: "Flap",
    jsonDataMapping: "flapsCount",
    getDataFrom: "flaps",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedLoading: true,
    allowedViewType: ["FLAPS"],
  },
  {
    columnKey: "inf",
    columnName: "Inf",
    jsonDataMapping: "insertionFails",
    getDataFrom: "flaps",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["FLAPS"],
  },
  {
    columnKey: "hits",
    columnName: "Hits",
    jsonDataMapping: "hits",
    getDataFrom: "flaps",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["FLAPS"],
  },
  {
    columnKey: "miss",
    columnName: "Miss",
    jsonDataMapping: "miss",
    getDataFrom: "flaps",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["FLAPS"],
  },
  {
    columnKey: "misses",
    columnName: "Miss %",
    jsonDataMapping: "misses",
    getDataFrom: "flaps",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["FLAPS"],
  },
  {
    columnKey: "pwr",
    columnName: "Pwr",
    jsonDataMapping: "powerAdjustments",
    getDataFrom: "flaps",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["FLAPS"],
  },
  {
    columnKey: "first_flap_time",
    columnName: "First Flap Time",
    jsonDataMapping: "createTime",
    getDataFrom: "flaps",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["FLAPS"],
  },
  {
    columnKey: "last_flap_time",
    columnName: "Last Flap Time",
    jsonDataMapping: "lastFlapTime",
    getDataFrom: "flaps",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedViewType: ["FLAPS"],
  },
];

export const getStbTeleViewConfig = (deviceType: string) => {
  let configList: any = [];
  if (deviceType && deviceType === ConfigConst.ecmstb) {
    ecmViewTypes?.map((vt: any) => {
      let tempViewAttr = ecmAttrList?.filter?.((att: TableAttr) =>
        att.allowedViewType?.some(
          (viewType: string) => viewType === vt?.viewName
        )
      );
      let tempSpecAvailAttr = tempViewAttr?.filter?.(
        (att: TableAttr) => att?.specInfo
      );
      configList?.push({
        viewTypeKey: vt?.viewTypeKey,
        viewName: vt?.viewName,
        specAvailAttr: tempSpecAvailAttr,
        attributes: tempViewAttr,
      });
    });
  } else if (deviceType && deviceType === ConfigConst.oldstb) {
    stbViewTypes?.map((vt: any) => {
      let tempViewAttr = stbAttrList?.filter?.((att: TableAttr) =>
        att.allowedViewType?.some(
          (viewType: string) => viewType === vt?.viewName
        )
      );
      let tempSpecAvailAttr = tempViewAttr?.filter?.(
        (att: TableAttr) => att?.specInfo
      );
      configList?.push({
        viewTypeKey: vt?.viewTypeKey,
        viewName: vt?.viewName,
        specAvailAttr: tempSpecAvailAttr,
        attributes: tempViewAttr,
      });
    });
  }
  return configList;
};

export const getStbTeleActiveDataSet = (
  deviceType: string,
  diagType: string
) => {
  let activeDataSet: any = {};
  if (deviceType && diagType && deviceType === ConfigConst.ecm) {
    ecmDataSetType?.map((dt: any) => {
      if (dt?.dataSetName === diagType) {
        activeDataSet = dt;
      }
    });
  } else if (deviceType && diagType && deviceType === ConfigConst.stb) {
    stbDataSetType?.map((dt: any) => {
      if (dt?.dataSetName === diagType) {
        activeDataSet = dt;
      }
    });
  }
  return activeDataSet;
};
