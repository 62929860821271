import { useDispatch } from "react-redux";
import errImg from "../../resources/images/icons/404Error.png";
import timeout from "../../resources/images/icons/timeOutError.svg";
import emptyData from "../../resources/images/icons/EmptyDataError.svg";
import { Link } from "react-router-dom";
import RPDClient from "../../network/httpclient/rpd/RPDClient";
import { openNewWindow } from "../../components/reusable/Util";
const OrderSuccessBox = (props: any) => {
  const {
    exBoxType,
    errSecName,
    headerText,
    ErrorMSG,
    bodyText,
    srcSystem,
    errCode,
    primaryBtn,
    handleRefresh,
    orderid,
    rpdOrderInfo,
  } = props;
  const dispatch = useDispatch();
  const handleOrderStatus = (orderid: string) => {
    rpdOrderInfo !== undefined
      ? //? openNewWindow(`/#/rpd?orderId=` + orderid)
        window.open(`/#/rpd?orderId=` + orderid, "_blank")
      : dispatch(RPDClient.getOrderInfo(orderid));
  };

  const openSingleCMView = (cmMac: any) => {
    let url = window?.open(`/#/rpd?orderId=${cmMac}`, cmMac)!;
    url?.addEventListener("load", () => {});
  };

  return (
    <div className="exceptionCont">
      {exBoxType === "error" ? (
        <img src={errImg} />
      ) : exBoxType === "timeout" ? (
        <img src={timeout} />
      ) : (
        <img src={emptyData} />
      )}
      {exBoxType === "error" ? (
        <h4>
          Sorry! We are unable to pull info for <b>{errSecName}</b> section at
          this time.
        </h4>
      ) : exBoxType === "timeout" ? (
        <h4>Sorry! Something just went wrong.</h4>
      ) : (
        <h4>{headerText}</h4>
      )}
      {exBoxType === "error" ? (
        <p className="errorMsgCont">
          This is due to the reason - Error Code: {errCode} - “{ErrorMSG}” from{" "}
          {srcSystem}. You can try to refresh or try again after sometime.
        </p>
      ) : exBoxType === "timeout" ? (
        <p>Please try refreshing if data is missing.</p>
      ) : (
        // <Link to="" onClick={() => handleOrderStatus(orderid)}>
        //   {bodyText}
        // </Link>
        <span
          id="cm_mac"
          className="idLink"
          title="RPD Order detail"
          onClick={() => {
            openSingleCMView(orderid);
          }}
        >
          {bodyText}
        </span>
      )}

      {primaryBtn ? (
        <div>
          <button className="refreshExcBtn" onClick={() => handleRefresh()}>
            Refresh
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default OrderSuccessBox;
