import { useState } from "react";
import { Button, Dropdown, DropdownButton, Form } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import AppLabels from "../../../../constants/App_Labels";
import "../../../../design-tokens/forms/Noise.scss";
import CustomPopUp from "../../../reusable/CustomPopUp";
import CustomRadioGroup from "../../../reusable/CustomRadioGroup";
import EmailEntry from "../../../reusable/EmailEntry";
import ImageUpload from "../../../reusable/ImageUpload";
import RedAsterisk from "../../../reusable/RedAsterisk";
import SignatureCapture from "../../../reusable/SignatureCapture";
import { isIgniteSTBAccount, isIgniteAccount } from "../../../reusable/Util";

interface Props {
  detailsObj: any;
  setDetailsObj: any;
}

const SecondHalf = ({ detailsObj, setDetailsObj }: Props) => {
  const labels = AppLabels();
  const checkboxArr = [labels?.Yes, labels?.No];

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setDetailsObj((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRadioDropdownChange = (name: any, value: number) => {
    setDetailsObj({ ...detailsObj, [name]: value });
  };

  return (
    <div>
      {isIgniteSTBAccount() ? (
        <Form.Group>
          <label className="txtFieldLabel">
            {labels.ACCOUNT_NUMBER}
            <RedAsterisk />
          </label>
          <Form.Control
            onChange={handleChange}
            value={detailsObj?.accountNumber}
            name="accountNumber"
          />
        </Form.Group>
      ) : (
        <>
          <Form.Group>
            <label className="txtFieldLabel">
              {labels.ACCOUNT_NUMBER}
              {detailsObj?.hhid === "" && <RedAsterisk />}
            </label>
            <Form.Control
              onChange={handleChange}
              value={detailsObj?.accountNumber}
              name="accountNumber"
              disabled={true}
            />
          </Form.Group>
          <Form.Group>
            <label className="txtFieldLabel">
              {labels.HHID}
              {detailsObj?.hhid !== "" && <RedAsterisk />}
            </label>
            <Form.Control
              //onChange={handleChange}
              value={detailsObj?.hhid}
              disabled={true}
              name="hhid"
            />
          </Form.Group>

          <Form.Group>
            <label className="txtFieldLabel">
              {labels.ACCOUNT_TYPE}
              {detailsObj?.account_type !== "" && <RedAsterisk />}
            </label>
            <Form.Control
              onChange={handleChange}
              value={detailsObj?.account_type}
              name="account_type"
            />
          </Form.Group>
        </>
      )}

      {/* <Form.Group>
            <label className="txtFieldLabel">
              {labels.HHID}
              {detailsObj?.hhid !== "" && <RedAsterisk />}
            </label>
            <Form.Control
              onChange={handleChange}
              value={detailsObj?.hhid}
              name="hhid"
            />
          </Form.Group>

          <Form.Group>
            <label className="txtFieldLabel">
              {labels.ACCOUNT_TYPE}
              {detailsObj?.account_type !== "" && <RedAsterisk />}
            </label>
            <Form.Control
              onChange={handleChange}
              value={detailsObj?.account_type}
              name="account_type"
            />
          </Form.Group> */}
      <span className="formContainer">
        <Form.Group>
          <label className="txtFieldLabel">
            {labels.TECH_NAME}
            <RedAsterisk />
          </label>
          <Form.Control
            onChange={handleChange}
            value={detailsObj?.techName}
            name="techName"
          />
        </Form.Group>
        <Form.Group>
          <label className="txtFieldLabel">
            {labels.TECH_ID}
            <RedAsterisk />
          </label>
          <Form.Control value={detailsObj?.technicianId} disabled={true} />
        </Form.Group>
      </span>
      <span className="formContainer">
        <Form.Group>
          <label className="txtFieldLabel">
            {detailsObj?.UnitNoOrSuiteNo === ""
              ? "House"
              : "Street" + " Number"}
            <RedAsterisk />
          </label>
          <Form.Control
            onChange={handleChange}
            value={detailsObj?.HouseNo}
            name="HouseNo"
          />
        </Form.Group>
        <Form.Group>
          <label className="txtFieldLabel">
            {"Street Name"}
            <RedAsterisk />
          </label>
          <Form.Control
            onChange={handleChange}
            value={detailsObj?.StreetName}
            name=" StreetName"
          />
        </Form.Group>
        <Form.Group>
          <label className="txtFieldLabel">{"Street Direction"}</label>
          <Form.Control
            onChange={handleChange}
            value={detailsObj?.StreetDirection}
            name="StreetDirection"
          />
        </Form.Group>
      </span>
      <span className="formContainer">
        {detailsObj?.UnitNoOrSuiteNo !== "" && (
          <Form.Group>
            <label className="txtFieldLabel">{"Unit or Suite Number"}</label>
            <Form.Control
              onChange={handleChange}
              value={detailsObj?.UnitNoOrSuiteNo}
              name="UnitNoOrSuiteNo"
            />
          </Form.Group>
        )}
        <Form.Group>
          <label className="txtFieldLabel">
            {labels.City}
            <RedAsterisk />
          </label>
          <Form.Control
            onChange={handleChange}
            value={detailsObj?.City}
            name="City"
          />
        </Form.Group>
      </span>
      <span className="formContainer">
        <Form.Group>
          <label className="txtFieldLabel">
            {"Node Number"}
            <RedAsterisk />
          </label>
          <Form.Control
            onChange={handleChange}
            value={detailsObj?.nodeNumber}
            name="nodeNumber"
          />
        </Form.Group>
        <Form.Group>
          <label className="txtFieldLabel">{"CBPID"}</label>
          <Form.Control
            onChange={handleChange}
            value={detailsObj?.cbpId}
            name="cbpId"
            disabled={true}
          />
        </Form.Group>
      </span>

      {/* <Form.Group>
        <label className="txtFieldLabel">{"Customer Contact Number"}</label>
        <Form.Control
          onChange={(v) => props.setCustomerContactNumber(v.target.value)}
          value={props.customerContactNumber}
        />
      </Form.Group> */}
    </div>
  );
};

export default SecondHalf;
