import { shallowEqual, useSelector } from "react-redux";
import { getNodeTeleGloabalStatus } from "../components/reusable/NodeUtil";
import {
  getAccessLevelByRoles,
  getRolesByType,
} from "../components/reusable/Util";
import ConfigConst from "../constants/ConfigConst";
import { ITechProfile } from "../store/ducksPattern/TechProfile";

export function GetTechInfo() {
  const {
    data,
    isLoading,
    status,
    error,
    roles,
    techId,
    lanId,
    activeWorkOrder,
    activeOrderCategory,
  } = useSelector(
    (state: any) =>
      ({
        data: state.slices.techProfile?.data as ITechProfile["data"],
        isLoading: state.slices.techProfile
          ?.isLoading as ITechProfile["isLoading"],
        status: state.slices.techProfile?.status as ITechProfile["status"],
        error: state.slices.techProfile?.error,
        activeWorkOrder: state.slices.techProfile?.activeWorkOrder,
        roles: state.slices.techProfile?.data?.roles,
        techId: state.slices.techProfile?.data?.techId,
        lanId: state.slices.techProfile?.data?.lanId as string,
        activeOrderCategory: state.slices.techProfile?.activeorderCategory,
      } as ITechProfile | any),
    shallowEqual
  );
  const isTechnicianRoleAvailable = getRolesByType(
    roles,
    ConfigConst.ST
  )?.includes(ConfigConst.TECHNICIAN);

  const isOnlySTRoleAvailable = getAccessLevelByRoles(roles)?.some(
    (role) => role === ConfigConst.ST
  );

  const isMaintenanceRoleAvailable = getAccessLevelByRoles(roles).includes(
    ConfigConst.MT
  );

  return {
    data,
    isLoading,
    status,
    error,
    activeWorkOrder,
    activeOrderCategory,
    roles,
    techId,
    lanId,
    isMaintenanceRoleAvailable,
    isTechnicianRoleAvailable,
    isOnlySTRoleAvailable,
  };
}

export function GetFormHistory() {
  const { data, isLoading, status, error, formData, dataList } = useSelector(
    (state: any) => ({
      data: state.AccountState.FormDataInfo.data?.[0],
      dataList: state.AccountState.FormDataInfo.data,
      isLoading: state.AccountState.FormDataInfo.isLoading,
      status: state.AccountState.FormDataInfo.status,
      error: state.AccountState.FormDataInfo.error,
      formData: state.AccountState.FormDataInfo?.data?.[0]?.formData,
    }),
    shallowEqual
  );

  return {
    data,
    dataList,
    isLoading,
    status,
    error,
    formData,
  };
}
export function GetListAcc() {
  const { data, isLoading, status, error } = useSelector(
    (state: any) => ({
      data: state.slices.multiAcc.data,
      isLoading: state.slices.multiAcc.isLoading,
      status: state.slices.multiAcc.status,
      error: state.slices.multiAcc.error,
    }),
    shallowEqual
  );

  return {
    data,
    isLoading,
    status,
    error,
  };
}
export function GetListONT() {
  const { data, isLoading, status, error } = useSelector(
    (state: any) => ({
      data: state.slices.multiONT.data,
      isLoading: state.slices.multiONT.isLoading,
      status: state.slices.multiONT.status,
      error: state.slices.multiONT.error,
    }),
    shallowEqual
  );

  return {
    data,
    isLoading,
    status,
    error,
  };
}
export function GetListMAC() {
  const { data, isLoading, status, error } = useSelector(
    (state: any) => ({
      data: state.slices.multiMAC.data,
      isLoading: state.slices.multiMAC.isLoading,
      status: state.slices.multiMAC.status,
      error: state.slices.multiMAC.error,
    }),
    shallowEqual
  );

  return {
    data,
    isLoading,
    status,
    error,
  };
}
export function GetSpeedData() {
  const { data, isLoading, status, error } = useSelector(
    (state: any) => ({
      data: state.AccountState.cmSpeedTestInfo?.data,
      isLoading: state.AccountState.cmSpeedTestInfo?.isLoading,
      status: state.AccountState.cmSpeedTestInfo?.status,
      error: state.AccountState.cmSpeedTestInfo?.error,
    }),
    shallowEqual
  );

  return {
    data,
    isLoading,
    status,
    error,
  };
}

export function GetPrecheckDiagData() {
  const { precheckDiagnosticData } = useSelector(
    (state: any) => ({
      precheckDiagnosticData:
        state.AccountState.Certification.PrecheckDiagnosticInfo,
    }),
    shallowEqual
  );
  return {
    precheckDiagnosticData,
  };
}

export function GetFileDownloadState() {
  const { data, isLoading, status, error } = useSelector(
    (state: any) => ({
      data: state.FileManagerState.fileDownloadState?.data,
      isLoading: state.FileManagerState.fileDownloadState?.isLoading,
      status: state.FileManagerState.fileDownloadState?.status,
      error: state.FileManagerState.fileDownloadState?.error,
    }),
    shallowEqual
  );

  return {
    data,
    isLoading,
    status,
    error,
  };
}
export function GetFileUploadState() {
  const { data, isLoading, status, error } = useSelector(
    (state: any) => ({
      data: state.FileManagerState.fileUploadState?.data,
      isLoading: state.FileManagerState.fileUploadState?.isLoading,
      status: state.FileManagerState.fileUploadState?.status,
      error: state.FileManagerState.fileUploadState?.error,
    }),
    shallowEqual
  );

  return {
    data,
    isLoading,
    status,
    error,
  };
}
export function GetWifiVisibilityDetails() {
  const { data } = useSelector(
    (state: any) => ({
      data: state.AccountState?.wifiCertVisibility?.data,
    }),
    shallowEqual
  );

  return {
    data,
  }
}
export function GetXLEVisibilityDetails() {
  const { data } = useSelector(
    (state: any) => ({
      data: state.slices?.xleVisible?.data,
    }),
    shallowEqual
  );
  return {
    data
  }
}
export function GetXLEGWDetails() {
  const { gwSummarydata,gwLoading,status,gwDeviceError } = useSelector(
  (state: any) => ({
      gwSummarydata: state.AccountState?.xleGW?.data,
      gwLoading: state.AccountState?.xleGW?.isLoading,
      status: state.AccountState?.xleGW?.status,
      gwDeviceError: state.AccountState?.xleGW?.error,
  }),
  shallowEqual
);
return {
  gwSummarydata,gwLoading,status,gwDeviceError
}
}
export function GetCellularDetails() {
  const { cellulardata,isLoading,status,Error } = useSelector(
  (state: any) => ({
    cellulardata: state.AccountState?.xleCellular?.data,
    isLoading: state.AccountState?.xleCellular?.isLoading,
      status: state.AccountState?.xleCellular?.status,
      Error: state.AccountState?.xleCellular?.error,
  }),
  shallowEqual
);
return {
  cellulardata,isLoading,status,Error
}
}
export function GetBatteryDetails() {
  const { batterydata,batteryLoading,batteryStatus,batteryError } = useSelector(
  (state: any) => ({
    batterydata: state.AccountState?.battery?.data,
    batteryLoading: state.AccountState?.battery?.isLoading,
    batteryStatus: state.AccountState?.battery?.status,
    batteryError: state.AccountState?.battery?.error,
  }),
  shallowEqual
);
return {
  batterydata,batteryLoading,batteryStatus,batteryError
}
}
export function GetFileFetchState() {
  const { data, isLoading, status, error } = useSelector(
    (state: any) => ({
      data: state.FileManagerState.fileFetchState?.data,
      isLoading: state.FileManagerState.fileFetchState?.isLoading,
      status: state.FileManagerState.fileFetchState?.status,
      error: state.FileManagerState.fileFetchState?.error,
    }),
    shallowEqual
  );

  return {
    data,
    isLoading,
    status,
    error,
  };
}

export function GetFormInfo() {
  const { data, isLoading, status, error } = useSelector(
    (state: any) => ({
      data: state.AccountState.FormInfo.data,
      isLoading: state.AccountState.FormInfo.isLoading,
      status: state.AccountState.FormInfo.status,
      error: state.AccountState.FormInfo.error,
    }),
    shallowEqual
  );

  return {
    data,
    isLoading,
    status,
    error,
  };
}

export function GetEmailInfo() {
  const { data, requestType, isLoading, status, error } = useSelector(
    (state: any) => ({
      data: state.AccountState.sendEmailData.data,
      requestType: state.AccountState.sendEmailData.requestType,
      isLoading: state.AccountState.sendEmailData.isLoading,
      status: state.AccountState.sendEmailData.status,
      error: state.AccountState.sendEmailData.error,
    }),
    shallowEqual
  );

  return {
    data,
    requestType,
    isLoading,
    status,
    error,
  };
}
export function GetAccountDetails() {
  const { data, isLoading, status, error } = useSelector(
    (state: any) => ({
      data: state.AccountState.AccountDetails.data,
      isLoading: state.AccountState.AccountDetails.isLoading,
      status: state.AccountState.AccountDetails.status,
      error: state.AccountState.AccountDetails.error,
    }),
    shallowEqual
  );

  return {
    data,
    isLoading,
    status,
    error,
  };
}

export function GetWorkOrdersList() {
  const {
    data,
    isLoading,
    status,
    error,
    activeWOID,
    activeWorkType,
    activeOrderCategory,
    workOrderDetails,
    woDetailsLoading,
  } = useSelector(
    (state: any) => ({
      data: state.workOrdersList.data,
      isLoading: state.workOrdersList.isLoading,
      status: state.workOrdersList.status,
      error: state.workOrdersList.error,
      activeWOID: state.slices.techProfile.activeWorkOrder,
      activeWorkType: state.slices.techProfile.activeWorkType,
      activeOrderCategory: state.slices.techProfile.activeorderCategory,
      workOrderDetails: state.slices.woDetails.data,
      woDetailsLoading: state.slices.woDetails.isLoading,
    }),
    shallowEqual
  );

  //get work order object using active WO ID from Work Orders List
  const activeWO = data?.filter((e: any) => e.id === activeWOID)?.[0];

  return {
    data,
    isLoading,
    status,
    error,
    activeWO,
    activeWorkType,
    activeOrderCategory,
    workOrderDetails,
    woDetailsLoading,
  };
}
export function GetWorkOrderDetails() {
  const { isLoading, status, error, data, productList, orderCategory } =
    useSelector(
      (state: any) => ({
        isLoading: state.slices.woDetails.isLoading,
        data: state.slices.woDetails.data,
        status: state.slices.woDetails.status,
        error: state.slices.woDetails.error,
        productList: state.slices.woDetails?.data?.productList,
        orderCategory: state.slices.woDetails?.data?.orderCategory,
      }),
      shallowEqual
    );
  return {
    data,
    isLoading,
    status,
    error,
    productList,
    orderCategory,
  };
}
export function GetPingDetails() {
  const { isLoading, status, error, data } = useSelector(
    (state: any) => ({
      isLoading: state.slices.pingData.isLoading,
      data: state.slices.pingData.data,
      status: state.slices.pingData.status,
      error: state.slices.pingData.error,
    }),
    shallowEqual
  );
  return {
    data,
    isLoading,
    status,
    error,
  };
}
export function GetTraceRouteDetails() {
  const { isLoading, status, error, data } = useSelector(
    (state: any) => ({
      isLoading: state.slices.traceRouteData.isLoading,
      data: state.slices.traceRouteData.data,
      status: state.slices.traceRouteData.status,
      error: state.slices.traceRouteData.error,
    }),
    shallowEqual
  );
  return {
    data,
    isLoading,
    status,
    error,
  };
}
export function GetWorkOrderUpdateStatus() {
  const { isLoading, status, data, error } = useSelector(
    (state: any) => ({
      isLoading: state.slices.woUpdateStatus.isLoading,
      status: state.slices.woUpdateStatus.status,
      error: state.slices.woUpdateStatus.error,
      data: state.slices.woUpdateStatus.data,
    }),
    shallowEqual
  );
  return {
    data,
    isLoading,
    status,
    error,
  };
}

export function GetActiveAccountDetails() {
  const {
    data,
    isLoading,
    status,
    error,
    accountNumber,
    cbpId,
    brand,
    selectedSA,
    accType,
    sortedDevices,
    testType,
    certiData,
    EmailFormData,
    allCertiList,
  } = useSelector(
    (state: any) => ({
      data: state.AccountState.ActiveAccountDetails.data,
      isLoading: state.AccountState.ActiveAccountDetails.isLoading,
      status: state.AccountState.ActiveAccountDetails.status,
      error: state.AccountState.ActiveAccountDetails.error,
      accountNumber:
        state.AccountState.ActiveAccountDetails.data?.accountNumber,
      cbpId: state.AccountState.ActiveAccountDetails.data?.cbpId,
      brand: state.AccountState.ActiveAccountDetails.data?.brand,
      selectedSA: state.AccountState.ActiveAccountDetails.selectedSA,
      accType: state.AccountState.ActiveAccountDetails.accountType,
      sortedDevices: state.AccountState.ActiveAccountDetails.sortedDevices,
      testType:
        state.AccountState.Certification?.ActiveCertiInfo?.certiInfo?.data
          ?.testType,
      certiData: state.AccountState.Certification?.ActiveCertiInfo?.certiInfo,
      EmailFormData:
        state.AccountState.Certification?.ActiveCertiInfo?.certiInfo?.data,
      allCertiList: state.AccountState.Certification?.AllCertiInfo?.data,
    }),
    shallowEqual
  );

  return {
    data,
    isLoading,
    status,
    error,
    accountNumber,
    cbpId,
    brand,
    selectedSA,
    accType,
    sortedDevices,
    testType,
    certiData,
    EmailFormData,
    allCertiList,
  };
}
export function GetNodeBasicInfoDetails() {
  const {
    mlBasicData,
    mlBasicIsLoading,
    mlBasicStatus,
    mlBasicError,
    diagBasicStartTime,
    diagBasicEndTime,
    apiBasicCallCounter,
    isBasicDevicesVisible,
    allBasicDiagIsLoading,
    diagBasicType,
    isBasicAborted,
    advancedBasicFilters,
    nodeBasicId,
    allDiagBasicStatus,
    allDiagBasicError,
    viewBasicType,
    modemBasicType,
    isBasicCWEnabled,
    isBasicNeighbourhood,
    advFBasicCond,
    selectedBasicProfile,
  } = useSelector(
    (state: any) => ({
      mlBasicData: state.slices.NodeBasictelemetry?.mlData, //ml = modemsList
      mlBasicIsLoading: state.slices.NodeBasictelemetry?.mlIsLoading,
      mlBasicStatus: state.slices.NodeBasictelemetry?.mlStatus,
      mlBasicError: state.slices.NodeBasictelemetry?.mlError,
      diagBasicStartTime: state.slices.NodeBasictelemetry?.diagStartTime,
      diagBasicEndTime: state.slices.NodeBasictelemetry?.diagEndTime,
      apiBasicCallCounter: state.slices.NodeBasictelemetry?.apiCallCounter,
      isBasicDevicesVisible: state.slices.NodeBasictelemetry?.isDevicesVisible,
      allBasicDiagIsLoading: state.slices.NodeBasictelemetry?.allDiagIsLoading,
      diagBasicType: state.slices.NodeBasictelemetry?.diagType, //diagnostics type, eg (all, summary)
      isBasicAborted: state.slices.NodeBasictelemetry?.isAborted,
      advancedBasicFilters: state.slices.NodeBasictelemetry?.advancedFilters,
      nodeBasicId: state.slices.NodeBasictelemetry?.nodeId,
      allDiagBasicStatus: state.slices.NodeBasictelemetry?.allDiagStatus,
      allDiagBasicError: state.slices.NodeBasictelemetry?.allDiagError,
      viewBasicType: state.slices.NodeBasictelemetry?.viewType,
      modemBasicType: state.slices.NodeBasictelemetry?.modemType,
      isBasicCWEnabled: state.slices.NodeBasictelemetry?.isCWEnabled,
      isBasicNeighbourhood: state.slices.NodeBasictelemetry?.isNeighbourhood,
      advFBasicCond: state.slices.NodeBasictelemetry?.advFCond,
      selectedBasicProfile: state.slices.NodeBasictelemetry?.activeProfile,
    }),
    shallowEqual
  );

  return {
    mlBasicData,
    mlBasicIsLoading,
    mlBasicStatus,
    mlBasicError,
    diagBasicStartTime,
    diagBasicEndTime,
    apiBasicCallCounter,
    isBasicDevicesVisible,
    allBasicDiagIsLoading,
    diagBasicType,
    isBasicAborted,
    advancedBasicFilters,
    nodeBasicId,
    allDiagBasicStatus,
    allDiagBasicError,
    viewBasicType,
    modemBasicType,
    isBasicCWEnabled,
    advFBasicCond,
    isBasicNeighbourhood,
    selectedBasicProfile,
  };
}

export function GetAddressInfoDetails() {
  const {
    mlAddressData,
    mlAddressIsLoading,
    mlAddressStatus,
    mlAddressError,
    diagAddressStartTime,
    diagAddressEndTime,
    apiAddressCallCounter,
    isAddressDevicesVisible,
    allAddressDiagIsLoading,
    diagAddressType,
    isAddressAborted,
    advancedAddressFilters,
    nodeAddressId,
    allDiagAddressStatus,
    allDiagAddressError,
    viewAddressType,
    modemAddressType,
    isAddressCWEnabled,
    isAddressNeighbourhood,
    advFAddressCond,
    selectedAddressProfile,
  } = useSelector(
    (state: any) => ({
      mlAddressData: state.slices.addressTelemetry?.mlData, //ml = modemsList
      mlAddressIsLoading: state.slices.addressTelemetry?.mlIsLoading,
      mlAddressStatus: state.slices.addressTelemetry?.mlStatus,
      mlAddressError: state.slices.addressTelemetry?.mlError,
      diagAddressStartTime: state.slices.addressTelemetry?.diagStartTime,
      diagAddressEndTime: state.slices.addressTelemetry?.diagEndTime,
      apiAddressCallCounter: state.slices.addressTelemetry?.apiCallCounter,
      isAddressDevicesVisible: state.slices.addressTelemetry?.isDevicesVisible,
      allAddressDiagIsLoading: state.slices.addressTelemetry?.allDiagIsLoading,
      diagAddressType: state.slices.addressTelemetry?.diagType, //diagnostics type, eg (all, summary)
      isAddressAborted: state.slices.addressTelemetry?.isAborted,
      advancedAddressFilters: state.slices.addressTelemetry?.advancedFilters,
      nodeAddressId: state.slices.addressTelemetry?.nodeId,
      allDiagAddressStatus: state.slices.addressTelemetry?.allDiagStatus,
      allDiagAddressError: state.slices.addressTelemetry?.allDiagError,
      viewAddressType: state.slices.addressTelemetry?.viewType,
      modemAddressType: state.slices.addressTelemetry?.modemType,
      isAddressCWEnabled: state.slices.addressTelemetry?.isCWEnabled,
      isAddressNeighbourhood: state.slices.addressTelemetry?.isNeighbourhood,
      advFAddressCond: state.slices.addressTelemetry?.advFCond,
      selectedAddressProfile: state.slices.addressTelemetry?.activeProfile,
    }),
    shallowEqual
  );

  return {
    mlAddressData,
    mlAddressIsLoading,
    mlAddressStatus,
    mlAddressError,
    diagAddressStartTime,
    diagAddressEndTime,
    apiAddressCallCounter,
    isAddressDevicesVisible,
    allAddressDiagIsLoading,
    diagAddressType,
    isAddressAborted,
    advancedAddressFilters,
    nodeAddressId,
    allDiagAddressStatus,
    allDiagAddressError,
    viewAddressType,
    modemAddressType,
    isAddressCWEnabled,
    isAddressNeighbourhood,
    advFAddressCond,
    selectedAddressProfile,
  };
}

export function GetNodeInfoDetails() {
  const {
    searchNodeStatus,
    currNodedata,
    nodedata,
    currNodeState,
    allNodes,
    activekey,
    nodeSubTabIndex,
    activeNodePage,
    data,
    status,
    isLoading,
    error,
    nodeInfo,
    searchType,
  } = useSelector(
    (state: any) => ({
      searchNodeStatus: state.NodeState.NodeInfoDetails.status,
      searchType: state.NodeState.NodeInfoDetails.searchType,
      currNodedata: state.NodeState.ActiveNodeinfoDetails.data,
      nodedata: state.NodeState.NodeInfoDetails.data,
      currNodeState: state.NodeState,
      allNodes: state.AllNodes.nodes,
      activekey: state.AllNodes.activekey,
      nodeSubTabIndex: state.NodeState.ActiveNodeinfoDetails.activeSubTabIndex,
      activeNodePage: state.NodeState.ActiveNodeinfoDetails.activeNodePage,
      data: state.NodeState.NodeInfoDetails.data,
      status: state.NodeState.NodeInfoDetails.status,
      isLoading: state.NodeState.NodeInfoDetails.isLoading,
      error: state.NodeState.NodeInfoDetails.error,
      nodeInfo: state.NodeState.ActiveNodeinfoDetails.data,
    }),
    shallowEqual
  );
  const activeConfigNodeId = currNodedata?.smt
    ? currNodedata?.smtId
    : currNodedata?.nodeId;

  return {
    searchNodeStatus,
    currNodedata,
    nodedata,
    currNodeState,
    allNodes,
    activekey,
    nodeSubTabIndex,
    activeNodePage,
    data,
    status,
    isLoading,
    error,
    nodeInfo,
    activeConfigNodeId,
    searchType,
  };
}

export const GetPONONTDetails = () => {
  const { ponInfo, allIsLoading,oltIsLoading,ponstatus,nodeSummaryList, oltResultData, ponNode } = useSelector(
    (state: any) => ({
      ponInfo: state.slices.PONFluxtelemetry,
      allIsLoading: state.slices.PONFluxtelemetry.allIsLoading,
      oltIsLoading: state.slices.PONFluxtelemetry.oltIsLoading,
      ponstatus: state.slices.PONFluxtelemetry.oltStatus,
      nodeSummaryList: state.slices.PONFluxtelemetry?.oltData,
      oltResultData:state.slices.PONFluxtelemetry?.oltData,
      ponNode: state.GponState.GponNodeInfo?.data?.ponNode,
    }),
    shallowEqual
  );
  return {
    ponInfo,
    allIsLoading,
    oltIsLoading,
    ponstatus,
    nodeSummaryList,
    oltResultData,
    ponNode,
  };
};
export const GetPONPortDetails = () => {
  const { ponInfo, ponstatus,nodeSummaryList, oltResultData, ponNode } = useSelector(
    (state: any) => ({
      ponInfo: state.slices.PONPorttelemetry,
      ponstatus: state.slices.PONPorttelemetry.oltStatus,
      nodeSummaryList: state.slices.PONPorttelemetry?.oltData,
      oltResultData:state.slices.PONPorttelemetry?.oltData,
      ponNode: state.GponState.GponNodeInfo?.data?.ponNode,
    }),
    shallowEqual
  );
  return {
    ponInfo,
    ponstatus,
    nodeSummaryList,
    oltResultData,
    ponNode,
  };
};
export function GetOltSearchDetails() {
  const {
    isLoading,
    oltResultData,
    oltName,
    oltStatus,
    oltError,
    isOntCountLoading,
    ontCountData,
    ontCountStatus,
    ontCountError,
    isAborted,
    diagStartTime,
    diagEndTime,
  } = useSelector(
    (state: any) => ({
      isLoading: state.slices.PONPorttelemetry.oltIsLoading,
      oltResultData: state.slices.PONPorttelemetry.oltData,
      oltName: state.slices.PONPorttelemetry.oltName,
      oltStatus: state.slices.PONPorttelemetry.oltStatus,
      oltError: state.slices.PONPorttelemetry.oltError,
      diagStartTime: state.slices.PONPorttelemetry.diagStartTime,
      diagEndTime: state.slices.PONPorttelemetry.diagEndTime,

      isOntCountLoading: state.GponState.OltResultDetails.isOntCountLoading,
      ontCountData: state.GponState.OltResultDetails.ontCountData,
      isAborted: state.GponState.OltResultDetails.isAborted,
      ontCountStatus: state.GponState.OltResultDetails.ontCountStatus,
      ontCountError: state.GponState.OltResultDetails.ontCountError,
    }),
    shallowEqual
  );
  return {
    oltResultData,
    oltName,
    isLoading,
    oltStatus,
    oltError,
    isOntCountLoading,
    ontCountData,
    ontCountStatus,
    ontCountError,
    isAborted,
    diagStartTime,
    diagEndTime,
  };
}

export const GetGponNodeDetails = () => {
  const { nodeInfo, nodeSummaryList, oltResultData, ponNode ,status,loading,subdbEndTime,phub} = useSelector(
    (state: any) => ({
      nodeInfo: state.GponState.GponNodeInfo,
      status:state.GponState.GponNodeInfo.status,
      loading:state.GponState.GponNodeInfo.isLoading,
      subdbEndTime: state.GponState.GponNodeInfo.diagEndTime,
      nodeSummaryList: state.GponState.GponNodeSummary,
      oltResultData: state.GponState.OltResultDetails.resultList,
      ponNode: state.GponState.GponNodeInfo?.data?.ponNode,
      phub:state.GponState.GponNodeInfo?.data?.phub,
    }),
    shallowEqual
  );
  return {
    nodeInfo,
    status,
    loading,
    subdbEndTime,
    nodeSummaryList,
    oltResultData,
    ponNode,
    phub
  };
};

export function GetTelemetryDetails() {
  const { ducksConfigData } = useSelector(
    (state: any) => ({
      ducksConfigData: state.userDucksConfig.configData,
    }),
    shallowEqual
  );
  return {
    ducksConfigData,
  };
}

export function GetTelemetryAverages() {
  const { nodeAveragesStatusData, nodeAveragesStatusLoading } = useSelector(
    (state: any) => ({
      nodeAveragesStatusData: state.NodeState.nodeAveragesStatus.data,
      nodeAveragesStatusLoading: state.NodeState.nodeAveragesStatus.isLoading,
    }),
    shallowEqual
  );
  return {
    nodeAveragesStatusData,
    nodeAveragesStatusLoading,
  };
}

export function GetNotificationInfo() {
  const { notiList, notiListLoading } = useSelector(
    (state: any) => ({
      notiList: state.NotificationList.data,
      notiListLoading: state.NotificationList.isLoading,
    }),
    shallowEqual
  );

  return {
    notiList,
    notiListLoading,
  };
}

export function GetNodePowerSuppliesDetails() {
  const {
    allNodePowerSuppliesData,
    allNodePowerSuppliesLoading,
    allNodePowerSuppliesStatus,
  } = useSelector(
    (state: any) => ({
      allNodePowerSuppliesData:
        state.slices.topologyState.activeNodePowerSupplies.data,
      allNodePowerSuppliesLoading:
        state.slices.topologyState.activeNodePowerSupplies.isLoading,
      allNodePowerSuppliesStatus:
        state.slices.topologyState.activeNodePowerSupplies.status,
    }),
    shallowEqual
  );

  return {
    allNodePowerSuppliesData,
    allNodePowerSuppliesLoading,
    allNodePowerSuppliesStatus,
  };
}

export function GetNodeRFDropsDetails() {
  const { allNodeRFDropsData, allNodeRFDropsLoading, allNodeRFDropsStatus } =
    useSelector(
      (state: any) => ({
        allNodeRFDropsData: state.slices.topologyState.activeNodeRFDrops.data,
        allNodeRFDropsLoading:
          state.slices.topologyState.activeNodeRFDrops.isLoading,
        allNodeRFDropsStatus:
          state.slices.topologyState.activeNodeRFDrops.status,
      }),
      shallowEqual
    );
  return {
    allNodeRFDropsData,
    allNodeRFDropsLoading,
    allNodeRFDropsStatus,
  };
}

export function GetNodeMDUDetails() {
  const { allNodeMDUData, allNodeMDULoading, allNodeMDUStatus } = useSelector(
    (state: any) => ({
      allNodeMDUData: state.slices.topologyState.activeNodeMDU.data,
      allNodeMDULoading: state.slices.topologyState.activeNodeMDU.isLoading,
      allNodeMDUStatus: state.slices.topologyState.activeNodeMDU.status,
    }),
    shallowEqual
  );

  return {
    allNodeMDUData,
    allNodeMDULoading,
    allNodeMDUStatus,
  };
}

export function GetNodeSupportStructuresDetails() {
  const {
    allNodeSupportStructuresData,
    allNodeSupportStructuresLoading,
    allNodeSupportStructuresStatus,
  } = useSelector(
    (state: any) => ({
      allNodeSupportStructuresData:
        state.slices.topologyState.activeNodeSupportStructures.data,
      allNodeSupportStructuresLoading:
        state.slices.topologyState.activeNodeSupportStructures.isLoading,
      allNodeSupportStructuresStatus:
        state.slices.topologyState.activeNodeSupportStructures.status,
    }),
    shallowEqual
  );

  return {
    allNodeSupportStructuresData,
    allNodeSupportStructuresLoading,
    allNodeSupportStructuresStatus,
  };
}

export function GetNodeActiveRFEquipmentDetails() {
  const {
    allNodeActiveRFEquipmentData,
    allNodeActiveRFEquipmentLoading,
    allNodeActiveRFEquipmentStatus,
  } = useSelector(
    (state: any) => ({
      allNodeActiveRFEquipmentData:
        state.slices.topologyState.activeNodeActiveRFEquipment.data,
      allNodeActiveRFEquipmentLoading:
        state.slices.topologyState.activeNodeActiveRFEquipment.isLoading,
      allNodeActiveRFEquipmentStatus:
        state.slices.topologyState.activeNodeActiveRFEquipment.status,
    }),
    shallowEqual
  );

  return {
    allNodeActiveRFEquipmentData,
    allNodeActiveRFEquipmentLoading,
    allNodeActiveRFEquipmentStatus,
  };
}

export function GetRpaInfo() {
  const { data, isLoading, status, error, success } = useSelector(
    (state: any) => ({
      data: state.AccountState.sendRpaData?.data,
      isLoading: state.AccountState.sendRpaData?.isLoading,
      status: state.AccountState.sendRpaData?.status,
      error: state.AccountState.sendRpaData?.error,
      // sentAttemptErrors: state.AccountState.sendRpaData?.sentAttemptErrors,
      success: state.AccountState.sendRpaData?.success,
    }),
    shallowEqual
  );

  return {
    data,
    isLoading,
    status,
    error,
    // sentAttemptErrors,
    success,
  };
}

export function GetClearFlapsInfo() {
  const {
    clearFlapsData,
    clearFlapsIsLoading,
    clearFlapsError,
    clearFlapsStatus,
  } = useSelector(
    (state: any) => ({
      clearFlapsData: state.NodeState.ClearFlapsNodeModems.data,
      clearFlapsIsLoading: state.NodeState.ClearFlapsNodeModems.isLoading,
      clearFlapsError: state.NodeState.ClearFlapsNodeModems.error,
      clearFlapsStatus: state.NodeState.ClearFlapsNodeModems.status,
    }),
    shallowEqual
  );
  return {
    clearFlapsData,
    clearFlapsIsLoading,
    clearFlapsError,
    clearFlapsStatus,
  };
}

export function GetFlapsHistory() {
  const {
    flapHistoryData,
    flapHistoryIsLoading,
    flapHistoryStatus,
    flapHistoryError,
  } = useSelector(
    (state: any) => ({
      flapHistoryData: state.NodeState.FlapHistoryData.data,
      flapHistoryIsLoading: state.NodeState.FlapHistoryData.isLoading,
      flapHistoryStatus: state.NodeState.FlapHistoryData.status,
      flapHistoryError: state.NodeState.FlapHistoryData.error,
    }),
    shallowEqual
  );
  return {
    flapHistoryData,
    flapHistoryIsLoading,
    flapHistoryStatus,
    flapHistoryError,
  };
}

export function GetRPDInfo() {
  const {
    data,
    isLoading,
    nodeId,
    macAddress,
    error,
    isSwVersionLoading,
    swData,
    swError,
  } = useSelector(
    (state: any) => ({
      data: state.AccountState.rpd.rpd.data,
      isLoading: state.AccountState.rpd.rpd.isLoading,
      nodeId: state.AccountState.rpd.rpd.nodeId,
      macAddress: state.AccountState.rpd.rpd.macAddress,
      error: state.AccountState.rpd.rpd.error,
      isSwVersionLoading: state.AccountState.rpd.rpdSwVersion.isLoading,
      swData: state.AccountState.rpd.rpdSwVersion.data,
      swError: state.AccountState.rpd.rpdSwVersion.error,
    }),
    shallowEqual
  );

  return {
    data,
    isLoading,
    nodeId,
    macAddress,
    error,
    isSwVersionLoading,
    swData,
    swError,
  };
}

export function GetRPDRegistrationInfo() {
  const { data, isLoading, clamshellID, error, macAddress, rpdNumber } =
    useSelector(
      (state: any) => ({
        data: state.AccountState.rpd.rpdRegistration.data,
        isLoading: state.AccountState.rpd.rpdRegistration.isLoading,
        clamshellID: state.AccountState.rpd.rpdRegistration.clamshellId,
        error: state.AccountState.rpd.rpdRegistration.error,
        macAddress: state.AccountState.rpd.rpdRegistration.macAddress,
        rpdNumber: state.AccountState.rpd.rpdRegistration.rpdNumber,
      }),
      shallowEqual
    );
  return {
    data,
    isLoading,
    clamshellID,
    error,
    macAddress,
    rpdNumber,
  };
}

export function GetRPDSwapInfo() {
  const {
    data,
    isLoading,
    macAddress,
    error,
    oldDeviceType,
    newDeviceType,
    nptId,
    isMacRPDLoading,
    macRPDError,
    macRPDData,
    emails,
  } = useSelector(
    (state: any) => ({
      data: state.AccountState.rpd.rpdSwap.data,
      isLoading: state.AccountState.rpd.rpdSwap.isLoading,
      macAddress: state.AccountState.rpd.rpdSwap.macAddress,
      error: state.AccountState.rpd.rpdSwap.error,
      oldDeviceType: state.AccountState.rpd.rpdSwap.oldDeviceType,
      newDeviceType: state.AccountState.rpd.rpdSwap.newDeviceType,
      nptId: state.AccountState.rpd.rpdSwap.nptId,
      isMacRPDLoading: state.AccountState.rpd.rpdMac.isLoading,
      macRPDError: state.AccountState.rpd.rpdMac.error,
      macRPDData: state.AccountState.rpd.rpdMac.data,
      emails: state.AccountState.rpd.rpdSwap.emails,
    }),
    shallowEqual
  );
  return {
    data,
    isLoading,
    macAddress,
    error,
    oldDeviceType,
    newDeviceType,
    nptId,
    isMacRPDLoading,
    macRPDError,
    macRPDData,
    emails,
  };
}

export function GetRPDSWUpgradeMacInfo() {
  const {
    data,
    error,
    isLoading,
    isSWUpgLoading,
    swUpgradeData,
    swUpgradeError,
    isMacRPDLoading,
    macRPDError,
    macRPDData,
    nptId,
    macAddress,
    mail,
    device,
  } = useSelector(
    (state: any) => ({
      data: state.AccountState.rpd.nptSummaryMac.data,
      error: state.AccountState.rpd.nptSummaryMac.error,
      isLoading: state.AccountState.rpd.nptSummaryMac.isLoading,
      isSWUpgLoading: state.AccountState.rpd.rpdSWUpgradeMac.isLoading,
      swUpgradeData: state.AccountState.rpd.rpdSWUpgradeMac.data,
      swUpgradeError: state.AccountState.rpd.rpdSWUpgradeMac.error,
      isMacRPDLoading: state.AccountState.rpd.rpdMac.isLoading,
      macRPDError: state.AccountState.rpd.rpdMac.error,
      macRPDData: state.AccountState.rpd.rpdMac.data,
      nptId: state.AccountState.rpd.rpdSWUpgradeMac.nptId,
      macAddress: state.AccountState.rpd.rpdSWUpgradeMac.macAd,
      mail: state.AccountState.rpd.rpdSWUpgradeMac.mail,
      device: state.AccountState.rpd.rpdSWUpgradeMac.device,
    }),
    shallowEqual
  );
  return {
    data,
    error,
    isLoading,
    isSWUpgLoading,
    swUpgradeData,
    swUpgradeError,
    isMacRPDLoading,
    macRPDError,
    macRPDData,
    nptId,
    macAddress,
    mail,
    device,
  };
}

export function GetRPDActivationInfo() {
  const {
    data,
    error,
    isLoading,
    isActivationLoading,
    activationData,
    activationError,
    nptId,
    mails,
  } = useSelector(
    (state: any) => ({
      data: state.AccountState.rpd.nptSummary.data,
      error: state.AccountState.rpd.nptSummary.error,
      isLoading: state.AccountState.rpd.nptSummary.isLoading,
      isActivationLoading: state.AccountState.rpd.rpdActivation.isLoading,
      activationData: state.AccountState.rpd.rpdActivation.data,
      activationError: state.AccountState.rpd.rpdActivation.error,
      nptId: state.AccountState.rpd.nptSummary.nptId,
      mails: state.AccountState.rpd.nptSummary.mails,
    }),
    shallowEqual
  );
  return {
    data,
    error,
    isLoading,
    isActivationLoading,
    activationData,
    activationError,
    nptId,
    mails,
  };
}

export function GetNodeGeneralProps() {
  const { nodeTopologyTab } = useSelector(
    (state: any) => ({
      nodeTopologyTab: state.slices.nodeReducer.nodeGeneral.nodeTopologyTab,
    }),
    shallowEqual
  );

  return { nodeTopologyTab };
}

export function GetRPDOrderInfo() {
  const { data, isLoading, orderId, status, error } = useSelector(
    (state: any) => ({
      data: state.AccountState.rpd.rpdOrderInfo.data,
      isLoading: state.AccountState.rpd.rpdOrderInfo.isLoading,
      orderId: state.AccountState.rpd.rpdOrderInfo.orderId,
      status: state.AccountState.rpd.rpdOrderInfo.status,
      error: state.AccountState.rpd.rpdOrderInfo.error,
    }),
    shallowEqual
  );

  return {
    data,
    isLoading,
    orderId,
    status,
    error,
  };
}

export function GetRPDOptionsList() {
  const { data, isLoading, status, error } = useSelector(
    (state: any) => ({
      data: state.AccountState.rpd.rpdOptionsList.data,
      isLoading: state.AccountState.rpd.rpdOptionsList.isLoading,
      status: state.AccountState.rpd.rpdOptionsList.status,
      error: state.AccountState.rpd.rpdOptionsList.error,
    }),
    shallowEqual
  );

  return {
    data,
    isLoading,
    status,
    error,
  };
}

export function GetRPDProvisioningInfo() {
  const {
    data,
    isLoading,
    nptId,
    error,
    qpsk,
    aux,
    docsisip,
    videoip,
    mapping,
    macdomain,
    rpdmac,
    docsishostname,
    videohostname,
  } = useSelector(
    (state: any) => ({
      data: state.AccountState.rpd.rpdProvisioning.data,
      isLoading: state.AccountState.rpd.rpdProvisioning.isLoading,
      nptId: state.AccountState.rpd.rpdProvisioning.nptId,
      error: state.AccountState.rpd.rpdProvisioning.error,
      qpsk: state.AccountState.rpd.rpdProvisioning.qpsk,
      aux: state.AccountState.rpd.rpdProvisioning.aux,
      docsisip: state.AccountState.rpd.rpdProvisioning.docsisip,
      videoip: state.AccountState.rpd.rpdProvisioning.videoip,
      mapping: state.AccountState.rpd.rpdProvisioning.mapping,
      macdomain: state.AccountState.rpd.rpdProvisioning.macdomain,
      rpdmac: state.AccountState.rpd.rpdProvisioning.rpdmac,
      docsishostname: state.AccountState.rpd.rpdProvisioning.docsishostname,
      videohostname: state.AccountState.rpd.rpdProvisioning.videohostname,
    }),
    shallowEqual
  );
  return {
    data,
    isLoading,
    nptId,
    error,
    qpsk,
    aux,
    docsisip,
    videoip,
    mapping,
    macdomain,
    rpdmac,
    docsishostname,
    videohostname,
  };
}

export function GetNodeCertificationHist() {
  const {
    nodeCertHistData,
    nodeCertHistIsLoading,
    nodeCertHistStatus,
    nodeCertHistError,
  } = useSelector(
    (state: any) => ({
      nodeCertHistData:
        state.NodeState.nodeCertifications.nodeCertificationDetails.data,
      nodeCertHistIsLoading:
        state.NodeState.nodeCertifications.nodeCertificationDetails.isLoading,
      nodeCertHistStatus:
        state.NodeState.nodeCertifications.nodeCertificationDetails.status,
      nodeCertHistError:
        state.NodeState.nodeCertifications.nodeCertificationDetails.error,
      nodeCertifcationDetailsError:
        state.NodeState.nodeCertifications.nodeCertificationDetails
          .detailsError,
      nodeCertifcationDetailsLoading:
        state.NodeState.nodeCertifications.nodeCertificationDetails
          .detailsLoading,
      nodeCertifcationDetailsStatus:
        state.NodeState.nodeCertifications.nodeCertificationDetails
          .detailsStatus,
      nodeCertifcationDetailsData:
        state.NodeState.nodeCertifications.nodeCertificationDetails.detailsData,
    }),
    shallowEqual
  );

  return {
    nodeCertHistData,
    nodeCertHistIsLoading,
    nodeCertHistStatus,
    nodeCertHistError,
  };
}

export function GetSWUpgradeInfo() {
  const { data, isLoading, nptId, error } = useSelector(
    (state: any) => ({
      data: state.AccountState.rpd.rpdSWUpgrade.data,
      isLoading: state.AccountState.rpd.rpdSWUpgrade.isLoading,
      nptId: state.AccountState.rpd.rpdSWUpgrade.nptId,
      error: state.AccountState.rpd.rpdSWUpgrade.error,
    }),
    shallowEqual
  );
  return {
    data,
    isLoading,
    nptId,
    error,
  };
}

export function GetRPDDecommInfo() {
  const {
    data,
    isLoading,
    macAddress,
    error,
    isMacRPDLoading,
    macRPDError,
    macRPDData,
  } = useSelector(
    (state: any) => ({
      data: state.AccountState.rpd.rpdDecomm.data,
      isLoading: state.AccountState.rpd.rpdDecomm.isLoading,
      macAddress: state.AccountState.rpd.rpdDecomm.macAddress,
      error: state.AccountState.rpd.rpdDecomm.error,
      isMacRPDLoading: state.AccountState.rpd.rpdDecommMac.isLoading,
      macRPDError: state.AccountState.rpd.rpdDecommMac.error,
      macRPDData: state.AccountState.rpd.rpdDecommMac.data,
    }),
    shallowEqual
  );
  return {
    data,
    isLoading,
    macAddress,
    error,
    isMacRPDLoading,
    macRPDError,
    macRPDData,
  };
}

export function GetCASAVideoExecInfo() {
  const {
    data,
    isLoading,
    nptId,
    error,
    qpsk,
    aux,
    docsisip,
    videoip,
    mapping,
    macdomain,
    rpdmac,
    docsishostname,
    videohostname,
    videoCore,
    botType,
  } = useSelector(
    (state: any) => ({
      data: state.AccountState.rpd.vccapCASAVideo.data,
      isLoading: state.AccountState.rpd.vccapCASAVideo.isLoading,
      nptId: state.AccountState.rpd.vccapCASAVideo.nptId,
      error: state.AccountState.rpd.vccapCASAVideo.error,
      qpsk: state.AccountState.rpd.vccapCASAVideo.qpsk,
      aux: state.AccountState.rpd.vccapCASAVideo.aux,
      docsisip: state.AccountState.rpd.vccapCASAVideo.docsisip,
      videoip: state.AccountState.rpd.vccapCASAVideo.videoip,
      mapping: state.AccountState.rpd.vccapCASAVideo.mapping,
      macdomain: state.AccountState.rpd.vccapCASAVideo.macdomain,
      rpdmac: state.AccountState.rpd.vccapCASAVideo.rpdmac,
      docsishostname: state.AccountState.rpd.vccapCASAVideo.docsishostname,
      videohostname: state.AccountState.rpd.vccapCASAVideo.videohostname,
      videoCore: state.AccountState.rpd.vccapCASAVideo.videoCore,
      botType: state.AccountState.rpd.vccapCASAVideo.botType,
    }),
    shallowEqual
  );

  return {
    data,
    isLoading,
    nptId,
    error,
    qpsk,
    aux,
    docsisip,
    videoip,
    mapping,
    macdomain,
    rpdmac,
    docsishostname,
    videohostname,
    videoCore,
    botType,
  };
}

export function GetPostNodeCertificateResp() {
  const {
    postNodeCertData,
    postNodeCertIsLoading,
    postNodeCertStatus,
    postNodeCertError,
  } = useSelector(
    (state: any) => ({
      postNodeCertData:
        state.NodeState.nodeCertifications.postNodeCertResponse.data,
      postNodeCertIsLoading:
        state.NodeState.nodeCertifications.postNodeCertResponse.isLoading,
      postNodeCertStatus:
        state.NodeState.nodeCertifications.postNodeCertResponse.status,
      postNodeCertError:
        state.NodeState.nodeCertifications.postNodeCertResponse.error,
    }),
    shallowEqual
  );

  return {
    postNodeCertData,
    postNodeCertIsLoading,
    postNodeCertStatus,
    postNodeCertError,
  };
}
export function GetOltListDetails() {
  const {
    isOLTLoading,
    data,
    status,
    error,
    startTime,
    endTime,
  } = useSelector(
    (state: any) => ({
      isOLTLoading: state.GponState.PonOltInfo.isLoading,
      data: state.GponState.PonOltInfo.data,
      status: state.GponState.PonOltInfo.status,
      error: state.GponState.PonOltInfo.error,
      startTime: state.GponState.PonOltInfo.diagStartTime,
      endTime: state.GponState.PonOltInfo.diagEndTime,
    }),
    shallowEqual
  );
  return {
    isOLTLoading,
    data,
    status,
    error,
    startTime,
    endTime,
  };
}

export function GetVccapSWUpgradeInfo() {
  const { data, isLoading, macAddress, error } = useSelector(
    (state: any) => ({
      data: state.AccountState.rpd.vccapSWUpgrade.data,
      isLoading: state.AccountState.rpd.vccapSWUpgrade.isLoading,
      macAddress: state.AccountState.rpd.vccapSWUpgrade.macAddress,
      error: state.AccountState.rpd.vccapSWUpgrade.error,
    }),
    shallowEqual
  );
  return {
    data,
    isLoading,
    macAddress,
    error,
  };
}

export function GetVccapUATPortConfigInfo() {
  const { data, isLoading, macAddress, error } = useSelector(
    (state: any) => ({
      data: state.AccountState.rpd.vccapUATPortConfig.data,
      isLoading: state.AccountState.rpd.vccapUATPortConfig.isLoading,
      macAddress: state.AccountState.rpd.vccapUATPortConfig.macAddress,
      error: state.AccountState.rpd.vccapUATPortConfig.error,
    }),
    shallowEqual
  );
  return {
    data,
    isLoading,
    macAddress,
    error,
  };
}

export function GetVccapRPDConfigInfo() {
  const { data, isLoading, macAddress, error, deviceName } = useSelector(
    (state: any) => ({
      data: state.AccountState.rpd.vccapHarmonicConfig.data,
      isLoading: state.AccountState.rpd.vccapHarmonicConfig.isLoading,
      macAddress: state.AccountState.rpd.vccapHarmonicConfig.macAddress,
      error: state.AccountState.rpd.vccapHarmonicConfig.error,
      deviceName: state.AccountState.rpd.vccapHarmonicConfig.deviceName,
    }),
    shallowEqual
  );
  return {
    data,
    isLoading,
    macAddress,
    error,
    deviceName,
  };
}

export function GetOltNameSearchDetails() {
  const {
    oltIsLoading,
    oltResultData,
    oltName,
    oltStatus,
    oltError,
    isOntCountLoading,
    ontCountData,
    ontCountStatus,
    ontCountError,
    isAborted,
    diagStartTime,
    diagEndTime,
  } = useSelector(
    (state: any) => ({
      oltIsLoading: state.GponState.OltResultDetails.isLoading,
      oltResultData: state.GponState.OltResultDetails.resultList,
      oltName: state.GponState.OltResultDetails.oltName,
      oltStatus: state.GponState.OltResultDetails.status,
      oltError: state.GponState.OltResultDetails.error,
      diagStartTime: state.GponState.OltResultDetails.diagStartTime,
      diagEndTime: state.GponState.OltResultDetails.diagEndTime,

      isOntCountLoading: state.GponState.OltResultDetails.isOntCountLoading,
      ontCountData: state.GponState.OltResultDetails.ontCountData,
      isAborted: state.GponState.OltResultDetails.isAborted,
      ontCountStatus: state.GponState.OltResultDetails.ontCountStatus,
      ontCountError: state.GponState.OltResultDetails.ontCountError,
    }),
    shallowEqual
  );
  return {
    oltResultData,
    oltName,
    oltIsLoading,
    oltStatus,
    oltError,
    isOntCountLoading,
    ontCountData,
    ontCountStatus,
    ontCountError,
    isAborted,
    diagStartTime,
    diagEndTime,
  };
}

export function GetDiagType() {
  const { diagType } = useSelector(
    (state: any) => ({
      diagType: state.slices.topologyState.telemetry?.diagType,
    }),
    shallowEqual
  );

  return {
    diagType,
  };
}

export function GetCMTSNodeDetails() {
  const { cmtsNodeDetailsData, cmtsNodeDetailsLoading, cmtsNodeDetailsStatus } =
    useSelector(
      (state: any) => ({
        cmtsNodeDetailsLoading: state.NodeState.CMTSNodeinfoDetails.isLoading,
        cmtsNodeDetailsData: state.NodeState.CMTSNodeinfoDetails.cmts,
        cmtsNodeDetailsStatus: state.NodeState.CMTSNodeinfoDetails.status,
      }),
      shallowEqual
    );

  return {
    cmtsNodeDetailsData,
    cmtsNodeDetailsLoading,
    cmtsNodeDetailsStatus,
  };
}

export function GetCMTSIPDetails() {
  const { cmtsIPDetailsData, cmtsIPDetailsLoading, cmtsIPDetailsStatus } =
    useSelector(
      (state: any) => ({
        cmtsIPDetailsLoading: state.NodeState.CMTSIPinfoDetails.isLoading,
        cmtsIPDetailsData: state.NodeState.CMTSIPinfoDetails.cmts,
        cmtsIPDetailsStatus: state.NodeState.CMTSIPinfoDetails.status,
      }),
      shallowEqual
    );

  return {
    cmtsIPDetailsLoading,
    cmtsIPDetailsData,
    cmtsIPDetailsStatus,
  };
}

export function GetEmtaTNDetails() {
  const { emtaTNdata, emtaTNLoading, emtaTNStatus, emtaTNError } = useSelector(
    (state: any) => ({
      emtaTNdata: state.AccountState.ihpDetails?.data,
      emtaTNLoading: state.AccountState.ihpDetails?.isLoading,
      emtaTNStatus: state.AccountState.ihpDetails?.status,
      emtaTNError: state.AccountState.ihpDetails?.error,
    }),
    shallowEqual
  );

  return {
    emtaTNdata,
    emtaTNLoading,
    emtaTNStatus,
    emtaTNError,
  };
}

export const GetUtilToolAppConfigProps = () => {
  const { data, isLoading, error, status } = useSelector(
    (state: any) => ({
      data: state.slices.topologyState.utilToolAppData?.data,
      isLoading: state.slices.topologyState.utilToolAppData?.isLoading,
      error: state.slices.topologyState.utilToolAppData?.error,
      status: state.slices.topologyState.utilToolAppData?.status,
    }),
    shallowEqual
  );

  return { data, isLoading, error, status };
};
