import "../../design-tokens/account-page/NeighbourhoodTab.scss";
import SearchIcon from "@material-ui/icons/Search";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { useEffect, useState } from "react";
import CustomPopOver from "../reusable/CustomPopOver";
import { HiViewList } from "react-icons/hi";
import { FaMapMarkedAlt } from "react-icons/fa";
import NeighborhoodTable from "./NeighbourhoodTable";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import NeighbourhoodClient from "../../network/httpclient/neighbourhood/NeighbourhoodClient";
import DiagContainer from "../reusable/DiagContainer";
import LoaderGif from "../../resources/images/loader.webp";
import {
  getAccType,
  getFormatedAddress,
  getHomeAddress,
  macAddress,
} from "../reusable/Util";
import NeighbourhoodMapView from "./NeighbourhoodMapView";
import TapClient from "../../network/httpclient/neighbourhood/TapClient";
import {
  ResetNeighboursDevices,
  SaveNeighboursDevices,
} from "../../store/ducksPattern/account/Neighbourhood";
const NeighborhoodTab = (props: any) => {
  const {
    sortedDevices,
    accData,
    neighboursData,
    neighboursLoading,
    neighboursError,
    neighboursDevicesInfo,
    TapListLoading,
  } = useSelector(
    (state: any) => ({
      sortedDevices: state.AccountState.ActiveAccountDetails.sortedDevices,
      accData: state.AccountState.ActiveAccountDetails.data,
      neighboursData: state.AccountState.acctSlice.neighbourhood.data,
      neighboursLoading: state.AccountState.acctSlice.neighbourhood.isLoading,
      neighboursError: state.AccountState.acctSlice.neighbourhood.error,
      neighboursDevicesInfo:
        state.AccountState.acctSlice.neighbourhood.neighDataList,
      TapListLoading: state.AccountState.TapListData.isLoading,
    }),
    shallowEqual
  );
  let custCM = sortedDevices?.find((d: any) => d.deviceCategory === "CM");
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [errorMSG, setErrorMSG] = useState("");
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [neighView, setNeighView] = useState("tableView");
  const [enableMapView, setEnableMapView] = useState(false);
  const handleSearch = (e: any) => {
    let sv = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
    setSearchValue(sv);
    setErrorDisplay(false);
    setErrorMSG("");
  };
  const refreshNeighNetwork = () => {
    if (custCM !== undefined) {
      dispatch(
        NeighbourhoodClient.getNeighboursInfo(
          custCM.macAddress,
          getAccType(accData?.accountNumber)
        )
      );
      setSearchValue(custCM.macAddress);
    }
  };
  const searchNeighbours = () => {
    let valueFlag = macAddress(searchValue);
    if (searchValue !== undefined && searchValue !== "" && valueFlag === "") {
      setNeighView("tableView");
      /* setEnableMapView(false); */
      dispatch(ResetNeighboursDevices());
      dispatch(
        NeighbourhoodClient.getNeighboursInfo(
          searchValue,
          getAccType(accData?.accountNumber)
        )
      );
    } else {
      setErrorDisplay(true);
      setErrorMSG(valueFlag);
    }
  };
  useEffect(() => {
    if (custCM !== undefined && neighboursData === undefined) {
      dispatch(
        NeighbourhoodClient.getNeighboursInfo(
          custCM?.macAddress,
          getAccType(accData?.accountNumber)
        )
      );
    }
    setSearchValue(custCM?.macAddress);
  }, []);
  useEffect(() => {
    if (neighboursData !== undefined && neighboursData?.length !== 0) {
      let neighboursList = neighboursData?.resultList;
      if (neighboursList !== undefined && neighboursList?.length !== 0) {
        let neighMACList: any = [];
        let neighboursDevices = neighboursList?.map((d: any) => {
          let homeAddress = getHomeAddress(d.servicedAddress);
          neighMACList.push(d.cmMACAddress);
          let nDevice = {
            deviceKey: d.cmSerialNumber,
            homeAddress: homeAddress,
            cmMac: d.cmMACAddress,
            cmModel: d.cmModel,
            cmData: {
              summary: {
                isLoading: false,
                data: undefined,
                status: "",
              },
              docsis: {
                isLoading: false,
                data: undefined,
                status: "",
              },
            },
          };
          return nDevice;
        });
        if (
          neighboursDevices !== undefined &&
          neighboursDevices?.length !== 0 &&
          neighboursDevicesInfo?.length === 0
        ) {
          dispatch(SaveNeighboursDevices(neighboursDevices));
          /*  dispatch(TapClient.getTapsByMacList(neighMACList?.toString())); */
          neighboursList?.map((d: any) => {
            if (d !== undefined && d?.hasOwnProperty("servicedAddress")) {
              let gAddress = getFormatedAddress(d?.servicedAddress);
              dispatch(
                NeighbourhoodClient.getNeighGeoCode(d.cmSerialNumber, gAddress)
              );
            }
          });
        }
      }
    }
  }, [neighboursData]);
  useEffect(() => {
    if (!TapListLoading) {
      setEnableMapView(true);
    } else if (TapListLoading) {
      setEnableMapView(false);
    }
  }, [TapListLoading]);
  return (
    <>
      <div className="areaViewCont">
        <section className="filterCont">
          <InputGroup className="searchNeigh">
            <FormControl
              placeholder="Type MAC ID"
              className="searchInput-Neigh"
              value={searchValue}
              onChange={handleSearch}
            />
            <Button id="searchNeighBtn" onClick={() => searchNeighbours()}>
              <SearchIcon />
            </Button>
          </InputGroup>
          {errorDisplay ? <span className="failState">{errorMSG}</span> : <></>}

          <div className="neighViewCont">
            <CustomPopOver
              position="bottom"
              popOverData="List View"
              originComp={
                <span
                  className={neighView}
                  onClick={() => setNeighView("tableView")}
                >
                  <HiViewList />
                </span>
              }
              index="popOver"
            />
            <CustomPopOver
              position="bottom"
              popOverData="Map View"
              originComp={
                <span
                  className={neighView}
                  onClick={
                    enableMapView ? () => setNeighView("mapView") : () => {}
                  }
                >
                  {enableMapView ? (
                    <FaMapMarkedAlt />
                  ) : (
                    <img
                      style={{ width: "30px", cursor: "not-allowed" }}
                      src={LoaderGif}
                    />
                  )}
                </span>
              }
              index="popOver"
            />
          </div>
        </section>
      </div>
      <div>
        {neighView === "tableView" ? (
          <DiagContainer
            data={neighboursData}
            isLoading={neighboursLoading}
            error={neighboursError}
            sectionName="Neighbourhood"
            handleRefresh={refreshNeighNetwork}
          >
            <NeighborhoodTable />
          </DiagContainer>
        ) : neighView === "mapView" ? (
          <NeighbourhoodMapView />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default NeighborhoodTab;
