import {
  REBOOT_BEGIN,
  REBOOT_SUCCESS,
  REBOOT_FAILURE,
  REBOOT_RESET_STATUS,
  RebootActionTypes,
} from "../../../types/account/deviceActions/Reboot";

export function rebootBegin(): RebootActionTypes {
  return {
    type: REBOOT_BEGIN,
  };
}

export function rebootSuccess(): RebootActionTypes {
  return {
    type: REBOOT_SUCCESS,
  };
}

export function rebootFailure(error: Error): RebootActionTypes {
  return {
    type: REBOOT_FAILURE,
    payload: error,
  };
}
export function resetReboot(): RebootActionTypes {
  return {
    type: REBOOT_RESET_STATUS,
  };
}
