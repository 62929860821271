import AppLabels from "../../constants/App_Labels";
import CmViewData from "../../data/cablemodem/CmViewData";
import DataCard from "../../topology/components/reusable/DataCard";
import SimpleDataTable from "../../topology/components/reusable/SimpleDataTable";
import CertiExceptionCont from "../certification/CertiExceptionCont";

import {
  convertCMData,
  formatCamelCase,
  getChCWValue,
  getMinMaxRange,
  getThrColoredTableCell,
  getValueByStrMapping,
  isNull,
} from "../reusable/Util";
import CmDsChart from "./CmDsChart";

interface Props {
  cmData: any;
  flag?: any;
}

const CmDSTab = ({ cmData, flag }: Props) => {
  const labels = AppLabels();
  let cmDataConfig = CmViewData();
  let dsSummaryConfig = cmDataConfig?.downStream?.summary;
  let dsScQAMConfig = cmDataConfig?.downStream?.scQam;
  let dsScQAMData =
    flag === true
      ? cmData?.docsis30?.downstream
      : cmData?.docsis?.docsis30?.downstream;
  let dsCWData = cmData?.codewords;
  let ofdmConfig = cmDataConfig?.downStream?.ofdm;
  let ofdmData =
    flag === true
      ? cmData?.ofdm?.downstreamChannels
      : cmData?.docsis?.ofdm?.downstreamChannels;
  let dsChartRanges = getMinMaxRange(
    flag === true ? cmData : cmData?.docsis,
    "ds"
  );
  return (
    <div>
      <CertiExceptionCont
        dataObj={flag === true ? cmData : cmData?.docsis}
        sectionName={labels.DOWNSTREAM}
      >
        <DataCard
          cardHeader={labels.SUMMARY}
          cardBody={
            <div className="cmChDetailBody">
              <SimpleDataTable
                headers={dsSummaryConfig?.individual?.headers}
                tBody={Object.keys(dsSummaryConfig?.individual?.dataKeys)?.map(
                  (key: any, j: number) => {
                    let summaryConfig: any =
                      dsSummaryConfig?.individual?.dataKeys;

                    if (summaryConfig[key]?.dataSource === "ofdm") {
                      return ofdmData?.map((ch: any) => {
                        let attValue = getValueByStrMapping(
                          ch,
                          summaryConfig[key]?.dataKey
                        );
                        let computedValue = convertCMData(key, attValue);
                        return (
                          <tr key={j}>
                            <td>
                              {summaryConfig[key]?.label} - {ch?.channelId}
                            </td>
                            {getThrColoredTableCell(computedValue, j)}
                          </tr>
                        );
                      });
                    } else {
                      let attValue = getValueByStrMapping(
                        cmData?.docsis,
                        summaryConfig[key]?.dataKey
                      );

                      let computedValue = convertCMData(key, attValue);
                      return (
                        <tr key={j}>
                          <td>{summaryConfig[key]?.label}</td>
                          {getThrColoredTableCell(computedValue, j)}
                        </tr>
                      );
                    }
                  }
                )}
              />
              <SimpleDataTable
                headers={dsSummaryConfig?.common?.headers}
                tBody={
                  <>
                    {dsSummaryConfig?.common?.dataSet?.scQam?.map(
                      (ch: any, j: number) => {
                        return (
                          <tr key={j}>
                            {Object.keys(ch)?.map((key: string, k: number) => {
                              let attObj =
                                key === "level"
                                  ? ch[key]?.toUpperCase()
                                  : getValueByStrMapping(dsScQAMData, ch[key]);
                              return getThrColoredTableCell(attObj, k);
                            })}
                          </tr>
                        );
                      }
                    )}
                    {ofdmData?.map((ofdmCh: any, j: number) => {
                      let chConfig: any = dsSummaryConfig.common?.dataSet?.ofdm;
                      return (
                        <tr key={j}>
                          {Object.keys(chConfig)?.map((key: any, k: number) => {
                            let attObj =
                              key === "level"
                                ? `${formatCamelCase(chConfig[key])} - ${ofdmCh?.channelId
                                }`
                                : getValueByStrMapping(ofdmCh, chConfig[key]);
                            return getThrColoredTableCell(attObj, k);
                          })}
                        </tr>
                      );
                    })}
                  </>
                }
              />
            </div>
          }
        />
      </CertiExceptionCont>
      <CmDsChart
        cardLabel={labels.chart.toUpperCase()}
        chartRanges={dsChartRanges}
        scQamChs={dsScQAMData?.channels}
        ofdmChs={ofdmData}
      />
      {dsScQAMData && flag === false && (
        <DataCard
          cardHeader={labels.SCQAM}
          cardBody={
            <SimpleDataTable
              headers={dsScQAMConfig?.headers}
              tBody={dsScQAMData?.channels?.map((ch: any, j: number) => {
                return (
                  <tr key={j}>
                    {Object.keys(dsScQAMConfig?.dataKeys?.docsis)?.map(
                      (key: string, k: number) => {
                        let chConfig: any = dsScQAMConfig?.dataKeys?.docsis;
                        let attObj = getValueByStrMapping(ch, chConfig[key]);
                        return getThrColoredTableCell(attObj, k);
                      }
                    )}
                    <td>{dsCWData?.timeDelta}</td>
                    {Object.keys(dsScQAMConfig?.dataKeys?.codeword)?.map(
                      (key: string, k: number) => {
                        let chConfig: any = dsScQAMConfig?.dataKeys?.codeword;
                        let chCwData = getChCWValue(
                          dsCWData?.downstreamChannels,
                          ch?.frequency
                        );
                        let attObj = getValueByStrMapping(
                          chCwData,
                          chConfig[key]
                        );
                        return getThrColoredTableCell(attObj, k);
                      }
                    )}
                  </tr>
                );
              })}
            />
          }
        />
      )}
      {ofdmData && flag === false && (
        <DataCard
          cardHeader={labels.OFDM}
          cardBody={
            <SimpleDataTable
              headers={ofdmConfig?.headers}
              tBody={ofdmData?.map((ch: any, j: number) => {
                let cwConfig: any = ofdmConfig?.dataKeys?.codeword;
                let cwChannel = dsCWData?.ofdmDsChannels?.find((cwCh: any) =>
                  cwCh?.profiles?.[0]?.channelID?.includes(ch?.channelId)
                );
                return (
                  <tr key={j}>
                    <td className="multiLineValue">
                      {ch?.profiles?.map((p: any) => {
                        return (
                          "" + ch?.channelId + "-profile-" + p?.profileId + "\n"
                        );
                      })}
                    </td>
                    {Object.keys(ofdmConfig?.dataKeys?.docsis)?.map(
                      (key: string, k: number) => {
                        let chConfig: any = ofdmConfig?.dataKeys?.docsis;
                        let attObj = getValueByStrMapping(ch, chConfig[key]);
                        return getThrColoredTableCell(attObj, k);
                      }
                    )}
                    <td>{dsCWData?.timeDelta}</td>
                    {Object.keys(ofdmConfig?.dataKeys?.codeword)?.map(
                      (key: string, k: number) => {
                        let attValue = "";
                        cwChannel?.profiles?.map((p: any) => {
                          let attObj = getValueByStrMapping(p, cwConfig[key]);
                          attValue += `${typeof attObj === "object"
                            ? isNull(attObj?.value)
                            : isNull(attObj)
                            }\n`;
                        });
                        return (
                          <td key={k} className="multiLineValue">
                            {attValue}
                          </td>
                        );
                      }
                    )}
                  </tr>
                );
              })}
            />
          }
        />
      )}
    </div>
  );
};

export default CmDSTab;
