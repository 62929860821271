import { shallowEqual, useSelector } from "react-redux";
import appConst from "../../constants/appConst";
import {
  DrawerViewType,
  MapStyleTypes,
  StandardReduxType,
  UniversalSearchTypes,
} from "../../store/reduxTypes";
import { AllNodeDataType } from "../types";
import { IRedline } from "../../store/slices/redlineSlice";
import { ITopoConfig } from "../../store/slices/topologyConfig";
import { IMapPolygons } from "../../store/slices/mapItems/mapPolygons";

export function GetGoogleMaps() {
  const {
    currentViewBDY,
    pressedCoord,
    zoomLevel,
    currentMapCenter,
    mapStyle,
    activeMapStyleName,
  } = useSelector(
    (state: any) => ({
      currentViewBDY: state.slices.topologyState.googleMaps.currentViewBDY,
      pressedCoord: state.slices.topologyState.googleMaps.pressedCoord,
      zoomLevel: state.slices.topologyState.googleMaps.zoomLevel,
      currentMapCenter: state.slices.topologyState.googleMaps.currentMapCenter,
      mapStyle: state.slices.topologyState.googleMaps.mapStyle,
      activeMapStyleName: state.slices.topologyState.googleMaps
        .activeMapStyleName as MapStyleTypes,
    }),
    shallowEqual
  );

  return {
    currentViewBDY,
    pressedCoord,
    zoomLevel,
    currentMapCenter,
    mapStyle,
    activeMapStyleName,
  };
}

export const usePolygonStates = (): IMapPolygons => {
  return useSelector(
    (state: any) => state.slices.topologyState.mapPolygons,
    shallowEqual
  );
};

export const GetMapDetailsProps = () => {
  const {
    mapCenter,
    mapIsLoading,
    highlightedPolygon,
    mapFilters,
    isNodeSiteFocused,
    activeMapElement,
    searchedAddressPin,
    searchedElePin,
    showChart,
    rightClickMapElement,
    showRightClickMenu,
  } = useSelector(
    (state: any) => ({
      mapCenter: state.slices.topologyState.mapDetails.mapCenter,
      mapIsLoading: state.slices.topologyState.mapDetails.mapIsLoading,
      highlightedPolygon:
        state.slices.topologyState.mapDetails.highlightedPolygon,
      mapFilters: state.slices.topologyState.mapDetails.mapFilters,
      isNodeSiteFocused:
        state.slices.topologyState.mapDetails.isNodeSiteFocused,
      activeMapElement: state.slices.topologyState.mapDetails.activeMapElement,
      searchedAddressPin: state.slices.topologyState.mapDetails.searchedAddress,
      searchedElePin: state.slices.topologyState.mapDetails.searchedElement,
      showChart: state.slices.topologyState.mapDetails.showChart,
      rightClickMapElement:
        state.slices.topologyState.mapDetails.rightClickMapElement,
      showRightClickMenu:
        state.slices.topologyState.mapDetails.showRightClickMenu,
    }),
    shallowEqual
  );

  return {
    mapCenter,
    mapIsLoading,
    highlightedPolygon,
    mapFilters,
    isNodeSiteFocused,
    activeMapElement,
    searchedAddressPin,
    searchedElePin,
    showChart,
    rightClickMapElement,
    showRightClickMenu,
  };
};
export const GetNodeSiteDetailsProps = () => {
  const {
    allNodeData,
    activeNodeSiteId,
    activeNodeIndex,
    activeNodePolyType,
    isTopoEleLoading,
    activeNodePolyCords
  } = useSelector(
    (state: any) => ({
      isTopoEleLoading:
        state.slices.topologyState.nodeSiteDetail?.isTopoEleLoading,
      allNodeData: state.slices.topologyState.nodeSiteDetail
        ?.allNodeData ,
      activeNodeSiteId:
        state.slices.topologyState.nodeSiteDetail?.activeNodeSiteId,
      activeNodeIndex:
        state.slices.topologyState.nodeSiteDetail?.activeNodeIndex,
      activeNodePolyType:
        state.slices.topologyState.nodeSiteDetail?.activeNodePolyType,
      activeNodePolyCords:
      state.slices.topologyState.nodeSiteDetail?.activeNodePolyCords,
    }),
    shallowEqual
  );

  const activeNode = allNodeData?.[activeNodeIndex];
  const activeNodeSite = activeNode?.node as {
    data: any;
    isLoading: any;
    status: any;
    error: any;
  };

  return {
    isTopoEleLoading,
    allNodeData,
    activeNodeSiteId,
    activeNodeIndex,
    activeNode,
    activeNodeSite,
    activeNodePolyType,
    activeNodePolyCords
  };
};

export const GetNodeTapAddressProps = () => {
  const { data, isLoading, error, status } = useSelector(
    (state: any) => ({
      data: state.slices.topologyState.nodeTapAddress?.data,
      isLoading: state.slices.topologyState.nodeTapAddress?.isLoading,
      error: state.slices.topologyState.nodeTapAddress?.error,
      status: state.slices.topologyState.nodeTapAddress?.status,
    }),
    shallowEqual
  );

  return { data, isLoading, error, status };
};

export const GetNikaOrderProps = () => {
  const {
    data,
    isLoading,
    status,
    error,
    nikaFilters,
    activeOrderId,
    isNikaMarkersVisible,
  } = useSelector(
    (state: any) => ({
      data: state.slices.topologyState.nikaOrder?.data,
      isLoading: state.slices.topologyState.nikaOrder?.isLoading,
      status: state.slices.topologyState.nikaOrder?.status,
      error: state.slices.topologyState.nikaOrder?.error,
      nikaFilters: state.slices.topologyState.nikaOrder?.nikaFilters,
      activeOrderId: state.slices.topologyState.nikaOrder?.activeOrderId,
      isNikaMarkersVisible:
        state.slices.topologyState.nikaOrder?.isNikaMarkersVisible,
    }),
    shallowEqual
  );

  return {
    data,
    isLoading,
    status,
    error,
    nikaFilters,
    activeOrderId,
    isNikaMarkersVisible,
  };
};

export const GetMapDrawerProps = () => {
  const {
    drawerView,
    commonSearchBarVal,
    drawerTabIndex,
    logicalViewTabIndex,
  } = useSelector(
    (state: any) => ({
      drawerView: state.slices.topologyState.mapDrawer
        ?.drawerView as DrawerViewType,
      commonSearchBarVal:
        state.slices.topologyState.mapDrawer.commonSearchBarVal,
      drawerTabIndex: state.slices.topologyState.mapDrawer.drawerTabIndex,
      logicalViewTabIndex:
        state.slices.topologyState.mapDrawer.logicalViewTabIndex,
    }),
    shallowEqual
  );

  return {
    drawerView,
    commonSearchBarVal,
    drawerTabIndex,
    logicalViewTabIndex,
  };
};

export const GetTelemetryProps = () => {
  const {
    mlData,
    mlIsLoading,
    mlStatus,
    mlError,
    diagStartTime,
    diagEndTime,
    apiCallCounter,
    isDevicesVisible,
    allDiagIsLoading,
    diagType,
    isAborted,
    advancedFilters,
    nodeId,
    allDiagStatus,
    allDiagError,
    viewType,
    modemType,
    isCWEnabled,
    isNeighbourhood,
    advFCond,
    selectedProfile,
  } = useSelector(
    (state: any) => ({
      mlData: state.slices.topologyState.telemetry?.mlData, //ml = modemsList
      mlIsLoading: state.slices.topologyState.telemetry?.mlIsLoading,
      mlStatus: state.slices.topologyState.telemetry?.mlStatus,
      mlError: state.slices.topologyState.telemetry?.mlError,
      diagStartTime: state.slices.topologyState.telemetry?.diagStartTime,
      diagEndTime: state.slices.topologyState.telemetry?.diagEndTime,
      apiCallCounter: state.slices.topologyState.telemetry?.apiCallCounter,
      isDevicesVisible: state.slices.topologyState.telemetry?.isDevicesVisible,
      allDiagIsLoading: state.slices.topologyState.telemetry?.allDiagIsLoading,
      diagType: state.slices.topologyState.telemetry?.diagType, //diagnostics type, eg (all, summary)
      isAborted: state.slices.topologyState.telemetry?.isAborted,
      advancedFilters: state.slices.topologyState.telemetry?.advancedFilters,
      nodeId: state.slices.topologyState.telemetry?.nodeId,
      allDiagStatus: state.slices.topologyState.telemetry?.allDiagStatus,
      allDiagError: state.slices.topologyState.telemetry?.allDiagError,
      viewType: state.slices.topologyState.telemetry?.viewType,
      modemType: state.slices.topologyState.telemetry?.modemType,
      isCWEnabled: state.slices.topologyState.telemetry?.isCWEnabled,
      isNeighbourhood: state.slices.topologyState.telemetry?.isNeighbourhood,
      advFCond: state.slices.topologyState.telemetry?.advFCond,
      selectedProfile: state.slices.topologyState.telemetry?.activeProfile,
    }),
    shallowEqual
  );

  return {
    mlData,
    mlIsLoading,
    mlStatus,
    mlError,
    diagStartTime,
    diagEndTime,
    apiCallCounter,
    isDevicesVisible,
    allDiagIsLoading,
    diagType,
    isAborted,
    advancedFilters,
    nodeId,
    allDiagStatus,
    allDiagError,
    viewType,
    modemType,
    isCWEnabled,
    advFCond,
    isNeighbourhood,
    selectedProfile,
  };
};

export const GetCpatEventsProps = () => {
  const { data, isLoading, status, error, isEventsVisible } = useSelector(
    (state: any) => ({
      data: state.slices.topologyState.cpatEvents?.data,
      isLoading: state.slices.topologyState.cpatEvents?.isLoading,
      status: state.slices.topologyState.cpatEvents?.status,
      error: state.slices.topologyState.cpatEvents?.error,
      isEventsVisible: state.slices.topologyState.cpatEvents?.isEventsVisible,
    }),
    shallowEqual
  );
  return { data, isLoading, status, error, isEventsVisible };
};

export const GetMduPolylinesProps = () => {
  const {
    mduPolylinesdata,
    mduPolylineIsLoading,
    mduPolylineStatus,
    mduPolylineError,
  } = useSelector(
    (state: any) => ({
      mduPolylinesdata:
        state.slices.topologyState.mduElements.mduPolylines?.data,
      mduPolylineIsLoading:
        state.slices.topologyState.mduElements.mduPolylines?.isLoading,
      mduPolylineStatus:
        state.slices.topologyState.mduElements.mduPolylines?.status,
      mduPolylineError:
        state.slices.topologyState.mduElements.mduPolylines?.error,
    }),
    shallowEqual
  );
  return {
    mduPolylinesdata,
    mduPolylineIsLoading,
    mduPolylineStatus,
    mduPolylineError,
  };
};

export const GetMapInfoWindowMenuProps = () => {
  const {
    originCoord,
    destinationCoord,
    totalDistance,
    distancePoints,
    distancePolylines,
  } = useSelector(
    (state: any) => ({
      originCoord: state.slices.topologyState.mapInfoWindowMenu?.originCoord,
      destinationCoord:
        state.slices.topologyState.mapInfoWindowMenu?.destinationCoord,
      totalDistance:
        state.slices.topologyState.mapInfoWindowMenu?.totalDistance,
      distancePoints:
        state.slices.topologyState.mapInfoWindowMenu?.distancePoints,
      distancePolylines:
        state.slices.topologyState.mapInfoWindowMenu?.distancePolylines,
    }),
    shallowEqual
  );

  return {
    originCoord,
    destinationCoord,
    totalDistance,
    distancePoints,
    distancePolylines,
  };
};

export const GetMduActivesProps = () => {
  const {
    mduActivesdata,
    mduActiveIsLoading,
    mduActiveStatus,
    mduActiveError,
  } = useSelector(
    (state: any) => ({
      mduActivesdata: state.slices.topologyState.mduElements.mduActives?.data,
      mduActiveIsLoading:
        state.slices.topologyState.mduElements.mduActives?.isLoading,
      mduActiveStatus:
        state.slices.topologyState.mduElements.mduActives?.status,
      mduActiveError: state.slices.topologyState.mduElements.mduActives?.error,
    }),
    shallowEqual
  );
  return {
    mduActivesdata,
    mduActiveIsLoading,
    mduActiveStatus,
    mduActiveError,
  };
};

export const GetMduFiberEquipmentsProps = () => {
  const {
    mduFiberEquipmentsdata,
    mduFiberEquipmentsIsLoading,
    mduFiberEquipmentsStatus,
    mduFiberEquipmentsError,
  } = useSelector(
    (state: any) => ({
      mduFiberEquipmentsdata:
        state.slices.topologyState.mduElements.mduFiberEquipments?.data,
      mduFiberEquipmentsIsLoading:
        state.slices.topologyState.mduElements.mduFiberEquipments?.isLoading,
      mduFiberEquipmentsStatus:
        state.slices.topologyState.mduElements.mduFiberEquipments?.status,
      mduFiberEquipmentsError:
        state.slices.topologyState.mduElements.mduFiberEquipments?.error,
    }),
    shallowEqual
  );
  return {
    mduFiberEquipmentsdata,
    mduFiberEquipmentsIsLoading,
    mduFiberEquipmentsStatus,
    mduFiberEquipmentsError,
  };
};

export const GetMicroReflectionsGroupedIssuesProps = () => {
  const { data, isLoading, status, error, selectedData, nodeId } = useSelector(
    (state: any) => ({
      data: state.slices.topologyState.mrGroupedIssues?.data,
      isLoading: state.slices.topologyState.mrGroupedIssues?.isLoading,
      status: state.slices.topologyState.mrGroupedIssues?.status,
      error: state.slices.topologyState.mrGroupedIssues?.error,
      selectedData: state.slices.topologyState.mrGroupedIssues?.selectedData,
      nodeId: state.slices.topologyState.mrGroupedIssues?.nodeId,
    }),
    shallowEqual
  );
  return { data, isLoading, status, error, selectedData, nodeId };
};

export const GetSamKeyAddressProps = () => {
  const { data, isLoading, status, error } = useSelector(
    (state: any) => ({
      data: state.slices.topologyState.samkeyAddress?.data,
      isLoading: state.slices.topologyState.samkeyAddress?.isLoading,
      status: state.slices.topologyState.samkeyAddress?.status,
      error: state.slices.topologyState.samkeyAddress?.error,
    }),
    shallowEqual
  );
  return { data, isLoading, status, error };
};

export const GetMduCADTextProps = () => {
  const {
    mduCADTextdata,
    mduCADTextIsLoading,
    mduCADTextStatus,
    mduCADTextError,
  } = useSelector(
    (state: any) => ({
      mduCADTextdata: state.slices.topologyState.mduElements.mduCADText?.data,
      mduCADTextIsLoading:
        state.slices.topologyState.mduElements.mduCADText?.isLoading,
      mduCADTextStatus:
        state.slices.topologyState.mduElements.mduCADText?.status,
      mduCADTextError: state.slices.topologyState.mduElements.mduCADText?.error,
    }),
    shallowEqual
  );
  return {
    mduCADTextdata,
    mduCADTextIsLoading,
    mduCADTextStatus,
    mduCADTextError,
  };
};
export const GetMduPassivesProps = () => {
  const {
    mduPassivesdata,
    mduPassivesIsLoading,
    mduPassivesStatus,
    mduPassivesError,
  } = useSelector(
    (state: any) => ({
      mduPassivesdata: state.slices.topologyState.mduElements.mduPassives?.data,
      mduPassivesIsLoading:
        state.slices.topologyState.mduElements.mduPassives?.isLoading,
      mduPassivesStatus:
        state.slices.topologyState.mduElements.mduPassives?.status,
      mduPassivesError:
        state.slices.topologyState.mduElements.mduPassives?.error,
    }),
    shallowEqual
  );
  return {
    mduPassivesdata,
    mduPassivesIsLoading,
    mduPassivesStatus,
    mduPassivesError,
  };
};

export const GetMduFiberCableProps = () => {
  const {
    mduFiberCabledata,
    mduFiberCableIsLoading,
    mduFiberCableStatus,
    mduFiberCableError,
  } = useSelector(
    (state: any) => ({
      mduFiberCabledata:
        state.slices.topologyState.mduElements?.mduFiberCable?.data,
      mduFiberCableIsLoading:
        state.slices.topologyState.mduElements?.mduFiberCable?.isLoading,
      mduFiberCableStatus:
        state.slices.topologyState.mduElements?.mduFiberCable?.status,
      mduFiberCableError:
        state.slices.topologyState.mduElements?.mduFiberCable?.error,
    }),
    shallowEqual
  );
  return {
    mduFiberCabledata,
    mduFiberCableIsLoading,
    mduFiberCableStatus,
    mduFiberCableError,
  };
};

export const GetMduRFCableProps = () => {
  const {
    mduRFCabledata,
    mduRFCableIsLoading,
    mduRFCableStatus,
    mduRFCableError,
  } = useSelector(
    (state: any) => ({
      mduRFCabledata: state.slices.topologyState.mduElements?.mduRFCable?.data,
      mduRFCableIsLoading:
        state.slices.topologyState.mduElements?.mduRFCable?.isLoading,
      mduRFCableStatus:
        state.slices.topologyState.mduElements?.mduRFCable?.status,
      mduRFCableError:
        state.slices.topologyState.mduElements?.mduRFCable?.error,
    }),
    shallowEqual
  );
  return {
    mduRFCabledata,
    mduRFCableIsLoading,
    mduRFCableStatus,
    mduRFCableError,
  };
};

export const GetMduPowerSupplyProps = () => {
  const {
    mduPowerSupplydata,
    mduPowerSupplyIsLoading,
    mduPowerSupplyStatus,
    mduPowerSupplyError,
  } = useSelector(
    (state: any) => ({
      mduPowerSupplydata:
        state.slices.topologyState.mduElements?.mduPowerSupply?.data,
      mduPowerSupplyIsLoading:
        state.slices.topologyState.mduElements?.mduPowerSupply?.isLoading,
      mduPowerSupplyStatus:
        state.slices.topologyState.mduElements?.mduPowerSupply?.status,
      mduPowerSupplyError:
        state.slices.topologyState.mduElements?.mduPowerSupply?.error,
    }),
    shallowEqual
  );
  return {
    mduPowerSupplydata,
    mduPowerSupplyIsLoading,
    mduPowerSupplyStatus,
    mduPowerSupplyError,
  };
};

export const GetMduTapProps = () => {
  const { mduTapdata, mduTapIsLoading, mduTapStatus, mduTapError } =
    useSelector(
      (state: any) => ({
        mduTapdata: state.slices.topologyState.mduElements?.mduTap?.data,
        mduTapIsLoading:
          state.slices.topologyState.mduElements?.mduTap?.isLoading,
        mduTapStatus: state.slices.topologyState.mduElements?.mduTap?.status,
        mduTapError: state.slices.topologyState.mduElements?.mduTap?.error,
      }),
      shallowEqual
    );
  return { mduTapdata, mduTapIsLoading, mduTapStatus, mduTapError };
};

export const GetAddressSearchProps = () => {
  const { data, isLoading, status, error, searchedAddress } = useSelector(
    (state: any) => ({
      data: state.slices.topologyState?.addressSearch?.data,
      isLoading: state.slices.topologyState?.addressSearch?.isLoading,
      status: state.slices.topologyState?.addressSearch?.status,
      error: state.slices.topologyState?.addressSearch?.error,
      searchedAddress:
        state.slices.topologyState?.addressSearch?.searchedAddress,
    }),
    shallowEqual
  );

  return { data, isLoading, status, error, searchedAddress };
};

export const GetMduAnnotationBlockProps = () => {
  const {
    mduAnnotationBlockdata,
    mduAnnotationBlockIsLoading,
    mduAnnotationBlockStatus,
    mduAnnotationBlockError,
  } = useSelector(
    (state: any) => ({
      mduAnnotationBlockdata:
        state.slices.topologyState.mduElements?.mduAnnotationBlock?.data,
      mduAnnotationBlockIsLoading:
        state.slices.topologyState.mduElements?.mduAnnotationBlock?.isLoading,
      mduAnnotationBlockStatus:
        state.slices.topologyState.mduElements?.mduAnnotationBlock?.status,
      mduAnnotationBlockError:
        state.slices.topologyState.mduElements?.mduAnnotationBlock?.error,
    }),
    shallowEqual
  );
  return {
    mduAnnotationBlockdata,
    mduAnnotationBlockIsLoading,
    mduAnnotationBlockStatus,
    mduAnnotationBlockError,
  };
};

export const GetSymbolsProps = () => {
  const { mduPolylinesdata, pngImages, isTopoEleImgLoading } = useSelector(
    (state: any) => ({
      isTopoEleImgLoading:
        state.slices.topologyState.symbols?.isTopoEleImgLoading,
      mduPolylinesdata: state.slices.topologyState.symbols?.data,
      pngImages: state.slices.topologyState.symbols?.pngImages,
    }),
    shallowEqual
  );
  return { mduPolylinesdata, pngImages, isTopoEleImgLoading };
};

export const GetMduSupportStructureProps = () => {
  const {
    mduSupportStructuredata,
    mduSupportStructureIsLoading,
    mduSupportStructureStatus,
    mduSupportStructureError,
  } = useSelector(
    (state: any) => ({
      mduSupportStructuredata:
        state.slices.topologyState.mduElements?.mduSupportStructure?.data,
      mduSupportStructureIsLoading:
        state.slices.topologyState.mduElements?.mduSupportStructure?.isLoading,
      mduSupportStructureStatus:
        state.slices.topologyState.mduElements?.mduSupportStructure?.status,
      mduSupportStructureError:
        state.slices.topologyState.mduElements?.mduSupportStructure?.error,
    }),
    shallowEqual
  );
  return {
    mduSupportStructuredata,
    mduSupportStructureIsLoading,
    mduSupportStructureStatus,
    mduSupportStructureError,
  };
};

export const GetMduRasterProps = () => {
  const { mduRasterdata, mduRasterIsLoading, mduRasterStatus, mduRasterError } =
    useSelector(
      (state: any) => ({
        mduRasterdata: state.slices.topologyState.mduElements?.mduRaster?.data,
        mduRasterIsLoading:
          state.slices.topologyState.mduElements?.mduRaster?.isLoading,
        mduRasterStatus:
          state.slices.topologyState.mduElements?.mduRaster?.status,
        mduRasterError:
          state.slices.topologyState.mduElements?.mduRaster?.error,
      }),
      shallowEqual
    );
  return { mduRasterdata, mduRasterIsLoading, mduRasterStatus, mduRasterError };
};

export const GetMduRasterImageProps = () => {
  const {
    mduRasterImagedata,
    mduRasterImageIsLoading,
    mduRasterImageStatus,
    mduRasterImageError,
  } = useSelector(
    (state: any) => ({
      mduRasterImagedata:
        state.slices.topologyState.mduElements?.mduRasterImage?.data,
      mduRasterImageIsLoading:
        state.slices.topologyState.mduElements?.mduRasterImage?.isLoading,
      mduRasterImageStatus:
        state.slices.topologyState.mduElements?.mduRasterImage?.status,
      mduRasterImageError:
        state.slices.topologyState.mduElements?.mduRasterImage?.error,
    }),
    shallowEqual
  );
  return {
    mduRasterImagedata,
    mduRasterImageIsLoading,
    mduRasterImageStatus,
    mduRasterImageError,
  };
};

export function GetConfigsProps() {
  const { data, isLoading, status, error, gKey } = useSelector(
    (state: any) => ({
      data: state.slices.topologyState.configs.data,
      gKey: state.slices.topologyState.configs.data?.googleMapKey,
      isLoading: state.slices.topologyState.configs.isLoading,
      status: state.slices.topologyState.configs.status,
      error: state.slices.topologyState.configs.error,
    }),
    shallowEqual
  );

  return { data, isLoading, status, error, gKey };
}

export function GetTopoConfigsProps() {
  const config = useSelector(
    (state: any) =>
      ({
        data: state.slices.topologyState.topologyConfig.data,
        isLoading: state.slices.topologyState.topologyConfig.isLoading,
        status: state.slices.topologyState.topologyConfig.status,
        error: state.slices.topologyState.topologyConfig.error,
      } as ITopoConfig),
    shallowEqual
  );

  return { ...config } as ITopoConfig;
}

export function GetClickOrdersProps() {
  const {
    data,
    isLoading,
    status,
    error,
    clickStatusFlt,
    clickOrderFlt,
    isOrdersVisible,
  } = useSelector(
    (state: any) => ({
      data: state.slices.topologyState.clickOrders.data,
      isLoading: state.slices.topologyState.clickOrders.isLoading,
      status: state.slices.topologyState.clickOrders.status,
      error: state.slices.topologyState.clickOrders.error,
      clickStatusFlt: state.slices.topologyState.clickOrders.clickStatusFlt,
      clickOrderFlt: state.slices.topologyState.clickOrders.clickOrderFlt,
      isOrdersVisible: state.slices.topologyState.clickOrders.isOrdersVisible,
    }),
    shallowEqual
  );

  return {
    data,
    isLoading,
    status,
    error,
    clickStatusFlt,
    clickOrderFlt,
    isOrdersVisible,
  };
}

export const GetRENSearchProps = () => {
  const {
    rensSearchData,
    rensSearchLoading,
    rensSearchStatus,
    rensSearchError,
  } = useSelector(
    (state: any) => ({
      rensSearchData: state.slices.topologyState?.renSearch?.data,
      rensSearchLoading: state.slices.topologyState?.renSearch?.isLoading,
      rensSearchStatus: state.slices.topologyState?.renSearch?.status,
      rensSearchError: state.slices.topologyState?.renSearch?.error,
    }),
    shallowEqual
  );

  return {
    rensSearchData,
    rensSearchLoading,
    rensSearchStatus,
    rensSearchError,
  };
};

export const GetTopoGeneralProps = () => {
  const {
    currNodedata,
    activeMainSearchType,
    ponNode,
    queryPonNode,
    smtSearchFlag,
  } = useSelector(
    (state: any) => ({
      currNodedata: state.NodeState.ActiveNodeinfoDetails.data,
      activeMainSearchType:
        state.slices.topologyState.topoGeneral.activeMainSearchType,
      queryPonNode: state.slices.topologyState.topoGeneral.queryPonNode,
      smtSearchFlag: state.slices.topologyState.topoGeneral.smtSearchFlag,
      ponNode: state.GponState.GponNodeInfo?.data?.ponNode,
    }),
    shallowEqual
  );

  const activeConfigNodeId = currNodedata?.nodeId;
  const isSMT = currNodedata?.smt ? true : false;
  const smtID = currNodedata?.smtId ? currNodedata?.smtId : "";
  const getNodeTypeVals = () => {
    const type = activeMainSearchType?.type as UniversalSearchTypes;
    switch (type) {
      case appConst.DOCSIS_NODE:
        return { type, nodeId: activeConfigNodeId, isSMT, smtID };
      case appConst.GPON_NODE:
        return { type, nodeId: ponNode ? ponNode : queryPonNode };
      default:
        return undefined;
    }
  };

  const activeNodeTypeVals = getNodeTypeVals();

  return {
    activeMainSearchType,
    activeNodeType: activeNodeTypeVals?.type,
    activeNodeId: activeNodeTypeVals?.nodeId,
    isSMTSearch: activeNodeTypeVals?.isSMT,
    smtID: activeNodeTypeVals?.smtID,
    smtSearchFlag,
  };
};

export const GetCommentsProps = () => {
  const {
    eleCommentsData,
    isLoading,
    crudIsLoading,
    crudData,
    crudError,
    crudStatus,
  } = useSelector(
    (state: any) => ({
      eleCommentsData: state.slices.topologyState?.comments?.eleComments,
      isLoading: state.slices.topologyState?.comments?.isLoading,
      crudIsLoading: state.slices.topologyState?.comments?.crudIsLoading,
      crudData: state.slices.topologyState?.comments?.crudData,
      crudError: state.slices.topologyState?.comments?.crudError,
      crudStatus: state.slices.topologyState?.comments?.crudStatus,
    }),
    shallowEqual
  );
  return {
    eleCommentsData,
    isLoading,
    crudIsLoading,
    crudData,
    crudError,
    crudStatus,
  };
};

export const GetMduCADAttributesProps = () => {
  const {
    mduCADAttributeData,
    mduCADAttributeIsLoading,
    mduCADAttributeStatus,
    mduCADAttributeError,
  } = useSelector(
    (state: any) => ({
      mduCADAttributeData:
        state.slices.topologyState.mduElements.mduCADAttribute?.data,
      mduCADAttributeIsLoading:
        state.slices.topologyState.mduElements.mduCADAttribute?.isLoading,
      mduCADAttributeStatus:
        state.slices.topologyState.mduElements.mduCADAttribute?.status,
      mduCADAttributeError:
        state.slices.topologyState.mduElements.mduCADAttribute?.error,
    }),
    shallowEqual
  );
  return {
    mduCADAttributeData,
    mduCADAttributeIsLoading,
    mduCADAttributeStatus,
    mduCADAttributeError,
  };
};

export const GetMduCADCircleProps = () => {
  const {
    mduCADCircleData,
    mduCADCircleIsLoading,
    mduCADCircleStatus,
    mduCADCircleError,
  } = useSelector(
    (state: any) => ({
      mduCADCircleData:
        state.slices.topologyState.mduElements.mduCADCircle?.data,
      mduCADCircleIsLoading:
        state.slices.topologyState.mduElements.mduCADCircle?.isLoading,
      mduCADCircleStatus:
        state.slices.topologyState.mduElements.mduCADCircle?.status,
      mduCADCircleError:
        state.slices.topologyState.mduElements.mduCADCircle?.error,
    }),
    shallowEqual
  );
  return {
    mduCADCircleData,
    mduCADCircleIsLoading,
    mduCADCircleStatus,
    mduCADCircleError,
  };
};

export const GetMduCADSymbolProps = () => {
  const {
    mduCADSymbolData,
    mduCADSymbolIsLoading,
    mduCADSymbolStatus,
    mduCADSymbolError,
  } = useSelector(
    (state: any) => ({
      mduCADSymbolData:
        state.slices.topologyState.mduElements.mduCADSymbol?.data,
      mduCADSymbolIsLoading:
        state.slices.topologyState.mduElements.mduCADSymbol?.isLoading,
      mduCADSymbolStatus:
        state.slices.topologyState.mduElements.mduCADSymbol?.status,
      mduCADSymbolError:
        state.slices.topologyState.mduElements.mduCADSymbol?.error,
    }),
    shallowEqual
  );
  return {
    mduCADSymbolData,
    mduCADSymbolIsLoading,
    mduCADSymbolStatus,
    mduCADSymbolError,
  };
};

export const GetMduCADHatchProps = () => {
  const {
    mduCADHatchData,
    mduCADHatchIsLoading,
    mduCADHatchStatus,
    mduCADHatchError,
  } = useSelector(
    (state: any) => ({
      mduCADHatchData: state.slices.topologyState.mduElements.mduCADHatch?.data,
      mduCADHatchIsLoading:
        state.slices.topologyState.mduElements.mduCADHatch?.isLoading,
      mduCADHatchStatus:
        state.slices.topologyState.mduElements.mduCADHatch?.status,
      mduCADHatchError:
        state.slices.topologyState.mduElements.mduCADHatch?.error,
    }),
    shallowEqual
  );
  return {
    mduCADHatchData,
    mduCADHatchIsLoading,
    mduCADHatchStatus,
    mduCADHatchError,
  };
};

export const GetMemoProps = () => {
  const {
    memoCrudIsLoading,
    memoCrudData,
    memoCrudError,
    memoCrudStatus,
    memoCrudType,
  } = useSelector(
    (state: any) => ({
      memoCrudIsLoading: state.slices.topologyState?.memo?.crudIsLoading,
      memoCrudData: state.slices.topologyState?.memo?.crudData,
      memoCrudError: state.slices.topologyState?.memo?.crudError,
      memoCrudStatus: state.slices.topologyState?.memo?.crudStatus,
      memoCrudType: state.slices.topologyState?.memo?.crudType,
    }),
    shallowEqual
  );
  return {
    memoCrudIsLoading,
    memoCrudData,
    memoCrudError,
    memoCrudStatus,
    memoCrudType,
  };
};

export const GetSettingsProps = () => {
  const { drawerAutoClose } = useSelector(
    (state: any) => ({
      drawerAutoClose: state.slices.topologyState?.settings?.drawerAutoClose,
    }),
    shallowEqual
  );
  return { drawerAutoClose };
};

export const useGponSpliceMapStates = () => {
  const { allData } = useSelector(
    (state: any) => ({
      allData: state.slices.topologyState?.gponSpliceMap
        ?.allData as StandardReduxType[],
    }),
    shallowEqual
  );
  return { allData };
};

export const useGponSplittersStates = () => {
  const { allData } = useSelector(
    (state: any) => ({
      allData: state.slices.topologyState?.gponSplitters
        ?.allData as StandardReduxType[],
    }),
    shallowEqual
  );
  return { allData };
};

export const useGponUsagesStates = () => {
  const { allData } = useSelector(
    (state: any) => ({
      allData: state.slices.topologyState?.gponUsages
        ?.allData as StandardReduxType[],
    }),
    shallowEqual
  );
  return { allData };
};

export const usePowertraceStates = () => {
  const {
    isActive,
    rensNumber,
    rootNodeSiteId,
    psInfo,
    elements,
    elementsStatus,
    elementsIsLoading,
    addresses,
  } = useSelector(
    (state: any) => ({
      isActive: state.slices.topologyState?.powertrace?.isActive,
      rensNumber: state.slices.topologyState?.powertrace?.rensNumber,
      rootNodeSiteId: state.slices.topologyState?.powertrace?.rootNodeSiteId,
      psInfo: state.slices.topologyState?.powertrace?.psInfo,
      elements: state.slices.topologyState?.powertrace?.elements?.data,
      elementsStatus: state.slices.topologyState?.powertrace?.elements?.status,
      elementsIsLoading:
        state.slices.topologyState?.powertrace?.elements?.isLoading,
      addresses: state.slices.topologyState?.powertrace?.addresses?.data,
    }),
    shallowEqual
  );

  return {
    isActive,
    rensNumber,
    rootNodeSiteId,
    psInfo,
    elements,
    elementsStatus,
    elementsIsLoading,
    addresses,
  };
};

export const useOltCardsStates = () => {
  const { data, isLoading, status, error } = useSelector(
    (state: any) => ({
      data: state.slices.topologyState?.oltCards?.data,
      isLoading: state.slices.topologyState?.oltCards?.isLoading,
      status: state.slices.topologyState?.oltCards?.status,
      error: state.slices.topologyState?.oltCards?.error,
    }),
    shallowEqual
  );

  return { data, isLoading, status, error } as StandardReduxType;
};

export const useFetchElementFiles = () => {
  const {
    fetchData,
    isLoading,
    fetchStatus,
    error,
    data,
    status,
    fetchIsLoading,
  } = useSelector(
    (state: any) => ({
      fetchData: state.slices.topologyState?.elementAttachments?.fetchData,
      isLoading: state.slices.topologyState?.elementAttachments?.isLoading,
      fetchStatus: state.slices.topologyState?.elementAttachments?.fetchStatus,
      error: state.slices.topologyState?.elementAttachments?.error,
      data: state.slices.topologyState?.elementAttachments?.data,
      status: state.slices.topologyState?.elementAttachments?.status,
      fetchIsLoading:
        state.slices.topologyState?.elementAttachments?.fetchIsLoading,
    }),
    shallowEqual
  );

  return {
    fetchData,
    isLoading,
    fetchStatus,
    error,
    data,
    status,
    fetchIsLoading,
  };
};

export const useRfDownstreamTraceState = () => {
  const {
    isActive,
    networkId,
    rootNodeSiteId,
    type,
    elementInfo,
    data,
    isLoading,
    status,
    error,
  } = useSelector(
    (state: any) => ({
      isActive: state.slices.topologyState?.rfDownstreamTrace?.isActive,
      networkId: state.slices.topologyState?.rfDownstreamTrace?.networkId,
      rootNodeSiteId:
        state.slices.topologyState?.rfDownstreamTrace?.rootNodeSiteId,
      type: state.slices.topologyState?.rfDownstreamTrace?.type,
      elementInfo: state.slices.topologyState?.rfDownstreamTrace?.elementInfo,
      data: state.slices.topologyState?.rfDownstreamTrace?.data,
      isLoading: state.slices.topologyState?.rfDownstreamTrace?.isLoading,
      status: state.slices.topologyState?.rfDownstreamTrace?.status,
      error: state.slices.topologyState?.rfDownstreamTrace?.error,
    }),
    shallowEqual
  );

  return {
    isActive,
    networkId,
    rootNodeSiteId,
    type,
    elementInfo,
    data,
    isLoading,
    status,
    error,
  };
};

export const useRfUpstreamTraceState = () => {
  const {
    isActive,
    networkId,
    rootNodeSiteId,
    type,
    elementInfo,
    data,
    isLoading,
    status,
    error,
  } = useSelector(
    (state: any) => ({
      isActive: state.slices.topologyState?.rfUpstreamTrace?.isActive,
      networkId: state.slices.topologyState?.rfUpstreamTrace?.networkId,
      rootNodeSiteId:
        state.slices.topologyState?.rfUpstreamTrace?.rootNodeSiteId,
      type: state.slices.topologyState?.rfUpstreamTrace?.type,
      elementInfo: state.slices.topologyState?.rfUpstreamTrace?.elementInfo,
      data: state.slices.topologyState?.rfUpstreamTrace?.data,
      isLoading: state.slices.topologyState?.rfUpstreamTrace?.isLoading,
      status: state.slices.topologyState?.rfUpstreamTrace?.status,
      error: state.slices.topologyState?.rfUpstreamTrace?.error,
    }),
    shallowEqual
  );

  return {
    isActive,
    networkId,
    rootNodeSiteId,
    type,
    elementInfo,
    data,
    isLoading,
    status,
    error,
  };
};

export const GetMduNodeSiteComponentProps = () => {
  const { allMduNSCData } = useSelector(
    (state: any) => ({
      allMduNSCData:
        state.slices.topologyState.mduElements.mduSiteComponents?.allMduNSC,
    }),
    shallowEqual
  );
  return { allMduNSCData };
};

export const GetMduNodeSiteInfoProps = () => {
  const { allMduNSData } = useSelector(
    (state: any) => ({
      allMduNSData:
        state.slices.topologyState.mduElements.mduNodeSiteInfo?.allMduNodeSites,
    }),
    shallowEqual
  );
  return { allMduNSData };
};

export const GetVhubSearchProps = () => {
  const { data, isLoading, status, error } = useSelector(
    (state: any) => ({
      data: state.slices.topologyState?.vhubSearch?.data,
      isLoading: state.slices.topologyState?.vhubSearch?.isLoading,
      status: state.slices.topologyState?.vhubSearch?.status,
      error: state.slices.topologyState?.vhubSearch?.error,
    }),
    shallowEqual
  );

  return { data, isLoading, status, error };
};

export const GetVhubClamshellSearchProps = () => {
  const { data, isLoading, status, error } = useSelector(
    (state: any) => ({
      data: state.slices.topologyState?.vhubClamshellSearch?.data,
      isLoading: state.slices.topologyState?.vhubClamshellSearch?.isLoading,
      status: state.slices.topologyState?.vhubClamshellSearch?.status,
      error: state.slices.topologyState?.vhubClamshellSearch?.error,
    }),
    shallowEqual
  );

  return { data, isLoading, status, error };
};

export const GetLocatorElementData = () => {
  const { locatorEle } = useSelector(
    (state: any) => ({
      locatorEle: state.slices.topologyState?.locatorElements?.elements,
    }),
    shallowEqual
  );

  return { locatorEle };
};

export const GetWirelessSiteSearchProps = () => {
  const {
    wirelessSiteData,
    wirelessSiteLoading,
    wirelessSiteStatus,
    wirelessSiteError,
  } = useSelector(
    (state: any) => ({
      wirelessSiteData: state.slices.topologyState?.wirelessSiteSearch?.data,
      wirelessSiteLoading:
        state.slices.topologyState?.wirelessSiteSearch?.isLoading,
      wirelessSiteStatus:
        state.slices.topologyState?.wirelessSiteSearch?.status,
      wirelessSiteError: state.slices.topologyState?.wirelessSiteSearch?.error,
    }),
    shallowEqual
  );

  return {
    wirelessSiteData,
    wirelessSiteLoading,
    wirelessSiteStatus,
    wirelessSiteError,
  };
};
export const GetSmallCellSearchProps = () => {
  const { smallCellData, smallCellLoading, smallCellStatus, smallCellError } =
    useSelector(
      (state: any) => ({
        smallCellData: state.slices.topologyState?.smallCellSearch?.data,
        smallCellLoading:
          state.slices.topologyState?.smallCellSearch?.isLoading,
        smallCellStatus: state.slices.topologyState?.smallCellSearch?.status,
        smallCellError: state.slices.topologyState?.smallCellSearch?.error,
      }),
      shallowEqual
    );

  return { smallCellData, smallCellLoading, smallCellStatus, smallCellError };
};
export const GetClamshellSearchProps = () => {
  const { clamshellData, clamshellLoading, clamshellStatus, clamshellError } =
    useSelector(
      (state: any) => ({
        clamshellData: state.slices.topologyState?.clamshellSearch?.data,
        clamshellLoading:
          state.slices.topologyState?.clamshellSearch?.isLoading,
        clamshellStatus: state.slices.topologyState?.clamshellSearch?.status,
        clamshellError: state.slices.topologyState?.clamshellSearch?.error,
      }),
      shallowEqual
    );

  return { clamshellData, clamshellLoading, clamshellStatus, clamshellError };
};
export const GetRbCircuitSearchProps = () => {
  const { rbCircuitData, rbCircuitLoading, rbCircuitStatus, rbCircuitError } =
    useSelector(
      (state: any) => ({
        rbCircuitData: state.slices.topologyState?.rbCircuitSearch?.data,
        rbCircuitLoading:
          state.slices.topologyState?.rbCircuitSearch?.isLoading,
        rbCircuitStatus: state.slices.topologyState?.rbCircuitSearch?.status,
        rbCircuitError: state.slices.topologyState?.rbCircuitSearch?.error,
      }),
      shallowEqual
    );

  return { rbCircuitData, rbCircuitLoading, rbCircuitStatus, rbCircuitError };
};

/**returns redline redux states */
export const useRedlineStates = (): IRedline => {
  return useSelector(
    (state: any) => state.slices.topologyState?.redlineSlice,
    shallowEqual
  );
};

export const GetOLTSearchProps = () => {
  const { oltData, oltLoading, oltStatus, oltError } = useSelector(
    (state: any) => ({
      oltData: state.slices.topologyState?.oltSearch?.data,
      oltLoading: state.slices.topologyState?.oltSearch?.isLoading,
      oltStatus: state.slices.topologyState?.oltSearch?.status,
      oltError: state.slices.topologyState?.oltSearch?.error,
    }),
    shallowEqual
  );

  return {
    oltData,
    oltLoading,
    oltStatus,
    oltError,
  };
};

export const useFiberUpstreamTraceState = () => {
  const {
    isActive,
    clickedEleNetworkId,
    networkId,
    rootNodeSiteId,
    type,
    elementInfo,
    showPopup,
    otdrDistance,
    otdrDistanceUnit,
    otdrInfo,
    data,
    isLoading,
    status,
    error,
  } = useSelector(
    (state: any) => ({
      isActive: state.slices.topologyState?.fiberUpstreamTrace?.isActive,
      clickedEleNetworkId:
        state.slices.topologyState?.fiberUpstreamTrace?.clickedEleNetworkId,
      networkId: state.slices.topologyState?.fiberUpstreamTrace?.networkId,
      rootNodeSiteId:
        state.slices.topologyState?.fiberUpstreamTrace?.rootNodeSiteId,
      type: state.slices.topologyState?.fiberUpstreamTrace?.type,
      elementInfo: state.slices.topologyState?.fiberUpstreamTrace?.elementInfo,
      showPopup: state.slices.topologyState?.fiberUpstreamTrace?.showPopup,
      otdrDistance:
        state.slices.topologyState?.fiberUpstreamTrace?.otdrDistance,
      otdrDistanceUnit:
        state.slices.topologyState?.fiberUpstreamTrace?.otdrDistanceUnit,
      otdrInfo: state.slices.topologyState?.fiberUpstreamTrace?.otdrInfo,
      data: state.slices.topologyState?.fiberUpstreamTrace?.data,
      isLoading: state.slices.topologyState?.fiberUpstreamTrace?.isLoading,
      status: state.slices.topologyState?.fiberUpstreamTrace?.status,
      error: state.slices.topologyState?.fiberUpstreamTrace?.error,
    }),
    shallowEqual
  );

  return {
    isActive,
    clickedEleNetworkId,
    networkId,
    rootNodeSiteId,
    type,
    elementInfo,
    showPopup,
    otdrDistance,
    otdrDistanceUnit,
    otdrInfo,
    data,
    isLoading,
    status,
    error,
  };
};
