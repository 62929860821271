import AppLabels from "../../../constants/App_Labels";
import formLabels from "./formLabels";
export default function PreJobInspectionData() {
  const labels = AppLabels() as any;
  const data = {
    initialCheckOriginalData: [
      {
        label: labels.familiarTaskSpecificJob,
        key: formLabels.familiarTaskSpecificJob,
        value: null,
      },
      {
        label: labels.communicationJobPlan,
        key: formLabels.communicationJobPlan,
        value: null,
      },
      {
        label: labels.awarenessApplicableJobProcedure,
        key: formLabels.awarenessApplicableJobProcedure,
        value: null,
      },
      {
        label: labels.employeeTrainingUpToDate,
        key: formLabels.employeeTrainingUpToDate,
        value: null,
      },
      {
        label: labels.experiencedInTask,
        key: formLabels.experiencedInTask,
        value: null,
      },
      {
        label: labels.applicablePPEInspections,
        key: formLabels.applicablePPEInspections,
        value: null,
      },
      {
        label: labels.applicableToolsEquipmentInspections,
        key: formLabels.applicableToolsEquipmentInspections,
        value: null,
      },
    ],
    inspectionAreaInitialData: [
      {
        label: labels.tripSlipFall,
        key: formLabels.tripSlipFall,
        value: null,
      },
      {
        label: labels.overheadHazards,
        key: formLabels.overheadHazards,
        value: null,
      },
      {
        label: labels.interferences,
        key: formLabels.interferences,
        value: null,
      },
      {
        label: labels.electricalHazards,
        key: formLabels.electricalHazards,
        value: null,
      },
      {
        label: labels.radioFrequencyEquipment,
        key: formLabels.radioFrequencyEquipment,
        value: null,
      },
      {
        label: labels.weatherConcerns,
        key: formLabels.weatherConcerns,
        value: null,
      },
      {
        label: labels.publicRoadWays,
        key: formLabels.publicRoadWays,
        value: null,
      },
      {
        label: labels.accessEgress,
        key: formLabels.accessEgress,
        value: null,
      },
      {
        label: labels.Other,
        key: formLabels.otherHazards,
        value: null,
      },
    ],
    ppeInitialData: [
      {
        label: labels.hardHat,
        key: formLabels.hardHat,
        value: false,
      },
      {
        label: labels.cutResistantGloves,
        key: formLabels.cutResistantGloves,
        value: false,
      },
      {
        label: labels.safetyLanyard,
        key: formLabels.safetyLanyard,
        value: false,
      },
      {
        label: labels.safetyFootwear,
        key: formLabels.safetyFootwear,
        value: false,
      },
      {
        label: labels.highVisibilityApparel,
        key: formLabels.highVisibilityApparel,
        value: false,
      },
      {
        label: labels.hearingProtection,
        key: formLabels.hearingProtection,
        value: false,
      },
      {
        label: labels.eyeFaceProtection,
        key: formLabels.eyeFaceProtection,
        value: false,
      },
      {
        label: labels.frRatedClothing,
        key: formLabels.frRatedClothing,
        value: false,
      },
      {
        label: labels.respiratory,
        key: formLabels.respiratory,
        value: false,
      },
      {
        label: labels.classOGloves,
        key: formLabels.classOGloves,
        value: false,
      },
      {
        label: labels.fivePointHarness,
        key: formLabels.fivePointHarness,
        value: false,
      },
      {
        label: labels.rescueDevice,
        key: formLabels.rescueDevice,
        value: false,
      },
    ],
    hazardsCheckboxInitialData: [
      {
        label: labels.WorkingFromHeights,
        key: formLabels.WorkingFromHeights,
        value: false,
      },
      {
        label: labels.LiveElectricity,
        key: formLabels.LiveElectricity,
        value: false,
      },
      {
        label: labels.ConfinedSpace,
        key: formLabels.ConfinedSpace,
        value: false,
      },
    ],
    heightHazardData: [
      { label: labels.rooftop, key: formLabels.rooftop, value: false },
      { label: labels.ladder, key: formLabels.ladder, value: false },
      {
        label: labels.elevatedWorkPlatform,
        key: formLabels.elevatedWorkPlatform,
        value: false,
      },
      {
        label: labels.poleClimbing,
        key: formLabels.poleClimbing,
        value: false,
      },
      {
        label: labels.Other,
        key: formLabels.otherFallProtectionPlan,
        value: false,
      },
    ],
    fallProtectionSystemData: [
      {
        label: labels.travelRestraintSystems,
        key: formLabels.travelRestraintSystems,
        value: false,
      },
      {
        label: labels.workPositioningSystem,
        key: formLabels.workPositioningSystem,
        value: false,
      },
      {
        label: labels.fallArrestSystem,
        key: formLabels.fallArrestSystem,
        value: false,
      },
      {
        label: labels.fallHazardZoneSystem,
        key: formLabels.fallHazardZoneSystem,
        value: false,
      },
    ],
    confinedSpaceData: [
      {
        label: labels.confinedSpaceWork,
        value: false,
        key: formLabels.confinedSpaceWork,
      },
      {
        label: labels.Other,
        value: false,
        key: formLabels.otherConfinedSpaceWork,
      },
    ],
    anchoragePointData: [
      { label: labels.strand, value: false, key: formLabels.strand },
      {
        label: labels.permanentAnchorPoint,
        value: false,
        key: formLabels.permanentAnchorPoint,
      },
      {
        label: labels.temporarySupportAnchorPoint,
        value: false,
        key: formLabels.temporarySupportAnchorPoint,
      },
      {
        label: labels.structuralAnchorPoint,
        value: false,
        key: formLabels.structuralAnchorPoint,
      },
    ],
    liveElectricityData: [
      {
        label: labels.closeProximity,
        value: false,
        key: formLabels.closeProximity,
      },
      {
        label: labels.electricalWork,
        value: false,
        key: formLabels.electricalWork,
      },
      {
        label: labels.exposedHydroLines,
        value: false,
        key: formLabels.exposedHydroLines,
      },
      {
        label: labels.Other,
        value: false,
        key: formLabels.otherLiveElectricity,
      },
    ],
    electricityControlsData: [
      {
        label: labels.electricalGloves,
        value: false,
        key: formLabels.electricalGloves,
      },
      {
        label: labels.equipmentInspectedInGoodCondition,
        value: false,
        key: formLabels.equipmentInspectedInGoodCondition,
      },
      {
        label: labels.distanceRequirements,
        value: false,
        key: formLabels.distanceRequirements,
      },
      {
        label: labels.competentPersons,
        value: false,
        key: formLabels.competentPersons,
      },
      {
        label: labels.pPEInspected,
        value: false,
        key: formLabels.pPEInspected,
      },
      {
        label: labels.lockOutTagOut,
        value: false,
        key: formLabels.lockOutTagOut,
      },
      {
        label: labels.customerNotifiedToContactHydro,
        value: false,
        key: formLabels.customerNotifiedToContactHydro,
      },
      {
        label: labels.drillingNearElectricalSources,
        value: false,
        key: formLabels.drillingNearElectricalSources,
      },
    ],
    confinedControlsData: [
      {
        label: labels.trafficControls,
        value: false,
        key: formLabels.trafficControls,
      },
      {
        label: labels.properManholeGear,
        value: false,
        key: formLabels.properManholeGear,
      },
      {
        label: labels.requiredAdditionalTechSupport,
        value: false,
        key: formLabels.requiredAdditionalTechSupport,
      },
      {
        label: labels.inspectorsNotified,
        value: false,
        key: formLabels.inspectorsNotified,
      },
    ],
    commentInitial: [
      {
        label: labels.siteConcernsComments,
        key: formLabels.siteConcernsComments,
        value: null,
      },
      {
        label: labels.safetyMeasuresComments,
        key: formLabels.safetyMeasuresComments,
        value: null,
      },
      {
        label: labels.safetyEquipmentComments,
        key: formLabels.safetyEquipmentComments,
        value: null,
      },
    ],
  };
  return data;
}
