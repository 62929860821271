import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { BanState } from "../../../store/states/account/accountInfo/ProfileManagement";
import {
  banSearchBegin,
  banSearchFailure,
  banSearchSuccess,
} from "../../../store/actions/account/accountInfo/ProfileManagementActions";
import { LoginFixState } from "../../../store/states/account/accountInfo/ProfileManagement";
import {
  loginFixBegin,
  loginFixFailure,
  loginFixSuccess,
} from "../../../store/actions/account/accountInfo/ProfileManagementActions";
import { LinkBanState } from "../../../store/states/account/accountInfo/ProfileManagement";
import {
  linkBanBegin,
  linkBanFailure,
  linkBanSuccess,
} from "../../../store/actions/account/accountInfo/ProfileManagementActions";
import { UnlinkBanState } from "../../../store/states/account/accountInfo/ProfileManagement";
import {
  unlinkBanBegin,
  unlinkBanFailure,
  unlinkBanSuccess,
} from "../../../store/actions/account/accountInfo/ProfileManagementActions";

export default class ProfileManagementClient {
  static getLoginFix =
    (
      cbpId: String,
      samKey: String
    ): ThunkAction<void, LoginFixState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "profile/reassociate?cbpId=" +
          cbpId +
          "&samKey=" +
          samKey,
      };
      dispatch(loginFixBegin());
      HttpClient.get(httpParameters)
        .then((resp) => {
          dispatch(loginFixSuccess());
          return resp;
        })
        .catch((error) => {
          dispatch(loginFixFailure(error));
          return error;
        });
    };

  static banSearch =
    (ban: String): ThunkAction<void, BanState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "profile/ban/" + ban,
      };
      dispatch(banSearchBegin());

      HttpClient.get<any>(httpParameters)
        .then((resp) => {
          dispatch(banSearchSuccess(resp));
          return resp;
        })
        .catch((error) => {
          dispatch(banSearchFailure(error));
          return error;
        });
    };

  static linkBan =
    (
      guid: String,
      ban: String,
      body: any
    ): ThunkAction<void, LinkBanState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "profile/link?guid=" +
          guid +
          "&ban=" +
          ban,
      };
      dispatch(linkBanBegin());

      HttpClient.post(httpParameters, body)
        .then((resp) => {
          dispatch(linkBanSuccess());
          return resp;
        })
        .catch((error) => {
          dispatch(linkBanFailure(error));
          return error;
        });
    };

  static unlinkBan =
    (
      guid: String,
      alias: String,
      body: any
    ): ThunkAction<void, UnlinkBanState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "profile/unlink?guid=" +
          guid +
          "&alias=" +
          alias,
      };
      dispatch(unlinkBanBegin());

      HttpClient.post(httpParameters, body)
        .then((resp) => {
          dispatch(unlinkBanSuccess());
          return resp;
        })
        .catch((error) => {
          dispatch(unlinkBanFailure(error));
          return error;
        });
    };
}
