import { WirelessSubscriberState } from "../../../../states/account/diagnostics/odu/WirelessSubscriberState";
import {
  WIRELESS_SUBSCRIBER_BEGIN,
  WIRELESS_SUBSCRIBER_FAILURE,
  WIRELESS_SUBSCRIBER_SUCCESS,
  RESET_WIRELESS_SUBSCRIBER,
  WirelessSubscriberActionTypes,
} from "../../../../types/account/diagnostics/odu/WirelessSubscriber";

const initialState: WirelessSubscriberState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function WirelessSubReducer(
  state = initialState,
  action: WirelessSubscriberActionTypes
): WirelessSubscriberState {
  switch (action.type) {
    case WIRELESS_SUBSCRIBER_BEGIN:
      return {
        isLoading: true,
      };
    case WIRELESS_SUBSCRIBER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case WIRELESS_SUBSCRIBER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };
    case RESET_WIRELESS_SUBSCRIBER:
      return {
        ...state,
        data: undefined,
        status: "",
      };
    default:
      return state;
  }
}
