import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { TableRow, TableHead, TableCell } from "@material-ui/core";
import { FaSortDown, FaSortUp, FaSort } from "react-icons/fa";
import {
  checkNullInTelemetry,
  getNodeTeleGloabalStatus,
  getSpecAttributes,
  getSpecAvailFlag,
} from "../../components/reusable/NodeUtil";
import { GetTelemetryDetails } from "../../get-api/GetResponse";
import { GetTelemetryProps } from "../../topology/util/reduxFunctions/getTopologyState";
import ConfigConst from "../../constants/ConfigConst";
import CustomDropDown from "../../components/reusable/CustomDropDown";
import { updateConfigSpecCheckbox } from "../../store/ducksPattern/userPrefConfig";
import { specOptions } from "../../config/STBTelemetryConfig";

interface Props {
  onSearch: any;
  onSpecDetailsChange: any;
  onSorting: any;
  tableAttr: any;
  handleDragStart: any;
  handleDragOver: any;
  handleOnDrop: any;
  handleDragEnter: any;
  dragOver: any;
}

const STBTeleTableHead = ({
  onSearch,
  onSpecDetailsChange,
  onSorting,
  tableAttr,
  handleDragStart,
  handleDragOver,
  handleOnDrop,
  handleDragEnter,
  dragOver,
}: Props) => {
  const dispatch = useDispatch();

  const [searchContent, setSearchContent] = useState(new Map<String, String>());
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");
  const [inputVal, setInputVal] = useState([]);
  const { allDiagStatus, allDiagIsLoading, isAborted } = GetTelemetryProps();
  const nodeDatasetType = GetTelemetryProps().diagType; //added for streaming
  const nodeViewType = GetTelemetryProps().viewType; //added for streaming
  const ducksConfigData = GetTelemetryDetails().ducksConfigData;
  const nodeGlobalStatus = getNodeTeleGloabalStatus(
    allDiagStatus,
    allDiagIsLoading,
    isAborted
  );
  const nodeTeleLoading = allDiagIsLoading;

  //reset search filter on change of view and dataset type
  useEffect(() => {
    if (searchContent.size > 0) {
      setSearchContent(new Map<String, String>());
      onSearch("", "", new Map<String, String>());
      setInputVal((inputVal: any) => {
        inputVal = [];
        return inputVal;
      });
    }
  }, [nodeDatasetType, nodeViewType]);

  const onSortingChange = (field: string) => {
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(field);
    setSortingOrder(order);
    onSorting(field, order);
  };

  const onSearchDataChange = useCallback(
    (value: any, by: string) => {
      if (value?.toString()?.trim().length > 0) {
        setSearchContent(searchContent.set(by, value));
      } else {
        setSearchContent((searchContent: Map<String, String>) => {
          searchContent.delete(by);
          return searchContent;
        });
      }
      onSearch(value, by, searchContent);
    },
    [searchContent, onSearch]
  );

  const resetSearchValue = useCallback((by: string) => {
    onSearch(
      "",
      by,
      searchContent.size > 0 ? searchContent : new Map<String, String>()
    );
    setSearchContent((searchContent: Map<String, String>) => {
      searchContent.delete(by);
      return searchContent;
    });
    setInputVal((inputVal: any) => {
      delete inputVal[by];
      return inputVal;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const displayValue = (by: string) => {
    return searchContent.get(by)?.toString();
  };

  // RESET SEARCH VALUE AFTER THE COLUMN IS HIDDEN
  useEffect(() => {
    if (tableAttr && tableAttr.length > 0) {
      tableAttr.forEach((attr: any) => {
        if (attr?.isVisible === false) {
          resetSearchValue(attr?.jsonDataMapping);
        } else {
          return;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableAttr]);

  const getSelectAttr = (columnMapping: string) => {
    let attributes: any[] = [];
    let specInfo = {};
    attributes = getSpecAttributes(
      ducksConfigData?.userPreferences?.node?.telemetry?.views,
      nodeViewType
    );
    attributes?.map((attr: any) => {
      if (columnMapping === attr?.jsonDataMapping) specInfo = attr?.specInfo;
    });
    return specInfo;
  };

  return (
    <TableHead className="telemetryTabHead">
      <TableRow>
        {React.Children.toArray(
          tableAttr?.map((column: any, index: number, index1: number) => {
            let isSpecAvail =
              getSpecAvailFlag(
                column,
                getSpecAttributes(
                  ducksConfigData?.userPreferences?.node?.telemetry?.views,
                  nodeViewType
                )
              ) &&
              nodeGlobalStatus?.toLowerCase().trim() ===
                ConfigConst.complete.toLowerCase() &&
              !nodeTeleLoading;

            return (
              <TableCell
                id={column?.jsonDataMapping}
                hidden={!column?.isVisible}
                draggable={true}
                className={
                  isSpecAvail
                    ? column?.jsonDataMapping === dragOver
                      ? "specAvailable dragged"
                      : "specAvailable"
                    : column?.jsonDataMapping === dragOver
                    ? "dragged"
                    : ""
                }
                onDragStart={handleDragStart}
                onDragOver={handleDragOver}
                onDrop={handleOnDrop}
                onDragEnter={handleDragEnter}
              >
                <div
                  key={column?.jsonDataMapping}
                  /*  className="emtaTableContainer" */
                >
                  <div
                    className="telmetryHead stbCellHeaderContainer"
                    onClick={() => onSortingChange(column?.jsonDataMapping)}
                    key={index1}
                  >
                    <span key={index1}>
                      {column?.columnName?.toUpperCase()}
                    </span>
                    {sortingField &&
                    sortingField === column?.jsonDataMapping ? (
                      <button className="deviceSortBtn">
                        {sortingOrder === "asc" ? (
                          <FaSortUp color="#000000bf" />
                        ) : (
                          <FaSortDown color="#000000bf" />
                        )}
                      </button>
                    ) : (
                      <button className="deviceSortBtn">
                        <FaSort color="#c4c4c4" />
                      </button>
                    )}
                  </div>
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="text"
                      className="sInput searchTeleInput"
                      placeholder="Search.."
                      value={checkNullInTelemetry(
                        inputVal[column?.jsonDataMapping]
                      )}
                      onChange={(e) => {
                        onSearchDataChange(
                          e.target.value,
                          column?.jsonDataMapping
                        );
                        setInputVal((inputVal: any) => {
                          inputVal[column?.jsonDataMapping] = e.target.value;
                          return inputVal;
                        });
                      }}
                    />
                    {isSpecAvail && (
                      <CustomDropDown
                        columnName={column?.jsonDataMapping}
                        options={specOptions}
                        searchFlag={false}
                        checkboxFlag={true}
                        placement="right"
                        selectAttrVal={getSelectAttr(column?.jsonDataMapping)}
                        handleCheckBox={(o: any) => {
                          dispatch(
                            updateConfigSpecCheckbox({
                              viewVal: nodeViewType,
                              jsonDataMapping: column?.jsonDataMapping,
                              option: o,
                            })
                          );
                        }}
                      />
                    )}
                  </span>
                </div>
              </TableCell>
            );
          })
        )}
      </TableRow>
    </TableHead>
  );
};

export default STBTeleTableHead;
