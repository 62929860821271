import { useTranslation } from "react-i18next";

export default function AppLabels() {
  const { t, i18n } = useTranslation();
  return Object.freeze({
    CONTACT_NUM: t("Contact#"),
    SENIOR_TECHNICIAN_EMAIL: t("SeniorTechnicianEmail"),
    TECHNICIAN_MANAGER_EMAIL: t("Technician'sManagerEmail"),
    TECHNICIAN_EMAIL: t("TechnicianEmail"),
    SrEmailMSG: t("SREmail"),
    Senior_Tech_ID: t("SeniorTechID"),
    SR_EMAIL_INTRO: t("Senior Email Intro"),
  });
}
