import { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { useSelector, shallowEqual } from "react-redux";
import BrowseFiles from "../BrowseFiles";
import AttachedFiles from "../AttachedFiles";
import Dropdown from "../../reusable/Dropdown";
import AppLabels from "../../../constants/App_Labels";
import DiagContainer from "../../reusable/DiagContainer";

import { GetTechInfo } from "../../../get-api/GetResponse";
import { getAccessLevelByRoles } from "../../reusable/Util";
import ConfigConst from "../../../constants/ConfigConst";
import SearchDropDown from "../SearchDropDown";

const EditForm = (props: any) => {
  const labels = AppLabels();
  const optionsType = ["Bug", "Suggestion", "Appreciation"];
  let nfbHead = props.oldFbHead.replace(props.fbType, "");
  nfbHead = nfbHead.replace(" - ", "");

  const roles: Array<String> = GetTechInfo()?.roles;
  const [roleData, setRoleData] = useState({ name: "all", check: false });
  const capitalize = (s: String) =>
    s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

  const feedbackSectionMap = new Map();
  feedbackSectionMap.set(capitalize(labels.HOME), "73936");
  feedbackSectionMap.set(capitalize(labels.ACCOUNT), "73937");
  feedbackSectionMap.set(capitalize(labels.NODE), "73938");
  feedbackSectionMap.set(capitalize(labels.Topology), "73939");
  feedbackSectionMap.set(capitalize(labels.History), "73940");

  const feedbackRole = new Map();
  feedbackRole.set(labels.serviceTech, "73934");
  feedbackRole.set(labels.maintenanceTech, "73935");

  const [fbRole, setFbRole] = useState(labels.serviceTech);

  const [fbSection, setFbSection] = useState(capitalize(labels.HOME));
  const onFeedbackSection = (e: any) => {
    setFbSection(e.target.value);
    props.setNewFbSection(feedbackSectionMap.get(capitalize(e.target.value)));
  };
  const onFeedbackRole = (e: any) => {
    setFbRole(e.target.value);
    props.setNewFbRole(feedbackRole.get(e.target.value));
  };

  const validRoleCheck = () => {
    let roleValue = { name: "all", check: false };

    //if (roles.includes("maintenance_tech") && !roles.includes("technician")) {
    if (
      getAccessLevelByRoles(roles).includes(ConfigConst.MT) &&
      !getAccessLevelByRoles(roles).includes(ConfigConst.ST)
    ) {
      roleValue = { name: labels.maintenanceTech, check: true };
    }
    // if (roles.includes("technician") && !roles.includes("maintenance_tech")) {
    if (
      getAccessLevelByRoles(roles).includes(ConfigConst.ST) &&
      !getAccessLevelByRoles(roles).includes(ConfigConst.MT)
    ) {
      roleValue = { name: labels.serviceTech, check: true };
    }
    setRoleData(roleValue);
  };

  useEffect(() => {
    validRoleCheck();
  }, [roles]);

  useEffect(() => {
    props.setNewDescription(props.oldDescription);
    props.setNewFbHead(nfbHead);
  }, []);

  const { issueData, isLoading, error, attachLoading,woList } = useSelector(
    (state: any) => ({
      issueData: state.IndividualIssueInfo.data,
      isLoading: state.IndividualIssueInfo.isLoading,
      error: state.IndividualIssueInfo.error,
      attachLoading: state.AttachmentInfo.isLoading,
      woList:state.workOrdersList.data,
    }),
    shallowEqual
  );

  const [accountNumberOptions,setAccountNumberOptions] = useState([]);
  const [workOrderOptions,setWorkOrderOptions] = useState([]);

  useEffect(() => {
    const workOrderOptions = woList?.map((wo: any) => wo.id);
    setWorkOrderOptions(workOrderOptions)

    const accountNumberOptions = woList?.map((wo: any) => wo.accountNumber);
    setAccountNumberOptions(accountNumberOptions)

    props.setNewAccountNumber(issueData?.fields?.customfield_47417)
    props.setNewWorkOrderNumber(issueData?.fields?.customfield_47418)
    setFbRole(issueData?.fields?.customfield_29819?.value);
    setFbSection(issueData?.fields?.customfield_45006?.value);
    props.setNewFbRole(
      feedbackRole.get(issueData?.fields?.customfield_29819?.value)
    );
    props.setNewFbSection(
      feedbackSectionMap.get(issueData?.fields?.customfield_45006?.value)
    );
  }, [issueData]);

  const AlreadyAttached = () => {
    if (issueData !== undefined) {
      return issueData?.fields?.attachment?.map((attachment: any) => (
        <div key={attachment.filename} className="attachment">
          <a
            href={attachment.content}
            download={attachment.filename}
            target="_blank"
            className="attached-file"
          >
            {attachment.filename}
          </a>
        </div>
      ));
    }
  };

  return (
    <Modal.Body bsPrefix="modal-body">
      <Button bsPrefix="back-button" onClick={props.onClose}>
        {labels.BACK}
      </Button>
      <DiagContainer
        isLoading={isLoading}
        data={issueData}
        error={error}
        sectionName="Attachments"
      >
        <Form className="form">
          <div className="feedBackBugContainer">
            {roleData?.check ? (
              <div>
                <div className="form-label">{labels.ROLE}</div>
                <div style={{ fontSize: "12px" }}>{roleData?.name}</div>
              </div>
            ) : (
              <Dropdown
                disabled={false}
                onChange={onFeedbackRole}
                value={fbRole}
                title={labels.ROLE}
                options={Array.from(feedbackRole.keys())}
              />
            )}
            <Dropdown
              disabled={true}
              value={
                props.fbType === "Testimonial" ? "Appreciation" : props.fbType
              }
              options={optionsType}
              title="Feedback Type"
            />
            <Dropdown
              disabled={false}
              onChange={onFeedbackSection}
              value={fbSection}
              title={labels.FEEDBACK_SECTION}
              options={Array.from(feedbackSectionMap.keys())}
            />
          </div>
          <div style={{display:"flex", flexDirection:"row",justifyContent:"space-between"}}>
          <SearchDropDown 
              options={accountNumberOptions} 
              selectedOption={props.newAccountNumber}
              setSelectedOption={props.setNewAccountNumber} 
              placeholder="Account #" 
              label="Account #"/>

            <SearchDropDown 
              options={workOrderOptions} 
              selectedOption={props.newWorkOrderNumber}
              setSelectedOption={props.setNewWorkOrderNumber} 
              placeholder="Work Order #" 
              label="Work Order #"/>

         </div>
          <Form.Group
            style={{
              display: props.fbType === "Appreciation" ? "none" : "flex",
              flexDirection: "column",
            }}
          >
            <Form.Label>{labels.FEEDBACK_SUBTYPE}</Form.Label>
            <Form.Control
              name="screen"
              onChange={(e) => props.setNewFbHead(e.target.value)}
              type="text"
              className="description"
              id="description"
              value={props.newFbHead || ""}
              maxLength={50}
              placeholder="Enter impacted feature..."
            />
          </Form.Group>
          <Form.Group className="selector">
            <Form.Label>{labels.DESCRIPTION}</Form.Label>
            <Form.Control
              name="description"
              onChange={props.onDescSubmit}
              as="textarea"
              rows={3}
              className="description"
              id="description"
              maxLength={1000}
              value={props.newDescription || ""}
            />
          </Form.Group>
          <Form.Group className="attachment-group">
            <Form.Label>{labels.ATTACH}</Form.Label>
            <BrowseFiles setFile={props.setFile} file={props.file} />

            <div className="attached-file-container">
              <AlreadyAttached />
            </div>

            <AttachedFiles
              file={props.file}
              setFile={props.setFile}
              screenshot={props.screenshot}
            />
          </Form.Group>

          <div className="submission">
            <Button
              className="submit"
              type="submit"
              onClick={props.handleSubmit}
              disabled={isLoading || attachLoading ? true : false}
            >
              {labels.SAVE}
            </Button>
            <Button bsPrefix="reset" onClick={props.onClose}>
              {labels.CANCEL}
            </Button>
          </div>
        </Form>
      </DiagContainer>
      <hr />
    </Modal.Body>
  );
};

export default EditForm;
