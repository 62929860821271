import { createSlice } from "@reduxjs/toolkit";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "nptSummary",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
    nptId: "",
    mails: undefined,
  },
  reducers: {
    nptSummaryBegin: (rpd) => {
      rpd.isLoading = true;
    },
    nptSummarySuccess: (rpd, action) => {
      rpd.isLoading = false;
      rpd.data = action.payload;
      rpd.error = undefined;
      rpd.status = storeConst.OK;
    },
    nptSummaryFailure: (rpd, action) => {
      rpd.isLoading = false;
      rpd.error = action.payload;
      rpd.data = undefined;
      rpd.status = storeConst.ERROR;
    },
    setNPTId: (rpd, action) => {
      rpd.nptId = action.payload;
    },
    nptSummaryReset: (rpd) => {
      rpd.isLoading = false;
      rpd.error = undefined;
      rpd.data = undefined;
      rpd.status = "";
      rpd.nptId = "";
    },
    setMail: (rpd, action) => {
      rpd.mails = action.payload;
    },
  },
});

export const {
  nptSummaryBegin,
  nptSummarySuccess,
  nptSummaryFailure,
  setNPTId,
  nptSummaryReset,
  setMail,
} = slice.actions;
export default slice.reducer;
