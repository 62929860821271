import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import InlineErrorMessage from "../../../../components/reusable/InlineErrorMessage";
import TopologyLabels from "../../../constants/topologyLabels";

interface props {
  setAddCommentModal: any;
  onAddCommentClick: any;
}

const AddCommentsModal = ({ setAddCommentModal, onAddCommentClick }: props) => {
  const labels = TopologyLabels();
  const [comment, setComment] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const handleAddClick = () => {
    if (comment.length > 0) {
      onAddCommentClick(comment);
    } else {
      setErrorMsg(labels.pleaseAddComment);
    }
  };
  return (
    <>
      <Modal
        style={{ position: "absolute" }}
        show={true}
        size="lg"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header className="searchPopHead">
          {labels.add} {labels.comment}
          <Button bsPrefix="close" onClick={() => setAddCommentModal(false)}>
            &#10005;
          </Button>
        </Modal.Header>
        <Modal.Body>
          {errorMsg !== "" && (
            <div className="addCommentErrorMsg">
              <InlineErrorMessage errorMessage={errorMsg} />
            </div>
          )}

          <div className="popUpCont">
            <div className="selectDrawing">
              <Form.Group className="selector">
                <Form.Control
                  name="description"
                  onChange={(e) => setComment(e.target.value)}
                  as="textarea"
                  rows={5}
                  className="description"
                  id="description"
                  maxLength={1000}
                  placeholder={labels.typeComment}
                  value={comment}
                />
              </Form.Group>
            </div>
            <div className="popUpSubmitCancelButton">
              <button
                className="popUpCancelButton"
                style={{ marginRight: "20px" }}
                onClick={() => {
                  setAddCommentModal(false);
                }}
              >
                {labels.cancel}
              </button>
              <button className="popUpSubmitButton" onClick={handleAddClick}>
                {labels.add}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AddCommentsModal;
