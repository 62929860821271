import { Button, Form, Modal } from "react-bootstrap";
import TopologyLabels from "../../../constants/topologyLabels";
interface props {
  filteredData: any;
  setDeleteModal: any;
  onDeleteClick: any;
}

const DeleteCommentsModal = ({
  filteredData,
  setDeleteModal,
  onDeleteClick,
}: props) => {
  const labels = TopologyLabels();
  return (
    <>
      <Modal
        style={{ position: "absolute" }}
        show={true}
        size="lg"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header className="searchPopHead">
          {labels.delete} {labels.comment}
          <Button bsPrefix="close" onClick={() => setDeleteModal(false)}>
            &#10005;
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="popUpCont">
            <div
              style={{
                textAlign: "center",
                fontWeight: 600,
                marginBottom: "9px",
              }}
            >
              {labels.deleteCommentQuestion}
            </div>
            <div className="popUpSubmitCancelButton">
              <button
                className="popUpCancelButton"
                style={{ marginRight: "20px" }}
                onClick={() => {
                  setDeleteModal(false);
                }}
              >
                {labels.cancel}
              </button>
              <button
                className="popUpSubmitButton"
                onClick={() => {
                  onDeleteClick(filteredData);
                }}
              >
                {labels.delete}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default DeleteCommentsModal;
