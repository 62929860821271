import TopologyLabels from "../../../constants/topologyLabels";

interface Props {
  type: boolean;
  setType: any;
  code: boolean;
  setCode: any;
  event: boolean;
  setEvent: any;
}

export default function RenderCPATRadioButtons(props: Props) {
  const labels = TopologyLabels();
  return (
    <>
      <div
        className="radioBtnContainer"
        onClick={() => {
          props.setType(true);
          props.setCode(false);
          props.setEvent(false);
        }}
      >
        <input type="radio" checked={props.type === true} readOnly />
        <label
          style={
            props.type === true
              ? { color: `var(--primary)`, fontWeight: 600 }
              : { color: `var(--pureBlack)` }
          }
        >
          {labels.type}
        </label>
      </div>
      <div
        className="radioBtnContainer"
        onClick={() => {
          props.setType(false);
          props.setCode(true);
          props.setEvent(false);
        }}
      >
        <input type="radio" checked={props.code === true} readOnly />
        <label
          style={
            props.code === true
              ? { color: `var(--primary)`, fontWeight: 600 }
              : { color: `var(--pureBlack)` }
          }
        >
          {labels.code}
        </label>
      </div>
      <div
        className="radioBtnContainer"
        onClick={() => {
          props.setType(false);
          props.setCode(false);
          props.setEvent(true);
        }}
      >
        <input type="radio" checked={props.event === true} readOnly />
        <label
          style={
            props.event === true
              ? { color: `var(--primary)`, fontWeight: 600 }
              : { color: `var(--pureBlack)` }
          }
        >
          {labels.event}
        </label>
      </div>
    </>
  );
}
