import { useEffect, useRef, useState } from "react";
import { Search } from "react-bootstrap-icons";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import { useDispatch } from "react-redux";
import {
  multiDeviceFilterConfig,
  singleDeviceSearchConfig,
  usemultiSearchConfig,
} from "../../config/SearchConfig";
import AppLabels from "../../constants/App_Labels";
import { GetAccountDetails, GetTechInfo } from "../../get-api/GetResponse";

import ConfigConst from "../../constants/ConfigConst";
import { useMultiDevicesSearch } from "../../hooks/reusableHooks";
import { NetworkConfig } from "../../network/httpclient/NetworkConfig";
import { AcDetailsClient } from "../../network/httpclient/accountInfo/AcDetailsClient";
import AccDetailsClient from "../../network/httpclient/accountInfo/AccDetailsClient";
import { useStbDetailsClient } from "../../network/httpclient/accountInfo/StbDetailsClient";
import { useTNDetailsClient } from "../../network/httpclient/accountInfo/TelephoneDetailsClient";
import NodeInfoDetailsClient from "../../network/httpclient/nodeinfo/NodeInfoDetailsClient";
import {
  SetActiveSubTabIndex,
  SetDeviceMacAddressSearchValue,
  SetDeviceSearchValue,
} from "../../store/actions/account/accountInfo/ActiveAccountAction";
import { SetNodeSearch } from "../../store/actions/node/nodeinfo/NodeInfoDetailsAction";
import AddressSearch from "../../topology/components/drawer/search/AddressSearch";
import MDS from "../../topology/components/drawer/search/MDS";
import CustomErrorBox from "../reusable/CustomErrorBox";
import CustomPopUp from "../reusable/CustomPopUp";
import InputHistoryBox from "../reusable/InputHistoryBox";
import { getTechRoles } from "../reusable/NodeUtil";
import {
  getSearchType,
  getSearchTypeName,
  isNumeric,
  isStringNumber,
  openNewWindow,
} from "../reusable/Util";

interface Props {
  onHide?: any;
  searchType: any;
  setSearchType: any;
  setCurrSearchValue: any;
}

const MultiDeviceSearch = ({
  searchType,
  setSearchType,
  setCurrSearchValue,
  onHide,
}: Props) => {
  const labels = AppLabels();
  const dispatch = useDispatch();
  const wrapperRef: any = useRef([]);
  const techData = GetTechInfo()?.data;
  const [searchPingValue, setSearchPingValue] = useState("10");
  const [searchIPValue, setSearchIPValue] = useState("");
  const [activeTab, setActiveTab] = useState<"search" | "ping">("search");
  const tnInfoApi = useTNDetailsClient();
  const stbInfo = useStbDetailsClient();
  const accInfo = AcDetailsClient();
  const [selRouteType, setSelRouteType] = useState("IP Ping");
  const [selAccType, setSelAccType] = useState("docsis");
  const [selAreaType, setSelAreaType] = useState("");
  const [selDeviceType, setSelDeviceType] = useState("");
  const [selDeviceTypes, setSelDeviceTypes] = useState<any>([]);
  const [selDiagType, setSelDiagType] = useState("");
  const [selDataSetOptions, setSelDataSetOptions] = useState("");
  const [activePH, setActivePH] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [currSearchType, setCurrSearchType] = useState("");
  const [errorBoxFlag, setErrorBoxFlag] = useState(false);
  const [errorBoxBody, setErrorBoxBody] = useState(<div></div>);
  const [errorAccFlag, setErrorAccBoxFlag] = useState(false);
  const [errorAccBoxBody, setErrorACCBoxBody] = useState(<div></div>);
  const [showAddressSearch, setShowAddressSearch] = useState(false);
  const [showMDSSearch, setShowMDSSearch] = useState(false);
  const [historyDisplay, setHistoryDisplay] = useState(false);
  const { status, error, isLoading } = GetAccountDetails();
  const multiDeviceSearchConfig = multiDeviceFilterConfig();

  const handleSearch = (e: any) => {
    const regExpre = /[^a-zA-Z0-9]/g;
    const ipExpre = /[^a-zA-Z0-9.]/g;
    let sv = e.target.value.replace(
      selAreaType === "cmts" || selAreaType === "olt" ? ipExpre : regExpre,
      ""
    );
    setSearchValue(sv);
    setCurrSearchValue(sv);
  };
  const handleChipClick = (chipName: string) => {
    if (chipName === "Address") {
      setShowAddressSearch(true);
    }
    if (chipName === "mds") {
      setShowMDSSearch(true);
    }
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleNetworkSearch();
    }
  };
  const handlePingSearch = (e: any) => {
    setSearchPingValue(e.target.value);
  };
  const handleIPSearch = (e: any) => {
    setSearchIPValue(e.target.value);
  };
  const handleClickOutside = (event: MouseEvent) => {
    const { current: wrap } = wrapperRef;
    if (wrap && wrap?.length !== 0 && !wrap?.contains(event.target)) {
      setHistoryDisplay(false);
    }
  };
  const handlePingPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleNetworkIPSearch();
    }
  };
  const displayInputError = (errorMsg: any) => {
    setErrorBoxBody(errorMsg);
    setErrorBoxFlag(true);
  };

  const handleUpdateDeviceType = (deviceType: string) => {
    if (selDeviceTypes?.indexOf(deviceType) === -1) {
      let tempDeviceTypes = selDeviceTypes.concat(deviceType);
      setSelDeviceTypes(tempDeviceTypes);
    } else {
      let tempDeviceTypes = selDeviceTypes.filter(
        (dt: string) => deviceType !== dt
      );
      setSelDeviceTypes(tempDeviceTypes);
    }
  };
  const handleNetworkIPSearch = async () => {
    switch (selRouteType) {
      case "IP Ping":
        let url = `/#/ping/ipAddress?ipAddress=${searchIPValue}&numberOfPing=${searchPingValue}`;
        let newWindow = window.open(url, "_blank")!;
        newWindow.focus();
        // dispatch(getStreamIpData(searchIPValue, searchPingValue));
        break;
      case "TraceRoute":
        let routeUrl = `/#/traceRoute?ipAddress=${searchIPValue}`;
        let windowRoute = window.open(routeUrl, "_blank")!;
        windowRoute.focus();
        // dispatch(getStreamTraceRouteData(searchIPValue));
        break;
    }
  };
  const handleNetworkSearch = async () => {
    dispatch(SetNodeSearch(selAreaType));
    switch (selAreaType) {
      case ConfigConst.node:
      case ConfigConst.gnode:
        let sType = getSearchType(searchValue);
        if (sType === ConfigConst.shub) {
          setSearchType(getSearchTypeName(sType));
          if (selDeviceTypes && selDeviceTypes?.length > 0) {
            if (onHide) onHide();
            setCurrSearchType(selAreaType);
          } else {
            displayInputError(<li>{labels.modemTypeErr}</li>);
          }
        } else {
          displayInputError(<li>{labels.nodeIdErr}</li>);
        }
        break;
      case ConfigConst.olt:
        if (searchValue && searchValue !== "" && selDeviceTypes?.length === 0) {
          setSearchType(ConfigConst.olt.toUpperCase());
          setCurrSearchType(selAreaType);
        } else {
          displayInputError(<li>{labels.oltNameErr}</li>);
        }
        break;
      case ConfigConst.household:
        let deviceType =
          selDeviceType === ConfigConst.ont
            ? ConfigConst.ontSN
            : getSearchType(searchValue);
        let sTypeName = getSearchTypeName(deviceType);
        if (sTypeName) setSearchType(sTypeName);
        let seleDeviceConfig: any = singleDeviceSearchConfig[selDeviceType];

        if (deviceType !== ConfigConst.invalidEntry) {
          if (
            selDeviceType !== ConfigConst.ont &&
            seleDeviceConfig?.allowedSearch?.indexOf(deviceType) > -1
          ) {
            if (
              selDeviceType === ConfigConst.emta &&
              deviceType === ConfigConst.tn
            ) {
              const apiResponse = await tnInfoApi.getIHPNumberInfo(searchValue);
              if (!apiResponse?.errorCode) {
                setSearchType(getSearchTypeName("macAddress"));
                setCurrSearchValue(apiResponse?.cmmac);
                dispatch(
                  AccDetailsClient.getAccDetails(
                    apiResponse?.cmmac,
                    "macAddress"
                  )
                );
              }
            } else if (
              (selDeviceType === ConfigConst.ecm &&
                deviceType === ConfigConst.tn) ||
              (selDeviceType === ConfigConst.ecm &&
                deviceType === ConfigConst.shub)
            ) {
              displayInputError(
                <li>
                  {selDeviceType?.toUpperCase()} can not be searched by{" "}
                  {sTypeName}
                </li>
              );
            } else {
              setSearchType(sTypeName);

              if (selDeviceType === ConfigConst.stb) {
                const response = await stbInfo.getStbAccountDetails(
                  searchValue
                );

                // response.accountNum?.toUpperCase(),
                // "accountNumber"

                if (response?.errorCode) {
                  dispatch(
                    AccDetailsClient.getAccDetails(
                      searchValue?.toUpperCase(),
                      //response?.accountNum,
                      deviceType
                    )
                  );
                } else if (response) {
                  let searchMacAddress = String(response?.stbMac);
                  dispatch(SetDeviceMacAddressSearchValue(searchMacAddress));
                  dispatch(
                    AccDetailsClient.getAccDetails(
                      response?.companyNum + response?.accountNum,
                      "accountNumber"
                    )
                  );
                }
              } else {
                if (searchValue?.length > 9) {
                  //searchs for MAC first the account
                  const result = await accInfo.getAccInfo(
                    searchValue?.toUpperCase(),
                    "macAddress"
                  );
                  if (result?.errorCode) {
                    dispatch(AccDetailsClient.getAccDetails(
                      searchValue?.toUpperCase(),
                      deviceType
                    ));
                  }
                } else {
                  dispatch(
                    AccDetailsClient.getAccDetails(
                      searchValue?.toUpperCase(),
                      deviceType
                    )
                  );
                }
              }
            }
          } else if (selDeviceType === ConfigConst.ont) {
            if (searchValue?.length === 12 && !isStringNumber(searchValue)) {
              setSearchType(sTypeName);
              dispatch(
                AccDetailsClient.getAccDetails(
                  searchValue?.toUpperCase(),
                  deviceType
                )
              );
            } else {
              displayInputError(<li>{labels.ontSearchErr}</li>);
            }
          } else {
            displayInputError(
              <li>
                {selDeviceType?.toUpperCase()} can not be searched by{" "}
                {sTypeName}.
              </li>
            );
          }
          if (getTechRoles(techData)) {
            dispatch(SetActiveSubTabIndex(1));
          }
        } else {
          displayInputError(
            <>
              <li>{labels.accNumSearchErr}</li>
              <li>{labels.cbpSearchErr}</li>
              <li>{labels.macSearchErr}</li>
              <li>{labels.tnSearchErr}</li>
              <li>{labels.ipSearchErr}</li>
            </>
          );
        }
        break;
      case ConfigConst.neighbourhood:
        let searchType = getSearchType(searchValue);
        if (searchType === ConfigConst.macAddress) {
          if (
            selDeviceTypes?.length > 0 &&
            selDeviceTypes?.indexOf(ConfigConst.emta) > -1
          ) {
            // emta selected
            setSearchType(ConfigConst.neighbourhood);
            setCurrSearchType(selAreaType);
            if (onHide) onHide();
          }
          if (
            selDeviceTypes?.length > 0 &&
            selDeviceTypes?.indexOf(ConfigConst.cm) > -1
          ) {
            // cm selected
            setSearchType(ConfigConst.neighbourhood);
            setCurrSearchType(selAreaType);
            if (onHide) onHide();
          }
          if (
            selDeviceTypes?.length > 0 &&
            selDeviceTypes?.indexOf(ConfigConst.ecm) > -1
          ) {
            // ecm selected
            setSearchType(ConfigConst.neighbourhood);
            setCurrSearchType(selAreaType);
            if (onHide) onHide();
          }
          if (
            selDeviceTypes?.length > 0 &&
            selDeviceTypes?.indexOf(ConfigConst.stb) > -1
          ) {
            // stb selected
            setSearchType(ConfigConst.neighbourhood);
            setCurrSearchType(selAreaType);
            if (onHide) onHide();
          }
        } else if (searchValue?.length === 12 && isStringNumber(searchValue)) {
          setCurrSearchType(selAreaType);
          if (onHide) onHide();
        } else {
          displayInputError(<li>{labels.macSearchErr}</li>);
        }
        break;

      case ConfigConst.electroline:
        if (
          searchValue &&
          searchValue?.length === 6 &&
          isNumeric(searchValue)
        ) {
          setSearchType(ConfigConst.electroline);
          setCurrSearchType(selAreaType);
          dispatch(NodeInfoDetailsClient.getInfoNodeDetails(searchValue));
        } else {
          displayInputError(<li>{labels.nodeIDError}</li>);
        }
        break;

      case ConfigConst.cmts:
        if (searchValue && getSearchType(searchValue) === "ipv4") {
          openNewWindow(`/#/cmts/level2?cmtsIp=${searchValue?.trim()}`);
        } else {
          displayInputError(<li>{labels.ipSearchErr}</li>);
        }

        break;

      case ConfigConst.f2:
        if (
          searchValue &&
          searchValue?.length === 6 &&
          isNumeric(searchValue)
        ) {
          setSearchType(ConfigConst.f2);
          setCurrSearchType(selAreaType);
          const url = `${
            NetworkConfig.F2_URL
          }?node=${searchValue?.trim()}&service=wave`;
          let newWindow = window.open(url, "_blank")!;
          newWindow.onload = () => {
            newWindow.document.title = `F2-${searchValue?.trim()}`;
          };
        } else {
          displayInputError(<li>{labels.nodeIDError}</li>);
        }
        break;
    }
  };

  //this custom hook is to search node/neighbourhood/olt
  useMultiDevicesSearch(
    currSearchType,
    setCurrSearchType,
    selDeviceTypes,
    searchValue,
    selDiagType
  );

  useEffect(() => {
    if (selAccType) {
      getTechRoles(techData)
        ? setSelAreaType(multiDeviceSearchConfig?.[selAccType]?.area?.[0])
        : setSelAreaType(multiDeviceSearchConfig?.[selAccType]?.area?.[1]);
    }
  }, [selAccType, techData]);

  useEffect(() => {
    if (selAreaType) {
      let device =
        multiDeviceSearchConfig?.[selAccType]?.[selAreaType]?.devices?.[0]?.key;
      let level =
        multiDeviceSearchConfig?.[selAccType]?.[selAreaType]?.level?.[0];
      if (selAreaType === ConfigConst.household) {
        setSelDeviceType(device ? device : "");
      } else {
        setSelDeviceType("");
        setSelDeviceTypes(device ? [device] : []);
        setActivePH(
          multiDeviceSearchConfig?.[selAccType]?.[selAreaType]?.searchMessage
        );
      }
      setSelDiagType(level ? level : "");
      setSelDataSetOptions(
        multiDeviceSearchConfig?.[selAccType]?.[selAreaType]?.level
      );
    }
  }, [selAreaType]);

  useEffect(() => {
    if (selDeviceType && selDeviceType !== "") {
      dispatch(SetDeviceSearchValue(selDeviceType));
      let deviceConfig: any = singleDeviceSearchConfig[selDeviceType];
      setActivePH(deviceConfig?.searchMessage);
    }
  }, [selDeviceType]);
  useEffect(() => {
    if (status === "ERROR") {
      setErrorAccBoxFlag(true);
      setErrorACCBoxBody(error?.errorMessage);
    }
  }, [status]);

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (searchType === "cbpId") {
      dispatch(AccDetailsClient.getAccDetails(searchValue, searchType));
    }
  }, [searchType]);

  return (
    <div className="multiDeviceSearchCont">
      <div className="tabsContainer">
        <span
          onClick={() => setActiveTab("search")}
          className={`tab ${activeTab === "search" ? "activeTab" : ""}`}
        >
          SEARCH
        </span>
        <span
          onClick={() => setActiveTab("ping")}
          className={`tab ${activeTab === "ping" ? "activeTab" : ""}`}
        >
          PING / TRACEROUTE
        </span>
        <div
          className="custTypeSeleCont"
          style={{ marginBottom: 0, position: "absolute", right: "20px" }}
        >
          <span
            onClick={() => handleChipClick("mds")}
            className="seleAccTypeSpan"
          >
            MULTI DEVICE SEARCH
          </span>
        </div>
      </div>
      <hr className="tabDivider" />

      {activeTab === "search" ? (
        <>
          <div className="custTypeSeleCont">
            <div className="topologySec">
              <label>{labels.technology.toUpperCase()} : </label>
              <div className="accTypeOptions">
                {Object.keys?.(multiDeviceSearchConfig)?.map(
                  (key: string, index: number) => {
                    return (
                      <span
                        key={key + index}
                        onClick={() => setSelAccType(key)}
                        className={selAccType === key ? "seleAccTypeSpan" : ""}
                      >
                        {key?.toUpperCase()}
                      </span>
                    );
                  }
                )}
              </div>
            </div>
          </div>
          <div className="seachInputSeleCont">
            <label>{labels.Level.toUpperCase()} : </label>
            {multiDeviceSearchConfig?.[selAccType]?.area?.map(
              (a: string, index: number) => {
                return (
                  <span key={a + index} onClick={() => setSelAreaType(a)}>
                    <input
                      type="radio"
                      value={a}
                      name="area"
                      onChange={() => {}}
                      checked={selAreaType === a}
                    />
                    <label>
                      {multiDeviceSearchConfig?.[selAccType]?.[
                        a
                      ]?.label.toUpperCase()}
                    </label>
                  </span>
                );
              }
            )}
          </div>
          {multiDeviceSearchConfig?.[selAccType]?.[selAreaType]?.devices && (
            <div className="seachInputSeleCont">
              <label>{labels.DEVICE.toUpperCase()} : </label>
              {multiDeviceSearchConfig?.[selAccType]?.[
                selAreaType
              ]?.devices?.map((device: any, index: number) => {
                if (selAreaType === ConfigConst.household) {
                  let deviceConfig: any = singleDeviceSearchConfig[device?.key];
                  return (
                    <span
                      key={device + index}
                      onClick={() => {
                        setSelDeviceType(deviceConfig?.key);
                      }}
                    >
                      <input
                        type="radio"
                        value={deviceConfig?.key}
                        name="device"
                        onChange={() => {}}
                        checked={selDeviceType === deviceConfig?.key}
                      />
                      <label>{deviceConfig?.label}</label>
                    </span>
                  );
                } else {
                  return (
                    <span
                      key={device?.key + index}
                      onClick={() => handleUpdateDeviceType(device?.key)}
                    >
                      {selDeviceTypes?.indexOf(device?.key) > -1 ? (
                        <ImCheckboxChecked color={`var(--primary)`} />
                      ) : (
                        <ImCheckboxUnchecked color={`var(--greyLight)`} />
                      )}
                      <label>{device?.label}</label>
                    </span>
                  );
                }
              })}
            </div>
          )}
          {multiDeviceSearchConfig?.[selAccType]?.[selAreaType]?.level && (
            <div className="seachInputSeleCont">
              <label>{labels.DATA_SET.toUpperCase()} : </label>
              {multiDeviceSearchConfig?.[selAccType]?.[selAreaType]?.level?.map(
                (l: string, index: number) => {
                  if (
                    selDeviceTypes?.length === 1 &&
                    selDeviceTypes?.every(
                      (e: string) => e === ConfigConst.cm
                    ) &&
                    l
                  ) {
                    return (
                      <span key={l + index} onClick={() => setSelDiagType(l)}>
                        <input
                          type="radio"
                          value={l}
                          name="level"
                          onChange={() => {}}
                          checked={selDiagType === l}
                        />
                        <label>{l?.toUpperCase()}</label>
                      </span>
                    );
                  } else {
                    if (selDiagType?.toUpperCase() === ConfigConst.FULL) {
                      setSelDiagType(ConfigConst.FULL_NO_CW.toLowerCase());
                    }
                    if (
                      selDeviceTypes?.length > 0 &&
                      l?.toUpperCase() !== ConfigConst.FULL
                    ) {
                      return (
                        <span key={l + index} onClick={() => setSelDiagType(l)}>
                          <input
                            type="radio"
                            value={l}
                            name="level"
                            onChange={() => {}}
                            checked={selDiagType === l}
                          />
                          <label>{l?.toUpperCase()}</label>
                        </span>
                      );
                    }
                  }
                }
              )}
            </div>
          )}
          <div className="searchInputCont">
            <div ref={wrapperRef} className="custSearchInputBox">
              <input
                id="multiDeviceSearchInput"
                className="custSearchInput"
                type="text"
                name="searchVal"
                value={searchValue}
                onChange={handleSearch}
                onKeyPress={(e: any) => handleKeyPress(e)}
                placeholder={activePH}
                onClick={() => setHistoryDisplay(true)}
                autoComplete="off"
              />
              <InputHistoryBox
                storageKey="searchHistory"
                historyDisplay={historyDisplay}
                setHistoryDisplay={setHistoryDisplay}
                searchValue={searchValue}
                onOptionClick={(sValue: any) => {
                  setSearchValue(sValue);
                  setCurrSearchValue(sValue);
                }}
                inputId="multiDeviceSearchInput"
              />
            </div>

            <button className="customSearchBtn" onClick={handleNetworkSearch}>
              <Search />
            </button>

            <div className="custTypeSeleCont" style={{ marginBottom: 0 }}>
              <span
                onClick={() => handleChipClick("Address")}
                className="seleTypeSpan"
              >
                Address Search
              </span>
            </div>
          </div>
          <CustomPopUp
            showVal={showAddressSearch}
            handleClose={() => setShowAddressSearch(false)}
            headerText={labels.SearchNodeByAddress}
            bodyElement={
              <AddressSearch
                onNodeOpen={() => setShowAddressSearch(false)}
                onSearchPopupHide={onHide}
              />
            }
          />
          <CustomPopUp
            showVal={showMDSSearch}
            handleClose={() => setShowMDSSearch(false)}
            headerText={labels.MultiDeviceSearch}
            bodyElement={
              <MDS
                onNodeOpen={() => setShowMDSSearch(false)}
                onSearchPopupHide={onHide}
              />
            }
          />
          <div className="checkBoxCont">
            <CustomErrorBox
              showVal={errorBoxFlag}
              bodyElement={
                <div>
                  <span>{labels.invalidInputErr}</span>
                  <ul>{errorBoxBody}</ul>
                </div>
              }
              handleClose={() => setErrorBoxFlag(false)}
            />
          </div>
          <div className="checkBoxCont">
            <CustomErrorBox
              showVal={errorAccFlag}
              bodyElement={errorAccBoxBody}
              handleClose={() => setErrorAccBoxFlag(false)}
            />
          </div>
        </>
      ) : (
        activeTab === "ping" && (
          <>
            <div className="seachInputSeleCont">
              {usemultiSearchConfig?.level?.map((a: string, index: number) => {
                return (
                  <span key={a + index} onClick={() => setSelRouteType(a)}>
                    <input
                      type="radio"
                      value={a}
                      name="level"
                      onChange={() => {}}
                      checked={selRouteType === a}
                    />
                    <label>{a?.toUpperCase()}</label>
                  </span>
                );
              })}
            </div>

            <div className="searchInputCont">
              <div className="row">
                <label className="textLabel">
                  {"IP ADDRESS"} :
                  <input
                    id="addressInput"
                    className="custPingSearchInput"
                    type="text"
                    name="searchVal"
                    value={searchIPValue}
                    onChange={handleIPSearch}
                    onKeyPress={(e: any) => handlePingPress(e)}
                    placeholder={"IP address"}
                    autoComplete="off"
                    style={{ paddingLeft: "3px" }}
                  />
                </label>
              </div>
              {selRouteType !== "TraceRoute" && (
                <>
                  <div className="row">
                    <label className="textLabel">
                      {"# PINGS"} :
                      <input
                        id="dataInput"
                        className="custNoPingSearchInput"
                        type="text"
                        name="searchVal"
                        value={searchPingValue}
                        onChange={handlePingSearch}
                        onKeyPress={(e: any) => handlePingPress(e)}
                        placeholder={"10"}
                        autoComplete="off"
                        style={{ paddingLeft: "3px" }}
                      />
                    </label>
                  </div>
                  <label className="subtextLabel">(Max 100 Pings)</label>
                </>
              )}
              <button
                className="customSearchBtn"
                onClick={handleNetworkIPSearch}
              >
                <Search />
              </button>
            </div>
          </>
        )
      )}
    </div>
  );
};

export default MultiDeviceSearch;
