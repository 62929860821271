import React from "react";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabScrollButton from "@material-ui/core/TabScrollButton/TabScrollButton";
import { Tooltip } from "@material-ui/core";
import CustomPopOver from "./CustomPopOver";

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "#da291c",
    boxShadow: theme.shadows[4],
    fontSize: 12,
  },
}))(Tooltip);

const ChipTabScrollButton = withStyles((theme) => ({
  root: {
    width: 28,
    overflow: "hidden",
    transition: "width 0.5s",
    color: "#da291c",
    "&.Mui-disabled": {
      width: 0,
    },
  },
}))(TabScrollButton);

const ChipTabs = withStyles({
  root: {
    backgroundColor: "transparent",
    minHeight: "auto",
  },
  indicator: {
    backgroundColor: "transparent",
  },
})(Tabs);

const ChipTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: "auto",
      textTransform: "none",
      minWidth: "auto",
      textAlign: "center",
      color: "#da291c",
      paddingTop: 4,
      paddingBottom: 4,
      paddingRight: 8,
      paddingLeft: 8,
      fontWeight: theme.typography.fontWeightLight,
      marginRight: theme.spacing(1),
      backgroundColor: "transparent",
      border: "1px solid #da291c",
      borderRadius: 25,
      "&:hover": {
        color: "#808284",
        opacity: 1,
        border: "1px solid #808284",
        borderRadius: 25,
      },
      "&$selected": {
        color: "#ffffff",
        fontWeight: theme.typography.fontWeightRegular,
        backgroundColor: "#da291c",
        border: "1px solid #da291c",
        borderRadius: 25,
      },
    },
    selected: {},
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

interface StyledTabProps {
  label: any;
}

export default function ChipGroup(props: any) {
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    props.onIndexChange(newValue);
  };
  var chipViews = [];
  for (let i = 0; i < props.chips?.length; i++) {
    props.chips?.[i] &&
      chipViews.push(
        <ChipTab
          key={i + ""}
          label={
            <CustomPopOver
              position="bottom"
              popOverData={props.chips[i]?.description}
              index="popOver"
              originComp={
                <span
                  className={
                    i === props.activeChipIndex ? "chipActive" : "chipInactive"
                  }
                >
                  {props.chips[i]?.title}
                </span>
              }
            />
          }
        />
      );
  }

  return (
    <ChipTabs
      value={props.activeChipIndex}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
      aria-label="Search Filters"
      ScrollButtonComponent={ChipTabScrollButton}
      draggable="true"
    >
      {chipViews}
    </ChipTabs>
  );
}
