import { NotificationInfo } from "../../models/notification/NotificationInfo";

export const NOTIFICATION_LIST_BEGIN = "NOTIFICATION_LIST_BEGIN";
export const NOTIFICATION_LIST_SUCCESS = "NOTIFICATION_LIST_SUCCESS";
export const NOTIFICATION_LIST_FAILURE = "NOTIFICATION_LIST_FAILURE";
export const NOTIFICATION_IS_READ = "NOTIFICATION_IS_READ";

interface NotificationListBegin {
  type: typeof NOTIFICATION_LIST_BEGIN;
}

interface NotificationListSuccess {
  type: typeof NOTIFICATION_LIST_SUCCESS;
  payload: NotificationInfo[];
}

interface NotificationListFailure {
  type: typeof NOTIFICATION_LIST_FAILURE;
  payload: Error;
}

interface NotificationIsRead{
  type: typeof NOTIFICATION_IS_READ;
  payload: String;
}

export type NotificationListActionTypes =
  | NotificationListBegin
  | NotificationListSuccess
  | NotificationListFailure
  | NotificationIsRead;
