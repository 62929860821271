import { Divider } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import { CircleFill, DiamondFill } from "react-bootstrap-icons";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceArea,
} from "recharts";
import AppLabels from "../../constants/App_Labels";
import ConfigConst from "../../constants/ConfigConst";
import {
  CHART_DYNAMIC_Y_AXIS_GAP,
  CHART_FIXED_Y_AXIS_GAP,
  CHART_Y_AXIS_TYPE,
} from "../../topology/constants/storageConst";
import CustomDropDown from "../reusable/CustomDropDown";
import { getChartTicks, hzToMHz } from "../reusable/Util";
import { CustomChartDot, CustomChartTooltip } from "./CmUsChart";
import { MdClose } from "react-icons/md";
import colors from "../../topology/config/colors";
import { BiExitFullscreen, BiFullscreen } from "react-icons/bi";

export const customDSChartLegend = (chartType: string, ofdmChs: any) => {
  return (
    <div className="legendCont">
      <span className="scQamCh">
        <CircleFill color={`var(--link)`} />
        SCQAM-{chartType === "power" ? "RX" : "SNR"}
      </span>
      {ofdmChs?.map((ch: any) => {
        return (
          <span key={ch?.channelId} className="ofdmCh">
            <DiamondFill color={`var(--purple)`} />
            OFDM-{ch?.channelId}-{chartType === "power" ? "RX" : "2PCT"}
          </span>
        );
      })}
    </div>
  );
};

interface Props {
  header?: boolean;
  isExpand?: boolean;
  setExpand?: any;
  cardLabel: string;
  chartRanges: any;
  scQamChs: any;
  ofdmChs: any;
  showChart?: any;
  onHideChart?: () => void;
}
const CmDsChart = ({
  header = true,
  isExpand,
  setExpand,
  cardLabel,
  chartRanges,
  scQamChs,
  ofdmChs,
  showChart,
  onHideChart,
}: Props) => {
  const labels = AppLabels();
  const axisTypes = ["Fixed", "Dynamic"];
  const yAxisDynamicDDOpt = ["2", "5", "7"];
  const yAxisFixedDDOpt = ["-5/+5", "-10/+10", "-15/+15", "-20/+20"];
  const savedYAxisType: any = localStorage.getItem(CHART_Y_AXIS_TYPE);

  const [chartData, setChartData] = useState(scQamChs ? scQamChs : []);
  const [activeAxisType, setActiveAxisType] = useState(
    savedYAxisType ? savedYAxisType : "Dynamic"
  );
  const [activeYAxisDDOpt, setActiveYAxisDDOpt] = useState(yAxisDynamicDDOpt);
  const [activeYAxisGap, setActiveYAxisGap] = useState("2");

  const [powerTicks, setPowerTicks] = useState([]);
  const [noiseTicks, setNoiseTicks] = useState([]);

  const savedFixedYAxisGap: any = localStorage.getItem(CHART_FIXED_Y_AXIS_GAP);
  const savedDynamicYAxisGap: any = localStorage.getItem(
    CHART_DYNAMIC_Y_AXIS_GAP
  );

  useEffect(() => {
    if (scQamChs) setChartData(scQamChs);
  }, [scQamChs]);

  useEffect(() => {
    if (ofdmChs) {
      let ofdmaChList: any = [];
      ofdmChs?.map((ch: any) => {
        ch?.channelBands?.map((chB: any) => {
          ofdmaChList.push({
            frequency: chB?.centerFreq,
            [`ofdm${ch?.channelId}Rx`]: chB?.rxPower,
            [`ofdm${ch?.channelId}Pct`]: ch?.mer?.thrVal,
          });
        });
      });
      let sortedChList = ofdmaChList?.sort(
        (a: any, b: any) => parseFloat(a?.frequency) - parseFloat(b?.frequency)
      );
      let tempChsList = [...sortedChList, ...scQamChs];
      setChartData(tempChsList);
    }
  }, [ofdmChs]);

  useEffect(() => {
    if (chartRanges && activeYAxisGap && activeAxisType) {
      if (activeAxisType === "Dynamic") {
        changeChartRanges(
          chartRanges?.minVals?.power,
          chartRanges?.maxVals?.power,
          chartRanges?.minVals?.noise,
          chartRanges?.maxVals?.noise,
          activeYAxisGap
        );
      } else if (activeAxisType === "Fixed") {
        let minPower = "";
        let maxPower = "";
        let yAxisGap = "";
        switch (activeYAxisGap) {
          case "-5/+5":
            minPower = "-5";
            maxPower = "5";
            yAxisGap = "5";
            break;
          case "-10/+10":
            minPower = "-10";
            maxPower = "10";
            yAxisGap = "5";
            break;
          case "-15/+15":
            minPower = "-15";
            maxPower = "15";
            yAxisGap = "5";
            break;
          case "-20/+20":
            minPower = "-20";
            maxPower = "20";
            yAxisGap = "10";
            break;
        }
        changeChartRanges(
          parseFloat(minPower),
          parseFloat(maxPower),
          chartRanges?.minVals?.noise,
          chartRanges?.maxVals?.noise,
          yAxisGap
        );
      }
    }
  }, [activeYAxisGap, chartRanges]);

  useEffect(() => {
    if (activeAxisType) {
      switch (activeAxisType) {
        case "Fixed":
          setActiveYAxisDDOpt(yAxisFixedDDOpt);
          if (savedFixedYAxisGap) setActiveYAxisGap(savedFixedYAxisGap);
          else {
            setActiveYAxisGap(yAxisFixedDDOpt[0]);
            localStorage.setItem(CHART_FIXED_Y_AXIS_GAP, yAxisFixedDDOpt[0]);
          }
          break;
        case "Dynamic":
          setActiveYAxisDDOpt(yAxisDynamicDDOpt);
          if (savedDynamicYAxisGap) setActiveYAxisGap(savedDynamicYAxisGap);
          else {
            setActiveYAxisGap(yAxisDynamicDDOpt[0]);
            localStorage.setItem(
              CHART_DYNAMIC_Y_AXIS_GAP,
              yAxisDynamicDDOpt[0]
            );
          }
          break;
      }
    }
  }, [activeAxisType]);

  /// to sync data among diff tabs///
  /* Ref:https://dev.to/cassiolacerda/how-to-syncing-react-state-across-multiple-tabs-with-usestate-hook-4bdm */
  useEffect(() => {
    window.addEventListener("storage", onStorageUpdate);
    return () => {
      window.removeEventListener("storage", onStorageUpdate);
    };
  }, []);

  const onStorageUpdate = (e: any) => {
    const { key, newValue } = e;
    if (key === CHART_FIXED_Y_AXIS_GAP) {
      setActiveYAxisGap(newValue);
    } else if (key === CHART_DYNAMIC_Y_AXIS_GAP) {
      setActiveYAxisGap(newValue);
    } else if (key === CHART_Y_AXIS_TYPE) {
      setActiveAxisType(newValue);
    }
  };
  ////////////////////////////////////////////

  const changeChartRanges = (
    minPower: any,
    maxPower: any,
    minNoise: any,
    maxNoise: any,
    yAxisGapVal: any
  ) => {
    let pTicks: any = getChartTicks(minPower, maxPower, parseInt(yAxisGapVal));
    let nTicks: any = getChartTicks(minNoise, maxNoise, parseInt(yAxisGapVal));

    if (pTicks && pTicks?.length > 0) setPowerTicks(pTicks);
    if (nTicks && nTicks?.length > 0) setNoiseTicks(nTicks);
  };

  const changeYAxisGap = (yAxisDDVal: any) => {
    setActiveYAxisGap(yAxisDDVal);
    if (activeAxisType === "Fixed") {
      localStorage.setItem(CHART_FIXED_Y_AXIS_GAP, yAxisDDVal);
    } else {
      localStorage.setItem(CHART_DYNAMIC_Y_AXIS_GAP, yAxisDDVal);
    }
  };

  const changeAxisType = (axisType: string) => {
    if (axisType) {
      setActiveAxisType(axisType);
      localStorage.setItem(CHART_Y_AXIS_TYPE, axisType);
    }
  };

  return (
    <>
      {chartData && chartData?.length > 0 && (
        <section className="chChartCont">
          {header && (
            <>
              <p className="cmChDetailHead">
                <b>{cardLabel}</b>
                <div className="chartActionCont">
                  <CustomDropDown
                    ddTitle="Type"
                    selectedOption={activeAxisType}
                    options={axisTypes}
                    searchFlag={false}
                    checkboxFlag={false}
                    setSelectedOption={(value: any) => {
                      changeAxisType(value);
                    }}
                  />
                  <CustomDropDown
                    ddTitle="Y-Axis"
                    selectedOption={`${activeYAxisGap}`}
                    options={activeYAxisDDOpt}
                    searchFlag={false}
                    checkboxFlag={false}
                    setSelectedOption={(value: any) => {
                      changeYAxisGap(value);
                    }}
                  />
                  {showChart && (
                    <>
                      <span
                        className="chartCloseBtn"
                        onClick={() => setExpand(!isExpand)}
                      >
                        {isExpand ? <BiExitFullscreen /> : <BiFullscreen />}
                      </span>
                      <span
                        className="chartCloseBtn"
                        onClick={() => (onHideChart ? onHideChart() : null)}
                      >
                        <MdClose />
                      </span>
                    </>
                  )}
                </div>
              </p>
              <Divider />
            </>
          )}
          <div className="chChartBody">
            <p>{labels.power.toUpperCase()}</p>
            <ResponsiveContainer
              width="100%"
              height="100%"
              minHeight="30vh"
              minWidth="50vw"
            >
              <LineChart width={1000} height={400} data={chartData}>
                <Line
                  type="monotone"
                  dataKey="rx_power.value"
                  stroke={`var(--link)`}
                  name={ConfigConst?.scQamRx?.toUpperCase()}
                  dot={<CustomChartDot />}
                />
                {ofdmChs?.map((ch: any) => {
                  return (
                    <Fragment key={ch?.channelId}>
                      <Line
                        type="monotone"
                        dataKey={`ofdm${ch?.channelId}Rx.value`}
                        stroke={`var(--purple)`}
                        strokeWidth={2}
                        name={`OFDM-${ch?.channelId}-RX`}
                        dot={<CustomChartDot />}
                      />
                      <ReferenceArea
                        x1={hzToMHz(ch?.lowerFreq)}
                        x2={hzToMHz(ch?.upperFreq)}
                        fill={`var(--purple)`}
                        fillOpacity={0.3}
                      />
                    </Fragment>
                  );
                })}
                <CartesianGrid stroke={colors.black70} strokeDasharray="3 3" />
                <XAxis
                  dataKey="frequency"
                  type="number"
                  domain={[
                    chartRanges?.minVals?.freq,
                    chartRanges?.maxVals?.freq,
                  ]}
                />
                <YAxis
                  type="number"
                  ticks={powerTicks}
                  domain={[powerTicks[0], powerTicks[powerTicks?.length - 1]]}
                />
                <Tooltip content={<CustomChartTooltip />} />
                <Legend content={customDSChartLegend("power", ofdmChs)} />
              </LineChart>
            </ResponsiveContainer>
            <Divider />
            <p>
              {labels.SNR.toUpperCase()}/{labels.secondPCT.toUpperCase()}
            </p>
            <ResponsiveContainer
              width="100%"
              height="100%"
              minHeight="30vh"
              minWidth="50vw"
            >
              <LineChart width={1000} height={400} data={chartData}>
                <Line
                  type="monotone"
                  dataKey="snr.value"
                  stroke={`var(--link)`}
                  name={ConfigConst?.scQamSnr?.toUpperCase()}
                  dot={<CustomChartDot />}
                />
                {ofdmChs?.map((ch: any) => {
                  return (
                    <Fragment key={ch?.channelId}>
                      <Line
                        type="monotone"
                        dataKey={`ofdm${ch?.channelId}Pct.value`}
                        stroke={`var(--purple)`}
                        strokeWidth={2}
                        name={`OFDM-${ch?.channelId}-2PCT`}
                        dot={<CustomChartDot />}
                      />
                      <ReferenceArea
                        x1={hzToMHz(ch?.lowerFreq)}
                        x2={hzToMHz(ch?.upperFreq)}
                        fill={`var(--purple)`}
                        fillOpacity={0.3}
                      />
                    </Fragment>
                  );
                })}
                <CartesianGrid stroke={colors.black70} strokeDasharray="3 3" />
                <XAxis
                  dataKey="frequency"
                  type="number"
                  domain={[
                    chartRanges?.minVals?.freq,
                    chartRanges?.maxVals?.freq,
                  ]}
                />
                <YAxis
                  type="number"
                  ticks={noiseTicks}
                  domain={[noiseTicks[0], noiseTicks[noiseTicks?.length - 1]]}
                />

                <Tooltip content={<CustomChartTooltip />} />
                <Legend content={customDSChartLegend("noise", ofdmChs)} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </section>
      )}
    </>
  );
};

export default CmDsChart;
