import { createSlice } from "@reduxjs/toolkit";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "rpdDecommMac",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
    nodeId: "",
    macAddress: "",
  },
  reducers: {
    rpdInfoDecommMacBegin: (rpd) => {
      rpd.isLoading = true;
    },
    rpdInfoDecommMacSuccess: (rpd, action) => {
      rpd.isLoading = false;
      rpd.data = action.payload;
      rpd.status = storeConst.OK;
      rpd.error = undefined;
    },
    rpdInfoDecommMacFailure: (rpd, action) => {
      rpd.isLoading = false;
      rpd.error = action.payload;
      rpd.data = undefined;
      rpd.status = storeConst.ERROR;
    },
    setNodeId: (rpd, action) => {
      rpd.nodeId = action.payload;
    },
    setMacAddress: (rpd, action) => {
      rpd.macAddress = action.payload;
    },
    rpdInfoDecommMacReset: (rpd) => {
      rpd.isLoading = false;
      rpd.error = undefined;
      rpd.data = undefined;
      rpd.status = "";
      rpd.macAddress = "";
      rpd.nodeId = "";
    },
  },
});

export const {
  rpdInfoDecommMacBegin,
  rpdInfoDecommMacSuccess,
  rpdInfoDecommMacFailure,
  setNodeId,
  setMacAddress,
  rpdInfoDecommMacReset,
} = slice.actions;
export default slice.reducer;
