import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FaSearch } from "react-icons/fa";
import { Divider } from "@material-ui/core";
import { GetVhubSearchProps } from "../../../util/reduxFunctions/getTopologyState";
import DiagContainer from "../../../../components/reusable/DiagContainer";
import TopologyLabels from "../../../constants/topologyLabels";
import {
  setMapCenter,
  setSelectedMapElement,
} from "../../../store/slices/mapItems/mapDetails";
import ExceptionBox from "../../../../components/reusable/ExceptionBox";
import { isNumeric } from "../../../util/utilFuncs";
import { getVhubSearch } from "../../../store/slices/mapItems/vhub/vhubSearch";
import appConst from "../../../constants/appConst";

const VhubSearch = () => {
  const dispatch = useDispatch();
  const labels = TopologyLabels();
  const { data, isLoading, error, status } = GetVhubSearchProps();
  const [vhubId, setVhubId] = useState<number | undefined>();
  const [vhubFlag, setVhubFlag] = useState(false);

  const searchVhub = () => {
    if (vhubId) {
      dispatch(getVhubSearch(vhubId));
      setVhubFlag(true);
    }
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      searchVhub();
    }
  };

  const elementData = {
    id: data?.features?.[0]?.featureId,
    type: appConst?.vhub,
    data: data?.features?.[0]?.properties?.data,
    coord: {
      lat: data?.features?.[0]?.geometry?.coordinates?.[1],
      lng: data?.features?.[0]?.geometry?.coordinates?.[0],
    },
  };

  useEffect(() => {
    if (
      data &&
      data !== undefined &&
      vhubFlag &&
      !isLoading &&
      status === appConst.OK
    ) {
      const geoData = {
        lat: data?.features?.[0]?.geometry?.coordinates?.[1],
        lng: data?.features?.[0]?.geometry?.coordinates?.[0],
      };
      const activeElement = {
        geometry: geoData,
        properties: {
          id: data?.features?.[0]?.featureId,
          type: appConst?.vhub,
          data: data?.features?.[0]?.properties?.data,
        },
      };
      dispatch(setSelectedMapElement(activeElement));
      dispatch(setMapCenter(geoData));
      setVhubFlag(false);
    }
  }, [data]);
  return (
    <div className="renSearchCont">
      <section>
        <input
          type="text"
          value={vhubId}
          placeholder="Enter VHUB Number"
          onChange={(val: any) => {
            setVhubId(isNumeric(val.target.value));
          }}
          onKeyPress={(event: any) => handleKeyPress(event)}
        />
        <button onClick={() => searchVhub()}>
          <FaSearch />
        </button>
      </section>
      <div className="searchResult">
        <DiagContainer
          isLoading={isLoading}
          data={data}
          error={error}
          sectionName={"Vhub Search"}
        >
          {elementData?.data?.length === 0 && (
            <ExceptionBox headerText={labels.noDataFound} />
          )}
        </DiagContainer>
      </div>
    </div>
  );
};

export default VhubSearch;
