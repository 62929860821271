import { NodeSummary } from "../../../models/node/nodeinfo/NodeSummary";

import {
  NODE_INFO_DETAILS_BEGIN,
  NODE_INFO_DETAILS_SUCCESS,
  NODE_INFO_DETAILS_FAILURE,
  NODE_INFO_RESET_STATUS,
  RESET_NODE_INFO_STATE,
  SET_NODE_SEARCH,
  NodeInfoDetailsActionTypes,
} from "../../../types/node/nodeinfo/NodeInfoDetails";

export function NodeInfoDetailsBegin(): NodeInfoDetailsActionTypes {
  return {
    type: NODE_INFO_DETAILS_BEGIN,
  };
}

export function NodeInfoDetailsSuccess(
  NodeInfoDetails: NodeSummary[]
): NodeInfoDetailsActionTypes {
  return {
    type: NODE_INFO_DETAILS_SUCCESS,
    payload: NodeInfoDetails,
  };
}

export function NodeInfoDetailsFailure(
  error: Error
): NodeInfoDetailsActionTypes {
  return {
    type: NODE_INFO_DETAILS_FAILURE,
    payload: error,
  };
}

export function NodeInfoResetStatus(): NodeInfoDetailsActionTypes {
  return {
    type: NODE_INFO_RESET_STATUS,
  };
}

export function SetNodeSearch(searchType: String): NodeInfoDetailsActionTypes {
  return {
    type: SET_NODE_SEARCH,
    payload: searchType,
  };
}

export function ResetNodeInfoState(): NodeInfoDetailsActionTypes {
  return {
    type: RESET_NODE_INFO_STATE,
  };
}
