import { CSSProperties, useEffect, useRef } from "react";

interface Props {
  children: JSX.Element | JSX.Element[];
  style?: CSSProperties;
  isDraggable?: Boolean;
}

export default function DraggableComp({
  children,
  style,
  isDraggable = true,
}: Props) {
  const ref = useRef(null);

  useEffect(() => {
    //Make the DIV element draggagle:
    if (isDraggable) dragElement(ref.current);

    //getElementById can also be used instead of ref
    // dragElement(document.getElementById("draggableDiv"));
  }, []);

  return (
    <div ref={ref} id="draggableDiv" style={style}>
      {children}
    </div>
  );
}

function dragElement(elmnt: any) {
  var pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0;
  elmnt.onmousedown = dragMouseDown;

  function dragMouseDown(e: any) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e: any) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    elmnt.style.top = elmnt.offsetTop - pos2 + "px";
    elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
  }

  function closeDragElement() {
    /* stop moving when mouse button is released:*/
    document.onmouseup = null;
    document.onmousemove = null;
  }
}
