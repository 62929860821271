import { AppBar, Box, Tab, Tabs } from "@material-ui/core";
import { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";

import "../../design-tokens/rpd-page/RPDPage.scss";
import { GetTechInfo } from "../../get-api/GetResponse";
import TECHClient from "../../network/httpclient/technician/TECHClient";
import RPDSearchTab from "./RPDSearchTab";
import RPDRegistrationTab from "./RPDRegistrationTab";
import RPDSwapTab from "./RPDSwapTab";
import RPDActivationTab from "./RPDActivationTab";
import RPDProvisioningTab from "./RPDProvisioningTab";
import RPDSWUpgradeTab from "./RPDSWUpgradeTab";
import RPDOrderDetailTab from "./RPDOrderDetailTab";
import VccapSWupgradeTab from "./VccapSWupgradeTab";
import VccapUATportTab from "./VccapUATportTab";
import VccapRpdConfigTab from "./VccapRpdConfigTab";
import CASAVideoExecTab from "./CASAVideoExecTab";
import AppLabels from "../../constants/App_Labels";
import ConfigConst from "../../constants/ConfigConst";
import ExceptionBox from "../../components/reusable/ExceptionBox";
import LoaderGif from "../../resources/images/loader.webp";
import SideTabs from "../../components/reusable/SideTabs";
import { getAccessLevelByRoles } from "../../components/reusable/Util";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const VccapPage = (props: any) => {
  const techData = GetTechInfo()?.data;
  const isLoading = GetTechInfo()?.isLoading;
  const labels = AppLabels();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [isRPDAvailable, setIsRPDAvailable] = useState(false);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const profileRef = useRef(false);

  const profileApi = useCallback(() => {
    if (techData === undefined && !profileRef.current) {
      dispatch(TECHClient.getTechniciansProfile());
      profileRef.current = true;
    } else if (techData !== undefined) {
      // techData?.roles?.map((item: any) => {
      //   if (
      //     item === ConfigConst.MAINTENANCE_TECH_MANAGER ||
      //     item.includes(ConfigConst.MAINTENANCE_TECH) ||
      //     item.includes(ConfigConst.PROVISIONING_TECH) ||
      //     item.includes(ConfigConst.PROVISIONING_TECH_MANAGER) ||
      //     item === ConfigConst.ADMIN
      //   ) {
      //     setIsRPDAvailable(true);
      //   }
      // });
      if (
        getAccessLevelByRoles(techData?.roles).includes(ConfigConst.MT) ||
        getAccessLevelByRoles(techData?.roles).includes(
          ConfigConst.PROVISION_T
        ) ||
        techData?.roles?.includes(ConfigConst.ADMIN)
      ) {
        setIsRPDAvailable(true);
      }
    }
  }, [dispatch, techData]);

  useEffect(() => {
    profileApi();
  }, [profileApi]);

  const tabsItems = [
    {
      label: labels.rpdConfig,
      compToRend: <VccapRpdConfigTab key="rpdconfig" />,
    },
    {
      label: labels.vccapSWupgrade,
      compToRend: <VccapSWupgradeTab key="vccapsw" />,
    },
    {
      label: labels.vccapUATport,
      compToRend: <VccapUATportTab key="vccapuat" />,
    },
    // {
    //   label: labels.casaRPDConfig,
    //   compToRend: <CASAVideoExecTab key="casaconfig" />,
    // },

    // {
    //   label: labels.rpdOrderInfo,
    //   compToRend: <RPDOrderDetailTab />,
    // },
  ];
  return (
    <SideTabs
      tabsItems={tabsItems}
      childrenStyle={{ marginRight: 25 }}
      size="small"
    />
  );
};
export default VccapPage;
