import { createSlice } from "@reduxjs/toolkit";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "vccapCASAVideo",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
    nptId: undefined,
    qpsk: undefined,
    aux: undefined,
    docsisip: undefined,
    videoip: undefined,
    mapping: undefined,
    macdomain: undefined,
    rpdmac: undefined,
    docsishostname: undefined,
    videohostname: undefined,
    videoCore: undefined,
    botType: "Generation",
  },
  reducers: {
    vccapCASAVideoBegin: (rpd) => {
      rpd.isLoading = true;
    },
    vccapCASAVideoSuccess: (rpd, action) => {
      rpd.isLoading = false;
      rpd.data = action.payload;
      rpd.error = undefined;
      rpd.status = storeConst.OK;
    },
    vccapCASAVideoFailure: (rpd, action) => {
      rpd.isLoading = false;
      rpd.error = action.payload;
      rpd.data = undefined;
      rpd.status = storeConst.ERROR;
    },
    vccapCASAVideoReset: (rpd) => {
      rpd.isLoading = false;
      rpd.error = undefined;
      rpd.data = undefined;
      rpd.status = "";
      rpd.nptId = undefined;
    },
    setNPTId: (rpd, action) => {
      rpd.nptId = action.payload;
      rpd.error = undefined;
      rpd.data = undefined;
      rpd.status = "";
    },
    setcasaQpsk: (rpd, action) => {
      rpd.qpsk = action.payload;
    },
    setcasaAux: (rpd, action) => {
      rpd.aux = action.payload;
    },
    setcasaDocsisip: (rpd, action) => {
      rpd.docsisip = action.payload;
    },
    setcasaVideoip: (rpd, action) => {
      rpd.videoip = action.payload;
    },
    setcasaMapping: (rpd, action) => {
      rpd.mapping = action.payload;
    },
    setcasaVideohostname: (rpd, action) => {
      rpd.videohostname = action.payload;
    },
    setcasaDocsishostname: (rpd, action) => {
      rpd.docsishostname = action.payload;
    },
    setcasaMacdomain: (rpd, action) => {
      rpd.macdomain = action.payload;
    },
    setcasaRpdmac: (rpd, action) => {
      rpd.rpdmac = action.payload;
    },
    setcasaVideoCore: (rpd, action) => {
      rpd.videoCore = action.payload;
    },
    setBotType: (rpd, action) => {
      rpd.botType = action.payload;
    },
  },
});

export const {
  vccapCASAVideoBegin,
  vccapCASAVideoSuccess,
  vccapCASAVideoFailure,
  vccapCASAVideoReset,
  setNPTId,
  setcasaQpsk,
  setcasaAux,
  setcasaDocsisip,
  setcasaVideoip,
  setcasaMapping,
  setcasaVideohostname,
  setcasaDocsishostname,
  setcasaMacdomain,
  setcasaRpdmac,
  setcasaVideoCore,
  setBotType,
} = slice.actions;
export default slice.reducer;
