import { FormEvent, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { DateRange } from "react-date-range";
import { MdDateRange } from "react-icons/md";

import TopologyLabels from "../../../../constants/topologyLabels";
import { CLICK_CONFIG as CC } from "../../../../config/mapItemsConfig";
import NumberInputStepper from "../../../reusable/NumberInputStepper";
import { isNumeric } from "../../../../util/utilFuncs";
import CustomDialog from "../../../../../components/reusable/CustomDialog";
import colors from "../../../../config/colors";

interface Props {
  setDRS: (arg0: any) => void;
  radiusValue: string;
  setRadiusValue: (arg0: string) => void;
  handleKeyPress: (arg0: KeyboardEvent) => void;
  getDRFormat: (arg0: string) => void;
  searchTypeIndex: number;
}

export default function Inputs({
  setDRS,
  radiusValue,
  setRadiusValue,
  handleKeyPress,
  getDRFormat,
  searchTypeIndex,
}: Props) {
  const labels = TopologyLabels();
  const now = new Date();
  const [daysBack, setDaysBack] = useState(CC.dfltDaysBack);
  const [dateRangeSelection, setDateRangeSelection] = useState(
    getDateRangeFormat(now, daysBack)
  );
  const [showDateRange, setShowDateRange] = useState(false);

  useEffect(() => {
    setDRS(dateRangeSelection);
  }, [dateRangeSelection]);

  const onIncPress = () => {
    const val = Number(radiusValue) + CC.incDecBy;
    setRadiusValue(String(val));
  };
  const onDecPress = () => {
    const val = Number(radiusValue) - CC.incDecBy;
    if (val > 0) setRadiusValue(String(val));
  };

  const onDaysBackChange = (e: FormEvent<HTMLInputElement>) => {
    const val = isNumeric(e?.currentTarget?.value);
    if (val <= CC.maxDaysBack) {
      setDaysBack(val);
      setDateRangeSelection(getDateRangeFormat(now, val));
    }
  };

  const handleDateRangeSelect = (ranges: any) => {
    const dates = ranges.selection;
    const timeDiff = dates?.endDate?.getTime() - dates?.startDate?.getTime();
    const days = Math.ceil(timeDiff / (1000 * 3600 * 24));
    setDateRangeSelection([dates]);
    setDaysBack(days);
  };

  const inputItems = [
    {
      label: labels.DaysBack,
      input: (
        <input
          value={daysBack}
          placeholder={labels.DaysBack}
          onChange={onDaysBackChange}
          onKeyPress={(e: any) => handleKeyPress(e)}
          style={{
            borderRadius: 5,
            borderWidth: 0.3,
            padding: 1.5,
            borderColor: `var(--greyMedium)`,
            textAlign: "center",
          }}
        />
      ),
    },
    {
      label: labels.DateRange,
      input: (
        <div className="fromToDate" onClick={() => setShowDateRange(true)}>
          <Form.Text style={{ margin: 2 }}>
            {`${getDRFormat("startDate")} - ${getDRFormat("endDate")}`}
          </Form.Text>
          <MdDateRange fill="var(--greyMedium)" size={20} />
        </div>
      ),
    },
    searchTypeIndex === 1 && {
      label: `${labels.RadiusIn} (${labels.M})`,
      input: (
        <NumberInputStepper
          inputValue={radiusValue}
          setInputValue={(v) => setRadiusValue(isNumeric(v.target.value))}
          placeholder={`${labels.RadiusInMeters}...`}
          onIncPress={onIncPress}
          onDecPress={onDecPress}
          minDec={CC.minRadius}
          maxInc={CC.maxRadius}
          onKeyPress={(e: any) => handleKeyPress(e)}
        />
      ),
    },
  ];

  return (
    <>
      <div className="clickFltInputCont">
        {inputItems?.map(
          (e: any) =>
            e && (
              <div key={e.label} className="inputItemsCont">
                <label className="nikaRadiusLabel">{e.label}</label>
                {e.input}
              </div>
            )
        )}
      </div>
      <CustomDialog
        className="CustomBox"
        open={showDateRange}
        handleClose={() => {
          setShowDateRange(false);
        }}
        childComponent={
          <div className="fromToRootContainer">
            <DateRange
              onChange={handleDateRangeSelect}
              moveRangeOnFirstSelection={false}
              ranges={dateRangeSelection}
              rangeColors={[colors.primary, colors.primary, colors.primary]}
            />
          </div>
        }
        heading={labels.SelectDateRange}
      />
    </>
  );
}

const getDateRangeFormat = (now: any, intervalInDays: number) => [
  {
    startDate: new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - intervalInDays
    ),
    endDate: new Date(),
    key: "selection",
  },
];
