import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../middleware/apiActions";
import { MDU_TAP } from "../../../middleware/apiEndpoints";
import storeConst from "../../../storeConst";
import { decrApiCall, incrApiCall } from "../mapDetails";

const slice = createSlice({
  name: "mduTap",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    getMduTapBegin: (mduTap) => {
      mduTap.isLoading = true;
      mduTap.status = "";
    },
    getMduTapSuccess: (mduTap, action) => {
      mduTap.isLoading = false;
      mduTap.data = action.payload;
      mduTap.status = storeConst.OK;
    },
    getMduTapFailure: (mduTap, action) => {
      mduTap.isLoading = false;
      mduTap.error = action.payload;
      mduTap.data = undefined;
      mduTap.status = storeConst.ERROR;
    },
  },
});

const { getMduTapBegin, getMduTapSuccess, getMduTapFailure } = slice.actions;
export default slice.reducer;

export const getMduTap = (owningDrawing: any) => (dispatch: any) => {
  dispatch(
    apiCallBegan({
      url: `${MDU_TAP}/${owningDrawing}`,
      method: "get",
      onStart: getMduTapBegin.type,
      onSuccess: getMduTapSuccess.type,
      onError: getMduTapFailure.type,
      onStartDispatch: incrApiCall.type,
      onSuccessDispatch: decrApiCall.type,
      onErrorDispatch: decrApiCall.type,
    })
  );
};
