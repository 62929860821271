import CustomAccordion from "../../../../components/reusable/CustomAccordion";
import TopologyLabels from "../../../constants/topologyLabels";
import {
  changeTimeFormat,
  GetAvailableTR,
} from "../../../../components/reusable/Util";
import AppLabels from "../../../../constants/App_Labels";
import { LINKS } from "../../../../constants/ExternalLinks";
import GoogleMapDirectionsBtn from "../../../../components/reusable/GoogleMapDirectionsBtn";

interface Props {
  elementData: any;
}

export default function ClickOrdersTable({ elementData }: Props) {
  const labels = AppLabels();
  const tLabels = TopologyLabels();
  const DT = elementData?.data;

  const onAcctNumPress = (accountNumber: any) => {
    if (accountNumber)
      window.open(
        LINKS?.techMPA_MRF + accountNumber,
        "_blank",
        "noopener,noreferrer"
      );
  };

  return (
    <div>
      {DT && (
        <CustomAccordion
          accTitle={tLabels.ClickOrder.toUpperCase()}
          headerComp={
            <GoogleMapDirectionsBtn
              lat={elementData?.coord?.lat}
              lng={elementData?.coord?.lng}
            />
          }
          accBodyElement={
            <table className="dataTableDetails">
              <thead>
                <tr>
                  <td>{labels.Name.toUpperCase()}</td>
                  <td>{labels.VALUE.toUpperCase()}</td>
                </tr>
              </thead>
              <tbody>
                {/* <tr>
                  <td>{labels.ACCOUNT_NUMBER}</td>
                  <td>
                    <a
                      className="idLink"
                      onClick={() => onAcctNumPress(DT?.accountNumber)}
                    >
                      {DT?.accountNumber}
                    </a>
                  </td>
                </tr> */}

                <GetAvailableTR name={labels.Address} value={DT?.address} />
                <GetAvailableTR name={labels.SHUB} value={DT?.shubId} />
                <GetAvailableTR name={labels.SMT} value={DT?.smtId} />
                <GetAvailableTR name={labels.callId} value={DT?.callId} />
                <GetAvailableTR
                  name={labels.ACCOUNT_NUMBER}
                  value={DT?.accountNumber}
                />
                <GetAvailableTR name={labels.STATUS} value={DT?.status} />
                <GetAvailableTR name={tLabels.Tech} value={DT?.TechName} />
                <GetAvailableTR
                  name={labels.Order_Type}
                  value={DT?.orderTypeName}
                />
                <GetAvailableTR
                  name={tLabels.orderCategoryDesc}
                  value={DT?.orderCategoryDesc}
                />
                <GetAvailableTR
                  name={tLabels.orderCategoryName}
                  value={DT?.orderCategoryName}
                />
                <GetAvailableTR
                  name={tLabels.orderTypeDesc}
                  value={DT?.orderTypeDesc}
                />
                <GetAvailableTR
                  name={tLabels.CompletionCodes}
                  value={DT?.completionCodes}
                />
                <GetAvailableTR
                  name={tLabels.TimeCreated}
                  value={changeTimeFormat(DT?.timeCreated)}
                />
                <GetAvailableTR
                  name={tLabels.TimeModified}
                  value={changeTimeFormat(DT?.timeModified)}
                />
                <GetAvailableTR
                  name={tLabels.RemedyImtId}
                  value={DT?.remedyImtId}
                />
                <GetAvailableTR name={tLabels.WorkType} value={DT?.workType} />
                <GetAvailableTR name={tLabels.PHUB} value={DT?.pHub} />
                <GetAvailableTR name={tLabels.PonHub} value={DT?.ponHub} />
                <GetAvailableTR name={tLabels.PonNap} value={DT?.ponNap} />
                <GetAvailableTR name={tLabels.PonNode} value={DT?.ponNode} />
                <GetAvailableTR name={tLabels.Company} value={DT?.company} />
                <GetAvailableTR name={tLabels.Revision} value={DT?.revision} />
                <GetAvailableTR name={labels.PRIORITY} value={DT?.priority} />
                <GetAvailableTR name={tLabels.comments} value={DT?.comments} />
                <GetAvailableTR
                  name={tLabels.returnComments}
                  value={DT?.returnComments}
                />
                <GetAvailableTR
                  name={tLabels.specialInstructions}
                  value={DT?.specialInstructions}
                />
              </tbody>
            </table>
          }
        />
      )}
    </div>
  );
}
