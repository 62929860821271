import {
  RESET_NODES,
  SAVE_NODES,
  SET_NODE_GEOCODE,
  UPDATE_NODES,
  INCREMENT_NODE_API_CALL_COUNTER,
  DECREMENT_NODE_API_CALL_COUNTER,
  NodesActionTypes,
} from '../../../types/account/node/NodesSummary';

export function SaveNodes(nodes: any): NodesActionTypes {
  return {
    type: SAVE_NODES,
    payload: nodes,
  };
}
export function SetNodeGeoCode(
  snNumber: string,
  geodata: any,
): NodesActionTypes {
  return {
    type: SET_NODE_GEOCODE,
    snNumber: snNumber,
    geoCode: geodata,
  };
}
export function UpdateNodeDevices(
  snNumber: string,
  subdataKey: string,
  data: any,
  status: string,
): NodesActionTypes {
  return {
    type: UPDATE_NODES,
    snNumber: snNumber,
    subdataKey: subdataKey,
    data: data,
    status: status,
  };
}
export function IncrementNodeApiCallCounter(
  snNumber: string,
  subdataKey: string,
): NodesActionTypes {
  return {
    type: INCREMENT_NODE_API_CALL_COUNTER,
    snNumber: snNumber,
    subDataKey: subdataKey,
  };
}
export function DecrementNodeApiCallCounter(
  snNumber: string,
  subdataKey: string,
): NodesActionTypes {
  return {
    type: DECREMENT_NODE_API_CALL_COUNTER,
    snNumber: snNumber,
    subDataKey: subdataKey,
  };
}

export function ResetNodes(): NodesActionTypes {
  return {
    type: RESET_NODES,
  };
}
