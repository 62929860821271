import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../middleware/apiActions";
import { MDU_CAD_CIRCLE } from "../../../middleware/apiEndpoints";
import storeConst from "../../../storeConst";
import { decrApiCall, incrApiCall } from "../mapDetails";

const slice = createSlice({
  name: "mduCADCircle",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    getMduCADCircleBegin: (mduCADCircle) => {
      mduCADCircle.isLoading = true;
      mduCADCircle.status = "";
    },
    getMduCADCircleSuccess: (mduCADCircle, action) => {
      mduCADCircle.isLoading = false;
      mduCADCircle.data = action.payload;
      mduCADCircle.status = storeConst.OK;
    },
    getMduCADCircleFailure: (mduCADCircle, action) => {
      mduCADCircle.isLoading = false;
      mduCADCircle.error = action.payload;
      mduCADCircle.data = undefined;
      mduCADCircle.status = storeConst.ERROR;
    },
  },
});

const { getMduCADCircleBegin, getMduCADCircleSuccess, getMduCADCircleFailure } =
  slice.actions;
export default slice.reducer;

export const getMduCADCircle = (owningDrawing: any) => (dispatch: any) => {
  dispatch(
    apiCallBegan({
      url: `${MDU_CAD_CIRCLE}/${owningDrawing}`,
      method: "get",
      onStart: getMduCADCircleBegin.type,
      onSuccess: getMduCADCircleSuccess.type,
      onError: getMduCADCircleFailure.type,
      onStartDispatch: incrApiCall.type,
      onSuccessDispatch: decrApiCall.type,
      onErrorDispatch: decrApiCall.type,
    })
  );
};
