
import { AttachmentState} from "../../states/feedback/AttachmentState"
import {
  ATTACHMENT_BEGIN,
  ATTACHMENT_SUCCESS,
  ATTACHMENT_FAILURE,
  AttachmentActionTypes
} from "../../types/feedback/FeedbackAttachment"

const initialState: AttachmentState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function AttachmentReducer(
  state = initialState,
  action: AttachmentActionTypes
): AttachmentState  {
  switch (action.type) {
    case ATTACHMENT_BEGIN:
      return {
        isLoading: true,
      };
    case ATTACHMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case ATTACHMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}
