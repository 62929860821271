import {
  CMTSRackIDActionTypes,
  CMTS_RACK_ID_BEGIN,
  CMTS_RACK_ID_SUCCESS,
  CMTS_RACK_ID_FAILURE,
} from "../../../types/node/nodeinfo/CMTSRackIDDetails";

export function CMTSRackIDBegin(): CMTSRackIDActionTypes {
  return {
    type: CMTS_RACK_ID_BEGIN,
  };
}

export function CMTSRackIDSuccess(
  CMTSRackIDDetails: any
): CMTSRackIDActionTypes {
  return {
    type: CMTS_RACK_ID_SUCCESS,
    payload: CMTSRackIDDetails,
  };
}

export function CMTSRackIDFailure(error: any): CMTSRackIDActionTypes {
  return {
    type: CMTS_RACK_ID_FAILURE,
    payload: error,
  };
}
