import AppLabels from "../../constants/App_Labels";
import CustomAccordion from "../reusable/CustomAccordion";
import DataDisplayTable from "../reusable/DataDisplayTable";

const ASTBDetails = (props: any) => {
  const { deviceData } = props;
  const labels = AppLabels();
  return (
    <CustomAccordion
      accTitle={labels.SUMMARY.toUpperCase()}
      accBodyElement={
        <>
          {deviceData !== undefined ? (
            <DataDisplayTable dataObject={deviceData} tableName="astbSummary" />
          ) : (
            <></>
          )}
        </>
      }
    />
  );
};

export default ASTBDetails;
