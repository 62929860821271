import { IoClose } from "react-icons/io5";
import { Modal } from "react-bootstrap";
import { Divider } from "@material-ui/core";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { BsBoxArrowUpRight } from "react-icons/bs";
import ExternalLinks from "../../constants/ExternalLinks";
import AppLabels from "../../constants/App_Labels";
import CustomPopOver from "./CustomPopOver";
import LoginFixModal from "../profile-management/LoginFixModal";
import ProfileManagement from "../profile-management/ProfileManagement";
import ProfileManagementClient from "../../network/httpclient/profileManagement/PofileManagementClient";

const ExternalAppMenu = (props: any) => {
  const labels = AppLabels();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showLoginFix, setShowLoginFix] = useState(false);
  const [showProfMan, setShowProfMan] = useState(false);
  const openLoginFix = () => {
    setShowLoginFix(true);
    setShow(false);
  };
  const openProfMan = () => {
    dispatch(ProfileManagementClient.banSearch(consolidatedBan));
    setShowProfMan(true);
    setShow(false);
  };
  const handleClose = () => {
    setShow(false);
    props?.setShow();
  };
  const handleShow = () => {
    setShow(true);
    props?.setShow();
  };
  const { sortedDevices, activeAccountInfo } = useSelector(
    (state: any) => ({
      sortedDevices: state.AccountState.ActiveAccountDetails.sortedDevices,
      activeAccountInfo: state.AccountState.ActiveAccountDetails.data,
    }),
    shallowEqual
  );
  const consolidatedBan = activeAccountInfo?.consolidatedBan;

  const macAddress = sortedDevices
    ? sortedDevices
      ?.filter(
        (cw: any) =>
          cw.deviceCategory === "CM" ||
          cw.deviceCategory === "GW_GPON" ||
          cw.deviceCategory === "GW_FWA"
      )
      ?.map((cw: any) => cw.macAddress)
    : null;
  const ExternalLinksDb = ExternalLinks();
  const ExternalAppDb = ExternalLinksDb.ExternalApplicationList;
  const KnowledgeBaseDb = ExternalLinksDb.knowledgeBaseList;
  function openLink(link: any) {
    const newWindow = window.open(link, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  }

  useEffect(() => {
    if (props.show) {
      setShow(props.show);
    }
  }, [props.show]);

  const isProfManDisabled: boolean =
    consolidatedBan === undefined
      || consolidatedBan === null
      || consolidatedBan === '' ? true : false;

  return (
    <>
      <CustomPopOver
        position="bottom"
        popOverData="Quick Links"
        originComp={
          <button className="appMenuBtn" onClick={handleShow}>
            <BsBoxArrowUpRight size={20} />
          </button>
        }
        index="popOver"
      />
      <LoginFixModal
        handleClose={handleClose}
        goBack={handleShow}
        show={showLoginFix}
        setShow={setShowLoginFix}
      />
      <ProfileManagement
        handleClose={handleClose}
        goBack={handleShow}
        show={showProfMan}
        setShow={setShowProfMan}
      />
      <Modal
        {...props}
        size="lg"
        keyboard={false}
        dialogClassName="modalExternalAppMenu"
        backdrop="static"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="searchPopHead">
          <header>QUICK LINKS</header>
          <button className="sPopUpClose" onClick={handleClose}>
            <IoClose color="white" />
          </button>
        </Modal.Header>
        <Modal.Body>

          <>
            <div className="topContainer">
              <p className="externalAppText">
                {labels.PROFILE_MANAGEMENT.toUpperCase()}
              </p>
              {isProfManDisabled && (
                <p style={{ fontSize: 13 }}>*{labels.NoProfileManagementLinkWarning}</p>)}
              <button
                className="applicationBoxWide"
                onClick={openLoginFix}
                disabled={isProfManDisabled}
              >
                <svg width="45" height="45" viewBox="0 0 45 45" fill="none">
                  <circle
                    cx="22.5"
                    cy="22.5"
                    r="21.5"
                    stroke="currentColor"
                    stroke-width="2"
                  />
                </svg>
                <div className="appNameText">
                  <span>{labels.LOGIN_FIX.toUpperCase()}</span>
                </div>
                <div className="letterInCircle">
                  <span>A</span>
                </div>
              </button>
              <button
                className="applicationBoxWide"
                onClick={openProfMan}
                disabled={consolidatedBan === null || consolidatedBan === undefined}
              >
                <svg width="45" height="45" viewBox="0 0 45 45" fill="none">
                  <circle
                    cx="22.5"
                    cy="22.5"
                    r="21.5"
                    stroke="currentColor"
                    stroke-width="2"
                  />
                </svg>
                <div className="appNameText">
                  <span>PROFILE MANAGEMENT</span>
                </div>
                <div className="letterInCircle">
                  <span>L</span>
                </div>
              </button>
            </div>
            <Divider
              orientation="horizontal"
              style={{ marginBottom: "1rem" }}
            />
          </>

          <div className="topContainer">
            <p className="externalAppText">EXTERNAL APPS (VPN Required)</p>
            {ExternalAppDb?.map((d: any, i: any) => {
              return (
                <button
                  className="applicationBox"
                  key={i}
                  onClick={() => openLink(d.Link)}
                >
                  <svg width="45" height="45" viewBox="0 0 45 45" fill="none">
                    <circle
                      cx="22.5"
                      cy="22.5"
                      r="21.5"
                      stroke="currentColor"
                      stroke-width="2"
                    />
                  </svg>
                  <div className="appNameText">
                    <span>{d.AppName}</span>
                    {d.AppName === "FSC Commercial" ? (
                      <div className="letterUnderBox">
                        <p>
                          <br></br> (No VPN){" "}
                        </p>
                      </div>
                    ) : null}
                  </div>
                  <div className="letterInCircle">
                    <span>
                      {d.AppName === "IEC" ? "C" : d.AppName.charAt(0)}
                    </span>
                  </div>
                </button>
              );
            })}
          </div>
          <Divider orientation="horizontal" />
          <div className="bottonCont">
            <p className="knowledgeBaseText">KNOWLEDGE BASE (VPN Required)</p>
            {KnowledgeBaseDb?.map((d: any, i: any) => {
              return (
                <button
                  className="applicationBox"
                  key={i}
                  onClick={() => openLink(d.Link)}
                >
                  <svg width="45" height="45" viewBox="0 0 45 45" fill="none">
                    <circle
                      cx="22.5"
                      cy="22.5"
                      r="21.5"
                      stroke="currentColor"
                      stroke-width="2"
                    />
                  </svg>
                  <div className="appNameText">
                    <span>{d.AppName}</span>
                    {d.AppName === "AXONIFY" ? (
                      <div className="letterUnderBox">
                        <p>
                          <br></br> (No VPN){" "}
                        </p>
                      </div>
                    ) : null}
                  </div>

                  <div className="letterInCircle">
                    <span>{d.AppName.charAt(0)}</span>
                  </div>
                </button>
              );
            })}
          </div>
        </Modal.Body>
        <Divider />
        <Modal.Footer>
          <button className="sPopCloseBtn" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ExternalAppMenu;
