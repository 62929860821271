import { createSlice } from "@reduxjs/toolkit";
import { ACCOUNT_SERVICE_URL, FILE_UPLOAD_URI } from "../../middleware/apiEndpoints";
import storeConst from "../../storeConst";
import { apiCallBegan } from "../../../topology/store/middleware/apiActions";

const slice = createSlice({
    name: "fileUpload",
    initialState: {
        isLoading: false,
        data: undefined,
        error: undefined,
        status: "",
    },
    reducers: {
        fileUploadBegin: (as) => {
            as.isLoading = true;
            as.status = "";
        },
        fileUploadSuccess: (as, { payload }) => {
            as.isLoading = false;
            as.data = payload;
            as.status = storeConst.OK;
        },
        fileUploadFailure: (as, { payload }) => {
            as.isLoading = false;
            as.error = payload;
            as.data = undefined;
            as.status = storeConst.ERROR;
        },
        fileUploadReset: (as) => {
            as.isLoading = false;
            as.status = "";
            as.data = undefined;
            as.error = undefined;
        },
    },
});

export const {
    fileUploadBegin,
    fileUploadSuccess,
    fileUploadFailure,
    fileUploadReset,
} = slice.actions;

export default slice.reducer;

//action creators
export const uploadFile = (file: any) =>
    apiCallBegan({
        baseURL: ACCOUNT_SERVICE_URL,
        url: `${FILE_UPLOAD_URI}${file?.name}`,
        onStart: fileUploadBegin.type,
        onSuccess: fileUploadSuccess.type,
        onError: fileUploadFailure.type,
        method: "post",
        data: JSON.stringify({
            fileName: file.name,
            fileData: file.file
        }),
    });