import { IoClose } from "react-icons/io5";
import { Modal } from "react-bootstrap";
import { Divider } from "@material-ui/core";
import CustomPopOver from "../../components/reusable/CustomPopOver";
import DictionaryIcon from "../../resources/images/icons/dictionary_icon.svg";
import GponDictDetails from "./GponDictDetails";
import { getDeviceData } from "../../components/reusable/Util";
import AppLabels from "../../constants/App_Labels";

type Props = {
  show: boolean;
  setShow: (flag: boolean) => void;
};

const GponDictionary = (props: Props) => {
  const { show, setShow } = props;
  const labels = AppLabels();
  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <CustomPopOver
        position="bottom"
        popOverData="Dictionary"
        originComp={
          // <button className="appMenuBtn" onClick={handleShow}>
          <img src={DictionaryIcon} alt="Dictionary" />
          // </button>
        }
        index="popOver"
      />
      <Modal
        {...props}
        size="xl"
        keyboard={false}
        dialogClassName="modalExternalAppMenu"
        backdrop="static"
        show={show}
        onHide={handleClose}
        scrollable={true}
      >
        <Modal.Header className="searchPopHead">
          <header>Dictionary</header>
          <button className="sPopUpClose" onClick={handleClose}>
            <IoClose color="white" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <GponDictDetails
            deviceData={getDeviceData(labels.gpon_ont_query)}
            accordTitle={labels.gpon_ont_query}
          />
          <GponDictDetails
            deviceData={getDeviceData(labels.ont_topology_data)}
            accordTitle={labels.ont_topology_data}
          />
          <GponDictDetails
            deviceData={getDeviceData(labels.ont_oper_data)}
            accordTitle={labels.ont_oper_data}
          />
          <GponDictDetails
            deviceData={getDeviceData(labels.ont_ethernet_port_oper_data)}
            accordTitle={labels.ont_ethernet_port_oper_data}
          />
          <GponDictDetails
            deviceData={getDeviceData(labels.pon_port_oper_data)}
            accordTitle={labels.pon_port_oper_data}
          />
          <GponDictDetails
            deviceData={getDeviceData(labels.olt_oper_data)}
            accordTitle={labels.olt_oper_data}
          />
        </Modal.Body>
        <Divider />
        <Modal.Footer>
          <button className="sPopCloseBtn" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default GponDictionary;
