import { createSlice } from "@reduxjs/toolkit";
import appConst from "../../../topology/constants/appConst";

const slice = createSlice({
  name: "nodeGeneral",
  initialState: {
    nodeTopologyTab: appConst.node,
  },
  reducers: {
    setNodeTopologyTab: (state, action) => {
      state.nodeTopologyTab = action.payload;
    },
  },
});

export const { setNodeTopologyTab } = slice.actions;
export default slice.reducer;
