import React, { useState } from "react";
import { Button } from "react-bootstrap";

import { GetTechInfo } from "../../../get-api/GetResponse";
import SeniorTechDetailsScreen from "./seniorTech/SeniorTechDetailsScreen";
import ViewTechDetails from "./technician/ViewTechDetails";
import ViewSeniorDetails from "./seniorTech/ViewSeniorDetails";
import AppLabels from "../../../constants/App_Labels";

interface Props {
  data: any;
  setOpenForm: any;
}

export default function ViewSeniorReferral({ data, setOpenForm }: Props) {
  const labels = AppLabels();
  const isCurrentTech =
    GetTechInfo()?.techId === data?.formData?.referredSeniorTechID;
  const isSeniorFormComplete = data?.formData?.referralStatus;
  const [showCompleteSeniorForm, setShowCompleteSeniorForm] = useState(false);

  return (
    <div className="viewSRMainContainer">
      {showCompleteSeniorForm ? (
        <SeniorTechDetailsScreen
          data={data}
          setOpenForm={setOpenForm}
          isSeniorFormComplete={isSeniorFormComplete}
        />
      ) : (
        isCurrentTech && (
          <>
            <div className="seniorFormBtnContainer">
              <Button
                onClick={() => setShowCompleteSeniorForm(true)}
                className="seniorFormBtn"
              >
                {isSeniorFormComplete
                  ? labels.Update + " "
                  : labels.Complete + " "}
                {labels.Senior_Form}
              </Button>
            </div>
            {isSeniorFormComplete && <ViewSeniorDetails data={data} />}
          </>
        )
      )}
      <ViewTechDetails data={data} />
    </div>
  );
}
