import { FeedbackComments } from "../../models/feedback/FeedbackComments";

export const FEEDBACK_COMMENTS_BEGIN = "FEEDBACK_COMMENTS_BEGIN";
export const FEEDBACK_COMMENTS_SUCCESS = "FEEDBACK_COMMENTS_SUCCESS";
export const FEEDBACK_COMMENTS_FAILURE = "FEEDBACK_COMMENTS_FAILURE";

interface feedbackCommentsBegin {
  type: typeof FEEDBACK_COMMENTS_BEGIN;
}

interface feedbackCommentsSuccess {
  type: typeof FEEDBACK_COMMENTS_SUCCESS;
  payload: FeedbackComments;
}

interface feedbackCommentsFailure {
  type: typeof FEEDBACK_COMMENTS_FAILURE;
  payload: Error;
}

export type FeedbackCommentsActionTypes =
  | feedbackCommentsBegin
  | feedbackCommentsSuccess
  | feedbackCommentsFailure;
