import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import ErrorBox from "../../components/reusable/ErrorBox";
import { getMultiTeleViewConfig } from "../../config/MultiAccConfig";
import AppLabels from "../../constants/App_Labels";
import "../../design-tokens/pingPage/pingPage.scss";
import { GetListAcc } from "../../get-api/GetResponse";
import { saveConfigData } from "../../store/ducksPattern/userPrefConfig";
import { getMultipleAccData } from "../../store/slices/ping/multiDeviceSearchAcc";
import storeConst from "../../topology/store/storeConst";
import MultiAccountPageDetails from "../ping-page/MultiAccountPageDetails";
import MultiDeviceAccTeleTab from "../ping-page/MultiDeviceAccTeleTab";
const MultiDeviceSearchAccPage = (props: any) => {
  const { location: search } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const labels = AppLabels();
  const [dataPing, setPingData] = useState(null);
  const isAccSearch = window.location.hash.includes("searchByAccountNumber");
  const listAcc = GetListAcc();

  const [responses, setResponses] = useState<string[]>([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [formattedData, setFormattedData] = useState("");
  const [statistics, setStatistics] = useState({});
  const [pingResponses, setPingResponses] = useState<string>("");
  const [statsObj, setStatsObj] = useState<any | null>(null);
  const [searchAccValue, setsearchAccValue] = useState<string | null>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (listAcc?.status === storeConst.ERROR && listAcc?.data === undefined) {
      setErrorMsg(labels.nodeNAErr);
      setOpen(true);
    } else if (
      listAcc?.status === "OK" &&
      JSON.stringify(listAcc?.data) === '[""]'
    ) {
      setErrorMsg(labels.nodeNAErr);
      setOpen(true);
    }
  }, [listAcc?.status]);
  useEffect(() => {
    let viewConfig = getMultiTeleViewConfig();
    dispatch(saveConfigData(viewConfig));
  }, []);

  useEffect(() => {
    const handleReceiveMessage = (event: MessageEvent) => {
      // Ensure the message is coming from the expected origin for security
      if (event.origin !== window.location.origin) return;

      if (event.data && event.data.searchMAValue && isAccSearch)
        setsearchAccValue(event.data.searchMAValue);
    };

    window.addEventListener("message", handleReceiveMessage);
    return () => {
      window.removeEventListener("message", handleReceiveMessage);
    };
  }, []);
  useEffect(() => {
    if (searchAccValue != null && isAccSearch) {
      dispatch(getMultipleAccData(searchAccValue));
    }
  }, [searchAccValue]);

  return (
    <>
      <div>
        <div>
          <MultiAccountPageDetails />
          <MultiDeviceAccTeleTab />
        </div>
        <div>
          <ErrorBox
            open={open}
            error={errorMsg}
            setOpen={setOpen}
            handleClose={() => {
              setOpen(false);
              history.replace(`/`);
            }}
          />
        </div>
      </div>
    </>
  );
};
export default MultiDeviceSearchAccPage;
