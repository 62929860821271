const ACCOUNT_SERVICE_URL = (window as any).config.ACCOUNT_SERVICE_URL;
const NETWORK_SERVICE_URL = (window as any).config.NETWORK_SERVICE_URL;
const TOPOLOGY_SERVICE_URL = (window as any).config.TOPOLOGY_SERVICE_URL;
const SYMBOLS_SERVICE_URL = (window as any).config.SYMBOLS_SERVICE_URL;
const F2_URL = (window as any).config.F2_URL;
const ELECTROLINE_URL = (window as any).config.ELECTROLINE_URL;

export const NetworkConfig = {
  ACCOUNT_SERVICE_URL,
  NETWORK_SERVICE_URL,
  TOPOLOGY_SERVICE_URL,
  SYMBOLS_SERVICE_URL,
  F2_URL,
  ELECTROLINE_URL,
};
