import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import AppLabels from "../../../constants/App_Labels";
import ConfigConst from "../../../constants/ConfigConst";
import {
  GetActiveAccountDetails,
  GetFormHistory,
  GetFormInfo,
  GetTechInfo,
} from "../../../get-api/GetResponse";
import FormClient from "../../../network/httpclient/form/FormClient";
import EmailClient from "../../../network/httpclient/notification/EmailClient";
import { sendEmailBegin } from "../../../store/ducksPattern/sendEmailData";
import CustomPopUp from "../../reusable/CustomPopUp";
import ExceptionBox from "../../reusable/ExceptionBox";
import { convertHTMLtoStr, isNull } from "../../reusable/Util";
import FormBoilerplate from "../FormBoilerplate";
import PrepareSREmailFormat from "./PrepareSREmailFormat";
import TechnicianDetailsScreen from "./technician/TechnicianDetailsScreen";
import srFormLabels from "./srFormLabels";
import { shallowEqual, useSelector } from "react-redux";
import EmailAuditClient from "../../../network/httpclient/EmailAudit/EmailAuditClient";

interface Props {
  setShowModal: any;
}

export default function SeniorReferralScreen({ setShowModal }: Props) {
  const labels = AppLabels();
  const srLabels = srFormLabels();
  const dispatch = useDispatch();
  const formHistory = GetFormHistory();
  const accountNumber = GetActiveAccountDetails()?.accountNumber;
  const formInfo = GetFormInfo();
  const isSeniorFormComplete = formHistory?.formData?.referralStatus;
  const techid = GetTechInfo()?.techId;
  const [isWOSelectionComplete, setIsWOSelectionComplete] = useState(false);
  const [woData, setWOData] = useState({});
  const [selectedWOID, setSelectedWOID] = useState(labels.Select);
  const [techDetails, setTechDetails] = useState({} as any);
  const profileInfo = GetTechInfo()?.data;
  const workOrderData = [labels.No_Work_Order, labels.Manual_Entry];
  const [showFlag, setShowFlag] = useState(false);
  const activeAccData = GetActiveAccountDetails()?.data;
  const {
    isLoading,
    status,
    formStatus,
    isFormLoading,
    refKey,
    EmailFormData,
    sentby,
  } = useSelector(
    (state: any) => ({
      isLoading: state.AccountState.sendEmailData.isLoading,
      status: state.AccountState.sendEmailData.status,
      formStatus: state.AccountState.FormInfo.status,
      isFormLoading: state.AccountState.FormInfo.isLoading,
      refKey: state.AccountState.FormInfo?.data?.id,
      EmailFormData: state.AccountState.FormInfo?.data?.formData,
      sentby: state.AccountState.FormInfo?.data?.technicianId,
    }),
    shallowEqual
  );
  const onSubmit = () => {
    const generateData = {
      formName: ConfigConst.SENIOR_REFERRAL,
      workorderId:
        selectedWOID === labels.No_Work_Order
          ? ConfigConst.NO_WORK_ORDER
          : selectedWOID,
      accountNumber,
      formData: JSON.stringify({ ...woData, ...techDetails }),
    };
    if (formHistory?.data?.id) {
      dispatch(FormClient.updateForm(generateData, formHistory?.data?.id));
    } else dispatch(FormClient.postForm(generateData));
    sendEmail();
  };

  const onPreview = () => {
    setShowFlag(true);
  };

  const sendEmail = () => {
    const emailSub =
      "Senior Referral Request: Referring Order: " +
      selectedWOID +
      " - " +
      labels.TECH_ID +
      ": " +
      profileInfo?.techId;
    const htmlMessage = convertHTMLtoStr(
      <PrepareSREmailFormat
        woID={isNull(selectedWOID)}
        techId={profileInfo?.techId}
        techName={profileInfo?.lanId}
        seniorReferralDetails={techDetails}
        address={activeAccData?.serviceAddress?.[0]}
        accountNumber={accountNumber}
        orderType={woData}
      />
    );
    let emailPayload = {
      accountNumber: accountNumber,
      franchise: "R",
      firstName: activeAccData?.firstName,
      lastName: activeAccData?.lastName,
      subject: emailSub,
      htmlMessage: htmlMessage,
      language: ConfigConst.SR_LANGUAGE,
      attachFileName: ConfigConst.SR_FILENAME,
      addresses: [
        profileInfo?.lanId,
        techDetails?.techManager,
        techDetails?.seniorTechnician,
      ],
      formName: ConfigConst.SENIOR_REFERRAL.toUpperCase()
    };
    const base64EncObj = { data: btoa(JSON.stringify(emailPayload)) };

    dispatch(EmailClient.sendEmailNoti(srLabels.SrEmailMSG, base64EncObj));
  };

  const disableSubmit = () => {
    if (isWOSelectionComplete && techDetails && !formInfo.isLoading) {
      return false;
    } else return true;
  };
  useEffect(() => {
    const generateEmailData = {
      componentType: ConfigConst.SENIOR_REFERRAL,
      refKey: refKey,
      sentTo: JSON.stringify([
        profileInfo?.lanId,
        techDetails?.techManager,
        techDetails?.seniorTechnician,
      ]),
      sentby: sentby,
      techid: techid,
    };

    // sendEmail();
    if (
      isLoading === false &&
      status === "OK" &&
      formStatus === "OK" &&
      isFormLoading === false &&
      EmailFormData !== undefined
    ) {
      if (formHistory?.data?.id) {
        dispatch(
          EmailAuditClient.updateForm(generateEmailData, formHistory?.data?.id)
        );
      } else dispatch(EmailAuditClient.postForm(generateEmailData));
    }
  }, [status, formStatus, isFormLoading]);
  return (
    <>
      <FormBoilerplate
        isWOSelectionComplete={isWOSelectionComplete}
        setIsWOSelectionComplete={setIsWOSelectionComplete}
        setWOData={setWOData}
        formLabel={labels.SR}
        formName={ConfigConst.SENIOR_REFERRAL}
        onSubmit={onSubmit}
        onPreview={onPreview}
        disableSubmit={disableSubmit}
        selectedWOID={selectedWOID}
        setSelectedWOID={setSelectedWOID}
        setShowEntireModal={setShowModal}
        disableFormUpdate={false}
        hideSubmitButton={isSeniorFormComplete}
        workOrderData={workOrderData}
      >
        {isSeniorFormComplete ? (
          <div className="exceptionBoxCont">
            <ExceptionBox
              headerText={labels.Completed_By_Senior_Tech}
              bodyText={labels.referralExceptionMessage}
            />
          </div>
        ) : (
          <TechnicianDetailsScreen setDetails={setTechDetails} />
        )}
      </FormBoilerplate>
      <CustomPopUp
        showVal={showFlag}
        headerText={labels.Email_Preview.toUpperCase()}
        bodyElement={
          <PrepareSREmailFormat
            woID={isNull(selectedWOID)}
            techId={profileInfo?.techId}
            techName={profileInfo?.lanId}
            seniorReferralDetails={techDetails}
            address={activeAccData?.serviceAddress?.[0]}
            accountNumber={accountNumber}
            orderType={woData}
          />
        }
        handleClose={() => setShowFlag(false)}
      />
    </>
  );
}
