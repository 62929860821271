import { IHPQrlnDataState } from "../../../../states/account/diagnostics/ihp/IHPQrlnDataState";
import {
  IHP_QRLN_DATA_BEGIN,
  IHP_QRLN_DATA_SUCCESS,
  IHP_QRLN_DATA_FAILURE,
  IhpQrlnDataActionTypes,
} from "../../../../types/account/diagnostics/ihp/IHPQrlnData";

const initialState: IHPQrlnDataState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function IHPQrlnDataReducer(
  state = initialState,
  action: IhpQrlnDataActionTypes
): IHPQrlnDataState {
  switch (action.type) {
    case IHP_QRLN_DATA_BEGIN:
      return {
        isLoading: true,
      };
    case IHP_QRLN_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case IHP_QRLN_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}
