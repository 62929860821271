import { Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import { InfoBox, InfoWindow, Marker } from "@react-google-maps/api";
import TopologySymbols from "../../../reusable/TopologySymbols";
import { BsArrowsMove } from "react-icons/bs";
import { markerToString } from "../../../../util/utilFuncs";
import colors from "../../../../config/colors";
import { mapElementStyles } from "../../../../config/mapItemsConfig";
import { useEffect, useState } from "react";


interface Props {
  ampBlockData: any;
  click: any;
  setClick: any;
}

const RfAmpBlocks = ({ ampBlockData, click, setClick }: Props) => {
  const latLng = new google.maps.LatLng(
    ampBlockData?.geometry?.coordinates?.[1],
    ampBlockData?.geometry?.coordinates?.[0]
  );
  const lat=ampBlockData?.geometry?.coordinates?.[1];
  const lng=ampBlockData?.geometry?.coordinates?.[0];
  const [showInfoBox, setShowInfoBox] = useState(false);
  const position = {
    lat: Number(ampBlockData?.geometry?.coordinates?.[1]),
    lng: Number(ampBlockData?.geometry?.coordinates?.[0]),
  };
  const divStyle = {
    background: `white`,
  };
  const showInfoWindow = () => {
    setClick(false);
  };
  const onCloseClick = () => {
    setClick(false);
  };
  useEffect(() => {
    setShowInfoBox(true);
  }, []);

  // INFO13 TABLE OR RF_AMP_BLOCK
  return (
    <div onClick={showInfoWindow}>
      {click && (
        <InfoWindow
          position={position}
          options={{ pixelOffset: new google.maps.Size(100, 0) }}
          onCloseClick={onCloseClick}
        >
          <div style={divStyle}>
            <TopologySymbols
              name="rf_amp_block"
              color={`var(--iconColor)`}
              size={150}
            />
          </div>
        </InfoWindow>
      )}
      <Marker
      position={{lat,lng }}
      icon={{
        url: markerToString(
          <BsArrowsMove color={colors.greyLight} size={20} />
        ),
        anchor:new google.maps.Point(mapElementStyles?.common?.anchor?.x - 120,
          mapElementStyles?.common?.anchor?.y + 3)
      }}
      draggable
      cursor="move"
      zIndex={4000}
    >
     {showInfoBox &&
      <InfoBox
        position={latLng}
        options={{
          closeBoxURL: "",
          disableAutoPan: true,
          boxStyle: {
            width: "auto",
            padding: "5px",
          },
        }}
      >
        <Table className="annotationBlock">
          <TableBody>
            <TableRow>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.DVC_ID}
              </TableCell>
              <TableCell colSpan={2}>
                {ampBlockData?.properties?.data?.tagText?.PART_NO}
              </TableCell>
              <TableCell colSpan={2}>
                {ampBlockData?.properties?.data?.tagText?.FT2LAST}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.FPAD_TXT}
              </TableCell>
              <TableCell colSpan={2}>
                {ampBlockData?.properties?.data?.tagText?.FEQ_TXT}
              </TableCell>
              <TableCell colSpan={2}>
                {ampBlockData?.properties?.data?.tagText?.SPLIT1_TXT}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.INP_D1}
              </TableCell>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.INP_D2}
              </TableCell>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.INP_D3}
              </TableCell>
              <TableCell colSpan={2}>
                {ampBlockData?.properties?.data?.tagText?.PS_ID}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.OUT_D1}
              </TableCell>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.OUT_D2}
              </TableCell>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.OUT_D3}
              </TableCell>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.VOLTS}
              </TableCell>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.PWR_DRCT}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.RTN_R1}
              </TableCell>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.RTN_R2}
              </TableCell>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.RPAD_TXT}
              </TableCell>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.SPARE}
              </TableCell>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.CASCADE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.RTN_OUT_R1}
              </TableCell>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.RTN_OUT_R2}
              </TableCell>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.REQ_TXT}
              </TableCell>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.SPARE}
              </TableCell>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.DGN_FREQ3}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </InfoBox>}
      </Marker>
    </div>
  );
};

export default RfAmpBlocks;
