import { GponNodeSummary } from "../../models/gpon/GponNodeSummary";
import { GponNodeSummaryState } from "../../states/gpon/GponNodeSummary";
import {
    SAVE_GPON_NODES,
    INCREMENT_GPON_NODE_API_CALL_COUNTER,
    GponNodesActionTypes,
    DECREMENT_GPON_NODE_API_CALL_COUNTER,
    RESET_GPON_NODES,
    UPDATE_GPON_NODES,
} from "../../types/gpon/GponNodeSummary";

const initialState: GponNodeSummaryState = {
    nodeSummaryList: [],
};

export function GponNodeSummaryReducer(
    state = initialState,
    action: GponNodesActionTypes
): GponNodeSummaryState {
    switch (action.type) {
        case SAVE_GPON_NODES:
            return {
                ...state,
                nodeSummaryList: action.payload,
            };
        case INCREMENT_GPON_NODE_API_CALL_COUNTER:
            return {
                ...state,
                nodeSummaryList: state.nodeSummaryList.map((d: GponNodeSummary) => {
                    if (d.serialNumber === action.snNumber) {
                        let tempData: any = d.data;
                        if (action.subDataKey === "summary") {
                            tempData = {
                                ...tempData,
                                ...{
                                    summary: {
                                        isLoading: true,
                                        data: undefined,
                                        status: "",
                                    },
                                },
                            };
                        }
                        return {
                            ...d,
                            ...{ data: tempData },
                        };
                    } else return d;
                }),
            };
        case DECREMENT_GPON_NODE_API_CALL_COUNTER:
            return {
                ...state,
                nodeSummaryList: state.nodeSummaryList.map((d: GponNodeSummary) => {
                    if (d.serialNumber === action.snNumber) {
                        let tempData: any = d.data;
                        if (action.subDataKey === "summary") {
                            let tempSummaryData = tempData?.summary;
                            tempSummaryData = {
                                ...tempSummaryData,
                                ...{
                                    isLoading: false,
                                },
                            };
                            tempData = { ...tempData, ...{ summary: tempSummaryData } };
                        }
                        return {
                            ...d,
                            ...{ data: tempData },
                        };
                    } else return d;
                }),
            };
        case UPDATE_GPON_NODES:
            return {
                ...state,
                nodeSummaryList: state.nodeSummaryList.map((d: GponNodeSummary) => {
                    if (d.serialNumber === action.snNumber) {
                        let tempData: any = d.data;
                        if (action.subdataKey === "summary") {
                            let tempSummaryData = tempData?.summary;
                            tempSummaryData = {
                                ...tempSummaryData,
                                ...{
                                    data: action.data,
                                    status: action.status,
                                },
                            };
                            tempData = { ...tempData, ...{ summary: tempSummaryData } };
                        }
                        return { ...d, ...{ data: tempData } };
                    } else {
                        return d;
                    }
                }),
            };
        case RESET_GPON_NODES:
            return {
                ...state,
                nodeSummaryList: [],
            };
        default:
            return state;
    }
}
