import { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";

import {
  getElementStyle,
  getMDUElementStyle,
  isMarkersInsideViewPort,
} from "../utilFuncs";
import {
  setSelectedData,
  setUpdatedData,
} from "../../store/slices/mapItems/microreflections/mrGroupedIssues";
import {
  zoomLevelRenderActives,
  zoomLevelRenderMarker,
} from "../../config/mapConfig";
import { getTopoConfig } from "../../store/slices/topologyConfig";
import {
  GetMapDetailsProps,
  GetNodeSiteDetailsProps,
  GetTopoGeneralProps,
} from "../reduxFunctions/getTopologyState";
import { TOPOLOGY_FILTERS_KEY } from "../../constants/storageConst";
import {
  setMapCenter,
  setMapFilters,
  setSelectedMapElement,
} from "../../store/slices/mapItems/mapDetails";
import appConst from "../../constants/appConst";
import { getTechRoles } from "../../../components/reusable/NodeUtil";
import ConfigConst from "../../../constants/ConfigConst";

/* export const useFeaturesData1 = (featuresAll: any, currentViewBDY: any) => {
  const [newFeatures, setNewFeatures] = useState<Array<any>>([]);
  const [featuresWM, setFeaturesWM] = useState<Array<any>>([]);

  //only show element for current view boundaries at certain zoomLevel
  useEffect(() => {
    if (featuresAll?.length > 0) {
      const currentNewFeatures = isMarkersInsideViewPort(
        featuresWM,
        currentViewBDY,
        featuresAll
      );
      setNewFeatures(currentNewFeatures);
      setFeaturesWM([...featuresWM, ...currentNewFeatures]);
    }
  }, [currentViewBDY]);

  return { newFeatures: newFeatures, allFeatures: featuresWM };
}; */

export const useFeaturesData = (
  featuresAll: any,
  currentViewBDY: any,
  zoomLevel: any,
  checkZoomLevel = true // pass false if you dont want to check the zoom level
) => {
  const [featuresWM, setFeaturesWM] = useState<Array<any>>([]);
  const newFeatures = useRef<Array<any>>([]);
  const isZoom = checkZoomLevel ? zoomLevel >= zoomLevelRenderMarker : true; //is zoom check optional?

  //only show element for current view boundaries at certain zoomLevel
  useEffect(() => {
    if (isZoom && featuresAll?.length > 0 && currentViewBDY) {
      const currentNewFeatures = isMarkersInsideViewPort(
        featuresWM,
        currentViewBDY,
        featuresAll
      );
      newFeatures.current = currentNewFeatures;
      setFeaturesWM([...featuresWM, ...currentNewFeatures]);
    }
  }, [currentViewBDY]);

  return {
    newFeatures: newFeatures?.current,
    allFeatures: featuresWM,
  };
};
export const useFeaturesDataAt18 = (
  featuresAll: any,
  currentViewBDY: any,
  zoomLevel: any
) => {
  const [featuresWM, setFeaturesWM] = useState<Array<any>>([]);
  const newFeatures = useRef<Array<any>>([]);

  //only show element for current view boundaries at certain zoomLevel
  useEffect(() => {
    if (
      zoomLevel >= zoomLevelRenderActives &&
      featuresAll?.length > 0 &&
      currentViewBDY
    ) {
      const currentNewFeatures = isMarkersInsideViewPort(
        featuresWM,
        currentViewBDY,
        featuresAll
      );
      newFeatures.current = currentNewFeatures;
      setFeaturesWM([...featuresWM, ...currentNewFeatures]);
    }
  }, [currentViewBDY]);

  return {
    newFeatures: newFeatures?.current,
    allFeatures: featuresWM,
  };
};

export const useElementToggelingAndStyle = (
  gMap: any,
  mapFilters: any,
  zoomLevel: any,
  pngImages: any
) => {
  useEffect(() => {
    if (gMap && mapFilters) {
      gMap?.data.setStyle((feature: any) => {
        return getElementStyle(feature, zoomLevel, mapFilters, pngImages);
      });
    }
  }, [mapFilters, gMap]);

  useEffect(() => {
    if (gMap && zoomLevel && mapFilters) {
      switch (zoomLevel) {
        case 17:
        case 18:
        case 19:
        case 20:
        case 21:
          gMap?.data.setStyle((feature: any) => {
            return getElementStyle(feature, zoomLevel, mapFilters, pngImages);
          });
          break;
        default:
          break;
      }
    }
  }, [zoomLevel]);
};

export const MicroReflectionsGroupedIssuesData = (
  data: any,
  selectedIndex: number,
  samKeyAddressData: any,
  dispatch: any
) => {
  //set selected row data in store
  useEffect(() => {
    if (data && selectedIndex !== -1) {
      dispatch(setSelectedData(data?.[selectedIndex]?.cmWrapper));
    }
  }, [data]);

  // update grouped issues data with addresses
  useEffect(() => {
    if (samKeyAddressData) {
      let tempGroupArr: any = [];
      let tempData = JSON.parse(JSON.stringify(data));
      let tempDataRow = tempData?.[selectedIndex];
      tempDataRow?.cmWrapper?.map((e: any, i: number) => {
        const samKeyAddress = samKeyAddressData?.features?.filter(
          (samAddress: any) =>
            Number(e?.samKey) === samAddress?.properties?.data?.samKey
        );
        tempGroupArr?.push({
          ...e,
          address: samKeyAddress?.[0],
        });
      });
      tempDataRow = { ...tempDataRow, cmWrapper: tempGroupArr };
      tempData[selectedIndex] = tempDataRow;

      dispatch(setUpdatedData(tempData));
    }
  }, [samKeyAddressData]);
};

export const useOnTopologyModuleMount = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTopoConfig());
  }, []);
};

export const useTopologySymbolsFilters = (
  topoEleFilters: any,
  setTopoEleFilters: (arg0: any) => void
) => {
  const dispatch = useDispatch();

  //preselect filters based on user's historic selection
  useEffect(() => {
    const storageResult = localStorage.getItem(TOPOLOGY_FILTERS_KEY);
    if (storageResult && topoEleFilters) {
      const parsedSR = JSON.parse(storageResult);
      topoEleFilters?.map((cat: any, catIndex: number) => {
        const fcItems = cat?.items?.length;
        let fcActiveItems = 0;
        cat?.items?.map((e: any) => {
          if (parsedSR?.includes(e.key)) {
            e.isActive = true;
            fcActiveItems++;
          } else e.isActive = false;
        });
        if (fcActiveItems === fcItems)
          topoEleFilters[catIndex].isAllSelected = true;
      });
      setTopoEleFilters([...topoEleFilters]);
    }
  }, []);

  useEffect(() => {
    let filterData: any = {};
    topoEleFilters?.map((cat: any) =>
      cat?.items?.map((e: any) => (filterData[e.key] = e.isActive))
    );
    dispatch(setMapFilters(filterData));
  }, [topoEleFilters]);
};

export const useMduElementStyle = (
  gMap: any,
  zoomLevel: any,
  pngImages: any
) => {
  useEffect(() => {
    if (gMap) {
      gMap?.data.setStyle((feature: any) => {
        return getMDUElementStyle(feature, zoomLevel, pngImages);
      });
    }
  }, [gMap]);

  useEffect(() => {
    if (gMap && zoomLevel) {
      switch (zoomLevel) {
        case 17:
        case 18:
        case 19:
        case 20:
        case 21:
          gMap?.data.setStyle((feature: any) => {
            return getMDUElementStyle(feature, zoomLevel, pngImages);
          });
          break;
        default:
          break;
      }
    }
  }, [zoomLevel]);
};

export const useTraceElementsData = (
  allNodeData: any,
  traceData: any,
  rootNodeSiteId: any
) => {
  const streamTraceData: any = useMemo(() => {
    const index = allNodeData?.findIndex(
      (e: any) => e.nodeSiteId === String(rootNodeSiteId)
    );
    let traceElements: any = {};
    if (index !== -1) {
      const rootNodeData: any = allNodeData[index];
      traceData?.map((e: any) => {
        const data = rootNodeData?.[e.type];
        const features = data?.data?.features;
        const dataIndex = features?.findIndex(
          (f: any) =>
            f.properties?.data?.networkId === e?.networkId ||
            f.properties?.data?.id === e?.networkId
        );
        if (dataIndex !== -1 && dataIndex !== undefined) {
          if (traceElements?.hasOwnProperty(e.type)) {
            traceElements = {
              ...traceElements,
              [e.type]: {
                ...traceElements?.[e.type],
                features: [
                  ...traceElements?.[e.type]?.features,
                  features?.[dataIndex],
                ],
              },
            };
          } else {
            traceElements = {
              ...traceElements,
              [e.type]: {
                type: "FeatureCollection",
                features: [features?.[dataIndex]],
              },
            };
          }
        }
      });
    }
    return traceElements;
  }, [traceData, allNodeData]);

  return streamTraceData;
};

export const useTracePointsData = (
  allNodeData: any,
  traceData: Array<any>,
  currentViewBDY: any
) => {
  const traceElements = useMemo(() => {
    let pointsData: any = {};
    const newData: Array<any> = traceData;
    const uniquePayload = newData?.filter(
      (item, index, self) =>
        item.geometry?.type === appConst.Point &&
        index === self?.findIndex((t) => t.networkId === item.networkId)
    );

    allNodeData?.map((nodeData: any) => {
      uniquePayload?.map((pointData: any) => {
        const data = nodeData?.[pointData.type];
        const features = data?.data?.features;
        const dataIndex = features?.findIndex(
          (f: any) =>
            f.properties?.data?.networkId === pointData?.networkId ||
            f.properties?.data?.id === pointData?.networkId
        );
        if (dataIndex === -1 || dataIndex === undefined) {
          if (pointsData?.hasOwnProperty(pointData.type)) {
            pointsData = {
              ...pointsData,
              [pointData.type]: [...pointsData?.[pointData.type], pointData],
            };
          } else {
            pointsData = {
              ...pointsData,
              [pointData.type]: [pointData],
            };
          }
        }
      });
    });
    return pointsData;
  }, [currentViewBDY]);

  return traceElements;
};

export const useTapAddressTraceData = (allNodeData: any, traceData: any) => {
  const tapAddressTraceData: any = useMemo(() => {
    let updatedData: any = [];

    allNodeData?.map((nodeData: any) => {
      const taps = nodeData?.[appConst.taps];
      const rfDropAdresses = nodeData?.[appConst.rfdropaddress];

      traceData?.map((d: any) => {
        const tapIndex = taps?.data?.features?.findIndex(
          (f: any) => f?.properties?.data?.networkId === d?.tapNetworkId
        );
        const rfDropAdressIndex = rfDropAdresses?.data?.features?.findIndex(
          (f: any) => f?.properties?.data?.networkId === d?.networkId
        );

        updatedData.push({
          ...d,
          tapFeatureData: taps?.data?.features?.[tapIndex],
          rfDropAddressFeatureData:
            rfDropAdresses?.data?.features?.[rfDropAdressIndex],
        });
      });
    });

    return updatedData;
  }, [traceData, allNodeData]);

  return tapAddressTraceData;
};

export const useMemoizedShowSwitch = ({ configData, techData, lanId }: any) => {
  const showSwitch = useMemo(() => {
    const list = configData?.TOPOLOGY_ACCESS_LIST?.toLowerCase();
    if (
      list?.includes(appConst.accessToAll.toLowerCase()) &&
      getTechRoles(techData, ConfigConst.TOPOLOGY)
    )
      return true;
    else if (
      list?.includes(appConst.allExceptIntServTech.toLowerCase()) &&
      getTechRoles(techData, ConfigConst.TOPOLOGY_EXCEPT_INT_SERV_TECH)
    )
      //switch logic to allow/disallow topology view from internal service techs
      return true;
    if (list?.includes(lanId?.toLowerCase())) return true;
    return false;
  }, [configData, techData, lanId]);

  return showSwitch;
};

export const useVhubData = (vhubFlag: boolean): any => {
  const dispatch = useDispatch();
  const { allNodeData } = GetNodeSiteDetailsProps();
  const { activeNodeId } = GetTopoGeneralProps();
  const ND = allNodeData?.findIndex((e:any) => activeNodeId === e?.nodeSiteId);
  const features = ND != -1 && allNodeData?.[ND]?.vhub?.data?.features?.[0];
  const flag = useMemo(() => {
    if (features && features !== undefined && vhubFlag) {
      const geoData = {
        lat: features.geometry?.coordinates?.[1],
        lng: features.geometry?.coordinates?.[0],
      };
      const activeElement = {
        geometry: geoData,
        properties: {
          id: features.featureId,
          type: appConst?.vhub,
          data: features.properties?.data,
        },
      };
      dispatch(setSelectedMapElement(activeElement));
      dispatch(setMapCenter(geoData));
      return true;
    } else {
      return false;
    }
  }, [features]);
  return flag;
};
