import {
    ATTACHMENT_BEGIN,
    ATTACHMENT_FAILURE,
    ATTACHMENT_SUCCESS,
    AttachmentActionTypes
  } from "../../types/feedback/FeedbackAttachment"

export function AttachmentBegin(): AttachmentActionTypes {
    return {
      type: ATTACHMENT_BEGIN,
    };
  }
  
  export function AttachmentSuccess(
    activeCerti: any
  ): AttachmentActionTypes {
    return {
      type: ATTACHMENT_SUCCESS,
      payload: activeCerti,
    };
  }
  
  export function AttachmentFailure(
    error: Error
  ): AttachmentActionTypes {
    return {
      type: ATTACHMENT_FAILURE,
      payload: error,
    };
  }