import { MdContentCopy } from "react-icons/md";
import AppLabels from "../../constants/App_Labels";
import CustomPopOver from "./CustomPopOver";

const CopyToClipboard = (props: any) => {
  const { dataString } = props;
  const labels = AppLabels();
  const handleCopyToClipboard = () => {
    window.navigator.clipboard
      .writeText(dataString)
      .then(() => {
        //success
      })
      .catch((err) => {
        console.log("Something went wrong" + err);
      });
  };
  return (
    <CustomPopOver
      position="top"
      popOverData={labels.COPY}
      originComp={
        <MdContentCopy
          className="copyClipboardBtn"
          onClick={() => handleCopyToClipboard()}
        />
      }
      index="popOver"
    />
  );
};

export default CopyToClipboard;
