import { useState } from "react";
import { useDispatch } from "react-redux";
import { FaSearch } from "react-icons/fa";

import TopologyLabels from "../../../constants/topologyLabels";
import {
  setMapCenter,
  setSearchedAddress,
} from "../../../store/slices/mapItems/mapDetails";
import { getActiveEleMarker } from "../../../util/utilFuncs";
import InlineErrorMessage from "../../../../components/reusable/InlineErrorMessage";

export default function Coordinates() {
  const dispatch = useDispatch();
  const labels = TopologyLabels();
  const [value, setValue] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const onSearch = () => {
    const latLngArr = value.split(", ");
    const lat = Number(latLngArr?.[0]);
    const lng = Number(latLngArr?.[1]);

    if (
      lat <= 90 &&
      lat >= -90 &&
      lng >= -180 &&
      lng <= 180 &&
      typeof lat == "number" &&
      typeof lng == "number"
    ) {
      dispatch(setMapCenter({ lat, lng }));
      setValue("");
      setErrorMsg("");

      let activeElement = getActiveEleMarker({ lat, lng });
      dispatch(setSearchedAddress(activeElement));
    } else {
      setErrorMsg(labels.Coordinates_Error);
    }
  };

  const onKeyboardKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") onSearch();
  };

  return (
    <div className="coordSearchCont">
      <section>
        <input
          type="text"
          title="Search by coordinates"
          value={value}
          placeholder="43.67812896229174, -79.33322046307663"
          onChange={(val: any) => {
            setValue(val.target.value);
          }}
          onKeyPress={(event: any) => onKeyboardKeyPress(event)}
        />
        <button onClick={() => onSearch()}>
          <FaSearch />
        </button>
      </section>
      <InlineErrorMessage errorMessage={errorMsg} />
    </div>
  );
}
