import { Marker } from "@react-google-maps/api";
import React, { useEffect, useMemo, useState } from "react";
import { renderToString } from "react-dom/server";
import { useDispatch } from "react-redux";

import CmDsChart from "../../../../components/modem/CmDsChart";
import { getMinMaxRange } from "../../../../components/reusable/Util";
import { mapElementStyles } from "../../../config/mapItemsConfig";
import appConst from "../../../constants/appConst";
import {
  setHighlightedPolygon,
  setSelectedMapElement,
  setShowChart,
} from "../../../store/slices/mapItems/mapDetails";
import {
  GetMapDetailsProps,
  GetMapDrawerProps,
  GetTelemetryProps,
} from "../../../util/reduxFunctions/getTopologyState";
import {
  isCoordsAvaileable,
  applyDiagAdvancedFilters,
  getCMStatusProps,
  getBasicAddressFormat,
} from "../../../util/utilFuncs";
import TopologySymbols from "../../reusable/TopologySymbols";
import DraggableComp from "../../../../components/reusable/DraggableComp";
import ClusterCMMarker from "../../reusable/ClusterCMMarker";
import ActiveCMClusterTable from "../../reusable/ActiveCMClusterTable";
import { DrawerViewType } from "../../../store/reduxTypes";
import { setDrawerView } from "../../../store/slices/mapDrawer";
import { HoverOverInter } from "../MapItems";

interface Props {
  setHoverOverProps: (arg0: HoverOverInter | null) => void;
}

const CmMarkers = ({ setHoverOverProps }: Props) => {
  const dispatch = useDispatch();
  const { mlData, advancedFilters, advFCond } = GetTelemetryProps();
  const { showChart, activeMapElement } = GetMapDetailsProps();
  const { drawerView } = GetMapDrawerProps();
  const [activeCmData, setActiveCmData] = useState<any>();
  const [showCluster, setShowCluster] = useState<boolean>(false);
  const [isChartExpand, setIsChartExpand] = useState<boolean>(false);
  const [activeClusterData, setActiveClusterData] = useState<any>();
  const [statusFilters, setStatusFilters] = useState<any>([]);

  const showClusteredMarkers = (clusterData: any, data: any) => {
    //status filter data
    const statusFilterArr: any = [];
    data?.map((e: any) => {
      statusFilterArr.push({
        key: e?.name,
        iconName: e?.name,
        iconColor: e?.color,
        value: true,
      });
    });
    setStatusFilters(statusFilterArr);
    //===================================
    if (clusterData && clusterData?.modems?.length > 0) {
      setShowCluster(true);
      let sortedCMList = clusterData?.modems?.sort(
        (a: any, b: any) =>
          a?.serviceAddress?.aptNumber - b?.serviceAddress?.aptNumber
      );
      setActiveClusterData({
        lat: clusterData?.lat,
        lng: clusterData?.lng,
        modems: sortedCMList,
      });
    }
  };

  const showDiagDeviceCharts = (
    deviceData: any,
    isFromClusterMarker: boolean = false
  ) => {
    if (deviceData) {
      let cmData = deviceData?.deviceDiag?.data;
      let dsChartRanges = getMinMaxRange(cmData?.docsis, "ds");
      let cmChartData = {
        coordinate: deviceData?.coordinate,
        scQamChs: cmData?.docsis?.docsis30?.downstream?.channels,
        chartRanges: dsChartRanges,
        ofdmChs: cmData?.docsis?.ofdm?.downstreamChannels,
      };
      setActiveCmData(cmChartData);
      // if (!isFromClusterMarker) dispatch(setShowChart(true));
    }
  };

  const showDiagDeviceDetails = (
    deviceData: any,
    isFromClusterMarker: boolean
  ) => {
    if (deviceData) {
      const activeElement = {
        geometry: deviceData?.coordinate,
        properties: { type: appConst.CM, data: deviceData },
      };
      if (isFromClusterMarker) {
        dispatch(setHighlightedPolygon(deviceData));
      }
      dispatch(setSelectedMapElement(activeElement));
    }
  };

  // const hideChart = () => {
  //   dispatch(setShowChart(false));
  // };

  const hideClusterPopUp = () => {
    setShowCluster(false);
  };

  const handleCmDeviceClick = (deviceData: any) => {
    if (drawerView != "standard")
      dispatch(setDrawerView("standard" as DrawerViewType));
    // hideChart();
    if (showCluster) setShowCluster(false);
    //showDiagDeviceCharts(deviceData, false);
    showDiagDeviceDetails(deviceData, false);
  };

  const handleClusterDeviceClick = (deviceData: any) => {
    if (drawerView != "standard")
      dispatch(setDrawerView("standard" as DrawerViewType));
    // hideChart();
    //showDiagDeviceCharts(deviceData, true);
    showDiagDeviceDetails(deviceData, true);
    // const interval = setInterval(() => {
    //   dispatch(setShowChart(true));
    //   clearInterval(interval);
    // }, 0);
  };

  useEffect(() => {
    if (
      activeMapElement?.properties?.type === appConst.CM &&
      activeMapElement?.properties?.data
    ) {
      showDiagDeviceCharts(activeMapElement?.properties?.data);
    }
  }, [activeMapElement]);

  const cmMarkerData = useMemo(() => {
    let groupedCMList: any = [];
    if (mlData && mlData?.length > 0) {
      mlData?.map((cm: any) => {
        if (
          cm?.deviceDiag &&
          applyDiagAdvancedFilters(
            cm?.deviceDiag?.data,
            advancedFilters,
            advFCond
          )
        ) {
          const geoData = isCoordsAvaileable(cm);
          if (geoData && cm?.deviceDiag) {
            let groupIndex = groupedCMList?.findIndex(
              (cmGroup: any) =>
                cmGroup?.lat === cm?.coordinate?.lat &&
                cmGroup?.lng === cm?.coordinate?.lng
            );
            if (groupIndex >= 0) {
              groupedCMList[groupIndex].modems.push(cm);
            } else {
              groupedCMList.push({
                lat: cm?.coordinate?.lat,
                lng: cm?.coordinate?.lng,
                modems: [cm],
              });
            }
          }
        }
      });
    }
    return groupedCMList;
  }, [mlData, advancedFilters]);

  const setHoverOverData = (device: any) => {
    setHoverOverProps({
      position: device?.coordinate,
      compToRender: (
        <span>{getBasicAddressFormat(device?.serviceAddress)}</span>
      ),
      options: { pixelOffset: new google.maps.Size(0, -35) },
    });
  };

  return (
    <>
      {cmMarkerData && cmMarkerData?.length > 0 && (
        <>
          {cmMarkerData?.map((cmGroup: any) => {
            if (cmGroup && cmGroup?.modems?.length === 1) {
              let device = cmGroup?.modems?.[0];
              const iconSize = mapElementStyles?.cableModem?.iconSize;
              const cmProps = getCMStatusProps(device?.deviceDiag);
              const cmMarker = renderToString(
                <TopologySymbols
                  name={cmProps.iconName}
                  color={cmProps.color}
                  size={iconSize}
                />
              );

              return (
                <Marker
                  key={device?.serialNumber}
                  position={device?.coordinate}
                  onMouseOver={() => setHoverOverData(device)}
                  onMouseOut={() => setHoverOverProps(null)}
                  icon={{
                    url:
                      "data:image/svg+xml;charset=utf-8," +
                      encodeURIComponent(cmMarker),
                    anchor: new google.maps.Point(iconSize / 2, iconSize * 1.5),
                  }}
                  zIndex={1000}
                  onClick={() => handleCmDeviceClick(device)}
                />
              );
            } else if (cmGroup && cmGroup?.modems?.length > 1) {
              return (
                <ClusterCMMarker
                  key={`cluster${cmGroup?.lat}${cmGroup?.lng}`}
                  clusterData={cmGroup}
                  lat={cmGroup?.lat}
                  lng={cmGroup?.lng}
                  showClusteredMarkers={showClusteredMarkers}
                />
              );
            }
          })}
        </>
      )}
      <ActiveCMClusterTable
        showCluster={showCluster}
        activeClusterData={activeClusterData}
        advancedFilters={advancedFilters}
        advFCond={advFCond}
        hideClusterPopUp={hideClusterPopUp}
        statusFilters={statusFilters}
        setStatusFilters={setStatusFilters}
        handleOnClick={handleClusterDeviceClick}
      />
      <DraggableComp style={{ top: 5, left: 5 }}>
        {activeCmData && activeCmData?.chartRanges && (
          <div
            className="chartPopUpCont"
            style={{
              minWidth: "45vw",
              maxHeight: isChartExpand ? "80vh" : "40vh",
            }}
          >
            <CmDsChart
              cardLabel={"DS Chart".toUpperCase()}
              chartRanges={activeCmData?.chartRanges}
              scQamChs={activeCmData?.scQamChs}
              ofdmChs={activeCmData?.ofdmChs}
              showChart={true}
              isExpand={isChartExpand}
              setExpand={(val: boolean) => setIsChartExpand(val)}
              onHideChart={() => setActiveCmData(null)}
            />
          </div>
        )}
      </DraggableComp>
    </>
  );
};

export default React.memo(CmMarkers);
