import {
  formatCamelCase,
  getValueByStrMapping,
  isNull,
} from "../../../components/reusable/Util";
import appConst from "../../constants/appConst";

interface props {
  tableConfig: any;
  eleData: any;
}

const MapEleDataDisplay = ({ tableConfig, eleData }: props) => {
  return (
    <table className="dataTableDetails">
      <tr>
        {tableConfig?.hasOwnProperty("headers") &&
        tableConfig?.headers?.length !== 0 ? (
          tableConfig?.headers?.map((h: any, i: number) => {
            return (
              <th
                key={i}
                className={tableConfig?.headers?.length <= 2 ? "fixedCol" : ""}
              >
                {h?.toUpperCase()}
              </th>
            );
          })
        ) : (
          <></>
        )}
      </tr>
      <tbody>
        {Object.keys(tableConfig?.dataKeys)?.map((key: any, i: number) => {
          return (
            <tr key={i}>
              <td>
                {tableConfig?.name === appConst?.cpat
                  ? key
                  : formatCamelCase(key)}
              </td>
              <td>
                {isNull(
                  getValueByStrMapping(eleData, tableConfig?.dataKeys[key])
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default MapEleDataDisplay;
