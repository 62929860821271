import React, { useState } from "react";
import { AppBar, Tabs, Tab, Box } from "@material-ui/core";
import AppLabels from "../../constants/App_Labels";
import { useDispatch } from "react-redux";
import NodeOverView from "../../components/overview/node/NodeOverView";
import { GetTechInfo, GetNodeInfoDetails } from "../../get-api/GetResponse";
import { NodeTelemetryTab } from "../../components/telemetry/NodeTelemetryTab";
import { getTechRoles } from "../../components/reusable/NodeUtil";
import { Certifications } from "../../components/certifications/Certifications";
import { OdmCertification } from "../../components/certifications/OdmCertification";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      className="nodeTabPanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const NodePageTabs = (props: any) => {
  const { data, selectedSmt, smtSelected } = props;
  const techData = GetTechInfo()?.data;
  const labels = AppLabels();
  const [value, setValue] = useState(1);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const isTelemetryEligible = getTechRoles(techData) ? true : false;

  return (
    <div className="nodeTabsCont">
      <AppBar position="static" className="nodeTabsBar">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label={labels.OVERVIEW.toUpperCase()} {...a11yProps(0)} />

          <Tab label={labels.TELEMETRY.toUpperCase()} {...a11yProps(1)} />

          {isTelemetryEligible && (
            <Tab
              label={labels.CERTIFICATIONS.toUpperCase()}
              {...a11yProps(2)}
            />
          )}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <NodeOverView data={data} />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <NodeTelemetryTab data={selectedSmt} selectdSmt={smtSelected} />
      </TabPanel>

      {isTelemetryEligible && (
        <TabPanel value={value} index={2}>
          <Certifications isPopup={false} />
        </TabPanel>
      )}

      {/* {isTelemetryEligible ? (
        <TabPanel value={value} index={3}>
          <OdmCertification isPopup={false} />
        </TabPanel>
      ) : (
        <TabPanel value={value} index={2}>
          <OdmCertification isPopup={false} />
        </TabPanel>
      )} */}
    </div>
  );
};

export default NodePageTabs;
