import { Marker } from "@react-google-maps/api";
import React from "react";
import { mapElementStyles } from "../../../../config/mapItemsConfig";
import appConst from "../../../../constants/appConst";
import { getTransparentSymbol } from "../../../../util/utilFuncs";
import { mapItemsClickConfig } from "../../../../config/mapItemsClickConfig";
import { zoomLevelRenderMarker } from "../../../../config/mapConfig";

interface Props {
  mduCADAttributeData?: any;
  zoomLevel?: any;
}
const MduCADAttributes = React.memo(
  ({ mduCADAttributeData, zoomLevel }: Props) => {
    return (
      <>
        {zoomLevel >= zoomLevelRenderMarker &&
          mduCADAttributeData !== undefined &&
          mduCADAttributeData?.features?.map(
            (mduCADAttribute: any, index: any) => {
              let isTableIcon =
                mduCADAttribute?.properties?.data?.wdsLayer ===
                  appConst?.mduStrobeTable ||
                mduCADAttribute?.properties?.data?.wdsLayer ===
                  appConst?.wdsLayer0 ||
                mduCADAttribute?.properties?.data?.wdsPlotstyle ===
                  appConst?.wdsPlotStyle;
              const propertiesData = mduCADAttribute?.properties?.data;
              const type = mduCADAttribute?.properties?.type;
              const textValue =
                propertiesData?.wdsText !== "null"
                  ? propertiesData?.wdsText
                  : "";
              const cadTextValue = {
                text: textValue,
                color: mapElementStyles?.[type]?.color,
                fontSize: mapElementStyles?.[type]?.fontSize,
              };
              return (
                <Marker
                  key={`${mduCADAttribute?.featureId}value`}
                  position={{
                    lat: mduCADAttribute?.geometry?.coordinates?.[1],
                    lng: mduCADAttribute?.geometry?.coordinates?.[0],
                  }}
                  label={cadTextValue}
                  icon={{
                    url:
                      appConst.imageStringPrefix +
                      encodeURIComponent(getTransparentSymbol()),
                    anchor: new google.maps.Point(5, 5),
                    labelOrigin: isTableIcon
                      ? new google.maps.Point(
                          zoomLevel === 21
                            ? mapItemsClickConfig?.mduCADTableIcon?.labelAnchor
                                ?.zoom21?.x
                            : zoomLevel === 20
                            ? mapItemsClickConfig?.mduCADTableIcon?.labelAnchor
                                ?.zoom20?.x
                            : mapItemsClickConfig?.mduCADTableIcon?.labelAnchor
                                ?.zoom19?.x,
                          zoomLevel === 21
                            ? mapItemsClickConfig?.mduCADTableIcon?.labelAnchor
                                ?.zoom21?.y
                            : zoomLevel === 20
                            ? mapItemsClickConfig?.mduCADTableIcon?.labelAnchor
                                ?.zoom20?.y
                            : mapItemsClickConfig?.mduCADTableIcon?.labelAnchor
                                ?.zoom19?.y
                        )
                      : new google.maps.Point(
                          zoomLevel === 21
                            ? mapItemsClickConfig?.mduCADAttribute?.labelAnchor
                                ?.zoom21?.x
                            : zoomLevel === 20
                            ? mapItemsClickConfig?.mduCADAttribute?.labelAnchor
                                ?.zoom20?.x
                            : mapItemsClickConfig?.mduCADAttribute?.labelAnchor
                                ?.zoom19?.x,
                          zoomLevel === 21
                            ? mapItemsClickConfig?.mduCADAttribute?.labelAnchor
                                ?.zoom21?.y
                            : zoomLevel === 20
                            ? mapItemsClickConfig?.mduCADAttribute?.labelAnchor
                                ?.zoom20?.y
                            : mapItemsClickConfig?.mduCADAttribute?.labelAnchor
                                ?.zoom19?.y
                        ),
                  }}
                />
              );
            }
          )}
      </>
    );
  }
);

export default MduCADAttributes;
