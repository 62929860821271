import { IptvNetwork } from "../../../../models/account/diagnostics/iptv/IptvNetwork";
import {
  IPTV_NETWORK_BEGIN,
  IPTV_NETWORK_SUCCESS,
  IPTV_NETWORK_FAILURE,
  IptvNetworkActionTypes,
} from "../../../../types/account/diagnostics/iptv/IPTVNetwork";

export function iptvNetworkBegin(): IptvNetworkActionTypes {
  return {
    type: IPTV_NETWORK_BEGIN,
  };
}

export function iptvNetworkSuccess(
  iptvNetwork: IptvNetwork
): IptvNetworkActionTypes {
  return {
    type: IPTV_NETWORK_SUCCESS,
    payload: iptvNetwork,
  };
}

export function iptvNetworkFailure(error: Error): IptvNetworkActionTypes {
  return {
    type: IPTV_NETWORK_FAILURE,
    payload: error,
  };
}
