import { createSlice } from "@reduxjs/toolkit";
import { ACCOUNT_SERVICE_URL, FILE_DOWNLOAD_URI } from "../../middleware/apiEndpoints";
import storeConst from "../../storeConst";
import { apiCallBegan } from "../../../topology/store/middleware/apiActions";

const slice = createSlice({
    name: "fileDownload",
    initialState: {
        isLoading: false,
        data: undefined,
        error: undefined,
        status: "",
    },
    reducers: {
        fileDownloadBegin: (as) => {
            as.isLoading = true;
            as.status = "";
        },
        fileDownloadSuccess: (as, { payload }) => {
            as.isLoading = false;
            as.data = payload;
            as.status = storeConst.OK;
        },
        fileDownloadFailure: (as, { payload }) => {
            as.isLoading = false;
            as.error = payload;
            as.data = undefined;
            as.status = storeConst.ERROR;
        },
        fileDownloadReset: (as) => {
            as.isLoading = false;
            as.status = "";
            as.data = undefined;
            as.error = undefined;
        },
    },
});

export const {
    fileDownloadBegin,
    fileDownloadSuccess,
    fileDownloadFailure,
    fileDownloadReset,
} = slice.actions;

export default slice.reducer;

//action creators
export const downloadFile = (filename: string) =>
    apiCallBegan({
        baseURL: ACCOUNT_SERVICE_URL,
        url: `${FILE_DOWNLOAD_URI}${filename}`,
        onStart: fileDownloadBegin.type,
        onSuccess: fileDownloadSuccess.type,
        onError: fileDownloadFailure.type,
    });