import { Divider } from "@material-ui/core";
import { CSSProperties } from "react";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";

import CustomPopOver from "./CustomPopOver";
import colors from "../../topology/config/colors";

interface Props {
  tabsItems: {
    icon: JSX.Element;
    compToRend: JSX.Element;
    popupText: string;
  }[];
  activetabIndex?: number;
  SetActiveTabIndex?: any;
  style?: CSSProperties;
  childrenStyle?: CSSProperties;
  onVisiblityClick?: any;
}

export default function SideBarTabs({
  tabsItems,
  activetabIndex = 0,
  SetActiveTabIndex,
  style,
  childrenStyle,
  onVisiblityClick,
}: Props) {
  return (
    <div className="sideBarTabsCont" style={style}>
      <div className="tabDataCont">
        {tabsItems?.map(
          (e: any, i: number) =>
            i === activetabIndex &&
            !e?.isTabHidden && <div key={i}>{e.compToRend}</div>
        )}
      </div>
      <div className="sideBarTabs">
        {tabsItems?.map(
          (e: any, i: number) =>
            !e?.isTabHidden && (
              <div
                key={i}
                style={
                  !e?.isDisabled
                    ? childrenStyle
                    : {
                      backgroundColor: colors.disabled,
                      opacity: 0.5,
                      pointerEvents: 'none',
                    }
                }
              >
                {e?.isVisible !== undefined && (
                  <span
                    className="showHide"
                    onClick={() => onVisiblityClick(e?.type)}
                  >
                    {e?.isVisible ? (
                      <ImCheckboxChecked color={colors.primary} size={14} />
                    ) : (
                      <ImCheckboxUnchecked color={colors.primary} size={14} />
                    )}
                  </span>
                )}
                <CustomPopOver
                  position="left"
                  popOverData={e.popupText}
                  index="popOver"
                  originComp={
                    <div
                      onClick={() => SetActiveTabIndex(i)}
                      className={
                        i === activetabIndex
                          ? "activeItemCont"
                          : "inactiveItemCont"
                      }
                    >
                      {e.icon}
                    </div>
                  }
                />
                {tabsItems.length > i + 1 && <Divider />}
              </div>
            )
        )}
      </div>
    </div>
  );
}
