import { Divider } from "@material-ui/core";
import { Form } from "react-bootstrap";

interface popUpProps {
  labelText: string;
  placeholderText: string;
  valueText: string;
}

const CustomTextItem = (props: popUpProps) => {
  const { labelText, placeholderText, valueText } = props;
  return (
    <div className="cardContentContainer">
      <span className="cardContentLabel">{labelText}</span>
      <br />
      <span className="cardContentText">{valueText}</span>
    </div>
  );
};

export default CustomTextItem;
