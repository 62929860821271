import { Dropdown, DropdownButton } from "react-bootstrap";
import AppLabels from "../../../constants/App_Labels";
import { useEffect, useState } from "react";

interface Props {
  setIsDropRiserGood?: any;
  setSeleReasonType?: any;
  setSeleDropLength?: any;
  setSeleDropResistance?: any;
  allReasons?: any;
  hsiFailCond: boolean;
}

const PostCheckActions = (props: Props) => {
  const labels = AppLabels();
  const [isDropRiserGood, setIsDropRiserGood] = useState("Select");
  const [seleReasonType, setSeleReasonType] = useState("Select");
  const [seleDropLength, setSeleDropLength] = useState("");
  const [seleDropResistance, setSeleDropResistance] = useState("");

  useEffect(() => {
    props?.setIsDropRiserGood(isDropRiserGood);
    props?.setSeleReasonType(seleReasonType);
    props?.setSeleDropLength(seleDropLength);
    props?.setSeleDropResistance(seleDropResistance);
  }, [isDropRiserGood, seleReasonType, seleDropLength, seleDropResistance]);

  return (
    <>
      <label className="reasons">Reasons for Failing</label>
      <div className="reviewActions">
        <p>Why is this certificate failing?</p>
        <div className="certiInputCont">
          <label>Reason for Failing
            <span className="mandatoryAst"> *</span>
          </label>
          <DropdownButton
            title={seleReasonType}
            onSelect={(e: any) => setSeleReasonType(e)}
            size="sm"
            className="certiReasonTypeDd"
            id="certiReasonTypeDd"
          >
            {props?.allReasons?.map((reason: any, index: any) => {
              return (
                <Dropdown.Item key={index} eventKey={reason.reasonText}>
                  {reason.reasonText}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
        </div>
      </div>
      {props?.hsiFailCond &&
        <div className="reviewActions">
          <p>
            Did you use your TDR and OHM to validate the drop and Riser were in
            good condition?
          </p>
          <div className="certiInputCont">
            <label>Drop and Riser in Good Condition
              <span className="mandatoryAst"> *</span>
            </label>
            <DropdownButton
              title={isDropRiserGood}
              onSelect={(e: any) => setIsDropRiserGood(e)}
              size="sm"
              className="custReviewDd"
              id="custReviewDd"
            >
              <Dropdown.Item key={0} eventKey={labels.Yes}>
                {labels.Yes}
              </Dropdown.Item>
              <Dropdown.Item key={1} eventKey={labels.No}>
                {labels.No}
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>}
      {props?.hsiFailCond && <div className="reviewActions">
        <p>
          What was the length of your drop?
        </p>
        <div className="certiInputCont" style={{ maxWidth: "500px" }}>
          <label>Length of Your Drop
            <span className="mandatoryAst"> *</span>
          </label>
          <textarea
            name="dropLength"
            rows={1}
            cols={50}
            maxLength={1000}
            style={{ resize: "none", width: "60%" }}
            onChange={(e) => setSeleDropLength(e.target.value)}
          />
        </div>
      </div>}
      {props?.hsiFailCond && <div className="reviewActions">
        <p>
          What was the resistance of your drop?
        </p>
        <div className="certiInputCont" style={{ maxWidth: "500px" }}>
          <label>Resistance of Your Drop
            <span className="mandatoryAst"> *</span>
          </label>
          <textarea
            name="dropResistance"
            rows={1}
            cols={50}
            maxLength={1000}
            style={{ resize: "none", width: "60%" }}
            onChange={(e) => setSeleDropResistance(e.target.value)}
          />
        </div>
      </div>}
    </>
  );
};

export default PostCheckActions;
