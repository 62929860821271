import { createSlice } from "@reduxjs/toolkit";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "rpdOptionsList",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
    optionsList: undefined,
  },
  reducers: {
    rpdOptionsListBegin: (rpd) => {
      rpd.isLoading = true;
    },
    rpdOptionsListSuccess: (rpd, action) => {
      rpd.isLoading = false;
      rpd.data = action.payload;
      rpd.error = undefined;
      rpd.status = storeConst.OK;
    },
    rpdOptionsListFailure: (rpd, action) => {
      rpd.isLoading = false;
      rpd.error = action.payload;
      rpd.data = undefined;
      rpd.status = storeConst.ERROR;
    },
    rpdOptionsListReset: (rpd) => {
      rpd.isLoading = false;
      rpd.error = undefined;
      rpd.data = undefined;
      rpd.status = "";
      rpd.optionsList = undefined;
    },
  },
});

export const {
  rpdOptionsListBegin,
  rpdOptionsListSuccess,
  rpdOptionsListFailure,
  rpdOptionsListReset,
} = slice.actions;
export default slice.reducer;
