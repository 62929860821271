import { createSlice } from "@reduxjs/toolkit";
import { ACCOUNT_SERVICE_URL, FILE_FETCH_URI } from "../../middleware/apiEndpoints";
import storeConst from "../../storeConst";
import { apiCallBegan } from "../../../topology/store/middleware/apiActions";

const slice = createSlice({
    name: "filesFetch",
    initialState: {
        isLoading: false,
        data: undefined,
        error: undefined,
        status: "",
    },
    reducers: {
        filesFetchBegin: (as) => {
            as.isLoading = true;
            as.status = "";
        },
        filesFetchSuccess: (as, { payload }) => {
            as.isLoading = false;
            as.data = payload;
            as.status = storeConst.OK;
        },
        filesFetchFailure: (as, { payload }) => {
            as.isLoading = false;
            as.error = payload;
            as.data = undefined;
            as.status = storeConst.ERROR;
        },
        filesFetchReset: (as) => {
            as.isLoading = false;
            as.status = "";
            as.data = undefined;
            as.error = undefined;
        },
    },
});

export const {
    filesFetchBegin,
    filesFetchSuccess,
    filesFetchFailure,
    filesFetchReset,
} = slice.actions;

export default slice.reducer;

//action creators
export const getAllFiles = () =>
    apiCallBegan({
        baseURL: ACCOUNT_SERVICE_URL,
        url: FILE_FETCH_URI,
        onStart: filesFetchBegin.type,
        onSuccess: filesFetchSuccess.type,
        onError: filesFetchFailure.type,
    });