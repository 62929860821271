import Navbar from "react-bootstrap/Navbar";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
//Css Imports
import "../../design-tokens/header/HeaderBar.scss";
import FeedbackContainer from "../feedback/FeedbackContainer";
import NotificationScrollingBanner from "../notification/NotificationScrollingBanner";

//Icons Imports

//Components Imports
import LogoBanner from "./LogoBanner";
import SearchMenu from "../search/SearchMenu";
import TechnicianPopover from "../profile/TechnicianPopover";
import NotificationBox from "../notification/NotificationBox";
import ExternalLinkContainer from "./ExternalLinkContainer";
import { GetTechInfo } from "../../get-api/GetResponse";
import { getAccessLevelByRoles } from "../reusable/Util";
import ConfigConst from "../../constants/ConfigConst";
import { getConfigs } from "../../topology/store/slices/configs";
import TECHClient from "../../network/httpclient/technician/TECHClient";
import xleVisiblilityClient from "../../network/httpclient/accountInfo/xleVisiblilityClient";
const HeaderBar = (props: any) => {
  const techData = GetTechInfo()?.data;
  const [notiOpen, setNotiOpen] = useState(false);
  const dispatch = useDispatch();
  const {flagXLE
  } = useSelector(
    (state: any) => ({
    flagXLE: state.slices?.xleVisible,
    
    }),
    shallowEqual
  );
  const isOpenModal = () => {
    const url = new URL(window.location.href);
    if (url.hash.includes("#/#?isOpenModal=true")) {
      const keyVal = url.hash.split("?")[1].split("=");
      const key = keyVal[0];
      const isModalOpen = keyVal[1];
      if (key == "isOpenModal") {
        return isModalOpen === "true" ? true : false;
      }
    }
    return false;
  };
  useEffect(() => {
    dispatch(getConfigs());
    dispatch(TECHClient.getTechniciansProfile());
      if(flagXLE?.data ===undefined){
      dispatch(xleVisiblilityClient.getXleEnable());
      }
  }, [dispatch]);

  const isMaintTech =
    techData && getAccessLevelByRoles(techData?.roles).includes(ConfigConst.MT);
  return (
    <div>
      <Navbar className="navHeader">
        <LogoBanner />
        <NotificationScrollingBanner openNoti={() => setNotiOpen(true)} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {" "}
          <SearchMenu />
          {isMaintTech && <ExternalLinkContainer />}
          <FeedbackContainer openModal={isOpenModal()}/>
          <NotificationBox
            setShowRSBar={props.setShowRSBar}
            openNoti={notiOpen}
            closeNoti={() => setNotiOpen(false)}
          />
          <TechnicianPopover />
        </div>
      </Navbar>
    </div>
  );
};

export default HeaderBar;