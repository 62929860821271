import { format, isAfter, isBefore, parseISO } from "date-fns";
import { renderToString } from "react-dom/server";
import {
  getAccessLevelByRoles,
  getMapPointsDistance,
  getPartialServiceFlag,
  hzToMHz,
  isNull,
} from "../../components/reusable/Util";
import ConfigConst from "../../constants/ConfigConst";
import { OLTOntCountType } from "../../screens/gpon-page/OLTOntCountType";
import TopologySymbols, {
  IconInterface,
} from "../components/reusable/TopologySymbols";
import colors from "../config/colors";
import {
  imageOverlayDividend,
  imageOverlayDividendMdu,
  imageOverlayDividendMduCADSymbol,
  mapCustomOverlayMeasure,
  zoomLevelRenderActives,
  zoomLevelRenderMarker,
  zoomLevelRenderMduOverlay,
} from "../config/mapConfig";
import { mapItemsClickConfig } from "../config/mapItemsClickConfig";
import { elementDataConfig, mapElementStyles } from "../config/mapItemsConfig";
import appConst from "../constants/appConst";
import {
  CLICK_ORDERS_FILTERS_KEY,
  CLICK_STATUS_FILTERS_KEY,
} from "../constants/storageConst";
import storeConst from "../store/storeConst";
import { GetConfigsProps } from "./reduxFunctions/getTopologyState";

export const getCenterOfPoly = (polycords: any) => {
  let center = [0.0, 0.0];
  let totalCords = polycords?.length;

  if (polycords !== undefined && totalCords > 0) {
    polycords?.map((cord: any, i: number) => {
      center[0] += cord?.[1];
      center[1] += cord?.[0];
    });
    center[0] = center[0] / totalCords;
    center[1] = center[1] / totalCords;
  }
  return { lat: center[0], lng: center[1] };
};

export function containsLocation(point: any, polygon: any) {
  // ray-casting algorithm based on
  // http://alienryderflex.com/polygon/

  var x = point[0],
    y = point[1];

  var inside = false;
  for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    var xi = polygon[i][0],
      yi = polygon[i][1];
    var xj = polygon[j][0],
      yj = polygon[j][1];

    var intersect =
      yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
}

export const isMarkersInsideViewPort = (
  existingFeaturesWM: Array<any>,
  currentBDY: any,
  features: Array<any>
) => {
  const neLat = currentBDY?.northEast?.lat;
  const neLng = currentBDY?.northEast?.lng;
  const swLat = currentBDY?.southWest?.lat;
  const swLng = currentBDY?.southWest?.lng;
  const currentVP = [
    [neLat, swLng],
    [neLat, neLng],
    [swLat, neLng],
    [swLat, swLng],
  ];
  let newFeatures = [] as Array<any>;
  let featuresWM = [...existingFeaturesWM] as Array<any>;

  features?.map((e: any) => {
    const isSS = isSupportStructure(e.properties?.type);
    //is feature data already in the list?
    const index = featuresWM?.findIndex((object) => {
      return isSS
        ? object?.featureId === e?.featureId
        : object.properties?.featureId === e.properties?.featureId;
    });

    if (e?.geometry?.type === "Point" && index === -1) {
      const point = [
        e?.geometry?.coordinates?.[1],
        e?.geometry?.coordinates?.[0],
      ];
      const isFeaturesWVP = containsLocation(point, currentVP);
      if (isFeaturesWVP) newFeatures.push(e);
    }
  });

  return newFeatures;
};

export const FormatAllNodeData=(nodeSites: any, allNodeData: any)=>{
    const existingFeatureId = allNodeData?.[1]?.node?.data?.features?.[0]?.properties?.featureId

    const index = nodeSites.findIndex((ns:any) => {
    const nsFeatureId = ns?.featureId;
      return nsFeatureId === existingFeatureId;
      });

  if (index > -1) {
    if (index !== 0) {
      const [matchedNs] = nodeSites.splice(index, 1);
      nodeSites.unshift(matchedNs);
    }
    return { foundMatch: true, existingNode: nodeSites[0], nodeSites };
  } else {
    return { foundMatch: false, existingNode: {}, nodeSites };
  }
 
}
export const isRfDropsMarkersInsideViewPort = (
  existingFeaturesWM: Array<any>,
  currentBDY: any,
  features: Array<any>
) => {
  const neLat = currentBDY?.northEast?.lat;
  const neLng = currentBDY?.northEast?.lng;
  const swLat = currentBDY?.southWest?.lat;
  const swLng = currentBDY?.southWest?.lng;
  const currentVP = [
    [neLat, swLng],
    [neLat, neLng],
    [swLat, neLng],
    [swLat, swLng],
  ];
  let newFeatures = [] as Array<any>;
  let featuresWM = [...existingFeaturesWM] as Array<any>;

  features?.map((e: any) => {
    //is feature data already in the list?
    const index = featuresWM?.findIndex((object) => {
      return object.properties?.featureId === e.properties?.featureId;
    });

    if (index === -1) {
      const point = [
        e?.geometry?.coordinates?.[1]?.[1],
        e?.geometry?.coordinates?.[1]?.[0],
      ];
      const isFeaturesWVP = containsLocation(point, currentVP);
      if (isFeaturesWVP) newFeatures.push(e);
    }
  });

  return newFeatures;
};

//create polygons as each new boundary comes to view  and return the polygons
export const isInsideViewPort = (
  nePoint: any,
  swPoint: any,
  viewPortPoly: any
) => {
  let newViewPortPoly = [];
  let nwPoint = [nePoint?.[0], swPoint?.[1]];
  let sePoint = [swPoint?.[0], nePoint?.[1]];
  let isNEPointWVP = false;
  let isSWPointWVP = false;
  let isNWPointWVP = false;
  let isSEPointWVP = false;
  const currentVP = [
    [nePoint?.[0], swPoint?.[1]],
    [nePoint?.[0], nePoint?.[1]],
    [swPoint?.[0], nePoint?.[1]],
    [swPoint?.[0], swPoint?.[1]],
  ];

  const centerPoint = getCenterOfPoly(currentVP);
  let isCenterPointWVP = false;

  for (let i = 0; i < viewPortPoly?.length; i++) {
    if (!isNEPointWVP) {
      isNEPointWVP = containsLocation(nePoint, viewPortPoly[i]);
    }
    if (!isSWPointWVP) {
      isSWPointWVP = containsLocation(swPoint, viewPortPoly[i]);
    }
    if (!isNWPointWVP) {
      isNWPointWVP = containsLocation(nwPoint, viewPortPoly[i]);
    }
    if (!isSEPointWVP) {
      isSEPointWVP = containsLocation(sePoint, viewPortPoly[i]);
    }
    if (!isCenterPointWVP) {
      isCenterPointWVP = containsLocation(
        [centerPoint?.lng, centerPoint?.lat],
        viewPortPoly[i]
      );
    }

    if (
      isNEPointWVP &&
      isSWPointWVP &&
      isNWPointWVP &&
      isSEPointWVP &&
      isCenterPointWVP
    ) {
      break;
    }
  }

  const isWVP =
    isNEPointWVP &&
    isSWPointWVP &&
    isNWPointWVP &&
    isSEPointWVP &&
    isCenterPointWVP
      ? true
      : false;

  if (isWVP) {
    newViewPortPoly.push(...viewPortPoly);
  } else {
    newViewPortPoly.push(currentVP);
    viewPortPoly?.map((e: any) => {
      if (isPolyWithinPoly(e, currentVP) === false) {
        newViewPortPoly.push(e);
      }
    });
  }

  return { isWVP, newViewPortPoly };
};

const isPolyWithinPoly = (innerPoly: any, outerPoly: any) => {
  let isWVP = true;

  for (let i = 0; i < innerPoly.length; i++) {
    isWVP = containsLocation(innerPoly[i], outerPoly);
    if (!isWVP) break;
  }

  return isWVP;
};

export function getMapActiveElementType(
  type: any,
  labels: any,
  defaultReturn = ""
) {
  switch (type) {
    case appConst.polygon:
    case appConst.node:
      return labels?.node;
    case appConst.fiberCable:
      return labels.fiberCable;
    case appConst.spliceCases:
      return labels.spliceCase;
    case appConst.taps:
      return labels.tap;
    case appConst.splitter:
      return labels.splitter;
    case appConst.powerBlock:
      return labels.powerBlock;
    case appConst.rfCable:
      return labels.rfCable;
    case appConst.terminator:
      return labels.terminator;
    case appConst.powerSupply:
      return labels.powerSupply;
    case appConst.rfDrop:
      return labels.cableDrops;
    case appConst.activeEquipment:
      return labels.activeEquipment;
    case appConst.annotationBlock:
      return labels.annotationBlock;
    case appConst.workOrders:
      return labels.workOrders;
    default:
      return defaultReturn;
  }
}

export const getUniqueMapData = (currData: any, newData: any) => {
  let combinedData: any;
  if (newData !== undefined && newData?.features?.length > 0) {
    if (currData !== undefined && currData?.features?.length > 0) {
      let filteredList: any = [];
      newData?.features?.map((nF: any) => {
        let isFPresent = currData?.features?.map((cF: any) => {
          if (cF?.properties?.featureId === nF?.properties?.featureId) {
            return true;
          } else {
            return false;
          }
        });
        if (isFPresent?.indexOf(true) === -1) {
          filteredList?.push(nF);
        }
      });
      let combinedFeatures = [...currData?.features, ...filteredList];
      combinedData = {
        ...currData,
        ...{ features: combinedFeatures },
      };
    } else {
      combinedData = newData;
    }
  }
  return combinedData;
};

export const getPolyAreaCords = (cords: any) => {
  let northEastPoly: any;
  let southWestPoly: any;

  if (cords !== undefined && cords?.length > 0) {
    let highLat = cords?.[0]?.[1];
    let highLng = cords?.[0]?.[0];
    let lowLat = cords?.[0]?.[1];
    let lowLng = cords?.[0]?.[0];
    cords?.map((c: any) => {
      if (c?.[0] > highLng) highLng = c?.[0];
      if (c?.[0] < lowLng) lowLng = c?.[0];
      if (c?.[1] > highLat) highLat = c?.[1];
      if (c?.[1] < lowLat) lowLat = c?.[1];
    });
    northEastPoly = { longitude: highLng, latitude: highLat };
    southWestPoly = { longitude: lowLng, latitude: lowLat };
  }
  return { topRight: northEastPoly, bottomLeft: southWestPoly };
};

export function compareValues(key: any, order = "asc") {
  return function innerSort(a: any, b: any) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key]?.toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key]?.toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
}

export const formatCamelCase = (inputString: any): String => {
  return inputString
    ? inputString
        .replace(/([A-Z]+)/g, " $1")
        .replace(/([A-Z][a-z])/g, " $1")
        .toUpperCase()
        .trim()
    : "";
};

//move latitude by given meters
export const moveLat = (lat: number, meter: number) => {
  const earth = 6378.137, //radius of the earth in kilometer
    pi = Math.PI,
    m = 1 / (((2 * pi) / 360) * earth) / 1000; //1 meter in degree
  const newLat = lat + meter * m;

  return newLat;
};

//move longitude by given meters
export const moveLng = (lat: number, lng: number, meter: number) => {
  const earth = 6378.137, //radius of the earth in kilometer
    pi = Math.PI,
    cos = Math.cos,
    m = 1 / (((2 * pi) / 360) * earth) / 1000; //1 meter in degree
  const newLng = lng + (meter * m) / cos(lat * (pi / 180));

  return newLng;
};

export const arrangeNodeData = (
  payload: any,
  id = "networkId",
  rootNodeSiteId = ""
) => {
  let tempPayload = payload;

  if (tempPayload?.features?.length > 0) {
    let tempFeatures = tempPayload?.features?.map((f: any) => {
      const featureID = f?.properties?.data?.[id];
      let featureProp = {
        ...f?.properties,
        ...{
          featureId: featureID,
          rootNodeSiteId,
          storedData: payload?.storedData,
        },
      };

      return { ...f, ...{ properties: featureProp } };
    });
    tempPayload = {
      ...tempPayload,
      ...{ features: tempFeatures },
    };
  }

  return tempPayload;
};

export const addNodeNum = (nodeData: any, nodeNum: any, i: any) => {
  let newNodeData: any = [];

  if (nodeNum !== undefined && nodeData?.features?.length > 0) {
    newNodeData = [
      {
        properties: {
          featureId: nodeData?.features?.[i]?.properties?.featureId,
          type: nodeData?.features?.[i]?.properties?.type,
          rootNodeSiteId: nodeNum,
          data: {
            ...{
              nodeNum: i === 0 ? nodeNum : undefined,
            },
            ...nodeData?.features?.[i]?.properties?.data,
          },
        },
        geometry: nodeData?.features?.[i]?.geometry,
      },
    ];
  }

  return newNodeData;
};

export const convertToLatLngLiteral = (geoData: any) => {
  let convertedData: any = [];

  if (geoData !== undefined && geoData?.length > 0) {
    convertedData = geoData?.map?.((c: any) => {
      return { lat: c?.[1], lng: c?.[0] };
    });
  }
  return convertedData;
};

export const isCustomMarkersInsideViewPort = (
  existingFeaturesWM: Array<any>,
  currentBDY: any,
  features: Array<any>
) => {
  const neLat = currentBDY?.northEast?.lat;
  const neLng = currentBDY?.northEast?.lng;
  const swLat = currentBDY?.southWest?.lat;
  const swLng = currentBDY?.southWest?.lng;
  const currentVP = [
    [neLat, swLng],
    [neLat, neLng],
    [swLat, neLng],
    [swLat, swLng],
  ];
  let newFeatures = [] as Array<any>;
  let featuresWM = [...existingFeaturesWM] as Array<any>;

  features?.map((e: any) => {
    //is feature data already in the list?
    const index = featuresWM?.findIndex((object) => {
      return object?.featureId === e?.featureId;
    });

    if (e?.geometry?.type === "Point" && index === -1) {
      const point = [
        e?.geometry?.coordinates?.[1],
        e?.geometry?.coordinates?.[0],
      ];
      const isFeaturesWVP = containsLocation(point, currentVP);
      if (isFeaturesWVP) newFeatures.push(e);
    }
  });

  return newFeatures;
};

export function isStringNumber(value: string) {
  if (value?.toLowerCase() === value?.toUpperCase()) {
    return true;
  } else return false;
}

export function isNumeric(number: any) {
  const regex = /^[0-9\b]+$/;
  if (regex.test(number)) {
    return number;
  } else return "";
}
export function isFloatNumber(number: any) {
  const regex = /[-+]?([0-9]*\.[0-9]+|[0-9]+)/g;
  if (regex.test(number)) {
    return true;
  } else return false;
}
export const getLineElementStyle = (
  elementType: string,
  cableType?: any,
  zoomLevel?: any
): any => {
  switch (elementType) {
    case appConst.fiberCable:
      return mapElementStyles?.fiberCableGeometry;
    case appConst?.rfDrop:
      return mapElementStyles?.rfDrops?.lineStyle;
    case appConst?.rfCable:
      let rfStyle;
      const cableColor = getRfCableColorByType(cableType);
      rfStyle = {
        ...mapElementStyles?.rfCable?.lineStyle,
        ...cableColor,
      };
      return rfStyle;
    case appConst?.poleStrand:
      return mapElementStyles?.poleStrand?.lineStyle;
    case appConst.mduPolyline:
      return mapElementStyles?.mduPolyline;
    case appConst.mduFiberCable:
      return mapElementStyles?.mduFiberCable;
    case appConst.mduRFCable:
      return mapElementStyles?.mduRFCable;
    case appConst.mduStrandSpecialCable:
      return mapElementStyles?.mduStrandSpecialCable;
    case appConst.mduStrandCable:
      return mapElementStyles?.mduStrandCable;
    case appConst.mduRaster:
      return mapElementStyles?.mduRaster;
    case appConst.mduCADHatch:
      return mapElementStyles?.mduCADHatch;
  }
};

export const getPointElementStyle = (
  elementType: string,
  eleData?: any,
  zoomLevel?: any,
  pngImages?: any
): any => {
  let configStyle: any = {};
  let eleIcon: any = getTransparentSymbol();
  let subEleName =
    elementType === appConst.fiberStorageAndRiser ? eleData?.stype : "";
  const anchor = getBase64ScaledImageAnchor(
    pngImages,
    elementType,
    eleData?.symbolName,
    eleData?.rotation,
    mapElementStyles?.[elementType],
    zoomLevel,
    subEleName
  );
  let iconLabel;
  switch (elementType) {
    case appConst?.taps:
      configStyle = mapElementStyles?.tap;
      eleIcon = getClickImageStringOfElement(
        zoomLevel,
        anchor,
        true,
        elementType
      );
      iconLabel = getIconLabel(eleData?.tap?.tapValue, configStyle);
      break;
    case appConst?.spliceCases:
    case appConst?.powerBlock:
    case appConst?.splitter:
    case appConst?.powerInserter:
    case appConst?.terminator:
    case appConst?.rfSplice:
    case appConst?.activeEquipment:
    case appConst?.fiberAddrConnectionPoint:
    case appConst?.fiberStorageAndRiser:
    case appConst?.cableDip:
    case appConst?.detailMark:
    case appConst?.pole:
    case appConst?.opticalTap:
      configStyle = mapElementStyles?.[elementType];
      eleIcon = getClickImageStringOfElement(zoomLevel, anchor);
      break;
    case appConst?.rfdropaddress:
      const SD = eleData?.streetNumber;
      const streetAdd = SD?.includes("-") ? SD?.split("-")[0] : SD;
      configStyle = mapElementStyles?.rfDrops;
      iconLabel = getIconLabel(
        `${eleData?.unit ? eleData?.unit + "-" : ""}${streetAdd}`,
        configStyle
      );
      eleIcon = getImageStringOfElement(
        undefined,
        configStyle,
        eleData?.rotation
      );
      eleIcon = {
        ...eleIcon,
        ...{
          anchor: new google.maps.Point(
            configStyle?.iconSize / 2,
            configStyle?.iconSize / 2
          ),
        },
      };
      break;
    case appConst?.powerSupply:
      configStyle = mapElementStyles?.powerSupply;
      eleIcon = getClickImageStringOfElement(
        zoomLevel,
        anchor,
        true,
        elementType
      );
      iconLabel = getIconLabel(eleData?.rensNumber, configStyle);
      break;
    case appConst?.annotationBlock:
      configStyle = mapElementStyles?.annotationBlock;
      eleIcon = getImageStringOfElement(
        eleData?.symbol,
        configStyle,
        eleData?.rotation
      );
      break;
    case appConst?.mdu:
      configStyle = mapElementStyles?.[elementType];
      let mduSymboleName =
        eleData?.symbolName === "sdu-cs" ? "MDU" : eleData?.symbolName;
      eleIcon = getImageStringOfElement(
        mduSymboleName,
        configStyle,
        eleData?.rotation
      );
      eleIcon = {
        ...eleIcon,
        ...{
          anchor: new google.maps.Point(
            mapElementStyles?.mdu?.anchor?.x,
            mapElementStyles?.mdu?.anchor?.y
          ),
        },
      };
      break;
    case appConst?.mduTap:
      configStyle = mapElementStyles?.mduTap;
      eleIcon = getClickImageStringOfElement(
        zoomLevel,
        anchor,
        true,
        elementType
      );
      iconLabel = getIconLabel(eleData?.tapVal, configStyle);
      break;
    case appConst?.mduActive:
    case appConst?.mduFiberEquipment:
    case appConst?.mduPowerSupply:
      eleIcon = getClickImageStringOfElement(zoomLevel, anchor);
      break;
    case appConst?.poleStrand:
      configStyle = mapElementStyles?.poleStrand;
      eleIcon = getImageStringOfElement(
        undefined,
        configStyle,
        eleData?.rotation
      );
      eleIcon = {
        ...eleIcon,
        ...{
          anchor: new google.maps.Point(
            configStyle?.iconSize / 2,
            configStyle?.iconSize / 2
          ),
        },
      };
      iconLabel = getIconLabel(eleData?.annoText, configStyle);
      break;
  }
  return {
    icon: eleIcon,
    label: iconLabel,
    clickable: elementType === appConst.rfdropaddress ? false : true,
    zIndex: elementType === appConst.rfdropaddress ? 0 : 1,
  };
};

export const getTapIconName = (numOutput: number) => {
  switch (numOutput) {
    case 2:
      return mapElementStyles?.tap?.tap2IconName;
    case 4:
      return mapElementStyles?.tap?.tap4IconName;
    case 8:
      return mapElementStyles?.tap?.tap8IconName;
    default:
      return mapElementStyles?.tap?.tapIconName;
  }
};

export const getImageStringOfElement = (
  name: any,
  configStyle: any,
  rotation: number
) => {
  if (name) {
    const iconString = renderToString(
      <TopologySymbols
        name={name}
        color={configStyle?.color}
        rotate={rotation}
        size={configStyle?.iconSize}
        opacity={configStyle?.iconOpacity}
      />
    );
    return {
      url: appConst.imageStringPrefix + encodeURIComponent(iconString),
      anchor: new google.maps.Point(
        configStyle?.iconSize / 2,
        configStyle?.iconSize / 2
      ),
    };
  }

  return {
    url: getTransparentSymbol(),
  };
};

export const getClickImageStringOfElement = (
  zoomLevel: any,
  anchor: any,
  isLabel: boolean = false,
  eleType: any = ""
) => {
  if (anchor) {
    const iconString = getMapElementClickableImage(
      zoomLevel,
      anchor?.clickIconSize,
      anchor?.hasSymbol
    );
    return {
      url: appConst.imageStringPrefix + encodeURIComponent(iconString),
      anchor: new google.maps.Point(
        anchor ? anchor?.x : mapElementStyles?.common.anchor.x,
        anchor ? anchor?.y : mapElementStyles?.common.anchor.y
      ),
      labelOrigin: isLabel
        ? new google.maps.Point(
            zoomLevel === 21
              ? mapItemsClickConfig?.[eleType]?.labelAnchor?.zoom21?.x
              : zoomLevel === 20
              ? mapItemsClickConfig?.[eleType]?.labelAnchor?.zoom20?.x
              : mapItemsClickConfig?.[eleType]?.labelAnchor?.zoom19?.x,
            zoomLevel === 21
              ? mapItemsClickConfig?.[eleType]?.labelAnchor?.zoom21?.y
              : zoomLevel === 20
              ? mapItemsClickConfig?.[eleType]?.labelAnchor?.zoom20?.y
              : mapItemsClickConfig?.[eleType]?.labelAnchor?.zoom19?.y
          )
        : undefined,
    };
  }

  return {
    url: getTransparentSymbol(),
  };
};

export const getPngImagesForElement = (
  pngImagesData: any = undefined,
  symbolName: any,
  rotation: number,
  configStyle: any,
  zoomLevel: number
) => {
  const base64Info = getPngBase64Info(
    pngImagesData,
    symbolName,
    rotation,
    configStyle?.color
  );

  if (base64Info !== undefined) {
    if (base64Info?.symbol !== undefined && base64Info?.symbol !== null) {
      const { scaledWidth, scaledHeight } = getBase64ScaledSize(
        zoomLevel,
        base64Info?.width,
        base64Info?.height
      );
      return {
        url: `${appConst.base64StringPrefix}${base64Info?.symbol}`,
        scaledSize: new google.maps.Size(scaledWidth, scaledHeight),
        anchor: new google.maps.Point(scaledWidth / 2, scaledHeight / 2),
      };
    } else {
      const iconString = renderToString(
        <TopologySymbols name={"notAvailable"} />
      );
      return {
        url: appConst.imageStringPrefix + encodeURIComponent(iconString),
        anchor: new google.maps.Point(
          mapElementStyles?.common?.anchor?.x,
          mapElementStyles?.common?.anchor?.y
        ),
      };
    }
  }

  return {
    url: getTransparentSymbol(),
  };
};

export const getIconLabel = (text: any, configStyle: any) => {
  if (text) {
    return {
      text: `${text}`,
      className: configStyle?.classname,
      color: configStyle?.color,
      fontSize: configStyle?.fontSize,
    };
  }
  return { text: " " };
};

export const getRfCablePatternStyle = (cableType: any) => {
  let currRfType = "";
  let patternType;
  const rfTypes = Object?.keys(mapElementStyles?.rfCable?.cableTypes);
  rfTypes?.map((type: any) => {
    const includes = cableType?.includes(type);
    if (includes) {
      currRfType = type;
    }
  });
  if (currRfType !== "") {
    const cableTypesConfig: any = mapElementStyles?.rfCable?.cableTypes;
    const cableStyle: any = cableTypesConfig?.[currRfType];
    if (currRfType !== "412") {
      patternType = {
        icon: {
          path: cableStyle?.lineDashPattern,
          strokeColor: cableStyle?.strokeColor,
          strokeOpacity: 1,
          strokeWeight: 2,
          scale: 1.5,
        },
        offset: "0",
        repeat: cableStyle?.repeat,
      };
    }
  } else {
    patternType = {
      icon: {
        path: mapElementStyles?.rfCable?.cableTypes?.["860"].lineDashPattern,
        strokeColor:
          mapElementStyles?.rfCable?.cableTypes?.["860"]?.strokeColor,
        strokeOpacity: 1,
        strokeWeight: 2,
        scale: 1.5,
      },
      offset: "0",
      repeat: mapElementStyles?.rfCable?.cableTypes?.["860"]?.repeat,
    };
  }
  return patternType;
};
export const getRfCableColorByType = (cableType: any) => {
  let currRfType = "";
  let cableStyleObj: any = mapElementStyles?.rfCable?.cableTypes.other;
  const rfTypes = Object?.keys(mapElementStyles?.rfCable?.cableTypes);
  rfTypes?.map((type: any) => {
    const includes = cableType?.includes(type);
    if (includes) {
      currRfType = type;
    }
  });
  if (currRfType !== "") {
    const cableTypesConfig: any = mapElementStyles?.rfCable?.cableTypes;
    cableStyleObj = cableTypesConfig?.[currRfType];
  }
  return cableStyleObj;
};

export const isSupportStructure = (eleName: any) => {
  const ss = [
    appConst?.pole,
    appConst?.cableDip,
    appConst?.detailMark,
    appConst?.poleStrand,
  ];
  if (ss?.indexOf(eleName) !== -1) return true;
  return false;
};

export const getElementStyle = (
  feature: any,
  zoomLevel: any,
  mapFilters: any,
  pngImages?: any
) => {
  let elementStyle = {};
  let isVisible = false;
  const eledata = feature?.getProperty(appConst.data);
  const eleType = feature?.getProperty(appConst?.type);
  const geoType = feature?.getGeometry()?.getType();
  const isSS = isSupportStructure(eleType);
  /* const isRFDrop = eleType === appConst?.rfDrop ? true : false; */
  switch (geoType) {
    case appConst?.Point:
      if (
        eleType === appConst.powerSupply &&
        zoomLevel >= zoomLevelRenderActives
      ) {
        isVisible = mapFilters?.[appConst?.powerSupply];
        elementStyle = getPointElementStyle(
          eleType,
          eledata,
          zoomLevel,
          pngImages
        );
      } else if (zoomLevel >= zoomLevelRenderMarker) {
        isVisible = isSS
          ? mapFilters?.[appConst?.supportStructure]
          : mapFilters?.[eleType];
        elementStyle = getPointElementStyle(
          eleType,
          eledata,
          zoomLevel,
          pngImages
        );
      } else if (
        zoomLevel === zoomLevelRenderActives &&
        eleType === appConst.activeEquipment
      ) {
        isVisible = mapFilters?.[appConst?.activeEquipment];
        elementStyle = getPointElementStyle(
          eleType,
          eledata,
          zoomLevel,
          pngImages
        );
      } else if (
        zoomLevel === zoomLevelRenderActives &&
        eleType === appConst.mdu
      ) {
        isVisible = mapFilters?.[appConst?.mdu];
        elementStyle = getPointElementStyle(
          eleType,
          eledata,
          zoomLevel,
          pngImages
        );
      }
      break;
    case appConst?.line:
      isVisible = isSS
        ? mapFilters?.[appConst?.supportStructure]
        : mapFilters?.[eleType];
      elementStyle = getLineElementStyle(
        eleType,
        eledata?.cableType,
        zoomLevel
      );
      break;
  }

  return {
    ...{
      visible: isVisible,
    },
    ...elementStyle,
  };
};

export const getMDUElementStyle = (
  feature: any,
  zoomLevel: any,
  pngImages?: any
) => {
  let elementStyle = {};
  let isVisible = false;
  const eledata = feature?.getProperty(appConst.data);
  const eleType = feature?.getProperty(appConst?.type);
  const geoType = feature?.getGeometry()?.getType();

  switch (geoType) {
    case appConst?.Point:
      if (zoomLevel >= zoomLevelRenderMduOverlay) {
        isVisible = true;
        elementStyle = getPointElementStyle(
          eleType,
          eledata,
          zoomLevel,
          pngImages
        );
      }
      break;
    case appConst?.line:
      isVisible = true;
      elementStyle = getLineElementStyle(
        eleType,
        eledata?.cableType,
        zoomLevel
      );
      break;
  }

  return {
    ...{
      visible: isVisible,
    },
    ...elementStyle,
  };
};

export const isCoordsAvaileable = (cm: any) => {
  let haveGeoData = false;
  if (
    cm &&
    cm?.hasOwnProperty(appConst.coordinate) &&
    cm?.coordinate?.lat &&
    cm?.coordinate?.lat !== 0 &&
    cm?.coordinate?.lng &&
    cm?.coordinate?.lng !== 0
  ) {
    haveGeoData = true;
  }

  return haveGeoData;
};

export const convertStreamDataToJson = (data: any) => {
  let convertedDataObj = [] as any;
  data?.split("\n")?.map((item: any) => {
    if (item !== "" && typeof item === "string") {
      let escapedStr = item?.replace(/\\\"/g, "");
      let jsonObj;
      try {
        jsonObj = JSON.parse(escapedStr);
      } catch {
        jsonObj = undefined;
      }
      if (jsonObj) {
        convertedDataObj.push(jsonObj);
      }
    }
  });
  return convertedDataObj;
};
export const convertDataToJson = (data: string) => {
  let jsonObj;
  // try {

  let fixedJSON = data.replace(/"""/g, '"');

  fixedJSON = fixedJSON.replace(/"0\s+"""/g, '"0"');

  jsonObj = JSON.parse(fixedJSON);

  // } catch (e) {
  //   console.error("JSON parsing failed", e);
  //   jsonObj = undefined;
  // }

  return jsonObj;
};
export const customParserForSummary = (data: any) => {
  let parsedData = [];
  try {
    const lines = data.split("\n");
    for (const line of lines) {
      if (line.trim() !== "") {
        const trimmedLine = line.trim();
        try {
          const jsonObject = JSON.parse(trimmedLine);
          const summaryString = jsonObject.ONTResponse.data.summary;
          if (typeof summaryString === "string") {
            try {
              const summaryData = JSON.parse(summaryString);
              parsedData.push(summaryData);
            } catch (error) {
              console.error("Failed to parse summary data:", error);
            }
          } else {
            console.error("Summary data is not a valid JSON string.");
          }
        } catch (error) {
          console.error("Custom parsing failed for summary:", error);
          parsedData = [];
        }
      }
    }
  } catch (error) {
    console.error("Custom parsing failed for summary:", error);
    parsedData = [];
  }
  return parsedData;
};
export const restructurePONStreamResp = (data: any) => {
  let structuredData;
  if (data?.errorCode) {
    structuredData = {
      data: data,
      status: data?.errorCode ? appConst.ERROR : appConst.OK,
      error: data?.errorCode ? data?.reason : null,
      type: storeConst.all,
    };
  } else {
   
    structuredData =data;
  }
  return structuredData;
};
export const combinePONData = (currData: any, newData: any) => {
  let combinedData = currData?.map((cm: any) => {
    
      let mergedData: any;
      newData?.filter((md: any) => {
        if (md?.serialNumber === cm?.serialNumber) {
          let strucCMData = restructurePONStreamResp(md);
          mergedData = { ...cm, ... strucCMData };
        }
      });
      if (mergedData) {
        return mergedData;
      } else {
        return cm;
      }
   
  });
  return combinedData;
};

export const convertToJson = (
  data: string,
  customParser: (str: string) => any
) => {
  let jsonObj;

  try {
    jsonObj = customParser(data);
  } catch (e) {
    console.error("Custom parsing failed", e);
    jsonObj = undefined;
  }

  return jsonObj;
};

export const combineTeleData = (currData: any, newData: any) => {
  let combinedData = currData?.map((cm: any) => {
    if (!cm?.hasOwnProperty(appConst.deviceDiag)) {
      let mergedData: any;
      newData?.filter((md: any) => {
        if (md?.macId === cm?.macAddress) {
          let strucCMData = restructureTeleStreamResp(md);
          mergedData = { ...cm, ...{ deviceDiag: strucCMData } };
        }
      });
      if (mergedData) {
        return mergedData;
      } else {
        return cm;
      }
    } else {
      return cm;
    }
  });
  return combinedData;
};

export const combineData = (currData: any, newData: any) => {
  let combinedData = currData?.map((cm: any) => {
    let mergedData: any;
    newData?.filter((md: any) => {
      if (md?.macId === cm?.macAddress) {
        let strucCMData = restructureTeleStreamResp(md);
        mergedData = { ...cm, ...{ deviceDiag: strucCMData } };
      }
    });
    if (mergedData) {
      return mergedData;
    } else {
      return cm;
    }
  });
  return combinedData;
};

export const newTeleData = (currData: any, data: any) => {
  let newData: any = [];
  currData?.map((cm: any) => {
    if (!cm?.hasOwnProperty(appConst.deviceDiag)) {
      data?.filter((md: any) => {
        if (md?.macId === cm?.macAddress) {
          newData.push(md);
        }
      });
    }
  });
  return newData;
};
export const newNodeData = (currData: any, data: any) => {
  let newData: any = [];
  currData?.map((cm: any) => {
    data?.filter((md: any) => {
      if (md?.accountSummaryResponse?.data?.macAddress === cm?.macAddress) {
        newData.push(md);
      }
    });
  });
  return newData;
};
export const mergeDatasets = (dataset1: any, dataset2: any) => {
  // Convert the second dataset into a map for faster lookups.
  const map2 = new Map();
  dataset2.forEach((item: any) => {
    const macAddress = item.accountSummaryResponse.data[0].macAddress;
    map2.set(macAddress, item.accountSummaryResponse.data[0]);
  });

  // Now iterate over the first dataset and merge the data
  const mergedData = dataset1.map((item1: any) => {
    const macAddress = item1.macAddress;
    if (map2.has(macAddress)) {
      return {
        ...item1,
        accountSummaryResponse: {
          data: [
            {
              ...map2.get(macAddress),
            },
          ],
          error: null,
        },
      };
    }
    return item1; // If no match found, return the original item
  });

  return mergedData;
};

export const restructureTeleStreamResp = (data: any) => {
  let structuredData;
  if (data?.summaryResponse) {
    structuredData = {
      data: { summary: data?.summaryResponse?.data },
      status: data?.summaryResponse?.error ? appConst.ERROR : appConst.OK,
      error: data?.summaryResponse?.error,
      type: storeConst.summary,
    };
  } else {
    const d = data?.fullCMDiagResponse?.data;
    structuredData = {
      data: d,
      status: d?.summary?.errorCode ? appConst.ERROR : appConst.OK,
      error: d?.summary?.errorCode ? d?.summary : null,
      type: storeConst.all,
    };
  }
  return structuredData;
};

export const getCMStatusProps = (deviceData: any) => {
  let summaryData = deviceData?.data?.summary;
  let docsisData = deviceData?.data?.docsis;
  const dVersion = deviceData?.data?.summary?.docsisVersion;

  const getProps = (
    color: string,
    d30IconName: IconInterface["name"],
    d31IconName: IconInterface["name"]
  ) => {
    return {
      color: mapElementStyles?.cableModem?.color?.[color],
      iconName: dVersion === appConst.d30 ? d30IconName : d31IconName,
    };
  };

  if (deviceData?.type === appConst.all) {
    if (deviceData?.status === appConst.ERROR) {
      return getProps("offLine", "d30Offline", "d31Offline");
    } else {
      if (docsisData && !docsisData?.hasOwnProperty(appConst.errorCode)) {
        if (docsisData?.overallStatus?.toLowerCase() === appConst.fail) {
          let cmChannels = getAvailableDocsisChannels(docsisData);
          let upStreamStatus = "";
          let downStreamStatus = "";

          if (cmChannels?.length > 0) {
            cmChannels?.map((ch: any) => {
              switch (ch?.chName) {
                case appConst.usScQAM:
                case appConst.ofdma:
                  if (ch?.chStatus === appConst.fail) {
                    upStreamStatus = appConst.fail;
                  }
                  break;
                case appConst.dsScQAM:
                case appConst.ofdm:
                  if (ch?.chStatus === appConst.fail) {
                    downStreamStatus = appConst.fail;
                  }
                  break;
              }
            });
          }
          if (
            upStreamStatus === appConst.fail &&
            downStreamStatus === appConst.fail
          ) {
            return getProps(
              "dualChannel",
              "d30dsAndusSpecOut",
              "d31dsAndusSpecOut"
            );
          } else if (upStreamStatus === appConst.fail) {
            return getProps("upStream", "d30usSpecOut", "d31usSpecOut");
          } else if (downStreamStatus === appConst.fail) {
            return getProps("downStream", "d30dsSpecOut", "d31dsSpecOut");
          } else {
            return getProps("offLine", "d30Offline", "d31Offline");
          }
        }
        return getProps("onLine", "d30dsAndusSpecIn", "d31dsAndusSpecIn");
      } else if (
        (!docsisData || docsisData?.hasOwnProperty(appConst.errorCode)) &&
        summaryData &&
        summaryData?.uptime > 0
      ) {
        return getProps("unknown", "d30NoSNPM", "d31NoSNPM");
      } else if (
        !docsisData ||
        docsisData?.hasOwnProperty(appConst.errorCode)
      ) {
        return getProps("offLine", "d30Offline", "d31Offline");
      }
    }
  } else {
    if (summaryData && summaryData?.uptime > 0) {
      return getProps("onLine", "d30dsAndusSpecIn", "d31dsAndusSpecIn");
    } else return getProps("offLine", "d30Offline", "d31Offline");
  }
  return getProps("offLine", "d30Offline", "d31Offline");
};

export const checkChannelStatus = (channelData: any, channelType: string) => {
  if (channelData) {
    switch (channelType) {
      case appConst.usScQAM:
        let totalUS30ChCount = channelData?.channels?.length;
        let failedUS30ChCount = 0;
        if (totalUS30ChCount > 0) {
          channelData?.channels?.map((ch: any) => {
            let channelStatus = getOptimalthrStatus([
              ch?.snr?.status,
              ch?.status?.status,
              ch?.txPower?.status,
            ]);

            if (channelStatus === appConst.fail) {
              failedUS30ChCount += 1;
            }
          });
        }
        if (failedUS30ChCount > 0) return appConst.fail;
        else return appConst.pass;
        break;
      case appConst.dsScQAM:
        let totalDS30ChCount = channelData?.channels?.length;
        let failedDS30ChCount = 0;
        if (totalDS30ChCount > 0) {
          channelData?.channels?.map((ch: any) => {
            let channelStatus = getOptimalthrStatus([
              ch?.snr?.status,
              ch?.status?.status,
              ch?.rx_power?.status,
            ]);
            if (channelStatus === appConst.fail) {
              failedDS30ChCount += 1;
            }
          });
        }
        if (failedDS30ChCount > 0) return appConst.fail;
        else return appConst.pass;
        break;
      case appConst.ofdma:
        let totalUS31ChCount = channelData?.channels?.length;
        let failedUS31ChCount = 0;
        if (totalUS31ChCount > 0) {
          channelData?.channels?.map((ch: any) => {
            let channelStatus = getOptimalthrStatus([
              ch?.mer?.thrVal?.status,
              ch?.status?.status,
              ch?.txPower?.status,
            ]);
            if (channelStatus === appConst.fail) {
              failedUS31ChCount += 1;
            }
          });
        }
        if (failedUS31ChCount > 0) return appConst.fail;
        else return appConst.pass;
        break;
      case appConst.ofdm:
        let totalDS31ChCount = channelData?.length;
        let failedDS31ChCount = 0;
        if (totalDS31ChCount > 0) {
          channelData?.map((ch: any) => {
            let channelStatus = getOptimalthrStatus([
              ch?.mer?.thrVal?.status,
              ch?.maxRxPower?.status,
              ch?.status?.status,
              ch?.minRxPower?.status,
              ch?.averageRxPower?.status,
            ]);
            if (channelStatus === appConst.fail) {
              failedDS31ChCount += 1;
            }
          });
        }
        if (failedDS31ChCount > 0) return appConst.fail;
        else return appConst.pass;
        break;
    }
  }
  return "";
};

export const getOptimalthrStatus = (allChStatus: any) => {
  let optimalStatus = appConst.pass as string;
  if (allChStatus?.length > 0) {
    allChStatus?.map((thrStatus: any) => {
      if (thrStatus) {
        if (thrStatus?.toLowerCase() === appConst.fail) {
          optimalStatus = appConst.fail;
        }
      } else {
        optimalStatus = appConst.fail;
      }
    });
  } else {
    return (optimalStatus = appConst.fail);
  }
  return optimalStatus;
};

export const getAvailableDocsisChannels = (docsisData: any) => {
  let channels: any = [];
  const channelTypes = [
    appConst.usScQAM,
    appConst.dsScQAM,
    appConst.ofdma,
    appConst.ofdm,
  ];
  channelTypes?.map((chType: string) => {
    if (docsisData) {
      switch (chType) {
        case appConst.usScQAM:
          if (docsisData?.docsis30?.upstream) {
            let chStatus = checkChannelStatus(
              docsisData?.docsis30?.upstream,
              appConst.usScQAM
            );
            let usScQAM = {
              chName: appConst.usScQAM,
              chData: docsisData?.docsis30?.upstream,
              chStatus: chStatus,
            };
            channels.push(usScQAM);
          }
          break;
        case appConst.dsScQAM:
          if (docsisData?.docsis30?.downstream) {
            let chStatus = checkChannelStatus(
              docsisData?.docsis30?.downstream,
              appConst.dsScQAM
            );
            let dsScQAM = {
              chName: appConst.dsScQAM,
              chData: docsisData?.docsis30?.downstream,
              chStatus: chStatus,
            };
            channels.push(dsScQAM);
          }
          break;
        case appConst.ofdma:
          if (docsisData?.ofdm?.upstream) {
            let chStatus = checkChannelStatus(
              docsisData?.ofdm?.upstream,
              appConst.ofdma
            );
            let ofdma = {
              chName: appConst.ofdma,
              chData: docsisData?.ofdm?.upstream,
              chStatus: chStatus,
            };
            channels.push(ofdma);
          }
          break;
        case appConst.ofdm:
          if (docsisData?.ofdm?.downstreamChannels) {
            let chStatus = checkChannelStatus(
              docsisData?.ofdm?.downstreamChannels,
              appConst.ofdm
            );
            let ofdm = {
              chName: appConst.ofdm,
              chData: docsisData?.ofdm?.downstreamChannels,
              chStatus: chStatus,
            };
            channels.push(ofdm);
          }
          break;
      }
    }
  });
  return channels;
};

export function getCommonLeakageLevel(midLeak: any, lteLeak: any) {
  const midLeakLvl = getMidLeakLevel(midLeak);
  const lteLeakLvl = getLteLeakLevel(lteLeak);
  if (midLeakLvl != -1 && lteLeakLvl != -1) {
    if (midLeakLvl > lteLeakLvl) {
      return midLeakLvl;
    } else {
      return lteLeakLvl;
    }
  } else if (midLeakLvl != -1) {
    return midLeakLvl;
  } else if (lteLeakLvl != -1) {
    return lteLeakLvl;
  } else {
    return -1;
  }
}

export function getMidLeakLevel(midLeak: any) {
  if (midLeak >= 20 && midLeak <= 40) {
    return 0;
  } else if (midLeak >= 50 && midLeak <= 99) {
    return 1;
  } else if (midLeak >= 100 && midLeak <= 199) {
    return 2;
  } else if (midLeak >= 200 && midLeak <= 499) {
    return 3;
  } else if (midLeak >= 500) {
    return 4;
  } else {
    return -1;
  }
}

export function getLteLeakLevel(lteLeak: any) {
  if (lteLeak >= 20 && lteLeak <= 40) {
    return 0;
  } else if (lteLeak >= 50 && lteLeak <= 99) {
    return 1;
  } else if (lteLeak >= 100 && lteLeak <= 199) {
    return 2;
  } else if (lteLeak >= 200 && lteLeak <= 499) {
    return 3;
  } else if (lteLeak >= 500) {
    return 4;
  } else {
    return -1;
  }
}

export function getIngressLevel(ingress: any) {
  if (ingress >= -20 && ingress <= -13) {
    return 0;
  } else if (ingress >= -12 && ingress <= -6) {
    return 1;
  } else if (ingress >= -5 && ingress <= 0) {
    return 2;
  } else if (ingress >= 1 && ingress <= 5) {
    return 3;
  } else if (ingress >= 6) {
    return 4;
  }
}

export const getFailedAttrs = (channels: any) => {
  let failedAttrs: any = [];

  if (channels?.length > 0) {
    channels?.map((ch: any) => {
      if (ch?.chStatus === appConst.fail) {
        switch (ch?.chName) {
          case appConst.usScQAM:
            let usSCQAMFailedChs: any = [];
            ch?.chData?.channels?.map((channel: any) => {
              let channelStatus = getOptimalthrStatus([
                channel?.snr?.status,
                channel?.status?.status,
                channel?.txPower?.status,
              ]);
              if (channelStatus === appConst.fail) {
                usSCQAMFailedChs?.push(channel);
              }
            });
            if (usSCQAMFailedChs?.length > 0) {
              failedAttrs?.push({
                chName: appConst.usScQAM,
                failedChs: usSCQAMFailedChs,
              });
            }
            break;
          case appConst.dsScQAM:
            let dsSCQAMFailedChs: any = [];
            ch?.chData?.channels?.map((channel: any) => {
              let channelStatus = getOptimalthrStatus([
                channel?.snr?.status,
                channel?.status?.status,
                channel?.rx_power?.status,
              ]);
              if (channelStatus === appConst.fail) {
                dsSCQAMFailedChs?.push(channel);
              }
            });
            if (dsSCQAMFailedChs?.length > 0) {
              failedAttrs?.push({
                chName: appConst.dsScQAM,
                failedChs: dsSCQAMFailedChs,
              });
            }
            break;
          case appConst.ofdma:
            let ofdmAFailedChs: any = [];
            ch?.chData?.channels?.map((channel: any) => {
              let channelStatus = getOptimalthrStatus([
                channel?.snr?.status,
                channel?.mer?.thrVal?.status,
                channel?.status?.status,
                channel?.txPower?.status,
              ]);
              if (channelStatus === appConst.fail) {
                ofdmAFailedChs?.push(channel);
              }
            });
            if (ofdmAFailedChs?.length > 0) {
              failedAttrs?.push({
                chName: appConst.ofdma,
                failedChs: ofdmAFailedChs,
              });
            }
            break;
          case appConst.ofdm:
            let ofdmFailedChs: any = [];
            ch?.chData?.map((channel: any) => {
              let channelStatus = getOptimalthrStatus([
                channel?.mer?.thrVal?.status,
                channel?.maxRxPower?.status,
                channel?.status?.status,
                channel?.minRxPower?.status,
                channel?.averageRxPower?.status,
              ]);
              if (channelStatus === appConst.fail) {
                ofdmFailedChs?.push(channel);
              }
            });
            if (ofdmFailedChs?.length > 0) {
              failedAttrs?.push({
                chName: appConst.ofdm,
                failedChs: ofdmFailedChs,
              });
            }
            break;
        }
      }
    });
  }

  return failedAttrs;
};

export const getOpKey = (operatorKeys: any, filterKey: string) => {
  return operatorKeys?.[filterKey];
};

export const encrChAdvFilterObj = (filterData: any, operatorKeys: any) => {
  let chFilterObj: any = {};
  if (filterData) {
    Object?.keys(filterData)?.map((fKey: any) => {
      if (fKey !== "freq") {
        let operatorKey = getOpKey(operatorKeys, fKey);
        if (operatorKey) {
          chFilterObj = {
            ...chFilterObj,
            ...{
              [fKey]: filterData?.[fKey]?.value,
              [operatorKey]: filterData?.[fKey]?.operator,
            },
          };
        } else {
          chFilterObj = {
            ...chFilterObj,
            ...{ [fKey]: filterData?.[fKey]?.value },
          };
        }
      } else {
        let freqObj = filterData?.freq?.value;
        freqObj?.map((freq: any) => {
          if (freq?.key !== "allOpts") {
            chFilterObj = {
              ...chFilterObj,
              ...{ [freq?.key]: freq?.value },
            };
          } else {
            chFilterObj = {
              ...chFilterObj,
              ...{ allFreqs: freq?.value },
            };
          }
        });
      }
    });
  }
  return chFilterObj;
};
export const decrChAdvFilterObj = (
  filterData: any,
  defObj: any,
  operatorKeys: any
) => {
  let chFilterObj: any = {};
  let freqFilter: any = [];
  let chFObjKeys = Object.keys(defObj);
  if (filterData) {
    chFObjKeys?.map((fKey: any) => {
      if (fKey !== "freq") {
        let operatorKey = getOpKey(operatorKeys, fKey);
        let savedFilter = operatorKey
          ? { operator: filterData?.[operatorKey], value: filterData?.[fKey] }
          : { value: filterData?.[fKey] };
        let filterObj = {
          ...defObj[fKey],
          ...savedFilter,
        };
        chFilterObj = {
          ...chFilterObj,
          ...{ [fKey]: filterObj },
        };
      }
    });
    Object.keys(filterData)?.map((filterKey: any) => {
      if (!chFObjKeys?.includes(filterKey)) {
        defObj?.freq?.value?.map((freq: any) => {
          if (filterKey === "allFreqs" && freq?.key === "allOpts") {
            freqFilter.push({
              key: "allOpts",
              label: freq?.label,
              value: filterData?.[filterKey],
            });
          } else if (freq?.key === filterKey) {
            freqFilter.push({
              key: filterKey,
              label: freq?.label,
              value: filterData?.[filterKey],
            });
          }
        });
      }
    });
    chFilterObj = {
      ...chFilterObj,
      ...{ freq: { label: "Freq", operator: ":", value: freqFilter } },
    };
  }
  return chFilterObj;
};
export const secToTime3 = (duration: any) => {
  if (!duration) return undefined;
  const seconds = Number(duration);
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);
  const hAndm = parseFloat(`${h}.${m}`);

  return { d, h, m, s, hAndm };
};
export const applyDiagAdvancedFilters = (
  data: any,
  filters: any,
  cond: string
) => {
  let filterCheckResult: boolean[] = [];
  const fRF = filters?.regtimeFlaps;
  const fUS = filters?.upstream;
  const fDS = filters?.downstream;

  const summary = data?.summary;
  const docsis = data?.docsis;
  const flaps = data?.flaps;
  const cw = data?.codewords;

  const summaryRegTime = secToTime3(summary?.cmtsCmRegisteredTime);
  const regTime = {
    day: summaryRegTime?.d ? summaryRegTime?.d : 0,
    hour: summaryRegTime?.h ? summaryRegTime?.h : 0,
  };
  const filterTime = {
    day: fRF?.day ? parseInt(fRF?.day) : 0,
    hour: fRF?.hour ? parseInt(fRF?.hour) : 0,
  };
  const firstFlap = new Date(Number(flaps?.createTime));
  const lastFlap = new Date(Number(flaps?.lastFlapTime));
  const dPartialService = docsis?.partialService?.d31PartialSvcState;
  const cwUSCh = cw?.upstreamChannels;
  const usData = docsis?.docsis30?.upstream;
  const usCh = usData?.channels;
  const OFDMACh = docsis?.ofdm?.upstream?.channels;

  const cwDSCh = cw?.downstreamChannels;
  const dsData = docsis?.docsis30?.downstream;
  const dsCh = dsData?.channels;
  const OFDMCh = docsis?.ofdm?.downstreamChannels;

  const isTrue: any = (filterKey: any, value: any, compareChar = ">") => {
    if (filterKey && filterKey !== "") {
      switch (compareChar) {
        case ">":
          if (parseFloat(value) > parseFloat(filterKey)) {
            return true;
          } else return false;
        case "<":
          if (parseFloat(value) < parseFloat(filterKey)) {
            return true;
          } else return false;
      }
    }
  };
  const notNull = (val: any) => {
    if (val === undefined || val === null || val === "") {
      return false;
    } else return true;
  };
  const checkResult = (result: any) => {
    if (result !== undefined && result === true) filterCheckResult.push(true);
    else if (result !== undefined && result === false)
      filterCheckResult.push(false);
  };

  if (filters?.isFiltersApplied) {
    //#### Summary/Flaps Filters check start
    if (fRF?.day || fRF?.hour) {
      if (fRF?.regTimeCompOperator === "<") {
        checkResult(
          regTime.day < filterTime.day ||
            (regTime.day == filterTime.day && regTime.hour < filterTime.hour)
        );
      } else if (fRF?.regTimeCompOperator === ">") {
        checkResult(
          regTime.day > filterTime.day ||
            (regTime.day == filterTime.day && regTime.hour > filterTime.hour)
        );
      }
    }
    checkResult(fRF?.firstFlap && isBefore(fRF?.firstFlap, firstFlap));
    checkResult(fRF?.lastFlap && isAfter(fRF?.lastFlap, lastFlap));
    checkResult(isTrue(fRF?.flapCount, flaps?.counts));
    checkResult(isTrue(fRF?.inf, flaps?.insertionFails));
    checkResult(isTrue(fRF?.pwr, flaps?.powerAdjustments));

    //#### Summary/Flaps Filters check ends

    //#### upstream check start
    if (fUS?.isPartialService) {
      checkResult(
        getPartialServiceFlag(dPartialService, "US") === appConst.Yes
      );
    }

    checkResult(isTrue(fUS?.txDelta, usData?.txPowerDelta, fUS?.txDeltaCompOP));
    checkResult(isTrue(fUS?.USSNRDelta, usData?.snrDelta, fUS?.SNRDeltaCompOP));

    if (
      notNull(fUS?.txLess) ||
      notNull(fUS?.txGreater) ||
      notNull(fUS.USSNR) ||
      notNull(fUS.USPRECWE) ||
      notNull(fUS.USPOSTCWE)
    ) {
      if (usCh && usCh?.length > 0) {
        usCh?.some((e: any, i: number) => {
          if (notNull(fUS?.txLess) && notNull(fUS?.txGreater)) {
            checkResult(
              isTrue(fUS?.txLess, e?.txPower?.value, "<") ||
                isTrue(fUS?.txGreater, e?.txPower?.value)
            );
          } else if (notNull(fUS?.txLess)) {
            checkResult(isTrue(fUS?.txLess, e?.txPower?.value, "<"));
          } else if (notNull(fUS?.txGreater)) {
            checkResult(isTrue(fUS?.txGreater, e?.txPower?.value));
          }

          checkResult(isTrue(fUS.USSNR, e?.snr?.value, fUS?.SNRCompOP));
          checkResult(
            isTrue(
              fUS.USPRECWE,
              cwUSCh?.[i]?.preCWEDelta?.value,
              fUS?.PRECWECompOP
            )
          );
          checkResult(
            isTrue(
              fUS.USPOSTCWE,
              cwUSCh?.[i]?.postCWEDelta?.value,
              fUS?.POSTCWECompOP
            )
          );
        });
      } else filterCheckResult.push(false);
    }

    if (
      notNull(fUS?.ofdmaTXLess) ||
      notNull(fUS?.ofdmaTXGreater) ||
      notNull(fUS?.ofdmaPCT)
    ) {
      if (OFDMACh && OFDMACh?.length > 0) {
        OFDMACh?.some((e: any) => {
          if (notNull(fUS?.ofdmaTXLess) && notNull(fUS?.ofdmaTXGreater)) {
            checkResult(
              isTrue(fUS?.ofdmaTXLess, e?.txPower?.value, "<") ||
                isTrue(fUS?.ofdmaTXGreater, e?.txPower?.value)
            );
          } else if (notNull(fUS?.ofdmaTXLess)) {
            checkResult(isTrue(fUS?.ofdmaTXLess, e?.txPower?.value, "<"));
          } else if (notNull(fUS?.ofdmaTXGreater)) {
            checkResult(isTrue(fUS?.ofdmaTXGreater, e?.txPower?.value));
          }
          checkResult(
            isTrue(fUS?.ofdmaPCT, e?.mer?.thrVal?.value, fUS?.ofdmaPCTCompOP)
          );
        });
      } else filterCheckResult.push(false);
    }

    if (notNull(fUS?.ofdmaPOSTCWE)) {
      if (cw?.ofdmaUsChannels && cw?.ofdmaUsChannels?.length > 0) {
        cw?.ofdmaUsChannels?.some((ch: any) => {
          ch?.iucList?.some((e: any) => {
            checkResult(
              isTrue(
                fUS?.ofdmaPOSTCWE,
                e?.postCWEDelta?.value,
                fUS?.ofdmaPOSTCWECompOP
              )
            );
          });
        });
      } else filterCheckResult.push(false);
    }

    if (fUS?.allFreqs === false) {
      const checkFreq = (from: any, to: any) => {
        if (!usCh) return false;
        const index = usCh?.findIndex(
          (e: any) => e?.frequency >= from && e?.frequency <= to
        );
        if (index === -1) return false;
        return true;
      };

      if (fUS?.SCQAM0to10) checkResult(checkFreq("0", "10"));
      if (fUS?.SCQAM10to15) checkResult(checkFreq("10", "15"));
      if (fUS?.SCQAM15to20) checkResult(checkFreq("15", "20"));
      if (fUS?.SCQAM20to25) checkResult(checkFreq("20", "25"));
      if (fUS?.SCQAM25to30) checkResult(checkFreq("25", "30"));
      if (fUS?.SCQAM30to35) checkResult(checkFreq("30", "35"));
      if (fUS?.SCQAM35to40) checkResult(checkFreq("35", "40"));
      if (fUS?.SCQAM40to45) checkResult(checkFreq("40", "45"));
      if (fUS?.SCQAM45to55) checkResult(checkFreq("45", "55"));
      if (fUS?.SCQAM55to65) checkResult(checkFreq("55", "65"));
      if (fUS?.SCQAM65to75) checkResult(checkFreq("65", "75"));
      if (fUS?.SCQAM75to85) checkResult(checkFreq("75", "85"));

      Object.keys(fUS)?.map((e) => {
        if (e.includes("OFDMA") && fUS?.[e]) {
          if (OFDMACh && OFDMACh?.length !== 0) {
            filterCheckResult.push(true);
          } else filterCheckResult.push(false);
        }
      });
    }
    //#### upstream check end

    //#### downstream check start
    if (fDS?.isPartialService) {
      checkResult(
        getPartialServiceFlag(dPartialService, "DS") === appConst.Yes
      );
    }
    checkResult(isTrue(fDS?.rxDelta, dsData?.rxPowerDelta, fDS?.rxDeltaCompOP));
    checkResult(isTrue(fDS?.DSSNRDelta, dsData?.snrDelta, fDS?.SNRDeltaCompOP));

    if (
      notNull(fDS?.rxLess) ||
      notNull(fDS?.rxGreater) ||
      notNull(fDS.DSSNR) ||
      notNull(fDS.DSPRECWE) ||
      notNull(fDS.DSPOSTCWE)
    ) {
      if (dsCh && dsCh?.length > 0) {
        dsCh?.some((e: any, i: number) => {
          if (notNull(fDS?.rxLess) && notNull(fDS?.rxGreater)) {
            checkResult(
              isTrue(fDS?.rxLess, e?.rx_power?.value, "<") ||
                isTrue(fDS?.rxGreater, e?.rx_power?.value)
            );
          } else if (notNull(fDS?.rxLess)) {
            checkResult(isTrue(fDS?.rxLess, e?.rx_power?.value, "<"));
          } else if (notNull(fDS?.rxGreater)) {
            checkResult(isTrue(fDS?.rxGreater, e?.rx_power?.value));
          }

          checkResult(isTrue(fDS.DSSNR, e?.snr?.value, fDS?.SNRCompOP));
          checkResult(
            isTrue(
              fDS.DSPRECWE,
              cwDSCh?.[i]?.preCWEDelta?.value,
              fDS?.PRECWECompOP
            )
          );
          checkResult(
            isTrue(
              fDS.DSPOSTCWE,
              cwDSCh?.[i]?.postCWEDelta?.value,
              fDS?.POSTCWECompOP
            )
          );
        });
      } else filterCheckResult.push(false);
    }
    if (
      notNull(fUS?.ofdmRXLess) ||
      notNull(fUS?.ofdmRXGreater) ||
      notNull(fDS?.ofdmPCT) ||
      notNull(fDS?.ofdmRXDelta)
    ) {
      if (OFDMCh && OFDMCh?.length > 0) {
        OFDMCh?.some((ch: any) => {
          if (fDS?.[`OFDM${ch.channelId}`]) {
            checkResult(
              isTrue(fDS?.ofdmPCT, ch?.mer?.thrVal?.value, fDS?.ofdmPCTCompOP)
            );
            checkResult(
              isTrue(fDS?.ofdmRXDelta, ch?.rxPowerDelta, fDS?.ofdmRXDeltaCompOP)
            );
            if (notNull(fDS?.ofdmRXLess) && notNull(fDS?.ofdmaTXGreater)) {
              ch?.channelBands?.some((e: any) => {
                checkResult(
                  isTrue(fDS?.ofdmRXLess, e?.rxPower?.value, "<") ||
                    isTrue(fDS?.ofdmRXGreater, e?.rxPower?.value)
                );
              });
            } else if (notNull(fDS?.ofdmRXLess)) {
              ch?.channelBands?.some((e: any) => {
                checkResult(isTrue(fDS?.ofdmRXLess, e?.rxPower?.value, "<"));
              });
            } else if (notNull(fDS?.ofdmRXGreater)) {
              ch?.channelBands?.some((e: any) => {
                checkResult(isTrue(fDS?.ofdmRXGreater, e?.rxPower?.value));
              });
            }
          }
        });
      } else filterCheckResult.push(false);
    }

    if (notNull(fDS?.ofdmPOSTCWE)) {
      if (cw?.ofdmDsChannels && cw?.ofdmDsChannels?.length > 0) {
        cw?.ofdmDsChannels?.some((ch: any) => {
          ch?.profiles?.some((e: any) => {
            checkResult(
              isTrue(
                fDS?.ofdmPOSTCWE,
                e?.postCWEDelta?.value,
                fDS?.ofdmPOSTCWECompOP
              )
            );
          });
        });
      } else filterCheckResult.push(false);
    }

    if (fDS?.allFreqs === false) {
      const checkFreq = (val: any, data = dsCh, attr = "frequency") => {
        if (!data) return false;
        const index = data?.findIndex((e: any) => e?.[attr]?.startsWith(val));
        if (index === -1) return false;
        return true;
      };

      if (fDS?.SCQAM200) checkResult(checkFreq("2"));
      if (fDS?.SCQAM300) checkResult(checkFreq("3"));
      if (fDS?.SCQAM400) checkResult(checkFreq("4"));
      if (fDS?.SCQAM500) checkResult(checkFreq("5"));
      if (fDS?.SCQAM600) checkResult(checkFreq("6"));
      if (fDS?.SCQAM700) checkResult(checkFreq("7"));
      if (fDS?.SCQAM800) checkResult(checkFreq("8"));
      if (fDS?.SCQAM900) checkResult(checkFreq("9"));

      Object.keys(fDS)?.map((e) => {
        if (!e.includes("OFDMA") && e.includes("OFDM") && fDS?.[e]) {
          const channelId = e.replace("OFDM", "");
          const channelData = OFDMCh?.find(
            (data: any) => `${data.channelId}` === channelId
          );
          if (channelData === undefined) {
            filterCheckResult.push(false);
          } else {
            filterCheckResult.push(true);
          }
        }
      });
    }
    //#### downstream check end

    if (filterCheckResult?.length > 0) {
      if (cond === appConst.and.toUpperCase()) {
        if (filterCheckResult?.indexOf(false) !== -1) {
          return false;
        } else return true;
      } else if (cond === appConst.or.toUpperCase()) {
        if (filterCheckResult?.indexOf(true) !== -1) {
          return true;
        } else return false;
      }
    } else if (summary && docsis && filterCheckResult?.length === 0)
      return true;
  } else return true;
};

export const isCoordValid = (lat: any, lng: any) => {
  if (lat && lng && lat != 0 && lng != 0 && lat != "" && lng != "") {
    return true;
  } else return false;
};
export const getOutputAddress = (sd: any) => {
  return (
    (sd?.aptNumber && sd?.aptNumber != "" ? sd?.aptNumber + "-" : "") +
    (sd?.streetNumber && sd?.streetNumber != ""
      ? sd?.streetNumber + " "
      : " ") +
    (sd?.streetAddress + " ") +
    (sd?.streetType && sd?.streetType != "" ? sd?.streetType + ", " : "") +
    (sd?.cityName && sd?.cityName != "" ? sd?.cityName : "") +
    (sd?.province && sd?.province != "" ? ", " + sd?.province : "") +
    (sd?.postalCode && sd?.postalCode != "" ? " " + sd?.postalCode : "")
  );
};

export const getBasicAddressFormat = (sd: any) => {
  return (
    (sd?.aptNumber && sd?.aptNumber != "" ? sd?.aptNumber + "-" : "") +
    (sd?.streetNumber && sd?.streetNumber != ""
      ? sd?.streetNumber + " "
      : " ") +
    (sd?.streetName + " ") +
    (sd?.streetType && sd?.streetType != "" ? sd?.streetType + ", " : "") +
    (sd?.cityName && sd?.cityName != "" ? sd?.cityName : "")
  );
};

export function getDataForSymbolRequest(
  pngImages: any,
  elementName: any,
  geoJsonData: any
) {
  let tempArr: any = [];
  if (geoJsonData !== undefined && geoJsonData !== null) {
    geoJsonData?.map((item: any) => {
      const symbolName = item?.properties?.data?.symbolName
        ?.replace("-", "")
        ?.replace(".dwg", "")
        ?.replace(".DWG", "")
        ?.toUpperCase(); //should be in uppercase without - & .dwg
      //const rotation = parseFloat(item?.properties?.data?.rotation?.toFixed(2));
      const rotation = item?.properties?.data?.rotation;
      const color = mapElementStyles?.[elementName]?.color?.replace("#", ""); // should be 6 digit hexcode without #

      const isImageDataAvailable =
        pngImages?.[elementName]?.[
          `${symbolName}-${rotation ? rotation : 0}-${color}.png`
        ];

      if (symbolName && !isImageDataAvailable) {
        tempArr.push({
          name: symbolName,
          rotation: rotation ? rotation : 0,
          color: color,
          symbolType: elementName,
        });
      }
    });
  }

  return tempArr;
}

export function getPowertraceDataForSymbolRequest(pngImages: any, data: any) {
  let tempData: any = {};
  if (data !== undefined && data !== null) {
    data?.map((item: any) => {
      const symbolName = item?.symbolName
        ?.replace("-", "")
        ?.replace(".dwg", "")
        ?.replace(".DWG", "")
        ?.toUpperCase(); //should be in uppercase without - & .dwg
      //const rotation = parseFloat(item?.properties?.data?.rotation?.toFixed(2));
      const rotation = item?.rotation;
      const elementName = item?.type;
      const elementConfigName =
        elementName === appConst.node ? appConst.nodeSite : elementName;
      const color = mapElementStyles?.[elementConfigName]?.color?.replace(
        "#",
        ""
      ); // should be 6 digit hexcode without #

      const isImageDataAvailable = pngImages?.[elementName]?.data?.find(
        (element: any) => {
          return (
            element.name ===
            `${symbolName}-${rotation ? rotation : 0}-${color}.png`
          );
        }
      );

      if (symbolName && !isImageDataAvailable) {
        if (tempData?.hasOwnProperty(elementName)) {
          tempData = {
            ...tempData,
            [elementName]: [
              ...tempData?.[elementName],
              {
                name: symbolName,
                rotation: rotation ? rotation : 0,
                color: color,
                symbolType: elementName,
              },
            ],
          };
        } else {
          tempData = {
            ...tempData,
            [elementName]: [
              {
                name: symbolName,
                rotation: rotation ? rotation : 0,
                color: color,
                symbolType: elementName,
              },
            ],
          };
        }
      }
    });
  }

  return tempData;
}

export function getDataForSymbolRequestForLocator(
  pngImages: any,
  elementName: any,
  locatorData: any
) {
  let tempArr: any = [];
  if (locatorData !== undefined && locatorData !== null) {
    locatorData?.map((item: any) => {
      const symbolName = item?.symbolName
        ?.replace("-", "")
        ?.replace(".dwg", "")
        ?.replace(".DWG", "")
        ?.toUpperCase(); //should be in uppercase without - & .dwg
      //const rotation = parseFloat(item?.properties?.data?.rotation?.toFixed(2));
      const rotation = item?.rotation;
      const color = mapElementStyles?.[elementName]?.color?.replace("#", ""); // should be 6 digit hexcode without #

      const isImageDataAvailable =
        pngImages?.[elementName]?.[
          `${symbolName}-${rotation ? rotation : 0}-${color}.png`
        ];

      if (symbolName && !isImageDataAvailable) {
        tempArr.push({
          name: symbolName,
          rotation: rotation ? rotation : 0,
          color: color,
          symbolType: elementName,
        });
      }
    });
  }
  return tempArr;
}

export function isSymbolImageAvailable(
  imageName: string,
  eleType: string,
  images: any
) {
  let isImageAvailable: boolean = false;
  if (images && images?.[eleType]) {
    if (images?.[eleType]?.[imageName]) {
      isImageAvailable = true;
    }
  }
  return isImageAvailable;
}

export const getPngBase64Info = (
  eleImages: any,
  symbolName: any,
  rotation: any,
  colorHexcode: any
) => {
  if (eleImages) {
    let rotationStr = rotation ? convertIMGRotation(rotation) : "0.0";
    const imageColor = colorHexcode?.replace("#", "");
    const imageName =
      symbolName
        ?.replace("-", "")
        ?.replace(".dwg", "")
        ?.replace(".DWG", "")
        ?.toUpperCase() +
      "-" +
      rotationStr +
      "-" +
      imageColor +
      ".png";
    const activeEleData = eleImages?.data;
    if (activeEleData && activeEleData?.length > 0) {
      let eleImage = activeEleData?.find((img: any) => img?.name === imageName);
      return eleImage;
    } else if (activeEleData && typeof activeEleData === "object") {
      return activeEleData;
    } else {
      return "";
    }
  }
  return undefined;
};

export const convertIMGRotation = (rotationVal: number) => {
  let rotationStr = String(rotationVal);
  if (rotationStr.indexOf(".") === -1) {
    rotationStr = rotationStr + ".0";
  }
  return rotationStr;
};
export const getBase64ScaledSize = (
  zoomLevel: number,
  actualWidth: any,
  actualHeight: any
) => {
  switch (zoomLevel) {
    case 19:
      return {
        scaledWidth:
          actualWidth / mapItemsClickConfig?.common?.image19ZoomScale,
        scaledHeight:
          actualHeight / mapItemsClickConfig?.common?.image19ZoomScale,
      };
    case 20:
      return {
        scaledWidth:
          actualWidth / mapItemsClickConfig?.common?.image20ZoomScale,
        scaledHeight:
          actualHeight / mapItemsClickConfig?.common?.image20ZoomScale,
      };
    case 21:
      return {
        scaledWidth:
          actualWidth / mapItemsClickConfig?.common?.image21ZoomScale,
        scaledHeight:
          actualHeight / mapItemsClickConfig?.common?.image21ZoomScale,
      };

    default:
      return {
        scaledWidth:
          actualWidth / mapItemsClickConfig?.common?.image19ZoomScale,
        scaledHeight:
          actualHeight / mapItemsClickConfig?.common?.image19ZoomScale,
      };
  }
};

export const getBase64ScaledImageAnchor = (
  pngImagesData: any = undefined,
  elementName: string,
  symbolName: any,
  rotation: number,
  configStyle: any,
  zoomLevel: number,
  subElementName: any = undefined
) => {
  const base64Info = getPngBase64Info(
    pngImagesData?.[elementName],
    symbolName,
    rotation,
    configStyle?.color
  );

  const clickConfigElementName =
    subElementName === appConst?.storageLoop
      ? "fiberStorageLoop"
      : subElementName === appConst?.riser
      ? "fiberStorageRiser"
      : elementName;

  if (base64Info) {
    let poleIconSize = mapItemsClickConfig?.pole?.iconSize19;
    let terminatorIconSize = mapItemsClickConfig?.terminator?.iconSize19;
    let fiberSpliceCaseIconSize =
      mapItemsClickConfig?.fiberSpliceCase?.iconSize19;
    let anchorPositonAdjustmentXValue =
      mapItemsClickConfig?.[clickConfigElementName]?.anchor?.zoom19?.x;
    let anchorPositonAdjustmentYValue =
      mapItemsClickConfig?.[clickConfigElementName]?.anchor?.zoom19?.y;
    let scaleImageNumber = mapItemsClickConfig?.common?.image19ZoomScale;
    switch (zoomLevel) {
      case 18:
        anchorPositonAdjustmentXValue =
          mapItemsClickConfig?.[clickConfigElementName]?.anchor?.zoom18?.x;
        anchorPositonAdjustmentYValue =
          mapItemsClickConfig?.[clickConfigElementName]?.anchor?.zoom18?.y;
        scaleImageNumber = mapItemsClickConfig?.common?.image18ZoomScale;
        break;
      case 19:
        poleIconSize = mapItemsClickConfig?.pole?.iconSize19;
        terminatorIconSize = mapItemsClickConfig?.terminator?.iconSize19;
        fiberSpliceCaseIconSize =
          mapItemsClickConfig?.fiberSpliceCase?.iconSize19;
        anchorPositonAdjustmentXValue =
          mapItemsClickConfig?.[clickConfigElementName]?.anchor?.zoom19?.x;
        anchorPositonAdjustmentYValue =
          mapItemsClickConfig?.[clickConfigElementName]?.anchor?.zoom19?.y;
        scaleImageNumber = mapItemsClickConfig?.common?.image19ZoomScale;
        break;
      case 20:
        poleIconSize = mapItemsClickConfig?.pole?.iconSize20;
        terminatorIconSize = mapItemsClickConfig?.terminator?.iconSize20;
        fiberSpliceCaseIconSize =
          mapItemsClickConfig?.fiberSpliceCase?.iconSize20;
        anchorPositonAdjustmentXValue =
          mapItemsClickConfig?.[clickConfigElementName]?.anchor?.zoom20?.x;
        anchorPositonAdjustmentYValue =
          mapItemsClickConfig?.[clickConfigElementName]?.anchor?.zoom20?.y;
        scaleImageNumber = mapItemsClickConfig?.common?.image20ZoomScale;
        break;
      case 21:
        poleIconSize = mapItemsClickConfig?.pole?.iconSize21;
        terminatorIconSize = mapItemsClickConfig?.terminator?.iconSize21;
        fiberSpliceCaseIconSize =
          mapItemsClickConfig?.fiberSpliceCase?.iconSize21;
        anchorPositonAdjustmentXValue =
          mapItemsClickConfig?.[clickConfigElementName]?.anchor?.zoom21?.x;
        anchorPositonAdjustmentYValue =
          mapItemsClickConfig?.[clickConfigElementName]?.anchor?.zoom21?.y;
        scaleImageNumber = mapItemsClickConfig?.common?.image21ZoomScale;
        break;

      default:
        break;
    }

    const clickArea = base64Info?.clickableArea;
    const dimensions = clickArea?.dimension?.split("x");
    const outerImageX = base64Info?.width / scaleImageNumber / 2;
    const outerImageY = base64Info?.height / scaleImageNumber / 2;
    const innerImageX =
      Number(clickArea?.x) / scaleImageNumber +
      Number(dimensions?.[0]) / scaleImageNumber / 2;
    const innerImageY =
      Number(clickArea?.y) / scaleImageNumber +
      Number(dimensions?.[1]) / scaleImageNumber / 2;

    const anchorX = outerImageX - innerImageX + anchorPositonAdjustmentXValue;
    const anchorY = outerImageY - innerImageY + anchorPositonAdjustmentYValue;
    return {
      x: isNaN(anchorX) ? mapElementStyles?.common?.anchor?.x : anchorX,
      y: isNaN(anchorY) ? mapElementStyles?.common?.anchor?.y : anchorY,
      hasSymbol:
        base64Info?.symbol !== undefined && base64Info?.symbol !== null
          ? true
          : false,
      clickIconSize:
        clickConfigElementName === appConst.pole
          ? poleIconSize
          : clickConfigElementName === appConst.terminator
          ? terminatorIconSize
          : clickConfigElementName === appConst.spliceCases
          ? fiberSpliceCaseIconSize
          : isNaN(anchorX)
          ? zoomLevel === 21
            ? mapItemsClickConfig?.common?.clickableIcon?.iconSize21
            : zoomLevel === 20
            ? mapItemsClickConfig?.common?.clickableIcon?.iconSize20
            : zoomLevel === 18
            ? mapItemsClickConfig?.common?.clickableIcon?.iconSize18
            : mapItemsClickConfig?.common?.clickableIcon?.iconSize19
          : (Number(dimensions?.[0]) > Number(dimensions?.[1])
              ? Number(dimensions?.[0])
              : Number(dimensions?.[1])) /
            scaleImageNumber /
            mapItemsClickConfig?.[clickConfigElementName]?.sizeDividend,
    };
  }

  return {
    x: mapElementStyles?.common.anchor.x,
    y: mapElementStyles?.common.anchor.y,
  };
};

export const getMapElementClickableImage = (
  zoomLevel: number,
  clickSize: number = 0,
  hasSymbol: boolean = true
) => {
  return renderToString(
    <TopologySymbols
      name={
        hasSymbol
          ? mapItemsClickConfig?.common?.clickableIcon?.iconName
          : "notAvailable"
      }
      size={
        clickSize !== 0
          ? clickSize
          : zoomLevel === 21
          ? mapItemsClickConfig?.common?.clickableIcon?.iconSize21
          : zoomLevel === 20
          ? mapItemsClickConfig?.common?.clickableIcon?.iconSize20
          : mapItemsClickConfig?.common?.clickableIcon?.iconSize19
      }
      color={
        hasSymbol
          ? mapItemsClickConfig?.common?.clickableIcon?.color
          : colors.primary
      }
    />
  );
};

export const markerToString = (marker: JSX.Element) =>
  appConst.imageStringPrefix + encodeURIComponent(renderToString(marker));

export const getTransparentSymbol = () =>
  markerToString(
    <TopologySymbols name={"rectangle"} size={20} color={"transparent"} />
  );

export const getParametersForCpatTable = (elementData: any) => {
  return {
    adress: elementData?.data?.adress,
    dateLastDetected: `${format(
      elementData?.data?.dateLastDetected,
      "yyyy-MM-dd HH:mm"
    )}`,
    descSector: elementData?.data?.descSector,
    id: elementData?.data?.id,
    address: elementData?.data?.address,
    freqLkMid: isNull(hzToMHz(elementData?.data?.freqLkMid).toFixed(1)),
    leakMid: elementData?.data?.leakMid,
    freqIngress:
      elementData?.data?.freqIngress == "-9999"
        ? elementData?.data?.freqIngress
        : hzToMHz(elementData?.data?.freqIngress).toFixed(1),
    ingress: elementData?.data?.ingress,
    leakLTE: elementData?.data?.leakLTE,
    freqLkLTE:
      elementData?.data?.freqLkLTE == "-9999"
        ? elementData?.data?.freqLkLTE
        : hzToMHz(elementData?.data?.freqLkLTE).toFixed(1),
    status: elementData?.data?.status,
  };
};
export const getImageOverlayBounds = (
  lat: any,
  lng: any,
  imgWidth: any,
  imgHeight: any,
  isFromMdu: boolean = false,
  isFromCADSymbol: boolean = false
) => {
  if (lat && lng && imgWidth && imgHeight) {
    const northBound = moveLat(
      lat,
      (imgHeight /
        (isFromCADSymbol
          ? imageOverlayDividendMduCADSymbol
          : isFromMdu
          ? imageOverlayDividendMdu
          : imageOverlayDividend)) *
        mapCustomOverlayMeasure
    );
    const eastBound = moveLng(
      northBound,
      lng,
      (imgWidth /
        (isFromCADSymbol
          ? imageOverlayDividendMduCADSymbol
          : isFromMdu
          ? imageOverlayDividendMdu
          : imageOverlayDividend)) *
        mapCustomOverlayMeasure
    );
    const southBound = moveLat(
      lat,
      -(
        (imgHeight /
          (isFromCADSymbol
            ? imageOverlayDividendMduCADSymbol
            : isFromMdu
            ? imageOverlayDividendMdu
            : imageOverlayDividend)) *
        mapCustomOverlayMeasure
      )
    );
    const westBound = moveLng(
      northBound,
      lng,
      -(
        (imgWidth /
          (isFromCADSymbol
            ? imageOverlayDividendMduCADSymbol
            : isFromMdu
            ? imageOverlayDividendMdu
            : imageOverlayDividend)) *
        mapCustomOverlayMeasure
      )
    );
    return {
      northBound,
      eastBound,
      southBound,
      westBound,
    };
  }
};

export const getMapEleImageObj = (
  pngImagesData: any = undefined,
  symbolName: any,
  rotation: number,
  configStyle: any,
  lat: any,
  lng: any,
  isFromMdu: boolean = false,
  isFromCADSymbol: boolean = false
) => {
  const base64Info = getPngBase64Info(
    pngImagesData,
    symbolName,
    rotation,
    configStyle?.color
  );

  if (base64Info !== undefined) {
    if (base64Info?.symbol !== undefined && base64Info?.symbol !== null) {
      const overLayBounds = getImageOverlayBounds(
        lat,
        lng,
        base64Info?.width,
        base64Info?.height,
        isFromMdu,
        isFromCADSymbol
      );
      return {
        ...{
          symbol: base64Info?.symbol,
        },
        ...overLayBounds,
      };
    } else {
      const iconString = renderToString(
        <TopologySymbols name={"notAvailable"} />
      );
      const overLayBounds = getImageOverlayBounds(
        lat,
        lng,
        mapElementStyles?.common?.width,
        mapElementStyles?.common?.height,
        isFromMdu,
        isFromCADSymbol
      );
      return {
        ...{
          symbol: iconString,
        },
        ...overLayBounds,
      };
    }
  }

  return {
    url: getTransparentSymbol(),
  };
};

export const getAllFeaturesData = (elementName: string, allNodeData: any) => {
  let allFeatures: any = [];

  //store all cached features data into a list
  if (allNodeData && allNodeData?.length > 0) {
    allNodeData?.map(
      (e: any) =>
        e?.[elementName]?.data?.features &&
        allFeatures.push(...e?.[elementName]?.data?.features)
    );
  }
  return allFeatures;
};

export const getSSEleData = (ssEleName: string, ssPayload: any) => {
  let ssEleData = {};
  if (ssPayload && ssPayload?.length > 0 && typeof ssPayload !== "string") {
    ssEleData = ssPayload?.find(
      (ss: any) => ss?.features?.[0]?.properties?.type === ssEleName
    );
  }
  return ssEleData;
};

export const getActiveEleMarker = (eleData: any) => {
  const geoData = { lat: eleData?.lat, lng: eleData?.lng };
  const activeElement = {
    geometry: geoData,
    properties: {
      id: appConst.searchedAddress,
      type: appConst.searchedAddress,
      data: eleData,
    },
  };
  return activeElement;
};

export const getParametersForTapTable = (elementData: any) => {
  return {
    id: elementData?.data?.tap?.networkId,
    tapValue: elementData?.data?.tap.tapValue,
    deviceType: elementData?.data?.tap.deviceType,
    freq1: Number(isNull(elementData?.data?.tap.freq1))?.toFixed(1),
    freq2: Number(isNull(elementData?.data?.tap.freq2))?.toFixed(1),
    freq3: Number(isNull(elementData?.data?.tap.freq3))?.toFixed(1),
    freq4: Number(isNull(elementData?.data?.tap.freq4))?.toFixed(1),
    freq5: Number(isNull(elementData?.data?.tap.freq5))?.toFixed(1),
    freq6: Number(isNull(elementData?.data?.tap.freq6))?.toFixed(1),
    freq7: Number(isNull(elementData?.data?.tap.freq7))?.toFixed(1),
    freq8: Number(isNull(elementData?.data?.tap.freq8))?.toFixed(1),
    freq9: Number(isNull(elementData?.data?.tap.freq9))?.toFixed(1),
    freq10: Number(isNull(elementData?.data?.tap.freq10))?.toFixed(1),
    freq11: Number(isNull(elementData?.data?.tap.freq11))?.toFixed(1),
    freq12: Number(isNull(elementData?.data?.tap.freq12))?.toFixed(1),
    rtnF1: elementData?.data?.tap?.rtnFreq1
      ? Number(elementData?.data?.tap.rtnFreq1)?.toFixed(1)
      : null,
    rtnF2: elementData?.data?.tap?.rtnFreq2
      ? Number(elementData?.data?.tap.rtnFreq2)?.toFixed(1)
      : null,
    rtnF3: elementData?.data?.tap?.rtnFreq3
      ? Number(elementData?.data?.tap.rtnFreq3)?.toFixed(1)
      : null,
    rtnF4: elementData?.data?.tap?.rtnFreq4
      ? Number(elementData?.data?.tap.rtnFreq4)?.toFixed(1)
      : null,
    symbolName: elementData?.data?.symbolName,
  };
};

export const getClusterMarkerDetails = (clusterData: any) => {
  let clusterDetails: any = {};
  if (clusterData && clusterData?.length > 1) {
    clusterData?.map((cm: any) => {
      const cmProps = getCMStatusProps(cm?.deviceDiag);
      if (
        cmProps?.iconName &&
        clusterDetails?.hasOwnProperty(cmProps?.iconName)
      ) {
        let tempCMCount = clusterDetails[cmProps.iconName]?.count;
        let tempCMType = {
          ...clusterDetails[cmProps.iconName],
          ...{ count: tempCMCount + 1 },
        };
        clusterDetails = {
          ...clusterDetails,
          ...{ [cmProps.iconName]: tempCMType },
        };
      } else if (
        cmProps?.iconName &&
        !clusterDetails?.hasOwnProperty(cmProps?.iconName)
      ) {
        let tempCMType = {
          count: 1,
          color: cmProps?.color,
        };
        clusterDetails = {
          ...clusterDetails,
          ...{ [cmProps.iconName]: tempCMType },
        };
      }
    });
  }

  return clusterDetails;
};

export const getClusterMarkerSortedData = (clusterDetails: any) => {
  const iconSize = 12;
  let data: any = [];
  if (clusterDetails?.hasOwnProperty("d30dsAndusSpecIn")) {
    let cmType = clusterDetails?.["d30dsAndusSpecIn"];
    data.push({
      name: "d30dsAndusSpecIn",
      color: cmType?.color,
      count: cmType?.count,
      size: iconSize,
    });
  }
  if (clusterDetails?.hasOwnProperty("d31dsAndusSpecIn")) {
    let cmType = clusterDetails?.["d31dsAndusSpecIn"];
    data.push({
      name: "d31dsAndusSpecIn",
      color: cmType?.color,
      count: cmType?.count,
      size: iconSize,
    });
  }
  if (clusterDetails?.hasOwnProperty("d30dsSpecOut")) {
    let cmType = clusterDetails?.["d30dsSpecOut"];
    data.push({
      name: "d30dsSpecOut",
      color: cmType?.color,
      count: cmType?.count,
      size: iconSize,
    });
  }
  if (clusterDetails?.hasOwnProperty("d31dsSpecOut")) {
    let cmType = clusterDetails?.["d31dsSpecOut"];
    data.push({
      name: "d31dsSpecOut",
      color: cmType?.color,
      count: cmType?.count,
      size: iconSize,
    });
  }
  if (clusterDetails?.hasOwnProperty("d30usSpecOut")) {
    let cmType = clusterDetails?.["d30usSpecOut"];
    data.push({
      name: "d30usSpecOut",
      color: cmType?.color,
      count: cmType?.count,
      size: iconSize,
    });
  }
  if (clusterDetails?.hasOwnProperty("d31usSpecOut")) {
    let cmType = clusterDetails?.["d31usSpecOut"];
    data.push({
      name: "d31usSpecOut",
      color: cmType?.color,
      count: cmType?.count,
      size: iconSize,
    });
  }
  if (clusterDetails?.hasOwnProperty("d30dsAndusSpecOut")) {
    let cmType = clusterDetails?.["d30dsAndusSpecOut"];
    data.push({
      name: "d30dsAndusSpecOut",
      color: cmType?.color,
      count: cmType?.count,
      size: iconSize,
    });
  }
  if (clusterDetails?.hasOwnProperty("d31dsAndusSpecOut")) {
    let cmType = clusterDetails?.["d31dsAndusSpecOut"];
    data.push({
      name: "d31dsAndusSpecOut",
      color: cmType?.color,
      count: cmType?.count,
      size: iconSize,
    });
  }
  if (clusterDetails?.hasOwnProperty("d30NoSNPM")) {
    let cmType = clusterDetails?.["d30NoSNPM"];
    data.push({
      name: "d30NoSNPM",
      color: cmType?.color,
      count: cmType?.count,
      size: iconSize,
    });
  }
  if (clusterDetails?.hasOwnProperty("d31NoSNPM")) {
    let cmType = clusterDetails?.["d31NoSNPM"];
    data.push({
      name: "d31NoSNPM",
      color: cmType?.color,
      count: cmType?.count,
      size: iconSize,
    });
  }
  if (clusterDetails?.hasOwnProperty("d30Offline")) {
    let cmType = clusterDetails?.["d30Offline"];
    data.push({
      name: "d30Offline",
      color: cmType?.color,
      count: cmType?.count,
      size: iconSize,
    });
  }
  if (clusterDetails?.hasOwnProperty("d31Offline")) {
    let cmType = clusterDetails?.["d31Offline"];
    data.push({
      name: "d31Offline",
      color: cmType?.color,
      count: cmType?.count,
      size: iconSize,
    });
  }

  return data;
};

export const combineOltCountData = (currData: any, newData: any) => {
  let combinedData = currData?.map((olt: any) => {
    if (!olt?.ontDevice?.hasOwnProperty("data")) {
      let mergedData: any;
      newData?.filter((md: any) => {
        if (parseInt(md?.nodeId) === parseInt(olt?.ponSplitterNodeId)) {
          let countType = restructureOltCountStreamResp(md);
          mergedData = { ...olt, ...{ ontDevice: countType } };
        }
      });
      if (mergedData) {
        return mergedData;
      } else {
        return olt;
      }
    } else {
      return olt;
    }
  });
  return combinedData;
};

export const newOltCountData = (currData: any, data: any) => {
  let newData: any = [];
  currData?.map((olt: any) => {
    if (olt?.ontDevice !== undefined) {
      let nData: any = data?.find(
        (md: any) => parseInt(md?.nodeId) === parseInt(olt?.ponSplitterNodeId)
      );
      if (nData !== undefined) {
        newData.push(nData);
      }
    }
  });
  return newData;
};

export const restructureOltCountStreamResp = (data: any) => {
  let countType: OLTOntCountType = {
    data: undefined,
    isCountLoading: false,
    status: "",
    error: undefined,
  };
  if (data?.gponOntCountResponse) {
    countType = {
      data: data?.gponOntCountResponse?.data,
      status: data?.gponOntCountResponse?.error ? appConst.ERROR : appConst.OK,
      error: data?.gponOntCountResponse?.error,
      isCountLoading: false,
    };
  } else {
    const d = data?.gponOntCountResponse;
    countType = {
      data: d?.data,
      status: d?.error ? appConst.ERROR : appConst.OK,
      error: d?.error ? d?.data : null,
      isCountLoading: false,
    };
  }
  return countType;
};

export const dateIsValid = (date: any) => {
  return !Number.isNaN(new Date(date).getTime());
};

export const topoElementIsAvailable = (activeNode: any, eleType: string) => {
  let isEleAvailable: boolean = false;
  if (eleType !== appConst.supportStructure) {
    if (
      activeNode?.[eleType] &&
      activeNode?.[eleType]?.status !== storeConst.ERROR
    ) {
      isEleAvailable = true;
    }
  } else if (eleType === appConst.supportStructure) {
    if (
      activeNode?.[appConst.cableDip] ||
      activeNode?.[appConst.detailMark] ||
      activeNode?.[appConst.pole] ||
      activeNode?.[appConst.poleStrand]
    ) {
      isEleAvailable = true;
    }
  }

  return isEleAvailable;
};

interface OpenNewWindowTypes {
  link: string;
  tabTitle?: string;
}
export const openNewWin = ({ link, tabTitle }: OpenNewWindowTypes) => {
  // pick the hash from the current url and append it to the new url
  const url =
    window.location.origin + window.location.hash.split("/")[0] + link;

  let newWindow = window.open(url, "_blank")!;
  if (tabTitle) {
    newWindow.onload = () => (newWindow.document.title = tabTitle);
  } else window.open(url, "_blank");
};

export const getMemoMarkerAnchor = (
  allNodeData: any,
  memoData: any,
  pngImages: any,
  zoomLevel: any
) => {
  const index = allNodeData?.findIndex(
    (e: any) => e.nodeSiteId === memoData?.properties?.rootNodeSiteId
  );
  if (index !== -1) {
    const nodeSiteData = allNodeData?.[index];
    for (let i = 0; i < Object.keys(nodeSiteData)?.length; i++) {
      const key = Object.keys(nodeSiteData)?.[i];
      const elementData = nodeSiteData?.[key]?.data;

      if (
        typeof nodeSiteData?.[key] === "object" &&
        nodeSiteData?.[key]?.status === appConst.OK &&
        elementData !== "" &&
        !Array.isArray(elementData) &&
        elementData?.features?.[0]?.properties?.type !== appConst.memoComment
      ) {
        const df = elementData?.features?.find(
          (e: any) =>
            memoData?.properties?.data?.networkId ===
            (e?.properties?.data?.id
              ? e?.properties?.data?.id
              : e?.properties?.data?.networkId)
        );

        if (df !== undefined) {
          const anchorInfo = getBase64ScaledImageAnchor(
            pngImages,
            df?.properties.type,
            df?.properties?.data?.symbolName,
            df?.properties?.data?.rotation,
            mapElementStyles?.[
              df?.properties.type === appConst.node
                ? appConst.nodeSite
                : df?.properties.type
            ],
            zoomLevel
          );

          return anchorInfo;
        }
      }
    }
  }

  return {
    x: mapElementStyles?.memoComment?.anchor?.zoom19?.x,
    y: mapElementStyles?.memoComment?.anchor?.zoom19?.y,
  };
};

export const findEleByRensData = (
  rensType: string,
  rensData: any,
  allNodes: any
) => {
  let eleData: any = undefined;
  if (allNodes && allNodes?.length > 0) {
    allNodes?.map((nodeInfo: any) => {
      const eleListData = nodeInfo?.[rensType];
      if (eleListData) {
        const eleFeatures = eleListData?.data?.features;
        const eleIndex = eleFeatures?.findIndex(
          (f: any) => f.properties?.data?.networkId === rensData?.networkId
        );
        if (eleIndex !== -1 && eleFeatures?.[eleIndex]) {
          const eleInfo = eleFeatures?.[eleIndex];
          const activeElement = {
            geometry: {
              lat: eleInfo?.geometry?.coordinates?.[1],
              lng: eleInfo?.geometry?.coordinates?.[0],
            },
            properties: {
              id: eleInfo?.properties?.featureId,
              type: eleInfo?.properties?.type,
              data: eleInfo?.properties?.data,
              rootNodeSiteId: eleInfo?.properties?.rootNodeSiteId,
            },
          };
          eleData = activeElement;
        }
      }
    });
  }

  return eleData;
};

export const findEleFromLocatorEle = (
  eleId: string,
  eleType: string,
  eleDetails: any,
  locatorEle: any
) => {
  let eleData: any = undefined;
  if (locatorEle && locatorEle?.length > 0) {
    const eleIndex = locatorEle?.findIndex(
      (f: any) => f?.[eleId] === eleDetails?.[eleId]
    );
    if (eleIndex !== -1 && locatorEle?.[eleIndex]) {
      const eleInfo = locatorEle?.[eleIndex];
      const activeElement = {
        geometry: {
          lat: eleInfo?.geometry?.coordinates?.[1],
          lng: eleInfo?.geometry?.coordinates?.[0],
        },
        properties: {
          id: eleInfo?.[eleId],
          type: eleType,
          data: eleInfo,
        },
      };
      eleData = activeElement;
    }
  }

  return eleData;
};

export const isMduTableIcon = (feature: any) => {
  const tableIcon =
    feature?.properties?.data?.wdsLayer === appConst?.mduStrobeTable ||
    feature?.properties?.data?.wdsLayer === appConst?.wdsLayer0 ||
    feature?.properties?.data?.wdsLayer === appConst?.wdsLayer2 ||
    feature?.properties?.data?.wdsLayer === appConst?.wdsLayerDraft ||
    feature?.properties?.data?.wdsLayer === appConst?.wdsLayerLbac ||
    feature?.properties?.data?.wdsLayer === appConst?.wdsLayerLbMiscSymbol ||
    feature?.properties?.data?.wdsLayer === appConst?.wdsLayerLbMiscText ||
    feature?.properties?.data?.wdsLayer === appConst?.wdsLayerLbRoadRow ||
    feature?.properties?.data?.wdsLayer === appConst?.wdsLayerMduFloorPlan ||
    feature?.properties?.data?.wdsLayer === appConst?.wdsLayerMduPanelDesign ||
    feature?.properties?.data?.wdsLayer ===
      appConst?.wdsLayerMduPanelDesignText ||
    feature?.properties?.data?.wdsLayer === appConst?.wdsLayerMduPanelStrobe ||
    feature?.properties?.data?.wdsLayer === appConst?.wdsLayerMduSymbol ||
    feature?.properties?.data?.wdsLayer === appConst?.wdsLayerMdBorder ||
    feature?.properties?.data?.wdsLayer === appConst?.wdsLayerMdFlrText ||
    feature?.properties?.data?.wdsLayer === appConst?.wdsLayerMdKeyText ||
    feature?.properties?.data?.wdsLayer === appConst?.wdsLayerMdPanelStrobe ||
    feature?.properties?.data?.wdsLayer === appConst?.wdsLayerMdPanelSymbol ||
    feature?.properties?.data?.wdsLayer === appConst?.wdsLayerMdPanelTap ||
    feature?.properties?.data?.wdsLayer === appConst?.wdsLayerMdPanelText ||
    feature?.properties?.data?.wdsLayer === appConst?.wdsLayerMdRiserStrobe ||
    feature?.properties?.data?.wdsLayer === appConst?.wdsLayerMdRiserSymbol ||
    feature?.properties?.data?.wdsLayer === appConst?.wdsLayerTemp ||
    feature?.properties?.data?.wdsLayer === appConst?.wdsLayerMdFlrPanel ||
    feature?.properties?.data?.wdsLayer === appConst?.wdsLayerMdRiserText ||
    feature?.properties?.data?.wdsLayer === appConst?.wdsLayerMduNotes ||
    feature?.properties?.data?.wdsLayer ===
      appConst?.wdsLayerMduPanelDesignSymbol ||
    feature?.properties?.data?.wdsLayer === appConst?.wdsLayerMduPanelLabel;

  return tableIcon;
};

export const isFullDiagPresent = (teleData: any) => {
  if (teleData && teleData?.length > 0) {
    return teleData?.some(
      (e: any) =>
        e.deviceDiag?.data?.docsis !== undefined &&
        e.deviceDiag?.data?.docsis !== null &&
        e.deviceDiag?.data?.docsis !== ""
    );
  }
};
export const isFullPresent = (teleData: any) => {
  if (teleData && teleData?.length > 0) {
    return teleData?.some(
      (e: any) =>
        e?.accountSummaryResponse?.data !== undefined &&
        e?.accountSummaryResponse?.data !== null &&
        e?.accountSummaryResponse?.data !== ""
    );
  }
};
export const emtaSwitchDisplay = (techData: any) => {
  const { data: configData } = GetConfigsProps();
  const emtaSwitch = configData?.EMTA_SWITCH;
  const isMaintTech =
    techData && getAccessLevelByRoles(techData?.roles).includes(ConfigConst.MT)
      ? true
      : false;

  return isMaintTech && emtaSwitch && emtaSwitch === "true" ? true : false;
};

export const rpdSwUpgSwitchDisplay = (techData: any) => {
  const { data: configData } = GetConfigsProps();
  const rpdSWUpgSwitch = configData?.RPD_SOFTWARE_UPGRADE_SWITCH;

  return rpdSWUpgSwitch && rpdSWUpgSwitch === "true" ? true : false;
};

export const remAttrFromJsonObj = (obj: any, key: any) => {
  const { [key]: ignore, ...rest } = obj;
  return rest;
};

export const nikaFiltersDefaultSelection = (
  nikaChipfilters: any,
  currentMapCenter: any
) => {
  return {
    filters: nikaChipfilters,
    queryParams: {
      type: appConst.area,
      centerLat: currentMapCenter?.latitude,
      centerLng: currentMapCenter?.longitude,
      radius: 1000,
    },
  };
};

export const cpatFiltersDefaultSelection = (
  labels: any,
  currentMapCenter: any
) => {
  const now = new Date();
  const endDate = Math.round(now.getTime() / 1000);
  const startDate = Math.round(
    new Date(now.getFullYear(), now.getMonth(), now.getDate() - 365).getTime() /
      1000
  );
  return {
    searchMethod: appConst.statusType,
    statusType: labels?.assignedUnassigned?.toLowerCase(),
    centerLat: currentMapCenter?.latitude,
    centerLng: currentMapCenter?.longitude,
    detectionStartDate: startDate,
    detectionEndDate: endDate,
    radius: 1000,
  };
};

export const getClickOrderCategoryConfig = (order: Array<any>) => {
  const orderData = [] as any;
  const uncheckedVals = [
    "CONN",
    "CSRV",
    "RECN",
    "DISC",
    "OC",
    "EQPU",
    "PHYD",
    "HTCD",
  ];
  const getCatLabel = (label: string) => {
    if (label === "Service") return "Order Type";
    return label;
  };
  const storageResult = localStorage.getItem(CLICK_ORDERS_FILTERS_KEY);
  order?.map((oe, oi) => {
    orderData.push({
      label: getCatLabel(oe?.label),
      allChecked: true,
      orderTypes: [],
    });
    oe?.orderTypes?.map((e: any) => {
      orderData[oi].orderTypes.push({
        label: e.name,
        desc: e.desc,
        checked: storageResult?.includes(e.name)
          ? true
          : uncheckedVals?.includes(e.name)
          ? false
          : true,
      });
    });
  });
  return orderData;
};

export const getClickStatusConfig = (status: Array<any>) => {
  const statusData = [] as any;
  const uncheckedVals = ["Cancelled"];
  const storageResult = localStorage.getItem(CLICK_STATUS_FILTERS_KEY);
  const returnCheckedVal = (label: string) => {
    if (storageResult) {
      if (storageResult.includes(label)) {
        return true;
      } else return false;
    } else {
      if (uncheckedVals.includes(label)) {
        return false;
      } else return true;
    }
  };

  status?.map((e) => {
    statusData.push({ label: e, checked: returnCheckedVal(e) });
  });
  return statusData;
};

export const clickFiltersDefaultSelection = (
  currentMapCenter: any,
  clickStatusFltCpy: any,
  clickOrderFltCpy: any
) => {
  const now = new Date();
  const endDate = Math.round(
    new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59).getTime()
  );
  const startDate = Math.round(
    new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - 30,
      0,
      0
    ).getTime()
  );

  let status = [] as Array<any>;
  clickStatusFltCpy?.map((e: any) => e.checked && status.push(e.label));
  let orderType = [] as Array<any>;
  clickOrderFltCpy?.map((oc: any) =>
    oc?.orderTypes?.map((e: any) => e.checked && orderType.push(e.label))
  );

  return {
    filterParams: {
      startDate: startDate,
      endDate: endDate,
      status,
      orderType,
    },
    searchParams: {
      method: appConst.area,
      centerLat: currentMapCenter?.latitude,
      centerLng: currentMapCenter?.longitude,
      radius: 500,
    },
  };
};
export const findActiveSMTEle = (smtVal: string, activeFeatures: any) => {
  if (smtVal && activeFeatures) {
    let activeSMT = activeFeatures?.filter(
      (active: any) => active?.properties?.data?.equipmentName === smtVal
    );
    if (activeSMT?.length > 0) {
      let seleSMT = activeSMT?.[0];
      const geoData = {
        lat: seleSMT?.geometry?.coordinates?.[1],
        lng: seleSMT?.geometry?.coordinates?.[0],
      };
      const activeSMTEle = {
        geometry: geoData,
        properties: {
          id: seleSMT?.featureId,
          type: seleSMT?.properties?.type,
          data: seleSMT?.properties?.data,
          rootNodeSiteId: seleSMT?.properties?.rootNodeSiteId,
        },
      };
      return activeSMTEle;
    } else return null;
  }
};

export const combineAllNodeEle = (eleName: string, allNodesData: any) => {
  let allEleList: any = [];
  if (eleName && allNodesData?.length > 0) {
    allNodesData.map((node: any) => {
      if (node?.[eleName]?.data)
        allEleList = [...allEleList, ...node?.[eleName]?.data?.features];
    });
  }
  return allEleList;
};

export const isPSAvailable = (rensNum: string, allPSEle: any) => {
  if (rensNum && allPSEle && allPSEle?.length > 0) {
    let psElement = allPSEle?.filter(
      (ps: any) => ps?.properties?.data?.rensNumber === rensNum
    );
    if (psElement && psElement?.length > 0) return true;
  }
  return false;
};

export const findRENSElebyID = (rensVal: string, psFeatures: any) => {
  if (rensVal && psFeatures) {
    let psElement = psFeatures?.filter(
      (ps: any) => ps?.properties?.data?.rensNumber === rensVal
    );
    if (psElement?.length > 0) {
      let selePS = psElement?.[0];
      const geoData = {
        lat: selePS?.geometry?.coordinates?.[1],
        lng: selePS?.geometry?.coordinates?.[0],
      };
      const searchedPSEle = {
        geometry: geoData,
        properties: {
          id: selePS?.featureId,
          type: selePS?.properties?.type,
          data: selePS?.properties?.data,
          rootNodeSiteId: selePS?.properties?.rootNodeSiteId,
        },
      };
      return searchedPSEle;
    } else return null;
  }
};

export const getNodeIDFromSC = (rSName: string) => {
  let nodeID = "";
  let nodeNums = rSName?.split("_");
  if (nodeNums?.length > 1) nodeID = nodeNums?.[1];
  else nodeID = nodeNums?.[0];
  return nodeID;
};

export function readClipboardImage(
  event: ClipboardEvent
): Promise<HTMLImageElement> {
  return new Promise((resolve) => {
    const items = event.clipboardData?.items;
    if (!items) return;

    for (const item of items) {
      if (item.type.indexOf("image") !== -1) {
        const blob = item.getAsFile();
        if (!blob) return;

        const reader = new FileReader();
        reader.onload = () => {
          const image = new window.Image();
          image.onload = () => {
            resolve(image);
          };
          image.src = reader.result as string;
        };
        reader.readAsDataURL(blob);
      }
    }
  });
}

export function getTraceCablesData(traceData: any) {
  return traceData?.filter(
    (element: any) => element?.geometry?.type === appConst.line
  );
}

export function getTraceDataExceptCables(traceData: any) {
  return traceData?.filter(
    (element: any) => element?.geometry?.type !== appConst.line
  );
}

export const clickStatusToLocalStorage = (clickStatusFlt: any) => {
  const keysToStore = [] as any;
  clickStatusFlt?.map(
    (cat: any) => cat?.checked === true && keysToStore?.push(cat?.label)
  );
  localStorage.setItem(CLICK_STATUS_FILTERS_KEY, JSON.stringify(keysToStore));
};

export const clickOrdersToLocalStorage = (clickOrdersFlt: any) => {
  const keysToStore = [] as any;
  clickOrdersFlt?.map((cat: any, index: any) => {
    cat?.orderTypes?.map((oTypes: any) => {
      oTypes?.checked === true && keysToStore?.push(oTypes.label);
    });
  });
  localStorage.setItem(CLICK_ORDERS_FILTERS_KEY, JSON.stringify(keysToStore));
};

export const getRensSearchType = (rensType: string) => {
  if (rensType) {
    switch (rensType) {
      case "RF_ACTIVE":
        return appConst.activeEquipment;

      case "RF_POWERSUPPLY":
        return appConst.powerSupply;

      case "SPLICE_CASE":
        return appConst.spliceCases;
    }
  }
};

export const getLocatorEleTypeConfig = (eleType: any) => {
  let eleIdName = "";
  let configType = "";
  switch (eleType) {
    case appConst.powerSupply:
    case appConst.activeEquipment:
    case appConst.spliceCases:
      eleIdName = "networkId";
      configType = appConst.rensElement;
      break;
    case appConst.wirelessSite:
      eleIdName = "networkId";
      configType = appConst.wirelessSite;
      break;

    case appConst.smallCell:
      eleIdName = "siteId";
      configType = appConst.smallCell;
      break;
    case appConst.clamshell:
      eleIdName = "siteId";
      configType = appConst.clamshell;
      break;
    case appConst.rbCircuit:
      eleIdName = "buildingId";
      configType = appConst.rbCircuit;
      break;
  }

  return { eleIdName, configType };
};

export const convertRfTraceDataToTreeFormat = (traceData: any) => {
  if (traceData) {
    const data = traceData?.reduce(function (
      updatedArray: any,
      currentObj: any
    ) {
      function getParent(s: any, b: any) {
        return b?.networkId === currentObj?.parentId
          ? b
          : b?.children && b?.children?.reduce(getParent, s);
      }

      const typeTitle = getTypeTitle(currentObj?.type)?.title;
      var index = 0,
        child;
      if ("parentId" in currentObj) {
        child = updatedArray?.reduce(getParent, {});
      }
      if (child && Object.keys(child)?.length) {
        child.children = child.children || [];
        child.children.push({
          name: currentObj?.networkId, //mandatory field
          networkId: currentObj?.networkId, //mandatory field
          parentId: currentObj?.parentId, //mandatory field
          type: currentObj?.type,
          attributes: {
            deviceType: typeTitle,
            deviceModel: currentObj?.deviceModel,
            activeDeviceRens: currentObj?.activeDeviceRens,
            tapValue: currentObj?.tapValue,
          },
        });
      } else {
        while (index < updatedArray?.length) {
          if (updatedArray[index]?.parentId === currentObj?.networkId) {
            if (currentObj?.children) {
              currentObj.children = (currentObj.children || []).concat(
                updatedArray.splice(index, 1)
              );
            }
          } else {
            index++;
          }
        }
        updatedArray.push({
          name: currentObj?.networkId, //mandatory field
          networkId: currentObj?.networkId, //mandatory field
          parentId: currentObj?.parentId, //mandatory field
          type: currentObj?.type,
          attributes: {
            deviceType: typeTitle,
            deviceModel: currentObj?.deviceModel,
            activeDeviceRens: currentObj?.activeDeviceRens,
            tapValue: currentObj?.tapValue,
          },
        });
      }

      return updatedArray;
    },
    []);

    return data;
  } else {
    return [];
  }
};

export const getTypeTitle = (type: string) => {
  let eleDataConfig: any;
  elementDataConfig?.map((o, i) => {
    if (type === o.name) {
      eleDataConfig = o;
    }
  });
  return eleDataConfig;
};

export const getISODateString = (dateString: string, time: string) => {
  const date = new Date(dateString + " " + time);
  const timeZoneOffset = date.getTimezoneOffset() * 60000; // in milliseconds
  const adjustedDate = new Date(date.getTime() - timeZoneOffset);
  return adjustedDate.toISOString().split(".")[0];
};

export function getFiberPointsData(data: Array<any>) {
  let tempData: Array<any> = [];

  if (data !== undefined && data !== null) {
    for (let i = 0; i < data?.length; i++) {
      if (data?.[i]?.geometry?.type === appConst.Point)
        tempData?.push(data?.[i]);

      const segments = data?.[i]?.segments;
      for (let j = 0; j < segments?.length; j++) {
        if (segments?.[j]?.geometry?.type === appConst.Point)
          tempData?.push(segments?.[j]);
      }
    }
  }

  return tempData;
}

export function createFiberTableRowObj(
  data: any,
  parentData: any,
  cumulativeLength: number,
  isChildData: boolean
) {
  return {
    id: data?.networkId
      ? data?.networkId
      : data?.cableName
      ? data?.cableName
      : data?.spliceName,
    circuit: isChildData ? parentData?.circuitName : data?.circuitName,
    elementName: isChildData
      ? parentData?.cableName
      : data?.cableName
      ? data?.cableName
      : data?.spliceName,
    cableType: isChildData ? parentData?.cableType : data?.cableType,
    constructionType: data?.constructionType,
    gisLength: getGisLength(data?.constructionType, data?.gisLength),
    cumulativeLength: cumulativeLength,
  };
}

export function getGisLength(constructionType: string, gisLength: number) {
  return constructionType === "LP" ? 32 : gisLength ? gisLength : 0;
}

export function convertFiberTreeDataToArray(
  data: Array<any>,
  distanceLength: number
) {
  let tempData: Array<any> = [];
  let sarchedSegment = undefined;

  if (data !== undefined && data !== null) {
    let distance = Math.abs(0 - distanceLength);
    let cumulativeLength = 0;
    for (let i = 0; i < data?.length; i++) {
      tempData?.push({
        ...data?.[i],
        id: data?.[i]?.cableName
          ? `${data?.[i]?.cableName}${i}`
          : `${data?.[i]?.spliceName}${i}`,
        elementName: data?.[i]?.cableName
          ? data?.[i]?.cableName
          : data?.[i]?.spliceName,
        gisLength: getGisLength(
          data?.[i]?.constructionType,
          data?.[i]?.gisLength
        ),
        cumulativeLength,
        isSegment: false,
      });

      const segments = data?.[i]?.segments;
      for (let j = 0; j < segments?.length; j++) {
        const gisLength = getGisLength(
          segments?.[j]?.constructionType,
          segments?.[j]?.gisLength
        );
        cumulativeLength = Number(cumulativeLength) + Number(gisLength);
        const cdistance = Math.abs(cumulativeLength - distanceLength);
        if (cdistance < distance) {
          sarchedSegment = {
            ...segments?.[j],
            cumulativeLength,
            isSegment: true,
          };
          distance = cdistance;
        }
        tempData?.push({
          ...segments?.[j],
          id: `${segments?.[j]?.networkId}-${j}${i}`,
          circuitName: data?.[i]?.circuitName,
          elementName: data?.[i]?.cableName
            ? data?.[i]?.cableName
            : data?.[i]?.spliceName,
          cableType: data?.[i]?.cableType,
          gisLength,
          cumulativeLength,
          isSegment: true,
        });
      }
    }
  }

  return { updatedData: tempData, otdrInfo: sarchedSegment };
}

export function findOtdrByLatLngAndDistance(
  data: Array<any>,
  distanceLength: number
) {
  let tempData: Array<any> = [];
  let sarchedSegment = undefined;
  let lastSegment = undefined;

  if (data !== undefined && data !== null) {
    let cumulativeLength = 0;
    let closestMarkerDistance = 0;
    let closestMarkerSegmentLatlng = undefined;

    for (var i = 0; i < data?.length; i++) {
      tempData?.push({
        ...data?.[i],
        id: data?.[i]?.cableName
          ? `${data?.[i]?.cableName}${i}`
          : `${data?.[i]?.spliceName}${i}`,
        elementName: data?.[i]?.cableName
          ? data?.[i]?.cableName
          : data?.[i]?.spliceName,
        gisLength: getGisLength(
          data?.[i]?.constructionType,
          data?.[i]?.gisLength
        ),
        cumulativeLength,
        isSegment: false,
      });

      const segments = data?.[i]?.segments;
      for (var j = 0; j < segments?.length; j++) {
        let gisLength = 0;
        let gisSegmentLatlng = undefined;

        let coordinates = undefined;
        if (segments?.[j]?.geometry?.type === appConst.line) {
          coordinates = segments?.[j]?.geometry?.coordinates;
        } else if (segments?.[j]?.geometry?.type === appConst.Point) {
          coordinates = [segments?.[j]?.geometry?.coordinates];
        }

        if (coordinates) {
          for (var k = 0; k < coordinates?.length; k++) {
            const currentLatLng = {
              lat: coordinates?.[k]?.[1],
              lng: coordinates?.[k]?.[0],
            };
            if (currentLatLng?.lat && currentLatLng?.lng) {
              if (gisSegmentLatlng) {
                gisLength = getMapPointsDistance(
                  gisSegmentLatlng,
                  currentLatLng,
                  gisLength
                );
              }
              gisSegmentLatlng = currentLatLng;

              if (closestMarkerSegmentLatlng) {
                const PrevDistance = toMeters(closestMarkerDistance);
                closestMarkerDistance = getMapPointsDistance(
                  closestMarkerSegmentLatlng,
                  currentLatLng,
                  closestMarkerDistance
                );

                const newDistanceLength = toMeters(closestMarkerDistance);
                if (
                  distanceLength <= newDistanceLength &&
                  sarchedSegment === undefined
                ) {
                  const newLatLng = moveTowards(
                    closestMarkerSegmentLatlng,
                    currentLatLng,
                    distanceLength - PrevDistance
                  );
                  const newDistance = getMapPointsDistance(
                    closestMarkerSegmentLatlng,
                    newLatLng,
                    toKms(PrevDistance)
                  );

                  sarchedSegment = {
                    ...segments?.[j],
                    markerDistance: toMeters(newDistance),
                    isSegment: true,
                    currentLatLng: newLatLng,
                  };
                }
              }
              lastSegment = {
                ...segments?.[j],
                markerDistance: cumulativeLength,
                isSegment: true,
                currentLatLng,
              };

              closestMarkerSegmentLatlng = currentLatLng;
            }
          }
        }

        cumulativeLength =
          cumulativeLength +
          Number(
            getGisLength(segments?.[j]?.constructionType, toMeters(gisLength))
          );

        tempData?.push({
          ...segments?.[j],
          id: `${segments?.[j]?.networkId}-${j}${i}`,
          circuitName: data?.[i]?.circuitName,
          elementName: data?.[i]?.cableName
            ? data?.[i]?.cableName
            : data?.[i]?.spliceName,
          cableType: data?.[i]?.cableType,
          gisLength: getGisLength(
            segments?.[j]?.constructionType,
            toMeters(gisLength)
          ),
          oldGisLength: segments?.[j]?.gisLength,
          cumulativeLength,
          isSegment: true,
        });
      }
    }
  }

  return { updatedData: tempData, otdrInfo: sarchedSegment, lastSegment };
}

export interface LatLng {
  lat: number;
  lng: number;
}

export function moveTowards(
  point: LatLng,
  nextPoint: LatLng,
  distance: number
) {
  var lat1 = toRad(point?.lat);
  var lon1 = toRad(point?.lng);
  var lat2 = toRad(nextPoint.lat);
  var lon2 = toRad(nextPoint.lng);
  var dLon = toRad(nextPoint.lng - point?.lng);

  // Find the bearing from this point to the next.
  var brng = Math.atan2(
    Math.sin(dLon) * Math.cos(lat2),
    Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon)
  );
  var angDist = distance / 6371000; // Earth's radius.(in meteres)
  // Calculate the destination point, given the source and bearing.
  lat2 = Math.asin(
    Math.sin(lat1) * Math.cos(angDist) +
      Math.cos(lat1) * Math.sin(angDist) * Math.cos(brng)
  );
  lon2 =
    lon1 +
    Math.atan2(
      Math.sin(brng) * Math.sin(angDist) * Math.cos(lat1),
      Math.cos(angDist) - Math.sin(lat1) * Math.sin(lat2)
    );
  if (isNaN(lat2) || isNaN(lon2)) return null;

  return { lat: toDeg(lat2), lng: toDeg(lon2) };
}

/** usage steps
var points = [
  { lat: 47.656, lng: -122.36 },
  { lat: 47.656, lng: -122.343 },
  { lat: 47.69, lng: -122.31 },
  { lat: 47.69, lng: -122.27 },
];
var destinationPointOnPath = moveAlongPath(points, 2500);
/ destinationPointOnPath will be a latLng on the path at 2.5km from the start.
 */
export function moveAlongPath(points: Array<LatLng>, distance: number) {
  var newLatlng = undefined;
  var newDistance = distance;
  for (var i = 0; i < points?.length; i++) {
    const distanceToNextPoint = getMapPointsDistance(
      { lat: points?.[i]?.lat, lng: points?.[i]?.lng },
      { lat: points?.[i + 1]?.lat, lng: points?.[i + 1]?.lng },
      0
    );
    if (distanceToNextPoint) {
      const distanceToNextPointMeters = toMeters(distanceToNextPoint);
      if (newDistance <= distanceToNextPointMeters) {
        // distanceToNextPoint is within this point and the next.
        // Return the destination point with moveTowards().
        newLatlng = moveTowards(points[i], points[i + 1], newDistance);
        break;
      } else {
        // The destination is further from the next point. Subtract
        // distanceToNextPoint from distance and continue recursively.
        newDistance = newDistance - distanceToNextPointMeters;
      }
    }
  }
  return newLatlng;
}

/** Converts numeric degrees to radians */
function toRad(value: number): number {
  return (value * Math.PI) / 180;
}

export function toDeg(value: number): number {
  return (value * 180) / Math.PI;
}

/**
 * Formats an ISO date string to a more human-readable format.
 *
 * @param isoDateString - The date string in ISO format ("YYYY-MM-DDTHH:mm:ss").
 * @returns A string formatted as 'yyyy-MM-dd hh:mm:ss a'.
 */
export const readISODate = (isoDateString: string): string => {
  try {
    return format(parseISO(isoDateString), "yyyy-MM-dd hh:mm:ss a");
  } catch (error) {
    console.error("Failed to format date:", error);
    return isoDateString; // Return original string as fallback
  }
};

export function toMeters(value: number): number {
  return value * 1000;
}

export function toKms(value: number): number {
  return value / 1000;
}

export const convertDataToTreeFormat = (traceData: Array<any>) => {
  if (traceData) {
    const data = traceData?.reduce(function (
      updatedArray: any,
      currentObj: any
    ) {
      function getParent(s: any, b: any) {
        return b.id === currentObj.parentId
          ? b
          : b.children && b.children.reduce(getParent, s);
      }

      var index = 0,
        child;
      if ("parentId" in currentObj) {
        child = updatedArray?.reduce(getParent, {});
      }
      if (child && Object.keys(child)?.length) {
        child.children = child.children || [];
        child.children.push(currentObj);
      } else {
        while (index < updatedArray?.length) {
          if (updatedArray?.[index]?.parentId === currentObj?.id) {
            if (currentObj?.children) {
              currentObj.children = (currentObj.children || []).concat(
                updatedArray.splice(index, 1)
              );
            }
          } else {
            index++;
          }
        }
        updatedArray.push(currentObj);
      }

      return updatedArray;
    },
    []);

    return data;
  } else {
    return [];
  }
};

export function getAllTreePaths(arr: Array<any>, path: Array<any> = []) {
  let result: Array<any> = [];
  arr.forEach((item: any) => {
    if (item.children) {
      // has children, not a leaf
      // add all its children
      result.push(...getAllTreePaths(item.children, [...path, item]));
    } else {
      // no children, is leaf
      result.push([...path, item]); // add its data
    }
  });
  return result;
}

export function fiberAllRoutePaths(traceData: Array<any>): Array<any> {
  if (traceData) {
    var newData: any = {};
    var index = 0;
    for (let i = 0; i < traceData?.length; i++) {
      const item = traceData?.[i];

      if (i === 0) {
        newData = {
          [index]: {
            parentId: item?.fiberEquipId,
            data: [item],
          },
        };
      } else {
        var startEquipMentId = null;
        var endEquipMentId = null;
        if (item?.cgSeq === 1) {
          index = index + 1;
          startEquipMentId = item?.fiberEquipId;
        } else {
          startEquipMentId = newData?.[index]?.parentId;
          if (item?.fiberEquipId) {
            endEquipMentId = item?.fiberEquipId;
          }
        }

        var updatedArray = [];
        if (newData?.[index]?.data) {
          updatedArray = [...newData?.[index]?.data, item];
        } else {
          updatedArray = [item];
        }
        newData = {
          ...newData,
          [index]: {
            ...newData?.[index],
            parentId: startEquipMentId,
            id: endEquipMentId,
            data: updatedArray,
          },
        };
      }
    }

    var array: Array<any> = [];
    Object.keys(newData)?.map((e) => {
      array.push(newData?.[e]);
    });

    return getAllTreePaths(convertDataToTreeFormat(array));
  } else {
    return [];
  }
}

export function getAllPossibleOtdrs(
  traceData: Array<any>,
  distanceLength: number
) {
  var allOtdrs: Array<any> = [];
  const data = fiberAllRoutePaths(traceData);
  for (var i = 0; i < data?.length; i++) {
    var finalArr: Array<any> = [];
    const innerArr = data?.[i];
    for (var j = 0; j < innerArr?.length; j++) {
      finalArr = [...finalArr, ...innerArr?.[j]?.data];
    }

    const otdr = findOtdrByLatLngAndDistance(
      sortSegmentsByDistance(finalArr),
      distanceLength
    );
    if (otdr?.otdrInfo) {
      allOtdrs.push(otdr?.otdrInfo);
    } else {
      allOtdrs.push(otdr?.lastSegment);
    }

    allOtdrs = allOtdrs?.filter(
      (item, index, self) =>
        index === self?.findIndex((t) => t?.networkId === item?.networkId)
    );
  }

  return allOtdrs;
}

export const toFixedWithoutZeros = (num: number, precision: number = 2) =>
  `${Number.parseFloat(num.toFixed(precision))}`;

export function sortSegmentsByDistance(traceData: Array<any>) {
  let sortedTraceData: Array<any> = [];
  if (traceData?.length > 0) {
    traceData?.map((splice: any) => {
      const spliceLoc = splice?.geometry;
      if (spliceLoc?.type && splice?.segments?.length > 1) {
        let numOfSeg = splice?.segments?.length;
        let firstSeg = splice?.segments?.[0];
        let lastSeg = splice?.segments?.[numOfSeg - 1];

        let originCoord = {
          lat: spliceLoc?.coordinates?.[1],
          lng: spliceLoc?.coordinates?.[0],
        };
        const firstSegDist = getClosestDist(
          originCoord,
          firstSeg?.geometry?.coordinates,
          firstSeg?.geometry?.type
        );
        const lastSegDist = getClosestDist(
          originCoord,
          lastSeg?.geometry?.coordinates,
          lastSeg?.geometry?.type
        );

        if (lastSegDist < firstSegDist) {
          let revertedSeg: Array<any> = splice?.segments?.toReversed();

          for (var i = 0; i < revertedSeg?.length; i++) {
            if (revertedSeg?.[i]?.geometry?.type === appConst.line) {
              const geometry = {
                ...revertedSeg[i]?.geometry,
                coordinates:
                  revertedSeg?.[i]?.geometry?.coordinates?.toReversed(),
              };
              revertedSeg[i] = { ...revertedSeg[i], geometry };
            }
          }

          let tempSplicData = { ...splice, ...{ segments: revertedSeg } };

          sortedTraceData.push(tempSplicData);
        } else {
          sortedTraceData.push(splice);
        }
      } else {
        sortedTraceData.push(splice);
      }
    });
  }
  return sortedTraceData;
}

export function getClosestDist(originPoint: any, coords: any, segType: string) {
  let closestDist: any;
  if (segType === appConst.Point) {
    let toPoint = { lat: coords?.[1], lng: coords?.[0] };
    closestDist = getMapPointsDistance(originPoint, toPoint, 0);
  } else if (segType === appConst.line) {
    coords?.map((c: any) => {
      let cToPoint = { lat: c?.[1], lng: c?.[0] };
      let distance = getMapPointsDistance(originPoint, cToPoint, 0);
      if (closestDist && distance < closestDist) closestDist = distance;
      else if (closestDist === undefined) closestDist = distance;
    });
  }
  return closestDist;
}

export function getTapNetworkIdsFromPowertraceData(
  data: Array<any>
): Array<string> {
  if (data)
    return data
      ?.filter((element) => element?.type === appConst.taps)
      .map((element) => element?.networkId);
  else return [];
}

export function getUniquePayload(
  data: Array<any>,
  id = "networkId"
): Array<any> {
  if (data) {
    return data?.filter(
      (item, index, self) =>
        index === self?.findIndex((t) => t?.[id] === item?.[id])
    );
  } else return [];
}
