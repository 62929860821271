import { ONTAlarm } from "../../../../models/account/diagnostics/ont/ONTAlarm";

export const ONT_ALARMS_BEGIN = "ONT_ALARMS_BEGIN";
export const ONT_ALARMS_SUCCESS = "ONT_ALARMS_SUCCESS";
export const ONT_ALARMS_FAILURE = "ONT_ALARMS_FAILURE";

interface ONTAlarmsBegin {
  type: typeof ONT_ALARMS_BEGIN;
}

interface ONTAlarmsSuccess {
  type: typeof ONT_ALARMS_SUCCESS;
  payload: ONTAlarm;
}

interface ONTAlarmsFailure {
  type: typeof ONT_ALARMS_FAILURE;
  payload: Error;
}

export type ONTAlarmsActionTypes =
  | ONTAlarmsBegin
  | ONTAlarmsSuccess
  | ONTAlarmsFailure;
