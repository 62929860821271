import { IHPQsipData } from "../../../../models/account/diagnostics/ihp/IHPQsipData";
import {
  IHP_QSIP_DATA_BEGIN,
  IHP_QSIP_DATA_SUCCESS,
  IHP_QSIP_DATA_FAILURE,
  IhpQsipDataActionTypes,
} from "../../../../types/account/diagnostics/ihp/IHPQsipData";

export function ihpQsipDataBegin(): IhpQsipDataActionTypes {
  return {
    type: IHP_QSIP_DATA_BEGIN,
  };
}

export function ihpQsipDataSuccess(
  ihpQsipdata: IHPQsipData
): IhpQsipDataActionTypes {
  return {
    type: IHP_QSIP_DATA_SUCCESS,
    payload: ihpQsipdata,
  };
}

export function ihpQsipDataFailure(error: Error): IhpQsipDataActionTypes {
  return {
    type: IHP_QSIP_DATA_FAILURE,
    payload: error,
  };
}
