import { useState } from "react";
import { TableRow, TableHead } from "@material-ui/core";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import { EnhancedTableProps } from "../overview/DeviceListTableHead";

const PodsListTableHead = (props: EnhancedTableProps) => {
  const { onSearch, onSorting } = props;
  const [searchName, setSearchName] = useState("");
  const [searchMac, setSearchMac] = useState("");
  const [searchSerial, setSearchSerial] = useState("");
  const [searchModel, setSearchModel] = useState("");
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");
  const onSortingChange = (field: string) => {
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

    setSortingField(field);
    setSortingOrder(order);
    onSorting(field, order);
  };
  const resetSearchValue = () => {
    setSearchName("");
    setSearchMac("");
    setSearchSerial("");
    setSearchModel("");
    onSearch("", "");
  };
  const onNameInputChange = (value: any, by: string) => {
    setSearchName(value);
    onSearch(value, by);
  };
  const onMACInputChange = (value: string, by: string) => {
    let filterMac = value.replace(/[^a-zA-Z0-9]/g, "");
    setSearchMac(value);
    onSearch(filterMac, by);
  };
  const onSerialInputChange = (value: any, by: string) => {
    setSearchSerial(value);
    onSearch(value, by);
  };
  const onModelInputChange = (value: any, by: string) => {
    setSearchModel(value);
    onSearch(value, by);
  };

  return (
    <>
      <TableHead className="pTabHead">
        <TableRow>
          <th onClick={() => onSortingChange("deviceFriendlyName")}>
            <span>Name</span>
            {sortingField && sortingField === "deviceFriendlyName" ? (
              <button className="deviceSortBtn">
                {sortingOrder === "asc" ? (
                  <FaSortUp color="black" />
                ) : (
                  <FaSortDown color="black" />
                )}
              </button>
            ) : (
              <></>
            )}
          </th>
          <th onClick={() => onSortingChange("model")}>
            <span>Model</span>
            {sortingField && sortingField === "model" ? (
              <button className="deviceSortBtn">
                {sortingOrder === "asc" ? (
                  <FaSortUp color="black" />
                ) : (
                  <FaSortDown color="black" />
                )}
              </button>
            ) : (
              <></>
            )}
          </th>
          <th>MAC Address</th>
          <th>Serial Number</th>
          <th>Status</th>
          <th>RSSI</th>
        </TableRow>
        <TableRow>
          <td>
            <input
              type="text"
              className="sInput"
              placeholder="Search.."
              value={searchName}
              onFocus={resetSearchValue}
              onChange={(e) => onNameInputChange(e.target.value, "name")}
            />
          </td>
          <td>
            <input
              type="text"
              className="sInput"
              placeholder="Search.."
              value={searchModel}
              onFocus={resetSearchValue}
              onChange={(e) => onModelInputChange(e.target.value, "model")}
            />
          </td>
          <td>
            <input
              type="text"
              className="sInput"
              placeholder="Search.."
              value={searchMac}
              onFocus={resetSearchValue}
              onChange={(e) =>
                onMACInputChange(e.target.value.toString(), "macAddress")
              }
            />
          </td>
          <td>
            <input
              type="text"
              className="sInput"
              placeholder="Search.."
              value={searchSerial}
              onFocus={resetSearchValue}
              onChange={(e) => onSerialInputChange(e.target.value, "serialNum")}
            />
          </td>
          <td></td>
          <td></td>
        </TableRow>
      </TableHead>
    </>
  );
};

export default PodsListTableHead;
