import { Image, Transformer } from "react-konva";
import { KonvaEventObject } from "konva/lib/Node";
import { useRef, useEffect } from "react";

import {
  ICommonDrawingProps,
  IGeoEditShape,
  getOpacity,
} from "../GeoEditCanvas";

export interface IGeoImagesShape extends IGeoEditShape {
  image: HTMLImageElement;
  width: number;
  height: number;
}

interface Props {
  images: IGeoImagesShape[];
  setImages: (rect: IGeoImagesShape[]) => void;
  cdp: ICommonDrawingProps;
  onClick: ({ id }: { id: string }) => void;
}

export default function Images({ images, setImages, cdp, onClick }: Props) {
  return (
    <>
      {images.map((img) => (
        <Shape
          key={img.id}
          shape={img}
          onClick={onClick}
          shapes={images}
          setShapes={setImages}
          cdp={cdp}
        />
      ))}
    </>
  );
}

interface ShapeProps {
  shape: IGeoImagesShape;
  shapes: IGeoImagesShape[];
  setShapes: (rect: IGeoImagesShape[]) => void;
  cdp: ICommonDrawingProps;
  onClick: ({ id }: { id: string }) => void;
}
const Shape = ({ shape, shapes, setShapes, cdp, onClick }: ShapeProps) => {
  const shapeRef = useRef<any>(null);
  const trRef = useRef<any>(null);
  const { selectedShape, isSelected: isSSelected } = cdp;
  const isSelected = isSSelected({ id: shape.id, shape: "image" });

  const handleDragStart = (e: KonvaEventObject<DragEvent>) => {
    cdp.setDrawingType("none");
    const id = e.target.id();
    setShapes(shapes.map((s) => ({ ...s, isDragging: s.id === id })));
  };

  const handleDragEnd = () =>
    setShapes(shapes.map((s) => ({ ...s, isDragging: false })));

  useEffect(() => {
    if (isSelected && trRef.current) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
    if (!isSelected && shapeRef.current) {
      shapeRef.current.scaleX(1);
      shapeRef.current.scaleY(1);
    }
  }, [isSelected]);

  const handleTREnd = () => {
    const node = shapeRef.current;
    if (node) {
      const scaleX = node.scaleX();
      const scaleY = node.scaleY();
      node.scaleX(1);
      node.scaleY(1);

      if (selectedShape?.id && selectedShape.shape === "image") {
        setShapes(
          shapes.map((s) =>
            s.id === selectedShape.id
              ? {
                  ...s,
                  x: node.x(),
                  y: node.y(),
                  width: Math.round(node.width() * scaleX),
                  height: Math.round(node.height() * scaleY),
                  rotation: node.rotation(),
                }
              : s
          )
        );
      }

      trRef.current.getLayer().batchDraw();
    }
  };

  return (
    <>
      <Image
        ref={shapeRef}
        x={shape.x}
        y={shape.y}
        image={shape.image}
        width={shape.width}
        height={shape.height}
        rotation={shape.rotation}
        opacity={getOpacity(shape.opacity)}
        draggable
        scaleX={shape.isDragging ? 1.1 : 1}
        scaleY={shape.isDragging ? 1.1 : 1}
        onMouseDown={handleDragStart}
        onDragEnd={handleDragEnd}
        onClick={() => onClick({ id: shape.id })}
      />
      {selectedShape && isSelected && (
        <Transformer ref={trRef as any} onTransformEnd={handleTREnd} />
      )}
    </>
  );
};
