import * as React from "react";
import { Divider } from "@material-ui/core";
import AppLabels from "../../constants/App_Labels";
import Loader from "../../resources/images/loader.webp";
import ConfigConst from "../../constants/ConfigConst";
import { isNull } from "../../components/reusable/Util";

export const NeighbourhoodTopBanner: React.FC<any> = (props) => {
  const { data, expand, cmMacSearchValue, modemType } = props;
  const labels = AppLabels();

  interface IKeyValue {
    text: string;
    value: string | number | JSX.Element;
  }

  const KeyValuesTitle = (props: IKeyValue) => {
    const { text, value } = props;
    return (
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <span style={{ fontWeight: 600 }}>{text}:</span>
        <span style={{ marginLeft: "5px" }}>{value}</span>
      </span>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "20px",
        fontSize: "14px",
      }}
    >
      <>
        <section
          style={{
            display: "flex",
            flexDirection: expand ? "column" : "row",
            gap: "6px",
          }}
        >
          <div>
            {" "}
            <span style={{ fontWeight: 600 }}>
              {labels.neighbourhood.toUpperCase()}:
            </span>
            &nbsp;
            <span>{isNull(cmMacSearchValue)}</span>
            &nbsp;
            {modemType === ConfigConst.emta ? (
              <span className="accTopology">
                {" "}
                {ConfigConst.emta.toUpperCase()}
              </span>
            ) : modemType === ConfigConst.ecmstb ? (
              <span className="accTopology">
                {" "}
                {ConfigConst.ecm.toUpperCase()}
              </span>
            ) : (
              modemType === ConfigConst.oldstb && (
                <span className="accTopology">
                  {" "}
                  {ConfigConst.stb.toUpperCase()}
                </span>
              )
            )}
          </div>
          <Divider orientation="vertical" hidden={expand} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "20px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <KeyValuesTitle
              text={labels.node_id.toUpperCase()}
              value={isNull(data?.nodeId)}
            />
            <div
              style={{
                display: expand ? "none" : "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "20px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <KeyValuesTitle
                text="Active"
                value={
                  data?.active === undefined ? (
                    <img src={Loader} alt="loading" height="20px" />
                  ) : (
                    `${data?.active}%`
                  )
                }
              />
              <KeyValuesTitle
                text="Assigned"
                value={
                  data?.assigned === undefined ? (
                    <img src={Loader} alt="loading" height="20px" />
                  ) : (
                    `${data?.assigned}`
                  )
                }
              />
              <KeyValuesTitle
                text="Responsive"
                value={
                  data?.responsive === undefined ? (
                    <img src={Loader} alt="loading" height="20px" />
                  ) : (
                    `${data?.responsive}`
                  )
                }
              />
              <KeyValuesTitle
                text="Unresponsive"
                value={
                  data?.unresponsive === undefined ? (
                    <img src={Loader} alt="loading" height="20px" />
                  ) : (
                    `${data?.unresponsive}`
                  )
                }
              />
            </div>
          </div>
        </section>

        <Divider orientation="vertical" hidden={!expand} />
        <section
          style={{
            display: expand ? "flex" : "none",
          }}
        >
          <span
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "flex-start",
              paddingLeft: "10px",
            }}
          >
            <KeyValuesTitle
              text="Active"
              value={
                data?.active === undefined ? (
                  <img src={Loader} alt="loading" height="20px" />
                ) : (
                  `${data?.active}%`
                )
              }
            />
            <KeyValuesTitle
              text="Assigned"
              value={
                data?.assigned === undefined ? (
                  <img src={Loader} alt="loading" height="20px" />
                ) : (
                  `${data?.assigned}`
                )
              }
            />
          </span>
          <span
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "flex-start",
              paddingLeft: "10px",
            }}
          >
            <KeyValuesTitle
              text="Responsive"
              value={
                data?.responsive === undefined ? (
                  <img src={Loader} alt="loading" height="20px" />
                ) : (
                  `${data?.responsive}`
                )
              }
            />
            <KeyValuesTitle
              text="Unresponsive"
              value={
                data?.unresponsive === undefined ? (
                  <img src={Loader} alt="loading" height="20px" />
                ) : (
                  `${data?.unresponsive}`
                )
              }
            />
          </span>
          <span
            style={{
              display: expand ? "flex" : "none",
              flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "flex-start",
              paddingLeft: "150px",
            }}
          >
            <KeyValuesTitle
              text="Start"
              value={
                data?.startTime === undefined ? (
                  <img src={Loader} alt="loading" height="20px" />
                ) : (
                  `${data?.startTime}`
                )
              }
            />
            <KeyValuesTitle
              text="End"
              value={
                data?.endTime === undefined ? (
                  <img src={Loader} alt="loading" height="20px" />
                ) : (
                  `${data?.endTime}`
                )
              }
            />
          </span>
        </section>
      </>
    </div>
  );
};
