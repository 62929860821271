export const FACTORY_RESET_BEGIN = "FACTORY_RESET_BEGIN";
export const FACTORY_RESET_SUCCESS = "FACTORY_RESET_SUCCESS";
export const FACTORY_RESET_FAILURE = "FACTORY_RESET_FAILURE";
export const FACTORY_RESET_STATUS = "FACTORY_RESET_STATUS";
interface FactoryResetBegin {
    type: typeof FACTORY_RESET_BEGIN
}
interface FactoryResetSuccess {
    type: typeof FACTORY_RESET_SUCCESS
};

interface FactoryResetFailure {
    type: typeof FACTORY_RESET_FAILURE,
    payload: Error
};
interface FactoryResetStatus {
    type: typeof FACTORY_RESET_STATUS
};

export type FactoryResetActionTypes = FactoryResetBegin | FactoryResetSuccess | FactoryResetFailure |FactoryResetStatus