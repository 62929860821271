import { shallowEqual, useDispatch, useSelector } from "react-redux";
import IHPClient from "../../network/httpclient/ihp/IHPClient";
import DiagContainer from "../reusable/DiagContainer";
import ReloadData from "../reusable/ReloadData";
import { GLOBAL_CONST } from "../../constants/Global";
import CustomAccordion from "../reusable/CustomAccordion";
import { isNull } from "../reusable/Util";
import ExceptionBox from "../reusable/ExceptionBox";

const QSIPData = (props: any) => {
  const { homeNum } = props;
  const dispatch = useDispatch();
  const { qsipLoading, qsipInfo, qsipError } = useSelector(
    (state: any) => ({
      qsipInfo: state.AccountState.IHPQsipDataInfo.data,
      qsipLoading: state.AccountState.IHPQsipDataInfo.isLoading,
      qsipError: state.AccountState.IHPQsipDataInfo.error,
    }),
    shallowEqual
  );
  const refreshData = () => {
    dispatch(IHPClient.getIHPQsipData(GLOBAL_CONST.telPrefix + homeNum));
  };

  return (
    <>
      <div className="refreshCont">
        <ReloadData refreshData={refreshData} />
      </div>
      <div className="cmSummaryCont">
        <DiagContainer
          isLoading={qsipLoading}
          data={qsipInfo}
          error={qsipError}
          sectionName="QSIP Data"
        >
          {!qsipInfo ? (
            <div className="diagErrorCont">
              <ExceptionBox
                headerText="No Data to display"
                bodyText="Please try refreshing or contact the Tech Insight admin if data is missing."
                primaryBtn={true}
                secBtn={false}
                handleRefresh={() => refreshData()}
              />
            </div>
          ) : (
            <div>
              <CustomAccordion
                accTitle="USER DATA"
                accBodyElement={
                  <table className="dataTableDetails">
                    <thead>
                      <th id="nameCol">NAME</th>
                      <th id="valueCol">VALUE</th>
                    </thead>
                    {qsipInfo && qsipInfo.userData !== undefined ? (
                      <tbody>
                        <tr>
                          <td>SIP URI</td>
                          <td>{isNull(qsipInfo.userData.sipUri)}</td>
                        </tr>
                        <tr>
                          <td>ACCOUNT STATUS</td>
                          <td>{isNull(qsipInfo.userData.accountStatus)} </td>
                        </tr>
                        <tr>
                          <td>Is REGISTERED</td>
                          <td>{isNull(qsipInfo.userData.isRegistered)} </td>
                        </tr>
                        <tr>
                          <td>NUMBER OF CONTACTS</td>
                          <td>{isNull(qsipInfo.userData.numberOfContacts)} </td>
                        </tr>
                        <tr>
                          <td>CONTACTS</td>

                          <td>
                            {isNull(
                              qsipInfo.userData.contacts
                                ? qsipInfo.userData.contacts?.map(
                                    (contacts: any) => (
                                      <p className="noFormating">
                                        {" "}
                                        {contacts}{" "}
                                      </p>
                                    )
                                  )
                                : ""
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>SERVICE PACKAGE</td>
                          <td>{isNull(qsipInfo.userData.servicePackage)} </td>
                        </tr>
                        <tr>
                          <td>SERVICES</td>
                          <td>
                            {isNull(
                              qsipInfo.userData.services
                                ? qsipInfo.userData.services?.map(
                                    (services: any) => (
                                      <p className="noFormating">
                                        {" "}
                                        {services}{" "}
                                      </p>
                                    )
                                  )
                                : ""
                            )}
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td className="noDevices" colSpan={4}>
                            No Data Available...
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                }
              />
              <CustomAccordion
                accTitle="LINE DATA"
                accBodyElement={
                  <table className="dataTableDetails">
                    <thead>
                      <th id="nameCol">NAME</th>
                      <th id="valueCol">VALUE</th>
                    </thead>
                    {qsipInfo && qsipInfo.userData !== undefined ? (
                      <tbody>
                        <tr>
                          <td>ENDPT ID</td>
                          <td>{isNull(qsipInfo.lineData.endptId)}</td>
                        </tr>
                        <tr>
                          <td>VMG</td>
                          <td> {isNull(qsipInfo.lineData.vmg)}</td>
                        </tr>
                        <tr>
                          <td>ZONE IDs</td>
                          <td> {isNull(qsipInfo.lineData.zoneIds)}</td>
                        </tr>
                        <tr>
                          <td>CLIENT TYPE</td>
                          <td> {isNull(qsipInfo.lineData.clientType)}</td>
                        </tr>
                        <tr>
                          <td>IS STATIC CLIENT</td>
                          <td> {isNull(qsipInfo.lineData.isStaticClient)}</td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td className="noDevices" colSpan={4}>
                            No Data Available...
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                }
              />
              <CustomAccordion
                accTitle="CALL DATA"
                accBodyElement={
                  <table className="dataTableDetails">
                    <thead>
                      <th id="nameCol">NAME</th>
                      <th id="valueCol">VALUE</th>
                    </thead>
                    {qsipInfo && qsipInfo.callData !== undefined ? (
                      <tbody>
                        <tr>
                          <td>NUMBER OF ACTIVE SESSIONS</td>
                          <td>
                            {isNull(qsipInfo.callData.numberOfActiveSessions)}
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td className="noDevices" colSpan={4}>
                            No Data Available...
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                }
              />
              <CustomAccordion
                accTitle="ENDPOINT DATA"
                accBodyElement={
                  <table className="dataTableDetails">
                    <thead>
                      <th id="nameCol">NAME</th>
                      <th id="valueCol">VALUE</th>
                    </thead>
                    {qsipInfo && qsipInfo.endpointData !== undefined ? (
                      <tbody>
                        <tr>
                          <td>URI</td>
                          <td>{isNull(qsipInfo.endpointData.uri)}</td>
                        </tr>
                        <tr>
                          <td>FIRST NAME</td>
                          <td>{isNull(qsipInfo.endpointData.firstName)}</td>
                        </tr>
                        <tr>
                          <td>LAST NAME</td>
                          <td>{isNull(qsipInfo.endpointData.lastName)}</td>
                        </tr>
                        <tr>
                          <td>TIMEZONE</td>
                          <td>{isNull(qsipInfo.endpointData.timezone)}</td>
                        </tr>
                        <tr>
                          <td>LOCATION</td>
                          <td>{isNull(qsipInfo.endpointData.location)}</td>
                        </tr>
                        <tr>
                          <td>SUBDOMAIN</td>
                          <td>{isNull(qsipInfo.endpointData.subdomain)}</td>
                        </tr>
                        <tr>
                          <td>EP NAME</td>
                          <td>{isNull(qsipInfo.endpointData.epName)}</td>
                        </tr>
                        <tr>
                          <td>VMG NAME</td>
                          <td>{isNull(qsipInfo.endpointData.vmgName)}</td>
                        </tr>
                        <tr>
                          <td>CLIENT TYPE</td>
                          <td>{isNull(qsipInfo.endpointData.clientType)}</td>
                        </tr>
                        <tr>
                          <td>DN</td>
                          <td>{isNull(qsipInfo.endpointData.dn)}</td>
                        </tr>
                        <tr>
                          <td>OPTIONS</td>
                          <td>
                            {isNull(
                              qsipInfo.endpointData.options
                                ? qsipInfo?.endpointData?.options?.map(
                                    (options: any) => (
                                      <p className="noFormating"> {options} </p>
                                    )
                                  )
                                : ""
                            )}
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td className="noDevices" colSpan={4}>
                            No Data Available...
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                }
              />
            </div>
          )}
        </DiagContainer>
      </div>
    </>
  );
};

export default QSIPData;
