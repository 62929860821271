import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../middleware/apiActions";
import { OLT_CARDS_URI } from "../../../middleware/apiEndpoints";
import { StandardReduxType } from "../../../reduxTypes";
import { decrApiCall, incrApiCall } from "../../mapItems/mapDetails";

const slice = createSlice({
  name: "oltCards",
  initialState: {
    data: undefined,
    isLoading: false,
    error: undefined,
    status: "",
  } as StandardReduxType,
  reducers: {
    oltCardsBegin: (s) => {
      s.isLoading = true;
    },
    oltCardsSuccess: (s, { payload }) => {
      s.isLoading = false;
      s.data = payload;
      s.status = "OK";
    },
    oltCardsFailure: (s, { payload }) => {
      s.isLoading = false;
      s.error = payload;
      s.data = undefined;
      s.status = "ERROR";
    },
  },
});

const { oltCardsBegin, oltCardsSuccess, oltCardsFailure } = slice.actions;
export default slice.reducer;

export const getGponOltCards = (networkId: string) =>
  apiCallBegan({
    url: OLT_CARDS_URI(networkId),
    onStart: oltCardsBegin.type,
    onSuccess: oltCardsSuccess.type,
    onError: oltCardsFailure.type,
    onStartDispatch: incrApiCall.type,
    onSuccessDispatch: decrApiCall.type,
    onErrorDispatch: decrApiCall.type,
  });
