import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../middleware/apiActions";
import { MDU_ANNOTATION_BLOCK } from "../../../middleware/apiEndpoints";
import storeConst from "../../../storeConst";
import { decrApiCall, incrApiCall } from "../mapDetails";

const slice = createSlice({
  name: "mduAnnotationBlock",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    getMduAnnotationBlockBegin: (mduAnnotationBlock) => {
      mduAnnotationBlock.isLoading = true;
      mduAnnotationBlock.status = "";
    },
    getMduAnnotationBlockSuccess: (mduAnnotationBlock, action) => {
      mduAnnotationBlock.isLoading = false;
      mduAnnotationBlock.data = action.payload;
      mduAnnotationBlock.status = storeConst.OK;
    },
    getMduAnnotationBlockFailure: (mduAnnotationBlock, action) => {
      mduAnnotationBlock.isLoading = false;
      mduAnnotationBlock.error = action.payload;
      mduAnnotationBlock.data = undefined;
      mduAnnotationBlock.status = storeConst.ERROR;
    },
  },
});

const {
  getMduAnnotationBlockBegin,
  getMduAnnotationBlockSuccess,
  getMduAnnotationBlockFailure,
} = slice.actions;
export default slice.reducer;

export const getMduAnnotationBlock =
  (owningDrawing: any) => (dispatch: any) => {
    dispatch(
      apiCallBegan({
        url: `${MDU_ANNOTATION_BLOCK}/${owningDrawing}`,
        method: "get",
        onStart: getMduAnnotationBlockBegin.type,
        onSuccess: getMduAnnotationBlockSuccess.type,
        onError: getMduAnnotationBlockFailure.type,
        onStartDispatch: incrApiCall.type,
        onSuccessDispatch: decrApiCall.type,
        onErrorDispatch: decrApiCall.type,
      })
    );
  };
