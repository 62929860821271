import { AppBar, Box, Tab, Tabs } from "@material-ui/core";
import { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";

import "../../design-tokens/rpd-page/RPDPage.scss";
import { GetTechInfo } from "../../get-api/GetResponse";
import TECHClient from "../../network/httpclient/technician/TECHClient";
import RPDSearchTab from "./RPDSearchTab";
import RPDRegistrationTab from "./RPDRegistrationTab";
import RPDSwapTab from "./RPDSwapTab";
import RPDSwapDA2200Tab from "./RPDSwapDA2200Tab";
import RPDActivationTab from "./RPDActivationTab";
import RPDProvisioningTab from "./RPDProvisioningTab";
import RPDSWUpgradeTab from "./RPDSWUpgradeTab";
import CASAVideoExecTab from "./CASAVideoExecTab";
import RPDOrderDetailTab from "./RPDOrderDetailTab";
import VccapSWupgradeTab from "./VccapSWupgradeTab";
import VccapUATportTab from "./VccapUATportTab";
import VccapRpdConfigTab from "./VccapRpdConfigTab";
import RPDActivationProvisionTab from "./RPDActivationProvisionTab";
import RPDSWUpgradeBasedMacTab from "./RPDSWUpgradeBasedMacTab";
import VccapPage from "./VccapPage";
import { setOrderId } from "../../store/ducksPattern/rpd/rpdOrderInfo";
import AppLabels from "../../constants/App_Labels";
import ConfigConst from "../../constants/ConfigConst";
import ExceptionBox from "../../components/reusable/ExceptionBox";
import LoaderGif from "../../resources/images/loader.webp";
import RPDClient from "../../network/httpclient/rpd/RPDClient";
import SideTabs from "../../components/reusable/SideTabs";
import { getAccessLevelByRoles } from "../../components/reusable/Util";
import { rpdSwUpgSwitchDisplay } from "../../topology/util/utilFuncs";
import RPDDecommissionTab from "./RPDDecommissionTab";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const RccapPage = (props: any) => {
  const techData = GetTechInfo()?.data;
  const isLoading = GetTechInfo()?.isLoading;
  const isRPDSwUpg = rpdSwUpgSwitchDisplay(techData);

  const labels = AppLabels();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);

  const [isRPDAvailable, setIsRPDAvailable] = useState(false);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const [orderSearchValue, setOrderSearchValue] = useState<string | null>("");
  const [orderSearchType, setOrderSearchType] = useState("");
  const [searchHistory, setSearchHistory] = useState([]);
  let tabsItems = [];

  useEffect(() => {
    if (orderSearchType == "Order" && orderSearchValue !== null) {
      dispatch(setOrderId(orderSearchValue));
      dispatch(RPDClient.getOrderInfo(orderSearchValue));
      setValue(3);
    }
  }, [orderSearchValue]);

  const profileRef = useRef(false);

  const profileApi = useCallback(() => {
    if (techData === undefined && !profileRef.current) {
      dispatch(TECHClient.getTechniciansProfile());
      profileRef.current = true;
    } else if (techData !== undefined) {
      // techData?.roles?.map((item: any) => {
      //   if (
      //     item === ConfigConst.MAINTENANCE_TECH_MANAGER ||
      //     item.includes(ConfigConst.MAINTENANCE_TECH) ||
      //     item.includes(ConfigConst.PROVISIONING_TECH) ||
      //     item.includes(ConfigConst.PROVISIONING_TECH_MANAGER) ||
      //     item === ConfigConst.ADMIN
      //   ) {
      //     setIsRPDAvailable(true);
      //   }
      // });
      if (
        getAccessLevelByRoles(techData?.roles).includes(ConfigConst.MT) ||
        getAccessLevelByRoles(techData?.roles).includes(
          ConfigConst.PROVISION_T
        ) ||
        techData?.roles?.includes(ConfigConst.ADMIN)
      ) {
        setIsRPDAvailable(true);
      }
    }
  }, [dispatch, techData]);

  useEffect(() => {
    profileApi();
  }, [profileApi]);

  const tabsItemsWithSwUpg = [
    {
      label: labels.rpdSearch,
      compToRend: <RPDSearchTab key="search" />,
    },
    {
      label: labels.rpdRegistration,
      compToRend: <RPDRegistrationTab key="register" />,
    },

    // {
    //   label: labels.rpdActivation,
    //   compToRend: <RPDActivationTab key="activation" />,
    // },

    {
      label: labels.rpdSwap,
      compToRend: <RPDSwapDA2200Tab key="swapda2200" />,
    },
    {
      label: labels.rpdSWUpgrade,
      compToRend: <RPDSWUpgradeBasedMacTab key="upgrademac" />,
    },
    {
      label: labels.rpdDecommission,
      compToRend: <RPDDecommissionTab key="decommission" />,
    },
  ];

  const tabsItemsWithoutSwUpg = [
    {
      label: labels.rpdSearch,
      compToRend: <RPDSearchTab key="search" />,
    },
    {
      label: labels.rpdRegistration,
      compToRend: <RPDRegistrationTab key="register" />,
    },
    {
      label: labels.rpdSwap,
      compToRend: <RPDSwapTab key="swap" />,
    },
  ];

  if (isRPDSwUpg) {
    tabsItems = [
      {
        label: labels.rpdSearch,
        compToRend: <RPDSearchTab key="search" />,
      },
      {
        label: labels.rpdRegistration,
        compToRend: <RPDRegistrationTab key="register" />,
      },
      {
        label: labels.rpdSwap,
        compToRend: <RPDSwapTab key="swap" />,
      },
      // {
      //   label: labels.rpdActivation,
      //   compToRend: <RPDActivationTab key="activation" />,
      // },
      // {
      //   label: "RPD Activation Provision",
      //   compToRend: <RPDActivationProvisionTab key="actprov" />,
      // },
      // {
      //   label: labels.rpdProvisioning,
      //   compToRend: <RPDProvisioningTab key="provision" />,
      // },
      {
        label: labels.rpdSWUpgrade,
        compToRend: <RPDSWUpgradeBasedMacTab key="upgrademac" />,
      },
    ];
  } else {
    tabsItems = [
      {
        label: labels.rpdSearch,
        compToRend: <RPDSearchTab key="search" />,
      },
      {
        label: labels.rpdRegistration,
        compToRend: <RPDRegistrationTab key="register" />,
      },
      {
        label: labels.rpdSwap,
        compToRend: <RPDSwapTab key="swap" />,
      },
    ];
  }

  return isRPDSwUpg ? (
    <SideTabs
      tabsItems={tabsItemsWithSwUpg}
      childrenStyle={{ marginRight: 25 }}
      size="small"
    />
  ) : (
    <SideTabs
      tabsItems={tabsItemsWithoutSwUpg}
      childrenStyle={{ marginRight: 25 }}
      size="small"
    />
  );
};
export default RccapPage;
