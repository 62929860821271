import React from "react";
import {
  formatCamelCase,
  getAvailableDocsisChannels,
  getFailedAttrs,
} from "../../../util/utilFuncs";
import { DATA_TABLE_CONFIG } from "../../../config/dataTableConfig";
import CustomAccordion from "../../../../components/reusable/CustomAccordion";
import FailedAttDisplayTable from "../../reusable/FailedAttDisplayTable";

interface Props {
  deviceData: any;
}

const CMFailuredetails = ({ deviceData }: Props) => {
  const dData = deviceData?.properties?.data;
  const docsisData = dData?.deviceDiag?.data?.docsis;
  const getChannelsBox = () => {
    if (docsisData) {
      let cmChannels = getAvailableDocsisChannels(docsisData);
      let failedAttrs = getFailedAttrs(cmChannels);
      if (failedAttrs?.length > 0) {
        return failedAttrs?.map((ch: any, i: number) => {
          let tableConfig = DATA_TABLE_CONFIG.find(
            (t: any) => t.name === ch?.chName
          );
          return (
            <CustomAccordion
              key={i}
              accTitle={formatCamelCase(ch?.chName)}
              accBodyElement={
                <>
                  {deviceData !== undefined ? (
                    <FailedAttDisplayTable
                      tableConfig={tableConfig}
                      dataList={ch?.failedChs}
                    />
                  ) : (
                    <></>
                  )}
                </>
              }
            />
          );
        });
      }
    }
  };
  return <div className="cmChannelsStat">{getChannelsBox()}</div>;
};

export default CMFailuredetails;
