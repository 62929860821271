import { BiLinkExternal } from "react-icons/bi";
import CustomAccordion from "../../../../../components/reusable/CustomAccordion";
import appConst from "../../../../constants/appConst";
import TopologyLabels from "../../../../constants/topologyLabels";
import { GetNodeSiteDetailsProps } from "../../../../util/reduxFunctions/getTopologyState";
import { getNodeIDFromSC } from "../../../../util/utilFuncs";

interface Props {
  isPrimaryNodeTab: boolean;
}

const NodeSiteComponents = ({ isPrimaryNodeTab }: Props) => {
  const labels = TopologyLabels();
  const { activeNodeIndex, allNodeData } = GetNodeSiteDetailsProps();
  const ND = isPrimaryNodeTab
    ? allNodeData?.findIndex((e:any) => e?.isPrimaryNode)
    : activeNodeIndex;
  const activeNodeSiteComponent =
    ND != -1 && (allNodeData?.[ND]?.siteComponents as any);

  const openReturnSegment = (rsName: string) => {
    if (rsName) {
      let nodeID = getNodeIDFromSC(rsName);
      if (nodeID && nodeID !== "") window.open(`/#/node?nodeId=` + nodeID);
    }
  };

  return (
    <div className="activeMapEleCont">
      {activeNodeSiteComponent?.data &&
        activeNodeSiteComponent?.data?.length > 0 && (
          <CustomAccordion
            accTitle={labels?.siteComponents?.toUpperCase()}
            accBodyElement={
              <div className="siteComponentsTable">
                <table className="dataTableDetails">
                  <thead>
                    <tr>
                      <td>{labels?.networkID.toUpperCase()}</td>
                      <td>{labels?.name.toUpperCase()}</td>
                      <td>{labels?.category.toUpperCase()}</td>
                      <td>{labels?.model.toUpperCase()}</td>
                      <td>{labels?.type.toUpperCase()}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {activeNodeSiteComponent?.data?.map((sc: any) => {
                      const nodeID = getNodeIDFromSC(sc?.name);
                      const isReturnSeg =
                        !isNaN(parseInt(nodeID)) && nodeID?.length === 6;
                      return (
                        <tr key={sc?.networkId}>
                          <td>{sc?.networkId}</td>
                          <td>
                            {sc?.name}
                            {sc?.type === appConst.returnSegment &&
                              isReturnSeg && (
                                <BiLinkExternal
                                  color="var(--link)"
                                  size={15}
                                  onClick={() => openReturnSegment(sc?.name)}
                                />
                              )}
                          </td>
                          <td>{sc?.category}</td>
                          <td>{sc?.model}</td>
                          <td>{sc?.type}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            }
          />
        )}
    </div>
  );
};

export default NodeSiteComponents;
