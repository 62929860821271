import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { formatBitsToSize, UTCtoLocalTime } from "../reusable/Util";
import { isNull } from "../reusable/Util";
import AppLabels from "../../constants/App_Labels";
import CustomAccordion from "../reusable/CustomAccordion";
import { getCmSpeedTest } from "../../store/slices/cablemodem/cmSpeedTest";
import { GetSpeedData } from "../../get-api/GetResponse";
import CertiExceptionCont from "../certification/CertiExceptionCont";
import CustomPopUp from "../reusable/CustomPopUp";
import LoaderGif from "../../resources/images/loader.webp";

const CMSpeedTestInfo = (props: any) => {
    const { macAddress, speedTestData, isViewCert = false,flag=false } = props;
    const labels = AppLabels();
    const dispatch = useDispatch();
    const isRef = useRef(true);
    const liveSpeedTestData = GetSpeedData();
    const speedTestState = isViewCert ? speedTestData : liveSpeedTestData;
    const [tableData, setTableData] = useState<any>([]);
    // Commented out until Error Codes classification is finalized
    // const [popUpFlag, setPopUpFlag] = useState(false);
    // const [popUpHeader, setPopUpHeader] = useState("");
    // const [popUpBody, setPopUpBody] = useState(<div></div>);

    // const handleErrorClick = (mac: string, data: any) => {
    //     setPopUpHeader(labels.ERROR.toUpperCase() + " - " + mac);
    //     setPopUpFlag(true);
    //     setPopUpBody(
    //         <CertiExceptionCont dataObj={data} sectionName="CM Speed Test" />
    //     );
    // };

    const startSpeedTest = () => {
        dispatch(getCmSpeedTest(macAddress));
        isRef.current = true;
    };

    useEffect(() => {
        if (!speedTestState?.isLoading && speedTestState?.status !== '' && isRef.current) {
            if (speedTestState?.data !== undefined) {
                setTableData(speedTestState?.data);
                isRef.current = false;
            }
        }
    }, [speedTestState?.data]);

    return (
        <CustomAccordion
            accTitle={`${labels.Speed_Test_Result.toUpperCase()}*`}
            accBodyElement={
                <>
                    {!isViewCert ?
                        <div className="tableHeaderWithRefresh" >
                            <span>
                                {speedTestState?.isLoading ?
                                    <>
                                        {labels.PullingSpeedTest}
                                        <img
                                            className="loaderImg"
                                            style={{ height: "25px" }}
                                            src={LoaderGif}
                                        />
                                    </> :
                                    <span style={{ fontStyle: "italic" }}>{labels.SpeedTestInfo}</span>
                                }
                            </span>
                            <div className="refreshCont">
                                <button
                                    className="blueHollowBtn"
                                    onClick={() => startSpeedTest()}
                                    disabled={speedTestState?.isLoading}
                                >
                                    {labels.START}
                                </button>
                            </div>
                        </div>
                        : <span style={{ fontStyle: "italic" }}>{labels.SpeedTestInfo}</span>}
                    <br />
                    <table className="dataTableDetails">
                        <thead>
                            <tr>
                                <th id="dateCol">{labels.Date.toUpperCase()}</th>
                                <th id="timeCol">{labels.Time.toUpperCase()}</th>
                                <th id="dsSpeedCol">{labels.DS_Speed.toUpperCase()}</th>
                                {flag===false && <th id="usSpeedCol">{labels.US_Speed.toUpperCase()}</th>}
                                <th id="pingCol">{labels.Ping.toUpperCase()}</th>
                            </tr>
                        </thead>

                        <tbody>
                            {tableData?.map((item: any, i: number) => {
                                const rts = item?.timestamp;
                                const time = UTCtoLocalTime(rts).myTime;
                                const date = UTCtoLocalTime(rts).myDate;
                                const ping = item?.ping?.substring(0, item?.ping?.indexOf('.') + 3);
                                return (item === undefined || item?.errorCode) ? (
                                    <tr key={"" + i}>
                                        <td className="error" colSpan={6}
                                        // onClick={() => { handleErrorClick(macAddress, item) }}
                                        >
                                            {labels.ERROR}: {labels.SpeedDataNA}
                                        </td>
                                    </tr>
                                ) : (
                                    <tr key={"" + i}>
                                        <td>{date}</td>
                                        <td>{time}</td>
                                        <td>{isNull(formatBitsToSize(item?.downstreamBps, "bits", 2))}</td>
                                        {flag===false && <td>{isNull(formatBitsToSize(item?.upstreamBps, "bits", 2))}</td>}
                                        <td>{isNull(ping)} ms</td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                    {/* <CustomPopUp
                        showVal={popUpFlag}
                        headerText={popUpHeader}
                        bodyElement={popUpBody}
                        handleClose={() => setPopUpFlag(false)}
                    /> */}
                </>
            }
        />
    );
};

export default CMSpeedTestInfo;
