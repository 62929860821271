export const TESTTYPE_CONFIG = {
  WHT: {
    testTypeCode: "WHT",
    deviceAllowed: [
      "CM",
      "ONT",
      "ODU",
      "IEMTA",
      "EMTA",
      "IPTV",
      "STB",
      "DESTB",
      "GSTB",
      "HSTB",
      "ASTB",
      "IPSTB",
    ],
  },
  DNS: {
    testTypeCode: "DNS",
    deviceAllowed: ["CM", "ONT", "ODU"],
  },
  DNG: {
    testTypeCode: "DNG",
    deviceAllowed: [],
  },
};
export const WORK_TYPE_CONFIG = {
  worktypeAllowed: [
    "DPSM",
    "I1",
    "I2",
    "IGPO",
    "P1",
    "P2",
    "P3",
    "P4",
    "DPSF",
    "DPSI",
    "DPSIC",
    "DPSR",
    "DPSRF",
    "FWA1",
    "FWA2",
    "FWAI",
    "IGHS",
    "IGRH",
    "IGRM",
    "IGTV",
    "IGTVF",
    "P1CR",
  ],
};
export const CERTIFICATION_CONFIG = {
  DOCSIS: [
    {
      category: "CM",
      name: "CM/GW",
      keyAttr: "macAddress",
      statusResponse: "docsis",
      iconName: "internet-router",
      deviceCertification: [
        { tab: "CM", label: "CABLE MODEM", active: true, isOpen: true },
        { tab: "CW", label: "CODEWORDS", active: false, isOpen: true },
      ],
    },
    {
      category: "IEMTA",
      name: "IHP",
      keyAttr: "cmmac",
      statusResponse: "summary",
      iconName: "homephone",
      deviceCertification: [
        {
          tab: "ihpSUMMARY",
          label: "EMTA SUMMARY",
          active: true,
          isOpen: true,
        },
      ],
    },
    {
      category: "EMTA",
      name: "RHP",
      keyAttr: "macAddress",
      statusResponse: "docsis",
      iconName: "homephone",
      deviceCertification: [
        {
          tab: "ihpSUMMARY",
          label: "EMTA SUMMARY",
          active: true,
          isOpen: true,
        },
      ],
    },
    {
      category: "IPTV",
      name: "IPTV",
      keyAttr: "serialNumber",
      statusResponse: "network",
      iconName: "cable-tv",
      deviceCertification: [
        {
          tab: "iptvSUMMARY",
          label: "IPTV SUMMARY",
          active: true,
          isOpen: true,
        },
      ],
    },
    {
      category: "DESTB",
      name: "DESTB",
      keyAttr: "serialNumber",
      iconName: "cable-tv",
      deviceCertification: [
        { tab: "stbSUMMARY", label: "STB SUMMARY", active: true, isOpen: true },
      ],
    },
    {
      category: "STB",
      name: "STB",
      keyAttr: "serialNumber",
      iconName: "cable-tv",
      deviceCertification: [
        { tab: "stbSUMMARY", label: "STB SUMMARY", active: true, isOpen: true },
      ],
    },
    {
      category: "ASTB",
      name: "ASTB",
      serviceDescription: "ASTB",
      iconName: "cable-tv",
      deviceCertification: [
        {
          tab: "astbSUMMARY",
          label: "ASTB SUMMARY",
          active: true,
          isOpen: true,
        },
      ],
    },
    {
      category: "GSTB",
      name: "GSTB",
      keyAttr: "serialNumber",
      iconName: "cable-tv",
      deviceCertification: [
        { tab: "stbSUMMARY", label: "STB SUMMARY", active: true, isOpen: true },
      ],
    },
    {
      category: "HSTB",
      name: "HSTB",
      keyAttr: "serialNumber",
      iconName: "cable-tv",
      deviceCertification: [
        { tab: "stbSUMMARY", label: "STB SUMMARY", active: true, isOpen: true },
      ],
    },
    {
      category: "IPSTB",
      name: "IPSTB",
      keyAttr: "serialNumber",
      iconName: "cable-tv",
      deviceCertification: [
        { tab: "stbSUMMARY", label: "STB SUMMARY", active: true, isOpen: true },
      ],
    },
  ],
  GPON: [
    {
      category: "ONT",
      name: "ONT",
      keyAttr: "serialNumber",
      statusResponse: "summary",
      iconName: "internet-router",
      deviceCertification: [
        { tab: "ontSUMMARY", label: "ONT SUMMARY", active: true, isOpen: true },
      ],
    },
    {
      category: "IEMTA",
      name: "IHP",
      keyAttr: "cmmac",
      statusResponse: "summary",
      iconName: "homephone",
      deviceCertification: [
        {
          tab: "ihpSUMMARY",
          label: "EMTA SUMMARY",
          active: true,
          isOpen: true,
        },
      ],
    },
    {
      category: "EMTA",
      name: "RHP",
      keyAttr: "macAddress",
      statusResponse: "docsis",
      iconName: "homephone",
      deviceCertification: [
        {
          tab: "ihpSUMMARY",
          label: "EMTA SUMMARY",
          active: true,
          isOpen: true,
        },
      ],
    },
    {
      category: "IPTV",
      name: "IPTV",
      keyAttr: "serialNumber",
      statusResponse: "network",
      iconName: "cable-tv",
      deviceCertification: [
        {
          tab: "iptvSUMMARY",
          label: "IPTV SUMMARY",
          active: true,
          isOpen: true,
        },
      ],
    },
    {
      category: "DESTB",
      name: "DESTB",
      keyAttr: "serialNumber",
      iconName: "cable-tv",
      deviceCertification: [
        { tab: "stbSUMMARY", label: "STB SUMMARY", active: true, isOpen: true },
      ],
    },
    {
      category: "STB",
      name: "STB",
      keyAttr: "serialNumber",
      iconName: "cable-tv",
      deviceCertification: [
        { tab: "stbSUMMARY", label: "STB SUMMARY", active: true, isOpen: true },
      ],
    },
    {
      category: "ASTB",
      name: "ASTB",
      serviceDescription: "ASTB",
      iconName: "cable-tv",
      deviceCertification: [
        {
          tab: "astbSUMMARY",
          label: "ASTB SUMMARY",
          active: true,
          isOpen: true,
        },
      ],
    },
    {
      category: "GSTB",
      name: "GSTB",
      keyAttr: "serialNumber",
      iconName: "cable-tv",
      deviceCertification: [
        { tab: "stbSUMMARY", label: "STB SUMMARY", active: true, isOpen: true },
      ],
    },
    {
      category: "HSTB",
      name: "HSTB",
      keyAttr: "serialNumber",
      iconName: "cable-tv",
      deviceCertification: [
        { tab: "stbSUMMARY", label: "STB SUMMARY", active: true, isOpen: true },
      ],
    },
    {
      category: "IPSTB",
      name: "IPSTB",
      keyAttr: "serialNumber",
      iconName: "cable-tv",
      deviceCertification: [
        { tab: "stbSUMMARY", label: "STB SUMMARY", active: true, isOpen: true },
      ],
    },
  ],
  FWA: [
    {
      category: "ODU",
      name: "ODU",
      keyAttr: "serialNumber",
      statusResponse: "wirelessInfo",
      iconName: "internet-router",
      deviceCertification: [
        { tab: "oduSUMMARY", label: "ODU SUMMARY", active: true, isOpen: true },
      ],
    },
    {
      category: "IEMTA",
      name: "IHP",
      keyAttr: "cmmac",
      statusResponse: "summary",
      iconName: "homephone",
      deviceCertification: [
        {
          tab: "ihpSUMMARY",
          label: "EMTA SUMMARY",
          active: true,
          isOpen: true,
        },
      ],
    },
    {
      category: "EMTA",
      name: "RHP",
      keyAttr: "macAddress",
      statusResponse: "docsis",
      iconName: "homephone",
      deviceCertification: [
        {
          tab: "ihpSUMMARY",
          label: "EMTA SUMMARY",
          active: true,
          isOpen: true,
        },
      ],
    },
    {
      category: "IPTV",
      name: "IPTV",
      keyAttr: "serialNumber",
      statusResponse: "network",
      iconName: "cable-tv",
      deviceCertification: [
        {
          tab: "iptvSUMMARY",
          label: "IPTV SUMMARY",
          active: true,
          isOpen: true,
        },
      ],
    },
    {
      category: "DESTB",
      name: "DESTB",
      keyAttr: "serialNumber",
      iconName: "cable-tv",
      deviceCertification: [
        { tab: "stbSUMMARY", label: "STB SUMMARY", active: true, isOpen: true },
      ],
    },
    {
      category: "STB",
      name: "STB",
      keyAttr: "serialNumber",
      iconName: "cable-tv",
      deviceCertification: [
        { tab: "stbSUMMARY", label: "STB SUMMARY", active: true, isOpen: true },
      ],
    },
    {
      category: "ASTB",
      name: "ASTB",
      serviceDescription: "ASTB",
      iconName: "cable-tv",
      deviceCertification: [
        {
          tab: "astbSUMMARY",
          label: "ASTB SUMMARY",
          active: true,
          isOpen: true,
        },
      ],
    },
    {
      category: "GSTB",
      name: "GSTB",
      keyAttr: "serialNumber",
      iconName: "cable-tv",
      deviceCertification: [
        { tab: "stbSUMMARY", label: "STB SUMMARY", active: true, isOpen: true },
      ],
    },
    {
      category: "HSTB",
      name: "HSTB",
      keyAttr: "serialNumber",
      iconName: "cable-tv",
      deviceCertification: [
        { tab: "stbSUMMARY", label: "STB SUMMARY", active: true, isOpen: true },
      ],
    },
    {
      category: "IPSTB",
      name: "IPSTB",
      keyAttr: "serialNumber",
      iconName: "cable-tv",
      deviceCertification: [
        { tab: "stbSUMMARY", label: "STB SUMMARY", active: true, isOpen: true },
      ],
    },
  ],
};
