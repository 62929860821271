import AppLabels from "../../../constants/App_Labels";
import ConfigConst from "../../../constants/ConfigConst";
import {
  convertPascalCase,
  getEmailFormatDate,
  isNull,
} from "../../reusable/Util";

interface Props {
  activeAccData: any;
  custType: string;
  body: any;
}

const PrepareEmailFormatEPR = (props: Props) => {
  const labels = AppLabels();
  return (
    <div
      style={{
        display: "inline-block",
        fontSize: "14pt",
        fontWeight: 500,
        fontFamily: "Ted Next",
      }}
    >
      <div style={{ justifyContent: "center", display: "flex" }}>
        {props.custType === ConfigConst.ROGERS ||
        props.custType === ConfigConst.TPIA ? (
          <span style={{ fontSize: "28.5pt", textAlign: "center" }}>
            {labels.EPREmailHeader}
          </span>
        ) : (
          <></>
        )}
      </div>
      {props.custType === ConfigConst.ROGERS ? (
        <p>
          {labels.Dear}{" "}
          {isNull(convertPascalCase(props.activeAccData?.firstName))}{" "}
          {isNull(convertPascalCase(props.activeAccData?.lastName))},
        </p>
      ) : props.custType === ConfigConst.FIDO ? (
        <p>
          {labels.Hi}{" "}
          {isNull(convertPascalCase(props.activeAccData?.firstName))},
        </p>
      ) : (
        <p>{labels.Hello},</p>
      )}
      <p>
        {
          "Please find included with this email, a copy of your equipment pickup receipt on "
        }
        {getEmailFormatDate()}
      </p>
      {props.body}
      {props.custType === ConfigConst.ROGERS ? (
        <p>{labels.RogersEmailThanks}</p>
      ) : props.custType === ConfigConst.FIDO ? (
        <p>{labels.FidoEmailThanks}</p>
      ) : (
        <p>{labels.Thank_you}</p>
      )}
    </div>
  );
};

export default PrepareEmailFormatEPR;
