import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { NetworkInterfacesState } from "../../../store/states/account/diagnostics/cm/NetworkInterfacesState";
import {
    networkInterfacesBegin,
    networkInterfacesSuccess,
    networkInterfacesFailure,
} from "../../../store/actions/account/diagnostics/cm/EthernetAction";

import { NetworkInterfacesData } from "../../../store/models/account/diagnostics/cm/NetworkInterfacesData";
import { getOUIChecked, getOUIW3IDChecked } from "../../../components/reusable/Util";

export default class EthernetClient {
  static getnetworkInterfaces =
    (
        mac: string,
        serialNum: string,
        hhID: string,
        oui: string
    ): ThunkAction<void, NetworkInterfacesState, unknown, Action<string>> =>
    async (dispatch) => {
      let qParam = getOUIChecked(oui,serialNum, hhID, mac);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/gateway/networkInterfaces",
        params: qParam,
      };
      dispatch(networkInterfacesBegin());
      HttpClient.get<NetworkInterfacesData>(httpParameters)
        .then((networkInterfacesData) => {
          dispatch(networkInterfacesSuccess(networkInterfacesData));
          return networkInterfacesData;
        })
        .catch((error) => {
          dispatch(networkInterfacesFailure(error));
          return error;
        });
    };
   
}