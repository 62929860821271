import { AccountDetailsState } from "../../../states/account/accountInfo/AccountDetailsState";
import {
  ACCOUNT_DETAILS_BEGIN,
  ACCOUNT_DETAILS_SUCCESS,
  ACCOUNT_DETAILS_FAILURE,
  ACCOUNT_RESET_STATUS,
  RESET_SEARCH_ACC_STATE,
  AccountDetailsActionTypes,
} from "../../../types/account/accountInfo/AccountDetails";

const initialState: AccountDetailsState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};
const AccDetailsReducer = (
  state = initialState,
  action: AccountDetailsActionTypes
): AccountDetailsState => {
  switch (action.type) {
    case ACCOUNT_DETAILS_BEGIN:
      return {
        isLoading: true,
      };
    case ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };

    case ACCOUNT_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };
    case ACCOUNT_RESET_STATUS:
      return {
        ...state,
        status: "",
      };
    case RESET_SEARCH_ACC_STATE:
      return {
        ...state,
        isLoading: false,
        data: undefined,
        error: undefined,
        status: "",
      };
    default:
      return state;
  }
};
export default AccDetailsReducer;
