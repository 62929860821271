import { CSSProperties, memo } from "react";
import { useDispatch } from "react-redux";
import { FaMapMarkedAlt, FaWpforms } from "react-icons/fa";

import appConst from "../../topology/constants/appConst";
import { setNodeTopologyTab } from "../../store/ducksPattern/node/nodeGeneral";
import { GetNodeGeneralProps, GetTechInfo } from "../../get-api/GetResponse";
import {
  GetConfigsProps,
  GetTopoGeneralProps,
} from "../../topology/util/reduxFunctions/getTopologyState";
import { useMemoizedShowSwitch } from "../../topology/util/customHooks/topologyHooks";

export const HAS_TOPOLOGY_ACCESS = (window as any).config.HAS_TOPOLOGY_ACCESS;

interface Props {
  style?: CSSProperties;
}

function NodeTopologySwitch({ style }: Props) {
  const dispatch = useDispatch();
  const { nodeTopologyTab } = GetNodeGeneralProps();
  const { lanId, data: techData } = GetTechInfo();
  const { activeNodeType } = GetTopoGeneralProps();
  const { data: configData } = GetConfigsProps();

  const memoizedShowSwitch = useMemoizedShowSwitch({
    configData,
    techData,
    lanId,
  });

  const items = [
    {
      key: appConst.node,
      icon: <FaWpforms />,
    },
    {
      key: appConst.topology,
      icon: <FaMapMarkedAlt />,
    },
  ];

  const getNodeSwitchPosition = () => {
    switch (activeNodeType) {
      case appConst.DOCSIS_NODE:
        return { position: "relative" } as CSSProperties;
      case appConst.GPON_NODE:
        return { position: "relative" } as CSSProperties;
      default:
        return { top: 180, right: 25 };
    }
  };

  const getBtnsPosition = () => {
    if (nodeTopologyTab === appConst.node) return getNodeSwitchPosition();
    if (nodeTopologyTab === appConst.topology) return { top: 10, right: 10 };
  };

  const getSwitchClassName = (t: string) =>
    nodeTopologyTab === t ? "activeSwitch" : "inactiveSwitch";

  return (
    <>
      {memoizedShowSwitch && (
        <div
          className="nodeSwitchCont"
          style={{ ...getBtnsPosition(), ...style }}
        >
          {items?.map((e: any) => (
            <div
              key={e.key}
              className={getSwitchClassName(e.key)}
              onClick={() => dispatch(setNodeTopologyTab(e.key))}
            >
              {e.icon}
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default memo(NodeTopologySwitch);
