export const SAVE_NODES = 'SAVE_NODES';
export const SET_NODE_GEOCODE = 'SET_NODE_GEOCODE';
export const UPDATE_NODES = 'UPDATE_NODES';
export const RESET_NODES = 'RESET_NODES';
export const INCREMENT_NODE_API_CALL_COUNTER =
  'INCREMENT_NODE_API_CALL_COUNTER';
export const DECREMENT_NODE_API_CALL_COUNTER =
  'DECREMENT_NODE_API_CALL_COUNTER';

interface SaveNodes {
  type: typeof SAVE_NODES;
  payload: any;
}
interface SetNodeGeoCode {
  type: typeof SET_NODE_GEOCODE;
  snNumber: string;
  geoCode: any;
}
interface UpdateNodeDevices {
  type: typeof UPDATE_NODES;
  snNumber: string;
  subdataKey: string;
  data: any;
  status: string;
}
interface ResetNodes {
  type: typeof RESET_NODES;
}
interface IncrementNodeApiCallCounter {
  type: typeof INCREMENT_NODE_API_CALL_COUNTER;
  snNumber: string;
  subDataKey: string;
}
interface DecrementNodeApiCallCounter {
  type: typeof DECREMENT_NODE_API_CALL_COUNTER;
  snNumber: string;
  subDataKey: string;
}

export type NodesActionTypes =
  | SaveNodes
  | SetNodeGeoCode
  | UpdateNodeDevices
  | ResetNodes
  | IncrementNodeApiCallCounter
  | DecrementNodeApiCallCounter;
