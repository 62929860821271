import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import "../../design-tokens/rpd-page/RPDPage.scss";
import LoaderGif from "../../resources/images/loader.webp";
import ExceptionBox from "../../components/reusable/ExceptionBox";
import PassFailBox from "../../components/reusable/PassFailBox";
import AppLabels from "../../constants/App_Labels";
import RPDOrderDetails from "../../components/rpd/rpdOrderDetail/RPDOrderDetails";
import RPDOrderDetailTop from "../../components/rpd/rpdOrderDetail/RPDOrderDetailTop";

import { GetRPDOrderInfo } from "../../get-api/GetResponse";

const RPDOrderDetailTab = (props: any) => {
  const dispatch = useDispatch();
  const rpdOrderInfo = GetRPDOrderInfo();
  const [macAdd, setMacAdd] = useState("");
  const labels = AppLabels();

  return (
    <>
      <div>
        <RPDOrderDetailTop
          orderId={rpdOrderInfo?.orderId}
          isLoading={rpdOrderInfo?.isLoading}
        />
      </div>
      <div className="historyResultCont">
        {rpdOrderInfo?.isLoading ? (
          <div className="loader">
            <img style={{ height: "60px" }} src={LoaderGif} />
          </div>
        ) : rpdOrderInfo?.data != null ? (
          rpdOrderInfo?.data.orderStatus === "Completed" ? (
            rpdOrderInfo?.data.botStatus === "PASS" ? (
              <div className="diagErrorCont">
                <PassFailBox
                  exBoxType="pass"
                  headerText={labels.rpdOrderSuccess}
                  bodyText={rpdOrderInfo?.data.botStatus}
                  primaryBtn={false}
                  secBtn={false}
                />
              </div>
            ) : (
              <div className="diagErrorCont">
                <PassFailBox
                  exBoxType="fail"
                  headerText={labels.rpdOrderFail}
                  bodyText={rpdOrderInfo?.data.botMessage}
                  primaryBtn={false}
                  secBtn={false}
                />
              </div>
            )
          ) : rpdOrderInfo?.data.orderStatus === "Failed" ? (
            <div className="diagErrorCont">
              <PassFailBox
                exBoxType="fail"
                headerText={labels.rpdOrderFail}
                bodyText={rpdOrderInfo?.data.botMessage}
                primaryBtn={false}
                secBtn={false}
              />
            </div>
          ) : (
            <div className="diagErrorCont">
              <PassFailBox
                exBoxType="inprogress"
                headerText={labels.rpdOrderProgress}
                bodyText={rpdOrderInfo?.data.botStatus}
                primaryBtn={false}
                secBtn={false}
              />
            </div>
          )
        ) : rpdOrderInfo?.error != null ? (
          <div className="diagErrorCont">
            <PassFailBox
              exBoxType="fail"
              headerText={rpdOrderInfo?.error?.errorMessage}
              bodyText={rpdOrderInfo?.error.responseFromSource}
              primaryBtn={false}
              secBtn={false}
            />
            {/* <ExceptionBox
              exBoxType="error"
              headerText={labels.noRPDOrderData}
              errCode={rpdOrderInfo?.error?.errorCode}
              ErrorMSG={rpdOrderInfo?.error?.errorMessage}
              srcSystem={rpdOrderInfo?.error?.sourceSystem}
              bodyText={rpdOrderInfo?.error?.responseFromSource}
              primaryBtn={false}
              secBtn={false}
            /> */}
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default RPDOrderDetailTab;
