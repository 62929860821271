import { Card } from "@material-ui/core";
import ErrorBox from "../../reusable/ErrorBox";
import AppLables from "../../../constants/App_Labels";
import RPDClient from "../../../network/httpclient/rpd/RPDClient";
import {
  setNodeId,
  setMacAddress,
  rpdInfoReset,
} from "../../../store/ducksPattern/rpd/rpd";
import { rpdInfoSwReset } from "../../../store/ducksPattern/rpd/rpdSwVersion";
import { TAF_ORDER_CONST } from "../../../constants/Global";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  formatMacAddress,
  formatNodeId,
  formatRPDMacAddress,
  isValidNodeId,
} from "../../../components/reusable/Util";

const RPDSearchTop = (props: any) => {
  const { isLoading, searchData } = props;
  const labels = AppLables();
  const [clamshellId, setClamshellId] = useState("");
  const [macAddres, setMacAddres] = useState("");
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("This is Error Message");

  const handleClose = () => {
    setOpen(false);
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleMacAddSearch(macAddres);
    }
  };

  const onClamshellIdSubmit = (e: any) => {
    let sv = formatNodeId(e.target.value);
    if (sv?.length <= 6) setClamshellId(sv);
  };

  const onMacAddressSubmit = (e: any) => {
    dispatch(rpdInfoReset());
    dispatch(rpdInfoSwReset());
    let sv = formatMacAddress(e.target.value);
    if (sv?.length <= 12) setMacAddres(sv);
  };

  const createInputMacAdObject = (macAddress: string): any => {
    let inputObject = {
      rpdmacAddress: macAddress,
    };
    return inputObject;
  };

  const createInputNodeObject = (clamshellID: string): any => {
    let inputObject = {
      Node_ID: clamshellID,
    };
    return inputObject;
  };

  const handleClamshellSearch = (clamshellId: string) => {
    setMacAddres("");
    if (isValidNodeId(clamshellId)) {
      dispatch(setNodeId(clamshellId));
      dispatch(
        RPDClient.getClamshellDetailsOnNode(
          TAF_ORDER_CONST.rpdSearchbyNodeIDBotOALName,
          // TAF_ORDER_CONST.rpdSearchbyNodeIDBotName,
          TAF_ORDER_CONST.rpdSearchbyNodeIDSuiteName,
          createInputNodeObject(clamshellId)
        )
      );
    } else if (
      clamshellId != undefined &&
      clamshellId.length > 0 &&
      (clamshellId.length == 6 || isNaN(Number(clamshellId)))
    ) {
      setOpen(true);
      setError(labels.clamshellEmptyError);
    } else {
      setOpen(true);
      setError(labels.clamshellEmptyError);
    }
  };

  const handleMacAddSearch = (macAddress: string) => {
    setClamshellId("");
    if (macAddress !== undefined && macAddress?.length === 12) {
      dispatch(setMacAddress(macAddress));
      let stringMacAdd = formatRPDMacAddress(macAddress);
      dispatch(
        RPDClient.getClamshellDetailsOnMacAdd(
          TAF_ORDER_CONST.rpdSearchbyMacAddressBotOALName,
          TAF_ORDER_CONST.rpdSearchbyMacAddressSuiteName,
          createInputMacAdObject(stringMacAdd)
        )
      );
    } else if (isValidNodeId(macAddress)) {
      dispatch(setNodeId(macAddress));
      dispatch(
        RPDClient.getClamshellDetailsOnNode(
          TAF_ORDER_CONST.rpdSearchbyNodeIDBotOALName,
          TAF_ORDER_CONST.rpdSearchbyNodeIDSuiteName,
          createInputNodeObject(macAddress)
        )
      );
    } else {
      setOpen(true);
      setError(labels.macAddressEmptyError);
    }
  };

  return (
    <>
      <Card className="filterRootContainer">
        <div className="filterContainer">
          <div className="filterRow">
            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.clamshellAndReturnSegment}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="rpdInputField"
                  onChange={onMacAddressSubmit}
                  className="rpdInputField"
                  id="rpdInputField"
                  value={macAddres}
                  onKeyPress={(e: any) => handleKeyPress(e)}
                  placeholder={labels.typeClamshellRpdMacAddress}
                />
              </Form.Group>
            </div>
            <div className="topSearchBtn">
              <button
                disabled={isLoading}
                className="applyFilersBtn"
                onClick={() => handleMacAddSearch(macAddres)}
              >
                {labels.SEARCH_BTN_TEXT}
              </button>
            </div>
          </div>
        </div>
      </Card>
      <ErrorBox
        open={open}
        error={error}
        setOpen={setOpen}
        handleClose={handleClose}
      />
    </>
  );
};
export default RPDSearchTop;
