export const TAP_INFO_BEGIN = "TAP_INFO_BEGIN";
export const TAP_INFO_SUCCESS = "TAP_INFO_SUCCESS";
export const TAP_INFO_FAILURE = "TAP_INFO_FAILURE";

interface TapInfoBegin {
  type: typeof TAP_INFO_BEGIN;
}

interface TapInfoSuccess {
  type: typeof TAP_INFO_SUCCESS;
  payload: any;
}

interface TapInfoFailure {
  type: typeof TAP_INFO_FAILURE;
  payload: Error;
}

export type TapInfoActionTypes = TapInfoBegin | TapInfoSuccess | TapInfoFailure;
