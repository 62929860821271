import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { RecoLegendState } from "../../../store/states/account/troubleshoot/RecoLegendState";
import {
  recoLegendBegin,
  recoLegendFailure,
  recoLegendSuccess,
} from "../../../store/actions/account/troubleshoot/RecoLegendAction";
import { RecoLegend } from "../../../store/models/account/troubleshoot/RecoLegend";

export default class RecoLegendClient {
  static getRecoLegend =
    (): ThunkAction<void, RecoLegendState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "splunk/legend",
      };

      dispatch(recoLegendBegin());

      HttpClient.get<RecoLegend>(httpParameters)
        .then((recoLegend) => {
          dispatch(recoLegendSuccess(recoLegend));
          return recoLegend;
        })
        .catch((error) => {
          dispatch(recoLegendFailure(error));
          return error;
        });
    };
}
