import DateFnsUtils from "@date-io/date-fns";
import { Divider } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import AppLabels from "../../../constants/App_Labels";
import ConfigConst from "../../../constants/ConfigConst";

import {
  GetActiveAccountDetails,
  GetFormHistory,
  GetTechInfo,
} from "../../../get-api/GetResponse";
import RedAsterisk from "../../reusable/RedAsterisk";
import Confirmation from "./sections/Confirmation";
import CustomerInformation from "./sections/CustomerInformation";
import OrderDetails from "./sections/OrderDetails";

interface Props {
  setDetails: any;
  isEmailsValid: any;
  setIsEmailsValid: any;
}

const EquipmentPickupDetails = ({
  setDetails,
  isEmailsValid,
  setIsEmailsValid,
}: Props) => {
  const labels = AppLabels();
  const technicianId = GetTechInfo()?.techId;
  const accountNumber = GetActiveAccountDetails()?.accountNumber;

  const form = GetFormHistory()?.dataList;
  const [accNumber, setAccNumber] = useState(accountNumber);
  const [customerEmails, setCustomerEmails] = useState([""] as any);
  const [emailOptOut, setEmailOptOut] = useState(false);
  const [date, setDate] = useState<any>(new Date());
  const [techId, setTechId] = useState(technicianId);
  const [formData, setFormData] = useState<[] | "" | any>();
  const [
    equipmentPickupDetailsandSerialNumbers,
    setEquipmentPickupDetailsandSerialNumbers,
  ] = useState("");
  const [acknowledgment, setAcknowledgment] = useState(false);
  const [signatureImage, setSignatureImage] = useState("");
  useEffect(() => {
    form?.map((e: any, i: number) => {
      e?.formData?.hasOwnProperty("equipmentPickupDetailsandSerialNumbers") ? (
        setFormData(form?.[i]?.formData)
      ) : (
        <></>
      );
      // setIndex(i);
    });
  }, []);
  useEffect(() => {
    if (emailOptOut) {
      setCustomerEmails([""]);
    }
  }, [emailOptOut]);

  useEffect(() => {
    if (formData && formData != "") {
      setAccNumber(formData?.accountNumber);
      setCustomerEmails(formData?.customerEmails);
      setEmailOptOut(formData?.emailOptOut);
      setDate(formData?.date);
      setTechId(formData?.technicianId);
      // setOrderNumber(formData?.orderNumber);
      setEquipmentPickupDetailsandSerialNumbers(
        formData?.equipmentPickupDetailsandSerialNumbers
      );
      setAcknowledgment(formData?.acknowledgment);
      setSignatureImage(formData?.signatureImage);
    }
  }, [formData]);

  return (
    <>
      <CustomerInformation
        labels={labels}
        accNumber={accNumber}
        customerEmails={customerEmails}
        setCustomerEmails={setCustomerEmails}
        emailOptOut={emailOptOut}
        setEmailOptOut={setEmailOptOut}
        setIsEmailsValid={setIsEmailsValid}
      />
      <Divider style={{ margin: "30px 0px 30px 0px" }} />
      <OrderDetails
        labels={labels}
        date={date}
        setDate={setDate}
        techId={techId}
        accNumber={accNumber}
        setDetails={setDetails}
        emailOptOut={emailOptOut}
        customerEmails={customerEmails}
        acknowledgment={acknowledgment}
        signatureImage={signatureImage}
        equipmentPickupDetailsandSerialNumbers={
          equipmentPickupDetailsandSerialNumbers
        }
        setEquipmentPickupDetailsandSerialNumbers={
          setEquipmentPickupDetailsandSerialNumbers
        }
      />
      <Divider style={{ margin: "30px 0px 30px 0px" }} />
      <Confirmation
        labels={labels}
        acknowledgment={acknowledgment}
        setAcknowledgment={setAcknowledgment}
        signatureImage={signatureImage}
        setSignatureImage={setSignatureImage}
      />
    </>
  );
};

export default EquipmentPickupDetails;
