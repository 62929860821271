import { useEffect, useLayoutEffect } from "react";
import {
  getDiagTypeByLevel,
  getSearchType,
  getSearchTypeConfig,
  isStringNumber,
} from "../components/reusable/Util";
import ConfigConst from "../constants/ConfigConst";
import { singleDeviceSearchConfig } from "../config/SearchConfig";
import AccDetailsClient from "../network/httpclient/accountInfo/AccDetailsClient";
import { useDispatch } from "react-redux";

export const useRemoveNavBar = () => {
  useLayoutEffect(() => {
    // remove the navbar from the DOM
    const navbar = document.getElementsByClassName("drawerCloseSec")[0];
    const header = document.getElementsByClassName(
      "headerSec"
    )[0] as HTMLElement;
    const main = document.getElementsByClassName(
      "main-section"
    )[0] as HTMLElement;
    if (navbar && header && main) {
      // remove the navbar from the DOM completely and adjust the header and main sections margin-left to 0
      if (header?.firstChild) {
        const headerFirstChild = header?.firstChild as HTMLElement;
        headerFirstChild.style.width = "100%";
      }
      header.style.marginLeft = "auto";
      main.style.marginLeft = "auto";
      navbar.remove();
    }
  }, []);
};

export const useMultiDevicesSearch = (
  searchType: string,
  setSearchType: any,
  deviceList: any,
  searchValue: string,
  selDiagType: string
) => {
  useEffect(() => {
    if (searchType) {
      switch (searchType) {
        case ConfigConst.node:
          if (
            deviceList?.length > 1 &&
            getDiagTypeByLevel(selDiagType) === "summary"
          ) {
            // Replacing ecm with ecmstb and stb with oldstb
            const modifiedDeviceList = deviceList.map((dT: any) => {
              if (dT === "ecm") {
                return "ecmstb";
              }
              if (dT === "stb") {
                return "oldstb";
              }
              return dT;
            });

            const joinedDeviceList = modifiedDeviceList.join(",");

            searchMultiDeviceNode(joinedDeviceList, searchValue);
          } else {
            deviceList?.map((dT: string) => {
              if (dT && dT !== "") {
                searchNode(dT, searchValue);
              }
            });
          }
          break;
        case ConfigConst.gnode:
          deviceList?.map((dT: string) => {
            if (dT && dT !== "") {
              searchNode(dT, searchValue);
            }
          });
          break;
        case ConfigConst.olt:
          searchOltByName(searchValue);
          break;
        case ConfigConst.neighbourhood:
          if (
            deviceList?.length > 1 &&
            getDiagTypeByLevel(selDiagType) === "summary"
          ) {
            // Replacing ecm with ecmstb and stb with oldstb
            const modifiedDeviceList = deviceList.map((dT: any) => {
              if (dT === "ecm") {
                return "ecmstb";
              }
              if (dT === "stb") {
                return "oldstb";
              }
              return dT;
            });

            const joinedDeviceList = modifiedDeviceList.join(",");

            searchMultiDeviceNeighbourhood(
              searchValue,
              joinedDeviceList,
              searchType
            );
          } else {
            deviceList?.map((dT: string) => {
              if (dT && dT !== "") {
                searchNeighbourhood(searchValue, dT, searchType);
              }
            });
          }
          break;
      }
      setSearchType("");
    }
  }, [searchType]);
  const searchMultiDeviceNeighbourhood = async (
    macAddress: string,
    deviceType: string,
    searchType: string
  ) => {
    let searchTypeConfig: any = getSearchTypeConfig("neighbourhoodMultiUri");
    let dataType;
    let viewType;
    if (deviceType !== "") {
      dataType = getDiagTypeByLevel(selDiagType);
      viewType = dataType ? "all" : undefined;
    }
    openInNewMultiWindow(
      `${searchTypeConfig?.uri}?mac=`,
      searchTypeConfig?.prefix,
      macAddress,
      deviceType,
      dataType,
      viewType
    );
  };
  const searchNeighbourhood = async (
    macAddress: string,
    deviceType: string,
    searchType: string
  ) => {
    let searchTypeConfig: any = getSearchTypeConfig(searchType);
    let dataType;
    let viewType;
    if (deviceType !== "") {
      dataType = getDiagTypeByLevel(selDiagType);
      viewType = dataType ? "all" : undefined;
    }
    openInNewWindow(
      `${searchTypeConfig?.uri}/${deviceType}?cmmac=`,
      searchTypeConfig?.prefix,
      macAddress,
      dataType,
      viewType
    );
  };

  const searchNode = async (deviceType: string, searchVal: string) => {
    let searchTypeConfig: any = getSearchTypeConfig(deviceType);
    let dataType;
    let viewType;
    if (deviceType !== "") {
      dataType = getDiagTypeByLevel(selDiagType);
      viewType = dataType ? "all" : undefined;
    }
    openInNewWindow(
      searchTypeConfig?.uri,
      searchTypeConfig?.prefix,
      searchVal,
      dataType,
      viewType
    );
  };
  const searchMultiDeviceNode = async (
    deviceType: string,
    searchVal: string
  ) => {
    let searchTypeConfig: any = getSearchTypeConfig("nodeMultiUri");

    let dataType;
    let viewType;
    if (deviceType !== "") {
      dataType = getDiagTypeByLevel(selDiagType);
      viewType = dataType ? "all" : undefined;
    }
    openInNewMultiWindow(
      searchTypeConfig?.uri,
      searchTypeConfig?.prefix,
      searchVal,
      deviceType,
      dataType,
      viewType
    );
  };
  const searchOltByName = (oltName: string) => {
    let searchTypeConfig: any = getSearchTypeConfig(ConfigConst.olt);
    openInNewWindow(searchTypeConfig?.uri, searchTypeConfig?.prefix, oltName);
  };

  const openInNewWindow = (
    url: string,
    searchType: string,
    searchValue: string,
    dataType?: string,
    viewType?: string
  ) => {
    const tempUrl = `${window.location.origin}/#${url}${searchValue?.trim()}${
      dataType ? "&dataSet=" + dataType : ""
    }${viewType ? "&viewType=" + viewType : ""}`;

    let newWindow = window.open(tempUrl, "_blank")!;
    newWindow.onload = () => {
      newWindow.document.title = `${searchType}-${searchValue?.trim()}`;
    };
  };

  const openInNewMultiWindow = (
    url: string,
    searchType: string,
    searchValue: string,
    dataType?: string,
    deviceType?: string,
    viewType?: string
  ) => {
    const tempUrl = `${window.location.origin}/#${url}${searchValue?.trim()}${
      dataType ? "&dataSet=" + dataType : ""
    }${viewType ? "&viewType=" + viewType : ""}${
      deviceType ? "&deviceType=" + deviceType : ""
    }`;

    let newWindow = window.open(tempUrl, "_blank")!;
    newWindow.onload = () => {
      newWindow.document.title = `${searchType}-${searchValue?.trim()}`;
    };
  };
};
