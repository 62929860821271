import { Button, IconButton, TextField } from "@material-ui/core";
import { MdClear, MdDelete } from "react-icons/md";

import { DrawingType } from "./GeoEditCanvas";

export interface IOnGeoEdits {
  stroke: string;
  strokeWidth: number;
  fill: string | undefined;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  radius?: number;
  rotation?: number;
  activeItem: DrawingType;
  opacity: number;
  innerRadius?: number;
  outerRadius?: number;
  starPoints?: number;
  size?: number;
  text?: string;
  sides?: number;
  angle?: number;
  txtColor?: string;
  [key: string]: any;
}

interface Props extends IOnGeoEdits {
  onEdit: ({ stroke, strokeWidth, fill, x, y }: IOnGeoEdits) => void;
  onDelete: () => void;
}

export default function GeoEdits({
  onEdit,
  stroke,
  strokeWidth,
  fill,
  x,
  y,
  activeItem,
  radius,
  rotation,
  width,
  height,
  opacity,
  innerRadius,
  outerRadius,
  starPoints,
  size,
  text,
  sides,
  onDelete,
  angle,
  txtColor,
}: Props) {
  const handleEdit = ({
    stk = stroke,
    stkW = strokeWidth,
    fl = fill,
    ex = x,
    ey = y,
    actItem = activeItem,
    rd = radius,
    rot = rotation,
    w = width,
    h = height,
    op = opacity,
    innerR = innerRadius,
    outerR = outerRadius,
    strPoints = starPoints,
    sz = size,
    txt = text,
    sds = sides,
    ang = angle,
    txtClr = txtColor,
  }) =>
    onEdit({
      stroke: stk,
      strokeWidth: stkW,
      fill: fl,
      x: ex,
      y: ey,
      activeItem: actItem,
      radius: rd,
      rotation: rot,
      width: w,
      height: h,
      opacity: op,
      innerRadius: innerR,
      outerRadius: outerR,
      starPoints: strPoints,
      size: sz,
      text: txt,
      sides: sds,
      angle: ang,
      txtColor: txtClr,
    });

  return (
    <>
      {activeItem !== "none" && (
        <div className="geoEditsCont flexedRowDiv">
          {activeItem !== "image" && activeItem !== "text" && (
            <input
              type="color"
              value={stroke}
              onChange={(e) =>
                handleEdit({
                  stk: e.target.value,
                  fl: fill !== "transparent" ? e.target.value : fill,
                })
              }
              style={{ height: 40 }}
            />
          )}
          {activeItem == "text" && (
            <input
              type="color"
              value={txtColor}
              onChange={(e) => handleEdit({ txtClr: e.target.value })}
              style={{ height: 40 }}
            />
          )}
          {activeItem !== "text" &&
            activeItem !== "image" &&
            activeItem !== "arrow" &&
            activeItem !== "line" &&
            activeItem !== "pen" && (
              <Button
                variant={fill !== "transparent" ? "contained" : "outlined"}
                onClick={() =>
                  handleEdit({
                    fl: fill === "transparent" ? stroke : "transparent",
                  })
                }
                color="primary"
                style={{ height: 40 }}
              >
                {fill !== "transparent" ? "Filled" : "Outlined"}
              </Button>
            )}
          {activeItem !== "text" && activeItem !== "image" && (
            <TextField
              variant="outlined"
              label="Stroke"
              placeholder="stroke width"
              value={String(strokeWidth)}
              size="small"
              type="number"
              onChange={(e) => handleEdit({ stkW: Number(e.target.value) })}
              style={{ width: 90 }}
            />
          )}
          {activeItem === "text" && (
            <>
              <TextField
                variant="outlined"
                label="Text"
                value={text}
                size="small"
                onChange={(e) => handleEdit({ txt: e.target.value })}
              />
              <TextField
                variant="outlined"
                label="Size"
                value={String(size)}
                size="small"
                type="number"
                onChange={(e) => handleEdit({ sz: Number(e.target.value) })}
                style={{ width: 90 }}
              />
            </>
          )}
          <TextField
            variant="outlined"
            label="Opacity"
            value={String(opacity)}
            size="small"
            type="number"
            onChange={(e) => handleEdit({ op: Number(e.target.value) })}
            style={{ width: 90 }}
          />
          {(activeItem === "triangle" || activeItem === "hexagon") && (
            <TextField
              variant="outlined"
              label="Sides"
              value={String(sides)}
              size="small"
              type="number"
              onChange={(e) =>
                Number(e.target.value) > 0 &&
                handleEdit({ sds: Number(e.target.value) })
              }
              style={{ width: 90 }}
            />
          )}
          {(activeItem === "circle" ||
            activeItem === "triangle" ||
            activeItem === "pie" ||
            activeItem === "hexagon") && (
            <TextField
              variant="outlined"
              label="Radius"
              value={String(radius)}
              size="small"
              type="number"
              onChange={(e) => handleEdit({ rd: Number(e.target.value) })}
              style={{ width: 90 }}
            />
          )}
          {activeItem === "pie" && (
            <TextField
              variant="outlined"
              label="Angle"
              value={String(angle)}
              size="small"
              type="number"
              onChange={(e) => handleEdit({ ang: Number(e.target.value) })}
              style={{ width: 90 }}
            />
          )}
          {(activeItem === "rectangle" ||
            activeItem === "image" ||
            activeItem === "text") && (
            <TextField
              variant="outlined"
              label="Width"
              value={String(width)}
              size="small"
              type="number"
              onChange={(e) => handleEdit({ w: Number(e.target.value) })}
              style={{ width: 90 }}
            />
          )}
          {(activeItem === "rectangle" || activeItem === "image") && (
            <TextField
              variant="outlined"
              label="Height"
              value={String(height)}
              size="small"
              type="number"
              onChange={(e) => handleEdit({ h: Number(e.target.value) })}
              style={{ width: 90 }}
            />
          )}
          {activeItem === "star" && (
            <>
              <TextField
                variant="outlined"
                label="Points"
                value={String(starPoints)}
                size="small"
                type="number"
                onChange={(e) =>
                  Number(e.target.value) > 0 &&
                  handleEdit({ strPoints: Number(e.target.value) })
                }
                style={{ width: 90 }}
              />
              <TextField
                variant="outlined"
                label="Inner Rad"
                value={String(innerRadius)}
                size="small"
                type="number"
                onChange={(e) =>
                  Number(e.target.value) > 0 &&
                  handleEdit({ innerR: Number(e.target.value) })
                }
                style={{ width: 90 }}
              />
              <TextField
                variant="outlined"
                label="Outer Rad"
                value={String(outerRadius)}
                size="small"
                type="number"
                onChange={(e) =>
                  Number(e.target.value) > 0 &&
                  handleEdit({ outerR: Number(e.target.value) })
                }
                style={{ width: 90 }}
              />
            </>
          )}
          {activeItem !== "circle" &&
            activeItem !== "pen" &&
            activeItem !== "line" && (
              <TextField
                variant="outlined"
                label="Rotation"
                value={String(rotation)}
                size="small"
                type="number"
                onChange={(e) => handleEdit({ rot: Number(e.target.value) })}
                style={{ width: 90 }}
              />
            )}
          <IconButton size="small" onClick={onDelete}>
            <MdDelete size={25} />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => handleEdit({ actItem: "none" })}
          >
            <MdClear size={25} />
          </IconButton>
        </div>
      )}
    </>
  );
}
