import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Wifi2GData from "./Wifi2GData";
import Wifi5GData from "./Wifi5GData";
import Wifi6GData from "./Wifi6GData";
import WIFIInfoClient from "../../network/httpclient/wifi_Info/WIFIInfoClient";
import DiagContainer from "../reusable/DiagContainer";
import ReloadData from "../reusable/ReloadData";
import { getCMW3ID } from "../reusable/Util";
import { GetActiveAccountDetails } from "../../get-api/GetResponse";

const WifiInfo = (props: any) => {
  const { selectedDevice, selectedSA } = props;
  const dispatch = useDispatch();
  const brandName = GetActiveAccountDetails()?.brand;
  const { radioLoading, wifiSettingsData, wifiRadioData, wifiRadioError } =
    useSelector(
      (state: any) => ({
        wifiSettingsData: state.AccountState.wifiSettingsInfo.data,
        wifiRadioData: state.AccountState.wifiRadiosInfo.data,
        wifiRadioError: state.AccountState.wifiRadiosInfo.error,
        radioLoading: state.AccountState.wifiRadiosInfo.isLoading,
      }),
      shallowEqual
    );

  const refreshData = () => {
    dispatch(
      WIFIInfoClient.getWIFISettings(
        selectedDevice.macAddress,
        selectedDevice.serialNumber,
        getCMW3ID(selectedSA,brandName)
      )
    );
    dispatch(
      WIFIInfoClient.getWIFIRadios(
        selectedDevice.macAddress,
        selectedDevice.serialNumber,
        getCMW3ID(selectedSA,brandName)
      )
    );
  };

  return (
    <>
      <div className="refreshCont">
        <ReloadData refreshData={refreshData} />
      </div>
      <div className="cmSummaryCont">
        <DiagContainer
          isLoading={radioLoading}
          data={wifiRadioData}
          sectionName="WiFi Settings"
          error={wifiRadioError}
          handleRefresh={() => refreshData()}
        >
          <Wifi2GData
            wifiSettingsData={wifiSettingsData}
            wifiRadioData={wifiRadioData}
          />
          <Wifi5GData
            wifiSettingsData={wifiSettingsData}
            wifiRadioData={wifiRadioData}
          />
          <Wifi6GData
            wifiSettingsData={wifiSettingsData}
            wifiRadioData={wifiRadioData}
          />
        </DiagContainer>
      </div>
    </>
  );
};

export default WifiInfo;
