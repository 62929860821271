import { GroundOverlay } from "@react-google-maps/api";
import React from "react";
import { zoomLevelRenderMduOverlay } from "../../../../config/mapConfig";
import { mapElementStyles } from "../../../../config/mapItemsConfig";
import appConst from "../../../../constants/appConst";
import { getMapEleImageObj } from "../../../../util/utilFuncs";
interface Props {
  mduStrandPointData?: any;
  pngImages?: any;
  zoomLevel?: any;
}

const MduStrandPoint = React.memo(
  ({ mduStrandPointData, pngImages, zoomLevel }: Props) => {
    const propertiesData = mduStrandPointData?.properties?.data;
    const type = mduStrandPointData?.properties?.type;
    const eleImageOverlay: any = getMapEleImageObj(
      pngImages?.[type],
      propertiesData?.symbolName,
      propertiesData?.rotation,
      mapElementStyles?.[type],
      mduStrandPointData?.geometry?.coordinates?.[1],
      mduStrandPointData?.geometry?.coordinates?.[0]
    );
    return (
      <>
        {zoomLevel >= zoomLevelRenderMduOverlay && (
          <GroundOverlay
            key={mduStrandPointData?.featureId}
            url={`${appConst.base64StringPrefix}${eleImageOverlay?.symbol}`}
            bounds={
              new google.maps.LatLngBounds(
                new google.maps.LatLng(
                  eleImageOverlay?.southBound,
                  eleImageOverlay?.westBound
                ),
                new google.maps.LatLng(
                  eleImageOverlay?.northBound,
                  eleImageOverlay?.eastBound
                )
              )
            }
          />
        )}
      </>
    );
  }
);

export default MduStrandPoint;
