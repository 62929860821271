import { NodeDeviceData } from "../../../models/node/telemetry/NodeDeviceData";
import { NodeDevicesState } from "../../../states/node/telemetry/NodeDevicesState";
import {
  NODEDevicesActionTypes,
  START_NODE_DEVICE_DIAG,
  RESET_NODE_DEVICES,
  SAVE_NODE_DEVICES,
  UPDATE_NODE_DEVICES,
  SET_NODE_TELE_DIAG_LOADING,
  SET_NODE_TELE_DIAG_TYPE,
  SET_TELE_DATASET_TYPE,
  SET_TELE_VIEW_TYPE,
  SET_NODE_DEVICES_ERROR,
  UPDATE_NODE_DEVICES_STREAM,
  UPDATE_NODE_DEVICES_ARRAY,
} from "../../../types/node/telemetry/NodeDevicesInfo";

const initialState: NodeDevicesState = {
  globleLoading: false,
  globleStatus: "",
  dataSetType: "",
  viewType: "ALL",
  startTime: 0,
  endTime: 0,
  diagDeviceCounter: 0,
  inProgressApiCalls: 0,
  nodeDevicesList: [],
  countStreamModemsLoaded: 0,
  streamModemsCounter: 0,
  nodeDevicesMap: {},
  overAllError: false,
};

export function NodeDevicesInfoReducer(
  state = initialState,
  action: NODEDevicesActionTypes
): NodeDevicesState {
  switch (action.type) {
    case SET_NODE_TELE_DIAG_LOADING:
      let tempDiagDeviceCounter = state.diagDeviceCounter;
      let tempStreamModemsCounter = state.streamModemsCounter;
      let tempStartTime = state.startTime;
      let tempEndTime = state.endTime;
      let tempGlobleStatus = state.globleStatus;
      let tempNodeDevices = state.nodeDevicesList;
      let tempCountStreamModemsLoaded = state.countStreamModemsLoaded;
      // if (action.payload && tempDiagDeviceCounter === 0) {
      //   tempStartTime = Date.now();
      //   tempEndTime = 0;
      //   tempGlobleStatus = "loading";
      //   if (
      //     state.globleStatus === "stopped" ||
      //     state.globleStatus === "complete"
      //   ) {
      //     tempNodeDevices = tempNodeDevices?.map((d: any) => {
      //       return { ...d, ...{ cmData: undefined, apiCallStatus: "" } };
      //     });
      //   }
      // }else

      if (action.payload && tempStreamModemsCounter === 0) {
        tempStartTime = Date.now();
        tempEndTime = 0;
        tempGlobleStatus = "loading";
        tempCountStreamModemsLoaded = 0;
        if (
          state.globleStatus === "stopped" ||
          state.globleStatus === "complete"
        ) {
          tempNodeDevices = tempNodeDevices?.map((d: any) => {
            return { ...d, ...{ cmData: undefined, apiCallStatus: "" } };
          });
        }
      }
      // else if (!action.payload && tempDiagDeviceCounter > 0) {

      //   tempDiagDeviceCounter = 0;
      //   tempEndTime = Date.now();
      //   tempGlobleStatus =
      //     state.diagDeviceCounter === state.nodeDevicesList?.length
      //       ? "complete"
      //       : "stopped";
      // }
      else if (!action.payload && tempStreamModemsCounter > 0) {
        tempCountStreamModemsLoaded = tempStreamModemsCounter;
        tempStreamModemsCounter = 0;
        tempEndTime = Date.now();
        tempGlobleStatus =
          state.countStreamModemsLoaded === state.nodeDevicesList?.length
            ? "complete"
            : "stopped";
      }
      return {
        ...state,
        globleLoading: action.payload,
        globleStatus: tempGlobleStatus,
        diagDeviceCounter: tempDiagDeviceCounter,
        streamModemsCounter: tempStreamModemsCounter,
        countStreamModemsLoaded: tempCountStreamModemsLoaded,
        startTime: tempStartTime,
        endTime: tempEndTime,
        nodeDevicesList: tempNodeDevices,
      };
    case SET_NODE_TELE_DIAG_TYPE:
      return {
        ...state,
        dataSetType: action.payload,
      };
    case START_NODE_DEVICE_DIAG:
      return {
        ...state,
        inProgressApiCalls: state.inProgressApiCalls + 1,
        diagDeviceCounter: state.diagDeviceCounter + 1,
        nodeDevicesList: state.nodeDevicesList.map((d: NodeDeviceData) => {
          if (d.deviceKey === action.deviceKey) {
            return { ...d, ...{ isLoading: true } };
          } else {
            return d;
          }
        }),
      };
    case SAVE_NODE_DEVICES:
      let tempArray = action.payload;
      let tempMap = new Map<string, number>();
      tempArray?.map((modem: any, index: number) => {
        tempMap.set(modem?.cmMac, index);
      });
      return {
        ...state,
        nodeDevicesList: tempArray,
        nodeDevicesMap: Object.fromEntries(tempMap),
      };

    case UPDATE_NODE_DEVICES:
      return {
        ...state,
        inProgressApiCalls: state.inProgressApiCalls - 1,
        nodeDevicesList: state.nodeDevicesList.map((d: NodeDeviceData) => {
          if (d.deviceKey === action.devicekey) {
            let tempData = d?.cmData;
            switch (action?.apiToUpdate) {
              case "all":
                tempData = { ...tempData, ...action.data };
                break;
              case "summary":
                tempData = { ...tempData, ...{ summary: action.data } };
                break;
              case "flaps":
                tempData = { ...tempData, ...{ flaps: action.data } };
                break;
              case "error":
                tempData = action.data;
                break;
            }
            return {
              ...d,
              ...{
                isLoading: false,
                apiCallStatus: action.status,
                cmData: tempData,
              },
            };
          } else {
            return d;
          }
        }),
      };
    // case UPDATE_NODE_DEVICES_STREAM:
    // return {
    //   ...state,
    //   streamModemsCounter: state.streamModemsCounter + 1,
    //   countStreamModemsLoaded: state.streamModemsCounter + 1 === state.nodeDevicesList.length?state.nodeDevicesList.length:0,
    //   nodeDevicesList: state.nodeDevicesList.map((d: NodeDeviceData) => {
    //     if (d.cmMac === action.macAddress) {
    //       let tempData = d?.cmData;
    //       switch (action?.apiToUpdate) {
    //         case "all":
    //           tempData = { ...tempData, ...action.data };
    //           break;
    //         case "summary":
    //           tempData = { ...tempData, ...{ summary: action.data } };
    //           break;
    //         case "flaps":
    //           tempData = { ...tempData, ...{ flaps: action.data } };
    //           break;
    //         case "error":
    //           tempData = action.data;
    //           break;
    //       }
    //       return {
    //         ...d,
    //         ...{
    //           isLoading: false,
    //           apiCallStatus: action.status,
    //           cmData: tempData,
    //         },
    //       };
    //     } else {
    //       return d;
    //     }
    //   }),
    // };
    case UPDATE_NODE_DEVICES_STREAM:
      let tempMap1 = state.nodeDevicesMap;
      let index = tempMap1?.[action.macAddress];
      //tempMap1.get(action.macAddress);
      let d = state.nodeDevicesList[index !== undefined ? index : -1];
      let tempList = state.nodeDevicesList;
      let tempObj = d;

      if (d !== undefined) {
        let tempData = d?.cmData;
        switch (action?.apiToUpdate) {
          case "all":
            tempData = { ...tempData, ...action.data };
            break;
          case "summary":
            tempData = { ...tempData, ...{ summary: action.data } };
            break;
          case "flaps":
            tempData = { ...tempData, ...{ flaps: action.data } };
            break;
          case "error":
            tempData = action.data;
            break;
        }
        tempObj = {
          ...d,
          ...{
            isLoading: false,
            apiCallStatus: action.status,
            cmData: tempData,
          },
        };
      }
      tempList[index !== undefined ? index : -1] = tempObj;
      return {
        ...state,
        streamModemsCounter: state.streamModemsCounter + 1,
        countStreamModemsLoaded:
          state.streamModemsCounter + 1 === state.nodeDevicesList.length
            ? state.nodeDevicesList.length
            : 0,
        nodeDevicesList: [...tempList],
      };
    case UPDATE_NODE_DEVICES_ARRAY:
      let tempMap2 = state.nodeDevicesMap;
      let tempList1 = state.nodeDevicesList;
      action.data.map((device: any) => {
        if (device != undefined) {
          device = JSON.parse(JSON.stringify(device));

          let index1 = tempMap2?.[device?.macId];

          let d1 = state.nodeDevicesList[index1 !== undefined ? index1 : -1];

          let tempObj1 = d1;

          if (d1 !== undefined) {
            let tempData = d1?.cmData;
            switch (action?.apiToUpdate) {
              case "all":
                tempData = { ...tempData, ...device.fullCMDiagResponse.data };
                break;
              case "summary":
                tempData = {
                  ...tempData,
                  ...{ summary: device.summaryResponse.data },
                };
                break;
              case "flaps":
                tempData = { ...tempData, ...{ flaps: action.data } };
                break;
              case "error":
                tempData = action.data;
                break;
            }
            tempObj1 = {
              ...d1,
              ...{
                isLoading: false,
                apiCallStatus: "OK",
                cmData: tempData,
              },
            };
          }
          tempList1[index1 !== undefined ? index1 : -1] = tempObj1;
        }
      });
      return {
        ...state,
        streamModemsCounter: state.streamModemsCounter + action.data.length,
        countStreamModemsLoaded:
          state.streamModemsCounter + action.data.length ===
          state.nodeDevicesList.length
            ? state.nodeDevicesList.length
            : 0,
        nodeDevicesList: [...tempList1],
      };
    case RESET_NODE_DEVICES:
      return {
        ...state,
        nodeDevicesList: [],
      };

    case SET_TELE_DATASET_TYPE:
      return {
        ...state,
        dataSetType: action.payload,
      };
    case SET_TELE_VIEW_TYPE:
      return {
        ...state,
        viewType: action.payload,
      };
    case SET_NODE_DEVICES_ERROR:
      return {
        ...state,
        overAllError: action.payload,
      };
    default:
      return state;
  }
}
