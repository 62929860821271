import { Button, Form } from "react-bootstrap";
import { ArrowRightCircleFill } from "react-bootstrap-icons";

const CreateComment = (props: any) => {
  return (
    <Form.Group className="create-comment">
      <Form.Control
        name="description"
        onChange={props.onCommentChange}
        as="textarea"
        rows={1}
        className="description"
        id="description"
        value={props.comment}
        placeholder="Write a comment..."
      />
      <Button
        className="send-icon-button"
        type="submit"
        onClick={props.handleSubmit}
      >
        <ArrowRightCircleFill className="send-icon" />
      </Button>
    </Form.Group>
  );
};

export default CreateComment;
