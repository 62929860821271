import { Paper } from "@material-ui/core";

import "../../../design-tokens/rpd-page/RPDPage.scss";
import { useDispatch } from "react-redux";
import AppLabels from "../../../constants/App_Labels";
import { TAF_ORDER_CONST } from "../../../constants/Global";
import RPDClient from "../../../network/httpclient/rpd/RPDClient";
import { formatRPDMacAddress, getDeviceType } from "../../reusable/Util";
import LoaderGif from "../../../resources/images/rogersLoader.gif";

const RPDSearchDetails = (props: any) => {
  const labels = AppLabels();

  const {
    isLoading,
    searchData,
    isSwVersionLoading,
    swVersion,
    deviceType,
    phub,
  } = props;
  const dispatch = useDispatch();

  const createInputMacAdObject = (macAddress: string): any => {
    let inputObject = {
      rpdmacAddress: macAddress,
    };
    return inputObject;
  };

  const handleMacAddSearch = (macAddress: string) => {
    let stringMacAdd = formatRPDMacAddress(macAddress);
    dispatch(
      RPDClient.getMacSwVersion(
        TAF_ORDER_CONST.rpdSearchbyMacAddressBotName,
        TAF_ORDER_CONST.rpdSearchbyMacAddressSuiteName,
        createInputMacAdObject(stringMacAdd)
      )
    );
  };

  return (
    <>
      <div className="certiTableCont">
        <Paper className="deviceTablePaper">
          <div className="activationInputContainer">
            <div className="filterRow">
              <div className="cardContentContainer">
                <span className="cardContentLabel">{labels.MacAddress}</span>
                <br />
                <span className="cardContentText">
                  {searchData?.macAddress}
                </span>
              </div>
              <div className="cardContentContainer">
                <span className="cardContentLabel">
                  {labels.clamshellIDTxt}
                </span>
                <br />
                <span className="cardContentText">{searchData?.clamShell}</span>
              </div>
              <div className="cardContentContainer">
                <span className="cardContentLabel">{labels.AccessLeaf}</span>
                <br />
                <span className="cardContentText">
                  {searchData?.accessLeaf}
                </span>
              </div>
              <div className="cardContentContainer">
                <span className="cardContentLabel">
                  {labels.AccessLeafPort}
                </span>
                <br />
                <span className="cardContentText">
                  {searchData?.accessLeafPort}
                </span>
              </div>
              <div className="cardContentContainer">
                <span className="cardContentLabel">{labels.ServiceLeaf}</span>
                <br />
                <span className="cardContentText">
                  {searchData?.serviceLeaf}
                </span>
              </div>
              <div className="cardContentContainer">
                <span className="cardContentLabel">
                  {labels.ServiceLeafPort}
                </span>
                <br />
                <span className="cardContentText">
                  {searchData?.serviceLeafPort}
                </span>
              </div>
            </div>
          </div>
          <div className="activationInputContainer">
            <div className="filterRow">
              <div className="cardContentContainer">
                <span className="cardContentLabel">{labels.CMTSName}</span>
                <br />
                <span className="cardContentText">{searchData?.cmtsName}</span>
              </div>
              <div className="cardContentContainer">
                <span className="cardContentLabel">{labels.CMTSPort}</span>
                <br />
                <span className="cardContentText">{searchData?.cmtsPort}</span>
              </div>
              <div className="cardContentContainer">
                <span className="cardContentLabel">{labels.MacDomain}</span>
                <br />
                <span className="cardContentText">{searchData?.macDomain}</span>
              </div>

              <>
                {isSwVersionLoading ? (
                  <div className="freqContainer">
                    <div className="cardContentContainer">
                      <img style={{ height: "30px" }} src={LoaderGif} />
                    </div>
                  </div>
                ) : deviceType === undefined ? (
                  <div className="freqContainer">
                    <button
                      disabled={isLoading}
                      className="applyFilersBtn"
                      onClick={() => handleMacAddSearch(searchData?.macAddress)}
                    >
                      {labels.fetchSwVersion}
                    </button>
                  </div>
                ) : (
                  <>
                    <div className="cardContentContainer">
                      <span className="cardContentLabel">
                        {labels.deviceType}
                      </span>
                      <br />
                      <span className="cardContentText">
                        {getDeviceType(deviceType)}
                      </span>
                    </div>
                    <div className="phubContainer">
                      <span className="cardContentLabel">{labels.PHUB}</span>
                      <br />
                      <span className="cardContentText">{phub}</span>
                    </div>
                    {swVersion === "" ||
                    swVersion === null ||
                    swVersion === undefined ? (
                      <div className="swVersionContainer">
                        <span className="cardContentLabel">
                          {labels.rpdSwVersion}
                        </span>
                        <br />
                        <span className="cardContentText">
                          CMTS connection failed
                        </span>
                      </div>
                    ) : (
                      <div className="swVersionContainer">
                        <span className="cardContentLabel">
                          {labels.rpdSwVersion}
                        </span>
                        <br />
                        <span className="cardContentText">{swVersion}</span>
                      </div>
                    )}
                  </>
                )}
              </>
            </div>
          </div>

          <>
            <div className="subDataCard">
              <span className="titleLabel">{labels.returnSegmentDetails}</span>
            </div>
            <div className="cardNodeInputContainer">
              <div className="cardContentContainer">
                <span className="cardContentLabel">{labels.nodeIDOne}</span>
                <br />
                {searchData?.ReturnSegment_1 == null ? (
                  <span className="cardContentText">N/A</span>
                ) : (
                  <span className="cardContentText">
                    {searchData?.ReturnSegment_1}
                  </span>
                )}
              </div>
              <div className="cardContentContainer">
                <span className="cardContentLabel">{labels.nodeIDTwo}</span>
                <br />
                {searchData?.ReturnSegment_2 == null ? (
                  <span className="cardContentText">N/A</span>
                ) : (
                  <span className="cardContentText">
                    {searchData?.ReturnSegment_2}
                  </span>
                )}
              </div>
            </div>
          </>
        </Paper>
      </div>
    </>
  );
};

export default RPDSearchDetails;
