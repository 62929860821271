export const SAVE_NEW_FEEDBACK_BEGIN = "SAVE_NEW_FEEDBACK_BEGIN";
export const SAVE_NEW_FEEDBACK_SUCCESS = "SAVE_NEW_FEEDBACK_SUCCESS";
export const SAVE_NEW_FEEDBACK_FAILURE = "SAVE_NEW_FEEDBACK_FAILURE";

interface SaveNewFeedbackBegin {
    type: typeof SAVE_NEW_FEEDBACK_BEGIN;
  }
  interface SaveNewFeedbackSuccess {
    type: typeof SAVE_NEW_FEEDBACK_SUCCESS;
    payload: any;
  }
  interface SaveNewFeedbackFailure {
    type: typeof SAVE_NEW_FEEDBACK_FAILURE;
    payload: Error;
  }

  export type NewFeedbackActionTypes =
  | SaveNewFeedbackBegin
  | SaveNewFeedbackSuccess
  | SaveNewFeedbackFailure