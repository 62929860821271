export const DEVICE_DIAG_LIST_BEGIN = "DEVICE_DIAG_LIST_BEGIN";
export const DEVICE_DIAG_LIST_SUCCESS = "DEVICE_DIAG_LIST_SUCCESS";
export const DEVICE_DIAG_LIST_FAILURE = "DEVICE_DIAG_LIST_FAILURE";
export const DEVICE_DIAG_LIST_RESET = "DEVICE_DIAG_LIST_RESET";
interface DeviceDiagListBegin {
  type: typeof DEVICE_DIAG_LIST_BEGIN;
}

interface DeviceDiagListSuccess {
  type: typeof DEVICE_DIAG_LIST_SUCCESS;
  payload: any;
}

interface DeviceDiagListFailure {
  type: typeof DEVICE_DIAG_LIST_FAILURE;
  payload: Error;
}
interface DeviceDiagListReset {
  type: typeof DEVICE_DIAG_LIST_RESET;
}

export type DeviceDiagListActionTypes =
  | DeviceDiagListBegin
  | DeviceDiagListSuccess
  | DeviceDiagListFailure
  | DeviceDiagListReset;
