import CWDownstream from "../../../components/codewords/CWDownstream";
import CwOFDMA from "../../../components/codewords/CwOFDMA";
import CwOFDMDownStream from "../../../components/codewords/CwOFDMDownStream";
import CWUpstream from "../../../components/codewords/CWUpstream";
import { useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import FailedAttributesCw from "../../FailedAttributes/FailedAttributesCw";
import CodeWordMonitor from "../../codewords/CodeWordMonitor";
import {
  SetSCQAMFLAG,
  SetFailedCodewords,
  SetCodewordValues,
} from "../../../store/actions/account/accountInfo/ActiveAccountAction";
import {
  CWMonitoringIndex,
  CWMonitoringReset,
} from "../../../store/actions/account/diagnostics/cm/CWMonitoringAction";
import ExceptionBox from "../../reusable/ExceptionBox";
import ViewCWUpstream from "../../codewords/ViewCWUpstream";
import ViewCWDownstream from "../../codewords/ViewCWDowsntream";
import ViewCwOFDMA from "../../codewords/ViewCwOFDMA";
import ViewCwOFDMDownStream from "../../codewords/ViewCwOFDMDownStream";
import ReloadData from "../../reusable/ReloadData";
import { MdRefresh } from "react-icons/md";

const ViewCodewordSteps = (props: any) => {
  const { hsiCertiData, seleDevice } = props;
  const failed = hsiCertiData?.summary?.failed;
  const [cw, setCW] = useState(false);
  const codewords = hsiCertiData?.codewords
    ? hsiCertiData?.codewords
    : hsiCertiData;
  const failedcodeword = hsiCertiData?.failedAttributes;

  const { cwMonitorLoading, cwMonitorCallIndex, cwMonitorData, isLoading } =
    useSelector(
      (state: any) => ({
        cwMonitorLoading: state.AccountState.cwMonitorInfo.overallLoading,
        isLoading: state.AccountState.cwMonitorInfo.isLoading,
        cwMonitorData: state.AccountState.cwMonitorInfo.data,
        cwMonitorCallIndex: state.AccountState.cwMonitorInfo.callIndex,
      }),
      shallowEqual
    );
  //dispatch(CWMonitoringReset());
  const dispatch = useDispatch();

  useEffect(() => {
    setCW(false);
    if (
      hsiCertiData?.codewords !== undefined &&
      hsiCertiData?.codewords?.length > 0
    ) {
      if (hsiCertiData?.failedCodewords?.length > 0) {
        dispatch(SetFailedCodewords(hsiCertiData?.failedCodewords));
        dispatch(SetSCQAMFLAG(true));
      } else {
        dispatch(SetFailedCodewords(""));
        dispatch(SetSCQAMFLAG(false));
      }
    }
  }, []);

  /*useEffect(() => {
    if (cwMonitorCallIndex < 7 && codewords !== undefined) {
      setCurrCWInfo(codewords[cwMonitorCallIndex - 1]);
      if (cwMonitorCallIndex === 1) {
        setFirstCWInfo(codewords[cwMonitorCallIndex - 1]);
      }
      setSeconds(60);
      dispatch(CWMonitoringIndex(cwMonitorCallIndex));
    }
  }, [cwMonitorCallIndex]);*/

  return (
    <>
      {cw === false ? (
        <div className="cmSummaryCont">
          {codewords !== undefined && codewords?.length > 0 ? (
            <>
              <ViewCWUpstream codewords={codewords} />
              <ViewCWDownstream codewords={codewords} />
              <ViewCwOFDMA codewords={codewords} />
              <ViewCwOFDMDownStream codewords={codewords} />
            </>
          ) : (
            <div className="diagErrorCont">
              <ExceptionBox
                headerText="No Data to display in Codeword Monitoring."
                bodyText="Please try refreshing."
                primaryBtn={false}
                secBtn={false}
              />
            </div>
          )}
        </div>
      ) : (
        <CodeWordMonitor seleDevice={seleDevice} cw={cw} />
      )}
    </>
  );
};
export default ViewCodewordSteps;
