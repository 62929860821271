import React from "react";
import AppLabels from "../../constants/App_Labels";
import RLogo from "../../resources/images/R-Logo2.png";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const FullScreenPopUp = (props: any) => {
  const {
    open,
    handleClose,
    childComponent,
    pageName,
    disableEscapeKeyDown = false,
  } = props;
  const labels = AppLabels();
  return (
    <div>
      <Dialog
        fullScreen
        className="fullScreenDialog"
        style={{ zIndex: 1250 }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        disableEscapeKeyDown={disableEscapeKeyDown}
      >
        <AppBar className="fullDialogCont">
          <Toolbar className="fullDialogBar">
            <div className="logo-container">
              <img alt="logoImage" src={RLogo} className="logo" />
              <span className="appName">
                {labels.APP_NAME_LABEL}&nbsp;&nbsp;|&nbsp;&nbsp;{pageName}
              </span>
            </div>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {childComponent}
      </Dialog>
    </div>
  );
};
export default FullScreenPopUp;
