import { useDispatch } from "react-redux";
import CmDownStreamCh from "./downstream/CmDownStreamCh";
import CmUpStreamCh from "./upstream/CmUpStreamCh";
import CmNetworkInfo from "./CmNetworkInfo";
import CMSummary from "./CmSummary";
import CableModemClient from "../../network/httpclient/cablemodem/CableModemClient";
import ReloadData from "../reusable/ReloadData";
import { isNull } from "../reusable/Util";
import CMInternetInfo from "./CMInternetInfo";
import CMSpeedTestInfo from "./CMSpeedTestInfo";
import DiagApiCallCont from "../reusable/DiagApiCallCont";
import CmSpeedTest, {
  getCmSpeedTest,
} from "../../store/slices/cablemodem/cmSpeedTest";
import { GetPrecheckDiagData } from "../../get-api/GetResponse";
import CmDSPrechChannel from "./precheck/CmDSPrechChannel";
import CmUSPrechChannel from "./precheck/CmUSPrechChannel";
import { ThunkDispatch } from "redux-thunk";
import { useEffect, useState } from "react";

const CableModemInfo = (props: any) => {
  const {
    selectedSA,
    selectedDevice,
    docsisLoading,
    cmSummarydata,
    managedRouterData,
    cmNodeInfo,
    cmDocsisInfo,
    cmSummaryInfoStatus,
    cmDocsisInfoStatus,
  } = props;
  const dispatch = useDispatch();
  const precheckDiagnosticData = GetPrecheckDiagData().precheckDiagnosticData;
  const precheckSelectedDevice = precheckDiagnosticData?.data?.find(
    (d: any) =>
      d?.serialNumber === selectedDevice?.serialNumber ||
      d?.macAddress === selectedDevice?.macAddress
  );
  const precheckSeleDeviceDiag = precheckSelectedDevice?.diagResult;
  const [managedRouterInfo, setManagedRouterInfo] = useState(
    managedRouterData || null
  );
  const thunkDispatch = useDispatch<ThunkDispatch<any, any, any>>();

  useEffect(() => {
    if (!managedRouterData && selectedDevice) {
      fetchManagedRouterInfo(selectedDevice?.macAddress);
    }
  }, []);

  const fetchManagedRouterInfo = async (macAddress: string) => {
    try {
      const returnedData = await thunkDispatch(
        CableModemClient.getManagedRouterInfo(macAddress)
      );
      // console.log(returnedData);
      setManagedRouterInfo(returnedData);
    } catch (error) {
      // console.error("Error:", error);
    }
  };

  const refreshCMSummary = () => {
    dispatch(
      CableModemClient.getCMSummary(
        selectedDevice.serialNumber,
        selectedDevice.macAddress
      )
    );
    fetchManagedRouterInfo(selectedDevice.macAddress);
  };
  const refreshNodeData = () => {
    dispatch(
      CableModemClient.getCMNodeDetails(
        selectedDevice.serialNumber,
        isNull(selectedSA.shubId)
      )
    );
  };
  const refreshDocsisInfo = () => {
    dispatch(
      CableModemClient.getCMDocsisInfo(
        selectedDevice.serialNumber,
        selectedDevice.macAddress
      )
    );
  };
  const refreshData = () => {
    refreshCMSummary();
    refreshNodeData();
    refreshDocsisInfo();
  };

  return (
    <>
      <div className="refreshCont">
        <ReloadData refreshData={refreshData} />
      </div>
      <div className="cmSummaryCont">
        <DiagApiCallCont
          data={cmSummarydata}
          status={cmSummaryInfoStatus}
          sectionName="CM Summary"
          handleRefresh={() => {
            refreshCMSummary();
            refreshNodeData();
          }}
        >
          <CMInternetInfo
            selectedSA={selectedSA}
            docsisLoading={docsisLoading}
            cmDocsisInfo={cmDocsisInfo}
            macAddress={selectedDevice.macAddress}
          />
        </DiagApiCallCont>

        <CMSpeedTestInfo macAddress={selectedDevice?.macAddress} />
        <DiagApiCallCont
          data={cmSummarydata}
          status={cmSummaryInfoStatus}
          sectionName="CM Summary"
          handleRefresh={() => {
            refreshCMSummary();
            refreshNodeData();
          }}
        >
          <CmNetworkInfo
            cmSummarydata={cmSummarydata}
            cmNodeInfo={cmNodeInfo}
            cmNodeInfoError={cmNodeInfo}
          />
        </DiagApiCallCont>
        <DiagApiCallCont
          isLoading={docsisLoading}
          sectionName="CM Docsis"
          data={cmDocsisInfo}
          status={cmDocsisInfoStatus}
          handleRefresh={refreshDocsisInfo}
        >
          <CmDSPrechChannel
            cmDocsisInfo={cmDocsisInfo}
            preCmDocsisInfo={precheckSeleDeviceDiag?.docsis}
          />
          <CmUSPrechChannel
            cmDocsisInfo={cmDocsisInfo}
            preCmDocsisInfo={precheckSeleDeviceDiag?.docsis}
          />
        </DiagApiCallCont>
        <DiagApiCallCont
          data={cmSummarydata}
          status={cmSummaryInfoStatus}
          sectionName="CM Summary"
          handleRefresh={() => {
            refreshCMSummary();
          }}
        >
          <CMSummary
            cmSummarydata={cmSummarydata}
            managedRouterdata={managedRouterInfo}
            managedRouterFlag={true}
          />
        </DiagApiCallCont>
      </div>
    </>
  );
};
export default CableModemInfo;
