import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";

interface Props {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}
export default function Shortcuts({ showModal, setShowModal }: Props) {
  const items = [
    { key: "Ctr+c", desc: "Copy image to clipboard" },
    { key: "Ctr+v", desc: "Paste image from clipboard" },
    { key: "Ctr+z", desc: "Undo added item" },
    { key: "Ctr+y", desc: "Redo added item" },
    { key: "Delete", desc: "Delete selected item" },
  ];

  return (
    <Modal
      show={showModal}
      id="geoEditShortcutModal"
      onHide={() => setShowModal(false)}
      centered
    >
      <Modal.Header className="searchPopHead">
        <header>{"Keyboard Shortcuts"}</header>
        <button className="sPopUpClose" onClick={() => setShowModal(false)}>
          <IoClose color="white" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: 5 }}>
          {items.map((item) => (
            <div
              key={item.key}
              className="flexedRowDiv"
              style={{
                marginBottom: 10,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  backgroundColor: `var(--greyLight)`,
                  padding: "2px 5px",
                  borderRadius: 2,
                }}
              >
                {item.key}
              </div>
              <div style={{ marginLeft: "auto" }}>{item.desc}</div>
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}
