
import {GetXleVisibleBegin,GetXleVisibleSuccess, GetXleVisibleFailure} from "../../../store/slices/accountInfo/xleVisible";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import {xleVisibleState} from  "../../../store/states/account/accountInfo/xleVisibleState";
import {GetXleGWBegin,GetXleGWSuccess, GetXleGWFailure} from "../../../store/slices/accountInfo/xleGW";
import {GetXleCellularBegin,GetXleCellularSuccess, GetXleCellularFailure} from "../../../store/slices/accountInfo/xleCellular";
import {xleGWState} from  "../../../store/states/account/accountInfo/xleGWState";
import {GetBatteryBegin,GetBatterySuccess, GetBatteryFailure} from "../../../store/slices/accountInfo/battery";
import {batteryState} from "../../../store/states/account/accountInfo/batteryState";
export default class xleVisiblilityClient {

static getXleEnable =
(): ThunkAction<void, xleVisibleState, unknown, Action<string>> =>
async (dispatch) => {
  let httpParameters: IHttpClientRequestParameters<any> = {
    url: NetworkConfig.ACCOUNT_SERVICE_URL + "xle/enabled",
  };

  dispatch(GetXleVisibleBegin());

  HttpClient.get<any>(httpParameters)
    .then((res) => {
      dispatch(GetXleVisibleSuccess(res));
      return res;
    })
    .catch((error) => {
      dispatch(GetXleVisibleFailure(error));
      return error;
    });
}
static getXleGateway =
(macAddress: string): ThunkAction<void, xleGWState, unknown, Action<string>> =>
async (dispatch) => {
  let httpParameters: IHttpClientRequestParameters<any> = {
    url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/rpil/cbeGateway/"+macAddress,
  };

  dispatch(GetXleGWBegin());

  HttpClient.get<any>(httpParameters)
    .then((res) => {
      dispatch(GetXleGWSuccess(res));
      return res;
    })
    .catch((error) => {
      dispatch(GetXleGWFailure(error));
      return error;
    });
}
static getXleCellular =
(macAddress: string): ThunkAction<void, xleVisibleState, unknown, Action<string>> =>
async (dispatch) => {
  let httpParameters: IHttpClientRequestParameters<any> = {
    url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/rpil/cbeCellular/"+macAddress,
  };

  dispatch(GetXleCellularBegin());

  HttpClient.get<any>(httpParameters)
    .then((res) => {
      dispatch(GetXleCellularSuccess(res));
      return res;
    })
    .catch((error) => {
      dispatch(GetXleCellularFailure(error));
      return error;
    });
}


static getBattery =
(macAddress: string): ThunkAction<void, batteryState, unknown, Action<string>> =>
async (dispatch) => {
  let httpParameters: IHttpClientRequestParameters<any> = {
    url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/rpil/cbeBattery/"+macAddress,
  };

  dispatch(GetBatteryBegin());

  HttpClient.get<any>(httpParameters)
    .then((res) => {
      dispatch(GetBatterySuccess(res));
      return res;
    })
    .catch((error) => {
      dispatch(GetBatteryFailure(error));
      return error;
    });
}
}