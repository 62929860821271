import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../middleware/apiActions";
import { WIRELESS_SITE_SEARCH_URI } from "../../middleware/apiEndpoints";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "wirelessSiteSearch",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    getWirelessSiteSearchBegin: (ws) => {
      ws.isLoading = true;
      ws.status = "";
    },
    getWirelessSiteSearchSuccess: (ws, { payload }) => {
      ws.isLoading = false;
      ws.data = payload;
      ws.status = storeConst.OK;
    },
    getWirelessSiteSearchFailure: (ws, { payload }) => {
      ws.isLoading = false;
      ws.error = payload;
      ws.data = undefined;
      ws.status = storeConst.ERROR;
    },
  },
});

const {
  getWirelessSiteSearchBegin,
  getWirelessSiteSearchSuccess,
  getWirelessSiteSearchFailure,
} = slice.actions;
export default slice.reducer;

export const getWirelessSiteSearch =
  (searchedWirelessSite: string) => (dispatch: any) => {
    dispatch(
      apiCallBegan({
        url: WIRELESS_SITE_SEARCH_URI + searchedWirelessSite,
        onStart: getWirelessSiteSearchBegin.type,
        onSuccess: getWirelessSiteSearchSuccess.type,
        onError: getWirelessSiteSearchFailure.type,
      })
    );
  };
