import { createSlice } from "@reduxjs/toolkit";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "rpdActivation",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
    nptid: "",
  },
  reducers: {
    rpdActivationBegin: (rpd) => {
      rpd.isLoading = true;
    },
    rpdActivationSuccess: (rpd, action) => {
      rpd.isLoading = false;
      rpd.data = action.payload;
      rpd.error = undefined;
      rpd.status = storeConst.OK;
    },
    rpdActivationFailure: (rpd, action) => {
      rpd.isLoading = false;
      rpd.error = action.payload;
      rpd.data = undefined;
      rpd.status = storeConst.ERROR;
    },
    setNPTId: (rpd, action) => {
      rpd.nptid = action.payload;
      rpd.error = undefined;
      rpd.data = undefined;
      rpd.status = "";
    },
  },
});

export const {
  rpdActivationBegin,
  rpdActivationSuccess,
  rpdActivationFailure,
  setNPTId,
} = slice.actions;
export default slice.reducer;
