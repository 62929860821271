import { ODUNetworkInterface } from "../../../../models/account/diagnostics/odu/ODUNetworkInterface";

export const ODU_NETWORK_INTERFACE_BEGIN = "ODU_NETWORK_INTERFACE_BEGIN";
export const ODU_NETWORK_INTERFACE_SUCCESS = "ODU_NETWORK_INTERFACE_SUCCESS";
export const ODU_NETWORK_INTERFACE_FAILURE = "ODU_NETWORK_INTERFACE_FAILURE";

interface ODUNetworkInterfaceBegin {
  type: typeof ODU_NETWORK_INTERFACE_BEGIN;
}

interface ODUNetworkInterfaceSuccess {
  type: typeof ODU_NETWORK_INTERFACE_SUCCESS;
  payload: ODUNetworkInterface;
}

interface ODUNetworkInterfaceFailure {
  type: typeof ODU_NETWORK_INTERFACE_FAILURE;
  payload: Error;
}

export type ODUNetworkInterfaceActionTypes =
  | ODUNetworkInterfaceBegin
  | ODUNetworkInterfaceSuccess
  | ODUNetworkInterfaceFailure;
