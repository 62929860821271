import { FormDataState } from "../../../states/account/forms/FormDataState";
import {
  GET_FORM_BEGIN,
  GET_FORM_SUCCESS,
  GET_FORM_FAILURE,
  HISTORY_FORM_RESET,
  NewFormDataActionTypes,
} from "../../../types/account/forms/FormData";

const initialState: FormDataState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function FormDataReducer(
  state = initialState,
  action: NewFormDataActionTypes
): FormDataState {
  switch (action.type) {
    case GET_FORM_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case GET_FORM_BEGIN:
      return {
        status: "",
        isLoading: true,
      };
    case GET_FORM_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };
    case HISTORY_FORM_RESET:
      return {
        ...state,
        isLoading: false,
        data: undefined,
        error: undefined,
        status: "",
      };
    default:
      return state;
  }
}
