import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../middleware/apiActions";
import {
  NODE_SITE_COMPONENTS,
  NODE_URI,
} from "../../../middleware/apiEndpoints";
import storeConst from "../../../storeConst";
import { StandardReduxType } from "../../../reduxTypes";
import ConfigConst from "../../../../../constants/ConfigConst";

type MduNodeSiteProp = [
  {
    networkId?: string;
    isLoading: boolean;
    data?: StandardReduxType;
    error?: StandardReduxType;
    status?: string;
  }?
];

const slice = createSlice({
  name: "mduNodeSiteInfo",
  initialState: {
    allMduNodeSites: [] as MduNodeSiteProp,
  },
  reducers: {
    getMDUNodeSiteInfoBegin: (mduNodeSiteInfo, { payload }) => {
      const mduNSIndex = mduNodeSiteInfo?.allMduNodeSites?.findIndex(
        (nsc: any) => nsc?.networkId === payload.networkId
      );
      if (mduNSIndex === -1) {
        mduNodeSiteInfo.allMduNodeSites.push({
          networkId: payload.networkId,
          isLoading: true,
        });
      } else {
        mduNodeSiteInfo.allMduNodeSites[mduNSIndex] = {
          networkId: payload.networkId,
          isLoading: true,
          data: undefined,
          error: undefined,
          status: "",
        };
      }
    },
    getMDUNodeSiteInfoSuccess: (mduNodeSiteInfo, { payload }) => {
      const mduNSCIndex = mduNodeSiteInfo?.allMduNodeSites?.findIndex(
        (nsc: any) => nsc?.networkId === payload.networkId
      );
      const mduNSCData = mduNodeSiteInfo?.allMduNodeSites[mduNSCIndex];
      mduNodeSiteInfo.allMduNodeSites[mduNSCIndex] = {
        ...mduNSCData,
        ...{
          isLoading: false,
          data: payload.data?.features?.[0],
          status: storeConst.OK,
        },
      };
    },
    getMDUNodeSiteInfoFailure: (mduNodeSiteInfo, { payload }) => {
      const mduNSCIndex = mduNodeSiteInfo?.allMduNodeSites?.findIndex(
        (nsc: any) => nsc?.networkId === payload.networkId
      );
      const mduNSCData = mduNodeSiteInfo?.allMduNodeSites[mduNSCIndex];
      mduNodeSiteInfo.allMduNodeSites[mduNSCIndex] = {
        ...mduNSCData,
        ...{ isLoading: false, error: payload.data, status: storeConst.ERROR },
      };
    },
  },
});

const {
  getMDUNodeSiteInfoBegin,
  getMDUNodeSiteInfoSuccess,
  getMDUNodeSiteInfoFailure,
} = slice.actions;
export default slice.reducer;

export const getMDUNodeSiteInfo =
  (nodeId: string, networkId: string) => (dispatch: any, getState: any) => {
    const { allMduNodeSites } =
      getState().slices.topologyState.mduElements.mduNodeSiteInfo;

    let mduNodeSiteData = allMduNodeSites?.filter(
      (nsc: any) => nsc?.networkId === networkId
    )?.[0];
    let nodeType = ConfigConst.docsis;
    let reqPayload = {
      nodeId: nodeId,
      nodeType: nodeType?.toUpperCase(),
    };
    if (!mduNodeSiteData) {
      dispatch(
        apiCallBegan({
          url: NODE_URI,
          params: reqPayload,
          onStart: getMDUNodeSiteInfoBegin.type,
          onSuccess: getMDUNodeSiteInfoSuccess.type,
          onError: getMDUNodeSiteInfoFailure.type,
          props: { networkId },
        })
      );
    }
  };
