import { Marker } from "@react-google-maps/api";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { mapElementStyles } from "../../../config/mapItemsConfig";
import appConst from "../../../constants/appConst";
import {
  getNodeSiteComponents,
  getNodeSiteInfo,
  setCenterAndCallApis,
} from "../../../store/slices/mapItems/node/nodeSiteDetail";
import {
  GetNodeSiteDetailsProps,
  GetTopoGeneralProps,
  GetVhubSearchProps,
} from "../../../util/reduxFunctions/getTopologyState";
import {
  getBase64ScaledImageAnchor,
  getMapElementClickableImage,
  getPngImagesForElement,
} from "../../../util/utilFuncs";
import { setSelectedMapElement } from "../../../store/slices/mapItems/mapDetails";
import { useVhubData } from "../../../util/customHooks/topologyHooks";
import { getVhubSearch } from "../../../store/slices/mapItems/vhub/vhubSearch";

interface Props {
  handleNodeSiteClick: any;
  zoomLevel: any;
  pngImages: any;
  isVisible: boolean;
}

const NodeSiteDetail = ({
  handleNodeSiteClick,
  zoomLevel,
  pngImages,
  isVisible,
}: Props) => {
  const dispatch = useDispatch();
  const [vhubFlag, setVhubFlag] = useState(false);
  const { activeNodeSite, allNodeData, activeNodeIndex, activeNode } =
    GetNodeSiteDetailsProps();
  const { activeNodeType } = GetTopoGeneralProps();
  const isPrimaryNode = activeNode?.isPrimaryNode ? true : false;
  const { data, isLoading, status } = GetVhubSearchProps();
  const locatorFlag = useVhubData(vhubFlag);
  if (locatorFlag === 1) {
    setVhubFlag(false);
  }
  useEffect(() => {
    if (
      !activeNodeSite?.isLoading &&
      activeNodeSite?.status === appConst.OK &&
      activeNodeSite?.data !== undefined
    ) {
      dispatch(setCenterAndCallApis());
      if (activeNodeType != appConst.GPON_NODE) {
        dispatch(
          getNodeSiteComponents(
            activeNodeSite?.data?.features?.[0]?.properties?.data?.networkId
          )
        );
      }
    }
  }, [activeNodeSite?.status]);

  useEffect(() => {
    if (
      allNodeData &&
      allNodeData?.length > 0 &&
      allNodeData?.[activeNodeIndex] &&
      activeNodeType != appConst.GPON_NODE
    ) {
      let activeNode = allNodeData?.[activeNodeIndex];
      if (
        !activeNode?.siteComponents &&
        activeNode?.node?.data &&
        activeNode?.node?.data?.features?.[0]?.properties?.data?.siteType !==
          "V-HUB"
      ) {
        dispatch(
          getNodeSiteComponents(
            activeNode?.node?.data?.features?.[0]?.properties?.data?.networkId
          )
        );
      }
    }
  }, [activeNodeIndex]);

  useEffect(() => {
    if (activeNode?.siteComponents?.data && !isPrimaryNode) {
      let nodeSites: any = [];
      activeNode?.siteComponents?.data?.map((nodeSite: any) => {
        if (nodeSite?.type === appConst.returnSegment) {
          let nodeNums = nodeSite?.name?.split("_");
          if (nodeNums?.length > 1) nodeSites.push(nodeNums?.[1]);
          else nodeSites.push(nodeNums?.[0]);
        }
      });
      if (
        nodeSites[0] &&
        activeNode?.node?.data?.features?.[0]?.properties?.data?.siteType !==
          "V-HUB"
      ) {
        dispatch(getNodeSiteInfo(nodeSites[0]));
      }
    }
  }, [activeNode?.siteComponents]);

  useEffect(() => {
    if (activeNode?.node?.data && !isPrimaryNode) {
      let nodeData = activeNode?.node?.data?.features?.[0];
      const index = activeNode?.node?.data?.features?.findIndex(
        (e: any) =>
          e?.properties?.data?.designation === String(activeNode?.nodeSiteId)
      );
      if (index !== -1) {
        nodeData = activeNode?.node?.data?.features?.[index];

        const geoData = {
          lat: nodeData?.properties?.data?.siteCoords?.coordinates?.[1],
          lng: nodeData?.properties?.data?.siteCoords?.coordinates?.[0],
        };
        const activeElement = {
          geometry: geoData,
          properties: {
            id: nodeData?.featureId,
            type: nodeData?.properties?.type,
            data: nodeData?.properties?.data,
          },
        };
        dispatch(setSelectedMapElement(activeElement));
      }
    }
  }, [activeNode?.node?.data]);

  useEffect(() => {
    if (
      data &&
      data !== undefined &&
      vhubFlag &&
      !isLoading &&
      status === appConst.OK
    ) {
      const geoData = {
        lat: data?.features?.[0]?.geometry?.coordinates?.[1],
        lng: data?.features?.[0]?.geometry?.coordinates?.[0],
      };
      const activeElement = {
        geometry: geoData,
        properties: {
          id: data?.features?.[0]?.featureId,
          type: appConst?.vhub,
          data: data?.features?.[0]?.properties?.data,
        },
      };
      dispatch(setSelectedMapElement(activeElement));
      setVhubFlag(false);
    }
  }, [data]);

  const onNodeSiteClick = (feature: any) => {
    if (
      feature?.properties?.data?.siteType === "V-HUB" &&
      feature?.properties?.data?.designation
    ) {
      dispatch(getVhubSearch(feature?.properties?.data?.designation));
      setVhubFlag(true);
    } else {
      handleNodeSiteClick(feature, appConst.node);
    }
  };
  return (
    <>
      {isVisible &&
        allNodeData?.map((site: any) =>
          site?.node?.data?.features?.map((feature: any) => {
            const propertiesData = feature?.properties?.data;

            const anchor = getBase64ScaledImageAnchor(
              pngImages,
              appConst.node,
              propertiesData?.symbolName,
              propertiesData?.rotation,
              mapElementStyles?.nodeSite,
              zoomLevel
            );

            return (
              propertiesData?.siteCoords?.coordinates && (
                <Fragment key={propertiesData?.networkId}>
                  <Marker
                    key={`${propertiesData?.networkId}SiteMarker`}
                    position={{
                      lat: propertiesData?.siteCoords?.coordinates?.[1],
                      lng: propertiesData?.siteCoords?.coordinates?.[0],
                    }}
                    icon={getPngImagesForElement(
                      pngImages,
                      propertiesData?.symbolName,
                      propertiesData?.rotation,
                      mapElementStyles?.nodeSite,
                      zoomLevel
                    )}
                    clickable={false}
                  />

                  <Marker
                    key={`${propertiesData?.networkId}ClickableSiteMarker`}
                    position={{
                      lat: propertiesData?.siteCoords?.coordinates?.[1],
                      lng: propertiesData?.siteCoords?.coordinates?.[0],
                    }}
                    onClick={() => {
                      onNodeSiteClick(feature);
                    }}
                    icon={{
                      url:
                        appConst.imageStringPrefix +
                        encodeURIComponent(
                          getMapElementClickableImage(
                            zoomLevel,
                            anchor?.clickIconSize
                          )
                        ),
                      anchor: new google.maps.Point(
                        anchor ? anchor?.x : mapElementStyles?.common.anchor.x,
                        anchor ? anchor?.y : mapElementStyles?.common.anchor.y
                      ),
                    }}
                  />
                </Fragment>
              )
            );
          })
        )}
    </>
  );
};

export default NodeSiteDetail;
