import { Divider } from "@material-ui/core";
import { MdInfo } from "react-icons/md";
import { useDispatch } from "react-redux";
import { getHomeAddress } from "../../../../components/reusable/Util";
import AppLabels from "../../../../constants/App_Labels";
import { mapElementStyles } from "../../../config/mapItemsConfig";
import appConst from "../../../constants/appConst";
import TopologyLabels from "../../../constants/topologyLabels";
import { setSelectedMapElement } from "../../../store/slices/mapItems/mapDetails";
import { GetTelemetryProps } from "../../../util/reduxFunctions/getTopologyState";
import {
  applyDiagAdvancedFilters,
  getCMStatusProps,
} from "../../../util/utilFuncs";
import SimpleDataTable from "../../reusable/SimpleDataTable";
import TopologySymbols from "../../reusable/TopologySymbols";

interface Props {
  cmList: any;
}

const CMNotPlotted = ({ cmList }: Props) => {
  const labels = AppLabels();
  const tLabels = TopologyLabels();
  const dispatch = useDispatch();
  const { advancedFilters, advFCond } = GetTelemetryProps();

  const npCmConfig = {
    headers: [labels?.cmMac, labels?.Address, labels?.STATUS],
  };
  const handleDiagDeviceClick = (deviceData: any) => {
    if (deviceData) {
      const activeElement = {
        geometry: deviceData?.coordinate,
        properties: { type: appConst.CM, data: deviceData },
      };
      dispatch(setSelectedMapElement(activeElement));
    }
  };
  return (
    <>
      {cmList && (
        <div className="unplottedCMCont">
          <p>{tLabels.notPlottedOnMap.toUpperCase()}</p>
          <Divider />
          <span className="infoWarning">
            <MdInfo />
            <span>{tLabels.noLocationInfo}</span>
          </span>
          <SimpleDataTable
            headers={npCmConfig?.headers}
            tBody={cmList?.map((cm: any, i: number) => {
              const iconSize = mapElementStyles?.cableModem?.iconSize;
              const cmProps = getCMStatusProps(cm?.deviceDiag);
              if (
                cm?.deviceDiag &&
                applyDiagAdvancedFilters(
                  cm?.deviceDiag?.data,
                  advancedFilters,
                  advFCond
                )
              ) {
                return (
                  <tr key={i}>
                    <td
                      className="cmMacLink"
                      onClick={() => handleDiagDeviceClick(cm)}
                    >
                      {cm?.macAddress}
                    </td>
                    <td>{getHomeAddress(cm?.serviceAddress)}</td>
                    <td>
                      <span>
                        <TopologySymbols
                          name={cmProps.iconName}
                          color={cmProps.color}
                          size={iconSize}
                        />
                      </span>
                    </td>
                  </tr>
                );
              }
            })}
          />
        </div>
      )}
    </>
  );
};

export default CMNotPlotted;
