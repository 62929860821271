export const SAVE_EMAIL_FORM_BEGIN = "SAVE_EMAIL_FORM_BEGIN";
export const SAVE_EMAIL_FORM_SUCCESS = "SAVE_EMAIL_FORM_SUCCESS";
export const SAVE_EMAIL_FORM_FAILURE = "SAVE_EMAIL_FORM_FAILURE";
export const SAVE_EMAIL_FORM_RESET = "SAVE_EMAIL_FORM_RESET";

interface SaveEmailFormBegin {
  type: typeof SAVE_EMAIL_FORM_BEGIN;
}

interface SaveEmailFormSuccess {
  type: typeof SAVE_EMAIL_FORM_SUCCESS;
  payload: any;
}

interface SaveEmailFormFailure {
  type: typeof SAVE_EMAIL_FORM_FAILURE;
  payload: Error;
}

interface SaveEmailFormReset {
  type: typeof SAVE_EMAIL_FORM_RESET;
}

export type NewEmailFormActionTypes =
  | SaveEmailFormBegin
  | SaveEmailFormSuccess
  | SaveEmailFormFailure
  | SaveEmailFormReset;
