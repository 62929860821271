import { Card } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import GWDevicesClient from "../../network/httpclient/gwDevices/GWDevicesClient";
import DiagContainer from "../reusable/DiagContainer";
import {
  isNull,
  getCheckValue,
  isEmptyObject,
  getCMW3ID,
  getCMW3ID4Brand,
} from "../reusable/Util";
import ReloadData from "../reusable/ReloadData";
import AppLabels from "../../constants/App_Labels";
import { useEffect, useState } from "react";
import ExceptionBox from "../reusable/ExceptionBox";
import { GetActiveAccountDetails } from "../../get-api/GetResponse";

const GWDevicesInfo = (props: any) => {
  const labels = AppLabels();
  const { selectedDevice, selectedSA } = props;
  const dispatch = useDispatch();
  const [sortedHosts, setSortedHost] = useState([]);
  const brandName = GetActiveAccountDetails()?.brand;

  const { gwLoading, gwDeviceInfo, gwDeviceError } = useSelector(
    (state: any) => ({
      gwDeviceInfo: state.AccountState.gwDevicesInfo.data,
      gwLoading: state.AccountState.gwDevicesInfo.isLoading,
      gwDeviceError: state.AccountState.gwDevicesInfo.error,
    }),
    shallowEqual
  );

  const sortOnlineList = ["true", "false"];
  useEffect(() => {
    let computedHosts: any = [];
    if (
      gwDeviceInfo !== undefined &&
      gwDeviceInfo?.hasOwnProperty("hosts") &&
      gwDeviceInfo?.hosts?.length !== 0
    ) {
      computedHosts = gwDeviceInfo?.hosts?.sort((a: any, b: any) => {
        return (
          sortOnlineList?.indexOf(a.active.toString()) -
          sortOnlineList?.indexOf(b.active.toString())
        );
      });
    }
    setSortedHost(computedHosts);
  }, [gwDeviceInfo]);
  const refreshData = () => {
    dispatch(
      GWDevicesClient.getGWDevices(
        selectedDevice.macAddress,
        selectedDevice.serialNumber,
        getCMW3ID4Brand(selectedSA, brandName)
      )
    );
  };

  return (
    <>
      <div className="refreshCont">
        <ReloadData refreshData={refreshData} />
      </div>
      <DiagContainer
        isLoading={gwLoading}
        data={gwDeviceInfo}
        sectionName="GW Devices"
        error={gwDeviceError}
        handleRefresh={() => refreshData()}
      >
        {gwDeviceInfo !== undefined ? (
          <div className="gwDevicesCont">
            <Card className="dataCard">
              <Card.Body>
                <table className="dataTableDetails">
                  {sortedHosts?.length !== 0 ? (
                    <>
                      <thead>
                        <th>{labels.HOSTNAME.toUpperCase()}</th>
                        <th>{labels.SIGNAL_DBM}</th>
                        <th>{labels.STATUS.toUpperCase()}</th>
                        <th>{labels.IS_ONLINE.toUpperCase()}</th>
                        <th>{labels.MAC_ADDRESS.toUpperCase()}</th>
                        <th>{labels.CONNECTION_TYPE.toUpperCase()}</th>
                      </thead>
                      <tbody>
                        {sortedHosts?.map((host: any, index: number) => {
                          return (
                            <tr key={index}>
                              <td>{isNull(host.hostName)}</td>
                              {host?.hasOwnProperty("signalStrength") ? (
                                <>
                                  <td>
                                    {isNull(
                                      getCheckValue(
                                        isEmptyObject(host.signalStrength)
                                      )
                                    )}
                                  </td>
                                  <td>
                                    <span
                                      className="statusBox"
                                      style={{
                                        color: host.signalStrength.color,
                                      }}
                                    >
                                      {isNull(host.signalStrength.status)}
                                    </span>
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td></td>
                                  <td></td>
                                </>
                              )}
                              <td>{isNull(host.active.toString())}</td>
                              <td>{isNull(host.macAddress)}</td>
                              <td>
                                {host?.hasOwnProperty("connectionType")
                                  ? isNull(host.connectionType)
                                  : isNull(host.layer1Interface)}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </>
                  ) : (
                    <div className="diagErrorCont">
                      <ExceptionBox
                        headerText="No Data to display in GW Devices."
                        bodyText="Please try refreshing or contact the Tech Insight admin if data is missing."
                        primaryBtn={true}
                        secBtn={false}
                        handleRefresh={() => refreshData()}
                      />
                    </div>
                  )}
                </table>
              </Card.Body>
            </Card>
          </div>
        ) : (
          <></>
        )}
      </DiagContainer>
    </>
  );
};

export default GWDevicesInfo;
