import { useMemo } from "react";
import {
  calculateCMUptime,
  getDeviceType,
  getTelemetryHomeAddress,
} from "../../components/reusable/NodeUtil";
import { secToTime } from "../../components/reusable/Util";
import {
  GetNodeInfoDetails,
  GetTelemetryDetails,
} from "../../get-api/GetResponse";
import { GetTelemetryProps } from "../../topology/util/reduxFunctions/getTopologyState";
const MultiDeviceNodeMapping = (nodeDevicesList: any) => {
  const ducksConfigData = GetTelemetryDetails().ducksConfigData;
  const viewType = GetTelemetryProps().viewType;
  const nodeInfo = GetNodeInfoDetails().currNodedata;
  const viewsd = ducksConfigData?.userPreferences?.node?.telemetry?.views;

  let tableColFields = viewsd?.find((view: any) => {
    return view?.attributes;
  });

  let tableData = useMemo(() => {
    const transformData = (device: any) => {
      const {
        macAddress,
        accountNumber,
        serviceAddress: rawServiceAddress,
        deviceType,
        serviceArea,
        model,
        serialNumber,
        accountSummaryResponse,
      } = device;

      const serviceAddr = getTelemetryHomeAddress(rawServiceAddress);
      const type = getDeviceType(deviceType);
      const smtId = serviceArea;
      let summary = accountSummaryResponse?.data?.[0]?.summary;
      const summaryData = accountSummaryResponse?.data?.[0] || undefined;
      let regStatus = accountSummaryResponse?.data?.[0]?.regStatus;
      const cmtsRegTime = regStatus?.cmtsCmRegisteredTime;
      const cmtsCmRegisteredTime = secToTime(cmtsRegTime);
      const cmtsUptime = secToTime(cmtsRegTime); //Based on cmtsStatus, returns blank or cmtsUptime
      const regStatusData =
        device?.regStatus ||
        summaryData?.regStatus ||
        regStatus?.regStatus?.cmtsStatus ||
        undefined;
      const serialNum = serialNumber ? serialNumber : regStatus?.serialNumber;
      const status =
        regStatusData?.cmOnlineStatus ??
        summary?.qamChannelStatus?.value ??
        regStatus?.regStatus?.cmtsStatus;
      const cmtsOnlineStat = regStatus?.regStatus?.cmtsStatus;
      const ip =
        summaryData?.cmtsIp ??
        summary?.stbIP ??
        regStatusData?.mgmtIPv6 ??
        regStatus?.mgmtIPv6;
      const cmUptime =
        regStatusData?.uptime ?? regStatus?.uptime ?? regStatus?.uptime;
      const regStatusHeader = regStatusData?.header;
      const uptime =
        cmUptime > 0
          ? calculateCMUptime(status, regStatusHeader, cmUptime)
          : undefined;
      const finalmodel = model ?? summary?.model;
      return {
        macAddress,
        accountNumber,
        serviceAddress: serviceAddr,
        serialNum,
        type,
        smtId,
        model: finalmodel,
        summary: summaryData,
        regStatus: regStatusData,
        ip,
        uptime,
        cmtsCmRegisteredTime: cmtsCmRegisteredTime,
        cmtsUptime: cmtsUptime,
        status,
      };
    };

    return nodeDevicesList?.map(transformData) ?? undefined;
  }, [nodeDevicesList, tableColFields]);

  return {
    tableData,
  };
};

export default MultiDeviceNodeMapping;
