import { ThunkAction } from "redux-thunk";
import { Action } from "redux";

import { HttpClient } from "../HttpClient";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { NetworkConfig } from "../NetworkConfig";
import { formatMacAddress } from "../../../components/reusable/Util";
import { CMSummary } from "../../../store/models/account/diagnostics/cm/CMSummary";
import { DocsisInfo } from "../../../store/models/account/diagnostics/cm/CMDocsisInfo";
import {
  DecCallCounter,
  IncrCallCounter,
  NeighboursInfoBegin,
  NeighboursInfoFailure,
  NeighboursInfoSuccess,
  SetNeighbourGeoCode,
  UpdateNeighboursDevices,
} from "../../../store/ducksPattern/account/Neighbourhood";
import storeConst from "../../../store/storeConst";

export default class NeighbourhoodClient {
  static getNeighboursInfo =
    (
      macAddress: string,
      accType: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let deviceType = "ALL_CM";
      //let deviceType = accType === "Maestro" ? "Maestro_CM" : accType === "SS" ? "SS_CM" : "";

      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL + "area/neighbours/" + macAddress,
        params: { deviceType: deviceType },
      };
      dispatch(NeighboursInfoBegin());
      HttpClient.get<any>(httpParameters)
        .then((NeighInfo) => {
          dispatch(NeighboursInfoSuccess(NeighInfo));
          return NeighInfo;
        })
        .catch((error) => {
          dispatch(NeighboursInfoFailure(error));
          return error;
        });
    };
  static getNeighCMSummary =
    (
      serialNum: string,
      macAddress: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let mac = formatMacAddress(macAddress);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "diag/cablemodem/" +
          mac +
          "/summary",
      };

      dispatch(
        IncrCallCounter({
          deviceKey: serialNum,
          subDataKey: storeConst.summary,
        })
      );

      HttpClient.get<CMSummary>(httpParameters)
        .then((cmSummary) => {
          dispatch(
            UpdateNeighboursDevices({
              deviceKey: serialNum,
              subDataKey: storeConst.summary,
              data: cmSummary,
              status: storeConst.OK,
            })
          );
          dispatch(
            DecCallCounter({
              deviceKey: serialNum,
              subDataKey: storeConst.summary,
            })
          );
          return cmSummary;
        })
        .catch((error) => {
          dispatch(
            UpdateNeighboursDevices({
              deviceKey: serialNum,
              subDataKey: storeConst.summary,
              data: error,
              status: storeConst.ERROR,
            })
          );
          dispatch(
            DecCallCounter({
              deviceKey: serialNum,
              subDataKey: storeConst.summary,
            })
          );
          return error;
        });
    };

  static getNeighCMDocsisInfo =
    (
      serialNum: string,
      macAddress: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let mac = formatMacAddress(macAddress);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "diag/cablemodem/" +
          mac +
          "/docsis",
      };

      dispatch(
        IncrCallCounter({ deviceKey: serialNum, subDataKey: storeConst.docsis })
      );

      HttpClient.get<DocsisInfo>(httpParameters)
        .then((cmDocsisInfo) => {
          dispatch(
            UpdateNeighboursDevices({
              deviceKey: serialNum,
              subDataKey: storeConst.docsis,
              data: cmDocsisInfo,
              status: storeConst.OK,
            })
          );
          dispatch(
            DecCallCounter({
              deviceKey: serialNum,
              subDataKey: storeConst.docsis,
            })
          );
          return cmDocsisInfo;
        })
        .catch((error) => {
          dispatch(
            UpdateNeighboursDevices({
              deviceKey: serialNum,
              subDataKey: storeConst.docsis,
              data: error,
              status: storeConst.ERROR,
            })
          );
          dispatch(
            DecCallCounter({
              deviceKey: serialNum,
              subDataKey: storeConst.docsis,
            })
          );
          return error;
        });
    };
  static getNeighFlaps =
    (
      serialNum: string,
      macAddress: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let mac = formatMacAddress(macAddress);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "diag/cablemodem/" +
          mac +
          "/flaps",
      };

      dispatch(
        IncrCallCounter({ deviceKey: serialNum, subDataKey: storeConst.flaps })
      );

      HttpClient.get<CMSummary>(httpParameters)
        .then((flaps) => {
          dispatch(
            UpdateNeighboursDevices({
              deviceKey: serialNum,
              subDataKey: storeConst.flaps,
              data: flaps,
              status: storeConst.OK,
            })
          );
          dispatch(
            DecCallCounter({
              deviceKey: serialNum,
              subDataKey: storeConst.flaps,
            })
          );
          return flaps;
        })
        .catch((error) => {
          dispatch(
            UpdateNeighboursDevices({
              deviceKey: serialNum,
              subDataKey: storeConst.flaps,
              data: error,
              status: storeConst.ERROR,
            })
          );
          dispatch(
            DecCallCounter({
              deviceKey: serialNum,
              subDataKey: storeConst.flaps,
            })
          );
          return error;
        });
    };
  static getNeighGeoCode =
    (
      serialNum: string,
      address: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "google/geocode",
        params: {
          address: address,
        },
      };
      HttpClient.get<DocsisInfo>(httpParameters)
        .then((geoData) => {
          dispatch(SetNeighbourGeoCode({ deviceKey: serialNum, geoData }));
          return geoData;
        })
        .catch((error) => {
          return error;
        });
    };
}
