import { Divider } from "@material-ui/core";

import "../../../design-tokens/forms/RRF.scss";
import AppLabels from "../../../constants/App_Labels";
import { changeTimeFormat } from "../../reusable/Util";
import { useEffect } from "react";

export default function ViewRRFScreen({ data }: any) {
  const formData = data?.formData;
  const labels = AppLabels() as any;
  const riserInfo = formData?.riserDetails;
  const riserInfoTitle = [
    labels.PRIORITY,
    labels.Property_Management_Contact_Number,
    labels.PHUB,
    labels.NODE,
    labels.SMT,
    labels.Impacted_Services,
    labels.Riser_Tagged_In_Panel,
    labels.Panel_Location,
    labels.Cable_Type,
    labels.TDR_Footage_From_Wallplate,
    labels.TDR_Footage_From_Panel,
    labels.OHM_Readings,
    labels.Levels_At_Tap,
    labels.Levels_At_Set,
    labels.Temp_Run,
    labels.JWO_Info,
  ];

  const customerInfoTitle = [
    labels.CUSTOMER_ADDRESS,
    labels.BUILDING_ADDRESS,
    labels.UNIT_NUMBER,
    labels.CITY,
    labels.PROVINCE,
    labels.POSTAL_CODE,
    labels.CUSTOMER_CONTACT_NUMBER,
  ];

  return (
    <div className="viewRRFContainer">
      <div>
        <label className="titleLabel">{labels.ACCOUNT_NUMBER}:</label>
        <label className="textLabel">{data?.accountNumber}</label>
      </div>
      {data?.workOrderId ? (
        <>
          <div>
            <label className="titleLabel">{labels.Work_Order_ID}:</label>
            <label className="textLabel">{data?.workOrderId}</label>
          </div>
          <div>
            <label className="titleLabel">{labels.Order_Type}:</label>
            <label className="textLabel">{formData?.orderType}</label>
          </div>
        </>
      ) : (
        <div>
          <label className="titleLabel">{labels.No_Work_Order}</label>
        </div>
      )}
      <Divider style={{ margin: "10px 0px 20px 0px" }} />

      <label className="titleLabel">{labels.Customer_Information}:</label>

      {Object.entries(formData?.customerInfo)?.map(
        ([key, value]: any, i: any) => (
          <>
            {value !== "" && value !== "Select a province" ? (
              <div>
                <label className="titleLabel">{customerInfoTitle[i]}:</label>
                <label className="textLabel" key={key}>
                  {" "}
                  {value}{" "}
                </label>
              </div>
            ) : (
              <></>
            )}
          </>
        )
      )}

      <Divider style={{ margin: "10px 0px 20px 0px" }} />
      <label className="titleLabel">{labels.TECH_INFO}</label>
      <div>
        <label className="titleLabel">{labels.TECHNICIAN_FIRM_COMPANY}:</label>
        <label className="textLabel">{formData?.techInfo?.company}</label>
      </div>
      <div>
        <label className="titleLabel">{labels.FIELD_SUPERVISOR_NAME}:</label>
        <label className="textLabel">
          {formData?.techInfo?.supervisorName}
        </label>
      </div>
      <div>
        <label className="titleLabel">
          {labels.Field_Supervisor_phone_number}:
        </label>
        <label className="textLabel">
          {formData?.techInfo?.supervisorNumber}
        </label>
      </div>
      <div>
        <label className="titleLabel">{labels.TECH_NAME}:</label>
        <label className="textLabel">{formData?.techInfo?.techName}</label>
      </div>
      <div>
        <label className="titleLabel">{labels.TECH_ID}:</label>
        <label className="textLabel">{formData?.techInfo?.techId}</label>
      </div>
      {formData?.validationDate !== undefined ? (
        <>
          <div>
            <label className="titleLabel">
              {labels.Field_Supervisor_Validation_Date}:
            </label>
            <label className="textLabel">
              {(formData?.validationDate).slice(0, 10)}
            </label>
          </div>
        </>
      ) : null}
      <div>
        <label className="titleLabel">{labels.Organization_Unit}:</label>
        <label className="textLabel">{formData?.techInfo?.selectOrgUnit}</label>
      </div>

      <Divider style={{ margin: "10px 0px 20px 0px" }} />

      <label className="titleLabel">{"Riser Referal Details"}</label>
      <div>
        {Object.entries(riserInfo)?.map(([key, value]: any, i: any) => (
          <div>
            <label className="titleLabel">{riserInfoTitle[i]}:</label>
            <label className="textLabel" key={key}>
              {" "}
              {value}{" "}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}
