import { ONTProfile } from "../../../../models/account/diagnostics/ont/ONTProfile";
import {
  ONT_Profile_BEGIN,
  ONT_Profile_SUCCESS,
  ONT_Profile_FAILURE,
  ONTProfileActionTypes,
} from "../../../../types/account/diagnostics/ont/ONTProfile";

export function ONTProfileBegin(): ONTProfileActionTypes {
  return {
    type: ONT_Profile_BEGIN,
  };
}

export function ONTProfileSuccess(ONTProfile: ONTProfile): ONTProfileActionTypes {
  return {
    type: ONT_Profile_SUCCESS,
    payload: ONTProfile,
  };
}

export function ONTProfileFailure(error: Error): ONTProfileActionTypes {
  return {
    type: ONT_Profile_FAILURE,
    payload: error,
  };
}
