import appConst from "../constants/appConst";
import languageLabels from "../constants/topologyLabels";
import AppLabels from "../../constants/App_Labels";

export default function DiagData() {
  const labels = languageLabels();
  const labels_app = AppLabels();

  return [
    {
      key: appConst.all,
      label: labels.full,
    },
    {
      key: appConst.all_no_cw,
      label: labels.fullWoCodewords,
    },
    {
      key: appConst.summary,
      label: labels.basic,
    },
  ];
}
