import { useEffect, useMemo, useRef, useState } from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { FaAngleDown, FaCheckSquare } from "react-icons/fa";
import { ImCheckboxUnchecked } from "react-icons/im";
import TopologySymbols from "./TopologySymbols";

interface Props {
  ddTitle?: string;
  options: any;
  searchFlag: boolean;
  setSelectedOptions: any;
  ddStatus?: any;
  ddContentStyle?: any;
}
const MultiSelectDD = ({
  ddTitle,
  options,
  searchFlag,
  setSelectedOptions,
  ddStatus,
  ddContentStyle,
}: Props) => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("All");
  const [optionsDisplay, setOptionsDisplay] = useState(false);
  const wrapperRef: any = useRef([]);

  const handleOptionClick = (option: any, checkVal: boolean) => {
    let selectedOpts = options;
    if (option?.key === "allOpts") {
      selectedOpts = toggelAllOptions(checkVal, selectedOpts);
    } else {
      selectedOpts = options?.map((o: any) => {
        if (o?.key === "allOpts") {
          if (!checkVal) {
            return { ...o, ...{ value: checkVal } };
          } else {
            return o;
          }
        } else if (o?.key === option?.key) {
          return { ...o, ...{ value: checkVal } };
        } else return o;
      });
    }
    setSelectedOptions(selectedOpts);
  };

  const toggelAllOptions = (allVal: boolean, options: any) => {
    if (options && options?.length !== 0) {
      return options?.map((o: any) => {
        return { ...o, ...{ value: allVal } };
      });
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    const { current: wrap } = wrapperRef;
    if (wrap && wrap?.length !== 0 && !wrap?.contains(event.target)) {
      setOptionsDisplay(false);
    }
  };

  const getDDStatus = () => {
    let checked = 0;
    let unChecked = 0;
    let status = "";
    options?.map((opt: any) => {
      if (opt?.value) checked++;
      else unChecked++;
      if (opt?.key === "allOpts") {
        if (opt?.value) status = "All";
      }
    });
    if (checked > 0 && unChecked > 0) status = "Custom";
    else if (checked === 0 && unChecked === options?.length) status = "None";

    return status;
  };

  const optionData = useMemo(() => {
    let computedOptions = options;
    if (searchValue !== "") {
      computedOptions = computedOptions.filter((option: any) => {
        return option?.label
          ?.toLowerCase()
          .includes(searchValue?.toLowerCase());
      });
    }
    let ddStatus = getDDStatus();
    setSelectedGroup(ddStatus);
    return computedOptions;
  }, [searchValue, options]);

  useEffect(() => {
    if (optionsDisplay) {
      setSearchValue("");
    }
  }, [optionsDisplay]);

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="custDDCont">
      <button className="custDDBtn" onClick={() => setOptionsDisplay(true)}>
        <span>
          {ddTitle && <b>{ddTitle}:</b>}
          {ddStatus ? ddStatus?.toUpperCase() : selectedGroup?.toUpperCase()}
        </span>
        <FaAngleDown />
      </button>
      {optionsDisplay && (
        <div ref={wrapperRef} className="custDDOptionsCont">
          {searchFlag && (
            <div className="searchContHead">
              <input
                type="text"
                className="custDDSearch"
                placeholder="Search Here.."
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <span>
                <SearchIcon />
              </span>
            </div>
          )}
          <div className="optionsList" style={ddContentStyle}>
            {optionData && optionData?.length !== 0 ? (
              optionData?.map((o: any, index: any) => {
                return (
                  <div className="custDDOption" key={index}>
                    <FormControlLabel
                      className="WHTCheck"
                      id={o?.key}
                      control={
                        <>
                          <Checkbox
                            checkedIcon={<FaCheckSquare color="#da291c" />}
                            icon={<ImCheckboxUnchecked color="#da291c" />}
                            checked={o?.value}
                            onChange={(event) =>
                              handleOptionClick(o, event.target.checked)
                            }
                          />
                          {o.iconName && (
                            <span style={{ marginLeft: 10 }}>
                              <TopologySymbols
                                name={o.iconName}
                                color={o.iconColor}
                                size={18}
                              />
                            </span>
                          )}
                        </>
                      }
                      label={o?.label ? o?.label?.toUpperCase() : ""}
                    />
                  </div>
                );
              })
            ) : (
              <div className="option">
                <span className="historyVal">No Value Found...</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiSelectDD;
