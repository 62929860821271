import { RHPDocsisEventInfo } from "../../../../models/account/diagnostics/ihp/RHPDocsisEventInfo";
export const RHP_DOCSIS_EVENT_INFO_BEGIN = "RHP_DOCSIS_EVENT_INFO_BEGIN";
export const RHP_DOCSIS_EVENT_INFO_SUCCESS = "RHP_DOCSIS_EVENT_INFO_SUCCESS";
export const RHP_DOCSIS_EVENT_INFO_FAILURE = "RHP_DOCSIS_EVENT_INFO_FAILURE";

interface RHPDocsisEventInfoBegin {
  type: typeof RHP_DOCSIS_EVENT_INFO_BEGIN;
}

interface RHPDocsisEventInfoSuccess {
  type: typeof RHP_DOCSIS_EVENT_INFO_SUCCESS;
  payload: RHPDocsisEventInfo[];
}

interface RHPDocsisEventInfoFailure {
  type: typeof RHP_DOCSIS_EVENT_INFO_FAILURE;
  payload: Error;
}

export type RHPDocsisEventInfoActionTypes =
  | RHPDocsisEventInfoBegin
  | RHPDocsisEventInfoSuccess
  | RHPDocsisEventInfoFailure;
