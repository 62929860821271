import "date-fns";
import { format, addDays } from "date-fns";
import { Form, DropdownButton, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useEffect, useState } from "react";
import { Card } from "@material-ui/core";
import ErrorBox from "../../reusable/ErrorBox";
import appLabels from "../../../constants/App_Labels";
import ChipGroup from "../../reusable/ChipGroup";
import {
  setMacAddress,
  rpdSwapReset,
} from "../../../store/ducksPattern/rpd/rpdSwap";
import { vccapUATPortConfigReset } from "../../../store/ducksPattern/rpd/vccapUATPortConfig";
import {
  formatMacAddress,
  isMacAddressValid,
  formatRPDMacAddress,
  formathexMacAddress,
} from "../../reusable/Util";
import RPDClient from "../../../network/httpclient/rpd/RPDClient";
import { TAF_ORDER_CONST } from "../../../constants/Global";
import EmailEntry from "../../reusable/EmailEntry";

const VCCAPUATPortTop = (props: any) => {
  const { oldMacAdd, techData, isLoading } = props;
  const [oldMacAd, setOldMacAd] = useState(formatMacAddress(oldMacAdd));
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("This is Error Message");
  const [seleTestType, setSeleTestType] = useState("Device List");
  const [newMacAd, setNewMacAd] = useState("");
  const [nptId, setNPTId] = useState("");
  const [ip, setIP] = useState("");
  const [hostname, setHostname] = useState("");
  const [version, setVersion] = useState("");
  const [node, setNode] = useState("");
  const [channel, setChannel] = useState("");
  const [interfaceId, setInterfaceId] = useState("");
  const [macdomain, setMacDomain] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const deviceList = ["CASA", "Harmonic"];
  const [device, setDevice] = useState("Harmonic");
  const botTypeList = ["Generation", "Execution"];
  const [botType, setBotType] = useState("Generation");
  const labels = appLabels();
  const [activeCHipIndex, setActiveCHipIndex] = useState(0);
  const searchTypeChips = [
    { title: labels.botGeneration, description: labels.botTypeGeneration },
    { title: labels.botExecution, description: labels.botTypeExecution },
  ];
  const changeActiveIndex = (data: number) => {
    setActiveCHipIndex(data);
    if (data === 1) setBotType(labels.botExecution);
    else setBotType(labels.botGeneration);

    dispatch(vccapUATPortConfigReset());
  };
  const [deviceCHipIndex, setDeviceCHipIndex] = useState(0);
  const deviceTypeChips = [
    { title: labels.devHarmonic, description: labels.devTypeHarmonic },
    { title: labels.devCasa, description: labels.devTypeCasa },
  ];
  const changeDeviceIndex = (data: number) => {
    setDeviceCHipIndex(data);
    if (data === 1) setDevice(labels.devCasa);
    else setDevice(labels.devHarmonic);

    dispatch(vccapUATPortConfigReset());
  };

  const [emails, setEmails] = useState([
    techData?.lanId !== null ? techData?.lanId : "",
  ]);
  const dispatch = useDispatch();

  const onNptIdSubmit = (e: any) => {
    dispatch(rpdSwapReset());
    setNPTId(e.target.value);
  };

  const onOldMacAdSubmit = (e: any) => {
    dispatch(rpdSwapReset());
    let sv = formatMacAddress(e.target.value);
    if (sv?.length <= 12) setOldMacAd(sv);
  };

  const onVersionSubmit = (e: any) => {
    if (e.target.value?.length <= 12) setVersion(e.target.value);
  };

  const onMacDomainSubmit = (e: any) => {
    if (e.target.value?.length <= 12) setMacDomain(e.target.value);
  };

  const onNodeSubmit = (e: any) => {
    setNode(e.target.value);
  };

  const onChannelSubmit = (e: any) => {
    setChannel(e.target.value);
  };
  const onInterfaceIdSubmit = (e: any) => {
    setInterfaceId(e.target.value);
  };

  const onHostnameSubmit = (e: any) => {
    setHostname(e.target.value);
  };
  const onIpSubmit = (e: any) => {
    setIP(e.target.value);
  };

  const createInputCasaObject = (
    oldMac: string,
    ip: string,
    channel: string,
    node: string,
    interfaceId: string,
    emails: string,
    npt: string,
    hostname: string
  ): any => {
    let inputObject = {
      MAC: oldMac,
      ip: ip,
      channel: channel,
      node: node,
      interface: interfaceId,
      "MAIL-ID": emails,
      NPT: npt,
      hostname: hostname,
    };
    return inputObject;
  };

  const createInputHarmonicObject = (
    oldMac: string,
    ip: string,
    channel: string,
    node: string,
    interfaceId: string,
    emails: string,
    npt: string,
    hostname: string
  ): any => {
    let inputObject = {
      MAC: oldMac,
      ip: ip,
      channel: channel,
      node: node,
      interface: interfaceId,
      "MAIL-ID": emails,
      NPT: npt,
      hostname: hostname,
    };
    return inputObject;
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleApplySwap = (oldMacAd: string) => {
    if (oldMacAd?.length === 12) {
      let stringMacAdd = formatMacAddress(oldMacAd);
      dispatch(setMacAddress(stringMacAdd));

      if (hostname?.length > 0) {
        if (ip?.length > 0 && ip !== "" && ip !== null) {
          if (node?.length === 6 && node !== "" && node !== null) {
            if (channel?.length > 0) {
              if (nptId?.length > 0) {
                if (interfaceId?.length > 0) {
                  if (emails[0] !== "") {
                    handleSwap(stringMacAdd);
                  } else {
                    setOpen(true);
                    setError(labels.emailEmptyError);
                  }
                } else {
                  setOpen(true);
                  setError(labels.enter_interface);
                }
              } else {
                setOpen(true);
                setError(labels.enter_nptid);
              }
            } else {
              setOpen(true);
              setError(labels.enter_valid_dwdm_channel);
            }
          } else {
            setOpen(true);
            setError(labels.enter_node_id);
          }
        } else {
          setOpen(true);
          setError(labels.enter_valid_fal_ip);
        }
      } else {
        setOpen(true);
        setError(labels.enter_valid_fal_hostname);
      }
    } else {
      setOpen(true);
      setError(labels.macIdEmptyError);
    }
  };

  const handleSwap = (oldMacAdd: string) => {
    if (oldMacAdd?.length === 12) {
      var macadd = formathexMacAddress(oldMacAdd);
      if (hostname?.length > 0) {
        if (ip?.length > 0 && ip !== "" && ip !== null) {
          if (node?.length === 6 && node !== "" && node !== null) {
            if (channel?.length > 0) {
              if (nptId?.length > 0) {
                if (interfaceId?.length > 0) {
                  if (device === "CASA") {
                    if (botType === "Execution") {
                      dispatch(
                        RPDClient.createVCCAPUATportConfig(
                          // "UAT Port Config for RPD-casa",
                          // "UAT_Port_Configuration-casa",
                          TAF_ORDER_CONST.vccapCasaUATPortBotName,
                          TAF_ORDER_CONST.vccapCasaUATPortSuiteName,
                          createInputCasaObject(
                            macadd,
                            ip,
                            channel,
                            node,
                            interfaceId,
                            emails.toString(),
                            nptId,
                            hostname
                          )
                        )
                      );
                    } else {
                      dispatch(
                        RPDClient.createVCCAPUATportConfig(
                          // "UAT Port Config for RPD-casa",
                          // "UAT_Port_Configuration-casa",
                          TAF_ORDER_CONST.vccapCasaUATPortGenerationBotName,
                          TAF_ORDER_CONST.vccapCasaUATPortSuiteName,
                          createInputCasaObject(
                            macadd,
                            ip,
                            channel,
                            node,
                            interfaceId,
                            emails.toString(),
                            nptId,
                            hostname
                          )
                        )
                      );
                    }
                  } else {
                    if (botType === "Execution") {
                      dispatch(
                        RPDClient.createVCCAPUATportConfig(
                          //"UAT Port Config for RPD-harmonic",
                          //"UAT_Port_Configuration-harmonic",
                          TAF_ORDER_CONST.vccapHarmonicUATPortBotName,
                          TAF_ORDER_CONST.vccapHarmonicUATPortSuiteName,
                          createInputHarmonicObject(
                            macadd,
                            ip,
                            channel,
                            node,
                            interfaceId,
                            emails.toString(),
                            nptId,
                            hostname
                          )
                        )
                      );
                    } else {
                      dispatch(
                        RPDClient.createVCCAPUATportConfig(
                          //"UAT Port Config for RPD-harmonic",
                          //"UAT_Port_Configuration-harmonic",
                          TAF_ORDER_CONST.vccapHarmonicUATPortGenerationBotName,
                          TAF_ORDER_CONST.vccapHarmonicUATPortSuiteName,
                          createInputHarmonicObject(
                            macadd,
                            ip,
                            channel,
                            node,
                            interfaceId,
                            emails.toString(),
                            nptId,
                            hostname
                          )
                        )
                      );
                    }
                  }
                  dispatch(setMacAddress(undefined));
                } else {
                  setOpen(true);
                  setError(labels.enter_interface);
                }
              } else {
                setOpen(true);
                setError(labels.enter_nptid);
              }
            } else {
              setOpen(true);
              setError(labels.enter_valid_dwdm_channel);
            }
          } else {
            setOpen(true);
            setError(labels.enter_node_id);
          }
        } else {
          setOpen(true);
          setError(labels.enter_valid_fal_ip);
        }
      } else {
        setOpen(true);
        setError(labels.enter_valid_fal_hostname);
      }
    } else {
      setOpen(true);
      setError(labels.macIdEmptyError);
    }
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleApplySwap(oldMacAd);
    }
  };

  const onNewMacAdSubmit = (e: any) => {
    dispatch(rpdSwapReset());
    let sv = formatMacAddress(e.target.value);
    if (sv?.length <= 12) setNewMacAd(sv);
  };

  const DropdownMenu = ({ label, data, value, onSelect }: any) => (
    <div className="inputContainer">
      <Form.Label className="rpdFormLabel">{label}</Form.Label>
      <DropdownButton
        title={value}
        onSelect={(v: any) => onSelect(v)}
        size="sm"
        id="dropdownBtn"
      >
        {data.map((e: any, i: number) => (
          <Dropdown.Item key={i} eventKey={e}>
            {e}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );

  return (
    <>
      <Card className="filterRootContainer">
        <div className="filterContainer">
          <div className="filterRow">
            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.deviceType}
                </Form.Label>
                <div className="titleChipCard">
                  <ChipGroup
                    chips={deviceTypeChips}
                    activeChipIndex={deviceCHipIndex}
                    onIndexChange={changeDeviceIndex}
                  />
                </div>
              </Form.Group>
            </div>

            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.botType}
                </Form.Label>
                <div className="titleChipCard">
                  <ChipGroup
                    chips={searchTypeChips}
                    activeChipIndex={activeCHipIndex}
                    onIndexChange={changeActiveIndex}
                  />
                </div>
              </Form.Group>
            </div>
          </div>
          <div className="filterRow">
            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.mac_address}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="rpdInputField"
                  onChange={onOldMacAdSubmit}
                  className="rpdInputField"
                  id="rpdInputField"
                  value={oldMacAd}
                  placeholder={labels.typeRpdMacAddress}
                />
              </Form.Group>
            </div>
            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.falHostname}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="rpdInputField"
                  onChange={onHostnameSubmit}
                  className="rpdInputField"
                  id="rpdInputField"
                  value={hostname}
                  placeholder={labels.type_fal_hostname}
                />
              </Form.Group>
            </div>
            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">{labels.falIp}</Form.Label>
                <Form.Control
                  disabled={false}
                  name="rpdInputField"
                  onChange={onIpSubmit}
                  className="rpdInputField"
                  id="rpdInputField"
                  value={ip}
                  onKeyPress={(e: any) => handleKeyPress(e)}
                  placeholder={labels.type_fal_ip}
                />
              </Form.Group>
            </div>
          </div>
          <div className="filterRow">
            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.NODE_ID}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="rpdInputField"
                  onChange={onNodeSubmit}
                  className="rpdInputField"
                  id="rpdInputField"
                  value={node}
                  placeholder={labels.type_node_id}
                />
              </Form.Group>
            </div>

            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.dwdm_channel}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="rpdInputField"
                  onChange={onChannelSubmit}
                  className="rpdInputField"
                  id="rpdInputField"
                  value={channel}
                  placeholder={labels.type_dwdm_channel}
                />
              </Form.Group>
            </div>
            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">{labels.nptID}</Form.Label>
                <Form.Control
                  disabled={false}
                  name="rpdInputField"
                  onChange={onNptIdSubmit}
                  className="rpdInputField"
                  id="rpdInputField"
                  value={nptId}
                  placeholder={labels.type_nptId}
                />
              </Form.Group>
            </div>
          </div>
          <div className="filterRow">
            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.interface}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="rpdInputField"
                  onChange={onInterfaceIdSubmit}
                  className="rpdInputField"
                  id="rpdInputField"
                  value={interfaceId}
                  placeholder={labels.type_interface}
                />
              </Form.Group>
            </div>
          </div>
          <div className="rpdRow">
            <EmailEntry
              title={labels.rpdEmail}
              email={emails}
              setEmail={setEmails}
              setIsEmailValid={setIsEmailValid}
              titleStyle={{
                fontSize: "12px",
                fontWeight: 500,
                margin: "0px",
                color: "#000",
              }}
              buttonStyle={{
                fontSize: "12px",
                background: "none",
                border: "none",
                padding: "0px",
                color: "#da291c",
              }}
              emailInputStyle={{
                fontSize: "12px",
                color: "#000",
                paddingLeft: "5px;",
                fontWeight: 500,
                borderRadius: "5px",
                border: "1px solid #808284",
              }}
            />
          </div>
          <div className="filterRow">
            <button
              disabled={isLoading}
              className="applyFilersBtn"
              onClick={() => handleApplySwap(oldMacAd)}
            >
              {labels.SUBMIT}
            </button>
          </div>
        </div>

        {/* <div className="filterSwapBtn">
          <button
            className="applyFilersBtn"
            onClick={() => handleApplySwap(oldMacAd)}
          >
            {labels.SUBMIT}
          </button>
        </div> */}
      </Card>
      <ErrorBox
        open={open}
        error={error}
        setOpen={setOpen}
        handleClose={handleClose}
      />
    </>
  );
};
export default VCCAPUATPortTop;
