import DataDisplayTable from "../reusable/DataDisplayTable";
import CustomAccordion from "../reusable/CustomAccordion";
import AppLabels from "../../constants/App_Labels";

const IPTVDeviceInfo = (props: any) => {
  const { deviceData } = props;
  const labels = AppLabels();
  return (
    <CustomAccordion
      accTitle={labels.DEVICE.toUpperCase()}
      accBodyElement={
        <>
          {deviceData !== undefined ? (
            <DataDisplayTable dataObject={deviceData} tableName="iptvSummary" />
          ) : (
            <></>
          )}
        </>
      }
    />
  );
};

export default IPTVDeviceInfo;
