import { GponNodeState } from "../../states/gpon/GponNodeInfo";
import {
  GPON_NODE_LIST_BEGIN,
  GPON_NODE_LIST_SUCCESS,
  GPON_NODE_LIST_FAILURE,
  RESET_GPON_NODES_INFO,
  GponNodesInfoActionTypes,
} from "../../types/gpon/GponNodeInfo";
import { changeTimeFormat } from "../../../components/reusable/Util";

const initialState: GponNodeState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
  diagStartTime: undefined,
  diagEndTime: undefined,
};

export function GponNodeReducer(
  state = initialState,
  action: GponNodesInfoActionTypes
): GponNodeState {
  switch (action.type) {
    case GPON_NODE_LIST_BEGIN:
      let startTime: any = changeTimeFormat(Date.now());
      return {
        isLoading: true,
        data: undefined,
        error: undefined,
        status: "",
        diagStartTime: startTime,
      };
    case GPON_NODE_LIST_SUCCESS:
      let endTime: any = changeTimeFormat(Date.now());
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
        diagEndTime: endTime,
      };
    case GPON_NODE_LIST_FAILURE:
      let et: any = changeTimeFormat(Date.now());
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
        diagEndTime: et,
      };
    case RESET_GPON_NODES_INFO:
      return initialState;

    default:
      return state;
  }
}
