import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import {ontSummaryBegin,
  ontSummarySuccess,
  ontSummaryFailure,  } from  "../../../store/slices/gpon/ontSummary";
import { ONTSummary } from "../../../store/models/account/diagnostics/ont/ONTSummary";
import { ONTAlarmsState } from "../../../store/states/account/diagnostics/ont/ONTAlarmsState";
import { ONTProfileState } from "../../../store/states/account/diagnostics/ont/ONTProfileState";
import { ONTIPState } from "../../../store/states/account/diagnostics/ont/ONTIPState";
import { OLTSummary } from "../../../store/models/account/diagnostics/ont/OLTSummary";
import {
  ONTAlarmsBegin,
  ONTAlarmsFailure,
  ONTAlarmsSuccess,
} from "../../../store/actions/account/diagnostics/ont/ONTAlarmsAction";
import {
  ONTProfileBegin,
  ONTProfileFailure,
  ONTProfileSuccess,
} from "../../../store/actions/account/diagnostics/ont/ONTProfileAction";
import {
  ONTIPBegin,
  ONTIPFailure,
  ONTIPSuccess,
} from "../../../store/actions/account/diagnostics/ont/ONTIPAction";
import { ONTAlarm } from "../../../store/models/account/diagnostics/ont/ONTAlarm";
import { ONTIP } from "../../../store/models/account/diagnostics/ont/ONTIP";
import { ONTProfile } from "../../../store/models/account/diagnostics/ont/ONTProfile";
import { ONTBandwidthState } from "../../../store/states/account/diagnostics/ont/ONTBandwidthState";
import {
  ONTBandwidthBegin,
  ONTBandwidthFailure,
  ONTBandwidthSuccess,
} from "../../../store/actions/account/diagnostics/ont/ONTBandwidthAction";
import { ONTBandwidth } from "../../../store/models/account/diagnostics/ont/ONTBandwidth";
import {
  DecrementApiCallCounter,
  IncrementApiCallCounter,
  UpdateActiveCertiDevices,
} from "../../../store/actions/account/certifications/ActiveCertiDevicesAction";
import {
  DecrementDiagApiCallCounter,
  IncrementDiagApiCallCounter,
  UpdateActiveDiagDevices,
} from "../../../store/actions/account/diagnostics/ActiveDiagDevicesActions";
import {
  oltSummaryBegin,
  oltSummarySuccess,
  oltSummaryFailure,
} from "../../../store/slices/gpon/oltSummaryDetails";

export default class ONTClient {
  static getONTSummary =
    (serialNum: string): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "diag/ont/" +
          serialNum +
          "/summary",
      };

      dispatch(IncrementDiagApiCallCounter(serialNum));
      HttpClient.get<ONTSummary>(httpParameters)
        .then((ONTSummary) => {
          dispatch(UpdateActiveDiagDevices(serialNum, "summary", ONTSummary));
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return ONTSummary;
        })
        .catch((error) => {
          dispatch(UpdateActiveDiagDevices(serialNum, "summary", error));
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return error;
        });
    };
    static getONTSummaryWithoutIncrement =
    (serialNum: string): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "diag/ont/" +
          serialNum +
          "/summary",
      };
      dispatch(ontSummaryBegin());
      HttpClient.get<ONTSummary>(httpParameters)
        .then((ONTSummary) => {
          dispatch(ontSummarySuccess( ONTSummary));
        
          return ONTSummary;
        })
        .catch((error) => {
          dispatch(ontSummaryFailure(error));
         
          return error;
        });
    };
  static getONTAlarms =
    (
      serialNum: string
    ): ThunkAction<void, ONTAlarmsState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/ont/alarms",
        params: {
          serialNumber: serialNum,
        },
      };

      dispatch(ONTAlarmsBegin());

      HttpClient.get<ONTAlarm>(httpParameters)
        .then((ONTAlarms) => {
          dispatch(ONTAlarmsSuccess(ONTAlarms));
          return ONTAlarms;
        })
        .catch((error) => {
          dispatch(ONTAlarmsFailure(error));
          return error;
        });
    };
  static getONTProfile =
    (
      serialNum: string
    ): ThunkAction<void, ONTProfileState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/ont/profile",
        params: {
          serialNumber: serialNum,
        },
      };

      dispatch(ONTProfileBegin());

      HttpClient.get<ONTProfile>(httpParameters)
        .then((ONTProfile) => {
          dispatch(ONTProfileSuccess(ONTProfile));
          return ONTProfile;
        })
        .catch((error) => {
          dispatch(ONTProfileFailure(error));
          return error;
        });
    };
  static getONTIpSessions =
    (
      serialNum: string
    ): ThunkAction<void, ONTIPState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/ont/ip",
        params: {
          serialNumber: serialNum,
        },
      };

      dispatch(ONTIPBegin());

      HttpClient.get<ONTIP>(httpParameters)
        .then((ONTIP) => {
          dispatch(ONTIPSuccess(ONTIP));
          return ONTIP;
        })
        .catch((error) => {
          dispatch(ONTIPFailure(error));
          return error;
        });
    };
  static getONTBandwidth =
    (
      serialNum: string
    ): ThunkAction<void, ONTBandwidthState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "diag/ont/" +
          serialNum +
          "/bandwidth",
      };

      dispatch(ONTBandwidthBegin());

      HttpClient.get<ONTBandwidth>(httpParameters)
        .then((ONTBandwidth) => {
          dispatch(ONTBandwidthSuccess(ONTBandwidth));
          return ONTBandwidth;
        })
        .catch((error) => {
          dispatch(ONTBandwidthFailure(error));
          return error;
        });
    };
  static getONTCertiSummary =
    (serialNum: string): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "diag/ont/" +
          serialNum +
          "/summary",
      };

      dispatch(IncrementApiCallCounter(serialNum));

      HttpClient.get<ONTSummary>(httpParameters)
        .then((ONTSummary) => {
          dispatch(UpdateActiveCertiDevices(serialNum, "summary", ONTSummary));
          dispatch(DecrementApiCallCounter(serialNum));
          return ONTSummary;
        })
        .catch((error) => {
          dispatch(UpdateActiveCertiDevices(serialNum, "summary", error));
          dispatch(DecrementApiCallCounter(serialNum));
          return error;
        });
    };

  static getOLTSummary =
    (serialNum: string): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.NETWORK_SERVICE_URL +
          "diag/ont/" +
          serialNum +
          "/statesSummary",
      };

      dispatch(oltSummaryBegin());
      HttpClient.get<OLTSummary>(httpParameters)
        .then((OLTSummary) => {
          dispatch(oltSummarySuccess(OLTSummary));
          return OLTSummary;
        })
        .catch((error) => {
          dispatch(oltSummaryFailure(error));
          return error;
        });
    };
}
