import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

import InlineErrorMessage from "../../../components/reusable/InlineErrorMessage";

interface props {
  isShown: any;
  onRightClick?: any;
  rightBtnText: string;
  leftBtnText?: string;
  onLeftClick?: any;
  inputTxt?: any;
  setInputTxt?: any;
  headerText?: any;
  placeholderTxt?: any;
  errorMessage?: any;
}

const AddMessageModal = ({
  isShown,
  onRightClick,
  rightBtnText,
  leftBtnText,
  onLeftClick,
  inputTxt = "",
  setInputTxt,
  headerText,
  placeholderTxt,
  errorMessage,
}: props) => {
  const [errorMsg, setErrorMsg] = useState("");

  const handleLeftBtnClick = () => {
    setErrorMsg("");
    onLeftClick(false);
  };

  const handleRightBtnClick = () => {
    if (inputTxt.length > 0) {
      setErrorMsg("");
      onRightClick(inputTxt);
    } else {
      setErrorMsg(errorMessage);
    }
  };

  return (
    <>
      <Modal
        style={{ position: "absolute" }}
        show={isShown}
        size="lg"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header className="searchPopHead">
          {headerText}
          <Button bsPrefix="close" onClick={handleLeftBtnClick}>
            &#10005;
          </Button>
        </Modal.Header>
        <Modal.Body>
          {errorMsg !== "" && (
            <div className="addCommentErrorMsg">
              <InlineErrorMessage errorMessage={errorMsg} />
            </div>
          )}

          <div className="popUpCont">
            <div className="selectDrawing">
              <Form.Group className="selector">
                <Form.Control
                  name="description"
                  onChange={(e) => setInputTxt(e.target.value)}
                  as="textarea"
                  rows={5}
                  className="description"
                  id="description"
                  maxLength={1000}
                  placeholder={placeholderTxt}
                  value={inputTxt}
                />
              </Form.Group>
            </div>
            <div className="popUpSubmitCancelButton">
              <button
                className="popUpCancelButton"
                style={{ marginRight: "20px" }}
                onClick={handleLeftBtnClick}
              >
                {leftBtnText}
              </button>
              <button
                className="popUpSubmitButton"
                onClick={handleRightBtnClick}
              >
                {rightBtnText}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddMessageModal;
