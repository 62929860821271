import { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Card } from "@material-ui/core";
import { Dropdown, DropdownButton, Form } from "react-bootstrap";

import ErrorBox from "../../reusable/ErrorBox";
import AppLabels from "../../../constants/App_Labels";
import RPDClient from "../../../network/httpclient/rpd/RPDClient";
import {
  setNPTId,
  nptSummaryMacReset,
} from "../../../store/ducksPattern/rpd/nptSummaryMac";
import { rpdInfoMacReset } from "../../../store/ducksPattern/rpd/rpdMac";
import { rpdSWUpgradeMacReset } from "../../../store/ducksPattern/rpd/rpdSWUpgradeMac";
import {
  setSwupgNPTId,
  setMacAdd,
  setMail,
  setDeviceType,
} from "../../../store/ducksPattern/rpd/rpdSWUpgradeMac";
import { TAF_ORDER_CONST } from "../../../constants/Global";
import EmailEntry from "../../reusable/EmailEntry";
import {
  formatMacAddress,
  isMacAddressValid,
  formatRPDMacAddress,
  isValidNodeId,
  formatNodeId,
  formatNPTNCTIMT,
  isRPDMacAddressValid,
} from "../../../components/reusable/Util";

const RPDSWUpgradeMacTop = (props: any) => {
  const { isLoading, techData, isMacLoading } = props;
  const [isNPT, setIsNPT] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [nptID, setNptID] = useState("");
  const [macaddress, setMacAddress] = useState("");
  const [emails, setEmails] = useState([
    techData?.lanId !== null ? techData?.lanId : "",
  ]);
  const labels = AppLabels();
  const dispatch = useDispatch();
  const deviceList = ["DA2000", "DA2200"];
  const [device, setDevice] = useState("DA2000");

  const createInputActivationObject = (nptID: string): any => {
    let inputObject = {
      NPT_ID: nptID,
    };
    return inputObject;
  };

  const createInputMacAdObject = (macAddress: string): any => {
    let inputObject = {
      rpdmacAddress: macAddress,
    };
    return inputObject;
  };

  useEffect(() => {
    if (isEmailValid) {
      dispatch(setMail(emails.toString()));
    }
  }, [isEmailValid]);

  const setEmailsdata = (data: any) => {
    setEmails(data);
    dispatch(setMail(data.toString()));
  };

  const handleSearch = (nptID: string) => {
    if (nptID?.length > 0 && nptID?.toLowerCase()?.startsWith("npt")) {
      dispatch(rpdInfoMacReset());
      dispatch(setNPTId(nptID));
      dispatch(setSwupgNPTId(nptID));
      dispatch(setMail(emails.toString()));
      dispatch(setDeviceType(device));
      dispatch(
        RPDClient.getNPTSummarySwUpg(
          TAF_ORDER_CONST.rpdNPTSummaryBotName,
          TAF_ORDER_CONST.rpdNPTSummarySuiteName,
          createInputActivationObject(nptID)
        )
      );
    } else if (nptID?.length > 0 && isRPDMacAddressValid(macaddress)) {
      let stringMacAdd = formatRPDMacAddress(macaddress);
      dispatch(nptSummaryMacReset());
      dispatch(setSwupgNPTId(nptID));
      dispatch(setMacAdd(stringMacAdd));
      dispatch(setMail(emails.toString()));
      dispatch(setDeviceType(device));
      dispatch(
        RPDClient.getRPDDetailsOnMacAdd(
          TAF_ORDER_CONST.rpdSearchbyMacAddressBotName,
          TAF_ORDER_CONST.rpdSearchbyMacAddressSuiteName,
          createInputMacAdObject(stringMacAdd)
        )
      );
    } else {
      setOpen(true);
      if (nptID?.length === 0) setError(labels.nptIdImtIdNctIDEmptyError);
      else if (macaddress?.length !== 12) setError(labels.macIdEmptyError);
    }
  };

  const onnptIdSubmit = (e: any) => {
    let sv = formatNPTNCTIMT(e.target.value);
    setNptID(sv);
    dispatch(rpdInfoMacReset());
    dispatch(nptSummaryMacReset());
    dispatch(rpdSWUpgradeMacReset());
    if (sv?.toLowerCase().startsWith("npt")) setIsNPT(true);
    else setIsNPT(false);
  };
  const onMacAddressSubmit = (e: any) => {
    dispatch(rpdInfoMacReset());
    dispatch(nptSummaryMacReset());
    dispatch(rpdSWUpgradeMacReset());
    let sv = formatMacAddress(e.target.value);
    if (sv?.length <= 12) setMacAddress(sv);
  };
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("This is Error Message");
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(rpdInfoMacReset());
    dispatch(nptSummaryMacReset());
    dispatch(rpdSWUpgradeMacReset());
    dispatch(setDeviceType(device));
  }, [device]);

  const DropdownMenu = ({ label, data, value, onSelect }: any) => (
    <div className="deviceTypeCont">
      <Form.Label className="rpdFormLabel">{label}</Form.Label>
      <DropdownButton
        title={value}
        onSelect={(v: any) => onSelect(v)}
        size="sm"
        className="deviceTypeDropd"
        id="deviceTypeDd"
      >
        {data.map((e: any, i: number) => (
          <Dropdown.Item key={i} eventKey={e}>
            {e}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );

  return (
    <>
      <Card className="filterRootContainer">
        <div className="filterContainer">
          <div className="filterRow">
            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.nptIdImtIdNctID}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="rpdInputField"
                  onChange={onnptIdSubmit}
                  className="rpdInputField"
                  id="rpdInputField"
                  value={nptID}
                  placeholder={labels.typeNptIMTNCTID}
                />
              </Form.Group>
            </div>
            {!isNPT ? (
              <div className="filterRow">
                <div className="inputContainer">
                  <Form.Group>
                    <Form.Label className="rpdFormLabel">
                      {labels.rpdMacAddress}
                    </Form.Label>
                    <Form.Control
                      disabled={false}
                      name="rpdInputField"
                      onChange={onMacAddressSubmit}
                      className="rpdInputField"
                      id="rpdInputField"
                      value={macaddress}
                      placeholder={labels.typeRpdMacAddress}
                    />
                  </Form.Group>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="filterRow">
            <EmailEntry
              title={labels.rpdEmail}
              email={emails}
              setEmail={setEmailsdata}
              setIsEmailValid={setIsEmailValid}
              titleStyle={{
                fontSize: "12px",
                fontWeight: 500,
                margin: "0px",
                color: "#000",
              }}
              buttonStyle={{
                fontSize: "12px",
                background: "none",
                border: "none",
                padding: "0px",
                color: "#da291c",
              }}
              emailInputStyle={{
                fontSize: "12px",
                color: "#000",
                paddingLeft: "5px",
                fontWeight: 500,
                borderRadius: "5px",
                border: "1px solid #808284",
              }}
            />
          </div>
          <div className="filterRow">
            <button
              disabled={isLoading || isMacLoading}
              className="applyFilersBtn"
              onClick={() => handleSearch(nptID)}
            >
              {labels.fetchDetails}
            </button>
          </div>
        </div>
      </Card>
      <ErrorBox
        open={open}
        error={error}
        setOpen={setOpen}
        handleClose={handleClose}
      />
      <div></div>
    </>
  );
};
export default RPDSWUpgradeMacTop;
