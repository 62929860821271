import React from "react";
import { Paper } from "@material-ui/core";
import ExceptionBox from "../reusable/ExceptionBox";

import RefreshGif from "../../resources/images/icons/refresh.gif";
import ActivityIndicator from "../reusable/ActivityIndicator";

const CMTSRealTimeinfo = (props: any) => {
  
  const { cmtsNodeDetailsData, cmtsNodeDetailsLoading } = props;
  const RenderTable = (props: any): JSX.Element => {
    const { data, title } = props;
    return (
      <div className="cmtsTableLayout">
        <div className="cmtsTableTitle">{title}</div>
        <table className="cmtsTable">
          <tbody>
            {data.map((item: any, index: number) => {
              const isBoldLabel =
                item.label === "US Post CER%" ||
                item.label === "US Channel SNR";
              return (
                <tr key={index}>
                  <td className={isBoldLabel ? "bold-label-cell" : ""}>
                    {isBoldLabel ? (
                      <strong>{item.label}</strong>
                    ) : (
                      item.label
                    )}
                  </td>
                  <td>
                    {isBoldLabel ? (
                      <strong>{item.value}</strong>
                    ) : (
                      item.value
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  if (
    cmtsNodeDetailsLoading &&
    (cmtsNodeDetailsData === undefined || cmtsNodeDetailsData === null)
  ) {
    return <ActivityIndicator />;
  } else if (
    !cmtsNodeDetailsLoading &&
    (cmtsNodeDetailsData === undefined || cmtsNodeDetailsData === null)
  ) {
    return (
      <div className="diagErrorCont">
        <ExceptionBox
          headerText="CMTS FOR THIS NODE IS NOT AVAILABLE"
          bodyText="Once you search a node it will reflect here."
          primaryBtn={false}
          secBtn={false}
        />
      </div>
    );
  }

  return (
    <>
      {cmtsNodeDetailsData !== undefined && cmtsNodeDetailsData !== null && (
        <>
          <Paper elevation={3} style={{ height: "100%", padding: "10px" }}>
            <div className="cmtsSectionTitle">
              CMTS REAL TIME INFO
              <img
                style={{
                  height: "23px",
                  marginLeft: "20px",
                  marginRight: "20px",
                  visibility: cmtsNodeDetailsLoading ? "visible" : "hidden",
                }}
                src={RefreshGif}
                alt="refresh"
              />
              <div className="cmtsCounter">{props?.counterTime}</div>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              {cmtsNodeDetailsData?.usChannels?.length > 0 && (
                <div className="cmtsTableContainer">
                  {cmtsNodeDetailsData?.usChannels?.map(
                    (item: any, index: number) => {
                      if (item.type === "docsOfdmaUpstream") {
                        return (
                          <RenderTable
                            key={index + "docsOfdmaUpstream"}
                            title={item.name}
                            data={[
                              {
                                label: "US Frequency",
                                value: item.frequency,
                              },
                              {
                                label: "US Channel Active CM's",
                                value: item.numActiveModems,
                              },
                              {
                                label: "US Channel SNR",
                                value: item.snr,
                              },
                              {
                                label: "US Pre CCER%",
                                value: item.preCcer,
                              },
                              {
                                label: "US Post CER%",
                                value: item.postCer,
                              },
                              {
                                label: "Upstream Channel Util%",
                                value: item.utilization,
                              },
                            ]}
                          />
                        );
                      } else {
                        return null;
                      }
                    }
                  )}
                </div>
              )}
              {cmtsNodeDetailsData?.usChannels?.length > 0 ? (
                <div className="cmtsTableContainer">
                  <>
                    {cmtsNodeDetailsData?.usChannels?.map(
                      (item: any, index: number) => {
                        if (item.type === "docsCableUpstreamChannel") {
                          return (
                            <RenderTable
                              key={index + "docsCableUpstreamChannel"}
                              title={item.name}
                              data={[
                                {
                                  label: "US Frequency",
                                  value: item.frequency,
                                },
                                {
                                  label: "US Channel Active CM's",
                                  value: item.numActiveModems,
                                },
                                {
                                  label: "US Channel SNR",
                                  value: item.snr,
                                },
                                {
                                  label: "US Pre CCER%",
                                  value: item.preCcer,
                                },
                                {
                                  label: "US Post CER%",
                                  value: item.postCer,
                                },
                                {
                                  label: "Upstream Channel Util%",
                                  value: item.utilization,
                                },
                              ]}
                            />
                          );
                        } else {
                          return null;
                        }
                      }
                    )}
                  </>
                </div>
              ) : (
                <div className="diagErrorCont">
                  <ExceptionBox
                    headerText="CMTS FOR THIS NODE IS NOT AVAILABLE"
                    bodyText="Once you search a node it will reflect here."
                    primaryBtn={false}
                    secBtn={false}
                  />
                </div>
              )}
            </div>
          </Paper>
        </>
      )}
    </>
  );
};
export default CMTSRealTimeinfo;
