import { ILatLng } from "../../util/types";

export const ACCOUNT_SERVICE_URL = (window as any).config.ACCOUNT_SERVICE_URL;
export const NETWORK_SERVICE_URL = (window as any).config.NETWORK_SERVICE_URL;
export const TOPOLOGY_SERVICE_URL = (window as any).config.TOPOLOGY_SERVICE_URL;
export const SYMBOLS_SERVICE_URL = (window as any).config.SYMBOLS_SERVICE_URL;

//API URIs
export const TECH_PROFILE_URI = "technicians/profile";
export const GOOGLE_KEY_URI = "google/key";
export const NODE_BOUNDARIES_URI = "node/boundaries";
export const NODE_URI = "node/";
export const CLAMSHELLID_URI = "node/clamshell";
export const NODE_TAP_URI = "passive/tap";
export const NODE_TAP_ADDRESS_URI = `/address`;
export const NODE_FIBER_CABLE_URI = "cable/fiber";
export const NODE_RF_CABLE_URI = "cable/rf";
export const NODE_FIBER_SPLICE_CASE = "passive/splicecase";
export const NODE_SPLITTER_URI = "passive/splitter";
export const NODE_POWER_BLOCK_URI = "passive/powerblock";
export const NODE_TERMINATOR_URI = "passive/terminator";
export const NODE_POWER_SUPPLY_URI = "powersupply";
export const NODE_CABLE_RF_DROP_URI = "cable/rfdrop";
export const NODE_ADDRESS_NO_URI = "cable/rfdropaddress";
export const NODE_MDU_URI = "mdu";
export const NODE_SUPPORT_STRUCTURE_URI = "supportstructure";
export const NODE_RF_SPLICE_URI = "passive/rfsplice";
export const NODE_ISP_SITE_URI = "passive/ispSite";
export const NODE_ACTIVE_RF_EQUIPMENTS_URI = "active";
export const NIKA_ORDERS = "nika/orders";
export const NODE_ANNOTATION_BLOCK_URI = "annotationblock";
export const NODE_FIBER_STORAGE_AND_RISER = "fiberequipment/storageAndRiser";
export const NODE_FIBER_ADDRESS_CONNECTION_POINT =
  "fiberequipment/addrConnectionPoint";
export const NODE_POWER_INSERTER = "passive/powerinserter";
export const NODE_MODEM_LIST = "diag/network/modemList";
export const NODE_BASIC_MODEM_LIST = "diag/network/listOfModem";
export const ADDRESS_MODEM_LIST = "diag/network/listByAddress";
export const GOOGLE_GEOCODE_URL = "google/geocode";
export const TELE_ALL_STREAM_DIAG = "diag/cablemodem/all/flux";
export const TELE_MULTIDEVICE_STREAM_DIAG = "diag/network/multiAddress/flux";
export const TELE_SUMMARY_STREAM_DIAG = "diag/cablemodem/summary/flux";
export const TELE_SUMMARY_BASIC_MULTI_NODE =
  "diag/cablemodem/summary/flux/devices";
export const TELE_STB_SUMMARY_STREAM_DIAG = "diag/stb/summary/flux";
export const CPAT_EVENTS = "cpat/events";
export const MDU_POLYLINES = "mdu/polyline";
export const MDU_ACTIVE = "mdu/active";
export const MDU_PASSIVE = "mdu/passive";
export const MDU_FIBER_EQUIPMENT = "mdu/fiberequipment";
export const MICRO_REFLECTIONS_GROUPED_ISSUES =
  "micro-reflection/grouped-issues/";
export const ADDRESS_BY_SAM_KEYS = "oal/AddresBySamkey?samkeys=";
export const MDU_CAD_TEXT = "mdu/cadtext";
export const MDU_FIBER_CABLE = "mdu/fibrecable";
export const MDU_RF_CABLE = "mdu/rfcable";
export const MDU_POWER_SUPPLY = "mdu/powersupply";
export const MDU_TAP = "mdu/tap";
export const ADDRESS_URI = "/address";
export const MDU_ANNOTATION_BLOCK = "mdu/annotationblock";
export const SYMBOLS_STREAM = "symbols";
export const MDU_SUPPORT_STRUCTURE = "mdu/supportstructure";
export const MDU_RASTER = "mdu/raster";
export const MDU_RASTER_IMAGES = "mduraster";
export const NODE_SITE_COMPONENTS = "node/sitecomponents/";
export const CONFIG_URI = "config";
export const CONFIG_TOPOLOGY_URI = "config/topology";
export const RENS_SEARCH_URI = "element/location/rens/";
export const WIRELESS_SITE_SEARCH_URI = "element/location/wirelesssite/";
export const NETWORKSITE_SEARCH_URI = "element/location/networksite/";
export const RB_CIRCUIT_SEARCH_URI = "element/location/rbcircuit/";
export const CLICK_TASK_URI = "click/task";
export const NODE_COMMENT_URI = "nodecomment";
export const MDU_CAD_ATTRIBUTE = "mdu/cadattribute";
export const MDU_CAD_CIRCLE = "mdu/cadcircle";
export const MDU_CAD_SYMBOL = "mdu/cadsymbol";
export const MDU_CAD_HATCH = "mdu/cadhatch";
export const MEMO_URI = "memocomment";
export const MEMO_AREA_URI = "memocomment/area";
export const OLT_URI = "olt/";
export const GPON_SPLICE_MAP_URI = "passive/gponsplicemap/";
export const GPON_SPLITTERS_URI = (lcpId: string) =>
  "passive/lcp/" + lcpId + "/splitters";
export const GPON_USAGES_URI = (lcpId: string) =>
  "passive/lcp/" + lcpId + "/usages";
export const POWERSUPPLY_POWERTRACE = (rensNumber: String) =>
  `powersupply/powertrace/${rensNumber}/elements`;
export const POWERSUPPLY_POWERTRACE_ADDRESSES = `powersupply/powertrace/addresses`;
export const OLT_CARDS_URI = (id: string) => "olt/rack/" + id + "/ispelement";
export const OPTICAL_TAP_URI = "passive/opticaltap";
export const LCP_URI = "passive/lcp";
export const UTILITY_TOOL_URI = "diag/util/simulator";
export const FILE_ATTACHMENT_URI = "networkelement";
export const GET_COMMENTS_URI = (networkId: String) =>
  `${NODE_COMMENT_URI}/${networkId}/comments`;
export const CREATE_COMMENT_URI = (networkId: String) =>
  `${NODE_COMMENT_URI}/${networkId}/comment`;
export const UPDATE_DELETE_COMMENT_URI = (
  networkId: String,
  commentId: String
) => `${NODE_COMMENT_URI}/${networkId}/comment/${commentId}`;
export const CREATE_MEMO_URI = (networkId: String) =>
  `${MEMO_URI}/${networkId}/comment`;
export const UPDATE_DELETE_MEMO_URI = (networkId: String, commentId: String) =>
  `${MEMO_URI}/${networkId}/comment/${commentId}`;
export const RF_DOWNSTREAM_TRACE_API = (networkId: String) =>
  `trace/rf/${networkId}/downstream`;
export const RF_UPSTREAM_TRACE_API = (networkId: String) =>
  `trace/rf/${networkId}/upstream`;
export const IP_PING = (ipAddress: String, numberOfPing: String) =>
  `/ip/${ipAddress}/${numberOfPing}`;
export const TRACEROUTE = (ipAddress: String) => `/TraceRoute/${ipAddress}`;
export const VHUB_SEARCH_URI = "vhub/";
export const CREATE_REDLINE_URI = "redline/projects";
export const GET_REDLINES_URI = "redline/projects/list";
export const REDLINE_URI = (trackerKey: String) =>
  `redline/projects/${trackerKey}`;
export const FIBER_UPSTREAM_TRACE_API = (networkId: String) =>
  `trace/fiber/${networkId}/upstream`;
export const MultiDeviceMAC = "/deviceList";
export const MultiDeviceONT = "/deviceListByONTSN";
export const MultiDeviceAcc = "/deviceListByAccountNumber"; //deviceListByAccountNumber
export const REDLINE_CONFIGS_URI = "redline/configs";
export const POLYGON_ID_URI = ({ lat, lng }: ILatLng) =>
  `node/polygons?lng=${lng}&lat=${lat}`;
export const REDLINE_ATTACHMENTS_URI = (trackerKey: string) =>
  `redline/projects/${trackerKey}/attachments`;
