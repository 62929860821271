import { Divider } from "@material-ui/core";
import React from "react";

import AppLabels from "../../../constants/App_Labels";
import colors from "../../../topology/config/colors";

interface Props {
  approbation: boolean;
  setApprobation: any;
}

export default function RenderRadioButtons(props: Props) {
  const labels = AppLabels();

  return (
    <>
      <label className="titleLabel" style={{ color: colors.danger }}>
        {labels.DrillingWarningMessage}
      </label>

      <label className="titleLabel">
        {
          labels.I_am_the_owner_or_superintendent_and_I_agree_to_the_work_as_described_above
        }
        .*
      </label>
      <div
        className="radioBtnContainer"
        onClick={() => {
          props.setApprobation(true);
        }}
      >
        <input
          type="radio"
          checked={props.approbation === true}
          readOnly
          style={{
            width: "20px",
            height: "20px",
          }}
        />
        <label
          style={
            props.approbation === true
              ? { color: `var(--primary)`, fontWeight: 600 }
              : { color: `var(--pureBlack)` }
          }
        >
          {labels.Approve}
        </label>
      </div>
      <div
        className="radioBtnContainer"
        onClick={() => {
          props.setApprobation(false);
        }}
      >
        <input
          type="radio"
          readOnly
          checked={props.approbation === false}
          style={{
            width: "20px",
            height: "20px",
          }}
        />
        <label
          style={
            props.approbation === false
              ? { color: `var(--primary)`, fontWeight: 600 }
              : { color: `var(--pureBlack)` }
          }
        >
          {labels.Do_Not_Approve}
        </label>
      </div>
      <Divider style={{ margin: "20px 0px 20px 0px" }} />
    </>
  );
}
