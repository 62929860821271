import { BsCheckCircle } from "react-icons/bs";
import { IoAlertCircle } from "react-icons/io5";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import DisplayIcon from "../reusable/DeviceMenuCollapse";
import AppLabels from "../../constants/App_Labels";
import { isNull } from "../reusable/Util";

const PodsListView = (props: any) => {
  const {
    tabType,
    index,
    label,
    handleSelect,
    value,
    podsList,
    handlePODClick,
    selPodIndex,
  } = props;
  const labels = AppLabels();
  let isSel = "";
  let podState = "";
  if (index === value) isSel = "selectedDeviceTab";
  const renderPodsStatus = (podStatus: string) => {
    if(podStatus!=="N/A" && podStatus!==""){
    let rssi_val=parseInt(podStatus);
    if (rssi_val  <=-55 &&  rssi_val>=-70 )
      return <BsCheckCircle style={{ fontSize: "large" }} color="#2DC071" />;
    else return <IoAlertCircle style={{ fontSize: "large" }} color="#BF371F" />;}
    else return "";
  };

  if (tabType === "vertical-Toggle") {
    return (
      <div
        role="tab"
        className={`custom-${isSel}-Tab`}
        id={`vertical-tab-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
      >
        <OverlayTrigger
          key={index}
          placement="right"
          overlay={
            <Tooltip id={`tooltip-${index}`}>
              <span>{label}</span>
              <br />
              <span>
                {labels.TOTAL_PODS.toUpperCase()}:{podsList?.length}
              </span>
            </Tooltip>
          }
        >
          <button
            onClick={() => handleSelect(index)}
            className="verticalDeviceTab"
          >
            <div className="leftTabCont">
              <div className="deviceIcons">
                <DisplayIcon devicetype={label} />
              </div>
            </div>
          </button>
        </OverlayTrigger>
      </div>
    );
  }

  return (
    <>
      <div
        role="tab"
        className={`custom-${isSel}-Tab`}
        id={`vertical-tab-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
      >
        <button
          onClick={() => handleSelect(index)}
          className="verticalDeviceTab"
        >
          <div className="leftTabCont">
            <div className="deviceIcons">
              <DisplayIcon devicetype={label} />
            </div>
            <div>
              <span className="tabLabel">{label}</span>
              <p className="tabSubLabel">
                {labels.TOTAL_PODS.toUpperCase()}:{podsList?.length}
              </p>
            </div>
          </div>
        </button>
      </div>
      {index === value ? (
        <div
          id={`vertical-tab-action-${index}`}
          className="diagActionsListCont"
        >
          <ul>
            {podsList?.length !== 0 ? (
              podsList?.map((pod: any, index: number) => {
                if (index === selPodIndex) podState = "selected";
                else podState = "";
                return (
                  <li key={index} className={`device-${podState}-Pod`}>
                    <button
                      onClick={() => handlePODClick(index, pod.serialNumber)}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{pod.deviceFriendlyName}</span>
                      {isNull(pod?.hasOwnProperty("statistics")) &&
                        isNull(pod.statistics?.rssi) && pod.statistics.hasOwnProperty("rssi")   ? (
                        renderPodsStatus(isNull(pod.statistics.rssi))
                      ) : (
                        <></>
                      )}
                    </button>
                  </li>
                );
              })
            ) : (
              <>
                <li>{labels.NO_DIAGNOSTICS}</li>
              </>
            )}
          </ul>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default PodsListView;
