import React, { useEffect, useRef, useState } from "react";
import { Badge } from "@material-ui/core";
import { IoClose } from "react-icons/io5";

import "../../design-tokens/ReusableComp.scss";
import CustomIcons from "./CustomIcons";
import { formatBytesToSize, randomString } from "./Util";
import AppLabels from "../../constants/App_Labels";
import ErrorBox from "./ErrorBox";

export interface IImage {
  id: string;
  size: number;
  image: string;
  fileName: string;
  [key: string]: any;
}
interface Props {
  title?: string;
  mandatoryTitle?: React.ReactNode;
  onImageChange: any;
  image: any;
}

export default function ImageUpload({
  title,
  onImageChange,
  image,
  mandatoryTitle,
}: Props) {
  const labels = AppLabels();
  const fileInputRef = useRef<any>();
  const [images, setImages] = useState<File[]>(image);
  const [warningPopup, setWarningPopup] = useState(false);

  useEffect(() => setImages(image), [image]);

  const onImageUploadChange = (files: any) => {
    if (files && files.length !== 0) {
      let prevList: any = [...images]; // Create a shallow copy to prevent direct state manipulation
      Array.from(files).forEach((file: any) => {
        const reader = new FileReader();
        reader.onloadend = (e) => {
          prevList = [
            ...prevList,
            {
              id: randomString(10),
              size: e.loaded,
              image: e.target!.result,
              fileName: file.name,
            },
          ];
          setImages(prevList);
          onImageChange(prevList);
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const deleteImage = (key: number) => {
    const filteredImages = images?.filter((n: any) => n?.id !== key);
    setImages(filteredImages);
    onImageChange(filteredImages);
  };

  return (
    <>
      {title && <label className="emailTitleLabel">{title}</label>}
      {mandatoryTitle}
      <div className="imageUploadContainer">
        <section className="uploadSection">
          <button
            onClick={(event) => {
              event.preventDefault();
              fileInputRef.current.click();
            }}
          >
            <CustomIcons name="upload" size={50} />
          </button>
          <input
            type="file"
            style={{ display: "none" }}
            ref={fileInputRef}
            accept="image/*"
            multiple={true}
            onChange={(event) => {
              setWarningPopup(false);
              let files: any = [];
              if (event.target.files) {
                Array.from(event.target.files).forEach((file) => {
                  if (file.type === "image/png" || file.type === "image/jpeg")
                    files.push(file);
                  else setWarningPopup(true);
                });
              }
              if (files && files?.length !== 0) {
                onImageUploadChange(files);
              }
            }}
          />
        </section>
        <section>
          <div className="previewContainer">
            {images?.length !== 0 ? (
              images?.map((p: any) => {
                return (
                  <Badge
                    key={p?.id}
                    badgeContent={
                      <IoClose
                        color={`var(--pureBlack)`}
                        onClick={() => deleteImage(p?.id)}
                      />
                    }
                  >
                    <div className="badgeContent">
                      <img src={p?.image} />
                      <label>{formatBytesToSize(p?.size)}</label>
                    </div>
                  </Badge>
                );
              })
            ) : (
              <>{labels.Select_Images_to_upload_here}</>
            )}
          </div>
        </section>
        <ErrorBox
          open={warningPopup}
          error={"Not an image type"}
          handleClose={() => setWarningPopup(false)}
        />
      </div>
    </>
  );
}
