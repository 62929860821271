import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../middleware/apiActions";
import { NODE_SITE_COMPONENTS } from "../../../middleware/apiEndpoints";
import storeConst from "../../../storeConst";
import { decrApiCall, incrApiCall } from "../mapDetails";

const slice = createSlice({
  name: "vhubClamshellSearch",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    getVhubClamshellSearchBegin: (vhubClamshellSearch) => {
      vhubClamshellSearch.isLoading = true;
      vhubClamshellSearch.status = "";
    },
    getVhubClamshellSearchSuccess: (vhubClamshellSearch, { payload }) => {
      vhubClamshellSearch.isLoading = false;
      vhubClamshellSearch.data = payload;
      vhubClamshellSearch.status = storeConst.OK;
    },
    getVhubClamshellSearchFailure: (vhubClamshellSearch, { payload }) => {
      vhubClamshellSearch.isLoading = false;
      vhubClamshellSearch.error = payload;
      vhubClamshellSearch.data = undefined;
      vhubClamshellSearch.status = storeConst.ERROR;
    },
    resetClamshellData: (vhubClamshellSearch) => {
      vhubClamshellSearch.isLoading = false;
      vhubClamshellSearch.error = undefined;
      vhubClamshellSearch.data = undefined;
      vhubClamshellSearch.status = "";
    },
  },
});
export const { resetClamshellData } = slice.actions;
const {
  getVhubClamshellSearchBegin,
  getVhubClamshellSearchSuccess,
  getVhubClamshellSearchFailure,
} = slice.actions;
export default slice.reducer;

export const getVhubClamshellSearch =
  (clamshellId: number) => (dispatch: any) => {
    dispatch(
      apiCallBegan({
        url: NODE_SITE_COMPONENTS + clamshellId,
        onStart: getVhubClamshellSearchBegin.type,
        onSuccess: getVhubClamshellSearchSuccess.type,
        onError: getVhubClamshellSearchFailure.type,
        onStartDispatch: incrApiCall.type,
        onSuccessDispatch: decrApiCall.type,
        onErrorDispatch: decrApiCall.type,
      })
    );
  };
