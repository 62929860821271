import { WIFISettingsState } from "../../../../states/account/diagnostics/cm/WIFISettingsState";

import {
  WIFI_SETTINGS_BEGIN,
  WIFI_SETTINGS_SUCCESS,
  WIFI_SETTINGS_FAILURE,
  WIFISettingsActionTypes,
} from "../../../../types/account/diagnostics/cm/WIFISettings";

const initialState: WIFISettingsState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function WIFISettingReducer(
  state = initialState,
  action: WIFISettingsActionTypes
): WIFISettingsState {
  switch (action.type) {
    case WIFI_SETTINGS_BEGIN:
      return {
        isLoading: true,
      };
    case WIFI_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case WIFI_SETTINGS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}
