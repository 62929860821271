import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  AccReset,
  EnableWHTFlag,
} from "../../store/actions/account/accountInfo/ActiveAccountAction";
import {
  SetSelectedServiceAddress,
  SetActiveAccountDetails,
  SetAccountSearchValue,
} from "../../store/actions/account/accountInfo/ActiveAccountAction";
import CustAccICon from "../../resources/images/icons/custAccIcon.svg";
import AppLabels from "../../constants/App_Labels";
import { isAccAvailable, isNull } from "../reusable/Util";
import {
  SetActiveAccountKey,
  UpdateAccount,
} from "../../store/actions/account/AllAccountsAction";

/*Changes Added by node Team */
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { FaCheckSquare } from "react-icons/fa";
import { ImCheckboxUnchecked } from "react-icons/im";
import { useEffect } from "react";

/*node team changes done */

function formatAddress(sa: any) {
  let address = "";
  if (sa.apartmentNumber === "") {
    address =
      "" +
      sa.streetNumber +
      " " +
      sa.streetName +
      " " +
      sa.streetType +
      ", " +
      sa.city +
      ", " +
      sa.province +
      " " +
      sa.postalCode;
  } else {
    address =
      "" +
      sa.apartmentNumber +
      "-" +
      sa.streetNumber +
      " " +
      sa.streetName +
      " " +
      sa.streetType +
      ", " +
      sa.city +
      ", " +
      sa.province +
      " " +
      sa.postalCode;
  }
  return address;
}

const SearchResult = (props: any) => {
  const {
    data,
    wHTFlag,
    serviceAddress,
    onHide,
    handleWHTChecked,
    currSearchValue,
  } = props;
  const { currdata, currAccState, allAccounts, searchAccStatus, accountData } =
    useSelector(
      (state: any) => ({
        currdata: state.AccountState.ActiveAccountDetails.data,
        currAccState: state.AccountState,
        allAccounts: state.AllAccounts.accounts,
        searchAccStatus: state.AccountState.AccountDetails.status,
        accountData: state.AccountState.AccountDetails.data,
      }),
      shallowEqual
    );
  useEffect(() => {
    if (searchAccStatus === "OK" && accountData !== "") {
      if (
        accountData?.serviceAddress &&
        accountData?.serviceAddress?.length === 1
      ) {
        openAccPage(formatAddress(accountData?.serviceAddress[0]));
      }
    }
  }, [searchAccStatus]);

  const history = useHistory();
  const labels = AppLabels();
  const dispatch = useDispatch();

  const openAccPage = (sa: String) => {
    if (currdata !== undefined && currAccState !== undefined) {
      dispatch(UpdateAccount(currdata?.accountNumber, currAccState));
    }
    if (isAccAvailable(allAccounts, data?.accountNumber)) {
      if (currdata?.accountNumber === data?.accountNumber) {
        window.open(`${window.location.origin}/#/account?accountNumber=${data.accountNumber}`, '_blank');
      } else {
        dispatch(SetActiveAccountKey(data?.accountNumber));
        window.open(`${window.location.origin}/#/account?accountNumber=${data.accountNumber}`, '_blank');
      }
      onHide();
    } else {
      dispatch(AccReset());
      dispatch(SetActiveAccountDetails(data));
      dispatch(SetActiveAccountKey(data?.accountNumber));
      dispatch(SetSelectedServiceAddress(sa));
      if (currSearchValue !== undefined && currSearchValue !== "") {
        dispatch(SetAccountSearchValue(currSearchValue));
      }
      if (wHTFlag) {
        dispatch(EnableWHTFlag(wHTFlag));
      }
      onHide();
      window.open(`${window.location.origin}/#/account?accountNumber=${data.accountNumber}`, '_blank');
    }
  };

  return (
    <div className="SearchResultCont">
      <p>SEARCH RESULT(S)</p>
      <p>
        <FormControlLabel
          className="WHTCheck"
          control={
            <Checkbox
              checkedIcon={<FaCheckSquare color="#da291c" />}
              icon={<ImCheckboxUnchecked color="#da291c" />}
              checked={wHTFlag}
              onChange={handleWHTChecked}
            />
          }
          label="Run Whole Home Test"
        />
      </p>
      {serviceAddress?.map((sa: any) => {
        return (
          <Card className="resultCard" onClick={() => openAccPage(sa)}>
            <span className="resultCardIcon">
              <img src={CustAccICon} alt="accIcon" />
            </span>
            <span className="resultCardDetails">
              <h5>
                {isNull(data.firstName)} {isNull(data.lastName)}
              </h5>
              <h5>
                {labels.ACCOUNT_NUMBER.toUpperCase()}:&nbsp;
                <span>{data.accountNumber}</span>
              </h5>
              <h5>
                {labels.SERVICE_ADDRESS.toUpperCase()}:&nbsp;
                <span>{sa}</span>
              </h5>
            </span>
          </Card>
        );
      })}
    </div>
  );
};

export default SearchResult;
