export const SAVE_ACTIVE_CERTI_BEGIN = "SAVE_ACTIVE_CERTI_BEGIN";
export const SAVE_ACTIVE_CERTI_SUCCESS = "SAVE_ACTIVE_CERTI_SUCCESS";
export const SAVE_ACTIVE_CERTI_FAILURE = "SAVE_ACTIVE_CERTI_FAILURE";
export const SAVE_PASSED_DEVICES = "SAVE_PASSED_DEVICES";
export const SAVE_FAILED_DEVICES = "SAVE_FAILED_DEVICES";
export const SAVE_SKIPPED_DEVICES = "SAVE_SKIPPED_DEVICES";
export const SAVE_MARGINAL_PASSED_DEVICES= "SAVE_MARGINAL_PASSED_DEVICES";
export const RESET_SAVED_DEVICES = "RESET_SAVED_DEVICES";
export const RESET_ACTIVE_CERTIFICATION = "RESET_ACTIVE_CERTIFICATION";
export const SAVE_WIFI_BYPASS_FLAG= "SAVE_WIFI_BYPASS_FLAG";
export const SAVE_DATA_LOADING="SAVE_DATA_LOADING";
interface SaveActiveCertiBegin {
  type: typeof SAVE_ACTIVE_CERTI_BEGIN;
}
interface SaveActiveCertiSuccess {
  type: typeof SAVE_ACTIVE_CERTI_SUCCESS;
  payload: any;
}
interface SaveDataLoading {
  type: typeof SAVE_DATA_LOADING;
  payload: any;
}
interface SaveActiveCertiFailure {
  type: typeof SAVE_ACTIVE_CERTI_FAILURE;
  payload: Error;
}
interface SavePassedDevices {
  type: typeof SAVE_PASSED_DEVICES;
  payload: any;
}
interface SaveFailedDevices {
  type: typeof SAVE_FAILED_DEVICES;
  payload: any;
}
interface SaveWifiBypassFlag {
  type: typeof SAVE_WIFI_BYPASS_FLAG;
  payload: any;
}
interface SaveSkippedDevices {
  type: typeof SAVE_SKIPPED_DEVICES;
  payload: any;
}
interface SaveMarginalPassDevices{
  type: typeof SAVE_MARGINAL_PASSED_DEVICES;
  payload: any;
}
interface ResetSavedDevices {
  type: typeof RESET_SAVED_DEVICES;
}
interface ResetActiveCertification {
  type: typeof RESET_ACTIVE_CERTIFICATION;
}
export type ActiveCertificationActionTypes =
  | SaveActiveCertiBegin
  | SaveActiveCertiSuccess  
  | SaveDataLoading
  | SaveActiveCertiFailure
  | SaveWifiBypassFlag
  | SavePassedDevices
  | SaveFailedDevices
  | SaveMarginalPassDevices
  | SaveSkippedDevices
  | ResetSavedDevices
  | ResetActiveCertification;
