import { Divider } from "@material-ui/core";
import AppLabels from "../../constants/App_Labels";
import { getFailCMTableStats, getMarginalCMTableStats, toProperCase } from "../reusable/Util";
// in this component we applied inline CSS due to export purpose
//please do not follow the same in other components as its not a good practice
interface Props {
  woID: string;
  woType: string;
  techId: string;
  firmName: string;
  techNotes: string;
  failedCodewords: any;
  wifiCertiDetails: any;
  hsiFailCondition: boolean;
  wifiFailCondition: boolean;
  allFailedAttributes?: any;
}

const DisplayEmailPreviewCert = (props: Props) => {
  const labels = AppLabels() as any;

  let getDownloadSpeed = (dValue: number) => {
    let downloadValue = (dValue / 1000).toFixed(2);
    return parseFloat(downloadValue);
  };

  let getUploadSpeed = (uValue: number) => {
    let uploadValue = (uValue / 1000).toFixed(2);
    return parseFloat(uploadValue);
  };

  let getFailedAttributes = (e: any) => {
    let failAreas = [];
    const download = getDownloadSpeed(e?.results?.speedTestResult?.download);
    const upload = getUploadSpeed(e?.results?.speedTestResult?.upload);
    const level = e?.results?.level;
    if (download < 25) {
      failAreas.push("download speed" + " (" + download + " Mbps)");
    }
    if (upload <= 4) {
      failAreas.push("upload speed" + " (" + upload + " Mbps)");
    }
    if (level < -65) {
      failAreas.push("signal strength" + " (" + level + " dBm)");
    }

    if (e?.status === "Fail") {
      return e?.locationName + ": " + failAreas;
    } else {
      return "";
    }
  };

  let getAttrTable = (el: any, type?: any) => {
    let colNames: any = null;
    if (type === "Fail") {
      colNames = getFailCMTableStats(el);
    } else {
      colNames = getMarginalCMTableStats(el);
    }
    const headers = colNames?.map((name: string, i: number) => (
      <th
        key={i}
        style={{
          padding: 5,
          border: "1px solid",
          textAlign: "left"
        }}
      >{name}</th>
    ));
    const rows = el?.value?.map((v: any, i: any) => (
      <tr key={i}>
        {el?.label?.map((attr: any, j: any) => (
          <td
            key={j}
            style={{
              padding: 5,
              border: "1px solid",
              textAlign: "left"
            }}
          >{v[attr]}</td>
        ))}
      </tr>
    ));
    return (
      <table style={{
        borderCollapse: "collapse",
        border: "1px solid",
      }}>
        <thead>
          <tr>{headers}</tr>
        </thead>
        <tbody> {rows}</tbody>
      </table>
    );
  };

  return (
    <div
      style={{
        display: "inline-block",
        fontWeight: 500,
        fontFamily: "Ted Next",
      }}
    >
      <div style={{ marginBottom: "10px" }} >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {"Technician ID"}:
        </span>
        <span>{" " + props.techId}</span>
      </div>
      <div style={{ marginBottom: "10px" }} >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {"Firm Name"}:
        </span>
        <span>
          {" " + props?.firmName == undefined
            ? ""
            : props?.firmName === ""
              ? ""
              : props?.firmName}
        </span>
      </div>
      <div style={{ marginBottom: "10px" }} >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {labels.Work_Order_ID}:
        </span>
        <span>{" " + props.woID}</span>
      </div>
      <Divider style={{ margin: "0px 0px 10px 0px" }} />
      {props?.hsiFailCondition && (
        <div>
          <div
            style={{
              fontWeight: 700,
              marginRight: "10px",
              display: "block",
            }}
          >
            {"Failed" + " " + labels.Attributes}:
          </div>
          {props?.allFailedAttributes &&
            props?.allFailedAttributes?.map((e: any, i: number) => {
              const deviceStatus = toProperCase(e?.device?.deviceStatus);
              const deviceCat = e?.device?.deviceCategory;
              const serialNum = e?.device?.serialNumber;
              return <div key={i} style={{ marginLeft: "10px" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ fontSize: "16px", fontWeight: 700 }}>
                    {deviceCat} - {serialNum}
                  </div>
                  <div style={{ fontSize: "16px", fontWeight: 700 }}>
                    Device Status: {deviceStatus}
                  </div>
                </div>
                {e?.failedAttributes?.length > 0 && (
                  e?.failedAttributes?.map((el: any, index: number) => {
                    return (
                      <div key={index} style={{ marginBottom: "10px" }}>
                        <li>
                          <span>{el?.name}</span>
                          <div style={{ marginLeft: "25px", marginTop: "10px" }}>
                            {getAttrTable(el, "Fail")}
                          </div>
                        </li>
                      </div>
                    );
                  })
                )}
                {e?.marginalAttributes?.length > 0 && (e?.marginalAttributes?.map((el: any, index: number) => {
                  return (
                    <div key={index} style={{ marginBottom: "10px" }}>
                      <li>
                        <span>{el?.name}</span>
                        <div style={{ marginLeft: "25px", marginTop: "10px" }}>
                          {getAttrTable(el)}
                        </div>
                      </li>
                    </div>
                  );
                })
                )}
                {props?.failedCodewords !== undefined &&
                  props?.failedCodewords !== null &&
                  props?.failedCodewords !== "" &&
                  props?.failedCodewords?.map((e: any, i: number) => (
                    <div key={i} >
                      <li>
                        <span>{e.name}</span>
                        <div
                          style={{ marginLeft: "25px", marginTop: "10px", marginBottom: "10px" }}
                        >
                          <table style={{ border: "1px solid" }}>
                            <thead>
                              <tr>
                                <th style={{ padding: 5, border: "1px solid" }}>
                                  {labels.UNCORRECTABLES_DELTA}
                                </th>
                                <th style={{ padding: 5, border: "1px solid" }}>
                                  {"Frequency"}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {e?.value?.map((f: any, j: number) => (
                                <tr>
                                  <td
                                    key={i}
                                    style={{ padding: 5, border: "1px solid" }}
                                  >
                                    {f.value}
                                  </td>
                                  <td
                                    key={i}
                                    style={{ padding: 5, border: "1px solid" }}
                                  >
                                    {f.frequency}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </li>
                    </div>
                  ))}
              </div>
            }
            )}
        </div>
      )}
      {props?.wifiFailCondition && (
        <>
          {props?.hsiFailCondition && (
            <Divider style={{ margin: "0px 0px 30px 0px" }} />
          )}

          <div style={{ marginBottom: "10px" }} >
            <span
              style={{
                fontWeight: 700,
                marginRight: "10px",
              }}
            >
              {"WiFi Pass Count"}:
            </span>
            <span>{" " + props.wifiCertiDetails?.summary?.passed}</span>
          </div>
          <div style={{ marginBottom: "10px" }} >
            <span
              style={{
                fontWeight: 700,
                marginRight: "10px",
              }}
            >
              {"WiFi Fail Count"}:
            </span>
            <span>{" " + props.wifiCertiDetails?.summary?.failed}</span>
          </div>
          <div style={{ marginBottom: "10px" }} >
            <span
              style={{
                fontWeight: 700,
                marginRight: "10px",
              }}
            >
              {"Failed Attributes"}:
            </span>
            <ul>
              {props.wifiCertiDetails?.locations
                ?.filter((e: any) => getFailedAttributes(e) !== "")
                .map((e: any, i: number) => (
                  <li key={i}>{getFailedAttributes(e)}</li>
                ))}
            </ul>
          </div>
        </>
      )
      }
      <div style={{ marginBottom: "10px" }} >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
            display: "inline-block",
            width: "100%",
          }}
        >
          {"Technician's Comment"}:
        </span>
        {props.techNotes ? (
          <span>{" " + props.techNotes}</span>
        ) : (
          <span style={{ fontStyle: "italic" }}>
            {" (No comments entered by the technician)"}
          </span>
        )}
      </div>
    </div >
  );
};

export default DisplayEmailPreviewCert;
