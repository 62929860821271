import { InfoWindow } from "@react-google-maps/api";
import { getHomeAddress } from "../../../components/reusable/Util";
import {
  getCMStatusProps,
  applyDiagAdvancedFilters,
} from "../../util/utilFuncs";
import SimpleDataTable from "./SimpleDataTable";
import TopologySymbols from "./TopologySymbols";
import AppLabels from "../../../constants/App_Labels";
import MultiSelectDD from "./MultiSelectDD";
import { mapElementStyles } from "../../config/mapItemsConfig";

interface Props {
  showCluster: any;
  activeClusterData?: any;
  advFCond: string;
  advancedFilters?: any;
  hideClusterPopUp?: any;
  statusFilters?: any;
  setStatusFilters?: any;
  handleOnClick?: any;
}

const ActiveCMClusterTable = ({
  showCluster,
  activeClusterData,
  advFCond,
  advancedFilters,
  hideClusterPopUp,
  statusFilters,
  setStatusFilters,
  handleOnClick,
}: Props) => {
  const labels = AppLabels();
  const npCmConfig = {
    headers: [labels?.cmMac, labels?.Address, labels?.STATUS],
  };

  return (
    <>
      {showCluster &&
        activeClusterData &&
        activeClusterData?.modems?.length > 0 && (
          <InfoWindow
            position={{
              lat: activeClusterData?.lat,
              lng: activeClusterData?.lng,
            }}
            onCloseClick={hideClusterPopUp}
            options={{
              zIndex: 1000,
              pixelOffset: new google.maps.Size(0, -10),
            }}
          >
            <div className="clusterCMCont" style={{ minHeight: 160 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <MultiSelectDD
                  ddTitle="Filter"
                  ddStatus={labels.STATUS}
                  ddContentStyle={{ maxHeight: 130 }}
                  options={statusFilters}
                  searchFlag={false}
                  setSelectedOptions={(value: any) => {
                    setStatusFilters(value);
                  }}
                />
              </div>
              <SimpleDataTable
                headers={npCmConfig?.headers}
                tBody={activeClusterData?.modems?.map((cm: any, i: number) => {
                  const iconSize = mapElementStyles?.cableModem?.iconSize;
                  const cmProps = getCMStatusProps(cm?.deviceDiag);
                  let ele = statusFilters?.find(
                    (filters: any) => filters?.key === cmProps?.iconName
                  );

                  if (
                    ele?.value &&
                    cm?.deviceDiag &&
                    applyDiagAdvancedFilters(
                      cm?.deviceDiag?.data,
                      advancedFilters,
                      advFCond
                    )
                  ) {
                    return (
                      <tr key={cm?.serialNumber}>
                        <td
                          className="cmMacLink"
                          onClick={() => handleOnClick(cm)}
                        >
                          {cm?.macAddress}
                        </td>
                        <td>{getHomeAddress(cm?.serviceAddress)}</td>
                        <td>
                          <span>
                            <TopologySymbols
                              name={cmProps.iconName}
                              color={cmProps.color}
                              size={iconSize}
                            />
                          </span>
                        </td>
                      </tr>
                    );
                  }
                })}
              />
            </div>
          </InfoWindow>
        )}
    </>
  );
};

export default ActiveCMClusterTable;
