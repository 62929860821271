import {
  CSSProperties,
  ChangeEvent,
  KeyboardEvent,
  MutableRefObject,
  ReactNode,
  useRef,
  useState,
} from "react";
import { Overlay, Popover } from "react-bootstrap";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import {
  LuUndo,
  LuRedo,
  LuType,
  LuArrowLeft,
  LuTriangle,
  LuCircle,
  LuOctagon,
  LuSquare,
  LuStar,
  LuPlus,
  LuImagePlus,
  LuMoreHorizontal,
  LuDownload,
  LuInfo,
} from "react-icons/lu";
import { TbScribble } from "react-icons/tb";
import { AiOutlineLine } from "react-icons/ai";

import CustomPopOver from "../../../../../components/reusable/CustomPopOver";
import { DrawingType } from "../GeoEditCanvas";
import Shortcuts from "./Shortcuts";
import GeoExport from "./GeoExport";
import TopologySymbols from "../../../reusable/TopologySymbols";
import CreateRedline from "./createRedline/CreateRedline";
import { ITechProfile } from "../../../../../store/ducksPattern/TechProfile";

export interface IOnAddTextInput {
  text: string;
}

interface Props {
  onMenuUndoClick: () => void;
  onMenuRedoClick: () => void;
  drawingType: DrawingType;
  setDrawingType: (drawingType: DrawingType) => void;
  onAddText: ({ text }: IOnAddTextInput) => void;
  stroke: string;
  setStroke: (stroke: string) => void;
  onAddImage: () => void;
  strokeWidth: number;
  setStrokeWidth: (sw: number) => void;
  opacity: number;
  setOpacity: (opacity: number) => void;
  fill: string;
  setFill: (fill: string) => void;
  txtSize: number;
  setTxtSize: (txtSize: number) => void;
  txtWidth: number;
  setTxtWidth: (txtWidth: number) => void;
  txtColor: string;
  setTxtColor: (txtColor: string) => void;
  stageRef: MutableRefObject<any>;
  latLng?: { latitude: number; longitude: number };
  nodeId?: string;
  techData?: ITechProfile["data"];
  owningDrawing?: string;
}

export default function GeoEditMenu({
  onMenuUndoClick,
  onMenuRedoClick,
  drawingType,
  setDrawingType,
  onAddText,
  stroke,
  setStroke,
  onAddImage,
  strokeWidth,
  setStrokeWidth,
  opacity,
  setOpacity,
  fill,
  setFill,
  txtSize,
  setTxtSize,
  txtWidth,
  setTxtWidth,
  txtColor,
  setTxtColor,
  stageRef,
  latLng,
  nodeId,
  techData,
  owningDrawing,
}: Props) {
  const txtTargetRef = useRef(null);
  const moreTargetRef = useRef(null);
  const [showTxtPopover, setTxtShowPopover] = useState(false);
  const [showMorePopover, setShowMorePopover] = useState(false);
  const [inputText, setInputText] = useState("");
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showRedlineModal, setShowRedlineModal] = useState(false);

  const getStyle = (drawing: DrawingType | "") => {
    if (drawing === drawingType) {
      return {
        backgroundColor: `var(--primary20)`,
        padding: 5,
      } as CSSProperties;
    } else return { padding: 5 };
  };

  const onColorChange = (e: ChangeEvent<HTMLInputElement>) => {
    const color = e.target.value;
    setStroke(color);
    fill !== "transparent" && setFill(color);
  };

  const items = [
    {
      label: "Create Redline",
      icon: <TopologySymbols name="editMap" color="black" />,
      style: getStyle(""),
      onClick: () => setShowRedlineModal(true),
    },
    {
      label: "Undo (Ctr+Z)",
      icon: <LuUndo />,
      style: getStyle(""),
      onClick: onMenuUndoClick,
    },
    {
      label: "Redo (Ctr+Y)",
      icon: <LuRedo />,
      style: getStyle(""),
      onClick: onMenuRedoClick,
    },
    {
      label: "Free Drawing",
      icon: <TbScribble />,
      style: getStyle("pen"),
      onClick: () => setDrawingType("pen"),
    },
    {
      label: "Line",
      icon: <AiOutlineLine style={{ transform: "rotate(40deg)" }} />,
      style: getStyle("line"),
      onClick: () => setDrawingType("line"),
    },
    {
      label: "Arrow",
      icon: <LuArrowLeft style={{ transform: "rotate(40deg)" }} />,
      style: getStyle("arrow"),
      onClick: () => setDrawingType("arrow"),
    },
    {
      label: "Text",
      icon: (
        <div ref={txtTargetRef}>
          <LuType />
        </div>
      ),
      style: getStyle("text"),
      onClick: () => setTxtShowPopover(true),
    },
    {
      label: "Triangle",
      icon: <LuTriangle />,
      style: getStyle("triangle"),
      onClick: () => setDrawingType("triangle"),
    },
    {
      label: "Square",
      icon: <LuSquare />,
      style: getStyle("rectangle"),
      onClick: () => setDrawingType("rectangle"),
    },
    {
      label: "Circle",
      icon: <LuCircle />,
      style: getStyle("circle"),
      onClick: () => setDrawingType("circle"),
    },
    {
      label: "Octagon",
      icon: <LuOctagon />,
      style: getStyle("hexagon"),
      onClick: () => setDrawingType("hexagon"),
    },
    {
      label: "Star",
      icon: <LuStar />,
      style: getStyle("star"),
      onClick: () => setDrawingType("star"),
    },
    // {
    //   label: "Pie",
    //   icon: <LuPieChart />,
    //   style: getStyle("pie"),
    //   onClick: () => setDrawingType("pie"),
    // },
    {
      label: "Add image to canvas",
      icon: <LuImagePlus />,
      style: getStyle(""),
      onClick: onAddImage,
    },
    {
      label: "Color Picker",
      icon: (
        <>
          <input
            type="color"
            value={stroke}
            onChange={onColorChange}
            style={{ height: 25, width: 25 }}
          />
        </>
      ),
      style: getStyle(""),
    },
    {
      label: "Filled, Stroke, Opacity, and More...",
      icon: (
        <div ref={moreTargetRef}>
          <LuMoreHorizontal />
        </div>
      ),
      style: getStyle(""),
      onClick: () => setShowMorePopover(true),
    },
    {
      label: "Info: Keyboard Shortucts",
      icon: <LuInfo />,
      style: getStyle(""),
      onClick: () => setShowInfoModal(true),
    },
    {
      label: "Export Image",
      icon: <LuDownload />,
      style: getStyle(""),
      onClick: () => setShowExportModal(true),
    },
  ];

  const onTxtAdd = () => {
    onAddText({ text: inputText });
    setInputText("");
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") onTxtAdd();
  };

  return (
    <div className="geoEditMenuCont">
      <div className="itemsCont">
        {items.map((item) => (
          <div key={item.label} onClick={item.onClick}>
            <CustomPopOver
              position="left"
              popOverData={item.label}
              index="popOver"
              originComp={<div style={item.style}>{item.icon}</div>}
            />
          </div>
        ))}
      </div>
      <CPopover
        targetRef={txtTargetRef}
        show={showTxtPopover}
        setShow={setTxtShowPopover}
      >
        <div className="geoEditMenuPopupcont">
          <input
            type="color"
            value={txtColor}
            onChange={(e) => setTxtColor(e.target.value)}
            style={{ height: 40, width: "auto" }}
          />
          <TextField
            variant="outlined"
            label="Text Size"
            value={String(txtSize)}
            size="small"
            type="number"
            onChange={(e) => setTxtSize(Number(e.target.value))}
          />
          <TextField
            variant="outlined"
            label="Text Width"
            value={String(txtWidth)}
            size="small"
            type="number"
            onChange={(e) => setTxtWidth(Number(e.target.value))}
          />
          <TextField
            variant="outlined"
            label="Text Value"
            placeholder="...type and press add to add to canvas"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyDown={handleKeyDown}
            multiline
            rows={3}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={onTxtAdd}>
                    <LuPlus size={25} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </CPopover>
      <CPopover
        targetRef={moreTargetRef}
        show={showMorePopover}
        setShow={setShowMorePopover}
      >
        <div className="geoEditMenuPopupcont">
          <Button
            variant={fill !== "transparent" ? "contained" : "outlined"}
            onClick={() =>
              setFill(fill === "transparent" ? stroke : "transparent")
            }
            color="primary"
            style={{ height: 40 }}
          >
            {fill !== "transparent" ? "Filled Shapes" : "Outlined Shapes"}
          </Button>
          <TextField
            variant="outlined"
            label="Stroke"
            value={String(strokeWidth)}
            size="small"
            type="number"
            onChange={(e) => setStrokeWidth(Number(e.target.value))}
          />
          <TextField
            variant="outlined"
            label="Opacity"
            value={String(opacity)}
            size="small"
            type="number"
            onChange={(e) => setOpacity(Number(e.target.value))}
          />
        </div>
      </CPopover>
      {showInfoModal && (
        <Shortcuts showModal={showInfoModal} setShowModal={setShowInfoModal} />
      )}
      {showExportModal && (
        <GeoExport
          showModal={showExportModal}
          setShowModal={setShowExportModal}
          stageRef={stageRef}
          latLng={latLng}
          nodeId={nodeId}
          owningDrawing={owningDrawing}
        />
      )}
      {showRedlineModal && (
        <CreateRedline
          showModal={showRedlineModal}
          setShowModal={setShowRedlineModal}
          stageRef={stageRef}
          nodeId={nodeId}
          latLng={latLng}
          owningDrawing={owningDrawing}
        />
      )}
    </div>
  );
}

interface ICPopover {
  children: ReactNode;
  targetRef: MutableRefObject<null>;
  show: boolean;
  setShow: (show: boolean) => void;
}
const CPopover = ({ children, targetRef, show, setShow }: ICPopover) => {
  const ref = useRef(null);

  return (
    <div ref={ref}>
      <Overlay
        show={show}
        target={targetRef?.current}
        placement="left"
        container={ref}
        rootClose
        onHide={() => setShow(false)}
      >
        <Popover id="popoverCont">{children}</Popover>
      </Overlay>
    </div>
  );
};
