import { shallowEqual, useDispatch, useSelector } from "react-redux";
import IPTVInfoClient from "../../network/httpclient/iptv/IPTVInfoClient";
import IPTVDeviceInfo from "./IPTVDeviceInfo";
import IPTVNetworkInfo from "./IPTVNetworkInfo";
import IPTVEthernetInfo from "./IPTVEthernetInfo";
import IPTVWIFIInfo from "./IPTVWIFIInfo";
import ReloadData from "../reusable/ReloadData";
import { useEffect } from "react";
import DiagApiCallCont from "../reusable/DiagApiCallCont";

const IPTVSummaryInfo = (props: any) => {
  const {
    params,
    connectionType,
    iptvLoading,
    iptvDeviceInfo,
    iptvDeviceStatus,
    iptvNetworkInfo,
    iptvEthernetInfo,
    iptvWifiInfo,
  } = props;

  const dispatch = useDispatch();
  const { iptvNetworkdata, iptvNetworkStatus } = useSelector(
    (state: any) => ({
      iptvNetworkdata: state.AccountState.IptvNetworkInfo.data,
      iptvNetworkStatus: state.AccountState.IptvNetworkInfo.status,
    }),
    shallowEqual
  );

  const refreshData = () => {
    dispatch(IPTVInfoClient.getIptvDevice(params));
    dispatch(IPTVInfoClient.getIptvNetwork(params));
  };
  useEffect(() => {
    if (connectionType?.toLowerCase() === "wifi") {
      dispatch(IPTVInfoClient.getIptvWifi(params));
    } else if (connectionType?.toLowerCase() === "ethernet") {
      dispatch(IPTVInfoClient.getIptvEthernet(params));
    }
  }, [iptvNetworkdata]);

  return (
    <>
      <div className="refreshCont">
        <ReloadData refreshData={refreshData} />
      </div>
      <div className="cmSummaryCont">
        <DiagApiCallCont
          isLoading={iptvLoading}
          data={iptvDeviceInfo}
          sectionName="IPTV Summary"
          status={iptvDeviceStatus}
          handleRefresh={() => refreshData()}
        >
          <IPTVNetworkInfo
            networkData={iptvNetworkInfo}
            iptvNetworkError={iptvNetworkInfo}
            refreshData={refreshData}
          />
          {connectionType?.toLowerCase() === "ethernet" ? (
            <IPTVEthernetInfo
              ethernetData={iptvEthernetInfo}
              ethernetError={iptvEthernetInfo}
              refreshData={refreshData}
            />
          ) : (
            <></>
          )}
          {connectionType?.toLowerCase() === "wifi" ? (
            <IPTVWIFIInfo
              wifiData={iptvWifiInfo}
              wifiError={iptvWifiInfo}
              refreshData={refreshData}
            />
          ) : (
            <></>
          )}
          <IPTVDeviceInfo deviceData={iptvDeviceInfo} />
        </DiagApiCallCont>
      </div>
    </>
  );
};

export default IPTVSummaryInfo;
