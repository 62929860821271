import { Accordion, Card } from "react-bootstrap";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { isNull } from "../../reusable/Util";
const WSubDetails = (props: any) => {
  const {
    subscriberData,
    accordionValue,
    accordionIndex,
    handleAccordionToggle,
  } = props;
  return (
    <Card className="dataCard">
      <Accordion.Toggle
        as={Card.Header}
        eventKey="0"
        onClick={() => handleAccordionToggle(0, accordionValue)}
      >
        <span>SUBSCRIBER DATA</span>
        {accordionValue === 1 && accordionIndex === 0 ? (
          <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
        ) : (
          <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
        )}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        <Card.Body>
          <table className="dataTableDetails">
            <thead>
              <tr>
                <th id="nameCol">NAME</th>
                <th id="valueCol">VALUE</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>MSISDN</td>
                <td>{isNull(subscriberData.msisdn)}</td>
              </tr>
              <tr>
                <td>IMSI</td>
                <td>{isNull(subscriberData.imsi)}</td>
              </tr>
              <tr>
                <td>SUBSCRIPTION ID</td>
                <td>{isNull(subscriberData.subscriptionId)}</td>
              </tr>
              <tr>
                <td>EPS PROFILE ID</td>
                <td>{isNull(subscriberData.epsProfileId)}</td>
              </tr>
              <tr>
                <td>EPS INDIVIDUAL DEFAULT CONTEXTID</td>
                <td>{isNull(subscriberData.epsIndividualDefaultContextId)}</td>
              </tr>
              <tr>
                <td>EPS INDIVIDUAL CONTEXT IDs</td>
                <td>
                  {subscriberData?.epsIndividualContextIds?.length !== 0 ? (
                    subscriberData?.epsIndividualContextIds?.map(
                      (id: any, i: number) => {
                        if (
                          i ===
                          subscriberData.epsIndividualContextIds?.length - 1
                        ) {
                          return id;
                        }
                        return id + " , ";
                      }
                    )
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
              <tr>
                <td>EPS INDIVIDUAL USE TYPE</td>
                <td>{isNull(subscriberData.epsIndividualUserType)}</td>
              </tr>
              <tr>
                <td>EPS LAST UPDATE LOCATION DATE</td>
                <td>{isNull(subscriberData.epsLastUpdateLocationDate)}</td>
              </tr>
              <tr>
                <td>EPS DYNAMIC PDN INFORMATION</td>
                <td>{isNull(subscriberData.epsDynamicPdnInformation)}</td>
              </tr>
              <tr>
                <td>EPS MME REALM</td>
                <td>{isNull(subscriberData.epsMmeRealm)}</td>
              </tr>
              <tr>
                <td>LOCATION</td>
                <td>{isNull(subscriberData.location)}</td>
              </tr>
              <tr>
                <td>MME ADDRESS</td>
                <td>{isNull(subscriberData.mmeAddress)}</td>
              </tr>
            </tbody>
          </table>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default WSubDetails;
