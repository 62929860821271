import {
  HISTORY_BEGIN,
  HISTORY_SUCCESS,
  HISTORY_RESET,
  HISTORY_FAILURE,
  HistoryActionTypes,
} from "../../types/history/History";

export function historyBegin(): HistoryActionTypes {
  return {
    type: HISTORY_BEGIN,
  };
}

export function historySuccess(history: any): HistoryActionTypes {
  return {
    type: HISTORY_SUCCESS,
    payload: history,
  };
}

export function historyReset(history: any): HistoryActionTypes {
  return {
    type: HISTORY_RESET,
    payload: history,
  };
}

export function historyFailure(error: Error): HistoryActionTypes {
  return {
    type: HISTORY_FAILURE,
    payload: error,
  };
}
