import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { useState } from "react";

import {
  isNull,
  getThreshold,
  getStatusOfCheck,
  getCheckValue,
  isEmptyObject,
  isObject,
  getStatusOfCheckHeader,
} from "../../reusable/Util";
import AppLabels from "../../../constants/App_Labels";
import CustomPopOver from "../../reusable/CustomPopOver";

const OFDMChannelData = (props: any) => {
  const labels = AppLabels();
  const { ofdmChannel } = props;
  const [summaryAcc, setSummaryAcc] = useState(true);
  const [detailsAcc, setDetailsAcc] = useState(false);

  const handleAccToggle = (accName: string) => {
    if (accName === "summary") setSummaryAcc(!summaryAcc);
    else if (accName === "details") setDetailsAcc(!detailsAcc);
  };
  return (
    <div className="ofdmChannelCont">
      <div className="ofdmChSummary">
        <div className="dsChDataAcc" onClick={() => handleAccToggle("summary")}>
          <p>{labels.SUMMARY.toUpperCase()}</p>
          {summaryAcc ? (
            <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
          ) : (
            <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
          )}
        </div>
        {summaryAcc ? (
          <table className="dataTableDetails">
            <thead>
              <tr>
                <th>{labels.NAME.toUpperCase()}</th>
                <th>{labels.VALUE.toUpperCase()}</th>
                <th>{labels.STATUS.toUpperCase()}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{labels.CHANNEL_ID.toUpperCase()}</td>
                <td>{isNull(ofdmChannel.channelId)}</td>
                <td></td>
              </tr>
              <tr>
                <td>{labels.MAX_MODULATION.toUpperCase()}</td>
                <td>{isNull(ofdmChannel.maxModulation)}</td>
                <td></td>
              </tr>
              <tr>
                <td>{labels.MIN_MODULATION.toUpperCase()}</td>
                <td>{isNull(ofdmChannel.minModulation)}</td>
                <td></td>
              </tr>
              <tr>
                <td>{labels.MER_MEAN.toUpperCase()}</td>
                {ofdmChannel?.hasOwnProperty("mer") ? (
                  <>
                    <td>{isNull(ofdmChannel.mer.mean)}</td>
                    <td></td>
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td>
                  {getStatusOfCheckHeader(
                    ofdmChannel?.mer?.thrVal,
                    labels.MER_2PCT.toUpperCase()
                  )}
                </td>
                {ofdmChannel?.hasOwnProperty("mer") ? (
                  <>
                    <td>
                      {isObject(ofdmChannel.mer.thrVal)
                        ? isNull(
                          getCheckValue(isEmptyObject(ofdmChannel.mer.thrVal))
                        )
                        : ""}
                    </td>
                    <CustomPopOver
                      popOverData={
                        isObject(ofdmChannel.mer.thrVal)
                          ? isNull(
                            getThreshold(
                              isEmptyObject(ofdmChannel.mer.thrVal)
                            )
                          )
                          : ""
                      }
                      originComp={
                        isObject(ofdmChannel.mer.thrVal)
                          ? isNull(
                            getStatusOfCheck(
                              isEmptyObject(ofdmChannel.mer.thrVal)
                            )
                          )
                          : ""
                      }
                      index="popOver"
                    />
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td>{labels.MER_STD.toUpperCase()}</td>
                {ofdmChannel?.hasOwnProperty("mer") ? (
                  <>
                    <td>{isNull(ofdmChannel.mer.stdDev)}</td>
                    <td></td>
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td>
                  {getStatusOfCheckHeader(
                    ofdmChannel?.maxRxPower,
                    labels.RX_POWER_MAX.toUpperCase()
                  )}
                </td>
                {ofdmChannel?.hasOwnProperty("maxRxPower") ? (
                  <>
                    <td>
                      {isObject(ofdmChannel.maxRxPower)
                        ? isNull(getCheckValue(ofdmChannel.maxRxPower))
                        : ""}
                    </td>
                    <CustomPopOver
                      popOverData={
                        isObject(ofdmChannel.maxRxPower)
                          ? isNull(getThreshold(ofdmChannel.maxRxPower))
                          : ""
                      }
                      originComp={
                        isObject(ofdmChannel.maxRxPower)
                          ? isNull(getStatusOfCheck(ofdmChannel.maxRxPower))
                          : ""
                      }
                      index="popOver"
                    />
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td>
                  {" "}
                  {getStatusOfCheckHeader(
                    ofdmChannel?.minRxPower,
                    labels.RX_POWER_MIN.toUpperCase()
                  )}
                </td>
                {ofdmChannel?.hasOwnProperty("minRxPower") ? (
                  <>
                    <td>
                      {isObject(ofdmChannel.minRxPower)
                        ? isNull(getCheckValue(ofdmChannel.minRxPower))
                        : ""}
                    </td>
                    <CustomPopOver
                      popOverData={
                        isObject(ofdmChannel.minRxPower)
                          ? isNull(getThreshold(ofdmChannel.minRxPower))
                          : ""
                      }
                      originComp={
                        isObject(ofdmChannel.minRxPower)
                          ? isNull(getStatusOfCheck(ofdmChannel.minRxPower))
                          : ""
                      }
                      index="popOver"
                    />
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td>{labels.RX_POWER_DELTA.toUpperCase()}</td>
                <td>{isNull(ofdmChannel.rxPowerDelta)}</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  {getStatusOfCheckHeader(
                    ofdmChannel?.averageRxPower,
                    labels.RX_POWER_AVG.toUpperCase()
                  )}
                </td>

                {ofdmChannel?.hasOwnProperty("averageRxPower") ? (
                  <>
                    <td>
                      {isObject(ofdmChannel.averageRxPower)
                        ? isNull(getCheckValue(ofdmChannel.averageRxPower))
                        : ""}
                    </td>
                    <CustomPopOver
                      popOverData={
                        isObject(ofdmChannel.averageRxPower)
                          ? isNull(getThreshold(ofdmChannel.averageRxPower))
                          : ""
                      }
                      originComp={
                        isObject(ofdmChannel.averageRxPower)
                          ? isNull(getStatusOfCheck(ofdmChannel.averageRxPower))
                          : ""
                      }
                      index="popOver"
                    />
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td>
                  {getStatusOfCheckHeader(
                    ofdmChannel?.status,
                    "Operational Status".toUpperCase()
                  )}
                </td>
                {ofdmChannel?.hasOwnProperty("status") ? (
                  <>
                    <td>
                      {isObject(ofdmChannel.status)
                        ? isNull(getCheckValue(ofdmChannel.status))
                        : ""}
                    </td>
                    <CustomPopOver
                      popOverData={
                        isObject(ofdmChannel.status)
                          ? isNull(getThreshold(ofdmChannel.status))
                          : ""
                      }
                      originComp={
                        isObject(ofdmChannel.status)
                          ? isNull(getStatusOfCheck(ofdmChannel.status))
                          : ""
                      }
                      index="popOver"
                    />
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td>{labels.CHANNELS.toUpperCase()}</td>
                <td>
                  {ofdmChannel?.hasOwnProperty("channelBands")
                    ? ofdmChannel.channelBands?.length
                    : 0}
                </td>
                <td></td>
              </tr>
              <tr>
                <td>{labels.ACTIVE_PROFILES.toUpperCase()}</td>
                <td>
                  {ofdmChannel?.hasOwnProperty("profiles")
                    ? ofdmChannel.profiles?.length
                    : 0}
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        ) : (
          <></>
        )}
      </div>
      <div className="ofdmChDetails">
        <div className="dsChDataAcc" onClick={() => handleAccToggle("details")}>
          <p>{labels.CHANNELS.toUpperCase()}</p>
          {detailsAcc ? (
            <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
          ) : (
            <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
          )}
        </div>
        {detailsAcc ? (
          <table className="dataTableDetails">
            <thead>
              <tr>
                <th id="nameCol">{labels.FREQUENCY}</th>
                <th id="valueCol">{labels.RX_POWER.toUpperCase()}</th>
              </tr>
            </thead>
            <tbody>
              {ofdmChannel?.hasOwnProperty("channelBands") &&
                ofdmChannel.channelBands?.length >= 0 ? (
                ofdmChannel.channelBands
                  ?.slice()
                  .sort((a: any, b: any) =>
                    a.centerFreq > b.centerFreq ? 1 : -1
                  )
                  ?.map((cb: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td>{isNull(cb.centerFreq)}</td>
                        {cb?.hasOwnProperty("rxPower") ? (
                          <td>
                            {isNull(getCheckValue(isEmptyObject(cb.rxPower)))}
                          </td>
                        ) : (
                          <td></td>
                        )}
                      </tr>
                    );
                  })
              ) : (
                <></>
              )}
            </tbody>
          </table>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default OFDMChannelData;
