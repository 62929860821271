import { GetTelemetryProps } from "../../topology/util/reduxFunctions/getTopologyState";
import { GetNodeInfoDetails, GetTechInfo } from "../../get-api/GetResponse";
import {
  getNumberorFloat,
  getTelemetryInfo,
  handleNan,
} from "../reusable/NodeUtil";
import { NodeScoreCalculation } from "./NodeScoreCalculation";
import { OdmNodeScoreCalculation } from "./OdmNodeScoreCalculation";
export const NodeCertificationData = (props: any) => {
  const { mlData, diagStartTime, diagEndTime } = GetTelemetryProps();
  const modemListInfo = getTelemetryInfo(mlData);
  let nodeScore = JSON.parse(JSON.stringify(NodeScoreCalculation({ mlData })));
  const nodeId = GetNodeInfoDetails().currNodedata.nodeId;
  const smtId = GetNodeInfoDetails().currNodedata.smt
    ? GetNodeInfoDetails().currNodedata.smtId
    : "";
  const lanId = GetTechInfo()?.data?.lanId;
  const clickId = GetTechInfo()?.techId;
  // const odm3Score = JSON.parse(
  //   JSON.stringify(OdmNodeScoreCalculation({ mlData }))
  // );

  const prepareJson = {
    nodeId: nodeId,
    smtId: smtId,
    woId: props.selectedWOID,
    lanId: lanId,
    clickId: clickId,
    overallScore: nodeScore?.odm?.normalPercent,
    overallUSScore: nodeScore?.odmUS?.normalPercent,
    overallDSScore: nodeScore?.odmDS?.normalPercent,
    breakDownScore: JSON.stringify([
      nodeScore?.odm?.normalPercent,
      nodeScore?.odm?.warningPercent,
      nodeScore?.odm?.criticalPercent,
    ]),
    // odm3Score: getNumberorFloat(handleNan(odm3Score?.odm3Score)),
    // odm3USScore: getNumberorFloat(handleNan(odm3Score?.odm3USScore)),
    // odm3DSScore: getNumberorFloat(handleNan(odm3Score?.odm3DSScore)),
    // odm3OFDMAScore: getNumberorFloat(handleNan(odm3Score?.odm3OFDMAScore)),
    // odm3OFDMScore: getNumberorFloat(handleNan(odm3Score?.odm3OFDMScore)),
    // odm3CmFailStatus:
    //   String(odm3Score?.odm3FailCount) +
    //   " / " +
    //   String(modemListInfo?.assigned),
    active: String(modemListInfo?.active),
    responsive: String(modemListInfo?.responsive),
    assigned: String(modemListInfo?.assigned),
    unresponsive: String(modemListInfo?.unresponsive),
    certStartTime: new Date(diagStartTime).toLocaleString("en-US"),
    certEndTime: new Date(diagEndTime).toLocaleString("en-US"),
    certData: btoa(JSON.stringify(mlData)),
    certDesc: props.comments,
  };

  return prepareJson;
};
