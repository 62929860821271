import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { ActiveCertiState } from "../../../store/states/account/certifications/ActiveCertiState";
import {
  SaveActiveCertiBegin,
  SaveActiveCertiFailure,
  SaveActiveCertiSuccess,
} from "../../../store/actions/account/certifications/ActiveCertificationAction";
import { AllCertificationState } from "../../../store/states/account/certifications/AllCertificationState";
import {
  GetAllCertificationsBegin,
  GetAllCertificationsFailure,
  GetAllCertificationsSuccess,
} from "../../../store/actions/account/certifications/AllCertificationAction";
import { CertiReasonsState } from "../../../store/states/account/certifications/CertiReasonsState";
import {
  GetAllReasonsBegin,
  GetAllReasonsFailure,
  GetAllReasonsSuccess,
} from "../../../store/actions/account/certifications/CertiReasonsActions";
import {GetWifiCertEnableBegin,GetWifiCertEnableSuccess, GetWifiCertEnableFailure} from "../../../store/slices/certification/wifiVisibility";


export default class CertificationClient {
  static getCerti =
    (
      certiID: string
    ): ThunkAction<void, ActiveCertiState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "certificate/" + certiID,
      };

      dispatch(SaveActiveCertiBegin());

      HttpClient.get<any>(httpParameters)
        .then((res) => {
          dispatch(SaveActiveCertiSuccess(res));
          return res;
        })
        .catch((error) => {
          dispatch(SaveActiveCertiFailure(error));
          return error;
        });
    };
  static createCerti =
    (
      dataObj: any
    ): ThunkAction<void, ActiveCertiState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "certificates",
      };

      dispatch(SaveActiveCertiBegin());

      HttpClient.post<any>(httpParameters, dataObj)
        .then((res) => {
          dispatch(SaveActiveCertiSuccess(res));
          return res;
        })
        .catch((error) => {
          dispatch(SaveActiveCertiFailure(error));
          return error;
        });
    };
  static updateCerti =
    (
      certiID: string,
      dataObj: any
    ): ThunkAction<void, ActiveCertiState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "certificates/" + certiID,
      };

      dispatch(SaveActiveCertiBegin());

      HttpClient.post<any>(httpParameters, dataObj)
        .then((res) => {
          dispatch(SaveActiveCertiSuccess(res));
          return res;
        })
        .catch((error) => {
          dispatch(SaveActiveCertiFailure(error));
          return error;
        });
    };
  static getAllCerti =
    (
      accNum: string
    ): ThunkAction<void, AllCertificationState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "account/" +
          accNum +
          "/certificates",
      };

      dispatch(GetAllCertificationsBegin());

      HttpClient.get<any>(httpParameters)
        .then((res) => {
          dispatch(GetAllCertificationsSuccess(res));
          return res;
        })
        .catch((error) => {
          dispatch(GetAllCertificationsFailure(error));
          return error;
        });
    };
  static getReasons =
    (): ThunkAction<void, CertiReasonsState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "certificates/reasons",
      };

      dispatch(GetAllReasonsBegin());

      HttpClient.get<any>(httpParameters)
        .then((res) => {
          dispatch(GetAllReasonsSuccess(res));
          return res;
        })
        .catch((error) => {
          dispatch(GetAllReasonsFailure(error));
          return error;
        });
    };
     
  static getWifiCertEnable =
  (): ThunkAction<void, CertiReasonsState, unknown, Action<string>> =>
  async (dispatch) => {
    let httpParameters: IHttpClientRequestParameters<any> = {
      url: NetworkConfig.ACCOUNT_SERVICE_URL + "wifiCert/enabled",
    };

    dispatch(GetWifiCertEnableBegin());

    HttpClient.get<any>(httpParameters)
      .then((res) => {
        dispatch(GetWifiCertEnableSuccess(res));
        return res;
      })
      .catch((error) => {
        dispatch(GetWifiCertEnableFailure(error));
        return error;
      });
  }
}
