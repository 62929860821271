import { Marker } from "@react-google-maps/api";
import React from "react";
import { renderToString } from "react-dom/server";
import { MdComment } from "react-icons/md";

import { mapElementStyles } from "../../../config/mapItemsConfig";
import appConst from "../../../constants/appConst";
import { getMemoMarkerAnchor } from "../../../util/utilFuncs";

interface Props {
  handleMemoClick: any;
  isVisible: boolean;
  zoomLevel: any;
  featuresAll: any;
  allNodeData: any;
  pngImages?: any;
}

const MemoMarkers = ({
  handleMemoClick,
  isVisible,
  zoomLevel,
  featuresAll,
  allNodeData,
  pngImages,
}: Props) => {
  return (
    <>
      {isVisible &&
        zoomLevel >= mapElementStyles?.memoComment?.zoomLevelRenderMarker &&
        featuresAll?.map((memoData: any) => {
          const anchor = getMemoMarkerAnchor(
            allNodeData,
            memoData,
            pngImages,
            zoomLevel
          );

          const memoMarker = renderToString(
            <MdComment
              size={
                zoomLevel === 21
                  ? mapElementStyles?.memoComment.iconSize.zoom21
                  : zoomLevel === 20
                  ? mapElementStyles?.memoComment.iconSize.zoom20
                  : mapElementStyles?.memoComment.iconSize.zoom19
              }
              color={mapElementStyles?.memoComment?.color}
            />
          );
          return (
            <Marker
              key={memoData?.featureId}
              position={{
                lat: memoData?.geometry?.coordinates?.[1],
                lng: memoData?.geometry?.coordinates?.[0],
              }}
              icon={{
                url:
                  "data:image/svg+xml;charset=utf-8," +
                  encodeURIComponent(memoMarker),
                anchor: new google.maps.Point(
                  anchor?.x +
                    (zoomLevel === 21
                      ? mapElementStyles?.memoComment.anchor.zoom21.x
                      : zoomLevel === 20
                      ? mapElementStyles?.memoComment.anchor.zoom20.x
                      : mapElementStyles?.memoComment.anchor.zoom19.x),
                  anchor?.y +
                    (zoomLevel === 21
                      ? mapElementStyles?.memoComment.anchor.zoom21.y
                      : zoomLevel === 20
                      ? mapElementStyles?.memoComment.anchor.zoom20.y
                      : mapElementStyles?.memoComment.anchor.zoom19.y)
                ),
              }}
              onClick={() => {
                handleMemoClick(memoData, appConst.memoComment);
              }}
            />
          );
        })}
    </>
  );
};

export default React.memo(MemoMarkers);
