import { createSlice } from "@reduxjs/toolkit";
import { changeTimeFormat } from "../../../components/reusable/Util";
import { apiCallBegan } from "../../../topology/store/middleware/apiActions";

import {
  MultiDeviceMAC,
  NETWORK_SERVICE_URL,
} from "../../../topology/store/middleware/apiEndpoints";
import storeConst from "../../../topology/store/storeConst";
import { convertStreamDataToJson } from "../../../topology/util/utilFuncs";

const slice = createSlice({
  name: "multiMAC",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
    startTime: undefined,
    endTime: undefined,
  },
  reducers: {
    getMultipleMACBegin: (tl) => {
      let startTime: any = changeTimeFormat(Date.now());
      tl.status = "";
      tl.error = undefined;
      tl.startTime = startTime;
      tl.isLoading = true;
    },
    getMultipleMACSuccess: (tl, { payload }) => {
      let dataChunkArray = convertStreamDataToJson(payload);

      dataChunkArray.forEach((dataChunk: any) => {
        const accountData = dataChunk.accountSummaryResponse?.data;

        if (accountData && Array.isArray(accountData)) {
          accountData.forEach((item: any) => {
            if ("summary" in item) {
              if (typeof item.summary === "string") {
                try {
                  item.summary = convertStreamDataToJson(item.summary);
                  // console.error(
                  //   "Item.Summary: :",
                  //   convertStreamDataToJson(item.summary)
                  // );
                } catch (error) {
                  console.error("Failed to parse summary:", error);
                }
              }
            }
          });
        }
      });

      tl.isLoading = false;
      tl.data = dataChunkArray;
      let endTime: any = changeTimeFormat(Date.now());
      tl.endTime = endTime;
      tl.status = storeConst.OK;
      tl.error = undefined;
    },
    getMultipleMACFailure: (tl, { payload }) => {
      let endTime: any = changeTimeFormat(Date.now());
      tl.isLoading = false;
      tl.endTime = endTime;
      tl.status = storeConst.ERROR;
      tl.error = payload;
    },
  },
});

export const {
  getMultipleMACBegin,
  getMultipleMACSuccess,
  getMultipleMACFailure,
} = slice.actions;

export default slice.reducer;

export const getMultipleMACData = (deviceMAC: any) => (dispatch: any) => {
  dispatch(
    apiCallBegan({
      baseURL: NETWORK_SERVICE_URL + "diag/network",
      url: MultiDeviceMAC,
      method: "post",
      data: deviceMAC,
      responseType: "stream",
      onStart: getMultipleMACBegin.type,
      onSuccess: getMultipleMACSuccess.type,
      onError: getMultipleMACFailure.type,
    })
  );
};
