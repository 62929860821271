import {FlapHistory} from '../../../models/node/telemetry/FlapHistory';
import {
  FLAP_HISTORY_BEGIN,
  FLAP_HISTORY_FAILURE,
  FLAP_HISTORY_SUCCESS,
  FlapHistoryActionTypes,
} from '../../../types/node/telemetry/FlapHistory';

export function FlapHistoryBegin(): FlapHistoryActionTypes {
  return {
    type: FLAP_HISTORY_BEGIN,
  };
}

export function FlapHistorySuccess(FlapHistory: FlapHistory): FlapHistoryActionTypes {
  return {
    type: FLAP_HISTORY_SUCCESS,
    payload: FlapHistory,
  };
}

export function FlapHistoryFailure(error: Error): FlapHistoryActionTypes {
  return {
    type: FLAP_HISTORY_FAILURE,
    payload: error,
  };
}
