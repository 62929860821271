import { useMemo } from "react";
import {
  isCMOperational,
  calculateCMUptime,
  getDocsisCodewordsObjScQam,
  getCodewordsObjScQam,
  getValueFromMap,
  getOfdmDocsisValueFromMap,
  getOfdmCodewordsValueFromMap,
  getThresholdFromMap,
  getOfdmaDocsisStatus,
  getOfdmDocsisStatus,
  getValueFromMapSpec,
  getSpecChannels,
  getSpecValue,
  getValueColorFromMap,
  getProfilesValueFromMap,
  getOFDMAProfilesValueFromMap,
  renderSpecColor,
  getOfdmDocsisColorFromMap,
  getOfdmValueColorFromMap,
  getOfdmSpecChannels,
  getTelemetryHomeAddress,
  getOFDMValueColorFromMap,
  getCmScore,
  getOfdmaScore,
  getUsOdmScore,
  getOfdmScore,
  getOdmRangeColorFromMap,
  getOdmOfdmValueColorFromMap,
  getOdmOfdmCWValFromMap,
  getOdmOfdmDocsisValFromMap,
  getOdmCertStatus,
  getDsOdmScore,
  handleNan,
  parseNodeData
} from "../reusable/NodeUtil";

import {
  secToTime,
  getPartialServiceFlag,
  changeTimeFormat,
  usInfterfaceDisplay,
} from "../reusable/Util";
import {
  GetTelemetryDetails,
  GetNodeInfoDetails,
} from "../../get-api/GetResponse";
import { GetTelemetryProps } from "../../topology/util/reduxFunctions/getTopologyState";

const TelemetryDataMapping = (nodeDevicesList: any) => {
  const ducksConfigData = GetTelemetryDetails().ducksConfigData;
  const viewType = GetTelemetryProps().viewType;
  const nodeInfo = GetNodeInfoDetails().currNodedata;
  const viewsd = ducksConfigData?.userPreferences?.node?.telemetry?.views;

  let tableColFields = viewsd?.find((view: any) => {
    if (view?.viewTypeKey?.toUpperCase() === viewType?.toUpperCase()) {
      return view?.attributes;
    }
  });

  const getSinglevaluedSpecFlag = (object: object) => {
    let flag = true;
    for (const [key, value] of Object.entries(object)) {
      flag = flag && value !== undefined;
    }
    return flag;
  };

  const getMultivaluedSpecFlag = (object: object) => {
    let flag = true;
    for (const [key, value] of Object.entries(object)) {
      flag = flag && (value === undefined || value?.length >= 1);
    }
    return flag;
  };
  const getSpecInfoForAttributes = (tableColFields: any) => {
    let usTxAttr: any,
      usSnrAttr: any,
      usTxMinAttr: any,
      usTxMaxAttr: any,
      usSnrMaxAttr: any,
      usSnrMinAttr: any,
      dsSnrMaxAttr: any,
      dsSnrMinAttr: any,
      dsRxMinAttr: any,
      dsRxMaxAttr: any,
      dsRxAttr: any,
      dsSnrAttr: any,
      ofdmaTxAttr: any,
      ofdmaPctAttr: any,
      ofdmRxMaxAttr: any,
      ofdmRxMinAttr: any,
      ofdmPctAttr: any,
      usPostCWEDeltaAttr: any,
      usPreCWEDeltaAttr: any,
      dsPostCWEDeltaAttr: any,
      dsPreCWEDeltaAttr: any,
      ofdmaPostCWEDeltaAttr: any,
      ofdmPostCWEDeltaAttr: any = {};
    tableColFields?.specAvailAttr?.map((attr: any) => {
      switch (attr?.columnKey) {
        case "us_tx_max":
          usTxMaxAttr = attr;
          break;
        case "us_tx_min":
          usTxMinAttr = attr;
          break;
        case "us_tx":
          usTxAttr = attr;
          break;
        case "us_snr":
          usSnrAttr = attr;
          break;
        case "us_snr_max":
          usSnrMaxAttr = attr;
          break;
        case "us_snr_min":
          usSnrMinAttr = attr;
          break;
        case "ds_snr_min":
          dsSnrMinAttr = attr;
          break;
        case "ds_snr_max":
          dsSnrMaxAttr = attr;
          break;
        case "ds_rx_max":
          dsRxMaxAttr = attr;
          break;
        case "ds_rx_min":
          dsRxMinAttr = attr;
          break;
        case "ds_rx":
          dsRxAttr = attr;
          break;
        case "ds_snr":
          dsSnrAttr = attr;
          break;
        case "ofdma_tx":
          ofdmaTxAttr = attr;
          break;
        case "ofdma_pct":
          ofdmaPctAttr = attr;
          break;
        case "ofdm_rx_min":
          ofdmRxMinAttr = attr;
          break;
        case "ofdm_rx_max":
          ofdmRxMaxAttr = attr;
          break;
        case "ofdm_pct":
          ofdmPctAttr = attr;
          break;
        case "ofdm_1_pct":
          ofdmPctAttr = attr;
          break;
        case "us_delta_post_cwe_pct":
          usPostCWEDeltaAttr = attr;
          break;
        case "us_delta_pre_cwe_pct":
          usPreCWEDeltaAttr = attr;
          break;

        case "ds_delta_pre_cwe_pct":
          dsPreCWEDeltaAttr = attr;
          break;
        case "ds_delta_post_cwe_pct":
          dsPostCWEDeltaAttr = attr;
          break;

        case "ofdma_delta_post_cwe_pct":
          ofdmaPostCWEDeltaAttr = attr;
          break;
        case "ofdm_delta_post_cwe_pct":
          ofdmPostCWEDeltaAttr = attr;
          break;
      }
    });
    return {
      usTxSpecInfo: usTxAttr?.specInfo,
      usSnrSpecInfo: usSnrAttr?.specInfo,
      usTxMinSpecInfo: usTxMinAttr?.specInfo,
      usTxMaxSpecInfo: usTxMaxAttr?.specInfo,
      usSnrMaxSpecInfo: usSnrMaxAttr?.specInfo,
      usSnrMinSpecInfo: usSnrMinAttr?.specInfo,
      dsSnrMinSpecInfo: dsSnrMinAttr?.specInfo,
      dsSnrMaxSpecInfo: dsSnrMaxAttr?.specInfo,
      dsRxMinSpecInfo: dsRxMinAttr?.specInfo,
      dsRxMaxSpecInfo: dsRxMaxAttr?.specInfo,
      dsRxSpecInfo: dsRxAttr?.specInfo,
      dsSnrSpecInfo: dsSnrAttr?.specInfo,
      ofdmaTxSpecInfo: ofdmaTxAttr?.specInfo,
      ofdmaPctSpecInfo: ofdmaPctAttr?.specInfo,
      ofdmRxMinSpecInfo: ofdmRxMinAttr?.specInfo,
      ofdmRxMaxSpecInfo: ofdmRxMaxAttr?.specInfo,
      ofdmPctSpecInfo: ofdmPctAttr?.specInfo,
      usPostCWEDeltaSpecInfo: usPostCWEDeltaAttr?.specInfo,
      usPreCWEDeltaSpecInfo: usPreCWEDeltaAttr?.specInfo,
      dsPostCWEDeltaSpecInfo: dsPostCWEDeltaAttr?.specInfo,
      dsPreCWEDeltaSpecInfo: dsPreCWEDeltaAttr?.specInfo,

      ofdmaPostCWEDeltaSpecInfo: ofdmaPostCWEDeltaAttr?.specInfo,
      ofdmPostCWEDeltaSpecInfo: ofdmPostCWEDeltaAttr?.specInfo,
    };
  };

  let tableData = useMemo(
    () =>
      nodeDevicesList
        ?.map((e: any) => {
          let {
            usTxSpecInfo,
            usSnrSpecInfo,
            usTxMinSpecInfo,
            usTxMaxSpecInfo,
            usSnrMaxSpecInfo,
            usSnrMinSpecInfo,
            dsSnrMinSpecInfo,
            dsSnrMaxSpecInfo,
            dsRxMinSpecInfo,
            dsRxMaxSpecInfo,
            dsRxSpecInfo,
            dsSnrSpecInfo,
            ofdmaTxSpecInfo,
            ofdmaPctSpecInfo,
            ofdmRxMinSpecInfo,
            ofdmRxMaxSpecInfo,
            ofdmPctSpecInfo,
            usPostCWEDeltaSpecInfo,
            usPreCWEDeltaSpecInfo,
            dsPostCWEDeltaSpecInfo,
            dsPreCWEDeltaSpecInfo,
            ofdmaPostCWEDeltaSpecInfo,
            ofdmPostCWEDeltaSpecInfo,
          } = getSpecInfoForAttributes(tableColFields);
          const summary = e.deviceDiag?.data?.summary;
          const docsis = e.deviceDiag?.data?.docsis;
          const flaps = e.deviceDiag?.data?.flaps;
          const codewords = e.deviceDiag?.data?.codewords;
          const d30US = docsis?.docsis30?.upstream;
          const d30DS = docsis?.docsis30?.downstream;
          let usMinTxPower = getSpecValue(d30US?.minTxPower, usTxMinSpecInfo);
          let usMaxTxPower = getSpecValue(d30US?.maxTxPower, usTxMaxSpecInfo);
          let usMaxSnr = getSpecValue(d30US?.maxSnr, usSnrMaxSpecInfo);
          let usMinSnr = getSpecValue(d30US?.minSnr, usSnrMinSpecInfo);
          let dsMinRxPower = getSpecValue(d30DS?.minRxPower, dsRxMinSpecInfo);
          let dsMaxRxPower = getSpecValue(d30DS?.maxRxPower, dsRxMaxSpecInfo);
          let dsSnrMax = getSpecValue(d30DS?.maxSnr, dsSnrMaxSpecInfo);
          let dsSnrMin = getSpecValue(d30DS?.minSnr, dsSnrMinSpecInfo);
          
          let usChannels = getSpecChannels(
            d30US?.channels,
            usTxSpecInfo,
            "txPower"
          );
          usChannels = getSpecChannels(usChannels, usSnrSpecInfo, "snr");
          let codewordsUs = getSpecChannels(
            codewords?.upstreamChannels,
            usPreCWEDeltaSpecInfo,
            "preCWEDelta"
          );
          codewordsUs = getSpecChannels(
            codewordsUs,
            usPostCWEDeltaSpecInfo,
            "postCWEDelta"
          );
          usChannels = getDocsisCodewordsObjScQam(
            usChannels,
            codewordsUs
          ).finalDocsis;
          codewordsUs = getDocsisCodewordsObjScQam(
            usChannels,
            codewordsUs
          ).finalcodewords;
          //let dsChannels = d30DS?.channels;

          let dsChannels = getSpecChannels(
            d30DS?.channels,
            dsRxSpecInfo,
            "rx_power"
          );
          dsChannels = getSpecChannels(dsChannels, dsSnrSpecInfo, "snr");

          let codewordsDs = getSpecChannels(
            codewords?.downstreamChannels,
            dsPreCWEDeltaSpecInfo,
            "preCWEDelta"
          );
          codewordsDs = getSpecChannels(
            codewordsDs,
            dsPostCWEDeltaSpecInfo,
            "postCWEDelta"
          );
          dsChannels = getDocsisCodewordsObjScQam(
            dsChannels,
            codewordsDs
          ).finalDocsis;
          codewordsDs = getDocsisCodewordsObjScQam(
            dsChannels,
            codewordsDs
          ).finalcodewords;
          const d31USChannel0 = docsis?.ofdm?.upstream?.channels?.[0];
          let ofdmaTx = getSpecValue(d31USChannel0?.txPower, ofdmaTxSpecInfo);
          let ofdmaPct = getSpecValue(
            d31USChannel0?.mer?.thrVal,
            ofdmaPctSpecInfo
          );
          const codewordsOfdma = getSpecChannels(
            codewords?.ofdmaUsChannels?.[0]?.iucList,
            ofdmaPostCWEDeltaSpecInfo,
            "postCWEDelta"
          );
          //const d31DSChannels = docsis?.ofdm?.downstreamChannels;
          let d31DSChannels = getSpecChannels(
            docsis?.ofdm?.downstreamChannels,
            ofdmPctSpecInfo,
            "mer.thrVal"
          );
          d31DSChannels = getSpecChannels(
            d31DSChannels,
            ofdmRxMaxSpecInfo,
            "maxRxPower"
          );
          d31DSChannels = getSpecChannels(
            d31DSChannels,
            ofdmRxMinSpecInfo,
            "minRxPower"
          );
          let codewordsOfdm = getOfdmSpecChannels(
            codewords?.ofdmDsChannels,
            ofdmPostCWEDeltaSpecInfo,
            "postCWEDelta"
          );
          // d31DSChannels = getDocsisCodewordsObjScQam(
          //   d31DSChannels,
          //   codewordsOfdm
          // ).finalDocsis;
          // codewordsOfdm = getDocsisCodewordsObjScQam(
          //   d31DSChannels,
          //   codewordsOfdm
          // ).finalcodewords;

          const codewordsTimeDelta = codewords?.timeDelta;
          const node= (docsis?.docsis30?.upstream?.connectedNode);
          const cmUptime = summary?.uptime;
          let  ofdmaChannel=docsis?.ofdm?.upstream?.channels;
          const cmtsOnlineStat = summary?.regStatus?.cmtsStatus;
          const regStatusHeader = summary?.regStatus?.header;
          let ofdm_Channel_ID= getProfilesValueFromMap(d31DSChannels, '', "\n");
          let ofdma_Channel_ID= getOFDMAProfilesValueFromMap(ofdmaChannel, '', "\n");
          const cmtsRegTime = summary?.cmtsCmRegisteredTime;
          if (
            docsis === undefined ||
            (getMultivaluedSpecFlag({
              usChannels,
              dsChannels,
              d31DSChannels,
              codewordsUs,
              codewordsDs,
              codewordsOfdma,
              codewordsOfdm,
            }) &&
              getSinglevaluedSpecFlag({
                usMinTxPower,
                usMaxTxPower,
                usMaxSnr,
                usMinSnr,
                dsMinRxPower,
                dsMaxRxPower,
                dsSnrMax,
                dsSnrMin,
                ofdmaTx,
                ofdmaPct,
              }))
          )
            return {
              apiStatus: {
                isLoading: e.isLoading,
                error: { ...e.deviceDiag?.data },
                status: e.deviceDiag?.data?.summary?.errorCode ? "ERROR" : "OK",
              },
              summary: summary,
              docsis: docsis,
              flaps: flaps,
              codewords: codewords,
              address: getTelemetryHomeAddress(e.serviceAddress),
              serviceAddress: e.serviceAddress,
              smt: e.serviceArea,
              cmMac: e?.macAddress,
              model: e?.model,
              ofdma_Channel_ID:ofdma_Channel_ID,
              ofdm_Channel_ID:ofdm_Channel_ID,
              cmUptime:
                cmUptime > 0
                  ? calculateCMUptime(cmtsOnlineStat, regStatusHeader, cmUptime)
                  : "", // null and undefined cases are handled by > operator
              cmtsUptime: isCMOperational(cmtsOnlineStat, cmtsRegTime), //Based on cmtsStatus, returns blank or cmtsUptime
              cmtsCmOnlineStatus: summary?.regStatus?.cmtsStatus,
              certStatus: summary?.certStatus,
              certRxStatus: d30DS?.certRxStatus,
              certDSSnrStatus: d30DS?.certDSSnrStatus,
              certTxStatus: d30US?.certTxStatus,
              certUSSnrStatus: d30US?.certUSSnrStatus,
              certCMTSRxStatus: d30US?.certCMTSRxStatus,
              certUSPreCerStatus: codewords?.certUSPreCerStatus,
              certUSPostCCerStatus: codewords?.certUSPostCCerStatus,
              onlineTimes: summary?.onlineTimes,
              onlinePercentage: summary?.onlinePercentage,
              node: node,
              mgmtIP:
                summary?.mgmtIP !== undefined && summary?.mgmtIP !== ""
                  ? summary?.mgmtIP
                  : summary?.mgmtIPv6,
              usInterface: d30US?.name,
              ofdmaUsInterface: d31USChannel0?.name,
              usInterfaceDisplay: usInfterfaceDisplay(
                d30US?.name,
                nodeInfo?.phub,
                summary?.cmtsIP
              ),

              flapsCount: flaps?.counts,
              insertionFails: flaps?.insertionFails,
              hits: flaps?.hits,
              miss: flaps?.miss,
              misses: (flaps?.miss !== undefined && flaps?.hits !== undefined) ? 
              ((flaps.hits + flaps.miss > 0 || flaps.hits < 0 || flaps.miss < 0) ? (Math.floor((flaps?.miss / (flaps?.hits + flaps?.miss)) * 1000) / 10).toFixed(1): 0) : 
              "",
              powerAdjustments: flaps?.powerAdjustments,
              createTime: changeTimeFormat(flaps?.createTime),
              lastFlapTime: changeTimeFormat(flaps?.lastFlapTime),

              ds31PartialSvcState: getPartialServiceFlag(
                docsis?.partialService?.d31PartialSvcState,
                "DS"
              ),
              us31PartialSvcState: getPartialServiceFlag(
                docsis?.partialService?.d31PartialSvcState,
                "US"
              ),

              usTxPowerTotal: d30US?.usTxPowerTotal,

              usMinTxPower: usMinTxPower,
              usMinTxPowerDisplay: renderSpecColor(
                d30US?.minTxPower?.status,
                usMinTxPower
              ),
              //usMinTxPowerStatus: d30US?.minTxPower?.status,
              // usMinTxThreshold: d30US?.minTxPower?.threshold?.pass,
              usMaxTxPower: usMaxTxPower,
              usMaxTxPowerDisplay: renderSpecColor(
                d30US?.maxTxPower?.status,
                usMaxTxPower
              ),
              //usMaxTxPowerStatus: d30US?.maxTxPower?.status,
              //usMaxTxPowerThreshold: d30US?.maxTxPower?.threshold?.pass,
              usTxPowerDelta: d30US?.txPowerDelta,

              usMinSnr: usMinSnr,
              usMinSnrDisplay: renderSpecColor(d30US?.minSnr?.status, usMinSnr),
              //usMinSnrStatus: d30US?.minSnr?.status,
              //usMinSnrThreshold: d30US?.minSnr?.threshold?.pass,
              //minSnrValue: getThresholdFromMap(d30US?.minSnr), //taking entire object instead of only value
              usMaxSnr: usMaxSnr,
              usMaxSnrDisplay: renderSpecColor(d30US?.maxSnr?.status, usMaxSnr),
              //usMaxSnrStatus: d30US?.maxSnr?.status,
              //usMaxSnrThreshold: d30US?.maxSnr?.threshold?.pass,
              // usMaxSnr1: getThresholdFromMap(d30US?.maxSnr), //taking entire object instead of only value

              usSNRDelta: d30US?.snrDelta,

              usFreq: getValueFromMap(usChannels, "frequency"),
              // usTx: getValueFromMapSpec(
              //   usChannels,
              //   "txPower.value",
              //   "txPower.status",
              //   usTxSpec
              // ),
              usTxDisplay: getValueColorFromMap(
                usChannels,
                "txPower.value",
                "txPower.status"
              ),
              //usTx: getValueFromMap(usChannels, "txPower.value"),
              usTx: getValueFromMap(usChannels, "txPower.value"),

              usSnrDisplay: getValueColorFromMap(
                usChannels,
                "snr.value",
                "snr.status"
              ),
              usSnr: getValueFromMap(usChannels, "snr.value"),
              cmtsRx: getValueFromMap(usChannels, "cmtsRxPower.value"),
              minRxPower: dsMinRxPower,
              minRxPowerDisplay: renderSpecColor(
                d30DS?.minRxPower?.status,
                dsMinRxPower
              ),
              //minRxPowerStatus: d30DS?.minRxPower?.status,
              //minRxPowerThreshold: d30DS?.minRxPower?.threshold?.pass,
              maxRxPower: dsMaxRxPower,
              maxRxPowerDisplay: renderSpecColor(
                d30DS?.maxRxPower?.status,
                dsMaxRxPower
              ),
              //maxRxPowerStatus: d30DS?.maxRxPower?.status,
              //maxRxPowerThreshold: d30DS?.maxRxPower?.threshold?.pass,

              rxPowerDelta: d30DS?.rxPowerDelta,

              dsMinSnr: dsSnrMin,
              dsMinSnrDisplay: renderSpecColor(d30DS?.minSnr?.status, dsSnrMin),
              //dsMinSnrStatus: d30DS?.minSnr?.status,
              //dsMinSnrThreshold: d30DS?.minSnr?.threshold?.pass,
              dsMaxSnr: dsSnrMax,
              dsMaxSnrDisplay: renderSpecColor(d30DS?.maxSnr?.status, dsSnrMax),
              //dsMaxSnrStatus: d30DS?.maxSnr?.status,
              //dsMaxSnrThreshold: d30DS?.maxSnr?.threshold?.pass,

              dsSnrDeltaValue: d30DS?.snrDelta,

              dsFreq: getValueFromMap(dsChannels, "frequency"),

              dsRx: getValueFromMap(dsChannels, "rx_power.value")
                ? "`" + getValueFromMap(dsChannels, "rx_power.value")
                : "",
              dsRxDisplay: getValueColorFromMap(
                dsChannels,
                "rx_power.value",
                "rx_power.status"
              ),
              dsSnr: getValueFromMap(dsChannels, "snr.value"),
              dsSnrDisplay: getValueColorFromMap(
                dsChannels,
                "snr.value",
                "snr.status"
              ),

              txPowerValue: ofdmaTx,
              txPowerValueDisplay: renderSpecColor(
                d31USChannel0?.txPower?.status,
                ofdmaTx
              ),
              merMean: d31USChannel0?.mer?.mean,
              merStdDev: d31USChannel0?.mer?.stdDev,
              merThrValValue: ofdmaPct,
              merThrValValueDisplay: renderSpecColor(
                d31USChannel0?.mer?.thrVal?.status,
                ofdmaPct
              ),

              usChannelStatus: d31USChannel0?.status?.value,

              ofdmChannelID: getValueFromMap(d31DSChannels, "channelId", " / "),
              dsMinRxPowerValue: getValueFromMap(
                d31DSChannels,
                "minRxPower.value",
                " / "
              )
                ? "`" +
                  getValueFromMap(d31DSChannels, "minRxPower.value", " / ")
                : "",
              dsMinRxPowerValueDisplay: getOFDMValueColorFromMap(
                d31DSChannels,
                "minRxPower.value",
                "minRxPower.status"
              ),
              dsMaxRxPowerValue: getValueFromMap(
                d31DSChannels,
                "maxRxPower.value",
                " / "
              )
                ? "`" +
                  getValueFromMap(d31DSChannels, "maxRxPower.value", " / ")
                : "",
              dsMaxRxPowerValueDisplay: getOFDMValueColorFromMap(
                d31DSChannels,
                "maxRxPower.value",
                "maxRxPower.status"
              ),
              dsRxPowerDelta: getValueFromMap(
                d31DSChannels,
                "rxPowerDelta",
                " / "
              )
                ? getValueFromMap(d31DSChannels, "rxPowerDelta", " / ")
                : "",
              dsMerMean: getValueFromMap(d31DSChannels, "mer.mean", " / ")
                ? getValueFromMap(d31DSChannels, "mer.mean", " / ")
                : "",
              dsMerStdDev:
                getValueFromMap(d31DSChannels, "mer.stdDev", " / ") &&
                getValueFromMap(d31DSChannels, "mer.stdDev", " / "),

              dsThrValValue:
                getValueFromMap(d31DSChannels, "mer.thrVal.value", " / ") &&
                getValueFromMap(d31DSChannels, "mer.thrVal.value", " / "),
              dsThrValValueDisplay: getOFDMValueColorFromMap(
                d31DSChannels,
                "mer.thrVal.value",
                "mer.thrVal.status"
              ),

              dsStatusValue: d31DSChannels?.status?.value,

              dsMinRxPowerValue1: getOfdmDocsisValueFromMap(
                d31DSChannels,
                "minRxPower.value",
                codewordsOfdm
              ),
              dsMinRxPowerValue1Display: getOfdmDocsisColorFromMap(
                d31DSChannels,
                "minRxPower.value",
                "minRxPower.status",
                codewordsOfdm
              ),
              //getValueFromMap(d31DSChannels, "minRxPower.value"),
              dsMaxRxPowerValue1: getOfdmDocsisValueFromMap(
                d31DSChannels,
                "maxRxPower.value",
                codewordsOfdm
              ),
              dsMaxRxPowerValue1Display: getOfdmDocsisColorFromMap(
                d31DSChannels,
                "maxRxPower.value",
                "maxRxPower.status",
                codewordsOfdm
              ),

              dsRxPowerDelta1: getOfdmDocsisValueFromMap(
                d31DSChannels,
                "rxPowerDelta",
                codewordsOfdm
              ),
              dsMerMean1: getOfdmDocsisValueFromMap(
                d31DSChannels,
                "mer.mean",
                codewordsOfdm
              ),
              dsMerStdDev1: getOfdmDocsisValueFromMap(
                d31DSChannels,
                "mer.stdDev",
                codewordsOfdm
              ),
              dsThrValValue1: getOfdmDocsisValueFromMap(
                d31DSChannels,
                "mer.thrVal.value",
                codewordsOfdm
              ),
              dsThrValValue1Display: getOfdmDocsisColorFromMap(
                d31DSChannels,
                "mer.thrVal.value",
                "mer.thrVal.status",
                codewordsOfdm
              ),

              //codewords
              codewordsTimeDelta: codewordsTimeDelta,
              usTotalCwDelta: getValueFromMap(codewordsUs, "totalCwDelta"),
              usPreCWEPer: getValueFromMap(codewordsUs, "preCWEPer"),
              usPostCWEPer: getValueFromMap(codewordsUs, "postCWEPer"),

              usPreCWEDelta: getValueFromMap(codewordsUs, "preCWEDelta.value"),

              usPreCWEDeltaDisplay: getValueColorFromMap(
                codewordsUs,
                "preCWEDelta.value",
                "preCWEDelta.status"
              ),

              usPostCWEDelta: getValueFromMap(
                codewordsUs,
                "postCWEDelta.value"
              ),

              usPostCWEDeltaDisplay: getValueColorFromMap(
                codewordsUs,
                "postCWEDelta.value",
                "postCWEDelta.status"
              ),

              dsTotalCwDelta: getValueFromMap(codewordsDs, "totalCwDelta"),
              dsPreCWEPer: getValueFromMap(codewordsDs, "preCWEPer"),
              dsPostCWEPer: getValueFromMap(codewordsDs, "postCWEPer"),
              dsPreCWEDelta: getValueFromMap(codewordsDs, "preCWEDelta.value"),
              dsPreCWEDeltaDisplay: getValueColorFromMap(
                codewordsDs,
                "preCWEDelta.value",
                "preCWEDelta.status"
              ),

              // ODM attribute mapping fields

              odmCertStatus: getOdmCertStatus(
                getCmScore(docsis, codewords),
                docsis
              ),
              ofdmaChannelIdOdm: getOdmRangeColorFromMap(
                codewordsOfdma,
                "channelID",
                ""
              ),

              txPowerValueDisplayOdm: getOdmRangeColorFromMap(
                docsis?.ofdm?.upstream?.channels,
                "txPower.value",
                "txPower.color"
              ),

              merThrValValueDisplayOdm: getOdmRangeColorFromMap(
                docsis?.ofdm?.upstream?.channels,
                "mer.thrVal.value",
                "mer.thrVal.color"
              ),

              ofdmChannelIdOdm: getOdmOfdmCWValFromMap(
                codewordsOfdm,
                "channelID",
                d31DSChannels
              ),

              dsRxPowerDelta1Odm: getOdmOfdmDocsisValFromMap(
                d31DSChannels,
                "rxPowerDelta",
                codewordsOfdm,
                ""
              ),

              dsThrValValue1Odm: getOdmOfdmDocsisValFromMap(
                d31DSChannels,
                "mer.thrVal.value",
                codewordsOfdm,
                "mer.thrVal.color"
              ),

              ofdmPostCWEDeltaDisplayOdm: getOdmOfdmValueColorFromMap(
                codewordsOfdm,
                "postCWEDelta.value",
                "postCWEDelta.status"
              ),

              usFreqOdm: getOdmRangeColorFromMap(usChannels, "frequency", ""),
              usTxOdm: getOdmRangeColorFromMap(
                usChannels,
                "txPower.value",
                "txPower.color"
              ),
              usSnrOdm: getOdmRangeColorFromMap(
                usChannels,
                "snr.value",
                "snr.color"
              ),
              usPostCWEDeltaOdm: getOdmRangeColorFromMap(
                codewordsUs,
                "postCWEDelta.value",
                "postCWEDelta.color"
              ),

              dsRxOdm: getOdmRangeColorFromMap(
                dsChannels,
                "rx_power.value",
                "rx_power.color"
              ),
              dsSnrOdm: getOdmRangeColorFromMap(
                dsChannels,
                "snr.value",
                "snr.color"
              ),
              dsPostCWEDeltaOdm: getOdmRangeColorFromMap(
                codewords?.downstreamChannels,
                "postCWEDelta.value",
                "postCWEDelta.color"
              ),

              // dsPostCWEDeltaOdm: getValueFromMap(
              //   codewords?.downstreamChannels,
              //   "postCWEDelta.value"
              // ),

              cmOdmScore: getCmScore(docsis, codewords)?.toString(),
              ofdmaOdmScore: getOfdmaScore(docsis)?.toString(),
              usOdmScore: getUsOdmScore(docsis, codewords)?.toString(),
              dsOdmScore: getDsOdmScore(docsis, codewords)?.toString(),
              ofdmOdmScore: getOfdmScore(docsis)?.toString(),

              dsPostCWEDelta: getValueFromMap(
                codewordsDs,
                "postCWEDelta.value"
              ),
              dsPostCWEDeltaDisplay: getValueColorFromMap(
                codewordsDs,
                "postCWEDelta.value",
                "postCWEDelta.status"
              ),

              ofdmaChannelId: getValueFromMap(codewordsOfdma, "channelID"),
              ofdmaTotalCwDelta: getValueFromMap(
                codewordsOfdma,
                "totalCwDelta"
              ),
              ofdmaPreCWEPer: getValueFromMap(codewordsOfdma, "preCWEPer"),
              ofdmaPostCWEPer: getValueFromMap(codewordsOfdma, "postCWEPer"),
              ofdmaPreCWEDelta: getValueFromMap(codewordsOfdma, "preCWEDelta"),
              ofdmaPostCWEDelta: getValueFromMap(
                codewordsOfdma,
                "postCWEDelta.value"
              ),
              ofdmaPostCWEDeltaDisplay: getValueColorFromMap(
                codewordsOfdma,
                "postCWEDelta.value",
                "postCWEDelta.status"
              ),

              ofdmChannelId: getOfdmCodewordsValueFromMap(
                codewordsOfdm,
                "channelID",
                d31DSChannels
              ),
              ofdmTotalCwDelta: getOfdmCodewordsValueFromMap(
                codewordsOfdm,
                "totalCwDelta",
                d31DSChannels
              ),
              ofdmPreCWEPer: getOfdmCodewordsValueFromMap(
                codewordsOfdm,
                "preCWEPer",
                d31DSChannels
              ),
              ofdmPostCWEPer: getOfdmCodewordsValueFromMap(
                codewordsOfdm,
                "postCWEPer",
                d31DSChannels
              ),
              ofdmPreCWEDelta: getOfdmCodewordsValueFromMap(
                codewordsOfdm,
                "preCWEDelta",
                d31DSChannels
              ),

              ofdmPostCWEDelta: getOfdmCodewordsValueFromMap(
                codewordsOfdm,
                "postCWEDelta.value",
                d31DSChannels
              ),
              ofdmPostCWEDeltaDisplay: getOfdmValueColorFromMap(
                codewordsOfdm,
                "postCWEDelta.value",
                "postCWEDelta.status"
              ),
            };
        })
        .filter((item: any) => item),
    [nodeDevicesList, tableColFields]
  );
  return {
    tableData,
  };
};

export default TelemetryDataMapping;
