import { InfoBox } from "@react-google-maps/api";
import React from "react";
import MduEOLBlock from "./MduEOLBlock";
import MduAmpBlock from "./MduAmpBlock";
import MduNodeBlock from "./MduNodeBlock";
import MduOtherAnnotationBlocks from "./MduOtherAnnotationBlocks";
import { zoomLevelRenderAnnotationBlock } from "../../../../../config/mapConfig";
interface Props {
  mduAnnotationBlockData?: any;
  zoomLevel?: any;
}

const MduAnnotationBlock = React.memo(
  ({ mduAnnotationBlockData, zoomLevel }: Props) => {
    return (
      <>
        {zoomLevel >= zoomLevelRenderAnnotationBlock &&
          mduAnnotationBlockData !== undefined &&
          mduAnnotationBlockData?.features?.map(
            (annotationData: any, index: any) => {
              if (
                annotationData?.properties?.data?.sType === "RF_EOL_BLOCK_MDU"
              ) {
                return <MduEOLBlock eolBlockData={annotationData} />;
              } else if (
                annotationData?.properties?.data?.sType === "RF_AMP_BLOCK_MDU"
              ) {
                return <MduAmpBlock ampBlockData={annotationData} />;
              } else if (
                annotationData?.properties?.data?.sType === "RF_NODE_BLOCK_MDU"
              ) {
                return <MduNodeBlock nodeBlockData={annotationData} />;
              } else if (
                annotationData?.properties?.data?.sType === "SITE_NAME_MDU" ||
                annotationData?.properties?.data?.sType ===
                  "RF_CABLE_ANNO_TYPE_MDU" ||
                annotationData?.properties?.data?.sType ===
                  "MDU_ISP_SYMBOL_ANNO_MDU" ||
                annotationData?.properties?.data?.sType === "CABLE_NAME_MDU" ||
                annotationData?.properties?.data?.sType ===
                  "RF_CABLE_ANNO_MDU" ||
                annotationData?.properties?.data?.sType === "EQUIP_NAME_MDU" ||
                annotationData?.properties?.data?.sType ===
                  "CABLE_SUMMARY_MDU" ||
                annotationData?.properties?.data?.sType ===
                  "CONTINUOUS_SEG_LENGTH_MDU" ||
                annotationData?.properties?.data?.sType === "SEG_LENGTH_MDU"
              ) {
                return (
                  <MduOtherAnnotationBlocks
                    otherAnnotationData={annotationData}
                  />
                );
              } else {
              }
            }
          )}
      </>
    );
  }
);

export default MduAnnotationBlock;
