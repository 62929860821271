import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import ExceptionBox from "../../../../../components/reusable/ExceptionBox";
import { isNull } from "../../../../../components/reusable/Util";
import appConst from "../../../../constants/appConst";
import TopologyLabels from "../../../../constants/topologyLabels";
import "../../../../styles/drawer/PortInfo.scss";

interface Props {
  elementData: any;
}

const PortsListTable = ({ elementData }: Props) => {
  const labels = TopologyLabels();
  let inPort: any;
  let outPort: any;
  elementData?.data?.port?.map((port: any, index: any) => {
    if (port?.portName === appConst?.in) {
      inPort = {
        freq1: port?.freq1,
        freq2: port?.freq2,
        freq3: port?.freq3,
        freq4: port?.freq4,
        freq5: port?.freq5,
        freq6: port?.freq6,
        freq7: port?.freq7,
        freq8: port?.freq8,
        rtnFreq1: port?.rtnFreq1,
        rtnFreq2: port?.rtnFreq2,
        portSequence: port?.portSequence,
        portDirection: port?.portDirection,
        networkId: port?.networkId,
        freq1Name: port?.freq1Name,
        freq2Name: port?.freq2Name,
        freq3Name: port?.freq3Name,
        freq4Name: port?.freq4Name,
        freq5Name: port?.freq5Name,
        freq6Name: port?.freq6Name,
        freq7Name: port?.freq7Name,
        freq8Name: port?.freq8Name,
        rtnFreq1Name: port?.rtnFreq1Name,
        rtnFreq2Name: port?.rtnFreq2Name,
      };
    } else if (port?.portName === "T")
      outPort = {
        freq1: port?.freq1,
        freq2: port?.freq2,
        freq3: port?.freq3,
        freq4: port?.freq4,
        freq5: port?.freq5,
        freq6: port?.freq6,
        freq7: port?.freq7,
        freq8: port?.freq8,
        rtnFreq1: port?.rtnFreq1,
        rtnFreq2: port?.rtnFreq2,
        portSequence: port?.portSequence,
        portDirection: port?.portDirection,
        networkId: port?.networkId,
      };
    else {
    }
  });
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead className="dataTableDetails">
            <TableRow>
              <th style={{ minWidth: 80 }}>{labels.portName?.toUpperCase()}</th>
              <th style={{ minWidth: 100 }}>{appConst?.in}</th>
              <th style={{ minWidth: 100 }}>{appConst?.out}</th>
            </TableRow>
          </TableHead>
          {elementData?.data?.port?.length === 0 ? (
            <TableRow tabIndex={-1} key="exception">
              <TableCell colSpan={6}>
                <div className="diagErrorCont">
                  <ExceptionBox
                    headerText={labels.no_port_info}
                    bodyText={labels.portInfo}
                    primaryBtn={false}
                    secBtn={false}
                  />
                </div>
              </TableCell>
            </TableRow>
          ) : (
            <TableBody className="tableData">
              <>
                <TableRow tabIndex={-1} className="portDataRow">
                  <TableCell>{labels.f1}</TableCell>
                  <TableCell>
                    {inPort?.freq1Name} {appConst.mHz}:
                    {Number(isNull(inPort?.freq1)).toFixed(1)}
                  </TableCell>
                  <TableCell>
                    {inPort?.freq1Name} {appConst.mHz}:
                    {Number(isNull(outPort?.freq1)).toFixed(1)}
                  </TableCell>
                </TableRow>
                <TableRow tabIndex={-1} className="portDataRow">
                  <TableCell>{labels.f2}</TableCell>
                  <TableCell>
                    {inPort?.freq2Name} {appConst.mHz}:
                    {Number(isNull(inPort?.freq2)).toFixed(1)}
                  </TableCell>
                  <TableCell>
                    {inPort?.freq2Name} {appConst.mHz}:
                    {Number(isNull(outPort?.freq2)).toFixed(1)}
                  </TableCell>
                </TableRow>
                <TableRow tabIndex={-1} className="portDataRow">
                  <TableCell>{labels.f3}</TableCell>
                  <TableCell>
                    {inPort?.freq3Name} {appConst.mHz}:
                    {Number(isNull(inPort?.freq3)).toFixed(1)}
                  </TableCell>
                  <TableCell>
                    {inPort?.freq3Name} {appConst.mHz}:
                    {Number(isNull(outPort?.freq3)).toFixed(1)}
                  </TableCell>
                </TableRow>
                <TableRow tabIndex={-1} className="portDataRow">
                  <TableCell>{labels.f4}</TableCell>
                  <TableCell>
                    {inPort?.freq4Name} {appConst.mHz}:
                    {Number(isNull(inPort?.freq4)).toFixed(1)}
                  </TableCell>
                  <TableCell>
                    {inPort?.freq4Name} {appConst.mHz}:
                    {Number(isNull(outPort?.freq4)).toFixed(1)}
                  </TableCell>
                </TableRow>
                <TableRow tabIndex={-1} className="portDataRow">
                  <TableCell>{labels.f5}</TableCell>
                  <TableCell>
                    {inPort?.freq5Name} {appConst.mHz}:
                    {Number(isNull(inPort?.freq5)).toFixed(1)}
                  </TableCell>
                  <TableCell>
                    {inPort?.freq5Name} {appConst.mHz}:
                    {Number(isNull(outPort?.freq5)).toFixed(1)}
                  </TableCell>
                </TableRow>
                <TableRow tabIndex={-1} className="portDataRow">
                  <TableCell>{labels.f6}</TableCell>
                  <TableCell>
                    {inPort?.freq6Name} {appConst.mHz}:
                    {Number(isNull(inPort?.freq6)).toFixed(1)}
                  </TableCell>
                  <TableCell>
                    {inPort?.freq6Name} {appConst.mHz}:
                    {Number(isNull(outPort?.freq6)).toFixed(1)}
                  </TableCell>
                </TableRow>
                <TableRow tabIndex={-1} className="portDataRow">
                  <TableCell>{labels.f7}</TableCell>
                  <TableCell>
                    {inPort?.freq7Name} {appConst.mHz}:
                    {Number(isNull(inPort?.freq7)).toFixed(1)}
                  </TableCell>
                  <TableCell>
                    {inPort?.freq7Name} {appConst.mHz}:
                    {Number(isNull(outPort?.freq7)).toFixed(1)}
                  </TableCell>
                </TableRow>
                <TableRow tabIndex={-1} className="portDataRow">
                  <TableCell>{labels.f8}</TableCell>
                  <TableCell>
                    {inPort?.freq8Name} {appConst.mHz}:
                    {Number(isNull(inPort?.freq8)).toFixed(1)}
                  </TableCell>
                  <TableCell>
                    {inPort?.freq8Name} {appConst.mHz}:
                    {Number(isNull(outPort?.freq8)).toFixed(1)}
                  </TableCell>
                </TableRow>
                <TableRow tabIndex={-1} className="portDataRow">
                  <TableCell>{labels.r1}</TableCell>
                  <TableCell>
                    {inPort?.rtnFreq1Name} {appConst.mHz}:
                    {Number(isNull(inPort?.rtnFreq1)).toFixed(1)}
                  </TableCell>
                  <TableCell>
                    {inPort?.rtnFreq1Name} {appConst.mHz}:
                    {Number(isNull(outPort?.rtnFreq1)).toFixed(1)}
                  </TableCell>
                </TableRow>
                <TableRow tabIndex={-1} className="portDataRow">
                  <TableCell>{labels.r2}</TableCell>
                  <TableCell>
                    {inPort?.rtnFreq2Name} {appConst.mHz}:
                    {Number(isNull(inPort?.rtnFreq2)).toFixed(1)}
                  </TableCell>
                  <TableCell>
                    {inPort?.rtnFreq2Name} {appConst.mHz}:
                    {Number(isNull(outPort?.rtnFreq2)).toFixed(1)}
                  </TableCell>
                </TableRow>
                <TableRow tabIndex={-1} className="portDataRow">
                  <TableCell>{labels.portDirection.toUpperCase()}</TableCell>
                  <TableCell>{isNull(inPort?.portDirection)}</TableCell>
                  <TableCell>{isNull(outPort?.portDirection)}</TableCell>
                </TableRow>
                <TableRow tabIndex={-1} className="portDataRow">
                  <TableCell>{labels.portSequence.toUpperCase()}</TableCell>
                  <TableCell>{isNull(inPort?.portSequence)}</TableCell>
                  <TableCell>{isNull(outPort?.portSequence)}</TableCell>
                </TableRow>
                <TableRow tabIndex={-1} className="portDataRow">
                  <TableCell>{labels.networkID.toUpperCase()}</TableCell>
                  <TableCell>{isNull(inPort?.networkId)}</TableCell>
                  <TableCell>{isNull(outPort?.networkId)}</TableCell>
                </TableRow>
              </>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default PortsListTable;
