import React, { useEffect, useState } from "react";
import { Divider } from "@material-ui/core";
import { Dropdown, DropdownButton, Form } from "react-bootstrap";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import "../../../../design-tokens/forms/SR.scss";
import AppLabels from "../../../../constants/App_Labels";
import ImpactedServices from "../../../../data/forms/SR/ImactedServices";
import {
  GetFormHistory,
  GetActiveAccountDetails,
} from "../../../../get-api/GetResponse";
import { isNumeric, validateEmail } from "../../../reusable/Util";
import RedAsterisk from "../../../reusable/RedAsterisk";
import InlineErrorMessage from "../../../reusable/InlineErrorMessage";
import srFormLabels from "../srFormLabels";

interface Props {
  setDetails: any;
}

export default function TechnicianDetailsScreen({ setDetails }: Props) {
  const labels = AppLabels();
  const srLabels = srFormLabels();
  const formData = GetFormHistory()?.formData;
  const timeWindowsData = [labels.TW1, labels.TW2, labels.TW3, labels.TW4];

  const customerData = GetActiveAccountDetails();
  const [impactedServices, setImpactedServices] = useState(ImpactedServices());
  const [tapDetails, setTAPDetails] = useState("");
  const [cseDetails, setCSEDetails] = useState("");
  const [humDetails, setHUMDetails] = useState("");
  const [snrDetails, setSNRDetails] = useState("");
  const [referredSeniorTechID, setReferredSeniorTechID] = useState("");
  const [seniorOrderDetails, setSeniorOrderDetails] = useState("");
  const [selectedDate, setSelectedDate] = useState<any>(new Date());
  const [selectedTimeWindows, setSelectedTimeWindows] = useState(labels.TW1);
  const [seniorTechnician, setSeniorTechnician] = useState("");
  const [techManager, setTechManager] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // for invalid email

  let homeNum: any[] = [];

  customerData?.selectedSA?.subscription?.map((sub: any) => {
    if (sub?.hasOwnProperty("deviceInfo")) {
      sub.deviceInfo?.map((deviceInfo: any) => {
        if (
          deviceInfo?.deviceCategory === "EMTA" ||
          deviceInfo?.deviceCategory === "IEMTA"
        )
          if (deviceInfo?.hasOwnProperty("phoneNumber")) {
            homeNum.push(deviceInfo.phoneNumber);
          }
      });
    }
  });

  const [customerName, setCustomerName] = useState(
    customerData?.data?.firstName + " " + customerData?.data?.lastName
  );
  const [contactNumber, setContactNumber] = useState(homeNum[0]);

  const textFieldItems = [
    {
      label: labels.TAP,
      value: tapDetails,
      onTxtChange: setTAPDetails,
    },
    {
      label: labels.CSE,
      value: cseDetails,
      onTxtChange: setCSEDetails,
    },
    {
      label: labels.HUM,
      value: humDetails,
      onTxtChange: setHUMDetails,
    },
    {
      label: labels.SNR.toUpperCase(),
      value: snrDetails,
      onTxtChange: setSNRDetails,
    },
  ];
  const technicianItems = [
    {
      label: srLabels.SENIOR_TECHNICIAN_EMAIL,
      value: seniorTechnician,
      onTxtChange: setSeniorTechnician,
    },
    {
      label: srLabels.TECHNICIAN_MANAGER_EMAIL,
      value: techManager,
      onTxtChange: setTechManager,
    },
  ];

  //set existing api data
  useEffect(() => {
    if (formData && formData !== "") {
      formData?.impactedServices?.map((k: any) =>
        impactedServices?.map(
          (e, i) => e.key === k && (impactedServices[i].active = true)
        )
      );

      setImpactedServices([...impactedServices]);
      setTAPDetails(formData?.tapDetails);
      setCSEDetails(formData?.cseDetails);
      setHUMDetails(formData?.humDetails);
      setSNRDetails(formData?.snrDetails);
      setCustomerName(formData?.customerName);
      setContactNumber(formData?.contactNumber);
      setSeniorTechnician(formData?.seniorTechnician);
      setTechManager(formData?.techManager);
      setReferredSeniorTechID(formData?.referredSeniorTechID);
      setSeniorOrderDetails(formData?.seniorOrderDetails);
      setSelectedDate(formData?.date);
      setSelectedTimeWindows(formData?.timeWindow);
    }
  }, [formData]);

  //generate data
  useEffect(() => {
    const selectedImpactedServices = impactedServices
      ?.map((e) => e.active && e.key)
      ?.filter((item) => item);

    const isDetailsComplete =
      selectedImpactedServices.length > 0 &&
      referredSeniorTechID !== "" &&
      seniorOrderDetails !== "" &&
      validateEmail(techManager) &&
      validateEmail(seniorTechnician);

    if (isDetailsComplete) {
      setDetails({
        impactedServices: selectedImpactedServices,
        tapDetails,
        cseDetails,
        humDetails,
        snrDetails,
        customerName: customerName,
        contactNumber: contactNumber,
        seniorTechnician: seniorTechnician,
        techManager: techManager,
        referredSeniorTechID: referredSeniorTechID,
        seniorOrderDetails,
        date: selectedDate,
        timeWindow: selectedTimeWindows,
      });
    } else setDetails(undefined);
  }, [
    impactedServices,
    tapDetails,
    cseDetails,
    humDetails,
    snrDetails,
    seniorTechnician,
    techManager,
    customerName,
    contactNumber,
    referredSeniorTechID,
    seniorOrderDetails,
    selectedDate,
    selectedTimeWindows,
  ]);

  useEffect(() => {
    if (seniorTechnician != "" || techManager != "") {
      let isEmailValid = false;
      for (let i = 0; i < technicianItems.length; i++) {
        isEmailValid = validateEmail(technicianItems[i].value);
        if (!isEmailValid) {
          break;
        }
      }

      if (isEmailValid && errorMessage != "") {
        setErrorMessage("");
      } else if (!isEmailValid && errorMessage != labels.Invalid_Email_Format) {
        setErrorMessage(labels.Invalid_Email_Format);
      }
    }
  }, [techManager, seniorTechnician]);

  const onCheckBoxStateChange = (checked: boolean, index: number) => {
    impactedServices[index].active = checked;
    setImpactedServices([...impactedServices]);
  };

  return (
    <div>
      <label className="titleLabel">
        {labels.Impacted_Services}:
        <RedAsterisk />
      </label>
      {impactedServices?.map((e, i) => (
        <div key={i} className="checkboxContainer">
          <label>{e.label}</label>
          <input
            type="checkbox"
            checked={e.active}
            style={{ width: "25px", height: "25px" }}
            onChange={(event) => onCheckBoxStateChange(event.target.checked, i)}
          />
        </div>
      ))}
      <Divider style={{ margin: "30px 0px 30px 0px" }} />
      <label className="titleLabel">{labels.Customer_Information}:</label>
      <Form.Group style={{ display: "flex" }}>
        <label className="txtFieldLabel" style={{ width: 200 }}>
          {labels.Customer_Name}
        </label>
        <Form.Control
          onChange={(v) => setCustomerName(v.target.value)}
          maxLength={50}
          value={customerName}
        />
      </Form.Group>
      <Form.Group style={{ display: "flex" }}>
        <label className="txtFieldLabel" style={{ width: 200 }}>
          {srLabels.CONTACT_NUM}
        </label>
        <Form.Control
          onChange={(v) => setContactNumber(v.target.value)}
          maxLength={50}
          value={contactNumber}
        />
      </Form.Group>
      <Divider style={{ margin: "30px 0px 30px 0px" }} />
      {textFieldItems.map((e: any, i: number) => (
        <Form.Group key={i}>
          <label className="txtFieldLabel">{e.label}</label>
          <Form.Control
            onChange={(v) => e.onTxtChange(v.target.value)}
            as="textarea"
            rows={1}
            maxLength={50}
            value={e.value}
            placeholder={`${labels.Enter} ${e.label}...`}
          />
        </Form.Group>
      ))}
      <Divider style={{ margin: "30px 0px 30px 0px" }} />
      <label className="titleLabel">{labels.TECH_INFO}:</label>
      <Form.Group>
        <label className="txtFieldLabel">
          {srLabels.Senior_Tech_ID}
          <RedAsterisk />
        </label>
        <Form.Control
          onChange={(v) => setReferredSeniorTechID(isNumeric(v.target.value))}
          as="textarea"
          rows={1}
          maxLength={4}
          value={referredSeniorTechID}
          placeholder={`${labels.Enter} ${srLabels.Senior_Tech_ID}...`}
        />
      </Form.Group>
      {technicianItems.map((e: any, i: number) => (
        <Form.Group>
          <label className="txtFieldLabel">
            {e.label}
            <RedAsterisk />
          </label>
          <Form.Control
            type="email"
            onChange={(v) => e.onTxtChange(v.target.value)}
            className="emailInput"
            maxLength={100}
            value={e.value}
            placeholder={labels.emailPlaceholder}
          />
        </Form.Group>
      ))}
      <InlineErrorMessage
        errorMessage={errorMessage}
        style={{ marginBottom: "5px" }}
      />
      <Divider style={{ margin: "30px 0px 30px 0px" }} />
      <label className="txtFieldLabel">
        {labels.Select_Date_for_Scheduled_Senior_Visit}
        <RedAsterisk />
      </label>
      <div className="datePickerContainer">
        <div className="itemContainer">
          <label className="labelTxt">{"Select Date"}</label>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              value={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              format="yyyy/MM/dd"
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className="itemContainer">
          <label className="labelTxt">{labels.Select_Time_Window}</label>
          <DropdownButton
            title={selectedTimeWindows}
            onSelect={(v: any) => setSelectedTimeWindows(v)}
            size="sm"
            id="dropdownBtn"
          >
            {timeWindowsData.map((e, i) => (
              <Dropdown.Item key={i} eventKey={e}>
                {e}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </div>
      </div>
      <Divider style={{ margin: "30px 0px 30px 0px" }} />
      <Form.Group>
        <label className="txtFieldLabel">
          {labels.Senior_Order_Details}
          <RedAsterisk />: (500 {labels.charsMax})
        </label>
        <Form.Control
          onChange={(e) => setSeniorOrderDetails(e.target.value)}
          as="textarea"
          rows={3}
          maxLength={500}
          value={seniorOrderDetails}
          placeholder={labels.Enter_Senior_Order_Details + "..."}
        />
      </Form.Group>
    </div>
  );
}
