import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../middleware/apiActions";
import { MDU_RF_CABLE } from "../../../middleware/apiEndpoints";
import storeConst from "../../../storeConst";
import { decrApiCall, incrApiCall } from "../mapDetails";

const slice = createSlice({
  name: "mduRFCable",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    getMduRFCableBegin: (mduRFCable) => {
      mduRFCable.isLoading = true;
      mduRFCable.status = "";
    },
    getMduRFCableSuccess: (mduRFCable, action) => {
      mduRFCable.isLoading = false;
      mduRFCable.data = action.payload;
      mduRFCable.status = storeConst.OK;
    },
    getMduRFCableFailure: (mduRFCable, action) => {
      mduRFCable.isLoading = false;
      mduRFCable.error = action.payload;
      mduRFCable.data = undefined;
      mduRFCable.status = storeConst.ERROR;
    },
  },
});

const { getMduRFCableBegin, getMduRFCableSuccess, getMduRFCableFailure } =
  slice.actions;
export default slice.reducer;

export const getMduRFCable = (owningDrawing: any) => (dispatch: any) => {
  dispatch(
    apiCallBegan({
      url: `${MDU_RF_CABLE}/${owningDrawing}`,
      method: "get",
      onStart: getMduRFCableBegin.type,
      onSuccess: getMduRFCableSuccess.type,
      onError: getMduRFCableFailure.type,
      onStartDispatch: incrApiCall.type,
      onSuccessDispatch: decrApiCall.type,
      onErrorDispatch: decrApiCall.type,
    })
  );
};
