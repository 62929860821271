import { createSlice } from "@reduxjs/toolkit";
import { changeTimeFormat } from "../../../components/reusable/Util";
import { apiCallBegan } from "../../../topology/store/middleware/apiActions";

import {
  MultiDeviceAcc,
  NETWORK_SERVICE_URL,
} from "../../../topology/store/middleware/apiEndpoints";
import storeConst from "../../../topology/store/storeConst";
import { convertStreamDataToJson } from "../../../topology/util/utilFuncs";
let multiAcc: any = [];
const batchSize = (window as any).config?.TELEMETRY_BATCH_SIZE ?? 5;

const slice = createSlice({
  name: "multiAcc",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
    startTime: undefined,
    endTime: undefined,
  },
  reducers: {
    getMultipleAccBegin: (tl) => {
      let startTime: any = changeTimeFormat(Date.now());
      tl.status = "";
      tl.error = undefined;
      tl.startTime = startTime;
      tl.isLoading = true;
    },
    getMultipleAccSuccess: (tl, { payload }) => {
      let dataChunkArray = convertStreamDataToJson(payload);

      dataChunkArray.forEach((dataChunk: any) => {
        const accountData = dataChunk.accountSummaryResponse?.data;

        if (accountData && Array.isArray(accountData)) {
          accountData.forEach((item: any) => {
            if ("summary" in item) {
              if (typeof item.summary === "string") {
                try {
                  item.summary = convertStreamDataToJson(item.summary);
                } catch (error) {
                  console.error("Failed to parse summary:", error);
                }
              }
            } else {
              console.log("Summary does not exist in this item:", item);
            }
          });
        }
      });

      tl.isLoading = false;
      tl.data = dataChunkArray;
      let endTime: any = changeTimeFormat(Date.now());
      tl.endTime = endTime;
      tl.status = storeConst.OK;
      tl.error = undefined;
    },

    getMultipleAccFailure: (tl, { payload }) => {
      let endTime: any = changeTimeFormat(Date.now());
      tl.data = undefined;
      tl.isLoading = false;
      tl.endTime = endTime;
      tl.status = storeConst.ERROR;
      tl.error = payload;
    },
  },
});

export const {
  getMultipleAccBegin,
  getMultipleAccSuccess,
  getMultipleAccFailure,
} = slice.actions;

export default slice.reducer;

export const getMultipleAccData = (deviceAcc: any) => (dispatch: any) => {
  dispatch(
    apiCallBegan({
      baseURL: NETWORK_SERVICE_URL + "diag/network",
      url: MultiDeviceAcc,
      method: "post",
      data: deviceAcc,

      responseType: "stream",
      onStart: getMultipleAccBegin.type,
      onSuccess: getMultipleAccSuccess.type,
      onError: getMultipleAccFailure.type,
    })
  );
};
