export default Object.freeze({
  other: "other",
  //initial checks
  familiarTaskSpecificJob: "familiarTaskSpecificJob",
  communicationJobPlan: "communicationJobPlan",
  awarenessApplicableJobProcedure: "awarenessApplicableJobProcedure",
  employeeTrainingUpToDate: "employeeTrainingUpToDate",
  experiencedInTask: "experiencedInTask",
  applicablePPEInspections: "applicablePPEInspections",
  applicableToolsEquipmentInspections: "applicableToolsEquipmentInspections",
  tripSlipFall: "tripSlipFall",
  overheadHazards: "overheadHazards",
  interferences: "interferences",
  electricalHazards: "electricalHazards",
  radioFrequencyEquipment: "radioFrequencyEquipment",
  weatherConcerns: "weatherConcerns",
  publicRoadWays: "publicRoadWays",
  accessEgress: "accessEgress",
  otherHazards: "otherHazards",
  // General PPE
  hardHat: "hardHat",
  safetyFootwear: "safetyFootwear",
  eyeFaceProtection: "eyeFaceProtection",
  classOGloves: "classOGloves",
  cutResistantGloves: "cutResistantGloves",
  highVisibilityApparel: "highVisibilityApparel",
  frRatedClothing: "frRatedClothing",
  fivePointHarness: "fivePointHarness",
  safetyLanyard: "safetyLanyard",
  respiratory: "respiratory",
  rescueDevice: "rescueDevice",
  hearingProtection: "hearingProtection",
  //Comments
  siteConcernsComments: "SiteSpecificSafetyConcerns",
  safetyMeasuresComments: "AdditionalSafetyMeasures",
  safetyEquipmentComments: "AdditionalSafetyEquipment",
  rooftop: "rooftop",
  ladder: "ladder",
  elevatedWorkPlatform: "elevatedWorkPlatform",
  poleClimbing: "poleClimbing",
  otherFallProtectionPlan: "otherFallProtectionPlan",
  travelRestraintSystems: "travelRestraintSystems",
  workPositioningSystem: "workPositioningSystem",
  fallArrestSystem: "fallArrestSystem",
  fallHazardZoneSystem: "fallHazardZoneSystem",
  guardrails: "guardrails",
  strand: "strand",
  permanentAnchorPoint: "permanentAnchorPoint",
  temporarySupportAnchorPoint: "temporarySupportAnchorPoint",
  structuralAnchorPoint: "structuralAnchorPoint",
  closeProximity: "closeProximity",
  electricalWork: "electricalWork",
  exposedHydroLines: "exposedHydroLines",
  otherLiveElectricity: "otherLiveElectricity",
  electricalGloves: "electricalGloves",
  equipmentInspectedInGoodCondition: "equipmentInspectedInGoodCondition",
  distanceRequirements: "distanceRequirements",
  competentPersons: "competentPersons",
  pPEInspected: "pPEInspected",
  lockOutTagOut: "lockOutTagOut",
  customerNotifiedToContactHydro: "customerNotifiedToContactHydro",
  drillingNearElectricalSources: "drillingNearElectricalSources",
  confinedSpaceWork: "confinedSpaceWork",
  otherConfinedSpaceWork: "otherConfinedSpaceWork",
  trafficControls: "trafficControls",
  properManholeGear: "properManholeGear",
  requiredAdditionalTechSupport: "requiredAdditionalTechSupport",
  inspectorsNotified: "inspectorsNotified",
  WorkingFromHeights: "WorkingFromHeights",
  LiveElectricity: "LiveElectricity",
  ConfinedSpace: "ConfinedSpace",
  lowRisk: "LowRisk",
  highRisk: "HighRisk",
});
