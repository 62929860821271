export default function ExternalLinks() {
  return {
    ExternalApplicationList: [
      {
        AppName: `XRAY`,
        Link: `https://xray.xtools.xfinity.tv/`,
      },
      {
        AppName: `TIER 2`,
        Link: `http://oss-cluster1.mgmt.net.cable.rogers.com/tier2/html/Tier2.html`,
      },
      {
        AppName: `F2`,
        Link: `http://snmp-prod-dyas.osn.wireless.rogers.com/graph/mainframeset.html`,
      },
      // {
      //   AppName: `SQV`,
      //   Link: `https://sqv.rogers.com/SQVWebservice/`,
      // },
      {
        AppName: `TECHMPA`,
        Link: `http://techmpa.rogers.com/`,
      },
      {
        AppName: `HITED`,
        Link: `https://maccprdrpaapp01.rci.rogers.ca/HiTed/`,
      },
      {
        AppName: `TAB`,
        Link: `http://technicalsupporttoolzone.rogers.com/techalertsboard/`,
      },
      {
        AppName: `IEC`,
        Link: `http://techmpa.rogers.com/?page_id=15745`,
      },
      {
        AppName: `FSC CHAT`,
        Link: `https://chatapps.rogers.com/secure/ucx-agent-chat/home/field-technician-support`,
      },
      {
        AppName: `FSC Commercial`,
        Link: `https://can01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fforms.office.com%2FPages%2FResponsePage.aspx%3Fid%3Dv8u0CsdLJki1LKFP7VKGuVJHJm_Gmi1Blja0lrtLYqlUMkZHTktYTE1PRDg2NFJQODUwS1VaUFdYWS4u%26wdLOR%3DcE4ABA9A0-1BE4-4485-A9B5-79E9E8851D0D&data=05%7C01%7CMatt.Barbosa%40rci.rogers.com%7Cb82662cbba3448ba903408db20db167d%7C0ab4cbbf4bc74826b52ca14fed5286b9%7C0%7C0%7C638139897562238917%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=x5CuokKqg3OJIrx99YgtV5dMMh%2FIovwCftPamdbh%2B%2FU%3D&reserved=0`,
      },
      {
        AppName: 'Vesta Tool',
        Link: 'https://can01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fvesta.traxion.xfinity.com%2F&data=05%7C01%7CRoland.Leung%40rci.rogers.com%7Ccd5525375d3945bf0b5c08db62ca97f1%7C0ab4cbbf4bc74826b52ca14fed5286b9%7C0%7C0%7C638212394485571296%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=IxvxWcllIntIZuVSM7CkU08V5CMC2wGlGCSVmEuJpaE%3D&reserved=0',
      }
    ],
    knowledgeBaseList: [
      {
        AppName: `HSI`,
        Link: `http://techmpa.rogers.com/?p=23167`,
      },
      {
        AppName: `IHP`,
        Link: `http://techmpa.rogers.com/?p=23159`,
      },
      {
        AppName: `IPTV`,
        Link: `http://techmpa.rogers.com/?p=22851`,
      },
      {
        AppName: `PODS`,
        Link: `http://techmpa.rogers.com/?p=21782`,
      },
      {
        AppName: `HSI LEGACY`,
        Link: `http://techmpa.rogers.com/?p=23165`,
      },
      {
        AppName: `RHP`,
        Link: `http://techmpa.rogers.com/?p=23156`,
      },
      {
        AppName: `STB`,
        Link: `http://techmpa.rogers.com/?p=23163`,
      },
      {
        AppName: `ONT`,
        Link: `http://techmpa.rogers.com/?p=24186`,
      },
      {
        AppName: `EERO`,
        Link: `http://techmpa.rogers.com/?p=17808`,
      },
      {
        AppName: "AXONIFY",
        Link: `https://rogers2.axonify.com`,
      },
    ],
  };
}

export const LINKS = {
  techMPA_MRF: "http://techmpa.rogers.com/?page_id=20789&mode=any&filter_105=",
};
