import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../middleware/apiActions";
import { NETWORKSITE_SEARCH_URI } from "../../middleware/apiEndpoints";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "clamshellSearch",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    getClamshellSearchBegin: (cs) => {
      cs.isLoading = true;
      cs.status = "";
    },
    getClamshellSearchSuccess: (cs, { payload }) => {
      cs.isLoading = false;
      cs.data = payload;
      cs.status = storeConst.OK;
    },
    getClamshellSearchFailure: (cs, { payload }) => {
      cs.isLoading = false;
      cs.error = payload;
      cs.data = undefined;
      cs.status = storeConst.ERROR;
    },
  },
});

const {
  getClamshellSearchBegin,
  getClamshellSearchSuccess,
  getClamshellSearchFailure,
} = slice.actions;
export default slice.reducer;

export const getClamshellSearch =
  (searchedClamshell: string) => (dispatch: any) => {
    dispatch(
      apiCallBegan({
        url: NETWORKSITE_SEARCH_URI + searchedClamshell,
        onStart: getClamshellSearchBegin.type,
        onSuccess: getClamshellSearchSuccess.type,
        onError: getClamshellSearchFailure.type,
      })
    );
  };
