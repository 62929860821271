import { useState } from "react";
import { Divider } from "@material-ui/core";

import CustomTab from "../../components/reusable/CustomTab";
import CertiDiagnostics from "../../components/certification/CertiDiagnostics";
import { isNull } from "../../components/reusable/Util";
import ExceptionBox from "../../components/reusable/ExceptionBox";
import AppLabels from "../../constants/App_Labels";

const HSICertiDetails = (props: any) => {
  const labels = AppLabels();
  const {
    certiDevices,
    precheckDevices,
    hsiCertiData,
    certiConfig,
    selDValue,
    setSelDValue,
    skippedDevices,
    selDevice,
    isApplicable,
    selectedSA,
    deviceDiagActions,
    remarkDetails,
  } = props;
  const [leftmenu, setLeftMenu] = useState(true);
  const [selDiagAction, setSelDiagAction] = useState("");
  const handleActionClick = (tab: string) => {
    setSelDiagAction(tab);
  };

  return (
    <div className="hsiCertiDetails">
      {remarkDetails !== undefined &&
        remarkDetails?.hasOwnProperty("reasonCode") ? (
        <div className="techInfo">
          <section className="techNote">
            <span className="blockSpan">
              <span>
                <h5>{labels.Reason}</h5>
                <span>{isNull(remarkDetails?.reasonText)}</span>
              </span>
            </span>
            <span className="blockSpan">
              <span>
                <h5>{labels.ReviewedWithCustomer}</h5>
                <span>
                  {remarkDetails?.isReviewedWithCustomer ? "Yes" : "No"}
                </span>
              </span>
            </span>
            <span className="blockSpan">
              <h5>{labels.TechnicianNotes}</h5>
              <span>{isNull(remarkDetails?.notes)}</span>
            </span>
          </section>
          <Divider orientation="vertical" style={{ height: "270px" }} />
          <section className="techRemarkInfo">
            <span className="blockSpan">
              <span>
                <h3>{labels.LengthOfDrop}</h3>
                <span>{remarkDetails?.lengthOfDrop}</span>
              </span>
            </span>
            <span className="blockSpan">
              <span>
                <h3>{labels.ResistanceOfDrop}</h3>
                <span>{remarkDetails?.resistanceOfDrop}</span>
              </span>
            </span>
            <span className="blockSpan">
              <span>
                <h3>{labels.ValidateWithTDRandOHM}</h3>
                <span>
                  {remarkDetails?.isReviewedWithCustomer ? "Yes" : "No"}
                </span>
              </span>
            </span>
          </section>
        </div>
      ) : (
        <></>
      )}
      {isApplicable ? (
        <div className="WHTCont">
          <section className={leftmenu ? "leftSection" : "toggleleftSection"}>
            <p className="certiDeviceLabel">{labels.PROVISIONED_DEVICES.toUpperCase()}</p>
            <div className="deviceTabs">
              <Divider />
              {certiDevices !== undefined && certiDevices?.length !== 0 ? (
                certiDevices?.map((device: any, index: number) => {
                  return (
                    <>
                      {certiConfig?.map((d: any, i: number) =>
                        device.deviceCategory === d.category ? (
                          <div key={i}>
                            <CustomTab
                              tabType={
                                !leftmenu ? "vertical-Toggle" : "vertical"
                              }
                              handleActionClick={(actionKey: any) =>
                                handleActionClick(actionKey)
                              }
                              deviceDiagActions={deviceDiagActions}
                              deviceStatus={device.status?.toLowerCase()}
                              index={index}
                              value={selDValue}
                              handleSelect={(i: any) => setSelDValue(i)}
                              label={isNull(d.name)}
                            />
                            <Divider />
                          </div>
                        ) : null
                      )}
                    </>
                  );
                })
              ) : certiDevices?.length === 0 && skippedDevices !== undefined ? (
                <>
                  {skippedDevices?.map((device: any, index: number) => {
                    return (
                      <>
                        {certiConfig?.map((d: any, i: number) =>
                          device.deviceCategory === d.category ? (
                            <div key={i}>
                              <CustomTab
                                tabType="vertical-certi"
                                deviceStatus="skip"
                                index={index}
                                value={selDValue}
                                handleSelect={(i: any) => setSelDValue(i)}
                                label={isNull(d.name)}
                              />
                              <Divider />
                            </div>
                          ) : null
                        )}
                      </>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
            </div>
          </section>
          <section className="rightPanel">
            <div className="diagResult">
              {certiDevices?.length === 0 && skippedDevices !== undefined ? (
                <div className="diagErrorCont">
                  <ExceptionBox
                    headerText="No Diagnostics to display."
                    bodyText="Drop & stay test type do not include the devices, which are provisioned for this account."
                    primaryBtn={false}
                    secBtn={false}
                  />
                </div>
              ) : (
                <>
                  {selDevice !== undefined && selDValue !== undefined ? (
                    <CertiDiagnostics
                      selectedSA={selectedSA}
                      hsiCertiData={hsiCertiData}
                      certiConfig={certiConfig}
                      selectedDevice={selDevice}
                      deviceDiagActions={deviceDiagActions}
                      seleDeviceDiag={selDevice.diagResult}
                      precheckDevice={precheckDevices?.find(
                        (f: any) => f?.serialNumber === selDevice?.serialNumber,
                      )}
                      selDiagAction={selDiagAction}
                      handleActionClick={(actionKey: any) =>
                        handleActionClick(actionKey)
                      }
                    />
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </section>
        </div>
      ) : (
        <div className="diagErrorCont">
          <ExceptionBox
            headerText="Whole Home Certification not available."
            bodyText="This could be either due to the reason that, it is not required for the Certification test type. "
            primaryBtn={false}
            secBtn={false}
          />
        </div>
      )}
    </div>
  );
};

export default HSICertiDetails;
