import { useCallback, useEffect, useState } from "react";
import {
  formatCamelCase,
  isNull,
  openNewWindow,
} from "../../components/reusable/Util";
import { GetTelemetryProps } from "../../topology/util/reduxFunctions/getTopologyState";
import Loader from "../../resources/images/loader.webp";
import ConfigConst from "../../constants/ConfigConst";
import {
  getNodeTeleGloabalStatus,
  getTelemetryInfo,
} from "../../components/reusable/NodeUtil";
import { GetNodeInfoDetails } from "../../get-api/GetResponse";
import AppLabels from "../../constants/App_Labels";
import { Divider } from "@material-ui/core";
import CustomDropDown from "../../components/reusable/CustomDropDown";
import { stbDataSetType } from "../../config/STBTelemetryConfig";
import { useDispatch } from "react-redux";
import {
  getTeleModemsList,
  setAllDiagLoading,
  setDiagType,
  stopDiagExecution,
} from "../../topology/store/slices/mapItems/diagnostics/telemetry";
import storeConst from "../../topology/store/storeConst";
import { addAbortKey } from "../../topology/store/middleware/apiClient";
import { TELE_ABORT_KEY } from "../../topology/store/middleware/abortKeys";
import { FaRegPlayCircle, FaRegStopCircle } from "react-icons/fa";
import CMTSClient from "../../network/httpclient/cmts/CMTSClient";

interface Props {
  screenType: string;
  macAddress?: string;
}

interface IKeyValue {
  text: String;
  value: string | number | JSX.Element;
}

const STBBasicDetails = ({ screenType, macAddress }: Props) => {
  const labels = AppLabels();
  const dispatch = useDispatch();
  const { currNodedata } = GetNodeInfoDetails();
  const {
    allDiagIsLoading,
    allDiagStatus,
    isAborted,
    mlData,
    modemType,
    diagType,
    diagStartTime,
    diagEndTime,
  } = GetTelemetryProps();

  const nodeTeleStatus = getNodeTeleGloabalStatus(
    allDiagStatus,
    allDiagIsLoading,
    isAborted
  );

  const [RackIDData, setRackIDData] = useState<any>({});
  const [dataSetName, setDataSetName] = useState("");
  const [bannerInfo, setBannerInfo] = useState<any>({
    active: "",
    assigned: "",
    responsive: "",
    unresponsive: "",
  });

  let dataSetNames: any = stbDataSetType?.map((e: any) => {
    return e?.dataSetName;
  });

  const getValueSpan = (val: any) => {
    if (!allDiagIsLoading && allDiagStatus === storeConst.OK) {
      return <span>{isNull(val)}</span>;
    } else if (allDiagIsLoading) {
      return (
        <span>
          <img src={Loader} alt="loading" height="15px" />
        </span>
      );
    } else {
      return <span>-</span>;
    }
  };

  const KeyValuesTitle = (props: IKeyValue) => {
    const { text, value } = props;
    return (
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          alignItems: "center",
        }}
      >
        <span style={{ fontWeight: 600 }}>{text}:</span>
        <span style={{ marginLeft: "5px" }}>{value}</span>
      </span>
    );
  };

  const getListOfModems = () => {
    const activeId = currNodedata?.smt
      ? currNodedata?.smtId
      : currNodedata?.nodeId;
    const type = currNodedata?.smt ? "smtid" : "shubid";
    dispatch(
      getTeleModemsList({
        value: activeId,
        type: type,
        diagnoseDevices: true,
        modemType: modemType,
      })
    );
  };

  const runTelemetry = () => {
    addAbortKey(TELE_ABORT_KEY);
    setBannerInfo({
      active: undefined,
      assigned: undefined,
      responsive: undefined,
      unresponsive: undefined,
    });
    if (
      nodeTeleStatus === ConfigConst.complete.toLowerCase() ||
      nodeTeleStatus === ConfigConst.stopped.toLowerCase()
    ) {
      getListOfModems();
    } else dispatch(setAllDiagLoading(true));
  };

  const stopTelemetry = () => {
    dispatch(stopDiagExecution());
  };

  useEffect(() => {
    if (currNodedata && modemType && currNodedata?.nodeId) {
      setDataSetName(
        diagType?.toLowerCase()?.trim() === storeConst.all_no_cw ||
          diagType?.toLowerCase()?.trim() === storeConst.all
          ? dataSetNames[0]
          : dataSetNames[1]
      );
      getListOfModems();
    }
    if (macAddress && modemType) {
      //getListOfModemsByMAC
    }
  }, [currNodedata, macAddress]);

  useEffect(() => {
    if (
      mlData?.length > 0 &&
      nodeTeleStatus === ConfigConst.complete &&
      !isAborted
    ) {
      const modemListInfo = getTelemetryInfo(mlData);
      setBannerInfo({
        active: modemListInfo?.active,
        assigned: modemListInfo?.assigned,
        responsive: modemListInfo?.responsive,
        unresponsive: modemListInfo?.unresponsive,
      });
    } else if (allDiagIsLoading) {
      setBannerInfo({
        active: undefined,
        assigned: undefined,
        responsive: undefined,
        unresponsive: undefined,
      });
    }
  }, [mlData]);

  const flexContainerStyle = {
    display: "flex",
    flexDirection: "column" as "column",
    flexWrap: "wrap" as "wrap",
    alignItems: "flex-start" as "flex-start",
    paddingleft: "15px" as "15px",
  };

  const flexItemStyle = {
    flexBasis: "50%",
  };

  const apiCmtsIPCall = useCallback(async () => {

    if (currNodedata) {
      try {
        const returnedData = await dispatch(
          CMTSClient.getCMTSRackID(currNodedata?.cmtsIp)
        );
        setRackIDData(returnedData);
      } catch (error) {
        console.error(error);
      }
    }
  }, [currNodedata]);

  useEffect(() => {
    if (currNodedata) {
      apiCmtsIPCall();
    }
  }, [currNodedata, apiCmtsIPCall]);

  return (
    <div className="stbBasicDetails">
      <section className="nodeDetailsCont">
        <div style={flexContainerStyle}>
          <p style={flexItemStyle}>
            <span>
              {currNodedata?.smt
                ? labels.SMT_ID.toUpperCase()
                : labels.NODE_ID.toUpperCase()}
              :
            </span>

            <span>
              {currNodedata?.smt
                ? isNull(currNodedata?.smtId)
                : isNull(currNodedata?.nodeId)}
            </span>

            <span className="accTopology">{screenType.toUpperCase()}</span>

            <p style={flexItemStyle}>
              <span>{labels.PHUB.toUpperCase()}:</span>
              <span>{isNull(currNodedata?.phub)}</span>
            </p>
          </p>
        </div>

        <div style={flexContainerStyle}>
          <p style={flexItemStyle}>
            <span>{labels.CMTS_IP.toUpperCase()}:</span>
            <span
              className="nodeLink"
              onClick={() => {
                openNewWindow(
                  `/#/node/cmts?nodeId=${currNodedata?.nodeId}&mac=${currNodedata?.macDomain}&usPort=${currNodedata?.usPort}&cmtsIp=${currNodedata?.cmtsIp}`
                );
              }}
            >
              {isNull(currNodedata?.cmtsIp)}
            </span>
          </p>
          <p style={flexItemStyle}>
            <span>{labels.CMTS_NAME.toUpperCase()}:</span>
            <span> {isNull(currNodedata?.cmtsName)} / {RackIDData?.cmtsLocation}</span>
          </p>
        </div>
        <div style={flexContainerStyle}>
          <p style={flexItemStyle}>
            <span>{labels.US_PORT.toUpperCase()}:</span>
            <span> {isNull(currNodedata?.usPort)}</span>
          </p>
          <p style={flexItemStyle}>
            <span>{labels.DS_PORT.toUpperCase()}:</span>
            <span> {isNull(currNodedata?.dsPort)}</span>
          </p>
        </div>

        <div style={flexContainerStyle}>
          <p style={flexItemStyle}>
            <span>{labels.MAC_DOMAIN.toUpperCase()}:</span>
            <span> {isNull(currNodedata?.macDomain)}</span>
          </p>

          <p style={flexItemStyle}>
            <span>{"US PEERS".toUpperCase()}:</span>
            <span> {isNull(currNodedata?.usPeers)}</span>
          </p>
        </div>

        {/* <Divider orientation="vertical" /> */}
        <div className="teleScores">
          <span
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "flex-start",
              paddingLeft: "10px",
            }}
          >
            <KeyValuesTitle
              text="Active"
              value={
                bannerInfo?.active === undefined ? (
                  <img src={Loader} alt="loading" height="20px" />
                ) : (
                  `${bannerInfo?.active}%`
                )
              }
            />
            <KeyValuesTitle
              text="Assigned"
              value={
                bannerInfo?.assigned === undefined ? (
                  <img src={Loader} alt="loading" height="20px" />
                ) : (
                  `${bannerInfo?.assigned}`
                )
              }
            />
          </span>
          <span
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "flex-start",
              paddingLeft: "10px",
            }}
          >
            <KeyValuesTitle
              text="Responsive"
              value={
                bannerInfo?.responsive === undefined ? (
                  <img src={Loader} alt="loading" height="20px" />
                ) : (
                  `${bannerInfo?.responsive}`
                )
              }
            />
            <KeyValuesTitle
              text="Unresponsive"
              value={
                bannerInfo?.unresponsive === undefined ? (
                  <img src={Loader} alt="loading" height="20px" />
                ) : (
                  `${bannerInfo?.unresponsive}`
                )
              }
            />
          </span>
        </div>

        <span
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            alignItems: "flex-start",
            paddingLeft: "10px",
          }}
        >
          <KeyValuesTitle
            text="Start"
            value={
              diagStartTime === undefined ? (
                <img src={Loader} alt="loading" height="20px" />
              ) : (
                `${diagStartTime}`
              )
            }
          />
          <KeyValuesTitle
            text="End"
            value={
              diagEndTime === undefined ? (
                <img src={Loader} alt="loading" height="20px" />
              ) : (
                `${diagEndTime}`
              )
            }
          />
        </span>
      </section>
      <section className="teleActionCont">
        <span>{labels.DATA_SET?.toUpperCase()}:</span>
        <CustomDropDown
          disabled={allDiagIsLoading}
          selectedOption={dataSetName}
          options={dataSetNames}
          searchFlag={false}
          checkboxFlag={false}
          setSelectedOption={(value: any) => {
            setDataSetName(value);
            dispatch(
              setDiagType(
                value === ConfigConst.FULL_NO_CW.toUpperCase()
                  ? storeConst.all_no_cw
                  : storeConst.summary
              )
            );
          }}
        />
        <div>
          {mlData !== undefined && mlData?.length !== 0 && (
            <>
              {!allDiagIsLoading ? (
                <button className="refreshCertiBtn" onClick={runTelemetry}>
                  {labels.START?.toUpperCase()} <FaRegPlayCircle />
                </button>
              ) : (
                <>
                  <button
                    className="refreshCertiBtn"
                    onClick={() => stopTelemetry()}
                  >
                    {labels.STOP?.toUpperCase()} <FaRegStopCircle />
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default STBBasicDetails;
