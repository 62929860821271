import { Card } from "react-bootstrap";
import { useState } from "react";
import { TabPanel } from "../../screens/account-page/AccountPageTabs";
import CustomTab from "../reusable/CustomTab";
import { isEmptyObject } from "../reusable/Util";
import DataDisplayTable from "../reusable/DataDisplayTable";
import AppLabels from "../../constants/App_Labels";
import CustomAccordion from "../reusable/CustomAccordion";
const Wifi5GData = (props: any) => {
  const { wifiSettingsData, wifiRadioData } = props;
  const [tabSelIndex, setTabSelIndex] = useState(0);
  const labels = AppLabels();
  return (
    <CustomAccordion
      accTitle={labels.WIFI_FG}
      accBodyElement={
        <>
          {wifiSettingsData !== undefined && wifiRadioData !== undefined ? (
            <Card className="dsChTabsCard">
              <Card.Header>
                <CustomTab
                  tabType="horizontal"
                  index={0}
                  value={tabSelIndex}
                  handleSelect={(i: any) => setTabSelIndex(i)}
                  label="RADIO"
                />
                <CustomTab
                  tabType="horizontal"
                  index={1}
                  value={tabSelIndex}
                  handleSelect={(i: any) => setTabSelIndex(i)}
                  label="SETTINGS"
                />
              </Card.Header>
              <Card.Body>
                <TabPanel value={tabSelIndex} index={0}>
                  {wifiRadioData?.hasOwnProperty("radio5g") &&
                  isEmptyObject(wifiRadioData.radio5g) !== 0 ? (
                    <DataDisplayTable
                      dataObject={wifiRadioData.radio5g}
                      tableName="radio5g"
                    />
                  ) : (
                    <></>
                  )}
                </TabPanel>
                <TabPanel value={tabSelIndex} index={1}>
                  {wifiSettingsData?.hasOwnProperty("wifi5g") &&
                  isEmptyObject(wifiSettingsData.wifi5g) !== 0 ? (
                    <DataDisplayTable
                      dataObject={wifiSettingsData.wifi5g}
                      tableName="wifi5g"
                    />
                  ) : (
                    <></>
                  )}
                </TabPanel>
              </Card.Body>
            </Card>
          ) : (
            <>NO DATA AVAILABLE...</>
          )}
        </>
      }
    />
  );
};
export default Wifi5GData;
