import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CustAccICon from "../../resources/images/icons/custAccIcon.svg";
import AppLabels from "../../constants/App_Labels";
import { isNull, isNodeAvailable, openElectroline } from "../reusable/Util";
import {
  SetActiveNodeInfoDetails,
  ActiveNodeInfoReset,
  NodeReset,
} from "../../store/actions/node/nodeinfo/ActiveNodeInfoAction";
import Node from "../../resources/images/icons/node_black.svg";
import Smt from "../../resources/images/icons/SMT.svg";
import {
  UpdateNode,
  SetActiveKey,
} from "../../store/actions/node/AllNodesAction";
import { useEffect } from "react";
import { GetNodeInfoDetails } from "../../get-api/GetResponse";
import ConfigConst from "../../constants/ConfigConst";

const SearchNodeResult = (props: any) => {
  const { onHide, nodedata, searchNodeStatus } = props;
  const history = useHistory();
  const labels = AppLabels();
  const dispatch = useDispatch();
  const { currNodedata, currNodeState, allNodes, searchType } =
    GetNodeInfoDetails();

  useEffect(() => {
    if (
      searchNodeStatus === "OK" &&
      nodedata?.length !== 0 &&
      searchType !== ConfigConst?.electroline
    ) {
      openNodePage(nodedata[0]);
    }
  }, [searchNodeStatus]);
  useEffect(() => {
    if (searchNodeStatus === "OK" && nodedata?.length !== 0) {
      if (searchType === ConfigConst?.electroline) {
        if (nodedata[0]?.electrolineServer != undefined)
          openElectroline(nodedata[0]?.electrolineServer);
        else {
          props.setError(
            "Sorry! This is RPHY node and it does not have electroline server."
          );
          props.setOpen(true);
        }
      }
    }
  }, [nodedata]);
  const openNodePage = (node: any) => {
    let currDataId = currNodedata?.smt
      ? currNodedata?.smtId
      : currNodedata?.nodeId;
    let id = node?.smt ? node?.smtId : node?.nodeId;
    let urlString = node?.smt ? "/node?smtId" : "/node?nodeId";

    if (currNodedata !== undefined && currNodeState !== undefined) {
      dispatch(UpdateNode(currDataId, currNodeState));
    }
    if (isNodeAvailable(allNodes, id)) {
      if (currNodedata?.nodeId === id) {
        history.push(`${urlString}=${id}`);
      } else {
        dispatch(SetActiveKey(id));
        history.push(`${urlString}=${id}`);
      }
    } else {
      dispatch(NodeReset());
      dispatch(SetActiveNodeInfoDetails(node));
      dispatch(SetActiveKey(id));
      history.push(`${urlString}=${id}`);
    }
    dispatch(SetActiveNodeInfoDetails(node));
    onHide();
    history.push(`${urlString}=${id}`);
  };

  return (
    <>
      {searchType !== ConfigConst?.electroline && (
        <div className="SearchResultCont">
          <p>SEARCH RESULT(S)</p>
          {nodedata.map((node: any, i: number) => {
            return (
              <Card
                key={i}
                className="resultCard"
                onClick={() => openNodePage(node)}
              >
                {node?.smt ? (
                  <>
                    <span className="resultCardIcon">
                      <img src={Smt} alt="accIcon" />
                    </span>
                    <span className="resultCardDetails">
                      <h5>
                        {labels.SMT_ID.toUpperCase()}
                        <span>{isNull(node?.smtId)}</span>
                      </h5>
                      <h5>
                        {labels.NODE_ID.toUpperCase()}:
                        <span>{isNull(node?.nodeId)}</span>
                      </h5>

                      <h5>
                        {labels.PHUB.toUpperCase()}:
                        <span>{isNull(node?.phub)}</span>
                        {node?.vhub === "true" && (
                          <span className="vhub">VHUB</span>
                        )}
                        {node?.nodeType === "R-PHY" && (
                          <span className="vhub">RPHY</span>
                        )}
                      </h5>
                    </span>
                  </>
                ) : (
                  <>
                    <span className="resultCardIcon">
                      <img src={Node} alt="accIcon" />
                    </span>
                    <span className="resultCardDetails">
                      <h5>{isNull(node?.nodeId)}</h5>

                      <h5>
                        {labels.PHUB.toUpperCase()}:&nbsp;
                        <span>{isNull(node?.phub)}</span>
                        {node?.vhub === "true" && (
                          <span className="vhub">VHUB</span>
                        )}
                        {node?.nodeType === "R-PHY" && (
                          <span className="vhub">RPHY</span>
                        )}
                      </h5>
                    </span>
                  </>
                )}
              </Card>
            );
          })}
        </div>
      )}
    </>
  );
};

export default SearchNodeResult;
