import { Marker } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import colors from "../../config/colors";
import { mapElementStyles } from "../../config/mapItemsConfig";
import appConst from "../../constants/appConst";
import {
  GetGoogleMaps,
  GetMapDetailsProps,
  GetSymbolsProps,
  GetNodeSiteDetailsProps,
} from "../../util/reduxFunctions/getTopologyState";
import {
  getBase64ScaledImageAnchor,
  getMemoMarkerAnchor,
} from "../../util/utilFuncs";
import TopologySymbols from "../reusable/TopologySymbols";
import { mapItemsClickConfig } from "../../config/mapItemsClickConfig";

const ActiveMapMarker = React.memo(() => {
  const { activeMapElement } = GetMapDetailsProps();
  const { allNodeData } = GetNodeSiteDetailsProps();
  const { pngImages } = GetSymbolsProps();
  const { zoomLevel } = GetGoogleMaps();

  const [pngInfo, setPngInfo] = useState<any>(undefined);

  useEffect(() => {
    if (activeMapElement) {
      if (activeMapElement?.properties?.type === appConst.memoComment) {
        const anchorInfo = getMemoMarkerAnchor(
          allNodeData,
          activeMapElement,
          pngImages,
          zoomLevel
        );

        setPngInfo({
          x: anchorInfo?.x,
          y: anchorInfo?.y,
        });
      } else {
        const anchorInfo = getBase64ScaledImageAnchor(
          pngImages,
          activeMapElement?.properties?.type,
          activeMapElement?.properties?.data?.symbolName,
          activeMapElement?.properties?.data?.rotation,
          mapElementStyles?.[
            activeMapElement?.properties?.type === appConst.node
              ? appConst.nodeSite
              : activeMapElement?.properties?.type
          ],
          zoomLevel
        );

        setPngInfo({
          x: anchorInfo?.x,
          y: anchorInfo?.y,
        });
      }
    }
  }, [activeMapElement]);

  return (
    <>
      {activeMapElement && activeMapElement?.geometry?.lat && (
        <Marker
          key="activeEleMarker"
          visible={activeMapElement?.geometry !== null}
          position={{
            lat: activeMapElement?.geometry?.lat,
            lng: activeMapElement?.geometry?.lng,
          }}
          zIndex={2000}
          icon={{
            url:
              appConst.imageStringPrefix +
              encodeURIComponent(
                renderToString(
                  <TopologySymbols
                    name={"pinLocation"}
                    size={
                      zoomLevel === 21
                        ? mapItemsClickConfig?.activeMarker?.iconSize21
                        : zoomLevel === 20
                        ? mapItemsClickConfig?.activeMarker?.iconSize20
                        : mapItemsClickConfig?.activeMarker?.iconSize19
                    }
                    color={colors.primary}
                  />
                )
              ),
            anchor: new google.maps.Point(
              activeMapElement?.properties?.type === appConst.cpat
                ? mapElementStyles?.cpatEvent?.anchor?.x -
                  mapElementStyles?.cpatEvent?.anchor?.x
                : activeMapElement?.properties?.type === appConst.mdu
                ? mapElementStyles?.mdu?.anchor?.x
                : pngInfo
                ? activeMapElement?.properties?.type === appConst.memoComment
                  ? pngInfo?.x
                  : pngInfo?.x -
                    (zoomLevel == 21
                      ? mapItemsClickConfig?.activeMarker?.anchor?.zoom21?.x
                      : zoomLevel == 20
                      ? mapItemsClickConfig?.activeMarker?.anchor?.zoom20?.x
                      : mapItemsClickConfig?.activeMarker?.anchor?.zoom19?.x)
                : mapElementStyles?.common.anchor.x,
              activeMapElement?.properties?.type === appConst.cpat
                ? mapElementStyles?.cpatEvent?.anchor?.y -
                  mapElementStyles?.cpatEvent?.anchor?.y
                : activeMapElement?.properties?.type === appConst.mdu
                ? mapElementStyles?.mdu?.anchor?.y
                : pngInfo
                ? activeMapElement?.properties?.type === appConst.memoComment
                  ? pngInfo?.y +
                    (zoomLevel === 21
                      ? mapElementStyles?.memoComment.anchor.zoom21.y
                      : zoomLevel === 20
                      ? mapElementStyles?.memoComment.anchor.zoom20.y
                      : mapElementStyles?.memoComment.anchor.zoom19.y) +
                    10
                  : pngInfo?.y +
                    (zoomLevel == 21
                      ? mapItemsClickConfig?.activeMarker?.anchor?.zoom21?.y
                      : zoomLevel == 20
                      ? mapItemsClickConfig?.activeMarker?.anchor?.zoom20?.y
                      : mapItemsClickConfig?.activeMarker?.anchor?.zoom19?.y)
                : mapElementStyles?.common.anchor.y
            ),
          }}
        />
      )}
    </>
  );
});

export default ActiveMapMarker;
