import { configureStore } from "@reduxjs/toolkit";
import apiClient from "./topology/store/middleware/apiClient";
import { rootReducer } from "./store/reducers/index";

export default function () {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: true, //you can temporarily turn this off if needed but dont push that change
        immutableCheck: false,
      }).concat(apiClient),
  });
}
