import { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import BrowseFiles from "./BrowseFiles";
import AttachedFiles from "./AttachedFiles";
import Dropdown from "../reusable/Dropdown";
import FeedbackClient from "../../network/httpclient/feedback/FeedbackClient";
import AppLabels from "../../constants/App_Labels";
import { dataURItoBlob, getAccessLevelByRoles } from "../reusable/Util";
import { GetTechInfo } from "../../get-api/GetResponse";
import ConfigConst from "../../constants/ConfigConst";
import SearchDropDown from "./SearchDropDown";

const FeedbackForm = (props: any) => {
  const [awaitDownload, setAwaitDownload] = useState(false);
  const [awaitAttachConfirm, setAwaitAttachConfirm] = useState(false);
  const [awaitThankYou, setAwaitThankYou] = useState(false);
  const env = (window as any).config.JIRA_COMPONENT;
  const labels = AppLabels();
  const dispatch = useDispatch();
  const [file, setFile] = useState<any[] | null[]>([]);
  const [description, setDescription] = useState("");
  const [fbType, setFbType] = useState("Bug");
  const [fbHead, setFbHead] = useState("");
  const [screen, setScreen] = useState("");
  const techData = GetTechInfo()?.data;
  const roles: Array<String> = GetTechInfo()?.roles;
  const [roleData, setRoleData] = useState({ name: "all", check: false });
  const capitalize = (s: String) =>
    s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

  const feedbackSectionMap = new Map();
  feedbackSectionMap.set(capitalize(labels.HOME), "73936");
  feedbackSectionMap.set(capitalize(labels.ACCOUNT), "73937");
  feedbackSectionMap.set(capitalize(labels.NODE), "73938");
  feedbackSectionMap.set(capitalize(labels.Topology), "73939");
  feedbackSectionMap.set(capitalize(labels.History), "73940");

  const feedbackRole = new Map();
  feedbackRole.set(labels.serviceTech, "73934");
  feedbackRole.set(labels.maintenanceTech, "73935");

  const [fbRole, setFbRole] = useState(labels.serviceTech);
  const [accountNumber,setAccountNumber] = useState('');
  const [accountNumberOptions,setAccountNumberOptions] = useState([]);
  const [workOrderNumber,setWorkOrderNumber] = useState('');
  const [workOrderOptions,setWorkOrderOptions] = useState([]);
  const [fbSection, setFbSection] = useState(capitalize(labels.HOME));
  const onFeedbackSub = (e: any) => setFbType(e.target.value);
  const onFeedbackSection = (e: any) => setFbSection(e.target.value);
  const onFeedbackRole = (e: any) => setFbRole(e.target.value);
  const onDescSubmit = (e: any) => setDescription(e.target.value);
  const handleReset = () => {
    setFile([]);
    setFbHead("");
    setDescription("");
    setFbType("Bug");
    setScreen("");
    setFbRole(labels.serviceTech);
    setFbSection(capitalize(labels.HOME));
  };
  const { data, isLoading, status, attachmentStatus, attachmentLoading,woList } =
    useSelector(
      (state: any) => ({
        data: state.FeedbackCreationInfo.data,
        status: state.FeedbackCreationInfo.status,
        isLoading: state.FeedbackCreationInfo.isLoading,
        attachmentStatus: state.AttachmentInfo.status,
        attachmentLoading: state.AttachmentInfo.isLoading,
        woList:state.workOrdersList.data,
      }),
      shallowEqual
    );

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (description !== "") {
      const body = JSON.stringify({
        fields: {
          project: {
            key: "TIUFM",
          },
          summary: fbType + " - " + fbHead,
          issuetype: {
            name: fbType === "Appreciation" ? "Testimonial" : fbType,
          },
          assignee: {
            name: "tech.insight@rci.rogers.com",
          },
          priority: {
            id: "10004",
          },
          description: description,
          components: [
            {
              name: env,
            },
          ],
          customfield_22204: techData?.lanId,
          customfield_45006: { id: feedbackSectionMap.get(fbSection) },
          customfield_47417:accountNumber,
          customfield_47418:workOrderNumber,
          customfield_47514:techData?.techId || "",
          customfield_29819: {
            id: roleData?.check
              ? feedbackRole.get(roleData?.name)
              : feedbackRole.get(fbRole),
          },
        },
      });
      /*    customfield_39200: [screen], */
      dispatch(FeedbackClient.postFeedbackIssue(body));
      setAwaitThankYou(true);
      if (file?.length > 0) setAwaitDownload(true);
    } else popUp("fill");
  };

  useEffect(() => {
    if (
      status === "OK" &&
      data?.hasOwnProperty("key") &&
      awaitDownload === true
    ) {
      setAwaitDownload(false);
      setAwaitAttachConfirm(true);
      let imgData = new FormData();
      file.map((image: any) => {
        if (typeof image == "object") {
          let myBlob = dataURItoBlob(image[1]);
          imgData.append("file", myBlob, image[0]?.name);
        }
      });
      dispatch(FeedbackClient.postAttachment(data.key, imgData));
    } else if (awaitAttachConfirm) {
      if (attachmentStatus === "OK") {
        popUp("thankYou");
        setAwaitThankYou(false);
        handleReset();
        setAwaitAttachConfirm(false);
      }
      if (attachmentStatus === "ERROR") {
        popUp("error");
        setAwaitThankYou(false);
        setAwaitAttachConfirm(false);
      }
    } else if (status === "OK" && awaitThankYou === true) {
      handleReset();
      popUp("thankYou");
      setAwaitThankYou(false);
    } else if (status === "ERROR" && awaitThankYou === true) {
      popUp("error");
      setAwaitThankYou(false);
    }
    const workOrderOptions = woList?.map((wo: any) => wo.id);
    setWorkOrderOptions(workOrderOptions)

    const accountNumberOptions = woList?.map((wo: any) => wo.accountNumber);
    setAccountNumberOptions(accountNumberOptions)

  }, [status, attachmentStatus, woList]);

  const popUp = (type: any) => {
    let text = document.getElementById("text");
    if (type === "thankYou") {
      if (text) text.innerHTML = labels.THANK_YOU_FEEDBACK;
      if (text) text.style.color = "green";
    } else if (type == "error") {
      if (text) text.innerHTML = "Error";
      if (text) text.style.color = "red";
    } else if (type == "fill") {
      if (text) text.innerHTML = "Please fill out description field.";
      if (text) text.style.color = "red";
    }
    text!.classList.remove("hide");
    setTimeout(function () {
      text!.classList.add("fade-in");
      setTimeout(function () {
        text!.classList.remove("fade-in");
        setTimeout(function () {
          text!.classList.add("hide");
        }, 1000);
      }, 2000);
    });
  };

  const validRoleCheck = () => {
    let roleValue = { name: "all", check: false };

    // if (roles.includes("maintenance_tech") && !roles.includes("technician")) {
    if (
      getAccessLevelByRoles(roles).includes(ConfigConst.MT) &&
      !getAccessLevelByRoles(roles).includes(ConfigConst.ST)
    ) {
      roleValue = { name: labels.maintenanceTech, check: true };
    }
    // if (roles.includes("technician") && !roles.includes("maintenance_tech")) {
    if (
      getAccessLevelByRoles(roles).includes(ConfigConst.ST) &&
      !getAccessLevelByRoles(roles).includes(ConfigConst.MT)
    ) {
      roleValue = { name: labels.serviceTech, check: true };
    }
    setRoleData(roleValue);
  };

  useEffect(() => {
    validRoleCheck();
  }, [roles]);

  const optionsType = ["Bug", "Suggestion", "Appreciation"];

  return (
    <Modal.Body bsPrefix="modal-body">
      <p id="myP">{labels.FEEDBACK_INSTRUCTIONS}</p>
      <Form onSubmit={handleSubmit} className="form">
        <div className="feedBackBugContainer">
          {roleData?.check ? (
            <div>
              <div className="form-label">{labels.ROLE}</div>
              <div style={{ fontSize: "12px" }}>{roleData?.name}</div>
            </div>
          ) : (
            <Dropdown
              disabled={false}
              onChange={onFeedbackRole}
              value={fbRole}
              title={labels.ROLE}
              options={Array.from(feedbackRole.keys())}
            />
          )}
          <Dropdown
            disabled={false}
            onChange={onFeedbackSub}
            value={fbType}
            title={labels.FEEDBACK_TYPE}
            options={optionsType}
          />
          <Dropdown
            disabled={false}
            onChange={onFeedbackSection}
            value={fbSection}
            title={labels.FEEDBACK_SECTION}
            options={Array.from(feedbackSectionMap.keys())}
          />
        </div>
        <div style={{display:"flex", flexDirection:"row",justifyContent:"space-between"}}>

            <SearchDropDown 
              options={accountNumberOptions} 
              selectedOption={accountNumber}
              setSelectedOption={setAccountNumber} 
              placeholder="Account #" 
              label="Account #"/>

            <SearchDropDown 
              options={workOrderOptions} 
              selectedOption={workOrderNumber}
              setSelectedOption={setWorkOrderNumber} 
              placeholder="Work Order #" 
              label="Work Order #"/>
        </div>
        <Form.Group
          style={{
            display: fbType === "Appreciation" ? "none" : "flex",
            flexDirection: "column",
          }}
        >
          <Form.Label>{labels.FEEDBACK_SUBTYPE}</Form.Label>
          <Form.Control
            name="screen"
            onChange={(e) => setFbHead(e.target.value)}
            type="text"
            className="description"
            id="description"
            value={fbHead || ""}
            maxLength={50}
            placeholder="Enter impacted feature..."
          />
        </Form.Group>
        <Form.Group className="selector">
          <Form.Label>{labels.DESCRIPTION}</Form.Label>
          <Form.Control
            name="description"
            onChange={onDescSubmit}
            as="textarea"
            rows={3}
            className="description"
            id="description"
            maxLength={1000}
            value={description || ""}
            placeholder="The more detail your point is, easier it would be for us to get to the best solution for it..."
          />
        </Form.Group>
        <Form.Group className="attachment-group">
          <Form.Label>{labels.ATTACH}</Form.Label>
          <BrowseFiles setFile={setFile} file={file} />
          <AttachedFiles file={file} setFile={setFile} />
        </Form.Group>
        <div className="submission">
          <Button
            className="submit"
            type="submit"
            disabled={
              isLoading ||
              attachmentLoading ||
              (fbHead === "" && fbType !== "Appreciation")
                ? true
                : false
            }
          >
            {labels.SUBMIT}
          </Button>
          <Button bsPrefix="reset" type="reset" onClick={handleReset}>
            {labels.RESET}
          </Button>
        </div>
        <div>
          <p className="hide" id="text">
            {labels.THANK_YOU_FEEDBACK}
          </p>
        </div>
      </Form>
      <hr />
    </Modal.Body>
  );
};

export default FeedbackForm;
