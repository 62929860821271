import { useState, ReactNode } from "react";
import { Accordion, Card } from "react-bootstrap";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { BiLinkExternal } from "react-icons/bi";

import colors from "../../topology/config/colors";

interface propsAccordion {
  accTitle: any;
  accBodyElement: any;
  initialOpenState?: boolean;
  externalLinkFlag?: boolean;
  onClickLink?: any;
  headerComp?: JSX.Element | JSX.Element[];
  headerTitleComp?: ReactNode;
  footerComp?: JSX.Element | JSX.Element[];
}

const CustomAccordion = (props: propsAccordion) => {
  const {
    accTitle,
    accBodyElement,
    initialOpenState = true,
    externalLinkFlag,
    onClickLink,
    headerComp,
    footerComp,
    headerTitleComp,
  } = props;
  const [accOpen, setAccOpen] = useState(initialOpenState);
  const isOpen = () => setAccOpen(!accOpen);
  //if(initialOpenState){setAccOpen(false)};

  return (
    <Accordion defaultActiveKey={initialOpenState ? "0" : ""}>
      <Card className="dataCard">
        <Accordion.Toggle
          as={Card.Header}
          eventKey="0"
          onClick={() => isOpen()}
        >
          <span>{accTitle}</span>
          {headerTitleComp && headerTitleComp}
          <div>
            <span
              style={{ color: colors.link }}
              onClick={(e: any) => e.stopPropagation()}
            >
              {headerComp && headerComp}
              {externalLinkFlag && (
                <BiLinkExternal
                  onClick={onClickLink}
                  className="basicTableHeaderIcon"
                />
              )}
            </span>
            {accOpen ? (
              <IoIosArrowDropup
                color="rgba(0, 0, 0, 0.5)"
                className="basicTableHeaderIcon"
              />
            ) : (
              <IoIosArrowDropdown
                color="rgba(0, 0, 0, 0.5)"
                className="basicTableHeaderIcon"
              />
            )}
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>{accBodyElement}</Card.Body>
        </Accordion.Collapse>
        {footerComp && <Card.Footer>{footerComp}</Card.Footer>}
      </Card>
    </Accordion>
  );
};

export default CustomAccordion;
