import { ONTIPState } from "../../../../states/account/diagnostics/ont/ONTIPState";
import {
  ONT_IP_BEGIN,
  ONT_IP_SUCCESS,
  ONT_IP_FAILURE,
  ONTIPActionTypes,
} from "../../../../types/account/diagnostics/ont/ONTIP";

const initialState: ONTIPState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function ONTIPReducer(
  state = initialState,
  action: ONTIPActionTypes
): ONTIPState {
  switch (action.type) {
    case ONT_IP_BEGIN:
      return {
        isLoading: true,
      };
    case ONT_IP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case ONT_IP_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}
