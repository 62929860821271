import "date-fns";
import { format, addDays } from "date-fns";
import { Form, DropdownButton, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useEffect, useState } from "react";
import { Card } from "@material-ui/core";
import ErrorBox from "../../reusable/ErrorBox";
import appLabels from "../../../constants/App_Labels";
import { rpdInfoMacReset } from "../../../store/ducksPattern/rpd/rpdMac";
import {
  setMacAddress,
  rpdSwapReset,
  setOldDeviceType,
  setNewDeviceType,
  setNptId,
  setRPDEmails,
} from "../../../store/ducksPattern/rpd/rpdSwap";
import {
  formatMacAddress,
  isMacAddressValid,
  formatRPDMacAddress,
  formatNPTNCTIMT,
  isRPDMacAddressValid,
} from "../../reusable/Util";
import RPDClient from "../../../network/httpclient/rpd/RPDClient";
import { TAF_ORDER_CONST } from "../../../constants/Global";
import EmailEntry from "../../reusable/EmailEntry";
import ChipGroup from "../../reusable/ChipGroup";
import { styles } from "@material-ui/pickers/views/Calendar/Calendar";

const RPDSwapTop = (props: any) => {
  const { oldMacAdd, techData, isLoading } = props;
  const labels = appLabels();
  const [oldMacAd, setOldMacAd] = useState(formatMacAddress(oldMacAdd));
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("This is Error Message");
  const [nptId, setNPTId] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const oldDeviceList = ["DA2000", "DA2200"];
  const [oldDevice, setOldDevice] = useState("DA2000");
  const [emails, setEmails] = useState([
    techData?.lanId !== null ? techData?.lanId : "",
  ]);

  const dispatch = useDispatch();

  const onNptIdSubmit = (e: any) => {
    dispatch(rpdInfoMacReset());
    dispatch(rpdSwapReset());
    let sv = formatNPTNCTIMT(e.target.value);
    setNPTId(sv);
  };

  useEffect(() => {
    dispatch(rpdInfoMacReset());
    dispatch(rpdSwapReset());
    dispatch(setOldDeviceType(oldDevice));
  }, [oldDevice]);

  const onOldMacAdSubmit = (e: any) => {
    dispatch(rpdInfoMacReset());
    dispatch(rpdSwapReset());
    let sv = formatMacAddress(e.target.value);
    if (sv?.length <= 12) setOldMacAd(sv);
  };

  useEffect(() => {
    if (isEmailValid) {
      dispatch(setRPDEmails(emails.toString()));
    }
  }, [isEmailValid]);

  const setEmailsdata = (data: any) => {
    setEmails(data);
    dispatch(setRPDEmails(data.toString()));
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleApplySwap = (oldMacAd: string) => {
    dispatch(rpdInfoMacReset());
    dispatch(rpdSwapReset());
    if (isRPDMacAddressValid(oldMacAd)) {
      let stringMacAdd = formatMacAddress(oldMacAd);
      dispatch(setMacAddress(stringMacAdd));
      dispatch(setOldDeviceType(oldDevice));
      dispatch(setNptId(nptId));
      dispatch(setRPDEmails(emails.toString()));

      if (nptId?.length > 0) {
        getOldMacAddressDetails(stringMacAdd);
      } else {
        setOpen(true);
        setError(labels.nptIdImtIdEmptyError);
      }
    } else {
      setOpen(true);
      setError(labels.oldMacAddressEmptyError);
    }
  };

  const createInputMacAdObject = (macAddress: string): any => {
    let inputObject = {
      rpdmacAddress: macAddress,
    };
    return inputObject;
  };

  const getOldMacAddressDetails = (oldMacAdd: string) => {
    if (oldMacAdd?.length === 12) {
      let stringMacAdd = formatRPDMacAddress(oldMacAdd);
      dispatch(
        RPDClient.getRPDDetailsOnMacAdd(
          TAF_ORDER_CONST.rpdSearchbyMacAddressBotName,
          TAF_ORDER_CONST.rpdSearchbyMacAddressSuiteName,
          createInputMacAdObject(stringMacAdd)
        )
      );
    } else {
      setOpen(true);
      setError(labels.oldMacAddressEmptyError);
    }
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleApplySwap(oldMacAd);
    }
  };

  const DropdownMenu = ({ label, data, value, onSelect }: any) => (
    <div className="deviceTypeCont">
      <Form.Label className="rpdFormLabel">{label}</Form.Label>
      <DropdownButton
        title={value}
        onSelect={(v: any) => onSelect(v)}
        size="sm"
        className="deviceTypeDropd"
        id="deviceTypeDd"
      >
        {data.map((e: any, i: number) => (
          <Dropdown.Item key={i} eventKey={e}>
            {e}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );

  return (
    <>
      <Card className="filterRootContainer">
        <div className="filterContainer">
          <div className="filterRow">
            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.oldRPDMacAddress}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="rpdInputField"
                  onChange={onOldMacAdSubmit}
                  className="rpdInputField"
                  id="rpdInputField"
                  value={oldMacAd}
                  placeholder={labels.typeOldRPDMacAddress}
                />
              </Form.Group>
            </div>

            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.nptIdimtId}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="rpdInputField"
                  onChange={onNptIdSubmit}
                  className="rpdInputField"
                  id="rpdInputField"
                  value={nptId}
                  onKeyPress={(e: any) => handleKeyPress(e)}
                  placeholder={labels.typeNptIDImtID}
                />
              </Form.Group>
            </div>
          </div>

          <div className="filterRow">
            <EmailEntry
              title={labels.rpdEmail}
              email={emails}
              setEmail={setEmailsdata}
              setIsEmailValid={setIsEmailValid}
              titleStyle={{
                fontSize: "12px",
                fontWeight: 500,
                margin: "0px",
                color: "#000",
              }}
              buttonStyle={{
                fontSize: "12px",
                background: "none",
                border: "none",
                padding: "0px",
                color: "#da291c",
              }}
              emailInputStyle={{
                fontSize: "12px",
                color: "#000",
                paddingLeft: "5px",
                fontWeight: 500,
                borderRadius: "5px",
                border: "1px solid #808284",
              }}
            />
          </div>
          <div className="filterRow">
            <span className="cardContentLabel">Note: </span>
            <span className="cardContentText">{labels.rpdSwapNote}</span>
          </div>
          <div className="filterRow">
            <button
              disabled={isLoading}
              className="applyFilersBtn"
              onClick={() => handleApplySwap(oldMacAd)}
            >
              {labels.fetchDetails}
            </button>
          </div>
        </div>
      </Card>
      <ErrorBox
        open={open}
        error={error}
        setOpen={setOpen}
        handleClose={handleClose}
      />
    </>
  );
};
export default RPDSwapTop;
