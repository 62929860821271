import { GroundOverlay } from "@react-google-maps/api";
import React from "react";
import { mapElementStyles } from "../../../../config/mapItemsConfig";
import appConst from "../../../../constants/appConst";
import { getMapEleImageObj } from "../../../../util/utilFuncs";
interface Props {
  mduPassivesData?: any;
  pngImages?: any;
}

const MduPassives = React.memo(({ mduPassivesData, pngImages }: Props) => {
  return (
    <>
      {mduPassivesData?.features?.map((feature: any) => {
        const propertiesData = feature?.properties?.data;
        const type = feature?.properties?.type;
        const eleImageOverlay: any = getMapEleImageObj(
          pngImages?.[type],
          propertiesData?.symbolName,
          propertiesData?.rotation,
          mapElementStyles?.[type],
          feature?.geometry?.coordinates?.[1],
          feature?.geometry?.coordinates?.[0]
        );

        return (
          <GroundOverlay
            key={feature?.featureId}
            url={`${appConst.base64StringPrefix}${eleImageOverlay?.symbol}`}
            bounds={
              new google.maps.LatLngBounds(
                new google.maps.LatLng(
                  eleImageOverlay?.southBound,
                  eleImageOverlay?.westBound
                ),
                new google.maps.LatLng(
                  eleImageOverlay?.northBound,
                  eleImageOverlay?.eastBound
                )
              )
            }
          />
        );
      })}
    </>
  );
});

export default MduPassives;
