import { IoMdLocate } from "react-icons/io";
import {
  getValueByStrMapping,
  isNull,
} from "../../../components/reusable/Util";
import { RENS_Search_Keys } from "../../config/mapItemsConfig";
import {
  combineAllNodeEle,
  findRENSElebyID,
  isPSAvailable,
} from "../../util/utilFuncs";
import { GetNodeSiteDetailsProps } from "../../util/reduxFunctions/getTopologyState";
import { useDispatch } from "react-redux";
import {
  setMapCenter,
  setSelectedMapElement,
} from "../../store/slices/mapItems/mapDetails";
import appConst from "../../constants/appConst";

interface Props {
  eleData: any;
  attrKey: any;
}

const CustomAMETableVal = ({ attrKey, eleData }: Props) => {
  const dispatch = useDispatch();
  const isRENSLocator = RENS_Search_Keys.indexOf(attrKey) !== -1 ? true : false;
  const { allNodeData } = GetNodeSiteDetailsProps();
  let isRensAvail: boolean = false;
  let allPSEle: any = [];

  if (isRENSLocator) {
    allPSEle = combineAllNodeEle(appConst.powerSupply, allNodeData);
    isRensAvail = isPSAvailable(
      getValueByStrMapping(eleData, attrKey),
      allPSEle
    );
  }
  const locateRENS = (rensId: any) => {
    if (rensId) {
      let selePSEle = findRENSElebyID(rensId, allPSEle);
      if (selePSEle) {
        dispatch(setMapCenter(selePSEle?.geometry));
        dispatch(setSelectedMapElement(selePSEle));
      }
    }
  };

  return (
    <>
      {isRENSLocator ? (
        <span className="locatorVal">
          {isNull(getValueByStrMapping(eleData, attrKey))}
          {isRensAvail && (
            <IoMdLocate
              size={15}
              color="var(--link)"
              onClick={() => locateRENS(getValueByStrMapping(eleData, attrKey))}
            />
          )}
        </span>
      ) : (
        <span>{isNull(getValueByStrMapping(eleData, attrKey))}</span>
      )}
    </>
  );
};

export default CustomAMETableVal;
