import { ThunkAction } from "redux-thunk";import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";

import { WorkOrdersState } from "../../../store/states/technician/WorkOrdersState";
import {
  workOrdersBegin,
  workOrdersFailure,
  workOrdersSuccess,
} from "../../../store/actions/technicians/WorkOrdersAction";

const getISODate = (date: Date) => {
  let month =
    date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
  let todayDate = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
  return date.getFullYear() + "-" + month + "-" + todayDate;
};

export default class WorkOrdersClient {
  static getTechWorkOrders =
    (
      techID: string,
      technicianName: string,
      maintenanceRole: boolean
    ): ThunkAction<void, WorkOrdersState, unknown, Action<string>> =>
    async (dispatch) => {
      let todayDate: any = new Date();
      var prevDate: any = new Date();
      prevDate.setDate(todayDate.getDate() - 1);
      let startDate;
      if (maintenanceRole) {
        startDate = getISODate(prevDate).toString() + "T00:00:00.000Z";
      } else {
        startDate = getISODate(todayDate).toString() + "T00:00:00.000Z";
      }
      let endDate = getISODate(todayDate).toString() + "T23:59:59.000Z";
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL + "workorders/technician/" + techID,

        params: {
          startDate: startDate,
          endDate: endDate,
          techName: technicianName,
        },
      };
      dispatch(workOrdersBegin());
      HttpClient.get<any>(httpParameters)
        .then((workOrders) => {
          dispatch(workOrdersSuccess(workOrders));
          
          return workOrders;
        })
        .catch((error) => {
          dispatch(workOrdersFailure(error));
          return error;
        });
    };
}
