import { ServiceAreaState } from "../../../states/account/accountInfo/ServiceAreaState";
import {
  SERVICE_AREA_BEGIN,
  SERVICE_AREA_SUCCESS,
  SERVICE_AREA_FAILURE,
  ServiceAreaInfoActionTypes,
} from "../../../types/account/accountInfo/ServiceAreaInfo";

const initialState: ServiceAreaState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function ServiceAreaReducer(
  state = initialState,
  action: ServiceAreaInfoActionTypes
): ServiceAreaState {
  switch (action.type) {
    case SERVICE_AREA_BEGIN:
      return {
        isLoading: true,
      };
    case SERVICE_AREA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case SERVICE_AREA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}
