import { Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import { InfoBox, InfoWindow, Marker } from "@react-google-maps/api";
import TopologySymbols from "../../../reusable/TopologySymbols";
import { useEffect, useState } from "react";
import { BsArrowsMove } from "react-icons/bs";
import { markerToString } from "../../../../util/utilFuncs";
import colors from "../../../../config/colors";
import { mapElementStyles } from "../../../../config/mapItemsConfig";

interface Props {
  nodeBlock15Data: any;
  click: any;
  setClick: any;
}

const RFNodeBlocks15 = ({ nodeBlock15Data, click, setClick }: Props) => {
  const latLng = new google.maps.LatLng(
    nodeBlock15Data?.geometry?.coordinates?.[1],
    nodeBlock15Data?.geometry?.coordinates?.[0]
  );
  const lat=nodeBlock15Data?.geometry?.coordinates?.[1];
  const lng=nodeBlock15Data?.geometry?.coordinates?.[0];
  
  const position = {
    lat: Number(nodeBlock15Data?.geometry?.coordinates?.[1]),
    lng: Number(nodeBlock15Data?.geometry?.coordinates?.[0]),
  };
  const divStyle = {
    background: `white`,
  };
  const showInfoWindow = () => {
    setClick(false);
  };
  const onCloseClick = () => {
    setClick(false);
  };
  const [showInfoBox, setShowInfoBox] = useState(false);
  useEffect(() => {
    setShowInfoBox(true);
  }, []);

  return (
    <div onClick={showInfoWindow}>
      {click && (
        <InfoWindow
          position={position}
          options={{ pixelOffset: new google.maps.Size(75, 0) }}
          onCloseClick={onCloseClick}
        >
          <div style={divStyle}>
            <TopologySymbols
              name="rf_node_block15"
              color={`var(--iconColor)`}
              size={150}
            />
          </div>
        </InfoWindow>
      )}
      <Marker
      position={{lat,lng }}
      icon={{
        url: markerToString(
          <BsArrowsMove color={colors.greyLight} size={20} />
        ),
        anchor:new google.maps.Point(mapElementStyles?.common?.anchor?.x - 70,
          mapElementStyles?.common?.anchor?.y + 3)
      }}
      draggable
      cursor="move"
      zIndex={4000}
    >
     {showInfoBox && (<InfoBox
        position={latLng}
        options={{
          closeBoxURL: "",
          disableAutoPan: true,
          boxStyle: {
            width: "auto",
            padding: "5px",
          },
        }}
      >
        <Table className="annotationBlock">
          <TableBody>
            <TableRow>
              <TableCell>
                {nodeBlock15Data?.properties?.data?.tagText?.RF_DVC_ID}
              </TableCell>
              <TableCell>
                {nodeBlock15Data?.properties?.data?.tagText?.RF_PART_NO}
              </TableCell>
              <TableCell>
                {nodeBlock15Data?.properties?.data?.tagText?.RF_DGN_FREQ3}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </InfoBox>)}
      </Marker>
    </div>
  );
};

export default RFNodeBlocks15;
