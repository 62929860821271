import { STBSummaryState } from "../../../../states/account/diagnostics/stb/STBSummaryState";
import {
  STB_SUMMARY_BEGIN,
  STB_SUMMARY_SUCCESS,
  STB_SUMMARY_FAILURE,
  StbSummaryActionTypes,
} from "../../../../types/account/diagnostics/stb/STBSummary";

const initialState: STBSummaryState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function STBSummaryReducer(
  state = initialState,
  action: StbSummaryActionTypes
): STBSummaryState {
  switch (action.type) {
    case STB_SUMMARY_BEGIN:
      return {
        isLoading: true,
      };
    case STB_SUMMARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case STB_SUMMARY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}
