import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import AppLabels from "../../../constants/App_Labels";
import ConfigConst from "../../../constants/ConfigConst";
import { CUSTOMER_TYPE_CONFIG } from "../../../constants/Global";
import {
  GetActiveAccountDetails,
  GetFormHistory,
  GetFormInfo,
  GetTechInfo,
} from "../../../get-api/GetResponse";
import EmailAuditClient from "../../../network/httpclient/EmailAudit/EmailAuditClient";
import FormClient from "../../../network/httpclient/form/FormClient";
import EmailClient from "../../../network/httpclient/notification/EmailClient";
import CustomPopUp from "../../reusable/CustomPopUp";
import { convertHTMLtoStr, isNull } from "../../reusable/Util";
import FormBoilerplate from "../FormBoilerplate";
import DisplayEmailPreview from "./DisplayEmailPreview";
import PrepareEmailFormat from "./PrepareEmailFormat";
import WorkDetails from "./WorkDetails";

interface Props {
  setShowModal: any;
}

export default function LetterOfPermissionScreen({ setShowModal }: Props) {
  const labels = AppLabels();
  const dispatch = useDispatch();
  const formHistory = GetFormHistory();
  const accountNumber = GetActiveAccountDetails()?.accountNumber;
  const activeAccData = GetActiveAccountDetails()?.data;
  const formInfo = GetFormInfo();
  const techid = GetTechInfo()?.techId;
  const [isWOSelectionComplete, setIsWOSelectionComplete] = useState(false);
  const [isCTSelectionComplete, setIsCTSelectionComplete] = useState(false);
  const [woData, setWOData] = useState({} as any);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [showFlag, setShowFlag] = useState(false);
  const [workDetailsData, setWorkDetailsData] = useState({} as any);
  const [selectedWOID, setSelectedWOID] = useState(labels.Select);
  const [selectedCustType, setSelectedCustType] = useState(labels.Select);
  const {
    isLoading,
    status,
    formStatus,
    isFormLoading,
    refKey,
    EmailFormData,
    sentby,
  } = useSelector(
    (state: any) => ({
      isLoading: state.AccountState.sendEmailData.isLoading,
      status: state.AccountState.sendEmailData.status,
      formStatus: state.AccountState.FormInfo.status,
      isFormLoading: state.AccountState.FormInfo.isLoading,
      refKey: state.AccountState.FormInfo?.data?.id,
      EmailFormData: state.AccountState.FormInfo?.data?.formData,
      sentby: state.AccountState.FormInfo?.data?.technicianId,
    }),
    shallowEqual
  );
  const onSubmit = () => {
    const generateLOPData = {
      formName: ConfigConst.LETTER_OF_PERMISSION,
      workorderId: selectedWOID,
      accountNumber: accountNumber,
      formData: JSON.stringify({ ...woData, ...workDetailsData }),
    };
    if (formHistory?.data?.id) {
      dispatch(FormClient.updateForm(generateLOPData, formHistory?.data?.id));
    } else dispatch(FormClient.postForm(generateLOPData));
    sendEmail();
  };

  const onPreview = () => {
    setShowFlag(true);
  };

  const sendEmail = () => {
    const franchise = CUSTOMER_TYPE_CONFIG?.[selectedCustType];
    const emailSub =
      ConfigConst.LOP_SUBJECT +
      (selectedCustType === ConfigConst.ROGERS
        ? "-" + ConfigConst.LOP_SUBJECT_ROGERS
        : selectedCustType === ConfigConst.FIDO
        ? "-" + ConfigConst.LOP_SUBJECT_FIDO
        : "");
    const htmlMessage = convertHTMLtoStr(
      <PrepareEmailFormat
        activeAccData={activeAccData}
        custType={selectedCustType}
        LOPbody={
          <DisplayEmailPreview
            woID={isNull(selectedWOID)}
            woType={isNull(woData?.orderType)}
            workDetails={workDetailsData}
          />
        }
      />
    );
    let emailPayload = {
      accountNumber: accountNumber,
      franchise: franchise,
      subject: emailSub,
      htmlMessage: htmlMessage,
      language: ConfigConst.LOP_LANGUAGE,
      firstName: activeAccData?.firstName,
      lastName: activeAccData?.lastName,
      attachFileName: ConfigConst.LOP_FILENAME,
      addresses: workDetailsData?.emails,
      formName: ConfigConst.LETTER_OF_PERMISSION.toUpperCase()
    };
    const base64EncObj = { data: btoa(JSON.stringify(emailPayload)) };
    dispatch(EmailClient.sendEmailNoti(labels.LopEmailMSG, base64EncObj));
  };

  const disableSubmit = () => {
    if (
      isWOSelectionComplete &&
      isCTSelectionComplete &&
      workDetailsData?.workRequired?.length > 0 &&
      workDetailsData?.workApprobation != undefined &&
      workDetailsData?.signature != "" &&
      workDetailsData?.emails != "" &&
      isEmailValid &&
      !formInfo.isLoading
    ) {
      return false;
    } else return true;
  };

  useEffect(() => {
    if (selectedCustType !== undefined && selectedCustType !== labels.Select) {
      setIsCTSelectionComplete(true);
    }
  }, [selectedCustType]);
  useEffect(() => {
    const generateEmailData = {
      componentType: ConfigConst.LETTER_OF_PERMISSION,
      refKey: refKey,
      sentTo: JSON.stringify({ ...workDetailsData?.emails }),
      sentby: sentby,
      techid: techid,
    };
    if (workDetailsData?.emails != "") {
      // sendEmail();
      if (
        isLoading === false &&
        status === "OK" &&
        formStatus === "OK" &&
        isFormLoading === false &&
        EmailFormData !== undefined
      ) {
        if (formHistory?.data?.id) {
          dispatch(
            EmailAuditClient.updateForm(
              generateEmailData,
              formHistory?.data?.id
            )
          );
        } else {
          dispatch(EmailAuditClient.postForm(generateEmailData));
        }
      }
    }
  }, [status, formStatus, isFormLoading]);
  return (
    <>
      <FormBoilerplate
        isWOSelectionComplete={isWOSelectionComplete}
        setIsWOSelectionComplete={setIsWOSelectionComplete}
        setWOData={setWOData}
        formLabel={labels.LOP}
        formName={ConfigConst.LETTER_OF_PERMISSION}
        onSubmit={onSubmit}
        onPreview={onPreview}
        disableSubmit={disableSubmit}
        selectedWOID={selectedWOID}
        setSelectedWOID={setSelectedWOID}
        setShowEntireModal={setShowModal}
        selectedCustType={selectedCustType}
        setSelectedCustType={setSelectedCustType}
      >
        <WorkDetails
          setIsEmailValid={setIsEmailValid}
          setWorkDetailsData={setWorkDetailsData}
        />
      </FormBoilerplate>
      <CustomPopUp
        showVal={showFlag}
        headerText={labels.Email_Preview.toUpperCase()}
        bodyElement={
          <PrepareEmailFormat
            activeAccData={activeAccData}
            custType={selectedCustType}
            LOPbody={
              <DisplayEmailPreview
                woID={isNull(selectedWOID)}
                woType={isNull(woData?.orderType)}
                workDetails={workDetailsData}
              />
            }
          />
        }
        handleClose={() => setShowFlag(false)}
      />
    </>
  );
}
