import { createSlice } from "@reduxjs/toolkit";
import {
  convertToLatLngLiteral,
  getUniqueMapData,
  isInsideViewPort,
} from "../../../util/utilFuncs";
import { apiCallBegan } from "../../middleware/apiActions";
import {
  NODE_BOUNDARIES_URI,
  POLYGON_ID_URI,
} from "../../middleware/apiEndpoints";
import { decrApiCall, incrApiCall } from "./mapDetails";
import { IAPIStatus } from "../../reduxTypes";
import { ILatLng } from "../../../util/types";

const slice = createSlice({
  name: "mapPolygons",
  initialState: {
    data: undefined,
    isLoading: false,
    error: undefined,
    status: "",

    idData: undefined,
    idIsLoading: false,
    idError: undefined,
    idStatus: "",
  } as IMapPolygons,
  reducers: {
    getApiBDYBegin: (mapPolygons) => {
      mapPolygons.isLoading = true;
    },
    getApiBDYSuccess: (mapPolygons, action) => {
      let newFeatureList: any = action.payload;
      let currMapPolygons: any;
      if (newFeatureList?.features?.length > 0) {
        let tempFeatures = newFeatureList?.features?.map((f: any) => {
          const featureID =
            f?.properties?.data?.networkId + f?.properties?.data?.jobId;
          let featureProp = {
            ...f?.properties,
            ...{ featureId: featureID, isSelected: false },
          };
          const coords = convertToLatLngLiteral(f?.geometry?.coordinates?.[0]);
          return { ...f, ...{ geometry: coords, properties: featureProp } };
        });
        newFeatureList = { ...newFeatureList, ...{ features: tempFeatures } };
      }

      if (mapPolygons?.data !== undefined) {
        currMapPolygons = JSON.parse(JSON.stringify(mapPolygons?.data));
      }
      let combinedPolygons = getUniqueMapData(currMapPolygons, newFeatureList);
      mapPolygons.isLoading = false;
      mapPolygons.data = combinedPolygons;
      mapPolygons.status = "OK";
    },
    getApiBDYFailure: (mapPolygons, action) => {
      mapPolygons.isLoading = false;
      mapPolygons.error = action.payload;
      mapPolygons.data = undefined;
      mapPolygons.status = "ERROR";
    },
    polygonIdBegin: (state) => {
      state.idIsLoading = true;
      state.idError = undefined;
      state.idStatus = "";
    },
    polygonIdSuccess: (state, { payload }) => {
      state.idIsLoading = false;
      state.idData = payload;
      state.idStatus = "OK";
    },
    polygonIdFailure: (state, { payload }) => {
      state.idIsLoading = false;
      state.idError = payload;
      state.idData = undefined;
      state.idStatus = "ERROR";
    },
  },
});

//should not be exposed to outside
const {
  getApiBDYBegin,
  getApiBDYSuccess,
  getApiBDYFailure,
  polygonIdBegin,
  polygonIdSuccess,
  polygonIdFailure,
} = slice.actions;
export default slice.reducer;

let viewPortPoly = undefined as any;
//action creators
export const getApiPolygons =
  (nE: any, sW: any): any =>
  (dispatch: any) => {
    const neLat = nE?.lat;
    const neLng = nE?.lng;
    const swLat = sW?.lat;
    const swLng = sW?.lng;

    const makeApiCall = () => {
      const requestData = {
        topRight: { latitude: neLat, longitude: neLng },
        bottomLeft: { latitude: swLat, longitude: swLng },
      };

      dispatch(
        apiCallBegan({
          url: NODE_BOUNDARIES_URI,
          data: requestData,
          method: "post",
          onStart: getApiBDYBegin.type,
          onStartDispatch: incrApiCall.type,
          onSuccess: getApiBDYSuccess.type,
          onSuccessDispatch: decrApiCall.type,
          onError: getApiBDYFailure.type,
          onErrorDispatch: decrApiCall.type,
        })
      );
    };

    //only make api call if current view is a new view
    if (viewPortPoly) {
      const { isWVP, newViewPortPoly } = isInsideViewPort(
        [neLat, neLng],
        [swLat, swLng],
        viewPortPoly
      );
      //make api call if its a new view
      if (!isWVP) {
        viewPortPoly = newViewPortPoly;
        makeApiCall();
      }
    } else {
      viewPortPoly = [
        [
          [neLat, swLng],
          [neLat, neLng],
          [swLat, neLng],
          [swLat, swLng],
        ],
      ];
      makeApiCall();
    }
  };

export const getPolygonId = ({ lat, lng }: ILatLng): any =>
  apiCallBegan({
    url: POLYGON_ID_URI({ lat, lng }),
    onStart: polygonIdBegin.type,
    onSuccess: polygonIdSuccess.type,
    onError: polygonIdFailure.type,
  });

interface IDData {
  id: string;
  name: string;
  type: string;
}
export interface IMapPolygons {
  data?: any;
  isLoading: boolean;
  error: any;
  status: IAPIStatus;

  idData?: IDData[];
  idIsLoading: boolean;
  idError: any;
  idStatus: IAPIStatus;
}
