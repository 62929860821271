import AppLabels from "../../constants/App_Labels";
import CustomAccordion from "../../components/reusable/CustomAccordion";
import { isNull } from "../../components/reusable/Util";
import LoaderGif from "../../resources/images/loader.webp";

const GponDictDetails = (props: any) => {
  const { deviceData, accordTitle } = props;
  const labels = AppLabels();

  return (
    <CustomAccordion
      accTitle={accordTitle.toUpperCase()}
      accBodyElement={
        <>
          <table className="dataTableDetails">
            <thead>
              <tr>
                <th style={{ width: "10%" }}>
                  {labels.field_name.toUpperCase()}
                </th>
                <th id="valueCol">{labels.Description.toUpperCase()}</th>
              </tr>
            </thead>
            <tbody>
              {deviceData !== undefined ? (
                <>
                  {deviceData?.map((ele: any, i: number) => {
                    return Object.entries(ele)?.map((a: any) => {
                      return (
                        <>
                          <tr key={"tableHeaders" + (i * i - i)}>
                            <td>{isNull(a[0])}</td>
                            {a[1] instanceof Array ? (
                              <>
                                <td key={"tableCol" + (i * i - i)}>
                                  {Object.values(a[1])?.map((eleVal) => (
                                    <span>
                                      {isNull(eleVal)}
                                      <br />
                                    </span>
                                  ))}
                                </td>
                              </>
                            ) : (
                              <>
                                <td key={"tableCol1" + (i * i - i)}>
                                  {isNull(a[1])}
                                </td>
                              </>
                            )}
                          </tr>
                        </>
                      );
                    });
                  })}
                </>
              ) : (
                <tr className="inlineError">
                  <td className="inlineError" colSpan={2}>
                    Data is not Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      }
    />
  );
};

export default GponDictDetails;
