import { Refresh } from "@material-ui/icons";
import { Button } from "react-bootstrap";

interface Props {
  bannerData?: any;
  refresh?: boolean;
  refreshTime?: string;
  handleRefresh?: (e: any) => void;
  onActionClick?(args: any, item: any): void;
  tabIndex?: number;
}

const UtilityToolBanner = (props: Props) => {
  const { refresh, tabIndex } = props;

  return (
    <div className="cmtsBanner">
      <div className="cmtsBannerTitleContainer">
        <div className="cmtsBannerLabel">
          <h5 style={{ fontWeight: "bold", margin: 0 }}>UTILITY TOOL</h5>
        </div>
        <div className="cmtsBannerLabelContainer"></div>
      </div>
      <div className="cmtsBannerBtns">
        <div className="cmtsBannerBtnsWrapper">
          {tabIndex === 0 && (
            <Button
              className="refreshCertiBtn"
              variant="outline-primary"
              size="sm"
              onClick={(e) => {
                if (!refresh) {
                  window.location.reload();
                }
              }}
              disabled={refresh}
            >
              Restart
              <Refresh />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default UtilityToolBanner;
