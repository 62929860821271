import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import AppLabels from "../../../constants/App_Labels";
import ConfigConst from "../../../constants/ConfigConst";
import {
  GetActiveAccountDetails,
  GetFormHistory,
  GetFormInfo,
  GetRpaInfo,
  GetTechInfo,
} from "../../../get-api/GetResponse";
import FormClient from "../../../network/httpclient/form/FormClient";
import EmailClient from "../../../network/httpclient/notification/EmailClient";
import {
  sendRpaReset,
  sendRpaSuccessReset,
} from "../../../store/ducksPattern/sendRpaData";
import CustomPopUp from "../../reusable/CustomPopUp";
import ResponseSnackBar from "../../reusable/ResponseSnackBar";
import { convertHTMLtoStr, isLegacyAccount, isNull } from "../../reusable/Util";
import EmailAuditClient from "../../../network/httpclient/EmailAudit/EmailAuditClient";
import RpaClient from "../../../network/httpclient/rpa/RpaClient";
import FormBoilerplate from "../FormBoilerplate";
import DisplayEmailPreviewNoise from "./DisplayEmailPreviewNoise";
import NoiseDetails from "./NoiseDetails";
import PrepareEmailFormatNoise from "./PrepareEmailFormatNoise";

interface Props {
  setShowModal: any;
}

const NoiseScreen = ({ setShowModal }: Props) => {
  const labels = AppLabels();
  const dispatch = useDispatch();
  const formHistory = GetFormHistory();
  const accountNumber = GetActiveAccountDetails()?.accountNumber;
  const activeAccData = GetActiveAccountDetails()?.data;
  const formInfo = GetFormInfo();
  const rpaInfo = GetRpaInfo();
  const techid= GetTechInfo()?.techId;
  const [distributionEmail, setEmail] = useState();
  const [isWOSelectionComplete, setIsWOSelectionComplete] = useState(false);
  const [isCTSelectionComplete, setIsCTSelectionComplete] = useState(false);
  const [woData, setWOData] = useState({} as any);
  const [selectedWOID, setSelectedWOID] = useState(labels.Select);
  const [noiseDetails, setNoiseDetails] = useState({} as any);
  const [showFlag, setShowFlag] = useState(false);
  const [selectedCustType, setSelectedCustType] = useState(labels.Select);
  const [showPreview, setShowPreview] = useState(false);
  const [bannerVisible, setBannerVisible] = useState(false);
  const workOrderData = [labels.No_Work_Order, labels.Manual_Entry];
  const formData = GetFormHistory()?.formData;
  const form = GetFormHistory()?.data;
  const [test, setTest] = useState(false);
  const {
    isLoading,
    status,
    formStatus,
    isFormLoading,
    refKey,
    EmailFormData,
    sentby,
  } = useSelector(
    (state: any) => ({
      isLoading: state.AccountState.sendEmailData.isLoading,
      status: state.AccountState.sendEmailData.status,
      formStatus: state.AccountState.FormInfo.status,
      isFormLoading: state.AccountState.FormInfo.isLoading,
      refKey: state.AccountState.FormInfo?.data?.id,
      EmailFormData: state.AccountState.FormInfo?.data?.formData,
      sentby: state.AccountState.FormInfo?.data?.technicianId,
    }),
    shallowEqual
  );
  const onSubmit = () => {
    if (!formHistory?.data?.id) {
      if (
        selectedCustType === ConfigConst.TPIA ||
        noiseDetails?.hotToCold === labels?.Yes ||
        noiseDetails?.bellModem === labels?.Yes ||
        noiseDetails?.above10Units === labels?.Yes
      ) {
        sendEmail();
        saveToDatabase();
      } else {
        dispatch(sendRpaReset());
        sendNoise();
      }
    }
  };
  useEffect(() => {
    if (formData && formData !== "") {
      if (form?.formName === ConfigConst.NOISE_REFERRAL) {
        setTest(true);
        // setNoiseDetails(form?.formData);
        // setSelectedCustType(form?.formData?.selectedCustType);
      }
    }
  }, [formData]);
  const saveToDatabase = (combinedDetails?: any) => {
    if (!formHistory?.data?.id) {
      const generateData = {
        formName: ConfigConst.NOISE_REFERRAL,
        workorderId:
          selectedWOID === labels.No_Work_Order
            ? ConfigConst.NO_WORK_ORDER
            : selectedWOID,
        accountNumber,
        formData: combinedDetails
          ? JSON.stringify({ ...woData, ...combinedDetails, selectedCustType })
          : JSON.stringify({ ...woData, ...noiseDetails, selectedCustType }),
        nodeNumber: noiseDetails?.nodeNumber,
      };
      dispatch(FormClient.postForm(generateData));
    }
  };

  const sendNoise = () => {
    const sendObj = {
      TPIA: noiseDetails?.tpia,
      ModemMACAddress: JSON.stringify(noiseDetails?.CMMac),
      STBMACAddress: JSON.stringify(noiseDetails?.STBMac),
      EMTAMACAddress: JSON.stringify(noiseDetails?.EMTAMac),
      HHID: noiseDetails?.hhid,
      SGIAccountNo: noiseDetails?.accountNumber,
      TechnicianName: noiseDetails?.techName,
      TechnicianID: noiseDetails?.technicianId,
      HouseNo: noiseDetails?.streetNumber,
      StreetName: noiseDetails?.streetName,
      StreetDirection: noiseDetails?.streetDirection,
      UnitNoOrSuiteNo: noiseDetails?.unitOrSuiteNumber,
      City: noiseDetails?.city,
      NodeNo: noiseDetails?.nodeNumber,
      CBPID: noiseDetails?.cbpId,
      ManagerName: noiseDetails?.managerName,
      HotToCold: noiseDetails?.hotToCold,
      BellModem: noiseDetails?.bellModem,
      F2InformationPrePadding: noiseDetails?.f2Information_PrePadding,
      FilteredOrPadded: noiseDetails?.filteredOrPadded,
      FilterOrPadInfo: noiseDetails?.filterOrPadInfo,
      F2InformationPostPadding: noiseDetails?.f2Information_PostPadding,
      Above10Units: noiseDetails?.above10Units,
      Priority: noiseDetails?.priority,
      LegacyOrIgniteOrFido: noiseDetails?.legacyOrIgniteOrFide,
      // customerContactNumber: noiseDetails?.customerContactNumber,
      Comments: noiseDetails?.comments,
    };
    dispatch(RpaClient.postToQueue(ConfigConst.NOISE_REFERRAL, sendObj));
  };

  const sendEmail = (distroEmail?: any) => {
    const franchise = "T";
    const emailSub = labels.Noise_Referral;
    const htmlMessage = convertHTMLtoStr(
      <PrepareEmailFormatNoise
        body={
          <DisplayEmailPreviewNoise
            woID={isNull(selectedWOID)}
            woType={isNull(woData?.orderType)}
            workDetails={noiseDetails}
            isLegacyAccount={isLegacyAccount}
          />
        }
      />
    );
    let emailPayload = {
      accountNumber: accountNumber,
      franchise: franchise,
      subject: emailSub,
      htmlMessage: htmlMessage,
      language: "EN",
      firstName: activeAccData?.firstName,
      lastName: activeAccData?.lastName,
      addresses: distroEmail ? distroEmail : noiseDetails?.distroEmails,
      formName: labels.Noise_Referral.toUpperCase()
      // addresses: ["lakshmi.narayanan1@rci.rogers.ca"],
    };
    const base64EncObj = { data: btoa(JSON.stringify(emailPayload)) };
    dispatch(EmailClient.sendEmailNoti("Noise Email", base64EncObj));
  };

  const onPreview = () => {
    setShowFlag(true);
  };

  const disableSubmit = () => {
    if (
      isWOSelectionComplete &&
      isCTSelectionComplete &&
      noiseDetails &&
      !formInfo.isLoading
    )
      return false;
    else return true;
  };

  useEffect(() => {
    if (
      (selectedCustType !== undefined && selectedCustType !== labels.Select) ||
      test === true
    ) {
      setIsCTSelectionComplete(true);
    }
  }, [selectedCustType, test]);

  const postBannerAction = () => {
    setBannerVisible(false);
  };

  useEffect(() => {
    // if (noiseInfo?.success === false && noiseInfo?.sentAttemptErrors < 3) {
    //   setTimeout(() => {
    //     sendNoise();
    //   }, 2000);
    // } else
    if (
      rpaInfo?.success === false
      // noiseInfo?.sentAttemptErrors === 3
    ) {
      setBannerVisible(true);
      dispatch(sendRpaSuccessReset());
      const combinedDetails = {
        rpaStatus: "Failure",
        ...noiseDetails,
      };
      sendEmail(["Noise.Hotline@rci.rogers.com"]);
      saveToDatabase(combinedDetails);
    }
    if (rpaInfo?.success === true) {
      setBannerVisible(true);
      dispatch(sendRpaSuccessReset());
      const combinedDetails = {
        formSubmissionNo: rpaInfo?.data?.Reference,
        rpaStatus: "Success",
        ...noiseDetails,
      };
      if (rpaInfo?.data?.Reference) {
        saveToDatabase(combinedDetails);
      } else {
        saveToDatabase(combinedDetails);
      }
    }
  }, [
    // noiseInfo?.sentAttemptErrors,
    rpaInfo?.success,
    rpaInfo?.data?.Reference,
  ]);
  useEffect(() => {
    const generateEmailData = {
      componentType: ConfigConst.NOISE_REFERRAL,
      refKey: refKey,
      sentTo: JSON.stringify({ ...noiseDetails?.distroEmails }),
      sentby: sentby,
      techid:techid,
    };
    if (noiseDetails?.distroEmails?.length > 0) {
      // sendEmail();
      if (
        isLoading === false &&
        status === "OK" &&
        formStatus === "OK" &&
        isFormLoading === false &&
        EmailFormData !== undefined
      ) {
        if (formHistory?.data?.id) {
          dispatch(
            EmailAuditClient.updateForm(
              generateEmailData,
              formHistory?.data?.id
            )
          );
        } else {
          dispatch(EmailAuditClient.postForm(generateEmailData));
        }
      }
    }
  }, [status, formStatus, isFormLoading]);
  return (
    <>
      <FormBoilerplate
        isWOSelectionComplete={isWOSelectionComplete}
        setIsWOSelectionComplete={setIsWOSelectionComplete}
        setWOData={setWOData}
        formLabel={labels.NRF}
        formName={ConfigConst.NOISE_REFERRAL}
        onSubmit={onSubmit}
        onPreview={onPreview}
        showPreview={showPreview}
        disableSubmit={disableSubmit}
        selectedWOID={selectedWOID}
        setSelectedWOID={setSelectedWOID}
        setShowEntireModal={setShowModal}
        disableFormUpdate={true}
        selectedCustType={selectedCustType}
        setSelectedCustType={setSelectedCustType}
        workOrderData={workOrderData}
      >
        <NoiseDetails
          setDetails={setNoiseDetails}
          selectedCustType={selectedCustType}
          setShowPreview={setShowPreview}
        />
      </FormBoilerplate>
      <CustomPopUp
        showVal={showFlag}
        headerText={labels.Email_Preview.toUpperCase()}
        bodyElement={
          <PrepareEmailFormatNoise
            body={
              <DisplayEmailPreviewNoise
                woID={isNull(selectedWOID)}
                woType={isNull(woData?.orderType)}
                workDetails={noiseDetails}
                isLegacyAccount={isLegacyAccount}
              />
            }
          />
        }
        handleClose={() => setShowFlag(false)}
      />
      <ResponseSnackBar
        open={bannerVisible}
        requestType={ConfigConst.NOISE_REFERRAL}
        handleClose={postBannerAction}
        responseType={rpaInfo?.status}
      />
    </>
  );
};

export default NoiseScreen;
