import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { EmailFormState } from "../../../store/states/account/forms/EmailFormState";

import {
  SaveEmailFormSuccess,
  SaveEmailFormBegin,
  SaveEmailFormFailure,
  SaveEmailFormReset,
} from "../../../store/actions/account/forms/FormEmailAuditAction";


export default class EmailAuditClient {
  static postForm =
    (data: any): ThunkAction<void, EmailFormState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "audit/" + data?.refKey + "/auditForm",
      };
      dispatch(SaveEmailFormBegin());
      HttpClient.post<any>(httpParameters, data)
        .then((res) => {
          dispatch(SaveEmailFormSuccess(res));
          return res;
        })
        .catch((error) => {
          dispatch(SaveEmailFormFailure(error));
          return error;
        });
    };

  static updateForm =
    (
      data: any,
      id: String
    ): ThunkAction<void, EmailFormState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "audit/" + id + "/auditForm/"+ data?.componentType
      };
      dispatch(SaveEmailFormBegin());
      HttpClient.post<any>(httpParameters, data)
        .then((res) => {
          dispatch(SaveEmailFormSuccess(res));
          return res;
        })
        .catch((error) => {
          dispatch(SaveEmailFormFailure(error));
          return error;
        });
    };

 
}
