export const HISTORY_BEGIN = "HISTORY_BEGIN";
export const HISTORY_SUCCESS = "HISTORY_SUCCESS";
export const HISTORY_RESET = "HISTORY_RESET";
export const HISTORY_FAILURE = "HISTORY_FAILURE";

interface historyBegin {
  type: typeof HISTORY_BEGIN;
}

interface historySuccess {
  type: typeof HISTORY_SUCCESS;
  payload: any;
}

interface historyReset {
  type: typeof HISTORY_RESET;
  payload: any;
}

interface historyFailure {
  type: typeof HISTORY_FAILURE;
  payload: Error;
}

export type HistoryActionTypes =
  | historyBegin
  | historySuccess
  | historyReset
  | historyFailure;
