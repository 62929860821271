import * as React from "react";
import { useRef, useState, } from "react";
import { useDispatch } from "react-redux";
import { PONOLTTable } from "./PONOLTTable";
import { useGponNodeClient } from "../../network/httpclient/gpon/GponNodeClient";
import { GetPONONTDetails } from "../../get-api/GetResponse";
import { getTelemetryHomeAddress } from "../../components/reusable/NodeUtil";
import { getOLTListData,getONTSummaryData } from "../../network/httpclient/gpon/PONFluxClient";
type Props = {
  children?: React.ReactNode;
  data?: any;
  ponNode?:any;
  open?: boolean;
  ponOltName?: any;
  setOpen?: (open: boolean) => void;
};

export const PONOLTTelemetryTab = (props: Props) => {
  const { data,ponNode } = props;
  const { nodeSummaryList } = GetPONONTDetails();
  const nodeInfoApi = useGponNodeClient();
  const isRendered = React.useRef(false);
  const [appendData,setAppendToTableData]= useState(data);
  const dispatch = useDispatch();

  const getNodeSummary = React.useCallback(async () => {
    if (data !== undefined && data.length > 0 && isRendered.current === false) {
      dispatch(getONTSummaryData({oltData: nodeSummaryList}));  
      isRendered.current = true;
    }
 
  }, [data, nodeInfoApi]);

  React.useEffect(() => {
    getNodeSummary();
  }, [getNodeSummary]);

  return (
    <PONOLTTable nodeSummary={data}ponNode={ponNode} ponOltName={props?.ponOltName} open={props?.open} setOpen={props.setOpen} />
  );
};