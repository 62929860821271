import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { NetworkConfig } from "../NetworkConfig";
import { AccountDetailsState } from "../../../store/states/account/accountInfo/AccountDetailsState";
import {
  AccountDetailsBegin,
  AccountDetailsFailure,
  AccountDetailsSuccess,
} from "../../../store/actions/account/accountInfo/AccountDetailsAction";
import { AccountDetails } from "../../../store/models/account/accountInfo/AccountDetails";
import { ServiceAreaState } from "../../../store/states/account/accountInfo/ServiceAreaState";
import {
  ServiceAreaInfoBegin,
  ServiceAreaInfoFailure,
  ServiceAreaInfoSuccess,
} from "../../../store/actions/account/accountInfo/ServiceAreaAction";
import { ServiceAreaInfo } from "../../../store/models/account/accountInfo/ServiceAreaInfo";
import { formatMacAddress } from "../../../components/reusable/Util";

export default class AccDetailsClient {
  static getAccDetails =
    (
      searchValue: string,
      searchType: string
    ): ThunkAction<void, AccountDetailsState, unknown, Action<string>> =>
    async (dispatch) => {
      const qParam = [];
      switch (searchType) {
        case "cbpId":
          qParam.push({ cbpId: searchValue });
          break;
        case "accountNumber":
          qParam.push({ accountNumber: searchValue });
          break;
        case "macAddress":
          let sV = formatMacAddress(searchValue);
          qParam.push({ macAddress: sV });
          break;
        case "tn":
          qParam.push({ tn: searchValue });
          break;
        case "ontSerialNumber":
          qParam.push({ ontSerialNumber: searchValue });
          break;
        default:
          qParam.push({ cbpId: searchValue });
          break;
      }
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "subscriptions",
        params: qParam?.[0],
      };

      dispatch(AccountDetailsBegin());

      HttpClient.get<AccountDetails>(httpParameters)
        .then((AccDetails) => {
          dispatch(AccountDetailsSuccess(AccDetails));
          return AccDetails;
        })
        .catch((error) => {
          dispatch(AccountDetailsFailure(error));
          return error;
        });
    };

  static getServiceAreaInfo =
    (
      samKey: string
    ): ThunkAction<void, ServiceAreaState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "serviceAddress/" + samKey,
      };
      dispatch(ServiceAreaInfoBegin());
      HttpClient.get<ServiceAreaInfo>(httpParameters)
        .then((saInfo) => {
          dispatch(ServiceAreaInfoSuccess(saInfo));
          return saInfo;
        })
        .catch((error) => {
          dispatch(ServiceAreaInfoFailure(error));
          return error;
        });
    };
}
