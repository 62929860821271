import { PONOLTInfo } from "../../models/gpon/PONOLTInfo";
import {
  PON_OLT_LIST_BEGIN,
  PON_OLT_LIST_SUCCESS,
  PON_OLT_LIST_FAILURE,
  RESET_PON_OLT_INFO,
  PonOltInfoActionTypes,
} from "../../types/gpon/PonOltInfo";
export function PonOltInfoBegin(): PonOltInfoActionTypes {
  return {
    type: PON_OLT_LIST_BEGIN,
  };
}

export function PonOltInfoSuccess(
  NodesInfo:PONOLTInfo
): PonOltInfoActionTypes {
  return {
    type: PON_OLT_LIST_SUCCESS,
    payload: NodesInfo,
  };
}

export function PonOltInfoFailure(error: Error): PonOltInfoActionTypes {
  return {
    type: PON_OLT_LIST_FAILURE,
    payload: error,
  };
}

export function PonOltInfoReset(): PonOltInfoActionTypes {
  return {
    type: RESET_PON_OLT_INFO,
  };
}