import { FormEvent, useEffect, useState } from "react";
import { Dropdown, DropdownButton, Form, FormControl } from "react-bootstrap";
import { format } from "date-fns";
import { MdDateRange } from "react-icons/md";
import { DateRange } from "react-date-range";
import { Divider } from "@material-ui/core";
import { useDispatch } from "react-redux";

import CustomAccordion from "../../../../components/reusable/CustomAccordion";
import { CPAT_CONFIG } from "../../../config/mapItemsConfig";
import TopologyLabels from "../../../constants/topologyLabels";
import { isNumeric } from "../../../util/utilFuncs";
import NumberInputStepper from "../../reusable/NumberInputStepper";
import RenderCPATRadioButtons from "./RenderCPATRadioButtons";
import CustomDialog from "../../../../components/reusable/CustomDialog";
import "../../../styles/drawer/cpat/Cpat.scss";
import { getCpatEvents } from "../../../store/slices/mapItems/cpat/cpatEvents";
import InlineErrorMessage from "../../../../components/reusable/InlineErrorMessage";
import appConst from "../../../constants/appConst";
import { GetGoogleMaps } from "../../../util/reduxFunctions/getTopologyState";
import colors from "../../../config/colors";

interface Props {
  setIsButtonClicked?: any;
}

export default function CPATFilters({ setIsButtonClicked }: Props) {
  const labels = TopologyLabels();
  const { currentMapCenter } = GetGoogleMaps();
  const [radiusValue, setRadiusValue] = useState("1000");
  const [midLeakageValue, setMidLeakageValue] = useState("");
  const [lteLeakageValue, setLteLeakageValue] = useState("");
  const [ingressValue, setIngressValue] = useState("");
  const [type, setType] = useState<boolean>(true);
  const [code, setCode] = useState<boolean>();
  const [event, setEvent] = useState<boolean>();
  const [seleTestType, setSeleTestType] = useState(labels.assignedUnassigned);
  const [minCodeValue, setMinCodeValue] = useState("");
  const [maxCodeValue, setMaxCodeValue] = useState("");
  const [eventValue, setEventValue] = useState("");
  const now = new Date();
  const [showDateRange, setShowDateRange] = useState(false);
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState("");
  const [daysBack, setDaysBack] = useState(CPAT_CONFIG.dfltDaysBack);
  const [dateRangeSelection, setDateRangeSelection] = useState([
    {
      startDate: new Date(now.getFullYear() - 1, now.getMonth(), now.getDate()),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const onDaysBackChange = (e: FormEvent<HTMLInputElement>) => {
    const val = isNumeric(e?.currentTarget?.value);
    if (val <= CPAT_CONFIG.maxDaysBack) {
      setDaysBack(val);
      setDateRangeSelection(getDateRangeFormat(now, val));
    }
  };

  const handleDateRangeSelect = (ranges: any) => {
    setDateRangeSelection([ranges.selection]);
    const dates = ranges.selection;
    const timeDiff = dates?.endDate?.getTime() - dates?.startDate?.getTime();
    const days = Math.ceil(timeDiff / (1000 * 3600 * 24));
    setDaysBack(days);
  };
  const onSearchPress = () => {
    if ((event && eventValue?.length === 0) || (event && isNaN(+eventValue))) {
      setErrorMsg("Please enter valid event number");
    } else if (
      !event &&
      dateRangeSelection?.[0]?.startDate > dateRangeSelection?.[0]?.endDate
    ) {
      setErrorMsg(labels.cpatDateValidation);
    } else if (
      (code &&
        (minCodeValue?.length === 0 ||
          maxCodeValue?.length === 0 ||
          isNaN(+minCodeValue) ||
          isNaN(+maxCodeValue) ||
          Number(minCodeValue) < CPAT_CONFIG.minCode ||
          Number(maxCodeValue) > CPAT_CONFIG.maxCode)) ||
      Number(minCodeValue) > Number(maxCodeValue)
    ) {
      setErrorMsg(
        `Status code must be between ${CPAT_CONFIG.minCode} ${labels.to} ${CPAT_CONFIG.maxCode}`
      );
    } /* else if (
      !event &&
      (isNaN(+midLeakageValue) ||
        Number(midLeakageValue) < CPAT_CONFIG.minMidLeakage ||
        Number(midLeakageValue) > CPAT_CONFIG.maxMidLeakage)
    ) {
      setErrorMsg(
        `Mid leakage must be between ${CPAT_CONFIG.minMidLeakage} ${labels.to} ${CPAT_CONFIG.maxMidLeakage}`
      );
    } else if (
      !event &&
      (isNaN(+lteLeakageValue) ||
        Number(lteLeakageValue) < CPAT_CONFIG.minLteLeakage ||
        Number(lteLeakageValue) > CPAT_CONFIG.maxLteLeakge)
    ) {
      setErrorMsg(
        `Lte leakage must be between ${CPAT_CONFIG.minLteLeakage} ${labels.to} ${CPAT_CONFIG.maxLteLeakge}`
      );
    } */ else if (
      !event &&
      (isNaN(+ingressValue) ||
        Number(ingressValue) < CPAT_CONFIG.minIngress ||
        Number(ingressValue) > CPAT_CONFIG.maxIngress)
    ) {
      setErrorMsg(
        `Ingress point must be between ${CPAT_CONFIG.minIngress} ${labels.to} ${CPAT_CONFIG.maxIngress}`
      );
    } else if (
      !event &&
      (isNaN(+radiusValue) ||
        Number(radiusValue) < CPAT_CONFIG.minRadius ||
        Number(radiusValue) > CPAT_CONFIG.maxRadius)
    ) {
      setErrorMsg(
        `${labels.RadiusMustBeBetween} ${CPAT_CONFIG.minRadius} ${labels.to} ${CPAT_CONFIG.maxRadius} ${labels.meters}`
      );
    } else if (!event && !code && !type) {
      setErrorMsg(`${labels.YouMustSelectAtLeastOneType} `);
    } else {
      setErrorMsg("");
      const searchMethod = type
        ? appConst.statusType
        : code
        ? appConst.statusCode
        : appConst.eventId;
      setIsButtonClicked(searchMethod === appConst.eventId);
      if (event) {
        const requestParams = {
          searchMethod: searchMethod,
          eventId: eventValue,
        };
        dispatch(getCpatEvents(requestParams));
      } else if (type) {
        const requestParams = {
          searchMethod: searchMethod,
          minMidLeakageLvl:
            midLeakageValue.trim()?.length === 0
              ? null
              : Number(midLeakageValue.trim()),
          minLTELeakageLvl:
            lteLeakageValue.trim()?.length === 0
              ? null
              : Number(lteLeakageValue.trim()),
          minIngressLvl:
            ingressValue.trim()?.length === 0
              ? null
              : Number(ingressValue.trim()),
          statusType: seleTestType?.toLowerCase(),
          centerLat: currentMapCenter?.latitude,
          centerLng: currentMapCenter?.longitude,
          radius: Number(radiusValue.trim()),
          detectionStartDate: Math.round(
            dateRangeSelection?.[0]?.startDate.getTime() / 1000
          ),
          detectionEndDate: Math.round(
            dateRangeSelection?.[0]?.endDate.getTime() / 1000
          ),
        };
        dispatch(getCpatEvents(requestParams));
      } else {
        const requestParams = {
          searchMethod: searchMethod,
          minIngressLvl:
            ingressValue.trim()?.length === 0
              ? null
              : Number(ingressValue.trim()),
          minStatusCode: Number(minCodeValue.trim()),
          maxStatusCode: Number(maxCodeValue.trim()),
          centerLat: currentMapCenter?.latitude,
          centerLng: currentMapCenter?.longitude,
          radius: Number(radiusValue.trim()),
          detectionStartDate: Math.round(
            dateRangeSelection?.[0]?.startDate.getTime() / 1000
          ),
          detectionEndDate: Math.round(
            dateRangeSelection?.[0]?.endDate.getTime() / 1000
          ),
        };
        dispatch(getCpatEvents(requestParams));
      }
    }
  };
  const onRadiusIncPress = () => {
    const val = Number(radiusValue) + CPAT_CONFIG.radiusIncDecBy;
    setRadiusValue(String(val));
  };
  const onRadiusDecPress = () => {
    const val = Number(radiusValue) - CPAT_CONFIG.radiusIncDecBy;
    if (val > 0) setRadiusValue(String(val));
  };
  const onMidLeakageIncPress = () => {
    const val = Number(midLeakageValue) + CPAT_CONFIG.midLeakageIncDecBy;
    setMidLeakageValue(String(val));
  };
  const onMidLeakageDecPress = () => {
    const val = Number(midLeakageValue) - CPAT_CONFIG.midLeakageIncDecBy;
    if (val > 0) setMidLeakageValue(String(val));
    if (val == 0) setMidLeakageValue(String(val + 1));
  };
  const onLteLeakageIncPress = () => {
    const val = Number(lteLeakageValue) + CPAT_CONFIG.lteLeakageIncDecBy;
    setLteLeakageValue(String(val));
  };
  const onLteLeakageDecPress = () => {
    const val = Number(lteLeakageValue) - CPAT_CONFIG.lteLeakageIncDecBy;
    if (val > 0) setLteLeakageValue(String(val));
    if (val == 0) setLteLeakageValue(String(val + 1));
  };
  const onIngressIncPress = () => {
    const val = Number(ingressValue) + CPAT_CONFIG.ingressIncDecBy;
    setIngressValue(String(val));
  };
  const onIngressDecPress = () => {
    const val = Number(ingressValue) - CPAT_CONFIG.ingressIncDecBy;
    setIngressValue(String(val));
    if (val <= -100) setIngressValue(String(-99));
  };
  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      onSearchPress();
    }
  };
  const [DRS, setDRS] = useState<any>();
  const getDRFormat = (dateStart = "startDate") => {
    const date =
      dateStart === "startDate" ? DRS?.[0]?.startDate : DRS?.[0]?.endDate;

    if (DRS) {
      return format(
        new Date(
          Number(date?.getFullYear()),
          Number(date?.getMonth()),
          Number(date?.getDate())
        ),
        "yyyy-MM-dd"
      );
    } else return null;
  };
  useEffect(() => {
    setDRS(dateRangeSelection);
  }, [dateRangeSelection]);
  const inputItems = [
    {
      label: labels.DaysBack,
      input: (
        <input
          value={daysBack}
          placeholder={labels.DaysBack}
          onChange={onDaysBackChange}
          onKeyPress={(e: any) => handleKeyPress(e)}
          style={{
            borderRadius: 5,
            borderWidth: 0.3,
            padding: 1.5,
            borderColor: `var(--greyMedium)`,
            textAlign: "center",
            fontSize: "16px",
          }}
        />
      ),
    },
    {
      label: labels.DateRange,
      input: (
        <div className="fromToDate" onClick={() => setShowDateRange(true)}>
          <Form.Text style={{ margin: 2 }}>
            {`${getDRFormat("startDate")} - ${getDRFormat("endDate")}`}
          </Form.Text>
          <MdDateRange fill="var(--greyMedium)" size={20} />
        </div>
      ),
    },
  ];
  return (
    <div className="cpatFilterCont">
      <CustomAccordion
        accTitle={labels.cpat.toUpperCase() + " " + labels.SEARCH}
        accBodyElement={
          <>
            <div className="dateAndDaysBackFilter">
              {/*              <div className="dateFilter">
                <label className="cpatFilter">
                  {labels.detectionDateLabel.toUpperCase()} :
                </label>
                <div
                  className="fromToDate"
                  onClick={() => setShowDateRange(true)}
                >
                  <Form.Text>
                    {format(
                      new Date(
                        Number(
                          dateRangeSelection?.[0]?.startDate?.getFullYear()
                        ),
                        Number(dateRangeSelection?.[0]?.startDate?.getMonth()),
                        Number(dateRangeSelection?.[0]?.startDate?.getDate())
                      ),
                      "MM/dd/yyyy"
                    )}{" "}
                    -{" "}
                    {format(
                      new Date(
                        Number(dateRangeSelection?.[0]?.endDate?.getFullYear()),
                        Number(dateRangeSelection?.[0]?.endDate?.getMonth()),
                        Number(dateRangeSelection?.[0]?.endDate?.getDate())
                      ),
                      "MM/dd/yyyy"
                    )}
                  </Form.Text>
                  <MdDateRange fill="var(--greyMedium)" size={20} />
                </div>
              </div>
              <div className="dateFilter">
                <label className="cpatFilter">
                  {labels.DaysBack.toUpperCase()} :
                </label>
                <input
                  value={daysBack}
                  placeholder={labels.DaysBack}
                  onChange={onDaysBackChange}
                  onKeyPress={(e: any) => handleKeyPress(e)}
                  className="daysBack"
                />
              </div> */}
              <div className="cpatDateFilter">
                {inputItems?.map(
                  (e: any) =>
                    e && (
                      <div key={e.label} className="inputItemsCont">
                        <label className="cpatFilter">
                          {e.label?.toUpperCase()}
                        </label>
                        {e.input}
                      </div>
                    )
                )}
              </div>
            </div>
            <Divider />
            <div className="searchByFilter">
              <section className="radioBtnCont">
                <label className="cpatFilter">
                  {labels.searchBy.toUpperCase()} :
                </label>
                <div className="cpatRadioButtons">
                  <RenderCPATRadioButtons
                    type={type as boolean}
                    setType={setType}
                    code={code as boolean}
                    setCode={setCode}
                    event={event as boolean}
                    setEvent={setEvent}
                  />
                </div>
              </section>
              <section>
                {type && (
                  <div className="cpatDropDown">
                    <DropdownButton
                      title={seleTestType}
                      onSelect={(e: any) => {
                        setSeleTestType(e);
                      }}
                      drop="down"
                      className="testTypeDropd"
                      id="testTypeDd"
                    >
                      <Dropdown.Item
                        key={0}
                        eventKey={labels.assignedUnassigned}
                      >
                        {labels.assignedUnassigned}
                      </Dropdown.Item>
                      <Dropdown.Item key={1} eventKey={labels.unassigned}>
                        {labels.unassigned}
                      </Dropdown.Item>
                      <Dropdown.Item key={2} eventKey={labels.assigned}>
                        {labels.assigned}
                      </Dropdown.Item>
                      <Dropdown.Item key={3} eventKey={labels.closed}>
                        {labels.closed}
                      </Dropdown.Item>
                      <Dropdown.Item key={4} eventKey={labels.archived}>
                        {labels.archived}
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                )}
                {code && (
                  <div className="codeTextBoxes">
                    <FormControl
                      placeholder="Min Val(1000)"
                      className="minCodeText"
                      id="stepperEleInput"
                      value={minCodeValue}
                      onChange={(v) =>
                        setMinCodeValue(isNumeric(v.target.value))
                      }
                      autoComplete="off"
                      onKeyPress={(e: any) => handleKeyPress(e)}
                    />
                    <FormControl
                      placeholder="Max Val(4000)"
                      className="maxCodeText"
                      id="stepperEleInput"
                      value={maxCodeValue}
                      onChange={(v) =>
                        setMaxCodeValue(isNumeric(v.target.value))
                      }
                      autoComplete="off"
                      onKeyPress={(e: any) => handleKeyPress(e)}
                    />
                  </div>
                )}
                {event && (
                  <div className="eventTextBox">
                    <FormControl
                      placeholder="Enter Event ID"
                      className="stepperInput-Text"
                      id="stepperEleInput"
                      value={eventValue}
                      onChange={(v) => setEventValue(isNumeric(v.target.value))}
                      autoComplete="off"
                      onKeyPress={(e: any) => handleKeyPress(e)}
                    />
                  </div>
                )}
              </section>
            </div>
            <Divider />
            {event !== true && (
              <div className="levelFilter">
                <label className="cpatFilter">
                  {labels.levels.toUpperCase()}
                </label>
                <div className="subLevelCont">
                  <label>{labels.midLeakage} :</label>
                  <div className="levelInputCont">
                    <NumberInputStepper
                      inputValue={midLeakageValue}
                      setInputValue={(v) =>
                        setMidLeakageValue(isNumeric(v.target.value))
                      }
                      placeholder={labels.midLeakagePlaceHolder}
                      onIncPress={onMidLeakageIncPress}
                      onDecPress={onMidLeakageDecPress}
                      minDec={CPAT_CONFIG.minMidLeakage}
                      maxInc={CPAT_CONFIG.maxMidLeakage}
                      onKeyPress={(e: any) => handleKeyPress(e)}
                    />
                  </div>
                </div>
                <div className="subLevelCont">
                  <label>{labels.lteLeakage} :</label>
                  <div className="levelInputCont">
                    <NumberInputStepper
                      inputValue={lteLeakageValue}
                      setInputValue={(v) =>
                        setLteLeakageValue(isNumeric(v.target.value))
                      }
                      placeholder={labels.lteLeakagePlaceHolder}
                      onIncPress={onLteLeakageIncPress}
                      onDecPress={onLteLeakageDecPress}
                      minDec={CPAT_CONFIG.minLteLeakage}
                      maxInc={CPAT_CONFIG.maxLteLeakge}
                      onKeyPress={(e: any) => handleKeyPress(e)}
                    />
                  </div>
                </div>
                <div className="subLevelCont">
                  <label>{labels.ingressPoint} :</label>
                  <div className="levelInputCont">
                    <NumberInputStepper
                      inputValue={ingressValue}
                      setInputValue={(v) => setIngressValue(v.target.value)}
                      placeholder={labels.ingressPointPlaceHolder}
                      onIncPress={onIngressIncPress}
                      onDecPress={onIngressDecPress}
                      minDec={CPAT_CONFIG.minIngress}
                      maxInc={CPAT_CONFIG.maxIngress}
                      onKeyPress={(e: any) => handleKeyPress(e)}
                    />
                  </div>
                </div>
              </div>
            )}

            {event !== true && (
              <>
                <Divider />
                <div className="coverageFilter">
                  <label className="cpatFilter">
                    {labels.coverage.toUpperCase()}
                  </label>
                  <div className="subLevelCont">
                    <label className="radiusLabel">{labels.radius} :</label>
                    <div className="levelInputCont">
                      <NumberInputStepper
                        inputValue={radiusValue}
                        setInputValue={(v) =>
                          setRadiusValue(isNumeric(v.target.value))
                        }
                        placeholder={labels.radiusRange}
                        onIncPress={onRadiusIncPress}
                        onDecPress={onRadiusDecPress}
                        minDec={CPAT_CONFIG.minRadius}
                        maxInc={CPAT_CONFIG.maxRadius}
                        onKeyPress={(e: any) => handleKeyPress(e)}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="diagOptionsCont">
              <div className="searchCPATBtn" style={{ flex: 1 }}>
                <button onClick={() => onSearchPress()}>
                  {labels.SEARCH.toUpperCase()}
                </button>
              </div>
            </div>
            <div>
              <CustomDialog
                className="CustomBox"
                open={showDateRange}
                handleClose={() => {
                  setShowDateRange(false);
                }}
                childComponent={
                  <div className="fromToRootContainer">
                    <DateRange
                      onChange={handleDateRangeSelect}
                      moveRangeOnFirstSelection={false}
                      ranges={dateRangeSelection}
                      rangeColors={[
                        colors.primary,
                        colors.primary,
                        colors.primary,
                      ]}
                    />
                  </div>
                }
                heading={labels.SelectDateRange}
              />
            </div>
            <InlineErrorMessage errorMessage={errorMsg} />
          </>
        }
      />
    </div>
  );
}
const getDateRangeFormat = (now: any, intervalInDays: number) => [
  {
    startDate: new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - intervalInDays
    ),
    endDate: new Date(),
    key: "selection",
  },
];
