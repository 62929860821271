import TopologyLabels from "../../../../../constants/topologyLabels";
import Table, { TCTypes, TDTypes } from "../../../../reusable/table/Table";

interface Props {
  data: any;
}

export default function GponAddressPort({ data }: Props) {
  const labels = TopologyLabels();
  const tableData = data?.ports?.map((d: any) => {
    return {
      id: d?.networkId,
      jitSlaveSeq: d?.jitSlaveSeq,
      usageDesc: d?.usageDesc,
      state: d?.state,
    } as TDTypes;
  });

  const tableColumns = [
    { th: labels.port.toUpperCase(), tdName: "jitSlaveSeq" },
    { th: labels.spatialDesignLocation, tdName: "usageDesc" },
    { th: labels.State.toUpperCase(), tdName: "state" },
  ] as TCTypes[];

  return (
    <Table
      title={`${labels.addressPort}: ${data?.name}`.toUpperCase()}
      data={tableData}
      columns={tableColumns}
      newTabTitle={`${labels.addressPort}: ${data?.name}`.toUpperCase()}
    />
  );
}
