import { Line, Transformer } from "react-konva";
import { useRef, useEffect } from "react";
import { KonvaEventObject } from "konva/lib/Node";

import {
  ICommonDrawingProps,
  IDrawPosition,
  IGeoEditShape,
  getOpacity,
} from "../GeoEditCanvas";

export interface IGeoLineShape extends IGeoEditShape {
  points: number[];
  strokeWidth: number;
}
interface Props {
  cdp: ICommonDrawingProps;
  lines: IGeoLineShape[];
  setLines: (lines: IGeoLineShape[]) => void;
  onClick: ({ id }: { id: string }) => void;
}

export default function Lines({ lines, cdp, setLines, onClick }: Props) {
  const { drawStartPosi, drawEndPosi } = cdp;

  return (
    <>
      {lines.map((l) => (
        <Shape
          key={l.id}
          shape={l}
          onClick={onClick}
          shapes={lines}
          setShapes={setLines}
          cdp={cdp}
        />
      ))}
      {cdp.drawingType === "line" && drawStartPosi && drawEndPosi && (
        <Line
          points={calcLinePoints(drawStartPosi, drawEndPosi)}
          stroke={cdp.color}
          strokeWidth={cdp.strokeWidth}
          fill={cdp.fill}
          opacity={getOpacity(cdp.opacity)}
        />
      )}
    </>
  );
}

interface ShapeProps {
  shape: IGeoLineShape;
  shapes: IGeoLineShape[];
  setShapes: (rect: IGeoLineShape[]) => void;
  cdp: ICommonDrawingProps;
  onClick: ({ id }: { id: string }) => void;
}
const Shape = ({ shape, shapes, setShapes, cdp, onClick }: ShapeProps) => {
  const shapeRef = useRef<any>(null);
  const trRef = useRef<any>(null);
  const { selectedShape, isSelected: isSSelected } = cdp;
  const isSelected = isSSelected({ id: shape.id, shape: "line" });

  const handleDragStart = (e: KonvaEventObject<DragEvent>) => {
    cdp.setDrawingType("none");
    const id = e.target.id();
    setShapes(shapes.map((s) => ({ ...s, isDragging: s.id === id })));
  };

  const handleDragEnd = () =>
    setShapes(shapes.map((s) => ({ ...s, isDragging: false })));

  useEffect(() => {
    if (isSelected && trRef.current) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
    if (!isSelected && shapeRef.current) {
      shapeRef.current.scaleX(1);
      shapeRef.current.scaleY(1);
    }
  }, [isSelected]);
  const handleTREnd = () => {
    const node = shapeRef.current;
    if (node) {
      const scaleX = node.scaleX();
      const scaleY = node.scaleY();
      node.scaleX(1);
      node.scaleY(1);
      let points = node.points();

      if (selectedShape?.id && selectedShape.shape === "line") {
        setShapes(
          shapes.map((s) =>
            s.id === selectedShape.id
              ? {
                  ...s,
                  points: points.map((p: any, i: number) =>
                    i % 2 === 0 ? p * scaleX : p * scaleY
                  ),
                  rotation: node.rotation(),
                }
              : s
          )
        );
      }

      trRef.current.getLayer().batchDraw();
    }
  };

  return (
    <>
      <Line
        ref={shapeRef}
        id={shape.id}
        points={shape.points}
        stroke={shape.stroke}
        rotation={shape.rotation}
        strokeWidth={
          shape.isDragging ? shape.strokeWidth + 2 : shape.strokeWidth
        }
        opacity={getOpacity(shape.opacity)}
        draggable
        onMouseDown={handleDragStart}
        onDragEnd={handleDragEnd}
        onClick={() => onClick({ id: shape.id })}
      />
      {selectedShape && isSelected && (
        <Transformer
          ref={trRef as any}
          onTransformEnd={handleTREnd}
          enabledAnchors={[
            "top-left",
            "top-right",
            "bottom-left",
            "bottom-right",
          ]}
        />
      )}
    </>
  );
};

export const calcLinePoints = (
  startPosi: IDrawPosition,
  endPosi: IDrawPosition
) => {
  return [startPosi.x, startPosi.y, endPosi.x, endPosi.y];
};
