import {
  ZAP_BEGIN,
  ZAP_SUCCESS,
  ZAP_FAILURE,
  ZAP_RESET_STATUS,
  ZapActionTypes,
} from "../../../types/account/deviceActions/Zap";

export function zapBegin(): ZapActionTypes {
  return {
    type: ZAP_BEGIN,
  };
}

export function zapSuccess(): ZapActionTypes {
  return {
    type: ZAP_SUCCESS,
  };
}

export function zapFailure(error: Error): ZapActionTypes {
  return {
    type: ZAP_FAILURE,
    payload: error,
  };
}
export function resetZap(): ZapActionTypes {
  return {
    type: ZAP_RESET_STATUS,
  };
}
