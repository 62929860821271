import ConfigConst from "../../constants/ConfigConst";
import { GetTechInfo } from "../../get-api/GetResponse";

const role_Types: any = {
  ST: [
    ConfigConst.SERVICE_TECH,
    ConfigConst.SERVICE_EXT_TECH,
    ConfigConst.SERVICE_OTHER_USER_TECH,
    ConfigConst.SERVICE_TECH_MANAGER,
    ConfigConst.SERVICE_TECH_READ_ONLY,
  ],
  MT: [
    ConfigConst.MAINTENANCE_TECH,
    ConfigConst.MAINTENANCE_TECH_MANAGER,
    ConfigConst.SERVICE_OTHER_USER_TECH,
  ],

  EXT_MT: [
    ConfigConst.EXT_MAINTENANCE_TECH,
    ConfigConst.EXT_MAINTENANCE_TECH_MANAGER,
  ],

  PT: [ConfigConst.PLATFORM_TECH, ConfigConst.PLATFORM_TECH_MANAGER],
  EXT_PT: [
    ConfigConst.EXT_PLATFORM_TECH,
    ConfigConst.EXT_PLATFORM_TECH_MANAGER,
  ],

  PROVISION_T: [
    ConfigConst.PROVISIONING_TECH,
    ConfigConst.PROVISIONING_TECH_MANAGER,
  ],

  HET: [ConfigConst.HEADEND_TECH, ConfigConst.HEADEND_TECH_MANAGER],

  EXT_HET: [ConfigConst.EXT_HEADEND_TECH, ConfigConst.EXT_HEADEND_TECH_MANAGER],

  NW_OP: [ConfigConst.NETWORK_OPERATIONS_TECH],
  NOC: [ConfigConst.NOC_TECH],

  CONST: [ConfigConst.CONSTRUCTION_TECH, ConfigConst.CONSTRUCTION_TECH_MANAGER],

  EXT_CONST: [
    ConfigConst.EXT_CONSTRUCTION_TECH,
    ConfigConst.EXT_CONSTRUCTION_TECH_MANAGER,
  ],

  TIER2: [ConfigConst.TIER2_TECH],
  SUPPORT: [ConfigConst.SUPPORT_ENGINEERING_TECH],
  FSC: [ConfigConst.FSC_TECH],
  OMD: [ConfigConst.OMD_TECH],
  TTM: [ConfigConst.TTM_TECH],
  IPNCM: [ConfigConst.IPNCM_Tech, ConfigConst.IPNCM_Tech_one],
  TOPOLOGY: [
    ConfigConst.TOPOLOGY_TECH,
    ConfigConst.MAINTENANCE_TECH,
    ConfigConst.MAINTENANCE_TECH_MANAGER,
    ConfigConst.EXT_MAINTENANCE_TECH,
    ConfigConst.EXT_MAINTENANCE_TECH_MANAGER,
    ConfigConst.PLATFORM_TECH,
    ConfigConst.PLATFORM_TECH_MANAGER,
    ConfigConst.EXT_PLATFORM_TECH,
    ConfigConst.EXT_PLATFORM_TECH_MANAGER,
    ConfigConst.CONSTRUCTION_TECH,
    ConfigConst.CONSTRUCTION_TECH_MANAGER,
    ConfigConst.EXT_CONSTRUCTION_TECH,
    ConfigConst.EXT_CONSTRUCTION_TECH_MANAGER,
    ConfigConst.OMD_TECH,
    ConfigConst.SERVICE_TECH,
    //ConfigConst.SERVICE_EXT_TECH, //TODO: Add External Service Techs in a Phase 2
  ],

  //switch logic to allow/disallow topology view from internal service techs
  TOPOLOGY_EXCEPT_INT_SERV_TECH: [
    ConfigConst.TOPOLOGY_TECH,
    ConfigConst.MAINTENANCE_TECH,
    ConfigConst.MAINTENANCE_TECH_MANAGER,
    ConfigConst.EXT_MAINTENANCE_TECH,
    ConfigConst.EXT_MAINTENANCE_TECH_MANAGER,
    ConfigConst.PLATFORM_TECH,
    ConfigConst.PLATFORM_TECH_MANAGER,
    ConfigConst.EXT_PLATFORM_TECH,
    ConfigConst.EXT_PLATFORM_TECH_MANAGER,
    ConfigConst.CONSTRUCTION_TECH,
    ConfigConst.CONSTRUCTION_TECH_MANAGER,
    ConfigConst.EXT_CONSTRUCTION_TECH,
    ConfigConst.EXT_CONSTRUCTION_TECH_MANAGER,
    ConfigConst.OMD_TECH,
  ],
  IT_OSS: [ConfigConst.IT_OSS_ADMIN, ConfigConst.IT_OSS_BUSINESS_ADMIN],
  LAW_FULL: [ConfigConst.LAW_FULL_ACCESS],
};
export const FlapVisibility  = [
  
    ConfigConst.MAINTENANCE_TECH,
    ConfigConst.MAINTENANCE_TECH_MANAGER,
    ConfigConst.EXT_MAINTENANCE_TECH,
    ConfigConst.EXT_MAINTENANCE_TECH_MANAGER,
  ConfigConst.PLATFORM_TECH, ConfigConst.PLATFORM_TECH_MANAGER,
    ConfigConst.EXT_PLATFORM_TECH,
    ConfigConst.EXT_PLATFORM_TECH_MANAGER,
  ConfigConst.HEADEND_TECH, ConfigConst.HEADEND_TECH_MANAGER,
  ConfigConst.EXT_HEADEND_TECH, ConfigConst.EXT_HEADEND_TECH_MANAGER,
  ConfigConst.NETWORK_OPERATIONS_TECH,
  ConfigConst.NOC_TECH

];
export interface RoleConfigType {
  type: string;
  roles: any;
  accessLevel: Array<string>;
}

const getRoleMatch = (roles: any, mapRoles: any) => {
  return roles.filter((role: string) =>
    mapRoles?.includes(role?.toLowerCase().trim())
  );
};

const getRoleConfigType = (
  roles: any,
  roleType: string,
  mapRoles: Array<string>
): RoleConfigType => {
  if (roles && roles.length > 0) {
    switch (roleType) {
      case "ST":
        return {
          type: "ST",
          roles: getRoleMatch(roles, mapRoles),
          accessLevel: ["ST"],
        };
      case "MT":
        return {
          type: "MT",
          roles: getRoleMatch(roles, mapRoles),
          accessLevel: ["MT"],
        };
      case "EXT_MT":
        return {
          type: "EXT_MT",
          roles: getRoleMatch(roles, mapRoles),
          accessLevel: ["EXT_MT", "MT"],
        };
      case "PT":
        return {
          type: "PT",
          roles: getRoleMatch(roles, mapRoles),
          accessLevel: ["PT", "MT"],
        };
      case "EXT_PT":
        return {
          type: "EXT_PT",
          roles: getRoleMatch(roles, mapRoles),
          accessLevel: ["EXT_PT", "PT", "MT"],
        };
      case "PROVISION_T":
        return {
          type: "PROVISION_T",
          roles: getRoleMatch(roles, mapRoles),
          accessLevel: ["PROVISION_T"],
        };
      case "HET":
        return {
          type: "HET",
          roles: getRoleMatch(roles, mapRoles),
          accessLevel: ["HET", "MT"],
        };
      case "EXT_HET":
        return {
          type: "EXT_HET",
          roles: getRoleMatch(roles, mapRoles),
          accessLevel: ["EXT_HET", "MT"],
        };
      case "NW_OP":
        return {
          type: "NW_OP",
          roles: getRoleMatch(roles, mapRoles),
          accessLevel: ["NW_OP", "MT"],
        };
      case "NOC":
        return {
          type: "NOC",
          roles: getRoleMatch(roles, mapRoles),
          accessLevel: ["NOC", "MT"],
        };
      case "CONST":
        return {
          type: "CONST",
          roles: getRoleMatch(roles, mapRoles),
          accessLevel: ["CONST", "MT"],
        };
      case "EXT_CONST":
        return {
          type: "EXT_CONST",
          roles: getRoleMatch(roles, mapRoles),
          accessLevel: ["EXT_CONST", "MT"],
        };
      case "TIER2":
        return {
          type: "TIER2",
          roles: getRoleMatch(roles, mapRoles),
          accessLevel: ["TIER2", "ST"],
        };
      case "SUPPORT":
        return {
          type: "SUPPORT",
          roles: getRoleMatch(roles, mapRoles),
          accessLevel: ["SUPPORT", "PT", "MT"],
        };
      case "FSC":
        return {
          type: "FSC",
          roles: getRoleMatch(roles, mapRoles),
          accessLevel: ["FSC", "PT", "MT"],
        };
      case "OMD":
        return {
          type: "OMD",
          roles: getRoleMatch(roles, mapRoles),
          accessLevel: ["OMD", "MT"],
        };
      case "TTM":
        return {
          type: "TTM",
          roles: getRoleMatch(roles, mapRoles),
          accessLevel: ["TTM", "PT", "MT"],
        };
      case "IPNCM":
        return {
          type: "IPNCM",
          roles: getRoleMatch(roles, mapRoles),
          accessLevel: ["IPNCM", "MT"],
        };
      case "TOPOLOGY":
        return {
          type: "TOPOLOGY",
          roles: getRoleMatch(roles, mapRoles),
          accessLevel: ["TOPOLOGY"],
        };
      case "TOPOLOGY_EXCEPT_INT_SERV_TECH":
        return {
          type: "TOPOLOGY_EXCEPT_INT_SERV_TECH",
          roles: getRoleMatch(roles, mapRoles),
          accessLevel: ["TOPOLOGY_EXCEPT_INT_SERV_TECH"],
        };
      case "IT_OSS":
        return {
          type: "IT_OSS",
          roles: getRoleMatch(roles, mapRoles),
          accessLevel: ["IT_OSS"],
        };
      case "LAW_FULL":
        return {
          type: "LAW_FULL",
          roles: getRoleMatch(roles, mapRoles),
          accessLevel: ["ST", "MT", "LAW_FULL"],
        };
    }
  }
  return { type: "", roles: [], accessLevel: [] };
};

export const processAccessRoleConfig = (roles: any): Array<RoleConfigType> => {
  const mapRoles = new Map(Object.entries(role_Types));
  let roleConfig: Array<RoleConfigType> = [];
  mapRoles.forEach((key: any, value: any) => {
    roleConfig.push(getRoleConfigType(roles, value, key));
  });
  return roleConfig?.filter((roleConfig) => roleConfig?.roles?.length > 0);
};

export const hasLimitedTopologyFeatureView = () => {
  /**
   * This function limits the view of certain features of the Topology left tab for internal service technicians. 
   * Features limited include disabling the following: 
   * _CPAT section
   * _Redline section
   * _Node comment and files buttons in the Node Overview section
   * _Net Maintenance and Construction Nika filters and information
   */

  const { data: techData } = GetTechInfo();
  const topologyRoleListNoIntServTech = role_Types.TOPOLOGY.filter((item: any) => item !== ConfigConst.SERVICE_TECH);
  // TODO: Add external service technician in a phase 2
  // const topologyRoleListNoIntServTech = role_Types.TOPOLOGY.filter((item: any) => item !== ConfigConst.SERVICE_TECH && item !== ConfigConst.SERVICE_EXT_TECH);
  var hasOtherTopologyRoleThanIntServTech = false;

  techData?.roles?.map((item: any) => {
    if (topologyRoleListNoIntServTech.includes(item.trim()))
      {
        hasOtherTopologyRoleThanIntServTech = true;
      }
    });
  return !hasOtherTopologyRoleThanIntServTech;
}