import { Card } from "react-bootstrap";
import { useState } from "react";
import CustomTab from "../reusable/CustomTab";
import { TabPanel } from "../../screens/account-page/AccountPageTabs";
import OFDMChannelData from "../cable-modem/downstream/OFDMChannelData";

const NeighCmDSCh = (props: any) => {
  const { cmDocsisInfo } = props;
  const [tabSelIndex, setTabSelIndex] = useState(0);
  let ofdmDownStreamChannels: any = [];
  if (
    cmDocsisInfo?.hasOwnProperty("ofdm") &&
    cmDocsisInfo.ofdm?.hasOwnProperty("downstreamChannels")
  ) {
    cmDocsisInfo.ofdm.downstreamChannels.map((dsC: any) => {
      ofdmDownStreamChannels.push(dsC);
    });
  }

  return (
    <>
      {cmDocsisInfo !== undefined ? (
        <Card className="dsChTabsCard">
          <Card.Header>
            {cmDocsisInfo?.docsisVersion === "DOCSIS31" ? (
              ofdmDownStreamChannels?.map((ch: any, index: number) => {
                return (
                  <CustomTab
                    tabType="horizontal"
                    key={index}
                    index={index}
                    value={tabSelIndex}
                    handleSelect={(i: any) => setTabSelIndex(i)}
                    label={`OFDM ${index + 1}`}
                  />
                );
              })
            ) : (
              <></>
            )}
          </Card.Header>
          <Card.Body>
            {cmDocsisInfo.docsisVersion === "DOCSIS31" &&
            ofdmDownStreamChannels?.length !== 0 ? (
              ofdmDownStreamChannels?.map((ch: any, index: number) => {
                return (
                  <TabPanel value={tabSelIndex} index={index}>
                    <OFDMChannelData ofdmChannel={ch} />
                  </TabPanel>
                );
              })
            ) : (
              <></>
            )}
          </Card.Body>
        </Card>
      ) : (
        <>NO DATA AVAILABLE...</>
      )}
    </>
  );
};

export default NeighCmDSCh;
