import { createSlice } from "@reduxjs/toolkit";
import storeConst from "../storeConst";

const slice = createSlice({
  name: "sendEmailData",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    requestType: "",
    status: "",
  },
  reducers: {
    sendEmailBegin: (emailData, action) => {
      emailData.isLoading = true;
      emailData.requestType = action.payload;
    },
    sendEmailSuccess: (emailData, action) => {
      emailData.isLoading = false;
      emailData.data = action.payload;
      emailData.status = storeConst.OK;
    },
    sendEmailFailure: (emailData, action) => {
      emailData.isLoading = false;
      emailData.error = action.payload;
      emailData.data = undefined;
      emailData.status = storeConst.ERROR;
    },
    sendEmailReset: (emailData) => {
      emailData.isLoading = false;
      emailData.error = undefined;
      emailData.data = undefined;
      emailData.requestType = "";
      emailData.status = "";
    },
  },
});

export const {
  sendEmailBegin,
  sendEmailSuccess,
  sendEmailFailure,
  sendEmailReset,
} = slice.actions;
export default slice.reducer;
