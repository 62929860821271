import { createSlice } from "@reduxjs/toolkit";
import { changeTimeFormat } from "../../../components/reusable/Util";
import { apiCallBegan } from "../../../topology/store/middleware/apiActions";
// import appConst from "../../../../constants/appConst";
// import {
//   combineTeleData,
//   convertStreamDataToJson,
//   isCoordsAvaileable,
//   isNumeric,
//   newTeleData,
// } from "../../../util/utilFuncs";

import {
  IP_PING,
  NETWORK_SERVICE_URL,
} from "../../../topology/store/middleware/apiEndpoints";

import storeConst from "../../../topology/store/storeConst";

const slice = createSlice({
  name: "pingData",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
    startTime: undefined,
    endTime: undefined,
  },
  reducers: {
    getStreamPingDataBegin: (tl) => {
      let startTime: any = changeTimeFormat(Date.now());
      tl.status = "";
      tl.error = undefined;
      tl.startTime = startTime;
      tl.isLoading = true;
    },
    getStreamPingDataSuccess: (tl, { payload }) => {
      //   let dataChunk = convertStreamDataToJson(payload);

      let endTime: any = changeTimeFormat(Date.now());
      tl.isLoading = false;
      tl.endTime = endTime;
      tl.data = payload;
      tl.status = storeConst.OK;
      tl.error = undefined;
    },
    getStreamPingDataFailure: (tl, { payload }) => {
      let endTime: any = changeTimeFormat(Date.now());
      tl.isLoading = false;
      tl.data = undefined;
      tl.endTime = endTime;
      tl.status = storeConst.ERROR;
      tl.error = payload;
    },
  },
});

export const {
  getStreamPingDataBegin,
  getStreamPingDataSuccess,
  getStreamPingDataFailure,
} = slice.actions;

export default slice.reducer;

export const getStreamIpData =
  (ipAddress: any, numberOfPing: any) => (dispatch: any) => {
    dispatch(
      apiCallBegan({
        baseURL: NETWORK_SERVICE_URL + "diag/network",
        url: IP_PING(ipAddress, numberOfPing),
        method: "post",
        data: JSON.stringify({
          ipAddress: ipAddress,
          numberOfPing: numberOfPing,
        }),
        responseType: "stream",

        onStart: getStreamPingDataBegin.type,
        onSuccess: getStreamPingDataSuccess.type,
        onError: getStreamPingDataFailure.type,
      })
    );
  };
