import {
  SAVE_PJI_SUCCESS,
  SAVE_PJI_BEGIN,
  SAVE_PJI_FAILURE,
  SAVE_PJI_RESET,
  NewPJIActionTypes,
} from "../../../types/account/forms/PJIForm";

export function SavePJISuccess(activePJI: any): NewPJIActionTypes {
  return {
    type: SAVE_PJI_SUCCESS,
    payload: activePJI,
  };
}

export function SavePJIBegin(): NewPJIActionTypes {
  return {
    type: SAVE_PJI_BEGIN,
  };
}

export function SavePJIFailure(error: Error): NewPJIActionTypes {
  return {
    type: SAVE_PJI_FAILURE,
    payload: error,
  };
}

export function SavePJIReset(): NewPJIActionTypes {
  return {
    type: SAVE_PJI_RESET,
  };
}
