import ExceptionBox from "../../../../components/reusable/ExceptionBox";
import { changeTimeFormat } from "../../../../components/reusable/Util";
import AppLabels from "../../../../constants/App_Labels";
import TopologyLabels from "../../../constants/topologyLabels";
import {
  GetClickOrdersProps,
  GetMapDrawerProps,
  GetTopoConfigsProps,
} from "../../../util/reduxFunctions/getTopologyState";
import Table, { TCTypes, TDTypes } from "../../reusable/table/Table";

export default function ClickAllOrdersTable() {
  const labels = AppLabels();
  const tLabels = TopologyLabels();
  const { data } = GetClickOrdersProps();
  const { drawerView } = GetMapDrawerProps();
  let tableData = [] as any;
  const TW = drawerView === "fullScreen" ? "100%" : "23.5vw";
  const TH = "calc(100vh - 200px)";
  const { data: FD } = GetTopoConfigsProps();
  data?.features?.map((e: any) => {
    const PD = e?.properties?.data;
    tableData?.push({
      id: PD?.callId + PD?.accountNumber,
      callId: PD?.callId,
      accountNumber: PD?.accountNumber,
      status: PD?.status,
      assignedEngineer: PD?.TechName,
      orderType: PD?.orderTypeName,
      orderCategoryDesc: PD?.orderCategoryDesc,
      orderCategoryName: PD?.orderCategoryName,
      orderTypeDesc: PD?.orderTypeDesc,
      address: PD?.address,
      smtId: PD?.smtId,
      shubId: PD?.shubId,
      completionCodes: PD?.completionCodes,
      timeCreated: changeTimeFormat(PD?.timeCreated),
      timeModified: changeTimeFormat(PD?.timeModified),
      remedyImtId: PD?.remedyImtId,
      workType: PD?.workType,
      pHub: PD?.pHub,
      ponHub: PD?.ponHub,
      ponNap: PD?.ponNap,
      ponNode: PD?.ponNode,
      company: PD?.company,
      revision: PD?.revision,
      priority: PD?.priority,
      returnComments: PD?.returnComments,
      specialInstructions: PD?.specialInstructions,
    } as TDTypes);
  });

  const tableColumns = [
    { th: labels.Address, tdName: "address", tdStyle: { minWidth: 160 } },
    { th: labels.SHUB, tdName: "shubId", tdStyle: { minWidth: 60 } },
    { th: labels.SMT, tdName: "smtId", tdStyle: { minWidth: 60 } },
    { th: labels.STATUS, tdName: "status", tdStyle: { minWidth: 80 } },
    { th: "Order", tdName: "orderType", tdStyle: { minWidth: 50 } },
    {
      th: tLabels.Tech,
      tdName: "assignedEngineer",
      tdStyle: { minWidth: 100 },
    },
    {
      th: tLabels.specialInstructions,
      tdName: "specialInstructions",
      tdStyle: { minWidth: 350 },
    },
    {
      th: tLabels.returnComments,
      tdName: "returnComments",
      tdStyle: { minWidth: 350 },
    },
    {
      th: labels.callId,
      tdName: "callId",
      tdStyle: { minWidth: 110 },
    },
    {
      th: labels.ACCOUNT_NUMBER,
      tdName: "accountNumber",
      tdStyle: { minWidth: 80 },
    },
    {
      th: tLabels.orderCategoryDesc,
      tdName: "orderCategoryDesc",
      tdStyle: { minWidth: 80 },
    },
    { th: tLabels.orderCategoryName, tdName: "orderCategoryName" },
    {
      th: tLabels.orderTypeDesc,
      tdName: "orderTypeDesc",
      tdStyle: { minWidth: 100 },
    },
    { th: tLabels.CompletionCodes, tdName: "completionCodes" },
    {
      th: tLabels.TimeCreated,
      tdName: "timeCreated",
      tdStyle: { minWidth: 145 },
    },
    {
      th: tLabels.TimeModified,
      tdName: "timeModified",
      tdStyle: { minWidth: 145 },
    },
    { th: tLabels.RemedyImtId, tdName: "remedyImtId" },
    { th: tLabels.WorkType, tdName: "workType", tdStyle: { minWidth: 90 } },
    { th: tLabels.PHUB, tdName: "pHub" },
    { th: tLabels.PonHub, tdName: "ponHub" },
    { th: tLabels.PonNap, tdName: "ponNap" },
    { th: tLabels.PonNode, tdName: "ponNode" },
    { th: tLabels.Company, tdName: "company" },
    { th: tLabels.Revision, tdName: "revision" },
    { th: labels.PRIORITY, tdName: "priority" },
  ] as TCTypes[];
  const regex = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}/;
  const match = FD?.batchConfig?.getClickStgTasksJob.match(regex);
  const dateTimePart = match?.[0];
  const clickOrdersLastBatch = tLabels?.asOf + dateTimePart;

  return (
    <div className="activeMapEleCont">
      {tableData?.length !== 0 ? (
        <Table
          title={tLabels.ClickOrders}
          data={tableData}
          columns={tableColumns}
          newTabTitle={tLabels.ClickOrders}
          tableStyle={{ maxWidth: TW, maxHeight: TH, overflow: "auto" }}
          headerDetails={clickOrdersLastBatch}
        />
      ) : (
        <div className="diagErrorCont">
          <ExceptionBox
            headerText={tLabels.NoSearchHistory}
            bodyText={tLabels.PerformSearchToSeeTheResults}
          />
        </div>
      )}
    </div>
  );
}
