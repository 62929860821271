import { AccountDetails } from "../../../models/account/accountInfo/AccountDetails";
import { Device } from "../../../models/account/accountInfo/Device";

export const SET_ACTIVE_ACCOUNT_DETAILS = "SET_ACTIVE_ACCOUNT_DETAILS";
export const SET_ACCOUNT_TYPE = "SET_ACCOUNT_TYPE";
export const SET_ACTIVE_SUB_TAB_INDEX = "SET_ACTIVE_SUB_TAB_INDEX";
export const SET_GOOGLE_API_KEY = "SET_GOOGLE_API_KEY";
export const SET_WHT_FLAG = "SET_WHT_FLAG";
export const ENABLE_WHT_FLAG = "ENABLE_WHT_FLAG";
export const SET_SELECTED_SERVICE_ADDRESS = "SET_SELECTED_SERVICE_ADDRESS";
export const SET_DEVICE_STATUS = "SET_DEVICE_STATUS";
export const SET_SELECTED_SA = "SET_SELECTED_SA";
export const SORTED_DEVICE_LIST = "SORTED_DEVICE_LIST";
export const SET_FAILED_CODEWORDS = "SET_FAILED_CODEWORDS";
export const SET_SCQAM_FLAG = "SET_SCQAM_FLAG";
export const RESET_APP = "RESET_APP";
export const RESET_ACC = "RESET_ACC";
export const SET_CODEWORD_VALUES = "SET_CODEWORD_VALUES";
export const SET_FRIENDLYNAME = "SET_FRIENDLYNAME";
export const SET_ACTIVE_ACC_SEARCH_VALUE = "SET_ACTIVE_ACC_SEARCH_VALUE";
export const SET_DEVICE_SEARCH_VALUE = "SET_DEVICE_SEARCH_VALUE";
export const SET_DEVICE_MAC_ADDRESS_SEARCH_VALUE =
  "SET_DEVICE_MAC_ADDRESS_SEARCH_VALUE";

interface SetActiveAccountDetails {
  type: typeof SET_ACTIVE_ACCOUNT_DETAILS;
  payload: AccountDetails;
}
interface SetAccountType {
  type: typeof SET_ACCOUNT_TYPE;
  payload: String;
}
interface SetActiveSubTabIndex {
  type: typeof SET_ACTIVE_SUB_TAB_INDEX;
  payload: number;
}
interface SetGApiKey {
  type: typeof SET_GOOGLE_API_KEY;
  payload: String;
}
interface SetWHTFlag {
  type: typeof SET_WHT_FLAG;
  payload: boolean;
}
interface SetSCQAMFLAG {
  type: typeof SET_SCQAM_FLAG;
  payload: boolean;
}
interface SetFailedCodewords {
  type: typeof SET_FAILED_CODEWORDS;
  payload: any;
}
interface SetCodewordValues {
  type: typeof SET_CODEWORD_VALUES;
  payload: any;
}
interface EnableWHTFlag {
  type: typeof ENABLE_WHT_FLAG;
  payload: boolean;
}
interface SetFriendlyName {
  type: typeof SET_FRIENDLYNAME;
  devicekey: string;
  deviceCategory: string;
  friendlyName: string;
}
interface SetSelectedServiceAddress {
  type: typeof SET_SELECTED_SERVICE_ADDRESS;
  payload: String;
}
interface SetSelectedSA {
  type: typeof SET_SELECTED_SA;
  payload: any;
}
interface AccountSortedDevices {
  type: typeof SORTED_DEVICE_LIST;
  payload: Device[];
}
interface SetDeviceStatus {
  type: typeof SET_DEVICE_STATUS;
  devicekey: string;
  deviceCategory: string;
  deviceStatus: string;
}
interface SetSCQAMFLAG {
  type: typeof SET_SCQAM_FLAG;
  payload: boolean;
}
interface AppReset {
  type: typeof RESET_APP;
}
interface AccountReset {
  type: typeof RESET_ACC;
}
interface SetAccountSearchValue {
  type: typeof SET_ACTIVE_ACC_SEARCH_VALUE;
  payload: String;
}
interface SetDeviceSearchValue {
  type: typeof SET_DEVICE_SEARCH_VALUE;
  payload: String;
}
interface SetDeviceMacAddressSearchValue {
  type: typeof SET_DEVICE_MAC_ADDRESS_SEARCH_VALUE;
  payload: String;
}

export type ActiveAccountActionTypes =
  | SetActiveAccountDetails
  | SetAccountType
  | SetActiveSubTabIndex
  | EnableWHTFlag
  | SetGApiKey
  | SetFriendlyName
  | SetWHTFlag
  | SetDeviceStatus
  | SetFailedCodewords
  | SetSCQAMFLAG
  | SetSelectedServiceAddress
  | SetSelectedSA
  | SetFailedCodewords
  | SetSCQAMFLAG
  | SetCodewordValues
  | AccountSortedDevices
  | AppReset
  | AccountReset
  | SetAccountSearchValue
  | SetDeviceSearchValue
  | SetDeviceMacAddressSearchValue;
