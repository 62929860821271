import "../../design-tokens/workorder/WorkOrderPopUp.scss";
import { useDispatch } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import AppLabels from "../../constants/App_Labels";
import { AppBar, Tabs, Tab, Divider } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import {
  a11yProps,
  checkWOStatusUpdateAllowed,
  TabPanel,
} from "../reusable/Util";
import { orderDetailsData } from "./OrderDetailsTab";
import { networkDetailsData } from "./NetworkDetailsTab";
import { productDetailsData } from "./ProductDetailsTab";
import CompletionFormData from "./CompletionFormTab";
import CompletedTab from "./CompletedTab";
import React from "react";
import {
  completeWorkOrderStatus,
  resetWOStatusUpdate,
  updateWorkOrderStatus,
} from "../../store/slices/workOrder/workOrderStatusUpdate";
import DiagContainer from "../reusable/DiagContainer";
import {
  GetWorkOrderDetails,
  GetWorkOrderUpdateStatus,
} from "../../get-api/GetResponse";
import ConfirmPopUp from "../reusable/ConfirmPopUp";
import ConfigConst from "../../constants/ConfigConst";

const WorkOrderPopUp = (props: any) => {
  const labels = AppLabels();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [completedForm, setCompletedForm] = useState<any>({});
  const [disabled, setDisabled] = useState(true);
  const [snackBarText, setSnackBarText] = useState("");
  const ref: any = useRef();
  const isRef = useRef(false);
  const techData = props?.techData;
  const techId = techData?.techId;
  const techName = techData?.name;
  const [submitted, setSubmitted] = useState(false);
  const [allowedToUpdate, setAllowedToUpdate] = useState(false);
  const [restrictionErrorMsg, setRestrictionErrorMsg] = useState("");

  const woData = GetWorkOrderDetails()?.data;
  const woDetailsError = GetWorkOrderDetails()?.error;
  const woDetailsIsLoading = GetWorkOrderDetails()?.isLoading;
  const wOUpdateStatus = GetWorkOrderUpdateStatus()?.status;
  const wOUpdateError = GetWorkOrderUpdateStatus()?.error;
  const wOUpdateIsLoading = GetWorkOrderUpdateStatus()?.isLoading;
  let box = document.getElementById("warning");

  const refreshData = React.useCallback(async () => {
    if (wOUpdateStatus !== undefined && wOUpdateStatus === "OK") {
      /*  await props.refreshData(); */
      setTimeout(() => {
        props?.setShowVal(false);
        isRef.current = false;
        resetWOUpdateState();
        setSubmitted(false);
        props.refreshData();
      }, 3000);
      if (box) {
        box.classList.add("hiddenDiv");
      }
    } else if (wOUpdateStatus !== undefined && wOUpdateStatus === "ERROR") {
      let error = wOUpdateError?.errorMessage?.substring(0, 300) + "...";
      if (wOUpdateError?.errorMessages) {
        for (const [key, val] of Object.entries(wOUpdateError?.errorMessages)) {
          error += ":\n\n " + key?.toUpperCase() + " " + val + ". ";
        }
      }
      setSnackBarText(error);
      if (box) {
        box.classList.remove("hiddenDiv");
      }
      /*  resetWOUpdateState(); */
      /*  setTimeout(() => {
        if (box) {
          setSnackBarText("");
          box.classList.add("hiddenDiv");
        }
      }, 3000); */
      isRef.current = false;
      setSubmitted(false);
    }
  }, [wOUpdateStatus]);

  useEffect(() => {
    refreshData();
  }, [wOUpdateStatus, submitted]);

  useEffect(() => {
    if (
      props?.headerText?.toUpperCase() === labels.Onsite?.toUpperCase() ||
      props?.headerText?.toUpperCase() === labels.Completed?.toUpperCase()
    ) {
      setValue(3);
    } else {
      setValue(0);
    }
  }, [props]);

  // Update WO Status onClick
  const onClick = (status: string) => {
    let isAllowedToUpdate: boolean = checkWOStatusUpdateAllowed(
      woData?.id,
      status,
      props.wOList
    );
    let onsiteWO = props.wOList?.find(
      (wo: any) =>
        wo?.status === ConfigConst.Enroute || wo?.status === ConfigConst.Onsite
    );
    if (isAllowedToUpdate) {
      const TechId: string = techId + "-" + techName?.replace(/ /g, "");
      const promise = new Promise<void>((resolve, reject) => {
        dispatch(updateWorkOrderStatus(woData?.id, status, TechId));
        resolve();
      });
      promise.then(() => {
        setSubmitted(true);
        isRef.current = true;
        refreshData();
      });
    } else {
      setRestrictionErrorMsg(
        `Only one order can be edited at one time. #${onsiteWO?.id} is in ${onsiteWO?.status} status. This order must be set as in "complete" or "dispatch" status before you start working on a new one.`
      );
      setAllowedToUpdate(true);
    }
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const resetWOUpdateState = () => {
    dispatch(resetWOStatusUpdate());
  };

  // Submit the Complete Tab Form
  const handleSubmit = () => {
    const TechId: string = techId + "-" + techName?.replace(/ /g, "");
    const promise = new Promise<void>((resolve, reject) => {
      dispatch(
        completeWorkOrderStatus(
          woData?.id,
          labels.Completed,
          TechId,
          completedForm
        )
      );
      resolve();
    });
    promise.then(() => {
      setSubmitted(true);
      isRef.current = true;
      refreshData();
    });
  };

  return (
    <div className="WOpopUp">
      <Modal
        show={props.showVal}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="xl"
        id={"modalBody"}
        scrollable={true}
        dialogClassName="modalClass"
        animation={false}
      >
        <Modal.Header
          className="searchPopHead"
          closeButton
          onHide={ref?.current?.cleanArray}
        >
          <header>
            {labels.STATUS.toUpperCase() +
              " - " +
              props?.headerText?.toUpperCase()}
          </header>
        </Modal.Header>
        <Modal.Body style={{ paddingRight: "20px" }}>
          <DiagContainer
            isLoading={woDetailsIsLoading}
            data={woData}
            error={woDetailsError}
            sectionName="woDetails"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {props?.headerText?.toUpperCase() === "DISPATCHED" ? (
                <>
                  <button
                    className="actionButton"
                    onClick={() => {
                      onClick(labels.Enroute);
                    }}
                    disabled={wOUpdateIsLoading}
                  >
                    {labels.Enroute.toUpperCase()}
                  </button>
                </>
              ) : props?.headerText?.toUpperCase() === "ENROUTE" ? (
                <>
                  <button
                    className="actionButton"
                    onClick={() => {
                      onClick(labels.Dispatched);
                    }}
                    disabled={wOUpdateIsLoading}
                  >
                    {labels.Dispatch.toUpperCase()}
                  </button>
                  <button
                    className="actionButton"
                    onClick={() => {
                      onClick(labels.Onsite);
                    }}
                    disabled={wOUpdateIsLoading}
                  >
                    {labels.Onsite.toUpperCase()}
                  </button>
                </>
              ) : props?.headerText?.toUpperCase() === "ONSITE" ? (
                <>
                  <button
                    className="actionButton"
                    onClick={() => {
                      onClick(labels.Dispatched);
                    }}
                    disabled={wOUpdateIsLoading}
                  >
                    {labels.Dispatch.toUpperCase()}
                  </button>
                  <button
                    className="actionButton"
                    disabled={disabled}
                    onClick={() => {
                      handleSubmit();
                    }}
                    title={
                      disabled ? "Please fill the below form to complete" : ""
                    }
                  >
                    {labels.Complete.toUpperCase()}
                  </button>
                </>
              ) : (
                <></>
              )}
              <div id="warning" className="warnText">
                {snackBarText}
              </div>
            </div>
            <Divider orientation="horizontal" />
            <div>
              <AppBar
                position="relative"
                style={{
                  boxShadow: "none",
                  display: "flex",
                  justifyContent: "space-around",
                  paddingLeft: "20px",
                  marginTop: "20px",
                  zIndex: 1,
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="secondary"
                  textColor="secondary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable force tabs example"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Tab
                    label={labels.Order_Details.toUpperCase()}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={labels.Network_Details.toUpperCase()}
                    {...a11yProps(1)}
                  />
                  <Tab
                    label={labels.Product_Details.toUpperCase()}
                    {...a11yProps(2)}
                  />
                  {(props?.headerText?.toUpperCase() ===
                    labels.Onsite?.toUpperCase() ||
                    props?.headerText?.toUpperCase() ===
                      labels.Completed?.toUpperCase()) && (
                    <Tab
                      label={labels.Completion.toUpperCase()}
                      {...a11yProps(3)}
                    />
                  )}
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <div>{orderDetailsData(woData)}</div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div>{networkDetailsData(woData)}</div>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <div>{productDetailsData(woData)}</div>
              </TabPanel>
              {props?.headerText?.toUpperCase() ===
              labels.Onsite?.toUpperCase() ? (
                <TabPanel value={value} index={3}>
                  <CompletionFormData
                    ref={ref}
                    setCompletedForm={setCompletedForm}
                    completedForm={completedForm}
                    setDisabled={setDisabled}
                    handleSubmit={handleSubmit}
                    woData={woData}
                  />
                </TabPanel>
              ) : props?.headerText?.toUpperCase() ===
                labels.Completed?.toUpperCase() ? (
                <TabPanel value={value} index={3}>
                  <CompletedTab woData={woData} />
                </TabPanel>
              ) : (
                <></>
              )}
            </div>
          </DiagContainer>
        </Modal.Body>
        {props?.headerText?.toUpperCase() === labels.Onsite?.toUpperCase() && (
          <Modal.Footer>
            <div className="submission" id="WOStatusSubmit">
              <Button
                className="submitButton"
                type="submit"
                disabled={disabled}
                title={
                  disabled ? "Please fill the necessary fields to complete" : ""
                }
                onClick={() => {
                  handleSubmit();
                }}
              >
                {labels.Complete}
              </Button>
              <Button
                bsPrefix="reset"
                type="reset"
                onClick={() => {
                  ref?.current?.cleanArray();
                }}
              >
                {labels.RESET}
              </Button>
            </div>
          </Modal.Footer>
        )}
      </Modal>
      <ConfirmPopUp
        showVal={allowedToUpdate}
        headerText="ERROR"
        popUpType="error"
        bodyHeadText=""
        bodyText={restrictionErrorMsg}
        confirmText="Close"
        handleConfirm={() => {
          setAllowedToUpdate(false);
          setRestrictionErrorMsg("");
        }}
        handleClose={() => {
          setAllowedToUpdate(false);
          setRestrictionErrorMsg("");
        }}
      />
    </div>
  );
};

export default WorkOrderPopUp;
