import { useEffect, useMemo, useRef, useState } from "react";

import { Checkbox, FormControlLabel } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { FaAngleDown, FaCheckSquare } from "react-icons/fa";
import { ImCheckboxUnchecked } from "react-icons/im";

const ColumnDropDown = (props: any) => {
  const {
    disabled = false,
    ddType,
    ddTitle,
    selectedOption,
    options,
    searchFlag,
    checkboxFlag,
    setSelectedOption,
    selectAttrVal,
    handleCheckBox,
    handleDragStart,
    handleDragOver,
    handleOnDrop,
    handleDragEnter,
    dragOver,
  } = props;

  const [searchValue, setSearchValue] = useState("");
  const [optionsDisplay, setOptionsDisplay] = useState(false);
  const wrapperRef: any = useRef([]);

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    setOptionsDisplay(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    const { current: wrap } = wrapperRef;
    if (wrap && wrap?.length !== 0 && !wrap?.contains(event.target)) {
      setOptionsDisplay(false);
    }
  };

  const optionData = useMemo(() => {
    let computedOptions = options; //?.map((e: any) => {return e?.columnName;});
    if (searchValue !== "") {
      computedOptions = computedOptions.filter((option: any) =>
        option?.columnName?.toLowerCase()?.includes(searchValue?.toLowerCase())
      );
    }
    return computedOptions;
  }, [searchValue, options]);

  useEffect(() => {
    if (optionsDisplay) {
      setSearchValue("");
    }
  }, [optionsDisplay]);

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getAttrVisible = (option: any) => {
    let selctAttr = selectAttrVal?.find((e: any) => {
      return e?.columnKey.toUpperCase() === option?.toUpperCase();
    });
    return selctAttr?.isVisible;
  };

  return (
    <div className="custDDCont">
      <button
        className="custDDBtn dropDownBtn"
        onClick={() => setOptionsDisplay(true)}
        disabled={disabled}
      >
        <span>
          <b>{ddTitle}:</b>
          {selectedOption}
        </span>
        <FaAngleDown />
      </button>
      {optionsDisplay ? (
        <div ref={wrapperRef} className="custDDOptionsCont">
          {searchFlag ? (
            <div className="searchContHead">
              <input
                type="text"
                className="custDDSearch"
                placeholder="Search Here.."
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <span>
                <SearchIcon />
              </span>
            </div>
          ) : (
            <></>
          )}
          <div className="optionsList">
            {optionData !== undefined && optionData?.length !== 0 ? (
              optionData?.map((o: any, index: any) => {
                return (
                  <div
                    className="custDDOption"
                    onClick={
                      checkboxFlag
                        ? undefined
                        : () => handleOptionClick(o?.columnKey)
                    }
                    key={index}
                  >
                    {checkboxFlag ? (
                      <div
                        className={
                          o?.jsonDataMapping === dragOver ? "dragged" : ""
                        }
                        draggable={o?.isVisible}
                        onDragStart={handleDragStart}
                        onDragOver={handleDragOver}
                        onDrop={handleOnDrop}
                        onDragEnter={handleDragEnter}
                        id={o?.jsonDataMapping}
                      >
                        <FormControlLabel
                          className="WHTCheck"
                          control={
                            <Checkbox
                              checkedIcon={<FaCheckSquare color="#da291c" />}
                              icon={<ImCheckboxUnchecked color="#da291c" />}
                              checked={getAttrVisible(o?.columnKey)}
                              onChange={() => handleCheckBox(o?.columnKey)}
                            />
                          }
                          label={o?.columnName}
                        />
                      </div>
                    ) : (
                      <>{o?.columnName}</>
                    )}
                  </div>
                );
              })
            ) : (
              <div className="option">
                <span className="historyVal">No Value Found...</span>
              </div>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ColumnDropDown;
