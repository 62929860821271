import { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { Tab, Tabs } from "@material-ui/core";

import CMTSBanner from "./CMTSBanner";
import CMTSClient from "../../../network/httpclient/cmts/CMTSClient";
import { GetCMTSNodeDetails } from "../../../get-api/GetResponse";
import { ExportCsv } from "../../gpon-page/components/ExportCsv";
import AppLabels from "../../../constants/App_Labels";
import { secToTime, TabPanel } from "../../../components/reusable/Util";
import CMTSEventLogs from "../../../components/cmtsInfo/CMTSEventLogs";
import CMTSRealTimeInfo from "../../../components/cmtsInfo/CMTSRealTimeInfo";
import { CMTSNodeDetails } from "../../../store/models/node/nodeinfo/CMTSNodeDetails";

const CMTSPage = (props: any) => {
  const search = props.location.search;
  const params = new URLSearchParams(search);
  const nodeId = params.get("nodeId");
  const macDomain = params.get("mac");
  const usPort = params.get("usPort");
  const cmtsIp = params.get("cmtsIp");
  const { cmtsNodeDetailsData, cmtsNodeDetailsLoading } = GetCMTSNodeDetails();
  const dispatch = useDispatch();
  const labels = AppLabels();
  const [bannerData, setBannerData] = useState<any>({
    cmtsIp: "",
    cmtsSysUpTime: "",
    node: "",
    macDomain: "",
    usPort: "",
    status: false,
    actions: ["Export to CSV"],
  });
  const [filterData, setFilterData] = useState<CMTSNodeDetails>();
  const [open, setOpen] = useState<boolean>(false);
  const [refreshTime, setRefreshTime] = useState<string>("");
  const [counterTime, setCounterTime] = useState<string>("00:00:00");
  const [disableRefresh, setDisableRefresh] = useState<boolean>(true);
  const [value, setValue] = useState<number>(0);
  const counter = useRef(10);
  const elapsed = useRef(0);
  const firstApiCall = useRef<any>(undefined);
  const secondApiCall = useRef<any>(undefined);

  console.log(filterData);

  const apiCall = useCallback(async () => {
    if (nodeId !== null && nodeId !== undefined && nodeId?.trim() !== "") {
      dispatch(CMTSClient.getCMTSNodeDetails(nodeId));
      setRefreshTime(new Date().toLocaleTimeString());
    }
  }, [nodeId]);

  const apiCmtsIPCall = useCallback(async () => {
    if (cmtsIp !== null && cmtsIp !== undefined && cmtsIp?.trim() !== "") {
      dispatch(CMTSClient.getCMTSIPDetails(cmtsIp));
      dispatch(CMTSClient.getCMTSRackID(cmtsIp));
      dispatch(CMTSClient.getCMTSLevel2Details(cmtsIp));
    }
  }, [cmtsIp]);

  const calcCCER = (
    uncorrectables2: number,
    uncorrectables1: number,
    total2: number,
    total1: number
  ) => {
    const total = total2 - total1;
    const uncorrectables = uncorrectables2 - uncorrectables1;
    const calc = uncorrectables / total;
    const ccer = calc * 100;
    return ccer.toFixed(4);
  };

  const calculateDelta = useCallback(
    async (firstRespone: any, secondResponse: any): Promise<any> => {
      const nodeDetailsData = { ...secondResponse };
      const firstData = firstRespone;
      const secondData = secondResponse;
      if (firstData !== undefined && secondData !== undefined) {
        if (firstData?.usChannels?.length === secondData?.usChannels?.length) {
          for (let i = 0; i < firstData?.usChannels?.length; i++) {
            for (let j = 0; j < secondData?.usChannels?.length; j++) {
              const firstDataItem = firstRespone?.usChannels[i];
              const secondDataItem = secondResponse?.usChannels[j];

              if (
                firstDataItem !== undefined &&
                secondDataItem !== undefined &&
                firstDataItem?.frequency === secondDataItem?.frequency
              ) {
                const total1 =
                  Number(firstDataItem?.correcteds ?? 0) +
                  Number(firstDataItem?.uncorrectables ?? 0) +
                  Number(firstDataItem?.unerroreds ?? 0);
                const total2 =
                  Number(secondDataItem?.correcteds ?? 0) +
                  Number(secondDataItem?.uncorrectables ?? 0) +
                  Number(secondDataItem?.unerroreds ?? 0);
                const preCWE = calcCCER(
                  Number(secondDataItem?.correcteds ?? 0),
                  Number(firstDataItem?.correcteds ?? 0),
                  total2,
                  total1
                );
                const postCer = calcCCER(
                  Number(secondDataItem?.uncorrectables ?? 0),
                  Number(firstDataItem?.uncorrectables ?? 0),
                  total2,
                  total1
                );
                nodeDetailsData.usChannels[i].preCcer = preCWE;
                nodeDetailsData.usChannels[i].postCer = postCer;
              }
            }
          }
        }
      }
      // clear the first api call data and store the second api call data in first api call ref
      firstApiCall.current = undefined;
      firstApiCall.current = secondResponse;
      secondApiCall.current = undefined;
      setFilterData(nodeDetailsData);
      return nodeDetailsData;
    },
    []
  );

  //store the firstapi call data in ref and second api call data in ref and compare the data

  const compareData = useCallback(async () => {
    if (cmtsNodeDetailsData !== undefined) {
      if (firstApiCall.current === undefined) {
        firstApiCall.current = cmtsNodeDetailsData;
      } else {
        secondApiCall.current = cmtsNodeDetailsData;
      }
      if (
        firstApiCall.current !== undefined &&
        secondApiCall.current !== undefined
      ) {
        await calculateDelta(firstApiCall.current, secondApiCall.current);
      }
    }
  }, [cmtsNodeDetailsData]);

  const getTime = useCallback(() => {
    let tempTime = counter.current;
    let seconds = tempTime;
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let timeString = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    setCounterTime(timeString);
    counter.current--;
    elapsed.current++;
  }, []);

  useEffect(() => {
    apiCall();
    apiCmtsIPCall();
  }, []);

  useEffect(() => {
    if (cmtsNodeDetailsData) {
      if (!filterData) {
        setFilterData(cmtsNodeDetailsData);
      }
      setBannerData({
        cmtsIp: cmtsNodeDetailsData.cmtsIp,
        cmtsSysUpTime: secToTime(cmtsNodeDetailsData?.cmtsUptime),
        cmtsName: cmtsNodeDetailsData?.cmtsName,
        node: nodeId,
        macDomain: macDomain,
        usPort: usPort,
        status: cmtsNodeDetailsLoading,
        actions: ["Export to CSV"],
      });
    }
  }, [cmtsNodeDetailsData]);

  const handleExportColumnData = () => {
    return [
      {
        label: "US Frequency",
        key: "usFrequency",
      },
      {
        label: "US Channel Active",
        key: "usChannelActiveCM",
      },
      {
        label: "US Channel SNR",
        key: "usChannelSNR",
      },
      {
        label: "US Pre CCER%",
        key: "usPreCCER",
      },
      {
        label: "US Post CER%",
        key: "usPostCCER",
      },
      {
        label: "UpStream Channel Util%",
        key: "upStreamChannelUtil",
      },
      {
        label: "CMTS Sysuptime",
        key: "cmtsSysUpTime",
      },
      {
        label: "Logical US Channel",
        key: "logicalUSChannel",
      },
    ];
  };

  const handleExportRowData = async () => {
    return await cmtsNodeDetailsData?.usChannels?.map((item: any) => {
      return {
        usFrequency: item.frequency,
        cmtsSysUpTime: secToTime(cmtsNodeDetailsData?.cmtsUptime),
        upStreamChannelUtil: item.utilization,
        logicalUSChannel: item.name,
        usChannelActiveCM: item.numActiveModems,
        usChannelSNR: item.snr,
        usPreCCER: item.preCcer,
        usPostCCER: item.postCer,
      };
    });
  };

  const getDefaultFileName = () => {
    return "CMTS_IP_" + bannerData.cmtsIp ?? "";
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    compareData();
  }, [cmtsNodeDetailsData]);

  useEffect(() => {
    // stop the making api call after 1 hour
    const interval = setInterval(() => {
      getTime();
      if (elapsed.current === 3600 || elapsed.current > 3600) {
        clearInterval(interval);
        setDisableRefresh(false);
      }
      // call the api every 10 seconds
      if (counter.current === 0) {
        apiCall().catch((err) => {
          clearInterval(interval);
          setDisableRefresh(false);
        });
        counter.current = 10;
      }
    }, 1000);
    // clear interval if tab is not overview
    if (value !== 0) {
      elapsed.current = 0;
      counter.current = 10;
      clearInterval(interval);
    }
    // clear the interval when component unmounts or when nodeId changes
    return () => clearInterval(interval);
  }, [value]);

  return (
    <>
      <div className="cmtsMainPage">
        {open && (
          <div className="abc">
            <ExportCsv
              show={open}
              headerText={labels.Export}
              cancelText={labels.CANCEL}
              handleClose={() => setOpen(!open)}
              rowData={JSON.parse(JSON.stringify(filterData))}
              handleCustom={true}
              handleColumnData={handleExportColumnData}
              handleRowData={handleExportRowData}
              getDefaultLibFileName={getDefaultFileName}
            />
          </div>
        )}

        <div className="cmtsBannerSec">
          <CMTSBanner
            bannerData={bannerData}
            refresh={disableRefresh}
            refreshTime={refreshTime}
            onActionClick={(args, item) => {
              console.log(args, item);
              if (item === "Export to CSV") {
                setOpen(true);
              }
            } }
            tabIndex={value} isLevel2={false}          />
        </div>

        <Tabs
          value={value}
          onChange={(event: any, newValue: any) => setValue(newValue)}
          aria-label="simple tabs example"
          className="cmtsTabSec"
        >
          <Tab label="Overview" {...a11yProps(0)} />
          <Tab label="CMTS Event Log" {...a11yProps(1)} />
        </Tabs>
        <div className="cmtsMainPageSec">
          <TabPanel value={value} index={0}>
            <CMTSRealTimeInfo
              counterTime={counterTime}
              cmtsNodeDetailsData={filterData}
              cmtsNodeDetailsLoading={cmtsNodeDetailsLoading}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <CMTSEventLogs />
          </TabPanel>
        </div>
      </div>
    </>
  );
};

export default CMTSPage;
