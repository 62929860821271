import { RecoLegend } from "../../../models/account/troubleshoot/RecoLegend";
import {
  RECO_LEGEND_BEGIN,
  RECO_LEGEND_SUCCESS,
  RECO_LEGEND_FAILURE,
  RecoLegendActionTypes,
} from "../../../types/account/troubleshoot/RecoLegendProfile";

export function recoLegendBegin(): RecoLegendActionTypes {
  return {
    type: RECO_LEGEND_BEGIN,
  };
}

export function recoLegendSuccess(
  recoLegend: RecoLegend
): RecoLegendActionTypes {
  return {
    type: RECO_LEGEND_SUCCESS,
    payload: recoLegend,
  };
}

export function recoLegendFailure(error: Error): RecoLegendActionTypes {
  return {
    type: RECO_LEGEND_FAILURE,
    payload: error,
  };
}
