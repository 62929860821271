import { NodeInfoDetails } from "../../../models/node/nodeinfo/NodeInfoDetails";

export enum ActiveNodePage {
  NODE = 0,
  GPON = 1,
  ONT = 2,
  OLT = 3,
}
export interface ActiveNodeInfoState {
  data?: NodeInfoDetails;
  activeSubTabIndex?: number;
  activeNodePage?: ActiveNodePage;
}
