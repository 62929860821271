import { BiLinkExternal } from "react-icons/bi";

import appConst from "../../../constants/appConst";
import CustomPopOver from "../../../../components/reusable/CustomPopOver";
import TopologyLabels from "../../../constants/topologyLabels";
import colors from "../../../config/colors";

interface Props {
  activeMapElement: any;
  onClick: any;
}

export default function MduSchematicsBtn({ activeMapElement, onClick }: Props) {
  const labels = TopologyLabels();

  return (
    <>
      {activeMapElement?.type === appConst.mdu && (
        <CustomPopOver
          popOverData={labels.mduDiagram}
          index="popOver"
          originComp={
            <span style={{ color: colors.link }} onClick={onClick}>
              <BiLinkExternal className="basicTableHeaderIcon" />
            </span>
          }
        />
      )}
    </>
  );
}
