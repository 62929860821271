import { FormState } from "../../../states/account/forms/FormState";
import {
  SAVE_FORM_SUCCESS,
  SAVE_FORM_BEGIN,
  SAVE_FORM_FAILURE,
  SAVE_FORM_RESET,
  NewFormActionTypes,
} from "../../../types/account/forms/Form";

const initialState: FormState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function FormReducer(
  state = initialState,
  action: NewFormActionTypes
): FormState {
  switch (action.type) {
    case SAVE_FORM_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        status: "OK",
      };
    case SAVE_FORM_BEGIN:
      return {
        status: "",
        isLoading: true,
      };
    case SAVE_FORM_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };
    case SAVE_FORM_RESET:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        data: undefined,
        status: "",
      };
    default:
      return state;
  }
}
