import React from "react";
import { Form } from "react-bootstrap";
import "../../../../design-tokens/forms/EPR.scss";
import EmailEntry from "../../../reusable/EmailEntry";
import RedAsterisk from "../../../reusable/RedAsterisk";

interface Props {
  labels: any;
  accNumber: any;
  customerEmails: any;
  setCustomerEmails: any;
  emailOptOut: any;
  setEmailOptOut: any;
  setIsEmailsValid: any;
}

const CustomerInformation = (props: Props) => {
  return (
    <>
      <label className="titleLabel">{props.labels.Customer_Information}</label>
      <Form.Group>
        <label className="txtFieldLabel">{props.labels.ACCOUNT_NUMBER}</label>
        <Form.Control value={props.accNumber} disabled={true} />
      </Form.Group>
      <label className="labelTxt">
        {props.labels.Customer_has_opted_out_of_the_Email_option}
      </label>
      <div className="checkboxContainer">
        <label>{props.labels.Customer_opted_out}</label>
        <input
          type="checkbox"
          checked={props.emailOptOut}
          style={{ width: "25px", height: "25px" }}
          onChange={(event) => props.setEmailOptOut(event.target.checked)}
        />
      </div>
      {!props.emailOptOut ? (
        <EmailEntry
          title={
            (
              <RedAsterisk textToLeft={props.labels.Customer_Emails} />
            ) as unknown as string
          }
          email={props.customerEmails}
          setEmail={props.setCustomerEmails}
          setIsEmailValid={props.setIsEmailsValid}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default CustomerInformation;
