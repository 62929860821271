import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import CustomAccordion from "../../../../components/reusable/CustomAccordion";
import ReloadData from "../../../../components/reusable/ReloadData";
import AppLabels from "../../../../constants/App_Labels";
import React from "react";
import CustomPopUp from "../../../../components/reusable/CustomPopUp";
import ErrorBox from "../../../../components/reusable/ErrorBox";
import ExceptionBox from "../../../../components/reusable/ExceptionBox";
import appConst from "../../../constants/appConst";
import TopologyLabels from "../../../constants/topologyLabels";
import {
  fileUploadReset,
  filesFetchReset,
  getAllFiles,
  uploadFile,
} from "../../../store/slices/mapItems/elementAttachments";
import { useFetchElementFiles } from "../../../util/reduxFunctions/getTopologyState";
import CustomDropFileInput from "./CustomDropFileInput";
import ElementAttachmentList from "./ElementAttachmentList";

interface props {
  activeMapElement: any;
}

const ElementAttachments = ({ activeMapElement }: props) => {
  const dispatch = useDispatch();
  const [popUpFlag, setPopUpFlag] = useState(false);
  const labels = AppLabels();
  const topologyLabels = TopologyLabels();
  const [uploads, setUploads] = React.useState<any[]>([]);
  const [fileName, setFileName] = useState("");
  const [fileUploadFlag, setFileUploadFlag] = useState(false);
  const [fileDownloadFlag, setFileDownloadFlag] = useState(false);
  const [description, setDescription] = useState("");
  const isRef = useRef(false);
  const sizeLimit = 5 * 1024 * 1024; // 5MB
  const { fetchData, isLoading, status, fetchStatus, data, fetchIsLoading } =
    useFetchElementFiles();
  const saveFileToDevice = async (attachment: any) => {
    const file = attachment?.fileData;
    // Convert the base64 string to a Blob
    const regex = /^data:(.+);base64,(.*)$/;
    const match = file.match(regex);
    if (!match || match.length !== 3) {
      return;
    }
    const mimeType = match[1];
    const base64Data = match[2];
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = attachment?.fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    isRef.current = false;
  };
  const handleDownload = (attachment: any) => {
    if (attachment?.fileName.length !== 0) {
      setFileName(attachment?.fileName);
    }
    isRef.current = true;
    saveFileToDevice(attachment);
  };
  const handleUpload = () => {
    dispatch(
      uploadFile(uploads[0], activeMapElement?.data?.networkId, description)
    );
    setPopUpFlag(false);
    setUploads([]);
    setFileUploadFlag(true);
    setDescription("");
  };
  const onRefresh = () => {
    activeMapElement?.data?.networkId !== undefined &&
      dispatch(getAllFiles(activeMapElement?.data?.networkId));
  };

  useEffect(() => {
    activeMapElement?.data?.networkId !== undefined &&
      fetchData === undefined &&
      onRefresh();
  }, []);

  useEffect(() => {
    activeMapElement?.data?.networkId !== undefined && fetchData && onRefresh();
  }, [activeMapElement?.data?.networkId]);

  useEffect(() => {
    if (status !== undefined && status !== "") {
      if (status === appConst.OK) {
        onRefresh();
      }
    }
    return () => {
      dispatch(fileUploadReset());
    };
  }, [status]);

  return (
    <div>
      <div className="refreshCont">
        <button
          className="uploadButton blueHollowBtn"
          onClick={() => setPopUpFlag(true)}
        >
          {labels.Upload_a_File?.toUpperCase()}
        </button>

        <span className="reloadButton">
          <ReloadData disabled={fetchIsLoading} refreshData={onRefresh} />
        </span>
      </div>
      <div className="activeMapEleCont">
        <CustomAccordion
          accTitle={topologyLabels.attachmentList}
          accBodyElement={
            fetchData === undefined || fetchData?.length === 0 ? (
              <div className="diagErrorCont">
                <ExceptionBox
                  headerText={topologyLabels.noAttachmentFound}
                  bodyText={topologyLabels.uploadFile}
                />
              </div>
            ) : (
              <div className="activeMapEleCont">
                <table className="dataTableDetails">
                  <thead>
                    <tr>
                      <td>{topologyLabels?.download}</td>
                      <td style={{ minWidth: 80 }}>
                        {topologyLabels?.fileName}
                      </td>
                      <td style={{ minWidth: 80 }}>{topologyLabels?.date}</td>
                      <td style={{ minWidth: 80 }}>
                        {topologyLabels?.description}
                      </td>
                      <td>{topologyLabels?.delete}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {fetchData !== undefined &&
                      fetchData?.length !== 0 &&
                      fetchData?.map((attachment: any) => {
                        return (
                          <ElementAttachmentList
                            key={attachment?.attachmentId}
                            attachment={attachment}
                            handleDownload={handleDownload}
                            dispatch={dispatch}
                            networkId={activeMapElement?.data?.networkId}
                          />
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )
          }
        />
      </div>
      <CustomPopUp
        showVal={popUpFlag}
        headerText={labels.Upload_a_File?.toUpperCase()}
        bodyClassName={"uploadBody"}
        className={"fileUploadPopUp"}
        confirmText={labels.Upload}
        buttonDisabled={isLoading || uploads?.[0]?.size > sizeLimit}
        handleConfirm={handleUpload}
        bodyElement={
          <CustomDropFileInput
            file={uploads}
            onFileChange={setUploads}
            description={description}
            setDescription={setDescription}
          />
        }
        handleClose={() => setPopUpFlag(false)}
      />
      {/* <ResponseSnackBar
        open={fileUploadFlag}
        isHeader={true}
        requestType={labels.File_Upload}
        handleClose={() => setFileUploadFlag(false)}
        responseType={status}
      /> */}
      <ErrorBox
        open={fileDownloadFlag}
        error={labels.fileDownloadError}
        handleClose={() => setFileDownloadFlag(false)}
      />
    </div>
  );
};

export default ElementAttachments;
