import { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { GroundOverlay, Polyline } from "@react-google-maps/api";

import appConst from "../../../../constants/appConst";
import {
  GetGoogleMaps,
  usePowertraceStates,
} from "../../../../util/reduxFunctions/getTopologyState";
import { mapElementStyles } from "../../../../config/mapItemsConfig";
import {
  useTracePointsData,
  useTapAddressTraceData,
} from "../../../../util/customHooks/topologyHooks";
import {
  getMapEleImageObj,
  getTapNetworkIdsFromPowertraceData,
  getTraceCablesData,
} from "../../../../util/utilFuncs";
import { getPowertraceAddresses } from "../../../../store/slices/mapItems/powertrace/powertrace";

interface Props {
  allNodeData?: any;
  pngImages: any;
}

export default function PowertraceElements({ allNodeData, pngImages }: Props) {
  const dispatch = useDispatch();
  const { currentViewBDY } = GetGoogleMaps();
  const { isActive, elements, addresses } = usePowertraceStates();

  const cables = getTraceCablesData(elements);
  const pointsData = useTracePointsData(allNodeData, elements, currentViewBDY);
  /*   const tapAddressTraceData = useTapAddressTraceData(allNodeData, addresses);
   */
  /* useEffect(() => {
    if (elements) {
      dispatch(
        getPowertraceAddresses(getTapNetworkIdsFromPowertraceData(elements))
      );
    }
  }, [elements]); */

  return (
    <>
      {isActive && (
        <Fragment>
          {pointsData !== undefined &&
            Object.keys(pointsData)?.map((e) => {
              return pointsData?.[e]?.map((pointData: any) => {
                if (
                  pointData?.geometry?.type === appConst.Point &&
                  pointData?.symbolName
                ) {
                  const eleImageOverlay: any = getMapEleImageObj(
                    pngImages?.[pointData?.type],
                    pointData?.symbolName,
                    pointData?.rotation,
                    pointData?.type === appConst.node
                      ? mapElementStyles?.nodeSite
                      : mapElementStyles?.[pointData?.type],
                    pointData?.geometry?.coordinates?.[1],
                    pointData?.geometry?.coordinates?.[0]
                  );

                  if (eleImageOverlay?.symbol !== undefined) {
                    return (
                      <GroundOverlay
                        key={`${pointData?.networkId}powertracePoint`}
                        url={`${appConst.base64StringPrefix}${eleImageOverlay?.symbol}`}
                        bounds={
                          new google.maps.LatLngBounds(
                            new google.maps.LatLng(
                              eleImageOverlay?.southBound,
                              eleImageOverlay?.westBound
                            ),
                            new google.maps.LatLng(
                              eleImageOverlay?.northBound,
                              eleImageOverlay?.eastBound
                            )
                          )
                        }
                      />
                    );
                  }
                }
              });
            })}

          {cables !== undefined &&
            cables?.map((cable: any) => {
              if (cable?.geometry?.type === appConst.line) {
                let lineLatLngArray: any = [];
                cable?.geometry?.coordinates?.map((cord: any) => {
                  lineLatLngArray.push({ lat: cord?.[1], lng: cord?.[0] });
                });
                return (
                  <Polyline
                    key={`${cable?.networkId}powertraceCable`}
                    path={lineLatLngArray}
                    options={mapElementStyles?.powertrace?.line}
                  />
                );
              }
            })}

          {/* {tapAddressTraceData !== undefined &&
            tapAddressTraceData?.map((addr: any) => {
              const tapAddressCoords =
                addr?.tapFeatureData?.geometry?.coordinates;
              const rfDropAddresCoords =
                addr?.rfDropAddressFeatureData?.geometry?.coordinates;

              if (tapAddressCoords && rfDropAddresCoords) {
                const path: any = [
                  {
                    lat: tapAddressCoords?.[1],
                    lng: tapAddressCoords?.[0],
                  },
                  {
                    lat: rfDropAddresCoords?.[1],
                    lng: rfDropAddresCoords?.[0],
                  },
                ];
                return (
                  <Polyline
                    key={`${addr?.networkId}${addr?.tapNetworkId}address`}
                    path={path}
                    options={mapElementStyles?.powertrace?.line}
                  />
                );
              }
            })} */}
        </Fragment>
      )}
    </>
  );
}
