import React, { useMemo, useState, useRef, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TablePagination,
  Snackbar,
} from "@material-ui/core";

import NodeTelemetryTableHead from "./NodeTelemetryTableHead";
import { getValueByStrMapping } from "../reusable/Util";
import {
  filterSpecData,
  checkNullInTelemetry,
  getModemsData,
} from "../reusable/NodeUtil";
import ExceptionBox from "../reusable/ExceptionBox";
import LoaderGif from "../../resources/images/loader.webp";
import AppLabels from "../../constants/App_Labels";
import CustomPopUp from "../reusable/CustomPopUp";
import CertiExceptionCont from "../certification/CertiExceptionCont";
import TelemetryDataMapping from "./TelemetryDataMapping";
import ErrorIcon from "../../resources/images/icons/ErrorIcon.svg";
import {
  GetTelemetryDetails,
  GetNodeInfoDetails,
} from "../../get-api/GetResponse";
import { BsBoxArrowUpRight } from "react-icons/bs";
import AccDetailsClient from "../../network/httpclient/accountInfo/AccDetailsClient";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  AccReset,
  SetActiveSubTabIndex,
} from "../../store/actions/account/accountInfo/ActiveAccountAction";
import { Alert } from "@material-ui/lab";
import { GetTelemetryProps } from "../../topology/util/reduxFunctions/getTopologyState";
import NeighPartialService from "../neighbourhood/NeighPartialService";
const NodeTeleDevicesTable = (props: any) => {
  const {
    isFullScreen,
    tableAttributes,
    searchAttributes,
    sortAttributes,
    nodeDevicesList,
    handleDragStart,
    handleDragOver,
    handleOnDrop,
    handleDragEnter,
    dragOver,
  } = props;
  const telemetryData = TelemetryDataMapping(nodeDevicesList);
  const tableData = telemetryData?.tableData;
  const labels = AppLabels();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(500);
  const [popUpFlag, setPopUpFlag] = useState(false);
  const [popUpHeader, setPopUpHeader] = useState("");
  const [popUpBody, setPopUpBody] = useState(<div></div>);

  const [regStatusPopUpFlag, setRegStatusPopUpFlag] = useState(false);
  const [regStatusHeader, setRegStatusHeader] = useState("");
  const [regStatusPopUpBody, setRegStatusPopUpBody] = useState(<div></div>);

  const topScrollRef = useRef<HTMLDivElement>(null);
  const mainTableRef = useRef<HTMLDivElement>(null);
  const [scrollWidth, setScrollWidth] = useState<number | undefined>(
    mainTableRef.current?.scrollWidth
  );

  const allDiagIsLoading = GetTelemetryProps().allDiagIsLoading;
  const nodeInfo = GetNodeInfoDetails().currNodedata;

  /*Properties added for searching feature on Telemetry Table */
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [searchValues, setSearchValues] = useState(new Map<String, String>());
  const [specInfo, setSpecInfo] = useState(new Map<String, Boolean>());
  const macRef = React.useRef(false);
  const macIdRef = React.useRef("");
  const dispatch = useDispatch();
  const { accInfo, status } = useSelector(
    (state: any) => ({
      accInfo: state.AccountState.AccountDetails?.data,
      status: state.AccountState.AccountDetails.status,
    }),
    shallowEqual
  );

  const [openSnackBar, setOpenSnackBar] = useState(false);

  /* debounce the resize handling to prevent re-rendering */
  function debounce(fn: Function, ms: number) {
    let timer: any;
    return (_: any, ...args: any) => {
      clearTimeout(timer);
      timer = setTimeout((_) => {
        timer = null;
        fn.apply(Function, args);
      }, ms);
    };
  }

  const openSingleCMView = (cmMac: any) => {
    let dataObj = getModemsData(cmMac, nodeDevicesList, nodeInfo);
    let url = window?.open(`/#/cablemodem?macId=${cmMac}`, cmMac)!;
    url?.addEventListener("load", () => {
      url.postMessage(dataObj, url.origin);
    });
  };

  const isModemLoaded = (cmMac: any) => {
    let modem: any = nodeDevicesList?.find((m: any) => m?.macAddress === cmMac);
    return modem?.deviceDiag?.data !== undefined; //added for streaming
  };

  const navigateToAcc = React.useCallback(
    (macId?: string) => {
      if (accInfo?.accountNumber !== undefined) {
        macRef.current = false;

        let newWindow =
          macIdRef.current?.trim() !== ""
            ? window.open(
                `/#/account?accountNumber=${
                  accInfo?.accountNumber
                }&mac=${macIdRef.current?.trim()}`,
                "_blank"
              )!
            : window.open(
                `/#/account?accountNumber=${accInfo?.accountNumber}`,
                "_blank"
              )!;
        macIdRef.current = "";
        newWindow.onload = () => {
          newWindow.document.title = "Account-" + accInfo?.accountNumber;
        };
      } else if (status === "ERROR") {
        setOpenSnackBar(true);
        dispatch(AccReset());
        macRef.current = false;
      }
    },
    [accInfo?.accountNumber, dispatch, status]
  );

  useEffect(() => {
    if (macRef.current) {
      dispatch(AccReset());
      navigateToAcc();
    }
  }, [accInfo?.accountNumber, status, navigateToAcc, dispatch]);

  const handleExternalLink = (cmMac: any) => {
    dispatch(AccDetailsClient.getAccDetails(cmMac, "macAddress"));
    dispatch(SetActiveSubTabIndex(1));
    macRef.current = true;
    macIdRef.current = cmMac?.toString();
    navigateToAcc();
  };

  const filterContent = (data: any, key: String, value: String) => {
    return data
      ?.filter((d: any) => checkNullInTelemetry(d))
      ?.filter((device: any) =>
        checkNullInTelemetry(
          getValueByStrMapping(device, key?.toString()) &&
            getValueByStrMapping(device, key?.toString())
        )
          ?.toString()
          ?.toLowerCase()
          ?.includes(value?.toLowerCase())
      );
  };

  const filterDevicesData = useMemo(() => {
    let filterData = tableData;
    specInfo?.forEach((value: Boolean, key: String) => {
      let keys: Array<String> = key.split(",");
      if (keys.length > 1) {
        filterData = filterSpecData(filterData, keys[0], keys[1], value);
      }
    });

    searchValues?.forEach((value: String, key: String) => {
      if (value?.length > 0 && key?.length > 0) {
        filterData = filterContent(filterData, key, value);
      }
    });

    if (sorting?.field) {
      // console.log(sorting?.field);
      // console.log(filterData[0]);

      const reversed = sorting?.order === "asc" ? 1 : -1;
      if (sorting?.field?.toLowerCase() === "address".toLowerCase()) {
        filterData = filterData?.sort((a: any, b: any) => {
          const addressA = a?.serviceAddress;
          const addressB = b?.serviceAddress;
          // first compare the street number and then street name and then apartment number
          const streetNumberA = addressA?.streetNumber;
          const streetNumberB = addressB?.streetNumber;
          const streetNameA = addressA?.streetName;
          const streetNameB = addressB?.streetName;
          const apartmentNumberA = addressA?.aptNumber;
          const apartmentNumberB = addressB?.aptNumber;
          const collator = new Intl.Collator(undefined, {
            numeric: true,
            sensitivity: "base",
          });

          if (collator.compare(streetNumberA, streetNumberB) === 0) {
            if (collator.compare(streetNameA, streetNameB) === 0) {
              return (
                collator.compare(apartmentNumberA, apartmentNumberB) * reversed
              );
            }
            return collator.compare(streetNameA, streetNameB) * reversed;
          }
          return collator.compare(streetNumberA, streetNumberB) * reversed;

          // return addressA?.streetName?.localeCompare(addressB?.streetName) === 0
          //   ? addressA?.streetNumber?.localeCompare(addressB?.streetNumber) ===
          //     0
          //     ? reversed *
          //     addressA?.aptNumber?.localeCompare(addressB?.aptNumber)
          //     : reversed *
          //     addressA?.streetNumber?.localeCompare(addressB?.streetNumber)
          //   : reversed *
          //   addressA?.streetName?.localeCompare(addressB?.streetName);
        });
      }
      // FIRST and LAST flap time
      else if (
        sorting?.field?.toLowerCase() === "createTime".toLowerCase() ||
        sorting?.field?.toLowerCase() === "lastFlapTime".toLowerCase()
      ) {
        filterData.sort((a: any, b: any) => {
          const selectedTimeA =
            a?.[sorting?.field] && a?.[sorting?.field].trim() !== ""
              ? new Date(a[sorting?.field])
              : new Date(0);
          const selectedTimeB =
            b?.[sorting?.field] && b?.[sorting?.field].trim() !== ""
              ? new Date(b[sorting?.field])
              : new Date(0);

          return (
            (selectedTimeA > selectedTimeB
              ? 1
              : selectedTimeA < selectedTimeB
              ? -1
              : 0) * reversed
          );
        });
      }

      // UPTIME AND REGTIME
      else if (
        sorting?.field?.toLowerCase() === "cmUptime".toLowerCase() ||
        sorting?.field?.toLowerCase() === "cmtsUptime".toLowerCase()
      ) {
        filterData.sort((a: any, b: any) => {
          const strA = a[sorting?.field] || "";
          const strB = b[sorting?.field] || "";

          const daysMatchA = strA.match(/(\d+)d/);
          const hoursMatchA = strA.match(/(\d+)h/);
          const minutesMatchA = strA.match(/(\d+)m/);

          const daysA = daysMatchA ? parseInt(daysMatchA[1]) : 0;
          const hoursA = hoursMatchA ? parseInt(hoursMatchA[1]) : 0;
          const minutesA = minutesMatchA ? parseInt(minutesMatchA[1]) : 0;

          const totalMinutesA = daysA * 24 * 60 + hoursA * 60 + minutesA;

          const daysMatchB = strB.match(/(\d+)d/);
          const hoursMatchB = strB.match(/(\d+)h/);
          const minutesMatchB = strB.match(/(\d+)m/);

          const daysB = daysMatchB ? parseInt(daysMatchB[1]) : 0;
          const hoursB = hoursMatchB ? parseInt(hoursMatchB[1]) : 0;
          const minutesB = minutesMatchB ? parseInt(minutesMatchB[1]) : 0;

          const totalMinutesB = daysB * 24 * 60 + hoursB * 60 + minutesB;

          return (
            (totalMinutesA > totalMinutesB
              ? 1
              : totalMinutesA < totalMinutesB
              ? -1
              : 0) * reversed
          );
        });
      } else if (sorting?.field?.toLowerCase() === "minrxpower") {
        filterData = filterData?.sort((a: any, b: any) => {
          const minRxPowerA =
            a?.minRxPower !== ""
              ? parseFloat(a?.minRxPower)
              : Number.NEGATIVE_INFINITY;
          const minRxPowerB =
            b?.minRxPower !== ""
              ? parseFloat(b?.minRxPower)
              : Number.NEGATIVE_INFINITY;

          return (
            (minRxPowerA > minRxPowerB
              ? 1
              : minRxPowerA < minRxPowerB
              ? -1
              : 0) * reversed
          );
        });
      } else if (sorting?.field?.toLowerCase() === "smt") {
        filterData = filterData?.sort((a: any, b: any) => {
          const fieldA = getValueByStrMapping(a, sorting?.field?.toString());
          const fieldB = getValueByStrMapping(b, sorting?.field?.toString());
          return Number(fieldA) > Number(fieldB)
            ? reversed
            : Number(fieldA) === Number(fieldB)
            ? 0
            : -reversed;
        });
      } else {
        filterData = filterData?.sort((a: any, b: any) => {
          const fieldA = getValueByStrMapping(
            a,
            sorting?.field?.toString()
          )?.toString();
          const fieldB = getValueByStrMapping(
            b,
            sorting?.field?.toString()
          )?.toString();
          const collator = new Intl.Collator(undefined, {
            numeric: true,

            sensitivity: "base",
          });

          return collator.compare(fieldA, fieldB) * reversed;
        });
      }
      return filterData;
    }

    props.onDataChange(filterData);
    return filterData;
  }, [search, searchBy, sorting, tableData, specInfo]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const renderCodewords = (modem: any, attr: any) => {
    if (attr?.displayAttribute)
      return checkNullInTelemetry(modem?.[attr?.displayAttribute]);
    else return <>{checkNullInTelemetry(modem?.[attr?.jsonDataMapping])}</>;
  };
  const partialService = (data: any) => {
    setPopUpHeader("CM PARTIAL SERVICE");
    setPopUpBody(
      <CertiExceptionCont dataObj={data} sectionName="CM Docsis">
        <NeighPartialService partialServiceData={data?.partialService} ofdmDownStreamChannelsData={data?.ofdm?.downstreamChannels} />
      </CertiExceptionCont>
    );
    setPopUpFlag(true);
  }
  const renderDocsis = (modem: any, attr: any) => {
    if (
      ((attr?.columnKey === labels.us_ps || attr?.columnKey === labels.ds_ps) &&
        checkNullInTelemetry(modem?.[attr?.jsonDataMapping])
          ?.toString()
          .toLowerCase() === labels.yes.toString().toLowerCase()) ||
      ((attr?.columnKey === labels.ofdma_status ||
        attr?.columnKey === labels.ofdm_status) &&
        checkNullInTelemetry(modem?.[attr?.jsonDataMapping])
          ?.toString()
          .toLowerCase() === labels.dormant.toString().toLowerCase())
    )
      return (
        <div className="dsValue" onClick={() => partialService(modem?.docsis)}>
          <span>{checkNullInTelemetry(modem?.[attr?.jsonDataMapping])}</span>
          <img style={{ height: "14px" }} src={ErrorIcon} alt="Error" />
        </div>
      );
      if (
        ((attr?.columnKey === labels.us_ps || attr?.columnKey === labels.ds_ps) &&
          checkNullInTelemetry(modem?.[attr?.jsonDataMapping])
            ?.toString()
            .toLowerCase() === labels.yes.toString().toLowerCase()) ||
        ((attr?.columnKey === labels.ofdma_status ||
          attr?.columnKey === labels.ofdm_status) &&
          checkNullInTelemetry(modem?.[attr?.jsonDataMapping])
            ?.toString()
            .toLowerCase() === labels.down.toString().toLowerCase())
      )
      return (
        <div className="dsValue" onClick={() => partialService(modem?.docsis)}>
          <span>{checkNullInTelemetry(modem?.[attr?.jsonDataMapping])}</span>
          <img style={{ height: "14px" }} src={ErrorIcon} alt="Error" />
        </div>
      );
      if (attr?.columnKey === "ofdma_channel_ID") {
        const profileData = modem[attr?.jsonDataMapping];
        if (Array.isArray(profileData) && profileData.length > 0) {
            return (
                <div>
                    {profileData.map((nestedArray, arrayIndex) => (
                        Array.isArray(nestedArray) ? nestedArray.map((profile, profileIndex) => (
                          profile?.active === 'true' ?  <div key={`${arrayIndex}-${profileIndex}`} style={{ fontWeight: profile?.active && 'bold'}}>
                                {profile?.profileString}
                            </div>:(
                              <div key={`${arrayIndex}-${profileIndex}`}>
                              {profile?.profileString}
                          </div>
                            )
                        )) : null
                    ))}
                </div>
            );
        } else {
          
            return <></>;
        }
      }
        
      if (attr?.columnKey === "ofdm_Channel_ID" ) {
        
          const profiles = modem[attr?.jsonDataMapping];
          if (Array.isArray(profiles)&& profiles.length > 0) {
            return (
              <div>
                {profiles?.map((profile: any, index: any) => (
                  <div key={index} style={{ fontWeight: profile?.active  && 'bold'  }}>
                    {profile?.profileString}
                  </div>
                ))}
              </div>
            );
          } else {
            return ""; 
          }
        }
    else if (attr?.displayAttribute)
      return checkNullInTelemetry(modem?.[attr?.displayAttribute]);
    else return <>{checkNullInTelemetry(modem?.[attr?.jsonDataMapping])}</>;
  };
  const getCellContent = (modem: any, attr: any) => {
    let cellContent = null;
    switch (attr?.getDataFrom) {
      case labels.LIST_OF_MODEMS:
        if (attr?.columnKey === "cm_mac") {
          cellContent = (
            <div className="cmMacWrapper">
              {isModemLoaded(modem?.cmMac) ? (
                <span
                  id="cm_mac"
                  className="idLink"
                  title="Single Cable Modem View"
                  onClick={() => {
                    openSingleCMView(modem?.cmMac);
                  }}
                >
                  {" "}
                  {checkNullInTelemetry(modem?.[attr?.jsonDataMapping])}
                </span>
              ) : (
                <span id="cm_mac" title="Single Cable Modem View">
                  {" "}
                  {checkNullInTelemetry(modem?.[attr?.jsonDataMapping])}
                </span>
              )}

              <span
                className="externalLink"
                title="Customer Account View"
                onClick={() => {
                  handleExternalLink(modem?.cmMac);
                }}
              >
                <BsBoxArrowUpRight size={16} />
              </span>
            </div>
          );
        } else if (attr?.columnKey === "address") {
          let addressValue = checkNullInTelemetry(
            modem?.[attr?.jsonDataMapping]
          );

          let trimmedValue = addressValue?.slice(0, 25);
          cellContent = (
            <span title={addressValue}>
              {addressValue?.length > 26 ? trimmedValue : addressValue}
            </span>
          );
        } else {
          cellContent = (
            <span>{checkNullInTelemetry(modem?.[attr?.jsonDataMapping])}</span>
          );
        }
        break;
      case labels.SUMMARY.toLowerCase():
        if (modem?.summary !== undefined && modem?.summary?.errorCode) {
          cellContent = (
            <span
              onClick={() => {
                handleErrorClick(modem?.cmMac, modem?.summary);
              }}
              className="failLinkState"
            >
              ERROR
            </span>
          );
        } else if (
          modem?.summary !== undefined &&
          modem?.summary?.errorCode === undefined
        ) {
          if (attr?.columnKey === "reg_status") {
            if (
              modem?.summary?.regStatus?.header !== null &&
              modem?.summary?.regStatus?.header !== undefined
            ) {
              cellContent = (
                <span
                  onClick={() => {
                    handleRegStatusClick(modem?.summary?.regStatus);
                  }}
                  className={
                    modem?.[attr?.jsonDataMapping]?.toLowerCase() === "offline"
                      ? "failLinkState"
                      : "successLinkState"
                  }
                >
                  {checkNullInTelemetry(
                    modem?.[attr?.jsonDataMapping]
                  )?.toUpperCase()}
                </span>
              );
            } else {
              cellContent = (
                <span>
                  {checkNullInTelemetry(
                    modem?.[attr?.jsonDataMapping]
                  )?.toUpperCase()}
                </span>
              );
            }
          } else {
            cellContent = (
              <span>
                {checkNullInTelemetry(modem?.[attr?.jsonDataMapping])}
              </span>
            );
          }
        } else if (allDiagIsLoading && modem?.summary === undefined) {
          cellContent = (
            <img className="nodeTeleDataLoader" src={LoaderGif} alt="Loading" />
          );
        }
        break;
      case labels.FLAPS.toLowerCase():
        if (modem?.flaps !== undefined && modem?.flaps?.errorCode) {
          cellContent = (
            <span
              onClick={() => {
                handleErrorClick(modem?.cmMac, modem?.flaps);
              }}
              className="failLinkState"
            >
              ERROR
            </span>
          );
        } else if (
          modem?.summary !== undefined &&
          modem?.summary?.errorCode === undefined
        ) {
          cellContent = (
            <span>{checkNullInTelemetry(modem?.[attr?.jsonDataMapping])}</span>
          );
        } else if (modem?.apiStatus?.isLoading && modem?.flaps === undefined) {
          cellContent = (
            <img className="nodeTeleDataLoader" src={LoaderGif} alt="Loading" />
          );
        }
        break;
      case labels.DOCSIS.toLowerCase():
        if (modem?.docsis !== undefined && modem?.docsis?.errorCode) {
          cellContent = (
            <span
              onClick={() => {
                handleErrorClick(modem?.cmMac, modem?.docsis);
              }}
              className="failLinkState"
            >
              ERROR
            </span>
          );
        } else if (
          modem?.docsis !== undefined &&
          modem?.docsis?.errorCode === undefined
        ) {
          cellContent = (
            <span
              className={
                attr?.hasOwnProperty("haveMultipleValues") ? "cellNewLine" : ""
              }
            >
              {renderDocsis(modem, attr)}
            </span>
          );
        } else if (modem?.apiStatus?.isLoading && modem?.docsis === undefined) {
          cellContent = (
            <img className="nodeTeleDataLoader" src={LoaderGif} alt="Loading" />
          );
        }
        break;
      case labels.Codewords.toLowerCase():
        if (modem?.codewords !== undefined && modem?.codewords?.errorCode) {
          cellContent = (
            <span
              onClick={() => {
                handleErrorClick(modem?.cmMac, modem?.codewords);
              }}
              className="failLinkState"
            >
              ERROR
            </span>
          );
        } else if (
          modem?.codewords !== undefined &&
          modem?.codewords?.errorCode === undefined
        ) {
          cellContent = (
            <span
              className={
                attr?.hasOwnProperty("haveMultipleValues") ? "cellNewLine" : ""
              }
            >
              {renderCodewords(modem, attr)}
            </span>
          );
        } else if (
          modem?.apiStatus?.isLoading &&
          modem?.codewords === undefined
        ) {
          cellContent = (
            <img className="nodeTeleDataLoader" src={LoaderGif} alt="Loading" />
          );
        }
        break;
      default:
        break;
    }
    return (
      <React.Fragment key={`$${attr?.columnKey + modem?.cmMac}_cellContent`}>
        {cellContent}
      </React.Fragment>
    );
  };

  const handleErrorClick = (mac: string, data: any) => {
    setPopUpHeader(labels.ERROR.toUpperCase() + " - " + mac);
    setPopUpFlag(true);
    setPopUpBody(
      <CertiExceptionCont dataObj={data} sectionName="CM Diagnostics" />
    );
  };

  const handleRegStatusClick = (data: any) => {
    setRegStatusHeader(data?.header?.toUpperCase());
    setRegStatusPopUpFlag(true);
    setRegStatusPopUpBody(
      <>
        {[data]?.map((item, index) => {
          return (
            <div
              className="errorPopupTelemetry"
              key={"RegStatus" + index * index + 998}
            >
              <div className="errorPopUpLabel">
                <div className="errorPopUpLabelName">{labels.CMTS_Status}</div>
                <div className="descriptionName">{item?.cmtsStatus}</div>
              </div>
              <div className="errorPopUpLabel">
                <div className="errorPopUpLabelName">{labels.OAL_Error}</div>
                <div className="descriptionName">{item?.oalError}</div>
              </div>
              <div className="errorPopUpLabel">
                <div className="errorPopUpLabelName">
                  {labels.CMTS_CM_Online_Status}
                </div>
                <div className="descriptionName">
                  {item?.cmtsCMOnlineStatus}
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const handleScroll = (event: React.UIEvent<React.ReactNode>) => {
    const targetDiv: HTMLDivElement = event.target as HTMLDivElement;
    if (targetDiv === topScrollRef.current && mainTableRef.current) {
      mainTableRef.current.scrollLeft = targetDiv.scrollLeft;
    } else if (targetDiv === mainTableRef.current && topScrollRef.current) {
      topScrollRef.current.scrollLeft = targetDiv.scrollLeft;
    }
  };
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const handleDoubleClick = (row: any) => {
    const isRowSelected = selectedRows.includes(row);

    if (isRowSelected) {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((selectedRow) => selectedRow !== row)
      );
    } else {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, row]);
    }
  };

  const isRowSelected = useMemo(() => {
    const selectedRowSet = new Set(selectedRows);

    return (row: any) => selectedRowSet.has(row);
  }, [selectedRows]);

  useEffect(() => {
    if (mainTableRef.current) {
      const debouncedHandler = debounce(
        () => setScrollWidth(mainTableRef.current?.scrollWidth),
        1000
      );
      ["resize", "click", "mousemove"].forEach((event) => {
        window.addEventListener(event, debouncedHandler, false);
      });
      setScrollWidth(mainTableRef.current?.scrollWidth);
      return () => {
        ["resize", "click", "mousemove"].forEach((event) => {
          window.removeEventListener(event, debouncedHandler, false);
        });
      };
    }
  }, [mainTableRef.current?.scrollWidth]);

  return (
    <div className="certiTableCont">
      <Paper className="nodeTeleDeviceTablePaper ">
        <Snackbar
          open={openSnackBar}
          autoHideDuration={2000}
          onClose={() => setOpenSnackBar(!openSnackBar)}
        >
          <Alert severity="warning">
            Unable to Fetch Details for the Selected Mac Address.
          </Alert>
        </Snackbar>
        <div
          ref={topScrollRef}
          className="nodeTopScroll"
          style={{
            overflowY:
              mainTableRef.current &&
              mainTableRef.current?.offsetHeight >=
                mainTableRef.current?.scrollHeight
                ? "hidden"
                : "scroll",
          }}
          onScroll={handleScroll}
        >
          <div
            style={{
              height: "16px",
              width: scrollWidth,
            }}
          />
        </div>
        <TableContainer
          ref={mainTableRef}
          onScroll={handleScroll}
          className={
            isFullScreen
              ? "nodeTeleTableContFull "
              : "nodeTeleTableCont nodeTopScroll"
          }
        >
          <Table
            stickyHeader={true}
            className="nodeTeleTable"
            /* style={{ minWidth: `${tableAttributes?.length * 100}px` }} */
          >
            <NodeTelemetryTableHead
              tableAttr={tableAttributes}
              searchAttributes={searchAttributes}
              sortAttributes={sortAttributes}
              onSpecDetailsChange={(specContent: Map<String, Boolean>) =>
                setSpecInfo(specContent)
              }
              onSearch={(
                value: any,
                by: string,
                searchMap: Map<String, String>
              ) => {
                setSearch(value);
                setSearchBy(by);
                setSearchValues(searchMap);
              }}
              onSorting={(field: any, order: any) => {
                setSorting({ field, order });
              }}
              handleDragStart={handleDragStart}
              handleDragOver={handleDragOver}
              handleOnDrop={handleOnDrop}
              handleDragEnter={handleDragEnter}
              dragOver={dragOver}
            />
            <TableBody className="dTabBody">
              {filterDevicesData?.length === 0 ? (
                <TableRow key="exception">
                  <TableCell colSpan={tableAttributes?.length}>
                    <div className="diagErrorCont">
                      <ExceptionBox
                        headerText={labels.NO_DATA}
                        bodyText={labels.NO_DATA_SEARCH_VAL}
                        primaryBtn={false}
                        secBtn={false}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                React.Children.toArray(
                  filterDevicesData
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((modem: any, index: number) => {
                      return (
                        <TableRow
                          tabIndex={-1}
                          key={"TableRow" + index * index * index}
                        >
                          {React.Children.toArray(
                            tableAttributes?.map((attr: any, idx: number) => {
                              return (
                                attr?.isVisible && attr?.columnKey!=='selectAll' &&(
                                  <TableCell
                                    key={"TableCell" + idx * idx + 888}
                                    align="left"
                                    className={
                                      attr.jsonDataMapping === dragOver
                                        ? "dragged"
                                        : attr.styleClassName
                                        ? attr.styleClassName
                                        : ""
                                    }
                                    draggable={
                                      attr.jsonDataMapping === dragOver
                                    }
                                    onDoubleClick={() =>
                                      handleDoubleClick(modem)
                                    }
                                    style={
                                      attr?.columnKey === "address"
                                        ? {
                                            whiteSpace: "nowrap",
                                            maxWidth: "400px",
                                            display: "block",
                                            overflowX: "hidden",
                                            fontWeight: 550,
                                            backgroundColor: isRowSelected(
                                              modem
                                            )
                                              ? "yellow"
                                              : "",
                                          }
                                        : {
                                            fontWeight: 550,
                                            backgroundColor: isRowSelected(
                                              modem
                                            )
                                              ? "yellow"
                                              : "",
                                          }
                                    }
                                  >
                                    {getCellContent(modem, attr)}
                                  </TableCell>
                                )
                              );
                            })
                          )}
                        </TableRow>
                      );
                    })
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="certiPageCont">
          <p>TOTAL MODEMS : {filterDevicesData?.length}</p>
          <TablePagination
            rowsPerPageOptions={[500, 1000, 1500, 2000]}
            component="div"
            count={filterDevicesData?.length ? filterDevicesData?.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
        <CustomPopUp
          showVal={popUpFlag}
          headerText={popUpHeader}
          bodyElement={popUpBody}
          handleClose={() => setPopUpFlag(false)}
        />
        <CustomPopUp
          showVal={regStatusPopUpFlag}
          headerText={regStatusHeader}
          bodyElement={regStatusPopUpBody}
          handleClose={() => setRegStatusPopUpFlag(false)}
        />
      </Paper>
    </div>
  );
};

export default NodeTeleDevicesTable;
