import { Route, Switch } from "react-router-dom";
import { CertificationHistTab } from "../components/overview/node/NodeCertificationHistoryTab";
import ConfigConst from "../constants/ConfigConst";
import AccountPage from "../screens/account-page/AccountPage";
import CableModemView from "../screens/cable-modem/CableModemView";
import CMTSLevel2Page from "../screens/cmts-level2-page/CMTSLevel2Page";
import DashbordPage from "../screens/dashbord-page/DashbordPage";
import { EmtaMainPage } from "../screens/emta-page/EmtaMainPage";
import FileManager from "../screens/file-manager-page/FileManager";
import HistoryPage from "../screens/history-page/HistoryPage";
import { NeighbourhoodPage } from "../screens/neighbourhood-page/MainPage";
import PonTabs from "../screens/node-page/PonTabs";
import { MultiDeviceNeighbourhoodPage } from "../screens/neighbourhood-page/MultiDeviceNeighbourhoodPage";
import { MultiDeviceAddressPage } from "../screens/node-page/MultiDeviceAddressPage";
import { MultiDeviceNodePage } from "../screens/node-page/MultiDeviceNodePage";
import NodeTabs from "../screens/node-page/NodeTabs";
import CMTSPage from "../screens/node-page/cmts-page/CMTSMainPage";
import MultiDeviceSearchAccPage from "../screens/ping-page/MultiDeviceSearchAccPage";
import MultiDeviceSearchONTPage from "../screens/ping-page/MultiDeviceSearchONTPage";
import MultiDeviceSearchMACPage from "../screens/ping-page/MultiDeviceSearchPageMAC";
import PingPage from "../screens/ping-page/PingPage";
import TraceRoutePage from "../screens/ping-page/TraceRoutePage";
import RfCalculator from "../screens/rf-calculator-page/RfCalculator";
import {PONONTPage} from "../screens/gpon-page/PONONTPage";
import {PONOltMainPage} from "../screens/gpon-page/PONOltMainPage";
import RfMaintenanceCalculator from "../screens/rf-calculator-page/RfMaintenanceCalculator";
import RPDPage from "../screens/rpd-page/RPDPage";
import VccapPage from "../screens/rpd-page/VccapPage";
import STBPage from "../screens/stb-page/STBPage";
import UtilityPage from "../screens/utilitytool/UtilityPage";
import ExpandedCustomTable from "../topology/components/reusable/newTab/ExpandedCustomTable";
import ExpandedTable from "../topology/components/reusable/newTab/ExpandedTable";
import appConst from "../topology/constants/appConst";
import FiberTableScreen from "../topology/screens/FiberTableScreen";
import ImageEditorScreen from "../topology/screens/ImageEditorScreen";
import MDUMapScreen from "../topology/screens/MDUMapScreen";
import RfTraceLogicalView from "../topology/screens/RfTraceLogicalView";
import SingleRedline from "../topology/screens/SingleRedline";
import TopoStandaloneScreen from "../topology/screens/TopoStandaloneScreen";

// below paths const can be used when calling these routes, no need to create separate constant for each route anymore.
export const paths = {
  dashboard: "/",
  account: "/" + ConfigConst.account,
  node: "/node",
  multiDeviceNode: "/multiDevice/node",
  multiDeviceAddress: "/multiDeviceByAddress",
  multiDeviceNeighbourhood: "/multiDevice/neighbourhood",
  nodeEMTA: "/node/emta",
  neighbourhood: (id: string) => `/node/neighbourhood/${id}`,
  certHistory: "/certification-history",
  nodeSTB: "/node/stb",
  ping: "/ping/ipAddress",
  traceRoute: "/traceRoute",
  multiDeviceMAC: "/searchByMacAddress",
  multiDeviceAcc: "/searchByAccountNumber",
  multiDeviceONT: "/searchByONTSN",
  // ponOlt:(oltName: string, ponPort:String, ponNode:String) => `/ponOltName=${oltName}&ponPort=${ponPort}&ponNode=${ponNode}`,
  ponOlt:"/pon",
  oltPage: (oltName: string) => `/ponOlt=${oltName}`,
  nodeECM: "/node/ecm",
  nodeCMTS: "/node/cmts",
  CMTSLevel2: "/cmts/level2",
  utilTools: "/util/tool",
  CM: "/" + ConfigConst.cableModem,
  topology: "/" + ConfigConst.topology,
  history: "/" + ConfigConst.history,
  topoMDU: "/" + ConfigConst.topology + "/mdu",
  rpd: "/" + ConfigConst.rpd,
  vccap: "/" + ConfigConst.vccap,
  fileManager: "/" + ConfigConst.fileManager,
  rfCalculator: "/" + ConfigConst.rfCalculator,
  rfMaintenanceCalculator: "/" + ConfigConst.rfMaintenanceCalculator,
  expandedTable: "/" + appConst.expandedTable,
  customExpandedTable: "/" + appConst.expandedCustomTable,
  imageEditor: "/image-editor",
  rfLogicalView: "/rf-logical-view",
  redline: (trackerKey: string) => `/redline/${trackerKey}`,
  fiberTableView: "/fiber-table-view",
};

export default function Routes() {
  return (
    <Switch>
      <Route
        path={paths.dashboard}
        exact
        render={(props) => <DashbordPage {...props} />}
      />
      <Route
        path={paths.account}
        exact
        render={(props) => <AccountPage {...props} />}
      />
      <Route
        path={paths.node}
        exact
        render={(props) => <NodeTabs {...props} />}
      />
      <Route
        path={paths.ponOlt}
        exact
        render={(props) => <PonTabs {...props} />}
      />
      <Route
        path={paths.multiDeviceNode}
        exact
        render={(props) => <MultiDeviceNodePage {...props} />}
      />
      <Route
        path={paths.multiDeviceAddress}
        exact
        render={(props) => <MultiDeviceAddressPage {...props} />}
      />
      <Route
        path={paths.multiDeviceNeighbourhood}
        exact
        render={(props) => <MultiDeviceNeighbourhoodPage {...props} />}
      />
      <Route
        path={paths.nodeEMTA}
        exact
        render={(props) => <EmtaMainPage {...props} />}
      />
       {/* <Route
        path={paths.ponOlt(":oltName",":ponPort",":ponNode")}
        exact
        render={(props) => <PONONTPage {...props} />}
      /> */}
      <Route
        path={paths.ponOlt}
        render={(props) => <PONONTPage {...props} />}
      />
        <Route
        path={paths.oltPage(":oltName")}
        exact
        render={(props) => <PONOltMainPage {...props} />}
        />
      <Route
        path={paths.ping}
        exact
        render={(props) => <PingPage {...props} />}
      />
      <Route
        path={paths.traceRoute}
        exact
        render={(props) => <TraceRoutePage {...props} />}
      />
      <Route
        path={paths.multiDeviceMAC}
        exact
        render={(props) => <MultiDeviceSearchMACPage {...props} />}
      />
      <Route
        path={paths.multiDeviceAcc}
        exact
        render={(props) => <MultiDeviceSearchAccPage {...props} />}
      />
      <Route
        path={paths.multiDeviceONT}
        exact
        render={(props) => <MultiDeviceSearchONTPage {...props} />}
      />
      <Route
        path={paths.neighbourhood(":id")}
        exact
        render={(props) => <NeighbourhoodPage {...props} />}
      />
      <Route
        path={paths.certHistory}
        exact
        // render={(props) => <OdmCertificationHistTab {...props} />}
        render={(props) => <CertificationHistTab {...props} />}
      />
      <Route
        path={paths.nodeSTB}
        exact
        render={(props) => <STBPage {...props} />}
      />
      <Route
        path={paths.nodeECM}
        exact
        render={(props) => <STBPage {...props} />}
      />
      <Route
        path={paths.nodeCMTS}
        exact
        render={(props) => <CMTSPage {...props} />}
      />
      <Route
        path={paths.CMTSLevel2}
        exact
        render={(props) => <CMTSLevel2Page {...props} />}
      />
      <Route
        path={paths.utilTools}
        exact
        render={(props) => <UtilityPage {...props} />}
      />
      <Route
        path={paths.CM}
        exact
        render={(props) => <CableModemView {...props} />}
      />
      <Route
        path={paths.topology}
        exact
        render={(props) => <TopoStandaloneScreen {...props} />}
      />
      <Route
        path={paths.history}
        exact
        render={(props) => <HistoryPage {...props} />}
      />
      <Route
        path={paths.topoMDU}
        exact
        render={(props) => <MDUMapScreen {...props} />}
      />
      <Route
        path={paths.rpd}
        exact
        render={(props) => <RPDPage {...props} />}
      />
      <Route
        path={paths.vccap}
        exact
        render={(props) => <VccapPage {...props} />}
      />
      <Route
        path={paths.fileManager}
        exact
        render={(props) => <FileManager {...props} />}
      />
      <Route
        path={paths.rfCalculator}
        exact
        render={(props) => <RfCalculator {...props} />}
      />
      <Route
        path={paths.rfMaintenanceCalculator}
        exact
        render={(props) => <RfMaintenanceCalculator {...props} />}
      />
      <Route
        path={paths.expandedTable}
        exact
        render={(props) => <ExpandedTable {...props} />}
      />
      <Route
        path={paths.customExpandedTable}
        exact
        render={(props) => <ExpandedCustomTable {...props} />}
      />
      <Route
        path={paths.imageEditor}
        exact
        render={(props) => <ImageEditorScreen {...props} />}
      />
      <Route
        path={paths.rfLogicalView}
        exact
        render={(props) => <RfTraceLogicalView {...props} />}
      />
      <Route
        path={paths.redline(":trackerKey")}
        exact
        render={(props) => <SingleRedline {...props} />}
      />
      <Route
        path={paths.fiberTableView}
        exact
        render={(props) => <FiberTableScreen {...props} />}
      />
    </Switch>
  );
}
