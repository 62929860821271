import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { NetworkConfig } from "../NetworkConfig";
import { TapInfoState } from "../../../store/states/account/neighbourhood/TapInfoState";
import {
  TapInfoBegin,
  TapInfoFailure,
  TapInfoSuccess,
} from "../../../store/actions/account/neighbourhood/TapInfoAction";
import { TapListState } from "../../../store/states/account/neighbourhood/TapListState";
import {
  TapListBegin,
  TapListFailure,
  TapListSuccess,
} from "../../../store/actions/account/neighbourhood/TapListAction";

export default class TapClient {
  static getTapsByMacList =
    (
      macList: string
    ): ThunkAction<void, TapListState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "topology/taps",
        params: { macList: macList },
      };
      dispatch(TapListBegin());
      HttpClient.get<any>(httpParameters)
        .then((TapList) => {
          dispatch(TapListSuccess(TapList));
          return TapList;
        })
        .catch((error) => {
          dispatch(TapListFailure(error));
          return error;
        });
    };
  static getMACsByTapName =
    (
      tapName: string
    ): ThunkAction<void, TapInfoState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "topology/taps/" + tapName,
      };
      dispatch(TapInfoBegin());
      HttpClient.get<any>(httpParameters)
        .then((TapInfo) => {
          dispatch(TapInfoSuccess(TapInfo));
          return TapInfo;
        })
        .catch((error) => {
          dispatch(TapInfoFailure(error));
          return error;
        });
    };
}
