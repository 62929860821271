import { GWDeviceSummaryState } from "../../../../states/account/diagnostics/cm/GWDeviceSummaryState";

import {
  GW_DEVICES_SUMMARY_BEGIN,
  GW_DEVICES_SUMMARY_SUCCESS,
  GW_DEVICES_SUMMARY_FAILURE,
  GWDeviceSummaryActionTypes,
} from "../../../../types/account/diagnostics/cm/GWDeviceSummary";

const initialState: GWDeviceSummaryState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function GWDeviceSummaryReducer(
  state = initialState,
  action: GWDeviceSummaryActionTypes
): GWDeviceSummaryState {
  switch (action.type) {
    case GW_DEVICES_SUMMARY_BEGIN:
      return {
        isLoading: true,
      };
    case GW_DEVICES_SUMMARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case GW_DEVICES_SUMMARY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}
