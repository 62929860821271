import {
  BAN_SEARCH_BEGIN,
  BAN_SEARCH_SUCCESS,
  BAN_SEARCH_FAILURE,
  BanActionTypes,
} from "../../../types/account/accountInfo/ProfileManagement";
import { BanState } from "../../../states/account/accountInfo/ProfileManagement";
import {
  LOGIN_FIX_BEGIN,
  LOGIN_FIX_SUCCESS,
  LOGIN_FIX_FAILURE,
  LoginFixActionTypes,
} from "../../../types/account/accountInfo/ProfileManagement";
import { LoginFixState } from "../../../states/account/accountInfo/ProfileManagement";
import {
  LINK_BAN_BEGIN,
  LINK_BAN_SUCCESS,
  LINK_BAN_FAILURE,
  LinkBanActionTypes,
} from "../../../types/account/accountInfo/ProfileManagement";
import { LinkBanState } from "../../../states/account/accountInfo/ProfileManagement";
import {
  UNLINK_BAN_BEGIN,
  UNLINK_BAN_SUCCESS,
  UNLINK_BAN_FAILURE,
  UnlinkBanActionTypes,
} from "../../../types/account/accountInfo/ProfileManagement";
import { UnlinkBanState } from "../../../states/account/accountInfo/ProfileManagement";

const initialBanState: BanState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

const initialLoginFixState: LoginFixState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

const initialLinkBanState: LinkBanState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

const initialUnlinkBanState: UnlinkBanState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function BanSearchReducer(
  state = initialBanState,
  action: BanActionTypes
): BanState {
  switch (action.type) {
    case BAN_SEARCH_BEGIN:
      return {
        isLoading: true,
      };
    case BAN_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case BAN_SEARCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}

export function LoginFixReducer(
  state = initialLoginFixState,
  action: LoginFixActionTypes
): LoginFixState {
  switch (action.type) {
    case LOGIN_FIX_BEGIN:
      return {
        isLoading: true,
      };
    case LOGIN_FIX_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: "OK",
      };
    case LOGIN_FIX_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}

export function LinkBanReducer(
  state = initialLinkBanState,
  action: LinkBanActionTypes
): LinkBanState {
  switch (action.type) {
    case LINK_BAN_BEGIN:
      return {
        isLoading: true,
      };
    case LINK_BAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: "OK",
      };
    case LINK_BAN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}

export function UnlinkBanReducer(
  state = initialUnlinkBanState,
  action: UnlinkBanActionTypes
): UnlinkBanState {
  switch (action.type) {
    case UNLINK_BAN_BEGIN:
      return {
        isLoading: true,
      };
    case UNLINK_BAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: "OK",
      };
    case UNLINK_BAN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}
