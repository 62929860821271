import { CSSProperties } from "react";
import { HiOutlineEmojiSad } from "react-icons/hi";

import ActivityIndicator from "../../../components/reusable/ActivityIndicator";
import TopologyLabels from "../../constants/topologyLabels";

interface Props {
  isLoading?: boolean;
  /**API status */
  status: "OK" | "ERROR" | "";
  /**API error */
  error?: any;
  /**API data if you want empty data to be validated */
  data?: any;
  children?: JSX.Element | JSX.Element[];
  headerMsg?: string; //custom label
  /**custom message */
  bodyMsg?: string;
  /**section name which API failed for */
  sectionName?: string;
  /**refresh function if API fails */
  onRefresh?: () => void;
  /**exception handler container */
  EHContStyle?: CSSProperties;
  /**component to show with failed message */
  compWithFail?: JSX.Element | JSX.Element[];
  /**failer container */
  failContStyle?: CSSProperties;
}

export default function ExceptionHandlerWrapper({
  isLoading,
  status,
  error,
  data,
  children,
  headerMsg,
  bodyMsg,
  sectionName,
  onRefresh,
  EHContStyle,
  compWithFail,
  failContStyle,
}: Props) {
  const labels = TopologyLabels();
  const isNoData = data === "" || data?.length === 0;

  const getMsg = () => {
    let labelMsg;
    let msgDetails;

    if (headerMsg) {
      labelMsg = headerMsg;
    } else if (sectionName) {
      labelMsg = `${labels.unableToPullMsg}(${sectionName})${labels.sectionMsg}`;
    } else if (error) labelMsg = labels.somethingWrongMsg;

    if (msgDetails) {
      msgDetails = bodyMsg;
    } else if (error) {
      msgDetails = `${labels.dueToTheReasonMsg} - ${labels.ErrorCode}: ${error?.errorCode} - “${error?.errorMessage}” ${labels.from} ${error?.sourceSystem}. ${labels.tryRefreshMsg}`;
    } else if (isNoData) {
      msgDetails = labels.noDataMsg;
    } else msgDetails = labels.refreshMsg;

    return { labelMsg, msgDetails };
  };

  if (isLoading)
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ActivityIndicator animationAlignement="none" />
      </div>
    );

  return (
    <>
      {status === "ERROR" || isNoData ? (
        <div style={failContStyle}>
          <div className="exceptionHandlerWrapperCont" style={EHContStyle}>
            <div
              className="errorIconCont"
              style={{
                backgroundColor: isNoData ? `var(--warning)` : `var(--danger)`,
              }}
            >
              <HiOutlineEmojiSad />
            </div>
            <div className="messageCont">
              {getMsg()?.labelMsg && <label>{getMsg()?.labelMsg}</label>}
              <p>{getMsg()?.msgDetails}</p>
              {onRefresh && (
                <button onClick={onRefresh}>
                  {labels.Refresh.toUpperCase()}
                </button>
              )}
            </div>
          </div>
          {compWithFail && compWithFail}
        </div>
      ) : (
        children
      )}
    </>
  );
}
