import { ActiveNodeInfoState, ActiveNodePage } from "../../../states/node/nodeinfo/ActiveNodeInfoState";

import {
  SET_ACTIVE_NODE_INFO_DETAILS,
  RESET_ACTIVE_NODE_INFO,
  SET_ACTIVE_NODE_SUB_TAB_INDEX,
  ActiveNodeInfoActionTypes,
  SET_ACTIVE_NODE_PAGE,
} from "../../../types/node/nodeinfo/ActiveNodeInfoDetails";


const initialState: ActiveNodeInfoState = {
  data: undefined,
  activeSubTabIndex: 0,
  activeNodePage: ActiveNodePage.NODE
};
const ActiveNodeInfoReducer = (
  state = initialState,
  action: ActiveNodeInfoActionTypes
): ActiveNodeInfoState => {
  switch (action.type) {
    case SET_ACTIVE_NODE_INFO_DETAILS:
      return {
        ...state,
        data: action.payload,
      };
    case RESET_ACTIVE_NODE_INFO:
      return {
        ...state,
        data: undefined,
      };
    case SET_ACTIVE_NODE_SUB_TAB_INDEX:
      return {
        ...state,
        activeSubTabIndex: action.payload,
      };
    case SET_ACTIVE_NODE_PAGE:
      return {
        ...state,
        activeNodePage: action.payload,
      };

    default:
      return state;
  }
};
export default ActiveNodeInfoReducer;
