import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../middleware/apiActions";
import {
  MDU_RASTER,
  SYMBOLS_SERVICE_URL,
} from "../../../middleware/apiEndpoints";
import storeConst from "../../../storeConst";
import { decrApiCall, incrApiCall } from "../mapDetails";

const slice = createSlice({
  name: "mduRaster",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    getMduRasterBegin: (mduRaster) => {
      mduRaster.isLoading = true;
      mduRaster.status = "";
    },
    getMduRasterSuccess: (mduRaster, action) => {
      mduRaster.isLoading = false;
      mduRaster.data = action.payload;
      mduRaster.status = storeConst.OK;
    },
    getMduRasterFailure: (mduRaster, action) => {
      mduRaster.isLoading = false;
      mduRaster.error = action.payload;
      mduRaster.data = undefined;
      mduRaster.status = storeConst.ERROR;
    },
  },
});

const { getMduRasterBegin, getMduRasterSuccess, getMduRasterFailure } =
  slice.actions;
export default slice.reducer;

export const getMduRaster = (owningDrawing: any) => (dispatch: any) => {
  dispatch(
    apiCallBegan({
      url: `${MDU_RASTER}/${owningDrawing}`,
      method: "get",
      onStart: getMduRasterBegin.type,
      onSuccess: getMduRasterSuccess.type,
      onError: getMduRasterFailure.type,
      onStartDispatch: incrApiCall.type,
      onSuccessDispatch: decrApiCall.type,
      onErrorDispatch: decrApiCall.type,
    })
  );
};
