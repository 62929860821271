import { useState } from "react";
import { FormControl, InputGroup, Modal } from "react-bootstrap";
import { Divider } from "@material-ui/core";
import { IoClose } from "react-icons/io5";
import SearchIcon from "@material-ui/icons/Search";
import FullPageLoader from "../reusable/FullPageLoader";
import AppLabels from "../../constants/App_Labels";
import { externalLinksConfig } from "../../config/SearchConfig";
import { BsBoxArrowUpRight } from "react-icons/bs";

const ExternalLinksPopUp = (props: any) => {
  const labels = AppLabels();
  const [searchValue, setSearchValue] = useState("");
  const [extLinksList, setExtLinksList] = useState<any>(externalLinksConfig);

  const onItemPress = (url: string) => {
    //const url = `${url}`;
    let newWindow = window.open(url, "_blank")!;
    newWindow.onload = () => {
      newWindow.document.title = `External Links`;
    };
  };

  function filterExtLinkOfObjects(value: string, data: any, filterKeys: any) {
    return filterKeys

      ?.map((e: any) =>
        data?.filter((data: any) =>
          data?.[e]?.toUpperCase()?.includes(value?.toUpperCase())
        )
      )

      .filter((f: any) => f?.length > 0)?.[0];
  }

  const handleWOSearch = (e: any) => {
    setSearchValue(e.target.value);
    if (externalLinksConfig) {
      let linkList = filterExtLinkOfObjects(
        e.target.value,
        externalLinksConfig,
        ["label"]
      );
      setExtLinksList(linkList);
    }
  };

  return (
    <>
      <Modal
        {...props}
        id="searchExtLinkPopUp"
        backdrop="static"
        keyboard={false}
        centered
        size="xl"
      >
        <Modal.Header className="searchPopHead">
          <header>{labels.EXTERNAL_LINK}</header>
          <button className="sPopUpClose" onClick={props.onHide}>
            <IoClose color="white" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="workOrderWdt">
            <div className="extLinkSearch">
              <InputGroup className="searchWOIG">
                <button className="searchWOBtn">
                  <SearchIcon />
                </button>
                <FormControl
                  placeholder="Filter external links..."
                  className="searchInput-WO"
                  value={searchValue}
                  onChange={(e) => handleWOSearch(e)}
                />
              </InputGroup>
            </div>
            <Divider orientation="horizontal" />
            <div className="externalLinkCont">
              <section className="extLinkbuttonCont">
                {extLinksList?.map((v: any, i: number) => (
                  <>
                    {v?.label === "ELECTROLINE" ? (
                      <button key={i * 32 * 7} className="extLinkbutton">
                        <a
                          key={i * 32 * 7}
                          href={`microsoft-edge:${v?.url}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="headerExternalLink">
                            <BsBoxArrowUpRight size={16} />
                          </span>
                          <span className="linkButtonTitle">{v?.label}</span>
                        </a>
                      </button>
                    ) : (
                      <button
                        key={i * 32 * 7}
                        className="extLinkbutton"
                        onClick={() => onItemPress(v?.url)}
                      >
                        <span className="headerExternalLink">
                          <BsBoxArrowUpRight size={16} />
                        </span>
                        <span className="linkButtonTitle">{v?.label}</span>
                      </button>
                    )}{" "}
                  </>
                ))}
              </section>
            </div>
          </div>
        </Modal.Body>
        <Divider orientation="horizontal" />
        <Modal.Footer>
          <button className="sPopCloseBtn" onClick={props.onHide}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <FullPageLoader />
    </>
  );
};

export default ExternalLinksPopUp;