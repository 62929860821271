import { NodeSummary } from "../../../models/node/nodeinfo/NodeSummary";

export const NODE_INFO_DETAILS_BEGIN = "NODE_INFO_DETAILS_BEGIN";
export const NODE_INFO_DETAILS_SUCCESS = "NODE_INFO_DETAILS_SUCCESS";
export const NODE_INFO_DETAILS_FAILURE = "NODE_INFO_DETAILS_FAILURE";
export const NODE_INFO_RESET_STATUS = "NODE_INFO_RESET_STATUS";
export const RESET_NODE_INFO_STATE = "RESET_NODE_INFO_STATE";
export const SET_NODE_SEARCH = "SET_NODE_SEARCH";

interface NodeInfoDetailsBegin {
  type: typeof NODE_INFO_DETAILS_BEGIN;
}
interface NodeInfoDetailsSuccess {
  type: typeof NODE_INFO_DETAILS_SUCCESS;
  payload: NodeSummary[];
}
interface NodeInfoDetailsFailure {
  type: typeof NODE_INFO_DETAILS_FAILURE;
  payload: Error;
}
interface NodeInfoResetStatus {
  type: typeof NODE_INFO_RESET_STATUS;
}
interface SetNodeSearch {
  type: typeof SET_NODE_SEARCH;
  payload: String;
}
interface ResetNodeInfoState {
  type: typeof RESET_NODE_INFO_STATE;
}

export type NodeInfoDetailsActionTypes =
  | NodeInfoDetailsBegin
  | NodeInfoDetailsSuccess
  | NodeInfoDetailsFailure
  | NodeInfoResetStatus
  | SetNodeSearch
  | ResetNodeInfoState;
