import "date-fns";
import { format, addDays } from "date-fns";
import { Form, DropdownButton, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useEffect, useState } from "react";
import { Card } from "@material-ui/core";
import ErrorBox from "../../reusable/ErrorBox";
import appLabels from "../../../constants/App_Labels";
import {
  setMacAddress,
  rpdSwapReset,
} from "../../../store/ducksPattern/rpd/rpdSwap";
import {
  formatMacAddress,
  isMacAddressValid,
  formatRPDMacAddress,
  formatNPTNCTIMT,
  isRPDMacAddressValid,
} from "../../../components/reusable/Util";
import RPDClient from "../../../network/httpclient/rpd/RPDClient";
import { TAF_ORDER_CONST } from "../../../constants/Global";
import EmailEntry from "../../reusable/EmailEntry";
import ChipGroup from "../../reusable/ChipGroup";

const RPDSwapTop = (props: any) => {
  const { oldMacAdd, techData, isLoading } = props;
  const labels = appLabels();
  const [oldMacAd, setOldMacAd] = useState(formatMacAddress(oldMacAdd));
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("This is Error Message");
  const [newMacAd, setNewMacAd] = useState("");
  const [nptId, setNPTId] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const oldDeviceList = ["DA2000", "DA2200"];
  const [oldDevice, setOldDevice] = useState("DA2000");
  const newDeviceList = ["DA2000", "DA2200"];
  const [newDevice, setNewDevice] = useState("DA2000");
  const qamProfileList = ["1.2GHz", "1.2GHz"];
  const [qamProfile, setQAMProfile] = useState("1.2GHz");
  const technologyList = ["FTTC", "FTTF"];
  const [technologyDevice, setTechnologyDevice] = useState("FTTC");
  const [emails, setEmails] = useState([
    techData?.lanId !== null ? techData?.lanId : "",
  ]);
  const [activeLobbyCameraCHipIndex, setActiveLobbyCameraCHipIndex] =
    useState(0);
  const [activeMdTypeCHipIndex, setActiveMdTypeCHipIndex] = useState(0);
  const mdTypeChips = [
    { title: labels.rpdMDType1, description: labels.rpdMDType1 },
    { title: labels.rpdMDType2, description: labels.rpdMDType2 },
  ];
  const lobbyCameraTypeChips = [
    { title: labels.rpdLobbyCamera1, description: labels.rpdLobbyCamera1 },
    { title: labels.rpdLobbyCamera2, description: labels.rpdLobbyCamera2 },
  ];
  const dispatch = useDispatch();

  const onNptIdSubmit = (e: any) => {
    dispatch(rpdSwapReset());
    let sv = formatNPTNCTIMT(e.target.value);
    setNPTId(sv);
  };

  const onOldMacAdSubmit = (e: any) => {
    dispatch(rpdSwapReset());
    let sv = formatMacAddress(e.target.value);
    if (sv?.length <= 12) setOldMacAd(sv);
  };

  const createInputSwapObject = (
    oldMac: string,
    newMac: string,
    nptId: string,
    emails: string
  ): any => {
    let inputObject = {
      Input_1: oldMac,
      Input_2: newMac,
      RelatedID: nptId,
      MAIL_ID: emails,
    };
    return inputObject;
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleApplySwap = (oldMacAd: string) => {
    if (isRPDMacAddressValid(oldMacAd)) {
      let stringMacAdd = formatRPDMacAddress(oldMacAd);
      dispatch(setMacAddress(stringMacAdd));

      if (isRPDMacAddressValid(newMacAd)) {
        if (nptId?.length > 0) {
          if (emails[0] !== "") {
            if (oldDevice === newDevice)
              handleSwap(stringMacAdd, newMacAd, nptId);
            else handleCrossSwap(stringMacAdd, newMacAd, nptId);
          } else {
            setOpen(true);
            setError(labels.emailEmptyError);
          }
        } else {
          setOpen(true);
          setError(labels.nptIdImtIdEmptyError);
        }
      } else {
        setOpen(true);
        setError(labels.newMacAddressEmptyError);
      }
    } else {
      setOpen(true);
      setError(labels.oldMacAddressEmptyError);
    }
  };

  const handleSwap = (oldMacAdd: string, newMacAd: string, nptId: string) => {
    if (newMacAd?.length === 12) {
      if (nptId?.length > 0) {
        let stringMacAdd = formatRPDMacAddress(newMacAd);
        // dispatch(
        //   RPDClient.postSwap(
        //     TAF_ORDER_CONST.rpdSwapBotName,
        //     TAF_ORDER_CONST.rpdSwapSuiteName,
        //     createInputSwapObject(
        //       oldMacAdd,
        //       stringMacAdd,
        //       nptId,
        //       emails.toString()
        //     )
        //   )
        // );
        dispatch(
          RPDClient.createSwap(
            TAF_ORDER_CONST.rpdSwapBotName,
            TAF_ORDER_CONST.rpdSwapSuiteName,
            createInputSwapObject(
              oldMacAdd,
              stringMacAdd,
              nptId,
              emails.toString()
            )
          )
        );
        dispatch(setMacAddress(undefined));
      } else {
        setOpen(true);
        setError(labels.nptIdImtIdEmptyError);
      }
    } else {
      setOpen(true);
      setError(labels.newMacAddressEmptyError);
    }
  };

  const handleCrossSwap = (
    oldMacAdd: string,
    newMacAd: string,
    nptId: string
  ) => {
    if (newMacAd?.length === 12) {
      if (nptId?.length > 0) {
        let stringMacAdd = formatRPDMacAddress(newMacAd);

        dispatch(
          RPDClient.createSwap(
            TAF_ORDER_CONST.rpdSwapBotName,
            TAF_ORDER_CONST.rpdSwapSuiteName,
            createInputSwapObject(
              oldMacAdd,
              stringMacAdd,
              nptId,
              emails.toString()
            )
          )
        );
        dispatch(setMacAddress(undefined));
      } else {
        setOpen(true);
        setError(labels.nptIdImtIdEmptyError);
      }
    } else {
      setOpen(true);
      setError(labels.newMacAddressEmptyError);
    }
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleApplySwap(oldMacAd);
    }
  };

  const onNewMacAdSubmit = (e: any) => {
    dispatch(rpdSwapReset());
    let sv = formatMacAddress(e.target.value);
    if (sv?.length <= 12) setNewMacAd(sv);
  };

  const DropdownMenu = ({ label, data, value, onSelect }: any) => (
    <div className="deviceTypeCont">
      <Form.Label className="rpdFormLabel">{label}</Form.Label>
      <DropdownButton
        title={value}
        onSelect={(v: any) => onSelect(v)}
        size="sm"
        className="deviceTypeDropd"
        id="deviceTypeDd"
      >
        {data.map((e: any, i: number) => (
          <Dropdown.Item key={i} eventKey={e}>
            {e}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );

  return (
    <>
      <Card className="filterRootContainer">
        <div className="filterContainer">
          <div className="filterRow">
            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.oldRPDMacAddress}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="rpdInputField"
                  onChange={onOldMacAdSubmit}
                  className="rpdInputField"
                  id="rpdInputField"
                  value={oldMacAd}
                  placeholder={labels.typeOldRPDMacAddress}
                />
              </Form.Group>
            </div>
            <div className="inputContainer">
              <Form.Group>
                <DropdownMenu
                  label={labels.deviceType}
                  data={oldDeviceList}
                  value={oldDevice}
                  onSelect={setOldDevice}
                />
              </Form.Group>
            </div>

            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.nptIdimtId}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="rpdInputField"
                  onChange={onNptIdSubmit}
                  className="rpdInputField"
                  id="rpdInputField"
                  value={nptId}
                  onKeyPress={(e: any) => handleKeyPress(e)}
                  placeholder={labels.typeNptIDImtID}
                />
              </Form.Group>
            </div>
          </div>
          <div className="filterRow">
            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.newRPDMacAddress}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="rpdInputField"
                  onChange={onNewMacAdSubmit}
                  className="rpdInputField"
                  id="rpdInputField"
                  value={newMacAd}
                  placeholder={labels.typeNewRPDMacAddress}
                />
              </Form.Group>
            </div>
            <div className="inputDeviceContainer">
              <Form.Group>
                <DropdownMenu
                  label={labels.deviceType}
                  data={newDeviceList}
                  value={newDevice}
                  onSelect={setNewDevice}
                />
              </Form.Group>
            </div>
          </div>
          {oldDevice !== newDevice && newDevice === "DA2200" && (
            <div className="filterRow">
              <div className="inputContainer">
                <div className="boxTitleContainer">
                  <span>{labels.rpdLobbyCamera}</span>
                </div>
                <div className="boxTitleContainer">
                  <ChipGroup
                    chips={lobbyCameraTypeChips}
                    activeChipIndex={activeLobbyCameraCHipIndex}
                    onIndexChange={setActiveLobbyCameraCHipIndex}
                  />
                </div>
              </div>
              <div className="inputContainer">
                <div className="boxTitleContainer">
                  <span>{labels.rpdMDType}</span>
                </div>
                <div className="boxTitleContainer">
                  <ChipGroup
                    chips={mdTypeChips}
                    activeChipIndex={activeMdTypeCHipIndex}
                    onIndexChange={setActiveMdTypeCHipIndex}
                  />
                </div>
              </div>

              <div className="inputContainer">
                <Form.Group>
                  <DropdownMenu
                    label="QAM Profile"
                    data={qamProfileList}
                    value={qamProfile}
                    onSelect={setQAMProfile}
                  />
                </Form.Group>
              </div>

              <div className="inputContainer">
                <Form.Group>
                  <DropdownMenu
                    label="Technology"
                    data={technologyList}
                    value={technologyDevice}
                    onSelect={setTechnologyDevice}
                  />
                </Form.Group>
              </div>
            </div>
          )}
          {oldDevice !== newDevice && newDevice === "DA2000" && (
            <div className="filterRow">
              <div className="inputContainer">
                <div className="boxTitleContainer">
                  <span>{labels.rpdLobbyCamera}</span>
                </div>
                <div className="boxTitleContainer">
                  <ChipGroup
                    chips={lobbyCameraTypeChips}
                    activeChipIndex={activeLobbyCameraCHipIndex}
                    onIndexChange={setActiveLobbyCameraCHipIndex}
                  />
                </div>
              </div>
              <div className="inputContainer">
                <div className="boxTitleContainer">
                  <span>{labels.rpdMDType}</span>
                </div>
                <div className="boxTitleContainer">
                  <ChipGroup
                    chips={mdTypeChips}
                    activeChipIndex={activeMdTypeCHipIndex}
                    onIndexChange={setActiveMdTypeCHipIndex}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="filterRow">
            <EmailEntry
              title={labels.rpdEmail}
              email={emails}
              setEmail={setEmails}
              setIsEmailValid={setIsEmailValid}
              titleStyle={{
                fontSize: "12px",
                fontWeight: 500,
                margin: "0px",
                color: "#000",
              }}
              buttonStyle={{
                fontSize: "12px",
                background: "none",
                border: "none",
                padding: "0px",
                color: "#da291c",
              }}
              emailInputStyle={{
                fontSize: "12px",
                color: "#000",
                paddingLeft: "5px;",
                fontWeight: 500,
                borderRadius: "5px",
                border: "1px solid #808284",
              }}
            />
          </div>
          <div className="filterRow">
            <button
              disabled={isLoading}
              className="applyFilersBtn"
              onClick={() => handleApplySwap(oldMacAd)}
            >
              {labels.SUBMIT}
            </button>
          </div>
        </div>
      </Card>
      <ErrorBox
        open={open}
        error={error}
        setOpen={setOpen}
        handleClose={handleClose}
      />
    </>
  );
};
export default RPDSwapTop;
