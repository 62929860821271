export const WORKORDERS_BEGIN = "WORKORDERS_BEGIN";
export const WORKORDERS_SUCCESS = "WORKORDERS_SUCCESS";
export const WORKORDERS_FAILURE = "WORKORDERS_FAILURE";

interface WorkOrdersBegin {
  type: typeof WORKORDERS_BEGIN;
}

interface WorkOrdersSuccess {
  type: typeof WORKORDERS_SUCCESS;
  payload: any;
}

interface WorkOrdersFailure {
  type: typeof WORKORDERS_FAILURE;
  payload: Error;
}

export type WorkOrderActionTypes =
  | WorkOrdersBegin
  | WorkOrdersSuccess
  | WorkOrdersFailure;
