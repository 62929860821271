export default function AspSerialNumberData() {
    const data = {
      stbSerialNumbers: [
        {
          key1: "quantitytextbox1",
          key2: "quantitytextbox2",
          isSnValid: false,
          lastVerifiedSn: "",
        },
        {
          key1: "quantitytextbox3",
          key2: "quantitytextbox4",
          isSnValid: false,
          lastVerifiedSn: "",
        },
        {
          key1: "quantitytextbox5",
          key2: "quantitytextbox6",
          isSnValid: false,
          lastVerifiedSn: "",
        },
        {
          key1: "quantitytextbox7",
          key2: "quantitytextbox8",
          isSnValid: false,
          lastVerifiedSn: "",
        },
        {
          key1: "quantitytextbox25",
          key2: "quantitytextbox26",
          isSnValid: false,
          lastVerifiedSn: "",
        },
        {
          key1: "quantitytextbox27",
          key2: "quantitytextbox28",
          isSnValid: false,
          lastVerifiedSn: "",
        },
        {
          key1: "quantitytextbox29",
          key2: "quantitytextbox30",
          isSnValid: false,
          lastVerifiedSn: '',
        },
        {
          key1: "quantitytextbox31",
          key2: "quantitytextbox32",
          isSnValid: false,
          lastVerifiedSn: "",
        },
        {
          key1: "quantitytextbox33",
          key2: "quantitytextbox34",
          isSnValid: false,
          lastVerifiedSn: "",
        },
        {
          key1: "quantitytextbox35",
          key2: "quantitytextbox36",
          isSnValid: false,
          lastVerifiedSn: "",
        },
      ],
      freePodSerialNumbers: [
        {
          key1: "quantitytextbox9",
          key2: "quantitytextbox10",
          isSnValid: false,
          lastVerifiedSn: "",
        },
        {
          key1: "quantitytextbox11",
          key2: "quantitytextbox12",
          isSnValid: false,
          lastVerifiedSn: "",
        },
        {
          key1: "quantitytextbox13",
          key2: "quantitytextbox14",
          isSnValid: false,
          lastVerifiedSn: "",
        },
        {
          key1: "quantitytextbox15",
          key2: "quantitytextbox16",
          isSnValid: false,
          lastVerifiedSn: "",
        },
      ],
      paidPodSerialNumbers: [
        {
          key1: "quantitytextbox17",
          key2: "quantitytextbox18",
          isSnValid: false,
          lastVerifiedSn: "",
        },
        {
          key1: "quantitytextbox19",
          key2: "quantitytextbox20",
          isSnValid: false,
          lastVerifiedSn: "",
        },
        {
          key1: "quantitytextbox21",
          key2: "quantitytextbox22",
          isSnValid: false,
          lastVerifiedSn: "",
        },
        {
          key1: "quantitytextbox23",
          key2: "quantitytextbox24",
          isSnValid: false,
          lastVerifiedSn: '',
        },
      ],
    };
  
    return data;
  }
  