import CustomAccordion from "../../../components/reusable/CustomAccordion";
import GoogleMapDirectionsBtn from "../../../components/reusable/GoogleMapDirectionsBtn";
import appConst from "../../constants/appConst";
import PortsListTable from "./elements/actives/PortsListTable";
import GponPortSelector from "./elements/node/gpon/GponPortSelector";
import GponSplitters from "./elements/node/gpon/GponSplitters";
import GponSpliceMap from "./elements/spliceCase/GponSpliceMap";
import TapAddress from "./elements/tap/TapAddress";

interface Props {
  elementData: any;
}

export default function OtherActiveMapElementComps({ elementData }: Props) {
  return (
    <>
      {elementData?.type === appConst.taps && <TapAddress />}
      {elementData?.type === appConst.activeEquipment && (
        <CustomAccordion
          accTitle={appConst?.powerLevel?.toUpperCase()}
          headerComp={
            <GoogleMapDirectionsBtn
              lat={elementData?.coord?.lat}
              lng={elementData?.coord?.lng}
            />
          }
          accBodyElement={<PortsListTable elementData={elementData} />}
        />
      )}
      {elementData?.type === appConst.spliceCases && (
        <GponSpliceMap elementData={elementData} />
      )}
      {elementData?.type === appConst.LCP && (
        <>
          <GponSplitters elementData={elementData} />
          <GponPortSelector elementData={elementData} />
        </>
      )}
    </>
  );
}
