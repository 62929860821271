import { Card, Divider } from "@material-ui/core";
import AppLabels from "../../../constants/App_Labels";
import "../../../design-tokens/node-page/NodeOverviewTab.scss";
import { GetTechInfo } from "../../../get-api/GetResponse";
import CustomAccordion from "../../reusable/CustomAccordion";
import { isNull } from "../../reusable/Util";
import NodeCertificationHistory from "./NodeCertificationHistory";
// import OdmCertificationHistory from "./OdmCertificationHistory";
const NodeOverView = (props: any) => {
  const { data } = props;
  const labels = AppLabels();
  const { isMaintenanceRoleAvailable } = GetTechInfo();

  const getLegacyCount = () => {
    if (data.hsiCount !== undefined && data.igniteCount !== undefined) {
      return data.hsiCount - data.igniteCount;
    }
  };

  const renderOfdmConfigInfo = () => {
    if (data !== undefined && data?.ofdmProfiles !== undefined) {
      return (
        <span>
          {data?.ofdmProfiles.map((ofdm: any, index: number) => {
            return (
              <span key={index}>
                <b>
                  {labels.OFDM} - {isNull(ofdm?.cmtsChanId)}
                </b>
                <span>
                  {labels.LOWER_FREQ} : {isNull(ofdm?.freqLower)}
                </span>
                <span>
                  {labels.UPPER_FREQ} : {isNull(ofdm?.freqUpper)}
                </span>
                <span>
                  {labels.WIDTH} : {isNull(ofdm?.width)}
                </span>
                <span>
                  {labels.MIN_MOD} :{" "}
                  {isNull(ofdm?.minModulation)?.toUpperCase()}
                </span>
                <span>
                  {labels.MAX_MOD} :{" "}
                  {isNull(ofdm?.maxModulation)?.toUpperCase()}
                </span>
                <br />
              </span>
            );
          })}
        </span>
      );
    }
  };
  const renderArchConfigInfo = () => {
    if (data !== undefined ) {
      return (
        <span>
            <span> {isNull(data?.arch)}</span>
              <span>
                
                  {labels.mgigCapable} : {isNull(data?.mgigCapable)}
               
                <span>
                  {labels.mgigCapacity} : {isNull(data?.mgigCapacity)}
                </span>
                {data?.mgigInServiceDate && (
                <span>
                  {labels.mgigInServiceDate} : {isNull(data?.mgigInServiceDate)}
                </span>)}
                {!data?.mgigInServiceDate && data?.mgigPlannedDate && (
                <span>
                  {labels.mgigPlannedDate} : {isNull(data?.mgigPlannedDate)}
                </span>)}
               
                <br />
              </span>
            
         
        </span>
      );
    }
  };
  const renderOfdmaConfigInfo = () => {
    if (data !== undefined && data?.ofdmaProfiles !== undefined) {
      return (
        <span>
          {data?.ofdmaProfiles.map((ofdma: any, index: number) => {
            if (index > 0) return; //Added this line as a part of 3170 to remove ofdma 2
            return (
              <span key={index}>
                <b>
                  {labels.OFDMA} - {isNull(ofdma?.cmtsChanId)}
                </b>
                <span>
                  {labels.LOWER_FREQ} : {isNull(ofdma?.freqLower)}
                </span>
                <span>
                  {labels.UPPER_FREQ} : {isNull(ofdma?.freqUpper)}
                </span>
                <span>
                  {labels.WIDTH} : {isNull(ofdma?.width)}
                </span>
                <span>
                  {labels.MIN_MOD} :{" "}
                  {isNull(ofdma?.minModulation)?.toUpperCase()}
                </span>
                <span>
                  {labels.MAX_MOD} :{" "}
                  {isNull(ofdma?.maxModulation)?.toUpperCase()}
                </span>
                <br />
              </span>
            );
          })}
        </span>
      );
    }
  };

  return (
    <>
      <div className="nodeOverviewAccordion">
        <section
          className={
            isMaintenanceRoleAvailable
              ? "nodeOverviewInfoMT"
              : "nodeOverviewInfo"
          }
        >
          <CustomAccordion
            accTitle={labels.NODE_OVERVIEW}
            accBodyElement={
              <Card className="custNodeInfoCard">
                <div className="nodeBasicInfo">
                  <div className="nodeDetailsDiv">
                    <header>{labels.HOMES_PASSED}</header>
                    <Divider orientation="horizontal" />
                    <p>
                      {data !== undefined && (
                        <span className="custNodespace">
                          {" "}
                          {isNull(data?.homesPassed)}
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="nodeDetailsDiv">
                    <header>{labels.FULL_MORDEM_COUNT}</header>
                    <Divider orientation="horizontal" />
                    <p>
                      {data !== undefined && (
                        <span className="custNodespace">
                          {" "}
                          {isNull(data?.fullCount)}
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="nodeDetailsDiv">
                    <header>{labels.IGNITE_MODEM_COUNT}</header>
                    <Divider orientation="horizontal" />
                    <p>
                      {data !== undefined && (
                        <span className="custNodespace">
                          {" "}
                          {isNull(data?.igniteCount)}
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="nodeDetailsDiv">
                    <header>{labels.LEGACY_MODEM_COUNT}</header>
                    <Divider orientation="horizontal" />
                    <p>
                      {data !== undefined && (
                        <span className="custNodespace">
                          {" "}
                          {isNull(getLegacyCount())}
                        </span>
                      )}
                    </p>
                  </div>
                </div>
                <header>&nbsp; </header>
                <div className="nodeBasicInfo">
                  <div className="nodeDetailsDiv">
                    <header>{labels.ARCHITECTURE}</header>
                    <Divider orientation="horizontal" />
                    <p>
                      {data !== undefined && renderArchConfigInfo()}
                    </p>
                  </div>
                  <div className="nodeDetailsDiv">
                    <header>{labels.OFDM_CHAN_CONFIG}</header>
                    <Divider orientation="horizontal" />
                    <p>{data !== undefined && renderOfdmConfigInfo()}</p>
                  </div>
                  <div className="nodeDetailsDiv">
                    <header>{labels.OFDMA_CHAN_CONFIG}</header>
                    <Divider orientation="horizontal" />
                    <p>{data !== undefined && renderOfdmaConfigInfo()}</p>
                  </div>
                </div>
              </Card>
            }
            initialOpenState={true}
          ></CustomAccordion>
        </section>
        {isMaintenanceRoleAvailable && (
          <section className="nodeCertificationHistory">
            <NodeCertificationHistory />
            {/* <OdmCertificationHistory /> */}
          </section>
        )}
      </div>
    </>
  );
};

export default NodeOverView;
