import { createSlice } from "@reduxjs/toolkit";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "stbAccountDetails",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    stbAccountDetailsBegin: (stbAccountDetails) => {
      stbAccountDetails.isLoading = true;
    },
    stbAccountDetailsSuccess: (stbAccountDetails, action) => {
      stbAccountDetails.isLoading = false;
      stbAccountDetails.data = action.payload;
      stbAccountDetails.status = storeConst.OK;
    },
    stbAccountDetailsFailure: (stbAccountDetails, action) => {
      stbAccountDetails.isLoading = false;
      stbAccountDetails.error = action.payload;
      stbAccountDetails.data = undefined;
      stbAccountDetails.status = storeConst.ERROR;
    },

    resetStbAccountDetails: (stbAccountDetails) => {
      stbAccountDetails.isLoading = false;
      stbAccountDetails.error = undefined;
      stbAccountDetails.data = undefined;
      stbAccountDetails.status = "";
    },
  },
});

export const {
  stbAccountDetailsBegin,
  stbAccountDetailsSuccess,
  stbAccountDetailsFailure,
  resetStbAccountDetails,
} = slice.actions;

export default slice.reducer;
