import { DATA_TABLE_CONFIG } from "../../constants/DataTableConfig";
import {
  dataConverter,
  formatCamelCase,
  getCheckValue,
  getStatusOfCheck,
  getThreshold,
  isEmptyObject,
  isNull,
  isObject,
  getStatusOfCheckHeader,
} from "./Util";
import CustomPopOver from "./CustomPopOver";
const DataDisplayTable = (props: any) => {
  const { dataObject, tableName } = props;
  let tableConfig: any = [];
  DATA_TABLE_CONFIG?.map((o, i) => {
    if (tableName === o.name) {
      tableConfig.push(o);
    }
  });

  let convertKeys: any = tableConfig?.[0]?.convertKeys
    ? Object.keys(tableConfig?.[0]?.convertKeys)
    : [];
  const handleOltLink = async (oltName: string) => {
    let newWindow = window.open(
      `${window.location.origin}#/` + `${"node?oltName=" + oltName}`,
      "_blank"
    )!;
    newWindow.onload = () => {
      newWindow.document.title = "OLT-" + oltName;
    };
  };

  return (
    <>
      {dataObject &&
      dataObject !== undefined &&
      tableConfig !== undefined &&
      tableConfig?.length !== 0 ? (
        <table className="dataTableDetails">
          <thead>
            <tr>
              {tableConfig?.[0]?.hasOwnProperty("headers") &&
              tableConfig?.[0]?.headers?.length !== 0 ? (
                tableConfig?.[0]?.headers?.map((h: any, i: number) => {
                  return (
                    <th
                      className={
                        tableConfig?.[0]?.headers?.length <= 2 ? "fixedCol" : ""
                      }
                      key={"tableHeaders" + i}
                    >
                      {h?.toUpperCase()}
                    </th>
                  );
                })
              ) : (
                <></>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(dataObject)?.map((key: any, i: number) => {
              if (tableConfig?.[0]?.notAllowedKeys?.indexOf(key) > -1)
                return <></>;
              return (
                <tr key={i}>
                  <td>
                    {getStatusOfCheckHeader(
                      dataObject[key],
                      formatCamelCase(key)
                    )}
                  </td>
                  {isObject(isNull(dataObject[key])) ? (
                    <>
                      <td>
                        {isNull(getCheckValue(isEmptyObject(dataObject[key])))}
                      </td>
                      <CustomPopOver
                        popOverData={getThreshold(
                          isEmptyObject(dataObject[key])
                        )}
                        originComp={getStatusOfCheck(
                          isEmptyObject(dataObject[key])
                        )}
                        index="popOver"
                      />
                    </>
                  ) : (
                    <>
                      {tableConfig?.[0]?.hasOwnProperty("convertKeys") ? (
                        convertKeys?.indexOf(key) >= 0 ? (
                          <td style={{ width: "40%" }}>
                            {dataConverter(
                              dataObject[key],
                              tableConfig?.[0]?.convertKeys[key]
                            )}
                          </td>
                        ) : (
                          <td style={{ width: "40%" }}>
                            {isNull(dataObject[key]?.toString())}
                          </td>
                        )
                      ) : (
                        <>
                          {tableConfig?.[0]?.attributeLink?.indexOf(key) >
                          -1 ? (
                            <>
                              <td
                                style={{
                                  width: "40%",
                                  color: "#1A73E9",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleOltLink(dataObject[key])}
                              >
                                {isNull(dataObject[key])}
                              </td>
                            </>
                          ) : (
                            <td style={{ width: "40%" }}>
                              {isNull(dataObject[key])}
                            </td>
                          )}
                        </>
                      )}
                      {tableConfig?.[0]?.headers?.length > 2 ? (
                        <>
                          <td></td>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <>No Data</>
      )}
    </>
  );
};

export default DataDisplayTable;
