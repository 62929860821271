import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import AppLabels from "../../../../constants/App_Labels";
import CMBasicDetails from "./CMBasicDetails";
import CMFailuredetails from "./CMFailuredetails";

interface Props {
  deviceData: any;
}
const CMInfoTabs = ({ deviceData }: Props) => {
  const appLabels = AppLabels();
  const [activeTabKey, setActiveTabKey] = useState("0");
  const handleSelect = (key: any) => {
    setActiveTabKey(key);
  };
  return (
    <div className="cmBasicInfoCont">
      <Tabs activeKey={activeTabKey} onSelect={handleSelect} className="cmTabs">
        <Tab
          eventKey="0"
          title={
            <span style={{ fontWeight: 600 }}>
              {appLabels?.SUMMARY.toUpperCase()}
            </span>
          }
        >
          <CMBasicDetails deviceData={deviceData} />
        </Tab>
        <Tab
          eventKey="1"
          title={
            <span style={{ fontWeight: 600 }}>
              {appLabels?.failures.toUpperCase()}
            </span>
          }
        >
          <CMFailuredetails deviceData={deviceData} />
        </Tab>
      </Tabs>
    </div>
  );
};

export default CMInfoTabs;
