import { Marker, Polyline } from "@react-google-maps/api";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { mapElementStyles } from "../../../config/mapItemsConfig";
import appConst from "../../../constants/appConst";
import { setMapCenter } from "../../../store/slices/mapItems/mapDetails";
import {
  GetGoogleMaps,
  GetNodeSiteDetailsProps,
} from "../../../util/reduxFunctions/getTopologyState";
import {
  getBase64ScaledImageAnchor,
  getMapElementClickableImage,
  getPngImagesForElement,
} from "../../../util/utilFuncs";

interface Props {
  pngImages: any;
  handleElementClick: any;
  zoomLevel: number;
}

const OLTMarker = ({ pngImages, handleElementClick, zoomLevel }: Props) => {
  const dispatch = useDispatch();
  const { activeMapStyleName } = GetGoogleMaps();
  const { allNodeData, activeNode } = GetNodeSiteDetailsProps();
  const oltPng = pngImages?.[appConst.olt];
  const [prevPngLength, setPrevPngLength] = useState(0);

  //center map to current olt symbol
  useEffect(() => {
    if (oltPng?.isLoading === false && oltPng?.status === appConst.OK) {
      if (prevPngLength !== oltPng?.data?.length) {
        const coord =
          activeNode?.olt?.data?.features?.[0]?.geometry?.coordinates;
        dispatch(setMapCenter({ lat: coord?.[1], lng: coord?.[0] }));
        setPrevPngLength(oltPng?.data?.length);
      }
    }
  }, [oltPng?.status]);

  return (
    <>
      {allNodeData.map((site:any) => {
        const feature = site?.olt?.data?.features?.[0];
        const pData = feature?.properties?.data;
        const markerLat = feature?.geometry?.coordinates?.[1];
        const markerLng = feature?.geometry?.coordinates?.[0];
        const nodeSiteCoord =
          site?.node?.data?.features?.[0]?.properties?.data?.siteCoords
            ?.coordinates;
        const nodeCoord = {
          lat: nodeSiteCoord?.[1],
          lng: nodeSiteCoord?.[0],
        };

        const anchor = getBase64ScaledImageAnchor(
          pngImages,
          appConst.olt,
          pData?.symbolName,
          pData?.rotation,
          mapElementStyles?.olt,
          zoomLevel
        );

        return (
          markerLat &&
          markerLng && (
            <Fragment key={feature?.featureId}>
              <Marker
                position={{ lat: markerLat, lng: markerLng }}
                icon={getPngImagesForElement(
                  oltPng,
                  pData?.symbolName,
                  pData?.rotation,
                  mapElementStyles?.olt,
                  zoomLevel
                )}
                clickable={false}
              />

              <Marker
                position={{ lat: markerLat, lng: markerLng }}
                onClick={() => {
                  handleElementClick(feature, appConst.olt);
                }}
                icon={{
                  url:
                    appConst.imageStringPrefix +
                    encodeURIComponent(
                      getMapElementClickableImage(
                        zoomLevel,
                        anchor?.clickIconSize
                      )
                    ),
                  anchor: new google.maps.Point(
                    anchor ? anchor?.x : mapElementStyles?.common.anchor.x,
                    anchor ? anchor?.y : mapElementStyles?.common.anchor.y
                  ),
                }}
              />

              {nodeCoord && markerLat && markerLng && (
                <Polyline
                  path={[nodeCoord, { lat: markerLat, lng: markerLng }]}
                  options={
                    activeMapStyleName === "standard"
                      ? mapElementStyles?.measureDistance.standard
                      : mapElementStyles?.measureDistance.others
                  }
                />
              )}
            </Fragment>
          )
        );
      })}
    </>
  );
};

export default React.memo(OLTMarker);
