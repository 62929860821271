export const partOptionDropDown = [
  { id: 0, key: "--", value: 0 },
  {
    id: 1,
    key: "FTTC NODE",
    value: 17,
    isTapSignal: true,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: -42 },
        { name: "860Mhz", value: -58 },
      ],
    },
  },
  {
    id: 2,
    key: "NODE TRUNK PORT",
    value: 17,
    isTapSignal: true,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: -26 },
        { name: "860Mhz", value: -38 },
      ],
    },
  },
  {
    id: 3,
    key: "NODE BRIDGER PORT",
    value: 17,
    isTapSignal: true,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: -34 },
        { name: "860Mhz", value: -46 },
      ],
    },
  },
  {
    id: 4,
    key: "BRIDGER PORT",
    value: 17,
    isTapSignal: true,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: -30 },
        { name: "860Mhz", value: -42 },
      ],
    },
  },
  {
    id: 5,
    key: "LINEX",
    value: 17,
    isTapSignal: true,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: -34 },
        { name: "860Mhz", value: -46 },
      ],
    },
  },
  {
    id: 6,
    key: "LLS102",
    value: 3.5,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: -3.5 },
        { name: "860Mhz", value: -4.4 },
      ],
    },
  },
  {
    id: 7,
    key: "LDC108",
    value: 1.5,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: -1.5 },
        { name: "860Mhz", value: -2.3 },
      ],
    },
  },
  {
    id: 8,
    key: "LDC108 TAP",
    value: 8.1,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: -9.1 },
        { name: "860Mhz", value: -9.3 },
      ],
    },
  },
  {
    id: 9,
    key: "LLS103",
    value: 3.5,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: -3.5 },
        { name: "860Mhz", value: -4.4 },
      ],
    },
  },
  {
    id: 10,
    key: "LLS103 TAP",
    value: 6.3,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: -7.0 },
        { name: "860Mhz", value: -7.3 },
      ],
    },
  },
  {
    id: 11,
    key: "MGTS2-04T",
    value: 0.0,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0.0 },
        { name: "111Mhz", value: 0.0 },
        { name: "860Mhz", value: 0.0 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: -4.3 },
        { name: "111Mhz", value: -4.3 },
        { name: "860Mhz", value: -4.3 },
      ],
    },
  },
  {
    id: 12,
    key: "MGTS4-08T",
    value: 0.0,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0.0 },
        { name: "111Mhz", value: 0.0 },
        { name: "860Mhz", value: 0.0 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: -8.1 },
        { name: "111Mhz", value: -8.1 },
        { name: "860Mhz", value: -8.1 },
      ],
    },
  },
  {
    id: 13,
    key: "MGTS4-11",
    value: 3.5,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: -3.5 },
        { name: "860Mhz", value: -4.6 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: -11.6 },
        { name: "111Mhz", value: -11.6 },
        { name: "860Mhz", value: -11.6 },
      ],
    },
  },
  {
    id: 14,
    key: "MGTS4-14",
    value: 1.4,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: -1.4 },
        { name: "860Mhz", value: -3.0 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: -14.5 },
        { name: "111Mhz", value: -14.5 },
        { name: "860Mhz", value: -14.5 },
      ],
    },
  },
  {
    id: 15,
    key: "MGTS4-17",
    value: 0.8,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: -0.8 },
        { name: "860Mhz", value: -2.1 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: -17.1 },
        { name: "111Mhz", value: -17.1 },
        { name: "860Mhz", value: -17.1 },
      ],
    },
  },
  {
    id: 16,
    key: "MGTS4-20",
    value: 0.8,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: -0.8 },
        { name: "860Mhz", value: -1.9 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: -20.2 },
        { name: "111Mhz", value: -20.2 },
        { name: "860Mhz", value: -20.2 },
      ],
    },
  },
  {
    id: 17,
    key: "MGTS4-23",
    value: 0.7,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: -0.7 },
        { name: "860Mhz", value: -1.6 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: -22.7 },
        { name: "111Mhz", value: -22.6 },
        { name: "860Mhz", value: -22.7 },
      ],
    },
  },
  {
    id: 18,
    key: "MGTS4-26",
    value: 0.9,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: -0.9 },
        { name: "860Mhz", value: -1.6 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: -26.6 },
        { name: "111Mhz", value: -26.5 },
        { name: "860Mhz", value: -26.6 },
      ],
    },
  },
  {
    id: 19,
    key: "MGTS4-29",
    value: 0.9,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: -0.9 },
        { name: "860Mhz", value: -1.6 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: -29.1 },
        { name: "111Mhz", value: -29.0 },
        { name: "860Mhz", value: -29.1 },
      ],
    },
  },
  {
    id: 20,
    key: "MGTS4-32",
    value: 0.9,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: -0.9 },
        { name: "860Mhz", value: -1.6 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: -31.8 },
        { name: "111Mhz", value: -31.7 },
        { name: "860Mhz", value: -31.8 },
      ],
    },
  },
  {
    id: 21,
    key: "MGTS8-10T",
    value: 0.0,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0.0 },
        { name: "111Mhz", value: 0.0 },
        { name: "860Mhz", value: 0.0 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: -10.5 },
        { name: "111Mhz", value: -10.5 },
        { name: "860Mhz", value: -10.5 },
      ],
    },
  },
  {
    id: 22,
    key: "MGTS8-12T",
    value: 0.0,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0.0 },
        { name: "111Mhz", value: 0.0 },
        { name: "860Mhz", value: 0.0 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: -12.2 },
        { name: "111Mhz", value: -12.2 },
        { name: "860Mhz", value: -12.2 },
      ],
    },
  },
  {
    id: 23,
    key: "MGTS8-15",
    value: 2.9,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: -2.9 },
        { name: "860Mhz", value: -4.3 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: -16.2 },
        { name: "111Mhz", value: -16.2 },
        { name: "860Mhz", value: -16.2 },
      ],
    },
  },
  {
    id: 24,
    key: "MGTS8-18",
    value: 1.6,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: -1.5 },
        { name: "860Mhz", value: -3.6 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: -18 },
        { name: "111Mhz", value: -18 },
        { name: "860Mhz", value: -18 },
      ],
    },
  },
  {
    id: 25,
    key: "MGTS8-21",
    value: 1.1,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: -1.1 },
        { name: "860Mhz", value: -2.0 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: -21.2 },
        { name: "111Mhz", value: -21.1 },
        { name: "860Mhz", value: -21.2 },
      ],
    },
  },
  {
    id: 26,
    key: "MGTS8-24",
    value: 1.1,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: -1.0 },
        { name: "860Mhz", value: -1.9 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: -24.3 },
        { name: "111Mhz", value: -24.3 },
        { name: "860Mhz", value: -24.3 },
      ],
    },
  },
  {
    id: 27,
    key: "MGTS8-27",
    value: 0.9,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: -0.9 },
        { name: "860Mhz", value: -1.6 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: -27.1 },
        { name: "111Mhz", value: -27.1 },
        { name: "860Mhz", value: -27.1 },
      ],
    },
  },
  {
    id: 28,
    key: "MGTS8-30",
    value: 0.8,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: -0.9 },
        { name: "860Mhz", value: -1.8 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: -30.4 },
        { name: "111Mhz", value: -30.4 },
        { name: "860Mhz", value: -30.4 },
      ],
    },
  },
  {
    id: 29,
    key: "MGTS8-33",
    value: 0.9,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: -0.9 },
        { name: "860Mhz", value: -1.8 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: -33.0 },
        { name: "111Mhz", value: -32.9 },
        { name: "860Mhz", value: -33.0 },
      ],
    },
  },
  {
    id: 30,
    key: "P3-412",
    value: 0.0192,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: 0.021 },
        { name: "860Mhz", value: 0.0965 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: 0.0797 },
        { name: "111Mhz", value: 0.0797 },
        { name: "860Mhz", value: 0.0797 },
      ],
    },
  },
  {
    id: 31,
    key: "P3-500",
    value: 0.0156,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: 0.0171 },
        { name: "860Mhz", value: 0.0767 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: 0.0564 },
        { name: "111Mhz", value: 0.0564 },
        { name: "860Mhz", value: 0.0564 },
      ],
    },
  },
  {
    id: 32,
    key: "P3-625",
    value: 0.0132,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: 0.0144 },
        { name: "860Mhz", value: 0.0633 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: 0.0351 },
        { name: "111Mhz", value: 0.0351 },
        { name: "860Mhz", value: 0.0351 },
      ],
    },
  },
  {
    id: 33,
    key: "P3-750",
    value: 0.0105,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: 0.0115 },
        { name: "860Mhz", value: 0.0538 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: 0.0249 },
        { name: "111Mhz", value: 0.02 },
        { name: "860Mhz", value: 0.02 },
      ],
    },
  },
  {
    id: 34,
    key: "P31000",
    value: 0.0089,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: 0.0098 },
        { name: "860Mhz", value: 0.0423 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: 0.0131 },
        { name: "111Mhz", value: 0.0131 },
        { name: "860Mhz", value: 0.0131 },
      ],
    },
  },
  {
    id: 35,
    key: "QR-540",
    value: 0.0135,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: 0.0141 },
        { name: "860Mhz", value: 0.0623 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: 0.0528 },
        { name: "111Mhz", value: 0.0528 },
        { name: "860Mhz", value: 0.0528 },
      ],
    },
  },
  {
    id: 36,
    key: "QR-715",
    value: 0.0102,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: 0.0108 },
        { name: "860Mhz", value: 0.0486 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: 0.0327 },
        { name: "111Mhz", value: 0.0327 },
        { name: "860Mhz", value: 0.0327 },
      ],
    },
  },
  {
    id: 37,
    key: "QR-860",
    value: 0.0088,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: 0.0089 },
        { name: "860Mhz", value: 0.041 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: 0.0238 },
        { name: "111Mhz", value: 0.0238 },
        { name: "860Mhz", value: 0.0238 },
      ],
    },
  },
  {
    id: 38,
    key: "CW-875",
    value: 0.0108,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: 0.0119 },
        { name: "860Mhz", value: 0.0675 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: 0.0164 },
        { name: "111Mhz", value: 0.0164 },
        { name: "860Mhz", value: 0.0164 },
      ],
    },
  },
  {
    id: 39,
    key: "P11000",
    value: 0.0095,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: 0.0105 },
        { name: "860Mhz", value: 0.0528 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: 0.0171 },
        { name: "111Mhz", value: 0.0171 },
        { name: "860Mhz", value: 0.0171 },
      ],
    },
  },
  {
    id: 40,
    key: "P1-750",
    value: 0.0117,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: 0.0128 },
        { name: "860Mhz", value: 0.0653 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: 0.0315 },
        { name: "111Mhz", value: 0.0315 },
        { name: "860Mhz", value: 0.0315 },
      ],
    },
  },
  {
    id: 41,
    key: "P1-500",
    value: 0.0167,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: 0.0184 },
        { name: "860Mhz", value: 0.0883 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: 0.0472 },
        { name: "111Mhz", value: 0.0472 },
        { name: "860Mhz", value: 0.0472 },
      ],
    },
  },
  {
    id: 42,
    key: "P1-412",
    value: 0.0223,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: 0.0242 },
        { name: "860Mhz", value: 0.1021 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: 0.0712 },
        { name: "111Mhz", value: 0.0712 },
        { name: "860Mhz", value: 0.0712 },
      ],
    },
  },
  {
    id: 43,
    key: "PD-375",
    value: 0.0154,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: 0.0167 },
        { name: "860Mhz", value: 0.0726 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: 0.0545 },
        { name: "111Mhz", value: 0.0545 },
        { name: "860Mhz", value: 0.0545 },
      ],
    },
  },
  {
    id: 44,
    key: "NE-412",
    value: 0.0225,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: 0.0246 },
        { name: "860Mhz", value: 0.1176 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: 0.0727 },
        { name: "111Mhz", value: 0.0727 },
        { name: "860Mhz", value: 0.0727 },
      ],
    },
  },
  {
    id: 45,
    key: "NE-500",
    value: 0.0176,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: 0.0193 },
        { name: "860Mhz", value: 0.092 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: 0.0415 },
        { name: "111Mhz", value: 0.0415 },
        { name: "860Mhz", value: 0.0415 },
      ],
    },
  },
  {
    id: 46,
    key: "NE-750",
    value: 0.0119,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: 0.013 },
        { name: "860Mhz", value: 0.0704 },
      ],
      spigotLevel: [
        { name: "42Mhz", value: 0.0213 },
        { name: "111Mhz", value: 0.0213 },
        { name: "860Mhz", value: 0.0213 },
      ],
    },
  },
  {
    id: 47,
    key: "MINICX",
    value: 0.1169,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: 0.1275 },
        { name: "860Mhz", value: 0.5289 },
      ],
    },
  },
  {
    id: 48,
    key: "59UF",
    value: 0.0525,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: 0.0573 },
        { name: "860Mhz", value: 0.2467 },
      ],
    },
  },
  {
    id: 49,
    key: "6UF",
    value: 0.042,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: 0.0459 },
        { name: "860Mhz", value: 0.2001 },
      ],
    },
  },
  {
    id: 50,
    key: "11UF",
    value: 0.0275,
    freq: {
      signalLevel: [
        { name: "42Mhz", value: 0 },
        { name: "111Mhz", value: 0.03 },
        { name: "860Mhz", value: 0.1351 },
      ],
    },
  },
];
