import React, { useEffect } from "react";
import { renderToString } from "react-dom/server";
import { useDispatch } from "react-redux";
import {
  mduRaster2Location,
  mduRasterLocation,
  raster1Polylines,
  raster2Polylines,
} from "../../../../config/mapConfig";
import appConst from "../../../../constants/appConst";
import { getMduRasterImage } from "../../../../store/slices/mapItems/mdu/mduRasterImage";
import {
  GetMduPolylinesProps,
  GetMduRasterImageProps,
} from "../../../../util/reduxFunctions/getTopologyState";
interface Props {
  gMap?: google.maps.Map;
  mduRasterData?: any;
  mduPolylinesdata?: any;
  mduPassivesdata?: any;
  mduTapdata?: any;
}

const MduRaster = React.memo(
  ({
    gMap,
    mduRasterData,
    mduPolylinesdata,
    mduPassivesdata,
    mduTapdata,
  }: Props) => {
    const dispatch = useDispatch();
    useEffect(() => {
      mduRasterData?.features?.map((imageData: any, index: any) => {
        if (
          imageData !== undefined &&
          imageData?.length !== 0 &&
          mduPolylinesdata?.length == 0 &&
          mduPassivesdata?.length == 0 &&
          mduTapdata?.length == 0
        ) {
          gMap?.data.addGeoJson(raster1Polylines);
          dispatch(getMduRasterImage(imageData?.properties?.data?.imageFile));
        }
      });
    }, [mduRasterData]);
    const { mduRasterImagedata } = GetMduRasterImageProps();

    if (
      mduRasterImagedata !== undefined &&
      mduPolylinesdata?.length == 0 &&
      mduPassivesdata?.length == 0 &&
      mduTapdata?.length == 0
    )
      mduRasterImagedata?.map((rasterImage: any, index: any) => {
        if (index === 0) {
          if (rasterImage?.message?.includes(appConst?.success)) {
            let rasterOverlay = new google.maps.GroundOverlay(
              "data:image/png;base64," + rasterImage?.image,
              mduRasterLocation
            );
            rasterOverlay.setMap(gMap as google.maps.Map);
          }
        } else if (index === 1) {
          gMap?.data.addGeoJson(raster2Polylines);
          if (rasterImage?.message?.includes(appConst?.success)) {
            let rasterOverlay = new google.maps.GroundOverlay(
              "data:image/png;base64," + rasterImage?.image,
              mduRasterLocation
            );
            rasterOverlay.setMap(gMap as google.maps.Map);
          }
        }
      });

    return <></>;
  }
);

export default MduRaster;
