import React, { ChangeEvent, useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { partOptionDropDown } from "./RfCalculatorConfig";

interface TableRowProps {
  tableData?: any;
  deleteRow?: any;
  updateRow?: any;
}

const TableRowComponent: React.FC<TableRowProps> = ({
  tableData,
  deleteRow,
  updateRow,
}) => {
  const [rowData, setRowData] = useState<any>({});

  const notNan = (val: string) => {
    if (val === "NaN") {
      return "0";
    } else {
      return val;
    }
  };

  const doNothing = () => {
    /*
      this function is to temporarily combat an incorrect implementation of the output fields
        as input elements. This is used as the onchange function so console does not yell at you.
    */
  };

  useEffect(() => {
    /*
      Update the tableRows array upon values changing
    */
    updateRow(rowData);
  }, [rowData]);

  return (
    <div>
      {React.Children.toArray(
        tableData?.map((data: any, indexVal: number) => (
          <>
            <div
              id={`"inputs"` + indexVal}
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                width: "120%",
              }}
            >
              <section>
                <span>
                  <label>
                    <select
                      name="Drop Gauge"
                      id={`"dropGauge"` + { indexVal }}
                      value={data?.partType}
                      onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                        setRowData({
                          ...data,
                          partType: e.target.value,
                          qty: data?.qty ? data?.qty : 1,
                          indexVal: indexVal,
                        })
                      }
                      style={{
                        width: "125px",
                        marginLeft: "15px",
                        borderRadius: "5px",
                        borderWidth: "1px",
                      }}
                    >
                      {partOptionDropDown?.map((el) =>
                        indexVal === 0 && el.id < 6 ? (
                          <option
                            id={`"dropGauge"` + el?.key + { indexVal }}
                            value={el?.id}
                          >
                            {el?.key}
                          </option>
                        ) : (
                          indexVal > 0 &&
                          el.id >= 5 && (
                            <option
                              id={`"dropGauge"` + el?.key + { indexVal }}
                              value={el?.id}
                            >
                              {el?.key}
                            </option>
                          )
                        )
                      )}
                    </select>
                  </label>
                </span>
                <span>
                  <input
                    type="text"
                    id={`"qtytext"` + { indexVal }}
                    value={notNan(data?.qty)}
                    onChange={(e) =>
                      setRowData({
                        ...data,
                        qty: notNan(e.target.value),
                        indexVal: indexVal,
                      })
                    }
                    style={{
                      width: "80px",
                      marginLeft: "10px",
                      borderRadius: "5px",
                      borderWidth: "1px",
                    }}
                  />
                </span>
              </section>
              <section style={{ marginLeft: "20px" }}>
                <span>
                  {data?.freq?.signalLevel?.map((ele: any, index: number) => (
                    <label id={`"frqtext1"` + index + { indexVal }}>
                      <input
                        type="text"
                        value={Math.abs(ele?.value)}
                        onChange={doNothing}
                        style={{
                          width: "80px",
                          marginLeft: "50px",
                          borderRadius: "5px",
                          borderWidth: "1px",
                          color: "blue",
                        }}
                      />
                    </label>
                  ))}
                </span>
              </section>
              <section style={{ marginLeft: "20px" }}>
                <span>
                  {data?.freq?.spigotLevel?.map((ele: any, index: number) => (
                    <label id={`"frqtext2"` + index + { indexVal }}>
                      <input
                        type="text"
                        value={Math.abs(ele?.value)}
                        onChange={doNothing}
                        style={{
                          width: "80px",
                          marginLeft: "50px",
                          borderRadius: "5px",
                          borderWidth: "1px",
                          color: "blue",
                        }}
                      />
                    </label>
                  ))}
                </span>
              </section>
              <section>
                <span>
                  <div
                    style={{
                      marginLeft: "15px",
                      width: "110px",
                    }}
                    id={`"delButton"` + { indexVal }}
                    className="trashIconSpan"
                    onClick={() => deleteRow(indexVal)} // Needs to be debugged
                    title="Reset"
                  >
                    <MdDelete />
                  </div>
                </span>
              </section>
            </div>
          </>
        ))
      )}
    </div>
  );
};

export default TableRowComponent;
