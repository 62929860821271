import { IHPQrlnData } from "../../../../models/account/diagnostics/ihp/IHPQrlnData";
import {
  IHP_QRLN_DATA_BEGIN,
  IHP_QRLN_DATA_SUCCESS,
  IHP_QRLN_DATA_FAILURE,
  IhpQrlnDataActionTypes,
} from "../../../../types/account/diagnostics/ihp/IHPQrlnData";

export function ihpQrlnDataBegin(): IhpQrlnDataActionTypes {
  return {
    type: IHP_QRLN_DATA_BEGIN,
  };
}

export function ihpQrlnDataSuccess(
  ihpqrlndata: IHPQrlnData
): IhpQrlnDataActionTypes {
  return {
    type: IHP_QRLN_DATA_SUCCESS,
    payload: ihpqrlndata,
  };
}

export function ihpQrlnDataFailure(error: Error): IhpQrlnDataActionTypes {
  return {
    type: IHP_QRLN_DATA_FAILURE,
    payload: error,
  };
}
