import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "redirectSource",
  initialState: {
    source: "",
  },
  reducers: {
    setSource: (state, action) => {
      state.source = action.payload;
    },
  },
});

export const { setSource } = slice.actions;
export default slice.reducer;
