import {
  Paper,
  Typography,
  Divider,
  ListItem,
  ListItemText,
  Container,
  List,
} from "@material-ui/core";
import { useState, useEffect, CSSProperties } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";

import FullScreenPopUp from "../../components/reusable/FullScreenPopUp";
import UpdateRedline from "../components/drawer/redline/UpdateRedline";
import { useRedlineStates } from "../util/reduxFunctions/getTopologyState";
import ExceptionHandlerWrapper from "../components/reusable/ExceptionHandlerWrapper";
import {
  getRedline,
  getRedlineAttachments,
} from "../store/slices/redlineSlice";
import { ITechProfile } from "../../store/ducksPattern/TechProfile";
import { readISODate } from "../util/utilFuncs";
import { CChip } from "../components/drawer/redline/RedlinesTable";

interface IPostedMsg {
  latLng?: { latitude: number; longitude: number };
  techData?: ITechProfile["data"];
}

interface IImg {
  data: string;
  fileName: string;
}

export default function SingleRedline(props: any) {
  const { trackerKey } = useParams<{ trackerKey: string }>();
  const dispatch = useDispatch();
  const {
    redlineData,
    redlineIsLoading,
    redlineStatus,
    redlineError,
    attachmentsData,
    attachmentsIsLoading,
    attachmentsStatus,
    attachmentsError,
  } = useRedlineStates();
  const [openFullImg, setOpenFullImg] = useState(false);
  const [fullImg, setFullImg] = useState<IImg>();
  const [postedMsg, setPostedMsg] = useState<IPostedMsg>();

  useEffect(() => {
    if (trackerKey) {
      dispatch(getRedline(trackerKey));
      dispatch(getRedlineAttachments(trackerKey));
    }
  }, [trackerKey]);

  //get posted message from url
  useEffect(() => {
    const handleReceiveMessage = (event: MessageEvent) => {
      // Ensure the message is coming from the expected origin for security
      if (event.origin !== window.location.origin) return;

      const latLng = event?.data?.latLng;
      const techData = event?.data?.techData;
      if (latLng || techData) setPostedMsg({ latLng, techData });
    };

    window.addEventListener("message", handleReceiveMessage);
    return () => {
      window.removeEventListener("message", handleReceiveMessage);
    };
  }, []);

  const trimmedComments = redlineData?.comments?.replace(/\n+$/, "");

  return (
    <ExceptionHandlerWrapper
      data={redlineData}
      isLoading={redlineIsLoading && !redlineData}
      status={redlineStatus}
      error={redlineError}
    >
      <Container maxWidth="md">
        <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
          <div className="flexedRowDiv" style={{ marginBottom: 10 }}>
            <Typography
              variant="h5"
              style={{ fontWeight: 500, marginRight: 10 }}
            >
              Redline Tracker Key: {redlineData?.trackorKey}{" "}
            </Typography>
            {redlineData?.status && <CChip label={redlineData?.status} />}
          </div>
          <Divider style={{ marginBottom: "20px" }} />
          <List>
            <LI
              primary="Project Description"
              secondary={redlineData?.projectDesc}
            />
            <LI
              primary="Comments"
              secondary={trimmedComments}
              sStyle={{ whiteSpace: "pre-line" }}
            />
            <LI primary="Node ID" secondary={redlineData?.nodeId} />
            <LI primary="Market ID" secondary={redlineData?.marketId} />
            <LI primary="Address" secondary={redlineData?.address} />
            <LI primary="Latitude" secondary={redlineData?.latitude} />
            <LI primary="Longitude" secondary={redlineData?.longitude} />
            <LI
              primary="Created Date"
              secondary={readISODate(redlineData?.createdDate)}
            />
            <LI primary="LAN ID" secondary={redlineData?.lanId} />
            <LI
              primary="Project Manager"
              secondary={redlineData?.projectManager}
            />
            <LI primary="Tracker ID" secondary={redlineData?.trackorId} />
            <ListItem
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <ListItemText
                primary="Attachment(s)"
                primaryTypographyProps={{ style: { fontWeight: 500 } }}
              />
              {
                <ExceptionHandlerWrapper
                  data={attachmentsData}
                  isLoading={attachmentsIsLoading}
                  status={attachmentsStatus}
                  error={attachmentsError}
                >
                  <div
                    style={{
                      gap: 10,
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {attachmentsData?.attachments?.map((img: any) => (
                      <img
                        src={getImgScr(img)}
                        alt={img.fileName}
                        style={{ cursor: "pointer", height: 200 }}
                        onClick={() => {
                          setOpenFullImg(true);
                          setFullImg(img);
                        }}
                      />
                    ))}
                  </div>
                </ExceptionHandlerWrapper>
              }
            </ListItem>
            <UpdateRedline
              trackerKey={trackerKey}
              techData={postedMsg?.techData}
            />
          </List>
        </Paper>
      </Container>
      <FullScreenPopUp
        open={openFullImg}
        handleClose={() => setOpenFullImg(false)}
        childComponent={
          fullImg && (
            <img
              src={getImgScr(fullImg)}
              alt={fullImg.fileName}
              style={{
                maxHeight: "90%",
                maxWidth: "90%",
                display: "block",
                margin: "auto",
              }}
            />
          )
        }
        pageName={fullImg?.fileName}
      />
    </ExceptionHandlerWrapper>
  );
}

const getImgScr = (img: IImg) => {
  return `data:image/png;base64,${img?.data}`;
};

interface LIProps {
  primary: string;
  secondary: string;
  /**secondary text style */
  sStyle?: CSSProperties;
}
/**Custom List Item*/
const LI = ({ primary, secondary, sStyle }: LIProps) => (
  <ListItem>
    <ListItemText
      primary={primary}
      secondary={secondary}
      primaryTypographyProps={{ style: { fontWeight: 500 } }}
      secondaryTypographyProps={{ style: { fontWeight: 450, ...sStyle } }}
    />
  </ListItem>
);
