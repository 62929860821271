import { Button, TextField } from "@material-ui/core";
import { format } from "date-fns";
import { MutableRefObject, useState } from "react";
import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { LuDownload } from "react-icons/lu";

interface Props {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  stageRef: MutableRefObject<any>;
  latLng?: { latitude: number; longitude: number };
  nodeId?: string;
  owningDrawing?: string;
}
export default function GeoExport({
  showModal,
  setShowModal,
  stageRef,
  latLng,
  nodeId,
  owningDrawing,
}: Props) {
  const now = new Date();
  const formattedDate = format(now, "yyyy-MM-dd");
  const [imgName, setImgName] = useState(
    nodeId
      ? `(NodeID - ${nodeId}), (Date - ${formattedDate})`
      : owningDrawing
      ? `MDU - ${owningDrawing}`
      : `(LatLng - ${latLng?.latitude}, ${latLng?.longitude}), (Date - ${formattedDate})`
  );

  const exportCanvas = () => {
    const currentRef = stageRef.current;
    if (currentRef) {
      const uri = (currentRef as any).toDataURL({ pixelRatio: 2 });
      if (uri) {
        const link = document.createElement("a");
        link.href = uri;
        link.download = imgName + ".png";
        link.click();
        setShowModal(false);
      }
    }
  };

  return (
    <Modal
      show={showModal}
      //   id="geoEditShortcutModal"
      onHide={() => setShowModal(false)}
      centered
    >
      <Modal.Header className="searchPopHead">
        <header>{"Export Canvas"}</header>
        <button className="sPopUpClose" onClick={() => setShowModal(false)}>
          <IoClose color="white" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <TextField
            variant="outlined"
            label="Image Name"
            value={imgName}
            size="small"
            onChange={(e: any) => setImgName(e.target.value)}
          />
          <Button
            onClick={exportCanvas}
            variant="contained"
            style={{ marginTop: 15 }}
            color="primary"
            startIcon={<LuDownload style={{ marginBottom: 3 }} />}
          >
            Export
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
