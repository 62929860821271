import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import {
  sendRpaBegin,
  sendRpaFailure,
  sendRpaSuccess
} from "../../../store/ducksPattern/sendRpaData";
import { HttpClient } from "../HttpClient";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { NetworkConfig } from "../NetworkConfig";

export default class RpaClient {
  static postToQueue =
    (
      formName: any,
      body: any
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "rpa/postToQueue",
        params: {
          formName,
        },
      };
      dispatch(sendRpaBegin());
      HttpClient.post<any>(httpParameters, body)
        .then((resp) => {
          dispatch(sendRpaSuccess(resp));
          return resp;
        })
        .catch((error) => {
          dispatch(sendRpaFailure(error));
          return error;
        });
    };
}
