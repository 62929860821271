import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Drawer from "../../components/reusable/Drawer";
import DrawerContent from "../components/drawer/DrawerContent";
import DrawerHeader from "../components/drawer/DrawerHeader";
import AppLabels from "../../constants/App_Labels";
import {
  GetConfigsProps,
  GetMapDrawerProps,
} from "../util/reduxFunctions/getTopologyState";
import MapView from "../components/map/MapView";
import { GetMapDetailsProps } from "../util/reduxFunctions/getTopologyState";
import MapItemsErrorHandling from "../components/map/MapItemsErrorHandling";
import "../styles/TopologyPage.scss";
import {
  useMemoizedShowSwitch,
  useOnTopologyModuleMount,
} from "../util/customHooks/topologyHooks";
import { getConfigs } from "../store/slices/configs";
import ExceptionHandlerWrapper from "../components/reusable/ExceptionHandlerWrapper";
import { setMapCenter } from "../store/slices/mapItems/mapDetails";
import { GetTechInfo } from "../../get-api/GetResponse";

const TopoStandaloneScreen = (props: any) => {
  const labels = AppLabels();
  const dispatch = useDispatch();
  const { gKey, isLoading, status, error } = GetConfigsProps();
  const mapC = GetMapDetailsProps()?.mapCenter;
  const [activeTabIndex, SetActiveTabIndex] = useState(0);
  const { lanId, data: techData } = GetTechInfo();
  const { data: configData } = GetConfigsProps();
  const { drawerTabIndex } = GetMapDrawerProps();
  const memoizedShowSwitch = useMemoizedShowSwitch({
    configData,
    techData,
    lanId,
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        dispatch(
          setMapCenter({
            lat: position?.coords?.latitude,
            lng: position?.coords?.longitude,
          })
        );
      },
      () => null //error handling
    );
  }, []);

  useOnTopologyModuleMount();

  useEffect(() => SetActiveTabIndex(drawerTabIndex), [drawerTabIndex]);

  return (
    <>
      {memoizedShowSwitch ? (
        <>
          <Drawer
            content={
              <DrawerContent
                activeTabIndex={activeTabIndex}
                SetActiveTabIndex={SetActiveTabIndex}
                isFromTopoStandaloneView={true}
              />
            }
            headerContent={<DrawerHeader />}
          >
            <ExceptionHandlerWrapper
              headerMsg={labels.mapExceptionHeaderMsg}
              bodyMsg={labels.Google_map_key_is_not_available}
              isLoading={isLoading}
              status={status}
              error={error}
              data={gKey}
              onRefresh={() => dispatch(getConfigs())}
            >
              <MapView
                defaultMapCenter={mapC}
                gKey={gKey}
                isFromTopoStandaloneView={true}
              />
            </ExceptionHandlerWrapper>
          </Drawer>
          <MapItemsErrorHandling isFromTopoStandaloneView={true} />
        </>
      ) : (
        <ExceptionHandlerWrapper
          status="ERROR"
          headerMsg="Unauthorized Access"
          EHContStyle={{ margin: 10 }}
        />
      )}
    </>
  );
};

export default TopoStandaloneScreen;
