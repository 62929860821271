import { useEffect, useState } from "react";
import { Divider } from "@material-ui/core";
import { Form } from "react-bootstrap";

import "../../../design-tokens/forms/LOP.scss";
import WorkDetailsData from "../../../data/forms/LOP/WorkDetailsData";
import AppLabels from "../../../constants/App_Labels";
import RenderRadioButtons from "./RenderRadioButtons";
import SignatureCapture from "../../reusable/SignatureCapture";
import EmailEntry from "../../reusable/EmailEntry";
import { GetFormHistory } from "../../../get-api/GetResponse";

interface Props {
  setWorkDetailsData: any;
  setIsEmailValid: any;
}

export default function WorkDetails({
  setWorkDetailsData,
  setIsEmailValid,
}: Props) {
  const labels = AppLabels();
  const formData = GetFormHistory()?.formData;

  const [initialData, setInitialData] = useState(WorkDetailsData());
  const [approbation, setApprobation] = useState<boolean>();
  const [summary, setSummary] = useState("");
  const [signatureImage, setSignatureImage] = useState("");
  const [emails, setEmails] = useState([""]);

  //set existing api data
  useEffect(() => {
    if (formData && formData != "") {
      formData?.workRequired?.map((k: any) =>
        initialData?.map(
          (e, i) => e.key === k && (initialData[i].active = true)
        )
      );

      setInitialData([...initialData]);
      setSummary(formData?.summaryWorkRequired);
      setSignatureImage(formData?.signature);
      setApprobation(formData?.workApprobation);
      setEmails(formData?.emails);
    }
  }, [formData]);

  //generate data
  useEffect(() => {
    if (approbation !== undefined) {
      const selectedWorkRequired = initialData
        ?.map((e) => e.active && e.key)
        ?.filter((item) => item);

      setWorkDetailsData({
        summaryWorkRequired: summary,
        signature: signatureImage,
        workApprobation: approbation,
        workRequired: selectedWorkRequired,
        emails,
      });
    }
  }, [initialData, summary, signatureImage, approbation, emails]);

  const onCheckBoxStateChange = (checked: boolean, index: number) => {
    initialData[index].active = checked;
    setInitialData([...initialData]);
  };

  return (
    <div>
      <div>
        <label className="titleLabel">{labels.Work_Required_Involves}:*</label>
        {initialData?.map((e, i) => (
          <div key={i} className="checkboxContainer">
            <label>{e.label}</label>
            <input
              type="checkbox"
              checked={e.active}
              style={{ width: "25px", height: "25px" }}
              onChange={(event) =>
                onCheckBoxStateChange(event.target.checked, i)
              }
            />
          </div>
        ))}
      </div>
      <Divider style={{ margin: "20px 0px 20px 0px" }} />
      <Form.Group className="selector">
        <label className="titleLabel">
          {labels.Summary_of_Work_Required}: (500 {labels.charsMax})
        </label>
        <Form.Control
          onChange={(e) => setSummary(e.target.value)}
          as="textarea"
          rows={3}
          maxLength={500}
          value={summary}
          placeholder={labels.Enter_work_summary + "..."}
        />
      </Form.Group>
      <Divider style={{ margin: "20px 0px 20px 0px" }} />
      <RenderRadioButtons
        approbation={approbation as boolean}
        setApprobation={setApprobation}
      />
      <SignatureCapture
        title={labels.Customer_Superintendent_or_Authorized_Signature + ".*"}
        signatureImage={signatureImage}
        onSignedImageChange={setSignatureImage}
      />
      <Divider style={{ margin: "20px 0px 20px 0px" }} />
      <EmailEntry
        title={labels.Send_a_copy_of_the_LOP_via_email + "*"}
        email={emails}
        setEmail={setEmails}
        setIsEmailValid={setIsEmailValid}
      />
    </div>
  );
}
