//package first
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

import AppLabels from "../../constants/App_Labels";
import "../../design-tokens/account-page/FailedAttributes.scss";
import { hzToMHz } from "../reusable/Util";
import {
  SetSCQAMFLAG,
  SetFailedCodewords,
  SetCodewordValues,
} from "../../store/actions/account/accountInfo/ActiveAccountAction";
import {
  getBaselineRatios,
  getCorrectedsDelta,
  checkDeltaStatus,
  getUnerroredsDelta,
} from "../reusable/Util";
import { useEffect, useState } from "react";
import {
  getOFDMBaselineRatios,
  getOFDMcorrectedDelta,
  getOFDMTotalCodewordsDelta,
  getOFDMUncorrectablesDelta,
  isNull,
} from "../reusable/Util";
import { UpdateActiveCertiDevices } from "../../store/actions/account/certifications/ActiveCertiDevicesAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

//interface for 2 objects passed

//const FailedAttributesCw = (props: any) => {
//const { currCWInfo, firstCWInfo } = props;
//interface for 2 objects passed
interface Props {
  currCWInfo: any;
  firstCWInfo: any;
  serialNumber: any;
}
export default function FailedAttributesCw(
  this: any,
  { currCWInfo, firstCWInfo, serialNumber }: Props
) {
  const labels = AppLabels();
  let firstResult: any;
  let data: any;
  const dispatch = useDispatch();
  const arr: { name: string; value: any; labels: string[] }[] = [];
  const arrObj: {}[] = [];

  const { scqam, cwMonitorCallIndex, tracer, failedAttributes } = useSelector(
    (state: any) => ({
      scqam: state.AccountState.ActiveAccountDetails?.scqam,
      cwMonitorCallIndex: state.AccountState.cwMonitorInfo.callIndex,
      tracer: state.AccountState.cwMonitorInfo.tracer,
      failedAttributes:
        state.AccountState.ActiveAccountDetails?.failedAttributes,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (arr?.length > 0 && failedAttributes !== "") {
      arr?.map((e, i) => {
        e?.value?.map((v: any, j: number) => {
          if (v?.value !== failedAttributes?.[i]?.value?.[j]?.value) {
            dispatch(SetFailedCodewords(arr));
            dispatch(SetSCQAMFLAG(true));
            if (tracer) {
              dispatch(UpdateActiveCertiDevices(serialNumber, "codeword", arr));
            } //cm -serial
            if (arrObj?.length > 0) {
              dispatch(SetCodewordValues(arrObj));

              //add tracer
            }
            return "";
          }
        });
      });
    } else if (arr?.length > 0 && failedAttributes?.length !== arr?.length) {
      dispatch(SetFailedCodewords(arr));
      dispatch(SetSCQAMFLAG(true)); //add tracer
      if (tracer) {
        dispatch(UpdateActiveCertiDevices(serialNumber, "codeword", arr));
      } //cm -serial
      if (arrObj?.length > 0) {
        dispatch(SetCodewordValues(arrObj));
        //add tracer
      }
      return;
    }
  }, [dispatch, arr]);
  useEffect(() => {
    if (arrObj?.length > 0) {
      dispatch(SetCodewordValues(arrObj));
    }
  }, [cwMonitorCallIndex]);
  /////////////////////////////////UPSTREAM SC-QAM CHANNELS//////////////////////
  const CWUpstream_channels_Array: any = [];

  currCWInfo?.upstreamChannels?.map((ch: any, index: number) => {
    if (firstCWInfo?.upstreamChannels[index]?.frequency === ch?.frequency) {
      firstResult = firstCWInfo?.upstreamChannels[index];
      data = ch?.uncorrectables - firstResult?.uncorrectables;
      if (data > 0) {
        CWUpstream_channels_Array.push({
          value: isNull(data),
          name: isNull(hzToMHz(ch?.frequency).toFixed(1)),
          frequency: isNull(hzToMHz(ch?.frequency).toFixed(1)),
        });
        arrObj.push({
          name: labels.UPSTREAM_SCQAM_CHANNELS.toUpperCase(),
          frequency: isNull(hzToMHz(ch?.frequency).toFixed(1)),
          baselineError: isNull(
            getBaselineRatios(
              ch?.unerroreds,
              ch?.correcteds,
              ch?.uncorrectables
            )
          ),
          unerroreds_delta: isNull(
            getUnerroredsDelta(
              firstCWInfo?.upstreamChannels,
              tracer ? 20 : 60,
              ch
            )
          ),
          corrected_delta: isNull(
            getCorrectedsDelta(
              firstCWInfo?.upstreamChannels,
              tracer ? 20 : 60,
              ch
            )
          ),
          uncorrectables: data,
        });
      } else {
        arrObj.push({
          name: labels.UPSTREAM_SCQAM_CHANNELS.toUpperCase(),
          frequency: isNull(hzToMHz(ch?.frequency).toFixed(1)),
          baselineError: isNull(
            getBaselineRatios(
              ch?.unerroreds,
              ch?.correcteds,
              ch?.uncorrectables
            )
          ),
          unerroreds_delta: isNull(
            getUnerroredsDelta(
              firstCWInfo?.upstreamChannels,
              tracer ? 20 : 60,
              ch
            )
          ),
          corrected_delta: isNull(
            getCorrectedsDelta(
              firstCWInfo?.upstreamChannels,
              tracer ? 20 : 60,
              ch
            )
          ),
          uncorrectables: isNull(
            checkDeltaStatus(
              firstCWInfo?.upstreamChannels,
              tracer ? 20 : 60,
              ch
            )
          ),
        });
      }
    }
  });
  if (CWUpstream_channels_Array?.length > 0) {
    arr.push({
      name: labels.UPSTREAM_SCQAM_CHANNELS.toUpperCase(),
      value: CWUpstream_channels_Array,
      labels: [labels.FREQUENCY, labels.UNCORRECTABLES_DELTA],
    });
  }
  ///////////////////////////////// DOWNSTREAM SC-QAM CHANNELS///////////////////////
  const CWDownstream_channels_Array: any = [];
  currCWInfo?.downstreamChannels?.map((ch: any, index: number) => {
    if (firstCWInfo?.downstreamChannels[index]?.frequency === ch?.frequency) {
      firstResult = firstCWInfo?.downstreamChannels[index];

      data = ch?.uncorrectables - firstResult?.uncorrectables;

      if (data > 0) {
        CWDownstream_channels_Array.push({
          value: isNull(data),
          name: isNull(Math.floor(hzToMHz(ch?.frequency))),
          frequency: isNull(Math.floor(hzToMHz(ch?.frequency))),
        });
        arrObj.push({
          name: labels.DOWNSTREAM_SCQAM_CHANNELS.toUpperCase(),
          frequency: isNull(Math.floor(hzToMHz(ch?.frequency))),
          baselineError: isNull(
            getBaselineRatios(
              ch?.unerroreds,
              ch?.correcteds,
              ch?.uncorrectables
            )
          ),
          unerrored_Delta: isNull(
            getUnerroredsDelta(
              firstCWInfo?.downstreamChannels,
              tracer ? 20 : 60,
              ch
            )
          ),
          corrected_Delta: isNull(
            getCorrectedsDelta(
              firstCWInfo?.downstreamChannels,
              tracer ? 20 : 60,
              ch
            )
          ),
          uncorrectables: data,
        });
      } else {
        arrObj.push({
          name: labels.DOWNSTREAM_SCQAM_CHANNELS.toUpperCase(),
          frequency: isNull(Math.floor(hzToMHz(ch?.frequency))),
          baselineError: isNull(
            getBaselineRatios(
              ch?.unerroreds,
              ch?.correcteds,
              ch?.uncorrectables
            )
          ),
          unerrored_Delta: isNull(
            getUnerroredsDelta(
              firstCWInfo?.downstreamChannels,
              tracer ? 20 : 60,
              ch
            )
          ),
          corrected_Delta: isNull(
            getCorrectedsDelta(
              firstCWInfo?.downstreamChannels,
              tracer ? 20 : 60,
              ch
            )
          ),
          uncorrectables: isNull(
            checkDeltaStatus(
              firstCWInfo?.downstreamChannels,
              tracer ? 20 : 60,
              ch
            )
          ),
        });
      }
    }
  });
  if (CWDownstream_channels_Array?.length > 0) {
    arr.push({
      name: labels.DOWNSTREAM_SCQAM_CHANNELS.toUpperCase(),
      value: CWDownstream_channels_Array,
      labels: [labels.FREQUENCY, labels.UNCORRECTABLES_DELTA],
    });
  }

  ///////////////////////////////// OFDMA CHANNELS ///////////////////////
  const OFDMA_channels_Array: any = [];
  currCWInfo?.ofdmaUsChannels?.map((ch: any, index: number) => {
    ch?.iucList?.length !== 0 &&
      ch?.iucList?.map((p: any, i: number) => {
        if (firstCWInfo?.ofdmaUsChannels?.[index] !== null) {
          firstResult = firstCWInfo?.ofdmaUsChannels?.[index]?.iucList?.find(
            (c: any) => c?.iuc === p?.iuc
          );
          // data = p?.unreliableCodewords - firstResult?.unreliableCodewords;

          arrObj.push({
            name: labels.OFDMA_CHANNELS.toUpperCase(),
            //value: isNull(data),
            channelid: ch?.channelId,
            iuc: `[${p?.iuc}]${p?.modulation}`,
            baselineError: isNull(
              getOFDMBaselineRatios(p?.unreliableCodewords, p?.totalCodewords)
            ),
            correcteds_delta: isNull(
              getOFDMcorrectedDelta(
                firstCWInfo?.ofdmaUsChannels?.[index],
                tracer ? 20 : 60,
                p,
                "OFDMA"
              )
            ),
            uncorrectable_delta: isNull(
              getOFDMUncorrectablesDelta(
                firstCWInfo?.ofdmaUsChannels?.[index],
                tracer ? 20 : 60,
                p,
                "OFDMA"
              )
            ),
            total_delta: isNull(
              getOFDMTotalCodewordsDelta(
                firstCWInfo?.ofdmaUsChannels?.[index],
                tracer ? 20 : 60,
                p,
                "OFDMA"
              )
            ),
          });
        }
      });
  });

  ///////////////////////////////// OFDM CHANNELS ///////////////////////
  currCWInfo?.ofdmDsChannels?.map((ch: any, index: number) => {
    ch?.profiles?.length !== 0 &&
      ch?.profiles?.map((p: any, i: number) => {
        if (firstCWInfo?.ofdmDsChannels?.[index] !== null) {
          firstResult = firstCWInfo?.ofdmDsChannels?.[index]?.profiles?.find(
            (c: any) => c?.profileId === p?.profileId
          );

          arrObj.push({
            name: labels.OFDM_CHANNELS.toUpperCase(),
            firstData: firstCWInfo,
            lastData: currCWInfo,
          });
        }
      });
  });

  return <></>;
}
