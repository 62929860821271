import React, { useState } from "react";
import CustomIcons, { IconInterface } from "./CustomIcons";

interface Props {
  name: IconInterface["name"];
  color?: string;
  hoverColor?: string;
  size?: number;
}

function CustomIconsHoverable({
  name,
  size,
  color = `var(--pureBlack)`,
  hoverColor = `var(--primary)`,
}: Props) {
  const [activeColor, setActiveColor] = useState(color);

  return (
    <div
      onMouseEnter={() => setActiveColor(hoverColor)}
      onMouseLeave={() => setActiveColor(color)}
    >
      <CustomIcons name={name} color={activeColor} size={size} />
    </div>
  );
}

export default CustomIconsHoverable;
