import { combineReducers } from "redux";
import { GWDeviceReducer } from "./diagnostics/cm/GWDeviceReducer";
import { WIFISettingReducer } from "./diagnostics/cm/WIFISettingReducer";
import { WIFIRadiosReducer } from "./diagnostics/cm/WIFIRadiosReducer";
import { RebootReducer } from "./deviceActions/RebootReducer";
import { FactoryResetReducer } from "./deviceActions/FactoryResetReducer";
import {HardZapReducer} from "./deviceActions/HardZapReducer";
import { ZapReducer } from "./deviceActions/ZapReducer";
import { IPTVNetworkReducer } from "./diagnostics/iptv/IPTVNetworkReducer";
import { IHPInsideWiringReducer } from "./diagnostics/ihp/IHPInsideWiringReducer";
import { IHPQrlnDataReducer } from "./diagnostics/ihp/IHPQrlnDataReducer";
import { IHPQsipDataReducer } from "./diagnostics/ihp/IHPQsipDataReducer";
import { IHPFeaturesReducer } from "./diagnostics/ihp/IHPFeaturesReducer";
import { RHPDocsisEventsReducer } from "./diagnostics/ihp/RHPDocsisEventsReducer";
import { ONTAlarmsReducer } from "./diagnostics/ont/ONTAlarmsReducer";
import { ONTIPReducer } from "./diagnostics/ont/ONTIPReducer";
import { ONTProfileReducer } from "./diagnostics/ont/ONTProfileReducer";
import { ODUNInterfaceReducer } from "./diagnostics/odu/ODUNInterfaceReducer";
import { WirelessSubReducer } from "./diagnostics/odu/WirelessSubReducer";
import { PodReducer } from "./diagnostics/pods/PodReducer";
import ontSummary from "../../slices/gpon/ontSummary"
import AccDetailsReducer from "./accountInfo/AccDetailsReducer";
import ActiveAccountReducer from "./accountInfo/ActiveAccountReducer";
import { ServiceAreaReducer } from "./accountInfo/ServiceAreaReducer";
import { CertificationReducer } from "./certifications/CertificationReducer";
import { STBSummaryReducer } from "./diagnostics/stb/STBSummaryReducer";
import { RecoEngineReducer } from "./troubleshoot/RecoEngineReducer";
import { RecoLegendReducer } from "./troubleshoot/RecoLegendReducer";
import { ActiveDiagDeviceReducer } from "./diagnostics/ActiveDiagDeviceReducer";
import { ZapAllSTBReducer } from "./deviceActions/ZapAllSTBReducer";
import { BanSearchReducer } from "./accountInfo/ProfileManagementReducer";
import { LoginFixReducer } from "./accountInfo/ProfileManagementReducer";
import { LinkBanReducer } from "./accountInfo/ProfileManagementReducer";
import { UnlinkBanReducer } from "./accountInfo/ProfileManagementReducer";
import { TowerViewReducer } from "./towerview/TowerViewReducer";
import { CWMonitoringReducer } from "./diagnostics/cm/CWMonitoringReducer";
import { CMDocsisEventsReducer } from "./diagnostics/cm/CMDocsisEventsReducer";
import { TapListReducer } from "./neighbourhood/TapListReducer";
import { TapInfoReducer } from "./neighbourhood/TapInfoReducer";
import { PCDeviceDiagReducer } from "./precheck/PCDeviceDiagReducer";
import { PCNeighDiagReducer } from "./precheck/PCNeighDiagReducer";
import { CMFlapsReducer } from "./diagnostics/cm/CMFlapsReducer";
import { PJIHistoryReducer } from "./forms/PJIHistoryReducer";
import { PJIReducer } from "./forms/PJIReducer";
import { FormDataReducer } from "./forms/FormDataReducer";
import { FormReducer } from "./forms/FormReducer";
import { NodeReducer } from "./node/NodeReducer";
import { NodeSummaryReducer } from "./node/NodeSummaryReducer";
import { ONTBandwidthReducer } from "./diagnostics/ont/ONTBandwidthReducer";
import acctReducer from "../../ducksPattern/account/acctReducer";
import sendEmailData from "../../ducksPattern/sendEmailData";
import validateData from "../../ducksPattern/validateData";
import sendRpaData from "../../ducksPattern/sendRpaData";
import rpdReducer from "../../ducksPattern/rpd/rpdReducer";
import oltSummaryDetailsReducer from "../../slices/gpon/oltSummaryDetails";
import cmSpeedTest from "../../slices/cablemodem/cmSpeedTest";
import ihpDetailsReducer from "../../slices/accountInfo/IhpNumberDetails";
import wifiCertVisibility from "../../slices/certification/wifiVisibility";
import {GWDeviceSummaryReducer} from "./diagnostics/cm/GWDeviceSummaryReducer";
import { NetworkInterfacesReducer} from "./diagnostics/cm/NetworkInterfacesReducer";
import xleGW from "../../slices/accountInfo/xleGW";
import battery from "../../slices/accountInfo/battery";
import xleCellular from "../../slices/accountInfo/xleCellular";
export const allAccountReducers = combineReducers({
  acctSlice: acctReducer,
  cmSpeedTestInfo: cmSpeedTest,
  DiagDevicesInfo: ActiveDiagDeviceReducer,
  cmFlapsDetails: CMFlapsReducer,
  cmDocsisEvents: CMDocsisEventsReducer,
  cwMonitorInfo: CWMonitoringReducer,
  gwDevicesInfo: GWDeviceReducer,
  networkInterfacesReducer: NetworkInterfacesReducer,
  wifiSettingsInfo: WIFISettingReducer,
  wifiCertVisibility:wifiCertVisibility,
  xleGW:xleGW,
  battery:battery,
  xleCellular:xleCellular,
  wifiRadiosInfo: WIFIRadiosReducer,
  AccountDetails: AccDetailsReducer,
  ActiveAccountDetails: ActiveAccountReducer,
  ServiceAreaInfo: ServiceAreaReducer,
  rebootDevice: RebootReducer,
  FactoryResetDevice: FactoryResetReducer,
  HardZapReducer: HardZapReducer,
  ZapDevice: ZapReducer,
  zapAllStbInfo: ZapAllSTBReducer,
  IptvNetworkInfo: IPTVNetworkReducer,
  gwDeviceSummaryInfo: GWDeviceSummaryReducer,
  PodInfo: PodReducer,
  IHPInsideWiringInfo: IHPInsideWiringReducer,
  IHPQrlnDataInfo: IHPQrlnDataReducer,
  IHPQsipDataInfo: IHPQsipDataReducer,
  IHPFeaturesInfo: IHPFeaturesReducer,
  RHPDocsisEventsInfo: RHPDocsisEventsReducer,
  ONTAlarmsInfo: ONTAlarmsReducer,
  ONTIPInfo: ONTIPReducer,
  ONTProfileInfo: ONTProfileReducer,
  ODUNInterfaceInfo: ODUNInterfaceReducer,
  WirelessSubInfo: WirelessSubReducer,
  STBSummaryInfo: STBSummaryReducer,
  Certification: CertificationReducer,
  RecoEngineInfo: RecoEngineReducer,
  RecoLegendInfo: RecoLegendReducer,
  BanSearchInfo: BanSearchReducer,
  LoginFixInfo: LoginFixReducer,
  LinkBanInfo: LinkBanReducer,
  UnlinkBanInfo: UnlinkBanReducer,
  TowerViewInfo: TowerViewReducer,
  TapListData: TapListReducer,
  ActiveTapData: TapInfoReducer,
  PCAccDevices: PCDeviceDiagReducer,
  PCAccNeighDetails: PCNeighDiagReducer,
  PJIHistoryInfo: PJIHistoryReducer,
  PJIInfo: PJIReducer,
  FormInfo: FormReducer,
  FormDataInfo: FormDataReducer,
  NodesList: NodeReducer,
  NodesDevices: NodeSummaryReducer,
  ONTBandwidthInfo: ONTBandwidthReducer,
  OLTSummaryDetails: oltSummaryDetailsReducer,
  ontSummary: ontSummary,
  sendEmailData,
  validateData,
  sendRpaData,
  rpd: rpdReducer,
  ihpDetails: ihpDetailsReducer,
});
