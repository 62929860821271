import {
  TAP_LIST_BEGIN,
  TAP_LIST_FAILURE,
  TAP_LIST_SUCCESS,
  TapListActionTypes,
} from "../../../types/account/neighbourhood/TapList";

export function TapListBegin(): TapListActionTypes {
  return {
    type: TAP_LIST_BEGIN,
  };
}

export function TapListSuccess(TapList: any): TapListActionTypes {
  return {
    type: TAP_LIST_SUCCESS,
    payload: TapList,
  };
}

export function TapListFailure(error: Error): TapListActionTypes {
  return {
    type: TAP_LIST_FAILURE,
    payload: error,
  };
}
