import { Divider } from "@material-ui/core";
import AppLabels from "../../../constants/App_Labels";
import "../../../design-tokens/forms/Noise.scss";
import { changeTimeFormat, isIgniteSTBAccount } from "../../reusable/Util";

interface Props {
  data: any;
}

const ViewASPScreen = ({ data }: Props) => {
  const formData = data?.formData;
  const labels = AppLabels() as any;
  // console.log(data?.workOrderId);
  const workOrderID =
    data?.workOrderId === "" || data?.workOrderId === undefined
      ? labels.No_Work_Order
      : data?.workOrderId;

  const isSTBchecked = formData?.EquipmentMenu.includes("STB");

  const checkquantitySTB = (stb: String) => {
    let sample = "";
    if (formData?.EquipmentMenu.includes("STB") && stb !== undefined) {
      sample = sample + formData?.stbquantity;
    }
    return sample;
  };

  const checkquantityFreePod = (fp: String) => {
    let sample = "";
    if (formData?.EquipmentMenu.includes("Free Pod") && fp !== undefined) {
      sample = sample + formData?.freepodquantity;
    }
    return sample;
  };

  const checkquantityPaidPod = (pp: String) => {
    let sample = "";
    if (formData?.EquipmentMenu.includes("Paid Pod") && pp !== undefined) {
      sample = sample + formData?.paidpodquantity;
    }
    return sample;
  };
  const serialNumberSTBCheck = (stb: String) => {
    let sample = "";
    if (formData?.EquipmentMenu.includes("STB")) {
      if (stb === undefined) {
        sample = "";
      } else if (stb === "1") {
        sample = sample + "STB:" + formData?.quantitytextbox1 + ",";
      } else if (stb === "2") {
        sample =
          sample +
          "STB:" +
          formData?.quantitytextbox1 +
          "," +
          "STB:" +
          formData?.quantitytextbox3 +
          ",";
      } else if (stb === "3") {
        sample =
          sample +
          "STB:" +
          formData?.quantitytextbox1 +
          "," +
          "STB:" +
          formData?.quantitytextbox3 +
          "," +
          "STB:" +
          formData?.quantitytextbox5 +
          ",";
      } else if (stb === "4") {
        sample =
          sample +
          "STB:" +
          formData?.quantitytextbox1 +
          "," +
          "STB:" +
          formData?.quantitytextbox3 +
          "," +
          "STB:" +
          formData?.quantitytextbox5 +
          "," +
          "STB:" +
          formData?.quantitytextbox7 +
          ",";
      } else if (stb === "5") {
        sample =
          sample +
          "STB:" +
          formData?.quantitytextbox1 +
          "," +
          "STB:" +
          formData?.quantitytextbox3 +
          "," +
          "STB:" +
          formData?.quantitytextbox5 +
          "," +
          "STB:" +
          formData?.quantitytextbox7 +
          "," +
          "STB:" +
          formData?.quantitytextbox9 +
          ",";
      } else if (stb === "6") {
        sample =
          sample +
          "STB:" +
          formData?.quantitytextbox1 +
          "," +
          "STB:" +
          formData?.quantitytextbox3 +
          "," +
          "STB:" +
          formData?.quantitytextbox5 +
          "," +
          "STB:" +
          formData?.quantitytextbox7 +
          "," +
          "STB:" +
          formData?.quantitytextbox25 +
          "," +
          "STB:" +
          formData?.quantitytextbox27 +
          ",";
      } else if (stb === "7") {
        sample =
          sample +
          "STB:" +
          formData?.quantitytextbox1 +
          "," +
          "STB:" +
          formData?.quantitytextbox3 +
          "," +
          "STB:" +
          formData?.quantitytextbox5 +
          "," +
          "STB:" +
          formData?.quantitytextbox7 +
          "," +
          "STB:" +
          formData?.quantitytextbox25 +
          "," +
          "STB:" +
          formData?.quantitytextbox27 +
          "," +
          "STB:" +
          formData?.quantitytextbox29 +
          ",";
      } else if (stb === "8") {
        sample =
          sample +
          "STB:" +
          formData?.quantitytextbox1 +
          "," +
          "STB:" +
          formData?.quantitytextbox3 +
          "," +
          "STB:" +
          formData?.quantitytextbox5 +
          "," +
          "STB:" +
          formData?.quantitytextbox7 +
          "," +
          "STB:" +
          formData?.quantitytextbox25 +
          "," +
          "STB:" +
          formData?.quantitytextbox27 +
          "," +
          "STB:" +
          formData?.quantitytextbox29 +
          "," +
          "STB:" +
          formData?.quantitytextbox31 +
          ",";
      } else if (stb === "9") {
        sample =
          sample +
          "STB:" +
          formData?.quantitytextbox1 +
          "," +
          "STB:" +
          formData?.quantitytextbox3 +
          "," +
          "STB:" +
          formData?.quantitytextbox5 +
          "," +
          "STB:" +
          formData?.quantitytextbox7 +
          "," +
          "STB:" +
          formData?.quantitytextbox25 +
          "," +
          "STB:" +
          formData?.quantitytextbox27 +
          "," +
          "STB:" +
          formData?.quantitytextbox29 +
          "," +
          "STB:" +
          formData?.quantitytextbox31 +
          "," +
          "STB:" +
          formData?.quantitytextbox33 +
          ",";
      } else if (stb === "10") {
        sample =
          sample +
          "STB:" +
          formData?.quantitytextbox1 +
          "," +
          "STB:" +
          formData?.quantitytextbox3 +
          "," +
          "STB:" +
          formData?.quantitytextbox5 +
          "," +
          "STB:" +
          formData?.quantitytextbox7 +
          "," +
          "STB:" +
          formData?.quantitytextbox25 +
          "," +
          "STB:" +
          formData?.quantitytextbox27 +
          "," +
          "STB:" +
          formData?.quantitytextbox29 +
          "," +
          "STB:" +
          formData?.quantitytextbox31 +
          "," +
          "STB:" +
          formData?.quantitytextbox33 +
          "," +
          "STB:" +
          formData?.quantitytextbox35 +
          ",";
      }
      return sample;
    }
  };

  const serialNumberPaidPodCheck = (pp: String) => {
    let sample = "";
    if (formData?.EquipmentMenu.includes("Paid Pod")) {
      if (pp == undefined) {
        sample = "";
      } else if (pp === "1") {
        sample = sample + "Paid Pod:" + formData?.quantitytextbox17 + ",";
      } else if (pp === "2") {
        sample =
          sample +
          "Paid Pod:" +
          formData?.quantitytextbox17 +
          "," +
          "Paid Pod:" +
          formData?.quantitytextbox19 +
          ",";
      } else if (pp === "3") {
        sample =
          sample +
          "Paid Pod:" +
          formData?.quantitytextbox17 +
          "," +
          "Paid Pod:" +
          formData?.quantitytextbox19 +
          "," +
          "Paid Pod:" +
          formData?.quantitytextbox21 +
          ",";
      } else if (pp === "4") {
        sample =
          sample +
          "Paid Pod:" +
          formData?.quantitytextbox17 +
          "," +
          "Paid Pod:" +
          formData?.quantitytextbox19 +
          "," +
          "Paid Pod:" +
          formData?.quantitytextbox21 +
          "," +
          "Paid Pod:" +
          formData?.quantitytextbox23 +
          ",";
      }
      return sample;
    }
  };

  const serialNumberFreePodCheck = (fp: String) => {
    let sample = "";
    if (formData?.EquipmentMenu.includes("Free Pod")) {
      if (fp === undefined) {
        sample = "";
      } else if (fp === "1") {
        sample = sample + "Free Pod:" + formData?.quantitytextbox9 + ",";
      } else if (fp === "2") {
        sample =
          sample +
          "Free Pod:" +
          formData?.quantitytextbox9 +
          "," +
          "Free Pod:" +
          formData?.quantitytextbox11 +
          ",";
      } else if (fp === "3") {
        sample =
          sample +
          "Free Pod:" +
          formData?.quantitytextbox9 +
          "," +
          "Free Pod:" +
          formData?.quantitytextbox111 +
          "," +
          "Free Pod:" +
          formData?.quantitytextbox13 +
          ",";
      } else if (fp === "4") {
        sample =
          sample +
          "Free Pod:" +
          formData?.quantitytextbox9 +
          "," +
          "Free Pod:" +
          formData?.quantitytextbox11 +
          "," +
          "Free Pod:" +
          formData?.quantitytextbox13 +
          "," +
          "Free Pod:" +
          formData?.quantitytextbox15 +
          ",";
      }
      return sample;
    }
  };

  return (
    <div className="viewNoiseContainer">
      <div>
        <label className="titleLabel">{labels.Work_Order_ID}:</label>
        <label className="textLabel">{workOrderID}</label>
      </div>
      <div>
        <label className="titleLabel">{"Order Date"}:</label>
        <label className="textLabel">
          {changeTimeFormat(data?.updatedDate)}
        </label>
      </div>
      <div>
        <label className="titleLabel">{"Created By"}:</label>
        <label className="textLabel">{data?.createdBy}</label>
      </div>
      <Divider style={{ margin: "10px 0px 20px 0px" }} />
      {formData?.formSubmissionNo != "" && (
        <div>
          <label className="titleLabel">{"Form Submission Number"}:</label>
          <label className="textLabel">{formData?.formSubmissionNo}</label>
        </div>
      )}
      <Divider style={{ margin: "15px 0px 20px 0px" }} />
      <div>
        <label className="titleLabel">{"STB quantity"}:</label>
        <label className="textLabel">
          {checkquantitySTB(formData?.stbquantity)}
        </label>
      </div>
      <div>
        <label className="titleLabel">{"SN for STB "}:</label>
        <label className="textLabel">
          {serialNumberSTBCheck(formData?.stbquantity)}
        </label>
      </div>

      <Divider style={{ margin: "15px 0px 20px 0px" }} />
      <div>
        <label className="titleLabel">{"Free Pod quantity"}:</label>
        <label className="textLabel">
          {checkquantityFreePod(formData?.freepodquantity)}
        </label>
      </div>

      <div>
        <label className="titleLabel">{"SN for Free Pod "}:</label>
        <label className="textLabel">
          {serialNumberFreePodCheck(formData?.freepodquantity)}
        </label>
      </div>

      <Divider style={{ margin: "15px 0px 20px 0px" }} />
      <div>
        <label className="titleLabel">{"Paid Pod quantity"}:</label>
        <label className="textLabel">
          {checkquantityPaidPod(formData?.paidpodquantity)}
        </label>
      </div>

      <div>
        <label className="titleLabel">{"SN for Paid Pod "}:</label>
        <label className="textLabel">
          {serialNumberPaidPodCheck(formData?.paidpodquantity)}
        </label>
      </div>

      <Divider style={{ margin: "15px 0px 20px 0px" }} />
      <div>
        <label className="titleLabel">{" Pod Type "}:</label>
        <label className="textLabel">{formData?.podtype}</label>
      </div>
      <div>
        <label className="titleLabel">{"Free Pod reasoning"}:</label>
        <label className="textLabel">{formData?.freepodreasoning}</label>
      </div>
      {formData?.freepodreasoning === "Other" && (
        <>
          {formData?.freepodreasoningcomments !== "" && (
            <div>
              <label className="titleLabel">{"Mandatory Comments"}:</label>
              <label className="textLabel">
                {formData?.freepodreasoningcomments}
              </label>
            </div>
          )}
        </>
      )}

      {isIgniteSTBAccount() ? (
        <div>
          <label className="titleLabel">{labels.ACCOUNT_NUMBER}:</label>
          <label className="textLabel">{formData?.accountNumber}</label>
        </div>
      ) : (
        <>
          <div>
            <label className="titleLabel">{labels.ACCOUNT_NUMBER}:</label>
            <label className="textLabel">{formData?.accountNumber}</label>
          </div>
          <div>
            <label className="titleLabel">{labels.HHID}:</label>
            <label className="textLabel">{formData?.hhid}</label>
          </div>
          <div>
            <label className="titleLabel">{labels.ACCOUNT_TYPE}:</label>
            <label className="textLabel">{formData?.account_type}</label>
          </div>
        </>
      )}
      <div>
        <label className="titleLabel">{labels.TECH_NAME}:</label>
        <label className="textLabel">{formData?.techName}</label>
      </div>
      <div>
        <label className="titleLabel">{labels.TECH_ID}:</label>
        <label className="textLabel">{formData?.technicianId}</label>
      </div>
      <div>
        <label className="titleLabel">
          {formData?.UnitNoOrSuiteNo === "" ? "House" : "Street" + " Number"}:
        </label>
        <label className="textLabel">{formData?.HouseNo}</label>
      </div>
      <div>
        <label className="titleLabel">{"Street Name"}:</label>
        <label className="textLabel">{formData?.StreetName}</label>
      </div>
      {formData?.streetDirection !== "" && (
        <div>
          <label className="titleLabel">{"Street Direction"}:</label>
          <label className="textLabel">{formData?.StreetDirection}</label>
        </div>
      )}
      {formData?.unitOrSuiteNumber !== "" && (
        <div>
          <label className="titleLabel">{"Unit Or Suite Number"}:</label>
          <label className="textLabel">{formData?.UnitNoOrSuiteNo}</label>
        </div>
      )}
      <div>
        <label className="titleLabel">{labels.City}:</label>
        <label className="textLabel">{formData?.City}</label>
      </div>
      <div>
        <label className="titleLabel">{"Node Number"}:</label>
        <label className="textLabel">{formData?.nodeNumber}</label>
      </div>
      {formData?.cbpId !== "" && (
        <div>
          <label className="titleLabel">{"CBPID"}:</label>
          <label className="textLabel">{formData?.cbpId}</label>
        </div>
      )}

      <Divider style={{ margin: "10px 0px 20px 0px" }} />

      <div>
        <label className="titleLabel">{"Distribution Email(s)"}:</label>
        <label className="textLabel">{"gta.woc@rci.rogers.com"}</label>
      </div>
    </div>
  );
};

export default ViewASPScreen;
