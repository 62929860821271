import {
  NODEDevicesActionTypes,
  RESET_NODE_DEVICES,
  SAVE_NODE_DEVICES,
  UPDATE_NODE_DEVICES,
  START_NODE_DEVICE_DIAG,
  SET_NODE_TELE_DIAG_LOADING,
  SET_NODE_TELE_DIAG_TYPE,
  SET_TELE_DATASET_TYPE,
  SET_TELE_VIEW_TYPE,
  SET_NODE_DEVICES_ERROR,
  UPDATE_NODE_DEVICES_STREAM,
  UPDATE_NODE_DEVICES_ARRAY,
} from "../../../types/node/telemetry/NodeDevicesInfo";

export function setNodeTeleDiagLoading(
  loadingValue: boolean
): NODEDevicesActionTypes {
  return {
    type: SET_NODE_TELE_DIAG_LOADING,
    payload: loadingValue,
  };
}
export function setNodeTeleDiagType(dataType: string): NODEDevicesActionTypes {
  return {
    type: SET_NODE_TELE_DIAG_TYPE,
    payload: dataType,
  };
}
export function StartNODEDeviceDiag(deviceKey: string): NODEDevicesActionTypes {
  return {
    type: START_NODE_DEVICE_DIAG,
    deviceKey: deviceKey,
  };
}

export function SaveNODEDevices(devices: any): NODEDevicesActionTypes {
  return {
    type: SAVE_NODE_DEVICES,
    payload: devices,
  };
}
export function UpdateNODEDevices(
  devicekey: string,
  apiName: string,
  data: any,
  status: string
): NODEDevicesActionTypes {
  return {
    type: UPDATE_NODE_DEVICES,
    devicekey: devicekey,
    apiToUpdate: apiName,
    data: data,
    status: status,
  };
}

export function UpdateNODEDevicesStream(
  macAddress: string,
  apiName: string,
  data: any,
  status: string
): NODEDevicesActionTypes {
  return {
    type: UPDATE_NODE_DEVICES_STREAM,
    macAddress: macAddress,
    apiToUpdate: apiName,
    data: data,
    status: status,
  };
}

export function UpdateNODEDevicesArray(
  data: any,
  apiName: string
): NODEDevicesActionTypes {
  return {
    type: UPDATE_NODE_DEVICES_ARRAY,
    data: data,
    apiToUpdate: apiName,
  };
}

export function SetNodeDevicesError(
  overAllError: boolean
): NODEDevicesActionTypes {
  return {
    type: SET_NODE_DEVICES_ERROR,
    payload: overAllError,
  };
}

export function SetTeleDatasetType(
  datasetType: string
): NODEDevicesActionTypes {
  return {
    type: SET_TELE_DATASET_TYPE,
    payload: datasetType,
  };
}

export function SetTeleViewType(viewType: string): NODEDevicesActionTypes {
  return {
    type: SET_TELE_VIEW_TYPE,
    payload: viewType,
  };
}

export function ResetNODEDevices(): NODEDevicesActionTypes {
  return {
    type: RESET_NODE_DEVICES,
  };
}
