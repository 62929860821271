export const GET_FORM_SUCCESS = "GET_FORM_SUCCESS";
export const GET_FORM_BEGIN = "GET_FORM_BEGIN";
export const GET_FORM_FAILURE = "GET_FORM_FAILURE";
export const HISTORY_FORM_RESET = "HISTORY_FORM_RESET";

interface GetFormSuccess {
  type: typeof GET_FORM_SUCCESS;
  payload: any;
}

interface GetFormBegin {
  type: typeof GET_FORM_BEGIN;
}

interface GetFormFailure {
  type: typeof GET_FORM_FAILURE;
  payload: Error;
}

interface HistoryReset {
  type: typeof HISTORY_FORM_RESET;
}

export type NewFormDataActionTypes =
  | GetFormSuccess
  | GetFormBegin
  | GetFormFailure
  | HistoryReset;
