import {
  FaAngleDown,
  FaAngleRight,
  FaArrowLeft,
  FaCheckCircle,
} from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { Card, Dropdown, DropdownButton } from "react-bootstrap";
import { FiAlertTriangle } from "react-icons/fi";
import { IoAlertCircle } from "react-icons/io5";
import { MdError } from "react-icons/md";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import AppLabels from "../../../constants/App_Labels";
import ConfigConst from "../../../constants/ConfigConst";
import {
  GetActiveAccountDetails,
  GetFormHistory,
  GetTechInfo,
  GetWorkOrdersList,
} from "../../../get-api/GetResponse";
import CertificationClient from "../../../network/httpclient/certification/CertificationClient";
import FormClient from "../../../network/httpclient/form/FormClient";
import EmailClient from "../../../network/httpclient/notification/EmailClient";
import ErrorIcon from "../../../resources/images/icons/ErrorIcon.svg";
import TestedGrayIcon from "../../../resources/images/icons/tested-gray.svg";
import TestedIcon from "../../../resources/images/icons/tested.svg";
import ViewHSICertificationPage from "../../../screens/certification-page/ViewHSICertificationPage";
import ViewWifiCertificationPage from "../../../screens/certification-page/ViewWifiCertificationPage";
import { SetSCQAMFLAG } from "../../../store/actions/account/accountInfo/ActiveAccountAction";
import FormSection from "../../certification/certificationSteps/FormSection";
import ConfirmPopUp from "../../reusable/ConfirmPopUp";
import CustomPopUp from "../../reusable/CustomPopUp";
import FullScreenPopUp from "../../reusable/FullScreenPopUp";
import {
  convertHTMLtoStr,
  isEmptyObject,
  isNull,
  checkWiFiFailCondition,
  checkHSIFailCondition,
  ccAddress,
  isLegacyAccount,
  isIgniteAccount,
} from "../../reusable/Util";
import DisplayEmailPreviewCert from "../DisplayEmailPreviewCert";
import PrepareEmailFormatCert from "../PrepareEmailFormatCert";
import PostCheckActions from "./PostCheckActions";
import { getAllFailedAttributes } from "../../../config/FailedAttributes";
import { setSource } from "../../../store/slices/redirectSource";
import { resetPrecheck } from "../../../store/ducksPattern/precheckDiagnosticData";

const ReviewCerti = (props: any) => {
  const labels = AppLabels();
  const { currentStep, handleCancel, handlePrev, handleNext } = props;
  const { certiData, allReasons, workOrderFlag, scqam, accountNumber } =
    useSelector(
      (state: any) => ({
        certiData: state.AccountState.Certification.ActiveCertiInfo.certiInfo,
        allReasons: state.AccountState.Certification.AllReasonsInfo.data,
        workOrderFlag: state.AccountState.Certification.ActiveCertiInfo?.flag,
        scqam: state.AccountState.ActiveAccountDetails.scqam,
        accountNumber:
          state.AccountState.ActiveAccountDetails.data?.accountNumber,
      }),
      shallowEqual
    );
  const onApiCallRef = useRef(false);
  const formHistory = GetFormHistory();
  const [custInfoAcc, setCustInfoAcc] = useState(true);
  const [hsiInfoAcc, setHSIInfoAcc] = useState(true);
  const [wifiInfoAcc, setWIFIInfoAcc] = useState(true);
  const [reviewInfoAcc, setReviewInfoAcc] = useState(true);
  const [formHistoryData, setFormHistoryData] = useState({});
  const [openHsiCerti, setOpenHsiCerti] = useState(false);
  const [openWifiCerti, setOpenWifiCerti] = useState(false);
  const [seleDropLength, setSeleDropLength] = useState("");
  const [seleDropResistance, setSeleDropResistance] = useState("");
  const [isDropRiserGood, setIsDropRiserGood] = useState("Select");
  const [seleReasonType, setSeleReasonType] = useState("Select");
  const [seleCustReview, setSeleCustReview] = useState("Select");
  const [isPJISubmitted, setIsPJISubmitted] = useState(false);
  // const [isIECSubmitted, setIsIECSubmitted] = useState(false);
  const [showFlag, setShowFlag] = useState(false);
  const [techNote, setTechNote] = useState("");
  const dispatch = useDispatch();
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [showPOPUp, setShowPOPUp] = useState(false);
  const isLegacyAcc = isLegacyAccount();
  const { isMaintenanceRoleAvailable, isTechnicianRoleAvailable } =
    GetTechInfo();

  let allFailedAttributes: any = undefined;
  const certiDevicesData =
    certiData?.data?.hsiCertificate?.devices?.filter(
      (d: any) => d?.status?.toLowerCase() === "fail"
    ) || [];
  allFailedAttributes = getAllFailedAttributes(certiDevicesData);

  
  // DO NOT REMOVE. IEC VISIBILITY IS HIDDEN AND WILL BE BROUGHT BACK LATER
  // useEffect(() => {
  //   formHistory?.dataList?.some((e: any) => {
  //     if (e?.formData?.hasOwnProperty("ServiceChecklist")) {
  //       return setIsIECSubmitted(true);
  //     }
  //   });
  // }, [formHistory]);

  const brand = GetActiveAccountDetails()?.brand;
  const testType = GetActiveAccountDetails()?.testType;
  const activeAccData = GetActiveAccountDetails()?.data;
  const techData = GetTechInfo()?.data;
  const accType = GetActiveAccountDetails()?.selectedSA?.topology;
  const orderCategory = GetWorkOrdersList()?.activeOrderCategory
    ? GetWorkOrdersList()?.activeOrderCategory
    : GetWorkOrdersList()?.workOrderDetails?.orderCategory;
  const woType = GetWorkOrdersList()?.activeWorkType
    ? GetWorkOrdersList()?.activeWorkType
    : GetWorkOrdersList()?.workOrderDetails?.workType;
  const workOrderDetails = GetWorkOrdersList()?.workOrderDetails;
  const productList: string[] = workOrderDetails?.productList
    ? workOrderDetails.productList
    : workOrderDetails?.error ?? [];
  const indexWithWSTV = productList.findIndex((item) =>
    item.includes("PRD: WSTV")
  );

  const onApiCall = (pjiformId: String, formPJIData: any) => {
    const pjiApiData = {
      formName: ConfigConst.PRE_JOB_INSPECTION,
      workorderId: certiData?.data?.workOrderId,
      accountNumber: activeAccData?.accountNumber,
      cbpid: activeAccData?.cbpId ? activeAccData?.cbpId : "",
      formData: JSON.stringify({ ...formPJIData }),
    };
    if (formPJIData) {
      dispatch(FormClient.updateForm(pjiApiData, pjiformId));
    }
  };

  const handleConfirm = () => {
    dispatch(SetSCQAMFLAG(false));
    setShowPOPUp(false);
    let rTest = allReasons?.filter((r: any) => r.reasonText === seleReasonType);
    let reviewFlag =
      seleCustReview === "Yes" ? true : seleCustReview === "No" ? false : "";
    let tempCertiObj = {
      remark: {
        reasonCode: dropQuesRequired() ? rTest?.[0]?.reasonCode : "100",
        reasonText: dropQuesRequired() ? rTest?.[0]?.reasonText : "N/A",
        isReviewedWithCustomer: reviewFlag,
        notes: techNote,
        lengthOfDrop: dropQuesRequired() ? seleDropLength : "N/A",
        resistanceOfDrop: dropQuesRequired() ? seleDropResistance : "N/A",
        areDropAndRiserGood: dropQuesRequired() ? isDropRiserGood : "N/A",
      },
      technician: {
        lanId: isNull(techData?.lanId),
        techId: isNull(techData?.techId),
        companyNumber: isNull(techData?.companyId),
        contactNumber: isNull(techData?.MobilePhone),
      },
      status: "COMPLETE",
      workflow: {
        status: "COMPLETE",
        completedSteps: [1, 2, 3, 4],
      },
    };
    if (certiData?.hasOwnProperty("id")) {
      const base64EncObj = { data: btoa(JSON.stringify(tempCertiObj)) };
      dispatch(CertificationClient.updateCerti(certiData.id, base64EncObj));
    }
    if (hsiFailCondition || wifiFailCondition) {
      sendEmail();
    }
    handleNext(4);
  };
  let hsiCertiData = certiData?.data?.hsiCertificate;
  let wifiCertiData = certiData?.data?.wifiCertificate;
  let passCount =
    hsiCertiData?.summary?.passed?.count +
    hsiCertiData?.summary?.marginalPass?.count;

  // DO NOT REMOVE. IEC VISIBILITY IS HIDDEN AND WILL BE BROUGHT BACK LATER
  // const navigateIECForm = () => {
  //   if (
  //     indexWithWSTV !== -1 && //has WSTV product
  //     (orderCategory === "SO" || orderCategory === "WO") //ignite service and install orders
  //   ) {
  //     setIsIECSubmitted(true); //remove IEC
  //   } else if (
  //     (brand !== "Fido" &&
  //       (woType === "IGPO" ||
  //         woType === "DPSI" ||
  //         woType === "DPSM" ||
  //         orderCategory === "SO") &&
  //       testType === "WHT" && !isLegacyAcc &&
  //       accType?.accountNumber?.length === 9) ||
  //     (isIgniteAccount(accType) &&
  //       (orderCategory === "SO" || orderCategory === "WO"))
  //   ) {
  //     setIsIECSubmitted(false);
  //   } else {
  //     setIsIECSubmitted(true);
  //   }
  // };

  const changeOrderTypeValue = (orderCategory: string) => {
    if (orderCategory === ConfigConst.WO) {
      return labels.Installation;
    } else if (orderCategory === ConfigConst.SO) {
      return labels.Service;
    } else return orderCategory;
  };

  const wifiFailCondition = checkWiFiFailCondition(
    certiData?.data?.wifiCertificate
  );
  const hsiFailCondition = checkHSIFailCondition(
    certiData?.data?.hsiCertificate
  );
  const isCertFail = wifiFailCondition || hsiFailCondition;
  const isIPTVDevices = (deviceList: any) => {
    if (deviceList && deviceList?.length > 0) {
      let iptvCheck: boolean[] = deviceList?.map((d: any) =>
        d?.deviceCategory?.includes("IPTV")
      );
      return iptvCheck?.indexOf(false) !== -1 ? false : true;
    } else return false;
  };
  const isCertFailOnlyDueToIPTV = () => {
    return (
      certiData?.data?.hsiCertificate?.status?.includes("Fail") &&
      isIPTVDevices(certiData?.data?.hsiCertificate?.summary?.failed?.devices)
    );
  };
  const dropQuesRequired = () => {
    let dropQueRequiredFlag = true;
    switch (accType) {
      case ConfigConst.gpon.toUpperCase():
      case ConfigConst.fwa.toUpperCase():
        dropQueRequiredFlag = false;
        break;
      default:
        dropQueRequiredFlag = isCertFailOnlyDueToIPTV()
          ? false
          : wifiFailCondition || hsiFailCondition;
        break;
    }
    return dropQueRequiredFlag;
  };
  const mandatoryPostCheckStatus = () => {
    if (dropQuesRequired()) {
      if (hsiFailCondition) {
        return (
          seleReasonType !== "Select" &&
          isDropRiserGood != "Select" &&
          seleDropLength != "" &&
          seleDropResistance != ""
        );
      } else if (wifiFailCondition) {
        return seleReasonType !== "Select";
      } else return true;
    } else return true;
  };

  const sendEmail = () => {
    const franchise = "T";
    let emailSub = "Failed Certificate";

    const htmlMessage = convertHTMLtoStr(
      <PrepareEmailFormatCert
        body={
          <DisplayEmailPreviewCert
            woID={isNull(certiData?.data?.workOrderId)}
            woType={isNull(changeOrderTypeValue(orderCategory))}
            techId={techData?.techId}
            firmName={techData?.firmName}
            techNotes={techNote}
            // selectedAddress={getFormatedAddress(activeAccSA)}
            failedCodewords={hsiCertiData?.failedCodewords}
            wifiCertiDetails={wifiCertiData}
            hsiFailCondition={hsiFailCondition}
            wifiFailCondition={wifiFailCondition}
            allFailedAttributes={allFailedAttributes}
          />
        }
        hsiFailCondition={hsiFailCondition}
        wifiFailCondition={wifiFailCondition}
        techName={techData?.lanId}
      />
    );
    let emailPayload = {
      accountNumber: accountNumber,
      franchise: franchise,
      subject: emailSub,
      htmlMessage: htmlMessage,
      language: "EN",
      firstName: activeAccData?.firstName,
      lastName: activeAccData?.lastName,
      addresses: [techData?.supervisorsEmail, ccAddress(techData?.companyId)],
      formName: "Failed Certificate".toUpperCase()
    };
    const base64EncObj = { data: btoa(JSON.stringify(emailPayload)) };
    dispatch(EmailClient.sendEmailNoti("Cert EMail", base64EncObj));
  };

  const onSubmitCerti = () => {
    if (scqam != true) {
      let rTest = allReasons?.filter(
        (r: any) => r.reasonText === seleReasonType
      );
      let reviewFlag =
        seleCustReview === "Yes" ? true : seleCustReview === "No" ? false : "";
      let tempCertiObj = {
        remark: {
          reasonCode: dropQuesRequired() ? rTest?.[0]?.reasonCode : "100",
          reasonText: dropQuesRequired() ? rTest?.[0]?.reasonText : "N/A",
          isReviewedWithCustomer: reviewFlag,
          notes: techNote,
          lengthOfDrop: dropQuesRequired() ? seleDropLength : "N/A",
          resistanceOfDrop: dropQuesRequired() ? seleDropResistance : "N/A",
          areDropAndRiserGood: dropQuesRequired() ? isDropRiserGood : "N/A",
        },
        technician: {
          lanId: isNull(techData?.lanId),
          techId: isNull(techData?.techId),
          companyNumber: isNull(techData?.companyId),
          contactNumber: isNull(techData?.MobilePhone),
        },
        status: "COMPLETE",
        workflow: {
          status: "COMPLETE",
          completedSteps: [1, 2, 3, 4],
        },
      };
      if (certiData?.hasOwnProperty("id")) {
        const base64EncObj = { data: btoa(JSON.stringify(tempCertiObj)) };
        dispatch(CertificationClient.updateCerti(certiData.id, base64EncObj));
      }
      if (hsiFailCondition || wifiFailCondition) {
        sendEmail();
      }
      handleNext(4);
      dispatch(setSource(""));
      dispatch(resetPrecheck());
    } else {
      setShowPOPUp(true);
    }
  };
  useEffect(() => {
    if (
      workOrderFlag === false &&
      hsiCertiData !== undefined &&
      isEmptyObject(hsiCertiData) !== null &&
      mandatoryPostCheckStatus() &&
      seleCustReview !== "Select"
    ) {
      if (isTechnicianRoleAvailable || isMaintenanceRoleAvailable) {
        setEnableSubmit(isPJISubmitted);
      } else {
        setEnableSubmit(true);
      }
    } else if (
      hsiCertiData !== undefined &&
      isEmptyObject(hsiCertiData) !== null &&
      wifiCertiData !== undefined &&
      isEmptyObject(wifiCertiData) !== null &&
      mandatoryPostCheckStatus() &&
      seleCustReview !== "Select"
      //&& isIECSubmitted
    ) {
      if (isTechnicianRoleAvailable || isMaintenanceRoleAvailable) {
        setEnableSubmit(isPJISubmitted);
        //setEnableSubmit(isIECSubmitted);
      } else {
        setEnableSubmit(true);
      }
    } else {
      setEnableSubmit(false);
    }
  }, [
    seleReasonType,
    seleDropLength,
    seleDropResistance,
    isDropRiserGood,
    seleCustReview,
    isPJISubmitted,
    isTechnicianRoleAvailable,
    isMaintenanceRoleAvailable,
    //isIECSubmitted,
  ]);

  useEffect(() => {
    if (certiData?.data?.workOrderId) {
      dispatch(FormClient.getForm(certiData?.data?.workOrderId, ""));
    }
  }, [certiData?.data?.workOrderId]);

  useEffect(() => {
    if (!onApiCallRef.current) {
      formHistory?.dataList?.some((e: any) => {
        if (
          e?.formName === ConfigConst.PRE_JOB_INSPECTION &&
          !e?.accountNumber
        ) {
          onApiCallRef.current = true;
          onApiCall(e?.id, e?.formData);
        }
      });
    }
  }, [formHistory]);

  useEffect(() => {
    dispatch(CertificationClient.getReasons());
    //navigateIECForm();
  }, []);
  useEffect(() => {
    formHistory?.dataList?.some((e: any) => {
      if (e?.formName === ConfigConst.PRE_JOB_INSPECTION) {
        setFormHistoryData(e?.formData);
        setIsPJISubmitted(true);
      }
    });
  }, [formHistory]);


  return (
    <div className="certiStepCont">
      <div className="hsiCertiStep">
        <h4>Review &amp; Submit</h4>
        <div className="reviewCertiCont">
          <section className="certiAccordian">
            <Card.Header onClick={() => setCustInfoAcc(!custInfoAcc)}>
              {custInfoAcc ? <FaAngleDown /> : <FaAngleRight />}
              <span>Customer Information</span>
            </Card.Header>
            {custInfoAcc ? (
              <Card.Body>
                <p>
                  Verify the customer account and workorder ID to ensure that
                  the certification is tagged to the right information.
                </p>
                <div className="certiInputCont">
                  <div>
                    <label>
                      Customer Account Number
                      <span className="mandatoryAst"> *</span>
                    </label>
                    <input
                      type="text"
                      value={isNull(certiData?.data?.accountNumber)}
                      disabled
                    />
                  </div>
                  <div>
                    <label>
                      Customer Work Order ID
                      <span className="mandatoryAst"> *</span>
                    </label>
                    <input
                      type="text"
                      value={isNull(certiData?.data?.workOrderId)}
                      disabled
                    />
                  </div>
                  <div>
                    <label>
                      Select Test Type
                      <span className="mandatoryAst"> *</span>
                    </label>
                    <input
                      type="text"
                      value={
                        isNull(certiData?.data?.testType) === "WHT"
                          ? "Whole Home Test"
                          : isNull(certiData?.data?.testType) === "DNG"
                          ? "EDBA Test"
                          : "N/A"
                      }
                      disabled
                    />
                  </div>
                </div>
              </Card.Body>
            ) : (
              <></>
            )}
          </section>

          <section className="certiAccordian">
            <Card.Header onClick={() => setHSIInfoAcc(!hsiInfoAcc)}>
              {hsiInfoAcc ? <FaAngleDown /> : <FaAngleRight />}
              <span>Whole Home Certification</span>
            </Card.Header>
            {hsiInfoAcc ? (
              <Card.Body>
                <p>
                  This is the certification you submitted. You can review them
                  if need be, or proceed for submission
                </p>
                <div className="certiInputCont">
                  <label>
                    Whole Home Certification Summary
                    <span className="mandatoryAst"> *</span>
                  </label>
                  {hsiCertiData?.summary ? (
                    <div className="activeCertiResults">
                      <span>
                        <h4>{passCount < 10 ? "0" + passCount : passCount}</h4>{" "}
                        Device
                        <FaCheckCircle color="#28A745" />
                      </span>
                      <span>
                        <h4>
                          {hsiCertiData?.summary?.failed?.count < 10
                            ? "0" + hsiCertiData?.summary?.failed?.count
                            : hsiCertiData?.summary?.failed?.count}
                        </h4>{" "}
                        Device
                        <img src={ErrorIcon} />
                      </span>
                      <span>
                        <h4>
                          {hsiCertiData?.summary?.skipped?.count < 10
                            ? "0" + hsiCertiData?.summary?.skipped?.count
                            : hsiCertiData?.summary?.skipped?.count}
                        </h4>{" "}
                        Device
                        <FiAlertTriangle color="#E77C40" />
                      </span>
                    </div>
                  ) : (
                    <div className="disabledResults">
                      <div>
                        <span>
                          -- Device
                          <FaCheckCircle />
                        </span>
                        <span>
                          -- Device
                          <MdError />
                        </span>
                        <span>
                          -- Device
                          <FiAlertTriangle />
                        </span>
                      </div>
                    </div>
                  )}
                  {hsiCertiData?.summary ? (
                    <i onClick={() => setOpenHsiCerti(true)}>
                      View Certification
                    </i>
                  ) : (
                    <></>
                  )}
                </div>
              </Card.Body>
            ) : (
              <></>
            )}
          </section>
          {workOrderFlag && (
            <section className="certiAccordian">
              <Card.Header onClick={() => setWIFIInfoAcc(!wifiInfoAcc)}>
                {wifiInfoAcc ? <FaAngleDown /> : <FaAngleRight />}
                <span>WiFi Certification</span>
              </Card.Header>
              {wifiInfoAcc ? (
                <Card.Body>
                  <p>
                    This is the certification you submitted. You can review them
                    if need be, or proceed for submission
                  </p>
                  <div className="certiInputCont">
                    <label>
                      WIFI Certification Summary
                      <span className="mandatoryAst"> *</span>
                    </label>
                    {wifiCertiData?.isApplicable ? (
                      <>
                        <div className="activeCertiResults">
                          <span>
                            <h4>
                              {wifiCertiData?.summary?.total < 10
                                ? "0" + wifiCertiData?.summary?.total
                                : wifiCertiData?.summary?.total}
                            </h4>{" "}
                            Locations <img src={TestedIcon} />
                          </span>
                          <span>
                            <h4>
                              {wifiCertiData?.summary?.passed < 10
                                ? "0" + wifiCertiData?.summary?.passed
                                : wifiCertiData?.summary?.passed}
                            </h4>{" "}
                            Locations
                            <FaCheckCircle color="#28A745" />
                          </span>
                          <span>
                            <h4>
                              {wifiCertiData?.summary?.failed < 10
                                ? "0" + wifiCertiData?.summary?.failed
                                : wifiCertiData?.summary?.failed}
                            </h4>{" "}
                            Locations
                            <img src={ErrorIcon} />
                          </span>
                        </div>
                        <i onClick={() => setOpenWifiCerti(true)}>
                          View Certification
                        </i>
                      </>
                    ) : (
                      <div className="disabledResults">
                        <div>
                          <span>
                            -- Locations <img src={TestedGrayIcon} />
                          </span>
                          <span>
                            -- Locations
                            <FaCheckCircle />
                          </span>
                          <span>
                            -- Locations
                            <IoAlertCircle />
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </Card.Body>
              ) : (
                <></>
              )}
            </section>
          )}
          <section className="certiAccordian">
            <Card.Header onClick={() => setReviewInfoAcc(!reviewInfoAcc)}>
              {reviewInfoAcc ? <FaAngleDown /> : <FaAngleRight />}
              <span>Review &amp; Notes</span>
            </Card.Header>
            {reviewInfoAcc ? (
              <Card.Body className="reviewBody">
                {dropQuesRequired() && (
                  <div className="postCheckActions">
                    <PostCheckActions
                      setIsDropRiserGood={setIsDropRiserGood}
                      setSeleReasonType={setSeleReasonType}
                      setSeleDropLength={setSeleDropLength}
                      setSeleDropResistance={setSeleDropResistance}
                      allReasons={allReasons}
                      hsiFailCond={hsiFailCondition}
                    />
                  </div>
                )}
                <div className="reviewActions">
                  <p>Please provide your notes (if any) before submission.</p>
                  <div className="certiInputCont">
                    <div>
                      <label>
                        Technician Notes{" "}
                        <span className="taCaption">(1000 chars max)</span>
                      </label>
                      <textarea
                        name="techNotes"
                        rows={4}
                        cols={50}
                        maxLength={1000}
                        style={{ resize: "none" }}
                        onChange={(e) => setTechNote(e.target.value)}
                      />
                    </div>
                    <label>
                      Reviewed with customer
                      <span className="mandatoryAst"> *</span>
                    </label>
                    <DropdownButton
                      title={seleCustReview}
                      onSelect={(e: any) => setSeleCustReview(e)}
                      size="sm"
                      className="custReviewDd"
                      id="custReviewDd"
                    >
                      <Dropdown.Item key={0} eventKey="Yes">
                        Yes
                      </Dropdown.Item>
                      <Dropdown.Item key={1} eventKey="No">
                        No
                      </Dropdown.Item>
                    </DropdownButton>
                    <FormSection
                      isPJISubmitted={isPJISubmitted}
                      workOrderId={certiData?.data?.workOrderId}
                      //isIECSubmitted={isIECSubmitted}
                      formHistoryData={formHistoryData}
                    />
                  </div>
                </div>
              </Card.Body>
            ) : (
              <></>
            )}
          </section>
        </div>
        <div className="certiStepBtnCont">
          <button className="certiCancelBtn" onClick={() => handleCancel()}>
            Cancel
          </button>
          <button
            className="certiPrevBtn"
            onClick={() => handlePrev()}
            disabled={currentStep == 1 ? true : false}
          >
            <FaArrowLeft />
            <span>Previous</span>
          </button>
          {(hsiFailCondition || wifiFailCondition) && (
            <button
              className="certiSubmitBtn"
              onClick={() => setShowFlag(true)}
              disabled={!enableSubmit}
            >
              <span>Preview</span>
            </button>
          )}
          <button
            className="certiSubmitBtn"
            onClick={() => onSubmitCerti()}
            disabled={!enableSubmit}
          >
            <span>Submit</span>
          </button>
        </div>
      </div>
      <FullScreenPopUp
        open={openHsiCerti}
        handleClose={() => {
          setOpenHsiCerti(false);
        }}
        childComponent={<ViewHSICertificationPage certiData={certiData} />}
        pageName="Whole Home Certification Details"
      />
      <FullScreenPopUp
        open={openWifiCerti}
        handleClose={() => {
          setOpenWifiCerti(false);
        }}
        childComponent={<ViewWifiCertificationPage certiData={certiData} />}
        pageName="WiFi Certification Details"
      />
      <ConfirmPopUp
        showVal={showPOPUp}
        headerText="WARNING"
        bodyHeadText={labels.SCQAM_QUESTION}
        bodyText="This certification will fail because of impairment on SC-QAMs" //label
        cancelText="No"
        confirmText="Yes"
        handleConfirm={() => handleConfirm()}
        handleClose={() => setShowPOPUp(false)}
      />
      <CustomPopUp
        showVal={showFlag}
        headerText={labels.Email_Preview.toUpperCase()}
        bodyElement={
          <PrepareEmailFormatCert
            body={
              <DisplayEmailPreviewCert
                woID={isNull(certiData?.data?.workOrderId)}
                woType={isNull(changeOrderTypeValue(orderCategory))}
                techId={techData?.techId}
                firmName={techData?.firmName}
                techNotes={techNote}
                failedCodewords={hsiCertiData?.failedCodewords}
                wifiCertiDetails={wifiCertiData}
                hsiFailCondition={hsiFailCondition}
                wifiFailCondition={wifiFailCondition}
                allFailedAttributes={allFailedAttributes}
              />
            }
            hsiFailCondition={hsiFailCondition}
            wifiFailCondition={wifiFailCondition}
            techName={techData?.lanId}
          />
        }
        handleClose={() => setShowFlag(false)}
      />
    </div>
  );
};

export default ReviewCerti;
