import { POD } from "../../../../models/account/diagnostics/pods/POD";
import {
  PODS_BEGIN,
  PODS_FAILURE,
  PODS_SUCCESS,
  PODS_RESET,
  PODSActionTypes,
} from "../../../../types/account/diagnostics/pods/Pods";

export function podsBegin(): PODSActionTypes {
  return {
    type: PODS_BEGIN,
  };
}

export function podsSuccess(pods: POD| POD[]): PODSActionTypes {
  return {
    type: PODS_SUCCESS,
    payload: pods,
  };
}

export function podsFailure(error: Error): PODSActionTypes {
  return {
    type: PODS_FAILURE,
    payload: error,
  };
}
export function ResetPODS(): PODSActionTypes {
  return {
    type: PODS_RESET,
  };
}
