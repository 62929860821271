export default Object.freeze({
  nodeUri: "/node?nodeId=",
  gponNodeUri: "/node?gponId=",
  emtaUri: "/node/emta?nodeId=",
  ecmUri: "/node/ecm?nodeId=",
  ecmNeighUri: "/node/ecm?macAddress=",
  stbUri: "/node/stb?nodeId=",
  stbNeighUri: "/node/stb?macAddress=",
  // oltUri: "/node?oltName=",
  oltUri: "/ponOlt=",
  neighbourhoodUri: "/node/neighbourhood",
  nodePrefix: "N",
  gNodePrefix: "GN",
  emtaPrefix: "EMTA",
  ecmPrefix: "ECM",
  stbPrefix: "STB",
  oltPrefix: "OLT",
  neighbourhoodPrefix: "NEIGHBOURHOOD",
  nodeMultiUri: "/multiDevice/node?nodeId=",
  neighbourhoodMultiUri: "/multiDevice/neighbourhood",
});

const CM = {
  key: "cm",
  label: "CM",
  allowedSearch: ["accountNumber", "macAddress", "tn"],
  searchMessage: "Search by CBP, FA ID, MAC, IHP TN...",
};
const ONT = {
  key: "ont",
  label: "ONT",
  allowedSearch: ["ontSn"],
  searchMessage: "Search by ONT SN...",
};
const EMTA = {
  key: "emta",
  label: "EMTA",
  allowedSearch: ["accountNumber", "macAddress", "tn"],
  searchMessage: "Search by ACCT#, MAC, TN...",
};
const STB = {
  key: "stb",
  label: "STB",
  allowedSearch: ["macAddress", "ipv4","accountNumber"],
  searchMessage: "Search by MAC, IP Address...",
};
const ECM = {
  key: "ecm",
  label: "ECM",
  allowedSearch: ["macAddress", "accountNumber"],
  searchMessage: "Search by MAC...",
};

export const singleDeviceSearchConfig: any = {
  cm: CM,
  ont: ONT,
  emta: EMTA,
  stb: STB,
  ecm: ECM,
};
export const usemultiSearchConfig: any = {
  level: ["IP Ping", "TraceRoute"],
};
export const multiDeviceSearchConfig: any = {
  docsis: {
    area: ["node", "household", "neighbourhood", "electroline", "cmts", "f2"],
    node: {
      label: "NODE/SMT",
      devices: [CM, EMTA, ECM, STB],
      allowedSearch: ["nodeId"],
      searchMessage: "Search By Node ID, SMT ID...",
      level: ["full", "full w/o cw", "basic"],
    },
    neighbourhood: {
      label: "Neighbourhood",
      devices: [CM, EMTA, ECM, STB],
      allowedSearch: ["macAddress"],
      searchMessage: "Search By MAC Address...",
      level: ["full w/o cw", "basic"],
    },
    household: {
      label: "Household",
      devices: [CM, EMTA, ECM, STB],
    },

    electroline: {
      label: "ELECTROLINE",
      allowedSearch: ["nodeId"],
      searchMessage: "Search By Non-RPHY Node ID...",
    },
    f2: {
      label: "F2",
      allowedSearch: ["nodeId"],
      searchMessage: "Search By Node ID...",
    },
    cmts: {
      label: "CMTS",
      allowedSearch: ["macAddress", "ipv4"],
      searchMessage: "Search By CMTS IP Address...",
    },
  },
  gpon: {
    area: ["gnode", "olt", "household"],
    gnode: {
      label: "NODE/SMT",
      devices: [ONT],
      allowedSearch: ["gponId"],
      searchMessage: "Search By Node ID, SMT ID...",
    },
    olt: {
      label: "OLT",
      allowedSearch: ["oltName"],
      searchMessage: "Search By OLT Name...",
    },
    household: {
      label: "Household",
      devices: [ONT],
    },
  },
};

export const multiDeviceFilterConfig: any = (isEmtaSwitch = true) => {
  let multiDeviceConfig = {
    ...multiDeviceSearchConfig,
    docsis: {
      ...multiDeviceSearchConfig["docsis"],
      node: { ...multiDeviceSearchConfig["docsis"]?.["node"], devices: [CM] },
      neighbourhood: {
        ...multiDeviceSearchConfig["docsis"]?.["neighbourhood"],
        devices: [CM],
      },
      household: {
        ...multiDeviceSearchConfig["docsis"]?.["household"],
        devices: [CM],
      },
    },
  };
  if (isEmtaSwitch) {
    multiDeviceConfig = multiDeviceSearchConfig;
  }
  return multiDeviceConfig;
};

export const externalLinksConfig: any = [
  { label: "AXONIFY", url: "https://rogers2.axonify.com/training/auth.html" },
  { label: "CLICK", url: "https://fse-ca.cloud.clicksoftware.com/#/welcome" },
  { label: "CONTINUITY", url: "https://continuity.rogers.com/ContinuitySPS/" },
  {
    label: "ELECTROLINE",
    url: "http://etsgateway01-dyas.osn.wireless.rogers.com/electroline/cgi-bin/electroline_shub_redir.cgi",
  },
  {
    label: "F2",
    url: "http://snmp-prod-dyas.osn.wireless.rogers.com/graph/mainframeset.html",
  },
  {
    label: "FAST TRACK",
    url: "https://rogers.plateau.com/learning/user/personal/landOnPortalHome.do?OWASP_CSRFTOKEN=IJM6-0QGX-QT1O-5UN8-M9LX-LTHF-SO2I-289D&fromSF=Y&fromDeepLink=true&pageID=",
  },
  {
    label: "FSC CHAT",
    url: "https://chatapps.rogers.com/secure/ucx-agent-chat/home/field-technician-support",
  },
  {
    label: "GO360",
    url: "http://sngo3601prd.rci.rogers.ca/go360rogersviewer/loginpage.jsp",
  },
  { label: "HRCONNECT", url: "https://rogers2.service-now.com/hrportal" },
  // {
  //   label: "MAINTENANCE ESCALATION",
  //   url: "https://apps.powerapps.com/play/e/default-0ab4cbbf-4bc7-4826-b52c-a14fed5286b9/a/6a86434a-1fb2-4b8b-9d13-42d30c844efb?tenantId=0ab4cbbf-4bc7-4826-b52c-a14fed5286b9&source=portal&screenColor=rgba(251%2C%20101%2C%2060%2C%201)",
  // },
  // {
  //   label: "MAINTENANCE SCHEDULE",
  //   url: "https://rcirogers.sharepoint.com/:x:/r/sites/FieldOperationsTorontoScarborough/Schedules/_layouts/15/Doc.aspx?sourcedoc=%7B90307286-89EB-4EAE-9480-DA6ADE99D379%7D&file=2023%20GTA%20Maintenance%20Schedule%20-%20Live.xlsx&action=default&mobileredirect=true&cid=769bebf4-8b1c-45d8-b80d-abd0386f2aad",
  // },
  { label: "NIKA", url: "https://rogers.nikamanager.com/app/Login.asp" },
  // { label: "NOISE REFERRAL", url: "opens NOISE referral form" },
  { label: "NXT", url: "https://nxt.oss.rogers.com/nxt-ui/#/login" },
  {
    label: "SPECTRUM MODIFICATION REQUEST",
    url: "https://forms.office.com/pages/responsepage.aspx?id=v8u0CsdLJki1LKFP7VKGuZrMQbW7Sx5Mu1yEZ1uyUJdUNEgxT1ZaOEI2V1gzWFNXVUtZQ1c3TkMwRC4u",
  },
  {
    label: "OMD TICKET REQUEST",
    url: "https://apps.powerapps.com/play/e/default-0ab4cbbf-4bc7-4826-b52c-a14fed5286b9/a/6a86434a-1fb2-4b8b-9d13-42d30c844efb?tenantId=0ab4cbbf-4bc7-4826-b52c-a14fed5286b9&source=portal&screenColor=rgba(251%2C%20101%2C%2060%2C%201)",
  },
  { label: "REMEDY", url: "https://remedy.oss.rogers.com/arsys" },
  // {
  //   label: "ROAD RESTRICTIONS",
  //   url: "https://www.toronto.ca/services-payments/streets-parking-transportation/road-restrictions-closures/restrictions-map/#location=&lat=&lng=",
  // },
  {
    label: "RPD DASHBOARD",
    url: "http://etsgateway01-dyas.osn.wireless.rogers.com/hfcops02/RPD/main.php",
  },

  {
    label: "SAM34",
    url: "https://rcirogers.sharepoint.com/teams/TECH-AccessNetworks/wirelinerecords/Reports/Forms/AllItems.aspx?RootFolder=%2Fteams%2FTECH%2DAccessNetworks%2Fwirelinerecords%2FReports%2FSAM%5FReports&View=%7B1EF1CB50%2DC608%2D40C4%2DA0EA%2DFB0FACAAF35E%7D",
  },
  { label: "SCTE", url: "https://www.scte.org/accounts/login/?next=/" },
  {
    label: "SICK FORM",
    url: "https://forms.office.com/Pages/ResponsePage.aspx?id=v8u0CsdLJki1LKFP7VKGufhlYVnUjtlCheAamnCOMsdUQk5UMjk3QzZTRE4wMjhYWkoxSUZXMkFJTS4u",
  },
  {
    label: "TECH NOTES",
    url: "https://rcirogers.sharepoint.com/teams/TECH-AccessNetworks/Access_Network_Engineering/accessnetworkeng/Pages/Tech%20Notes.aspx",
  },
  {
    label: "TECH ORDERING PORTAL",
    url: "https://www.techportal-rogers.com/",
  },
  { label: "TECHMPA", url: "http://techmpa.rogers.com/" },
  {
    label: "TICKETS",
    url: "https://remedy.oss.rogers.com/arsys/forms/remedy-app/COM:TicketDisplayHome/User+View/?cacheid=c76ce3bf",
  },
  { label: "TIMEKEEPER", url: "https://rogerscommunications.kronos.net/" },
  // {
  //   label: "UNIFORM",
  //   url: "https://can01.safelinks.protection.outlook.com/?url=https%3A%2F%2Frcirogers.sharepoint.com%2F%3Af%3A%2Fs%2FFieldOperationsTorontoScarborough%2FDYFiles%2FEoBASijOGdBMilRhuIDoLIQBxzOxHrzsqNJwmRONgLNjZA%3Fe%3D5%253aTLCOm3%26at%3D9&data=05%7C01%7CGordon.Reilly%40rci.rogers.com%7C2685f736db104bae217a08db053cba58%7C0ab4cbbf4bc74826b52ca14fed5286b9%7C0%7C0%7C638109530596873699%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=TMyE3lBnXhwePd5NWM85lrhPFPfkSyOIRuwguGahW%2BA%3D&reserved=0",
  // },
  {
    label: "UPLIFT OUTAGE REPORTING",
    url: "https://forms.microsoft.com/Pages/ResponsePage.aspx?id=v8u0CsdLJki1LKFP7VKGuThU45p_jbJHu6cIGv2RQA1UNkJBUkdFTjg1Tk1IRFAyUkxBVUxUUVpQMi4u",
  },
  {
    label: "ECOMPLIANCE",
    url: "https://my.ecompliance.com/MobileInspections",
  },
  {
    label: "NODE FAULTS",
    url: "http://ipdrapp01-dyas-oss.osn.wireless.rogers.com/nodefault/node_fault.html",
  },
  {
    label: "TECH INSIGHT TRAINING",
    url: "https://rcirogers.sharepoint.com/sites/tim/SitePages/TIM%20Training.aspx",
  },
];
