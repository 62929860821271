import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { NetworkConfig } from "../NetworkConfig";
import { NodeInfoDetailsState } from "../../../store/states/node/nodeinfo/NodeInfoDetailsState";
import {
  NodeInfoDetailsBegin,
  NodeInfoDetailsFailure,
  NodeInfoDetailsSuccess,
} from "../../../store/actions/node/nodeinfo/NodeInfoDetailsAction";
import { NodeSummary } from "../../../store/models/node/nodeinfo/NodeSummary";
import { NodeAveragesData } from "../../../store/models/node/telemetry/NodeAverages";
import {
  nodeAveragesBegin,
  nodeAveragesFailure,
  nodeAveragesSuccess,
} from "../../../store/ducksPattern/nodeAveragesStatus";
import { setActiveMainSearchType } from "../../../topology/store/slices/topoGeneral";
import appConst from "../../../topology/constants/appConst";
import { UniversalSearchTypes } from "../../../topology/store/reduxTypes";

export default class NodeInfoDetailsClient {
  static getInfoNodeDetails =
    (
      nodeId: string
    ): ThunkAction<void, NodeInfoDetailsState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.NETWORK_SERVICE_URL + "diag/network/nodeConfig",
        params: { shubid: nodeId },
      };
      dispatch(NodeInfoDetailsBegin());
      dispatch(
        setActiveMainSearchType({
          type: appConst.DOCSIS_NODE as UniversalSearchTypes,
        })
      );

      HttpClient.get<NodeSummary[]>(httpParameters)
        .then((nodesummeryvalue) => {
          dispatch(NodeInfoDetailsSuccess(nodesummeryvalue));

          return nodesummeryvalue;
        })
        .catch((error) => {
          dispatch(NodeInfoDetailsFailure(error));
          return error;
        });
    };
  static getNodeDetailsByPostalCode =
    (
      postalCode: string
    ): ThunkAction<void, NodeInfoDetailsState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.NETWORK_SERVICE_URL + "diag/network/nodeConfig",
        params: { postalcode: postalCode },
      };
      dispatch(NodeInfoDetailsBegin());

      HttpClient.get<NodeSummary[]>(httpParameters)
        .then((postalCodeValues) => {
          dispatch(NodeInfoDetailsSuccess(postalCodeValues));
          return postalCodeValues;
        })
        .catch((error) => {
          dispatch(NodeInfoDetailsFailure(error));
          return error;
        });
    };

  static getNodeAvergesStatus =
    (
      data: any,
      nodeId: any
    ): ThunkAction<void, NodeAveragesData, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.NETWORK_SERVICE_URL +
          "diag/network/" +
          nodeId +
          "/nodeAveragesStatus",
      };

      dispatch(nodeAveragesBegin());

      HttpClient.post<any>(httpParameters, data)
        .then((res) => {
          dispatch(nodeAveragesSuccess(res));
          return res;
        })
        .catch((error) => {
          dispatch(nodeAveragesFailure(error));
          return error;
        });
    };
}
