import { useEffect } from "react";
import appConst from "../../../constants/appConst";
import { GetNodeSiteDetailsProps } from "../../../util/reduxFunctions/getTopologyState";

interface Props {
  gMap?: any;
}

const RFDrops = ({ gMap }: Props) => {
  const { activeNode, allNodeData } = GetNodeSiteDetailsProps();
  const rfDropsData = activeNode?.rfDrop?.data;

  useEffect(() => {
    if (
      gMap &&
      rfDropsData !== undefined &&
      rfDropsData?.features?.length > 0
    ) {
      gMap?.data.addGeoJson(rfDropsData);
    }
  }, [rfDropsData]);

  useEffect(() => {
    if (
      gMap &&
      allNodeData &&
      rfDropsData !== undefined &&
      rfDropsData?.features?.length > 0
    ) {
      let allFeatures = [] as any;
      allNodeData?.map(
        (e: any) =>
          e?.rfDrop?.data?.features &&
          allFeatures.push(...e?.rfDrop?.data?.features)
      );
      const tempGeoJson = {
        type: appConst?.FeatureCollection,
        features: allFeatures,
      };
      gMap?.data.addGeoJson(tempGeoJson);
    }
  }, [gMap]);

  return <></>;
};
export default RFDrops;
