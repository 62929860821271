import { Card, CardContent, CardHeader, IconButton } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CustAccICon from "../../resources/images/icons/custAccIcon.svg";

import AppLabels from "../../constants/App_Labels";
import { GLOBAL_CONST } from "../../constants/Global";
import "../../design-tokens/workorder/WorkOrderCard.scss";
import {
  GetNodeInfoDetails,
  GetTechInfo,
  GetWorkOrderDetails,
} from "../../get-api/GetResponse";
import AccDetailsClient from "../../network/httpclient/accountInfo/AccDetailsClient";
import CallAssigned from "../../resources/images/icons/Call Assigned.svg";
import Destination from "../../resources/images/icons/Destination.svg";
import SMT from "../../resources/images/icons/SMT.svg";
import Account from "../../resources/images/icons/account.svg";
import Appointment from "../../resources/images/icons/calendar-outline.svg";
import CallCreated from "../../resources/images/icons/call created.svg";
import CustomerIcon from "../../resources/images/icons/customer.svg";
import Node from "../../resources/images/icons/node_black.svg";
import Phone from "../../resources/images/icons/phone.svg";
import SpecialInstructions from "../../resources/images/icons/special instructions.svg";
import thriveLogo from "../../resources/images/thriveLogo.png";
import { HistoryReset } from "../../store/actions/account/forms/FormDataAction";
import {
  SetOrderCategroy,
  SetWorkOrderId,
  SetWorkType,
} from "../../store/ducksPattern/TechProfile";
import { getWorkOrderDetails } from "../../store/slices/workOrder/workOrderDetails";
import PreJobInspectionScreen from "../forms/PJIForm/PreJobInspectionScreen";
import ExceptionBox from "../reusable/ExceptionBox";
import {
  getMTWorkOrderStatusColor,
  getWOStatusSpan,
  isNull,
  openNewWindow,
} from "../reusable/Util";
import WorkOrderPopUp from "./WorkOrderPopUp";

import { setSource } from "../../store/slices/redirectSource";
import { resetWOStatusUpdate } from "../../store/slices/workOrder/workOrderStatusUpdate";

const WorkOrderCard = (props: any) => {
  const { wOList, techData, techName, wOType, refreshData } = props;
  const dispatch = useDispatch();
  const { currNodedata } = GetNodeInfoDetails();
  const labels = AppLabels();
  const woData = GetWorkOrderDetails()?.data;
  const [acc, setAcc] = useState("");
  const [preChAccNum, setPreChAccNum] = useState("");
  const { isTechnicianRoleAvailable, isOnlySTRoleAvailable,isMaintenanceRoleAvailable } = GetTechInfo();
  const [headerText, setHeaderText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { wOIsLoading, wOStatus, redirectSource, searchAccStatus, data } =
    useSelector(
      (state: any) => ({
        wOIsLoading: state.workOrdersList.isLoading,
        wOStatus: state.workOrdersList.status,
        redirectSource: state.slices.redirectSource.source,
        searchAccStatus: state.AccountState.AccountDetails.status,
        data: state.AccountState.AccountDetails.data,
      }),
      shallowEqual
    );
  const [popUpFlag, setPopUpFlag] = useState(false);
  const tryAccNum = useRef(false);
  const isEnrouteFound = useRef(false);
  const scannedOnce = useRef(false);

  useEffect(() => {
    const enrouteAcc: any = wOList?.find(
      (e: { status: string }) => e?.status === labels.Enroute
    );
    const onsiteAcc: any = wOList?.find(
      (e: { status: string }) => e?.status === labels.Onsite
    );
    if (enrouteAcc && !onsiteAcc && redirectSource === "") {
      navigateForPrecheck(enrouteAcc);
      isEnrouteFound.current = true;
    }
  }, [wOList]);

  useEffect(() => {
    if (searchAccStatus !== "OK" && tryAccNum.current === false) {
      if (
        searchAccStatus === "ERROR" &&
        data === undefined &&
        preChAccNum.length === 9
      ) {
        dispatch(AccDetailsClient.getAccDetails(preChAccNum, "accountNumber"));
        tryAccNum.current = true;
      }
    }
  }, [searchAccStatus]);

  useEffect(() => {
    if (!wOIsLoading && isOnlySTRoleAvailable) {
      setTimeout(() => {
        if (isEnrouteFound.current === false && redirectSource === "") {
          refreshData();
        }
      }, 60000);
    }
  }, [wOIsLoading]);

  const navigateForPrecheck = (enrouteAcc: any) => {
    if (
      wOStatus === "OK" &&
      !wOIsLoading &&
      wOList &&
      !scannedOnce.current &&
      isOnlySTRoleAvailable
    ) {
      scannedOnce.current = true;
      searchAcc(
        enrouteAcc?.accountNumber,
        enrouteAcc?.id,
        enrouteAcc?.workType,
        enrouteAcc?.orderCategory,
        "WO Precheck"
      );
    }
  };

  const searchAcc = (
    accNum: string,
    woNum: string,
    woType: string,
    orderCategory: string,
    source: string
  ) => {
    dispatch(SetWorkType(woType));
    dispatch(HistoryReset());
    dispatch(SetWorkOrderId(woNum));
    dispatch(SetOrderCategroy(orderCategory));
    dispatch(getWorkOrderDetails(woNum));
    if (accNum !== undefined && accNum?.length === 9) {
      dispatch(AccDetailsClient.getAccDetails(accNum, "cbpId"));
    } else if (accNum !== undefined && accNum?.length === 12) {
      dispatch(AccDetailsClient.getAccDetails(accNum, "accountNumber"));
    }
    if (source === "WO Precheck") {
      dispatch(setSource(source));
      setPreChAccNum(accNum);
    }
  };

  const searchNode = (shubId: string, woNum: string) => {
    if (shubId !== undefined && shubId?.length === 6) {
      // currNodedata !== undefined
      //   ?
      openNewWindow(`/#/node?nodeId=${shubId}`);
      // : dispatch(NodeInfoDetailsClient.getInfoNodeDetails(shubId));
    }
  };

  const titleWithJobId = (woId: string, jobId: string) => {
    return (
      <>
        <span className="left-title">#{woId}</span>
        <span className="right-title">{jobId}</span>
      </>
    );
  };
  const onClosePopup = () => {
    setShowModal(false);
  };
  const setShowPJI = (woId: string, accNum: string, status: string) => {
    dispatch(SetWorkOrderId(woId));
    dispatch(HistoryReset());
    setShowModal(true);
    setAcc(accNum);
    // if (accNum !== undefined && accNum?.length === 9) {
    //   dispatch(AccDetailsClient.getAccDetails(accNum, "cbpId"));
    // } else if (accNum !== undefined && accNum?.length === 12) {
    //   dispatch(AccDetailsClient.getAccDetails(accNum, "accountNumber"));
    // }
  };
  const statusOnClick = (event: any, wo: any) => {
    event?.stopPropagation();
    setHeaderText(wo?.status?.toUpperCase());
    if (woData?.id !== wo?.id) dispatch(getWorkOrderDetails(wo?.id));
    dispatch(resetWOStatusUpdate());
    setPopUpFlag(true);
  };

  return (
    <>
      <div className={wOList?.length > 0 ? "workOrderList" : ""}>
        {wOList !== undefined && wOList?.length !== 0 ? (
          wOList?.map((wo: any, i: number) => {
            const isMTFlag =
              wo?.workOrderType !== undefined &&
              GLOBAL_CONST.maintTech.includes(wo?.workOrderType)
                ? true
                : false;
            wOType(isMTFlag);
            return (
              <Card className="workOrderCard" key={wo?.id + i * i}>
                <CardHeader
                  avatar={
                    <img
                      src={CustAccICon}
                      alt="accIcon"
                      className="cardHeaderImg"
                    />
                  }
                  title={
                    isMTFlag ? (
                      titleWithJobId(wo?.id, wo?.jobCode)
                    ) : (
                      <>#{wo?.id}</>
                    )
                  }
                  action={
                    (isTechnicianRoleAvailable || isMaintenanceRoleAvailable) && (
                      <IconButton
                        onClick={() =>
                          setShowPJI(wo?.id, wo?.accountNumber, wo?.status)
                        }
                      >
                        <img
                          src={thriveLogo}
                          className="img"
                          alt={labels.PJI}
                        />
                      </IconButton>
                    )
                  }
                />

                <div
                // title={
                //   isMTFlag
                //     ? "See work order details and update the status"
                //     : ""
                // }
                // onClick={(event) => {
                //   isMTFlag && statusOnClick(event, wo);
                // }}
                >
                  {isMTFlag
                    ? getMTWorkOrderStatusColor(wo?.jobCode, wo?.status)
                    : getWOStatusSpan(wo?.status)}
                </div>

                <CardContent
                  onClick={() => {
                    !isMTFlag &&
                      searchAcc(
                        wo?.accountNumber,
                        wo?.id,
                        wo?.workType,
                        wo?.orderCategory,
                        ""
                      );
                  }}
                >
                  <table className="cardInfo">
                    <tbody>
                      {isNull(wo?.accountNumber) !== "" &&
                        isNull(wo?.accountNumber) !== "N/A" && (
                          <tr>
                            <td>
                              <img
                                src={Account}
                                alt="Account"
                                className="cardHeaderImg"
                              />
                            </td>
                            {isMTFlag ? (
                              <td>
                                {
                                  <a
                                    className="smt"
                                    onClick={() =>
                                      searchAcc(
                                        wo?.accountNumber,
                                        wo?.id,
                                        wo?.workType,
                                        wo?.orderCategory,
                                        ""
                                      )
                                    }
                                  >
                                    {isNull(wo?.accountNumber)}
                                  </a>
                                }
                              </td>
                            ) : (
                              <td>{isNull(wo?.accountNumber)}</td>
                            )}
                          </tr>
                        )}

                      {isNull(wo?.shubId) !== "" &&
                        isNull(wo?.shubId) !== "N/A" &&
                        isMTFlag && (
                          <tr>
                            <td>
                              <img
                                src={Node}
                                alt="Node"
                                className="cardHeaderImg"
                              />
                            </td>
                            <td>
                              {" "}
                              {
                                <a
                                  className="smt"
                                  onClick={() =>
                                    isMTFlag && searchNode(wo?.shubId, wo?.id)
                                  }
                                >
                                  {isNull(wo?.shubId)}
                                </a>
                              }
                            </td>
                          </tr>
                        )}
                      {isNull(wo?.smtId) !== "" &&
                        isNull(wo?.smtId) !== "N/A" &&
                        isMTFlag && (
                          <tr>
                            <td>
                              <img
                                src={SMT}
                                alt="SMT"
                                className="cardHeaderImg"
                              />
                            </td>
                            <td>
                              {
                                <a
                                  className="smt"
                                  onClick={() =>
                                    isMTFlag && searchNode(wo?.smtId, wo?.id)
                                  }
                                >
                                  {isNull(wo?.smtId)}
                                </a>
                              }
                            </td>
                          </tr>
                        )}

                      {isNull(wo?.address) !== "" &&
                        isNull(wo?.address) !== "N/A" && (
                          <tr>
                            <td>
                              <img
                                src={Destination}
                                alt="Address"
                                className="cardHeaderImg"
                              />
                            </td>
                            <td>{isNull(wo?.address)}</td>
                          </tr>
                        )}

                      {isNull(wo?.customerName) !== "" &&
                        isNull(wo?.customerName) !== "N/A" &&
                        isMTFlag && (
                          <tr>
                            <td>
                              <img
                                src={CustomerIcon}
                                alt="Customer"
                                className="cardHeaderImg"
                              />
                            </td>
                            <td>{isNull(wo?.customerName)}</td>
                          </tr>
                        )}

                      {isNull(wo?.customerContactNumber) !== "" &&
                        isNull(wo?.customerContactNumber) !== "N/A" && (
                          <tr>
                            <td>
                              <img
                                src={Phone}
                                alt="Phone"
                                className="cardHeaderImg"
                              />
                            </td>
                            <td>{isNull(wo?.customerContactNumber)}</td>
                          </tr>
                        )}
                      {isNull(wo?.appointmentTime) !== "" &&
                        isNull(wo?.appointmentTime) !== "N/A" &&
                        !isMTFlag && (
                          <tr>
                            <td>
                              <img
                                src={Appointment}
                                alt="Appointment"
                                className="cardHeaderImg"
                              />
                            </td>
                            <td>{isNull(wo?.appointmentTime)}</td>
                          </tr>
                        )}

                      {isNull(wo?.specialInstructions) !== "" &&
                        isNull(wo?.specialInstructions) !== "N/A" &&
                        wo?.specialInstructions?.length > 0 &&
                        isMTFlag && (
                          <tr>
                            <td>
                              <img
                                src={SpecialInstructions}
                                alt="097"
                                className="cardHeaderImg"
                              />
                            </td>
                            <td>
                              {isNull(wo?.specialInstructions).length > 90
                                ? wo?.specialInstructions.substring(0, 90)
                                : isNull(wo?.specialInstructions)}
                            </td>
                          </tr>
                        )}

                      {isNull(wo?.callCreated) !== "" &&
                        isNull(wo?.callCreated) !== "N/A" &&
                        isMTFlag && (
                          <tr>
                            <td>
                              <img
                                src={CallCreated}
                                style={{ width: 41, height: 35 }}
                                alt="CallCreated"
                                className="cardHeaderImg"
                              />
                            </td>
                            <td>{isNull(wo?.callCreated)}</td>
                          </tr>
                        )}

                      {isNull(wo?.callAssigned) !== "" &&
                        isNull(wo?.callAssigned) !== "N/A" &&
                        isMTFlag && (
                          <tr>
                            <td>
                              <img
                                src={CallAssigned}
                                style={{ width: 41, height: 35 }}
                                alt="CallAssigned"
                                className="cardHeaderImg"
                              />
                            </td>
                            <td>{isNull(wo?.callAssigned)}</td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </CardContent>
              </Card>
            );
          })
        ) : (
          <div className="diagErrorCont">
            <ExceptionBox
              headerText="No Work Orders to display."
              bodyText={`The ones Assigned to ${techData?.name} will reflect here.`}
              primaryBtn={false}
              secBtn={false}
            />
          </div>
        )}
      </div>
      <WorkOrderPopUp
        wOList={wOList}
        techData={techData}
        showVal={popUpFlag}
        setShowVal={(val: boolean) => setPopUpFlag(val)}
        headerText={headerText}
        handleClose={() => setPopUpFlag(false)}
        refreshData={refreshData}
      />
      {showModal && (
        <Modal
          show={showModal}
          onHide={onClosePopup}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="searchPopHead">
            <header>{labels.PreJob_Inspection_Form.toUpperCase()}</header>
            <button className="sPopUpClose" onClick={onClosePopup}>
              <IoClose color="white" />
            </button>
          </Modal.Header>
          <Modal.Body className="confirmBody">
            <PreJobInspectionScreen setShowModal={setShowModal} acc={acc} />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default WorkOrderCard;
