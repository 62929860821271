import CustomAccordion from "../reusable/CustomAccordion";
import CustomPopOver from "../reusable/CustomPopOver";
import {
  getBaselineRatios,
  getCorrectedsDelta,
  checkDeltaStatus,
  getUnerroredsDelta,
  hzToMHz,
  isNull,
} from "../reusable/Util";

const CWUpstream = (props: any) => {
  const { cwUpStreamData, currSeconds, firstCWInfo } = props;

  return (
    <>
      {cwUpStreamData !== undefined && cwUpStreamData?.length !== 0 ? (
        <CustomAccordion
          accTitle={"Upstream SC-QAM Channels".toUpperCase()}
          accBodyElement={
            <>
              <table className="dataTableDetails">
                <thead>
                  <tr>
                    <th>{"frequency".toUpperCase()}</th>
                    <CustomPopOver
                      position="top"
                      popOverData="Total uncorrectable codewords/total codewords prior to the current polling session"
                      originComp={<th>{"Baseline Errors".toUpperCase()}</th>}
                      index="popOver"
                    />
                    <CustomPopOver
                      position="top"
                      popOverData="Count of unerrored codewords passed during the interval"
                      originComp={<th>{"unerrored delta".toUpperCase()}</th>}
                      index="popOver"
                    />
                    <CustomPopOver
                      position="top"
                      popOverData="Count of errored codewords that have been corrected during the interval"
                      originComp={<th>{"corrected delta".toUpperCase()}</th>}
                      index="popOver"
                    />
                    <CustomPopOver
                      position="top"
                      popOverData="Count of errored codewords that were not corrected during the interval"
                      originComp={<th>{"uncorrectables delta".toUpperCase()}</th>}
                      index="popOver"
                    />
                  </tr>
                </thead>
                <tbody>
                  {cwUpStreamData?.map((ch: any, i: number) => {
                    return (
                      <tr key={i}>
                        <td>{isNull(hzToMHz(ch?.frequency).toFixed(1))}</td>
                        <td>
                          {isNull(
                            getBaselineRatios(
                              ch?.unerroreds,
                              ch?.correcteds,
                              ch?.uncorrectables
                            )
                          )}
                        </td>
                        <td>
                          {isNull(
                            getUnerroredsDelta(firstCWInfo, currSeconds, ch)
                          )}
                        </td>
                        <td>
                          {isNull(
                            getCorrectedsDelta(firstCWInfo, currSeconds, ch)
                          )}
                        </td>
                        <td>
                          {isNull(
                            checkDeltaStatus(firstCWInfo, currSeconds, ch)
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          }
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default CWUpstream;
