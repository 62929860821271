import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";

import { ASTBSummary } from "../../../store/models/account/diagnostics/astb/ASTBSummary";

import {
  DecrementApiCallCounter,
  IncrementApiCallCounter,
  UpdateActiveCertiDevices,
} from "../../../store/actions/account/certifications/ActiveCertiDevicesAction";
import {
  DecrementDiagApiCallCounter,
  IncrementDiagApiCallCounter,
  UpdateActiveDiagDevices,
} from "../../../store/actions/account/diagnostics/ActiveDiagDevicesActions";

export default class ASTBClient {
  static getASTBSummary =
    (
      serialNum: string,
      macAddress: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "diag/astb/" +
          macAddress +
          "/summary",
      };

      dispatch(IncrementDiagApiCallCounter(serialNum));
      HttpClient.get<ASTBSummary>(httpParameters)
        .then((ASTBSummary) => {
          dispatch(UpdateActiveDiagDevices(serialNum, "summary", ASTBSummary));
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return ASTBSummary;
        })
        .catch((error) => {
          dispatch(UpdateActiveDiagDevices(serialNum, "summary", error));
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return error;
        });
    };
  static getASTBCertiSummary =
    (
      serialNum: string,
      macAddress: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "diag/astb/" +
          macAddress +
          "/summary",
      };

      dispatch(IncrementApiCallCounter(serialNum));

      HttpClient.get<ASTBSummary>(httpParameters)
        .then((ASTBSummary) => {
          dispatch(UpdateActiveCertiDevices(serialNum, "summary", ASTBSummary));
          dispatch(DecrementApiCallCounter(serialNum));
          return ASTBSummary;
        })
        .catch((error) => {
          dispatch(UpdateActiveCertiDevices(serialNum, "summary", error));
          dispatch(DecrementApiCallCounter(serialNum));
          return error;
        });
    };
}
