import React from "react";
import { useEffect } from "react";

interface Props {
  gMap?: google.maps.Map;
  mduStrandCableData?: any;
}

const MduStrandCable = React.memo(({ gMap, mduStrandCableData }: Props) => {
  useEffect(() => {
    gMap?.data.addGeoJson(mduStrandCableData);
  }, [gMap, mduStrandCableData]);
  return <></>;
});

export default MduStrandCable;
