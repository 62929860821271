import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { FaCheckCircle } from "react-icons/fa";

import NodeTableHead from "./NodeTableHead";
import ErrorIcon from "../../resources/images/icons/ErrorIcon.svg";
import { NodeSummary } from "../../store/models/account/node/NodeSummary";
import NodeClient from "../../network/httpclient/node/NodeClient";
import CustomPopOver from "../reusable/CustomPopOver";
import { getThreshold, isEmptyObject } from "../reusable/Util";

const NodeTable = (props: any) => {
  const { nodesDevices } = useSelector(
    (state: any) => ({
      nodesDevices: state.AccountState.NodesDevices.nodeSummaryList,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const [nodeFlag, setNodeFlag] = useState(true);
  useEffect(() => {
    if (nodesDevices !== undefined && nodesDevices?.length !== 0 && nodeFlag) {
      nodesDevices?.map((d: any) => {
        if (d?.data?.summary?.data === undefined) {
          dispatch(NodeClient.getNodeSummary(d?.serialNumber));
        }
      });
      setNodeFlag(false);
    }
  }, [nodesDevices]);

  const getValueBasedOnStatus = (data: any) => {
    return (
      <td style={{ textAlign: "center" }}>
        {data ? (
          <>
            {data?.status === "Pass" ? (
              <>
                <span className="passLinkState">{data?.value}</span>
                <FaCheckCircle color="#00985f" />
              </>
            ) : (
              <>
                <span className="failLinkState">{data?.value}</span>
                <img style={{ height: "14px" }} src={ErrorIcon} />
              </>
            )}
          </>
        ) : (
          <span>-</span>
        )}
      </td>
    );
  };

  return (
    <div className="neighTableCont">
      <table className="neighTable">
        <NodeTableHead />
        <tbody className="nodeTabBody">
          {nodesDevices?.map((d: NodeSummary) => {
            return (
              <tr>
                <td>{d.homeAddress}</td>
                <td>{d.serialNumber}</td>
                <td>{d.ontModel}</td>
                <td>{d?.data?.summary?.data?.oltName}</td>
                <td>{d?.data?.summary?.data?.ponPort}</td>
                <td>{d?.data?.summary?.data?.lastChange}</td>
                {d?.data?.summary?.data?.operStatus ? (
                  <CustomPopOver
                    popOverData={getThreshold(
                      isEmptyObject(d?.data?.summary?.data?.operStatus)
                    )}
                    originComp={getValueBasedOnStatus(
                      isEmptyObject(d?.data?.summary?.data?.operStatus)
                    )}
                    index="popOver"
                  />
                ) : (
                  <td style={{ textAlign: "center" }}>-</td>
                )}
                {d?.data?.summary?.data?.provStatus ? (
                  <CustomPopOver
                    popOverData={getThreshold(
                      isEmptyObject(d?.data?.summary?.data?.provStatus)
                    )}
                    originComp={getValueBasedOnStatus(
                      isEmptyObject(d?.data?.summary?.data?.provStatus)
                    )}
                    index="popOver"
                  />
                ) : (
                  <td style={{ textAlign: "center" }}>-</td>
                )}
                {d?.data?.summary?.data?.opticalTx ? (
                  <CustomPopOver
                    popOverData={getThreshold(
                      isEmptyObject(d?.data?.summary?.data?.opticalTx)
                    )}
                    originComp={getValueBasedOnStatus(
                      isEmptyObject(d?.data?.summary?.data?.opticalTx)
                    )}
                    index="popOver"
                  />
                ) : (
                  <td style={{ textAlign: "center" }}>-</td>
                )}
                {d?.data?.summary?.data?.opticalRx ? (
                  <CustomPopOver
                    popOverData={getThreshold(
                      isEmptyObject(d?.data?.summary?.data?.opticalRx)
                    )}
                    originComp={getValueBasedOnStatus(
                      isEmptyObject(d?.data?.summary?.data?.opticalRx)
                    )}
                    index="popOver"
                  />
                ) : (
                  <td style={{ textAlign: "center" }}>-</td>
                )}
                <td style={{ textAlign: "center" }}>
                  {d?.data?.summary?.data?.adminStatus}
                </td>
                <td style={{ textAlign: "center" }}>
                  {d?.data?.summary?.data?.opticalDistance}
                </td>
                <td style={{ textAlign: "center" }}>
                  {d?.data?.summary?.data?.etherPortCurrentStatus}
                </td>
                <td style={{ textAlign: "center" }}>
                  {d?.data?.summary?.data?.etherPortOperStatus}
                </td>
                <td style={{ textAlign: "center" }}>
                  {d?.data?.summary?.data?.etherPortAdminStatus}
                </td>
                <td style={{ textAlign: "center" }}>
                  {d?.data?.summary?.data?.etherPortConfigIndicator}
                </td>
                <td>{d?.data?.summary?.data?.swVersion}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default NodeTable;
