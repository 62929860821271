import { FeedbackProfile } from "../../models/feedback/FeedbackProfile";
import {
  FEEDBACK_PROFILE_BEGIN,
  FEEDBACK_PROFILE_SUCCESS,
  FEEDBACK_PROFILE_FAILURE,
  FeedbackProfileActionTypes,
} from "../../types/feedback/FeedbackProfile";

import {
  TECHNICIAN_FEEDBACK_BEGIN,
  TECHNICIAN_FEEDBACK_SUCCESS,
  TECHNICIAN_FEEDBACK_FAILURE,
  TechnicianFeedbackActionTypes,
} from "../../types/feedback/TechnicianFeedback";

export function feedbackProfileBegin():FeedbackProfileActionTypes
{
  return {
    type: FEEDBACK_PROFILE_BEGIN,
  };
}

export function feedbackProfileSuccess(
  feedbackProfile: FeedbackProfile
): FeedbackProfileActionTypes
{
  return {
    type: FEEDBACK_PROFILE_SUCCESS,
    payload: feedbackProfile,
  };
}

export function feedbackProfileFailure(
  error: Error
):  FeedbackProfileActionTypes
{
  return {
    type: FEEDBACK_PROFILE_FAILURE,
    payload: error,
  };
}

export function technicianFeedbackBegin():TechnicianFeedbackActionTypes
{
  return {
    type: TECHNICIAN_FEEDBACK_BEGIN,
  };
}

export function technicianFeedbackSuccess(
  technicianFeedback: FeedbackProfile
): TechnicianFeedbackActionTypes
{
  return {
    type: TECHNICIAN_FEEDBACK_SUCCESS,
    payload: technicianFeedback,
  };
}

export function technicianFeedbackFailure(
  error: Error
):  TechnicianFeedbackActionTypes
{
  return {
    type: TECHNICIAN_FEEDBACK_FAILURE,
    payload: error,
  };
}