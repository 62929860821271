import { NetworkInterfacesData } from "../../../../models/account/diagnostics/cm/NetworkInterfacesData";

export const NETWORK_INTERFACES_BEGIN = "NETWORK_INTERFACES_BEGIN";
export const NETWORK_INTERFACES_SUCCESS = "NETWORK_INTERFACES_SUCCESS";
export const NETWORK_INTERFACES_FAILURE = "NETWORK_INTERFACES_FAILURE";

interface NetworkInterfacesBegin {
  type: typeof NETWORK_INTERFACES_BEGIN;
}

interface NetworkInterfacesSuccess {
  type: typeof NETWORK_INTERFACES_SUCCESS;
  payload: NetworkInterfacesData;
}

interface NetworkInterfacesFailure {
  type: typeof NETWORK_INTERFACES_FAILURE;
  payload: Error;
}

export type NetworkInterfacesActionTypes =
  | NetworkInterfacesBegin
  | NetworkInterfacesSuccess
  | NetworkInterfacesFailure;