import {
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CertiExceptionCont from "../../components/certification/CertiExceptionCont";
import CustomPopUp from "../../components/reusable/CustomPopUp";
import ExceptionBox from "../../components/reusable/ExceptionBox";
import {
  checkNullInTelemetry,
  filterSpecData,
} from "../../components/reusable/NodeUtil";
import { getValueByStrMapping } from "../../components/reusable/Util";
import TelemetryActionMenu from "../../components/telemetry/TelemetryActionMenu";
import AppLabels from "../../constants/App_Labels";
import ConfigConst from "../../constants/ConfigConst";
import AccDetailsClient from "../../network/httpclient/accountInfo/AccDetailsClient";
import { useStbDetailsClient } from "../../network/httpclient/accountInfo/StbDetailsClient";
import LoaderGif from "../../resources/images/loader.webp";
import {
  AccReset,
  SetActiveSubTabIndex,
} from "../../store/actions/account/accountInfo/ActiveAccountAction";
import { GetTelemetryProps } from "../../topology/util/reduxFunctions/getTopologyState";
import MultiDeviceAccTableHead from "./MultiDeviceAccTableHead";
import MultiDeviceONTDataMapping from "./MultiDeviceONTDataMapping";

interface Props {
  tableAttributes: any;
  nodeDevicesList: any;
  onDataChange: any;
  handleDragStart: any;
  handleDragOver: any;
  handleOnDrop: any;
  handleDragEnter: any;
  dragOver: any;
}

const MultiDevicesONTTable = ({
  tableAttributes,
  onDataChange,
  dragOver,
  handleDragEnter,
  handleDragOver,
  handleDragStart,
  handleOnDrop,
  nodeDevicesList,
}: Props) => {
  const telemetryData = MultiDeviceONTDataMapping(nodeDevicesList);
  const tableData = telemetryData?.tableData;
  const labels = AppLabels();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(500);
  const [popUpFlag, setPopUpFlag] = useState(false);
  const [popUpHeader, setPopUpHeader] = useState("");
  const [popUpBody, setPopUpBody] = useState(<div></div>);
  const stbInfo = useStbDetailsClient();
  const topScrollRef = useRef<HTMLDivElement>(null);
  const mainTableRef = useRef<HTMLDivElement>(null);
  const [scrollWidth, setScrollWidth] = useState<number | undefined>(
    mainTableRef.current?.scrollWidth
  );

  /*Properties added for searching feature on Telemetry Table */
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [searchValues, setSearchValues] = useState(new Map<String, String>());
  const [specInfo, setSpecInfo] = useState(new Map<String, Boolean>());
  const macRef = useRef(false);
  const macIdRef = useRef("");
  const modemTypeRef = useRef("");
  const { allDiagIsLoading, modemType } = GetTelemetryProps();
  const dispatch = useDispatch();
  const { accInfo, status } = useSelector(
    (state: any) => ({
      accInfo: state.AccountState.AccountDetails?.data,
      status: state.AccountState.AccountDetails.status,
    }),
    shallowEqual
  );

  const [openSnackBar, setOpenSnackBar] = useState(false);

  /* debounce the resize handling to prevent re-rendering */
  function debounce(fn: Function, ms: number) {
    let timer: any;
    return (_: any, ...args: any) => {
      clearTimeout(timer);
      timer = setTimeout((_) => {
        timer = null;
        fn.apply(Function, args);
      }, ms);
    };
  }

  const isModemLoaded = (cmMac: any) => {
    let modem: any = nodeDevicesList?.find((m: any) => m?.macAddress === cmMac);
    return modem?.deviceDiag?.data !== undefined; //added for streaming
  };

  const navigateToAcc = useCallback(
    (modemtype?: any) => {
      if (accInfo?.accountNumber !== undefined) {
        macRef.current = false;
        let newWindow =
          macIdRef.current?.trim() !== ""
            ? modemTypeRef.current === "EMTA"
              ? window.open(
                  `/#/account?accountNumber=${accInfo?.accountNumber}&device=EMTA`,
                  "_blank"
                )!
              : window.open(
                  `/#/account?accountNumber=${
                    accInfo?.accountNumber
                  }&deviceKey=${macIdRef.current?.trim()}`,
                  "_blank"
                )!
            : window.open(
                `/#/account?accountNumber=${accInfo?.accountNumber}`,
                "_blank"
              )!;
        macIdRef.current = "";
        modemTypeRef.current = "";
        newWindow.onload = () => {
          newWindow.document.title = "Account-" + accInfo?.accountNumber;
        };
      } else if (status === "ERROR") {
        setOpenSnackBar(true);
        dispatch(AccReset());
        macRef.current = false;
      }
    },
    [accInfo?.accountNumber, dispatch, status]
  );

  useEffect(() => {
    if (macRef.current) {
      dispatch(AccReset());
      navigateToAcc();
    }
  }, [accInfo?.accountNumber, status, navigateToAcc, dispatch]);

  const handleExternalLink = async (
    cmMac: any,
    stbKey?: any,
    modemtype?: any
  ) => {
    // dispatch(AccDetailsClient.getAccDetails(cmMac, "macAddress"));
    if (modemtype?.toUpperCase() === ConfigConst.stb?.toUpperCase()) {
      const apiResponse = await stbInfo.getStbAccountDetails(cmMac);
      if (apiResponse?.errorCode) {
      } else if (apiResponse) {
        dispatch(
          AccDetailsClient.getAccDetails(
            apiResponse?.companyNum + apiResponse?.accountNum,
            "accountNumber"
          )
        );
      }
    } else dispatch(AccDetailsClient.getAccDetails(cmMac, "macAddress"));
    dispatch(SetActiveSubTabIndex(1));
    macRef.current = true;
    let deviceKey = stbKey;
    if (modemtype?.toUpperCase() === ConfigConst.ecm?.toUpperCase()) {
      deviceKey = stbKey?.startsWith("020") ? stbKey?.substr(3) : stbKey;
      macIdRef.current = deviceKey?.toString();
    } else {
      macIdRef.current = cmMac?.toString();
    }
    modemTypeRef.current = modemtype?.toUpperCase();
    navigateToAcc(modemtype?.toUpperCase());
  };

  const filterContent = (data: any, key: String, value: String) => {
    return data
      ?.filter((d: any) => checkNullInTelemetry(d))
      ?.filter((device: any) =>
        checkNullInTelemetry(
          getValueByStrMapping(device, key?.toString()) &&
            getValueByStrMapping(device, key?.toString())
        )
          ?.toString()
          ?.toLowerCase()
          ?.includes(value?.toLowerCase())
      );
  };

  const filterDevicesData = useMemo(() => {
    let filterData = tableData;
    specInfo?.forEach((value: Boolean, key: String) => {
      let keys: Array<String> = key.split(",");
      if (keys.length > 1) {
        filterData = filterSpecData(filterData, keys[0], keys[1], value);
      }
    });

    searchValues?.forEach((value: String, key: String) => {
      if (value?.length > 0 && key?.length > 0) {
        filterData = filterContent(filterData, key, value);
      }
    });

    if (sorting?.field) {
      const reversed = sorting?.order === "asc" ? 1 : -1;

      if (sorting?.field?.toLowerCase() === "address".toLowerCase()) {
        filterData = filterData?.sort((a: any, b: any) => {
          const addressA = a?.serviceAddress;
          const addressB = b?.serviceAddress;
          // first compare the street number and then street name and then apartment number
          const streetNumberA = addressA?.streetNumber;
          const streetNumberB = addressB?.streetNumber;
          const streetNameA = addressA?.streetName;
          const streetNameB = addressB?.streetName;
          const apartmentNumberA = addressA?.aptNumber;
          const apartmentNumberB = addressB?.aptNumber;
          const collator = new Intl.Collator(undefined, {
            numeric: true,
            sensitivity: "base",
          });

          if (collator.compare(streetNumberA, streetNumberB) === 0) {
            if (collator.compare(streetNameA, streetNameB) === 0) {
              return (
                collator.compare(apartmentNumberA, apartmentNumberB) * reversed
              );
            }
            return collator.compare(streetNameA, streetNameB) * reversed;
          }
          return collator.compare(streetNumberA, streetNumberB) * reversed;

          // return addressA?.streetName?.localeCompare(addressB?.streetName) === 0
          //   ? addressA?.streetNumber?.localeCompare(addressB?.streetNumber) ===
          //     0
          //     ? reversed *
          //     addressA?.aptNumber?.localeCompare(addressB?.aptNumber)
          //     : reversed *
          //     addressA?.streetNumber?.localeCompare(addressB?.streetNumber)
          //   : reversed *
          //   addressA?.streetName?.localeCompare(addressB?.streetName);
        });
      } else if (sorting?.field?.toLowerCase() === "createtime".toLowerCase()) {
        filterData.sort((a: any, b: any) => {
          const createTimeA =
            a?.createTime && a?.createTime.trim() !== ""
              ? new Date(a.createTime)
              : new Date(0);
          const createTimeB =
            b?.createTime && b?.createTime.trim() !== ""
              ? new Date(b.createTime)
              : new Date(0);

          return (
            (createTimeA > createTimeB
              ? 1
              : createTimeA < createTimeB
              ? -1
              : 0) * reversed
          );
        });
      } else if (sorting?.field?.toLowerCase() === "minrxpower".toLowerCase()) {
        filterData = filterData?.sort((a: any, b: any) => {
          const minRxPowerA =
            a?.minRxPower !== ""
              ? parseFloat(a?.minRxPower)
              : Number.NEGATIVE_INFINITY;
          const minRxPowerB =
            b?.minRxPower !== ""
              ? parseFloat(b?.minRxPower)
              : Number.NEGATIVE_INFINITY;

          return (
            (minRxPowerA > minRxPowerB
              ? 1
              : minRxPowerA < minRxPowerB
              ? -1
              : 0) * reversed
          );
        });
      } else if (sorting?.field?.toLowerCase() === "fdcpower") {
        filterData = filterData?.sort((a: any, b: any) => {
          const fdcPowerA =
            a?.fdcPower && a?.fdcPower !== ""
              ? Math.round(parseFloat(a?.fdcPower))
              : Number.NEGATIVE_INFINITY;
          const fdcPowerB =
            b?.fdcPower && b?.fdcPower !== ""
              ? Math.round(parseFloat(b?.fdcPower))
              : Number.NEGATIVE_INFINITY;

          return (
            (fdcPowerA > fdcPowerB ? 1 : fdcPowerA < fdcPowerB ? -1 : 0) *
            reversed
          );
        });
      } else if (sorting?.field?.toLowerCase() === "smt") {
        filterData = filterData?.sort((a: any, b: any) => {
          const fieldA = getValueByStrMapping(a, sorting?.field?.toString());
          const fieldB = getValueByStrMapping(b, sorting?.field?.toString());
          return Number(fieldA) > Number(fieldB)
            ? reversed
            : Number(fieldA) === Number(fieldB)
            ? 0
            : -reversed;
        });
      } else if (sorting?.field?.toLowerCase() === "cmmac") {
        filterData = filterData?.sort((a: any, b: any) => {
          const macA = getValueByStrMapping(a, sorting?.field?.toString());
          const macB = getValueByStrMapping(b, sorting?.field?.toString());
          return macA > macB ? reversed : macA === macB ? 0 : -reversed;
        });

        // Locked or Unlocked
      } else if (sorting?.field?.toLowerCase() === "qamchannelstatus") {
        filterData = filterData?.sort((a: any, b: any) => {
          const statusA = a?.summary?.qamChannelStatus?.value;
          const statusB = b?.summary?.qamChannelStatus?.value;
          const collator = new Intl.Collator(undefined, {
            numeric: true,
            sensitivity: "base",
          });

          return collator.compare(statusA, statusB) * reversed;
        });
      } else {
        filterData = filterData?.sort((a: any, b: any) => {
          const fieldA = getValueByStrMapping(
            a,
            sorting?.field?.toString()
          )?.toString();
          const fieldB = getValueByStrMapping(
            b,
            sorting?.field?.toString()
          )?.toString();
          const collator = new Intl.Collator(undefined, {
            numeric: true,

            sensitivity: "base",
          });

          return collator.compare(fieldA, fieldB) * reversed;
        });
      }
      return filterData;
    }

    onDataChange(filterData);
    return filterData;
  }, [search, searchBy, sorting, tableData, specInfo]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderDocsis = (modem: any, attr: any) => {
    if (attr?.displayAttribute)
      return checkNullInTelemetry(modem?.[attr?.displayAttribute]);
    else return <>{checkNullInTelemetry(modem?.[attr?.jsonDataMapping])}</>;
  };
  const getCellContent = (modem: any, attr: any) => {
    let cellContent: any;

    switch (attr?.getDataFrom) {
      case labels.accountSummaryResponse:
        if (modem !== undefined && modem?.errorData != null) {
          cellContent = (
            <span
              onClick={() => {
                handleErrorClick(modem?.errorData);
              }}
              className="failLinkState"
            >
              ERROR
            </span>
          );
        } else if (modem !== undefined && modem?.errorData === undefined) {
          if (attr?.columnKey === "macAddress") {
            let mac = modem?.macAddress;
            // modemType === ConfigConst.oldstb ? modem?.cmMac : modem?.ecmMac;

            let stbDeviceKey =
              modem?.type?.toUpperCase() === ConfigConst.stb?.toUpperCase()
                ? modem?.macAddress
                : modem?.serialNum;
            cellContent = (
              <div className="cmMacWrapper">
                <span id="cm_mac">{checkNullInTelemetry(mac)}</span>
                <span
                  className="externalLink"
                  title="Customer Account View"
                  onClick={() => {
                    handleExternalLink(mac, stbDeviceKey, modem?.type);
                  }}
                >
                  <BsBoxArrowUpRight size={16} />
                </span>
              </div>

              // <div className="cmMacWrapper">
              //   {isModemLoaded(modem?.cmMac) && mac ? (
              //     <span
              //       id="cm_mac"
              //       className="idLink"
              //       title="Customer Account View"
              //       onClick={() => {
              //         handleExternalLink(mac, stbDeviceKey);
              //       }}
              //     >
              //       {checkNullInTelemetry(modem?.cmMac)}
              //     </span>
              //   ) : (
              //     <span id="cm_mac">{checkNullInTelemetry(modem?.cmMac)}</span>
              //   )}
              // </div>
            );
          } else {
            cellContent = (
              <span>
                {checkNullInTelemetry(modem?.[attr?.jsonDataMapping])}
              </span>
            );
          }
        }
        break;
      case labels.SUMMARY.toLowerCase():
        if (modem?.summary !== undefined && modem?.errorData != null) {
          cellContent = (
            <span
              onClick={() => {
                handleErrorClick(modem?.errorData);
              }}
              className="failLinkState"
            >
              ERROR
            </span>
          );
        } else if (modem !== undefined && modem?.errorCode === undefined) {
          cellContent = (
            <span>
              {
                <TelemetryActionMenu
                  menuType="vertical"
                  viewDropdown={"ALL"}
                  columnData={tableAttributes}
                  rowData={tableData}
                  nodeDevicesList={modem?.serialNumber}
                  isNeighbourhood={false}
                  isMultiDevice={true}
                  nodeId={modem?.serialNumber}
                  deviceType={"ONT"}
                />
              }
            </span>
          );
        } else if (modem?.summary?.errorCode !== undefined) {
          cellContent = (
            <span
              onClick={() => {
                handleErrorClick(modem?.summary);
              }}
              className="failLinkState"
            >
              ERROR
            </span>
          );
        } else if (
          modem?.apiStatus?.isLoading &&
          modem?.accountSummaryResponse?.data?.summary === undefined
        ) {
          if (attr?.allowedLoading) {
            cellContent = (
              <img
                className="nodeTeleDataLoader"
                src={LoaderGif}
                alt="Loading"
              />
            );
          }
        }
        break;
      case labels.regstatus.toLowerCase():
        if (modem?.regStatus !== undefined && modem?.errorData != null) {
          cellContent = (
            <span
              onClick={() => {
                handleErrorClick(modem?.errorData);
              }}
              className="failLinkState"
            >
              ERROR
            </span>
          );
        } else if (
          modem?.regStatus !== undefined &&
          modem?.regStatus?.errorCode === undefined
        ) {
          cellContent = (
            <span>{checkNullInTelemetry(modem?.[attr?.jsonDataMapping])}</span>
          );
        } else if (modem?.regStatus?.errorCode !== undefined) {
          cellContent = (
            <span
              onClick={() => {
                handleErrorClick(modem?.regStatus);
              }}
              className="failLinkState"
            >
              ERROR
            </span>
          );
        } else if (
          modem?.apiStatus?.isLoading &&
          modem?.accountSummaryResponse?.data?.regStatus === undefined
        ) {
          if (attr?.allowedLoading) {
            cellContent = (
              <img
                className="nodeTeleDataLoader"
                src={LoaderGif}
                alt="Loading"
              />
            );
          }
        }
        break;
      default:
        break;
    }
    return (
      <React.Fragment key={`$${attr?.columnKey + modem?.cmMac}_cellContent`}>
        {cellContent}
      </React.Fragment>
    );
  };

  const handleErrorClick = (data: any) => {
    setPopUpHeader(labels.ERROR.toUpperCase() + " - ");
    setPopUpFlag(true);
    setPopUpBody(
      <CertiExceptionCont dataObj={data} sectionName="CM Diagnostics" />
    );
  };

  const handleScroll = (event: React.UIEvent<React.ReactNode>) => {
    const targetDiv: HTMLDivElement = event.target as HTMLDivElement;
    if (targetDiv === topScrollRef.current && mainTableRef.current) {
      mainTableRef.current.scrollLeft = targetDiv.scrollLeft;
    } else if (targetDiv === mainTableRef.current && topScrollRef.current) {
      topScrollRef.current.scrollLeft = targetDiv.scrollLeft;
    }
  };

  useEffect(() => {
    if (mainTableRef.current) {
      const debouncedHandler = debounce(
        () => setScrollWidth(mainTableRef.current?.scrollWidth),
        1000
      );
      ["resize", "click", "mousemove", "mouseenter"].forEach((event) => {
        window.addEventListener(event, debouncedHandler, false);
      });
      setScrollWidth(mainTableRef.current?.scrollWidth);
      return () => {
        ["resize", "click", "mousemove", "mouseenter"].forEach((event) => {
          window.removeEventListener(event, debouncedHandler, false);
        });
      };
    }
  }, [mainTableRef.current?.scrollWidth]);
  return (
    <div className="certiTableCont">
      <Paper className="nodeTeleDeviceTablePaper ">
        <Snackbar
          open={openSnackBar}
          autoHideDuration={2000}
          onClose={() => setOpenSnackBar(!openSnackBar)}
        >
          <Alert severity="warning">
            Unable to Fetch Details for the Selected Mac Address.
          </Alert>
        </Snackbar>
        <div
          ref={topScrollRef}
          className="topScroll gponTopScroll"
          style={{
            overflowY:
              mainTableRef.current &&
              mainTableRef.current?.offsetHeight >=
                mainTableRef.current?.scrollHeight
                ? "hidden"
                : "scroll",
          }}
          onScroll={handleScroll}
        >
          <div
            style={{
              height: "16px",
              width: scrollWidth,
            }}
          />
        </div>
        <TableContainer
          ref={mainTableRef}
          onScroll={handleScroll}
          className="nodeTeleTableCont gponTopScroll"
        >
          <Table stickyHeader={true} className="stbTelemetryTable">
            <MultiDeviceAccTableHead
              tableAttr={tableAttributes}
              onSpecDetailsChange={(specContent: Map<String, Boolean>) =>
                setSpecInfo(specContent)
              }
              onSearch={(
                value: any,
                by: string,
                searchMap: Map<String, String>
              ) => {
                setSearch(value);
                setSearchBy(by);
                setSearchValues(searchMap);
              }}
              onSorting={(field: any, order: any) => {
                setSorting({ field, order });
              }}
              handleDragStart={handleDragStart}
              handleDragOver={handleDragOver}
              handleOnDrop={handleOnDrop}
              handleDragEnter={handleDragEnter}
              dragOver={dragOver}
            />
            <TableBody className="dTabBody">
              {filterDevicesData?.length === 0 ? (
                <TableRow key="exception">
                  <TableCell colSpan={tableAttributes?.length}>
                    <div className="diagErrorCont">
                      <ExceptionBox
                        headerText={labels.NO_DATA}
                        bodyText={labels.NO_DATA_SEARCH_VAL}
                        primaryBtn={false}
                        secBtn={false}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                React.Children.toArray(
                  filterDevicesData
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((modem: any, index: number) => {
                      return (
                        <TableRow
                          tabIndex={-1}
                          key={"TableRow" + index * index * index}
                        >
                          {React.Children.toArray(
                            tableAttributes?.map((attr: any, idx: number) => {
                              return (
                                attr?.isVisible && (
                                  <TableCell
                                    key={"TableCell" + idx * idx + 888}
                                    align="left"
                                    className={
                                      attr.jsonDataMapping === dragOver
                                        ? "dragged"
                                        : ""
                                    }
                                    draggable={
                                      attr.jsonDataMapping === dragOver
                                    }
                                    style={
                                      attr?.columnKey === "osVersion"
                                        ? {
                                            whiteSpace: "nowrap",
                                            maxWidth: "100px",
                                            display: "block",
                                            overflowX: "hidden",
                                            fontWeight: 550,
                                          }
                                        : attr?.columnKey === "address"
                                        ? {
                                            whiteSpace: "nowrap",
                                            maxWidth: "400px",
                                            display: "block",
                                            overflowX: "hidden",
                                            fontWeight: 550,
                                          }
                                        : {
                                            whiteSpace: "nowrap",
                                            fontWeight: 550,
                                          }
                                    }
                                  >
                                    {getCellContent(modem, attr)}
                                  </TableCell>
                                )
                              );
                            })
                          )}
                        </TableRow>
                      );
                    })
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="tablePaginationContainerGpon">
          <span className="tablePaginationTextGpon">
            TOTAL ROWS: {filterDevicesData?.length}
          </span>
          <TablePagination
            className="tablePaginationGpon"
            rowsPerPageOptions={[500, 1000, 1500, 2000]}
            component="div"
            count={filterDevicesData?.length ? filterDevicesData?.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
        <CustomPopUp
          showVal={popUpFlag}
          headerText={popUpHeader}
          bodyElement={popUpBody}
          handleClose={() => setPopUpFlag(false)}
        />
      </Paper>
    </div>
  );
};

export default MultiDevicesONTTable;
