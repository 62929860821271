import { AppBar, Divider, Tab, Tabs } from "@material-ui/core";
import { useState } from "react";
import { Certifications } from "../../components/certifications/Certifications";
import {
  changeTimeFormat,
  convertHealthData,
} from "../../components/reusable/Util";
import AppLabels from "../../constants/App_Labels";
import { TabPanel, a11yProps } from "./NodePageTabs";

const NodeCertificateDetails = (props: any) => {
  const { nodeCertData } = props;
  const [value, setValue] = useState(0);
  const labels = AppLabels();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className="nodeFullCertiCont">
      <div className="nodeFullCertiInfoCont">
        <section className="nodeCertiDetailsSec">
          <p>
            <span>{labels.Certified + " " + labels.By}: </span>
            <span>{nodeCertData?.certifiedBy}</span>
          </p>
          <p>
            <span>{labels.Certified + " " + labels.Time_and_Date}: </span>
            <span>{changeTimeFormat(nodeCertData?.certCreatedTime)}</span>
          </p>
          <p>
            <span>{labels.NODE_ID}: </span>
            <span>{nodeCertData?.nodeId}</span>
          </p>
          {nodeCertData?.smtId != undefined && nodeCertData?.smtId != "" && (
            <p>
              <span>{labels.SMT_ID}: </span>
              <span>{nodeCertData?.smtId}</span>
            </p>
          )}
          <p>
            <span>{labels.Work_Order_ID}: </span>
            <span>{nodeCertData?.workorderId ?? ""}</span>
          </p>
        </section>

        <Divider orientation="vertical" style={{ height: "170px" }} />

        <section className="nodeScoreDetailsSec">
          <p style={{ marginBottom: "17px", fontSize: "18px" }}>
            <span>{labels.Scores.toUpperCase()}</span>
          </p>
          <div className="scoreDiv">
            <p className="smHealthScore">
              <span>{labels.Overall}: </span>
              <span>
                {convertHealthData(
                  labels.Overall,
                  nodeCertData?.overallScore,
                  labels
                )}
              </span>
            </p>
            <p className="smHealthScore">
              <span>{labels.US}: </span>
              <span>
                {convertHealthData(
                  labels.US,
                  nodeCertData?.overallUSScore,
                  labels
                )}
              </span>
            </p>
            <p className="smHealthScore">
              <span>{labels.DS}: </span>
              <span>
                {convertHealthData(
                  labels.DS,
                  nodeCertData?.overallDSScore,
                  labels
                )}
              </span>
            </p>
            <p className="smHealthScore">
              <span>{labels.Breakdown}: </span>
              <span>
                {convertHealthData(
                  labels.Breakdown,
                  JSON.parse(nodeCertData?.breakdownScore),
                  labels
                )}
              </span>
            </p>
          </div>
        </section>

        <Divider orientation="vertical" style={{ height: "170px" }} />

        <section className="nodeScoreDetailsSec">
          <p style={{ marginBottom: "17px", fontSize: "18px" }}>
            <span>{labels.STATUS.toUpperCase()}</span>
          </p>
          <p>
            <span>
              {labels.ACTIVE} / {labels.ASSIGNED}:{" "}
            </span>
            <span>
              {`${nodeCertData?.active}%`} / {nodeCertData?.assigned}
            </span>
          </p>
          <p>
            <span>
              {labels.RESPONSIVE} / {labels.UNRESPONSIVE}:{" "}
            </span>
            <span>
              {nodeCertData?.responsive} / {nodeCertData?.unresponsive}
            </span>
          </p>
          <p>
            <span>{labels.START}: </span>
            <span>{nodeCertData?.certStartTime}</span>
          </p>
          <p>
            <span>{labels.END}: </span>
            <span>{nodeCertData?.certEndTime}</span>
          </p>
        </section>
      </div>

      <div>
        <AppBar position="static" className="accTabsBar">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label={labels.CERTIFICATIONS} {...a11yProps(0)} />
            {/* <Tab label={labels.TELEMETRY} {...a11yProps(1)} /> */}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Certifications data={nodeCertData?.mlData} isPopup={true} />
        </TabPanel>
        {/* <TabPanel value={value} index={1}></TabPanel> */}
      </div>
    </div>
  );
};
export default NodeCertificateDetails;
