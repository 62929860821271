import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DiagContainer from "../../components/reusable/DiagContainer";
import TelemetryActionMenu from "../../components/telemetry/TelemetryActionMenu";
import AppLabels from "../../constants/App_Labels";
import ConfigConst from "../../constants/ConfigConst";
import {
  GetListMAC,
  GetNodeInfoDetails,
  GetTelemetryDetails,
} from "../../get-api/GetResponse";
import { updateConfigDataReposition } from "../../store/ducksPattern/userPrefConfig";
import MultiDevicesTable from "../ping-page/MultiDevicesTable";

const MultiDeviceMACTeleTab = () => {
  const dispatch = useDispatch();
  const labels = AppLabels();
  const { currNodedata } = GetNodeInfoDetails();

  const { ducksConfigData } = GetTelemetryDetails();

  const listMAC = GetListMAC();
  const address = ConfigConst.address.toLowerCase();
  const [activeDataSet, setActiveDataSet] = useState<any>();
  const [viewTypeOptions, setViewTypeOptions] = useState<any>([]);
  const [teleViewType, setTeleViewType] = useState<any>("");
  const [selectAttrVal, setSelectAttrVal] = useState<any>([]);
  const [tableAttributes, setTableAttributes] = useState<any>([]);
  const [tableData, setTableData] = useState();
  const [dragOver, setDragOver] = useState("");
  const [popUpFlag, setPopUpFlag] = useState(false);
  const [popUpHeader, setPopUpHeader] = useState("");
  const [popUpBody, setPopUpBody] = useState(<div></div>);
  const [averagesPopUp, setAveragesPopUp] = useState(false);
  const [avgPopUpBody, setAvgPopUpBody] = useState(<div></div>);
  const [nodeAvgData, setNodeAvgData] = useState({});
  // const [teleData, setTeleData] = useState(listAcc?.data);
  const teleData = GetListMAC()?.data;
  const handleDragStart = (event: any) => {
    const { id } = event.target;
    const idx = tableAttributes.findIndex(
      (obj: any) => obj.jsonDataMapping === id
    );
    event.dataTransfer.setData("colIdx", idx);
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };
  const handleDragEnter = (event: any) => {
    const { id } = event.target;
    const identifier =
      id === "null" || id === null || id === "" ? dragOver : id;
    setDragOver((state) => {
      state = identifier;
      return state;
    });
  };

  const handleOnDrop = (event: any) => {
    const { id } = event.target;
    const identifier =
      id === "null" || id === null || id === "" ? dragOver : id;
    const droppedColIdx = tableAttributes.findIndex(
      (obj: any) => obj.jsonDataMapping == identifier
    );
    const draggedColIdx = event.dataTransfer.getData("colIdx");
    const tempCols = [...tableAttributes];
    const tempCols1 = [...tableAttributes];
    if (droppedColIdx !== -1 && draggedColIdx !== -1) {
      if (droppedColIdx < draggedColIdx) {
        tempCols[droppedColIdx] = tempCols1[draggedColIdx];
        tempCols[droppedColIdx + 1] = tempCols1[droppedColIdx];

        tempCols1.splice(draggedColIdx, 1);
        for (let i = droppedColIdx + 2; i <= tempCols1.length; i++) {
          tempCols[i] = tempCols1[i - 1];
        }
      } else if (draggedColIdx < droppedColIdx) {
        tempCols[droppedColIdx] = tempCols1[draggedColIdx];
        tempCols[droppedColIdx - 1] = tempCols1[droppedColIdx];
        tempCols1.splice(draggedColIdx, 1);
        for (let i = droppedColIdx - 2; i >= 0; i--) {
          tempCols[i] = tempCols1[i];
        }
      }
      if (teleViewType) {
        dispatch(
          updateConfigDataReposition({ viewVal: teleViewType, tempCols })
        );
      }
    }
    setDragOver((state) => {
      state = "";
      return state;
    });
  };

  useEffect(() => {
    if (listMAC?.error) {
      setPopUpFlag(true);
      setPopUpHeader(labels.ERROR.toUpperCase());
      setPopUpBody(
        <span className="telemertyinfoBoxSpan">{labels.NO_DATA}</span>
      );
    }
  }, [listMAC?.error]);

  useEffect(() => {
    ducksConfigData?.userPreferences?.node?.telemetry?.views?.map((v: any) => {
      setTableAttributes(v?.attributes);
      setSelectAttrVal(v?.attributes);
    });
  }, [ducksConfigData, listMAC]);

  return (
    <div className="stbTeleTabCont">
      {/* {activeDataSet?.dataSetName !== ConfigConst.BASIC.toUpperCase() && ( */}
      <div className="nodeFilterCont">
        <div className="designMaintainer" />
        {listMAC?.data !== undefined && listMAC?.data?.length !== 0 && (
          <TelemetryActionMenu
            menuType="vertical"
            viewDropdown={"ALL"}
            columnData={tableAttributes}
            rowData={tableData}
            nodeDevicesList={teleData}
            isNeighbourhood={true}
            isMultiDevice={true}
            nodeId={"MAC"}
            deviceType={"MultiDevice"}
          />
        )}
      </div>
      <div>
        {/* <div> */}

        <DiagContainer
          data={listMAC?.data}
          isLoading={listMAC?.isLoading}
          error={listMAC?.error}
          sectionName={"Multi-Device Search"}
        >
          <MultiDevicesTable
            tableAttributes={tableAttributes}
            nodeDevicesList={teleData}
            onDataChange={setTableData}
            handleDragStart={handleDragStart}
            handleDragOver={handleDragOver}
            handleOnDrop={handleOnDrop}
            handleDragEnter={handleDragEnter}
            dragOver={dragOver}
          />
        </DiagContainer>
        {/* </div> */}
      </div>
    </div>
  );
};

export default MultiDeviceMACTeleTab;
