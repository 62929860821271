import {
    CLEAR_FLAPS_BEGIN,
    CLEAR_FLAPS_SUCCESS,
    CLEAR_FLAPS_FAILURE,
    ClearFlapsActionTypes,
  } from "../../../types/node/telemetry/ClearFlaps"
  
  export function ClearFlapsBegin(): ClearFlapsActionTypes {
    return {
      type: CLEAR_FLAPS_BEGIN,
    };
  }
  
  export function ClearFlapsSuccess(ClearFlapList: any): ClearFlapsActionTypes {
    return {
      type: CLEAR_FLAPS_SUCCESS,
      payload: ClearFlapList,
    };
  }
  
  export function ClearFlapsFailure(error: Error): ClearFlapsActionTypes {
    return {
      type: CLEAR_FLAPS_FAILURE,
      payload: error,
    };
  }
  