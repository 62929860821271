import { createSlice } from "@reduxjs/toolkit";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "oltSummaryDetails",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    // action => action handler
    oltSummaryBegin: (oltSummary) => {
      oltSummary.isLoading = true;
    },
    oltSummarySuccess: (oltSummary, action) => {
      oltSummary.isLoading = false;
      oltSummary.data = action.payload;
      oltSummary.status = storeConst.OK;
    },
    oltSummaryFailure: (oltSummary, action) => {
      oltSummary.isLoading = false;
      oltSummary.error = action.payload;
      oltSummary.data = undefined;
      oltSummary.status = storeConst.ERROR;
    },
    updateOltSummary: (oltSummary, action) => {
      oltSummary.data = action.payload;
    },
    resetOltSummary: (oltSummary) => {
      oltSummary.isLoading = false;
      oltSummary.error = undefined;
      oltSummary.data = undefined;
      oltSummary.status = "";
    },
  },
});

export const {
  oltSummaryBegin,
  oltSummarySuccess,
  oltSummaryFailure,
  updateOltSummary,
  resetOltSummary,
} = slice.actions;

export default slice.reducer;
