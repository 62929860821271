import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Card } from "@material-ui/core";

import "../../design-tokens/ReusableComp.scss";
import AppLabels from "../../constants/App_Labels";
import SimpleListView from "./SImpleListView";
import {
  GetFileDownloadState,
  GetFileUploadState,
  GetFileFetchState,
  GetTechInfo,
} from "../../get-api/GetResponse";
import ConfigConst from "../../constants/ConfigConst";
import CustomPopUp from "../../components/reusable/CustomPopUp";
import { downloadFile } from "../../store/ducksPattern/filemanager/fileDownload";
import { uploadFile } from "../../store/ducksPattern/filemanager/fileUpload";
import { getAllFiles } from "../../store/ducksPattern/filemanager/fileFetch";
import DiagContainer from "../../components/reusable/DiagContainer";
import DropFileInput from "../../components/reusable/DropFileInput";
import ReloadData from "../../components/reusable/ReloadData";
import ResponseSnackBar from "../../components/reusable/ResponseSnackBar";
import ErrorBox from "../../components/reusable/ErrorBox";
import { NetworkConfig } from "../../network/httpclient/NetworkConfig";
import FileClient from "../../network/httpclient/files/FileClient";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const FileManager = (props: any) => {
  const labels = AppLabels();
  const dispatch = useDispatch();
  const isRef = useRef(false);
  const [uploads, setUploads] = React.useState<any[]>([]);
  const sizeLimit = 5 * 1024 * 1024; // 5MB
  const [popUpFlag, setPopUpFlag] = useState(false);
  const [fileDownloadFlag, setFileDownloadFlag] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileUploadFlag, setFileUploadFlag] = useState(false);
  const roles = GetTechInfo()?.roles;
  const downloadInfo = GetFileDownloadState();
  const uploadInfo = GetFileUploadState();
  const fetchInfo = GetFileFetchState();
  const isRequiredRoleAvailable =
    roles?.includes(ConfigConst.ADMIN) || roles?.includes(ConfigConst.MANAGER);

  const [files, setFiles] = React.useState<any[]>([]);
  useEffect(() => {
    if (fetchInfo?.data === undefined) {
      dispatch(FileClient.getAllFiles());
    }
    setFiles(fetchInfo?.data);
  }, [fetchInfo?.data]);

  useEffect(() => {
    if (!uploadInfo.isLoading && uploadInfo.status === "OK") {
      dispatch(FileClient.getAllFiles());
    }
  }, [uploadInfo.status]);

  useEffect(() => {
    if (!downloadInfo.isLoading && downloadInfo.status === labels.ERROR) {
      setFileDownloadFlag(true);
    }
    if (
      !downloadInfo.isLoading &&
      downloadInfo.status === labels.OK &&
      isRef.current
    ) {
      if (fileName.length !== 0) {
        saveFileToDevice(fileName);
      }
    }
  }, [downloadInfo.status]);

  const saveFileToDevice = async (filename: string) => {
    const file = downloadInfo?.data;

    // Convert the base64 string to a Blob
    const regex = /^data:(.+);base64,(.*)$/;
    const match = file.match(regex);
    if (!match || match.length !== 3) {
      return;
    }
    const mimeType = match[1];
    const base64Data = match[2];

    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], { type: mimeType });

    // Create a download link and click it programmatically
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = filename;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    isRef.current = false;
  };

  const handleDownload = (filename: string) => {
    dispatch(FileClient.downloadFile(filename));
    if (filename.length !== 0) {
      setFileName(filename);
    }
    isRef.current = true;
  };

  const handleUpload = () => {
    dispatch(uploadFile(uploads[0]));
    setPopUpFlag(false);
    setUploads([]);
    setFileUploadFlag(true);
  };

  return (
    <div className="filemanager">
      <div className="filemanager-header">
        <h4>{labels.FILE_MANAGER}</h4>
      </div>
      <div className="filemanager-content">
        <div className="refreshCont">
          {/* TODO: ACTIVATE THE BELOW CONDITION AFTER THE ROLES ARE DETERMINED */}
          {isRequiredRoleAvailable && (
            <button
              className="uploadButton blueHollowBtn"
              onClick={() => setPopUpFlag(true)}
            >
              {labels.Upload_a_File?.toUpperCase()}
            </button>
          )}
          <span className="reloadButton">
            <ReloadData
              disabled={fetchInfo?.isLoading}
              refreshData={() => dispatch(FileClient.getAllFiles())}
            />
          </span>
        </div>
        <Card className="custInfoCard" style={{ marginTop: "0px" }}>
          <DiagContainer
            isLoading={fetchInfo?.isLoading}
            data={fetchInfo?.data}
            sectionName={labels.FILE_MANAGER}
            error={fetchInfo?.error}
            handleRefresh={() => dispatch(FileClient.getAllFiles())}
          >
            <SimpleListView
              className="filemanager-table"
              headers={[
                "",
                labels.NAME,
                labels.Size?.toUpperCase(),
                labels.Last_Modified?.toUpperCase(),
                labels.ACTION?.toUpperCase(),
              ]}
              files={files}
              handleDownload={handleDownload}
            />
          </DiagContainer>
        </Card>
      </div>

      <CustomPopUp
        showVal={popUpFlag}
        headerText={labels.Upload_a_File?.toUpperCase()}
        bodyClassName={"uploadBody"}
        className={"fileUploadPopUp"}
        confirmText={labels.Upload}
        buttonDisabled={uploadInfo?.isLoading || uploads?.[0]?.size > sizeLimit}
        handleConfirm={handleUpload}
        bodyElement={<DropFileInput file={uploads} onFileChange={setUploads} />}
        handleClose={() => setPopUpFlag(false)}
      />

      <ResponseSnackBar
        open={fileUploadFlag}
        isHeader={true}
        requestType={labels.File_Upload}
        handleClose={() => setFileUploadFlag(false)}
        responseType={uploadInfo?.status}
      />
      <ErrorBox
        open={fileDownloadFlag}
        error={labels.fileDownloadError}
        handleClose={() => setFileDownloadFlag(false)}
      />
    </div>
  );
};
export default FileManager;
