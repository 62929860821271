import { Divider } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { Form } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { useEffect, useState } from "react";

import { getStbTypeValue } from "../../components/reusable/NodeUtil";
import AppLabels from "../../constants/App_Labels";
import ConfigConst from "../../constants/ConfigConst";
import { GetTelemetryDetails } from "../../get-api/GetResponse";

interface popUpProps {
  showVal: any;
  headerText: string;
  bodyHeadText?: string;
  bodyText?: string;
  popUpType?: string;
  handleConfirm: any;
  handleClose: any;
  confirmText: string;
  cancelText?: string;
  viewDropdown?: any;
  columnData?: any;
  rowData?: any;
  deviceType?: any;
  deviceId?: any;
  isMultiDevice?: boolean;
}

const TelemetryExportCsvPopUp = (props: popUpProps) => {
  const labels = AppLabels();
  const {
    showVal,
    headerText,
    handleConfirm,
    handleClose,
    cancelText,
    popUpType,
    viewDropdown,
    columnData,
    rowData,
    deviceType,
    deviceId,
    isMultiDevice,
  } = props;

  const [fileName, setFileName] = useState("");
  const [header, setHeader] = useState([]);
  const [inputVal, setInputVal] = useState("");
  const data= rowData;
  const ducksConfigData = GetTelemetryDetails().ducksConfigData;
  const deviceTypeVal = deviceType?.includes(",")
    ? deviceType
    : deviceType?.includes("ECM")
    ? "ECM"
    : deviceType?.toUpperCase() === "OLDSTB"
    ? "STB"
    : deviceType;
    const processColumnData = (row: any, column: any) => {
      if (column?.jsonDataMapping === "ofdm_Channel_ID") {
        const profileObj = row[column.jsonDataMapping];
    if (Array.isArray(profileObj) && profileObj.length > 0) {
      return profileObj.map((profile: any) => {
        const itemValue = profile?.profileString;
        return itemValue ? itemValue.toLowerCase() : "";  
      }).join("\n");  
    }
    return row?.[column?.jsonDataMapping]?.toString()?.trim();  
  } else  if (column?.jsonDataMapping === "ofdma_Channel_ID") {
        const profileObj = row[column.jsonDataMapping];
        if (Array.isArray(profileObj) && profileObj.length > 0) {
          return profileObj.map((nestedArray) => {
            if (Array.isArray(nestedArray)) {
              return nestedArray.map((profile, profileIndex) => {
                const itemValue = profile?.profileString;
                return itemValue ? itemValue.toLowerCase() : "";  
              }).join("\n");  
            }
            return row?.[column?.jsonDataMapping]?.toString()?.trim();  
          }).join("\n");
        } return row?.[column?.jsonDataMapping]?.toString()?.trim(); 
      } else {
        return row?.[column?.jsonDataMapping]?.toString()?.trim() ; 
      }
    };
    
  const getDefaultLibFileName = () => {
    var today = new Date();
    var time = today.toLocaleTimeString().slice(0, 5).split(":");
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    return (
      deviceTypeVal +
      "_" +
      deviceId +
      "-" +
      viewDropdown +
      "_" +
      mm +
      "-" +
      dd +
      "-" +
      yyyy +
      "_" +
      time[0] +
      ":" +
      time[1]
    )?.toUpperCase();
  };

  // const getData = () => {
  //   rowData?.forEach((key: any) => {
  //     Object.keys(key)?.map(function (k: any, index: any) {
  //       if (typeof key[k] === "string" && key[k]?.startsWith("-")) {
  //         console.log("if string");
  //         key[k] = "\n" + key[k];
  //       }
  //       else if (
  //         k === "apiStatus" &&
  //         (key[k]?.error?.summary?.errorCode ||
  //           key[k]?.error?.flaps?.errorCode ||
  //           key[k]?.error?.docsis?.errorCode ||
  //           key[k]?.error?.codewords?.errorCode)
  //       ) {
  //         key[k] = "ERROR";
  //       }
  //       else if (key[k] === undefined || key[k] === null) {
  //         key[k] = "";
  //       } else {
  //         key[k] = key[k];
  //       }
  //     });
  //   });

  //   return rowData;
  // };

  const getData = () => {
    return data?.map((originalRow: any) => {
      const row = { ...originalRow };
      let summaryFlag = false;
      let accsummaryFlag = false;
      let docsisFlag = false;
      let flapsFlag = false;
      let codewordsFlag = false;
      if (row?.summary != null && row?.summary?.errorCode) summaryFlag = true;
      else if (row?.flaps != null && row?.flaps?.errorCode) flapsFlag = true;
      else if (row?.codewords != null && row?.codewords?.errorCode)
        codewordsFlag = true;
      else if (row?.docsis != null && row?.docsis?.errorCode) docsisFlag = true;
      else if (row?.summary != null && row?.summary?.errorCode)
        accsummaryFlag = true;
      columnData?.map((column: any) => {
        if (
          typeof row[column.jsonDataMapping] === "string" &&
          row[column.jsonDataMapping]?.startsWith("-")
        ) {
          row[column.jsonDataMapping] =
            row[column.jsonDataMapping]?.toString()?.trim() + "\n";
        }
        switch (column?.getDataFrom) {
          case labels.LIST_OF_MODEMS:
            break;
          case labels.SUMMARY.toLowerCase():
            row[column.jsonDataMapping] = summaryFlag
              ? labels.ERROR
              : row?.[column?.jsonDataMapping]?.toString()?.trim();
            break;
          case labels.SummaryStb:
            row[column.jsonDataMapping] = summaryFlag
              ? labels.ERROR
              : column?.jsonDataMapping === "qamChannelStatus" ||
                column?.jsonDataMapping === "qamSnr"
              ? row?.summary?.[column?.jsonDataMapping]?.value
                  ?.toString()
                  ?.trim()
              : column?.jsonDataMapping === "way"
              ? getStbTypeValue(
                  row?.summary?.[column?.jsonDataMapping]?.toString()?.trim()
                )?.toUpperCase()
              : row?.[column?.jsonDataMapping]?.toString()?.trim();
            break;
          case labels.accountSummaryResponse:
            row[column.jsonDataMapping] = accsummaryFlag
              ? labels.ERROR
              : row?.[column?.jsonDataMapping];
            break;
          case labels.FLAPS.toLowerCase():
            row[column.jsonDataMapping] = flapsFlag
              ? labels.ERROR
              : row?.[column?.jsonDataMapping]?.toString()?.trim();
            break;
          case labels.DOCSIS.toLowerCase():
            row[column.jsonDataMapping] = docsisFlag
              ? labels.ERROR
              : processColumnData(row, column);;
            break;
          case labels.Codewords.toLowerCase():
            row[column.jsonDataMapping] = codewordsFlag
              ? labels.ERROR
              : row?.[column?.jsonDataMapping]?.toString()?.trim();
            break;
        }
      });
      return row;
    }) || [];
  
  };

  const getFileName = () => {
    let filename = !inputVal.replace(/\s/g, "").length
      ? getDefaultLibFileName()
      : inputVal;
    return filename + ".csv";
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };
  const prepareHeader = () => {
    const header1: any = [];
    const viewUserPrefs =
      ducksConfigData?.userPreferences?.node?.telemetry?.views
        ?.filter(
          (pref: any) =>
            pref?.viewTypeKey?.toLowerCase() === viewDropdown?.toLowerCase() ||
            (viewDropdown?.toLowerCase() === labels.ALL.toLowerCase() &&
              (pref?.viewTypeKey?.toLowerCase() ===
                ConfigConst.FULL?.toLowerCase() ||
                pref?.viewTypeKey?.toLowerCase() ===
                  ConfigConst.FULL_NO_CW?.toLowerCase())) ||
            isMultiDevice
        )
        ?.flatMap((v: any) => v?.attributes);

    viewUserPrefs?.forEach((field: any) => {
      if (field?.isVisible && field?.columnKey!== "selectAll") {
        header1.push({
          label: field?.columnName?.toString()?.toUpperCase(),
          key: field?.jsonDataMapping,
        });
      }
    });

    setHeader((header) => {
      header = header1;
      return header;
    });
  };

  useEffect(() => {
    setInputVal("");
  }, [fileName]);

  return (
    <div>
      <Modal
        show={showVal}
        onHide={() => handleClose()}
        backdrop="static"
        keyboard={false}
        centered
        id="sAPopUp"
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header className="searchPopHead">
            <header>{headerText}</header>
            <button className="sPopUpClose" onClick={() => handleClose()}>
              <IoClose color="white" />
            </button>
          </Modal.Header>
          <Modal.Body className="confirmBody">
            <div className="confirmBodyCont">
              <div>
                <b>
                  {" "}
                  <h6>File Name :</h6>
                </b>
                <input
                  style={{
                    minHeight: "25px",
                    minWidth: "250px",
                    width: "100%",
                  }}
                  type="text"
                  className="csvfilenameinputbox"
                  defaultValue={getDefaultLibFileName()}
                  name="csvFileName"
                  placeholder={getDefaultLibFileName()}
                  onChange={(e) => {
                    setInputVal(e.target.value.toString());
                  }}
                />
                <br />
                <br />
              </div>
            </div>
          </Modal.Body>
          <Divider orientation="horizontal" />
          <Modal.Footer>
            {popUpType !== "error" && (
              <button className="sPopCloseBtn" onClick={() => handleClose()}>
                {cancelText}
              </button>
            )}
            <CSVLink
              data={getData()}
              headers={header}
              filename={fileName}
              className="btn btn-primary btn-xl"
              onClick={() => {
                prepareHeader();
                setFileName(getFileName());
                handleConfirm();
              }}
              target="_blank"
            >
              Export
            </CSVLink>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default TelemetryExportCsvPopUp;
