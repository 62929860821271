import "../../design-tokens/rpd-page/RPDPage.scss";
import RPDSwapTop from "../../components/rpd/rpdSwap/RPDSwapTop";
import RPDSwapDetails from "../../components/rpd/rpdSwap/RPDSwapDetails";
import { GetRPDSwapInfo } from "../../get-api/GetResponse";
import LoaderGif from "../../resources/images/loader.webp";
import ExceptionBox from "../../components/reusable/ExceptionBox";
import AppLabels from "../../constants/App_Labels";
import { GetTechInfo } from "../../get-api/GetResponse";
import OrderSuccessBox from "../../components/rpd/OrderSuccessBox";

const RPDSwapTab = () => {
  const rpdSwapInfo = GetRPDSwapInfo();
  const techData = GetTechInfo()?.data;
  const labels = AppLabels();

  return (
    <>
      <div>
        <RPDSwapTop
          oldMacAdd={rpdSwapInfo?.macAddress}
          techData={techData}
          isLoading={rpdSwapInfo?.isLoading}
        />
      </div>
      <div className="historyResultCont">
        {/* {rpdSwapInfo?.macAddress !== undefined && (
          <RPDSwapDetails
            oldMacAdd={rpdSwapInfo?.macAddress}
            rpdSwapData={rpdSwapInfo}
            isLoading={rpdSwapInfo?.isLoading}
          />
        )} */}
        {rpdSwapInfo.isLoading ? (
          <div className="loader">
            <img style={{ height: "60px" }} src={LoaderGif} />
          </div>
        ) : rpdSwapInfo?.data === undefined &&
          rpdSwapInfo?.error !== undefined ? (
          <div className="diagErrorCont">
            <ExceptionBox
              exBoxType="error"
              headerText={labels.rpdSwapError}
              errCode={rpdSwapInfo?.error.errorCode}
              ErrorMSG={rpdSwapInfo?.error.errorMessage}
              srcSystem={rpdSwapInfo?.error.sourceSystem}
              bodyText={rpdSwapInfo?.error.responseFromSource}
              primaryBtn={false}
              secBtn={false}
            />
          </div>
        ) : rpdSwapInfo?.data !== undefined &&
          rpdSwapInfo?.error === undefined ? (
          <div className="diagErrorCont">
            <OrderSuccessBox
              headerText={labels.rpdCreateOrderSwapSuccess}
              bodyText={labels.orderStatusCheck + rpdSwapInfo?.data.orderid}
              primaryBtn={false}
              secBtn={false}
              orderid={rpdSwapInfo?.data.orderid}
              rpdOrderInfo={rpdSwapInfo}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default RPDSwapTab;
