import { shallowEqual, useSelector } from "react-redux";

import CustOverView from "./CustOverView";
import DeviceOverView from "./DeviceOverView";
import OverViewTS from "./OverViewTS";
import "../../design-tokens/account-page/OverViewTab.scss";
import PodsOverViewTable from "../pods/PodsOverViewTable";
import DiagContainer from "../reusable/DiagContainer";

const OverviewTab = (props: any) => {
  const { serviceAddress, handleAddressSelect, refreshPods } = props;
  const {
    selectedServiceAddress,
    selectedSA,
    sortedDevices,
    podsLoading,
    podList,
    podError,
  } = useSelector(
    (state: any) => ({
      selectedServiceAddress:
        state.AccountState.ActiveAccountDetails.selectedServiceAddress,
      selectedSA: state.AccountState.ActiveAccountDetails.selectedSA,
      sortedDevices: state.AccountState.ActiveAccountDetails.sortedDevices,
      podList: state.AccountState.PodInfo.data,
      podError: state.AccountState.PodInfo.error,
      podsLoading: state.AccountState.PodInfo.isLoading,
    }),
    shallowEqual
  );

  const macAddress = sortedDevices
    ? sortedDevices
        ?.filter(
          (d: any) =>
            d.deviceCategory === "CM" ||
            d.deviceCategory === "GW_GPON" ||
            d.deviceCategory === "GW_FWA"
        )
        ?.map((d: any) => d.macAddress)
    : null;
  return (
    <div className="overviewContainer">
      {selectedSA !== undefined ? (
        <>
          <section className="accountInfoCont">
            <CustOverView
              selectedSA={selectedSA}
              selectedServiceAddress={selectedServiceAddress}
              serviceAddress={serviceAddress}
              handleAddressSelect={handleAddressSelect}
            />
            <DeviceOverView selectedSA={selectedSA} />
            <DiagContainer
              isLoading={podsLoading}
              data={podList}
              error={podError}
              sectionName="Pods Devices"
              handleRefresh={() => refreshPods()}
            >
              <PodsOverViewTable refreshPods={refreshPods} />
            </DiagContainer>
          </section>
          {macAddress !== null ? (
            <section className="techSupportCont">
              <OverViewTS macAddress={macAddress} />
            </section>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default OverviewTab;
