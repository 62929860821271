import axios, { AxiosRequestConfig } from "axios";
import { IHttpClientRequestParameters } from "./IHttpClientRequestParameters";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "../../azure/authConfig";
import { IHttpClientRequestRetryParameters } from "./IHttpClientRequestRetryParameters";

export class HttpClient {
  static msalInstance = new PublicClientApplication(msalConfig);
  static isTokenExpired = false;

  static acquireAccessToken = async (msalInstance: PublicClientApplication) => {
    var activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    var accounts = msalInstance.getAllAccounts();

    if (
      (!activeAccount && accounts?.length === 0) ||
      HttpClient.isTokenExpired
    ) {
      HttpClient.isTokenExpired = false;
      await msalInstance
        .loginPopup(loginRequest)
        .then(() => {
          activeAccount = msalInstance.getActiveAccount();
          accounts = msalInstance.getAllAccounts();
        })
        .catch((e) => {
          console.log(e);
        });
    }
    const request = {
      scopes: ["User.Read"],
      account: activeAccount || accounts?.[0],
    };

    const authResult = await msalInstance.acquireTokenSilent(request);

    return authResult.idToken;
  };

  //Refresh token if status code is 401
  static refreshAccessTokenWhenRequired() {
    axios.interceptors.response.use(
      (res) => res,
      (err) => {
        if (err.response.status === 401) {
          this.isTokenExpired = true;
        }
        return Promise.reject(err);
      }
    );
  }

  static headers: { [k: string]: any } = {
    "Content-Type": "application/json",
  };

  static get<T>(parameters: IHttpClientRequestParameters<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, params } = parameters;
      let requestParameters: IHttpClientRequestRetryParameters = {
        type: "get",
        url,
        params,
        resolve,
        reject,
      };
      this.retryRequest(requestParameters);
    });
  }

  static post<T>(
    parameters: IHttpClientRequestParameters<T>,
    payload: any
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, params } = parameters;
      let requestParameters: IHttpClientRequestRetryParameters = {
        type: "post",
        url,
        params,
        payload,
        resolve,
        reject,
      };
      this.retryRequest(requestParameters);
    });
  }

  static put<T>(
    parameters: IHttpClientRequestParameters<T>,
    payload: any
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, params } = parameters;
      let requestParameters: IHttpClientRequestRetryParameters = {
        type: "put",
        url,
        params,
        payload,
        resolve,
        reject,
      };
      this.retryRequest(requestParameters);
    });
  }

  static retryRequest(requestParamerets: IHttpClientRequestRetryParameters) {
    const {
      type,
      url,
      params,
      payload,
      resolve,
      maxRetries = 3,
      retries = 0,
    } = requestParamerets;

    if (retries < maxRetries) {
      this.acquireAccessToken(this.msalInstance).then((token) => {
        this.headers["Authorization"] = "Bearer " + token;

        const options: AxiosRequestConfig = {
          headers: this.headers,
          params: params,
        };

        switch (type) {
          case "get":
            axios
              .get(url, options)
              .then((response: any) => {
                resolve(response.data);
              })
              .catch((response: any) => {
                let params: IHttpClientRequestRetryParameters = {
                  ...requestParamerets,
                  response,
                  maxRetries,
                  retries,
                };
                this.retryRequestCatchHandler(params);
              });
            break;
          case "post":
            axios
              .post(url, payload, options)
              .then((response: any) => {
                resolve(response.data);
              })
              .catch((response: any) => {
                let params: IHttpClientRequestRetryParameters = {
                  ...requestParamerets,
                  response,
                  maxRetries,
                  retries,
                };
                this.retryRequestCatchHandler(params);
              });
            break;
          case "put":
            axios
              .put(url, payload, options)
              .then((response: any) => {
                resolve(response.data);
              })
              .catch((response: any) => {
                let params: IHttpClientRequestRetryParameters = {
                  ...requestParamerets,
                  response,
                  maxRetries,
                  retries,
                };
                this.retryRequestCatchHandler(params);
              });
            break;

          default:
            break;
        }
      });
    }
  }

  static retryRequestCatchHandler(
    requestParamerets: IHttpClientRequestRetryParameters
  ) {
    let { reject, response, maxRetries, retries = 0 } = requestParamerets;

    if (response?.response?.status === 401 && maxRetries !== retries + 1) {
      this.isTokenExpired = true;
      if (retries > 1) {
        sessionStorage.clear();
      }
      retries++;
      let params: IHttpClientRequestRetryParameters = {
        ...requestParamerets,
        retries,
      };
      this.retryRequest(params);
    } else {
      reject(response?.response?.data);
    }
  }
}
