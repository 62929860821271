import { createSlice } from "@reduxjs/toolkit";

import storeConst from "../../../storeConst";
import { apiCallBegan } from "../../../middleware/apiActions";
import {
  POWERSUPPLY_POWERTRACE_ADDRESSES,
  POWERSUPPLY_POWERTRACE,
} from "../../../middleware/apiEndpoints";
import { incrApiCall, decrApiCall } from "../mapDetails";

const slice = createSlice({
  name: "powertrace",
  initialState: {
    isActive: false,
    rensNumber: undefined,
    psInfo: undefined, //power supply info
    rootNodeSiteId: undefined,
    elements: {
      isLoading: false,
      data: undefined,
      error: undefined,
      status: "",
    },
    addresses: {
      isLoading: false,
      data: undefined,
      error: undefined,
      status: "",
    },
  },
  reducers: {
    powertraceElementsBegin: (powertraceElements) => {
      powertraceElements.elements.isLoading = true;
      powertraceElements.elements.status = "";
      powertraceElements.elements.data = undefined;
      powertraceElements.elements.error = undefined;
    },
    powertraceElementsSuccess: (powertraceElements, { payload }) => {
      powertraceElements.isActive = true;
      powertraceElements.rensNumber = payload.rensNumber;
      powertraceElements.psInfo = payload.psInfo;
      powertraceElements.rootNodeSiteId = payload.rootNodeSiteId;
      powertraceElements.elements.isLoading = false;
      powertraceElements.elements.data = payload?.data;
      powertraceElements.elements.error = undefined;
      powertraceElements.elements.status = storeConst.OK;
    },
    powertraceElementsFailure: (powertraceElements, { payload }) => {
      powertraceElements.isActive = false;
      powertraceElements.rensNumber = undefined;
      powertraceElements.psInfo = undefined;
      powertraceElements.rootNodeSiteId = payload.rootNodeSiteId;
      powertraceElements.elements.isLoading = false;
      powertraceElements.elements.error = payload;
      powertraceElements.elements.data = undefined;
      powertraceElements.elements.status = storeConst.ERROR;
    },
    powertraceAddressesBegin: (powertraceAddresses) => {
      powertraceAddresses.addresses.isLoading = true;
      powertraceAddresses.addresses.status = "";
      powertraceAddresses.addresses.data = undefined;
    },
    powertraceAddressesSuccess: (powertraceAddresses, { payload }) => {
      powertraceAddresses.addresses.isLoading = false;
      powertraceAddresses.addresses.data = payload;
      powertraceAddresses.addresses.status = storeConst.OK;
      powertraceAddresses.addresses.error = undefined;
    },
    powertraceAddressesFailure: (powertraceAddresses, { payload }) => {
      powertraceAddresses.addresses.isLoading = false;
      powertraceAddresses.addresses.error = payload;
      powertraceAddresses.addresses.data = undefined;
      powertraceAddresses.addresses.status = storeConst.ERROR;
    },
    resetPowertraceData: (powertrace) => {
      powertrace.isActive = false;
      powertrace.rensNumber = undefined;
      powertrace.psInfo = undefined;
      powertrace.rootNodeSiteId = undefined;
      powertrace.elements = {
        isLoading: false,
        data: undefined,
        error: undefined,
        status: "",
      };
      powertrace.addresses = {
        isLoading: false,
        data: undefined,
        error: undefined,
        status: "",
      };
    },
    setPowertraceIsActive: (powertrace, { payload }) => {
      powertrace.isActive = payload;
    },
  },
});

export const { resetPowertraceData, setPowertraceIsActive } = slice.actions;
//should not be exposed to outside
const {
  powertraceElementsBegin,
  powertraceElementsSuccess,
  powertraceElementsFailure,
  powertraceAddressesBegin,
  powertraceAddressesSuccess,
  powertraceAddressesFailure,
} = slice.actions;
export default slice.reducer;

export const getPowertraceElements = (psInfo: any) => (dispatch: any) => {
  dispatch(
    apiCallBegan({
      url: POWERSUPPLY_POWERTRACE(psInfo?.data?.rensNumber),
      method: "get",
      onStart: powertraceElementsBegin.type,
      onStartDispatch: incrApiCall.type,
      onSuccess: powertraceElementsSuccess.type,
      onSuccessDispatch: decrApiCall.type,
      onError: powertraceElementsFailure.type,
      onErrorDispatch: decrApiCall.type,
      props: {
        rootNodeSiteId: psInfo?.rootNodeSiteId,
        rensNumber: psInfo?.data?.rensNumber,
        psInfo,
      },
    })
  );
};

export const getPowertraceAddresses =
  (tapNetworkIds: Array<string>) => (dispatch: any) => {
    dispatch(
      apiCallBegan({
        url: POWERSUPPLY_POWERTRACE_ADDRESSES,
        method: "post",
        data: tapNetworkIds,
        onStart: powertraceAddressesBegin.type,
        onStartDispatch: incrApiCall.type,
        onSuccess: powertraceAddressesSuccess.type,
        onSuccessDispatch: decrApiCall.type,
        onError: powertraceAddressesFailure.type,
        onErrorDispatch: decrApiCall.type,
      })
    );
  };
