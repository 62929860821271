import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

const SearchDropDown = (props:any) => {

  const [options,setOptions] = useState(props?.options || [])
  const [filteredOptions, setFilteredOptions] = useState(options)
  const [ hideList, setHideList] = useState(true);

  const filterOptions = ()=>{
    let filteredOptions = options
       filteredOptions = options?.filter((el:any)=>{
        return el.includes(props?.selectedOption)
      })
  
    setFilteredOptions(filteredOptions)
  }

  useEffect(()=>{
    setFilteredOptions(props?.options)
    setOptions(props?.options)
  },[props?.options])

  useEffect(()=>{
    filterOptions()
  },[props?.selectedOption])

  return (
      <Form.Group>
            <Form.Label>{props?.label}</Form.Label>
            <Form.Control
              name="search"
              onChange={(e) => props?.setSelectedOption(e.target.value)}
              onClick={()=>setHideList(false)}
              type="text"
              value={props?.selectedOption}
              maxLength={50}
              placeholder={props?.placeholder}
            />
            {props?.options &&
              <select style= {{width:"100%", display: hideList?"none":"inline"}} size={options?.length || 0}>
                {filteredOptions?.map((el:any)=>{
                  return <option value={el} onClick={(e)=>{ props?.setSelectedOption(el); setHideList(true)}}>{el}</option>
                })}
                {props?.selectedOption && filteredOptions?.length==0 && <option style={{backgroundColor:"#05fff7"}} value={props?.selectedOption} onClick={(e)=>{setHideList(true); props?.setSelectedOption(props?.selectedOption)}}>{props?.selectedOption}</option>}
              </select>
            }
     </Form.Group>
  );
};

export default SearchDropDown;