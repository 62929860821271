import { combineReducers } from "redux";
import { FeedbackReducer } from "./feedback/FeedbackReducer";
import { FeedbackCommentsReducer } from "./feedback/FeedbackCommentsReducer";
import { FeedbackCreationReducer } from "./feedback/FeedbackCreationReducer";
import { CommentCreationReducer } from "./feedback/CommentCreationReducer";
import { AttachmentReducer } from "./feedback/AttachmentReducer";
import { FeedbackUpdateReducer } from "./feedback/FeedbackUpdateReducer";
import { IndividualIssueReducer } from "./feedback/IndividualIssueReducer";
import { MonthlyFeedbackReducer } from "./feedback/MonthlyFeedbackReducer";
import { GetMonthlyFeedbackReducer } from "./feedback/MonthlyFeedbackReducer";
import { TechnicianFeedbackReducer } from "./feedback/TechnicianFeedbackReducer";
import { HistoryReducer } from "./history/HistoryReducer";
import { WorkOrdersReducer } from "./technicians/WorkOrdersReducer";
import { NotificationListReducer } from "./notification/NotificationListReducer";
import { NotiCRUDReducer } from "./notification/NotiCRUDReducer";
import UserPrefConfigReducer from "./preferences/UserPrefConfigReducer";

//Changes by M&T Team Added
import AllNodesReducer from "./node/AllNodesReducer";
import { allNodeReducers } from "./node/NodeReducer";
//Changes by M&T end here

import { FormsHistoryReducer } from "./history/FormsHistoryReducer";
import { allAccountReducers } from "./account/AccReducer";
import { allGponNodeReducer } from "./gpon/AllGponNodeReducer";
import AllAccountsReducer from "./account/AllAccountsReducer";
import reducerIndex from "../ducksPattern/reducerIndex";
import userPrefConfig from "../ducksPattern/userPrefConfig";
import fileManagerReducer from "../ducksPattern/filemanager/fileManagerReducer";

export const rootReducer = (state, action) => {
  if (action.type === "RESET_APP") {
    /* state = undefined; */
    const { TechniciansInfo, NotificationList } = state;
    state = {
      TechniciansInfo,
      NotificationList,
    };
  }
  if (action.type === "RESET_ACC") {
    state.AccountState = undefined;
  }
  if (action.type === "SWITCH_ACCOUNT") {
    state.AccountState = action.payload;
  }
  if (action.type === "RESET_NODE") {
    state.NodeState = undefined;
  }
  if (action.type === "SWITCH_NODE") {
    state.NodeState = action.payload;
  }
  return allReducers(state, action);
};

const allReducers = combineReducers({
  slices: reducerIndex,
  AllAccounts: AllAccountsReducer,
  AccountState: allAccountReducers,
  FeedbackInfo: FeedbackReducer,
  FeedbackCommentsInfo: FeedbackCommentsReducer,
  FeedbackCreationInfo: FeedbackCreationReducer,
  FileManagerState: fileManagerReducer,
  CommentCreationInfo: CommentCreationReducer,
  AttachmentInfo: AttachmentReducer,
  FeedbackUpdateInfo: FeedbackUpdateReducer,
  IndividualIssueInfo: IndividualIssueReducer,
  MonthlyFeedbackInfo: MonthlyFeedbackReducer,
  GetMonthlyFeedbackInfo: GetMonthlyFeedbackReducer,
  TechnicianFeedbackInfo: TechnicianFeedbackReducer,
  workOrdersList: WorkOrdersReducer,
  NotiCRUDInfo: NotiCRUDReducer,
  NotificationList: NotificationListReducer,
  HistoryInfo: HistoryReducer,
  FormsHistoryInfo: FormsHistoryReducer,
  AllNodes: AllNodesReducer,
  NodeState: allNodeReducers,
  GponState: allGponNodeReducer,
  UserPrefConfig: UserPrefConfigReducer,
  userDucksConfig: userPrefConfig,
});
