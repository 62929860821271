import { InfoBox } from "@react-google-maps/api";
import { useDispatch } from "react-redux";
import { HiArrowUp, HiArrowDown } from "react-icons/hi";
import { AiFillBulb } from "react-icons/ai";
import { FaNetworkWired } from "react-icons/fa";

import {
  GetMapDetailsProps,
  usePowertraceStates,
  useRfDownstreamTraceState,
  useRfUpstreamTraceState,
} from "../../../util/reduxFunctions/getTopologyState";
import {
  getRfDownstreamTraceElements,
  setRfDownstreamTraceIsActive,
} from "../../../store/slices/mapItems/trace/rf/rfDownstreamTrace";
import { setShowRightClickMenu } from "../../../store/slices/mapItems/mapDetails";
import { mapElementStyles } from "../../../config/mapItemsConfig";
import {
  getRfUptreamTraceElements,
  setRfUpstreamTraceIsActive,
} from "../../../store/slices/mapItems/trace/rf/rfUpstreamTrace";
import TopologyLabels from "../../../constants/topologyLabels";
import colors from "../../../config/colors";
import appConst from "../../../constants/appConst";
import {
  getPowertraceElements,
  setPowertraceIsActive,
} from "../../../store/slices/mapItems/powertrace/powertrace";
import {
  setDrawerTabIndex,
  setLogicalViewTabIndex,
} from "../../../store/slices/mapDrawer";

export default function ElementRightClickMenu() {
  const labels = TopologyLabels();
  const dispatch = useDispatch();
  const { rightClickMapElement, showRightClickMenu } = GetMapDetailsProps();
  const {
    rensNumber,
    elements,
    isActive: isPowertaceActive,
  } = usePowertraceStates();
  const rightClickRensNumber =
    rightClickMapElement?.properties?.data?.rensNumber;
  const isPowertraceSelected =
    isPowertaceActive && rightClickRensNumber === rensNumber;
  const {
    data: rfDsData,
    isActive: isRfDsActive,
    networkId: rfDsNetworkId,
  } = useRfDownstreamTraceState();
  const {
    data: rfUsData,
    isActive: isRfUsActive,
    networkId: rfUsNetworkId,
  } = useRfUpstreamTraceState();
  const rightClickNetworkId = rightClickMapElement?.properties?.data?.networkId;
  const isRfDsSelected = isRfDsActive && rightClickNetworkId === rfDsNetworkId;
  const isRfUsSelected = isRfUsActive && rightClickNetworkId === rfUsNetworkId;

  const onPowertraceClick = () => {
    dispatch(setShowRightClickMenu(false));

    if (isPowertraceSelected) {
      dispatch(setPowertraceIsActive(false));
    } else if (rightClickRensNumber !== rensNumber) {
      dispatch(setPowertraceIsActive(true));
      dispatch(getPowertraceElements(rightClickMapElement?.properties));
    } else if (rightClickRensNumber === rensNumber && elements !== undefined) {
      dispatch(setPowertraceIsActive(true));
    }
  };

  const onDownstreamClick = () => {
    dispatch(setShowRightClickMenu(false));

    if (isRfDsSelected) {
      dispatch(setRfDownstreamTraceIsActive(false));
    } else if (rightClickNetworkId !== rfDsNetworkId) {
      dispatch(setRfDownstreamTraceIsActive(true));
      dispatch(
        getRfDownstreamTraceElements(
          rightClickNetworkId,
          rightClickMapElement?.properties?.rootNodeSiteId,
          rightClickMapElement?.properties?.type,
          rightClickMapElement?.properties?.data
        )
      );
    } else if (
      rightClickNetworkId === rfDsNetworkId &&
      rfDsData !== undefined
    ) {
      dispatch(setRfDownstreamTraceIsActive(true));
    }
  };

  const onUpstreamClick = () => {
    dispatch(setShowRightClickMenu(false));

    if (isRfUsSelected) {
      dispatch(setRfUpstreamTraceIsActive(false));
    } else if (rightClickNetworkId !== rfUsNetworkId) {
      dispatch(setRfUpstreamTraceIsActive(true));
      dispatch(
        getRfUptreamTraceElements(
          rightClickNetworkId,
          rightClickMapElement?.properties?.rootNodeSiteId,
          rightClickMapElement?.properties?.type,
          rightClickMapElement?.properties?.data
        )
      );
    } else if (
      rightClickNetworkId === rfUsNetworkId &&
      rfUsData !== undefined
    ) {
      dispatch(setRfUpstreamTraceIsActive(true));
    }
  };

  const onRfTraceLogicalViewClick = (traceType: any) => {
    dispatch(setLogicalViewTabIndex(traceType === appConst.upstream ? 1 : 0));
    dispatch(setDrawerTabIndex(8));
  };

  return (
    <>
      {showRightClickMenu && rightClickMapElement?.geometry && (
        <InfoBox
          position={rightClickMapElement?.geometry}
          options={{ zIndex: 1000, closeBoxURL: "" }}
        >
          <div className="infoWinCont">
            {rightClickMapElement?.properties?.type === appConst.powerSupply ? (
              <div
                style={{
                  backgroundColor: isPowertraceSelected && colors.platinum,
                }}
                className="itemCont"
                onClick={onPowertraceClick}
              >
                <AiFillBulb
                  style={{ marginRight: 5 }}
                  color={
                    isPowertraceSelected
                      ? mapElementStyles?.powertrace?.line?.strokeColor
                      : colors.greyMedium
                  }
                />
                <label
                  style={{
                    color:
                      isPowertraceSelected &&
                      mapElementStyles?.powertrace?.line?.strokeColor,
                  }}
                >{`${labels.powertrace}`}</label>
              </div>
            ) : (
              <>
                <div
                  style={{
                    backgroundColor: isRfDsSelected && colors.platinum,
                  }}
                  className="itemCont"
                  onClick={onDownstreamClick}
                >
                  <HiArrowDown
                    style={{ marginRight: 5 }}
                    color={
                      isRfDsSelected
                        ? mapElementStyles?.trace?.downstream?.line?.strokeColor
                        : colors.greyMedium
                    }
                  />
                  <label
                    style={{
                      color:
                        isRfDsSelected &&
                        mapElementStyles?.trace?.downstream?.line?.strokeColor,
                    }}
                  >{`${labels.downstream} ${labels.trace}`}</label>
                </div>
                <div
                  style={{ flex: 1, height: 3, backgroundColor: colors.white }}
                />
                {isRfDsSelected && (
                  <>
                    <div
                      style={{
                        backgroundColor: colors.platinum,
                      }}
                      className="itemCont"
                      onClick={() =>
                        onRfTraceLogicalViewClick(appConst.downstream)
                      }
                    >
                      <FaNetworkWired
                        style={{ marginRight: 5 }}
                        color={
                          mapElementStyles?.trace?.downstream?.line?.strokeColor
                        }
                      />
                      <label
                        style={{
                          color:
                            mapElementStyles?.trace?.downstream?.line
                              ?.strokeColor,
                        }}
                      >{`${labels.ds} ${labels.logicalView}`}</label>
                    </div>
                    <div
                      style={{
                        flex: 1,
                        height: 3,
                        backgroundColor: colors.white,
                      }}
                    />
                  </>
                )}
                <div
                  style={{
                    backgroundColor: isRfUsSelected && colors.platinum,
                  }}
                  className="itemCont"
                  onClick={onUpstreamClick}
                >
                  <HiArrowUp
                    style={{ marginRight: 5 }}
                    color={
                      isRfUsSelected
                        ? mapElementStyles?.trace?.upstream?.line?.strokeColor
                        : colors.greyMedium
                    }
                  />
                  <label
                    style={{
                      color:
                        isRfUsSelected &&
                        mapElementStyles?.trace?.upstream?.line?.strokeColor,
                    }}
                  >{`${labels.upstream} ${labels.trace}`}</label>
                </div>
                {isRfUsSelected && (
                  <>
                    <div
                      style={{
                        flex: 1,
                        height: 3,
                        backgroundColor: colors.white,
                      }}
                    />
                    <div
                      style={{
                        backgroundColor: colors.platinum,
                      }}
                      className="itemCont"
                      onClick={() =>
                        onRfTraceLogicalViewClick(appConst.upstream)
                      }
                    >
                      <FaNetworkWired
                        style={{ marginRight: 5 }}
                        color={
                          mapElementStyles?.trace?.upstream?.line?.strokeColor
                        }
                      />
                      <label
                        style={{
                          color:
                            mapElementStyles?.trace?.upstream?.line
                              ?.strokeColor,
                        }}
                      >{`${labels.us} ${labels.logicalView}`}</label>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </InfoBox>
      )}
    </>
  );
}
