import SimpleDataTable from "../../topology/components/reusable/SimpleDataTable";
import {
  convertCMData,
  getThrColoredTableCell,
  getValueByStrMapping,
  formatCamelCase,
} from "../reusable/Util";

interface Props {
  cmConfig?: any;
  cmData?: any;
  preCmData?: any;
  usFlag?: any;
  isPrecheck?: boolean;
}

const CmSpecSummary = ({ cmConfig, cmData, preCmData, usFlag, isPrecheck }: Props) => {
  let ofdmData = usFlag
    ? cmData?.ofdm?.upstream?.channels
    : cmData?.ofdm?.downstreamChannels;
  let preOfdmData = usFlag
    ? preCmData?.ofdm?.upstream?.channels
    : preCmData?.ofdm?.downstreamChannels;
  let scQAMData = usFlag
    ? cmData?.docsis30?.upstream
    : cmData?.docsis30?.downstream;
  return (
    <>
      {preCmData ? (
        <>
          <SimpleDataTable
            headers={cmConfig?.precheckHeaders}
            tBody={Object.keys(cmConfig?.dataKeys)?.map(
              (key: any, j: number) => {
                let summaryConfig = cmConfig?.dataKeys;
                if (summaryConfig[key]?.dataSource === "ofdm") {
                  return ofdmData?.map((ch: any, i: number) => {
                    let preCh = preOfdmData[i];
                    let preAttValue = getValueByStrMapping(
                      preCh,
                      summaryConfig[key]?.dataKey
                    );
                    let preComputedValue = convertCMData(key, preAttValue);
                    let attValue = getValueByStrMapping(
                      ch,
                      summaryConfig[key]?.dataKey
                    );
                    let computedValue = convertCMData(key, attValue);
                    return (
                      <tr key={j}>
                        <td>
                          {summaryConfig[key]?.label} - {ch?.channelId}
                        </td>
                        {getThrColoredTableCell(
                          preComputedValue,
                          "col_0_${j}",
                          true
                        )}
                        {getThrColoredTableCell(
                          computedValue,
                          "col_1_${j}",
                          true
                        )}
                      </tr>
                    );
                  });
                } else {
                  let preAttValue = getValueByStrMapping(
                    preCmData?.docsis,
                    summaryConfig[key]?.dataKey
                  );
                  let attValue = getValueByStrMapping(
                    cmData?.docsis,
                    summaryConfig[key]?.dataKey
                  );
                  let preComputedValue = convertCMData(key, preAttValue);
                  let computedValue = convertCMData(key, attValue);
                  return (
                    <tr key={j}>
                      <td>{summaryConfig[key]?.label}</td>
                      {getThrColoredTableCell(
                        preComputedValue,
                        "col_0_${j}",
                        true
                      )}
                      {getThrColoredTableCell(
                        computedValue,
                        "col_1_${j}",
                        true
                      )}
                    </tr>
                  );
                }
              }
            )}
          />
        </>
      ) : (
        <div className="cmSpecSumm">
          <span className="cmSpecTableHead">{isPrecheck ? "Precheck" : "Current"}</span>
          <SimpleDataTable
            headers={cmConfig?.headers}
            tBody={
              <>
                {usFlag ? (
                  <>
                    {cmConfig?.dataSet?.map((ch: any, j: number) => {
                      return (
                        <tr key={j}>
                          {Object.keys(ch)?.map((key: string, k: number) => {
                            let attObj =
                              key === "level"
                                ? ch[key]?.toUpperCase()
                                : getValueByStrMapping(scQAMData, ch[key]);
                            let colorFlag = key === "level" ? false : true;
                            return getThrColoredTableCell(attObj, k, colorFlag);
                          })}
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {cmConfig?.dataSet?.scQam?.map((ch: any, j: number) => {
                      return (
                        <tr key={j}>
                          {Object.keys(ch).map((key: string, k: number) => {
                            let attObj =
                              key === "level"
                                ? ch[key]?.toUpperCase()
                                : getValueByStrMapping(scQAMData, ch[key]);
                            let colorFlag = key === "level" ? false : true;
                            return getThrColoredTableCell(attObj, k, colorFlag);
                          })}
                        </tr>
                      );
                    })}
                    {ofdmData?.map((ofdmCh: any, j: number) => {
                      let chConfig: any = cmConfig.dataSet?.ofdm;
                      return (
                        <tr key={j}>
                          {Object.keys(chConfig)?.map((key: any, k: number) => {
                            let attObj =
                              key === "level"
                                ? `${formatCamelCase(chConfig[key])} - ${ofdmCh?.channelId
                                }`
                                : getValueByStrMapping(ofdmCh, chConfig[key]);
                            let colorFlag = key === "level" ? false : true;
                            return getThrColoredTableCell(attObj, k, colorFlag);
                          })}
                        </tr>
                      );
                    })}
                  </>
                )}
              </>
            }
          />
        </div>
      )}
    </>
  );
};

export default CmSpecSummary;
