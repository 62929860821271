import { Menu, MenuItem } from "@material-ui/core";
import { useEffect, useState } from "react";
import { BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import DeviceActionClient from "../../network/httpclient/deviceActions/DeviceActionClient";
import ConfirmPopUp from "./ConfirmPopUp";
import ResponseSnackBar from "./ResponseSnackBar";
import { resetReboot } from "../../store/actions/account/deviceActions/RebootAction";
import { factoryResetStaus } from "../../store/actions/account/deviceActions/FactoryResetAction";
import { resetZap } from "../../store/actions/account/deviceActions/ZapAction";
import {resetHardZap} from "../../store/actions/account/deviceActions/HardZapAction";
import AppLabels from "../../constants/App_Labels";
import { formatMacAddress, getCMW3ID } from "./Util";

const DeviceActionMenu = (props: any) => {
  const { menuType, deviceInfo, deviceActions, deviceName } = props;
  const labels = AppLabels();
  const rebootSN = ["IPTV", "ODU", "ONT"];
  const rebootMAC = ["CM","GW","EMTA", "IEMTA", "DESTB", "STB"];
  const dispatch = useDispatch();
  const [showConfirm, setShowConfirm] = useState(false);
  const [open, setOpen] = useState(false);
  const [deviceSN, setDeviceSN] = useState("");
  const [requestType, setRequestType] = useState("default");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const handleConfirmClose = () => setShowConfirm(false);
  const [aOrigin, setaOrigin] = useState({
    vertical: 15,
    horizontal: 10,
  });
  useEffect(() => {
    if (menuType === "vertical") {
      setaOrigin({
        vertical: 40,
        horizontal: 15,
      });
    }
  }, [menuType]);
  const {
    rebootStatus,
    factoryResetStatus,
    zapDeviceStatus,
    hardzapDeviceStatus,
    data,
    selectedSA,
  } = useSelector(
    (state: any) => ({
      rebootStatus: state.AccountState.rebootDevice.status,
      factoryResetStatus: state.AccountState.FactoryResetDevice.status,
      zapDeviceStatus: state.AccountState.ZapDevice.status,
      hardzapDeviceStatus: state.AccountState.HardZapReducer.status,
      data: state.AccountState.ActiveAccountDetails.data,
      selectedSA: state.AccountState.ActiveAccountDetails.selectedSA,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (rebootStatus === "OK") {
      setRequestType("Reboot");
      setOpen(true);
    } else if (factoryResetStatus === "OK") {
      setRequestType("Factory Reset");
      setOpen(true);
    } else if (zapDeviceStatus === "OK") {
      setRequestType("Zap");
      setOpen(true);
    } else if (hardzapDeviceStatus === "OK") {
      setRequestType("Hard Zap");
      setOpen(true);
    }else if (rebootStatus === "ERROR") {
      setRequestType("Reboot");
      setOpen(true);
    } else if (factoryResetStatus === "ERROR") {
      setRequestType("Factory Reset");
      setOpen(true);
    } else if (zapDeviceStatus === "ERROR") {
      setRequestType("Zap");
      setOpen(true);
    }
    else if (hardzapDeviceStatus === "ERROR") {
      setRequestType("Hard Zap");
      setOpen(true);
    }
  }, [rebootStatus, factoryResetStatus, zapDeviceStatus,hardzapDeviceStatus]);
  const handleActionClick = (
    event: React.MouseEvent<HTMLElement>,
    actionType: string
  ) => {
    if (
      actionType === "Reboot" ||
      actionType === "Factory Reset" ||
      actionType === "Zap" ||
      actionType === "Soft Zap" ||
      actionType === "Hard Zap"
    ) {
      setRequestType(actionType);
      setShowConfirm(true);
      handleMenuClose();
    }
  };
  const handleClose = () => {
    if (requestType === "Reboot" && deviceSN === deviceInfo.serialNumber) {
      dispatch(resetReboot());
    } else if (
      requestType === "Factory Reset" &&
      deviceSN === deviceInfo.serialNumber
    ) {
      dispatch(factoryResetStaus());
    } else if ((requestType === "Zap" || requestType === "Soft Zap") && deviceSN === deviceInfo.serialNumber) {
      dispatch(resetZap());
    }
    else if (requestType === "Hard Zap" && deviceSN === deviceInfo.serialNumber) {
      dispatch(resetHardZap());
    }
    setDeviceSN("");
    setOpen(false);
  };
  const handleConfirm = () => {
    if (requestType === "Reboot") performReboot();
    else if (requestType === "Factory Reset") factoryResetDevice();
    else if (requestType === "Zap" || requestType==="Soft Zap" ) performZapDevice();
    else if (requestType === "Hard Zap") performHardZapDevice();
  };
  const performReboot = () => {
    if (
      deviceInfo.macAddress !== undefined &&
      rebootMAC?.indexOf(deviceName) > -1
    ) {
      let deviceDetails = {
        serialNum: deviceInfo.serialNumber,
        macAddress: formatMacAddress(deviceInfo.macAddress),
      };

      dispatch(
        DeviceActionClient.rebootDevice(
          deviceName,
          deviceDetails,
          getCMW3ID(selectedSA)
        )
      );
    } else if (
      deviceInfo.serialNumber !== undefined &&
      rebootSN?.indexOf(deviceName) > -1
    ) {
      let dOUI = "";
      if (deviceInfo?.hasOwnProperty("oui")) dOUI = deviceInfo.oui;
      let deviceDetails = {
        oui: dOUI,
        serialNum: deviceInfo.serialNumber,
      };
      dispatch(
        DeviceActionClient.rebootDevice(
          deviceName,
          deviceDetails,
          getCMW3ID(selectedSA)
        )
      );
    }
    setDeviceSN(deviceInfo.serialNumber);
    setShowConfirm(false);
  };
  const factoryResetDevice = () => {
    if (deviceInfo.serialNumber !== undefined) {
      dispatch(
        DeviceActionClient.factoryResetDevice(
          deviceInfo.macAddress,
          deviceInfo.serialNumber,
          getCMW3ID(selectedSA)
        )
      );
      setDeviceSN(deviceInfo.serialNumber);
      setShowConfirm(false);
    }
  };
  const performZapDevice = () => {
    if (deviceInfo.serialNumber !== undefined) {
      dispatch(
        DeviceActionClient.zapDevice(
          deviceName,
          deviceInfo,
          data?.accountNumber
        )
      );
      setDeviceSN(deviceInfo.serialNumber);
      setShowConfirm(false);
    }
  };
  const performHardZapDevice = () => {
    if (deviceInfo.serialNumber !== undefined) {
      dispatch(
        DeviceActionClient.hardzapDevice(
          deviceName,
          deviceInfo,
          data?.accountNumber
        )
      );
      setDeviceSN(deviceInfo.serialNumber);
      setShowConfirm(false);
    }
  };

  return (
    <div className="deviceActionCont">
      <button onClick={handleMenuClick} className={`deviceTabMenu-${menuType}`}>
        {menuType === "vertical" ? (
          <BsThreeDotsVertical style={{ color: "#333" }} />
        ) : (
          <BsThreeDots />
        )}
      </button>
      <Menu
        className="deviceActionMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={aOrigin}
        disableScrollLock={true}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {deviceActions?.length !== 0 ? (
          deviceActions?.map((a: any, i: number) => {
            return (
              <MenuItem
                key={i}
                className="runAction"
                onClick={(e) => handleActionClick(e, a.action)}
              >
                {a.action}
              </MenuItem>
            );
          })
        ) : (
          <>{labels.NO_ACTIONS}</>
        )}
      </Menu>
      <ConfirmPopUp
        showVal={showConfirm}
        headerText={labels.CAUTION.toUpperCase()}
        bodyHeadText={`${labels.YOU_ARE_TRYING_TO} ${requestType} ${labels.THIS_DEVICE}.`}
        bodyText={labels.ARE_YOU_SURE_TO_PROCEED}
        cancelText={labels.CANCEL}
        confirmText={labels.PROCEED}
        handleConfirm={handleConfirm}
        handleClose={handleConfirmClose}
      />
      <ResponseSnackBar
        open={open}
        requestType={requestType}
        responseType={
          requestType === "Reboot"
            ? rebootStatus
            : requestType === "Factory Reset"
            ? factoryResetStatus
            : requestType === "Zap" || requestType === "Soft Zap" 
            ? zapDeviceStatus
            : requestType === "Hard Zap"
            ? hardzapDeviceStatus
            : ""
        }
        handleClose={handleClose}
      />
    </div>
  );
};

export default DeviceActionMenu;