import {
  WORKORDERS_BEGIN,
  WORKORDERS_FAILURE,
  WORKORDERS_SUCCESS,
  WorkOrderActionTypes,
} from "../../types/technicians/Workorders";

export function workOrdersBegin(): WorkOrderActionTypes {
  return {
    type: WORKORDERS_BEGIN,
  };
}

export function workOrdersSuccess(workorders: any): WorkOrderActionTypes {
  return {
    type: WORKORDERS_SUCCESS,
    payload: workorders,
  };
}

export function workOrdersFailure(error: Error): WorkOrderActionTypes {
  return {
    type: WORKORDERS_FAILURE,
    payload: error,
  };
}
