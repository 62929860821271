// list down the device category in the order which they should be displayed in the app
// should be in this order: CM, ODU, ONT, GW_GPON, FWA, EMTA, ETA, IPTV, DESTB, STB, ASTB, DESTB, GSTB, HSTB, IPSTB, EERO, PODS
export const DOCSIS_DEVICES = [
  "CM",
  "IEMTA",
  "EMTA",
  "IPTV",
  "STB",
  "DESTB",
  "GSTB",
  "HSTB",
  "ASTB",
  "IPSTB",
];
export const GPON_DEVICES = [
  "ONT",
  "IEMTA",
  "EMTA",
  "IPTV",
  "STB",
  "DESTB",
  "GSTB",
  "HSTB",
  "ASTB",
  "IPSTB",
];
export const FWA_DEVICES = [
  "ODU",
  "IEMTA",
  "EMTA",
  "IPTV",
  "STB",
  "DESTB",
  "GSTB",
  "HSTB",
  "ASTB",
  "IPSTB",
];

export const DEVICE_CATEGORY = [
  {
    category: "CM",
    topology: ["DOCSIS"],
    name: "CM/GW",
    serviceDescription: "High Speed Internet - GW",
    iconName: "internet-router",
    deviceDiagnostics: [
      { tab: "CM", label: "CABLE MODEM", active: true, isOpen: true },
      { tab: "DE", label: "DOCSIS EVENTS", active: false, isOpen: false },
      { tab: "CW", label: "CODEWORDS", active: false, isOpen: false },
      { tab: "GW", label: "GW DEVICES", active: false, isOpen: false },
      { tab: "GWStatus", label: "GW STATUS", active: false, isOpen: false },
      { tab: "Ethernet", label: "ETHERNET", active: false, isOpen: false },
      { tab: "WIFI", label: "WIFI SETTINGS", active: false, isOpen: false },
    ],
    deviceActions: [
      { action: "Reboot", label: "Reboot" },
      // { action: "Factory Reset", label: "Factory Reset" },
    ],
  },
  {
    category: "CM",
    topology: ["GPON"],
    name: "GW",
    serviceDescription: "High Speed Internet-GW",
    iconName: "internet-router",
    deviceDiagnostics: [
      { tab: "GWSummary", label: "GW SUMMARY", active: false, isOpen: false },
      { tab: "GW", label: "GW DEVICES", active: false, isOpen: false },
      { tab: "Ethernet", label: "ETHERNET", active: false, isOpen: false },
      { tab: "WIFI", label: "WIFI SETTINGS", active: false, isOpen: false },
    ],
    // deviceActions: [
    //   { action: "Reboot", label: "Reboot" },
    //   { action: "Factory Reset", label: "Factory Reset" },
    // { action: "Hard Zap", label: "Hard Zap" },
    // ],
  },
  {
    category: "CM",
    topology: [ "FWA"],
    name: "GW",
    serviceDescription: "High Speed Internet-GW",
    iconName: "internet-router",
    deviceDiagnostics: [
      { tab: "GW", label: "GW DEVICES", active: false, isOpen: false },
      { tab: "Ethernet", label: "ETHERNET", active: false, isOpen: false },
      { tab: "WIFI", label: "WIFI SETTINGS", active: false, isOpen: false },
    ],
    // deviceActions: [
    //   { action: "Reboot", label: "Reboot" },
    //   { action: "Factory Reset", label: "Factory Reset" },
    // ],
  },
  {
    category: "ODU",
    topology: ["FWA"],
    name: "ODU",
    serviceDescription: "High Speed Internet - ODU",
    iconName: "internet-router",
    deviceDiagnostics: [
      { tab: "oduSUMMARY", label: "ODU SUMMARY", active: true, isOpen: true },
      {
        tab: "networkInterfaces",
        label: "NETWORK INTERFACES",
        active: false,
        isOpen: false,
      },
      {
        tab: "wirelessSub",
        label: "WIRELESS SUBSCRIBER INFO",
        active: false,
        isOpen: false,
      },
    ],
    // deviceActions: [{ action: "Reboot", label: "Reboot" }],
  },
  {
    category: "ONT",
    topology: ["GPON"],
    name: "ONT",
    serviceDescription: "High Speed Internet - ONT",
    iconName: "internet-router",
    deviceDiagnostics: [
      { tab: "ontSUMMARY", label: "ONT SUMMARY", active: true, isOpen: true },
      { tab: "ontAlarms", label: "ONT ALARMS", active: false, isOpen: false },
      { tab: "ontProfile", label: "ONT PROFILE", active: false, isOpen: false },
      { tab: "ontIP", label: "ONT IP SESSIONS", active: false, isOpen: false },
    ],
    deviceActions: [
      { action: "Reboot", label: "Reboot" },
      { action: "Zap", label: "Zap" },
      // { action: "Soft Zap", label: "Zap" },
      // { action: "Hard Zap", label: "Hard Zap" },
    ],
  },
  {
    category: "IEMTA",
    topology: ["DOCSIS", "FWA", "GPON"],
    name: "IHP",
    serviceDescription: "Ignite Home Phone",
    iconName: "homephone",
    deviceDiagnostics: [
      { tab: "ihpSUMMARY", label: "EMTA SUMMARY", active: true, isOpen: true },
      { tab: "etaDATA", label: "INSIDE WIRING", active: false, isOpen: false },
      { tab: "qlrnDATA", label: "QLRN DATA", active: false, isOpen: false },
      { tab: "qsipDATA", label: "QSIP DATA", active: false, isOpen: false },
      {
        tab: "featuresDATA",
        label: "FEATURE VERIFICATION",
        active: false,
        isOpen: false,
      },
      {
        tab: "PCE",
        label: "SIP",
        active: false,
        isOpen: false,
      },
    ],
    deviceActions: [{ action: "Reboot", label: "Reboot" }],
  },
  {
    category: "EMTA",
    topology: ["DOCSIS", "FWA", "GPON"],
    name: "RHP",
    serviceDescription: "Rogers Home Phone",
    iconName: "homephone",
    deviceDiagnostics: [
      { tab: "ihpSUMMARY", label: "EMTA SUMMARY", active: true, isOpen: true },
      { tab: "DE", label: "DOCSIS EVENTS", active: false, isOpen: false },
      { tab: "qlrnDATA", label: "QLRN DATA", active: false, isOpen: false },
      {
        tab: "featuresDATA",
        label: "FEATURE VERIFICATION",
        active: false,
        isOpen: false,
      },
      {
        tab: "PCE",
        label: "PACKET CABLE EVENTS",
        active: false,
        isOpen: false,
      },
    ],
    deviceActions: [{ action: "Reboot", label: "Reboot" }],
  },
  {
    category: "IPTV",
    topology: ["DOCSIS", "FWA", "GPON"],
    name: "IPTV",
    serviceDescription: "IPTV",
    iconName: "cable-tv",
    deviceDiagnostics: [
      { tab: "iptvSUMMARY", label: "IPTV SUMMARY", active: true, isOpen: true },
    ],
    deviceActions: [
      // { action: "Reboot", label: "Reboot" },
      { action: "Zap", label: "Zap" },
    ],
  },
  {
    category: "DESTB",
    topology: ["DOCSIS", "FWA", "GPON"],
    name: "DESTB",
    serviceDescription: "DESTB",
    iconName: "cable-tv",
    deviceDiagnostics: [
      { tab: "stbSUMMARY", label: "STB SUMMARY", active: true, isOpen: true },
      { tab: "DE", label: "DOCSIS EVENTS", active: false, isOpen: false },
    ],
    deviceActions: [
      { action: "Reboot", label: "Reboot" },
      { action: "Zap", label: "Zap" },
    ],
  },
  {
    category: "STB",
    topology: ["DOCSIS", "FWA", "GPON"],
    name: "STB",
    serviceDescription: "STB",
    iconName: "cable-tv",
    deviceDiagnostics: [
      { tab: "stbSUMMARY", label: "STB SUMMARY", active: true, isOpen: true },
    ],
    deviceActions: [
      { action: "Reboot", label: "Reboot" },
      { action: "Zap", label: "Zap" },
    ],
  },
  {
    category: "ASTB",
    topology: ["DOCSIS", "FWA", "GPON"],
    name: "ASTB",
    serviceDescription: "ASTB",
    iconName: "cable-tv",
    deviceDiagnostics: [
      { tab: "astbSUMMARY", label: "ASTB SUMMARY", active: true, isOpen: true },
    ],
    deviceActions: [{ action: "Zap", label: "Zap" }],
  },

  {
    category: "GSTB",
    topology: ["DOCSIS", "FWA", "GPON"],
    name: "GSTB",
    serviceDescription: "GSTB",
    iconName: "cable-tv",
    deviceDiagnostics: [
      { tab: "stbSUMMARY", label: "STB SUMMARY", active: true, isOpen: true },
    ],
    deviceActions: [{ action: "Zap", label: "Zap" }],
  },
  {
    category: "HSTB",
    topology: ["DOCSIS", "FWA", "GPON"],
    name: "HSTB",
    serviceDescription: "HSTB",
    iconName: "cable-tv",
    deviceDiagnostics: [
      { tab: "stbSUMMARY", label: "STB SUMMARY", active: true, isOpen: true },
    ],
    deviceActions: [{ action: "Zap", label: "Zap" }],
  },
  {
    category: "IPSTB",
    topology: ["DOCSIS", "FWA", "GPON"],
    name: "IPSTB",
    serviceDescription: "IPSTB",
    iconName: "cable-tv",
    deviceDiagnostics: [
      { tab: "stbSUMMARY", label: "STB SUMMARY", active: true, isOpen: true },
    ],
    deviceActions: [{ action: "Zap", label: "Zap" }],
  },
  {
    category: "EERO",
    name: "EERO",
    serviceDescription: "EERO",
    iconName: "wifi-pod",
  },
  {
    category: "PODS",
    name: "PODS",
    serviceDescription: "PODS",
    iconName: "wifi-pod",
  },
];
