import { useDispatch } from "react-redux";
import "../../design-tokens/node-page/NodePage.scss";
import SearchByNodeUrl from "../../components/search/SearchByNodeUrl";
import NodePageDetails from "./NodePageDetails";
import { useEffect, useState, useCallback, useRef } from "react";
import { IoClose } from "react-icons/io5";
import Node from "../../resources/images/icons/node_black.svg";
import Smt from "../../resources/images/icons/SMT.svg";
import {
  ChangeNodeState,
  DeleteNode,
  SetActiveKey,
  UpdateNode,
} from "../../store/actions/node/AllNodesAction";
import { useHistory } from "react-router-dom";
import { getNodeState } from "../../components/reusable/Util";
import TECHClient from "../../network/httpclient/technician/TECHClient";
import {
  GetTechInfo,
  GetNodeInfoDetails,
  GetGponNodeDetails,
  GetOltNameSearchDetails,
  GetNodeGeneralProps,
} from "../../get-api/GetResponse";
import { GponMainPage } from "../gpon-page/GponMainPage";
import { OltLandingPage } from "../gpon-page/OltLandingPage";
import ExceptionBox from "../../components/reusable/ExceptionBox";
import appConst from "../../topology/constants/appConst";

const NodePage = (props: any) => {
  const {
    location: { search },
  } = props;
  // const techData = GetTechInfo()?.data;
  const { currNodeState, currNodedata, allNodes, activekey } =
    GetNodeInfoDetails();
  const { nodeInfo } = GetGponNodeDetails();
  const { oltResultData } = GetOltNameSearchDetails();

  const { nodeTopologyTab } = GetNodeGeneralProps();
  const dispatch = useDispatch();
  // const profileRef = useRef(false);
  const history = useHistory();
  const [deleteEntityNum, setDeleteEntityNum] = useState("");
  const pushToHistory = (entityNum: string, smtFlag: boolean) => {
    if (entityNum !== undefined && entityNum !== "") {
      let search = smtFlag ? `?smtId=${entityNum}` : `?nodeId=${entityNum}`;
      history.push({
        pathname: "/node",
        search: search,
      });
    }
  };

  // Already called in the App.tsx
  // const profileApi = useCallback(() => {
  //   if (techData === undefined && !profileRef.current) {
  //     dispatch(TECHClient.getTechniciansProfile());
  //     profileRef.current = true;
  //   }
  // }, [dispatch, techData]);

  useEffect(() => {
    // profileApi();
    let id = currNodedata?.smt ? currNodedata?.smtId : currNodedata?.nodeId;
    pushToHistory(activekey, currNodedata?.smt);
    if (currNodedata && allNodes?.length > 0) {
      let oldNodeState = currNodeState;
      if (oldNodeState !== undefined && id !== activekey) {
        dispatch(UpdateNode(id, oldNodeState));
      }
      let { newNodeState } = getNodeState(allNodes, activekey);
      if (newNodeState !== undefined && id !== activekey) {
        dispatch(ChangeNodeState(newNodeState));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activekey, currNodedata, nodeInfo]);

  const handleNodeTabClick = (key: string) => {
    dispatch(SetActiveKey(key));
  };

  const getEntityNumByTabIndex = (tabIndex: number): string => {
    let entityNum = "";
    allNodes?.map((nodedata: any, index: number) => {
      if (tabIndex === index) {
        entityNum = nodedata?.entityNum;
      }
    });
    return entityNum;
  };
  const deleteNode = (entityNum: string) => {
    let entityTabIndex = 0;
    allNodes?.map((nodedata: any, index: number) => {
      if (nodedata?.entityNum === entityNum) {
        entityTabIndex = index + 1;
      }
    });
    if (entityTabIndex === allNodes?.length) {
      if (allNodes?.length > 1) {
        dispatch(SetActiveKey(getEntityNumByTabIndex(entityTabIndex - 2)));
      } else {
        dispatch(SetActiveKey(""));
      }
    } else if (entityTabIndex < allNodes?.length) {
      dispatch(SetActiveKey(getEntityNumByTabIndex(entityTabIndex)));
    }
    if (entityNum !== undefined) {
      setDeleteEntityNum(entityNum);
    }
  };
  useEffect(() => {
    if (deleteEntityNum !== undefined && deleteEntityNum !== "") {
      dispatch(DeleteNode(deleteEntityNum));
      setDeleteEntityNum("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteEntityNum]);

  const handlePages = () => {
    let content = null;
    if (currNodedata) {
      // DOCSIS NODE PAGE
      content = (
        <>
          {allNodes?.length > 1 ? (
            <>
              <section className="accTabs">
                <div className="allAccountsNav">
                  {allNodes?.map((nodedata: any, index: any) => {
                    let activeFlag = false;
                    if (activekey === nodedata?.entityNum) {
                      activeFlag = true;
                    }
                    return (
                      <div
                        className={`accTab-${activeFlag ? "active" : ""}-Btn`}
                        key={index}
                      >
                        <span
                          className="entityNum"
                          onClick={() =>
                            handleNodeTabClick(nodedata?.entityNum)
                          }
                        >
                          {nodedata?.entityType === "smt" ? (
                            <img src={Smt} alt="SMT" />
                          ) : (
                            <img src={Node} alt="Node" />
                          )}{" "}
                          {nodedata?.entityNum}
                        </span>
                        <IoClose
                          onClick={() => deleteNode(nodedata?.entityNum)}
                        />
                      </div>
                    );
                  })}
                </div>
                <NodePageDetails {...props} />
              </section>
            </>
          ) : (
            nodeTopologyTab === appConst.node && <NodePageDetails {...props} />
          )}
        </>
      );
    } else if (nodeInfo?.data !== undefined || nodeInfo?.error !== undefined) {
      // GPON NODE PAGE
      content = <GponMainPage {...props} />;
    } else if (oltResultData !== undefined) {
      // OLT LANDING PAGE
      content = (
        <>
          {oltResultData?.length > 0 ? (
            <OltLandingPage data={oltResultData} {...props} />
          ) : (
            <div className="diagErrorCont">
              <ExceptionBox
                headerText="OLT data is not available."
                bodyText="Once you search an olt name it will reflect here."
                primaryBtn={false}
                secBtn={false}
              />
            </div>
          )}
        </>
      );
    } else {
      content = <SearchByNodeUrl {...props} />;
    }
    return content;
  };

  return <div className="accTabsCont">{handlePages()}</div>;
};

export default NodePage;
