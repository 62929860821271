import { createSlice } from "@reduxjs/toolkit";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "xleVisible",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    // action => action handler
    GetXleVisibleBegin: (xleVisible) => {
      xleVisible.isLoading = true;
    },
    GetXleVisibleSuccess: (xleVisible, action) => {
        xleVisible.isLoading = false;
        xleVisible.data = action.payload;
        xleVisible.status = storeConst.OK;
    },
    GetXleVisibleFailure: (xleVisible, action) => {
        xleVisible.isLoading = false;
        xleVisible.error = action.payload;
        xleVisible.data = undefined;
      xleVisible.status = storeConst.ERROR;
    },

 
  },
});

export const {
    GetXleVisibleBegin,
    GetXleVisibleSuccess,
    GetXleVisibleFailure,
} = slice.actions;

export default slice.reducer;
