import { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import AppsIcon from "@material-ui/icons/Apps";
import { Dropdown, DropdownButton } from "react-bootstrap";

import "../../design-tokens/gpon-page/GponLandingPage.scss";
import ExternalAppMenu from "../../components/reusable/ExternalAppMenu";
import { IoIosArrowDropup } from "react-icons/io";
import { gponOltTableConfig } from "../../constants/TelePrefConfig";
import ExportIcon from "../../resources/images/icons/ExportIcon.svg";
import { GponTopBanner } from "./GponBanner";
import { GponTelemetryTable } from "./components/GponTelemetryTable";
import { GetOltNameSearchDetails } from "../../get-api/GetResponse";
import { getStreamData } from "../../../src/store/slices/gpon/oltNameSearchDetails";
import { GponOntCountSummary } from "../../store/models/account/diagnostics/ont/GponOntCountSummary";
import GponDictionary from "./GponDictionary";
import AppLabels from "../../constants/App_Labels";
import { ExportCsv } from "./components/ExportCsv";

export const OltLandingPage = (props: any) => {
  const {
    location: { search },
  } = props;
  const query = new URLSearchParams(search);
  const dispatch = useDispatch();
  const [expand, setExpand] = useState(false);
  const [oltSearchValue, setOltSearchValue] = useState<string | null>("");
  const [tableData, setTableData] = useState<GponOntCountSummary[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [dictionary, setDictionary] = useState(false);
  const [quickLinks, setQuickLinks] = useState(false);
  const {
    oltResultData,
    oltName,
    oltIsLoading,
    isOntCountLoading,
    diagStartTime,
    diagEndTime,
  } = GetOltNameSearchDetails();
  const isFluxRendered = useRef(false);
  const labels = AppLabels();

  const handleToggle = () => {
    setExpand((prev) => !prev);
  };

  const isONTCountLoaded = useCallback(
    (node: any) => {
      return oltResultData?.find(
        (n: any) => n?.ponSplitterNodeId === node && n?.ontDevice !== ""
      );
    },
    [oltResultData]
  );

  useEffect(() => {
    if (search && search.includes("oltName")) {
      setOltSearchValue(query.get("oltName"));
    } else {
      setOltSearchValue(oltName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const handleOltNodeLink = async (gponNodeId: string) => {
    let newWindow = window.open(
      `${window.location.origin}#/` + `${"node?gponId=" + gponNodeId}`,
      "_blank"
    )!;
    newWindow.onload = () => {
      newWindow.document.title = "GN-" + gponNodeId;
    };
  };

  useEffect(() => {
    if (
      oltResultData &&
      oltResultData?.length !== 0 &&
      isFluxRendered.current === false &&
      !isOntCountLoading &&
      !isONTCountLoaded(oltResultData) &&
      oltResultData.some(
        (e: any) =>
          e.ontDevice.data === undefined || e.ontDevice.error === undefined
      )
    ) {
      const nodeList = oltResultData?.map((e: any) => e?.ponSplitterNodeId);
      isFluxRendered.current = true;
      dispatch(getStreamData({ nodeList: nodeList }));
    }
  }, [oltIsLoading]);

  const getTableData = useCallback(() => {
    if (props?.data !== undefined) {
      let tableData = props?.data.map((olt: any) => {
        let ontObj = olt?.ontDevice;
        if (olt?.ontDevice) {
          if (
            olt?.ontDevice?.data?.totalCount === undefined ||
            olt?.ontDevice?.data?.totalCount === ""
          ) {
            ontObj = { ...ontObj, data: { totalCount: 0 } };
          }
        }

        return {
          slotName: " " + olt?.slotName,
          portName: olt?.portName,
          ponSplitterNodeId: olt?.ponSplitterNodeId,
          ontCount: ontObj,
        };
      });
      setTableData(tableData);
      setFilteredData(tableData);
      if (diagEndTime) {
        setDisabled(false);
      }
    }
  }, [props?.data]);

  const handleExportColumnData = (columnData: any) => {
    let headers: any = [];
    columnData.forEach((column: any) => {
      headers.push({
        label: column.title,
        key: column.attribute,
      });
    });
    return headers;
  };

  const handleExportRowData = (rowData: any) => {
    rowData.forEach((row: any) => {
      Object.keys(row).forEach((key: any) => {
        if (row[key] instanceof Object) {
          row[key] =
            row[key] && row[key]?.data?.totalCount !== undefined
              ? row[key]?.data?.totalCount
              : 0;
        } else if (row[key] instanceof Array) {
          row[key] = row[key].join(", ");
        } else if (row[key] instanceof Date) {
          row[key] = row[key].toLocaleString();
        } else if (row[key] instanceof Boolean) {
          row[key] = row[key] ? "Yes" : "No";
        } else if (row[key] instanceof Number) {
          row[key] = row[key].toString();
        } else if (row[key] instanceof String) {
          row[key] = row[key].toString();
        } else if (
          row[key] === null ||
          row[key] === undefined ||
          row[key]?.trim === "" ||
          row[key] === "null" ||
          row[key] === "undefined"
        ) {
          row[key] = "";
        } else {
          row[key] = row[key] + "";
        }
      });
    });
    return rowData;
  };

  const getDefaultFileName = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    let time = today.toLocaleTimeString().split(":");
    const fileName = oltSearchValue ? "OLT_" + oltSearchValue : "OLT";
    return (
      fileName +
      "_" +
      mm +
      "-" +
      dd +
      "-" +
      yyyy +
      "_" +
      time[0] +
      "_" +
      time[1]
    );
  };

  useEffect(() => {
    getTableData();
  }, [getTableData]);

  return (
    <div className="gponLandingPage">
      <div className="gponBannerContainer">
        <GponTopBanner
          data={props.data}
          expand={expand}
          gponType={"olt"}
          gponSearchVal={oltSearchValue}
          loadingStatus={isOntCountLoading}
          diagStartTime={diagStartTime}
          diagEndTime={diagEndTime}
        />
        <div className="gponBannerIconsContainer">
          <div className="gponBannerIconsWrapper">
            <DropdownButton
              title={
                <AppsIcon
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                />
              }
              className="gponMenuIcon"
            >
              <Dropdown.Item
                className="gponMenuListItemContainer"
                onClick={() => {
                  if (!dictionary) {
                    setDictionary(!dictionary);
                  }
                }}
              >
                <span style={{ marginRight: "12px" }}>
                  <GponDictionary
                    show={dictionary}
                    setShow={() => setDictionary(false)}
                  />
                </span>
                <span>Dictionary</span>
              </Dropdown.Item>
              <Dropdown.Item
                className="gponMenuListItemContainer"
                onClick={() => setQuickLinks(!quickLinks)}
              >
                <span style={{ marginRight: "12px" }}>
                  <ExternalAppMenu
                    show={quickLinks}
                    setShow={() => setQuickLinks(false)}
                  />
                </span>
                <span>Quick Links</span>
              </Dropdown.Item>
              <Dropdown.Item
                className="gponMenuListItemContainer"
                onClick={() => {
                  setOpen(true);
                }}
                disabled={disabled}
              >
                <span style={{ marginRight: "12px" }}>
                  <img src={ExportIcon} alt="export" />
                </span>
                <span>Export</span>
              </Dropdown.Item>
            </DropdownButton>
          </div>
          <div
            className="gponCollapseContainer"
            style={{
              rotate: expand ? "0deg" : "180deg",
            }}
          >
            <IoIosArrowDropup color="black" size={25} onClick={handleToggle} />
          </div>
        </div>
      </div>

      {open && (
        <>
          <div className="abc">
            <ExportCsv
              show={open}
              headerText={labels.Export}
              cancelText={labels.CANCEL}
              handleClose={() => setOpen(false)}
              rowData={JSON.parse(JSON.stringify(filteredData))}
              columnData={gponOltTableConfig}
              handleCustom={true}
              handleColumnData={handleExportColumnData}
              handleRowData={handleExportRowData}
              getDefaultLibFileName={getDefaultFileName}
            />
          </div>
        </>
      )}
      <div className="gponOltBodyContainer">
        <GponTelemetryTable
          tableHeader={gponOltTableConfig}
          tableData={tableData.length > 0 ? tableData : []}
          onClickExternalLink={handleOltNodeLink}
          setFilterData={(data: any) => setFilteredData(data)}
        />
      </div>
    </div>
  );
};
