import { NodeInfoDetails } from "../../../models/node/nodeinfo/NodeInfoDetails";
import { ActiveNodePage } from "../../../states/node/nodeinfo/ActiveNodeInfoState";

import {
  SET_ACTIVE_NODE_INFO_DETAILS,
  SET_ACTIVE_NODE_SUB_TAB_INDEX,
  RESET_NODE,
  RESET_ACTIVE_NODE_INFO,
  ActiveNodeInfoActionTypes,
  SET_ACTIVE_NODE_PAGE,
} from "../../../types/node/nodeinfo/ActiveNodeInfoDetails";

export function SetActiveNodeInfoDetails(
  NodeInfoDetails: NodeInfoDetails
): ActiveNodeInfoActionTypes {
  return {
    type: SET_ACTIVE_NODE_INFO_DETAILS,
    payload: NodeInfoDetails,
  };
}
export function SetActiveSubTabIndex(
  subTabIndex: number
): ActiveNodeInfoActionTypes {
  return {
    type: SET_ACTIVE_NODE_SUB_TAB_INDEX,
    payload: subTabIndex,
  };
}
export function ActiveNodeInfoReset(): ActiveNodeInfoActionTypes {
  return {
    type: RESET_ACTIVE_NODE_INFO,
  };
}

export function SetActiveNodePage(
  activeNodePage: ActiveNodePage
): ActiveNodeInfoActionTypes {
  return {
    type: SET_ACTIVE_NODE_PAGE,
    payload: activeNodePage,
  };
}

// export function AppReset(): ActiveNodeInfoActionTypes {
//   return {
//     type: RESET_APP,
//   };
// }
export function NodeReset(): ActiveNodeInfoActionTypes {
  return {
    type: RESET_NODE,
  };
}
