import { Fragment } from "react";
import { Polyline } from "@react-google-maps/api";

import appConst from "../../../../../constants/appConst";
import { useRfDownstreamTraceState } from "../../../../../util/reduxFunctions/getTopologyState";
import { mapElementStyles } from "../../../../../config/mapItemsConfig";
import { getTraceCablesData } from "../../../../../util/utilFuncs";

interface Props {
  allNodeData?: any;
}

export default function RfDownstreamTraceElements({ allNodeData }: Props) {
  const { data: traceData, isActive } = useRfDownstreamTraceState();

  const cables = getTraceCablesData(traceData);

  return (
    <>
      {isActive && (
        <Fragment>
          {cables !== undefined &&
            cables?.map((cable: any) => {
              if (cable?.geometry?.type === appConst.line) {
                let lineLatLngArray: any = [];
                cable?.geometry?.coordinates?.map((cord: any) => {
                  lineLatLngArray.push({ lat: cord?.[1], lng: cord?.[0] });
                });
                return (
                  <Polyline
                    key={`${cable?.networkId}rfDownstreamTraceLine`}
                    path={lineLatLngArray}
                    options={mapElementStyles?.trace?.downstream?.line}
                  />
                );
              }
            })}
        </Fragment>
      )}
    </>
  );
}
