import { ServiceAreaInfo } from "../../../models/account/accountInfo/ServiceAreaInfo";
import {
  SERVICE_AREA_BEGIN,
  SERVICE_AREA_FAILURE,
  SERVICE_AREA_SUCCESS,
  ServiceAreaInfoActionTypes,
} from "../../../types/account/accountInfo/ServiceAreaInfo";

export function ServiceAreaInfoBegin(): ServiceAreaInfoActionTypes {
  return {
    type: SERVICE_AREA_BEGIN,
  };
}

export function ServiceAreaInfoSuccess(
  serviceAreaInfo: ServiceAreaInfo
): ServiceAreaInfoActionTypes {
  return {
    type: SERVICE_AREA_SUCCESS,
    payload: serviceAreaInfo,
  };
}

export function ServiceAreaInfoFailure(
  error: Error
): ServiceAreaInfoActionTypes {
  return {
    type: SERVICE_AREA_FAILURE,
    payload: error,
  };
}
