import { CWMonitor } from "../../../../models/account/diagnostics/cm/CWMonitor";

import {
  CWMonitorActionTypes,
  CW_MONITORING_BEGIN,
  CW_MONITORING_FAILURE,
  CW_MONITORING_SUCCESS,
  SET_CODEWORD_FAILURE,
  CW_MONITORING_RESET,
  CW_MONITORING_INDEX,
  CW_Monitoring_Certification_Success,
  CW_Monitoring_Tracer
} from "../../../../types/account/diagnostics/cm/CWMonitoring";

export function CWMonitoringBegin(): CWMonitorActionTypes {
  return {
    type: CW_MONITORING_BEGIN,
  };
}

export function CWMonitoringSuccess(
  cwMonitor: CWMonitor
): CWMonitorActionTypes {
  return {
    type: CW_MONITORING_SUCCESS,
    payload: cwMonitor,
  };
}
export function CWMonitoringTracer(
  tracer: boolean
): CWMonitorActionTypes {
  return {
    type: CW_Monitoring_Tracer,
    payload: tracer,
  };
}
export function CWMonitoringCertificationSuccess(
  cwMonitor: CWMonitor
): CWMonitorActionTypes {
  return {
    type: CW_Monitoring_Certification_Success,
    payload: cwMonitor,
  };
}


export function CWMonitoringFailure(error: any): CWMonitorActionTypes {
  return {
    type: CW_MONITORING_FAILURE,
    payload: error,
  };
}

export function CWMonitoringIndex(index: number): CWMonitorActionTypes {
  return {
    type: CW_MONITORING_INDEX,
    payload: index,
  };
}

export function CWMonitoringReset(): CWMonitorActionTypes {
  return {
    type: CW_MONITORING_RESET,
  };
}
export function CWMonitoringFailures(error: any): CWMonitorActionTypes {
  return {
    type: SET_CODEWORD_FAILURE,
    payload: error,
  };
}