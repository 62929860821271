import CustomPopOver from "../reusable/CustomPopOver";
import { ReactComponent as TapPin } from "../../resources/images/icons/tapPin.svg";
import { useDispatch } from "react-redux";
import TapClient from "../../network/httpclient/neighbourhood/TapClient";

const TapMarker = (props: any) => {
  const { tapDetails } = props;
  const dispatch = useDispatch();
  const handleTapPinClick = () => {
    dispatch(TapClient.getMACsByTapName(tapDetails?.tap));
  };
  return (
    <>
      <div className="tapMarkerCont">
        <CustomPopOver
          position="top"
          popOverData={tapDetails?.tap}
          originComp={<TapPin />}
          index="popOver"
        />
      </div>
    </>
  );
};

export default TapMarker;
