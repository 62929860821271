import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useGponUsagesStates } from "../../../../../util/reduxFunctions/getTopologyState";
import TopologyLabels from "../../../../../constants/topologyLabels";
import ExceptionHandlerWrapper from "../../../../reusable/ExceptionHandlerWrapper";
import Table, { TCTypes, TDTypes } from "../../../../reusable/table/Table";
import { getGponUsages } from "../../../../../store/slices/drawer/passive/gponUsages";
import GponAddressPort from "./GponAddressPort";

interface Props {
  elementData: any;
}

export default function GponPortSelector({ elementData }: Props) {
  const labels = TopologyLabels();
  const dispatch = useDispatch();
  const { allData } = useGponUsagesStates();
  const [selectedPortIndex, setSelectedPortIndex] = useState(0);
  const lcpNetworkId = elementData?.data?.networkId;
  const sIndex = allData.findIndex(
    (sMap) => sMap?.data?.lcpNetworkId === lcpNetworkId
  );
  const currUsage = allData?.[sIndex];
  const usageData = currUsage?.data?.payload;

  useEffect(() => {
    if (!currUsage) dispatch(getGponUsages({ lcpNetworkId }));
  }, [currUsage]);

  const tableData = usageData?.map((d: any, i: number) => {
    return {
      id: d?.networkId,
      name: (
        <a
          className={selectedPortIndex === i ? "selectedIdLink" : "idLink"}
          onClick={() => setSelectedPortIndex(i)}
        >
          {d?.name}
        </a>
      ),
      equipType: d?.equipType,
    } as TDTypes;
  });

  const tableColumns = [
    { th: labels.name.toUpperCase(), tdName: "name" },
    { th: labels.type.toUpperCase(), tdName: "equipType" },
  ] as TCTypes[];

  return (
    <ExceptionHandlerWrapper
      isLoading={currUsage?.isLoading}
      status={currUsage?.status}
      data={tableData}
      onRefresh={() => dispatch(getGponUsages({ lcpNetworkId }))}
      sectionName={labels.portSelector.toUpperCase()}
    >
      <Table
        title={labels.portSelector.toUpperCase()}
        data={tableData}
        columns={tableColumns}
        showExpandBtn={false}
        showExportBtn={false}
      />
      <GponAddressPort data={usageData?.[selectedPortIndex]} />
    </ExceptionHandlerWrapper>
  );
}
