import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";

import ResponseSnackBar from "../../../components/reusable/ResponseSnackBar";
import { GetNodeInfoDetails } from "../../../get-api/GetResponse";
import appConst from "../../constants/appConst";
import {
  GetClickOrdersProps,
  GetCommentsProps,
  GetMapDetailsProps,
  GetMemoProps,
  GetMicroReflectionsGroupedIssuesProps,
  GetNikaOrderProps,
  GetNodeSiteDetailsProps,
  GetTelemetryProps,
  GetVhubSearchProps,
  useRfDownstreamTraceState,
  useRfUpstreamTraceState,
  GetGoogleMaps
} from "../../util/reduxFunctions/getTopologyState";
import TopologyLabels from "../../constants/topologyLabels";
import { useDispatch } from "react-redux";
import { getNodeSiteByClamshellId,getClamShellDetail } from "../../store/slices/mapItems/node/nodeSiteDetail";

interface Props {
  isFromTopoStandaloneView?: Boolean;
}

export default function MapItemsErrorHandling({
  isFromTopoStandaloneView = false,
}: Props) {
  const labels = TopologyLabels();
  const dispatch = useDispatch();
  const { activeNodeSite, activeNode, activeNodePolyType,isTopoEleLoading,allNodeData } =
    GetNodeSiteDetailsProps();
    const { currentMapCenter, currentViewBDY } = GetGoogleMaps();
    const {activeNodePolyCords}= GetNodeSiteDetailsProps();
  const nikaOrder = GetNikaOrderProps();
  const { mlError, mlIsLoading, mlStatus } = GetTelemetryProps();
  const mrGroupedIssues = GetMicroReflectionsGroupedIssuesProps();
  const nodeInfo = GetNodeInfoDetails();
  const clickOrder = GetClickOrdersProps();
  const { memoCrudStatus, memoCrudIsLoading, memoCrudError } = GetMemoProps();
  const {
    error: rfDsError,
    isLoading: isRfDsLoading,
    status: rfDsStatus,
  } = useRfDownstreamTraceState();
  const {
    error: rfUsError,
    isLoading: isRfUsLoading,
    status: rfUsStatus,
  } = useRfUpstreamTraceState();
  const vhubData = GetVhubSearchProps();
  const [isBannerVisisble, setIsBannerVisisble] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [isShowModal, setShowModal] = useState(false);
  const [isClamshellSearched, setIsClamshellSearched] = useState(false);
  const { crudStatus, crudError, eleCommentsData } = GetCommentsProps();
  const { activeMapElement } = GetMapDetailsProps();
  const statusForNodeComments =
    eleCommentsData?.[activeMapElement?.properties?.data?.id]?.status;
  const errorNodeComments =
    eleCommentsData?.[activeMapElement?.properties?.data?.id]?.error;

  useEffect(() => {
    if (isBannerVisisble && !isShowModal) {
      setTimeout(() => setIsBannerVisisble(false), 3000);
    }
  }, [isBannerVisisble]);

  //error handling for node site
  useEffect(() => {
    if (
      !isFromTopoStandaloneView &&
      activeNode?.isPrimaryNode &&
      activeNodeSite?.isLoading === false &&
      activeNodeSite?.status === appConst.ERROR
    ) {
      if (activeNodeSite?.error?.errorCode === "ELEMENT-NOT-FOUND" ) {
        setShowModal(true);
        if (
          !isClamshellSearched &&
          nodeInfo?.nodeInfo?.clamShell &&
          nodeInfo?.nodeInfo?.clamShell !== ""
        ) {
          dispatch(getNodeSiteByClamshellId(nodeInfo?.nodeInfo?.clamShell));
          setIsClamshellSearched(true);
         
        }
      } else{
        setIsBannerVisisble(true);
        setBannerMessage(activeNodeSite?.error?.errorMessage);
      }
    }
  }, [activeNodeSite?.status]);
  useEffect(() => {
    if (
      !isFromTopoStandaloneView &&
      allNodeData?.[0]?.isPrimaryNode &&
      allNodeData?.[0]?.node?.isLoading === false &&
      allNodeData?.[0]?.node?.status === appConst.ERROR &&activeNodePolyCords
    ) {
     if( allNodeData?.[0]?.node?.error?.errorCode === "ELEMENT-NOT-FOUND" &&activeNodePolyCords!==undefined) 
      {
        //currentViewBDY
        // const tranformedCurrentViewBDY= {geometry:  Object.values(activeNodePolyCords)};
        // //activeNodePolyCords
        const tranformedCurrentViewBDY= {geometry:  Object.values(activeNodePolyCords).map((coord:any)=>
          ({
          lat:coord.latitude,
          lng:coord.longitude,
        }))};
     
        dispatch(getClamShellDetail(tranformedCurrentViewBDY));
      }
     
    }
  }, [activeNodePolyType]);

  //pHub handling for topology
  useEffect(() => {
    if (
      activeNodePolyType &&
      activeNodePolyType?.toLowerCase() === appConst.phub
    ) {
      setIsBannerVisisble(true);
      setShowModal(true);
    }
  }, [activeNodePolyType]);

  //error handling for nika orders
  useEffect(() => {
    if (
      nikaOrder?.isLoading === false &&
      nikaOrder?.status === appConst.ERROR
    ) {
      setBannerMessage(nikaOrder?.error?.errorMessage);
      setIsBannerVisisble(true);
    }
  }, [nikaOrder?.status]);

  //error handling for node config info
  useEffect(() => {
    if (nodeInfo?.isLoading === false && nodeInfo?.status === appConst.ERROR) {
      setBannerMessage(nodeInfo?.error?.errorMessage);
      setIsBannerVisisble(true);
    }
  }, [nodeInfo?.status]);

  //error handling for Diagnostics
  useEffect(() => {
    if (mlIsLoading === false && mlStatus === appConst.ERROR) {
      setBannerMessage(mlError?.errorMessage);
      setIsBannerVisisble(true);
    }
  }, [mlStatus]);

  //error handling for micro reflections grouped issues
  useEffect(() => {
    if (
      mrGroupedIssues?.isLoading === false &&
      mrGroupedIssues?.status === appConst.ERROR
    ) {
      setBannerMessage(mrGroupedIssues?.error?.errorMessage);
      setIsBannerVisisble(true);
    }
  }, [mrGroupedIssues?.status]);

  //error handling for click orders
  useEffect(() => {
    if (
      clickOrder?.isLoading === false &&
      clickOrder?.status === appConst.ERROR
    ) {
      setBannerMessage(clickOrder?.error?.errorMessage);
      setIsBannerVisisble(true);
    }
  }, [clickOrder?.status]);

  //error handling for Node Comments
  useEffect(() => {
    if (
      statusForNodeComments !== undefined &&
      statusForNodeComments !== "" &&
      statusForNodeComments === appConst.ERROR
    ) {
      setBannerMessage(errorNodeComments?.errorMessage);
      setIsBannerVisisble(true);
    }
    if (
      crudStatus !== undefined &&
      crudStatus !== "" &&
      crudStatus === appConst.ERROR
    ) {
      setBannerMessage(crudError?.errorMessage);
      setIsBannerVisisble(true);
    }
  }, [crudStatus, statusForNodeComments]);

  //error handling for memo comments
  useEffect(() => {
    if (memoCrudIsLoading === false && memoCrudStatus === appConst.ERROR) {
      setBannerMessage(memoCrudError?.error?.errorMessage);
      setIsBannerVisisble(true);
    }
  }, [memoCrudStatus]);

  //error handling for olt
  useEffect(() => {
    if (
      activeNode?.olt?.isLoading === false &&
      activeNode?.olt?.status === appConst.ERROR
    ) {
      setBannerMessage(activeNode?.olt?.error?.errorMessage);
      setIsBannerVisisble(true);
    }
  }, [activeNode?.olt?.status]);

  //error handling for rf downstream trace
  useEffect(() => {
    if (isRfDsLoading === false && rfDsStatus === appConst.ERROR) {
      setBannerMessage(rfDsError?.errorMessage);
      setIsBannerVisisble(true);
    }
  }, [rfDsStatus]);

  //error handling for rf upstream trace
  useEffect(() => {
    if (isRfUsLoading === false && rfUsStatus === appConst.ERROR) {
      setBannerMessage(rfUsError?.errorMessage);
      setIsBannerVisisble(true);
    }
  }, [rfUsStatus]);

  //error handling for vhub
  useEffect(() => {
    if (vhubData?.isLoading === false && vhubData?.status === appConst.ERROR) {
      setBannerMessage(vhubData?.error?.errorMessage);
      setIsBannerVisisble(true);
    }
  }, [vhubData?.status]);

  return (
    <>
      {!isShowModal ? (
        <ResponseSnackBar
          open={isBannerVisisble}
          requestType={bannerMessage}
          handleClose={() => setIsBannerVisisble(false)}
          responseType={appConst.ERROR}
          isHeader
        />
      ) : (
        <Modal
          style={{ position: "absolute" }}
          show={isShowModal}
          onHide={() => setShowModal(false)}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header className="searchPopHead">
            <header>Node not Found!</header>
            <button className="sPopUpClose" onClick={() => setShowModal(false)}>
              <IoClose color="white" />
            </button>
          </Modal.Header>
          <Modal.Body>{labels.nodeNotFoundInSpatial}</Modal.Body>
        </Modal>
      )}
    </>
  );
}
