export const NODE_MODEMS_LIST_BEGIN = "NODE_MODEMS_LIST_BEGIN";
export const NODE_MODEMS_LIST_SUCCESS = "NODE_MODEMS_LIST_SUCCESS";
export const NODE_MODEMS_LIST_FAILURE = "NODE_MODEMS_LIST_FAILURE";

interface NodeModemsListBegin {
  type: typeof NODE_MODEMS_LIST_BEGIN;
}

interface NodeModemsListSuccess {
  type: typeof NODE_MODEMS_LIST_SUCCESS;
  payload: any;
}

interface NodeModemsListFailure {
  type: typeof NODE_MODEMS_LIST_FAILURE;
  payload: Error;
}

export type NodeModemsListActionTypes =
  | NodeModemsListBegin
  | NodeModemsListSuccess
  | NodeModemsListFailure;
