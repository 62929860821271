export const FULL = [
  {
    columnName: "Address",
    jsonDataName: "homeAddress",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "listOfModems",
  },
  {
    columnName: "SMT",
    jsonDataName: "serviceArea",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "listOfModems",
  },
  {
    columnName: "Type",
    jsonDataName: "cmModel",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "listOfModems",
  },
  {
    columnName: "CM MAC",
    jsonDataName: "cmMac",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "listOfModems",
  },

  {
    columnName: "Model",
    jsonDataName: "model",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "listOfModems",
  },
  {
    columnName: "Uptime",
    jsonDataName: "uptime",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "summary",
  },
  {
    columnName: "Registered Time",
    jsonDataName: "registeredTime",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "summary",
  },
  {
    columnName: "US PS",
    jsonDataName: "usPartialService",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "US Tx Total",
    jsonDataName: "usTxTotal",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "US Tx min",
    jsonDataName: "usTxMin",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "US Tx max",
    jsonDataName: "usTxMax",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },

  {
    columnName: "US Tx delta",
    jsonDataName: "usTxDelta",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "US SNR min",
    jsonDataName: "usSnrMin",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "US SNR max",
    jsonDataName: "usSnrMax",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "US SNR delta",
    jsonDataName: "usSnrDelta",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "DS PS",
    jsonDataName: "dsPartialService",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "DS Rx min",
    jsonDataName: "dsRxMin",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },

  {
    columnName: "DS Rx max",
    jsonDataName: "dsRxMax",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "DS Rx delta",
    jsonDataName: "dsRxDelta",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "DS SNR min",
    jsonDataName: "dsSnrMin",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "DS SNR max",
    jsonDataName: "dsSnrMax",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "DS SNR delta",
    jsonDataName: "dsSnrDelta",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "OFDMA 1 TX",
    jsonDataName: "ofdmaTx",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },

  {
    columnName: "OFDMA 1 mean",
    jsonDataName: "ofdmaMean",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "OFDMA 1 STD",
    jsonDataName: "ofdmaStd",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "OFDMA 1 PCT",
    jsonDataName: "ofdmaPct",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "OFDM 1 Rx",
    jsonDataName: "ofdm1Rx",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "OFDM 1 Rx max",
    jsonDataName: "ofdm1RxMax",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "OFDM 1 Rx delta",
    jsonDataName: "ofdm1RxDelta",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },

  {
    columnName: "OFDM 1 mean",
    jsonDataName: "ofdm1Mean",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "OFDM 1 STD",
    jsonDataName: "ofdm1Std",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "OFDM 1 PCT",
    jsonDataName: "ofdm1Pct",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },

  {
    columnName: "OFDM 2 Rx",
    jsonDataName: "ofdm2Rx",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "OFDM 2 Rx max",
    jsonDataName: "ofdm2RxMax",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "OFDM 2 Rx delta",
    jsonDataName: "ofdm2RxDelta",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },

  {
    columnName: "OFDM 2 mean",
    jsonDataName: "ofdm2Mean",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "OFDM 2 STD",
    jsonDataName: "ofdm2Std",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "OFDM 2 PCT",
    jsonDataName: "ofdm2Pct",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },

  {
    columnName: "Online Times",
    jsonDataName: "onlineTimes",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "summary",
  },
  {
    columnName: "Online Percent",
    jsonDataName: "onlinePercentage",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "summary",
  },
  {
    columnName: "Flap",
    jsonDataName: "flap",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "flaps",
  },

  {
    columnName: "Inf",
    jsonDataName: "insertionFails",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "flaps",
  },
  {
    columnName: "Hits",
    jsonDataName: "hits",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "flaps",
  },
  {
    columnName: "Miss",
    jsonDataName: "miss",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "flaps",
  },
  {
    columnName: "Pwr",
    jsonDataName: "powerAdjustments",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "flaps",
  },
  {
    columnName: "First Flap Time",
    jsonDataName: "createTime",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "flaps",
  },
  {
    columnName: "Last Flap Time",
    jsonDataName: "lastFlapTime",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "flaps",
  },

  {
    columnName: "IP",
    jsonDataName: "cmtsIP",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "summary",
  },
  {
    columnName: "US Interfaces",
    jsonDataName: "usInterface",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
];

export const FLAPS = [
  {
    columnName: "Address",
    jsonDataName: "homeAddress",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "listOfModems",
  },
  {
    columnName: "CM MAC",
    jsonDataName: "cmMac",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "listOfModems",
  },

  {
    columnName: "Flap",
    jsonDataName: "flap",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "flaps",
  },

  {
    columnName: "Inf",
    jsonDataName: "insertionFails",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "flaps",
  },
  {
    columnName: "Hits",
    jsonDataName: "hits",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "flaps",
  },
  {
    columnName: "Miss",
    jsonDataName: "miss",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "flaps",
  },
  {
    columnName: "Pwr",
    jsonDataName: "powerAdjustments",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "flaps",
  },
  {
    columnName: "First Flap Time",
    jsonDataName: "createTime",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "flaps",
  },
  {
    columnName: "Last Flap Time",
    jsonDataName: "lastFlapTime",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "flaps",
  },
];

export const UPSTREAM = [
  {
    columnName: "Address",
    jsonDataName: "homeAddress",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "listOfModems",
  },
  {
    columnName: "CM MAC",
    jsonDataName: "cmMac",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "listOfModems",
  },
  {
    columnName: "Uptime",
    jsonDataName: "uptime",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "summary",
  },
  {
    columnName: "Registered Time",
    jsonDataName: "registeredTime",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "summary",
  },
  {
    columnName: "Frequency",
    jsonDataName: "usfrequency",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "Tx",
    jsonDataName: "usTxPower",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "Tx Delta",
    jsonDataName: "usTxDelta",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "SNR (US)",
    jsonDataName: "usSnr",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "SNR (US) Delta",
    jsonDataName: "usSnrDelta",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "Δ Time(s) (US)",
    jsonDataName: "timestamp",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "codewords",
  },
  {
    columnName: "Δ US pre CWE %",
    jsonDataName: "sample",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "codewords",
  },

  {
    columnName: "Δ US post CWE %",
    jsonDataName: "sample",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "codewords",
  },

  {
    columnName: "US Total Codewords (Δ)",
    jsonDataName: "sample",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "codewords",
  },

  {
    columnName: "US pre CWE %",
    jsonDataName: "sample",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "codewords",
  },
  {
    columnName: "US post CWE %",
    jsonDataName: "sample",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "codewords",
  },

  {
    columnName: "Flap",
    jsonDataName: "flap",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "flaps",
  },

  {
    columnName: "Inf",
    jsonDataName: "insertionFails",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "flaps",
  },
  {
    columnName: "Hits",
    jsonDataName: "hits",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "flaps",
  },
  {
    columnName: "Miss",
    jsonDataName: "miss",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "flaps",
  },
  {
    columnName: "Pwr",
    jsonDataName: "powerAdjustments",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "flaps",
  },
  {
    columnName: "First Flap Time",
    jsonDataName: "createTime",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "flaps",
  },
  {
    columnName: "Last Flap Time",
    jsonDataName: "lastFlapTime",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "flaps",
  },
];
export const OFDMA = [
  {
    columnName: "Address",
    jsonDataName: "homeAddress",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "listOfModems",
  },
  {
    columnName: "CM MAC",
    jsonDataName: "cmMac",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "listOfModems",
  },
  {
    columnName: "Frequency",
    jsonDataName: "ofdmaFrequency",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "codewords",
  },
  {
    columnName: "Uptime",
    jsonDataName: "uptime",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "summary",
  },
  {
    columnName: "Registered Time",
    jsonDataName: "registeredTime",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "summary",
  },
  {
    columnName: "OFDMA Status",
    jsonDataName: "ofdmaStatus",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "OFDMA TX",
    jsonDataName: "ofdmaTx",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "OFDMA 2nd %tile",
    jsonDataName: "odfmaPct",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "OFDMA MER Mean",
    jsonDataName: "ofdmaMean",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "OFDMA St Dev",
    jsonDataName: "odfmaStdDev",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },

  /*{
columnName: "OFDMA Min Mod",
jsonDataName: "ofdmaMinMod",
isSortingAvail: true,
isSearchAvail: true,
checked: true,
getJsonFrom: "docsis",
},
{
columnName: "OFDMA Max Mod",
jsonDataName: "ofdmaMaxMod",
isSortingAvail: true,
isSearchAvail: true,
checked: true,
getJsonFrom: "docsis",
},
{
columnName: "OFDMA Low Freq",
jsonDataName: "ofdmaLowFreq",
isSortingAvail: true,
isSearchAvail: true,
checked: true,
getJsonFrom: "docsis",
},
{
columnName: "OFDMA Upper Freq",
jsonDataName: "ofdmaHighFreq",
isSortingAvail: true,
isSearchAvail: true,
checked: true,
getJsonFrom: "docsis",
},*/

  {
    columnName: "Δ Time(s) (OFDMA)",
    jsonDataName: "timestamp",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "codewords",
  },

  {
    columnName: "Δ OFDMA pre CWE %",
    jsonDataName: "sample",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "codewords",
  },
  {
    columnName: "Δ OFDMA post CWE %,",
    jsonDataName: "",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "codewords",
  },
  {
    columnName: "OFDMA Total Codewords (Δ)",
    jsonDataName: "sample",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "codewords",
  },
  {
    columnName: "OFDMA pre CWE %",
    jsonDataName: "sample",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "codewords",
  },
  {
    columnName: "OFDMA post CWE %",
    jsonDataName: "sample",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "codewords",
  },

  {
    columnName: "Flap",
    jsonDataName: "flap",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "flaps",
  },

  {
    columnName: "Inf",
    jsonDataName: "insertionFails",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "flaps",
  },
  {
    columnName: "Hits",
    jsonDataName: "hits",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "flaps",
  },
  {
    columnName: "Miss",
    jsonDataName: "miss",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "flaps",
  },
  {
    columnName: "Pwr",
    jsonDataName: "powerAdjustments",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "flaps",
  },
  {
    columnName: "First Flap Time",
    jsonDataName: "createTime",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "flaps",
  },
  {
    columnName: "Last Flap Time",
    jsonDataName: "lastFlapTime",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "flaps",
  },
];
export const DOWNSTREAM = [
  {
    columnName: "Address",
    jsonDataName: "homeAddress",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "listOfModems",
  },
  {
    columnName: "CM MAC",
    jsonDataName: "cmMac",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "listOfModems",
  },
  {
    columnName: "Uptime",
    jsonDataName: "uptime",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "summary",
  },
  {
    columnName: "Registered Time",
    jsonDataName: "registeredTime",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "summary",
  },
  {
    columnName: "Frequency",
    jsonDataName: "dsfrequency",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },

  {
    columnName: "RX (DS)",
    jsonDataName: "dsRxPower",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "RX (DS) Delta",
    jsonDataName: "dsRxDelta",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "SNR (DS)",
    jsonDataName: "dsSnr",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "SNR (DS) Delta",
    jsonDataName: "dsSnrDelta",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "Δ Time(s) (DS)",
    jsonDataName: "timestamp",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "codewords",
  },
  {
    columnName: "Δ DS pre CWE %",
    jsonDataName: "sample",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "codewords",
  },

  {
    columnName: "Δ DS post CWE %",
    jsonDataName: "sample",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "codewords",
  },
  {
    columnName: "DS Total Codewords (Δ)",
    jsonDataName: "sample",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "codewords",
  },

  {
    columnName: "DS pre CWE %",
    jsonDataName: "sample",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "codewords",
  },
  {
    columnName: "DS post CWE %",
    jsonDataName: "sample",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "codewords",
  },
];
export const OFDM = [
  {
    columnName: "Address",
    jsonDataName: "homeAddress",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "listOfModems",
  },
  {
    columnName: "CM MAC",
    jsonDataName: "cmMac",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "listOfModems",
  },
  {
    columnName: "Uptime",
    jsonDataName: "uptime",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "summary",
  },
  {
    columnName: "Registered Time",
    jsonDataName: "registeredTime",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "summary",
  },
  {
    columnName: "Frequency",
    jsonDataName: "ofdmFrequency",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "Status (OFDM)",
    jsonDataName: "ofdmStatus",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },

  /*{
    columnName: "OFDM Min Mod",
    jsonDataName: "ofdmMinMod",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
    },
    {
    columnName: "OFDM Max Mod",
    jsonDataName: "ofdmMaxMod",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
    },
    {
    columnName: "OFDM Low Freq",
    jsonDataName: "ofdmLowFreq",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
    },
    {
    columnName: "OFDM Upper Freq",
    jsonDataName: "ofdmHighFreq",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
    },*/
  {
    columnName: "OFDM RX",
    jsonDataName: "ofdmRx",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "OFDM RX Delta",
    jsonDataName: "ofdmRxDelta",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  // {
  // columnName: "OFDM RX Range",
  // jsonDataName: "ofdmRx",
  // isSortingAvail: true,
  // isSearchAvail: true,
  // checked: true,
  // getJsonFrom: "docsis",
  // },
  // {
  // columnName: "OFDM SNR Delta",
  // jsonDataName: "ofdmRx",
  // isSortingAvail: true,
  // isSearchAvail: true,
  // checked: true,
  // getJsonFrom: "docsis",
  // },

  {
    columnName: "OFDM 2nd percentile",
    jsonDataName: "odfmPct",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "OFDM MER Mean",
    jsonDataName: "ofdmMean",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "OFDM MER Std Dev",
    jsonDataName: "odfmStdDev",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "docsis",
  },
  {
    columnName: "Δ Time(s) (OFDM)",
    jsonDataName: "timestamp",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "codewords",
  },

  {
    columnName: "Δ OFDM pre CWE %",
    jsonDataName: "sample",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "codewords",
  },
  {
    columnName: "Δ OFDM post CWE %,",
    jsonDataName: "sample",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "codewords",
  },
  {
    columnName: "OFDM Total Codewords (Δ)",
    jsonDataName: "sample",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "codewords",
  },
  {
    columnName: "OFDM pre CWE %",
    jsonDataName: "sample",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "codewords",
  },
  {
    columnName: "OFDM post CWE %",
    jsonDataName: "sample",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "codewords",
  },
];

export const BASIC = [
  {
    columnName: "Address",
    jsonDataName: "homeAddress",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "listOfModems",
  },
  {
    columnName: "SMT",
    jsonDataName: "serviceArea",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "listOfModems",
  },
  {
    columnName: "Type",
    jsonDataName: "cmModel",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "listOfModems",
  },
  {
    columnName: "CM MAC",
    jsonDataName: "cmMac",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "listOfModems",
  },

  {
    columnName: "Model",
    jsonDataName: "model",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "listOfModems",
  },
  {
    columnName: "Uptime",
    jsonDataName: "uptime",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "summary",
  },
  {
    columnName: "Registered Time",
    jsonDataName: "registeredTime",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "summary",
  },

  {
    columnName: " Registered Status",
    jsonDataName: "cmOnlineStatus",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "summary",
  },

  {
    columnName: "IP",
    jsonDataName: "cmtsIP",
    isSortingAvail: true,
    isSearchAvail: true,
    checked: true,
    getJsonFrom: "summary",
  },
];

export const viewOptionsType = [
  "FULL",
  "FLAPS",
  "BASIC",
  "US SC QAM",
  "OFDMA",
  "DS SC QAM",
  "OFDM",
];

export const specOptions = [
  { columnName: "IN-SPEC", columnKey: "inSpec" },
  { columnName: "OUT-SPEC", columnKey: "outSpec" },
  { columnName: "MARGINAL", columnKey: "marginal" },
  { columnName: "OTHERS", columnKey: "others" },
];
// export const specOptions = [columnName: "IN-SPEC",
// isVisible, "OUT-SPEC", "MARGINAL"];
