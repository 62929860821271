import { Form } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { useEffect, useState, CSSProperties } from "react";

import "../../design-tokens/ReusableComp.scss";
import { validateEmail } from "./Util";
import InlineErrorMessage from "./InlineErrorMessage";
import AppLabels from "../../constants/App_Labels";
import { propTypes } from "react-bootstrap/esm/Image";

interface Props {
  email: any;
  setEmail: any;
  setIsEmailValid?: any;
  title?: string;
  autocomplete?: string;
  titleStyle?: CSSProperties;
  buttonStyle?: CSSProperties;
  emailInputStyle?: CSSProperties;
}

export default function EmailEntry({
  email,
  setEmail,
  setIsEmailValid,
  title,
  autocomplete,
  titleStyle,
  buttonStyle,
  emailInputStyle,
}: Props) {
  const labels = AppLabels();
  const [emails, setEmails] = useState(email);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (email !== emails) {
      setEmails(email);
    }
  }, [email]);

  //check if email format is valid
  useEffect(() => {
    if (emails?.length > 0 && emails[0] !== "") {
      let isEmailValid = false;
      for (let i = 0; i < emails?.length; i++) {
        isEmailValid = validateEmail(emails[i]);
        if (!isEmailValid) {
          break;
        }
      }

      setIsEmailValid && setIsEmailValid(isEmailValid);
      if (isEmailValid && errorMessage != "") {
        setErrorMessage("");
      } else if (!isEmailValid && errorMessage != labels.Invalid_Email_Format) {
        setErrorMessage(labels.Invalid_Email_Format);
      }
    }
  }, [emails]);

  const setEmailInArray = (value: any, index: number) => {
    emails[index] = value;
    setEmails([...emails]);
    setEmail([...emails]);
  };

  const onRemove = (index: number) => {
    const newEmailArr = emails.filter((e: any, i: number) => index !== i);
    setEmails([...newEmailArr]);
    setEmail([...newEmailArr]);
  };

  return (
    <>
      <Form.Group>
        {title && (
          <label style={titleStyle} className="emailTitleLabel">
            {title}
          </label>
        )}
        {emails?.map((element: any, index: any) => (
          <div className="inlineEmailContainer" key={index}>
            <Form.Control
              name="email"
              onChange={(e) => setEmailInArray(e.target.value, index)}
              className="emailInput"
              maxLength={100}
              value={element}
              style={emailInputStyle}
              autoComplete={autocomplete}
              placeholder={labels.emailPlaceholder}
            />

            {index > 0 && (
              <button
                type="button"
                className="emailRemoveBtn"
                onClick={() => onRemove(index)}
              >
                <MdClose color="black" />
              </button>
            )}
          </div>
        ))}
        <div className="moreBtnContainer">
          <InlineErrorMessage
            errorMessage={errorMessage}
            style={{ marginBottom: "5px" }}
          />
          <button
            type="button"
            className="emailBtn"
            style={buttonStyle}
            onClick={() => setEmails([...emails, ""])}
          >
            + {labels.Add_more_email}
          </button>
        </div>
      </Form.Group>
    </>
  );
}
