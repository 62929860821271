import AppLabels from "../../constants/App_Labels";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import "../../design-tokens/account-page/FailedAttributes.scss";
import { isNull } from "../reusable/Util";
const FailedAttributesRhp = (props: any) => {
  const { rhpDocsisInfo } = props;

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "#000000",
      boxShadow: theme.shadows[4],
      fontSize: 12,
    },
  }))(Tooltip);
  const labels = AppLabels();

  const arr: any = [];
  const upStreamArray: any = [];
  if (rhpDocsisInfo) {
    if (
      rhpDocsisInfo?.hasOwnProperty("upstreamChannels") &&
      rhpDocsisInfo?.upstreamChannels?.length !== 0
    ) {
      rhpDocsisInfo?.upstreamChannels?.map((val: any, index: any) => {
        if (
          val?.hasOwnProperty("tx_power") &&
          val.tx_power?.hasOwnProperty("status")
        ) {
          if (val.tx_power?.status === "Fail") {
            upStreamArray.push({
              name: labels.CM_TRANSMIT_POWER.toUpperCase(),
              value: isNull(val?.tx_power?.value),
            });
          }
        }
        if (val?.hasOwnProperty("snr") && val.snr?.hasOwnProperty("status")) {
          if (val.snr?.status === "Fail") {
            upStreamArray.push({
              name: labels.UPSTREAM_SNR.toUpperCase(),
              value: isNull(val?.snr?.value),
            });
          }
        }
      });
    }
    if (
      rhpDocsisInfo?.hasOwnProperty("downstreamChannels") &&
      rhpDocsisInfo?.downstreamChannels?.length !== 0
    ) {
      rhpDocsisInfo?.downstreamChannels?.map((val: any, index: any) => {
        if (
          val?.hasOwnProperty("rx_power") &&
          val.rx_power?.hasOwnProperty("status")
        ) {
          if (val.rx_power.status === "Fail") {
            upStreamArray.push({
              name: labels.CM_RECEIVE_POWER.toUpperCase(),
              value: isNull(val?.rx_power?.value),
            });
          }
        }
        if (val?.hasOwnProperty("snr") && val.snr?.hasOwnProperty("status")) {
          if (val.snr.status === "Fail") {
            upStreamArray.push({
              name: labels.DOWNSTREAM_SNR.toUpperCase(),
              value: isNull(val?.snr?.value),
            });
          }
        }
      });
    }
    if (upStreamArray?.length > 0) {
      arr.push({
        name: "RHP_DOCSIS",
        value: upStreamArray,
      });
    }
  }
  return (
    <div className="faCmGW">
      <p>
        <span style={{ fontWeight: 600 }}>{labels.failedAttributes?.toUpperCase()} : </span>
        {arr ? (
          arr.map((el: any, index: any) => {
            return (
              <LightTooltip
                title={
                  <table className="tableTypeTwo">
                    <thead>
                      <tr>
                        <th>{labels.Name?.toUpperCase()}</th>
                        <th>{labels.VALUE?.toUpperCase()}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {el?.value?.map((v: any, i: any) => {
                        return (
                          <tr key={i}>
                            <td>{v?.name}</td> <td>{v?.value}</td>{" "}
                          </tr>
                        );
                      })}{" "}
                    </tbody>
                  </table>
                }
              >
                <span className="tooltipText" style={{ fontWeight: 500 }}>
                  {el?.name},{" "}
                </span>
              </LightTooltip>
            );
          })
        ) : (
          <></>
        )}
      </p>
    </div>
  );
};

export default FailedAttributesRhp;
