import { CMTSNodeDetails } from "../../../models/node/nodeinfo/CMTSNodeDetails";

export const CMTS_NODE_BEGIN = "CMTS_NODE_BEGIN";
export const SET_CMTS_NODE_DETAILS = "SET_CMTS_NODE_DETAILS";
export const CMTS_NODE_SUCCESS = "CMTS_NODE_SUCCESS";
export const CMTS_NODE_FAILURE = "CMTS_NODE_FAILURE";
export const RESET_CMTS_NODE = "RESET_CMTS_NODE";

interface CMTSNodeBegin {
  type: typeof CMTS_NODE_BEGIN;
}

interface SetCMTSNodeDetails {
  type: typeof SET_CMTS_NODE_DETAILS;
  payload: CMTSNodeDetails;
}

interface CMTSNodeSuccess {
  type: typeof CMTS_NODE_SUCCESS;
  payload: CMTSNodeDetails;
}

interface CMTSNodeFailure {
  type: typeof CMTS_NODE_FAILURE;
  payload: any;
}

interface ResetCMTSNode {
  type: typeof RESET_CMTS_NODE;
}

export type CMTSNodeActionTypes =
  | CMTSNodeBegin
  | SetCMTSNodeDetails
  | CMTSNodeSuccess
  | CMTSNodeFailure
  | ResetCMTSNode;
