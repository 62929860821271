//colors ordered in alphabetical order
export default Object.freeze({
  background: "#E6E6E6",
  black01: "rgba(0, 0, 0, 0.01)", //pop up black Opacity
  black50: "rgba(0, 0, 0, 0.5)", //pop up black Opacity
  black70: "rgba(0, 0, 0, 0.75)", //pop up black Opacity
  brightGrey: "#3C4257",
  danger: "#dc3545", //any errors, colors in error icons
  disabled: "#6C757D", //for disabled state of any action or any tabs or any font , link etc
  good: "#FFD600",
  greyDark: "#414042",
  greyLight: "#BBBDC0",
  greyMedium: "#808284", //use it for unknown signal strength, list vs table switcher in diagnostics. , unselected navtabs
  info: "#cce5ff",
  infoCurtain: "#8ac3ff",
  lightGreen: "#28a820", //workorders list/homepgae
  lightPink: "#cccccc33",
  link: "#1A73E9", //colors for links, clickable labels, breadcrumb
  intermittent: "#DC3545",
  marginal: "#FFBF3F",
  maroonRed: "#A72856", //color for distance from gateway in wifi analyzer
  oceanBlue: "#079de6",
  oceanBlue10: "#d9f0f8",
  brightBlue: "#0096FF", //color for rf doenstream trace
  orange: "#FF9900", //out of spec US SCQAM channel
  orangeLight: "#FFCC00", //out of spec OFDMA channel
  sunsetOrange: "#FA5F55", // for powertrace
  pink: "#fdadad",
  primary: "#DA291C", //rogers red for headers, popup headers etc.
  primary10: "rgba(218, 41, 28, 0.10)", //BACKGROUND AS per UI, app tray background
  primary20: "rgba(218, 41, 28, 0.20)", //To hightlight geojson selected area
  primary30: "rgba(218, 41, 28, 0.30)",
  pureBlack: "#000000", //font color for any regular font, lik diagnostic attributes of a column etc.
  purple: "#9b4e9b",
  profileBackground: "rgba(255, 133, 133, 0.10)", // for profile tray in drawer
  aqua: "#00A0B7", //out of spec DS SCQAM channel
  aquaMed: "#68CADA", //out of spec OFDM channel
  aquaLight: "#BBE4EC", //out of spec US & DS channel
  skyBlue: "#4782e5",
  solitude: "#e2e8f0",
  success: "#00985f", //use it for success msg, success icon, anyplace it is pass status.
  tabBackground: "#f6f6f6", //navigational tabs
  veryGood: "#8AC640",
  warning: "#ffbf3f",
  warningCurtainText: "#63480C", //warning curtain msg text
  warningCurtain: "#F9F0DB", //Curtain msg background
  skip: "#E77C40",
  white: "#FFFFFF",
  paleGrey: "#EEEEEE",
  clickService: "#3984c4",
  clickServiceShadow: "rgba(57, 132, 196, 0.50)",
  CPAT: "#6bd030",
  nika: "#7e0d08",
  nika70: "rgba(252,144,3, 0.70)",
  nikaShadow: "rgba(252,144,3, 0.50)",
  blue: "#006ACC",
  darkGrey: "#4F4F4F",
  iconColor: "#5f6368",
  mikadoYellow: "#ffc107", // fiber cable color
  darkImperialBlue: "#0e0962", // rfdrops color
  denim: "#1A73E9", // rf cable color
  crystal: "#a5e3de", // rf cable(715, 540 cable type)
  pearlyPurple: "#bd6fa0", // rf cable(860 cable type)
  americanSilver: "#CCD1D1", // cable drops color
  philippineBronze: "#6E2C00", // Support structure

  lightBlue: "#3BE6FF", // Support structure
  gold: "#FFC000", // NODES, ACTIVES, TAPS, POWER SUPPLIES
  green: "#00A053", // SPLITTERS, TERMINATORS, PWR BLKS, RF CABLES, RF SPLICES, CABLE DROPS
  darkGreen: "#006635", // LONG RF CABLES
  electricGreen: "#00FF00", //RF cables >=750
  tBlue: "#0300FB", //FIBRE CABLES, FOSCS, MDUs
  platinum: "#E5E4E2", // Rightclick menu active background color
  peru: "#cd853f", // Current Work Order Icon
  otdrHighlight: "rgba(255, 49, 49, 0.40)",
});
