import CustomAccordion from "../../../../components/reusable/CustomAccordion";
import TopologyLabels from "../../../constants/topologyLabels";
import { GetAvailableTR } from "../../../../components/reusable/Util";
import GoogleMapDirectionsBtn from "../../../../components/reusable/GoogleMapDirectionsBtn";

interface Props {
  elementData: any;
}

export default function OLTDetailsTable({ elementData }: Props) {
  const labels = TopologyLabels();
  const data = elementData?.data;

  return (
    <>
      {data && (
        <div className="activeMapEleCont">
          <CustomAccordion
            accTitle={labels.OLT + ": " + data?.oltName}
            headerComp={
              <GoogleMapDirectionsBtn
                lat={elementData?.coord?.lat}
                lng={elementData?.coord?.lng}
              />
            }
            accBodyElement={
              <table className="dataTableDetails">
                <thead>
                  <tr>
                    <td>{labels?.name.toUpperCase()}</td>
                    <td>{labels?.Value.toUpperCase()}</td>
                  </tr>
                </thead>
                <tbody>
                  <GetAvailableTR
                    name={labels.networkID}
                    value={data?.networkId}
                  />
                  <GetAvailableTR
                    name={labels.Comments}
                    value={data?.comments}
                  />
                  <GetAvailableTR
                    name={labels.description}
                    value={data?.description}
                  />
                  <GetAvailableTR
                    name={labels.SiteName}
                    value={data?.siteName}
                  />
                  <GetAvailableTR name={labels.Address} value={data?.address} />
                  <GetAvailableTR
                    name={labels.Province}
                    value={data?.province}
                  />
                  <GetAvailableTR name={labels.Town} value={data?.town} />
                  <GetAvailableTR
                    name={labels.BuildingId}
                    value={data?.buildingId}
                  />
                  <GetAvailableTR
                    name={labels.BuildingType}
                    value={data?.buildingType}
                  />
                  <GetAvailableTR name={labels.ISPCode} value={data?.ispCode} />
                  <GetAvailableTR
                    name={labels.ISPABuildingFk}
                    value={data?.ispaBuildingFk}
                  />
                  <GetAvailableTR
                    name={labels.ISPABuildingFloorFk}
                    value={data?.ispaBuildingFloorFk}
                  />
                  <GetAvailableTR
                    name={labels.Manufacture}
                    value={data?.manufacture}
                  />
                  <GetAvailableTR
                    name={labels.MaxPorts}
                    value={data?.maxPorts}
                  />
                  <GetAvailableTR name={labels.Model} value={data?.model} />
                  <GetAvailableTR
                    name={labels.MountPoint}
                    value={data?.mountPoint}
                  />
                  <GetAvailableTR name={labels.Part} value={data?.part} />
                  <GetAvailableTR name={labels.RackId} value={data?.rackId} />
                  <GetAvailableTR
                    name={labels.RackName}
                    value={data?.rackName}
                  />
                  <GetAvailableTR
                    name={labels.RackType}
                    value={data?.rackType}
                  />
                  <GetAvailableTR name={labels.RePres} value={data?.rePres} />
                  <GetAvailableTR name={labels.rpats} value={data?.rpats} />
                  <GetAvailableTR name={labels.SType} value={data?.sType} />
                  <GetAvailableTR name={labels.SCPres} value={data?.scPres} />
                  <GetAvailableTR
                    name={labels.SectionFCode}
                    value={data?.sectionFCode}
                  />
                  <GetAvailableTR name={labels.Attr1} value={data?.attr1} />
                  <GetAvailableTR
                    name={labels.Catalogue + " 1"}
                    value={data?.catalogue1}
                  />
                  <GetAvailableTR
                    name={labels.Catalogue + " 2"}
                    value={data?.catalogue2}
                  />
                  <GetAvailableTR name={labels.Clli} value={data?.clli} />
                  <GetAvailableTR
                    name={labels.SymbolName}
                    value={data?.symbolName}
                  />
                </tbody>
              </table>
            }
          />
        </div>
      )}
    </>
  );
}
