import { Marker } from "@react-google-maps/api";
import React, { useEffect } from "react";
import { renderToString } from "react-dom/server";
import { FaSearchLocation } from "react-icons/fa";
import { useDispatch } from "react-redux";
import colors from "../../config/colors";
import { mapElementStyles } from "../../config/mapItemsConfig";
import { setMapCenter } from "../../store/slices/mapItems/mapDetails";
import { GetMapDetailsProps } from "../../util/reduxFunctions/getTopologyState";

const SearchedElement = () => {
  const { searchedElePin } = GetMapDetailsProps();
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchedElePin) {
      dispatch(
        setMapCenter({
          lat: searchedElePin?.geometry?.coordinates?.[1],
          lng: searchedElePin?.geometry?.coordinates?.[0],
        })
      );
    }
  }, [searchedElePin]);

  const searchedEleIcon = renderToString(
    <FaSearchLocation
      size={mapElementStyles?.common?.iconSize}
      color={colors.primary}
    />
  );

  return (
    <>
      {searchedElePin && searchedElePin?.geometry && (
        <Marker
          key="activeEleMarker"
          visible={searchedElePin?.geometry !== null}
          zIndex={99999}
          clickable={false}
          position={{
            lat: searchedElePin?.geometry?.coordinates?.[1],
            lng: searchedElePin?.geometry?.coordinates?.[0],
          }}
          icon={{
            url:
              "data:image/svg+xml;charset=utf-8," +
              encodeURIComponent(searchedEleIcon),
            anchor: new google.maps.Point(
              mapElementStyles?.common?.anchor?.x,
              mapElementStyles?.common?.anchor?.y
            ),
          }}
        />
      )}
    </>
  );
};

export default React.memo(SearchedElement);
