import { InfoBox } from "@react-google-maps/api";
import { useState } from "react";
import { MdContentCopy } from "react-icons/md";
import { BsGlobe } from "react-icons/bs";
import { FaShuttleVan } from "react-icons/fa";
import { AiOutlineClear } from "react-icons/ai";
import { GiPathDistance } from "react-icons/gi";
import { SiGooglestreetview } from "react-icons/si";

import AppLabels from "../../../../constants/App_Labels";
import {
  copyToClipboard,
  getMapPointsDistance,
} from "../../../../components/reusable/Util";
import { GetGoogleMaps } from "../../../util/reduxFunctions/getTopologyState";
import SearchByCoordinates from "./SearchByCoordinates";
import DistanceMeasurementMarkers from "./DistanceMeasurementMarkers";

interface Props {
  showInfoWin: boolean;
  setShowInfoWin: any;
}

export default function InfoWindowMenu({ showInfoWin, setShowInfoWin }: Props) {
  const labels = AppLabels();
  const { pressedCoord } = GetGoogleMaps();
  const activeLatLng = pressedCoord?.lat + ", " + pressedCoord?.lng;

  const [showCoordSearchModal, setShowCoordSearchModal] = useState(false);
  const [measurePoints, setMeasurePoints] = useState<any>([]);
  const [measurePoly, setMeasurePoly] = useState<any>([]);
  const [measureDistances, setMeasureDistances] = useState<any>([]);

  const onMeasureDistance = (coord = pressedCoord) => {
    if (measurePoints?.length > 0) {
      //populate polylines
      const polyline = [measurePoints[measurePoints.length - 1], coord];
      measurePoly?.length > 0
        ? setMeasurePoly([...measurePoly, polyline])
        : setMeasurePoly([polyline]);

      //populate distance numbers
      const distance = getMapPointsDistance(
        measurePoints[measurePoints.length - 1],
        coord,
        measureDistances?.length > 0
          ? measureDistances[measureDistances.length - 1]
          : 0
      );
      measureDistances?.length > 0
        ? setMeasureDistances([...measureDistances, distance])
        : setMeasureDistances([distance]);
    }

    //populate points
    measurePoints?.length > 0
      ? setMeasurePoints([...measurePoints, coord])
      : setMeasurePoints([coord]);

    setShowInfoWin(false);
  };

  const onClearMeasurements = () => {
    setMeasurePoints([]);
    setMeasurePoly([]);
    setMeasureDistances([]);
    setShowInfoWin(false);
  };

  return (
    <>
      {showInfoWin && (
        <InfoBox
          position={pressedCoord}
          options={{ zIndex: 1000, closeBoxURL: "" }}
        >
          <div className="infoWinCont">
            <div
              className="itemCont"
              onClick={() => {
                copyToClipboard(activeLatLng);
                setShowInfoWin(false);
              }}
            >
              <MdContentCopy style={{ marginRight: 5 }} />
              <label>{activeLatLng}</label>
            </div>
            <div
              className="itemCont"
              onClick={() => {
                setShowInfoWin(false);
                setShowCoordSearchModal(true);
              }}
            >
              <BsGlobe style={{ marginRight: 5 }} />
              <label>{labels.Search_Coordinates}</label>
            </div>
            <div
              className="itemCont"
              onClick={() =>
                window.open(
                  `https://www.google.com/maps/dir/?api=1&destination=${pressedCoord?.lat},${pressedCoord?.lng}&travelmode=driving`
                )
              }
            >
              <FaShuttleVan style={{ marginRight: 5 }} />
              <label>{labels.DirectionsToHere}</label>
            </div>
            <div
              className="itemCont"
              onClick={() =>
                window.open(
                  `https://www.google.com/maps/search/?api=1&query=${pressedCoord?.lat},${pressedCoord?.lng}`
                )
              }
            >
              <SiGooglestreetview style={{ marginRight: 5 }} />
              <label>{"Open in google maps"}</label>
            </div>
            <div className="itemCont" onClick={() => onMeasureDistance()}>
              <GiPathDistance style={{ marginRight: 5 }} />
              <label>
                {measurePoints?.length > 0
                  ? labels.Distance_To_Here
                  : labels.Measure_Distance}
              </label>
            </div>
            {measurePoints?.length > 0 && (
              <div className="itemCont" onClick={onClearMeasurements}>
                <AiOutlineClear style={{ marginRight: 5 }} />
                <label>{labels.Clear_measurements}</label>
              </div>
            )}
          </div>
        </InfoBox>
      )}
      <DistanceMeasurementMarkers
        measurePoints={measurePoints}
        setMeasurePoints={setMeasurePoints}
        measurePoly={measurePoly}
        setMeasurePoly={setMeasurePoly}
        measureDistances={measureDistances}
        setMeasureDistances={setMeasureDistances}
      />
      <SearchByCoordinates
        showCoordSearchModal={showCoordSearchModal}
        setShowCoordSearchModal={setShowCoordSearchModal}
      />
    </>
  );
}
