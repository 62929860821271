import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { FormsHistoryState } from "../../../store/states/history/FormsHistory";
import {
  formsHistoryBegin,
  formsHistorySuccess,
  formsHistoryFailure,
} from "../../../store/actions/history/FormsHistoryAction";

export default class FormsHistoryClient {
  static getHistory =
    (
      techId: String,
      lanId: String,
      accountId: String,
      cbpid: String,
      workorderId: String,
      startDate: String,
      endDate: String,
      formType: String,
      limit: Number,
      offset: Number,
      nodeNumber: String,
    ): ThunkAction<void, FormsHistoryState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "workorders/forms",
      };
      const dataObj = {
        techId: techId,
        lanId: lanId,
        accountId: accountId,
        cbpid:cbpid,
        workorderId: workorderId,
        startDate: startDate,
        endDate: endDate,
        formType: formType,
        limit: limit,
        offset: offset,
        nodeNumber: nodeNumber,
      };

      dispatch(formsHistoryBegin());

      HttpClient.post<any>(httpParameters, dataObj)
        .then((res) => {
          dispatch(formsHistorySuccess(res));
          return res;
        })
        .catch((error) => {
          dispatch(formsHistoryFailure(error));
          return error;
        });
    };
}
