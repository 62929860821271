import "date-fns";
import { format, addDays } from "date-fns";
import { Form, DropdownButton, Dropdown } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Card } from "@material-ui/core";
import ErrorBox from "../../reusable/ErrorBox";
import { getTestTypeCode } from "../../reusable/Util";
import { DateRange } from "react-date-range";
import CustomDialog from "../../reusable/CustomDialog";
import { MdDateRange } from "react-icons/md";

const HistoryFilters = (props: any) => {
  const techData = props?.techData;
  const canSearchForTechId =
    techData?.roles.includes("manager") || techData?.roles.includes("admin");

  useEffect(() => {
    if (!canSearchForTechId) {
      setTechLanId(techData?.lanId);
    }
  }, [canSearchForTechId]);

  const [techId, setTechId] = useState("");
  const [techLanId, setTechLanId] = useState("");
  const [workOrderId, setWorkOrderId] = useState("");
  const [accountId, setAccountId] = useState("");
  const [seleTestType, setSeleTestType] = useState("All Tests");

  const onTechIdSubmit = (e: any) => setTechId(e.target.value);
  const onTechLanIdSubmit = (e: any) => setTechLanId(e.target.value);
  const onWorkOrderIdSubmit = (e: any) => {
    setWorkOrderId(e.target?.value?.replace(/\s+/g, "")?.toUpperCase());
  };
  const onAccountIdSubmit = (e: any) => setAccountId(e.target.value);

  const [open, setOpen] = useState(false);
  const [error, setError] = useState("This is Error Message");
  const handleClose = () => {
    setOpen(false);
  };
  const [showDateRange, setShowDateRange] = useState(false);
  const [dateRangeSelection, setDateRangeSelection] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: "selection",
    },
  ]);
  const handleDateRangeSelect = (ranges: any) => {
    setDateRangeSelection([ranges.selection]);
  };

  const handleApplyFilters = () => {
    var datesDiff = 0;
    if (
      dateRangeSelection?.[0]?.startDate != null &&
      dateRangeSelection?.[0]?.endDate != null
    ) {
      const diffInMs = Math.abs(
        dateRangeSelection?.[0]?.endDate?.getTime() -
          dateRangeSelection?.[0]?.startDate.getTime()
      );
      datesDiff = Math.round(diffInMs / (1000 * 60 * 60 * 24));
    }

    if (
      techId != undefined &&
      techId.length > 0 &&
      (techId.length != 4 || isNaN(Number(techId)))
    ) {
      setError("Please enter valid 4 digit Technician ID");
      setOpen(true);
    } else if (datesDiff > 30) {
      setError("Date difference should not be more than 30 days");
      setOpen(true);
    } else if (
      dateRangeSelection?.[0]?.startDate?.getTime() >
      dateRangeSelection?.[0]?.endDate.getTime()
    ) {
      setError("From date should be equal or lesser than To date");
      setOpen(true);
    } else {
      props.onApplyFilters(
        techId,
        techLanId,
        workOrderId,
        accountId,
        getTestTypeCode(seleTestType),
        format(
          new Date(
            Number(dateRangeSelection?.[0]?.startDate?.getFullYear()),
            Number(dateRangeSelection?.[0]?.startDate?.getMonth()),
            Number(dateRangeSelection?.[0]?.startDate?.getDate())
          ),
          "dd/MM/yyyy"
        ),
        format(
          new Date(
            Number(dateRangeSelection?.[0]?.endDate?.getFullYear()),
            Number(dateRangeSelection?.[0]?.endDate?.getMonth()),
            Number(dateRangeSelection?.[0]?.endDate?.getDate())
          ),
          "dd/MM/yyyy"
        )
      );
    }
  };

  return (
    <>
      <Card className="filterRootContainer">
        <div className="filterContainer">
          <span className="filterText">Filters</span>
          <div className="filterRow">
            <div className="inputContainer">
              <Form.Group>
                <Form.Label>Technician ID</Form.Label>
                <Form.Control
                  disabled={!canSearchForTechId}
                  name="inputField"
                  onChange={onTechIdSubmit}
                  className="inputField"
                  id="inputField"
                  value={techId || ""}
                  placeholder="Type Tech ID.."
                />
              </Form.Group>
            </div>
            <div className="inputContainer">
              <Form.Group>
                <Form.Label>Technician Lan ID</Form.Label>
                <Form.Control
                  disabled={!canSearchForTechId}
                  name="inputField"
                  onChange={onTechLanIdSubmit}
                  className="inputField"
                  id="inputField"
                  value={techLanId || ""}
                  placeholder="Type Tech Lan ID.."
                />
              </Form.Group>
            </div>
            <div className="inputContainer">
              <Form.Group>
                <Form.Label>Work Order ID</Form.Label>
                <Form.Control
                  name="inputField"
                  onChange={onWorkOrderIdSubmit}
                  className="inputField"
                  id="inputField"
                  value={workOrderId || ""}
                  placeholder="Type Order ID.."
                />
              </Form.Group>
            </div>
          </div>
          <div className="filterRow">
            <div className="inputContainer">
              <Form.Group>
                <Form.Label>Account ID</Form.Label>
                <Form.Control
                  name="inputField"
                  onChange={onAccountIdSubmit}
                  className="inputField"
                  id="inputField"
                  value={accountId || ""}
                  placeholder="Type Account ID.."
                />
              </Form.Group>
            </div>
            <div className="testTypeCont">
              <Form.Label>Test Type</Form.Label>
              <DropdownButton
                title={seleTestType}
                onSelect={(e: any) => {
                  setSeleTestType(e);
                }}
                drop="up"
                size="sm"
                className="testTypeDropd"
                id="testTypeDd"
              >
                <Dropdown.Item key={0} eventKey="All Tests">
                  All Tests
                </Dropdown.Item>
                <Dropdown.Item key={1} eventKey="Whole Home Test">
                  Whole Home Test
                </Dropdown.Item>
                <Dropdown.Item key={2} eventKey="Drop &amp; Stay Test">
                  Drop &amp; Stay Test
                </Dropdown.Item>
                <Dropdown.Item key={3} eventKey="EDBA Test">
                  EDBA Test
                </Dropdown.Item>
              </DropdownButton>
            </div>
            <div className="inputContainer">
              <Form.Group>
                <Form.Label>Date Range</Form.Label>
                <div
                  className="fromToDate"
                  onClick={() => setShowDateRange(true)}
                >
                  <Form.Text className="fromToDateLabel">
                    {format(
                      new Date(
                        Number(
                          dateRangeSelection?.[0]?.startDate?.getFullYear()
                        ),
                        Number(dateRangeSelection?.[0]?.startDate?.getMonth()),
                        Number(dateRangeSelection?.[0]?.startDate?.getDate())
                      ),
                      "MM/dd/yyyy"
                    )}{" "}
                    -{" "}
                    {format(
                      new Date(
                        Number(dateRangeSelection?.[0]?.endDate?.getFullYear()),
                        Number(dateRangeSelection?.[0]?.endDate?.getMonth()),
                        Number(dateRangeSelection?.[0]?.endDate?.getDate())
                      ),
                      "MM/dd/yyyy"
                    )}
                  </Form.Text>
                  <MdDateRange fill={"#808284"} size={20} />
                </div>
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="filterBtn">
          <button className="applyFilersBtn" onClick={handleApplyFilters}>
            Apply Filters
          </button>
        </div>
      </Card>
      <ErrorBox
        open={open}
        error={error}
        setOpen={setOpen}
        handleClose={handleClose}
      />
      <div>
        <CustomDialog
          open={showDateRange}
          handleClose={() => {
            setShowDateRange(false);
          }}
          childComponent={
            <div className="fromToRootContainer">
              <DateRange
                onChange={handleDateRangeSelect}
                moveRangeOnFirstSelection={false}
                ranges={dateRangeSelection}
                rangeColors={["#da291c", "#da291c", "#da291c"]}
              />
            </div>
          }
          heading="Select Date Range"
        />
      </div>
    </>
  );
};
export default HistoryFilters;
