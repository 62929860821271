export const NEIGH_DIAG_LIST_BEGIN = "NEIGH_DIAG_LIST_BEGIN";
export const NEIGH_DIAG_LIST_SUCCESS = "NEIGH_DIAG_LIST_SUCCESS";
export const NEIGH_DIAG_LIST_FAILURE = "NEIGH_DIAG_LIST_FAILURE";
export const NEIGH_DIAG_LIST_RESET = "NEIGH_DIAG_LIST_RESET";

interface NeighDiagListBegin {
  type: typeof NEIGH_DIAG_LIST_BEGIN;
}

interface NeighDiagListSuccess {
  type: typeof NEIGH_DIAG_LIST_SUCCESS;
  payload: any;
}

interface NeighDiagListFailure {
  type: typeof NEIGH_DIAG_LIST_FAILURE;
  payload: Error;
}

interface NeighDiagListReset {
  type: typeof NEIGH_DIAG_LIST_RESET;
}
export type NeighDiagListActionTypes =
  | NeighDiagListBegin
  | NeighDiagListSuccess
  | NeighDiagListFailure
  | NeighDiagListReset;
