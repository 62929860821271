import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../middleware/apiActions";
import { MDU_SUPPORT_STRUCTURE } from "../../../middleware/apiEndpoints";
import storeConst from "../../../storeConst";
import { decrApiCall, incrApiCall } from "../mapDetails";

const slice = createSlice({
  name: "mduSupportStructure",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    getMDUSupportStructureBegin: (mduSupportStructure) => {
      mduSupportStructure.isLoading = true;
      mduSupportStructure.status = "";
    },
    getMDUSupportStructureSuccess: (mduSupportStructure, action) => {
      mduSupportStructure.isLoading = false;
      mduSupportStructure.data = action.payload;
      mduSupportStructure.status = storeConst.OK;
    },
    getMDUSupportStructureFailure: (mduSupportStructure, action) => {
      mduSupportStructure.isLoading = false;
      mduSupportStructure.error = action.payload;
      mduSupportStructure.data = undefined;
      mduSupportStructure.status = storeConst.ERROR;
    },
  },
});

const {
  getMDUSupportStructureBegin,
  getMDUSupportStructureSuccess,
  getMDUSupportStructureFailure,
} = slice.actions;
export default slice.reducer;

export const getMDUSupportStructure =
  (owningDrawing: any) => (dispatch: any) => {
    dispatch(
      apiCallBegan({
        url: `${MDU_SUPPORT_STRUCTURE}/${owningDrawing}`,
        method: "get",
        onStart: getMDUSupportStructureBegin.type,
        onSuccess: getMDUSupportStructureSuccess.type,
        onError: getMDUSupportStructureFailure.type,
        onStartDispatch: incrApiCall.type,
        onSuccessDispatch: decrApiCall.type,
        onErrorDispatch: decrApiCall.type,
      })
    );
  };
