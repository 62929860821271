const NeighbourhoodTableHead = (props: any) => {
  let columns = [
    "Home Address",
    "CM MAC",
    "Model",
    "CM Uptime",
    "CM Registration Time",
    "D3-US",
    "D3-DS",
    "D31-US",
    "D31-DS",
    "P.Svc",
    // "FLAP COUNT",
    "INF",
  ];
  return (
    <thead className="nTabHead">
      <tr>
        {columns?.map((c: any, i: number) => {
          return (
            <th key={i}>
              <span>{c}</span>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default NeighbourhoodTableHead;
