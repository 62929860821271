import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ONTClient from "../../network/httpclient/ont/ONTClient";
import ONTDetails from "./ONTDetails";
import OLTDetails from "./OLTDetails";
import ONTBandwidth from "./ONTBandwidth";
import ReloadData from "../reusable/ReloadData";
import DiagApiCallCont from "../reusable/DiagApiCallCont";
import { OLTSummary } from "../../store/models/account/diagnostics/ont/OLTSummary";
import CMSpeedTestInfo from "../cable-modem/CMSpeedTestInfo";
const ONTSummaryInfo = (props: any) => {
  const {
    selectedDevice,
    selectedSA,
    ontLoading,
    ontSummaryInfo,
    ontSummaryStatus,
    gwMAC
  } = props;
  const dispatch = useDispatch();
  const { bandwidthInfo, bandwidthIsLoading, bandwidthStatus } = useSelector(
    (state: any) => ({
      bandwidthInfo: state.AccountState.ONTBandwidthInfo.data,
      bandwidthIsLoading: state.AccountState.ONTBandwidthInfo.isLoading,
      bandwidthStatus: state.AccountState.ONTBandwidthInfo.status,
    }),
    shallowEqual
  );

  const { oltSummaryInfo, oltSummaryIsLoading, oltSummaryStatus } = useSelector(
    (state: any) => ({
      oltSummaryInfo: state.AccountState.OLTSummaryDetails.data,
      oltSummaryIsLoading: state.AccountState.OLTSummaryDetails.isLoading,
      oltSummaryStatus: state.AccountState.OLTSummaryDetails.status,
    }),
    shallowEqual
  );

  // let processDeviceData = getProcessData(oltSummaryInfo);

  const refreshData = () => {
    dispatch(ONTClient.getONTSummary(selectedDevice.serialNumber));
    dispatch(ONTClient.getONTBandwidth(selectedDevice.serialNumber));
    dispatch(ONTClient.getOLTSummary(selectedDevice.serialNumber));
  };

  return (
    <>
      <div className="refreshCont">
        <ReloadData refreshData={refreshData} />
      </div>
      <div className="cmSummaryCont">
        <DiagApiCallCont
          isLoading={bandwidthIsLoading}
          data={bandwidthInfo}
          sectionName="Internet Speed Information"
          status={bandwidthStatus}
          handleRefresh={() => refreshData()}
        >
          <ONTBandwidth
            selectedSA={selectedSA}
            bandwidthLoading={bandwidthIsLoading}
            bandwidthInfo={bandwidthInfo}
          />
        </DiagApiCallCont>
        <CMSpeedTestInfo macAddress={gwMAC} flag={true}/>
        <DiagApiCallCont
          isLoading={ontLoading}
          data={ontSummaryInfo}
          sectionName="ONT Summary"
          status={ontSummaryStatus}
          handleRefresh={() => refreshData()}
        >
          <ONTDetails deviceData={ontSummaryInfo} />
        </DiagApiCallCont>

        <DiagApiCallCont
          isLoading={oltSummaryIsLoading}
          data={oltSummaryInfo}
          sectionName="OLT Summary"
          status={oltSummaryStatus}
          handleRefresh={() => refreshData()}
        >
          <OLTDetails
            selectedSA={selectedSA}
            oltSummaryLoading={oltSummaryIsLoading}
            oltSummaryInfo={oltSummaryInfo}
          />
        </DiagApiCallCont>
      </div>
    </>
  );
};

export default ONTSummaryInfo;
