import { Divider } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import Toolbar from "@material-ui/core/Toolbar";
import { TransitionProps } from "@material-ui/core/transitions";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const CustomDialog = (props: any) => {
  const { open, handleClose, childComponent, heading } = props;
  return (
    <div>
      <Dialog
        className="fullScreenDialog"
        style={{ zIndex: 1250 }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className="fullDialogCont">
          <Toolbar className="fullDialogBar">
            <div className="logo-container">
              <span>{heading}</span>
            </div>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {childComponent}
        <Divider orientation="horizontal" />
        <div style={{ alignSelf: "flex-end", margin: 10 }}>
          <button className="applyFilersBtn" onClick={handleClose}>
            OK
          </button>
        </div>
      </Dialog>
    </div>
  );
};
export default CustomDialog;
