import CustomAccordion from "../reusable/CustomAccordion";
import CustomPopOver from "../reusable/CustomPopOver";

const ViewCwOFDMA = (props: any) => {
  const { codewords } = props;

  return (
    <>
      {codewords !== undefined ? (
        <CustomAccordion
          accTitle={"OFDMA Channels".toUpperCase()}
          accBodyElement={
            <>
              <table className="dataTableDetails">
                <thead>
                  <tr>
                    <th>{"channel Id".toUpperCase()}</th>
                    <CustomPopOver
                      position="top"
                      popOverData="Modulation Profile"
                      originComp={<th>{"Iuc".toUpperCase()}</th>}
                      index="popOver"
                    />
                    <CustomPopOver
                      position="top"
                      popOverData="Total uncorrectable codewords/total codewords prior to the current polling session"
                      originComp={<th>{"Baseline Errors".toUpperCase()}</th>}
                      index="popOver"
                    />
                    <CustomPopOver
                      position="top"
                      popOverData="Count of errored codewords that have been corrected during the interval"
                      originComp={<th>{"corrected delta".toUpperCase()}</th>}
                      index="popOver"
                    />
                    <CustomPopOver
                      position="top"
                      popOverData="Count of errored codewords that were not corrected during the interval"
                      originComp={<th>{"uncorrectables delta".toUpperCase()}</th>}
                      index="popOver"
                    />
                    <CustomPopOver
                      position="top"
                      popOverData="Count of all codewords received during the interval"
                      originComp={<th>{"total delta".toUpperCase()}</th>}
                      index="popOver"
                    />
                  </tr>
                </thead>
                <tbody>
                  {codewords?.map((ch: any, index: number) => {
                    return ch?.name === "OFDMA CHANNELS" && (
                      <tr key={index}>
                        <td rowSpan={ch?.length}>{ch?.channelid}</td>
                        <td>{ch?.iuc}</td>
                        <td>{ch?.baselineError}</td>
                        <td>{ch?.correcteds_delta}</td>
                        <td>{ch?.uncorrectable_delta}</td>
                        <td>{ch?.total_delta}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          }
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default ViewCwOFDMA;
