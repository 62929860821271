import { useMemo } from "react";
import {
  calculateCMUptime,
  getDeviceType,
} from "../../components/reusable/NodeUtil";
import { secToTime } from "../../components/reusable/Util";
import {
  GetNodeInfoDetails,
  GetTelemetryDetails,
} from "../../get-api/GetResponse";
import { GetTelemetryProps } from "../../topology/util/reduxFunctions/getTopologyState";
const MultiDeviceAcDataMapping = (nodeDevicesList: any) => {
  const ducksConfigData = GetTelemetryDetails().ducksConfigData;
  const viewType = GetTelemetryProps().viewType;
  const nodeInfo = GetNodeInfoDetails().currNodedata;
  const viewsd = ducksConfigData?.userPreferences?.node?.telemetry?.views;

  let tableColFields = viewsd?.find((view: any) => {
    return view?.attributes;
  });

  let tableData = useMemo(() => {
    const collectedData: any[] = [];

    nodeDevicesList?.forEach((e: any) => {
      if (e?.accountSummaryResponse?.data != null) {
        e?.accountSummaryResponse?.data?.forEach((data: any) => {
          let macAddress = data?.macAddress;
          let accountNumber = data?.accountNumber;
          let serviceAddress = data?.serviceAddress;
          let type = getDeviceType(data?.deviceType);

          let summary = data?.summary;
          const regStatus = data?.regStatus;
          let smtId = data?.smtId ? data?.smtId : summary?.serviceArea;

          const serialNum = summary?.serialNumber
            ? summary?.serialNumber
            : regStatus?.serialNumber;
          const status =
            regStatus != undefined
              ? regStatus?.cmOnlineStatus
                ? regStatus?.cmOnlineStatus
                : regStatus?.regStatus
                ? regStatus?.regStatus?.cmtsStatus
                : summary?.qamChannelStatus
                ? summary?.qamChannelStatus?.value
                : regStatus?.qamChannelStatus
                ? regStatus?.qamChannelStatus?.value
                : undefined
              : undefined;

          const model =
            summary != undefined
              ? summary?.model
              : data?.model
              ? data?.model
              : undefined;

          const cmtsRegTime = regStatus?.cmtsCmRegisteredTime;
          const cmtsCmRegisteredTime = secToTime(cmtsRegTime);
          const cmtsUptime = secToTime(cmtsRegTime);
          const ip =
            regStatus !== undefined && regStatus?.wanIP
              ? type?.toUpperCase() === "ECM"
                ? regStatus?.mgmtIPv6
                : regStatus?.wanIP
              : regStatus?.stbIP
              ? regStatus?.stbIP
              : summary !== undefined
              ? summary?.wanIP
                ? summary?.wanIP
                : summary?.stbIP
                ? summary?.stbIP
                : regStatus?.mgmtIPv6
              : undefined;
          let cmUptime = regStatus?.uptime;
          let regStatusHeader = regStatus?.regStatus?.header;
          const uptime =
            cmUptime > 0
              ? calculateCMUptime(status, regStatusHeader, cmUptime)
              : undefined;

          collectedData.push({
            macAddress,
            accountNumber,
            serviceAddress,
            serialNum,
            type,
            smtId,
            model,
            summary,
            regStatus,
            ip,
            uptime,
            cmtsCmRegisteredTime: cmtsCmRegisteredTime,
            cmtsUptime: cmtsUptime,
            status,
          });
        });
      } else if (e?.accountSummaryResponse?.data == null) {
        const errorData = e?.accountSummaryResponse?.error;
        collectedData.push({
          macAddress: null,
          accountNumber: null,
          serviceAddress: null,
          serialNum: null,
          type: null,
          smtId: null,
          model: null,
          summary: null,
          regStatus: null,
          ip: null,
          uptime: null,
          cmtsCmRegisteredTime: null,
          cmtsUptime: null,
          status: null,
          errorData,
        });
      }
    });

    return collectedData;
  }, [nodeDevicesList, tableColFields]);

  return {
    tableData,
  };
};

export default MultiDeviceAcDataMapping;
