import { IHPInsideWiring } from "../../../../models/account/diagnostics/ihp/IHPInsideWiring";

export const IHP_INSIDE_WIRING_BEGIN = "IHP_INSIDE_WIRING_BEGIN";
export const IHP_INSIDE_WIRING_SUCCESS = "IHP_INSIDE_WIRING_SUCCESS";
export const IHP_INSIDE_WIRING_FAILURE = "IHP_INSIDE_WIRING_FAILURE";

interface IhpInsideWiringBegin {
  type: typeof IHP_INSIDE_WIRING_BEGIN;
}

interface IhpInsideWiringSuccess {
  type: typeof IHP_INSIDE_WIRING_SUCCESS;
  payload: IHPInsideWiring;
}

interface IhpInsideWiringFailure {
  type: typeof IHP_INSIDE_WIRING_FAILURE;
  payload: Error;
}

export type IhpInsideWiringActionTypes =
  | IhpInsideWiringBegin
  | IhpInsideWiringSuccess
  | IhpInsideWiringFailure;
