import { ClearFlapsState } from "../../../states/node/telemetry/ClearFlapsState";
import {
  CLEAR_FLAPS_BEGIN,
  CLEAR_FLAPS_SUCCESS,
  CLEAR_FLAPS_FAILURE,
  ClearFlapsActionTypes,
} from "../../../types/node/telemetry/ClearFlaps"

const initialState: ClearFlapsState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export default function ClearFlapsReducer(
  state = initialState,
  action: ClearFlapsActionTypes
): ClearFlapsState {
  switch (action.type) {
    case CLEAR_FLAPS_BEGIN:
      return {
        isLoading: true,
      };
    case CLEAR_FLAPS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case CLEAR_FLAPS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}
