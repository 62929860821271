import {
  ActiveCertiDevicesActionTypes,
  RESET_ACTIVE_CERTI_FAILURE,
  SAVE_ACTIVE_CERTI_DEVICES,
  UPDATE_ACTIVE_CERTI_DEVICES,
  DECREMENT_API_CALL_COUNTER,
  INCREMENT_API_CALL_COUNTER,
} from "../../../types/account/certifications/ActiveCertiDevices";

export function SaveActiveCertiDevices(
  devices: any
): ActiveCertiDevicesActionTypes {
  return {
    type: SAVE_ACTIVE_CERTI_DEVICES,
    payload: devices,
  };
}

export function UpdateActiveCertiDevices(
  devicekey: string,
  subdataKey: string,
  data: any
): ActiveCertiDevicesActionTypes {
  return {
    type: UPDATE_ACTIVE_CERTI_DEVICES,
    devicekey: devicekey,
    subdataKey: subdataKey,
    data: data,
  };
}
export function IncrementApiCallCounter(
  deviceKey: string
): ActiveCertiDevicesActionTypes {
  return {
    type: INCREMENT_API_CALL_COUNTER,
    devicekey: deviceKey,
  };
}
export function DecrementApiCallCounter(
  deviceKey: string
): ActiveCertiDevicesActionTypes {
  return {
    type: DECREMENT_API_CALL_COUNTER,
    devicekey: deviceKey,
  };
}

export function ResetActiveCertiDevices(): ActiveCertiDevicesActionTypes {
  return {
    type: RESET_ACTIVE_CERTI_FAILURE,
  };
}
