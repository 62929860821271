import { IoIosArrowDropup, IoIosArrowDropdown } from "react-icons/io";
import AppLabels from "../../../constants/App_Labels";
import CmViewData from "../../../data/cablemodem/CmViewData";
import DataCard from "../../../topology/components/reusable/DataCard";
import CertiExceptionCont from "../../certification/CertiExceptionCont";
import {
    convertCMData,
    getChCWValue,
    getMinMaxRange,
    getThrColoredTableCell,
    getValueByStrMapping,
    isNull,
} from "../../reusable/Util";
import CmUsChart from "../CmUsChart";
import { useState } from "react";
import CmSpecSummary from "../CmSpecSummary";
import CmSpecChart from "../CmSpecChart";

interface Props {
    cmData: any;
    flag?: any;
    preCmData?: any;
}

const CmUSPrechTab = ({ cmData, flag, preCmData }: Props) => {
    const labels = AppLabels();
    const [summaryAcc, setSummaryAcc] = useState(false);
    const [chartAcc, setChartAcc] = useState(false);
    const [scqamAcc, setScqamAcc] = useState(false);
    const [ofdmAcc, setOfdmAcc] = useState(false);

    const handleAccToggle = (accName: string) => {
        switch (accName) {
            case "summary":
                setSummaryAcc(!summaryAcc);
                break;
            case "chart":
                setChartAcc(!chartAcc);
                break;
            case "scqam":
                setScqamAcc(!scqamAcc);
                break;
            case "ofdm":
                setOfdmAcc(!ofdmAcc);
                break;
        }
    };
    let cmDataConfig = CmViewData();
    let usSummaryConfig = cmDataConfig?.upStream?.summary;
    let usScQAMConfig = cmDataConfig?.upStream?.scQam;
    let usScQAMData =
        flag === true
            ? cmData?.docsis30?.upstream
            : cmData?.docsis?.docsis30?.upstream;
    let ofdmaConfig = cmDataConfig?.upStream?.ofdma;
    let ofdmaData =
        flag === true
            ? cmData?.ofdm?.upstream?.channels
            : cmData?.docsis?.ofdm?.upstream?.channels;
    let usChartRanges = getMinMaxRange(
        flag === true ? cmData : cmData?.docsis,
        "us"
    );

    return (
        <div>
            {preCmData && (
                <CertiExceptionCont
                    dataObj={flag === true ? cmData : cmData?.docsis}
                    sectionName={labels.UPSTREAM}
                >
                    <div
                        className="dsChDataAcc"
                        onClick={() => handleAccToggle("summary")}
                    >
                        <p>{labels.SUMMARY.toUpperCase()}</p>
                        {summaryAcc ? (
                            <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
                        ) : (
                            <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
                        )}
                    </div>
                    {summaryAcc ? (
                        <DataCard
                            cardHeader={""}
                            cardBody={
                                <div>
                                    <CmSpecSummary
                                        cmConfig={usSummaryConfig?.individual}
                                        cmData={cmData}
                                        preCmData={preCmData}
                                        usFlag={true}
                                    />
                                    {/* display precheck certificate values  */}
                                    <CmSpecSummary
                                        cmConfig={usSummaryConfig?.common}
                                        cmData={preCmData}
                                        usFlag={true}
                                        isPrecheck={true}
                                    />
                                    {/* display postcheck certificate values  */}
                                    <CmSpecSummary
                                        cmConfig={usSummaryConfig?.common}
                                        cmData={cmData}
                                        usFlag={true}
                                    />
                                </div>
                            }
                        />
                    ) : (
                        <></>
                    )}
                </CertiExceptionCont>
            )}

            <div className="dsChDataAcc" onClick={() => handleAccToggle("chart")}>
                <p>
                    {preCmData
                        ? labels.PRECHECK_CHART.toUpperCase()
                        : labels.chart.toUpperCase()}
                </p>
                {chartAcc ? (
                    <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
                ) : (
                    <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
                )}
            </div>
            {chartAcc ? (
                <CmUsChart
                    cardLabel={""}
                    chartRanges={usChartRanges}
                    scQamChs={usScQAMData?.channels}
                    ofdmaChs={ofdmaData}
                />
            ) : (
                <></>
            )}
            {usScQAMData && flag === false && (
                <>
                    <div className="dsChDataAcc" onClick={() => handleAccToggle("scqam")}>
                        <p>
                            {preCmData
                                ? labels.PRECHECK_SCQAM.toUpperCase()
                                : labels.SCQAM.toUpperCase()}
                        </p>
                        {scqamAcc ? (
                            <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
                        ) : (
                            <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
                        )}
                    </div>
                    {scqamAcc ? (
                        <CmSpecChart
                            cmConfig={cmDataConfig}
                            cmData={cmData}
                            scQamFlag={true}
                            usFlag={true}
                        />
                    ) : (
                        <></>
                    )}
                </>
            )}
            {ofdmaData && flag === false && (
                <>
                    <div className="dsChDataAcc" onClick={() => handleAccToggle("ofdm")}>
                        <p>
                            {preCmData ? labels.PRECHECK_OFDM : labels.OFDM.toUpperCase()}
                        </p>
                        {ofdmAcc ? (
                            <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
                        ) : (
                            <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
                        )}
                    </div>
                    {ofdmAcc ? (
                        <CmSpecChart
                            cmConfig={cmDataConfig}
                            cmData={cmData}
                            usFlag={true}
                        />
                    ) : (
                        <></>
                    )}
                </>
            )}
        </div>
    );
};

export default CmUSPrechTab;
