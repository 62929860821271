import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DiagContainer from "../../components/reusable/DiagContainer";
import { getNodeTeleGloabalStatus } from "../../components/reusable/NodeUtil";
import ColumnDropDown from "../../components/telemetry/ColumnDropDown";
import MultiAddressTable from "../../components/telemetry/MultiAddressTable";
import AppLabels from "../../constants/App_Labels";
import { dataSetType } from "../../constants/TelePrefConfig";
import {
  updateConfigDataCheckbox,
  updateConfigDataReposition,
} from "../../store/ducksPattern/userPrefConfig";

import "../../design-tokens/telemetry/NodeTelemetryTab.scss";

import CustomPopUp from "../../components/reusable/CustomPopUp";
import TelemetryActionMenu from "../../components/telemetry/TelemetryActionMenu";
import {
  GetAddressInfoDetails,
  GetNodeInfoDetails,
  GetTelemetryAverages,
  GetTelemetryDetails,
} from "../../get-api/GetResponse";
import { SetNodeDevicesError } from "../../store/actions/node/telemetry/NodeDevicesInfoActions";

import ConfigConst from "../../constants/ConfigConst";
import {
  setAddressViewType,
  stopDiagExecution,
} from "../../topology/store/slices/mapItems/diagnostics/addressTelemetry";
import storeConst from "../../topology/store/storeConst";

import { MdFullscreen } from "react-icons/md";

export function MultiDeviceAddressTeleTab(props: any) {
  const {
    data,
    selectdSmt,
    fullScreen,
    handleFullScreen,
    streetName,
    streetNo,
  } = props;
  const dispatch = useDispatch();
  const labels = AppLabels();
  const address = ConfigConst.address.toLowerCase();

  const [dataSetName, setDataSetName] = useState("");
  const [viewTypeOptions, setViewTypeOptions] = useState<any>([]);
  const [viewType, setTeleViewType] = useState("");
  const [tableAttr, setTableAttr] = useState<any>();

  const [selectAttrVal, setSelectAttrVal] = useState<any>();
  const [tableData, setTableData] = useState();
  const [dragOver, setDragOver] = useState("");

  const [popUpFlag, setPopUpFlag] = useState(false);
  const [popUpHeader, setPopUpHeader] = useState("");
  const [popUpBody, setPopUpBody] = useState(<div></div>);
  const [averagesPopUp, setAveragesPopUp] = useState(false);
  const [avgPopUpBody, setAvgPopUpBody] = useState(<div></div>);
  const [nodeAvgData, setNodeAvgData] = useState({});

  const { ducksConfigData } = GetTelemetryDetails();
  const { currNodedata } = GetNodeInfoDetails();

  const {
    allAddressDiagIsLoading,
    allDiagAddressStatus,
    isAddressAborted,
    mlAddressData,
    viewAddressType,
    diagAddressStartTime,
    diagAddressEndTime,
    mlAddressIsLoading,
    mlAddressError,
    allDiagAddressError,
    diagAddressType,
    modemAddressType,
  } = GetAddressInfoDetails();
  const nodeTeleStatus = getNodeTeleGloabalStatus(
    allDiagAddressStatus,
    allAddressDiagIsLoading,
    isAddressAborted
  );

  const { nodeAveragesStatusData, nodeAveragesStatusLoading } =
    GetTelemetryAverages();

  const [teleData, setTeleData] = useState(mlAddressData);
  //   const isFullDiagAvail = isFullDiagPresent(teleData);

  const handleDragStart = (event: any) => {
    const { id } = event.target;
    const idx = tableAttr.findIndex((obj: any) => obj.jsonDataMapping === id);
    event.dataTransfer.setData("colIdx", idx);
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };
  const handleDragEnter = (event: any) => {
    const { id } = event.target;
    const identifier =
      id === "null" || id === null || id === "" ? dragOver : id;
    setDragOver((state) => {
      state = identifier;
      return state;
    });
  };

  const handleOnDrop = (event: any) => {
    var viewVal = "";
    if (viewType?.toUpperCase() === labels.ALL.toUpperCase()) {
      viewVal = labels.ALL.toUpperCase();
    } else if (viewType?.toUpperCase() === labels.ALL_NO_CW.toUpperCase()) {
      viewVal = labels.ALL_NO_CW.toUpperCase();
    } else {
      viewVal = viewType?.toUpperCase();
    }

    const { id } = event.target;
    const identifier =
      id === "null" || id === null || id === "" ? dragOver : id;
    const droppedColIdx = tableAttr.findIndex(
      (obj: any) => obj.jsonDataMapping == identifier
    );
    const draggedColIdx = event.dataTransfer.getData("colIdx");
    const tempCols = [...tableAttr];
    const tempCols1 = [...tableAttr];
    if (droppedColIdx !== -1 && draggedColIdx !== -1) {
      if (droppedColIdx < draggedColIdx) {
        tempCols[droppedColIdx] = tempCols1[draggedColIdx];
        tempCols[droppedColIdx + 1] = tempCols1[droppedColIdx];

        tempCols1.splice(draggedColIdx, 1);
        for (let i = droppedColIdx + 2; i <= tempCols1.length; i++) {
          tempCols[i] = tempCols1[i - 1];
        }
      } else if (draggedColIdx < droppedColIdx) {
        tempCols[droppedColIdx] = tempCols1[draggedColIdx];
        tempCols[droppedColIdx - 1] = tempCols1[droppedColIdx];
        tempCols1.splice(draggedColIdx, 1);
        for (let i = droppedColIdx - 2; i >= 0; i--) {
          tempCols[i] = tempCols1[i];
        }
      }
      dispatch(updateConfigDataReposition({ viewVal, tempCols }));
    }
    setDragOver((state) => {
      state = "";
      return state;
    });
  };

  const stopTelemetry = () => {
    dispatch(stopDiagExecution());
  };

  const handleNodeModemListRefresh = () => {
    const activeId = currNodedata?.smt
      ? currNodedata?.smtId
      : currNodedata?.nodeId;
    const type = currNodedata?.smt ? "smtid" : "shubid";
    // dispatch(
    //   getAddressModemsList({
    //     streetNo: streetNo ? streetNo : "",
    //     streetName: streetName ? streetName : "",
    //     modemType: "cm,emta,ecmstb,oldstb",
    //   })
    // );
  };

  const handleCheckboxClick = (option: string) => {
    var viewVal = "";
    if (viewType?.toUpperCase() === labels.ALL.toUpperCase()) {
      viewVal = labels.ALL.toUpperCase();
    } else if (viewType?.toUpperCase() === labels.ALL_NO_CW.toUpperCase()) {
      viewVal = labels.ALL_NO_CW.toUpperCase();
    } else {
      viewVal = viewType?.toUpperCase();
    }
    dispatch(updateConfigDataCheckbox({ viewVal, option }));
  };

  useEffect(() => {
    if (mlAddressData === undefined) {
      const activeId = currNodedata?.smt
        ? currNodedata?.smtId
        : currNodedata?.nodeId;
      const type = currNodedata?.smt ? "smtid" : "shubid";
      //   if (modemAddressType?.trim()?.toLowerCase() === "emta") {
      //     dispatch(
      //       getAddressModemsList({
      //         streetNo: streetNo ? streetNo : "",
      //         streetName: streetName ? streetName : "",
      //         modemType: "cm,emta,ecmstb,oldstb",
      //       })
      //     );
      //   } else {
      //     dispatch(
      //       getAddressModemsList({
      //         streetNo: streetNo ? streetNo : "",
      //         streetName: streetName ? streetName : "",
      //         modemType: "cm,emta,ecmstb,oldstb",
      //       })
      //     );
      //   }
    }
  }, [modemAddressType]);

  useEffect(() => {
    if (dataSetName !== undefined && dataSetName !== "") {
      dataSetType?.map((v: any) => {
        if (dataSetName === v?.dataSetName) {
          setViewTypeOptions(v?.subDataSetViews);
          dispatch(setAddressViewType(v?.subDataSetViews?.[0]?.columnKey));
          setTeleViewType(v?.subDataSetViews?.[0]?.columnKey);
        }
      });
    }
  }, [dataSetName]);

  useEffect(() => {
    if (viewType !== undefined && viewType !== "") {
      ducksConfigData?.userPreferences?.node?.telemetry?.views?.map(
        (v: any) => {
          if (v?.viewTypeKey === viewType) {
            setTableAttr(v?.attributes);
            setSelectAttrVal(v?.attributes);
          }
        }
      );
    }
  }, [viewType, ducksConfigData]);

  useEffect(() => {
    if (!isAddressAborted && allDiagAddressError) {
      setPopUpFlag(true);
      setPopUpHeader(labels.ERROR.toUpperCase());
      setPopUpBody(
        <span className="telemertyinfoBoxSpan">{labels.NO_DATA}</span>
      );

      stopTelemetry();
    }
  }, [allDiagAddressError]);

  const filterBySMT = (filterKey: string) => {
    // if (filterKey?.length > 0 && filterKey !== labels.ALL?.toUpperCase()) {
    //   return mlAddressData
    //     ?.filter((d: any) => isNull(d))
    //     .filter(
    //       (d: any) =>
    //         isNull(d.serviceArea) &&
    //         d.serviceArea?.toLowerCase()?.includes(filterKey)
    //     );
    // }
    return mlAddressData;
  };

  useEffect(() => {
    let filterData;
    if (currNodedata?.smt) {
      filterData = filterBySMT(selectdSmt);
    } else {
      filterData = filterBySMT(data);
    }

    setTeleData(filterData);
  }, [data, selectdSmt, mlAddressData]);

  //Setting Dataset dropdown with proper value when changed in topology
  useEffect(() => {
    let diagTypeName = "";
    if (
      diagAddressType === null ||
      diagAddressType?.toLowerCase()?.trim() === storeConst.all
    ) {
      diagTypeName = ConfigConst.FULL;
    } else if (
      diagAddressType?.toLowerCase()?.trim() === storeConst.all_no_cw
    ) {
      diagTypeName = ConfigConst.FULL_NO_CW;
    } else {
      diagTypeName = ConfigConst.FULL_NO_CW;
    }
    setDataSetName(diagTypeName);
  }, [diagAddressType, dataSetName]);

  return (
    <div>
      <div className="telemetryActionCont">
        <div className="nodeFilterCont">
          <div className="nodeFilters nodeFilterContainer">
            {/* {viewTypeOptions?.length !== 0 && (
              <CustomDropDown
                disabled={dataSetName === labels.BASIC?.toUpperCase()}
                ddTitle={labels.VIEW_TYPE?.toUpperCase()}
                selectedOption={viewType}
                options={viewTypeOptions}
                searchFlag={false}
                checkboxFlag={false}
                setSelectedOption={(value: any) => {
                  setTeleViewType(value);
                  //dispatch(SetTeleViewType(value));
                  dispatch(setViewType(value));
                }}
              />
            )} */}
            {viewTypeOptions?.length !== 0 && (
              <ColumnDropDown
                disabled={
                  dataSetName?.toUpperCase() === labels.BASIC?.toUpperCase()
                }
                ddTitle={labels.VIEW_TYPE?.toUpperCase()}
                selectedOption={
                  viewTypeOptions?.filter(
                    (obj: any) =>
                      obj?.columnKey?.toUpperCase() === viewType?.toUpperCase()
                  )?.[0]?.columnName
                }
                options={viewTypeOptions}
                searchFlag={false}
                checkboxFlag={false}
                setSelectedOption={(value: any) => {
                  setTeleViewType(value);
                  dispatch(setAddressViewType(value));
                }}
              />
            )}

            {viewType !== labels.CUSTOM?.toUpperCase() && (
              <ColumnDropDown
                ddTitle={labels.fields?.toUpperCase()}
                selectedOption=""
                options={
                  currNodedata?.smt
                    ? tableAttr?.filter(
                        (e: any) =>
                          e?.columnKey !== address && e?.columnKey !== "smt"
                      )
                    : tableAttr?.filter((e: any) => e?.columnKey !== address)
                }
                // options={filterListAttr}
                searchFlag={true}
                checkboxFlag={true}
                setSelectedOption={(value: any) => {}}
                selectAttrVal={selectAttrVal}
                handleCheckBox={handleCheckboxClick}
                handleDragStart={handleDragStart}
                handleDragOver={handleDragOver}
                handleOnDrop={handleOnDrop}
                handleDragEnter={handleDragEnter}
                dragOver={dragOver}
              />
            )}
          </div>

          <div className="designMaintainer" />

          {!fullScreen && (
            <button
              disabled={mlAddressData?.length > 0 ? false : true}
              className="fullScreenBtn"
              onClick={() => handleFullScreen()}
            >
              <MdFullscreen />
            </button>
          )}
          {mlAddressData !== undefined && mlAddressData?.length !== 0 && (
            <TelemetryActionMenu
              menuType="vertical"
              viewDropdown={viewType === "full" ? "All" : viewType}
              columnData={tableAttr}
              rowData={tableData}
              nodeDevicesList={teleData}
              nodeId={currNodedata?.nodeId ? currNodedata?.nodeId : ""}
              deviceType={
                modemAddressType ? modemAddressType?.toUpperCase() : "CM"
              }
              isNeighbourhood={true}
              isMultiDevice={false}
            />
          )}
        </div>
      </div>
      <div>
        <DiagContainer
          data={mlAddressData}
          isLoading={mlAddressIsLoading}
          error={mlAddressError}
          sectionName={labels.NODE_TELEMETRY_INFO?.toUpperCase()}
          handleRefresh={handleNodeModemListRefresh}
        >
          <MultiAddressTable
            tableAttributes={tableAttr}
            nodeDevicesList={teleData}
            onDataChange={setTableData}
            handleDragStart={handleDragStart}
            handleDragOver={handleDragOver}
            handleOnDrop={handleOnDrop}
            handleDragEnter={handleDragEnter}
            dragOver={dragOver}
          />
        </DiagContainer>
        <CustomPopUp
          showVal={popUpFlag}
          headerText={popUpHeader}
          bodyElement={popUpBody}
          handleClose={() => {
            setPopUpFlag(false);
            dispatch(SetNodeDevicesError(false));
          }}
        />
        <CustomPopUp
          showVal={averagesPopUp}
          headerText={labels.NODE_AVERAGES}
          bodyElement={avgPopUpBody}
          handleClose={() => {
            setAveragesPopUp(false);
          }}
        />
      </div>
    </div>
  );
}
