export const FORMS_HISTORY_BEGIN = 'FORMS_HISTORY_BEGIN';
export const FORMS_HISTORY_SUCCESS = 'FORMS_HISTORY_SUCCESS';
export const FORMS_HISTORY_RESET = 'FORMS_HISTORY_RESET';
export const FORMS_HISTORY_FAILURE = 'FORMS_HISTORY_FAILURE';

interface formsHistoryBegin {
  type: typeof FORMS_HISTORY_BEGIN;
}

interface formsHistorySuccess {
  type: typeof FORMS_HISTORY_SUCCESS;
  payload: any;
}

interface formsHistoryReset {
  type: typeof FORMS_HISTORY_RESET;
  payload: any;
}

interface formsHistoryFailure {
  type: typeof FORMS_HISTORY_FAILURE;
  payload: Error;
}

export type FormsHistoryActionTypes =
  | formsHistoryBegin
  | formsHistorySuccess
  | formsHistoryReset
  | formsHistoryFailure;
