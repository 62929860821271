import {
    MONTHLY_FEEDBACK_BEGIN,
    MONTHLY_FEEDBACK_FAILURE,
    MONTHLY_FEEDBACK_SUCCESS,
    MonthlyFeedbackActionTypes
  } from "../../types/feedback/MonthlyFeedbackTypes"

  import {
    GET_MONTHLY_FEEDBACK_BEGIN,
    GET_MONTHLY_FEEDBACK_FAILURE,
    GET_MONTHLY_FEEDBACK_SUCCESS,
    GetMonthlyFeedbackActionTypes
  } from "../../types/feedback/MonthlyFeedbackTypes"
  
export function MonthlyFeedbackBegin(): MonthlyFeedbackActionTypes {
    return {
      type: MONTHLY_FEEDBACK_BEGIN,
    };
  }
  
  export function MonthlyFeedbackSuccess(
    res: any
  ): MonthlyFeedbackActionTypes {
    return {
      type: MONTHLY_FEEDBACK_SUCCESS,
      payload: res,
    };
  }
  
  export function MonthlyFeedbackFailure(
    error: Error
  ): MonthlyFeedbackActionTypes {
    return {
      type: MONTHLY_FEEDBACK_FAILURE,
      payload: error,
    };
  }

export function GetMonthlyFeedbackBegin(): GetMonthlyFeedbackActionTypes {
    return {
      type: GET_MONTHLY_FEEDBACK_BEGIN,
    };
  }
  
  export function GetMonthlyFeedbackSuccess(
    res: any
  ): GetMonthlyFeedbackActionTypes {
    return {
      type: GET_MONTHLY_FEEDBACK_SUCCESS,
      payload: res,
    };
  }
  
  export function GetMonthlyFeedbackFailure(
    error: Error
  ): GetMonthlyFeedbackActionTypes {
    return {
      type: GET_MONTHLY_FEEDBACK_FAILURE,
      payload: error,
    };
  }