import { BiLinkExternal } from "react-icons/bi";
import CustomAccordion from "../../../../../../../components/reusable/CustomAccordion";
import TopologyLabels from "../../../../../../constants/topologyLabels";
import appConst from "../../../../../../constants/appConst";

interface Props {
  siteComponents: any;
}

const MduNodeSiteComponents = ({ siteComponents }: Props) => {
  const labels = TopologyLabels();

  const openReturnSegment = (rsName: string) => {
    if (rsName) {
      let nodeID = getNodeID(rsName);
      if (nodeID && nodeID !== "") window.open(`/#/node?nodeId=` + nodeID);
    }
  };
  const getNodeID = (rSName: string) => {
    let nodeID = "";
    let nodeNums = rSName?.split("_");
    if (nodeNums?.length > 1) nodeID = nodeNums?.[1];
    else nodeID = nodeNums?.[0];
    return nodeID;
  };

  return (
    <div className="activeMapEleCont">
      {siteComponents && siteComponents?.length > 0 && (
        <CustomAccordion
          accTitle={labels?.siteComponents?.toUpperCase()}
          accBodyElement={
            <div className="siteComponentsTable">
              <table className="dataTableDetails">
                <thead>
                  <tr>
                    <td>{labels?.networkID.toUpperCase()}</td>
                    <td>{labels?.name.toUpperCase()}</td>
                    <td>{labels?.category.toUpperCase()}</td>
                    <td>{labels?.model.toUpperCase()}</td>
                    <td>{labels?.type.toUpperCase()}</td>
                  </tr>
                </thead>
                <tbody>
                  {siteComponents?.map((sc: any) => {
                    const nodeID = getNodeID(sc?.name);
                    const isReturnSeg =
                      !isNaN(parseInt(nodeID)) && nodeID?.length === 6;
                    return (
                      <tr key={sc?.networkId}>
                        <td>{sc?.networkId}</td>
                        <td>
                          {sc?.name}
                          {sc?.type === appConst.returnSegment &&
                            isReturnSeg && (
                              <BiLinkExternal
                                color="var(--link)"
                                size={15}
                                onClick={() => openReturnSegment(sc?.name)}
                              />
                            )}
                        </td>
                        <td>{sc?.category}</td>
                        <td>{sc?.model}</td>
                        <td>{sc?.type}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          }
        />
      )}
    </div>
  );
};

export default MduNodeSiteComponents;
