import TopologyLabels from "../../../../constants/topologyLabels";
import Table, { TCTypes, TDTypes } from "../../../reusable/table/Table";

interface Props {
  data: any;
  designation: string;
}
export default function GponSpliceMapTable({ data, designation }: Props) {
  const labels = TopologyLabels();
  const tableData = data?.payload?.map((d: any, i: number) => {
    return {
      id: i,
      trayNumber: d?.trayNumber,
      leftCableName: d?.leftCableName,
      leftStrandNumber: d?.leftStrandNumber,
      leftTubeStrandColor: getTubeStrandColor({ d }),
      noSlots: d?.noSlots,
      slotState: d?.slotState,
      rightTubeStrandColor: getTubeStrandColor({ position: "right", d }),
      rightStrandNumber: d?.rightStrandNumber,
      rightCableName: d?.rightCableName,
      usage: d?.usage,
    } as TDTypes;
  });

  const tableColumns = [
    { th: labels.TrayNo, tdName: "trayNumber" },
    { th: labels.LeftCableName, tdName: "leftCableName" },
    { th: labels.LeftNo, tdName: "leftStrandNumber" },
    { th: labels.LeftTubeStrandColor, tdName: "leftTubeStrandColor" },
    { th: labels.Slot, tdName: "noSlots" },
    { th: labels.State, tdName: "slotState" },
    { th: labels.RightTubeStrandColor, tdName: "rightTubeStrandColor" },
    { th: labels.RightNo, tdName: "rightStrandNumber" },
    { th: labels.RightCableName, tdName: "rightCableName" },
    { th: labels.Usage, tdName: "usage" },
  ] as TCTypes[];

  return (
    <>
      {tableData?.length > 0 && (
        <Table
          title={`${labels.SpliceMap}: ${designation}`}
          data={tableData}
          columns={tableColumns}
          newTabTitle={`${labels.SpliceMap}: ${designation}`}
        />
      )}
    </>
  );
}

interface ColorProps {
  position?: "right" | "left";
  d: any;
}
const getTubeStrandColor = ({ position = "left", d }: ColorProps) => {
  const strandColour =
    position === "left" ? d?.leftStrandColour : d?.rightStrandColour;
  const strandColorRbg =
    position === "left" ? d?.leftStrandColourRbg : d?.rightStrandColourRbg;
  const tubeColour =
    position === "left" ? d?.leftTubeColour : d?.rightTubeColour;
  const tubeColorRbg =
    position === "left" ? d?.leftTubeColourRbg : d?.rightTubeColourRbg;

  const isAvail = () => {
    if (position === "left") {
      if (d?.leftStrandColour || d?.leftTubeColour) return true;
    } else if (d?.rightStrandColour || d?.rightTubeColour) return true;

    return false;
  };

  const getBgColor = (color: string) => {
    if (color === "white" || color === "yellow" || color === "aqua")
      return `var(--pureBlack)`;
    return `var(--white)`;
  };

  if (isAvail()) {
    return (
      <span>
        <span
          className="gponSpliceMapTableColorCell"
          style={{
            backgroundColor: "#" + tubeColorRbg,
            color: getBgColor(tubeColour),
          }}
        >
          {tubeColour}
        </span>
        <span> / </span>
        <span
          className="gponSpliceMapTableColorCell"
          style={{
            backgroundColor: "#" + strandColorRbg,
            color: getBgColor(strandColour),
          }}
        >
          {strandColour}
        </span>
      </span>
    );
  } else return null;
};
