import { createSlice } from "@reduxjs/toolkit";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "battery",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    // action => action handler
    GetBatteryBegin: (battery) => {
        battery.isLoading = true;
    },
    GetBatterySuccess: (battery, action) => {
        battery.isLoading = false;
        battery.data= action.payload.map((item:any) => item.data)
        battery.status = storeConst.OK;
    },
    GetBatteryFailure: (battery, action) => {
        battery.isLoading = false;
        battery.error = action.payload;
        battery.data = undefined;
        battery.status = storeConst.ERROR;
    },

 
  },
});

export const {
    GetBatteryBegin,
    GetBatterySuccess,
    GetBatteryFailure,
} = slice.actions;

export default slice.reducer;
