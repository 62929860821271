import { IndividualIssueModel } from "../../models/feedback/IndividualIssue";
import {
  ISSUE_BEGIN,
  ISSUE_SUCCESS,
  ISSUE_FAILURE,
  IssueActionTypes,
} from "../../types/feedback/IndividualIssue"

export function issueBegin():IssueActionTypes
{
  return {
    type: ISSUE_BEGIN,
  };
}

export function issueSuccess(
  individualIssue: IndividualIssueModel
): IssueActionTypes
{
  return {
    type: ISSUE_SUCCESS,
    payload: individualIssue,
  };
}

export function issueFailure(
  error: Error
):  IssueActionTypes
{
  return {
    type: ISSUE_FAILURE,
    payload: error,
  };
}
