import { Divider } from "@material-ui/core";
import AppLabels from "../../constants/App_Labels";
import { getHomeAddress, secToTime } from "../reusable/Util";
interface Props {
  cmData: any;
  nodeData?: any;
}

const CMSummaryInfoSm = ({ cmData, nodeData }: Props) => {
  const labels = AppLabels();
  const summaryData = cmData?.deviceDiag?.data?.summary;

  return (
    <div className="cmSMInfoCont">
      <section className="cmBasicDetails">
        <p className="cmMacID">
          <span>{labels.Mac_Id.toUpperCase()}:</span>
          <span>{cmData?.macAddress}</span>
        </p>
        <p>
          <span>{labels.NODE_ID.toUpperCase()}:</span>
          <span>{nodeData?.nodeId}</span>
        </p>
        <p>
          <section>
            <span>{labels.PHUB.toUpperCase()}:</span>
            <span>{cmData?.phub}</span>
          </section>
          <span className="divideSpan">|</span>
          <section>
            <span>{labels.SMT.toUpperCase()}:</span>
            <span>{cmData?.serviceArea}</span>
          </section>
        </p>
      </section>
      <Divider />
      <section className="cmBasicDetails">
        <p>
          <span>{labels.CMTS_NAME.toUpperCase()}:</span>
          <span> {summaryData?.cmtsName}</span>
        </p>
        <p>
          <span>{labels.MAC_DOMAIN.toUpperCase()}:</span>
          <span>{nodeData?.macDomain}</span>
        </p>
        <p>
          <span>{labels.Address.toUpperCase()}:</span>
          <span>{getHomeAddress(cmData?.serviceAddress)}</span>
        </p>
      </section>
      <Divider />
      <section className="cmBasicDetails">
        <p>
          <span>{labels.regStatus.toUpperCase()}:</span>
          <span>{summaryData?.regStatus?.cmtsStatus}</span>
        </p>
        <p>
          <span>{labels.Uptime.toUpperCase()}:</span>
          <span>{secToTime(summaryData?.uptime)}</span>
        </p>
        <p>
          <span>{labels.Registered_Time.toUpperCase()}:</span>
          <span>{secToTime(summaryData?.cmtsCmRegisteredTime)}</span>
        </p>
      </section>
    </div>
  );
};

export default CMSummaryInfoSm;
