import { FlapHistoryState } from "../../../states/node/telemetry/FlapHistoryState";
import {
  FLAP_HISTORY_BEGIN,
  FLAP_HISTORY_SUCCESS,
  FLAP_HISTORY_FAILURE,
  FlapHistoryActionTypes,
} from "../../../types/node/telemetry/FlapHistory";

const initialState: FlapHistoryState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

const FlapHistoryReducer = (
  state = initialState,
  action: FlapHistoryActionTypes
): FlapHistoryState => {
  switch (action.type) {
    case FLAP_HISTORY_BEGIN:
      return {
        isLoading: true,
      };
    case FLAP_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case FLAP_HISTORY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
};
export default FlapHistoryReducer;
