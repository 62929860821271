import { MonthlyFeedbackState } from "../../states/feedback/MonthlyFeedbackState"
import {
  MONTHLY_FEEDBACK_BEGIN,
  MONTHLY_FEEDBACK_SUCCESS,
  MONTHLY_FEEDBACK_FAILURE,
  MonthlyFeedbackActionTypes
} from "../../types/feedback/MonthlyFeedbackTypes"
import { GetMonthlyFeedbackState } from "../../states/feedback/MonthlyFeedbackState";
import {
  GET_MONTHLY_FEEDBACK_BEGIN,
  GET_MONTHLY_FEEDBACK_SUCCESS,
  GET_MONTHLY_FEEDBACK_FAILURE,
  GetMonthlyFeedbackActionTypes
} from "../../types/feedback/MonthlyFeedbackTypes"

const initialGetState: GetMonthlyFeedbackState = {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  };
const initialState: MonthlyFeedbackState = {
  isLoading: false,
  error: undefined,
  status: "",
};

export function MonthlyFeedbackReducer(
  state = initialState,
  action: MonthlyFeedbackActionTypes
): MonthlyFeedbackState  {
  switch (action.type) {
    case MONTHLY_FEEDBACK_BEGIN:
      return {
        isLoading: true,
      };
    case MONTHLY_FEEDBACK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: "OK",
      };
    case MONTHLY_FEEDBACK_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        status: "ERROR",
      };

    default:
      return state;
  }
}

export function GetMonthlyFeedbackReducer(
  state = initialGetState,
  action: GetMonthlyFeedbackActionTypes
): GetMonthlyFeedbackState  {
  switch (action.type) {
    case GET_MONTHLY_FEEDBACK_BEGIN:
      return {
        isLoading: true,
      };
    case GET_MONTHLY_FEEDBACK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case GET_MONTHLY_FEEDBACK_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}