import { Card } from "react-bootstrap";
import { FiRefreshCw } from "react-icons/fi";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ONTClient from "../../network/httpclient/ont/ONTClient";
import DiagContainer from "../reusable/DiagContainer";
import ExceptionBox from "../reusable/ExceptionBox";
import { isNull } from "../reusable/Util";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const ONTIPSession = (props: any) => {
  const { selectedDevice } = props;
  const dispatch = useDispatch();
  const { ontIPLoading, ontIPInfo, ontIPError } = useSelector(
    (state: any) => ({
      ontIPLoading: state.AccountState.ONTIPInfo.isLoading,
      ontIPInfo: state.AccountState.ONTIPInfo.data,
      ontIPError: state.AccountState.ONTIPInfo.error,
    }),
    shallowEqual
  );
  const refreshData = () => {
    dispatch(ONTClient.getONTIpSessions(selectedDevice.serialNumber));
  };

  return (
    <>
      <div className="refreshCont">
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip-2">Refresh</Tooltip>}
        >
          <button className="refreshBtn" onClick={() => refreshData()}>
            <FiRefreshCw />
          </button>
        </OverlayTrigger>
      </div>
      <DiagContainer
        isLoading={ontIPLoading}
        data={ontIPInfo}
        error={ontIPError}
        handleRefresh={() => refreshData()}
      >
        {ontIPInfo !== undefined ? (
          <div className="gwDevicesCont">
            <Card className="dataCard">
              <Card.Body>
                <table className="dataTableDetails">
                  {ontIPInfo !== undefined && ontIPInfo?.length !== 0 ? (
                    <>
                      <tbody>
                        {ontIPInfo?.map((alarm: any, i: number) => {
                          return (
                            <>
                              <>
                                <>
                                  <tr>
                                    <th>{"Session Number"}</th>
                                    <td>{isNull(alarm.sessionNumber)}</td>
                                  </tr>
                                  <tr>
                                    <th>{"Device Id"}</th>
                                    <td>{isNull(alarm.deviceId)}</td>
                                  </tr>
                                </>
                                <>
                                  {" "}
                                  <tr>
                                    <th>{"Profiles"}</th>
                                    <td>
                                      {alarm.profiles?.length !== 0 &&
                                        alarm.profiles?.map(
                                          (alarms: any, i: number) => {
                                            return <div>{isNull(alarms)};</div>;
                                          }
                                        )}
                                    </td>
                                  </tr>
                                </>
                                <>
                                  <tr>
                                    <th>{"Site Names"}</th>
                                    <td>
                                      {alarm.siteNames?.length !== 0 &&
                                        alarm.siteNames?.map(
                                          (site: any, i: number) => {
                                            return <div>{isNull(site)};</div>;
                                          }
                                        )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>{"Session Id"}</th>
                                    <td>{isNull(alarm.sessionId)}</td>
                                  </tr>
                                  <tr>
                                    <th>{"DiamPeer"}</th>
                                    <td>{isNull(alarm.diamPeer)}</td>
                                  </tr>
                                  <tr>
                                    <th>{"nasPortId"}</th>
                                    <td>{isNull(alarm.nasPortId)}</td>
                                  </tr>
                                  <tr>
                                    <th>{"IPV4"}</th>
                                    <td>{isNull(alarm.ipv4)}</td>
                                  </tr>
                                  <tr>
                                    <th>{"ipv6Prefix"}</th>
                                    <td>{isNull(alarm.ipv6Prefix)}</td>
                                  </tr>
                                  <tr>
                                    <th>{"IPV6"}</th>
                                    <td>{isNull(alarm.ipv6)}</td>
                                  </tr>
                                  <tr>
                                    <th>{"Last Message Time"}</th>
                                    <td>{isNull(alarm.lastMessageTime)}</td>
                                  </tr>
                                </>
                                <tr>
                                  <th>{"qosQueues"}</th>
                                  <td>
                                    {alarm.qosQueues?.length !== 0 &&
                                      alarm.qosQueues?.map(
                                        (queue: any, i: number) => {
                                          return (
                                            <>
                                              <div>
                                                {"queueId:" +
                                                  isNull(queue.queueId)}
                                                <br />
                                                {"maxRequestedBandwidthDL: " +
                                                  isNull(
                                                    queue.maxRequestedBandwidthDL
                                                  )}
                                                <br />
                                                {"maxRequestedBandwidthUL: " +
                                                  isNull(
                                                    queue.maxRequestedBandwidthUL
                                                  )}
                                                <br />
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                  </td>
                                </tr>
                              </>
                            </>
                          );
                        })}
                      </tbody>
                    </>
                  ) : (
                    <div className="diagErrorCont">
                      <ExceptionBox
                        headerText="No Data to display"
                        bodyText="Please try refreshing if data is missing."
                        primaryBtn={true}
                        secBtn={false}
                        handleRefresh={() => refreshData()}
                      />
                    </div>
                  )}
                </table>
              </Card.Body>
            </Card>
          </div>
        ) : (
          <div className="diagErrorCont">
            <ExceptionBox
              headerText="No Data to display"
              bodyText="Please try refreshing if data is missing."
              primaryBtn={true}
              secBtn={false}
              handleRefresh={() => refreshData()}
            />
          </div>
        )}
      </DiagContainer>
    </>
  );
};

export default ONTIPSession;
