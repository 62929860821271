import { useDispatch } from "react-redux";
import CustomAccordion from "../../../../components/reusable/CustomAccordion";
import GoogleMapDirectionsBtn from "../../../../components/reusable/GoogleMapDirectionsBtn";
import { GetAvailableTR } from "../../../../components/reusable/Util";
import colors from "../../../config/colors";
import TopologyLabels from "../../../constants/topologyLabels";
import {
  getVhubClamshellSearch,
  resetClamshellData,
} from "../../../store/slices/mapItems/vhub/vhubClamshellSearch";
import { GetVhubClamshellSearchProps } from "../../../util/reduxFunctions/getTopologyState";
import appConst from "../../../constants/appConst";
import { getNodeIDFromSC } from "../../../util/utilFuncs";
import { BiLinkExternal } from "react-icons/bi";
import { useEffect } from "react";

interface Props {
  elementData: any;
}

export default function VhubDetailsTable({ elementData }: Props) {
  const labels = TopologyLabels();
  const dispatch = useDispatch();
  const { data } = GetVhubClamshellSearchProps();
  const onNetworkIdClick = (clamshell: any) => {
    if (clamshell?.networkId !== undefined) {
      dispatch(getVhubClamshellSearch(clamshell?.networkId));
    }
  };
  const openReturnSegment = (rsName: string) => {
    if (rsName) {
      let nodeID = getNodeIDFromSC(rsName);
      if (nodeID && nodeID !== "") window.open(`/#/node?nodeId=` + nodeID);
    }
  };

  useEffect(() => {
    if (elementData?.data?.networkId) {
      dispatch(resetClamshellData());
    }
  }, [elementData?.data?.networkId]);
  return (
    <>
      {elementData && (
        <div className="activeMapEleCont">
          <CustomAccordion
            accTitle={labels?.vhub?.toUpperCase()}
            headerComp={
              <GoogleMapDirectionsBtn
                lat={elementData?.coord?.lat}
                lng={elementData?.coord?.lng}
              />
            }
            accBodyElement={
              <table className="dataTableDetails">
                <thead>
                  <tr>
                    <td>{labels?.name.toUpperCase()}</td>
                    <td>{labels?.Value.toUpperCase()}</td>
                  </tr>
                </thead>
                <tbody>
                  <GetAvailableTR
                    name={labels?.networkID}
                    value={elementData?.data?.networkId}
                  />
                  <GetAvailableTR
                    name={labels?.profileName}
                    value={elementData?.data?.profileName}
                  />
                  <GetAvailableTR
                    name={labels?.Volts}
                    value={elementData?.data?.volts}
                  />
                  <GetAvailableTR
                    name={labels?.Amps}
                    value={elementData?.data?.amps}
                  />
                  <GetAvailableTR
                    name={labels?.address}
                    value={elementData?.data?.address}
                  />
                  <GetAvailableTR
                    name={labels?.Town}
                    value={elementData?.data?.town}
                  />
                  <GetAvailableTR
                    name={labels?.AMPDrawId}
                    value={elementData?.data?.ampDrawId}
                  />
                  <GetAvailableTR
                    name={labels?.psId}
                    value={elementData?.data?.psId}
                  />
                  <GetAvailableTR
                    name={labels?.sType}
                    value={elementData?.data?.sType}
                  />
                  <GetAvailableTR
                    name={labels?.Wattage}
                    value={elementData?.data?.wattage}
                  />
                  <GetAvailableTR
                    name={labels?.Designation}
                    value={elementData?.data?.designation}
                  />
                  <GetAvailableTR
                    name={labels?.rfNode}
                    value={elementData?.data?.rfNode}
                  />
                  <GetAvailableTR
                    name={labels?.siteType}
                    value={elementData?.data?.siteType}
                  />
                </tbody>
              </table>
            }
          />
        </div>
      )}
      {elementData?.data?.children &&
        elementData?.data?.children !== undefined && (
          <div className="activeMapEleCont">
            <CustomAccordion
              accTitle={labels?.vhubChildrenClamshells?.toUpperCase()}
              accBodyElement={
                <table className="dataTableDetails">
                  <thead>
                    <tr>
                      <td>{labels?.networkID.toUpperCase()}</td>
                      <td>{labels?.Clamshell.toUpperCase()}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {elementData?.data?.children?.map(
                      (clamshell: any, i: number) => {
                        if (clamshell) {
                          return (
                            <tr key={i}>
                              <td
                                style={{
                                  color: colors.link,
                                  cursor: "pointer",
                                }}
                                onClick={() => onNetworkIdClick(clamshell)}
                              >
                                {clamshell?.networkId}
                              </td>
                              <td>{clamshell?.clamshellId}</td>
                            </tr>
                          );
                        }
                      }
                    )}
                  </tbody>
                </table>
              }
            />
          </div>
        )}

      {data && data?.length > 0 && (
        <CustomAccordion
          accTitle={labels?.siteComponents?.toUpperCase()}
          accBodyElement={
            <div className="siteComponentsTable">
              <table className="dataTableDetails">
                <thead>
                  <tr>
                    <td>{labels?.networkID.toUpperCase()}</td>
                    <td>{labels?.name.toUpperCase()}</td>
                    <td>{labels?.category.toUpperCase()}</td>
                    <td>{labels?.model.toUpperCase()}</td>
                    <td>{labels?.type.toUpperCase()}</td>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((sc: any) => {
                    const nodeID = getNodeIDFromSC(sc?.name);
                    const isReturnSeg =
                      !isNaN(parseInt(nodeID)) && nodeID?.length === 6;
                    return (
                      <tr key={sc?.networkId}>
                        <td>{sc?.networkId}</td>
                        <td>
                          {sc?.name}
                          {sc?.type === appConst.returnSegment &&
                            isReturnSeg && (
                              <BiLinkExternal
                                color="var(--link)"
                                size={15}
                                onClick={() => openReturnSegment(sc?.name)}
                              />
                            )}
                        </td>
                        <td>{sc?.category}</td>
                        <td>{sc?.model}</td>
                        <td>{sc?.type}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          }
        />
      )}
    </>
  );
}
