import { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { Divider } from "@material-ui/core";
import { IoClose } from "react-icons/io5";
import FullPageLoader from "../reusable/FullPageLoader";

import SearchResult from "./SearchResult";
import ErrorBox from "../reusable/ErrorBox";
import SearchBar from "./SearchBar";
import SearchNodeResult from "./SearchNodeResult";
import {
  GetOltNameSearchDetails,
  GetTechInfo,
  GetEmtaTNDetails,
} from "../../get-api/GetResponse";
import { getAccessLevelByRoles } from "../reusable/Util";
import ConfigConst from "../../constants/ConfigConst";
import MultiDeviceSearch from "./MultiDeviceSearch";
const SearchPopUp = (props: any) => {
  const {
    searchAccStatus,
    data,
    searchNodeStatus,
    nodedata,
    gponNodeStatus,
    gponNodeData,
  } = useSelector(
    (state: any) => ({
      searchAccStatus: state.AccountState.AccountDetails.status,
      data: state.AccountState.AccountDetails.data,
      searchNodeStatus: state.NodeState.NodeInfoDetails.status,
      nodedata: state.NodeState.NodeInfoDetails.data,
      gponNodeStatus: state.GponState.GponNodeInfo.status,
      gponNodeData: state.GponState.GponNodeInfo.data,
    }),
    shallowEqual
  );
  const [currSearchValue, setCurrSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("This is Error Message");
  const [serviceAddress, setServiceAddress] = useState<String[]>([]);
  const [wHTFlag, setWHTFlag] = useState(false);
  const [searchType, setSearchType] = useState("accountNumber");
  const techData = GetTechInfo()?.data;
  const isMaintTech =
    techData && getAccessLevelByRoles(techData?.roles).includes(ConfigConst.MT)
      ? true
      : false;
  const handleClose = () => {
    setOpen(false);
  };
  const { oltResultData, oltStatus, oltError } = GetOltNameSearchDetails();
  const { emtaTNStatus, emtaTNdata } = GetEmtaTNDetails();

  useEffect(() => {
    if (searchAccStatus === "OK" && data !== "") {
      if (currSearchValue !== undefined && currSearchValue !== "") {
        let sHistory: any = localStorage.getItem("searchHistory");
        let CurrsearchHistory = JSON.parse(sHistory);
        if (CurrsearchHistory === null || CurrsearchHistory === undefined) {
          localStorage.setItem(
            "searchHistory",
            JSON.stringify([currSearchValue])
          );
        } else {
          if (CurrsearchHistory?.indexOf(currSearchValue) > -1) {
            CurrsearchHistory.splice(
              CurrsearchHistory?.indexOf(currSearchValue),
              1
            );
          }
          CurrsearchHistory.push(currSearchValue);
          localStorage.setItem(
            "searchHistory",
            JSON.stringify(CurrsearchHistory)
          );
        }
      }
      setSearchType("");
      let addresses: String[] = [];
      data?.serviceAddress?.map((sa: any) => {
        let address = "";
        if (sa.apartmentNumber === "") {
          address =
            "" +
            sa.streetNumber +
            " " +
            sa.streetName +
            " " +
            sa.streetType +
            ", " +
            sa.city +
            ", " +
            sa.province +
            " " +
            sa.postalCode;
        } else {
          address =
            "" +
            sa.apartmentNumber +
            "-" +
            sa.streetNumber +
            " " +
            sa.streetName +
            " " +
            sa.streetType +
            ", " +
            sa.city +
            ", " +
            sa.province +
            " " +
            sa.postalCode;
        }
        addresses.push(address);
      });
      setServiceAddress(addresses);
    } else if (
      searchAccStatus === "ERROR" &&
      data === undefined &&
      currSearchValue.length === 9 &&
      searchType === "Account Number"
    ) {
      setSearchType("cbpId");
    } else if (
      searchAccStatus === "ERROR" &&
      data === undefined &&
      currSearchValue.length === 12 &&
      searchType === "Account Number"
    ) {
      setSearchType("macAddress");
    } else if (searchAccStatus === "ERROR" && data === undefined) {
      setSearchType("");
      setError(
        "Sorry! There is no Account matching your input. Please check and try again."
      );
      setOpen(true);
    } else if (searchAccStatus === "OK" && data === "") {
      setSearchType("");
      setError(
        "Sorry! There is no Account matching your input. Please check and try again."
      );
      setOpen(true);
    } else if (gponNodeStatus === "ERROR" && gponNodeData === undefined) {
      setSearchType("");
      setError(
        "Sorry! There is no GPON Node matching your input. Please check and try again."
      );
      setOpen(true);
    }
  }, [searchAccStatus, gponNodeStatus]);
  /*Changes added by node Team to populate node information in popup */
  useEffect(() => {
    if (
      searchNodeStatus === "OK" &&
      JSON.stringify(nodedata) !== '[""]' &&
      nodedata !== undefined &&
      JSON.stringify(nodedata) != ""
    ) {
      if (currSearchValue !== "" && currSearchValue !== undefined) {
        let sHistory: any = localStorage.getItem("searchHistory");
        let CurrsearchHistory = JSON.parse(sHistory);
        if (
          CurrsearchHistory === null ||
          CurrsearchHistory === undefined ||
          CurrsearchHistory?.length === 0
        ) {
          localStorage.setItem(
            "searchHistory",
            JSON.stringify([currSearchValue])
          );
        } else {
          if (CurrsearchHistory.indexOf(currSearchValue) > -1) {
            CurrsearchHistory.splice(
              CurrsearchHistory.indexOf(currSearchValue),
              1
            );
          }
          CurrsearchHistory.push(currSearchValue);
          localStorage.setItem(
            "searchHistory",
            JSON.stringify(CurrsearchHistory)
          );
        }
      }
      // handleDGShow();
    } else if (searchNodeStatus === "ERROR" && nodedata === undefined) {
      setError(
        "Sorry! There is no Node matching your input. Please check and try again."
      );
      setOpen(true);
    } else if (
      searchNodeStatus === "OK" &&
      JSON.stringify(nodedata) === '[""]'
    ) {
      setError(
        "Sorry! There is no Node matching your input. Please check and try again."
      );
      setOpen(true);
    }
  }, [searchNodeStatus]);

  useEffect(() => {
    if (oltStatus === "OK" && JSON.stringify(oltResultData) !== '[""]') {
      if (currSearchValue !== "" && currSearchValue !== undefined) {
        let sHistory: any = localStorage.getItem("searchHistory");
        let CurrsearchHistory = JSON.parse(sHistory);
        if (
          CurrsearchHistory === null ||
          CurrsearchHistory === undefined ||
          CurrsearchHistory?.length === 0
        ) {
          localStorage.setItem(
            "searchHistory",
            JSON.stringify([currSearchValue])
          );
        } else {
          if (CurrsearchHistory.indexOf(currSearchValue) > -1) {
            CurrsearchHistory.splice(
              CurrsearchHistory.indexOf(currSearchValue),
              1
            );
          }
          CurrsearchHistory.push(currSearchValue);
          localStorage.setItem(
            "searchHistory",
            JSON.stringify(CurrsearchHistory)
          );
        }
      }

      //handleDGShow();
    } else if (oltStatus === "ERROR" && oltResultData === undefined) {
      setError(
        "Sorry! There is no OLT matching your input. Please check and try again."
      );
      setOpen(true);
    } else if (oltStatus === "OK" && JSON.stringify(oltResultData) === '[""]') {
      setError(
        "Sorry! There is no OLT matching your input. Please check and try again."
      );
      setOpen(true);
    } else {
    }
  }, [oltStatus]);

  useEffect(() => {
    if (emtaTNStatus === "OK" && JSON.stringify(emtaTNdata) !== '{""}') {
      if (currSearchValue !== "" && currSearchValue !== undefined) {
        let sHistory: any = localStorage.getItem("searchHistory");
        let CurrsearchHistory = JSON.parse(sHistory);
        if (
          CurrsearchHistory === null ||
          CurrsearchHistory === undefined ||
          CurrsearchHistory?.length === 0
        ) {
          localStorage.setItem(
            "searchHistory",
            JSON.stringify([currSearchValue])
          );
        } else {
          if (CurrsearchHistory.indexOf(currSearchValue) > -1) {
            CurrsearchHistory.splice(
              CurrsearchHistory.indexOf(currSearchValue),
              1
            );
          }
          CurrsearchHistory.push(currSearchValue);
          localStorage.setItem(
            "searchHistory",
            JSON.stringify(CurrsearchHistory)
          );
        }
      }

      //handleDGShow();
    } else if (emtaTNStatus === "ERROR" && emtaTNdata === undefined) {
      setError(
        "Sorry! There is no Device matching your input. Please check and try again."
      );
      setOpen(true);
    } else if (emtaTNStatus === "OK" && JSON.stringify(emtaTNdata) === '{""}') {
      setError(
        "Sorry! There is no Device matching your input. Please check and try again."
      );
      setOpen(true);
    } else {
    }
  }, [emtaTNStatus]);
  const handleWHTChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWHTFlag(event.target.checked);
  };
  return (
    <>
      <Modal
        {...props}
        id="searchPopUp"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header className="searchPopHead">
          <header>SEARCH</header>
          <button className="sPopUpClose" onClick={props.onHide}>
            <IoClose color="white" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <section className="searchPOPUpInputCont">
            <MultiDeviceSearch
              searchType={searchType}
              setSearchType={setSearchType}
              setCurrSearchValue={setCurrSearchValue}
              onHide={props.onHide}
            />
          </section>
        </Modal.Body>
        <Divider orientation="horizontal" />
        {searchAccStatus === "OK" ? (
          <SearchResult
            onHide={props.onHide}
            wHTFlag={wHTFlag}
            handleWHTChecked={handleWHTChecked}
            data={data}
            serviceAddress={serviceAddress}
            currSearchValue={currSearchValue}
          />
        ) : /*Changes added by node Team */ searchNodeStatus === "OK" &&
          JSON.stringify(nodedata) !== '[""]' ? (
          <SearchNodeResult
            onHide={props.onHide}
            nodedata={nodedata}
            searchNodeStatus={searchNodeStatus}
            setError={setError}
            setOpen={setOpen}
          />
        ) : (
          // : oltStatus === "OK" &&
          //   oltResultData !== undefined &&
          //   JSON.stringify(oltResultData) != "" &&
          //   JSON.stringify(oltResultData) !== '[""]' ? (
          //   <>
          //     {console.log("Entered :::::::::")}
          //     {/* <SearchNodeResult
          //       onHide={props.onHide}
          //       nodedata={oltResultData}
          //       searchNodeStatus={oltStatus}
          //     /> */}
          //   </>
          // )
          /*Changes by node Team end */ <></>
        )}
        <Modal.Footer>
          <button className="sPopCloseBtn" onClick={props.onHide}>
            Close
          </button>
        </Modal.Footer>
        <ErrorBox
          open={open}
          error={error}
          setOpen={setOpen}
          handleClose={handleClose}
        />
      </Modal>
      <FullPageLoader />
    </>
  );
};

export default SearchPopUp;
