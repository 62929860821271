import { AppBar, Tab, Tabs } from "@material-ui/core";
import { useEffect, useState } from "react";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import CMSummaryInfoFull from "../../components/modem/CMSummaryInfoFull";
import CMSummaryInfoSm from "../../components/modem/CMSummaryInfoSm";
import CmDSTab from "../../components/modem/CmDSTab";
import CmFlapsTab from "../../components/modem/CmFlapsTab";
import CmUSTab from "../../components/modem/CmUSTab";
import DeviceActionMenu from "../../components/reusable/DeviceActionMenu";
import ExceptionBox from "../../components/reusable/ExceptionBox";
import { TabPanel, a11yProps } from "../../components/reusable/Util";
import AppLabels from "../../constants/App_Labels";
import "../../design-tokens/modem-page/CableModemPage.scss";

const CableModemView = (props: any) => {
  const labels = AppLabels();
  const [cmData, setcmData] = useState<any>();
  const [nodeData, setNodeData] = useState<any>();
  const [value, setValue] = useState(0);
  const [expand, setExpand] = useState(false);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleToggle = () => {
    setExpand((prev) => !prev);
  };

  useEffect(() => {
    const message = (e: any) => {
      if (e && e.data?.hasOwnProperty("cmData")) {
        setcmData(e.data?.cmData);
      }
      if (e && e.data?.hasOwnProperty("nodeData")) {
        setNodeData(e.data?.nodeData);
      }
    };
    window.addEventListener("message", message);
    return () => {
      window.removeEventListener("message", message);
    };
  }, []);

  return (
    <div className="cmDetailsPage">
      {cmData ? (
        <div>
          <div className="cmBasicDetailsCont">
            {expand ? (
              <CMSummaryInfoFull cmData={cmData} nodeData={nodeData} />
            ) : (
              <CMSummaryInfoSm cmData={cmData} nodeData={nodeData} />
            )}
            <div className="nodeTglBtnCont">
              <DeviceActionMenu
                key={1}
                deviceName={cmData?.deviceType}
                deviceActions={[{ action: "Reboot", label: "Reboot" }]}
                menuType={"vertical"}
                deviceInfo={{
                  macAddress: cmData?.macAddress,
                  serialNumber: cmData?.serialNumber,
                }}
              />
              <span onClick={handleToggle}>
                {expand ? (
                  <IoIosArrowDropup size={25} />
                ) : (
                  <IoIosArrowDropdown size={25} />
                )}
              </span>
            </div>
          </div>
          {cmData?.deviceDiag?.data?.docsis && (
            <>
              <AppBar position="static" className="accTabsBar">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="secondary"
                  textColor="secondary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab label="DOWNSTREAM" {...a11yProps(0)} />
                  <Tab label="UPSTREAM" {...a11yProps(1)} />
                  <Tab label="FLAPS" {...a11yProps(2)} />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <CmDSTab cmData={cmData?.deviceDiag?.data} flag={false} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <CmUSTab cmData={cmData?.deviceDiag?.data} flag={false} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <CmFlapsTab cmData={cmData?.deviceDiag?.data} />
              </TabPanel>
            </>
          )}
        </div>
      ) : (
        <div className="diagErrorCont">
          <ExceptionBox
            headerText={labels.NO_DATA}
            bodyText={labels.NO_DATA_ERROR_MSG}
          />
        </div>
      )}
    </div>
  );
};

export default CableModemView;
