import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { HttpClient } from "../HttpClient";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { NetworkConfig } from "../NetworkConfig";

import {
  VhubDetailsBegin,
  VhubDetailsFailure,
  VhubDetailsSuccess,
} from "../../../store/slices/nodeInfo/VhubDetails";

interface VhubDetailsParams {
  nodeId: string;
  nodeType: string;
}

export const useVhubDetailsClient = () => {
  const dispatch = useDispatch();

  const getVhubDetails = useCallback(
    async ({ nodeId, nodeType }: VhubDetailsParams) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        // url: "/api/topology-service/node/",
        url: NetworkConfig.TOPOLOGY_SERVICE_URL + "node",
        params: {
          nodeId,
          nodeType,
        },
      };

      dispatch(VhubDetailsBegin());

      const result = await HttpClient.get(httpParameters)
        .then((response) => {
          const parentValue = response?.features?.[0]?.properties?.data?.parent;
          if (parentValue) {
            dispatch(VhubDetailsSuccess(response));
            return parentValue;
          } else {
            // throw new Error("Parent value not found in the response");
            return "";
          }
        })
        .catch((error) => {
          dispatch(VhubDetailsFailure(error));
          return "";
        });
      return result;
    },
    [dispatch]
  );

  return {
    getVhubDetails,
  };
};
