import { IHPFeatures } from "../../../../models/account/diagnostics/ihp/IHPFeatures";

export const IHP_FEATURES_BEGIN = "IHP_FEATURES_BEGIN";
export const IHP_FEATURES_SUCCESS = "IHP_FEATURES_SUCCESS";
export const IHP_FEATURES_FAILURE = "IHP_FEATURES_FAILURE";

interface IHPFeaturesBegin {
  type: typeof IHP_FEATURES_BEGIN;
}

interface IHPFeaturesSuccess {
  type: typeof IHP_FEATURES_SUCCESS;
  payload: IHPFeatures;
}

interface IHPFeaturesFailure {
  type: typeof IHP_FEATURES_FAILURE;
  payload: Error;
}

export type IhpFeaturesActionTypes =
  | IHPFeaturesBegin
  | IHPFeaturesSuccess
  | IHPFeaturesFailure;
