import {
    SAVE_GPON_NODES,
    UPDATE_GPON_NODES,
    RESET_GPON_NODES,
    INCREMENT_GPON_NODE_API_CALL_COUNTER,
    DECREMENT_GPON_NODE_API_CALL_COUNTER,
    GponNodesActionTypes,
} from '../../types/gpon/GponNodeSummary';
export function SaveGponNodes(nodes: any): GponNodesActionTypes {
    return {
        type: SAVE_GPON_NODES,
        payload: nodes,
    };
}

export function UpdateGponNodeDevices(
    snNumber: string,
    subdataKey: string,
    data: any,
    status: string,
): GponNodesActionTypes {
    return {
        type: UPDATE_GPON_NODES,
        snNumber: snNumber,
        subdataKey: subdataKey,
        data: data,
        status: status,
    };
}
export function IncrementGponNodeApiCallCounter(
    snNumber: string,
    subdataKey: string,
): GponNodesActionTypes {
    return {
        type: INCREMENT_GPON_NODE_API_CALL_COUNTER,
        snNumber: snNumber,
        subDataKey: subdataKey,
    };
}
export function DecrementGponNodeApiCallCounter(
    snNumber: string,
    subdataKey: string,
): GponNodesActionTypes {
    return {
        type: DECREMENT_GPON_NODE_API_CALL_COUNTER,
        snNumber: snNumber,
        subDataKey: subdataKey,
    };
}

export function ResetGponNodes(): GponNodesActionTypes {
    return {
        type: RESET_GPON_NODES,
    };
}
