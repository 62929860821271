import { Dropdown, DropdownButton } from "react-bootstrap";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import AppLabels from "../../../constants/App_Labels";
import formLabels from "../../../data/forms/PJI/formLabels";
import PreJobInspectionData from "../../../data/forms/PJI/PreJobInspectionsData";
import {
  createPJIApiObj,
  updateObjValuesFromArray,
  updateObjValuesFromAnotherObj,
} from "./PJIUtil";
import HighRiskView from "./views/main/HighRiskView";
import LowRiskView from "./views/main/LowRiskView";
import ConfirmPopUp from "../../reusable/ConfirmPopUp";

interface Props {
  setRequiredDataFilled?: any;
  onApiCall?: any;
  formData?: any;
}

const WorkDetails = forwardRef((props: Props, ref) => {
  const labels = AppLabels();
  const initialData = PreJobInspectionData();
  const safetyLevels = [labels.Medium_to_High_Risk, labels.Low_Risk];
  const safetyLevelsDesc = [
    labels.Medium_to_High_Risk_Desc,
    labels.Low_Risk_Desc,
  ];
  const safetyLevelKeys = [formLabels.highRisk, formLabels.lowRisk];

  //////// All States with initial data //////////////////////////////
  const [safetyLevelClickedValue, setsafetyLevelClickedValue] = useState("");
  const [safetyLevelChangePopup, setSafetyLevelChangePopup] = useState(false);
  const [isSafetyIndexUpdatingFirstTime, setSafetyIndexUpdatingFirstTime] =
    useState(true);
  const [isHazardDataUpdated, setHazardDataUpdated] = useState(false);
  const [safetyLevelIndex, setSafetyLevelIndex] = useState(
    safetyLevelKeys.indexOf(props.formData?.riskOption)
  );
  const [initialChecks, setInitialChecks] = useState(
    initialData?.initialCheckOriginalData
  );
  const [inspectionPhysicalWork, setInspectionPhysicalWork] = useState(
    initialData?.inspectionAreaInitialData
  );
  const [ppe, setPpe] = useState(initialData?.ppeInitialData);
  const [hazardsSituations, setHazardsSituations] = useState(
    initialData?.hazardsCheckboxInitialData
  );
  const [workingfromHeight, setWorkingfromHeight] = useState(
    initialData?.heightHazardData
  );
  const [fallProtectionSystem, setFallProtectionSystem] = useState(
    initialData?.fallProtectionSystemData
  );
  const [anchorage, setAnchorage] = useState(initialData?.anchoragePointData);
  const [liveElectricity, setLiveElectricity] = useState(
    initialData?.liveElectricityData
  );
  const [electricityControls, setElectricityControls] = useState(
    initialData?.electricityControlsData
  );
  const [confinedSpace, setConfinedSpace] = useState(
    initialData?.confinedSpaceData
  );
  const [confinedSpaceControls, setConfinedSpaceControls] = useState(
    initialData?.confinedControlsData
  );
  const [comments, setComments] = useState(initialData?.commentInitial);

  //Enable or disable submit button based on mandatory fields
  useEffect(() => {
    setSafetyLevelIndex(safetyLevelKeys.indexOf(props.formData?.riskOption));
  }, [props.formData]);
  useEffect(() => {
    const selectedInitialChecks = initialChecks?.filter(
      (item: any) => item.value != null
    );
    const selectedinspectionPhysicalWork = inspectionPhysicalWork?.filter(
      (item: any) => item.value != null
    );
    if (safetyLevelIndex === 1) {
      props.setRequiredDataFilled(
        initialChecks?.length === selectedInitialChecks?.length
      );
    } else if (safetyLevelIndex === 0) {
      props.setRequiredDataFilled(
        initialChecks?.length === selectedInitialChecks?.length &&
          inspectionPhysicalWork?.length ===
            selectedinspectionPhysicalWork?.length
      );
    }
  }, [initialChecks, inspectionPhysicalWork, props.formData]);

  //clear data whenever risk option is changed
  useEffect(() => {
    if (props.formData !== undefined && props.formData?.length !== 0) {
      if (isSafetyIndexUpdatingFirstTime && props.formData?.riskOption) {
        setSafetyIndexUpdatingFirstTime(false);
      } else {
        resetAllData();
        setSafetyIndexUpdatingFirstTime(false);
      }
    }
  }, [safetyLevelIndex]);

  ////////////////////////// update form values when form data is avilable ////////////////////////////////////////////
  useEffect(() => {
    if (props.formData !== undefined && props.formData?.length !== 0) {
      setInitialChecks(
        updateObjValuesFromAnotherObj(
          initialChecks,
          props.formData?.initialChecks
        )
      );
      setComments(
        updateObjValuesFromAnotherObj(comments, props.formData?.comments)
      );
      /////////////////////// only for High Risk //////////////////////////
      if (props.formData?.riskOption === formLabels.highRisk) {
        setInspectionPhysicalWork(
          updateObjValuesFromAnotherObj(
            inspectionPhysicalWork,
            props.formData?.hazardsPresent
          )
        );
        setPpe(updateObjValuesFromArray(ppe, props.formData?.generalPPE));
        ////// Hazeard Situations //////
        let hazardsSituationsCopy = hazardsSituations?.map((el) =>
          props.formData?.hazardActivities?.hasOwnProperty(el.key) &&
          props.formData?.hazardActivities?.[el.key]?.length > 0
            ? { ...el, value: true }
            : el
        );
        setHazardsSituations(hazardsSituationsCopy);
      }
    }
  }, [props.formData]);

  ////////update hazards inner views data when form data is avilable and hazard selection is changed////////
  useEffect(() => {
    if (hazardsSituations?.[0]?.value === false) {
      setWorkingfromHeight(initialData?.heightHazardData);
      setFallProtectionSystem(initialData?.fallProtectionSystemData);
      setAnchorage(initialData?.anchoragePointData);
    }
    if (hazardsSituations?.[1]?.value === false) {
      setLiveElectricity(initialData?.liveElectricityData);
      setElectricityControls(initialData?.electricityControlsData);
    }
    if (hazardsSituations?.[2]?.value === false) {
      setConfinedSpace(initialData?.confinedSpaceData);
      setConfinedSpaceControls(initialData?.confinedControlsData);
    }
    /////// update data when form history has data
    if (!isHazardDataUpdated && props.formData?.hazardActivities) {
      ////////// Working from heights ///////
      if (props.formData?.hazardActivities?.WorkingFromHeights?.length > 0) {
        setWorkingfromHeight(
          updateObjValuesFromArray(
            workingfromHeight,
            props.formData?.hazardActivities?.WorkingFromHeights
          )
        );
        setFallProtectionSystem(
          updateObjValuesFromArray(
            fallProtectionSystem,
            props.formData?.hazardActivities?.WorkingFromHeights
          )
        );
      }
      ////////// Live Electricity ///////
      if (props.formData?.hazardActivities?.LiveElectricity?.length > 0) {
        setLiveElectricity(
          updateObjValuesFromArray(
            liveElectricity,
            props.formData?.hazardActivities?.LiveElectricity
          )
        );
        setElectricityControls(
          updateObjValuesFromArray(
            electricityControls,
            props.formData?.hazardActivities?.LiveElectricity
          )
        );
      }
      ////////// Confined Space ///////
      if (props.formData?.hazardActivities?.ConfinedSpace?.length > 0) {
        setConfinedSpace(
          updateObjValuesFromArray(
            confinedSpace,
            props.formData?.hazardActivities?.ConfinedSpace
          )
        );
        setConfinedSpaceControls(
          updateObjValuesFromArray(
            confinedSpaceControls,
            props.formData?.hazardActivities?.ConfinedSpace
          )
        );
      }
      ////////// Anchorage ///////
      if (props.formData?.hazardActivities?.Anchorage?.length > 0) {
        setAnchorage(
          updateObjValuesFromArray(
            anchorage,
            props.formData?.hazardActivities?.Anchorage
          )
        );
      }
      setHazardDataUpdated(true);
    }
  }, [hazardsSituations]);

  useImperativeHandle(ref, () => ({
    handleSubmit() {
      const data = {
        riskOption: safetyLevelKeys[safetyLevelIndex],
        initialChecks: initialChecks,
        comments: comments,
        inspectionPhysicalWork: inspectionPhysicalWork,
        ppe: ppe,
        workingFromHeightData: [...workingfromHeight, ...fallProtectionSystem],
        liveElectricityData: [...liveElectricity, ...electricityControls],
        confinedSpaceData: [...confinedSpace, ...confinedSpaceControls],
        anchorage: anchorage,
      };
      props.onApiCall(createPJIApiObj(data));
    },
  }));

  const resetAllData = () => {
    setInitialChecks(initialData?.initialCheckOriginalData);
    setInspectionPhysicalWork(initialData?.inspectionAreaInitialData);
    setPpe(initialData?.ppeInitialData);
    setHazardsSituations(initialData?.hazardsCheckboxInitialData);
    setWorkingfromHeight(initialData?.heightHazardData);
    setFallProtectionSystem(initialData?.fallProtectionSystemData);
    setAnchorage(initialData?.anchoragePointData);
    setLiveElectricity(initialData?.liveElectricityData);
    setElectricityControls(initialData?.electricityControlsData);
    setConfinedSpace(initialData?.confinedSpaceData);
    setConfinedSpaceControls(initialData?.confinedControlsData);
    setComments(initialData?.commentInitial);
  };

  //Handle when safety level option is changed
  const onSafetyLevelChange = (value: any) => {
    setsafetyLevelClickedValue(value);
    const selectedInitialChecks = initialChecks?.filter(
      (item: any) => item.value != null
    );
    if (
      selectedInitialChecks?.length > 0 &&
      safetyLevelsDesc.indexOf(value) != safetyLevelIndex
    ) {
      setSafetyLevelChangePopup(true);
    } else {
      setSafetyLevelIndex(safetyLevelsDesc.indexOf(value));
    }
  };

  const onPopupConfirm = () => {
    setSafetyLevelIndex(safetyLevelsDesc.indexOf(safetyLevelClickedValue));
    setSafetyLevelChangePopup(false);
  };

  return (
    <div className="WorkDetailscontainer">
      <DropdownButton
        title={
          safetyLevelIndex === -1
            ? `${labels.Select_one_of_the_options}*`
            : safetyLevels[safetyLevelIndex]
        }
        onSelect={(v: any) => onSafetyLevelChange(v)}
        size="sm"
        id="dropdownBtn"
      >
        {safetyLevelsDesc.map((e, i) => (
          <Dropdown.Item key={i} eventKey={e}>
            {e}
          </Dropdown.Item>
        ))}
      </DropdownButton>
      {safetyLevelIndex === 0 ? (
        <HighRiskView
          initialChecks={initialChecks}
          setInitialChecks={setInitialChecks}
          inspectionPhysicalWork={inspectionPhysicalWork}
          setInspectionPhysicalWork={setInspectionPhysicalWork}
          ppe={ppe}
          setPPE={setPpe}
          hazardsSituations={hazardsSituations}
          setHazardsSituations={setHazardsSituations}
          workingfromHeight={workingfromHeight}
          setWorkingfromHeight={setWorkingfromHeight}
          fallProtectionSystem={fallProtectionSystem}
          setFallProtectionSystem={setFallProtectionSystem}
          anchorage={anchorage}
          setAnchorage={setAnchorage}
          liveElectricity={liveElectricity}
          setLiveElectricity={setLiveElectricity}
          electricityControls={electricityControls}
          setElectricityControls={setElectricityControls}
          confinedSpace={confinedSpace}
          setConfinedSpace={setConfinedSpace}
          confinedSpaceControls={confinedSpaceControls}
          setConfinedSpaceControls={setConfinedSpaceControls}
          comments={comments}
          setComments={setComments}
        />
      ) : safetyLevelIndex === 1 ? (
        <LowRiskView
          initialChecks={initialChecks}
          setInitialChecks={setInitialChecks}
          comments={comments}
          setComments={setComments}
        />
      ) : (
        <></>
      )}
      <ConfirmPopUp
        showVal={safetyLevelChangePopup}
        headerText={labels.WARNING?.toUpperCase()}
        bodyText={labels.ABORT_MESSAGE}
        cancelText={labels.CANCEL}
        confirmText={labels.Continue}
        handleConfirm={onPopupConfirm}
        handleClose={() => setSafetyLevelChangePopup(false)}
      />
    </div>
  );
});

export default WorkDetails;
