import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../topology/store/middleware/apiActions";
import {
  NETWORK_SERVICE_URL,
  WO_STATUS_UPDATE_URI,
} from "../../middleware/apiEndpoints";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "workOrderUpdateStatus",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    // action => action handler
    workOrderStatusUpdateBegin: (woUpdate) => {
      woUpdate.isLoading = true;
      woUpdate.error = undefined;
      woUpdate.data = undefined;
      woUpdate.status = "";
    },
    workOrderStatusUpdateSuccess: (woUpdate, action) => {
      woUpdate.isLoading = false;
      woUpdate.data = action.payload;
      woUpdate.status = storeConst.OK;
    },
    workOrderStatusUpdateFailure: (woUpdate, action) => {
      woUpdate.isLoading = false;
      woUpdate.error = action.payload;
      woUpdate.data = undefined;
      woUpdate.status = storeConst.ERROR;
    },
    resetWOStatusUpdate: (woUpdate) => {
      woUpdate.isLoading = false;
      woUpdate.error = undefined;
      woUpdate.data = undefined;
      woUpdate.status = "";
    },
  },
});

export const {
  workOrderStatusUpdateBegin,
  workOrderStatusUpdateSuccess,
  workOrderStatusUpdateFailure,
  resetWOStatusUpdate,
} = slice.actions;
export default slice.reducer;

//action creators
export const updateWorkOrderStatus =
  (workOrderId: string, status: string, techID: string) => (dispatch: any) => {
    let params = {
      WorkOrderID: workOrderId,
      Status: status,
      TechID: techID,
    };
    dispatch(
      apiCallBegan({
        baseURL: NETWORK_SERVICE_URL,
        url: WO_STATUS_UPDATE_URI,
        method: "post",
        data: JSON.stringify(params),
        onStart: workOrderStatusUpdateBegin.type,
        onSuccess: workOrderStatusUpdateSuccess.type,
        onError: workOrderStatusUpdateFailure.type,
      })
    );
  };

//action creators
export const completeWorkOrderStatus =
  (workOrderId: string, status: string, techID: string, completedForm: any) =>
  (dispatch: any) => {
    var addCodesArr = completedForm?.additCode?.map((adCode: any) => ({
      Code: adCode?.key,
    }));
    let params = {
      WorkOrderID: workOrderId,
      Status: status,
      TechID: techID,
      FaultCode: completedForm?.faultCode?.key,
      NetworkCode: completedForm?.networkCode?.key,
      ActivityCode: completedForm?.activityCode?.key,
      CauseCode: completedForm?.causeCode?.key,
      PreventionCode: completedForm?.preventionCode?.key,
      AdditionalCodes: addCodesArr,
      Comments: completedForm?.comment,
    };

    dispatch(
      apiCallBegan({
        baseURL: NETWORK_SERVICE_URL,
        url: WO_STATUS_UPDATE_URI,
        method: "post",
        data: JSON.stringify(params),
        onStart: workOrderStatusUpdateBegin.type,
        onSuccess: workOrderStatusUpdateSuccess.type,
        onError: workOrderStatusUpdateFailure.type,
      })
    );
  };
