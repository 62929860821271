import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../middleware/apiActions";
import { MDU_POWER_SUPPLY } from "../../../middleware/apiEndpoints";
import storeConst from "../../../storeConst";
import { decrApiCall, incrApiCall } from "../mapDetails";

const slice = createSlice({
  name: "mduPowerSupply",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    getMduPowerSupplyBegin: (mduPowerSupply) => {
      mduPowerSupply.isLoading = true;
      mduPowerSupply.status = "";
    },
    getMduPowerSupplySuccess: (mduPowerSupply, action) => {
      mduPowerSupply.isLoading = false;
      mduPowerSupply.data = action.payload;
      mduPowerSupply.status = storeConst.OK;
    },
    getMduPowerSupplyFailure: (mduPowerSupply, action) => {
      mduPowerSupply.isLoading = false;
      mduPowerSupply.error = action.payload;
      mduPowerSupply.data = undefined;
      mduPowerSupply.status = storeConst.ERROR;
    },
  },
});

const {
  getMduPowerSupplyBegin,
  getMduPowerSupplySuccess,
  getMduPowerSupplyFailure,
} = slice.actions;
export default slice.reducer;

export const getMduPowerSupply = (owningDrawing: any) => (dispatch: any) => {
  dispatch(
    apiCallBegan({
      url: `${MDU_POWER_SUPPLY}/${owningDrawing}`,
      method: "get",
      onStart: getMduPowerSupplyBegin.type,
      onSuccess: getMduPowerSupplySuccess.type,
      onError: getMduPowerSupplyFailure.type,
      onStartDispatch: incrApiCall.type,
      onSuccessDispatch: decrApiCall.type,
      onErrorDispatch: decrApiCall.type,
    })
  );
};
