import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../middleware/apiActions";
import { MDU_FIBER_EQUIPMENT } from "../../../middleware/apiEndpoints";
import storeConst from "../../../storeConst";
import { decrApiCall, incrApiCall } from "../mapDetails";

const slice = createSlice({
  name: "mduFiberEquipments",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    getMduFiberEquipmentsBegin: (mduFiberEquipments) => {
      mduFiberEquipments.isLoading = true;
      mduFiberEquipments.status = "";
    },
    getMduFiberEquipmentsSuccess: (mduFiberEquipments, action) => {
      mduFiberEquipments.isLoading = false;
      mduFiberEquipments.data = action.payload;
      mduFiberEquipments.status = storeConst.OK;
    },
    getMduFiberEquipmentsFailure: (mduFiberEquipments, action) => {
      mduFiberEquipments.isLoading = false;
      mduFiberEquipments.error = action.payload;
      mduFiberEquipments.data = undefined;
      mduFiberEquipments.status = storeConst.ERROR;
    },
  },
});

const {
  getMduFiberEquipmentsBegin,
  getMduFiberEquipmentsSuccess,
  getMduFiberEquipmentsFailure,
} = slice.actions;
export default slice.reducer;

export const getMduFiberEquipments =
  (owningDrawing: any) => (dispatch: any) => {
    dispatch(
      apiCallBegan({
        url: `${MDU_FIBER_EQUIPMENT}/${owningDrawing}`,
        method: "get",
        onStart: getMduFiberEquipmentsBegin.type,
        onSuccess: getMduFiberEquipmentsSuccess.type,
        onError: getMduFiberEquipmentsFailure.type,
        onStartDispatch: incrApiCall.type,
        onSuccessDispatch: decrApiCall.type,
        onErrorDispatch: decrApiCall.type,
      })
    );
  };
