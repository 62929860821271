import { combineReducers } from "redux";
import { IPTVCertiNetworkReducer } from "./iptv/IPTVCertiNetworkReducer";
import { ActiveCertiReducer } from "./ActiveCertiReducer";
import { STBCertiSummaryReducer } from "./stb/STBCertiSummaryReducer";
import { AllCertificationReducer } from "./AllCertificationReducer";
import { CertiReasonsReducer } from "./CertiReasonsReducer";
import { ActiveCertiDeviceReducer } from "./ActiveCertiDeviceReducer";
import precheckDiagnosticData from "../../../ducksPattern/precheckDiagnosticData";
import allFailedAttributes from "../../../ducksPattern/allFailedAttributes";

export const CertificationReducer = combineReducers({
  ActiveCertiInfo: ActiveCertiReducer,
  AllCertiInfo: AllCertificationReducer,
  AllReasonsInfo: CertiReasonsReducer,
  IptvCertiNetworkInfo: IPTVCertiNetworkReducer,
  StbCertiSummaryInfo: STBCertiSummaryReducer,
  ActiveCertiDevicesInfo: ActiveCertiDeviceReducer,
  PrecheckDiagnosticInfo: precheckDiagnosticData,
  FailedAttributeInfo: allFailedAttributes,
});
