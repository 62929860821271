import { FaSearch } from "react-icons/fa";
import DiagContainer from "../../../../components/reusable/DiagContainer";
import { Divider } from "@material-ui/core";
import SimpleDataTable from "../../reusable/SimpleDataTable";
import ExceptionBox from "../../../../components/reusable/ExceptionBox";
import { useDispatch } from "react-redux";
import TopologyLabels from "../../../constants/topologyLabels";
import {
  GetLocatorElementData,
  GetSmallCellSearchProps,
} from "../../../util/reduxFunctions/getTopologyState";
import { useState } from "react";
import { getSmallCellSearch } from "../../../store/slices/search/smallCellSearch";
import {
  setMapCenter,
  setSelectedMapElement,
} from "../../../store/slices/mapItems/mapDetails";
import { addToLocatorEle } from "../../../store/slices/mapItems/locatorElements";
import { findEleFromLocatorEle } from "../../../util/utilFuncs";
import appConst from "../../../constants/appConst";

const SmallCellSearch = () => {
  const dispatch = useDispatch();
  const labels = TopologyLabels();
  const { smallCellData, smallCellError, smallCellLoading } =
    GetSmallCellSearchProps();
  const { locatorEle } = GetLocatorElementData();
  const [smallCell, setSmallCell] = useState("");

  const smallCellConfig = [labels.siteId, labels.siteType, labels.Designation];

  const searchSmallCell = () => {
    if (smallCell) {
      dispatch(getSmallCellSearch(smallCell));
    }
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      searchSmallCell();
    }
  };

  const makeEleActive = (eleData: any) => {
    dispatch(
      setMapCenter({
        lat: eleData?.geometry?.lat,
        lng: eleData?.geometry?.lng,
      })
    );
    dispatch(setSelectedMapElement(eleData));
  };
  const handleSmallCellClick = (ele: any) => {
    if (ele) {
      const eleData = findEleFromLocatorEle(
        "siteId",
        appConst.smallCell,
        ele,
        locatorEle?.[appConst.smallCell]
      );
      if (eleData) {
        makeEleActive(eleData);
      } else {
        /*  dispatch(
          addToLocatorEle({
            eleType: appConst.smallCell,
            eleData: ele,
          })
        ); */

        const lat = ele?.geometry?.coordinates?.[1];
        const lng = ele?.geometry?.coordinates?.[0];
        let activeEle = {
          geometry: {
            lat,
            lng,
          },
          properties: {
            id: ele?.siteId,
            type: appConst.smallCell,
            data: ele,
          },
        };
        dispatch(setMapCenter({ lat, lng }));
        makeEleActive(activeEle);
      }
    }
  };

  return (
    <div className="renSearchCont">
      <section>
        <input
          type="text"
          value={smallCell}
          placeholder="Enter Small Cell site name"
          onChange={(val: any) => {
            setSmallCell(val.target.value);
          }}
          onKeyPress={(event: any) => handleKeyPress(event)}
        />
        <button onClick={() => searchSmallCell()}>
          <FaSearch />
        </button>
      </section>
      <div className="searchResult">
        <DiagContainer
          isLoading={smallCellLoading}
          data={smallCellData}
          error={smallCellError}
          sectionName={"SmallCell Search"}
        >
          <Divider />
          {smallCellData?.length > 0 ? (
            <SimpleDataTable
              headers={smallCellConfig}
              tBody={smallCellData?.map((ele: any, i: number) => {
                if (ele) {
                  return (
                    <tr key={i}>
                      <td
                        className="rensNID"
                        onClick={() => handleSmallCellClick(ele)}
                      >
                        {ele?.siteId}
                      </td>
                      <td>{ele?.siteType}</td>
                      <td>{ele?.designation}</td>
                    </tr>
                  );
                }
              })}
            />
          ) : (
            <ExceptionBox headerText={labels.noDataFound} />
          )}
        </DiagContainer>
      </div>
    </div>
  );
};
export default SmallCellSearch;
