import { createSlice } from "@reduxjs/toolkit";
import { changeTimeFormat } from "../../../../../components/reusable/Util";
import appConst from "../../../../constants/appConst";
import {
  convertStreamDataToJson,
  isCoordsAvaileable,
  isNumeric,
  mergeDatasets,
} from "../../../../util/utilFuncs";
import { TELE_ABORT_KEY } from "../../../middleware/abortKeys";
import { apiCallBegan } from "../../../middleware/apiActions";
import { abortRequest, addAbortKey } from "../../../middleware/apiClient";
import {
  NETWORK_SERVICE_URL,
  NODE_BASIC_MODEM_LIST,
  TELE_SUMMARY_BASIC_MULTI_NODE,
} from "../../../middleware/apiEndpoints";
import { DiagExecutionType } from "../../../reduxTypes";
import storeConst from "../../../storeConst";
import { decrApiCall, incrApiCall, setMapCenter } from "../mapDetails";

let telemetryData: any = [];
const batchSize = (window as any).config?.TELEMETRY_BATCH_SIZE ?? 5;
const slice = createSlice({
  name: "NodeBasictelemetry",
  initialState: {
    mlIsLoading: false, //ml = modemsList
    mlData: undefined,
    mlError: undefined,
    mlStatus: "",
    nodeId: undefined,
    advFCond: appConst.or.toUpperCase(),
    activeProfile: appConst.default.toUpperCase(),
    advancedFilters: undefined,
    allDiagIsLoading: false,
    isDevicesVisible: false,
    isAborted: false,
    apiCallCounter: 0, //used for stop telemetry button
    diagStartTime: undefined,
    diagEndTime: undefined,
    diagType: storeConst.all as DiagExecutionType, //diagnostics type, eg (all, summary)
    viewType: storeConst.all,
    allDiagStatus: "",
    allDiagError: undefined,
    modemType: "",
    isCWEnabled: true,
    isNeighbourhood: false,
    neighbourMacId: undefined,
  },
  reducers: {
    getBasicModemsListBegin: (tl) => {
      tl.mlIsLoading = true;
      tl.apiCallCounter++;
      tl.mlStatus = "";
      tl.isAborted = false;
      tl.diagStartTime = undefined;
      tl.diagEndTime = undefined;
      tl.allDiagStatus = "";
    },
    getBasicModemsListSuccess: (tl, { payload }) => {
      tl.mlIsLoading = false;
      tl.apiCallCounter--;
      tl.mlData = payload.data;
      tl.allDiagError = undefined;
      tl.mlStatus = storeConst.OK;
      if (
        payload?.diagnoseDevices &&
        (tl?.modemType?.toUpperCase() !== "CM" ||
          (tl.neighbourMacId && tl?.modemType?.toUpperCase() === "CM"))
      ) {
        tl.allDiagIsLoading = true; // initiate diagnostics execution
      }
    },
    getBasicModemsListFailure: (tl, { payload }) => {
      tl.mlIsLoading = false;
      tl.apiCallCounter--;
      tl.mlError = payload.data;
      tl.mlData = undefined;
      tl.mlStatus = storeConst.ERROR;
    },
    setNodeId: (tl, { payload }) => {
      tl.nodeId = payload;
    },
    setNeighbourMacId: (tl, { payload }) => {
      tl.neighbourMacId = payload;
    },
    getStreamBasicDataBegin: (tl) => {
      let startTime: any = changeTimeFormat(Date.now());
      tl.apiCallCounter++;
      tl.allDiagStatus = "";
      tl.allDiagError = undefined;
      tl.diagStartTime = startTime;
    },
    getStreamBasicDataSuccess: (tl, { payload }) => {
      let currMlData: any = JSON.parse(JSON.stringify(tl.mlData));

      let dataChunk = convertStreamDataToJson(payload);

      if (dataChunk?.length > 0 && currMlData?.length > 0) {
        let combinedData = mergeDatasets(currMlData, dataChunk);

        tl.mlData = combinedData;
        if (currMlData?.length === dataChunk?.length && combinedData) {
          let endTime: any = changeTimeFormat(Date.now());
          tl.allDiagIsLoading = false;
          tl.apiCallCounter--;
          tl.diagEndTime = endTime;
          tl.mlData = combinedData;
          tl.allDiagStatus = storeConst.OK;
          tl.allDiagError = undefined;
        }
      }
    },
    getStreamBasicDataFailure: (tl, { payload }) => {
      let endTime: any = changeTimeFormat(Date.now());
      tl.allDiagIsLoading = false;
      tl.apiCallCounter--;
      tl.diagEndTime = endTime;
      tl.allDiagStatus = storeConst.ERROR;
      tl.allDiagError = payload;
    },
    setDiagType: (tl, { payload }) => {
      tl.diagType = payload; //set to the type of diagnostics you want to run. eg (all, summary)
    },
    setViewType: (tl, { payload }) => {
      tl.viewType = payload;
    },
    setModemType: (tl, { payload }) => {
      tl.modemType = payload;
    },
    setAllDiagLoading: (tl, { payload }) => {
      let currMlData: any = JSON.parse(JSON.stringify(tl.mlData));
      tl.allDiagIsLoading = payload;
      if (!payload && telemetryData?.length > 0) {
        tl.mlData = mergeDatasets(currMlData, telemetryData);
        telemetryData = [];
      }
    },
    setDevicesVisible: (tl, { payload }) => {
      tl.isDevicesVisible = payload;
    },
    setAdvancedFilters: (tl, { payload }) => {
      tl.advancedFilters = payload;
    },
    setAdvFCond: (tl, { payload }) => {
      tl.advFCond = payload;
    },
    setSelectedProfile: (tl, { payload }) => {
      tl.activeProfile = payload;
    },
    setAborted: (tl, { payload }) => {
      let endTime: any = changeTimeFormat(Date.now());
      tl.isAborted = payload;
      tl.diagEndTime = endTime;
    },
    setIsCWEnabled: (tl, { payload }) => {
      tl.isCWEnabled = payload;
    },
    setIsNeighbourhood: (tl, { payload }) => {
      tl.isNeighbourhood = payload;
    },
  },
});

export const {
  setDiagType,
  setAllDiagLoading,
  setDevicesVisible,
  setAborted,
  setAdvFCond,
  setSelectedProfile,
  setAdvancedFilters,
  setViewType,
  setModemType,
  setIsCWEnabled,
  setIsNeighbourhood,
  setNodeId,
  setNeighbourMacId,
} = slice.actions;

const {
  getBasicModemsListBegin,
  getBasicModemsListSuccess,
  getBasicModemsListFailure,
  getStreamBasicDataBegin,
  getStreamBasicDataSuccess,
  getStreamBasicDataFailure,
} = slice.actions; //should not be exposed to outside
export default slice.reducer;

interface ModemsListType {
  value: string;
  type: "shubid" | "smtid" | "cmmac";
  diagnoseDevices?: boolean;
  modemType?: string;
}
interface DiagRequestType {
  macList: any;
  nodeType?: string;
  modemType?: string;
  isCWEnabled?: boolean;
  isNeighbourhood?: boolean;
}
export const getTeleBasicModemsList =
  ({ value, type, diagnoseDevices = false, modemType }: ModemsListType) =>
  (dispatch: any) => {
    stopDiagExecution(); //if diagnostics execution is in progress then stop it
    if (value && value?.length === 6 && isNumeric(value)) {
      dispatch(setNodeId(value));
    } else {
      dispatch(setNeighbourMacId(value));
    }

    addAbortKey(TELE_ABORT_KEY); // add abort key to abort calls when needed
    dispatch(
      apiCallBegan({
        baseURL: NETWORK_SERVICE_URL,
        url: NODE_BASIC_MODEM_LIST,
        params: { [type]: value, modemType: modemType },
        method: "post",
        props: { diagnoseDevices },
        onStart: getBasicModemsListBegin.type,
        onSuccess: getBasicModemsListSuccess.type,
        onError: getBasicModemsListFailure.type,
        onStartDispatch: incrApiCall.type,
        onSuccessDispatch: decrApiCall.type,
        onErrorDispatch: decrApiCall.type,
      })
    );
  };

export const getStreamBasicData =
  ({
    macList,
    nodeType,
    modemType,
    isCWEnabled,
    isNeighbourhood,
  }: DiagRequestType) =>
  (dispatch: any, getState: any) => {
    const { diagType, mlData } = getState().slices.NodeBasictelemetry;
    let getFirstCoords = mlData?.filter((cm: any) => {
      if (isCoordsAvaileable(cm)) {
        return cm;
      }
    });
    dispatch(setMapCenter(getFirstCoords?.[0]?.coordinate));
    const diagUrl = TELE_SUMMARY_BASIC_MULTI_NODE;
    dispatch(
      apiCallBegan({
        baseURL: NETWORK_SERVICE_URL,
        url: diagUrl,
        method: "post",
        data: JSON.stringify({
          macList: macList,
          nodeType: nodeType,
          modemType: modemType,
          isCWEnabled: isCWEnabled,
          isNeighbourhood: isNeighbourhood,
        }),
        responseType: "stream",
        abortKey: TELE_ABORT_KEY,
        onStart: getStreamBasicDataBegin.type,
        onSuccess: getStreamBasicDataSuccess.type,
        onError: getStreamBasicDataFailure.type,
      })
    );
  };

export const stopDiagExecution = () => (dispatch: any) => {
  dispatch(setAllDiagLoading(false));
  dispatch(setAborted(true));
  abortRequest(TELE_ABORT_KEY);
};
