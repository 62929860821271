import { RHPDocsisEventsState } from "../../../../states/account/diagnostics/ihp/RHPDocsisEventsState";
import {
  RHP_DOCSIS_EVENT_INFO_BEGIN,
  RHP_DOCSIS_EVENT_INFO_SUCCESS,
  RHP_DOCSIS_EVENT_INFO_FAILURE,
  RHPDocsisEventInfoActionTypes,
} from "../../../../types/account/diagnostics/ihp/RHPDocsisEvent";

const initialState: RHPDocsisEventsState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function RHPDocsisEventsReducer(
  state = initialState,
  action: RHPDocsisEventInfoActionTypes
): RHPDocsisEventsState {
  switch (action.type) {
    case RHP_DOCSIS_EVENT_INFO_BEGIN:
      return {
        isLoading: true,
      };
    case RHP_DOCSIS_EVENT_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case RHP_DOCSIS_EVENT_INFO_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };
    default:
      return state;
  }
}
