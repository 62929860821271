import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import {
    isNull,
    getCheckValue,
    getDelta,
    isEmptyObject,
    getStatusOfCheckHeader,
    popOverCell,
} from "../../reusable/Util";
import { useState } from "react";
import AppLabels from "../../../constants/App_Labels";
interface Props {
    docsis30UsChannels: any;
    preDocsis30UsChannels?: any;
}

const DocsisUSPrechChData = ({
    docsis30UsChannels,
    preDocsis30UsChannels,
}: Props) => {
    const labels = AppLabels();
    const [summaryAcc, setSummaryAcc] = useState(true);
    const [detailsAcc, setDetailsAcc] = useState(false);
    const [preAcc, setPreAcc] = useState(false);

    const handleAccToggle = (accName: string) => {
        if (accName === "summary") setSummaryAcc(!summaryAcc);
        else if (accName === "details") setDetailsAcc(!detailsAcc);
        else if (accName === "precheck details") setPreAcc(!preAcc);
    };

    return (
        <div className="ofdmChannelCont">
            <div className="ofdmChSummary">
                <div className="dsChDataAcc" onClick={() => handleAccToggle("summary")}>
                    <p>{labels.SUMMARY.toUpperCase()}</p>
                    {summaryAcc ? (
                        <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
                    ) : (
                        <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
                    )}
                </div>
                {summaryAcc ? (
                    <table className="dataTableDetails">
                        <thead>
                            <tr>
                                <th id="nameCol">{labels.NAME.toUpperCase()}</th>
                                <th id="preValCol">{labels.PRECHECK_VALUE.toUpperCase()}</th>
                                <th id="curValCol">{labels.CURRENT_VALUE.toUpperCase()}</th>
                                <th id="diffCol">{labels.DIFFERENCE.toUpperCase()}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {" "}
                                    {getStatusOfCheckHeader(
                                        docsis30UsChannels?.maxTxPower,
                                        "UP TX MAX"
                                    )}
                                </td>
                                {docsis30UsChannels?.hasOwnProperty("maxTxPower") ? (
                                    <>
                                        <td>
                                            {preDocsis30UsChannels?.maxTxPower !== undefined ?
                                                popOverCell(preDocsis30UsChannels?.maxTxPower) : "N/A"
                                            }
                                        </td>
                                        <td>
                                            {popOverCell(docsis30UsChannels?.maxTxPower)}
                                        </td>
                                        <td>
                                            {getDelta(
                                                docsis30UsChannels?.maxTxPower?.value,
                                                preDocsis30UsChannels?.maxTxPower?.value
                                            )}
                                        </td>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </tr>
                            <tr>
                                <td>
                                    {" "}
                                    {getStatusOfCheckHeader(
                                        docsis30UsChannels?.minTxPower,
                                        "UP TX MIN"
                                    )}
                                </td>
                                {docsis30UsChannels?.hasOwnProperty("minTxPower") ? (
                                    <>
                                        <td>
                                            {preDocsis30UsChannels?.minTxPower !== undefined ?
                                                popOverCell(preDocsis30UsChannels?.minTxPower) : "N/A"
                                            }
                                        </td>
                                        <td>
                                            {popOverCell(docsis30UsChannels?.minTxPower)}
                                        </td>
                                        <td>
                                            {getDelta(
                                                docsis30UsChannels?.minTxPower?.value,
                                                preDocsis30UsChannels?.minTxPower?.value
                                            )}
                                        </td>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </tr>
                            <tr>
                                <td>
                                    {" "}
                                    {getStatusOfCheckHeader(
                                        docsis30UsChannels?.txPowerDelta,
                                        "UP TX DELTA"
                                    )}
                                </td>
                                <td>{isNull(preDocsis30UsChannels?.txPowerDelta) !== "" ?
                                    isNull(preDocsis30UsChannels?.txPowerDelta) : "N/A"}</td>
                                <td>{isNull(docsis30UsChannels?.txPowerDelta)}</td>
                                <td>
                                    {getDelta(
                                        docsis30UsChannels?.txPowerDelta,
                                        preDocsis30UsChannels?.txPowerDelta
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {" "}
                                    {getStatusOfCheckHeader(
                                        docsis30UsChannels?.averageTxPower,
                                        "UP TX AVG"
                                    )}
                                </td>
                                {docsis30UsChannels?.hasOwnProperty("averageTxPower") ? (
                                    <>
                                        <td>
                                            {preDocsis30UsChannels?.averageTxPower !== undefined ?
                                                popOverCell(preDocsis30UsChannels?.averageTxPower) : "N/A"
                                            }
                                        </td>
                                        <td>
                                            {popOverCell(docsis30UsChannels?.averageTxPower)}
                                        </td>
                                        <td>
                                            {getDelta(
                                                docsis30UsChannels?.averageTxPower?.value,
                                                preDocsis30UsChannels?.averageTxPower?.value
                                            )}
                                        </td>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </tr>
                            <tr>
                                <td>{labels.MODULATION.toUpperCase()}</td>
                                <td>{isNull(preDocsis30UsChannels?.modulation) !== "" ?
                                    isNull(preDocsis30UsChannels?.modulation) : "N/A"}</td>
                                <td>{isNull(docsis30UsChannels.modulation)}</td>
                                <td>N/A</td>
                            </tr>
                        </tbody>
                    </table>
                ) : (
                    <></>
                )}
            </div>
            <div className="ofdmChDetails">
                <div className="dsChDataAcc" onClick={() => handleAccToggle("details")}>
                    <p>{labels.CHANNELS.toUpperCase()}</p>
                    {detailsAcc ? (
                        <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
                    ) : (
                        <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
                    )}
                </div>
                {detailsAcc ? (
                    <table className="dataTableDetails">
                        <thead>
                            <tr>
                            <th id="nameCol">{labels.channel.toUpperCase()}</th>
                            <th id="valueCol">{labels.node.toUpperCase()}</th>
                                <th id="valueCol">{labels.FREQUENCY}</th>
                                <th id="valueCol">{labels.TX_POWER.toUpperCase()}</th>
                                <th id="valueCol">{labels.SNR.toUpperCase()}</th>
                                <th id="valueCol">{labels.STATUS.toUpperCase()}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {docsis30UsChannels?.hasOwnProperty("channels") &&
                                docsis30UsChannels.channels?.length >= 0 ? (
                                docsis30UsChannels.channels
                                    ?.slice()
                                    .sort((a: any, b: any) =>
                                        a.frequency > b.frequency ? 1 : -1
                                    )
                                    ?.map((cb: any, index: number) => {
                                        return (
                                            <tr key={index}>
                                                	  {cb?.hasOwnProperty("name") ? (
                                                    <td>
                                                    {(isNull(isEmptyObject(cb.name)))}
                                                </td>
                                                ) : (
                                                    <td></td>
                                                )}
												<td>{isNull(docsis30UsChannels.connectedNode)}</td>
                                                <td>{isNull(cb.frequency)}</td>
                                                {cb?.hasOwnProperty("txPower") ? (
                                                    <td>
                                                        {isNull(getCheckValue(isEmptyObject(cb.txPower)))}
                                                    </td>
                                                ) : (
                                                    <td></td>
                                                )}
                                                {cb?.hasOwnProperty("snr") ? (
                                                    <td>
                                                        {isNull(getCheckValue(isEmptyObject(cb.snr)))}
                                                    </td>
                                                ) : (
                                                    <td></td>
                                                )}
                                                {cb?.hasOwnProperty("status") ? (
                                                    <td>
                                                        {isNull(getCheckValue(isEmptyObject(cb.status)))}
                                                    </td>
                                                ) : (
                                                    <td></td>
                                                )}
                                            </tr>
                                        );
                                    })
                            ) : (
                                <></>
                            )}
                        </tbody>
                    </table>
                ) : (
                    <></>
                )}
            </div>
            <div className="ofdmPreChDetails">
                <div
                    className="dsChDataAcc"
                    onClick={() => handleAccToggle("precheck details")}
                >
                    <p>{labels.PRECHECK_CHANNELS.toUpperCase()}</p>
                    {preAcc ? (
                        <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
                    ) : (
                        <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
                    )}
                </div>
                {preAcc ? (
                    <table className="dataTableDetails">
                        <thead>
                            <tr>
                            <th id="nameCol">{labels.channel.toUpperCase()}</th>
                            <th id="valueCol">{labels.node.toUpperCase()}</th>
                                <th id="valueCol">{labels.FREQUENCY}</th>
                                <th id="valueCol">{labels.TX_POWER.toUpperCase()}</th>
                                <th id="valueCol">{labels.SNR.toUpperCase()}</th>
                                <th id="valueCol">{labels.STATUS.toUpperCase()}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {preDocsis30UsChannels?.hasOwnProperty("channels") &&
                                preDocsis30UsChannels?.channels?.length >= 0 ? (
                                preDocsis30UsChannels?.channels
                                    ?.slice()
                                    .sort((a: any, b: any) =>
                                        a.frequency > b.frequency ? 1 : -1
                                    )
                                    ?.map((cb: any, index: number) => {
                                        return (
                                            <tr key={index}>
                                                {cb?.hasOwnProperty("name") ? (
                                                    <td>
                                                        {(isNull(isEmptyObject(cb.name)))}
                                                    </td>
                                                ) : (
                                                    <td></td>
                                                )}
                                                 <td>{isNull(docsis30UsChannels.connectedNode)}</td>
                                                <td>{isNull(cb.frequency)}</td>
                                                {cb?.hasOwnProperty("txPower") ? (
                                                    <td>
                                                        {isNull(getCheckValue(isEmptyObject(cb.txPower)))}
                                                    </td>
                                                ) : (
                                                    <td></td>
                                                )}
                                                {cb?.hasOwnProperty("snr") ? (
                                                    <td>
                                                        {isNull(getCheckValue(isEmptyObject(cb.snr)))}
                                                    </td>
                                                ) : (
                                                    <td></td>
                                                )}
                                                {cb?.hasOwnProperty("status") ? (
                                                    <td>
                                                        {isNull(getCheckValue(isEmptyObject(cb.status)))}
                                                    </td>
                                                ) : (
                                                    <td></td>
                                                )}
                                            </tr>
                                        );
                                    })
                            ) : (
                                <></>
                            )}
                        </tbody>
                    </table>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default DocsisUSPrechChData;
