import { DiagDevice } from "../../../models/account/diagnostics/DiagDevice";
import { ActiveDiagDevicesState } from "../../../states/account/diagnostics/ActiveDiagDevicesState";
import {
  ActiveDiagDevicesActionTypes,
  RESET_ACTIVE_DIAG_FAILURE,
  SAVE_ACTIVE_DIAG_DEVICES,
  UPDATE_ACTIVE_DIAG_DEVICES,
  START_LOADING_DIAG_DEVICES,
  DECREMENT_DIAG_API_CALL_COUNTER,
  INCREMENT_DIAG_API_CALL_COUNTER,
} from "../../../types/account/diagnostics/ActiveDiagDevices";

const initialState: ActiveDiagDevicesState = {
  diagDevices: [],
};

export function ActiveDiagDeviceReducer(
  state = initialState,
  action: ActiveDiagDevicesActionTypes
): ActiveDiagDevicesState {
  switch (action.type) {
    case START_LOADING_DIAG_DEVICES:
      return {
        ...state,
        diagDevices: state.diagDevices.map((d: DiagDevice) => {
          return { ...d, ...{ isLoading: true } };
        }),
      };
    case SAVE_ACTIVE_DIAG_DEVICES:
      return {
        ...state,
        diagDevices: action.payload,
      };
    case INCREMENT_DIAG_API_CALL_COUNTER:
      return {
        ...state,
        diagDevices: state.diagDevices.map((d: DiagDevice) => {
          if (d.deviceKey === action.devicekey) {
            let apiCalls = d.inProgressCalls + 1;
            let tempLoading = d.isLoading;
            if (apiCalls > 0) {
              tempLoading = true;
            }
            return {
              ...d,
              ...{ inProgressCalls: apiCalls, isLoading: tempLoading },
            };
          } else return d;
        }),
      };
    case DECREMENT_DIAG_API_CALL_COUNTER:
      return {
        ...state,
        diagDevices: state.diagDevices.map((d: DiagDevice) => {
          if (d.deviceKey === action.devicekey) {
            let apiCalls = d.inProgressCalls - 1;
            let tempLoading = d.isLoading;
            if (apiCalls === 0) {
              tempLoading = false;
            }
            return {
              ...d,
              ...{ inProgressCalls: apiCalls, isLoading: tempLoading },
            };
          } else return d;
        }),
      };
    case UPDATE_ACTIVE_DIAG_DEVICES:
      return {
        ...state,
        diagDevices: state.diagDevices.map((d: DiagDevice) => {
          if (d.deviceKey === action.devicekey) {
            if (d.data !== undefined) {
              let oldData = d.data;
              let newData = { [action.subdataKey]: action.data };
              let updatedData = { ...oldData, ...newData };
              return { ...d, ...{ data: updatedData } };
            } else {
              let newData = { [action.subdataKey]: action.data };
              return { ...d, ...{ data: newData } };
            }
          } else {
            return d;
          }
        }),
      };
    case RESET_ACTIVE_DIAG_FAILURE:
      return {
        ...state,
        diagDevices: [],
      };
    default:
      return state;
  }
}
