import { createSlice } from "@reduxjs/toolkit";
import { StandardReduxType } from "../../topology/store/reduxTypes";

interface ITechData {
  techId: string;
  lanId: string;
  roles: string[];
  supervisorsEmail: string;
  [key: string]: any;
}
export interface ITechProfile {
  isLoading: boolean;
  data?: ITechData;
  error: any;
  status: StandardReduxType["status"];
  activeWorkType: string;
  activeWorkOrder: string;
  activeorderCategory: string;
}

const slice = createSlice({
  name: "techProfile",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
    activeWorkType: "",
    activeWorkOrder: "",
    activeorderCategory: "",
  } as ITechProfile,
  reducers: {
    // action => action handler
    techProfileBegin: (profile) => {
      profile.isLoading = true;
    },
    techProfileSuccess: (profile, action) => {
      profile.isLoading = false;
      profile.data = action.payload;
      profile.status = "OK";
    },
    techProfileFailure: (profile, action) => {
      profile.isLoading = false;
      profile.error = action.payload;
      profile.data = undefined;
      profile.status = "ERROR";
    },
    SetWorkType: (profile, action) => {
      profile.activeWorkType = action.payload;
    },
    SetWorkOrderId: (profile, action) => {
      profile.activeWorkOrder = action.payload;
    },
    SetOrderCategroy: (profile, action) => {
      profile.activeorderCategory = action.payload;
    },
  },
});

export const {
  techProfileBegin,
  techProfileSuccess,
  techProfileFailure,
  SetWorkType,
  SetWorkOrderId,
  SetOrderCategroy,
} = slice.actions;
export default slice.reducer;
