import { createSlice } from "@reduxjs/toolkit";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "ontSummary",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    // action => action handler
    ontSummaryBegin: (ontSummary) => {
        ontSummary.isLoading = true;
    },
    ontSummarySuccess: (ontSummary, action) => {
        ontSummary.isLoading = false;
      ontSummary.data = action.payload;
      ontSummary.status = storeConst.OK;
    },
    ontSummaryFailure: (ontSummary, action) => {
        ontSummary.isLoading = false;
        ontSummary.error = action.payload;
        ontSummary.data = undefined;
        ontSummary.status = storeConst.ERROR;
    },
    
    resetOntSummary: (ontSummary) => {
        ontSummary.isLoading = false;
      ontSummary.error = undefined;
      ontSummary.data = undefined;
      ontSummary.status = "";
    },
  },
});

export const {
  ontSummaryBegin,
  ontSummarySuccess,
  ontSummaryFailure,
  resetOntSummary,
} = slice.actions;

export default slice.reducer;