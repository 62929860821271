import { formatBitsToSize } from "../reusable/Util";
import { isNull } from "../reusable/Util";
import LoaderGif from "../../resources/images/loader.webp";
import AppLabels from "../../constants/App_Labels";
import CustomAccordion from "../reusable/CustomAccordion";

const ONTBandwidth = (props: any) => {
  const { selectedSA, bandwidthLoading, bandwidthInfo } = props;
  const labels = AppLabels();
  let hsiPlanInfo: any = [];
  selectedSA?.subscription?.map((sub: any) => {
    if (
      sub?.hasOwnProperty("planInfoList") &&
      sub.planInfoList?.hasOwnProperty("planInfo")
    ) {
      sub.planInfoList.planInfo?.map((pInfo: any) => {
        if (pInfo.productType === "HSI" || pInfo.productType === "I")
          hsiPlanInfo = pInfo;
      });
    }
  });

  return (
    <CustomAccordion
      accTitle={labels.INTERNET_SPEED_INFORMATION.toUpperCase()}
      accBodyElement={
        <>
          <table className="dataTableDetails">
            <thead>
              <tr>
                <th id="nameCol">{labels.NAME.toUpperCase()}</th>
                <th id="valueCol">{labels.VALUE.toUpperCase()}</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>{labels.SUBSCRIBER_DOWNSTREAM_BITRATE.toUpperCase()}</td>
                <td>
                  {hsiPlanInfo?.hasOwnProperty("orderedDownstream") ? (
                    isNull(
                      formatBitsToSize(hsiPlanInfo?.orderedDownstream, "mb")
                    )
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
              <tr>
                <td>{labels.SUBSCRIBER_UPSTREAM_BITRATE.toUpperCase()}</td>
                <td>
                  {hsiPlanInfo?.hasOwnProperty("orderedUpstream") ? (
                    isNull(formatBitsToSize(hsiPlanInfo?.orderedUpstream, "mb"))
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
              {!bandwidthLoading ? (
                bandwidthInfo !== undefined ? (
                  <>
                    <tr>
                      <td>
                        {labels.PROVISIONED_DOWNSTREAM_SPEED.toUpperCase()}
                      </td>
                      <td>
                        {isNull(formatBitsToSize(bandwidthInfo?.downBand))}
                      </td>
                    </tr>
                    <tr>
                      <td>{labels.PROVISIONED_UPSTREAM_SPEED.toUpperCase()}</td>
                      <td>{isNull(formatBitsToSize(bandwidthInfo?.upBand))}</td>
                    </tr>
                  </>
                ) : (
                  <tr className="inlineError">
                    <td className="inlineError" colSpan={2}>
                      Data is not Available
                    </td>
                  </tr>
                )
              ) : (
                <img
                  className="loaderImg"
                  style={{ height: "30px" }}
                  src={LoaderGif}
                />
              )}
            </tbody>
          </table>
        </>
      }
    />
  );
};

export default ONTBandwidth;
