import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { PodState } from "../../../store/states/account/diagnostics/pods/PodState";
import {
  podsBegin,
  podsFailure,
  podsSuccess,
} from "../../../store/actions/account/diagnostics/pods/PodAction";
import { POD } from "../../../store/models/account/diagnostics/pods/POD";
export default class PodsClient {
  static getPods =
    (w3Id: string): ThunkAction<void, PodState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/rpil/pods/" + w3Id,
      };
      dispatch(podsBegin());
      HttpClient.get<POD>(httpParameters)
        .then((pods) => {
          dispatch(podsSuccess(pods));
          return pods;
        })
        .catch((error) => {
          dispatch(podsFailure(error));
          return error;
        });
    };
}
