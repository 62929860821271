import { DeviceActionState } from "../../../states/account/deviceActions/DeviceActionState";

import {
  REBOOT_BEGIN,
  REBOOT_SUCCESS,
  REBOOT_FAILURE,
  REBOOT_RESET_STATUS,
  RebootActionTypes,
} from "../../../types/account/deviceActions/Reboot";

const initialState: DeviceActionState = {
  isLoading: false,
  error: undefined,
  status: "",
};

export function RebootReducer(
  state = initialState,
  action: RebootActionTypes
): DeviceActionState {
  switch (action.type) {
    case REBOOT_BEGIN:
      return {
        isLoading: true,
      };
    case REBOOT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: "OK",
      };
    case REBOOT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        status: "ERROR",
      };
    case REBOOT_RESET_STATUS:
      return {
        ...state,
        status: "",
      };
    default:
      return state;
  }
}
