import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { DeviceActionState } from "../../../store/states/account/deviceActions/DeviceActionState";

import {
  rebootBegin,
  rebootSuccess,
  rebootFailure,
} from "../../../store/actions/account/deviceActions/RebootAction";
import {
  factoryResetBegin,
  factoryResetSuccess,
  factoryResetFailure,
} from "../../../store/actions/account/deviceActions/FactoryResetAction";
import {
  zapBegin,
  zapFailure,
  zapSuccess,
} from "../../../store/actions/account/deviceActions/ZapAction";
import {
  hardzapBegin,
  hardzapFailure,
  hardzapSuccess,
} from "../../../store/actions/account/deviceActions/HardZapAction";
import {
  getOUIChecked,
  getOUIW3IDChecked,
} from "../../../components/reusable/Util";
import {
  AddZapSTBRequest,
  UpdateZapSTBRequest,
} from "../../../store/actions/account/deviceActions/ZapAllSTBAction";

interface DeviceInfo {
  oui?: string;
  macAddress?: string;
  serialNum?: string;
}

interface RebootConfig {
  rebootURL: string;
  qParams: any;
}

const getRebootConfig = (
  deviceType: string,
  deviceInfo: DeviceInfo,
  hhid: string
): RebootConfig => {
  let rebootByMAC = ["CM","GW", "EMTA", "IEMTA", "DESTB", "STB"];
  let rebootBySN = ["IPTV", "ODU", "ONT"];
  let rebootPayload: RebootConfig = { rebootURL: "", qParams: [] };
  if (rebootByMAC?.indexOf(deviceType) > -1) {
    if (deviceType === "EMTA" || deviceType === "IEMTA" || deviceType === "CM") {
      rebootPayload.rebootURL =
        "diag/cablemodem/" + deviceInfo.macAddress + "/reboot";
    } else if (deviceType === "DESTB") {
      rebootPayload.rebootURL = "diag/stb/" + deviceInfo.macAddress + "/reboot";
      rebootPayload.qParams.push({ stbType: "DESTB" });
    } else if (deviceType === "STB") {
      rebootPayload.rebootURL =
        "diag/stb/legacy/" + deviceInfo.macAddress + "/reboot";
      rebootPayload.qParams.push({ stbType: "STB" });
    } else {
      rebootPayload.rebootURL = "diag/gateway/reboot";
      rebootPayload.qParams.push(
        getOUIW3IDChecked(deviceInfo.serialNum!, hhid, deviceInfo.macAddress!)
      );
    }
    return rebootPayload;
  } else if (rebootBySN?.indexOf(deviceType) > -1) {
    if(deviceType?.toLowerCase() ==="ont"){
      rebootPayload.rebootURL = `diag/${deviceType?.toLowerCase()}/reboot`;
      
        rebootPayload.qParams.push({
          serialNumber: deviceInfo.serialNum,
        });
      
    
      return rebootPayload;
    }
else{
    rebootPayload.rebootURL = `api/diag/${deviceType?.toLowerCase()}/reboot`;
    if (
      deviceInfo.oui !== undefined &&
      deviceInfo.oui !== null &&
      deviceInfo.oui !== ""
    ) {
      rebootPayload.qParams.push({
        oui: deviceInfo.oui,
        serialNumber: deviceInfo.serialNum,
      });
    } else {
      rebootPayload.qParams.push({
        serialNumber: deviceInfo.serialNum,
      });
    }
    return rebootPayload;
  }
}
  return rebootPayload;
};

export default class DeviceActionClient {
  static rebootDevice =
    (
      deviceType: string,
      deviceInfo: DeviceInfo,
      hhid: string
    ): ThunkAction<void, DeviceActionState, unknown, Action<string>> =>
    async (dispatch) => {
      let rebootConfig = getRebootConfig(deviceType, deviceInfo, hhid);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "" + rebootConfig.rebootURL,
        params: rebootConfig.qParams?.[0],
      };
      dispatch(rebootBegin());

      HttpClient.post<any>(httpParameters, null)
        .then((reboot) => {
          dispatch(rebootSuccess());
          return reboot;
        })
        .catch((error) => {
          dispatch(rebootFailure(error));
          return error;
        });
    };

  static factoryResetDevice =
    (
      macAddress: string,
      serialNum: string,
      hhid: string
    ): ThunkAction<void, DeviceActionState, unknown, Action<string>> =>
    async (dispatch) => {
      let qParam = getOUIW3IDChecked(serialNum, hhid, macAddress);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/gateway/factoryreset",
        params: qParam,
      };

      dispatch(factoryResetBegin());

      HttpClient.post<any>(httpParameters, null)
        .then((factoryReset) => {
          dispatch(factoryResetSuccess());
          return factoryReset;
        })
        .catch((error) => {
          dispatch(factoryResetFailure(error));
          return error;
        });
    };

  static zapDevice =
    (
      deviceType: string,
      deviceInfo: any,
      accountNumber: string
    ): ThunkAction<void, DeviceActionState, unknown, Action<string>> =>
    async (dispatch) => {
      let zapURL = "";
      let stbDevices = ["STB", "DESTB", "GSTB", "HSTB", "IPSTB", "ASTB"];
      if (deviceType === "IPTV") zapURL = "diag/iptv/zap";
      else if (deviceType === "ONT") zapURL = "diag/ont/softzap";
      else if (stbDevices?.indexOf(deviceType) > -1) zapURL = "diag/stb/zap";
      let httpParameters: IHttpClientRequestParameters<any>;
      if (stbDevices?.indexOf(deviceType) > -1) {
        httpParameters = {
          url: NetworkConfig.ACCOUNT_SERVICE_URL + zapURL,
          params: {
            accountNumber: accountNumber,
            addressKey: deviceInfo?.addressKey,
            serialNumber: deviceInfo?.itemNumber !==undefined? deviceInfo?.itemNumber + deviceInfo?.serialNumber:
            deviceInfo?.serialNumber,
          },
        };
      } else {
        httpParameters = {
          url: NetworkConfig.ACCOUNT_SERVICE_URL + zapURL,
          params: { serialNumber: deviceInfo.serialNumber },
        };
      }

      dispatch(zapBegin());

      HttpClient.post<any>(httpParameters, null)
        .then((zapDevice) => {
          dispatch(zapSuccess());
          return zapDevice;
        })
        .catch((error) => {
          dispatch(zapFailure(error));
          return error;
        });
    };

  static zapSTB =
    (
      accountNumber: string,
      deviceInfo: any
    ): ThunkAction<void, DeviceActionState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any>;
      httpParameters = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/stb/zap",
        params: {
          accountNumber: accountNumber,
          addressKey: deviceInfo?.addressKey,
          serialNumber: 
          deviceInfo?.itemNumber !==undefined? deviceInfo?.itemNumber + deviceInfo?.serialNumber:
            deviceInfo?.serialNumber,
        },
      };

      dispatch(AddZapSTBRequest(deviceInfo?.serialNumber));

      HttpClient.post<any>(httpParameters, null)
        .then((zapDevice) => {
          dispatch(UpdateZapSTBRequest(deviceInfo?.serialNumber, "pass"));
          return zapDevice;
        })
        .catch((error) => {
          dispatch(UpdateZapSTBRequest(deviceInfo?.serialNumber, "fail"));
          return error;
        });
    };

    
 static hardzapDevice =
 (
   deviceType: string,
   deviceInfo: any,
   accountNumber: string
 ): ThunkAction<void, DeviceActionState, unknown, Action<string>> =>
 async (dispatch) => {
   let zapURL = "";
   let httpParameters: IHttpClientRequestParameters<any>;
     zapURL = "diag/network/hardZap/MaestroDevice";
     httpParameters = {
       url:  NetworkConfig.NETWORK_SERVICE_URL + zapURL,
       params: {
         serialNumber: deviceInfo.serialNumber,
        
       },
     };
  
   dispatch(hardzapBegin());
   HttpClient.post<any>(httpParameters, null)
     .then((zapDevice) => {
       dispatch(hardzapSuccess());
       return zapDevice;
     })
     .catch((error) => {
       dispatch(hardzapFailure(error));
       return error;
     });
 };

}
