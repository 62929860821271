export const SAVE_CONFIG_DATA = "SAVE_CONFIG_DATA";
export const UPDATE_CONFIG_DATA = "UPDATE_CONFIG_DATA";
export const UPDATE_CONFIG_PINS = "UPDATE_CONFIG_PINS";

interface SaveConfigData {
  type: typeof SAVE_CONFIG_DATA;
  payload: any;
}

interface UpdateConfigData {
  type: typeof UPDATE_CONFIG_DATA;
  viewType: string;
  payload: any;
}

interface UpdateConfigPins {
  type: typeof UPDATE_CONFIG_PINS;
  viewType: string;
  payload: any;
}
export type UserPrefConfigActionTypes =
  | SaveConfigData
  | UpdateConfigData
  | UpdateConfigPins;
