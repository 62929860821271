export const nbaData = [
  {
    ParentAttribute: "Docsis3.0",
    ChildAttribute: "DownStream",
    Channel: "DS_DOCSIS3.0",
    SubAttribute: "Rx Power",
    State: "Marginal Pass",
    PreCheckAction: [
      "Receive Power (RX) is the measurement of the forward signal that is sent from the Rogers Headend to the modem",
      "Most Downstream impairements are caused by an increase in resistance. You will likely be looking for some type of short or wet cables. A wet cable is easily identified by the cable showing a powdery substance and blackened centre pin.  It may also be cause by length of drop where the loss causes a low RX Power although the cable is not impaired",
      "Using your TDR and Ohm meter, please ensure that both the Drop and Riser are in good condition",
      "Ensure that your drop loss is appropriate for your drop's length and cable type. ",
      "Using your Signal Level Meter, please ensure that your Forward signal is as per design at the Tap, CSE/POE, and your modem.",
    ],
  },
  {
    ParentAttribute: "Docsis 3.0",
    ChildAttribute: "DownStream",
    Channel: "DS_DOCSIS3.0",
    SubAttribute: "Rx Power",
    State: "Fail",
    PreCheckAction: [
      "Most Downstream impairments are caused by an increase in resistance. You will likely be looking for some type of short.",
      "Using your TDR and Ohm meter, please ensure that both the Drop and Riser are in good condition.",
      "Ensure that your drop loss is appropriate for your drop's length and cable type. ",
      "Using your Signal Level Meter, please ensure that your Forward signal is as per design at the Tap, CSE/POE, and your modem.",
    ],
  },
  {
    ParentAttribute: "Docsis 3.0",
    ChildAttribute: "DownStream",
    Channel: "DS_DOCSIS3.0",
    SubAttribute: "Snr",
    State: "Fail",
    PreCheckAction: [
      "Signal to Noise is a ratio between the amount of Downstream Signal Power and Noise Power and is generally impacted by some sort of impairment that impacts that ratio.",
      "Most Downstream impairments are caused by an increase in resistance. You will likely be looking for some type of short or over amplification (EG unnecessary drop amp). SNR impairments are not caused by cable length alone",
      "Using your TDR and Ohm meter, please ensure that both the Drop and Riser are in good condition",
      "Ensure that your drop loss is appropriate for your drop's length and cable type. ",
      "Using your Signal Level Meter, please ensure that your Forward signal is as per design at the Tap, CSE/POE, and your modem.",
    ],
  },
  {
    ParentAttribute: "Docsis 3.0",
    ChildAttribute: "DownStream",
    Channel: "DOWNSTREAM_SCQAM",
    SubAttribute: "Uncorrectables",
    State: "Fail",
    PreCheckAction: [
      "An uncorrectable codeword is a corrupted codeword that the Gateway and CMTS are unable to repair themselves. They can also show up as pre or post errors on your meter.",
      "Using your meter, tune into the Frequency that was identified as failing by Tech Insight, and track down where the post-BER begins.",
      "Using your TDR and Ohm meter, please ensure that both the Drop and Riser are in good condition",
      "Ensure that your drop loss is appropriate for your drop's length and cable type. ",
      "Using your Signal Level Meter, please ensure that your signal is as per design at the Tap, CSE/POE, and your modem.",
    ],
  },
  {
    ParentAttribute: "Docsis 3.0",
    ChildAttribute: "Upstream",
    Channel: "US_DOCSIS3.0",
    SubAttribute: "Tx Power",
    State: "Marginal Pass",
    PreCheckAction: [
      "Transmit Power (TX) is the measurement of the return signal that is sent from the modem to the Rogers Headend",
      "Most Upstream impairments are caused by a decrease in resistance. You will likely be looking for some type of open or loose connection.",
      "The lower the TX is, the closer it would be to upstream noise floor which could cause intermittent impact to the customer's experience. Try not to leave modems transmitting in the low to mid 30's",
      "Using your.TDR and Ohm meter, please ensure that both the Drop and Riser are in good condition",
      "Ensure that your drop loss is appropriate for your drop's length and cable type. ",
      "Using your Signal Level Meter, please ensure that your signal is as per design at the Tap, CSE/POE and your modem.",
    ],
  },
  {
    ParentAttribute: "Docsis 3.0",
    ChildAttribute: "Upstream",
    Channel: "US_DOCSIS3.0",
    SubAttribute: "Tx Power",
    State: "Fail",
    PreCheckAction: [
      "Transmit Power (TX) is the measurement of the return signal that is sent from the modem to the Rogers Headend",
      "Most Upstream impairments are caused by a decrease in resistance. You will likely be looking for some type of open or loose connection.",
      "The lower the TX is, the closer it would be to the upstream noise floor which could cause intermittent impact to the customer's experience. Try not to leave modems transmitting in the low to mid 30's.",
      "Using your TDR and Ohm meter, please ensure that both the Drop and Riser are in good condition",
      "Ensure that your drop loss is appropriate for your drop's length and cable type. ",
      "Using your Signal Level Meter, please ensure that your signal is as per design at the Tap, CSE/POE, and your modem.",
    ],
  },
  {
    ParentAttribute: "Docsis 3.0",
    ChildAttribute: "Upstream",
    Channel: "US_DOCSIS3.0",
    SubAttribute: "Snr",
    State: "Fail",
    PreCheckAction: [
      "Signal to Noise is a ratio between the amount of Upstream Signal Power and Noise Power and is generally impacted by some sort of impairment that impacts that ratio.",
      "Most Upstream impairments are caused by a decrease in resistance. You will likely be looking for some type of open or loose connection, causing ingress. SNR impairments are not caused by cable length alone.",
      "Using your TDR and Ohm meter, please ensure that both the Drop and Riser are in good condition",
      "Ensure that your drop loss is appropriate for your drop's length and cable type. ",
      "Using your Signal Level Meter, please ensure that your Forward signal is as per design at the Tap, CSE/POE, and your modem.",
    ],
  },
  {
    ParentAttribute: "Docsis 3.0",
    ChildAttribute: "Upstream",
    Channel: "UPSTREAM_SCQAM",
    SubAttribute: "Uncorrectables",
    State: "Fail",
    PreCheckAction: [
      "An uncorrectable codeword is a corrupted codeword that the Gateway and CMTS are unable to repair themselves. They can also show up as pre or post errors on your meter.",
      "Using your TDR and Ohm meter, please ensure that both the Drop and Riser are in good condition",
      "Ensure that your drop loss is appropriate for your drop's length and cable type. ",
      "Using your Signal Level Meter, please ensure that your signal is as per design at the Tap, CSE/POE, and your modem.",
    ],
  },
  {
    ParentAttribute: "Docsis 3.1",
    ChildAttribute: "Downstream",
    Channel: "DS_OFDM",
    SubAttribute: "Rx Power",
    State: "Marginal Pass",
    PreCheckAction: [
      "Receive Power (RX) is the measurement of the forward signal that is sent from the Rogers Headend to the modem",
      "Most Downstream impairments are caused by an increase in resistance. You will likely be looking for some type of short or wet cables. A wet cable is easily identified by the cable showing a powdery substance and blackened center pin. It may also be caused by the length of the drop where the loss causes low RX POWER, although the cable is not impaired.",
      "Using your TDR and Ohm meter, please ensure that both the Drop and Riser are in good condition",
      "Ensure that your drop loss is appropriate for your drop's length and cable type. ",
      "Using your Signal Level Meter, please ensure that your Forward signal is as per design at the Tap, CSE/POE, and your modem.",
    ],
  },
  {
    ParentAttribute: "Docsis 3.1",
    ChildAttribute: "Downstream",
    Channel: "DS_OFDM",
    SubAttribute: "Rx Power",
    State: "Fail",
    PreCheckAction: [
      "Receive Power (RX) is the measurement of the forward signal that is sent from the Rogers Headend to the modem",
      "Most Downstream impairments are caused by an increase in resistance. You will likely be looking for some type of short or wet cables. A wet cable is easily identified by the cable showing a powdery substance and blackened center pin. It may also be caused by the length of the drop where the loss causes low RX POWER, although the cable is not impaired.",
      "Using your TDR and Ohm meter, please ensure that both the Drop and Riser are in good condition",
      "Ensure that your drop loss is appropriate for your drop's length and cable type. ",
      "Using your Signal Level Meter, please ensure that your Forward signal is as per design at the Tap, CSE/POE, and your modem.",
    ],
  },
  {
    ParentAttribute: "Docsis 3.1",
    ChildAttribute: "Downstream",
    Channel: "DS_OFDM",
    SubAttribute: "Mer 2Pct",
    State: "Fail",
    PreCheckAction: [
      "Calculate Modulation Error Ratio (MER) to evaluate signal quality",
      "Measure the power of the useful signal and the power of noise and interference to calculate MER",
      "Higher MER indicates better signal quality, while lower MER indicates poorer signal quality",
      "Understand that the 2nd percentile value in an OFDM channel means that 98% of subcarriers are above that value",
      "Identify downstream impairments caused by an increase in resistance and look for shorts or over-amplification, such as unnecessary drop amp",
      "Note that MER impairments are not solely caused by cable length",
      "Use a TDR and Ohm meter to check the condition of both the Drop and Riser",
      "Ensure appropriate drop loss based on the length and cable type using a calculator",
      "Use a Signal Level Meter to verify the forward signal at the Tap, CSE/POE, and modem"
    ],
  },
  {
    ParentAttribute: "Docsis 3.1",
    ChildAttribute: "Downstream",
    Channel: "DS_OFDM",
    SubAttribute: "Mer 2Pct",
    State: "Marginal Pass",
    PreCheckAction: [
      "Calculate Modulation Error Ratio (MER) to assess signal quality",
      "Measure the power of the useful signal and the power of noise and interference to calculate MER",
      "Higher MER indicates better signal quality, while lower MER indicates poorer signal quality",
      "Understand that the 2nd percentile value in an OFDMA channel means that 98% of subcarriers are above that value",
      "Identify upstream impairments caused by a decrease in resistance and look for open or loose connections causing ingress",
      "Note that SNR impairments are not solely caused by cable length",
      "Use a TDR and Ohm meter to check the condition of both the Drop and Riser",
      "Ensure appropriate drop loss based on the length and cable type using a calculator",
      "Use a Signal Level Meter to verify the forward signal at the Tap, CSE/POE, and modem"
    ],
  },
  {
    ParentAttribute: "Docsis 3.1",
    ChildAttribute: "Downstream",
    Channel: "DOWNSTREAM_SCQAM",
    SubAttribute: "Uncorrectables",
    State: "Fail",
    PreCheckAction: [
      "An uncorrectable codeword is a corrupted codeword that the Gateway and CMTS are unable to repair themselves. They can also show up as pre or post errors on your meter.",
      "Using your meter, tune into the Frequency that was identified as failing by Tech Insight, and track down where the post-BER begins.",
      "Using your TDR and Ohm meter, please ensure that both the Drop and Riser are in good condition",
      "Ensure that your drop loss is appropriate for your drop's length and cable type. ",
      "Using your Signal Level Meter, please ensure that your signal is as per design at the Tap, CSE/POE, and your modem.",
    ],
  },
  {
    ParentAttribute: "Docsis 3.1",
    ChildAttribute: "Upstream",
    Channel: "US_OFDMA",
    SubAttribute: "Tx Power",
    State: "Marginal Pass",
    PreCheckAction: [
      "Transmit Power (TX) is the measurement of the return signal that is sent from the modem to the Rogers Headend",
      "Most Upstream impairments are caused by a decrease in resistance. You will likely be looking for some type of open or loose connection.",
      "The lower the TX is, the closer it would be to the upstream noise floor which could cause intermittent impact to the customer's experience. Try not to leave modems transmitting in the low to mid 30's.",
      "Using your TDR and Ohm meter, please ensure that both the Drop and Riser are in good condition",
      "Ensure that your drop loss is appropriate for your drop's length and cable type. ",
      "Using your Signal Level Meter, please ensure that your signal is as per design at the Tap, CSE/POE, and your modem.",
    ],
  },
  {
    ParentAttribute: "Docsis 3.1",
    ChildAttribute: "Upstream",
    Channel: "US_OFDMA",
    SubAttribute: "Tx Power",
    State: "Fail",
    PreCheckAction: [
      "Transmit Power (TX) is the measurement of the return signal that is sent from the modem to the Rogers Headend",
      "Most Upstream impairments are caused by a decrease in resistance. You will likely be looking for some type of open or loose connection.",
      "The lower the TX is, the closer it would be to the upstream noise floor which could cause intermittent impact to the customer's experience. Try not to leave modems transmitting in the low to mid 30's.",
      "Using your TDR and Ohm meter, please ensure that both the Drop and Riser are in good condition",
      "Ensure that your drop loss is appropriate for your drop's length and cable type. ",
      "Using your Signal Level Meter, please ensure that your signal is as per design at the Tap, CSE/POE, and your modem.",
    ],
  },
  {
    ParentAttribute: "Docsis 3.1",
    ChildAttribute: "Upstream",
    Channel: "US_OFDMA",
    SubAttribute: "Mer 2Pct",
    State: "Fail",
    PreCheckAction: [
      "Calculate Modulation Error Ratio (MER) to assess signal quality",
      "Measure the power of the useful signal and the power of noise and interference to calculate MER",
      "Higher MER indicates better signal quality, while lower MER indicates poorer signal quality",
      "Understand that the 2nd percentile value in an OFDMA channel means that 98% of subcarriers are above that value",
      "Identify upstream impairments caused by a decrease in resistance and look for open or loose connections causing ingress",
      "Note that SNR impairments are not solely caused by cable length",
      "Use a TDR and Ohm meter to check the condition of both the Drop and Riser",
      "Ensure appropriate drop loss based on the length and cable type using a calculator",
      "Use a Signal Level Meter to verify the forward signal at the Tap, CSE/POE, and modem"
    ],
  },
  {
    ParentAttribute: "Docsis 3.1",
    ChildAttribute: "Upstream",
    Channel: "UPSTREAM_SCQAM",
    SubAttribute: "Uncorrectables",
    State: "Fail",
    PreCheckAction: [
      "An uncorrectable codeword is a corrupted codeword that the Gateway and CMTS are unable to repair themselves. They can also show up as pre or post errors on your meter.",
      "Using your TDR and Ohm meter, please ensure that both the Drop and Riser are in good condition",
      "Ensure that your drop loss is appropriate for your drop's length and cable type. ",
      "Using your Signal Level Meter, please ensure that your signal is as per design at the Tap, CSE/POE, and your modem.",
    ],
  },
  {
    ParentAttribute: "Docsis 3.1",
    ChildAttribute: "Upstream",
    Channel: "US_OFDMA",
    SubAttribute: "Mer 2Pct",
    State: "Marginal Pass",
    PreCheckAction: [
      "Calculate Modulation Error Ratio (MER) to assess signal quality",
      "Measure the power of the useful signal and the power of noise and interference to calculate MER",
      "Higher MER indicates better signal quality, while lower MER indicates poorer signal quality",
      "Understand that the 2nd percentile value in an OFDMA channel means that 98% of subcarriers are above that value",
      "Identify upstream impairments caused by a decrease in resistance and look for open or loose connections causing ingress",
      "Note that SNR impairments are not solely caused by cable length",
      "Use a TDR and Ohm meter to check the condition of both the Drop and Riser",
      "Ensure appropriate drop loss based on the length and cable type using a calculator",
      "Use a Signal Level Meter to verify the forward signal at the Tap, CSE/POE, and modem"
    ],
  },
];
