import React, { useCallback, useEffect, useRef, useState } from "react";
import { isNull, isNumeric, openNewWindow, secToTime } from "../../components/reusable/Util"; // adjust import path
import { CMTSNodeDetails } from "../../store/models/node/nodeinfo/CMTSNodeDetails";
import { GetCMTSNodeDetails } from "../../get-api/GetResponse";
import CMTSClient from "../../network/httpclient/cmts/CMTSClient";
import { ThunkDispatch } from "redux-thunk";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import RefreshGif from "../../resources/images/icons/refresh.gif";
import AppLabels from "../../constants/App_Labels";

const CMTSLevel2Page = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const labels = AppLabels();
  const { cmtsNodeDetailsData, cmtsNodeDetailsLoading } = GetCMTSNodeDetails();
  const [filterData, setFilterData] = useState<CMTSNodeDetails>();
  const search = props.location.search;
  const params = new URLSearchParams(search);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const cmtsIp = searchParams.get("cmtsIp");

  const [disableRefresh, setDisableRefresh] = useState<boolean>(true);
  const [value, setValue] = useState<number>(0);
  const [CMTSLevel2Data, setCMTSLevel2Data] = useState(null);
  const [RackIDData, setRackIDData] = useState<any>({});
  const [nodeData, setNodedata] = useState<any>([]);
  const [cmtsCounter, setCmtsCounter] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refreshTime, setRefreshTime] = useState<string>("");

  const [stopClock, setStopClock] = useState(false);
  const [manualCounter, setManualCounter] = useState<number | null>(null);

  const [totalMinutes, setTotalMinutes] = useState<number>(0);
  const [stopApiCalls, setStopApiCalls] = useState<boolean>(false);

  // console.log(totalMinutes, stopApiCalls);

  const [bannerData, setBannerData] = useState<any>({
    cmtsIp: "",
    cmtsSysUpTime: "",
    CMTSSysDescr: "",
    node: "",
    macDomain: "",
    usPort: "",
    status: false,
    actions: ["Export to CSV"],
  });

  const getNodedata = useCallback(async () => {
    if (cmtsIp) {
      const nodeData = await dispatch(CMTSClient.getCMTSNodeList(cmtsIp));
      setNodedata(nodeData);
    }
  }, [cmtsIp])
  useEffect(() => {
    getNodedata();
  }, [getNodedata]);

  const renderNodeList = (nodedata: any) => {
    return (<>
      {nodedata?.length !== 0 ? (
        nodedata?.map((nodeObject: any, i: number) => {
          if (isNumeric(nodeObject?.nodeId)) {
            return (
              <span
                key={i + nodeObject?.nodeId}
                className="nodeLink"
                onClick={() => {
                  if (nodeObject?.nodeId && window) {
                    openNewWindow(`/#/node?nodeId=${nodeObject?.nodeId}`);
                  }
                }}
              >
                {(i === 0) ? isNull(nodeObject?.nodeId) + ',' : isNull(nodeObject?.nodeId)}
              </span>
            );
          }
        })
      ) : (
        <span>{labels.NA}</span>
      )}

    </>)
  }

  useEffect(() => {
    const minuteId = setInterval(() => {
      if (totalMinutes >= 60) {
        clearInterval(minuteId);
        setStopApiCalls(true); // stop api calls after 60 mins
      } else {
        setTotalMinutes((prev) => prev + 1);
      }
    }, 60000); // 60000 milliseconds=1min

    return () => {
      clearInterval(minuteId);
    };
  }, [totalMinutes]);

  const apiCmtsIPCall = useCallback(async () => {
    setIsLoading(true);
    setStopClock(true);
    if (stopApiCalls) return;

    if (cmtsIp) {
      try {
        const returnedData = await dispatch(CMTSClient.getCMTSRackID(cmtsIp));
        setRackIDData(returnedData);

        const level2Data = await dispatch(
          CMTSClient.getCMTSLevel2Details(cmtsIp)
        );
        setCMTSLevel2Data(level2Data);
      } catch (error) {
        // console.error(error);
      } finally {
        setIsLoading(false);
        setStopClock(false);
      }
    }
  }, [cmtsIp, stopApiCalls]);

  useEffect(() => {
    if (!stopClock) {
      const countdownId = setInterval(() => {
        setCmtsCounter((prevCounter) => {
          if (manualCounter !== null) {
            return manualCounter;
          }
          if (prevCounter === 0) {
            apiCmtsIPCall();
            setRefreshTime(new Date().toLocaleTimeString());
            return 30;
          }
          return prevCounter - 1;
        });
      }, 1000);

      return () => {
        clearInterval(countdownId);
      };
    }
  }, [apiCmtsIPCall, stopClock, manualCounter]);

  useEffect(() => {
    if (RackIDData) {
      if (!filterData) {
        setFilterData(cmtsNodeDetailsData);
      }
      setBannerData({
        cmtsIp: RackIDData?.cmtsIp,
        CMTSSysDescr: RackIDData?.cmtsDesc,
        cmtsName: RackIDData?.cmtsName + " / " + RackIDData.cmtsLocation,
        cmtsSysUpTime: secToTime(RackIDData?.uptime),
      });
    }
  }, [RackIDData]);

  const DataTable = ({ data }: { data: any[] }) => {
    return (
      <table className="cmtsTable">
        <thead>
          <tr>
            <th style={{ width: "75px", textAlign: "center" }}>#</th>
            <th>Interface</th>
            <th style={{ textAlign: "center" }}>Frequency</th>
            <th style={{ textAlign: "center" }}>CM Active / CM Total</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td style={{ width: "75px", textAlign: "center" }}>
                {index + 1}.
              </td>
              <td style={{ textAlign: "left" }}>{item.name.trim()}</td>
              <td style={{ textAlign: "center" }}>{item.frequency.trim()}</td>
              <td
                style={{
                  textAlign: "center",
                  backgroundColor:
                    item.numActiveModems !== 0 || item.numTotalModems !== 0
                      ? "orange"
                      : "",
                }}
              >
                {`${item.numActiveModems
                  .toString()
                  .trim()} / ${item.numTotalModems.toString().trim()}`}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="cmtsLevel2MainPage">
      <div className="cmtsBannerTitleContainer">
        <div className="cmtsBannerLabel">
          <h5 style={{ fontWeight: "bold", margin: 5 }}>CMTS IP:</h5>
          <h5 style={{ fontWeight: "normal", margin: 5 }}>
            {bannerData?.cmtsIp}
          </h5>

          <img
            style={{
              height: "23px",
              marginLeft: "20px",
              marginRight: "20px",
              visibility: isLoading ? "visible" : "hidden",
            }}
            src={RefreshGif}
            alt="refresh"
          />

          <div
            className="cmtsInfoSection"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontSize: "17px",
              fontWeight: 600,
              margin: "2px 5px",
              padding: "5px 0px",
              // borderBottom: "1px solid var(--black10)",
            }}
          >
            <div className="cmtsCounter">{cmtsCounter}</div>
          </div>
        </div>

        <div className="cmtsBannerLabel" style={{ margin: "5px" }}>
          <span style={{ fontSize: "14px", fontWeight: 600 }}>
            CMTS Sys Descr:
          </span>
          <span style={{ fontSize: "14px", fontWeight: "normal" }}>
            {RackIDData?.cmtsDesc}
          </span>
        </div>

        <div className="cmtsBannerLabel" style={{ margin: "5px" }}>
          <span style={{ fontSize: "14px", fontWeight: 600 }}>CMTS Name:</span>
          <span style={{ fontSize: "14px", fontWeight: "normal" }}>
            {RackIDData?.cmtsName + " / " + RackIDData?.cmtsLocation}
          </span>
        </div>

        <div className="cmtsBannerLabel" style={{ margin: "5px" }}>
          <span style={{ fontSize: "14px", fontWeight: 600 }}>
            CMTS Sys UpTime:
          </span>
          <span style={{ fontSize: "14px", fontWeight: "normal" }}>
            {secToTime(RackIDData?.uptime)}
          </span>
        </div>


        <div className="cmtsBannerLabel" style={{ margin: "5px" }}>
          <span style={{ fontSize: "14px", fontWeight: 600 }}>
            Node ID(s):
          </span>
          <span style={{ fontSize: "14px", fontWeight: "normal" }}>
            {renderNodeList(nodeData)}
          </span>
        </div>
      </div>

      <div
        style={{
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0 0 16px rgba(0,0,0,0.2)",
        }}
      >
        <DataTable data={CMTSLevel2Data || []} />
      </div>
    </div>
  );
};

export default CMTSLevel2Page;
