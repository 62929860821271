import InternetRouterIcon from "../../resources/images/icons/internet.svg"
import WifiIcon from "../../resources/images/icons/wifi.svg"
import HomePhoneIcon from "../../resources/images/icons/homephone.svg"
import CableTvIcon from "../../resources/images/icons/cable-tv.svg"
import WIFIPod from "../../resources/images/icons/wifi-pod.svg"
import { DEVICE_CATEGORY } from '../../constants/CustomerDeviceTypes'

const DisplayIcon = (props: any) => {
    const deviceInfo = DEVICE_CATEGORY.find(({ name }) => name === props.devicetype)

    if (deviceInfo) {
        const icon = deviceInfo.iconName
        if (icon === 'internet-router') {
            return <img src={InternetRouterIcon} />
        }
        else if (icon === "wifi") {
            return <img src={WifiIcon} />
        }
        else if (icon === "homephone") {
            return <img src={HomePhoneIcon} />
        }
        else if (icon === "cable-tv") {
            return <img src={CableTvIcon} />
        }
        else if (icon === "wifi-pod"){
            return <img src={WIFIPod} />
        }
    }
       return <>NO ICON AVAILABLE</>
}

export default DisplayIcon