import { Button, ListItem, ListItemText, TextField } from "@material-ui/core";
import { ChangeEventHandler, useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import ImageUpload from "../../../../components/reusable/ImageUpload";
import ActivityIndicator from "../../../../components/reusable/ActivityIndicator";
import { useRedlineStates } from "../../../util/reduxFunctions/getTopologyState";
import ExceptionHandlerWrapper from "../../reusable/ExceptionHandlerWrapper";
import { getRedline, updateRedline } from "../../../store/slices/redlineSlice";
import { ITechProfile } from "../../../../store/ducksPattern/TechProfile";

interface Props {
  trackerKey: string;
  techData?: ITechProfile["data"];
}
export default function UpdateRedline({ trackerKey, techData }: Props) {
  const dispatch = useDispatch();
  const commentsPrefix = `(Tech: ${techData?.lanId?.split("@")?.[0]}), `;
  const commentsMaxLength = 1000 - commentsPrefix?.length;
  const [form, setForm] = useState({ comments: "", attachments: [] });
  const {
    updateData,
    updateIsLoading,
    updateStatus,
    updateError,
    redlineIsLoading,
  } = useRedlineStates();
  const [refresh, setRefresh] = useState(false);

  const handleTFChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleSubmit = () => {
    if (form.comments || form.attachments?.length > 0) {
      const attch = form.attachments.map((e: any) => ({
        fileName: e.fileName,
        data: e.image,
      }));
      const d = {
        comments: form.comments && commentsPrefix + form.comments,
        attachments: attch,
      };
      setRefresh(true);
      dispatch(updateRedline({ trackerKey, data: d }));
    }
  };

  //refresh redline after edit
  useEffect(() => {
    if (refresh && updateStatus === "OK") {
      setForm({ comments: "", attachments: [] });
      dispatch(getRedline(trackerKey));
    }
  }, [updateStatus]);

  const isLoading = updateIsLoading || redlineIsLoading;

  return (
    <>
      <ListItem
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {/* <ListItemText primary="Add New Attachment(s)" />
        <ImageUpload
          image={form.attachments}
          onImageChange={(attachments: any) =>
            setForm((pv) => ({ ...pv, attachments }))
          }
        /> */}
      </ListItem>
      <ListItem>
        <TextField
          variant="outlined"
          label={`Comments (max ${commentsMaxLength} chars)`}
          name="comments"
          value={form.comments}
          size="small"
          onChange={handleTFChange}
          multiline
          rows={3}
          inputProps={{ maxLength: commentsMaxLength }}
          fullWidth
        />
      </ListItem>
      <ListItem>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 10 }}
          disabled={isLoading}
          onClick={handleSubmit}
          fullWidth
        >
          {isLoading && (
            <ActivityIndicator
              style={{ width: 30, height: 20, marginRight: 5 }}
              animationAlignement="none"
            />
          )}
          Submit Changes
        </Button>
      </ListItem>
      <ListItem>
        {updateData && (
          <div
            style={{
              gap: 1,
              flexDirection: "column",
              display: "flex",
              fontWeight: "bold",
            }}
          >
            {(updateData?.trackorId || updateData?.trackorKey) && (
              <label style={{ color: `var(--success)` }}>
                Success: (Tracker Id: {updateData?.trackorId}, Tracker Key:{" "}
                {updateData?.trackorKey})
              </label>
            )}
            {updateData?.errors?.comments && (
              <label style={{ color: `var(--error)` }}>
                Comment Update Failed
              </label>
            )}
            {updateData.errors?.attachments?.map(
              (attch, i) =>
                attch && (
                  <label style={{ color: `var(--error)` }}>
                    Attachement {i + 1} Failed: {attch?.fileName}
                  </label>
                )
            )}
          </div>
        )}
      </ListItem>
      <ExceptionHandlerWrapper
        status={updateStatus}
        error={updateError}
        EHContStyle={{ marginTop: 10 }}
      />
    </>
  );
}
