import "../../design-tokens/history-page/HistoryPage.scss";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import HistoryFilters from "../../components/history/certifications/CertificationsHistoryFilters";
import HistoryTable from "../../components/history/certifications/CertificationsHistoryTable";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import HistoryClient from "../../network/httpclient/history/HistoryClient";
import { historyReset } from "../../store/actions/history/HistoryAction";
import { GetTechInfo } from "../../get-api/GetResponse";

const CertificationsHistoryTab = (props: any) => {
  const dispatch = useDispatch();
  const techData = GetTechInfo()?.data;

  const canSearchForTechId =
    techData?.roles.includes("manager") || techData?.roles.includes("admin");

  const [currentScreen, setCurrentScreen] = useState(0);
  const [offset, setOffset] = useState(0);
  const now = new Date();
  const yesterday = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - 1
  );
  const [filterParams, setFilterParams] = useState({
    techId: "",
    techLanId: canSearchForTechId ? "" : techData?.lanId,
    accountId: "",
    workOrderId: "",
    fromDate: format(
      new Date(
        Number(yesterday?.getFullYear()),
        Number(yesterday?.getMonth()),
        Number(yesterday?.getDate())
      ),
      "dd/MM/yyyy"
    ),
    toDate: format(
      new Date(
        Number(now?.getFullYear()),
        Number(now?.getMonth()),
        Number(now?.getDate())
      ),
      "dd/MM/yyyy"
    ),
    testType: "",
    limit: 25,
    offset: 0,
  });

  useEffect(() => {
    if (data === undefined) {
      refreshHistory(0);
    }
  }, []);

  const { data, isLoading } = useSelector(
    (state: any) => ({
      data: state.HistoryInfo.data,
      isLoading: state.HistoryInfo.isLoading,
    }),
    shallowEqual
  );

  const handleFilters = (
    techId: any,
    techLanId: any,
    workOrderId: any,
    accountId: any,
    testType: any,
    fromDate: any,
    toDate: any
  ) => {
    setFilterParams({
      ...filterParams,
      techId: techId,
      techLanId: techLanId,
      accountId: accountId,
      workOrderId: workOrderId,
      fromDate: fromDate,
      toDate: toDate,
      testType: testType,
      limit: 25,
      offset: 0,
    });

    dispatch(historyReset(undefined));
    setCurrentScreen(currentScreen + 1);
    setOffset(0);
    dispatch(
      HistoryClient.getHistory(
        techId,
        techLanId,
        accountId,
        workOrderId,
        fromDate,
        toDate,
        testType,
        25,
        0
      )
    );
  };

  function getDataFromIndex(index: Number) {
    var selectedData: any = [];
    if (data !== undefined && data?.indexes !== undefined) {
      data?.indexes?.map((item: any) => {
        if (index === item.index) {
          selectedData = item.data;
        }
      });
    }
    return selectedData;
  }

  const refreshHistory = (offset: any) => {
    dispatch(
      HistoryClient.getHistory(
        filterParams.techId,
        filterParams.techLanId,
        filterParams.accountId,
        filterParams.workOrderId,
        filterParams.fromDate,
        filterParams.toDate,
        filterParams.testType,
        filterParams.limit,
        offset
      )
    );
  };

  return (
    <>
      <div>
        <HistoryFilters techData={techData} onApplyFilters={handleFilters} />
      </div>
      <div className="historyResultCont">
        <HistoryTable
          allCerti={getDataFromIndex(offset)}
          isLoading={isLoading}
          techData={techData}
          page={currentScreen}
          totalElements={data?.totalElements}
          handleChangePage={(offset: any) => {
            if (getDataFromIndex(offset)?.length === 0) {
              refreshHistory(offset);
            }
            setOffset(offset);
          }}
        />
      </div>
    </>
  );
};
export default CertificationsHistoryTab;
