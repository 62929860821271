import React, { useState, useEffect, useRef } from "react";
import { Button } from "@material-ui/core";
import { GetTelemetryProps } from "../../topology/util/reduxFunctions/getTopologyState";
import TelemetryDataMapping from "../telemetry/TelemetryDataMapping";
import { DataTable } from "./DataTable";
import { certificationTableConfig as tableHeader } from "../../constants/TelePrefConfig";
import AppLabels from "../../constants/App_Labels";
import CustomPopUp from "../reusable/CustomPopUp";
import WOSelection from "./WOSelection";
import {
  getNodeTeleGloabalStatus,
  isFullDiagWithCW,
} from "../reusable/NodeUtil";
import { changeTimeFormat } from "../reusable/Util";
import ConfigConst from "../../constants/ConfigConst";
import storeConst from "../../topology/store/storeConst";
import ResponseSnackBar from "../reusable/ResponseSnackBar";
import { GetPostNodeCertificateResp } from "../../get-api/GetResponse";
type Props = {
  children?: React.ReactNode;
  data?: any;
  isPopup: boolean;
};

export const Certifications: React.FC<Props> = (props: Props) => {
  const labels = AppLabels();
  const mlData = props.data ? props.data : GetTelemetryProps().mlData;
  const { allDiagIsLoading, allDiagStatus, isAborted, diagType, diagEndTime } =
    GetTelemetryProps();
  const nodeTeleStatus = getNodeTeleGloabalStatus(
    allDiagStatus,
    allDiagIsLoading,
    isAborted
  );
  const telemetryData = TelemetryDataMapping(mlData);
  const [popUpData, setPopUpData] = useState({
    popUpFlag: false,
    popUpBody: <></>,
    popUpHeader: "",
  });
  const [bannerVisible, setBannerVisible] = useState(false);
  const isRef = useRef(false);
  const postNodeCert = GetPostNodeCertificateResp();
  const isFullDiagCWAvail = isFullDiagWithCW(mlData);

  useEffect(() => {
    if (
      !postNodeCert?.postNodeCertIsLoading &&
      postNodeCert?.postNodeCertStatus != "" &&
      isRef.current
    ) {
      setBannerVisible(true);
      if (postNodeCert?.postNodeCertStatus === ConfigConst.OK) handleClose();
      isRef.current = false;
    }
  }, [postNodeCert?.postNodeCertIsLoading]);
  const handleClick = () => {
    isRef.current = true;
    setPopUpData({
      popUpFlag: true,
      popUpBody: (
        <>
          <WOSelection />
        </>
      ),
      popUpHeader: labels.CERTIFY,
    });
  };
  const handleClose = () => {
    setPopUpData({
      popUpFlag: false,
      popUpBody: <></>,
      popUpHeader: "",
    });
  };
  const handleDisable = () => {
    if (
      nodeTeleStatus === ConfigConst.complete.toLowerCase() &&
      diagType === storeConst.all
    ) {
      return (
        !isFullDiagCWAvail ||
        diagEndTime ===
          changeTimeFormat(postNodeCert?.postNodeCertData?.certEndTime)
      );
    }
    return true;
  };

  return (
    <>
      <div className="certificationsContainer">
        {!props.isPopup && (
          <div className="certifyBtnWrapper">
            <Button
              variant="contained"
              className="certifyBtn"
              disabled={handleDisable()}
              onClick={handleClick}
            >
              {labels.CERTIFY}
            </Button>
          </div>
        )}
        <DataTable
          tableHeader={tableHeader}
          tableData={telemetryData?.tableData}
        />
      </div>
      <CustomPopUp
        showVal={popUpData?.popUpFlag}
        headerText={popUpData?.popUpHeader}
        bodyElement={popUpData?.popUpBody}
        handleClose={handleClose}
      />
      <ResponseSnackBar
        open={bannerVisible}
        isHeader={true}
        requestType={labels.Node_Certification_Dtls}
        handleClose={() => setBannerVisible(false)}
        responseType={postNodeCert?.postNodeCertStatus}
      />
    </>
  );
};
