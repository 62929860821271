import { createSlice } from "@reduxjs/toolkit";
import {
  FILE_ATTACHMENT_URI,
  TOPOLOGY_SERVICE_URL,
} from "../../middleware/apiEndpoints";
import storeConst from "../../storeConst";
import { apiCallBegan } from "../../middleware/apiActions";

const slice = createSlice({
  name: "elementAttachments",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
    fetchIsLoading: false,
    fetchData: undefined,
    fetchStatus: "",
  },
  reducers: {
    fileUploadBegin: (attachment) => {
      attachment.isLoading = true;
      attachment.status = "";
    },
    fileUploadSuccess: (attachment, { payload }) => {
      attachment.isLoading = false;
      attachment.data = payload;
      attachment.status = storeConst.OK;
    },
    fileUploadFailure: (attachment, { payload }) => {
      attachment.isLoading = false;
      attachment.error = payload;
      attachment.data = undefined;
      attachment.status = storeConst.ERROR;
    },
    fileUploadReset: (attachment) => {
      attachment.isLoading = false;
      attachment.status = "";
      attachment.data = undefined;
      attachment.error = undefined;
    },
    filesFetchBegin: (attachment) => {
      attachment.fetchIsLoading = true;
      attachment.status = "";
    },
    filesFetchSuccess: (attachment, { payload }) => {
      attachment.fetchIsLoading = false;
      attachment.fetchData = payload;
      attachment.fetchStatus = storeConst.OK;
    },
    filesFetchFailure: (attachment, { payload }) => {
      attachment.fetchIsLoading = false;
      attachment.error = payload;
      attachment.fetchData = undefined;
      attachment.fetchStatus = storeConst.ERROR;
    },
    filesFetchReset: (attachment) => {
      attachment.fetchIsLoading = false;
      attachment.fetchStatus = "";
      attachment.fetchData = undefined;
      attachment.error = undefined;
    },
  },
});

export const {
  fileUploadBegin,
  fileUploadSuccess,
  fileUploadFailure,
  fileUploadReset,
  filesFetchBegin,
  filesFetchSuccess,
  filesFetchFailure,
  filesFetchReset,
} = slice.actions;

export default slice.reducer;

//action creators
export const uploadFile = (file: any, networkId: any, description: any) =>
  apiCallBegan({
    baseURL: TOPOLOGY_SERVICE_URL,
    url: `${FILE_ATTACHMENT_URI}/${networkId}/attachment`,
    onStart: fileUploadBegin.type,
    onSuccess: fileUploadSuccess.type,
    onError: fileUploadFailure.type,
    method: "post",
    data: JSON.stringify({
      fileName: file.name,
      fileData: file.file,
      description: description,
    }),
  });

export const getAllFiles = (networkId: any) =>
  apiCallBegan({
    baseURL: TOPOLOGY_SERVICE_URL,
    url: `${FILE_ATTACHMENT_URI}/${networkId}/attachments`,
    onStart: filesFetchBegin.type,
    onSuccess: filesFetchSuccess.type,
    onError: filesFetchFailure.type,
  });

export const deleteFile = (attachmentId: any, networkId: any) =>
  apiCallBegan({
    baseURL: TOPOLOGY_SERVICE_URL,
    url: `${FILE_ATTACHMENT_URI}/${networkId}/attachment/${attachmentId}`,
    onStart: fileUploadBegin.type,
    onSuccess: fileUploadSuccess.type,
    onError: fileUploadFailure.type,
    method: "post",
    data: {},
  });
