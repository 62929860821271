export const ZAP_BEGIN = "ZAP_BEGIN";
export const ZAP_SUCCESS = "ZAP_SUCCESS";
export const ZAP_FAILURE = "ZAP_FAILURE";
export const ZAP_RESET_STATUS = "ZAP_RESET_STATUS";
interface ZapBegin {
    type: typeof ZAP_BEGIN
}
interface ZapSuccess {
    type: typeof ZAP_SUCCESS
};

interface ZapFailure {
    type: typeof ZAP_FAILURE
    payload: Error
};
interface ZapReset {
    type: typeof ZAP_RESET_STATUS
};
export type ZapActionTypes = ZapBegin | ZapSuccess | ZapFailure | ZapReset