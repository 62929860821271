import React, { useEffect } from "react";

interface Props {
  gMap?: google.maps.Map;
  mduCADHatchData?: any;
}

const MduCADHatch = React.memo(({ gMap, mduCADHatchData }: Props) => {
  useEffect(() => {
    if (
      mduCADHatchData !== undefined &&
      mduCADHatchData?.features?.length > 0
    ) {
      gMap?.data.addGeoJson(mduCADHatchData);
    }
  }, [gMap, mduCADHatchData]);
  return <></>;
});

export default MduCADHatch;
