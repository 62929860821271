import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../middleware/apiActions";
import { MDU_PASSIVE } from "../../../middleware/apiEndpoints";
import storeConst from "../../../storeConst";
import { decrApiCall, incrApiCall } from "../mapDetails";

const slice = createSlice({
  name: "mduPassives",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    getMduPassivesBegin: (mduPassives) => {
      mduPassives.isLoading = true;
      mduPassives.status = "";
    },
    getMduPassivesSuccess: (mduPassives, action) => {
      mduPassives.isLoading = false;
      mduPassives.data = action.payload;
      mduPassives.status = storeConst.OK;
    },
    getMduPassivesFailure: (mduPassives, action) => {
      mduPassives.isLoading = false;
      mduPassives.error = action.payload;
      mduPassives.data = undefined;
      mduPassives.status = storeConst.ERROR;
    },
  },
});

const { getMduPassivesBegin, getMduPassivesSuccess, getMduPassivesFailure } =
  slice.actions;
export default slice.reducer;

export const getMduPassives = (owningDrawing: any) => (dispatch: any) => {
  dispatch(
    apiCallBegan({
      url: `${MDU_PASSIVE}/${owningDrawing}`,
      method: "get",
      onStart: getMduPassivesBegin.type,
      onSuccess: getMduPassivesSuccess.type,
      onError: getMduPassivesFailure.type,
      onStartDispatch: incrApiCall.type,
      onSuccessDispatch: decrApiCall.type,
      onErrorDispatch: decrApiCall.type,
    })
  );
};
