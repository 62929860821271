import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { useState } from "react";

import {
    isNull,
    getCheckValue,
    getDelta,
    formatModProfiles,
    isEmptyObject,
    getStatusOfCheckHeader,
    popOverCell,
} from "../../reusable/Util";
import AppLabels from "../../../constants/App_Labels";

interface Props {
    ofdmChannel?: any;
    preOfdmChannel?: any;
}

const OFDMDSPrechChData = ({ ofdmChannel, preOfdmChannel }: Props) => {
    const labels = AppLabels();
    const [summaryAcc, setSummaryAcc] = useState(true);
    const [detailsAcc, setDetailsAcc] = useState(false);
    const [preAcc, setPreAcc] = useState(false);

    const handleAccToggle = (accName: string) => {
        if (accName === "summary") setSummaryAcc(!summaryAcc);
        else if (accName === "details") setDetailsAcc(!detailsAcc);
        else if (accName === "precheck details") setPreAcc(!preAcc);
    };

    return (
        <div className="ofdmChannelCont">
            <div className="ofdmChSummary">
                <div className="dsChDataAcc" onClick={() => handleAccToggle("summary")}>
                    <p>{labels.SUMMARY.toUpperCase()}</p>
                    {summaryAcc ? (
                        <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
                    ) : (
                        <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
                    )}
                </div>
                {summaryAcc ? (
                    <table className="dataTableDetails">
                        <thead>
                            <tr>
                                <th id={"nameCol"}>{labels.NAME.toUpperCase()}</th>
                                <th id={"preValCol"}>{labels.PRECHECK_VALUE.toUpperCase()}</th>
                                <th id={"curValCol"}>{labels.CURRENT_VALUE.toUpperCase()}</th>
                                <th id={"diffCol"}>{labels.DIFFERENCE.toUpperCase()}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{labels.CHANNEL_ID.toUpperCase()}</td>
                                <td>{isNull(preOfdmChannel?.channelId) !== "" ?
                                    isNull(preOfdmChannel?.channelId) : "N/A"}</td>
                                <td>{isNull(ofdmChannel.channelId)}</td>
                                <td>N/A</td>
                            </tr>
                            <tr>
                                <td>
                                    <span style={{ display: 'flex', flexDirection: "column" }}>
                                        <span>{labels.ModulationProfiles.toUpperCase()}</span>
                                    </span>
                                </td>
                                <td>{formatModProfiles(preOfdmChannel)}</td>
                                <td>{formatModProfiles(ofdmChannel)}</td>
                                <td>N/A</td>
                            </tr>
                            <tr>
                                <td>{labels.MER_MEAN.toUpperCase()}</td>
                                {ofdmChannel?.hasOwnProperty("mer") ? (
                                    <>
                                        <td>{isNull(preOfdmChannel?.mer?.mean) !== "" ?
                                            isNull(preOfdmChannel?.mer?.mean) : "N/A"}</td>
                                        <td>{isNull(ofdmChannel.mer.mean)}</td>
                                        <td>
                                            {getDelta(
                                                ofdmChannel?.mer?.mean,
                                                preOfdmChannel?.mer?.mean
                                            )}
                                        </td>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </tr>
                            <tr>
                                <td>
                                    {getStatusOfCheckHeader(
                                        ofdmChannel?.mer?.thrVal,
                                        labels.MER_2PCT.toUpperCase()
                                    )}
                                </td>
                                {ofdmChannel?.hasOwnProperty("mer") ? (
                                    <>
                                        <td>
                                            {preOfdmChannel?.mer?.thrVal !== undefined ?
                                                popOverCell(preOfdmChannel?.mer?.thrVal) : "N/A"
                                            }
                                        </td>
                                        <td>
                                            {popOverCell(ofdmChannel?.mer?.thrVal)}
                                        </td>
                                        <td>
                                            {getDelta(
                                                ofdmChannel?.mer?.thrVal?.value,
                                                preOfdmChannel?.mer?.thrVal?.value
                                            )}
                                        </td>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </tr>
                            <tr>
                                <td>{labels.MER_STD.toUpperCase()}</td>
                                {ofdmChannel?.hasOwnProperty("mer") ? (
                                    <>
                                        <td>{isNull(preOfdmChannel?.mer?.stdDev) !== "" ?
                                            isNull(preOfdmChannel?.mer?.stdDev) : "N/A"}</td>
                                        <td>{isNull(ofdmChannel.mer.stdDev)}</td>
                                        <td>
                                            {getDelta(
                                                ofdmChannel?.mer?.stdDev,
                                                preOfdmChannel?.mer?.stdDev
                                            )}
                                        </td>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </tr>
                            <tr>
                                <td>
                                    {getStatusOfCheckHeader(
                                        ofdmChannel?.maxRxPower,
                                        labels.RX_POWER_MAX.toUpperCase()
                                    )}
                                </td>
                                {ofdmChannel?.hasOwnProperty("maxRxPower") ? (
                                    <>
                                        <td>
                                            {preOfdmChannel?.maxRxPower !== undefined ?
                                                popOverCell(preOfdmChannel?.maxRxPower) : "N/A"
                                            }
                                        </td>
                                        <td>
                                            {popOverCell(ofdmChannel.maxRxPower)}
                                        </td>
                                        <td>
                                            {getDelta(
                                                ofdmChannel?.maxRxPower?.value,
                                                preOfdmChannel?.maxRxPower?.value
                                            )}
                                        </td>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </tr>
                            <tr>
                                <td>
                                    {" "}
                                    {getStatusOfCheckHeader(
                                        ofdmChannel?.minRxPower,
                                        labels.RX_POWER_MIN.toUpperCase()
                                    )}
                                </td>
                                {ofdmChannel?.hasOwnProperty("minRxPower") ? (
                                    <>
                                        <td>
                                            {preOfdmChannel?.minRxPower !== undefined ?
                                                popOverCell(preOfdmChannel?.minRxPower) : "N/A"
                                            }
                                        </td>
                                        <td>
                                            {popOverCell(ofdmChannel.minRxPower)}
                                        </td>
                                        <td>
                                            {getDelta(
                                                ofdmChannel?.minRxPower?.value,
                                                preOfdmChannel?.minRxPower?.value
                                            )}
                                        </td>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </tr>
                            <tr>
                                <td>{labels.RX_POWER_DELTA.toUpperCase()}</td>
                                <td>{isNull(preOfdmChannel?.rxPowerDelta) !== "" ?
                                    isNull(preOfdmChannel?.rxPowerDelta) : "N/A"}</td>
                                <td>{isNull(ofdmChannel.rxPowerDelta)}</td>
                                <td>
                                    {getDelta(
                                        ofdmChannel?.rxPowerDelta,
                                        preOfdmChannel?.rxPowerDelta
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {getStatusOfCheckHeader(
                                        ofdmChannel?.averageRxPower,
                                        labels.RX_POWER_AVG.toUpperCase()
                                    )}
                                </td>
                                {ofdmChannel?.hasOwnProperty("averageRxPower") ? (
                                    <>
                                        <td>
                                            {preOfdmChannel?.averageRxPower !== undefined ?
                                                popOverCell(preOfdmChannel?.averageRxPower) : "N/A"
                                            }
                                        </td>
                                        <td>
                                            {popOverCell(ofdmChannel.averageRxPower)}
                                        </td>
                                        <td>
                                            {getDelta(
                                                ofdmChannel?.averageRxPower?.value,
                                                preOfdmChannel?.averageRxPower?.value
                                            )}
                                        </td>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </tr>
                            <tr>
                                <td>
                                    {getStatusOfCheckHeader(
                                        ofdmChannel?.status,
                                        "Operational Status".toUpperCase()
                                    )}
                                </td>
                                {ofdmChannel?.hasOwnProperty("status") ? (
                                    <>
                                        <td>
                                            {preOfdmChannel?.status !== undefined ?
                                                popOverCell(preOfdmChannel?.status) : "N/A"
                                            }
                                        </td>
                                        <td>
                                            {popOverCell(ofdmChannel.status)}
                                        </td>
                                        <td>N/A</td>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </tr>
                            <tr>
                                <td>{labels.CHANNELS.toUpperCase()}</td>
                                <td>
                                    {preOfdmChannel?.hasOwnProperty("channelBands")
                                        ? preOfdmChannel?.channelBands?.length
                                        : "N/A"}
                                </td>
                                <td>
                                    {ofdmChannel?.hasOwnProperty("channelBands")
                                        ? ofdmChannel.channelBands?.length
                                        : 0}
                                </td>
                                <td>N/A</td>
                            </tr>
                        </tbody>
                    </table>
                ) : (
                    <></>
                )}
            </div>
            <div className="ofdmChDetails">
                <div className="dsChDataAcc" onClick={() => handleAccToggle("details")}>
                    <p>{labels.CHANNELS.toUpperCase()}</p>
                    {detailsAcc ? (
                        <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
                    ) : (
                        <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
                    )}
                </div>
                {detailsAcc ? (
                    <table className="dataTableDetails">
                        <thead>
                            <tr>
                                <th id="nameCol">{labels.FREQUENCY}</th>
                                <th id="valueCol">{labels.RX_POWER.toUpperCase()}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ofdmChannel?.hasOwnProperty("channelBands") &&
                                ofdmChannel.channelBands?.length >= 0 ? (
                                ofdmChannel.channelBands
                                    ?.slice()
                                    .sort((a: any, b: any) =>
                                        a.centerFreq > b.centerFreq ? 1 : -1
                                    )
                                    ?.map((cb: any, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td>{isNull(cb.centerFreq)}</td>
                                                {cb?.hasOwnProperty("rxPower") ? (
                                                    <td>
                                                        {isNull(getCheckValue(isEmptyObject(cb.rxPower)))}
                                                    </td>
                                                ) : (
                                                    <td></td>
                                                )}
                                            </tr>
                                        );
                                    })
                            ) : (
                                <></>
                            )}
                        </tbody>
                    </table>
                ) : (
                    <></>
                )}
            </div>
            <div className="ofdmPreChDetails">
                <div
                    className="dsChDataAcc"
                    onClick={() => handleAccToggle("precheck details")}
                >
                    <p>{labels.PRECHECK_CHANNELS.toUpperCase()}</p>
                    {preAcc ? (
                        <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
                    ) : (
                        <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
                    )}
                </div>
                {preAcc ? (
                    <table className="dataTableDetails">
                        <thead>
                            <tr>
                                <th id="nameCol">{labels.FREQUENCY}</th>
                                <th id="valueCol">{labels.RX_POWER.toUpperCase()}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {preOfdmChannel?.hasOwnProperty("channelBands") &&
                                preOfdmChannel?.channelBands?.length >= 0 ? (
                                preOfdmChannel?.channelBands
                                    ?.slice()
                                    .sort((a: any, b: any) =>
                                        a.centerFreq > b.centerFreq ? 1 : -1
                                    )
                                    ?.map((cb: any, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td>{isNull(cb.centerFreq)}</td>
                                                {cb?.hasOwnProperty("rxPower") ? (
                                                    <td>
                                                        {isNull(getCheckValue(isEmptyObject(cb.rxPower)))}
                                                    </td>
                                                ) : (
                                                    <td></td>
                                                )}
                                            </tr>
                                        );
                                    })
                            ) : (
                                <></>
                            )}
                        </tbody>
                    </table>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default OFDMDSPrechChData;
