import { Divider } from "@material-ui/core";
import { InfoBox } from "@react-google-maps/api";
import React from "react";
import colors from "../../../../../config/colors";

interface Props {
  eolBlockData: any;
}
const MduEOLBlock = React.memo(({ eolBlockData }: Props) => {
  const latLng = new google.maps.LatLng(
    eolBlockData?.geometry?.coordinates?.[1],
    eolBlockData?.geometry?.coordinates?.[0]
  );

  return (
    <div>
      <InfoBox
        position={latLng}
        options={{
          closeBoxURL: "",
          disableAutoPan: true,
          pixelOffset: new google.maps.Size(-10, -40),
          boxStyle: {
            width: "auto",
            padding: "5px",
          },
        }}
      >
        <div className="eolBlock">
          <div className="tombstone">
            <div>{eolBlockData?.properties?.data?.tagText?.INP_D1}</div>
            <Divider
              style={{
                margin: "5px -4px 5px -4px",
                backgroundColor: colors.greyLight,
              }}
            />
            <div>{eolBlockData?.properties?.data?.tagText?.INP_D2}</div>
            <Divider
              style={{
                margin: "5px -4px 5px -4px",
                backgroundColor: colors.greyLight,
              }}
            />
            <div>{eolBlockData?.properties?.data?.tagText?.INP_D3}</div>
          </div>
          <span className="tombstoneSpan" style={{ justifyContent: "center" }}>
            {eolBlockData?.properties?.data?.tagText?.FT2LAST}
          </span>
        </div>
      </InfoBox>
    </div>
  );
});

export default MduEOLBlock;
