import { useMemo } from "react";
import {
  GetNodeInfoDetails,
  GetTelemetryDetails,
} from "../../get-api/GetResponse";
import { GetTelemetryProps } from "../../topology/util/reduxFunctions/getTopologyState";
const MultiDeviceONTDataMapping = (nodeDevicesList: any) => {
  const ducksConfigData = GetTelemetryDetails().ducksConfigData;
  const viewType = GetTelemetryProps().viewType;
  const nodeInfo = GetNodeInfoDetails().currNodedata;
  const viewsd = ducksConfigData?.userPreferences?.node?.telemetry?.views;

  let tableColFields = viewsd?.find((view: any) => {
    return view?.attributes;
  });

  let tableData = useMemo(() => {
    const collectedData: any[] = [];

    nodeDevicesList?.forEach((innerArray: any[]) => {
      innerArray.forEach((e: any) => {
        if (e != null) {
          // e?.ONTResponse?.data?.forEach((data: any) => {

          let serialNumber = e?.serialNumber;
          let accountNumber = e?.accountNum;
          let serviceAddress = e?.aptNumber
            ? e?.aptNumber +
              " " +
              e?.streetNumber +
              " " +
              e?.streetAddress +
              " " +
              e?.streetType +
              " " +
              e?.province +
              " " +
              e?.postalCode
            : e?.streetNumber +
              " " +
              e?.streetAddress +
              " " +
              e?.streetType +
              " " +
              e?.province +
              " " +
              e?.postalCode;

          let companyNum = e?.companyNum;
          let samKey = e?.samKey;
          let smtId = e?.serviceArea;
          let node = e?.returnSegment;
          const model = e?.model;

          collectedData.push({
            accountNumber,
            serviceAddress,
            serialNumber,
            companyNum,
            model,
            samKey,
            node,
            smtId,
          });
          // });
        } else if (e?.accountSummaryResponse?.data == null) {
          const errorData = e?.accountSummaryResponse?.error;
          collectedData.push({
            macAddress: null,
            accountNumber: null,
            serviceAddress: null,
            serialNum: null,
            type: null,
            smtId: null,
            model: null,
            summary: null,
            regStatus: null,
            ip: null,
            uptime: null,
            cmtsCmRegisteredTime: null,
            cmtsUptime: null,
            status: null,
            errorData,
          });
        }
      });
    });
    return collectedData;
  }, [nodeDevicesList, tableColFields]);

  return {
    tableData,
  };
};

export default MultiDeviceONTDataMapping;
