import { Divider } from "@material-ui/core";
import AppLabels from "../../../constants/App_Labels";
import ConfigConst from "../../../constants/ConfigConst";
import "../../../design-tokens/forms/EPR.scss";
import { changeDateFormat, changeTimeFormat } from "../../reusable/Util";

interface Props {
  data: any;
}

const ViewEquipmentPickupReceiptScreen = ({ data }: Props) => {
  const formData = data?.formData;
  const labels = AppLabels() as any;
  const workOrderID = data?.workOrderId;

  return (
    <div className="viewEPRContainer">
      <div>
        <label className="titleLabel">{labels.Work_Order_ID}:</label>
        <label className="textLabel">{workOrderID}</label>
      </div>
      <div>
        <label className="titleLabel">{"Order Date"}:</label>
        <label className="textLabel">
          {changeTimeFormat(data?.updatedDate)}
        </label>
      </div>
      <div>
        <label className="titleLabel">{"Created By"}:</label>
        <label className="textLabel">{data?.createdBy}</label>
      </div>
      <Divider style={{ margin: "10px 0px 20px 0px" }} />
      <div>
        <label className="titleLabel">{labels.Customer_Information}:</label>
      </div>
      <div>
        <label className="titleLabel">
          {" "}
          {"Last 4 Digit "}
          {labels.ACCOUNT_NUMBER}:
        </label>
        <label className="textLabel">
          {formData?.accountNumber && formData?.accountNumber % 10000}
        </label>
      </div>
      {formData?.customerEmails != "" && (
        <div>
          <label className="titleLabel">{labels.Customer_Emails}:</label>
          {formData?.customerEmails?.map((e: any, i: number) => (
            <div key={i}>
              <label className="textLabel">{e}</label>
            </div>
          ))}
        </div>
      )}
      <div>
        <label className="titleLabel">
          {labels.Customer_has_opted_out_of_the_Email_option}:
        </label>
        <label className="textLabel">
          {formData?.emailOptOut ? labels.Yes : labels.No}
        </label>
      </div>
      <Divider style={{ margin: "10px 0px 20px 0px" }} />
      <div>
        <label className="titleLabel">{labels.Order_Details}:</label>
      </div>
      <div>
        <label className="titleLabel">{labels.Date}:</label>
        <label className="textLabel">{changeDateFormat(formData?.date)}</label>
      </div>
      <div>
        <label className="titleLabel">{labels.TECH_ID}:</label>
        <label className="textLabel">{formData?.technicianId}</label>
      </div>
      <div>
        <label className="titleLabel">
          {labels.Equipment_Pickup_Details_and_Serial_Numbers}:
        </label>
        <label className="textLabel">
          {formData?.equipmentPickupDetailsandSerialNumbers}
        </label>
      </div>
      <Divider style={{ margin: "10px 0px 20px 0px" }} />
      <div>
        <label className="titleLabel">{labels.Confirmation}:</label>
      </div>
      <div>
        <label className="titleLabel">
          {"Customer Equipment Acknowledgment "}:
        </label>
        <label className="textLabel">
          {formData?.acknowledgment ? labels.Yes : labels.No}
        </label>
      </div>
      <div>
        <label className="titleLabel">{labels.Customers_Signature}:</label>
        <div className="imgContainer">
          <img
            src={formData?.signatureImage}
            style={{ height: "50%", width: "50%" }}
          />
          <div className="dashedLine" />
        </div>
      </div>
    </div>
  );
};

export default ViewEquipmentPickupReceiptScreen;
