import { Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import { InfoBox, InfoWindow, Marker } from "@react-google-maps/api";
import TopologySymbols from "../../../reusable/TopologySymbols";
import { useEffect, useState } from "react";
import { BsArrowsMove } from "react-icons/bs";
import { markerToString } from "../../../../util/utilFuncs";
import colors from "../../../../config/colors";
import { mapElementStyles } from "../../../../config/mapItemsConfig";

interface Props {
  nodeBlockData: any;
  click: any;
  setClick: any;
}

const RFNodeBlocks = ({ nodeBlockData, click, setClick }: Props) => {
  const latLng = new google.maps.LatLng(
    nodeBlockData?.geometry?.coordinates?.[1],
    nodeBlockData?.geometry?.coordinates?.[0]
  );
  const lat = nodeBlockData?.geometry?.coordinates?.[1];
  const lng = nodeBlockData?.geometry?.coordinates?.[0];

  const position = {
    lat: Number(nodeBlockData?.geometry?.coordinates?.[1]),
    lng: Number(nodeBlockData?.geometry?.coordinates?.[0]),
  };
  const divStyle = {
    background: `white`,
  };
  const showInfoWindow = () => {
    setClick(false);
  };
  const onCloseClick = () => {
    setClick(false);
  };
  const [showInfoBox, setShowInfoBox] = useState(false);
  useEffect(() => {
    setShowInfoBox(true);
  }, []);

  return (
    <div onClick={showInfoWindow}>
      {click && (
        <InfoWindow
          position={position}
          options={{ pixelOffset: new google.maps.Size(75, 0) }}
          onCloseClick={onCloseClick}
        >
          <div style={divStyle}>
            <TopologySymbols
              name="rf_node_block"
              color={`var(--iconColor)`}
              size={150}
            />
          </div>
        </InfoWindow>
      )}
      <Marker
        position={{ lat, lng }}
        icon={{
          url: markerToString(
            <BsArrowsMove color={colors.greyLight} size={20} />
          ),
          anchor: new google.maps.Point(
            mapElementStyles?.common?.anchor?.x - 80,
            mapElementStyles?.common?.anchor?.y + 3
          ),
        }}
        draggable
        cursor="move"
        zIndex={4000}
      >
        {showInfoBox && (
          <InfoBox
            position={latLng}
            options={{
              closeBoxURL: "",
              disableAutoPan: true,
              boxStyle: {
                width: "auto",
                padding: "5px",
              },
            }}
          >
            <Table className="annotationBlock">
              <TableBody>
                <TableRow>
                  <TableCell>
                    {nodeBlockData?.properties?.data?.tagText?.RF_DVC_ID}
                  </TableCell>
                  <TableCell>
                    {nodeBlockData?.properties?.data?.tagText?.RF_PART_NO}
                  </TableCell>
                  <TableCell>
                    {nodeBlockData?.properties?.data?.tagText?.RF_DGN_FREQ3}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {nodeBlockData?.properties?.data?.tagText?.FIB_DVC_ID}
                  </TableCell>
                  <TableCell>
                    {nodeBlockData?.properties?.data?.tagText?.FIB_PART_NO}
                  </TableCell>
                  <TableCell>
                    {nodeBlockData?.properties?.data?.tagText?.FIB_DGN_FREQ3}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    {nodeBlockData?.properties?.data?.tagText?.PS_ID}
                  </TableCell>
                  <TableCell>
                    {nodeBlockData?.properties?.data?.tagText?.VOLTS}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={2}>
                    <div>
                      {nodeBlockData?.properties?.data?.tagText?.FIB_LINK}
                    </div>
                    <div>
                      {nodeBlockData?.properties?.data?.tagText?.FIB_TYPE}
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </InfoBox>
        )}
      </Marker>
    </div>
  );
};

export default RFNodeBlocks;
