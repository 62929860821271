import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../middleware/apiActions";
import { GPON_SPLITTERS_URI } from "../../../middleware/apiEndpoints";
import { StandardReduxType } from "../../../reduxTypes";
import { decrApiCall, incrApiCall } from "../../mapItems/mapDetails";

let currSplitterIndex = -1;

const slice = createSlice({
  name: "gponSplitter",
  initialState: {
    allData: [] as StandardReduxType[],
  },
  reducers: {
    splitterBegin: (s, { payload }) => {
      currSplitterIndex = s.allData.findIndex(
        (sMap) => sMap?.data?.lcpNetworkId === payload.lcpNetworkId
      );
      const objStructure = {
        isLoading: true,
        error: undefined,
        status: "",
        data: { lcpNetworkId: payload.lcpNetworkId },
      } as StandardReduxType;

      if (currSplitterIndex === -1) {
        s.allData.push(objStructure);
        currSplitterIndex = s.allData.length - 1; //set current index
      } else s.allData[currSplitterIndex] = objStructure;
    },
    splitterSuccess: (s, { payload }) => {
      s.allData[currSplitterIndex] = {
        isLoading: false,
        error: undefined,
        status: "OK",
        data: {
          lcpNetworkId: payload.lcpNetworkId,
          payload: payload.data,
        },
      };
    },
    splitterFailure: (s, { payload }) => {
      s.allData[currSplitterIndex] = {
        isLoading: false,
        error: payload.data,
        status: "ERROR",
        data: {
          lcpNetworkId: payload.lcpNetworkId,
        },
      };
    },
  },
});

const { splitterBegin, splitterSuccess, splitterFailure } = slice.actions;
export default slice.reducer;

interface Props {
  lcpNetworkId: string;
}
export const getGponSplitter =
  ({ lcpNetworkId }: Props) =>
  (dispatch: any) => {
    dispatch(
      apiCallBegan({
        url: GPON_SPLITTERS_URI(lcpNetworkId),
        onStart: splitterBegin.type,
        onSuccess: splitterSuccess.type,
        onError: splitterFailure.type,
        onStartDispatch: incrApiCall.type,
        onSuccessDispatch: decrApiCall.type,
        onErrorDispatch: decrApiCall.type,
        props: { lcpNetworkId },
      })
    );
  };
