import { Button, TextField, Popper, Divider } from "@material-ui/core";
import {
  useState,
  ChangeEventHandler,
  FormEvent,
  useEffect,
  ChangeEvent,
  MutableRefObject,
} from "react";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch } from "react-redux";

import { IImage } from "../../../../../../components/reusable/ImageUpload";
import {
  createRedline,
  getRedlineConfigs,
} from "../../../../../store/slices/redlineSlice";
import {
  usePolygonStates,
  useRedlineStates,
} from "../../../../../util/reduxFunctions/getTopologyState";
import ExceptionHandlerWrapper from "../../../../reusable/ExceptionHandlerWrapper";
import ActivityIndicator from "../../../../../../components/reusable/ActivityIndicator";
import MUIDialog from "../../../../reusable/MUIDialog";
import InlineErrorMessage from "../../../../../../components/reusable/InlineErrorMessage";
import { getPolygonId } from "../../../../../store/slices/mapItems/mapPolygons";
import RedlineAttachments from "./RedlineAttachments";

interface Props {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  nodeId?: string;
  latLng?: { latitude: number; longitude: number };
  owningDrawing?: string;
  stageRef: MutableRefObject<any>;
}

type CityType = { name: string; id: string };

export default function CreateRedline({
  showModal,
  setShowModal,
  nodeId,
  latLng,
  owningDrawing,
  stageRef,
}: Props) {
  const dispatch = useDispatch();
  const {
    crData,
    crIsLoading,
    crError,
    crStatus,
    configsData,
    configsIsLoading,
  } = useRedlineStates();
  const { idData, idIsLoading } = usePolygonStates();
  const lat = latLng?.latitude;
  const lng = latLng?.longitude;
  // const projDescPrefix = owningDrawing
  //   ? `(MDU: ${owningDrawing}), `
  //   : `(Center LatLng: ${lat}, ${lng}), `;
  const projDescPrefix = owningDrawing
    ? `(MDU: ${owningDrawing}), `
    : ` `;
  const projDescMaxLength = 1000 - projDescPrefix?.length;
  const [form, setForm] = useState({
    nodeId: nodeId || "",
    marketId: "",
    streetAddress: "",
    city: "",
    cityName: "",
    province: "",
    postalCode: "",
    projectDesc: "",
  });
  const [formError, setFormError] = useState({
    nodeId: "",
    marketId: "",
    streetAddress: "",
    city: "",
    cityName: "",
    province: "",
    postalCode: "",
    projectDesc: "",
    attachments: "",
  });
  const [attachments, setAttachements] = useState<IImage[]>([]);
  const [showSubmitMsg, setShowSubmitMsg] = useState(false);
  const provinceKeys = configsData?.provinceCities?.map(
    (p) => Object.keys(p)?.[0]
  );
  const nodeIds = idData?.map((e) => e.id);

  useEffect(() => {
    dispatch(getRedlineConfigs());
    // if (!nodeId && !idData && lat && lng) dispatch(getPolygonId({ lat, lng }));
  }, []);

  /**clear city on province selection */
  useEffect(() => {
    setForm((pv) => ({ ...pv, city: "", cityName: "" }));
  }, [form?.province]);

  // //auto set first item in nodeId field
  // useEffect(() => {
  //   if (idData) setForm((pv) => ({ ...pv, nodeId: idData?.[0]?.id }));
  // }, [idData]);

  const handleTFChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target;

    setForm((prevForm) => ({ ...prevForm, [name]: value }));
    validateField(name as keyof typeof form, value);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    let hasError = false;
    if (!validateField("nodeId", form.nodeId)) hasError = true;
    // if (!validateField("postalCode", form.postalCode)) hasError = true;
    if (!validateField("projectDesc", form.projectDesc)) hasError = true;
    if (!validateField("province", form.province)) hasError = true;
    if (!validateField("city", form.cityName)) hasError = true;
    if (!validateField("marketId", form.marketId)) hasError = true;
    if (!validateField("attachments", attachments)) hasError = true;
    if (hasError) return;
    const attachmentsBase64 = attachments?.map((e) => ({
      fileName: e.fileName,
      data: e.image,
    }));
    /**remove dashes and commas*/
    const cleanedStreetAddress = form.streetAddress?.replace(/[-,]/g, "");

    dispatch(
      createRedline({
        ...form,
        streetAddress: cleanedStreetAddress,
        projectDesc: projDescPrefix + form?.projectDesc,
        latitude: lat,
        longitude: lng,
        attachments: attachmentsBase64,
      })
    );
    setShowSubmitMsg(true);
  };

  const validateField = (key: keyof typeof formError, value: any) => {
    switch (key) {
      case "nodeId" :
        if (value && value?.length !== 6) {
          setFormError((pv) => ({
            ...pv,
            [key]: "Node Id must be 6 digits number or not filled",
          }));
          return false;
        } else {
          setFormError((pv) => ({ ...pv, [key]: "" }));
          return true;
        }
        // case "postalCode":
        //   if (value && value?.length !== 6) {
        //     setFormError((pv) => ({
        //       ...pv,
        //       [key]: "Postal Code must be 6 digits or not filled",
        //     }));
        //     return false;
        //   } else {
        //     setFormError((pv) => ({ ...pv, [key]: "" }));
        //     return true;
        //   }
      case "marketId" :
        if (value?.length < 1) {
          setFormError((pv) => ({
            ...pv,
            [key]: "Market ID must be selected",
          }));
          return false;
        } else {
          setFormError((pv) => ({ ...pv, [key]: "" }));
          return true;
        }
        case "projectDesc" :
          if (value?.length < 1) {
            setFormError((pv) => ({
              ...pv,
              [key]: "Project Description must be added",
            }));
            return false;
          } else {
            setFormError((pv) => ({ ...pv, [key]: "" }));
            return true;
          }
        case "province":
          if (value?.length < 1) {
            setFormError((pv) => ({
              ...pv,
              [key]: "Province must be selected",
            }));
            return false;
          } else {
            setFormError((pv) => ({ ...pv, [key]: "" }));
            return true;
          }
          case "city":
            if (value?.length < 1) {
              setFormError((pv) => ({
                ...pv,
                [key]: "City must be selected",
              }));
              return false;
            } else {
              setFormError((pv) => ({ ...pv, [key]: "" }));
              return true;
            }
      case "attachments":
        if (value?.length < 1) {
          setFormError((pv) => ({
            ...pv,
            [key]: "At least one image must be added",
          }));
          return false;
        } else {
          setFormError((pv) => ({ ...pv, [key]: "" }));
          return true;
        }
      default:
        return true;
    }
  };

  const getCities = (pKey: string): CityType[] => {
    const pData = configsData?.provinceCities?.find((p: any) => p[pKey]);
    if (pData) return pData[pKey]?.map((city: CityType) => city);
    return [];
  };

  const onCitySelect = (_: ChangeEvent<{}>, city: CityType) => {
    setForm((pv) => ({
      ...pv,
      cityName: city?.name || "",
      city: city?.id || "",
    }));
     validateField("city",form?.cityName);
  };

  return (
    <MUIDialog
      title="Create Redline Form"
      show={showModal}
      setShow={setShowModal}
      disableCloseBtn={crIsLoading}
    >
      <form
        onSubmit={handleSubmit}
        autoComplete="off"
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          marginTop: 10,
          marginBottom: 10,
          gap: 10,
        }}
      >
        <TextField
          variant="outlined"
          label="Node Id"
          name="nodeId"
          value={form.nodeId}
          size="small"
          onChange={handleTFChange}
          error={Boolean(formError.nodeId)}
          helperText={formError.nodeId}
          required
        />

        {/* <CAutocomplete
            label="Node/Boundary Id"
            value={form.nodeId}
            isLoading={idIsLoading}
            onChange={(_, val) => setForm((pv) => ({ ...pv, nodeId: val }))}
            onInputChange={(val) => setForm((pv) => ({ ...pv, nodeId: val }))}
            options={nodeIds || []}
            helperText={formError.nodeId}
            freeSolo
          /> */}

        <CAutocomplete
          label="Market Id"
          value={form.marketId}
          isLoading={configsIsLoading}
          onChange={(_, val) => {
            setForm((pv) => ({ ...pv, marketId: val || "" }));
            validateField("marketId", val);
          }}
          options={configsData?.marketIds || []}
          helperText={formError.marketId}
          required
        />

        <TextField
          variant="outlined"
          label="Street Address (max 1000 chars)"
          name="streetAddress"
          placeholder="23 McSweeney Cres"
          value={form.streetAddress}
          size="small"
          onChange={handleTFChange}
          error={Boolean(formError.streetAddress)}
          helperText={formError.streetAddress}
          inputProps={{ maxLength: 1000 }}
          required
        />

        <CAutocomplete
          label="Province"
          value={form.province}
          isLoading={configsIsLoading}
          onChange={(_, val) => {setForm((pv) => ({ ...pv, province: val }));
          validateField("province", val); }}
          options={provinceKeys || []}
          helperText={formError.province}
          required
        />

        <CAutocomplete
          label="City"
          value={{ name: form.cityName, id: form.city }}
          isLoading={configsIsLoading}
          onChange={onCitySelect}
          options={getCities(form.province)}
          helperText={formError.cityName}
          getOptionLabel={(option: CityType) => option?.name}
          required
        />

        <TextField
          variant="outlined"
          label="Postal Code"
          name="postalCode"
          placeholder="L1T4C7"
          value={form.postalCode}
          size="small"
          onChange={handleTFChange}
          error={Boolean(formError.postalCode)}
          helperText={formError.postalCode}
          inputProps={{ maxLength: 6 }}
        />

        <TextField
          variant="outlined"
          label={`Project Description (max ${projDescMaxLength} chars)`}
          name="projectDesc"
          value={form.projectDesc}
          size="small"
          onChange={handleTFChange}
          error={Boolean(formError.projectDesc)}
          helperText={formError.projectDesc}
          multiline
          rows={3}
          inputProps={{ maxLength: projDescMaxLength }}
          required
        />
        <div>
          <Divider style={{ margin: "15px 0px" }} />
        </div>
        <RedlineAttachments
          attachments={attachments}
          setAttachements={setAttachements}
          stageRef={stageRef}
        />
        <InlineErrorMessage errorMessage={formError?.attachments} />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginTop: 10 }}
          disabled={crIsLoading}
        >
          {crIsLoading && (
            <ActivityIndicator
              style={{ width: 30, height: 20, marginRight: 5 }}
              animationAlignement="none"
            />
          )}
          Submit
        </Button>
        {crIsLoading && (
          <label style={{ color: `var(--info)` }}>
            Note: Create Redline may take some time...
          </label>
        )}
        {crData && showSubmitMsg && (
          <div
            style={{
              gap: 1,
              flexDirection: "column",
              display: "flex",
              fontWeight: "bold",
            }}
          >
            {(crData?.trackorId || crData?.trackorKey) && (
              <label style={{ color: `var(--success)` }}>
                Success: (Tracker Id: {crData?.trackorId}, Tracker Key:{" "}
                {crData?.trackorKey})
              </label>
            )}
            {crData?.errors?.createRedline && (
              <label style={{ color: `var(--error)` }}>
                Create Redline Failed
              </label>
            )}
            {crData?.errors?.address && (
              <label style={{ color: `var(--error)` }}>Address Failed</label>
            )}
            {crData.errors?.attachments?.map(
              (attch, i) =>
                attch && (
                  <label style={{ color: `var(--error)` }}>
                    Attachement {i + 1} Failed: {attch?.fileName}
                  </label>
                )
            )}
          </div>
        )}
        {showSubmitMsg && !crIsLoading && (
          <ExceptionHandlerWrapper
            status={crStatus}
            error={crError}
            EHContStyle={{ marginTop: 10 }}
          />
        )}
      </form>
    </MUIDialog>
  );
}

interface ICAutocomplete {
  value: any;
  isLoading: boolean;
  onChange: (event: ChangeEvent<{}>, value: any) => void;
  onInputChange?: (value: string) => void;
  options: any[];
  helperText: string;
  /**@default false */
  required?: boolean;
  label: string;
  getOptionLabel?: ((option: any) => string) | undefined;
  /**If true, the Autocomplete is free solo, meaning that the user input is not bound to provided options. @default false */
  freeSolo?: boolean;
}
const CAutocomplete = ({
  value,
  isLoading,
  onChange,
  onInputChange,
  options,
  helperText,
  label,
  getOptionLabel,
  required = false,
  freeSolo = false,
}: ICAutocomplete) => {
  return (
    <Autocomplete
      freeSolo={freeSolo}
      value={value}
      loading={isLoading}
      onChange={(event, newValue) => onChange(event, newValue)}
      onInputChange={(_, newValue) => onInputChange && onInputChange(newValue)}
      options={options}
      getOptionLabel={getOptionLabel}
      PopperComponent={({ children, ...props }) => (
        <Popper {...props} style={{ zIndex: 30000 }}>
          {children}
        </Popper>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          autoComplete="off"
          variant="outlined"
          label={label}
          size="small"
          error={Boolean(helperText)}
          helperText={helperText}
          required={required}
        />
      )}
    />
  );
};
