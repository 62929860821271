import { useEffect, useMemo, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { FiAlertTriangle } from "react-icons/fi";
import ErrorIcon from "../../../resources/images/icons/ErrorIcon.svg";
import TestedIcon from "../../../resources/images/icons/tested.svg";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TablePagination,
} from "@material-ui/core";
import HistoryTableHead from "./CertificationsHistoryTableHead";
import "../../../design-tokens/certification/CertificationTab.scss";
import { changeTimeFormat, isNull } from "../../reusable/Util";
import FullScreenPopUp from "../../reusable/FullScreenPopUp";
import ViewFullCertificate from "../../../screens/certification-page/ViewFullCertificate";
import ExceptionBox from "../../reusable/ExceptionBox";
import LoaderGif from "../../../resources/images/loader.webp";

const HistoryTable = (props: any) => {
  const { allCerti, techData, isLoading, totalElements } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [activeCerti, setActiveCerti] = useState({});
  const [openCerti, setOpenCerti] = useState(false);
  useEffect(() => {
    setPage(0);
  }, [props.page]);
  const certiData = useMemo(() => {
    let computedCertis = allCerti;
    if (search && searchBy === "id") {
      computedCertis = computedCertis.filter((certi: any) =>
        certi?.id?.toLowerCase()?.includes(search?.toLowerCase())
      );
    } else if (search && searchBy === "workOrderId") {
      computedCertis = computedCertis.filter((certi: any) =>
        certi?.workOrderId?.toLowerCase()?.includes(search?.toLowerCase())
      );
    } else if (search && searchBy === "techId") {
      computedCertis = computedCertis.filter((certi: any) =>
        certi?.technician?.techId?.toLowerCase().includes(search?.toLowerCase())
      );
    }
    //Sorting Certies
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      if (sorting.field === "techId") {
        computedCertis = computedCertis.sort((a: any, b: any) => {
          return a.technician?.techId || b.technician?.techId
            ? !a.technician?.techId
              ? -1
              : !b.technician?.techId
              ? 1
              : reversed *
                (a.technician?.techId).localeCompare(b.technician?.techId)
            : 0;
        });
      } else {
        computedCertis = computedCertis.sort((a: any, b: any) => {
          return reversed * a[sorting.field].localeCompare(b[sorting.field]);
        });
      }
    }
    return computedCertis;
  }, [search, searchBy, sorting, allCerti]);
  const handleCerticlick = (id: string) => {
    let seleCerti = allCerti.filter((c: any) => c.workOrderId === id);
    const data = seleCerti[0];
    if (typeof data?.hsiCertificate === "string") {
      data["hsiCertificate"] = JSON.parse(data?.hsiCertificate);
    }
    if (typeof data?.wifiCertificate === "string") {
      data["wifiCertificate"] = JSON.parse(data?.wifiCertificate);
    }

    setActiveCerti({ data: data });
    if (data?.status?.toLowerCase() === "complete") {
      setOpenCerti(true);
    }
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    props.handleChangePage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows = 1;

  return (
    <>
      <div className="certiTableCont">
        <Paper className="deviceTablePaper">
          <TableContainer>
            <Table className="deviceTable">
              <HistoryTableHead
                onSearch={(value: any, by: string) => {
                  setSearch(value);
                  setSearchBy(by);
                }}
                onSorting={(field: any, order: any) =>
                  setSorting({ field, order })
                }
              />
              <TableBody className="dTabBody">
                {isLoading ? (
                  <div className="loader">
                    <img style={{ height: "60px" }} src={LoaderGif} />
                  </div>
                ) : certiData?.length === 0 ? (
                  <TableRow tabIndex={-1} key="exception">
                    <TableCell colSpan={8}>
                      <div className="diagErrorCont">
                        <ExceptionBox
                          headerText="No Certifications to display."
                          bodyText="The ones started or submitted on this account will reflect here. Please contact the Tech Insight admin if data is missing."
                          primaryBtn={false}
                          secBtn={false}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  certiData?.map((certi: any, index: number) => {
                    var hsiCertificateData = certi?.hsiCertificate;
                    var wifiCertificateData = certi?.wifiCertificate;
                    if (typeof hsiCertificateData === "string") {
                      hsiCertificateData = JSON.parse(certi?.hsiCertificate);
                    }
                    if (typeof wifiCertificateData === "string") {
                      wifiCertificateData = JSON.parse(certi?.wifiCertificate);
                    }

                    return (
                      <TableRow tabIndex={-1} key={certi.id}>
                        <TableCell align="left">
                          {certi.status?.toLowerCase() === "complete" ? (
                            <a
                              className="idLink"
                              onClick={() =>
                                handleCerticlick(certi.workOrderId)
                              }
                            >
                              {isNull(certi.workOrderId)}
                            </a>
                          ) : techData !== undefined &&
                            certi.technician?.techId === techData?.techId ? (
                            <a
                              className="idLink"
                              onClick={() =>
                                handleCerticlick(certi.workOrderId)
                              }
                            >
                              {isNull(certi.workOrderId)}
                            </a>
                          ) : (
                            <a>{isNull(certi.workOrderId)}</a>
                          )}
                        </TableCell>
                        <TableCell>
                          {/* <img
                            src={userIMG}
                            className="techImg"
                            alt="technicianImg"
                          /> */}
                          <span className="techName">
                            {isNull(certi.technician?.techId)}
                          </span>
                        </TableCell>
                        <TableCell align="left" className="hsiCertiStatus">
                          {hsiCertificateData?.summary ? (
                            <p>
                              <span>
                                {/* {hsiCertificateData?.summary?.passed?.count +
                                  hsiCertificateData?.summary?.marginalPass
                                    ?.count <
                                10
                                  ? "0" +
                                    (hsiCertificateData?.summary?.passed
                                      ?.count +
                                      hsiCertificateData?.summary?.marginalPass
                                        ?.count)
                                  : hsiCertificateData?.summary?.passed?.count +
                                    hsiCertificateData?.summary?.marginalPass
                                      ?.count} */}
                                {hsiCertificateData?.summary?.marginalPass
                                  ?.count !== undefined
                                  ? hsiCertificateData?.summary?.passed?.count +
                                      hsiCertificateData?.summary?.marginalPass
                                        ?.count <
                                    10
                                    ? "0" +
                                      (hsiCertificateData?.summary?.marginalPass
                                        ?.count +
                                        hsiCertificateData?.summary?.passed
                                          ?.count)
                                    : hsiCertificateData?.summary?.passed
                                        ?.count +
                                      hsiCertificateData?.summary?.marginalPass
                                        ?.count
                                  : hsiCertificateData?.summary?.passed
                                      ?.count !== undefined &&
                                    hsiCertificateData?.summary?.passed?.count <
                                      10
                                  ? "0" +
                                    hsiCertificateData?.summary?.passed?.count
                                  : hsiCertificateData?.summary?.passed?.count}
                                <FaCheckCircle color="#2DC071" />
                              </span>
                              <span>
                                {hsiCertificateData?.summary?.failed?.count
                                  ? hsiCertificateData?.summary?.failed?.count <
                                    10
                                    ? "0" +
                                      hsiCertificateData?.summary?.failed?.count
                                    : hsiCertificateData?.summary?.failed?.count
                                  : "00"}
                                <img src={ErrorIcon} />
                              </span>
                              <span>
                                {hsiCertificateData?.summary?.skipped?.count
                                  ? hsiCertificateData?.summary?.skipped
                                      ?.count < 10
                                    ? "0" +
                                      hsiCertificateData?.summary?.skipped
                                        ?.count
                                    : hsiCertificateData?.summary?.skipped
                                        ?.count
                                  : "00"}
                                <FiAlertTriangle color="#E77C40" />
                              </span>
                            </p>
                          ) : (
                            <>-</>
                          )}
                        </TableCell>
                        <TableCell align="left" className="wifiCertiStatus">
                          {wifiCertificateData?.summary ? (
                            <p>
                              <span>
                                {wifiCertificateData?.summary?.total
                                  ? wifiCertificateData?.summary?.total < 10
                                    ? "0" + wifiCertificateData?.summary?.total
                                    : wifiCertificateData?.summary?.total
                                  : "00"}{" "}
                                <img src={TestedIcon} />
                              </span>
                              <span>
                                {wifiCertificateData?.summary?.passed
                                  ? certi.wifiCertificate?.summary?.passed < 10
                                    ? "0" + wifiCertificateData?.summary?.passed
                                    : wifiCertificateData?.summary?.passed
                                  : "00"}{" "}
                                <FaCheckCircle color="#2DC071" />
                              </span>
                              <span>
                                {wifiCertificateData?.summary?.failed
                                  ? wifiCertificateData?.summary?.failed < 10
                                    ? "0" + wifiCertificateData?.summary?.failed
                                    : wifiCertificateData?.summary?.failed
                                  : "00"}{" "}
                                <img src={ErrorIcon} />
                              </span>
                            </p>
                          ) : (
                            <>-</>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {isNull(certi.accountNumber)}
                        </TableCell>
                        <TableCell align="left">
                          {isNull(certi.status)?.slice(0, 1) +
                            "" +
                            isNull(certi.status)?.slice(1)?.toLowerCase()}
                        </TableCell>
                        <TableCell align="left">
                          <span>
                            {certi?.hasOwnProperty("submittedDate")
                              ? changeTimeFormat(isNull(certi.submittedDate))
                              : "-"}
                          </span>
                        </TableCell>
                        <TableCell align="left">
                          {isNull(certi.testType) === "WHT"
                            ? "Whole Home"
                            : isNull(certi.testType) === "DNS"
                            ? "Drop & Stay"
                            : isNull(certi.testType) === "DNG"
                            ? "EDBA Test"
                            : isNull(certi.testType)}
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
                {certiData?.length !== 0 && emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={8} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="certiPageCont">
            <p>TOTAL CERTIFICATIONS : {certiData?.length}</p>
            <TablePagination
              rowsPerPageOptions={[25]}
              component="div"
              count={totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </Paper>
      </div>
      <FullScreenPopUp
        open={openCerti}
        handleClose={() => {
          setOpenCerti(false);
        }}
        childComponent={<ViewFullCertificate certiData={activeCerti} />}
        pageName="Certification Details"
      />
    </>
  );
};

export default HistoryTable;
