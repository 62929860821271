import { ONTIP } from "../../../../models/account/diagnostics/ont/ONTIP";
import {
  ONT_IP_BEGIN,
  ONT_IP_SUCCESS,
  ONT_IP_FAILURE,
  ONTIPActionTypes,
} from "../../../../types/account/diagnostics/ont/ONTIP";

export function ONTIPBegin(): ONTIPActionTypes {
  return {
    type: ONT_IP_BEGIN,
  };
}

export function ONTIPSuccess(ONTIP: ONTIP): ONTIPActionTypes {
  return {
    type: ONT_IP_SUCCESS,
    payload: ONTIP,
  };
}

export function ONTIPFailure(error: Error): ONTIPActionTypes {
  return {
    type: ONT_IP_FAILURE,
    payload: error,
  };
}
