import * as React from "react";
import { EmtaNodeTeleTab } from "./EmtaNodeTeleTab";

export function EmtaNodeTelemetryTab(props: any) {
  const {
    data,
    selectdSmt,
    tableAttr,
    dataSetNames,
    dataSetType,
    exportDeviceId,
    exportDeviceType,
  } = props;

  return (
    <>
      <EmtaNodeTeleTab
        tableAttr={tableAttr}
        data={data}
        selectdSmt={selectdSmt}
        startDiag={props.startDiag}
        dataSetType={dataSetType}
        dataSetNames={dataSetNames}
        exportDeviceId={exportDeviceId}
        exportDeviceType={exportDeviceType}
        refreshNodeModemList={props.refreshNodeModemList}
        handleFlapHistory={props?.handleFlapHistory}
        selectedMac={props?.selectedMac}
      />
    </>
  );
}
