import AppLabels from "../../../constants/App_Labels";

export default function WorkDetailsData() {
  const labels = AppLabels();
  return [
    {
      label: labels.InstallingTemporaryAerialDrop,
      active: false,
      key: "InstallingTemporaryAerialDrop",
    },
    {
      label: labels.InstallingPermeantAerialDrop,
      active: false,
      key: "InstallingPermeantAerialDrop",
    },
    {
      label: labels.InstallingNewCableInsideHome,
      active: false,
      key: "InstallingNewCableInsideHome",
    },
    {
      label: labels.InstallingNewCableOutsideHome,
      active: false,
      key: "InstallingNewCableOutsideHome",
    },
    {
      label: labels.InstallingOutdoorAntenna,
      active: false,
      key: "InstallingOutdoorAntenna",
    },
    {
      label: labels.DrillingInsideHome,
      active: false,
      key: "DrillingInsideHome",
    },
    {
      label: labels.DrillingOutsideHome,
      active: false,
      key: "DrillingOutsideHome",
    },
    {
      label: labels.StaplingInsideHome,
      active: false,
      key: "StaplingInsideHome",
    },
    {
      label: labels.StaplingOutsideHome,
      active: false,
      key: "StaplingOutsideHome",
    },
    {
      label: labels.RelocateCSE,
      active: false,
      key: "RelocateCSE",
    },
    {
      label: labels.AttachingCableFence,
      active: false,
      key: "AttachingCableFence",
    },
    {
      label: labels.AttachingCableOutsideWall,
      active: false,
      key: "AttachingCableOutsideWall",
    },
    {
      label: labels.AttachingCableToTree,
      active: false,
      key: "AttachingCableToTree",
    },
    {
      label: labels.OtherLOPMsg,
      active: false,
      key: "OtherLOPMsg",
    },
  ];
}
