import { createSlice } from "@reduxjs/toolkit";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "rpdOrderInfo",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
    orderId: undefined,
  },
  reducers: {
    rpdOrderInfoBegin: (rpd) => {
      rpd.isLoading = true;
    },
    rpdOrderInfoSuccess: (rpd, action) => {
      rpd.isLoading = false;
      rpd.data = action.payload;
      rpd.error = undefined;
      rpd.status = storeConst.OK;
    },
    rpdOrderInfoFailure: (rpd, action) => {
      rpd.isLoading = false;
      rpd.error = action.payload;
      rpd.data = undefined;
      rpd.status = storeConst.ERROR;
    },
    rpdOrderInfoReset: (rpd) => {
      rpd.isLoading = false;
      rpd.error = undefined;
      rpd.data = undefined;
      rpd.status = "";
      rpd.orderId = undefined;
    },
    setOrderId: (rpd, action) => {
      rpd.orderId = action.payload;
      rpd.error = undefined;
      rpd.data = undefined;
      rpd.status = "";
    },
  },
});

export const {
  rpdOrderInfoBegin,
  rpdOrderInfoSuccess,
  rpdOrderInfoFailure,
  rpdOrderInfoReset,
  setOrderId,
} = slice.actions;
export default slice.reducer;
