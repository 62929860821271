import { Card, Divider } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import CustomCarousel from "../reusable/CustomCarousel";

const ViewNotification = (props: any) => {
  const { showVal, ntfItems, bodyHeadText, bodyText, handleClose } = props;
  return (
    <div className="notiCont">
      <Modal
        show={showVal}
        onHide={() => handleClose()}
        backdrop="static"
        keyboard={false}
        centered
        id="NotiPopUp"
      >
        <Modal.Header className="searchPopHead">
          <header>{"Notification Details".toUpperCase()}</header>
          <button className="sPopUpClose" onClick={() => handleClose()}>
            <IoClose color="white" />
          </button>
        </Modal.Header>
        <Modal.Body className="confirmBody">
          <div className="ntfHeadCard">
            <h6 className="cardHeader">{bodyHeadText}</h6>
            <p
              className="cardContent"
              dangerouslySetInnerHTML={{ __html: bodyText }}
            />
          </div>
          {ntfItems !== undefined && ntfItems?.length !== 0 ? (
            <div className="notiItemsCont">
              {ntfItems?.map((i: any) => {
                return (
                  <Card className="ntfItemsCard">
                    <div>
                      <h6 className="cardHeader">{i?.header}</h6>
                      <p className="cardContent">{i?.desc}</p>
                    </div>
                    <CustomCarousel images={i?.images} />
                  </Card>
                );
              })}
            </div>
          ) : (
            <></>
          )}
        </Modal.Body>
        <Divider orientation="horizontal" />
      </Modal>
    </div>
  );
};

export default ViewNotification;
