export const SAVE_FORM_BEGIN = "SAVE_FORM_BEGIN";
export const SAVE_FORM_SUCCESS = "SAVE_FORM_SUCCESS";
export const SAVE_FORM_FAILURE = "SAVE_FORM_FAILURE";
export const SAVE_FORM_RESET = "SAVE_FORM_RESET";

interface SaveFormBegin {
  type: typeof SAVE_FORM_BEGIN;
}

interface SaveFormSuccess {
  type: typeof SAVE_FORM_SUCCESS;
  payload: any;
}

interface SaveFormFailure {
  type: typeof SAVE_FORM_FAILURE;
  payload: Error;
}

interface SaveFormReset {
  type: typeof SAVE_FORM_RESET;
}

export type NewFormActionTypes =
  | SaveFormBegin
  | SaveFormSuccess
  | SaveFormFailure
  | SaveFormReset;
