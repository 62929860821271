import { Divider } from "@material-ui/core";
import { useState, useEffect, useMemo } from "react";
import { FiFilter } from "react-icons/fi";
import { IoSearch } from "react-icons/io5";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { useGponAddressClient } from "../../../../network/httpclient/gpon/GponAddressClient";
import CustomDropDown from "../../../../components/reusable/CustomDropDown";
import ChipGroup from "../../../../components/reusable/ChipGroup";
import DiagContainer from "../../../../components/reusable/DiagContainer";
import InlineErrorMessage from "../../../../components/reusable/InlineErrorMessage";
import {
  openNewWindow,
  setCurrentWindowName,
} from "../../../../components/reusable/Util";
import AppLabels from "../../../../constants/App_Labels";
import {
  GetGponNodeDetails,
  GetNodeInfoDetails,
} from "../../../../get-api/GetResponse";
import NodeInfoDetailsClient from "../../../../network/httpclient/nodeinfo/NodeInfoDetailsClient";
import TopologyLabels from "../../../constants/topologyLabels";
import TopologyData from "../../../data/TopologyData";
import {
  setMapCenter,
  setSearchedAddress,
} from "../../../store/slices/mapItems/mapDetails";
import { getAddressSearch } from "../../../store/slices/search/addressSearch";
import {
  GetAddressSearchProps,
  GetTopoGeneralProps,
} from "../../../util/reduxFunctions/getTopologyState";
import { getActiveEleMarker, getOutputAddress } from "../../../util/utilFuncs";
import { SetActiveNodePage } from "../../../../store/actions/node/nodeinfo/ActiveNodeInfoAction";
import { ActiveNodePage } from "../../../../store/states/node/nodeinfo/ActiveNodeInfoState";
import { useGponNodeClient } from "../../../../network/httpclient/gpon/GponNodeClient";

interface Props {
  onNodeOpen: () => void;
  onSearchPopupHide: () => void;
}

const AddressSearch = ({ onNodeOpen, onSearchPopupHide }: Props) => {
  const appLabels = AppLabels();
  const labels = TopologyLabels();
  const dispatch = useDispatch();
  const history = useHistory();
  const gponAddressFetch = useGponAddressClient();
  const gponNode = useGponNodeClient();
  const provOptions = TopologyData()?.provOptions;
  const { data, isLoading, error } = GetAddressSearchProps();
  const { activeNodeId } = GetTopoGeneralProps();
  const { currNodedata } = GetNodeInfoDetails();
  const { nodeInfo, oltResultData } = GetGponNodeDetails();
  const [aptNum, setAptNum] = useState("");
  const [streetNum, setStreetNum] = useState("");
  const [streetName, setStreetName] = useState("");
  const [streetType, setStreetType] = useState("");
  const [province, setProvince] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isSearchInitiated, setIsSearchInitiated] = useState(false);
  const [filterSearchVal, setFilterSearchVal] = useState("");
  const [activeChip, setActiveChip] = useState(0);
  const [isNodePageHasData, setIsNodePageHasData] = useState(false);

  const address =
    (aptNum !== "" ? aptNum.toUpperCase() + "-" : "") +
    (streetNum !== "" ? streetNum.toUpperCase() + " " : "") +
    (streetName !== "" ? streetName.toUpperCase() : "") +
    (streetType !== "" ? " " + streetType.toUpperCase() : "") +
    (province !== "" ? ", " + province.toUpperCase() : "") +
    (postalCode !== "" ? " " + postalCode.toUpperCase() : "");

  //open node if there is only one result
  useEffect(() => {
    if (isSearchInitiated && data?.length === 1 && data?.[0]?.returnSegment) {
      onResultsItemPress(data?.[0]);
    }
  }, [data]);

  useEffect(() => {
    // any of the node page has data then set isNodePageHasData to true
    if (currNodedata || nodeInfo?.data || oltResultData) {
      setIsNodePageHasData(true);
    }
  }, [currNodedata, nodeInfo, oltResultData]);

  const onSubmit = () => {
    if (streetName?.trim() === "") {
      setErrorMsg(labels.streetName + " " + labels.isRequiredForAddressSearch);
    } else if (streetNum === "") {
      setErrorMsg(labels.StreetNo + " " + labels.isRequiredForAddressSearch);
    } else {
      setErrorMsg("");
      setIsSearchInitiated(true);
      dispatch(
        getAddressSearch({
          searchedAddress: address,
          queryParams: {
            streetName: streetName?.trim(),
            streetNum,
            aptNum,
            postalCode,
            streetType,
            province,
          },
        })
      );
    }
  };

  const openNode = (address: any) => {
    onNodeOpen();
    onSearchPopupHide && onSearchPopupHide();
    if (activeNodeId != address?.returnSegment) {
      if (activeNodeId) {
        openNewWindow(
          `/#/node?nodeId=${address?.returnSegment}&lat=${address?.lat}&lng=${address?.lng}`
        );
      } else {
        dispatch(
          NodeInfoDetailsClient.getInfoNodeDetails(address?.returnSegment)
        );
      }
    }
  };

  const openGponNode = (nodeId: string, samKey: string) => {
    onNodeOpen();
    if (isNodePageHasData) {
      onSearchPopupHide && onSearchPopupHide();
      const url = `/#/node?gponId=${nodeId}&samKey=${samKey}`;
      let newWindow = window.open(url, "_blank")!;
      newWindow.name = `GN-${nodeId}`;
      newWindow.document.title = `GN-${nodeId}`;
      newWindow.focus();
    } else {
      setCurrentWindowName(`GN-${nodeId}`);
      history.replace(`/node?gponId=${nodeId}&samKey=${samKey}`);
    }
  };

  const handleGponNodeSearch = async (samKey: string) => {
    const apiresonse = await gponAddressFetch.getGponAddressInfo(samKey);
    if (apiresonse?.serviceAddressInfo?.generalAddressInfo?.gponNode) {
      const gponNodeIdRespose = await gponNode.getNodesInfo(
        apiresonse.serviceAddressInfo.generalAddressInfo.gponNode,
        "Maestro"
      );
      if (
        gponNodeIdRespose?.ponNode !== "" ||
        gponNodeIdRespose?.ponNode !== null ||
        gponNodeIdRespose?.ponNode !== undefined ||
        gponNodeIdRespose?.ponNode !== "undefined"
      ) {
        openGponNode(gponNodeIdRespose?.ponNode, samKey);
      } else setErrorMsg("GPON Node not found");
    } else setErrorMsg("GPON Node not found");
  };

  const onResultsItemPress = (address: any) => {
    if (address?.returnSegment && activeChip === 0) {
      openNode(address);
      let activeElement = getActiveEleMarker(address);
      dispatch(setSearchedAddress(activeElement));
      //dispatch(setMapCenter({ lat: address?.lat, lng: address?.lng }));
    } else if (address?.samKey !== "" && activeChip === 1) {
      // make api call to get the gpon node id using samkey
      handleGponNodeSearch(address?.samKey);
    } else setErrorMsg("Return Segment not found");
  };

  const addressData = useMemo(() => {
    let computedData = data;
    if (filterSearchVal !== "" && computedData?.length > 0) {
      computedData = computedData?.filter((address: any) => {
        let fullAddress = getOutputAddress(address);
        return fullAddress
          ?.toLowerCase()
          .includes(filterSearchVal?.toLowerCase());
      });
    }
    return computedData;
  }, [filterSearchVal, data]);

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  return (
    <div>
      <label style={{ fontWeight: 600 }}>
        {`${labels.Note}: ${labels.SubscriberAddressesMsg}`}
      </label>
      <div>
        <ChipGroup
          chips={[
            {
              title: "DOCSIS",
              description: appLabels.searchPlaceholdDocsisNode,
            },
            { title: "GPON", description: appLabels.searchPlaceholdGponNode },
          ]}
          activeChipIndex={activeChip}
          onIndexChange={(index: number) => {
            setActiveChip(index);
            if (index === 1) {
              dispatch(SetActiveNodePage(ActiveNodePage.GPON));
            } else {
              dispatch(SetActiveNodePage(ActiveNodePage.NODE));
            }
          }}
        />
      </div>
      <div className="addressSearchCont">
        <div className="adSearchInputCont">
          <section>
            <span>
              <label>{labels.ApartmentNo}:</label>
              <input
                type="text"
                value={aptNum}
                onChange={(event: any) => setAptNum(event.target.value?.trim())}
                onKeyPress={(event: any) => handleKeyPress(event)}
              />
            </span>
            <span>
              <label>{labels.StreetNo}*:</label>
              <input
                type="text"
                value={streetNum}
                onChange={(event: any) =>
                  setStreetNum(event.target.value?.trim())
                }
                onKeyPress={(event: any) => handleKeyPress(event)}
              />
            </span>
          </section>
          <section>
            <span>
              <label>{labels.streetName}*:</label>
              <input
                type="text"
                value={streetName}
                onChange={(event: any) => setStreetName(event.target.value)}
                onKeyPress={(event: any) => handleKeyPress(event)}
              />
            </span>
            <span>
              <label>{labels.streetType}:</label>
              <input
                type="text"
                value={streetType}
                onChange={(event: any) =>
                  setStreetType(event.target.value?.trim())
                }
                onKeyPress={(event: any) => handleKeyPress(event)}
              />
            </span>
          </section>
          <section>
            <span>
              <label>{labels.PostalCode}:</label>
              <input
                type="text"
                value={postalCode}
                onChange={(event: any) =>
                  setPostalCode(event.target.value?.trim())
                }
                onKeyPress={(event: any) => handleKeyPress(event)}
              />
            </span>
            <span>
              <label>{labels.province}:</label>
              <CustomDropDown
                selectedOption={province}
                options={provOptions}
                searchFlag={false}
                checkboxFlag={false}
                setSelectedOption={(value: any) => {
                  setProvince(value);
                }}
              />
            </span>
          </section>
          <section className="addSearchBtnCont">
            <p>
              {labels.address?.toUpperCase()}:{address}
            </p>
            <InlineErrorMessage errorMessage={errorMsg} />
            <button className="addSearchBtnCont" onClick={() => onSubmit()}>
              {labels.SEARCH} <IoSearch />
            </button>
          </section>
        </div>
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <>
          <div className="addSearchFilterCont">
            <div className="addSearchFilter">
              <FiFilter />
              <input
                type="text"
                className="addFilterInput"
                placeholder={labels.FilterResultsByAnyValue + "..."}
                onChange={(e) => setFilterSearchVal(e.target.value)}
              />
            </div>
            <p>
              <span>{appLabels.SEARCH_RESULTS}</span>
              <span>{addressData?.length}</span>
            </p>
          </div>
          <DiagContainer
            isLoading={isLoading}
            data={data}
            error={error}
            sectionName={"Address Search"}
            handleRefresh={onSubmit}
          >
            <div className="addSearchResultCont">
              {addressData?.map((address: any, i: number) => {
                return (
                  <p
                    className="addressLink"
                    onClick={() => onResultsItemPress(address)}
                    key={i}
                  >
                    {getOutputAddress(address)}
                    <MdKeyboardArrowRight />
                  </p>
                );
              })}
            </div>
          </DiagContainer>
        </>
      </div>
    </div>
  );
};

export default AddressSearch;
