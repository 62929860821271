export const SAVE_PJI_BEGIN = "SAVE_PJI_BEGIN";
export const SAVE_PJI_SUCCESS = "SAVE_PJI_SUCCESS";
export const SAVE_PJI_FAILURE = "SAVE_PJI_FAILURE";
export const SAVE_PJI_RESET = "SAVE_PJI_RESET";

interface SavePJIBegin {
  type: typeof SAVE_PJI_BEGIN;
}

interface SavePJISuccess {
  type: typeof SAVE_PJI_SUCCESS;
  payload: any;
}

interface SavePJIFailure {
  type: typeof SAVE_PJI_FAILURE;
  payload: Error;
}

interface SavePJIReset {
  type: typeof SAVE_PJI_RESET;
}

export type NewPJIActionTypes =
  | SavePJISuccess
  | SavePJIBegin
  | SavePJIFailure
  | SavePJIReset;
