import { IconInterface } from "../components/reusable/TopologySymbols";
import appConst from "../constants/appConst";
import colors from "./colors";

export const mapItemsClickConfig: any = {
  common: {
    image18ZoomScale: 24, //do not change this value
    image19ZoomScale: 16, //do not change this value
    image20ZoomScale: 8, //do not change this value
    image21ZoomScale: 3.8, //do not change this value
    clickableIcon: {
      color: "transparent",
      iconName: "circle" as IconInterface["name"],
      iconSize18: 10,
      iconSize19: 22,
      iconSize20: 38,
      iconSize21: 55,
    },
  },
  activeMarker: {
    iconSize19: 18,
    iconSize20: 22,
    iconSize21: 22,
    anchor: {
      zoom19: { x: -2, y: 10 },
      zoom20: { x: -1, y: 12 },
      zoom21: { x: 10, y: 2 },
    },
  },
  node: {
    sizeDividend: 1.8,
    anchor: {
      zoom19: { x: 10, y: 10 },
      zoom20: { x: 18, y: 18 },
      zoom21: { x: 42, y: 42 },
    },
  },
  olt: {
    sizeDividend: 1.2,
    anchor: {
      zoom18: { x: 4, y: 4 },
      zoom19: { x: 6, y: 6 },
      zoom20: { x: 14, y: 14 },
      zoom21: { x: 28, y: 28 },
    },
  },
  [appConst.LCP]: {
    sizeDividend: 1.8,
    anchor: {
      zoom19: { x: 10, y: 10 },
      zoom20: { x: 18, y: 18 },
      zoom21: { x: 42, y: 42 },
    },
  },
  tap: {
    sizeDividend: 1.5,
    labelAnchor: {
      zoom19: { x: 6, y: 6 },
      zoom20: { x: 12, y: 12 },
      zoom21: { x: 24, y: 24 },
    },
    anchor: {
      zoom19: { x: 6, y: 6 },
      zoom20: { x: 12, y: 12 },
      zoom21: { x: 24, y: 24 },
    },
  },
  splitter: {
    sizeDividend: 1.5,
    anchor: {
      zoom19: { x: 6, y: 6 },
      zoom20: { x: 12, y: 12 },
      zoom21: { x: 24, y: 24 },
    },
  },
  opticalTap: {
    sizeDividend: 1.5,
    anchor: {
      zoom19: { x: 6, y: 6 },
      zoom20: { x: 12, y: 12 },
      zoom21: { x: 24, y: 24 },
    },
  },
  ispSite: {
    sizeDividend: 1.5,
    anchor: {
      zoom19: { x: 10, y: 10 },
      zoom20: { x: 18, y: 18 },
      zoom21: { x: 42, y: 42 },
    },
  },
  powerBlock: {
    sizeDividend: 1.5,
    anchor: {
      zoom19: { x: 6, y: 6 },
      zoom20: { x: 12, y: 12 },
      zoom21: { x: 24, y: 24 },
    },
  },
  powerInserter: {
    sizeDividend: 1.5,
    anchor: {
      zoom19: { x: 6, y: 6 },
      zoom20: { x: 12, y: 12 },
      zoom21: { x: 24, y: 24 },
    },
  },
  terminator: {
    sizeDividend: 1.5,
    iconSize19: 12,
    iconSize20: 20,
    iconSize21: 46,
    anchor: {
      zoom19: { x: 6.5, y: 6 },
      zoom20: { x: 11, y: 10 },
      zoom21: { x: 23, y: 23 },
    },
  },
  powerSupply: {
    sizeDividend: 1.5,
    anchor: {
      zoom18: { x: 8, y: 20 },
      zoom19: { x: 8, y: 24 },
      zoom20: { x: 12, y: 35 },
      zoom21: { x: 15, y: 50 },
    },
    labelAnchor: {
      zoom18: { x: 4, y: 4 },
      zoom19: { x: 8, y: 6 },
      zoom20: { x: 12, y: 4 },
      zoom21: { x: 25, y: -8 },
    },
  },
  fiberSpliceCase: {
    sizeDividend: 1.5,
    iconSize19: 14,
    iconSize20: 22,
    iconSize21: 48,
    anchor: {
      zoom19: { x: 6, y: 6 },
      zoom20: { x: 10, y: 10 },
      zoom21: { x: 24, y: 24 },
    },
  },
  fiberStorageLoop: {
    sizeDividend: 1.8,
    anchor: {
      zoom19: { x: 10, y: 10 },
      zoom20: { x: 20, y: 20 },
      zoom21: { x: 40, y: 40 },
    },
  },
  fiberStorageRiser: {
    sizeDividend: 1.2,
    anchor: {
      zoom19: { x: 1, y: 1 },
      zoom20: { x: 4, y: 4 },
      zoom21: { x: 7, y: 7 },
    },
  },
  fiberAddrConnectionPoint: {
    sizeDividend: 1.8,
    anchor: {
      zoom19: { x: 3, y: 3 },
      zoom20: { x: 7, y: 7 },
      zoom21: { x: 17, y: 17 },
    },
  },
  rfSplice: {
    sizeDividend: 1.5,
    anchor: {
      zoom19: { x: 5, y: 5 },
      zoom20: { x: 7, y: 7 },
      zoom21: { x: 16, y: 16 },
    },
  },
  activeEquipment: {
    sizeDividend: 1.5,
    anchor: {
      zoom18: { x: 4, y: 4 },
      zoom19: { x: 8, y: 8 },
      zoom20: { x: 16, y: 16 },
      zoom21: { x: 32, y: 32 },
    },
  },
  //Support Structure
  cableDip: {
    sizeDividend: 1.5,
    anchor: {
      zoom19: { x: 1, y: 1 },
      zoom20: { x: 4, y: 4 },
      zoom21: { x: 7, y: 7 },
    },
  },
  //Support Structure
  pole: {
    sizeDividend: 1.5,
    iconSize19: 12,
    iconSize20: 20,
    iconSize21: 46,
    anchor: {
      zoom19: { x: 6.5, y: 6 },
      zoom20: { x: 11, y: 10 },
      zoom21: { x: 23, y: 23 },
    },
  },
  //Support Structure
  detailMark: {
    sizeDividend: 1.5,
    anchor: {
      zoom19: { x: 8, y: 8 },
      zoom20: { x: 16, y: 16 },
      zoom21: { x: 32, y: 32 },
    },
  },
  vhub: {
    sizeDividend: 1.8,
    anchor: {
      zoom19: { x: 10, y: 10 },
      zoom20: { x: 18, y: 18 },
      zoom21: { x: 42, y: 42 },
    },
  },
  //MDU Passive
  mduPassive: {
    sizeDividend: 1.5,
    anchor: {
      zoom19: { x: 6, y: 6 },
      zoom20: { x: 12, y: 12 },
      zoom21: { x: 100, y: 100 },
    },
  },
  mduActive: {
    sizeDividend: 1.5,
    anchor: {
      zoom18: { x: 4, y: 4 },
      zoom19: { x: 8, y: 8 },
      zoom20: { x: 16, y: 16 },
      zoom21: { x: 32, y: 32 },
    },
  },
  mduTap: {
    sizeDividend: 1.5,
    anchor: {
      zoom18: { x: 4, y: 4 },
      zoom19: { x: 6, y: 6 },
      zoom20: { x: 12, y: 12 },
      zoom21: { x: 24, y: 24 },
    },
    labelAnchor: {
      zoom18: { x: 4, y: 4 },
      zoom19: { x: 6, y: 6 },
      zoom20: { x: 12, y: 12 },
      zoom21: { x: 24, y: 24 },
    },
  },
  mduFiberEquipment: {
    sizeDividend: 1.5,
    anchor: {
      zoom18: { x: 4, y: 4 },
      zoom19: { x: 6, y: 6 },
      zoom20: { x: 12, y: 12 },
      zoom21: { x: 24, y: 24 },
    },
  },
  mduPowerSupply: {
    sizeDividend: 1.5,
    anchor: {
      zoom18: { x: 6, y: 6 },
      zoom19: { x: 8, y: 8 },
      zoom20: { x: 18, y: 18 },
      zoom21: { x: 32, y: 32 },
    },
  },
  mduStrandPoint: {
    sizeDividend: 1.5,
    anchor: {
      zoom19: { x: 6, y: 6 },
      zoom20: { x: 12, y: 12 },
      zoom21: { x: 100, y: 100 },
    },
  },
  mduCADSymbol: {
    sizeDividend: 1.5,
    anchor: {
      zoom19: { x: 6, y: 6 },
      zoom20: { x: 12, y: 12 },
      zoom21: { x: 100, y: 100 },
    },
  },
  mduCADAttribute: {
    sizeDividend: 1.5,
    anchor: {
      zoom19: { x: 6, y: 6 },
      zoom20: { x: 12, y: 12 },
      zoom21: { x: 100, y: 100 },
    },
    labelAnchor: {
      zoom19: { x: 15, y: 8 },
      zoom20: { x: 24, y: 10 },
      zoom21: { x: 50, y: 20 },
    },
  },
  mduCADTableIcon: {
    labelAnchor: {
      zoom19: { x: 20, y: -3 },
      zoom20: { x: 20, y: -10 },
      zoom21: { x: 30, y: -20 },
    },
  },
};
