import AppLabels from "../../../constants/App_Labels";

export default function ImpactedServices() {
  const labels = AppLabels();
  return [
    {
      label: labels.Internet,
      active: false,
      key: "Internet",
    },
    {
      label: labels.Ignite_TV,
      active: false,
      key: "Ignite_TV",
    },
    {
      label: labels.Legacy_TV,
      active: false,
      key: "Legacy_TV",
    },
    {
      label: labels.Home_Phone,
      active: false,
      key: "Home_Phone",
    },
    {
      label: labels.Smart_Home_Monitoring,
      active: false,
      key: "Smart_Home_Monitoring",
    },
    {
      label: labels.FIDO,
      active: false,
      key: "FIDO",
    },
  ];
}
