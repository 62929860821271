import AppLabels from "../../constants/App_Labels";
import appConst from "../constants/appConst";
import TopologyLabels from "../constants/topologyLabels";

export default function TopologyData() {
  const appLabels = AppLabels();
  const labels = TopologyLabels();

  const searchTypeConfig = [
    {
      key: appConst.node,
      label: "Node",
    },
    {
      key: appConst.address,
      label: appLabels.Address,
    },
  ];

  let provOptions = [
    "",
    "AB",
    "BC",
    "MB",
    "NB",
    "NL",
    "NT",
    "NS",
    "NU",
    "ON",
    "PE",
    "QC",
    "SK",
    "YT",
  ];

  const elementSearchTypes = [
    {
      key: appConst.rensNum,
      label: labels.rensNum,
    },
    {
      key: appConst.address,
      label: labels.Address,
    },
    {
      key: appConst.searchCoordinates,
      label: labels.coordinates,
    },
    { key: appConst.vhub, label: labels.vhub.toUpperCase() },
    /*   {
      key: appConst.OLT,
      label: labels.OLT,
    },
    {
      key: appConst.ponNode,
      label: labels.PonNode.toUpperCase(),
    }, */
    {
      key: appConst.wirelessSite,
      label: labels.wirelessSite,
    },
    {
      key: appConst.smallCell,
      label: labels.smallCell,
    },
    {
      key: appConst.clamshell,
      label: labels.Clamshell,
    },
    {
      key: appConst.rbCircuit,
      label: labels.rbCircuit,
    },
    {
      key: appConst.olt,
      label: labels.olt,
    },
  ];

  return { searchTypeConfig, provOptions, elementSearchTypes };
}
