import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";

interface Props {
  showVal: boolean;
  headerText: string;
  bodyElement: JSX.Element;
  bodyClassName?: string;
  className?: string;
  confirmText?: string;
  buttonDisabled?: boolean;
  handleConfirm?: () => void;
  handleClose: () => void;
}

const CustomPopUp = ({
  showVal,
  headerText,
  bodyElement,
  bodyClassName,
  className,
  confirmText,
  buttonDisabled,
  handleClose,
  handleConfirm,
}: Props) => {
  return (
    <div>
      <Modal
        show={showVal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        id="customPopUp"
        dialogClassName={className}
      >
        <Modal.Header className="searchPopHead">
          <header>{headerText}</header>
          <button className="sPopUpClose" onClick={handleClose}>
            <IoClose color="white" />
          </button>
        </Modal.Header>
        <Modal.Body className={"cmSummaryCont " + bodyClassName}>{bodyElement}</Modal.Body>
        <Modal.Footer>
          <button className="sPopCloseBtn" onClick={handleClose}>
            Close
          </button>
          {handleConfirm && confirmText && (
            <button className="sPopBtn" onClick={handleConfirm} disabled={buttonDisabled}>
              {confirmText}
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CustomPopUp;
