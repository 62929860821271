import { Polygon } from "@react-google-maps/api";

import { mapElementStyles } from "../../../config/mapItemsConfig";
import appConst from "../../../constants/appConst";
import {
  GetMapDetailsProps,
  usePolygonStates,
} from "../../../util/reduxFunctions/getTopologyState";

interface Props {
  handlePolyDBClick?: any;
  handleClick?: any;
  handleRightClick?: any;
}

const Polygons = ({
  handlePolyDBClick,
  handleClick,
  handleRightClick,
}: Props) => {
  let polygons = usePolygonStates()?.data;
  let { activeMapElement, mapFilters, highlightedPolygon } =
    GetMapDetailsProps();

  return (
    <>
      {(mapFilters === undefined || mapFilters?.[appConst.polygon]) &&
      polygons &&
      polygons?.features?.length > 0 ? (
        polygons?.features?.map((f: any, index: number) => {
          let isSelected =
            activeMapElement?.properties?.id === f?.properties?.featureId
              ? true
              : highlightedPolygon?.properties?.data?.polygonId ===
                f?.properties?.data?.networkId
              ? true
              : false;
          return (
            <Polygon
              key={index}
              paths={f?.geometry}
              options={
                isSelected
                  ? mapElementStyles?.polygon?.active
                  : mapElementStyles?.polygon?.nonActive
              }
              onClick={handleClick}
              onDblClick={() => {
                handlePolyDBClick(f, appConst.polygon);
              }}
              onRightClick={handleRightClick}
            />
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};

export default Polygons;
