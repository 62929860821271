import { Tab, Tabs } from "react-bootstrap";
import { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CableModemInfo from "../cable-modem/CableModemInfo";
import GWDevicesInfo from "../gateway/GWDevicesInfo";
import WifiInfo from "../gateway/WifiInfo";
import ReloadData from "../reusable/ReloadData";
import ViewCodewordSteps from "../certification/certificationSteps/ViewCodewordSteps";
import ErrorIcon from "../../resources/images/icons/ErrorIcon.svg";
import RefreshGif from "../../resources/images/icons/refresh.gif";
import EthernetInfo  from "../gateway/EthernetInfo";
import  XLEGWInfo  from "../gateway/XLEGWInfo";
import EthernetClient from "../../network/httpclient/gwDevices/EthernetClient";
import {
  checkRoles,
  getAccessLevelByRoles,
  getCMW3ID,
  getCMW3ID4Brand,
  getDeviceDetails,
  getDeviceLoading,
  isNull,
  renderDeviceStatus,
  isLegacyAccount,techRole
} from "../reusable/Util";
import CableModemClient from "../../network/httpclient/cablemodem/CableModemClient";
import GWDevicesClient from "../../network/httpclient/gwDevices/GWDevicesClient";
import WIFIInfoClient from "../../network/httpclient/wifi_Info/WIFIInfoClient";
import AppLabels from "../../constants/App_Labels";
import FailedAttributesCm from "../FailedAttributes/FailedAttributesCm";
import MarginalPassAttributesCm from "../FailedAttributes/MarginalPassAttributesCm";
import { SetDeviceStatus } from "../../store/actions/account/accountInfo/ActiveAccountAction";
import CodeWordMonitor from "../codewords/CodeWordMonitor";
import CodeWordsClient from "../../network/httpclient/codeword/CodeWordsClient";
import { FaCheckCircle } from "react-icons/fa";
import { CWMonitoringReset } from "../../store/actions/account/diagnostics/cm/CWMonitoringAction";
import CMDocsisEvents from "./CMDocsisEvents";
import { CWMonitoringTracer } from "../../store/actions/account/diagnostics/cm/CWMonitoringAction";
import {
  SetFailedCodewords,
  SetCodewordValues,
} from "../../store/actions/account/accountInfo/ActiveAccountAction";
import { SetSCQAMFLAG } from "../../store/actions/account/accountInfo/ActiveAccountAction";
import ConfigConst from "../../constants/ConfigConst";
import { ThunkDispatch } from "redux-thunk";
import { GetActiveAccountDetails } from "../../get-api/GetResponse";
const CmDiagnostics = (props: any) => {
  const labels = AppLabels();
  const isLegacyAcc = isLegacyAccount();
  const isTechRole = techRole();
  const dispatch = useDispatch();
  const {
    selectedDevice,
    selectedSA,
    deviceDiagActions,
    selDiagAction,
    handleActionClick,
  } = props;
  const [gwTabFlag, setGwTabFlag] = useState(false);
  const [wifiTabFlag, setWifiTabFlag] = useState(false);
  const [docsisEventsTabFlag, setDocsisEventsTabFlag] = useState(false);
  const [codewordTabFlag, setCodewordTabFlag] = useState(false);
  const [ethernetTabFlag, setEthernetTabFlag] = useState(false);
  const [gatewayStatusTabFlag,setGatewayStatusTabFlag]= useState(false);
  const [macAddress,setmacAddress]= useState("");
  const [key, setKey] = useState(selDiagAction);
  const [managedRouterInfo, setManagedRouterInfo] = useState(null);
  const brandName = GetActiveAccountDetails()?.brand;
  

  const {
    docsisEventsInfo,
    gwDevicesInfo,
    flagXLE,
    wifiSettingsInfo,
    wifiRadiosInfo,
    diagDevicesData,
    cwMonitorLoading,
    cwMonitorStatus,
    failedAttributesCW,
    sortedDevices,
    flapsInfo,
    failedAttributes,
    codewords,
    tracer,
    techInfoData,
  } = useSelector(
    (state: any) => ({
      docsisEventsInfo: state.AccountState.cmDocsisEvents.data,
      gwDevicesInfo: state.AccountState.gwDevicesInfo.data,
      flagXLE: state.slices?.xleVisible,
      wifiSettingsInfo: state.AccountState.wifiSettingsInfo.data,
      wifiRadiosInfo: state.AccountState.wifiRadiosInfo.data,
      diagDevicesData: state.AccountState.DiagDevicesInfo.diagDevices,
      cwMonitorLoading: state.AccountState.cwMonitorInfo.overallLoading,
      cwMonitorStatus: state.AccountState.cwMonitorInfo.status,
      sortedDevices: state.AccountState.ActiveAccountDetails.sortedDevices,
      failedAttributesCW:
        state.AccountState.ActiveAccountDetails?.failedAttributes,
      flapsInfo: state.AccountState.cmFlapsDetails.data,
      failedAttributes:
        state.AccountState.ActiveAccountDetails?.failedAttributes,
      codewords: state.AccountState.ActiveAccountDetails?.codeword,
      tracer: state.AccountState.cwMonitorInfo.tracer,
      techInfoData: state.slices.techProfile?.data,
    }),
    shallowEqual
  );

  const isMainTech = checkRoles(techInfoData?.roles);
  const isPlatformTech = getAccessLevelByRoles(techInfoData?.roles).includes(
    ConfigConst.PT
  );
  let cmLoading = getDeviceLoading(
    selectedDevice.serialNumber,
    diagDevicesData
  );
  let cmSummaryDetails = getDeviceDetails(
    selectedDevice.serialNumber,
    "summary",
    diagDevicesData
  );
  let cmNodeDetails = getDeviceDetails(
    selectedDevice.serialNumber,
    "nodeInfo",
    diagDevicesData
  );
  let cmDocsisDetails = getDeviceDetails(
    selectedDevice.serialNumber,
    "docsis",
    diagDevicesData
  );
  let cmSummaryStatus =
    cmSummaryDetails?.hasOwnProperty("errorCode") || cmSummaryDetails === ""
      ? "ERROR"
      : "OK";
  let cmDocsisStatus =
    (cmDocsisDetails?.hasOwnProperty("errorCode") || cmDocsisDetails === "") &&
    cmDocsisDetails?.errorCode != 1009 &&
    (cwMonitorStatus === "ERROR" ||
      cwMonitorStatus?.toUpperCase() === "SKIPPED" ||
      cwMonitorStatus === "")
      ? "ERROR"
      : cmDocsisDetails?.hasOwnProperty("errorCode") &&
        cmDocsisDetails?.errorCode == 1009
      ? "Fail"
      : "OK";

  useEffect(() => {
    if (deviceDiagActions?.length !== 0) {
      deviceDiagActions?.map((action: any) => {
        if (action.tab === selDiagAction) {
          if (
            action.tab === "GW" &&
            !action.isOpen &&
            selectedDevice !== undefined
          ) {
            action.isOpen = true;
            if (gwDevicesInfo === undefined) {
              dispatch(
                GWDevicesClient.getGWDevices(
                  selectedDevice.macAddress,
                  selectedDevice.serialNumber,
                  getCMW3ID4Brand(selectedSA, brandName)
                )
              );
            }
          } else if (
            action.tab === "WIFI" &&
            !action.isOpen &&
            selectedDevice !== undefined
          ) {
            action.isOpen = true;
            if (wifiSettingsInfo === undefined) {
              dispatch(
                WIFIInfoClient.getWIFISettings(
                  selectedDevice.macAddress,
                  selectedDevice.serialNumber,
                  getCMW3ID(selectedSA,brandName)
                )
              );
            }
            if (wifiRadiosInfo === undefined) {
              dispatch(
                WIFIInfoClient.getWIFIRadios(
                  selectedDevice.macAddress,
                  selectedDevice.serialNumber,
                  getCMW3ID(selectedSA,brandName)
                )
              );
            }
          } else if (
            action.tab === "Ethernet" &&
            !action.isOpen &&
            selectedDevice !== undefined
          ) {
            action.isOpen = true;
          
            dispatch(
              EthernetClient.getnetworkInterfaces(
                selectedDevice.macAddress,
                selectedDevice.serialNumber,
                getCMW3ID4Brand(selectedSA, brandName),
                selectedDevice.oui
              )
            );
          }
          else if (
            action.tab === "GWStatus" &&
            !action.isOpen &&
            selectedDevice !== undefined
          ) {
            action.isOpen = true;
          
        // setmacAddress(sortedDevices.filter((d:any)=> d.deviceType==="WXLE"||
        // d.deviceType==="WXBB" ).map((d: any) => d.macAddress));
        //     dispatch(
        //       xleVisiblilityClient.getXleGateway(
        //         macAddress              
        //       )
        //     );
          }
           else if (
            action.tab === "CW" &&
            !action.isOpen &&
            selectedDevice !== undefined
          ) {
            action.isOpen = true;

            dispatch(CWMonitoringTracer(false));
            if (codewords === undefined && !cwMonitorLoading) {
              dispatch(
                CodeWordsClient.getCodewords(selectedDevice.macAddress, tracer)
              );
            }
          } else if (
            action.tab === "DE" &&
            !action.isOpen &&
            selectedDevice !== undefined
          ) {
            action.isOpen = true;
            if (docsisEventsInfo === undefined) {
              dispatch(
                CableModemClient.getCMDocsisEvents(selectedDevice.macAddress)
              );
            }
            if (flapsInfo === undefined &&isTechRole) {
              dispatch(
                CableModemClient.getCMFlapsData(selectedDevice.macAddress)
              );
            }
          }
        }
        if (action.tab === "GW" && action.isOpen) {
          setGwTabFlag(true);
        } else if (action.tab === "WIFI" && action.isOpen) {

          setWifiTabFlag(true);
        } else if (action.tab === "CW" && action.isOpen) {
          setCodewordTabFlag(true);
        } else if (action.tab === "DE" && action.isOpen) {
          setDocsisEventsTabFlag(true);
        }
        else  if (action.tab === "Ethernet" && action.isOpen) {
          setEthernetTabFlag(true);
          }
          else if(action.tab === "GWStatus" && action.isOpen){
            setGatewayStatusTabFlag(true);
          }
      });
    }
    setKey(selDiagAction);
  }, [selDiagAction]);

  const thunkDispatch = useDispatch<ThunkDispatch<any, any, any>>();

  
  useEffect(() => {
    // if (managedRouterInfo === null) {
    //   fetchManagedRouterInfo(selectedDevice.macAddress);
    // }
    // fetchManagedRouterInfo(selectedDevice.macAddress);
    if (cmSummaryDetails !== undefined && cmSummaryDetails?.length === 0) {
      dispatch(
        CableModemClient.getCMSummary(
          selectedDevice.serialNumber,
          selectedDevice.macAddress
        )
      );
    }

    // fetchManagedRouterInfo(selectedDevice.macAddress);
    if (tracer === true) {
      dispatch(CWMonitoringReset());
      dispatch(SetFailedCodewords(""));
      dispatch(SetCodewordValues(""));
      dispatch(SetSCQAMFLAG(false));
    }
    if (cmNodeDetails !== undefined && cmNodeDetails?.length === 0) {
      dispatch(
        CableModemClient.getCMNodeDetails(
          selectedDevice.serialNumber,
          isNull(selectedSA.shubId)
        )
      );
    }
    if (cmDocsisDetails !== undefined && cmDocsisDetails?.length === 0) {
      dispatch(
        CableModemClient.getCMDocsisInfo(
          selectedDevice.serialNumber,
          selectedDevice.macAddress
        )
      );
    }
    setGwTabFlag(false);
    setWifiTabFlag(false);
    setCodewordTabFlag(false);
    setDocsisEventsTabFlag(false);
    setGatewayStatusTabFlag(false);
  }, [selectedDevice]);

  useEffect(() => {
    if (cmDocsisDetails?.length !== 0) {
      dispatch(
        SetDeviceStatus(
          selectedDevice?.serialNumber,
          selectedDevice?.deviceCategory,
          (cmDocsisDetails?.hasOwnProperty("errorCode") ||
            cmDocsisDetails === "") &&
            cmDocsisDetails?.errorCode != 1009 &&
            (cwMonitorStatus === "ERROR" ||
              cwMonitorStatus?.toUpperCase() === "SKIPPED")
            ? "skipped"
            : cmDocsisDetails?.hasOwnProperty("overallStatus")
            ? cmDocsisDetails?.overallStatus?.toLowerCase()
            : cmDocsisDetails?.hasOwnProperty("errorCode")
            ? "skipped"
            : "fail"
        )
      );
    }
  }, [cmDocsisDetails]);

  const refreshCW = () => {
    dispatch(CWMonitoringReset());
    dispatch(SetFailedCodewords(""));
    dispatch(SetSCQAMFLAG(false));
    dispatch(CWMonitoringTracer(false));
    setEthernetTabFlag(false);
    setGatewayStatusTabFlag(false);
    dispatch(CodeWordsClient.getCodewords(selectedDevice.macAddress, tracer));
  };

  return (
    <div className="detailDiagCont">
      <section className="deviceData">
        <div className="deviceStatusCont">
          <p>
            <span>{labels.MODEL.toUpperCase()} : </span>
            <span>{isNull(selectedDevice.deviceModel)}</span>
          </p>
          <p className="deviceOverallStat">
            <span>{labels.STATUS.toUpperCase()} : </span>
            {failedAttributes?.length > 0 ? (
              renderDeviceStatus(undefined, "Fail")
            ) : !cmLoading ? (
              renderDeviceStatus(cmDocsisDetails, cmDocsisStatus)
            ) : (
              <span style={{ fontStyle: "italic" }}>{labels.FETCHING}</span>
            )}
          </p>
        </div>
        <p>
          <span>{labels.SERIAL_NUMBER.toUpperCase()} : </span>
          <span>{isNull(selectedDevice.serialNumber)}</span>
        </p>
        <FailedAttributesCm cmDocsisInfo={cmDocsisDetails} />
        <MarginalPassAttributesCm cmDocsisInfo={cmDocsisDetails} />
      </section>
      <section className="detailDiagTabs">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k: any) => handleActionClick(k)}
        >
          {/* {((isMainTech && isPlatformTech) || !isMainTech) && ( */}
          <Tab eventKey={labels.CM} title={labels.CABLE_MODEM.toUpperCase()}>
            <CableModemInfo
              selectedDevice={selectedDevice}
              selectedSA={selectedSA}
              docsisLoading={cmLoading}
              cmSummarydata={cmSummaryDetails}
              managedRouterData={managedRouterInfo}
              cmNodeInfo={cmNodeDetails}
              cmDocsisInfo={cmDocsisDetails}
              cmSummaryInfoStatus={cmSummaryStatus}
              cmDocsisInfoStatus={cmDocsisStatus}
            />
          </Tab>
          {/* )} */}
          {docsisEventsTabFlag && (
            <Tab eventKey="DE" title="DOCSIS EVENT LOGS">
              <CMDocsisEvents selectedDevice={selectedDevice} />
            </Tab>
          )}
          {/* {((isMainTech && isPlatformTech) || !isMainTech) && */}
          {codewordTabFlag && (
            <Tab
              eventKey="CW"
              title={
                <span className="tabStatusCont">
                  CODEWORD MONITORING
                  {!cwMonitorLoading && cwMonitorStatus === "OK" ? (
                    <FaCheckCircle color="#00985f" />
                  ) : !cwMonitorLoading && cwMonitorStatus === "ERROR" ? (
                    <img style={{ height: "16px" }} src={ErrorIcon} />
                  ) : cwMonitorLoading ? (
                    <img style={{ height: "20px" }} src={RefreshGif} />
                  ) : (
                    <></>
                  )}
                </span>
              }
            >
              {codewords !== undefined && !cwMonitorLoading ? (
                <>
                  <div style={{ textAlign: "end" }}>
                    <ReloadData
                      refreshData={refreshCW}
                      disabled={cwMonitorLoading}
                    />
                  </div>
                  <ViewCodewordSteps
                    hsiCertiData={codewords}
                    seleDevice={selectedDevice}
                  />
                </>
              ) : (
                <CodeWordMonitor seleDevice={selectedDevice} />
              )}
            </Tab>
          )}
          {gwTabFlag && (
            <Tab eventKey={labels.GW} title={labels.GW_DEVICES.toUpperCase()}>
              <GWDevicesInfo
                selectedDevice={selectedDevice}
                selectedSA={selectedSA}
              />
            </Tab>
          )}
          {wifiTabFlag && (
            <Tab
              eventKey={labels.WIFI}
              title={labels.WIFI_SETTINGS.toUpperCase()}
            >
              <WifiInfo
                selectedDevice={selectedDevice}
                selectedSA={selectedSA}
              />
            </Tab>
          )}
           {ethernetTabFlag && !isLegacyAcc && (
            <Tab eventKey={labels.ETHERNET} title={labels.ETHERNET.toUpperCase()}>
             <EthernetInfo
               selectedDevice={selectedDevice}
               selectedSA={selectedSA}
             />
           </Tab>
          )
          }
           {gatewayStatusTabFlag  && flagXLE?.data &&(
            <Tab eventKey={labels.GWStatus} title={"GW STATUS"}>
             <XLEGWInfo macAddress={ selectedDevice.macAddress}/>
           </Tab>
          )
          }
        </Tabs>
      </section>
    </div>
  );
};
export default CmDiagnostics;
