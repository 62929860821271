import { useEffect } from "react";
import { zoomLevelFetchBoundaries } from "../../config/mapConfig";
import appConst from "../../constants/appConst";
import { DrawerViewType } from "../../store/reduxTypes";
import {
  setCurrentMapCenter,
  setCurrentViewBDY,
  setCurrentZoomLevel,
} from "../../store/slices/googleMaps";
import { setDrawerView } from "../../store/slices/mapDrawer";
import {
  setHighlightedPolygon,
  setRightClickMapElement,
  setSelectedMapElement,
  setShowRightClickMenu,
} from "../../store/slices/mapItems/mapDetails";
import { getApiPolygons } from "../../store/slices/mapItems/mapPolygons";
import { GetMapDrawerProps } from "../reduxFunctions/getTopologyState";

export function handleOnIdle(map: any, dispatch: any) {
  const nE = map?.getBounds()?.getNorthEast()?.toJSON();
  const sW = map?.getBounds()?.getSouthWest()?.toJSON();
  if (map?.getZoom() >= zoomLevelFetchBoundaries) {
    //Saving current Viewport
    dispatch(setCurrentViewBDY({ northEast: nE, southWest: sW }));

    //Get polygons within current viewport
    dispatch(getApiPolygons(nE, sW));
  }
  //Setting current center
  dispatch(
    setCurrentMapCenter({
      latitude: map?.getCenter()?.lat(),
      longitude: map?.getCenter()?.lng(),
    })
  );
}

export function handleZoomChange(newZoomLevel: any, dispatch: any) {
  if (newZoomLevel) {
    //Saving current zoom level
    dispatch(setCurrentZoomLevel(newZoomLevel));
  }
}

export const useElementEventHandler = (gMap: any, dispatch: any) => {
  useEffect(() => {
    if (gMap !== undefined) {
      gMap.data.addListener("mouseover", function (event: any) {
        const notClickableElmts = [appConst.rfDrop];
        const isNotClickable = notClickableElmts?.includes(
          event.feature?.getProperty("type")
        );
        if (!isNotClickable) {
          if (event?.feature?.getGeometry()?.getType() === appConst.line) {
            gMap.data.revertStyle();
            gMap.data.overrideStyle(event.feature, {
              strokeWeight: 5,
              strokeOpacity: 0.5,
            });
          }
        }
      });

      gMap.data.addListener("mouseout", function (event: any) {
        if (event?.feature?.getGeometry()?.getType() === appConst.line) {
          gMap.data.revertStyle();
        }
      });

      gMap?.data?.addListener("click", function (event: any) {
        const notClickableElmts = [appConst.rfDrop, appConst.rfdropaddress];
        const featureType = event?.feature?.getGeometry()?.getType();
        const isNotClickable = notClickableElmts?.includes(
          event.feature?.getProperty("type")
        );
        dispatch(setDrawerView("standard" as DrawerViewType));
        if (!isNotClickable) {
          const data = {
            id: event?.feature?.getProperty("featureId"),
            type: event.feature?.getProperty("type"),
            data: event?.feature?.getProperty("data"),
            rootNodeSiteId: event?.feature?.getProperty("rootNodeSiteId"),
          };
          const activeElement = {
            geometry:
              featureType === appConst.Point
                ? event.feature?.getGeometry()?.get()?.toJSON()
                : null,
            properties: data,
          };

          dispatch(setHighlightedPolygon(data));
          dispatch(setSelectedMapElement(activeElement));
        }
      });

      gMap.data.addListener("rightclick", function (event: any) {
        const featureType = event?.feature?.getGeometry()?.getType();
        const isClickable = appConst.enabledElementsForTrace?.includes(
          event.feature?.getProperty("type")
        );
        if (isClickable && featureType === appConst.Point) {
          const activeRightClickElement = {
            geometry:
              featureType === appConst.Point
                ? event.feature?.getGeometry()?.get()?.toJSON()
                : null,
            properties: {
              id: event?.feature?.getProperty("featureId"),
              type: event.feature?.getProperty("type"),
              data: event?.feature?.getProperty("data"),
              rootNodeSiteId: event?.feature?.getProperty("rootNodeSiteId"),
            },
          };
          dispatch(setShowRightClickMenu(true));
          dispatch(setRightClickMapElement(activeRightClickElement));
        }
      });
    }
  }, [gMap]);
};

export const useMDUElementEventHandler = (gMap: any, dispatch: any) => {
  useEffect(() => {
    if (gMap !== undefined) {
      gMap?.data?.addListener("click", function (event: any) {
        const featureType = event?.feature?.getGeometry()?.getType();
        if (featureType === appConst.Point) {
          dispatch(setDrawerView("standard" as DrawerViewType));
          const data = {
            id: event?.feature?.getProperty("featureId"),
            type: event.feature?.getProperty("type"),
            data: event?.feature?.getProperty("data"),
          };
          const activeElement = {
            geometry: event.feature?.getGeometry()?.get()?.toJSON(),
            properties: data,
          };

          dispatch(setSelectedMapElement(activeElement));
        }
      });
    }
  }, [gMap]);
};
