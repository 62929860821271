import { useEffect, useState } from "react";
import { BsLayersHalf } from "react-icons/bs";
import { useDispatch } from "react-redux";

import "../../styles/map/Map.scss";
import { MapStyleTypes } from "../../store/reduxTypes";
import { setMapStyle } from "../../store/slices/googleMaps";
import standardImg from "../../resources/images/mapStyles/standard.png";
import aubergineImg from "../../resources/images/mapStyles/aubergine.png";
import darkImg from "../../resources/images/mapStyles/dark.png";
import nightImg from "../../resources/images/mapStyles/night.png";
import retroImg from "../../resources/images/mapStyles/retro.png";
import silverImg from "../../resources/images/mapStyles/silver.png";
import { GetGoogleMaps } from "../../util/reduxFunctions/getTopologyState";
import AppLabels from "../../../constants/App_Labels";
import { MAP_STYLE_NAME } from "../../constants/storageConst";

export default function MapStyles() {
  const labels = AppLabels();
  const dispatch = useDispatch();
  const { activeMapStyleName } = GetGoogleMaps();
  const [showItems, setShowItems] = useState(false);

  //get and set map style name from storage if exists
  useEffect(() => {
    const mapStyleName = localStorage.getItem(MAP_STYLE_NAME);
    if (mapStyleName) dispatch(setMapStyle(mapStyleName));
  }, []);

  const items = [
    {
      id: "standard" as MapStyleTypes,
      title: labels.Standard,
      img: standardImg,
    },
    {
      id: "silver" as MapStyleTypes,
      title: labels.Silver,
      img: silverImg,
    },
    // {
    //   id: "retro" as MapStyleTypes,
    //   title: labels.Retro,
    //   img: retroImg,
    // },
    {
      id: "dark" as MapStyleTypes,
      title: labels.Dark,
      img: darkImg,
    },
    {
      id: "night" as MapStyleTypes,
      title: labels.Night,
      img: nightImg,
    },
    {
      id: "aubergine" as MapStyleTypes,
      title: labels.Aubergine,
      img: aubergineImg,
    },
  ];

  const changeMapStyle = (style: MapStyleTypes) => {
    dispatch(setMapStyle(style));
    setShowItems(!showItems);
    localStorage.setItem(MAP_STYLE_NAME, style);
  };

  return (
    <div className="layerCont">
      <div className={showItems ? "layerItemsCont" : "closedLayerItemsCont"}>
        {items.map((e: any) => (
          <div
            key={e.id}
            onClick={() => changeMapStyle(e.id)}
            className={
              activeMapStyleName === e.id
                ? "selectedLayerSingleItemCont"
                : "layerSingleItemCont"
            }
          >
            <div className="imgBorder">
              <img src={e.img} alt={e.title} />
            </div>
            <span>{e.title}</span>
          </div>
        ))}
      </div>
      {!showItems && (
        <div className="layerIconCont" onClick={() => setShowItems(!showItems)}>
          <BsLayersHalf />
        </div>
      )}
    </div>
  );
}
