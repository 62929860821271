import { useState } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme } from "@material-ui/core/styles";

import AppLabels from "../../../../../constants/App_Labels";
import InitialChecksView from "../inner/InitialChecksView";
import CommentsView from "../inner/CommentsView";

interface Props {
  initialChecks: any;
  setInitialChecks: any;
  comments: any;
  setComments: any;
}

export default function HighRiskView(props: Props) {
  const labels = AppLabels();
  const styles = useStyles();
  const steps = [labels.INITIAL_CHECKS_COMPLETED, labels.COMMENTS];

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{ [k: number]: boolean }>({});

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <InitialChecksView
            initialChecks={props.initialChecks}
            setInitialChecks={props.setInitialChecks}
          />
        );
      case 1:
        return (
          <CommentsView
            comments={props.comments}
            setComments={props.setComments}
          />
        );
      default:
        return "";
    }
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    const completedCopy = completed;
    completedCopy[activeStep] = true;
    setCompleted(completedCopy);
  };

  const onStepClick = (step: number) => () => {
    setActiveStep(step);
    const completedCopy = completed;
    completedCopy[step] = true;
    setCompleted(completedCopy);
  };

  const isNextDisabled = (step: number) => {
    const selectedInitialChecks = props.initialChecks?.filter(
      (item: any) => item.value != null
    );
    if (
      step === 0 &&
      props.initialChecks?.length != selectedInitialChecks?.length
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="overallPJI">
      <Stepper
        non-linear
        activeStep={activeStep}
        orientation="vertical"
        className={styles.root}
      >
        {steps?.map((label: any, index: any) => (
          <Step key={label} completed={completed[index]}>
            <StepLabel onClick={onStepClick(index)}>{label}</StepLabel>
            <StepContent>
              <div className={styles.actionsContainer}>
                <Typography className="overallPJI">
                  {getStepContent(index)}
                </Typography>
                <div className="btnContainerPJI">
                  {activeStep !== steps?.length - 1 ? (
                    <button
                      onClick={handleNext}
                      className="stepNextBtn"
                      disabled={isNextDisabled(activeStep)}
                    >
                      <span>{labels.Next}</span>
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    border: "4px",
    padding: 0,
    margin: 0,
    marginTop: "20px",
    "& .MuiStepIcon-completed": { color: "var(--good) !important" },
    "& .MuiStepIcon-active": { color: "grey" },
    "& .Mui-disabled .MuiStepIcon-root": { color: "grey" },
  },
  button: {
    marginTop: theme.spacing(5),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));
