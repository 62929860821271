import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../middleware/apiActions";
import { CONFIG_URI } from "../middleware/apiEndpoints";
import storeConst from "../storeConst";

const slice = createSlice({
  name: "configs",
  initialState: {
    data: "",
    isLoading: false,
    error: undefined,
    status: "",
  },
  reducers: {
    configsBegin: (configs) => {
      configs.isLoading = true;
    },
    configsSuccess: (configs, action) => {
      configs.isLoading = false;
      configs.data = action.payload;
      configs.status = storeConst.OK;
    },
    configsFailure: (configs, action) => {
      configs.isLoading = false;
      configs.error = action.payload;
      configs.data = "";
      configs.status = storeConst.ERROR;
    },
  },
});

const { configsBegin, configsSuccess, configsFailure } = slice.actions;
export default slice.reducer;

export const getConfigs = (): any => (dispatch: any, getState: any) => {
  const { data } = getState().slices.topologyState.configs;

  if (data === "") {
    dispatch(
      apiCallBegan({
        url: CONFIG_URI,
        onStart: configsBegin.type,
        onSuccess: configsSuccess.type,
        onError: configsFailure.type,
      })
    );
  }
};
