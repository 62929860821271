export const GET_PJI_SUCCESS = "GET_PJI_SUCCESS";
export const GET_PJI_BEGIN = "GET_PJI_BEGIN";
export const GET_PJI_FAILURE = "GET_PJI_FAILURE";
export const GET_PJI_RESET = "GET_PJI_RESET";

interface GetPJISuccess {
  type: typeof GET_PJI_SUCCESS;
  payload: any;
}

interface GetPJIBegin {
  type: typeof GET_PJI_BEGIN;
}

interface GetPJIFailure {
  type: typeof GET_PJI_FAILURE;
  payload: Error;
}

interface GetPJIReset {
  type: typeof GET_PJI_RESET;
}

export type NewPJIHistoryActionTypes =
  | GetPJISuccess
  | GetPJIBegin
  | GetPJIFailure
  | GetPJIReset;
