import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import {
  sendValidateBegin,
  sendValidateFailure,
  sendValidateSuccess,
} from "../../../store/ducksPattern/validateData";


export default class ValidateClient {
  static sendValidateNoti =
    (
      requestType: string,
      data: any
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "validate/response",
      };
      dispatch(sendValidateBegin(requestType));
      HttpClient.post<any>(httpParameters, data)
        .then((res) => {
          dispatch(sendValidateSuccess(res));
          return res;
        })
        .catch((error) => {
          dispatch(sendValidateFailure(error));
          return error;
        });
    };
}
