import { useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ODUClient from "../../../network/httpclient/odu/ODUClient";
import LoaderGif from "../../../resources/images/loader.webp";
import WSubDetails from "./WSubDetails";
import WSubMobility from "./WSubMobility";
import { ResetWirelessSubscriber } from "../../../store/actions/account/diagnostics/odu/WirelessSubscriberAction";
import ExceptionBox from "../../reusable/ExceptionBox";
import { isNull } from "../../reusable/Util";
import ReloadData from "../../reusable/ReloadData";

const WirelessSubscriberInfo = (props: any) => {
  const { selectedDevice } = props;
  const dispatch = useDispatch();
  const { wirelessSubLoading, WirelessSubInfo, WirelessSubError } = useSelector(
    (state: any) => ({
      wirelessSubLoading: state.AccountState.WirelessSubInfo.isLoading,
      WirelessSubInfo: state.AccountState.WirelessSubInfo.data,
      WirelessSubError: state.AccountState.WirelessSubInfo.error,
    }),
    shallowEqual
  );
  const [wirelessNumResult, setWirelessNumResult] = useState("");
  const [errorFlag, setErrorFlag] = useState(false);
  const [resultEnabled, setResultEnabled] = useState(false);
  const [accordionIndex, setAccordionIndex] = useState(0);
  const [accordionValue, setAccordionValue] = useState(1);
  useEffect(() => {
    setAccordionValue(1);
  }, [accordionIndex]);
  useEffect(() => {
    setAccordionIndex(0);
    dispatch(ResetWirelessSubscriber());
  }, [selectedDevice]);
  useEffect(() => {
    if (WirelessSubInfo !== undefined) {
      setResultEnabled(true);
      setErrorFlag(false);
    }
    if (WirelessSubInfo === undefined) {
      if (
        selectedDevice?.msisdn !== "" &&
        selectedDevice?.msisdn.length === 10
      ) {
        dispatch(ODUClient.getWirelessSubInfo(selectedDevice?.msisdn));
        setWirelessNumResult(selectedDevice?.msisdn);
      } else {
        setErrorFlag(true);
      }
    }
  }, [WirelessSubInfo]);
  useEffect(() => {
    if (WirelessSubError !== undefined) setErrorFlag(true);
  }, [WirelessSubError]);
  const handleAccordionToggle = (index: number, value: number) => {
    if (value === 1) {
      if (index === accordionIndex) setAccordionValue(0);
      else setAccordionValue(1);
      setAccordionIndex(index);
    } else if (value === 0) {
      if (index === accordionIndex) setAccordionValue(1);
      else setAccordionValue(0);
      setAccordionIndex(index);
    }
  };

  const refreshData = () => {
    dispatch(ODUClient.getWirelessSubInfo(wirelessNumResult));
  };

  return (
    <div>
      <section className={resultEnabled ? "wsContainer" : "wsCenterContainer"}>
        {errorFlag ? (
          <Card
            className={
              resultEnabled ? "searchSubInfoCard" : "searchCSubInfoCard"
            }
          >
            <p className="wsErrorMessage">
              Invalid number. Please check the number and try again.
            </p>
          </Card>
        ) : (
          <></>
        )}
      </section>
      <div className="refreshCont">
        <ReloadData refreshData={refreshData} />
      </div>
      {!wirelessSubLoading ? (
        <div className="cmSummaryCont">
          {resultEnabled ? (
            <p className="wsResultMessage">
              Displaying result for Subscriber number : {wirelessNumResult}
            </p>
          ) : (
            <></>
          )}
          <Accordion defaultActiveKey="0">
            {WirelessSubInfo !== undefined ? (
              <>
                <WSubDetails
                  subscriberData={WirelessSubInfo}
                  accordionValue={accordionValue}
                  accordionIndex={accordionIndex}
                  handleAccordionToggle={handleAccordionToggle}
                />
                <WSubMobility
                  subscriberData={WirelessSubInfo}
                  accordionValue={accordionValue}
                  accordionIndex={accordionIndex}
                  handleAccordionToggle={handleAccordionToggle}
                />
              </>
            ) : WirelessSubError !== undefined ? (
              <ExceptionBox
                exBoxType="error"
                errSecName="ODU Wireless Subscriber"
                errCode={isNull(WirelessSubError?.errorCode)}
                ErrorMSG={isNull(WirelessSubError?.errorMessage)}
                srcSystem={
                  WirelessSubError?.hasOwnProperty("sourceSystem")
                    ? WirelessSubError?.sourceSystem
                    : "Request"
                }
                primaryBtn={true}
                secBtn={true}
                handleRefresh={() => setErrorFlag(false)}
              />
            ) : (
              <></>
            )}
          </Accordion>
        </div>
      ) : (
        <img className="loaderImg" src={LoaderGif} />
      )}
    </div>
  );
};

export default WirelessSubscriberInfo;
