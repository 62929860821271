import { Snackbar } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const ResponseSnackBar = (props: any) => {
  const { open, responseType, requestType, handleClose } = props;
  const renderAlert = () => {
    if (responseType === "OK") {
      return (
        <Alert onClose={handleClose} severity="success">
          {requestType} request submitted successfully.
        </Alert>
      );
    } else if (responseType === "ERROR") {
      return (
        <Alert onClose={handleClose} severity="error">
          {requestType} request failed.
        </Alert>
      );
    }
    /* return(<Alert onClose={handleClose} severity="info">This is an information message!</Alert>) */
  };

  return (
    <div className="snackBarCont">
      <Snackbar
        className="snackBar"
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        style={props?.isHeader ? { top: "0px" } : undefined}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        {renderAlert()}
      </Snackbar>
    </div>
  );
};

export default ResponseSnackBar;
