import { createSlice } from "@reduxjs/toolkit";
import { changeTimeFormat } from "../../../../../components/reusable/Util";
import appConst from "../../../../constants/appConst";
import {
  combineTeleData,
  convertStreamDataToJson,
  isCoordsAvaileable,
  mergeDatasets,
  newTeleData,
} from "../../../../util/utilFuncs";
import { TELE_ABORT_KEY } from "../../../middleware/abortKeys";
import { apiCallBegan } from "../../../middleware/apiActions";
import { abortRequest } from "../../../middleware/apiClient";
import {
  ADDRESS_MODEM_LIST,
  NETWORK_SERVICE_URL,
  TELE_MULTIDEVICE_STREAM_DIAG,
} from "../../../middleware/apiEndpoints";
import { DiagExecutionType } from "../../../reduxTypes";
import storeConst from "../../../storeConst";
import { decrApiCall, incrApiCall, setMapCenter } from "../mapDetails";

let telemetryData: any = [];
const batchSize = (window as any).config?.TELEMETRY_BATCH_SIZE ?? 5;
const slice = createSlice({
  name: "addresstelemetry",
  initialState: {
    mlIsLoading: false, //ml = modemsList
    mlData: undefined,
    mlError: undefined,
    mlStatus: "",
    nodeId: undefined,
    advFCond: appConst.or.toUpperCase(),
    activeProfile: appConst.default.toUpperCase(),
    advancedFilters: undefined,
    allDiagIsLoading: false,
    isDevicesVisible: false,
    isAborted: false,
    apiCallCounter: 0, //used for stop telemetry button
    diagStartTime: undefined,
    diagEndTime: undefined,
    diagType: storeConst.all as DiagExecutionType, //diagnostics type, eg (all, summary)
    viewType: storeConst.all,
    allDiagStatus: "",
    allDiagError: undefined,
    modemType: "",
    isCWEnabled: true,
    isNeighbourhood: false,
    neighbourMacId: undefined,
  },
  reducers: {
    getAddressModemsListBegin: (tl) => {
      tl.mlIsLoading = true;
      tl.apiCallCounter++;
      tl.mlStatus = "";
      tl.isAborted = false;
      tl.diagStartTime = undefined;
      tl.diagEndTime = undefined;
      tl.allDiagStatus = "";
    },
    // getAddressModemsListSuccess: (tl, { payload }) => {
    //   console.log(payload, "payload");
    //   tl.mlIsLoading = false;
    //   tl.apiCallCounter--;
    //   tl.mlData = payload;
    //   tl.allDiagError = undefined;
    //   tl.mlStatus = storeConst.OK;

    //   tl.allDiagIsLoading = true; // initiate diagnostics execution
    // },
    getAddressModemsListSuccess: (tl, { payload }) => {
      tl.mlIsLoading = false;
      tl.apiCallCounter--;
      tl.mlData = payload;
      tl.mlStatus = storeConst.OK;
      tl.allDiagError = undefined;
      //   if (
      //     payload?.diagnoseDevices &&
      //     (tl?.modemType?.toUpperCase() !== "CM" ||
      //       (tl.neighbourMacId && tl?.modemType?.toUpperCase() === "CM"))
      //   ) {
      tl.allDiagIsLoading = true; // initiate diagnostics execution
      //   }
    },
    getAddressModemsListFailure: (tl, { payload }) => {
      tl.mlIsLoading = false;
      tl.apiCallCounter--;
      tl.mlError = payload;
      tl.mlData = undefined;
      tl.mlStatus = storeConst.ERROR;
    },
    setNodeId: (tl, { payload }) => {
      tl.nodeId = payload;
    },
    setNeighbourMacId: (tl, { payload }) => {
      tl.neighbourMacId = payload;
    },
    getStreamAddressDataBegin: (tl) => {
      let startTime: any = changeTimeFormat(Date.now());
      tl.apiCallCounter++;
      tl.allDiagStatus = "";
      tl.allDiagError = undefined;
      tl.diagStartTime = startTime;
    },
    getStreamAddressDataSuccess: (tl, { payload }) => {
      let currMlData: any = JSON.parse(JSON.stringify(tl.mlData));

      let dataChunk = convertStreamDataToJson(payload);

      if (dataChunk?.length > 0 && currMlData?.length > 0) {
        telemetryData = newTeleData(currMlData, dataChunk);

        let combinedData = [];
        if (telemetryData?.length >= Number(batchSize)) {
          combinedData = combineTeleData(currMlData, telemetryData);

          if (combinedData) {
            tl.mlData = combinedData;
            telemetryData = [];
          }
        }
        if (currMlData?.length === dataChunk?.length && combinedData) {
          let endTime: any = changeTimeFormat(Date.now());
          tl.allDiagIsLoading = false;
          tl.apiCallCounter--;
          tl.diagEndTime = endTime;
          if (telemetryData?.length > 0)
            tl.mlData = combineTeleData(currMlData, telemetryData);
          tl.allDiagStatus = storeConst.OK;
          tl.allDiagError = undefined;
        }
      }
    },
    getStreamAddressDataFailure: (tl, { payload }) => {
      let endTime: any = changeTimeFormat(Date.now());
      tl.allDiagIsLoading = false;
      tl.apiCallCounter--;
      tl.diagEndTime = endTime;
      tl.allDiagStatus = storeConst.ERROR;
      tl.allDiagError = payload;
    },
    setDiagType: (tl, { payload }) => {
      tl.diagType = payload; //set to the type of diagnostics you want to run. eg (all, summary)
    },
    setAddressViewType: (tl, { payload }) => {
      tl.viewType = payload;
    },
    setModemType: (tl, { payload }) => {
      tl.modemType = payload;
    },
    setDiagLoading: (tl, { payload }) => {
      let currMlData: any = JSON.parse(JSON.stringify(tl.mlData));
      tl.allDiagIsLoading = payload;
      if (!payload && telemetryData?.length > 0) {
        tl.mlData = mergeDatasets(currMlData, telemetryData);
        telemetryData = [];
      }
    },
    setDevicesVisible: (tl, { payload }) => {
      tl.isDevicesVisible = payload;
    },
    setAdvancedFilters: (tl, { payload }) => {
      tl.advancedFilters = payload;
    },
    setAdvFCond: (tl, { payload }) => {
      tl.advFCond = payload;
    },
    setSelectedProfile: (tl, { payload }) => {
      tl.activeProfile = payload;
    },
    setAborted: (tl, { payload }) => {
      let endTime: any = changeTimeFormat(Date.now());
      tl.isAborted = payload;
      tl.diagEndTime = endTime;
    },
    setIsCWEnabled: (tl, { payload }) => {
      tl.isCWEnabled = payload;
    },
    setIsNeighbourhood: (tl, { payload }) => {
      tl.isNeighbourhood = payload;
    },
  },
});

export const {
  setDiagType,
  setDiagLoading,
  setDevicesVisible,
  setAborted,
  setAdvFCond,
  setSelectedProfile,
  setAdvancedFilters,
  setAddressViewType,
  setModemType,
  setIsCWEnabled,
  setIsNeighbourhood,
  setNodeId,
  setNeighbourMacId,
} = slice.actions;

const {
  getAddressModemsListBegin,
  getAddressModemsListSuccess,
  getAddressModemsListFailure,
  getStreamAddressDataBegin,
  getStreamAddressDataSuccess,
  getStreamAddressDataFailure,
} = slice.actions; //should not be exposed to outside
export default slice.reducer;

interface ModemsListType {
  streetNo?: string;
  streetName?: string;
  modemType?: string;
}
interface DiagRequestType {
  macList: any;
  nodeType?: string;
  modemType?: string;
  isCWEnabled?: boolean;
  isNeighbourhood?: boolean;
}
export const getAddressModemsList =
  ({ streetNo, streetName, modemType }: ModemsListType) =>
  (dispatch: any) => {
    dispatch(
      apiCallBegan({
        baseURL: NETWORK_SERVICE_URL,
        url: ADDRESS_MODEM_LIST,
        params: {
          streetNo: streetNo,
          streetName: streetName,
          modemType: modemType,
        },
        method: "post",
        onStart: getAddressModemsListBegin.type,
        onSuccess: getAddressModemsListSuccess.type,
        onError: getAddressModemsListFailure.type,
        onStartDispatch: incrApiCall.type,
        onSuccessDispatch: decrApiCall.type,
        onErrorDispatch: decrApiCall.type,
      })
    );
  };

export const getStreamAddressData =
  ({
    macList,
    nodeType,
    modemType,
    isCWEnabled,
    isNeighbourhood,
  }: DiagRequestType) =>
  (dispatch: any, getState: any) => {
    const { diagType, mlData } = getState().slices.topologyState.telemetry;
    let getFirstCoords = mlData?.filter((cm: any) => {
      if (isCoordsAvaileable(cm)) {
        return cm;
      }
    });
    dispatch(setMapCenter(getFirstCoords?.[0]?.coordinate));
    const diagUrl = TELE_MULTIDEVICE_STREAM_DIAG;
    dispatch(
      apiCallBegan({
        baseURL: NETWORK_SERVICE_URL,
        url: diagUrl,
        method: "post",
        data: JSON.stringify({
          macList: macList,
          nodeType: nodeType,
          modemType: modemType,
          isCWEnabled: isCWEnabled,
          isNeighbourhood: isNeighbourhood,
        }),
        responseType: "stream",
        abortKey: TELE_ABORT_KEY,
        onStart: getStreamAddressDataBegin.type,
        onSuccess: getStreamAddressDataSuccess.type,
        onError: getStreamAddressDataFailure.type,
      })
    );
  };

export const stopDiagExecution = () => (dispatch: any) => {
  dispatch(setDiagLoading(false));
  dispatch(setAborted(true));
  abortRequest(TELE_ABORT_KEY);
};
