import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { Divider } from "@material-ui/core";

import "../../design-tokens/forms/WorkOrderSelection.scss";
import AppLabels from "../../constants/App_Labels";
import {
  GetActiveAccountDetails,
  GetFormHistory,
  GetFormInfo,
  GetRpaInfo,
} from "../../get-api/GetResponse";
import ExceptionBox from "../reusable/ExceptionBox";
import WorkOrderSelection from "./WorkOrderSelection";
import ActivityIndicator from "../reusable/ActivityIndicator";
import ResponseSnackBar from "../reusable/ResponseSnackBar";
import ConfigConst from "../../constants/ConfigConst";
import { useDispatch } from "react-redux";
import FormsHistoryClient from "../../network/httpclient/history/FormsHistoryClient";
import { getDate } from "../reusable/Util";

interface Props {
  isWOSelectionComplete: boolean;
  setIsWOSelectionComplete: any;
  setWOData: any;
  formLabel: string;
  formName: string;
  children: JSX.Element;
  onSubmit: any;
  onPreview?: any;
  disableSubmit: any;
  selectedWOID: string;
  setSelectedWOID: any;
  setShowEntireModal: any;
  onBannerSuccess?: any;
  disableFormUpdate?: boolean;
  hideSubmitButton?: boolean;
  workOrderData?: any;
  selectedCustType?: string;
  setSelectedCustType?: any;
  showPreview?: any;
  isFromCertification?: boolean;
}

export default function FormBoilerplate(props: Props) {
  const labels = AppLabels();
  const dispatch = useDispatch();
  const formHistory = GetFormHistory();
  const formInfo = GetFormInfo();
  const accountNumber = GetActiveAccountDetails()?.accountNumber;
  const [bannerVisible, setBannerVisible] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [submittedFlag, setSubmittedFlag] = useState(false);
  const [formHistoryInfo, setFormHIstoryInfo] = useState(false);
  const disableFormUpdate =
    props.disableFormUpdate !== undefined ? props.disableFormUpdate : true;
  let submitDisabled = props.disableSubmit();
  const rpaInfo = GetRpaInfo();

  useEffect(() => {
    if (formHistory?.dataList === undefined) {
      setFormHIstoryInfo(false);
    } else {
      formHistory?.dataList?.some((e: any) => {
        if (e?.formName === props?.formName) return setFormHIstoryInfo(true);
      });
    }
  }, [formHistory]);

  useEffect(() => {
    if (!bannerVisible && formInfo.isLoading && isSubmitDisabled) {
      setBannerVisible(true);
    }
  }, [formInfo.isLoading]);

  useEffect(() => {
    if (submitDisabled !== undefined && !bannerVisible) {
      setIsSubmitDisabled(submitDisabled);
    }
  }, [submitDisabled]);

  // Refresh forms history data once form is submitted or updated
  useEffect(() => {
    if (submittedFlag && formInfo.status === ConfigConst.OK) {
      dispatch(
        FormsHistoryClient.getHistory(
          "",
          "",
          accountNumber,
          "",
          "",
          getDate("yesterday"),
          getDate("now"),
          "",
          25,
          0,
          ""
        )
      );
      props.setShowEntireModal(false);
    }
  }, [formInfo.status, submittedFlag]);

  const onSubmit = () => {
    setIsSubmitDisabled(true);
    props.onSubmit();
  };
  const postBannerAction = () => {
    setBannerVisible(false);
    setSubmittedFlag(true);
  };

  return (
    <div style={{ flex: 1 }}>
      <WorkOrderSelection
        selectedWOID={props.selectedWOID}
        setSelectedWOID={props.setSelectedWOID}
        formName={props.formName}
        isWOSelectionComplete={props.isWOSelectionComplete}
        setIsWOSelectionComplete={props.setIsWOSelectionComplete}
        setWOData={props.setWOData}
        workOrderData={props.workOrderData}
        selectedCustType={props.selectedCustType}
        setSelectedCustType={props.setSelectedCustType}
        isFromCertification={props.isFromCertification}
      />

      {formHistory.isLoading ? (
        <ActivityIndicator />
      ) : formHistory?.data?.id && disableFormUpdate ? (
        <div className="diagErrorCont">
          <ExceptionBox
            headerText={
              props.formLabel +
              " " +
              labels.has_already_been_submitted_for_this_Work_Order_ID_and_you_cannot_submit_another_one
            }
            bodyText={labels.Change_Work_Order_ID_and_try_again}
          />
        </div>
      ) : (
        <>
          {props.children}
          <Divider style={{ margin: "20px 0px 20px 0px" }} />
          <div className="submitBtnContainer">
            {formInfo?.isLoading || rpaInfo?.isLoading ? (
              <ActivityIndicator
                style={{ width: "20%", height: "20%" }}
                animationAlignement="none"
              />
            ) : (
              !props.hideSubmitButton && (
                <>
                  {(props.formName === ConfigConst.LETTER_OF_PERMISSION ||
                    props.formName === ConfigConst.COACH_BACK ||
                    props.formName === ConfigConst.RISER_REPAIR_FORM ||
                    props.formName === ConfigConst.SENIOR_REFERRAL ||
                    props.formName ===
                      ConfigConst.CUSTOMER_EXPERIENCE_CERTIFICATION) && (
                    <Button
                      onClick={props.onPreview}
                      disabled={isSubmitDisabled}
                      className="previewButton"
                    >
                      {labels.preview}
                    </Button>
                  )}
                  {props.showPreview &&
                    props.formName === ConfigConst.EQUIPMENT_PICKUP_RECEIPT && (
                      <Button
                        onClick={props.onPreview}
                        disabled={isSubmitDisabled}
                        className="previewButton"
                      >
                        {labels.preview}
                      </Button>
                    )}
                  {props.showPreview &&
                    props.formName === ConfigConst.NOISE_REFERRAL && (
                      <Button
                        onClick={props.onPreview}
                        disabled={isSubmitDisabled}
                        className="previewButton"
                      >
                        {labels.preview}
                      </Button>
                    )}
                    {props.showPreview &&
                    props.formName === ConfigConst.RPA_ADDPODORSTB && (
                      <Button
                        onClick={props.onPreview}
                       // disabled={isSubmitDisabled}
                        className="previewButton"
                      >
                        {labels.preview}
                      </Button>
                    )}
                  <Button
                    onClick={onSubmit}
                    disabled={isSubmitDisabled}
                    className="submitButton"
                  >
                    {formHistoryInfo === true &&
                    props.formName !== ConfigConst.COACH_BACK
                      ? labels.Update.toUpperCase()
                      : labels.SUBMIT}
                  </Button>
                </>
              )
            )}
          </div>
        </>
      )}
      <ResponseSnackBar
        open={bannerVisible}
        requestType={props.formLabel}
        handleClose={postBannerAction}
        responseType={formInfo?.status}
      />
    </div>
  );
}
