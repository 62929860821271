import { Divider } from "@material-ui/core";
import Filters from "./Filters";
import RedlinesTable from "./RedlinesTable";

export default function RedlineTab() {
  return (
    <>
      <Filters />
      <Divider style={{ margin: "10px 0px" }} />
      <RedlinesTable />
    </>
  );
}
