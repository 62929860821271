import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { FormState } from "../../../store/states/account/forms/FormState";
import {
  fileUploadBegin,
  fileUploadSuccess,
  fileUploadFailure,
} from "../../../store/ducksPattern/filemanager/fileUpload";
import {
  fileDownloadBegin,
  fileDownloadSuccess,
  fileDownloadFailure,
} from "../../../store/ducksPattern/filemanager/fileDownload";
import {
  filesFetchBegin,
  filesFetchSuccess,
  filesFetchFailure,
} from "../../../store/ducksPattern/filemanager/fileFetch";

export default class FileClient {
  static postFile =
    (data: any): ThunkAction<void, FormState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL + "file/" + "upload/" + data?.name,
      };
      dispatch(fileUploadBegin());
      console.log("File upload", data);
      HttpClient.post<any>(httpParameters, data)
        .then((res) => {
          dispatch(fileUploadSuccess(res));
          return res;
        })
        .catch((error) => {
          dispatch(fileUploadFailure(error));
          return error;
        });
    };

  static downloadFile =
    (data: any): ThunkAction<void, FormState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "file/" + "download/" + data,
      };
      dispatch(fileDownloadBegin());
      HttpClient.get<any>(httpParameters)
        .then((res) => {
          dispatch(fileDownloadSuccess(res));
          return res;
        })
        .catch((error) => {
          dispatch(fileDownloadFailure(error));
          return error;
        });
    };

  static getAllFiles =
    (): ThunkAction<void, FormState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "file/get",
      };
      dispatch(filesFetchBegin());
      HttpClient.get<any>(httpParameters)
        .then((res) => {
          dispatch(filesFetchSuccess(res));
          return res;
        })
        .catch((error) => {
          dispatch(filesFetchFailure(error));
          return error;
        });
    };
}
