import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { HttpClient } from "../HttpClient";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { NetworkConfig } from "../NetworkConfig";

import {
  GponNodesInfoSuccess,
  GponNodesInfoFailure,
  GponNodesInfoBegin,
} from "../../../store/actions/gpon/GponNodeInfoAction";
import {
  DecrementGponNodeApiCallCounter,
  IncrementGponNodeApiCallCounter,
  UpdateGponNodeDevices,
} from "../../../store/actions/gpon/GponNodeSummaryAction";
import { GponNodesInfo } from "../../../store/models/gpon/GponNodesInfo";
import { GponNodeSummary } from "../../../store/models/gpon/GponNodeSummary";
import appConst from "../../../topology/constants/appConst";
import { UniversalSearchTypes } from "../../../topology/store/reduxTypes";
import { setActiveMainSearchType } from "../../../topology/store/slices/topoGeneral";

export const useGponNodeClient = () => {
  const dispatch = useDispatch();

  const getNodesInfo = useCallback(
    async (node: string, accType: string) => {
      let deviceType = accType === "Maestro" ? "Maestro_CM" : "ALL_CM";
      const limit = "32";

      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.NETWORK_SERVICE_URL + "area/gpon/node/" + node,
        params: { deviceType: deviceType, limit: limit },
      };
      dispatch(
        setActiveMainSearchType({
          type: appConst.GPON_NODE as UniversalSearchTypes,
        })
      );
      dispatch(GponNodesInfoBegin());
      const result = await HttpClient.get<GponNodesInfo>(httpParameters)
        .then((NodeInfo) => {
          dispatch(GponNodesInfoSuccess(NodeInfo));
          return NodeInfo;
        })
        .catch((error) => {
          dispatch(GponNodesInfoFailure(error));
          return error;
        });
      return result;
    },
    [dispatch]
  );

  const getNodeSummary = useCallback(
    (serialNum: string) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.NETWORK_SERVICE_URL +
          "diag/ont/" +
          serialNum +
          "/summary",
      };

      dispatch(IncrementGponNodeApiCallCounter(serialNum, "summary"));
      HttpClient.get<GponNodeSummary>(httpParameters)
        .then((nodeSummary) => {
          dispatch(
            UpdateGponNodeDevices(serialNum, "summary", nodeSummary, "OK")
          );
          dispatch(DecrementGponNodeApiCallCounter(serialNum, "summary"));
          return nodeSummary;
        })
        .catch((error) => {
          dispatch(UpdateGponNodeDevices(serialNum, "summary", error, "ERROR"));
          dispatch(DecrementGponNodeApiCallCounter(serialNum, "summary"));
          return error;
        });
    },
    [dispatch]
  );
  return {
    getNodesInfo,
    getNodeSummary,
  };
};
