import LoaderGif from "../../resources/images/loader.webp";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ConfigConst from "../../constants/ConfigConst";

const FullPageLoader = () => {
  const {
    isSearchLoading,
    isNodeSearchLoading,
    isNodeCertificateLoading,
    isGponNodeLoading,
    isOltLoading,
    oltIsLoading,
    isLoading
  } = useSelector(
    (state: any) => ({
      isSearchLoading: state.AccountState.AccountDetails.isLoading,
      isNodeSearchLoading: state.NodeState.NodeInfoDetails.isLoading,
      isNodeCertificateLoading:
        state.NodeState.nodeCertifications.nodeCertificationDetails
          .detailsLoading,
      isGponNodeLoading: state.GponState.GponNodeInfo.isLoading,
      isOltLoading: state.GponState.OltResultDetails.isLoading,
      oltIsLoading: state.slices.PONFluxtelemetry.oltIsLoading,
      isLoading: state.slices.PONPorttelemetry.oltIsLoading,
    }),
    shallowEqual
  );

  if (
    !(
   oltIsLoading 
    ) &&
    !(isSearchLoading ||
    isNodeSearchLoading ||
    isNodeCertificateLoading ||
    isGponNodeLoading ||
    isOltLoading ||
    isLoading)
  ){

    return null;

  }
  else if (
    !(
      isSearchLoading ||
      isNodeSearchLoading ||
      isNodeCertificateLoading ||
      // isGponNodeLoading ||
      oltIsLoading ||
      isOltLoading ||
      isLoading
    )
  ){
    return null;

  }
    
  return (
    <div className="loader-container">
      <div className="loader">
        <img src={LoaderGif} alt="Loading..." />
      </div>
    </div>
  );
};

export default FullPageLoader;