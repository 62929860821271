import AppLabels from "../../constants/App_Labels";
import "../../design-tokens/workorder/WorkOrderPopUp.scss";
import { secToTime } from "../reusable/Util";

export function orderDetailsData(woData: any) {
  const labels = AppLabels();

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className="WODetailColumnsContainer">
        <div className="WODetailColumn">
          <div className="WODetailColumnInfo">
            <header>{labels.Order_Number}</header>
            <p>{woData?.id ? woData?.id : labels.NA}</p>
          </div>
          <div className="WODetailColumnInfo">
            <header>{labels.IMT_Number}</header>
            <p>{woData?.imtNumber === "0" ? labels.NA : woData?.imtNumber}</p>
          </div>
          <div className="WODetailColumnInfo">
            <header>{labels.Customer_Name}</header>
            <p>{woData?.customerName ? woData?.customerName : labels.NA}</p>
          </div>
          <div className="WODetailColumnInfo">
            <header>{labels.Area}</header>
            <p>{woData?.district ? woData?.district : labels.NA}</p>
          </div>
          <div className="WODetailColumnInfo">
            <header>{labels.appointment_start_time}</header>
            <p>
              {woData?.scheduledStart
                ? woData?.scheduledStart?.replace("T", " - ")
                : labels.NA}
            </p>
          </div>
        </div>
        <header>&nbsp; </header>
        <div className="WODetailColumn">
          <div className="WODetailColumnInfo">
            <header>{labels.Work_type}</header>
            <p>{woData?.workType ? woData?.workType : labels.NA}</p>
          </div>
          <div className="WODetailColumnInfo">
            <header>{labels.Account_No}</header>
            <p>{woData?.accountNumber ? woData?.accountNumber : labels.NA}</p>
          </div>
          <div className="WODetailColumnInfo">
            <header>{labels.Phone_Number}</header>
            <p>
              {woData?.customerContactNumber
                ? woData?.customerContactNumber
                : labels.NA}
            </p>
          </div>
          <div className="WODetailColumnInfo">
            <header>{labels.Dwelling}</header>
            <p>{woData?.dwellingCode ? woData?.dwellingCode : labels.NA}</p>
          </div>
          <div className="WODetailColumnInfo">
            <header>{labels.appointment_end_time}</header>
            <p>
              {woData?.scheduledFinish
                ? woData?.scheduledFinish?.replace("T", " - ")
                : labels.NA}
            </p>
          </div>
        </div>
        <div className="WODetailColumn">
          <div className="WODetailColumnInfo">
            <header>{labels.Order_Type}</header>
            <p>{woData?.orderType ? woData?.orderType : labels.NA}</p>
          </div>
          <div className="WODetailColumnInfo">
            <header>{labels.Subscriber_Type}</header>
            <p>{woData?.subscrTypeCode ? woData?.subscrTypeCode : labels.NA}</p>
          </div>
          <div className="WODetailColumnInfo" style={{ height: "87px" }}>
            <header>{labels.Address}</header>
            <p>{woData?.address ? woData?.address : labels.NA}</p>
          </div>
          <div className="WODetailColumnInfo">
            <header></header>
            <p></p>
          </div>
          <div className="WODetailColumnInfo">
            <header>{labels.Est_Duration}</header>
            <p>{woData?.duration ? secToTime(woData?.duration) : labels.NA}</p>
          </div>
        </div>
        <div className="WODetailColumn">
          <div className="WODetailColumnInfo">
            <header>{labels.CBU}</header>
            <p>{woData?.cbu ? woData?.cbu : labels.NA}</p>
          </div>
          <div className="WODetailColumnInfo">
            <header>{labels.Prime_Dep_Code}</header>
            <p>
              {woData?.primeDependCode ? woData?.primeDependCode : labels.NA}
            </p>
          </div>
        </div>
      </div>
      <div className="WODetailColumnsContainer">
        <div className="WODetailRow">
          <div className="WODetailColumnInfo">
            <header>{labels.Special_Instructions}</header>
            <p>{woData?.specialInstructions}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
