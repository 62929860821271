import React, { useMemo, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { FiAlertTriangle } from "react-icons/fi";
import ErrorIcon from "../../resources/images/icons/ErrorIcon.svg";
import TestedIcon from "../../resources/images/icons/tested.svg";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TablePagination,
} from "@material-ui/core";
import CertificateTableHead from "./CertificateTableHead";
import { changeTimeFormat, isNull } from "../reusable/Util";
import FullScreenPopUp from "../reusable/FullScreenPopUp";
import ViewFullCertificate from "../../screens/certification-page/ViewFullCertificate";
import { useDispatch } from "react-redux";
import CertificationClient from "../../network/httpclient/certification/CertificationClient";
import ExceptionBox from "../reusable/ExceptionBox";
import { getWorkOrderDetails } from "../../store/slices/workOrder/workOrderDetails";

const CertificationTable = (props: any) => {
  const { allCerti, changeDisplay, completedStep, techData } = props;
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [activeCerti, setActiveCerti] = useState({});
  const [openCerti, setOpenCerti] = useState(false);
  const certiData = useMemo(() => {
    let computedCertis = allCerti;
    if (search && searchBy === "id") {
      computedCertis = computedCertis.filter((certi: any) =>
        certi?.id?.toLowerCase()?.includes(search?.toLowerCase())
      );
    } else if (search && searchBy === "workOrderId") {
      computedCertis = computedCertis.filter((certi: any) =>
        certi?.workOrderId?.toLowerCase()?.includes(search?.toLowerCase())
      );
    } else if (search && searchBy === "techId") {
      computedCertis = computedCertis.filter((certi: any) =>
        certi?.technician?.techId
          ?.toLowerCase()
          ?.includes(search?.toLowerCase())
      );
    }
    //Sorting Certies
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      if (sorting.field === "techId") {
        computedCertis = computedCertis.sort((a: any, b: any) => {
          return a.technician?.techId || b.technician?.techId
            ? !a.technician?.techId
              ? -1
              : !b.technician?.techId
                ? 1
                : reversed *
                (a.technician?.techId).localeCompare(b.technician?.techId)
            : 0;
        });
      } else {
        computedCertis = computedCertis.sort((a: any, b: any) => {
          return reversed * a[sorting.field].localeCompare(b[sorting.field]);
        });
      }
    }
    return computedCertis;
  }, [search, searchBy, sorting, allCerti]);
  const handleCerticlick = (id: string, workOrderId: any) => {
    dispatch(getWorkOrderDetails(workOrderId));
    let seleCerti = allCerti.filter((c: any) => c.id === id);
    setActiveCerti({ data: seleCerti?.[0] });
    if (seleCerti?.[0].workflow?.status?.toLowerCase() === "complete") {
      setOpenCerti(true);
    } else if (
      seleCerti?.[0].workflow?.status?.toLowerCase() === "inprogress"
    ) {
      dispatch(CertificationClient.getCerti(seleCerti?.[0].id));
      changeDisplay();
      completedStep(seleCerti?.[0]?.workflow?.completedSteps?.length);
    }
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, certiData?.length - page * rowsPerPage);
  return (
    <>
      <div className="certiTableCont">
        <Paper className="deviceTablePaper">
          <TableContainer>
            <Table className="deviceTable">
              <CertificateTableHead
                onSearch={(value: any, by: string) => {
                  setSearch(value);
                  setSearchBy(by);
                }}
                onSorting={(field: any, order: any) =>
                  setSorting({ field, order })
                }
              />
              <TableBody className="dTabBody">
                {certiData?.length === 0 ? (
                  <TableRow tabIndex={-1} key="exception">
                    <TableCell colSpan={8}>
                      <div className="diagErrorCont">
                        <ExceptionBox
                          headerText="No Certifications to display."
                          bodyText="The ones started or submitted on this account will reflect here. Please contact the Tech Insight admin if data is missing."
                          primaryBtn={false}
                          secBtn={false}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  certiData
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((certi: any, index: number) => {
                      return (
                        <TableRow tabIndex={-1} key={certi.id + index}>
                          <TableCell align="left">
                            {certi?.status?.toLowerCase() === "complete" ? (
                              <a
                                className="idLink"
                                onClick={() =>
                                  handleCerticlick(certi.id, certi.workOrderId)
                                }
                              >
                                {isNull(certi.workOrderId)}
                              </a>
                            ) : techData !== undefined &&
                              certi.technician?.techId === techData?.techId ? (
                              <a
                                className="idLink"
                                onClick={() =>
                                  handleCerticlick(certi.id, certi.workOrderId)
                                }
                              >
                                {isNull(certi.workOrderId)}
                              </a>
                            ) : (
                              <a>{isNull(certi.workOrderId)}</a>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {isNull(certi.status)?.slice(0, 1) +
                              "" +
                              isNull(certi.status)?.slice(1)?.toLowerCase()}
                          </TableCell>
                          <TableCell align="left">
                            <span>
                              {certi?.hasOwnProperty("submittedDate")
                                ? changeTimeFormat(isNull(certi.submittedDate))
                                : "-"}
                            </span>
                          </TableCell>
                          <TableCell align="left">
                            {isNull(certi.testType) === "WHT"
                              ? "Whole Home"
                              : isNull(certi.testType) === "DNS"
                                ? "Drop & Stay"
                                : isNull(certi.testType) === "DNG"
                                  ? "EDBA Test"
                                  : isNull(certi.testType)}
                          </TableCell>
                          <TableCell>
                            <span className="techName">
                              {isNull(certi.technician?.techId)}
                            </span>
                          </TableCell>
                          <TableCell align="left" className="hsiCertiStatus">
                            {certi?.hsiCertificate?.summary ? (
                              <p>
                                <span>
                                  {certi?.hsiCertificate?.summary?.marginalPass
                                    ?.count !== undefined
                                    ? certi?.hsiCertificate?.summary?.passed
                                      ?.count +
                                      certi?.hsiCertificate?.summary
                                        ?.marginalPass?.count <
                                      10
                                      ? "0" +
                                      (certi?.hsiCertificate?.summary
                                        ?.marginalPass?.count +
                                        certi?.hsiCertificate?.summary?.passed
                                          ?.count)
                                      : certi?.hsiCertificate?.summary?.passed
                                        ?.count +
                                      certi?.hsiCertificate?.summary
                                        ?.marginalPass?.count
                                    : certi?.hsiCertificate?.summary?.passed
                                      ?.count !== undefined &&
                                      certi?.hsiCertificate?.summary?.passed
                                        ?.count < 10
                                      ? "0" +
                                      certi?.hsiCertificate?.summary?.passed
                                        ?.count
                                      : certi?.hsiCertificate?.summary?.passed
                                        ?.count}
                                  <FaCheckCircle color="#2DC071" />
                                </span>
                                <span>
                                  {certi.hsiCertificate?.summary?.failed?.count
                                    ? certi.hsiCertificate?.summary?.failed
                                      ?.count < 10
                                      ? "0" +
                                      certi.hsiCertificate?.summary?.failed
                                        ?.count
                                      : certi.hsiCertificate?.summary?.failed
                                        ?.count
                                    : "00"}
                                  <img src={ErrorIcon} />
                                </span>
                                <span>
                                  {certi.hsiCertificate?.summary?.skipped?.count
                                    ? certi.hsiCertificate?.summary?.skipped
                                      ?.count < 10
                                      ? "0" +
                                      certi.hsiCertificate?.summary?.skipped
                                        ?.count
                                      : certi.hsiCertificate?.summary?.skipped
                                        ?.count
                                    : "00"}
                                  <FiAlertTriangle color="#E77C40" />
                                </span>
                              </p>
                            ) : (
                              <>-</>
                            )}
                          </TableCell>
                          <TableCell align="left" className="wifiCertiStatus">
                            {certi.wifiCertificate?.summary ? (
                              <p>
                                <span>
                                  {certi.wifiCertificate?.summary?.total
                                    ? certi.wifiCertificate?.summary?.total < 10
                                      ? "0" +
                                      certi.wifiCertificate?.summary?.total
                                      : certi.wifiCertificate?.summary?.total
                                    : "00"}{" "}
                                  <img src={TestedIcon} />
                                </span>
                                <span>
                                  {certi.wifiCertificate?.summary?.passed
                                    ? certi.wifiCertificate?.summary?.passed <
                                      10
                                      ? "0" +
                                      certi.wifiCertificate?.summary?.passed
                                      : certi.wifiCertificate?.summary?.passed
                                    : "00"}{" "}
                                  <FaCheckCircle color="#2DC071" />
                                </span>
                                <span>
                                  {certi.wifiCertificate?.summary?.failed
                                    ? certi.wifiCertificate?.summary?.failed <
                                      10
                                      ? "0" +
                                      certi.wifiCertificate?.summary?.failed
                                      : certi.wifiCertificate?.summary?.failed
                                    : "00"}{" "}
                                  <img src={ErrorIcon} />
                                </span>
                              </p>
                            ) : (
                              <>-</>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })
                )}
                {certiData?.length !== 0 && emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={8} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="certiPageCont">
            <p>TOTAL CERTIFICATIONS : {certiData?.length}</p>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={certiData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </Paper>
      </div>
      <FullScreenPopUp
        open={openCerti}
        handleClose={() => {
          setOpenCerti(false);
        }}
        childComponent={<ViewFullCertificate certiData={activeCerti} />}
        pageName="Certification Details"
      />
    </>
  );
};

export default CertificationTable;
