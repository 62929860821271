import { GponNodesInfo } from "../../models/gpon/GponNodesInfo";

export const GPON_NODE_LIST_BEGIN = "GPON_NODE_LIST_BEGIN";
export const GPON_NODE_LIST_SUCCESS = "GPON_NODE_LIST_SUCCESS";
export const GPON_NODE_LIST_FAILURE = "GPON_NODE_LIST_FAILURE";
export const RESET_GPON_NODES_INFO = "RESET_GPON_NODES_INFO";

interface GponNodesInfoBegin {
  type: typeof GPON_NODE_LIST_BEGIN;
}

interface GponNodesInfoSuccess {
  type: typeof GPON_NODE_LIST_SUCCESS;
  payload: GponNodesInfo;
}

interface GponNodesInfoFailure {
  type: typeof GPON_NODE_LIST_FAILURE;
  payload: Error;
}

interface GponNodesInfoReset {
  type: typeof RESET_GPON_NODES_INFO;
}

export type GponNodesInfoActionTypes =
  | GponNodesInfoBegin
  | GponNodesInfoSuccess
  | GponNodesInfoFailure
  | GponNodesInfoReset;
