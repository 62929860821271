import React, { useState } from "react";
import { Paper } from "@material-ui/core";
import ExceptionBox from "../../components/reusable/ExceptionBox";
import RefreshGif from "../../resources/images/icons/refresh.gif";
import ActivityIndicator from "../../components/reusable/ActivityIndicator";
//import { GetUtilToolAppConfigProps } from "../../get-api/GetResponse";
import { Form, Button } from "react-bootstrap";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import AppLabels from "../../constants/App_Labels";
import { useDispatch } from "react-redux";
import { saveUtilAppConfig } from "../../topology/store/slices/drawer/utiltool/UtilityToolAppSaveConfig";
import { getUtilToolAppConfig } from "../../topology/store/slices/drawer/utiltool/UtilityToolAppConfig";

export interface AppConfigDetails {
  appData: AppData[];
}

export interface AppData {
  name: string;
  value: string;
}

const UtilityPageInfo = (props: any) => {
  const { utilAppData, utilAppDataLoading, error, status } = props;
  const labels = AppLabels();
  const dispatch = useDispatch();
  //const { utilappdata, isLoading, error, status } = GetUtilToolAppConfigProps();
  const appStateData = utilAppData;
  let appCurrdata: Map<string, AppData> = new Map<string, AppData>();
  //const [disabled, setDisabled] = useState(true);

  const getPlaceHolder = (name: any) => {
    switch (name) {
      case "SIMULATED_ROLES":
        return "Enter Role id list...";

      case "LAN_IDS_SIMULATION":
        return "Enter Lan id list...";

      case "CLICK_TECHID_SIMULATED_USER":
        return "Enter Single Lan id";

      case "CLICK_TECHID_SIM_ACCESS_LIST":
        return "Enter Lan id list...";
    }
  };

  const handleSubmit = () => {
    const appCurrvalues = Array.from(appCurrdata.values());
    let appCurrarray: Array<AppData> = [];

    appCurrvalues?.forEach((ele: AppData) => {
      appStateData?.appData?.forEach((stateEle: AppData) => {
        if (ele?.name === stateEle?.name && ele?.value !== stateEle?.value) {
          appCurrarray.push(ele);
        }
      });
    });
    const deltaObject: AppConfigDetails = { appData: appCurrarray };
    if (deltaObject && deltaObject?.appData?.length > 0) {
      dispatch(saveUtilAppConfig(deltaObject));

      setTimeout(function () {
        dispatch(getUtilToolAppConfig());
      }, 2000);
    } else {
      alert("Data attributes not modified");
    }
  };

  const RenderTable = (props: any): JSX.Element => {
    const { data, title } = props;
    const textAreaVal: string =
      data[0]?.type === "textarea" ? data[0]?.value : "";
    const textVal: string = data[0]?.type === "text" ? data[0]?.value : "";
    const boolVal: boolean =
      data[0]?.type === "radio"
        ? data[0]?.value === "true"
          ? true
          : false
        : false;
    const [textAreaData, setTextAreaData] = useState(textAreaVal);
    const [textData, setTextData] = useState(textVal);
    const [boolData, setBoolData] = useState<boolean>(boolVal);

    const handleBoolData = (itemVal: boolean) => {
      setBoolData(!itemVal);
      appCurrdata.set(data[0]?.name, {
        name: data[0]?.name,
        value: (!itemVal).toString(),
      });
    };

    const handleTextAreaData = (itemVal: any) => {
      setTextAreaData(itemVal);
      appCurrdata.set(data[0]?.name, { name: data[0]?.name, value: itemVal });
    };

    const handleTextData = (itemVal: any) => {
      setTextData(itemVal);
      appCurrdata.set(data[0]?.name, { name: data[0]?.name, value: itemVal });
    };

    return (
      <div className="cmtsTableLayout">
        {/* <div className="cmtsTableTitle">{title}</div> */}

        {data.map((item: any, index: number) => {
          return (
            <>
              {item?.type === "radio" && (
                <div className="cmtsBannerLabel">
                  <span className="cmtsBannerLabelText">{item?.label}:</span>
                  <span
                    key={item?.name + index}
                    onClick={() => handleBoolData(boolData)}
                  >
                    {boolData ? (
                      <ImCheckboxChecked color={`var(--primary)`} />
                    ) : (
                      <ImCheckboxUnchecked color={`var(--greyLight)`} />
                    )}
                  </span>
                </div>
              )}

              {item?.type === "textarea" && (
                <>
                  <div className="cmtsBannerLabel">
                    <span className="cmtsBannerLabelText">{item?.label}:</span>
                    <Form.Group
                      className="selector"
                      style={{
                        height: "169px",
                        width: "500px",
                      }}
                    >
                      <Form.Control
                        name={item?.name}
                        onChange={(e) => handleTextAreaData(e?.target?.value)}
                        as="textarea"
                        rows={5}
                        cols={50}
                        className="description"
                        id={item?.name}
                        maxLength={1000}
                        placeholder={getPlaceHolder(item?.name)}
                        value={textAreaData}
                      />
                    </Form.Group>
                  </div>
                </>
              )}

              {item?.type === "text" && (
                <>
                  <div className="cmtsBannerLabel">
                    <span className="cmtsBannerLabelText">{item?.label}:</span>
                    <input
                      type="search"
                      placeholder={getPlaceHolder(item?.name)}
                      className="description"
                      style={{ width: "340px" }}
                      maxLength={45}
                      onChange={(e) => handleTextData(e?.target?.value)}
                      value={textData}
                    />
                    {/* <Form.Group
                      className="selector"
                      style={{
                        height: "169px",
                        width: "500px",
                      }}
                    >
                      <Form.Control
                        name={item?.name}
                        onChange={(e) => handleTextData(e?.target?.value)}
                        as="text"
                        // rows={5}
                        // cols={50}
                        className="description"
                        id={item?.name}
                        max={30}
                        //placeholder={getPlaceHolder(item?.name)}
                        value={textData}
                      />
                    </Form.Group> */}
                  </div>
                </>
              )}
            </>
          );
        })}
      </div>
    );
  };

  if (
    utilAppDataLoading &&
    (utilAppData === undefined || utilAppData === null)
  ) {
    return <ActivityIndicator />;
  } else if (
    !utilAppDataLoading &&
    (utilAppData === undefined || utilAppData === null)
  ) {
    return (
      <>
        <Paper elevation={3} style={{ height: "100%", padding: "10px" }}>
          <div className="cmtsSectionTitle">
            APP CONFIG INFO
            <img
              style={{
                height: "23px",
                marginLeft: "20px",
                marginRight: "20px",
                visibility: utilAppDataLoading ? "visible" : "hidden",
              }}
              src={RefreshGif}
              alt="refresh"
            />
            <div className="cmtsCounter">{props?.counterTime}</div>
          </div>
          <div className="diagErrorCont">
            <ExceptionBox
              headerText="Not Authorized to acces this utility tool, please contact admin"
              bodyText="Once you search a node it will reflect here."
              primaryBtn={false}
              secBtn={false}
              errCode={error?.errorCode}
              ErrorMSG={error?.errorMessage}
              srcSystem={error?.responseFromSource}
            />
          </div>
        </Paper>
      </>
    );
  }

  const getDataByKey = (item: any) => {
    switch (item.name) {
      case "IS_ROLE_SIMULATION":
        return [
          {
            name: item.name,
            label: "IS ROLE SIMULATION",
            value: item.value,
            type: "radio",
          },
        ];
      case "EMTA_SWITCH":
        return [
          {
            name: item.name,
            label: "EMTA SWITCH",
            value: item.value,
            type: "radio",
          },
        ];
      case "SIMULATED_ROLES":
        return [
          {
            name: item.name,
            label: "SIMULATED ROLES",
            value: item.value,
            type: "textarea",
          },
        ];
      case "LAN_IDS_SIMULATION":
        return [
          {
            name: item.name,
            label: "LAN IDS SIMULATION",
            value: item.value,
            type: "textarea",
          },
        ];
      case "CLICK_TECHID_SIMULATION":
        return [
          {
            name: item.name,
            label: "CLICK TECHID SIMULATION",
            value: item.value,
            type: "radio",
          },
        ];
      case "CLICK_TECHID_SIMULATED_USER":
        return [
          {
            name: item.name,
            label: "CLICK TECHID SIMULATED USER",
            value: item.value,
            type: "text",
          },
        ];
      case "CLICK_TECHID_SIM_ACCESS_LIST":
        return [
          {
            name: item.name,
            label: "CLICK TECHID_SIMULATED ACCESS LIST",
            value: item.value,
            type: "textarea",
          },
        ];
    }
  };

  return (
    <>
      {utilAppData !== undefined && utilAppData !== null && (
        <>
          <Paper elevation={3} style={{ height: "100%", padding: "10px" }}>
            <div className="cmtsSectionTitle">
              APP CONFIG INFO
              <img
                style={{
                  height: "23px",
                  marginLeft: "20px",
                  marginRight: "20px",
                  visibility: utilAppDataLoading ? "visible" : "hidden",
                }}
                src={RefreshGif}
                alt="refresh"
              />
              <div className="cmtsCounter">{props?.counterTime}</div>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              {utilAppData?.appData?.length > 0 ? (
                <>
                  <div className="cmtsTableContainer">
                    <>
                      {utilAppData?.appData?.map((item: any, index: number) => {
                        if (item !== undefined) {
                          return (
                            <RenderTable
                              key={index + "utiltool"}
                              title={item.name}
                              data={getDataByKey(item)}
                            />
                          );
                        } else {
                          return null;
                        }
                      })}
                    </>
                  </div>
                  <div className="submission" id="WOStatusSubmit">
                    <Button
                      className="submitButton"
                      type="submit"
                      // disabled={disabled}
                      // title={
                      //   disabled ? "Modify necessary data fields to submit" : ""
                      // }
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      {labels.SUBMIT}
                    </Button>
                  </div>
                </>
              ) : (
                <div className="diagErrorCont">
                  <ExceptionBox
                    headerText="APP DATA IS NOT AVAILABLE"
                    bodyText="Try to reload to reflect here."
                    primaryBtn={false}
                    secBtn={false}
                    errCode={error?.errorCode}
                    ErrorMSG={error?.errorMessage}
                    srcSystem={error?.responseFromSource}
                  />
                </div>
              )}
            </div>
          </Paper>
        </>
      )}
    </>
  );
};
export default UtilityPageInfo;
