import { createSlice } from "@reduxjs/toolkit";
import appConst from "../../../../constants/appConst";
import { arrangeNodeData } from "../../../../util/utilFuncs";
import { apiCallBegan } from "../../../middleware/apiActions";
import { NIKA_ORDERS } from "../../../middleware/apiEndpoints";
import storeConst from "../../../storeConst";
import { decrApiCall, incrApiCall } from "../mapDetails";

const slice = createSlice({
  name: "nikaOrder",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
    nikaFilters: undefined,
    activeOrderId: undefined,
    isNikaMarkersVisible: false,
  },
  reducers: {
    getNikaOrderBegin: (nikaOrder) => {
      nikaOrder.isLoading = true;
      nikaOrder.status = "";
    },
    getNikaOrderSuccess: (nikaOrder, { payload }) => {
      const arrangedData = payload.map((e: any) => arrangeNodeData(e, "id"));

      nikaOrder.isLoading = false;
      nikaOrder.data = arrangedData;
      nikaOrder.status = storeConst.OK;
    },
    getNikaOrderFailure: (nikaOrder, { payload }) => {
      nikaOrder.isLoading = false;
      nikaOrder.error = payload;
      nikaOrder.data = undefined;
      nikaOrder.status = storeConst.ERROR;
    },
    setNikaFilters: (nikaOrder, { payload }) => {
      nikaOrder.nikaFilters = payload;
    },
    setActiveOrderId: (nikaOrder, { payload }) => {
      nikaOrder.activeOrderId = payload;
    },
    setNikaMarkersVisible: (nikaOrder, { payload }) => {
      nikaOrder.isNikaMarkersVisible = payload;
    },
  },
});

export const { setNikaFilters, setNikaMarkersVisible } = slice.actions;
const {
  getNikaOrderBegin,
  getNikaOrderSuccess,
  getNikaOrderFailure,
  setActiveOrderId,
} = slice.actions;
export default slice.reducer;

export const getNikaOrder = (props: any) => (dispatch: any) => {
  if (props.queryParams?.type === appConst.node) {
    dispatch(setActiveOrderId(props?.queryParams?.nodeId));
  } else dispatch(setActiveOrderId(props?.queryParams?.type));

  dispatch(
    apiCallBegan({
      url: NIKA_ORDERS,
      data: props,
      method: "post",
      onStart: getNikaOrderBegin.type,
      onSuccess: getNikaOrderSuccess.type,
      onError: getNikaOrderFailure.type,
      onStartDispatch: incrApiCall.type,
      onSuccessDispatch: decrApiCall.type,
      onErrorDispatch: decrApiCall.type,
    })
  );
};
