import AppLabels from "../../../constants/App_Labels";
import ConfigConst from "../../../constants/ConfigConst";
import {
  convertPascalCase,
  getEmailFormatDate,
  isNull,
} from "../../reusable/Util";

interface Props {
  ASPbody: any;
}

const PrepareEmailFormat = (props: Props) => {
  const labels = AppLabels();
  return (
    <div
      style={{
        display: "inline-block",
        fontSize: "14pt",
        fontWeight: 500,
        fontFamily: "Ted Next",
      }}
    >
      <div style={{ justifyContent: "center", display: "flex" }}>
        <span style={{ fontSize: "28.5pt", textAlign: "center" }}>
          {labels.Add_Ignite_STBs_or_Pods}
        </span>
      </div>
      <p>{labels.Hello},</p>
      <p>
        {"URGENT ACTION REQUIRED: Add a pod/STB Order Pending. "}
        {getEmailFormatDate()}
      </p>
      {props.ASPbody}
      <p>{labels.Thank_you}</p>
    </div>
  );
};

export default PrepareEmailFormat;
