import { useState } from "react";
import CustomTab from "../reusable/CustomTab";
import { TabPanel } from "../reusable/Util";
import MultiDeviceSearch from "../search/MultiDeviceSearch";
import OtherSearch from "../search/OtherSearch";
import SingleDeviceSearch from "../search/SingleDeviceSearch";

interface Props {
  searchType: any;
  setSearchType: any;
  setCurrSearchValue: any;
}

const SearchTabs = ({
  searchType,
  setSearchType,
  setCurrSearchValue,
}: Props) => {
  const [tabSelIndex, setTabSelIndex] = useState(0);
  return (
    <div className="searchBoxTabsCont">
      {/* <section className="searchTabsheader">
        <CustomTab
          tabType="horizontal"
          key={0}
          index={0}
          value={tabSelIndex}
          handleSelect={(i: any) => setTabSelIndex(i)}
          label="NETWORK"
        />
        <CustomTab
          tabType="horizontal"
          key={1}
          index={1}
          value={tabSelIndex}
          handleSelect={(i: any) => setTabSelIndex(i)}
          label="ACCOUNT/DEVICE"
        />
        <CustomTab
          tabType="horizontal"
          key={2}
          index={2}
          value={tabSelIndex}
          handleSelect={(i: any) => setTabSelIndex(i)}
          label="UTIL"
        />
      </section> */}
      <section className="searchBoxInputCont">
        <MultiDeviceSearch
          searchType={searchType}
          setSearchType={setSearchType}
          setCurrSearchValue={setCurrSearchValue}
        />
        {/*  <TabPanel value={tabSelIndex} index={1}>
          <SingleDeviceSearch
            searchType={searchType}
            setSearchType={setSearchType}
            setCurrSearchValue={setCurrSearchValue}
          />
        </TabPanel>
        <TabPanel value={tabSelIndex} index={0}>
          
        </TabPanel> */}
        {/* <TabPanel value={tabSelIndex} index={2}>
          <OtherSearch />
        </TabPanel> */}
      </section>
    </div>
  );
};

export default SearchTabs;
