import {
  GET_FORM_SUCCESS,
  GET_FORM_BEGIN,
  GET_FORM_FAILURE,
  HISTORY_FORM_RESET,
  NewFormDataActionTypes,
} from "../../../types/account/forms/FormData";

export function GetFormSuccess(activeForm: any): NewFormDataActionTypes {
  return {
    type: GET_FORM_SUCCESS,
    payload: activeForm,
  };
}

export function GetFormBegin(): NewFormDataActionTypes {
  return {
    type: GET_FORM_BEGIN,
  };
}

export function GetFormFailure(error: Error): NewFormDataActionTypes {
  return {
    type: GET_FORM_FAILURE,
    payload: error,
  };
}

export function HistoryReset(): NewFormDataActionTypes {
  return {
    type: HISTORY_FORM_RESET,
  };
}
