import { WirelessSubscriber } from "../../../../models/account/diagnostics/odu/WirelessSubscriber";
import {
  WIRELESS_SUBSCRIBER_BEGIN,
  WIRELESS_SUBSCRIBER_FAILURE,
  WIRELESS_SUBSCRIBER_SUCCESS,
  RESET_WIRELESS_SUBSCRIBER,
  WirelessSubscriberActionTypes,
} from "../../../../types/account/diagnostics/odu/WirelessSubscriber";

export function WirelessSubscriberBegin(): WirelessSubscriberActionTypes {
  return {
    type: WIRELESS_SUBSCRIBER_BEGIN,
  };
}

export function WirelessSubscriberSuccess(
  WirelessSubscriber: WirelessSubscriber
): WirelessSubscriberActionTypes {
  return {
    type: WIRELESS_SUBSCRIBER_SUCCESS,
    payload: WirelessSubscriber,
  };
}

export function WirelessSubscriberFailure(
  error: Error
): WirelessSubscriberActionTypes {
  return {
    type: WIRELESS_SUBSCRIBER_FAILURE,
    payload: error,
  };
}
export function ResetWirelessSubscriber(): WirelessSubscriberActionTypes {
  return {
    type: RESET_WIRELESS_SUBSCRIBER,
  };
}
