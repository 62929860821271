import { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import AppsIcon from "@material-ui/icons/Apps";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { replacePatternData} from "../../components/reusable/Util";
import "../../design-tokens/gpon-page/GponLandingPage.scss";
import ExternalAppMenu from "../../components/reusable/ExternalAppMenu";
import { IoIosArrowDropup } from "react-icons/io";
import { ponOltTableConfig } from "../../constants/TelePrefConfig";
import ExportIcon from "../../resources/images/icons/ExportIcon.svg";
import { OLTBanner } from "./OLTBanner";
import { PONTelemetryTable } from "./components/PONTelemetryTable";
import { GetOltSearchDetails,GetOltListDetails } from "../../get-api/GetResponse";
import { getStreamData } from "../../../src/store/slices/gpon/oltNameSearchDetails";
import { PONONT } from "../../store/models/account/diagnostics/ont/PONONT";
import GponDictionary from "./GponDictionary";
import AppLabels from "../../constants/App_Labels";
import { ExportCsv } from "./components/ExportCsv";
import { getOLTPONData } from "../../network/httpclient/gpon/PONPortClient";
import { usePONONTClient } from "../../network/httpclient/gpon/PONONTClient";
import React from "react";
import ExceptionBox from "../../components/reusable/ExceptionBox";

export const PONOltMainPage = (props: any) => {
    const { location: search } = props;
  const query = new URLSearchParams(search?.pathname?.replace(/^\//,''));
  const ponOltName = query.get("ponOlt");
  const dispatch = useDispatch();
  const [expand, setExpand] = useState(false);
  const [oltSearchValue, setOltSearchValue] = useState<string | null>("");
  const [tableData, setTableData] = useState<PONONT[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [dictionary, setDictionary] = useState(false);
  const [quickLinks, setQuickLinks] = useState(false);
 
  const [responsesCount, setResponsesCount] = useState(0); 
  const {
    oltResultData,
    oltName,
    isLoading,
    oltStatus,
    oltError,
    isOntCountLoading,
    diagStartTime,
    diagEndTime,
  } = GetOltSearchDetails();
  const {
    isOLTLoading,
    data,
    status,
    error,
    startTime,
    // endTime,
  } = GetOltListDetails();
  const isFluxRendered = useRef(false);
  const labels = AppLabels();
  const [endTime, setEndTime] = useState<String | undefined>(oltStatus?.toUpperCase() ==="ERROR" ?"":undefined);
     let completedRequests = 0;
  const oltPortInfoApi = usePONONTClient();
  const [oltData,setOltData]= useState(oltResultData);
  const handleToggle = () => {
    setExpand((prev) => !prev);
  };
  const formatDate = (date: Date) => {
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    });
  };

  useEffect(() => {
    if (search && ponOltName) {
      setOltSearchValue(query.get("ponOlt"));
      dispatch(
        getOLTPONData({
        oltName: ponOltName,
      })
    );
    } else {
      setOltSearchValue(oltName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOltNodeLink = async (rowData: any, ponPort: String) => {
   
      let newWindow = window.open(
        `/#/pon?ponOltName=${ponOltName}&ponPort=${replacePatternData(ponPort,'-').toString()}&ponNode=${rowData?.ponSplitterNodeId}`,
        "_blank"
      )!;
      newWindow.onload = () => {
        newWindow.document.title = "PON-" + ponOltName;
      };
  
  };

  const portCounts = async (ponOltName: any, ponPort: any)=>{
    let count="0";
    let slotName="";
    const  apiResponse=await oltPortInfoApi.getOLTData(ponOltName,ponPort)
    if(apiResponse?.length>0)
      {
        
        count=apiResponse?.length ;
        slotName = apiResponse[0]?.slotName || "";
      }
      else if(apiResponse?.errorCode){ 
        count= apiResponse;
        slotName =apiResponse;
      }
      completedRequests++;
return {count,slotName};
  }

  type PONONT = {
    slotName: string;
    portName: string;
    ponSplitterNodeId: string;
    oltName: string;
    [key: string]: any; 
  };
  
  const arraysEqual = (arr1: string[], arr2: string[]) => {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((value, index) => value === arr2[index]);
  };
  const sortPorts = (portA: string, portB: string) => {
    const splitA = portA.split('/').map(Number);
    const splitB = portB.split('/').map(Number);
    for (let i = 0; i < Math.max(splitA.length, splitB.length); i++) {
      const partA = splitA[i] || 0;
      const partB = splitB[i] || 0;
      if (partA !== partB) {
        return partA - partB;
      }
    }
    return 0;
  };
  
  const getTableData = useCallback(async () => {
    if (oltResultData !== undefined) { 
      for (const olt of oltResultData) {
        if (olt !== undefined) {
          const { count: totalCount, slotName } = await portCounts(olt?.oltName, replacePatternData(olt?.portName, '-'));
          setTableData(prevData => {
            const updatedTableData = [...prevData];
            const existingIndex = updatedTableData.findIndex(entry =>
              entry.portName === olt.portName && arraysEqual(entry.ponSplitterNodes, olt.ponSplitterNodes || [])
            );

            if (existingIndex !== -1) {
              updatedTableData[existingIndex] = { ...updatedTableData[existingIndex], ontCount: totalCount,slotName };
            } else {
              updatedTableData.push({
                ...olt,
                slotName: slotName ? " " + slotName : "",
                portName: olt?.portName ? olt?.portName : "",
                ponSplitterNodes: olt?.ponSplitterNodes ? olt?.ponSplitterNodes : [],
                ontCount: totalCount,
              });
            }

            updatedTableData.sort((a, b) => sortPorts(a.portName, b.portName)); 
            setFilteredData([...updatedTableData]); 
           
              if (completedRequests === oltResultData.length) {
                setEndTime(formatDate(new Date())); 
                setDisabled(false);
              }
            

            return updatedTableData;
          });
        }
      }
    }
  }, [oltResultData]);
    useEffect(() => {
      // Initialize the table data with the initial values but set ontCount to loading state
      if (oltResultData) {
        const initialData = oltResultData.map((olt:any) => ({
          ...olt,
          slotName: undefined,
          portName: olt?.portName ? olt?.portName : "",
          ponSplitterNodeId: olt?.ponSplitterNodes ? olt?.ponSplitterNodes : "",
          ontCount: undefined, // initial state for ontCount
        }));
        setTableData(initialData);
        setFilteredData(initialData);
      }
  
      getTableData();
    }, [getTableData]);
  const handleExportColumnData = (columnData: any) => {
    let headers: any = [];
    columnData.forEach((column: any) => {
      headers.push({
        label: column.title,
        key: column.attribute,
      });
    });
    return headers;
  };

  const handleExportRowData = (rowData: any) => {
    rowData.forEach((row: any) => {
      Object.keys(row).forEach((key: any) => {
        if (row[key] instanceof Object && !row[key]?.errorCode ) {
          row[key] = row[key].join(", ");
        } else if (row[key] instanceof Array) {
          row[key] = row[key].join(", ");
        } else if (row[key] instanceof Date) {
          row[key] = row[key].toLocaleString();
        } else if (row[key] instanceof Boolean) {
          row[key] = row[key] ? "Yes" : "No";
        } else if (row[key] instanceof Number) {
          row[key] = row[key].toString();
        } else if (row[key] instanceof String) {
            row[key] = row[key].toString();
          
        } else if (
          row[key] === null ||
          row[key] === undefined ||
          row[key]?.trim === "" ||
          row[key] === "null" ||
          row[key] === "undefined" 
        
        ) {
          row[key] = "";
        } else if( row[key]?.errorCode)
          row[key] = "ERROR";
          else {
          row[key] = " "+ row[key] + " ";
        }
      });
    });
    return rowData;
  };

  const getDefaultFileName = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    let time = today.toLocaleTimeString().split(":");
    const fileName = oltSearchValue ? "OLT_" + oltSearchValue : "OLT";
    return (
      fileName +
      "_" +
      mm +
      "-" +
      dd +
      "-" +
      yyyy +
      "_" +
      time[0] +
      "_" +
      time[1]
    );
  };

  return (
    <div className="gponLandingPage">
      <div className="gponBannerContainer">
        <OLTBanner
          data={props.data}
          expand={expand}
          gponType={"olt"}
          gponSearchVal={oltSearchValue}
          loadingStatus={isOntCountLoading}
          diagStartTime={diagStartTime}
          diagEndTime={endTime}
        />
        <div className="gponBannerIconsContainer">
          <div className="gponBannerIconsWrapper">
            <DropdownButton
              title={
                <AppsIcon
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                />
              }
              className="gponMenuIcon"
            >
              <Dropdown.Item
                className="gponMenuListItemContainer"
                onClick={() => {
                  if (!dictionary) {
                    setDictionary(!dictionary);
                  }
                }}
              >
                <span style={{ marginRight: "12px" }}>
                  <GponDictionary
                    show={dictionary}
                    setShow={() => setDictionary(false)}
                  />
                </span>
                <span>Dictionary</span>
              </Dropdown.Item>
              <Dropdown.Item
                className="gponMenuListItemContainer"
                onClick={() => setQuickLinks(!quickLinks)}
              >
                <span style={{ marginRight: "12px" }}>
                  <ExternalAppMenu
                    show={quickLinks}
                    setShow={() => setQuickLinks(false)}
                  />
                </span>
                <span>Quick Links</span>
              </Dropdown.Item>
              <Dropdown.Item
                className="gponMenuListItemContainer"
                onClick={() => {
                  setOpen(true);
                }}
                disabled={disabled}
              >
                <span style={{ marginRight: "12px" }}>
                  <img src={ExportIcon} alt="export" />
                </span>
                <span>Export</span>
              </Dropdown.Item>
            </DropdownButton>
          </div>
          <div
            className="gponCollapseContainer"
            style={{
              rotate: expand ? "0deg" : "180deg",
            }}
          >
            <IoIosArrowDropup color="black" size={25} onClick={handleToggle} />
          </div>
        </div>
      </div>

      {open && (
        <>
          <div className="abc">
            <ExportCsv
              show={open}
              headerText={labels.Export}
              cancelText={labels.CANCEL}
              handleClose={() => setOpen(false)}
              rowData={JSON.parse(JSON.stringify(filteredData))}
              columnData={ponOltTableConfig}
              handleCustom={true}
              handleColumnData={handleExportColumnData}
              handleRowData={handleExportRowData}
              getDefaultLibFileName={getDefaultFileName}
            />
          </div>
        </>
      )}
       {tableData && tableData?.length > 0 ? (
      <div className="gponOltBodyContainer">
        <PONTelemetryTable
          tableHeader={ponOltTableConfig}
          tableData={tableData.length > 0 ? tableData : []}
          onClickExternalLink={handleOltNodeLink}
          setFilterData={(data: any) => setFilteredData(data)}
        />
      </div>
      ) : oltStatus?.toUpperCase() ==="ERROR" && (
        <div className="diagErrorCont">
          <ExceptionBox
            headerText={oltError?.errorMessage ? oltError?.errorMessage: "Error"}
            bodyText={""}
            primaryBtn={false}
            secBtn={false}
          />
        </div>
      )}
     
    </div>
  );
};