import { createSlice } from "@reduxjs/toolkit";

import { apiCallBegan } from "../../../../middleware/apiActions";
import storeConst from "../../../../storeConst";
import { FIBER_UPSTREAM_TRACE_API } from "../../../../middleware/apiEndpoints";
import { incrApiCall, decrApiCall } from "../../mapDetails";
import appConst from "../../../../../constants/appConst";

const slice = createSlice({
  name: "fiberUpstreamTrace",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
    isActive: false,
    showPopup: false,
    clickedEleNetworkId: undefined,
    networkId: undefined,
    rootNodeSiteId: undefined,
    type: undefined,
    elementInfo: undefined,
    otdrDistance: undefined,
    otdrInfo: undefined,
    otdrDistanceUnit: appConst?.km,
  },
  reducers: {
    fiberUpstreamTraceBegin: (fiberUpstreamTrace) => {
      fiberUpstreamTrace.isActive = false;
      fiberUpstreamTrace.isLoading = true;
      fiberUpstreamTrace.status = "";
      fiberUpstreamTrace.data = undefined;
      fiberUpstreamTrace.error = undefined;
    },
    fiberUpstreamTraceSuccess: (fiberUpstreamTrace, { payload }) => {
      fiberUpstreamTrace.isActive = true;
      fiberUpstreamTrace.networkId = payload.networkId;
      fiberUpstreamTrace.type = payload.type;
      fiberUpstreamTrace.rootNodeSiteId = payload.rootNodeSiteId;
      fiberUpstreamTrace.elementInfo = payload.elementInfo;
      fiberUpstreamTrace.isLoading = false;
      fiberUpstreamTrace.data = payload?.data;
      fiberUpstreamTrace.error = undefined;
      fiberUpstreamTrace.status = storeConst.OK;
    },
    fiberUpstreamTraceFailure: (fiberUpstreamTrace, { payload }) => {
      fiberUpstreamTrace.isActive = false;
      fiberUpstreamTrace.rootNodeSiteId = payload.rootNodeSiteId;
      fiberUpstreamTrace.networkId = payload.networkId;
      fiberUpstreamTrace.type = payload.type;
      fiberUpstreamTrace.elementInfo = payload.elementInfo;
      fiberUpstreamTrace.isLoading = false;
      fiberUpstreamTrace.error = payload;
      fiberUpstreamTrace.data = undefined;
      fiberUpstreamTrace.status = storeConst.ERROR;
    },
    resetFiberUpstreamTraceData: (fiberUpstreamTrace) => {
      fiberUpstreamTrace.isActive = false;
      fiberUpstreamTrace.showPopup = false;
      fiberUpstreamTrace.rootNodeSiteId = undefined;
      fiberUpstreamTrace.networkId = undefined;
      fiberUpstreamTrace.type = undefined;
      fiberUpstreamTrace.elementInfo = undefined;
      fiberUpstreamTrace.otdrDistance = undefined;
      fiberUpstreamTrace.otdrInfo = undefined;
      fiberUpstreamTrace.isLoading = false;
      fiberUpstreamTrace.status = "";
      fiberUpstreamTrace.data = undefined;
      fiberUpstreamTrace.error = undefined;
    },
    setFiberUpstreamTraceIsActive: (fiberUpstreamTrace, { payload }) => {
      fiberUpstreamTrace.isActive = payload;
    },
    setFiberUpstreamTraceShowPopup: (fiberUpstreamTrace, { payload }) => {
      fiberUpstreamTrace.showPopup = payload;
    },
    setFiberUpstreamTraceOtdrDistance: (fiberUpstreamTrace, { payload }) => {
      fiberUpstreamTrace.otdrDistance = payload;
    },
    setFiberUpstreamTraceOtdrInfo: (fiberUpstreamTrace, { payload }) => {
      fiberUpstreamTrace.otdrInfo = payload;
    },
    setFiberUsTraceOtdrDistanceUnit: (fiberUpstreamTrace, { payload }) => {
      fiberUpstreamTrace.otdrDistanceUnit = payload;
    },
    setFiberUpstreamTraceBasicInfo: (fiberUpstreamTrace, { payload }) => {
      fiberUpstreamTrace.clickedEleNetworkId = payload?.clickedEleNetworkId;
      fiberUpstreamTrace.networkId = payload?.networkId;
      fiberUpstreamTrace.rootNodeSiteId = payload?.rootNodeSiteId;
      fiberUpstreamTrace.type = payload?.type;
      fiberUpstreamTrace.elementInfo = payload?.elementInfo;
    },
  },
});

export const {
  resetFiberUpstreamTraceData,
  setFiberUpstreamTraceIsActive,
  setFiberUpstreamTraceShowPopup,
  setFiberUpstreamTraceBasicInfo,
  setFiberUpstreamTraceOtdrDistance,
  setFiberUsTraceOtdrDistanceUnit,
  setFiberUpstreamTraceOtdrInfo,
} = slice.actions;
//should not be exposed to outside
const {
  fiberUpstreamTraceBegin,
  fiberUpstreamTraceSuccess,
  fiberUpstreamTraceFailure,
} = slice.actions;
export default slice.reducer;

export const getFiberUptreamTraceElements =
  (networkId: any, rootNodeSiteId: any, type: any, elementInfo: any) =>
  (dispatch: any) => {
    dispatch(
      apiCallBegan({
        url: FIBER_UPSTREAM_TRACE_API(networkId),
        method: "get",
        onStart: fiberUpstreamTraceBegin.type,
        onStartDispatch: incrApiCall.type,
        onSuccess: fiberUpstreamTraceSuccess.type,
        onSuccessDispatch: decrApiCall.type,
        onError: fiberUpstreamTraceFailure.type,
        onErrorDispatch: decrApiCall.type,
        props: {
          rootNodeSiteId,
          networkId,
          type,
          elementInfo,
        },
      })
    );
  };
