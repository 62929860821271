import * as React from "react";

import { GponTable } from "./GponTable";
import { useGponNodeClient } from "../../network/httpclient/gpon/GponNodeClient";
import { GetGponNodeDetails } from "../../get-api/GetResponse";

type Props = {
  children?: React.ReactNode;
  data?: any;
  open?: boolean;
  setOpen?: (open: boolean) => void;
};

export const GponTelemetryTab = (props: Props) => {
  const { data } = props;
  const { nodeSummaryList } = GetGponNodeDetails();
  const nodeInfoApi = useGponNodeClient();
  const isRendered = React.useRef(false);

  const isNodeSumarryLoaded = React.useCallback(
    (node: any) => {
      return nodeSummaryList?.nodeSummaryList?.find(
        (n: any) =>
          n?.ontSerialNumber === node?.ontSerialNumber &&
          n?.data?.summary?.status?.trim() !== ""
      );
    },
    [nodeSummaryList?.nodeSummaryList]
  );

  const getNodeSummary = React.useCallback(async () => {
    if (data !== undefined && data.length > 0 && isRendered.current === false) {
      data.map(async (node: any) => {
        // summary api call for each serial number
        if (!isNodeSumarryLoaded(node)) {
          await nodeInfoApi.getNodeSummary(node.ontSerialNumber);
        }
      });
      isRendered.current = true;
    }
  }, [data, isNodeSumarryLoaded, nodeInfoApi]);

  React.useEffect(() => {
    getNodeSummary();
  }, [getNodeSummary]);

  return (
    <GponTable nodeSummary={data} open={props?.open} setOpen={props.setOpen} />
  );
};
