import { STBSummary } from "../../../../models/account/diagnostics/stb/STBSummary";
import {
  STB_SUMMARY_BEGIN,
  STB_SUMMARY_SUCCESS,
  STB_SUMMARY_FAILURE,
  StbSummaryActionTypes,
} from "../../../../types/account/diagnostics/stb/STBSummary";

export function stbSummaryBegin(): StbSummaryActionTypes {
  return {
    type: STB_SUMMARY_BEGIN,
  };
}

export function stbSummarySuccess(
  stbSummary: STBSummary
): StbSummaryActionTypes {
  return {
    type: STB_SUMMARY_SUCCESS,
    payload: stbSummary,
  };
}

export function stbSummaryFailure(error: Error): StbSummaryActionTypes {
  return {
    type: STB_SUMMARY_FAILURE,
    payload: error,
  };
}
