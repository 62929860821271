import { RecoEngine } from "../../../models/account/troubleshoot/RecoEngine";
import {
  RECO_ENGINE_BEGIN,
  RECO_ENGINE_SUCCESS,
  RECO_ENGINE_FAILURE,
  RecoEngineActionTypes,
} from "../../../types/account/troubleshoot/RecoEngineProfile";

export function recoEngineBegin(): RecoEngineActionTypes {
  return {
    type: RECO_ENGINE_BEGIN,
  };
}

export function recoEngineSuccess(
  techniciansProfile: RecoEngine
): RecoEngineActionTypes {
  return {
    type: RECO_ENGINE_SUCCESS,
    payload: techniciansProfile,
  };
}

export function recoEngineFailure(error: Error): RecoEngineActionTypes {
  return {
    type: RECO_ENGINE_FAILURE,
    payload: error,
  };
}
