import { Tab, Tabs } from "react-bootstrap";
import { useState, useEffect } from "react";
import { FaCircle } from "react-icons/fa";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getDeviceDetails, getDeviceLoading, isNull } from "../reusable/Util";
import STBClient from "../../network/httpclient/stb/STBClient";
import STBSummary from "./STBSummary";
import FailedAttributesStb from "../FailedAttributes/FailedAttributesStb";
import { SetDeviceStatus } from "../../store/actions/account/accountInfo/ActiveAccountAction";
import { stbSummarySuccess } from "../../store/actions/account/diagnostics/stb/STBSummaryAction";
import CMDocsisEvents from "../cable-modem/CMDocsisEvents";
import STBDocsisEventLogs from "./STBDocsisEventLogs";
import CableModemClient from "../../network/httpclient/cablemodem/CableModemClient";

const STBDiagnostics = (props: any) => {
  const dispatch = useDispatch();
  const {
    selectedDevice,
    selectedSA,
    selDiagAction,
    handleActionClick,
    deviceDiagActions,
  } = props;
  const [key, setKey] = useState(selDiagAction);
  const [docsisEventsTabFlag, setDocsisEventsTabFlag] = useState(false);
  const { stbSummaryStatus, stbSummaryInfo, diagDevicesData } = useSelector(
    (state: any) => ({
      stbSummaryInfo: state.AccountState.STBSummaryInfo.data,
      stbSummaryStatus: state.AccountState.STBSummaryInfo.status,
      diagDevicesData: state.AccountState.DiagDevicesInfo.diagDevices,
    }),
    shallowEqual
  );
  let stbLoading = getDeviceLoading(
    selectedDevice.serialNumber,
    diagDevicesData
  );
  let stbSummaryDetails = getDeviceDetails(
    selectedDevice.serialNumber,
    "summary",
    diagDevicesData
  );
  let stbCMSummaryDetails = getDeviceDetails(
    selectedDevice.serialNumber,
    "cmSummary",
    diagDevicesData
  );
  let stbCMInterfacesDetails = getDeviceDetails(
    selectedDevice.serialNumber,
    "cmInterface",
    diagDevicesData
  );
  let stbDocsisDetails = getDeviceDetails(
    selectedDevice.serialNumber,
    "docsis",
    diagDevicesData
  );
  let stbDocsisEventsDetails = getDeviceDetails(
    selectedDevice.serialNumber,
    "docsisEvents",
    diagDevicesData
  );
  let cmNodeDetails = getDeviceDetails(
    selectedDevice.serialNumber,
    "nodeInfo",
    diagDevicesData
  );
  let stbCMSummaryStatus =
    stbCMSummaryDetails?.hasOwnProperty("errorCode") &&
    stbCMSummaryDetails?.errorCode != 1009
      ? "ERROR"
      : stbCMSummaryDetails?.hasOwnProperty("errorCode") &&
        stbCMSummaryDetails?.errorCode == 1009
      ? "Fail"
      : "OK";
  let stbCMInterfacesStatus =
    stbCMInterfacesDetails?.hasOwnProperty("errorCode") &&
    stbCMInterfacesDetails?.errorCode != 1009
      ? "ERROR"
      : stbCMInterfacesDetails?.hasOwnProperty("errorCode") &&
        stbCMInterfacesDetails?.errorCode == 1009
      ? "Fail"
      : "OK";
  let stbDocsisStatus =
    stbDocsisDetails?.hasOwnProperty("errorCode") &&
    stbDocsisDetails?.errorCode != 1009
      ? "ERROR"
      : stbDocsisDetails?.hasOwnProperty("errorCode") &&
        stbDocsisDetails?.errorCode == 1009
      ? "Fail"
      : "OK";
  let stbDocsisEventStatus =
    stbDocsisEventsDetails?.hasOwnProperty("errorCode") &&
    stbDocsisEventsDetails?.errorCode != 1009
      ? "ERROR"
      : stbDocsisEventsDetails?.hasOwnProperty("errorCode") &&
        stbDocsisEventsDetails?.errorCode == 1009
      ? "Fail"
      : "OK";

  useEffect(() => {
    if (deviceDiagActions?.length !== 0) {
      deviceDiagActions?.map((action: any) => {
        if (action.tab === selDiagAction) {
          if (
            action.tab === "DE" &&
            !action.isOpen &&
            selectedDevice !== undefined
          ) {
            action.isOpen = true;
            if (
              stbDocsisEventsDetails === undefined ||
              stbDocsisEventsDetails?.length === 0
            ) {
              dispatch(
                STBClient.getSTBCMDocsisEvents(
                  selectedDevice?.serialNumber,
                  stbSummaryDetails?.cmMac
                )
              );
            }
          }
        }
        if (action.tab === "DE" && action.isOpen) {
          setDocsisEventsTabFlag(true);
        }
      });
    }
    setKey(selDiagAction);
  }, [selDiagAction]);

  useEffect(() => {
    if (stbSummaryDetails !== undefined && stbSummaryDetails?.length === 0) {
      dispatch(
        STBClient.getSTBSummary(
          selectedDevice.serialNumber,
          selectedDevice.macAddress
        )
      );
    }
    setDocsisEventsTabFlag(false);
  }, [selectedDevice]);

  useEffect(() => {
    if (
      stbSummaryInfo !== undefined &&
      stbSummaryInfo?.hasOwnProperty("cmMac") &&
      stbSummaryInfo.cmMac !== null &&
      stbSummaryInfo.cmMac !== ""
    ) {
      if (
        stbCMSummaryDetails !== undefined &&
        stbCMSummaryDetails?.length === 0
      ) {
        dispatch(
          STBClient.getSTBCMSummary(
            selectedDevice.serialNumber,
            stbSummaryInfo?.cmMac
          )
        );
      }
      if (cmNodeDetails !== undefined && cmNodeDetails?.length === 0) {
        dispatch(
          CableModemClient.getCMNodeDetails(
            selectedDevice.serialNumber,
            isNull(selectedSA.shubId)
          )
        );
      }
      if (
        stbCMInterfacesDetails !== undefined &&
        stbCMInterfacesDetails?.length === 0
      ) {
        dispatch(
          STBClient.getSTBInterfaces(
            selectedDevice.serialNumber,
            stbSummaryInfo?.cmMac
          )
        );
      }
      if (stbDocsisDetails !== undefined && stbDocsisDetails?.length === 0) {
        dispatch(
          STBClient.getSTBDocsis(
            selectedDevice.serialNumber,
            stbSummaryInfo?.cmMac
          )
        );
      }
    }
    setDocsisEventsTabFlag(false);
  }, [selectedDevice, stbSummaryInfo]);

  useEffect(() => {
    if (stbSummaryDetails !== undefined && stbSummaryDetails?.length !== 0) {
      dispatch(stbSummarySuccess(stbSummaryDetails));
    }
  }, [stbSummaryDetails]);
  useEffect(() => {
    if (stbSummaryInfo === undefined || stbSummaryStatus === "ERROR") {
    }
    if (stbSummaryDetails?.length !== 0 && stbDocsisDetails?.length !== 0) {
      let summaryStatus = stbSummaryDetails?.hasOwnProperty("overallStatus")
        ? stbSummaryDetails?.overallStatus?.toLowerCase()
        : "fail";
      let docsisStatus = stbDocsisDetails?.hasOwnProperty("overallStatus")
        ? stbDocsisDetails?.overallStatus?.toLowerCase()
        : "fail";
      if (summaryStatus === "pass" && docsisStatus === "pass") {
        dispatch(
          SetDeviceStatus(
            selectedDevice?.serialNumber,
            selectedDevice?.deviceCategory,
            "pass"
          )
        );
      } else {
        dispatch(
          SetDeviceStatus(
            selectedDevice?.serialNumber,
            selectedDevice?.deviceCategory,
            "fail"
          )
        );
      }
    } else if (
      stbSummaryDetails !== undefined &&
      !stbSummaryDetails?.hasOwnProperty("cmMac")
    ) {
      dispatch(
        SetDeviceStatus(
          selectedDevice?.serialNumber,
          selectedDevice?.deviceCategory,
          stbSummaryDetails?.hasOwnProperty("overallStatus")
            ? stbSummaryDetails?.overallStatus?.toLowerCase()
            : "fail"
        )
      );
    }
  }, [stbLoading]);

  const renderDeviceStatus = () => {
    if (
      stbSummaryInfo !== undefined &&
      stbDocsisDetails !== undefined &&
      stbSummaryInfo?.hasOwnProperty("overallStatus") &&
      stbDocsisDetails?.hasOwnProperty("overallStatus") &&
      stbSummaryStatus === "OK" &&
      stbDocsisStatus === "OK"
    ) {
      if (
        stbSummaryInfo.overallStatus === "Pass" &&
        stbDocsisDetails.overallStatus === "Pass"
      )
        return (
          <span className="deviceStatusTxtCont" style={{ color: "#00985f" }}>
            <FaCircle />
            <span style={{ fontWeight: 700 }}>PASS</span>
          </span>
        );
      else if (
        stbSummaryInfo.overallStatus === "Fail" ||
        stbDocsisDetails.overallStatus === "Fail"
      )
        return (
          <span className="deviceStatusTxtCont" style={{ color: "#dc3545" }}>
            <FaCircle />
            <span style={{ fontWeight: 700 }}>FAIL</span>
          </span>
        );
      else {
        return (
          <span className="deviceStatusTxtCont" style={{ color: "#E77C40" }}>
            <FaCircle />
            <span style={{ fontWeight: 700 }}>SKIPPED</span>
          </span>
        );
      }
    } else if (stbSummaryInfo === undefined || stbSummaryStatus === "ERROR") {
      return (
        <span className="deviceStatusTxtCont">
          <FaCircle color="#E77C40" />
          <span style={{ fontWeight: 700 }}>SKIPPED</span>
        </span>
      );
    } else if (
      stbSummaryInfo !== undefined &&
      !stbSummaryInfo?.hasOwnProperty("cmMac")
    ) {
      return (
        <span className="deviceStatusTxtCont">
          <FaCircle
            color={
              stbSummaryInfo.overallStatus === "Fail"
                ? "#dc3545"
                : stbSummaryInfo.overallStatus === "Pass"
                ? "#00985f"
                : "#E77C40"
            }
          />
          <span style={{ fontWeight: 700 }}>
            {stbSummaryInfo?.overallStatus?.toUpperCase()
              ? stbSummaryInfo?.overallStatus?.toUpperCase()
              : "SKIPPED"}
          </span>
        </span>
      );
    } else if (stbDocsisDetails === undefined || stbDocsisStatus === "ERROR") {
      return (
        <span className="deviceStatusTxtCont">
          <FaCircle color="#E77C40" />
          <span style={{ fontWeight: 700 }}>SKIPPED</span>
        </span>
      );
    } else {
      return (
        <span className="deviceStatusTxtCont">
          <FaCircle color="#dc3545" />
          <span style={{ fontWeight: 700 }}>FAIL</span>
        </span>
      );
    }
  };
  return (
    <div className="detailDiagCont">
      <section className="deviceData">
        <div className="deviceStatusCont">
          <p>
            <span>MODEL : </span>
            <span>{isNull(selectedDevice.deviceModel)}</span>
          </p>
          <p className="deviceOverallStat">
            <span>STATUS : </span>
            {!stbLoading ? (
              renderDeviceStatus()
            ) : (
              <span style={{ fontStyle: "italic" }}>Fetching...</span>
            )}
          </p>
        </div>
        <p>
          <span>SERIAL NUMBER : </span>
          <span>{isNull(selectedDevice.serialNumber)}</span>
        </p>
        <p>
          {stbDocsisDetails &&
          stbDocsisDetails?.overallStatus === "Fail" &&
          ((stbSummaryInfo && stbSummaryInfo?.overallStatus === "Pass") ||
            stbSummaryInfo === undefined) ? (
            <FailedAttributesStb
              stbDocsisInfo={stbDocsisDetails}
              type="stbDocsisInfo"
            />
          ) : (
            <></>
          )}
        </p>
        <p>
          {stbSummaryInfo &&
          stbSummaryInfo?.overallStatus === "Fail" &&
          ((stbDocsisDetails && stbDocsisDetails?.overallStatus === "Pass") ||
            stbDocsisDetails === undefined) ? (
            <FailedAttributesStb
              stbSummaryInfo={stbSummaryInfo}
              type="stbSummaryInfo"
            />
          ) : (
            <></>
          )}
        </p>
        <p>
          {stbSummaryInfo &&
          stbSummaryInfo?.overallStatus === "Fail" &&
          stbDocsisDetails &&
          stbDocsisDetails?.overallStatus === "Fail" ? (
            <FailedAttributesStb
              stbSummaryInfo={stbSummaryInfo}
              stbDocsisInfo={stbDocsisDetails}
              type="both"
            />
          ) : (
            <></>
          )}
        </p>
      </section>
      <section className="detailDiagTabs">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k: any) => handleActionClick(k)}
        >
          <Tab eventKey="stbSUMMARY" title="STB SUMMARY">
            <STBSummary
              selectedDevice={selectedDevice}
              stbLoading={stbLoading}
              stbSummarydata={stbSummaryDetails}
              stbSummaryStatus={stbSummaryStatus}
              stbCMSummarydata={stbCMSummaryDetails}
              stbCMSummaryStatus={stbCMSummaryStatus}
              stbCMInterfacesdata={stbCMInterfacesDetails}
              stbCMInterfacesStatus={stbCMInterfacesStatus}
              stbDocsisData={stbDocsisDetails}
              stbDocsisStatus={stbDocsisStatus}
              cmNodeInfo={cmNodeDetails}
            />
          </Tab>
          {docsisEventsTabFlag && (
            <Tab eventKey="DE" title="DOCSIS EVENT LOGS">
              <STBDocsisEventLogs
                selectedDevice={selectedDevice}
                stbLoading={stbLoading}
                stbCmMac={stbSummaryInfo?.cmMac}
                stbDocsisEventsDetails={stbDocsisEventsDetails}
                stbDocsisEventStatus={stbDocsisEventStatus}
              />
            </Tab>
          )}
        </Tabs>
      </section>
    </div>
  );
};

export default STBDiagnostics;
