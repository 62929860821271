import { NotificationInfo } from "../../models/notification/NotificationInfo";
import {
  NOTIFICATION_CRUD_BEGIN,
  NOTIFICATION_CRUD_FAILURE,
  NOTIFICATION_CRUD_SUCCESS,
  NOTIFICATION_CRUD_RESET,
  NotificationCRUDActionTypes,
} from "../../types/notification/NotificationCRUD";

export function NotificationCRUDBegin(
  rtype: String
): NotificationCRUDActionTypes {
  return {
    type: NOTIFICATION_CRUD_BEGIN,
    rType: rtype,
  };
}

export function NotificationCRUDSuccess(
  notifications: NotificationInfo
): NotificationCRUDActionTypes {
  return {
    type: NOTIFICATION_CRUD_SUCCESS,
    payload: notifications,
  };
}

export function NotificationCRUDFailure(
  error: Error
): NotificationCRUDActionTypes {
  return {
    type: NOTIFICATION_CRUD_FAILURE,
    payload: error,
  };
}
export function NotificationCRUDReset(): NotificationCRUDActionTypes {
  return {
    type: NOTIFICATION_CRUD_RESET,
  };
}
