import { Divider } from "@material-ui/core";
import {
  getDistanceStrValue,
  getDownloadValue,
  getSignalStrValue,
  getUploadValue,
  isNull,
  performEllipsis,
} from "../reusable/Util";
import channel from "../../resources/images/icons/channel.svg";
import security from "../../resources/images/icons/security.svg";
import distance from "../../resources/images/icons/distance.svg";
import signal from "../../resources/images/icons/signal.svg";
import speed from "../../resources/images/icons/speed.svg";
import CustomPopOver from "../reusable/CustomPopOver";

const WiFiCertiDetails = (props: any) => {
  const { selectedLocation } = props;

  return (
    <div className="detailDiagCont">
      <section>
        <div className="locationInfoCont">
          <p>
            <span>SSID: </span>
            <span>{isNull(selectedLocation?.results?.SSID)}</span>
          </p>
          <p>
            <span>BSSID: </span>
            <span>{isNull(selectedLocation?.results?.BSSID)}</span>
          </p>
        </div>
      </section>
      <Divider style={{ margin: "0px 20px" }} />
      <section className="locationDetailsCont">
        <div>
          <p>
            <span>{isNull(selectedLocation?.results?.channels)}</span>
            <span>
              <img src={channel} alt="channel" />
            </span>
            <span>CHANNEL</span>
          </p>
          <p>
            <CustomPopOver
              popOverData={isNull(selectedLocation?.results?.capabilities)}
              originComp={
                <span>
                  {isNull(
                    performEllipsis(selectedLocation?.results?.capabilities, 7)
                  )}
                </span>
              }
              index="popOver"
            />
            <span>
              <img src={security} alt="security" />
            </span>
            <span>SECURITY</span>
          </p>
          <p>
            {getDistanceStrValue(selectedLocation?.results?.distance)}
            <span>
              <img src={distance} alt="distance" />
            </span>
            <span>DISTANCE</span>
          </p>
        </div>
        <div>
          <p>
            {getSignalStrValue(selectedLocation?.results?.level)}
            <span>
              <img src={signal} alt="signal" />
            </span>
            <span>SIGNAL</span>
          </p>
          <p>
            {getUploadValue(selectedLocation?.results?.speedTestResult?.upload)}

            <span>
              <img src={speed} alt="speed" />
            </span>
            <span>UPLOAD SPEED</span>
          </p>
          <p>
            {getDownloadValue(
              selectedLocation?.results?.speedTestResult?.download
            )}

            <span>
              <img src={speed} alt="speed" />
            </span>
            <span>DOWNLOAD SPEED</span>
          </p>
        </div>
      </section>
    </div>
  );
};

export default WiFiCertiDetails;
