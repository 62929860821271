import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../topology/store/middleware/apiActions";
import {
  NETWORK_SERVICE_URL,
  NODE_CERTIFICATION_URI,
  NODE_CERTIFICATION_DETAILS,
} from "../../middleware/apiEndpoints";
import storeConst from "../../storeConst";
import { addCertificateData } from "../../../components/reusable/NodeUtil";

const slice = createSlice({
  name: "nodeCertificationDetails",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
    detailsStatus: "",
    detailsLoading: false,
    detailsError: undefined,
  },
  reducers: {
    // action => action handler
    nodeCertifcationHistoryBegin: (nodeCertifcationHist) => {
      nodeCertifcationHist.isLoading = true;
      nodeCertifcationHist.status = "";
      nodeCertifcationHist.detailsStatus = "";
    },
    nodeCertifcationHistorySuccess: (nodeCertifcationHist, action) => {
      nodeCertifcationHist.isLoading = false;
      nodeCertifcationHist.data = action.payload;
      nodeCertifcationHist.status = storeConst.OK;
    },
    nodeCertifcationHistoryFailure: (nodeCertifcationHist, action) => {
      nodeCertifcationHist.isLoading = false;
      nodeCertifcationHist.error = action.payload;
      nodeCertifcationHist.data = undefined; //change it to undefined
      nodeCertifcationHist.status = storeConst.ERROR;
    },
    nodeCertifcationDetailsBegin: (nodeCertifcationHist, action) => {
      nodeCertifcationHist.detailsStatus = "";
      nodeCertifcationHist.detailsError = undefined;
      nodeCertifcationHist.detailsLoading = true;
    },
    nodeCertifcationDetailsSuccess: (nodeCertifcationHist, action) => {
      nodeCertifcationHist.detailsLoading = false;
      nodeCertifcationHist.detailsStatus = storeConst.OK;
      nodeCertifcationHist.data = addCertificateData(
        nodeCertifcationHist.data,
        action.payload
      );
    },
    nodeCertifcationDetailsFailure: (nodeCertifcationHist, action) => {
      nodeCertifcationHist.detailsError = action.payload;
      nodeCertifcationHist.detailsLoading = false;
      nodeCertifcationHist.detailsStatus = storeConst.ERROR;
    },
  },
});

export const {
  nodeCertifcationHistoryBegin,
  nodeCertifcationHistorySuccess,
  nodeCertifcationHistoryFailure,
  nodeCertifcationDetailsBegin,
  nodeCertifcationDetailsSuccess,
  nodeCertifcationDetailsFailure,
} = slice.actions;
export default slice.reducer;

//action creators
export const getNodeCertificationHistory = (nodeId: string) =>
  apiCallBegan({
    baseURL: NETWORK_SERVICE_URL,
    url: NODE_CERTIFICATION_URI + "?nodeId=" + nodeId,
    onStart: nodeCertifcationHistoryBegin.type,
    onSuccess: nodeCertifcationHistorySuccess.type,
    onError: nodeCertifcationHistoryFailure.type,
  });

export const getNodeCertificationDetails = (certificateId: string) =>
  apiCallBegan({
    baseURL: NETWORK_SERVICE_URL,
    url: NODE_CERTIFICATION_DETAILS + "?certificateId=" + certificateId,
    onStart: nodeCertifcationDetailsBegin.type,
    onSuccess: nodeCertifcationDetailsSuccess.type,
    onError: nodeCertifcationDetailsFailure.type,
  });
