import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import "../../design-tokens/rpd-page/RPDPage.scss";
import LoaderGif from "../../resources/images/loader.webp";
import RPDExceptionBox from "../../components/reusable/RPDExceptionBox";
import AppLabels from "../../constants/App_Labels";
import RPDSearchTop from "../../components/rpd/rpdSearch/RPDSearchTop";
import RPDSearchDetails from "../../components/rpd/rpdSearch/RPDSearchDetails";

import { GetRPDInfo } from "../../get-api/GetResponse";

const RPDSearchTab = (props: any) => {
  const dispatch = useDispatch();
  const rpdInfo = GetRPDInfo();
  const [macAdd, setMacAdd] = useState("");
  const labels = AppLabels();

  return (
    <>
      <div>
        <RPDSearchTop isLoading={rpdInfo?.isLoading} />
      </div>
      <div className="historyResultCont">
        {rpdInfo?.isLoading ? (
          <div className="loader">
            <img style={{ height: "60px" }} src={LoaderGif} />
          </div>
        ) : rpdInfo?.data != null ? (
          <RPDSearchDetails
            isLoading={rpdInfo?.isLoading}
            searchData={rpdInfo?.data}
            isSwVersionLoading={rpdInfo?.isSwVersionLoading}
            swVersion={rpdInfo?.swData?.running_sw_image_name}
            deviceType={rpdInfo?.swData?.DeviceType}
            phub={rpdInfo?.swData?.PHUB}
          />
        ) : rpdInfo?.error != null ? (
          <div className="diagErrorCont">
            <RPDExceptionBox
              exBoxType="error"
              headerText={labels.noRPDData}
              errCode={rpdInfo?.error?.errorCode}
              ErrorMSG={rpdInfo?.error?.errorMessage}
              srcSystem={rpdInfo?.error?.sourceSystem}
              bodyText={rpdInfo?.error?.responseFromSource}
              primaryBtn={false}
              secBtn={false}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default RPDSearchTab;
