import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../middleware/apiActions";
import { MDU_CAD_SYMBOL } from "../../../middleware/apiEndpoints";
import storeConst from "../../../storeConst";
import { decrApiCall, incrApiCall } from "../mapDetails";

const slice = createSlice({
  name: "mduCADSymbol",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    getMduCADSymbolBegin: (mduCADSymbol) => {
      mduCADSymbol.isLoading = true;
      mduCADSymbol.status = "";
    },
    getMduCADSymbolSuccess: (mduCADSymbol, action) => {
      mduCADSymbol.isLoading = false;
      mduCADSymbol.data = action.payload;
      mduCADSymbol.status = storeConst.OK;
    },
    getMduCADSymbolFailure: (mduCADSymbol, action) => {
      mduCADSymbol.isLoading = false;
      mduCADSymbol.error = action.payload;
      mduCADSymbol.data = undefined;
      mduCADSymbol.status = storeConst.ERROR;
    },
  },
});

const { getMduCADSymbolBegin, getMduCADSymbolSuccess, getMduCADSymbolFailure } =
  slice.actions;
export default slice.reducer;

export const getMduCADSymbol = (owningDrawing: any) => (dispatch: any) => {
  dispatch(
    apiCallBegan({
      url: `${MDU_CAD_SYMBOL}/${owningDrawing}`,
      method: "get",
      onStart: getMduCADSymbolBegin.type,
      onSuccess: getMduCADSymbolSuccess.type,
      onError: getMduCADSymbolFailure.type,
      onStartDispatch: incrApiCall.type,
      onSuccessDispatch: decrApiCall.type,
      onErrorDispatch: decrApiCall.type,
    })
  );
};
