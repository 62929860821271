import { useEffect, useState } from "react";
import { Menu, Avatar } from "@material-ui/core";
import { IoMdArrowDropdown } from "react-icons/io";
import Userdummy from "../../resources/images/userdummy.png";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { teleViewConfig } from "../../constants/TelePrefConfig";
import { emtaTeleViewConfig } from "../../constants/EmtaTelemetryConfig";
import { saveConfigData } from "../../store/ducksPattern/userPrefConfig";
import { GetTechInfo } from "../../get-api/GetResponse";
import { TbCookieOff } from "react-icons/tb";
import { FaCircleCheck } from "react-icons/fa6";
import { HttpClient } from "../../network/httpclient/HttpClient";
import {FlapVisibility} from "../../components/reusable/RoleConfig";

const TechnicianPopover = () => {
  const emta = window.location.hash.includes("emta");
  const techData = GetTechInfo()?.data;
  // const node = window.location.hash.includes("node");
  const neighbourhood = window.location.hash.includes("neighbourhood");
  const ecm= window.location.hash.includes("node/ecm");
  const stb =window.location.hash.includes("node/stb");
  const multDevice =window.location.hash.includes("multiDevice");
  const { configData } = useSelector(
    (state: any) => ({
      configData: state.UserPrefConfig.configData,
    }),
    shallowEqual
  );
  const [node,setnode] = useState(window.location.hash.includes("node"));
  window.addEventListener('popstate', () => {
    setnode(window.location.hash.includes("node"));
  });
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showCheck, setShowCheck] = useState(false);
  const handlePIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(document.getElementById("pMenu"));
  };
  const handleProfileClose = () => {
    setAnchorEl(null);
    setShowCheck(false);
  };
  //enabling for dynatrace
  const role =
    techData && techData?.hasOwnProperty("roles")
      ? techData?.roles?.map((e: any) => e + ",")
      : "-";
      const techRole = () => { 
        if (techData && techData.hasOwnProperty("roles")) {
          const roleExists = techData.roles.some((e: any) => {
            return FlapVisibility?.some((roleVisible: any) => {
              return e === roleVisible;
            });
          });

          return roleExists;  
        }
        return false; // Return false if techData or roles are missing
      };
      
  //load preferences config
  useEffect(() => {
    if (configData === undefined ) {
       if ((node || emta) && !neighbourhood && techData &&!ecm && !stb &&!multDevice) {
        const isTechRole = techRole();
      dispatch(saveConfigData(emta ? emtaTeleViewConfig(isTechRole): teleViewConfig(isTechRole)));
    }
  }
  }, [configData, emta,node,techData]);

  const clearCookies = () => {
    var cookies = document.cookie.split("; ");
    for (var c = 0; c < cookies.length; c++) {
      var d = window.location.hostname.split(".");
      while (d.length > 0) {
        var cookieBase =
          encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
          "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
          d.join(".") +
          " ;path=";
        var p = window.location.pathname.split("/");
        document.cookie = cookieBase + "/";
        while (p.length > 0) {
          document.cookie = cookieBase + p.join("/");
          p.pop();
        }
        d.shift();
      }
    }
    sessionStorage.clear();
    HttpClient.acquireAccessToken(HttpClient.msalInstance);
    setShowCheck(true);
  };

  return (
    <div>
      <button className="headerIcon profileBtn" onClick={handlePIconClick}>
        {techData &&
        techData?.hasOwnProperty("profileImage") &&
        techData.profileImage !== "" ? (
          <img
            className="techImg"
            alt="technicianImg"
            src={techData?.profileImage}
          />
        ) : (
          <img className="techImg" alt="technicianImg" src={Userdummy} />
        )}
        <IoMdArrowDropdown />
      </button>
      <section id="pMenu"></section>
      <Menu
        className="profileMenu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleProfileClose}
        elevation={7}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="profileConatiner">
          <div>
            {techData &&
            techData?.hasOwnProperty("profileImage") &&
            techData.profileImage !== "" ? (
              <Avatar alt="Tech Image" src={techData?.profileImage} />
            ) : (
              <Avatar alt="Tech Image" src={Userdummy} />
            )}
            <div
              className="clearCookiesBtn"
              onClick={() => clearCookies()}
              title="Clear Cookies"
            >
              <TbCookieOff size={20} />
              {showCheck && <FaCircleCheck color="var(--success)" size={17} />}
            </div>
          </div>
          <div>
            <ul className="technNameId">
              <li>
                {techData && techData?.hasOwnProperty("lanId")
                  ? techData?.lanId
                  : "-"}
              </li>
              <li className="techBasicDetails">
                <span className="popoverInfoSpanVal">
                  Techid:{" "}
                  {techData && techData?.hasOwnProperty("techId")
                    ? techData?.techId
                    : "-"}
                </span>
                <span className="infoSeprator">|</span>
                <span className="popoverInfoSpanVal">
                  Company:
                  {techData && techData?.hasOwnProperty("companyId")
                    ? techData?.companyId
                    : "-"}
                </span>
              </li>
              <li className="techBasicDetails">
                <span className="popoverInfoSpanVal">
                  Manager:
                  {techData && techData?.hasOwnProperty("supervisorName")
                    ? techData?.supervisorName
                    : "-"}
                </span>
                <span className="infoSeprator">|</span>
                <span className="popoverInfoSpanVal">
                  Region:
                  {techData && techData?.hasOwnProperty("region")
                    ? techData?.region
                    : "-"}
                </span>
              </li>
              {/* revist to cleanup this section 
              <li className="techBasicDetails">
                <span className="popoverInfoSpanVal">
                  Roles:
                  {role}
                </span>
                 <span className="infoSeprator">|</span>
                <span className="popoverInfoSpanVal"></span> 
              </li> */}
            </ul>
          </div>
        </div>
      </Menu>
      <input
        type="hidden"
        name="lanId"
        id="lanId"
        value={techData?.lanId || ""}
      ></input>
      <input type="hidden" name="roles" id="roles" value={role || ""}></input>
      <input
        type="hidden"
        name="techId"
        id="techId"
        value={techData?.techId || ""}
      ></input>
      <input
        type="hidden"
        name="companyId"
        id="companyId"
        value={techData?.companyId || ""}
      ></input>
      <input
        type="hidden"
        name="manager"
        id="manager"
        value={techData?.supervisorName || ""}
      ></input>
      <input
        type="hidden"
        name="region"
        id="region"
        value={techData?.region || ""}
      ></input>
    </div>
  );
};
export default TechnicianPopover;