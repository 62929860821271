import { useState } from "react";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import {
  isNull,
  openNewWindow,
  secToTime,
  validateStatus,
} from "../reusable/Util";
import AppLabels from "../../constants/App_Labels";
import CustomAccordion from "../reusable/CustomAccordion";
import ConfigConst from "../../constants/ConfigConst";

const IHPDeviceInfo = (props: any) => {
  const { summaryData, cmSummaryData, cmNodeDetails } = props;
  const [linesAcc, setLinesAcc] = useState(true);
  const handleAccToggle = (accName: string) => {
    if (accName === "lines") setLinesAcc(!linesAcc);
  };
  const labels = AppLabels();

  return (
    <CustomAccordion
      accTitle={labels.SUMMARY.toUpperCase()}
      accBodyElement={
        <>
          <table className="dataTableDetails">
            <thead>
              <tr>
                <th id="nameCol">{labels.NAME.toUpperCase()}</th>
                <th id="valueCol">{labels.VALUE.toUpperCase()}</th>
              </tr>
            </thead>
            {summaryData !== undefined ? (
              <tbody>
                <tr>
                  <td>EMTA IP</td>
                  <td>{isNull(summaryData?.emtaIP)}</td>
                </tr>
                <tr>
                  <td>EMTA MAC</td>
                  <td>{isNull(summaryData?.emtaMAC)}</td>
                </tr>
                <tr>
                  <td>EMTA FQDN</td>
                  <td>{isNull(summaryData?.emtaFQDN)}</td>
                </tr>
                <tr>
                  <td>CM MAC</td>
                  <td>{isNull(summaryData?.cmmac)}</td>
                </tr>
                <tr>
                  <td>CM IP</td>
                  <td>{isNull(cmSummaryData?.mgmtIP)}</td>
                </tr>
                <tr>
                  <td>EMTA OPERATIONAL STATUS</td>
                  <td>
                    {validateStatus(cmSummaryData?.bootState, [
                      ConfigConst.operational.toLowerCase(),
                    ])}
                  </td>
                </tr>
                <tr>
                  <td>DESCRIPTION</td>
                  <td>{isNull(summaryData?.desc)}</td>
                </tr>
                <tr>
                  <td>EMTA ENABLED</td>
                  <td>{isNull(summaryData?.emtaEnabled)}</td>
                </tr>
                <tr>
                  <td>BATTERY STATUS</td>
                  <td>
                    {validateStatus(summaryData?.batteryStatus, [
                      ConfigConst.unavailable.toLowerCase(),
                      ConfigConst.normal.toLowerCase(),
                      ConfigConst.AC_Restored.toLowerCase(),
                      ConfigConst.Battery_Good.toLowerCase(),
                      ConfigConst.Battery_Present.toLowerCase(),
                      ConfigConst.Battery_Not_Low.toLowerCase(),
                    ])}
                  </td>
                </tr>
                <tr>
                  <td>R FACTOR</td>
                  <td>{isNull(summaryData?.rFactor)}</td>
                </tr>
                <tr>
                  <td>UPTIME</td>
                  <td>{isNull(secToTime(summaryData?.uptime))}</td>
                </tr>
                <tr>
                  <td>PROV INIT STATUS</td>
                  <td>
                    {validateStatus(summaryData?.provInitStatus, [
                      ConfigConst.pass.toLowerCase(),
                      ConfigConst.passWithWarnings.toLowerCase(),
                    ])}
                  </td>
                </tr>
                <tr>
                  <td>PROV OP STATUS</td>
                  <td>
                    {validateStatus(summaryData?.provOpStatus, [
                      ConfigConst.operational.toLowerCase(),
                    ])}
                  </td>
                </tr>
                <tr>
                  <td>CMTS CM Last Registered Time</td>
                  <td>{isNull(cmSummaryData?.cmtsCmRegisteredTimeStr)}</td>
                </tr>
                <tr>
                  <td>CM Operational Status</td>
                  <td>
                    {validateStatus(cmSummaryData?.cmOnlineStatus?.value, [
                      ConfigConst.operational.toLowerCase(),
                    ])}
                  </td>
                </tr>
                <tr>
                  <td>CMTS IP</td>
                  <td>
                    <span
                      className="nodeLink"
                      onClick={() => {
                        openNewWindow(
                          `/#/node/cmts?nodeId=${
                            cmNodeDetails?.nodeId ? cmNodeDetails?.nodeId : "-"
                          }&mac=${
                            cmNodeDetails?.mac_domain
                              ? cmNodeDetails?.mac_domain
                              : "-"
                          }&usPort=${
                            cmNodeDetails?.port_group_name_normalized
                              ? "Upstream " +
                                cmNodeDetails?.port_group_name_normalized
                              : "-"
                          }&cmtsIp=${
                            cmSummaryData?.cmtsIP ? cmSummaryData?.cmtsIP : "-"
                          }`
                        );
                      }}
                    >
                      {isNull(cmSummaryData?.cmtsIP)}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>CMTS CM Status</td>
                  <td>
                    {validateStatus(cmSummaryData?.cmtsCmOnlineStatus?.value, [
                      ConfigConst.registrationComplete.toLowerCase(),
                      ConfigConst.onlineTekAssigned.toLowerCase(),
                      ConfigConst.operational.toLowerCase(),
                    ])}
                  </td>
                </tr>
                <tr>
                  <td>CMTS Name</td>
                  <td>{isNull(cmSummaryData?.cmtsName)}</td>
                </tr>
                <tr>
                  <td>CMTS LOCATION</td>
                  <td>{isNull(cmSummaryData?.cmtsLocation)}</td>
                </tr>
                <tr>
                  <td>CMTS Sys Up Time</td>
                  <td>{isNull(secToTime(cmSummaryData?.cmtsUptime))}</td>
                </tr>
                <tr>
                  <td>MTA Percent Time Online</td>
                  <td>{isNull(cmSummaryData?.onlinePercentage)}</td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td className="noDevices" colSpan={4}>
                    No Data Available...
                  </td>
                </tr>
              </tbody>
            )}
          </table>
          {summaryData?.hasOwnProperty("lines") &&
          summaryData?.lines?.length !== 0 ? (
            <div className="ofdmChDetails">
              <div
                className="dsChDataAcc"
                onClick={() => handleAccToggle("lines")}
              >
                <p style={{ marginBottom: "0px" }}>
                  {labels.LINES.toUpperCase()}
                </p>
                {linesAcc ? (
                  <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
                ) : (
                  <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
                )}
              </div>
              {linesAcc ? (
                <table className="dataTableDetails">
                  <thead style={{ marginTop: "10px" }}>
                    <th>{labels.LINE_NO.toUpperCase()}</th>
                    <th>{labels.HOOK_STATUS.toUpperCase()}</th>
                    {summaryData?.sipRegistrarAddr === undefined && (
                      <th>{labels.NCS_STATUS.toUpperCase()}</th>
                    )}
                    <th>{labels.LINE_STATE.toUpperCase()}</th>
                    <th>{labels.CALL_AGENT_ID.toUpperCase()}</th>
                    <th>{labels.CALL_PSTATE.toUpperCase()}</th>
                    {summaryData?.sipRegistrarAddr && (
                      <>
                        <th>{labels.SIP_URI.toUpperCase()}</th>
                        <th>{labels.SIP_REG_STATUS.toUpperCase()}</th>
                      </>
                    )}
                  </thead>
                  <tbody>
                    {summaryData?.lines?.map((line: any, i: number) => {
                      return (
                        <tr key={i}>
                          <td>{isNull(line?.lineNo)}</td>
                          <td>
                            {validateStatus(line?.hookStatus, [
                              ConfigConst.onHook.toLowerCase(),
                            ])}
                          </td>
                          {summaryData?.sipRegistrarAddr === undefined && (
                            <td>
                              {validateStatus(line?.ncsEndPointStatus, [
                                ConfigConst.operational.toLowerCase(),
                                ConfigConst.unknown.toLowerCase(),
                              ])}
                            </td>
                          )}
                          <td>
                            {line?.lineState.includes(ConfigConst.isnr) ? (
                              <span className="succes">
                                {isNull(line?.lineState)}
                              </span>
                            ) : (
                              <span className="error">
                                {isNull(line?.lineState)}
                              </span>
                            )}
                          </td>
                          <td>{isNull(line?.callAgentId)}</td>
                          <td>{isNull(line?.callPState)}</td>
                          {summaryData?.sipRegistrarAddr && (
                            <>
                              <td>{isNull(line?.sipURI)}</td>
                              <td>{isNull(line?.sipRegStatus)}</td>
                            </>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </>
      }
    />
  );
};

export default IHPDeviceInfo;
