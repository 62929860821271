import { ONTProfileState } from "../../../../states/account/diagnostics/ont/ONTProfileState";
import {
  ONT_Profile_BEGIN,
  ONT_Profile_SUCCESS,
  ONT_Profile_FAILURE,
  ONTProfileActionTypes,
} from "../../../../types/account/diagnostics/ont/ONTProfile";

const initialState: ONTProfileState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function ONTProfileReducer(
  state = initialState,
  action: ONTProfileActionTypes
): ONTProfileState {
  switch (action.type) {
    case ONT_Profile_BEGIN:
      return {
        isLoading: true,
      };
    case ONT_Profile_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case ONT_Profile_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}
