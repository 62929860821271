import { FaSearch } from "react-icons/fa";
import DiagContainer from "../../../../components/reusable/DiagContainer";
import { Divider } from "@material-ui/core";
import SimpleDataTable from "../../reusable/SimpleDataTable";
import ExceptionBox from "../../../../components/reusable/ExceptionBox";
import { useDispatch } from "react-redux";
import TopologyLabels from "../../../constants/topologyLabels";
import {
  GetLocatorElementData,
  GetRbCircuitSearchProps,
} from "../../../util/reduxFunctions/getTopologyState";
import { useState } from "react";
import {
  getRbCircuitSearch,
  resetRbCircuit,
} from "../../../store/slices/search/rbCircuitSearch";
import {
  setMapCenter,
  setSelectedMapElement,
} from "../../../store/slices/mapItems/mapDetails";
import { findEleFromLocatorEle } from "../../../util/utilFuncs";
import appConst from "../../../constants/appConst";
import { addToLocatorEle } from "../../../store/slices/mapItems/locatorElements";

const RbCircuitSearch = () => {
  const dispatch = useDispatch();
  const labels = TopologyLabels();
  const { rbCircuitData, rbCircuitLoading, rbCircuitError } =
    GetRbCircuitSearchProps();
  const { locatorEle } = GetLocatorElementData();
  const [rbCircuit, setRbCircuit] = useState("");

  const rbCircuitConfig = [
    labels.circuitId,
    labels.buildingId,
    labels.ringName,
  ];

  const searchRbCircuit = () => {
    if (rbCircuit) {
      dispatch(getRbCircuitSearch(rbCircuit));
    }
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      searchRbCircuit();
    }
  };
  const makeEleActive = (eleData: any) => {
    dispatch(
      setMapCenter({
        lat: eleData?.geometry?.lat,
        lng: eleData?.geometry?.lng,
      })
    );
    dispatch(setSelectedMapElement(eleData));
  };
  const handleRbCircuitClick = (ele: any) => {
    if (ele) {
      const eleData = findEleFromLocatorEle(
        "buildingId",
        appConst.rbCircuit,
        ele,
        locatorEle?.[appConst.rbCircuit]
      );
      if (eleData) {
        makeEleActive(eleData);
      } else {
        dispatch(
          addToLocatorEle({
            eleType: appConst.rbCircuit,
            eleData: ele,
          })
        );
        let activeEle = {
          geometry: {
            lat: ele?.geometry?.coordinates?.[1],
            lng: ele?.geometry?.coordinates?.[0],
          },
          properties: {
            id: ele?.siteId,
            type: appConst.rbCircuit,
            data: ele,
          },
        };
        makeEleActive(activeEle); /* 
        dispatch(resetRbCircuit()); */
      }
    }
  };

  return (
    <div className="renSearchCont">
      <section>
        <input
          type="text"
          value={rbCircuit}
          placeholder="Enter rb circuit name"
          onChange={(val: any) => {
            setRbCircuit(val.target.value);
          }}
          onKeyPress={(event: any) => handleKeyPress(event)}
        />
        <button onClick={() => searchRbCircuit()}>
          <FaSearch />
        </button>
      </section>
      <div className="searchResult">
        <DiagContainer
          isLoading={rbCircuitLoading}
          data={rbCircuitData}
          error={rbCircuitError}
          sectionName={"RbCircuit Search"}
        >
          <Divider />
          {rbCircuitData?.length > 0 ? (
            <SimpleDataTable
              headers={rbCircuitConfig}
              tBody={rbCircuitData?.map((ele: any, i: number) => {
                if (ele) {
                  return (
                    <tr key={i}>
                      <td
                        className="rensNID"
                        onClick={() => handleRbCircuitClick(ele)}
                      >
                        {ele?.circuitId}
                      </td>
                      <td>{ele?.buildingId}</td>
                      <td>{ele?.ringName}</td>
                    </tr>
                  );
                }
              })}
            />
          ) : (
            <ExceptionBox headerText={labels.noDataFound} />
          )}
        </DiagContainer>
      </div>
    </div>
  );
};

export default RbCircuitSearch;
