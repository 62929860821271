import LoaderGif from "../../resources/images/loader.webp";
import ExceptionBox from "./ExceptionBox";
import { isNull } from "./Util";

export default function DiagContainer(props: any) {
  return (
    <div>
      {props.isLoading ? (
        <div className="diagAPICont">
          <img className="loaderImg" src={LoaderGif} />
        </div>
      ) : props.data != undefined ? (
        props.children
      ) : props.error ? (
        <div className="diagErrorCont">
          {/* <h4>{props.sectionName}</h4> */}
          <ExceptionBox
            exBoxType="error"
            errSecName={props.sectionName}
            errCode={isNull(props.error?.errorCode)}
            ErrorMSG={isNull(props.error?.errorMessage)}
            srcSystem={
              props.error?.hasOwnProperty("sourceSystem")
                ? props.error?.sourceSystem
                : "Request"
            }
            primaryBtn={true}
            secBtn={true}
            handleRefresh={props.handleRefresh}
          /* shareFeedback={shareFeedback} */
          />
        </div>
      ) : null}
    </div>
  );
}
