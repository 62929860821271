import { useDispatch } from "react-redux";
import AstbClient from "../../network/httpclient/astb/AstbClient";
import ASTBDetails from "./ASTBDetails";
import ReloadData from "../reusable/ReloadData";
import DiagApiCallCont from "../reusable/DiagApiCallCont";

const ASTBSummaryInfo = (props: any) => {
  const {
    selectedDevice,
    astbSummaryLoading,
    astbSummaryInfo,
    astbSummaryStatus,
  } = props;
  const dispatch = useDispatch();

  const refreshData = () => {
    dispatch(
      AstbClient.getASTBSummary(
        selectedDevice.serialNumber,
        selectedDevice.macAddress
      )
    );
  };

  return (
    <>
      <div className="refreshCont">
        <ReloadData refreshData={refreshData} />
      </div>
      <div className="cmSummaryCont">
        <DiagApiCallCont
          isLoading={astbSummaryLoading}
          data={astbSummaryInfo}
          sectionName="ASTB Summary"
          status={astbSummaryStatus}
          handleRefresh={() => refreshData()}
        >
          <ASTBDetails deviceData={astbSummaryInfo} />
        </DiagApiCallCont>
      </div>
    </>
  );
};

export default ASTBSummaryInfo;
