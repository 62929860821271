import { Card } from "react-bootstrap";
import { useState } from "react";
import CustomTab from "../../reusable/CustomTab";
import { TabPanel } from "../../../screens/account-page/AccountPageTabs";
import { isNull, getCheckValue, isEmptyObject } from "../../reusable/Util";
import AppLabels from "../../../constants/App_Labels";
import CustomAccordion from "../../reusable/CustomAccordion";
import DocsisUSPrechChData from "./DocsisUSPrechChData";
import OFDMUSPrechChData from "./OFDMUSPrechChData";
import CmUSPrechTab from "../../modem/precheck/CmUSPrechTab";

interface Props {
    cmDocsisInfo?: any;
    preCmDocsisInfo?: any;
    isPrecheck?: boolean;
}

const CmUSPrechChannel = ({ cmDocsisInfo, preCmDocsisInfo, isPrecheck = false }: Props) => {
    const [tabSelIndex, setTabSelIndex] = useState(0);
    let ofdmUpStreamChannels: any = [];
    let preOfdmUpStreamChannels: any = [];
    const labels = AppLabels();
    if (
        cmDocsisInfo?.hasOwnProperty("ofdm") &&
        cmDocsisInfo.ofdm?.hasOwnProperty("upstream") &&
        cmDocsisInfo.ofdm?.upstream?.hasOwnProperty("channels")
    ) {
        cmDocsisInfo.ofdm?.upstream?.channels?.map((usC: any) => {
            ofdmUpStreamChannels.push(usC);
        });
    }
    if (
        preCmDocsisInfo?.hasOwnProperty("ofdm") &&
        preCmDocsisInfo.ofdm?.hasOwnProperty("upstream") &&
        preCmDocsisInfo.ofdm?.upstream?.hasOwnProperty("channels")
    ) {
        preCmDocsisInfo.ofdm?.upstream?.channels?.map((usC: any) => {
            preOfdmUpStreamChannels.push(usC);
        });
    }
    const [count, setIndex] = useState(
        cmDocsisInfo.docsisVersion === "DOCSIS31"
            ? ofdmUpStreamChannels?.length + 1
            : 1
    );
    return (
        <CustomAccordion
            accTitle={labels.UPSTREAM_CHANNELS.toUpperCase()}
            accBodyElement={
                <>
                    {cmDocsisInfo !== undefined ? (
                        <>
                            <Card className="dsChTabsCard">
                                <Card.Header>
                                    <CustomTab
                                        tabType="horizontal"
                                        index={0}
                                        value={tabSelIndex}
                                        handleSelect={(i: any) => setTabSelIndex(i)}
                                        label="DOCSIS 3.0"
                                    />
                                    {cmDocsisInfo?.docsisVersion === "DOCSIS31" &&
                                        ofdmUpStreamChannels?.length !== 0 ? (
                                        ofdmUpStreamChannels?.map((ch: any, index: number) => {
                                            return (
                                                <CustomTab
                                                    key={index}
                                                    tabType="horizontal"
                                                    index={index + 1}
                                                    value={tabSelIndex}
                                                    handleSelect={(i: any) => setTabSelIndex(i)}
                                                    label={`OFDMA`}
                                                />
                                            );
                                        })
                                    ) : (
                                        <></>
                                    )}
                                    <CustomTab
                                        tabType="horizontal"
                                        key={count}
                                        index={count}
                                        value={tabSelIndex}
                                        handleSelect={(i: any) => setTabSelIndex(i)}
                                        label="SPECTRUM"
                                    />
                                </Card.Header>
                                <Card.Body>
                                    {cmDocsisInfo?.docsisVersion === "DOCSIS31" &&
                                        ofdmUpStreamChannels?.length !== 0 ? (
                                        ofdmUpStreamChannels?.map((ch: any, index: number) => {
                                            return (
                                                <TabPanel value={tabSelIndex} index={index + 1}>
                                                    <OFDMUSPrechChData
                                                        ofdmUpChannel={ch}
                                                        totalChannel={ofdmUpStreamChannels.length}
                                                        preOfdmUpChannel={preOfdmUpStreamChannels[index]}
                                                        preTotalChannel={preOfdmUpStreamChannels.length}
                                                    />
                                                </TabPanel>
                                            );
                                        })
                                    ) : (
                                        <></>
                                    )}
                                    {cmDocsisInfo?.hasOwnProperty("docsis30") &&
                                        cmDocsisInfo?.docsis30?.hasOwnProperty("upstream") ? (
                                        <TabPanel value={tabSelIndex} index={0}>
                                            <DocsisUSPrechChData
                                                docsis30UsChannels={cmDocsisInfo.docsis30.upstream}
                                                preDocsis30UsChannels={
                                                    preCmDocsisInfo?.docsis30?.upstream
                                                }
                                            />
                                        </TabPanel>
                                    ) : (
                                        <></>
                                    )}
                                    <TabPanel value={tabSelIndex} index={count}>
                                        <CmUSPrechTab
                                            cmData={cmDocsisInfo}
                                            flag={true}
                                            preCmData={preCmDocsisInfo}
                                        />
                                        <CmUSPrechTab cmData={cmDocsisInfo} flag={true} />
                                    </TabPanel>
                                </Card.Body>
                            </Card>
                        </>
                    ) : (
                        <>NO DATA AVAILABLE...</>
                    )}
                </>
            }
        />
    );
};

export default CmUSPrechChannel;
