import { createSlice } from "@reduxjs/toolkit";
import { defaultZoom } from "../../config/mapConfig";
import { MapStyleTypes } from "../reduxTypes";

//map style themes
const standardMode = require("../../config/mapStyles/standard.json");
const darkMode = require("../../config/mapStyles/dark.json");
const nightMode = require("../../config/mapStyles/night.json");
const aubergineMode = require("../../config/mapStyles/aubergine.json");
const retroMode = require("../../config/mapStyles/retro.json");
const silverMode = require("../../config/mapStyles/silver.json");

const slice = createSlice({
  name: "googleMaps",
  initialState: {
    pressedCoord: undefined,
    zoomLevel: defaultZoom,
    currentViewBDY: null,
    currentMapCenter: null,
    mapStyle: aubergineMode,
    activeMapStyleName: "aubergine" as MapStyleTypes,
  },
  reducers: {
    setPressedCoord: (maps, action) => {
      maps.pressedCoord = action.payload;
    },
    setCurrentZoomLevel: (maps, action) => {
      maps.zoomLevel = action.payload;
    },
    setCurrentViewBDY: (maps, action) => {
      maps.currentViewBDY = action.payload;
    },
    setCurrentMapCenter: (maps, action) => {
      maps.currentMapCenter = action.payload;
    },

    setMapStyle: (maps, { payload }) => {
      maps.activeMapStyleName = payload;
      switch (payload as MapStyleTypes) {
        case "standard":
          maps.mapStyle = standardMode;
          break;
        case "dark":
          maps.mapStyle = darkMode;
          break;
        case "night":
          maps.mapStyle = nightMode;
          break;
        case "aubergine":
          maps.mapStyle = aubergineMode;
          break;
        case "retro":
          maps.mapStyle = retroMode;
          break;
        case "silver":
          maps.mapStyle = silverMode;
          break;
        default:
          maps.mapStyle = standardMode;
          break;
      }
    },
  },
});

export const {
  setPressedCoord,
  setCurrentZoomLevel,
  setCurrentViewBDY,
  setCurrentMapCenter,
  setMapStyle,
} = slice.actions;
//should not be exposed to outside
export default slice.reducer;
