import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { FiExternalLink } from "react-icons/fi";
import { FaCheckCircle } from "react-icons/fa";
import { FiAlertTriangle } from "react-icons/fi";
import { MdError } from "react-icons/md";
import { useEffect, useState } from "react";
import ErrorIcon from "../../../resources/images/icons/ErrorIcon.svg";
import { Divider } from "@material-ui/core";
import FullScreenPopUp from "../../reusable/FullScreenPopUp";
import CertificationDetailsPage from "../../../screens/certification-page/CertificationDetailsPage";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ResetSavedDevices } from "../../../store/actions/account/certifications/ActiveCertificationAction";
import ViewHSICertificationPage from "../../../screens/certification-page/ViewHSICertificationPage";
import { CWMonitoringReset } from "../../../store/actions/account/diagnostics/cm/CWMonitoringAction";
import { CWMonitoringTracer } from "../../../store/actions/account/diagnostics/cm/CWMonitoringAction";
import {
  SetFailedCodewords,
  SetCodewordValues,
} from "../../../store/actions/account/accountInfo/ActiveAccountAction";
import { SetSCQAMFLAG } from "../../../store/actions/account/accountInfo/ActiveAccountAction";
import { cmSpeedTestReset } from "../../../store/slices/cablemodem/cmSpeedTest";
const HSICertification = (props: any) => {
  const { currentStep, handleCancel, handlePrev, handleNext } = props;
  const { certiData } = useSelector(
    (state: any) => ({
      certiData: state.AccountState.Certification.ActiveCertiInfo.certiInfo,
    }),
    shallowEqual
  );

  const [seleTestType, setSeleTestType] = useState(certiData?.data?.testType);
  const [openDiag, setOpenDiag] = useState(false);
  const [openCerti, setOpenCerti] = useState(false);
  const [passCount, setPassCount] = useState(0);
  const [failCount, setFailCount] = useState(0);
  const [skipCount, setSkipCount] = useState(0);
  const [summaryFlag, setSummaryFlag] = useState(false);

  const dispatch = useDispatch();
  const goRunDiag = () => {
    dispatch(ResetSavedDevices());
    dispatch(cmSpeedTestReset());
    dispatch(CWMonitoringTracer(true));
    dispatch(SetFailedCodewords(""));
    dispatch(CWMonitoringReset());
    dispatch(SetSCQAMFLAG(false));
    dispatch(SetCodewordValues(""));
    setOpenDiag(true);
  };
  useEffect(() => {
    if (
      certiData !== undefined &&
      certiData?.hasOwnProperty("data") &&
      certiData.data?.hasOwnProperty("hsiCertificate") &&
      certiData.data.hsiCertificate?.hasOwnProperty("summary")
    ) {
      certiData.data.hsiCertificate?.summary?.marginalPass?.count
        ? setPassCount(
            certiData.data.hsiCertificate?.summary?.passed?.count +
              certiData.data.hsiCertificate?.summary?.marginalPass?.count
          )
        : setPassCount(certiData.data.hsiCertificate?.summary?.passed?.count);
      setFailCount(certiData.data.hsiCertificate?.summary?.failed?.count);
      setSkipCount(certiData.data.hsiCertificate?.summary?.skipped?.count);
      setSeleTestType(certiData.data.testType);
      setSummaryFlag(true);
    }
  }, [certiData]);

  return (
    <div className="certiStepCont">
      <>
        <div className="hsiCertiStep">
          <h4>Perform Whole Home Certification</h4>
          <p>
            Complete the Device Diagnostics and review the same for all
            variables and optimizations. If satisfied, you can submit the
            certification and the information will be saved here below. Note:
            The Test Diagnostic details will open in a new window.
          </p>
          <div>
            <button
              className="runDiagBtn"
              onClick={() => goRunDiag()}
              disabled={seleTestType === "DNG"}
            >
              <span>Run Diagnostics</span> <FiExternalLink />
            </button>
          </div>
          <Divider />
          <p className="hsiCertiWarnText">
            *Once you save the certification, a summary of the result will be
            displayed here. You can still choose to run a new test if you are
            not satisfied with the results.*
          </p>
          {summaryFlag ? (
            <div className="activeCertiCont">
              <label>Whole Home Certification Summary</label>
              <div className="activeCertiResults">
                <span>
                  <h4>{passCount < 10 ? "0" + passCount : passCount}</h4> Device
                  <FaCheckCircle color="#28A745" />
                </span>
                <span>
                  <h4>{failCount < 10 ? "0" + failCount : failCount}</h4> Device
                  <img src={ErrorIcon} />
                </span>
                <span>
                  <h4>{skipCount < 10 ? "0" + skipCount : skipCount}</h4> Device
                  <FiAlertTriangle color="#E77C40" />
                </span>
              </div>
              <i onClick={() => setOpenCerti(true)}>View Certification</i>
            </div>
          ) : (
            <div className="hsiCertiSummary">
              <label>Whole Home Certification Summary</label>
              <p>
                <span>
                  -- Device
                  <FaCheckCircle />
                </span>
                <span>
                  -- Device
                  <MdError />
                </span>
                <span>
                  -- Device
                  <FiAlertTriangle />
                </span>
              </p>
            </div>
          )}
        </div>
        <div className="certiStepBtnCont">
          <button className="certiCancelBtn" onClick={() => handleCancel()}>
            Cancel
          </button>
          <button
            className="certiPrevBtn"
            onClick={() => handlePrev()}
            disabled={currentStep == 1 ? true : false}
          >
            <FaArrowLeft />
            <span>Previous</span>
          </button>
          <button className="certiNextBtn" onClick={() => handleNext(2)}>
            <span>Next</span> <FaArrowRight />
          </button>
        </div>
        <FullScreenPopUp
          open={openDiag}
          handleClose={() => {
            setOpenDiag(false);
            dispatch(ResetSavedDevices());
          }}
          childComponent={
            <CertificationDetailsPage
              testType={seleTestType}
              handleCloseOnSave={() => setOpenDiag(false)}
            />
          }
          pageName="Whole Home Certification Details"
        />
        <FullScreenPopUp
          open={openCerti}
          handleClose={() => {
            setOpenCerti(false);
            dispatch(CWMonitoringReset());
          }}
          childComponent={<ViewHSICertificationPage certiData={certiData} />}
          pageName="Whole Home Certification Details"
        />
      </>
    </div>
  );
};

export default HSICertification;
