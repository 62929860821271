import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import {
  isNull,
  getCheckValue,
  getDelta,
  isEmptyObject,
  getStatusOfCheckHeader,
  popOverCell,
} from "../../reusable/Util";
import { useState } from "react";
import AppLabels from "../../../constants/App_Labels";
interface Props {
  docsis30dsChannels?: any;
  preDocsis30dsChannels?: any;
}

const DocsisDSPrechChData = ({ docsis30dsChannels, preDocsis30dsChannels }: Props) => {
  const labels = AppLabels();
  const [summaryAcc, setSummaryAcc] = useState(true);
  const [detailsAcc, setDetailsAcc] = useState(false);
  const [preAcc, setPreAcc] = useState(false);

  const handleAccToggle = (accName: string) => {
    if (accName === "summary") setSummaryAcc(!summaryAcc);
    else if (accName === "details") setDetailsAcc(!detailsAcc);
    else if (accName === "precheck details") setPreAcc(!preAcc);
  };

  return (
    <div className="ofdmChannelCont">
      <div className="ofdmChSummary">
        <div className="dsChDataAcc" onClick={() => handleAccToggle("summary")}>
          <p>{labels.SUMMARY.toUpperCase()}</p>
          {summaryAcc ? (
            <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
          ) : (
            <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
          )}
        </div>
        {summaryAcc ? (
          <table className="dataTableDetails">
            <thead>
              <tr>
                <th id="nameCol">{labels.NAME.toUpperCase()}</th>
                <th id="preValCol">{labels.PRECHECK_VALUE.toUpperCase()}</th>
                <th id="curValCol">{labels.CURRENT_VALUE.toUpperCase()}</th>
                <th id="diffCol">{labels.DIFFERENCE.toUpperCase()}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {getStatusOfCheckHeader(
                    docsis30dsChannels?.maxRxPower,
                    labels.DOWN_RX_MAX.toUpperCase()
                  )}
                </td>
                {docsis30dsChannels?.hasOwnProperty("maxRxPower") ? (
                  <>
                    <td>
                      {preDocsis30dsChannels?.maxRxPower !== undefined ?
                        popOverCell(preDocsis30dsChannels?.maxRxPower) : "N/A"
                      }
                    </td>
                    <td>
                      {popOverCell(docsis30dsChannels?.maxRxPower)}
                    </td>
                    <td>
                      {getDelta(
                        docsis30dsChannels?.maxRxPower?.value,
                        preDocsis30dsChannels?.maxRxPower?.value
                      )}
                    </td>
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td>
                  {getStatusOfCheckHeader(
                    docsis30dsChannels?.minRxPower,
                    labels.DOWN_RX_MIN.toUpperCase()
                  )}
                </td>

                {docsis30dsChannels?.hasOwnProperty("minRxPower") ? (
                  <>
                    <td>
                      {preDocsis30dsChannels?.minRxPower !== undefined ?
                        popOverCell(preDocsis30dsChannels?.minRxPower) : "N/A"
                      }
                    </td>
                    <td>
                      {popOverCell(docsis30dsChannels?.minRxPower)}
                    </td>
                    <td>
                      {getDelta(
                        docsis30dsChannels?.minRxPower?.value,
                        preDocsis30dsChannels?.minRxPower?.value
                      )}
                    </td>
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td>{labels.DOWN_RX_DELTA.toUpperCase()}</td>
                <td>{isNull(preDocsis30dsChannels?.rxPowerDelta) !== "" ?
                  isNull(preDocsis30dsChannels?.rxPowerDelta) : "N/A"}</td>
                <td>{isNull(docsis30dsChannels?.rxPowerDelta)}</td>
                <td>
                  {getDelta(
                    docsis30dsChannels?.rxPowerDelta,
                    preDocsis30dsChannels?.rxPowerDelta
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  {getStatusOfCheckHeader(
                    docsis30dsChannels?.averageRxPower,
                    labels.DOWN_RX_AVG.toUpperCase()
                  )}
                </td>

                {docsis30dsChannels?.hasOwnProperty("averageRxPower") ? (
                  <>
                    <td>
                      {preDocsis30dsChannels?.averageRxPower !== undefined ?
                        popOverCell(preDocsis30dsChannels?.averageRxPower) : "N/A"
                      }
                    </td>
                    <td>
                      {popOverCell(docsis30dsChannels?.averageRxPower)}
                    </td>
                    <td>
                      {getDelta(
                        docsis30dsChannels?.averageRxPower?.value,
                        preDocsis30dsChannels?.averageRxPower?.value
                      )}
                    </td>
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td>
                  {getStatusOfCheckHeader(
                    docsis30dsChannels?.maxSnr,
                    labels.DOWN_SNR_MAX.toUpperCase()
                  )}
                </td>

                {docsis30dsChannels?.hasOwnProperty("maxSnr") ? (
                  <>
                    <td>
                      {preDocsis30dsChannels?.maxSnr !== undefined ?
                        popOverCell(preDocsis30dsChannels?.maxSnr) : "N/A"
                      }
                    </td>
                    <td>
                      {popOverCell(docsis30dsChannels?.maxSnr)}
                    </td>
                    <td>
                      {getDelta(
                        docsis30dsChannels?.maxSnr?.value,
                        preDocsis30dsChannels?.maxSnr?.value
                      )}
                    </td>
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td>
                  {getStatusOfCheckHeader(
                    docsis30dsChannels?.minSnr,
                    labels.DOWN_SNR_MIN.toUpperCase()
                  )}
                </td>

                {docsis30dsChannels?.hasOwnProperty("minSnr") ? (
                  <>
                    <td>
                      {preDocsis30dsChannels?.minSnr !== undefined ?
                        popOverCell(preDocsis30dsChannels?.minSnr) : "N/A"
                      }
                    </td>
                    <td>
                      {popOverCell(docsis30dsChannels?.minSnr)}
                    </td>
                    <td>
                      {getDelta(
                        docsis30dsChannels?.minSnr?.value,
                        preDocsis30dsChannels?.minSnr?.value
                      )}
                    </td>
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td>{labels.DOWN_SNR_DELTA.toUpperCase()}</td>
                <td>{isNull(preDocsis30dsChannels?.snrDelta) !== "" ?
                  isNull(preDocsis30dsChannels?.snrDelta) : "N/A"}</td>
                <td>{isNull(docsis30dsChannels?.snrDelta)}</td>
                <td>
                  {getDelta(
                    docsis30dsChannels?.snrDelta,
                    preDocsis30dsChannels?.snrDelta
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  {getStatusOfCheckHeader(
                    docsis30dsChannels?.averageSnr,
                    labels.DOWN_SNR_AVG.toUpperCase()
                  )}
                </td>
                {docsis30dsChannels?.hasOwnProperty("averageSnr") ? (
                  <>
                    <td>
                      {preDocsis30dsChannels?.averageSnr !== undefined ?
                        popOverCell(preDocsis30dsChannels?.averageSnr) : "N/A"
                      }
                    </td>
                    <td>
                      {popOverCell(docsis30dsChannels?.averageSnr)}
                    </td>
                    <td>
                      {getDelta(
                        docsis30dsChannels?.averageSnr?.value,
                        preDocsis30dsChannels?.averageSnr?.value
                      )}
                    </td>
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td>{labels.DOWN_FRQ_MAX.toUpperCase()}</td>
                <td>{isNull(preDocsis30dsChannels?.maxFrequency) !== "" ?
                  isNull(preDocsis30dsChannels?.maxFrequency) : "N/A"}</td>
                <td>{isNull(docsis30dsChannels?.maxFrequency)}</td>
                <td>N/A</td>
              </tr>
              <tr>
                <td>{labels.DOWN_FRQ_MIN.toUpperCase()}</td>
                <td>{isNull(preDocsis30dsChannels?.minFrequency) !== "" ?
                  isNull(preDocsis30dsChannels?.minFrequency) : "N/A"}</td>
                <td>{isNull(docsis30dsChannels?.minFrequency)}</td>
                <td>N/A</td>
              </tr>
              <tr>
                <td>{labels.DOWN_FRQ_DELTA.toUpperCase()}</td>
                <td>{isNull(preDocsis30dsChannels?.frequencyDelta) !== "" ?
                  isNull(preDocsis30dsChannels?.frequencyDelta) : "N/A"}</td>
                <td>{isNull(docsis30dsChannels?.frequencyDelta)}</td>
                <td>N/A</td>
              </tr>
              <tr>
                <td>{labels.MODULATION.toUpperCase()}</td>
                {docsis30dsChannels?.hasOwnProperty("channels") &&
                  docsis30dsChannels?.channels?.length >= 0 ? (
                  <>
                    <td>
                      {isNull(preDocsis30dsChannels?.channels?.[0]?.modulation)}
                    </td>
                    <td>
                      {isNull(docsis30dsChannels?.channels?.[0]?.modulation)}
                    </td>
                    <td>N/A</td>
                  </>
                ) : (
                  <></>
                )}
              </tr>
            </tbody>
          </table>
        ) : (
          <></>
        )}
      </div>
      <div className="ofdmChDetails">
        <div className="dsChDataAcc" onClick={() => handleAccToggle("details")}>
          <p>{labels.CHANNELS.toUpperCase()}</p>
          {detailsAcc ? (
            <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
          ) : (
            <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
          )}
        </div>
        {detailsAcc ? (
          <table className="dataTableDetails">
            <thead>
              <tr>
                <th id="nameCol">{labels.FREQUENCY.toUpperCase()}</th>
                <th id="valueCol">{labels.RX_POWER.toUpperCase()}</th>
                <th id="valueCol">{labels.SNR.toUpperCase()}</th>
                <th id="valueCol">{labels.STATUS.toUpperCase()}</th>
              </tr>
            </thead>
            <tbody>
              {docsis30dsChannels?.hasOwnProperty("channels") &&
                docsis30dsChannels?.channels?.length >= 0 ? (
                docsis30dsChannels?.channels
                  ?.slice()
                  ?.sort((a: any, b: any) =>
                    a?.frequency > b?.frequency ? 1 : -1
                  )
                  ?.map((cb: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td>{isNull(cb?.frequency)}</td>
                        {cb?.hasOwnProperty("rx_power") ? (
                          <td>
                            {isNull(getCheckValue(isEmptyObject(cb?.rx_power)))}
                          </td>
                        ) : (
                          <td></td>
                        )}
                        {cb?.hasOwnProperty("snr") ? (
                          <td>
                            {isNull(getCheckValue(isEmptyObject(cb?.snr)))}
                          </td>
                        ) : (
                          <td></td>
                        )}
                        {cb?.hasOwnProperty("status") ? (
                          <td>
                            {isNull(getCheckValue(isEmptyObject(cb?.status)))}
                          </td>
                        ) : (
                          <td></td>
                        )}
                      </tr>
                    );
                  })
              ) : (
                <></>
              )}
            </tbody>
          </table>
        ) : (
          <></>
        )}
      </div>
      <div className="ofdmPreChDetails">
        <div
          className="dsChDataAcc"
          onClick={() => handleAccToggle("precheck details")}
        >
          <p>{labels.PRECHECK_CHANNELS.toUpperCase()}</p>
          {preAcc ? (
            <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
          ) : (
            <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
          )}
        </div>
        {preAcc ? (
          <table className="dataTableDetails">
            <thead>
              <tr>
                <th id="nameCol">{labels.FREQUENCY.toUpperCase()}</th>
                <th id="valueCol">{labels.RX_POWER.toUpperCase()}</th>
                <th id="valueCol">{labels.SNR.toUpperCase()}</th>
                <th id="valueCol">{labels.STATUS.toUpperCase()}</th>
              </tr>
            </thead>
            <tbody>
              {preDocsis30dsChannels?.hasOwnProperty("channels") &&
                preDocsis30dsChannels?.channels?.length >= 0 ? (
                preDocsis30dsChannels?.channels
                  ?.slice()
                  ?.sort((a: any, b: any) =>
                    a?.frequency > b?.frequency ? 1 : -1
                  )
                  ?.map((cb: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td>{isNull(cb?.frequency)}</td>
                        {cb?.hasOwnProperty("rx_power") ? (
                          <td>
                            {isNull(getCheckValue(isEmptyObject(cb?.rx_power)))}
                          </td>
                        ) : (
                          <td></td>
                        )}
                        {cb?.hasOwnProperty("snr") ? (
                          <td>
                            {isNull(getCheckValue(isEmptyObject(cb?.snr)))}
                          </td>
                        ) : (
                          <td></td>
                        )}
                        {cb?.hasOwnProperty("status") ? (
                          <td>
                            {isNull(getCheckValue(isEmptyObject(cb?.status)))}
                          </td>
                        ) : (
                          <td></td>
                        )}
                      </tr>
                    );
                  })
              ) : (
                <></>
              )}
            </tbody>
          </table>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default DocsisDSPrechChData;
