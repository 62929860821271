import { useRef, useState } from "react";
import AppsIcon from "@material-ui/icons/Apps";
import { Dropdown, DropdownButton } from "react-bootstrap";

import "../../design-tokens/gpon-page/GponLandingPage.scss";
import ExportIcon from "../../resources/images/icons/ExportIcon.svg";
import ExternalAppMenu from "../../components/reusable/ExternalAppMenu";
import { PONOLTBanner } from "./PONOLTBanner";
import { PONOLTTelemetryTab } from "./PONOLTTelemetryTab";
import { IoIosArrowDropup } from "react-icons/io";
import GponDictionary from "./GponDictionary";
import NodeTopologySwitch from "../../components/node/NodeTopologySwitch";
import ExceptionBox from "../../components/reusable/ExceptionBox";

export const PONOLTLandingPage = (props: any) => {
  const { tableData, nodeSummaryData,ponNode,ponOltName } = props;
  const [expand, setExpand] = useState(true);
  const [dictionary, setDictionary] = useState(false);
  const [quickLinks, setQuickLinks] = useState(false);
  const [exportShow, setExportShow] = useState(false);

  const handleToggle = () => {
    setExpand((prev) => !prev);
  };

  return (
    <>
      <div className="gponLandingPage">
        <div className="gponBannerContainer">
          <PONOLTBanner
            data={{ ...nodeSummaryData }}
            expand={expand}
          />

          <div className="gponBannerIconsContainer">
            <NodeTopologySwitch />
            <div className="gponBannerIconsWrapper">
              <DropdownButton
                title={
                  <AppsIcon
                    style={{
                      width: "30px",
                      height: "30px",
                    }}
                  />
                }
                className="gponMenuIcon"
              >
                <Dropdown.Item
                  className="gponMenuListItemContainer"
                  onSelect={() => {
                    if (!dictionary) {
                      setDictionary(!dictionary);
                    }
                  }}
                >
                  <span style={{ marginRight: "12px" }}>
                    <GponDictionary
                      show={dictionary}
                      setShow={() => setDictionary(false)}
                    />
                  </span>
                  <span>Dictionary</span>
                </Dropdown.Item>
                <Dropdown.Item
                  className="gponMenuListItemContainer"
                  onClick={() => setQuickLinks(!quickLinks)}
                >
                  <span style={{ marginRight: "12px" }}>
                    <ExternalAppMenu
                      show={quickLinks}
                      setShow={() => setQuickLinks(false)}
                    />
                  </span>
                  <span>Quick Links</span>
                </Dropdown.Item>
                <Dropdown.Item
                  className="gponMenuListItemContainer"
                  onClick={() => setExportShow(!exportShow)}
                >
                  <span style={{ marginRight: "12px" }}>
                    <img src={ExportIcon} alt="export" />
                  </span>
                  <span>Export</span>
                </Dropdown.Item>
              </DropdownButton>
            </div>
            <div
              className="gponCollapseContainer"
              style={{
                rotate: expand ? "0deg" : "180deg",
              }}
            >
              <IoIosArrowDropup
                color="black"
                size={25}
                onClick={handleToggle}
              />
            </div>
          </div>
        </div>
        {tableData && tableData?.length > 0 ? (
          <div className="gponBodyContainer">
            <PONOLTTelemetryTab
              data={tableData}
              open={exportShow}
              ponNode={ponNode}
              ponOltName={ponOltName}
              setOpen={() => setExportShow(false)}
            />
          </div>
        ) : (
          <div className="diagErrorCont">
            <ExceptionBox
              headerText={`There are no customers for the OLT`}
              bodyText=""
              primaryBtn={false}
              secBtn={false}
            />
          </div>
        )}
      </div>
    </>
  );
};