import { Divider } from "@material-ui/core";
import { useEffect, useState } from "react";
import { MdAdd, MdRefresh } from "react-icons/md";
import { useDispatch } from "react-redux";
import ChipGroup from "../../../../components/reusable/ChipGroup";
import ExceptionBox from "../../../../components/reusable/ExceptionBox";
import { GetTechInfo } from "../../../../get-api/GetResponse";
import appConst from "../../../constants/appConst";
import TopologyLabels from "../../../constants/topologyLabels";
import {
  createComment,
  getComments,
  resetCrudData,
} from "../../../store/slices/mapItems/comments/comments";
import "../../../styles/drawer/nodeComments/NodeComments.scss";
import { GetCommentsProps } from "../../../util/reduxFunctions/getTopologyState";
import AddCommentsModal from "./AddCommentsModal";
import NodeCommentsList from "./NodeCommentsList";

interface props {
  activeMapElement: any;
}

const NodeCommentsView = ({ activeMapElement }: props) => {
  const labels = TopologyLabels();
  const dispatch = useDispatch();
  const [searchTypeIndex, setSearchTypeIndex] = useState(0);
  const [addCommentModal, setAddCommentModal] = useState(false);
  const tabChips = [
    { title: labels.all, description: labels.allComments },
    { title: labels.mine, description: labels.myComments },
  ];

  const { eleCommentsData, crudStatus } = GetCommentsProps();
  const data = eleCommentsData?.[activeMapElement?.data?.networkId]?.data;
  const status = eleCommentsData?.[activeMapElement?.data?.networkId]?.status;
  const techData = GetTechInfo()?.data;
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    if (
      tabChips?.[searchTypeIndex]?.title?.toUpperCase() ===
        labels.mine?.toUpperCase() &&
      status === appConst?.OK
    ) {
      const filterData = data.filter(
        (data: any) => data.lanId === techData?.lanId
      );
      setFilteredData(filterData);
    } else {
      setFilteredData(data);
    }
  }, [searchTypeIndex, data]);

  const onRefresh = () => {
    dispatch(getComments(activeMapElement?.data?.networkId));
  };

  const onAddCommentClick = (comment: any) => {
    setAddCommentModal(false);
    dispatch(
      createComment(activeMapElement?.data?.networkId, {
        comment: comment,
      })
    );
  };

  useEffect(() => {
    if (data === undefined && activeMapElement?.data) {
      onRefresh();
    }
  }, []);

  useEffect(() => {
    if (crudStatus !== undefined && crudStatus !== "") {
      if (crudStatus === appConst.OK) {
        onRefresh();
      }
    }
    return () => {
      dispatch(resetCrudData());
    };
  }, [crudStatus]);

  return (
    <>
      <div className="commentsTopHeader">
        <div className="totalComments">
          {labels.total?.toUpperCase()}:{" "}
          {filteredData ? filteredData?.length : 0}
        </div>
        <div className="commentFeatures">
          <ChipGroup
            chips={tabChips}
            activeChipIndex={searchTypeIndex}
            onIndexChange={setSearchTypeIndex}
          />
          <div className="addRefreshFeature">
            <button
              className="addComment"
              onClick={() => {
                setAddCommentModal(true);
              }}
            >
              <MdAdd />
            </button>
            <button className="refreshComments" onClick={onRefresh}>
              <MdRefresh />
            </button>
          </div>
        </div>
      </div>
      <Divider style={{ margin: "6px 0px" }} />

      {data === undefined || filteredData?.length === 0 ? (
        <div className="diagErrorCont">
          <ExceptionBox
            headerText={labels.noCommentFound}
            bodyText={labels.addAComment}
          />
        </div>
      ) : (
        filteredData?.map((e: any) => (
          <NodeCommentsList
            key={e?.id}
            techData={techData}
            filteredData={e}
            dispatch={dispatch}
          />
        ))
      )}

      {addCommentModal && (
        <AddCommentsModal
          setAddCommentModal={setAddCommentModal}
          onAddCommentClick={onAddCommentClick}
        />
      )}
    </>
  );
};

export default NodeCommentsView;
