import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Card } from "@material-ui/core";

import ErrorBox from "../../reusable/ErrorBox";
import AppLabels from "../../../constants/App_Labels";
import {
  rpdDecommReset,
  setMacAddress,
} from "../../../store/ducksPattern/rpd/rpdDecomm";
import {
  isValidNodeId,
  formatNodeId,
  formatMacAddress,
  isMacAddressValid,
  formatRPDMacAddress,
} from "../../reusable/Util";
import { TAF_ORDER_CONST } from "../../../constants/Global";
import RPDClient from "../../../network/httpclient/rpd/RPDClient";

const RPDDecommTop = (props: any) => {
  const { macAdd, isLoading } = props;
  const labels = AppLabels();
  const [macAddr, setMacAddr] = useState("");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("This is Error Message");
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSubmit(macAddr);
    }
  };

  const onMacAdSubmit = (e: any) => {
    let sv = formatMacAddress(e.target.value);
    if (sv?.length <= 12) setMacAddr(sv);
  };

  const handleSubmit = (macaddres: string) => {
    if (isMacAddressValid(macaddres)) {
      dispatch(setMacAddress(macaddres));
      getMacAddressDetails(macaddres);
    } else {
      setOpen(true);
      setError(labels.macAddressEmptyError);
    }
  };

  const createInputMacAdObject = (macAddress: string): any => {
    let inputObject = {
      rpdmacAddress: macAddress,
    };
    return inputObject;
  };

  const getMacAddressDetails = (oldMacAdd: string) => {
    if (oldMacAdd?.length === 12) {
      let stringMacAdd = formatRPDMacAddress(oldMacAdd);
      dispatch(
        RPDClient.getRPDDetailsOnDecommMacAdd(
          TAF_ORDER_CONST.rpdSearchbyMacAddressBotName,
          TAF_ORDER_CONST.rpdSearchbyMacAddressSuiteName,
          createInputMacAdObject(stringMacAdd)
        )
      );
    } else {
      setOpen(true);
      setError(labels.macAddressEmptyError);
    }
  };

  return (
    <>
      <Card className="filterRootContainer">
        <div className="filterContainer">
          <div className="filterRow">
            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.mac_address}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="rpdInputField"
                  onChange={onMacAdSubmit}
                  className="rpdInputField"
                  id="rpdInputField"
                  value={macAddr}
                  onKeyPress={(e: any) => handleKeyPress(e)}
                  placeholder={labels.enter_mac_address}
                />
              </Form.Group>
            </div>
            <div className="topSearchBtn">
              <button
                disabled={isLoading}
                className="applyFilersBtn"
                onClick={() => handleSubmit(macAddr)}
              >
                {labels.SUBMIT}
              </button>
            </div>
          </div>
        </div>
      </Card>
      <ErrorBox
        open={open}
        error={error}
        setOpen={setOpen}
        handleClose={handleClose}
      />
    </>
  );
};
export default RPDDecommTop;
