import { Divider } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getMultiDeviceInfo } from "../../components/reusable/NodeUtil";
import { isNull } from "../../components/reusable/Util";
import { stbDataSetType } from "../../config/STBTelemetryConfig";
import AppLabels from "../../constants/App_Labels";
import ConfigConst from "../../constants/ConfigConst";
import { GetListAcc } from "../../get-api/GetResponse";
import Loader from "../../resources/images/loader.webp";

import storeConst from "../../topology/store/storeConst";

interface IKeyValue {
  text: String;
  value: string | number | JSX.Element;
}

const MultiAccountPageDetails = () => {
  const labels = AppLabels();
  const dispatch = useDispatch();
  const listAcc = GetListAcc();

  const [dataSetName, setDataSetName] = useState("");
  const [bannerInfo, setBannerInfo] = useState<any>({
    active: "",
    assigned: "",
    responsive: "",
    unresponsive: "",
  });

  let dataSetNames: any = stbDataSetType?.map((e: any) => {
    return e?.dataSetName;
  });

  const getValueSpan = (val: any) => {
    if (!listAcc?.isLoading && listAcc?.status === storeConst.OK) {
      return <span>{isNull(val)}</span>;
    } else if (listAcc?.isLoading) {
      return (
        <span>
          <img src={Loader} alt="loading" height="15px" />
        </span>
      );
    } else {
      return <span>-</span>;
    }
  };

  const KeyValuesTitle = (props: IKeyValue) => {
    const { text, value } = props;
    return (
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          alignItems: "center",
        }}
      >
        <span style={{ fontWeight: 600 }}>{text}:</span>
        <span style={{ marginLeft: "5px" }}>{value}</span>
      </span>
    );
  };

  useEffect(() => {
    if (listAcc?.data?.length > 0 && listAcc?.status === ConfigConst.OK) {
      const modemListInfo = getMultiDeviceInfo(listAcc?.data);
      setBannerInfo({
        active: modemListInfo?.active,
        assigned: modemListInfo?.assigned,
        responsive: modemListInfo?.responsive,
        unresponsive: modemListInfo?.unresponsive,
      });
    } else if (listAcc?.isLoading) {
      setBannerInfo({
        active: undefined,
        assigned: undefined,
        responsive: undefined,
        unresponsive: undefined,
      });
    }
  }, [listAcc?.data]);

  return (
    <>
      <div>
        <div className="stbBasicDetails">
          <section className="nodeDetailsCont">
            <div>
              <p>
                <span>{"MULTI- DEVICE ACCOUNT SEARCH"}</span>
              </p>
            </div>
            <Divider orientation="vertical" />
            <div className="teleScores">
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                  paddingLeft: "10px",
                }}
              >
                <KeyValuesTitle
                  text="Active"
                  value={
                    bannerInfo?.active === undefined ? (
                      <img src={Loader} alt="loading" height="20px" />
                    ) : (
                      `${bannerInfo?.active}%`
                    )
                  }
                />
                <KeyValuesTitle
                  text="Assigned"
                  value={
                    bannerInfo?.assigned === undefined ? (
                      <img src={Loader} alt="loading" height="20px" />
                    ) : (
                      `${bannerInfo?.assigned}`
                    )
                  }
                />
              </span>
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                  paddingLeft: "10px",
                }}
              >
                <KeyValuesTitle
                  text="Responsive"
                  value={
                    bannerInfo?.responsive === undefined ? (
                      <img src={Loader} alt="loading" height="20px" />
                    ) : (
                      `${bannerInfo?.responsive}`
                    )
                  }
                />
                <KeyValuesTitle
                  text="Unresponsive"
                  value={
                    bannerInfo?.unresponsive === undefined ? (
                      <img src={Loader} alt="loading" height="20px" />
                    ) : (
                      `${bannerInfo?.unresponsive}`
                    )
                  }
                />
              </span>
            </div>
          </section>
        </div>
        {/* <div className="stbBasicDetails">
          <section className="nodeDetailsCont">
            <MultiDeviceAccTeleTab listAcc={listAcc} />
          </section>
        </div> */}
      </div>
    </>
  );
};

export default MultiAccountPageDetails;
