import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { InfoBox } from "@react-google-maps/api";
import React from "react";

interface Props {
  ampBlockData: any;
}
const MduAmpBlock = React.memo(({ ampBlockData }: Props) => {
  const latLng = new google.maps.LatLng(
    ampBlockData?.geometry?.coordinates?.[1],
    ampBlockData?.geometry?.coordinates?.[0]
  );

  return (
    <>
      <InfoBox
        position={latLng}
        options={{
          closeBoxURL: "",
          disableAutoPan: true,
          boxStyle: {
            width: "auto",
            padding: "5px",
          },
        }}
      >
        <Table className="annotationBlock">
          <TableBody>
            <TableRow>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.DVC_ID}
              </TableCell>
              <TableCell colSpan={2}>
                {ampBlockData?.properties?.data?.tagText?.PART_NO}
              </TableCell>
              <TableCell colSpan={2}>
                {ampBlockData?.properties?.data?.tagText?.FT2LAST}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.FPAD_TXT}
              </TableCell>
              <TableCell colSpan={2}>
                {ampBlockData?.properties?.data?.tagText?.FEQ_TXT}
              </TableCell>
              <TableCell colSpan={2}>
                {ampBlockData?.properties?.data?.tagText?.SPLIT1_TXT}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.INP_D1}
              </TableCell>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.INP_D2}
              </TableCell>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.INP_D3}
              </TableCell>
              <TableCell colSpan={2}>
                {ampBlockData?.properties?.data?.tagText?.PS_ID}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.OUT_D1}
              </TableCell>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.OUT_D2}
              </TableCell>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.OUT_D3}
              </TableCell>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.VOLTS}
              </TableCell>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.PWR_DRCT}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.RTN_R1}
              </TableCell>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.RTN_R2}
              </TableCell>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.RPAD_TXT}
              </TableCell>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.SPARE}
              </TableCell>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.CASCADE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.RTN_OUT_R1}
              </TableCell>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.RTN_OUT_R2}
              </TableCell>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.REQ_TXT}
              </TableCell>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.SPARE}
              </TableCell>
              <TableCell>
                {ampBlockData?.properties?.data?.tagText?.DGN_FREQ3}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </InfoBox>
    </>
  );
});

export default MduAmpBlock;
