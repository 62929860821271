import {
  FACTORY_RESET_BEGIN,
  FACTORY_RESET_SUCCESS,
  FACTORY_RESET_FAILURE,
  FACTORY_RESET_STATUS,
  FactoryResetActionTypes,
} from "../../../types/account/deviceActions/FactoryReset";

export function factoryResetBegin(): FactoryResetActionTypes {
  return {
    type: FACTORY_RESET_BEGIN,
  };
}

export function factoryResetSuccess(): FactoryResetActionTypes {
  return {
    type: FACTORY_RESET_SUCCESS,
  };
}

export function factoryResetFailure(error: Error): FactoryResetActionTypes {
  return {
    type: FACTORY_RESET_FAILURE,
    payload: error,
  };
}

export function factoryResetStaus(): FactoryResetActionTypes {
  return {
    type: FACTORY_RESET_STATUS,
  };
}
