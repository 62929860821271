import { shallowEqual, useDispatch, useSelector } from "react-redux";
import "../../design-tokens/account-page/AccountPage.scss";
import SearchByUrl from "../../components/search/SearchByUrl";
import AccountPageDetails from "./AccountPageDetails";
import { useEffect, useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import {
  ChangeAccState,
  DeleteAccount,
  SetActiveAccountKey,
  UpdateAccount,
} from "../../store/actions/account/AllAccountsAction";
import { useHistory } from "react-router-dom";
import { getAccountState } from "../../components/reusable/Util";
import { GetTechInfo } from "../../get-api/GetResponse";
import { SetActiveSubTabIndex } from "../../store/actions/account/accountInfo/ActiveAccountAction";
import { useLayoutEffect } from "react";
import { getSearchType } from "../../components/reusable/Util";

const AccountPage = (props: any) => {
  const {
    currAccState,
    data,
    allAccounts,
    activeAcckey,
    accSearchValue,
    redirectSource,
  } = useSelector(
    (state: any) => ({
      currAccState: state.AccountState,
      data: state.AccountState.ActiveAccountDetails.data,
      allAccounts: state.AllAccounts.accounts,
      activeAcckey: state.AllAccounts.activeAccKey,
      accSearchValue:
        state.AccountState.ActiveAccountDetails.accountSearchValue,
      redirectSource: state.slices.redirectSource.source,
    }),
    shallowEqual
  );
  const history = useHistory();
  const [deleteAccNum, setDeleteAccNum] = useState("");
  const dispatch = useDispatch();
  const { isMaintenanceRoleAvailable ,roles} = GetTechInfo();
  const pushToHistory = (accNum: string) => {
    if (accNum !== undefined && accNum !== "") {
      history.push({
        pathname: "/account",
        search: `?accountNumber=${accNum}`,
      });
    }
  };
  const [macAddress, setMacAddress] = useState("");
  const [device, setDevice] = useState("");

  // set Active Sub Tab Index to Diagnostic Tab when account is selected from Telemetry page
  // checking if account is selected from Telemetry page by document title and if yes then set active sub tab index to Diagnostic Tab
  useLayoutEffect(() => {
    console.log(window.location.href);

    const currentURL = window.location.href;
    const valueAfterLastEqual = currentURL.split("=").pop();
    let mac = valueAfterLastEqual?.replace(/[-:.]/g, "").toUpperCase();
    const hexPattern = /^[0-9A-Fa-f]{12}$/;
    let isMac = mac?.length === 12 && hexPattern.test(mac);

    if (mac) {
      if (isMac) {
        setMacAddress(mac);
      } else if (/^EMTA$/i.test(mac)) {
        setDevice("EMTA");
      }
    }
    if (data && window.document.title === "Account-" + data?.accountNumber) {
      dispatch(SetActiveSubTabIndex(1));
      window.document.title = "Tech Insight";
    } else if (
      (
        isMaintenanceRoleAvailable &&
        (window.document.title?.includes("ONT SN: ") ||
          window.document.title?.includes("GN-") ||
          window.document.title?.includes("OLT-") ||
          window.document.title?.includes("Tech Insight"))) ||
      redirectSource != ""
    ) {
      dispatch(SetActiveSubTabIndex(1));
    }
    else if(data){
      dispatch(SetActiveSubTabIndex(1));
    }
    if (
      isMaintenanceRoleAvailable &&
      accSearchValue !== undefined &&
      getSearchType(accSearchValue) === "macAddress"
    ) {
      dispatch(SetActiveSubTabIndex(1));
    }
  }, [data, isMaintenanceRoleAvailable,roles]);

  useEffect(() => {
    if (data && allAccounts?.length > 1) {
      pushToHistory(activeAcckey);
      let oldAccState = currAccState;
      let newAccState = getAccountState(allAccounts, activeAcckey);
      if (oldAccState !== undefined && data?.accountNumber !== activeAcckey) {
        dispatch(UpdateAccount(data?.accountNumber, oldAccState));
      }
      if (newAccState !== undefined && data?.accountNumber !== activeAcckey) {
        dispatch(ChangeAccState(newAccState));
      }
    }
  }, [activeAcckey]);
  const handleAccTabClick = (key: string) => {
    dispatch(SetActiveAccountKey(key));
  };
  const getAccNumByTabIndex = (tabIndex: number): string => {
    let accNum = "";
    allAccounts?.map((acc: any, index: number) => {
      if (tabIndex === index) {
        accNum = acc.accNum;
      }
    });
    return accNum;
  };
  const deleteAcc = (accNum: string) => {
    let accTabIndex = 0;
    allAccounts?.map((acc: any, index: number) => {
      if (acc.accNum === accNum) {
        accTabIndex = index + 1;
      }
    });
    if (accTabIndex === allAccounts?.length) {
      if (allAccounts?.length > 1) {
        dispatch(SetActiveAccountKey(getAccNumByTabIndex(accTabIndex - 2)));
      } else {
        dispatch(SetActiveAccountKey(""));
      }
    } else if (accTabIndex < allAccounts?.length) {
      dispatch(SetActiveAccountKey(getAccNumByTabIndex(accTabIndex)));
    }
    if (accNum !== undefined) {
      setDeleteAccNum(accNum);
    }
  };

  useEffect(() => {
    if (deleteAccNum !== undefined && deleteAccNum !== "") {
      dispatch(DeleteAccount(deleteAccNum));
      setDeleteAccNum("");
    }
  }, [deleteAccNum]);
  return (
    <div className="accTabsCont">
      {!data && <SearchByUrl {...props} />}
      {data && allAccounts?.length > 1 ? (
        <section className="accTabs">
          <div className="allAccountsNav">
            {allAccounts?.map((acc: any) => {
              let activeFlag = false;
              if (activeAcckey === acc?.accNum) {
                activeFlag = true;
              }
              return (
                <div className={`accTab-${activeFlag ? "active" : ""}-Btn`}>
                  <span onClick={() => handleAccTabClick(acc?.accNum)}>
                    {acc?.accNum}
                  </span>
                  <IoClose onClick={() => deleteAcc(acc?.accNum)} />
                </div>
              );
            })}
          </div>
          <AccountPageDetails
            {...props}
            macAddress={macAddress}
            device={device}
          />
        </section>
      ) : (
        data && (
          <AccountPageDetails
            {...props}
            macAddress={macAddress}
            device={device}
          />
        )
      )}
    </div>
  );
};

export default AccountPage;
