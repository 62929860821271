import { ONTAlarm } from "../../../../models/account/diagnostics/ont/ONTAlarm";
import {
  ONT_ALARMS_BEGIN,
  ONT_ALARMS_SUCCESS,
  ONT_ALARMS_FAILURE,
  ONTAlarmsActionTypes,
} from "../../../../types/account/diagnostics/ont/ONTAlarms";

export function ONTAlarmsBegin(): ONTAlarmsActionTypes {
  return {
    type: ONT_ALARMS_BEGIN,
  };
}

export function ONTAlarmsSuccess(ONTAlarms: ONTAlarm): ONTAlarmsActionTypes {
  return {
    type: ONT_ALARMS_SUCCESS,
    payload: ONTAlarms,
  };
}

export function ONTAlarmsFailure(error: Error): ONTAlarmsActionTypes {
  return {
    type: ONT_ALARMS_FAILURE,
    payload: error,
  };
}
