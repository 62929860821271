import { NewFeedbackState } from "../../states/feedback/NewFeedbackState"
import {
  SAVE_NEW_FEEDBACK_BEGIN,
  SAVE_NEW_FEEDBACK_SUCCESS,
  SAVE_NEW_FEEDBACK_FAILURE,
  NewFeedbackActionTypes
} from "../../types/feedback/FeedbackCreation"

const initialState: NewFeedbackState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function FeedbackCreationReducer(
  state = initialState,
  action: NewFeedbackActionTypes
): NewFeedbackState  {
  switch (action.type) {
    case SAVE_NEW_FEEDBACK_BEGIN:
      return {
        isLoading: true,
      };
    case SAVE_NEW_FEEDBACK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case SAVE_NEW_FEEDBACK_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}
