import * as React from "react";
import { Divider } from "@material-ui/core";
import AppLabels from "../../constants/App_Labels";
import Loader from "../../resources/images/loader.webp";

export const GponTopBanner: React.FC<any> = (props) => {
  const {
    data,
    expand,
    gponType,
    gponSearchVal,
    loadingStatus,
    diagStartTime,
    diagEndTime,
  } = props;
  const labels = AppLabels();

  interface IKeyValue {
    text: string;
    value: string | number | JSX.Element;
  }

  const KeyValuesTitle = (props: IKeyValue) => {
    const { text, value } = props;
    return (
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          alignItems: "center",
        }}
      >
        <span style={{ fontWeight: 600 }}>{text}:</span>
        <span style={{ marginLeft: "5px" }}>{value}</span>
      </span>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        gap: "5px",
        fontSize: "14px",
      }}
    >
      {gponType ? (
        <>
          <section
            style={{
              display: "flex",
              flexDirection: expand ? "column" : "row",
              gap: "6px",
            }}
          >
            <div>
              <span style={{ fontWeight: 600 }}>
                {labels.olt_name.toUpperCase()}:
              </span>
              &nbsp;
              <span>{gponSearchVal ?? labels.NA}</span>
            </div>
          </section>
          <Divider orientation="vertical" hidden={expand} />
          <section
            style={{
              display: !expand ? "flex" : "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <span style={{ fontWeight: 600, paddingLeft: "10px" }}>
                {labels.Start_Time}:
              </span>
              <span>
                {loadingStatus ? (
                  <img
                    className="nodeTeleDataLoader"
                    src={Loader}
                    alt="LoaderGif"
                  />
                ) : (
                  diagStartTime
                )}
              </span>
              <span style={{ fontWeight: 600, paddingLeft: "10px" }}>
                {labels.End_Time}:
              </span>
              <span>
                {loadingStatus ? (
                  <img
                    className="nodeTeleDataLoader"
                    src={Loader}
                    alt="LoaderGif"
                  />
                ) : (
                  diagEndTime
                )}
              </span>
            </div>
          </section>
        </>
      ) : (
        <>
          <section
            style={{
              display: "flex",
              flexDirection: expand ? "column" : "row",
              gap: "6px",
            }}
          >
            <div>
              <span style={{ fontWeight: 600 }}>
                {labels.NODE_ID.toUpperCase()}:
              </span>
              &nbsp;
              <span>{data?.ponNode ?? labels.NA}</span>
              &nbsp;
              <span className="accTopology">{labels.gpon.toUpperCase()}</span>
            </div>
            <Divider orientation="vertical" hidden={expand} />

            <KeyValuesTitle
              text="PHUB"
              value={data?.phub ? data?.phub : labels.NA}
            />
          </section>

          <Divider
            style={{ margin: "0px 10px" }}
            orientation="vertical"
            hidden={!expand}
          />
          <section
            style={{
              display: expand ? "flex" : "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <KeyValuesTitle
                text="Active"
                value={
                  data?.active === undefined ? (
                    <img src={Loader} alt="loading" height="20px" />
                  ) : (
                    `${data?.active}%`
                  )
                }
              />
              <KeyValuesTitle
                text="Assigned"
                value={
                  data?.assigned === undefined ? (
                    <img src={Loader} alt="loading" height="20px" />
                  ) : (
                    `${data?.assigned}`
                  )
                }
              />
              <KeyValuesTitle
                text="Responsive"
                value={
                  data?.responsive === undefined ? (
                    <img src={Loader} alt="loading" height="20px" />
                  ) : (
                    `${data?.responsive}`
                  )
                }
              />
              <KeyValuesTitle
                text="Unresponsive"
                value={
                  data?.unresponsive === undefined ? (
                    <img src={Loader} alt="loading" height="20px" />
                  ) : (
                    `${data?.unresponsive}`
                  )
                }
              />
            </div>
          </section>
          <Divider
            style={{ margin: "0px 10px" }}
            orientation="vertical"
            hidden={!expand}
          />
          <section
            style={{
              display: expand ? "flex" : "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <KeyValuesTitle
                text="Start Time"
                value={
                  data?.diagStartTime === undefined ? (
                    <img src={Loader} alt="loading" height="20px" />
                  ) : (
                    `${data?.diagStartTime}`
                  )
                }
              />
              <KeyValuesTitle
                text="End Time"
                value={
                  data?.diagEndTime === undefined ? (
                    <img src={Loader} alt="loading" height="20px" />
                  ) : (
                    `${data?.diagEndTime}`
                  )
                }
              />
            </div>
          </section>
        </>
      )}
    </div>
  );
};
