import { useEffect, useState } from "react";
import { TableRow, TableHead } from "@material-ui/core";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import AppLabels from "../../constants/App_Labels";
import MultiDeviceAction from "../device-actions/MultiDeviceAction";

export interface EnhancedTableProps {
  devices: any;
  onSearch: (value: string, by: string) => void;
  onSorting: (field: string, order: string) => void;
}

const DeviceListTableHead = (props: EnhancedTableProps) => {
  const { onSearch, onSorting, devices } = props;
  const labels = AppLabels();
  const [searchType, setSearchType] = useState("");
  const [searchMac, setSearchMac] = useState("");
  const [searchSerial, setSearchSerial] = useState("");
  const [searchModel, setSearchModel] = useState("");
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");
  const [zapAllSTBFlag, setZapAllSTBFlag] = useState(false);
  let stbDevices = ["STB", "DESTB", "GSTB", "HSTB", "IPSTB", "ASTB"];
  const onSortingChange = (field: string) => {
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

    setSortingField(field);
    setSortingOrder(order);
    onSorting(field, order);
  };
  const resetSearchValue = () => {
    setSearchType("");
    setSearchMac("");
    setSearchSerial("");
    setSearchModel("");
    onSearch("", "");
  };
  const onTypeInputChange = (value: any, by: string) => {
    setSearchType(value);
    onSearch(value, by);
  };
  const onMACInputChange = (value: string, by: string) => {
    let filterMac = value.replace(/[^a-zA-Z0-9]/g, "");
    setSearchMac(value);
    onSearch(filterMac, by);
  };
  const onSerialInputChange = (value: any, by: string) => {
    setSearchSerial(value);
    onSearch(value, by);
  };
  const onModelInputChange = (value: any, by: string) => {
    setSearchModel(value);
    onSearch(value, by);
  };
  useEffect(() => {
    if (devices !== undefined && devices?.length !== 0) {
      let filteredSTBs = devices.filter(
        (d: any) => stbDevices?.indexOf(d.deviceCategory) > -1
      );
      if (filteredSTBs?.length !== 0) {
        setZapAllSTBFlag(true);
      } else {
        setZapAllSTBFlag(false);
      }
    }
  }, [devices]);

  return (
    <>
      <TableHead className="dTabHead">
        <TableRow>
          <th onClick={() => onSortingChange("deviceCategory")}>
            <span>{labels.DEVICE_CATEGORY}</span>
            {sortingField && sortingField === "deviceCategory" ? (
              <button className="deviceSortBtn">
                {sortingOrder === "asc" ? (
                  <FaSortUp color="black" />
                ) : (
                  <FaSortDown color="black" />
                )}
              </button>
            ) : (
              <></>
            )}
          </th>
          <th onClick={() => onSortingChange("deviceModel")}>
            <span>{labels.MODEL}</span>
            {sortingField && sortingField === "deviceModel" ? (
              <button className="deviceSortBtn">
                {sortingOrder === "asc" ? (
                  <FaSortUp color="black" />
                ) : (
                  <FaSortDown color="black" />
                )}
              </button>
            ) : (
              <></>
            )}
          </th>
          <th>{labels.MAC_ADDRESS}</th>
          <th>{labels.SERIAL_NUMBER}</th>
          <th>{labels.STATUS}</th>
          <th style={{ textAlign: "center" }}>{labels.ACTION}</th>
        </TableRow>
        <TableRow>
          <td>
            <input
              type="text"
              className="sInput"
              placeholder="Search.."
              value={searchType}
              onFocus={resetSearchValue}
              onChange={(e) => onTypeInputChange(e.target.value, "type")}
            />
          </td>
          <td>
            <input
              type="text"
              className="sInput"
              placeholder="Search.."
              value={searchModel}
              onFocus={resetSearchValue}
              onChange={(e) => onModelInputChange(e.target.value, "model")}
            />
          </td>
          <td>
            <input
              type="text"
              className="sInput"
              placeholder="Search.."
              value={searchMac}
              onFocus={resetSearchValue}
              onChange={(e) =>
                onMACInputChange(e.target.value.toString(), "macAddress")
              }
            />
          </td>
          <td>
            <input
              type="text"
              className="sInput"
              placeholder="Search.."
              value={searchSerial}
              onFocus={resetSearchValue}
              onChange={(e) => onSerialInputChange(e.target.value, "serialNum")}
            />
          </td>
          <td></td>
          <td>
            {zapAllSTBFlag ? (
              <MultiDeviceAction menuType="horizontal" />
            ) : (
              <></>
            )}
          </td>
        </TableRow>
      </TableHead>
    </>
  );
};

export default DeviceListTableHead;
