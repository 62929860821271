import { createSlice } from "@reduxjs/toolkit";
import { ACCOUNT_SERVICE_URL, SPEED_TEST_URI } from "../../middleware/apiEndpoints";
import storeConst from "../../storeConst";
import { apiCallBegan } from "../../../topology/store/middleware/apiActions";

const slice = createSlice({
    name: "cmSpeedTest",
    initialState: {
        isLoading: false,
        data: [] as Array<any>,
        error: undefined,
        status: "",
    },
    reducers: {
        cmSpeedTestBegin: (as) => {
            as.isLoading = true;
            as.status = "";
        },
        cmSpeedTestSuccess: (as, { payload }) => {
            as.isLoading = false;
            as.data.unshift(payload);
            as.status = storeConst.OK;
        },
        cmSpeedTestFailure: (as, { payload }) => {
            as.isLoading = false;
            as.error = payload;
            as.data.unshift(payload);
            as.status = storeConst.ERROR;
        },
        cmSpeedTestReset: (as) => {
            as.isLoading = false;
            as.status = "";
            as.data = [];
            as.error = undefined;
        },
    },
});

export const {
    cmSpeedTestBegin,
    cmSpeedTestSuccess,
    cmSpeedTestFailure,
    cmSpeedTestReset,
} = slice.actions;

export default slice.reducer;

//action creators
export const getCmSpeedTest = (macAddress: any) =>
    apiCallBegan({
        baseURL: ACCOUNT_SERVICE_URL,
        url: `${SPEED_TEST_URI}?macAddress=${macAddress}`,
        onStart: cmSpeedTestBegin.type,
        onSuccess: cmSpeedTestSuccess.type,
        onError: cmSpeedTestFailure.type,
    });