import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { NetworkConfig } from "../NetworkConfig";
import { RPDState } from "../../../store/states/rpd/RPDState";
import { RPDDetails } from "../../../store/models/rpd/RPDDetails";
import { RPDRegistrationRes } from "../../../store/models/rpd/RPDRegistrationRes";
import { RPDSwapRes } from "../../../store/models/rpd/RPDSwapRes";
import { RPDNPTSummaryRes } from "../../../store/models/rpd/RPDNPTSummaryRes";
import { RPDActivationRes } from "../../../store/models/rpd/RPDActivationRes";
import { RPDOrderInfoRes } from "../../../store/models/rpd/RPDOrderInfoRes";
import { RPDProvisioningRes } from "../../../store/models/rpd/RPDProvisioningRes";
import { RPDSWUpgradeRes } from "../../../store/models/rpd/RPDSWUpgradeRes";
import { VccapCasaVideoExecRes } from "../../../store/models/rpd/VccapCasaVideoExecRes";
import { VccapUATPortConfigRes } from "../../../store/models/rpd/VccapUATPortConfigRes";
import { VccapSWUpgradeRes } from "../../../store/models/rpd/VccapSWUpgradeRes";
import { VccapHarmonicRPDRes } from "../../../store/models/rpd/VccapHarmonicRPDRes";

import {
  rpdInfoBegin,
  rpdInfoSuccess,
  rpdInfoFailure,
} from "../../../store/ducksPattern/rpd/rpd";
import {
  rpdInfoMacBegin,
  rpdInfoMacSuccess,
  rpdInfoMacFailure,
} from "../../../store/ducksPattern/rpd/rpdMac";
import {
  rpdRegistrationBegin,
  rpdRegistrationSuccess,
  rpdRegistrationFailure,
  setRPDClamshellId,
} from "../../../store/ducksPattern/rpd/rpdRegistration";
import {
  rpdSwapBegin,
  rpdSwapSuccess,
  rpdSwapFailure,
} from "../../../store/ducksPattern/rpd/rpdSwap";
import {
  nptSummaryBegin,
  nptSummarySuccess,
  nptSummaryFailure,
} from "../../../store/ducksPattern/rpd/nptSummary";
import {
  nptSummaryMacBegin,
  nptSummaryMacSuccess,
  nptSummaryMacFailure,
} from "../../../store/ducksPattern/rpd/nptSummaryMac";
import {
  rpdActivationBegin,
  rpdActivationSuccess,
  rpdActivationFailure,
} from "../../../store/ducksPattern/rpd/rpdActivation";
import {
  rpdOrderInfoBegin,
  rpdOrderInfoSuccess,
  rpdOrderInfoFailure,
  rpdOrderInfoReset,
} from "../../../store/ducksPattern/rpd/rpdOrderInfo";
import {
  rpdProvisioningBegin,
  rpdProvisioningSuccess,
  rpdProvisioningFailure,
  rpdProvisioningReset,
} from "../../../store/ducksPattern/rpd/rpdProvisioning";

import {
  vccapCASAVideoBegin,
  vccapCASAVideoSuccess,
  vccapCASAVideoFailure,
  vccapCASAVideoReset,
} from "../../../store/ducksPattern/rpd/vccapCASAVideo";

import {
  rpdSWUpgradeBegin,
  rpdSWUpgradeSuccess,
  rpdSWUpgradeFailure,
  rpdSWUpgradeReset,
} from "../../../store/ducksPattern/rpd/rpdSWUpgrade";

import {
  rpdSWUpgradeMacBegin,
  rpdSWUpgradeMacSuccess,
  rpdSWUpgradeMacFailure,
  rpdSWUpgradeMacReset,
} from "../../../store/ducksPattern/rpd/rpdSWUpgradeMac";

import {
  vccapSWUpgradeBegin,
  vccapSWUpgradeSuccess,
  vccapSWUpgradeFailure,
  vccapSWUpgradeReset,
} from "../../../store/ducksPattern/rpd/vccapSWUpgrade";

import {
  vccapUATPortConfigBegin,
  vccapUATPortConfigSuccess,
  vccapUATPortConfigFailure,
  vccapUATPortConfigReset,
} from "../../../store/ducksPattern/rpd/vccapUATPortConfig";

import {
  vccapHarmonicConfigBegin,
  vccapHarmonicConfigSuccess,
  vccapHarmonicConfigFailure,
  vccapHarmonicConfigReset,
} from "../../../store/ducksPattern/rpd/vccapHarmonicConfig";
import { sortObject } from "../../../components/reusable/Util";
import { RPDOptionsRes } from "../../../store/models/rpd/RPDOptionsRes";
import {
  rpdOptionsListBegin,
  rpdOptionsListFailure,
  rpdOptionsListSuccess,
} from "../../../store/ducksPattern/rpd/rpdOptionsList";
import {
  rpdDecommBegin,
  rpdDecommSuccess,
  rpdDecommFailure,
} from "../../../store/ducksPattern/rpd/rpdDecomm";
import {
  rpdInfoDecommMacBegin,
  rpdInfoDecommMacSuccess,
  rpdInfoDecommMacFailure,
} from "../../../store/ducksPattern/rpd/rpdDecommMac";
import {
  rpdInfoSwBegin,
  rpdInfoSwFailure,
  rpdInfoSwSuccess,
} from "../../../store/ducksPattern/rpd/rpdSwVersion";

export default class RPDClient {
  static getClamshellDetailsOnNode =
    (
      botName: string,
      suiteName: string,
      inputObj: any
    ): ThunkAction<void, RPDState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "taf/createOrder",
        params: {
          botName: botName,
          suiteName: suiteName,
        },
      };

      dispatch(rpdInfoBegin());

      HttpClient.post<RPDDetails>(httpParameters, JSON.stringify(inputObj))
        .then((RpdDetails) => {
          dispatch(rpdInfoSuccess(RpdDetails));
          return RpdDetails;
        })
        .catch((error) => {
          dispatch(rpdInfoFailure(error));
          return error;
        });
    };

  static getClamshellDetailsOnMacAdd =
    (
      botName: string,
      suiteName: string,
      inputObj: any
    ): ThunkAction<void, RPDState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "taf/createOrder",

        params: {
          botName: botName,
          suiteName: suiteName,
        },
      };

      dispatch(rpdInfoBegin());

      HttpClient.post<RPDDetails>(httpParameters, JSON.stringify(inputObj))
        .then((RpdDetails) => {
          dispatch(rpdInfoSuccess(RpdDetails));
          return RpdDetails;
        })
        .catch((error) => {
          dispatch(rpdInfoFailure(error));
          return error;
        });
    };

  static getMacSwVersion =
    (
      botName: string,
      suiteName: string,
      inputObj: any
    ): ThunkAction<void, RPDState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "taf/createOrder",

        params: {
          botName: botName,
          suiteName: suiteName,
        },
      };

      dispatch(rpdInfoSwBegin());

      HttpClient.post<RPDDetails>(httpParameters, JSON.stringify(inputObj))
        .then((RpdDetails) => {
          dispatch(rpdInfoSwSuccess(RpdDetails));
          return RpdDetails;
        })
        .catch((error) => {
          dispatch(rpdInfoSwFailure(error));
          return error;
        });
    };

  static getRPDDetailsOnMacAdd =
    (
      botName: string,
      suiteName: string,
      inputObj: any
    ): ThunkAction<void, RPDState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "taf/createOrder",

        params: {
          botName: botName,
          suiteName: suiteName,
        },
      };

      dispatch(rpdInfoMacBegin());

      HttpClient.post<RPDDetails>(httpParameters, JSON.stringify(inputObj))
        .then((RpdDetails) => {
          dispatch(rpdInfoMacSuccess(RpdDetails));
          return RpdDetails;
        })
        .catch((error) => {
          dispatch(rpdInfoMacFailure(error));

          return error;
        });
    };

  static getRPDDetailsOnDecommMacAdd =
    (
      botName: string,
      suiteName: string,
      inputObj: any
    ): ThunkAction<void, RPDState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "taf/createOrder",

        params: {
          botName: botName,
          suiteName: suiteName,
        },
      };

      dispatch(rpdInfoDecommMacBegin());

      HttpClient.post<RPDDetails>(httpParameters, JSON.stringify(inputObj))
        .then((RpdDetails) => {
          dispatch(rpdInfoDecommMacSuccess(RpdDetails));
          return RpdDetails;
        })
        .catch((error) => {
          dispatch(rpdInfoDecommMacFailure(error));

          return error;
        });
    };
  static postRegister =
    (
      botName: string,
      suiteName: string,
      inputObj: any
    ): ThunkAction<void, RPDState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "taf/createOrder",

        params: {
          botName: botName,
          suiteName: suiteName,
        },
      };

      dispatch(rpdRegistrationBegin());

      HttpClient.post<RPDRegistrationRes>(
        httpParameters,
        JSON.stringify(inputObj)
      )
        .then((RPDRegistrationRes) => {
          dispatch(rpdRegistrationSuccess(RPDRegistrationRes));
          return RPDRegistrationRes;
        })
        .catch((error) => {
          dispatch(rpdRegistrationFailure(error));
          return error;
        });
    };

  static getNPTSummary =
    (
      botName: string,
      suiteName: string,
      inputObj: any
    ): ThunkAction<void, RPDState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "taf/createOrder",

        params: {
          botName: botName,
          suiteName: suiteName,
        },
      };

      dispatch(nptSummaryBegin());

      HttpClient.post<RPDNPTSummaryRes>(
        httpParameters,
        JSON.stringify(inputObj)
      )
        .then((RPDNPTSummaryRes) => {
          dispatch(nptSummarySuccess(RPDNPTSummaryRes));
          return RPDNPTSummaryRes;
        })
        .catch((error) => {
          dispatch(nptSummaryFailure(error));

          return error;
        });
    };

  static getNPTSummarySwUpg =
    (
      botName: string,
      suiteName: string,
      inputObj: any
    ): ThunkAction<void, RPDState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "taf/createOrder",

        params: {
          botName: botName,
          suiteName: suiteName,
        },
      };

      dispatch(nptSummaryMacBegin());

      HttpClient.post<RPDNPTSummaryRes>(
        httpParameters,
        JSON.stringify(inputObj)
      )
        .then((RPDNPTSummaryRes) => {
          dispatch(nptSummaryMacSuccess(RPDNPTSummaryRes));
          return RPDNPTSummaryRes;
        })
        .catch((error) => {
          dispatch(nptSummaryMacFailure(error));
          return error;
        });
    };

  static postActivation =
    (
      botName: string,
      suiteName: string,
      inputObj: any
    ): ThunkAction<void, RPDState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "taf/createOrder",

        params: {
          botName: botName,
          suiteName: suiteName,
        },
      };

      dispatch(rpdActivationBegin());

      HttpClient.post<RPDActivationRes>(httpParameters, inputObj)
        .then((RPDActivationRes) => {
          dispatch(rpdActivationSuccess(RPDActivationRes));
          return RPDActivationRes;
        })
        .catch((error) => {
          dispatch(rpdActivationFailure(error));
          return error;
        });
    };

  static postSwap =
    (
      botName: string,
      suiteName: string,
      inputObj: any
    ): ThunkAction<void, RPDState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "taf/createOrder",

        params: {
          botName: botName,
          suiteName: suiteName,
        },
      };

      dispatch(rpdSwapBegin());

      HttpClient.post<RPDSwapRes>(httpParameters, JSON.stringify(inputObj))
        .then((RPDSwapRes) => {
          dispatch(rpdSwapSuccess(RPDSwapRes));
          return RPDSwapRes;
        })
        .catch((error) => {
          dispatch(rpdSwapFailure(error));
          return error;
        });
    };

  static createSwap =
    (
      botName: string,
      suiteName: string,
      inputObj: any
    ): ThunkAction<void, RPDState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "taf/createOrderId",

        params: {
          botName: botName,
          suiteName: suiteName,
        },
      };

      dispatch(rpdSwapBegin());

      HttpClient.post<RPDSwapRes>(httpParameters, JSON.stringify(inputObj))
        .then((RPDSwapRes) => {
          dispatch(rpdSwapSuccess(RPDSwapRes));
          return RPDSwapRes;
        })
        .catch((error) => {
          dispatch(rpdSwapFailure(error));
          return error;
        });
    };

  static createCrossSwap =
    (
      botName: string,
      suiteName: string,
      inputObj: any
    ): ThunkAction<void, RPDState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "taf/createOrderId",

        params: {
          botName: botName,
          suiteName: suiteName,
        },
      };

      dispatch(rpdSwapBegin());

      HttpClient.post<RPDSwapRes>(httpParameters, JSON.stringify(inputObj))
        .then((RPDSwapRes) => {
          dispatch(rpdSwapSuccess(RPDSwapRes));
          return RPDSwapRes;
        })
        .catch((error) => {
          dispatch(rpdSwapFailure(error));
          return error;
        });
    };

  static createVccapHarmonicConfig =
    (
      botName: string,
      suiteName: string,
      inputObj: any
    ): ThunkAction<void, RPDState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "taf/createOrderId",

        params: {
          botName: botName,
          suiteName: suiteName,
        },
      };

      dispatch(vccapHarmonicConfigBegin());

      HttpClient.post<VccapHarmonicRPDRes>(
        httpParameters,
        JSON.stringify(inputObj)
      )
        .then((VccapHarmonicRPDRes) => {
          dispatch(vccapHarmonicConfigSuccess(VccapHarmonicRPDRes));
          return VccapHarmonicRPDRes;
        })
        .catch((error) => {
          dispatch(vccapHarmonicConfigFailure(error));
          return error;
        });
    };

  static createVCCAPSW =
    (
      botName: string,
      suiteName: string,
      inputObj: any
    ): ThunkAction<void, RPDState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "taf/createOrderId",

        params: {
          botName: botName,
          suiteName: suiteName,
        },
      };

      dispatch(vccapSWUpgradeBegin());

      HttpClient.post<VccapSWUpgradeRes>(
        httpParameters,
        JSON.stringify(inputObj)
      )
        .then((VccapSWUpgradeRes) => {
          console.log(JSON.stringify(VccapSWUpgradeRes));
          dispatch(vccapSWUpgradeSuccess(VccapSWUpgradeRes));
          return VccapSWUpgradeRes;
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
          dispatch(vccapSWUpgradeFailure(error));
          return error;
        });
    };

  static createVCCAPUATportConfig =
    (
      botName: string,
      suiteName: string,
      inputObj: any
    ): ThunkAction<void, RPDState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "taf/createOrderId",

        params: {
          botName: botName,
          suiteName: suiteName,
        },
      };

      dispatch(vccapUATPortConfigBegin());

      HttpClient.post<VccapUATPortConfigRes>(
        httpParameters,
        JSON.stringify(inputObj)
      )
        .then((VccapUATPortConfigRes) => {
          console.log(JSON.stringify(VccapUATPortConfigRes));
          dispatch(vccapUATPortConfigSuccess(VccapUATPortConfigRes));
          return VccapUATPortConfigRes;
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
          dispatch(vccapUATPortConfigFailure(error));
          return error;
        });
    };

  static createProvisioning =
    (
      botName: string,
      suiteName: string,
      inputObj: any
    ): ThunkAction<void, RPDState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "taf/createOrderId",

        params: {
          botName: botName,
          suiteName: suiteName,
        },
      };

      dispatch(rpdProvisioningBegin());

      HttpClient.post<RPDProvisioningRes>(
        httpParameters,
        JSON.stringify(inputObj)
      )
        .then((RPDProvisioningRes) => {
          dispatch(rpdProvisioningSuccess(RPDProvisioningRes));
          return RPDProvisioningRes;
        })
        .catch((error) => {
          dispatch(rpdProvisioningFailure(error));
          return error;
        });
    };

  static createCASAVideoExec =
    (
      botName: string,
      suiteName: string,
      inputObj: any
    ): ThunkAction<void, RPDState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "taf/createOrderId",

        params: {
          botName: botName,
          suiteName: suiteName,
        },
      };

      dispatch(vccapHarmonicConfigBegin());

      HttpClient.post<VccapCasaVideoExecRes>(
        httpParameters,
        JSON.stringify(inputObj)
      )
        .then((VccapCasaVideoExecRes) => {
          dispatch(vccapHarmonicConfigSuccess(VccapCasaVideoExecRes));
          return VccapCasaVideoExecRes;
        })
        .catch((error) => {
          dispatch(vccapHarmonicConfigFailure(error));
          return error;
        });
    };
  static createSWUpgrade =
    (
      botName: string,
      suiteName: string,
      inputObj: any
    ): ThunkAction<void, RPDState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "taf/createOrderId",

        params: {
          botName: botName,
          suiteName: suiteName,
        },
      };

      dispatch(rpdSWUpgradeBegin());

      HttpClient.post<RPDSWUpgradeRes>(httpParameters, JSON.stringify(inputObj))
        .then((RPDSWUpgradeRes) => {
          console.log(JSON.stringify(RPDSWUpgradeRes));
          dispatch(rpdSWUpgradeSuccess(RPDSWUpgradeRes));
          return RPDSWUpgradeRes;
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
          dispatch(rpdSWUpgradeFailure(error));
          return error;
        });
    };

  static createSWUpgradeMac =
    (
      botName: string,
      suiteName: string,
      inputObj: any
    ): ThunkAction<void, RPDState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "taf/createOrderId",

        params: {
          botName: botName,
          suiteName: suiteName,
        },
      };

      dispatch(rpdSWUpgradeMacBegin());

      HttpClient.post<RPDSWUpgradeRes>(httpParameters, JSON.stringify(inputObj))
        .then((RPDSWUpgradeRes) => {
          dispatch(rpdSWUpgradeMacSuccess(RPDSWUpgradeRes));
          return RPDSWUpgradeRes;
        })
        .catch((error) => {
          dispatch(rpdSWUpgradeMacFailure(error));
          return error;
        });
    };

  static createSWUpgradeDA2200Mac =
    (
      botName: string,
      suiteName: string,
      inputObj: any
    ): ThunkAction<void, RPDState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "taf/createOrderId",

        params: {
          botName: botName,
          suiteName: suiteName,
        },
      };

      dispatch(rpdSWUpgradeMacBegin());

      HttpClient.post<RPDSWUpgradeRes>(httpParameters, JSON.stringify(inputObj))
        .then((RPDSWUpgradeRes) => {
          dispatch(rpdSWUpgradeMacSuccess(RPDSWUpgradeRes));
          return RPDSWUpgradeRes;
        })
        .catch((error) => {
          dispatch(rpdSWUpgradeMacFailure(error));
          return error;
        });
    };

  static createDecomm =
    (
      botName: string,
      suiteName: string,
      inputObj: any
    ): ThunkAction<void, RPDState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "taf/createOrderId",

        params: {
          botName: botName,
          suiteName: suiteName,
        },
      };

      dispatch(rpdDecommBegin());

      HttpClient.post<RPDSWUpgradeRes>(httpParameters, JSON.stringify(inputObj))
        .then((RPDSWUpgradeRes) => {
          dispatch(rpdDecommSuccess(RPDSWUpgradeRes));
          return RPDSWUpgradeRes;
        })
        .catch((error) => {
          dispatch(rpdDecommFailure(error));
          return error;
        });
    };

  static getOrderInfo =
    (orderid: string): ThunkAction<void, RPDState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "taf/getOrderId",

        params: {
          orderId: orderid,
        },
      };

      dispatch(rpdOrderInfoBegin());

      HttpClient.get<RPDOrderInfoRes>(httpParameters)
        .then((RPDOrderInfoRes) => {
          dispatch(rpdOrderInfoSuccess(RPDOrderInfoRes));
          return RPDOrderInfoRes;
        })
        .catch((error) => {
          dispatch(rpdOrderInfoFailure(error));
          return error;
        });
    };

  static getOptionsList =
    (): ThunkAction<void, RPDState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "taf/getAllOptions",
      };

      dispatch(rpdOptionsListBegin());

      HttpClient.get<RPDOptionsRes>(httpParameters)
        .then((RPDOptionsRes) => {
          dispatch(rpdOptionsListSuccess(RPDOptionsRes));
          return RPDOptionsRes;
        })
        .catch((error) => {
          dispatch(rpdOptionsListFailure(error));
          return error;
        });
    };
}
