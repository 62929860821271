import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";

const TelemetryPopUp = (props: any) => {
  const { showVal, headerText, bodyElement, handleClose, requestType } = props;
  
  return (
    <div  >
      <Modal
        show={showVal}
        onHide={() => handleClose()}
        backdrop="static"
        keyboard={false}
        centered
        id={(requestType==="Flap History")?"telemetrymediumpopup":"telemetrypopup"}
      >
        <Modal.Header className="searchPopHead">
          <header>{headerText}</header>
          <button className="sPopUpClose" onClick={() => handleClose()}>
            <IoClose color="white" />
          </button>
        </Modal.Header>
        <Modal.Body className="cmSummaryCont">{bodyElement}</Modal.Body>

        <Modal.Footer>
          <button className="sPopCloseBtn" onClick={() => handleClose()}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TelemetryPopUp;
