export const ACCOUNT_SERVICE_URL = (window as any).config.ACCOUNT_SERVICE_URL;
export const NETWORK_SERVICE_URL = (window as any).config.NETWORK_SERVICE_URL;
export const TOPOLOGY_SERVICE_URL = (window as any).config.TOPOLOGY_SERVICE_URL;

//API URIs
export const WO_DETAILS_URI = "workorders/workOrderDetails";
export const WO_STATUS_UPDATE_URI = "workorders/statusUpdate";
export const NODE_CERTIFICATION_URI = "node/certificate";
export const NODE_CERTIFICATION_DETAILS = "node/certificateDetails";
export const GPON_OLT_NAME_SEARCH_DETAILS = "diag/network/olt/";
export const GPON_ONT_COUNT_FLUX = "area/gpon/ontcount/flux";
export const SPEED_TEST_URI = "/diag/gateway/speedTest";
export const FILE_DOWNLOAD_URI = "/file/download/";
export const FILE_UPLOAD_URI = "/file/upload/";
export const FILE_FETCH_URI = "/file/get";
export const GW_DEVICES="/diag/gateway/device";