import { WirelessSubscriber } from "../../../../models/account/diagnostics/odu/WirelessSubscriber";

export const WIRELESS_SUBSCRIBER_BEGIN = "WIRELESS_SUBSCRIBER_BEGIN";
export const WIRELESS_SUBSCRIBER_SUCCESS = "WIRELESS_SUBSCRIBER_SUCCESS";
export const WIRELESS_SUBSCRIBER_FAILURE = "WIRELESS_SUBSCRIBER_FAILURE";
export const RESET_WIRELESS_SUBSCRIBER = "RESET_WIRELESS_SUBSCRIBER";
interface WirelessSubscriberBegin {
  type: typeof WIRELESS_SUBSCRIBER_BEGIN;
}

interface WirelessSubscriberSuccess {
  type: typeof WIRELESS_SUBSCRIBER_SUCCESS;
  payload: WirelessSubscriber;
}

interface WirelessSubscriberFailure {
  type: typeof WIRELESS_SUBSCRIBER_FAILURE;
  payload: Error;
}
interface WirelessSubscriberReset {
  type: typeof RESET_WIRELESS_SUBSCRIBER;
}

export type WirelessSubscriberActionTypes =
  | WirelessSubscriberBegin
  | WirelessSubscriberSuccess
  | WirelessSubscriberFailure
  | WirelessSubscriberReset;
