import { Badge } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import ViewNotification from "./ViewNotification";
import CreateNotification from "./CreateNotification";
import { BsThreeDotsVertical } from "react-icons/bs";
import ExceptionBox from "../reusable/ExceptionBox";
import { Menu, MenuItem } from "@material-ui/core";
import ConfirmPopUp from "../reusable/ConfirmPopUp";

//Icons Imports
import { FaRegBell } from "react-icons/fa";
import LoaderGif from "../../resources/images/loader.webp";
//src\sqv-web\src\resources\images\loader.webp
import { BsFillCircleFill } from "react-icons/bs";

//CSS Imports
import "../../design-tokens/header/NotificationBox.scss";
import AppLabels from "../../constants/App_Labels";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import NotificationClient from "../../network/httpclient/notification/NotificationClient";
import ReloadData from "../reusable/ReloadData";
import { formatDistance } from "date-fns";
import { GetTechInfo } from "../../get-api/GetResponse";

import { Height } from "@material-ui/icons";

const NotificationBox = (props: any) => {
  //const { setShowRSBar } = props;
  const profileInfo = GetTechInfo()?.data;
  const { setShowRSBar, openNoti, closeNoti } = props;
  const { notiList, notiListLoading, notiCRUDStatus, notiCRUDLoading } =
    useSelector(
      (state: any) => ({
        notiList: state.NotificationList.data,
        notiListLoading: state.NotificationList.isLoading,
        notiCRUDLoading: state.NotiCRUDInfo.isLoading,
        notiCRUDStatus: state.NotiCRUDInfo.status,
      }),
      shallowEqual
    );

  const dispatch = useDispatch();
  const [activeNoti, setActiveNoti] = useState<any>();
  let activeNotiList =
    notiList !== undefined && notiList?.length > 0
      ? notiList?.filter((n: any) => n?.isActive && !n?.isRead)
      : [];
  const [isAdmin, setIsAdmin] = useState(false);
  const labels = AppLabels();
  const [showConfirm, setShowConfirm] = useState(false);
  const [requestType, setRequestType] = useState("default");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notiDisplay, setNotiDisplay] = useState(false);
  const [showNoti, setShowNoti] = useState(false);
  const [createNoti, setCreateNoti] = useState(false);
  const [updateNotiFlag, setUpdateNotiFlag] = useState(false);
  const wrapperRef: any = useRef([]);
  const [ntf, setNtf] = useState<any>(null);
  const [unreadNoti, setUnreadNoti] = useState(0);
  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    n: any
  ) => {
    setAnchorEl(event.currentTarget);
    setActiveNoti(n);
  };
  const handleIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setNotiDisplay(true);
  };
  const handleNotificationClick = (noti: any) => {
    setNtf(noti);
    setNotiDisplay(false);
    setShowNoti(true);
    dispatch(NotificationClient.readNoti(noti?.id));
    //refreshData()
  };
  const handleCreateLink = () => {
    setNotiDisplay(false);
    setCreateNoti(true);
  };
  const handleClickOutside = (event: MouseEvent) => {
    const { current: wrap } = wrapperRef;
    if (wrap && wrap?.length !== 0 && !wrap?.contains(event.target)) {
      handleMenuClose();
      setNotiDisplay(false);
    }
  };
  const handleMenuClose = () => setAnchorEl(null);
  const handleConfirmClose = () => setShowConfirm(false);
  const [aOrigin, setaOrigin] = useState({
    vertical: 15,
    horizontal: 10,
  });

  const handleActionClick = (actionType: string) => {
    handleMenuClose();
    setNotiDisplay(false);
    setRequestType(actionType);
    setShowConfirm(true);
  };

  const handleConfirm = () => {
    if (requestType === "edit") {
      setShowConfirm(false);
      setUpdateNotiFlag(true);
      setCreateNoti(true);
    } else if (requestType === "delete") {
      setShowConfirm(false);
      dispatch(
        NotificationClient.deleteNoti(activeNoti?.id, { isDelete: true })
      );
    }
  };
  useEffect(() => {
    if (openNoti) {
      setNotiDisplay(true);
      closeNoti();
    }
  }, [openNoti]);
  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    dispatch(NotificationClient.getAllNoti());
  }, []);
  useEffect(() => {
    if (!notiCRUDLoading && notiCRUDStatus === "OK") {
      setShowRSBar(true);
      refreshData();
    } else if (!notiCRUDLoading && notiCRUDStatus === "ERROR") {
      setShowRSBar(true);
    }
  }, [notiCRUDLoading]);
  const refreshData = () => {
    if (isAdmin) {
      dispatch(NotificationClient.getAllAdminNoti());
    } else {
      dispatch(NotificationClient.getAllNoti());
    }
  };
  useEffect(() => {
    if (
      profileInfo !== undefined &&
      profileInfo?.hasOwnProperty("roles") &&
      profileInfo?.roles?.indexOf("admin") > -1
    ) {
      setIsAdmin(true);
      dispatch(NotificationClient.getAllAdminNoti());
    }
  }, [profileInfo]);

  const closeNotiDetail = () => {
    //refreshData();
    setShowNoti(false);
  };

  return (
    <div ref={wrapperRef}>
      <button className="headerIcon sBtn" onClick={handleIconClick}>
        <Badge
          badgeContent={activeNotiList?.length > 0 ? activeNotiList?.length : 0}
        >
          <FaRegBell color="inherit" />
        </Badge>
      </button>
      {notiDisplay && (
        <div className="notiContainer">
          <div className="notiContHead">
            <div>
              <ReloadData refreshData={refreshData} />
              <span>NOTIFICATIONS</span>
            </div>
            {isAdmin ? (
              <a
                className="createNewNotiLink"
                onClick={() => handleCreateLink()}
              >
                CREATE NEW +
              </a>
            ) : (
              <></>
            )}
          </div>
          <hr style={{ marginTop: "10px" }} />
          <div className="searchOptionCont">
            {!notiListLoading ? (
              <>
                {notiList !== undefined && notiList?.length !== 0 ? (
                  notiList?.map((n: any, i: number) => {
                    return (
                      <div key={i} tabIndex={0} className="option">
                        <div className={isAdmin ? "ntfInfoAdmin" : "ntfInfo"}>
                          <section onClick={() => handleNotificationClick(n)}>
                            <h6
                              className={
                                n.isRead ? "ntfListTitleRead" : "ntfListTitle"
                              }
                            >
                              {n?.title?.length > 27
                                ? n?.title?.substring(0, 25) + "..."
                                : n?.title}{" "}
                              {/*n.isRead ? "" : <BsFillCircleFill color="#FF0000"  style={{ height: "9px", marginBottom: "5px" }}/>*/}
                              <span className="ntfTS">
                                {formatDistance(
                                  new Date(n?.startDate),
                                  new Date(),
                                  { addSuffix: true }
                                )}
                              </span>
                            </h6>

                            <p
                              className="ntfListMessage"
                              dangerouslySetInnerHTML={{
                                __html: n?.description,
                              }}
                            />
                          </section>
                          {isAdmin ? (
                            <span
                              className="ntfMenu"
                              onClick={(e: any) => handleMenuClick(e, n)}
                            >
                              <BsThreeDotsVertical color="#808284" />
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="diagErrorCont">
                    <ExceptionBox
                      headerText="No Notifications to display."
                      bodyText={`All release notes will reflect here.`}
                      primaryBtn={false}
                      secBtn={false}
                    />
                  </div>
                )}
              </>
            ) : (
              <div className="diagAPICont">
                <img className="loaderImg" src={LoaderGif} />
              </div>
            )}
          </div>
        </div>
      )}
      {ntf !== null ? (
        <ViewNotification
          showVal={showNoti}
          ntfItems={ntf.items}
          bodyHeadText={ntf.title}
          bodyText={ntf.description}
          handleClose={closeNotiDetail}
        />
      ) : (
        <></>
      )}
      <CreateNotification
        showVal={createNoti}
        headerText={
          updateNotiFlag
            ? "Edit Notification".toUpperCase()
            : "Create Notification".toUpperCase()
        }
        handleClose={() => {
          setCreateNoti(false);
          setUpdateNotiFlag(false);
        }}
        updateFlag={updateNotiFlag}
        activeNoti={activeNoti}
      />
      <Menu
        className="deviceActionMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={aOrigin}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          className="runAction"
          onClick={(e) => handleActionClick("edit")}
        >
          Edit
        </MenuItem>
        <MenuItem
          className="runAction"
          onClick={(e) => handleActionClick("delete")}
        >
          Delete
        </MenuItem>
      </Menu>
      <ConfirmPopUp
        showVal={showConfirm}
        headerText={labels.CAUTION.toUpperCase()}
        bodyHeadText={`${labels.YOU_ARE_TRYING_TO} ${requestType} this notification.`}
        bodyText={labels.ARE_YOU_SURE_TO_PROCEED}
        cancelText={labels.CANCEL}
        confirmText={labels.PROCEED}
        handleConfirm={handleConfirm}
        handleClose={handleConfirmClose}
      />
    </div>
  );
};

export default NotificationBox;
