import { Dropdown, DropdownButton, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Card } from "@material-ui/core";
import ChipGroup from "../../reusable/ChipGroup";

import ErrorBox from "../../reusable/ErrorBox";
import AppLabels from "../../../constants/App_Labels";
import {
  rpdRegistrationReset,
  setRPDClamshellId,
  setRPDMacAddress,
  setRPDNumber,
} from "../../../store/ducksPattern/rpd/rpdRegistration";
import {
  isValidNodeId,
  formatNodeId,
  formatMacAddress,
  formatRPDMacAddress,
} from "../../../components/reusable/Util";
import RPDClient from "../../../network/httpclient/rpd/RPDClient";
import { TAF_ORDER_CONST } from "../../../constants/Global";

const RPDRegistrationTop = (props: any) => {
  const { clamshellID, macAddressID, rpdNumber, isLoading } = props;
  const labels = AppLabels();
  const [clamshellId, setClamshellId] = useState(clamshellID);
  const [macAddress, setMacAddress] = useState(formatMacAddress(macAddressID));
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("This is Error Message");
  const [activeCHipIndex, setActiveCHipIndex] = useState(rpdNumber - 1);
  const searchTypeChips = [
    { title: labels.slotOne, description: labels.rpdslotOne },
    { title: labels.slotTwo, description: labels.rpdslotTwo },
  ];
  const deviceList = ["DA2000", "DA2200"];
  const [device, setDevice] = useState("DA2000");
  const dispatch = useDispatch();

  const onClamshellIdSubmit = (e: any) => {
    dispatch(rpdRegistrationReset());
    let sv = formatNodeId(e.target.value);
    if (sv?.length <= 6) setClamshellId(sv);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changeActiveIndex = (data: number) => {
    setActiveCHipIndex(data);
    dispatch(rpdRegistrationReset());
  };

  const createInputRegisterObject = (
    clamshell: string,
    slot: string,
    macAddress: string,
    device: string
  ): any => {
    let inputObject = {
      values: {
        ClamShell: clamshell,
        RPD_Number: slot,
        RPD_MACAddress: macAddress,
        DeviceType: device,
      },
    };
    return inputObject;
  };

  const handleSubmitNew = () => {
    if (isValidNodeId(clamshellId)) {
      dispatch(setRPDClamshellId(clamshellId));
      if (macAddress?.length === 12) {
        let stringMacAdd = formatRPDMacAddress(macAddress);
        dispatch(setRPDMacAddress(stringMacAdd));
        var rpdnumber = activeCHipIndex + 1;
        dispatch(setRPDNumber(rpdnumber));
        dispatch(
          RPDClient.postRegister(
            TAF_ORDER_CONST.rpdRegistrationBotName,
            TAF_ORDER_CONST.rpdRegistrationSuiteName,
            createInputRegisterObject(
              clamshellId,
              rpdnumber + "",
              stringMacAdd,
              device
            )
          )
        );
      } else {
        setOpen(true);
        setError(labels.macIdEmptyError);
      }
    } else {
      setOpen(true);
      setError(labels.clamshellEmptyError);
    }
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSubmitNew();
    }
  };

  const onMacAddressSubmit = (e: any) => {
    dispatch(rpdRegistrationReset());
    let sv = formatMacAddress(e.target.value);
    if (sv?.length <= 12) setMacAddress(sv);
  };

  const DropdownMenu = ({ label, data, value, onSelect }: any) => (
    <div className="deviceTypeCont">
      <Form.Label className="rpdFormLabel">{label}</Form.Label>
      <DropdownButton
        title={value}
        onSelect={(v: any) => onSelect(v)}
        size="sm"
        className="deviceTypeDropd"
        id="deviceTypeDd"
      >
        {data.map((e: any, i: number) => (
          <Dropdown.Item key={i} eventKey={e}>
            {e}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );

  return (
    <>
      <Card className="filterRootContainer">
        <div className="filterContainer">
          <div className="filterRow">
            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.clamshellIDTxt}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="rpdInputField"
                  onChange={onClamshellIdSubmit}
                  className="rpdInputField"
                  id="rpdInputField"
                  value={clamshellId}
                  onKeyPress={(e: any) => handleKeyPress(e)}
                  placeholder={labels.typeClamshellIDTxt}
                />
              </Form.Group>
            </div>

            <div className="inputChipContainer">
              <div className="boxTitleContainer">
                <Form.Group>
                  <Form.Label className="rpdFormLabel">
                    {labels.rpdSlot}
                  </Form.Label>
                  <div className="titleChipCard">
                    <ChipGroup
                      chips={searchTypeChips}
                      activeChipIndex={activeCHipIndex}
                      onIndexChange={changeActiveIndex}
                    />
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.MacAddress}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="rpdInputField"
                  onChange={onMacAddressSubmit}
                  className="rpdInputField"
                  id="rpdInputField"
                  value={macAddress}
                  onKeyPress={(e: any) => handleKeyPress(e)}
                  placeholder={labels.typeMacAddress}
                />
              </Form.Group>
            </div>
            <div className="inputContainer">
              <Form.Group>
                <DropdownMenu
                  label={labels.deviceType}
                  data={deviceList}
                  value={device}
                  onSelect={setDevice}
                />
              </Form.Group>
            </div>
            <div className="topSearchBtn">
              <button
                disabled={isLoading}
                className="applyFilersBtn"
                onClick={() => handleSubmitNew()}
              >
                {labels.SUBMIT}
              </button>
            </div>
          </div>
        </div>
      </Card>
      <ErrorBox
        open={open}
        error={error}
        setOpen={setOpen}
        handleClose={handleClose}
      />
    </>
  );
};
export default RPDRegistrationTop;
