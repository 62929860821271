import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../middleware/apiActions";
import {
  ADDRESS_URI,
  NETWORK_SERVICE_URL,
} from "../../middleware/apiEndpoints";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "addressSearch",
  initialState: {
    isLoading: false,
    searchedAddress: "",
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    getAddressSearchBegin: (as) => {
      as.isLoading = true;
      as.status = "";
    },
    getAddressSearchSuccess: (as, { payload }) => {
      as.isLoading = false;
      as.data = payload;
      as.status = storeConst.OK;
    },
    getAddressSearchFailure: (as, { payload }) => {
      as.isLoading = false;
      as.error = payload;
      as.data = undefined;
      as.status = storeConst.ERROR;
    },
    setSearchedAddress: (as, { payload }) => {
      as.searchedAddress = payload;
    },
  },
});

const {
  getAddressSearchBegin,
  getAddressSearchSuccess,
  getAddressSearchFailure,
  setSearchedAddress,
} = slice.actions;
export default slice.reducer;

interface AddressSearchType {
  source?: "oal" | "adm";
  searchedAddress: string;
  queryParams: {
    aptNum?: string;
    streetNum: string;
    streetName: string;
    streetType?: string;
    cityName?: string;
    province?: string;
    postalCode?: string;
  };
}

export const getAddressSearch =
  ({ source = "oal", searchedAddress, queryParams }: AddressSearchType) =>
  (dispatch: any) => {
    dispatch(setSearchedAddress(searchedAddress));
    dispatch(
      apiCallBegan({
        baseURL: NETWORK_SERVICE_URL,
        url: source + ADDRESS_URI,
        params: queryParams,
        onStart: getAddressSearchBegin.type,
        onSuccess: getAddressSearchSuccess.type,
        onError: getAddressSearchFailure.type,
      })
    );
  };
