import { useState } from "react";
import { Button } from "react-bootstrap";
import { FaRegStopCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { FiFilter, FiEye, FiEyeOff } from "react-icons/fi";
import { Badge } from "@material-ui/core";

import LoaderGif from "../../../../resources/images/loader.webp";
import TopologyLabels from "../../../constants/topologyLabels";
import DiagData from "../../../data/DiagData";
import {
  getTeleModemsList,
  setAdvancedFilters,
  setAllDiagLoading,
  setDiagType,
  setIsCWEnabled,
  stopDiagExecution,
} from "../../../store/slices/mapItems/diagnostics/telemetry";
import {
  GetTelemetryProps,
  GetTopoGeneralProps,
} from "../../../util/reduxFunctions/getTopologyState";
import SelectionChip from "../../reusable/SelectionChip";
import CustomPopOver from "../../../../components/reusable/CustomPopOver";
import DiagAdvanceFilter from "./advancefilter/DiagAdvanceFilter";
import { addAbortKey } from "../../../store/middleware/apiClient";
import { TELE_ABORT_KEY } from "../../../store/middleware/abortKeys";
import appConst from "../../../constants/appConst";

const DiagFilters = () => {
  const labels = TopologyLabels();
  const dispatch = useDispatch();
  const chipsInitialItems = DiagData();
  const { diagType, apiCallCounter, advancedFilters } = GetTelemetryProps();
  const { activeNodeId } = GetTopoGeneralProps();
  const isFiltersApplied = advancedFilters?.isFiltersApplied || false;

  const [advFilterBox, setAdvFilterBox] = useState(false);
  const stopTelemetry = () => {
    if (apiCallCounter > 0) {
      dispatch(stopDiagExecution());
    }
  };

  const onChipsPress = (checked: boolean, index: number) => {
    const selectedDiagType = chipsInitialItems[index];
    if (diagType !== selectedDiagType?.key) {
      dispatch(setDiagType(selectedDiagType?.key));
      if (selectedDiagType?.key === appConst.all) {
        dispatch(setIsCWEnabled(true));
      } else if (selectedDiagType?.key === appConst.all_no_cw) {
        dispatch(setIsCWEnabled(false));
      }
    }
  };

  const getDiag = () => {
    if (apiCallCounter <= 0) {
      dispatch(
        getTeleModemsList({ value: activeNodeId, diagnoseDevices: true })
      );

      addAbortKey(TELE_ABORT_KEY);
      dispatch(setAllDiagLoading(true));
    }
  };

  const handleFilterOnOff = () => {
    if (advancedFilters) {
      dispatch(
        setAdvancedFilters({
          ...advancedFilters,
          isFiltersApplied: !isFiltersApplied,
        })
      );
    }
  };

  return (
    <div className="filterTabCont">
      <div className="topoDiagActionCont">
        <div className="selectionChipsCont">
          {chipsInitialItems?.map((e: any, i: number) => (
            <SelectionChip
              key={e.key}
              style={{
                marginRight: "10px",
                minWidth: "auto",
                padding: "3px 10px 3px 5px",
                marginBottom: 10,
              }}
              label={e.label}
              iconRequired={false}
              checked={diagType === e.key ? true : false}
              setChecked={(checked) => onChipsPress(checked, i)}
            />
          ))}
          <Button
            onClick={getDiag}
            className="stepperSubmitBtn"
            style={{ height: 30, borderRadius: 25, fontSize: 12 }}
          >
            {labels.GetDiagnostics.toUpperCase()}
          </Button>
        </div>
        <div className="diagOptionsCont" style={{ marginTop: 5 }}>
          <Badge
            invisible={!advancedFilters}
            badgeContent={
              <CustomPopOver
                position="top"
                popOverData={"Show/Hide Filtered Data"}
                index="popOver"
                originComp={
                  <div onClick={handleFilterOnOff}>
                    {isFiltersApplied ? (
                      <FiEye style={{ width: 15, height: 15 }} />
                    ) : (
                      <FiEyeOff style={{ width: 15, height: 15 }} />
                    )}
                  </div>
                }
              />
            }
          >
            <CustomPopOver
              position="bottom"
              popOverData={labels.advanceFilter}
              index="popOver"
              originComp={
                <section
                  className={isFiltersApplied ? "filterActive" : ""}
                  onClick={() => setAdvFilterBox(true)}
                >
                  <FiFilter />
                </section>
              }
            />
          </Badge>
        </div>
      </div>
      {apiCallCounter > 0 && (
        <Button className="stopTeleBtn" onClick={() => stopTelemetry()}>
          {labels.stop?.toUpperCase()}
          <FaRegStopCircle />
          <img className="teleBtnLoader" src={LoaderGif} />
        </Button>
      )}
      <DiagAdvanceFilter
        showAdvFilter={advFilterBox}
        handleClose={() => setAdvFilterBox(false)}
      />
    </div>
  );
};

export default DiagFilters;
