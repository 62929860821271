import ShieldIcon from "../../../resources/images/icons/CertiSuccess.svg";
import { shallowEqual, useSelector } from "react-redux";
import { getFormatedHomeAddress, isNull } from "../../reusable/Util";
import { useState } from "react";
import FullScreenPopUp from "../../reusable/FullScreenPopUp";
import ViewFullCertificate from "../../../screens/certification-page/ViewFullCertificate";

const CertiSubmitStep = (props: any) => {
  const { goListing, disableStepClicks } = props;
  const { certiData } = useSelector(
    (state: any) => ({
      certiData: state.AccountState.Certification.ActiveCertiInfo.certiInfo,
    }),
    shallowEqual
  );
  disableStepClicks();
  const [openCerti, setOpenCerti] = useState(false);
  return (
    <div className="certiStepCont">
      <div className="hsiCertiStep">
        <h4>Perfect! Certifications saved successfully.</h4>
        <p>
          Thank you for completing the steps in order. Please find below, the
          certification ID. You can find the submitted certifications in the
          list.
        </p>
        <img src={ShieldIcon} alt="successIMG" />
        <div className="certiDetails">
          <p>
            <span>Customer Account ID</span>
            <span>{isNull(certiData?.data?.accountNumber)}</span>
          </p>
          <p>
            <span>Work Order ID</span>
            <span>{isNull(certiData?.data?.workOrderId)}</span>
          </p>
          <p>
            <span>Service Address: </span>
            <span>{getFormatedHomeAddress(certiData?.data?.address)}</span>
          </p>
          <i onClick={() => setOpenCerti(true)}>View Certificate</i>
        </div>
      </div>

      <div className="certiStepBtnCont">
        <button className="certiSubmitBtn" onClick={() => goListing()}>
          <span>Go to Listing</span>
        </button>
      </div>
      <FullScreenPopUp
        open={openCerti}
        handleClose={() => {
          setOpenCerti(false);
        }}
        childComponent={<ViewFullCertificate certiData={certiData} />}
        pageName="Certification Details"
      />
    </div>
  );
};

export default CertiSubmitStep;
