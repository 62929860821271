import { combineReducers } from "redux";
import mduPolylines from "./slices/mapItems/mdu/mduPolylines";
import mduActives from "./slices/mapItems/mdu/mduActives";
import mduFiberEquipments from "./slices/mapItems/mdu/mduFiberEquipments";
import mduPassives from "./slices/mapItems/mdu/mduPassives";
import mduFiberCable from "./slices/mapItems/mdu/mduFiberCable";
import mduRFCable from "./slices/mapItems/mdu/mduRFCable";
import mduPowerSupply from "./slices/mapItems/mdu/mduPowerSupply";
import mduTap from "./slices/mapItems/mdu/mduTap";
import mduAnnotationBlock from "./slices/mapItems/mdu/mduAnnotationBlock";
import mduCADText from "./slices/mapItems/mdu/mduCADText";
import mduSupportStructure from "./slices/mapItems/mdu/mduSupportStructure";
import mduRaster from "./slices/mapItems/mdu/mduRaster";
import mduRasterImage from "./slices/mapItems/mdu/mduRasterImage";
import mduCADAttribute from "./slices/mapItems/mdu/mduCADAttribute";
import mduCADCircle from "./slices/mapItems/mdu/mduCADCircle";
import mduCADHatch from "./slices/mapItems/mdu/mduCADHatch";
import mduCADSymbol from "./slices/mapItems/mdu/mduCADSymbol";
import mduSiteComponents from "./slices/mapItems/mdu/mduSiteComponents";
import mduNodeSiteInfo from "./slices/mapItems/mdu/mduNodeSiteInfo";

export default combineReducers({
  mduPolylines,
  mduActives,
  mduFiberEquipments,
  mduPassives,
  mduFiberCable,
  mduRFCable,
  mduPowerSupply,
  mduTap,
  mduAnnotationBlock,
  mduCADText,
  mduSupportStructure,
  mduRaster,
  mduRasterImage,
  mduCADAttribute,
  mduCADCircle,
  mduCADHatch,
  mduCADSymbol,
  mduSiteComponents,
  mduNodeSiteInfo,
});
