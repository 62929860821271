import { IHPQrlnData } from "../../../../models/account/diagnostics/ihp/IHPQrlnData";

export const IHP_QRLN_DATA_BEGIN = "IHP_QRLN_DATA_BEGIN";
export const IHP_QRLN_DATA_SUCCESS = "IHP_QRLN_DATA_SUCCESS";
export const IHP_QRLN_DATA_FAILURE = "IHP_QRLN_DATA_FAILURE";

interface IhpQrlnDataBegin {
  type: typeof IHP_QRLN_DATA_BEGIN;
}

interface IhpQrlnDataSuccess {
  type: typeof IHP_QRLN_DATA_SUCCESS;
  payload: IHPQrlnData;
}

interface IhpQrlnDataFailure {
  type: typeof IHP_QRLN_DATA_FAILURE;
  payload: Error;
}

export type IhpQrlnDataActionTypes =
  | IhpQrlnDataBegin
  | IhpQrlnDataSuccess
  | IhpQrlnDataFailure;
