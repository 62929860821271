import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { InfoWindow, InfoWindowProps } from "@react-google-maps/api";

import "../../styles/map/Markers.scss";
import {
  setHighlightedPolygon,
  setSelectedMapElement,
} from "../../store/slices/mapItems/mapDetails";
import FiberCables from "./elements/FiberCables";
import FiberSpliceCases from "./elements/passiveRfEquipment/FiberSpliceCases";
import NodeSiteDetail from "./elements/NodeSiteDetail";
import NodeTaps from "./elements/passiveRfEquipment/NodeTaps";
import Polygons from "./elements/Polygons";
import RFCables from "./elements/RFCables";
import Splitter from "./elements/passiveRfEquipment/Splitter";
import PowerBlock from "./elements/passiveRfEquipment/PowerBlock";
import Terminator from "./elements/passiveRfEquipment/Terminator";
import PowerSupply from "./elements/PowerSupply";
import MDU from "./elements/mdu/MDU";
import RFDrops from "./elements/RFDrops";
import RFSplice from "./elements/passiveRfEquipment/RFSplice";
import AddressMarkers from "./elements/AddressMarkers";
import ActiveRFEquipments from "./elements/ActiveRFEquipments";
import NikaMarkers from "./elements/nika/NikaMarkers";
import AnnotationBlocks from "./elements/annotationBlock/AnnotationBlocks";
import ActiveMapMarker from "./ActiveMapMarker";
import appConst from "../../constants/appConst";
import { useElementToggelingAndStyle } from "../../util/customHooks/topologyHooks";
import {
  GetMapDetailsProps,
  GetMapDrawerProps,
  GetNodeSiteDetailsProps,
  GetTelemetryProps,
} from "../../util/reduxFunctions/getTopologyState";
import { useElementEventHandler } from "../../util/customHooks/mapEventHandlers";
import { useSymbolsRequestHandler } from "../../util/customHooks/symbolHooks";
import CmMarkers from "./elements/CmMarkers";
import CpatMarkers from "./elements/CpatMarkers";
import SamKeyAddresesMarkers from "./elements/SamKeyAddresesMarkers";
import {
  getClamShellDetail,
  setActiveNodeIndex,
  setActiveNodeSiteId,
  setCenterAndCallApis,
} from "../../store/slices/mapItems/node/nodeSiteDetail";
import SearchedAddressPin from "./SearchedAddressPin";
import StorageAndRiser from "./elements/fiberEquipment/StorageAndRiser";
import AddressConnectionPoint from "./elements/fiberEquipment/AddressConnectionPoint";
import PowerInserter from "./elements/passiveRfEquipment/PowerInserter";
import { getAllFeaturesData } from "../../util/utilFuncs";
import PoleStrand from "./elements/supportStructure/PoleStrand";
import DetailMark from "./elements/supportStructure/DetailMark";
import CableDip from "./elements/supportStructure/CableDip";
import Pole from "./elements/supportStructure/Pole";
import ClickOrdersMarker from "./elements/click/ClickOrdersMarker";
import SearchedElement from "./SearchedElement";
import MemoMarkers from "./memo/MemoMarkers";
import OLTMarker from "./elements/OLTMarker";
import { setDrawerView } from "../../store/slices/mapDrawer";
import { DrawerViewType } from "../../store/reduxTypes";
import PowertraceElements from "./elements/powertrace/PowertraceElements";
import OpticalTap from "./elements/fiber/OpticalTap";
import LCPMarker from "./elements/fiber/LCPMarker";
import WorkOrdersMarkers from "./elements/workOrders/WorkOrdersMarkers";
import RfDownstreamTraceElements from "./elements/trace/rf/RfDownstreamTraceElements";
import RfUpstreamTraceElements from "./elements/trace/rf/RfUpstreamTraceElements";
import VHUBMarker from "./elements/VHUBMarker";
import LocatorElements from "./elements/LocatorElements";
import FiberUpstreamTraceElements from "./elements/trace/fiber/FiberUpstreamTraceElements";
import IspSites from "./elements/fiber/IspSites";

interface Props {
  gMap?: google.maps.Map;
  pngImages: any;
  zoomLevel?: any;
  handleClick?: any;
  handleRightClick?: any;
  showCharts?: any;
  setShowCharts?: any;
}

export type HoverOverInter = {
  position: {
    lat: number;
    lng: number;
  };
  compToRender: JSX.Element;
  options?: InfoWindowProps["options"];
};

const MapItems = ({
  gMap,
  pngImages,
  zoomLevel,
  handleClick,
  handleRightClick,
}: Props) => {
  const dispatch = useDispatch();
  const { mapFilters } = GetMapDetailsProps();
  const { activeNodeSite, activeNode, allNodeData } = GetNodeSiteDetailsProps();
  const { diagStartTime, isDevicesVisible } = GetTelemetryProps();
  const { drawerView } = GetMapDrawerProps();
  const [hoverOverProps, setHoverOverProps] = useState<HoverOverInter | null>(
    null
  );

  // get symbols after active node elements data sucess api call
  useSymbolsRequestHandler(
    activeNodeSite,
    activeNode,
    allNodeData,
    pngImages,
    dispatch
  );
  //loading all element's styles & applying events to the all map elements
  useElementEventHandler(gMap, dispatch);
  //toggeling element's visibility based on the filters
  useElementToggelingAndStyle(gMap, mapFilters, zoomLevel, pngImages);

  const handleElementClick = (data: any, type: string) => {
    if (drawerView != "standard")
      dispatch(setDrawerView("standard" as DrawerViewType));
    const geoData =
      type === appConst.node
        ? {
            lat: data?.properties?.data?.siteCoords?.coordinates?.[1],
            lng: data?.properties?.data?.siteCoords?.coordinates?.[0],
          }
        : type !== appConst.polygon
        ? {
            lat: data?.geometry?.coordinates?.[1],
            lng: data?.geometry?.coordinates?.[0],
          }
        : null;
    const activeElement = {
      geometry: geoData,
      properties: {
        id: data?.properties?.featureId
          ? data?.properties?.featureId
          : data?.featureId,
        type: data?.properties?.type,
        data: data?.properties?.data,
        rootNodeSiteId:
          type === appConst.node
            ? data?.properties?.data?.nodeNum
            : data?.properties?.rootNodeSiteId,
      },
    };
    dispatch(setHighlightedPolygon(data));
    dispatch(setSelectedMapElement(activeElement));

    if (type === appConst.polygon) {
      // search for node on polygon double click
      dispatch(getClamShellDetail(data));
    }
    if (type === appConst.node) {
      let nodeID = data?.properties?.data?.nodeNum
        ? data?.properties?.data?.nodeNum
        : data?.properties?.data?.designation;
      const nodeIndex = allNodeData?.findIndex(
        (node: any) => node.nodeSiteId === nodeID
      );
      const isPrimaryNode = allNodeData?.[nodeIndex]?.isPrimaryNode
        ? true
        : false;

      dispatch(setActiveNodeIndex(nodeIndex));
      dispatch(setActiveNodeSiteId(nodeID));
      if (isPrimaryNode) {
        dispatch(setCenterAndCallApis());
      }
    }
  };

  return (
    <>
      <Polygons
        handlePolyDBClick={handleElementClick}
        handleClick={handleClick}
        handleRightClick={handleRightClick}
      />
      <AddressMarkers
        gMap={gMap}
        zoomLevel={zoomLevel}
        featuresAll={getAllFeaturesData(appConst.rfdropaddress, allNodeData)}
      />
      //Topology Cables
      <FiberCables gMap={gMap} />
      <RFCables gMap={gMap} />
      <RFDrops gMap={gMap} />
      <PoleStrand gMap={gMap} />
      <PowertraceElements allNodeData={allNodeData} pngImages={pngImages} />
      <RfDownstreamTraceElements allNodeData={allNodeData} />
      <RfUpstreamTraceElements allNodeData={allNodeData} />
      <FiberUpstreamTraceElements
        allNodeData={allNodeData}
        pngImages={pngImages}
      />
      //Topology Equipment
      <NodeSiteDetail
        handleNodeSiteClick={handleElementClick}
        zoomLevel={zoomLevel}
        pngImages={pngImages?.[appConst.node]}
        isVisible={mapFilters === undefined || mapFilters?.[appConst.polygon]}
      />
      <NodeTaps
        gMap={gMap}
        pngImages={pngImages?.[appConst.taps]}
        isVisible={mapFilters?.[appConst.taps]}
        zoomLevel={zoomLevel}
        featuresAll={getAllFeaturesData(appConst.taps, allNodeData)}
      />
      <FiberSpliceCases
        gMap={gMap}
        pngImages={pngImages?.[appConst.spliceCases]}
        isVisible={mapFilters?.[appConst.spliceCases]}
        zoomLevel={zoomLevel}
        featuresAll={getAllFeaturesData(appConst.spliceCases, allNodeData)}
      />
      <StorageAndRiser
        gMap={gMap}
        pngImages={pngImages?.[appConst.fiberStorageAndRiser]}
        isVisible={mapFilters?.[appConst.fiberStorageAndRiser]}
        zoomLevel={zoomLevel}
        featuresAll={getAllFeaturesData(
          appConst.fiberStorageAndRiser,
          allNodeData
        )}
      />
      <AddressConnectionPoint
        gMap={gMap}
        isVisible={mapFilters?.[appConst.fiberAddrConnectionPoint]}
        zoomLevel={zoomLevel}
        featuresAll={getAllFeaturesData(
          appConst.fiberAddrConnectionPoint,
          allNodeData
        )}
      />
      <PowerInserter
        gMap={gMap}
        pngImages={pngImages?.[appConst.powerInserter]}
        isVisible={mapFilters?.[appConst.powerInserter]}
        zoomLevel={zoomLevel}
        featuresAll={getAllFeaturesData(appConst.powerInserter, allNodeData)}
      />
      <RFSplice
        gMap={gMap}
        pngImages={pngImages?.[appConst.rfSplice]}
        isVisible={mapFilters?.[appConst.rfSplice]}
        zoomLevel={zoomLevel}
        featuresAll={getAllFeaturesData(appConst.rfSplice, allNodeData)}
      />
      <Splitter
        gMap={gMap}
        pngImages={pngImages?.[appConst.splitter]}
        isVisible={mapFilters?.[appConst.splitter]}
        zoomLevel={zoomLevel}
        featuresAll={getAllFeaturesData(appConst.splitter, allNodeData)}
      />
      <PowerBlock
        gMap={gMap}
        pngImages={pngImages?.[appConst.powerBlock]}
        isVisible={mapFilters?.[appConst.powerBlock]}
        zoomLevel={zoomLevel}
        featuresAll={getAllFeaturesData(appConst.powerBlock, allNodeData)}
      />
      <Terminator
        gMap={gMap}
        pngImages={pngImages?.[appConst.terminator]}
        isVisible={mapFilters?.[appConst.terminator]}
        zoomLevel={zoomLevel}
        featuresAll={getAllFeaturesData(appConst.terminator, allNodeData)}
      />
      <ActiveRFEquipments
        gMap={gMap}
        pngImages={pngImages?.[appConst.activeEquipment]}
        isVisible={mapFilters?.[appConst.activeEquipment]}
        zoomLevel={zoomLevel}
        featuresAll={getAllFeaturesData(appConst.activeEquipment, allNodeData)}
      />
      <PowerSupply
        gMap={gMap}
        pngImages={pngImages?.[appConst.powerSupply]}
        isVisible={mapFilters?.[appConst.powerSupply]}
        zoomLevel={zoomLevel}
        featuresAll={getAllFeaturesData(appConst.powerSupply, allNodeData)}
      />
      <DetailMark
        gMap={gMap}
        pngImages={pngImages?.[appConst.detailMark]}
        isVisible={mapFilters?.[appConst.supportStructure]}
        zoomLevel={zoomLevel}
        featuresAll={getAllFeaturesData(appConst.detailMark, allNodeData)}
      />
      <CableDip
        gMap={gMap}
        pngImages={pngImages?.[appConst.cableDip]}
        isVisible={mapFilters?.[appConst.supportStructure]}
        zoomLevel={zoomLevel}
        featuresAll={getAllFeaturesData(appConst.cableDip, allNodeData)}
      />
      <Pole
        gMap={gMap}
        pngImages={pngImages?.[appConst.pole]}
        isVisible={mapFilters?.[appConst.supportStructure]}
        zoomLevel={zoomLevel}
        featuresAll={getAllFeaturesData(appConst.pole, allNodeData)}
      />
      <OpticalTap
        gMap={gMap}
        pngImages={pngImages?.[appConst.opticalTap]}
        isVisible={mapFilters?.[appConst.opticalTap]}
        zoomLevel={zoomLevel}
        featuresAll={getAllFeaturesData(appConst.opticalTap, allNodeData)}
      />
      <IspSites
        gMap={gMap}
        pngImages={pngImages?.[appConst.ispSite]}
        isVisible={mapFilters?.[appConst.ispSite]}
        zoomLevel={zoomLevel}
        featuresAll={getAllFeaturesData(appConst.ispSite, allNodeData)}
      />
      <MDU
        gMap={gMap}
        zoomLevel={zoomLevel}
        featuresAll={getAllFeaturesData(appConst.mdu, allNodeData)}
        isVisible={mapFilters?.[appConst.mdu]}
      />
      <MemoMarkers
        handleMemoClick={handleElementClick}
        isVisible={mapFilters?.[appConst.memoComment]}
        zoomLevel={zoomLevel}
        featuresAll={getAllFeaturesData(appConst.memoComment, allNodeData)}
        allNodeData={allNodeData}
        pngImages={pngImages}
      />
      <OLTMarker
        pngImages={pngImages}
        zoomLevel={zoomLevel}
        handleElementClick={handleElementClick}
      />
      <LCPMarker
        pngImages={pngImages?.[appConst.LCP]}
        zoomLevel={zoomLevel}
        handleElementClick={handleElementClick}
        isVisible={mapFilters?.[appConst.LCP]}
      />
      <VHUBMarker
        pngImages={pngImages}
        zoomLevel={zoomLevel}
        handleElementClick={handleElementClick}
      />
      //Other Entities
      <NikaMarkers setHoverOverProps={setHoverOverProps} />
      <AnnotationBlocks />
      {isDevicesVisible && diagStartTime && (
        <CmMarkers setHoverOverProps={setHoverOverProps} />
      )}
      <CpatMarkers handleCpatMarkerClick={handleElementClick} />
      <SamKeyAddresesMarkers />
      <ClickOrdersMarker onMarkerPress={handleElementClick} />
      <WorkOrdersMarkers
        setHoverOverProps={setHoverOverProps}
        isVisible={mapFilters?.[appConst.workOrders]}
      />
      {hoverOverProps && (
        <InfoWindow
          position={hoverOverProps?.position}
          options={hoverOverProps?.options}
          zIndex={999999}
        >
          {hoverOverProps?.compToRender}
        </InfoWindow>
      )}
      <ActiveMapMarker />
      <SearchedAddressPin />
      <SearchedElement />
      <LocatorElements
        pngImages={pngImages}
        zoomLevel={zoomLevel}
        handleElementClick={handleElementClick}
      />
    </>
  );
};

export default React.memo(MapItems);
