import { TapListState } from "../../../states/account/neighbourhood/TapListState";
import {
  TAP_LIST_BEGIN,
  TAP_LIST_FAILURE,
  TAP_LIST_SUCCESS,
  TapListActionTypes,
} from "../../../types/account/neighbourhood/TapList";

const initialState: TapListState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function TapListReducer(
  state = initialState,
  action: TapListActionTypes
): TapListState {
  switch (action.type) {
    case TAP_LIST_BEGIN:
      return {
        isLoading: true,
      };
    case TAP_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case TAP_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}
