export function createPJIApiObj(data: any) {
  return {
    riskOption: data?.riskOption,
    initialChecks: reduceObjData(data?.initialChecks),
    comments: reduceObjData(data?.comments),
    hazardsPresent: reduceObjData(data?.inspectionPhysicalWork),
    generalPPE: objToArray(data?.ppe),
    hazardActivities: {
      WorkingFromHeights: objToArray(data?.workingFromHeightData),
      LiveElectricity: objToArray(data?.liveElectricityData),
      ConfinedSpace: objToArray(data?.confinedSpaceData),
      Anchorage: objToArray(data?.anchorage),
    },
  };
}

export function objToArray(obj: any) {
  var arr: String[] = [];
  const selectedData = obj?.filter((item: any) => item.value === true);
  if (selectedData?.length > 0) {
    selectedData?.map((item: any) => {
      arr.push(item.key);
    });
  }
  return arr;
}

export function reduceObjData(obj: any) {
  const jsonObj: { [key: string]: any } = {};
  const actualSelectedData = obj.filter(
    (item: any) => item.value != null && item.value != ""
  );
  if (actualSelectedData?.length > 0) {
    actualSelectedData?.map((item: any) => {
      jsonObj[item.key] = item.value;
    });
  }
  return jsonObj;
}

export function updateObjValuesFromAnotherObj(
  obj: any,
  selectedValuesObj: any
) {
  let data = obj?.map((el: any) =>
    selectedValuesObj?.hasOwnProperty(el.key)
      ? { ...el, value: selectedValuesObj?.[el.key] }
      : el
  );
  return data;
}

export function updateObjValuesFromArray(obj: any, arrObj: any) {
  if (arrObj?.length > 0) {
    let data = obj?.map((el: any) =>
      arrObj?.indexOf(el.key) > -1 ? { ...el, value: true } : el
    );
    return data;
  }
  return obj;
}
