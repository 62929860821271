import { HashRouter } from "react-router-dom";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./azure/authConfig";
//CSS Imports
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";

//components Imports
import HeaderBar from "./components/header/HeaderBar";
import MonthlyFeedbackModal from "./components/feedback/monthly/MonthlyFeedbackModal";
import ResponseSnackBar from "./components/reusable/ResponseSnackBar";
import { NotificationCRUDReset } from "./store/actions/notification/NotiCRUDAction";
import DrawerCont from "./components/drawer-box/DrawerCont";
import TECHClient from "./network/httpclient/technician/TECHClient";
import AppLabels from "./constants/App_Labels";
import { browserName } from "react-device-detect";
import { GetTechInfo } from "./get-api/GetResponse";
import Routes from "./routes/Routes";

const theme = createMuiTheme({
  typography: {
    fontFamily: "Ted Next",
  },
  palette: {
    primary: {
      main: "#da291c",
    },
    secondary: {
      main: "#da291c",
    },
  },
});

function App() {
  const labels = AppLabels();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [showRSBar, setShowRSBar] = useState(false);
  const [drawerToggle, setDrawerToggle] = useState(false);
  const dispatch = useDispatch();
  const { data: techData } = GetTechInfo();
  const { notiCRUDStatus, notiCRUDRequestType } = useSelector(
    (state: any) => ({
      notiCRUDRequestType: state.NotiCRUDInfo.rType,
      notiCRUDStatus: state.NotiCRUDInfo.status,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (!(instance?.getAllAccounts()?.length > 0) && !isAuthenticated) {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  });

  const handleClose = () => {
    setShowRSBar(false);
    dispatch(NotificationCRUDReset());
  };
  useEffect(() => {
    if (techData !== undefined) {
      if (!window.sessionStorage.getItem(labels.UserActive)) {
        dispatch(
          TECHClient.captureEvent(techData?.techId, browserName, labels.TI_WEB)
        );
        window.sessionStorage.setItem(labels.UserActive, "1");
      }
    }
  }, [techData]);

  return (
    <div>
      <AuthenticatedTemplate>
        <ThemeProvider theme={theme}>
          <HashRouter>
            <div className="App">
              <DrawerCont
                drawerToggle={drawerToggle}
                setDrawerToggle={setDrawerToggle}
              />
              <div className="appCont">
                <div className={drawerToggle ? "headerSmSec" : "headerSec"}>
                  <header
                    className={drawerToggle ? "App-sm-header" : "App-header"}
                  >
                    <HeaderBar setShowRSBar={setShowRSBar} />
                  </header>
                  <div className="dummyDiv"></div>
                  {/*  <BreadCrumbBar /> */}
                </div>
                <main
                  className={drawerToggle ? "main-sm-section" : "main-section"}
                >
                  <Routes />
                </main>
                <ResponseSnackBar
                  open={showRSBar}
                  requestType={notiCRUDRequestType + " notification "}
                  responseType={notiCRUDStatus}
                  handleClose={handleClose}
                />
              </div>
            </div>
            <MonthlyFeedbackModal />
          </HashRouter>
        </ThemeProvider>
      </AuthenticatedTemplate>
    </div>
  );
}

export default App;
