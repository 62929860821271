import { Divider } from "@material-ui/core";
import { isNull } from "../../../../components/reusable/Util";
import TopologyLabels from "../../../constants/topologyLabels";
import { GetTelemetryProps } from "../../../util/reduxFunctions/getTopologyState";
import { isCoordsAvaileable } from "../../../util/utilFuncs";
import CMAdvancedFilterResults from "./CMAdvancedFilterResults";
import CMNotPlotted from "./CMNotPlotted";

const DiagDetails = () => {
  const { diagStartTime, diagEndTime, mlData, advancedFilters } =
    GetTelemetryProps();
  GetTelemetryProps();
  const labels = TopologyLabels();

  const getDetails = () => {
    const assigned = mlData?.length;
    let unresponsive = 0;
    let responsive = 0;
    let plotted = 0;
    let notPlotted = 0;
    let active = "0";
    let notPlottedCMs: any = [];
    mlData?.map((d: any) => {
      if (d?.deviceDiag) {
        const summary = d?.deviceDiag?.data?.summary;
        if (summary && summary?.uptime > 0) {
          responsive += 1;
        } else unresponsive += 1;
        if (isCoordsAvaileable(d)) plotted += 1;
        else {
          notPlotted += 1;
          notPlottedCMs?.push(d);
        }
      }
    });

    if (responsive > 0) {
      active = ((100 * responsive) / assigned).toFixed(0);
    }
    return {
      responsive,
      unresponsive,
      active,
      plotted,
      notPlotted,
      notPlottedCMs,
    };
  };
  return (
    <>
      <div className="topoDiagDetail">
        <div
          className="teleSummaryDetails"
          style={{ alignSelf: "center", marginBottom: 10 }}
        >
          <span>{labels?.totalAssigned?.toUpperCase()}:</span>
          <span style={{ marginLeft: 5 }}>{isNull(mlData?.length)}</span>
        </div>
        <div className="teleSummaryDetails">
          <section>
            <span>{labels?.startTime?.toUpperCase()}</span>
            <span>{isNull(diagStartTime)}</span>
          </section>
          <section>
            <span>{labels?.endTime?.toUpperCase()}</span>
            <span>{isNull(diagEndTime)}</span>
          </section>
        </div>
        <div className="teleSummaryDetails">
          <section>
            <span>{labels?.plottedMap?.toUpperCase()}</span>
            <span>{isNull(getDetails()?.plotted)}</span>
          </section>
          <section>
            <span>{labels?.notPlottedMap?.toUpperCase()}</span>
            <span>{isNull(getDetails()?.notPlotted)}</span>
          </section>
        </div>
        <Divider />
        <div className="teleSummaryStat">
          <section className="teleDiagGreen">
            <span>{isNull(getDetails()?.responsive)}</span>
            <span>{labels?.responsive}</span>
          </section>
          <section className="teleDiagRed">
            <span>{isNull(getDetails()?.unresponsive)}</span>
            <span>{labels?.unresponsive}</span>
          </section>
          <section className="teleDiagBlack">
            <span>{isNull(getDetails()?.active)}%</span>
            <span>{labels?.active}</span>
          </section>
        </div>
      </div>
      {getDetails()?.notPlottedCMs?.length > 0 && (
        <CMNotPlotted cmList={getDetails()?.notPlottedCMs} />
      )}
      {advancedFilters?.isFiltersApplied && (
        <CMAdvancedFilterResults cmList={mlData} />
      )}
    </>
  );
};

export default DiagDetails;
