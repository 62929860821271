import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../middleware/apiActions";
import { GPON_SPLICE_MAP_URI } from "../../middleware/apiEndpoints";
import { StandardReduxType } from "../../reduxTypes";
import { decrApiCall, incrApiCall } from "../mapItems/mapDetails";

let currSpliceIndex = -1;

const slice = createSlice({
  name: "gponSpliceMap",
  initialState: {
    allData: [] as StandardReduxType[],
  },
  reducers: {
    spliceMapBegin: (sm, { payload }) => {
      currSpliceIndex = sm.allData.findIndex(
        (sMap) =>
          sMap?.data?.nodeSiteId === payload.nodeSiteID &&
          sMap?.data?.enclosureNetworkId === payload.enclosureNetworkId
      );
      const objStructure = {
        isLoading: true,
        error: undefined,
        status: "",
        data: {
          nodeSiteId: payload.nodeSiteId,
          enclosureNetworkId: payload.enclosureNetworkId,
        },
      } as StandardReduxType;

      if (currSpliceIndex === -1) {
        sm.allData.push(objStructure);
        currSpliceIndex = sm.allData.length - 1; //set current index
      } else sm.allData[currSpliceIndex] = objStructure;
    },
    spliceMapSuccess: (sm, { payload }) => {
      sm.allData[currSpliceIndex] = {
        isLoading: false,
        error: undefined,
        status: "OK",
        data: {
          nodeSiteId: payload.nodeSiteId,
          enclosureNetworkId: payload.enclosureNetworkId,
          payload: payload.data,
        },
      };
    },
    spliceMapFailure: (sm, { payload }) => {
      sm.allData[currSpliceIndex] = {
        isLoading: false,
        error: payload.data,
        status: "ERROR",
        data: {
          nodeSiteId: payload.nodeSiteId,
          enclosureNetworkId: payload.enclosureNetworkId,
        },
      };
    },
  },
});

const { spliceMapBegin, spliceMapSuccess, spliceMapFailure } = slice.actions;
export default slice.reducer;

interface Props {
  nodeSiteId: string;
  enclosureNetworkId: string;
}
export const getGponSpliceMap =
  ({ nodeSiteId, enclosureNetworkId }: Props) =>
  (dispatch: any) => {
    dispatch(
      apiCallBegan({
        url: GPON_SPLICE_MAP_URI + enclosureNetworkId,
        onStart: spliceMapBegin.type,
        onSuccess: spliceMapSuccess.type,
        onError: spliceMapFailure.type,
        onStartDispatch: incrApiCall.type,
        onSuccessDispatch: decrApiCall.type,
        onErrorDispatch: decrApiCall.type,
        props: { nodeSiteId, enclosureNetworkId },
      })
    );
  };
