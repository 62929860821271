import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationsEng from "./resources/locals/en/translation.json";
import translationsFre from "./resources/locals/fre/translation.json";

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: translationsEng,
    },
    fre: {
      translations: translationsFre,
    },
  },
  fallbackLng: "en",
  debug: true,
  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: false, // we use content as keys
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
  },
  react: {
    wait: true,
  },
});

export default i18n;
