import { Divider } from "@material-ui/core";

import "../../../design-tokens/forms/FeedBack.scss";
import AppLabels from "../../../constants/App_Labels";
import CustomCarousel from "../../reusable/CustomCarousel";

export default function ViewFeedBackScreen({ data }: any) {
  const formData = data?.formData;
  const labels = AppLabels() as any;

  return (
    <div className="viewFBContainer">
      <div>
        <label className="titleLabel">{labels.ACCOUNT_NUMBER}:</label>
        <label className="textLabel">{data?.accountNumber}</label>
      </div>
      {data?.workOrderId ? (
        <>
          <div>
            <label className="titleLabel">{labels.Work_Order_ID}:</label>
            <label className="textLabel">{data?.workOrderId}</label>
          </div>
          <div>
            <label className="titleLabel">{labels.Order_Type}:</label>
            <label className="textLabel">{formData?.orderType}</label>
          </div>
        </>
      ) : (
        <div>
          <label className="titleLabel">{labels.No_Work_Order}</label>
        </div>
      )}
      <div>
        <label className="titleLabel">{labels.Coachback_Group}:</label>
        <label className="textLabel">{formData?.menuItem2}</label>
      </div>
      <Divider style={{ margin: "10px 0px 20px 0px" }} />
      <div>
        <label className="titleLabel">{labels.COACHBACK_TYPE}:</label>
        <label className="textLabel">{formData?.menuItem}</label>
      </div>
      <Divider style={{ margin: "10px 0px 20px 0px" }} />
      <div>
        <label className="titleLabel">{labels.FEEDBACK_MESSAGE}:</label>
      </div>
      <div>{formData?.feedBackMsg}</div>
      <Divider style={{ margin: "10px 0px 20px 0px" }} />
      <div>
        {formData?.files && (
          <div style={{ marginTop: "15px", marginBottom: "25px" }}>
            <label className="titleLabel">{labels.Attached_Images}:</label>
            <div className="carouselContainer">
              <CustomCarousel images={formData?.files} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
