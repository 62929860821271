import ExceptionBox from "../../../components/reusable/ExceptionBox";
import appConst from "../../constants/appConst";
import TopologyLabels from "../../constants/topologyLabels";
import { GetTopoGeneralProps } from "../../util/reduxFunctions/getTopologyState";
import ClickOrdersTable from "./click/ClickOrdersTable";
import NodeConfigTable from "./elements/node/NodeConfigTable";
import NodeSiteComponents from "./elements/node/NodeSiteComponents";
import NodeSiteDetailsTable from "./elements/node/NodeSiteDetailsTable";
import GponSplitters from "./elements/node/gpon/GponSplitters";
import GponPortSelector from "./elements/node/gpon/GponPortSelector";
import OLTDetailsTable from "./olt/OLTDetailsTable";
import OLTCards from "./olt/OLTCards";
import WorkOrdersTable from "./workOrders/WorkOrdersTable";
import VhubDetailsTable from "./vhub/VhubDetailsTable";

interface Props {
  elementData: any;
  isPrimaryNodeTab: boolean;
}

export default function CustomAMEComps({
  elementData,
  isPrimaryNodeTab,
}: Props) {
  const labels = TopologyLabels();
  const { activeNodeType, activeNodeId } = GetTopoGeneralProps();

  return (
    <>
      {elementData?.type === appConst.vhub ? (
        <VhubDetailsTable elementData={elementData} />
      ) : elementData?.type === appConst.node ? (
        <>
          <NodeSiteDetailsTable
            isPrimaryNodeTab={isPrimaryNodeTab}
            elementData={elementData}
          />
          <NodeConfigTable elementData={elementData} />
          <NodeSiteComponents isPrimaryNodeTab={isPrimaryNodeTab} />
          {activeNodeType === appConst.GPON_NODE &&
            elementData?.data?.nodeNum === activeNodeId && (
              <>
                <GponSplitters elementData={elementData} />
                <GponPortSelector elementData={elementData} />
              </>
            )}
        </>
      ) : elementData?.type === appConst.click ? (
        <ClickOrdersTable elementData={elementData} />
      ) : elementData?.type === appConst.olt ? (
        <>
          <OLTDetailsTable elementData={elementData} />
          <OLTCards elementData={elementData} />
        </>
      ) : elementData?.type === appConst.workOrders ? (
        <WorkOrdersTable elementData={elementData} />
      ) : (
        <div className="diagErrorCont">
          <ExceptionBox
            headerText={labels?.noSelectedEle}
            bodyText={labels?.selectEleMSG}
          />
        </div>
      )}
    </>
  );
}
