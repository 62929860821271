import { Tab, Tabs } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import GWDevicesInfo from "./GWDevicesInfo";
import WifiInfo from "./WifiInfo";
import { getCMW3ID, isNull, getCMW3ID4Brand } from "../reusable/Util";
import GWDevicesClient from "../../network/httpclient/gwDevices/GWDevicesClient";
import WIFIInfoClient from "../../network/httpclient/wifi_Info/WIFIInfoClient";
import AppLabels from "../../constants/App_Labels";
import { GetActiveAccountDetails } from "../../get-api/GetResponse";
import EthernetClient from "../../network/httpclient/gwDevices/EthernetClient";
import EthernetInfo from "./EthernetInfo";
const GWDiagnostics = (props: any) => {
  const dispatch = useDispatch();
  const labels = AppLabels();
  const {
    selectedDevice,
    selectedSA,
    deviceDiagActions,
    selDiagAction,
    handleActionClick,
  } = props;
  const [wifiTabFlag, setWifiTabFlag] = useState(false);
  const [ethernetTabFlag, setEthernetTabFlag] = useState(false);
  const [key, setKey] = useState(selDiagAction);
  const brandName = GetActiveAccountDetails()?.brand;
  useEffect(() => {
    if (deviceDiagActions?.length !== 0) {
      deviceDiagActions?.map((action: any) => {
        if (action.tab === selDiagAction) {
          if (
            action.tab === "WIFI" &&
            !action.isOpen &&
            selectedDevice !== undefined
          ) {
            action.isOpen = true;
            dispatch(
              WIFIInfoClient.getWIFISettings(
                selectedDevice.macAddress,
                selectedDevice.serialNumber,
                getCMW3ID(selectedSA,brandName)
              )
            );
            dispatch(
              WIFIInfoClient.getWIFIRadios(
                selectedDevice.macAddress,
                selectedDevice.serialNumber,
                getCMW3ID(selectedSA,brandName)
              )
            );
          }
          else if (
            action.tab === "Ethernet" &&
            !action.isOpen &&
            selectedDevice !== undefined
          ) {
            action.isOpen = true;
          
            dispatch(
              EthernetClient.getnetworkInterfaces(
                selectedDevice.macAddress,
                selectedDevice.serialNumber,
                getCMW3ID4Brand(selectedSA, brandName),
                selectedDevice.oui
              )
            );
          }
        }
        if (action.tab === "WIFI" && action.isOpen) {
          setWifiTabFlag(true);
        }
        else  if (action.tab === "Ethernet" && action.isOpen) {
          setEthernetTabFlag(true);
          }
      });
    }
    setKey(selDiagAction);
  }, [selDiagAction]);
  useEffect(() => {
    dispatch(
      GWDevicesClient.getGWDevices(
        selectedDevice.macAddress,
        selectedDevice.serialNumber,
        getCMW3ID4Brand(selectedSA, brandName)
      )
    );
    setWifiTabFlag(false);
    setEthernetTabFlag(false);
  }, [selectedDevice]);
  return (
    <div className="detailDiagCont">
      <section className="deviceData">
        <div className="deviceStatusCont">
          <p>
            <span>{labels.MODEL.toUpperCase()} : </span>
            <span>{isNull(selectedDevice.deviceModel)}</span>
          </p>
        </div>
        <p>
          <span>{labels.SERIAL_NUMBER.toUpperCase()} : </span>
          <span>{isNull(selectedDevice.serialNumber)}</span>
        </p>
      </section>
      <section className="detailDiagTabs">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k: any) => handleActionClick(k)}
        >
          <Tab eventKey={labels.GW} title={labels.GW_DEVICES.toUpperCase()}>
            <GWDevicesInfo
              selectedDevice={selectedDevice}
              selectedSA={selectedSA}
            />
          </Tab>
          {wifiTabFlag ? (
            <Tab
              eventKey={labels.WIFI}
              title={labels.WIFI_SETTINGS.toUpperCase()}
            >
              <WifiInfo
                selectedDevice={selectedDevice}
                selectedSA={selectedSA}
              />
            </Tab>
          ) : (
            <></>
          )}
           {ethernetTabFlag && (
            <Tab eventKey={labels.ETHERNET} title={labels.ETHERNET.toUpperCase()}>
             <EthernetInfo
               selectedDevice={selectedDevice}
               selectedSA={selectedSA}
             />
           </Tab>
          )
          }
        </Tabs>
      </section>
    </div>
  );
};

export default GWDiagnostics;
