import {NodesInfo} from '../../../models/account/node/NodesInfo';

export const NODE_LIST_BEGIN = 'NODE_LIST_BEGIN';
export const NODE_LIST_SUCCESS = 'NODE_LIST_SUCCESS';
export const NODE_LIST_FAILURE = 'NODE_LIST_FAILURE';

interface NodesInfoBegin {
  type: typeof NODE_LIST_BEGIN;
}

interface NodesInfoSuccess {
  type: typeof NODE_LIST_SUCCESS;
  payload: NodesInfo;
}

interface NodesInfoFailure {
  type: typeof NODE_LIST_FAILURE;
  payload: Error;
}

export type NodesInfoActionTypes =
  | NodesInfoBegin
  | NodesInfoSuccess
  | NodesInfoFailure;
