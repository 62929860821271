import { CMTSIPDetails } from "../../../models/node/nodeinfo/CMTSNodeDetails";

export const CMTS_IP_BEGIN = "CMTS_IP_BEGIN";
export const SET_CMTS_IP_DETAILS = "SET_CMTS_IP_DETAILS";
export const CMTS_IP_SUCCESS = "CMTS_IP_SUCCESS";
export const CMTS_IP_FAILURE = "CMTS_IP_FAILURE";
export const RESET_CMTS_IP = "RESET_CMTS_IP";

interface CMTSIPBegin {
  type: typeof CMTS_IP_BEGIN;
}

interface SetCMTSIPDetails {
  type: typeof SET_CMTS_IP_DETAILS;
  payload: CMTSIPDetails;
}

interface CMTSIPSuccess {
  type: typeof CMTS_IP_SUCCESS;
  payload: CMTSIPDetails[];
}

interface CMTSIPFailure {
  type: typeof CMTS_IP_FAILURE;
  payload: any;
}

interface ResetCMTSIP {
  type: typeof RESET_CMTS_IP;
}

export type CMTSIPActionTypes =
  | CMTSIPBegin
  | SetCMTSIPDetails
  | CMTSIPSuccess
  | CMTSIPFailure
  | ResetCMTSIP;
