import appConst from "../constants/appConst";

export const getTopologyFilters = (labels: any) => {
  const rf = {
    label: labels.radioFreqRF,
    key: appConst.RF,
    isAllSelected: false,
    items: [
      { key: appConst.rfCable, label: labels.rfCables, isActive: true },
      { key: appConst.rfSplice, label: labels.rfSplice, isActive: true },
      { key: appConst.rfDrop, label: labels.cableDrops, isActive: true },
      { key: appConst.splitter, label: labels.splitters, isActive: true },
      { key: appConst.terminator, label: labels.terminators, isActive: true },
      {
        key: appConst.activeEquipment,
        label: labels.activeEquipment,
        isActive: true,
      },
      { key: appConst.taps, label: labels.taps, isActive: true },
    ],
  };

  const fiber = {
    label: labels.Fiber,
    key: appConst.Fibre,
    isAllSelected: false,
    items: [
      { key: appConst.LCP, label: labels.LCP, isActive: true },
      { key: appConst.fiberCable, label: labels.fiberCable, isActive: true },
      {
        key: appConst.fiberStorageAndRiser,
        label: labels.fiberStorageAndRiser,
        isActive: true,
      },
      {
        key: appConst.fiberAddrConnectionPoint,
        label: labels.fiberAddrConnectionPoint,
        isActive: true,
      },
      { key: appConst.spliceCases, label: labels.spliceCases, isActive: true },
      { key: appConst.opticalTap, label: labels.opticalTap, isActive: true },
    ],
  };

  const fiberAndRF = {
    label: labels.FiberAndRF,
    key: appConst.fiberAndRF,
    isAllSelected: false,
    items: [
      {
        key: appConst.polygon,
        label: labels.nodeBoundaries,
        isActive: true,
      },
      { key: appConst.powerBlock, label: labels.powerBlocks, isActive: true },
      { key: appConst.powerSupply, label: labels.powerSupply, isActive: true },
      { key: appConst.mdu, label: labels.mdu, isActive: true },
      {
        key: appConst.supportStructure,
        label: labels.supportStructure,
        isActive: true,
      },
      {
        key: appConst.annotationBlock,
        label: labels.annotationBlock,
        isActive: true,
      },
      {
        key: appConst.powerInserter,
        label: labels.powerInserter,
        isActive: true,
      },
      { key: appConst.rfdropaddress, label: labels.addressNum, isActive: true },
    ],
  };

  const other = {
    label: labels.other,
    key: appConst.other,
    isAllSelected: false,
    items: [
      { key: appConst.memoComment, label: labels.comments, isActive: true },
      { key: appConst.workOrders, label: labels.workOrders, isActive: true },
      { key: appConst.ispSite, label: labels.ispSites, isActive: true },
    ],
  };

  return [rf, fiber, fiberAndRF, other];
};
