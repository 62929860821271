interface TableAttr {
  columnKey: string;
  columnName: string;
  jsonDataMapping: string;
  haveMultipleValues?: boolean;
  displayAttribute?: string;
  specInfo?: any;
  getDataFrom: string;
  isPinned: boolean;
  isVisible: boolean;
  position: number;
  allowedLoading?: boolean;
}

export const multiAttrList: TableAttr[] = [
  {
    columnKey: "serialNumber",
    columnName: "Serial Number",
    jsonDataMapping: "serialNumber",
    getDataFrom: "accountSummaryResponse",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "companyNum",
    columnName: "Company Number",
    jsonDataMapping: "companyNum",
    getDataFrom: "accountSummaryResponse",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "accountNumber",
    columnName: "Account Number #",
    jsonDataMapping: "accountNumber",
    getDataFrom: "accountSummaryResponse",
    isPinned: false,
    isVisible: true,
    position: 0,
  },

  {
    columnKey: "samKey",
    columnName: "Sam Key",
    jsonDataMapping: "samKey",
    getDataFrom: "accountSummaryResponse",
    isPinned: false,
    isVisible: true,
    position: 0,
  },

  {
    columnKey: "model",
    columnName: "Model",
    jsonDataMapping: "model",
    getDataFrom: "accountSummaryResponse",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "serviceAddress",
    columnName: "Home Address",
    jsonDataMapping: "serviceAddress",
    getDataFrom: "accountSummaryResponse",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  // {
  //   columnKey: "smtId",
  //   columnName: "SMT",
  //   jsonDataMapping: "smtId",
  //   getDataFrom: "accountSummaryResponse",
  //   isPinned: false,
  //   isVisible: true,
  //   position: 0,
  // },

  // {
  //   columnKey: "node",
  //   columnName: " Node",
  //   jsonDataMapping: "node",
  //   getDataFrom: "accountSummaryResponse",
  //   isPinned: false,
  //   isVisible: true,
  //   position: 0,
  // },
  {
    columnKey: "pcrf",
    columnName: " PCRF Session",
    jsonDataMapping: "pcrf",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const getMultiTeleONTViewConfig = () => {
  let configList: any = [];

  let tempViewAttr = multiAttrList;

  configList?.push({
    attributes: tempViewAttr,
  });

  return configList;
};
