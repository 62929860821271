import {
  TAP_INFO_BEGIN,
  TAP_INFO_FAILURE,
  TAP_INFO_SUCCESS,
  TapInfoActionTypes,
} from "../../../types/account/neighbourhood/TapInfo";

export function TapInfoBegin(): TapInfoActionTypes {
  return {
    type: TAP_INFO_BEGIN,
  };
}

export function TapInfoSuccess(TapInfo: any): TapInfoActionTypes {
  return {
    type: TAP_INFO_SUCCESS,
    payload: TapInfo,
  };
}

export function TapInfoFailure(error: Error): TapInfoActionTypes {
  return {
    type: TAP_INFO_FAILURE,
    payload: error,
  };
}
