import { createSlice } from "@reduxjs/toolkit";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "vccapUATPortConfig",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
    macAddress: undefined,
  },
  reducers: {
    vccapUATPortConfigBegin: (rpd) => {
      rpd.isLoading = true;
    },
    vccapUATPortConfigSuccess: (rpd, action) => {
      rpd.isLoading = false;
      rpd.data = action.payload;
      rpd.error = undefined;
      rpd.status = storeConst.OK;
    },
    vccapUATPortConfigFailure: (rpd, action) => {
      rpd.isLoading = false;
      rpd.error = action.payload;
      rpd.data = undefined;
      rpd.status = storeConst.ERROR;
    },
    vccapUATPortConfigReset: (rpd) => {
      rpd.isLoading = false;
      rpd.error = undefined;
      rpd.data = undefined;
      rpd.status = "";
      rpd.macAddress = undefined;
    },
    setMacAddress: (rpd, action) => {
      rpd.macAddress = action.payload;
      rpd.error = undefined;
      rpd.data = undefined;
      rpd.status = "";
    },
  },
});

export const {
  vccapUATPortConfigBegin,
  vccapUATPortConfigSuccess,
  vccapUATPortConfigFailure,
  vccapUATPortConfigReset,
  setMacAddress,
} = slice.actions;
export default slice.reducer;
