import * as React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { GetGponNodeDetails } from "../../../get-api/GetResponse";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import "../../../design-tokens/gpon-page/GponLandingPage.scss";
import { IView } from "../../../constants/TelePrefConfig";
import CustomPopOver from "../../../components/reusable/CustomPopOver";
import { getThreshold } from "../../../components/reusable/Util";
import Loader from "../../../resources/images/loader.webp";
import CertiExceptionCont from "../../../components/certification/CertiExceptionCont";
import CustomPopUp from "../../../components/reusable/CustomPopUp";
import { dateIsValid, isStringNumber } from "../../../topology/util/utilFuncs";
import { BsBoxArrowUpRight } from "react-icons/bs";

type Props = {
  tableData: any;
  tableHeader: any[];
  searchAttributes?: any;
  sortAttributes?: any;
  sortingField?: string;
  onClickExternalLink?: (data: any, column: string) => void;
  setFilterData?: (data: any) => void;
};

export const PONTelemetryTable: React.FC<Props> = (props: Props) => {
  const { tableData, tableHeader, onClickExternalLink, setFilterData } = props;
  const [page, setPage] = React.useState(0);
  const {loading}= GetGponNodeDetails();
  const [rowsPerPageValue, setRowsPerPageValue] = React.useState(500);
  const [sortingOrder, setSortingOrder] = React.useState("asc");
  const [tableRowData, setTableRowData] = React.useState(tableData || []);
  const [sortingFieldState, setSortingFieldState] = React.useState("");
  const mainTableRef = React.useRef<HTMLTableElement>(null);
  const topScrollRef = React.useRef<HTMLDivElement>(null);
  const [searchInput, setSearchInput] = React.useState<any>(
    // create an object with the same keys as the table header
    tableHeader?.reduce((acc: any, curr: any) => {
      acc[curr?.attribute] = "";
      return acc;
    }, {})
  );
  const [openPopOver, setOpenPopOver] = React.useState([
    ...Array(tableData?.length).fill(false),
  ]);
  const [errorRow, setErrorRow] = React.useState<any>({});
  // const [scrollWidth, setScrollWidth] = React.useState<number | undefined>(
  //   mainTableRef.current?.scrollWidth
  // );

  const getValueBasedOnStatus = React.useCallback((data: any) => {
    if (data) {
      return (
        <span>
          {data?.status === "Fail" ? (
            <span className="failLinkState" style={{ fontWeight: 700 }}>
              {data?.value}
            </span>
          ) : (
            <span>{data?.value}</span>
          )}
        </span>
      );
    } else {
      return <span>-</span>;
    }
  }, []);
  const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
  const handleDoubleClick = (row: any) => {
    const isRowSelected = selectedRows.includes(row);

    if (isRowSelected) {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((selectedRow) => selectedRow !== row)
      );
    } else {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, row]);
    }
  };

  const isRowSelected = React.useMemo(() => {
    const selectedRowSet = new Set(selectedRows);

    return (row: any) => selectedRowSet.has(row);
  }, [selectedRows]);
  const onSortingChange = (field: string) => {
    // if the same field is clicked again, then toggle the sorting order
    if (sortingFieldState === field) {
      setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
    }
    // sort the table data
    if (field.trim().toLowerCase() === "address") {
      const reversed = sortingOrder === "asc" ? 1 : -1;
      const sortedData = tableRowData?.sort((a: any, b: any) => {
        const addressA = a?.servicedAddress;
        const addressB = b?.servicedAddress;
        // first compare the street number and then street name and then apartment number
        const streetNumberA = addressA?.streetNumber;
        const streetNumberB = addressB?.streetNumber;
        const streetNameA = addressA?.streetName;
        const streetNameB = addressB?.streetName;
        const apartmentNumberA = addressA?.aptNumber;
        const apartmentNumberB = addressB?.aptNumber;
        const collator = new Intl.Collator(undefined, {
          numeric: true,
          sensitivity: "base",
        });

        if (collator.compare(streetNumberA, streetNumberB) === 0) {
          if (collator.compare(streetNameA, streetNameB) === 0) {
            return (
              collator.compare(apartmentNumberA, apartmentNumberB) * reversed
            );
          }
          return collator.compare(streetNameA, streetNameB) * reversed;
        }
        return collator.compare(streetNumberA, streetNumberB) * reversed;
      });
      setTableRowData(sortedData);
      if (setFilterData) {
        setFilterData(sortedData);
      }
    } else {
      const sortedData = tableRowData?.sort((a: any, b: any) => {
        const reversed = sortingOrder === "asc" ? 1 : -1;
        const collator = new Intl.Collator(undefined, {
          numeric: true,
          sensitivity: "base",
        });
        if (a[field] instanceof Object && b[field] instanceof Object) {
          // sort the numbers which includes the negative numbers as well
          // if value is a number, then sort it
          if (
            !isNaN(Number(a[field].value)) &&
            !isNaN(Number(b[field].value))
          ) {
            return Number(a[field].value) > Number(b[field].value)
              ? reversed
              : -reversed;
          }
          if (a[field].value === undefined && b[field].value === undefined) {
            return (
              collator.compare(
                a[field]?.data?.totalCount,
                b[field].data?.totalCount
              ) * reversed
            );
          }
          return collator.compare(a[field].value, b[field].value) * reversed;
        } else if (a[field] && b[field]) {
          // sort the strings
          return collator.compare(a[field], b[field]) * reversed;
        } else {
          // if value is empty, then sort it to the bottom
          if (!a[field] && !b[field]) {
            return 0;
          } else if (!a[field]) {
            return 1;
          } else if (!b[field]) {
            return -1;
          }
          return a[field] > b[field] ? reversed : -reversed;
        }
      });
      setTableRowData(sortedData);
      if (setFilterData) {
        setFilterData(sortedData);
      }
    }
    setSortingFieldState(field);
    setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
  };

  const handleSearch = (searchContent: any, columnKey: string) => {
    let filteredData = tableData;
    let searchContentValue: any = [];
    let keys: any = [];

    Object.keys(searchInput).forEach((key) => {
      // if any of the column is not empty, then set the searchContentValue to the value of the column
      if (searchInput[key]?.trim() !== "") {
        searchContentValue.push(searchInput[key]);
        keys.push(key);
      }
    });

    for (let i = 0; i < keys.length; i++) {
      filteredData = filteredData.filter((row: any) => {
        const rowValue = row[keys[i]];

        if (rowValue instanceof Object) {
          if (rowValue?.value === undefined) {
            if(rowValue?.data?.totalCount ==undefined)
             {           
                return rowValue
                ?.toString()
                .includes(searchContentValue[i]?.trim()?.toString());
            } 
              else
            return rowValue?.data?.totalCount
              ?.toString()
              .includes(searchContentValue[i]?.trim()?.toString());
          }       
          return rowValue?.value
            ?.toLowerCase()
            .includes(searchContentValue[i].toLowerCase()?.trim());
        }
        if (isStringNumber(searchContentValue[i])) {
          return rowValue
            ?.toString()
            .includes(searchContentValue[i]?.trim()?.toString());
        } else {
          return rowValue
            ?.toString()
            ?.toLowerCase()
            .includes(searchContentValue[i].toLowerCase()?.trim());
        }
      });
    }
    setTableRowData(filteredData);
    if (setFilterData) {
      setFilterData(filteredData);
    }
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPageValue(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleScroll = (event: React.UIEvent<React.ReactNode>) => {
    const targetDiv: HTMLDivElement = event.target as HTMLDivElement;
    if (targetDiv === topScrollRef.current && mainTableRef.current) {
      mainTableRef.current.scrollLeft = targetDiv.scrollLeft;
    } else if (targetDiv === mainTableRef.current && topScrollRef.current) {
      topScrollRef.current.scrollLeft = targetDiv.scrollLeft;
    }
  };



  React.useEffect(() => {
    setTableRowData(tableData);
  }, [tableData]);

  const rowCellRender = (row: any, column: IView, index: number) => {
    let cellContent: any;

    if (row[column?.attribute] instanceof Object) {
        switch (column?.attribute) {

          case "opticalTx":
            cellContent = (
              <>
                {row[column?.attribute]?.error === null ||
                row[column?.attribute]?.error === undefined || !row[column?.attribute]?.hasOwnProperty("error")? (
                  <>
                    {row[column?.attribute]?.value ? (
                     <CustomPopOver
                     popOverData={getThreshold(row[column?.attribute]) || <></>}
                     originComp={
                       getValueBasedOnStatus(row[column?.attribute]) || <></>
                     }
                     index="popOver"
                   />
                    ) : (
                      <img src={Loader} alt="loading" height="20px" />
                    )}
                  </>
                ) : (
                  <>
                    {column?.disableError && (
                      <>
                        {row[column?.attribute]?.isCountLoading === false ? (
                          <>{row[column?.attribute]?.data?.totalCount}</>
                        ) : (
                          <img src={Loader} alt="loading" height="20px" />
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            );
            break;
            case "ponSplitterNodeId":
                cellContent = (
                  <>
                    {row[column?.attribute]?.error === null ||
                    row[column?.attribute]?.error === undefined && (
                      <>
                    {Array.isArray(  row[column?.attribute]) ? (
                      <>{(row[column?.attribute]).join(", ")}</>
                     ): row[column?.attribute] ? (
                     <>  {row[column?.attribute]}</>):
                       (
                <img src={Loader} alt="loading" height="20px" />)}</>)
               }</>);
                break;
                case "ontCount":
                  cellContent = (
                    <>
                      {row[column?.attribute]?.errorCode &&(
                    
                          <CustomPopOver
                          popOverData={<span>{row["status"]}</span>}
                          originComp={
                            <span
                              style={{
                                // style as error
                                color: "var(--primary)",
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setErrorRow({
                                  row:row[column?.attribute],
                                  index,
                                  column: column?.title,
                                });
                                // set openPopOver to true to open the pop over based on the index
                                setOpenPopOver((prev) => {
                                  return {
                                    ...prev,
                                    [index]: !prev[index],
                                  };
                                });
                              }}
                            >
                              ERROR
                            </span>
                          }
                          index="popOver"
                        />
                      )}
                    </>);
                    break;
          default:
            cellContent = (
              <>
                <CustomPopOver
                  popOverData={getThreshold(row[column?.attribute]) || <></>}
                  originComp={
                    getValueBasedOnStatus(row[column?.attribute]) || <></>
                  }
                  index="popOver"
                />
              </>
            );
        }
      }
   else if (row[column?.attribute] ) {
        if(column?.attribute==="portName")
            cellContent = (
                <>
                  {row[column?.attribute]?.error === null ||
                  row[column?.attribute]?.error === undefined && (
                    <>
                      {row[column?.attribute]!= undefined ? (
                      <> <span
                      style={{
                        // style as link
                        color: "#007bff",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (onClickExternalLink)
                          onClickExternalLink(
                            row,
                            row[column?.attribute]
                          );
                      }}
                    >
                      {row[column?.attribute] || ""}
                    </span></>
                      ) : (
                        <img src={Loader} alt="loading" height="20px" />
                      )}
                    </>
                  )}
                </>
              )
              else if(column?.attribute === "ontSerialNumber" && row[column?.attribute]!= undefined 
                && (row[column?.attribute]?.error === null ||
                  row[column?.attribute]?.error === undefined)
               ) {
                cellContent = (
                  <>
                    <span id="cm_mac">{row[column?.attribute] || ""}</span>
                    <span
                      className="externalLink"
                      title="Customer Account View"
                      onClick={() => {
                        if (onClickExternalLink)
                          onClickExternalLink(
                            row[column?.attribute],
                            column?.attribute
                          );
                      }}
                    >
                      <BsBoxArrowUpRight size={16} />
                    </span>
                  </>
                )
               }     
              else
          cellContent = (
            <>
              {row[column?.attribute]?.error === null ||
              row[column?.attribute]?.error === undefined && (
                <>
                  {row[column?.attribute]!= undefined ? (
                   row[column?.attribute] || ""
                  ) : (
                    <img src={Loader} alt="loading" height="20px" />
                  )}
                </>
              )}
            </>
          )

       
        // default:
        //         cellContent = row[column?.attribute] || "";
    //   }
    }
     else if(row[column?.attribute] ==undefined && !row?.hasOwnProperty("error")){
        cellContent = (
            <>
             
                    <img src={Loader} alt="loading" height="20px" />
                  
                </>
              ) 
    }else if (column?.externalLink) {
      cellContent = (
        <>
          <div className="cmMacWrapper">
            {column?.attribute === "ontSerialNumber" ? (
              <>
                <span id="cm_mac">{row[column?.attribute] || ""}</span>
                <span
                  className="externalLink"
                  title="Customer Account View"
                  onClick={() => {
                    if (onClickExternalLink)
                      onClickExternalLink(
                        row[column?.attribute],
                        column?.attribute
                      );
                  }}
                >
                  <BsBoxArrowUpRight size={16} />
                </span>
              </>
            ) : (
              <span
                style={{
                  // style as link
                  color: "#007bff",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (onClickExternalLink)
                    onClickExternalLink(
                      row[column?.attribute],
                      column?.attribute
                    );
                }}
              >
                {row[column?.attribute] || ""}
              </span>
            )}
          </div>
        </>
      );
    } else if (
      (row[column?.attribute] === null ||
        row[column?.attribute] === undefined ||  row[column?.attribute] === "") &&
      row?.hasOwnProperty("error")&&column?.attribute!=="address"
    ) {

      // display error code from the error object
      cellContent = (
        <>
          {row[column?.attribute] || (
            <>
              <CustomPopOver
                popOverData={<span>{row["status"]}</span>}
                originComp={
                  <span
                    style={{
                      // style as error
                      color: "var(--primary)",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setErrorRow({
                        row:row?.data,
                        index,
                        column: column?.title,
                      });
                      // set openPopOver to true to open the pop over based on the index
                      setOpenPopOver((prev) => {
                        return {
                          ...prev,
                          [index]: !prev[index],
                        };
                      });
                    }}
                  >
                    ERROR
                  </span>
                }
                index="popOver"
              />
            </>
          )}
        </>
      );
    } else if (row[column?.attribute]?.error === null ||
        row[column?.attribute]?.error === undefined)  {
          <>
            {row[column?.attribute]?.allIsLoading === false ? (
              <>{row[column?.attribute] ||""}</>
            ) : (
              <img src={Loader} alt="loading" height="20px" />
            )}  </>}
    else {
      switch (column?.attribute) {
        case "lastChange":
          cellContent = (
            <>
              {dateIsValid(row[column?.attribute])
                ? new Date(row[column?.attribute])
                    ?.toISOString()
                    .split("T")[0] +
                  `, ` +
                  new Date(row[column?.attribute] ?? "").toLocaleTimeString()
                : ""}
            </>
          );
          break;
        default:
          cellContent = row[column?.attribute] || "";
      }
    }
    return cellContent;
  };

  const identifyRow = React.useCallback(() => {
    const samKey = window.location;
    if (samKey) {
      // take samkey= from the url
      const samKeyFromUrl = samKey.hash.split("samKey=")[1];
      return samKeyFromUrl;
    }
  }, []);

  return (
    <>
      <Paper
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          ref={topScrollRef}
          className="topScroll gponTopScroll"
          style={{
            backgroundColor: "var(--backgroundLight)",
            overflowY:
              mainTableRef.current &&
              mainTableRef.current?.offsetHeight >=
                mainTableRef.current?.scrollHeight
                ? "hidden"
                : "auto",
          }}
          onScroll={handleScroll}
        >
          <div
            style={{
              height: "16px",
              // width: scrollWidth,
            }}
          />
        </div>
        <TableContainer
          style={{ maxHeight: "80vh" }}
          ref={mainTableRef}
          onScroll={handleScroll}
          className="gponTableScrollBar"
        >
          <Table aria-label="simple table" className="gponTableCustoms">
            <TableHead className="tableHeaderContainerGpon">
              <TableRow>
                {React.Children.toArray(
                  tableHeader?.map((header: IView, index: number) => (
                    <TableCell align="left">
                      <span
                        style={
                          header?.style || {
                            minWidth: header?.width,
                            maxWidth: header?.maxWidth,
                          }
                        }
                        className="tableHeaderGpon"
                      >
                        <span className="tableHeaderTextGpon">
                          <span>
                            <span>{header?.title}</span>

                            {/* if the column is sortable, then show the sort icon */}
                            <span
                              hidden={header?.disableSort}
                              onClick={() => onSortingChange(header?.attribute)}
                            >
                              {sortingFieldState === header?.attribute ? (
                                <button
                                  style={{
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                  }}
                                  className="deviceSortBtn"
                                >
                                  {sortingOrder === "desc" ? (
                                    <FaSortUp color="#000000bf" />
                                  ) : (
                                    <FaSortDown color="#000000bf" />
                                  )}
                                </button>
                              ) : (
                                <button
                                  style={{
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                  }}
                                  className="deviceSortBtn"
                                >
                                  <FaSort color="#c4c4c4" />
                                </button>
                              )}
                            </span>
                          </span>
                          {/* search bar */}
                          <input
                            type="search"
                            disabled={header?.disableSearch}
                            hidden={header?.disableSearch}
                            placeholder="Search"
                            className="gponTableSearchBar"
                            onChange={(e) => {
                              searchInput[header?.attribute] =
                                e.currentTarget.value;
                              handleSearch(
                                e.currentTarget.value,
                                header?.attribute
                              );
                            }}
                          />
                        </span>
                      </span>
                    </TableCell>
                  ))
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {React.Children.toArray(
                tableRowData
                  ?.slice(
                    page * rowsPerPageValue,
                    page * rowsPerPageValue + rowsPerPageValue
                  )
                  .map((row: any, rowIndex: number) => (
                    <TableRow
                      style={{
                        backgroundColor:
                          identifyRow() &&
                          identifyRow() === row?.servicedAddress?.samKey
                            ? "bisque"
                            : "",
                      }}
                    >
                      {React.Children.toArray(
                        tableHeader?.map((header: IView, index: number) => {
                          return (
                            <TableCell
                              align="left"
                              className="tableCellGpon"
                              style={{
                                verticalAlign: "baseline",
                                whiteSpace: "pre",
                                backgroundColor: isRowSelected(row)
                                  ? "yellow"
                                  : "",
                              }}
                              onDoubleClick={() => handleDoubleClick(row)}
                              
                            >
                              {rowCellRender(row, header, rowIndex)}
                            </TableCell>
                          );
                        })
                      )}
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="tablePaginationContainerGpon">
          <span className="tablePaginationTextGpon">
            TOTAL ROWS: {tableRowData?.length}
          </span>
          <TablePagination
            className="tablePaginationGpon"
            rowsPerPageOptions={[10, 25, 100, 500]}
            component="div"
            count={tableRowData?.length}
            rowsPerPage={rowsPerPageValue}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </Paper>

      <CustomPopUp
        showVal={openPopOver[errorRow.index]}
        headerText={"Error Details"}
        bodyElement={
          <>
            <CertiExceptionCont
              dataObj={errorRow.row}
              sectionName={errorRow.column}
            />
          </>
        }
        handleClose={() => {
          setOpenPopOver((prev) => {
            return {
              ...prev,
              [errorRow.index]: !prev[errorRow.index],
            };
          });
        }}
      />
    </>
  );
};