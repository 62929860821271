import { useEffect, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import WorkOrderCard from "./WorkOrderCard";
import "../../design-tokens/workorder/WorkOrders.scss";
import { useDispatch } from "react-redux";
import FullPageLoader from "../reusable/FullPageLoader";
import DiagContainer from "../reusable/DiagContainer";
import { FormControl, InputGroup } from "react-bootstrap";
import {
  filterArrayOfObjects,
  getAccessLevelByRoles,
  sortWOJobCodeList,
} from "../reusable/Util";
import ExceptionBox from "../reusable/ExceptionBox";
import ReloadButton from "../../components/reusable/ReloadButton";
import WorkOrdersClient from "../../network/httpclient/workorders/WorkOrdersClient";
import { GetTechInfo, GetWorkOrdersList } from "../../get-api/GetResponse";
import { WorkOrder } from "../../store/models/technicians/WorkOrder";
import ConfigConst from "../../constants/ConfigConst";

const WorkOrders = (props: any) => {
  // const { refreshData } = props;
  const dispatch = useDispatch();
  const techData = GetTechInfo()?.data;
  const workOrders = GetWorkOrdersList()?.data as WorkOrder[];
  const woLoading = GetWorkOrdersList()?.isLoading;
  const woError = GetWorkOrdersList()?.error;

  let techName = techData?.name;
  const sortWOList = [
    "enroute",
    "onsite",
    "dispatched",
    "completed",
    "tentative",
    "pendingrestoral",
  ];

  const [searchValue, setSearchValue] = useState("");
  const [wOList, setWOList] = useState<any>([]);
  const [wOType, setWOType] = useState(false);
  const handleWOSearch = (e: any) => {
    setSearchValue(e.target.value);
    if (workOrders !== undefined && workOrders?.length !== 0) {
      let woList = filterArrayOfObjects(e.target.value, workOrders, [
        "id",
        "status",
        "accountNumber",
        "customerContactNumber",
        "address",
      ]);
      setWOList(woList);
    }
  };

  useEffect(() => {
    if (workOrders !== undefined && workOrders?.length !== 0) {
      let sortWOListTemp = sortWOList;
      workOrders?.map((e) => {
        if (!sortWOListTemp.includes(e?.status)) {
          sortWOListTemp.push(e?.status?.toLowerCase());
        }
      });
      let sortWOJobCodeListTemp = sortWOJobCodeList;
      workOrders?.map((e) => {
        if (!sortWOJobCodeListTemp.includes(e?.jobCode)) {
          sortWOJobCodeListTemp.push(e?.jobCode?.toUpperCase());
        }
      });

      let woList = workOrders?.sort((a, b) => {
        return (
          sortWOList?.indexOf(a?.status?.toLowerCase()) -
          sortWOList?.indexOf(b?.status?.toLowerCase())
        );
      });

      let woPriorityList = workOrders.sort(
        (a, b) =>
          sortWOListTemp?.indexOf(a?.status?.toLowerCase()) -
            sortWOListTemp?.indexOf(b?.status?.toLowerCase()) ||
          sortWOJobCodeListTemp?.indexOf(a?.jobCode?.toUpperCase()) -
            sortWOJobCodeListTemp?.indexOf(b?.jobCode?.toUpperCase())
      );

      if (wOType) {
        setWOList(woPriorityList);
      } else {
        setWOList(woList);
      }
    }
  }, [workOrders]);

  const refreshData = () => {
    if (techData !== undefined && techData.hasOwnProperty("name")) {
      dispatch(
        WorkOrdersClient.getTechWorkOrders(
          techData?.techId,
          techData?.name,
          getAccessLevelByRoles(techData?.roles).includes(ConfigConst.MT)
        )
      );
    }
  };

  return (
    <div className="workOrderWdt">
      <div className="workOrderSearch">
        <InputGroup className="searchWOIG">
          <button className="searchWOBtn">
            <SearchIcon />
          </button>
          <FormControl
            placeholder="Filter work order results..."
            className="searchInput-WO"
            value={searchValue}
            onChange={(e) => handleWOSearch(e)}
          />
        </InputGroup>
      </div>
      <ReloadButton className="workOrderRefreshBtn" refreshData={refreshData} />
      {techName ? (
        <DiagContainer
          isLoading={woLoading}
          sectionName="Work Orders"
          data={workOrders}
          error={woError}
          handleRefresh={refreshData}
        >
          <WorkOrderCard
            wOList={wOList}
            techName={techName}
            wOType={setWOType}
            techData={techData}
            refreshData={refreshData}
          />
        </DiagContainer>
      ) : (
        <div className="diagErrorCont">
          <ExceptionBox
            headerText="No Work Orders to display."
            bodyText={`Technician name is not available.`}
            primaryBtn={false}
            secBtn={false}
          />
        </div>
      )}
      <FullPageLoader />
    </div>
  );
};

export default WorkOrders;
