import React, { useState, useEffect } from "react";
import { Refresh } from "@material-ui/icons";
import { Button } from "react-bootstrap";
import Loader from "../../../resources/images/loader.webp";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Menu, MenuItem } from "@material-ui/core";
import AppLabels from "../../../constants/App_Labels";

interface Props {
  bannerData?: any;
  refresh?: boolean;
  isLevel2: boolean;
  refreshTime?: string;
  handleRefresh?: (e: any) => void;
  onActionClick?(args: any, item: any): void;
  tabIndex?: number;
}

const CMTSBanner = (props: Props) => {
  const {
    bannerData,
    refresh,
    onActionClick,
    refreshTime,
    tabIndex,
    isLevel2,
  } = props;
  const labels = AppLabels();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [aOrigin, setaOrigin] = useState({
    vertical: 40,
    horizontal: 15,
  });

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  // console.log("REFRESH TIME", refreshTime);

  const KeyValuePairText = ({ label, value, status }: any): JSX.Element => {
    // if status is true then loading icon will be shown in value
    // if status is false and value is null then '-' will be shown in value
    // if status is false and value is not null then value will be shown in value
    let valueToDisplay =
      value === null || value === undefined || value?.trim() === ""
        ? "-"
        : value;
    let valueElement = status ? (
      <div className="cmtsBannerLoadingIcon">
        <img src={Loader} alt="loading" height="20px" />
      </div>
    ) : (
      <span className="cmtsBannerText">{valueToDisplay}</span>
    );

    return (
      <div className="cmtsBannerLabel">
        <span className="cmtsBannerLabelText">{label}:</span>
        {valueElement}
      </div>
    );
  };

  return (
    <div className="cmtsBanner">
      <div className="cmtsBannerTitleContainer">
        <div className="cmtsBannerLabel">
          <h5 style={{ fontWeight: "bold", margin: 0 }}>CMTS IP:</h5>
          <h5 style={{ fontWeight: "normal", margin: 0 }}>
            {bannerData?.cmtsIp}
          </h5>
        </div>
        <div className="cmtsBannerLabelContainer">
          {isLevel2 ? (
            <>
              <KeyValuePairText
                label="CMTS SysUp Time"
                value={bannerData?.cmtsSysUpTime}
                status={bannerData?.status}
              />

              <div>
                <KeyValuePairText
                  label="CMTSSysDescr"
                  value={bannerData?.CMTSSysDescr}
                  status={bannerData?.status}
                />
              </div>

              <KeyValuePairText
                label="CMTS SysName"
                value={bannerData?.cmtsName}
                status={bannerData?.status}
              />

              <KeyValuePairText
                label="Last Refresh"
                value={refreshTime}
                status={bannerData?.status}
              />
            </>
          ) : (
            <>
              <KeyValuePairText
                label="CMTS SysUp Time"
                value={bannerData?.cmtsSysUpTime}
                status={bannerData?.status}
              />
              <KeyValuePairText
                label="Node"
                value={bannerData?.node}
                status={bannerData?.status}
              />
              <KeyValuePairText
                label="CMTS Name"
                value={bannerData?.cmtsName}
                status={bannerData?.status}
              />
              <KeyValuePairText
                label="MAC Domain"
                value={bannerData?.macDomain}
                status={bannerData?.status}
              />
              <KeyValuePairText
                label="US PORT"
                value={bannerData?.usPort}
                status={bannerData?.status}
              />
              <KeyValuePairText
                label="Last Refresh"
                value={refreshTime}
                status={bannerData?.status}
              />
            </>
          )}
        </div>
      </div>
      <div className="cmtsBannerBtns">
        <div className="cmtsBannerBtnsWrapper">
          {tabIndex === 0 && (
            <Button
              className="refreshCertiBtn"
              variant="outline-primary"
              size="sm"
              onClick={(e) => {
                if (!refresh) {
                  window.location.reload();
                }
              }}
              disabled={refresh}
            >
              Restart
              <Refresh />
            </Button>
          )}
        </div>
        <div className="telemetrydots">
          <button className="nodeTeleActionMenu" onClick={handleMenuClick}>
            {tabIndex === 0 && <BsThreeDotsVertical />}
          </button>
        </div>
        <Menu
          className="telemetryDeviceActionMenu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          getContentAnchorEl={null}
          anchorOrigin={aOrigin}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {bannerData?.actions?.length > 0 ? (
            bannerData?.actions?.map((a: any, i: number) => {
              return (
                <MenuItem
                  key={i}
                  // className={`runAction ${
                  //   a === labels.Export_to_CSV &&
                  //   nodeTeleStatus === "loading" &&
                  //   "disabledMenuItem"
                  // }`}
                  onClick={(e) => {
                    if (onActionClick) {
                      onActionClick(e, a);
                    }
                  }}
                >
                  {a}
                </MenuItem>
              );
            })
          ) : (
            <>{labels.NO_ACTIONS}</>
          )}
        </Menu>
      </div>
    </div>
  );
};

export default CMTSBanner;
