import { useEffect, useState } from "react";

import ExceptionBox from "../../../../components/reusable/ExceptionBox";
import TopologyLabels from "../../../constants/topologyLabels";
import NikaTableOrders from "../../drawer/nika/NikaTableOrders";

export default function ExpandedCustomTable(props: any) {
  const labels = TopologyLabels();
  const [tableProps, setTableProps] = useState<any>(null);

  useEffect(() => {
    const message = (e: any) => {
      if (e?.data?.compType) setTableProps(e?.data);
    };
    window.addEventListener("message", message);
    return () => {
      window.removeEventListener("message", message);
    };
  }, []);

  const comp = {
    NikaTableOrders: (
      <NikaTableOrders
        data={tableProps?.data}
        configData={tableProps?.configData}
        showExpandBtn={false}
        tableStyle={{}}
      />
    ),
  };

  return (
    <div className="tabDataCont">
      {tableProps?.compType ? (
        comp[tableProps?.compType as NewTabCompType]
      ) : (
        <div className="diagErrorCont">
          <ExceptionBox
            headerText={labels.noDataFound}
            bodyText={labels.passProperDataMsg}
          />
        </div>
      )}
    </div>
  );
}

export type NewTabCompType = "NikaTableOrders";
