import ConfigConst from "../../constants/ConfigConst";
import AppLabels from "../../constants/App_Labels";
import LoaderGif from "../../resources/images/loader.webp";

const NodeAverages = (props: any) => {
  const labels = AppLabels();
  const {
    setAveragesPopUp,
    setAvgPopUpBody,
    nodeTeleStatus,
    nodeAvgStatusData,
    nodeAveragesStatusLoading,
    isFullDiagPresent,
  } = props;

  return (
    <div>
      <button
        className="custDDBtn"
        style={{ fontWeight: 600, fontSize: "12px" }}
        disabled={
          !(
            nodeTeleStatus === ConfigConst.complete.toLowerCase() &&
            isFullDiagPresent
          )
        }
        onClick={() => {
          setAveragesPopUp(true);
          if (nodeAveragesStatusLoading === ConfigConst.loading.toLowerCase()) {
            setAvgPopUpBody(
              <img
                className="nodeTeleDataLoader"
                src={LoaderGif}
                alt="LoaderGif"
              />
            );
          } else {
            setAvgPopUpBody(
              <div>
                {nodeAvgStatusData?.upStreamFreq &&
                  nodeAvgStatusData?.upStreamFreq?.length > 0 && (
                    <div className="avgNodeTable">
                      <div className="avgOFDMA">{labels.US_SC_QAM}</div>
                      <div className="dataTableDetails avgNodeTable">
                        <table>
                          <thead>
                            <tr>
                              <th>{labels.US_Freq?.toUpperCase()}</th>
                              <th>{labels.US_TX?.toUpperCase()}</th>
                              <th>{labels.US_SNR?.toUpperCase()}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {nodeAvgStatusData?.upStreamFreq?.map(
                              (e: any, index: number) => {
                                return (
                                  <tr key={index}>
                                    <td>{e?.frequency}</td>
                                    <td
                                      className={
                                        e?.usTXStatus == "Fail"
                                          ? "outSpecState"
                                          : e?.usTXStatus == "Pass"
                                          ? "inSpecState"
                                          : e?.usTXStatus == "Marginal Pass"
                                          ? "marginal"
                                          : "none"
                                      }
                                    >
                                      {e?.usTx}
                                    </td>
                                    <td
                                      className={
                                        e?.usSNRStatus == "Fail"
                                          ? "outSpecState"
                                          : e?.usSNRStatus == "Pass"
                                          ? "inSpecState"
                                          : e?.usSNRStatus == "Marginal Pass"
                                          ? "marginal"
                                          : "none"
                                      }
                                    >
                                      {e?.usSNR}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                {nodeAvgStatusData?.ofdmaFreq &&
                  nodeAvgStatusData?.ofdmaFreq?.length > 0 && (
                    <div className="avgNodeTable">
                      <div className="avgOFDMA">{labels.OFDMA}</div>
                      <div className="dataTableDetails avgNodeTable">
                        <table>
                          <thead>
                            <tr>
                              <th>{labels.CHANNEL_ID?.toUpperCase()}</th>
                              <th>{labels.OFDMA_TX}</th>
                              <th>{labels.OFDMA_PCT}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {nodeAvgStatusData?.ofdmaFreq?.map(
                              (e: any, index: number) => {
                                return (
                                  <tr key={index}>
                                    <td>{e?.channelId}</td>
                                    <td
                                      className={
                                        e?.ofdmaTxStatus == "Fail"
                                          ? "outSpecState"
                                          : e?.ofdmaTxStatus == "Pass"
                                          ? "inSpecState"
                                          : e?.ofdmaTxStatus == "Marginal Pass"
                                          ? "marginal"
                                          : "none"
                                      }
                                    >
                                      {e?.ofdmaTx}
                                    </td>
                                    <td
                                      className={
                                        e?.ofdmPCTStatus == "Fail"
                                          ? "outSpecState"
                                          : e?.ofdmPCTStatus == "Pass"
                                          ? "inSpecState"
                                          : e?.ofdmPCTStatus == "Marginal Pass"
                                          ? "marginal"
                                          : "none"
                                      }
                                    >
                                      {e?.ofdmaPCT}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                {nodeAvgStatusData?.downStreamFreq &&
                  nodeAvgStatusData?.downStreamFreq?.length > 0 && (
                    <div className="avgNodeTable">
                      <div className="avgOFDMA">{labels.DS_SC_QAM}</div>
                      <div
                        className="dataTableDetails avgNodeTable"
                        style={{ maxHeight: "150px", overflowY: "auto" }}
                      >
                        <table>
                          <thead>
                            <tr>
                              <th>{labels.DS_Freq?.toUpperCase()}</th>
                              <th>{labels.DS_RX?.toUpperCase()}</th>
                              <th>{labels.DS_SNR?.toUpperCase()}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {nodeAvgStatusData?.downStreamFreq?.map(
                              (e: any, index: number) => {
                                return (
                                  <tr key={index}>
                                    <td>{e?.frequency}</td>
                                    <td
                                      className={
                                        e?.dsRxStatus == "Fail"
                                          ? "outSpecState"
                                          : e?.dsRxStatus == "Pass"
                                          ? "inSpecState"
                                          : e?.dsRxStatus == "Marginal Pass"
                                          ? "marginal"
                                          : "none"
                                      }
                                    >
                                      {e?.dsRx}
                                    </td>
                                    <td
                                      className={
                                        e?.dsSNRStatus == "Fail"
                                          ? "outSpecState"
                                          : e?.dsSNRStatus == "Pass"
                                          ? "inSpecState"
                                          : e?.dsSNRStatus == "Marginal Pass"
                                          ? "marginal"
                                          : "none"
                                      }
                                    >
                                      {e?.dsSNR}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                {nodeAvgStatusData?.ofdmFreq &&
                  nodeAvgStatusData?.ofdmFreq?.length > 0 && (
                    <div className="avgNodeTable">
                      <div className="avgOFDMA">{labels.OFDM}</div>
                      <div className="dataTableDetails avgNodeTable">
                        <table>
                          <thead>
                            <tr>
                              <th>{labels.OFDM_CHANNEL_ID?.toUpperCase()}</th>
                              <th>{labels.OFDM_RX_MIN?.toUpperCase()}</th>
                              <th>{labels.OFDM_RX_MAX?.toUpperCase()}</th>
                              <th>{labels.OFDM_PCT?.toUpperCase()}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {nodeAvgStatusData?.ofdmFreq?.map(
                              (e: any, index: number) => {
                                return (
                                  <tr key={index}>
                                    <td>{e?.channelId}</td>
                                    <td
                                      className={
                                        e?.ofdmRxMinStatus == "Fail"
                                          ? "outSpecState"
                                          : e?.ofdmRxMinStatus == "Pass"
                                          ? "inSpecState"
                                          : e?.ofdmRxMinStatus ==
                                            "Marginal Pass"
                                          ? "marginal"
                                          : "none"
                                      }
                                    >
                                      {e?.ofdmRxMin}
                                    </td>
                                    <td
                                      className={
                                        e?.ofdmRxMaxStatus == "Fail"
                                          ? "outSpecState"
                                          : e?.ofdmRxMaxStatus == "Pass"
                                          ? "inSpecState"
                                          : e?.ofdmRxMaxStatus ==
                                            "Marginal Pass"
                                          ? "marginal"
                                          : "none"
                                      }
                                    >
                                      {e?.ofdmRxMax}
                                    </td>
                                    <td
                                      className={
                                        e?.ofdmPCTStatus == "Fail"
                                          ? "outSpecState"
                                          : e?.ofdmPCTStatus == "Pass"
                                          ? "inSpecState"
                                          : e?.ofdmPCTStatus == "Marginal Pass"
                                          ? "marginal"
                                          : "none"
                                      }
                                    >
                                      {e?.ofdmPCT}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
              </div>
            );
          }
        }}
      >
        {labels.AVERAGES?.toUpperCase()}
      </button>
    </div>
  );
};

export default NodeAverages;
