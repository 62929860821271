export const GLOBAL_CONST = {
  portNumber: "1",
  telPrefix: "+1",
  feedbackLink:
    "https://forms.office.com/Pages/ResponsePage.aspx?id=v8u0CsdLJki1LKFP7VKGuY3Rh7zlHf9Ds03ZfM93R_lUOUxLMFpYMUtSVjVQMUxVQ1dWN0NUMVoyWC4u",
  radianValue: 57.29578,
  rotationConstant: 15,
  localStorageSearchHistory: "NodeRecentSearch",
  polygon: "POLYGON",
  linestring: "LINESTRING",
  rfCable: "RFCABLE",
  fiberCableGeometry: "FIBERCABLEGEOMETRY",
  maintTech: ["MA", "OUTG"],
  rfDrop: "RFDROP",
  mduPolyline: "MDUPOLYLINE",
  f2Link: "http://snmp-prod-dyas.osn.wireless.rogers.com/cgi-ets/cmts_perf",
};

export const CUSTOMER_TYPE_CONFIG: any = {
  Rogers: "R",
  Fido: "F",
  TPIA: "T",
};

export const TAF_ORDER_CONST: any = {
  rpdSearchbyMacAddressSuiteName: "MobileApp",
  rpdSearchbyMacAddressBotName: "Get_RPD_Details",
  rpdSearchbyMacAddressBotOALName: "Get_RPD_Details_OAL",
  rpdSearchbyNodeIDSuiteName: "MobileApp",
  rpdSearchbyNodeIDBotName: "Get_Inventory_DetailByNode",
  rpdSearchbyNodeIDBotOALName: "Get_Inventory_DetailByNode_OAL",
  rpdRegistrationSuiteName: "MobileApp",
  rpdRegistrationBotName: "Register_RPDMAC",
  rpdSwapSuiteName: "MobileApp",
  rpdSwapBotName: "SwapRPDMac",
  rpdCrossSwapSuiteName: "MobileApp-DA2200",
  rpdCrossSwapBotName: "RPDCrossSwap",
  rpdNPTSummarySuiteName: "MobileApp",
  rpdNPTSummaryBotName: "GetNPTSummary",
  rpdNPTSummaryDA2200SuiteName: "MobileApp-DA2200",
  rpdNPTSummaryDA2200BotName: "Get_DeviceType_and_NPTSummary",
  rpdActivationSuiteName: "MobileApp",
  rpdActivationBotName: "RPDActivation",
  rpdActivationDA2200SuiteName: "RCCAP-software-upgrade-DA2200",
  rpdActivationDA2200BotName: "RCCAP_Software_Upgrade_DA2200",
  rpdProvisioningSuiteName: "RPD-Provisioning",
  rpdProvisioningBotName: "RPD Registration Provisioning",
  rpdSWUpgradeSuiteName: "RPD-Upgradation",
  rpdSWUpgradeBotName: "RPD Software Upgradation",
  rpdSWUpgradeDA2200SuiteName: "RCCAP-software-upgrade-DA2200",
  rpdSWUpgradeDA2200BotName: "RCCAP_Software_Upgrade_DA2200",
  vccapHarmonicConfigSuiteName: "vccap-RPD-Provisioning-harmonic",
  vccapHarmonicConfigBotName: "Vccap RPD Configuration Execution harmonic",
  vccapHarmonicConfigGenerationBotName:
    "Vccap RPD Configuration Generation harmonic",
  vccapCasaConfigSuiteName: "vccap-RPD-Provisioning-Casa-video",
  vccapCasaConfigBotName: "RPD Casa Video Execution",
  vccapCasaConfigGenerationBotName: "RPD Casa Video Configuration Generation",
  vccapCasaSWUpgradeSuiteName: "vccap-RPD-upgradation-casa",
  vccapCasaSWUpgradeBotName: "RPD software upgrade for vccap casa",
  vccapHarmonicSWUpgradeSuiteName: "vccap-RPD-upgradation-harmonic",
  vccapHarmonicSWUpgradeBotName: "vccap RPD upgradation harmonic",
  vccapCasaUATPortSuiteName: "UAT_Port_Configuration-casa",
  vccapCasaUATPortBotName: "UAT Port Config for RPD Casa",
  vccapCasaUATPortGenerationBotName: "UAT Port Config Generation Casa",
  vccapHarmonicUATPortSuiteName: "UAT_Port_Configuration-harmonic",
  vccapHarmonicUATPortBotName: "UAT Port Config for RPD-harmonic",
  vccapHarmonicUATPortGenerationBotName: "UAT Port Config Generation-harmonic",
  rpdDecommSuiteName: "RPD-Decommission",
  rpdDecommBotName: "RPD Decommission Bot",
};
