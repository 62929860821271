import { createSlice } from "@reduxjs/toolkit";

import { apiCallBegan } from "../../../middleware/apiActions";
import { MICRO_REFLECTIONS_GROUPED_ISSUES } from "../../../middleware/apiEndpoints";
import storeConst from "../../../storeConst";
import { decrApiCall, incrApiCall } from "../mapDetails";

const slice = createSlice({
  name: "mrGroupedIssues",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
    selectedData: undefined,
    nodeId: "",
  },
  reducers: {
    getMrGroupedIssuesBegin: (mrGroupedIssues) => {
      mrGroupedIssues.isLoading = true;
      mrGroupedIssues.status = "";
    },
    getMrGroupedIssuesSuccess: (mrGroupedIssues, { payload }) => {
      let tempArr: any = [];
      payload?.groupWrapper?.map((e: any) => {
        e?.dataWrapper?.map((dw: any) => {
          tempArr.push(dw);
        });
      });

      mrGroupedIssues.isLoading = false;
      mrGroupedIssues.data = tempArr;
      mrGroupedIssues.status = storeConst.OK;
    },
    getMrGroupedIssuesFailure: (mrGroupedIssues, { payload }) => {
      mrGroupedIssues.isLoading = false;
      mrGroupedIssues.error = payload;
      mrGroupedIssues.data = undefined;
      mrGroupedIssues.status = storeConst.ERROR;
    },
    setUpdatedData: (mrGroupedIssues, { payload }) => {
      mrGroupedIssues.data = payload;
    },
    resetSelectedData: (mrGroupedIssues) => {
      mrGroupedIssues.selectedData = undefined;
    },
    setSelectedData: (mrGroupedIssues, { payload }) => {
      mrGroupedIssues.selectedData = payload;
    },
    setNodeId: (mrGroupedIssues, { payload }) => {
      mrGroupedIssues.nodeId = payload;
    },
  },
});

const {
  getMrGroupedIssuesBegin,
  getMrGroupedIssuesSuccess,
  getMrGroupedIssuesFailure,
} = slice.actions;
export default slice.reducer;

export const { setSelectedData, resetSelectedData, setUpdatedData, setNodeId } =
  slice.actions;

export const getMRGroupedIssues = (nodeId: any) => (dispatch: any) => {
  dispatch(
    apiCallBegan({
      url: MICRO_REFLECTIONS_GROUPED_ISSUES + nodeId,
      method: "get",
      onStart: getMrGroupedIssuesBegin.type,
      onSuccess: getMrGroupedIssuesSuccess.type,
      onError: getMrGroupedIssuesFailure.type,
      onStartDispatch: incrApiCall.type,
      onSuccessDispatch: decrApiCall.type,
      onErrorDispatch: decrApiCall.type,
    })
  );
};
