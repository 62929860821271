import AppLabels from "../../constants/App_Labels";
import "../../design-tokens/workorder/WorkOrderPopUp.scss";
import { openNewWindow } from "../reusable/Util";

export function networkDetailsData(woData: any) {
  const labels = AppLabels();

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className="WODetailColumnsContainer">
        <div className="WODetailColumn">
          <div className="WODetailColumnInfo">
            <header>{labels.Map_Area}</header>
            <p>{woData?.mapAreaCode ? woData?.mapAreaCode : labels.NA}</p>
          </div>
          <div className="WODetailColumnInfo">
            <header>{labels.Cable_Type}</header>
            <p>{woData?.cableType ? woData?.cableType : labels.NA}</p>
          </div>
          <div className="WODetailColumnInfo">
            <header>{labels.Contract_Type}</header>
            <p>{woData?.contractType ? woData?.contractType : labels.NA}</p>
          </div>
          <div className="WODetailColumnInfo">
            <header>{labels.PON_NAP}</header>
            <p>{woData?.ponNap ? woData?.ponNap : labels.NA}</p>
          </div>
          <div className="WODetailColumnInfo">
            <header>{labels.Fiber_Feed_Type}</header>
            <p>{woData?.fiberFeedType ? woData?.fiberFeedType : labels.NA}</p>
          </div>
        </div>
        <header>&nbsp; </header>
        <div className="WODetailColumn">
          <div className="WODetailColumnInfo">
            <header>{labels.PHUB}</header>
            <p>{woData?.phub ? woData?.phub : labels.NA}</p>
          </div>
          <div className="WODetailColumnInfo">
            <header>{labels.Account_No}</header>
            <p>{woData?.accountNumber ? woData?.accountNumber : labels.NA}</p>
          </div>
          <div className="WODetailColumnInfo">
            <header>{labels.Misc_Work_Code}</header>
            <p>{woData?.miscWorkCode ? woData?.miscWorkCode : labels.NA}</p>
          </div>
          <div className="WODetailColumnInfo">
            <header>{labels.PON_NODE}</header>
            <p>{woData?.ponNode ? woData?.ponNode : labels.NA}</p>
          </div>
          <div className="WODetailColumnInfo">
            <header>{labels.Fiber_Condition}</header>
            <p>{woData?.fiberCondition ? woData?.fiberCondition : labels.NA}</p>
          </div>
        </div>
        <div className="WODetailColumn">
          <div className="WODetailColumnInfo" onClick={() => {
            if (woData?.shubId && window) {
              openNewWindow(`/#/node?shubId=${woData?.shubId}`);
            }
          }}>
            <header>{labels.SHUB}</header>
            <span className={woData?.shubId && "linkStyle"}>{woData?.shubId ? woData?.shubId : labels.NA}</span>
          </div>
          <div className="WODetailColumnInfo">
            <header>{labels.Cable_Condition}</header>
            <p>{woData?.cableCondition ? woData?.cableCondition : labels.NA}</p>
          </div>
          <div className="WODetailColumnInfo">
            <header>{labels.Topology}</header>
            <p>{woData?.topology ? woData?.topology : labels.NA}</p>
          </div>
          <div className="WODetailColumnInfo">
            <header>{labels.ONT_Present}</header>
            <p>{woData?.ontPresent ? woData?.ontPresent : labels.NA}</p>
          </div>
        </div>
        <div className="WODetailColumn">
          <div
            className="WODetailColumnInfo"
            onClick={() => {
              if (woData?.smtId && window) {
                openNewWindow(`/#/node?smtId=${woData?.smtId}`);
              }
            }}>
            <header>{labels.SMT}</header>
            <span className={woData?.smtId && "linkStyle"}>{woData?.smtId ? woData?.smtId : labels.NA}</span>
          </div>
          <div className="WODetailColumnInfo">
            <header>{labels.Feed}</header>
            <p>{woData?.feedType ? woData?.feedType : labels.NA}</p>
          </div>
          <div className="WODetailColumnInfo">
            <header>{labels.PON_Hub}</header>
            <p>{woData?.ponHub ? woData?.ponHub : labels.NA}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
