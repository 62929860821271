import AppLabels from "../../constants/App_Labels";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import "../../design-tokens/account-page/FailedAttributes.scss";
import { isNull } from "../reusable/Util";
const FailedAttributesOdu = (props: any) => {
  const { oduWirelessInfo } = props;

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#ffffff",
      color: "#000000",
      boxShadow: theme.shadows[4],
      fontSize: 12,
    },
  }))(Tooltip);
  const labels = AppLabels();

  const arr: any = [];
  const data = oduWirelessInfo;

  if (oduWirelessInfo && oduWirelessInfo?.hasOwnProperty("cells")) {
    data?.cells?.map((element: any, key: any) => {
      const oduWiresslessInfoArray = [];

      const band = element?.band;
      const rsrp = element?.rsrp;
      const sinr = element?.sinr;
      if (band?.status === "Fail") {
        oduWiresslessInfoArray.push({
          name: "band".toUpperCase(),
          value: isNull(band?.value),
        });
      }
      if (rsrp?.status === "Fail") {
        oduWiresslessInfoArray.push({
          name: "rsrp".toUpperCase(),
          value: isNull(rsrp?.value),
        });
      }
      if (sinr?.status === "Fail") {
        oduWiresslessInfoArray.push({
          name: "sinr".toUpperCase(),
          value: isNull(sinr?.value),
        });
      }
      if (oduWiresslessInfoArray?.length > 0) {
        arr.push({
          name: "ODU_WIRELESS_INFO_" + (key + 1),
          value: oduWiresslessInfoArray,
          labels: [labels.NAME.toUpperCase(), labels.VALUE.toUpperCase()],
        });
      }
    });
  }

  return (
    <div className="faCmGW">
      <p>
        <span style={{ fontWeight: 600 }}> {labels.failedAttributes?.toUpperCase()} : </span>
        {arr ? (
          arr?.map((el: any, index: any) => {
            return (
              <LightTooltip
                title={
                  <table className="tableTypeTwo">
                    <thead>
                      <tr>
                        <th>{labels.Name?.toUpperCase()}</th>
                        <th>{labels.VALUE?.toUpperCase()}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {el?.value?.map((v: any, i: any) => {
                        return (
                          <tr key={i}>
                            <td>{v?.name}</td> <td>{v?.value}</td>{" "}
                          </tr>
                        );
                      })}{" "}
                    </tbody>
                  </table>
                }
              >
                <span className="tooltipText" style={{ fontWeight: 500 }}>
                  {el?.name},{" "}
                </span>
              </LightTooltip>
            );
          })
        ) : (
          <></>
        )}
      </p>
    </div>
  );
};

export default FailedAttributesOdu;
