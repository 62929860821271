import { CSSProperties } from "react";
import { FaShuttleVan } from "react-icons/fa";

import TopologyLabels from "../../topology/constants/topologyLabels";
import CustomPopOver from "./CustomPopOver";

interface Props {
  lat: number;
  lng: number;
  iconStyle?: CSSProperties;
}

export default function GoogleMapDirectionsBtn({ lat, lng, iconStyle }: Props) {
  const labels = TopologyLabels();

  if (lat && lng) {
    return (
      <CustomPopOver
        popOverData={labels.googleDirectionMsg}
        index="popOver"
        originComp={
          <FaShuttleVan
            className="basicTableHeaderIcon"
            style={iconStyle}
            onClick={() =>
              window.open(
                `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}&travelmode=driving`
              )
            }
          />
        }
      />
    );
  } else return null;
}
