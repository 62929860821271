import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { useState } from "react";
import {
  isNull,
  getThreshold,
  getStatusOfCheck,
  getCheckValue,
  isObject,
  getStatusOfCheckHeader,
} from "../../reusable/Util";
import AppLabels from "../../../constants/App_Labels";
import CustomPopOver from "../../reusable/CustomPopOver";

const OFDMUpChannelData = (props: any) => {
  const labels = AppLabels();
  const { ofdmUpChannel, totalChannel } = props;

  const [summaryAcc, setSummaryAcc] = useState(true);
  const [detailsAcc, setDetailsAcc] = useState(false);

  const handleAccToggle = (accName: string) => {
    if (accName === "summary") setSummaryAcc(!summaryAcc);
    else if (accName === "details") setDetailsAcc(!detailsAcc);
  };

  return (
    <div className="ofdmChannelCont">
      <div className="ofdmChSummary">
        <div className="dsChDataAcc" onClick={() => handleAccToggle("summary")}>
          <p>{labels.SUMMARY.toUpperCase()}</p>
          {summaryAcc ? (
            <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
          ) : (
            <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
          )}
        </div>
        {summaryAcc ? (
          <table className="dataTableDetails">
            <thead>
              <tr>
                <th>{labels.NAME.toUpperCase()}</th>
                <th>{labels.VALUE.toUpperCase()}</th>
                <th>{labels.STATUS.toUpperCase()}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{labels.FREQ_LOWER_BOUNDARY.toUpperCase()}</td>
                <td>{isNull(ofdmUpChannel.freqLowerBoundary)}</td>
                <td></td>
              </tr>
              <tr>
                <td>{labels.FREQ_UPPER_BOUNDARY.toUpperCase()}</td>
                <td>{isNull(ofdmUpChannel.freqUpperBoundary)}</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  {getStatusOfCheckHeader(
                    ofdmUpChannel?.mer?.thrVal,
                    labels.MER_2PCT.toUpperCase()
                  )}
                </td>
                {ofdmUpChannel?.hasOwnProperty("mer") ? (
                  <>
                    <td>
                      {isObject(ofdmUpChannel.mer.thrVal)
                        ? isNull(getCheckValue(ofdmUpChannel.mer.thrVal))
                        : ""}
                    </td>
                    <CustomPopOver
                      popOverData={
                        isObject(ofdmUpChannel.mer.thrVal)
                          ? isNull(getThreshold(ofdmUpChannel.mer.thrVal))
                          : ""
                      }
                      originComp={
                        isObject(ofdmUpChannel.mer.thrVal)
                          ? isNull(getStatusOfCheck(ofdmUpChannel.mer.thrVal))
                          : ""
                      }
                      index="popOver"
                    />
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td>
                  {getStatusOfCheckHeader(
                    ofdmUpChannel?.snr,
                    labels.SNR.toUpperCase()
                  )}
                </td>
                {ofdmUpChannel?.hasOwnProperty("snr") ? (
                  <>
                    <td>
                      {isObject(ofdmUpChannel.snr)
                        ? isNull(getCheckValue(ofdmUpChannel.snr))
                        : ""}
                    </td>
                    <td></td>
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td>{labels.MER_MEAN.toUpperCase()}</td>
                {ofdmUpChannel?.hasOwnProperty("mer") ? (
                  <>
                    <td>{isNull(ofdmUpChannel.mer.mean)}</td>
                    <td></td>
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td>
                  {getStatusOfCheckHeader(
                    ofdmUpChannel?.status,
                    "Operational Status".toUpperCase()
                  )}
                </td>
                {ofdmUpChannel?.hasOwnProperty("status") ? (
                  <>
                    <td>
                      {isObject(ofdmUpChannel.status)
                        ? isNull(getCheckValue(ofdmUpChannel.status))
                        : ""}
                    </td>
                    <CustomPopOver
                      popOverData={
                        isObject(ofdmUpChannel.status)
                          ? isNull(getThreshold(ofdmUpChannel.status))
                          : ""
                      }
                      originComp={
                        isObject(ofdmUpChannel.status)
                          ? isNull(getStatusOfCheck(ofdmUpChannel.status))
                          : ""
                      }
                      index="popOver"
                    />
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td>{labels.OFDMA_CHANNELS.toUpperCase()}</td>
                <td>{isNull(totalChannel)}</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  {getStatusOfCheckHeader(
                    ofdmUpChannel?.txPower,
                    labels.TX_POWER.toUpperCase()
                  )}
                </td>
                {ofdmUpChannel?.hasOwnProperty("txPower") ? (
                  <>
                    <td>
                      {isObject(ofdmUpChannel.txPower)
                        ? isNull(getCheckValue(ofdmUpChannel.txPower))
                        : ""}
                    </td>
                    <CustomPopOver
                      popOverData={
                        isObject(ofdmUpChannel.txPower)
                          ? isNull(getThreshold(ofdmUpChannel.txPower))
                          : ""
                      }
                      originComp={
                        isObject(ofdmUpChannel.txPower)
                          ? isNull(getStatusOfCheck(ofdmUpChannel.txPower))
                          : ""
                      }
                      index="popOver"
                    />
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td>{labels.MIN_MODULATION.toUpperCase()}</td>
                <td>{isNull(ofdmUpChannel.minModulation)}</td>
                <td></td>
              </tr>
              <tr>
                <td>{labels.MAX_MODULATION.toUpperCase()}</td>
                <td>{isNull(ofdmUpChannel.maxModulation)}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default OFDMUpChannelData;
