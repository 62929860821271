import CmDownStreamCh from "../cable-modem/downstream/CmDownStreamCh";
import CmUpStreamCh from "../cable-modem/upstream/CmUpStreamCh";
import AppLabels from "../../constants/App_Labels";
import CmNetworkInfo from "../cable-modem/CmNetworkInfo";
import CMSummary from "../cable-modem/CmSummary";
import IPTVDeviceInfo from "../iptv/IPTVDeviceInfo";
import IPTVNetworkInfo from "../iptv/IPTVNetworkInfo";
import IPTVEthernetInfo from "../iptv/IPTVEthernetInfo";
import IPTVWIFIInfo from "../iptv/IPTVWIFIInfo";
import IHPDeviceInfo from "../homephone/IHPDeviceInfo";
import RHPdocsisInfo from "../homephone/RHPdocsisInfo";
import ONTDetails from "../ont/ONTDetails";
import ODUDetails from "../odu/ODUDetails";
import ODUWirelessInfo from "../odu/ODUWirelessInfo";
import STBDeviceInfo from "../stb/STBDeviceInfo";
import STBDocsisInfo from "../stb/STBDocsisInfo";
import CertiExceptionCont from "./CertiExceptionCont";
import CMInternetInfo from "../cable-modem/CMInternetInfo";
import ASTBDetails from "../astb/ASTBDetails";
import CMSpeedTestInfo from "../cable-modem/CMSpeedTestInfo";
import { GetPrecheckDiagData } from "../../get-api/GetResponse";
import CmDSPrechChannel from "../cable-modem/precheck/CmDSPrechChannel";
import CmUSPrechChannel from "../cable-modem/precheck/CmUSPrechChannel";

const CertiDiagDetails = (props: any) => {
  const { selectedDevice, precheckDevice, selectedSA, deviceDetails } = props;
  let stbDevices = ["STB", "DESTB", "GSTB", "HSTB", "IPSTB"];
  const labels = AppLabels();
  const precheckDiagnosticData = GetPrecheckDiagData().precheckDiagnosticData;
  // Double check this before release
  const precheckSelectedDevice = precheckDevice || precheckDiagnosticData?.data?.find(
    (d: any) =>
      d?.serialNumber === selectedDevice?.serialNumber ||
      d?.macAddress === selectedDevice?.macAddress
  );
  const precheckSeleDeviceDiag = precheckSelectedDevice?.diagResult;

  return (
    <div className="cmSummaryCont">
      {selectedDevice.deviceCategory === "CM" ? (
        <>
          <CertiExceptionCont
            dataObj={deviceDetails?.data?.summary}
            sectionName="CM Summary"
            errorDeviceSkip={labels.errorDeviceSkip}
          >
            <CMInternetInfo
              selectedSA={selectedSA}
              docsisLoading={false}
              cmDocsisInfo={deviceDetails?.data?.docsis}
            />
          </CertiExceptionCont>
          {/* <Divider marginVertical={15} color="none" />  */}
          <CMSpeedTestInfo macAddress={selectedDevice?.macAddress} />
          <CertiExceptionCont
            dataObj={deviceDetails?.data?.summary}
            sectionName="CM Summary"
            errorDeviceSkip={labels.errorDeviceSkip}
          >
            <CmNetworkInfo
              cmSummarydata={deviceDetails?.data?.summary}
              cmNodeInfo={deviceDetails?.data?.nodeInfo}
            />
          </CertiExceptionCont>
          <CertiExceptionCont
            dataObj={deviceDetails?.data?.docsis}
            sectionName="CM Docsis"
            errorDeviceSkip={labels.errorDeviceSkip}
          >
            <CmDSPrechChannel
              cmDocsisInfo={deviceDetails?.data?.docsis}
              preCmDocsisInfo={precheckSeleDeviceDiag?.docsis}
            />
            <CmUSPrechChannel
              cmDocsisInfo={deviceDetails?.data?.docsis}
              preCmDocsisInfo={precheckSeleDeviceDiag?.docsis}
            />
          </CertiExceptionCont>
          <CertiExceptionCont
            dataObj={deviceDetails?.data?.summary}
            sectionName="CM Summary"
            errorDeviceSkip={labels.errorDeviceSkip}
          >
            <CMSummary cmSummarydata={deviceDetails?.data?.summary} />
          </CertiExceptionCont>
        </>
      ) : selectedDevice.deviceCategory === "IPTV" ? (
        <>
          <CertiExceptionCont
            dataObj={deviceDetails?.data?.network}
            sectionName="IPTV Network Info"
            errorDeviceSkip={labels.errorDeviceSkip}
          >
            <IPTVNetworkInfo networkData={deviceDetails?.data?.network} />
          </CertiExceptionCont>
          {deviceDetails?.data?.hasOwnProperty("ethernet") ? (
            <CertiExceptionCont
              dataObj={deviceDetails?.data?.ethernet}
              sectionName="IPTV Ethernet"
              errorDeviceSkip={labels.errorDeviceSkip}
            >
              <IPTVEthernetInfo ethernetData={deviceDetails?.data?.ethernet} />
            </CertiExceptionCont>
          ) : deviceDetails?.data?.hasOwnProperty("wifi") ? (
            <CertiExceptionCont
              dataObj={deviceDetails?.data?.wifi}
              sectionName="IPTV WiFi"
              errorDeviceSkip={labels.errorDeviceSkip}
            >
              <IPTVWIFIInfo wifiData={deviceDetails?.data?.wifi} />
            </CertiExceptionCont>
          ) : (
            <></>
          )}
          <CertiExceptionCont
            dataObj={deviceDetails?.data?.device}
            sectionName="IPTV Summary"
            errorDeviceSkip={labels.errorDeviceSkip}
          >
            <IPTVDeviceInfo deviceData={deviceDetails?.data?.device} />
          </CertiExceptionCont>
        </>
      ) : selectedDevice.deviceCategory === "IEMTA" ? (
        <CertiExceptionCont
          dataObj={deviceDetails?.data?.summary}
          sectionName="EMTA Summary"
          errorDeviceSkip={labels.errorDeviceSkip}
        >
          <IHPDeviceInfo summaryData={deviceDetails?.data?.summary} />
        </CertiExceptionCont>
      ) : selectedDevice.deviceCategory === "EMTA" ? (
        <>
          <CertiExceptionCont
            dataObj={deviceDetails?.data?.docsis}
            sectionName="EMTA Docsis"
            errorDeviceSkip={labels.errorDeviceSkip}
          >
            <RHPdocsisInfo
              docsisInfo={deviceDetails?.data?.docsis}
              docsisError={undefined}
            />
          </CertiExceptionCont>
          <CertiExceptionCont
            dataObj={deviceDetails?.data?.summary}
            sectionName="EMTA Summary"
            errorDeviceSkip={labels.errorDeviceSkip}
          >
            <IHPDeviceInfo summaryData={deviceDetails?.data?.summary} />
          </CertiExceptionCont>
        </>
      ) : selectedDevice.deviceCategory === "ONT" ? (
        <CertiExceptionCont
          dataObj={deviceDetails?.data?.summary}
          sectionName="ONT Summary"
          errorDeviceSkip={labels.errorDeviceSkip}
        >
          <ONTDetails deviceData={deviceDetails?.data?.summary} />
        </CertiExceptionCont>
      ) : selectedDevice.deviceCategory === "ODU" ? (
        <>
          <CertiExceptionCont
            dataObj={deviceDetails?.data?.summary}
            sectionName="ODU Summary"
            errorDeviceSkip={labels.errorDeviceSkip}
          >
            <ODUDetails
              selDevice={selectedDevice}
              deviceData={deviceDetails?.data?.summary}
            />
          </CertiExceptionCont>
          <CertiExceptionCont
            dataObj={deviceDetails?.data?.wirelessInfo}
            sectionName="ODU Wireless Info"
            errorDeviceSkip={labels.errorDeviceSkip}
          >
            <ODUWirelessInfo deviceData={deviceDetails?.data?.wirelessInfo} />
          </CertiExceptionCont>
        </>
      ) : stbDevices?.indexOf(selectedDevice.deviceCategory) > -1 ? (
        <>
          <CertiExceptionCont
            dataObj={deviceDetails?.data?.summary}
            sectionName="STB Summary"
            errorDeviceSkip={labels.errorDeviceSkip}
          >
            <STBDeviceInfo deviceData={deviceDetails?.data?.summary} />
          </CertiExceptionCont>
          <CertiExceptionCont
            dataObj={deviceDetails?.data?.docsis}
            sectionName="STB Docsis"
            errorDeviceSkip={labels.errorDeviceSkip}
          >
            <STBDocsisInfo deviceData={deviceDetails?.data?.docsis} />
          </CertiExceptionCont>
        </>
      ) : selectedDevice.deviceCategory === "ASTB" ? (
        <CertiExceptionCont
          dataObj={deviceDetails?.data?.summary}
          sectionName="ASTB Summary"
          errorDeviceSkip={labels.errorDeviceSkip}
        >
          <ASTBDetails deviceData={deviceDetails?.data?.summary} />
        </CertiExceptionCont>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CertiDiagDetails;
