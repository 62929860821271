import { NodeInfoDetailsState } from "../../../states/node/nodeinfo/NodeInfoDetailsState";
import {
  NODE_INFO_DETAILS_BEGIN,
  NODE_INFO_DETAILS_SUCCESS,
  NODE_INFO_DETAILS_FAILURE,
  NODE_INFO_RESET_STATUS,
  SET_NODE_SEARCH,
  RESET_NODE_INFO_STATE,
  NodeInfoDetailsActionTypes,
} from "../../../types/node/nodeinfo/NodeInfoDetails";

const initialState: NodeInfoDetailsState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
  searchType: "",
};
const NodeInfoDetailsReducer = (
  state = initialState,
  action: NodeInfoDetailsActionTypes
): NodeInfoDetailsState => {
  switch (action.type) {
    case NODE_INFO_DETAILS_BEGIN:
      return {
        ...state,
        isLoading: true,
      };
    case NODE_INFO_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };

    case NODE_INFO_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };
    case NODE_INFO_RESET_STATUS:
      return {
        ...state,
        status: "",
      };
    case SET_NODE_SEARCH:
      return {
        ...state,
        searchType: action.payload,
      };
    case RESET_NODE_INFO_STATE:
      return {
        ...state,
        isLoading: false,
        data: undefined,
        error: undefined,
        status: "",
      };
    default:
      return state;
  }
};
export default NodeInfoDetailsReducer;
