import React from "react";
import { useEffect } from "react";

interface Props {
  gMap?: google.maps.Map;
  mduFiberCableData?: any;
}

const MduFiberCable = React.memo(({ gMap, mduFiberCableData }: Props) => {
  useEffect(() => {
    if (
      mduFiberCableData !== undefined &&
      mduFiberCableData?.features?.length > 0
    ) {
      gMap?.data.addGeoJson(mduFiberCableData);
    }
  }, [gMap, mduFiberCableData]);
  return <></>;
});

export default MduFiberCable;
