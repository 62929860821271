import DataDisplayTable from "../reusable/DataDisplayTable";
import CustomAccordion from "../reusable/CustomAccordion";
import AppLabels from "../../constants/App_Labels";
import ExceptionBox from "../reusable/ExceptionBox";
import { isNull } from "../reusable/Util";

const IPTVNetworkInfo = (props: any) => {
  const { networkData, iptvNetworkError, refreshData } = props;
  const labels = AppLabels();
  return (
    <CustomAccordion
      accTitle={labels.NETWORK.toUpperCase()}
      accBodyElement={
        <>
          {networkData !== undefined &&
          !networkData?.hasOwnProperty("errorCode") ? (
            <DataDisplayTable
              dataObject={networkData}
              tableName="iptvNetworkInfo"
            />
          ) : (
            <div className="diagErrorCont">
              <ExceptionBox
                exBoxType="error"
                errSecName="IPTV Network"
                errCode={isNull(iptvNetworkError?.errorCode)}
                srcSystem={
                  iptvNetworkError?.hasOwnProperty("sourceSystem")
                    ? iptvNetworkError?.sourceSystem
                    : "Request"
                }
                ErrorMSG={isNull(iptvNetworkError?.errorMessage)}
                primaryBtn={true}
                secBtn={true}
                handleRefresh={refreshData}
              />
            </div>
          )}
        </>
      }
    />
  );
};

export default IPTVNetworkInfo;
