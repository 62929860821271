import { useEffect, useState } from "react";
import { Divider } from "@material-ui/core";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

import { CERTIFICATION_CONFIG } from "../../constants/CertificationsConfig";
import { changeTimeFormat, isNull } from "../../components/reusable/Util";
import { DeviceDiag } from "../../components/diagnostics/DiagnosticsTab";
import { CWMonitoringTracer } from "../../store/actions/account/diagnostics/cm/CWMonitoringAction";
import HSICertiDetails from "./HSICertiDetails";

const ViewHSICertificationPage = (props: any) => {
  const { certiData } = props;
  const dispatch = useDispatch();
  const { accType, selectedSA } = useSelector(
    (state: any) => ({
      accType: state.AccountState.ActiveAccountDetails.accountType,
      selectedSA: state.AccountState.ActiveAccountDetails.selectedSA,
    }),
    shallowEqual
  );
  let certiConfig: any = [];
  if (accType === "DOCSIS") certiConfig = CERTIFICATION_CONFIG.DOCSIS;
  else if (accType === "GPON") certiConfig = CERTIFICATION_CONFIG.GPON;
  else if (accType === "FWA") certiConfig = CERTIFICATION_CONFIG.FWA;
  let hsiCertiData = certiData?.data?.hsiCertificate;
  let techData = certiData?.data?.technician;
  let certiDevices = hsiCertiData?.devices;
  let precheckDevices = hsiCertiData?.precheckDevices;
  let skippedDevices = hsiCertiData?.summary?.skipped?.devices;
  let remarkDetails = certiData?.data?.remark;
  const [selDValue, setSelDValue] = useState<number | undefined>(0);
  const [selDevice, setSelDevice] = useState<any>();
  const [deviceDiagActions, setDeviceDiagActions] = useState<DeviceDiag[]>([]);
  useEffect(() => {
    dispatch(CWMonitoringTracer(true));
  }, []);
  useEffect(() => {
    if (
      selDValue !== undefined &&
      certiDevices !== undefined &&
      certiDevices?.length !== 0
    ) {
      setSelDevice(certiDevices[selDValue]);
      let deviceDiag = getDeviceDiag(certiDevices[selDValue].deviceCategory);
      setDeviceDiagActions(deviceDiag);
    }
  }, [selDValue]);
  const getDeviceDiag = (deviceName: string): DeviceDiag[] => {
    let diagArr: DeviceDiag[] = [];
    certiConfig?.map((d: any) => {
      if (
        deviceName === d.category &&
        d?.hasOwnProperty("deviceCertification")
      ) {
        diagArr = d.deviceCertification;
      }
    });
    return diagArr;
  };

  return (
    <div className="hsiCertiCont">
      <div className="viewCertiInfoCont">
        <section>
          <h4>Whole Home Certification</h4>
          <p>
            <span>Test Type: </span>
            <span>
              {certiData?.data?.testType === "WHT"
                ? "Whole Home Test"
                : certiData?.data?.testType === "DNS"
                  ? "Drop & Stay Test"
                  : "N/A"}
            </span>
          </p>
          <p>
            <span>Generated by: </span>
            <span>
              {techData?.lanId} ({techData?.techId})
            </span>
          </p>
          <p>
            <span>Generated on: </span>
            <span>
              {changeTimeFormat(isNull(certiData?.data?.updatedDate))}
            </span>
          </p>
          <p>
            <span>Account Number: </span>
            <span>{isNull(certiData?.data?.accountNumber)}</span>
          </p>
          <p>
            <span>Work Order ID: </span>
            <span>{certiData?.data?.workOrderId}</span>
          </p>
        </section>
        <Divider orientation="vertical" style={{ height: "170px" }} />
        <section className="deviceStatusCount">
          <h5>Whole Home Summary</h5>
          <div>
            <p className="passCount">
              <span>
                {hsiCertiData?.summary?.passed?.count +
                  hsiCertiData?.summary?.marginalPass?.count <
                  10
                  ? "0" +
                  (hsiCertiData?.summary?.passed?.count +
                    hsiCertiData?.summary?.marginalPass?.count)
                  : hsiCertiData?.summary?.passed?.count +
                  hsiCertiData?.summary?.marginalPass?.count}
              </span>
              <span>Passed</span>
            </p>
            <p className="failCount">
              <span>
                {hsiCertiData?.summary?.failed?.count < 10
                  ? "0" + hsiCertiData?.summary?.failed?.count
                  : hsiCertiData?.summary?.failed?.count}
              </span>
              <span>Failed</span>
            </p>
            <p className="skipCount">
              <span>
                {hsiCertiData?.summary?.skipped?.count < 10
                  ? "0" + hsiCertiData?.summary?.skipped?.count
                  : hsiCertiData?.summary?.skipped?.count}
              </span>
              <span>Skipped</span>
            </p>
          </div>
        </section>
      </div>
      <HSICertiDetails
        certiDevices={certiDevices}
        precheckDevices={precheckDevices}
        hsiCertiData={hsiCertiData}
        certiConfig={certiConfig}
        selDValue={selDValue}
        isApplicable={hsiCertiData?.isApplicable}
        skippedDevices={skippedDevices}
        setSelDValue={setSelDValue}
        selDevice={selDevice}
        selectedSA={selectedSA}
        deviceDiagActions={deviceDiagActions}
        remarkDetails={remarkDetails}
      />
    </div>
  );
};

export default ViewHSICertificationPage;
