import { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { FaCheckCircle } from "react-icons/fa";

import AppLabels from "../../constants/App_Labels";
import { getDeviceStatusFlag, isNull } from "../reusable/Util";
import CertiDiagDetails from "./CertiDiagDetails";
import ViewCertiDiagDetails from "./ViewCertiDiagDetails";
import FailedAttributesCert from "../FailedAttributes/FailedAttributesCert";
import FailedAttributesCertSum from "../FailedAttributes/FailedAttributesCertSum";
import ErrorIcon from "../../resources/images/icons/ErrorIcon.svg";
import RefreshGif from "../../resources/images/icons/refresh.gif";
import CodeWordMonitor from "../codewords/CodeWordMonitor";
import ViewCodewordSteps from "../certification/certificationSteps/ViewCodewordSteps";
import ExceptionBox from "../reusable/ExceptionBox";

const CertiDiagnostics = (props: any) => {
  const {
    selectedDevice,
    hsiCertiData,
    selectedSA,
    deviceDiagActions,
    deviceDataObj,
    seleDeviceDiag,
    precheckDevice,
    selDiagAction,
    handleActionClick,
  } = props;
  const {
    cwMonitorLoading,
    cwMonitorStatus,
    failedAttributes,
    codewords,
  } = useSelector(
    (state: any) => ({
      cwMonitorLoading: state.AccountState.cwMonitorInfo.overallLoading,
      cwMonitorStatus: state.AccountState.cwMonitorInfo.status,
      failedAttributes:
        state.AccountState.ActiveAccountDetails?.failedAttributes,
      codewords: state.AccountState.ActiveAccountDetails?.codeword,
    }),
    shallowEqual
  );
  const labels = AppLabels();
  const [deviceDetails, setDeviceDetails] = useState();
  const [deviceStatus, setDeviceStatus] = useState(
    selectedDevice?.deviceStatus
  );
  const [key, setKey] = useState(deviceDiagActions?.[0]?.tab);

  useEffect(() => {
    if (selectedDevice.deviceCategory === "CM") {
      if (
        (selectedDevice.deviceStatus === "pass" ||
          selectedDevice.deviceStatus?.toUpperCase() === "MARGINAL PASS") &&
        failedAttributes !== undefined &&
        failedAttributes?.length > 0
      ) {
        setDeviceStatus("fail");
      } else {
        setDeviceStatus(selectedDevice.deviceStatus);
      }
    } else {
      setDeviceStatus(selectedDevice.deviceStatus);
    }
  }, [selectedDevice.deviceStatus, cwMonitorLoading]);

  useEffect(() => {
    if (seleDeviceDiag === undefined) {
      let certiDeviceData =
        selectedDevice.deviceCategory !== "IEMTA"
          ? deviceDataObj.filter(
            (device: any) => device.deviceKey === selectedDevice.serialNumber
          )
          : deviceDataObj.filter(
            (device: any) =>
              device.deviceKey === "IEMTA" + selectedDevice.serialNumber
          );
      setDeviceDetails(certiDeviceData?.[0]);
    }
  }, [selectedDevice]);
  useEffect(() => {
    if (selDiagAction === "CM" || selDiagAction === "CW") {
      setKey(selDiagAction);
    }
  }, [selDiagAction]);

  return (
    <div className="detailDiagCont">
      <section className="deviceData">
        <p>
          <span>{labels.MODEL.toUpperCase()} : </span>
          <span>{isNull(selectedDevice.deviceModel)}</span>
        </p>
        <p className="deviceOverallStat">
          <span>{labels.STATUS.toUpperCase()} : </span>
          {getDeviceStatusFlag(
            selectedDevice?.hasOwnProperty("deviceStatus")
              ? deviceStatus
              : selectedDevice.status
          )}
        </p>
        <p>
          <span>{labels.SERIAL_NUMBER.toUpperCase()} : </span>
          <span>{isNull(selectedDevice.serialNumber)}</span>
        </p>
        <div>
          {deviceDetails !== undefined &&
            deviceDataObj &&
            selectedDevice?.hasOwnProperty("deviceName") &&
            ((selectedDevice?.hasOwnProperty("deviceStatus") &&
              (selectedDevice?.deviceStatus === "fail" ||
                selectedDevice?.deviceStatus?.toUpperCase() ===
                "MARGINAL PASS")) ||
              (selectedDevice?.hasOwnProperty("status") &&
                (selectedDevice?.status === "fail" ||
                  selectedDevice?.status?.toUpperCase() === "MARGINAL PASS")) ||
              failedAttributes !== undefined) ? (
            <FailedAttributesCert
              selectedDevice={selectedDevice}
              selectedSA={selectedSA}
              deviceDetails={deviceDataObj}
            />
          ) : seleDeviceDiag !== undefined &&
            seleDeviceDiag &&
            ((selectedDevice?.hasOwnProperty("deviceStatus") &&
              (selectedDevice?.deviceStatus === "fail" ||
                selectedDevice?.deviceStatus?.toUpperCase() ===
                "MARGINAL PASS")) ||
              (selectedDevice?.hasOwnProperty("status") &&
                (selectedDevice?.status === "fail" ||
                  selectedDevice?.status?.toUpperCase() === "MARGINAL PASS")) ||
              hsiCertiData?.failedCodewords !== undefined) ? (
            <FailedAttributesCertSum
              selectedDevice={selectedDevice}
              selectedSA={selectedSA}
              deviceDetails={seleDeviceDiag}
            />
          ) : (
            <></>
          )}
        </div>
      </section>
      <section className="detailDiagTabs">
        <Tabs
          id="controlled-tab-example"
          activeKey={
            deviceDiagActions?.[0]?.tab === "CM"
              ? key
              : deviceDiagActions?.[0]?.tab
          }
          onSelect={(k: any) => handleActionClick(k)}
        >
          <Tab
            eventKey={deviceDiagActions?.[0]?.tab}
            title={deviceDiagActions?.[0]?.label?.toUpperCase()}
          >
            {deviceDetails !== undefined ? ( // live diagnostic data
              <CertiDiagDetails
                selectedDevice={selectedDevice}
                precheckDevice={precheckDevice}
                selectedSA={selectedSA}
                deviceDetails={deviceDetails}
              />
            ) : seleDeviceDiag !== undefined ? ( // saved diagnostic data
              <ViewCertiDiagDetails
                selectedDevice={selectedDevice}
                precheckDevice={precheckDevice}
                selectedSA={selectedSA}
                seleDeviceDiag={seleDeviceDiag}
                speedTestData={hsiCertiData?.cmSpeedTest}
              />
            ) : (
              <></>
            )}
          </Tab>
          {deviceDiagActions?.[0]?.label?.toUpperCase() === "CABLE MODEM" && (
            <Tab
              eventKey="CW"
              title={
                <span className="tabStatusCont">
                  CODEWORD MONITORING
                  {!cwMonitorLoading && cwMonitorStatus === "OK" ? (
                    <FaCheckCircle color="#00985f" />
                  ) : !cwMonitorLoading && cwMonitorStatus === "ERROR" ? (
                    <img style={{ height: "16px" }} src={ErrorIcon} />
                  ) : cwMonitorLoading ? (
                    <img style={{ height: "20px" }} src={RefreshGif} />
                  ) : (
                    <></>
                  )}
                </span>
              }
            >
              {(seleDeviceDiag !== undefined &&
                hsiCertiData !== undefined &&
                hsiCertiData?.codewords !== undefined) ||
                (codewords !== undefined &&
                  codewords !== "" &&
                  !cwMonitorLoading) ? (
                <ViewCodewordSteps
                  hsiCertiData={
                    hsiCertiData !== undefined ? hsiCertiData : codewords
                  }
                  seleDevice={selectedDevice}
                />
              ) : codewords === undefined && !cwMonitorLoading ? (
                <div className="diagErrorCont">
                  <ExceptionBox
                    headerText="No Data to display in Codeword Monitoring."
                    bodyText="Please try refreshing."
                    primaryBtn={false}
                    secBtn={false}
                  />
                </div>
              ) : (
                <CodeWordMonitor seleDevice={selectedDevice} />
              )}
            </Tab>
          )}
        </Tabs>
      </section>
    </div>
  );
};

export default CertiDiagnostics;
