import {
    SAVE_EMAIL_FORM_SUCCESS,
    SAVE_EMAIL_FORM_BEGIN,
    SAVE_EMAIL_FORM_FAILURE,
    SAVE_EMAIL_FORM_RESET,
    NewEmailFormActionTypes,
  } from "../../../types/account/forms/EmailFormAudit";
  
  export function SaveEmailFormSuccess(activeForm: any): NewEmailFormActionTypes {
    return {
      type: SAVE_EMAIL_FORM_SUCCESS,
      payload: activeForm,
    };
  }
  
  export function SaveEmailFormBegin(): NewEmailFormActionTypes {
    return {
      type: SAVE_EMAIL_FORM_BEGIN,
    };
  }
  
  export function SaveEmailFormFailure(error: Error): NewEmailFormActionTypes {
    return {
      type: SAVE_EMAIL_FORM_FAILURE,
      payload: error,
    };
  }
  
  export function SaveEmailFormReset(): NewEmailFormActionTypes {
    return {
      type: SAVE_EMAIL_FORM_RESET,
    };
  }
  