import { useDispatch } from "react-redux";
import { Paper } from "@material-ui/core";

import "../../../design-tokens/rpd-page/RPDPage.scss";
import AppLabels from "../../../constants/App_Labels";
import RPDClient from "../../../network/httpclient/rpd/RPDClient";
import CustomTextItem from "../../reusable/CustomTextItem";
import { TAF_ORDER_CONST } from "../../../constants/Global";
import {
  formatMacAddress,
  isMacAddressValid,
  formatRPDMacAddress,
  formathexMacAddress,
  getDeviceType,
} from "../../reusable/Util";
import { GetRPDSWUpgradeMacInfo } from "../../../get-api/GetResponse";

const RPDSWUpgradeMacDetails = (props: any) => {
  const {
    activationData,
    isActivationLoading,
    rpdMac,
    macDomain,
    cmtsPort,
    cmtsName,
    mailId,
    relatedId,
    isNpt,
    device,
    swVersion,
    phub,
  } = props;
  const dispatch = useDispatch();
  const labels = AppLabels();
  const rpdSWUpgradeMacInfo = GetRPDSWUpgradeMacInfo();

  const createInputActivationObject = (activationData: any): any => {
    if (relatedId?.toLowerCase().startsWith("npt")) {
      let inputObject = {
        "RPD-MAC": formathexMacAddress(rpdMac),
        cmts_port: cmtsPort,
        macDomain: macDomain,
        hostname: cmtsName,
        "MAIL-ID": mailId,
        related_id: relatedId,
        is_npt_id: 1,
      };
      return inputObject;
    } else {
      let inputObject = {
        "RPD-MAC": formathexMacAddress(rpdMac),
        cmts_port: cmtsPort,
        macDomain: macDomain,
        hostname: cmtsName,
        "MAIL-ID": mailId,
        related_id: relatedId,
        is_npt_id: 0,
      };
      return inputObject;
    }
  };

  const handleApplyActivate = (deviceType: string) => {
    if (getDeviceType(device) === "DA2000") {
      dispatch(
        RPDClient.createSWUpgradeMac(
          TAF_ORDER_CONST.rpdSWUpgradeBotName,
          TAF_ORDER_CONST.rpdSWUpgradeSuiteName,
          createInputActivationObject(activationData)
        )
      );
    } else if (getDeviceType(device) === "DA2200") {
      dispatch(
        RPDClient.createSWUpgradeDA2200Mac(
          TAF_ORDER_CONST.rpdSWUpgradeDA2200BotName,
          TAF_ORDER_CONST.rpdSWUpgradeDA2200SuiteName,
          createInputActivationObject(activationData)
        )
      );
    }
  };

  return (
    <>
      <div className="certiTableCont">
        <Paper className="deviceTablePaper">
          <div className="activationInputContainer">
            <div className="filterRow">
              <CustomTextItem
                labelText={labels.MacAddress}
                placeholderText={labels.typeMacAddress}
                valueText={rpdMac}
              />
              <CustomTextItem
                labelText={labels.CMTSName}
                placeholderText={labels.typeCMTSName}
                valueText={cmtsName}
              />
              <CustomTextItem
                labelText={labels.MacDomain}
                placeholderText={labels.typeMacDomain}
                valueText={macDomain}
              />
              <CustomTextItem
                labelText={labels.CMTSPort}
                placeholderText={labels.typeCMTSSlot}
                valueText={cmtsPort}
              />
              {device !== null && (
                <CustomTextItem
                  labelText={labels.deviceType}
                  placeholderText={labels.deviceType}
                  valueText={getDeviceType(device)}
                />
              )}

              <div className="phubContainer">
                <span className="cardContentLabel">{labels.PHUB}</span>
                <br />
                <span className="cardContentText">{phub}</span>
              </div>

              {swVersion === null ||
              swVersion === undefined ||
              swVersion === "" ? (
                <div className="swVersionContainer">
                  <span className="cardContentLabel">
                    {labels.rpdSwVersion}
                  </span>
                  <br />
                  <span className="cardContentText">CMTS connection failed</span>
                </div>
              ) : (
                <div className="swVersionContainer">
                  <span className="cardContentLabel">
                    {labels.rpdSwVersion}
                  </span>
                  <br />
                  <span className="cardContentText">{swVersion}</span>
                </div>
              )}
            </div>
          </div>

          <div className="filterRootContainer" style={{ marginTop: "10px" }}>
            <button
              disabled={
                isActivationLoading ||
                !(device === "casa-rpd-100" || device === "casa-rpd-200")
              }
              className="applyFilersBtn"
              onClick={() => handleApplyActivate(device)}
            >
              {labels.SUBMIT}
            </button>
          </div>
        </Paper>
      </div>
    </>
  );
};

export default RPDSWUpgradeMacDetails;
