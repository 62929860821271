import { Chip, Tooltip } from "@material-ui/core";

import ExceptionHandlerWrapper from "../../reusable/ExceptionHandlerWrapper";
import { TCTypes } from "../../reusable/table/Table";
import {
  GetGoogleMaps,
  GetMapDrawerProps,
  useRedlineStates,
} from "../../../util/reduxFunctions/getTopologyState";
import { paths } from "../../../../routes/Routes";
import { GetTechInfo } from "../../../../get-api/GetResponse";
import { readISODate } from "../../../util/utilFuncs";
import PaginatedTable from "../../reusable/table/PaginatedTable";

export default function RedlinesTable() {
  const { drawerView } = GetMapDrawerProps();
  const { redlinesData, redlinesIsLoading, redlinesStatus, redlinesError } =
    useRedlineStates();
  const { currentMapCenter: latLng } = GetGoogleMaps();
  const { data: techData } = GetTechInfo();
  const TW = drawerView === "fullScreen" ? "100%" : "23.5vw";
  const TH =
    drawerView === "fullScreen" ? "calc(100vh - 310px)" : "calc(100vh - 470px)";

  const tableData = redlinesData?.map((d) => ({
    ...d,
    id: d.trackorKey,
    trackorKey: (
      <a className="idLink" onClick={() => onMarkerPress(d.trackorKey)}>
        {d.trackorKey}
      </a>
    ),
    createdDate: readISODate(d.createdDate),
    projectDesc:
      d?.projectDesc &&
      (d?.projectDesc?.length > 50 ? (
        <Tooltip title={d?.projectDesc}>
          <span>{d?.projectDesc?.substring(0, 47) + "..."}</span>
        </Tooltip>
      ) : (
        <span>{d?.projectDesc}</span>
      )),
    status: d?.status && <CChip label={d?.status} />,
  }));

  const tableColumns = [
    { th: "Tracker Key", tdName: "trackorKey", tdStyle: { minWidth: 100 } },
    { th: "Address", tdName: "address", tdStyle: { minWidth: 150 } },
    {
      th: "Status",
      tdName: "status",
      tdStyle: { textAlign: "center", paddingTop: 3 },
    },
    { th: "Created Date", tdName: "createdDate", tdStyle: { minWidth: 100 } },
    { th: "Tech", tdName: "lanId", tdStyle: { minWidth: 120 } },
    { th: "Node ID", tdName: "nodeId", tdStyle: { minWidth: 70 } },
    { th: "Project Desc", tdName: "projectDesc", tdStyle: { minWidth: 300 } },
    { th: "Market ID", tdName: "marketId", tdStyle: { minWidth: 100 } },
    {
      th: "Tracker Class ID",
      tdName: "trackorClassId",
      tdStyle: { minWidth: 100 },
    },
    { th: "Tracker ID", tdName: "trackorId", tdStyle: { minWidth: 90 } },
  ] as TCTypes[];

  const onMarkerPress = (trackerKey: string) => {
    const url = window?.open(`/#` + paths.redline(trackerKey));
    url?.addEventListener("load", () => {
      url.document.title = `redline: ${trackerKey}`;
      url.postMessage({ latLng, techData }, url.origin);
    });
  };

  return (
    <ExceptionHandlerWrapper
      data={tableData}
      isLoading={redlinesIsLoading}
      status={redlinesStatus}
      error={redlinesError}
    >
      {tableData && (
        <PaginatedTable
          title={"Redlines"}
          data={tableData}
          columns={tableColumns}
          tableStyle={{ maxWidth: TW, maxHeight: TH }}
          showExpandBtn={false}
          dataPerScreen={200}
        />
      )}
    </ExceptionHandlerWrapper>
  );
}

/**Custom chip */
export const CChip = ({ label }: { label: string }) => {
  return (
    <Chip
      label={label}
      variant="outlined"
      size="small"
      style={{
        color: getChipColor(label),
        borderColor: getChipColor(label),
        borderRadius: 7,
      }}
    />
  );
};

const getChipColor = (value: string) => {
  switch (value.toLowerCase()) {
    case "active":
      return `var(--success)`;
    case "complete":
      return `var(--info)`;
    case "on-hold":
      return `var(--warning)`;
    case "cancelled":
      return `var(--error)`;
    case "inactive":
      return `var(--error)`;
    case "not viable":
      return `var(--error)`;
    default:
      return `var(--grey)`;
  }
};
