import { Menu, MenuItem } from "@material-ui/core";
import { useEffect, useState } from "react";
import { BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ConfirmPopUp from "../reusable/ConfirmPopUp";
import AppLabels from "../../constants/App_Labels";
import { MULTI_DEVICE_ACTION_CONFIG } from "../../constants/DeviceActionsConfig";
import { formatCamelCase } from "../reusable/Util";
import MultiResponseSnackBar from "../reusable/MultiResponseSnackBar";
import { ResetZapSTB } from "../../store/actions/account/deviceActions/ZapAllSTBAction";
import DeviceActionClient from "../../network/httpclient/deviceActions/DeviceActionClient";

const MultiDeviceAction = (props: any) => {
  const { menuType } = props;
  const { sortedDevices, data, zapStbInfo } = useSelector(
    (state: any) => ({
      sortedDevices: state.AccountState.ActiveAccountDetails.sortedDevices,
      data: state.AccountState.ActiveAccountDetails.data,
      zapStbInfo: state.AccountState.zapAllStbInfo.stbDevices,
    }),
    shallowEqual
  );
  let stbDevices = ["STB", "DESTB", "GSTB", "HSTB", "IPSTB", "ASTB"];
  const labels = AppLabels();
  const dispatch = useDispatch();
  const [showConfirm, setShowConfirm] = useState(false);
  const [open, setOpen] = useState(false);
  const [passedDevices, setPassedDevices] = useState<any>([]);
  const [failedDevices, setFailedDevices] = useState<any>([]);
  const [requestType, setRequestType] = useState("default");
  const [responseType, setResponseType] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const handleConfirmClose = () => setShowConfirm(false);
  const [aOrigin, setaOrigin] = useState({
    vertical: 15,
    horizontal: 10,
  });
  useEffect(() => {
    if (menuType === "vertical") {
      setaOrigin({
        vertical: 40,
        horizontal: 15,
      });
    }
  }, [menuType]);
  const handleActionClick = (
    event: React.MouseEvent<HTMLElement>,
    actionType: string
  ) => {
    if (actionType === "zapAllSTB") {
      setRequestType(actionType);
      setShowConfirm(true);
      handleMenuClose();
    }
  };
  const handleConfirm = () => {
    if (requestType === "zapAllSTB") {
      performZapAllSTBs();
    }
  };
  const performZapAllSTBs = () => {
    if (sortedDevices && sortedDevices?.length !== 0) {
      let filteredSTBs = sortedDevices.filter(
        (d: any) => stbDevices?.indexOf(d.deviceCategory) > -1
      );
      filteredSTBs?.map((stb: any) => {
        dispatch(DeviceActionClient.zapSTB(data?.accountNumber, stb));
      });
    }
    setShowConfirm(false);
  };
  useEffect(() => {
    if (zapStbInfo !== undefined && zapStbInfo?.length !== 0) {
      let zapInprogressCount = 0;
      let passDevices: any = [];
      let failDevices: any = [];
      zapStbInfo?.map((stb: any) => {
        if (stb?.requestStatus === "pass") {
          passDevices.push(stb.deviceKey);
        } else if (stb?.requestStatus === "fail") {
          failDevices.push(stb.deviceKey);
        } else {
          zapInprogressCount += 1;
        }
      });
      if (zapInprogressCount === 0) {
        setPassedDevices(passDevices);
        setFailedDevices(failDevices);
      }
    }
  }, [zapStbInfo]);
  useEffect(() => {
    if (passedDevices?.length > 0 && failedDevices?.length === 0) {
      setResponseType("OK");
      setOpen(true);
    } else if (failedDevices?.length > 0 && passedDevices?.length === 0) {
      setResponseType("ERROR");
      setOpen(true);
    } else if (passedDevices?.length > 0 && failedDevices?.length > 0) {
      setResponseType("INFO");
      setOpen(true);
    }
  }, [passedDevices, failedDevices]);
  const handleClose = () => {
    if (requestType === "zapAllSTB") {
      dispatch(ResetZapSTB());
    }
    setOpen(false);
  };
  return (
    <div className="deviceActionCont">
      <button onClick={handleMenuClick} className={`deviceTabMenu-${menuType}`}>
        {menuType === "vertical" ? <BsThreeDotsVertical /> : <BsThreeDots />}
      </button>
      <Menu
        className="deviceActionMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={aOrigin}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {MULTI_DEVICE_ACTION_CONFIG?.length !== 0 ? (
          MULTI_DEVICE_ACTION_CONFIG?.map((a: any, i: number) => {
            return (
              <MenuItem
                key={i}
                className="runAction"
                onClick={(e) => handleActionClick(e, a.actionType)}
              >
                {a.actionName}
              </MenuItem>
            );
          })
        ) : (
          <>{labels.NO_ACTIONS}</>
        )}
      </Menu>
      <ConfirmPopUp
        showVal={showConfirm}
        headerText={labels.CAUTION.toUpperCase()}
        bodyHeadText={`${labels.YOU_ARE_TRYING_TO} ${formatCamelCase(
          requestType
        )} Devices.`}
        bodyText={labels.ARE_YOU_SURE_TO_PROCEED}
        cancelText={labels.CANCEL}
        confirmText={labels.PROCEED}
        handleConfirm={handleConfirm}
        handleClose={handleConfirmClose}
      />
      <MultiResponseSnackBar
        open={open}
        responseType={responseType}
        handleClose={handleClose}
        passedDevices={passedDevices}
        failedDevices={failedDevices}
      />
    </div>
  );
};

export default MultiDeviceAction;
