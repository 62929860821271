import { Divider } from "@material-ui/core";

import "../../../design-tokens/forms/PJI.scss";
import AppLabels from "../../../constants/App_Labels";
import formLabels from "../../../data/forms/PJI/formLabels";
import PreJobInspectionData from "../../../data/forms/PJI/PreJobInspectionsData";

export default function ViewPreJobInspectionScreen({ data }: any) {
  const formData = data?.formData;
  const labels = AppLabels() as any;
  const initialData = PreJobInspectionData();

  return (
    <div className="viewPJIContainer">
      <div>
        <label className="titleLabel">{labels.Work_Order_ID}:</label>
        <label className="textLabel">{data?.workOrderId}</label>
      </div>
      <div>
        <label className="titleLabel">{labels.Order_Type}:</label>
        <label className="textLabel">{formData?.orderType}</label>
      </div>
      <div>
        <label className="titleLabel">{labels.Safety_Level}:</label>
        <label className="textLabel">{formData?.riskOption}</label>
      </div>
      <Divider style={{ margin: "10px 0px 20px 0px" }} />
      <label className="titleLabel">{labels.Initial_Checks}:</label>
      {initialData?.initialCheckOriginalData?.map((e, i) => (
        <div key={i}>
          {formData?.initialChecks?.hasOwnProperty(e.key) && (
            <div className="hazardsCosntainer">
              <label className="textLabel">{e.label}:</label>
              <label className="textValue">
                &nbsp;&nbsp;&nbsp;{formData?.initialChecks?.[e.key]}
              </label>
            </div>
          )}
        </div>
      ))}
      {formData?.riskOption === formLabels.highRisk && (
        <div>
          <Divider style={{ margin: "10px 0px 20px 0px" }} />
          <label className="titleLabel">{labels.Hazards_Present}:</label>
          {initialData?.inspectionAreaInitialData?.map((e, i) => (
            <div key={i}>
              {formData?.hazardsPresent?.hasOwnProperty(e.key) && (
                <div className="hazardsCosntainer">
                  <label className="textLabel">{e.label}:</label>
                  <label className="textValue">
                    {formData?.hazardsPresent?.[e.key]}
                  </label>
                </div>
              )}
            </div>
          ))}
          {formData?.generalPPE?.length > 0 && (
            <div>
              <Divider style={{ margin: "10px 0px 20px 0px" }} />
              <div>
                <label className="titleLabel">
                  {labels.General_PPE_Requirements_Involves}:
                </label>
              </div>
              {formData?.generalPPE?.map((e: any, i: number) => (
                <div key={i}>
                  <label className="textLabel">{labels?.[e]}</label>
                </div>
              ))}
            </div>
          )}
          {formData?.hazardActivities?.WorkingFromHeights?.length > 0 ||
          formData?.hazardActivities?.LiveElectricity?.length > 0 ||
          formData?.hazardActivities?.ConfinedSpace?.length > 0 ? (
            <>
              <Divider style={{ margin: "10px 0px 20px 0px" }} />
              <label className="titleLabel">
                {labels.MED_TO_HIGH_HAZARD_ACTIVITIES}:
              </label>
              {formData?.hazardActivities?.WorkingFromHeights?.length > 0 && (
                <div>
                  <label className="titleLabel">
                    {labels.Working_From_Heights_Involves}:
                  </label>
                  {formData?.hazardActivities?.WorkingFromHeights?.map(
                    (e: any, i: number) => (
                      <div key={i}>
                        <label className="textLabel">{labels?.[e]}</label>
                      </div>
                    )
                  )}
                </div>
              )}
              {formData?.hazardActivities?.LiveElectricity?.length > 0 && (
                <div style={{ marginTop: 10 }}>
                  <label className="titleLabel">
                    {labels.Live_Electricity_Involves}:
                  </label>
                  {formData?.hazardActivities?.LiveElectricity?.map(
                    (e: any, i: number) => (
                      <div key={i}>
                        <label className="textLabel">{labels?.[e]}</label>
                      </div>
                    )
                  )}
                </div>
              )}
              {formData?.hazardActivities?.ConfinedSpace?.length > 0 && (
                <div style={{ marginTop: 10 }}>
                  <label className="titleLabel">
                    {labels.Confined_Space_Involves}:
                  </label>
                  {formData?.hazardActivities?.ConfinedSpace?.map(
                    (e: any, i: number) => (
                      <div key={i}>
                        <label className="textLabel">{labels?.[e]}</label>
                      </div>
                    )
                  )}
                </div>
              )}
              {formData?.hazardActivities?.Anchorage?.length > 0 && (
                <div style={{ marginTop: 10 }}>
                  <label className="titleLabel">
                    {labels.Anchorage_Involves}:
                  </label>
                  {formData?.hazardActivities?.Anchorage?.map(
                    (e: any, i: number) => (
                      <div key={i}>
                        <label className="textLabel">{labels?.[e]}</label>
                      </div>
                    )
                  )}
                </div>
              )}
            </>
          ) : null}
        </div>
      )}
      {Object.keys(formData?.comments).length !== 0 && (
        <>
          <Divider style={{ margin: "15px 0px 20px 0px" }} />
          <label className="titleLabel">{labels.COMMENTS}:</label>
          {initialData?.commentInitial?.map((e, i) => (
            <div key={i}>
              {formData?.comments?.hasOwnProperty(e.key) && (
                <div>
                  <label className="titleLabel"> {e.label}:</label>
                  <label className="textLabel">
                    &nbsp;{formData?.comments?.[e.key]}
                  </label>
                </div>
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );
}
