import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "topoGeneral",
  initialState: {
    activeMainSearchType: undefined,
    queryPonNode: undefined,
    smtSearchFlag: undefined,
  },
  reducers: {
    setActiveMainSearchType: (state, { payload }) => {
      state.activeMainSearchType = payload;
    },
    setActiveQueryPonNode: (state, { payload }) => {
      state.queryPonNode = payload;
    },
    setSmtSearchFlag: (state, { payload }) => {
      state.smtSearchFlag = payload;
    },
  },
});

export const {
  setActiveMainSearchType,
  setActiveQueryPonNode,
  setSmtSearchFlag,
} = slice.actions;
export default slice.reducer;
