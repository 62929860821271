import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { HttpClient } from "../HttpClient";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { NetworkConfig } from "../NetworkConfig";

import {
  ihpNumberDetailsBegin,
  ihpNumberDetailsSuccess,
  ihpNumberDetailsFailure,
} from "../../../store/slices/accountInfo/IhpNumberDetails";
import { TNInfo } from "../../../store/models/account/accountInfo/TNInfo";

export const useTNDetailsClient = () => {
  const dispatch = useDispatch();

  const getIHPNumberInfo = useCallback(
    async (phoneNum: string) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.NETWORK_SERVICE_URL + "diag/emta/telephone",
        params: { tn: phoneNum },
      };
      dispatch(ihpNumberDetailsBegin());

      const result = await HttpClient.get<TNInfo>(httpParameters)
        .then((ihpinfo) => {
          dispatch(ihpNumberDetailsSuccess(ihpinfo));
          return ihpinfo;
        })
        .catch((error) => {
          dispatch(ihpNumberDetailsFailure(error));
          return error;
        });
      return result;
    },
    [dispatch]
  );

  return {
    getIHPNumberInfo,
  };
};
