import { TowerView } from "../../../models/account/towerview/TowerView";
import {
  TOWER_VIEW_BEGIN,
  TOWER_VIEW_SUCCESS,
  TOWER_VIEW_FAILURE,
  TOWER_VIEW_RESET,
  TowerViewActionTypes,
} from "../../../types/account/towerview/TowerView";

export function towerViewBegin(): TowerViewActionTypes {
  return {
    type: TOWER_VIEW_BEGIN,
  };
}

export function towerViewSuccess(towerView: TowerView): TowerViewActionTypes {
  return {
    type: TOWER_VIEW_SUCCESS,
    payload: towerView,
  };
}

export function towerViewFailure(error: Error): TowerViewActionTypes {
  return {
    type: TOWER_VIEW_FAILURE,
    payload: error,
  };
}

export function resetTowerView(): TowerViewActionTypes {
  return {
    type: TOWER_VIEW_RESET,
  };
}
