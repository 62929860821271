import { IHPInsideWiring } from "../../../../models/account/diagnostics/ihp/IHPInsideWiring";
import {
  IHP_INSIDE_WIRING_BEGIN,
  IHP_INSIDE_WIRING_SUCCESS,
  IHP_INSIDE_WIRING_FAILURE,
  IhpInsideWiringActionTypes,
} from "../../../../types/account/diagnostics/ihp/IHPInsideWiring";

export function ihpInsideWiringBegin(): IhpInsideWiringActionTypes {
  return {
    type: IHP_INSIDE_WIRING_BEGIN,
  };
}

export function ihpInsideWiringSuccess(
  ihpInsideWiring: IHPInsideWiring
): IhpInsideWiringActionTypes {
  return {
    type: IHP_INSIDE_WIRING_SUCCESS,
    payload: ihpInsideWiring,
  };
}

export function ihpInsideWiringFailure(
  error: Error
): IhpInsideWiringActionTypes {
  return {
    type: IHP_INSIDE_WIRING_FAILURE,
    payload: error,
  };
}
