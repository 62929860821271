import { MultiDeviceAddressTeleTab } from "./MultiDeviceAddressTeleTab";

export function MultiDeviceAddressTab(props: any) {
  const {
    data,
    selectdSmt,
    tableAttr,
    dataSetNames,
    dataSetType,
    exportDeviceId,
    exportDeviceType,
    streetName,
    streetNo,
  } = props;

  return (
    <>
      <MultiDeviceAddressTeleTab
        tableAttr={tableAttr}
        data={data}
        selectdSmt={selectdSmt}
        startDiag={props.startDiag}
        dataSetType={dataSetType}
        dataSetNames={dataSetNames}
        exportDeviceId={exportDeviceId}
        exportDeviceType={exportDeviceType}
        streetName={streetName}
        streetNo={streetNo}
        refreshNodeModemList={props.refreshNodeModemList}
        handleFlapHistory={props?.handleFlapHistory}
        selectedMac={props?.selectedMac}
      />
    </>
  );
}
