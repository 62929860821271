import { useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import "../../design-tokens/towerview/TowerView.scss";
import ODUClient from "../../network/httpclient/odu/ODUClient";
import TowerViewClient from "../../network/httpclient/towerview/TowerViewClient";
import WSubDetails from "../odu/wirelessSubscriber/WSubDetails";
import WSubMobility from "../odu/wirelessSubscriber/WSubMobility";
import TowerDetails from "./TowerDetails";
import CellDetails from "./CellDetails";
import ExceptionBox from "../reusable/ExceptionBox";
import LoaderGif from "../../resources/images/loader.webp";
import { isNull } from "../../components/reusable/Util";
import ReloadData from "../reusable/ReloadData";

const TowerViewTab = (props: any) => {
  const dispatch = useDispatch();
  const { wirelessSubLoading, WirelessSubInfo, WirelessSubError } = useSelector(
    (state: any) => ({
      wirelessSubLoading: state.AccountState?.WirelessSubInfo?.isLoading,
      WirelessSubInfo: state.AccountState?.WirelessSubInfo?.data,
      WirelessSubError: state.AccountState?.WirelessSubInfo?.error,
    }),
    shallowEqual
  );
  const [wirelessNumResult, setWirelessNumResult] = useState("");
  const [errorFlag, setErrorFlag] = useState(false);
  const [resultEnabled, setResultEnabled] = useState(true);
  const [accordionIndex, setAccordionIndex] = useState(3);
  const [accordionValue, setAccordionValue] = useState(4);

  useEffect(() => {
    if (WirelessSubInfo?.mobility?.eNBId && TowerViewInfo === undefined) {
      dispatch(
        TowerViewClient.getTowerViewDetail(WirelessSubInfo?.mobility?.eNBId)
      );
    }
  }, [WirelessSubInfo?.mobility?.eNBId]);
  const { TowerViewInfo, TowerViewInfoIsLoading, TowerViewInfoError } =
    useSelector(
      (state: any) => ({
        TowerViewInfo: state.AccountState.TowerViewInfo.data,
        TowerViewInfoIsLoading: state.AccountState.TowerViewInfo.isLoading,
        TowerViewInfoError: state.AccountState.TowerViewInfo.error,
      }),
      shallowEqual
    );

  const { sortedDevices } = useSelector(
    (state: any) => ({
      sortedDevices: state.AccountState.ActiveAccountDetails.sortedDevices,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (WirelessSubError !== undefined) setErrorFlag(true);
  }, [WirelessSubError]);
  useEffect(() => {
    if (WirelessSubInfo !== undefined) {
      setResultEnabled(true);
      setErrorFlag(false);
    }
    if (WirelessSubInfo === undefined) {
      sortedDevices.forEach((device: any) => {
        if (device.hasOwnProperty("msisdn")) {
          if (device.msisdn !== "" && device.msisdn?.length === 10) {
            dispatch(ODUClient.getWirelessSubInfo(device.msisdn));
            setWirelessNumResult(device.msisdn);
          } else {
            setErrorFlag(true);
          }
        }
      });
    }
  }, [WirelessSubInfo, sortedDevices]);
  const handleAccordionToggle = (index: number, value: number) => {
    if (value === 1) {
      if (index === accordionIndex) setAccordionValue(0);
      else setAccordionValue(1);
      setAccordionIndex(index);
    } else if (value === 0) {
      if (index === accordionIndex) setAccordionValue(1);
      else setAccordionValue(0);
      setAccordionIndex(index);
    }
  };

  const refreshData = () => {
    dispatch(ODUClient.getWirelessSubInfo(wirelessNumResult));
    dispatch(
      TowerViewClient.getTowerViewDetail(WirelessSubInfo?.mobility?.eNBId)
    );
  };

  return (
    <div>
      <section className={resultEnabled ? "wsContainer" : "wsCenterContainer"}>
        {errorFlag ? (
          <Card
            className={
              resultEnabled ? "searchSubInfoCard" : "searchCSubInfoCard"
            }
          >
            <p className="wsErrorMessage">
              Invalid number. Please check the number and try again.
            </p>
          </Card>
        ) : (
          <></>
        )}
      </section>
      <div className="refreshCont">
        <ReloadData refreshData={refreshData} />
      </div>
      {!wirelessSubLoading ? (
        <div className="cmSummaryCont">
          {resultEnabled ? (
            <p className="wsResultMessage">
              Displaying result for Subscriber number : {wirelessNumResult}
            </p>
          ) : (
            <></>
          )}
          <Accordion defaultActiveKey="0">
            {WirelessSubInfo !== undefined && WirelessSubInfo !== "" ? (
              <>
                <WSubDetails
                  subscriberData={WirelessSubInfo}
                  accordionValue={accordionValue}
                  accordionIndex={accordionIndex}
                  handleAccordionToggle={handleAccordionToggle}
                />
                <WSubMobility
                  subscriberData={WirelessSubInfo}
                  accordionValue={accordionValue}
                  accordionIndex={accordionIndex}
                  handleAccordionToggle={handleAccordionToggle}
                />
                {WirelessSubInfo !== undefined && (
                  <>
                    {TowerViewInfo !== undefined && TowerViewInfo !== "" ? (
                      <>
                        <TowerDetails
                          towerDetails={TowerViewInfo}
                          accordionValue={accordionValue}
                          accordionIndex={accordionIndex}
                          handleAccordionToggle={handleAccordionToggle}
                        />
                        <CellDetails
                          towerDetails={TowerViewInfo}
                          ecgi={WirelessSubInfo?.mobility?.ecgi}
                          accordionValue={accordionValue}
                          accordionIndex={accordionIndex}
                          handleAccordionToggle={handleAccordionToggle}
                        />
                      </>
                    ) : TowerViewInfoError !== undefined ? (
                      <ExceptionBox
                        exBoxType="error"
                        errSecName="Tower View Detail"
                        errCode={isNull(TowerViewInfoError?.errorCode)}
                        ErrorMSG={isNull(TowerViewInfoError?.errorMessage)}
                        srcSystem={
                          TowerViewInfoError?.hasOwnProperty("sourceSystem")
                            ? TowerViewInfoError?.sourceSystem
                            : "Request"
                        }
                        primaryBtn={true}
                        secBtn={true}
                        handleRefresh={() => setErrorFlag(false)}
                      />
                    ) : TowerViewInfoIsLoading ? (
                      <div style={{ textAlign: "center" }}>
                        <img className="loaderImg" src={LoaderGif} />
                      </div>
                    ) : null}
                  </>
                )}
              </>
            ) : WirelessSubError !== undefined ? (
              <ExceptionBox
                exBoxType="error"
                errSecName="Wireless Subscriber"
                errCode={isNull(WirelessSubError?.errorCode)}
                ErrorMSG={isNull(WirelessSubError?.errorMessage)}
                srcSystem={
                  WirelessSubError?.hasOwnProperty("sourceSystem")
                    ? WirelessSubError?.sourceSystem
                    : "Request"
                }
                primaryBtn={true}
                secBtn={true}
                handleRefresh={() => setErrorFlag(false)}
              />
            ) : (
              <div className="diagErrorCont">
                <ExceptionBox
                  headerText="No data to display."
                  bodyText="Please try refreshing if data is missing"
                  primaryBtn={false}
                  secBtn={false}
                />
              </div>
            )}
          </Accordion>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <img className="loaderImg" src={LoaderGif} />
        </div>
      )}
    </div>
  );
};

export default TowerViewTab;
