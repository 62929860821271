import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import "../../design-tokens/node-page/NodePage.scss";

import { GetGponNodeDetails } from "../../get-api/GetResponse";
import { GponLandingPage } from "../gpon-page/GponLandingPage";
import { SaveGponNodes } from "../../store/actions/gpon/GponNodeSummaryAction";
import { setActiveQueryPonNode } from "../../topology/store/slices/topoGeneral";

export const GponMainPage = (props: any) => {
  const {
    location: { search },
  } = props;
  const query = new URLSearchParams(search);
  const qPonNode = query.get("gponId");
  const dispatch = useDispatch();
  const { nodeInfo, nodeSummaryList } = GetGponNodeDetails();
  const [nodeInfoData, setNodeInfoData] = useState<any>(null);
  const [nodeSummaryData, setNodeSummaryData] = useState<any>({
    active: 0,
    assigned: 0,
    responsive: 0,
    unresponsive: 0,
    ponNode: nodeInfo?.data?.ponNode,
    phub: nodeInfo?.data?.phub,
    diagStartTime: nodeInfo?.diagStartTime,
    diagEndTime: nodeInfo?.diagEndTime,
  });

  const calculateNodeSummary = useCallback(() => {
    // calculate the node summary percentage of status is ok or not
    let value: any = [];
    nodeSummaryList?.nodeSummaryList?.forEach((d: any) => {
      if (d?.data?.summary?.status === "OK") {
        value.push(d?.data?.summary?.status);
      } else if (d?.data?.summary?.status === "ERROR") {
        value.push(d?.data?.summary?.status);
      } else if (d?.data?.summary?.status === "WARNING") {
        value.push(d?.data?.summary?.status);
      }
    });
    // out of nodeSummaryList?.nodeSummaryList?.length total nodes, how many are ok, error, warning
    let ok = value.filter((d: any) => d === "OK").length;
    let error = value.filter((d: any) => d === "ERROR").length;
    let warning = value.filter((d: any) => d === "WARNING").length;
    let total = ok + error + warning;
    let okPercentage = ((ok / total) * 100)?.toFixed(2);
    let responsive = ok + warning;
    let unresponsive = error;

    return {
      active: okPercentage,
      assigned: total,
      responsive: responsive,
      unresponsive: unresponsive,
      ponNode: nodeInfo?.data?.ponNode,
      phub: nodeInfo?.data?.phub,
      diagStartTime: nodeInfo?.diagStartTime,
      diagEndTime: nodeInfo?.diagEndTime,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeSummaryList?.nodeSummaryList]);

  useEffect(() => {
    if (
      nodeSummaryList?.nodeSummaryList?.every(
        (d: any) => d?.data?.summary?.isLoading === false
      )
    ) {
      setNodeSummaryData(calculateNodeSummary());
    }
    if (
      nodeSummaryList?.nodeSummaryList?.some(
        (d: any) => d?.data?.summary?.isLoading === true
      )
    ) {
      setNodeSummaryData({
        active: undefined,
        assigned: undefined,
        responsive: undefined,
        unresponsive: undefined,
        ponNode: nodeInfo?.data?.ponNode,
        phub: nodeInfo?.data?.phub,
        diagStartTime: undefined,
        diagEndTime: undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeSummaryList?.nodeSummaryList]);

  const nodeInfoDataHandler = useCallback(async () => {
    dispatch(setActiveQueryPonNode(qPonNode)); // save pon node in redux

    if (nodeInfo?.data !== undefined) {
      let nodesList = nodeInfo?.data?.resultList;
      if (nodesList !== undefined && nodesList?.length !== 0) {
        let nodesDevices = await nodesList?.map((d: any) => {
          let nDevice = {
            ...d,
            snNumber: d.ontSerialNumber,
            ontSerialNumber: d.ontSerialNumber,
            serialNumber: d.ontSerialNumber,
            ontModel: d.ontModel,
            data: {
              summary: {
                isLoading: false,
                data: undefined,
                status: "",
              },
            },
          };
          return nDevice;
        });
        if (
          nodesDevices !== undefined &&
          nodesDevices?.length !== 0 &&
          nodeSummaryList.nodeSummaryList?.length === 0
        ) {
          dispatch(SaveGponNodes(nodesDevices));
        }
        setNodeInfoData(nodesDevices);
      }
    } else {
      setNodeInfoData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    nodeInfoDataHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeInfo]);

  return (
    <div className="accTabsCont">
      <>
        <GponLandingPage
          tableData={nodeInfoData}
          nodeSummaryData={nodeSummaryData}
          ponNode={qPonNode}
          {...props}
        />
      </>
    </div>
  );
};
