import "../../design-tokens/certification/HsiCertificationPage.scss";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CableModemClient from "../../network/httpclient/cablemodem/CableModemClient";
import IHPClient from "../../network/httpclient/ihp/IHPClient";
import ONTClient from "../../network/httpclient/ont/ONTClient";
import IPTVInfoClient from "../../network/httpclient/iptv/IPTVInfoClient";
import ODUClient from "../../network/httpclient/odu/ODUClient";
import { useEffect, useState } from "react";
import { Device } from "../../store/models/account/accountInfo/Device";
import { ResetSavedDevices } from "../../store/actions/account/certifications/ActiveCertificationAction";
import CodeWordsClient from "../../network/httpclient/codeword/CodeWordsClient";
import { CWMonitoringReset } from "../../store/actions/account/diagnostics/cm/CWMonitoringAction";
import { SetFailedCodewords } from "../../store/actions/account/accountInfo/ActiveAccountAction";
import {
  CERTIFICATION_CONFIG,
  TESTTYPE_CONFIG,
} from "../../constants/CertificationsConfig";
import { addDiagInDevice } from "../../components/reusable/StoreCertificationObj";
import {
  changeStatusToSkipped,
  changeTimeFormat,
  extractW3IdOfHSI,
  isIgniteAccount,
  isNull,
} from "../../components/reusable/Util";
import { useRef } from "react";
import {
  SaveSkippedDevices,
  SaveDataLoading,
} from "../../store/actions/account/certifications/ActiveCertificationAction";
import CertificationClient from "../../network/httpclient/certification/CertificationClient";
import STBClient from "../../network/httpclient/stb/STBClient";
import { CertiDevice } from "../../store/models/account/certifications/CertiDevice";
import { SaveActiveCertiDevices } from "../../store/actions/account/certifications/ActiveCertiDevicesAction";
import CertificationDeviceList from "../../components/certification/CertificationDeviceList";
import ASTBClient from "../../network/httpclient/astb/AstbClient";
import packageJson from "../../../package.json";
import ReloadButton from "../../components/reusable/ReloadButton";
import { CWMonitoringTracer } from "../../store/actions/account/diagnostics/cm/CWMonitoringAction";
import { SetSCQAMFLAG } from "../../store/actions/account/accountInfo/ActiveAccountAction";
import {
  GetWorkOrderDetails,
  GetPrecheckDiagData,
} from "../../get-api/GetResponse";
import { cmSpeedTestReset, getCmSpeedTest } from "../../store/slices/cablemodem/cmSpeedTest";

const CertificationDetailsPage = (props: any) => {
  const { handleCloseOnSave } = props;

  const {
    sortedDevices,
    selectedSA,
    accType,
    IptvNetworkInfo,
    stbSummaryInfo,
    certiData,
    passDevices,
    failDevices,
    marginalDevices,
    skipDevices,
    certiDeviceList,
    failedAttributes,
    codewords,
    cwMonitorLoading,
    tracer,
    activeAcc,
    cmSpeedTestData,
  } = useSelector(
    (state: any) => ({
      sortedDevices: state.AccountState.ActiveAccountDetails.sortedDevices,
      accType: state.AccountState.ActiveAccountDetails.accountType,
      selectedSA: state.AccountState.ActiveAccountDetails.selectedSA,
      cwMonitorLoading: state.AccountState.cwMonitorInfo.overallLoading,
      IptvNetworkInfo:
        state.AccountState.Certification.IptvCertiNetworkInfo.data,
      stbSummaryInfo: state.AccountState.Certification.StbCertiSummaryInfo.data,
      certiData: state.AccountState.Certification.ActiveCertiInfo.certiInfo,
      passDevices:
        state.AccountState.Certification.ActiveCertiInfo.passedDevices,
      codewords: state.AccountState.ActiveAccountDetails?.codeword,
      cwMonitorInfo: state.AccountState.cwMonitorInfo.data,
      marginalDevices:
        state.AccountState.Certification.ActiveCertiInfo.marginalPassDevices,
      failDevices:
        state.AccountState.Certification.ActiveCertiInfo.failedDevices,
      skipDevices:
        state.AccountState.Certification.ActiveCertiInfo.skippedDevices,
      certiDeviceList:
        state.AccountState.Certification.ActiveCertiDevicesInfo.certiDevices,
      failedAttributes:
        state.AccountState.ActiveAccountDetails?.failedAttributes,
      tracer: state.AccountState.cwMonitorInfo.tracer,
      activeAcc: state.AccountState.ActiveAccountDetails?.data,
      cmSpeedTestData: state.AccountState?.cmSpeedTestInfo,
    }),
    shallowEqual
  );

  const [reloadDiagFlag, setreloadDiagFlag] = useState(false); //added
  //This is used to restart the certification

  let testType = certiData?.data?.testType;
  let brand = certiData?.data?.brand;

  const WHTDeviceCat = TESTTYPE_CONFIG.WHT.deviceAllowed;
  const DNSDeviceCat = TESTTYPE_CONFIG.DNS.deviceAllowed;
  let certiConfig: any = [];
  if (accType === "DOCSIS") certiConfig = CERTIFICATION_CONFIG.DOCSIS;
  else if (accType === "GPON") certiConfig = CERTIFICATION_CONFIG.GPON;
  else if (accType === "FWA") certiConfig = CERTIFICATION_CONFIG.FWA;
  const dispatch = useDispatch();
  let skipCount = useRef(0);
  const [passCount, setPassCount] = useState(0);
  const [failCount, setFailCount] = useState(0);
  const [marginalpassCount, setMarginalPassCount] = useState(0);
  const [saveFlag, setSaveFlag] = useState(true);
  const [cwFlag, setcwFlag] = useState(true);
  const [certiDevices, setCertiDevices] = useState<Device[]>([]);
  const [updatedDevices, setupdatedDevices] = useState<Device[]>([]);
  const [allDeviceSkipped, setAllDeviceSkipped] = useState(false);
  const woDetails = GetWorkOrderDetails()?.data;
  const precheckDevicesObj = GetPrecheckDiagData().precheckDiagnosticData?.data;
  const [loadingFlag, setLoadingFlag] = useState(false);
  const checkSkipCondition = (devCat: string, device: Device) => {
    const productList = woDetails?.productList;
    const isPTPL = productList?.some((po: any) => po?.includes("PRD: PTPL"));
    let filteredDevices: Device[] = [];
    let skippedDevices: Device[] = [];
    if (
      testType === "WHT" &&
      ((isIgniteAccount(activeAcc) &&
        isPTPL &&
        (devCat?.includes("IPTV") || devCat?.endsWith("STB"))) || // Ignite IPTV Skip Condition
        (brand === "Legacy" && devCat?.endsWith("STB")))
    )
      return true;
  };

  const goRunDiag = () => {
    setPassCount(0);
    setFailCount(0);
    setMarginalPassCount(0);
    setSaveFlag(true);
    dispatch(ResetSavedDevices());
    dispatch(SetSCQAMFLAG(false));
    dispatch(CWMonitoringReset());
    dispatch(SetFailedCodewords(""));
    dispatch(CWMonitoringTracer(true));
    setreloadDiagFlag(!reloadDiagFlag);
    skipCount.current = 0;
    dispatch(SaveDataLoading(true));
  };

  let techData = certiData?.data?.technician;
  const currentLanConnectionType =
    IptvNetworkInfo !== undefined
      ? IptvNetworkInfo?.lanConnectionType?.toUpperCase()
      : undefined;
  useEffect(() => {
    if (
      stbSummaryInfo !== undefined &&
      stbSummaryInfo?.hasOwnProperty("cmMac") &&
      stbSummaryInfo.cmMac !== null &&
      stbSummaryInfo.cmMac !== ""
    ) {
      dispatch(
        STBClient.getCertiSTBDocsis(
          stbSummaryInfo.serialNum,
          stbSummaryInfo.cmMac
        )
      );
    }
  }, [stbSummaryInfo]);

  useEffect(() => {
    if (passDevices !== undefined && passDevices?.hasOwnProperty("count")) {
      setPassCount(passDevices.count);
    }
  }, [passDevices]);
  useEffect(() => {
    if (failDevices !== undefined && failDevices?.hasOwnProperty("count")) {
      setFailCount(failDevices.count);
    }
  }, [failDevices]);
  useEffect(() => {
    if (
      marginalDevices !== undefined &&
      marginalDevices?.hasOwnProperty("count")
    ) {
      setMarginalPassCount(marginalDevices.count);
    }
  }, [marginalDevices]);
  useEffect(() => {
    let count = 0;

    certiDeviceList?.map((d: any) => {
      if (!d.isLoading) count += 1;
    });
    if (count !== 0 && count === certiDevices?.length) {
      setLoadingFlag(!loadingFlag);
    } else if (allDeviceSkipped) {
      setLoadingFlag(!loadingFlag);
    }
  }, [certiDeviceList]);

  useEffect(() => {
    let filteredDevices: Device[] = [];
    let skippedDevices: Device[] = [];
    let allowedDeviceList = certiConfig?.map((d: any) => {
      return d.category;
    });
    dispatch(SaveDataLoading(true));
    if (sortedDevices !== undefined) {
      sortedDevices?.map((device: Device) => {
        if (checkSkipCondition(device?.deviceCategory, device)) {
          skippedDevices?.push(device);

          skipCount.current = skipCount.current + 1;
          filteredDevices.push(device);
        } else if (
          testType === "WHT" &&
          WHTDeviceCat?.indexOf(device.deviceCategory) > -1 &&
          allowedDeviceList?.indexOf(device.deviceCategory) > -1
        ) {
          filteredDevices?.push(device);
        } else if (
          testType === "DNS" &&
          WHTDeviceCat?.indexOf(device.deviceCategory) > -1 &&
          allowedDeviceList?.indexOf(device.deviceCategory) > -1
        ) {
          if (DNSDeviceCat?.indexOf(device.deviceCategory) > -1) {
            filteredDevices?.push(device);
          } else {
            skippedDevices?.push(device);
            skipCount.current = skipCount.current + 1;
          }
        }
      });
    }

    if (skipCount.current > 0) {
      let tempObj = {
        ...{ count: skipCount.current },
        ...{ devices: skippedDevices },
      };
      if (filteredDevices?.length === 0 && testType === "DNS") {
        setAllDeviceSkipped(true);
      }

      dispatch(SaveSkippedDevices(tempObj));
    }
    let reduxDevices: CertiDevice[] = filteredDevices?.map((d: any) => {
      return {
        isLoading: true,
        inProgressCalls: 0,
        deviceKey:
          d.deviceCategory === "IEMTA"
            ? d.deviceCategory + d.serialNumber
            : d.serialNumber,
        data: undefined,
      };
    });

    dispatch(SaveActiveCertiDevices(reduxDevices));

    setCertiDevices(filteredDevices);
  }, [testType, reloadDiagFlag]);
  useEffect(() => {
    if (certiDevices !== undefined && certiDevices?.length !== 0) {
      certiDevices?.map((d) => {
        switch (d.deviceCategory) {
          case "CM":
            dispatch(
              CableModemClient.getCMCertiSummary(d.serialNumber, d.macAddress)
            );
            dispatch(
              CableModemClient.getCMCertiNodeDetails(
                d.serialNumber,
                selectedSA.shubId
              )
            );
            dispatch(
              CableModemClient.getCMCertiDocsisInfo(
                d.serialNumber,
                d.macAddress
              )
            );

            dispatch(CodeWordsClient.getCodewords(d.macAddress, tracer));
            dispatch(cmSpeedTestReset());
            dispatch(getCmSpeedTest(d.macAddress));
            break;
          case "EMTA":
            dispatch(
              IHPClient.getIHPCertiSummary(
                d.deviceCategory,
                d.serialNumber,
                d.macAddress,
                ""
              )
            );
            dispatch(IHPClient.getIHPCertiDocsis(d.serialNumber, d.macAddress));
            break;
          case "IEMTA":
            dispatch(
              IHPClient.getIHPCertiSummary(
                d.deviceCategory,
                d.serialNumber,
                d.cmmac,
                d.atamac
              )
            );
            break;
          case "ONT":
            dispatch(ONTClient.getONTCertiSummary(d.serialNumber));
            break;
          case "IPTV":
            const params = d?.useRpil
              ? {
                oui: d.oui,
                serialNum: d.serialNumber,
                hhid: extractW3IdOfHSI(selectedSA),
                macAddress: d.macAddress,
              }
              : { oui: d.oui, serialNum: d.serialNumber };
            dispatch(IPTVInfoClient.getIptvCertiDevice(params));
            dispatch(IPTVInfoClient.getIptvCertiNetwork(params));
            break;
          case "ODU":
            dispatch(ODUClient.getODUCertiSummary(d.oui, d.serialNumber));
            dispatch(ODUClient.getODUCertiWirelessInfo(d.oui, d.serialNumber));
            break;
          case "STB":
          case "DESTB":
          case "GSTB":
          case "HSTB":
          case "IPSTB":
            // if (!checkSkipCondition()) {
            dispatch(
              STBClient.getCertiSTBSummary(d.serialNumber, d.macAddress)
            );
            // }
            break;
          case "ASTB":
            dispatch(
              ASTBClient.getASTBCertiSummary(d.serialNumber, d.macAddress)
            );
            break;
          default:
            break;
        }
      });
    }
  }, [certiDevices]);

  useEffect(() => {
    if (certiDevices !== undefined && certiDevices?.length !== 0) {
      certiDevices?.map((d) => {
        switch (d.deviceCategory) {
          case "IPTV":
            const params = d?.useRpil
              ? {
                oui: d.oui,
                serialNum: d.serialNumber,
                hhid: extractW3IdOfHSI(selectedSA),
                macAddress: d.macAddress,
              }
              : { oui: d.oui, serialNum: d.serialNumber };
            if (currentLanConnectionType !== undefined) {
              switch (currentLanConnectionType?.toLowerCase()) {
                case "ethernet":
                  dispatch(IPTVInfoClient.getIptvCertiEthernet(params));
                  break;
                case "wifi":
                  dispatch(IPTVInfoClient.getIptvCertiWifi(params));
                  break;
              }
            }
        }
      });
    }
  }, [currentLanConnectionType]);

  useEffect(() => {
    if (!cwMonitorLoading) {
      setSaveFlag(false); //false-enable
      setcwFlag(!saveFlag);
    } else if (saveFlag !== true) {
      setSaveFlag(true);
      setcwFlag(saveFlag);
    }
  }, [cwMonitorLoading]);
  const updateCertiObject = () => {
    let newCompletedSteps = certiData?.data?.workflow?.completedSteps;
    if (newCompletedSteps?.indexOf(2) === -1) newCompletedSteps.push(2);
    let newActiveCerti = {
      workflow: {
        status: "INPROGRESS",
        completedSteps: newCompletedSteps,
      },
      technician: {
        lanId: isNull(techData?.lanId),
        techId: isNull(techData?.techId),
        companyNumber: isNull(techData?.companyNumber),
        contactNumber: isNull(techData?.contactNumber),
      },
    };

    let certiStatus =
      failCount > 0
        ? "Failed"
        : marginalDevices?.count > 0
          ? "Marginally Passed"
          : "Passed";
    let totalDevices =
      passCount + failCount + skipCount.current + marginalDevices?.count;
    let skipDeviceObj =
      skipDevices !== undefined ? skipDevices : { count: 0, devices: [] };

    let devicesObj: any = [];

    updatedDevices?.map((d: any) => {
      certiDeviceList?.map((o: any) => {
        if (d.deviceCategory !== "IEMTA" && d.serialNumber === o.deviceKey) {
          let newDevice = addDiagInDevice(d, o);
          if (checkSkipCondition(d?.deviceCategory, d)) {
            newDevice = { ...newDevice, status: "skip" };
            devicesObj.push(changeStatusToSkipped(newDevice));
          } else {
            devicesObj.push(newDevice);
          }
        } else if (
          d.deviceCategory === "IEMTA" &&
          d.deviceCategory + d.serialNumber === o.deviceKey
        ) {
          let newDevice = addDiagInDevice(d, o);
          devicesObj.push(newDevice);
        }
      });
    });

    let tempCertiObject = {
      hsiCertificate: {
        isApplicable: true,
        uiClient: {
          type: "web",
          versionName: packageJson?.version,
        },
        status: certiStatus,
        summary: {
          total: totalDevices,
          passed: passDevices,
          marginalPass: marginalDevices,
          failed: failDevices,
          skipped: skipDeviceObj,
        },
        devices: devicesObj,
        precheckDevices: precheckDevicesObj, //adding precheck devices data to certificate
        cmSpeedTest: cmSpeedTestData,
        codewords: codewords,
        failedCodewords: failedAttributes,
      },
    };
    let updatedCertiObj = { ...newActiveCerti, ...tempCertiObject };
    return updatedCertiObj;
  };

  const onSaveCerti = () => {
    let newCertiObj = updateCertiObject();
    if (certiData !== undefined && certiData?.hasOwnProperty("id")) {
      const base64EncObj = { data: btoa(JSON.stringify(newCertiObj)) };
      dispatch(CertificationClient.updateCerti(certiData.id, base64EncObj));
    }
    dispatch(cmSpeedTestReset());
    handleCloseOnSave();
  };

  return (
    <div className="hsiCertiCont">
      <div className="certiInfoCont">
        <section>
          <h4>Whole Home Certification</h4>
          <p>
            <span>Test Type: </span>
            <span>
              {testType === "WHT"
                ? "Whole Home Test"
                : testType === "DNS"
                  ? "Drop & Stay Test"
                  : "N/A"}
            </span>
          </p>
          <p>
            <span>Generated by: </span>
            <span>
              {techData?.lanId} ({techData?.techId})
            </span>
          </p>
          <p>
            <span>Generated on: </span>
            <span>{changeTimeFormat(certiData?.data?.updatedDate)}</span>
          </p>
          <p>
            <span>Account Number: </span>
            <span>{isNull(certiData?.data?.accountNumber)}</span>
          </p>
          <p>
            <span>Work Order ID: </span>
            <span>{certiData?.data?.workOrderId}</span>
          </p>
        </section>
        <section className="deviceStatusCount">
          <h5>Device Test Status</h5>
          <div>
            <p className="passCount">
              <span>
                {passCount + marginalpassCount < 10
                  ? "0" + (passCount + marginalpassCount)
                  : passCount + marginalpassCount}
              </span>
              <span>Passed</span>
            </p>
            <p className="failCount">
              <span>{failCount < 10 ? "0" + failCount : failCount}</span>
              <span>Failed</span>
            </p>
            <p className="skipCount">
              <span>
                {skipCount.current < 10
                  ? "0" + skipCount.current
                  : skipCount.current}
              </span>
              <span>Skipped</span>
            </p>
          </div>
        </section>
        <section>
          <button
            className="saveCertiBtn"
            disabled={saveFlag}
            onClick={() => onSaveCerti()} //isLoading for codewords
          >
            SAVE CERTIFICATION
          </button>
          <ReloadButton
            className="restartCertiBtn"
            refreshData={() => goRunDiag()}
            disabled={saveFlag}
          />
        </section>
      </div>
      <CertificationDeviceList
        deviceList={certiDevices}
        sortedDevices={sortedDevices}
        skipCount={skipCount}
        skippedFlag={allDeviceSkipped}
        saveFlag={saveFlag}
        cwFlag={cwFlag}
        updateDevices={(devices: Device[]) => {
          setupdatedDevices(devices);
        }}
        enableSave={() => setSaveFlag(false)}
        disableSave={() => setSaveFlag(true)}
        certiConfig={certiConfig}
        checkSkipCondition={checkSkipCondition}
      />
    </div>
  );
};

export default CertificationDetailsPage;
