import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";

import { ODUWirelessInfo } from "../../../store/models/account/diagnostics/odu/ODUWirelessInfo";
import { ODUSummary } from "../../../store/models/account/diagnostics/odu/ODUSummary";
import { ODUNInterfaceState } from "../../../store/states/account/diagnostics/odu/ODUNInterfaceState";
import {
  ODUNetworkInterfaceBegin,
  ODUNetworkInterfaceFailure,
  ODUNetworkInterfaceSuccess,
} from "../../../store/actions/account/diagnostics/odu/ODUNInterfaceAction";
import { ODUNetworkInterface } from "../../../store/models/account/diagnostics/odu/ODUNetworkInterface";
import { WirelessSubscriberState } from "../../../store/states/account/diagnostics/odu/WirelessSubscriberState";
import {
  WirelessSubscriberBegin,
  WirelessSubscriberFailure,
  WirelessSubscriberSuccess,
} from "../../../store/actions/account/diagnostics/odu/WirelessSubscriberAction";
import { WirelessSubscriber } from "../../../store/models/account/diagnostics/odu/WirelessSubscriber";
import { getOUIChecked } from "../../../components/reusable/Util";
import {
  DecrementApiCallCounter,
  IncrementApiCallCounter,
  UpdateActiveCertiDevices,
} from "../../../store/actions/account/certifications/ActiveCertiDevicesAction";
import {
  DecrementDiagApiCallCounter,
  IncrementDiagApiCallCounter,
  UpdateActiveDiagDevices,
} from "../../../store/actions/account/diagnostics/ActiveDiagDevicesActions";

export default class ODUClient {
  static getODUSummary =
    (
      oui: string,
      serialNum: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let qParam = getOUIChecked(oui, serialNum);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/odu/summary",
        params: qParam,
      };

      dispatch(IncrementDiagApiCallCounter(serialNum));

      HttpClient.get<ODUSummary>(httpParameters)
        .then((ODUSummary) => {
          dispatch(UpdateActiveDiagDevices(serialNum, "summary", ODUSummary));
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return ODUSummary;
        })
        .catch((error) => {
          dispatch(UpdateActiveDiagDevices(serialNum, "summary", error));
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return error;
        });
    };

  static getODUWirelessInfo =
    (
      oui: string,
      serialNum: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let qParam = getOUIChecked(oui, serialNum);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/odu/wirelessInfo",
        params: qParam,
      };

      dispatch(IncrementDiagApiCallCounter(serialNum));

      HttpClient.get<ODUWirelessInfo>(httpParameters)
        .then((ODUWirelessInfo) => {
          dispatch(
            UpdateActiveDiagDevices(serialNum, "wirelessInfo", ODUWirelessInfo)
          );
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return ODUWirelessInfo;
        })
        .catch((error) => {
          dispatch(UpdateActiveDiagDevices(serialNum, "wirelessInfo", error));
          dispatch(DecrementDiagApiCallCounter(serialNum));
          return error;
        });
    };
  static getODUNetworkInterfaces =
    (
      oui: string,
      serialNum: string
    ): ThunkAction<void, ODUNInterfaceState, unknown, Action<string>> =>
    async (dispatch) => {
      let qParam = getOUIChecked(oui, serialNum);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/odu/networkInterfaces",
        params: qParam,
      };

      dispatch(ODUNetworkInterfaceBegin());

      HttpClient.get<ODUNetworkInterface>(httpParameters)
        .then((ODUNetworkInterfaces) => {
          dispatch(ODUNetworkInterfaceSuccess(ODUNetworkInterfaces));
          return ODUNetworkInterfaces;
        })
        .catch((error) => {
          dispatch(ODUNetworkInterfaceFailure(error));
          return error;
        });
    };
  static getWirelessSubInfo =
    (
      teleNum: string
    ): ThunkAction<void, WirelessSubscriberState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/odu/wireless/subscriber",
        params: { tn: teleNum },
      };

      dispatch(WirelessSubscriberBegin());

      HttpClient.get<WirelessSubscriber>(httpParameters)
        .then((WirelessSubscriber) => {
          dispatch(WirelessSubscriberSuccess(WirelessSubscriber));
          return WirelessSubscriber;
        })
        .catch((error) => {
          dispatch(WirelessSubscriberFailure(error));
          return error;
        });
    };
  static getODUCertiSummary =
    (
      oui: string,
      serialNum: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let qParam = getOUIChecked(oui, serialNum);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/odu/summary",
        params: qParam,
      };

      dispatch(IncrementApiCallCounter(serialNum));

      HttpClient.get<ODUSummary>(httpParameters)
        .then((ODUSummary) => {
          dispatch(UpdateActiveCertiDevices(serialNum, "summary", ODUSummary));
          dispatch(DecrementApiCallCounter(serialNum));
          return ODUSummary;
        })
        .catch((error) => {
          dispatch(UpdateActiveCertiDevices(serialNum, "summary", error));
          dispatch(DecrementApiCallCounter(serialNum));
          return error;
        });
    };

  static getODUCertiWirelessInfo =
    (
      oui: string,
      serialNum: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let qParam = getOUIChecked(oui, serialNum);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/odu/wirelessInfo",
        params: qParam,
      };

      dispatch(IncrementApiCallCounter(serialNum));

      HttpClient.get<ODUWirelessInfo>(httpParameters)
        .then((ODUWirelessInfo) => {
          dispatch(
            UpdateActiveCertiDevices(serialNum, "wirelessInfo", ODUWirelessInfo)
          );
          dispatch(DecrementApiCallCounter(serialNum));
          return ODUWirelessInfo;
        })
        .catch((error) => {
          dispatch(UpdateActiveCertiDevices(serialNum, "wirelessInfo", error));
          dispatch(DecrementApiCallCounter(serialNum));
          return error;
        });
    };
}
