import { PONOLTInfoState } from "../../states/gpon/PONOLTInfo";
import {
  PON_OLT_LIST_BEGIN,
  PON_OLT_LIST_SUCCESS,
  PON_OLT_LIST_FAILURE,
  RESET_PON_OLT_INFO,
  PonOltInfoActionTypes,
} from "../../types/gpon/PonOltInfo";
import { changeTimeFormat } from "../../../components/reusable/Util";

const initialState: PONOLTInfoState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
  diagStartTime: undefined,
  diagEndTime: undefined,
};

export function PonOltReducer(
  state = initialState,
  action: PonOltInfoActionTypes
): PONOLTInfoState {
  switch (action.type) {
    case PON_OLT_LIST_BEGIN:
      let startTime: any = changeTimeFormat(Date.now());
      return {
        isLoading: true,
        data: undefined,
        error: undefined,
        status: "",
        diagStartTime: startTime,
      };
    case PON_OLT_LIST_SUCCESS:
      let endTime: any = changeTimeFormat(Date.now());
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
        diagEndTime: endTime,
      };
    case PON_OLT_LIST_FAILURE:
      let et: any = changeTimeFormat(Date.now());
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
        diagEndTime: et,
      };
    case RESET_PON_OLT_INFO:
      return initialState;

    default:
      return state;
  }
}