import {NodesInfo} from '../../../models/account/node/NodesInfo';
import {
  NODE_LIST_BEGIN,
  NODE_LIST_FAILURE,
  NODE_LIST_SUCCESS,
  NodesInfoActionTypes,
} from '../../../types/account/node/NodesList';

export function NodesInfoBegin(): NodesInfoActionTypes {
  return {
    type: NODE_LIST_BEGIN,
  };
}

export function NodessInfoSuccess(NodesInfo: NodesInfo): NodesInfoActionTypes {
  return {
    type: NODE_LIST_SUCCESS,
    payload: NodesInfo,
  };
}

export function NodesInfoFailure(error: Error): NodesInfoActionTypes {
  return {
    type: NODE_LIST_FAILURE,
    payload: error,
  };
}
