import { useDispatch } from "react-redux";
import STBClient from "../../network/httpclient/stb/STBClient";
import DiagApiCallCont from "../reusable/DiagApiCallCont";
import ReloadData from "../reusable/ReloadData";
import STBCMInfo from "./STBCMInfo";
import STBDeviceInfo from "./STBDeviceInfo";
import STBDocsisInfo from "./STBDocsisInfo";
const STBSummary = (props: any) => {
  const {
    selectedDevice,
    stbLoading,
    stbSummarydata,
    stbSummaryStatus,
    stbCMSummarydata,
    stbCMSummaryStatus,
    stbCMInterfacesdata,
    stbCMInterfacesStatus,
    stbDocsisData,
    stbDocsisStatus,
    cmNodeInfo,
  } = props;
  const dispatch = useDispatch();

  const refreshData = () => {
    dispatch(
      STBClient.getSTBSummary(
        selectedDevice.serialNumber,
        selectedDevice.macAddress
      )
    );
  };

  return (
    <>
      <div className="refreshCont">
        <ReloadData refreshData={refreshData} />
      </div>
      <div className="cmSummaryCont">
        <DiagApiCallCont
          isLoading={stbLoading}
          data={stbSummarydata}
          status={stbSummaryStatus}
          sectionName="STB Summary"
          handleRefresh={() => refreshData()}
        >
          <STBDeviceInfo deviceData={stbSummarydata} />
        </DiagApiCallCont>
        {stbSummarydata !== undefined &&
        stbSummarydata?.hasOwnProperty("cmMac") ? (
          <>
            <DiagApiCallCont
              isLoading={stbLoading}
              data={stbCMInterfacesdata}
              status={stbCMInterfacesStatus}
              sectionName="STB Interfaces"
              handleRefresh={() => refreshData()}
            >
              <STBCMInfo
                stbCMData={stbCMSummarydata}
                stbInterfaceData={stbCMInterfacesdata}
                cmNodeInfo={cmNodeInfo}
              />
            </DiagApiCallCont>
            <DiagApiCallCont
              isLoading={stbLoading}
              data={stbDocsisData}
              status={stbDocsisStatus}
              sectionName="STB Docsis"
              handleRefresh={() => refreshData()}
            >
              <STBDocsisInfo deviceData={stbDocsisData} />
            </DiagApiCallCont>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default STBSummary;
