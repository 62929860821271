import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../middleware/apiActions";
import { MDU_CAD_TEXT } from "../../../middleware/apiEndpoints";
import storeConst from "../../../storeConst";
import { decrApiCall, incrApiCall } from "../mapDetails";

const slice = createSlice({
  name: "mduCADText",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    getMduCADTextBegin: (mduCADText) => {
      mduCADText.isLoading = true;
      mduCADText.status = "";
    },
    getMduCADTextSuccess: (mduCADText, action) => {
      mduCADText.isLoading = false;
      mduCADText.data = action.payload;
      mduCADText.status = storeConst.OK;
    },
    getMduCADTextFailure: (mduCADText, action) => {
      mduCADText.isLoading = false;
      mduCADText.error = action.payload;
      mduCADText.data = undefined;
      mduCADText.status = storeConst.ERROR;
    },
  },
});

const { getMduCADTextBegin, getMduCADTextSuccess, getMduCADTextFailure } =
  slice.actions;
export default slice.reducer;

export const getMduCADText = (owningDrawing: any) => (dispatch: any) => {
  dispatch(
    apiCallBegan({
      url: `${MDU_CAD_TEXT}/${owningDrawing}`,
      method: "get",
      onStart: getMduCADTextBegin.type,
      onSuccess: getMduCADTextSuccess.type,
      onError: getMduCADTextFailure.type,
      onStartDispatch: incrApiCall.type,
      onSuccessDispatch: decrApiCall.type,
      onErrorDispatch: decrApiCall.type,
    })
  );
};
