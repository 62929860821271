import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../middleware/apiActions";
import { MDU_FIBER_CABLE } from "../../../middleware/apiEndpoints";
import storeConst from "../../../storeConst";
import { decrApiCall, incrApiCall } from "../mapDetails";

const slice = createSlice({
  name: "mduFiberCable",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    getMduFiberCableBegin: (mduFiberCable) => {
      mduFiberCable.isLoading = true;
      mduFiberCable.status = "";
    },
    getMduFiberCableSuccess: (mduFiberCable, action) => {
      mduFiberCable.isLoading = false;
      mduFiberCable.data = action.payload;
      mduFiberCable.status = storeConst.OK;
    },
    getMduFiberCableFailure: (mduFiberCable, action) => {
      mduFiberCable.isLoading = false;
      mduFiberCable.error = action.payload;
      mduFiberCable.data = undefined;
      mduFiberCable.status = storeConst.ERROR;
    },
  },
});

const {
  getMduFiberCableBegin,
  getMduFiberCableSuccess,
  getMduFiberCableFailure,
} = slice.actions;
export default slice.reducer;

export const getMduFiberCable = (owningDrawing: any) => (dispatch: any) => {
  dispatch(
    apiCallBegan({
      url: `${MDU_FIBER_CABLE}/${owningDrawing}`,
      method: "get",
      onStart: getMduFiberCableBegin.type,
      onSuccess: getMduFiberCableSuccess.type,
      onError: getMduFiberCableFailure.type,
      onStartDispatch: incrApiCall.type,
      onSuccessDispatch: decrApiCall.type,
      onErrorDispatch: decrApiCall.type,
    })
  );
};
