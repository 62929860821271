import { createSlice } from "@reduxjs/toolkit";

import { apiCallBegan } from "../../../../middleware/apiActions";
import storeConst from "../../../../storeConst";
import { RF_UPSTREAM_TRACE_API } from "../../../../middleware/apiEndpoints";
import { incrApiCall, decrApiCall } from "../../mapDetails";

const slice = createSlice({
  name: "rfUpstreamTrace",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
    isActive: false,
    networkId: undefined,
    rootNodeSiteId: undefined,
    type: undefined,
    elementInfo: undefined,
  },
  reducers: {
    rfUpstreamTraceBegin: (rfUpstreamTrace) => {
      rfUpstreamTrace.isActive = false;
      rfUpstreamTrace.isLoading = true;
      rfUpstreamTrace.status = "";
      rfUpstreamTrace.data = undefined;
      rfUpstreamTrace.error = undefined;
    },
    rfUpstreamTraceSuccess: (rfUpstreamTrace, { payload }) => {
      rfUpstreamTrace.isActive = true;
      rfUpstreamTrace.networkId = payload.networkId;
      rfUpstreamTrace.type = payload.type;
      rfUpstreamTrace.rootNodeSiteId = payload.rootNodeSiteId;
      rfUpstreamTrace.elementInfo = payload.elementInfo;
      rfUpstreamTrace.isLoading = false;
      rfUpstreamTrace.data = payload?.data;
      rfUpstreamTrace.error = undefined;
      rfUpstreamTrace.status = storeConst.OK;
    },
    rfUpstreamTraceFailure: (rfUpstreamTrace, { payload }) => {
      rfUpstreamTrace.isActive = false;
      rfUpstreamTrace.rootNodeSiteId = payload.rootNodeSiteId;
      rfUpstreamTrace.networkId = payload.networkId;
      rfUpstreamTrace.type = payload.type;
      rfUpstreamTrace.elementInfo = payload.elementInfo;
      rfUpstreamTrace.isLoading = false;
      rfUpstreamTrace.error = payload;
      rfUpstreamTrace.data = undefined;
      rfUpstreamTrace.status = storeConst.ERROR;
    },
    resetRfUpstreamTraceData: (rfUpstreamTrace) => {
      rfUpstreamTrace.isActive = false;
      rfUpstreamTrace.rootNodeSiteId = undefined;
      rfUpstreamTrace.networkId = undefined;
      rfUpstreamTrace.type = undefined;
      rfUpstreamTrace.elementInfo = undefined;
      rfUpstreamTrace.isLoading = false;
      rfUpstreamTrace.status = "";
      rfUpstreamTrace.data = undefined;
      rfUpstreamTrace.error = undefined;
    },
    setRfUpstreamTraceIsActive: (rfUpstreamTrace, { payload }) => {
      rfUpstreamTrace.isActive = payload;
    },
  },
});

export const { resetRfUpstreamTraceData, setRfUpstreamTraceIsActive } =
  slice.actions;
//should not be exposed to outside
const { rfUpstreamTraceBegin, rfUpstreamTraceSuccess, rfUpstreamTraceFailure } =
  slice.actions;
export default slice.reducer;

export const getRfUptreamTraceElements =
  (networkId: any, rootNodeSiteId: any, type: any, elementInfo: any) =>
  (dispatch: any) => {
    dispatch(
      apiCallBegan({
        url: RF_UPSTREAM_TRACE_API(networkId),
        method: "get",
        onStart: rfUpstreamTraceBegin.type,
        onStartDispatch: incrApiCall.type,
        onSuccess: rfUpstreamTraceSuccess.type,
        onSuccessDispatch: decrApiCall.type,
        onError: rfUpstreamTraceFailure.type,
        onErrorDispatch: decrApiCall.type,
        props: {
          rootNodeSiteId,
          networkId,
          type,
          elementInfo,
        },
      })
    );
  };
