import AppLabels from "../../../../../constants/App_Labels";
import DataCard from "../../../reusable/DataCard";
import NumberInputBox from "../../../reusable/NumberInputBox";
import MultiSelectDD from "../../../reusable/MultiSelectDD";
import { useState, useEffect } from "react";
import {
  decrChAdvFilterObj,
  encrChAdvFilterObj,
} from "../../../../util/utilFuncs";
import { GetTelemetryProps } from "../../../../util/reduxFunctions/getTopologyState";
import CustomDropDown from "../../../../../components/reusable/CustomDropDown";
import AdvFilterData from "../../../../data/AdvFilterData";

interface Props {
  filtersData: any;
  setState: any;
  updateActiveProfile: any;
}
const UpstreamFilters = ({
  filtersData,
  setState,
  updateActiveProfile,
}: Props) => {
  const labels = AppLabels();
  const { advancedFilters } = GetTelemetryProps();
  let advFilterData = AdvFilterData();
  let usAdvFData = advancedFilters?.upstream;
  const [usAdvFilters, setUsAdvFilters] = useState<any>(filtersData);
  const [advFilterFlag, setAdvFilterFlag] = useState<boolean>(true);
  const updateFilter = (
    filterKey: string,
    value: any,
    isOperator: boolean = false
  ) => {
    if (filterKey) {
      let filter = isOperator
        ? {
            label: usAdvFilters?.[filterKey]?.label,
            operator: value,
            value: usAdvFilters?.[filterKey]?.value,
          }
        : {
            label: usAdvFilters?.[filterKey]?.label,
            operator: usAdvFilters?.[filterKey]?.operator,
            value: value,
          };
      setUsAdvFilters({
        ...usAdvFilters,
        ...{ [filterKey]: filter },
      });
    }
  };

  useEffect(() => {
    if (usAdvFilters) {
      let encrFilterObj = encrChAdvFilterObj(
        usAdvFilters,
        advFilterData?.usOperatorKeys
      );
      setState(encrFilterObj);
    }
  }, [usAdvFilters]);

  useEffect(() => {
    if (usAdvFData && advFilterFlag) {
      let decrFilterObj = decrChAdvFilterObj(
        usAdvFData,
        filtersData,
        advFilterData?.usOperatorKeys
      );
      setUsAdvFilters(decrFilterObj);
      setAdvFilterFlag(false);
      updateActiveProfile();
    }
  }, [advancedFilters]);

  useEffect(() => {
    if (!usAdvFData || !advFilterFlag) {
      setUsAdvFilters(filtersData);
    }
  }, [filtersData]);

  return (
    <DataCard
      cardHeader={labels.UPSTREAM.toUpperCase()}
      cardBody={
        <div className="advFilterBody">
          <p className="advfilterRow">
            <span className="labelGroup">
              <span>
                {usAdvFilters?.isPartialService?.label?.toUpperCase()}
              </span>
            </span>
            <span className="filterComparator">
              {usAdvFilters?.isPartialService?.operator}
            </span>
            <input
              type="checkbox"
              checked={usAdvFilters?.isPartialService?.value}
              className="partialCheckBox"
              onChange={(event) =>
                updateFilter("isPartialService", event.target.checked)
              }
            />
          </p>
          <p className="advfilterRow">
            <span className="labelGroup">
              <span>{usAdvFilters?.freq?.label?.toUpperCase()}</span>
            </span>
            <span className="filterComparator">
              {usAdvFilters?.freq?.operator}
            </span>
            <MultiSelectDD
              options={usAdvFilters?.freq?.value}
              searchFlag={true}
              setSelectedOptions={(value: any) => {
                updateFilter("freq", value);
              }}
            />
          </p>
          <p className="advfilterRow">
            <span className="labelGroup">
              <span>{usAdvFilters?.txLess?.label?.toUpperCase()}</span>
            </span>
            <span className="filterComparator">
              {usAdvFilters?.txLess?.operator}
            </span>
            <NumberInputBox
              value={usAdvFilters?.txLess?.value}
              setValue={(v: any) => updateFilter("txLess", v)}
            />
            <span className="filterLabel">OR</span>
            <span className="filterComparator">
              {usAdvFilters?.txGreater?.operator}
            </span>
            <NumberInputBox
              value={usAdvFilters?.txGreater?.value}
              setValue={(v: any) => updateFilter("txGreater", v)}
            />
          </p>
          {Object.keys(usAdvFilters)?.map((filterKey: any, i: number) => {
            let isKeyAllowed =
              advFilterData?.usSpeFilterKeys1.includes(filterKey);
            if (isKeyAllowed) {
              let filter: any = usAdvFilters?.[filterKey];
              return (
                <p className="advfilterRow" key={i}>
                  <span className="labelGroup">
                    <span>{filter?.label?.toUpperCase()}</span>
                  </span>
                  <CustomDropDown
                    selectedOption={filter?.operator}
                    options={advFilterData?.compOperatorList}
                    searchFlag={false}
                    checkboxFlag={false}
                    setSelectedOption={(value: any) => {
                      updateFilter(filterKey, value, true);
                    }}
                  />
                  <NumberInputBox
                    value={filter?.value}
                    setValue={(v: any) => updateFilter(filterKey, v)}
                  />
                </p>
              );
            }
          })}
          <p className="advfilterRow">
            <span className="labelGroup">
              <span>{usAdvFilters?.ofdmaTXLess?.label?.toUpperCase()}</span>
            </span>
            <span className="filterComparator">
              {usAdvFilters?.ofdmaTXLess?.operator}
            </span>
            <NumberInputBox
              value={usAdvFilters?.ofdmaTXLess?.value}
              setValue={(v: any) => updateFilter("ofdmaTXLess", v)}
            />
            <span className="filterLabel">OR</span>
            <span className="filterComparator">
              {usAdvFilters?.ofdmaTXGreater?.operator}
            </span>
            <NumberInputBox
              value={usAdvFilters?.ofdmaTXGreater?.value}
              setValue={(v: any) => updateFilter("ofdmaTXGreater", v)}
            />
          </p>
          {Object.keys(usAdvFilters)?.map((filterKey: any, i: number) => {
            let isKeyAllowed =
              advFilterData?.usSpeFilterKeys2.includes(filterKey);
            if (isKeyAllowed) {
              let filter: any = usAdvFilters?.[filterKey];
              return (
                <p className="advfilterRow" key={i}>
                  <span className="labelGroup">
                    <span>{filter?.label?.toUpperCase()}</span>
                  </span>
                  <CustomDropDown
                    selectedOption={filter?.operator}
                    options={advFilterData?.compOperatorList}
                    searchFlag={false}
                    checkboxFlag={false}
                    setSelectedOption={(value: any) => {
                      updateFilter(filterKey, value, true);
                    }}
                  />
                  <NumberInputBox
                    value={filter?.value}
                    setValue={(v: any) => updateFilter(filterKey, v)}
                  />
                </p>
              );
            }
          })}
        </div>
      }
    />
  );
};

export default UpstreamFilters;
