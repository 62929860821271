import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../middleware/apiActions";
import { VHUB_SEARCH_URI } from "../../../middleware/apiEndpoints";
import storeConst from "../../../storeConst";
import { decrApiCall, incrApiCall } from "../mapDetails";

const slice = createSlice({
  name: "vhubSearch",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    getVhubSearchBegin: (vhubSearch) => {
      vhubSearch.isLoading = true;
      vhubSearch.status = "";
    },
    getVhubSearchSuccess: (vhubSearch, { payload }) => {
      vhubSearch.isLoading = false;
      vhubSearch.data = payload;
      vhubSearch.status = storeConst.OK;
    },
    getVhubSearchFailure: (vhubSearch, { payload }) => {
      vhubSearch.isLoading = false;
      vhubSearch.error = payload;
      vhubSearch.data = undefined;
      vhubSearch.status = storeConst.ERROR;
    },
  },
});

const { getVhubSearchBegin, getVhubSearchSuccess, getVhubSearchFailure } =
  slice.actions;
export default slice.reducer;

export const getVhubSearch = (vhubId: number) => (dispatch: any) => {
  dispatch(
    apiCallBegan({
      url: VHUB_SEARCH_URI + vhubId,
      onStart: getVhubSearchBegin.type,
      onSuccess: getVhubSearchSuccess.type,
      onError: getVhubSearchFailure.type,
      onStartDispatch: incrApiCall.type,
      onSuccessDispatch: decrApiCall.type,
      onErrorDispatch: decrApiCall.type,
    })
  );
};
