import { Star, Transformer } from "react-konva";
import { KonvaEventObject } from "konva/lib/Node";
import { useRef, useEffect } from "react";

import {
  IGeoEditShape,
  IDrawPosition,
  ICommonDrawingProps,
  getOpacity,
} from "../GeoEditCanvas";

export interface IGeoStarShape extends IGeoEditShape {
  innerRadius: number;
  outerRadius: number;
  fill: string | undefined;
  strokeWidth: number;
  starPoints: number;
}
interface Props {
  stars: IGeoStarShape[];
  setStars: (rect: IGeoStarShape[]) => void;
  cdp: ICommonDrawingProps;
  onClick: ({ id }: { id: string }) => void;
}

export default function Stars({ stars, setStars, cdp, onClick }: Props) {
  return (
    <>
      {stars.map((star) => (
        <Shape
          key={star.id}
          shape={star}
          onClick={onClick}
          shapes={stars}
          setShapes={setStars}
          cdp={cdp}
        />
      ))}
      {cdp.drawingType === "star" && cdp.drawStartPosi && cdp.drawEndPosi && (
        <Star
          x={cdp.drawStartPosi.x}
          y={cdp.drawStartPosi.y}
          numPoints={5}
          innerRadius={
            calcStarRadius(cdp.drawStartPosi, cdp.drawEndPosi).innerRadius
          }
          outerRadius={
            calcStarRadius(cdp.drawStartPosi, cdp.drawEndPosi).outerRadius
          }
          stroke={cdp.color}
          fill={cdp.fill}
          opacity={getOpacity(cdp.opacity)}
          strokeWidth={cdp.strokeWidth}
        />
      )}
    </>
  );
}

interface ShapeProps {
  shape: IGeoStarShape;
  shapes: IGeoStarShape[];
  setShapes: (rect: IGeoStarShape[]) => void;
  cdp: ICommonDrawingProps;
  onClick: ({ id }: { id: string }) => void;
}
const Shape = ({ shape, shapes, setShapes, cdp, onClick }: ShapeProps) => {
  const shapeRef = useRef<any>(null);
  const trRef = useRef<any>(null);
  const { selectedShape, isSelected: isSSelected } = cdp;
  const isSelected = isSSelected({ id: shape.id, shape: "star" });

  const handleDragStart = (e: KonvaEventObject<DragEvent>) => {
    cdp.setDrawingType("none");
    const id = e.target.id();
    setShapes(shapes.map((s) => ({ ...s, isDragging: s.id === id })));
  };

  const handleDragEnd = () =>
    setShapes(shapes.map((s) => ({ ...s, isDragging: false })));

  useEffect(() => {
    if (isSelected && trRef.current) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
    if (!isSelected && shapeRef.current) {
      shapeRef.current.scaleX(1);
      shapeRef.current.scaleY(1);
    }
  }, [isSelected]);

  const handleTREnd = () => {
    const node = shapeRef.current;
    if (node) {
      const scaleX = node.scaleX();
      node.scaleX(1);
      node.scaleY(1);

      if (selectedShape?.id && selectedShape.shape === "star") {
        setShapes(
          shapes.map((s) =>
            s.id === selectedShape.id
              ? {
                  ...s,
                  x: node.x(),
                  y: node.y(),
                  innerRadius: Math.round(node.innerRadius() * scaleX),
                  outerRadius: Math.round(node.outerRadius() * scaleX),
                  rotation: node.rotation(),
                }
              : s
          )
        );
      }

      trRef.current.getLayer().batchDraw();
    }
  };

  return (
    <>
      <Star
        ref={shapeRef}
        x={shape.x}
        y={shape.y}
        innerRadius={shape.innerRadius}
        outerRadius={shape.outerRadius}
        stroke={shape.stroke}
        fill={shape.fill}
        strokeWidth={shape.strokeWidth}
        rotation={shape.rotation}
        numPoints={shape.starPoints}
        opacity={getOpacity(shape.opacity)}
        draggable
        scaleX={shape.isDragging ? 1.1 : 1}
        scaleY={shape.isDragging ? 1.1 : 1}
        onMouseDown={handleDragStart}
        onDragEnd={handleDragEnd}
        onClick={() => onClick({ id: shape.id })}
      />
      {selectedShape && isSelected && (
        <Transformer
          ref={trRef as any}
          onTransformEnd={handleTREnd}
          enabledAnchors={[
            "top-left",
            "top-right",
            "bottom-left",
            "bottom-right",
          ]}
        />
      )}
    </>
  );
};

export const calcStarRadius = (
  startPosi: IDrawPosition,
  endPosi: IDrawPosition
) => {
  const deltaX = endPosi.x - startPosi.x;
  const deltaY = endPosi.y - startPosi.y;
  const outerRadius = Math.round(Math.sqrt(deltaX * deltaX + deltaY * deltaY));
  const innerRadius = Math.round(outerRadius * 0.4); // Adjust the factor as desired

  return { innerRadius, outerRadius };
};
