import { Accordion, Card } from "react-bootstrap";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { isNull } from "../../components/reusable/Util";

const TowerDetails = (props: any) => {
  const {
    towerDetails,
    accordionValue,
    accordionIndex,
    handleAccordionToggle,
  } = props;
  return (
    <Card className="dataCard">
      <Accordion.Toggle
        as={Card.Header}
        eventKey="2"
        onClick={() => handleAccordionToggle(2, accordionValue)}
      >
        <span>TOWER DETAILS</span>
        {accordionValue === 2 && accordionIndex === 2 ? (
          <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
        ) : (
          <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
        )}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="2">
        <Card.Body>
          <table className="dataTableDetails">
            <thead>
              <tr>
                <th id="nameCol">NAME</th>
                <th id="valueCol">VALUE</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>ENB ID</td>
                <td>{isNull(towerDetails?.eNBId)}</td>
              </tr>
              <tr>
                <td>ENB NAME</td>
                <td>{isNull(towerDetails?.eNBName)}</td>
              </tr>
              <tr>
                <td>LOCATION CODE</td>
                <td>{isNull(towerDetails?.locationCode)}</td>
              </tr>
              <tr>
                <td>TAC</td>
                <td>{isNull(towerDetails?.tac)}</td>
              </tr>
              <tr>
                <td>SITE NAME</td>
                <td>{isNull(towerDetails?.siteName)}</td>
              </tr>
              <tr>
                <td>SITE ADDRESS</td>
                <td>{isNull(towerDetails?.siteAddress)}</td>
              </tr>
              <tr>
                <td>SITE PROVINCE</td>
                <td>{isNull(towerDetails?.siteProvince)}</td>
              </tr>
              <tr>
                <td>TOWER TYPE</td>
                <td>{isNull(towerDetails?.towerType)}</td>
              </tr>
              <tr>
                <td>TOWER HEIGHT</td>
                <td>{isNull(towerDetails?.towerHeight)}</td>
              </tr>
              <tr>
                <td>LATITUDE</td>
                <td>{isNull(towerDetails?.latitude)}</td>
              </tr>
              <tr>
                <td>LONGITUDE</td>
                <td>{isNull(towerDetails?.longtitude)}</td>
              </tr>
              <tr>
                <td>ERROR</td>
                <td>{isNull(towerDetails?.error)}</td>
              </tr>
            </tbody>
          </table>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default TowerDetails;
