import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import TopologyLabels from "../../../../constants/topologyLabels";
import {
  setAdvFCond,
  setAdvancedFilters,
  setSelectedProfile,
} from "../../../../store/slices/mapItems/diagnostics/telemetry";
import DownstreamFilters from "./DownstreamFilters";
import Summaryfilters from "./Summaryfilters";
import UpstreamFilters from "./UpstreamFilters";
import AppLabels from "../../../../../constants/App_Labels";
import AdvFilterData from "../../../../data/AdvFilterData";
import { MdClose } from "react-icons/md";
import { GetTelemetryProps } from "../../../../util/reduxFunctions/getTopologyState";
import appConst from "../../../../constants/appConst";
import CustomDropDown from "../../../../../components/reusable/CustomDropDown";
interface Props {
  showAdvFilter: boolean;
  handleClose: any;
}

const DiagAdvanceFilter = ({ showAdvFilter, handleClose }: Props) => {
  const tLabels = TopologyLabels();
  const appLabels = AppLabels();
  const dispatch = useDispatch();
  const { advFCond, selectedProfile } = GetTelemetryProps();
  let advFilterData = AdvFilterData();
  const [summaryFilterData, setSummaryFilterData] = useState(
    advFilterData?.summaryFilters
  );
  const [usFilterData, setUsFilterData] = useState(
    advFilterData?.upStreamFilters
  );
  const [dsFilterData, setDsFilterData] = useState(
    advFilterData?.downStreamFilters
  );
  const [regtimeFlaps, setRegtimeFlaps] = useState<any>(null);
  const [upstream, setUpstream] = useState<any>(null);
  const [downstream, setDownstream] = useState<any>(null);
  const [activeCond, setActiveCond] = useState("");
  const [activeProfile, setActiveProfile] = useState(selectedProfile);
  const [advFilterFlag, setAdvFilterFlag] = useState<boolean>(false);

  const handleApplyFilter = () => {
    dispatch(
      setAdvancedFilters({
        isFiltersApplied: true,
        regtimeFlaps: { ...regtimeFlaps },
        upstream: { ...upstream },
        downstream: { ...downstream },
      })
    );
    dispatch(setSelectedProfile(activeProfile));
    handleClose();
  };
  const handleResetFilter = () => {
    dispatch(setAdvancedFilters(undefined));
    dispatch(setAdvFCond(appConst.or.toUpperCase()));
    dispatch(setSelectedProfile(advFilterData?.profileList[0]));

    //reset data
    setSummaryFilterData(advFilterData?.summaryFilters);
    setUsFilterData(advFilterData?.upStreamFilters);
    setDsFilterData(advFilterData?.downStreamFilters);
  };

  const handleCondSwitch = (cond: string) => {
    if (cond) {
      dispatch(setAdvFCond(cond));
    }
  };

  const updateActiveProfile = () => {
    setAdvFilterFlag(true);
    setActiveProfile(selectedProfile);
  };

  useEffect(() => {
    if (advFCond) {
      setActiveCond(advFCond);
    }
  }, [advFCond]);

  useEffect(() => {
    if (selectedProfile) {
      setActiveProfile(selectedProfile);
    }
  }, [selectedProfile]);

  useEffect(() => {
    if (
      activeProfile &&
      (!advFilterFlag || activeProfile !== selectedProfile)
    ) {
      let tempDSFilterData = {
        ...advFilterData?.downStreamFilters,
        ...advFilterData?.dsODM3Profile,
      };
      switch (activeProfile) {
        case "DEFAULT":
          setSummaryFilterData(advFilterData?.summaryFilters);
          setUsFilterData(advFilterData?.upStreamFilters);
          setDsFilterData(advFilterData?.downStreamFilters);
          break;
        case "ODM3 M/S":
          let tempUSMSFilterData = {
            ...advFilterData?.upStreamFilters,
            ...advFilterData?.usODM3Profile,
            ...advFilterData?.usODM3MidProfile,
          };
          setSummaryFilterData(advFilterData?.summaryFilters);
          setUsFilterData(tempUSMSFilterData);
          setDsFilterData(tempDSFilterData);
          break;
        case "ODM3 L/S":
          let tempUSLSFilterData = {
            ...advFilterData?.upStreamFilters,
            ...advFilterData?.usODM3Profile,
            ...advFilterData?.usODM3LowProfile,
          };
          setSummaryFilterData(advFilterData?.summaryFilters);
          setUsFilterData(tempUSLSFilterData);
          setDsFilterData(tempDSFilterData);
          break;
      }
    }
    if (advFilterFlag) setAdvFilterFlag(false);
  }, [activeProfile]);

  return (
    <div>
      <Modal
        show={showAdvFilter}
        onHide={() => handleClose()}
        backdrop="static"
        keyboard={false}
        centered
        id="advFilterPopUp"
      >
        <Modal.Header className="advFilterPopHead">
          <header>{tLabels.advanceFilter.toUpperCase()} </header>
          <div className="advActionGroup">
            <CustomDropDown
              selectedOption={activeProfile}
              options={advFilterData?.profileList}
              searchFlag={false}
              checkboxFlag={false}
              setSelectedOption={(value: any) => {
                setActiveProfile(value);
              }}
            />
            <button className="sPopUpClose" onClick={() => handleClose()}>
              <MdClose size={20} color="white" />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className="advFilterCont">
          <Summaryfilters
            filtersData={summaryFilterData}
            setState={setRegtimeFlaps}
          />
          <div className="chFilterCont">
            <UpstreamFilters
              filtersData={usFilterData}
              setState={setUpstream}
              updateActiveProfile={updateActiveProfile}
            />
            <DownstreamFilters
              filtersData={dsFilterData}
              setState={setDownstream}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="advFilterPopFoot">
          <div className="advActionGroup">
            <p>
              <span
                className={
                  activeCond === appConst.or.toUpperCase()
                    ? "activeCondSpan"
                    : ""
                }
                onClick={() => handleCondSwitch(appConst.or.toUpperCase())}
              >
                OR
              </span>
              <span
                className={
                  activeCond === appConst.and.toUpperCase()
                    ? "activeCondSpan"
                    : ""
                }
                onClick={() => handleCondSwitch(appConst.and.toUpperCase())}
              >
                AND
              </span>
            </p>
          </div>
          <div>
            <button
              className="clearAdvFilterBtn"
              onClick={() => handleResetFilter()}
            >
              {appLabels.Clear.toUpperCase()}
            </button>
            <button
              className="applyAdvFilterBtn"
              onClick={() => handleApplyFilter()}
            >
              {tLabels.apply.toUpperCase()}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DiagAdvanceFilter;
