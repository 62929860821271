import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import AppLabels from "../../../constants/App_Labels";
import ConfigConst from "../../../constants/ConfigConst";
import {
  GetActiveAccountDetails,
  GetFormHistory,
  GetFormInfo,
  GetRpaInfo,
} from "../../../get-api/GetResponse";
import FormClient from "../../../network/httpclient/form/FormClient";
import EmailClient from "../../../network/httpclient/notification/EmailClient";
import {
  sendRpaReset,
  sendRpaSuccessReset,
} from "../../../store/ducksPattern/sendRpaData";
import CustomPopUp from "../../reusable/CustomPopUp";
import ResponseSnackBar from "../../reusable/ResponseSnackBar";
import { convertHTMLtoStr, isNull } from "../../reusable/Util";

import AspSerialNumberData from "../../../data/forms/RPA/AspSerialNumberData";
import EmailAuditClient from "../../../network/httpclient/EmailAudit/EmailAuditClient";
import FormBoilerplate from "../FormBoilerplate";
import ASPSDetails from "./ASPSDetails";
import DisplayEmailPreviewASP from "./DisplayEmailPreviewASP";
import PrepareEmailFormat from "./PrepareEmailFormat";

interface Props {
  setShowModal: any;
  techType: string;
}

const ASPScreen = ({ setShowModal, techType }: Props) => {
  const labels = AppLabels();
  const dispatch = useDispatch();
  const formHistory = GetFormHistory();
  const aspFormInitialData = AspSerialNumberData();
  const accountNumber = GetActiveAccountDetails()?.accountNumber;
  const activeAccData = GetActiveAccountDetails()?.data;
  const account_type = GetActiveAccountDetails()?.accType;
  const formInfo = GetFormInfo();
  const rpaInfo = GetRpaInfo();
  //const message = "Message - Order for additional pods/STB requiring approval, please take action as soon as possible. See account/order details attached";
  //const [freePod, setFreePod] = useState("");
  const [isWOSelectionComplete, setIsWOSelectionComplete] = useState(false);
  //const [isCTSelectionComplete, setIsCTSelectionComplete] = useState(false);
  const [woData, setWOData] = useState({} as any);
  const [selectedWOID, setSelectedWOID] = useState(labels.Select);
  const [aspDetails, setASPDetails] = useState({} as any);
  const [isSerialNumbersVerifed, setSerialNumbersVerifed] = useState(false);
  const [equipMenu, setEquipMenu] = useState([] as any);
  const [showwarningFlag, setwarningFlag] = useState(false);
  const [showsubmitmessage, setshowsubmitmessage] = useState(false);
  const [showFlag, setShowFlag] = useState(false);
  //const [selectedCustType, setSelectedCustType] = useState(labels.Select);
  const [showPreview, setShowPreview] = useState(true);
  const [bannerVisible, setBannerVisible] = useState(false);
  const workOrderData = [labels.Select];
  const formData = GetFormHistory()?.formData;
  const form = GetFormHistory()?.data;
  const [test, setTest] = useState(false);
  const {
    isLoading,
    status,
    formStatus,
    isFormLoading,
    refKey,
    EmailFormData,
    sentby,
  } = useSelector(
    (state: any) => ({
      isLoading: state.AccountState.sendEmailData.isLoading,
      status: state.AccountState.sendEmailData.status,
      formStatus: state.AccountState.FormInfo.status,
      isFormLoading: state.AccountState.FormInfo.isLoading,
      refKey: state.AccountState.FormInfo?.data?.id,
      EmailFormData: state.AccountState.FormInfo?.data?.formData,
      sentby: state.AccountState.FormInfo?.data?.technicianId,
    }),
    shallowEqual
  );
  const onSubmit = () => {
    if (
      !formHistory?.data?.id &&
      selectedWOID !== labels.Select &&
      woData?.orderType === "Installation"
    ) {
      sendEmail();
      //sendValidate();
      //saveToDatabase();
      savetoDatabaseRPAformat();
    } else {
      dispatch(sendRpaReset());
      savetoDatabaseRPAformat();

      //sendASP();
    }
  };

  const onPreview = () => {
    setShowFlag(true);
    setwarningFlag(true);
  };

  useEffect(() => {
    if (formData && formData !== "") {
      if (form?.formName === ConfigConst.RPA_ADDPODORSTB) {
        setTest(true);
        // setNoiseDetails(form?.formData);
        // setSelectedCustType(form?.formData?.selectedCustType);
      }
    }
  }, [formData]);

  /*const saveToDatabase = (combinedDetails?: any) => {
    console.log("WOdataBefore", combinedDetails)
    if (!formHistory?.data?.id) {
      
      const generateData = {
        formName: ConfigConst.RPA_ADDPODORSTB,
        workorderId:
          selectedWOID === labels.No_Work_Order
            ? ConfigConst.NO_WORK_ORDER
            : selectedWOID,
        accountNumber,
        account_type,
        formData: combinedDetails
          ? JSON.stringify({ ...woData, ...combinedDetails })
          : JSON.stringify({ ...woData, ...aspDetails }),
        nodeNumber: aspDetails?.nodeNumber,
      };
      console.log("WOdataAfter", combinedDetails)
      dispatch(FormClient.postForm(generateData));
    }
  };
  */

  const checkquantity = (
    stbcheck: boolean,
    freepodcheck: boolean,
    paidpodcheck: boolean,
    fp: String,
    pp: String,
    stb: String
  ) => {
    let sample = "";
    if (paidpodcheck && !stbcheck && !freepodcheck) {
      sample = sample + "Paid Pod:" + aspDetails?.paidpodquantity;
    } else if (!paidpodcheck && !stbcheck && freepodcheck) {
      sample = sample + "Free Pod:" + aspDetails?.freepodquantity;
    } else if (!paidpodcheck && stbcheck && !freepodcheck) {
      sample = sample + "STB:" + aspDetails?.stbquantity;
    } else if (paidpodcheck && stbcheck && !freepodcheck) {
      sample =
        sample +
        "STB:" +
        aspDetails?.stbquantity +
        "," +
        "Paid Pod:" +
        aspDetails?.paidpodquantity;
    } else if (!paidpodcheck && stbcheck && freepodcheck) {
      sample =
        sample +
        "STB:" +
        aspDetails?.stbquantity +
        "," +
        "Free Pod:" +
        aspDetails?.freepodquantity;
    } else if (paidpodcheck && !stbcheck && freepodcheck) {
      sample =
        sample +
        "Paid Pod:" +
        aspDetails?.paidpodquantity +
        "," +
        "Free Pod:" +
        aspDetails?.freepodquantity;
    } else if (paidpodcheck && stbcheck && freepodcheck) {
      sample =
        sample +
        "Paid Pod:" +
        aspDetails?.paidpodquantity +
        "," +
        "Free Pod:" +
        aspDetails?.freepodquantity +
        "," +
        "STB:" +
        aspDetails?.stbquantity;
    }
    return sample;
  };

  const podTypeCheck = (pt: String) => {
    let sample = "";
    if (pt === "XE1") {
      sample = sample + "Gen1";
    } else if (pt === "XE2") {
      sample = sample + "Gen2";
    }
    return sample;
  };
  const serialNumberSTBCheck = (stbcheck: boolean, stb: String) => {
    let sample = "";
    if (stb === undefined || !stbcheck) {
      sample = "";
    } else if (stb === "1" && stbcheck) {
      sample = sample + "STB:" + aspDetails?.quantitytextbox1 + ",";
    } else if (stb === "2" && stbcheck) {
      sample =
        sample +
        "STB:" +
        aspDetails?.quantitytextbox1 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox3 +
        ",";
    } else if (stb === "3" && stbcheck) {
      sample =
        sample +
        "STB:" +
        aspDetails?.quantitytextbox1 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox3 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox5 +
        ",";
    } else if (stb === "4" && stbcheck) {
      sample =
        sample +
        "STB:" +
        aspDetails?.quantitytextbox1 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox3 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox5 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox7 +
        ",";
    } else if (stb === "5" && stbcheck) {
      sample =
        sample +
        "STB:" +
        aspDetails?.quantitytextbox1 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox3 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox5 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox7 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox25 +
        ",";
    } else if (stb === "6" && stbcheck) {
      sample =
        sample +
        "STB:" +
        aspDetails?.quantitytextbox1 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox3 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox5 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox7 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox25 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox27 +
        ",";
    } else if (stb === "7" && stbcheck) {
      sample =
        sample +
        "STB:" +
        aspDetails?.quantitytextbox1 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox3 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox5 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox7 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox25 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox27 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox29 +
        ",";
    } else if (stb === "8" && stbcheck) {
      sample =
        sample +
        "STB:" +
        aspDetails?.quantitytextbox1 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox3 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox5 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox7 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox25 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox27 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox29 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox31 +
        ",";
    } else if (stb === "9" && stbcheck) {
      sample =
        sample +
        "STB:" +
        aspDetails?.quantitytextbox1 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox3 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox5 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox7 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox25 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox27 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox29 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox31 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox33 +
        ",";
    } else if (stb === "10" && stbcheck) {
      sample =
        sample +
        "STB:" +
        aspDetails?.quantitytextbox1 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox3 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox5 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox7 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox25 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox27 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox29 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox31 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox33 +
        "," +
        "STB:" +
        aspDetails?.quantitytextbox35 +
        ",";
    }

    return sample;
  };

  const serialNumberPaidPodCheck = (ppcheck: boolean, pp: String) => {
    let sample = "";
    if (pp === undefined || !ppcheck) {
      sample = "";
    } else if (pp === "1" && ppcheck) {
      sample = sample + "Paid Pod:" + aspDetails?.quantitytextbox17 + ",";
    } else if (pp === "2" && ppcheck) {
      sample =
        sample +
        "Paid Pod:" +
        aspDetails?.quantitytextbox17 +
        "," +
        "Paid Pod:" +
        aspDetails?.quantitytextbox19 +
        ",";
    } else if (pp === "3" && ppcheck) {
      sample =
        sample +
        "Paid Pod:" +
        aspDetails?.quantitytextbox17 +
        "," +
        "Paid Pod:" +
        aspDetails?.quantitytextbox19 +
        "," +
        "Paid Pod:" +
        aspDetails?.quantitytextbox21 +
        ",";
    } else if (pp === "4") {
      sample =
        sample +
        "Paid Pod:" +
        aspDetails?.quantitytextbox17 +
        "," +
        "Paid Pod:" +
        aspDetails?.quantitytextbox19 +
        "," +
        "Paid Pod:" +
        aspDetails?.quantitytextbox21 +
        "," +
        "Paid Pod:" +
        aspDetails?.quantitytextbox23 +
        ",";
    }
    return sample;
  };

  const serialNumberFreePodCheck = (fpcheck: boolean, fp: String) => {
    let sample = "";
    if (fp === undefined || !fpcheck) {
      sample = "";
    }
    if (fp === "1" && fpcheck) {
      sample = sample + "Free Pod:" + aspDetails?.quantitytextbox9 + ",";
    } else if (fp === "2" && fpcheck) {
      sample =
        sample +
        "Free Pod:" +
        aspDetails?.quantitytextbox9 +
        "," +
        "Free Pod:" +
        aspDetails?.quantitytextbox11 +
        ",";
    } else if (fp === "3" && fpcheck) {
      sample =
        sample +
        "Free Pod:" +
        aspDetails?.quantitytextbox9 +
        "," +
        "Free Pod:" +
        aspDetails?.quantitytextbox11 +
        "," +
        "Free Pod:" +
        aspDetails?.quantitytextbox13 +
        ",";
    } else if (fp === "4" && fpcheck) {
      sample =
        sample +
        "Free Pod:" +
        aspDetails?.quantitytextbox9 +
        "," +
        "Free Pod:" +
        aspDetails?.quantitytextbox11 +
        "," +
        "Free Pod:" +
        aspDetails?.quantitytextbox13 +
        "," +
        "Free Pod:" +
        aspDetails?.quantitytextbox15 +
        ",";
    }
    return sample;
  };

  const savetoDatabaseRPAformat = (combinedDetails?: any) => {
    const generateData = {
      formName: ConfigConst.RPA_ADDPODORSTB,
      workorderId:
        selectedWOID === labels.Select
          ? ConfigConst.NO_WORK_ORDER
          : selectedWOID,
      accountNumber,
      account_type,
      formData: combinedDetails
        ? JSON.stringify({ ...woData, ...combinedDetails })
        : JSON.stringify({ ...woData, ...aspDetails, ...sendObj }),
      nodeNumber: aspDetails?.nodeNumber,
    };
    //onsole.log("WOdataAfter", combinedDetails)
    // dispatch(FormClient.postForm(generateData));
    //}
    if (!formHistory?.data?.id) {
      /*const generateData = {
      formName: ConfigConst.RPA_ADDPODORSTB,
      workorderId:
        selectedWOID === labels.No_Work_Order
          ? ConfigConst.NO_WORK_ORDER
          : selectedWOID,
      accountNumber,
      account_type,
      formData: combinedDetails
        ? JSON.stringify({ ...woData, ...combinedDetails })
        : JSON.stringify({ ...woData, ...sendObj }),
      nodeNumber: aspDetails?.nodeNumber,
    };
   */
      // console.log("WOdataAfter", combinedDetails)
      dispatch(FormClient.postForm(generateData));
    } else {
      //if (formHistory?.data?.id) {
      dispatch(FormClient.updateForm(generateData, formHistory?.data?.id));
    }
  };

  //const sendASP = () => {
  const sendObj = {
    OriginalSOorWO:
      woData?.orderType === labels.Installation
        ? "WO " + selectedWOID
        : "SO " + selectedWOID,
    HHID: aspDetails?.hhid,
    EquipmentMenu: equipMenu?.equipmentMenu,
    PodorSTBquantity: checkquantity(
      equipMenu?.equipmentMenu?.includes("STB"),
      equipMenu?.equipmentMenu?.includes("Free Pod"),
      equipMenu?.equipmentMenu?.includes("Paid Pod"),
      aspDetails?.freepodquantity,
      aspDetails?.paidpodquantity,
      aspDetails?.stbquantity
    ),
    PodType: podTypeCheck(aspDetails?.podtype),
    techType:
      techType && techType === ConfigConst?.ROGERS ? "internal" : "external",
    SerialNumber:
      serialNumberSTBCheck(
        equipMenu?.equipmentMenu?.includes("STB"),
        aspDetails?.stbquantity
      ) +
      serialNumberFreePodCheck(
        equipMenu?.equipmentMenu?.includes("Free Pod"),
        aspDetails?.freepodquantity
      ) +
      serialNumberPaidPodCheck(
        equipMenu?.equipmentMenu?.includes("Paid Pod"),
        aspDetails?.paidpodquantity
      ),
    ModemMacID: aspDetails?.CMMac,
    MandatoryComments: aspDetails?.freepodreasoningcomments,
    FreePodReasoning: aspDetails?.freepodreasoning,
    isSTBchecked: equipMenu?.equipmentMenu?.includes("STB"),
    isFreePodChecked: equipMenu?.equipmentMenu?.includes("Free Pod"),
    isPaidPodChecked: equipMenu?.equipmentMenu?.includes("Paid Pod"),
    //HouseNo: aspDetails?.streetNumber!== undefined?aspDetails?.streetNumber:"",
    // StreetName: aspDetails?.streetName !== undefined? aspDetails?.streetName:"",
    //StreetDirection: aspDetails?.streetDirection !== undefined? aspDetails?.streetDirection: "",
    //UnitNoOrSuiteNo: aspDetails?.unitOrSuiteNumber!== undefined?aspDetails?.unitOrSuiteNumber:"",
    //City: aspDetails?.city!== undefined ? aspDetails?.city : "",

    // customerContactNumber: noiseDetails?.customerContactNumber,
  };

  //dispatch(RpaClient.postToQueue(ConfigConst.RPA_ADDPODORSTB, sendObj));
  //};

  const sendEmail = () => {
    const franchise = "T";
    const emailSub = ConfigConst.ASP_SUBJECT;
    const htmlMessage = convertHTMLtoStr(
      <PrepareEmailFormat
        ASPbody={
          <DisplayEmailPreviewASP
            woID={isNull(selectedWOID)}
            woType={isNull(woData?.orderType)}
            aspDets={aspDetails}
            equipment={JSON.stringify(equipMenu.equipmentMenu)}

            // workDetails={aspDetails}
          />
        }
      />
    );

    let emailPayload = {
      accountNumber: accountNumber,
      franchise: franchise,
      subject: emailSub,
      htmlMessage: htmlMessage,
      language: "EN",
      firstName: activeAccData?.firstName,
      lastName: activeAccData?.lastName,
      attachFileName: ConfigConst.ASP_FILENAME,
      addresses: aspDetails?.distroEmails,
      formName: ConfigConst.ASP_SUBJECT.toUpperCase()
    };
    const base64EncObj = { data: btoa(JSON.stringify(emailPayload)) };
    dispatch(EmailClient.sendEmailNoti(labels.AddSTBEmail, base64EncObj));
  };

  const checkserialNumberSTB = (stb: String) => {
    let sample = false;

    if (stb === "1" && aspDetails?.quantitytextbox1 === "") {
      sample = true;
    } else if (
      stb === "2" &&
      aspDetails?.quantitytextbox1 === "" &&
      aspDetails?.quantitytextbox3 === ""
    ) {
      sample = true;
    } else if (
      stb === "3" &&
      aspDetails?.quantitytextbox1 === "" &&
      aspDetails?.quantitytextbox3 === "" &&
      aspDetails?.quantitytextbox5 === ""
    ) {
      sample = true;
    } else if (
      stb === "4" &&
      aspDetails?.quantitytextbox1 !== "" &&
      aspDetails?.quantitytextbox3 !== "" &&
      aspDetails?.quantitytextbox5 !== "" &&
      aspDetails?.quantitytextbox7 !== ""
    ) {
      sample = false;
    } else if (
      stb === "5" &&
      aspDetails?.quantitytextbox1 !== "" &&
      aspDetails?.quantitytextbox3 !== "" &&
      aspDetails?.quantitytextbox5 !== "" &&
      aspDetails?.quantitytextbox7 !== "" &&
      aspDetails?.quantitytextbox25 !== ""
    ) {
      sample = false;
    } else if (
      stb === "6" &&
      aspDetails?.quantitytextbox1 !== "" &&
      aspDetails?.quantitytextbox3 !== "" &&
      aspDetails?.quantitytextbox5 !== "" &&
      aspDetails?.quantitytextbox7 !== "" &&
      aspDetails?.quantitytextbox25 !== "" &&
      aspDetails?.quantitytextbox27 !== ""
    ) {
      sample = false;
    } else if (
      stb === "7" &&
      aspDetails?.quantitytextbox1 !== "" &&
      aspDetails?.quantitytextbox3 !== "" &&
      aspDetails?.quantitytextbox5 !== "" &&
      aspDetails?.quantitytextbox7 !== "" &&
      aspDetails?.quantitytextbox25 !== "" &&
      aspDetails?.quantitytextbox27 !== "" &&
      aspDetails?.quantitytextbox29 !== ""
    ) {
      sample = false;
    } else if (
      stb === "8" &&
      aspDetails?.quantitytextbox1 !== "" &&
      aspDetails?.quantitytextbox3 !== "" &&
      aspDetails?.quantitytextbox5 !== "" &&
      aspDetails?.quantitytextbox7 !== "" &&
      aspDetails?.quantitytextbox25 !== "" &&
      aspDetails?.quantitytextbox27 !== "" &&
      aspDetails?.quantitytextbox29 !== "" &&
      aspDetails?.quantitytextbox31 !== ""
    ) {
      sample = false;
    } else if (
      stb === "9" &&
      aspDetails?.quantitytextbox1 !== "" &&
      aspDetails?.quantitytextbox3 !== "" &&
      aspDetails?.quantitytextbox5 !== "" &&
      aspDetails?.quantitytextbox7 !== "" &&
      aspDetails?.quantitytextbox25 !== "" &&
      aspDetails?.quantitytextbox27 !== "" &&
      aspDetails?.quantitytextbox29 !== "" &&
      aspDetails?.quantitytextbox31 !== "" &&
      aspDetails?.quantitytextbox33 !== ""
    ) {
      sample = false;
    } else if (
      stb === "10" &&
      aspDetails?.quantitytextbox1 !== "" &&
      aspDetails?.quantitytextbox3 !== "" &&
      aspDetails?.quantitytextbox5 !== "" &&
      aspDetails?.quantitytextbox7 !== "" &&
      aspDetails?.quantitytextbox25 !== "" &&
      aspDetails?.quantitytextbox27 !== "" &&
      aspDetails?.quantitytextbox29 !== "" &&
      aspDetails?.quantitytextbox31 !== "" &&
      aspDetails?.quantitytextbox33 !== "" &&
      aspDetails?.quantitytextbox35 !== ""
    ) {
      sample = false;
    }
    return sample;
  };

  const checkSerialNumberPaidPod = (pp: String) => {
    let sample = false;
    if (pp === undefined) {
      sample = true;
    } else if (
      pp === "1" &&
      (aspDetails?.quantitytextbox17 !== "" ||
        aspDetails?.quantitytextbox17 !== undefined)
    ) {
      sample = true;
    } else if (
      pp === "2" &&
      aspDetails?.quantitytextbox17 !== "" &&
      aspDetails?.quantitytextbox19 !== ""
    ) {
      sample = true;
    } else if (
      pp === "3" &&
      aspDetails?.quantitytextbox17 !== "" &&
      aspDetails?.quantitytextbox19 !== "" &&
      aspDetails?.quantitytextbox21 !== ""
    ) {
      sample = true;
    } else if (
      pp === "4" &&
      aspDetails?.quantitytextbox17 !== "" &&
      aspDetails?.quantitytextbox19 !== "" &&
      aspDetails?.quantitytextbox21 !== "" &&
      aspDetails?.quantitytextbox23 !== ""
    ) {
      sample = true;
    }
    return sample;
  };

  const checkSerialNumberFreePod = (fp: String) => {
    let sample = false;
    if (fp === undefined) {
      sample = true;
    } else if (
      fp === "1" &&
      (aspDetails?.quantitytextbox9 !== "" ||
        aspDetails?.quantitytextbox9 !== undefined)
    ) {
      sample = true;
    } else if (
      fp === "2" &&
      aspDetails?.quantitytextbox9 !== "" &&
      aspDetails?.quantitytextbox11 !== ""
    ) {
      sample = true;
    } else if (
      fp === "3" &&
      aspDetails?.quantitytextbox9 !== "" &&
      aspDetails?.quantitytextbox11 !== "" &&
      aspDetails?.quantitytextbox13 !== ""
    ) {
      sample = true;
    } else if (
      fp === "4" &&
      aspDetails?.quantitytextbox9 !== "" &&
      aspDetails?.quantitytextbox11 !== "" &&
      aspDetails?.quantitytextbox13 !== "" &&
      aspDetails?.quantitytextbox15 !== ""
    ) {
      sample = true;
    }
    return sample;
  };

  const disableSubmit = () => {
    if (
      !formInfo.isLoading &&
      selectedWOID !== labels.Select &&
      isWOSelectionComplete &&
      isNull(woData?.orderType) !== "" &&
      aspDetails?.acknowledgment !== "" &&
      aspDetails?.podtype !== "" &&
      isSerialNumbersVerifed &&
      equipMenu?.equipmentMenu?.length > 0 &&
      // aspDetails?.hhid &&
      // aspDetails?.hhid !== "" &&
      (aspDetails?.accountNumber !== "" || aspDetails?.hhid !== "") &&
      aspDetails?.account_type !== "" &&
      aspDetails?.techName &&
      aspDetails?.techName !== "" &&
      //aspDetails?.technicianId !== '' &&
      aspDetails?.HouseNo &&
      aspDetails?.HouseNo !== "" &&
      aspDetails?.StreetName &&
      aspDetails?.StreetName !== "" &&
      aspDetails?.City &&
      aspDetails?.City !== "" &&
      aspDetails?.nodeNumber &&
      aspDetails?.nodeNumber !== ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  const postBannerAction = () => {
    setBannerVisible(false);
  };

  useEffect(() => {
    // if (noiseInfo?.success === false && noiseInfo?.sentAttemptErrors < 3) {
    //   setTimeout(() => {
    //     sendNoise();
    //   }, 2000);
    // } else
    if (
      rpaInfo?.success === false
      // noiseInfo?.sentAttemptErrors === 3
    ) {
      setBannerVisible(true);
      dispatch(sendRpaSuccessReset());
      const combinedDetails = {
        rpaStatus: "Failure",
        ...aspDetails,
      };
      //sendEmail();
      sendEmail();
      //savetoDatabaseRPAformat(combinedDetails);
      savetoDatabaseRPAformat(sendObj);
    }
    if (rpaInfo?.success === true) {
      setBannerVisible(true);
      dispatch(sendRpaSuccessReset());
      const combinedDetails = {
        formSubmissionNo: rpaInfo?.data?.Reference,
        rpaStatus: "Success",
        ...aspDetails,
      };
      if (rpaInfo?.data?.Reference) {
        savetoDatabaseRPAformat(sendObj);
      } else {
        savetoDatabaseRPAformat(sendObj);
      }
    }
  }, [
    // noiseInfo?.sentAttemptErrors,
    rpaInfo?.success,
    rpaInfo?.data?.Reference,
  ]);
  useEffect(() => {
    const generateEmailData = {
      componentType: ConfigConst.RPA_ADDPODORSTB,
      refKey: refKey,
      sentTo: JSON.stringify({ ...aspDetails?.distroEmails }),
      sentby: sentby,
      techid: "123",
    };
    if (aspDetails?.distroEmails?.length > 0) {
      //sendEmail();
      if (
        isLoading === false ||
        status === "OK" ||
        formStatus === "OK" ||
        isFormLoading === false ||
        EmailFormData !== undefined
      ) {
        if (formHistory?.data?.id) {
          dispatch(
            EmailAuditClient.updateForm(
              generateEmailData,
              formHistory?.data?.id
            )
          );
        } else {
          dispatch(EmailAuditClient.postForm(generateEmailData));
        }
      }
    }
  }, [status, formStatus, isFormLoading]);
  return (
    <>
      <FormBoilerplate
        isWOSelectionComplete={isWOSelectionComplete}
        setIsWOSelectionComplete={setIsWOSelectionComplete}
        setWOData={setWOData}
        formLabel={labels.ASP}
        formName={ConfigConst.RPA_ADDPODORSTB}
        onSubmit={onSubmit}
        onPreview={onPreview}
        showPreview={showPreview}
        disableSubmit={disableSubmit}
        selectedWOID={selectedWOID}
        setSelectedWOID={setSelectedWOID}
        setShowEntireModal={setShowModal}
        //disableFormUpdate={false}
        workOrderData={workOrderData}
      >
        <ASPSDetails
          setDetails={setASPDetails}
          setEquipmentMenu={setEquipMenu}
          setSerialNumbersVerifed={setSerialNumbersVerifed}
          aspFormInitialData={aspFormInitialData}
        />
      </FormBoilerplate>
      <CustomPopUp
        showVal={showFlag}
        headerText={labels.Email_Preview.toUpperCase()}
        bodyElement={
          <PrepareEmailFormat
            ASPbody={
              <DisplayEmailPreviewASP
                woID={isNull(selectedWOID)}
                woType={isNull(woData?.orderType)}
                aspDets={aspDetails}
                equipment={JSON.stringify(equipMenu.equipmentMenu)}
              />
            }
          />
        }
        handleClose={() => setShowFlag(false)}
      />
      <CustomPopUp
        showVal={showwarningFlag}
        headerText={"Warning"}
        bodyElement={
          <div>
            <span style={{ color: "red" }}>
              {" "}
              "Please ensure you add all necessary equipment customer is
              requesting, you can only submit this form once for the original
              work order"{" "}
            </span>
          </div>
        }
        handleClose={() => setwarningFlag(false)}
      />

      <ResponseSnackBar
        open={bannerVisible}
        requestType={ConfigConst.RPA_ADDPODORSTB}
        handleClose={postBannerAction}
        responseType={rpaInfo?.status}
      />
    </>
  );
};

export default ASPScreen;
