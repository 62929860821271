import { Device } from "../../store/models/account/accountInfo/Device";
import { shallowEqual, useSelector } from "react-redux";
import { CWMonitor } from "../../store/models/account/diagnostics/cm/CWMonitor";
import { isDeviceOffline } from "./Util";

export const addDeviceStatus = (device: any, dataDevice: any): Device => {
  //update for codewords
  let computedDevice: any = {};
  switch (device.deviceCategory) {
    case "CM":
      if (dataDevice?.hasOwnProperty("docsis")) {
        let tempDStatus = "";
        if (dataDevice["docsis"]?.hasOwnProperty("overallStatus")) {
          if (
            (dataDevice["docsis"]?.overallStatus === "Pass" ||
              dataDevice["docsis"]?.overallStatus === "Marginal Pass" ||
              dataDevice["docsis"]?.overallStatus?.toLowerCase() ===
              "skipped") &&
            dataDevice.hasOwnProperty("codeword") &&
            dataDevice["codeword"]?.length > 0
          ) {
            dataDevice["docsis"].overallStatus = "Fail";
            tempDStatus = "fail";
          } else {
            tempDStatus =
              dataDevice["docsis"]?.["overallStatus"]?.toLowerCase();
          }
        } else if (
          (dataDevice["docsis"]?.hasOwnProperty("errorCode") &&
            dataDevice["docsis"]?.errorCode != 1009) ||
          dataDevice["docsis"] === ""
        ) {
          tempDStatus = "skipped";
        } else if (
          (dataDevice["docsis"]?.hasOwnProperty("errorCode") &&
            dataDevice["docsis"]?.errorCode == 1009) ||
          (dataDevice?.hasOwnProperty("codeword") &&
            dataDevice["codeword"]?.length > 0)
        ) {
          tempDStatus = "fail";
        }
        let dataTemp = {
          deviceStatus: tempDStatus,
        };
        let tempDevice = { ...device, ...dataTemp };
        computedDevice = tempDevice;
      }
      break;
    case "EMTA":
      if (dataDevice?.hasOwnProperty("docsis")) {
        let tempDStatus = "";
        if (dataDevice["docsis"]?.hasOwnProperty("overallStatus")) {
          tempDStatus = dataDevice["docsis"]?.["overallStatus"]?.toLowerCase();
        } else if (
          (dataDevice["docsis"]?.hasOwnProperty("errorCode") &&
            dataDevice["docsis"]?.errorCode != 1009) ||
          dataDevice["docsis"] === ""
        ) {
          // tempDStatus = "fail";
          tempDStatus = "skipped";
        } else if (
          dataDevice["docsis"]?.hasOwnProperty("errorCode") &&
          dataDevice["docsis"]?.errorCode == 1009
        ) {
          tempDStatus = "fail";
        }
        let dataTemp = {
          deviceStatus: tempDStatus,
        };
        let tempDevice = { ...device, ...dataTemp };
        computedDevice = tempDevice;
      }
      break;
    case "IEMTA":
      if (dataDevice?.hasOwnProperty("summary")) {
        let tempDStatus = "";
        if (
          (dataDevice["summary"]?.hasOwnProperty("errorCode") &&
            dataDevice["summary"]?.errorCode != 1009) ||
          dataDevice["summary"] === ""
        ) {
          // tempDStatus = "fail";
          tempDStatus = "skipped";
        } else if (
          dataDevice["summary"]?.hasOwnProperty("errorCode") &&
          dataDevice["summary"]?.errorCode == 1009
        ) {
          tempDStatus = "fail";
        } else if (dataDevice["summary"]) {
          tempDStatus = "pass";
        }
        let dataTemp = {
          deviceStatus: tempDStatus,
        };
        let tempDevice = { ...device, ...dataTemp };
        computedDevice = tempDevice;
      }
      break;
    case "ONT":
      if (dataDevice?.hasOwnProperty("summary")) {
        let tempDStatus = "";
        if (dataDevice["summary"]?.hasOwnProperty("overallStatus")) {
          tempDStatus = dataDevice["summary"]?.["overallStatus"]?.toLowerCase();
        } else if (
          (dataDevice["summary"]?.hasOwnProperty("errorCode") &&
            dataDevice["summary"]?.errorCode != 1009) ||
          dataDevice["summary"] === ""
        ) {
          tempDStatus = "skipped";
        } else if (
          dataDevice["summary"]?.hasOwnProperty("errorCode") ||
          dataDevice["summary"]?.errorCode == 1009
        ) {
          tempDStatus = "fail";
        }
        let dataTemp = {
          deviceStatus: tempDStatus,
        };
        let tempDevice = { ...device, ...dataTemp };
        computedDevice = tempDevice;
      }
      break;
    case "IPTV":
      if (dataDevice?.hasOwnProperty("network")) {
        let tempDStatus = "";

        if (
          (dataDevice["network"]?.hasOwnProperty("errorCode") &&
            !isDeviceOffline(dataDevice["network"])) ||
          dataDevice["network"] === ""
        ) {
          // tempDStatus = "fail";
          tempDStatus = "skipped";
        } else if (
          dataDevice["network"]?.hasOwnProperty("errorCode") &&
          isDeviceOffline(dataDevice["network"])
        ) {
          tempDStatus = "fail";
        } else {
          let lanType = dataDevice["network"]?.lanConnectionType?.toLowerCase();
          if (
            lanType === "wifi" &&
            dataDevice?.hasOwnProperty("wifi") &&
            dataDevice["wifi"]?.hasOwnProperty("signalStrength")
          ) {
            tempDStatus =
              dataDevice["wifi"]["signalStrength"]["status"]?.toLowerCase();
          } else if (
            (lanType === "wifi" &&
              dataDevice["wifi"]?.hasOwnProperty("errorCode") &&
              !isDeviceOffline(dataDevice["wifi"])) ||
            dataDevice["wifi"] === ""
          ) {
            // tempDStatus = "fail";
            tempDStatus = "skipped";
          } else if (
            lanType === "wifi" &&
            dataDevice["wifi"]?.hasOwnProperty("errorCode") &&
            isDeviceOffline(dataDevice["wifi"])
          ) {
            // tempDStatus = "fail";
            tempDStatus = "skipped";
          } else if (
            lanType === "ethernet" &&
            dataDevice?.hasOwnProperty("ethernet") &&
            dataDevice["ethernet"]?.hasOwnProperty("ethernetStatus")
          ) {
            tempDStatus =
              dataDevice["ethernet"]["ethernetStatus"]["status"]?.toLowerCase();
          } else if (
            (lanType === "ethernet" &&
              dataDevice["ethernet"]?.hasOwnProperty("errorCode") &&
              !isDeviceOffline(dataDevice["ethernet"])) ||
            dataDevice["ethernet"] === ""
          ) {
            // tempDStatus = "fail";
            tempDStatus = "skipped";
          } else if (
            lanType === "ethernet" &&
            dataDevice["ethernet"]?.hasOwnProperty("errorCode") &&
            isDeviceOffline(dataDevice["ethernet"])
          ) {
            tempDStatus = "fail";
          } else if (
            (lanType === "wifi" &&
              dataDevice?.hasOwnProperty("wifi") &&
              !dataDevice["wifi"]?.hasOwnProperty("signalStrength")) ||
            (lanType === "ethernet" &&
              dataDevice?.hasOwnProperty("ethernet") &&
              !dataDevice["ethernet"]?.hasOwnProperty("ethernetStatus"))
          ) {
            tempDStatus = "skipped";
          }
        }
        let tempFName = dataDevice["device"]?.hasOwnProperty("friendlyName")
          ? dataDevice?.device?.friendlyName
          : "";
        let dataTemp = {
          deviceStatus: tempDStatus,
          friendlyName: tempFName,
        };
        let tempDevice = { ...device, ...dataTemp };
        computedDevice = tempDevice;
      }
      break;
    case "ODU":
      if (dataDevice?.hasOwnProperty("wirelessInfo")) {
        let tempDStatus = "";
        if (dataDevice["wirelessInfo"]?.hasOwnProperty("overallStatus")) {
          tempDStatus =
            dataDevice["wirelessInfo"]?.["overallStatus"]?.toLowerCase();
        } else if (
          (dataDevice["wirelessInfo"]?.hasOwnProperty("errorCode") &&
            dataDevice["wirelessInfo"]?.errorCode != 1009) ||
          dataDevice["wirelessInfo"] === ""
        ) {
          tempDStatus = "skipped";
        } else if (
          dataDevice["wirelessInfo"]?.hasOwnProperty("errorCode") &&
          dataDevice["wirelessInfo"]?.errorCode == 1009
        ) {
          tempDStatus = "fail";
        }
        let dataTemp = {
          deviceStatus: tempDStatus,
        };
        let tempDevice = { ...device, ...dataTemp };
        computedDevice = tempDevice;
      }
      break;
    case "STB":
    case "DESTB":
    case "GSTB":
    case "HSTB":
    case "IPSTB":
      let dStatus = "";
      if (
        dataDevice?.hasOwnProperty("summary") &&
        dataDevice?.hasOwnProperty("docsis")
      ) {
        let summaryStatus = "";
        let docsisStatus = "";
        if (
          (dataDevice["summary"]?.hasOwnProperty("errorCode") &&
            dataDevice["summary"]?.errorCode != 1009) ||
          dataDevice["summary"] === ""
        ) {
          summaryStatus = "skipped";
        } else if (
          dataDevice["summary"]?.hasOwnProperty("errorCode") &&
          dataDevice["summary"]?.errorCode == 1009
        ) {
          summaryStatus = "fail";
        } else {
          summaryStatus =
            dataDevice["summary"]?.["overallStatus"]?.toLowerCase();
        }
        if (
          (dataDevice["docsis"]?.hasOwnProperty("errorCode") &&
            dataDevice["docsis"]?.errorCode != 1009) ||
          dataDevice["docsis"] === ""
        ) {
          docsisStatus = "skipped";
        } else if (
          dataDevice["docsis"]?.hasOwnProperty("errorCode") &&
          dataDevice["docsis"]?.errorCode == 1009
        ) {
          docsisStatus = "fail";
        } else {
          docsisStatus = dataDevice["docsis"]?.["overallStatus"]?.toLowerCase();
        }

        if (summaryStatus === "fail" || docsisStatus === "fail") {
          dStatus = "fail";
        } else {
          dStatus = "pass";
        }
      } else if (
        dataDevice?.hasOwnProperty("summary") &&
        !dataDevice["summary"]?.hasOwnProperty("cmMac")
      ) {
        if (
          (dataDevice["summary"]?.hasOwnProperty("errorCode") &&
            dataDevice["summary"]?.errorCode != 1009) ||
          dataDevice["summary"] === ""
        ) {
          // dStatus = "fail";
          dStatus = "skipped";
        } else if (
          dataDevice["summary"]?.hasOwnProperty("errorCode") &&
          dataDevice["summary"]?.errorCode == 1009
        ) {
          dStatus = "fail";
        } else {
          dStatus = dataDevice["summary"]?.["overallStatus"]?.toLowerCase();
        }
      }
      let dataTemp = {
        deviceStatus: dStatus,
      };
      let tempDevice = { ...device, ...dataTemp };
      computedDevice = tempDevice;
      break;
    case "ASTB":
      if (dataDevice?.hasOwnProperty("summary")) {
        let tempDStatus = "";
        if (dataDevice["summary"]?.hasOwnProperty("overallStatus")) {
          tempDStatus = dataDevice["summary"]?.["overallStatus"]?.toLowerCase();
        } else if (
          (dataDevice["summary"]?.hasOwnProperty("errorCode") &&
            dataDevice["summary"]?.errorCode != 1009) ||
          dataDevice["summary"] === ""
        ) {
          tempDStatus = "skipped";
        } else if (
          dataDevice["summary"]?.hasOwnProperty("errorCode") &&
          dataDevice["summary"]?.errorCode == 1009
        ) {
          tempDStatus = "fail";
        }

        let dataTemp = {
          deviceStatus: tempDStatus,
        };
        let tempDevice = { ...device, ...dataTemp };
        computedDevice = tempDevice;
      }
      break;
    default:
      break;
  }
  return computedDevice;
};

export const getPassCount = (devices: Device[]): any => {
  let passCount = 0;
  let passDevices: Device[] = [];
  if (devices !== undefined && devices?.length !== 0) {
    devices?.map((d: any) => {
      if (d.deviceStatus === "pass") {
        passDevices.push(d);
        passCount++;
      }
    });
  }
  return { ...{ count: passCount }, ...{ devices: passDevices } };
};

export const getFailCount = (devices: Device[]): any => {
  //create device obj
  let failCount = 0;
  let failDevices: Device[] = [];
  if (devices !== undefined && devices?.length !== 0) {
    devices?.map((d: any) => {
      if (d.deviceStatus === "fail") {
        failDevices.push(d);
        failCount++;
      }
    });
  }
  return {
    ...{ count: failCount },
    ...{ devices: failDevices },
  };
};
export const getMarginalPassCount = (devices: Device[]): any => {
  //create device obj
  let MarginalPassCount = 0;
  let marginalDevices: Device[] = [];
  if (devices !== undefined && devices?.length !== 0) {
    devices
      ?.filter((d: any) => d?.deviceCategory === "CM")
      ?.map((d: any) => {
        if (d?.hasOwnProperty("deviceStatus")) {
          if (d?.deviceStatus === "marginal pass") {
            marginalDevices.push(d);
            MarginalPassCount++;
          }
        }
      });
  }
  return {
    ...{ count: MarginalPassCount },
    ...{ devices: marginalDevices },
  };
};

export const addDiagInDevice = (d: any, dataObj: any): any => {
  let tempObj = {
    status: d.deviceStatus,
    deviceType: d.deviceType,
    deviceCategory: d.deviceCategory,
    macAddress: d.macAddress,
    serialNumber: d.serialNumber,
    deviceModel: d.deviceModel,
    diagResult: dataObj.data,
    friendlyName: d.friendlyName,
    iccId: d.iccId,
    msisdn: d.msisdn,
    sectorId: d.sectorId,
    cellId: d.cellId,
    azimuthAngle: d.azimuthAngle,
  };
  return tempObj;
};
