import { useState, useEffect } from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import "../../../design-tokens/feedback/Feedback.scss";
import Rating from "./Rating";
import TechInsightIcon from "../../../resources/images/TechInishgtIcon";
import AppLabels from "../../../constants/App_Labels";
import FeedbackClient from "../../../network/httpclient/feedback/FeedbackClient";
import DiagContainer from "../../reusable/DiagContainer";
import { GetTechInfo } from "../../../get-api/GetResponse";

export default function MonthlyFeedbackModal() {
  const dispatch = useDispatch();
  const labels = AppLabels();
  const {
    data: techData,
    isLoading: techDataIsLoading,
    error: techDataError,
  } = GetTechInfo();
  const [modal, setModal] = useState(0);
  const [why, setWhy] = useState("");
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [rating, setRating] = useState(0);
  const closeModal = () => setModal(0);
  const { monthlyData, isLoading, status, monthlyDataStatus } = useSelector(
    (state: any) => ({
      monthlyData: state.GetMonthlyFeedbackInfo.data,
      monthlyDataStatus: state.GetMonthlyFeedbackInfo.status,
      isLoading: state.MonthlyFeedbackInfo.isLoading,
      status: state.MonthlyFeedbackInfo.status,
    }),
    shallowEqual
  );

  const onWhy = (e: any) => {
    setWhy(e.currentTarget.innerHTML);
  };

  const handleSubmit = (e: any) => {
    if (rating !== 0) {
      const body = JSON.stringify({
        userId: techData.lanId,
        rating: rating,
        feedback: why,
        status: "Submitted",
        uiAgent: "WEB",
      });
      e.preventDefault();
      dispatch(FeedbackClient.postMonthlyFeedback(body));
      handleReset();
    } else {
      setErrorDisplay(true);
    }
  };

  const handleDontAsk = (e: any) => {
    const body = JSON.stringify({
      userId: techData.lanId,
      status: "Declined",
      uiAgent: "WEB",
    });
    e.preventDefault();
    dispatch(FeedbackClient.postMonthlyFeedback(body));
    closeModal();
  };

  const handleReset = () => {
    setWhy("");
    setRating(1);
  };

  useEffect(() => {
    if (monthlyDataStatus === "OK" && monthlyData?.displayRatingPopup) {
      setModal(1);
    }
  }, [monthlyData]);
  useEffect(() => {
    if (rating !== 0) {
      setErrorDisplay(false);
    }
  }, [rating]);
  return (
    <Modal
      id="monthly-feedback-container"
      onHide={() => {}}
      show={modal === 1 ? true : false}
      dialogClassName="monthly-modal-90w"
    >
      {/* <Button bsPrefix="close-monthly" onClick={() => setModal(0)}>
        &#10005;
      </Button> */}
      <DiagContainer
        isLoading={techDataIsLoading}
        data={techData}
        error={techDataError}
        sectionName="Feedback"
        handleRefresh={() => {}}
      >
        <ModalBody bsPrefix="monthly-modal-body">
          <TechInsightIcon size={80} />
          {status !== "OK" ? (
            <>
              <p id="monthly-title">{labels.MONTHLY_TITLE}</p>
              <div id="align-center">
                <p className="monthly-question">{labels.MONTHLY_QUESTION}</p>
                <Rating
                  totalRating={10}
                  rating={rating}
                  onRatingClick={setRating}
                />
                <span className="ratingRadioGrpContainer">
                  <p>{labels.NOT_LIKELY}</p>
                  <p>{labels.LIKELY}</p>
                </span>

                <p className="monthly-question">{labels.MONTHLY_WHY}</p>
              </div>
              <span
                className="textarea"
                role="textbox"
                onInput={onWhy}
                contentEditable={true}
              />
              {errorDisplay ? (
                <p className="failState">
                  Please select a rating scale before submitting!
                </p>
              ) : (
                <></>
              )}
              <div className="submission">
                <button
                  className="submit-monthly"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={isLoading ? true : false}
                >
                  {labels.SUBMIT}
                </button>
                {/* <Button bsPrefix="cancel-monthly" onClick={closeModal}>
                  {labels.CANCEL}
                </Button> */}
                <button onClick={handleDontAsk} className="monthly-dont-ask">
                  {labels.DONT_ASK}
                </button>
              </div>
              {status === "ERROR" ? (
                <p className="errorMessage">{labels.ERROR_FEEDBACL_MONTHLY}</p>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              <div style={{ padding: "20px" }}>
                <p>{labels.THANK_YOU_FEEDBACK}</p>
                <hr className="hrMonthly" />
                <Button bsPrefix="reset" onClick={closeModal}>
                  {labels.CLOSE_BTN_TEXT}
                </Button>
              </div>
            </>
          )}
        </ModalBody>
      </DiagContainer>
    </Modal>
  );
}
