import { Device } from "../../../models/account/accountInfo/Device";
import { ActiveAccState } from "../../../states/account/accountInfo/ActiveAccState";

import {
  SET_ACTIVE_ACCOUNT_DETAILS,
  SET_SELECTED_SERVICE_ADDRESS,
  SORTED_DEVICE_LIST,
  SET_ACCOUNT_TYPE,
  SET_ACTIVE_SUB_TAB_INDEX,
  SET_GOOGLE_API_KEY,
  SET_FRIENDLYNAME,
  SET_DEVICE_STATUS,
  SET_WHT_FLAG,
  SET_SCQAM_FLAG,
  ENABLE_WHT_FLAG,
  SET_SELECTED_SA,
  SET_FAILED_CODEWORDS,
  SET_CODEWORD_VALUES,
  SET_ACTIVE_ACC_SEARCH_VALUE,
  SET_DEVICE_SEARCH_VALUE,
  ActiveAccountActionTypes,
  SET_DEVICE_MAC_ADDRESS_SEARCH_VALUE,
} from "../../../types/account/accountInfo/ActiveAccDetails";

const initialState: ActiveAccState = {
  data: undefined,
  activeSubTabIndex: 0,
  accountType: "",
  gApiKey: "",
  wHTFlag: false,
  enableWHT: false,
  scqam: false,
  selectedServiceAddress: "",
  accountSearchValue: "",
  deviceSearchValue: "",
  deviceMacAddressSearchValue: "",
};
const ActiveAccReducer = (
  state = initialState,
  action: ActiveAccountActionTypes
): ActiveAccState => {
  switch (action.type) {
    case SET_ACTIVE_ACCOUNT_DETAILS:
      return {
        ...state,
        data: action.payload,
      };
    case SET_ACCOUNT_TYPE:
      return {
        ...state,
        accountType: action.payload,
      };
    case SET_ACTIVE_SUB_TAB_INDEX:
      return {
        ...state,
        activeSubTabIndex: action.payload,
      };
    case SET_GOOGLE_API_KEY:
      return {
        ...state,
        gApiKey: action.payload,
      };
    case SET_WHT_FLAG:
      return {
        ...state,
        wHTFlag: action.payload,
      };

    case ENABLE_WHT_FLAG:
      return {
        ...state,
        enableWHT: action.payload,
      };
    case SET_SCQAM_FLAG:
      return {
        ...state,
        scqam: action.payload,
      };
    case SET_SELECTED_SERVICE_ADDRESS:
      return {
        ...state,
        selectedServiceAddress: action.payload,
      };
    case SET_SELECTED_SA:
      return {
        ...state,
        selectedSA: action.payload,
      };
    case SORTED_DEVICE_LIST:
      return {
        ...state,
        sortedDevices: action.payload,
      };
    case SET_FAILED_CODEWORDS:
      return {
        ...state,
        ...{ failedAttributes: action.payload },
      };
    case SET_CODEWORD_VALUES:
      return {
        ...state,
        codeword: action.payload,
      };
    case SET_DEVICE_STATUS:
      return {
        ...state,
        sortedDevices: state?.sortedDevices?.map((d: Device) => {
          if (
            d.serialNumber === action.devicekey &&
            d.deviceCategory === action.deviceCategory
          ) {
            return { ...d, ...{ deviceStatus: action.deviceStatus } };
          } else {
            return d;
          }
        }),
      };
    case SET_FRIENDLYNAME:
      return {
        ...state,
        sortedDevices: state?.sortedDevices?.map((d: Device) => {
          if (
            d.serialNumber === action.devicekey &&
            d.deviceCategory === action.deviceCategory
          ) {
            return { ...d, ...{ friendlyName: action.friendlyName } };
          } else {
            return d;
          }
        }),
      };
    case SET_DEVICE_SEARCH_VALUE:
      return {
        ...state,
        deviceSearchValue: action.payload,
      };
    case SET_ACTIVE_ACC_SEARCH_VALUE:
      return {
        ...state,
        accountSearchValue: action.payload,
      };

    case SET_DEVICE_MAC_ADDRESS_SEARCH_VALUE: 
      return {
        ...state,
        deviceMacAddressSearchValue: action.payload,
      };

    default:
      return state;
  }
};
export default ActiveAccReducer;
