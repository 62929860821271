import { Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import { InfoBox, InfoWindow ,Marker} from "@react-google-maps/api";
import appConst from "../../../../constants/appConst";
import TopologySymbols from "../../../reusable/TopologySymbols";
import { useEffect, useState } from "react";
import { BsArrowsMove } from "react-icons/bs";
import { markerToString } from "../../../../util/utilFuncs";
import colors from "../../../../config/colors";
import { mapElementStyles } from "../../../../config/mapItemsConfig";

interface Props {
  commonAnnotationData: any;
  click: any;
  setClick: any;
}

const CommonAnnotationBlocks = ({
  commonAnnotationData,
  click,
  setClick,
}: Props) => {
  const latLng = new google.maps.LatLng(
    commonAnnotationData?.geometry?.coordinates?.[1],
    commonAnnotationData?.geometry?.coordinates?.[0]
  );

  const position = {
    lat: Number(commonAnnotationData?.geometry?.coordinates?.[1]),
    lng: Number(commonAnnotationData?.geometry?.coordinates?.[0]),
  };
  const lat=commonAnnotationData?.geometry?.coordinates?.[1];
  const lng=commonAnnotationData?.geometry?.coordinates?.[0];
  const divStyle = {
    background: `white`,
  };
  const showInfoWindow = () => {
    setClick(false);
  };
  const onCloseClick = () => {
    setClick(false);
  };
  const [showInfoBox, setShowInfoBox] = useState(false);
  useEffect(() => {
    setShowInfoBox(true);
  }, []);

  return (
    <div onClick={showInfoWindow}>
      {click && (
        <InfoWindow
          position={position}
          options={{ pixelOffset: new google.maps.Size(75, 0) }}
          onCloseClick={onCloseClick}
        >
          <div style={divStyle}>
            <TopologySymbols
              name="AuroraNodeAnno"
              color={`var(--iconColor)`}
              size={150}
            />
          </div>
        </InfoWindow>
      )}
      <Marker
      position={{lat,lng }}
      icon={{
        url: markerToString(
          <BsArrowsMove color={colors.greyLight} size={20} />
        ),
        anchor:new google.maps.Point(mapElementStyles?.common?.anchor?.x - 70,
          mapElementStyles?.common?.anchor?.y + 3)
      }}
      draggable
      cursor="move"
      zIndex={4000}
    >
     {showInfoBox && ( <InfoBox
        position={latLng}
        options={{
          closeBoxURL: "",
          disableAutoPan: true,
          boxStyle: {
            width: "auto",
            padding: "5px",
          },
        }}
      >
        <Table className="annotationBlock">
          <TableBody>
            <TableRow>
              <TableCell>{appConst?.fd}</TableCell>
              <TableCell>{appConst?.rn}</TableCell>
              <TableCell>{appConst?.rfPorts?.toUpperCase()}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {commonAnnotationData?.properties?.data?.tagText?.FD}
              </TableCell>
              <TableCell>
                {commonAnnotationData?.properties?.data?.tagText?.RN}
              </TableCell>
              <TableCell>
                {
                  commonAnnotationData?.properties?.data?.tagText[
                    appConst?.rfPorts
                  ]
                }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </InfoBox>)}
      </Marker>
    </div>
  );
};

export default CommonAnnotationBlocks;
