import { combineReducers } from "redux";
import { GponNodeReducer } from "./GponNodeInfoReducer";
import { GponNodeSummaryReducer } from "./GponNodeSummaryReducer";
import oltNameSearchDetails from "../../slices/gpon/oltNameSearchDetails";
import {PonOltReducer} from "./PonOltInfoReducer";
export const allGponNodeReducer = combineReducers({
  GponNodeInfo: GponNodeReducer,
  GponNodeSummary: GponNodeSummaryReducer,
  OltResultDetails: oltNameSearchDetails,
  PonOltInfo: PonOltReducer,
});
