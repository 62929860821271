import { NotificationInfo } from "../../models/notification/NotificationInfo";

export const NOTIFICATION_CRUD_BEGIN = "NOTIFICATION_CRUD_BEGIN";
export const NOTIFICATION_CRUD_SUCCESS = "NOTIFICATION_CRUD_SUCCESS";
export const NOTIFICATION_CRUD_FAILURE = "NOTIFICATION_CRUD_FAILURE";
export const NOTIFICATION_CRUD_RESET = "NOTIFICATION_CRUD_RESET";
interface NotificationCRUDBegin {
  type: typeof NOTIFICATION_CRUD_BEGIN;
  rType: String;
}

interface NotificationCRUDSuccess {
  type: typeof NOTIFICATION_CRUD_SUCCESS;
  payload: NotificationInfo;
}

interface NotificationCRUDFailure {
  type: typeof NOTIFICATION_CRUD_FAILURE;
  payload: Error;
}
interface NotiCRUDReset {
  type: typeof NOTIFICATION_CRUD_RESET;
}
export type NotificationCRUDActionTypes =
  | NotificationCRUDBegin
  | NotificationCRUDSuccess
  | NotificationCRUDFailure
  | NotiCRUDReset;
