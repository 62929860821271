import {ONTBandwidth} from '../../../../models/account/diagnostics/ont/ONTBandwidth';
import {
  ONT_BANDWIDTH_BEGIN,
  ONT_BANDWIDTH_SUCCESS,
  ONT_BANDWIDTH_FAILURE,
  ONTBandwidthActionTypes,
} from '../../../../types/account/diagnostics/ont/ONTBandwidth';

export function ONTBandwidthBegin(): ONTBandwidthActionTypes {
  return {
    type: ONT_BANDWIDTH_BEGIN,
  };
}

export function ONTBandwidthSuccess(
  ONTBandwidth: ONTBandwidth,
): ONTBandwidthActionTypes {
  return {
    type: ONT_BANDWIDTH_SUCCESS,
    payload: ONTBandwidth,
  };
}

export function ONTBandwidthFailure(error: Error): ONTBandwidthActionTypes {
  return {
    type: ONT_BANDWIDTH_FAILURE,
    payload: error,
  };
}
