import {
  ADD_ACCOUNT,
  SET_ACTIVE_ACCOUNT_KEY,
  AllAccountActionTypes,
  DELETE_ACCOUNT,
  SWITCH_ACCOUNT,
  RESET_ALL_ACCOUNTS,
  UPDATE_ACCOUNT,
} from "../../types/account/AllAccounts";

export function AddAccount(
  accNum: string,
  accState: any
): AllAccountActionTypes {
  return {
    type: ADD_ACCOUNT,
    accNum: accNum,
    payload: accState,
  };
}

export function SetActiveAccountKey(accNum: string): AllAccountActionTypes {
  return {
    type: SET_ACTIVE_ACCOUNT_KEY,
    accNum: accNum,
  };
}

export function DeleteAccount(accNum: string): AllAccountActionTypes {
  return {
    type: DELETE_ACCOUNT,
    accNum: accNum,
  };
}

export function UpdateAccount(
  accNum: string,
  accState: any
): AllAccountActionTypes {
  return {
    type: UPDATE_ACCOUNT,
    accNum: accNum,
    payload: accState,
  };
}

export function ChangeAccState(accState: any): AllAccountActionTypes {
  return {
    type: SWITCH_ACCOUNT,
    payload: accState,
  };
}
export function ResetAllAccState(): AllAccountActionTypes {
  return {
    type: RESET_ALL_ACCOUNTS,
  };
}
