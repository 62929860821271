import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Divider } from "@material-ui/core";
import { DropdownButton, Dropdown, Button } from "react-bootstrap";

import "../../../design-tokens/forms/PJI.scss";
import AppLabels from "../../../constants/App_Labels";
import ConfigConst from "../../../constants/ConfigConst";
import {
  GetActiveAccountDetails,
  GetFormHistory,
  GetFormInfo,
} from "../../../get-api/GetResponse";
import FormClient from "../../../network/httpclient/form/FormClient";
import ResponseSnackBar from "../../../components/reusable/ResponseSnackBar";
import FormBoilerplate from "../FormBoilerplate";
import WorkDetails from "./WorkDetails";
import { isLegacyAccount } from "../../reusable/Util";

interface Props {
  setShowModal: any;
  isFromCertfication?: boolean;
  workOrderId?: any;
  formHistoryData?: any;
  acc?: any;
}

export default function PreJobInspectionScreen(props: Props) {
  const labels = AppLabels();
  const childCompRef = useRef<any>(null);
  const dispatch = useDispatch();
  const formHistory = GetFormHistory();
  const accountNumber = props?.acc
    ? undefined
    : GetActiveAccountDetails()?.accountNumber;
  const formInfo = GetFormInfo();
  const orderTypeData = [labels.Installation, labels.Service,labels.Maintenance];
  const acceptedWOFirstChar = isLegacyAccount() ? "C" : "W";
  const [formHistoryInfo, setFormHIstoryInfo] = useState(false);
  const [formHistoryId, setFormHistoryId] = useState("");
  const [formHistoryData, setFormHistoryData] = useState("");
  const [formAccountNumber, setFormAccountNumber] = useState("");
  ///////// All states ////////////////
  const [bannerVisible, setBannerVisible] = useState(false);
  const [orderType, setOrderType] = useState(orderTypeData[0]);

  const [isWOSelectionComplete, setIsWOSelectionComplete] = useState(
    props.isFromCertfication ? true : false
  );
  const [woData, setWOData] = useState({ orderType: orderType });
  const [selectedWOID, setSelectedWOID] = useState(
    props.workOrderId ? props.workOrderId : labels.Select
  );
  const [isRequiredDataCompleted, setisRequiredDataCompleted] = useState(false);

  //show banner for 2 sec then close the entire modal
  useEffect(() => {
    if (bannerVisible && !formInfo.isLoading) {
      dispatch(FormClient.getForm(selectedWOID, ""));
      setTimeout(() => props.setShowModal(false), 2000);
    }
  }, [bannerVisible, formInfo.isLoading]);
  useEffect(() => {
    formHistory?.dataList?.some((e: any) => {
      if (e?.formName === ConfigConst.PRE_JOB_INSPECTION) {
        setFormHIstoryInfo(true);
        setFormHistoryId(e?.id);
        setFormAccountNumber(e?.accountNumber);
        setFormHistoryData(e?.formData);
        setOrderType(
          orderTypeData[orderTypeData.indexOf(e?.formData?.orderType)]
        );
      }
    });
  }, [formHistory?.dataList]);

  const onSubmit = () => {
    childCompRef?.current?.handleSubmit();
  };

  const onApiCall = (data: any) => {
    const pjiApiData = {
      formName: ConfigConst.PRE_JOB_INSPECTION,
      workorderId: selectedWOID,
      accountNumber:
        accountNumber !== undefined
          ? accountNumber
          : formAccountNumber
          ? formAccountNumber
          : "",
      cbpid: props?.acc?.length === 9 ? props?.acc : "",
      formData: JSON.stringify({ ...woData, ...data }),
    };
    if (formHistoryInfo === true) {
      //check if history form has acc then dont change...
      dispatch(FormClient.updateForm(pjiApiData, formHistoryId));
    } else dispatch(FormClient.postForm(pjiApiData));
  };

  const disableSubmit = () => {
    if (
      isWOSelectionComplete &&
      isRequiredDataCompleted &&
      !formInfo.isLoading
    ) {
      return false;
    } else return true;
  };

  return (
    <>
      {props.isFromCertfication ? (
        <div style={{ flex: 1 }}>
          <div style={{ marginTop: "20px" }}>
            <label className="labelTxt">{labels.Work_Order_ID}:&nbsp;</label>
            <label>{props.workOrderId}</label>
          </div>
          <div className="WorkDetailscontainer">
            <label className="labelTxt">{labels.Select_Order_Type}*</label>
            <DropdownButton
              title={orderType}
              onSelect={(v: any) => {
                setOrderType(v);
                setWOData({ orderType: v });
              }}
              size="sm"
              id="dropdownBtn"
            >
              {orderTypeData.map((e, i) => (
                <Dropdown.Item key={i} eventKey={e}>
                  {e}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
          <Divider style={{ margin: "20px 0px 20px 0px" }} />
          <WorkDetails
            setRequiredDataFilled={setisRequiredDataCompleted}
            onApiCall={onApiCall}
            formData={props.formHistoryData}
            ref={childCompRef}
          />
          <Divider style={{ margin: "20px 0px 20px 0px" }} />
          <div className="pjiSubmitBtnContainer">
            <Button
              onClick={() => {
                setBannerVisible(true);
                onSubmit();
              }}
              disabled={disableSubmit()}
              className="pjiSubmitButton"
            >
              {formHistoryInfo === true
                ? labels.Update.toUpperCase()
                : labels.SUBMIT}
            </Button>
          </div>
          <ResponseSnackBar
            open={bannerVisible}
            requestType={labels.PJI}
            handleClose={setBannerVisible}
            responseType={formInfo?.status}
          />
        </div>
      ) : (
        <FormBoilerplate
          isWOSelectionComplete={isWOSelectionComplete}
          setIsWOSelectionComplete={setIsWOSelectionComplete}
          setWOData={setWOData}
          formLabel={labels.PJI}
          formName={ConfigConst.PRE_JOB_INSPECTION}
          onSubmit={onSubmit}
          disableSubmit={disableSubmit}
          selectedWOID={selectedWOID}
          setSelectedWOID={setSelectedWOID}
          setShowEntireModal={props.setShowModal}
          disableFormUpdate={false}
        >
          {selectedWOID?.length === 13 ||
          [acceptedWOFirstChar, acceptedWOFirstChar?.toLowerCase()]?.indexOf(
            selectedWOID.charAt(0)
          ) != -1 ? (
            <WorkDetails
              setRequiredDataFilled={setisRequiredDataCompleted}
              onApiCall={onApiCall}
              formData={formHistoryData}
              ref={childCompRef}
            />
          ) : (
            <></>
          )}
        </FormBoilerplate>
      )}
    </>
  );
}
