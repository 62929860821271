import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import TopologyLabels from "../../../constants/topologyLabels";
import { updateDeleteComment } from "../../../store/slices/mapItems/comments/comments";
interface props {
  filteredData: any;
  setEditModal: any;
  dispatch: any;
}

const EditCommentsModal = ({ filteredData, setEditModal, dispatch }: props) => {
  const labels = TopologyLabels();
  const [comment, setComment] = useState(filteredData?.comment);

  const onEditClick = () => {
    setEditModal(false);
    dispatch(
      updateDeleteComment(filteredData?.networkId, filteredData?.id, {
        comment: comment,
      })
    );
  };

  return (
    <>
      <Modal
        style={{ position: "absolute" }}
        show={true}
        size="lg"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header className="searchPopHead">
          {labels.edit} {labels.comment}
          <Button bsPrefix="close" onClick={() => setEditModal(false)}>
            &#10005;
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="popUpCont">
            <div className="selectDrawing">
              <Form.Group className="selector">
                <Form.Control
                  name="description"
                  as="textarea"
                  rows={5}
                  className="description"
                  id="description"
                  maxLength={1000}
                  placeholder={labels.typeComment}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="popUpSubmitCancelButton">
              <button
                className="popUpCancelButton"
                style={{ marginRight: "20px" }}
                onClick={() => {
                  setEditModal(false);
                }}
              >
                {labels.cancel}
              </button>
              <button className="popUpSubmitButton" onClick={onEditClick}>
                {labels?.edit}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default EditCommentsModal;
