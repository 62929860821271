import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DEVICE_CATEGORY } from "../../constants/CustomerDeviceTypes";
import { AccountSortedDevices } from "../../store/actions/account/accountInfo/ActiveAccountAction";
import { SaveActiveDiagDevices } from "../../store/actions/account/diagnostics/ActiveDiagDevicesActions";
import { Device } from "../../store/models/account/accountInfo/Device";
import { DiagDevice } from "../../store/models/account/diagnostics/DiagDevice";
import { filterDevicesByTopology, isUpdated } from "../reusable/Util";

export default function SetAccountDevices() {
  const { serviceAreaInfo, selSA, accType, allAccounts, data } = useSelector(
    (state: any) => ({
      serviceAreaInfo: state.AccountState.ServiceAreaInfo.data,
      selSA: state.AccountState.ActiveAccountDetails.selectedSA,
      accType: state.AccountState.ActiveAccountDetails.accountType,
      data: state.AccountState.ActiveAccountDetails.data,
      allAccounts: state.AllAccounts.accounts,
    }),
    shallowEqual
  );
  const sortList = DEVICE_CATEGORY?.map((item, key) => item.category);
  const dispatch = useDispatch();

  useEffect(() => {
    let devices: Device[] = [];
    selSA?.subscription?.map((sub: any) => {
      sub.deviceInfo?.map((d: Device) => devices.push(d));
    });
    if (
      serviceAreaInfo !== undefined &&
      serviceAreaInfo?.hasOwnProperty("serviceAddressInfo") &&
      serviceAreaInfo.serviceAddressInfo?.hasOwnProperty(
        "generalAddressInfo"
      ) &&
      serviceAreaInfo.serviceAddressInfo.generalAddressInfo?.hasOwnProperty(
        "ontSerialNumber"
      )
    ) {
      devices.push({
        atamac: "",
        cmmac: "",
        deviceModel: "",
        deviceType: "ONT",
        deviceName: "ONT",
        deviceCategory: "ONT",
        deviceVersion: "",
        equipmentManufacturer: "",
        ipAddress: "",
        macAddress: "",
        oui: "",
        phoneNumber: "",
        serialNumber:
          serviceAreaInfo.serviceAddressInfo.generalAddressInfo.ontSerialNumber,
      });
    }
    if (devices?.length !== 0) {
      let sortDevices = devices.sort((a, b) => {
        return (
          sortList?.indexOf(a.deviceCategory) -
          sortList?.indexOf(b.deviceCategory)
        );
      });
      if (!isUpdated(allAccounts, data?.accountNumber)) {
        dispatch(AccountSortedDevices(sortDevices));
      }

      let computedDevices = filterDevicesByTopology(sortDevices, accType);

      let DiagDevices: DiagDevice[] = computedDevices?.filteredDevices?.map(
        (d: any) => {
          return {
            isLoading: false,
            inProgressCalls: 0,
            deviceKey:
              d.deviceCategory === "IEMTA"
                ? d.deviceCategory + d.serialNumber
                : d.serialNumber,
            deviceStatus: "",
            data: undefined,
          };
        }
      );
      if (!isUpdated(allAccounts, data?.accountNumber)) {
        dispatch(SaveActiveDiagDevices(DiagDevices));
      }
    }
  }, [selSA, serviceAreaInfo, accType]);

  return <div></div>;
}
