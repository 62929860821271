import { Divider } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import AppLabels from "../../constants/App_Labels";
import ConfigConst from "../../constants/ConfigConst";
import { DEVICE_CATEGORY } from "../../constants/CustomerDeviceTypes";
import "../../design-tokens/account-page/DiagnosticsTab.scss";
import ONTClient from "../../network/httpclient/ont/ONTClient";
import {
  GetActiveAccountDetails,
  GetTechInfo,
  GetWorkOrderDetails,
  GetXLEVisibilityDetails
} from "../../get-api/GetResponse";
import { useStbDetailsClient } from "../../network/httpclient/accountInfo/StbDetailsClient";
import HideMenubtn from "../../resources/images/icons/hide_menu.svg";
import ShowMenubtn from "../../resources/images/icons/show_menu.svg";
import { SetWHTFlag } from "../../store/actions/account/accountInfo/ActiveAccountAction";
import {
  setIsPrecheckDone,
  setPrecheckDiagnosticData,
} from "../../store/ducksPattern/precheckDiagnosticData";
import { Device } from "../../store/models/account/accountInfo/Device";
import  XLEPodDiagnostics  from "../pods/XLEPodDiagnostics";
import { setSource } from "../../store/slices/redirectSource";
import DeviceDiagnostics from "../diagnostics/DeviceDiagnostics";
import DeviceOverView from "../overview/DeviceOverView";
import PodsDiagnostics from "../pods/PodsDiagnostics";
import PodsListView from "../pods/PodsListView";
import PodsOverViewTable from "../pods/PodsOverViewTable";
import CustomPopOver from "../reusable/CustomPopOver";
import CustomTab from "../reusable/CustomTab";
import ExceptionBox from "../reusable/ExceptionBox";
import { addDiagInDevice } from "../reusable/StoreCertificationObj";
import {
  getAccessLevelByRoles,
  isEqualDevices,
  isNull,
  isLegacyAccount,hasWXLEorWXBB
} from "../reusable/Util";
import PerformWHT from "./PerformWHT";
import DiagApiCallCont from "../reusable/DiagApiCallCont";

export interface DeviceDiag {
  tab: string;
  label: string;
  active: boolean;
  isOpen: boolean;
}

const DiagnosticsTab = (props: any) => {
  const { macAddress, device, isPodRequired } = props;
  const {
    sortedDevices,
    WHTFlag,
    diagDevicesData,
    selectedServiceAddress,
    podList,
    podError,
    podsLoading,
    selectedSA,
    accType,
    accSearchValue,
    source,
    deviceSearchValue,
    redirectSource,
    precheckDiagnosticData,
    deviceMacAddressSearchValue,
    ontSummaryDetails
  } = useSelector(
    (state: any) => ({
      sortedDevices: state.AccountState.ActiveAccountDetails.sortedDevices,
      WHTFlag: state.AccountState.ActiveAccountDetails.wHTFlag,
      selectedServiceAddress:
        state.AccountState.ActiveAccountDetails.selectedServiceAddress,
      selectedSA: state.AccountState.ActiveAccountDetails.selectedSA,
      accType: state.AccountState.ActiveAccountDetails.accountType,
      podList: state.AccountState.PodInfo.data,
      podError: state.AccountState.PodInfo.error,
      podsLoading: state.AccountState.PodInfo.isLoading,
      diagDevicesData: state.AccountState.DiagDevicesInfo.diagDevices,
      accSearchValue:
        state.AccountState.ActiveAccountDetails.accountSearchValue,
      source: state.slices.redirectSource.source,
      deviceSearchValue:
        state.AccountState.ActiveAccountDetails.deviceSearchValue,
      redirectSource: state.slices.redirectSource.source,
      precheckDiagnosticData:
        state.AccountState.Certification.PrecheckDiagnosticInfo,
      deviceMacAddressSearchValue:
        state.AccountState.ActiveAccountDetails.deviceMacAddressSearchValue,
        ontSummaryDetails:state.AccountState.ontSummary.data,
    }),
    shallowEqual
  );
  const flagXLE= GetXLEVisibilityDetails();
  const labels = AppLabels();
  const dispatch = useDispatch();
  const hasDevice=(isPodRequired !== undefined && isPodRequired !== "" )||(flagXLE?.data && hasWXLEorWXBB(sortedDevices));
  const hasXFIDevice=(flagXLE?.data && hasWXLEorWXBB(sortedDevices));
 

  const { isMaintenanceRoleAvailable, roles, isOnlySTRoleAvailable } =
    GetTechInfo();
  const [devices, setDevices] = useState<Device[]>(sortedDevices);
  const woData = GetWorkOrderDetails()?.data;
  const activeAccNum = GetActiveAccountDetails()?.data?.accountNumber;
  const activeAccCbpId = GetActiveAccountDetails()?.data?.cbpId;
  const isLegacyAcc = isLegacyAccount();
  useEffect(() => {
    setDevices(sortedDevices);
  }, [sortedDevices]);

  const isAllCMDataAvailable = (device: any) => {
    const data = device?.diagResult;
    if (
      data?.docsis?.errorCode ||
      data?.summary?.errorCode ||
      data?.nodeInfo?.errorCode
    ) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    let isAllDevicesLoading = false;
    diagDevicesData?.map((e: any) => {
      if (
        e.isLoading === true ||
        e.data === undefined ||
        e.inProgressCalls != 0
      ) {
        isAllDevicesLoading = true;
      } else isAllDevicesLoading = false;
    });
    if (isAllDevicesLoading === false) {
      let devicesObj: any = [];
      SetWHTFlag(isAllDevicesLoading);
      //perform precheck data storage if all api calls are completed
      const allApiCallsCompleted = diagDevicesData.every(
        (e: any) => e.inProgressCalls === 0 || e.isLoading === false
      );
      if (allApiCallsCompleted) {
        sortedDevices?.map((e: any) => {
          diagDevicesData?.map((o: any) => {
            if (e.serialNumber === o.deviceKey) {
              let newDevice = addDiagInDevice(e, o);
              devicesObj.push(newDevice);
            }
          });
        });
        //save precheck devices if not already saved to redux
        if (
          precheckDiagnosticData?.isPrecheckDone === false &&
          devicesObj.length > 0
        ) {
          //check if data is missing for CM devices
          const isCMDataMissing = devicesObj?.some((e: any) => {
            return (
              e?.deviceCategory === "CM" && isAllCMDataAvailable(e) === false
            );
          });
          dispatch(setPrecheckDiagnosticData(devicesObj));
          //if there is no missing data, finalize precheck test
          if (!isCMDataMissing) {
            dispatch(setIsPrecheckDone(true));
          }
        }
      }
      SetWHTFlag(isAllDevicesLoading);
    }
  }, [diagDevicesData]);

  useEffect(() => {
    setDiagView("tableView");
  }, [selectedServiceAddress]);

  const sortList = DEVICE_CATEGORY?.map((item, key) => item.category);
  const [selDValue, setSelDValue] = useState<number | undefined>();
  const [selDevice, setSelDevice] = useState<Device>();
  const [gwMAC, setgwMAC] = useState<string | undefined>(undefined);


  const [selPodIndex, setSelPodIndex] = useState(0);
  const [diagView, setDiagView] = useState("tableView");

  const [deviceDiagActions, setDeviceDiagActions] = useState<DeviceDiag[]>([]);
  const [selDiagAction, setSelDiagAction] = useState("");
  const handleActionClick = (tab: string) => setSelDiagAction(tab);
  const [currMacSearch, setcurrMacSearch] = useState("");
  const stbInfo = useStbDetailsClient();

  // const handleViewChange = () => {
  //   // if (sortedDevices !== undefined && sortedDevices?.length !== 0) {
  //   //   setSelDValue(0);
  //   // }

  useEffect(() => {
    const hexPattern = /^[0-9A-Fa-f]{12}$/;
    let isMac = macAddress?.length === 12 && hexPattern.test(macAddress);

    if (isMac) {
      setcurrMacSearch(macAddress);
    }
  }, []);

  useEffect(() => {
    if (devices?.length === 0) {
      setSelDValue(-1);
    } else if (devices?.length === 1) {
      setSelDValue(0);
    }
    //defaulting to ONT tab for maintenance role on ONT search
    // if (diagView && diagView === "tableView") {
    //   //setSelDValue(undefined);
    // }
    //defaulting to ONT tab for mainatance role on ONT search
    if (
      // isMaintenanceRoleAvailable &&
      diagView &&
      diagView === "tableView" &&
      devices?.length !== 0 &&
      selectedSA?.topology?.toLowerCase() === "gpon"
    ) {
      if (devices !== undefined && devices?.length > 1) {
        const isOntDevice = devices.some(
          (e: any) =>
            e.deviceType === "ONT" && e.serialNumber === accSearchValue
        );
        const deviceIndex = getAccDiaDeviceIndex(devices);
        if (isOntDevice) {
          setSelDValue(1);
        } else if (deviceIndex >= 0) {
          setSelDValue(deviceIndex);
        }
      }
    }

    if (
      isMaintenanceRoleAvailable &&
      diagView &&
      diagView === "tableView" &&
      devices?.length !== 0 &&
      selectedSA?.topology?.toLowerCase() !== "gpon" &&
      accSearchValue !== undefined
    ) {
      if (devices !== undefined && devices?.length > 1) {
        const deviceIndex = getAccDiaDeviceIndex(devices);
        if (deviceIndex >= 0) {
          setSelDValue(deviceIndex);
        }
      }
    }
  }, [diagView, selectedSA, roles, devices]);

  useEffect(() => {
    if (
      isOnlySTRoleAvailable &&
      (redirectSource === "WO Precheck" || woData?.status === labels.Enroute) &&
      diagDevicesData.every((d: any) => d?.data === undefined) &&
      (woData?.accountNumber === activeAccNum ||
        woData?.accountNumber === activeAccCbpId)
    ) {
      handleRunWHT();
      dispatch(setSource("WO Precheck Completed"));
    }
  }, [redirectSource]);
  useEffect(()=>{
  if(devices?.length !== 0 &&
    selectedSA?.topology?.toLowerCase() == "gpon" && ontSummaryDetails==undefined ){
      if (devices !== undefined && devices?.length > 1) {
        devices?.some(
          (e: any) =>
            e.deviceCategory==="ONT" &&  (
               dispatch(ONTClient.getONTSummaryWithoutIncrement(e.serialNumber))
        )
        );
    // dispatch(ONTClient.getONTSummary(selectedDevice.serialNumber));
  }}
  },[devices])

  // async function fetchStbAccountDetails(accSearchValue: any) {
  //   const response = await stbInfo.getStbAccountDetails(accSearchValue);
  //   console.log("TEST");
  //   return response;
  // }

  const getAccDiaDeviceIndex = (devices: any): number => {
    const cmSet = new Set<string>(["CM"]);
    const emtaSet = new Set<string>(["EMTA", "IEMTA", "IHP", "IMTA"]);
    const ecmSet = new Set<string>(["DESTB", "STB"]);
    const stbSet = new Set<string>(["DESTB", "STB"]);
    // 0 or -1 are already handled

    if (/^EMTA$/i.test(device)) {
      for (let i = 0; i < devices.length; i++) {
        if (emtaSet.has(devices[i]?.deviceCategory.toUpperCase())) {
          // console.log("TEST", devices[i]?.deviceCategory);
          return i;
        }
      }
      return 1;
    }
    if (
      deviceSearchValue === "" &&
      accSearchValue === "" &&
      deviceMacAddressSearchValue === ""
    ) {
      return -1;
    }
    if (!deviceSearchValue) {
      for (let i = 0; i < devices.length; i++) {
       if(devices[i]?.macaddress !="" &&devices[i]?.macAddress!=""&&devices[i]?.serialNumber!="")
        { 
          if (
          devices[i]?.macaddress === accSearchValue ||
          devices[i]?.macaddress === deviceMacAddressSearchValue ||
          devices[i]?.macAddress === accSearchValue ||
          devices[i]?.macAddress === deviceMacAddressSearchValue ||
          devices[i]?.serialNumber === accSearchValue ||
          devices[i]?.serialNumber === deviceMacAddressSearchValue
        ) {
          return i;
        }
      }
      }
    }

    // if (!deviceMacAddressSearchValue) {
    //   fetchStbAccountDetails(accSearchValue).then((response) => {
    //     for (let i = 0; i < devices.length; i++) {
    //       if (devices[i]?.macaddress === String(response?.stbMac)) {
    //         setSelDValue(i);
    //         return i;
    //       }
    //     }
    //   });
    // }

    if (currMacSearch) {
      for (let i = 0; i < devices.length; i++) {
        if (devices[i]?.macaddress === currMacSearch) {
          return i;
        }
      }
    }

    

    const isMacAdd: boolean = devices.some(
      (e: any) => e.macaddress === accSearchValue
    );

    for (let index = 0; index < devices.length; index++) {
      const dev = devices[index];

      if (dev?.hasOwnProperty("macaddress")) {
        if (
          deviceSearchValue?.toUpperCase() === "CM" &&
          cmSet.has(dev?.deviceCategory.toUpperCase())
        ) {
       
          return index;
        } else if (
          deviceSearchValue?.toUpperCase() === "EMTA" &&
          emtaSet.has(dev?.deviceCategory.toUpperCase()) &&
          isMacAdd
        ) {
          return index;
        } else if (
          deviceSearchValue?.toUpperCase() === "ECM" &&
          ecmSet.has(dev?.deviceCategory.toUpperCase())
        ) {
          return index;
        } else if (
          deviceSearchValue?.toUpperCase() === "STB" &&
          stbSet.has(dev?.deviceCategory.toUpperCase())
        ) {
          let firstStbIndex = index;

          for (let i = 0; i < devices.length; i++) {
            if (
              devices[i]?.macaddress === accSearchValue ||
              devices[i]?.macaddress === deviceMacAddressSearchValue ||
              devices[i]?.macAddress === accSearchValue ||
              devices[i]?.macAddress === deviceMacAddressSearchValue ||
              devices[i]?.serialNumber === accSearchValue ||
              devices[i]?.serialNumber === deviceMacAddressSearchValue
            ) {
              return i;
            }
          }

          return firstStbIndex;
        }
      }
    }
    return -1;
  };

  const techData = GetTechInfo()?.data;
  const isPlatformTech = getAccessLevelByRoles(techData?.roles).includes(
    ConfigConst.PT
  );
  // const filteredDiagActions = () => {
  //   const maintRole = checkRoles(techData?.roles);
  //   if (maintRole && !isPlatformTech) {
  //     return deviceDiagActions?.filter(
  //       (action: any) => !["CW", "CM"].includes(action.tab)
  //     );
  //   } else {
  //     return deviceDiagActions;
  //   }
  // };
  const handleDeviceAction=(deviceActions: any) => {
    const actionExits=deviceActions?.some((e: any) => 
     e?.action ==="Reboot");
    if(!actionExits)
    deviceActions.push({action: "Reboot", label: "Reboot"});
    return deviceActions;
  
  };
  const diagActionsData = useMemo(() => {
    let computedActions = deviceDiagActions;
    computedActions?.map((action: any) => {
      if (action.tab === selDiagAction) {
        action.active = true;
      } else action.active = false;
    });
    return computedActions;
  }, [selDiagAction]);

  useEffect(() => {
    if (selDValue !== undefined && devices?.length !== 0) {
      setDiagView("tabView");
      if (selDValue !== devices?.length + 1) {
        setSelDevice(sortedDevices[selDValue]);
        let deviceDiag = getDeviceDiag(devices[selDValue].deviceCategory);
        setDeviceDiagActions(deviceDiag);
        if (deviceDiag?.length !== 0) {
          setSelDiagAction(deviceDiag?.[0]?.tab);
          deviceDiag?.map((diag: any, i: number) => {
            if (i !== 0) diag.isOpen = false;
          });
        } else setSelDiagAction("");
      }
    }
  }, [selDValue]);
  const getDeviceDiag = (deviceName: string): DeviceDiag[] => {
    let diagArr: DeviceDiag[] = [];

    DEVICE_CATEGORY?.map((d: any) => {
      if (
        deviceName === d.category &&
        d.topology?.indexOf(accType) > -1 &&
        d?.hasOwnProperty("deviceDiagnostics")
      ) {
        if(isLegacyAcc && d.category ==="CM"){
          diagArr=  d.deviceDiagnostics.filter((e:any)=>
        e.tab !=="Ethernet");         
        } 
        //flagXLE-> true in db then show tab
         if(!(flagXLE?.data && d.category ==="CM"&&devices.some((d:any)=> d.deviceType==="WXLE"||
        d.deviceType==="WXBB" )))
          {
          diagArr=  d.deviceDiagnostics.filter((e:any)=>
        e.tab !=="GWStatus"); 
         
        
        }  
        const cmDevice = devices?.find((f: any) => f.deviceCategory === "CM");
        if (cmDevice) {
            setgwMAC(cmDevice?.macAddress);
        }
        
        
        else {
          diagArr = d.deviceDiagnostics;
        }
      }
    });
    return diagArr;
  };

  const handlePodClick = (podIndex: number) => {
    setSelPodIndex(podIndex);
  };
  let WHTResponse = PerformWHT(WHTFlag);
  useEffect(() => {
    if (
      WHTResponse?.devices !== undefined &&
      WHTResponse?.devices?.length !== 0
    ) {
      let sortDevices = WHTResponse?.devices.sort((a, b) => {
        return (
          sortList?.indexOf(a.deviceCategory) -
          sortList?.indexOf(b.deviceCategory)
        );
      });
      let matchDevices = isEqualDevices(sortDevices, sortedDevices);
      if (matchDevices) setDevices(sortDevices);
    }
  }, [WHTResponse]);

  const handleRunWHT = () => {
    dispatch(SetWHTFlag(true));
  };
  const [leftmenu, setLeftMenu] = useState(true);
  let hideShowTitle = leftmenu
    ? "Collapse Device panel"
    : "Expand Device Panel";
    const containsONT=  devices?.some((item, key) => item.deviceCategory==="ONT");
  return (
    <div className="diagnosticsCont">
      <section className={leftmenu ? "leftSection" : "toggleleftSection"}>
        <CustomPopOver
          position="bottom"
          popOverData={hideShowTitle}
          originComp={
            <button
              className="hideshowicon"
              onClick={() => setLeftMenu(!leftmenu)}
            >
              <img src={leftmenu ? HideMenubtn : ShowMenubtn} />
            </button>
          }
          index="popOver"
        />
        <button
          className="blueHollowBtn"
          onClick={() => handleRunWHT()}
          disabled={WHTFlag}
        >
          RUN WHOLE HOME TEST
        </button>
        <div className="deviceTabs">
          <Divider />
          <div>
            {devices !== undefined && devices?.length != 0 ? (
              devices?.map((device, index) => {
                return (
                  <div key={index}>
                    {React.Children.toArray(
                      DEVICE_CATEGORY?.map((d: any, i: number) =>
                        device.deviceCategory === d.category &&
                        d?.topology?.indexOf(accType) > -1 ? (
                          <div key={i}>
                            <CustomTab
                              tabType={
                                !leftmenu ? "vertical-Toggle" : "vertical"
                              }
                              handleActionClick={(actionKey: any) =>
                                handleActionClick(actionKey)
                              }
                              deviceDiagActions={deviceDiagActions}
                              deviceActions={d.name ==="GW" && containsONT? 
                             
                              // handleDeviceAction( d.deviceActions):
                              // d.deviceActions
                              [{action: "Reboot", label: "Reboot" }]:d.deviceActions
                              }
                              deviceStatus={
                                device?.hasOwnProperty("deviceStatus")
                                  ? device.deviceStatus
                                  : ""
                              }
                              index={index}
                              value={selDValue}
                              handleSelect={(i: any) => setSelDValue(i)}
                              label={isNull(d.name)}
                              friendlyName={
                                device?.hasOwnProperty("friendlyName")
                                  ? device.friendlyName
                                  : ""
                              }
                              subLabel={isNull(device.serialNumber)}
                              deviceInfo={device}
                              diagView={diagView}
                            />
                            <Divider />
                          </div>
                        ) : null
                      )
                    )}
                  </div>
                );
              })
            ) : (
              <div className="diagErrorCont">
                <ExceptionBox
                  headerText="No Devices to display."
                  bodyText="The ones provisioned to this account will reflect here."
                  primaryBtn={false}
                  secBtn={true}
                />
              </div>
            )}
          </div>
          {(hasDevice && (
            <PodsListView
              tabType={!leftmenu ? "vertical-Toggle" : "vertical"}
              handlePODClick={(podIndex: number) => handlePodClick(podIndex)}
              podsList={podList}
              selPodIndex={selPodIndex}
              index={devices?.length + 1}
              value={selDValue}
              handleSelect={(i: any) => setSelDValue(i)}
              label={labels.PODS.toUpperCase()}
              diagView={diagView}
            />
          ))}
          <Divider />
        </div>
      </section>
      <section className={leftmenu ? "rightSection" : "rightToggleSection"}>
        {selectedSA !== undefined ? (
          <div className="diagResult">
            {diagView === "tableView" ? (
              <>
                <DeviceOverView selectedSA={selectedSA} tabName="diagnostics" />
                {podList !== undefined ? <PodsOverViewTable /> : <></>}
              </>
            ) : selDevice !== undefined &&
              selDValue !== undefined &&
              devices !== undefined &&
              selDValue <= devices?.length ? (
              <DeviceDiagnostics
                selectedSA={selectedSA}
                accType={accType}
                gwMAC={gwMAC}
                selectedDevice={selDevice}
                deviceDiagActions={diagActionsData}
                selDiagAction={selDiagAction}
                handleActionClick={(actionKey: any) =>
                  handleActionClick(actionKey)
                }
              />
            ) :    podList &&  podList[selPodIndex]?.deviceFriendlyName === 'XLE'&& hasXFIDevice ?(
                
              <XLEPodDiagnostics
                selPOD={podList}
                selPodIndex={selPodIndex}
                podStatus={podError}
                podsLoading={podsLoading}
                podW3ID={isPodRequired}
              />
            )
            : (
              <PodsDiagnostics
                selPOD={podList}
                selPodIndex={selPodIndex}
                podStatus={podError}
                podsLoading={podsLoading}
                podW3ID={isPodRequired}
              />
            )
            
          }
          </div>
        ) : (
          <></>
        )}
      </section>
    </div>
  );
};

export default DiagnosticsTab;
