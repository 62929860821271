import { Divider } from "@material-ui/core";
import { useEffect, useState } from "react";
import { BiMenuAltLeft } from "react-icons/bi";
import { IoIosArrowBack } from "react-icons/io";
import { MdAccountBox, MdFolder } from "react-icons/md";
import { IoHome } from "react-icons/io5";
import { withRouter } from "react-router-dom";

import SideTab from "./SideTab";
import { ReactComponent as HistoryTabIcon } from "../../resources/images/icons/historyIcon.svg";
/*Changes added by node Team*/
import { ReactComponent as Node } from "../../resources/images/icons/Node_W.svg";
import { ReactComponent as Network } from "../../resources/images/icons/network.svg";
/*node Team changes completed*/
import ConfigConst from "../../constants/ConfigConst";
import CustomIcons from "../reusable/CustomIcons";
import AppLabels from "../../constants/App_Labels";
import { useDispatch } from "react-redux";
import {
  GetAddressInfoDetails,
  GetNodeBasicInfoDetails,
  GetNodeInfoDetails,
  GetTechInfo,
} from "../../get-api/GetResponse";
import { getStreamAddressData } from "../../topology/store/slices/mapItems/diagnostics/addressTelemetry";
import { getStreamBasicData } from "../../topology/store/slices/mapItems/diagnostics/nodeBasicTelemetry";
import {
  getStreamData,
  setIsCWEnabled,
  setModemType,
} from "../../topology/store/slices/mapItems/diagnostics/telemetry";
import {
  decrApiCall,
  incrApiCall,
  setHighlightedPolygon,
  setSelectedMapElement,
} from "../../topology/store/slices/mapItems/mapDetails";

import {
  GetConfigsProps,
  GetTelemetryProps,
} from "../../topology/util/reduxFunctions/getTopologyState";
import { getAccessLevelByRoles } from "../reusable/Util";
import { AiTwotoneFolderOpen } from "react-icons/ai";
import { BsCalculator } from "react-icons/bs";
import { useMemoizedShowSwitch } from "../../topology/util/customHooks/topologyHooks";

const DrawerCont = (props: any) => {
  const { drawerToggle, setDrawerToggle, history } = props;
  const labels = AppLabels();
  const {
    location: { search, pathname },
  } = props;
  const dispatch = useDispatch();
  const { lanId, data: techData } = GetTechInfo();
  const { currNodedata, data } = GetNodeInfoDetails();
  const { data: configData } = GetConfigsProps();
  const [activePath, setActivePath] = useState("/");
  const [activeAccSearch, setActiveAccSearch] = useState("");
  /*Changes added by node Team*/
  const [activeNodeSearch, setActiveNodeSearch] = useState("");
  /*node Team changes completed*/

  const isMaintanenceTech: boolean = getAccessLevelByRoles(
    techData?.roles
  ).includes(ConfigConst.MT);

  const {
    mlData,
    isDevicesVisible,
    allDiagIsLoading,
    modemType,
    diagType,
    isCWEnabled,
    isNeighbourhood,
  } = GetTelemetryProps();
  const {
    mlBasicData,
    isBasicDevicesVisible,
    allBasicDiagIsLoading,
    modemBasicType,
    diagBasicType,
    isBasicCWEnabled,
    isBasicNeighbourhood,
  } = GetNodeBasicInfoDetails();
  const {
    allAddressDiagIsLoading,
    allDiagAddressStatus,
    isAddressAborted,
    mlAddressData,
    viewAddressType,
  } = GetAddressInfoDetails();
  const modemTypeVal = () => {
    if (
      pathname &&
      (pathname.includes("/node/emta") ||
        pathname.includes("/node/neighbourhood/emta"))
    ) {
      dispatch(setIsCWEnabled(false));
      dispatch(setModemType("EMTA"));
      return "emta";
    } else {
      dispatch(setModemType("CM"));
      return "CM";
    }
  };
  const mType =
    modemType !== undefined && modemType !== "" ? modemType : modemTypeVal();

  const memoizedShowSwitch = useMemoizedShowSwitch({
    configData,
    techData,
    lanId,
  });

  useEffect(() => {
    if (history?.location?.pathname === "/" + ConfigConst.account) {
      setActiveAccSearch(history?.location?.search);
    } /*Changes added by node Team*/ else if (
      history?.location?.pathname === "/node"
    ) {
      setActiveNodeSearch(history?.location?.search);
    }

    if (diagType?.toUpperCase() === ConfigConst.ALL_NO_CW) {
      dispatch(setIsCWEnabled(false));
    } else if (diagType?.toUpperCase() === ConfigConst.ALL) {
      dispatch(setIsCWEnabled(true));
    }
    /*node Team changes completed*/
  }, [history?.location?.search]);

  useEffect(() => {
    setActivePath(history?.location?.pathname);
  }, [history?.location?.pathname]);

  //Removed from CmMarkers & Added this useEffect here as this is common place for topology and Maintenance
  useEffect(() => {
    if (mlData && mlData?.length !== 0 && allDiagIsLoading) {
      const macList = mlData?.map((e: any) => e?.macAddress);

      dispatch(incrApiCall());
      dispatch(
        getStreamData({
          macList: macList,
          nodeType: currNodedata?.nodeType,
          modemType: mType,
          isCWEnabled: isCWEnabled,
          isNeighbourhood: isNeighbourhood,
        })
      );
    } else if (mlData && mlData?.length !== 0 && !allDiagIsLoading) {
      dispatch(decrApiCall());
    }
  }, [allDiagIsLoading]);
  useEffect(() => {
    if (
      mlAddressData &&
      mlAddressData?.length !== 0 &&
      allAddressDiagIsLoading &&
      data?.[0]?.nodeType
    ) {
      const macList = mlAddressData?.map((e: any) => e?.macAddress);
      const deviceList = mlAddressData?.map((e: any) =>
        e?.deviceType?.includes("EMTA_NCS") ||
        e?.deviceType?.includes("EMTA_SIP")
          ? "EMTA"
          : e?.deviceType
      );

      //adding deviceType for each devices
      dispatch(incrApiCall());
      dispatch(
        getStreamAddressData({
          macList: macList,
          nodeType: data?.[0]?.nodeType,
          modemType: deviceList,
          isCWEnabled: isCWEnabled,
          isNeighbourhood: isNeighbourhood,
        })
      );
    } else if (
      mlAddressData &&
      mlAddressData?.length !== 0 &&
      !allAddressDiagIsLoading
    ) {
      dispatch(decrApiCall());
    }
  }, [allAddressDiagIsLoading, data]);
  useEffect(() => {
    if (mlBasicData && mlBasicData?.length !== 0 && allBasicDiagIsLoading) {
      const macList = mlBasicData?.map((e: any) => e?.macAddress);

      dispatch(incrApiCall());
      dispatch(
        getStreamBasicData({
          macList: macList,
          nodeType: currNodedata?.nodeType,
          modemType: modemBasicType,
          isCWEnabled: isBasicCWEnabled,
          isNeighbourhood: isBasicNeighbourhood,
        })
      );
    } else if (
      mlBasicData &&
      mlBasicData?.length !== 0 &&
      !allBasicDiagIsLoading
    ) {
      dispatch(decrApiCall());
    }
  }, [allBasicDiagIsLoading]);

  const isHistoryTabAvailable = () => {
    var isAvailable = false;
    techData?.roles?.map((item: any) => {
      if (
        item === ConfigConst.MANAGER ||
        item.includes(ConfigConst.TECHNICIAN) ||
        item.includes(ConfigConst.EXTERNAL_TECHNICIAN) ||
        item === ConfigConst.ADMIN ||
        item.includes(ConfigConst.MAINTENANCE_TECH) ||
        item.includes(ConfigConst.PLATFORM_TECH)
      ) {
        isAvailable = true;
      }
    });
    return isAvailable;
  };

  const isRPDTabAvailable = () => {
    var isAvailable = false;
    // techData?.roles?.map((item: any) => {
    //   if (
    //     item === ConfigConst.MAINTENANCE_TECH_MANAGER ||
    //     item.includes(ConfigConst.MAINTENANCE_TECH) ||
    //     item.includes(ConfigConst.PROVISIONING_TECH) ||
    //     item.includes(ConfigConst.PROVISIONING_TECH_MANAGER) ||
    //     item === ConfigConst.ADMIN
    //   ) {
    //     isAvailable = true;
    //   }
    // });
    if (
      getAccessLevelByRoles(techData?.roles).includes(ConfigConst.MT) ||
      getAccessLevelByRoles(techData?.roles).includes(
        ConfigConst.PROVISION_T
      ) ||
      techData?.roles?.includes(ConfigConst.ADMIN)
    ) {
      isAvailable = true;
    }
    return isAvailable;
  };

  return (
    <>
      <section className={drawerToggle ? "drawerOpenSec" : "drawerCloseSec"}>
        <button
          className="drawerBtn"
          onClick={
            drawerToggle ? () => {} : () => setDrawerToggle(!drawerToggle)
          }
        >
          {drawerToggle ? (
            <IoIosArrowBack onClick={() => setDrawerToggle(!drawerToggle)} />
          ) : (
            <BiMenuAltLeft />
          )}
        </button>
        <Divider />
        <div className="sideTabLinks">
          <SideTab
            drawerToggle={drawerToggle}
            activePath={activePath}
            tabPath="/"
            tabIcon={<IoHome />}
            tabName={labels.HOME}
          />
          <SideTab
            drawerToggle={drawerToggle}
            activePath={activePath}
            tabPath={"/" + ConfigConst.account}
            tabQueryParams={activeAccSearch}
            tabIcon={<MdAccountBox />}
            tabName={labels.ACCOUNT}
          />

          <SideTab
            drawerToggle={drawerToggle}
            activePath={activePath}
            tabPath={"/" + ConfigConst.node}
            tabQueryParams={activeNodeSearch}
            tabIcon={<Node />}
            tabName={labels.NODE}
          />
          {memoizedShowSwitch && (
            <SideTab
              drawerToggle={drawerToggle}
              activePath={activePath}
              tabPath={"/" + ConfigConst.topology}
              tabIcon={<CustomIcons name="topology" color={`var(--white)`} />}
              tabName={labels.TOPOLOGY}
            />
          )}
          {isHistoryTabAvailable() ? (
            <SideTab
              drawerToggle={drawerToggle}
              activePath={activePath}
              tabPath={"/" + ConfigConst.history}
              tabIcon={<HistoryTabIcon />}
              tabName={labels.HISTORY}
            />
          ) : (
            <></>
          )}
          {isRPDTabAvailable() ? (
            <SideTab
              drawerToggle={drawerToggle}
              activePath={activePath}
              tabPath={"/" + ConfigConst.rpd}
              tabIcon={<CustomIcons name="rpd" color={`var(--white)`} />}
              tabName={labels.RPD}
            />
          ) : (
            <></>
          )}
          {/* {isRPDTabAvailable() ? (
            <SideTab
              drawerToggle={drawerToggle}
              activePath={activePath}
              tabPath={"/" + ConfigConst.vccap}
              tabIcon={<CustomIcons name="rpd" color={`var(--white)`} />}
              tabName={labels.vccap}
            />
          ) : (
            // <></>
            <SideTab
              drawerToggle={drawerToggle}
              activePath={activePath}
              tabPath={"/" + ConfigConst.vccap}
              tabIcon={<CustomIcons name="rpd" color={`var(--white)`} />}
              tabName={labels.vccap}
            />
          )} */}

          <SideTab
            drawerToggle={drawerToggle}
            activePath={activePath}
            tabPath={"/" + ConfigConst.fileManager}
            tabIcon={<AiTwotoneFolderOpen />}
            tabName={labels.FILE_MANAGER}
          />
          <SideTab
            drawerToggle={drawerToggle}
            activePath={activePath}
            tabPath={"/" + ConfigConst.rfCalculator}
            tabIcon={
              <BsCalculator />
              // <CustomIcons name="rfLossCalc_service" color={`var(--white)`} />
            }
            tabName={labels.RF_ST_CALCULATOR}
          />
          {/* <SideTab
            drawerToggle={drawerToggle}
            activePath={activePath}
            tabPath={"/" + ConfigConst.rfMaintenanceCalculator}
            tabIcon={
              <CustomIcons name="rfLossCalc_mt" color={`var(--white)`} />
            }
            tabName={labels.RF_MT_CALCULATOR}
          /> */}
        </div>
      </section>
    </>
  );
};

export default withRouter(DrawerCont);
