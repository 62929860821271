import CustomAccordion from "../reusable/CustomAccordion";
import {
  isNull,
  getThreshold,
  getStatusOfCheck,
  getCheckValue,
  isEmptyObject,
  getStatusOfCheckHeader,
  hzToMHz,
} from "../reusable/Util";
import AppLabels from "../../constants/App_Labels";
import ExceptionBox from "../reusable/ExceptionBox";
import CustomPopOver from "../reusable/CustomPopOver";
const IHPdocsisInfo = (props: any) => {
  const { docsisInfo, docsisError, handleRefresh } = props;
  const labels = AppLabels();
  return (
    <>
      {docsisInfo !== undefined && !docsisInfo?.hasOwnProperty("errorCode") ? (
        <CustomAccordion
          accTitle={labels.DOCSIS.toUpperCase()}
          accBodyElement={
            <table className="dataTableDetails">
              <thead>
                <th>{labels.NAME.toUpperCase()}</th>
                <th>{labels.VALUE.toUpperCase()}</th>
                <th>{labels.STATUS.toUpperCase()}</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {getStatusOfCheckHeader(
                      docsisInfo?.upstreamChannels?.[0]?.tx_power,
                      labels.CM_TRANSMIT_POWER.toUpperCase()
                    )}
                  </td>

                  {docsisInfo?.hasOwnProperty("upstreamChannels") &&
                  docsisInfo?.upstreamChannels?.length !== 0 &&
                  docsisInfo?.upstreamChannels?.[0]?.hasOwnProperty(
                    "tx_power"
                  ) ? (
                    <>
                      <td>
                        {isNull(
                          getCheckValue(
                            isEmptyObject(
                              docsisInfo.upstreamChannels?.[0]?.tx_power
                            )
                          )
                        )}
                      </td>
                      <CustomPopOver
                        popOverData={getThreshold(
                          isEmptyObject(
                            docsisInfo.upstreamChannels?.[0]?.tx_power
                          )
                        )}
                        originComp={getStatusOfCheck(
                          isEmptyObject(
                            docsisInfo.upstreamChannels?.[0]?.tx_power
                          )
                        )}
                        index="popOver"
                      />
                    </>
                  ) : (
                    <>
                      <td>N/A</td>
                      <td>-</td>
                      <td>N/A</td>
                    </>
                  )}
                </tr>
                <tr>
                  <td>
                    {getStatusOfCheckHeader(
                      docsisInfo?.downstreamChannels?.[0]?.rx_power,
                      labels.CM_RECEIVE_POWER.toUpperCase()
                    )}
                  </td>

                  {docsisInfo?.hasOwnProperty("downstreamChannels") &&
                  docsisInfo?.downstreamChannels?.length !== 0 &&
                  docsisInfo?.downstreamChannels?.[0]?.hasOwnProperty(
                    "rx_power"
                  ) ? (
                    <>
                      <td>
                        {isNull(
                          getCheckValue(
                            isEmptyObject(
                              docsisInfo.downstreamChannels?.[0]?.rx_power
                            )
                          )
                        )}
                      </td>
                      <CustomPopOver
                        popOverData={getThreshold(
                          isEmptyObject(
                            docsisInfo.downstreamChannels?.[0]?.rx_power
                          )
                        )}
                        originComp={getStatusOfCheck(
                          isEmptyObject(
                            docsisInfo.downstreamChannels?.[0]?.rx_power
                          )
                        )}
                        index="popOver"
                      />
                    </>
                  ) : (
                    <>
                      <td>N/A</td>
                      <td>-</td>
                      <td>N/A</td>
                    </>
                  )}
                </tr>
                <tr>
                  <td>
                    {getStatusOfCheckHeader(
                      docsisInfo?.downstreamChannels?.[0]?.snr,
                      labels.DOWNSTREAM_SNR.toUpperCase()
                    )}
                  </td>

                  {docsisInfo?.hasOwnProperty("downstreamChannels") &&
                  docsisInfo?.downstreamChannels?.length !== 0 &&
                  docsisInfo?.downstreamChannels?.[0]?.hasOwnProperty("snr") ? (
                    <>
                      <td>
                        {isNull(
                          getCheckValue(
                            isEmptyObject(
                              docsisInfo.downstreamChannels?.[0]?.snr
                            )
                          )
                        )}
                      </td>
                      <CustomPopOver
                        popOverData={getThreshold(
                          isEmptyObject(docsisInfo.downstreamChannels?.[0]?.snr)
                        )}
                        originComp={getStatusOfCheck(
                          isEmptyObject(docsisInfo.downstreamChannels?.[0]?.snr)
                        )}
                        index="popOver"
                      />
                    </>
                  ) : (
                    <>
                      <td>N/A</td>
                      <td>-</td>
                      <td>N/A</td>
                    </>
                  )}
                </tr>
                <tr>
                  <td>
                    {getStatusOfCheckHeader(
                      docsisInfo?.upstreamChannels?.[0]?.snr,
                      labels.UPSTREAM_SNR.toUpperCase()
                    )}
                  </td>

                  {docsisInfo?.hasOwnProperty("upstreamChannels") &&
                  docsisInfo?.upstreamChannels?.length !== 0 &&
                  docsisInfo?.upstreamChannels?.[0]?.hasOwnProperty("snr") ? (
                    <>
                      <td>
                        {isNull(
                          getCheckValue(
                            isEmptyObject(docsisInfo.upstreamChannels?.[0]?.snr)
                          )
                        )}
                      </td>
                      <CustomPopOver
                        popOverData={getThreshold(
                          isEmptyObject(docsisInfo.upstreamChannels?.[0]?.snr)
                        )}
                        originComp={getStatusOfCheck(
                          isEmptyObject(docsisInfo.upstreamChannels?.[0]?.snr)
                        )}
                        index="popOver"
                      />
                    </>
                  ) : (
                    <>
                      <td>N/A</td>
                      <td>-</td>
                      <td>N/A</td>
                    </>
                  )}
                </tr>
                {/* \\latest  code */}
                <tr>
                  <td>
                    {labels.TX.toUpperCase()} &nbsp;
                    {labels.Margin.toUpperCase()}
                  </td>
                  {docsisInfo?.hasOwnProperty("upstreamChannels") &&
                  docsisInfo?.upstreamChannels?.length !== 0 &&
                  docsisInfo?.hasOwnProperty("txMargin") ? (
                    <>
                      <td>
                        {isNull(
                          docsisInfo.txMargin ? docsisInfo.txMargin : null
                        )}
                      </td>
                      <td>
                        <span></span>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>N/A</td>
                      <td>-</td>
                      <td>N/A</td>
                    </>
                  )}
                </tr>
                <tr>
                  <td>
                    {labels.RX.toUpperCase()} &nbsp;
                    {labels.Margin.toUpperCase()}
                  </td>
                  {docsisInfo?.hasOwnProperty("downstreamChannels") &&
                  docsisInfo?.downstreamChannels?.length !== 0 &&
                  docsisInfo?.hasOwnProperty("rxMargin") ? (
                    <>
                      <td>
                        {isNull(
                          docsisInfo.rxMargin ? docsisInfo.rxMargin : null
                        )}
                      </td>
                      <td>
                        <span></span>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>N/A</td>
                      <td>-</td>
                      <td>N/A</td>
                    </>
                  )}
                </tr>
                <tr>
                  <td>{labels.US_Freq.toUpperCase()}</td>

                  {docsisInfo?.hasOwnProperty("upstreamChannels") &&
                  docsisInfo?.upstreamChannels?.length !== 0 &&
                  docsisInfo?.upstreamChannels?.[0]?.hasOwnProperty(
                    "frequency"
                  ) ? (
                    <>
                      <td>
                        {isNull(
                          docsisInfo.upstreamChannels?.[0]?.frequency
                            ? hzToMHz(
                                docsisInfo.upstreamChannels?.[0]?.frequency
                              ) +
                                " " +
                                labels.Mhz
                            : null
                        )}
                      </td>
                      <td>
                        <span></span>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>N/A</td>
                      <td>-</td>
                      <td>N/A</td>
                    </>
                  )}
                </tr>
                <tr>
                  <td>{labels.DS_Freq.toUpperCase()}</td>

                  {docsisInfo?.hasOwnProperty("downstreamChannels") &&
                  docsisInfo?.downstreamChannels?.length !== 0 &&
                  docsisInfo?.downstreamChannels?.[0]?.hasOwnProperty(
                    "frequency"
                  ) ? (
                    <>
                      <td>
                        {isNull(
                          docsisInfo.downstreamChannels?.[0]?.frequency
                            ? hzToMHz(
                                docsisInfo.downstreamChannels?.[0]?.frequency
                              ) +
                                " " +
                                labels.Mhz
                            : null
                        )}
                      </td>
                      <td>
                        <span></span>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>N/A</td>
                      <td>-</td>
                      <td>N/A</td>
                    </>
                  )}
                </tr>
                <tr>
                  <td>{labels.cmts_rx.toUpperCase()}</td>

                  {docsisInfo?.hasOwnProperty("upstreamChannels") &&
                  docsisInfo?.upstreamChannels?.length !== 0 &&
                  docsisInfo?.upstreamChannels?.[0]?.hasOwnProperty(
                    "rx_power"
                  ) ? (
                    <>
                      <td>
                        {isNull(
                          getCheckValue(
                            isEmptyObject(
                              docsisInfo.upstreamChannels?.[0]?.rx_power
                            )
                          )
                        )}
                      </td>
                      <CustomPopOver
                        popOverData={getThreshold(
                          isEmptyObject(
                            docsisInfo.upstreamChannels?.[0]?.rx_power
                          )
                        )}
                        originComp={getStatusOfCheck(
                          isEmptyObject(
                            docsisInfo.upstreamChannels?.[0]?.rx_power
                          )
                        )}
                        index="popOver"
                      />
                    </>
                  ) : (
                    <>
                      <td>N/A</td>
                      <td>-</td>
                      <td>N/A</td>
                    </>
                  )}
                </tr>
              </tbody>
            </table>
          }
        />
      ) : docsisError !== undefined ? (
        <div className="diagErrorCont">
          <ExceptionBox
            exBoxType="error"
            errSecName="RHP Docsis"
            errCode={isNull(docsisError?.errorCode)}
            srcSystem={
              docsisError?.hasOwnProperty("sourceSystem")
                ? docsisError?.sourceSystem
                : "Request"
            }
            ErrorMSG={isNull(docsisError?.errorMessage)}
            primaryBtn={true}
            secBtn={true}
            handleRefresh={handleRefresh}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default IHPdocsisInfo;
