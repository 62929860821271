import { DeviceActionState } from "../../../states/account/deviceActions/DeviceActionState";

import {
  HARD_ZAP_BEGIN,
  HARD_ZAP_SUCCESS,
  HARD_ZAP_FAILURE,
  HARD_ZAP_RESET_STATUS,
  HardZapActionTypes,
} from "../../../types/account/deviceActions/HardZap";

const initialState: DeviceActionState = {
  isLoading: false,
  error: undefined,
  status: "",
};

export function HardZapReducer(
  state = initialState,
  action: HardZapActionTypes
): DeviceActionState {
  switch (action.type) {
    case HARD_ZAP_BEGIN:
      return {
        isLoading: true,
      };
    case HARD_ZAP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: "OK",
      };
    case HARD_ZAP_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        status: "ERROR",
      };
    case HARD_ZAP_RESET_STATUS:
      return {
        ...state,
        status: "",
      };
    default:
      return state;
  }
}

