import "../../design-tokens/rpd-page/RPDPage.scss";
import RPDRegistrationTop from "../../components/rpd/rpdRegistration/RPDRegistrationTop";
import RPDRegistrationDetails from "../../components/rpd/rpdRegistration/RPDRegistrationDetails";
import { GetRPDRegistrationInfo } from "../../get-api/GetResponse";
import LoaderGif from "../../resources/images/loader.webp";
import RPDExceptionBox from "../../components/reusable/RPDExceptionBox";
import ExceptionBox from "../../components/reusable/ExceptionBox";
import AppLabels from "../../constants/App_Labels";

const RPDRegistrationTab = () => {
  const rpdRegistrationInfo = GetRPDRegistrationInfo();
  const labels = AppLabels();
  return (
    <>
      <div>
        <RPDRegistrationTop
          clamshellID={rpdRegistrationInfo?.clamshellID}
          macAddressID={rpdRegistrationInfo?.macAddress}
          rpdNumber={rpdRegistrationInfo?.rpdNumber}
          isLoading={rpdRegistrationInfo?.isLoading}
        />
      </div>
      <div className="historyResultCont">
        {rpdRegistrationInfo.isLoading ? (
          <div className="loader">
            <img style={{ height: "60px" }} src={LoaderGif} />
          </div>
        ) : rpdRegistrationInfo?.data === undefined &&
          rpdRegistrationInfo?.error !== undefined ? (
          <div className="diagErrorCont">
            <RPDExceptionBox
              exBoxType="error"
              headerText={labels.rpdRegistrationError}
              errCode={rpdRegistrationInfo?.error.errorCode}
              ErrorMSG={rpdRegistrationInfo?.error.errorMessage}
              srcSystem={rpdRegistrationInfo?.error.sourceSystem}
              bodyText={rpdRegistrationInfo?.error.responseFromSource}
              primaryBtn={false}
              secBtn={false}
            />
          </div>
        ) : rpdRegistrationInfo?.data !== undefined &&
          rpdRegistrationInfo?.error === undefined ? (
          <div className="diagErrorCont">
            <ExceptionBox
              headerText={labels.rpdRegistrationSuccess}
              bodyText={rpdRegistrationInfo?.data.RPD_ID}
              primaryBtn={false}
              secBtn={false}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default RPDRegistrationTab;
