import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import {
  sendEmailBegin,
  sendEmailFailure,
  sendEmailSuccess,
} from "../../../store/ducksPattern/sendEmailData";

export default class EmailClient {
  static sendEmailNoti =
    (
      requestType: string,
      data: any
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "email/notification",
      };
      dispatch(sendEmailBegin(requestType));
      HttpClient.post<any>(httpParameters, data)
        .then((res) => {
          dispatch(sendEmailSuccess(res));
          return res;
        })
        .catch((error) => {
          dispatch(sendEmailFailure(error));
          return error;
        });
    };
}
