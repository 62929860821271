import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import Slide, { SlideProps } from "@material-ui/core/Slide";
import { forwardRef, ReactNode } from "react";
import { IoClose } from "react-icons/io5";

const Transition = forwardRef<unknown, SlideProps>((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

interface Props {
  show: boolean;
  setShow: (toggle: boolean) => void;
  title: string;
  children: ReactNode;
  /**used to disable close icon @default false */
  disableCloseBtn?: boolean;
}

export default function MUIDialog({
  show,
  setShow,
  title,
  children,
  disableCloseBtn = false,
}: Props) {
  return (
    <Dialog
      open={show}
      PaperProps={{ style: { minWidth: 600 } }}
      TransitionComponent={Transition}
    >
      <DialogTitle
        style={{
          backgroundColor: `var(--primary)`,
          color: `var(--white)`,
          paddingTop: 5,
          paddingBottom: 5,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span style={{ fontWeight: 700 }}>{title}</span>
          <IconButton
            onClick={() => setShow(false)}
            color="inherit"
            style={{ marginLeft: 15 }}
            disabled={disableCloseBtn}
          >
            <IoClose />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}
