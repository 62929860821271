import { POD } from "../../../../models/account/diagnostics/pods/POD";

export const PODS_BEGIN = "PODS_BEGIN";
export const PODS_SUCCESS = "PODS_SUCCESS";
export const PODS_FAILURE = "PODS_FAILURE";
export const PODS_RESET = "PODS_RESET";
interface PODSBegin {
  type: typeof PODS_BEGIN;
}

interface PODSSuccess {
  type: typeof PODS_SUCCESS;
  payload: POD |POD[];
}


interface PODSFailure {
  type: typeof PODS_FAILURE;
  payload: Error;
}
interface PODSReset {
  type: typeof PODS_RESET;
}
export type PODSActionTypes = PODSBegin | PODSSuccess | PODSFailure | PODSReset;
