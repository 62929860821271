import AppLabels from "../../../constants/App_Labels";
// in this component we applied inline CSS due to export purpose
//please do not follow the same in other components as its not a good practice
interface Props {
  woID: string;
  woType: string;
  workDetails: any;
  isLegacyAccount?: any;
}

const DisplayEmailPreviewNoise = (props: Props) => {
  const labels = AppLabels() as any;

  return (
    <div
      style={{
        display: "inline-block",
        fontWeight: 500,
        fontFamily: "Ted Next",
      }}
    >
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {labels.Work_Order_ID}:
        </span>
        <span>{props.woID}</span>
      </div>
      {props.woType !== "" && (
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              marginRight: "10px",
            }}
          >
            {labels.Order_Type}:
          </span>
          <span>{props.woType}</span>
        </div>
      )}
      {/* <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {"Form Submission Number"}:
        </span>
        <span>{props?.workDetails?.formSubmissionNo}</span>
      </div> */}
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {"TPIA"}:
        </span>
        <span>{props?.workDetails?.tpia}</span>
      </div>
      {props?.workDetails?.CMMac.length !== 0 && (
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              marginRight: "10px",
            }}
          >
            {labels.CABLE_MODEM + " " + labels.MAC_ADDRESS}:
          </span>
          <ul>
            {props?.workDetails?.CMMac?.map((e: any, i: number) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </div>
      )}
      {props?.workDetails?.STBMac.length !== 0 && (
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              marginRight: "10px",
            }}
          >
            {"STB" + " " + labels.MAC_ADDRESS}:
          </span>
          <ul>
            {props?.workDetails?.STBMac?.map((e: any, i: number) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </div>
      )}
      {props?.workDetails?.EMTAMac.length !== 0 && (
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              marginRight: "10px",
            }}
          >
            {"EMTA" + " " + labels.MAC_ADDRESS}:
          </span>
          <ul>
            {props?.workDetails?.EMTAMac?.map((e: any, i: number) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </div>
      )}
      {props?.isLegacyAccount ? (
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              marginRight: "10px",
            }}
          >
            {labels.ACCOUNT_NUMBER}:
          </span>
          <span>{props?.workDetails?.accountNumber}</span>
        </div>
      ) : (
        <>
          <div
            style={{
              marginBottom: "10px",
            }}
          >
            <span
              style={{
                fontWeight: 700,
                marginRight: "10px",
              }}
            >
              {labels.ACCOUNT_NUMBER}:
            </span>
            <span>{props?.workDetails?.accountNumber}</span>
          </div>
          <div
            style={{
              marginBottom: "10px",
            }}
          >
            <span
              style={{
                fontWeight: 700,
                marginRight: "10px",
              }}
            >
              {labels.HHID}:
            </span>
            <span>{props?.workDetails?.hhid}</span>
          </div>
        </>
      )}
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {labels.technician_Name}:
        </span>
        <span>{props?.workDetails?.techName}</span>
      </div>
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {labels.technician_Id}:
        </span>
        <span>{props?.workDetails?.technicianId}</span>
      </div>
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {props?.workDetails?.unitOrSuiteNumber === ""
            ? "House" + " Number"
            : "Street" + " Number"}
          :
        </span>
        <span>{props?.workDetails?.streetNumber}</span>
      </div>
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {"Street Name"}:
        </span>
        <span>{props?.workDetails?.streetName}</span>
      </div>
      {props?.workDetails?.streetDirection !== "" && (
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              marginRight: "10px",
            }}
          >
            {"Street Direction"}:
          </span>
          <span>{props?.workDetails?.streetDirection}</span>
        </div>
      )}
      {props?.workDetails?.unitOrSuiteNumber !== "" && (
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              marginRight: "10px",
            }}
          >
            {"Unit Or Suite Number"}:
          </span>
          <span>{props?.workDetails?.unitOrSuiteNumber}</span>
        </div>
      )}
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {labels.City}:
        </span>
        <span>{props?.workDetails?.city}</span>
      </div>
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {"Node Number"}:
        </span>
        <span>{props?.workDetails?.nodeNumber}</span>
      </div>
      {props?.workDetails?.cbpId !== "" && (
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              marginRight: "10px",
            }}
          >
            {"CBPID"}:
          </span>
          <span>{props?.workDetails?.cbpId}</span>
        </div>
      )}
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {"Manager Name"}:
        </span>
        <span>{props?.workDetails?.managerName}</span>
      </div>
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {labels.Hot_To_Cold}:
        </span>
        <span>{props?.workDetails?.hotToCold}</span>
      </div>
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {"Bell Modem"}:
        </span>
        <span>{props?.workDetails?.bellModem}</span>
      </div>
      {props?.workDetails?.f2Information_PrePadding !== "" && (
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              marginRight: "10px",
            }}
          >
            {"f2 Information PrePadding"}:
          </span>
          <span>{props?.workDetails?.f2Information_PrePadding}</span>
        </div>
      )}
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {"Filtered Or Padded"}:
        </span>
        <span>{props?.workDetails?.filteredOrPadded}</span>
      </div>
      {props?.workDetails?.filteredOrPadded === labels.Yes && (
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              marginRight: "10px",
            }}
          >
            {"Filtered Or Padded Information"}:
          </span>
          <span>{props?.workDetails?.filterOrPadInfo}</span>
        </div>
      )}
      {props?.workDetails?.f2Information_PostPadding !== "" && (
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              marginRight: "10px",
            }}
          >
            {"f2 Information PostPadding"}:
          </span>
          <span>{props?.workDetails?.f2Information_PostPadding}</span>
        </div>
      )}
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {"Above 10 Units"}:
        </span>
        <span>{props?.workDetails?.above10Units}</span>
      </div>
      {props?.workDetails?.above10Units === labels.Yes && (
        <>
          <div
            style={{
              marginBottom: "10px",
            }}
          >
            <span
              style={{
                fontWeight: 700,
                marginRight: "10px",
              }}
            >
              {"Above 10 Units (Affected Units)"}:
            </span>
            <ul>
              {props?.workDetails?.above10UnitsList?.map(
                (e: any, i: number) => (
                  <li key={i}>{e}</li>
                )
              )}
            </ul>
          </div>
          {props?.workDetails?.above10UnitsComments !== "" && (
            <div
              style={{
                marginBottom: "10px",
              }}
            >
              <span
                style={{
                  fontWeight: 700,
                  marginRight: "10px",
                }}
              >
                {"Above 10 Units Comments"}:
              </span>
              <span>{props?.workDetails?.above10UnitsComments}</span>
            </div>
          )}
        </>
      )}
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {labels.PRIORITY}:
        </span>
        <span>{props?.workDetails?.priority}</span>
      </div>
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {"Legacy Or Ignite Or Fido"}:
        </span>
        <span>{props?.workDetails?.legacyOrIgniteOrFide}</span>
      </div>
      {props?.workDetails?.screenShots?.length !== 0 && (
        <div
          style={{
            marginBottom: "10px",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              marginRight: "10px",
            }}
          >
            {"ScreenShots"}:
          </span>
          <span
            className="carouselContainer"
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {props?.workDetails?.screenShots?.map((n: any) => {
              return (
                <>
                  <img
                    src={n?.image}
                    style={{
                      maxHeight: "700px",
                      maxWidth: "700px",
                      margin: "1rem",
                      objectFit: "scale-down",
                      pointerEvents: "none",
                    }}
                  />
                  <br />
                </>
              );
            })}
          </span>
        </div>
      )}
      {/* <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {"Customer Contact Number"}:
        </span>
        <span>{props?.workDetails?.customerContactNumber}</span>
      </div> */}
      {props?.workDetails?.comments !== "" && (
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              marginRight: "10px",
            }}
          >
            {"Comments"}:
          </span>
          <span>{props?.workDetails?.comments}</span>
        </div>
      )}
      {props?.workDetails?.distroEmails.length !== 0 && (
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              marginRight: "10px",
            }}
          >
            {"Distribution Email(s)"}:
          </span>
          <ul>
            {props?.workDetails?.distroEmails?.map((e: any, i: number) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DisplayEmailPreviewNoise;
