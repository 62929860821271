import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CustomPopUp from "../../components/reusable/CustomPopUp";
import DiagContainer from "../../components/reusable/DiagContainer";
import {
  calculatingNodeAveragesData,
  getBasicDeviceType,
  getNodeTeleGloabalStatus,
  getTelemetryHomeAddress,
} from "../../components/reusable/NodeUtil";
import { isNull } from "../../components/reusable/Util";
import ClearFlaps from "../../components/telemetry/ClearFlaps";
import ColumnDropDown from "../../components/telemetry/ColumnDropDown";
import TelemetryActionMenu from "../../components/telemetry/TelemetryActionMenu";
import AppLabels from "../../constants/App_Labels";
import "../../design-tokens/telemetry/NodeTelemetryTab.scss";
import {
  GetNodeBasicInfoDetails,
  GetNodeInfoDetails,
  GetTelemetryAverages,
  GetTelemetryDetails,
} from "../../get-api/GetResponse";
import NodeInfoDetailsClient from "../../network/httpclient/nodeinfo/NodeInfoDetailsClient";
import { SetNodeDevicesError } from "../../store/actions/node/telemetry/NodeDevicesInfoActions";
import {
  updateBasicConfigDataCheckbox,
  updateConfigDataReposition,
} from "../../store/ducksPattern/userPrefConfig";
import {
  getTeleModemsList,
  setViewType,
} from "../../topology/store/slices/mapItems/diagnostics/telemetry";
import MultiDeviceNodeTable from "../multi-device-node-page/MultiDeviceNodeTable";

import { Paper } from "@material-ui/core";
import NodeAverages from "../../components/telemetry/NodeAverages";
import ConfigConst from "../../constants/ConfigConst";
import storeConst from "../../topology/store/storeConst";
import { isFullDiagPresent } from "../../topology/util/utilFuncs";
import STBAverages from "../stb-page/STBAverages";

export function MultiDeviceNodeTeleTab(props: any) {
  const { data, tableAttr, dataSetNames, exportDeviceId, exportDeviceType } =
    props;
  const dispatch = useDispatch();
  const labels = AppLabels();
  const address = ConfigConst.address.toLowerCase();

  const [dataSetName, setDataSetName] = useState("");
  const [viewTypeOptions, setViewTypeOptions] = React.useState<any>([]);
  const [viewType, setTeleViewType] = useState("");

  const [selectAttrVal, setSelectAttrVal] = useState<any>();
  const [tableAttributes, setTableAttributes] = useState<any>(tableAttr);
  const [tableData, setTableData] = useState();
  const [dragOver, setDragOver] = useState("");

  const [popUpFlag, setPopUpFlag] = useState(false);
  const [popUpHeader, setPopUpHeader] = useState("");
  const [popUpBody, setPopUpBody] = useState(<div></div>);
  const [averagesPopUp, setAveragesPopUp] = useState(false);
  const [avgPopUpBody, setAvgPopUpBody] = useState(<div></div>);
  const [nodeAvgData, setNodeAvgData] = useState({});

  const { ducksConfigData } = GetTelemetryDetails();
  const { currNodedata } = GetNodeInfoDetails();

  const {
    allBasicDiagIsLoading,
    allDiagBasicStatus,
    isBasicAborted,
    mlBasicData,
    diagBasicStartTime,
    diagBasicEndTime,
    mlBasicIsLoading,
    mlBasicError,
    allDiagBasicError,
    diagBasicType,
    modemBasicType,
    isBasicNeighbourhood,
  } = GetNodeBasicInfoDetails();
  const nodeTeleStatus = getNodeTeleGloabalStatus(
    allDiagBasicStatus,
    allBasicDiagIsLoading,
    isBasicAborted
  );

  const { nodeAveragesStatusData, nodeAveragesStatusLoading } =
    GetTelemetryAverages();

  const [teleData, setTeleData] = useState(mlBasicData);
  const isFullDiagAvail = isFullDiagPresent(teleData);
  const isNeighValue = isBasicNeighbourhood ? isBasicNeighbourhood : false;

  const handleDragStart = (event: any) => {
    const { id } = event.target;
    const idx = tableAttributes.findIndex(
      (obj: any) => obj.jsonDataMapping === id
    );
    event.dataTransfer.setData("colIdx", idx);
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };
  const handleDragEnter = (event: any) => {
    const { id } = event.target;
    const identifier =
      id === "null" || id === null || id === "" ? dragOver : id;
    setDragOver((state) => {
      state = identifier;
      return state;
    });
  };

  const handleOnDrop = (event: any) => {
    var viewVal =
      viewType?.toUpperCase() === labels.ALL.toUpperCase()
        ? labels.ALL.toUpperCase()
        : viewType?.toUpperCase();
    const { id } = event.target;
    const identifier =
      id === "null" || id === null || id === "" ? dragOver : id;
    const droppedColIdx = tableAttributes.findIndex(
      (obj: any) => obj.jsonDataMapping == identifier
    );
    const draggedColIdx = event.dataTransfer.getData("colIdx");
    const tempCols = [...tableAttributes];
    const tempCols1 = [...tableAttributes];
    if (droppedColIdx !== -1 && draggedColIdx !== -1) {
      if (droppedColIdx < draggedColIdx) {
        tempCols[droppedColIdx] = tempCols1[draggedColIdx];
        tempCols[droppedColIdx + 1] = tempCols1[droppedColIdx];

        tempCols1.splice(draggedColIdx, 1);
        for (let i = droppedColIdx + 2; i <= tempCols1.length; i++) {
          tempCols[i] = tempCols1[i - 1];
        }
      } else if (draggedColIdx < droppedColIdx) {
        tempCols[droppedColIdx] = tempCols1[draggedColIdx];
        tempCols[droppedColIdx - 1] = tempCols1[droppedColIdx];
        tempCols1.splice(draggedColIdx, 1);
        for (let i = droppedColIdx - 2; i >= 0; i--) {
          tempCols[i] = tempCols1[i];
        }
      }
      dispatch(updateConfigDataReposition({ viewVal, tempCols }));
    }
    setDragOver((state) => {
      state = "";
      return state;
    });
  };

  const handleNodeModemListRefresh = () => {
    if (props?.refreshNodeModemList() === undefined) {
      const activeId = currNodedata?.smt
        ? currNodedata?.smtId
        : currNodedata?.nodeId;
      const type = currNodedata?.smt ? "smtid" : "shubid";
      dispatch(
        getTeleModemsList({
          value: activeId,
          type: type,
          diagnoseDevices: true,
          modemType: modemBasicType,
        })
      );
    } else {
      props.refreshNodeModemList();
    }
  };

  const handleCheckboxClick = (option: string) => {
    var viewVal =
      viewType?.toUpperCase() === labels.ALL.toUpperCase()
        ? labels.ALL.toUpperCase()
        : viewType?.toUpperCase();
    dispatch(updateBasicConfigDataCheckbox({ viewVal, option }));
  };

  useEffect(() => {
    if (mlBasicData === undefined) {
      const activeId = currNodedata?.smt
        ? currNodedata?.smtId
        : currNodedata?.nodeId;
      const type = currNodedata?.smt ? "smtid" : "shubid";
      if (modemBasicType?.trim()?.toLowerCase() === "emta") {
        dispatch(
          getTeleModemsList({
            value: activeId,
            type: type,
            diagnoseDevices: true,
            modemType: modemBasicType,
          })
        );
      }
    }
    if (dataSetNames?.length > 0) {
      setDataSetName(dataSetNames[0]);
    }
  }, [modemBasicType]);

  useEffect(() => {
    if (!isBasicAborted && allDiagBasicError) {
      setPopUpFlag(true);
      setPopUpHeader(labels.ERROR.toUpperCase());
      setPopUpBody(
        <span className="telemertyinfoBoxSpan">{labels.NO_DATA}</span>
      );
    }
  }, [allDiagBasicError]);

  const filterBySMT = (filterKey: string) => {
    if (filterKey?.length > 0 && filterKey !== labels.ALL?.toUpperCase()) {
      return mlBasicData
        ?.filter((d: any) => isNull(d))
        .filter(
          (d: any) =>
            isNull(d.serviceArea) &&
            d.serviceArea?.toLowerCase()?.includes(filterKey?.toLowerCase())
        );
    }
    return mlBasicData;
  };

  useEffect(() => {
    if (data != undefined && data != "") {
      let sortedData = [...data];

      sortedData?.sort((a, b) => {
        const addressA = getTelemetryHomeAddress(a?.serviceAddress);
        const addressB = getTelemetryHomeAddress(b?.serviceAddress);

        if (addressA < addressB) {
          return -1;
        }
        if (addressA > addressB) {
          return 1;
        }
        if (a?.deviceType < b?.deviceType) {
          return -1;
        }
        if (a?.deviceType > b?.deviceType) {
          return 1;
        }

        return 0;
      });
      setTeleData(sortedData);
    }
  }, [data, mlBasicData]);

  React.useEffect(() => {
    if (dataSetName !== undefined && dataSetName !== "") {
      // sets the view type options based on the selected data set name
      props?.dataSetType?.map((v: any) => {
        if (
          dataSetName?.trim()?.toLowerCase() ===
          v?.dataSetName?.toLowerCase()?.trim()
        ) {
          setViewTypeOptions(v?.subDataSetViews);
          dispatch(setViewType(v?.subDataSetViews?.[0]?.columnKey));
          setTeleViewType(v?.subDataSetViews?.[0]?.columnKey);
        }
      });
    }
  }, [dataSetName]);

  useEffect(() => {
    if (
      nodeTeleStatus === ConfigConst.complete.toLowerCase() &&
      isFullDiagAvail
    ) {
      calculatingNodeAveragesData(teleData, setNodeAvgData);
    }
  }, [nodeTeleStatus, teleData]);

  useEffect(() => {
    if (
      nodeAvgData !== undefined &&
      Object.keys(nodeAvgData)?.length > 0 &&
      dataSetName?.toLowerCase() !== ConfigConst.BASIC?.toLowerCase()
    ) {
      dispatch(
        NodeInfoDetailsClient.getNodeAvergesStatus(
          nodeAvgData,
          currNodedata?.smt ? currNodedata?.smtId : currNodedata?.nodeId
        )
      );
    }
  }, [nodeAvgData]);

  useEffect(() => {
    if (viewType !== undefined && viewType !== "") {
      ducksConfigData?.userPreferences?.node?.telemetry?.views?.map(
        (v: any) => {
          setTableAttributes(v?.attributes);
          setSelectAttrVal(v?.attributes);
        }
      );
    }
  }, [viewType, ducksConfigData]);

  //Setting Dataset dropdown with proper value when changed in topology
  useEffect(() => {
    setDataSetName(
      diagBasicType === storeConst.all || diagBasicType === storeConst.all_no_cw
        ? ConfigConst.FULL_NO_CW
        : ConfigConst.BASIC
    );
  }, [diagBasicType, dataSetName]);

  return (
    <Paper
      elevation={3}
      style={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
      }}
    >
      <div
        className="nodeFilterCont"
        style={{
          padding: "6px",
          boxShadow: "inset 0px -2px 3px 1px rgb(0 0 0 / 12%)",
        }}
      >
        <div className="nodeFilters nodeFilterContainer">
          {/* {viewTypeOptions?.length !== 0 && (
            <CustomDropDown
              disabled={dataSetName !== labels.FULL?.toUpperCase()}
              ddTitle={labels.VIEW_TYPE?.toUpperCase()}
              selectedOption={viewType}
              options={viewTypeOptions}
              searchFlag={false}
              checkboxFlag={false}
              setSelectedOption={(value: any) => {
                setTeleViewType(value);
                dispatch(setViewType(value));
              }}
            />
          )} */}
          {viewTypeOptions?.length !== 0 && (
            <ColumnDropDown
              disabled={
                dataSetName?.toUpperCase() === labels.BASIC?.toUpperCase()
              }
              ddTitle={labels.VIEW_TYPE?.toUpperCase()}
              selectedOption={
                viewTypeOptions?.filter(
                  (obj: any) =>
                    obj?.columnKey?.toUpperCase() === viewType?.toUpperCase()
                )?.[0]?.columnName
              }
              options={viewTypeOptions}
              searchFlag={false}
              checkboxFlag={false}
              setSelectedOption={(value: any) => {
                setTeleViewType(value);
                dispatch(setViewType(value));
              }}
            />
          )}
          {viewType !== labels.CUSTOM?.toUpperCase() && (
            <ColumnDropDown
              ddTitle={labels.fields?.toUpperCase()}
              selectedOption=""
              options={tableAttributes?.filter(
                (e: any) => e?.columnKey !== "serviceAddress"
              )}
              // options={filterListAttr}
              searchFlag={true}
              checkboxFlag={true}
              setSelectedOption={(value: any) => {}}
              selectAttrVal={selectAttrVal}
              handleCheckBox={handleCheckboxClick}
              handleDragStart={handleDragStart}
              handleDragOver={handleDragOver}
              handleOnDrop={handleOnDrop}
              handleDragEnter={handleDragEnter}
              dragOver={dragOver}
            />
          )}
        </div>
        {dataSetName?.toLowerCase() !== labels.BASIC?.toLowerCase() &&
          modemBasicType !== ConfigConst.oldstb && (
            <NodeAverages
              setAveragesPopUp={setAveragesPopUp}
              setAvgPopUpBody={setAvgPopUpBody}
              nodeTeleStatus={nodeTeleStatus}
              nodeAvgStatusData={nodeAveragesStatusData}
              nodeAveragesStatusLoading={nodeAveragesStatusLoading}
              isFullDiagPresent={isFullDiagAvail}
            />
          )}
        {dataSetName?.toLowerCase() !== labels.BASIC?.toLowerCase() &&
          modemBasicType === ConfigConst.oldstb && (
            <STBAverages
              setAveragesPopUp={setAveragesPopUp}
              setAvgPopUpBody={setAvgPopUpBody}
              nodeTeleStatus={nodeTeleStatus}
              nodeAvgData={mlBasicData}
              isFullDiagPresent={isFullDiagAvail}
            />
          )}
        <div className="designMaintainer" />
        {viewType?.toUpperCase() === labels.FLAPS?.toUpperCase() &&
          !isNeighValue && (
            <ClearFlaps
              deviceType={
                exportDeviceType
                  ? exportDeviceType?.toUpperCase()
                  : ConfigConst.emta.toUpperCase()
              }
              selectedMac={props?.selectedMac}
            />
          )}
        {mlBasicData !== undefined && mlBasicData?.length !== 0 && (
          <TelemetryActionMenu
            menuType="vertical"
            viewDropdown={viewType === "full" ? "All" : viewType}
            columnData={tableAttributes}
            rowData={tableData}
            nodeDevicesList={teleData}
            // export csv props
            nodeId={exportDeviceId ?? currNodedata?.nodeId}
            deviceType={getBasicDeviceType(modemBasicType?.toUpperCase())}
            // flap history props
            selectedMac={props?.selectedMac}
            isNeighbourhood={true}
            isMultiDevice={true}
          />
        )}
      </div>
      <div>
        <DiagContainer
          data={teleData}
          isLoading={mlBasicIsLoading}
          error={mlBasicError}
          sectionName={labels.NODE_TELEMETRY_INFO?.toUpperCase()}
          handleRefresh={handleNodeModemListRefresh}
        >
          <MultiDeviceNodeTable
            tableAttributes={tableAttributes}
            nodeDevicesList={teleData}
            onDataChange={setTableData}
            handleDragStart={handleDragStart}
            handleDragOver={handleDragOver}
            handleOnDrop={handleOnDrop}
            handleDragEnter={handleDragEnter}
            dragOver={dragOver}
          />
        </DiagContainer>
        <CustomPopUp
          showVal={popUpFlag}
          headerText={popUpHeader}
          bodyElement={popUpBody}
          handleClose={() => {
            setPopUpFlag(false);
            dispatch(SetNodeDevicesError(false));
          }}
        />
        <CustomPopUp
          showVal={averagesPopUp}
          headerText={labels.NODE_AVERAGES}
          bodyElement={avgPopUpBody}
          handleClose={() => {
            setAveragesPopUp(false);
          }}
        />
      </div>
    </Paper>
  );
}
