import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../middleware/apiActions";
import { RENS_SEARCH_URI } from "../../middleware/apiEndpoints";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "renSearch",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    getRENSearchBegin: (as) => {
      as.isLoading = true;
      as.status = "";
    },
    getRENSearchSuccess: (as, { payload }) => {
      as.isLoading = false;
      as.data = payload;
      as.status = storeConst.OK;
    },
    getRENSearchFailure: (as, { payload }) => {
      as.isLoading = false;
      as.error = payload;
      as.data = undefined;
      as.status = storeConst.ERROR;
    },
  },
});

const { getRENSearchBegin, getRENSearchSuccess, getRENSearchFailure } =
  slice.actions;
export default slice.reducer;

export const getRENSearch = (searchedREN: string) => (dispatch: any) => {
  dispatch(
    apiCallBegan({
      url: RENS_SEARCH_URI + searchedREN,
      onStart: getRENSearchBegin.type,
      onSuccess: getRENSearchSuccess.type,
      onError: getRENSearchFailure.type,
    })
  );
};
