import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { TESTTYPE_CONFIG } from "../../constants/CertificationsConfig";
import ASTBClient from "../../network/httpclient/astb/AstbClient";
import CableModemClient from "../../network/httpclient/cablemodem/CableModemClient";
import IHPClient from "../../network/httpclient/ihp/IHPClient";
import IPTVInfoClient from "../../network/httpclient/iptv/IPTVInfoClient";
import ODUClient from "../../network/httpclient/odu/ODUClient";
import ONTClient from "../../network/httpclient/ont/ONTClient";
import STBClient from "../../network/httpclient/stb/STBClient";
import {
  EnableWHTFlag,
  SetDeviceStatus,
  SetFriendlyName,
  SetWHTFlag,
} from "../../store/actions/account/accountInfo/ActiveAccountAction";
import { StartLoadingDiagDevices } from "../../store/actions/account/diagnostics/ActiveDiagDevicesActions";
import { Device } from "../../store/models/account/accountInfo/Device";
import { DiagDevice } from "../../store/models/account/diagnostics/DiagDevice";
import { addDeviceStatus } from "../reusable/StoreCertificationObj";
//import { addfriendlyName } from "../reusable/StoreCertificationObj";
import { extractW3IdOfHSI, filterDevicesByTopology } from "../reusable/Util";

export default function SetALLDeviceStatus() {
  const {
    sortedDevices,
    diagDevicesData,
    selectedSA,
    WHTFlag,
    enableWHTFlag,
    accType,
    iptvNetworkInfo,
    stbSummaryInfo,
  } = useSelector(
    (state: any) => ({
      WHTFlag: state.AccountState.ActiveAccountDetails.wHTFlag,
      enableWHTFlag: state.AccountState.ActiveAccountDetails.enableWHT,
      sortedDevices: state.AccountState.ActiveAccountDetails.sortedDevices,
      diagDevicesData: state.AccountState.DiagDevicesInfo.diagDevices,
      accType: state.AccountState.ActiveAccountDetails.accountType,
      selectedSA: state.AccountState.ActiveAccountDetails.selectedSA,
      iptvNetworkInfo: state.AccountState.IptvNetworkInfo.data,
      stbSummaryInfo: state.AccountState.STBSummaryInfo.data,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const WHTDeviceCat = TESTTYPE_CONFIG.WHT.deviceAllowed;
  const [certiDevices, setCertiDevices] = useState<Device[]>([]);
  const currentLanConnectionType =
    iptvNetworkInfo !== undefined
      ? iptvNetworkInfo?.lanConnectionType?.toUpperCase()
      : undefined;
  useEffect(() => {
    if (WHTFlag) {
      if (sortedDevices !== undefined && sortedDevices?.length !== 0) {
        let filteredDevices = sortedDevices.filter(
          (d: any) => WHTDeviceCat?.indexOf(d.deviceCategory) > -1
        );
        let computedDevices = filterDevicesByTopology(filteredDevices, accType);
        setCertiDevices(computedDevices?.filteredDevices);
        dispatch(StartLoadingDiagDevices());
      }
    }
  }, [WHTFlag]);
  useEffect(() => {
    if (diagDevicesData !== undefined && diagDevicesData?.length !== 0) {
      let count = 0;
      diagDevicesData?.map((d: any) => {
        if (!d.isLoading && d?.data !== undefined) count += 1;
      });
      if (count !== 0 && count === diagDevicesData?.length) {
        if (WHTFlag) dispatch(SetWHTFlag(false));
        if (enableWHTFlag) {
          dispatch(EnableWHTFlag(false));
        }
        diagDevicesData?.map((diagR: DiagDevice) => {
          sortedDevices?.map((d: Device) => {
            if (
              d.deviceCategory !== "IEMTA" &&
              d.serialNumber === diagR.deviceKey
            ) {
              let cDevice: any = addDeviceStatus(d, diagR?.data);
              if (cDevice.deviceStatus !== undefined) {
                dispatch(
                  SetDeviceStatus(
                    d.serialNumber,
                    d.deviceCategory,
                    cDevice?.deviceStatus
                  )
                );
              }
            } else if (
              d.deviceCategory === "IEMTA" &&
              diagR.deviceKey === "IEMTA" + d.serialNumber
            ) {
              let cDevice = addDeviceStatus(d, diagR?.data);
              if (cDevice.deviceStatus !== undefined) {
                dispatch(
                  SetDeviceStatus(
                    d.serialNumber,
                    d.deviceCategory,
                    cDevice?.deviceStatus
                  )
                );
              }
            }
            if (
              d.deviceCategory === "IPTV" &&
              diagR.deviceKey === d.serialNumber
            ) {
              //et cDevice = addFriendlyName(d, diagR?.data);
              if (diagR?.data?.device?.friendlyName !== undefined) {
                dispatch(
                  SetFriendlyName(
                    d.serialNumber,
                    d.deviceCategory,
                    diagR?.data?.device?.friendlyName
                  )
                );
                //console.log("FNAL" +fname)
              }
            }
          });
        });
      }
    }
  }, [diagDevicesData]);

  useEffect(() => {
    if (certiDevices !== undefined && certiDevices?.length !== 0) {
      certiDevices?.map((d) => {
        switch (d.deviceCategory) {
          case "CM":
            dispatch(
              CableModemClient.getCMSummary(d.serialNumber, d.macAddress)
            );
            dispatch(
              CableModemClient.getCMNodeDetails(
                d.serialNumber,
                selectedSA.shubId
              )
            );
            dispatch(
              CableModemClient.getCMDocsisInfo(d.serialNumber, d.macAddress)
            );
            break;
          case "EMTA":
            dispatch(
              IHPClient.getIHPSummary(
                d.deviceCategory,
                d.serialNumber,
                d.macAddress,
                ""
              )
            );
            dispatch(IHPClient.getIHPDocsis(d.serialNumber, d.macAddress));
            break;
          case "IEMTA":
            dispatch(
              IHPClient.getIHPSummary(
                d.deviceCategory,
                d.serialNumber,
                d.cmmac,
                d.atamac
              )
            );
            break;
          case "ONT":
            dispatch(ONTClient.getONTSummary(d.serialNumber));
            dispatch(ONTClient.getONTBandwidth(d.serialNumber));
            break;
          case "IPTV":
            const params = d?.useRpil
              ? {
                  oui: d.oui,
                  serialNum: d.serialNumber,
                  hhid: extractW3IdOfHSI(selectedSA),
                  macAddress: d.macAddress,
                }
              : { oui: d.oui, serialNum: d.serialNumber };
            dispatch(IPTVInfoClient.getIptvDevice(params));
            dispatch(IPTVInfoClient.getIptvNetwork(params));
            break;
          case "ODU":
            dispatch(ODUClient.getODUSummary(d.oui, d.serialNumber));
            dispatch(ODUClient.getODUWirelessInfo(d.oui, d.serialNumber));
            break;
          case "STB":
          case "DESTB":
          case "GSTB":
          case "HSTB":
          case "IPSTB":
            dispatch(STBClient.getSTBSummary(d.serialNumber, d.macAddress));
            break;
          case "ASTB":
            dispatch(ASTBClient.getASTBSummary(d.serialNumber, d.macAddress));
            break;
          default:
            break;
        }
      });
    }
  }, [certiDevices]);
  useEffect(() => {
    if (certiDevices !== undefined && certiDevices?.length !== 0) {
      certiDevices?.map((d) => {
        switch (d.deviceCategory) {
          case "IPTV":
            const params = d?.useRpil
              ? {
                  oui: d.oui,
                  serialNum: d.serialNumber,
                  hhid: extractW3IdOfHSI(selectedSA),
                  macAddress: d.macAddress,
                }
              : { oui: d.oui, serialNum: d.serialNumber };
            if (currentLanConnectionType !== undefined) {
              switch (currentLanConnectionType?.toLowerCase()) {
                case "ethernet":
                  dispatch(IPTVInfoClient.getIptvEthernet(params));
                  break;
                case "wifi":
                  dispatch(IPTVInfoClient.getIptvWifi(params));
                  break;
              }
            }
        }
      });
    }
  }, [currentLanConnectionType]);
  // useEffect(() => {
  //   if (
  //     stbSummaryInfo !== undefined &&
  //     stbSummaryInfo?.hasOwnProperty("cmMac") &&
  //     stbSummaryInfo.cmMac !== null &&
  //     stbSummaryInfo.cmMac !== ""
  //   ) {
  //     dispatch(
  //       STBClient.getSTBCMSummary(
  //         stbSummaryInfo.serialNum,
  //         stbSummaryInfo.cmMac
  //       )
  //     );
  //     dispatch(
  //       STBClient.getSTBInterfaces(
  //         stbSummaryInfo.serialNum,
  //         stbSummaryInfo.cmMac
  //       )
  //     );
  //     dispatch(
  //       STBClient.getSTBDocsis(stbSummaryInfo.serialNum, stbSummaryInfo.cmMac)
  //     );
  //   }
  // }, [stbSummaryInfo]);
  return <div></div>;
}
