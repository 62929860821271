export const multiDeviceDataSetType = [
  // {
  //   dataSetName: "FULL",
  //   subDataSetViews: ["ALL", "FLAPS", "US SC QAM", "DS SC QAM"],
  // },
  //   { dataSetName: "BASIC", subDataSetViews: ["BASIC"] },
  {
    dataSetName: "BASIC",
    subDataSetViews: [
      { columnKey: "basic", columnName: "BASIC", isVisible: true },
    ],
  },
];
interface TableAttr {
  columnKey: string;
  columnName: string;
  jsonDataMapping: string;
  haveMultipleValues?: boolean;
  displayAttribute?: string;
  specInfo?: any;
  getDataFrom: string;
  isPinned: boolean;
  isVisible: boolean;
  position: number;
  allowedLoading?: boolean;
}

export const multiAttrList: TableAttr[] = [
  {
    columnKey: "serviceAddress",
    columnName: "Home Address",
    jsonDataMapping: "serviceAddress",
    getDataFrom: "accountSummaryResponse",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "type",
    columnName: "Type",
    jsonDataMapping: "type",
    getDataFrom: "accountSummaryResponse",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "macAddress",
    columnName: "MAC Address",
    jsonDataMapping: "macAddress",
    getDataFrom: "accountSummaryResponse",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "model",
    columnName: "Model",
    jsonDataMapping: "model",
    getDataFrom: "accountSummaryResponse",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "smtId",
    columnName: "SMT",
    jsonDataMapping: "smtId",
    getDataFrom: "accountSummaryResponse",
    isPinned: false,
    isVisible: true,
    position: 0,
  },

  {
    columnKey: "uptime",
    columnName: " Uptime",
    jsonDataMapping: "uptime",
    getDataFrom: "regstatus",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "cmtsCmRegisteredTime",
    columnName: "Reg Time",
    jsonDataMapping: "cmtsUptime",
    getDataFrom: "regstatus",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "status",
    columnName: "Status",
    jsonDataMapping: "status",
    getDataFrom: "status",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ip",
    columnName: "IP Address",
    jsonDataMapping: "ip",
    getDataFrom: "regstatus",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedLoading: true,
  },
];

export const getMultiDeviceNodeViewConfig = () => {
  let configList: any = [];

  let tempViewAttr = multiAttrList;

  configList?.push({
    attributes: tempViewAttr,
  });

  return configList;
};
