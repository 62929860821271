import { useEffect, useState } from "react";
import { BiCommentAdd } from "react-icons/bi";
import { useDispatch } from "react-redux";

import "../../../styles/map/Map.scss";
import CustomPopOver from "../../../../components/reusable/CustomPopOver";
import {
  createMemo,
  resetCrudData,
} from "../../../store/slices/mapItems/memo/memo";
import {
  GetMapDetailsProps,
  GetMemoProps,
  GetNodeSiteDetailsProps,
} from "../../../util/reduxFunctions/getTopologyState";
import TopologyLabels from "../../../constants/topologyLabels";
import AddMessageModal from "../../reusable/AddMessageModal";
import appConst from "../../../constants/appConst";
import { createMemoSuccessData } from "../../../store/slices/mapItems/node/nodeSiteDetail";

export default function AddMemo() {
  const labels = TopologyLabels();
  const dispatch = useDispatch();
  const { activeMapElement } = GetMapDetailsProps();
  const rootNodeSiteId =
    activeMapElement?.properties?.type === appConst.node
      ? activeMapElement?.properties?.data?.nodeNum
      : activeMapElement?.properties?.rootNodeSiteId;
  const { allNodeData } = GetNodeSiteDetailsProps();
  const { memoCrudStatus, memoCrudType, memoCrudData } = GetMemoProps();

  const [isAddButtonVisible, setAddButtonVisible] = useState(false);
  const [isMemoModalVisible, setMemoModalVisible] = useState(false);
  const [memoInputTxt, setMemoInputTxt] = useState("");

  useEffect(() => {
    //Show add memo button if memo is not created yet
    const index = allNodeData?.findIndex(
      (e: any) => e.nodeSiteId === String(rootNodeSiteId)
    );
    if (index !== -1) {
      const commentIndex = allNodeData?.[
        index
      ]?.memoComment?.data?.features?.findIndex(
        (e: any) =>
          e.properties?.data?.networkId ===
          (activeMapElement?.properties?.id
            ? activeMapElement?.properties?.id
            : activeMapElement?.properties?.data?.networkId)
      );
      if (commentIndex === -1 || commentIndex === undefined) {
        setAddButtonVisible(true);
      } else {
        setAddButtonVisible(false);
      }
    } else {
      setAddButtonVisible(false);
    }
  }, [activeMapElement, allNodeData]);

  useEffect(() => {
    if (
      memoCrudStatus !== undefined &&
      memoCrudStatus !== "" &&
      memoCrudType === appConst.create &&
      memoCrudStatus === appConst.OK
    ) {
      dispatch(
        createMemoSuccessData({
          data: memoCrudData?.data?.features?.[0],
          rootNodeSiteId: rootNodeSiteId,
        })
      );
    }

    return () => {
      dispatch(resetCrudData());
    };
  }, [memoCrudStatus]);

  const onAddBtnclick = () => {
    setMemoInputTxt("");
    setMemoModalVisible(true);
  };

  const onAddClick = (memo: any) => {
    setMemoModalVisible(false);
    dispatch(
      createMemo(
        activeMapElement?.properties?.id
          ? activeMapElement?.properties?.id
          : activeMapElement?.properties?.data?.networkId,
        {
          latitude: activeMapElement?.geometry?.lat,
          longitude: activeMapElement?.geometry?.lng,
          comment: memo,
        }
      )
    );
  };

  return (
    <>
      {activeMapElement !== undefined && isAddButtonVisible ? (
        <CustomPopOver
          position="left"
          popOverData={labels.addMemo}
          index="popOver"
          originComp={
            <div className="addMemoCont" onClick={onAddBtnclick}>
              <BiCommentAdd />
            </div>
          }
        />
      ) : null}
      <AddMessageModal
        isShown={isMemoModalVisible}
        leftBtnText={labels.cancel}
        rightBtnText={labels.add}
        headerText={labels.addMemo}
        placeholderTxt={`${labels.typeMemoHere} (${labels.max1000CharsAllowed})`}
        errorMessage={labels.pleaseAddMemo}
        inputTxt={memoInputTxt}
        setInputTxt={setMemoInputTxt}
        onLeftClick={() => setMemoModalVisible(false)}
        onRightClick={onAddClick}
      />
    </>
  );
}
