import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../topology/store/middleware/apiActions";
import {
  NETWORK_SERVICE_URL,
  NODE_CERTIFICATION_URI,
} from "../../middleware/apiEndpoints";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "postNodeCertResponse",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    // action => action handler
    postNodeCertificationBegin: (postNodeCertResponse) => {
      postNodeCertResponse.isLoading = true;
    },
    postNodeCertificationSuccess: (postNodeCertResponse, action) => {
      postNodeCertResponse.isLoading = false;
      postNodeCertResponse.data = action.payload;
      postNodeCertResponse.status = storeConst.OK;
    },
    postNodeCertificationFailure: (postNodeCertResponse, action) => {
      postNodeCertResponse.isLoading = false;
      postNodeCertResponse.error = action.payload;
      postNodeCertResponse.data = undefined;
      postNodeCertResponse.status = storeConst.ERROR;
    },
  },
});

export const {
  postNodeCertificationBegin,
  postNodeCertificationSuccess,
  postNodeCertificationFailure,
} = slice.actions;
export default slice.reducer;

//action creators
export const postNodeCertDetails = (requestData: any) =>
  apiCallBegan({
    baseURL: NETWORK_SERVICE_URL,
    url: NODE_CERTIFICATION_URI,
    data: requestData,
    method: "post",
    onStart: postNodeCertificationBegin.type,
    onSuccess: postNodeCertificationSuccess.type,
    onError: postNodeCertificationFailure.type,
  });
