import { FormsHistoryState } from "../../states/history/FormsHistory";
import {
  FORMS_HISTORY_BEGIN,
  FORMS_HISTORY_SUCCESS,
  FORMS_HISTORY_RESET,
  FORMS_HISTORY_FAILURE,
  FormsHistoryActionTypes,
} from "../../types/history/FormsHistory";

const initialState: FormsHistoryState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function FormsHistoryReducer(
  state = initialState,
  action: FormsHistoryActionTypes
): FormsHistoryState {
  switch (action.type) {
    case FORMS_HISTORY_BEGIN:
      return {
        ...state,
        isLoading: true,
      };
    case FORMS_HISTORY_SUCCESS:
      if (action.payload !== undefined) {
        if (action.payload?.first) {
          action.payload["indexes"] = [
            {
              index: action.payload?.number,
              data: action.payload?.content,
            },
          ];
        } else {
          const prevData = state.data?.indexes;
          const newData = [
            {
              index: action.payload?.number,
              data: action.payload?.content,
            },
          ];
          action.payload[`indexes`] = [...prevData, ...newData];
        }
      }
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case FORMS_HISTORY_RESET:
      return {
        ...state,
        data: undefined,
      };
    case FORMS_HISTORY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}
