import { useState, useMemo, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Divider,
  Paper,
} from "@material-ui/core";
import PodsListTableHead from "./PodsListTableHead";
import { isNull } from "../reusable/Util";
import { POD } from "../../store/models/account/diagnostics/pods/POD";
import AppLabels from "../../constants/App_Labels";
import ExceptionBox from "../reusable/ExceptionBox";
import { checkRSSIValue, getRSSIValue } from "../reusable/Util";
import CustomPopOver from "../reusable/CustomPopOver";

const PodsOverViewTable = (props: any) => {
  const labels = AppLabels();
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const { podList } = useSelector(
    (state: any) => ({
      podList: state.AccountState.PodInfo.data,
    }),
    shallowEqual
  );
  const [pods, setPods] = useState<POD[]>(podList
    ?.filter((pod: any) => pod?.deviceFriendlyName !== "XLE"));
  useEffect(() => {
    setPods(podList
      ?.filter((pod: any) => pod?.deviceFriendlyName !== "XLE"));
  }, [podList]);
  const podsData = useMemo(() => {
    let computedPods = pods;
    if (computedPods !== undefined && pods?.length !== 0) {
      if (search && searchBy === "name") {
        computedPods = computedPods.filter((pod) =>
          pod?.deviceFriendlyName
            ?.toLowerCase()
            ?.includes(search?.toLowerCase())
        );
      } else if (search && searchBy === "macAddress") {
        computedPods = computedPods.filter((pod) =>
          pod?.deviceId?.toLowerCase()?.includes(search?.toLowerCase())
        );
      } else if (search && searchBy === "serialNum") {
        computedPods = computedPods.filter((pod) =>
          pod?.serialNumber?.toLowerCase()?.includes(search?.toLowerCase())
        );
      } else if (search && searchBy === "model") {
        computedPods = computedPods.filter((pod) =>
          pod?.model?.toLowerCase()?.includes(search?.toLowerCase())
        );
      }
      //Sorting Devices
      if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computedPods = computedPods.sort((a: any, b: any) => {
          return reversed * a[sorting.field].localeCompare(b[sorting.field]);
        });
      }
    }
    return computedPods;
  }, [search, searchBy, sorting, pods]);

  return (
    <>
      <Paper className="deviceTablePaper">
        <div className="deviceTabLabel">
          {labels.PODS_DEVICES.toUpperCase()}
        </div>
        <Divider orientation="horizontal" />
        {podsData !== undefined ? (
          <TableContainer>
            <Table className="deviceTable">
              <PodsListTableHead
                onSearch={(value: any, by: string) => {
                  setSearch(value);
                  setSearchBy(by);
                }}
                devices={undefined}
                onSorting={(field, order) => setSorting({ field, order })}
              />

              {podsData?.length === 0 ? (
                <TableRow tabIndex={-1} key="exception">
                  <TableCell colSpan={6}>
                    <div className="diagErrorCont">
                      <ExceptionBox
                        headerText="No Pods to display."
                        bodyText="The ones provisioned to this account will reflect here."
                        primaryBtn={false}
                        secBtn={false}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                <TableBody className="dTabBody">
                  {podsData?.map((pod, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow tabIndex={-1} key={pod.serialNumber}>
                        <TableCell id={labelId}>
                          {pod.deviceFriendlyName?.toUpperCase()}
                        </TableCell>
                        <TableCell>{isNull(pod.model)}</TableCell>
                        <TableCell>
                          <span className="deviceMAC">
                            {isNull(pod.deviceId)}
                          </span>
                        </TableCell>
                        <TableCell>{isNull(pod.serialNumber)}</TableCell>
                        <TableCell>
                          <span>
                            {isNull(pod.connectionState)?.toUpperCase()}
                          </span>
                        </TableCell>
                        <TableCell>
                          {/*   {pod?.hasOwnProperty("health")
                              ? isNull(pod.health.status?.toUpperCase())
                              : ""} */}
                          {pod?.hasOwnProperty("statistics") &&
                          pod.statistics?.hasOwnProperty("rssi") &&
                          isNull(pod.statistics.rssi) ? (
                            <>
                              <CustomPopOver
                                popOverData={checkRSSIValue(
                                  isNull(pod.statistics.rssi)?.toUpperCase()
                                )}
                                originComp={getRSSIValue(
                                  isNull(pod.statistics.rssi)?.toUpperCase()
                                )}
                                index="popOver"
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
              <caption></caption>
            </Table>
          </TableContainer>
        ) : (
          <></>
        )}
      </Paper>
    </>
  );
};

export default PodsOverViewTable;
