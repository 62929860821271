import "date-fns";
import { format, addDays } from "date-fns";
import "../../../design-tokens/rpd-page/RPDPage.scss";
import { Form, DropdownButton, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useEffect, useState } from "react";
import { Card } from "@material-ui/core";
import ErrorBox from "../../reusable/ErrorBox";
import appLabels from "../../../constants/App_Labels";
import ChipGroup from "../../reusable/ChipGroup";
import {
  setMacAddress,
  vccapSWUpgradeReset,
} from "../../../store/ducksPattern/rpd/vccapSWUpgrade";
import {
  formatMacAddress,
  isMacAddressValid,
  formatRPDMacAddress,
  formathexMacAddress,
} from "../../reusable/Util";
import RPDClient from "../../../network/httpclient/rpd/RPDClient";
import { TAF_ORDER_CONST } from "../../../constants/Global";
import EmailEntry from "../../reusable/EmailEntry";
import { hostname } from "os";

const VCCAPSWupgradeTop = (props: any) => {
  const { oldMacAdd, techData, isLoading } = props;
  const [oldMacAd, setOldMacAd] = useState(formatMacAddress(oldMacAdd));
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("This is Error Message");
  const [seleTestType, setSeleTestType] = useState("Device List");
  const [newMacAd, setNewMacAd] = useState("");
  const [nptId, setNPTId] = useState("");
  const [ip, setIP] = useState("");
  const [hostname, setHostname] = useState("");
  const [version, setVersion] = useState("");
  const [macdomain, setMacDomain] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const deviceList = ["CASA", "Harmonic"];
  const labels = appLabels();
  const [device, setDevice] = useState("Harmonic");
  const [deviceCHipIndex, setDeviceCHipIndex] = useState(0);
  const deviceTypeChips = [
    { title: labels.devHarmonic, description: labels.devTypeHarmonic },
    { title: labels.devCasa, description: labels.devTypeCasa },
  ];
  const changeDeviceIndex = (data: number) => {
    setDeviceCHipIndex(data);
    if (data === 1) {
      dispatch(vccapSWUpgradeReset());
      setDevice(labels.devCasa);
    } else {
      dispatch(vccapSWUpgradeReset());
      setDevice(labels.devHarmonic);
    }

    //dispatch(rpdRegistrationReset());
  };
  const [emails, setEmails] = useState([
    techData?.lanId !== null ? techData?.lanId : "",
  ]);
  const dispatch = useDispatch();

  const onNptIdSubmit = (e: any) => {
    dispatch(vccapSWUpgradeReset());
    setNPTId(e.target.value);
  };

  const onOldMacAdSubmit = (e: any) => {
    dispatch(vccapSWUpgradeReset());
    let sv = formatMacAddress(e.target.value);
    if (sv?.length <= 12) setOldMacAd(sv);
  };

  const onVersionSubmit = (e: any) => {
    setVersion(e.target.value);
  };

  const onMacDomainSubmit = (e: any) => {
    setMacDomain(e.target.value);
  };
  const onHostnameSubmit = (e: any) => {
    setHostname(e.target.value);
  };
  const onIpSubmit = (e: any) => {
    setIP(e.target.value);
  };

  const createInputCasaObject = (
    oldMac: string,
    macdomain: string,
    version: string,
    ip: string,
    hostname: string,
    emails: string,
    npt: string
  ): any => {
    let inputObject = {
      "RPD-MAC": oldMac,
      macDomain: macdomain,
      VersionToDownload: version,
      ip: ip,
      hostname: hostname,
      "MAIL-ID": emails,
      //NPT: npt,
    };
    return inputObject;
  };

  const createInputHarmonicObject = (
    oldMac: string,
    macdomain: string,
    version: string,
    ip: string,
    hostname: string,
    emails: string
  ): any => {
    let inputObject = {
      mac_address: oldMac,
      mac_domain: macdomain,
      version: version,
      ip: ip,
      hostname: hostname,
      //MAIL_ID: emails,
      "MAIL-ID": emails,
    };
    return inputObject;
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleApplySwap = (oldMacAd: string) => {
    if (oldMacAd?.length === 12) {
      let stringMacAdd = formatMacAddress(oldMacAd);
      dispatch(setMacAddress(stringMacAdd));

      if (hostname?.length > 0) {
        if (ip?.length > 0 && ip !== "" && ip !== null) {
          if (version?.length > 0 && version !== "" && version !== null) {
            if (macdomain?.length > 0) {
              if (emails[0] !== "") {
                handleSwap(stringMacAdd);
              } else {
                setOpen(true);
                setError(labels.emailEmptyError);
              }
            } else {
              setOpen(true);
              setError(labels.enter_mac_domain);
            }
          } else {
            setOpen(true);
            setError(labels.enter_version);
          }
        } else {
          setOpen(true);
          setError(labels.enter_valid_cmts_ip);
        }
      } else {
        setOpen(true);
        setError(labels.enter_valid_cmts_hostname);
      }
    } else {
      setOpen(true);
      setError(labels.macIdEmptyError);
    }
  };

  const handleSwap = (oldMacAdd: string) => {
    if (oldMacAdd?.length === 12) {
      var macadd = formathexMacAddress(oldMacAdd);
      if (hostname?.length > 0) {
        if (ip?.length > 0 && ip !== "" && ip !== null) {
          if (version?.length > 0 && version !== "" && version !== null) {
            if (macdomain?.length > 0) {
              if (device === "CASA") {
                // if (nptId?.length > 0) {
                dispatch(
                  RPDClient.createVCCAPSW(
                    // "RPD software upgrade for vccap casa",
                    // "RPD-Upgradation",
                    TAF_ORDER_CONST.vccapCasaSWUpgradeBotName,
                    TAF_ORDER_CONST.vccapCasaSWUpgradeSuiteName,
                    createInputCasaObject(
                      macadd,
                      macdomain,
                      version,
                      ip,
                      hostname,
                      emails.toString(),
                      nptId
                    )
                  )
                );
                // } else {
                //   setOpen(true);
                //   setError(labels.enter_nptid);
                // }
              } else {
                dispatch(
                  RPDClient.createVCCAPSW(
                    //"Vccap Harmonic software upgrade",
                    //"vccap-RPD-upgradation-harmonic",
                    TAF_ORDER_CONST.vccapHarmonicSWUpgradeBotName,
                    TAF_ORDER_CONST.vccapHarmonicSWUpgradeSuiteName,
                    createInputHarmonicObject(
                      macadd,
                      macdomain,
                      version,
                      ip,
                      hostname,
                      emails.toString()
                    )
                  )
                );
              }
              dispatch(setMacAddress(undefined));
            } else {
              setOpen(true);
              setError(labels.enter_mac_domain);
            }
          } else {
            setOpen(true);
            setError(labels.enter_version);
          }
        } else {
          setOpen(true);
          setError(labels.enter_valid_cmts_ip);
        }
      } else {
        setOpen(true);
        setError(labels.enter_valid_cmts_hostname);
      }
    } else {
      setOpen(true);
      setError(labels.macIdEmptyError);
    }
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleApplySwap(oldMacAd);
    }
  };

  const onNewMacAdSubmit = (e: any) => {
    dispatch(vccapSWUpgradeReset());
    let sv = formatMacAddress(e.target.value);
    if (sv?.length <= 12) setNewMacAd(sv);
  };

  const DropdownMenu = ({ label, data, value, onSelect }: any) => (
    <div className="inputContainer">
      <Form.Label>{label}</Form.Label>
      <DropdownButton
        title={value}
        onSelect={(v: any) => onSelect(v)}
        size="sm"
        id="dropdownBtn"
      >
        {data.map((e: any, i: number) => (
          <Dropdown.Item key={i} eventKey={e}>
            {e}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );

  return (
    <>
      <Card className="rpdRootContainer">
        <div className="rpdContainer">
          <div className="rpdRow">
            {/* <div className="inputContainer">
              <Form.Group>
                <DropdownMenu
                  label="VCCAP"
                  data={deviceList}
                  value={device}
                  onSelect={setDevice}
                />
              </Form.Group>
            </div> */}
            <div className="rpdInputontainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.deviceType}
                </Form.Label>
                <div className="titleChipCard">
                  <ChipGroup
                    chips={deviceTypeChips}
                    activeChipIndex={deviceCHipIndex}
                    onIndexChange={changeDeviceIndex}
                  />
                </div>
              </Form.Group>
            </div>
          </div>
          <div className="rpdRow">
            <div className="rpdInputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.mac_address}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="rpdInputField"
                  onChange={onOldMacAdSubmit}
                  className="rpdInputField"
                  id="rpdInputField"
                  value={oldMacAd}
                  placeholder={labels.typeRpdMacAddress}
                />
              </Form.Group>
            </div>
            <div className="rpdInputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.CMTSHostname}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="rpdInputField"
                  onChange={onHostnameSubmit}
                  className="rpdInputField"
                  id="rpdInputField"
                  value={hostname}
                  placeholder={labels.type_cmts_hostname}
                />
              </Form.Group>
            </div>
            <div className="rpdInputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.CMTSIp}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="rpdInputField"
                  onChange={onIpSubmit}
                  className="rpdInputField"
                  id="rpdInputField"
                  value={ip}
                  onKeyPress={(e: any) => handleKeyPress(e)}
                  placeholder={labels.type_cmts_ip}
                />
              </Form.Group>
            </div>
          </div>
          <div className="rpdRow">
            <div className="rpdInputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.version}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="rpdInputField"
                  onChange={onVersionSubmit}
                  className="rpdInputField"
                  id="rpdInputField"
                  value={version}
                  placeholder={labels.type_version}
                />
              </Form.Group>
            </div>

            <div className="rpdInputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.mac_domain}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="rpdInputField"
                  onChange={onMacDomainSubmit}
                  className="rpdInputField"
                  id="rpdInputField"
                  value={macdomain}
                  placeholder={labels.type_mac_domain}
                />
              </Form.Group>
            </div>

            <div className="rpdInputContainer">
              {device === "CASA" ? (
                // <Form.Group>
                //   <Form.Label>{labels.nptID}</Form.Label>
                //   <Form.Control
                //     disabled={false}
                //     name="rpdInputField"
                //     onChange={onNptIdSubmit}
                //     className="rpdInputField"
                //     id="rpdInputField"
                //     value={nptId}
                //     placeholder={labels.typeNptID}
                //   />
                // </Form.Group>
                <div className="inputContainer"></div>
              ) : (
                <div className="inputContainer"></div>
              )}
            </div>
          </div>
          <div className="rpdRow">
            <EmailEntry
              title={labels.rpdEmail}
              email={emails}
              setEmail={setEmails}
              setIsEmailValid={setIsEmailValid}
              titleStyle={{
                fontSize: "12px",
                fontWeight: 500,
                margin: "0px",
                color: "#000",
              }}
              buttonStyle={{
                fontSize: "12px",
                background: "none",
                border: "none",
                padding: "0px",
                color: "#da291c",
              }}
              emailInputStyle={{
                fontSize: "12px",
                color: "#000",
                paddingLeft: "5px;",
                fontWeight: 500,
                borderRadius: "5px",
                border: "1px solid #808284",
              }}
            />
          </div>
          <div className="rpdRow">
            <button
              disabled={isLoading}
              className="applyFilersBtn"
              onClick={() => handleApplySwap(oldMacAd)}
            >
              {labels.SUBMIT}
            </button>
          </div>
        </div>

        {/* <div className="filterSwapBtn">
          <button
            className="applyFilersBtn"
            onClick={() => handleApplySwap(oldMacAd)}
          >
            {labels.SUBMIT}
          </button>
        </div> */}
      </Card>
      <ErrorBox
        open={open}
        error={error}
        setOpen={setOpen}
        handleClose={handleClose}
      />
    </>
  );
};
export default VCCAPSWupgradeTop;
