import {
  NOTIFICATION_CRUD_BEGIN,
  NOTIFICATION_CRUD_FAILURE,
  NOTIFICATION_CRUD_SUCCESS,
  NOTIFICATION_CRUD_RESET,
  NotificationCRUDActionTypes,
} from "../../types/notification/NotificationCRUD";
import { NotiCRUDState } from "../../states/notification/NotiCRUDState";

const initialState: NotiCRUDState = {
  isLoading: false,
  rType: "",
  data: undefined,
  error: undefined,
  status: "",
};

export function NotiCRUDReducer(
  state = initialState,
  action: NotificationCRUDActionTypes
): NotiCRUDState {
  switch (action.type) {
    case NOTIFICATION_CRUD_BEGIN:
      return {
        isLoading: true,
        rType: action.rType,
      };
    case NOTIFICATION_CRUD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case NOTIFICATION_CRUD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };
    case NOTIFICATION_CRUD_RESET:
      return {
        ...state,
        data: undefined,
        error: undefined,
        status: "",
        rType: "",
      };

    default:
      return state;
  }
}
