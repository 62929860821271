import { PodState } from "../../../../states/account/diagnostics/pods/PodState";
import {
  PODS_BEGIN,
  PODS_FAILURE,
  PODS_SUCCESS,
  PODS_RESET,
  PODSActionTypes,
} from "../../../../types/account/diagnostics/pods/Pods";

const initialState: PodState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function PodReducer(
  state = initialState,
  action: PODSActionTypes
): PodState {
  switch (action.type) {
    case PODS_BEGIN:
      return {
        isLoading: true,
      };
    case PODS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case PODS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };
    case PODS_RESET:
      return {
        ...state,
        data: undefined,
        status: "",
      };
    default:
      return state;
  }
}
