import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { GWDeviceState } from "../../../store/states/account/diagnostics/cm/GWDevicesState";
import {
  gwDeviceBegin,
  gwDeviceSuccess,
  gwDeviceFailure,
} from "../../../store/actions/account/diagnostics/cm/GWDevicesAction";
import { GWDevice } from "../../../store/models/account/diagnostics/cm/GWDevice";
import { getOUIChecked, getOUIW3IDChecked } from "../../../components/reusable/Util";
import {
  gwDeviceSummaryBegin,
  gwDeviceSummarySuccess,
  gwDeviceSummaryFailure,
} from "../../../store/actions/account/diagnostics/cm/GWDeviceSummary";
import { GWDeviceSummary } from "../../../store/models/account/diagnostics/cm/GWDeviceSummary";
import { GWDeviceSummaryState } from "../../../store/states/account/diagnostics/cm/GWDeviceSummaryState";
export default class GWDevicesClient {
  static getGWDevices =
    (
      macAddress: string,
      serialNum: string,
      hhid: string
    ): ThunkAction<void, GWDeviceState, unknown, Action<string>> =>
    async (dispatch) => {
      let qParam = getOUIW3IDChecked(serialNum, hhid, macAddress);
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/gateway/hosts",
        params: qParam,
      };
      dispatch(gwDeviceBegin());
      HttpClient.get<GWDevice>(httpParameters)
        .then((gwDevices) => {
          dispatch(gwDeviceSuccess(gwDevices));
          return gwDevices;
        })
        .catch((error) => {
          dispatch(gwDeviceFailure(error));
          return error;
        });
    };
    static getGWDeviceSummary =
    (
      mac: string,
      serialNum: string,
      hhID: string,
      oui: string
    ): ThunkAction<void, GWDeviceSummaryState, unknown, Action<string>> =>
    async (dispatch) => {
      let qParam = getOUIChecked(oui,serialNum, hhID, mac);
  
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/gateway/device",
        params: qParam,
      };
      dispatch(gwDeviceSummaryBegin());
      HttpClient.get<GWDeviceSummary>(httpParameters)
        .then((gwDevicesSummary) => {
          dispatch(gwDeviceSummarySuccess(gwDevicesSummary));
          return gwDevicesSummary;
        })
        .catch((error) => {
          dispatch(gwDeviceSummaryFailure(error));
          return error;
        });
    };
}
