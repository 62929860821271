import React, { useEffect, useRef } from "react";
import { zoomLevelRenderMdus } from "../../../../config/mapConfig";
import appConst from "../../../../constants/appConst";
import { useFeaturesDataAt18 } from "../../../../util/customHooks/topologyHooks";
import { GetGoogleMaps } from "../../../../util/reduxFunctions/getTopologyState";

interface Props {
  gMap: any;
  zoomLevel: any;
  featuresAll: any;
  isVisible: any;
}

const MDU = ({ gMap, featuresAll, zoomLevel, isVisible }: Props) => {
  const { currentViewBDY } = GetGoogleMaps();
  const markerWM = useRef(0);
  const featureWM = useFeaturesDataAt18(featuresAll, currentViewBDY, zoomLevel);

  useEffect(() => {
    if (
      gMap &&
      isVisible &&
      zoomLevel >= zoomLevelRenderMdus &&
      featureWM !== undefined &&
      featureWM?.newFeatures?.length > 0 &&
      featureWM?.allFeatures?.length !== markerWM?.current
    ) {
      const tempGeoJson = {
        type: appConst?.FeatureCollection,
        features: featureWM?.newFeatures,
      };
      gMap?.data.addGeoJson(tempGeoJson);
      markerWM.current = featureWM?.allFeatures?.length;
    }
  }, [featureWM]);
  return <></>;
};

export default React.memo(MDU);
