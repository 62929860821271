import { useMemo } from "react";
import {
  calculateCMUptime,
  getSpecChannels,
  getSpecValue,
  getStbTypeValue,
  getTelemetryHomeAddress,
  getValueColorFromMap,
  getValueFromMap,
  isCMOperational,
  renderSpecColor,
} from "../../components/reusable/NodeUtil";
import {
  changeTimeFormat,
  hzToMHz,
  secToTime,
  usInfterfaceDisplay,
} from "../../components/reusable/Util";
import {
  GetNodeInfoDetails,
  GetTelemetryDetails,
} from "../../get-api/GetResponse";
import { GetTelemetryProps } from "../../topology/util/reduxFunctions/getTopologyState";
const STBTeleDataMapping = (nodeDevicesList: any) => {
  const ducksConfigData = GetTelemetryDetails().ducksConfigData;
  const viewType = GetTelemetryProps().viewType;
  const nodeInfo = GetNodeInfoDetails().currNodedata;
  const viewsd = ducksConfigData?.userPreferences?.node?.telemetry?.views;

  let tableColFields = viewsd?.find((view: any) => {
    if (view?.viewTypeKey?.toUpperCase() === viewType?.toUpperCase()) {
      return view?.attributes;
    }
  });

  const getSinglevaluedSpecFlag = (object: object) => {
    let flag = true;
    for (const [key, value] of Object.entries(object)) {
      flag = flag && value !== undefined;
    }
    return flag;
  };
  const getMultivaluedSpecFlag = (object: object) => {
    let flag = true;
    for (const [key, value] of Object.entries(object)) {
      flag = flag && (value === undefined || value?.length >= 1);
    }
    return flag;
  };
  const getSpecInfoForAttributes = (tableColFields: any) => {
    let usTxAttr: any,
      usSnrAttr: any,
      usTxMinAttr: any,
      usTxMaxAttr: any,
      usSnrMaxAttr: any,
      usSnrMinAttr: any,
      dsSnrMaxAttr: any,
      dsSnrMinAttr: any,
      dsRxMinAttr: any,
      dsRxMaxAttr: any,
      dsRxAttr: any,
      dsSnrAttr: any,
      qamSnrAttr: any = {};
    tableColFields?.specAvailAttr?.map((attr: any) => {
      switch (attr?.columnKey) {
        case "us_tx_max":
          usTxMaxAttr = attr;
          break;
        case "us_tx_min":
          usTxMinAttr = attr;
          break;
        case "us_tx":
          usTxAttr = attr;
          break;
        case "us_snr":
          usSnrAttr = attr;
          break;
        case "us_snr_max":
          usSnrMaxAttr = attr;
          break;
        case "us_snr_min":
          usSnrMinAttr = attr;
          break;
        case "ds_snr_min":
          dsSnrMinAttr = attr;
          break;
        case "ds_snr_max":
          dsSnrMaxAttr = attr;
          break;
        case "ds_rx_max":
          dsRxMaxAttr = attr;
          break;
        case "ds_rx_min":
          dsRxMinAttr = attr;
          break;
        case "ds_rx":
          dsRxAttr = attr;
          break;
        case "ds_snr":
          dsSnrAttr = attr;
          break;
        case "qamSnr":
          qamSnrAttr = attr;
          break;
      }
    });
    return {
      usTxSpecInfo: usTxAttr?.specInfo,
      usSnrSpecInfo: usSnrAttr?.specInfo,
      usTxMinSpecInfo: usTxMinAttr?.specInfo,
      usTxMaxSpecInfo: usTxMaxAttr?.specInfo,
      usSnrMaxSpecInfo: usSnrMaxAttr?.specInfo,
      usSnrMinSpecInfo: usSnrMinAttr?.specInfo,
      dsSnrMinSpecInfo: dsSnrMinAttr?.specInfo,
      dsSnrMaxSpecInfo: dsSnrMaxAttr?.specInfo,
      dsRxMinSpecInfo: dsRxMinAttr?.specInfo,
      dsRxMaxSpecInfo: dsRxMaxAttr?.specInfo,
      dsRxSpecInfo: dsRxAttr?.specInfo,
      dsSnrSpecInfo: dsSnrAttr?.specInfo,
      qamSnrSpecInfo: qamSnrAttr?.specInfo,
    };
  };

  let tableData = useMemo(
    () =>
      nodeDevicesList
        ?.map((e: any) => {
          let {
            usTxSpecInfo,
            usSnrSpecInfo,
            usTxMinSpecInfo,
            usTxMaxSpecInfo,
            usSnrMaxSpecInfo,
            usSnrMinSpecInfo,
            dsSnrMinSpecInfo,
            dsSnrMaxSpecInfo,
            dsRxMinSpecInfo,
            dsRxMaxSpecInfo,
            dsRxSpecInfo,
            dsSnrSpecInfo,
            qamSnrSpecInfo,
          } = getSpecInfoForAttributes(tableColFields);
          const summary = e.deviceDiag?.data?.summary;
          const docsis = e.deviceDiag?.data?.docsis;
          const flaps = e.deviceDiag?.data?.flaps;
          const status = e?.deviceDiag?.status;
          const apiError = e?.deviceDiag?.error;
          const d30US = docsis?.docsis30?.upstream;
          const d30DS = docsis?.docsis30?.downstream;
          let usMinTxPower = getSpecValue(d30US?.minTxPower, usTxMinSpecInfo);
          let usMaxTxPower = getSpecValue(d30US?.maxTxPower, usTxMaxSpecInfo);
          let usMaxSnr = getSpecValue(d30US?.maxSnr, usSnrMaxSpecInfo);
          let usMinSnr = getSpecValue(d30US?.minSnr, usSnrMinSpecInfo);
          let dsMinRxPower = getSpecValue(d30DS?.minRxPower, dsRxMinSpecInfo);
          let dsMaxRxPower = getSpecValue(d30DS?.maxRxPower, dsRxMaxSpecInfo);
          let dsSnrMax = getSpecValue(d30DS?.maxSnr, dsSnrMaxSpecInfo);
          let dsSnrMin = getSpecValue(d30DS?.minSnr, dsSnrMinSpecInfo);
          let qamSnr = getSpecValue(summary?.qamSnr, qamSnrSpecInfo);
          let usChannels = getSpecChannels(
            d30US?.channels,
            usTxSpecInfo,
            "txPower"
          );
          usChannels = getSpecChannels(usChannels, usSnrSpecInfo, "snr");
          let dsChannels = getSpecChannels(
            d30DS?.channels,
            dsRxSpecInfo,
            "rx_power"
          );
          dsChannels = getSpecChannels(dsChannels, dsSnrSpecInfo, "snr");
          const cmUptime = summary?.uptime;
          const cmtsOnlineStat = summary?.regStatus?.cmtsStatus;
          const regStatusHeader = summary?.regStatus?.header;
          const cmtsRegTime = summary?.cmtsCmRegisteredTime;
          if (
            docsis === undefined ||
            (getMultivaluedSpecFlag({
              usChannels,
              dsChannels,
            }) &&
              getSinglevaluedSpecFlag({
                usMinTxPower,
                usMaxTxPower,
                usMaxSnr,
                usMinSnr,
                dsMinRxPower,
                dsMaxRxPower,
                dsSnrMax,
                dsSnrMin,
                qamSnr,
              }))
          )
            return {
              apiStatus: {
                isLoading: e.isLoading,
                error: { ...e.deviceDiag?.data },
                status: e.deviceDiag?.data?.summary?.errorCode ? "ERROR" : "OK",
              },
              summary: summary,
              docsis: docsis,
              flaps: flaps,
              status: status,
              apiError: apiError,
              serialNum: e?.serialNumber,
              address: getTelemetryHomeAddress(e.serviceAddress),
              serviceAddress: e.serviceAddress,
              smt: e?.serviceArea,
              cmMac: e?.macAddress,
              ecmMac: e?.macAddress,
              model: e?.model,
              qamModel: summary?.model,
              stbIP: summary?.stbIP,
              osVersion: summary?.osVersion,
              type: getStbTypeValue(summary?.way),
              rdcPower: summary?.rdcPower,
              qamChStatusDisplay: summary?.qamChannelStatus?.value,
              qamFreq: summary?.qamFreq ? hzToMHz(summary?.qamFreq) : "",
              qamSnr: qamSnr,
              qamChannelStatus: summary?.qamChannelStatus?.value,
              qamSnrDisplay: summary?.qamSnr?.value,
              mpegDiscontinuityCount: summary?.mpegDiscontinuityCount,
              qamUncorrected: summary?.qamUncorrected,
              qamTime: secToTime(summary?.qamTime),
              fdcPower: summary?.fdcPower,
              fdcUncorrected: summary?.fdcUncorrected,
              stbUptime: cmUptime > 0 ? secToTime(cmUptime) : "",
              uptime2: cmUptime > 0 ? secToTime(cmUptime) : "",

              cmUptime:
                cmUptime > 0
                  ? calculateCMUptime(cmtsOnlineStat, regStatusHeader, cmUptime)
                  : "",
              cmtsUptime: isCMOperational(cmtsOnlineStat, cmtsRegTime), //Based on cmtsStatus, returns blank or cmtsUptime
              cmOnlineStatus: summary?.cmOnlineStatus,
              cmtsCmOnlineStatus: summary?.regStatus?.cmtsStatus,
              cmtsCmRegisteredTime: secToTime(summary?.cmtsCmRegisteredTime),
              onlineTimes: summary?.onlineTimes,
              onlinePercentage: summary?.onlinePercentage,
              mgmtIP:
                summary?.mgmtIP !== undefined && summary?.mgmtIP !== ""
                  ? summary?.mgmtIP
                  : summary?.mgmtIPv6,

              certStatus: summary?.certStatus,
              certRxStatus: d30DS?.certRxStatus,
              certDSSnrStatus: d30DS?.certDSSnrStatus,
              certTxStatus: d30US?.certTxStatus,
              certUSSnrStatus: d30US?.certUSSnrStatus,
              certCMTSRxStatus: d30US?.certCMTSRxStatus,

              flapsCount: flaps?.counts,
              insertionFails: flaps?.insertionFails,
              hits: flaps?.hits,
              miss: flaps?.miss,
              misses: (flaps?.miss !== undefined && flaps?.hits !== undefined) ? 
              ((flaps.hits + flaps.miss > 0 || flaps.hits < 0 || flaps.miss < 0)  ? (Math.floor((flaps?.miss / (flaps?.hits + flaps?.miss)) * 1000) / 10).toFixed(1): 0) : 
              "",
              powerAdjustments: flaps?.powerAdjustments,
              createTime: changeTimeFormat(flaps?.createTime),
              lastFlapTime: changeTimeFormat(flaps?.lastFlapTime),

              usModulation: d30US?.modulation,
              usChannels: d30US?.channels?.length,
              usMinTxPower: usMinTxPower,
              usMinTxPowerDisplay: renderSpecColor(
                d30US?.minTxPower?.status,
                usMinTxPower
              ),
              usMaxTxPower: usMaxTxPower,
              usMaxTxPowerDisplay: renderSpecColor(
                d30US?.maxTxPower?.status,
                usMaxTxPower
              ),
              usTxPowerDelta: d30US?.txPowerDelta,
              usMinSnr: usMinSnr,
              usMinSnrDisplay: renderSpecColor(d30US?.minSnr?.status, usMinSnr),
              usMaxSnr: usMaxSnr,
              usMaxSnrDisplay: renderSpecColor(d30US?.maxSnr?.status, usMaxSnr),
              usSNRDelta: d30US?.snrDelta,
              usFreq: getValueFromMap(d30US?.channels, "frequency"),
              usTxDisplay: getValueColorFromMap(
                usChannels,
                "txPower.value",
                "txPower.status"
              ),

              usTx: getValueFromMap(usChannels, "txPower.value"),
              usSnrDisplay: getValueColorFromMap(
                usChannels,
                "snr.value",
                "snr.status"
              ),
              usSnr: getValueFromMap(usChannels, "snr.value"),

              dsChannels: d30DS?.channels?.length,
              minRxPower: dsMinRxPower,
              minRxPowerDisplay: renderSpecColor(
                d30DS?.minRxPower?.status,
                dsMinRxPower
              ),
              maxRxPower: dsMaxRxPower,
              maxRxPowerDisplay: renderSpecColor(
                d30DS?.maxRxPower?.status,
                dsMaxRxPower
              ),
              rxPowerDelta: d30DS?.rxPowerDelta,
              dsMinSnr: dsSnrMin,
              dsMinSnrDisplay: renderSpecColor(d30DS?.minSnr?.status, dsSnrMin),
              dsMaxSnr: dsSnrMax,
              dsMaxSnrDisplay: renderSpecColor(d30DS?.maxSnr?.status, dsSnrMax),
              dsSnrDeltaValue: d30DS?.snrDelta,
              dsFreq: getValueFromMap(d30DS?.channels, "frequency"),
              dsRx: getValueFromMap(dsChannels, "rx_power.value"),
              dsRxDisplay: getValueColorFromMap(
                dsChannels,
                "rx_power.value",
                "rx_power.status"
              ),
              dsSnr: getValueFromMap(dsChannels, "snr.value"),
              dsSnrDisplay: getValueColorFromMap(
                dsChannels,
                "snr.value",
                "snr.status"
              ),
              usInterface: d30US?.name,
              usInterfaceDisplay: usInfterfaceDisplay(
                d30US?.name,
                nodeInfo?.phub,
                nodeInfo?.cmtsIp
              ),
            };
        })
        .filter((item: any) => item),
    [nodeDevicesList, tableColFields]
  );
  return {
    tableData,
  };
};

export default STBTeleDataMapping;
