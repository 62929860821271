import * as React from "react";
import { FaRegPlayCircle, FaRegStopCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getMultiNodeBasicInfoByDeviceType,
  getNodeTeleGloabalStatus,
} from "../../components/reusable/NodeUtil";
import AppLabels from "../../constants/App_Labels";
import ConfigConst from "../../constants/ConfigConst";
import {
  getMultiDeviceNodeViewConfig,
  multiDeviceDataSetType,
} from "../../constants/MultiDeviceNodeConfig";
import { GetTechInfo } from "../../get-api/GetResponse";
import {FlapVisibility} from "../../components/reusable/RoleConfig";
import {
  GetNodeBasicInfoDetails,
  GetTelemetryDetails,
} from "../../get-api/GetResponse";
import { useRemoveNavBar } from "../../hooks/reusableHooks";
import { TELE_ABORT_KEY } from "../../topology/store/middleware/abortKeys";
import { addAbortKey } from "../../topology/store/middleware/apiClient";
import {
  getTeleBasicModemsList,
  setAllDiagLoading,
  setDiagType,
  setIsCWEnabled,
  setIsNeighbourhood,
  setModemType,
  setNodeId,
  setViewType,
  stopDiagExecution,
} from "../../topology/store/slices/mapItems/diagnostics/nodeBasicTelemetry";
import { MultiDeviceNodeTab } from "../multi-device-node-page/MultiDeviceNodeTab";

import storeConst from "../../topology/store/storeConst";

import {
  neighbourhoodDataSetType,
  neighbourhoodDataSetTypeEcm,
  neighbourhoodDataSetTypeStb,
  neighbourhoodTeleViewConfig,
  neighbourhoodTeleViewConfigECM,
  neighbourhoodTeleViewConfigSTB,
} from "../../constants/NeighbourhoodTelemetryConfig";
import { saveConfigData } from "../../store/ducksPattern/userPrefConfig";
import { MultiDeviceNeighbourhoodBanner } from "../multi-device-node-page/MultiDeviceNeighbourhoodBanner";

export const MultiDeviceNeighbourhoodPage = (props: any) => {
  // route params from react router
  const { id } = useParams<{ id: string }>();
  const { location: search } = props;
  const searchParams = new URLSearchParams(search.search);
  const cmmac = searchParams.get("mac");
  const deviceType = searchParams.get("dataSet");
  useRemoveNavBar();
  const labels = AppLabels();
  const dispatch = useDispatch();
  const { ducksConfigData } = GetTelemetryDetails();
  const {
    allBasicDiagIsLoading,
    allDiagBasicStatus,
    isBasicAborted,
    mlBasicData,
    nodeBasicId,
    viewBasicType,
    diagBasicStartTime,
    diagBasicEndTime,
  } = GetNodeBasicInfoDetails();
  const nodeTeleStatus = getNodeTeleGloabalStatus(
    allDiagBasicStatus,
    allBasicDiagIsLoading,
    isBasicAborted
  );
  const node_id = React.useRef<boolean>(false);
  const [dataSetName, setDataSetName] = React.useState("");
  const [bannerInfo, setBannerInfo] = React.useState<any>({
    active: "",
    assigned: "",
    responsive: "",
    unresponsive: "",
    startTime: "",
    endTime: "",
    nodeId: "",
    modemListInfo: "",
    deviceType: deviceType?.toUpperCase() ? deviceType?.toUpperCase() : "",
  });
  const [expand, setExpand] = React.useState(true);
  const [tableAttributes, setTableAttributes] = React.useState<any>([]);

  const neighbourhoodDataSet = React.useMemo(() => {
    switch (id?.toLowerCase()?.trim()) {
      case "ecm":
        return neighbourhoodDataSetTypeEcm;
      case "stb":
        return neighbourhoodDataSetTypeStb;
      default:
        return neighbourhoodDataSetType;
    }
  }, [id]);

  let dataSetNames: any = multiDeviceDataSetType?.map((e: any) => {
    return e?.dataSetName;
  });

  const handleToggle = () => {
    setExpand((prev) => !prev);
  };

  const handleRefreshNodeModemList = () => {
    dispatch(
      getTeleBasicModemsList({
        value: cmmac ?? "",
        type: "cmmac",
        diagnoseDevices: true,
        modemType:
          deviceType !== undefined && deviceType != null
            ? deviceType
            : undefined,
      })
    );
  };

  const runTelemetry = () => {
    addAbortKey(TELE_ABORT_KEY);
    setBannerInfo({
      deviceType: deviceType?.toUpperCase() ? deviceType?.toUpperCase() : "",
      active: undefined,
      assigned: undefined,
      responsive: undefined,
      unresponsive: undefined,
      startTime: undefined,
      endTime: undefined,
      nodeId: nodeBasicId,
    });
    if (
      nodeTeleStatus === ConfigConst.complete.toLowerCase() ||
      nodeTeleStatus === ConfigConst.stopped.toLowerCase()
    ) {
      dispatch(
        getTeleBasicModemsList({
          value: cmmac ?? "",
          type: "cmmac",
          diagnoseDevices: true,
          modemType:
            deviceType !== undefined && deviceType != null
              ? deviceType
              : undefined,
        })
      );
    } else dispatch(setAllDiagLoading(true));
  };
  const techData = GetTechInfo()?.data;
  const techRole = () => { 
    if (techData && techData.hasOwnProperty("roles")) {
      const roleExists = techData.roles.some((e: any) => {
        return FlapVisibility?.some((roleVisible: any) => {
          return e === roleVisible;
        });
      });

      return roleExists;  
    }
    return false; // Return false if techData or roles are missing
  };
  
  const stopTelemetry = () => {
    dispatch(stopDiagExecution());
  };
  const isTechRole = techRole();
  const filterNeighTeleViewConfigAtt = () => {
    neighbourhoodTeleViewConfig(isTechRole)?.map((viewObj: any) => {
      if (viewObj?.viewTypeKey === "full" || viewObj?.viewTypeKey === "basic") {
        return {
          ...viewObj,
          attributes: viewObj?.attributes?.filter(
            (attr: any) => attr?.columnKey !== "lines"
          ),
        };
      } else return viewObj;
    });
  };

  const neighTeleViewConfig = React.useMemo(() => {
    switch (id?.toLowerCase()?.trim()) {
      case "cm":
        return filterNeighTeleViewConfigAtt();
      case "ecm":
        return neighbourhoodTeleViewConfigECM(isTechRole);
      case "stb":
        return neighbourhoodTeleViewConfigSTB;
      default:
        return neighbourhoodTeleViewConfig(isTechRole);
    }
  }, [id]);

  React.useEffect(() => {
    if (mlBasicData !== undefined && node_id.current === false) {
      dispatch(setNodeId(mlBasicData[0]?.returnSegment));
      node_id.current = true;
    }
  }, [mlBasicData]);

  React.useEffect(() => {
    dispatch(
      setModemType(
        deviceType !== undefined && deviceType != null ? deviceType : undefined
      )
    );
    dispatch(setIsNeighbourhood(true));
    let viewConfig = getMultiDeviceNodeViewConfig();
    dispatch(saveConfigData(viewConfig));
    const dataSetType = searchParams.get("dataSet");
    const viewTypeParam = searchParams.get("viewType");
    if (dataSetType && dataSetType !== "") {
      dispatch(setDiagType(dataSetType?.toLowerCase()?.trim()));
      setDataSetName(dataSetNames[0]);
    } else {
      dispatch(setDiagType(storeConst.all_no_cw));
      setDataSetName(dataSetNames[0]);
    }
    if (viewTypeParam && viewTypeParam !== "") {
      dispatch(setViewType(viewTypeParam?.toUpperCase()?.trim()));
    }
    dispatch(setIsCWEnabled(false));
  }, [deviceType]);

  React.useEffect(() => {
    if (viewBasicType !== undefined && viewBasicType !== "") {
      ducksConfigData?.userPreferences?.node?.telemetry?.views?.forEach(
        (v: any) => {
          setTableAttributes(v?.attributes);
        }
      );
    }
  }, [viewBasicType]);

  React.useEffect(() => {
    if (deviceType !== undefined && deviceType !== "") {
      dispatch(
        getTeleBasicModemsList({
          value: cmmac ?? "",
          type: "cmmac",
          diagnoseDevices: true,
          modemType:
            deviceType !== undefined && deviceType != null
              ? deviceType
              : undefined,
        })
      );
    }
  }, [deviceType]);

  React.useEffect(() => {
    if (
      mlBasicData?.length > 0 &&
      (nodeTeleStatus === ConfigConst.complete ||
        nodeTeleStatus === ConfigConst.stopped)
    ) {
      const modemListInfo = getMultiNodeBasicInfoByDeviceType(mlBasicData);
      setBannerInfo({
        active: modemListInfo?.active,
        assigned: modemListInfo?.assigned,
        responsive: modemListInfo?.responsive,
        unresponsive: modemListInfo?.unresponsive,
        startTime: diagBasicStartTime,
        endTime: diagBasicEndTime,
        nodeId: nodeBasicId,
        deviceType: deviceType?.toUpperCase() ? deviceType?.toUpperCase() : "",
        modemListInfo: modemListInfo,
      });
    } else if (allBasicDiagIsLoading) {
      setBannerInfo({
        active: undefined,
        assigned: undefined,
        responsive: undefined,
        unresponsive: undefined,
        startTime: undefined,
        endTime: undefined,
        nodeId: nodeBasicId,
        deviceType: deviceType?.toUpperCase() ? deviceType?.toUpperCase() : "",
        modemListInfo: "",
      });
    }
  }, [mlBasicData]);

  React.useEffect(() => {
    runTelemetry();
  }, [deviceType]);
  return (
    <div>
      <div className="emtaLandingPage">
        <div className="gponBannerContainer">
          <MultiDeviceNeighbourhoodBanner
            data={bannerInfo}
            expand={expand}
            cmMacSearchValue={cmmac}
            modemType={deviceType}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              alignItems: "center",
              marginLeft: "auto",
              marginRight: "2%",
            }}
          >
            {/* <span
              style={{
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              {labels.DATA_SET?.toUpperCase()}
            </span>
            &nbsp;
            <CustomDropDown
              selectedOption={dataSetName}
              options={dataSetNames}
              searchFlag={false}
              checkboxFlag={false}
              setSelectedOption={(value: any) => {
                setDataSetName(value);
                //dispatch(SetTeleDatasetType(value));
                dispatch(
                  setDiagType(
                    value === ConfigConst.FULL_NO_CW.toUpperCase()
                      ? storeConst.all_no_cw
                      : storeConst.summary
                  )
                );
              }}
            /> */}
            <div style={{ padding: "0px 20px" }}>
              {mlBasicData !== undefined && mlBasicData?.length !== 0 && (
                <>
                  {!allBasicDiagIsLoading ? (
                    <button className="refreshCertiBtn" onClick={runTelemetry}>
                      {labels.START?.toUpperCase()} <FaRegPlayCircle />
                    </button>
                  ) : (
                    <>
                      <button
                        className="refreshCertiBtn"
                        onClick={() => stopTelemetry()}
                      >
                        {labels.STOP?.toUpperCase()} <FaRegStopCircle />
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
          </div>

          {/* <div className="gponBannerIconsContainer">
            <div
              className="gponCollapseContainer"
              style={{
                rotate: expand ? "0deg" : "180deg",
              }}
            >
              <IoIosArrowDropup
                color="black"
                size={25}
                onClick={handleToggle}
              />
            </div>
          </div> */}
        </div>
        <div className="gponBodyContainer" style={{ padding: "4px" }}>
          <MultiDeviceNodeTab
            tableAttr={tableAttributes}
            data={mlBasicData}
            startDiag={runTelemetry}
            dataSetType={multiDeviceDataSetType}
            dataSetNames={dataSetNames}
            exportDeviceId={cmmac}
            exportDeviceType={id}
            refreshNodeModemList={handleRefreshNodeModemList}
            selectedMac={cmmac ?? ""}
          />
          {/* <MultiDeviceNodeTab
              tableAttr={tableAttributes}
              data={mlBasicData}
              dataSetType={multiDeviceDataSetType}
              dataSetNames={dataSetNames}
              nodeId={currNodedata?.nodeId ? currNodedata?.nodeId : nodeId}
              exportDeviceId={nodeId ? nodeId : smtId}
            /> */}
        </div>
      </div>
    </div>
  );
};
