import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AppLabels from "../../../constants/App_Labels";
import ConfigConst from "../../../constants/ConfigConst";
import EquipmentMenu from "../../../data/forms/RPA/EquipmentMenu";
import {
  GetActiveAccountDetails,
  GetFormHistory,
  GetTechInfo,
  GetWorkOrdersList,
} from "../../../get-api/GetResponse";
import {
  aspStbPaidFreePodValidation,
  capitalize,
  extractW3IdOfHSI,
  getCMMac,
  isIgniteAccount,
  isLegacyAccount,
} from "../../reusable/Util";
import FirstHalf from "./sections/FirstHalf";
import SecondHalf from "./sections/SecondHalf";

interface Props {
  setDetails: any;
  setEquipmentMenu: any;
  setSerialNumbersVerifed: any;
  aspFormInitialData: any;
}

const ASPSDetails = ({
  setDetails,
  setEquipmentMenu,
  setSerialNumbersVerifed,
  aspFormInitialData,
}: Props) => {
  const labels = AppLabels();
  const dispatch = useDispatch();
  const techData = GetTechInfo()?.data;
  const technicianId = GetTechInfo()?.techId;
  const activeWO = GetWorkOrdersList()?.activeWO;
  const legacyAccount = isLegacyAccount();
  const activeAccData = GetActiveAccountDetails()?.data;
  const accountNumber = GetActiveAccountDetails()?.accountNumber;
  const account_type = GetActiveAccountDetails()?.accType;
  const selectedSA = GetActiveAccountDetails()?.selectedSA;
  const cbpId = GetActiveAccountDetails()?.cbpId;
  const sortedDevices = GetActiveAccountDetails()?.sortedDevices;
  const form = GetFormHistory()?.data;
  const formData = GetFormHistory()?.formData;
  const igniteAccount = isIgniteAccount(accountNumber);
  const [initialData, setInitialData] = useState(EquipmentMenu());

  const [acknowledgment, setAcknowledgment] = useState(false);

  const obj = {
    CMMac: getCMMac(sortedDevices),

    account_type: account_type,
    accountNumber: accountNumber,
    hhid: !legacyAccount ? extractW3IdOfHSI(selectedSA) : "",
    techName: techData?.fullName
      ? techData?.fullName.replace(/[^a-zA-Z ]/g, "").trim()
      : "",
    technicianId: technicianId ? technicianId : "",
    //technicianId:"1232",
    HouseNo: selectedSA?.streetNumber ? selectedSA?.streetNumber : "",
    StreetName:
      (selectedSA?.streetName ? capitalize(selectedSA?.streetName) : "") +
      " " +
      (selectedSA?.streetType ? selectedSA?.streetType : ""),
    StreetDirection: selectedSA?.direction ? selectedSA?.direction : "",
    UnitNoOrSuiteNo: selectedSA?.apartmentNumber
      ? selectedSA?.apartmentNumber
      : "",
    City: selectedSA?.city ? capitalize(selectedSA?.city) : "",
    nodeNumber: selectedSA?.shubId ? selectedSA?.shubId : "",
    cbpId: cbpId ? cbpId : "",
    isSTBchecked: false,
    isFreePodChecked: false,
    isPaidPodChecked: false,
    stbquantity: "1",
    acknowledgment: "No",
    podtype: "XE1",
    freepodquantity: "1",
    paidpodquantity: "1",
    //wifi or other
    freepodreasoning: "",
    freepodreasoningcomments: "",
    //stb
    quantitytextbox1: "",
    quantitytextbox2: "",
    quantitytextbox3: "",
    quantitytextbox4: "",
    quantitytextbox5: "",
    quantitytextbox6: "",
    quantitytextbox7: "",
    quantitytextbox8: "",
    quantitytextbox25: "",
    quantitytextbox26: "",
    quantitytextbox27: "",
    quantitytextbox28: "",
    quantitytextbox29: "",
    quantitytextbox30: "",
    quantitytextbox31: "",
    quantitytextbox32: "",
    quantitytextbox33: "",
    quantitytextbox34: "",
    quantitytextbox35: "",
    quantitytextbox36: "",
    //free
    quantitytextbox9: "",
    quantitytextbox10: "",
    quantitytextbox11: "",
    quantitytextbox12: "",
    quantitytextbox13: "",
    quantitytextbox14: "",
    quantitytextbox15: "",
    quantitytextbox16: "",
    //paid
    quantitytextbox17: "",
    quantitytextbox18: "",
    quantitytextbox19: "",
    quantitytextbox20: "",
    quantitytextbox21: "",
    quantitytextbox22: "",
    quantitytextbox23: "",
    quantitytextbox24: "",

    distroEmails: ["gta.woc@rci.rogers.com"],
  };

  const [formSubmissionNo, setFormSubmissionNo] = useState("");
  const [detailsObj, setDetailsObj] = useState(obj);
  const [formHistory, setFormHistory] = useState({} as any);
  const [stbTextInputArray, setStbTextInputArray] = useState(
    aspFormInitialData?.stbSerialNumbers
  );
  const [paidPodInputArray, setPaidPodTextInputArray] = useState(
    aspFormInitialData?.paidPodSerialNumbers
  );
  const [freePodTextInputArray, setFreePodTextInputArray] = useState(
    aspFormInitialData?.freePodSerialNumbers
  );

  useEffect(() => {
    if (formData && formData != "") {
      if (form?.formName === ConfigConst.RPA_ADDPODORSTB) {
        if (formData && formData != "") {
          formData?.equipmentMenu?.map((k: any) =>
            initialData?.map(
              (e, i) => e.key === k && (initialData[i].active = true)
            )
          );

          setInitialData([...initialData]);
          setAcknowledgment(formData?.acknowledgment);
          setFormSubmissionNo(formData?.formSubmissionNo);
          setFormHistory(formData);
        } else resetData();
      } else resetData();
    }
  }, [formData]);

  useEffect(() => {
    if (formHistory) setDetailsObj(formHistory);
  }, [formHistory]);

  useEffect(() => {
    setSerialNumbersVerifed(
      aspStbPaidFreePodValidation(
        detailsObj,
        stbTextInputArray,
        freePodTextInputArray,
        paidPodInputArray
      )
    );
  }, [stbTextInputArray, paidPodInputArray, freePodTextInputArray, detailsObj]);

  const resetData = () => {
    setFormSubmissionNo("");
    setDetailsObj(obj);
  };
  useEffect(() => {
    const selectedEquipmentMenu = initialData
      ?.map((e) => e.active && e.key)
      ?.filter((item) => item);

    setEquipmentMenu({
      equipmentMenu: selectedEquipmentMenu,
    });
  }, [initialData]);

  useEffect(() => {
    if (detailsObj?.freepodreasoning === "Wifi Satisfaction Guarantee") {
      setDetailsObj({
        ...detailsObj,
        freepodreasoningcomments: "",
      });
    }
  }, [detailsObj?.freepodreasoning]);

  useEffect(() => {
    if (detailsObj?.stbquantity !== "") {
      setDetailsObj({
        ...detailsObj,
      });
    }
  }, [detailsObj?.stbquantity]);

  const ackUpdate = (ackFlag: any) => {
    if (ackFlag !== "") {
      setAcknowledgment(ackFlag);
      setDetailsObj({
        ...detailsObj,
        acknowledgment: ackFlag === true ? "Yes" : "No",
      });
    }
  };

  useEffect(() => {
    if (detailsObj?.podtype !== "") {
      setDetailsObj({
        ...detailsObj,
      });
    }
  }, [detailsObj?.podtype]);

  useEffect(() => {
    if (detailsObj?.freepodquantity !== "") {
      setDetailsObj({
        ...detailsObj,
      });
    }
  }, [detailsObj?.freepodquantity]);

  useEffect(() => {
    if (detailsObj?.paidpodquantity !== "") {
      setDetailsObj({
        ...detailsObj,
      });
    }
  }, [detailsObj?.paidpodquantity]);

  // const l = [];
  //           l.push(initialData.find((opt:any) => opt.active)?.label )   // initialData[index].active = checked;

  //              console.log("equipment list", l)

  //let l: string[] = [];
  const F1 = initialData
    .filter((o: any) => o.active)
    .map((opt: any) => {
      // l.push(opt.label);
      return opt.label;
    });
  //   F1.map((opt:any)=>
  //     l.push(opt.label)
  // );

  const checkFlags = () => {
    if (
      detailsObj?.techName !== "" &&
      detailsObj?.HouseNo !== "" &&
      detailsObj?.StreetName !== "" &&
      detailsObj?.City !== ""
    ) {
      return true;
    } else return false;
  };

  useEffect(() => {
    if (checkFlags()) {
      setDetails({
        ...detailsObj,
      });
    } else {
      setDetails(undefined);
    }
  }, [detailsObj]);

  // useEffect(() => {
  //   if (checkFlags()) {
  //     setDetails({
  //       ...detailsObj,
  //     });
  //   } else {
  //     setDetails(undefined);
  //   }
  //   console.log("HHID", detailsObj?.hhid)
  // }, [detailsObj]);

  return (
    <>
      <FirstHalf
        formSubmissionNo={formSubmissionNo}
        detailsObj={detailsObj}
        setDetailsObj={setDetailsObj}
        initialData={initialData}
        setInitialData={setInitialData}
        stbTextInputArray={stbTextInputArray}
        setStbTextInputArray={setStbTextInputArray}
        paidPodTextInputArray={paidPodInputArray}
        setPaidPodTextInputArray={setPaidPodTextInputArray}
        freePodTextInputArray={freePodTextInputArray}
        setFreePodTextInputArray={setFreePodTextInputArray}
        acknowledgment={acknowledgment}
        ackUpdate={ackUpdate}
      />
      {/* <Divider style={{ margin: "30px 0px 30px 0px" }} /> */}
      <SecondHalf detailsObj={detailsObj} setDetailsObj={setDetailsObj} />
    </>
  );
};

export default ASPSDetails;
