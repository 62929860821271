import Checkbox from "@material-ui/core/Checkbox";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { FaCheckCircle, FaCheckSquare } from "react-icons/fa";
import { IoAlertCircle } from "react-icons/io5";
import { ImCheckboxUnchecked } from "react-icons/im";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ErrorIcon from "../../../resources/images/icons/ErrorIcon.svg";
import CertificationClient from "../../../network/httpclient/certification/CertificationClient";
import ViewWifiCertificationPage from "../../../screens/certification-page/ViewWifiCertificationPage";
import FullScreenPopUp from "../../reusable/FullScreenPopUp";
import TestedIcon from "../../../resources/images/icons/tested.svg";
import TestedGrayIcon from "../../../resources/images/icons/tested-gray.svg";
import { isNull } from "../../reusable/Util";
import packageJson from "../../../../package.json";
import workOrderFlag from "../../certification/CertificationProcess";
import { GetTechInfo } from "../../../get-api/GetResponse";

const WifiCertification = (props: any) => {
  const { currentStep, handleCancel, handlePrev, handleNext, workOrderFlag } =
    props;
  const techData = GetTechInfo()?.data;
  const { certiData } = useSelector(
    (state: any) => ({
      certiData: state.AccountState.Certification.ActiveCertiInfo.certiInfo,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const [summaryFlag, setSummaryFlag] = useState(false);
  const [notApplicable, setNotApplicable] = useState(false);
  const [enableRefresh, setEnableRefresh] = useState(true);
  const [openWifiCerti, setOpenWifiCerti] = useState(false);
  const [totalLocations, setTotalLocations] = useState(0);
  const [passLocation, setPassLocation] = useState(0);
  const [failLocation, setFailLocation] = useState(0);
  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSummaryFlag(!event.target.checked);
    setEnableRefresh(!event.target.checked);
    setNotApplicable(event.target.checked);
  };
  useEffect(() => {
    if (certiData !== undefined && certiData?.hasOwnProperty("id")) {
      dispatch(CertificationClient.getCerti(certiData.id));
    }
  }, []);
  const handleRefresh = () => {
    if (certiData !== undefined && certiData?.hasOwnProperty("id")) {
      dispatch(CertificationClient.getCerti(certiData.id));
    }
  };
  const OnSaveWifiCerti = () => {
    if (
      certiData !== undefined &&
      certiData?.hasOwnProperty("data") &&
      notApplicable === true
    ) {
      let newCompletedSteps = certiData?.data?.workflow?.completedSteps;
      if (newCompletedSteps?.indexOf(3) === -1) newCompletedSteps.push(3);
      let newActiveCerti = {
        workflow: {
          status: "INPROGRESS",
          completedSteps: newCompletedSteps,
        },
        technician: {
          lanId: isNull(techData?.lanId),
          techId: isNull(techData?.techId),
          companyNumber: isNull(techData?.companyId),
          contactNumber: isNull(techData?.MobilePhone),
        },
        wifiCertificate: {
          isApplicable: false,
          uiClient: {
            type: "web",
            versionName: packageJson?.version,
          },
          status: "Skipped",
        },
      };
      if (certiData?.hasOwnProperty("id")) {
        const base64EncObj = { data: btoa(JSON.stringify(newActiveCerti)) };
        dispatch(CertificationClient.updateCerti(certiData.id, base64EncObj));
      }
    }
    handleNext(3);
  };
  useEffect(() => {
    if (
      certiData !== undefined &&
      certiData?.hasOwnProperty("data") &&
      certiData?.data?.hasOwnProperty("wifiCertificate") &&
      certiData?.data?.wifiCertificate?.hasOwnProperty("summary")
    ) {
      setTotalLocations(certiData?.data?.wifiCertificate?.summary?.total);
      setPassLocation(certiData?.data?.wifiCertificate?.summary?.passed);
      setFailLocation(certiData?.data?.wifiCertificate?.summary?.failed);
      setSummaryFlag(true);
    }
    if (
      certiData !== undefined &&
      certiData?.data?.wifiCertificate?.hasOwnProperty("isApplicable")
    ) {
      setEnableRefresh(certiData?.data?.wifiCertificate?.isApplicable);
      setSummaryFlag(certiData?.data?.wifiCertificate?.isApplicable);
    }
  }, [certiData]);

  return (
    <div className="certiStepCont">
      <div className="hsiCertiStep">
        <h4>WiFi Certification</h4>
        <p>
          Please use an Android mobile phone to complete WiFI certification. Hit{" "}
          <i>refresh data</i> below, to fetch the WiFi results submitted on
          mobile*
        </p>
        <p className="wifiCertiWarnText">
          *Once you save the certification, a summary of the result will be
          displayed here. You can still choose to run a new test if you are not
          satisfied with the results.*
        </p>
        <div className="refreshBtnCont">
          {enableRefresh ? (
            <button className="certiRefreshBtn" onClick={() => handleRefresh()}>
              Refresh Data
            </button>
          ) : (
            <button disabled className="certiRefreshBtn">
              Refresh Data
            </button>
          )}
        </div>
        <div className={summaryFlag ? "activeCertiCont" : "hsiCertiSummary"}>
          <label>WiFi Certification Summary</label>
          {summaryFlag ? (
            <>
              <div className="activeCertiResults">
                {certiData?.data?.wifiCertificate?.summary ? (
                  <>
                    <span>
                      <h4>
                        {totalLocations < 10
                          ? "0" + totalLocations
                          : totalLocations}
                      </h4>{" "}
                      Locations <img src={TestedIcon} />
                    </span>
                    <span>
                      <h4>
                        {passLocation < 10 ? "0" + passLocation : passLocation}
                      </h4>{" "}
                      Locations
                      <FaCheckCircle color="#28A745" />
                    </span>
                    <span>
                      <h4>
                        {failLocation < 10 ? "0" + failLocation : failLocation}
                      </h4>{" "}
                      Locations
                      <img src={ErrorIcon} />
                    </span>
                  </>
                ) : (
                  <>No Data Available</>
                )}
              </div>
              {certiData?.data?.wifiCertificate?.summary ? (
                <i onClick={() => setOpenWifiCerti(true)}>View Certification</i>
              ) : (
                <></>
              )}
            </>
          ) : (
            <p>
              <span>
                -- Locations
                <img src={TestedGrayIcon} />
              </span>
              <span>
                -- Locations
                <FaCheckCircle />
              </span>
              <span>
                -- Locations
                <IoAlertCircle />
              </span>
            </p>
          )}
        </div>
        {workOrderFlag && (
          <div className="wifiCertiInputCont">
            <FormControlLabel
              className="wifiCertiCheck"
              disabled={certiData?.data?.testType === "DNG"}
              control={
                <Checkbox
                  checkedIcon={<FaCheckSquare color="#000000bf" />}
                  icon={<ImCheckboxUnchecked />}
                  name="wifiCertiSkip"
                  checked={!enableRefresh}
                  onChange={handleChecked}
                />
              }
              label="WiFi Certification not applicable for this account"
            />
          </div>
        )}
      </div>
      <div className="certiStepBtnCont">
        <button className="certiCancelBtn" onClick={() => handleCancel()}>
          Cancel
        </button>
        <button
          className="certiPrevBtn"
          onClick={() => handlePrev()}
          disabled={currentStep == 1 ? true : false}
        >
          <FaArrowLeft />
          <span>Previous</span>
        </button>
        <button className="certiNextBtn" onClick={() => OnSaveWifiCerti()}>
          <span>Next</span> <FaArrowRight />
        </button>
      </div>
      <FullScreenPopUp
        open={openWifiCerti}
        handleClose={() => {
          setOpenWifiCerti(false);
        }}
        childComponent={<ViewWifiCertificationPage certiData={certiData} />}
        pageName="WiFi Certification Details"
      />
    </div>
  );
};

export default WifiCertification;
