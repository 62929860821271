import {
  isNull,
  getCheckValue,
  isEmptyObject,
  formatCamelCase,
} from "../components/reusable/Util";

export const getAllFailedAttributes = (certiDevicesData: any) => {
  let allFailedAttributes: any[] = [];
  certiDevicesData?.map((device: any) => {
    let item = null;
    let failedAttributes: { name: any; value: any; label: any }[] = [];
    let marginalAttributes: { name: any; value: any; label: any }[] = [];
    let diagResult = device?.diagResult;
    switch (device?.deviceCategory) {
      case "CM":
        failedAttributes = getCmFailedAttributes(diagResult?.docsis);
        marginalAttributes = getCmMarginalPassAttributes(diagResult?.docsis);
        break;
      case "IPTV":
        const getWifiApi = diagResult?.wifi;
        const getEthernetApi = diagResult?.ethernet;
        failedAttributes = getIPTVFailedAttributes(getEthernetApi, getWifiApi);
        break;
      case "EMTA":
        const getIHPDocsisApi = diagResult?.data?.docsis;
        failedAttributes = getRHPFailedAttributes(getIHPDocsisApi);
        break;
      case "ONT":
        const getSummaryApi = diagResult?.summary;
        failedAttributes = getONTFailedAttributes(getSummaryApi);
        break;
      case "ODU":
        const getODUWirelessInfoApi = diagResult?.wirelessInfo;
        failedAttributes = getODUFailedAttributes(getODUWirelessInfoApi);
        break;
      case "STB":
      case "DESTB":
      case "GSTB":
      case "HSTB":
      case "IPSTB":
        const getSumApi = diagResult?.data?.summary;
        const getDocsApi = diagResult?.data?.docsis;
        const isSummaryFail =
          getSumApi?.overallStatus?.toLowerCase() === "fail";
        const isDocsisFail =
          getDocsApi?.overallStatus?.toLowerCase() === "fail";
        if (isSummaryFail && isDocsisFail) {
          failedAttributes = getSTBFailedAttributes(
            getSumApi,
            getDocsApi,
            "both"
          );
        } else if (isDocsisFail) {
          failedAttributes = getSTBFailedAttributes(
            getSumApi,
            getDocsApi,
            "stbDocsisInfo"
          );
        } else if (isSummaryFail) {
          failedAttributes = getSTBFailedAttributes(
            getSumApi,
            getDocsApi,
            "stbSummaryInfo"
          );
        }
        break;
      case "ASTB":
        const getSummApi = diagResult?.summary;
        failedAttributes = getASTBSummaryFailedAttributes(getSummApi);
        break;
      default:
        break;
    }
    if (device?.status?.toLowerCase() === "fail") {
      item = {
        device: {
          deviceCategory: device?.deviceCategory,
          macAddress: device?.macAddress,
          serialNumber: device?.serialNumber,
          deviceStatus: device?.status,
        },
        failedAttributes: failedAttributes,
        marginalPassAttributes:
          device?.deviceCategory === "CM" ? marginalAttributes : undefined,
      };
      allFailedAttributes?.push(item);
    }
  });
  return allFailedAttributes;
};

export const getCmFailedAttributes = (
  cmDocsisInfo: any,
  failedAttributes?: any
) => {
  const arr: { name: any; value: any; label: any }[] = [];
  ///////////////////////////////// downstream 3.0 //////////////////////
  if (
    cmDocsisInfo &&
    cmDocsisInfo?.hasOwnProperty("docsis30") &&
    cmDocsisInfo.docsis30?.hasOwnProperty("downstream")
  ) {
    const downstreamChannel = cmDocsisInfo.docsis30?.downstream;

    ///////////////////////////////// downstream 3.0 channels///////////////////////
    const downStream3_channels_0Array: any = [];

    if (
      downstreamChannel?.hasOwnProperty("channels") &&
      downstreamChannel?.channels?.length > 0
    ) {
      downstreamChannel?.channels?.map((checkData: any, index: any) => {
        if (
          checkData?.hasOwnProperty("frequency") &&
          checkData?.hasOwnProperty("snr") &&
          checkData?.hasOwnProperty("rx_power") &&
          checkData?.hasOwnProperty("status")
        ) {
          if (
            checkData?.snr?.hasOwnProperty("status") &&
            checkData?.rx_power?.hasOwnProperty("status") &&
            checkData?.status?.hasOwnProperty("status")
          ) {
            if (
              checkData?.snr?.status?.toLowerCase() === "fail" ||
              checkData?.rx_power?.status?.toLowerCase() === "fail" ||
              checkData?.status?.status?.toLowerCase() === "fail"
            ) {
              downStream3_channels_0Array.push({
                frequency: isNull(checkData?.frequency),
                rx_power: isNull(
                  getCheckValue(isEmptyObject(checkData?.rx_power), false, true)
                ),
                snr: isNull(
                  getCheckValue(isEmptyObject(checkData?.snr), false, true)
                ),
                status: isNull(
                  getCheckValue(isEmptyObject(checkData?.status), false, true)
                ),
              });
            }
          }
        }
      });
    }
    if (downStream3_channels_0Array?.length > 0) {
      arr.push({
        name: "DS_DOCSIS3.0_CHANNELS",
        value: downStream3_channels_0Array,
        label: ["frequency", "rx_power", "snr", "status"],
      });
    }
  }
  ///////////////////////////////// downstream 3.1 channels///////////////////////
  if (
    cmDocsisInfo &&
    cmDocsisInfo?.hasOwnProperty("ofdm") &&
    cmDocsisInfo?.ofdm?.hasOwnProperty("downstreamChannels") &&
    cmDocsisInfo?.ofdm?.downstreamChannels?.length > 0
  ) {
    cmDocsisInfo?.ofdm?.downstreamChannels?.map((cb: any, index: any) => {
      const downStream3_fdm_channels_0Array: any = [];
      const downStream_ofdm_channels_Array: any = [];
      cb?.channelBands?.map((val: any, index: any) => {
        if (val?.centerFreq && val?.rxPower?.hasOwnProperty("status")) {
          if (val?.rxPower?.status?.toLowerCase() === "fail") {
            downStream3_fdm_channels_0Array.push({
              centerFreq: isNull(val?.centerFreq),
              rxPower: isNull(
                getCheckValue(isEmptyObject(val?.rxPower), false, true)
              ),
            });
          }
        }
      });
      if (downStream3_fdm_channels_0Array?.length > 0) {
        arr.push({
          name: `DS_OFDM${index + 1}_CHANNELS`,
          value: downStream3_fdm_channels_0Array,
          label: ["centerFreq", "rxPower"],
        });
      }
    });
    cmDocsisInfo?.ofdm?.downstreamChannels?.map((cb: any, index: any) => {
      const downStream_ofdm_channels_Array: any = [];
      if (
        cb?.hasOwnProperty("mer") &&
        cb?.mer?.hasOwnProperty("thrVal") &&
        cb?.mer?.thrVal?.hasOwnProperty("status") &&
        cb?.mer?.thrVal?.status?.toLowerCase() === "fail"
      ) {
        downStream_ofdm_channels_Array.push({
          name: "MER 2PCT",
          value: isNull(
            getCheckValue(
              isEmptyObject(cb?.mer?.thrVal),
              false,
              true
            )
          ),
        });
      }
      if (
        cb?.hasOwnProperty("status") &&
        cb?.status?.hasOwnProperty("value") &&
        cb?.status?.value?.toLowerCase() !== "up"
      ) {
        downStream_ofdm_channels_Array.push({
          name: "Operational Status",
          value: isNull(
            getCheckValue(
              isEmptyObject(cb?.status),
              false,
              true
            )
          ),
        });
      }
      if (downStream_ofdm_channels_Array?.length > 0) {
        arr.push({
          name: `DS_OFDM${index + 1}_SUMMARY`,
          value: downStream_ofdm_channels_Array,
          label: ["name", "value"],
        });
      }
    });
  }
  ///////////////////////////////// upstream 3.0 ///////////////////////
  if (
    cmDocsisInfo &&
    cmDocsisInfo?.hasOwnProperty("docsis30") &&
    cmDocsisInfo.docsis30?.hasOwnProperty("upstream")
  ) {
    const upstreamChannel = cmDocsisInfo?.docsis30?.upstream;
    if (upstreamChannel) {
      ///////////////////////////////// upstream 3.0 channels///////////////////////
      const upStream3_channels_0Array: any = [];

      if (
        upstreamChannel?.hasOwnProperty("channels") &&
        upstreamChannel?.channels?.length > 0
      ) {
        upstreamChannel?.channels?.map((checkData: any, index: any) => {
          if (
            checkData?.hasOwnProperty("frequency") &&
            checkData?.hasOwnProperty("snr") &&
            checkData?.hasOwnProperty("txPower") &&
            checkData?.hasOwnProperty("status")
          ) {
            if (
              checkData?.snr?.hasOwnProperty("status") &&
              checkData?.txPower?.hasOwnProperty("status") &&
              checkData?.status?.hasOwnProperty("status")
            ) {
              if (
                checkData?.snr?.status?.toLowerCase() === "fail" ||
                checkData?.txPower?.status?.toLowerCase() === "fail" ||
                checkData?.status?.status?.toLowerCase() === "fail"
              ) {
                upStream3_channels_0Array.push({
                  frequency: isNull(checkData?.frequency),
                  txPower: isNull(
                    getCheckValue(
                      isEmptyObject(checkData?.txPower),
                      false,
                      true
                    )
                  ),
                  snr: isNull(
                    getCheckValue(isEmptyObject(checkData?.snr), false, true)
                  ),
                  status: isNull(
                    getCheckValue(isEmptyObject(checkData?.status), false, true)
                  ),
                });
              }
            }
          }
        });
      }
      if (upStream3_channels_0Array?.length > 0) {
        arr.push({
          name: "US_DOCSIS3.0_CHANNELS",
          value: upStream3_channels_0Array,
          label: ["frequency", "txPower", "snr", "status"],
        });
      }
    }
  }
  //////////////////////////// Upstream OFDMA //////////////////////////////////
  let ofdmUpStreamChannels: any = [];
  const upStream3_fdma_channels_0Array: any = [];
  if (cmDocsisInfo) {
    if (
      cmDocsisInfo?.hasOwnProperty("ofdm") &&
      cmDocsisInfo?.ofdm?.hasOwnProperty("upstream")
    ) {
      cmDocsisInfo?.ofdm?.upstream?.channels?.map((usC: any) => {
        ofdmUpStreamChannels?.push(usC);
      });
    }

    if (
      cmDocsisInfo?.hasOwnProperty("docsisVersion") &&
      cmDocsisInfo.docsisVersion === "DOCSIS31" &&
      ofdmUpStreamChannels?.length !== 0
    ) {
      ofdmUpStreamChannels?.map((ofdmUpChannel: any, index: number) => {
        if (
          ofdmUpChannel?.hasOwnProperty("mer") &&
          ofdmUpChannel?.mer?.hasOwnProperty("thrVal") &&
          ofdmUpChannel?.mer?.thrVal?.hasOwnProperty("status") &&
          ofdmUpChannel?.mer?.thrVal?.status?.toLowerCase() === "fail"
        ) {
          upStream3_fdma_channels_0Array.push({
            name: "MER 2PCT",
            value: isNull(
              getCheckValue(
                isEmptyObject(ofdmUpChannel?.mer?.thrVal),
                false,
                true
              )
            ),
          });
        }
        if (
          ofdmUpChannel?.hasOwnProperty("status") &&
          ofdmUpChannel?.status?.hasOwnProperty("value") &&
          ofdmUpChannel?.status?.value?.toLowerCase() !== "up"
        ) {
          upStream3_fdma_channels_0Array.push({
            name: "Operational Status",
            value: isNull(
              getCheckValue(
                isEmptyObject(ofdmUpChannel?.status),
                false,
                true
              )
            ),
          });
        }
        if (
          ofdmUpChannel?.hasOwnProperty("txPower") &&
          ofdmUpChannel?.txPower?.hasOwnProperty("status") &&
          ofdmUpChannel?.txPower?.status?.toLowerCase() === "fail"
        ) {
          upStream3_fdma_channels_0Array.push({
            name: "TX POWER",
            value: isNull(ofdmUpChannel?.txPower?.value),
          });
        }
        if (upStream3_fdma_channels_0Array?.length > 0) {
          arr.push({
            name: `US_OFDMA${index + 1}_SUMMARY`,
            value: upStream3_fdma_channels_0Array,
            label: ["name", "value"],
          });
        }
      });
    }
    if (
      failedAttributes !== undefined &&
      Object.keys(failedAttributes).length > 0
    ) {
      Object.values(failedAttributes)?.map((v: any, index: number) => {
        if (v?.name === "UPSTREAM_SCQAM_CHANNELS") {
          arr.push({
            name: "UPSTREAM_SCQAM_CHANNELS",
            value: v.value,
            label: ["Uncorrectables"],
          });
        } else {
          arr.push({
            name: "DOWNSTREAM_SCQAM_CHANNELS",
            value: v.value,
            label: ["Uncorrectables"],
          });
        }
      });
    }
  }
  return arr;
};

export const getCmMarginalPassAttributes = (cmDocsisInfo: any) => {
  const arr: { name: any; value: any; label: any }[] = [];
  ///////////////////////////////// downstream 3.0 //////////////////////
  if (
    cmDocsisInfo &&
    cmDocsisInfo?.hasOwnProperty("docsis30") &&
    cmDocsisInfo.docsis30?.hasOwnProperty("downstream")
  ) {
    const downstreamChannel = cmDocsisInfo.docsis30?.downstream;

    ///////////////////////////////// downstream 3.0 channels///////////////////////
    const downStream3_channels_0Array: any = [];

    if (
      downstreamChannel?.hasOwnProperty("channels") &&
      downstreamChannel?.channels?.length > 0
    ) {
      downstreamChannel?.channels?.map((checkData: any, index: any) => {
        if (checkData?.hasOwnProperty("rx_power")) {
          if (
            checkData.snr?.hasOwnProperty("status") &&
            checkData.rx_power?.hasOwnProperty("status")
          ) {
            if (
              checkData?.rx_power?.status?.toLowerCase() === "marginal pass" //this has been changed
            ) {
              downStream3_channels_0Array.push({
                frequency: isNull(checkData?.frequency),
                rx_power: isNull(
                  getCheckValue(isEmptyObject(checkData?.rx_power), false, true)
                ),
              });
            }
          }
        }
      });
    }
    if (downStream3_channels_0Array?.length > 0) {
      arr.push({
        name: "DS_DOCSIS3.0_CHANNELS",
        value: downStream3_channels_0Array,
        label: ["frequency", "rx_power"],
      });
    }
  }
  ///////////////////////////////// downstream 3.1 channels///////////////////////
  if (
    cmDocsisInfo &&
    cmDocsisInfo?.hasOwnProperty("ofdm") &&
    cmDocsisInfo?.ofdm?.hasOwnProperty("downstreamChannels") &&
    cmDocsisInfo?.ofdm?.downstreamChannels?.length > 0
  ) {
    cmDocsisInfo?.ofdm?.downstreamChannels?.map((cb: any, index: any) => {
      const downStream3_fdm_channels_0Array: any = [];
      cb?.channelBands?.map((val: any, index: any) => {
        if (val?.centerFreq && val?.rxPower?.hasOwnProperty("status")) {
          if (val?.rxPower?.status?.toLowerCase() === "marginal pass") {
            downStream3_fdm_channels_0Array.push({
              centerFreq: isNull(val?.centerFreq),
              rxPower: isNull(
                getCheckValue(isEmptyObject(val?.rxPower), false, true)
              ),
            });
          }
        }
      });
      if (downStream3_fdm_channels_0Array?.length > 0) {
        arr.push({
          name: `DS_OFDM${index + 1}_CHANNELS`,
          value: downStream3_fdm_channels_0Array,
          label: ["centerFreq", "rxPower"],
        });
      }
    });
    cmDocsisInfo?.ofdm?.downstreamChannels?.map((cb: any, index: any) => {
      const downStream_ofdm_channels_Array: any = [];
      if (
        cb?.hasOwnProperty("mer") &&
        cb?.mer?.hasOwnProperty("thrVal") &&
        cb?.mer?.thrVal?.hasOwnProperty("status") &&
        cb?.mer?.thrVal?.status?.toLowerCase() === "marginal pass"
      ) {
        downStream_ofdm_channels_Array.push({
          name: "MER 2PCT",
          value: isNull(
            getCheckValue(
              isEmptyObject(cb?.mer?.thrVal),
              false,
              true
            )
          ),
        });
      }

      if (downStream_ofdm_channels_Array?.length > 0) {
        arr.push({
          name: `DS_OFDM${index + 1}_SUMMARY`,
          value: downStream_ofdm_channels_Array,
          label: ["name", "value"],
        });
      }
    });
  }

  ///////////////////////////////// upstream 3.0 ///////////////////////
  if (
    cmDocsisInfo &&
    cmDocsisInfo?.hasOwnProperty("docsis30") &&
    cmDocsisInfo.docsis30?.hasOwnProperty("upstream")
  ) {
    const upstreamChannel = cmDocsisInfo?.docsis30?.upstream;
    if (upstreamChannel) {
      ///////////////////////////////// upstream 3.0 channels///////////////////////
      const upStream3_channels_0Array: any = [];

      if (
        upstreamChannel?.hasOwnProperty("channels") &&
        upstreamChannel?.channels?.length > 0
      ) {
        upstreamChannel?.channels?.map((checkData: any, index: any) => {
          if (
            checkData?.hasOwnProperty("frequency") &&
            checkData?.hasOwnProperty("txPower")
          ) {
            if (checkData?.txPower?.hasOwnProperty("status")) {
              if (
                checkData?.txPower?.status?.toLowerCase() === "marginal pass"
              ) {
                upStream3_channels_0Array.push({
                  frequency: isNull(checkData?.frequency),
                  txPower: isNull(
                    getCheckValue(
                      isEmptyObject(checkData?.txPower),
                      false,
                      true
                    )
                  ),
                });
              }
            }
          }
        });
      }
      if (upStream3_channels_0Array?.length > 0) {
        arr.push({
          name: "US_DOCSIS3.0_CHANNELS",
          value: upStream3_channels_0Array,
          label: ["frequency", "txPower"],
        });
      }
    }
  }
  //////////////////////////// Upstream OFDMA //////////////////////////////////
  let ofdmUpStreamChannels: any = [];
  const upStream3_fdma_channels_0Array: any = [];
  if (cmDocsisInfo) {
    if (
      cmDocsisInfo?.hasOwnProperty("ofdm") &&
      cmDocsisInfo?.ofdm?.hasOwnProperty("upstream")
    ) {
      cmDocsisInfo?.ofdm?.upstream?.channels?.map((usC: any) => {
        ofdmUpStreamChannels?.push(usC);
      });
    }

    if (
      cmDocsisInfo?.hasOwnProperty("docsisVersion") &&
      cmDocsisInfo.docsisVersion === "DOCSIS31" &&
      ofdmUpStreamChannels?.length !== 0
    ) {
      ofdmUpStreamChannels?.map((ofdmUpChannel: any, index: number) => {
        if (
          ofdmUpChannel?.hasOwnProperty("txPower") &&
          ofdmUpChannel?.txPower?.hasOwnProperty("status") &&
          ofdmUpChannel?.txPower?.status?.toLowerCase() === "marginal pass"
        ) {
          upStream3_fdma_channels_0Array.push({
            name: "TX POWER",
            value: isNull(ofdmUpChannel?.txPower?.value),
          });
        }
        if (
          ofdmUpChannel?.hasOwnProperty("mer") &&
          ofdmUpChannel?.mer?.hasOwnProperty("thrVal") &&
          ofdmUpChannel?.mer?.thrVal?.hasOwnProperty("status") &&
          ofdmUpChannel?.mer?.thrVal?.status?.toLowerCase() === "marginal pass"
        ) {
          upStream3_fdma_channels_0Array.push({
            name: "MER 2PCT",
            value: isNull(
              getCheckValue(
                isEmptyObject(ofdmUpChannel?.mer?.thrVal),
                false,
                true
              )
            ),
          });
        }
        if (upStream3_fdma_channels_0Array?.length > 0) {
          arr.push({
            name: `US_OFDMA${index + 1}_SUMMARY`,
            value: upStream3_fdma_channels_0Array,
            label: ["name", "value"],
          });
        }
      });
    }
    //     if (
    //       marginalPassAttributes !== undefined &&
    //       Object.keys(marginalPassAttributes).length > 0
    //     ) {
    //       Object.values(marginalPassAttributes)?.map((v: any, index: number) => {
    //         if (v?.name === labels.UPSTREAM_SCQAM_CHANNELS.toUpperCase()) {
    //           arr.push({
    //             name: labels.UPSTREAM_SCQAM_CHANNELS.toUpperCase(),
    //             value: v.value,
    //           });
    //         } else {
    //           arr.push({
    //             name: labels.DOWNSTREAM_SCQAM_CHANNELS.toUpperCase(),
    //             value: v.value,
    //           });
    //         }
    //       });
    //     }
    //
  }
  return arr;
};

const getRHPFailedAttributes = (rhpDocsisInfo: any) => {
  const arr: any = [];
  const upStreamArray: any = [];
  if (rhpDocsisInfo) {
    if (
      rhpDocsisInfo?.hasOwnProperty("upstreamChannels") &&
      rhpDocsisInfo?.upstreamChannels?.length !== 0
    ) {
      rhpDocsisInfo?.upstreamChannels?.map((val: any, index: any) => {
        if (
          val?.hasOwnProperty("tx_power") &&
          val.tx_power?.hasOwnProperty("status")
        ) {
          if (val.tx_power?.status === "fail") {
            upStreamArray.push({
              name: "CM TRANSMIT POWER",
              value: isNull(val?.tx_power?.value),
            });
          }
        }
        if (val?.hasOwnProperty("snr") && val.snr?.hasOwnProperty("status")) {
          if (val.snr?.status === "fail") {
            upStreamArray.push({
              name: "UPSTREAM SNR",
              value: isNull(val?.snr?.value),
            });
          }
        }
      });
    }
    if (
      rhpDocsisInfo?.hasOwnProperty("downstreamChannels") &&
      rhpDocsisInfo?.downstreamChannels?.length !== 0
    ) {
      rhpDocsisInfo?.downstreamChannels?.map((val: any, index: any) => {
        if (
          val?.hasOwnProperty("rx_power") &&
          val.rx_power?.hasOwnProperty("status")
        ) {
          if (val.rx_power.status === "fail") {
            upStreamArray.push({
              name: "CM RECEIVE POWER",
              value: isNull(val?.rx_power?.value),
            });
          }
        }
        if (val?.hasOwnProperty("snr") && val.snr?.hasOwnProperty("status")) {
          if (val.snr.status === "fail") {
            upStreamArray.push({
              name: "DOWNSTREAM SNR",
              value: isNull(val?.snr?.value),
            });
          }
        }
      });
    }
    if (upStreamArray?.length > 0) {
      arr.push({
        name: "RHP_DOCSIS",
        value: upStreamArray,
      });
    }
  }
  return arr;
};

const getONTFailedAttributes = (ontSummaryInfo: any) => {
  const arr: any = [];
  const data = ontSummaryInfo;
  if (data) {
    const summaryArray = [];
    const opticalTx = data?.opticalTx;
    const opticalRx = data?.opticalRx;
    const operStatus = data?.operStatus;
    const provStatus = data?.provStatus;
    if (opticalTx?.status == "Fail") {
      summaryArray.push({
        name: formatCamelCase("opticalTx"),
        value: isNull(opticalTx?.value),
      });
    }
    if (opticalRx?.status == "Fail") {
      summaryArray.push({
        name: formatCamelCase("opticalRx"),
        value: isNull(opticalRx?.value),
      });
    }
    if (operStatus?.status == "Fail") {
      summaryArray.push({
        name: formatCamelCase("operStatus"),
        value: isNull(operStatus?.value),
      });
    }
    if (provStatus?.status == "Fail") {
      summaryArray.push({
        name: formatCamelCase("provStatus"),
        value: isNull(provStatus?.value),
      });
    }
    if (summaryArray?.length > 0) {
      arr.push({
        name: "ONT_SUMMARY",
        value: summaryArray,
        label: ["name", "value"],
      });
    }
  }
  return arr;
};

const getSTBFailedAttributes = (
  stbSummaryInfo: any,
  stbDocsisInfo: any,
  type: any
) => {
  const arr: any = [];
  const data = stbDocsisInfo;
  const stbDocsisArray: any = [];
  if (
    data &&
    data !== undefined &&
    (type === "stbDocsisInfo" || type === "both")
  ) {
    const upstreamChannel = data?.upstreamChannels?.[0];
    const upstreamSNR = upstreamChannel?.snr;
    const upstreamTxPower = upstreamChannel?.tx_power;
    const downstreamChannel = data?.downstreamChannels?.[0];
    const downstreamSNR = downstreamChannel?.snr;
    const downstreamRxPower = downstreamChannel?.rx_power;

    if (upstreamTxPower?.status === "fail") {
      stbDocsisArray.push({
        name: "CM TRANSMIT POWER",
        value: isNull(upstreamTxPower?.value),
      });
    }

    if (upstreamSNR?.status == "Fail") {
      stbDocsisArray.push({
        name: "UPSTREAM SNR",
        value: isNull(upstreamSNR?.value),
      });
    }
    if (downstreamRxPower?.status == "Fail") {
      stbDocsisArray.push({
        name: "CM RECEIVE POWER",
        value: isNull(downstreamRxPower?.value),
      });
    }
    if (downstreamSNR?.status == "Fail") {
      stbDocsisArray.push({
        name: "DOWNSTREAM SNR",
        value: isNull(downstreamSNR?.value),
      });
    }
    if (stbDocsisArray?.length > 0) {
      arr.push({
        name: "STB_DOCSIS",
        value: stbDocsisArray,
        labels: ["name", "value"],
      });
    }
  }
  if (
    stbSummaryInfo &&
    stbSummaryInfo !== undefined &&
    (type === "stbSummaryInfo" || type === "both")
  ) {
    const stbDocsisSummaryArr: any = [];
    if (stbSummaryInfo && Object.keys(stbSummaryInfo).length > 0) {
      if (
        stbSummaryInfo?.hasOwnProperty("qamChannelStatus") &&
        stbSummaryInfo.qamChannelStatus?.hasOwnProperty("status")
      ) {
        if (stbSummaryInfo.qamChannelStatus.status === "fail") {
          stbDocsisSummaryArr.push({
            name: "QAM CHANNEL STATUS",
            value: isNull(stbSummaryInfo.qamChannelStatus?.value),
          });
        }
      }
      if (
        stbSummaryInfo?.hasOwnProperty("qamSnr") &&
        stbSummaryInfo.qamSnr?.hasOwnProperty("status")
      ) {
        if (stbSummaryInfo.qamSnr.status === "fail") {
          stbDocsisSummaryArr.push({
            name: "QAM SNR",
            value: isNull(stbSummaryInfo.qamSnr?.value),
          });
        }
      }
    }
    if (stbDocsisSummaryArr?.length > 0) {
      arr.push({
        name: "STB_SUMMARY",
        value: stbDocsisSummaryArr,
        labels: ["name", "value"],
      });
    }
  }
  return arr;
};

const getODUFailedAttributes = (oduWirelessInfo: any) => {
  const arr: any = [];
  const data = oduWirelessInfo;

  if (oduWirelessInfo && oduWirelessInfo?.hasOwnProperty("cells")) {
    data?.cells?.map((element: any, key: any) => {
      const oduWiresslessInfoArray = [];

      const band = element?.band;
      const rsrp = element?.rsrp;
      const sinr = element?.sinr;
      if (band?.status === "fail") {
        oduWiresslessInfoArray.push({
          name: "band".toUpperCase(),
          value: isNull(band?.value),
        });
      }
      if (rsrp?.status === "fail") {
        oduWiresslessInfoArray.push({
          name: "rsrp".toUpperCase(),
          value: isNull(rsrp?.value),
        });
      }
      if (sinr?.status === "fail") {
        oduWiresslessInfoArray.push({
          name: "sinr".toUpperCase(),
          value: isNull(sinr?.value),
        });
      }
      if (oduWiresslessInfoArray?.length > 0) {
        arr.push({
          name: "ODU_WIRELESS_INFO_" + (key + 1),
          value: oduWiresslessInfoArray,
          labels: ["name", "value"],
        });
      }
    });
  }
  return arr;
};

const getIPTVFailedAttributes = (ethernetData: any, wifiData: any) => {
  const arr: any = [];
  if (ethernetData !== "" && ethernetData !== undefined) {
    const ethernetDataArray = [];
    if (ethernetData?.ethernetStatus?.status === "fail") {
      ethernetDataArray.push({
        name: formatCamelCase("ethernetStatus"),
        value: isNull(ethernetData?.ethernetStatus?.value),
      });
    }
    if (ethernetDataArray?.length > 0) {
      arr.push({
        name: "ETHERNET_DATA",
        value: ethernetDataArray,
        labels: ["name", "value"],
      });
    }
  }
  if (wifiData !== "" && wifiData !== undefined) {
    const WifiDataArray = [];
    if (wifiData?.signalStrength?.status == "Fail") {
      WifiDataArray.push({
        name: formatCamelCase("signalStrength"),
        value: isNull(wifiData?.signalStrength?.value),
      });
    }
    if (WifiDataArray?.length > 0) {
      arr.push({
        name: "WIFI_DATA",
        value: WifiDataArray,
        labels: ["name", "value"],
      });
    }
  }
  return arr;
};

function getASTBSummaryFailedAttributes(api: any) {
  const arr: any = [];
  if (
    !api?.isLoading &&
    api?.data != "" &&
    api?.data != undefined &&
    api?.data?.overallStatus == "Fail"
  ) {
    const astbSummaryArray = [];
    const powerLevel = api?.data?.powerLevel;
    const fatalErrorCount = api?.data?.fatalErrorCount;
    const oobAcgLevel = api?.data?.oobAcgLevel;
    const oobSnr = api?.data?.oobSnr;
    const carrierLock = api?.data?.carrierLock;
    const inbandSnr = api?.data?.inbandSnr;

    if (powerLevel?.status == "Fail") {
      astbSummaryArray.push({
        name: "Power Level",
        value: powerLevel?.value,
      });
    }
    if (fatalErrorCount?.status == "Fail") {
      astbSummaryArray.push({
        name: "Fatal Error Count",
        value: fatalErrorCount?.value,
      });
    }
    if (oobAcgLevel?.status == "Fail") {
      astbSummaryArray.push({
        name: "Oob Acg Level",
        value: oobAcgLevel?.value,
      });
    }
    if (oobSnr?.status == "Fail") {
      astbSummaryArray.push({
        name: "Oob Snr",
        value: oobSnr?.value,
      });
    }
    if (carrierLock?.status == "Fail") {
      astbSummaryArray.push({
        name: "Carrier Lock",
        value: carrierLock?.value,
      });
    }
    if (inbandSnr?.status == "Fail") {
      astbSummaryArray.push({
        name: "Inband Snr",
        value: inbandSnr?.value,
      });
    }

    if (astbSummaryArray?.length > 0) {
      arr.push({
        name: "ASTB_SUMMARY",
        value: astbSummaryArray,
        labels: ["Name", "Value"],
      });
    }
  }
  return arr;
}

function GetScqamFailedAttributes(firstCWInfo: any, currCWInfo: any) {
  const FREQ_CONV_NUM = 1000000;
  //let firstResult: any;
  let data: any;
  //const dispatch = useDispatch();
  const failedAttributearr = [];
  // Commented out the labels due to hook order problem
  // const labels = languageLabels();
  /////////////////////////////////UPSTREAM SC-QAM CHANNELS//////////////////////
  const CWUpstream_channels_Array: any = []; //curr-first
  if (firstCWInfo !== undefined) {
    [].concat.apply(
      [],
      firstCWInfo?.upstreamChannels?.map((e: any, i: number) => {
        currCWInfo?.upstreamChannels?.map((ch: any) => {
          if (e?.frequency === ch?.frequency) {
            data = ch?.uncorrectables - e?.uncorrectables;
            if (data > 0) {
              CWUpstream_channels_Array.push({
                value: data,
                name: (e?.frequency / FREQ_CONV_NUM).toFixed(1),
              });
            }
          }
        });
      })
    );
  }
  if (CWUpstream_channels_Array?.length > 0) {
    failedAttributearr.push({
      name: "Upstream SC-QAM Channels".toUpperCase(),
      value: CWUpstream_channels_Array,
      labels: ["FREQ (MHz)", "Uncorrectables Delta"],
    });
  }
  const CWDownstream_channels_Array: any = [];
  if (firstCWInfo !== undefined) {
    [].concat.apply(
      [],
      firstCWInfo?.downstreamChannels?.map((e: any, i: number) => {
        currCWInfo?.downstreamChannels?.map((ch: any) => {
          if (e?.frequency === ch?.frequency) {
            data = ch?.uncorrectables - e?.uncorrectables;
            if (data > 0) {
              CWDownstream_channels_Array.push({
                value: data,
                name: (e?.frequency / FREQ_CONV_NUM).toFixed(0),
              });
            }
          }
        });
      })
    );
  }
  if (CWDownstream_channels_Array?.length > 0) {
    failedAttributearr.push({
      name: "Downstream SC-QAM Channels".toUpperCase(),
      value: CWDownstream_channels_Array,
      labels: ["FREQ (MHz)", "Uncorrectables Delta"],
    });
  }

  //dispatch(SetFailedCodewords(failedAttributearr));
  return failedAttributearr;
}
