import { RecoLegendState } from "../../../states/account/troubleshoot/RecoLegendState";
import {
  RECO_LEGEND_BEGIN,
  RECO_LEGEND_SUCCESS,
  RECO_LEGEND_FAILURE,
  RecoLegendActionTypes,
} from "../../../types/account/troubleshoot/RecoLegendProfile";

const initialState: RecoLegendState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function RecoLegendReducer(
  state = initialState,
  action: RecoLegendActionTypes
): RecoLegendState {
  switch (action.type) {
    case RECO_LEGEND_BEGIN:
      return {
        isLoading: true,
      };
    case RECO_LEGEND_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case RECO_LEGEND_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}
