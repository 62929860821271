import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../middleware/apiActions";
import {
  MDU_RASTER_IMAGES,
  SYMBOLS_SERVICE_URL,
} from "../../../middleware/apiEndpoints";
import storeConst from "../../../storeConst";
import { decrApiCall, incrApiCall } from "../mapDetails";

const slice = createSlice({
  name: "mduRasterImage",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    getMduRasterImageBegin: (mduRasterImage) => {
      mduRasterImage.isLoading = true;
      mduRasterImage.status = "";
    },
    getMduRasterImageSuccess: (mduRasterImage, action) => {
      mduRasterImage.isLoading = false;
      mduRasterImage.data = action.payload;
      mduRasterImage.status = storeConst.OK;
    },
    getMduRasterImageFailure: (mduRasterImage, action) => {
      mduRasterImage.isLoading = false;
      mduRasterImage.error = action.payload;
      mduRasterImage.data = undefined;
      mduRasterImage.status = storeConst.ERROR;
    },
  },
});

const {
  getMduRasterImageBegin,
  getMduRasterImageSuccess,
  getMduRasterImageFailure,
} = slice.actions;
export default slice.reducer;

export const getMduRasterImage = (imageName: any) => (dispatch: any) => {
  dispatch(
    apiCallBegan({
      baseURL: SYMBOLS_SERVICE_URL,
      url: `${MDU_RASTER_IMAGES}/${imageName}`,
      method: "get",
      onStart: getMduRasterImageBegin.type,
      onStartDispatch: incrApiCall.type,
      onSuccess: getMduRasterImageSuccess.type,
      onSuccessDispatch: decrApiCall.type,
      onError: getMduRasterImageFailure.type,
      onErrorDispatch: decrApiCall.type,
    })
  );
};
