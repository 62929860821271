import { Divider } from "@material-ui/core";
import { MdClose } from "react-icons/md";
import TopologyLabels from "../../../../../constants/topologyLabels";
import { useState } from "react";
import CustomDropDown from "../../../../../../components/reusable/CustomDropDown";
import { FaSearch } from "react-icons/fa";
import InlineErrorMessage from "../../../../../../components/reusable/InlineErrorMessage";
import { isStringNumber } from "../../../../../util/utilFuncs";
import { useFiberUpstreamTraceState } from "../../../../../util/reduxFunctions/getTopologyState";
import { useDispatch } from "react-redux";
import { setFiberUsTraceOtdrDistanceUnit } from "../../../../../store/slices/mapItems/trace/fiber/fiberUpstreamTrace";
import appConst from "../../../../../constants/appConst";

interface Props {
  cardLabel: string;
  showPopup?: any;
  onHidePopup?: () => void;
  onDistanceSubmit?: any;
}
export default function OtdrPopup({
  cardLabel,
  showPopup,
  onHidePopup,
  onDistanceSubmit,
}: Props) {
  const labels = TopologyLabels();
  const dispatch = useDispatch();
  const { otdrDistanceUnit } = useFiberUpstreamTraceState();
  const distanceOptions = [labels.kms, labels?.meters?.toUpperCase()];

  const [distance, setDistance] = useState("");
  const [activeDistanceType, setActiveDistanceType] = useState(
    otdrDistanceUnit === appConst.m
      ? distanceOptions?.[1]
      : distanceOptions?.[0]
  );
  const [errorMsg, setErrorMsg] = useState("");

  const onDistanceUnitChange = (value: any) => {
    setActiveDistanceType(value);
    dispatch(
      setFiberUsTraceOtdrDistanceUnit(
        value === labels.kms ? appConst.km : appConst.m
      )
    );
  };

  const onSearch = () => {
    if (distance === "" || !isStringNumber(distance)) {
      setErrorMsg("Please enter valid distance");
    } else {
      setErrorMsg("");
      onDistanceSubmit(
        activeDistanceType === labels?.kms ? Number(distance) * 1000 : distance
      );
    }
  };

  const onKeyboardKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") onSearch();
  };

  return (
    <>
      {showPopup && (
        <section className="otdrCont">
          <div className="otdrDetailHead">
            <b>{cardLabel}</b>
            <div className="otdrActionCont">
              <CustomDropDown
                ddTitle={labels.distance}
                selectedOption={activeDistanceType}
                options={distanceOptions}
                searchFlag={false}
                checkboxFlag={false}
                setSelectedOption={onDistanceUnitChange}
              />
              <span
                className="otdrCloseBtn"
                onClick={() => (onHidePopup ? onHidePopup() : null)}
              >
                <MdClose />
              </span>
            </div>
          </div>
          <Divider />

          <div className="otdrBody">
            <div className="otdrSearchCont">
              <section>
                <input
                  contentEditable={true}
                  type="text"
                  title="Type distance"
                  value={distance}
                  placeholder="Type distance..."
                  onChange={(val: any) => {
                    setDistance(val.target.value);
                  }}
                  onKeyPress={(event: any) => onKeyboardKeyPress(event)}
                />
                <button onClick={() => onSearch()}>
                  <FaSearch />
                </button>
              </section>
              <InlineErrorMessage errorMessage={errorMsg} />
            </div>
          </div>
        </section>
      )}
    </>
  );
}
