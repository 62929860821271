import { createSlice } from "@reduxjs/toolkit";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "neighbourhood",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
    neighDataList: [] as Array<object>,
  },
  reducers: {
    NeighboursInfoBegin: (hood) => {
      hood.isLoading = true;
    },
    NeighboursInfoSuccess: (hood, action) => {
      hood.isLoading = false;
      hood.data = action.payload;
      hood.status = storeConst.OK;
    },
    NeighboursInfoFailure: (hood, action) => {
      hood.isLoading = false;
      hood.error = action.payload;
      hood.data = undefined;
      hood.status = storeConst.ERROR;
    },
    SaveNeighboursDevices: (hood, action) => {
      hood.neighDataList = action.payload;
    },
    SetNeighbourGeoCode: (hood, action) => {
      hood.neighDataList = hood?.neighDataList?.map((d: any) => {
        if (d.deviceKey === action.payload.deviceKey) {
          let geoResponse: any = action.payload.geoData;
          return {
            ...d,
            ...{ location: geoResponse?.results?.[0]?.geometry?.location },
          };
        } else return d;
      });
    },
    UpdateNeighboursDevices: (hood, action) => {
      hood.neighDataList = hood.neighDataList?.map((d: any) => {
        if (d.deviceKey === action.payload.deviceKey) {
          let tempCMData: any = d.cmData;
          let subDataType = action.payload.subDataKey;
          let tempData = tempCMData?.[subDataType];
          tempData = {
            ...tempData,
            ...{ data: action.payload.data, status: action.payload.status },
          };
          tempCMData = { ...tempCMData, ...{ [subDataType]: tempData } };
          return { ...d, ...{ cmData: tempCMData } };
        } else {
          return d;
        }
      });
    },
    IncrCallCounter: (hood, action) => {
      hood.neighDataList = hood.neighDataList.map((d: any) => {
        if (d.deviceKey === action.payload.deviceKey) {
          let tempCMData: any = d.cmData;
          tempCMData = {
            ...tempCMData,
            ...{
              [action.payload.subDataKey]: {
                isLoading: true,
                data: undefined,
                status: "",
              },
            },
          };
          return { ...d, ...{ cmData: tempCMData } };
        } else return d;
      });
    },
    DecCallCounter: (hood, action) => {
      hood.neighDataList = hood.neighDataList.map((d: any) => {
        if (d.deviceKey === action.payload.deviceKey) {
          let tempCMData: any = d.cmData;
          let subDataType = action.payload.subDataKey;
          let tempData = tempCMData?.[subDataType];
          tempData = { ...tempData, ...{ isLoading: false } };
          tempCMData = { ...tempCMData, ...{ [subDataType]: tempData } };
          return { ...d, ...{ cmData: tempCMData } };
        } else return d;
      });
    },
    ResetNeighboursDevices: (neighbourhood) => {
      neighbourhood.neighDataList = [];
    },
  },
});

export const {
  NeighboursInfoBegin,
  NeighboursInfoSuccess,
  NeighboursInfoFailure,
  SaveNeighboursDevices,
  SetNeighbourGeoCode,
  UpdateNeighboursDevices,
  IncrCallCounter,
  DecCallCounter,
  ResetNeighboursDevices,
} = slice.actions;
export default slice.reducer;
