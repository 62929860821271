import LoaderGif from "../../resources/images/loader.webp";
import ExceptionBox from "./ExceptionBox";
import { isDeviceOffline, isNull } from "./Util";

export default function DiagApiCallCont(props: any) {
  return (
    <div>
      {props.isLoading ? (
        <div className="diagAPICont">
          <img className="loaderImg" src={LoaderGif} />
        </div>
      ) : props.data != undefined && props.status === "OK" ? (
        props.children
      ) : props.data != undefined &&
        (props.status === "ERROR" || props.status?.toUpperCase()?.includes("FAIL")) ? (
        <div className="diagErrorCont">
          <ExceptionBox
            exBoxType="error"
            errSecName={props.sectionName}
            errCode={isNull(props.data?.errorCode)}
            ErrorMSG={isNull(props.data?.errorMessage)}
            errorDeviceSkip={
              !isDeviceOffline(props.data)
                ? "This device will be skipped for the certification due to a data issue."
                : "This device is failing due to it being offline."
            }
            srcSystem={
              props.data?.hasOwnProperty("sourceSystem")
                ? props.data?.sourceSystem
                : "Request"
            }
            primaryBtn={true}
            handleRefresh={props.handleRefresh}
          />
        </div>
      ) : null}
    </div>
  );
}
