import { InfoBox, Marker } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { BsArrowsMove } from "react-icons/bs";
import { useDispatch } from "react-redux";

import colors from "../../config/colors";
import { DrawerViewType } from "../../store/reduxTypes";
import { setDrawerView } from "../../store/slices/mapDrawer";
import { GetMapDrawerProps } from "../../util/reduxFunctions/getTopologyState";
import {
  getClusterMarkerDetails,
  markerToString,
  getClusterMarkerSortedData,
} from "../../util/utilFuncs";
import TopologySymbols from "./TopologySymbols";

interface Props {
  clusterData: any;
  lat: number;
  lng: number;
  showClusteredMarkers: any;
}

const ClusterCMMarker = ({
  clusterData,
  lat,
  lng,
  showClusteredMarkers,
}: Props) => {
  const dispatch = useDispatch();
  const clusterDetails = getClusterMarkerDetails(clusterData?.modems);
  const data = getClusterMarkerSortedData(clusterDetails);
  const [showInfoBox, setShowInfoBox] = useState(false);
  const { drawerView } = GetMapDrawerProps();

  //Work around to re-attach 'InfoBox' to 'Marker' on mount
  useEffect(() => {
    setShowInfoBox(true);
  }, []);

  return (
    <Marker
      position={{ lat, lng }}
      icon={{
        url: markerToString(
          <BsArrowsMove color={colors.greyLight} size={20} />
        ),
      }}
      draggable
      cursor="move"
      zIndex={4000}
    >
      {showInfoBox && (
        <InfoBox
          options={{
            closeBoxURL: "",
            disableAutoPan: true,
            boxStyle: {
              width: "auto",
            },
            pixelOffset: new google.maps.Size(
              Object?.keys(clusterDetails)?.length > 1 ? -35 : -21,
              -6
            ),
          }}
        >
          <div
            className="clusterMarker"
            onClick={() => {
              showClusteredMarkers(clusterData, data);
              if (drawerView != "standard")
                dispatch(setDrawerView("standard" as DrawerViewType));
            }}
          >
            {data?.map((d: any) => {
              return (
                <span key={d?.name} className="clusterCMType">
                  <TopologySymbols
                    name={d?.name}
                    color={d?.color}
                    size={d?.size}
                  />
                  <span>{d?.count}</span>
                </span>
              );
            })}
          </div>
        </InfoBox>
      )}
    </Marker>
  );
};

export default ClusterCMMarker;
