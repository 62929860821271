import React, { useEffect, useState } from "react";
import { Form, FormControl, Modal } from "react-bootstrap";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import { isValid } from "date-fns";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import draftToHtml from "draftjs-to-html";
import { IoClose } from "react-icons/io5";
import { ReactComponent as DragDrop } from "../../resources/images/icons/dragDrop.svg";
import NotiItemCard from "./NotiItemCard";
import { useDispatch } from "react-redux";
import NotificationClient from "../../network/httpclient/notification/NotificationClient";
import { randomString } from "../reusable/Util";
import AppLabels from "../../constants/App_Labels";
import CustomTextEditor from "../reusable/CustomTextEditor";

const CreateNotification = (props: any) => {
  const labels = AppLabels();
  const { showVal, headerText, handleClose, updateFlag, activeNoti } = props;
  const dispatch = useDispatch();
  const now = new Date();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [title, setTitle] = useState("");
  const [ntfBody, setNtfBody] = useState("");
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [notiItems, setNotiItems] = useState<any>([]);
  const [enableSubmit, setEnableSubmit] = useState(true);

  const onDragEnd = (result: any) => {
    //dropped outside the list
    if (!result.destination) {
      return;
    }

    const itemstmp: any[] = reorder(
      notiItems,
      result.source.index,
      result.destination.index
    );

    setNotiItems(itemstmp);
  };

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    height: 20,
    width: 20,
    left: "50%",
    top: "-15px",
    background: "transparent",
  });

  const reorder = (list: [], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  useEffect(() => {
    setNtfBody(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  }, [editorState]);

  useEffect(() => {
    if (updateFlag) {
      setTitle(activeNoti?.title);
      setNtfBody(activeNoti?.description);
      setNotiItems(activeNoti?.items);
      setSelectedFromDate(activeNoti?.startDate);
      setSelectedToDate(activeNoti?.endDate);
      const blocksFromHTML = convertFromHTML(activeNoti?.description);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(state));
    }
  }, [updateFlag]);

  const toTimeStamp = (date: any) => {
    var datum = Date.parse(date);
    if (datum > 0) {
      return datum;
    } else {
      return date;
    }
  };
  const tomorrow = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + 1
  );
  const [selectedFromDate, setSelectedFromDate] = useState<any>(now);
  const [selectedToDate, setSelectedToDate] = useState<any>(tomorrow);

  useEffect(() => {
    setErrorDisplay(false);
    setErrorMsg("");
    if (
      title &&
      isValid(selectedFromDate) &&
      isValid(selectedToDate) &&
      title !== ""
    ) {
      if (notiItems !== undefined && notiItems?.length > 0) {
        let enableFlag = false;
        notiItems?.map((n: any) => {
          if (n?.header === "") {
            enableFlag = true;
          }
        });
        if (enableFlag) {
          setEnableSubmit(true);
        } else {
          setEnableSubmit(false);
        }
      } else {
        setEnableSubmit(false);
      }
    } else {
      setEnableSubmit(true);
    }
  }, [title, selectedFromDate, selectedToDate, notiItems]);
  const onTitleSubmit = (e: any) => setTitle(e.target.value);
  const onDescSubmit = (e: any) => setNtfBody(e.target.value);

  const addNewItem = () => {
    const item = {
      id: randomString(10),
      header: "",
      desc: "",
      images: [],
    };
    let tempNotiItems = [...notiItems, ...[item]];
    setNotiItems(tempNotiItems);
  };

  const deleteItem = (key: number) => {
    let tempNotiItems: any = [];
    if (notiItems?.length !== 0 && key) {
      tempNotiItems = notiItems?.filter((n: any) => n?.id !== key);
    }
    setNotiItems(tempNotiItems);
  };

  const updateItem = (itemKey: string, itemDetails: any) => {
    let tempNotiItems: any = [];
    if (notiItems?.length !== 0 && itemKey && itemDetails) {
      tempNotiItems = notiItems?.map((item: any) => {
        if (item?.id === itemKey) {
          return { ...item, ...itemDetails };
        }
        return item;
      });
    }
    setNotiItems(tempNotiItems);
  };

  const resetNoti = () => {
    setTitle("");
    setNtfBody("");
    setNotiItems([]);
    setSelectedFromDate(now);
    setSelectedToDate(tomorrow);
    setErrorDisplay(false);
    setErrorMsg("");
  };

  const handleFromDateChange = (date: Date | null) => {
    setSelectedFromDate(date);
  };

  const handleToDateChange = (date: Date | null) => {
    setSelectedToDate(date);
  };

  const submitNoti = () => {
    if (!enableSubmit) {
      if (toTimeStamp(selectedFromDate) < toTimeStamp(selectedToDate)) {
        let computedNotiItems =
          notiItems?.length !== 0
            ? notiItems?.map((n: any) => {
                delete n.id;
                return n;
              })
            : [];
        let notiObject = {
          title: title,
          items: computedNotiItems,
          description: ntfBody,
          startDate: toTimeStamp(selectedFromDate),
          endDate: toTimeStamp(selectedToDate),
        };
        resetNoti();
        handleClose();
        notiObject.items = notiObject.items.map((item: any, idx: number) => {
          let newImages = item.images.map((image: any, i: number) => {
            return {
              ...image,
              sequenceIndex: i,
            };
          });

          return {
            ...item,
            sequenceIndex: idx,
            images: newImages,
          };
        });
        if (updateFlag) {
          dispatch(NotificationClient.updateNoti(activeNoti?.id, notiObject));
        } else {
          dispatch(NotificationClient.createNoti(notiObject));
        }
        setTitle("");
        setEditorState(EditorState.createEmpty());
        setNotiItems([]);
      } else {
        setErrorDisplay(true);
        setErrorMsg("End date can not be greater then start date*");
      }
    } else {
      setErrorDisplay(true);
      if (title === "") setErrorMsg("Please provide notification title*");
      else if (!isValid(selectedFromDate))
        setErrorMsg("Please provide valid start date*");
      else if (!isValid(selectedToDate))
        setErrorMsg("Please provide valid end date*");
      else setErrorMsg("Please provide title of notification item*");
    }
  };

  return (
    <div className="notiCont">
      <Modal
        show={showVal}
        onHide={() => {
          handleClose();
        }}
        backdrop="static"
        keyboard={false}
        centered
        id="NotiPopUp"
      >
        <Modal.Header className="searchPopHead">
          <header>{headerText}</header>
          <button
            className="sPopUpClose"
            onClick={() => {
              resetNoti();
              handleClose();
            }}
          >
            <IoClose color="white" />
          </button>
        </Modal.Header>
        <Modal.Body className="confirmBody">
          <div className="notiCreateCont">
            <Form className="ntfForm">
              <Form.Group className="selector">
                <Form.Label>{labels.notificationTitle}</Form.Label>
                <FormControl
                  placeholder="Type Notification Title"
                  onChange={onTitleSubmit}
                  maxLength={65}
                  id="ntfTitleInput"
                  value={title || ""}
                />
              </Form.Group>
              <Form.Group className="ntfBodyInput">
                <Form.Label>Notification Body</Form.Label>
                <CustomTextEditor
                  placeholder="Type Notification Body"
                  editorState={editorState}
                  onEditorStateChange={setEditorState}
                />
              </Form.Group>
            </Form>
            <div className="dateContainer">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="dateInnerView">
                  <KeyboardDateTimePicker
                    id="date-picker-dialog"
                    ampm={true}
                    label="From"
                    value={selectedFromDate}
                    onChange={handleFromDateChange}
                    disablePast
                    format="yyyy/MM/dd HH:mm"
                  />
                </div>
                <div className="dateInnerView">
                  <KeyboardDateTimePicker
                    id="date-picker-dialog"
                    ampm={true}
                    label="To"
                    value={selectedToDate}
                    onChange={handleToDateChange}
                    disablePast
                    format="yyyy/MM/dd HH:mm"
                  />
                </div>
              </MuiPickersUtilsProvider>
            </div>
            <p>
              <button className="addItemsBtn" onClick={() => addNewItem()}>
                {"Add Item".toUpperCase()}
              </button>
            </p>
            <div className="ntfItemsCont">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="vertical">
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {notiItems?.map((noti: any, i: number) => {
                        return (
                          <Draggable
                            key={noti.id}
                            draggableId={noti.id}
                            index={i}
                          >
                            {(provided, snapshot) => (
                              <div
                                className="mainItemContainer"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <div
                                  className={`main-div-drag`}
                                  style={{
                                    ...getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    ),
                                    position: "absolute",
                                  }}
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                >
                                  <DragDrop height="30px" />
                                </div>

                                <NotiItemCard
                                  item={noti}
                                  deleteItem={(key: any) => deleteItem(key)}
                                  updateItem={(key: any, itemDetails: any) =>
                                    updateItem(key, itemDetails)
                                  }
                                  updateFlag={updateFlag}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={errorDisplay ? "errorFooterCont" : ""}>
          {errorDisplay ? <span className="errorMsg">{errorMsg}</span> : <></>}
          <div className="notiBtnCont">
            <button
              className="sPopCloseBtn"
              onClick={() => {
                resetNoti();
                handleClose();
              }}
            >
              Cancel
            </button>
            <div className="ntfBtn">
              <button className="submitNtfBtn" onClick={() => submitNoti()}>
                {updateFlag ? "Update" : "Submit"}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateNotification;
