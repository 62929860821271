import { createSlice } from "@reduxjs/toolkit";
import { getLocatorEleTypeConfig } from "../../../util/utilFuncs";

const slice = createSlice({
  name: "locatorElements",
  initialState: {
    elements: {},
  },
  reducers: {
    addToLocatorEle: (locatorElements, action) => {
      let currLocatorEle: any = locatorElements.elements;
      const eleType = action.payload?.eleType;
      const eleData = action.payload?.eleData;
      let eleTypeConfig = getLocatorEleTypeConfig(eleType);
      if (eleData) {
        if (currLocatorEle[eleType]) {
          if (
            currLocatorEle[eleType]?.findIndex(
              (ele: any) =>
                ele?.[eleTypeConfig?.eleIdName] ===
                eleData?.[eleTypeConfig?.eleIdName]
            ) === -1
          ) {
            currLocatorEle[eleType] = [
              ...currLocatorEle[eleType],
              ...[eleData],
            ];
            currLocatorEle = {
              ...currLocatorEle,
              ...{ [eleType]: currLocatorEle[eleType] },
            };
          }
        } else {
          let eleFeatures = [eleData];
          currLocatorEle = { ...currLocatorEle, ...{ [eleType]: eleFeatures } };
        }
      }
      locatorElements.elements = currLocatorEle;
    },
  },
});

export const { addToLocatorEle } = slice.actions;

export default slice.reducer;
