import { useTranslation } from "react-i18next";

export default function IEC_Labels() {
  const { t, i18n } = useTranslation();
  return Object.freeze({
    Customer_Experience_Acknowledgement: t(
      "Customer_Experience_Acknowledgement"
    ),
    Email_Details: t("Email_Details"),
    Completed_IEC: t("completed IEC"),
    Customer_Experience_Certification_Form:
      "Ignite Experience Certification Form",
    Customer_Experience_Certification: "Ignite Experience Certification",
    CEC: t("IEC"),
    CECEmailMSG: t("IEC Email"),
    IECExceptionMsg: t("IECExceptionMsg"),
    DesOfIssue: t("Description of issue"),
    verifyConnection: t("verifyConnection"),
    bestwifiExperience: t("bestwifiExperience"),
    wifiSatisfaction: t("wifiSatisfaction"),
    WifiSpeed: t("WifiSpeed"),
    WifiHubApp: t("WifiHubApp"),
    CustomerSurvey: t("CustomerSurvey"),
    customerConfidence: t("customerConfidence"),
    activities: t("activities"),
    wifiHubAppOnline: t("wifiHubAppOnline"),
    connectDevices: t("connectDevices"),
    demoServices: t("demoServices"),
    wifiHubAppStatus: t("wifiHubAppStatus"),
    AppAccess: t("AppAccess"),
    NoEmail: t("NoEmail"),
    registeration: t("registeration"),
    accountHolderStats: t("accountHolderStats"),
    systemIssue: t("systemIssue"),
    languageBarrier: t("languageBarrier"),
    noAppUse: t("noAppUse"),
  });
}
