import { useState, useEffect } from "react";
import AppLabels from "../../../../../constants/App_Labels";
import DataCard from "../../../reusable/DataCard";
import NumberInputBox from "../../../reusable/NumberInputBox";
import MultiSelectDD from "../../../reusable/MultiSelectDD";
import { GetTelemetryProps } from "../../../../util/reduxFunctions/getTopologyState";
import {
  decrChAdvFilterObj,
  encrChAdvFilterObj,
} from "../../../../util/utilFuncs";
import AdvFilterData from "../../../../data/AdvFilterData";
import CustomDropDown from "../../../../../components/reusable/CustomDropDown";

interface Props {
  filtersData: any;
  setState: any;
}
const DownstreamFilters = ({ filtersData, setState }: Props) => {
  const labels = AppLabels();
  const { advancedFilters } = GetTelemetryProps();
  let advFilterData = AdvFilterData();
  let dsAdvFData = advancedFilters?.downstream;
  const [dsAdvFilters, setDsAdvFilters] = useState<any>(filtersData);
  const [advFilterFlag, setAdvFilterFlag] = useState<boolean>(true);
  const updateFilter = (
    filterKey: string,
    value: any,
    isOperator: boolean = false
  ) => {
    if (filterKey) {
      let filter = isOperator
        ? {
            label: dsAdvFilters?.[filterKey]?.label,
            operator: value,
            value: dsAdvFilters?.[filterKey]?.value,
          }
        : {
            label: dsAdvFilters?.[filterKey]?.label,
            operator: dsAdvFilters?.[filterKey]?.operator,
            value: value,
          };
      setDsAdvFilters({
        ...dsAdvFilters,
        ...{ [filterKey]: filter },
      });
    }
  };

  useEffect(() => {
    if (dsAdvFilters) {
      let encrFilterObj = encrChAdvFilterObj(
        dsAdvFilters,
        advFilterData?.dsOperatorKeys
      );
      setState(encrFilterObj);
    }
  }, [dsAdvFilters]);

  useEffect(() => {
    if (dsAdvFData && advFilterFlag) {
      let decrFilterObj = decrChAdvFilterObj(
        dsAdvFData,
        filtersData,
        advFilterData?.dsOperatorKeys
      );
      setDsAdvFilters(decrFilterObj);
      setAdvFilterFlag(false);
    }
  }, [advancedFilters]);

  useEffect(() => {
    if (!dsAdvFData || !advFilterFlag) {
      setDsAdvFilters(filtersData);
    }
  }, [filtersData]);

  return (
    <DataCard
      cardHeader={labels.DOWNSTREAM.toUpperCase()}
      cardBody={
        <div className="advFilterBody">
          <p className="advfilterRow">
            <span className="labelGroup">
              <span>
                {dsAdvFilters?.isPartialService?.label?.toUpperCase()}
              </span>
            </span>
            <span className="filterComparator">
              {dsAdvFilters?.isPartialService?.operator}
            </span>
            <input
              type="checkbox"
              checked={dsAdvFilters?.isPartialService?.value}
              className="partialCheckBox"
              onChange={(event) =>
                updateFilter("isPartialService", event.target.checked)
              }
            />
          </p>
          <p className="advfilterRow">
            <span className="labelGroup">
              <span>{dsAdvFilters?.freq?.label?.toUpperCase()}</span>
            </span>
            <span className="filterComparator">
              {dsAdvFilters?.freq?.operator}
            </span>
            <MultiSelectDD
              options={dsAdvFilters?.freq?.value}
              searchFlag={true}
              setSelectedOptions={(value: any) => {
                updateFilter("freq", value);
              }}
            />
          </p>
          <p className="advfilterRow">
            <span className="labelGroup">
              <span>{dsAdvFilters?.rxLess?.label?.toUpperCase()}</span>
            </span>
            <span className="filterComparator">
              {dsAdvFilters?.rxLess?.operator}
            </span>
            <NumberInputBox
              value={dsAdvFilters?.rxLess?.value}
              setValue={(v: any) => updateFilter("rxLess", v)}
            />
            <span>OR</span>
            <span className="filterComparator">
              {dsAdvFilters?.rxGreater?.operator}
            </span>
            <NumberInputBox
              value={dsAdvFilters?.rxGreater?.value}
              setValue={(v: any) => updateFilter("rxGreater", v)}
            />
          </p>
          {Object.keys(dsAdvFilters)?.map((filterKey: any, i: number) => {
            let isKeyAllowed =
              advFilterData?.dsSpeFilterKeys1.includes(filterKey);
            if (isKeyAllowed) {
              let filter: any = dsAdvFilters?.[filterKey];
              return (
                <p className="advfilterRow" key={i}>
                  <span className="labelGroup">
                    <span>{filter?.label?.toUpperCase()}</span>
                  </span>
                  <CustomDropDown
                    selectedOption={filter?.operator}
                    options={advFilterData?.compOperatorList}
                    searchFlag={false}
                    checkboxFlag={false}
                    setSelectedOption={(value: any) => {
                      updateFilter(filterKey, value, true);
                    }}
                  />
                  <NumberInputBox
                    value={filter?.value}
                    setValue={(v: any) => updateFilter(filterKey, v)}
                  />
                </p>
              );
            }
          })}
          <p className="advfilterRow">
            <span className="labelGroup">
              <span>{dsAdvFilters?.ofdmRXLess?.label?.toUpperCase()}</span>
            </span>
            <span className="filterComparator">
              {dsAdvFilters?.ofdmRXLess?.operator}
            </span>
            <NumberInputBox
              value={dsAdvFilters?.ofdmRXLess?.value}
              setValue={(v: any) => updateFilter("ofdmRXLess", v)}
            />
            <span>OR</span>
            <span className="filterComparator">
              {dsAdvFilters?.ofdmRXGreater?.operator}
            </span>
            <NumberInputBox
              value={dsAdvFilters?.ofdmRXGreater?.value}
              setValue={(v: any) => updateFilter("ofdmRXGreater", v)}
            />
          </p>
          {Object.keys(dsAdvFilters)?.map((filterKey: any, i: number) => {
            let isKeyAllowed =
              advFilterData?.dsSpeFilterKeys2.includes(filterKey);
            if (isKeyAllowed) {
              let filter: any = dsAdvFilters?.[filterKey];
              return (
                <p className="advfilterRow" key={i}>
                  <span className="labelGroup">
                    <span>{filter?.label?.toUpperCase()}</span>
                  </span>
                  <CustomDropDown
                    selectedOption={filter?.operator}
                    options={advFilterData?.compOperatorList}
                    searchFlag={false}
                    checkboxFlag={false}
                    setSelectedOption={(value: any) => {
                      updateFilter(filterKey, value, true);
                    }}
                  />
                  <NumberInputBox
                    value={filter?.value}
                    setValue={(v: any) => updateFilter(filterKey, v)}
                  />
                </p>
              );
            }
          })}
        </div>
      }
    />
  );
};

export default DownstreamFilters;
