import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { HttpClient } from "../HttpClient";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";

import {
  stbAccountDetailsBegin,
  stbAccountDetailsFailure,
  stbAccountDetailsSuccess,
} from "../../../store/slices/accountInfo/stbAccountDetails";
import { STBInfo } from "../../../store/models/account/accountInfo/STBInfo";
import { NetworkConfig } from "../NetworkConfig";

export const useStbDetailsClient = () => {
  const dispatch = useDispatch();

  const getStbAccountDetails = useCallback(
    async (value: string) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: "/api/network-service/diag/stb/account",
        //url: NetworkConfig.NETWORK_SERVICE_URL + "diag/stb/account",
        params: { id: value },
      };

      dispatch(stbAccountDetailsBegin());

      const result = await HttpClient.get<STBInfo>(httpParameters)
        .then((stbAccountDetails) => {
          dispatch(stbAccountDetailsSuccess(stbAccountDetails));
          return stbAccountDetails;
        })
        .catch((error) => {
          dispatch(stbAccountDetailsFailure(error));
          return error;
        });
      return result;
    },
    [dispatch]
  );

  return {
    getStbAccountDetails,
  };
};
