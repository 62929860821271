import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";

interface Props {
  label?: string;
  selectedValueIndex?: number;
  data: any;
  onClick: any;
}

export default function CustomRadioGroup(props: Props) {
  var views = [];
  for (let i = 0; i < props.data.length; i++) {
    views.push(
      <ToggleButton
        key={i}
        type="radio"
        className="toggleButtons"
        size="sm"
        value={props.data?.[i]}
        id={`${i}`}
      >
        {props.data?.[i]?.toUpperCase()}
      </ToggleButton>
    );
  }

  const handleSelection = (e: any) => {
    props.onClick(props.data?.[props.data?.indexOf(e.target.value)]);
  };

  return (
    <div className="radioGroupContainer">
      <label>{props.label}</label>
      <ToggleButtonGroup
        name="firstSet"
        value={props.data?.[props?.selectedValueIndex as number]}
        onClick={handleSelection}
      >
        {views}
      </ToggleButtonGroup>
    </div>
  );
}
