import { IHPFeatures } from "../../../../models/account/diagnostics/ihp/IHPFeatures";
import {
  IHP_FEATURES_BEGIN,
  IHP_FEATURES_SUCCESS,
  IHP_FEATURES_FAILURE,
  IhpFeaturesActionTypes,
} from "../../../../types/account/diagnostics/ihp/IHPFeatures";

export function ihpFeaturesBegin(): IhpFeaturesActionTypes {
  return {
    type: IHP_FEATURES_BEGIN,
  };
}

export function ihpFeaturesSuccess(
  ihpFeatures: IHPFeatures
): IhpFeaturesActionTypes {
  return {
    type: IHP_FEATURES_SUCCESS,
    payload: ihpFeatures,
  };
}

export function ihpFeaturesFailure(error: Error): IhpFeaturesActionTypes {
  return {
    type: IHP_FEATURES_FAILURE,
    payload: error,
  };
}
