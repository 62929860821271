import React, { useEffect } from "react";

interface Props {
  gMap?: google.maps.Map;
  mduStrandSpecialCableData?: any;
}

const MduStrandSpecialCable = React.memo(
  ({ gMap, mduStrandSpecialCableData }: Props) => {
    useEffect(() => {
      gMap?.data.addGeoJson(mduStrandSpecialCableData);
    }, [gMap,mduStrandSpecialCableData]);
    return <></>;
  }
);

export default MduStrandSpecialCable;
