import { useEffect, useState } from "react";
import { BiLinkExternal } from "react-icons/bi";

import "../../../../styles/drawer/rf/RfLogicalView.scss";
import TopologyLabels from "../../../../constants/topologyLabels";
import {
  convertRfTraceDataToTreeFormat,
  getTypeTitle,
  getUniquePayload,
} from "../../../../util/utilFuncs";
import NetworkTraceTreeView from "../../../../components/reusable/NetworkTraceTreeView";
import {
  GetMapDrawerProps,
  useRfDownstreamTraceState,
  useRfUpstreamTraceState,
} from "../../../../util/reduxFunctions/getTopologyState";
import ChipGroup from "../../../../../components/reusable/ChipGroup";
import ExceptionBox from "../../../../../components/reusable/ExceptionBox";
import { paths } from "../../../../../routes/Routes";
import appConst from "../../../../constants/appConst";
import ConfigConst from "../../../../../constants/ConfigConst";

export default function TraceLogicalView() {
  const labels = TopologyLabels();
  const { drawerView, logicalViewTabIndex } = GetMapDrawerProps();
  const {
    data: rfDsData,
    networkId: rfDsNetworkId,
    type: rfDsDeviceType,
    isActive: isRfDsActive,
    elementInfo: rfDsElementInfo,
  } = useRfDownstreamTraceState();
  const {
    data: rfUsData,
    networkId: rfUsNetworkId,
    type: rfUsDeviceType,
    isActive: isRfUsActive,
    elementInfo: rfUsElementInfo,
  } = useRfUpstreamTraceState();
  const chipData = [
    {
      title: labels.downstream?.toUpperCase(),
      description: `${labels.downstream} ${labels.logicalView}`,
    },
    {
      title: labels.upstream?.toUpperCase(),
      description: `${labels.upstream} ${labels.logicalView}`,
    },
  ];

  const [chipTypeIndex, setChipTypeIndex] = useState(0);
  const [title, setTitle] = useState("");
  const [data, setData] = useState<any>({});

  const expandTreeInNewTab = () => {
    const url = window?.open(`/#` + paths.rfLogicalView);
    url?.addEventListener("load", () => {
      url.document.title = paths.rfLogicalView;
      url.postMessage(
        {
          deviceType: chipTypeIndex === 0 ? rfDsDeviceType : rfUsDeviceType,
          networkId: chipTypeIndex === 0 ? rfDsNetworkId : rfUsNetworkId,
          traceType:
            chipTypeIndex === 0 ? appConst.downstream : appConst.upstream,
          elementInfo: chipTypeIndex === 0 ? rfDsElementInfo : rfUsElementInfo,
          traceData:
            chipTypeIndex === 0
              ? getUniquePayload(rfDsData)
              : getUniquePayload(rfUsData)?.slice(0)?.reverse(),
        },
        url.origin
      );
    });
  };

  useEffect(() => {
    setChipTypeIndex(logicalViewTabIndex);
  }, [logicalViewTabIndex]);

  useEffect(() => {
    if (chipTypeIndex === 0 && rfDsData && isRfDsActive) {
      setTitle(rfDsNetworkId);
      setData({
        name: rfDsNetworkId,
        type: rfDsDeviceType,
        attributes: {
          deviceType: getTypeTitle(rfDsDeviceType)?.title,
          deviceModel:
            rfDsDeviceType === appConst.taps
              ? rfDsElementInfo?.tap?.deviceType
              : rfDsElementInfo?.rfDeviceType,
          activeDeviceRens: rfDsElementInfo?.powerSupplyId,
          tapValue: rfDsElementInfo?.tap?.tapValue,
        },
        children: convertRfTraceDataToTreeFormat(getUniquePayload(rfDsData)),
      });
    } else if (chipTypeIndex === 1 && rfUsData && isRfUsActive) {
      setTitle(rfUsNetworkId);
      setData({
        name: rfUsNetworkId,
        type: rfUsDeviceType,
        attributes: {
          deviceType: getTypeTitle(rfUsDeviceType)?.title,
          deviceModel:
            rfUsDeviceType === appConst.taps
              ? rfUsElementInfo?.tap?.deviceType
              : rfUsElementInfo?.rfDeviceType,
          activeDeviceRens: rfUsElementInfo?.powerSupplyId,
          tapValue: rfUsElementInfo?.tap?.tapValue,
        },
        children: convertRfTraceDataToTreeFormat(
          getUniquePayload(rfUsData)?.slice(0)?.reverse()
        ),
      });
    } else {
      setTitle("");
      setData({});
    }
  }, [chipTypeIndex, rfDsData, rfUsData]);

  return (
    <>
      <div className="labelContainer">
        <ChipGroup
          chips={chipData}
          activeChipIndex={chipTypeIndex}
          onIndexChange={setChipTypeIndex}
        />
        {title !== "" && (
          <div>
            <span className="labelMedium">{title}</span>
            <BiLinkExternal
              className="basicHeaderIcon"
              onClick={expandTreeInNewTab}
            />
          </div>
        )}
      </div>
      {title !== "" ? (
        <NetworkTraceTreeView
          data={data}
          zoom={0.2}
          translate={{ x: 250, y: 30 }}
          style={{ bottom: 70, right: 70 }}
          isZoomButtonsVisible={drawerView !== ConfigConst.close}
        />
      ) : (
        <div className="diagErrorCont">
          <ExceptionBox headerText={labels.noDataFound} bodyText={""} />
        </div>
      )}
    </>
  );
}
