import { useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import LoaderGif from "../../resources/images/loader.webp";
import CodeWordsClient from "../../network/httpclient/codeword/CodeWordsClient";
import {
  CWMonitoringReset,
  CWMonitoringBegin,
  CWMonitoringSuccess,
} from "../../store/actions/account/diagnostics/cm/CWMonitoringAction";
import ReloadData from "../reusable/ReloadData";
import CWDownstream from "./CWDownstream";
import CwOFDMA from "./CwOFDMA";
import CwOFDMDownStream from "./CwOFDMDownStream";
import CWUpstream from "./CWUpstream";
import FailedAttributesCw from "../FailedAttributes/FailedAttributesCw";
import { CertiDevice } from "../../store/models/account/certifications/CertiDevice";
import { SaveActiveCertiDevices } from "../../store/actions/account/certifications/ActiveCertiDevicesAction";
import {
  SetFailedCodewords,
  SetCodewordValues,
} from "../../store/actions/account/accountInfo/ActiveAccountAction";
import { SetSCQAMFLAG } from "../../store/actions/account/accountInfo/ActiveAccountAction";
import ExceptionBox from "../reusable/ExceptionBox";

const CodeWordMonitor = (props: any) => {
  const { seleDevice, flag, cw } = props;
  const dispatch = useDispatch();
  const {
    cwMonitorLoading,
    cwMonitorCallIndex,
    cwMonitorData,
    isLoading,
    cwMonitorStatus,
    tracer,
  } = useSelector(
    (state: any) => ({
      cwMonitorLoading: state.AccountState.cwMonitorInfo.overallLoading,
      isLoading: state.AccountState.cwMonitorInfo.isLoading,
      cwMonitorData: state.AccountState.cwMonitorInfo.data,
      cwMonitorCallIndex: state.AccountState.cwMonitorInfo.callIndex,
      cwMonitorStatus: state.AccountState.cwMonitorInfo.status,
      tracer: state.AccountState.cwMonitorInfo.tracer,
    }),
    shallowEqual
  );
  const loadingRef = useRef(cwMonitorLoading);
  const [currCWInfo, setCurrCWInfo] = useState<any>();
  const [firstCWInfo, setFirstCWInfo] = useState<any>();
  const [seconds, setSeconds] = useState<any>();
  const [show, setShow] = useState(false);
  const firstIsLoading = cwMonitorCallIndex === 0 ? isLoading : false;

  loadingRef.current = cwMonitorLoading;
  const refreshData = () => {
    setFirstCWInfo(undefined);
    dispatch(CWMonitoringReset());
    dispatch(SetFailedCodewords(""));
    dispatch(SetSCQAMFLAG(false));
    dispatch(SetCodewordValues(""));
    dispatch(CodeWordsClient.getCodewords(seleDevice.macAddress, tracer));
  };

  useEffect(() => {
    if (cwMonitorLoading) {
      let cwInterval = setInterval(() => {
        if (!loadingRef.current) {
          clearInterval(cwInterval);
        } else {
          dispatch(CodeWordsClient.getCodewords(seleDevice.macAddress, tracer));
        }
      }, 10000);
      return () => {
        clearInterval(cwInterval);
      };
    }
  }, [cwMonitorLoading]);

  useEffect(() => {
    if (
      cwMonitorCallIndex >= 1 &&
      cwMonitorData !== undefined &&
      cwMonitorData?.length !== 0
    ) {
      setCurrCWInfo(cwMonitorData[cwMonitorCallIndex - 1]);
      if (cwMonitorCallIndex === 1) {
        setFirstCWInfo(cwMonitorData[cwMonitorCallIndex - 1]);
      }
      setSeconds((cwMonitorCallIndex - 1) * 10);
    }
  }, [cwMonitorCallIndex]);
  useEffect(() => {
    if (cw) {
      refreshData();
    }
  }, [cw]);

  return (
    <>
      {!tracer && (
        <div className="refreshCont">
          <ReloadData refreshData={refreshData} disabled={cwMonitorLoading} />
        </div>
      )}
      {!firstIsLoading ? (
        <div className="cmSummaryCont">
          {currCWInfo !== undefined &&
          firstCWInfo !== undefined &&
          cwMonitorStatus !== "ERROR" ? (
            <>
              <FailedAttributesCw
                firstCWInfo={firstCWInfo}
                currCWInfo={currCWInfo}
                serialNumber={seleDevice.serialNumber}
              />

              <CWUpstream
                firstCWInfo={firstCWInfo?.upstreamChannels}
                cwUpStreamData={currCWInfo?.upstreamChannels}
                currSeconds={seconds}
              />
              <CWDownstream
                firstCWInfo={firstCWInfo?.downstreamChannels}
                cwDownStreamData={currCWInfo?.downstreamChannels}
                currSeconds={seconds}
              />
              <CwOFDMA
                firstCWInfo={firstCWInfo?.ofdmaUsChannels}
                cwOFDMAData={currCWInfo?.ofdmaUsChannels}
                currSeconds={seconds}
              />
              <CwOFDMDownStream
                firstCWInfo={firstCWInfo?.ofdmDsChannels}
                cwOFDMDsData={currCWInfo?.ofdmDsChannels}
                currSeconds={seconds}
              />
            </>
          ) : (
            <div className="diagErrorCont">
              <ExceptionBox
                headerText="No Data to display in Codeword Monitoring."
                bodyText="Please try refreshing."
                errorDeviceSkip={
                  "This device will be skipped for the certification due to a data issue."
                }
                primaryBtn={false}
                secBtn={false}
              />
            </div>
          )}
        </div>
      ) : (
        <img className="loaderImg" src={LoaderGif} />
      )}
    </>
  );
};

export default CodeWordMonitor;
