import { useDispatch } from "react-redux";
import { useEffect } from "react";
import CustomAccordion from "../../../../../../../components/reusable/CustomAccordion";
import appConst from "../../../../../../constants/appConst";
import TopologyLabels from "../../../../../../constants/topologyLabels";
import {
  GetCommentsProps,
  GetMduNodeSiteComponentProps,
  GetMduNodeSiteInfoProps,
} from "../../../../../../util/reduxFunctions/getTopologyState";
import CustomTabs from "../../../../../reusable/CustomTabs";
import MapEleDataDisplay from "../../../../../reusable/MapEleDataDisplay";
import NodeCommentsView from "../../../../../drawer/nodeComments/NodeCommentsView";
import { getComments } from "../../../../../../store/slices/mapItems/comments/comments";
import { getMDUSiteComponent } from "../../../../../../store/slices/mapItems/mdu/mduSiteComponents";
import MduNodeSiteComponents from "./MduNodeSiteComponents";
import { getNodeIDFromSC } from "../../../../../../util/utilFuncs";
import { getMDUNodeSiteInfo } from "../../../../../../store/slices/mapItems/mdu/mduNodeSiteInfo";
import MduNodeSiteDetails from "./MduNodeSiteDetails";

interface Props {
  eleConfig: any;
  eleData: any;
}

const MduFiberEquipment = ({ eleConfig, eleData }: Props) => {
  const labels = TopologyLabels();
  const dispatch = useDispatch();
  const { eleCommentsData } = GetCommentsProps();
  const { allMduNSCData } = GetMduNodeSiteComponentProps();
  const { allMduNSData } = GetMduNodeSiteInfoProps();
  const data = eleCommentsData?.[eleData?.networkId]?.data;

  const isMDUsite = eleData?.sType === "SITE_MDU" ? true : false;
  const activeNSC = allMduNSCData?.filter(
    (nsc: any) => nsc?.networkId === eleData?.networkId
  )?.[0];
  const activeNodeSiteInfo = allMduNSData?.filter(
    (nsc: any) => nsc?.networkId === eleData?.networkId
  )?.[0];

  const handleSelect = (key: any) => {
    if (key == appConst?.comment) {
      if (data === undefined && eleData) {
        dispatch(getComments(eleData?.networkId));
      }
    }
  };

  useEffect(() => {
    if (isMDUsite && !activeNSC?.data) {
      dispatch(getMDUSiteComponent(eleData?.networkId));
    }

    if (isMDUsite && activeNSC?.data && !eleData?.ups) {
      let rsName = activeNSC?.data?.filter(
        (ns: any) => ns?.type === appConst.returnSegment
      )?.[0]?.name;
      const nodeID = getNodeIDFromSC(rsName);

      if (nodeID && eleData?.networkId) {
        dispatch(getMDUNodeSiteInfo(nodeID, eleData?.networkId));
      }
    }
  }, [eleData?.networkId, activeNSC]);

  const showBasicInfo = () => {
    return (
      <div className="activeMapEleCont">
        {eleData !== undefined &&
          eleConfig !== undefined &&
          eleConfig?.length !== 0 && (
            <>
              <CustomAccordion
                accTitle={eleConfig?.title?.toUpperCase()}
                accBodyElement={
                  <>
                    {eleData && (
                      <MapEleDataDisplay
                        tableConfig={eleConfig}
                        eleData={eleData}
                      />
                    )}
                  </>
                }
              />
            </>
          )}
      </div>
    );
  };

  const tabsForNode = [
    {
      eventKey: appConst.info,
      title: labels.info,
      compToRend: (
        <div>
          {activeNodeSiteInfo ? (
            <MduNodeSiteDetails
              nodeSiteDetails={activeNodeSiteInfo?.data?.properties?.data}
            />
          ) : (
            showBasicInfo()
          )}
          {activeNSC?.data && (
            <MduNodeSiteComponents siteComponents={activeNSC?.data} />
          )}
        </div>
      ),
    },
    {
      eventKey: appConst.comment,
      title: labels.comments,
      compToRend: <NodeCommentsView activeMapElement={{ data: eleData }} />,
    },
  ];

  return (
    <>
      {!isMDUsite ? (
        <div>{showBasicInfo()}</div>
      ) : (
        <CustomTabs
          defaultKey={appConst.info}
          tabs={tabsForNode}
          onSelect={handleSelect}
        />
      )}
    </>
  );
};

export default MduFiberEquipment;
