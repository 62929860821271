import { AllAccountsState } from "../../states/account/AllAccountsState";
import {
  ADD_ACCOUNT,
  SET_ACTIVE_ACCOUNT_KEY,
  AllAccountActionTypes,
  DELETE_ACCOUNT,
  RESET_ALL_ACCOUNTS,
  UPDATE_ACCOUNT,
} from "../../types/account/AllAccounts";

const initialState: AllAccountsState = {
  activeAccKey: "",
  accounts: [],
};
const AllAccountsReducer = (
  state = initialState,
  action: AllAccountActionTypes
): AllAccountsState => {
  switch (action.type) {
    case ADD_ACCOUNT:
      if (state.accounts?.length > 0) {
        let filteredAcc = state.accounts?.filter(
          (a: any) => a.accNum === action.accNum
        );
        if (filteredAcc !== undefined && filteredAcc?.length === 0) {
          return {
            ...state,
            accounts: [
              ...state.accounts,
              ...[
                {
                  accNum: action.accNum,
                  isUpdated: false,
                  accState: action.payload,
                },
              ],
            ],
          };
        } else {
          return state;
        }
      } else {
        return {
          ...state,
          accounts: [
            {
              accNum: action.accNum,
              isUpdated: false,
              accState: action.payload,
            },
          ],
        };
      }
    case SET_ACTIVE_ACCOUNT_KEY:
      return {
        ...state,
        activeAccKey: action.accNum,
      };
    case DELETE_ACCOUNT:
      let tempAccounts = state.accounts?.filter(
        (acc: any) => acc.accNum !== action.accNum
      );
      return {
        ...state,
        accounts: tempAccounts,
      };

    case UPDATE_ACCOUNT:
      return {
        ...state,
        accounts: state.accounts?.map((acc: any) => {
          if (acc.accNum === action.accNum) {
            let updatedAccState = { ...acc.accState, ...action.payload };
            return {
              ...acc,
              ...{ isUpdated: true, accState: updatedAccState },
            };
          }
          return acc;
        }),
      };
    case RESET_ALL_ACCOUNTS:
      return {
        ...state,
        accounts: [],
      };
    default:
      return state;
  }
};
export default AllAccountsReducer;
