import { NetworkInterfacesData } from "../../../../models/account/diagnostics/cm/NetworkInterfacesData";

import {
    NETWORK_INTERFACES_BEGIN,
    NETWORK_INTERFACES_SUCCESS,
    NETWORK_INTERFACES_FAILURE,
    NetworkInterfacesActionTypes,
} from "../../../../types/account/diagnostics/cm/Ethernet";

export function networkInterfacesBegin(): NetworkInterfacesActionTypes {
  return {
    type: NETWORK_INTERFACES_BEGIN,
  };
}

export function networkInterfacesSuccess(networkInterfaces: NetworkInterfacesData): NetworkInterfacesActionTypes {
  return {
    type: NETWORK_INTERFACES_SUCCESS,
    payload: networkInterfaces,
  };
}

export function networkInterfacesFailure(error: Error): NetworkInterfacesActionTypes {
  return {
    type: NETWORK_INTERFACES_FAILURE,
    payload: error,
  };
}