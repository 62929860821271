import "../../design-tokens/rpd-page/RPDPage.scss";
import { useDispatch } from "react-redux";
import RPDSwapTop from "../../components/rpd/rpdSwapDA2200/RPDSwapTop";
import RPDSwapDetails from "../../components/rpd/rpdSwapDA2200/RPDSwapDetails";
import { GetRPDSwapInfo } from "../../get-api/GetResponse";
import LoaderGif from "../../resources/images/loader.webp";
import RPDExceptionBox from "../../components/reusable/RPDExceptionBox";
import AppLabels from "../../constants/App_Labels";
import { GetTechInfo, GetRPDOptionsList } from "../../get-api/GetResponse";
import OrderSuccessBox from "../../components/rpd/OrderSuccessBox";
import { useEffect } from "react";
import RPDClient from "../../network/httpclient/rpd/RPDClient";

const RPDSwapTab = () => {
  const rpdSwapInfo = GetRPDSwapInfo();
  const techData = GetTechInfo()?.data;
  const labels = AppLabels();
  const rpdOptionsList = GetRPDOptionsList();
  const dispatch = useDispatch();

  useEffect(() => {
    if (rpdOptionsList?.data == undefined) dispatch(RPDClient.getOptionsList());
  }, []);

  return (
    <>
      <div>
        <RPDSwapTop
          oldMacAdd={rpdSwapInfo?.macAddress}
          techData={techData}
          isLoading={rpdSwapInfo?.isMacRPDLoading}
        />
      </div>
      <div className="historyResultCont">
        {rpdSwapInfo.isLoading || rpdSwapInfo.isMacRPDLoading ? (
          <div className="loader">
            <img style={{ height: "60px" }} src={LoaderGif} />
          </div>
        ) : rpdSwapInfo?.data === undefined &&
          rpdSwapInfo?.error !== undefined ? (
          <div className="diagErrorCont">
            <RPDExceptionBox
              exBoxType="error"
              headerText={labels.rpdSwapError}
              errCode={rpdSwapInfo?.error.errorCode}
              ErrorMSG={rpdSwapInfo?.error.errorMessage}
              srcSystem={rpdSwapInfo?.error.sourceSystem}
              bodyText={rpdSwapInfo?.error.responseFromSource}
              primaryBtn={false}
              secBtn={false}
            />
          </div>
        ) : rpdSwapInfo?.data !== undefined &&
          rpdSwapInfo?.error === undefined ? (
          <div className="diagErrorCont">
            <OrderSuccessBox
              headerText={labels.rpdCreateOrderSwapSuccess}
              bodyText={labels.orderStatusCheck + rpdSwapInfo?.data.orderid}
              primaryBtn={false}
              secBtn={false}
              orderid={rpdSwapInfo?.data.orderid}
              rpdOrderInfo={rpdSwapInfo}
            />
          </div>
        ) : rpdSwapInfo?.macRPDData !== undefined ? (
          <RPDSwapDetails
            oldMacAdd={rpdSwapInfo?.macAddress}
            oldDeviceType={rpdSwapInfo?.macRPDData?.DeviceType}
            nptId={rpdSwapInfo?.nptId}
            rpdSwapData={rpdSwapInfo}
            rpdMacData={rpdSwapInfo?.macRPDData}
            isLoading={rpdSwapInfo?.isLoading}
            techData={techData}
          />
        ) : rpdSwapInfo?.macRPDError !== undefined ? (
          <div className="diagErrorCont">
            <RPDExceptionBox
              exBoxType="error"
              headerText={labels.rpdSwapError}
              errCode={rpdSwapInfo?.macRPDError?.errorCode}
              ErrorMSG={rpdSwapInfo?.macRPDError?.errorMessage}
              srcSystem={rpdSwapInfo?.macRPDError?.sourceSystem}
              bodyText={rpdSwapInfo?.macRPDError?.responseFromSource}
              primaryBtn={false}
              secBtn={false}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default RPDSwapTab;
