import React, { MutableRefObject, useEffect, useState } from "react";

import FullScreenPopUp from "../../../../../../components/reusable/FullScreenPopUp";
import { IImage } from "../../../../../../components/reusable/ImageUpload";
import {
  dataURItoBlob,
  formatBytesToSize,
  randomString,
} from "../../../../../../components/reusable/Util";

interface Props {
  attachments: IImage[];
  setAttachements: (imgs: IImage[]) => void;
  stageRef: MutableRefObject<any>;
}
export default function RedlineAttachments({
  attachments,
  setAttachements,
  stageRef,
}: Props) {
  const [openFullImg, setOpenFullImg] = useState(false);
  // const onImageChange = (imgs: any) =>
  // setForm((pv) => ({ ...pv, attachments: imgs }));
  const canvas = attachments?.[0];

  useEffect(() => {
    setAttachements([getMapImage(stageRef)]);
  }, [stageRef]);

  return (
    <>
      {/* <ImageUpload
          title={"Attachments*"}
          image={form.attachments}
          onImageChange={onImageChange}
        /> */}
      {canvas?.isQualityReduced && (
        <h6 style={{ color: `var(--warning)`, fontWeight: 700 }}>
          Note: Image quality reduced to maintain file size capacity!
        </h6>
      )}
      <h6>File Size: {formatBytesToSize(canvas?.size)}</h6>
      {canvas && (
        <img
          src={canvas?.image}
          alt={canvas.fileName}
          style={{
            cursor: "pointer",
            height: 200,
            display: "block",
            margin: "auto",
          }}
          onClick={() => setOpenFullImg(true)}
        />
      )}
      {canvas && (
        <FullScreenPopUp
          open={openFullImg}
          handleClose={() => setOpenFullImg(false)}
          childComponent={
            <img
              src={canvas?.image}
              alt={canvas?.fileName}
              style={{
                maxHeight: "90%",
                maxWidth: "90%",
                display: "block",
                margin: "auto",
              }}
            />
          }
          pageName={canvas?.fileName}
        />
      )}
    </>
  );
}

const MAX_IMG_SIZE = 2 * 1024 * 1024; // 2MB in bytes
/**reduce image quality if size is greater than 2MB */
const getMapImage = (stageRef: MutableRefObject<any>): IImage => {
  let pixelRatio = 1.5;
  let img;
  let fileSize;
  let isQualityReduced = false;

  do {
    // Get image with current pixelRatio
    img = stageRef?.current?.toDataURL({ pixelRatio });
    let blob = dataURItoBlob(img);
    fileSize = blob.size;

    // If the size is still greater than 2MB, decrease pixelRatio and try again
    if (fileSize > MAX_IMG_SIZE) {
      pixelRatio -= 0.1; // reduce pixelRatio by 10%
      isQualityReduced = true;
      if (pixelRatio < 0.1) break; // break out of loop if pixelRatio gets too low
    }
  } while (fileSize > MAX_IMG_SIZE);

  return {
    id: randomString(10),
    size: fileSize,
    image: img,
    fileName: "canvas.png",
    isQualityReduced,
  };
};
