import ExceptionBox from "../reusable/ExceptionBox";
import { isDeviceOffline, isNull } from "../reusable/Util";

const CertiExceptionCont = (props: any) => {
  const { dataObj, sectionName, errorDeviceSkip } = props;
  let errorDevice = errorDeviceSkip ? errorDeviceSkip : "";
  return (
    <div>
      {dataObj !== undefined && !dataObj?.hasOwnProperty("errorCode") ? (
        props.children
      ) : dataObj !== undefined && dataObj?.hasOwnProperty("errorCode") ? (
        <div className="diagErrorCont">
          <ExceptionBox
            exBoxType="error"
            errSecName={sectionName}
            errorDeviceSkip={
              errorDevice != ""
                ? !isDeviceOffline(dataObj)
                  ? errorDevice
                  : ""
                : ""
            }
            errCode={isNull(dataObj?.errorCode)}
            srcSystem={
              dataObj?.hasOwnProperty("sourceSystem")
                ? dataObj?.sourceSystem
                : "Request"
            }
            ErrorMSG={isNull(dataObj?.errorMessage)}
            primaryBtn={false}
            secBtn={false}
          />
        </div>
      ) : null}
    </div>
  );
};

export default CertiExceptionCont;
