import AppLabels from "../../constants/App_Labels";
import TopologyLabels from "../constants/topologyLabels";

export default function AdvanceFilter() {
  const tLabels = TopologyLabels();
  const labels = AppLabels();

  const profileList = ["DEFAULT", "ODM3 M/S", "ODM3 L/S"];
  const compOperatorList = ["<", ">"];
  const regTimeDOptions = ["", "1", "2", "3", "4", "5", "6", "7"];
  const regTimeHOptions = [
    "",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
  ];

  let usSpeFilterKeys1 = [
    "txDelta",
    "USSNR",
    "USSNRDelta",
    "USPRECWE",
    "USPOSTCWE",
  ];
  let usSpeFilterKeys2 = ["ofdmaPCT", "ofdmaPOSTCWE"];
  const usFreqDDOptions = [
    { key: "allOpts", label: "All", value: true },
    {
      key: "SCQAM0to10",
      label: "0-10 " + labels.Mhz + " " + labels.SCQAM,
      value: true,
    },
    {
      key: "SCQAM10to15",
      label: "10-15 " + labels.Mhz + " " + labels.SCQAM,
      value: true,
    },
    {
      key: "SCQAM20to25",
      label: "20-25 " + labels.Mhz + " " + labels.SCQAM,
      value: true,
    },
    {
      key: "SCQAM25to30",
      label: "25-30 " + labels.Mhz + " " + labels.SCQAM,
      value: true,
    },
    {
      key: "SCQAM30to35",
      label: "30-35 " + labels.Mhz + " " + labels.SCQAM,
      value: true,
    },
    {
      key: "SCQAM35to40",
      label: "35-40 " + labels.Mhz + " " + labels.SCQAM,
      value: true,
    },
    {
      key: "SCQAM40to45",
      label: "40-45 " + labels.Mhz + " " + labels.SCQAM,
      value: true,
    },
    {
      key: "SCQAM45to55",
      label: "45-55 " + labels.Mhz + " " + labels.SCQAM,
      value: true,
    },
    {
      key: "SCQAM55to65",
      label: "55-65 " + labels.Mhz + " " + labels.SCQAM,
      value: true,
    },
    {
      key: "SCQAM65to75",
      label: "65-75 " + labels.Mhz + " " + labels.SCQAM,
      value: true,
    },
    {
      key: "SCQAM75to85",
      label: "75-85 " + labels.Mhz + " " + labels.SCQAM,
      value: true,
    },
    {
      key: "OFDMA10",
      label: "OFDMA CH - 10",
      value: true,
    },
    {
      key: "OFDMA11",
      label: "OFDMA CH - 11",
      value: true,
    },
  ];
  const dsFreqDDOptions = [
    { key: "allOpts", label: "All", value: true },
    {
      key: "SCQAM200",
      label: "200 " + labels.Mhz + " " + labels.SCQAM,
      value: true,
    },
    {
      key: "SCQAM300",
      label: "300 " + labels.Mhz + " " + labels.SCQAM,
      value: true,
    },
    {
      key: "SCQAM400",
      label: "400 " + labels.Mhz + " " + labels.SCQAM,
      value: true,
    },
    {
      key: "SCQAM500",
      label: "500 " + labels.Mhz + " " + labels.SCQAM,
      value: true,
    },
    {
      key: "SCQAM600",
      label: "600 " + labels.Mhz + " " + labels.SCQAM,
      value: true,
    },
    {
      key: "SCQAM700",
      label: "700 " + labels.Mhz + " " + labels.SCQAM,
      value: true,
    },
    {
      key: "SCQAM800",
      label: "800 " + labels.Mhz + " " + labels.SCQAM,
      value: true,
    },
    {
      key: "SCQAM900",
      label: "900 " + labels.Mhz + " " + labels.SCQAM,
      value: true,
    },
    {
      key: "OFDM33",
      label: "OFDM CH - 33",
      value: true,
    },
    {
      key: "OFDM34",
      label: "OFDM CH - 34",
      value: true,
    },
  ];

  const usOperatorKeys = {
    txDelta: "txDeltaCompOP",
    USSNR: "SNRCompOP",
    USSNRDelta: "SNRDeltaCompOP",
    USPRECWE: "PRECWECompOP",
    USPOSTCWE: "POSTCWECompOP",
    ofdmaPCT: "ofdmaPCTCompOP",
    ofdmaPOSTCWE: "ofdmaPOSTCWECompOP",
  };

  const dsOperatorKeys = {
    rxDelta: "rxDeltaCompOP",
    DSSNR: "SNRCompOP",
    DSSNRDelta: "SNRDeltaCompOP",
    DSPRECWE: "PRECWECompOP",
    DSPOSTCWE: "POSTCWECompOP",
    ofdmRXDelta: "ofdmRXDeltaCompOP",
    ofdmPCT: "ofdmPCTCompOP",
    ofdmPOSTCWE: "ofdmPOSTCWECompOP",
  };

  let summaryFilters = {
    regTimeCompOperator: {
      label: tLabels.registeredTime,
      operator: "",
      value: "<",
    },
    day: {
      label: "D",
      operator: "",
      value: "",
    },
    hour: {
      label: "H",
      operator: "",
      value: "",
    },
    firstFlap: {
      label: labels.First_Flap,
      operator: ":",
      value: "",
    },
    lastFlap: {
      label: labels.Last_Flap,
      operator: ":",
      value: "",
    },
    flapCount: {
      label: tLabels.flapCount,
      operator: ">",
      value: "",
    },
    inf: {
      label: tLabels.inf,
      operator: ">",
      value: "",
    },
    pwr: {
      label: tLabels.pwr,
      operator: ">",
      value: "",
    },
  };

  let upStreamFilters = {
    isPartialService: {
      label: tLabels.partialService,
      operator: ":",
      value: false,
    },
    txLess: { label: labels.TX, operator: "<", value: "" },
    txGreater: { label: labels.TX, operator: ">", value: "" },
    txDelta: { label: labels.TX_Delta, operator: ">", value: "" },
    USSNR: {
      label: labels.US + " " + labels.SNR,
      operator: "<",
      value: "",
    },
    USSNRDelta: {
      label: labels.US + " " + labels.SNR_Delta,
      operator: ">",
      value: "",
    },
    USPRECWE: {
      key: "scQamPreCwe%",
      label: labels.US + " " + labels.PRE_CCER + " %",
      operator: ">",
      value: "",
    },
    USPOSTCWE: {
      label: labels.US + " " + labels.POST_CER + " %",
      operator: ">",
      value: "",
    },
    ofdmaTXLess: {
      label: labels.OFDMA + " " + labels.TX,
      operator: "<",
      value: "",
    },
    ofdmaTXGreater: {
      label: labels.OFDMA + " " + labels.TX,
      operator: ">",
      value: "",
    },
    ofdmaPCT: {
      label: labels.OFDMA + " " + labels.secondPCT,
      operator: "<",
      value: "",
    },
    ofdmaPOSTCWE: {
      label: labels.OFDMA + " " + labels.post + " " + labels.CWE,
      operator: ">",
      value: "",
    },
    freq: { label: labels.Freq, operator: ":", value: usFreqDDOptions },
  };

  let usODM3Profile = {
    txLess: { label: labels.TX, operator: "<", value: "30.5" },
    txGreater: { label: labels.TX, operator: ">", value: "52.5" },
    USSNR: {
      label: labels.US + " " + labels.SNR,
      operator: "<",
      value: "29",
    },
    USPOSTCWE: {
      label: labels.US + " " + labels.POST_CER + " %",
      operator: ">",
      value: "0.57",
    },
    ofdmaTXLess: {
      label: labels.OFDMA + " " + labels.TX,
      operator: "<",
      value: "30.5",
    },
    ofdmaTXGreater: {
      label: labels.OFDMA + " " + labels.TX,
      operator: ">",
      value: "52.5",
    },
  };

  let usODM3MidProfile = {
    ofdmaPCT: {
      label: labels.OFDMA + " " + labels.secondPCT,
      operator: "<",
      value: "26",
    },
  };
  let usODM3LowProfile = {
    ofdmaPCT: {
      label: labels.OFDMA + " " + labels.secondPCT,
      operator: "<",
      value: "12",
    },
  };

  let dsSpeFilterKeys1 = [
    "rxDelta",
    "DSSNR",
    "DSSNRDelta",
    "DSPRECWE",
    "DSPOSTCWE",
  ];
  let dsSpeFilterKeys2 = ["ofdmRXDelta", "ofdmPCT", "ofdmPOSTCWE"];
  let downStreamFilters = {
    isPartialService: {
      label: tLabels.partialService,
      operator: ":",
      value: false,
    },
    rxLess: {
      label: labels.RX,
      operator: "<",
      value: "",
    },
    rxGreater: {
      label: labels.RX,
      operator: ">",
      value: "",
    },
    rxDelta: {
      label: labels.RX_Delta,
      operator: ">",
      value: "",
    },
    DSSNR: {
      label: labels.DS + " " + labels.SNR,
      operator: "<",
      value: "",
    },
    DSSNRDelta: {
      label: labels.DS + " " + labels.SNR_Delta,
      operator: ">",
      value: "",
    },
    DSPRECWE: {
      label: labels.DS + " " + labels.PRE_CCER + " %",
      operator: ">",
      value: "",
    },
    DSPOSTCWE: {
      label: labels.DS + " " + labels.POST_CER + " %",
      operator: ">",
      value: "",
    },
    ofdmRXLess: {
      label: labels.OFDM + " " + labels.RX,
      operator: "<",
      value: "",
    },
    ofdmRXGreater: {
      label: labels.OFDM + " " + labels.RX,
      operator: ">",
      value: "",
    },
    ofdmRXDelta: {
      label: labels.OFDM + " " + labels.RX_Delta,
      operator: ">",
      value: "",
    },
    ofdmPCT: {
      label: labels.OFDM + " " + labels.secondPCT,
      operator: "<",
      value: "",
    },
    ofdmPOSTCWE: {
      label: labels.OFDM + " " + labels.post + " " + labels.CWE,
      operator: ">",
      value: "",
    },
    freq: { label: labels.Freq, operator: ":", value: dsFreqDDOptions },
  };

  let dsODM3Profile = {
    rxLess: {
      label: labels.RX,
      operator: "<",
      value: "-14",
    },
    rxGreater: {
      label: labels.RX,
      operator: ">",
      value: "13.5",
    },
    DSSNR: {
      label: labels.DS + " " + labels.SNR,
      operator: "<",
      value: "31",
    },
    DSPOSTCWE: {
      label: labels.DS + " " + labels.POST_CER + " %",
      operator: ">",
      value: "0.5",
    },
    ofdmRXLess: {
      label: labels.OFDM + " " + labels.RX,
      operator: "<",
      value: "-12",
    },
    ofdmRXGreater: {
      label: labels.OFDM + " " + labels.RX,
      operator: ">",
      value: "12",
    },
    ofdmPCT: {
      label: labels.OFDM + " " + labels.secondPCT,
      operator: "<",
      value: "29",
    },
  };

  return {
    profileList,
    compOperatorList,
    regTimeDOptions,
    regTimeHOptions,
    summaryFilters,
    usSpeFilterKeys1,
    usSpeFilterKeys2,
    upStreamFilters,
    usODM3Profile,
    usODM3MidProfile,
    usODM3LowProfile,
    dsSpeFilterKeys1,
    dsSpeFilterKeys2,
    downStreamFilters,
    dsODM3Profile,
    usFreqDDOptions,
    dsFreqDDOptions,
    usOperatorKeys,
    dsOperatorKeys,
  };
}
