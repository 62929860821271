import { Divider } from "@material-ui/core";
import WiFiCertiDetails from "../../components/certification/WiFiCertiDetails";
import CustomTab from "../../components/reusable/CustomTab";
import ExceptionBox from "../../components/reusable/ExceptionBox";
import { isNull } from "../../components/reusable/Util";
import ViewODUDetails from "./ViewODUDetails";

const WiFiViewCertiDetails = (props: any) => {
  const {
    remarkInfo,
    certiLocations,
    isApplicable,
    selLValue,
    setSelLValue,
    selLocation,
    oduData,
  } = props;
  return (
    <div className="wifiCertiDetails">
      {remarkInfo !== undefined && remarkInfo?.hasOwnProperty("reasonCode") ? (
        <div className="techInfo">
          <section className="techNote">
            <h5>Technician Notes</h5>
            <textarea
              rows={4}
              cols={50}
              disabled
              className="taNotesCont"
              value={isNull(remarkInfo?.notes)}
            />
          </section>
          <Divider orientation="vertical" style={{ height: "170px" }} />
          <section className="techRemarkInfo">
            <p>
              <span>
                <h3>Reason Code</h3>
                <h5>{isNull(remarkInfo?.reasonText)}</h5>
              </span>
              <span>
                <h3>Access Point Name</h3>
                <h5>{isNull(selLocation?.results?.SSID)}</h5>
              </span>
            </p>
            <p>
              <span>
                <h3>Reviewed with Customer</h3>
                <h5>{remarkInfo?.isReviewedWithCustomer ? "Yes" : "No"}</h5>
              </span>
              <span>
                <h3>Frequency</h3>
                <h5>{isNull(selLocation?.results?.frequencyBand)}</h5>
              </span>
            </p>
          </section>
        </div>
      ) : (
        <></>
      )}

      {isApplicable ? (
        <div className="WHTCont">
          <section className="leftSection">
            {oduData !== undefined ? (
              <>
                <p className="certiDeviceLabel">TESTED DEVICES</p>
                <div className="deviceTabs">
                  <Divider />
                  <div>
                    <CustomTab
                      tabType="vertical-certi-Locations"
                      index={certiLocations?.length + 1}
                      deviceStatus=""
                      value={selLValue}
                      handleSelect={(i: any) => setSelLValue(i)}
                      label="ODU"
                    />
                    <Divider />
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            <p className="certiDeviceLabel">TEST LOCATIONS</p>
            <div className="deviceTabs">
              <Divider />
              {certiLocations !== undefined && certiLocations?.length !== 0 ? (
                certiLocations?.map((location: any, index: number) => {
                  return (
                    <div>
                      <CustomTab
                        tabType="vertical-certi-Locations"
                        deviceStatus={location?.status?.toLowerCase()}
                        index={index}
                        value={selLValue}
                        handleSelect={(i: any) => setSelLValue(i)}
                        label={location?.locationName?.toUpperCase()}
                      />
                      <Divider />
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </section>
          <section className="rightPanel">
            <div className="diagResult">
              {selLocation !== undefined && selLValue !== undefined ? (
                <WiFiCertiDetails selectedLocation={selLocation} />
              ) : (
                selLValue === certiLocations?.length + 1 && (
                  <>
                    {oduData == "NA" ? (
                      <div className="diagErrorCont">
                        <ExceptionBox
                          headerText="No Data to display"
                          bodyText="ODU is not responding"
                          primaryBtn={false}
                          secBtn={false}
                        />
                      </div>
                    ) : (
                      <ViewODUDetails oduData={oduData} />
                    )}
                  </>
                )
              )}
            </div>
          </section>
        </div>
      ) : (
        <div className="diagErrorCont">
          <ExceptionBox
            headerText="WiFi Certification not available."
            bodyText="This could be either due to the reason that the technician has marked the Wifi Certfication as not applicable or it is not required for the Certification test type. "
            primaryBtn={false}
            secBtn={false}
          />
        </div>
      )}
    </div>
  );
};

export default WiFiViewCertiDetails;
