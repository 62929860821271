import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { NodeModemsListState } from "../../../store/states/node/telemetry/NodeModemListState";
import {
  NodeModemsListBegin,
  NodeModemsListFailure,
  NodeModemsListSuccess,
} from "../../../store/actions/node/telemetry/NodeModemsListAction";
import {
  StartNODEDeviceDiag,
  UpdateNODEDevices,
} from "../../../store/actions/node/telemetry/NodeDevicesInfoActions";
import {
  FlapHistoryBegin,
  FlapHistoryFailure,
  FlapHistorySuccess,
} from "../../../store/actions/node/telemetry/FlapHistoryAction";
import { NetworkConfig } from "../NetworkConfig";
import { FlapHistoryState } from "../../../store/states/node/telemetry/FlapHistoryState";
import { FlapHistory } from "../../../store/models/node/telemetry/FlapHistory";
import { ClearFlapsState } from "../../../store/states/node/telemetry/ClearFlapsState";
import {
  ClearFlapsBegin,
  ClearFlapsFailure,
  ClearFlapsSuccess,
} from "../../../store/actions/node/telemetry/ClearFlapsAction";

export default class NodeModemsClient {
  static getNodeModemsInfo =
    (
      searchValue: string,
      searchType: string
    ): ThunkAction<void, NodeModemsListState, unknown, Action<string>> =>
    async (dispatch) => {
      const qParam = [];
      switch (searchType) {
        case "shubId":
          qParam.push({ shubid: searchValue });
          break;
        case "smtId":
          qParam.push({ smtid: searchValue });
          break;
        default:
          qParam.push({ shubid: searchValue });
          break;
      }
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.NETWORK_SERVICE_URL + "diag/network/modemList",
        //url: "http://nprapl000624:8082/"+"diag/network/modemList",
        params: qParam?.[0],
      };

      dispatch(NodeModemsListBegin());
      HttpClient.get<any>(httpParameters)
        .then((NodeModemsList) => {
          dispatch(NodeModemsListSuccess(NodeModemsList));
          return NodeModemsList;
        })
        .catch((error) => {
          dispatch(NodeModemsListFailure(error));
          return error;
        });
    };
  static getFullNodeCMDiag =
    (
      macAddress: string,
      deviceKey: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.NETWORK_SERVICE_URL +
          "diag/cablemodem/" +
          macAddress +
          "/all",
        // url: "http://nprapl000624:8082/" + "diag/cablemodem/" + macAddress + "/all",
      };

      dispatch(StartNODEDeviceDiag(deviceKey));
      HttpClient.get<any>(httpParameters)
        .then((cmData) => {
          dispatch(UpdateNODEDevices(deviceKey, "all", cmData, "OK"));
          return cmData;
        })
        .catch((error) => {
          dispatch(UpdateNODEDevices(deviceKey, "error", error, "ERROR"));
          return error;
        });
    };

  static getNodeTeleCMSummary =
    (
      macAddress: string,
      deviceKey: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.NETWORK_SERVICE_URL +
          "diag/cablemodem/" +
          macAddress +
          "/summary",
      };

      dispatch(StartNODEDeviceDiag(deviceKey));
      HttpClient.get<any>(httpParameters)
        .then((cmData) => {
          dispatch(UpdateNODEDevices(deviceKey, "summary", cmData, "OK"));
          return cmData;
        })
        .catch((error) => {
          dispatch(UpdateNODEDevices(deviceKey, "error", error, "ERROR"));
          return error;
        });
    };

  static clearFlapsbyMacList =
    (
      maclist: string,
      nodeId: string,
      deviceType: string
    ): ThunkAction<void, ClearFlapsState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.NETWORK_SERVICE_URL + "diag/cablemodem/flaps",
        params: { maclist: maclist, nodeId: nodeId, deviceType: deviceType },
      };
      dispatch(ClearFlapsBegin());
      HttpClient.get<any>(httpParameters)
        .then((ClearFlaps) => {
          dispatch(ClearFlapsSuccess(ClearFlaps));
          return ClearFlaps;
        })
        .catch((error) => {
          dispatch(ClearFlapsFailure(error));
          return error;
        });
    };
  static getFlapHistoryByNodeId =
    (
      shubId: string
    ): ThunkAction<void, FlapHistoryState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.NETWORK_SERVICE_URL + "diag/cablemodem/flaps/history",
        params: { nodeId: shubId },
      };
      dispatch(FlapHistoryBegin());
      HttpClient.get<FlapHistory>(httpParameters)
        .then((flapHistory) => {
          dispatch(FlapHistorySuccess(flapHistory));

          return flapHistory;
        })
        .catch((error) => {
          dispatch(FlapHistoryFailure(error));
          return error;
        });
    };
}
