import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./azure/authConfig";

//Css Imports
import "./index.scss";

//Component Imports
import App from "./App";
import i18n from "./i18n";
import { PublicClientApplication } from "@azure/msal-browser";
import configureStore from "./configureStore";

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);
const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </Provider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
