import React, { useEffect } from "react";

interface Props {
  gMap?: google.maps.Map;
  mduPolylineData?: any;
}

const MduPolyline = React.memo(({ gMap, mduPolylineData }: Props) => {
  useEffect(() => {
    if (
      mduPolylineData !== undefined &&
      mduPolylineData?.features?.length > 0
    ) {
      gMap?.data.addGeoJson(mduPolylineData);
    }
  }, [gMap, mduPolylineData]);
  return <></>;
});

export default MduPolyline;
