import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { NetworkConfig } from "../NetworkConfig";
import { NotificationListState } from "../../../store/states/notification/NotificationListState";
import {
  NotificationIsRead,
  NotificationListBegin,
  NotificationListFailure,
  NotificationListSuccess,
} from "../../../store/actions/notification/NotificationListAction";
import { NotiCRUDState } from "../../../store/states/notification/NotiCRUDState";
import {
  NotificationCRUDBegin,
  NotificationCRUDFailure,
  NotificationCRUDSuccess,
} from "../../../store/actions/notification/NotiCRUDAction";


export default class NotificationClient {
  static createNoti =
    (notiObj: any): ThunkAction<void, NotiCRUDState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "notifications",
      };
      dispatch(NotificationCRUDBegin("Create"));
      HttpClient.post<any>(httpParameters, notiObj)
        .then((res) => {
          dispatch(NotificationCRUDSuccess(res));
          return res;
        })
        .catch((error) => {
          dispatch(NotificationCRUDFailure(error));
          return error;
        });
    };
  static updateNoti =
    (
      notiId: string,
      notiObj: any
    ): ThunkAction<void, NotiCRUDState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "notifications/" + notiId,
      };
      dispatch(NotificationCRUDBegin("Update"));
      HttpClient.post<any>(httpParameters, notiObj)
        .then((res) => {
          dispatch(NotificationCRUDSuccess(res));
          return res;
        })
        .catch((error) => {
          dispatch(NotificationCRUDFailure(error));
          return error;
        });
    };
  static getAllNoti =
    (): ThunkAction<void, NotificationListState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "notifications",
      };
      dispatch(NotificationListBegin());
      HttpClient.get<any>(httpParameters)
        .then((notiList) => {
          dispatch(NotificationListSuccess(notiList));
          return notiList;
        })
        .catch((error) => {
          dispatch(NotificationListFailure(error));
          return error;
        });
    };
  static getAllAdminNoti =
    (): ThunkAction<void, NotificationListState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "allnotifications",
      };
      dispatch(NotificationListBegin());
      HttpClient.get<any>(httpParameters)
        .then((notiList) => {
          dispatch(NotificationListSuccess(notiList));
          return notiList;
        })
        .catch((error) => {
          dispatch(NotificationListFailure(error));
          return error;
        });
    };
  static deleteNoti =
    (
      notiId: string,
      notiObj: any
    ): ThunkAction<void, NotiCRUDState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "notifications/" + notiId,
      };
      dispatch(NotificationCRUDBegin("Delete"));
      HttpClient.post<any>(httpParameters, notiObj)
        .then((res) => {
          dispatch(NotificationCRUDSuccess(res));
          return res;
        })
        .catch((error) => {
          dispatch(NotificationCRUDFailure(error));
          return error;
        });
    };
    
    static readNoti =
    (
      notiId: string
    ): ThunkAction<void, NotiCRUDState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "viewNotification/" + notiId,
      };
      HttpClient.post<any>(httpParameters, null)
        .then((res) => {
          dispatch(NotificationIsRead(notiId));
          //dispatch(notificationViwedSuccess(notiId));
          return res;
        })
        .catch((error) => {
          //dispatch(NotificationCRUDFailure(error));
          return error;
        });
    };
}
