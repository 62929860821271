import { CSSProperties } from "react";

import ExceptionBox from "../../../../components/reusable/ExceptionBox";
import TopologyLabels from "../../../constants/topologyLabels";
import { GetMapDrawerProps } from "../../../util/reduxFunctions/getTopologyState";
import "../../../styles/drawer/nika/Nika.scss";
import { formatCamelCase } from "../../../util/utilFuncs";
import Table, { TCTypes, TDTypes } from "../../reusable/table/Table";

const NIKA_MANAGER_URL = (window as any).config?.NIKA_MANAGER_URL;

interface Props {
  data: any;
  configData: any;
  showExpandBtn?: boolean;
  tableStyle?: CSSProperties;
}
export default function NikaTableOrders({
  data,
  configData,
  showExpandBtn = true,
  tableStyle,
}: Props) {
  const labels = TopologyLabels();
  const { drawerView } = GetMapDrawerProps();
  const TW = drawerView === "fullScreen" ? "100%" : "calc(23.5vw)";
  const TH =
    drawerView === "fullScreen" ? "calc(100vh - 230px)" : "calc(100vh - 300px)";

  const tableData = [] as TDTypes[];

  data?.map((allTypes: any) => {
    allTypes?.features?.map((e: any) => {
      const propData = e?.properties?.data;
      tableData.push({
        program: (
          <a
            className="idLink"
            onClick={() =>
              onMarkerPress({ id: propData?.id, orderKey: propData?.orderKey })
            }
          >
            {formatCamelCase(
              propData?.projectType
                ? propData?.projectType
                : e?.properties?.type
            )}
          </a>
        ),
        projectType: propData?.projectType,
        id: propData?.id,
        node: propData?.node ? propData?.node : propData?.phub,
        repairType: propData?.repairType,
        jobType: propData?.jobType,
        currentStatus: propData?.currentStatus,
        age: propData?.laFieldWorkCompleteDays,
        streetNumber: propData?.streetNumber,
        streetName: propData?.streetName,
        streetType: propData?.streetType,
        city: propData?.municipality,
        compass: propData?.compass,
        zoomAccuracy: propData?.googleAccuracy,
      } as TDTypes);
    });
  });

  const tableColumns = [
    { th: labels.Program, thStyle: { minWidth: 110 }, tdName: "program" },
    {
      th: labels.ProjectType,
      thStyle: { minWidth: 100 },
      tdName: "projectType",
    },
    { th: labels.ID, thStyle: { minWidth: 80 }, tdName: "id" },
    { th: labels.NodePHUB, tdName: "node" },
    { th: labels.RepairType, thStyle: { minWidth: 80 }, tdName: "repairType" },
    { th: labels.JobType, thStyle: { minWidth: 85 }, tdName: "jobType" },
    {
      th: labels.CurrentStatus,
      thStyle: { minWidth: 80 },
      tdName: "currentStatus",
    },
    { th: labels.Age, thStyle: { minWidth: 20 }, tdName: "age" },
    {
      th: labels.StreetNumber,
      thStyle: { minWidth: 80 },
      tdName: "streetNumber",
    },
    { th: labels.streetName, thStyle: { minWidth: 80 }, tdName: "streetName" },
    { th: labels.streetType, thStyle: { minWidth: 80 }, tdName: "streetType" },
    { th: labels.Compass, thStyle: { minWidth: 30 }, tdName: "compass" },
    { th: labels.city, thStyle: { minWidth: 80 }, tdName: "city" },
    {
      th: labels.ZoomAccuracy,
      thStyle: { minWidth: 80 },
      tdName: "zoomAccuracy",
    },
  ] as TCTypes[];

  const onMarkerPress = ({ id, orderKey }: any) => {
    if (id && orderKey) {
      window.open(
        `${NIKA_MANAGER_URL}${configData?.nikaManagerKey}&OrderID=${id}&OrderKey=${orderKey}`,
        "_blank",
        "noopener,noreferrer"
      );
    }
  };

  return (
    <>
      {tableData?.length !== 0 ? (
        <Table
          title={labels.NikaOrders}
          data={tableData}
          columns={tableColumns}
          tableStyle={
            tableStyle
              ? tableStyle
              : { maxWidth: TW, maxHeight: TH, overflow: "auto" }
          }
          newTabProps={{ compType: "NikaTableOrders", data, configData }}
          showExpandBtn={showExpandBtn}
        />
      ) : (
        <div className="diagErrorCont">
          <ExceptionBox
            headerText={labels.NoSearchHistory}
            bodyText={labels.PerformSearchToSeeTheResults}
          />
        </div>
      )}
    </>
  );
}
