import { openNewWindow, secToTime } from "../reusable/Util";
import { isNull } from "../reusable/Util";
import AppLabels from "../../constants/App_Labels";
import CustomAccordion from "../reusable/CustomAccordion";
import { IoWarning } from "react-icons/io5";

const CmNetworkInfo = (props: any) => {
  const { cmSummarydata, cmNodeInfo, cmNodeInfoError } = props;
  const labels = AppLabels();

  const openRtCMTSView = () => {
    openNewWindow(
      `/#/node/cmts?nodeId=${
        cmNodeInfo?.nodeId ? cmNodeInfo?.nodeId : "-"
      }&mac=${cmNodeInfo?.mac_domain ? cmNodeInfo?.mac_domain : "-"}&usPort=${
        cmNodeInfo?.port_group_name_normalized
          ? "Upstream " + cmNodeInfo?.port_group_name_normalized
          : "-"
      }&cmtsIp=${cmSummarydata?.cmtsIP ? cmSummarydata?.cmtsIP : "-"}`
    );
  };
  return (
    <CustomAccordion
      accTitle={labels.NETWORK_INFO.toUpperCase()}
      accBodyElement={
        <table className="dataTableDetails">
          <thead>
            <tr>
              <th id="nameCol">{labels.NAME.toUpperCase()}</th>
              <th id="valueCol">{labels.VALUE.toUpperCase()}</th>
            </tr>
          </thead>
          <tbody>
            {cmNodeInfo !== undefined &&
            !cmNodeInfo?.hasOwnProperty("errorCode") ? (
              <>
                <tr>
                  <td>{labels.NODE}</td>
                  <td>{isNull(cmNodeInfo.nodeId)}</td>
                </tr>
                <tr>
                  <td>{labels.NODE_ARCHITECTURE.toUpperCase()}</td>
                  <td>{isNull(cmNodeInfo.nodeType)}</td>
                </tr>
                <tr>
                  <td>{labels.VHUB}</td>
                  <td>
                    {cmNodeInfo?.hasOwnProperty("vhub")
                      ? isNull(cmNodeInfo.vhub.toString())
                      : "N/A"}
                  </td>
                </tr>
              </>
            ) : (
              <tr>
                <td className="inlineError" colSpan={4}>
                  ERROR:{" "}
                  {cmNodeInfoError !== undefined
                    ? isNull(cmNodeInfoError.errorMessage)
                    : "Node data is not Available"}
                  ...
                </td>
              </tr>
            )}
            {cmSummarydata !== undefined ? (
              <>
                <tr>
                  <td>{labels.CM_REGISTRATION_STATUS.toUpperCase()}</td>
                  <td>{isNull(cmSummarydata.cmtsCmOnlineStatus?.value)}</td>
                </tr>
                <tr>
                  <td>{labels.CMTS_IP.toUpperCase()}</td>
                  <td>
                    <span
                      className={
                        cmNodeInfo?.cmts_ip === cmSummarydata?.cmtsIP
                          ? "nodeLink"
                          : "centerFlexBox"
                      }
                      onClick={
                        cmNodeInfo?.cmts_ip === cmSummarydata?.cmtsIP
                          ? openRtCMTSView
                          : undefined
                      }
                    >
                      {isNull(cmSummarydata.cmtsIP)}{" "}
                      {cmNodeInfo?.cmts_ip &&
                        cmSummarydata?.cmtsIP &&
                        cmNodeInfo?.cmts_ip !== cmSummarydata?.cmtsIP && (
                          <span className="cmtsMismatchMSG">
                            <IoWarning size={16} />
                            {labels.cmtsMismatch}
                          </span>
                        )}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>{labels.CMTS_NAME.toUpperCase()}</td>
                  <td>{isNull(cmSummarydata.cmtsName)}</td>
                </tr>
                <tr>
                  <td>{labels.CMTS_UPTIME.toUpperCase()}</td>
                  <td>{isNull(secToTime(cmSummarydata.cmtsUptime))}</td>
                </tr>
              </>
            ) : (
              <tr>
                <td className="noDevices" colSpan={4}>
                  No Data Available...
                </td>
              </tr>
            )}
          </tbody>
        </table>
      }
    />
  );
};

export default CmNetworkInfo;
