import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";

import {
  getNikaOrder,
  setNikaFilters,
} from "../../../store/slices/mapItems/nika/nikaOrder";
import {
  GetGoogleMaps,
  GetMapDrawerProps,
  GetNikaOrderProps,
  GetTopoGeneralProps,
} from "../../../util/reduxFunctions/getTopologyState";
import appConst from "../../../constants/appConst";
import { isStringNumber } from "../../../util/utilFuncs";
import TopologyLabels from "../../../constants/topologyLabels";
import { NIKA_CONFIG } from "../../../config/mapItemsConfig";
import SelectionChip from "../../reusable/SelectionChip";
import "../../../styles/drawer/nika/Nika.scss";
import NumberInputStepper from "../../reusable/NumberInputStepper";
import { isNumeric } from "../../../util/utilFuncs";
import InlineErrorMessage from "../../../../components/reusable/InlineErrorMessage";
import NikaData from "../../../data/NikaData";
import ChipGroup from "../../../../components/reusable/ChipGroup";
import { hasLimitedTopologyFeatureView } from "../../../../components/reusable/RoleConfig";
export default function Filters() {
  const labels = TopologyLabels();
  const dispatch = useDispatch();
  const chipsInitialItems = NikaData();
  const { currentMapCenter } = GetGoogleMaps();
  const { nikaFilters } = GetNikaOrderProps();
  const { drawerView } = GetMapDrawerProps();
  const { activeNodeId } = GetTopoGeneralProps();
  const [radiusValue, setRadiusValue] = useState("1000");
  const [chipsItems, setChipsItems] = useState(
    getInitialFilters(nikaFilters, chipsInitialItems)
  );
  const [searchTypeIndex, setSearchTypeIndex] = useState(1);
  const [nodeId, setNodeId] = useState(activeNodeId);
  const [errorMsg, setErrorMsg] = useState("");
  const marginRight = drawerView === "fullScreen" ? 15 : 0;

  const searchTypeData = [
    { title: labels.node.toUpperCase(), description: labels.SearchbyNodeId },
    { title: labels.Radius.toUpperCase(), description: labels.SearchbyRadius },
  ];

  const getFilters = (data: Array<any>) => {
    let filters = [] as any;
    data.map((e) => e.checked && filters.push(e.key));
    return filters;
  };

  //get initial default filters list
  useEffect(() => {
    if (!nikaFilters) dispatch(setNikaFilters(getFilters(chipsInitialItems)));
  }, []);

  const onSearchPress = () => {
    if (
      searchTypeIndex === 1 &&
      (!isStringNumber(radiusValue) ||
        Number(radiusValue) < NIKA_CONFIG.minRadius ||
        Number(radiusValue) > NIKA_CONFIG.maxRadius)
    ) {
      setErrorMsg(
        `${labels.RadiusMustBeBetween} ${NIKA_CONFIG.minRadius} ${labels.to} ${NIKA_CONFIG.maxRadius} ${labels.meters}`
      );
    } else if (
      searchTypeIndex === 0 &&
      !activeNodeId &&
      (!isStringNumber(nodeId) || nodeId?.length !== 6)
    ) {
      setErrorMsg(`${labels.nodeIDError} `);
    } else {
      //use selected chip filters
      let filters = { filters: nikaFilters };
      if (filters.filters?.length < 1) {
        setErrorMsg(labels.YouMustSelectAtLeastOneType);
      } else {
        setErrorMsg("");

        const nodeParams = {
          ...filters,
          queryParams: {
            type: appConst.node,
            nodeId: activeNodeId ? activeNodeId : nodeId,
          },
        };
        const areaParams = {
          ...filters,
          queryParams: {
            type: appConst.area,
            centerLat: currentMapCenter?.latitude,
            centerLng: currentMapCenter?.longitude,
            radius: Number(radiusValue),
          },
        };
        if (searchTypeIndex === 0) dispatch(getNikaOrder(nodeParams));
        if (searchTypeIndex === 1) dispatch(getNikaOrder(areaParams));
      }
    }
  };

  const onIncPress = () => {
    const val = Number(radiusValue) + NIKA_CONFIG.incDecBy;
    setRadiusValue(String(val));
  };
  const onDecPress = () => {
    const val = Number(radiusValue) - NIKA_CONFIG.incDecBy;
    if (val > 0) setRadiusValue(String(val));
  };

  const onChipsPress = (checked: boolean, index: number) => {
    chipsItems[index].checked = checked;
    setChipsItems([...chipsItems]);
    dispatch(setNikaFilters(getFilters(chipsItems)));
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      onSearchPress();
    }
  };

  const isDisabled = hasLimitedTopologyFeatureView();

  return (
    <>
      <div className="nikaTypeSelectionCont">
        <ChipGroup
          chips={searchTypeData}
          activeChipIndex={searchTypeIndex}
          onIndexChange={setSearchTypeIndex}
        />
        <Button
          onClick={onSearchPress}
          className="stepperSubmitBtn"
          style={{ height: 30, borderRadius: 25 }}
        >
          {labels.GetOrders.toUpperCase()}
        </Button>
      </div>
      <div className="nikaFilterTabCont">
        {searchTypeIndex === 0 && !activeNodeId && (
          <div className="nikaSearchFilter">
            <input
              type="text"
              title={`${labels.node_ID}`}
              value={nodeId}
              placeholder={`${labels.type} ${labels.node_ID}...`}
              onChange={(val: any) => {
                setNodeId(val.target.value);
              }}
            />
          </div>
        )}
        <div className="radiusInputCont" style={{ marginRight }}>
          <label className="nikaRadiusLabel">
            {labels.RadiusIn} ({labels.M})
          </label>
          <NumberInputStepper
            inputValue={radiusValue}
            setInputValue={(v) => setRadiusValue(isNumeric(v.target.value))}
            placeholder={`${labels.RadiusInMeters}...`}
            onIncPress={onIncPress}
            onDecPress={onDecPress}
            minDec={NIKA_CONFIG.minRadius}
            maxInc={NIKA_CONFIG.maxRadius}
            onKeyPress={(e: any) => handleKeyPress(e)}
          />
        </div>
        <div className="selectionChipsCont" style={{ marginTop: 10 }}>
          {chipsItems?.map((e: any, i: number) => (
            ((e.key === "buriedDrop" && isDisabled) || (!isDisabled)) && (
              <SelectionChip
                key={e.key}
                style={{ marginRight: 10, marginBottom: 10 }}
                label={e.label}
                checked={e.checked}
                setChecked={(checked) => onChipsPress(checked, i)}
              />
            )
          ))}
        </div>
        <InlineErrorMessage errorMessage={errorMsg} />
      </div>
    </>
  );
}

const getInitialFilters = (filterKeys: any, data: any) => {
  if (filterKeys) {
    let filters = [] as any;
    data.map((e: any) =>
      filters.push({
        ...e,
        checked: filterKeys?.includes(e.key) ? true : false,
      })
    );
    return filters;
  } else return data;
};
