import { Card } from "react-bootstrap";
import { FiRefreshCw } from "react-icons/fi";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ONTClient from "../../network/httpclient/ont/ONTClient";
import DiagContainer from "../reusable/DiagContainer";
import ExceptionBox from "../reusable/ExceptionBox";
import { isNull } from "../reusable/Util";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const ONTAlarmsInfo = (props: any) => {
  const { selectedDevice } = props;
  const dispatch = useDispatch();
  const { ontAlarmLoading, ontAlarmsInfo, ontAlarmsError } = useSelector(
    (state: any) => ({
      ontAlarmLoading: state.AccountState.ONTAlarmsInfo.isLoading,
      ontAlarmsInfo: state.AccountState.ONTAlarmsInfo.data,
      ontAlarmsError: state.AccountState.ONTAlarmsInfo.error,
    }),
    shallowEqual
  );
  const refreshData = () => {
    dispatch(ONTClient.getONTAlarms(selectedDevice.serialNumber));
  };
  return (
    <>
      <div className="refreshCont">
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip-2">Refresh</Tooltip>}
        >
          <button className="refreshBtn" onClick={() => refreshData()}>
            <FiRefreshCw />
          </button>
        </OverlayTrigger>
      </div>
      <DiagContainer
        isLoading={ontAlarmLoading}
        data={ontAlarmsInfo}
        error={ontAlarmsError}
        handleRefresh={() => refreshData()}
      >
        {ontAlarmsInfo !== undefined ? (
          <div className="gwDevicesCont">
            <Card className="dataCard">
              <Card.Body>
                <table className="dataTableDetails">
                  {ontAlarmsInfo !== undefined &&
                  ontAlarmsInfo?.length !== 0 ? (
                    <>
                      <thead>
                        <th>NOTIFICATION ID</th>
                        <th>OS TIME</th>
                        <th>NE TIME</th>
                        <th>PROBABLE CAUSE</th>
                        <th>ADDITIONAL TEXT</th>
                        <th>PROPOSED REPAIR ACTION</th>
                      </thead>
                      <tbody>
                        {ontAlarmsInfo?.map((alarm: any, i: number) => {
                          return (
                            <tr>
                              <td>{isNull(alarm.notificationId)}</td>
                              <td>{isNull(alarm.osTime)}</td>
                              <td>{isNull(alarm.neTime)}</td>
                              <td>{isNull(alarm.probableCause)}</td>
                              <td>{isNull(alarm.additionalText)}</td>
                              <td>{isNull(alarm.proposedRepairAction)}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </>
                  ) : (
                    <div className="diagErrorCont">
                      <ExceptionBox
                        headerText="No Data to display"
                        bodyText="Please try refreshing if data is missing."
                        primaryBtn={true}
                        secBtn={false}
                        handleRefresh={() => refreshData()}
                      />
                    </div>
                  )}
                </table>
              </Card.Body>
            </Card>
          </div>
        ) : (
          <></>
        )}
      </DiagContainer>
    </>
  );
};

export default ONTAlarmsInfo;
