interface TableAttr {
  columnKey: string;
  columnName: string;
  jsonDataMapping: string;
  haveMultipleValues?: boolean;
  displayAttribute?: string;
  specInfo?: any;
  getDataFrom: string;
  isPinned: boolean;
  isVisible: boolean;
  position: number;
  allowedLoading?: boolean;
}

export const multiAttrList: TableAttr[] = [
  {
    columnKey: "accountNumber",
    columnName: "Account Number #",
    jsonDataMapping: "accountNumber",
    getDataFrom: "accountSummaryResponse",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "serviceAddress",
    columnName: "Home Address",
    jsonDataMapping: "serviceAddress",
    getDataFrom: "accountSummaryResponse",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "type",
    columnName: "Type",
    jsonDataMapping: "type",
    getDataFrom: "accountSummaryResponse",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "macAddress",
    columnName: "MAC Address",
    jsonDataMapping: "macAddress",
    getDataFrom: "accountSummaryResponse",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "model",
    columnName: "Model",
    jsonDataMapping: "model",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "smtId",
    columnName: "SMT",
    jsonDataMapping: "smtId",
    getDataFrom: "accountSummaryResponse",
    isPinned: false,
    isVisible: true,
    position: 0,
  },

  {
    columnKey: "uptime",
    columnName: " Uptime",
    jsonDataMapping: "uptime",
    getDataFrom: "regstatus",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "cmtsCmRegisteredTime",
    columnName: "Reg Time",
    jsonDataMapping: "cmtsUptime",
    getDataFrom: "regstatus",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "status",
    columnName: "Status",
    jsonDataMapping: "status",
    getDataFrom: "regstatus",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ip",
    columnName: "IP Address",
    jsonDataMapping: "ip",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
    allowedLoading: true,
  },
];

export const getMultiTeleMACViewConfig = () => {
  let configList: any = [];

  let tempViewAttr = multiAttrList;

  configList?.push({
    attributes: tempViewAttr,
  });

  return configList;
};
