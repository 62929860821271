import { useEffect, useState } from "react";
import { Form, DropdownButton, Dropdown } from "react-bootstrap";
import { Paper } from "@material-ui/core";
import { useDispatch } from "react-redux";
import "../../../design-tokens/rpd-page/RPDPage.scss";
import ErrorBox from "../../reusable/ErrorBox";
import RPDClient from "../../../network/httpclient/rpd/RPDClient";
import { TAF_ORDER_CONST } from "../../../constants/Global";
import appLabels from "../../../constants/App_Labels";
import {
  formatMacAddress,
  isMacAddressValid,
  formatRPDMacAddress,
  isRPDMacAddressValid,
  isValidSwVersion,
  getDeviceType,
} from "../../reusable/Util";
import { setMacAddress } from "../../../store/ducksPattern/rpd/rpdSwap";
import ChipGroup from "../../reusable/ChipGroup";
import {
  GetRPDOptionsList,
  GetRPDSwapInfo,
} from "../../../get-api/GetResponse";

const RPDSwapDetails = (props: any) => {
  const {
    oldMacAdd,
    oldDeviceType,
    nptId,
    rpdSwapData,
    rpdMacData,
    isLoading,
    techData,
  } = props;
  const labels = appLabels();
  const rpdSwapInfo = GetRPDSwapInfo();
  const [newMacAd, setNewMacAd] = useState("");
  const [isSwapLoading, setIsSwapLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("This is Error Message");
  const newDeviceList = ["DA2000", "DA2200"];
  const [newDevice, setNewDevice] = useState("DA2000");
  const [isNewMacValid, setIsNewMacValid] = useState(false);

  const [activeLobbyCameraCHipIndex, setActiveLobbyCameraCHipIndex] =
    useState(1);
  const [activeMdTypeCHipIndex, setActiveMdTypeCHipIndex] = useState(0);
  const mdTypeChips = [
    { title: labels.rpdMDType1, description: labels.rpdMDType1 },
    { title: labels.rpdMDType2, description: labels.rpdMDType2 },
  ];
  const lobbyCameraTypeChips = [
    { title: labels.rpdLobbyCamera1, description: labels.rpdLobbyCamera1 },
    { title: labels.rpdLobbyCamera2, description: labels.rpdLobbyCamera2 },
  ];

  const dispatch = useDispatch();

  const rpdOptionsList = GetRPDOptionsList();

  const [qamProfileList, setQamProfileList] = useState([""]);
  const [qamProfile, setQAMProfile] = useState("");

  const [technologyList, setTechnologyList] = useState([""]);
  const [technologyDevice, setTechnologyDevice] = useState("");

  useEffect(() => {
    const techfilteredOptions = rpdOptionsList.data.filter(
      (o: { type: string }) => o.type === "TECH"
    );

    const techList: string[] = [];
    for (let i = 0; i < techfilteredOptions.length; i++) {
      const temp = techfilteredOptions[i];
      techList.push(temp.label);
    }
    if (techList.length > 0) setTechnologyDevice(techList[0]);
    setTechnologyList(techList);

    const profilefilteredOptions = rpdOptionsList.data.filter(
      (o: { type: string }) => o.type === "PROFILE"
    );

    const profileList: string[] = [];
    for (let i = 0; i < profilefilteredOptions.length; i++) {
      const temp = profilefilteredOptions[i];
      profileList.push(temp.label);
    }
    if (profileList.length > 0) setQAMProfile(profileList[0]);
    setQamProfileList(profileList);
  }, []);

  const createInputCrossSwapObject = (
    oldMac: string,
    newMac: string,
    nptId: string,
    oldDeviceType: string,
    newDeviceType: string,
    profile: string,
    tech: string,
    mdtype: string,
    lobbyCam: string,
    rtn: number[],
    macDom: string,
    portno: string,
    hostnm: string,
    interfc: string,
    accessLeafName: string,
    emails: string
  ): any => {
    let inputObject = {
      "Old-RPD-MAC": oldMac,
      "New-RPD-MAC": newMac,
      RelatedID: nptId,
      old_mac_device_type: oldDeviceType,
      new_mac_device_type: newDeviceType,
      "QAM PROFILE": profile,
      TECHNOLOGY: tech,
      "MD-Type": mdtype,
      "LOBBY-CAMERA": lobbyCam,
      RTN: rtn,
      macDomain: macDom,
      port: portno,
      hostname: hostnm,
      interface: interfc,
      hostname_lfa: accessLeafName,
      is_npt_id: "0",
      "MAIL-ID": emails,
    };
    return inputObject;
  };

  const createInputSwapObject = (
    oldMac: string,
    newMac: string,
    nptId: string,
    email: string,
    interfaceId: string,
    hostname: string,
    oldDeviceType: string,
    newDeviceType: string
  ): any => {
    let inputObject = {
      Input_1: oldMac,
      Input_2: newMac,
      RelatedID: nptId,
      "MAIL-ID": email,
      interface: interfaceId,
      hostname_lfa: hostname,
      old_mac_device_type: oldDeviceType,
      new_mac_device_type: newDeviceType,
    };
    return inputObject;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleApplySwap(newMacAd, nptId);
    }
  };

  const handleApplySwap = (newMacAd: string, nptId: string) => {
    if (isRPDMacAddressValid(newMacAd)) {
      let stringMacAdd = formatRPDMacAddress(newMacAd);
      let oldMac = formatRPDMacAddress(rpdMacData?.macAddress);

      let oldrpdtype = "DA2000";
      if (oldDeviceType === "casa-rpd-200") oldrpdtype = "DA2200";
      else oldrpdtype = "DA2000";

      if (oldrpdtype === newDevice)
        handleSwap(oldMac, newMacAd, nptId, oldrpdtype, newDevice);
      else handleCrossSwap(oldMac, newMacAd, nptId, oldrpdtype, newDevice);
    } else {
      setOpen(true);
      setError(labels.newMacAddressEmptyError);
    }
  };

  const handleSwap = (
    oldMacAdd: string,
    newMacAd: string,
    nptId: string,
    olddevice: string,
    newdevice: string
  ) => {
    if (newMacAd?.length === 12) {
      if (nptId?.length > 0) {
        let stringMacAdd = formatRPDMacAddress(newMacAd);

        dispatch(
          RPDClient.createSwap(
            TAF_ORDER_CONST.rpdSwapBotName,
            TAF_ORDER_CONST.rpdSwapSuiteName,
            createInputSwapObject(
              oldMacAdd,
              stringMacAdd,
              nptId,
              rpdSwapInfo?.emails,
              rpdSwapInfo?.macRPDData?.accessLeafPort,
              rpdSwapInfo?.macRPDData?.accessLeaf,
              olddevice,
              newdevice
            )
          )
        );
        dispatch(setMacAddress(undefined));
      } else {
        setOpen(true);
        setError(labels.nptIdImtIdEmptyError);
      }
    } else {
      setOpen(true);
      setError(labels.newMacAddressEmptyError);
    }
  };

  const handleCrossSwap = (
    oldMacAdd: string,
    newMacAd: string,
    nptId: string,
    olddevice: string,
    newdevice: string
  ) => {
    if (newMacAd?.length === 12) {
      if (nptId?.length > 0) {
        let stringMacAdd = formatRPDMacAddress(newMacAd);

        let rtn: Array<number> = [];
        if (rpdSwapInfo?.macRPDData?.ReturnSegment_1 != null) {
          let a: number = +rpdSwapInfo?.macRPDData?.ReturnSegment_1?.trim();
          rtn.push(a);
        }

        if (rpdSwapInfo?.macRPDData?.ReturnSegment_2 != null) {
          let a: number = +rpdSwapInfo?.macRPDData?.ReturnSegment_2?.trim();
          rtn.push(a);
        }
        let lobbycam: string = "N";
        let mdtype: string = "BDR";

        if (activeLobbyCameraCHipIndex == 0) lobbycam = "Y";
        else lobbycam = "N";

        if (activeMdTypeCHipIndex == 0) mdtype = "BDR";
        else mdtype = "Non-BDR";

        dispatch(
          RPDClient.createCrossSwap(
            TAF_ORDER_CONST.rpdCrossSwapBotName,
            TAF_ORDER_CONST.rpdCrossSwapSuiteName,
            createInputCrossSwapObject(
              oldMacAdd,
              stringMacAdd,
              nptId,
              olddevice,
              newdevice,
              qamProfile,
              technologyDevice,
              mdtype,
              lobbycam,
              rtn,
              rpdSwapInfo?.macRPDData?.macDomain,
              rpdSwapInfo?.macRPDData?.cmtsPort,
              rpdSwapInfo?.macRPDData?.cmtsName,
              rpdSwapInfo?.macRPDData?.accessLeafPort,
              rpdSwapInfo?.macRPDData?.accessLeaf,
              rpdSwapInfo?.emails
            )
          )
        );
        dispatch(setMacAddress(undefined));
      } else {
        setOpen(true);
        setError(labels.nptIdImtIdEmptyError);
      }
    } else {
      setOpen(true);
      setError(labels.newMacAddressEmptyError);
    }
  };

  const onNewMacAdSubmit = (e: any) => {
    let sv = formatMacAddress(e.target.value);
    if (sv?.length <= 12) {
      setIsNewMacValid(true);
      setNewMacAd(sv);
    } else {
      setIsNewMacValid(false);
    }
  };

  const DropdownMenu = ({ label, data, value, onSelect }: any) => (
    <div className="deviceTypeCont">
      <Form.Label className="rpdFormLabel">{label}</Form.Label>
      <DropdownButton
        title={value}
        onSelect={(v: any) => onSelect(v)}
        size="sm"
        className="deviceTypeDropd"
        id="deviceTypeDd"
      >
        {data.map((e: any, i: number) => (
          <Dropdown.Item key={i} eventKey={e}>
            {e}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );

  return (
    <>
      {!isSwapLoading && (
        <>
          <div className="certiTableCont">
            <Paper className="deviceTablePaper">
              <div className="filterContainer">
                <div className="activationInputContainer">
                  <div className="filterRow">
                    <div className="cardContentContainer">
                      <span className="cardContentLabel">
                        {labels.MacAddress}
                      </span>
                      <br />

                      <span className="cardContentText">
                        {rpdSwapInfo?.macRPDData?.macAddress}
                      </span>
                    </div>
                    <div className="cardContentContainer">
                      <span className="cardContentLabel">
                        {labels.clamshellIDTxt}
                      </span>
                      <br />
                      <span className="cardContentText">
                        {rpdSwapInfo?.macRPDData?.clamShell}
                      </span>
                    </div>
                    <div className="cardContentContainer">
                      <span className="cardContentLabel">
                        {labels.AccessLeaf}
                      </span>
                      <br />
                      <span className="cardContentText">
                        {rpdSwapInfo?.macRPDData?.accessLeaf}
                      </span>
                    </div>
                    <div className="cardContentContainer">
                      <span className="cardContentLabel">
                        {labels.AccessLeafPort}
                      </span>
                      <br />
                      <span className="cardContentText">
                        {rpdSwapInfo?.macRPDData?.accessLeafPort}
                      </span>
                    </div>
                    <div className="cardContentContainer">
                      <span className="cardContentLabel">
                        {labels.ServiceLeaf}
                      </span>
                      <br />
                      <span className="cardContentText">
                        {rpdSwapInfo?.macRPDData?.serviceLeaf}
                      </span>
                    </div>
                    <div className="cardContentContainer">
                      <span className="cardContentLabel">
                        {labels.ServiceLeafPort}
                      </span>
                      <br />
                      <span className="cardContentText">
                        {rpdSwapInfo?.macRPDData?.serviceLeafPort}
                      </span>
                    </div>
                    <div className="cardContentContainer">
                      <span className="cardContentLabel">
                        {labels.CMTSName}
                      </span>
                      <br />
                      <span className="cardContentText">
                        {rpdSwapInfo?.macRPDData?.cmtsName}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="activationInputContainer">
                  <div className="filterRow">
                    <div className="cardContentContainer">
                      <span className="cardContentLabel">
                        {labels.CMTSPort}
                      </span>
                      <br />
                      <span className="cardContentText">
                        {rpdSwapInfo?.macRPDData?.cmtsPort}
                      </span>
                    </div>
                    <div className="cardContentContainer">
                      <span className="cardContentLabel">
                        {labels.MacDomain}
                      </span>
                      <br />
                      <span className="cardContentText">
                        {rpdSwapInfo?.macRPDData?.macDomain}
                      </span>
                    </div>
                    <div className="cardContentContainer">
                      <span className="cardContentLabel">
                        {labels.deviceType}
                      </span>
                      <br />
                      {/* <span className="cardContentText">{oldDeviceType}</span> */}
                      <span className="cardContentText">
                        {getDeviceType(rpdSwapInfo?.macRPDData?.DeviceType)}
                      </span>
                    </div>
                    <div className="phubContainer">
                      <span className="cardContentLabel">{labels.PHUB}</span>
                      <br />
                      <span className="cardContentText">
                        {rpdSwapInfo?.macRPDData?.PHUB}
                      </span>
                    </div>
                    {rpdSwapInfo?.macRPDData?.running_sw_image_name === "" ||
                    rpdSwapInfo?.macRPDData?.running_sw_image_name ===
                      undefined ||
                    rpdSwapInfo?.macRPDData?.running_sw_image_name === null ? (
                      <div className="swVersionContainer">
                        <span className="cardContentLabel">
                          {labels.rpdSwVersion}
                        </span>
                        <br />
                        <span className="cardContentText">CMTS connection failed</span>
                      </div>
                    ) : (
                      <div className="swVersionContainer">
                        <span className="cardContentLabel">
                          {labels.rpdSwVersion}
                        </span>
                        <br />
                        <span className="cardContentText">
                          {rpdSwapInfo?.macRPDData?.running_sw_image_name}
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <>
                  <div className="subDataCard">
                    <span className="titleLabel">
                      {labels.returnSegmentDetails}
                    </span>
                  </div>
                  <div className="cardNodeInputContainer">
                    <div className="cardContentContainer">
                      <span className="cardContentLabel">
                        {labels.nodeIDOne}
                      </span>
                      <br />
                      {rpdSwapInfo?.macRPDData?.ReturnSegment_1 == null ? (
                        <span className="cardContentText">N/A</span>
                      ) : (
                        <span className="cardContentText">
                          {rpdSwapInfo?.macRPDData?.ReturnSegment_1}
                        </span>
                      )}
                    </div>
                    <div className="cardContentContainer">
                      <span className="cardContentLabel">
                        {labels.nodeIDTwo}
                      </span>
                      <br />
                      {rpdSwapInfo?.macRPDData?.ReturnSegment_2 == null ? (
                        <span className="cardContentText">N/A</span>
                      ) : (
                        <span className="cardContentText">
                          {rpdSwapInfo?.macRPDData?.ReturnSegment_2}
                        </span>
                      )}
                    </div>
                  </div>
                </>

                <div className="activationInputContainer">
                  <div className="filterRow">
                    <div className="inputContainer">
                      <Form.Group>
                        <Form.Label className="rpdFormLabel">
                          {labels.newRPDMacAddress}
                        </Form.Label>
                        <Form.Control
                          disabled={false}
                          name="rpdInputField"
                          onChange={onNewMacAdSubmit}
                          className="rpdInputField"
                          id="rpdInputField"
                          value={newMacAd}
                          placeholder={labels.typeNewRPDMacAddress}
                        />
                      </Form.Group>
                    </div>
                    <div className="inputContainer">
                      <Form.Group>
                        <DropdownMenu
                          label={labels.deviceType}
                          data={newDeviceList}
                          value={newDevice}
                          onSelect={setNewDevice}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <div className="activationInputContainer">
                  {(oldDeviceType === "casa-rpd-100"
                    ? "DA2000"
                    : oldDeviceType === "casa-rpd-200"
                    ? "DA2200"
                    : "") !== newDevice &&
                    (newDevice === "DA2200" ? (
                      <div className="filterRow">
                        <div className="inputContainer">
                          <Form.Group>
                            <DropdownMenu
                              label={labels.qamProfile}
                              data={qamProfileList}
                              value={qamProfile}
                              onSelect={setQAMProfile}
                            />
                          </Form.Group>
                        </div>

                        <div className="inputContainer">
                          <Form.Group>
                            <DropdownMenu
                              label={labels.rpdTechnology}
                              data={technologyList}
                              value={technologyDevice}
                              onSelect={setTechnologyDevice}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    ) : (
                      <div className="filterRow">
                        <div className="inputContainer">
                          <div className="boxTitleContainer">
                            <span>{labels.rpdLobbyCamera}</span>
                          </div>
                          <div className="boxTitleContainer">
                            <ChipGroup
                              chips={lobbyCameraTypeChips}
                              activeChipIndex={activeLobbyCameraCHipIndex}
                              onIndexChange={setActiveLobbyCameraCHipIndex}
                            />
                          </div>
                        </div>
                        <div className="inputContainer">
                          <div className="boxTitleContainer">
                            <span>{labels.rpdMDType}</span>
                          </div>
                          <div className="boxTitleContainer">
                            <ChipGroup
                              chips={mdTypeChips}
                              activeChipIndex={activeMdTypeCHipIndex}
                              onIndexChange={setActiveMdTypeCHipIndex}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>

                <div className="activationInputContainer">
                  <div className="filterRow">
                    <button
                      disabled={
                        !(
                          isNewMacValid &&
                          (oldDeviceType === "casa-rpd-200" ||
                            oldDeviceType === "casa-rpd-100")
                        )
                      }
                      className="applyFilersBtn"
                      onClick={() => handleApplySwap(newMacAd, nptId)}
                    >
                      {labels.SUBMIT}
                    </button>
                  </div>
                  <br />
                </div>
              </div>
              <br />
            </Paper>
          </div>
          <ErrorBox
            open={open}
            error={error}
            setOpen={setOpen}
            handleClose={handleClose}
          />
        </>
      )}
    </>
  );
};

export default RPDSwapDetails;
