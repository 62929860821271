import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../middleware/apiActions";
import {
  CREATE_COMMENT_URI,
  GET_COMMENTS_URI,
  UPDATE_DELETE_COMMENT_URI,
} from "../../../middleware/apiEndpoints";
import storeConst from "../../../storeConst";

const slice = createSlice({
  name: "comments",
  initialState: {
    isLoading: false,
    eleComments: {},
    crudIsLoading: false,
    crudData: undefined,
    crudError: undefined,
    crudStatus: "",
  },
  reducers: {
    getEleCommentsBegin: (elementComments, { payload }) => {
      elementComments.isLoading = true;
      const comments: any = elementComments?.eleComments;
      if (!comments?.[payload.networkId]) {
        elementComments.eleComments = {
          ...elementComments.eleComments,
          [payload.networkId]: {
            isLoading: true,
            data: undefined,
            error: undefined,
            status: "",
          },
        };
      }
    },
    getEleCommentsSuccess: (elementComments, { payload }) => {
      elementComments.isLoading = false;
      const netwId = payload.networkId;
      elementComments.eleComments = {
        ...elementComments.eleComments,
        ...{
          [netwId]: {
            isLoading: false,
            data: payload?.data,
            error: undefined,
            status: storeConst.OK,
          },
        },
      };
    },

    getEleCommentsFailure: (elementComments, { payload }) => {
      elementComments.isLoading = false;
      const netwId = payload.networkId;
      elementComments.eleComments = {
        ...elementComments.eleComments,
        ...{
          [netwId]: {
            isLoading: false,
            data: undefined,
            error: payload?.data,
            status: storeConst.ERROR,
          },
        },
      };
    },
    crudCommentsBegin: (crudComments) => {
      crudComments.crudIsLoading = true;
      crudComments.crudStatus = "";
    },
    crudCommentsSuccess: (crudComments, { payload }) => {
      crudComments.crudIsLoading = false;
      crudComments.crudData = payload;
      crudComments.crudError = undefined;
      crudComments.crudStatus = storeConst.OK;
    },
    crudCommentsFailure: (crudComments, { payload }) => {
      crudComments.crudIsLoading = false;
      crudComments.crudError = payload;
      crudComments.crudData = undefined;
      crudComments.crudStatus = storeConst.ERROR;
    },
    resetCrudData: (crudComments) => {
      crudComments.crudIsLoading = false;
      crudComments.crudStatus = "";
      crudComments.crudData = undefined;
      crudComments.crudError = undefined;
    },
  },
});

export const { resetCrudData } = slice.actions;
//should not be exposed to outside
const {
  getEleCommentsBegin,
  getEleCommentsSuccess,
  getEleCommentsFailure,
  crudCommentsBegin,
  crudCommentsSuccess,
  crudCommentsFailure,
} = slice.actions;
export default slice.reducer;

export const getComments =
  (networkId: any): any =>
  (dispatch: any) => {
    if (networkId) {
      dispatch(
        apiCallBegan({
          url: GET_COMMENTS_URI(networkId),
          method: "get",
          onStart: getEleCommentsBegin.type,
          onSuccess: getEleCommentsSuccess.type,
          onError: getEleCommentsFailure.type,
          props: { networkId },
        })
      );
    }
  };

export const createComment =
  (networkId: any, props: any) => (dispatch: any) => {
    if (networkId) {
      dispatch(
        apiCallBegan({
          url: CREATE_COMMENT_URI(networkId),
          data: props,
          method: "post",
          onStart: crudCommentsBegin.type,
          onSuccess: crudCommentsSuccess.type,
          onError: crudCommentsFailure.type,
        })
      );
    }
  };

export const updateDeleteComment =
  (networkId: any, commentId: any, props: any) => (dispatch: any) => {
    if (networkId) {
      dispatch(
        apiCallBegan({
          url: UPDATE_DELETE_COMMENT_URI(networkId, commentId),
          data: props,
          method: "post",
          onStart: crudCommentsBegin.type,
          onSuccess: crudCommentsSuccess.type,
          onError: crudCommentsFailure.type,
        })
      );
    }
  };
