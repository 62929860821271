import { Snackbar } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const MultiResponseSnackBar = (props: any) => {
  const { open, responseType, passedDevices, failedDevices, handleClose } =
    props;
  const renderAlert = () => {
    if (responseType === "OK") {
      return (
        <Alert onClose={handleClose} severity="success">
          Zap request successful for all STBs.
        </Alert>
      );
    } else if (responseType === "ERROR") {
      return (
        <Alert onClose={handleClose} severity="error">
          Zap request failed for all STBs.
        </Alert>
      );
    } else if (responseType === "INFO") {
      return (
        <Alert className="infoBanner" onClose={handleClose} severity="info">
          <p>
            Zap request successful for:
            {passedDevices?.map((d: any) => {
              return <span> {d}</span>;
            })}
          </p>
          <p>
            Zap request failed for:
            {failedDevices?.map((d: any) => {
              return <span> {d}</span>;
            })}
          </p>
        </Alert>
      );
    }
  };

  return (
    <div className="snackBarCont">
      <Snackbar
        className="snackBar"
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        {renderAlert()}
      </Snackbar>
    </div>
  );
};

export default MultiResponseSnackBar;
