import AppLabels from "../../../constants/App_Labels";
// in this component we applied inline CSS due to export purpose
//please do not follow the same in other components as its not a good practice
interface Props {
  woID: string;
  woType: string;
  workDetails: any;
}

const DisplayEmailPreview = (props: Props) => {
  const labels = AppLabels() as any;

  return (
    <div
      style={{
        display: "inline-block",
        fontWeight: 500,
        fontFamily: "Ted Next",
      }}
    >
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {labels.Work_Order_ID}:
        </span>
        <span>{props.woID}</span>
      </div>
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {labels.Order_Type}:
        </span>
        <span>{props.woType}</span>
      </div>
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {labels.Work_Required}:
        </span>
        <ul>
          {props?.workDetails?.workRequired?.map((e: any, i: number) => (
            <li key={i} style={{ maxWidth: "500px" }}>
              <span>{labels?.[e]}</span>
            </li>
          ))}
        </ul>
      </div>
      {props?.workDetails?.summaryWorkRequired != "" && (
        <div
          style={{
            marginBottom: "10px",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              marginRight: "10px",
            }}
          >
            {labels.Work_Summary}:
          </span>
          <span style={{ wordBreak: "break-word" }}>
            {props?.workDetails?.summaryWorkRequired}
          </span>
        </div>
      )}
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {labels.Work_Approved}:
        </span>
        <span>
          {props?.workDetails?.workApprobation === true
            ? labels.Yes
            : labels.No}
        </span>
      </div>
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {labels.Authorized_Signature}:
        </span>
        <div>
          <img
            src={props?.workDetails?.signature}
            style={{ height: "150px", width: "400px" }}
            alt="signature"
          />
        </div>
      </div>
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {labels.Copy_of_this_Email_Sent_to}:
        </span>
        <ul>
          {props?.workDetails?.emails?.map((e: any, i: number) => (
            <li key={i}>{e}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DisplayEmailPreview;
