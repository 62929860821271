import { InfoBox } from "@react-google-maps/api";
import colors from "../../../../config/colors";
import React from "react";
import { zoomLevelRenderMarker } from "../../../../config/mapConfig";

interface Props {
  mduCADTextData?: any;
  zoomLevel?: any;
}
const MduCADText = React.memo(({ mduCADTextData, zoomLevel }: Props) => {
  return (
    <>
      {zoomLevel >= zoomLevelRenderMarker &&
        mduCADTextData !== undefined &&
        mduCADTextData?.features?.map((mduCADText: any, index: any) => {
          const latLng = new google.maps.LatLng(
            mduCADText?.geometry?.coordinates?.[1],
            mduCADText?.geometry?.coordinates?.[0]
          );
          return (
            <div key={index}>
              <InfoBox
                position={latLng}
                options={{
                  closeBoxURL: "",
                  disableAutoPan: true,
                  boxStyle: {
                    width: "auto",
                  },
                  pixelOffset: new google.maps.Size(5, -11),
                }}
              >
                <div
                  style={{
                    fontSize: 13,
                    color: colors.greyMedium,
                  }}
                >
                  {mduCADText?.properties?.data?.wdsText}
                </div>
              </InfoBox>
            </div>
          );
        })}
    </>
  );
});

export default MduCADText;
