import React from "react";
import { Form } from "react-bootstrap";
import { Checkbox, Divider } from "@material-ui/core";
import { useEffect, useImperativeHandle, useState } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";

import AppLabels from "../../constants/App_Labels";
import "../../design-tokens/workorder/WorkOrderPopUp.scss";
import { getByKey } from "../reusable/Util";
import { codesList, codesObject, emptyCode } from "./WODropdownConfig";

const CompletionFormData = React.forwardRef((props: any, ref) => {
  const labels = AppLabels();
  // const [file, setFile] = useState<any[] | null[]>([]);
  const [codesObj, setCodesObj] = useState<any>(codesObject);
  const [isOpen, setIsOpen] = useState(false);
  const {
    faultCode,
    networkCode,
    activityCode,
    causeCode,
    preventionCode,
    additCode,
    comment,
  } = codesObj;

  const {
    fCodesList,
    nCodesList,
    aCodesList,
    pCodesList,
    addCodesList,
    cCodesList,
  } = codesList();

  let errorTable: any[] = [
    faultCode,
    networkCode,
    activityCode,
    causeCode,
    preventionCode,
    ...additCode,
  ];

  const wo = props?.woData;

  useEffect(() => {
    props?.setCompletedForm(codesObj);
    if (errorTable?.find((e: any) => e?.key === "")) {
      props?.setDisabled(true);
    } else {
      props?.setDisabled(false);
    }
  }, [codesObj]);

  // Clean the selections on the form
  useImperativeHandle(ref, () => {
    return {
      cleanArray: cleanArray,
    };
  });
  const cleanArray = () => {
    setCodesObj(codesObject);
  };

  // To keep the multiselect dropdown open after selection
  const onToggleHandler = (isOpen: any, e: any, metadata: any) => {
    if (metadata.source !== "select") {
      setIsOpen(isOpen);
    }
  };

  // Delete respective row from the table
  const handleDelete = (code: any) => {
    switch (code?.key?.charAt(0)) {
      case "1":
        setCodesObj((codesObj: any) => ({
          ...codesObj,
          faultCode: emptyCode,
        }));
        break;
      case "2":
      case "6":
        setCodesObj((codesObj: any) => ({
          ...codesObj,
          networkCode: emptyCode,
        }));
        break;
      case "3":
        setCodesObj((codesObj: any) => ({
          ...codesObj,
          activityCode: emptyCode,
        }));
        break;
      case "4":
        setCodesObj((codesObj: any) => ({
          ...codesObj,
          causeCode: emptyCode,
        }));
        break;
      case "5":
        setCodesObj((codesObj: any) => ({
          ...codesObj,
          preventionCode: emptyCode,
        }));
        break;
      case "R":
      case "N":
      case "Y":
      case "O":
        const newAdditCode = additCode?.filter(
          (obj: any) => obj?.key !== code?.key
        );
        setCodesObj((codesObj: any) => ({
          ...codesObj,
          additCode: newAdditCode,
        }));
        break;
    }
  };

  // Add to Additional Codes Array
  const onSelectAddt = (e: any) => {
    const code = getByKey(addCodesList, e);
    if (!additCode.length) {
      setCodesObj((codesObj: any) => ({
        ...codesObj,
        additCode: [code],
      }));
    } else {
      if (!additCode.find((item: any) => item?.key === code?.key)) {
        setCodesObj((codesObj: any) => ({
          ...codesObj,
          additCode: [...additCode, code],
        }));
      }
    }
  };

  return (
    <Form className="form" onSubmit={props.handleSubmit}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          width: "70%",
          marginLeft: "10px",
        }}
      >
        <p
          id="headerP"
          style={{
            marginLeft: "20px",
          }}
        >
          {labels.Completion_Codes}
        </p>
        <div
          className="WODetailRow"
          style={{
            justifyContent: "flex-start",
          }}
        >
          <div className="WODetailColumnInfo">
            <span>
              <label>{labels.Fault_Code}</label>
              <span className="mandatoryAst">*</span>
            </span>
            <DropdownButton
              title={faultCode?.key}
              onSelect={(e: any) => {
                setCodesObj((codesObj: any) => ({
                  ...codesObj,
                  faultCode: getByKey(fCodesList, e),
                }));
              }}
              size="sm"
              className="ddButton"
              id="preventionCode"
            >
              {fCodesList
                ?.filter((obj: any) => obj?.key !== "")
                ?.map((fault: any, i: number) => {
                  return (
                    <Dropdown.Item key={i * 78 * i + 37} eventKey={fault?.key}>
                      {fault?.key + " - " + fault?.shortDesc}
                    </Dropdown.Item>
                  );
                })}
            </DropdownButton>
          </div>
          <div className="WODetailColumnInfo">
            <span>
              <label>{labels.Network_Code}</label>
              <span className="mandatoryAst">*</span>
            </span>
            <DropdownButton
              title={networkCode?.key}
              onSelect={(e: any) => {
                setCodesObj((codesObj: any) => ({
                  ...codesObj,
                  networkCode: getByKey(nCodesList, e),
                }));
              }}
              size="sm"
              className="ddButton"
              id="preventionCode"
            >
              {nCodesList
                ?.filter((obj: any) => obj?.key !== "")
                ?.map((network: any, i: number) => {
                  return (
                    <Dropdown.Item
                      key={i * 25 * i + 37}
                      eventKey={network?.key}
                    >
                      {network?.key + " - " + network?.shortDesc}
                    </Dropdown.Item>
                  );
                })}
            </DropdownButton>
          </div>
          <div className="WODetailColumnInfo">
            <span>
              <label>{labels.Activity_Code}</label>
              <span className="mandatoryAst">*</span>
            </span>
            <DropdownButton
              title={activityCode?.key}
              onSelect={(e: any) =>
                setCodesObj((codesObj: any) => ({
                  ...codesObj,
                  activityCode: getByKey(aCodesList, e),
                }))
              }
              size="sm"
              className="ddButton"
              id="activityCode"
            >
              {aCodesList
                ?.filter((obj: any) => obj?.key !== "")
                ?.map((activity: any, i: number) => {
                  return (
                    <Dropdown.Item
                      key={i * 32 * i + 37}
                      eventKey={activity?.key}
                    >
                      {activity?.key + " - " + activity?.shortDesc}
                    </Dropdown.Item>
                  );
                })}
            </DropdownButton>
          </div>
        </div>
        <div
          className="WODetailRow"
          style={{
            justifyContent: "flex-start",
          }}
        >
          <div className="WODetailColumnInfo">
            <span>
              <label>{labels.Cause_Code}</label>
              <span className="mandatoryAst">*</span>
            </span>
            <DropdownButton
              title={causeCode?.key}
              onSelect={(e: any) =>
                setCodesObj((codesObj: any) => ({
                  ...codesObj,
                  causeCode: getByKey(cCodesList, e),
                }))
              }
              size="sm"
              className="ddButton"
              id="causeCode"
            >
              {cCodesList
                ?.filter((obj: any) => obj?.key !== "")
                ?.map((cause: any, i: number) => {
                  return (
                    <Dropdown.Item key={i * 32 * i + 37} eventKey={cause?.key}>
                      {cause?.key + " - " + cause?.shortDesc}
                    </Dropdown.Item>
                  );
                })}
            </DropdownButton>
          </div>
          <div className="WODetailColumnInfo">
            <span>
              <label>{labels.Prevention_Code}</label>
              <span className="mandatoryAst">*</span>
            </span>
            <DropdownButton
              title={preventionCode?.key}
              onSelect={(e: any) =>
                setCodesObj((codesObj: any) => ({
                  ...codesObj,
                  preventionCode: getByKey(pCodesList, e),
                }))
              }
              size="sm"
              className="ddButton"
              id="preventionCode"
            >
              {pCodesList
                ?.filter((obj: any) => obj?.key !== "")
                ?.map((prevention: any, i: number) => {
                  return (
                    <Dropdown.Item
                      key={i * 32 * i + 37}
                      eventKey={prevention?.key}
                    >
                      {prevention?.key + " - " + prevention?.shortDesc}
                    </Dropdown.Item>
                  );
                })}
            </DropdownButton>
          </div>
          <div className="WODetailColumnInfo">
            <label>{labels.Additional_Codes}</label>
            <DropdownButton
              show={isOpen}
              onToggle={(isOpen, e, metadata) =>
                onToggleHandler(isOpen, e, metadata)
              }
              title={
                !additCode.length
                  ? ""
                  : additCode
                    ?.map((a: any) => a?.key)
                    ?.sort((a: any, b: any) => (a > b ? 1 : -1))
                    ?.join(", ")
              }
              onSelect={(e: any) => onSelectAddt(e)}
              size="sm"
              className="ddButton"
              id="dropdown-autoclose-inside"
            >
              {addCodesList
                ?.filter((obj: any) => obj?.key !== "")
                ?.map((additional: any, i: number) => {
                  return (
                    <Dropdown.Item
                      key={i * 32 * i + 37}
                      eventKey={additional?.key}
                      className="addtDropDown"
                    >
                      <Checkbox
                        checked={
                          additCode.find(
                            (item: any) => item?.key === additional?.key
                          ) !== undefined
                        }
                      />
                      {additional?.key + " - " + additional?.shortDesc}
                    </Dropdown.Item>
                  );
                })}
            </DropdownButton>
          </div>
        </div>
        <div className="WODetailRow">
          <div className="WODetailColumnInfo">
            <p id="headerP">Code Table</p>
            <table className="dataTableDetails">
              <thead>
                <tr>
                  <th>{labels.Name}</th>
                  <th>{labels.Description}</th>
                </tr>
              </thead>
              <tbody>
                {errorTable
                  ?.sort((a: any, b: any) => (a?.key > b?.key ? 1 : -1))
                  ?.filter((obj: any) => obj?.key !== "")
                  ?.map((code: any, index: number) => {
                    return (
                      code !== undefined &&
                      code?.key !== "" && (
                        <tr key={index * index - 786}>
                          <td>{code?.key + " - " + code?.shortDesc}</td>
                          <td className="errorTableRow">
                            <span className="tableText">
                              {code?.description}
                            </span>
                            <span
                              onClick={() => handleDelete(code)}
                              className="trashIconSpan"
                            >
                              <FaTrash />
                            </span>
                          </td>
                        </tr>
                      )
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        {/* // TODO: Enable file attachment once the backend change is done
        <div className="WODetailRow">
          <div className="WODetailColumnInfo" style={{ width: "100%" }}>
            <Form.Group className="attachment-group">
              <Form.Label id="attachLabel">{labels.ATTACH}</Form.Label>
              <BrowseFiles setFile={setFile} file={file} />
              <AttachedFiles file={file} setFile={setFile} />
            </Form.Group>
          </div>
        </div> */}
        <div className="WODetailRow">
          <div className="WODetailColumnInfo" style={{ width: "100%" }}>
            <Form.Group className="selector">
              <label className="titleLabel">
                {labels.COMMENTS}: (200 {labels.charsMax})
              </label>
              <Form.Control
                onChange={(e) =>
                  setCodesObj((codesObj: any) => ({
                    ...codesObj,
                    comment: e?.target?.value,
                  }))
                }
                as="textarea"
                rows={2}
                maxLength={200}
                value={comment}
                placeholder={labels.TYPE + " " + labels.COMMENTS + "..."}
              />
            </Form.Group>
          </div>
        </div>
      </div>
    </Form>
  );
});

export default CompletionFormData;
