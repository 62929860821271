import { Accordion, Card } from "react-bootstrap";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import CellDetailTable from "./CellDetailTable";

const CellDetails = (props: any) => {
  const {
    towerDetails,
    ecgi,
    accordionValue,
    accordionIndex,
    handleAccordionToggle,
  } = props;
  return (
    <Card className="dataCard">
      <Accordion.Toggle
        as={Card.Header}
        eventKey="3"
        onClick={() => handleAccordionToggle(3, accordionValue)}
      >
        <span>CELL DETAILS</span>
        {accordionValue === 3 && accordionIndex === 3 ? (
          <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
        ) : (
          <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
        )}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="3">
        <Card.Body>
          <CellDetailTable towerDetails={towerDetails} ecgi={ecgi} />
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default CellDetails;
