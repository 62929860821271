import React from "react";
import { useEffect } from "react";

interface Props {
  gMap?: google.maps.Map;
  mduRFCableData?: any;
}

const MduRFCable = React.memo(({ gMap, mduRFCableData }: Props) => {
  useEffect(() => {
    if (mduRFCableData !== undefined && mduRFCableData?.features?.length > 0) {
      gMap?.data.addGeoJson(mduRFCableData);
    }
  }, [gMap, mduRFCableData]);
  return <></>;
});

export default MduRFCable;
