import { Marker } from "@react-google-maps/api";
import { HoverOverInter } from "../../MapItems";
import {
  GetTechInfo,
  GetWorkOrdersList,
} from "../../../../../get-api/GetResponse";
import { mapElementStyles } from "../../../../config/mapItemsConfig";
import { useDispatch } from "react-redux";
import { setSelectedMapElement } from "../../../../store/slices/mapItems/mapDetails";
import appConst from "../../../../constants/appConst";
import { useEffect } from "react";
import WorkOrdersClient from "../../../../../network/httpclient/workorders/WorkOrdersClient";
import { getAccessLevelByRoles } from "../../../../../components/reusable/Util";
import ConfigConst from "../../../../../constants/ConfigConst";
import { renderToString } from "react-dom/server";
import TopologySymbols from "../../../reusable/TopologySymbols";
import colors from "../../../../config/colors";

interface Props {
  setHoverOverProps: (arg0: HoverOverInter | null) => void;
  isVisible: boolean;
}

export default function WorkOrdersMarkers({
  setHoverOverProps,
  isVisible,
}: Props) {
  const dispatch = useDispatch();
  const workOrdersList = GetWorkOrdersList()?.data;
  const techData = GetTechInfo()?.data;
  const setHoverOverData = (element: any) => {
    const comp = () => (
      <>
        {element?.address && (
          <div>
            <span style={{ fontWeight: 600 }}>Address: </span>
            <span>{element?.address}</span>
          </div>
        )}
        {element?.specialInstructions && (
          <div>
            <span style={{ fontWeight: 600 }}>Special Instructions: </span>
            <span>{element?.specialInstructions}</span>
          </div>
        )}
      </>
    );
    setHoverOverProps({
      position: {
        lat: element?.latitude,
        lng: element?.longitude,
      },
      compToRender: comp(),
      options: { pixelOffset: new google.maps.Size(1, -12) },
    });
  };
  const onMarkerPress = (data: any) => {
    const activeElement = {
      geometry: {
        lat: data?.latitude,
        lng: data?.longitude,
      },
      properties: {
        id: data?.id,
        type: appConst?.workOrders,
        data: data,
      },
    };
    dispatch(setSelectedMapElement(activeElement));
  };

  useEffect(() => {
    if (techData !== undefined && techData?.hasOwnProperty("name")) {
      dispatch(
        WorkOrdersClient.getTechWorkOrders(
          techData?.techId,
          techData?.name,
          getAccessLevelByRoles(techData?.roles)?.includes(ConfigConst.MT)
        )
      );
    }
  }, [techData]);
  return (
    <>
      {isVisible &&
        workOrdersList?.length !== undefined &&
        workOrdersList?.length !== 0 &&
        workOrdersList?.map((workOrder: any) => {
          const markerLat = workOrder?.latitude;
          const markerLng = workOrder?.longitude;

          return (
            <Marker
              key={workOrder?.id}
              onMouseOver={() => setHoverOverData(workOrder)}
              onMouseOut={() => setHoverOverProps(null)}
              position={{
                lat: markerLat,
                lng: markerLng,
              }}
              icon={{
                url:
                  appConst.imageStringPrefix +
                  encodeURIComponent(
                    renderToString(
                      <TopologySymbols
                        name="workOrderTruck"
                        size={37}
                        color={colors.peru}
                      />
                    )
                  ),
                anchor: new google.maps.Point(
                  mapElementStyles?.common?.anchor?.x,
                  mapElementStyles?.common?.anchor?.y
                ),
              }}
              onClick={() => {
                onMarkerPress(workOrder);
              }}
            />
          );
        })}
    </>
  );
}
