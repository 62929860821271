import * as React from "react";
import { FaRegPlayCircle, FaRegStopCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import {
  getMultiNodeBasicInfoByDeviceType,
  getNodeTeleGloabalStatus,
} from "../../components/reusable/NodeUtil";
import AppLabels from "../../constants/App_Labels";
import ConfigConst from "../../constants/ConfigConst";
import {
  getMultiDeviceNodeViewConfig,
  multiDeviceDataSetType,
} from "../../constants/MultiDeviceNodeConfig";
import {
  GetNodeBasicInfoDetails,
  GetNodeInfoDetails,
  GetTelemetryDetails,
} from "../../get-api/GetResponse";
import NodeInfoDetailsClient from "../../network/httpclient/nodeinfo/NodeInfoDetailsClient";
import { SetActiveNodeInfoDetails } from "../../store/actions/node/nodeinfo/ActiveNodeInfoAction";
import { saveConfigData } from "../../store/ducksPattern/userPrefConfig";
import { TELE_ABORT_KEY } from "../../topology/store/middleware/abortKeys";
import { addAbortKey } from "../../topology/store/middleware/apiClient";
import {
  getTeleBasicModemsList,
  setAllDiagLoading,
  setDiagType,
  setIsCWEnabled,
  setModemType,
  setViewType,
  stopDiagExecution,
} from "../../topology/store/slices/mapItems/diagnostics/nodeBasicTelemetry";
import storeConst from "../../topology/store/storeConst";
import { MultiDeviceNodeBanner } from "../multi-device-node-page/MultiDeviceNodeBanner";
import { MultiDeviceNodeTab } from "../multi-device-node-page/MultiDeviceNodeTab";

export const MultiDeviceNodePage = (props: any) => {
  const { location: search } = props;
  const dispatch = useDispatch();
  const labels = AppLabels();
  const searchParams = new URLSearchParams(search.search);
  const nodeId = searchParams.get("nodeId");
  const smtId = searchParams.get("smtId");
  const deviceType = searchParams.get("dataSet");

  const { ducksConfigData } = GetTelemetryDetails();
  const { nodedata, currNodedata } = GetNodeInfoDetails();
  const {
    allBasicDiagIsLoading,
    allDiagBasicStatus,
    isBasicAborted,
    mlBasicData,
    viewBasicType,
    diagBasicStartTime,
    diagBasicEndTime,
  } = GetNodeBasicInfoDetails();
  const nodeTeleStatus = getNodeTeleGloabalStatus(
    allDiagBasicStatus,
    allBasicDiagIsLoading,
    isBasicAborted
  );
  const [dataSetName, setDataSetName] = React.useState("");
  const [bannerInfo, setBannerInfo] = React.useState<any>({
    nodeId: currNodedata?.[0]?.nodeId
      ? currNodedata?.[0]?.nodeId
      : nodeId
      ? nodeId
      : "",
    smtId: currNodedata?.[0]?.smtId
      ? currNodedata?.[0]?.smtId
      : smtId
      ? smtId
      : "",
    isSmt: currNodedata?.[0]?.smt
      ? currNodedata?.[0]?.smt
      : smtId
      ? true
      : false,
    phub: currNodedata?.[0]?.phub || nodedata?.[0]?.phub || "",
    modemListInfo: "",
    deviceType: deviceType?.toUpperCase() ? deviceType?.toUpperCase() : "",
    active: "",
    assigned: "",
    responsive: "",
    unresponsive: "",
    startTime: "",
    endTime: "",
  });
  const [expand, setExpand] = React.useState(true);
  const [tableAttributes, setTableAttributes] = React.useState<any>([]);

  let dataSetNames: any = multiDeviceDataSetType?.map((e: any) => {
    return e?.dataSetName;
  });

  const handleToggle = () => {
    setExpand((prev) => !prev);
  };
  React.useEffect(() => {
    let viewConfig = getMultiDeviceNodeViewConfig();
    dispatch(saveConfigData(viewConfig));
  }, []);
  const runTelemetry = () => {
    addAbortKey(TELE_ABORT_KEY);
    setBannerInfo({
      nodeId: currNodedata?.[0]?.nodeId
        ? currNodedata?.[0]?.nodeId
        : nodeId
        ? nodeId
        : "",
      deviceType: deviceType?.toUpperCase() ? deviceType?.toUpperCase() : "",
      smtId: currNodedata?.[0]?.smtId
        ? currNodedata?.[0]?.smtId
        : smtId
        ? smtId
        : "",
      isSmt: currNodedata?.[0]?.smt
        ? currNodedata?.[0]?.smt
        : smtId
        ? true
        : false,
      phub: currNodedata?.[0]?.phub || nodedata?.[0]?.phub || "",
      active: undefined,
      assigned: undefined,
      responsive: undefined,
      unresponsive: undefined,
      startTime: undefined,
      endTime: undefined,
    });
    if (
      nodeTeleStatus === ConfigConst.complete.toLowerCase() ||
      nodeTeleStatus === ConfigConst.stopped.toLowerCase()
    ) {
      const activeId = currNodedata?.[0]?.smt
        ? currNodedata?.[0]?.smtId
        : smtId
        ? smtId
        : currNodedata?.[0]?.nodeId
        ? currNodedata?.[0]?.nodeId
        : nodeId;
      const type = currNodedata?.[0]?.smt ? "smtid" : "shubid";
      dispatch(setIsCWEnabled(false));
      dispatch(
        getTeleBasicModemsList({
          value: activeId,
          type: type,
          diagnoseDevices: true,
          modemType:
            deviceType !== undefined && deviceType != null
              ? deviceType
              : undefined,
        })
      );
    } else {
      dispatch(setAllDiagLoading(true));
      const activeId = currNodedata?.[0]?.smt
        ? currNodedata?.[0]?.smtId
        : smtId
        ? smtId
        : currNodedata?.[0]?.nodeId
        ? currNodedata?.[0]?.nodeId
        : nodeId;
      const type = currNodedata?.[0]?.smt ? "smtid" : "shubid";
      dispatch(
        getTeleBasicModemsList({
          value: activeId,
          type: type,
          diagnoseDevices: true,
          modemType:
            deviceType !== undefined && deviceType != null
              ? deviceType
              : undefined,
        })
      );
    }
  };

  const stopTelemetry = () => {
    dispatch(stopDiagExecution());
  };

  React.useLayoutEffect(() => {
    // remove the navbar from the DOM
    const navbar = document.getElementsByClassName("drawerCloseSec")[0];
    const header = document.getElementsByClassName(
      "headerSec"
    )[0] as HTMLElement;
    const main = document.getElementsByClassName(
      "main-section"
    )[0] as HTMLElement;
    if (navbar && header && main) {
      // remove the navbar from the DOM completely and adjust the header and main sections margin-left to 0
      if (header?.firstChild) {
        const headerFirstChild = header?.firstChild as HTMLElement;
        headerFirstChild.style.width = "100%";
      }
      header.style.marginLeft = "auto";
      main.style.marginLeft = "auto";
      navbar.remove();
      dispatch(setIsCWEnabled(false));
      dispatch(setModemType(deviceType));
    }
    if (search) {
      const dataSetType = searchParams.get("dataSet");
      const viewTypeParam = searchParams.get("viewType");
      dispatch(setIsCWEnabled(false));
      if (dataSetType && dataSetType !== "") {
        dispatch(setDiagType(dataSetType?.toLowerCase()?.trim()));
        setDataSetName(dataSetNames[0]);
      } else {
        dispatch(setDiagType(storeConst.all_no_cw));
        setDataSetName(dataSetNames[0]);
      }
      if (viewTypeParam && viewTypeParam !== "") {
        dispatch(setViewType(viewTypeParam?.toUpperCase()?.trim()));
      }
    }
  }, []);

  React.useEffect(() => {
    if (viewBasicType !== undefined && viewBasicType !== "") {
      ducksConfigData?.userPreferences?.node?.telemetry?.views?.map(
        (v: any) => {
          setTableAttributes(v?.attributes);
        }
      );
    }
  }, [viewBasicType]);

  React.useEffect(() => {
    if (
      mlBasicData?.length > 0 &&
      nodeTeleStatus === ConfigConst.complete &&
      !isBasicAborted
    ) {
      const modemListInfo = getMultiNodeBasicInfoByDeviceType(mlBasicData);

      setBannerInfo({
        nodeId: currNodedata?.[0]?.nodeId ? currNodedata?.[0]?.nodeId : nodeId,
        smtId: currNodedata?.[0]?.smtId ? currNodedata?.[0]?.smtId : smtId,
        isSmt: currNodedata?.[0]?.smt
          ? currNodedata?.[0]?.smt
          : smtId
          ? true
          : false,
        phub: currNodedata?.[0]?.phub || nodedata?.[0]?.phub,
        deviceType: deviceType?.toUpperCase() ? deviceType?.toUpperCase() : "",
        modemListInfo: modemListInfo,
        active: modemListInfo?.active,
        assigned: modemListInfo?.assigned,
        responsive: modemListInfo?.responsive,
        unresponsive: modemListInfo?.unresponsive,
        startTime: diagBasicStartTime,
        endTime: diagBasicEndTime,
      });
    } else if (allBasicDiagIsLoading) {
      setBannerInfo({
        nodeId: currNodedata?.[0]?.nodeId ? currNodedata?.[0]?.nodeId : nodeId,
        smtId: currNodedata?.[0]?.smtId ? currNodedata?.[0]?.smtId : smtId,
        isSmt: currNodedata?.[0]?.smt
          ? currNodedata?.[0]?.smt
          : smtId
          ? true
          : false,
        deviceType: deviceType?.toUpperCase() ? deviceType?.toUpperCase() : "",
        modemListInfo: "",
        phub: currNodedata?.[0]?.phub || nodedata?.[0]?.phub || "",
        active: undefined,
        assigned: undefined,
        responsive: undefined,
        unresponsive: undefined,
        startTime: undefined,
        endTime: undefined,
      });
    }
  }, [mlBasicData]);
  React.useEffect(() => {
    if (smtId || nodeId)
      dispatch(
        NodeInfoDetailsClient.getInfoNodeDetails(
          nodeId ? nodeId : smtId ? smtId : ""
        )
      );
  }, []);
  React.useEffect(() => {
    if (smtId || nodeId) {
      dispatch(SetActiveNodeInfoDetails(nodedata));
    }
  }, [nodedata]);
  React.useEffect(() => {
    if (currNodedata !== undefined) runTelemetry();
  }, [currNodedata]);
  return (
    <div>
      {
        <div className="emtaLandingPage">
          <div className="gponBannerContainer">
            <MultiDeviceNodeBanner data={bannerInfo} expand={expand} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
                marginLeft: "auto",
                marginRight: "2%",
              }}
            >
              {/* <span
                style={{
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                {labels.DATA_SET?.toUpperCase()}
              </span>
              &nbsp;
              <CustomDropDown
                selectedOption={dataSetName}
                options={dataSetNames}
                searchFlag={false}
                checkboxFlag={false}
                setSelectedOption={(value: any) => {
                  setDataSetName(value);
                  //dispatch(SetTeleDatasetType(value));
                  dispatch(setDiagType(value === storeConst.summary));
                }}
              /> */}
              <div style={{ padding: "0px 20px" }}>
                {mlBasicData !== undefined && mlBasicData?.length !== 0 && (
                  <>
                    {!allBasicDiagIsLoading ? (
                      <button
                        className="refreshCertiBtn"
                        onClick={runTelemetry}
                      >
                        {labels.START?.toUpperCase()} <FaRegPlayCircle />
                      </button>
                    ) : (
                      <>
                        <button
                          className="refreshCertiBtn"
                          onClick={() => stopTelemetry()}
                        >
                          {labels.STOP?.toUpperCase()} <FaRegStopCircle />
                        </button>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>

            {/* <div className="gponBannerIconsContainer">
              <div
                className="gponCollapseContainer"
                style={{
                  rotate: expand ? "0deg" : "180deg",
                }}
              >
                <IoIosArrowDropup
                  color="black"
                  size={25}
                  onClick={handleToggle}
                />
              </div>
            </div> */}
          </div>
          <div className="gponBodyContainer" style={{ padding: "4px" }}>
            <MultiDeviceNodeTab
              tableAttr={tableAttributes}
              data={mlBasicData}
              dataSetType={multiDeviceDataSetType}
              dataSetNames={dataSetNames}
              nodeId={
                currNodedata?.[0]?.nodeId ? currNodedata?.[0]?.nodeId : nodeId
              }
              exportDeviceId={nodeId ? nodeId : smtId}
            />
          </div>
        </div>
      }
    </div>
  );
};
