import AppLabels from "../../constants/App_Labels";
import ASTBDetails from "../astb/ASTBDetails";
import CMInternetInfo from "../cable-modem/CMInternetInfo";
import CMSpeedTestInfo from "../cable-modem/CMSpeedTestInfo";
import CmNetworkInfo from "../cable-modem/CmNetworkInfo";
import CMSummary from "../cable-modem/CmSummary";
import IHPDeviceInfo from "../homephone/IHPDeviceInfo";
import RHPdocsisInfo from "../homephone/RHPdocsisInfo";
import IPTVDeviceInfo from "../iptv/IPTVDeviceInfo";
import IPTVEthernetInfo from "../iptv/IPTVEthernetInfo";
import IPTVNetworkInfo from "../iptv/IPTVNetworkInfo";
import IPTVWIFIInfo from "../iptv/IPTVWIFIInfo";
import ODUDetails from "../odu/ODUDetails";
import ODUWirelessInfo from "../odu/ODUWirelessInfo";
import ONTDetails from "../ont/ONTDetails";
import STBDeviceInfo from "../stb/STBDeviceInfo";
import STBDocsisInfo from "../stb/STBDocsisInfo";
import CertiExceptionCont from "./CertiExceptionCont";
import CmDSPrechChannel from "../cable-modem/precheck/CmDSPrechChannel";
import CmUSPrechChannel from "../cable-modem/precheck/CmUSPrechChannel";

const ViewCertiDiagDetails = (props: any) => {
  const {
    selectedDevice,
    precheckDevice,
    selectedSA,
    seleDeviceDiag,
    speedTestData,
  } = props;
  const labels = AppLabels();
  let stbDevices = ["STB", "DESTB", "GSTB", "HSTB", "IPSTB"];

  const precheckSelectedDevice = precheckDevice;
  const precheckSeleDeviceDiag = precheckSelectedDevice?.diagResult;
  // Double check this before the release
  return (
    <div className="cmSummaryCont">
      {selectedDevice.deviceCategory === "CM" ? (
        <>
          <CertiExceptionCont
            dataObj={seleDeviceDiag?.summary}
            sectionName="CM Summary"
            errorDeviceSkip={labels.errorDeviceSkip}
          >
            <CMInternetInfo
              selectedSA={selectedSA}
              docsisLoading={false}
              cmDocsisInfo={seleDeviceDiag?.docsis}
            />
          </CertiExceptionCont>
          <CMSpeedTestInfo
            macAddress={selectedDevice?.macAddress}
            speedTestData={speedTestData}
            isViewCert={true}
          />
          <CertiExceptionCont
            dataObj={seleDeviceDiag?.summary}
            sectionName="CM Summary"
            errorDeviceSkip={labels.errorDeviceSkip}
          >
            <CmNetworkInfo
              cmSummarydata={seleDeviceDiag?.summary}
              cmNodeInfo={seleDeviceDiag?.nodeInfo}
            />
          </CertiExceptionCont>
          <CertiExceptionCont
            dataObj={seleDeviceDiag?.docsis}
            sectionName="CM Docsis"
            errorDeviceSkip={labels.errorDeviceSkip}
          >
            <CmDSPrechChannel
              cmDocsisInfo={seleDeviceDiag?.docsis}
              preCmDocsisInfo={precheckSeleDeviceDiag?.docsis}
            />
            <CmUSPrechChannel
              cmDocsisInfo={seleDeviceDiag?.docsis}
              preCmDocsisInfo={precheckSeleDeviceDiag?.docsis}
            />
          </CertiExceptionCont>
          <CertiExceptionCont
            dataObj={seleDeviceDiag?.summary}
            sectionName="CM Summary"
            errorDeviceSkip={labels.errorDeviceSkip}
          >
            <CMSummary cmSummarydata={seleDeviceDiag?.summary} />
          </CertiExceptionCont>
        </>
      ) : selectedDevice.deviceCategory === "IPTV" ? (
        <>
          <CertiExceptionCont
            dataObj={seleDeviceDiag?.network}
            sectionName="IPTV Network Info"
            errorDeviceSkip={labels.errorDeviceSkip}
          >
            <IPTVNetworkInfo networkData={seleDeviceDiag?.network} />
          </CertiExceptionCont>

          {seleDeviceDiag?.hasOwnProperty("ethernet") ? (
            <CertiExceptionCont
              dataObj={seleDeviceDiag?.ethernet}
              sectionName="IPTV Ethernet"
              errorDeviceSkip={labels.errorDeviceSkip}
            >
              <IPTVEthernetInfo ethernetData={seleDeviceDiag?.ethernet} />
            </CertiExceptionCont>
          ) : seleDeviceDiag?.hasOwnProperty("wifi") ? (
            <CertiExceptionCont
              dataObj={seleDeviceDiag?.wifi}
              sectionName="IPTV WiFi"
              errorDeviceSkip={labels.errorDeviceSkip}
            >
              <IPTVWIFIInfo wifiData={seleDeviceDiag?.wifi} />
            </CertiExceptionCont>
          ) : (
            <></>
          )}
          <CertiExceptionCont
            dataObj={seleDeviceDiag?.device}
            sectionName="IPTV Summary"
            errorDeviceSkip={labels.errorDeviceSkip}
          >
            <IPTVDeviceInfo deviceData={seleDeviceDiag?.device} />
          </CertiExceptionCont>
        </>
      ) : selectedDevice.deviceCategory === "IEMTA" ? (
        <CertiExceptionCont
          dataObj={seleDeviceDiag?.summary}
          sectionName="EMTA Summary"
          errorDeviceSkip={labels.errorDeviceSkip}
        >
          <IHPDeviceInfo summaryData={seleDeviceDiag?.summary} />
        </CertiExceptionCont>
      ) : selectedDevice.deviceCategory === "EMTA" ? (
        <>
          <CertiExceptionCont
            dataObj={seleDeviceDiag?.docsis}
            sectionName="EMTA Docsis"
            errorDeviceSkip={labels.errorDeviceSkip}
          >
            <RHPdocsisInfo
              docsisInfo={seleDeviceDiag?.docsis}
              docsisError={undefined}
            />
          </CertiExceptionCont>
          <CertiExceptionCont
            dataObj={seleDeviceDiag?.summary}
            sectionName="EMTA Summary"
            errorDeviceSkip={labels.errorDeviceSkip}
          >
            <IHPDeviceInfo summaryData={seleDeviceDiag?.summary} />
          </CertiExceptionCont>
        </>
      ) : selectedDevice.deviceCategory === "ONT" ? (
        <CertiExceptionCont
          dataObj={seleDeviceDiag?.summary}
          sectionName="ONT Summary"
          errorDeviceSkip={labels.errorDeviceSkip}
        >
          <ONTDetails deviceData={seleDeviceDiag?.summary} />
        </CertiExceptionCont>
      ) : selectedDevice.deviceCategory === "ODU" ? (
        <>
          <CertiExceptionCont
            dataObj={seleDeviceDiag?.summary}
            sectionName="ODU Summary"
            errorDeviceSkip={labels.errorDeviceSkip}
          >
            <ODUDetails
              selDevice={selectedDevice}
              deviceData={seleDeviceDiag?.summary}
            />
          </CertiExceptionCont>
          <CertiExceptionCont
            dataObj={seleDeviceDiag?.wirelessInfo}
            sectionName="ODU Wireless Info"
            errorDeviceSkip={labels.errorDeviceSkip}
          >
            <ODUWirelessInfo deviceData={seleDeviceDiag?.wirelessInfo} />
          </CertiExceptionCont>
        </>
      ) : stbDevices?.indexOf(selectedDevice.deviceCategory) > -1 ? (
        <>
          <CertiExceptionCont
            dataObj={seleDeviceDiag?.summary}
            sectionName="STB Summary"
            errorDeviceSkip={labels.errorDeviceSkip}
          >
            <STBDeviceInfo deviceData={seleDeviceDiag?.summary} />
          </CertiExceptionCont>
          <CertiExceptionCont
            dataObj={seleDeviceDiag?.docsis}
            sectionName="STB Docsis"
            errorDeviceSkip={labels.errorDeviceSkip}
          >
            <STBDocsisInfo deviceData={seleDeviceDiag?.docsis} />
          </CertiExceptionCont>
        </>
      ) : selectedDevice.deviceCategory === "ASTB" ? (
        <CertiExceptionCont
          dataObj={seleDeviceDiag?.summary}
          sectionName="ASTB Summary"
          errorDeviceSkip={labels.errorDeviceSkip}
        >
          <ASTBDetails deviceData={seleDeviceDiag?.summary} />
        </CertiExceptionCont>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ViewCertiDiagDetails;
