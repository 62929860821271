import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import ExceptionBox from "../reusable/ExceptionBox";
import { GponTelemetryTable } from "../../screens/gpon-page/components/GponTelemetryTable";
import { GetCMTSIPDetails } from "../../get-api/GetResponse";

const CMTSEventLogs = (props: any) => {
  const { cmtsIPDetailsData, cmtsIPDetailsLoading } = GetCMTSIPDetails();
  return (
    <>
      {cmtsIPDetailsData !== undefined ? (
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {cmtsIPDetailsData?.length > 0 ? (
              <div
                style={{
                  borderRadius: "5px",
                  boxShadow: "rgb(0 0 0) 0px 2px 6px -1px",
                }}
              >
                <GponTelemetryTable
                  tableHeader={[
                    {
                      title: "First Time",
                      attribute: "firstOccurTime",
                      disableSearch: false,
                    },
                    {
                      title: "Last Time",
                      attribute: "lastOccurTime",
                      disableSearch: false,
                    },
                    {
                      title: "Count",
                      attribute: "noOfOccurance",
                      disableSearch: false,
                      maxWidth: "80px",
                    },
                    {
                      title: "Level",
                      attribute: "level",
                      disableSearch: false,
                      maxWidth: "80px",
                    },
                    {
                      title: "Description",
                      attribute: "description",
                      disableSearch: false,
                    },
                  ]}
                  tableData={
                    cmtsIPDetailsData?.length > 0 ? cmtsIPDetailsData : []
                  }
                />
              </div>
            ) : (
              <div className="diagErrorCont">
                <ExceptionBox
                  headerText="CMTS EVENT LOGS FOR THIS IP IS NOT AVAILABLE"
                  bodyText="Once you search a node it will reflect here."
                  primaryBtn={false}
                  secBtn={false}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="diagErrorCont">
          <ExceptionBox
            headerText="CMTS EVENT LOGS FOR THIS IP IS NOT AVAILABLE"
            bodyText="Once you search a node it will reflect here."
            primaryBtn={false}
            secBtn={false}
          />
        </div>
      )}
    </>
  );
};

export default CMTSEventLogs;
