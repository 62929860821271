import appConst from "../../topology/constants/appConst";
import { formatCamelCase } from "./Util";
import CustomAMETableVal from "../../topology/components/drawer/CustomAMETableVal";

interface props {
  tableConfig: any;
  eleData: any;
}

const MapEleDataDisplay = ({ tableConfig, eleData }: props) => {
  return (
    <table className="dataTableDetails">
      <thead>
        <tr>
          {tableConfig?.hasOwnProperty("headers") &&
          tableConfig?.headers?.length !== 0 ? (
            tableConfig?.headers?.map((h: any, i: number) => {
              return (
                <td
                  key={i}
                  className={
                    tableConfig?.headers?.length <= 2 ? "fixedCol" : ""
                  }
                >
                  {h?.toUpperCase()}
                </td>
              );
            })
          ) : (
            <></>
          )}
        </tr>
      </thead>
      <tbody>
        {Object.keys(tableConfig?.dataKeys)?.map((key: any, i: number) => {
          return (
            <tr key={i}>
              <td>
                {tableConfig?.name === appConst?.cpat
                  ? key
                  : formatCamelCase(key)}
              </td>
              <td>
                <CustomAMETableVal
                  eleData={eleData}
                  attrKey={tableConfig?.dataKeys[key]}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default MapEleDataDisplay;
