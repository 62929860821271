import React, { CSSProperties } from "react";

interface Props {
  errorMessage: string;
  style?: CSSProperties;
}
export default function InlineErrorMessage(props: Props) {
  return (
    <>
      {props.errorMessage !== "" ? (
        <span style={props.style} className="errorMsg">
          {props.errorMessage}
        </span>
      ) : null}
    </>
  );
}
