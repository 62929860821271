import { createSlice } from "@reduxjs/toolkit";
import { changeTimeFormat } from "../../../components/reusable/Util";
import { apiCallBegan } from "../../../topology/store/middleware/apiActions";

import {
  NETWORK_SERVICE_URL,
  TRACEROUTE,
} from "../../../topology/store/middleware/apiEndpoints";

import storeConst from "../../../topology/store/storeConst";

const slice = createSlice({
  name: "traceRouteData",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
    startTime: undefined,
    endTime: undefined,
  },
  reducers: {
    getStreamTraceRouteBegin: (tl) => {
      let startTime: any = changeTimeFormat(Date.now());
      tl.status = "";
      tl.error = undefined;
      tl.startTime = startTime;
      tl.isLoading = true;
    },
    getStreamTraceRouteSuccess: (tl, { payload }) => {
      let endTime: any = changeTimeFormat(Date.now());
      tl.isLoading = false;
      tl.endTime = endTime;
      tl.data = payload;
      tl.status = storeConst.OK;
      tl.error = undefined;
    },
    getStreamTraceRouteFailure: (tl, { payload }) => {
      let endTime: any = changeTimeFormat(Date.now());
      tl.isLoading = false;
      tl.endTime = endTime;
      tl.status = storeConst.ERROR;
      tl.error = payload;
    },
  },
});

export const {
  getStreamTraceRouteBegin,
  getStreamTraceRouteSuccess,
  getStreamTraceRouteFailure,
} = slice.actions;

export default slice.reducer;

export const getStreamTraceRouteData = (ipAddress: any) => (dispatch: any) => {
  dispatch(
    apiCallBegan({
      baseURL: NETWORK_SERVICE_URL + "diag/network",
      url: TRACEROUTE(ipAddress),
      method: "get",
      data: JSON.stringify({
        ipAddress: ipAddress,
      }),
      responseType: "stream",
      onStart: getStreamTraceRouteBegin.type,
      onSuccess: getStreamTraceRouteSuccess.type,
      onError: getStreamTraceRouteFailure.type,
    })
  );
};
