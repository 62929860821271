import { Accordion, Card } from "react-bootstrap";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { isNull } from "../../reusable/Util";
const WSubMobility = (props: any) => {
  const {
    subscriberData,
    accordionValue,
    accordionIndex,
    handleAccordionToggle,
  } = props;
  return (
    <Card className="dataCard">
      <Accordion.Toggle
        as={Card.Header}
        eventKey="1"
        onClick={() => handleAccordionToggle(1, accordionValue)}
      >
        <span>MOBILITY</span>
        {accordionValue === 1 && accordionIndex === 1 ? (
          <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
        ) : (
          <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
        )}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="1">
        <Card.Body>
          <table className="dataTableDetails">
            <thead>
              <tr>
                <th id="nameCol">NAME</th>
                <th id="valueCol">VALUE</th>
              </tr>
            </thead>
            {subscriberData?.hasOwnProperty("mobility") ? (
              <tbody>
                <tr>
                  <td>ROAMING STATUS</td>
                  <td>{isNull(subscriberData.mobility.roamingStatus)}</td>
                </tr>
                <tr>
                  <td>SUSPEND STATUS</td>
                  <td>{isNull(subscriberData.mobility.suspendStatus)}</td>
                </tr>
                <tr>
                  <td>RADIO TECHNOLOGY</td>
                  <td>{isNull(subscriberData.mobility.radioTechnology)}</td>
                </tr>
                <tr>
                  <td>MGMT STATE</td>
                  <td>{isNull(subscriberData.mobility.mgmtState)}</td>
                </tr>
                <tr>
                  <td>TRACKING AREA</td>
                  <td>{isNull(subscriberData.mobility.trackingArea)}</td>
                </tr>
                <tr>
                  <td>PAST TRACKING AREA</td>
                  <td>{isNull(subscriberData.mobility.pastTrackingArea)}</td>
                </tr>
                <tr>
                  <td>ENB ID</td>
                  <td>{isNull(subscriberData.mobility.eNBId)}</td>
                </tr>
                <tr>
                  <td>ENB NAME</td>
                  <td>{isNull(subscriberData.mobility.eNBName)}</td>
                </tr>
                <tr>
                  <td>PAST ENB LIST</td>
                  <td>{isNull(subscriberData.mobility.pastENBList)}</td>
                </tr>
                <tr>
                  <td>ECGI</td>
                  <td>{isNull(subscriberData.mobility.ecgi)}</td>
                </tr>
              </tbody>
            ) : (
              <>No Data Available</>
            )}
          </table>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default WSubMobility;
