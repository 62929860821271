import { useState } from "react";
import { TableRow, TableHead } from "@material-ui/core";
import { FaSortDown, FaSortUp, FaSort } from "react-icons/fa";

const TowerDetailTableHead = (props: any) => {
  const { onSorting } = props;

  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");
  const onSortingChange = (field: string) => {
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

    setSortingField(field);
    setSortingOrder(order);
    onSorting(field, order);
  };

  return (
    <>
      <TableHead className="pTabHead">
        <TableRow>
          <th className="mediumCol">SECTOR ID</th>
          <th>NAME</th>
          <th className="mediumCol" onClick={() => onSortingChange("ecgi")}>
            <span>ECGI</span>
            {sortingField && sortingField === "ecgi" ? (
              <button className="deviceSortBtn">
                {sortingOrder === "asc" ? (
                  <FaSortUp color="#000000bf" />
                ) : (
                  <FaSortDown color="#000000bf" />
                )}
              </button>
            ) : (
              <button className="deviceSortBtn">
                <FaSort color="#c4c4c4" />
              </button>
            )}
          </th>
          <th
            className="mediumCol"
            onClick={() => onSortingChange("operState")}
          >
            <span>CELL STATE</span>
            {sortingField && sortingField === "operState" ? (
              <button className="deviceSortBtn">
                {sortingOrder === "asc" ? (
                  <FaSortUp color="#000000bf" />
                ) : (
                  <FaSortDown color="#000000bf" />
                )}
              </button>
            ) : (
              <button className="deviceSortBtn">
                <FaSort color="#c4c4c4" />
              </button>
            )}
          </th>
          <th
            className="mediumCol"
            onClick={() => onSortingChange("technology")}
          >
            <span>TECHNOLOGY</span>
            {sortingField && sortingField === "technology" ? (
              <button className="deviceSortBtn">
                {sortingOrder === "asc" ? (
                  <FaSortUp color="#000000bf" />
                ) : (
                  <FaSortDown color="#000000bf" />
                )}
              </button>
            ) : (
              <button className="deviceSortBtn">
                <FaSort color="#c4c4c4" />
              </button>
            )}
          </th>
          <th
            className="mediumCol"
            onClick={() => onSortingChange("bandwidth")}
          >
            <span>BANDWIDTH</span>
            {sortingField && sortingField === "bandwidth" ? (
              <button className="deviceSortBtn">
                {sortingOrder === "asc" ? (
                  <FaSortUp color="#000000bf" />
                ) : (
                  <FaSortDown color="#000000bf" />
                )}
              </button>
            ) : (
              <button className="deviceSortBtn">
                <FaSort color="#c4c4c4" />
              </button>
            )}
          </th>
          <th
            className="mediumCol"
            onClick={() => onSortingChange("frequency")}
          >
            <span>FREQUENCY</span>
            {sortingField && sortingField === "frequency" ? (
              <button className="deviceSortBtn">
                {sortingOrder === "asc" ? (
                  <FaSortUp color="#000000bf" />
                ) : (
                  <FaSortDown color="#000000bf" />
                )}
              </button>
            ) : (
              <button className="deviceSortBtn">
                <FaSort color="#c4c4c4" />
              </button>
            )}
          </th>
          <th
            className="smallCol"
            onClick={() => onSortingChange("plannedStatus")}
          >
            <span>PLANNED STATUS</span>
            {sortingField && sortingField === "plannedStatus" ? (
              <button className="deviceSortBtn">
                {sortingOrder === "asc" ? (
                  <FaSortUp color="#000000bf" />
                ) : (
                  <FaSortDown color="#000000bf" />
                )}
              </button>
            ) : (
              <button className="deviceSortBtn">
                <FaSort color="#c4c4c4" />
              </button>
            )}
          </th>
          <th>RADIO TYPE</th>
          <th
            className="mediumCol"
            onClick={() => onSortingChange("adminState")}
          >
            <span>ADMIN STATE</span>
            {sortingField && sortingField === "adminState" ? (
              <button className="deviceSortBtn">
                {sortingOrder === "asc" ? (
                  <FaSortUp color="#000000bf" />
                ) : (
                  <FaSortDown color="#000000bf" />
                )}
              </button>
            ) : (
              <button className="deviceSortBtn">
                <FaSort color="#c4c4c4" />
              </button>
            )}
          </th>
        </TableRow>
        <TableRow>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </TableRow>
      </TableHead>
    </>
  );
};

export default TowerDetailTableHead;
