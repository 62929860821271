import { IptvNetwork } from "../../../../models/account/diagnostics/iptv/IptvNetwork";

export const IPTV_NETWORK_BEGIN = "IPTV_NETWORK_BEGIN";
export const IPTV_NETWORK_SUCCESS = "IPTV_NETWORK_SUCCESS";
export const IPTV_NETWORK_FAILURE = "IPTV_NETWORK_FAILURE";

interface IptvNetworkBegin {
  type: typeof IPTV_NETWORK_BEGIN;
}

interface IptvNetworkSuccess {
  type: typeof IPTV_NETWORK_SUCCESS;
  payload: IptvNetwork;
}

interface IptvNetworkFailure {
  type: typeof IPTV_NETWORK_FAILURE;
  payload: Error;
}

export type IptvNetworkActionTypes =
  | IptvNetworkBegin
  | IptvNetworkSuccess
  | IptvNetworkFailure;
