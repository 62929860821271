import { Marker, Polyline } from "@react-google-maps/api";
import { useEffect, useState } from "react";

import { getMapPointsDistance } from "../../../../components/reusable/Util";
import { mapElementStyles } from "../../../config/mapItemsConfig";
import { GetGoogleMaps } from "../../../util/reduxFunctions/getTopologyState";
import { getTransparentSymbol } from "../../../util/utilFuncs";

interface Props {
  measurePoints: any;
  setMeasurePoints: (arg0: any) => void;
  measurePoly: any;
  setMeasurePoly: (arg0: any) => void;
  measureDistances: any;
  setMeasureDistances: (arg0: any) => void;
}

export default function DistanceMeasurementMarkers({
  measurePoints,
  setMeasurePoints,
  measurePoly,
  setMeasurePoly,
  measureDistances,
  setMeasureDistances,
}: Props) {
  const { activeMapStyleName } = GetGoogleMaps();
  const [newDistPoints, setNewDistPoints] = useState(measurePoints);

  useEffect(() => {
    setNewDistPoints(measurePoints);
  }, [measurePoints]);

  const onMarkerDrag = (e: google.maps.MapMouseEvent, index: number) => {
    const coord = { lat: e.latLng?.lat(), lng: e.latLng?.lng() };
    if (index != 0) measurePoly[index - 1][1] = coord; //previous line
    if (index != measurePoly?.length) measurePoly[index][0] = coord; // following line
    setMeasurePoly([...measurePoly]);

    //create new distance numbers
    newDistPoints[index] = coord;
    const distances = [] as Array<number>;
    newDistPoints?.map((e: any, i: number) => {
      if (i != 0) {
        const currDist = getMapPointsDistance(
          newDistPoints[i - 1],
          e,
          distances?.length > 0 ? distances[distances.length - 1] : 0
        );
        distances.push(currDist);
      }
    });
    setMeasureDistances(distances);
    setNewDistPoints([...newDistPoints]);
  };

  return (
    <div>
      {measurePoints?.map((e: any, i: number) => (
        <Marker
          position={e}
          key={e.lat + e.lng}
          draggable={true}
          cursor="move"
          onDragStart={(e) => onMarkerDrag(e, i)}
          onDrag={(e) => onMarkerDrag(e, i)}
          onDragEnd={() => setMeasurePoints(newDistPoints)}
          label={{
            text: measureDistances?.[i - 1]
              ? measureDistances?.[i - 1] < 1
                ? (measureDistances?.[i - 1] * 1000)?.toFixed(2) + " M"
                : measureDistances?.[i - 1]?.toFixed(2) + " KM"
              : "⬤",
            className: measureDistances?.[i - 1]
              ? "mapDestinationDistanceTxt"
              : "",
            color: measureDistances?.[i - 1]
              ? `var(--pureBlack)`
              : activeMapStyleName === "standard"
              ? `var(--pureBlack)`
              : `var(--white)`,
            fontSize: "11px",
          }}
          icon={{
            url: getTransparentSymbol(),
            anchor: new google.maps.Point(
              mapElementStyles?.common?.anchor?.x + 3,
              mapElementStyles?.common?.anchor?.y + 3
            ),
          }}
        />
      ))}
      {measurePoly?.map((e: any) => (
        <Polyline
          key={`${e?.[0]?.lat},${e?.[1]?.lng}`}
          path={[e?.[0], e?.[1]]}
          options={
            activeMapStyleName === "standard"
              ? mapElementStyles?.measureDistance.standard
              : mapElementStyles?.measureDistance.others
          }
        />
      ))}
    </div>
  );
}
