import { GroundOverlay } from "@react-google-maps/api";
import React from "react";
import { mapElementStyles } from "../../../../config/mapItemsConfig";
import appConst from "../../../../constants/appConst";
import { getMapEleImageObj, isMduTableIcon } from "../../../../util/utilFuncs";
interface Props {
  mduCADSymbolData?: any;
  pngImages?: any;
}

const MduCADSymbol = React.memo(({ mduCADSymbolData, pngImages }: Props) => {
  let eleImageOverlay: any;
  return (
    <>
      {mduCADSymbolData &&
        mduCADSymbolData?.features?.map((feature: any) => {
          const propertiesData = feature?.properties?.data;
          const type = feature?.properties?.type;
          if (pngImages?.[type] !== undefined) {
            //STR1, STR2, STR3, STR4 icons have wdsLayer = 0.
            const isTableIcon = isMduTableIcon(feature);

            eleImageOverlay = getMapEleImageObj(
              pngImages?.[type],
              propertiesData?.symbolName,
              propertiesData?.rotation,
              mapElementStyles?.[type],
              feature?.geometry?.coordinates?.[1],
              feature?.geometry?.coordinates?.[0],
              isTableIcon ? false : true,
              isTableIcon ? true : false
            );
          }

          return (
            <GroundOverlay
              key={feature?.featureId}
              url={`${appConst.base64StringPrefix}${eleImageOverlay?.symbol}`}
              bounds={
                new google.maps.LatLngBounds(
                  new google.maps.LatLng(
                    eleImageOverlay?.southBound,
                    eleImageOverlay?.westBound
                  ),
                  new google.maps.LatLng(
                    eleImageOverlay?.northBound,
                    eleImageOverlay?.eastBound
                  )
                )
              }
            />
          );
        })}
    </>
  );
});

export default MduCADSymbol;
