import { FiRefreshCw } from "react-icons/fi";
import { MdRefresh } from "react-icons/md";
import CustomPopOver from "./CustomPopOver";
const ReloadData = (props: any) => {
  return (
    <CustomPopOver
      popOverData="Refresh Data"
      originComp={
        <button
          className="refreshBtn"
          onClick={() => props.refreshData()}
          disabled={props.disabled}
        >
          {/* <span>Refresh</span> */}
          <MdRefresh />
        </button>
      }
      index="popOver"
    />
  );
};

export default ReloadData;
