import {
  SAVE_FORM_SUCCESS,
  SAVE_FORM_BEGIN,
  SAVE_FORM_FAILURE,
  SAVE_FORM_RESET,
  NewFormActionTypes,
} from "../../../types/account/forms/Form";

export function SaveFormSuccess(activeForm: any): NewFormActionTypes {
  return {
    type: SAVE_FORM_SUCCESS,
    payload: activeForm,
  };
}

export function SaveFormBegin(): NewFormActionTypes {
  return {
    type: SAVE_FORM_BEGIN,
  };
}

export function SaveFormFailure(error: Error): NewFormActionTypes {
  return {
    type: SAVE_FORM_FAILURE,
    payload: error,
  };
}

export function SaveFormReset(): NewFormActionTypes {
  return {
    type: SAVE_FORM_RESET,
  };
}
