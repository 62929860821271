import { createSlice } from "@reduxjs/toolkit";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "xleGW",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    // action => action handler
    GetXleGWBegin: (xleGW) => {
        xleGW.isLoading = true;
    },
    GetXleGWSuccess: (xleGW, action) => {
        xleGW.isLoading = false;
        xleGW.data= action.payload.map((item:any) => item.data)
        xleGW.status = storeConst.OK;
    },
    GetXleGWFailure: (xleGW, action) => {
        xleGW.isLoading = false;
        xleGW.error = action.payload;
        xleGW.data = undefined;
        xleGW.status = storeConst.ERROR;
    },

 
  },
});

export const {
    GetXleGWBegin,
    GetXleGWSuccess,
    GetXleGWFailure,
} = slice.actions;

export default slice.reducer;
