import { FeedbackIssuesState } from "../../states/feedback/FeedbackIssuesState"
import {
  FEEDBACK_PROFILE_BEGIN,
  FEEDBACK_PROFILE_SUCCESS,
  FEEDBACK_PROFILE_FAILURE,
  FeedbackProfileActionTypes,
} from "../../types/feedback/FeedbackProfile"

const initialState: FeedbackIssuesState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function FeedbackReducer(
  state = initialState,
  action: FeedbackProfileActionTypes
): FeedbackIssuesState  {
  switch (action.type) {
    case FEEDBACK_PROFILE_BEGIN:
      return {
        isLoading: true,
      };
    case FEEDBACK_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case FEEDBACK_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}
