import { Divider } from "@material-ui/core";
import * as React from "react";
import AppLabels from "../../constants/App_Labels";
import Loader from "../../resources/images/loader.webp";

export const MultiDeviceAddressBanner: React.FC<any> = (props) => {
  const { data, expand, emtaType = false, emtaSearchVal } = props;
  const labels = AppLabels();

  interface IKeyValue {
    text: string;
    value: string | number | JSX.Element;
  }

  const KeyValuesTitle = (props: IKeyValue) => {
    const { text, value } = props;
    return (
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          alignItems: "center",
        }}
      >
        <span style={{ fontWeight: 600 }}>{text}:</span>
        <span style={{ marginLeft: "5px" }}>{value}</span>
      </span>
    );
  };
  const flexRowStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "20px",
    alignItems: "center",
    justifyContent: "space-between",
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          gap: "20px",
          fontSize: "14px",
        }}
      >
        <section
          style={{
            display: "flex",
            flexDirection: expand ? "column" : "row",
            gap: "6px",
          }}
        >
          <div>
            <>
              <span style={{ fontWeight: 600 }}>
                {labels.address.toUpperCase()}:
              </span>{" "}
              &nbsp;
              <span>
                {data?.streetNum + " " + data?.streetName ?? labels.NA}
              </span>
            </>
            &nbsp;
          </div>
          <Divider orientation="vertical" hidden={expand} />
        </section>
        <div
          style={{
            display: expand ? "none" : "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "20px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <KeyValuesTitle
            text="Active"
            value={
              data?.active === undefined ? (
                <img src={Loader} alt="loading" height="20px" />
              ) : (
                `${data?.active}%`
              )
            }
          />
          <KeyValuesTitle
            text="Assigned"
            value={
              data?.assigned === undefined ? (
                <img src={Loader} alt="loading" height="20px" />
              ) : (
                `${data?.assigned}`
              )
            }
          />
          <KeyValuesTitle
            text="Responsive"
            value={
              data?.responsive === undefined ? (
                <img src={Loader} alt="loading" height="20px" />
              ) : (
                `${data?.responsive}`
              )
            }
          />
          <KeyValuesTitle
            text="Unresponsive"
            value={
              data?.unresponsive === undefined ? (
                <img src={Loader} alt="loading" height="20px" />
              ) : (
                `${data?.unresponsive}`
              )
            }
          />
        </div>
      </div>
      <>
        <Divider orientation="vertical" hidden={!expand} />
        <section
          style={{
            display: expand ? "flex" : "none",
          }}
        >
          <span
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "flex-start",
              paddingLeft: "10px",
            }}
          >
            <KeyValuesTitle
              text="Active"
              value={
                data?.active === undefined ? (
                  <img src={Loader} alt="loading" height="20px" />
                ) : (
                  `${data?.active}%`
                )
              }
            />
            <KeyValuesTitle
              text="Assigned"
              value={
                data?.assigned === undefined ? (
                  <img src={Loader} alt="loading" height="20px" />
                ) : (
                  `${data?.assigned}`
                )
              }
            />
          </span>
          <span
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "flex-start",
              paddingLeft: "10px",
            }}
          >
            <KeyValuesTitle
              text="Responsive"
              value={
                data?.responsive === undefined ? (
                  <img src={Loader} alt="loading" height="20px" />
                ) : (
                  `${data?.responsive}`
                )
              }
            />
            <KeyValuesTitle
              text="Unresponsive"
              value={
                data?.unresponsive === undefined ? (
                  <img src={Loader} alt="loading" height="20px" />
                ) : (
                  `${data?.unresponsive}`
                )
              }
            />
          </span>
          <span
            style={{
              display: expand ? "flex" : "none",
              flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "flex-start",
              paddingLeft: "150px",
            }}
          >
            <KeyValuesTitle
              text="Start"
              value={
                data?.startTime === undefined ? (
                  <img src={Loader} alt="loading" height="20px" />
                ) : (
                  `${data?.startTime}`
                )
              }
            />
            <KeyValuesTitle
              text="End"
              value={
                data?.endTime === undefined ? (
                  <img src={Loader} alt="loading" height="20px" />
                ) : (
                  `${data?.endTime}`
                )
              }
            />
          </span>
        </section>
      </>
    </>
  );
};
