import { useState } from "react";
import SearchPopUp from "./SearchPopUp";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ResetSearchAccState } from "../../store/actions/account/accountInfo/AccountDetailsAction";
//Icons Imports
import { IoSearch } from "react-icons/io5";
import CustomPopOver from "../reusable/CustomPopOver";
//Css Imports

/*Added for Node */
import { ResetNodeInfoState } from "../../store/actions/node/nodeinfo/NodeInfoDetailsAction";

/*changes added for node is done */

const SearchMenu = () => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  let location = useLocation();
  if (location.pathname === "/") return null;

  const handleSearchClose = () => {
    setModalShow(false);
    dispatch(ResetSearchAccState());

    /*Added by node Team to reset nodeinfo redux state */
    //dispatch(ResetSearchNodeState());
    dispatch(ResetNodeInfoState());
    /*changes by node Team is done */
  };

  return (
    <div className="search">
      <CustomPopOver
        position="bottom"
        popOverData="Search"
        originComp={
          <button
            className="sBtn headerIcon"
            onClick={() => setModalShow(true)}
          >
            <IoSearch color="inherit" className="searchBtnWidth" />
          </button>
        }
        index="popOver"
      />
      <SearchPopUp show={modalShow} onHide={handleSearchClose} />
    </div>
  );
};

export default SearchMenu;
