import { Divider } from "@material-ui/core";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import LoaderGif from "../../resources/images/loader.webp";
import { SaveSkippedDevices } from "../../store/actions/account/certifications/ActiveCertificationAction";
import {
  SaveFailedDevices,
  SavePassedDevices,
  SaveMarginalPassDevices,
  SaveDataLoading,
} from "../../store/actions/account/certifications/ActiveCertificationAction";
import { Device } from "../../store/models/account/accountInfo/Device";
import { DeviceDiag } from "../diagnostics/DiagnosticsTab";
import CustomTab from "../reusable/CustomTab";
import ExceptionBox from "../reusable/ExceptionBox";
import {
  addDeviceStatus,
  getFailCount,
  getMarginalPassCount,
  getPassCount,
} from "../reusable/StoreCertificationObj";
import { isNull, removeDeviceStatus } from "../reusable/Util";
import CertiDiagnostics from "./CertiDiagnostics";

const CertificationDeviceList = (props: any) => {
  const {
    deviceList,
    saveFlag,
    updateDevices,
    skippedFlag,
    sortedDevices,
    skipCount,
    certiConfig,
    cwFlag,
    checkSkipCondition,
  } = props;
  const dispatch = useDispatch();
  const { selectedSA, certiDeviceList, skipDevices, isLoadingFlag } =
    useSelector(
      (state: any) => ({
        selectedSA: state.AccountState.ActiveAccountDetails.selectedSA,
        certiDeviceList:
          state.AccountState.Certification.ActiveCertiDevicesInfo.certiDevices,
        skipDevices:
          state.AccountState.Certification.ActiveCertiInfo.skippedDevices,
        isLoadingFlag:
          state.AccountState.Certification.ActiveCertiInfo.isDataLoading,
      }),
      shallowEqual
    );
  const [certiDevices, setCertiDevices] = useState<Device[]>(deviceList);
  const [selDValue, setSelDValue] = useState<number | undefined>();
  const [selDiagAction, setSelDiagAction] = useState("");
  const handleActionClick = (tab: string) => {
    setSelDiagAction(tab);
  };

  const [deviceDiagActions, setDeviceDiagActions] = useState<DeviceDiag[]>([]);
  const [selDevice, setSelDevice] = useState<Device>();
  const [loadingFlag, setLoadingFlag] = useState(true);
  const [leftmenu, setLeftMenu] = useState(true);
  //const [codewordTabFlag, setCodewordTabFlag] = useState(false);
  const certiDevicesRef = useRef(certiDevices);
  useEffect(() => {
    if (deviceList !== undefined && deviceList?.length !== 0) {
      certiDevicesRef.current = removeDeviceStatus(deviceList);
    }
  }, [deviceList]);

  useEffect(() => {
    let computedDevices = certiDevicesRef.current;

    if (
      certiDeviceList !== undefined &&
      certiDeviceList?.length !== 0 &&
      computedDevices !== undefined &&
      computedDevices?.length !== 0
    ) {
      computedDevices = computedDevices?.map((d: any) => {
        let certiDeviceData =
          d.deviceCategory !== "IEMTA"
            ? certiDeviceList?.filter(
              (device: any) => device.deviceKey === d.serialNumber
            )
            : certiDeviceList?.filter(
              (device: any) => device.deviceKey === "IEMTA" + d.serialNumber
            );

        if (
          !certiDeviceData?.[0]?.isLoading &&
          !d?.hasOwnProperty("deviceStatus")
        ) {
          let cDevice = addDeviceStatus(d, certiDeviceData?.[0]?.data);
          d = { ...d, ...cDevice };
          return d;
        } else return d;
      });
    }
    setCertiDevices(computedDevices);
    let count = 0;

    certiDeviceList?.map((d: any) => {
      if (!d.isLoading) count += 1;
    });
    if (count !== 0 && count === deviceList?.length) {
      setLoadingFlag(!loadingFlag);
      dispatch(SaveDataLoading(!loadingFlag));
    } else if (skippedFlag) {
      setLoadingFlag(!loadingFlag);
      dispatch(SaveDataLoading(!loadingFlag));
    }
  }, [certiDeviceList]);
  useEffect(() => {
    if (!isLoadingFlag) {
      let skippedDevices = skipDevices?.devices ?? [];
      certiDevices?.map((d: any) => {
        if (
          !checkSkipCondition(d?.deviceCategory, d) &&
          (d?.deviceStatus?.toUpperCase() === "SKIPPED" ||
            d?.deviceStatus === undefined ||
            d?.deviceStatus === "")
        ) {
          // Check if the device is already in the skippedDevices array
          const isAlreadySkipped = skippedDevices?.some(
            (skip: any) =>
              skip?.serialNumber === d?.serialNumber &&
              skip?.deviceCategory === d?.deviceCategory
          );
          if (isAlreadySkipped === false) {
            skippedDevices.push(d);
            skipCount.current = skipCount.current + 1;

            let tempObj = {
              count: skipCount.current, // skippedDevices?.length can be used
              devices: skippedDevices,
            };
            dispatch(SaveSkippedDevices(tempObj));
          }
        }
      });

      // }
    }
  }, [isLoadingFlag]);

  useEffect(() => {
    if (
      certiDevices !== undefined &&
      certiDevices?.length !== 0 &&
      !skippedFlag
    ) {
      dispatch(
        SavePassedDevices(
          getPassCount(
            certiDevices.filter(
              (f) => !checkSkipCondition(f?.deviceCategory, f)
            )
          )
        )
      );
      dispatch(
        SaveFailedDevices(
          getFailCount(
            certiDevices.filter(
              (f) => !checkSkipCondition(f?.deviceCategory, f)
            )
          )
        )
      );
      dispatch(
        SaveMarginalPassDevices(
          getMarginalPassCount(
            certiDevices.filter(
              (f) => !checkSkipCondition(f?.deviceCategory, f)
            )
          )
        )
      );
      updateDevices(certiDevices);
      setSelDValue(0);
      //enableSave();
    } else if (skippedFlag) {
      dispatch(SavePassedDevices({ count: 0, devices: [] }));
      dispatch(SaveFailedDevices({ count: 0, devices: [] }));
      dispatch(SaveMarginalPassDevices({ count: 0, devices: [] }));
    }
  }, [loadingFlag]);

  useEffect(() => {
    if (
      selDValue !== undefined &&
      certiDevices !== undefined &&
      certiDevices?.length !== 0
    ) {
      setSelDevice(certiDevices[selDValue]);
      let deviceDiag = getDeviceDiag(certiDevices[selDValue].deviceCategory);
      setDeviceDiagActions(deviceDiag);
    }
  }, [selDValue]);
  const getDeviceDiag = (deviceName: string): DeviceDiag[] => {
    let diagArr: DeviceDiag[] = [];
    certiConfig?.map((d: any) => {
      if (
        deviceName === d.category &&
        d?.hasOwnProperty("deviceCertification")
      ) {
        diagArr = d.deviceCertification;
      }
    });
    return diagArr;
  };

  return (
    <div className="WHTCont">
      <section className={leftmenu ? "leftSection" : "toggleleftSection"}>
        {!skippedFlag ? (
          <>
            <p className="certiDeviceLabel">PROVISIONED DEVICES</p>
            <div className="deviceTabs">
              <Divider />
              {certiDevices !== undefined && certiDevices?.length !== 0 ? (
                certiDevices
                  ?.map((f) => {
                    return checkSkipCondition(f?.deviceCategory, f)
                      ? { ...f, deviceStatus: "skip" }
                      : f;
                  })
                  ?.map((device: any, index: number) => {
                    return (
                      <div key={index}>
                        {certiConfig?.map((d: any, i: number) =>
                          device.deviceCategory === d.category ? (
                            <div key={i}>
                              <CustomTab
                                tabType={
                                  !leftmenu ? "vertical-Toggle" : "vertical"
                                }
                                handleActionClick={(actionKey: any) =>
                                  handleActionClick(actionKey)
                                }
                                deviceDiagActions={deviceDiagActions}
                                deviceStatus={device.deviceStatus}
                                index={index}
                                value={selDValue}
                                handleSelect={(i: any) => {
                                  setSelDValue(i);
                                }}
                                label={isNull(d.name)}
                              />
                              <Divider />
                            </div>
                          ) : null
                        )}
                      </div>
                    );
                  })
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <>
            <p className="certiDeviceLabel">PROVISIONED DEVICES</p>
            <div className="deviceTabs">
              <Divider />
              {skipDevices !== undefined &&
                skipDevices?.devices?.length !== 0 ? (
                skipDevices?.devices?.map((device: any, index: number) => {
                  return (
                    <div key={index}>
                      {certiConfig?.map((d: any, i: number) =>
                        device.deviceCategory === d.category ? (
                          <div key={i}>
                            <CustomTab
                              tabType={
                                !leftmenu ? "vertical-Toggle" : "vertical"
                              }
                              deviceStatus="skip"
                              index={index}
                              value={selDValue}
                              handleSelect={(i: any) => setSelDValue(i)}
                              label={isNull(d.name)}
                            />
                            <Divider />
                          </div>
                        ) : null
                      )}
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </>
        )}
      </section>
      <section className="rightPanel">
        <div className="diagResult">
          {!skippedFlag ? (
            <>
              {selDevice !== undefined &&
                selDValue !== undefined &&
                (saveFlag === false || cwFlag !== saveFlag) ? (
                <CertiDiagnostics
                  selectedSA={selectedSA}
                  certiConfig={certiConfig}
                  selectedDevice={
                    checkSkipCondition(selDevice?.deviceCategory, selDevice)
                      ? { ...selDevice, deviceStatus: "skip" }
                      : selDevice
                  }
                  deviceDiagActions={deviceDiagActions}
                  deviceDataObj={certiDeviceList}
                  seleDeviceDiag={undefined}
                  selDiagAction={selDiagAction}
                  handleActionClick={(actionKey: any) =>
                    handleActionClick(actionKey)
                  }
                />
              ) : (
                <img className="loaderImg" src={LoaderGif} />
              )}
            </>
          ) : (
            <div className="diagErrorCont">
              <ExceptionBox
                headerText="No Diagnostics to display."
                primaryBtn={false}
                secBtn={false}
              />
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default CertificationDeviceList;
