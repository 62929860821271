import { combineReducers } from "redux";
import ActiveNodeInfoReducer from "./nodeinfo/ActiveNodeInfoReducer";
import CMTSNodeReducer from "./nodeinfo/CMTSNodeReducer";
import CMTSIPReducer from "./nodeinfo/CMTSIPReducer";
import NodeInfoDetailsReducer from "./nodeinfo/NodeInfoDetailsReducer";
import { NodeModemsListReducer } from "./telemetry/NodeModemsListReducer";
import { NodeDevicesInfoReducer } from "./telemetry/NodeDevicesInfoReducer";
import FlapHistoryReducer from "./telemetry/FlapHistoryReducer";
import ClearFlapsReducer from "./telemetry/ClearFlapsReducer";
import nodeAveragesStatus from "../../ducksPattern/nodeAveragesStatus";
import { NodeCertifications } from "../../slices/nodecertifications/nodeCertificationIndex";

export const allNodeReducers = combineReducers({
  ActiveNodeinfoDetails: ActiveNodeInfoReducer,
  CMTSNodeinfoDetails: CMTSNodeReducer,
  CMTSIPinfoDetails: CMTSIPReducer,
  NodeInfoDetails: NodeInfoDetailsReducer,
  NodeModemsList: NodeModemsListReducer,
  NodeDevicesInfo: NodeDevicesInfoReducer,
  FlapHistoryData: FlapHistoryReducer,
  ClearFlapsNodeModems: ClearFlapsReducer,
  nodeAveragesStatus: nodeAveragesStatus,
  nodeCertifications: NodeCertifications,
});
