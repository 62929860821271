import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { useCallback, useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import ActivityIndicator from "../../../../../components/reusable/ActivityIndicator";
import AppLabels from "../../../../../constants/App_Labels";
import {
  defaultMDUSchematicLocation,
  defaultZoom,
  zoomLevelRenderMarker,
  zoomLevelRenderMduOverlay,
} from "../../../../config/mapConfig";
import { getMduActives } from "../../../../store/slices/mapItems/mdu/mduActives";
import { getMduAnnotationBlock } from "../../../../store/slices/mapItems/mdu/mduAnnotationBlock";
import { getMduCADText } from "../../../../store/slices/mapItems/mdu/mduCADText";
import { getMduFiberCable } from "../../../../store/slices/mapItems/mdu/mduFiberCable";
import { getMduFiberEquipments } from "../../../../store/slices/mapItems/mdu/mduFiberEquipments";
import { getMduPassives } from "../../../../store/slices/mapItems/mdu/mduPassives";
import { getMduPowerSupply } from "../../../../store/slices/mapItems/mdu/mduPowerSupply";
import { getMduRFCable } from "../../../../store/slices/mapItems/mdu/mduRFCable";
import { getMDUSupportStructure } from "../../../../store/slices/mapItems/mdu/mduSupportStructure";
import { getMduTap } from "../../../../store/slices/mapItems/mdu/mduTap";
import { getMduPolylines } from "../../../../store/slices/mapItems/mdu/mduPolylines";
import { getMduRaster } from "../../../../store/slices/mapItems/mdu/mduRaster";
import "../../../../styles/map/MduMap/MDUMap.scss";
import {
  GetGoogleMaps,
  GetMapDetailsProps,
  GetMduActivesProps,
  GetMduAnnotationBlockProps,
  GetMduCADAttributesProps,
  GetMduCADCircleProps,
  GetMduCADHatchProps,
  GetMduCADSymbolProps,
  GetMduCADTextProps,
  GetMduFiberCableProps,
  GetMduFiberEquipmentsProps,
  GetMduPassivesProps,
  GetMduPolylinesProps,
  GetMduPowerSupplyProps,
  GetMduRasterProps,
  GetMduRFCableProps,
  GetMduSupportStructureProps,
  GetMduTapProps,
  GetSymbolsProps,
} from "../../../../util/reduxFunctions/getTopologyState";
import MduActives from "./MduActives";
import MduCADText from "./MduCADText";
import MduFiberCable from "./MduFiberCable";
import MduFiberEquipments from "./MduFiberEquipments";
import MduPassives from "./MduPassives";
import MduPolyline from "./MduPolyline";
import MduPowerSupply from "./MduPowerSupply";
import MduRaster from "./MduRaster";
import MduRFCable from "./MduRFCable";
import MduSupportStructure from "./MduSupportStructure";
import MduTap from "./MduTap";
import { useMduSymbolsRequestHandler } from "../../../../util/customHooks/mduSymbolHooks";
import MduAnnotationBlock from "./mduAnnotationBlocks/MduAnnotationBlock";
import { Snackbar } from "@material-ui/core";
import LoaderGif from "../../../../../resources/images/loader.webp";
import MduCADAttributes from "./MduCADAttributes";
import { getMduCADAttribute } from "../../../../store/slices/mapItems/mdu/mduCADAttribute";
import { getMduCADCircle } from "../../../../store/slices/mapItems/mdu/mduCADCircle";
import { getMduCADHatch } from "../../../../store/slices/mapItems/mdu/mduCADHatch";
import { getMduCADSymbol } from "../../../../store/slices/mapItems/mdu/mduCADSymbol";
import MduCADHatch from "./MduCADHatch";
import MduCADSymbol from "./MduCADSymbol";
import MduCADCircle from "./MduCADCircle";
import { setMapCenter } from "../../../../store/slices/mapItems/mapDetails";
import {
  handleZoomChange,
  useMDUElementEventHandler,
} from "../../../../util/customHooks/mapEventHandlers";
import { useMduElementStyle } from "../../../../util/customHooks/topologyHooks";
import ActiveMapMarker from "../../ActiveMapMarker";
import ImageEditor from "../../redline/ImageEditor";
interface Props {
  owningDrawing: string;
  gKey: string;
}
const MduMapView = ({ owningDrawing, gKey }: Props) => {
  const labels = AppLabels();
  const dispatch = useDispatch();
  const mapContRef = useRef(null);
  const { mapIsLoading, mapCenter } = GetMapDetailsProps();
  const { mduPolylinesdata, mduPolylineIsLoading } = GetMduPolylinesProps();
  const { mduActivesdata } = GetMduActivesProps();
  const { mduFiberEquipmentsdata } = GetMduFiberEquipmentsProps();
  const { mduCADTextdata } = GetMduCADTextProps();
  const { mduPassivesdata } = GetMduPassivesProps();
  const { mduFiberCabledata } = GetMduFiberCableProps();
  const { mduRFCabledata } = GetMduRFCableProps();
  const { mduPowerSupplydata } = GetMduPowerSupplyProps();
  const { mduTapdata } = GetMduTapProps();
  const { mduAnnotationBlockdata } = GetMduAnnotationBlockProps();
  const { mduSupportStructuredata } = GetMduSupportStructureProps();
  const { mduRasterdata } = GetMduRasterProps();
  const { mduCADAttributeData } = GetMduCADAttributesProps();
  const { mduCADCircleData } = GetMduCADCircleProps();
  const { mduCADSymbolData } = GetMduCADSymbolProps();
  const { mduCADHatchData } = GetMduCADHatchProps();
  const { mapStyle, zoomLevel } = GetGoogleMaps();
  const { isTopoEleImgLoading, pngImages } = GetSymbolsProps();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: gKey,
  });

  const [gMap, setGMap] = useState<google.maps.Map>();
  const [mapLoading, setMapLoading] = useState(false);

  const onLoad = useCallback((mapInstance) => {
    setGMap(mapInstance);
  }, []);

  const TopologyMapOptions = {
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    minZoom: 16,
    maxZoom: 21,
    disableDoubleClickZoom: true,
  };

  useMduElementStyle(gMap, zoomLevel, pngImages);

  useMDUElementEventHandler(gMap, dispatch);

  useEffect(() => {
    if (!mduPolylineIsLoading && !isTopoEleImgLoading && !mapIsLoading) {
      setMapLoading(false);
      let firstPolyLineCords =
        mduPolylinesdata?.features?.[0]?.geometry?.coordinates?.[0];
      if (firstPolyLineCords && firstPolyLineCords?.length > 0) {
        dispatch(
          setMapCenter({
            lat: firstPolyLineCords?.[1],
            lng: firstPolyLineCords?.[0],
          })
        );
      }
    } else if (mduPolylineIsLoading || isTopoEleImgLoading || mapIsLoading) {
      setMapLoading(true);
    }
  }, [mduPolylineIsLoading, isTopoEleImgLoading, mapIsLoading]);

  // Call for MDU Symbols
  useMduSymbolsRequestHandler();

  useEffect(() => {
    if (owningDrawing && owningDrawing !== "") {
      dispatch(getMduCADSymbol(owningDrawing));
      dispatch(getMduRaster(owningDrawing));
      dispatch(getMduPolylines(owningDrawing));
      dispatch(getMduPassives(owningDrawing));
      dispatch(getMduActives(owningDrawing));
      dispatch(getMduFiberEquipments(owningDrawing));
      dispatch(getMduCADText(owningDrawing));
      dispatch(getMduFiberCable(owningDrawing));
      dispatch(getMduRFCable(owningDrawing));
      dispatch(getMduPowerSupply(owningDrawing));
      dispatch(getMduTap(owningDrawing));
      dispatch(getMduAnnotationBlock(owningDrawing));
      dispatch(getMDUSupportStructure(owningDrawing));
      dispatch(getMduCADAttribute(owningDrawing));
      //dispatch(getMduCADCircle(owningDrawing));
      dispatch(getMduCADHatch(owningDrawing));
    }
  }, [owningDrawing]);

  return (
    <>
      {!mapLoading && isLoaded ? (
        <>
          <div ref={mapContRef} style={{ height: "100%" }}>
            <GoogleMap
              id="mduMap"
              mapContainerClassName="mduMapCont"
              center={mapCenter ? mapCenter : defaultMDUSchematicLocation}
              options={{ ...TopologyMapOptions, styles: mapStyle }}
              onZoomChanged={() => handleZoomChange(gMap?.getZoom(), dispatch)}
              onLoad={onLoad}
              zoom={zoomLevel ? zoomLevel : defaultZoom}
            >
              <ActiveMapMarker />
              <MduRaster
                gMap={gMap}
                mduRasterData={mduRasterdata}
                mduPolylinesdata={mduPolylinesdata}
                mduPassivesdata={mduPassivesdata}
                mduTapdata={mduTapdata}
              />
              <MduPolyline gMap={gMap} mduPolylineData={mduPolylinesdata} />
              <MduFiberCable
                gMap={gMap}
                mduFiberCableData={mduFiberCabledata}
              />
              <MduRFCable gMap={gMap} mduRFCableData={mduRFCabledata} />
              <MduSupportStructure
                mduSupportStructureData={mduSupportStructuredata}
                gMap={gMap}
                pngImages={pngImages}
                zoomLevel={zoomLevel}
              />

              {zoomLevel >= zoomLevelRenderMarker && (
                <MduCADText
                  mduCADTextData={mduCADTextdata}
                  zoomLevel={zoomLevel}
                />
              )}
              {zoomLevel >= zoomLevelRenderMduOverlay && (
                <>
                  <MduPassives
                    mduPassivesData={mduPassivesdata}
                    pngImages={pngImages}
                  />
                  <MduActives
                    gMap={gMap}
                    mduActivesData={mduActivesdata}
                    pngImages={pngImages}
                  />
                  <MduFiberEquipments
                    gMap={gMap}
                    mduFiberEquipmentsData={mduFiberEquipmentsdata}
                    pngImages={pngImages}
                  />
                  <MduPowerSupply
                    gMap={gMap}
                    mduPowerSupplyData={mduPowerSupplydata}
                    pngImages={pngImages}
                  />
                  <MduTap
                    gMap={gMap}
                    mduTapData={mduTapdata}
                    zoomLevel={zoomLevel}
                    pngImages={pngImages}
                  />
                  <MduCADSymbol
                    pngImages={pngImages}
                    mduCADSymbolData={mduCADSymbolData}
                  />
                  <MduCADAttributes
                    mduCADAttributeData={mduCADAttributeData}
                    zoomLevel={zoomLevel}
                  />
                  <MduCADHatch gMap={gMap} mduCADHatchData={mduCADHatchData} />

                  <MduAnnotationBlock
                    mduAnnotationBlockData={mduAnnotationBlockdata}
                    zoomLevel={zoomLevel}
                  />
                </>
              )}
              <ImageEditor
                mapContRef={mapContRef}
                contStyle={{ bottom: "120px" }}
                owningDrawing={owningDrawing}
              />
            </GoogleMap>
          </div>
          <Snackbar
            className="mapLoaderCont"
            open={mapLoading}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            message={
              <>
                <span>Map Loading...</span>
                <img className="mapLoading" src={LoaderGif} />
              </>
            }
          />
        </>
      ) : (
        <ActivityIndicator
          style={{ width: "15%", height: "15%" }}
          animationAlignement="center"
        />
      )}
    </>
  );
};

export default MduMapView;
