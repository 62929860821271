import { useEffect, useRef, useState } from "react";
import { FormControl } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { ReactComponent as UploadImage } from "../../resources/images/icons/uploadImage.svg";
import { Badge } from "@material-ui/core";
import {
  randomString,
  formatBytesToSize,
  getBase64Size,
} from "../reusable/Util";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const NotiItemCard = (props: any) => {
  const { item, deleteItem, updateItem, updateFlag } = props;
  const fileInputRef = useRef<any>();
  const [itemTitle, setItemTitle] = useState(updateFlag ? item?.header : "");
  const [itemDesc, setItemDesc] = useState(updateFlag ? item?.desc : "");
  const [images, setImages] = useState<File[]>([]);
  const [previews, setPreviews] = useState<any>(updateFlag ? item?.images : []);

  useEffect(() => {
    if (images && images?.length !== 0) {
      let prevList: any = item?.images;
      images?.map((i: any) => {
        const reader = new FileReader();
        reader.onloadend = (e) => {
          prevList = [
            ...prevList,
            ...[
              { id: randomString(10), image: e.target!.result, size: e.loaded },
            ],
          ];

          setPreviews(prevList);
        };
        reader.readAsDataURL(i);
      });
    }
  }, [images]);
  useEffect(() => {
    updateItem(item?.id, {
      id: item?.id,
      header: itemTitle,
      desc: itemDesc,
      images: previews,
    });
  }, [previews, itemTitle, itemDesc]);

  const getListStyle = (isDraggingOver: any) => ({
    display: "flex",
    //flexDirection: "row"
  });

  const deleteItemImage = (key: number) => {
    let tempNotiItemsImages: any = [];
    if (previews?.length !== 0 && key) {
      tempNotiItemsImages = previews?.filter((n: any) => n?.id !== key);
    }
    setPreviews(tempNotiItemsImages);
  };
  const onDragEnd = (result: any) => {
    // dropped outside the list

    if (!result.destination) {
      return;
    }

    const itemstmp: any[] = reorder(
      item.images,
      result.source.index,
      result.destination.index
    );

    setPreviews(itemstmp);
  };

  const reorder = (list: [], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  return (
    <div className="itemCardCont">
      <Badge
        badgeContent={
          <IoClose color="#000000" onClick={() => deleteItem(item?.id)} />
        }
      >
        <div className="itemCardBody">
          <section className="imageUploadCont">
            <button
              onClick={(event) => {
                event.preventDefault();
                fileInputRef.current.click();
              }}
            >
              <UploadImage height="50px" />
            </button>
            <input
              type="file"
              style={{ display: "none" }}
              ref={fileInputRef}
              accept="image/*"
              multiple={true}
              onChange={(event: any) => {
                let files: any = [];
                Array.from(event.target.files).forEach((file) => {
                  files.push(file);
                });
                if (files && files?.length !== 0) {
                  setImages(files);
                }
              }}
            />
          </section>
          <section className="itemInputCont">
            <div className="itemInputs">
              <FormControl
                placeholder="Type item title"
                value={itemTitle}
                maxLength={200}
                onChange={(e: any) => setItemTitle(e.target.value)}
              />
              <FormControl
                placeholder="Type item description"
                value={itemDesc}
                onChange={(e: any) => setItemDesc(e.target.value)}
              />
            </div>
            <div className="imagePrevCont">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                    >
                      {item?.images && item?.images?.length !== 0 ? (
                        item?.images?.map((p: any, index: number) => {
                          return (
                            <Draggable
                              key={index}
                              draggableId={`${p.name}-${index}`}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  // style={{paddingTop: 20,
                                  //   paddingBottom: 20}}
                                >
                                  <Badge
                                    badgeContent={
                                      <IoClose
                                        color="#000000"
                                        onClick={() => deleteItemImage(p?.id)}
                                      />
                                    }
                                  >
                                    <div className="badgeImageConatiner">
                                      <img src={p?.image} />
                                      <span className="imageSize">
                                        {updateFlag
                                          ? getBase64Size(p?.image)
                                          : formatBytesToSize(
                                              p?.size,
                                              "Bytes",
                                              0
                                            )}
                                      </span>
                                    </div>
                                  </Badge>
                                </div>
                              )}
                            </Draggable>
                          );
                        })
                      ) : (
                        <>No image previews available </>
                      )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </section>
        </div>
      </Badge>
    </div>
  );
};

export default NotiItemCard;
