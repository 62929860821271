import { createSlice } from "@reduxjs/toolkit";
import { arrangeNodeData } from "../../../../util/utilFuncs";
import { apiCallBegan } from "../../../middleware/apiActions";
import { CPAT_EVENTS } from "../../../middleware/apiEndpoints";
import storeConst from "../../../storeConst";
import { decrApiCall, incrApiCall } from "../mapDetails";

const slice = createSlice({
  name: "cpatEvents",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
    cpatFilters: undefined,
    isEventsVisible: false,
  },
  reducers: {
    getCpatEventsBegin: (cpatEvents) => {
      cpatEvents.isLoading = true;
      cpatEvents.status = "";
    },
    getCpatEventsSuccess: (cpatEvents, action) => {
      cpatEvents.isLoading = false;
      cpatEvents.data = action.payload;
      cpatEvents.status = storeConst.OK;
    },
    getCpatEventsFailure: (cpatEvents, action) => {
      cpatEvents.isLoading = false;
      cpatEvents.error = action.payload;
      cpatEvents.data = undefined;
      cpatEvents.status = storeConst.ERROR;
    },
    setEventsVisible: (cpatEvents, action) => {
      cpatEvents.isEventsVisible = action.payload;
    },
  },
});

export const { setEventsVisible } = slice.actions;
const { getCpatEventsBegin, getCpatEventsSuccess, getCpatEventsFailure } =
  slice.actions;
export default slice.reducer;

export const getCpatEvents = (props: any) => (dispatch: any) => {
  dispatch(
    apiCallBegan({
      url: CPAT_EVENTS,
      data: props,
      method: "post",
      onStart: getCpatEventsBegin.type,
      onSuccess: getCpatEventsSuccess.type,
      onError: getCpatEventsFailure.type,
      onStartDispatch: incrApiCall.type,
      onSuccessDispatch: decrApiCall.type,
      onErrorDispatch: decrApiCall.type,
    })
  );
};
