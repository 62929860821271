import { GWDeviceSummary } from "../../../../models/account/diagnostics/cm/GWDeviceSummary";

import {
    GW_DEVICES_SUMMARY_BEGIN,
    GW_DEVICES_SUMMARY_SUCCESS,
    GW_DEVICES_SUMMARY_FAILURE,
    GWDeviceSummaryActionTypes,
} from "../../../../types/account/diagnostics/cm/GWDeviceSummary";

export function gwDeviceSummaryBegin(): GWDeviceSummaryActionTypes {
  return {
    type: GW_DEVICES_SUMMARY_BEGIN,
  };
}

export function gwDeviceSummarySuccess(gwDeviceSummary: GWDeviceSummary): GWDeviceSummaryActionTypes {
  return {
    type: GW_DEVICES_SUMMARY_SUCCESS,
    payload: gwDeviceSummary,
  };
}

export function gwDeviceSummaryFailure(error: Error): GWDeviceSummaryActionTypes {
  return {
    type: GW_DEVICES_SUMMARY_FAILURE,
    payload: error,
  };
}
