import { useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { FaRegPlayCircle, FaRegStopCircle } from "react-icons/fa";
import { MdSettings } from "react-icons/md";
import { getTechRoles } from "../../components/reusable/NodeUtil";
import AppLabels from "../../constants/App_Labels";
import { GetTechInfo } from "../../get-api/GetResponse";
import LoaderGif from "../../resources/images/loader.webp";
import NodeTopologySwitch from "../node/NodeTopologySwitch";
import CustomDropDown from "../reusable/CustomDropDown";
import { MultiSelectDropdown } from "../reusable/MultiSelectDropdown";
import {
  convertHealthData,
  isNull,
  isNumeric,
  openNewWindow,
} from "../reusable/Util";
// import OdmScores from "../overview/node/OdmScores";

const NodeBasicInfo = (props: any) => {
  const {
    data,
    rackidData,
    vhubNumber,
    isLoading,
    nodeScoreDataConfig,
    stopDiag,
    startDiag,
    mlData,
    allDiagIsLoading,
    dataSetName,
    setDataSetName,
    dataSetNames,
  } = props;
  const labels = AppLabels();
  const [smtValue, setSmtValue] = useState(labels.ALL?.toUpperCase());
  const techData = GetTechInfo()?.data;
  const [hyperlink, setHyperlinks] = useState<null | JSX.Element>(null);
  const [diagDataSet, setDiagDataSet] = useState("");
  const peers = data?.clamshellPeers ? data.clamshellPeers.split(",") : [];
  useEffect(() => {
    props.smtSearchId(data?.smtId);
  }, [data?.smtId]);

  const renderServiceAreas = () => {
    if (data && data?.serviceAreas) {
      let serviceAreas = data?.serviceAreas?.split(", ");
      serviceAreas?.unshift(labels.ALL?.toUpperCase());
      return serviceAreas;
    }
    return null;
  };

  const renderSMTPeers = () => {
    return (
      <div className="smtPhubCont">
        <p>
          <span>{labels.SMT.toUpperCase()}:</span>
          <DropdownButton
            title={smtValue}
            key={"SMTDropdown"}
            onSelect={(e: any) => {
              setSmtValue(e);
              props.onSelectedSMT(e);
            }}
            size="sm"
            className="serviceAreas smtDropdown"
            id="serviceAreas"
          >
            {renderServiceAreas()?.map((smt: any, i: number) => {
              return (
                <Dropdown.Item key={`${smt}+ddItem+${i}`} eventKey={smt}>
                  {smt}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
        </p>
        <p>
          <span>{labels.PHUB.toUpperCase()}:</span>
          <span> {isNull(data?.phub)}</span>
          <span style={{ marginLeft: "20px" }}>
            {data?.vhub === "true" && `${labels.VHUB.toUpperCase()}:`}
          </span>
          <span> {isNull(vhubNumber)}</span>
          {data?.nodeType === "R-PHY" && <span className="vhub">RPHY</span>}
        </p>
      </div>
    );
  };

  const usPeers: Array<string> =
    data?.usPeers?.indexOf(",") !== -1
      ? data?.usPeers?.split(",")
      : data?.usPeers?.length > 1
      ? [data?.usPeers]
      : [];
  const vhubPeers: Array<string> =
    data?.vhubPeers?.indexOf(",") !== -1
      ? data?.vhubPeers?.split(",")
      : data?.vhubPeers?.length > 1
      ? [data?.vhubPeers]
      : [];

  const renderUsPeers = () => {
    return (
      <div>
        <span>{labels.US_PEERS?.toUpperCase()}:</span>
        {usPeers && usPeers?.length > 0 ? (
          usPeers?.map((node: string, i: number) => {
            return (
              <span
                className="nodeLink"
                onClick={() => {
                  if (data && window) {
                    openNewWindow(`/#/node?nodeId=${node}`);
                  }
                }}
              >
                {isNull(node)}
              </span>
            );
          })
        ) : (
          <span>{labels.NA}</span>
        )}
      </div>
    );
  };
  const onButtonClick = () => {
    const links = handleVhubClick();
    setHyperlinks(<>{links}</>);
  };
  const handleVhubClick = () => {
    return (
      <div className="vhubPeersContainer">
        {vhubPeers && vhubPeers?.length > 0 ? (
          vhubPeers?.map((node: string, i: number) => {
            return (
              <span
                className="vhubLink"
                onClick={() => {
                  if (data && window) {
                    openNewWindow(`/#/node?nodeId=${node}`);
                  }
                }}
              >
                {isNull(node)}
              </span>
            );
          })
        ) : (
          <span>{labels.NA}</span>
        )}
      </div>
    );
  };
  const renderPhub = () => {
    return (
      <div className="usInfo">
        <p>
          <span>{labels.PHUB.toUpperCase()}:</span>
          <span> {isNull(data?.phub)}</span>
          {data?.vhub === "true" && <span className="vhub">VHUB</span>}
          {data?.nodeType === "R-PHY" && <span className="vhub">RPHY</span>}
        </p>
        <p>
          <span>{labels.NODE_ID?.toUpperCase()}:</span>
          <span
            className="nodeLink"
            onClick={() => {
              if (data && window) {
                openNewWindow(`/#/node?nodeId=${data?.nodeId}`);
              }
            }}
          >
            {isNull(data?.nodeId)}
          </span>
        </p>
      </div>
    );
  };
  const relatedSMTs = () => {
    return (
      <>
        <p>
          <span>{labels.SMT.toUpperCase()}:</span>
          {renderServiceAreas()?.length !== 0 ? (
            renderServiceAreas()?.map((smt: any, i: number) => {
              if (isNumeric(smt)) {
                return (
                  <span
                    key={i + smt}
                    className="nodeLink"
                    onClick={() => {
                      if (data && window) {
                        openNewWindow(`/#/node?smtId=${smt}`);
                      }
                    }}
                  >
                    {isNull(smt)}
                  </span>
                );
              }
            })
          ) : (
            <span>{labels.NA}</span>
          )}
        </p>
      </>
    );
  };
  return (
    <div className="nodeInfo">
      <section className="nodeDetails">
        {data?.smt ? (
          <h4 id="smtID">
            <span>{labels.SMT_ID.toUpperCase()}:</span>
            <span>{data?.smtId}</span>
          </h4>
        ) : (
          <h4 id="nodeID">
            <span>{labels.NODE_ID.toUpperCase()}:</span>
            <span>{isNull(data?.nodeId)}</span>
            {data?.vhub === "true" && (
              <>
                <button onClick={onButtonClick} className="vhub">
                  <span>VHUB</span>
                </button>
                <div> {hyperlink}</div>
              </>
            )}
          </h4>
        )}

        <div className="nodeSummaryDiv">
          {data?.smt === false && renderSMTPeers()}
          <div>
            {data?.smt && relatedSMTs()}
            {data?.smt && renderPhub()}
          </div>

          <div className="cmtsInfo">
            <p>
              <span>{labels.CMTS_IP.toUpperCase()}:</span>
              <span
                className="nodeLink"
                onClick={() => {
                  openNewWindow(
                    `/#/node/cmts?nodeId=${data?.nodeId}&mac=${data?.macDomain}&usPort=${data?.usPort}&cmtsIp=${data?.cmtsIp}`
                  );
                }}
              >
                {isNull(data?.cmtsIp)}
              </span>
            </p>
            <p>
              <span>{labels.CMTS_NAME.toUpperCase()}:</span>
              <span>
                {" "}
                {isNull(data?.cmtsName)} / {rackidData?.cmtsLocation}
              </span>
            </p>
            <p>
              <span>{labels.US_PORT.toUpperCase()}:</span>
              <span> {isNull(data?.usPort)}</span>
            </p>
            <p>
              <span>{labels.DS_PORT.toUpperCase()}:</span>
              <span> {isNull(data?.dsPort)}</span>
            </p>
          </div>
          <div className="usInfo">
            <p>
              <span>{labels.MAC_DOMAIN.toUpperCase()}:</span>
              <span> {isNull(data?.macDomain)}</span>
            </p>
            <p>{usPeers && renderUsPeers()}</p>
          </div>
          <div className="usInfo">
            <p>
              <span>{labels.clamShellId.toUpperCase()}:</span>
              <span> {isNull(data?.clamShell)}</span>
            </p>
            <p>
              <span>{labels.clamshellPeers.toUpperCase()}:</span>
              {peers.length > 0 ? (
                peers.map((peer: any, index: any) => (
                  <span
                    key={index}
                    className="nodeLink"
                    onClick={() => {
                      if (window) {
                        openNewWindow(`/#/node?nodeId=${peer.trim()}`);
                      }
                    }}
                  >
                    {peer.trim()}
                  </span>
                ))
              ) : (
                <span>{isNull(data?.clamshellPeers)}</span>
              )}
            </p>
          </div>
        </div>
      </section>
      {getTechRoles(techData) && (
        <section
          className="nodeDetails teleScoreCont"
          id={
            diagDataSet === "FULL W/O CW" || diagDataSet === "BASIC"
              ? "hideNodeDetails"
              : ""
          }
        >
          {/* <OdmScores scores={nodeScoreDataConfig?.odmScores} isLoading={isLoading()} /> */}
          <div>
            <p className="scoreHeader">
              <span>{labels.Scores.toUpperCase()}</span>
              <span className="betaLabel">
                {"( " + labels.beta} <MdSettings size={20} />
                {")"}
              </span>
            </p>
          </div>
          <div className="scoreDiv">
            {nodeScoreDataConfig?.scores?.map((attr: any) => {
              let computedVal = convertHealthData(
                attr?.label,
                attr?.dataKey,
                labels
              );
              return (
                <p
                  className="smHealthScore"
                  key={"score" + attr?.label + attr?.dataKey}
                >
                  <span>{attr?.label}:</span>
                  <span>
                    {isLoading() ? (
                      <img
                        className="nodeTeleDataLoader"
                        src={LoaderGif}
                        alt="LoaderGif"
                      />
                    ) : (
                      computedVal
                    )}
                  </span>
                </p>
              );
            })}
          </div>
        </section>
      )}
      <section className="nodeDetails teleDeviceStatsCont">
        <p>
          <span>{labels.STATUS.toUpperCase()}</span>
        </p>
        <div className="rightSec">
          {nodeScoreDataConfig?.status?.map((attr: any) => {
            let computedVal = convertHealthData(
              attr?.label,
              attr?.dataKey,
              labels
            );
            return (
              <p key={"status" + attr?.label + attr?.dataKey}>
                <span>{attr?.label}:</span>
                <span>
                  {attr?.label !== labels.START ? (
                    isLoading() ? (
                      <img
                        className="nodeTeleDataLoader"
                        src={LoaderGif}
                        alt="LoaderGif"
                      />
                    ) : (
                      computedVal
                    )
                  ) : (
                    computedVal
                  )}
                </span>
              </p>
            );
          })}
        </div>
      </section>

      <section className="runTeleSec">
        <>
          <p className="teleSwitchCont">
            <span>{labels.TELEMETRY.toUpperCase()}</span>
            <NodeTopologySwitch style={{ alignSelf: "flex-end" }} />
          </p>
          <div className="modemTypeContainer" style={{ marginBottom: "5px" }}>
            <span className="modemTypeContainerText">DEVICE TYPE:</span>

            <MultiSelectDropdown
              defalutValue={props.modemTypeList[0]}
              data={props.modemTypeList}
              checkboxFlag={true}
              handleClick={props.onSelectedModemType}
            />
          </div>
          <div className="modemTypeContainer">
            <span className="modemTypeContainerText">
              {labels.DATA_SET.toUpperCase()}:
            </span>
            <span>
              <CustomDropDown
                selectedOption={dataSetName}
                options={dataSetNames}
                searchFlag={false}
                checkboxFlag={false}
                setSelectedOption={setDataSetName}
              />
            </span>
            {mlData !== undefined && mlData?.length !== 0 && (
              <div className="diagButHolder">
                {!allDiagIsLoading ? (
                  <button
                    className="refreshCertiBtn"
                    onClick={() => {
                      startDiag();
                      setDiagDataSet(dataSetName);
                    }}
                  >
                    {labels.START?.toUpperCase()} <FaRegPlayCircle />
                  </button>
                ) : (
                  <button
                    className="refreshCertiBtn"
                    onClick={() => {
                      stopDiag();
                      setDiagDataSet(dataSetName);
                    }}
                  >
                    {labels.STOP?.toUpperCase()} <FaRegStopCircle />
                  </button>
                )}
              </div>
            )}
          </div>
        </>
      </section>
    </div>
  );
};

export default NodeBasicInfo;
