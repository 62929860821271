import { CSSProperties } from "react";
import { BsCheck, BsPlus } from "react-icons/bs";

import "../../styles/ReusableComp.scss";

interface Props {
  label: string;
  checked: boolean;
  iconRequired?: boolean;
  setChecked: (arg0: boolean) => void;
  style?: CSSProperties;
}

export default function SelectionChip({
  label,
  checked,
  iconRequired = true,
  setChecked,
  style,
}: Props) {
  const icon = checked ? (
    <BsCheck color={`var(--primary)`} />
  ) : (
    <BsPlus color={`var(--greyDark)`} />
  );

  return (
    <div
      style={style}
      className={checked ? "selectChipContChecked" : "selectChipCont"}
      onClick={() => setChecked(!checked)}
    >
      {iconRequired ? icon : <></>}
      <label className={checked ? "selectChipTxtChecked" : "selectTxtChipCont"}>
        {label}
      </label>
    </div>
  );
}
