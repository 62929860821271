import { CMDocsisEventInfo } from "../../../../models/account/diagnostics/cm/CMDocsisEventInfo";
import {
  CM_DOCSIS_EVENT_INFO_BEGIN,
  CM_DOCSIS_EVENT_INFO_SUCCESS,
  CM_DOCSIS_EVENT_INFO_FAILURE,
  CMDocsisEventInfoActionTypes,
} from "../../../../types/account/diagnostics/cm/CMDocsisEvent";

export function cmDocsisEventInfoBegin(): CMDocsisEventInfoActionTypes {
  return {
    type: CM_DOCSIS_EVENT_INFO_BEGIN,
  };
}

export function cmDocsisEventInfoSuccess(
  docsisEventsInfo: CMDocsisEventInfo[]
): CMDocsisEventInfoActionTypes {
  return {
    type: CM_DOCSIS_EVENT_INFO_SUCCESS,
    payload: docsisEventsInfo,
  };
}

export function cmDocsisEventInfoFailure(
  error: Error
): CMDocsisEventInfoActionTypes {
  return {
    type: CM_DOCSIS_EVENT_INFO_FAILURE,
    payload: error,
  };
}
