import { useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import TowerDetailTableHead from "./TowerDetailTableHead";
import "../../design-tokens/certification/CertificationTab.scss";
import { isNull } from "../reusable/Util";

const CellDetailTable = (props: any) => {
  const { towerDetails, ecgi } = props;
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const cellData = useMemo(() => {
    let computedCells = towerDetails?.cells;

    //Sorting Certies
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;

      computedCells = computedCells.sort((a: any, b: any) => {
        return reversed * a[sorting.field]?.localeCompare(b[sorting.field]);
      });
    }
    return computedCells;
  }, [sorting, towerDetails?.cells]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows = 1;

  return (
    <>
      <div className="certiTableCont">
        <TableContainer>
          <Table className="deviceTable">
            <TowerDetailTableHead
              onSorting={(field: any, order: any) =>
                setSorting({ field, order })
              }
            />
            <TableBody className="dTabBody">
              {cellData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((cellInfo: any, index: number) => {
                  return (
                    <TableRow tabIndex={-1} key={index}>
                      <TableCell
                        align="left"
                        className={cellInfo?.ecgi === ecgi ? "selected" : ""}
                      >
                        {isNull(cellInfo.azimuth)}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={cellInfo?.ecgi === ecgi ? "selected" : ""}
                      >
                        {isNull(cellInfo.cellName)}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={cellInfo?.ecgi === ecgi ? "selected" : ""}
                      >
                        {isNull(cellInfo.ecgi)}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={
                          cellInfo?.operState == "ENABLED"
                            ? "active"
                            : "inActive"
                        }
                      >
                        {isNull(cellInfo.operState)}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={cellInfo?.ecgi === ecgi ? "selected" : ""}
                      >
                        {isNull(cellInfo.technology)}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={cellInfo?.ecgi === ecgi ? "selected" : ""}
                      >
                        {isNull(cellInfo.bandwidth)}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={cellInfo?.ecgi === ecgi ? "selected" : ""}
                      >
                        {isNull(cellInfo.frequency)}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={cellInfo?.ecgi === ecgi ? "selected" : ""}
                      >
                        {isNull(cellInfo.plannedStatus)}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={cellInfo?.ecgi === ecgi ? "selected" : ""}
                      >
                        {isNull(cellInfo.radioType)}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={cellInfo?.ecgi === ecgi ? "selected" : ""}
                      >
                        {isNull(cellInfo.adminState)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {cellData?.length !== 0 && emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="certiPageCont">
          <p>TOTAL DATA : {cellData?.length}</p>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={cellData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
};

export default CellDetailTable;
