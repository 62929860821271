import { MutableRefObject, useState, CSSProperties } from "react";
import { ImCamera } from "react-icons/im";
import { useDispatch } from "react-redux";
import html2canvas from "html2canvas";

import { setDrawerView } from "../../../store/slices/mapDrawer";
import { DrawerViewType } from "../../../store/reduxTypes";
import ActivityIndicator from "../../../../components/reusable/ActivityIndicator";
import { paths } from "../../../../routes/Routes";
import {
  GetGoogleMaps,
  GetTopoGeneralProps,
} from "../../../util/reduxFunctions/getTopologyState";
import { GetTechInfo } from "../../../../get-api/GetResponse";

interface Props {
  mapContRef: MutableRefObject<null>;
  contStyle?: CSSProperties;
  owningDrawing?: string;
}

export default function ImageEditor({
  mapContRef,
  contStyle,
  owningDrawing,
}: Props) {
  const dispatch = useDispatch();
  const { activeNodeId: nodeId } = GetTopoGeneralProps();
  const { currentMapCenter: latLng } = GetGoogleMaps();
  const { data: techData } = GetTechInfo();
  const [imgIsLoading, setImgIsLoading] = useState(false);

  const captureScreenshot = () => {
    dispatch(setDrawerView("close" as DrawerViewType));
    setImgIsLoading(true);
    setTimeout(() => {
      if (mapContRef.current) {
        const mapContainer = mapContRef.current;
        if (mapContainer) {
          html2canvas(mapContainer, { useCORS: true })
            .then((canvas) => {
              const dataUrl = canvas.toDataURL("image/png");
              setImgIsLoading(false);

              const url = window?.open(`/#` + paths.imageEditor);
              url?.addEventListener("load", () => {
                url.document.title = paths.imageEditor;
                url.postMessage(
                  { bgImage: dataUrl, nodeId, latLng, techData, owningDrawing },
                  url.origin
                );
              });
            })
            .catch((error) => {
              console.error("Error capturing map screenshot:", error);
            });
        }
      }
    }, 2500);
  };

  return (
    <>
      <div
        className="addMemoCont"
        style={{ bottom: "240px", ...contStyle }}
        onClick={captureScreenshot}
      >
        {imgIsLoading ? (
          <ActivityIndicator
            style={{ width: "40px", height: "20px" }}
            animationAlignement="center"
          />
        ) : (
          <ImCamera />
        )}
      </div>
    </>
  );
}
