export const SAVE_ACTIVE_CERTI_DEVICES = "SAVE_ACTIVE_CERTI_DEVICES";
export const UPDATE_ACTIVE_CERTI_DEVICES = "UPDATE_ACTIVE_CERTI_DEVICES";
export const RESET_ACTIVE_CERTI_FAILURE = "RESET_ACTIVE_CERTI_FAILURE";
export const INCREMENT_API_CALL_COUNTER = "INCREMENT_API_CALL_COUNTER";
export const DECREMENT_API_CALL_COUNTER = "DECREMENT_API_CALL_COUNTER";
interface SaveActiveCertiDevices {
  type: typeof SAVE_ACTIVE_CERTI_DEVICES;
  payload: any;
}
interface UpdateActiveCertiDevices {
  type: typeof UPDATE_ACTIVE_CERTI_DEVICES;
  devicekey: string;
  subdataKey: string;
  data: any;
}
interface ResetActiveCertiDevices {
  type: typeof RESET_ACTIVE_CERTI_FAILURE;
}
interface IncrementApiCallCounter {
  type: typeof INCREMENT_API_CALL_COUNTER;
  devicekey: string;
}
interface DecrementApiCallCounter {
  type: typeof DECREMENT_API_CALL_COUNTER;
  devicekey: string;
}
export type ActiveCertiDevicesActionTypes =
  | SaveActiveCertiDevices
  | UpdateActiveCertiDevices
  | ResetActiveCertiDevices
  | IncrementApiCallCounter
  | DecrementApiCallCounter;
