import { Divider } from "@material-ui/core";
import { InfoBox, InfoWindow, Marker } from "@react-google-maps/api";
import colors from "../../../../config/colors";
import TopologySymbols from "../../../reusable/TopologySymbols";
import { useEffect, useState } from "react";
import { BsArrowsMove } from "react-icons/bs";
import { markerToString } from "../../../../util/utilFuncs";
import { mapElementStyles } from "../../../../config/mapItemsConfig";

interface Props {
  eolBlockData: any;
  click: any;
  setClick: any;
}
const RFEOLBlocks = ({ eolBlockData, click, setClick }: Props) => {
  const latLng = new google.maps.LatLng(
    eolBlockData?.geometry?.coordinates?.[1],
    eolBlockData?.geometry?.coordinates?.[0]
  );
  const lat=eolBlockData?.geometry?.coordinates?.[1];
  const lng=eolBlockData?.geometry?.coordinates?.[0];
  const position = {
    lat: Number(eolBlockData?.geometry?.coordinates?.[1]),
    lng: Number(eolBlockData?.geometry?.coordinates?.[0]),
  };
  const divStyle = {
    background: `white`,
  };
  const showInfoWindow = () => {
    setClick(false);
  };
  const onCloseClick = () => {
    setClick(false);
  };
  const [showInfoBox, setShowInfoBox] = useState(false);
  useEffect(() => {
    setShowInfoBox(true);
  }, []);

  return (
    <div onClick={showInfoWindow}>
      {click && (
        <InfoWindow
          position={position}
          options={{ pixelOffset: new google.maps.Size(15, 0) }}
          onCloseClick={onCloseClick}
        >
          <div style={divStyle}>
            <TopologySymbols
              name="rf_eol_block"
              color={`var(--iconColor)`}
              size={150}
            />
          </div>
        </InfoWindow>
      )}
      <Marker
      position={{lat,lng }}
      icon={{
        url: markerToString(
          <BsArrowsMove color={colors.greyLight} size={20} />
        ),
        anchor:new google.maps.Point(mapElementStyles?.common?.anchor?.x - 20,
          mapElementStyles?.common?.anchor?.y + 3)
      }}
      draggable
      cursor="move"
      zIndex={4000}
    >
     {showInfoBox && (<InfoBox
        position={latLng}
        options={{
          closeBoxURL: "",
          disableAutoPan: true,
          boxStyle: {
            width: "auto",
            padding: "5px",
          },
        }}
      >
        <div className="eolBlock">
          <div className="tombstone">
            <div>{eolBlockData?.properties?.data?.tagText?.INP_D1}</div>
            <Divider
              style={{
                margin: "5px -4px 5px -4px",
                backgroundColor: colors.greyLight,
              }}
            />
            <div>{eolBlockData?.properties?.data?.tagText?.INP_D2}</div>
            <Divider
              style={{
                margin: "5px -4px 5px -4px",
                backgroundColor: colors.greyLight,
              }}
            />
            <div>{eolBlockData?.properties?.data?.tagText?.INP_D3}</div>
          </div>
          <span className="tombstoneSpan">
            {eolBlockData?.properties?.data?.tagText?.FT2LAST}
          </span>
        </div>
      </InfoBox>)}
      </Marker>
    </div>
  );
};

export default RFEOLBlocks;
