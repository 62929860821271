import AppLabels from "../../constants/App_Labels";
import CustomAccordion from "../reusable/CustomAccordion";
import {
  isNull,
  getThreshold,
  getStatusOfCheck,
  getCheckValue,
  isEmptyObject,
  getStatusOfCheckHeader,
  formatBitsToSize,
  hzToMHz,
  getMultiStatusOfCheck,
} from "../reusable/Util";
import CustomPopOver from "../reusable/CustomPopOver";
const STBDocsisInfo = (props: any) => {
  const { deviceData } = props;
  const labels = AppLabels();
  return (
    <CustomAccordion
      accTitle={labels.DOCSIS.toUpperCase()}
      accBodyElement={
        <table className="dataTableDetails">
          <thead>
            <th>NAME</th>
            <th>VALUE</th>
            <th>STATUS</th>
          </thead>
          <tbody>
            <tr>
              <td>{labels.Upstream_Bitrate.toUpperCase()}</td>
              <td>
                {isNull(formatBitsToSize(deviceData?.speed?.upstreamBitrate))}
              </td>
              <td></td>
            </tr>
            <tr>
              <td>{labels.Downstream_Bitrate.toUpperCase()}</td>
              <td>
                {isNull(formatBitsToSize(deviceData?.speed?.downstreamBitrate))}
              </td>
              <td></td>
            </tr>
            <tr>
              <td>{labels.US_Freq.toUpperCase()}</td>
              <td className="cellNewLine">
                {deviceData?.upstreamChannels?.map((ch: any) => {
                  return hzToMHz(ch?.frequency) + " " + labels.Mhz + "\n";
                })}
              </td>
              <td></td>
            </tr>
            <tr>
              <td>{labels.DS_Freq.toUpperCase()}</td>
              <td className="cellNewLine">
                {deviceData?.downstreamChannels?.map((ch: any) => {
                  return hzToMHz(ch?.frequency) + " " + labels.Mhz + "\n";
                })}
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                {labels.US.toUpperCase() +
                  " " +
                  labels.MODULATION.toUpperCase()}
              </td>
              <td>{isNull(deviceData?.upstreamChannels?.[0]?.modulation)}</td>
              <td></td>
            </tr>
            <tr>
              <td>
                {getStatusOfCheckHeader(
                  deviceData?.upstreamChannels?.[0]?.tx_power,
                  labels.TX_POWER.toUpperCase()
                )}
              </td>
              {deviceData?.hasOwnProperty("upstreamChannels") &&
              deviceData?.upstreamChannels?.length !== 0 ? (
                <>
                  <td className="cellNewLine">
                    {deviceData?.upstreamChannels?.map((ch: any) => {
                      return isNull(getCheckValue(isEmptyObject(ch?.tx_power)));
                    })}
                  </td>
                  <CustomPopOver
                    popOverData={getThreshold(
                      isEmptyObject(deviceData?.upstreamChannels?.[0]?.tx_power)
                    )}
                    originComp={getMultiStatusOfCheck(
                      deviceData?.upstreamChannels,
                      "tx_power"
                    )}
                    index="popOver"
                  />
                </>
              ) : (
                <>
                  <td>N/A</td>
                  <td></td>
                </>
              )}
            </tr>
            <tr>
              <td>{labels.TX_Delta.toUpperCase()}</td>
              <td>{isNull(deviceData?.txMargin)}</td>
              <td></td>
            </tr>
            <tr>
              <td>
                {getStatusOfCheckHeader(
                  deviceData?.downstreamChannels?.[0]?.rx_power,
                  labels.RX_POWER.toUpperCase()
                )}
              </td>
              {deviceData?.hasOwnProperty("downstreamChannels") &&
              deviceData?.downstreamChannels?.length !== 0 ? (
                <>
                  <td className="cellNewLine">
                    {deviceData?.downstreamChannels?.map((ch: any) => {
                      return isNull(getCheckValue(isEmptyObject(ch?.rx_power)));
                    })}
                  </td>
                  <CustomPopOver
                    popOverData={getThreshold(
                      isEmptyObject(
                        deviceData.downstreamChannels?.[0]?.rx_power
                      )
                    )}
                    originComp={getMultiStatusOfCheck(
                      deviceData?.downstreamChannels,
                      "rx_power"
                    )}
                    index="popOver"
                  />
                </>
              ) : (
                <>
                  <td>N/A</td>
                  <td></td>
                </>
              )}
            </tr>
            <tr>
              <td>{labels.RX_Delta.toUpperCase()}</td>
              <td>{isNull(deviceData?.rxMargin)}</td>
              <td></td>
            </tr>
            <tr>
              {/* <td>{labels.CMTS_CM_RX}</td>
              <td className="cellNewLine">
                {deviceData?.upstreamChannels?.map((ch: any) => {
                  return isNull(ch?.rx_power) + "\n";
                })}
              </td>
              <td></td> */}
              <td>
                {getStatusOfCheckHeader(
                  deviceData?.upstreamChannels?.[0]?.rx_power,
                  labels.CMTS_CM_RX
                )}
              </td>
              {deviceData?.hasOwnProperty("upstreamChannels") &&
              deviceData?.upstreamChannels?.length !== 0 &&
              deviceData?.upstreamChannels?.[0]?.hasOwnProperty("rx_power") ? (
                <>
                  <td className="cellNewLine">
                    {deviceData?.upstreamChannels?.map((ch: any) => {
                      return isNull(getCheckValue(isEmptyObject(ch?.rx_power)));
                    })}
                  </td>
                  <CustomPopOver
                    popOverData={getThreshold(
                      isEmptyObject(deviceData?.upstreamChannels?.[0]?.rx_power)
                    )}
                    originComp={getMultiStatusOfCheck(
                      deviceData?.upstreamChannels,
                      "rx_power"
                    )}
                    index="popOver"
                  />
                </>
              ) : (
                <>
                  <td>N/A</td>
                  <td></td>
                </>
              )}
            </tr>
            <tr>
              <td>
                {getStatusOfCheckHeader(
                  deviceData?.upstreamChannels?.[0]?.snr,
                  labels.CMTS_CM_SNR
                )}
              </td>
              {deviceData?.hasOwnProperty("upstreamChannels") &&
              deviceData?.upstreamChannels?.length !== 0 &&
              deviceData?.upstreamChannels?.[0]?.hasOwnProperty("snr") ? (
                <>
                  <td className="cellNewLine">
                    {deviceData?.upstreamChannels?.map((ch: any) => {
                      return isNull(getCheckValue(isEmptyObject(ch?.snr)));
                    })}
                  </td>
                  <CustomPopOver
                    popOverData={getThreshold(
                      isEmptyObject(deviceData?.upstreamChannels?.[0]?.snr)
                    )}
                    originComp={getMultiStatusOfCheck(
                      deviceData?.upstreamChannels,
                      "snr"
                    )}
                    index="popOver"
                  />
                </>
              ) : (
                <>
                  <td>N/A</td>
                  <td></td>
                </>
              )}
            </tr>
            <tr>
              <td>
                {getStatusOfCheckHeader(
                  deviceData?.downstreamChannels?.[0]?.snr,
                  "DOWNSTREAM SNR"
                )}
              </td>
              {deviceData?.hasOwnProperty("downstreamChannels") &&
              deviceData?.downstreamChannels?.length !== 0 &&
              deviceData?.downstreamChannels?.[0]?.hasOwnProperty("snr") ? (
                <>
                  <td className="cellNewLine">
                    {deviceData?.downstreamChannels?.map((ch: any) => {
                      return isNull(getCheckValue(isEmptyObject(ch?.snr)));
                    })}
                  </td>
                  <CustomPopOver
                    popOverData={getThreshold(
                      isEmptyObject(deviceData.downstreamChannels?.[0]?.snr)
                    )}
                    originComp={getMultiStatusOfCheck(
                      deviceData?.downstreamChannels,
                      "snr"
                    )}
                    index="popOver"
                  />
                </>
              ) : (
                <>
                  <td>N/A</td>
                  <td></td>
                </>
              )}
            </tr>
          </tbody>
        </table>
      }
    />
  );
};

export default STBDocsisInfo;
