export const SAVE_NODE_DEVICES = "SAVE_NODE_DEVICES";
export const UPDATE_NODE_DEVICES = "UPDATE_NODE_DEVICES";
export const RESET_NODE_DEVICES = "RESET_NODE_DEVICES";
export const START_NODE_DEVICE_DIAG = "START_NODE_DEVICE_DIAG";
export const SET_NODE_TELE_DIAG_LOADING = "SET_NODE_TELE_DIAG_LOADING";
export const SET_NODE_TELE_DIAG_TYPE = "SET_NODE_TELE_DIAG_TYPE";
export const SET_TELE_DATASET_TYPE = "SET_TELE_DATASET_TYPE";
export const SET_TELE_VIEW_TYPE = "SET_TELE_VIEW_TYPE";
export const UPDATE_NODE_DEVICES_STREAM = "UPDATE_NODE_DEVICES_STREAM";
export const UPDATE_NODE_DEVICES_ARRAY = "UPDATE_NODE_DEVICES_ARRAY";
export const SET_NODE_DEVICES_ERROR = "SET_NODE_DEVICES_ERROR";

interface SetNodeTeleDiag {
  type: typeof SET_NODE_TELE_DIAG_LOADING;
  payload: boolean;
}
interface SetNodeTeleDiagType {
  type: typeof SET_NODE_TELE_DIAG_TYPE;
  payload: string;
}

interface StartNodeDeviceDiag {
  type: typeof START_NODE_DEVICE_DIAG;
  deviceKey: string;
}
interface SaveNODEDevices {
  type: typeof SAVE_NODE_DEVICES;
  payload: any;
}

interface UpdateNODEDevices {
  type: typeof UPDATE_NODE_DEVICES;
  devicekey: string;
  apiToUpdate: string;
  data: any;
  status: string;
}

interface updateNODEDevicesStream {
  type: typeof UPDATE_NODE_DEVICES_STREAM;
  macAddress: string;
  apiToUpdate: string;
  data: any;
  status: string;
}
interface updateNODEDevicesArray {
  type: typeof UPDATE_NODE_DEVICES_ARRAY;
  data: any;
  apiToUpdate: string;
}
interface SetNodeDevicesError {
  type: typeof SET_NODE_DEVICES_ERROR;
  payload: any;
}

interface ResetNODEDevices {
  type: typeof RESET_NODE_DEVICES;
}

interface SetTeleDatasetType {
  type: typeof SET_TELE_DATASET_TYPE;
  payload: any;
}

interface SetTeleViewType {
  type: typeof SET_TELE_VIEW_TYPE;
  payload: any;
}

export type NODEDevicesActionTypes =
  | SetNodeTeleDiag
  | SetNodeTeleDiagType
  | StartNodeDeviceDiag
  | SaveNODEDevices
  | UpdateNODEDevices
  | updateNODEDevicesStream
  | updateNODEDevicesArray
  | SetNodeDevicesError
  | ResetNODEDevices
  | SetTeleDatasetType
  | SetTeleViewType;
