import { useTranslation } from "react-i18next";

export default function AppLabels() {
  const { t, i18n } = useTranslation();
  return Object.freeze({
    APP_NAME_LABEL: t("Tech Insight"),
    /*Changes added by node Team for universal search placeholder, can delete if this placeholder not used*/
    //SEARCH_BOX_TEXT: t("SearchPlaceholder"),
    //SEARCH_BOX_TEXT: t("UniversalSearchPlaceholder"),
    /*Node Team changes completed 
    UniversalSearchPlaceholder has been added in translation.json*/
    RF_CALCULATOR: t("RF_CALCULATOR"),
    RF_ST_CALCULATOR: t("RF_ST_CALCULATOR"),
    FILE_MANAGER: t("FileManager"),
    mgigCapable:t("mgigCapable"),
    mgigCapacity: t("mgigCapacity"),
    mgigPlannedDate: t("mgigPlannedDate"),
    mgigInServiceDate: t("mgigInServiceDate"),
    AddSTBEmail: t("Add STB POD Email"),
    SEARCH_BOX_TEXT: t("SearchPlaceholder"),
    SEARCH_BOX_NODE_TEXT: t("SearchPlaceholderNode"),
    SEARCH_BOX_ACCOUNT_TEXT: t("SearchPlaceholderAccount"),
    ONT_SN_SEARCH_BOX_TEXT: t("ONTSNSearchPlaceholder"),
    GW_DEVICES_SUMMARY: t("Gw Summary"),
    SEARCH_BTN_TEXT: t("Search"),
    ASP: t("ASP"),
    STB: t("STB"),
    FREE_POD: t("FREE POD"),
    PAID_POD: t("PAID POD"),
    errorDeviceSkip: t("errorDeviceSkip"),
    SEARCH_RESULTS: t("SEARCH RESULT(S)"),
    Add_Ignite_STBs_or_Pods: t("Add_Ignite_STBs_or_Pods"),
    Compliment: t("Compliment"),
    Quality: t("Quality"),
    Misinformation: t("Misinformation"),
    MultiDeviceSearch: t("MultiDeviceSearch"),
    macMultiSearch: t("macMultiSearch"),
    multiErr: t("multiErr"),
    accountMultiSearch: t("accountMultiSearch"),
    Education: t("Education"),
    Compliance: t("Compliance"),
    SummaryStb: t("SummaryStb"),
    ACCOUNT_NUMBER: t("Account Number"),
    SERVICE_ADDRESS: t("Service Address"),
    CLOSE_BTN_TEXT: t("Close"),
    BC_HOME: t("Home"),
    regstatus: t("regstatus"),
    technician_Id: "Technician ID",
    technician_Name: "Technician Name",
    Call_Type: t("Call Type"),
    BC_ACC_PAGE: t("Account"),
    accountSummaryResponse: t("accountSummaryResponse"),
    ACCOUNT_TYPE: t("Account Type"),
    Email_Info: t("Customer or Manager's Email"),
    Email: t("Email"),
    REPLACEFECERRORMESSAGE: t("Modem has dropped OFDM profile"),
    ACCOUNT_ID: t("Account Id"),
    CBP_ID: t("CBP ID"),
    HHID: t("HHID"),
    OVERVIEW: t("Overview"),
    DIAGNOSTICS: t("Diagnostics"),
    CUSTOMER_TELEPHONE_NUMBER: t("Customer Telephone Number"),
    HOME_PHONE: t("Home Phone"),
    CUSTOMER_SERVICE_AREA: t("Customer Service Area"),
    CURREENT_SERVICE_ADDRESS: t("Current Service Address"),
    OTHER_SERVICE_ADDRESS: t("Other Service Addresses"),
    UNIT: t("Unit"),
    HASHSIGN: t("#"),
    NA: t("N/A"),
    HUB: t("HUB"),
    NAP: t("NAP"),
    NODE: t("NODE"),
    HYPHEN: t("-"),
    NEIGHBOURHOOD_NETWORK: t("Neighbourhood Network"),
    SMT: t("SMT"),
    SHUB: t("SHUB"),
    PHUB: t("PHUB"),
    UPSTREAM_SCQAM_CHANNELS: t("Upstream_ScQAM_Channels"),
    DOWNSTREAM_SCQAM_CHANNELS: t("Downstream_SCQAM_Channels"),
    UNCORRECTABLES_DELTA: t("Uncorrectables Delta"),
    OFDMA_CHANNEL: t("Ofdma_Channels"),
    OFDM_CHANNELS: t("Ofdm_Channels"),
    CUSTOMER_PROVISIONED_DEVICES: t("Customer's Provisioned Devices"),
    NO_DEVICES_AVAILABLE: t("No Devices Available..."),
    DEVICE_CATEGORY: t("Device Category"),
    MODEL: t("Model"),
    MAC_ADDRESS: t("MAC Address"),
    SERIAL_NUMBER: t("Serial Number"),
    SERIAL_NO: t("Serial #"),
    ACTION: t("Action"),
    TOTAL_PODS: t("Total Pods"),
    NO_DIAGNOSTICS: t("No Diagnostics"),
    NO_ACTIONS: t("No Actions"),
    ARE_YOU_SURE_TO_PROCEED: t("Are you sure to proceed ?"),
    SCQAM_QUESTION: t("Are you sure you want to submit?"),
    CANCEL: t("Cancel"),
    CANCEL_MESSAGE: t("CancelMessage"),
    ABORT_MESSAGE: t("AbortMessage"),
    PROCEED: t("Proceed"),
    YOU_ARE_TRYING_TO: t("You are trying to"),
    THIS_DEVICE: t("this device"),
    CAUTION: t("Caution"),
    PODS: t("Pods"),
    PROVISIONED_DEVICES: t("Provisioned Devices"),
    ACTIVE_DEVICES_LIST_AND_DIAGNOSTICS: t(
      "Active Devices List and Diagnostics"
    ),
    PRECHECK_DATA: t("Precheck Data"),
    LAST_PRECHECK_DIAGNOSTICS_DATA: t("Last Precheck Diagnostics Data"),
    DIAGNOSTICS_HISTORY: t("Diagnostics History"),
    TEST_RESULTS_FROM_CURRENT_SESSION: t("Test Results from Current Session"),
    PODS_DEVICES: t("Pods Devices"),
    NO_PODS_AVAILABLE: t("No PODS Available..."),
    STATUS: t("Status"),
    FETCHING: t("Fetching.."),
    CABLE_MODEM: t("Cable Modem"),
    GW_DEVICES: t("Gw Devices"),
    WIFI_SETTINGS: t("Wifi Settings"),
    CM: t("CM"),
    Mac_Id: t("Mac Id"),
    cmMac: t("CM MAC"),
    GW: t("GW"),
    WIFI: t("WIFI"),
    POD: t("Pod"),
    POD_DETAILS: t("Pod Details"),
    SUMMARY: t("Summary"),
    NAME: t("Name"),
    VALUE: t("Value"),
    CURRENT_VALUE: t("Current Value"),
    PRECHECK_VALUE: t("Precheck Value"),
    PRECHECK_CHANNELS: t("Precheck Channels"),
    PRECHECK_OFDM: t("Precheck OFDM"),
    PRECHECK_SCQAM: t("Precheck SCQAM"),
    PRECHECK_CHART: t("Precheck Chart"),
    PRECHECK_MIN: t("Precheck Min"),
    PRECHECK_MAX: t("Precheck Max"),
    PRECHECK_DIFFERENCE: t("Precheck Difference"),
    DIFFERENCE: t("Difference"),
    LENGTHOFDROP: t("LengthOfDrop"),
    RESISTANCEOFDROP: t("ResistanceOfDrop"),
    WHYPARAMFAILS: t("WhyParamFails"),
    VALIDATEGOODCOND: t("ValidateGoodCond"),
    DELTA: t("Delta"),
    DEVICE_ID: t("Device Id"),
    DEVICE_NAME: t("Device Name"),
    DEVICE_TYPE: t("Device Type"),
    FIRMWARE_VERSION: t("Firmware Version"),
    CONNECTION_STATUS: t("Connection Status"),
    HEALTH_STATUS: t("Health Status"),
    HEALTH_SCORE: t("Health Score"),
    STATISTICS: t("Statistics"),
    MAC: t("Mac"),
    ONLINE_PERCENTAGE: t("Online Percentage"),
    CareSales: t("CareSales"),
    TechSupport: t("TechSupport"),
    CM_STATUS: t("Cm Status"),
    ONLINE_TIMES: t("Online Times"),
    MGMT_IPV6: t("Mgmt Ipv6"),
    MGMT_IPV4: t("Mgmt Ipv4"),
    WAN_IP: t("Wan Ip"),
    WAN_IPV6: t("Wan Ipv6"),
    CM_POWER_UPTIME: t("Cm Power Uptime"),
    CM_REGISTRATION_TIME: t("Cm Registration Time"),
    BRIDGE_MODE_STATUS: t("Bridge Mode Status"),
    TR069_STATUS: t("Tr069 Status"),
    INTERNET_SPEED_INFORMATION: t("Internet Speed Information"),
    SUBSCRIBER_DOWNSTREAM_BITRATE: t("Subscriber Downstream Bitrate"),
    SUBSCRIBER_UPSTREAM_BITRATE: t("Subscriber Upstream Bitrate"),
    PROVISIONED_DOWNSTREAM_SPEED: t("Provisioned Downstream Speed"),
    PROVISIONED_UPSTREAM_SPEED: t("Provisioned Upstream Speed"),
    NETWORK_INFO: t("Network Info"),
    NODE_ARCHITECTURE: t("Node Architecture"),
    VHUB: t("VHUB"),
    CM_REGISTRATION_STATUS: t("Cm Registration Status"),
    CMTS_IP: t("Cmts Ip"),
    CMTS_NAME: t("Cmts Name"),
    CMTS_UPTIME: t("Cmts Uptime"),
    DOWNSTREAM_CHANNELS: t("Downstream Channels"),
    UPSTREAM_CHANNELS: t("Upstream Channels"),
    THRESHOLD: t("Threshold"),
    CHANNEL_ID: t("Channel Id"),
    MAX_MODULATION: t("Max Modulation"),
    MIN_MODULATION: t("Min Modulation"),
    MER_MEAN: t("Mer Mean"),
    MER_2PCT: t("Mer 2PCT"),
    MER_STD: t("Mer Std"),
    RX_POWER_MAX: t("Rx Power Max"),
    RX_POWER_MIN: t("Rx Power Min"),
    RX_POWER_DELTA: t("Rx Power Delta"),
    RX_POWER_AVG: t("Rx Power Avg"),
    CHANNELS: t("Channels"),
    ACTIVE_PROFILES: t("Active Profiles"),
    FREQUENCY: t("FREQUENCY (MHz)"),
    RX_POWER: t("RX_POWER"),
    DOWN_RX_MAX: t("Down Rx Max"),
    DOWN_RX_MIN: t("Down Rx Min"),
    DOWN_RX_DELTA: t("Down Rx Delta"),
    DOWN_RX_AVG: t("Down Rx Avg"),
    DOWN_SNR_MAX: t("Down Snr Max"),
    DOWN_SNR_MIN: t("Down Snr Min"),
    DOWN_SNR_DELTA: t("Down Snr Delta"),
    DOWN_SNR_AVG: t("Down Snr Avg"),
    DOWN_FRQ_MAX: t("Down Frq Max"),
    DOWN_FRQ_MIN: t("Down Frq Min"),
    DOWN_FRQ_DELTA: t("Down Frq Delta"),
    DOWN_FRQ_AVG: t("Down Frq Avg"),
    MODULATION: t("Modulation"),
    SNR: t("Snr"),
    FREQ_LOWER_BOUNDARY: t("Freq Lower Boundary"),
    FREQ_UPPER_BOUNDARY: t("Freq Upper Boundary"),
    OFDMA_CHANNELS: t("Ofdma Channels"),
    TX_POWER: t("Tx Power"),
    UP_TX_MAX: t("Up Tx Max"),
    UP_TX_MIN: t("Up Tx Min"),
    UP_TX_DELTA: t("Up Tx Delta"),
    UP_TX_AVG: t("Up Tx Avg"),
    SIGNAL_DBM: t("SIGNAL(dBm)"),
    IS_ONLINE: t("Is Online"),
    CONNECTION_TYPE: t("Connection Type"),
    HOSTNAME: t("Hostname"),
    WIFI_TPF: t("WIFI 2.4G"),
    WIFI_FG: t("WIFI 5G"),
    WIFI_6G: t("WIFI 6G"),
    CM_TRANSMIT_POWER: t("Cm Transmit Power"),
    CM_RECEIVE_POWER: t("Cm Receive Power"),
    DOWNSTREAM_SNR: t("Downstream Snr"),
    UPSTREAM_SNR: t("Upstream Snr"),
    LINES: t("Lines"),
    LINE_NO: t("Line No"),
    HOOK_STATUS: t("Hook Status"),
    NCS_STATUS: t("Ncs End Point Status"),
    LINE_STATE: t("Line State"),
    CALL_AGENT_ID: t("Call Agent Id"),
    CALL_PSTATE: t("Call P State"),
    SIP_URI: t("Sip Uri"),
    SIP_REG_STATUS: t("Sip Reg Status"),
    CUSTOMER_ADDRESS: t("Customer Address"),
    BUILDING_ADDRESS: t("Building Address"),
    UNIT_NUMBER: t("Unit Number"),
    CITY: t("City"),
    PROVINCE: t("Province"),
    POSTAL_CODE: t("Postal Code"),
    CUSTOMER_CONTACT_NUMBER: t("Customer Contact Number"),
    TECH_INFO: t("Technician Information"),
    PRIORITY: t("Priority"),
    Property_Management_Contact_Number: t("Property_Management_Contact_Number"),
    Management_Number: t("Management_Number"),
    TDR_FOOTAGE: t("TDR_FOOTAGE"),
    Riser_Tagged_In_Panel: t("Riser_Tagged_In_Panel"),
    Panel_Location: t("Panel_Location"),
    TDR_Footage_From_Wallplate: t("TDR_Footage_From_Wallplate"),
    TDR_Footage_From_Panel: t("TDR_Footage_From_Panel"),
    OHM_Readings: t("OHM_Readings"),
    Levels_At_Tap: t("Levels_At_Tap"),
    Levels_At_Set: t("Levels_At_Set"),
    Temp_Run: t("Temp_Run"),
    JWO_Info: t("JWO_Info"),
    RRF: t("RRF"),
    Completed_Riser_Repair_Referral_Form: t(
      "Completed_Riser_Repair_Referral_Form"
    ),
    FbEmailGreetings: t("FbEmailGreetings"),
    RRFEmailGreetings: t("RRFEmailGreetings"),
    EmailInfo: t("Here is some information related to the issue."),
    DesOfIssue: t("Description of issue"),
    DOCSIS: t("Docsis"),
    SEARCH_POPUP_ALLOW_TEXT: t(
      "Allow us to help you find what you are looking for."
    ),
    DEVICE: t("Device"),
    NETWORK: t("Network"),
    ETHERNET: t("Ethernet"),
    IPTV_WIFI: t("Wifi"),
    WIRELESS_INFO: t("Wireless Info"),
    EXTERNAL_LINK: t("External Links"),
    REFRESH: t("Refresh"),
    COLLAPSE: t("Collapse"),
    EXPAND: t("Expand"),
    VERTICAL_VIEW: t("Vertical View"),
    HORIZONTAL_VIEW: t("Horizontal View"),
    FEEDBACK: t("Feedback"),
    FeedbackExceptionMsg: t("FeedbackExceptionMsg"),
    MONTHLY_QUESTION: t("MonthlyQuestion"),
    MONTHLY_WHY: t("MonthlyWhy"),
    preview: t("preview"),
    SUBMIT: t("Submit"),
    DONT_ASK: t("DontAsk"),
    SHARE_FEEDBACK: t("ShareYourFeedback"),
    FEEDBACK_INSTRUCTIONS: t("FeedbackInstructions"),
    FEEDBACK_MESSAGE: t("Feedback Message"),
    COACHBACK_TYPE: t("COACHBACK_TYPE"),
    FEEDBACK_TYPE: t("FEEDBACK_TYPE"),
    FEEDBACK_EMAIL_PREVIEW: t("Feedback Email Preview"),
    RESET: t("Reset"),
    DESCRIPTION: t("Description"),
    ATTACH: t("Attach"),
    SUBMITTED_ON: t("SubmittedOn"),
    TYPE: t("Type"),
    SUBTYPE: t("SubType"),
    ATTACHMENTS: t("Attachments"),
    YOUR_FEEDBACK: t("YourFeedback"),
    ALL_FEEDBACK: t("AllFeedback"),
    CHOOSE_FILE: t("ChooseFile"),
    SAVE: t("Save"),
    THANK_YOU_FEEDBACK: t("ThankYouFeedback"),
    COMMENTS: t("Comments"),
    VIEW_ALL_COMMENTS: t("View All Comments"),
    FEEDBACK_SECTION: t("FeedBackSection"),
    ROLE: t("Role"),
    FEEDBACK_SUBTYPE: t("FEEDBACK_SUBTYPE"),
    LIKELY: t("Likely"),
    NOT_LIKELY: t("NotLikely"),
    BACK: t("Back"),
    EDIT_FEEDBACK: t("EditFeedback"),
    MONTHLY_TITLE: t("MonthlyTitle"),
    MONTHLY_SUBTITLE: t("MonthlySubtitle"),
    INCORRECT_FILE: t("IncorrectFile"),
    NO_ATTACHMENTS: t("NoAttachments"),
    PROFILE_MANAGEMENT: t("ProfileManagement"),
    NoProfileManagementLinkWarning: t("NoProfileManagementLinkWarning"),
    LOGIN_FIX: t("LoginFix"),
    WARNING: t("Warning"),
    NO_COMMENTS: t("NoComments"),
    PROVIDE_COMMENT: t("ProvideComment"),
    ERROR_FEEDBACL_MONTHLY: t("ErrorFeedbackMonthly"),
    MOBILE_APP_LOGIN_TEXT: t("MobileAppLoginText"),
    OKAY: t("Okay"),
    COPY: t("Copy"),
    USERNAME: t("Username"),
    ASSOCIATED_WITH: t("AssociatedWith"),
    PROF_MAN_RELINK: t("ProfManRelink"),
    RELINK: t("Relink"),
    PROF_MAN_REMOVE: t("ProfManRemove"),
    REMOVE: t("Remove"),
    PERMANENTLY_REMOVE: t("PermanentlyRemove"),
    ERROR_COMMENT: t("ErrorComment"),
    TOOL_TIP: t("ToolTip"),
    LINK_SUCCESS: t("LinkSuccess"),
    UNLINK_SUCCESS: t("UnlinkSuccess"),
    TRY_AGAIN: t("TryAgain"),
    LOGIN_FIX_SUCCESS: t("LoginFixSuccess"),
    Attach_Img_MaxSize: t("Attach_Img_MaxSize"),
    PJI_FORM: t("PreJobInspectionForm"),
    NO_RISK: t("LowRisk"),
    HIGH_RISK: t("HighRisk"),
    WORKORDER_ID: t("WorkorderId"),
    TECH_ID: t("technician ID"),
    TECH_NAME: t("technician Name"),
    TASK: t("task"),
    INITIAL_CHECKS_COMPLETED: t("Initial Checks Completed?*"),
    familiarTaskSpecificJob: t("FamiliarWithTaskSpecificJobHazard"),
    communicationJobPlan: t("CommunicationJobPlanToEmployee"),
    awarenessApplicableJobProcedure: t("AwarenessOfApplicableJobProcedure"),
    employeeTrainingUpToDate: t("EmployeeTrainingUpToDate"),
    experiencedInTask: t("ExperiencedInTask"),
    applicablePPEInspections: t(
      "ApplicablePersonalProtectionEquipementInspections"
    ),
    applicableToolsEquipmentInspections: t(
      "ApplicableToolsAndEquipementInspections"
    ),
    HAZARDS_PRESENT: t("Hazards Present?*"),
    tripSlipFall: t("TripSlipFallHazard"),
    overheadHazards: t("OverheadHazards"),
    interferences: t("Interferences"),
    electricalHazards: t("ElectricalHazards"),
    radioFrequencyEquipment: t("RadioFrequencyEquipement"),
    weatherConcerns: t("WeatherConcerns"),
    publicRoadWays: t("PublicRoadWays"),
    accessEgress: t("AccessEgress"),
    otherHazards: t("OtherHazards"),
    GENERAL_PPE: t("General PPE/Equipment Requirements"),
    hardHat: t("HardHat"),
    safetyFootwear: t("SafetyFootwear"),
    eyeFaceProtection: t("EyeFaceProtection"),
    classOGloves: t("ClassOGloves"),
    cutResistantGloves: t("CutResistantGloves"),
    highVisibilityApparel: t("HighVisibilityApparel"),
    frRatedClothing: t("FrRatedClothing"),
    fivePointHarness: t("FivePointHarness"),
    safetyLanyard: t("SafetyLanyard"),
    respiratory: t("Respiratory"),
    rescueDevice: t("RescueDevice"),
    hearingProtection: t("HearingProtection"),
    MED_TO_HIGH_HAZARD_ACTIVITIES: t("Medium to High Hazard Activities"),
    WorkingFromHeights: t("WorkingFromHeights"),
    LiveElectricity: t("LiveElectricity"),
    ConfinedSpace: t("ConfinedSpace"),
    ANCHORAGE: t("Anchorage"),
    rooftop: t("Rooftop"),
    ladder: t("Ladder"),
    elevatedWorkPlatform: t("ElevatedWorkPlatform"),
    poleClimbing: t("PoleClimbing"),
    otherFallProtectionPlan: t("OtherFallProtectionPlan"),
    travelRestraintSystems: t("TravelRestraintSystems"),
    workPositioningSystem: t("WorkPositioningSystem"),
    fallArrestSystem: t("FallArrestSystem"),
    fallHazardZoneSystem: t("FallHazardZoneSystem"),
    guardrails: t("Guardrails"),
    strand: t("Strand"),
    permanentAnchorPoint: t("PermanentAnchorPoint"),
    temporarySupportAnchorPoint: t("TemporarySupportAnchorPoint"),
    structuralAnchorPoint: t("StructuralAnchorPoint"),
    closeProximity: t("CloseProximity"),
    electricalWork: t("ElectricalWork"),
    exposedHydroLines: t("ExposedHydroLines"),
    otherLiveElectricity: t("OtherLiveElectricity"),
    electricalGloves: t("ElectricalGloves"),
    equipmentInspectedInGoodCondition: t("EquipmentInspectedInGoodCondition"),
    distanceRequirements: t("DistanceRequirements"),
    competentPersons: t("CompetentPersons"),
    pPEInspected: t("PPEInspected"),
    lockOutTagOut: t("LockOutTagOut"),
    customerNotifiedToContactHydro: t("CustomerNotifiedToContactHydro"),
    drillingNearElectricalSources: t("DrillingNearElectricalSources"),
    confinedSpaceWork: t("ConfinedSpaceWork"),
    otherConfinedSpaceWork: t("OtherConfinedSpaceWork"),
    trafficControls: t("TrafficControls"),
    properManholeGear: t("ProperManholeGear"),
    requiredAdditionalTechSupport: t("RequiredAdditionalTechSupport"),
    inspectorsNotified: t("InspectorsNotified"),
    siteConcernsComments: t("SiteSpecificSafetyConcerns"),
    safetyMeasuresComments: t("AdditionalSafetyMeasures"),
    safetyEquipmentComments: t("AdditionalSafetyEquipment"),
    CUSTOMER_LOP_FORM: t("Customer Letter of Permission Form"),
    LOADING_NOTIFICATIONS: t("Loading Notifications"),
    GWStatus: t("GWStatus"),
    Battery: t("Battery"),
    /*Changes added by Node Team for node related placeholders*/
    SEARCH_BOX_TEXT_MT: t("UniversalSearchPlaceholder"),
    NODE_ID: t("Node ID"),
    SMT_ID: t("Smt ID"),
    NODE_TYPE: t("Node Type"),
    NRM_NODE_TYPE: t("NRM Node Type"),
    V_HUB: t("V HUB"),
    US_ASSOCIATED_NODE: t("US Associated Node"),
    FULL_MORDEM_COUNT: t("Full Modem Count"),
    LEGACY_MODEM_COUNT: t("Legacy Modem Count"),
    IGNITE_MODEM_COUNT: t("Ignite Modem Count"),
    HOMES_PASSED: t("Homes Passed"),
    IPTV_MORDEM_COUNTS: t("IPTV Modem Counts"),
    US_PORT: t("US Ports"),
    DS_PORT: t("DS_PORT"),
    ARCHITECTURE: t("Architecture"),
    OFDM_CHAN_CONFIG: t("OFDM - CHAN CONFIG"),
    OFDMA_CHAN_CONFIG: t("OFDMA - CHAN CONFIG"),
    OFDM: t("OFDM"),
    OFDMA: t("OFDMA"),
    CLAM_SHELL: t("Clamshell"),
    US_PEERS: t("US PEERS"),
    MAC_DOMAIN: t("MAC DOMAIN"),
    POWER: t("POWER"),
    UPSTREAM: t("UPSTREAM"),
    DOWNSTREAM: t("DOWNSTREAM"),
    TELEMETRY: t("TELEMETRY"),
    CERTIFICATIONS: t("Certifications"),
    DEVICES: t("DEVICES"),
    CX_IMPACT: t("CX IMPACT"),
    US_DS_METRICS: t("US/DS METRICS"),
    LOWER_FREQ: t("Lower freq"),
    UPPER_FREQ: t("Upper freq"),
    WIDTH: t("Width"),
    MIN_MOD: t("Min mod"),
    MAX_MOD: t("Max mod"),
    DATA_SET: t("Data Set"),
    VIEW_TYPE: t("View Type"),
    FULL: t("Full"),
    FULL_NO_CW: t("FULL_NO_CW"),
    BASIC: t("Basic"),
    ALL: t("All"),
    ALL_NO_CW: t("ALL_NO_CW"),
    CUSTOM: t("Custom"),
    FLAPS: t("Flaps"),
    LIST_OF_MODEMS: t("listOfModems"),
    START: t("Start"),
    STOP: t("Stop"),
    END: t("End"),
    NODE_TELEMETRY_INFO: t("Node Telemetry Info"),
    NO_DATA: t("No Data to display"),
    NO_DATA_ERROR_MSG: t(
      "Please contact the Tech Insight admin if data is missing"
    ),
    NO_DATA_SEARCH_VAL: t("No_Data_Found"),
    OK: t("OK"),
    ERROR: t("ERROR"),
    NODE_TELE_DETAILS: t("Node Telemetry Details"),
    ABOUT_TO_CLEAR_FLAP_DATA: t("You are about to clear flap data"),
    WANT_TO_CLEAR_FLAP_DATA: t("Do you really want to clear flap data?"),
    CLEARED_FLAPS_RESULT: t("Cleared Flaps Result"),
    TOTAL_FLAPS: t("Total Flaps"),
    CLEARED_FLAPS: t("Cleared Flaps"),
    PCRF: t("PCRF"),
    FLAPS_CLEARED_SUCCESS: t("Flaps have been successfully cleared"),
    FLAPS_CLEARED_PARTIALLY: t("Flaps have been partially cleared"),
    FLAPS_CLEARED_NEIGH_SUCCESS: t("FLAPS_CLEARED_NEIGH_SUCCESS"),
    CLEAR_FLAPS: t("Clear Flaps"),
    CLEAR_FLAPS_DETAILS: t("Clear Flaps Details"),
    CLEAR_FLAPS_FAILURE: t("Clearing Flaps API Call Returned Failure!"),
    LAN_ID_NOT_PRESENT: t("Required String parameter 'lanId' is not present"),
    NO_FLAPS_FOUND: t("FLAPS ARE NOT CLEARED BECAUSE THERE WAS NO FLAP DATA"),
    US_SC_QAM: t("US SC QAM"),
    DS_SC_QAM: t("DS SC QAM"),
    ACTIVE: t("Active"),
    RESPONSIVE: t("Responsive"),
    UNRESPONSIVE: t("Unresponsive"),
    ASSIGNED: t("Assigned"),
    TOTALS: t("Totals"),
    History: t("History"),
    Export: t("Export"),
    Export_Details: t("ExportDetails"),
    Telemetry_Info: t("Telemetry Info"),
    Flap_History: t("Flap History"),
    Refresh_Info: t("Refresh Info"),
    Export_to_CSV: t("Export to CSV"),
    Saved_Views: t("Saved Views"),
    Telemetry_Summary: t("Telemetry Summary"),
    CreatedBy: t("Created By"),
    Time_and_Date: t("Time and Date"),
    Last_Refreshed: t("Last Refreshed"),
    ClearedBy: t("Cleared By"),
    us_ps: t("us_ps"),
    ds_ps: t("ds_ps"),
    yes: t("yes"),
    Codewords: t("Codewords"),
    Attributes: t("Attributes"),
    fields: t("fields"),
    NODE_OVERVIEW: t("NODE OVERVIEW"),
    ofdm_status: t("ofdm_status"),
    ofdma_status: t("ofdma_status"),
    up: t("up"),
    down: t("down"),
    NO_WO_ERROR_MESSAGE: t(
      "There are no work orders currently associated to this account. Please select option and enter your work order ID and your order type manually."
    ),
    Select: t("Select..."),
    Installation: t("Installation"),
    Service: t("Service"),
    Maintenance: t("Maintenance"),
    Manual_Entry: t("Manual Entry"),
    LOP: t("LOP"),
    PJI: t("PJI"),
    SR: t("SR"),
    CB: t("CB"),
    NRF: t("NRF"),
    PreJob_Inspection_Form: "Pre-Job Inspection Form",
    Letter_of_Permission_Form: "Letter of Permission Form",
    Senior_Referral_Form: "Senior Referral Form",
    Riser_Repair_Referral: "Riser Repair Referral Form",
    Noise_Referral: "Noise Referral",
    Noise_Referral_Form: "Noise Referral Form",
    Noise_Referall: "Noise Referall",
    Noise_Referall_Form: "Noise Referall Form",
    TECHNICIAN_FIRM_COMPANY: "Technician Firm/Company",
    FIELD_SUPERVISOR_NAME: "Field Supervisor Name",
    Field_Supervisor_phone_number: "Field Supervisor phone number",
    Field_Supervisor_Validation_Date: "Field Supervisor Validation Date",
    Org_Unit: "Org Unit",
    Organization_Unit: "Organization Unit",
    Free_Text_Box_for_Tech_to_enter_feedback:
      "Free Text Box for Tech to enter feedback for support/tech",
    Maintenance_Referral: "Maintenance Referral",
    NO_FAULT_FOUND: "No Fault Found",
    CLARIFICATION: "Clarification",
    CUSTOMER_FEEDBACK: "Customer Feedback",
    EPR: t("EPR"),
    Equipment_Pickup_Receipt: "Equipment Pickup Receipt",
    Equipment_Pickup_Receipt_Form: "Equipment Pickup Receipt Form",
    SeniorReferralPopupMsg:
      "Use this form when making a referral to a senior or your supervisor. Always arrange a return date and time with the customer.",
    Work_Order_ID_should_be_alphanumeric_characters_starting_with: t(
      "Work Order ID should be 13 alphanumeric characters starting with"
    ),
    FeedBackPopupMsg:
      "use this form to provide feedback to Tech Support or Technicians",
    has_already_been_submitted_for_this_Work_Order_ID_and_you_cannot_submit_another_one:
      t(
        "has already been submitted for this Work Order ID and you cannot submit another one"
      ),
    Change_Work_Order_ID_and_try_again: t("Change Work Order ID and try again"),
    Work_Required_Involves: t("Work Required Involves"),
    Work_Required: t("Work Required"),
    Summary_of_Work_Required: t("Summary of Work Required"),
    DrillingWarningMessage: t("DrillingWarningMessage"),
    I_am_the_owner_or_superintendent_and_I_agree_to_the_work_as_described_above:
      t(
        "I_am_the_owner_or_superintendent_and_I_agree_to_the_work_as_described_above"
      ),
    Approve: t("Approve"),
    Do_Not_Approve: t("Do Not Approve"),
    Customer_Superintendent_or_Authorized_Signature: t(
      "Customer, Superintendent or Authorized Signature"
    ),
    Authorized_Signature: t("Authorized Signature"),
    Enter_work_summary: t("Enter work summary"),
    InstallingTemporaryAerialDrop: t("Installing a temporary aerial drop"),
    InstallingPermeantAerialDrop: t("Installing a permanent aerial drop"),
    InstallingNewCableInsideHome: t("Installing new cable inside of home"),
    InstallingNewCableOutsideHome: t("Installing new cable outside of home"),
    InstallingOutdoorAntenna: t("InstallingOutdoorAntenna"),
    DrillingInsideHome: t(
      "Drilling inside of home - Location must be 12 inches from the utilities"
    ),
    DrillingOutsideHome: t(
      "Drilling outside of home - Location must be 12 inches from the utilities"
    ),
    StaplingInsideHome: t("Stapling inside of home"),
    StaplingOutsideHome: t("Stapling outside of home"),
    RelocateCSE: t("Relocate CSE (grey box on side of residence)"),
    AttachingCableFence: t("Attaching cable to fence"),
    AttachingCableOutsideWall: t("Attaching cable to outside wall"),
    AttachingCableToTree: t("Attaching cable to tree"),
    OtherLOPMsg: t("Other (enter full details below)"),
    charsMax: t("chars max"),
    Signature_Capture: t("Signature Capture"),
    Clear: t("Clear"),
    Confirm: t("Confirm"),
    Sign_in_the_box_above: t("Sign in the box above"),
    Select_Work_Order_ID: t("Select Work Order ID"),
    Enter_Work_Order_ID: t("Enter Work Order ID"),
    Date_Of_Work_Order: t("Date Of Work Order"),
    Coachback_Form: t("Coachback_Form"),
    Coachback_Date: t("Coachback_Date"),
    Select_Order_Type: t("Select Order Type"),
    Customer_Type: t("Customer Type"),
    Order_Type: t("Order Type"),
    Send_a_copy_of_the_LOP_via_email: t("Send a copy of the LOP via email"),
    emailPlaceholder: t("sample@rci.rogers.ca"),
    Add_more_email: t("Add email"),
    Invalid_Email_Format: t("Invalid Email Format"),
    Update: t("Update"),
    Email_Preview: t("Email_Preview"),
    RogersEmailHeader: t("Your letter of permission"),
    RRFEmailHeader: t("Riser Referral Request"),
    Coachback_details: t("Coachback_details"),
    Coachback_Group: t("Coachback_Group"),
    Call_Center: t("Call Center"),
    Technician: t("Technician"),
    FSC: t("FSC"),
    RogersEmailThanks: t("Thank you for choosing Rogers!"),
    FidoEmailThanks: t("Thank you for choosing Fido!"),
    Hello: t("Hello"),
    Dear: t("Dear"),
    Hi: t("Hi"),
    Thank_you: t("Thank you"),
    LopEmailMSG: t("LOP EMail"),
    RRFEmailMSG: t("RRF Email"),
    CbEmailMSG: t("CbEmailMSG"),
    Work_Order_ID: t("Work Order ID"),
    Work_Summary: t("Work Summary"),
    Work_Approved: t("Work Approved"),
    Copy_of_this_Email_Sent_to: t("Copy of this Email Sent to"),
    Yes: t("Yes"),
    No: t("No"),
    Safety_Level: t("Safety Level"),
    Other: t("Other"),
    Initial_Checks: t("Initial Checks"),
    Hazards_Present: t("Hazards Present"),
    General_PPE_Requirements_Involves: t(
      "General PPE/Equipment Requirements Involves"
    ),
    Working_From_Heights_Involves: t("Working From Heights Involves"),
    Live_Electricity_Involves: t("Live Electricity Involves"),
    Confined_Space_Involves: t("Confined Space Involves"),
    Anchorage_Involves: t("Anchorage Involves"),
    Medium_to_High_Risk: t("Medium to High Risk"),
    Low_Risk: t("Low Risk"),
    Medium_to_High_Risk_Desc: t("Medium to High Risk Desc"),
    Low_Risk_Desc: t("Low Risk Desc"),
    Select_one_of_the_options: t("Select one of the options"),
    na: t("na"),
    Next: t("Next"),
    Fall_Protection_Plan: t("Fall Protection Plan"),
    Anchorage_Point: t("Anchorage Point"),
    Controls: t("Controls"),
    Add: t("Add"),
    Edit: t("Edit"),
    Continue: t("Continue"),
    Internet: t("Internet"),
    Ignite_TV: t("Ignite TV"),
    Legacy_TV: t("Legacy TV"),
    Home_Phone: t("Home Phone"),
    Smart_Home_Monitoring: t("Smart Home Monitoring"),
    FIDO: t("FIDO"),
    Impacted_Services: t("Impacted Services"),
    TW1: t("8-11"),
    TW2: t("11-2"),
    TW3: t("2-5"),
    TW4: t("5-8"),
    TAP: t("TAP"),
    CSE: t("CSE"),
    HUM: t("HUM"),
    Your_Tech_ID: t("Your Tech Id"),
    Enter: t("Enter"),
    Select_Date_for_Scheduled_Senior_Visit: t(
      "Select Date for Scheduled Senior Visit"
    ),
    Select_Time_Window: t("Select Time Window"),
    Senior_Order_Details: t("Senior Order Details"),
    Enter_Senior_Order_Details: t("Enter Senior Order Details"),
    HISTORY: t("HISTORY"),
    Completed_By_Senior_Tech: t("Completed By Senior Tech"),
    Completed_Feed_Back: t("completed feedback"),
    referralExceptionMessage: t("referralExceptionMessage"),
    Complete: t("Complete"),
    Completed: t("Completed"),
    Senior_Form: t("Senior Form"),
    Resolved: t("Resolved"),
    Unresolved: t("Unresolved"),
    Referral_Status: t("Referral Status"),
    Senior_Tech_IDs: t("Senior Tech ID(s)"),
    Senior_Tech_IDs_Placeholder: t("4556, 1122..."),
    Senior_Tech_Names: t("Senior Tech Name(s)"),
    Senior_Tech_Names_Placeholder: t("John Smith, Jack Johnson..."),
    Senior_Tech_Email_Addresses: t("Senior Tech Email Address(s)"),
    Customer_Information: t("CustomerInformation"),
    Customer_Name: t("CustomerName"),
    Is_the_customer_satisfied_with_the_outcome: t(
      "Is the customer satisfied with the outcome?"
    ),
    Senior_Tech_Notes_And_Root_Cause_Details: t(
      "Senior Tech Notes & Root Cause Details"
    ),
    Attach_Images: t("Attach Image(s)"),
    Attached_Images: t("Attached Images"),
    Select_Images_to_upload_here: t("Select Images to upload here"),
    No_Work_Order: t("No Work Order"),
    HOME: t("HOME"),
    ACCOUNT: t("ACCOUNT"),
    TOPOLOGY: t("TOPOLOGY"),
    notificationTitle: t("Notification Title (200 chars Max)*"),
    mapExceptionHeaderMsg: t("mapExceptionHeaderMsg"),
    Google_map_key_is_not_available: t("Google map key is not available."),
    Distance_To_Here: t("Distance To Here"),
    Measure_Distance: t("Measure Distance"),
    Clear_measurements: t("Clear measurements"),
    Google_Maps: t("GoogleMaps"),
    RELATED_SMTS: t("RELATED SMT(S)"),
    TI_WEB: t("TI_WEB"),
    UserActive: t("UserActive"),
    dormant: t("dormant"),
    registeredStatus: t("registeredStatus"),
    regStatus: t("regStatus"),
    Name: t("Name"),
    Description: t("Status_Description"),
    CMTS_Status: t("CMTS_Status"),
    OAL_Error: t("OAL_Error"),
    CMTS_CM_Online_Status: t("CMTS_CM_Online_Status"),
    ODU_Details: t("ODU Details"),
    Search_Geographic_Location: t(
      "Search a location by geographic coordinates"
    ),
    Uppercase_Search: t("SEARCH"),
    Search_Coordinates: t("Search by Coordinates"),
    Coordinates_Error: t("Invalid coordinates. Please check for any errors."),
    Standard: t("Standard"),
    Silver: t("Silver"),
    Retro: t("Retro"),
    Dark: t("Dark"),
    Night: t("Night"),
    Aubergine: t("Aubergine"),
    Resolution_Details: t("Resolution details"),
    Order_Details: t("Order Details"),
    Product_Details: t("Product Details"),
    Network_Details: t("Network Details"),
    Order_Number: t("Order Number"),
    IMT_Number: t("IMT Number"),
    Address: t("Address"),
    Work_type: t("Work Type"),
    Account_No: t("Account #"),
    Phone_Number: t("Phone Number"),
    Dwelling: t("Dwelling"),
    Subscriber_Type: t("Subscriber Type"),
    CBU: t("CBU"),
    Prime_Dep_Code: t("Prime / Dep Code"),
    Area: t("Area"),
    Map_Area: t("Map Area"),
    Scheduled_Start_Time: t("Scheduled Start Date and Time"),
    Scheduled_End_Time: t("Scheduled End Date and Time"),
    appointment_start_time: t("appointment_start_time"),
    appointment_end_time: t("appointment_end_time"),
    Est_Duration: t("Estimated Duration"),
    Enroute: t("Enroute"),
    Onsite: t("Onsite"),
    Cable_Type: t("Cable Type"),
    Cable_Condition: t("Cable Condition"),
    Feed: t("Feed"),
    Contract_Type: t("Contract Type "),
    Misc_Work_Code: t("Misc Work Code"),
    Topology: t("Topology"),
    Dispatch: t("Dispatch"),
    Dispatched: t("Dispatched"),
    Property_Management_Number: t("Property Management Number"),
    PON_Hub: t("PON Hub"),
    PON_NAP: t("PON NAP"),
    PON_NODE: t("PON NODE"),
    ONT_Present: t("ONT Present"),
    Fiber_Feed_Type: t("Fiber Feed Type "),
    Fiber_Condition: t("Fiber Condition"),
    Campaign_Number: t("Campaign Number"),
    Product_List: t("Product List"),
    Fault_Code: t("Fault Code"),
    Network_Code: t("Network Code"),
    Activity_Code: t("Activity Code"),
    Cause_Code: t("Cause Code"),
    Additional_Codes: t("Additional Codes"),
    Equipment_Pickup_Details_and_Serial_Numbers: t(
      "Equipment Pickup Details & Serial Numbers"
    ),
    Total_Characters_Left: t("Total Characters Left"),
    Date: t("Date"),
    Customer_Emails: t("Customer Email(s)"),
    Customer_has_opted_out_of_the_Email_option: t(
      "Customer has opted out of the E-mail option"
    ),
    Customer_opted_out: t("Customer opted out"),
    Customer_did_not_opt_out: t("Customer did not opt out"),
    Confirmation: t("Confirmation"),
    Customers_Signature: t("Customers Signature"),
    EPREmailHeader: t("Your Equipment Pickup Receipt"),
    EprEmailMSG: t("EPR EMail"),
    General: t("General"),
    Timestamp: t("Timestamp"),
    Bridge_Mode: t("Bridge Mode"),
    Incaptive_Portal: t("Incaptive Portal"),
    CM_IP_Address: t("CM IP Address"),
    CM_IPv6_Portal: t("CM IPv6 Portal"),
    CPE_IP: t("CPE IP"),
    CPE_IPv6: t("CPE IPv6"),
    SAMKEY: t("SAMKEY"),
    Service_Area: t("Service Area"),
    Return_Segment: t("Return Segment"),
    CMTS_CM_Status: t("CMTS CM Status"),
    CMTS_IP_Adress: t("CMTS IP Adress"),
    CMTS_Name: t("CMTS Name"),
    CMTS_Sys_Uptime: t("CMTS Sys Uptime"),
    Upstream_Bitrate: t("Upstream Bitrate"),
    Mbps: t("Mbps"),
    Downstream_Bitrate: t("Downstream Bitrate"),
    CM_MAC: t("CM MAC"),
    CMTS_CM_RX: t("CMTS CM RX"),
    CMTS_CM_SNR: t("CMTS CM SNR"),
    CMTS_CM_Percent_Time_Online: t("CMTS CM Percent Time Online"),
    CMTS_CM_Online: t("CMTS CM Online %"),
    CMTS_CM_Online_Times: t("CMTS CM Online Times"),
    First_Flap: t("First Flap"),
    Last_Flap: t("Last Flap"),
    Local_Time: t("Local Time"),
    Power_Adjustments: t("Power Adjustments"),
    Miss: t("Miss"),
    Insertion_Fails: t("Insertion Fails"),
    Counts: t("Counts"),
    Hits: t("Hits"),
    Uptime: t("Uptime"),
    Registered_Time: t("Registered Time"),
    Docsis_Version: t("Docsis Version"),
    Apt: t("Apt"),
    City: t("City"),
    Completion_Codes: t("Completion Codes"),
    Prevention_Code: t("Prevention Code"),
    Special_Instructions: t("Special Instructions"),
    Performed_Outage_Activity_No_Fault_Found: t(
      "Performed Outage Activity - No Fault Found"
    ),
    Performed_Service_Referral_Activity_No_Fault_Found: t(
      "Performed Service Referral Activity - No Fault Found"
    ),
    Performed_Dem_Mtce_Activity_No_Fault_Found: t(
      "Performed Dem Mtce Activity - No Fault Found"
    ),
    Performed_Preventative_Mtce_Activity_No_Fault_Found: t(
      "Performed Preventative Mtce Activity - No Fault Found"
    ),
    No_Forward_Signal: t("No Forward Signal"),
    No_Return_Signal: t("No Return Signal"),
    No_Forward_Return_Signal: t("No Forward/Return Signal"),
    Forward_Signal_Levels_Out_Of_Spec: t("Forward Signal Levels Out Of Spec"),
    Return_Signal_Levels_Out_Of_Spec: t("Return Signal Levels Out Of Spec"),
    Forward_Return_Signal_Levels_Out_Of_Spec: t(
      "Forward/Return Signal Levels Out Of Spec"
    ),
    Visible_Impaired_Distorted_Fwd_Signal: t(
      "Visible Impaired/Distorted Fwd Signal"
    ),
    Signal_Leakage_Out_Of_Spec: t("Signal Leakage Out Of Spec"),
    Return_Noise_Ingress: t("Return Noise/Ingress"),
    General_Equipment_Housing_Damage: t("General Equipment/Housing Damage"),
    Smt_Alarm: t("Smt Alarm"),
    Head_End_Provisioning_Source_Problem: t(
      "Head End/Provisioning/Source Problem"
    ),
    Power_Supply_In_Standby: t("Power Supply In Standby"),
    Power_Supply_Fault: t("Power Supply Fault"),
    Other_Fault_Found: t("Other (Fault Found)"),
    Head_End: t("Head End"),
    Fibre_Cable: t("Fibre - Cable"),
    Fibre_Connector: t("Fibre - Connector"),
    Node_Rf_Interface_Cable_Board: t("Node - Rf Interface Cable/Board"),
    Node_Optical_Receiver: t("Node - Optical Receiver"),
    Node_Optical_Transmitter: t("Node - Optical Transmitter"),
    Node_Rf_Module: t("Node - Rf Module"),
    Node_Housing: t("Node - Housing"),
    Trunk_Cable: t("Trunk - Cable"),
    Trunk_Amplifier: t("Trunk - Amplifier"),
    Trunk_Connector_Coupler_Splitters_Power_Inserters: t(
      "Trunk - Connector/Coupler/Splitters/Power Inserters"
    ),
    Trunk_Primary_Power_Supply_Ac: t("Trunk - Primary Power Supply (Ac)"),
    Trunk_Backup_Ups_Power_Supply_Dc: t("Trunk - Backup Ups Power Supply (Dc)"),
    Distribution_Cable: t("Distribution - Cable"),
    Distribution_Lx_Amp: t("Distribution - Lx/Amp"),
    Distribution_Connectors_Couplers_Splitters: t(
      "Distribution - Connectors/Couplers/Splitters"
    ),
    Mulitap_Plate: t("Mulitap - Plate"),
    Multitap_Housing: t("Multitap - Housing"),
    Drop_Cable: t("Drop - Cable"),
    Drop_Amplifier: t("Drop Amplifier"),
    Drop_Conectors: t("Drop - Conectors"),
    Cse_Splitters_Grd_Blocks_Splices: t("Cse - Splitters/Grd Blocks/Splices"),
    Internal_Cabling_Splitter_Wallplate: t(
      "Internal Cabling/Splitter/Wallplate"
    ),
    Rogers_Htd_Modem_Dstb_Voip: t("Rogers Htd (Modem, Dstb, Voip)"),
    Customer_Equipment_Tv_Pc_Phone: t("Customer Equipment (Tv, Pc, Phone)"),
    Other_Fault_Location: t("Other (Fault Location)"),
    Lobby_Camera_Rogers_Owned: t("Lobby Camera (Rogers Owned)"),
    Lobby_Camera_Customer_Owned: t("Lobby Camera (Customer Owned)"),
    Traffic_Light: t("Traffic Light"),
    Replaced_Network_Asset: t("Replaced Network Asset"),
    Repaired_Network_Asset: t("Repaired Network Asset"),
    Tightened_Equipment: t("Tightened Equipment"),
    Ran_Temp_Line: t("Ran Temp Line"),
    Adjusted_Signal_Levels: t("Adjusted Signal Levels"),
    ReSet_Up_ReAlignment: t("Re-Set Up/Re-Alignment"),
    Cable_Spliced: t("Cable Spliced"),
    Installed_Noise_Filter: t("Installed Noise Filter"),
    Installed_Leakage_Pad: t("Installed Leakage Pad"),
    Installed_Drop_Amp: t("Installed Drop Amp"),
    OnSite_Verification_Performed: t("On-Site Verification Performed"),
    Cable_Located: t("Cable Located"),
    Worked_On_Special_Project: t("Worked On Special Project"),
    Assisted_Another_Technician: t("Assisted Another Technician"),
    Used_Truck_For_Powering_Power_Supply: t(
      "Used Truck For Powering Power Supply"
    ),
    Met_With_Contractor: t("Met With Contractor"),
    Identified_Escalated_Faults_Found: t("Identified & Escalated Faults Found"),
    Exchange_Transponder: t("Exchange Transponder"),
    Installed_Portable_Generator: t("Installed Portable Generator"),
    Generator_Missing_Or_Vandalized: t("Generator Missing Or Vandalized  "),
    Other_Actvity_Performed: t("Other (Actvity Performed)"),
    Accidental_Property_Damage: t("Accidental Property Damage"),
    Vandalism: t("Vandalism"),
    Environmental_Conditions: t("Environmental Conditions"),
    Expected_Equipment_Degration: t("Expected Equipment Degration"),
    Moisture: t("Moisture"),
    Plant_Not_Installed_To_Spec: t("Plant Not Installed To Spec"),
    Plant_Not_Located_To_Spec: t("Plant Not Located To Spec"),
    Plant_Not_Functioning_To_Spec: t("Plant Not Functioning To Spec"),
    Plant_Not_Designed_To_Spec: t("Plant Not Designed To Spec"),
    Previous_Plant_Repair_Not_To_Spec: t("Previous Plant Repair Not To Spec"),
    Recent_Construction_Not_To_Spec: t("Recent Construction Not To Spec"),
    Recent_Rebuild_No_To_Spec: t("Recent Rebuild No To Spec"),
    Recent_Installed_Equip_Not_To_Spec: t("Recent Installed Equip Not To Spec"),
    Component_Alignment_Not_To_Spec: t("Component Alignment Not To Spec"),
    Loose_Connectors: t("Loose Connector(s)"),
    Corroded_Connections: t("Corroded Connection(s)"),
    Grounding_Short_Fault: t("Grounding/Short Fault"),
    Cut_Cable: t("Cut Cable"),
    Cracked_Damaged_Cable: t("Cracked/Damaged Cable"),
    Cable_Bending_Out_Of_Spec: t("Cable Bending Out Of Spec"),
    Primary_Power_Outage_Surge_Out_Of_Spec: t(
      "Primary Power Outage/Surge/Out Of Spec"
    ),
    Backup_Power_Outage_Surge_Out_Of_Spec: t(
      "Backup Power Outage/Surge/Out Of Spec"
    ),
    Blown_Fuse_Tripeed_Ac_Breaker: t("Blown Fuse/Tripeed Ac Breaker"),
    Cause_Unknown: t("Cause Unknown"),
    Other_Cause_Determined: t("Other (Cause Determined)"),
    Better_Protection_Of_Equipment: t("Better Protection Of Equipment"),
    Waterproof_Equipment: t("Waterproof Equipment"),
    Early_Preventative_Diagnostic: t("Early Preventative Diagnostic"),
    Accurate_Locate_Ativity: t("Accurate Locate Ativity"),
    Better_Design_Of_Plant: t("Better Design Of Plant"),
    Bettr_Grouding: t("Better Grouding"),
    Better_Battery_Maintenance: t("Better Battery Maintenance"),
    Better_Location_Placement_Of_Equipment: t(
      "Better Location/Placement Of Equipment"
    ),
    First_Time_Right_By_Serv_Tech: t("First Time Right By Serv Tech"),
    First_Time_Right_By_Construction: t("First Time Right By Construction"),
    First_Time_Right_By_Maintenance: t("First Time Right By Maintenance"),
    First_Time_Right_By_Maintenance_Contractors: t(
      "First Time Right By Maintenance Contractors"
    ),
    Not_A_Mtce_Issue_Unnecessary_Mtce_Order: t(
      "Not A Mtce Issue - Unnecessary Mtce Order"
    ),
    Other_Prevention: t("Other (Prevention)"),
    Ref_To_Engineering: t("Ref  To Engineering"),
    Ref_To_Service: t("Ref To Service"),
    Ref_To_Head_End: t("Ref To Head End"),
    Ref_To_Construction: t("Ref To Construction"),
    ReGenerate: t("Re-Generate"),
    Refer_To_Noise: t("Refer To Noise"),
    Hot_To_Cold: t("Hot To Cold"),
    Tracking_Code_2: t("Tracking Code 2"),
    Incorrect_Referral: t("Incorrect Referral"),
    Tracking_Code_4: t("Tracking Code 4"),
    Tracking_Code_5: t("Tracking Code 5"),
    Recep_Prob_OutageCanc_Via_Mdi: t("Recep Prob/Outage-Canc Via Mdi"),
    Recep_Prob_Outage_Not_Verif: t("Recep Prob/Outage - Not Verif."),
    Recep_Prob_Outage_Verified: t("Recep Prob/Outage - Verified"),
    Og_Activity_Nff: t("Og Activity - Nff"),
    Service_Ref_Nff: t("Service Ref - Nff"),
    Dm_Mtce_Nff: t("Dm Mtce - Nff"),
    Pm_Mtce_Nff: t("Pm Mtce - Nff"),
    No_Fwd_Signal: t("No Fwd Signal"),
    No_Rtn_Signal: t("No Rtn Signal"),
    No_Fwd_Ret_Signal: t("No Fwd/Ret Signal"),
    Fwd_Signal_Level_Oos: t("Fwd Signal Level Oos"),
    Rtn_Sigan_Level_Oos: t("Rtn Sigan Level Oos"),
    Fwd_Ret_Signal_Level_Oos: t("Fwd/Ret Signal Level Oos"),
    Impair_Dst_Fwd_S: t("Impair/Dst Fwd S"),
    Signal_Leak_Oos: t("Signal Leak Oos"),
    Rtn_Noise_Ingress: t("Rtn Noise Ingress"),
    Gen_Equip_Hous_Dmg: t("Gen Equip/Hous Dmg"),
    He_Provi_Source: t("He/Provi/Source"),
    Other_Fault_Fnd: t("Other - Fault Fnd"),
    Fibre_Able: t("Fibre - Able"),
    Fiber_Connector: t("Fiber - Connector"),
    Node_Rf_I_F_Cable: t("Node - Rf I/F Cable"),
    Node_Optical_Rec: t("Node - Optical Rec"),
    Node_Optical_Trans: t("Node - Optical Trans"),
    Node_Rf_Mod: t("Node  - Rf Mod"),
    Trunk_Cn_Cu_Sp_Pi: t("Trunk - Cn/Cu/Sp/Pi"),
    Trunk_Prm_Pwr_Sply: t("Trunk - Prm Pwr Sply"),
    Trunk_Upd_Pwr_Sply: t("Trunk - Upd Pwr Sply"),
    Dist_Cable: t("Dist - Cable"),
    Dist_Line_Extender: t("Dist - Line Extender"),
    Dist_Connec_Cplrs_Split: t("Dist - Connec/Cplrs/Split"),
    Mulittap_Plate: t("Mulittap - Plate"),
    Drop_Connector: t("Drop - Connector"),
    Cse: t("Cse"),
    Int_Cab_Spltter_Wallp: t("Int Cab/Spltter/Wallp"),
    Rogers_Htd: t("Rogers Htd"),
    Customer_Equip: t("Customer Equip"),
    Other_Fault_Loc: t("Other - Fault Loc"),
    Lobby_Cam_R_O: t("Lobby Cam R/O"),
    Lobby_Cam_C_O: t("Lobby Cam C/O"),
    Replaced_Net_Asset: t("Replaced Net Asset"),
    Repaired_Net_Asset: t("Repaired Net Asset"),
    Tightened_Equip: t("Tightened Equip"),
    Adj_Signal_Level: t("Adj Signal Level"),
    ReSetup_ReAlign: t("Re-Setup/Re-Align"),
    Inst_Noise_Filter: t("Inst Noise Filter"),
    Installed_Lkg_Pad: t("Installed Lkg Pad"),
    Inst_Drop_Amp: t("Inst Drop Amp"),
    OnSite_Verify: t("On-Site Verify"),
    Worked_On_S_P: t("Worked On S/P"),
    Assisted_Tech: t("Assisted Tech"),
    Pwrg_Pwr_Supply: t("Pwrg Pwr Supply"),
    Met_W_Contractor: t("Met W Contractor"),
    Id_Esc_Fault_Fnd: t("Id & Esc Fault Fnd"),
    Oth_Activity_Perf: t("Oth Activity Perf"),
    Acc_Pprt_Damage: t("Acc Pprt Damage"),
    Env_Conditions: t("Env Conditions"),
    Ex_Equip_Degrad: t("Ex Equip Degrad"),
    Plant_Install_Nts: t("Plant Install Nts"),
    Plant_Locate_Nts: t("Plant Locate Nts"),
    Plant_Funct_Nts: t("Plant Funct Nts"),
    Plant_Design_Nts: t("Plant Design Nts"),
    Pre_Plnt_Repr_Nts: t("Pre Plnt Repr Nts"),
    Recent_Consnts: t("Recent Consnts"),
    Recent_Rebuild_Nts: t("Recent Rebuild Nts"),
    Rec_Inst_Equip_Fail: t("Rec Inst Equip Fail"),
    Comp_Alignmnt_Oos: t("Comp Alignmnt Oos"),
    Loose_Conn: t("Loose Conn"),
    Corroded_Conn: t("Corroded Conn"),
    Grdng_Short_Fault: t("Grdng/Short Fault"),
    Crack_Damaged_Cable: t("Crack/Damaged Cable"),
    Cable_Bending_Oos: t("Cable Bending Oos"),
    Prim_Pwr_Og_Oos: t("Prim Pwr Og Oos"),
    Bkup_Pwr_Og_Oos: t("Bkup Pwr Og Oos"),
    Blow_Fuse_Ac_Brkr: t("Blow Fuse/Ac Brkr"),
    Oth_Cause_Determ: t("Oth Cause Determ"),
    Bet_Prot_Of_Equip: t("Bet Prot Of Equip"),
    Waterproof_Equip: t("Waterproof Equip"),
    Early_Prev_Diagnos: t("Early Prev Diagnos"),
    Accurate_Loc_Activ: t("Accurate Loc Activ"),
    Bet_Dsgn_Of_Plant: t("Bet Dsgn Of Plant"),
    Better_Grounding: t("Better Grounding"),
    Better_Maint_Batt: t("Better Maint Batt"),
    Bet_Loc_Of_Equip: t("Bet Loc Of Equip"),
    Ftr_Service_Tech: t("Ftr Service Tech"),
    Ftr_By_Constr: t("Ftr By Constr"),
    Ftr_By_Maint: t("Ftr By Maint"),
    Ftr_By_Maint_Contr: t("Ftr By Maint Contr"),
    Not_A_Mtce_Issue: t("Not A Mtce Issue"),
    Re: t("Re "),
    Rs: t("Rs"),
    Rh: t("Rh"),
    Rc: t("Rc"),
    Rr: t("Rr"),
    Rn: t("Rn"),
    Tracking_Code_1: t("Tracking Code 1"),
    Tracking_Code_3: t("Tracking Code 3"),
    inSpec: t("inSpec"),
    outSpec: t("outSpec"),
    marginal: t("marginal"),
    pass: t("pass"),
    fail: t("fail"),
    TX: t("TX"),
    US: t("US"),
    Times: t("Times"),
    DS_Speed: t("DS_Speed"),
    US_Speed: t("US_Speed"),
    Ping: t("Ping"),
    Jitter: t("Jitter"),
    Speed_Test_Result: t("Speed_Test_Result"),
    PullingSpeedTest: t("PullingSpeedTest"),
    Time: t("Time"),
    SpeedDataNA: t("SpeedDataNA"),
    SpeedTestInfo: t("SpeedTestInfo"),
    pre: t("pre"),
    post: t("post"),
    CWE: t("CWE"),
    Total: t("Total"),
    TX_Max: t("TX Max"),
    TX_Min: t("TX Min"),
    TX_Delta: t("TX Delta"),
    SNR_Max: t("SNR Max"),
    SNR_Min: t("SNR Min"),
    SNR_Delta: t("SNR Delta"),
    Min: t("Min"),
    Max: t("Max"),
    Delta: t("Delta"),
    RX: t("RX"),
    DS: t("DS"),
    RX_DS: t("RX (DS)"),
    SNR_DS: t("SNR (DS)"),
    RX_Max: t("RX Max"),
    RX_Min: t("RX Min"),
    RDC_Tx: t("RDC Tx"),
    FDC_Rx: t("FDC Rx"),
    DS_SCQAMSNR: t("DS SCQAM SNR"),
    RX_Delta: t("RX Delta"),
    Channel: t("Channel"),
    PLC: t("PLC"),
    Range: t("Range"),
    Sec_Perc: t("Second Percentile"),
    Margin: t("Margin"),
    Mer_Perc: t("MER Percentile"),
    US_Interface: t("US Interface"),
    US_Freq: t("US Freq"),
    US_TX: t("US TX"),
    US_SNR: t("US SNR"),
    PRE_CCER: t("PRE CCER"),
    POST_CER: t("POST CER"),
    Mean: t("Mean"),
    STD: t("STD"),
    PCT: t("PCT"),
    Level: t("Level"),
    Freq: t("Freq"),
    DS_PS: t("DS PS"),
    US_PS: t("US PS"),
    SCQAM: t("SCQAM"),
    AVERAGES: t("Averages"),
    NODE_AVERAGES: t("NODE_AVERAGES"),
    OFDMA_PCT: t("OFDMA 2 PCT"),
    OFDMA_TX: t("OFDMA TX"),
    DS_Freq: t("DS Freq"),
    DS_RX: t("DS RX"),
    DS_SNR: t("DS SNR"),
    OFDM_CHANNEL_ID: t("OFDM CHANNEL ID"),
    OFDM_RX_MIN: t("OFDM RX MIN"),
    OFDM_RX_MAX: t("OFDM RX MAX"),
    OFDM_PCT: t("OFDM 2 PCT"),
    Should_Not_Refresh: t("pageRefreshMessage"),
    RPD: t("RPD"),
    SiteID: t("Site ID"),
    RPDNumber: t("RPD Number"),
    ClamshellName: t("Clamshell Name"),
    MacAddress: t("Mac Address"),
    CMTSName: t("CMTS Name"),
    CMTSSlot: t("CMTS Slot"),
    CMTSPort: t("CMTS Port"),
    CMTSHostname: t("CMTS Hostname"),
    CMTSIp: t("CMTS IP"),
    AccessLeaf: t("Access Leaf"),
    AccessLeafPort: t("Access Leaf Port"),
    ServiceLeaf: t("Service Leaf"),
    ServiceLeafPort: t("Service Leaf Port"),
    MacDomain: t("Mac Domain"),
    USFreq: t("US Frequency Template"),
    DSFreq: t("DS Frequency Template"),
    OFDMFreq: t("OFDM Frequency Template"),
    OFDMAFreq: t("OFDMA Frequency Template"),
    oldRPDMacAddress: t("Old RPD Mac Address"),
    newRPDMacAddress: t("New RPD Mac Address"),
    nptID: t("NPT ID"),
    clamshellAndReturnSegment: t("Return Segment ID / RPD Mac Address"),
    clamshellID: t("Return Segment ID"),
    rpdMacAddress: t("RPD Mac Address"),
    or: t("Or"),
    fetchDetails: t("Fetch Details"),
    nodeIDOne: t("Return Segment #1"),
    nodeIDTwo: t("Return Segment #2"),
    phubID: t("PHUB ID"),
    clamshellIDTxt: t("Clamshell ID"),
    rpd: t("RPD"),
    rpdSlot: t("Please select your slot"),
    slotOne: t("RPD 1"),
    slotTwo: t("RPD 2"),
    rpdslotOne: t("RPD 1"),
    rpdslotTwo: t("RPD 2"),
    clamshellDetails: t("Clamshell Details"),
    noClamshell: t("No Clamshell to display"),
    rpdSearch: t("RPD Search"),
    rpdRegistration: t("RPD Registration"),
    rpdSwap: t("RPD Swap"),
    rpdActivation: t("RPD Activation"),
    rpdProvisioning: t("RPD Provisioning"),
    rpdSWUpgrade: t("RPD SW Upgrade"),
    rpdDecommission: t("RPD Decommissioning"),
    clamshellEmptyError: t("Please enter valid Clamshell ID"),
    macAddressEmptyError: t(
      "Please enter valid Return Segment ID or RPD Mac Address"
    ),
    macIdEmptyError: t("Please enter valid RPD Mac Address"),
    oldMacAddressEmptyError: t("Please enter valid Old RPD Mac Address"),
    newMacAddressEmptyError: t("Please enter valid New RPD Mac Address"),
    nptIdEmptyError: t("Please enter valid NPT ID"),
    typeSiteID: t("Type Site ID.."),
    typeRPDNumber: t("Type RPD Number.."),
    typeClamshellName: t("Type Clamshell Name.."),
    typeMacAddress: t("Type Mac Address.."),
    typeCMTSName: t("Type CMTS Name.."),
    typeCMTSSlot: t("Type CMTS Slot.."),
    typeAccessLeafPort: t("Type Access Leaf Port.."),
    typeMacDomain: t("Type Mac Domain.."),
    typeUSFreq: t("Type US Frequency Template.."),
    typeDSFreq: t("Type DS Frequency Template.."),
    typeOFDMFreq: t("Type OFDM Frequency Template.."),
    typeOFDMAFreq: t("Type OFDMA Frequency Template.."),
    typeOldRPDMacAddress: t("Type Old RPD Mac Address.."),
    typeNewRPDMacAddress: t("Type New RPD Mac Address.."),
    typeNptID: t("Type NPT ID.."),
    typeNptIMTNCTID: t("Type NPT ID / IMT ID / NCT ID.."),
    typeClamshellID: t("Type Return Segment ID.."),
    typeClamshellIDTxt: t("Type Clamshell ID.."),
    typeRpdMacAddress: t("Type RPD Mac Address.."),
    typeClamshellRpdMacAddress: t(
      "Type Return Segment ID or RPD Mac Address.."
    ),
    rpdPortName: t("RPD Port Name"),
    macDomain: t("Mac Domain"),
    distinguishedKey: t("Distinguished Key"),
    rpdPortType: t("Rpd Port Type"),
    videoServiceGroupName: t("Video Service Group Name"),
    rpdPortNumber: t("Rpd Port Number"),
    responseCode: t("Response Code"),
    returnSegmentDetails: t("Return Segment Details"),
    noRPDData: t("No RPD Data to display."),
    noRPDErrorDesc: t("Once you search a RPD it will reflect here."),
    rpdRegistrationError: t("RPD Registration Failed."),
    rpdRegistrationDesc: t("Something went wrong. Please check with the Admin"),
    rpdRegistrationSuccess: t("RPD Registered Successfully"),
    rpdRegistrationFail: t("RPD Registration failed"),
    noRPDRegData: t("No RPD Data to display."),
    rpdSwapError: t("RPD Swap Failed."),
    rpdSwapDesc: t("Something went wrong. Please check with the Admin"),
    rpdSwapSuccess: t("RPD Swapped Successfully"),
    rpdSwapFail: t("RPD Swap failed"),
    noRPDSwapData: t("No RPD Data to display."),
    noNPTData: t("No NPT Data to display."),
    noNPTErrorDesc: t("Once you search a NPT ID it will reflect here."),
    rpdActivateSuccess: t("RPD Activated Successfully"),
    rpdActivateFail: t("RPD Activation failed"),
    rpdActivationError: t("RPD Activation Failed."),
    rpdLobbyCamera: t("Is Lobby Camera added?"),
    rpdLobbyCamera1: t("Yes"),
    rpdLobbyCamera2: t("No"),
    rpdMDType: t("Please select MD-Type"),
    rpdMDType1: t("BDR"),
    rpdMDType2: t("Non-BDR"),
    rpdEmail: t("Send email"),
    rpdAuthenticationError: t(
      "You do not have the required roles to access this feature"
    ),
    rpdAuthenticationMsg: t(
      "Please contact the Tech Insight admin for further details"
    ),
    nptIdimtId: t("NCT ID / IMT ID"),
    typeNptIDImtID: t("Type NCT ID or IMT ID"),
    nptIdImtIdEmptyError: t("Please enter valid NCT ID or IMT ID"),
    emailEmptyError: t("Please enter valid email Id"),
    chart: t("Chart"),
    power: t("Power"),
    noise: t("Noise"),
    secondPCT: t("2PCT"),
    SearchAddressError: t("SearchAddressError"),
    Mhz: t("Mhz"),
    Scores: t("Scores"),
    Start_Time: t("Start Time"),
    End_Time: t("End Time"),
    Breakdown: t("Breakdown"),
    serviceTech: t("ServiceTech"),
    maintenanceTech: t("MaintenanceTech"),

    rpdOrderInfo: t("RPD Order Detail"),
    noRPDOrderData: t("No RPD Order data found"),
    rpdCreateOrderSwapSuccess: t("RPD Swap transaction submitted successfully"),
    rpdCreateOrderProvisioningSuccess: t(
      "RPD Provisioning transaction submitted successfully"
    ),
    rpdCreateOrderSWUpgradeSuccess: t(
      "RPD Software Upgrade transaction submitted successfully"
    ),
    rpdCreateOrderDecommSuccess: t(
      "RPD Decommissioning transaction submitted successfully"
    ),
    rpdProvisioningError: t("RPD Provisioning Failed."),
    rpdSWUpgradeError: t("RPD Software Upgrade Failed."),
    sendEmail: t("Enter valid email Id"),

    vccapSWupgrade: t("RPD SW Upgrade"),
    vccapUATport: t("RPD UAT Port"),
    casaRPDConfig: t("CASA RPD Config"),
    harmonicRPDConfig: t("Harmonic RPD Config"),
    rpdConfig: t("RPD Config"),
    rpdOrderSuccess: t("Transaction Completed successfully"),
    rpdOrderFail: t("Transaction Completed and failed"),
    rpdOrderProgress: t("Transaction is in progress"),
    rpdCasaRPDfail: t("vCCAP CASA RPD Config transaction submission failed"),
    rpdCasaRPDSuccess: t(
      "vCCAP CASA RPD Config transaction submitted successfully"
    ),
    rpdHarmonicRPDfail: t(
      "vCCAP harmonic RPD Config transaction submission failed"
    ),
    rpdHarmonicRPDSuccess: t(
      "vCCAP harmonic RPD Config transaction submitted successfully"
    ),
    rpdVccapSWUpgSuccess: t(
      "vCCAP SW upgrade transaction submitted successfully"
    ),
    rpdVccapSWUpgFail: t("vCCAP SW upgrade transaction failed"),
    orderId: t("Order Id "),
    rpdVccapUATportFail: t(
      "vCCAP UAT Port Config transaction submission failed"
    ),
    rpdVccapUATPortSuccess: t(
      "vCCAP UAT Port Config transaction submitted successfully"
    ),
    Order_ID: t("Order ID"),
    orderIdEmptyError: t("Enter valid Order ID"),
    enter_order_ID: t("Enter order ID"),
    vccap: t("vCCAP"),
    enter_valid_core: t("Enter valid Video Core RPD No"),
    enter_mac_address: t("Enter valid mac address"),
    enter_mac_domain: t("Enter valid Mac Domain"),
    enter_docsis_hostname: t("Enter valid Docsis Hostname"),
    enter_video_hostname: t("Enter valid Video Hostname"),
    enter_built_type: t("Enter valid Built Type"),
    enter_docsis_ip: t("Enter valid Docsis IP"),
    enter_video_ip: t("Enter valid Video IP"),
    enter_aux_core: t("Enter valid Auxillary Core List"),
    enter_qpsk: t("Enter valid QPSK"),
    enter_node_id: t("Enter valid Node ID"),
    enter_node: t("Enter valid Node"),
    enter_hw_type: t("Enter valid RPD Hardware Type"),
    enter_address: t("Enter valid Civic Address"),
    enter_vs: t("Enter valid VS"),
    enter_vc: t("Enter valid VC"),
    enter_ip: t("Enter valid IP"),
    enter_hostname: t("Enter valid Hostname"),
    enter_version: t("Enter valid RPD Software Version"),
    enter_channel: t("Enter valid Channel"),
    enter_interface: t("Enter valid Interface"),
    enter_nptid: t("Enter valid NPT ID"),

    type_address: t("Type address"),
    type_vs: t("Type VS"),
    type_vc: t("Type VC"),
    type_ip: t("Type IP"),
    type_rpd_hw: t("Type RPD Hardware Type"),
    type_version: t("Type RPD Software Version"),
    type_channel: t("Type Channel"),
    type_nptId: t("Type NPT ID"),
    type_interface: t("Type Interface"),

    IP: t("IP"),
    vc: t("VC"),
    vs: t("VS"),
    address: t("Address"),
    rpd_hw: t("RPD Hardware Type"),
    version: t("RPD Software Version"),
    node: t("Node"),
    channel: t("Channel"),
    interface: t("Interface"),

    type_order_ID: t("Type Order ID"),
    type_valid_core: t("Type Video Core RPD No"),
    type_mac_address: t("Type mac address"),
    type_mac_domain: t("Type Mac Domain"),
    type_docsis_hostname: t("Type Docsis Hostname"),
    type_video_hostname: t("Type Video Hostname"),
    type_built_type: t("Type Built Type"),
    type_docsis_ip: t("Type Docsis IP"),
    type_video_ip: t("Type Video IP"),
    type_aux_core: t("Type Auxillary Core List"),
    type_qpsk: t("Type QPSK"),
    type_node_id: t("Type Node ID"),
    type_node: t("Type Node"),
    type_hostname: t("Type Hostname"),
    orderStatusCheck: t(
      "Click here to check the status of the Order. Order Id "
    ),

    order_ID: t("Order ID"),
    valid_core: t("Video Core RPD No"),
    mac_address: t("RPD Mac Address"),
    mac_domain: t("Mac Domain"),
    docsis_hostname: t("Docsis Hostname"),
    video_hostname: t("Video Hostname"),
    built_type: t("Built Type"),
    docsis_ip: t("Docsis IP"),
    video_ip: t("Video IP"),
    aux_core: t("Auxillary Core List"),
    qpsk: t("QPSK"),
    node_id: t("Node Id"),
    CERTIFY: t("CERTIFY"),
    Certification_History: t("Certification_History"),
    Node_Certification_Dtls: t("Node_Certification_Dtls"),
    Certified: t("Certified"),
    By: t("By"),
    Overall: t("Overall"),
    STime: t("STime"),
    ETime: t("ETime"),
    View_Less: t("View_Less"),
    View_More: t("View_More"),
    Completion: t("Completion"),
    CompleteFormMessage: t("CompleteFormMessage"),
    SearchNodeByAddress: t("SearchNodeByAddress"),
    beta: t("beta"),

    botType: t("Bot Type"),
    botGeneration: t("Generation"),
    botExecution: t("Execution"),
    botTypeGeneration: t("Generation"),
    botTypeExecution: t("Execution"),
    deviceType: t("Device Type"),
    devHarmonic: t("Harmonic"),
    devCasa: t("CASA"),
    devTypeHarmonic: t("Harmonic"),
    devTypeCasa: t("CASA"),

    type_cmts_hostname: t("Type CMTS Hostname"),
    type_cmts_ip: t("Type CMTS IP"),
    civicAddress: t("Civic Address"),
    type_civic_address: t("Type Civic Address"),
    falHostname: t("FAL Switch Hostname"),
    falIp: t("FAL Switch IP"),
    type_fal_hostname: t("Type FAL Switch Hostname"),
    type_fal_ip: t("Type FAL Switch IP"),
    enter_valid_fal_hostname: t("Enter valid FAL Switch Hostname"),
    enter_valid_fal_ip: t("Enter valid FAL Switch IP"),
    enter_valid_cmts_hostname: t("Enter valid CMTS Hostname"),
    enter_valid_cmts_ip: t("Enter valid CMTS IP"),
    vc_macdomain: t("Mac Domain (VC)"),
    type_vc_macdomain: t("Type Mac Domain (VC)"),
    enter_valid_vc_macdomain: t("Enter valid Mac Domain (VC)"),
    vs_sub_macdomain: t("Sub Mac Domain (VS)"),
    type_sub_vs_macdomain: t("Type Sub Mac Domain (VS)"),
    enter_valid_sub_vs_macdomain: t("Enter valid Sub Mac Domain (VS)"),
    dwdm_channel: t("DWDM Channel"),
    type_dwdm_channel: t("Type DWDM Channel"),
    enter_valid_dwdm_channel: t("Enter valid DWDM Channel"),
    order_id: t("Order ID "),
    order_status: t("Order Status"),
    olt_name: t("olt_name"),
    lt_details: t("lt_details"),
    lt_card_admin_status: t("lt_card_admin_status"),
    lt_card_oper_status: t("lt_card_oper_status"),
    lt_card_oper_error: t("lt_card_oper_error"),
    lt_card_last_restart_cause: t("lt_card_last_restart_cause"),
    lt_card_avail_status: t("lt_card_avail_status"),
    lt_card_power_status: t("lt_card_power_status"),
    lt_card_last_restart_time: t("lt_card_last_restart_time"),
    port_tx_optical_signal_level: t("port_tx_optical_signal_level"),
    port_rx_optical_signal_level: t("port_rx_optical_signal_level"),
    last_change_time: t("last_change_time"),
    gpon: t("gpon"),
    field_name: t("field_name"),
    gpon_ont_query: t("gpon_ont_query"),
    tier_2_ont_query: t("tier_2_ont_query"),
    ont_topology_data: t("ont_topology_data"),
    ont_oper_data: t("ont_oper_data"),
    ont_ethernet_port_oper_data: t("ont_ethernet_port_oper_data"),
    pon_port_oper_data: t("pon_port_oper_data"),
    olt_oper_data: t("olt_oper_data"),

    nptIdImtIdNctID: t("NPT ID / IMT ID / NCT ID"),
    nptIdImtIdNctIDEmptyError: t("Please enter valid NPT ID / IMT ID / NCT ID"),
    is_operational: t("is_operational"),
    admin_status: t("admin_status"),
    speed: t("speed"),
    cmts_rx: t("cmts_rx"),
    searchPlaceholdDocsisNode: t("searchPlaceholdDocsisNode"),
    searchPlaceholdGponNode: t("searchPlaceholdGponNode"),
    Size: t("Size"),
    Last_Modified: t("Last_Modified"),
    Upload: t("Upload"),
    Upload_a_File: t("Upload_a_File"),
    fileDownloadError: t("fileDownloadError"),
    File_Upload: t("File_Upload"),
    Ready_to_Upload: t("Ready_to_Upload"),
    Filter_By_File_Name: t("Filter_By_File_Name"),
    Ignite_Only: t("Ignite_Only"),
    Drag_Drop: t("Drag_Drop"),
    Browse_Files: t("Browse_Files"),
    File_Type_Not_Allowed: t("File_Type_Not_Allowed"),
    File_Upload_Warning_Msg: t("File_Upload_Warning_Msg"),
    cmDetails: t("cmDetails"),
    eCMInterfaceStatus: t("eCMInterfaceStatus"),
    eCMIP: t("eCMIP"),
    pcInterfaceType: t("pcInterfaceType"),
    accNumSearchErr: t("accNumSearchErr"),
    cbpSearchErr: t("cbpSearchErr"),
    macSearchErr: t("macSearchErr"),
    ontSearchErr: t("ontSearchErr"),
    tnSearchErr: t("tnSearchErr"),
    ipSearchErr: t("ipSearchErr"),
    modemTypeErr: t("modemTypeErr"),
    nodeIdErr: t("nodeIdErr"),
    invalidInputErr: t("invalidInputErr"),
    oltNameErr: t("oltNameErr"),
    nodeNAErr: t("nodeNAErr"),
    transmit: t("transmit"),
    receive: t("receive"),
    technology: t("technology"),
    nodeIDError: t("nodeIDError"),
    filteredResults: t("filteredResults"),
    DirectionsToHere: t("DirectionsToHere"),
    callId: t("callId"),
    failures: t("failures"),
    downstream: t("downstream"),
    upstream: t("upstream"),
    trace: t("trace"),
    neighbourhood: t("neighbourhood"),

    typeDeviceType: t("Select Device Type"),
    oldDeviceType: t("Old Device Type"),
    newDeviceType: t("New device Type"),
    nextBestActions: t("nextBestActions"),
    failedAttributes: t("failedAttributes"),
    marginallyPassedAttributes: t("marginallyPassedAttributes"),
    precheckAction: t("precheckAction"),
    Pre: t("Pre"),
    PrecheckValues: t("PrecheckValues"),
    Precheck: t("Precheck"),
    Current: t("Current"),
    LengthOfDrop: t("LengthOfDrop"),
    ResistanceOfDrop: t("ResistanceOfDrop"),
    WhatsLengthOfDrop: t("WhatsLengthOfDrop"),
    WhatsResistanceOfDrop: t("WhatsResistanceOfDrop"),
    WhyCertFails: t("WhyCertFails"),
    ValidateGoodCond: t("ValidateGoodCond"),
    ValidateWithTDRandOHM: t("ValidateWithTDRandOHM"),
    Reason: t("Reason"),
    ReviewedWithCustomer: t("ReviewedWithCustomer"),
    TechnicianNotes: t("TechnicianNotes"),
    odm3: t("odm3"),
    RF_MT_CALCULATOR: t("RF_MT_CALCULATOR"),
    rpdSwapNote: t(
      "PLEASE UPGRADE SW AFTER THE RPD SWAP IS COMPLETED AND COMES ONLINE"
    ),
    ODM: t("ODM"),
    FailedCM: t("FailedCM"),
    FCM: t("FCM"),
    rpdSwVersion: t("Software Version"),
    qamProfile: t("QAM Profile"),

    Managed_LANIP: t("Managed LAN-IP"),
    LAN_Subnet: t("LAN-Subnet"),
    ModulationProfiles: t("ModulationProfiles"),
    podWarningMsg: t("podWarningMsg"),
    fetchSwVersion: "Fetch SW Version",
    rpdTechnology: "Technology",
    cmtsMismatch: t("cmtsMismatch"),
    clamshellPeers: t("clamshellPeers"),
    clamShellId: t("clamShellId"),
  });
}
