import { format } from "date-fns";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { FaFileCsv } from "react-icons/fa";

import CustomPopOver from "../../../../components/reusable/CustomPopOver";
import TopologyLabels from "../../../constants/topologyLabels";
import { TCTypes } from "./Table";

interface Props {
  data: any;
  columns: TCTypes[];
  title: string;
  showExportBtn: boolean;
}

export default function ExportAsCsv({
  data,
  columns,
  title,
  showExportBtn,
}: Props) {
  const labels = TopologyLabels();
  const [csvData, setCsvData] = useState([]);

  const handleExport = () => {
    setCsvData(
      data.map((row: any) =>
        columns.reduce(
          (acc, column) => ({
            ...acc,
            [column.th]: convertDataToString(row[column.tdName]),
          }),
          {}
        )
      )
    );
  };

  if (!showExportBtn) return null;
  return (
    <CustomPopOver
      popOverData={labels.ExportAsCSV}
      index="popOver"
      originComp={
        <CSVLink
          data={csvData}
          filename={`${title} - ${format(
            new Date(),
            "M-d-yyyy, h-mm-ss a"
          )}.csv`}
          onClick={handleExport}
        >
          <FaFileCsv className="basicTableHeaderIcon" />
        </CSVLink>
      }
    />
  );
}

function convertDataToString(element: any): string {
  if (typeof element === "string") {
    return element;
  } else if (Array.isArray(element)) {
    return element.map(convertDataToString).join("");
  } else if (typeof element === "object" && element !== null && element.props) {
    if (
      element.$$typeof === Symbol.for("react.element") &&
      "label" in element.props
    ) {
      return element.props.label;
    } else if (element.props.dangerouslySetInnerHTML) {
      const html = element.props.dangerouslySetInnerHTML.__html;
      const tempElement = document.createElement("div");
      tempElement.innerHTML = html;
      return tempElement.textContent || tempElement.innerText || "";
    } else if (element.props.children) {
      return convertDataToString(element.props.children);
    }
  }
  return element;
}
