import { createSlice } from "@reduxjs/toolkit";

import { apiCallBegan } from "../../../middleware/apiActions";
import { ADDRESS_BY_SAM_KEYS } from "../../../middleware/apiEndpoints";
import storeConst from "../../../storeConst";
import { decrApiCall, incrApiCall } from "../mapDetails";

const slice = createSlice({
  name: "samkeyAddress",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    getSamkeyAddressBegin: (samkeyAddress) => {
      samkeyAddress.isLoading = true;
      samkeyAddress.status = "";
    },
    getSamkeyAddressSuccess: (samkeyAddress, { payload }) => {
      samkeyAddress.isLoading = false;
      samkeyAddress.data = payload;
      samkeyAddress.status = storeConst.OK;
    },
    getSamkeyAddressFailure: (samkeyAddress, { payload }) => {
      samkeyAddress.isLoading = false;
      samkeyAddress.error = payload;
      samkeyAddress.data = undefined;
      samkeyAddress.status = storeConst.ERROR;
    },
    resetSamKeyAddress: (samkeyAddress) => {
      samkeyAddress.isLoading = false;
      samkeyAddress.error = undefined;
      samkeyAddress.data = undefined;
      samkeyAddress.status = "";
    },
  },
});

const {
  getSamkeyAddressBegin,
  getSamkeyAddressSuccess,
  getSamkeyAddressFailure,
} = slice.actions;
export default slice.reducer;

export const { resetSamKeyAddress } = slice.actions;

export const getAddressBySamKeys = (samKeys: any) => (dispatch: any) => {
  dispatch(
    apiCallBegan({
      url: ADDRESS_BY_SAM_KEYS + samKeys,
      method: "get",
      onStart: getSamkeyAddressBegin.type,
      onSuccess: getSamkeyAddressSuccess.type,
      onError: getSamkeyAddressFailure.type,
      onStartDispatch: incrApiCall.type,
      onSuccessDispatch: decrApiCall.type,
      onErrorDispatch: decrApiCall.type,
    })
  );
};
