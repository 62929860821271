import { InfoWindow } from "@react-google-maps/api";

import TopologyLabels from "../../../../constants/topologyLabels";
import { formatCamelCase } from "../../../../util/utilFuncs";
import { TCTypes, TDTypes } from "../../../reusable/table/Table";

interface Props {
  activeClusterData?: any;
  showCluster?: any;
  setShowCluster?: any;
  onMarkerPress?: any;
}

export default function ActiveClickClusterTable({
  activeClusterData,
  showCluster,
  setShowCluster,
  onMarkerPress,
}: Props) {
  const labels = TopologyLabels();
  const dataList = activeClusterData?.clusterData;

  const tableData = [] as TDTypes[];

  dataList?.map((e: any) => {
    const propData = e?.properties?.data;
    tableData.push({
      callId: (
        <a className="idLink" onClick={() => onMarkerPress(e)}>
          {formatCamelCase(propData?.callId)}
        </a>
      ),
      orderType: propData?.orderTypeName,
      id: propData?.callId,
    } as TDTypes);
  });

  const tableColumns = [
    {
      th: labels.ID,
      thStyle: { minWidth: 80 },
      tdName: "callId",
    },

    {
      th: labels.orderType,
      thStyle: { minWidth: 80 },
      tdName: "orderType",
    },
  ] as TCTypes[];

  return (
    <>
      {showCluster && dataList && dataList?.length > 0 && (
        <InfoWindow
          position={{
            lat: activeClusterData?.lat,
            lng: activeClusterData?.lng,
          }}
          onCloseClick={() => setShowCluster(false)}
          options={{
            zIndex: 1000,
            pixelOffset: new google.maps.Size(0, -12),
          }}
        >
          <div style={{ marginTop: 10 }}>
            <table className="dataTableDetails">
              <thead>
                <tr>
                  {tableColumns?.map((column, i: number) => (
                    <th key={i} style={column.thStyle}>
                      {column.th}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData?.map((TD: any) => (
                  <tr key={TD.id}>
                    {tableColumns?.map((column, i: number) => (
                      <td key={i} style={column.tdStyle}>
                        {TD?.[column?.tdName]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </InfoWindow>
      )}
    </>
  );
}
