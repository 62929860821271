import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AiOutlineZoomIn } from "react-icons/ai";

import TopologyLabels from "../../constants/topologyLabels";
import {
  getNodeSiteDetail,
  setActiveNodeIndex,
  setActiveNodeSiteId,
} from "../../store/slices/mapItems/node/nodeSiteDetail";
import {
  GetGoogleMaps,
  GetNodeSiteDetailsProps,
  GetTopoGeneralProps,
} from "../../util/reduxFunctions/getTopologyState";
import TopoSettings from "./settings/TopoSettings";
import { IoMdLocate } from "react-icons/io";
import {
  setMapCenter,
  setSelectedMapElement,
} from "../../store/slices/mapItems/mapDetails";

export default function DrawerHeader() {
  const dispatch = useDispatch();
  const labels = TopologyLabels();
  const { zoomLevel } = GetGoogleMaps();
  const { activeNodeId } = GetTopoGeneralProps();
  const { allNodeData } = GetNodeSiteDetailsProps();
  const nodeSiteCoords =
    allNodeData?.[0]?.node?.data?.features?.[0]?.properties?.data?.siteCoords
      ?.coordinates;
  const geoData = { lat: nodeSiteCoords?.[1], lng: nodeSiteCoords?.[0] };
  const nodeSiteLocator = {
    geometry: geoData,
    properties: {
      id: allNodeData?.[0]?.node?.data?.features?.[0]?.properties?.featureId,
      type: allNodeData?.[0]?.node?.data?.features?.[0]?.properties?.type,
      data: allNodeData?.[0]?.node?.data?.features?.[0]?.properties?.data,
      rootNodeSiteId:
        allNodeData?.[0]?.node?.data?.features?.[0]?.properties?.rootNodeSiteId,
    },
  };
  //get topology if current node config ID is not same as current topology node id
  useEffect(() => {
    if (activeNodeId) dispatch(getNodeSiteDetail(activeNodeId));
  }, []);
  const locateNodeSite = () => {
    nodeSiteCoords && dispatch(setMapCenter(geoData));
    dispatch(setActiveNodeIndex(0));
    dispatch(setActiveNodeSiteId(allNodeData?.[0]?.nodeSiteId));
    dispatch(setSelectedMapElement(nodeSiteLocator));
  };

  return (
    <div className="topoSearchCont">
      {activeNodeId ? (
        <div className="headerTitle">
          <label style={{ margin: 0, fontWeight: 600 }}>
            {labels.node_ID.toUpperCase()}: {activeNodeId}
          </label>
          <span className="customTRCell">
            <IoMdLocate onClick={locateNodeSite} />
          </span>
        </div>
      ) : (
        <div></div>
      )}
      <div className="flexedRowDiv">
        <div className="zoomCont">
          <label>{zoomLevel}</label>
          <AiOutlineZoomIn />
        </div>
        <TopoSettings />
      </div>
    </div>
  );
}
