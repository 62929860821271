import { useState } from "react";
import { TableRow, TableHead } from "@material-ui/core";
import { FaSortDown, FaSortUp, FaSort } from "react-icons/fa";

const HistoryTableHead = (props: any) => {
  const { onSearch, onSorting } = props;
  const [searchTechID, setSearchTechID] = useState("");
  const [searchWID, setSearchWID] = useState("");
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");
  const onSortingChange = (field: string) => {
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

    setSortingField(field);
    setSortingOrder(order);
    onSorting(field, order);
  };
  const resetSearchValue = () => {
    setSearchTechID("");
    setSearchWID("");
    onSearch("", "");
  };

  const onTechIDChange = (value: string, by: string) => {
    setSearchTechID(value);
    onSearch(value, by);
  };
  const onWOrderIDChange = (value: any, by: string) => {
    setSearchWID(value);
    onSearch(value, by);
  };

  return (
    <>
      <TableHead className="pTabHead">
        <TableRow>
          <th className="mediumCol">Work Order ID</th>
          <th className="mediumCol">Form Type</th>
          <th className="largeCol" onClick={() => onSortingChange("techId")}>
            <span>Tech ID</span>
            {sortingField && sortingField === "techId" ? (
              <button className="deviceSortBtn">
                {sortingOrder === "asc" ? (
                  <FaSortUp color="#000000bf" />
                ) : (
                  <FaSortDown color="#000000bf" />
                )}
              </button>
            ) : (
              <button className="deviceSortBtn">
                <FaSort color="#c4c4c4" />
              </button>
            )}
          </th>
          <th className="smallCol">Account ID</th>
          <th className="smallCol">Referred To</th>
          <th>Submitted On</th>
          {/* <th>RPA Success</th> */}
        </TableRow>
        <TableRow>
          <td>
            <input
              type="text"
              className="sInput"
              placeholder="Search.."
              value={searchWID}
              onFocus={resetSearchValue}
              onChange={(e) =>
                onWOrderIDChange(
                  e.target.value.replace(/\s+/g, ""),
                  "workOrderId"
                )
              }
            />
          </td>
          <td></td>
          <td>
            <input
              type="text"
              className="sInput"
              placeholder="Search.."
              value={searchTechID}
              onFocus={resetSearchValue}
              onChange={(e) =>
                onTechIDChange(e.target.value.toString(), "techId")
              }
            />
          </td>
          <td></td>
          <td></td>
          <td></td>
        </TableRow>
      </TableHead>
    </>
  );
};

export default HistoryTableHead;
