import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../middleware/apiActions";
import { CLICK_TASK_URI } from "../../middleware/apiEndpoints";
import storeConst from "../../storeConst";
import { decrApiCall, incrApiCall } from "./mapDetails";

const slice = createSlice({
  name: "clickOrders",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
    clickStatusFlt: undefined,
    clickOrderFlt: undefined,
    isOrdersVisible: false,
  },
  reducers: {
    getClickOrdersBegin: (clickOrders) => {
      clickOrders.isLoading = true;
      clickOrders.status = "";
    },
    getClickOrdersSuccess: (clickOrders, { payload }) => {
      clickOrders.isLoading = false;
      clickOrders.data = payload;
      clickOrders.status = storeConst.OK;
    },
    getClickOrdersFailure: (clickOrders, { payload }) => {
      clickOrders.isLoading = false;
      clickOrders.error = payload;
      clickOrders.data = undefined;
      clickOrders.status = storeConst.ERROR;
    },
    setClickStatusFlt: (clickOrders, { payload }) => {
      clickOrders.clickStatusFlt = payload;
    },
    setClickOrderFlt: (clickOrders, { payload }) => {
      clickOrders.clickOrderFlt = payload;
    },
    setOrdersVisible: (clickOrders, { payload }) => {
      clickOrders.isOrdersVisible = payload;
    },
  },
});

export const { setClickStatusFlt, setClickOrderFlt, setOrdersVisible } =
  slice.actions;
const { getClickOrdersBegin, getClickOrdersSuccess, getClickOrdersFailure } =
  slice.actions;
export default slice.reducer;

export const getClickOrders = (props: any) =>
  apiCallBegan({
    url: CLICK_TASK_URI,
    data: props,
    method: "post",
    onStart: getClickOrdersBegin.type,
    onSuccess: getClickOrdersSuccess.type,
    onError: getClickOrdersFailure.type,
    onStartDispatch: incrApiCall.type,
    onSuccessDispatch: decrApiCall.type,
    onErrorDispatch: decrApiCall.type,
  });
