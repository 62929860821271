import { elementDataConfig } from "../../config/mapItemsConfig";
import CustomAccordion from "../../../components/reusable/CustomAccordion";
import MapEleDataDisplay from "../../../components/reusable/MapEleDataDisplay";
import appConst from "../../constants/appConst";
import OtherActiveMapElementComps from "./OtherActiveMapElementComps";
import {
  getParametersForCpatTable,
  getParametersForTapTable,
} from "../../util/utilFuncs";
import CustomAMEComps from "./CustomAMEComps";
import GoogleMapDirectionsBtn from "../../../components/reusable/GoogleMapDirectionsBtn";
import PowerTraceBtn from "./powertrace/PowerTraceBtn";
import CustomPopUp from "../../../components/reusable/CustomPopUp";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { MdError } from "react-icons/md";
import TopologyLabels from "../../constants/topologyLabels";
import RfTraceButtons from "./trace/rf/RfTraceButtons";
import MduSchematicsBtn from "./mdu/MduSchematicsBtn";
import { NetworkConfig } from "../../../network/httpclient/NetworkConfig";
import { IHttpClientRequestParameters } from "../../../network/httpclient/IHttpClientRequestParameters";
import { HttpClient } from "../../../network/httpclient/HttpClient";
import { image } from "html2canvas/dist/types/css/types/image";
import { createPortal } from "react-dom";
import RasterImageBtn from "./mdu/RasterImageBtn";

interface props {
  elementData: any;
  isPrimaryNodeTab?: boolean;
}

const ActiveMapElement = ({ elementData, isPrimaryNodeTab = false }: props) => {
  const appLabels = TopologyLabels();
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpBody, setPopUpBody] = useState(<></>);
  const [popUpName, setPopUpName] = useState("MDU OWNING DRAWINGS");
  const [showImagePopUp, setShowImagePopUp] = useState(false);
  const [imageName, setImageName] = useState('');
  const labels = TopologyLabels();
  let eleDataConfig: any;
  let cpatData: any;
  let tapData: any;
  elementDataConfig?.map((o, i) => {
    if (elementData?.type === o.name) {
      eleDataConfig = o;
    }
  });

  if (eleDataConfig?.name === appConst?.cpat) {
    cpatData = getParametersForCpatTable(elementData);
    tapData = null;
  } else if (eleDataConfig?.name === appConst?.taps) {
    cpatData = null;
    tapData = getParametersForTapTable(elementData);
  } else {
    cpatData = null;
    tapData = null;
  }

  const onClickForMDU = () => {
    setPopUpName("MDU OWNING DRAWINGS");
    if (elementData?.data?.owningDrawings) {
      if (elementData?.data?.owningDrawings?.length > 1) {
        setPopUpBody(
          <div className="popUpBodyCon">
            {elementData?.data?.owningDrawings?.map((drawing: any) => {
              return (
                <p
                  className="drawingNumChip"
                  onClick={() => openMDUScreen(drawing?.id)}
                >
                  <span>
                    {appLabels.networkID}: {elementData?.data?.networkId}
                  </span>
                  <span>
                    {appLabels.drawingName}:{" "}
                    {drawing?.name + "(" + drawing?.id + ")"}
                  </span>
                </p>
              );
            })}
          </div>
        );
        setShowPopUp(true);
      } else openMDUScreen(elementData?.data?.owningDrawings?.[0]?.id);
    } else {
      setPopUpBody(
        <div className="errorMSG">
          <MdError size={25} color={`var(--danger)`} />
          <p>Owning Drawing number is not Available</p>
        </div>
      );
      setShowPopUp(true);
    }
  };

  const onClickForOwningDiagram = async () => {
    if (elementData?.data?.owningDrawings) {
      {
        elementData?.data?.owningDrawings?.map((drawing: any) => {
          getOwningImage(drawing?.id);
        });
      }
    } else {
      setPopUpBody(
        <div className="errorMSG">
          <MdError size={25} color={`var(--danger)`} />
          <p>Owning Drawing number is not Available</p>
        </div>
      );
      setShowPopUp(true);
    }
  };

  const openMDUScreen = (owningDrawingNum: string) => {
    window.open(`/#/topology/mdu?owningDrawing=` + owningDrawingNum);
    setShowPopUp(false);
  };

  const showImage = (imageFile: any) =>{
    const image = new Image();
        image.src = `${appConst.base64StringPrefix}${imageFile.image}`
        const w : any = window.open();
        setTimeout(() => {
          w.document.title = "PAPER MAP - " + imageFile.name;
      }, 100);
        w.document.write(image.outerHTML);
  };

  const openOwningScreen = (imageFileName: string) => {
    let httpParameters: IHttpClientRequestParameters<any> = {
      url: `${NetworkConfig.SYMBOLS_SERVICE_URL}mduraster/${imageFileName}`,
    };
    HttpClient.get<any>(httpParameters)
      .then((resp) => {
        setPopUpBody(
          <>
            <div className="popUpBodyCon">
              {resp.map((image: any) => {
                return (
                  <p
                    className="drawingNumChip"
                    onClick={() => showImage(image)}
                  >
                    {image.name}
                  </p>
                );
              })}
            </div>
            
          </>
        );
        setShowPopUp(true);
      })
      .catch((error) => {
        return error;
      });
  };

  const getOwningImage = async (owningDrawingNum: string) => {
    let httpParameters: IHttpClientRequestParameters<any> = {
      url: `${NetworkConfig.TOPOLOGY_SERVICE_URL}mdu/raster/${owningDrawingNum}`,
    };
    HttpClient.get<any>(httpParameters)
      .then((resp) => {
        setPopUpName("PAPER MAP");
        if (resp?.features) {
            {
              resp?.features?.map((topology: any) => {
                openOwningScreen(topology?.properties?.data?.imageFile);
              });
            }
        }
      })
      .catch((error) => {
        return error;
      });
  };

  return (
    <div className="activeMapEleCont">
      {elementData !== undefined &&
      eleDataConfig !== undefined &&
      eleDataConfig?.length !== 0 ? (
        <>
          <CustomAccordion
            accTitle={
              eleDataConfig?.name === appConst.node &&
              elementData?.data?.nodeNum
                ? eleDataConfig?.title?.toUpperCase() +
                  " - " +
                  elementData?.data?.nodeNum
                : eleDataConfig?.title?.toUpperCase()
            }
            headerComp={
              <>
                <RfTraceButtons activeMapElement={elementData} />
                <PowerTraceBtn activeMapElement={elementData} />
                <GoogleMapDirectionsBtn
                  lat={elementData?.coord?.lat}
                  lng={elementData?.coord?.lng}
                />
                <RasterImageBtn
                  activeMapElement={elementData}
                  onClick={onClickForOwningDiagram}
                />
                <MduSchematicsBtn
                  activeMapElement={elementData}
                  onClick={onClickForMDU}
                />
              </>
            }
            accBodyElement={
              <>
                {elementData?.data && (
                  <MapEleDataDisplay
                    tableConfig={eleDataConfig}
                    eleData={
                      eleDataConfig?.name === appConst?.cpat
                        ? cpatData
                        : eleDataConfig?.name === appConst?.taps
                        ? tapData
                        : elementData?.data
                    }
                  />
                )}
              </>
            }
          />
          <OtherActiveMapElementComps elementData={elementData} />
        </>
      ) : (
        <CustomAMEComps
          isPrimaryNodeTab={isPrimaryNodeTab}
          elementData={elementData}
        />
      )}
      <CustomPopUp
        showVal={showPopUp}
        headerText={popUpName}
        bodyElement={popUpBody}
        handleClose={() => setShowPopUp(false)}
      />
    </div>
  );
};

export default ActiveMapElement;
