import { Form } from "react-bootstrap";

const DropdownComponent = (props: any) => {
  const optionList = props.options?.map((item: any) => (
    <option key={item} value={item}>
      {item}
    </option>
  ));
  return (
    <Form.Group className="selector">
      <Form.Label className="viewmode">{props.title}</Form.Label>
      <Form.Control
        disabled={props.disabled}
        as="select"
        className="select"
        value={props.value}
        name="fbType"
        onChange={props.onChange}
      >
        {optionList}
      </Form.Control>
    </Form.Group>
  );
};

export default DropdownComponent;
