import { FormControl, InputGroup } from "react-bootstrap";

interface Props {
  value: any;
  setValue: any;
  placeholder?: string;
}
const NumberInputBox = ({ value, setValue, placeholder }: Props) => {
  return (
    <InputGroup className="numStepperCont">
      <FormControl
        placeholder={placeholder}
        className="numStepperInput"
        id="numStepper"
        value={value}
        onChange={(val: any) => {
          let compVal: string = val.target.value?.replace(/[^-\d.\d]/g, "");
          setValue(compVal);
        }}
        autoComplete="on"
      />
    </InputGroup>
  );
};

export default NumberInputBox;
