import { useState } from "react";
import { Card } from "react-bootstrap";
import CustomAccordion from "../../components/reusable/CustomAccordion";
import CustomTab from "../../components/reusable/CustomTab";
import DataDisplayTable from "../../components/reusable/DataDisplayTable";
import AppLabels from "../../constants/App_Labels";
import { TabPanel } from "../account-page/AccountPageTabs";

const ViewODUDetails = (props: any) => {
  const labels = AppLabels();
  const [tabSelIndex, setTabSelIndex] = useState(0);
  return (
    <div className="oduDetailsCont">
      <CustomAccordion
        accTitle={labels.ODU_Details.toUpperCase()}
        accBodyElement={
          <>
            <DataDisplayTable
              dataObject={props.oduData}
              tableName="oduDetails"
            />
          </>
        }
      />
      <CustomAccordion
        accTitle={"Cell Details".toUpperCase()}
        accBodyElement={
          <>
            <Card className="dsChTabsCard">
              <Card.Header>
                {props.oduData !== undefined && (
                  <>
                    <CustomTab
                      tabType="horizontal"
                      index={0}
                      value={tabSelIndex}
                      handleSelect={(i: any) => setTabSelIndex(i)}
                      label="PRIMARY CELL"
                    />
                    {props.oduData?.["Secondary Cell"]?.map(
                      (c: any, index: number) => {
                        return (
                          <CustomTab
                            tabType="horizontal"
                            index={index + 1}
                            value={tabSelIndex}
                            handleSelect={(i: any) => setTabSelIndex(i)}
                            label={`CELL ${index + 1}`}
                          />
                        );
                      }
                    )}
                  </>
                )}
              </Card.Header>
              <Card.Body>
                {props.oduData !== undefined && (
                  <>
                    <TabPanel value={tabSelIndex} index={0}>
                      <DataDisplayTable
                        dataObject={props.oduData?.["Primary Cell"]}
                        tableName="cellDetails"
                      />
                    </TabPanel>
                    {props.oduData?.["Secondary Cell"]?.map(
                      (c: any, index: number) => {
                        return (
                          <TabPanel value={tabSelIndex} index={index + 1}>
                            <DataDisplayTable
                              dataObject={c}
                              tableName="cellDetails"
                            />
                          </TabPanel>
                        );
                      }
                    )}
                  </>
                )}
              </Card.Body>
            </Card>
          </>
        }
      />
    </div>
  );
};

export default ViewODUDetails;
