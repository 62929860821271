import { HistoryState } from "../../states/history/History";
import {
  HISTORY_BEGIN,
  HISTORY_SUCCESS,
  HISTORY_RESET,
  HISTORY_FAILURE,
  HistoryActionTypes,
} from "../../types/history/History";

const initialState: HistoryState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function HistoryReducer(
  state = initialState,
  action: HistoryActionTypes
): HistoryState {
  switch (action.type) {
    case HISTORY_BEGIN:
      return {
        ...state,
        isLoading: true,
      };
    case HISTORY_SUCCESS:
      if (action.payload !== undefined) {
        if (action.payload?.first) {
          action.payload["indexes"] = [
            {
              index: action.payload?.number,
              data: action.payload?.content,
            },
          ];
        } else {
          const prevData = state.data?.indexes;
          const newData = [
            {
              index: action.payload?.number,
              data: action.payload?.content,
            },
          ];
          action.payload[`indexes`] = [...prevData, ...newData];
        }
      }
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case HISTORY_RESET:
      return {
        ...state,
        data: undefined,
      };
    case HISTORY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}
