import { useEffect, useRef } from "react";
import { zoomLevelRenderMarker } from "../../../config/mapConfig";
import appConst from "../../../constants/appConst";
import { useFeaturesData } from "../../../util/customHooks/topologyHooks";
import { GetGoogleMaps } from "../../../util/reduxFunctions/getTopologyState";

interface Props {
  gMap: any;
  featuresAll: any;
  zoomLevel: any;
}

const AddressMarkers = ({ gMap, featuresAll, zoomLevel }: Props) => {
  const { currentViewBDY } = GetGoogleMaps();
  const markerWM = useRef(0);
  const featureWM = useFeaturesData(featuresAll, currentViewBDY, zoomLevel);

  useEffect(() => {
    if (
      gMap &&
      zoomLevel >= zoomLevelRenderMarker &&
      featureWM !== undefined &&
      featureWM?.newFeatures?.length > 0 &&
      featureWM?.allFeatures?.length !== markerWM?.current
    ) {
      const tempGeoJson = {
        type: appConst?.FeatureCollection,
        features: featureWM?.newFeatures,
      };
      gMap?.data.addGeoJson(tempGeoJson);
      markerWM.current = featureWM?.allFeatures?.length;
    }
  }, [featureWM]);

  return <></>;
};

export default AddressMarkers;
