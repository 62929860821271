import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { HttpClient } from "../HttpClient";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { NetworkConfig } from "../NetworkConfig";

import {
    PonOltInfoSuccess,
  PonOltInfoFailure,
  PonOltInfoBegin,
} from "../../../store/actions/gpon/PONOLTAction";
import {
  DecrementGponNodeApiCallCounter,
  IncrementGponNodeApiCallCounter,
  UpdateGponNodeDevices,
} from "../../../store/actions/gpon/GponNodeSummaryAction";
import { PONOLTInfo } from "../../../store/models/gpon/PONOLTInfo";
import { GponNodeSummary } from "../../../store/models/gpon/GponNodeSummary";
import appConst from "../../../topology/constants/appConst";
import { UniversalSearchTypes } from "../../../topology/store/reduxTypes";
import { setActiveMainSearchType } from "../../../topology/store/slices/topoGeneral";

export const usePONONTClient = () => {
  const dispatch = useDispatch();

 
   const getOLTData = useCallback(
    async (oltName: string, ponPort: string) => {
    let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.NETWORK_SERVICE_URL + "diag/network/olt/" +
          oltName + '/'+
          ponPort
      };
   
      dispatch(PonOltInfoBegin());
      const result = await HttpClient.get<PONOLTInfo>(httpParameters)
        .then((PONOLTInfo) => {
          dispatch(PonOltInfoSuccess(PONOLTInfo));
          return PONOLTInfo;
        })
        .catch((error) => {
          dispatch(PonOltInfoFailure(error));
          return error;
        });
      return result;
    },
    [dispatch]
  );
    
  
  return {
    getOLTData,
  };
};