import DataDisplayTable from "../reusable/DataDisplayTable";
import AppLabels from "../../constants/App_Labels";
import CustomAccordion from "../reusable/CustomAccordion";
import { isNull, isObject } from "../reusable/Util";
import { remAttrFromJsonObj } from "../../topology/util/utilFuncs";

const STBDeviceInfo = (props: any) => {
  const { deviceData } = props;
  const labels = AppLabels();

  const newDeviceData = remAttrFromJsonObj(deviceData, "cmMac");

  return (
    <CustomAccordion
      accTitle={labels.SUMMARY.toUpperCase()}
      accBodyElement={
        <>
          {newDeviceData !== undefined ? (
            <DataDisplayTable
              dataObject={newDeviceData}
              tableName="stbSummary"
            />
          ) : (
            <></>
          )}
        </>
      }
    />
  );
};

export default STBDeviceInfo;
