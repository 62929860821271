import * as React from "react";
import { useDispatch } from "react-redux";
import { GetNodeCertificationHist } from "../../../get-api/GetResponse";
import NodeCertificateDetails from "../../../screens/node-page/NodeCertificateDetails";
import { getNodeCertificationDetails } from "../../../store/slices/nodecertifications/nodeCertificationsHist";

export const CertificationHistTab = (props: any) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState<any>({
    nodeCertHist: [],
    history: { id: "" },
    index: 0,
  });
  const { nodeCertHistData } = GetNodeCertificationHist();
  const [nodeCerti, setNodeCerti] = React.useState<any>({});

  React.useEffect(() => {
    const message = (event: any) => {
      if (event?.data.nodeCertHistData) {
        setData(event.data);
        setNodeCerti(event.data?.nodeCertHistData[event.data.index]);
      }
    };
    window.addEventListener("message", message);
    return () => {
      window.removeEventListener("message", message);
    };
  }, []);

  React.useEffect(() => {
    if (data?.history?.id !== "" && data?.history?.id !== undefined) {
      dispatch(getNodeCertificationDetails(data?.history?.id));
    }
  }, [data, dispatch]);

  React.useEffect(() => {
    if (nodeCertHistData !== undefined) {
      setNodeCerti((prev: any) => ({
        ...prev,
        mlData: nodeCertHistData[0]?.mlData,
      }));
    }
  }, [nodeCertHistData]);

  return (
    <>
      {nodeCerti?.mlData && <NodeCertificateDetails nodeCertData={nodeCerti} />}
    </>
  );
};
