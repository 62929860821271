import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../middleware/apiActions";
import { NODE_SITE_COMPONENTS } from "../../../middleware/apiEndpoints";
import storeConst from "../../../storeConst";
import { StandardReduxType } from "../../../reduxTypes";
import appConst from "../../../../constants/appConst";

type NodeSiteCompProp = [
  {
    networkId?: string;
    isLoading: boolean;
    data?: StandardReduxType;
    error?: StandardReduxType;
    status?: string;
  }?
];

const slice = createSlice({
  name: "mduSiteComponents",
  initialState: {
    allMduNSC: [] as NodeSiteCompProp,
  },
  reducers: {
    getMDUSiteComponentsBegin: (mduSiteComponents, { payload }) => {
      const mduNSCIndex = mduSiteComponents?.allMduNSC?.findIndex(
        (nsc: any) => nsc?.networkId === payload.networkId
      );
      if (mduNSCIndex === -1) {
        mduSiteComponents.allMduNSC.push({
          networkId: payload.networkId,
          isLoading: true,
        });
      } else {
        mduSiteComponents.allMduNSC[mduNSCIndex] = {
          networkId: payload.networkId,
          isLoading: true,
          data: undefined,
          error: undefined,
          status: "",
        };
      }
    },
    getMDUSiteComponentsSuccess: (mduSiteComponents, { payload }) => {
      const mduNSCIndex = mduSiteComponents?.allMduNSC?.findIndex(
        (nsc: any) => nsc?.networkId === payload.networkId
      );
      const mduNSCData = mduSiteComponents?.allMduNSC[mduNSCIndex];
      mduSiteComponents.allMduNSC[mduNSCIndex] = {
        ...mduNSCData,
        ...{ isLoading: false, data: payload.data, status: storeConst.OK },
      };
    },
    getMDUSiteComponentsFailure: (mduSiteComponents, { payload }) => {
      const mduNSCIndex = mduSiteComponents?.allMduNSC?.findIndex(
        (nsc: any) => nsc?.networkId === payload.networkId
      );
      const mduNSCData = mduSiteComponents?.allMduNSC[mduNSCIndex];
      mduSiteComponents.allMduNSC[mduNSCIndex] = {
        ...mduNSCData,
        ...{ isLoading: false, error: payload.data, status: storeConst.ERROR },
      };
    },
  },
});

const {
  getMDUSiteComponentsBegin,
  getMDUSiteComponentsSuccess,
  getMDUSiteComponentsFailure,
} = slice.actions;
export default slice.reducer;

export const getMDUSiteComponent =
  (networkId: any) => (dispatch: any, getState: any) => {
    const { allMduNSC } =
      getState().slices.topologyState.mduElements.mduSiteComponents;

    let mduNodeSiteData = allMduNSC?.filter(
      (nsc: any) => nsc?.networkId === networkId
    )?.[0];
    if (!mduNodeSiteData) {
      dispatch(
        apiCallBegan({
          url: NODE_SITE_COMPONENTS + networkId,
          onStart: getMDUSiteComponentsBegin.type,
          onSuccess: getMDUSiteComponentsSuccess.type,
          onError: getMDUSiteComponentsFailure.type,
          props: { networkId },
        })
      );
    }
  };
