import { STBSummary } from "../../../../models/account/diagnostics/stb/STBSummary";
import {
  STB_CERTI_SUMMARY_BEGIN,
  STB_CERTI_SUMMARY_SUCCESS,
  STB_CERTI_SUMMARY_FAILURE,
  StbCertiSummaryActionTypes,
} from "../../../../types/account/certifications/stb/STBCertiSummary";

export function stbCertiSummaryBegin(): StbCertiSummaryActionTypes {
  return {
    type: STB_CERTI_SUMMARY_BEGIN,
  };
}

export function stbCertiSummarySuccess(
  stbCertiSummary: STBSummary
): StbCertiSummaryActionTypes {
  return {
    type: STB_CERTI_SUMMARY_SUCCESS,
    payload: stbCertiSummary,
  };
}

export function stbCertiSummaryFailure(
  error: Error
): StbCertiSummaryActionTypes {
  return {
    type: STB_CERTI_SUMMARY_FAILURE,
    payload: error,
  };
}
