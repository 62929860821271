import React from "react";

export default function TechInsightIcon(props: any) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 338 338"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
          d="M272 45C245 27.7371 205 25 169 25C133 25 93 27.7371 66 45C57.3643 50.5214 50.5214 57.3643 45 66C27.7371 93 25 133 25 169C25 205 27.7371 245 45 272C50.5214 280.636 57.3643 287.479 66 293C93 310.263 133 313 169 313C205 313 245 310.263 272 293C280.636 287.479 287.479 280.636 293 272C310.263 245 313 205 313 169C313 133 310.263 93 293 66C287.479 57.3643 280.636 50.5214 272 45Z"
          fill="#DA291C"
        />
      </g>
      <g filter="url(#filter1_f)">
        <path
          d="M306.5 172.5L250.976 109L103 247.111L156.5 306L288.5 292L306.5 172.5Z"
          fill="url(#paint0_linear)"
        />
      </g>
      <g filter="url(#filter2_d)">
        <circle cx="169" cy="169" r="104" fill="white" />
      </g>
      <path
        d="M162 168.412C162 169.019 162 169.627 161.939 170.448C161.818 173.517 161.423 176.525 160.754 179.473L155.345 175.917L147.87 190.686C146.958 192.479 145.135 193.633 143.129 193.603C141.124 193.603 139.27 192.448 138.389 190.686L129.728 173.426L124.532 183.818C123.56 185.763 121.463 186.948 119.305 186.735C117.117 186.523 115.294 185.034 114.687 182.937L107.181 157.138L97.6694 202.263C97.1832 204.634 95.1169 206.366 92.6859 206.457C92.5947 206.457 92.5339 206.487 92.4732 206.487C90.1333 206.487 88.0366 204.907 87.3681 202.628L79.4976 175.34L74.3926 186.188C73.7544 187.495 72.5996 188.528 71.2019 188.954C66.5001 188.5 66.0556 185.5 66.0001 185C65.6368 181.73 64.3669 176.364 65.0001 174.5C65.2383 173.799 66.7506 177.885 67.2212 176.525L75.912 158.11C76.854 156.105 78.9507 154.919 81.1385 155.072C83.3265 155.254 85.2106 156.773 85.8183 158.9L91.6831 179.229L101.164 134.195C101.681 131.825 103.717 130.093 106.178 130.002C108.579 129.941 110.766 131.491 111.465 133.83L121.098 166.983L124.958 159.235C125.869 157.442 127.692 156.287 129.698 156.287C129.728 156.287 129.728 156.287 129.728 156.287C131.734 156.287 133.557 157.442 134.469 159.235L143.16 176.525L148.569 165.859C149.268 164.492 150.483 163.519 151.942 163.124C153.4 162.729 154.981 162.972 156.226 163.823L161.97 167.622C162 167.865 162 168.138 162 168.412Z"
        fill="#DA291C"
        fill-opacity="0.08"
      />
      <path
        d="M229 177L222.87 190.686C221.958 192.479 220.135 193.633 218.129 193.603C216.124 193.603 214.27 192.448 213.389 190.686L204.728 173.426L199.532 183.818C198.56 185.763 196.463 186.948 194.305 186.735C192.117 186.523 190.294 185.034 189.686 182.937L182.181 157.138L172.669 202.263C172.183 204.634 170.117 206.366 167.686 206.457C167.595 206.457 167.534 206.487 167.473 206.487C165.133 206.487 163.037 204.907 162.368 202.628L154.498 175.34L149.393 186.188C148.754 187.495 147.6 188.528 146.202 188.954C145.32 189.227 144.409 189.288 143.528 189.105C141.249 184.578 139.729 179.685 139 174.702L142.221 176.525L150.912 158.11C151.854 156.105 153.951 154.919 156.138 155.072C158.326 155.254 160.211 156.773 160.818 158.9L166.683 179.229L176.164 134.195C176.681 131.825 178.716 130.093 181.178 130.002C183.579 129.941 185.766 131.491 186.465 133.83L196.098 166.983L199.958 159.235C200.869 157.442 202.692 156.287 204.698 156.287C204.728 156.287 204.728 156.287 204.728 156.287C206.734 156.287 208.557 157.442 209.469 159.235L218.16 176.525L222 169L229 177Z"
        fill="#DA291C"
        fill-opacity="0.08"
      />
      <path
        d="M273 167C273 167 273.061 172.179 273 173C272.879 176.069 272.75 180.92 272.75 180.92C272.5 183 271.463 188.948 269.305 188.735C267.117 188.523 265.294 187.034 264.686 184.937L257.181 159.138L247.669 204.263C247.183 206.634 245.117 208.366 242.686 208.457C242.595 208.457 242.534 208.487 242.473 208.487C240.133 208.487 238.036 206.907 237.368 204.628L229 177L222 169L225.912 160.11C226.854 158.105 228.951 156.919 231.138 157.072C233.326 157.254 235.211 158.773 235.818 160.9L241.683 181.229L251.164 136.195C251.681 133.825 253.716 132.093 256.178 132.002C258.579 131.941 260.766 133.491 261.465 135.83L271.098 168.983L273 167Z"
        fill="#DA291C"
        fill-opacity="0.08"
      />
      <g filter="url(#filter3_d)">
        <path
          d="M139.641 128.289C139.641 123.871 136.059 120.285 131.641 120.285H120.389C118.237 120.285 116.146 119.432 114.982 117.622C112.632 113.966 109.712 107.002 114.852 99.625C116.095 97.8419 118.249 97.0001 120.423 97.0001C137.443 97 164.012 97 164.012 97C164.012 97 164.012 97 164.012 97C164.012 97 183.39 97 205.86 97C208.084 97 210.297 97.8797 211.5 99.7498C215.959 106.677 213.512 113.501 211.389 117.276C210.254 119.293 208.021 120.285 205.707 120.285H172.479C168.074 120.285 164.498 123.846 164.479 128.251L164.032 232.18C164.02 235.06 162.491 237.78 159.865 238.96C153.988 241.602 149.517 241.747 143.613 238.988C141.075 237.801 139.641 235.123 139.641 232.321C139.641 192.245 139.641 167.492 139.641 128.289Z"
          fill="#DA291C"
          fill-opacity="0.9"
        />
        <path
          d="M181 173.034C181 172.07 182.29 171.623 182.966 172.31C189.25 178.697 197.75 178.697 204.034 172.31C204.71 171.623 206 172.07 206 173.034V231.909C206 234.81 204.457 237.546 201.821 238.758C195.664 241.589 191.017 241.891 184.796 238.788C182.356 237.572 181 234.959 181 232.232V173.034Z"
          fill="#DA291C"
          fill-opacity="0.9"
        />
        <ellipse
          cx="193.5"
          cy="149"
          rx="12.5"
          ry="12"
          fill="#DA291C"
          fill-opacity="0.9"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="338"
          height="338"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feMorphology
            radius="5"
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="10" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_f"
          x="94.2727"
          y="100.273"
          width="220.955"
          height="214.455"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="4.36364"
            result="effect1_foregroundBlur"
          />
        </filter>
        <filter
          id="filter2_d"
          x="52"
          y="52"
          width="248"
          height="248"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="7" dy="7" />
          <feGaussianBlur stdDeviation="10" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d"
          x="112"
          y="97"
          width="110"
          height="153"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="4" dy="5" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear"
          x1="152.429"
          y1="156.85"
          x2="268.074"
          y2="277.755"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.751984" stop-color="#3E2723" stop-opacity="0.15" />
          <stop offset="0.975942" stop-color="#3E2723" stop-opacity="0.09" />
          <stop offset="1" stop-color="#3E2723" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
