import { InfoBox } from "@react-google-maps/api";
import React from "react";
import colors from "../../../../../config/colors";

interface Props {
  otherAnnotationData?: any;
}
const MduOtherAnnotationBlocks = React.memo(
  ({ otherAnnotationData }: Props) => {
    const latLng = new google.maps.LatLng(
      otherAnnotationData?.geometry?.coordinates?.[1],
      otherAnnotationData?.geometry?.coordinates?.[0]
    );

    return (
      <div>
        <InfoBox
          position={latLng}
          options={{
            closeBoxURL: "",
            disableAutoPan: true,
            boxStyle: {
              width: "auto",
              padding: "5px",
            },
            pixelOffset: new google.maps.Size(18, -18),
          }}
        >
          <div
            style={{
              fontSize: 13,
              color: colors.greyMedium,
            }}
          >
            {otherAnnotationData?.properties?.data?.tagText?.["0"]}
          </div>
        </InfoBox>
      </div>
    );
  }
);

export default MduOtherAnnotationBlocks;
