import { PJIHistoryState } from "../../../states/account/forms/PJIHistoryState";
import {
  GET_PJI_SUCCESS,
  GET_PJI_BEGIN,
  GET_PJI_FAILURE,
  GET_PJI_RESET,
  NewPJIHistoryActionTypes,
} from "../../../types/account/forms/PJIHistoryForm";

const initialState: PJIHistoryState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function PJIHistoryReducer(
  state = initialState,
  action: NewPJIHistoryActionTypes
): PJIHistoryState {
  switch (action.type) {
    case GET_PJI_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        status: "OK",
      };
    case GET_PJI_BEGIN:
      return {
        isLoading: true,
      };
    case GET_PJI_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };
    case GET_PJI_RESET:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        data: undefined,
        status: "",
      };
    default:
      return state;
  }
}
