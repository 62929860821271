import { shallowEqual, useDispatch, useSelector } from "react-redux";
import IHPClient from "../../network/httpclient/ihp/IHPClient";
import DiagContainer from "../reusable/DiagContainer";
import ReloadData from "../reusable/ReloadData";
import AppLabels from "../../constants/App_Labels";
import { GLOBAL_CONST } from "../../constants/Global";
import CustomAccordion from "../reusable/CustomAccordion";
import { isNull } from "../reusable/Util";
import ExceptionBox from "../reusable/ExceptionBox";

const ETAData = (props: any) => {
  const labels = AppLabels();
  const { selectedDevice } = props;
  const dispatch = useDispatch();
  const { etaLoading, etaInfo, etaError } = useSelector(
    (state: any) => ({
      etaInfo: state.AccountState.IHPInsideWiringInfo.data,
      etaLoading: state.AccountState.IHPInsideWiringInfo.isLoading,
      etaError: state.AccountState.IHPInsideWiringInfo.error,
    }),
    shallowEqual
  );
  const refreshData = () => {
    if (selectedDevice?.deviceType !== "IMTA") {
      dispatch(
        IHPClient.getIHPInsideWiring(
          selectedDevice?.macaddress,
          selectedDevice?.atamac,
          GLOBAL_CONST.portNumber
        )
      );
    } else {
      dispatch(
        IHPClient.getIHPInsideWiring(
          selectedDevice.cmmac,
          selectedDevice.atamac,
          GLOBAL_CONST.portNumber
        )
      );
    }
  };

  return (
    <>
      <div className="refreshCont">
        <ReloadData refreshData={refreshData} />
      </div>
      <div className="cmSummaryCont">
        <DiagContainer
          isLoading={etaLoading}
          data={etaInfo}
          error={etaError}
          sectionName="Inside Wiring"
        >
          <CustomAccordion
            accTitle={labels.SUMMARY.toUpperCase()}
            accBodyElement={
              <table className="dataTableDetails">
                {etaInfo && etaInfo !== undefined ? (
                  <>
                    <thead>
                      <th id="nameCol">{labels.NAME.toUpperCase()}</th>
                      <th id="valueCol">{labels.VALUE.toUpperCase()}</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>HAZARDOUS POTENTIAL TEST</td>
                        <td style={{ width: "40%" }}>
                          {isNull(
                            etaInfo.HazardousPotentialTest
                              ? etaInfo.HazardousPotentialTest.split(",")?.map(
                                  (HazardousPotentialTest: any) => (
                                    <p className="noFormating">
                                      {" "}
                                      {HazardousPotentialTest}{" "}
                                    </p>
                                  )
                                )
                              : ""
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>LOOP FOREIGN EMF TEST</td>
                        <td>
                          {isNull(
                            etaInfo.LoopForeignEmfTest
                              ? etaInfo.LoopForeignEmfTest.split(",")?.map(
                                  (LoopForeignEmfTest: any) => (
                                    <p className="noFormating">
                                      {" "}
                                      {LoopForeignEmfTest}{" "}
                                    </p>
                                  )
                                )
                              : ""
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>RESISTIVE FAULTS TEST</td>
                        <td>
                          {isNull(
                            etaInfo.ResistiveFaultsTest
                              ? etaInfo.ResistiveFaultsTest.split(",")?.map(
                                  (ResistiveFaultsTest: any) => (
                                    <p className="noFormating">
                                      {" "}
                                      {ResistiveFaultsTest}{" "}
                                    </p>
                                  )
                                )
                              : ""
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>RECEIVER OFF HOOK TEST</td>
                        <td>
                          {isNull(
                            etaInfo.ReceiverOffHookTest
                              ? etaInfo.ReceiverOffHookTest.split(",")?.map(
                                  (ReceiverOffHookTest: any) => (
                                    <p className="noFormating">
                                      {" "}
                                      {ReceiverOffHookTest}{" "}
                                    </p>
                                  )
                                )
                              : ""
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>RINGER TEST</td>
                        <td>
                          {isNull(
                            etaInfo.RingerTest
                              ? etaInfo.RingerTest.split(",")?.map(
                                  (RingerTest: any) => (
                                    <p className="noFormating">
                                      {" "}
                                      {RingerTest}{" "}
                                    </p>
                                  )
                                )
                              : ""
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>PTC FAULT DETECTION</td>
                        <td>
                          {isNull(
                            etaInfo.PtcFaultDetection
                              ? etaInfo.PtcFaultDetection.split(",")?.map(
                                  (PtcFaultDetection: any) => (
                                    <p className="noFormating">
                                      {" "}
                                      {PtcFaultDetection}{" "}
                                    </p>
                                  )
                                )
                              : ""
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </>
                ) : (
                  <div className="diagErrorCont">
                    <ExceptionBox
                      headerText="No Data to display"
                      bodyText="Please try refreshing or contact the Tech Insight admin if data is missing."
                      primaryBtn={true}
                      secBtn={false}
                      handleRefresh={() => refreshData()}
                    />
                  </div>
                )}
              </table>
            }
          />
        </DiagContainer>
      </div>
    </>
  );
};

export default ETAData;
