import { conversionMapValuesToArray } from "../../components/reusable/NodeUtil";
import AppLabels from "../../constants/App_Labels";
import ConfigConst from "../../constants/ConfigConst";
import storeConst from "../../store/storeConst";

interface Props {
  setAveragesPopUp: any;
  setAvgPopUpBody: any;
  nodeTeleStatus: string;
  nodeAvgData: any;
  isFullDiagPresent: any;
}

const STBAverages = ({
  setAveragesPopUp,
  setAvgPopUpBody,
  nodeTeleStatus,
  nodeAvgData,
  isFullDiagPresent,
}: Props) => {
  const labels = AppLabels();

  const calcNodeAverages = () => {
    if (!nodeAvgData?.length) return;

    let modemCount_rdcTx = 0;
    let modemCount_fdcRx = 0;
    let modemCount_SNR = 0;

    let totalTransmit = 0;
    let totalReceive = 0;
    let totalSnr = 0;

    nodeAvgData.forEach((device: any) => {
      const { status, data } = device.deviceDiag || {};
      const { rdcPower, fdcPower, qamSnr } = data?.summary || {};

      if (status === storeConst.OK && data?.summary) {
        if (rdcPower) {
          totalTransmit += Number(rdcPower);
          modemCount_rdcTx++;
        }
        if (fdcPower) {
          totalReceive += Number(fdcPower);
          modemCount_fdcRx++;
        }
        if (qamSnr?.value) {
          totalSnr += Number(qamSnr.value);
          modemCount_SNR++;
        }
      }
    });

    const stbAveragesData = {
      rdcTx: modemCount_rdcTx
        ? (totalTransmit / modemCount_rdcTx).toFixed(2)
        : "N/A",
      fdcRx: modemCount_fdcRx
        ? (totalReceive / modemCount_fdcRx).toFixed(2)
        : "N/A",
      snr: modemCount_SNR ? (totalSnr / modemCount_SNR).toFixed(2) : "N/A",
    };

    setAvgPopUpBody(
      <div>
        <div className="avgNodeTable">
          <div className="avgOFDMA">{labels.AVERAGES?.toUpperCase()}</div>
          <div className="dataTableDetails avgNodeTable">
            <table>
              <thead>
                <tr>
                  <th>{labels.RDC_Tx}</th>
                  <th>{labels.FDC_Rx}</th>
                  <th>{labels.DS_SCQAMSNR}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{stbAveragesData.rdcTx}</td>
                  <td>{stbAveragesData.fdcRx}</td>
                  <td>{stbAveragesData.snr}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <button
        className="custDDBtn"
        style={{ fontWeight: 600 }}
        disabled={
          !(
            (nodeTeleStatus === ConfigConst.complete.toLowerCase())
            // && isFullDiagPresent
          )
        }
        onClick={() => {
          setAveragesPopUp(true);
          calcNodeAverages();
        }}
      >
        {labels.AVERAGES?.toUpperCase()}
      </button>
    </div>
  );
};

export default STBAverages;
