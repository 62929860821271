import GoogleMap from "google-map-react";
import { shallowEqual, useSelector } from "react-redux";
import ExceptionBox from "../reusable/ExceptionBox";
import { checkCMStatus } from "../reusable/Util";
import TapMarker from "../tapView/TapMarker";
import NeighMapMarker from "./NeighMapMarker";

const NeighbourhoodMapView = (props: any) => {
  const { neighboursDevices, gKey, TapListData } = useSelector(
    (state: any) => ({
      neighboursDevices:
        state.AccountState.acctSlice.neighbourhood.neighDataList,
      gKey: state.slices.topologyState.configs.data?.googleMapKey,
      TapListData: state.AccountState.TapListData.data,
    }),
    shallowEqual
  );
  let tapList: any = [];
  const neighMapOptions = (maps: any) => {
    return {
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: true,
      fullscreenControl: false,
    };
  };
  const drawConLines = (
    google: any,
    source: any,
    destination: any,
    connStatus: string
  ) => {
    var flightPath = new google.maps.Polyline({
      path: [source, destination],
      geodesic: true,
      strokeColor:
        connStatus === "pass"
          ? "#00985f"
          : connStatus === "fail"
          ? "#dc3545"
          : "#808284",
      strokeOpacity: 1,
      strokeWeight: 2,
    });
    flightPath.setMap(google.map);
  };
  const handleGoogleMapApi = (google: any) => {
    if (
      neighboursDevices &&
      neighboursDevices?.lenght !== 0 &&
      TapListData?.data &&
      TapListData?.data?.length !== 0
    ) {
      neighboursDevices?.map((n: any) => {
        if (
          TapListData?.data?.hasOwnProperty(n?.cmMac) &&
          TapListData?.data[n?.cmMac]
        ) {
          let source = {
            lat: parseFloat(TapListData?.data[n?.cmMac]?.lat),
            lng: parseFloat(TapListData?.data[n?.cmMac]?.lon),
          };
          let dest = n?.location;
          let connStatus = checkCMStatus(n);
          drawConLines(google, source, dest, connStatus);
        }
      });
    }
  };
  return (
    <>
      {gKey !== "" ? (
        <div className="neighMapContainer">
          <GoogleMap
            bootstrapURLKeys={{ key: gKey }}
            defaultCenter={
              neighboursDevices?.[0]?.location
                ? neighboursDevices?.[0]?.location
                : { lat: 43.65189, lng: -79.38171 }
            }
            defaultZoom={neighboursDevices?.[0]?.location ? 18 : 5}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) =>
              handleGoogleMapApi({ map, maps })
            }
            options={neighMapOptions}
          >
            {neighboursDevices?.map((neigh: any, i: number) => {
              if (neigh?.location !== undefined && neigh?.location !== "") {
                return (
                  <NeighMapMarker
                    lat={neigh?.location?.lat}
                    lng={neigh?.location?.lng}
                    address={neigh?.homeAddress}
                    isCustomer={i === 0 ? true : false}
                    neighData={neigh}
                  />
                );
              }
            })}
            {TapListData?.data &&
              TapListData?.data?.length !== 0 &&
              neighboursDevices?.map((n: any) => {
                if (
                  TapListData?.data?.hasOwnProperty(n?.cmMac) &&
                  TapListData?.data[n?.cmMac] &&
                  tapList?.indexOf(TapListData?.data[n?.cmMac]?.tap) === -1
                ) {
                  tapList = [...tapList, ...[TapListData?.data[n?.cmMac]?.tap]];
                  return (
                    <TapMarker
                      lat={TapListData?.data[n?.cmMac]?.lat}
                      lng={TapListData?.data[n?.cmMac]?.lon}
                      tapDetails={TapListData?.data[n?.cmMac]}
                    />
                  );
                }
              })}
          </GoogleMap>
        </div>
      ) : (
        <div className="diagErrorCont">
          <ExceptionBox
            headerText="Sorry!! We are enable to display map view."
            bodyText={`Google map key is not available.`}
            primaryBtn={false}
            secBtn={false}
          />
        </div>
      )}
    </>
  );
};

export default NeighbourhoodMapView;
