import AppLabels from "../../constants/App_Labels";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import "../../design-tokens/account-page/FailedAttributes.scss";
import { formatCamelCase, isNull } from "../reusable/Util";
const FailedAttributesOnt = (props: any) => {
  const { ontSummaryInfo } = props;

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#ffffff",
      color: "#000000",
      boxShadow: theme.shadows[4],
      fontSize: 12,
    },
  }))(Tooltip);
  const labels = AppLabels();
  const arr = [];
  const data = ontSummaryInfo;
  if (data) {
    const summaryArray = [];
    const opticalTx = data?.opticalTx;
    const opticalRx = data?.opticalRx;
    const operStatus = data?.operStatus;
    const provStatus = data?.provStatus;
    if (opticalTx?.status == "Fail") {
      summaryArray.push({
        name: formatCamelCase("opticalTx"),
        value: isNull(opticalTx?.value),
      });
    }
    if (opticalRx?.status == "Fail") {
      summaryArray.push({
        name: formatCamelCase("opticalRx"),
        value: isNull(opticalRx?.value),
      });
    }
    if (operStatus?.status == "Fail") {
      summaryArray.push({
        name: formatCamelCase("operStatus"),
        value: isNull(operStatus?.value),
      });
    }
    if (provStatus?.status == "Fail") {
      summaryArray.push({
        name: formatCamelCase("provStatus"),
        value: isNull(provStatus?.value),
      });
    }
    if (summaryArray?.length > 0) {
      arr.push({
        name: "ONT_SUMMARY",
        value: summaryArray,
        labels: [labels.NAME.toUpperCase(), labels.VALUE.toUpperCase()],
      });
    }
  }

  return (
    <div className="faCmGW">
      <p>
        <span style={{ fontWeight: 600 }}>{labels.failedAttributes?.toUpperCase()} : </span>
        {arr ? (
          arr.map((el: any, index: any) => {
            return (
              <LightTooltip
                title={
                  <table className="tableTypeTwo">
                    <thead>
                      <tr>
                        <th>{labels.Name?.toUpperCase()}</th>
                        <th>{labels.VALUE?.toUpperCase()}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {el?.value?.map((v: any, i: any) => {
                        return (
                          <tr key={i}>
                            <td>{v?.name}</td> <td>{v?.value}</td>{" "}
                          </tr>
                        );
                      })}{" "}
                    </tbody>
                  </table>
                }
              >
                <span className="tooltipText" style={{ fontWeight: 500 }}>
                  {el?.name},{" "}
                </span>
              </LightTooltip>
            );
          })
        ) : (
          <></>
        )}
      </p>
    </div>
  );
};

export default FailedAttributesOnt;
