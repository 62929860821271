import { createSlice } from "@reduxjs/toolkit";

import { getUniqueMapData } from "../../../util/utilFuncs";

const slice = createSlice({
  name: "mapDetails",
  initialState: {
    mapIsLoading: false,
    inProgressApiCalls: 0,
    mapCenter: undefined,
    highlightedPolygon: {
      type: "FeatureCollection",
      features: [],
    },
    mapFilters: undefined,
    mapData: undefined,
    activeMapElement: undefined,
    searchedAddress: undefined,
    searchedElement: undefined,
    isNodeSiteFocused: true,
    showChart: false,
    rightClickMapElement: undefined,
    showRightClickMenu: false,
  },
  reducers: {
    incrApiCall: (mapDetails) => {
      mapDetails.inProgressApiCalls++;
      mapDetails.mapIsLoading = true;
    },
    decrApiCall: (mapDetails) => {
      mapDetails.inProgressApiCalls--;
      if (mapDetails.inProgressApiCalls === 0) mapDetails.mapIsLoading = false;
    },
    setMapCenter: (mapDetails, action) => {
      mapDetails.mapCenter = action.payload;
    },
    setHighlightedPolygon: (mapDetails, action) => {
      mapDetails.highlightedPolygon = action.payload;
    },
    setMapFilters: (mapDetails, action) => {
      mapDetails.mapFilters = action.payload;
    },
    setMapData: (mapDetails, action) => {
      let newMapData: any = action.payload;
      let currMapData: any;
      if (mapDetails?.mapData !== undefined) {
        currMapData = JSON.parse(JSON.stringify(mapDetails?.mapData));
      }
      let combinedMapData = getUniqueMapData(currMapData, newMapData);
      mapDetails.mapData = combinedMapData;
    },
    setSelectedMapElement: (mapDetails, action) => {
      mapDetails.activeMapElement = action.payload;
    },
    setSearchedAddress: (mapDetails, action) => {
      mapDetails.searchedAddress = action.payload;
    },
    setSearchedElement: (mapDetails, action) => {
      mapDetails.searchedElement = action.payload;
    },
    setNodeSiteFocus: (mapDetails, action) => {
      mapDetails.isNodeSiteFocused = action.payload;
    },
    setShowChart: (mapDetails, action) => {
      mapDetails.showChart = action.payload;
    },
    setRightClickMapElement: (mapDetails, action) => {
      mapDetails.rightClickMapElement = action.payload;
    },
    setShowRightClickMenu: (mapDetails, action) => {
      mapDetails.showRightClickMenu = action.payload;
    },
  },
});

export const {
  incrApiCall,
  decrApiCall,
  setMapCenter,
  setHighlightedPolygon,
  setMapFilters,
  setMapData,
  setSelectedMapElement,
  setSearchedAddress,
  setSearchedElement,
  setNodeSiteFocus,
  setShowChart,
  setRightClickMapElement,
  setShowRightClickMenu,
} = slice.actions;
export default slice.reducer;
