

import AppLabels from "../../../constants/App_Labels";

export default function EquipmentMenu() {
  const labels = AppLabels();
  return [
    {
      label: labels.STB,
      active: false,
      key: "STB",
    },
    {
      label: labels.PAID_POD,
      active: false,
      key: "Paid Pod",
    },
    {
      label: labels.FREE_POD,
      active: false,
      key: "Free Pod",
    },
   
  ];
}