import { NodeModemsListState } from "../../../states/node/telemetry/NodeModemListState";
import {
  NODE_MODEMS_LIST_BEGIN,
  NODE_MODEMS_LIST_FAILURE,
  NODE_MODEMS_LIST_SUCCESS,
  NodeModemsListActionTypes,
} from "../../../types/node/telemetry/NodeModemsList";

const initialState: NodeModemsListState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function NodeModemsListReducer(
  state = initialState,
  action: NodeModemsListActionTypes
): NodeModemsListState {
  switch (action.type) {
    case NODE_MODEMS_LIST_BEGIN:
      return {
        isLoading: true,
      };
    case NODE_MODEMS_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case NODE_MODEMS_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}
