import {
    HARD_ZAP_BEGIN,
    HARD_ZAP_SUCCESS,
    HARD_ZAP_FAILURE,
    HARD_ZAP_RESET_STATUS,
    HardZapActionTypes,
  } from "../../../types/account/deviceActions/HardZap";
  
  export function hardzapBegin(): HardZapActionTypes {
    return {
      type: HARD_ZAP_BEGIN,
    };
  }
  
  export function hardzapSuccess(): HardZapActionTypes {
    return {
      type: HARD_ZAP_SUCCESS,
    };
  }
  
  export function hardzapFailure(error: Error): HardZapActionTypes {
    return {
      type: HARD_ZAP_FAILURE,
      payload: error,
    };
  }
  export function resetHardZap(): HardZapActionTypes {
    return {
      type: HARD_ZAP_RESET_STATUS,
    };
  }
  