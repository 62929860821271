import { Card, Modal } from "react-bootstrap";
import AppLabels from "../../constants/App_Labels";
import { IoClose } from "react-icons/io5";
import { Divider } from "@material-ui/core";
import Node from "../../resources/images/icons/node_black.svg";
import Smt from "../../resources/images/icons/SMT.svg";
import { isAccAvailable, isNull, isNodeAvailable } from "../reusable/Util";

const SearchBoxMiniNode = (props: any) => {
  const { show, handleDGClose, nodedata, openNodePage } = props;

  const labels = AppLabels();

  return (
    <Modal
      show={show}
      onHide={handleDGClose}
      backdrop="static"
      keyboard={false}
      centered
      id="sAPopUp"
    >
      <Modal.Header className="searchPopHead">
        <header>{labels.SEARCH_RESULTS}</header>
        <button className="sPopUpClose" onClick={handleDGClose}>
          <IoClose color="white" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="SearchResultCont">
          {nodedata.map((node: any, i: number) => {
            return (
              <Card
                key={i}
                className="resultCard"
                onClick={() => openNodePage(node)}
              >
                {node?.smt ? (
                  <>
                    <span className="resultCardIcon">
                      <img src={Smt} alt="accIcon" />
                    </span>
                    <span className="resultCardDetails">
                      <h5>
                        {labels.SMT_ID.toUpperCase()}:&nbsp;
                        <span>{isNull(node?.smtId)}</span>
                      </h5>
                      <h5>
                        {labels.NODE_ID.toUpperCase()}:&nbsp;
                        <span>{isNull(node?.nodeId)}</span>
                      </h5>

                      <h5>
                        {labels.PHUB.toUpperCase()}:&nbsp;
                        <span>{isNull(node?.phub)}</span>
                        {node?.vhub === "true" && (
                          <span className="vhub">VHUB</span>
                        )}
                        {node?.nodeType === "R-PHY" && (
                          <span className="vhub">RPHY</span>
                        )}
                      </h5>
                    </span>
                  </>
                ) : (
                  <>
                    <span className="resultCardIcon">
                      <img src={Node} alt="accIcon" />
                    </span>
                    <span className="resultCardDetails">
                      <h5>{isNull(node?.nodeId)}</h5>

                      <h5>
                        {labels.PHUB.toUpperCase()}:&nbsp;
                        <span>{isNull(node?.phub)}</span>
                        {node?.vhub === "true" && (
                          <span className="vhub">VHUB</span>
                        )}
                        {node?.nodeType === "R-PHY" && (
                          <span className="vhub">RPHY</span>
                        )}
                      </h5>
                    </span>
                  </>
                )}
              </Card>
            );
          })}
        </div>
      </Modal.Body>
      <Divider orientation="horizontal" />
      <Modal.Footer>
        <button className="sPopCloseBtn" onClick={handleDGClose}>
          {labels.CLOSE_BTN_TEXT}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
export default SearchBoxMiniNode;
