import { useEffect, useState } from "react";
import "../../design-tokens/telemetry/NodeTelemetryTab.scss";
import ConfirmPopUp from "../reusable/ConfirmPopUp";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import "../../design-tokens/telemetry/NodeTelemetryTab.scss";
import CustomPopUp from "../reusable/CustomPopUp";
import NodeModemsClient from "../../network/httpclient/telemetry/NodeModemsClient";
import { Divider } from "@material-ui/core";
import CertiExceptionCont from "../certification/CertiExceptionCont";
import AppLabels from "../../constants/App_Labels";
import LoaderGif from "../../resources/images/loader.webp";
import { checkFlaps, getNodeTeleGloabalStatus } from "../reusable/NodeUtil";
import {
  GetClearFlapsInfo,
  GetTelemetryDetails,
  GetNodeInfoDetails,
} from "../../get-api/GetResponse";
import ConfigConst from "../../constants/ConfigConst";
import { GetTelemetryProps } from "../../topology/util/reduxFunctions/getTopologyState";

const ClearFlaps = (props: any) => {
  const dispatch = useDispatch();
  const labels = AppLabels();
  const [showConfirmPopUp, setShowConfirmPopUp] = useState(false);
  const [showInformPopUp, setShowInformPopUp] = useState(false);
  const [popUpBody, setPopUpBody] = useState(<div></div>);
  const [popUpHeader, setPopUpHeader] = useState(labels.CLEAR_FLAPS_DETAILS);
  const {
    clearFlapsData,
    clearFlapsIsLoading,
    clearFlapsError,
    clearFlapsStatus,
  } = GetClearFlapsInfo();

  const nodeInfo = GetNodeInfoDetails().currNodedata;
  const { mlData, allDiagIsLoading, allDiagStatus, isAborted } =
    GetTelemetryProps();
  const nodeTeleStatus = getNodeTeleGloabalStatus(
    allDiagStatus,
    allDiagIsLoading,
    isAborted
  );

  useEffect(() => {
    if (clearFlapsStatus !== "") {
      if (clearFlapsStatus === labels.OK) {
        handleClearFlapsInfo(); // Set up the successful result pop up
      } else if (clearFlapsStatus === labels.ERROR) {
        setPopUpHeader(labels.ERROR.toUpperCase());
        setPopUpBody(
          <CertiExceptionCont
            dataObj={clearFlapsError}
            sectionName="CM Diagnostics"
          />
        );
      }
    }
  }, [clearFlapsStatus]);

  useEffect(() => {
    if (clearFlapsIsLoading) {
      setPopUpBody(
        <img className="nodeTeleDataLoader" alt="Loader Gif" src={LoaderGif} />
      );
    }
  }, [clearFlapsIsLoading]);

  const handleConfirm = () => {
    setShowConfirmPopUp(false);
    dispatchFlapsCall();
  };

  const dispatchFlapsCall = () => {
    let macListString: Array<string> = checkFlaps(mlData);
    if (macListString?.length > 0) {
      dispatch(
        NodeModemsClient.clearFlapsbyMacList(
          macListString?.toString(),
          nodeInfo?.nodeId ?? mlData[0]?.returnSegment,
          props?.deviceType
        )
      );
    } else {
      setPopUpBody(
        <span className="telemertyinfoBox">{labels.NO_FLAPS_FOUND}</span>
      );
    }
    setShowInformPopUp(true);
  };

  const handleClearFlapsInfo = () => {
    let failures = 0;
    let totalFlaps = 0;
    let modems;
    if (clearFlapsData !== undefined && clearFlapsData?.length > 0) {
      let modems = clearFlapsData?.map((d: any, index: number) => {
        if (d?.status?.includes("failed")) failures += 1;
      });
      totalFlaps = modems?.length;
    }

    let clearedFlapsNumber = totalFlaps - failures;
    setPopUpBody(
      <>
        <span className="telemertyinfoBox top">
          {failures > 0 ? (
            labels.FLAPS_CLEARED_PARTIALLY
          ) : props && props?.selectedMac ? (
            <>
              <p>{labels.FLAPS_CLEARED_SUCCESS}</p>
              <p>{labels.FLAPS_CLEARED_NEIGH_SUCCESS}</p>
            </>
          ) : (
            labels.FLAPS_CLEARED_SUCCESS
          )}
        </span>
        <div>
          <p className="telemertyinfoBoxDiv" id="totals">
            <span className="telemertyinfoBox">{labels.TOTAL_FLAPS}: </span>
            <span className="telemertyinfoBoxSpan">{totalFlaps}</span>
            <span className="telemertyinfoBox">{labels.CLEARED_FLAPS}: </span>
            <span className="telemertyinfoBoxSpan">{clearedFlapsNumber}</span>
          </p>
        </div>
        <Divider orientation="horizontal" />
        <div className="responseHead">
          <span className="telemertyinfoBox head">
            {labels.MAC_ADDRESS.toUpperCase()}
          </span>
          <span className="telemertyinfoBox">
            {labels.STATUS.toUpperCase()}
          </span>
        </div>
        <div className="clearFlapsResponseJson">
          {clearFlapsData?.map((modem: any, index: number) => {
            return (
              <div
                key={index}
                className={
                  modem?.status?.includes("failed")
                    ? "telemertyinfoBoxDiv fail flaps"
                    : "telemertyinfoBoxDiv flaps"
                }
              >
                <span className="telemertyinfoBox">{modem?.macAddress}</span>
                <span className="telemertyinfoBoxSpan flaps">
                  {modem?.status}
                </span>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <>
      <ConfirmPopUp
        showVal={showConfirmPopUp}
        headerText={labels.WARNING}
        bodyHeadText={labels.ABOUT_TO_CLEAR_FLAP_DATA}
        bodyText={labels.WANT_TO_CLEAR_FLAP_DATA}
        cancelText={labels.CANCEL}
        confirmText={labels.PROCEED}
        handleConfirm={() => handleConfirm()}
        handleClose={() => setShowConfirmPopUp(false)}
      />
      <button
        className="telemetryClearFlapsBtn"
        id="clearFlapsButtonId"
        onClick={() => {
          setShowConfirmPopUp(true);
        }}
        disabled={
          clearFlapsIsLoading ||
          allDiagIsLoading ||
          nodeTeleStatus !== ConfigConst.complete.toLowerCase()
        }
      >
        {labels.CLEAR_FLAPS.toUpperCase()}
      </button>
      <CustomPopUp
        showVal={showInformPopUp}
        headerText={popUpHeader}
        bodyElement={popUpBody}
        handleClose={() => setShowInformPopUp(false)}
      />
    </>
  );
};

export default ClearFlaps;
