import {
  ActiveDiagDevicesActionTypes,
  RESET_ACTIVE_DIAG_FAILURE,
  SAVE_ACTIVE_DIAG_DEVICES,
  UPDATE_ACTIVE_DIAG_DEVICES,
  DECREMENT_DIAG_API_CALL_COUNTER,
  INCREMENT_DIAG_API_CALL_COUNTER,
  START_LOADING_DIAG_DEVICES,
} from "../../../types/account/diagnostics/ActiveDiagDevices";

export function StartLoadingDiagDevices(): ActiveDiagDevicesActionTypes {
  return {
    type: START_LOADING_DIAG_DEVICES,
  };
}

export function SaveActiveDiagDevices(
  devices: any
): ActiveDiagDevicesActionTypes {
  return {
    type: SAVE_ACTIVE_DIAG_DEVICES,
    payload: devices,
  };
}

export function UpdateActiveDiagDevices(
  devicekey: string,
  subdataKey: string,
  data: any
): ActiveDiagDevicesActionTypes {
  return {
    type: UPDATE_ACTIVE_DIAG_DEVICES,
    devicekey: devicekey,
    subdataKey: subdataKey,
    data: data,
  };
}

export function IncrementDiagApiCallCounter(
  deviceKey: string
): ActiveDiagDevicesActionTypes {
  return {
    type: INCREMENT_DIAG_API_CALL_COUNTER,
    devicekey: deviceKey,
  };
}
export function DecrementDiagApiCallCounter(
  deviceKey: string
): ActiveDiagDevicesActionTypes {
  return {
    type: DECREMENT_DIAG_API_CALL_COUNTER,
    devicekey: deviceKey,
  };
}

export function ResetActiveDiagDevices(): ActiveDiagDevicesActionTypes {
  return {
    type: RESET_ACTIVE_DIAG_FAILURE,
  };
}
