import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../middleware/apiActions";
import { GPON_USAGES_URI } from "../../../middleware/apiEndpoints";
import { StandardReduxType } from "../../../reduxTypes";
import { decrApiCall, incrApiCall } from "../../mapItems/mapDetails";

let currUsagesIndex = -1;

const slice = createSlice({
  name: "gponUsages",
  initialState: {
    allData: [] as StandardReduxType[],
  },
  reducers: {
    usagesBegin: (s, { payload }) => {
      currUsagesIndex = s.allData.findIndex(
        (sMap) => sMap?.data?.lcpNetworkId === payload.lcpNetworkId
      );
      const objStructure = {
        isLoading: true,
        error: undefined,
        status: "",
        data: { lcpNetworkId: payload.lcpNetworkId },
      } as StandardReduxType;

      if (currUsagesIndex === -1) {
        s.allData.push(objStructure);
        currUsagesIndex = s.allData.length - 1; //set current index
      } else s.allData[currUsagesIndex] = objStructure;
    },
    usagesSuccess: (s, { payload }) => {
      s.allData[currUsagesIndex] = {
        isLoading: false,
        error: undefined,
        status: "OK",
        data: {
          lcpNetworkId: payload.lcpNetworkId,
          payload: payload.data,
        },
      };
    },
    usagesFailure: (s, { payload }) => {
      s.allData[currUsagesIndex] = {
        isLoading: false,
        error: payload.data,
        status: "ERROR",
        data: {
          lcpNetworkId: payload.lcpNetworkId,
        },
      };
    },
  },
});

const { usagesBegin, usagesSuccess, usagesFailure } = slice.actions;
export default slice.reducer;

interface Props {
  lcpNetworkId: string;
}
export const getGponUsages =
  ({ lcpNetworkId }: Props) =>
  (dispatch: any) => {
    dispatch(
      apiCallBegan({
        url: GPON_USAGES_URI(lcpNetworkId),
        onStart: usagesBegin.type,
        onSuccess: usagesSuccess.type,
        onError: usagesFailure.type,
        onStartDispatch: incrApiCall.type,
        onSuccessDispatch: decrApiCall.type,
        onErrorDispatch: decrApiCall.type,
        props: { lcpNetworkId },
      })
    );
  };
