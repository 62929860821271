import { AppBar, Box, Tab, Tabs } from "@material-ui/core";
import { useEffect, useState, useCallback, useRef } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useDispatch } from "react-redux";

import "../../design-tokens/history-page/HistoryPage.scss";
import { GetTechInfo } from "../../get-api/GetResponse";
import TECHClient from "../../network/httpclient/technician/TECHClient";
import CertificationsHistoryTab from "../history-page/CertificationsHistoryTab";
import FormsHistoryTab from "../history-page/FormsHistoryTab";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const HistoryPage = (props: any) => {
  // const techData = GetTechInfo()?.data;
  // const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  // const profileRef = useRef(false);

  // Already called in the App.tsx
  // const profileApi = useCallback(() => {
  //   if (techData === undefined && !profileRef.current) {
  //     dispatch(TECHClient.getTechniciansProfile());
  //     profileRef.current = true;
  //   }
  // }, [dispatch, techData]);

  // useEffect(() => {
  //   profileApi();
  // }, [profileApi]);

  return (
    <div>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label={"Certifications".toUpperCase()} {...a11yProps(0)} />
          <Tab label={"Forms".toUpperCase()} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <CertificationsHistoryTab />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FormsHistoryTab />
      </TabPanel>
    </div>
  );
};
export default HistoryPage;
