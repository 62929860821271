import {
  getOFDMBaselineRatios,
  getOFDMcorrectedDelta,
  getOFDMTotalCodewordsDelta,
  getOFDMUncorrectablesDelta,
  isNull,
} from "../reusable/Util";

import CustomAccordion from "../reusable/CustomAccordion";
import CustomPopOver from "../reusable/CustomPopOver";
import { shallowEqual, useSelector } from "react-redux";
const ViewCwOFDMDownStream = (props: any) => {
  const { codewords } = props;
  let arr: { firstData: any; lastData: any }[] = [];
  codewords?.map((e: any, i: number) => {
    if (e?.name === "OFDM_CHANNELS") {
      arr.push(e);
    }
  });
  const { tracer } = useSelector(
    (state: any) => ({
      tracer: state.AccountState.cwMonitorInfo.tracer,
    }),
    shallowEqual
  );
  return (
    <>
      {arr !== undefined && arr?.length !== 0 ? (
        <CustomAccordion
          accTitle={"OFDM Channels".toUpperCase()}
          accBodyElement={
            <>
              <table className="dataTableDetails">
                <thead>
                  <tr>
                    <th>{"channel Id".toUpperCase()}</th>
                    <CustomPopOver
                      position="top"
                      popOverData="Modulation Profile"
                      originComp={<th>{"profile Id".toUpperCase()}</th>}
                      index="popOver"
                    />
                    <CustomPopOver
                      position="top"
                      popOverData="Total uncorrectable codewords/total codewords prior to the current polling session"
                      originComp={<th>{"Baseline Errors".toUpperCase()}</th>}
                      index="popOver"
                    />
                    <CustomPopOver
                      position="top"
                      popOverData="Count of errored codewords that have been corrected during the interval"
                      originComp={<th>{"corrected delta".toUpperCase()}</th>}
                      index="popOver"
                    />
                    <CustomPopOver
                      position="top"
                      popOverData="Count of errored codewords that were not corrected during the interval"
                      originComp={<th>{"uncorrectables delta".toUpperCase()}</th>}
                      index="popOver"
                    />
                    <CustomPopOver
                      position="top"
                      popOverData="Count of all codewords received during the interval"
                      originComp={<th>{"total delta".toUpperCase()}</th>}
                      index="popOver"
                    />
                  </tr>
                </thead>
                {arr?.[0]?.lastData?.ofdmDsChannels?.map(
                  (ch: any, index: number) => {
                    return (
                      <tbody key={index}>
                        {ch?.profiles !== undefined &&
                          ch?.profiles?.length !== 0 ? (
                          ch?.profiles?.map((p: any, i: number) => {
                            return (
                              <tr key={i}>
                                {i === 0 ? (
                                  <td rowSpan={ch?.profiles?.length}>
                                    {isNull(ch?.channelId)}
                                  </td>
                                ) : (
                                  <></>
                                )}
                                <td>
                                  [{isNull(p?.profileId)}]
                                  {isNull(p?.modulation)}
                                </td>
                                <td>
                                  {isNull(
                                    getOFDMBaselineRatios(
                                      p?.uncorrectableCodewords,
                                      p?.totalCodewords
                                    )
                                  )}
                                </td>
                                <td>
                                  {isNull(
                                    getOFDMcorrectedDelta(
                                      arr?.[0]?.firstData?.ofdmDsChannels?.[
                                      index
                                      ],
                                      tracer ? 20 : 60,
                                      p,
                                      "OFDM"
                                    )
                                  )}
                                </td>
                                <td>
                                  {isNull(
                                    getOFDMUncorrectablesDelta(
                                      arr?.[0]?.firstData?.ofdmDsChannels?.[
                                      index
                                      ],
                                      tracer ? 20 : 60,
                                      p,
                                      "OFDM"
                                    )
                                  )}
                                </td>
                                <td>
                                  {isNull(
                                    getOFDMTotalCodewordsDelta(
                                      arr?.[0]?.firstData?.ofdmDsChannels?.[
                                      index
                                      ],
                                      tracer ? 20 : 60,
                                      p,
                                      "OFDM"
                                    )
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </tbody>
                    );
                  }
                )}
              </table>
            </>
          }
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ViewCwOFDMDownStream;
