import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../middleware/apiActions";
import { MDU_POLYLINES } from "../../../middleware/apiEndpoints";
import storeConst from "../../../storeConst";
import { decrApiCall, incrApiCall } from "../mapDetails";

const slice = createSlice({
  name: "mduPolylines",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    getMduPolylinesBegin: (mduPolylines) => {
      mduPolylines.isLoading = true;
      mduPolylines.status = "";
    },
    getMduPolylinesSuccess: (mduPolylines, action) => {
      mduPolylines.isLoading = false;
      mduPolylines.data = action.payload;
      mduPolylines.status = storeConst.OK;
    },
    getMduPolylinesFailure: (mduPolylines, action) => {
      mduPolylines.isLoading = false;
      mduPolylines.error = action.payload;
      mduPolylines.data = undefined;
      mduPolylines.status = storeConst.ERROR;
    },
  },
});

const { getMduPolylinesBegin, getMduPolylinesSuccess, getMduPolylinesFailure } =
  slice.actions;
export default slice.reducer;

export const getMduPolylines = (owningDrawing: any) => (dispatch: any) => {
  dispatch(
    apiCallBegan({
      url: `${MDU_POLYLINES}/${owningDrawing}`,
      method: "get",
      onStart: getMduPolylinesBegin.type,
      onSuccess: getMduPolylinesSuccess.type,
      onError: getMduPolylinesFailure.type,
      onStartDispatch: incrApiCall.type,
      onSuccessDispatch: decrApiCall.type,
      onErrorDispatch: decrApiCall.type,
    })
  );
};
