import { ThunkAction } from "redux-thunk";
import { Action } from "redux";

import { HttpClient } from "../HttpClient";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { NetworkConfig } from "../NetworkConfig";
import { NodeSummary } from "../../../store/models/account/node/NodeSummary";
import { NodesState } from "../../../store/states/account/node/NodesState";
import {
  NodesInfoBegin,
  NodesInfoFailure,
  NodessInfoSuccess,
} from "../../../store/actions/account/node/NodesAction";
import { NodesInfo } from "../../../store/models/account/node/NodesInfo";
import {
  DecrementNodeApiCallCounter,
  IncrementNodeApiCallCounter,
  SetNodeGeoCode,
  UpdateNodeDevices,
} from "../../../store/actions/account/node/NodeSummaryAction";

export default class NodeClient {
  static getNodesInfo =
    (
      node: string,
      accType: string
    ): ThunkAction<void, NodesState, unknown, Action<string>> =>
    async (dispatch) => {
      let deviceType = accType === "Maestro" ? "Maestro_CM" : "ALL_CM";

      let httpParameters: IHttpClientRequestParameters<any> = {
        // url: NetworkConfig.ACCOUNT_SERVICE_URL + "area/gpon/node/" + node,
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "area/gpon/" + node,
        params: { deviceType: deviceType },
      };
      dispatch(NodesInfoBegin());
      HttpClient.get<NodesInfo>(httpParameters)
        .then((NodeInfo) => {
          dispatch(NodessInfoSuccess(NodeInfo));
          return NodeInfo;
        })
        .catch((error) => {
          dispatch(NodesInfoFailure(error));
          return error;
        });
    };

  static getNodeGeoCode =
    (
      serialNum: string,
      address: string
    ): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "google/geocode",
        params: {
          address,
        },
      };

      HttpClient.get<any>(httpParameters)
        .then((geoData) => {
          dispatch(SetNodeGeoCode(serialNum, geoData));
          return geoData;
        })
        .catch((error) => {
          return error;
        });
    };

  static getNodeSummary =
    (serialNum: string): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "diag/ont/" +
          serialNum +
          "/summary",
      };

      dispatch(IncrementNodeApiCallCounter(serialNum, "summary"));

      HttpClient.get<NodeSummary>(httpParameters)
        .then((nodeSummary) => {
          dispatch(UpdateNodeDevices(serialNum, "summary", nodeSummary, "OK"));
          dispatch(DecrementNodeApiCallCounter(serialNum, "summary"));
          return nodeSummary;
        })
        .catch((error) => {
          dispatch(UpdateNodeDevices(serialNum, "summary", error, "ERROR"));
          dispatch(DecrementNodeApiCallCounter(serialNum, "summary"));
          return error;
        });
    };
}
