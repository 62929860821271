import appConst from "../constants/appConst";

export const DATA_TABLE_CONFIG = [
  {
    name: "nodeBoundary",
    title: "Polygon",
    headers: ["name", "value"],
    dataKeys: {
      id: "id",
      jobId: "jobId",
      polygonName: "polygonName",
    },
  },
  {
    name: appConst.usScQAM,
    dataKeys: {
      frequency: "frequency",
      snr: "snr",
      status: "status",
      txPower: "txPower",
    },
  },
  {
    name: appConst.dsScQAM,
    dataKeys: {
      frequency: "frequency",
      snr: "snr",
      status: "status",
      rxPower: "rx_power",
    },
  },
  {
    name: appConst.ofdma,
    dataKeys: {
      name: "name",
      "2pct": "mer.thrVal",
      status: "status",
      txPower: "txPower",
    },
  },
  {
    name: appConst.ofdm,
    dataKeys: {
      name: "name",
      "2pct": "mer.thrVal",
      status: "status",
      minRxPower: "minRxPower",
      maxRxPower: "maxRxPower",
      averageRxPower: "averageRxPower",
    },
  },
];
