import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";

import { FeedbackIssuesState } from "../../../store/states/feedback/FeedbackIssuesState";
import {
  feedbackProfileBegin,
  feedbackProfileFailure,
  feedbackProfileSuccess,
} from "../../../store/actions/feedback/FeedbackProfileAction";

import { FeedbackProfile } from "../../../store/models/feedback/FeedbackProfile";

import { TechnicianIssuesState } from "../../../store/states/feedback/TechnicianIssuesState";
import {
  technicianFeedbackBegin,
  technicianFeedbackFailure,
  technicianFeedbackSuccess,
} from "../../../store/actions/feedback/FeedbackProfileAction";

import { FeedbackCommentsState } from "../../../store/states/feedback/FeedbackCommentsState";
import {
  feedbackCommentsBegin,
  feedbackCommentsFailure,
  feedbackCommentsSuccess,
} from "../../../store/actions/feedback/FeedbackCommentAction";
import { FeedbackComments } from "../../../store/models/feedback/FeedbackComments";

import { NewFeedbackState } from "../../../store/states/feedback/NewFeedbackState";
import {
  SaveNewFeedbackFailure,
  SaveNewFeedbackBegin,
  SaveNewFeedbackSuccess,
} from "../../../store/actions/feedback/FeedbackCreationAction";

import { NewCommentState } from "../../../store/states/feedback/NewCommentState";
import {
  SaveNewCommentFailure,
  SaveNewCommentBegin,
  SaveNewCommentSuccess,
} from "../../../store/actions/feedback/CommentCreationAction";

import { AttachmentState } from "../../../store/states/feedback/AttachmentState";
import {
  AttachmentFailure,
  AttachmentBegin,
  AttachmentSuccess,
} from "../../../store/actions/feedback/AttachmentAction";

import { FeedbackUpdateState } from "../../../store/states/feedback/FeedbackUpdateState";
import {
  UpdateFeedbackFailure,
  UpdateFeedbackBegin,
  UpdateFeedbackSuccess,
} from "../../../store/actions/feedback/FeedbackUpdateAction";

import { IndividualIssueState } from "../../../store/states/feedback/IndividualIssueState";
import {
  issueFailure,
  issueBegin,
  issueSuccess,
} from "../../../store/actions/feedback/IndividualIssueAction";
import { IndividualIssueModel } from "../../../store/models/feedback/IndividualIssue";

import { MonthlyFeedbackState } from "../../../store/states/feedback/MonthlyFeedbackState";
import {
  MonthlyFeedbackFailure,
  MonthlyFeedbackBegin,
  MonthlyFeedbackSuccess,
} from "../../../store/actions/feedback/MonthlyFeedbackAction";

import { GetMonthlyFeedbackState } from "../../../store/states/feedback/MonthlyFeedbackState";
import {
  GetMonthlyFeedbackFailure,
  GetMonthlyFeedbackBegin,
  GetMonthlyFeedbackSuccess,
} from "../../../store/actions/feedback/MonthlyFeedbackAction";

export default class FeedbackClient {
  static getFeedbackProfile =
    (): ThunkAction<void, FeedbackIssuesState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "jira/userstories/search/reporters/tech.insight@rci.rogers.com",
      };
      dispatch(feedbackProfileBegin());

      HttpClient.get<FeedbackProfile>(httpParameters)
        .then((issuesProfile) => {
          dispatch(feedbackProfileSuccess(issuesProfile));
          return issuesProfile;
        })
        .catch((error) => {
          dispatch(feedbackProfileFailure(error));
          return error;
        });
    };

  static getTechnicianFeedback =
    (
      lanId: any
    ): ThunkAction<void, TechnicianIssuesState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "jira/userstories/search?reportedBy=" +
          lanId,
      };
      dispatch(technicianFeedbackBegin());

      HttpClient.get<FeedbackProfile>(httpParameters)
        .then((issuesProfile) => {
          dispatch(technicianFeedbackSuccess(issuesProfile));
          return issuesProfile;
        })
        .catch((error) => {
          dispatch(technicianFeedbackFailure(error));
          return error;
        });
    };

  static getIndividualIssue =
    (
      issueKey: string
    ): ThunkAction<void, IndividualIssueState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "jira/userstories/issue/" +
          issueKey,
      };
      dispatch(issueBegin());

      HttpClient.get<IndividualIssueModel>(httpParameters)
        .then((issue) => {
          dispatch(issueSuccess(issue));
          return issue;
        })
        .catch((error) => {
          dispatch(issueFailure(error));
          return error;
        });
    };

  static getFeedbackComments =
    (
      issueId: string
    ): ThunkAction<void, FeedbackCommentsState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "jira/userstories/issue/" +
          issueId +
          "/comment",
      };
      dispatch(feedbackCommentsBegin());

      HttpClient.get<any>(httpParameters)
      .then((feedbackComments) => {
       const feedbackCommentsObj:FeedbackComments={
        [issueId]:feedbackComments
       }
        dispatch(feedbackCommentsSuccess(feedbackCommentsObj));
        return feedbackComments;
      })
      .catch((error) => {
        dispatch(feedbackCommentsFailure(error));
        return error;
      });
  };


  static postFeedbackIssue =
    (
      feedbackData: any
    ): ThunkAction<void, NewFeedbackState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "jira/userstories",
      };
      dispatch(SaveNewFeedbackBegin());
      HttpClient.post<any>(httpParameters, feedbackData)
        .then((res) => {
          dispatch(SaveNewFeedbackSuccess(res));
          return res;
        })
        .catch((error) => {
          dispatch(SaveNewFeedbackFailure(error));
          return error;
        });
    };

  static postFeedbackComment =
    (
      issueID: any,
      commentData: any
    ): ThunkAction<void, NewCommentState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "jira/userstories/issue/" +
          issueID +
          "/comment",
      };

      dispatch(SaveNewCommentBegin());

      HttpClient.post<any>(httpParameters, commentData)
        .then((res) => {
          dispatch(SaveNewCommentSuccess(res));
          return res;
        })
        .catch((error) => {
          dispatch(SaveNewCommentFailure(error));
          return error;
        });
    };

  static postAttachment =
    (
      issueID: any,
      attachmentData: any
    ): ThunkAction<void, AttachmentState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "jira/userstories/issue/" +
          issueID +
          "/attachments",
        contentType: "multipart/form-data",
      };
      dispatch(AttachmentBegin());
      HttpClient.post<any>(httpParameters, attachmentData)
        .then((res) => {
          dispatch(AttachmentSuccess(res));
          return res;
        })
        .catch((error) => {
          dispatch(AttachmentFailure(error));
          return error;
        });
    };

  static editIssue =
    (
      issueID: any,
      newData: any
    ): ThunkAction<void, FeedbackUpdateState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.ACCOUNT_SERVICE_URL +
          "jira/userstories/issue/" +
          issueID,
      };

      dispatch(UpdateFeedbackBegin());

      HttpClient.put<any>(httpParameters, newData)
        .then((res) => {
          dispatch(UpdateFeedbackSuccess(res));
          return res;
        })
        .catch((error) => {
          dispatch(UpdateFeedbackFailure(error));
          return error;
        });
    };

  static postMonthlyFeedback =
    (
      body: any
    ): ThunkAction<void, MonthlyFeedbackState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "rating",
      };
      dispatch(MonthlyFeedbackBegin());
      HttpClient.post<any>(httpParameters, body)
        .then((res) => {
          dispatch(MonthlyFeedbackSuccess(res));
          return res;
        })
        .catch((error) => {
          dispatch(MonthlyFeedbackFailure(error));
          return error;
        });
    };

  static getMonthlyFeedback =
    (
      userId: any
    ): ThunkAction<void, GetMonthlyFeedbackState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "rating/" + userId,
      };
      dispatch(GetMonthlyFeedbackBegin());
      HttpClient.get<any>(httpParameters)
        .then((res) => {
          dispatch(GetMonthlyFeedbackSuccess(res));
          return res;
        })
        .catch((error) => {
          dispatch(GetMonthlyFeedbackFailure(error));
          return error;
        });
    };
}
