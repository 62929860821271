import { useEffect, useState } from "react";
import "../../design-tokens/rpd-page/RPDPage.scss";
import VCCAPRPDConfigTop from "../../components/rpd/vccapRPDConfig/VCCAPRPDConfigTop";
import VCCAPRPDConfigDetails from "../../components/rpd/vccapRPDConfig/VCCAPRPDConfigDetails";
import { GetVccapRPDConfigInfo } from "../../get-api/GetResponse";
import LoaderGif from "../../resources/images/loader.webp";
import ExceptionBox from "../../components/reusable/ExceptionBox";
import AppLabels from "../../constants/App_Labels";
import { GetTechInfo } from "../../get-api/GetResponse";
import OrderSuccessBox from "../../components/rpd/OrderSuccessBox";
import OrderSuccessPopUp from "../../components/rpd/OrderSuccessPopUp";
import ErrorBox from "../../components/reusable/ErrorBox";

const VccapRpdConfigTab = () => {
  const rpdSwapInfo = GetVccapRPDConfigInfo();
  const techData = GetTechInfo()?.data;
  const [showConfirm, setShowConfirm] = useState(false);
  const labels = AppLabels();

  const handleConfirm = () => {
    setShowConfirm(false);
  };

  useEffect(() => {
    if (rpdSwapInfo?.data !== undefined)
      if (rpdSwapInfo?.error === undefined) setShowConfirm(true);

    if (rpdSwapInfo?.data === undefined)
      if (rpdSwapInfo?.error !== undefined) setShowConfirm(true);
  }, [rpdSwapInfo?.data]);

  return (
    <>
      <div>
        <VCCAPRPDConfigTop
          oldMacAdd={rpdSwapInfo?.macAddress}
          techData={techData}
          isLoading={rpdSwapInfo?.isLoading}
        />
      </div>
      <div className="historyResultCont">
        {rpdSwapInfo.isLoading ? (
          <div className="loader">
            <img style={{ height: "60px" }} src={LoaderGif} />
          </div>
        ) : rpdSwapInfo?.data === undefined &&
          rpdSwapInfo?.error !== undefined &&
          rpdSwapInfo.deviceName === "Harmonic" ? (
          <div className="diagErrorCont">
            <ErrorBox
              open={showConfirm}
              error={labels.rpdHarmonicRPDfail}
              setOpen={setShowConfirm}
              handleClose={handleConfirm}
            />
            <ExceptionBox
              exBoxType="error"
              headerText={labels.rpdHarmonicRPDfail}
              errCode={rpdSwapInfo?.error.errorCode}
              ErrorMSG={rpdSwapInfo?.error.errorMessage}
              srcSystem={rpdSwapInfo?.error.sourceSystem}
              bodyText={rpdSwapInfo?.error.responseFromSource}
              primaryBtn={false}
              secBtn={false}
            />
          </div>
        ) : rpdSwapInfo?.data === undefined &&
          rpdSwapInfo?.error !== undefined &&
          rpdSwapInfo.deviceName === "CASA" ? (
          <div className="diagErrorCont">
            <ErrorBox
              open={showConfirm}
              error={labels.rpdCasaRPDfail}
              setOpen={setShowConfirm}
              handleClose={handleConfirm}
            />
            <ExceptionBox
              exBoxType="error"
              headerText={labels.rpdCasaRPDfail}
              errCode={rpdSwapInfo?.error.errorCode}
              ErrorMSG={rpdSwapInfo?.error.errorMessage}
              srcSystem={rpdSwapInfo?.error.sourceSystem}
              bodyText={rpdSwapInfo?.error.responseFromSource}
              primaryBtn={false}
              secBtn={false}
            />
          </div>
        ) : rpdSwapInfo?.data !== undefined &&
          rpdSwapInfo?.error === undefined &&
          rpdSwapInfo.deviceName === "Harmonic" ? (
          <div className="diagErrorCont">
            <ErrorBox
              open={showConfirm}
              error={
                labels.rpdHarmonicRPDSuccess +
                " " +
                labels.order_id +
                rpdSwapInfo?.data.orderid
              }
              setOpen={setShowConfirm}
              handleClose={handleConfirm}
            />
            {/* <OrderSuccessPopUp
              showVal={showConfirm}
              headerText={labels.order_status}
              bodyHeadText={labels.order_id + rpdSwapInfo?.data.orderid}
              bodyText={labels.rpdHarmonicRPDSuccess}
              confirmText="OK"
              handleConfirm={handleConfirm}
              handleClose={() => setShowConfirm(false)}
              popUpType="pass"
              orderId={rpdSwapInfo?.data.orderid}
            /> */}
            {/* <ExceptionBox
              headerText={labels.rpdHarmonicRPDSuccess}
              bodyText={labels.orderId + rpdSwapInfo?.data.orderid}
              primaryBtn={false}
              secBtn={false}
            /> */}
            <OrderSuccessBox
              headerText={labels.rpdHarmonicRPDSuccess}
              bodyText={labels.orderStatusCheck + rpdSwapInfo?.data.orderid}
              primaryBtn={false}
              secBtn={false}
              orderid={rpdSwapInfo?.data.orderid}
              rpdOrderInfo={rpdSwapInfo}
            />
          </div>
        ) : rpdSwapInfo?.data !== undefined &&
          rpdSwapInfo?.error === undefined &&
          rpdSwapInfo.deviceName === "CASA" ? (
          <div className="diagErrorCont">
            <ErrorBox
              open={showConfirm}
              error={
                labels.rpdCasaRPDSuccess +
                " " +
                labels.order_id +
                rpdSwapInfo?.data.orderid
              }
              setOpen={setShowConfirm}
              handleClose={handleConfirm}
            />
            {/* <OrderSuccessPopUp
            showVal={showConfirm}
            headerText={labels.order_status}
            bodyHeadText={labels.order_id + rpdSwapInfo?.data.orderid}
            bodyText={labels.rpdHarmonicRPDSuccess}
            confirmText="OK"
            handleConfirm={handleConfirm}
            handleClose={() => setShowConfirm(false)}
            popUpType="pass"
            orderId={rpdSwapInfo?.data.orderid}
          /> */}
            {/* <ExceptionBox
            headerText={labels.rpdHarmonicRPDSuccess}
            bodyText={labels.orderId + rpdSwapInfo?.data.orderid}
            primaryBtn={false}
            secBtn={false}
          /> */}
            <OrderSuccessBox
              headerText={labels.rpdCasaRPDSuccess}
              bodyText={labels.orderStatusCheck + rpdSwapInfo?.data.orderid}
              primaryBtn={false}
              secBtn={false}
              orderid={rpdSwapInfo?.data.orderid}
              rpdOrderInfo={rpdSwapInfo}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default VccapRpdConfigTab;
