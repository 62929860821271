import { shallowEqual, useDispatch, useSelector } from "react-redux";
import IHPClient from "../../network/httpclient/ihp/IHPClient";
import DiagContainer from "../reusable/DiagContainer";
import ReloadData from "../reusable/ReloadData";
import AppLabels from "../../constants/App_Labels";
import { GLOBAL_CONST } from "../../constants/Global";
import CustomAccordion from "../reusable/CustomAccordion";
import { isNull } from "../reusable/Util";
import ExceptionBox from "../reusable/ExceptionBox";

const QLRNData = (props: any) => {
  const labels = AppLabels();
  const { homeNum } = props;
  const dispatch = useDispatch();
  const { qlrnLoading, qlrnInfo, qlrnError } = useSelector(
    (state: any) => ({
      qlrnInfo: state.AccountState.IHPQrlnDataInfo.data,
      qlrnLoading: state.AccountState.IHPQrlnDataInfo.isLoading,
      qlrnError: state.AccountState.IHPQrlnDataInfo.error,
    }),
    shallowEqual
  );
  const refreshData = () => {
    dispatch(IHPClient.getIHPQrlnData(GLOBAL_CONST.telPrefix + homeNum));
  };

  return (
    <>
      <div className="refreshCont">
        <ReloadData refreshData={refreshData} />
      </div>
      <div className="cmSummaryCont">
        <DiagContainer
          isLoading={qlrnLoading}
          data={qlrnInfo}
          error={qlrnError}
          sectionName="QLRN Data"
        >
          <CustomAccordion
            accTitle={labels.SUMMARY.toUpperCase()}
            accBodyElement={
              <table className="dataTableDetails">
                {qlrnInfo !== undefined ? (
                  <>
                    <thead>
                      <th id="nameCol">{labels.NAME.toUpperCase()}</th>
                      <th id="valueCol">{labels.VALUE.toUpperCase()}</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>OUTPUT</td>
                        <td>
                          {isNull(
                            qlrnInfo.output
                              ? qlrnInfo.output
                                  ?.split("\n")
                                  ?.map((output: any) => (
                                    <p className="noFormating"> {output} </p>
                                  ))
                              : ""
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>DESIGNATED SWITCH</td>
                        <td>{isNull(qlrnInfo.designatedSwitch)}</td>
                      </tr>
                    </tbody>
                  </>
                ) : (
                  <div className="diagErrorCont">
                    <ExceptionBox
                      headerText="No Data to display"
                      bodyText="Please try refreshing or contact the Tech Insight admin if data is missing."
                      primaryBtn={true}
                      secBtn={false}
                      handleRefresh={() => refreshData()}
                    />
                  </div>
                )}
              </table>
            }
          />
        </DiagContainer>
      </div>
    </>
  );
};

export default QLRNData;
