import { createSlice } from "@reduxjs/toolkit";

import { apiCallBegan } from "../../../../middleware/apiActions";
import storeConst from "../../../../storeConst";
import { RF_DOWNSTREAM_TRACE_API } from "../../../../middleware/apiEndpoints";
import { incrApiCall, decrApiCall } from "../../mapDetails";

const slice = createSlice({
  name: "rfDownstreamTrace",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
    isActive: false,
    networkId: undefined,
    rootNodeSiteId: undefined,
    type: undefined,
    elementInfo: undefined,
  },
  reducers: {
    rfDownstreamTraceBegin: (rfDownstreamTrace) => {
      rfDownstreamTrace.isActive = false;
      rfDownstreamTrace.isLoading = true;
      rfDownstreamTrace.status = "";
      rfDownstreamTrace.data = undefined;
      rfDownstreamTrace.error = undefined;
    },
    rfDownstreamTraceSuccess: (rfDownstreamTrace, { payload }) => {
      rfDownstreamTrace.isActive = true;
      rfDownstreamTrace.networkId = payload.networkId;
      rfDownstreamTrace.rootNodeSiteId = payload.rootNodeSiteId;
      rfDownstreamTrace.type = payload.type;
      rfDownstreamTrace.elementInfo = payload.elementInfo;
      rfDownstreamTrace.isLoading = false;
      rfDownstreamTrace.data = payload?.data;
      rfDownstreamTrace.error = undefined;
      rfDownstreamTrace.status = storeConst.OK;
    },
    rfDownstreamTraceFailure: (rfDownstreamTrace, { payload }) => {
      rfDownstreamTrace.isActive = false;
      rfDownstreamTrace.rootNodeSiteId = payload.rootNodeSiteId;
      rfDownstreamTrace.networkId = payload.networkId;
      rfDownstreamTrace.type = payload.type;
      rfDownstreamTrace.elementInfo = payload.elementInfo;
      rfDownstreamTrace.isLoading = false;
      rfDownstreamTrace.error = payload;
      rfDownstreamTrace.data = undefined;
      rfDownstreamTrace.status = storeConst.ERROR;
    },
    resetRfDownstreamTraceData: (rfDownstreamTrace) => {
      rfDownstreamTrace.isActive = false;
      rfDownstreamTrace.rootNodeSiteId = undefined;
      rfDownstreamTrace.networkId = undefined;
      rfDownstreamTrace.type = undefined;
      rfDownstreamTrace.elementInfo = undefined;
      rfDownstreamTrace.isLoading = false;
      rfDownstreamTrace.status = "";
      rfDownstreamTrace.data = undefined;
      rfDownstreamTrace.error = undefined;
    },
    setRfDownstreamTraceIsActive: (rfDownstreamTrace, { payload }) => {
      rfDownstreamTrace.isActive = payload;
    },
  },
});

export const { resetRfDownstreamTraceData, setRfDownstreamTraceIsActive } =
  slice.actions;
//should not be exposed to outside
const {
  rfDownstreamTraceBegin,
  rfDownstreamTraceSuccess,
  rfDownstreamTraceFailure,
} = slice.actions;
export default slice.reducer;

export const getRfDownstreamTraceElements =
  (networkId: any, rootNodeSiteId: any, type: any, elementInfo: any) =>
  (dispatch: any) => {
    dispatch(
      apiCallBegan({
        url: RF_DOWNSTREAM_TRACE_API(networkId),
        method: "get",
        onStart: rfDownstreamTraceBegin.type,
        onStartDispatch: incrApiCall.type,
        onSuccess: rfDownstreamTraceSuccess.type,
        onSuccessDispatch: decrApiCall.type,
        onError: rfDownstreamTraceFailure.type,
        onErrorDispatch: decrApiCall.type,
        props: {
          rootNodeSiteId,
          networkId,
          type,
          elementInfo,
        },
      })
    );
  };
