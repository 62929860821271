import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { MdRefresh } from "react-icons/md";

import CertificationTable from "./CertificationTable";
import "../../design-tokens/certification/CertificationTab.scss";
import CertificationProcess from "./CertificationProcess";
import { HistoryReset } from "../../store/actions/account/forms/FormDataAction";
import ConfirmPopUp from "../reusable/ConfirmPopUp";
import AppLabels from "../../constants/App_Labels";
import LoaderGif from "../../resources/images/loader.webp";
import { ResetActiveCerti } from "../../store/actions/account/certifications/ActiveCertificationAction";
import { SetWorkOrderId } from "../../store/ducksPattern/TechProfile";
import CertificationClient from "../../network/httpclient/certification/CertificationClient";
import { GetActiveAccountDetails, GetTechInfo, GetWorkOrdersList } from "../../get-api/GetResponse";
import WorkOrdersClient from "../../network/httpclient/workorders/WorkOrdersClient";
import { getAccessLevelByRoles } from "../reusable/Util";
import ConfigConst from "../../constants/ConfigConst";

const CertificationTab = (props: any) => {
  const [display, setDisplay] = useState(false);
  const [showPOPUp, setShowPOPUp] = useState(false);
  const [inprogressCertiStep, setInprogressCertiStep] = useState(0);
  const dispatch = useDispatch();
  const labels = AppLabels();
  const techData = GetTechInfo()?.data;
  const { sortedDevices, data: accInfo, allCertiList } = GetActiveAccountDetails();
  const { data, isLoading } = GetWorkOrdersList();
  useEffect(() => {
    let accNum = accInfo.accountNumber;
    dispatch(CertificationClient.getAllCerti(accNum));
  }, [display]);
  useEffect(() => {
    dispatch(HistoryReset());
  }, []);
  const refreshCertiList = () => {
    let accNum = accInfo.accountNumber;
    dispatch(CertificationClient.getAllCerti(accNum));
  };
  const startCertification = () => {
    setInprogressCertiStep(0);
    setDisplay(true);
    if (data === undefined && isLoading === false) { // For techs coming from Click link, pull WO List data
      dispatch(
        WorkOrdersClient.getTechWorkOrders(
          techData?.techId,
          techData?.name,
          getAccessLevelByRoles(techData?.roles).includes(ConfigConst.MT)
        )
      );
    }
  };
  const handleCancel = () => {
    setShowPOPUp(true);
  };
  const handleConfirm = () => {
    dispatch(ResetActiveCerti());
    setShowPOPUp(false);
    setDisplay(false);
  };
  const goListing = () => {
    dispatch(ResetActiveCerti());
    dispatch(SetWorkOrderId(""));
    setDisplay(false);
  };
  const isStartCertificationActive =
    (techData?.roles?.includes("technician") ||
      techData?.roles?.includes("external-technician")) &&
      techData?.hasOwnProperty("techId") &&
      sortedDevices?.length > 0
      ? true
      : false;
  return (
    <div className="certiTabBox">
      {!display ? (
        <div style={{ textAlign: "center" }}>
          <div className="newCertiCont">
            <button
              className="refreshCertiBtn"
              onClick={() => refreshCertiList()}
            >
              Refresh <MdRefresh />
            </button>
            {isStartCertificationActive && (
              <button
                className="startCertiBtn"
                onClick={() => startCertification()}
              >
                START NEW CERTIFICATION
              </button>
            )}
          </div>
          {allCertiList !== undefined ? (
            <CertificationTable
              allCerti={allCertiList}
              techData={techData}
              changeDisplay={() => setDisplay(true)}
              completedStep={(s: number) => setInprogressCertiStep(s)}
            />
          ) : (
            <img style={{ height: "60px" }} src={LoaderGif} />
          )}
        </div>
      ) : (
        <CertificationProcess
          handleCancel={handleCancel}
          goListing={goListing}
          openInprogressCerti={inprogressCertiStep}
        />
      )}
      <ConfirmPopUp
        showVal={showPOPUp}
        headerText="WARNING"
        bodyHeadText={labels.ARE_YOU_SURE_TO_PROCEED}
        bodyText="You are trying to abort midway, the certification will stay in the InProgress status in the list. You can continue later from your last saved step."
        cancelText="No"
        confirmText="Yes"
        handleConfirm={() => handleConfirm()}
        handleClose={() => setShowPOPUp(false)}
      />
    </div>
  );
};

export default CertificationTab;
