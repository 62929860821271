import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import TableRowComponent from "./TableRowComponent";
import "./RfCalculatorStyle.scss";
import { Button, Card, Divider } from "@material-ui/core";
import AppLabels from "../../constants/App_Labels";
import { FaPlus } from "react-icons/fa";
import {
  getSelPartValue,
  handleNan,
  rfCsvHeaders,
  rfCsvData,
} from "./RfUtilComponent";
import { ExportCsv } from "../gpon-page/components/ExportCsv";
import { BiDownload } from "react-icons/bi";
import { IoRefreshOutline } from "react-icons/io5";

const RfMaintenanceCalculator = (props: any) => {
  const labels = AppLabels();
  let newFreqObj = {
    signalLevel: [
      { name: "42Mhz", value: 0 },
      { name: "111Mhz", value: 0 },
      { name: "860Mhz", value: 0 },
    ],
    spigotLevel: [
      { name: "42Mhz", value: 0 },
      { name: "111Mhz", value: 0 },
      { name: "860Mhz", value: 0 },
    ],
  };
  const tapSigmalOptions = [
    { name: "Virtual Analog", value: 0 },
    { name: "Digital", value: 6 },
  ];
  const [values, setValues] = useState<any>({
    partType: "",
    qty: 0,
    freq: newFreqObj,
  });
  const [tableRows, setTableRows] = useState<any[]>([]); // array of the TableRowComponents
  const [tapSignal, setTapSignal] = useState<number>(0);
  const isDataUpdate = useRef(false);
  const [open, setOpen] = useState(false); //for modal for export

  const getPrevRowData = (rowData: any, indexVal: number) => {
    let prevFrq = {};

    var itemPosition: number = indexVal;
    while (itemPosition > 0) {
      if (itemPosition - 1 !== -1 && rowData && rowData?.[itemPosition - 1]) {
        if (
          handleNan(rowData?.[itemPosition - 1]?.qty) !== 0 &&
          rowData?.[itemPosition - 1]?.qty !== ""
        ) {
          prevFrq = rowData?.[itemPosition - 1]?.freq;
          break;
        }
      }
      itemPosition--;
    }
    return prevFrq;
  };

  const updateSeqRowVals = (rowData: any) => {
    tableValueReset(rowData);
    let rowDataMap = rowData;

    if (rowData !== undefined && rowData?.length > 0) {
      let prevFrq: any;
      rowDataMap = rowData.map((ele: any, index: number) => {
        if (index > -1) {
          let rowObj = rowData?.[index];

          prevFrq = prevFrq ? prevFrq : getPrevRowData(rowData, index);

          let processedObj = getSelPartValue(
            rowObj.partType,
            rowObj.qty,
            index === 0 ? tapSignal : 0,
            prevFrq
          );

          rowObj = {
            ...rowObj,
            freq: {
              signalLevel: processedObj?.signalLevel,
              spigotLevel: processedObj?.spigotLevel,
            },
          };
          prevFrq = rowObj?.freq;
          return rowObj;
        } else {
          return ele;
        }
      });
    }
    return rowDataMap;
  };

  const handleAddRow = () => {
    let newTableRows = [...tableRows, values];
    let tabRow = updateSeqRowVals(newTableRows);
    setTableRows(tabRow);
    isDataUpdate.current = true;
  };

  const handleDeleteRow = (indexToDelete: number) => {
    const updatedTableRows = tableRows.filter((rowData, index: number) => {
      if (index !== indexToDelete) {
        return rowData;
      }
    });
    let tabRow = updateSeqRowVals(updatedTableRows);
    setTableRows(tabRow);
    isDataUpdate.current = true;
  };

  const tableValueReset = (tableRowsData: any) => {
    const updatedTableRows = tableRowsData.map((rowData: any) => {
      return {
        ...rowData,
        freq: newFreqObj,
      };
    });
    setTableRows(updatedTableRows);
  };

  const updateRowValues = (selRowData: any) => {
    /*
      updates the whole values
    */
    const updatedTableRows = tableRows.map((rowData: any, index: number) => {
      if (index === selRowData?.indexVal) {
        return {
          ...rowData,
          partType: selRowData?.partType,
          qty: selRowData.qty,
        };
      } else {
        return rowData;
      }
    });
    setTableRows(updatedTableRows);
    isDataUpdate.current = true;
  };

  const getDefaultFileName = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    let time = today.toLocaleTimeString().split(":");
    return (
      "RF_LOSS_" + mm + "-" + dd + "-" + yyyy + "_" + time[0] + "_" + time[1]
    );
  };

  const handleReset = () => {
    setTableRows([]);
    setTapSignal(0);
  };

  useEffect(() => {
    if (tapSignal !== undefined && tableRows?.length > 0) {
      isDataUpdate.current = true;
    }
  }, [tapSignal]);

  useEffect(() => {
    /*
      Update the tableRows array upon values changing
    */
    if (isDataUpdate.current) {
      let tabRow = updateSeqRowVals(tableRows);
      setTableRows(tabRow);
      isDataUpdate.current = false;
    }
  }, [isDataUpdate.current, tapSignal]);

  return (
    <div className="filemanager">
      <div className="filemanager-header">
        <h4>{labels.RF_MT_CALCULATOR}</h4>
      </div>
      <div
        style={{
          display: "flex",
          flex: "1",
          alignItems: "flex-start",
          flexDirection: "row",
          padding: "0px 20px 20px 20px",
        }}
      >
        <Button
          style={{
            marginLeft: "5px",
            width: "110px",
            borderRadius: "7px",
            border: "1px solid blue",
          }}
          className="blueHollowBtn"
          onClick={handleReset}
          title="Reset"
          disabled={tableRows?.length < 1}
        >
          <IoRefreshOutline />
        </Button>
        <Button
          style={{
            marginLeft: "5px",
            width: "110px",
            borderRadius: "7px",
            border: "1px solid blue",
          }}
          className="blueHollowBtn"
          onClick={() => setOpen(true)}
          title="Export"
          disabled={tableRows?.length < 1}
        >
          <BiDownload />
        </Button>
        <ExportCsv
          show={open}
          handleClose={() => setOpen(false)}
          headerText={labels.Export}
          cancelText={labels.CANCEL}
          rowData={() => rfCsvData(tableRows, tapSignal)}
          columnData={rfCsvHeaders}
          getDefaultLibFileName={getDefaultFileName}
        />
      </div>
      <div className="filemanager-content">
        <Card className="custInfoCard" style={{ marginTop: "0px" }}>
          <div className="filemanager-header">
            <span>
              <label style={{ fontWeight: "bold" }}>
                Signal Level At Tap
                <label>
                  <select
                    name="Drop tapSignal"
                    id={`"DroptapSignal"`}
                    value={tapSignal}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      setTapSignal(handleNan(e.currentTarget.value));
                    }}
                    style={{
                      width: "100px",
                      marginLeft: "15px",
                      borderRadius: "5px",
                      borderWidth: "1px",
                    }}
                  >
                    {tapSigmalOptions?.map((el) => (
                      <option
                        id={`"DroptapSignalOption"` + el?.name}
                        value={el?.value}
                      >
                        {el?.name}
                      </option>
                    ))}
                  </select>
                </label>
                <input
                  type="text"
                  value={tapSignal}
                  //onChange={(e) => setTapSignal(e)}
                  style={{
                    width: "40px",
                    marginLeft: "10px",
                    borderRadius: "5px",
                    borderWidth: "1px",
                  }}
                  disabled={true}
                />
              </label>
            </span>

            <strong style={{ marginLeft: "100px", marginBottom: "-15px" }}>
              {"Signal Levels"}
            </strong>

            <strong style={{ marginLeft: "300px", marginBottom: "-15px" }}>
              {"Spigot Levels"}
            </strong>
          </div>
          <hr />

          <div className="filemanager-header">
            <section>
              <span style={{ marginLeft: "35px" }}>{"Part"}</span>
              <span style={{ marginLeft: "85px" }}>{"Qty"}</span>
              <Divider orientation="vertical" />
            </section>
            <section style={{ marginLeft: "15px" }}>
              <span style={{ marginLeft: "103px" }}>{"42 Mhz"}</span>
              <span style={{ marginLeft: "70px" }}>{"111 Mhz"}</span>
              <span style={{ marginLeft: "65px" }}>{"860 Mhz"}</span>
              {/* <span style={{ marginLeft: "45px" }}>{"1200 Mhz"}</span> */}
              <Divider orientation="vertical" />
            </section>
            <section style={{ marginLeft: "25px" }}>
              <span style={{ marginLeft: "75px" }}>{"42 Mhz"}</span>
              <span style={{ marginLeft: "65px" }}>{"111 Mhz"}</span>
              <span style={{ marginLeft: "65px" }}>{"860 Mhz"}</span>
              {/* <span style={{ marginLeft: "50px" }}>{"1200 Mhz"}</span> */}
            </section>
          </div>

          <div className="calculator-form" id="inputs">
            <div>
              <TableRowComponent
                tableData={tableRows}
                deleteRow={handleDeleteRow}
                updateRow={updateRowValues}
              />
            </div>
            <button
              style={{
                marginLeft: "425px",
                width: "220px",
                height: "29px",
                borderRadius: "5px",
                border: "1px solid blue",
              }}
              className="blueHollowBtn"
              onClick={handleAddRow}
              title="Add Rows"
            >
              <FaPlus />
            </button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default RfMaintenanceCalculator;
