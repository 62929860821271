import { CMDocsisEventInfo } from "../../../../models/account/diagnostics/cm/CMDocsisEventInfo";

export const CM_DOCSIS_EVENT_INFO_BEGIN = "CM_DOCSIS_EVENT_INFO_BEGIN";
export const CM_DOCSIS_EVENT_INFO_SUCCESS = "CM_DOCSIS_EVENT_INFO_SUCCESS";
export const CM_DOCSIS_EVENT_INFO_FAILURE = "CM_DOCSIS_EVENT_INFO_FAILURE";

interface CMDocsisEventInfoBegin {
  type: typeof CM_DOCSIS_EVENT_INFO_BEGIN;
}

interface CMDocsisEventInfoSuccess {
  type: typeof CM_DOCSIS_EVENT_INFO_SUCCESS;
  payload: CMDocsisEventInfo[];
}

interface CMDocsisEventInfoFailure {
  type: typeof CM_DOCSIS_EVENT_INFO_FAILURE;
  payload: Error;
}

export type CMDocsisEventInfoActionTypes =
  | CMDocsisEventInfoBegin
  | CMDocsisEventInfoSuccess
  | CMDocsisEventInfoFailure;
