import {
  ActiveCertificationActionTypes,
  SAVE_ACTIVE_CERTI_BEGIN,
  SAVE_ACTIVE_CERTI_FAILURE,
  SAVE_ACTIVE_CERTI_SUCCESS,
  SAVE_DATA_LOADING,
  SAVE_WIFI_BYPASS_FLAG,
  SAVE_FAILED_DEVICES,
  SAVE_PASSED_DEVICES,
  SAVE_MARGINAL_PASSED_DEVICES,
  SAVE_SKIPPED_DEVICES,
  RESET_SAVED_DEVICES,
  RESET_ACTIVE_CERTIFICATION,
} from "../../../types/account/certifications/ActiveCertification";

export function SaveActiveCertiBegin(): ActiveCertificationActionTypes {
  return {
    type: SAVE_ACTIVE_CERTI_BEGIN,
  };
}

export function SaveActiveCertiSuccess(
  activeCerti: any
): ActiveCertificationActionTypes {
  return {
    type: SAVE_ACTIVE_CERTI_SUCCESS,
    payload: activeCerti,
  };
}

export function SaveActiveCertiFailure(
  error: Error
): ActiveCertificationActionTypes {
  return {
    type: SAVE_ACTIVE_CERTI_FAILURE,
    payload: error,
  };
}
export function SaveDataLoading(
  dataFlag: any
): ActiveCertificationActionTypes {
  return {
    type: SAVE_DATA_LOADING,
    payload: dataFlag,
  };
}
export function SavePassedDevices(
  passDevices: any
): ActiveCertificationActionTypes {
  return {
    type: SAVE_PASSED_DEVICES,
    payload: passDevices,
  };
}
export function SaveFailedDevices(
  failDevices: any
): ActiveCertificationActionTypes {
  return {
    type: SAVE_FAILED_DEVICES,
    payload: failDevices,
  };
}
export function SaveMarginalPassDevices(
  marginalDevices: any
): ActiveCertificationActionTypes {
  return {
    type: SAVE_MARGINAL_PASSED_DEVICES,
    payload: marginalDevices,
  };
}
export function SaveWifiBypassFlag(
  flag: any
  ):ActiveCertificationActionTypes {
    return {
  type:  SAVE_WIFI_BYPASS_FLAG,
  payload: flag,
};
  }
export function SaveSkippedDevices(
  skipDevices: any
): ActiveCertificationActionTypes {
  return {
    type: SAVE_SKIPPED_DEVICES,
    payload: skipDevices,
  };
}
export function ResetSavedDevices(): ActiveCertificationActionTypes {
  return {
    type: RESET_SAVED_DEVICES,
  };
}
export function ResetActiveCerti(): ActiveCertificationActionTypes {
  return {
    type: RESET_ACTIVE_CERTIFICATION,
  };
}
