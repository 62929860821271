import { useState, useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import { PencilFill, ChatLeftText } from "react-bootstrap-icons";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Userdummy from "../../resources/images/userdummy.png";
import FeedbackClient from "../../network/httpclient/feedback/FeedbackClient";
import AppLabels from "../../constants/App_Labels";
import { parseDate } from "../reusable/Util";
import { GetTechInfo } from "../../get-api/GetResponse";
import ListComments from "./ListComments";
import React from "react";

const HistoryComponent = (props: any) => {
  const dispatch = useDispatch();
  const labels = AppLabels();
  const [waitDownload, setWaitDownload] = useState(false);
  const techData = GetTechInfo()?.data;
  const [showFullDescription, setShowFullDescription] = useState(false);
  const description = `${props?.issue?.fields?.description}`;


  const { issueData, status, commentsData } = useSelector(
    (state: any) => ({
      issueData: state?.IndividualIssueInfo?.data,
      status: state?.IndividualIssueInfo?.status,
      commentsData: state?.FeedbackCommentsInfo?.data,
    }),
    shallowEqual
  );

  const handleEdit = (event: any) => {
    dispatch(FeedbackClient.getIndividualIssue(props?.issue?.key));
    props.setIssueToBeEditted(props?.issue?.key);
    props.showForm(0);
    props.setEdit(1);
  };

  const handleComments = (event: any) => {
    if(props.tab!="YourFeedback" || !commentsData[props?.issue?.key])
      dispatch(FeedbackClient.getFeedbackComments(props?.issue?.key));
    props.setIdComments(props?.issue?.key);
    props.showForm(0);
    props.setComments(1);
  };

  const getAttachments = () => {
    dispatch(FeedbackClient.getIndividualIssue(props?.issue?.key));
    setWaitDownload(true);
  };

  const getName = () => {
    if (props?.issue?.fields?.customfield_22204) {
      const name = props?.issue?.fields?.customfield_22204.split("@")?.[0];
      const arrName = name.split(".");
      return arrName?.[0] + " " + arrName?.[1];
    }
  };

  useEffect(() => {
    if(props.tab==="YourFeedback")
    dispatch(FeedbackClient.getFeedbackComments(props?.issue?.key));
    if (
      issueData !== undefined &&
      waitDownload === true &&
      status === "OK" &&
      issueData.key === props.issue.key
    ) {
      const id = "old-attachments" + props?.issue?.key + props?.tab;
      const oldAttachments = document.getElementById(id);

      if (oldAttachments) oldAttachments.innerHTML = "";
      
      if (issueData?.fields?.attachment?.length === 0) {
        const p = document.createElement("p");
        p.innerHTML = labels.NO_ATTACHMENTS;
        p.id = "no-attachment";
        oldAttachments?.appendChild(p);
        if (oldAttachments) oldAttachments.style.display = "flex";
      }

      issueData?.fields?.attachment?.map((attachment: any, index: any) => {
        const a = document.createElement("a");
        a.id = "attachment" + index.toString();
        a.className = "old-attachments";
        a.target = "_blank";
        a.href = attachment.content;
        a.innerHTML = attachment.filename;
        
        oldAttachments?.appendChild(a);
        if (oldAttachments) oldAttachments.style.display = "flex";
      });
    }
  }, [status, issueData]);

  return (
    <Card className="feedback-card">
      <Card.Body className="feedback-card">
        <img className="feedback-photo" src={Userdummy} />
        <div
          className="icons-row"
          style={{
            display:
              props?.issue?.fields?.customfield_22204 === techData.lanId
                ? "flex"
                : "none",
          }}
        >
          <div className="icons-container">
            <Button bsPrefix="edit" onClick={handleEdit}>
              <PencilFill className="icon" />
            </Button>
          </div>
        </div>
        <div className="feedback-body">
          <Card.Title className="feedback-title">
            <p className="fbSummary">{props?.issue?.fields?.summary}</p>
            {props.tab==="AllFeedback"&&<span>
              Submitted by: {getName()}
              {props?.issue?.fields?.reportedBy
                ?.split("-//")?.[0]
                .match(/[A-Z][a-z]+/g)}
            </span>}
          </Card.Title>
          {props.tab==="AllFeedback"&& <React.Fragment><Card.Text className="feedback-submission">
            {`${labels.SUBMITTED_ON} `}
            {parseDate(props?.issue?.fields?.created)}
          </Card.Text>
          <Card.Text className="feedback-type">
            <b style={{ fontWeight: 500 }}>{`${labels.TYPE}: `}</b>
            {`${
              props?.issue?.fields?.issuetype?.name === "Testimonial"
                ? "Appreciation"
                : props?.issue?.fields?.issuetype?.name
            } `}
            <b
              style={{
                fontWeight: 500,
                paddingLeft: "10px",
              }}
            >
              {`Jira ID: `}
            </b>
            {props?.issue?.key || ""}

            <Button
              style={{
                paddingLeft: "10px",
              }}
              bsPrefix="attachment-link"
              onClick={getAttachments}
            >
              {labels.ATTACHMENTS}
            </Button>
          </Card.Text>
          <div
            id={"old-attachments" + props.issue.key + props.tab}
            className="old-attachments-container"
          ></div></React.Fragment>}
          <Card.Text className="feedback-description">
          {showFullDescription
                ? description
                : (description.split(" ").length > 10 ? description.split(" ").slice(0, 10).join(" ").concat("..."):description.split(" ").slice(0, 10).join(" "))}
              {description.split(" ").length > 10 && (

                <Button
                  variant="link"
                  className="show-more-less"
                  onClick={() =>
                    setShowFullDescription(!showFullDescription)
                  }
                >
                  {showFullDescription ? "less" : "more"}
                </Button>
              )}
          </Card.Text>
          <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <p style={{fontWeight: 600}}>Comments ({commentsData[props?.issue?.key]?.comments?.length})</p>
            <Button style={{marginTop:"-11px"}} bsPrefix="comments-button" onClick={handleComments}>
              <Card.Text className="comments-button-text">
                <u style={{ fontWeight: 600,color:"#62aeff" }}>{labels.VIEW_ALL_COMMENTS}</u>
              </Card.Text>
            </Button>
          </div>
          {props.tab==="YourFeedback"&&<ListComments issueKey={props?.issue?.key}/>}
        </div>
      </Card.Body>
      <hr className="historyHR" />
    </Card>
  );
};

export default HistoryComponent;
