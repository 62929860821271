import { Divider } from "@material-ui/core";
import { useState, useEffect } from "react";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import TopologyLabels from "../../constants/topologyLabels";

interface OETypes {
  id: number | string;
  value: string;
}
export interface TreeItemTypes {
  id: number | string;
  name: string;
  children?: any;
  otherElements?: OETypes[];
  collapse?: boolean;
  selectedId?: number | string;
  setSelectedId?: (selectedId: number | string) => void;
}
interface TreeViewTypes {
  children: JSX.Element | JSX.Element[];
  collapsed: boolean;
  setCollapsed: (collapse: boolean) => void;
  title: string;
}

export default function TreeView({
  children,
  collapsed,
  setCollapsed,
  title,
}: TreeViewTypes) {
  const labels = TopologyLabels();

  return (
    <div style={{ margin: "10px 0px" }} className="treeViewCont">
      <div className="flexedRowDiv treeViewHeader">
        <label className="treeViewTitle">{title}</label>
        <label
          className="treeViewCollapseBtn"
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed ? labels.ExpandAll : labels.CollapseAll}
        </label>
      </div>
      <Divider style={{ margin: "5px 0px" }} />
      {children}
    </div>
  );
}

export function TreeItem({
  id,
  name,
  children,
  otherElements,
  collapse = true,
  selectedId,
  setSelectedId,
}: TreeItemTypes) {
  const [collapsed, setCollapsed] = useState(collapse);
  useEffect(() => setCollapsed(collapse), [collapse]);

  const onCollapseClick = () => {
    onSelectedIDChange(id);
    setCollapsed(!collapsed);
  };
  const onSelectedIDChange = (id: number | string) =>
    setSelectedId && setSelectedId(id);

  return (
    <div className="treeItemCont">
      <div
        className={
          selectedId === id ? "flexedRowDiv treeItemSelected" : "flexedRowDiv"
        }
        onClick={onCollapseClick}
      >
        {collapsed ? <BiChevronRight /> : <BiChevronDown />}
        <label className="treeItemLabel treeItemCollapse">{name}</label>
      </div>
      {!collapsed && (
        <div style={{ marginLeft: 30 }}>
          {otherElements?.map(
            (e) =>
              e && (
                <div
                  key={e.value}
                  onClick={() => onSelectedIDChange(e.id)}
                  className={selectedId === e.id ? "treeItemSelected" : ""}
                >
                  <label className="treeItemLabel">{e.value}</label>
                </div>
              )
          )}
          {children?.map((e: TreeItemTypes) => (
            <TreeItem
              {...e}
              collapse={collapse}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
            />
          ))}
        </div>
      )}
    </div>
  );
}
