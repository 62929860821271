import { createSlice } from "@reduxjs/toolkit";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "xleCellular",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    // action => action handler
    GetXleCellularBegin: (xleCellular) => {
        xleCellular.isLoading = true;
    },
    GetXleCellularSuccess: (xleCellular, action) => {
        xleCellular.isLoading = false;
        xleCellular.data= action.payload.map((item:any) => item.data)
        xleCellular.status = storeConst.OK;
    },
    GetXleCellularFailure: (xleCellular, action) => {
        xleCellular.isLoading = false;
        xleCellular.error = action.payload;
        xleCellular.data = undefined;
        xleCellular.status = storeConst.ERROR;
    },

 
  },
});

export const {
    GetXleCellularBegin,
    GetXleCellularSuccess,
    GetXleCellularFailure,
} = slice.actions;

export default slice.reducer;
