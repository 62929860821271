import errImg from "../../resources/images/icons/404Error.png";
import timeout from "../../resources/images/icons/timeOutError.svg";
import emptyData from "../../resources/images/icons/EmptyDataError.svg";
import pass from "../../resources/images/icons/pass.svg";
import fail from "../../resources/images/icons/fail.svg";
import inprogress from "../../resources/images/icons/inprogress.svg";
const PassFailBox = (props: any) => {
  const {
    exBoxType,
    errSecName,
    headerText,
    ErrorMSG,
    bodyText,
    srcSystem,
    errCode,
    primaryBtn,
    handleRefresh,
  } = props;

  return (
    <div className="exceptionCont">
      {exBoxType === "pass" ? (
        <img src={pass} />
      ) : exBoxType === "fail" ? (
        <img src={fail} />
      ) : exBoxType === "inprogress" ? (
        <img src={inprogress} />
      ) : (
        <img src={emptyData} />
      )}
      {exBoxType === "error" ? (
        <h4>
          Sorry! We are unable to pull info for <b>{errSecName}</b> section at
          this time.
        </h4>
      ) : exBoxType === "timeout" ? (
        <h4>Sorry! Something just went wrong.</h4>
      ) : (
        <h4>{headerText}</h4>
      )}
      {exBoxType === "error" ? (
        <p className="errorMsgCont">
          This is due to the reason - Error Code: {errCode} - “{ErrorMSG}” from{" "}
          {srcSystem}. You can try to refresh or try again after sometime.
        </p>
      ) : exBoxType === "timeout" ? (
        <p>Please try refreshing if data is missing.</p>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: bodyText }}></div>
      )}

      {primaryBtn ? (
        <div>
          <button className="refreshExcBtn" onClick={() => handleRefresh()}>
            Refresh
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PassFailBox;
