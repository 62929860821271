import AppLabels from "../../../constants/App_Labels";
import ConfigConst from "../../../constants/ConfigConst";
import {
  convertPascalCase,
  getEmailFormatDate,
  isNull,
} from "../../reusable/Util";
import IECLabels from "../../../constants/IEC_Labels";
interface Props {
  activeAccData: any;
  custType: string;
  body: any;
}

const PrepareCECEmailFormat = (props: Props) => {
  const labels = AppLabels();
  const iec_labels = IECLabels();

  return (
    <div
      style={{
        display: "inline-block",
        fontSize: "14pt",
        fontWeight: 500,
        fontFamily: "Ted Next",
      }}
    >
      <p>{"Dear Valued Customer"},</p>
      <div>
        <p>
          {"Thank you for choosing Rogers Ignite"}
          <sub>TM</sub>
          {"! "}
          {" We know you are going to love your new services. "}
          {"During your recent service call, "}
          {props?.activeAccData === true
            ? props.body?.formData?.techName
            : props.body?.props?.RRFDetails?.techName}
          {
            " set up your Ignite services and discussed or completed the following: "
          }
        </p>
      </div>
      <br />
      <div>
        {props?.activeAccData === true ? (
          <>
            {props.body?.formData?.services?.map(
              (services: any, j: number) =>
                services?.label === "Service" &&
                services?.active === true && (
                  <>
                    {props.body?.formData?.ServiceChecklist?.map(
                      (e: any, i: number) =>
                        e?.active === true && (
                          <li key={i}>
                            <span>{e?.label}</span>
                          </li>
                        )
                    )}
                  </>
                )
            )}
            {props.body?.formData?.services?.map(
              (services: any, j: number) =>
                services?.label === "Installation" &&
                services?.active === true && (
                  <>
                    {props.body?.formData?.InstallChecklist?.map(
                      (e: any, i: number) =>
                        e?.active === true && (
                          <li key={i}>
                            <span>{e?.label}</span>
                          </li>
                        )
                    )}
                    {props.body?.formData?.InstallChecklist?.[2]?.active !==
                      true &&
                      props.body?.formData?.WifiHubAppActivity?.map(
                        (e: any, i: number) =>
                          e?.active === true && (
                            <li key={i}>
                              <span>{e?.label}</span>
                            </li>
                          )
                      )}
                  </>
                )
            )}
          </>
        ) : (
          <>
            {props.body?.props?.RRFDetails?.services?.map(
              (services: any, j: number) =>
                services?.label === "Service" &&
                services?.active === true && (
                  <>
                    {props.body?.props?.RRFDetails?.ServiceChecklist?.map(
                      (e: any, i: number) =>
                        e?.active === true && (
                          <li key={i}>
                            <span>{e?.label}</span>
                          </li>
                        )
                    )}
                  </>
                )
            )}
            {props.body?.props?.RRFDetails?.services?.map(
              (services: any, j: number) =>
                services?.label === "Installation" &&
                services?.active === true && (
                  <>
                    {props.body?.props?.RRFDetails?.InstallChecklist?.map(
                      (e: any, i: number) =>
                        e?.active === true && (
                          <li key={i}>
                            <span>{e?.label}</span>
                          </li>
                        )
                    )}

                    {props.body?.props?.RRFDetails?.InstallChecklist?.[2]
                      ?.active !== true &&
                      props.body?.props?.RRFDetails?.WifiHubAppActivity?.map(
                        (e: any, i: number) =>
                          e?.active === true && (
                            <li key={i}>
                              <span>{e?.label}</span>
                            </li>
                          )
                      )}
                  </>
                )
            )}
          </>
        )}
      </div>
      <br />
      <br />
      <div>
        {
          "If you have not yet downloaded the Ignite HomeConnect app, please get started "
        }
        <a href="https://www.rogers.com/internet/ignite-wifi-hub">here</a>
        {
          " to easily manage, help protect and optimize your home WiFi network and connected devices. Plus, Click "
        }
        <a href="https://www.rogers.com/support/internet/all-about-wifi-quick-links-faqs-and-more">
          here
        </a>
        {" for tips on how to get your best WiFi and much more."}
      </div>
      <br />
      <div>
        {
          "Expert support is available 24/7 through the Ignite HomeConnect app* and "
        }
        <a href="https://www.rogers.com/support">Anna,</a>
        {" our Virtual Assistant, or you can "}
        <a href="https://www.rogers.com/contact/technical-support">contact</a>
        {
          " our technical support team at anytime for remote video assistance if needed. You can also visit "
        }
        <a href="https://www.rogers.com/consumer/profile/signin">MyRogers</a>
        {" at anytime for billing and account details. "}
      </div>
      <br />
      <br />
      <br />
      <div>{labels.RogersEmailThanks}</div>
      <br />
      <div>
        {
          "*Access to Ignite HomeConnect requires Ignite Internet from Rogers, the Ignite WiFi Gateway modem, a MyRogers account and a compatible iOS or Android device. "
        }
      </div>
      <br />
      <div>
        <i>
          {
            "This email is automatically generated, please do not reply. This e-mail (and any attachment(s)) is confidential; if you are not the intended recipient please delete it immediately without keeping a copy. "
          }
        </i>
      </div>
    </div>
  );
};

export default PrepareCECEmailFormat;
