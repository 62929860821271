import AppLabels from "../../constants/App_Labels";
import ConfigConst from "../../constants/ConfigConst";
import { GetTelemetryProps } from "../../topology/util/reduxFunctions/getTopologyState";
import { getNumberorFloat, getTelemetryInfo } from "../reusable/NodeUtil";
import { NodeScoreCalculation } from "../certifications/NodeScoreCalculation";
// import { OdmNodeScoreCalculation } from "../certifications/OdmNodeScoreCalculation";

export default function NodeViewData(props: any) {
  const { currNodedata, nodeTeleStatus, mlData, diagStartTime, diagEndTime } =
    props;
  const labels = AppLabels();
  //const {  } = GetTelemetryProps();
  const modemListInfo = getTelemetryInfo(mlData);
  const isDataAvail = !(
    nodeTeleStatus === ConfigConst.complete.toLowerCase() ||
    nodeTeleStatus === ConfigConst.stopped.toLowerCase()
  );

  let nodeScore;
  if (
    nodeTeleStatus != undefined &&
    (nodeTeleStatus === ConfigConst.complete ||
      nodeTeleStatus === ConfigConst.stopped)
  ) {
    nodeScore = JSON.parse(JSON.stringify(NodeScoreCalculation({ mlData })));
  }
  // const odm3ScoreObj = JSON.parse(
  //   JSON.stringify(OdmNodeScoreCalculation({ mlData }))
  // );
  // let odmScore;
  // if (
  //   nodeTeleStatus != undefined &&
  //   (nodeTeleStatus === ConfigConst.complete ||
  //     nodeTeleStatus === ConfigConst.stopped)
  // ) {
  //   odmScore = {
  //     failedCM: mlData ? odm3ScoreObj?.odm3FailCount + "/" + modemListInfo?.assigned : "",
  //     odm3Score: mlData ? getNumberorFloat(odm3ScoreObj?.odm3Score) : "",
  //     odm3USScore: mlData ? getNumberorFloat(odm3ScoreObj?.odm3USScore) : "",
  //     odm3DSScore: mlData ? getNumberorFloat(odm3ScoreObj?.odm3DSScore) : "",
  //     odm3OFDMAScore: mlData ? getNumberorFloat(odm3ScoreObj?.odm3OFDMAScore) : "",
  //     odm3OFDMScore: mlData ? getNumberorFloat(odm3ScoreObj?.odm3OFDMScore) : "",
  //   };;
  // }
  const renderServiceAreas = () => {
    if (currNodedata && currNodedata?.serviceAreas) {
      let serviceAreas = currNodedata?.serviceAreas?.split(", ");
      serviceAreas?.unshift(labels.ALL?.toUpperCase());
      return serviceAreas;
    }
    return null;
  };

  const relatedSMTs: any = () => {
    return currNodedata?.serviceAreas === null ||
      currNodedata?.serviceAreas?.length === 0
      ? []
      : currNodedata?.serviceAreas
        ?.split(", ")
        ?.filter((smt: string) => !smt.includes(currNodedata?.smtId));
  };

  const usPeers = () => {
    return currNodedata?.usAssociatedNodes === null ||
      currNodedata?.usAssociatedNodes?.length === 0
      ? []
      : currNodedata?.usAssociatedNodes;
  };

  const data = {
    summary: [
      // return an array with 1 value
      {
        label: labels.PHUB,
        dataKey: currNodedata?.phub, // phub chips
      },
      {
        label: currNodedata?.smt ? labels.NODE_ID : labels.SMT,
        dataKey: currNodedata?.smt
          ? currNodedata?.nodeId
          : renderServiceAreas(),
      },
      {
        label: labels.RELATED_SMTS,
        dataKey: relatedSMTs(),
      },
      //US Peers disabled for Dec7th release
      // {
      //   label: labels.US_PEERS,
      //   dataKey: usPeers(),
      // },
      {
        label: labels.CMTS_IP,
        dataKey: currNodedata?.cmtsIp, // phub chips
      },
      {
        label: labels.CMTS_NAME,
        dataKey: currNodedata?.cmtsName, // phub chips
      },
      {
        label: labels.MAC_DOMAIN,
        dataKey: currNodedata?.macDomain, // phub chips
      },
      {
        label: labels.US_PORT,
        dataKey: currNodedata?.usPort, // phub chips
      },
    ],
    scores: [
      // return an array with 1 value
      {
        label: labels.Overall,
        dataKey: [nodeScore?.odm?.normalPercent],
      },
      {
        label: labels.US,
        dataKey: [nodeScore?.odmUS?.normalPercent],
      },
      {
        label: labels.DS,
        dataKey: [nodeScore?.odmDS?.normalPercent],
      },
      // return an array with 3 values
      {
        label: labels.Breakdown,
        dataKey: [
          nodeScore?.odm?.normalPercent,
          nodeScore?.odm?.warningPercent,
          nodeScore?.odm?.criticalPercent,
        ],
      },
    ],
    // odmScores: odmScore,
    status: [
      {
        label: labels.ACTIVE + " / " + labels.ASSIGNED,
        dataKey: isDataAvail
          ? ""
          : modemListInfo?.active + "% / " + modemListInfo?.assigned,
      },
      {
        label: labels.RESPONSIVE + " / " + labels.UNRESPONSIVE,
        dataKey: isDataAvail
          ? ""
          : modemListInfo?.responsive + " / " + modemListInfo?.unresponsive,
      },
      {
        label: labels.START,
        dataKey: diagStartTime,
      },
      {
        label: labels.END,
        dataKey: diagEndTime,
      },
    ],
  };
  return data;
}
