import STBBasicDetails from "./STBBasicDetails";
import STBTeleTab from "./STBTeleTab";

interface Props {
  screenType: string;
  reloadNodeInfo: any;
  macAddress: any;
}

const STBPageDetails = ({ screenType, macAddress, reloadNodeInfo }: Props) => {
  return (
    <div>
      <STBBasicDetails macAddress={macAddress} screenType={screenType} />
      <STBTeleTab screenType={screenType} reloadNodeInfo={reloadNodeInfo} />
    </div>
  );
};

export default STBPageDetails;
