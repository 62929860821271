import { Button } from "react-bootstrap";
import { RiRadioButtonFill } from "react-icons/ri";
import { CgRadioCheck } from "react-icons/cg";

const Rating = (props: any) => {
  var stepViews = [];
  for (let i = 1; i <= props.totalRating; i++) {
    stepViews.push(
      <div key={i} className="item-container">
        <Button
          bsPrefix="icon-monthly-rating"
          onClick={() => {
            props.onRatingClick(i);
          }}
        >
          {props.rating >= i ? (
            <RiRadioButtonFill className="radio" />
          ) : (
            <CgRadioCheck className="radio" />
          )}
        </Button>
        <p className="text-monthly-rating">{i}</p>
      </div>
    );
  }

  return <div className="ratingRadioGrpContainer">{stepViews}</div>;
};

export default Rating;
