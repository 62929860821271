import Card from "@material-ui/core/Card";
import Tooltip from "@material-ui/core/Tooltip";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import RecoEngineClient from "../../network/httpclient/troubleshoot/RecoEngineClient";
import RecoLegendClient from "../../network/httpclient/troubleshoot/RecoLegendClient";
import { withStyles } from "@material-ui/core/styles";
import DiagContainer from "../reusable/DiagContainer";
import ExceptionBox from "../reusable/ExceptionBox";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "#000000",
    boxShadow: theme.shadows[4],
    fontSize: 12,
  },
}))(Tooltip);

const OverViewTS = (props: any) => {
  const { macAddress } = props;
  const dispatch = useDispatch();

  const {
    recoEngineInfo,
    recoEngineLoading,
    recoEngineError,
    recoLegendInfo,
    accData,
  } = useSelector(
    (state: any) => ({
      recoEngineInfo: state.AccountState.RecoEngineInfo.data,
      recoEngineLoading: state.AccountState.RecoEngineInfo.isLoading,
      recoEngineError: state.AccountState.RecoEngineInfo.error,
      recoLegendInfo: state.AccountState.RecoLegendInfo.data,
      accData: state.AccountState.ActiveAccountDetails.data,
    }),
    shallowEqual
  );
  let brandName = accData?.brand !== undefined ? accData?.brand : "";
  const getRecoData = () => {
    dispatch(RecoEngineClient.getRecoEngine(macAddress, brandName));
    dispatch(RecoLegendClient.getRecoLegend());
  };

  const getLegendInfo = (key: any) => {
    if (recoLegendInfo && Object.keys(recoLegendInfo)?.length > 0) {
      return recoLegendInfo[key];
    }
    return "Legend not available";
  };
  return (
    <>
      <div className="recoMainDiv">
        <Card className="troubleShootCardOne">
          <header>RECO ENGINE</header>
          <span className="noteText">
            <b>Note</b>:This is historical data (results display after 7 days
            for provisioned or swapped modem) and do not reflect changes in real
            time.
          </span>
          <p className="recoBtnCont">
            <button
              className="blueHollowBtn"
              onClick={() => getRecoData()}
              disabled={recoEngineLoading}
            >
              GET RECO ENGINE RESULTS
            </button>
          </p>
          <DiagContainer
            isLoading={recoEngineLoading}
            data={recoEngineInfo}
            error={recoEngineError}
            sectionName="Reco Engine"
            handleRefresh={getRecoData}
          >
            {!recoEngineInfo || Object.keys(recoEngineInfo)?.length === 0 ? (
              <div className="diagErrorCont">
                <ExceptionBox
                  headerText="No Data to display"
                  bodyText="Please try refreshing or contact the Tech Insight admin if data is missing."
                  primaryBtn={false}
                  secBtn={false}
                />
              </div>
            ) : (
              <table className="troubleshootDetails">
                <tr className="recoHeader ">
                  <th className="recoBorder">TEST</th>
                  <th>RESULT</th>
                </tr>
                <tbody className="recoTBody">
                  {recoEngineInfo ? (
                    Object.keys(recoEngineInfo)?.map((key: any, i: number) => {
                      return (
                        <tr className="recoTestTr">
                          <td className="recoTestTdFC">
                            {recoLegendInfo ? (
                              <LightTooltip
                                className="legendToolTip"
                                title={getLegendInfo(key)}
                              >
                                <span>{key}</span>
                              </LightTooltip>
                            ) : (
                              <>
                                <span className="noLegends">{key}</span>
                              </>
                            )}
                          </td>
                          {recoEngineInfo[key] ? (
                            <>
                              <td className="recoTestTdSC">
                                {recoEngineInfo[key]}
                              </td>
                            </>
                          ) : (
                            <>No data</>
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            )}
          </DiagContainer>
        </Card>
      </div>
    </>
  );
};

export default OverViewTS;
