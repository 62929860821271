import CMSummary from "../cable-modem/CmSummary";
import CmDownStreamCh from "../cable-modem/downstream/CmDownStreamCh";
import CmUpStreamCh from "../cable-modem/upstream/CmUpStreamCh";
import CertiExceptionCont from "../certification/CertiExceptionCont";
import CustomAccordion from "../reusable/CustomAccordion";
import LoaderGif from "../../resources/images/loader.webp";
import NeighPartialService from "./NeighPartialService";
import CMFlapsDetails from "../cable-modem/CMFlapsDetails";

const NeighFullDiagView = (props: any) => {
  const { summayData, docsisData, flapsData } = props;

  return (
    <div className="cmSummaryCont">
      {!summayData?.isLoading ||
      !docsisData?.isLoading ||
      !flapsData?.isLoading ? (
        <>
          <CertiExceptionCont
            dataObj={docsisData?.data}
            sectionName="CM Docsis"
          >
            <CmDownStreamCh cmDocsisInfo={docsisData?.data} />
            <CmUpStreamCh cmDocsisInfo={docsisData?.data} />
            <CustomAccordion
              accTitle={"PARTIAL SERVICE"}
              accBodyElement={
                <NeighPartialService
                  partialServiceData={docsisData?.data?.partialService} ofdmDownStreamChannelsData={docsisData?.data?.ofdm?.downstreamChannels}
                />
              }
            />
          </CertiExceptionCont>
          <CertiExceptionCont
            dataObj={flapsData?.data}
            sectionName="CM Flaps Details"
          >
            <CustomAccordion
              accTitle={"Flaps Details".toUpperCase()}
              accBodyElement={<CMFlapsDetails flapsInfo={flapsData?.data} />}
            />
          </CertiExceptionCont>
          <CertiExceptionCont
            dataObj={summayData?.data}
            sectionName="CM Summary"
          >
            <CMSummary cmSummarydata={summayData?.data} />
          </CertiExceptionCont>
        </>
      ) : (
        <div className="neighLoaderCont">
          <img style={{ width: "20%" }} src={LoaderGif} />
        </div>
      )}
    </div>
  );
};

export default NeighFullDiagView;
