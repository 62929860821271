import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import ErrorBox from "../../components/reusable/ErrorBox";
import { getMultiTeleONTViewConfig } from "../../config/MultiONTConfig";
import AppLabels from "../../constants/App_Labels";
import "../../design-tokens/pingPage/pingPage.scss";
import { GetListONT } from "../../get-api/GetResponse";
import LoaderGif from "../../resources/images/loader.webp";
import { saveConfigData } from "../../store/ducksPattern/userPrefConfig";
import { getMultipleONTData } from "../../store/slices/ping/multiDeviceSearchONT";
import storeConst from "../../topology/store/storeConst";
import MultiDeviceONTTeleTab from "../ping-page/MultiDeviceONTTeleTab";

const MultiDeviceSearchONTPage = (props: any) => {
  const { location: search } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const labels = AppLabels();
  const [dataPing, setPingData] = useState(null);
  const isONTSearch = window.location.hash.includes("searchByONTSN");
  const listONT = GetListONT();
  const [responses, setResponses] = useState<string[]>([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [formattedData, setFormattedData] = useState("");
  const [statistics, setStatistics] = useState({});
  const [pingResponses, setPingResponses] = useState<string>("");
  const [statsObj, setStatsObj] = useState<any | null>(null);
  const [searchONTValue, setsearchONTValue] = useState<string | null>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (listONT?.status === storeConst.ERROR && listONT?.data === undefined) {
      setErrorMsg(labels.nodeNAErr);
      setOpen(true);
    } else if (
      listONT?.status === "OK" &&
      JSON.stringify(listONT?.data) === '[""]'
    ) {
      setErrorMsg(labels.nodeNAErr);
      setOpen(true);
    }
  }, [listONT?.status]);
  useEffect(() => {
    let viewConfig = getMultiTeleONTViewConfig();
    dispatch(saveConfigData(viewConfig));
  }, []);

  useEffect(() => {
    const handleReceiveMessage = (event: MessageEvent) => {
      if (event.origin !== window.location.origin) return;

      if (event.data && event.data.searchONTValue && isONTSearch)
        setsearchONTValue(event.data.searchONTValue);
    };

    window.addEventListener("message", handleReceiveMessage);
    return () => {
      window.removeEventListener("message", handleReceiveMessage);
    };
  }, []);
  useEffect(() => {
    if (searchONTValue != null && isONTSearch) {
      dispatch(getMultipleONTData(searchONTValue));
    }
  }, [searchONTValue]);

  return (
    <>
      <div>
        <div>
          {listONT?.data != undefined ? (
            <>
              <>
                <section className="InputCont">
                  {/* <div className="SearchCont">
                    <div className="SeleCont"> */}
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label style={{ fontSize: "24px", fontWeight: "bold" }}>
                      Search By ONT Serial Number
                    </label>
                    <label style={{ fontSize: "18px", fontWeight: 600 }}>
                      NO. OF ONT: {listONT?.data?.length}
                    </label>
                    {/* </div>
                    </div> */}
                  </div>
                </section>
                <div>
                  <MultiDeviceONTTeleTab />
                </div>
              </>
            </>
          ) : listONT?.status === storeConst.ERROR ? (
            <div>
              <ErrorBox
                open={open}
                error={errorMsg}
                setOpen={setOpen}
                handleClose={() => {
                  setOpen(false);
                  history.replace(`/`);
                }}
              />
            </div>
          ) : (
            <img className="loaderImg" src={LoaderGif} />
          )}
        </div>
      </div>
    </>
  );
};
export default MultiDeviceSearchONTPage;
