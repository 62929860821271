export const SAVE_ACTIVE_DIAG_DEVICES = "SAVE_ACTIVE_DIAG_DEVICES";
export const UPDATE_ACTIVE_DIAG_DEVICES = "UPDATE_ACTIVE_DIAG_DEVICES";
export const RESET_ACTIVE_DIAG_FAILURE = "RESET_ACTIVE_DIAG_FAILURE";
export const START_LOADING_DIAG_DEVICES = "START_LOADING_DIAG_DEVICES";
export const INCREMENT_DIAG_API_CALL_COUNTER =
  "INCREMENT_DIAG_API_CALL_COUNTER";
export const DECREMENT_DIAG_API_CALL_COUNTER =
  "DECREMENT_DIAG_API_CALL_COUNTER";
interface StartLoadingDiagDevices {
  type: typeof START_LOADING_DIAG_DEVICES;
}
interface SaveActiveDiagDevices {
  type: typeof SAVE_ACTIVE_DIAG_DEVICES;
  payload: any;
}
interface UpdateActiveDiagDevices {
  type: typeof UPDATE_ACTIVE_DIAG_DEVICES;
  devicekey: string;
  subdataKey: string;
  data: any;
}
interface ResetActiveDiagDevices {
  type: typeof RESET_ACTIVE_DIAG_FAILURE;
}
interface IncrementDiagApiCallCounter {
  type: typeof INCREMENT_DIAG_API_CALL_COUNTER;
  devicekey: string;
}
interface DecrementDiagApiCallCounter {
  type: typeof DECREMENT_DIAG_API_CALL_COUNTER;
  devicekey: string;
}
export type ActiveDiagDevicesActionTypes =
  | SaveActiveDiagDevices
  | StartLoadingDiagDevices
  | UpdateActiveDiagDevices
  | ResetActiveDiagDevices
  | IncrementDiagApiCallCounter
  | DecrementDiagApiCallCounter;
