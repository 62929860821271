import { useState } from "react";
import { Paper } from "@material-ui/core";
import { useDispatch } from "react-redux";

import "../../../design-tokens/rpd-page/RPDPage.scss";
import AppLabels from "../../../constants/App_Labels";
import RPDClient from "../../../network/httpclient/rpd/RPDClient";
import ErrorBox from "../../reusable/ErrorBox";
import { TAF_ORDER_CONST } from "../../../constants/Global";
import { setMacAddress } from "../../../store/ducksPattern/rpd/rpdDecomm";
import EmailEntry from "../../reusable/EmailEntry";
import { formathexMacAddress } from "../../reusable/Util";

const RPDDecommDetails = (props: any) => {
  const { macAdd, rpdDecommData, isLoading, techData } = props;
  const [isSWUpgradeLoading, setIsSWUpgradeLoading] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [emails, setEmails] = useState([
    techData?.lanId !== null ? techData?.lanId : "",
  ]);

  const [open, setOpen] = useState(false);
  const [error, setError] = useState("This is Error Message");
  const dispatch = useDispatch();
  const labels = AppLabels();

  const handleClose = () => {
    setOpen(false);
  };

  const createInputSWUpgradeObject = (mailId: string, mac: string): any => {
    let inputObject = {
      "MAIL-ID": mailId,
      mac_address: formathexMacAddress(mac),
      macDomain: rpdDecommData?.macRPDData?.macDomain,
      port: rpdDecommData?.macRPDData?.cmtsPort,
      ip: "",
      hostname: rpdDecommData?.macRPDData?.cmtsName,
      is_npt_id: "0",
    };

    return inputObject;
  };

  const handleSubmit = () => {
    if (emails[0] !== "") {
      setIsSWUpgradeLoading(true);
      dispatch(
        RPDClient.createDecomm(
          TAF_ORDER_CONST.rpdDecommBotName,
          TAF_ORDER_CONST.rpdDecommSuiteName,
          createInputSWUpgradeObject(emails.toString(), macAdd)
        )
      );
      dispatch(setMacAddress(undefined));
    } else {
      setOpen(true);
      setError(labels.sendEmail);
    }
  };

  return (
    <>
      {!isSWUpgradeLoading && (
        <div className="certiTableCont">
          <Paper className="deviceTablePaper">
            <div className="filterContainer">
              <div className="activationInputContainer">
                <div className="filterRow">
                  <div className="cardContentContainer">
                    <span className="cardContentLabel">
                      {labels.MacAddress}
                    </span>
                    <br />

                    <span className="cardContentText">
                      {rpdDecommData?.macRPDData?.macAddress}
                    </span>
                  </div>
                  <div className="cardContentContainer">
                    <span className="cardContentLabel">
                      {labels.clamshellIDTxt}
                    </span>
                    <br />
                    <span className="cardContentText">
                      {rpdDecommData?.macRPDData?.clamShell}
                    </span>
                  </div>
                  <div className="cardContentContainer">
                    <span className="cardContentLabel">
                      {labels.AccessLeaf}
                    </span>
                    <br />
                    <span className="cardContentText">
                      {rpdDecommData?.macRPDData?.accessLeaf}
                    </span>
                  </div>
                  <div className="cardContentContainer">
                    <span className="cardContentLabel">
                      {labels.AccessLeafPort}
                    </span>
                    <br />
                    <span className="cardContentText">
                      {rpdDecommData?.macRPDData?.accessLeafPort}
                    </span>
                  </div>
                  <div className="cardContentContainer">
                    <span className="cardContentLabel">
                      {labels.ServiceLeaf}
                    </span>
                    <br />
                    <span className="cardContentText">
                      {rpdDecommData?.macRPDData?.serviceLeaf}
                    </span>
                  </div>
                  <div className="cardContentContainer">
                    <span className="cardContentLabel">
                      {labels.ServiceLeafPort}
                    </span>
                    <br />
                    <span className="cardContentText">
                      {rpdDecommData?.macRPDData?.serviceLeafPort}
                    </span>
                  </div>
                </div>
              </div>
              <div className="activationInputContainer">
                <div className="filterRow">
                  <div className="cardContentContainer">
                    <span className="cardContentLabel">{labels.CMTSName}</span>
                    <br />
                    <span className="cardContentText">
                      {rpdDecommData?.macRPDData?.cmtsName}
                    </span>
                  </div>
                  <div className="cardContentContainer">
                    <span className="cardContentLabel">{labels.CMTSPort}</span>
                    <br />
                    <span className="cardContentText">
                      {rpdDecommData?.macRPDData?.cmtsPort}
                    </span>
                  </div>
                  <div className="cardContentContainer">
                    <span className="cardContentLabel">{labels.MacDomain}</span>
                    <br />
                    <span className="cardContentText">
                      {rpdDecommData?.macRPDData?.macDomain}
                    </span>
                  </div>
                </div>
              </div>

              <>
                <div className="subDataCard">
                  <span className="titleLabel">
                    {labels.returnSegmentDetails}
                  </span>
                </div>
                <div className="cardNodeInputContainer">
                  <div className="cardContentContainer">
                    <span className="cardContentLabel">{labels.nodeIDOne}</span>
                    <br />
                    {rpdDecommData?.macRPDData?.ReturnSegment_1 === null ||
                    rpdDecommData?.macRPDData?.ReturnSegment_1 === "" ||
                    rpdDecommData?.macRPDData?.ReturnSegment_1 === undefined ? (
                      <span className="cardContentText">N/A</span>
                    ) : (
                      <span className="cardContentText">
                        {rpdDecommData?.macRPDData?.ReturnSegment_1}
                      </span>
                    )}
                  </div>
                  <div className="cardContentContainer">
                    <span className="cardContentLabel">{labels.nodeIDTwo}</span>
                    <br />
                    {rpdDecommData?.macRPDData?.ReturnSegment_2 === null ||
                    rpdDecommData?.macRPDData?.ReturnSegment_2 === undefined ||
                    rpdDecommData?.macRPDData?.ReturnSegment_2 === "" ? (
                      <span className="cardContentText">N/A</span>
                    ) : (
                      <span className="cardContentText">
                        {rpdDecommData?.macRPDData?.ReturnSegment_2}
                      </span>
                    )}
                  </div>
                </div>
              </>
            </div>

            <div className="activationInputContainer">
              <div className="filterRow">
                <div className="inputContainer">
                  <EmailEntry
                    title={labels.rpdEmail}
                    email={emails}
                    setEmail={setEmails}
                    setIsEmailValid={setIsEmailValid}
                    titleStyle={{
                      fontSize: "12px",
                      fontWeight: 500,
                      margin: "0px",
                      color: "#000",
                    }}
                    buttonStyle={{
                      fontSize: "12px",
                      background: "none",
                      border: "none",
                      padding: "0px",
                      color: "#da291c",
                    }}
                    emailInputStyle={{
                      fontSize: "12px",
                      color: "#000",
                      paddingLeft: "5px;",
                      fontWeight: 500,
                      borderRadius: "5px",
                      border: "1px solid #808284",
                    }}
                  />

                  <button
                    disabled={isLoading}
                    className="applyFilersBtn"
                    onClick={() => handleSubmit()}
                  >
                    {labels.SUBMIT}
                  </button>
                </div>
              </div>
            </div>
            <br />
          </Paper>
          <ErrorBox
            open={open}
            error={error}
            setOpen={setOpen}
            handleClose={handleClose}
          />
        </div>
      )}
    </>
  );
};

export default RPDDecommDetails;
