import { shallowEqual, useDispatch, useSelector } from "react-redux";
import DiagContainer from "../reusable/DiagContainer";
import { isNull, breakLongStrings } from "../reusable/Util";
import ReloadData from "../reusable/ReloadData";
import ExceptionBox from "../reusable/ExceptionBox";
import CustomAccordion from "../reusable/CustomAccordion";
import IHPClient from "../../network/httpclient/ihp/IHPClient";

const RHPPKTCableEvents = (props: any) => {
  const { selectedDevice, ihpLoading, pceEventInfo, pceEventStatus } = props;
  const dispatch = useDispatch();
  const refreshData = () => {
    if (selectedDevice.deviceCategory !== "IEMTA") {
      dispatch(
        IHPClient.getPCEventLog(
          selectedDevice?.serialNumber,
          selectedDevice?.macaddress,
          selectedDevice?.atamac
        )
      );
    } else {
      dispatch(
        IHPClient.getPCEventLog(
          selectedDevice.serialNumber,
          selectedDevice?.cmmac,
          selectedDevice?.atamac
        )
      );
    }
  };

  return (
    <>
      {" "}
      <div className="refreshCont">
        <ReloadData refreshData={refreshData} />
      </div>
      <div className="cmSummaryCont">
        <DiagContainer
          isLoading={ihpLoading}
          data={pceEventInfo}
          sectionName="Packet Cable Events"
          error={pceEventStatus}
          handleRefresh={() => refreshData()}
        >
          {pceEventInfo !== undefined ? (
            <div className="docsisEventCont">
              <table className="dataTableDetails">
                {pceEventInfo?.length !== 0 ? (
                  <>
                    <thead>
                      <th className="smallCol">{"Time".toUpperCase()}</th>
                      <th className="smallCol">{"Level".toUpperCase()}</th>
                      <th>{"Description".toUpperCase()}</th>
                      <th>{"End Point".toUpperCase()}</th>
                    </thead>
                    <tbody>
                      {pceEventInfo?.map((eventLog: any, index: number) => {
                        return (
                          <tr key={"pce" + index * index + 56}>
                            <td>{isNull(eventLog?.time)}</td>
                            <td>{isNull(eventLog?.level)}</td>
                            <td>
                              {isNull(
                                breakLongStrings(eventLog?.description, ";")
                              )}
                            </td>
                            <td>
                              {isNull(
                                breakLongStrings(eventLog?.endpoint, ";")
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </>
                ) : (
                  <div className="diagErrorCont">
                    <ExceptionBox
                      headerText={
                        selectedDevice.deviceCategory !== "IEMTA"
                          ? "No Data to display in Packet Cable Events."
                          : "No Data to display in SIP Events."
                      }
                      bodyText="Please try refreshing."
                      primaryBtn={false}
                      secBtn={false}
                    />
                  </div>
                )}
              </table>
            </div>
          ) : (
            <></>
          )}
        </DiagContainer>
      </div>
    </>
  );
};

export default RHPPKTCableEvents;
