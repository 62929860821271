import { IptvNetwork } from "../../../../models/account/diagnostics/iptv/IptvNetwork";
import {
  IPTV_CERTI_NETWORK_BEGIN,
  IPTV_CERTI_NETWORK_SUCCESS,
  IPTV_CERTI_NETWORK_FAILURE,
  IptvCertiNetworkActionTypes,
} from "../../../../types/account/certifications/iptv/IPTVCertiNetwork";

export function IptvCertiNetworkBegin(): IptvCertiNetworkActionTypes {
  return {
    type: IPTV_CERTI_NETWORK_BEGIN,
  };
}

export function IptvCertiNetworkSuccess(
  IptvCertiNetwork: IptvNetwork
): IptvCertiNetworkActionTypes {
  return {
    type: IPTV_CERTI_NETWORK_SUCCESS,
    payload: IptvCertiNetwork,
  };
}

export function IptvCertiNetworkFailure(
  error: Error
): IptvCertiNetworkActionTypes {
  return {
    type: IPTV_CERTI_NETWORK_FAILURE,
    payload: error,
  };
}
