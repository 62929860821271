import { useState } from "react";
import "../../../styles/drawer/elements/ElementSearch.scss";
import CustomAccordion from "../../../../components/reusable/CustomAccordion";
import TopologyData from "../../../data/TopologyData";
import appConst from "../../../constants/appConst";
import RENSearch from "./RENSearch";
import EleAddressSearch from "./EleAddressSearch";
import Coordinates from "./Coordinates";
import VhubSearch from "./VhubSearch";
import TopologyLabels from "../../../constants/topologyLabels";
import CustomDropDown from "../../../../components/reusable/CustomDropDown";
import { Divider } from "@material-ui/core";
import WirelessSiteSearch from "./WirelessSiteSearch";
import SmallCellSearch from "./SmallCellSearch";
import ClamshellSearch from "./ClamshellSearch";
import RbCircuitSearch from "./RbCircuitSearch";
import OLTSearch from "./OLTSearch";

const MapElementSearch = () => {
  const topoLabels = TopologyLabels();
  const { elementSearchTypes } = TopologyData();
  const [eleSearchType, setEleSearchType] = useState(
    elementSearchTypes[0]?.label
  );
  const [activeEleTabIndex, setActiveEleTabIndex] = useState(0);

  const elementTabs = [
    {
      elementKey: appConst.rensNum,
      compToRend: <RENSearch />,
    },
    {
      elementKey: appConst.address,
      compToRend: <EleAddressSearch />,
    },
    {
      elementKey: appConst.searchCoordinates,
      compToRend: <Coordinates />,
    },
    {
      elementKey: appConst.vhub,
      compToRend: <VhubSearch />,
    },
    /* 
    {
      elementKey: appConst.ponNode,
      compToRend: <VhubSearch />,
    }, */
    {
      elementKey: appConst.wirelessSite,
      compToRend: <WirelessSiteSearch />,
    },
    {
      elementKey: appConst.smallCell,
      compToRend: <SmallCellSearch />,
    },
    {
      elementKey: appConst.clamshell,
      compToRend: <ClamshellSearch />,
    },
    {
      elementKey: appConst.rbCircuit,
      compToRend: <RbCircuitSearch />,
    },
    {
      elementKey: appConst.olt,
      compToRend: <OLTSearch />,
    },
  ];

  const changeEleSearchType = (val: string) => {
    const selectedEleSearchTypeIndex = elementSearchTypes?.findIndex(
      (sType: any) => sType?.label === val
    );
    if (eleSearchType !== val && selectedEleSearchTypeIndex !== -1) {
      setEleSearchType(val);
      setActiveEleTabIndex(selectedEleSearchTypeIndex);
    }
  };

  return (
    <>
      <CustomAccordion
        accTitle={topoLabels.locator.toUpperCase()}
        accBodyElement={
          <div className="elementSearchDdCont">
            <div className="elementSearchDropDown">
              <span>{topoLabels.type.toUpperCase()}:</span>
              <CustomDropDown
                ddTitle=""
                selectedOption={eleSearchType}
                options={elementSearchTypes?.map((type: any) => type?.label)}
                searchFlag={false}
                checkboxFlag={false}
                setSelectedOption={(value: any) => {
                  changeEleSearchType(value);
                }}
              />
            </div>
            <Divider style={{ marginTop: "5px" }} />
            <section className="elementSearchInput">
              {elementTabs?.[activeEleTabIndex]?.compToRend}
            </section>
          </div>
        }
      />
    </>
  );
};

export default MapElementSearch;
