import { secToTime } from "../reusable/Util";
import { isNull } from "../reusable/Util";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import AppLabels from "../../constants/App_Labels";
import CustomAccordion from "../reusable/CustomAccordion";
import ReloadData from "../reusable/ReloadData";
import xleVisiblilityClient from "../../network/httpclient/accountInfo/xleVisiblilityClient";
import DiagContainer from "../reusable/DiagContainer";
import { Card } from "react-bootstrap";
import ExceptionBox from "../reusable/ExceptionBox";
const XLEGWInfo = (props: any) => {
  const { macAddress } = props;
  const labels = AppLabels();
  const dispatch = useDispatch();
       
const {  gwSummarydata,gwLoading,status,gwDeviceError} = useSelector(
    (state: any) => ({
        gwSummarydata: state.AccountState?.xleGW?.data,
        gwLoading: state.AccountState?.xleGW?.isLoading,
        status: state.AccountState?.xleGW?.status,
        gwDeviceError: state.AccountState?.xleGW?.error,
    }),
    shallowEqual
  );

  const refreshData = () => {
    dispatch(
        xleVisiblilityClient.getXleGateway(
          macAddress              
        )
      );

  };
  return (
    <><div className="refreshCont">
          <ReloadData refreshData={refreshData} />
      </div>
      
      <DiagContainer
        isLoading={gwLoading}
        data={gwSummarydata}
        sectionName="GW Status"
        error={gwDeviceError}
        handleRefresh={() => refreshData()}
      >
        {gwSummarydata !== undefined &&status!=="ERROR" ? (
          <div className="gwDevicesCont">
            <Card className="dataCard">
              <Card.Body>
                <table className="dataTableDetails">
                {status!=="ERROR"? (
                  <>
                      <thead>
                          <tr>
                              <th id="nameCol">{labels.NAME.toUpperCase()}</th>
                              <th id="valueCol">{labels.VALUE.toUpperCase()}</th>
                          </tr>
                      </thead>
                      <tbody>
                      {(gwSummarydata?.[0]?.model_name &&
                          <tr>
                              <td>{labels.MODEL.toUpperCase()}</td>
                              <td>{isNull(gwSummarydata?.[0]?.model_name)}</td>
                          </tr>
                      )}
                          <tr>
                              <td>{labels.MAC.toUpperCase()}</td>
                              <td>{isNull(macAddress)}</td>
                          </tr>
                          {(gwSummarydata?.[0]?.home_connection_interface &&
                          <tr>
                              <td>{"GW connection type".toUpperCase()}</td>
                              <td>{isNull(gwSummarydata?.[0]?.home_connection_interface)}</td>
                          </tr>
                          )}
                          {(gwSummarydata?.[0]?.reboot_reason &&
                          <tr>
                              <td>{"Reboot Reason".toUpperCase()}</td>
                              <td>{isNull(gwSummarydata?.[0]?.reboot_reason)}</td>
                          </tr>
                          )}
                          {(gwSummarydata?.[0]?.backup_cpe_activation_status &&
                          <tr>
                              <td>{"XLE Activation Status".toUpperCase()}</td>
                              <td>{isNull(gwSummarydata?.[0]?.backup_cpe_activation_status)}</td>
                          </tr>
                          )}
                          
                          {(gwSummarydata?.[0]?.backup_cpe_state &&
                          <tr>
                              <td>{"XLE State".toUpperCase()}</td>
                              <td>{isNull(gwSummarydata?.[0]?.backup_cpe_state)}</td>
                          </tr> 
                          )}
                          {(gwSummarydata?.[0]?.vendor &&
                          <tr>
                              <td>{"Vendor".toUpperCase()}</td>
                              <td>{isNull(gwSummarydata?.[0]?.vendor)}</td>
                          </tr>
                          )}
                          {(gwSummarydata?.[0]?.scenario &&
                          <tr>
                              <td>{"scenario".toUpperCase()}</td>
                              <td>{isNull(gwSummarydata?.[0]?.scenario)}</td>
                          </tr>
                          )}
                          {(gwSummarydata?.[0]?.cpe_state &&
                          <tr>
                              <td>{"cpe state".toUpperCase()}</td>
                              <td>{isNull(gwSummarydata?.[0]?.cpe_state)}</td>
                          </tr>
                          )}
                          {(gwSummarydata?.[0]?.system_ready_time &&
                          <tr>
                              <td>{"system ready time".toUpperCase()}</td>
                              <td>{isNull(gwSummarydata?.[0]?.system_ready_time)}</td>
                          </tr>
                          )}
                          {(gwSummarydata?.[0]?.status &&
                          <tr>
                              <td>{"status".toUpperCase()}</td>
                              <td>{isNull(gwSummarydata?.[0]?.status)}</td>
                          </tr>
                          )}
                          {(gwSummarydata?.[0]?.firmware_download_priority &&
                          <tr>
                              <td>{"firmware Download Priority".toUpperCase()}</td>
                              <td>{isNull(gwSummarydata?.[0]?.firmware_download_priority)}</td>
                          </tr>
                          )}
                          {gwSummarydata?.[0]?.firmware_name && (
                          <tr>
                              <td>{"FIRMWARE NAME"}</td>
                              <td>
                                
                                     { isNull(gwSummarydata?.[0]?.firmware_name)}
                                 
                              </td>
                          </tr>)}
                          {(gwSummarydata?.[0]?.firmware_download_status &&
                          <tr>
                              <td>{"firmware download status".toUpperCase()}</td>
                              <td>{isNull(gwSummarydata?.[0]?.firmware_download_status)}</td>
                          </tr>
                          )}
                          {(gwSummarydata?.[0]?.firmware_downloaded_name &&
                          <tr>
                              <td>{"firmware downloaded name".toUpperCase()}</td>
                              <td>{isNull(gwSummarydata?.[0]?.firmware_downloaded_name)}</td>
                          </tr>
                          )}
                          </tbody>
                    </>
                   ) : (
                    <div className="diagErrorCont">
                      <ExceptionBox
                        headerText="No Data to display in GW Status."
                        bodyText="Please try refreshing or contact the Tech Insight admin if data is missing."
                        primaryBtn={true}
                        secBtn={false}
                        handleRefresh={() => refreshData()}
                      />
                    </div>
                     )}
              </table>
</Card.Body>
</Card>
</div>
) : (
    <ExceptionBox
    headerText="No Data to display in GW Status."
    bodyText="Please try refreshing or contact the Tech Insight admin if data is missing."
    primaryBtn={true}
    secBtn={false}
    handleRefresh={() => refreshData()}
  />
)}
      </DiagContainer>
    </>
  );
};
export default XLEGWInfo;
