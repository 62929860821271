import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../middleware/apiActions";
import { RB_CIRCUIT_SEARCH_URI } from "../../middleware/apiEndpoints";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "rbCircuitSearch",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    getRbCircuitSearchBegin: (rbc) => {
      rbc.isLoading = true;
      rbc.status = "";
    },
    getRbCircuitSearchSuccess: (rbc, { payload }) => {
      rbc.isLoading = false;
      rbc.data = payload;
      rbc.status = storeConst.OK;
    },
    getRbCircuitSearchFailure: (rbc, { payload }) => {
      rbc.isLoading = false;
      rbc.error = payload;
      rbc.data = undefined;
      rbc.status = storeConst.ERROR;
    },
    resetRbCircuit: (rbc) => {
      rbc.isLoading = false;
      rbc.data = undefined;
      rbc.error = undefined;
      rbc.status = "";
    },
  },
});

const {
  getRbCircuitSearchBegin,
  getRbCircuitSearchSuccess,
  getRbCircuitSearchFailure,
} = slice.actions;

export const { resetRbCircuit } = slice.actions;
export default slice.reducer;

export const getRbCircuitSearch =
  (searchedRbCircuit: string) => (dispatch: any) => {
    dispatch(
      apiCallBegan({
        url: RB_CIRCUIT_SEARCH_URI,
        params: { circuitName: searchedRbCircuit },
        onStart: getRbCircuitSearchBegin.type,
        onSuccess: getRbCircuitSearchSuccess.type,
        onError: getRbCircuitSearchFailure.type,
      })
    );
  };
