import { WIFIRadiosState } from "../../../../states/account/diagnostics/cm/WIFIRadiosState";

import {
  WIFI_RADIOS_BEGIN,
  WIFI_RADIOS_SUCCESS,
  WIFI_RADIOS_FAILURE,
  WIFIRadiosActionTypes,
} from "../../../../types/account/diagnostics/cm/WIFIRadios";

const initialState: WIFIRadiosState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function WIFIRadiosReducer(
  state = initialState,
  action: WIFIRadiosActionTypes
): WIFIRadiosState {
  switch (action.type) {
    case WIFI_RADIOS_BEGIN:
      return {
        isLoading: true,
      };
    case WIFI_RADIOS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case WIFI_RADIOS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}
