import CustomAccordion from "../reusable/CustomAccordion";
import CustomPopOver from "../reusable/CustomPopOver";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
const ViewCWUpstream = (props: any) => {
  const { codewords } = props;
  const { tracer } = useSelector(
    (state: any) => ({
      tracer: state.AccountState.cwMonitorInfo.tracer,
    }),
    shallowEqual
  );
  return (
    <>
      {codewords !== undefined ? (
        <CustomAccordion
          accTitle={"Upstream SC-QAM Channels".toUpperCase()}
          accBodyElement={
            <>
              <table className="dataTableDetails">
                <thead>
                  <tr>
                    <th>{"frequency".toUpperCase()}</th>
                    <CustomPopOver
                      position="top"
                      popOverData="Total uncorrectable codewords/total codewords prior to the current polling session"
                      originComp={<th>{"Baseline Errors".toUpperCase()}</th>}
                      index="popOver"
                    />
                    <CustomPopOver
                      position="top"
                      popOverData="Count of unerrored codewords passed during the interval"
                      originComp={<th>{"unerrored delta".toUpperCase()}</th>}
                      index="popOver"
                    />
                    <CustomPopOver
                      position="top"
                      popOverData="Count of errored codewords that have been corrected during the interval"
                      originComp={<th>{"corrected delta".toUpperCase()}</th>}
                      index="popOver"
                    />
                    <CustomPopOver
                      position="top"
                      popOverData="Count of errored codewords that were not corrected during the interval"
                      originComp={<th>{"uncorrectables delta".toUpperCase()}</th>}
                      index="popOver"
                    />
                  </tr>
                </thead>
                <tbody>
                  {codewords.map((ch: any, i: number) => {
                    return ch.name === "UPSTREAM_SCQAM_CHANNELS" && (
                      <tr key={i}>
                        <td>{ch?.frequency}</td>
                        <td>{ch?.baselineError}</td>
                        <td>{ch?.unerroreds_delta}</td>
                        <td>{ch?.corrected_delta}</td>
                        {ch?.uncorrectables > 0 ? (
                          <td className="failState">
                            {ch?.uncorrectables} {tracer ? "@20s" : "@60s"}
                          </td>
                        ) : (
                          <td>{ch?.uncorrectables}</td>
                        )}
                      </tr>
                    )
                  }
                  )}
                </tbody>
              </table>
            </>
          }
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ViewCWUpstream;
