import Divider from "@material-ui/core/Divider";
import React, { useEffect, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { BiFullscreen, BiExitFullscreen, BiMenuAltRight } from "react-icons/bi";

import "../../design-tokens/ReusableComp.scss";
import ConfigConst from "../../constants/ConfigConst";
import { useWindowSize } from "./Util";
import { FaAddressCard } from "react-icons/fa";
import { DrawerViewType } from "../../topology/store/reduxTypes";
import { setDrawerView } from "../../topology/store/slices/mapDrawer";
import { useDispatch } from "react-redux";
import { GetMapDrawerProps } from "../../topology/util/reduxFunctions/getTopologyState";

interface Props {
  children: JSX.Element;
  content: JSX.Element;
  headerContent?: JSX.Element;
  hiddenComp?: JSX.Element;
  isHiddenOpen?: boolean;
  isDrawerAccessible?: boolean;
}

export default function Drawer({
  children,
  content,
  headerContent,
  hiddenComp,
  isHiddenOpen,
  isDrawerAccessible = true,
}: Props) {
  const dispatch = useDispatch();
  const { drawerView } = GetMapDrawerProps();
  const close = ConfigConst.close as DrawerViewType;
  const fullScreen = ConfigConst.fullScreen as DrawerViewType;
  const standard = ConfigConst.standard as DrawerViewType;

  const [winWidth, winHeight] = useWindowSize();
  const styles = activeStyles(drawerView);

  return (
    <div className="drawerContainer">
      <section className={styles.childrenCont}>{children}</section>
      {isDrawerAccessible && (
        <section
          className={drawerView === close ? "menuIconCont" : "closedContainer"}
        >
          <BiMenuAltRight onClick={() => dispatch(setDrawerView(standard))} />
        </section>
      )}
      <section className={styles.contentCont}>
        <div className="contentHeaderCont">
          <div className="leftIconsCont">
            <IoIosArrowForward
              onClick={() => dispatch(setDrawerView(close))}
              className="svg"
            />
            {headerContent}
            {winWidth > 1000 &&
              (drawerView !== fullScreen ? (
                <BiFullscreen
                  onClick={() => dispatch(setDrawerView(fullScreen))}
                  className="svg"
                />
              ) : (
                <BiExitFullscreen
                  onClick={() => dispatch(setDrawerView(standard))}
                  className="svg"
                />
              ))}
          </div>
        </div>
        <Divider />
        {content}
      </section>
      <section
        className={
          isHiddenOpen ? styles.hiddenContOpen : styles.hiddenContClose
        }
      >
        {hiddenComp}
      </section>
    </div>
  );
}

const activeStyles = (drawerView: string) => {
  switch (drawerView) {
    case ConfigConst.fullScreen:
      return {
        childrenCont: "closedContainer",
        contentCont: "contentFullScreenCont",
        hiddenContOpen: "hiddenFullScreenCont",
        hiddenContClose: "closedHiddenContainer",
      };
    case ConfigConst.standard:
      return {
        childrenCont: "childrenStandardCont",
        contentCont: "contentStandardCont",
        hiddenContOpen: "hiddenStandardCont",
        hiddenContClose: "closedHiddenContainer",
      };
    case ConfigConst.close:
      return {
        childrenCont: "childrenFullCont",
        contentCont: "closedContainer",
        hiddenContOpen: "closedHiddenContainer",
        hiddenContClose: "closedHiddenContainer",
      };
    default:
      return {
        childrenCont: "childrenStandardCont",
        contentCont: "contentStandardCont",
        hiddenContOpen: "hiddenStandardCont",
        hiddenContClose: "closedHiddenContainer",
      };
  }
};
