import Tree, { Point } from "react-d3-tree";
import { CSSProperties, useRef, useState } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

import "../../styles/drawer/rf/RfLogicalView.scss";
import appConst from "../../constants/appConst";
import colors from "../../config/colors";
import TopologyLabels from "../../constants/topologyLabels";

interface Props {
  data: any;
  zoom?: number;
  translate?: Point;
  nodeSize?: {
    x: number;
    y: number;
  };
  style?: CSSProperties;
  isZoomButtonsVisible?: Boolean;
}

export default function NetworkTraceTreeView({
  data,
  zoom,
  translate,
  nodeSize,
  style,
  isZoomButtonsVisible = true,
}: Props) {
  const labels = TopologyLabels();
  const treeRef = useRef<any>(null);
  const [currentZoom, setCurrentZoom] = useState(zoom ? zoom : 0.4);

  const onZoomInClick = () => {
    if (currentZoom < 1)
      setCurrentZoom(parseFloat((currentZoom + 0.2).toFixed(1)));
  };

  const onZoomOutClick = () => {
    if (currentZoom > 0.2)
      setCurrentZoom(parseFloat((currentZoom - 0.2).toFixed(1)));
  };

  const renderRectSvgNode = ({ nodeDatum, toggleNode }: any) => (
    <g>
      {nodeDatum.type === appConst.activeEquipment ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill={colors.greyDark}
          stroke-linecap="round"
          stroke-linejoin="round"
          x={"-15"}
          y={"-20"}
          onClick={toggleNode}
        >
          <path d="M5 4h14a2 2 0 0 1 1.84 2.75l-7.1 12.25a2 2 0 0 1 -3.5 0l-7.1 -12.25a2 2 0 0 1 1.75 -2.75" />
        </svg>
      ) : nodeDatum.type === appConst.taps ||
        nodeDatum.type === appConst.powerSupply ||
        nodeDatum.type === appConst.node ? (
        <rect
          x="-10"
          fill={colors.greyDark}
          width="20"
          height="20"
          onClick={toggleNode}
        />
      ) : nodeDatum?.type?.toLowerCase() === appConst.rfCable?.toLowerCase() ? (
        <circle onClick={toggleNode} x="-10" r="10" fill={colors.white} />
      ) : (
        <circle onClick={toggleNode} x="-10" r="10" fill={colors.greyDark} />
      )}

      {/* For text rendering */}
      {nodeDatum.type?.toLowerCase() !== appConst.rfCable.toLowerCase() && (
        <>
          <text fill={colors.greyDark} strokeWidth="1" x="20">
            {`${
              nodeDatum.type === appConst.taps ? labels.tapId : labels.networkID
            }: ${nodeDatum.name}`.toUpperCase()}
          </text>
          {nodeDatum.attributes?.deviceType && (
            <text fill={colors.greyDark} x="20" dy="20" strokeWidth="0.8">
              {`${labels.type}: ${nodeDatum.attributes?.deviceType}`.toUpperCase()}
            </text>
          )}
          {nodeDatum.attributes?.deviceModel && (
            <text fill={colors.greyDark} x="20" dy="40" strokeWidth="0.8">
              {`${labels.model}: ${nodeDatum.attributes?.deviceModel}`.toUpperCase()}
            </text>
          )}
          {nodeDatum.attributes?.activeDeviceRens && (
            <text fill={colors.greyDark} x="20" dy="60" strokeWidth="0.8">
              {`${labels.rensNum}: ${nodeDatum.attributes?.activeDeviceRens}`.toUpperCase()}
            </text>
          )}
          {nodeDatum.attributes?.tapValue && (
            <text fill={colors.greyDark} x="20" dy="60" strokeWidth="0.8">
              {`${labels.tapValue}: ${nodeDatum.attributes?.tapValue}`.toUpperCase()}
            </text>
          )}
        </>
      )}
    </g>
  );

  return (
    <div className="tree-container">
      <Tree
        ref={treeRef}
        data={data}
        orientation="vertical"
        pathFunc={"step"}
        zoom={currentZoom}
        translate={translate ? translate : { x: 600, y: 40 }}
        nodeSize={nodeSize ? nodeSize : { x: 200, y: 150 }}
        renderCustomNodeElement={renderRectSvgNode}
      />
      {isZoomButtonsVisible && (
        <div className="zoomBtnContainer" style={style}>
          <AiOutlinePlus size={20} onClick={onZoomInClick} />
          <AiOutlineMinus size={20} onClick={onZoomOutClick} />
        </div>
      )}
    </div>
  );
}
