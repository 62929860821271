import CustomAccordion from "../reusable/CustomAccordion";
import {
  isNull,
  getThreshold,
  getStatusOfCheck,
  getCheckValue,
  isEmptyObject,
  getStatusOfCheckHeader,
  validateStatus,
} from "../reusable/Util";
import AppLabels from "../../constants/App_Labels";
import ExceptionBox from "../reusable/ExceptionBox";
import CustomPopOver from "../reusable/CustomPopOver";
import ConfigConst from "../../constants/ConfigConst";
const RHPInterfaceInfo = (props: any) => {
  const { interfaceInfo, interfaceError, handleRefresh } = props;
  const labels = AppLabels();
  return (
    <>
      {interfaceInfo !== undefined &&
      !interfaceInfo?.hasOwnProperty("errorCode") ? (
        <CustomAccordion
          accTitle={labels.interface.toUpperCase()}
          accBodyElement={
            <table className="dataTableDetails">
              <thead style={{ marginTop: "10px" }}>
                <th>{labels.NAME.toUpperCase()}</th>
                <th>{labels.is_operational.toUpperCase()}</th>
                <th>{labels.admin_status.toUpperCase()}</th>
                <th>{labels.speed.toUpperCase()}</th>
                <th>{labels.Address.toUpperCase()}</th>
              </thead>
              <tbody>
                {interfaceInfo &&
                  interfaceInfo?.map((inter: any, i: number) => {
                    return (
                      <tr key={i + "int"}>
                        <td>{isNull(inter.name)}</td>
                        <td>{isNull(inter.inOperation ? "TRUE" : "FALSE")}</td>
                        <td>
                          {validateStatus(
                            inter.adminStatus ? "TRUE" : "FALSE",
                            [ConfigConst.True.toLowerCase()]
                          )}
                        </td>
                        <td>{isNull(inter.speed)}</td>
                        <td>{isNull(inter.phyAddress)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          }
        />
      ) : interfaceError !== undefined ? (
        <div className="diagErrorCont">
          <ExceptionBox
            exBoxType="error"
            errSecName="RHP Interface"
            errCode={isNull(interfaceError?.errorCode)}
            srcSystem={
              interfaceError?.hasOwnProperty("sourceSystem")
                ? interfaceError?.sourceSystem
                : "Request"
            }
            ErrorMSG={isNull(interfaceError?.errorMessage)}
            primaryBtn={true}
            secBtn={true}
            handleRefresh={handleRefresh}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default RHPInterfaceInfo;
