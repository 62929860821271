import { Form } from "react-bootstrap";
import { isLegacyAccount } from "../../../reusable/Util";
import "../../../../design-tokens/forms/Noise.scss";
import RedAsterisk from "../../../reusable/RedAsterisk";
import AppLabels from "../../../../constants/App_Labels";
import CustomRadioGroup from "../../../reusable/CustomRadioGroup";
import ConfigConst from "../../../../constants/ConfigConst";
import { useEffect, useState } from "react";
import { GetFormHistory } from "../../../../get-api/GetResponse";
interface Props {
  formSubmissionNo: any;
  detailsObj: any;
  setDetailsObj: any;
}

const TopHalf = ({ formSubmissionNo, detailsObj, setDetailsObj }: Props) => {
  const labels = AppLabels();

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setDetailsObj((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleArrayChange = (e: any, index: any) => {
    const { name, value } = e.target;
    detailsObj[name][index] = value;
    setDetailsObj({
      ...detailsObj,
      [name]: [...detailsObj[name]],
    });
  };

  return (
    <div>
      {formSubmissionNo !== "" && (
        <Form.Group>
          <label className="txtFieldLabel">{"Form Submission No"}</label>
          <Form.Control value={formSubmissionNo} disabled={true} />
        </Form.Group>
      )}
      {detailsObj?.CMMac?.map((CMMac: any, index: any) => (
        <Form.Group key={index}>
          {index === 0 && (
            <label className="txtFieldLabel">
              {labels.CABLE_MODEM + "(s)" + " " + labels.MAC_ADDRESS}
              <RedAsterisk />
            </label>
          )}
          <Form.Control
            onChange={(e) => handleArrayChange(e, index)}
            value={CMMac}
            name="CMMac"
          />
        </Form.Group>
      ))}
      {isLegacyAccount() && (
        <>
          {detailsObj?.STBMac?.map((STBMac: any, index: any) => (
            <Form.Group key={index}>
              {index === 0 && (
                <label className="txtFieldLabel">
                  {"STB" + "(s)" + " " + labels.MAC_ADDRESS}
                  <RedAsterisk />
                </label>
              )}
              <Form.Control
                onChange={(e) => handleArrayChange(e, index)}
                value={STBMac}
                name="STBMac"
              />
            </Form.Group>
          ))}
          {detailsObj?.EMTAMac?.map((EMTAMac: any, index: any) => (
            <Form.Group key={index}>
              {index === 0 && (
                <label className="txtFieldLabel">
                  {"EMTA" + "(s)" + " " + labels.MAC_ADDRESS}
                  <RedAsterisk />
                </label>
              )}
              <Form.Control
                onChange={(e) => handleArrayChange(e, index)}
                value={EMTAMac}
                name="EMTAMac"
              />
            </Form.Group>
          ))}
        </>
      )}
      {isLegacyAccount() ? (
        <Form.Group>
          <label className="txtFieldLabel">
            {labels.ACCOUNT_NUMBER}
            <RedAsterisk />
          </label>
          <Form.Control
            onChange={handleChange}
            value={detailsObj?.accountNumber}
            name="accountNumber"
          />
        </Form.Group>
      ) : (
        <>
          <Form.Group>
            <label className="txtFieldLabel">
              {labels.ACCOUNT_NUMBER}
              {detailsObj?.hhid === "" && <RedAsterisk />}
            </label>
            <Form.Control
              onChange={handleChange}
              value={detailsObj?.accountNumber}
              name="accountNumber"
            />
          </Form.Group>
          <Form.Group>
            <label className="txtFieldLabel">
              {labels.HHID}
              {detailsObj?.hhid !== "" && <RedAsterisk />}
            </label>
            <Form.Control
              onChange={handleChange}
              value={detailsObj?.hhid}
              name="hhid"
            />
          </Form.Group>
        </>
      )}
      <span className="formContainer">
        <Form.Group>
          <label className="txtFieldLabel">
            {labels.technician_Name}
            <RedAsterisk />
          </label>
          <Form.Control
            onChange={handleChange}
            value={detailsObj?.techName}
            name="techName"
          />
        </Form.Group>
        <Form.Group>
          <label className="txtFieldLabel">
            {labels.technician_Id}
            <RedAsterisk />
          </label>
          <Form.Control value={detailsObj?.technicianId} disabled={true} />
        </Form.Group>
      </span>
      <span className="formContainer">
        <Form.Group>
          <label className="txtFieldLabel">
            {detailsObj?.unitOrSuiteNumber === ""
              ? "House"
              : "Street" + " Number"}
            <RedAsterisk />
          </label>
          <Form.Control
            onChange={handleChange}
            value={detailsObj?.streetNumber}
            name="streetNumber"
          />
        </Form.Group>
        <Form.Group>
          <label className="txtFieldLabel">
            {"Street Name"}
            <RedAsterisk />
          </label>
          <Form.Control
            onChange={handleChange}
            value={detailsObj?.streetName}
            name="streetName"
          />
        </Form.Group>
        <Form.Group>
          <label className="txtFieldLabel">{"Street Direction"}</label>
          <Form.Control
            onChange={handleChange}
            value={detailsObj?.streetDirection}
            name="streetDirection"
          />
        </Form.Group>
      </span>
      <span className="formContainer">
        {detailsObj?.unitOrSuiteNumber !== "" && (
          <Form.Group>
            <label className="txtFieldLabel">{"Unit or Suite Number"}</label>
            <Form.Control
              onChange={handleChange}
              value={detailsObj?.unitOrSuiteNumber}
              name="unitOrSuiteNumber"
            />
          </Form.Group>
        )}
        <Form.Group>
          <label className="txtFieldLabel">
            {labels.City}
            <RedAsterisk />
          </label>
          <Form.Control
            onChange={handleChange}
            value={detailsObj?.city}
            name="city"
          />
        </Form.Group>
      </span>
      <span className="formContainer">
        <Form.Group>
          <label className="txtFieldLabel">
            {"Node Number"}
            <RedAsterisk />
          </label>
          <Form.Control
            onChange={handleChange}
            value={detailsObj?.nodeNumber}
            name="nodeNumber"
          />
        </Form.Group>
        <Form.Group>
          <label className="txtFieldLabel">{"CBPID"}</label>
          <Form.Control
            onChange={handleChange}
            value={detailsObj?.cbpId}
            name="cbpId"
          />
        </Form.Group>
      </span>
    </div>
  );
};

export default TopHalf;
