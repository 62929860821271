import { Marker } from "@react-google-maps/api";
import React, { Fragment, useEffect, useState } from "react";
import { mapElementStyles } from "../../../config/mapItemsConfig";
import appConst from "../../../constants/appConst";
import {
  GetLocatorElementData,
  GetOLTSearchProps,
} from "../../../util/reduxFunctions/getTopologyState";
import {
  getBase64ScaledImageAnchor,
  getLocatorEleTypeConfig,
  getMapElementClickableImage,
  getPngImagesForElement,
} from "../../../util/utilFuncs";

interface Props {
  pngImages: any;
  handleElementClick: any;
  zoomLevel: number;
}

const LocatorElements = ({
  pngImages,
  handleElementClick,
  zoomLevel,
}: Props) => {
  const { locatorEle } = GetLocatorElementData();
  const [locatorEleList, setLocatorEleList] = useState([]);
  const locatorSupportEle = [
    appConst?.powerSupply,
    appConst?.activeEquipment,
    appConst?.spliceCases,
    appConst?.wirelessSite,
    appConst?.smallCell,
    appConst?.clamshell,
    appConst?.rbCircuit,
    appConst?.olt,
  ];
  const { oltData } = GetOLTSearchProps();
  useEffect(() => {
    let templocatorEleList: any = [];
    locatorSupportEle?.map((ele: string) => {
      let eleFeatures = locatorEle?.[ele];
      let eleTypeConfig = getLocatorEleTypeConfig(ele);
      if (eleTypeConfig?.eleIdName !== "") {
        eleFeatures?.map((feature: any) => {
          if (
            locatorEleList?.findIndex(
              (locFeature: any) =>
                locFeature?.properties?.id ===
                feature?.[eleTypeConfig?.eleIdName]
            ) === -1
          ) {
            let activeEle = {
              geometry:
                eleTypeConfig?.configType === appConst.rensElement
                  ? feature?.geoData
                  : feature?.geometry,
              properties: {
                featureId: feature?.[eleTypeConfig?.eleIdName],
                type: eleTypeConfig.configType,
                eleType: ele,
                data: feature,
              },
            };
            templocatorEleList.push(activeEle);
          }
        });
      }
    });
    setLocatorEleList(templocatorEleList);
  }, [locatorEle]);

  return (
    <>
      {locatorEleList &&
        locatorEleList.map((locEle: any) => {
          const markerLat = locEle?.geometry?.coordinates?.[1];
          const markerLng = locEle?.geometry?.coordinates?.[0];
          const eleData = locEle?.properties?.data;
          const eleIcon = getPngImagesForElement(
            pngImages?.[locEle?.properties?.eleType],
            eleData?.symbolName,
            eleData?.rotation,
            mapElementStyles?.[locEle?.properties?.eleType],
            zoomLevel
          );
          const anchor = getBase64ScaledImageAnchor(
            pngImages,
            locEle?.properties?.eleType,
            eleData?.symbolName,
            eleData?.rotation,
            mapElementStyles?.[locEle?.properties?.eleType],
            zoomLevel
          );
          return (
            markerLat &&
            markerLng && (
              <Fragment key={locEle?.networkId}>
                <Marker
                  position={{ lat: markerLat, lng: markerLng }}
                  icon={eleIcon}
                  clickable={false}
                />

                <Marker
                  position={{ lat: markerLat, lng: markerLng }}
                  onClick={() => {
                    handleElementClick(locEle, locEle?.properties?.eleType);
                  }}
                  icon={{
                    url:
                      appConst.imageStringPrefix +
                      encodeURIComponent(
                        getMapElementClickableImage(
                          zoomLevel,
                          anchor?.clickIconSize
                        )
                      ),
                    anchor: new google.maps.Point(
                      anchor ? anchor?.x : mapElementStyles?.common.anchor.x,
                      anchor ? anchor?.y : mapElementStyles?.common.anchor.y
                    ),
                  }}
                />
              </Fragment>
            )
          );
        })}
      {oltData &&
        oltData?.features?.map((olt: any) => {
          const oltPng = pngImages?.[appConst?.olt];
          const oltMarkerLat = olt?.geometry?.coordinates?.[1];
          const oltMarkerLng = olt?.geometry?.coordinates?.[0];
          const eleData = olt?.properties?.data;
          const eleIcon = getPngImagesForElement(
            oltPng,
            eleData?.symbolName,
            eleData?.rotation,
            mapElementStyles?.[appConst?.olt],
            zoomLevel
          );
          const anchor = getBase64ScaledImageAnchor(
            pngImages,
            appConst?.olt,
            eleData?.symbolName,
            eleData?.rotation,
            mapElementStyles?.[appConst?.olt],
            zoomLevel
          );
          return (
            oltMarkerLat &&
            oltMarkerLng && (
              <Fragment key={olt?.featureId}>
                <Marker
                  position={{ lat: oltMarkerLat, lng: oltMarkerLng }}
                  icon={eleIcon}
                  clickable={false}
                />

                <Marker
                  position={{ lat: oltMarkerLat, lng: oltMarkerLng }}
                  onClick={() => {
                    handleElementClick(olt, olt?.properties?.eleType);
                  }}
                  icon={{
                    url:
                      appConst.imageStringPrefix +
                      encodeURIComponent(
                        getMapElementClickableImage(
                          zoomLevel,
                          anchor?.clickIconSize
                        )
                      ),
                    anchor: new google.maps.Point(
                      anchor ? anchor?.x : mapElementStyles?.common.anchor.x,
                      anchor ? anchor?.y : mapElementStyles?.common.anchor.y
                    ),
                  }}
                />
              </Fragment>
            )
          );
        })}
    </>
  );
};

export default React.memo(LocatorElements);
