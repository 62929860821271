import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { parseDate } from "../reusable/Util";

const ListComments = (props:any)=>{
    
   
    const {commentsData} = useSelector(
        (state: any) => ({
          commentsData: state?.FeedbackCommentsInfo?.data[props?.issueKey]
        }),
        shallowEqual
      );


    const CreateCommentElements = () => {
        if (commentsData?.comments?.length > 0){
          return commentsData?.comments?.sort((a:any,b:any) => {
            return Date.parse(b.created)-(Date.parse(a.created))
          }).map((commentItem: any,index:any) => {
              if(index <= 2){
                const comment = commentItem?.body?.split("-//")?.[0];
                const getName = () => {
                  const data = commentItem?.body?.split("-//");
                  if (data?.length > 1) {
                    const name = data[1].split("@")?.[0];
                    const arrName = name.split(".");
                    return arrName?.[0] + " " + arrName[1];
                  } else return commentItem?.author?.displayName;
                };
              return (<div>
                <span><b style={{marginRight:"2%"}}>{getName()}</b>{parseDate(commentItem?.created)}</span> 
                <p>{comment}</p>
                
              </div>) }
            });
          }
        else return (<span></span>)
    };

    return (<CreateCommentElements/>)
}

export default ListComments;