import { useEffect } from "react";
import appConst from "../../../constants/appConst";

import { GetNodeSiteDetailsProps } from "../../../util/reduxFunctions/getTopologyState";

interface Props {
  gMap: any;
}

const RFCables = ({ gMap }: Props) => {
  const { activeNode, allNodeData } = GetNodeSiteDetailsProps();
  const rfCableData = activeNode?.rfCable?.data;

  useEffect(() => {
    if (
      gMap &&
      rfCableData !== undefined &&
      rfCableData?.features?.length > 0
    ) {
      gMap?.data.addGeoJson(rfCableData);
    }
  }, [rfCableData]);

  useEffect(() => {
    if (
      gMap &&
      allNodeData &&
      rfCableData !== undefined &&
      rfCableData?.features?.length > 0
    ) {
      let allFeatures = [] as any;
      allNodeData?.map(
        (e: any) =>
          e?.rfCable?.data?.features &&
          allFeatures.push(...e?.rfCable?.data?.features)
      );
      const tempGeoJson = {
        type: appConst?.FeatureCollection,
        features: allFeatures,
      };
      gMap?.data.addGeoJson(tempGeoJson);
    }
  }, [gMap]);
  return <></>;
};

export default RFCables;
