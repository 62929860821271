import { Divider } from "@material-ui/core";
import { BiLinkExternal } from "react-icons/bi";
import { Snackbar} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  convertCMData,
  getHomeAddress,
  getThrColoredTableCell,
  getValueByStrMapping,
  secToTime,
} from "../../../../components/reusable/Util";
import appConst from "../../../constants/appConst";
import TopologyLabels from "../../../constants/topologyLabels";
import { formatCamelCase } from "../../../util/utilFuncs";
import CustomAccordion from "../../../../components/reusable/CustomAccordion";
import { GetNodeInfoDetails } from "../../../../get-api/GetResponse";
import CmViewData from "../../../../data/cablemodem/CmViewData";
import AppLabels from "../../../../constants/App_Labels";
import SimpleDataTable from "../../reusable/SimpleDataTable";
import GoogleMapDirectionsBtn from "../../../../components/reusable/GoogleMapDirectionsBtn";
import AccDetailsClient from "../../../../network/httpclient/accountInfo/AccDetailsClient";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import React, { useMemo, useState, useRef, useEffect } from "react";
import {
  AccReset,
  SetActiveSubTabIndex,
} from "../../../../store/actions/account/accountInfo/ActiveAccountAction";
interface Props {
  deviceData: any;
}

const CMBasicDetails = ({ deviceData }: Props) => {
  const appLabels = AppLabels();
  const dispatch = useDispatch();
  const labels = TopologyLabels();
  const { nodeInfo } = GetNodeInfoDetails();
  const dData = deviceData?.properties?.data;
  const summaryData = dData?.deviceDiag?.data?.summary;
  const docsisData = dData?.deviceDiag?.data?.docsis;
  const summaryStatus = dData?.deviceDiag?.status;
  const usScQAMData = docsisData?.docsis30?.upstream;
  const dsScQAMData = docsisData?.docsis30?.downstream;
  const ofdmaData = docsisData?.ofdm?.upstream?.channels;
  const ofdmData = docsisData?.ofdm?.downstreamChannels;
  let cmDataConfig = CmViewData();
  let usSummaryConfig = cmDataConfig?.upStream?.summary;
  let dsSummaryConfig = cmDataConfig?.downStream?.summary;
  const { accInfo, status } = useSelector(
    (state: any) => ({
      accInfo: state.AccountState.AccountDetails?.data,
      status: state.AccountState.AccountDetails.status,
    }),
    shallowEqual
  );
  const macRef = React.useRef(false);
  const macIdRef = React.useRef("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const getDsSummaryBox = () => {
    if (docsisData && docsisData !== null) {
      return (
        <CustomAccordion
          key="dsSummaryAcc"
          accTitle={(appLabels.DS + " " + appLabels.SUMMARY).toUpperCase()}
          accBodyElement={
            <div className="cmChSummaryDetails">
              <SimpleDataTable
                headers={dsSummaryConfig?.individual?.headers}
                tBody={Object.keys(dsSummaryConfig?.individual?.dataKeys)?.map(
                  (key: any, j: number) => {
                    let summaryConfig: any =
                      dsSummaryConfig?.individual?.dataKeys;

                    if (summaryConfig[key]?.dataSource === "ofdm") {
                      return ofdmData?.map((ch: any, index: number) => {
                        let attValue = getValueByStrMapping(
                          ch,
                          summaryConfig[key]?.dataKey
                        );
                        let computedValue = convertCMData(key, attValue);
                        return (
                          <tr key={index}>
                            <td>
                              {summaryConfig[key]?.label} - {ch?.channelId}
                            </td>
                            {getThrColoredTableCell(computedValue, j)}
                          </tr>
                        );
                      });
                    } else {
                      let attValue = getValueByStrMapping(
                        docsisData,
                        summaryConfig[key]?.dataKey
                      );

                      let computedValue = convertCMData(key, attValue);
                      return (
                        <tr key={j}>
                          <td>{summaryConfig[key]?.label}</td>
                          {getThrColoredTableCell(computedValue, j)}
                        </tr>
                      );
                    }
                  }
                )}
              />
              <SimpleDataTable
                headers={dsSummaryConfig?.common?.headers}
                tBody={
                  <>
                    {dsSummaryConfig?.common?.dataSet?.scQam?.map(
                      (ch: any, j: number) => {
                        return (
                          <tr key={j}>
                            {Object.keys(ch)?.map((key: string, k: number) => {
                              let attObj =
                                key === "level"
                                  ? ch[key]?.toUpperCase()
                                  : getValueByStrMapping(dsScQAMData, ch[key]);
                              return getThrColoredTableCell(attObj, k);
                            })}
                          </tr>
                        );
                      }
                    )}
                    {ofdmData?.map((ofdmCh: any, j: number) => {
                      let chConfig: any = dsSummaryConfig.common?.dataSet?.ofdm;
                      return (
                        <tr key={j}>
                          {Object.keys(chConfig)?.map((key: any, k: number) => {
                            let attObj =
                              key === "level"
                                ? `${formatCamelCase(chConfig[key])} - ${
                                    ofdmCh?.channelId
                                  }`
                                : getValueByStrMapping(ofdmCh, chConfig[key]);
                            return getThrColoredTableCell(attObj, k);
                          })}
                        </tr>
                      );
                    })}
                  </>
                }
              />
            </div>
          }
        />
      );
    }
  };

  const getUsSummaryBox = () => {
    if (docsisData && docsisData !== null) {
      return (
        <CustomAccordion
          key="usSummaryAcc"
          accTitle={(appLabels.US + " " + appLabels.SUMMARY).toUpperCase()}
          accBodyElement={
            <div className="cmChSummaryDetails">
              <SimpleDataTable
                headers={usSummaryConfig?.individual?.headers}
                tBody={Object.keys(usSummaryConfig?.individual?.dataKeys)?.map(
                  (key: any, j: number) => {
                    let summaryConfig: any =
                      usSummaryConfig?.individual?.dataKeys;

                    if (summaryConfig[key]?.dataSource === "ofdm") {
                      return ofdmaData?.map((ch: any) => {
                        let attValue = getValueByStrMapping(
                          ch,
                          summaryConfig[key]?.dataKey
                        );
                        let computedValue = convertCMData(key, attValue);
                        return (
                          <tr key={j}>
                            <td>{summaryConfig[key]?.label}</td>
                            {getThrColoredTableCell(computedValue, j)}
                          </tr>
                        );
                      });
                    } else {
                      let attValue = getValueByStrMapping(
                        docsisData,
                        summaryConfig[key]?.dataKey
                      );

                      let computedValue = convertCMData(key, attValue);
                      return (
                        <tr key={j}>
                          <td>{summaryConfig[key]?.label}</td>
                          {getThrColoredTableCell(computedValue, j)}
                        </tr>
                      );
                    }
                  }
                )}
              />
              <SimpleDataTable
                headers={usSummaryConfig?.common?.headers}
                tBody={usSummaryConfig?.common?.dataSet?.map(
                  (ch: any, j: number) => {
                    return (
                      <tr key={j}>
                        {Object.keys(ch)?.map((key: string, k: number) => {
                          let attObj =
                            key === "level"
                              ? ch[key]?.toUpperCase()
                              : getValueByStrMapping(usScQAMData, ch[key]);
                          return getThrColoredTableCell(attObj, k);
                        })}
                      </tr>
                    );
                  }
                )}
              />
            </div>
          }
        />
      );
    }
  };

  const openSingleCMView = () => {
    let dataObj = {
      cmData: dData,
      nodeData: {
        nodeId: nodeInfo?.nodeId,
        macDomain: nodeInfo?.macDomain,
      },
    };
    let url = window?.open(
      `/#/cablemodem?macId=${dData?.macAddress}`,
      dData?.macAddress
    )!;
    url?.addEventListener("load", () => {
      url.postMessage(dataObj, url.origin);
    });
  };
  const navigateToAcc = React.useCallback(
    (macId?: string) => {
      if (accInfo?.accountNumber !== undefined) {
        macRef.current = false;

        let newWindow =
          macIdRef.current?.trim() !== ""
            ? window.open(
                `/#/account?accountNumber=${
                  accInfo?.accountNumber
                }&mac=${macIdRef.current?.trim()}`,
                "_blank"
              )!
            : window.open(
                `/#/account?accountNumber=${accInfo?.accountNumber}`,
                "_blank"
              )!;
        macIdRef.current = "";
        newWindow.onload = () => {
          newWindow.document.title = "Account-" + accInfo?.accountNumber;
        };
      } else if (status === "ERROR") {
        setOpenSnackBar(true);
        dispatch(AccReset());
        macRef.current = false;
      }
    },
    [accInfo?.accountNumber, dispatch, status]
  );

  useEffect(() => {
    if (macRef.current) {
      dispatch(AccReset());
      navigateToAcc();
    }
  }, [accInfo?.accountNumber, status, navigateToAcc, dispatch]);

  const handleExternalLink = () => {
    dispatch(AccDetailsClient.getAccDetails(dData?.macAddress, "macAddress"));
    dispatch(SetActiveSubTabIndex(1));
    macRef.current = true;
    macIdRef.current = dData?.macAddress?.toString();
    navigateToAcc();
  };

  return (
    <div className="cmBasicDetailCont">
      <p className="cmBasicHeader">
      <Snackbar
          open={openSnackBar}
          autoHideDuration={2000}
          onClose={() => setOpenSnackBar(!openSnackBar)}
        >
          <Alert severity="warning">
            Unable to Fetch Details for the Selected Mac Address.
          </Alert>
        </Snackbar>
        <span>
          {labels.cmMac.toUpperCase()}: 
          <span
                  id="cm_mac"
                  className="idLink"
                  title="Single Cable Modem View"
                  onClick={() => {
                    openSingleCMView();
                  }}
                >
          {dData?.macAddress}
          </span>
        </span>
        <section>
          <GoogleMapDirectionsBtn
            lat={deviceData?.geometry?.lat}
            lng={deviceData?.geometry?.lng}
          />
          {/* {summaryStatus === appConst?.OK && ( */}
            <BiLinkExternal
              onClick={handleExternalLink}
              className="basicTableHeaderIcon"
            />
          {/* )} */}
        </section>
      </p>
      <Divider />
      <span className="cmBasicDetail">
        <b>{labels.model.toUpperCase()}:</b>
        {dData?.model}
      </span>
      <span className="cmBasicDetail">
        <b>{labels.Address.toUpperCase()}:</b>
        {getHomeAddress(dData?.serviceAddress)}
      </span>
      <span className="cmBasicDetail">
        <b>{labels.uptime.toUpperCase()}:</b>
        {summaryStatus === appConst?.ERROR ? (
          <span className="failedText">{labels.offline.toUpperCase()}</span>
        ) : (
          secToTime(summaryData?.uptime)
        )}
      </span>
      <span className="cmBasicDetail">
        <b>{labels.registeredTime.toUpperCase()}:</b>
        {secToTime(summaryData?.cmtsCmRegisteredTime)}
      </span>
      <Divider />
      <div>{getDsSummaryBox()}</div>
      <div>{getUsSummaryBox()}</div>
    </div>
  );
};

export default CMBasicDetails;
