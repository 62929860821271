import { TapInfoState } from "../../../states/account/neighbourhood/TapInfoState";
import {
  TAP_INFO_BEGIN,
  TAP_INFO_FAILURE,
  TAP_INFO_SUCCESS,
  TapInfoActionTypes,
} from "../../../types/account/neighbourhood/TapInfo";

const initialState: TapInfoState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function TapInfoReducer(
  state = initialState,
  action: TapInfoActionTypes
): TapInfoState {
  switch (action.type) {
    case TAP_INFO_BEGIN:
      return {
        isLoading: true,
      };
    case TAP_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case TAP_INFO_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}
