import { CSSProperties, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/drawer/nodeComments/NodeComments.scss";
import { hasLimitedTopologyFeatureView } from "../../../components/reusable/RoleConfig";

interface props {
  tabs: any;
  defaultKey: any;
  childrenStyle?: CSSProperties;
  onSelect?: any;
}

const CustomTabs = ({ tabs, defaultKey, childrenStyle, onSelect }: props) => {

  const isDisabled = hasLimitedTopologyFeatureView();

  const [key, setKey] = useState<string | null>(defaultKey);
  const handleSelect = (key: any) => {
    setKey(key);
    if (onSelect) {
      onSelect(key);
    }
  };
  return (
    <Tabs
      id="node-tabs"
      activeKey={key}
      onSelect={handleSelect}
      className="node-tabs"
    >
      {tabs?.filter((nt:any,i:any) => !((isDisabled && nt?.eventKey === "comment") || (isDisabled && nt?.eventKey === "attach"))).map((nt: any, index: any) => {
        return (
          <Tab
            key={index}
            eventKey={nt?.eventKey}
            title={
              <span style={{ fontWeight: 600, textTransform: "uppercase" }}>
                {nt?.title}
              </span>
            }
          >
            <div style={childrenStyle}>{nt?.compToRend}</div>
          </Tab>
        );
      })}
    </Tabs>
  );
};

export default CustomTabs;
