import AppLabels from "../../constants/App_Labels";

const BrowseFiles = ({ setFile, file }: any) => {
  const browseFileSet: any[] = [...file];
  const labels = AppLabels();

  const fileHandle = (e: any) => {
    e.target.value = null;
  };

  function filesSelectedHandler(event: any) {
    let reader = new FileReader();
    let fileToRead = event?.target?.files[0];

    if (fileToRead?.type?.match("image.*")) {
      reader.readAsDataURL(fileToRead);
    } else if (
      fileToRead !== undefined &&
      !fileToRead?.type?.match("image.*")
    ) {
      alert(labels.INCORRECT_FILE);
    }

    reader.onload = function (e) {
      const data = e.target!.result;
      const newFile = [fileToRead, data];
      browseFileSet.push(newFile);
      setFile(browseFileSet);
    };
  }

  return (
    <div className="custom-file">
      <input
        type="file"
        accept="image/png, image/jpeg"
        className="custom-file-input"
        id="inputGroupFile01"
        aria-describedby="inputGroupFileAddon01"
        multiple={true}
        onChange={(event) => filesSelectedHandler(event)}
        onClick={(e) => fileHandle(e)}
      />
      <label className="custom-file-label" htmlFor="inputGroupFile01">
        {labels.CHOOSE_FILE}
      </label>
    </div>
  );
};

export default BrowseFiles;
