import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import ErrorBox from "../../components/reusable/ErrorBox";
import {
  getModemTypeForTele,
  setCurrentWindowName,
} from "../../components/reusable/Util";
import { setHistoryDisplay } from "../../components/search/SearchBoxMini";
import AppLabels from "../../constants/App_Labels";
import {
  GetNodeInfoDetails,
  GetTelemetryDetails,
} from "../../get-api/GetResponse";
import { useRemoveNavBar } from "../../hooks/reusableHooks";
import NodeInfoDetailsClient from "../../network/httpclient/nodeinfo/NodeInfoDetailsClient";
import { SetActiveKey } from "../../store/actions/node/AllNodesAction";
import {
  NodeReset,
  SetActiveNodeInfoDetails,
} from "../../store/actions/node/nodeinfo/ActiveNodeInfoAction";
import {
  setDiagType,
  setIsCWEnabled,
  setModemType,
  setViewType,
} from "../../topology/store/slices/mapItems/diagnostics/telemetry";
import storeConst from "../../topology/store/storeConst";
import STBPageDetails from "./STBPageDetails";
import "../../design-tokens/stb-page/StbPage.scss";
import { GetTelemetryProps } from "../../topology/util/reduxFunctions/getTopologyState";
import { saveConfigData } from "../../store/ducksPattern/userPrefConfig";
import { getStbTeleViewConfig } from "../../config/STBTelemetryConfig";

const STBPage = (props: any) => {
  const { location: search } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const labels = AppLabels();

  const searchParams = new URLSearchParams(search.search);
  const nodeId = searchParams.get("nodeId");
  const macAddress = searchParams.get("macAddress");
  const modemDetails = getModemTypeForTele(search.pathname);

  const { searchNodeStatus, nodedata, currNodedata } = GetNodeInfoDetails();
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const getNodeInfo = () => {
    if (nodeId) dispatch(NodeInfoDetailsClient.getInfoNodeDetails(nodeId));
  };

  useRemoveNavBar();

  useEffect(() => {
    if (search && modemDetails) {
      dispatch(setModemType(modemDetails?.modemType));
      const dataSetType = searchParams.get("dataSet");
      const viewTypeParam = searchParams.get("viewType");
      dispatch(setIsCWEnabled(false));
      if (dataSetType && dataSetType !== "") {
        dispatch(setDiagType(dataSetType?.toLowerCase()?.trim()));
      } else {
        dispatch(setDiagType(storeConst.all_no_cw));
      }
      if (viewTypeParam && viewTypeParam !== "") {
        dispatch(setViewType(viewTypeParam?.toUpperCase()?.trim()));
      }
      if (modemDetails?.screenType) {
        //start making config file
        let viewConfig = getStbTeleViewConfig(modemDetails?.modemType);
        dispatch(saveConfigData(viewConfig));
      }
      if (nodeId) {
        setCurrentWindowName(
          modemDetails?.screenType?.toUpperCase() + "-" + nodeId
        );
        getNodeInfo();
      } else if (macAddress) {
        setCurrentWindowName(
          modemDetails?.screenType?.toUpperCase() + "-" + macAddress
        );
      }
    }
  }, []);

  useEffect(() => {
    if (
      searchNodeStatus === storeConst.OK &&
      JSON.stringify(nodedata) !== '[""]'
    ) {
      setHistoryDisplay(nodeId);
      let id = nodedata[0]?.smt ? nodedata[0]?.smtId : nodedata[0]?.nodeId;
      if (nodedata && nodedata.length === 1) {
        dispatch(NodeReset());
        dispatch(SetActiveNodeInfoDetails(nodedata[0]));
        dispatch(SetActiveKey(id));
      }
    } else if (
      searchNodeStatus === storeConst.ERROR &&
      nodedata === undefined
    ) {
      setErrorMsg(labels.nodeNAErr);
      setOpen(true);
    } else if (
      searchNodeStatus === "OK" &&
      JSON.stringify(nodedata) === '[""]'
    ) {
      setErrorMsg(labels.nodeNAErr);
      setOpen(true);
    }
  }, [searchNodeStatus]);

  return (
    <div>
      {currNodedata && (
        <STBPageDetails
          screenType={modemDetails?.screenType}
          reloadNodeInfo={() => getNodeInfo()}
          macAddress={macAddress}
        />
      )}

      <ErrorBox
        open={open}
        error={errorMsg}
        setOpen={setOpen}
        handleClose={() => {
          setOpen(false);
          history.replace(`/`);
        }}
      />
    </div>
  );
};

export default STBPage;
