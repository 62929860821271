import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { HistoryState } from "../../../store/states/history/History";
import {
  historyBegin,
  historySuccess,
  historyFailure,
} from "../../../store/actions/history/HistoryAction";

export default class HistoryClient {
  static getHistory =
    (
      techId: String,
      lanId: String,
      accountId: String,
      workorderId: String,
      startDate: String,
      endDate: String,
      testType: String,
      limit: Number,
      offset: Number
    ): ThunkAction<void, HistoryState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "certificates/search",
      };
      const dataObj = {
        techId: techId,
        lanId: lanId,
        accountId: accountId,
        workorderId: workorderId,
        startDate: startDate,
        endDate: endDate,
        testType: testType,
        limit: limit,
        offset: offset,
      };

      dispatch(historyBegin());

      HttpClient.post<any>(httpParameters, dataObj)
        .then((res) => {
          dispatch(historySuccess(res));
          return res;
        })
        .catch((error) => {
          dispatch(historyFailure(error));
          return error;
        });
    };
}
