import DataCard from "../../topology/components/reusable/DataCard";
import SimpleDataTable from "../../topology/components/reusable/SimpleDataTable";
import {
  getChCWValue,
  getThrColoredTableCell,
  getValueByStrMapping,
  isNull,
} from "../reusable/Util";

interface Props {
  cmConfig?: any;
  cmData?: any;
  scQamFlag?: any;
  usFlag?: any;
}

const CmSpecChart = ({ cmConfig, cmData, scQamFlag, usFlag }: Props) => {
  let scQAMData = usFlag
    ? cmData?.docsis30?.upstream
    : cmData?.docsis30?.downstream;
  let cWData = cmData?.codewords;
  let ofdmData = usFlag
    ? cmData?.ofdm?.upstream?.channels
    : cmData?.docsis?.ofdm?.downstreamChannels;
  let config = usFlag
    ? scQamFlag
      ? cmConfig?.upstream?.scQam
      : cmConfig?.upStream?.ofdma
    : scQamFlag
    ? cmConfig?.downStream?.scQam
    : cmConfig?.downStream?.ofdm;
  return (
    <DataCard
      cardHeader={""}
      cardBody={
        <SimpleDataTable
          headers={config?.headers}
          tBody={
            scQamFlag
              ? scQAMData?.channels?.map((ch: any, j: number) => {
                  return (
                    <tr key={j}>
                      {Object.keys(config?.dataKeys?.docsis)?.map(
                        (key: string, k: number) => {
                          let chConfig: any = config?.dataKeys?.docsis;
                          let attObj = getValueByStrMapping(ch, chConfig[key]);
                          return getThrColoredTableCell(attObj, k);
                        }
                      )}
                      <td>{cWData?.timeDelta}</td>
                      {Object.keys(config?.dataKeys?.codeword)?.map(
                        (key: string, k: number) => {
                          let chConfig: any = config?.dataKeys?.codeword;
                          let chCwData = getChCWValue(
                            cWData?.downstreamChannels,
                            ch?.frequency
                          );
                          let attObj = getValueByStrMapping(
                            chCwData,
                            chConfig[key]
                          );
                          return getThrColoredTableCell(attObj, k);
                        }
                      )}
                    </tr>
                  );
                })
              : ofdmData?.map((ch: any, j: number) => {
                  let cwConfig: any = config?.dataKeys?.codeword;
                  let cwChannel = cWData?.ofdmDsChannels?.find((cwCh: any) =>
                    cwCh?.profiles?.[0]?.channelID?.includes(ch?.channelId)
                  );
                  return (
                    <tr key={j}>
                      <td className="multiLineValue">
                        {ch?.profiles?.map((p: any) => {
                          return (
                            "" +
                            ch?.channelId +
                            "-profile-" +
                            p?.profileId +
                            "\n"
                          );
                        })}
                      </td>
                      {Object.keys(config?.dataKeys?.docsis)?.map(
                        (key: string, k: number) => {
                          let chConfig: any = config?.dataKeys?.docsis;
                          let attObj = getValueByStrMapping(ch, chConfig[key]);
                          return getThrColoredTableCell(attObj, k);
                        }
                      )}
                      <td>{cWData?.timeDelta}</td>
                      {Object.keys(config?.dataKeys?.codeword)?.map(
                        (key: string, k: number) => {
                          let attValue = "";
                          cwChannel?.profiles?.map((p: any) => {
                            let attObj = getValueByStrMapping(p, cwConfig[key]);
                            attValue += `${
                              typeof attObj === "object"
                                ? isNull(attObj?.value)
                                : isNull(attObj)
                            }\n`;
                          });
                          return (
                            <td key={k} className="multiLineValue">
                              {attValue}
                            </td>
                          );
                        }
                      )}
                    </tr>
                  );
                })
          }
        />
      }
    />
  );
};

export default CmSpecChart;
