import { CertiDevice } from "../../../models/account/certifications/CertiDevice";
import { ActiveCertiDevicesState } from "../../../states/account/certifications/ActiveCertiDevicesState";
import {
  ActiveCertiDevicesActionTypes,
  RESET_ACTIVE_CERTI_FAILURE,
  SAVE_ACTIVE_CERTI_DEVICES,
  UPDATE_ACTIVE_CERTI_DEVICES,
  DECREMENT_API_CALL_COUNTER,
  INCREMENT_API_CALL_COUNTER,
} from "../../../types/account/certifications/ActiveCertiDevices";

const initialState: ActiveCertiDevicesState = {
  certiDevices: [],
};

export function ActiveCertiDeviceReducer(
  state = initialState,
  action: ActiveCertiDevicesActionTypes
): ActiveCertiDevicesState {
  switch (action.type) {
    case SAVE_ACTIVE_CERTI_DEVICES:
      return {
        ...state,
        certiDevices: action.payload,
      };
    case INCREMENT_API_CALL_COUNTER:
      return {
        ...state,
        certiDevices: state.certiDevices.map((d: CertiDevice) => {
          if (d.deviceKey === action.devicekey) {
            let apiCalls = d.inProgressCalls + 1;
            return { ...d, ...{ inProgressCalls: apiCalls } };
          } else return d;
        }),
      };
    case DECREMENT_API_CALL_COUNTER:
      return {
        ...state,
        certiDevices: state.certiDevices.map((d: CertiDevice) => {
          if (d.deviceKey === action.devicekey) {
            let apiCalls = d.inProgressCalls - 1;
            let tempLoading = d.isLoading;
            if (apiCalls === 0) {
              tempLoading = false;
            }
            return {
              ...d,
              ...{ inProgressCalls: apiCalls, isLoading: tempLoading },
            };
          } else return d;
        }),
      };
    case UPDATE_ACTIVE_CERTI_DEVICES:
      return {
        ...state,
        certiDevices: state.certiDevices.map((d: CertiDevice) => {
          if (d.deviceKey === action.devicekey) {
            if (d.data !== undefined) {
              let oldData = d.data;
              let newData = { [action.subdataKey]: action.data };
              let updatedData = { ...oldData, ...newData };
              return { ...d, ...{ data: updatedData } };
            } else {
              let newData = { [action.subdataKey]: action.data };
              return { ...d, ...{ data: newData } };
            }
          } else {
            return d;
          }
        }),
      };
    case RESET_ACTIVE_CERTI_FAILURE:
      return {
        ...state,
        certiDevices: [],
      };
    default:
      return state;
  }
}
