import { createSlice } from "@reduxjs/toolkit";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "rpdRegistration",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
    clamshellId: undefined,
    macAddress: undefined,
    rpdNumber: 1,
  },
  reducers: {
    rpdRegistrationBegin: (rpd) => {
      rpd.isLoading = true;
    },
    rpdRegistrationSuccess: (rpd, action) => {
      rpd.isLoading = false;
      rpd.data = action.payload;
      rpd.error = undefined;
      rpd.status = storeConst.OK;
    },
    rpdRegistrationFailure: (rpd, action) => {
      rpd.isLoading = false;
      rpd.error = action.payload;
      rpd.data = undefined;
      rpd.status = storeConst.ERROR;
    },
    rpdRegistrationReset: (rpd) => {
      rpd.isLoading = false;
      rpd.error = undefined;
      rpd.data = undefined;
      rpd.status = "";
      rpd.clamshellId = undefined;
    },
    setRPDClamshellId: (rpd, action) => {
      rpd.clamshellId = action.payload;
      rpd.error = undefined;
      rpd.data = undefined;
      rpd.status = "";
    },
    setRPDMacAddress: (rpd, action) => {
      rpd.macAddress = action.payload;
      rpd.error = undefined;
      rpd.data = undefined;
      rpd.status = "";
    },
    setRPDNumber: (rpd, action) => {
      rpd.rpdNumber = action.payload;
      rpd.error = undefined;
      rpd.data = undefined;
      rpd.status = "";
    },
  },
});

export const {
  rpdRegistrationBegin,
  rpdRegistrationSuccess,
  rpdRegistrationFailure,
  rpdRegistrationReset,
  setRPDClamshellId,
  setRPDMacAddress,
  setRPDNumber,
} = slice.actions;
export default slice.reducer;
