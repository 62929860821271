import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../middleware/apiActions";
import {
  SYMBOLS_SERVICE_URL,
  SYMBOLS_STREAM,
} from "../middleware/apiEndpoints";
import storeConst from "../storeConst";

const slice = createSlice({
  name: "symbols",
  initialState: {
    isTopoEleImgLoading: false,
    inProgressApiCalls: 0,
    pngImages: {},
  },
  reducers: {
    getEleImagesBegin: (symbols, { payload }) => {
      symbols.inProgressApiCalls++;
      symbols.isTopoEleImgLoading = true;
      const images: any = symbols?.pngImages;

      if (!images?.[payload.elementName]) {
        symbols.pngImages = {
          ...symbols.pngImages,
          [payload.elementName]: {
            isLoading: true,
            data: undefined,
            error: undefined,
            status: "",
          },
        };
      }
    },
    getEleImagesSuccess: (symbols, { payload }) => {
      symbols.inProgressApiCalls--;
      if (symbols.inProgressApiCalls === 0) symbols.isTopoEleImgLoading = false;
      const images: any = JSON.parse(JSON.stringify(symbols?.pngImages));
      const eleName = payload.elementName;
      let activeEle: any = images[eleName];
      let newImages: any = [];

      if (payload?.data && payload?.data?.length > 0) {
        payload?.data?.map((item: any) => {
          if (item) {
            let isImageAvailable = activeEle?.data?.find(
              (img: any) => img?.name === item?.name
            );
            if (!isImageAvailable) {
              newImages?.push(item);
            }
          }
        });
      }
      if (activeEle?.data && activeEle?.data?.length > 0) {
        activeEle.data = [...activeEle?.data, ...newImages];
      } else {
        activeEle.data = newImages;
      }
      activeEle.isLoading = false;
      activeEle.status = storeConst.OK;
      symbols.pngImages = { ...symbols.pngImages, ...{ [eleName]: activeEle } };
    },
    getEleImagesFailure: (symbols, { payload }) => {
      symbols.inProgressApiCalls--;
      if (symbols.inProgressApiCalls === 0) symbols.isTopoEleImgLoading = false;
      const images: any = JSON.parse(JSON.stringify(symbols?.pngImages));
      const eleName = payload.elementName;
      let activeEle: any = images[eleName];
      if (typeof payload?.data === "object") {
        activeEle = {
          isLoading: false,
          data: payload?.data,
          status: storeConst.ERROR,
        };
      }
      symbols.pngImages = { ...symbols.pngImages, ...{ [eleName]: activeEle } };
    },
  },
});

//should not be exposed to outside
const { getEleImagesBegin, getEleImagesSuccess, getEleImagesFailure } =
  slice.actions;
export default slice.reducer;

//action creators
interface RequestBodyType {
  name: string;
  rotation: number;
  color: string;
  symbolType: string;
}
export const getPngImages =
  (requestBody: Array<RequestBodyType>, elementName: any): any =>
  (dispatch: any) => {
    const uniquePayload = requestBody?.filter(
      (item, index, self) =>
        index ===
        self?.findIndex(
          (t) =>
            t.name === item.name &&
            t.rotation === item.rotation &&
            t.color === item.color &&
            t.symbolType === item.symbolType
        )
    );

    dispatch(
      apiCallBegan({
        baseURL: SYMBOLS_SERVICE_URL,
        url: SYMBOLS_STREAM,
        data: uniquePayload,
        method: "post",
        onStart: getEleImagesBegin.type,
        onSuccess: getEleImagesSuccess.type,
        onError: getEleImagesFailure.type,
        props: { elementName },
      })
    );
  };
