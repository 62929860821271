import { GWDeviceSummary } from "../../../../models/account/diagnostics/cm/GWDeviceSummary";

export const GW_DEVICES_SUMMARY_BEGIN = "GW_DEVICES_SUMMARY_BEGIN";
export const GW_DEVICES_SUMMARY_SUCCESS = "GW_DEVICES_SUMMARY_SUCCESS";
export const GW_DEVICES_SUMMARY_FAILURE = "GW_DEVICES_SUMMARY_FAILURE";

interface GWDeviceSummaryBegin {
  type: typeof GW_DEVICES_SUMMARY_BEGIN;
}

interface GWDeviceSummarySuccess {
  type: typeof GW_DEVICES_SUMMARY_SUCCESS;
  payload: GWDeviceSummary;
}

interface GWDeviceSummaryFailure {
  type: typeof GW_DEVICES_SUMMARY_FAILURE;
  payload: Error;
}

export type GWDeviceSummaryActionTypes =
  | GWDeviceSummaryBegin
  | GWDeviceSummarySuccess
  | GWDeviceSummaryFailure;
