export const MONTHLY_FEEDBACK_BEGIN = "MONTHLY_FEEDBACK_BEGIN";
export const MONTHLY_FEEDBACK_SUCCESS = "MONTHLY_FEEDBACK_SUCCESS";
export const MONTHLY_FEEDBACK_FAILURE = "MONTHLY_FEEDBACK_FAILURE";
export const GET_MONTHLY_FEEDBACK_BEGIN = "GET_MONTHLY_FEEDBACK_BEGIN";
export const GET_MONTHLY_FEEDBACK_SUCCESS = "GET_MONTHLY_FEEDBACK_SUCCESS";
export const GET_MONTHLY_FEEDBACK_FAILURE = "GET_MONTHLY_FEEDBACK_FAILURE";

interface MonthlyFeedbackBegin {
    type: typeof MONTHLY_FEEDBACK_BEGIN;
  }
  interface MonthlyFeedbackSuccess {
    type: typeof MONTHLY_FEEDBACK_SUCCESS;
    payload: any;
  }
  interface MonthlyFeedbackFailure {
    type: typeof MONTHLY_FEEDBACK_FAILURE;
    payload: Error;
  }

  export type MonthlyFeedbackActionTypes =
  | MonthlyFeedbackBegin
  | MonthlyFeedbackSuccess
  | MonthlyFeedbackFailure

  interface GetMonthlyFeedbackBegin {
    type: typeof GET_MONTHLY_FEEDBACK_BEGIN;
  }
  interface GetMonthlyFeedbackSuccess {
    type: typeof GET_MONTHLY_FEEDBACK_SUCCESS;
    payload: any;
  }
  interface GetMonthlyFeedbackFailure {
    type: typeof GET_MONTHLY_FEEDBACK_FAILURE;
    payload: Error;
  }

  export type GetMonthlyFeedbackActionTypes =
  | GetMonthlyFeedbackBegin
  | GetMonthlyFeedbackSuccess
  | GetMonthlyFeedbackFailure