import { Form } from "react-bootstrap";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Card } from "@material-ui/core";
import ErrorBox from "../../reusable/ErrorBox";
import AppLables from "../../../constants/App_Labels";
import RPDClient from "../../../network/httpclient/rpd/RPDClient";
import { setOrderId } from "../../../store/ducksPattern/rpd/rpdOrderInfo";
import { TAF_ORDER_CONST } from "../../../constants/Global";
import {
  formatMacAddress,
  isMacAddressValid,
  formatRPDMacAddress,
  isValidNodeId,
  formatNodeId,
} from "../../reusable/Util";

const RPDOrderDetailTop = (props: any) => {
  const { isLoading, searchData, orderId } = props;
  const labels = AppLables();
  const [clamshellId, setClamshellId] = useState("");
  const [macAddres, setMacAddres] = useState(orderId !== null ? orderId : "");
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("This is Error Message");
  const handleClose = () => {
    setOpen(false);
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleMacAddSearch(macAddres);
    }
  };

  const onClamshellIdSubmit = (e: any) => {
    let sv = formatNodeId(e.target.value);
    if (sv?.length <= 6) setClamshellId(sv);
  };

  const onMacAddressSubmit = (e: any) => {
    let sv = formatMacAddress(e.target.value);
    if (sv?.length <= 99) setMacAddres(sv);
  };

  const createInputMacAdObject = (macAddress: string): any => {
    let inputObject = {
      rpdmacAddress: macAddress,
    };
    return inputObject;
  };

  const handleMacAddSearch = (macAddress: string) => {
    setOrderId("");
    if (macAddress !== undefined) {
      dispatch(setOrderId(macAddress));
      dispatch(RPDClient.getOrderInfo(macAddress));
    } else {
      setOpen(true);
      setError(labels.orderIdEmptyError);
    }
  };

  return (
    <>
      <Card className="filterRootContainer">
        <div className="filterContainer">
          <div className="filterRow">
            <div className="inputContainer">
              <Form.Group>
                <Form.Label className="rpdFormLabel">
                  {labels.Order_ID}
                </Form.Label>
                <Form.Control
                  disabled={false}
                  name="rpdInputField"
                  onChange={onMacAddressSubmit}
                  className="rpdInputField"
                  id="rpdInputField"
                  value={macAddres}
                  onKeyPress={(e: any) => handleKeyPress(e)}
                  placeholder={labels.enter_order_ID}
                />
              </Form.Group>
            </div>
            <div className="topSearchBtn">
              <button
                disabled={isLoading}
                className="applyFilersBtn"
                onClick={() => handleMacAddSearch(macAddres)}
              >
                {labels.SEARCH_BTN_TEXT}
              </button>
            </div>
          </div>
        </div>
      </Card>
      <ErrorBox
        open={open}
        error={error}
        setOpen={setOpen}
        handleClose={handleClose}
      />
    </>
  );
};
export default RPDOrderDetailTop;
