import { useState, useRef } from "react";
import { TbGridDots } from "react-icons/tb";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";

import DrawerAutoClose from "./DrawerAutoClose";
import LocateToPowerSupply from "./LocateToPowerSupply";

export default function TopoSettings() {
  const ref = useRef(null);
  const targetRef = useRef(null);
  const [showPopover, setShowPopover] = useState(false);

  return (
    <div ref={ref} className="topoSettingCont">
      <div
        ref={targetRef}
        onClick={() => setShowPopover(!showPopover)}
        className="topoSettingDropdown"
      >
        <TbGridDots />
      </div>
      <Overlay
        show={showPopover}
        target={targetRef.current}
        placement="bottom"
        container={ref}
        rootClose
        onHide={() => setShowPopover(false)}
      >
        <Popover id="popoverCont">
          <DrawerAutoClose
            showPopover={showPopover}
            setShowPopover={setShowPopover}
          />
          <LocateToPowerSupply
            showPopover={showPopover}
            setShowPopover={setShowPopover}
          />
          {/*=========SAMPLE FUTURE USES BELOW=========
          <section className="popoverItemsCont">Second Item</section>
          <section className="popoverItemsCont">Third Item</section>
          <section className="popoverItemsCont">Fourth Item</section> */}
        </Popover>
      </Overlay>
    </div>
  );
}
