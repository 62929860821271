import { FaSearch } from "react-icons/fa";
import DiagContainer from "../../../../components/reusable/DiagContainer";
import ExceptionBox from "../../../../components/reusable/ExceptionBox";
import { useDispatch } from "react-redux";
import TopologyLabels from "../../../constants/topologyLabels";
import {
  GetLocatorElementData,
  GetOLTSearchProps,
} from "../../../util/reduxFunctions/getTopologyState";
import { useEffect, useState } from "react";
import { findEleFromLocatorEle } from "../../../util/utilFuncs";
import appConst from "../../../constants/appConst";
import {
  setMapCenter,
  setSelectedMapElement,
} from "../../../store/slices/mapItems/mapDetails";
import { addToLocatorEle } from "../../../store/slices/mapItems/locatorElements";
import { getOLTSearch } from "../../../store/slices/search/oltSearch";

const OLTSearch = () => {
  const dispatch = useDispatch();
  const labels = TopologyLabels();
  const { oltData, oltLoading, oltError, oltStatus } = GetOLTSearchProps();
  const { locatorEle } = GetLocatorElementData();
  const [olt, setOlt] = useState("");
  const [oltFlag, setOltFlag] = useState(false);

  const searchOltName = () => {
    if (olt) {
      dispatch(getOLTSearch(olt));
      setOltFlag(true);
    }
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      searchOltName();
    }
  };

  const makeEleActive = (eleData: any) => {
    dispatch(
      setMapCenter({
        lat: eleData?.geometry?.lat,
        lng: eleData?.geometry?.lng,
      })
    );
    dispatch(setSelectedMapElement(eleData));
  };

  const elementData = {
    id: oltData?.features?.[0]?.featureId,
    type: appConst?.vhub,
    data: oltData?.features?.[0]?.properties?.data,
    coord: {
      lat: oltData?.features?.[0]?.geometry?.coordinates?.[1],
      lng: oltData?.features?.[0]?.geometry?.coordinates?.[0],
    },
  };

  useEffect(() => {
    if (
      oltData &&
      oltData !== undefined &&
      oltFlag &&
      !oltLoading &&
      oltStatus === appConst.OK
    ) {
      const geoData = {
        lat: oltData?.features?.[0]?.geometry?.coordinates?.[1],
        lng: oltData?.features?.[0]?.geometry?.coordinates?.[0],
      };
      const activeElement = {
        geometry: geoData,
        properties: {
          id: oltData?.features?.[0]?.featureId,
          type: appConst?.olt,
          data: oltData?.features?.[0]?.properties?.data,
        },
      };
      dispatch(setSelectedMapElement(activeElement));
      dispatch(setMapCenter(geoData));
      oltData?.features?.map((ele: any) => {
        dispatch(
          addToLocatorEle({
            eleType: appConst.olt,
            eleData: ele,
          })
        );
      });

      setOltFlag(false);
    }
  }, [oltData]);

  return (
    <div className="renSearchCont">
      <section>
        <input
          type="text"
          value={olt}
          placeholder="Enter OLT Name"
          onChange={(val: any) => {
            setOlt(val.target.value);
          }}
          onKeyPress={(event: any) => handleKeyPress(event)}
        />
        <button onClick={() => searchOltName()}>
          <FaSearch />
        </button>
      </section>
      <div className="searchResult">
        <DiagContainer
          isLoading={oltLoading}
          data={oltData}
          error={oltError}
          sectionName={"OLT Search"}
        >
          {elementData?.data?.length === 0 && (
            <ExceptionBox headerText={labels.noDataFound} />
          )}
        </DiagContainer>
      </div>
    </div>
  );
};

export default OLTSearch;
