import { Tab, Tabs } from "react-bootstrap";
import { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getDeviceDetails,
  getDeviceLoading,
  isNull,
  renderDeviceStatus,
} from "../reusable/Util";
import ONTClient from "../../network/httpclient/ont/ONTClient";
import ONTSummaryInfo from "./ONTSummaryInfo";
import ONTAlarmsInfo from "./ONTAlarmsInfo";
import ONTProfileInfo from "./ONTProfileInfo";
import ONTIPSession from "./ONTIPSession";

import FailedAttributesOnt from "../FailedAttributes/FailedAttributesOnt";
import { SetDeviceStatus } from "../../store/actions/account/accountInfo/ActiveAccountAction";

const ONTDiagnostics = (props: any) => {
  const dispatch = useDispatch();
  const {
    selectedDevice,
    selectedSA,
    deviceDiagActions,
    selDiagAction,
    handleActionClick,
    gwMAC
  } = props;
  const [key, setKey] = useState(selDiagAction);
  const [alarmTabFlag, setAlarmsTabFlag] = useState(false);
  const [profileTabFlag, setProfileTabFlag] = useState(false);
  const [ipTabFlag, setIPTabFlag] = useState(false);
  const { diagDevicesData, ontAlarmsInfo, ontProfile, ontIP } = useSelector(
    (state: any) => ({
      ontAlarmsInfo: state.AccountState.ONTAlarmsInfo?.data,
      diagDevicesData: state.AccountState.DiagDevicesInfo?.diagDevices,
      ontProfile: state.AccountState.ONTProfileInfo?.data, //change to ont profile
      ontIP: state.AccountState.ONTIPInfo.data, //change to ont ip sessions
    }),
    shallowEqual
  );
  let ontLoading = getDeviceLoading(
    selectedDevice.serialNumber,
    diagDevicesData
  );
  let ontSummaryDetails = getDeviceDetails(
    selectedDevice.serialNumber,
    "summary",
    diagDevicesData
  );
  let ontSummaryStatus =
    ontSummaryDetails?.hasOwnProperty("errorCode") &&
    ontSummaryDetails?.errorCode != 1009
      ? "ERROR"
      : ontSummaryDetails?.hasOwnProperty("errorCode") &&
        ontSummaryDetails?.errorCode == 1009
      ? "Fail"
      : "OK";
  useEffect(() => {
    if (deviceDiagActions?.length !== 0) {
      deviceDiagActions?.map((action: any) => {
        if (action.tab === selDiagAction) {
          if (
            action.tab === "ontAlarms" &&
            !action.isOpen &&
            selectedDevice !== undefined
          ) {
            action.isOpen = true;
            if (ontAlarmsInfo === undefined) {
              dispatch(ONTClient.getONTAlarms(selectedDevice.serialNumber));
            }
          }
          if (
            action.tab === "ontProfile" &&
            !action.isOpen &&
            selectedDevice !== undefined
          ) {
            action.isOpen = true;
            if (ontProfile === undefined) {
              dispatch(ONTClient.getONTProfile(selectedDevice.serialNumber)); //change to ont profile api
            }
          }
          if (
            action.tab === "ontIP" &&
            !action.isOpen &&
            selectedDevice !== undefined
          ) {
            action.isOpen = true;
            if (ontIP === undefined) {
              dispatch(ONTClient.getONTIpSessions(selectedDevice.serialNumber)); //change to ont profile api
            }
          }
        }
        if (action.tab === "ontAlarms" && action.isOpen) {
          setAlarmsTabFlag(true);
        }
        if (action.tab === "ontProfile" && action.isOpen) {
          setProfileTabFlag(true);
        }
        if (action.tab === "ontIP" && action.isOpen) {
          setIPTabFlag(true);
        }
      });
    }
    setKey(selDiagAction);
  }, [selDiagAction]);
  useEffect(() => {
    if (ontSummaryDetails !== undefined && ontSummaryDetails?.length === 0) {
      dispatch(ONTClient.getONTSummary(selectedDevice.serialNumber));
      dispatch(ONTClient.getONTBandwidth(selectedDevice.serialNumber));
      dispatch(ONTClient.getOLTSummary(selectedDevice.serialNumber));
    }
    setAlarmsTabFlag(false);
    setProfileTabFlag(false);
    setIPTabFlag(false);
  }, [selectedDevice]);

  useEffect(() => {
    if (ontSummaryDetails?.length !== 0) {
      dispatch(
        SetDeviceStatus(
          selectedDevice?.serialNumber,
          selectedDevice?.deviceCategory,
          ontSummaryDetails?.hasOwnProperty("overallStatus")
            ? ontSummaryDetails.overallStatus?.toLowerCase()
            : "fail"
        )
      );
    }
  }, [ontLoading]);
  return (
    <div className="detailDiagCont">
      <section className="deviceData">
        <div className="deviceStatusCont">
          <p>
            <span>SERIAL NUMBER : </span>
            <span>{isNull(selectedDevice.serialNumber)}</span>
          </p>
          <p className="deviceOverallStat">
            <span>STATUS : </span>
            {!ontLoading ? (
              renderDeviceStatus(ontSummaryDetails, ontSummaryStatus)
            ) : (
              <span style={{ fontStyle: "italic" }}>Fetching...</span>
            )}
          </p>
        </div>
        <p>
          {ontSummaryDetails && ontSummaryDetails?.overallStatus === "Fail" ? (
            <FailedAttributesOnt ontSummaryInfo={ontSummaryDetails} />
          ) : (
            <></>
          )}
        </p>
      </section>
      <section className="detailDiagTabs">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k: any) => handleActionClick(k)}
        >
          <Tab eventKey="ontSUMMARY" title="ONT SUMMARY">
            <ONTSummaryInfo
              selectedDevice={selectedDevice}
              selectedSA={selectedSA}
              ontLoading={ontLoading}
              gwMAC={gwMAC}
              ontSummaryInfo={ontSummaryDetails}
              ontSummaryStatus={ontSummaryStatus}
            />
          </Tab>
          {alarmTabFlag ? (
            <Tab eventKey="ontAlarms" title="ONT ALARMS">
              <ONTAlarmsInfo selectedDevice={selectedDevice} />
            </Tab>
          ) : (
            <></>
          )}
          {profileTabFlag ? (
            <Tab eventKey="ontProfile" title="ONT PROFILE">
              <ONTProfileInfo selectedDevice={selectedDevice} />
            </Tab>
          ) : (
            <></>
          )}
          {ipTabFlag ? (
            <Tab eventKey="ontIP" title="ONT IP SESSIONS">
              <ONTIPSession selectedDevice={selectedDevice} />
            </Tab>
          ) : (
            <></>
          )}
        </Tabs>
      </section>
    </div>
  );
};

export default ONTDiagnostics;
