import React from "react";
import { HttpClient } from "../HttpClient";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { NetworkConfig } from "../NetworkConfig";

export type GponAddressInfo = {
  serviceAddressId: string;
  serviceAddressInfo: {
    generalAddressInfo: {
      gponHub: string;
      gponNap: string;
      gponNode: string;
      ontSerialNumber: string;
      ontProvStatus: string;
    };
  };
};

export const useGponAddressClient = () => {
  const getGponAddressInfo = React.useCallback(async (samKey: string) => {
    let httpParameters: IHttpClientRequestParameters<any> = {
      url: NetworkConfig.NETWORK_SERVICE_URL + `serviceAddress/${samKey}`,
    };
    // UPDATE IF NEEDED TO DISPATCH ACTIONS TO REDUX
    const result = await HttpClient.get<GponAddressInfo>(httpParameters)
      .then((addressInfo) => {
        // REDUX ACTION
        return addressInfo;
      })
      .catch((error) => {
        // REDUX ACTION
        return error;
      });
    return result;
  }, []);

  return {
    getGponAddressInfo,
  };
};
