import { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import FeedbackHistory from "./FeedbackHistory";
import AppLabels from "../../constants/App_Labels";

const ControlledTabs = (props: any) => {
  const labels = AppLabels();
  const [key, setKey] = useState<string | null>("YOUR FEEDBACK");

  return (
    <Tabs
      id="feedback-tab"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="feedback-tabs"
    >
      <Tab eventKey="YOUR FEEDBACK" title={labels.YOUR_FEEDBACK}>
        <FeedbackHistory
          setIdComments={props.setIdComments}
          setIssueToBeEditted={props.setIssueToBeEditted}
          showForm={props.showForm}
          setEdit={props.setEdit}
          setComments={props.setComments}
          tab="YourFeedback"
        />
      </Tab>
      <Tab eventKey="ALL FEEDBACK" title={labels.ALL_FEEDBACK}>
        <FeedbackHistory
          setIdComments={props.setIdComments}
          setIssueToBeEditted={props.setIssueToBeEditted}
          showForm={props.showForm}
          setEdit={props.setEdit}
          setComments={props.setComments}
          tab="AllFeedback"
        />
      </Tab>
    </Tabs>
  );
};

export default ControlledTabs;
