import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../topology/store/middleware/apiActions";
import {
  NETWORK_SERVICE_URL,
  WO_DETAILS_URI,
} from "../../middleware/apiEndpoints";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "workOrderDetails",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    // action => action handler
    workOrderDetailsBegin: (wodetails) => {
      wodetails.isLoading = true;
    },
    workOrderDetailsSuccess: (wodetails, action) => {
      wodetails.isLoading = false;
      wodetails.data = action.payload;
      wodetails.status = storeConst.OK;
    },
    workOrderDetailsFailure: (wodetails, action) => {
      wodetails.isLoading = false;
      wodetails.error = action.payload;
      wodetails.data = undefined;
      wodetails.status = storeConst.ERROR;
    },
  },
});

export const {
  workOrderDetailsBegin,
  workOrderDetailsSuccess,
  workOrderDetailsFailure,
} = slice.actions;

export default slice.reducer;

//action creators
export const getWorkOrderDetails = (workOrderID: any) =>
  apiCallBegan({
    baseURL: NETWORK_SERVICE_URL,
    url: `${WO_DETAILS_URI}?workOrderID=${workOrderID}`,
    onStart: workOrderDetailsBegin.type,
    onSuccess: workOrderDetailsSuccess.type,
    onError: workOrderDetailsFailure.type,
  });
