import { FeedbackComments } from "../../models/feedback/FeedbackComments";
import {
  FEEDBACK_COMMENTS_BEGIN,
  FEEDBACK_COMMENTS_SUCCESS,
  FEEDBACK_COMMENTS_FAILURE,
  FeedbackCommentsActionTypes,
} from "../../types/feedback/FeedbackComments";

export function feedbackCommentsBegin():FeedbackCommentsActionTypes
{
  return {
    type: FEEDBACK_COMMENTS_BEGIN,
  };
}

export function feedbackCommentsSuccess(
  feedbackComments: FeedbackComments
): FeedbackCommentsActionTypes
{
  return {
    type: FEEDBACK_COMMENTS_SUCCESS,
    payload: feedbackComments,
  };
}

export function feedbackCommentsFailure(
  error: Error
):  FeedbackCommentsActionTypes
{
  return {
    type: FEEDBACK_COMMENTS_FAILURE,
    payload: error,
  };
}
