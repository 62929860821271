import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../middleware/apiActions";
import { NETWORKSITE_SEARCH_URI } from "../../middleware/apiEndpoints";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "smallCellSearch",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    getSmallCellSearchBegin: (sc) => {
      sc.isLoading = true;
      sc.status = "";
    },
    getSmallCellSearchSuccess: (sc, { payload }) => {
      sc.isLoading = false;
      sc.data = payload;
      sc.status = storeConst.OK;
    },
    getSmallCellSearchFailure: (sc, { payload }) => {
      sc.isLoading = false;
      sc.error = payload;
      sc.data = undefined;
      sc.status = storeConst.ERROR;
    },
  },
});

const {
  getSmallCellSearchBegin,
  getSmallCellSearchSuccess,
  getSmallCellSearchFailure,
} = slice.actions;
export default slice.reducer;

export const getSmallCellSearch =
  (searchedSmallCell: string) => (dispatch: any) => {
    dispatch(
      apiCallBegan({
        url: NETWORKSITE_SEARCH_URI + searchedSmallCell,
        onStart: getSmallCellSearchBegin.type,
        onSuccess: getSmallCellSearchSuccess.type,
        onError: getSmallCellSearchFailure.type,
      })
    );
  };
