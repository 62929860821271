import {
  GET_ALL_REASONS_BEGIN,
  GET_ALL_REASONS_FAILURE,
  GET_ALL_REASONS_SUCCESS,
  GetAllReasonsActionTypes,
} from "../../../types/account/certifications/CertiReasons";

export function GetAllReasonsBegin(): GetAllReasonsActionTypes {
  return {
    type: GET_ALL_REASONS_BEGIN,
  };
}

export function GetAllReasonsSuccess(
  allReasons: any
): GetAllReasonsActionTypes {
  return {
    type: GET_ALL_REASONS_SUCCESS,
    payload: allReasons,
  };
}

export function GetAllReasonsFailure(error: any): GetAllReasonsActionTypes {
  return {
    type: GET_ALL_REASONS_FAILURE,
    payload: error,
  };
}
