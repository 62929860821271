import { DropdownButton, Dropdown } from "react-bootstrap";
import { isNull } from "../reusable/Util";
import ExternalAppMenu from "../../components/reusable/ExternalAppMenu";
import AppLabels from "../../constants/App_Labels";
import { IoIosArrowDropdown } from "react-icons/io";
import { Divider } from "@material-ui/core";
import {
  GetWorkOrderDetails
} from "../../get-api/GetResponse";
import { ResetGponNodes } from "../../store/actions/gpon/GponNodeSummaryAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useGponNodeClient } from "../../network/httpclient/gpon/GponNodeClient";
const AccountBasicInfoSM = (props: any) => {
  const {
    data,
    sADdSelectedValue,
    handleAddressSelect,
    serviceAddress,
    selectedSA,
    handleToggle,
  } = props;
  const labels = AppLabels();
  const dispatch = useDispatch();
  const nodeInfoApi = useGponNodeClient();
  const { data: woDetails } = GetWorkOrderDetails();
  const handleNodeLink = async (gponNodeId: string) => {
    dispatch(ResetGponNodes());

    const apiResponse = await nodeInfoApi.getNodesInfo(gponNodeId, "Maestro");

    if (apiResponse?.resultList?.length > 0) {
      // open new window with node details
      let newWindow = window.open(
        `/#/node?gponId=${apiResponse.ponNode}`,
        "_blank"
      )!;
      newWindow.onload = () => {
        newWindow.document.title = "GPON-" + apiResponse?.ponNode;
      };
    } else {
      dispatch(ResetGponNodes);
      alert("Node not found");
    }
  };
  const handleShubLink = async (nodeId: string) => {
    let newWindow = window.open(
      `${window.location.origin}#/` + `${"node?nodeId=" + nodeId}`,
      "_blank"
    )!;
    newWindow.onload = () => {
      newWindow.document.title = "N-" + nodeId;
    };
  };

  return (
    <div className="custInfo">
      <section className="accDetailsSm">
        <p id="custNameEx">
          {isNull(data.firstName)} {isNull(data.lastName)}
        </p>
      </section>
      <section className="accDetailsSm">
        <div>
          <div className="basicAccDetails">
            <p>
              <span>{labels.ACCOUNT_ID?.toUpperCase()}</span>
              <span>{isNull(data.accountNumber)}</span>
            </p>
            <Divider orientation="vertical" />
            <p>
              <span>{(labels.node_id + " ")?.toUpperCase()}</span>
              <span
                className="clearAllLink"
                onClick={() => {selectedSA?.shubId?.toUpperCase() !='PON' ?
                  handleShubLink(selectedSA?.shubId): handleNodeLink(woDetails?.ponNode);
                }}
              >
                {isNull(selectedSA?.shubId?.toUpperCase() !='PON' ?
                  selectedSA?.shubId: woDetails?.ponNode)}
              </span>
            </p>
            <Divider orientation="vertical" />
            <p>
              <span>{(labels.SMT_ID + " ")?.toUpperCase()}</span>
              <span
                className="clearAllLink"
                onClick={() => {selectedSA?.smtId?.toUpperCase() !='PON' ?
                  handleShubLink(
                  selectedSA?.smtId): handleNodeLink( woDetails?.ponNap );
                }}
              >
                {isNull(selectedSA?.smtId?.toUpperCase() !='PON' ?
                  selectedSA?.smtId: woDetails?.ponNap)}
              </span>
            </p>
          </div>
          <div>
            <span>{labels.SERVICE_ADDRESS?.toUpperCase()}:</span>
            <DropdownButton
              title={sADdSelectedValue}
              onSelect={handleAddressSelect}
              size="sm"
              className="sADropd"
              id="sAddressDdSm"
            >
              {serviceAddress?.map((sa: any, index: number) => {
                return (
                  <Dropdown.Item eventKey={sa} key={index}>
                    {sa}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
          </div>
        </div>
        <div className="appMenuCont">
          <ExternalAppMenu />
          <IoIosArrowDropdown size={25} onClick={() => handleToggle()} />
        </div>
      </section>
    </div>
  );
};

export default AccountBasicInfoSM;
