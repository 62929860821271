import FailedAttributesCm from "./FailedAttributesCm";
import FailedAttributesIptv from "./FailedAttributesIptv";
import FailedAttributesOnt from "./FailedAttributesOnt";
import FailedAttributesOdu from "./FailedAttributesOdu";
import FailedAttributesStb from "./FailedAttributesStb";
import FailedAttributesRhp from "./FailedAttributesRhp";
import MarginalPassAttributesCm from "./MarginalPassAttributesCm";

const FailedAttributesCert = (props: any) => {
  const { selectedDevice, deviceDetails } = props;

  let stbDevices = ["STB", "DESTB", "GSTB", "HSTB", "IPSTB"];
  const result = deviceDetails.find(
    (data: any) => data?.deviceKey === selectedDevice?.serialNumber
  );
  return (
    <>
      {selectedDevice?.deviceCategory === "CM" ? (
        <>
          <FailedAttributesCm cmDocsisInfo={result?.data?.docsis} />
          <MarginalPassAttributesCm cmDocsisInfo={result?.data?.docsis} />
        </>
      ) : selectedDevice?.deviceCategory === "IPTV" ? (
        <FailedAttributesIptv
          ethernetData={result?.data?.ethernet}
          wifiData={result?.data?.wifi}
        />
      ) : selectedDevice?.deviceCategory === "ONT" ? (
        <FailedAttributesOnt ontSummaryInfo={result?.data?.summary} />
      ) : selectedDevice?.deviceCategory === "ODU" ? (
        <FailedAttributesOdu oduWirelessInfo={result?.data?.wirelessInfo} />
      ) : stbDevices?.indexOf(selectedDevice?.deviceCategory) > -1 ? (
        <>
          {((result?.data?.summary &&
            result?.data?.summary?.overallStatus === "Pass") ||
            result?.data?.summary === undefined) &&
          result?.data?.docsis &&
          result?.data?.docsis?.overallStatus === "Fail" ? (
            <FailedAttributesStb
              stbDocsisInfo={result?.data?.docsis}
              type="stbDocsisInfo"
            />
          ) : (
            <></>
          )}
          {result?.data?.summary &&
          result?.data?.summary?.overallStatus === "Fail" &&
          ((result?.data?.docsis &&
            result?.data?.docsis?.overallStatus === "Pass") ||
            result?.data?.docsis === undefined) ? (
            <FailedAttributesStb
              stbSummaryInfo={result?.data?.summary}
              type="stbSummaryInfo"
            />
          ) : (
            <></>
          )}
          {result?.data?.summary &&
          result?.data?.summary?.overallStatus === "Fail" &&
          result?.data?.docsis &&
          result?.data?.docsis?.overallStatus === "Fail" ? (
            <FailedAttributesStb
              stbSummaryInfo={result?.data?.summary}
              stbDocsisInfo={result?.data?.docsis}
              type="both"
            />
          ) : (
            <></>
          )}
        </>
      ) : selectedDevice?.deviceCategory === "EMTA" ? (
        <FailedAttributesRhp rhpDocsisInfo={result?.data?.docsis} />
      ) : (
        <></>
      )}
    </>
  );
};

export default FailedAttributesCert;
