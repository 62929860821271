import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import TreeView, { TreeItem, TreeItemTypes } from "../../reusable/TreeView";
import { useOltCardsStates } from "../../../util/reduxFunctions/getTopologyState";
import ExceptionHandlerWrapper from "../../reusable/ExceptionHandlerWrapper";
import TopologyLabels from "../../../constants/topologyLabels";
import { getGponOltCards } from "../../../store/slices/drawer/olt/oltCards";

interface Props {
  elementData: any;
}

export default function OLTCards({ elementData }: Props) {
  const labels = TopologyLabels();
  const dispatch = useDispatch();
  const { data, isLoading, status, error } = useOltCardsStates();
  const [collapsed, setCollapsed] = useState(true);
  const [selectedId, setSelectedId] = useState("");
  const networkId = elementData?.data?.rackNetworkId;

  useEffect(() => {
    if (!data && status !== "ERROR") dispatch(getGponOltCards(networkId));
  }, []);

  return (
    <ExceptionHandlerWrapper
      status={status}
      isLoading={isLoading}
      error={error}
      data={data}
      sectionName={labels.OLTCards}
      onRefresh={() => dispatch(getGponOltCards(networkId))}
      failContStyle={{ marginTop: 5 }}
    >
      <TreeView
        title={labels.OLTCards}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      >
        {data?.map((e: any, i: number) => (
          <TreeItem
            {...getItemData(e, i)}
            collapse={collapsed}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
          />
        ))}
      </TreeView>
    </ExceptionHandlerWrapper>
  );
}
const getItemData = (e: any, i: number): any => {
  return {
    key: e.networkId,
    id: e.networkId,
    name: `${i + 1}: ${e.networkClass} ${e.name ? `(${e.name})` : ""}`,
    children: e.child?.map((item: any, index: number) =>
      getItemData(item, index)
    ),
    otherElements: [
      getProperty(i, e, "networkId"),
      getProperty(i, e, "model"),
      getProperty(i, e, "sectionFCode"),
    ],
  } as TreeItemTypes;
};

const getProperty = (i: number, e: any, name: string) => {
  const value = e?.[name];
  if (value) return { id: i + value, value: name + ": " + value };
};
