import { createSlice } from "@reduxjs/toolkit";
import storeConst from "../storeConst";

const slice = createSlice({
  name: "nodeAveragesStatus",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    nodeAveragesBegin: (nodeAverages) => {
      nodeAverages.isLoading = true;
    },
    nodeAveragesSuccess: (nodeAverages, action) => {
      nodeAverages.isLoading = false;
      nodeAverages.data = action.payload;
      nodeAverages.status = storeConst.OK;
    },
    nodeAveragesFailure: (nodeAverages, action) => {
      nodeAverages.isLoading = false;
      nodeAverages.error = action.payload;
      nodeAverages.data = undefined;
      nodeAverages.status = storeConst.ERROR;
    },
  },
});

export const { nodeAveragesBegin, nodeAveragesSuccess, nodeAveragesFailure } =
  slice.actions;

export default slice.reducer;
