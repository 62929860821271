import { format } from "date-fns";
import CustomAccordion from "../../../../components/reusable/CustomAccordion";
import ExceptionBox from "../../../../components/reusable/ExceptionBox";
import { hzToMHz, isNull } from "../../../../components/reusable/Util";
import TopologyLabels from "../../../constants/topologyLabels";
import { GetCpatEventsProps } from "../../../util/reduxFunctions/getTopologyState";

export default function CPATTable() {
  const labels = TopologyLabels();
  const { data } = GetCpatEventsProps();
  let tableData = [] as any;
  data?.features?.map((allTypes: any) => {
    tableData?.push({
      adress: allTypes?.properties?.data?.adress,
      dateLastDetected: allTypes?.properties?.data?.dateLastDetected,
      freqLkLTE: allTypes?.properties?.data?.freqLkLTE,
      descSector: allTypes?.properties?.data?.descSector,
      id: allTypes?.properties?.data?.id,
      address: allTypes?.properties?.data?.address,
      freqLkMid: allTypes?.properties?.data?.freqLkMid,
      leakMid: allTypes?.properties?.data?.leakMid,
      freqIngress: allTypes?.properties?.data?.freqIngress,
      ingress: allTypes?.properties?.data?.ingress,
      leakLTE: allTypes?.properties?.data?.leakLTE,
      status: allTypes?.properties?.data?.status,
    });
  });

  return (
    <div className="activeMapEleCont">
      {tableData?.length !== 0 ? (
        <CustomAccordion
          accTitle={labels.cpatEvents}
          accBodyElement={
            <div className="cpatEventsTable">
              <table className="dataTableDetails">
                <thead>
                  <tr>
                    <th style={{ minWidth: 60 }}>{labels.eventNumber}</th>
                    <th style={{ minWidth: 60 }}>{labels.node}</th>
                    <th>{labels.address}</th>
                    <th>{labels.detectionDate}</th>
                    <th style={{ minWidth: 60 }}>{labels.status}</th>
                    <th>{labels.midLeakage}</th>
                    <th>{labels.midFreq}</th>
                    <th>{labels.lteLeakage}</th>
                    <th>{labels.lteFreq}</th>
                    <th>{labels.ingress}</th>
                    <th>{labels.ingressFreq}</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.map((e: any) => (
                    <tr key={e.id}>
                      <td>{e.id}</td>
                      <td>{e.descSector}</td>
                      <td>{e.adress}</td>
                      <td>{format(e.dateLastDetected, "yyyy-MM-dd HH:mm")}</td>
                      <td>{e.status}</td>
                      <td>{e.leakMid}</td>
                      <td>{isNull(hzToMHz(e.freqLkMid).toFixed(1))}</td>
                      <td>{e.leakLTE}</td>
                      <td>
                        {e.freqLkLTE == "-9999"
                          ? e.freqLkLTE
                          : hzToMHz(e.freqLkLTE).toFixed(1)}
                      </td>
                      <td>{e.ingress}</td>
                      <td>
                        {e.freqIngress == "-9999"
                          ? e.freqIngress
                          : hzToMHz(e.freqIngress).toFixed(1)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          }
        />
      ) : (
        <div className="diagErrorCont">
          <ExceptionBox
            headerText={labels.NoSearchHistory}
            bodyText={labels.PerformSearchToSeeTheResults}
          />
        </div>
      )}
    </div>
  );
}
