import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import { Form } from "react-bootstrap";
import {
  GetTechInfo,
  GetWorkOrdersList,
  GetNodeInfoDetails,
  GetPostNodeCertificateResp,
} from "../../get-api/GetResponse";
import AppLabels from "../../constants/App_Labels";
import { Divider } from "@material-ui/core";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { MdRefresh } from "react-icons/md";
import ActivityIndicator from "../reusable/ActivityIndicator";
import WorkOrdersClient from "../../network/httpclient/workorders/WorkOrdersClient";
import InlineErrorMessage from "../reusable/InlineErrorMessage";
import { postNodeCertDetails } from "../../store/slices/nodecertifications/postNodeCertifications";
import { NodeCertificationData } from "./NodeCertificationData";
import ResponseSnackBar from "../reusable/ResponseSnackBar";
import ConfigConst from "../../constants/ConfigConst";
import LoaderGif from "../../resources/images/loader.webp";
import { getAccessLevelByRoles } from "../reusable/Util";

interface Props {
  closePopup?: any;
}
const WOSelection = (props: Props) => {
  const labels = AppLabels();
  const workOrderList = GetWorkOrdersList();
  const techData = GetTechInfo()?.data;
  const nodeData = GetNodeInfoDetails()?.currNodedata;
  const postNodeCert = GetPostNodeCertificateResp();
  const dispatch = useDispatch();
  const [WOData, setWOData] = useState([labels.Select]);
  const [selectedWOID, setSelectedWOID] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [comments, setComments] = useState("");

  useEffect(() => {
    if (workOrderList?.data === undefined) {
      dispatch(
        WorkOrdersClient.getTechWorkOrders(
          techData?.techId,
          techData?.name,
          getAccessLevelByRoles(techData?.roles).includes(ConfigConst.MT)
        )
      );
    }
  }, []);
  useEffect(() => {
    if (workOrderList?.data) {
      const listOfWOID = workOrderList?.data
        .map((item: any) => {
          if (item.shubId === nodeData?.nodeId) return item.id;
        })
        .filter((item: any) => item);
      setWOData([labels.Select, ...listOfWOID]);
    }
  }, [workOrderList?.data]);

  // useEffect(() => {
  //   if (
  //     !postNodeCert?.postNodeCertIsLoading &&
  //     postNodeCert?.postNodeCertStatus != ""
  //   ) {
  //     if (postNodeCert?.postNodeCertStatus === ConfigConst.OK)
  //       props?.closePopup();
  //   }
  // }, [postNodeCert?.postNodeCertIsLoading]);

  const prepareJson = NodeCertificationData({ selectedWOID, comments });
  const onRefresh = () => {
    dispatch(
      WorkOrdersClient.getTechWorkOrders(
        techData?.techId,
        techData?.name,
        getAccessLevelByRoles(techData?.roles).includes(ConfigConst.MT)
      )
    );
  };
  const onWorkOrderIDChange = (value: any) => {
    value !== labels.Select ? setSelectedWOID(value) : setSelectedWOID("");
  };

  const handleSubmit = () => {
    setErrorMessage("");
    dispatch(postNodeCertDetails(prepareJson));
  };

  return (
    <>
      <div className="nodeCertWoPopup">
        <label className="labelTxt">{labels.Select_Work_Order_ID}</label>
        <div className="nodeCertWOIDContainer">
          {workOrderList.isLoading ? (
            <ActivityIndicator
              style={{ width: "20%", height: "20%" }}
              animationAlignement="none"
            />
          ) : (
            <MdRefresh className="refreshIcon" onClick={onRefresh} />
          )}
          <DropdownButton
            title={selectedWOID === "" ? labels.Select : selectedWOID}
            onSelect={onWorkOrderIDChange}
            size="sm"
            id="dropdownBtn"
            style={{ width: "100%" }}
          >
            {WOData?.map((e: any, i: number) => (
              <Dropdown.Item key={i} eventKey={e}>
                {e}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </div>
        <div className="nodeCertCommentsContainer">
          <Form.Group className="selector">
            <Form.Control
              onChange={(e) => setComments(e.target.value)}
              as="textarea"
              rows={3}
              maxLength={200}
              value={comments}
              placeholder={"Enter Comments Here" + "..."}
            />
          </Form.Group>
        </div>
        <InlineErrorMessage
          errorMessage={errorMessage}
          style={{ margin: "20px" }}
        />
      </div>
      <Divider style={{ margin: "20px 0px 20px 0px" }} />
      <div className="nodeCertSubmitBtnWrapper">
        <Button
          variant="contained"
          onClick={handleSubmit}
          className="certifyBtn"
        >
          {labels.SUBMIT}
        </Button>
        {postNodeCert?.postNodeCertIsLoading && (
          <img
            className="loaderImg"
            style={{ height: "30px" }}
            src={LoaderGif}
          />
        )}
      </div>
    </>
  );
};
export default WOSelection;
