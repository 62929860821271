import { GWDevice } from "../../../../models/account/diagnostics/cm/GWDevice";

export const GW_DEVICES_BEGIN = "GW_DEVICES_BEGIN";
export const GW_DEVICES_SUCCESS = "GW_DEVICES_SUCCESS";
export const GW_DEVICES_FAILURE = "GW_DEVICES_FAILURE";

interface GWDeviceBegin {
  type: typeof GW_DEVICES_BEGIN;
}

interface GWDeviceSuccess {
  type: typeof GW_DEVICES_SUCCESS;
  payload: GWDevice;
}

interface GWDeviceFailure {
  type: typeof GW_DEVICES_FAILURE;
  payload: Error;
}

export type GWDeviceActionTypes =
  | GWDeviceBegin
  | GWDeviceSuccess
  | GWDeviceFailure;
