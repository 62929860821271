import React from "react";
import "../../../../design-tokens/forms/EPR.scss";
import RedAsterisk from "../../../reusable/RedAsterisk";
import SignatureCapture from "../../../reusable/SignatureCapture";

interface Props {
  labels: any;
  acknowledgment: any;
  setAcknowledgment: any;
  signatureImage: any;
  setSignatureImage: any;
}

const Confirmation = (props: Props) => {
  return (
    <>
      <label className="titleLabel">{props.labels.Confirmation}</label>
      <label className="labelTxt">
        {
          "I Acknowledge that the technician is picking up the equipment stated on this form"
        }
        <RedAsterisk />
      </label>
      <div className="checkboxContainer">
        <label>{props.labels.Yes}</label>
        <input
          type="checkbox"
          checked={props.acknowledgment}
          style={{ width: "25px", height: "25px" }}
          onChange={(event) => props.setAcknowledgment(event.target.checked)}
        />
      </div>
      <label className="labelTxt">
        {props.labels.Customers_Signature}
        <RedAsterisk />
      </label>
      <SignatureCapture
        signatureImage={props.signatureImage}
        onSignedImageChange={props.setSignatureImage}
      />
    </>
  );
};

export default Confirmation;
