import { useEffect, useState } from "react";
import { FaRegPlayCircle, FaRegStopCircle } from "react-icons/fa";
import { DropdownButton, Dropdown } from "react-bootstrap";

import { convertHealthData, isNull, openNewWindow } from "../reusable/Util";
import AppLabels from "../../constants/App_Labels";
import LoaderGif from "../../resources/images/loader.webp";
import { getTechRoles } from "../../components/reusable/NodeUtil";
import { GetTechInfo } from "../../get-api/GetResponse";
import CustomDropDown from "../reusable/CustomDropDown";
import NodeTopologySwitch from "../node/NodeTopologySwitch";
import { MultiSelectDropdown } from "../reusable/MultiSelectDropdown";

const NodeBasicInfoSM = (props: any) => {
  const {
    data,
    isLoading,
    nodeScoreDataConfig,
    stopDiag,
    startDiag,
    mlData,
    allDiagIsLoading,
    dataSetName,
    setDataSetName,
    dataSetNames,
  } = props;
  const labels = AppLabels();
  const [smtValue, setSmtValue] = useState(labels.ALL?.toUpperCase());
  const techData = GetTechInfo()?.data;
  const [hyperlink, setHyperlinks] = useState<null | JSX.Element>(null);

  useEffect(() => {
    props.smtSearchId(data?.smtId);
  }, [data?.smtId]);
  const vhubPeers: Array<string> =
    data?.vhubPeers?.indexOf(",") !== -1
      ? data?.vhubPeers?.split(",")
      : data?.vhubPeers?.length > 1
      ? [data?.vhubPeers]
      : [];
  const renderServiceAreas = () => {
    if (data && data?.serviceAreas) {
      let serviceAreas = data?.serviceAreas?.split(", ");
      serviceAreas?.unshift(labels.ALL?.toUpperCase());
      return serviceAreas;
    }
    return null;
  };
  const onButtonClick = () => {
    const links = handleVhubClick();
    setHyperlinks(<>{links}</>);
  };
  const handleVhubClick = () => {
    return (
      <div className="vhubPeersContainerSM">
        {vhubPeers && vhubPeers?.length > 0 ? (
          vhubPeers?.map((node: string, i: number) => {
            return (
              <span
                className="vhubLink"
                onClick={() => {
                  if (data && window) {
                    openNewWindow(`/#/node?nodeId=${node}`);
                  }
                }}
              >
                {isNull(node)}
              </span>
            );
          })
        ) : (
          <span>{labels.NA}</span>
        )}
      </div>
    );
  };

  return (
    <div className="nodeInfo">
      <section className="nodeDetailsSm">
        {data?.smt ? (
          <div className="nodeDataEle" id="nodeNameSM">
            <span>{labels.SMT_ID.toUpperCase()}:</span>
            <span>{isNull(data?.smtId)}</span>
          </div>
        ) : (
          <div className="nodeDataEle" id="nodeNameSM">
            <span>{labels.NODE_ID.toUpperCase()}: </span>
            <span>{isNull(data?.nodeId)}</span>
          </div>
        )}

        <div className="nodeDataEle">
          <span>{labels.PHUB.toUpperCase()}: </span>
          <span>{isNull(data?.phub)}</span>
          {data?.vhub === "true" && (
            <>
              <button onClick={onButtonClick} className="vhub">
                <span>VHUB</span>
              </button>
              <div> {hyperlink}</div>
            </>
          )}
          {data?.nodeType === "R-PHY" && <span className="vhub">RPHY</span>}
        </div>
        <div className="nodeDataEle">
          {data?.smt ? (
            <>
              <span>{labels.NODE_ID.toUpperCase()}:</span>
              <span
                className="nodeLink"
                onClick={() => {
                  if (data && window) {
                    openNewWindow(`/#/node?nodeId=${data?.nodeId}`);
                  }
                }}
              >
                {isNull(data?.nodeId)}
              </span>
            </>
          ) : (
            <>
              <span>{labels.SMT.toUpperCase()}: </span>
              <DropdownButton
                title={smtValue}
                key={"smtDropdown"}
                onSelect={(e: any) => {
                  setSmtValue(e);
                  props.onSelectedSMT(e);
                }}
                size="sm"
                className="serviceAreas"
                id="serviceAreas"
              >
                {renderServiceAreas()?.map((smt: any, i: number) => {
                  return (
                    <Dropdown.Item key={`${smt}+ddItem+${i}`} eventKey={smt}>
                      {smt}
                    </Dropdown.Item>
                  );
                })}
              </DropdownButton>
            </>
          )}
        </div>
      </section>

      <section className="nodeDetailsSm">
        {nodeScoreDataConfig?.status
          ?.filter(
            (attr: any) =>
              !attr?.label?.includes(labels.START) &&
              !attr?.label?.includes(labels.END)
          )
          ?.map((attr: any, i: number) => {
            let computedVal = convertHealthData(
              attr?.label,
              attr?.dataKey,
              labels
            );
            return (
              <div
                key={"status" + attr?.label + attr?.dataKey}
                className="smHealthScore nodeDataEle"
              >
                <span>{attr?.label}:</span>
                <span>
                  {attr?.label !== labels.START ? (
                    isLoading() ? (
                      <img
                        className="nodeTeleDataLoader"
                        src={LoaderGif}
                        alt="LoaderGif"
                      />
                    ) : (
                      computedVal
                    )
                  ) : (
                    computedVal
                  )}
                </span>
              </div>
            );
          })}
      </section>
      <section className="nodeDetailsSm">
        <div className="runTeleSecSm">
          <div className="teleSeleCont">
            <>
              <div className="modemTypeContainer nodeDataEle">
                <span className="modemTypeContainerText">DEVICE TYPE:</span>
                <MultiSelectDropdown
                  defalutValue={props.modemTypeList[0]}
                  data={props.modemTypeList}
                  checkboxFlag={true}
                  handleClick={props.onSelectedModemType}
                  errorText={props.errorText}
                />
              </div>
              <div className="modemTypeContainer nodeDataEle">
                <span className="modemTypeContainerText">
                  {labels.DATA_SET.toUpperCase()}:
                </span>
                <span>
                  <CustomDropDown
                    selectedOption={dataSetName}
                    options={dataSetNames}
                    searchFlag={false}
                    checkboxFlag={false}
                    setSelectedOption={setDataSetName}
                  />
                </span>
              </div>
            </>

            <div className="diagButHolder nodeDataEle">
              {mlData !== undefined && mlData?.length !== 0 && (
                <>
                  {!allDiagIsLoading ? (
                    <button
                      className="refreshCertiBtn"
                      onClick={() => startDiag()}
                    >
                      {labels.START?.toUpperCase()} <FaRegPlayCircle />
                    </button>
                  ) : (
                    <button
                      className="refreshCertiBtn"
                      onClick={() => stopDiag()}
                    >
                      {labels.STOP?.toUpperCase()} <FaRegStopCircle />
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
          <NodeTopologySwitch />
        </div>
      </section>
    </div>
  );
};

export default NodeBasicInfoSM;
