import {AllNodesState} from "../../states/node/AllNodesState";
import {
  ADD_NODE,
  SET_ACTIVE_KEY,
  AllNodeActionTypes,
  DELETE_NODE,
  RESET_ALL_NODE,
  UPDATE_NODE,
} from "../../types/node/AllNodes";

const initialState: AllNodesState = {
  activekey: "",
  nodes: [],
};
const AllNodesReducer = (
  state = initialState,
  action: AllNodeActionTypes
): AllNodesState => {
  switch (action.type) {
    case ADD_NODE:
      if (state.nodes?.length > 0) {
        let filteredNode = state.nodes?.filter(
          (a: any) => a.entityNum === action.entityNum
        );
        if (filteredNode !== undefined && filteredNode?.length === 0) {
          return {
            ...state,
            nodes: [
              ...state.nodes,
              ...[
                {
                  entityNum: action.entityNum,
                  entityType: action.entityType,
                  isUpdated: false,
                  nodeState: action.payload,
                },
              ],
            ],
          };
        } else {
          return state;
        }
      } else {
        return {
          ...state,
          nodes: [
            {
              entityNum: action.entityNum,
              entityType: action.entityType,
              isUpdated: false,
              nodeState: action.payload,
            },
          ],
        };
      }

   
    case DELETE_NODE:
      let tempNodes = state.nodes?.filter(
        (node: any) => node.entityNum !== action.entityNum
      );
      return {
        ...state,
        nodes: tempNodes,
      };

    case UPDATE_NODE:
      return {
        ...state,
        nodes: state.nodes?.map((node: any) => {
          if (node.entityNum === action.entityNum) {
            let updatedNodeState = { ...node.nodeState, ...action.payload };
            return {
              ...node,
              ...{ isUpdated: true, nodeState: updatedNodeState },
            };
          }
          return node;
        }),
      };
    case RESET_ALL_NODE:
      return {
        ...state,
        nodes: [],
      };
    case SET_ACTIVE_KEY:
        return {
          ...state,
          activekey: action.entityNum,
           
      };  
    default:
      return state;
  }
};
export default AllNodesReducer;
