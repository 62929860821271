import CustomRadioGroup from "../../../../../components/reusable/CustomRadioGroup";
import AppLabels from "../../../../../constants/App_Labels";

interface Props {
  inspectionPhysicalWork: any;
  setInspectionPhysicalWork: any;
}

export default function InspectionPhysicalWorkView(props: Props) {
  const labels = AppLabels();
  const checkboxArr = [
    labels.Yes?.toLowerCase(),
    labels.No?.toLowerCase(),
    labels.na?.toLowerCase(),
  ];

  const onClickCheck = (index: any, aValue: any) => {
    let tempArray = [...props.inspectionPhysicalWork];
    tempArray[index].value = aValue;
    props.setInspectionPhysicalWork(tempArray);
  };

  return (
    <div>
      {props.inspectionPhysicalWork?.map((aselection: any, index: any) => (
        <div key={index}>
          <CustomRadioGroup
            label={aselection?.label}
            selectedValueIndex={checkboxArr?.indexOf(aselection?.value)}
            data={checkboxArr}
            onClick={(value: number) => onClickCheck(index, value)}
          />
        </div>
      ))}
    </div>
  );
}
