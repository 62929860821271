import {
  ADD_NODE,
  SET_ACTIVE_KEY,
  AllNodeActionTypes,
  DELETE_NODE,
  SWITCH_NODE,
  RESET_ALL_NODE,
  UPDATE_NODE,
} from "../../types/node/AllNodes";

export function AddNode(
  entityNum: string,
  nodeState: any,
  entityType: string
): AllNodeActionTypes {
  return {
    type: ADD_NODE,
    entityNum: entityNum,
    entityType: entityType,
    payload: nodeState,
  };
}

export function SetActiveKey(entityNum: string): AllNodeActionTypes {
  return {
    type: SET_ACTIVE_KEY,
    entityNum: entityNum,
  };
}

export function DeleteNode(entityNum: string): AllNodeActionTypes {
  return {
    type: DELETE_NODE,
    entityNum: entityNum,
  };
}

export function UpdateNode(
  entityNum: string,
  nodeState: any
): AllNodeActionTypes {
  return {
    type: UPDATE_NODE,
    entityNum: entityNum,
    payload: nodeState,
  };
}

export function ChangeNodeState(nodeState: any): AllNodeActionTypes {
  return {
    type: SWITCH_NODE,
    payload: nodeState,
  };
}
export function ResetAllNodeState(): AllNodeActionTypes {
  return {
    type: RESET_ALL_NODE,
  };
}
