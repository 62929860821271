import React, { useEffect, useRef, useState } from "react";

import { Checkbox, FormControlLabel } from "@material-ui/core";

import { FaAngleDown, FaCheckSquare } from "react-icons/fa";
import { ImCheckboxUnchecked } from "react-icons/im";

type Props = {
  title?: string;
  defalutValue?: any;
  data?: any[];
  checkboxFlag?: boolean;
  handleClick?: (data: any) => void;
  style?: any;
  errorText?: string;
};

export const MultiSelectDropdown = (props: Props) => {
  const {
    title,
    data,
    checkboxFlag,
    handleClick,
    defalutValue,
    style,
    errorText,
  } = props;

  const [options, setOptions] = useState<any>([]);
  const [optionsDisplay, setOptionsDisplay] = useState(false);
  const [selected, setSelected] = useState<any>([]);
  const wrapperRef: any = useRef([]);

  const handleOptionClick = (option: any) => {
    const newOptions = options.map((o: any) => {
      if (o.label === option.label) {
        return {
          ...o,
          checked: !o.checked,
        };
      }
      return o;
    });
    setOptions(newOptions);
    setSelected(newOptions.filter((o: any) => o.checked));
    if (handleClick) {
      handleClick(newOptions);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    const { current: wrap } = wrapperRef;
    if (wrap && wrap?.length !== 0 && !wrap?.contains(event.target)) {
      setOptionsDisplay(false);
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (defalutValue && data) {
      const options = data.map((item: any) => {
        if (item?.label === defalutValue?.label) {
          return {
            label: item?.label?.toString(),
            checked: true,
          };
        }
        return {
          label: item?.label?.toString(),
          checked: false,
        };
      });
      setOptions(options);
      setSelected(options.filter((o: any) => o.checked));
    } else if (data) {
      const options = data.map((item: any) => {
        return {
          label: item?.label?.toString(),
          checked: false,
        };
      });
      setOptions(options);
    }
  }, []);
  return (
    <div style={props.style ? props.style : {}}>
      <button
        className="custDDBtn"
        onClick={() => setOptionsDisplay(!optionsDisplay)}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
            flexWrap: "nowrap",
          }}
        >
          {title && <b>{title?.toString()?.toUpperCase()}</b>}
          {selected?.length > 0 && (
            <span className="multiSelectSelectedSpan">
              {selected?.map((s: any) => s?.label).join(", ")}
            </span>
          )}
        </span>
        <span>
          <FaAngleDown />
        </span>
      </button>
      {optionsDisplay && (
        <>
          <div ref={wrapperRef} className="custDDOptionsCont">
            <div className="optionsList">
              {options?.length > 0 ? (
                React.Children.toArray(
                  options?.map((o: any, index: any) => {
                    return (
                      <div className="custDDOption">
                        <FormControlLabel
                          className="WHTCheck"
                          control={
                            <Checkbox
                              hidden={!checkboxFlag}
                              checkedIcon={
                                <FaCheckSquare color="#da291c" size={20} />
                              }
                              icon={
                                <ImCheckboxUnchecked
                                  color="#da291c"
                                  size={20}
                                />
                              }
                              checked={o?.checked}
                              onChange={() => {
                                handleOptionClick(o);
                              }}
                            />
                          }
                          label={o?.label?.toString()?.toUpperCase()}
                        />
                      </div>
                    );
                  })
                )
              ) : (
                <div className="option">
                  <span className="historyVal">No Value Found...</span>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {errorText && <span className="errorText">{errorText}</span>}
    </div>
  );
};
