import { Marker } from "@react-google-maps/api";
import React, { Fragment } from "react";

import { mapElementStyles } from "../../../../config/mapItemsConfig";
import appConst from "../../../../constants/appConst";
import { GetNodeSiteDetailsProps } from "../../../../util/reduxFunctions/getTopologyState";
import {
  getBase64ScaledImageAnchor,
  getMapElementClickableImage,
  getPngImagesForElement,
} from "../../../../util/utilFuncs";

interface Props {
  pngImages: any;
  handleElementClick: any;
  zoomLevel: number;
  isVisible: boolean;
}

const LCPMarker = ({
  pngImages,
  handleElementClick,
  zoomLevel,
  isVisible,
}: Props) => {
  const { allNodeData } = GetNodeSiteDetailsProps();

  return (
    <>
      {isVisible &&
        allNodeData.map((site:any) =>
          site?.localConvergencePoint?.data?.features?.map((f: any) => {
            const pData = f?.properties?.data;
            const markerLat = f?.geometry?.coordinates?.[1];
            const markerLng = f?.geometry?.coordinates?.[0];

            const anchor = getBase64ScaledImageAnchor(
              pngImages,
              appConst.LCP,
              pData?.symbolName,
              pData?.rotation,
              mapElementStyles?.[appConst.LCP],
              zoomLevel
            );

            return (
              markerLat &&
              markerLng && (
                <Fragment key={f?.featureId}>
                  <Marker
                    position={{ lat: markerLat, lng: markerLng }}
                    icon={getPngImagesForElement(
                      pngImages,
                      pData?.symbolName,
                      pData?.rotation,
                      mapElementStyles?.[appConst.LCP],
                      zoomLevel
                    )}
                    clickable={false}
                  />

                  <Marker
                    position={{ lat: markerLat, lng: markerLng }}
                    onClick={() => handleElementClick(f, appConst.LCP)}
                    icon={{
                      url:
                        appConst.imageStringPrefix +
                        encodeURIComponent(
                          getMapElementClickableImage(
                            zoomLevel,
                            anchor?.clickIconSize
                          )
                        ),
                      anchor: new google.maps.Point(
                        anchor ? anchor?.x : mapElementStyles?.common.anchor.x,
                        anchor ? anchor?.y : mapElementStyles?.common.anchor.y
                      ),
                    }}
                  />
                </Fragment>
              )
            );
          })
        )}
    </>
  );
};

export default React.memo(LCPMarker);
