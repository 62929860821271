import DiagDetails from "./DiagDetails";
import DiagFilters from "./DiagFilters";
import "../../../styles/drawer/diagnostics/TopologyDiag.scss";
import DiagLegends from "./DiagLegends";

const DiagMainScreen = () => {
  return (
    <div>
      <DiagLegends />
      <DiagFilters />
      <DiagDetails />
    </div>
  );
};

export default DiagMainScreen;
