import { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import "../../../design-tokens/feedback/Feedback.scss";
import FeedbackClient from "../../../network/httpclient/feedback/FeedbackClient";
import DiagContainer from "../../reusable/DiagContainer";
import { dataURItoBlob } from "../../reusable/Util";
import AppLabels from "../../../constants/App_Labels";
import EditForm from "./EditForm";
import { GetTechInfo } from "../../../get-api/GetResponse";

const EditModal = (props: any) => {
  const labels = AppLabels();
  const dispatch = useDispatch();
  const [file, setFile] = useState<any[] | null[]>([]);
  const [awaitDownload, setAwaitDownload] = useState(false);
  const [awaitAttachStatus, setAwaitAttachStatus] = useState(false);
  const {
    data: techData,
    isLoading: techDataIsLoading,
    error: techDataError,
  } = GetTechInfo();

  const { data, status, attachStatus } = useSelector(
    (state: any) => ({
      data: state.FeedbackInfo.data,
      status: state.FeedbackUpdateInfo.status,
      attachStatus: state.AttachmentInfo.status,
    }),
    shallowEqual
  );

  let oldDescription: any = "";
  let oldFbHead: any = "";
  let type: any = "";

  const [newDescription, setNewDescription] = useState();
  const [newFbHead, setNewFbHead] = useState();
  const [newFbRole, setNewFbRole] = useState();
  const [newFbSection, setNewFbSection] = useState();

  const [newAccountNumber,setAccountNumber] = useState();
  const [newWorkOrderNumber,setWorkOrderNumber] = useState();

  const onDescSubmit = (e: any) => setNewDescription(e.target.value);

  const createForm = () => {
    data && data?.hasOwnProperty("issues") ? (
      data?.issues?.forEach((item: any) => {
        if (item.key === props.issueToBeEditted) {
          type = item.fields.issuetype.name;
          oldDescription = item.fields.description;
          oldFbHead = item.fields.summary;
        }
      })
    ) : (
      <></>
    );

    return (
      <EditForm
        newDescription={newDescription}
        newFbHead={newFbHead}
        newAccountNumber={newAccountNumber}
        newWorkOrderNumber={newWorkOrderNumber}
        onDescSubmit={onDescSubmit}
        file={file}
        setFile={setFile}
        handleSubmit={handleSubmit}
        onClose={onClose}
        fbType={type}
        oldDescription={oldDescription}
        oldFbHead={oldFbHead}
        setNewDescription={setNewDescription}
        setNewFbHead={setNewFbHead}
        setNewAccountNumber={setAccountNumber}
        setNewWorkOrderNumber={setWorkOrderNumber}
        setNewFbRole={setNewFbRole}
        setNewFbSection={setNewFbSection}
      />
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const body = JSON.stringify({
      fields: {
        project: {
          key: "TIUFM",
        },
        assignee: {
          name: "tech.insight@rci.rogers.com",
        },
        priority: {
          id: "10004",
        },
        summary: type + " - " + newFbHead,
        description: newDescription,
        customfield_47417:newAccountNumber,
        customfield_47418:newWorkOrderNumber,
        customfield_45006: { id: newFbSection },
        customfield_29819: {
          id: newFbRole,
        },
      },
    });

    dispatch(FeedbackClient.editIssue(props.issueToBeEditted, body));
    dispatch(FeedbackClient.getFeedbackProfile());
    dispatch(FeedbackClient.getTechnicianFeedback(techData.lanId));
    if (file?.length > 0) setAwaitDownload(true);
  };

  const onClose = () => {
    props.setEdit(0);
    props.setModal(1);
  };

  useEffect(() => {
    if (awaitDownload && status === "OK") {
      let imgData = new FormData();
      file?.map((image: any) => {
        if (typeof image == "object") {
          let myBlob = dataURItoBlob(image[1]);
          imgData.append("file", myBlob, image[0]?.name);
        } else {
          let myBlob = dataURItoBlob(image);
          imgData.append("file", myBlob, "DefaultScreenshot.jpeg");
        }
      });
      setAwaitDownload(false);
      setAwaitAttachStatus(true);
      dispatch(FeedbackClient.postAttachment(props.issueToBeEditted, imgData));
    } else if (awaitAttachStatus) {
      if (attachStatus === "ERROR") {
        popUp("error");
        setAwaitAttachStatus(false);
      }
      if (attachStatus === "OK") {
        setAwaitAttachStatus(false);
        onClose();
        setFile([]);
      }
    } else if (status === "OK") {
      onClose();
      setFile([]);
    } else if (status === "ERROR") {
      popUp("error");
    }
  }, [status, attachStatus]);

  const popUp = (type: any) => {
    let text = document.getElementById("text");
    if (type === "thankYou") {
      if (text) text.innerHTML = labels.THANK_YOU_FEEDBACK;
    } else if (type == "error") {
      if (text) text.innerHTML = "Error";
      if (text) text.style.color = "red";
    }
    text!.classList.remove("hide");
    setTimeout(function () {
      text!.classList.add("fade-in");
      setTimeout(function () {
        text!.classList.remove("fade-in");
        setTimeout(function () {
          text!.classList.add("hide");
        }, 1000);
      }, 2000);
    });
  };

  return (
    <div>
      <Modal
        id="edit-feedback-container"
        show={props.showEdit === 1 ? true : false}
        dialogClassName="modal-90w"
        onHide={() => {}}
      >
        <Modal.Header bsPrefix="fbHeader">
          {labels.EDIT_FEEDBACK}
          <Button bsPrefix="close" onClick={onClose}>
            ✕
          </Button>
        </Modal.Header>
        <DiagContainer
          isLoading={techDataIsLoading}
          data={techData}
          error={techDataError}
          sectionName="Feedback"
          handleRefresh={() => {
            dispatch(FeedbackClient.getFeedbackProfile());
            dispatch(FeedbackClient.getTechnicianFeedback(techData.lanId));
          }}
        >
          {createForm()}
          <div>
            <p className="hide" id="text"></p>
          </div>
        </DiagContainer>
      </Modal>
    </div>
  );
};

export default EditModal;
