import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AppLabels from "../../../constants/App_Labels";
import ConfigConst from "../../../constants/ConfigConst";
import { CUSTOMER_TYPE_CONFIG } from "../../../constants/Global";
import {
  GetActiveAccountDetails,
  GetFormHistory,
  GetFormInfo,
  GetTechInfo,
} from "../../../get-api/GetResponse";
import FormClient from "../../../network/httpclient/form/FormClient";
import EmailClient from "../../../network/httpclient/notification/EmailClient";
import CustomPopUp from "../../reusable/CustomPopUp";
import { convertHTMLtoStr, isNull } from "../../reusable/Util";
import FormBoilerplate from "../FormBoilerplate";
import DisplayEmailPreviewEPR from "./DisplayEmailPreviewEPR";
import EquipmentPickupDetails from "./EquipmentPickupDetails";
import PrepareEmailFormatEPR from "./PrepareEmailFormatEPR";
import { shallowEqual, useSelector } from "react-redux";
import EmailAuditClient from "../../../network/httpclient/EmailAudit/EmailAuditClient";

interface Props {
  setShowModal: any;
}

const EquipmentPickupReceiptScreen = ({ setShowModal }: Props) => {
  const labels = AppLabels();
  const dispatch = useDispatch();
  const formHistory = GetFormHistory();
  const accountNumber = GetActiveAccountDetails()?.accountNumber;
  const activeAccData = GetActiveAccountDetails()?.data;
  const formInfo = GetFormInfo();
  const techid = GetTechInfo()?.techId;
  const [isWOSelectionComplete, setIsWOSelectionComplete] = useState(false);
  const [isCTSelectionComplete, setIsCTSelectionComplete] = useState(false);
  const [woData, setWOData] = useState({} as any);
  const [selectedWOID, setSelectedWOID] = useState(labels.Select);
  const [equipmentPickupDetails, setEquipmentPickupDetails] = useState(
    {} as any
  );
  const [isEmailsValid, setIsEmailsValid] = useState(false);
  const [showFlag, setShowFlag] = useState(false);
  const [selectedCustType, setSelectedCustType] = useState(labels.Select);
  const [showPreview, setShowPreview] = useState(true);

  const {
    isLoading,
    status,
    formStatus,
    isFormLoading,
    refKey,
    EmailFormData,
    sentby,
  } = useSelector(
    (state: any) => ({
      isLoading: state.AccountState.sendEmailData.isLoading,
      status: state.AccountState.sendEmailData.status,
      formStatus: state.AccountState.FormInfo.status,
      isFormLoading: state.AccountState.FormInfo.isLoading,
      refKey: state.AccountState.FormInfo?.data?.id,
      EmailFormData: state.AccountState.FormInfo?.data?.formData,
      sentby: state.AccountState.FormInfo?.data?.technicianId,
    }),
    shallowEqual
  );

  useEffect(() => {
    formHistory?.dataList?.some((e: any) => {
      if (e?.formName === ConfigConst.EQUIPMENT_PICKUP_RECEIPT)
        setSelectedCustType(e?.formData?.selectedCustType);
    });
  }, [formHistory]);
  const onSubmit = () => {
    const generateData = {
      formName: ConfigConst.EQUIPMENT_PICKUP_RECEIPT,
      workorderId: selectedWOID,
      accountNumber,
      custType: selectedCustType,
      formData: JSON.stringify({
        ...woData,
        ...equipmentPickupDetails,
        selectedCustType,
      }),
    };
    // const generateEmailData = {
    //   componentType: ConfigConst.EQUIPMENT_PICKUP_RECEIPT,
    //   refKey: refKey,
    //   sentTo: sentTo,
    //   sentby: sentby,
    // };
    if (formHistory?.data?.id) {
      dispatch(FormClient.updateForm(generateData, formHistory?.data?.id));
    } else dispatch(FormClient.postForm(generateData));
    if (!equipmentPickupDetails?.emailOptOut) {
      sendEmail();
      // if (
      //   isLoading === false &&
      //   status !== "ERROR" &&
      //   formStatus === "OK" &&
      //   !isFormLoading
      // ) {
      //   if (formHistory?.data?.id) {
      //     dispatch(
      //       EmailAuditClient.updateForm(
      //         generateEmailData,
      //         formHistory?.data?.id
      //       )
      //     );
      //   } else {
      //     console.log("heree");
      //     dispatch(EmailAuditClient.postForm(generateEmailData));
      //   }
      // }
    }
  };
  useEffect(() => {
    const generateEmailData = {
      componentType: ConfigConst.EQUIPMENT_PICKUP_RECEIPT,
      refKey: refKey,
      sentTo: JSON.stringify({ ...equipmentPickupDetails?.customerEmails }),
      sentby: sentby,
      techid: techid,
    };
    if (!equipmentPickupDetails?.emailOptOut) {
      // sendEmail();
      if (
        isLoading === false &&
        status === "OK" &&
        formStatus === "OK" &&
        isFormLoading === false &&
        EmailFormData !== undefined
      ) {
        if (formHistory?.data?.id) {
          dispatch(
            EmailAuditClient.updateForm(
              generateEmailData,
              formHistory?.data?.id
            )
          );
        } else {
          dispatch(EmailAuditClient.postForm(generateEmailData));
        }
      }
    }
  }, [status, formStatus, isFormLoading]);

  const onPreview = () => {
    setShowFlag(true);
  };

  const sendEmail = () => {
    const franchise = CUSTOMER_TYPE_CONFIG?.[selectedCustType];
    const emailSub =
      ConfigConst.EPR_SUBJECT +
      (selectedCustType === ConfigConst.ROGERS
        ? "-" + ConfigConst.EPR_SUBJECT_ROGERS
        : selectedCustType === ConfigConst.FIDO
        ? "-" + ConfigConst.EPR_SUBJECT_FIDO
        : "");
    const htmlMessage = convertHTMLtoStr(
      <PrepareEmailFormatEPR
        activeAccData={activeAccData}
        custType={selectedCustType}
        body={
          <DisplayEmailPreviewEPR
            woID={isNull(selectedWOID)}
            woType={isNull(woData?.orderType)}
            workDetails={equipmentPickupDetails}
          />
        }
      />
    );
    let emailPayload = {
      accountNumber: accountNumber,
      franchise: franchise,
      subject: emailSub,
      htmlMessage: htmlMessage,
      language: ConfigConst.EPR_LANGUAGE,
      firstName: activeAccData?.firstName,
      lastName: activeAccData?.lastName,
      addresses: equipmentPickupDetails?.customerEmails,
      formName: ConfigConst.EQUIPMENT_PICKUP_RECEIPT.toUpperCase()
    };
    const base64EncObj = { data: btoa(JSON.stringify(emailPayload)) };
    dispatch(EmailClient.sendEmailNoti(labels.EprEmailMSG, base64EncObj));
  };

  const disableSubmit = () => {
    if (
      isWOSelectionComplete &&
      isCTSelectionComplete &&
      equipmentPickupDetails?.acknowledgment !== false &&
      (equipmentPickupDetails?.emailOptOut ||
        (equipmentPickupDetails?.emailOptOut === false &&
          equipmentPickupDetails?.customerEmails?.[0] !== "" &&
          isEmailsValid)) &&
      equipmentPickupDetails?.signatureImage != "" &&
      !formInfo.isLoading
    ) {
      return false;
    } else return true;
  };

  useEffect(() => {
    setShowPreview(!equipmentPickupDetails?.emailOptOut);
  }, [equipmentPickupDetails?.emailOptOut]);

  useEffect(() => {
    if (selectedCustType !== undefined && selectedCustType !== labels.Select) {
      setIsCTSelectionComplete(true);
    }
  }, [selectedCustType]);

  return (
    <>
      <FormBoilerplate
        isWOSelectionComplete={isWOSelectionComplete}
        setIsWOSelectionComplete={setIsWOSelectionComplete}
        setWOData={setWOData}
        formLabel={labels.EPR}
        formName={ConfigConst.EQUIPMENT_PICKUP_RECEIPT}
        onSubmit={onSubmit}
        onPreview={onPreview}
        showPreview={showPreview}
        disableSubmit={disableSubmit}
        selectedWOID={selectedWOID}
        setSelectedWOID={setSelectedWOID}
        setShowEntireModal={setShowModal}
        disableFormUpdate={false}
        selectedCustType={selectedCustType}
        setSelectedCustType={setSelectedCustType}
      >
        <EquipmentPickupDetails
          setDetails={setEquipmentPickupDetails}
          isEmailsValid={isEmailsValid}
          setIsEmailsValid={setIsEmailsValid}
        />
      </FormBoilerplate>
      <CustomPopUp
        showVal={showFlag}
        headerText={labels.Email_Preview.toUpperCase()}
        bodyElement={
          <PrepareEmailFormatEPR
            activeAccData={activeAccData}
            custType={selectedCustType}
            body={
              <DisplayEmailPreviewEPR
                woID={isNull(selectedWOID)}
                woType={isNull(woData?.orderType)}
                workDetails={equipmentPickupDetails}
              />
            }
          />
        }
        handleClose={() => setShowFlag(false)}
      />
    </>
  );
};

export default EquipmentPickupReceiptScreen;
