import { CWMonitorState } from "../../../../states/account/diagnostics/cm/CWMonitoringState";

import {
  CWMonitorActionTypes,
  CW_MONITORING_BEGIN,
  CW_MONITORING_FAILURE,
  CW_MONITORING_SUCCESS,
  CW_Monitoring_Certification_Success,
  SET_CODEWORD_FAILURE,
  CW_Monitoring_Tracer,
  CW_MONITORING_RESET,
} from "../../../../types/account/diagnostics/cm/CWMonitoring";

const initialState: CWMonitorState = {
  isLoading: false,
  overallLoading: false,
  callIndex: 0,
  data: undefined,
  error: undefined,
  status: "",
};

export function CWMonitoringReducer(
  state = initialState,
  action: CWMonitorActionTypes
): CWMonitorState {
  switch (action.type) {
    case CW_MONITORING_BEGIN:
      let loadingFlag = state.overallLoading;
      if (state.callIndex === 0) {
        loadingFlag = true;
      }
      return { ...state, isLoading: true, overallLoading: loadingFlag };

    case CW_MONITORING_SUCCESS:
      let newData: any = [];
      let loadFlag = state.overallLoading;
      let currCallIndex: any = state.callIndex;
      if (action.payload !== undefined) {
        let prevData = state.data;
        if (prevData !== undefined && prevData?.length !== 0) {
          let tempData = action.payload;
          newData = prevData.concat(tempData);
        } else {
          newData.push(action.payload);
        }
      }
      if (currCallIndex >= 6) {
        loadFlag = false;
      }

      return {
        ...state,
        isLoading: false,
        overallLoading: loadFlag,
        callIndex: currCallIndex + 1,
        data: newData,
        status: "OK",
      };
      case CW_Monitoring_Certification_Success:
        let newD: any = [];
        let overallflag = state.overallLoading;
        let index: any = state.callIndex;
        if (action.payload !== undefined) {
          let prevData = state.data;
          if (prevData !== undefined && prevData?.length !== 0) {
            let tempData = action.payload;
            newD = prevData.concat(tempData);
          } else {
            newD.push(action.payload);
          }
        }
       
          if (index > 2 ) {
            overallflag = false;
          }
        return {
          ...state,
          isLoading: false,
          overallLoading: overallflag,
          callIndex: index + 1,
          data: newD,
          status: "OK",
        };
    case CW_MONITORING_FAILURE:
      let temploadFlag = state.overallLoading;
      let tempCallIndex: any = state.callIndex;
      let newTempData: any = [];
      if (action.payload !== undefined) {
        let prevData = state.data;
        if (prevData !== undefined && prevData?.length !== 0) {
          let tempData = action.payload;
          newData = prevData.concat(tempData);
        } else {
          newData.push(action.payload);
        }
      }
     
      return {
        ...state,
        isLoading: false,
        overallLoading: false,
        callIndex: tempCallIndex + 1,
        data: newTempData,
        status: "ERROR",
      };
      case SET_CODEWORD_FAILURE:
        return {
          ...state,
          isLoading: false,
          overallLoading: false,
          data: [],
          error: action.payload,
          status: 'SKIPPED',
        };
        case CW_Monitoring_Tracer:
          return {
            ...state,
          tracer:  action.payload,
        }
    case CW_MONITORING_RESET:
      return {
        ...state,
        data: undefined,
        callIndex: 0,
        status: "",
      };
    default:
      return state;
  }
}