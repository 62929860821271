import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { useState } from "react";
import {
    isNull,
    getDelta,
    getCheckValue,
    isObject,
    getStatusOfCheckHeader,
    popOverCell,
} from "../../reusable/Util";
import AppLabels from "../../../constants/App_Labels";

interface Props {
    ofdmUpChannel: any;
    totalChannel: any;
    preOfdmUpChannel?: any;
    preTotalChannel?: any;
}

const OFDMUSPrechChData = ({
    ofdmUpChannel,
    totalChannel,
    preOfdmUpChannel,
    preTotalChannel,
}: Props) => {
    const labels = AppLabels();

    const [summaryAcc, setSummaryAcc] = useState(true);
    const [detailsAcc, setDetailsAcc] = useState(false);
    const [preAcc, setPreAcc] = useState(false);

    const handleAccToggle = (accName: string) => {
        if (accName === "summary") setSummaryAcc(!summaryAcc);
        else if (accName === "details") setDetailsAcc(!detailsAcc);
        else if (accName === "precheck details") setPreAcc(!preAcc);
    };

    return (
        <div className="ofdmChannelCont">
            <div className="ofdmChSummary">
                <div className="dsChDataAcc" onClick={() => handleAccToggle("summary")}>
                    <p>{labels.SUMMARY.toUpperCase()}</p>
                    {summaryAcc ? (
                        <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
                    ) : (
                        <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
                    )}
                </div>
                {summaryAcc ? (
                    <table className="dataTableDetails">
                        <thead>
                            <tr>
                                <th key="nameCol">{labels.NAME.toUpperCase()}</th>
                                <th key="preValCol">{labels.PRECHECK_VALUE.toUpperCase()}</th>
                                <th key="curValCol">{labels.CURRENT_VALUE.toUpperCase()}</th>
                                <th key="diffCol">{labels.DIFFERENCE.toUpperCase()}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{labels.FREQ_LOWER_BOUNDARY.toUpperCase()}</td>
                                <td>{isNull(preOfdmUpChannel?.freqLowerBoundary) !== "" ?
                                    isNull(preOfdmUpChannel?.freqLowerBoundary) : "N/A"}</td>
                                <td>{isNull(ofdmUpChannel.freqLowerBoundary)}</td>
                                <td>N/A</td>
                            </tr>
                            <tr>
                                <td>{labels.FREQ_UPPER_BOUNDARY.toUpperCase()}</td>
                                <td>{isNull(preOfdmUpChannel?.freqUpperBoundary) !== "" ?
                                    isNull(preOfdmUpChannel?.freqUpperBoundary) : "N/A"}</td>
                                <td>{isNull(ofdmUpChannel.freqUpperBoundary)}</td>
                                <td>N/A</td>
                            </tr>
                            <tr>
                                <td>
                                    {getStatusOfCheckHeader(
                                        ofdmUpChannel?.mer?.thrVal,
                                        labels.MER_2PCT.toUpperCase()
                                    )}
                                </td>
                                {ofdmUpChannel?.hasOwnProperty("mer") ? (
                                    <>
                                        <td>
                                            {preOfdmUpChannel?.mer?.thrVal !== undefined ?
                                                popOverCell(preOfdmUpChannel?.mer?.thrVal) : "N/A"
                                            }
                                        </td>
                                        <td>
                                            {popOverCell(ofdmUpChannel?.mer?.thrVal)}
                                        </td>
                                        <td>
                                            {getDelta(
                                                ofdmUpChannel?.mer?.thrVal,
                                                preOfdmUpChannel?.mer?.thrVal
                                            )}
                                        </td>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </tr>
                            <tr>
                                <td>
                                    {getStatusOfCheckHeader(
                                        ofdmUpChannel?.snr,
                                        labels.SNR.toUpperCase()
                                    )}
                                </td>
                                {ofdmUpChannel?.hasOwnProperty("snr") ? (
                                    <>
                                        <td>
                                            {isObject(preOfdmUpChannel?.snr)
                                                ? isNull(getCheckValue(preOfdmUpChannel?.snr))
                                                : "N/A"}
                                        </td>
                                        <td>
                                            {isObject(ofdmUpChannel.snr)
                                                ? isNull(getCheckValue(ofdmUpChannel.snr))
                                                : ""}
                                        </td>
                                        <td>
                                            {getDelta(
                                                ofdmUpChannel?.snr?.value,
                                                preOfdmUpChannel?.snr?.value
                                            )}
                                        </td>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </tr>
                            <tr>
                                <td>{labels.MER_MEAN.toUpperCase()}</td>
                                {ofdmUpChannel?.hasOwnProperty("mer") ? (
                                    <>
                                        <td>{isNull(preOfdmUpChannel?.mer?.mean) !== "" ?
                                            isNull(preOfdmUpChannel?.mer?.mean) : "N/A"}</td>
                                        <td>{isNull(ofdmUpChannel.mer.mean)}</td>
                                        <td>
                                            {getDelta(
                                                ofdmUpChannel?.mer?.mean,
                                                preOfdmUpChannel?.mer?.mean
                                            )}
                                        </td>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </tr>
                            <tr>
                                <td>
                                    {getStatusOfCheckHeader(
                                        ofdmUpChannel?.status,
                                        "Operational Status".toUpperCase()
                                    )}
                                </td>
                                {ofdmUpChannel?.hasOwnProperty("status") ? (
                                    <>
                                        <td>
                                            {preOfdmUpChannel?.status !== undefined ?
                                                popOverCell(preOfdmUpChannel?.status) : "N/A"
                                            }
                                        </td>
                                        <td>
                                            {popOverCell(ofdmUpChannel.status)}
                                        </td>
                                        <td>N/A</td>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </tr>
                            <tr>
                                <td>{labels.OFDMA_CHANNELS.toUpperCase()}</td>
                                <td>{preTotalChannel !== undefined && preTotalChannel !== 0 ?
                                    isNull(preTotalChannel) : 'N/A'}</td>
                                <td>{isNull(totalChannel)}</td>
                                <td>N/A</td>
                            </tr>
                            <tr>
                                <td>
                                    {getStatusOfCheckHeader(
                                        ofdmUpChannel?.txPower,
                                        labels.TX_POWER.toUpperCase()
                                    )}
                                </td>
                                {ofdmUpChannel?.hasOwnProperty("txPower") ? (
                                    <>
                                        <td>
                                            {preOfdmUpChannel?.txPower !== undefined ?
                                                popOverCell(preOfdmUpChannel?.txPower) : "N/A"
                                            }
                                        </td>
                                        <td>
                                            {popOverCell(ofdmUpChannel.txPower)}
                                        </td>
                                        <td>
                                            {getDelta(
                                                ofdmUpChannel?.txPower?.value,
                                                preOfdmUpChannel?.txPower?.value
                                            )}
                                        </td>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </tr>
                            <tr>
                                <td>{labels.MIN_MODULATION.toUpperCase()}</td>
                                <td>{isNull(preOfdmUpChannel?.minModulation) !== "" ?
                                    isNull(preOfdmUpChannel?.minModulation) : "N/A"}</td>
                                <td>{isNull(ofdmUpChannel.minModulation)}</td>
                                <td>N/A</td>
                            </tr>
                            <tr>
                                <td>{labels.MAX_MODULATION.toUpperCase()}</td>
                                <td>{isNull(preOfdmUpChannel?.maxModulation) !== "" ?
                                    isNull(preOfdmUpChannel?.maxModulation) : "N/A"}</td>
                                <td>{isNull(ofdmUpChannel.maxModulation)}</td>
                                <td>N/A</td>
                            </tr>
                        </tbody>
                    </table>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default OFDMUSPrechChData;
