import {
  CMTS_NODE_BEGIN,
  SET_CMTS_NODE_DETAILS,
  CMTS_NODE_SUCCESS,
  CMTS_NODE_FAILURE,
  RESET_CMTS_NODE,
} from "../../../types/node/nodeinfo/CMTSNodeDetails";
import { CMTSNodeState } from "../../../states/node/nodeinfo/CMTSNodeState";

const initialState: CMTSNodeState = {
  isLoading: false,
  cmts: undefined,
  status: "",
};

const CMTSNodeReducer = (state = initialState, action: any): CMTSNodeState => {
  switch (action.type) {
    case CMTS_NODE_BEGIN:
      return {
        ...state,
        isLoading: true,
      };
    case CMTS_NODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cmts: action.payload,
        status: "OK",
      };
    case CMTS_NODE_FAILURE:
      return {
        ...state,
        isLoading: false,
        cmts: action.payload,
        status: "ERROR",
      };
    case SET_CMTS_NODE_DETAILS:
      return {
        ...state,
        isLoading: false,
        cmts: action.payload,
        status: "OK",
      };
    case RESET_CMTS_NODE:
      return {
        ...state,
        isLoading: false,
        cmts: undefined,
        status: "",
      };
    default:
      return state;
  }
};

export default CMTSNodeReducer;
