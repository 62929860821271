import { CMFlapsState } from "../../../../states/account/diagnostics/cm/CMFlapsState";
import {
  CM_FLAPS_INFO_BEGIN,
  CM_FLAPS_INFO_SUCCESS,
  CM_FLAPS_INFO_FAILURE,
  CMFlapsInfoActionTypes,
} from "../../../../types/account/diagnostics/cm/CMFlapsData";

const initialState: CMFlapsState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function CMFlapsReducer(
  state = initialState,
  action: CMFlapsInfoActionTypes
): CMFlapsState {
  switch (action.type) {
    case CM_FLAPS_INFO_BEGIN:
      return {
        isLoading: true,
      };
    case CM_FLAPS_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case CM_FLAPS_INFO_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };
    default:
      return state;
  }
}
