import { ActiveCertiState } from "../../../states/account/certifications/ActiveCertiState";
import {
  ActiveCertificationActionTypes,
  SAVE_ACTIVE_CERTI_BEGIN,
  SAVE_ACTIVE_CERTI_FAILURE,
  SAVE_ACTIVE_CERTI_SUCCESS,
  SAVE_DATA_LOADING,
  SAVE_FAILED_DEVICES,
  SAVE_WIFI_BYPASS_FLAG,
  SAVE_PASSED_DEVICES,
  SAVE_SKIPPED_DEVICES,
  SAVE_MARGINAL_PASSED_DEVICES,
  RESET_SAVED_DEVICES,
  RESET_ACTIVE_CERTIFICATION,
} from "../../../types/account/certifications/ActiveCertification";

const initialState: ActiveCertiState = {
  isLoading: false,
  isDataLoading: false,
  certiInfo: undefined,
  error: undefined,
  flag: false,
  passedDevices: undefined,
  failedDevices: undefined,
  marginalPassDevices: undefined,
  skippedDevices: undefined,
  status: "",
};

export function ActiveCertiReducer(
  state = initialState,
  action: ActiveCertificationActionTypes
): ActiveCertiState {
  switch (action.type) {
    case SAVE_ACTIVE_CERTI_BEGIN:
      return {
        ...state,
        isLoading: true,
      };
      case SAVE_DATA_LOADING:
        return {
          ...state,
          isDataLoading: action.payload,
        };
    case SAVE_ACTIVE_CERTI_SUCCESS:
      return {
        ...state,
        isLoading: false,
        certiInfo: action.payload,
        status: "OK",
      };
    case SAVE_ACTIVE_CERTI_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        certiInfo: undefined,
        status: "ERROR",
      };
    case SAVE_PASSED_DEVICES:
      return {
        ...state,
        isLoading: false,
        passedDevices: action.payload,
      };
    case SAVE_FAILED_DEVICES:
      return {
        ...state,
        isLoading: false,
        failedDevices: action.payload,
      };
      case SAVE_MARGINAL_PASSED_DEVICES:
        return {
          ...state,
          isLoading: false,
          marginalPassDevices: action.payload,
        };
      case SAVE_WIFI_BYPASS_FLAG:
        return {
          ...state,
          flag: action.payload,
        };
    case SAVE_SKIPPED_DEVICES:
      return {
        ...state,
        isLoading: false,
        skippedDevices: action.payload,
      };
    case RESET_SAVED_DEVICES:
      return {
        ...state,
        isLoading: false,
        skippedDevices: undefined,
        passedDevices: undefined,
        marginalPassDevices: undefined,
        failedDevices: undefined,
      };
    case RESET_ACTIVE_CERTIFICATION:
      return {
        ...state,
        isLoading: false,
        certiInfo: undefined,
        flag: false,
        isDataLoading:false,
        error: undefined,
        passedDevices: undefined,
        failedDevices: undefined,
        skippedDevices: undefined,
        status: "",
      };
    default:
      return state;
  }
}
