import { Text, Transformer } from "react-konva";
import { KonvaEventObject } from "konva/lib/Node";
import { useRef, useEffect } from "react";

import {
  ICommonDrawingProps,
  IGeoEditShape,
  getOpacity,
} from "../GeoEditCanvas";

export interface IGeoTxt extends IGeoEditShape {
  text: string;
  txtColor: string;
  size: number;
  strokeWidth: number;
  width: number;
}
interface Props {
  texts: IGeoTxt[];
  setTexts: (txt: IGeoTxt[]) => void;
  cdp: ICommonDrawingProps;
  onClick: ({ id }: { id: string }) => void;
}

export default function Texts({ texts, setTexts, cdp, onClick }: Props) {
  return (
    <>
      {texts?.map((t) => (
        <Shape
          key={t.id}
          shape={t}
          onClick={onClick}
          shapes={texts}
          setShapes={setTexts}
          cdp={cdp}
        />
      ))}
    </>
  );
}

interface ShapeProps {
  shape: IGeoTxt;
  shapes: IGeoTxt[];
  setShapes: (rect: IGeoTxt[]) => void;
  cdp: ICommonDrawingProps;
  onClick: ({ id }: { id: string }) => void;
}
const Shape = ({ shape, shapes, setShapes, cdp, onClick }: ShapeProps) => {
  const shapeRef = useRef<any>(null);
  const trRef = useRef<any>(null);
  const { selectedShape, isSelected: isSSelected } = cdp;
  const isSelected = isSSelected({ id: shape.id, shape: "text" });

  const handleDragStart = (e: KonvaEventObject<DragEvent>) => {
    cdp.setDrawingType("none");
    const id = e.target.id();
    setShapes(shapes.map((s) => ({ ...s, isDragging: s.id === id })));
  };

  const handleDragEnd = () =>
    setShapes(shapes.map((s) => ({ ...s, isDragging: false })));

  useEffect(() => {
    if (isSelected && trRef.current) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
    if (!isSelected && shapeRef.current) {
      shapeRef.current.scaleX(1);
      shapeRef.current.scaleY(1);
    }
  }, [isSelected]);

  const handleTREnd = () => {
    const node = shapeRef.current;
    if (node) {
      const scaleX = node.scaleX();
      const scaleY = node.scaleY();
      node.scaleX(1);
      node.scaleY(1);

      if (selectedShape?.id && selectedShape.shape === "text") {
        setShapes(
          shapes.map((s) =>
            s.id === selectedShape.id
              ? {
                  ...s,
                  x: node.x(),
                  y: node.y(),
                  rotation: node.rotation(),
                  size: Math.round(Math.max(5, s.size * scaleY)),
                  width: Math.round(Math.max(5, s.width * scaleX)),
                }
              : s
          )
        );
      }

      trRef.current.getLayer().batchDraw();
    }
  };

  return (
    <>
      <Text
        ref={shapeRef}
        id={shape.id}
        text={shape.text}
        fill={shape.txtColor}
        x={shape.x}
        y={shape.y}
        rotation={shape.rotation}
        fontSize={shape.size}
        width={shape.width}
        opacity={getOpacity(shape.opacity)}
        scaleX={shape.isDragging ? 1.1 : 1}
        scaleY={shape.isDragging ? 1.1 : 1}
        draggable
        onMouseDown={handleDragStart}
        onDragEnd={handleDragEnd}
        onClick={() => onClick({ id: shape.id })}
      />
      {selectedShape && isSelected && (
        <Transformer ref={trRef as any} onTransformEnd={handleTREnd} />
      )}
    </>
  );
};
