export const defaultLocation = {
  lat: 43.703115245864026,
  lng: -79.71612682045222,
};
export const defaultZoom = 16;
export const zoomLevelRenderMarker = 19;
export const zoomLevelRenderAddressNum = 19;
export const zoomLevelRenderAnnotationBlock = 20;
export const zoomLevelFetchBoundaries = 14;
export const zoomLevelRenderMduOverlay = 18;
export const zoomLevelRenderActives = 18;
export const zoomLevelRenderMdus = 18;

//do not change this, unless you want to change overlay size
export const imageOverlayDividendMdu = 22;
export const imageOverlayDividendMduCADSymbol = 11;
export const imageOverlayDividend = 38;
export const mapCustomOverlayMeasure = 0.2645833;
//
export const TopologyMapOptions = {
  mapTypeControl: true,
  scaleControl: true,
  streetViewControl: true,
  fullscreenControl: false,
  maxZoom: 21,
  disableDoubleClickZoom: true,
};

export const defaultMDUSchematicLocation = {
  lat: 49.002157,
  lng: -94.989863,
};
export const overLayLocation = {
  north: 49.2139897044427,
  south: 47.9939897044427,
  east: -93.9729725123366,
  west: -96.0159725123366,
};
export const mduRasterLocation = {
  north: 49.0043952873874,
  south: 49.0011588606669,
  east: -94.9893197048475,
  west: -94.9967981772215,
};

export const mduRaster2Location = {
  north: 49.004394750485,
  south: 49.0011578606669,
  east: -94.9818418841105,
  west: -94.989320530666,
};

export const raster1Polylines = {
  type: "FeatureCollection",
  features: [
    {
      properties: {
        type: "mduRaster",
        data: {},
      },
      geometry: {
        coordinates: [
          [-94.9967981772215, 49.0011593975214],
          [-94.989320530666, 49.0011588606669],
          [-94.9893197048475, 49.004394750485],
          [-94.996797929632, 49.0043952873874],
          [-94.9967981772215, 49.0011593975214],
        ],
        type: "LineString",
      },
      type: "Feature",
    },
  ],
};

export const raster2Polylines = {
  type: "FeatureCollection",
  features: [
    {
      properties: {
        type: "mduRaster",
        data: {},
      },
      geometry: {
        coordinates: [
          [-94.989320530666, 49.0011588606669],
          [-94.9818428841105, 49.0011578606669],
          [-94.9818418841105, 49.004393750485],
          [-94.9893197048475, 49.004394750485],
          [-94.989320530666, 49.0011588606669],
        ],
        type: "LineString",
      },
      type: "Feature",
    },
  ],
};

export const mduBlockedSymbols = ["146", "SPLICE"];
