import { createSlice } from "@reduxjs/toolkit";
import storeConst from "../storeConst";

const slice = createSlice({
  name: "sendRpaData",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    // errorList: [] as any,
    status: "",
    success: undefined as any,
    // sentAttemptErrors: undefined as any,
  },
  reducers: {
    sendRpaBegin: (rpaData) => {
      rpaData.isLoading = true;
      // rpaData.sentAttemptErrors = noiseData.sentAttemptErrors ? noiseData.sentAttemptErrors : 0;
    },
    sendRpaSuccess: (rpaData, action) => {
      rpaData.isLoading = false;
      rpaData.data = action.payload;
      rpaData.status = storeConst.OK;
      rpaData.success = true;
      // rpaData.sentAttemptErrors = noiseData.sentAttemptErrors ? noiseData.sentAttemptErrors : undefined;
    },
    sendRpaFailure: (rpaData, action) => {
      rpaData.data = undefined;
      rpaData.status = storeConst.ERROR;
      rpaData.error = action.payload;
      // rpaData.errorList = [...noiseData.errorList, action.payload];
      // rpaData.sentAttemptErrors = noiseData.sentAttemptErrors + 1;
      rpaData.success = false;
      // rpaData.isLoading = noiseData.sentAttemptErrors === 3 ? false : true;
      rpaData.isLoading = false;
    },
    sendRpaReset: (rpaData) => {
      rpaData.isLoading = false;
      rpaData.error = undefined;
      // rpaData.errorList = [];
      rpaData.data = undefined;
      rpaData.status = "";
      // rpaData.sentAttemptErrors = undefined;
      rpaData.success = undefined;
    },
    sendRpaSuccessReset: (rpaData) => {
      rpaData.success = undefined;
    },
  },
});

export const {
  sendRpaBegin,
  sendRpaSuccess,
  sendRpaFailure,
  sendRpaReset,
  sendRpaSuccessReset,
} = slice.actions;
export default slice.reducer;
