export const DATA_TABLE_CONFIG = [
  {
    name: "wifi2g",
    headers: ["name", "value"],
    notAllowedKeys: ["securityKeyPassphrase"],
  },
  {
    name: "wifi5g",
    headers: ["name", "value"],
    notAllowedKeys: ["securityKeyPassphrase"],
  },
  {
    name: "wifi6g",
    headers: ["name", "value"],
    notAllowedKeys: ["securityKeyPassphrase"],
  },
  {
    name: "radio2g",
    headers: ["name", "value"],
    notAllowedKeys: [""],
  },
  {
    name: "radio5g",
    headers: ["name", "value"],
    notAllowedKeys: [""],
  },
  {
    name: "radio6g",
    headers: ["name", "value"],
    notAllowedKeys: [""],
  },
  {
    name: "ontSummary",
    headers: ["name", "value", "status"],
    notAllowedKeys: ["overallStatus"],
    attributeLink: "oltName",
  },
  {
    name: "oltSummary",
    headers: ["name", "value"],
    notAllowedKeys: [""],
  },
  {
    name: "ihpSummary",
    headers: ["name", "value"],
    notAllowedKeys: ["lines", "overallStatus"],
  },
  {
    name: "oduSummary",
    headers: ["name", "value"],
    notAllowedKeys: ["apn", "overallStatus"],
  },
  {
    name: "cellInfo",
    headers: ["name", "value", "status"],
    notAllowedKeys: [""],
  },
  {
    name: "iptvSummary",
    headers: ["name", "value"],
    notAllowedKeys: [""],
    convertKeys: [{ uptime: "upTime" }],
  },
  {
    name: "iptvNetworkInfo",
    headers: ["name", "value"],
    notAllowedKeys: [""],
  },
  {
    name: "iptvEthernet",
    headers: ["name", "value", "status"],
    notAllowedKeys: [""],
  },
  {
    name: "iptvWIFI",
    headers: ["name", "value", "status"],
    notAllowedKeys: [""],
  },
  {
    name: "stbSummary",
    headers: ["name", "value", "status"],
    notAllowedKeys: [
      "way",
      "uptime2",
      "totalMemory",
      "totalMemory2",
      "smt",
      "shub",
      "streetNum",
      "streetName",
      "accountNum",
      "companyNum",
      "serialNum",
      "error",
    ],
    convertKeys: {
      uptime: "secToTime",
      qamTime: "secToTime",
      fdcTime: "secToTime",
      qamFreq: "hzToMhz",
    },
  },
  {
    name: "astbSummary",
    headers: ["name", "value", "status"],
    notAllowedKeys: ["overallStatus"],
  },
  {
    name: "etaSummary",
    headers: ["name", "value"],
    notAllowedKeys: [""],
  },
  {
    name: "qlrnSummary",
    headers: ["name", "value"],
    notAllowedKeys: [""],
  },
  {
    name: "featuresSummary",
    headers: ["name", "value"],
    notAllowedKeys: [""],
  },
  {
    name: "oduDetails",
    headers: ["name", "value"],
    notAllowedKeys: ["APN1", "APN2", "Primary Cell", "Secondary Cell"],
  },
  {
    name: "cellDetails",
    headers: ["name", "value"],
    notAllowedKeys: [""],
  },
  {
    name: "dict-gpon-query",
    headers: ["field name", "description"],
    notAllowedKeys: [""],
  },
];
