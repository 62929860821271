import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import {
  CMTSNodeBegin,
  CMTSNodeFailure,
  CMTSNodeSuccess,
} from "../../../store/actions/node/nodeinfo/CMTSNodeAction";
import {
  CMTSIPBegin,
  CMTSIPFailure,
  CMTSIPSuccess,
} from "../../../store/actions/node/nodeinfo/CMTSIPAction";
import { CMTSNodeDetails } from "../../../store/models/node/nodeinfo/CMTSNodeDetails";
import { CMTSIPDetails } from "../../../store/models/node/nodeinfo/CMTSNodeDetails";
import {
  CMTSRackIDBegin,
  CMTSRackIDFailure,
  CMTSRackIDSuccess,
} from "../../../store/actions/node/nodeinfo/CMTSRackIDDetails";

// import {
//   CMTSLevel2DetailsBegin,
//   CMTSLevel2DetailsFailure,
//   CMTSLevel2DetailsSuccess,
// } from "../../../store/actions/node/nodeinfo/CMTSLevel2DetailsAction";
export default class CMTSClient {
  static getCMTSNodeDetails =
    (
      nodeId: string
    ): ThunkAction<void, CMTSNodeDetails, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.NETWORK_SERVICE_URL +
          "diag/cablemodem/" +
          "cmtsInfo?nodeId=" +
          nodeId,
      };

      dispatch(CMTSNodeBegin());
      HttpClient.get<CMTSNodeDetails>(httpParameters)
        .then((cmtsNodeDetails) => {
          dispatch(CMTSNodeSuccess(cmtsNodeDetails));
          return cmtsNodeDetails;
        })
        .catch((error) => {
          dispatch(CMTSNodeFailure(error));
          return error;
        });
    };

  static getCMTSIPDetails =
    (
      cmtsIp: string
    ): ThunkAction<void, CMTSIPDetails, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url:
          NetworkConfig.NETWORK_SERVICE_URL +
          "diag/cablemodem/" +
          "cmtsDocsisEvents?cmtsIp=" +
          cmtsIp,
      };

      dispatch(CMTSIPBegin());
      HttpClient.get<any>(httpParameters)
        .then((cmtsIPDetails) => {
          dispatch(CMTSIPSuccess(cmtsIPDetails));
          return cmtsIPDetails;
        })
        .catch((error) => {
          dispatch(CMTSIPFailure(error));
          return error;
        });
    };

  // static getCMTSRackID =
  //   (cmtsIp: string): ThunkAction<void, any, unknown, Action<string>> =>
  //   async (dispatch) => {
  //     let httpParameters: IHttpClientRequestParameters<any> = {
  //       url:
  //         NetworkConfig.NETWORK_SERVICE_URL +
  //         "diag/cablemodem/" +
  //         "cmtsSummary?cmtsIp=" +
  //         cmtsIp,
  //     };

  //     dispatch(CMTSRackIDBegin());
  //     HttpClient.get<any>(httpParameters)
  //       .then((cmtsRackIDDetails) => {
  //         dispatch(CMTSRackIDSuccess(cmtsRackIDDetails));
  //         return cmtsRackIDDetails;
  //       })
  //       .catch((error) => {
  //         dispatch(CMTSRackIDFailure(error));
  //         return error;
  //       });
  //   };

  static getCMTSRackID =
    (cmtsIp: string): ThunkAction<Promise<any>, any, unknown, Action<string>> =>
    async (dispatch) => {
      return new Promise((resolve, reject) => {
        let httpParameters: IHttpClientRequestParameters<any> = {
          url:
            NetworkConfig.NETWORK_SERVICE_URL +
            "diag/cablemodem/" +
            "cmtsSummary?cmtsIp=" +
            cmtsIp,
        };

        dispatch(CMTSRackIDBegin());
        HttpClient.get<any>(httpParameters)
          .then((cmtsRackIDDetails) => {
            dispatch(CMTSRackIDSuccess(cmtsRackIDDetails));
            resolve(cmtsRackIDDetails);
          })
          .catch((error) => {
            dispatch(CMTSRackIDFailure(error));
            reject(error);
          });
      });
    };

  // static getCMTSLevel2Details =
  //   (cmtsIp: string): ThunkAction<void, any, unknown, Action<string>> =>
  //   async (dispatch) => {
  //     let httpParameters: IHttpClientRequestParameters<any> = {
  //       url:
  //         NetworkConfig.NETWORK_SERVICE_URL +
  //         "diag/cablemodem/" +
  //         "cmtsDocsisChannels?cmtsIp=" +
  //         cmtsIp,
  //     };

  //     // dispatch(CMTSLevel2DetailsBegin());
  //     HttpClient.get<any>(httpParameters)
  //       .then((cmtsLevel2Details) => {
  //         // dispatch(CMTSLevel2DetailsSuccess(cmtsLevel2Details));
  //         return cmtsLevel2Details;
  //       })
  //       .catch((error) => {
  //         // dispatch(CMTSLevel2DetailsFailure(error));
  //         return error;
  //       });
  //   };

  static getCMTSLevel2Details =
    (cmtsIp: string): ThunkAction<Promise<any>, any, unknown, Action<string>> =>
    async (dispatch) => {
      return new Promise((resolve, reject) => {
        let httpParameters: IHttpClientRequestParameters<any> = {
          url:
            NetworkConfig.NETWORK_SERVICE_URL +
            "diag/cablemodem/" +
            "cmtsDocsisChannels?cmtsIp=" +
            cmtsIp,
        };

        // dispatch(CMTSLevel2DetailsBegin());
        HttpClient.get<any>(httpParameters)
          .then((cmtsLevel2Details) => {
            // dispatch(CMTSLevel2DetailsSuccess(cmtsLevel2Details));
            resolve(cmtsLevel2Details);
          })
          .catch((error) => {
            // dispatch(CMTSLevel2DetailsFailure(error));
            reject(error);
          });
      });
    };

    static getCMTSNodeList =
    (cmtsIp: string): ThunkAction<Promise<any>, any, unknown, Action<string>> =>
    async () => {
      return new Promise((resolve, reject) => {
        let httpParameters: IHttpClientRequestParameters<any> = {
          url:
            NetworkConfig.NETWORK_SERVICE_URL +
            "diag/cablemodem/" +
            "nodeDetailsList?cmtsIp=" +
            cmtsIp,
        };

        HttpClient.get<any>(httpParameters)
          .then((cmtsNodeDetails) => {
            resolve(cmtsNodeDetails);
          })
          .catch((error) => {
            reject(error);
          });
      });
    };
}
