import SearchBox from "../../components/search/SearchBox";
import DashbordWidget from "./DashbordWidget";
import "../../design-tokens/dashbord-page/DashbordPage.scss";
import { useEffect } from "react";
import TECHClient from "../../network/httpclient/technician/TECHClient";
import FeedbackClient from "../../network/httpclient/feedback/FeedbackClient";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import WorkOrdersClient from "../../network/httpclient/workorders/WorkOrdersClient";
import { GetTechInfo } from "../../get-api/GetResponse";
import AccDetailsClient from "../../network/httpclient/accountInfo/AccDetailsClient";
import {
  SetActiveSubTabIndex,
  SetAccountSearchValue,
} from "../../store/actions/account/accountInfo/ActiveAccountAction";
import { getAccessLevelByRoles } from "../../components/reusable/Util";
import ConfigConst from "../../constants/ConfigConst";

const DashbordPage = (props: any) => {
  const dispatch = useDispatch();
  const { feedbackData } = useSelector(
    (state: any) => ({
      feedbackData: state.GetMonthlyFeedbackInfo.data,
    }),
    shallowEqual
  );
  const techData = GetTechInfo()?.data;

  // Already called in the App.tsx
  // useEffect(() => {
  //   if (techData === undefined) {
  //     dispatch(TECHClient.getTechniciansProfile());
  //   }
  // }, []);
  useEffect(() => {
    if (feedbackData === undefined && techData !== undefined) {
      dispatch(FeedbackClient.getMonthlyFeedback(techData?.lanId));
    }
    if (techData !== undefined && techData?.hasOwnProperty("name")) {
      dispatch(
        WorkOrdersClient.getTechWorkOrders(
          techData?.techId,
          techData?.name,
          getAccessLevelByRoles(techData?.roles)?.includes(ConfigConst.MT)
        )
      );
    }
  }, [techData]);

  // Once the page is loaded, this will listen to the message from the parent window and will dispatch the action to get the account details
  useEffect(() => {
    const message = (e: any) => {
      if (e && e?.data?.hasOwnProperty("rowValue")) {
        const ontSerial = e?.data?.rowValue?.trim()?.toUpperCase();
        dispatch(SetActiveSubTabIndex(1));
        dispatch(AccDetailsClient.getAccDetails(ontSerial, "ontSerialNumber"));
        window.document.title = "ONT SN: " + e?.data?.rowValue;
        if (ontSerial !== undefined && ontSerial !== "") {
          dispatch(SetAccountSearchValue(ontSerial));
        }
      }
    };
    window.addEventListener("message", message);
    return () => {
      window.removeEventListener("message", message);
    };
  }, [dispatch]);

  return (
    <>
      <SearchBox techData={techData} />
      <DashbordWidget techData={techData} />
    </>
  );
};
export default DashbordPage;
