import { Marker } from "@react-google-maps/api";
import React from "react";
import { renderToString } from "react-dom/server";
import CustomIcons from "../../../components/reusable/CustomIcons";
import colors from "../../config/colors";
import { mapElementStyles } from "../../config/mapItemsConfig";
import { GetMapDetailsProps } from "../../util/reduxFunctions/getTopologyState";

const SearchedAddressPin = React.memo(() => {
  const { searchedAddressPin } = GetMapDetailsProps();
  const addressIcon = renderToString(
    <CustomIcons
      name="addressSearch"
      size={mapElementStyles?.common?.iconSize}
      color={colors.primary}
    />
  );

  return (
    <>
      {searchedAddressPin && searchedAddressPin?.geometry?.lat && (
        <Marker
          key="activeEleMarker"
          visible={searchedAddressPin?.geometry !== null}
          zIndex={99999}
          clickable={false}
          position={{
            lat: searchedAddressPin?.geometry?.lat,
            lng: searchedAddressPin?.geometry?.lng,
          }}
          icon={{
            url:
              "data:image/svg+xml;charset=utf-8," +
              encodeURIComponent(addressIcon),
            anchor: new google.maps.Point(
              mapElementStyles?.common?.anchor?.x,
              mapElementStyles?.common?.anchor?.y * 3
            ),
          }}
        />
      )}
    </>
  );
});

export default SearchedAddressPin;
