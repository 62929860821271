import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "allFailedAttributes",
    initialState: [] as Array<any>,
    reducers: {
        addFailedAttributes: (state, { payload }) => {
            const existingIndex = state.findIndex(item => item.device.serialNumber === payload.device.serialNumber);
            if (existingIndex !== -1) {
                // Replace the existing failed attribute with the new payload
                state[existingIndex] = payload;
            } else {
                // If the serial number doesn't exist, add the new failed attribute
                let len = state.unshift(payload);
            }
        },
        resetAllFailedAttributes: (state) => {
            state = [];
        },
    },
});

export const {
    addFailedAttributes,
    resetAllFailedAttributes,
} = slice.actions;

export default slice.reducer;