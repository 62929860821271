import { NotificationInfo } from "../../models/notification/NotificationInfo";
import {
  NOTIFICATION_LIST_BEGIN,
  NOTIFICATION_LIST_FAILURE,
  NOTIFICATION_LIST_SUCCESS,
  NOTIFICATION_IS_READ,
  NotificationListActionTypes,
} from "../../types/notification/NotificationList";

export function NotificationListBegin(): NotificationListActionTypes {
  return {
    type: NOTIFICATION_LIST_BEGIN,
  };
}

export function NotificationListSuccess(
  notifications: NotificationInfo[]
): NotificationListActionTypes {
  return {
    type: NOTIFICATION_LIST_SUCCESS,
    payload: notifications,
  };
}

export function NotificationListFailure(
  error: Error
): NotificationListActionTypes {
  return {
    type: NOTIFICATION_LIST_FAILURE,
    payload: error,
  };
}

export function NotificationIsRead(
  notiId: String
): NotificationListActionTypes {
  return {
    type: NOTIFICATION_IS_READ,
    payload: notiId,
  }
}
