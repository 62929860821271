import { Divider } from "@material-ui/core";
import AppLabels from "../../constants/App_Labels";
import "../../design-tokens/workorder/WorkOrderPopUp.scss";

export function productDetailsData(woData: any) {
  const labels = AppLabels();

  let sortedPlist = []
  if (woData?.productList !== undefined) {
    sortedPlist = woData?.productList.slice().sort((a: string, b: string) => b.length - a.length);
  }

  return (
    <div
      style={{ display: "flex", flexDirection: "column", overflowX: "auto" }}
    >
      <div className="WODetailRow" style={{ justifyContent: "start" }}>
        <div className="WODetailColumnInfo">
          <header>{labels.Order_Number}</header>
          <p>{woData?.id ? woData?.id : labels.NA}</p>
        </div>
        <div className="WODetailColumnInfo">
          <header>{labels.Address}</header>
          <p>{woData?.address}</p>
        </div>
      </div>
      <div className="WODetailRow" style={{ width: "100%" }}>
        <div
          className="WODetailColumnInfo"
          style={{
            width: "100%",
            boxSizing: "border-box",
            maxWidth: "inherit",
          }}
        >
          <header>{labels.Product_List}</header>
          {woData?.productList !== undefined ? (
            sortedPlist
              ?.map((product: any, i: number) => {
                return (
                  <div key={product + "55" + i * i} >
                    <Divider orientation="horizontal" />
                    <div className="productListItem">
                      <span style={{ paddingLeft: "0px", whiteSpace: "pre" }}>{product?.replace(/\s/g, "\t\t")}</span>
                    </div>
                  </div>
                );
              })
          ) : (
            <p>{labels.NA}</p>
          )}
        </div>
      </div>
    </div>
  );
}
