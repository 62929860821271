import { Dropdown, DropdownButton } from "react-bootstrap";
import AppLabels from "../../../constants/App_Labels";
import { CUSTOMER_TYPE_CONFIG } from "../../../constants/Global";
import RedAsterisk from "../../reusable/RedAsterisk";
import { GetFormHistory } from "../../../get-api/GetResponse";
import ConfigConst from "../../../constants/ConfigConst";
import { useEffect, useState } from "react";
interface Props {
  selectedCustType?: string;
  setSelectedCustType?: any;
  formName?: any;
}

const CustomerTypeSelection = (props: Props) => {
  const labels = AppLabels();
  const custTypes: any = CUSTOMER_TYPE_CONFIG;
  const formHistory = GetFormHistory();
  const [data, setData] = useState();
  const [formHistoryInfo, setFormHIstoryInfo] = useState(false);
  useEffect(() => {
    if (formHistory?.dataList !== undefined && formHistory?.dataList !== "") {
      formHistory?.dataList?.some((e: any) => {
        if (
          e?.formName === props?.formName &&
          props?.formName === ConfigConst.NOISE_REFERRAL
        ) {
          // props?.setSelectedCustType(e?.formData?.selectedCustType);
          // console.log(data, e?.formData?.selectedCustType);
          setFormHIstoryInfo(true);

          setData(e?.formData?.selectedCustType);
        }
      });
    }
  }, [formHistory]);
  return (
    <div className="custTypeCont">
      <label className="labelTxt">
        {labels.Customer_Type}:
        <RedAsterisk />
      </label>
      <div className="workOrderIDContainer">
        <DropdownButton
          title={formHistoryInfo === true ? data : props.selectedCustType}
          onSelect={(v: any) => props.setSelectedCustType(v)}
          size="sm"
          id="dropdownBtn"
          style={{ width: "100%" }}
        >
          {Object.keys(custTypes).map((key: string, i: number) => (
            <Dropdown.Item key={i} eventKey={key}>
              {key}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>
    </div>
  );
};

export default CustomerTypeSelection;
