import { ODUNetworkInterface } from "../../../../models/account/diagnostics/odu/ODUNetworkInterface";
import {
  ODU_NETWORK_INTERFACE_BEGIN,
  ODU_NETWORK_INTERFACE_SUCCESS,
  ODU_NETWORK_INTERFACE_FAILURE,
  ODUNetworkInterfaceActionTypes,
} from "../../../../types/account/diagnostics/odu/ODUNetworkInterface";

export function ODUNetworkInterfaceBegin(): ODUNetworkInterfaceActionTypes {
  return {
    type: ODU_NETWORK_INTERFACE_BEGIN,
  };
}

export function ODUNetworkInterfaceSuccess(
  ODUNetworkInterface: ODUNetworkInterface
): ODUNetworkInterfaceActionTypes {
  return {
    type: ODU_NETWORK_INTERFACE_SUCCESS,
    payload: ODUNetworkInterface,
  };
}

export function ODUNetworkInterfaceFailure(
  error: Error
): ODUNetworkInterfaceActionTypes {
  return {
    type: ODU_NETWORK_INTERFACE_FAILURE,
    payload: error,
  };
}
