import AppLabels from "../../constants/App_Labels";

export default function CmViewData() {
  const labels = AppLabels();
  const data = {
    cmSummary: {
      dataSet1: [
        {
          label: labels.Timestamp,
          dataKey: "deviceDiag.data.docsis.queryTimestamp",
        },
        { label: labels.TYPE, dataKey: "deviceType" },
        {
          label: labels.STATUS,
          dataKey: "deviceDiag.data.summary.regStatus.cmtsStatus",
        },
        { label: labels.Uptime, dataKey: "deviceDiag.data.summary.uptime" },
        {
          label: labels.Registered_Time,
          dataKey: "deviceDiag.data.summary.cmtsCmRegisteredTime",
        },
        { label: labels.MODEL, dataKey: "model" },
        {
          label: labels.Docsis_Version,
          dataKey: "deviceDiag.data.summary.docsisVersion",
        },
        {
          label: labels.SERIAL_NUMBER,
          dataKey: "deviceDiag.data.summary.serialNumber",
        },
        {
          label: labels.Bridge_Mode,
          dataKey: "deviceDiag.data.summary.bridgeMode",
        },
        {
          label: labels.Incaptive_Portal,
          dataKey: "deviceDiag.data.summary.inCaptivePortal",
        },
        {
          label: labels.CM_IP_Address,
          dataKey: "deviceDiag.data.summary.mgmtIP",
        },
        {
          label: labels.CM_IPv6_Portal,
          dataKey: "deviceDiag.data.summary.mgmtIPv6",
        },
        {
          label: labels.Description,
          dataKey: "deviceDiag.data.summary.description",
        },
      ],
      dataSet2: [
        {
          label: labels.Address,
          dataKey: "serviceAddress",
        },
        { label: labels.Apt, dataKey: "serviceAddress.aptNumber" },
        {
          label: labels.City,
          dataKey: "serviceAddress.cityName",
        },
        { label: labels.SAMKEY, dataKey: "serviceAddress.samKey" },
        {
          label: labels.Service_Area + " (" + labels.SMT + ")",
          dataKey: "serviceArea",
        },
        {
          label: labels.Return_Segment + " (" + labels.SHUB + ")",
          dataKey: "returnSegment",
        },
        {
          label: labels.CMTS_CM_Online,
          dataKey: "deviceDiag.data.summary.onlinePercentage",
        },
        {
          label: labels.CMTS_CM_Online_Times,
          dataKey: "deviceDiag.data.summary.onlineTimes",
        },
        {
          label: labels.CMTS_CM_Status,
          dataKey: "deviceDiag.data.summary.cmtsCmOnlineStatus",
        },
        {
          label: labels.CMTS_IP_Adress,
          dataKey: "deviceDiag.data.summary.cmtsIP",
        },
        {
          label: labels.CMTS_Sys_Uptime,
          dataKey: "deviceDiag.data.summary.cmtsUptime",
        },

        {
          label: labels.Upstream_Bitrate + " (" + labels.Mbps + ")",
          dataKey: "deviceDiag.data.docsis.speed.upstreamBitrate",
        },
        {
          label: labels.Downstream_Bitrate + " (" + labels.Mbps + ")",
          dataKey: "deviceDiag.data.docsis.speed.downstreamBitrate",
        },
      ],
    },
    upStream: {
      summary: {
        individual: {
          headers: [labels.NAME, labels.VALUE],
          precheckHeaders: [labels.NAME, labels.PRECHECK_VALUE, labels.CURRENT_VALUE],
          dataKeys: {
            usPs: {
              label: labels.US_PS,
              dataKey: "partialService.d31PartialSvcState",
            },
            usTxTotal: {
              label: labels.US_TX + " " + labels.Total.toUpperCase(),
              dataKey: "docsis30.upstream.usTxPowerTotal",
            },
            ofdmaTx: {
              label: labels.OFDMA_TX,
              dataSource: "ofdm",
              dataKey: "txPower",
            },
            ofdma2Pct: {
              label: labels.OFDMA + " " + labels.secondPCT,
              dataSource: "ofdm",
              dataKey: "mer.thrVal",
            },
          },
        },
        common: {
          headers: [labels.Level, labels.Min, labels.Max, labels.Delta],
          dataSet: [
            {
              level: "scqam tx",
              min: "minTxPower",
              max: "maxTxPower",
              delta: "txPowerDelta",
            },
            {
              level: "scqam snr",
              min: "minSnr",
              max: "maxSnr",
              delta: "snrDelta",
            },
          ],
        },
      },
      scQam: {
        headers: [
          labels.channel?.toUpperCase(),
          labels.Freq,
          labels.STATUS,
          labels.TX,
          labels.SNR,
          "(Δ) " + labels.Times,
          "(Δ) " + labels.pre + " " + labels.CWE + " %",
          "(Δ) " + labels.post + " " + labels.CWE + " %",
          "(Δ) " + labels.Total + " " + labels.Codewords,
          labels.PRE_CCER + " %",
          labels.POST_CER + " %",
        ],
        dataKeys: {
          docsis: {
            name:"name",
            freq: "frequency",
            status: "status",
            txPower: "txPower",
            snr: "snr",
          },
          codeword: {
            preCCERDelta: "preCWEDelta",
            postCERDelta: "postCWEDelta",
            totalCwDelta: "totalCwDelta",
            preCCER: "preCWEPer",
            postCER: "postCWEPer",
          },
        },
      },
      ofdma: {
        headers: [
          labels.US_Interface,
          labels.STATUS,
          labels.TX,
          labels.Mean,
          labels.STD,
          labels.secondPCT,
          "(Δ) " + labels.Times,
          labels.CHANNEL_ID,
          "(Δ) " + labels.PRE_CCER + " %",
          "(Δ) " + labels.POST_CER + " %",
          "(Δ) " + labels.Total + " " + labels.Codewords,
          labels.PRE_CCER + " %",
          labels.POST_CER + " %",
        ],
        dataKeys: {
          docsis: {
            name: "name",
            status: "status",
            txPower: "txPower",
            mean: "mer.mean",
            std: "mer.stdDev",
            pct: "mer.thrVal",
          },
          codeword: {
            channelID: "channelID",
            preCCERDelta: "preCWEDelta",
            postCERDelta: "postCWEDelta",
            totalCwDelta: "totalCwDelta",
            preCCER: "preCWEPer",
            postCER: "postCWEPer",
          },
        },
      },
    },
    downStream: {
      summary: {
        individual: {
          headers: [labels.NAME, labels.VALUE],
          precheckHeaders: [labels.NAME, labels.PRECHECK_VALUE, labels.CURRENT_VALUE],
          dataKeys: {
            dsPs: {
              label: labels.DS_PS,
              dataKey: "partialService.d31PartialSvcState",
            },
            ofdm2Pct: {
              label: labels.OFDM + " " + labels.secondPCT,
              dataSource: "ofdm",
              dataKey: "mer.thrVal",
            },
          },
        },
        common: {
          headers: [labels.Level, labels.Min, labels.Max, labels.Delta],
          dataSet: {
            scQam: [
              {
                level: "scqam rx",
                min: "minRxPower",
                max: "maxRxPower",
                delta: "rxPowerDelta",
              },
              {
                level: "scqam snr",
                min: "minSnr",
                max: "maxSnr",
                delta: "snrDelta",
              },
            ],
            ofdm: {
              level: "ofdmRx",
              min: "minRxPower",
              max: "maxRxPower",
              delta: "rxPowerDelta",
            },
          },
        },
      },
      scQam: {
        headers: [
          labels.Freq,
          labels.STATUS,
          labels.RX,
          labels.SNR,
          "(Δ) " + labels.Times,
          "(Δ) " + labels.pre + " " + labels.CWE + " %",
          "(Δ) " + labels.post + " " + labels.CWE + " %",
          "(Δ) " + labels.Total + " " + labels.Codewords,
          labels.PRE_CCER + " %",
          labels.POST_CER + " %",
        ],
        dataKeys: {
          docsis: {
            freq: "frequency",
            status: "status",
            txPower: "rx_power",
            snr: "snr",
          },
          codeword: {
            preCCERDelta: "preCWEDelta",
            postCERDelta: "postCWEDelta",
            totalCwDelta: "totalCwDelta",
            preCCER: "preCWEPer",
            postCER: "postCWEPer",
          },
        },
      },
      ofdm: {
        headers: [
          labels.CHANNEL_ID,
          labels.STATUS,
          labels.Mean,
          labels.STD,
          labels.secondPCT,
          "(Δ) " + labels.Times,
          "(Δ) " + labels.PRE_CCER + " %",
          "(Δ) " + labels.POST_CER + " %",
          "(Δ) " + labels.Total + " " + labels.Codewords,
          labels.PRE_CCER + " %",
          labels.POST_CER + " %",
        ],
        dataKeys: {
          docsis: {
            status: "status",
            mean: "mer.mean",
            std: "mer.stdDev",
            pct: "mer.thrVal",
          },
          codeword: {
            preCCERDelta: "preCWEDelta",
            postCERDelta: "postCWEDelta",
            totalCwDelta: "totalCwDelta",
            preCCER: "preCWEPer",
            postCER: "postCWEPer",
          },
        },
      },
    },
    flaps: {
      headers: [labels.NAME, labels.VALUE],
      dataKeys: {
        flaps: { label: labels.Counts, dataKey: "flaps.counts" },
        insertionFails: {
          label: labels.Insertion_Fails,
          dataKey: "flaps.insertionFails",
        },
        miss: { label: labels.Miss, dataKey: "flaps.miss" },
        hits: { label: labels.Hits, dataKey: "flaps.hits" },
        powerAdjustments: {
          label: labels.Power_Adjustments,
          dataKey: "flaps.powerAdjustments",
        },
        firstFlap: {
          label: labels.First_Flap + " (" + labels.Local_Time + ")",
          dataKey: "flaps.createTime",
        },
        lastFlap: {
          label: labels.Last_Flap + " (" + labels.Local_Time + ")",
          dataKey: "flaps.lastFlapTime",
        },
      },
    },
  };
  return data;
}
