import { CSSProperties, useEffect, useState } from "react";

import "../../design-tokens/ReusableComp.scss";

interface Props {
  tabsItems: {
    label: string;
    compToRend: JSX.Element;
    icon?: JSX.Element;
  }[];
  index?: number;
  position?: "left" | "right";
  style?: CSSProperties;
  childrenStyle?: CSSProperties;
  size?: "small" | "medium";
}

export default function SideTabs({
  tabsItems,
  index = 0,
  position = "right",
  style,
  childrenStyle,
  size,
}: Props) {
  const [activeItemIndex, setActiveItemIndex] = useState(index);
  const styles = activeStyle(position, size);

  //update selected tab if props index changes
  useEffect(() => {
    if (index !== activeItemIndex) {
      setActiveItemIndex(index);
    }
  }, [index]);

  return (
    <div className="sideTabsCont" style={style}>
      {position === "left" && (
        <div style={{ flex: 1 }}>
          {tabsItems?.map(
            (e: any, i: number) => i === activeItemIndex && e.compToRend
          )}
        </div>
      )}
      <div className={styles.itemCont}>
        {tabsItems?.map((e: any, i: number) => (
          <div
            key={i}
            onClick={() => setActiveItemIndex(i)}
            className={
              i === activeItemIndex
                ? styles.activeItemCont + " " + styles.itemPadding
                : styles.inactiveItemCont + " " + styles.itemPadding
            }
            style={childrenStyle}
          >
            {position === "right" && e.icon && e.icon}
            <label>{e.label}</label>
            {position === "left" && e.icon && e.icon}
          </div>
        ))}
      </div>
      {position === "right" && (
        <div style={{ flex: 1 }}>
          {tabsItems?.map(
            (e: any, i: number) => i === activeItemIndex && e.compToRend
          )}
        </div>
      )}
    </div>
  );
}

const activeStyle = (position: string, size: any) => {
  const sizes = () => {
    switch (position) {
      case "right":
        switch (size) {
          case "small":
            return {
              itemCont: "itemContSmall",
              itemPadding: "itemPaddingSmall",
            };
          case "medium":
            return {
              itemCont: "itemCont",
              itemPadding: "itemPadding",
            };
          default:
            return {
              itemCont: "itemCont",
              itemPadding: "itemPadding",
            };
        }
      case "left":
        switch (size) {
          case "small":
            return {
              itemCont: "leftItemContSmall",
              itemPadding: "itemPaddingSmall",
            };
          case "medium":
            return {
              itemCont: "leftItemCont",
              itemPadding: "itemPadding",
            };
          default:
            return {
              itemCont: "leftItemCont",
              itemPadding: "itemPadding",
            };
        }
      default:
        switch (size) {
          case "small":
            return {
              itemCont: "itemContSmall",
              itemPadding: "itemPaddingSmall",
            };
          case "medium":
            return {
              itemCont: "itemCont",
              itemPadding: "itemPadding",
            };
          default:
            return {
              itemCont: "itemCont",
              itemPadding: "itemPadding",
            };
        }
    }
  };

  switch (position) {
    case "right":
      return {
        activeItemCont: "activeItemCont",
        inactiveItemCont: "inactiveItemCont",
        ...sizes(),
      };
    case "left":
      return {
        activeItemCont: "leftActiveItemCont",
        inactiveItemCont: "leftInactiveItemCont",
        ...sizes(),
      };
    default:
      return {
        activeItemCont: "activeItemCont",
        inactiveItemCont: "inactiveItemCont",
        ...sizes(),
      };
  }
};
