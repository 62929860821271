import CustomAccordion from "../../../../../../../components/reusable/CustomAccordion";
import GoogleMapDirectionsBtn from "../../../../../../../components/reusable/GoogleMapDirectionsBtn";
import { GetAvailableTR } from "../../../../../../../components/reusable/Util";
import TopologyLabels from "../../../../../../constants/topologyLabels";

interface Props {
  nodeSiteDetails: any;
}
const MduNodeSiteDetails = ({ nodeSiteDetails }: Props) => {
  const labels = TopologyLabels();
  return (
    <>
      {nodeSiteDetails && (
        <div className="activeMapEleCont">
          <CustomAccordion
            accTitle="MDU NODE SITE"
            headerComp={
              <GoogleMapDirectionsBtn
                lat={nodeSiteDetails?.siteCoords?.coordinates?.[1]}
                lng={nodeSiteDetails?.siteCoords?.coordinates?.[0]}
              />
            }
            accBodyElement={
              <table className="dataTableDetails">
                <thead>
                  <tr>
                    <td>{labels?.name.toUpperCase()}</td>
                    <td>{labels?.Value.toUpperCase()}</td>
                  </tr>
                </thead>
                <tbody>
                  <GetAvailableTR
                    name={labels.networkID}
                    value={nodeSiteDetails?.networkId}
                  />
                  <GetAvailableTR
                    name={labels.polygonId}
                    value={nodeSiteDetails?.polygonId}
                  />
                  <GetAvailableTR
                    name={labels.polygonType}
                    value={nodeSiteDetails?.polygonType}
                  />
                  <GetAvailableTR
                    name={labels.SType}
                    value={nodeSiteDetails?.sType}
                  />
                  <GetAvailableTR
                    name={labels.sType}
                    value={nodeSiteDetails?.siteType}
                  />
                  <GetAvailableTR
                    name={labels.siteDescription}
                    value={nodeSiteDetails?.siteDescription}
                  />
                  <GetAvailableTR
                    name={labels.Designation}
                    value={nodeSiteDetails?.designation}
                  />
                  <GetAvailableTR
                    name={labels.Manufacturer}
                    value={nodeSiteDetails?.manufacturer}
                  />
                  <GetAvailableTR
                    name={labels.Attr11}
                    value={nodeSiteDetails?.attr11}
                  />
                  <GetAvailableTR
                    name={labels.Attr12}
                    value={nodeSiteDetails?.attr12}
                  />
                  <GetAvailableTR
                    name={labels.NumPorts}
                    value={nodeSiteDetails?.numPorts}
                  />
                  <GetAvailableTR
                    name={labels.Clamshell}
                    value={nodeSiteDetails?.clamshell}
                  />
                  <GetAvailableTR
                    name={labels.UPS}
                    value={nodeSiteDetails?.UPS}
                  />

                  <GetAvailableTR
                    name={labels.Wattage}
                    value={nodeSiteDetails?.wattage}
                  />
                  <GetAvailableTR
                    name={labels.sysType}
                    value={nodeSiteDetails?.sysType}
                  />
                  <GetAvailableTR
                    name={labels.OHUG}
                    value={nodeSiteDetails?.ohug}
                  />
                  <GetAvailableTR
                    name={labels.profileName}
                    value={nodeSiteDetails?.profileName}
                  />
                  <GetAvailableTR
                    name={labels.InterfaceFk}
                    value={nodeSiteDetails?.interfaceFk}
                  />
                  <GetAvailableTR
                    name={labels.deviceDesc}
                    value={nodeSiteDetails?.deviceDesc}
                  />
                  <GetAvailableTR
                    name={labels.Model}
                    value={nodeSiteDetails?.model}
                  />
                  <GetAvailableTR
                    name={labels.deviceClass}
                    value={nodeSiteDetails?.deviceClass}
                  />
                  <GetAvailableTR
                    name={labels.Volts}
                    value={nodeSiteDetails?.volts}
                  />
                  <GetAvailableTR
                    name={labels.Volts1}
                    value={nodeSiteDetails?.volts1}
                  />
                  <GetAvailableTR
                    name={labels.Volts2}
                    value={nodeSiteDetails?.volts2}
                  />
                  <GetAvailableTR
                    name={labels.Volts3}
                    value={nodeSiteDetails?.volts3}
                  />
                  <GetAvailableTR
                    name={labels.Volts4}
                    value={nodeSiteDetails?.volts4}
                  />
                  <GetAvailableTR
                    name={labels.Volts5}
                    value={nodeSiteDetails?.volts5}
                  />
                  <GetAvailableTR
                    name={labels.Amps}
                    value={nodeSiteDetails?.amps}
                  />
                  <GetAvailableTR
                    name={labels.Amps1}
                    value={nodeSiteDetails?.amps1}
                  />
                  <GetAvailableTR
                    name={labels.Amps2}
                    value={nodeSiteDetails?.amps2}
                  />
                  <GetAvailableTR
                    name={labels.Amps3}
                    value={nodeSiteDetails?.amps3}
                  />
                  <GetAvailableTR
                    name={labels.Amps4}
                    value={nodeSiteDetails?.amps4}
                  />
                  <GetAvailableTR
                    name={labels.Amps5}
                    value={nodeSiteDetails?.amps5}
                  />
                  <GetAvailableTR
                    name={labels.AMPClass}
                    value={nodeSiteDetails?.ampClass}
                  />
                  <GetAvailableTR
                    name={labels.AMPDrawId}
                    value={nodeSiteDetails?.ampDrawId}
                  />
                  <GetAvailableTR
                    name={labels.CTB}
                    value={nodeSiteDetails?.ctb}
                  />
                  <GetAvailableTR
                    name={labels.CSO}
                    value={nodeSiteDetails?.cso}
                  />
                  <GetAvailableTR
                    name={labels.SymbolName}
                    value={nodeSiteDetails?.symbolName}
                  />
                </tbody>
              </table>
            }
          />
        </div>
      )}
    </>
  );
};

export default MduNodeSiteDetails;
