import * as React from "react";
import { isNull } from "../../components/reusable/Util";
import AppLabels from "../../constants/App_Labels";
import Loader from "../../resources/images/loader.webp";

export const MultiDeviceNeighbourhoodBanner: React.FC<any> = (props) => {
  const { data, expand, cmMacSearchValue, modemType } = props;
  const labels = AppLabels();

  interface IKeyValue {
    text: string;
    value: string | number | JSX.Element;
  }

  const KeyValuesTitle = (props: IKeyValue) => {
    const { text, value } = props;
    return (
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <span style={{ fontWeight: 600 }}>{text}:</span>
        <span style={{ marginLeft: "5px" }}>{value}</span>
      </span>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "20px",
        fontSize: "14px",
      }}
    >
      <>
        <section
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "6px",
          }}
        >
          <div>
            {" "}
            <span style={{ fontWeight: 600 }}>
              {labels.neighbourhood.toUpperCase()}:
            </span>
            &nbsp;
            <span>{isNull(cmMacSearchValue)}</span>
            &nbsp;
          </div>
          {/* <Divider orientation="vertical" hidden={expand} /> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "20px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <KeyValuesTitle
              text={labels.node_id.toUpperCase()}
              value={isNull(data?.nodeId)}
            />
          </div>
        </section>
      </>
      <section style={{ paddingLeft: "100px" }}>
        <table
          style={{
            borderCollapse: "collapse",
            width: "100%",
            // textAlign: "left",
            paddingLeft: "50px",
          }}
        >
          <thead>
            <tr>
              <th style={{ fontWeight: 600, width: "56px" }}>Devices</th>
              <th style={{ fontWeight: 600, width: "66px" }}>Assigned</th>
              <th style={{ fontWeight: 600, width: "78px" }}>Responsive</th>
              <th style={{ fontWeight: 600, width: "93px" }}>Unresponsive</th>
              <th style={{ fontWeight: 600, width: "87px" }}>Active(%)</th>
            </tr>
          </thead>
          <tbody>
            {data?.modemListInfo ? (
              Object.keys(data?.modemListInfo)
                .sort()
                .map((key: string) => {
                  const devices = data.modemListInfo[key];
                  return (
                    <tr>
                      <td
                        style={{
                          fontWeight: 600,
                          width: "56px",
                          textAlign: "left",
                        }}
                      >
                        {key}
                      </td>
                      <td style={{ width: "66px", textAlign: "center" }}>
                        {devices?.assigned ?? (
                          <img src={Loader} alt="loading" height="10px" />
                        )}
                      </td>
                      <td style={{ width: "78px", textAlign: "center" }}>
                        {devices?.responsive ?? (
                          <img src={Loader} alt="loading" height="10px" />
                        )}
                      </td>
                      <td style={{ width: "70px", textAlign: "center" }}>
                        {devices?.unresponsive ?? (
                          <img src={Loader} alt="loading" height="10px" />
                        )}
                      </td>
                      <td style={{ width: "70px", paddingLeft: "10px" }}>
                        {devices?.active ?? (
                          <img src={Loader} alt="loading" height="10px" />
                        )}
                      </td>
                    </tr>
                  );
                })
            ) : (
              <tr>
                <td style={{ width: "60px" }}>
                  <img src={Loader} alt="loading" height="20px" />
                </td>
                <td style={{ width: "68px" }}>
                  <img src={Loader} alt="loading" height="20px" />
                </td>
                <td style={{ width: "78px" }}>
                  <img src={Loader} alt="loading" height="20px" />
                </td>
                <td style={{ width: "70px" }}>
                  <img src={Loader} alt="loading" height="20px" />
                </td>
                <td style={{ width: "75px" }}>
                  <img src={Loader} alt="loading" height="20px" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>

      <span
        style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          alignItems: "flex-start",
          // paddingLeft: "150px",
        }}
      >
        <KeyValuesTitle
          text="Start"
          value={
            data?.startTime === undefined ? (
              <img src={Loader} alt="loading" height="20px" />
            ) : (
              `${data?.startTime}`
            )
          }
        />
        <KeyValuesTitle
          text="End"
          value={
            data?.endTime === undefined ? (
              <img src={Loader} alt="loading" height="20px" />
            ) : (
              `${data?.endTime}`
            )
          }
        />
      </span>
      {/* </section> */}
    </div>
  );
};
