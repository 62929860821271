import {ONTBandwidthState} from '../../../../states/account/diagnostics/ont/ONTBandwidthState';
import {
  ONT_BANDWIDTH_BEGIN,
  ONT_BANDWIDTH_SUCCESS,
  ONT_BANDWIDTH_FAILURE,
  ONTBandwidthActionTypes,
} from '../../../../types/account/diagnostics/ont/ONTBandwidth';

const initialState: ONTBandwidthState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: '',
};

export function ONTBandwidthReducer(
  state = initialState,
  action: ONTBandwidthActionTypes,
): ONTBandwidthState {
  switch (action.type) {
    case ONT_BANDWIDTH_BEGIN:
      return {
        isLoading: true,
      };
    case ONT_BANDWIDTH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 'OK',
      };
    case ONT_BANDWIDTH_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: 'ERROR',
      };

    default:
      return state;
  }
}
