import Divider from "@material-ui/core/Divider";
import { useState } from "react";

import { mapElementStyles } from "../../../config/mapItemsConfig";
import TopologyLabels from "../../../constants/topologyLabels";
import TopologySymbols, { IconInterface } from "../../reusable/TopologySymbols";

const getIcon = (d30dsSpecOut: IconInterface["name"], eleColor: string) => (
  <TopologySymbols
    name={d30dsSpecOut}
    color={mapElementStyles?.cableModem?.color?.[eleColor]}
    size={30}
    style={{ marginTop: 5, marginBottom: 5 }}
  />
);

export default function DiagLegends() {
  const labels = TopologyLabels();
  const [showLegends, setShowLegends] = useState(false);

  const tableData = [
    {
      d30Icon: getIcon("d30dsAndusSpecIn", "onLine"),
      d31Icon: getIcon("d31dsAndusSpecIn", "onLine"),
      def: labels.UpstreamDownstreamInSpec,
    },
    {
      d30Icon: getIcon("d30dsSpecOut", "downStream"),
      d31Icon: getIcon("d31dsSpecOut", "downStream"),
      def: labels.DownstreadOutOfSpec,
    },
    {
      d30Icon: getIcon("d30usSpecOut", "upStream"),
      d31Icon: getIcon("d31usSpecOut", "upStream"),
      def: labels.UpstreamOutOfSpec,
    },
    {
      d30Icon: getIcon("d30dsAndusSpecOut", "dualChannel"),
      d31Icon: getIcon("d31dsAndusSpecOut", "dualChannel"),
      def: labels.UpstreamDownstreamOutOfSpec,
    },
    {
      d30Icon: getIcon("d30NoSNPM", "unknown"),
      d31Icon: getIcon("d31NoSNPM", "unknown"),
      def: `${labels.ModemOnline} ${labels.NoSNMPResponse}`,
    },
    {
      d30Icon: getIcon("d30Offline", "offLine"),
      d31Icon: getIcon("d31Offline", "offLine"),
      def: labels.ModemOffline,
    },
  ];

  return (
    <div>
      <div className="legendBtnCont">
        <button
          className="legendBtn"
          onClick={() => setShowLegends(!showLegends)}
        >
          {showLegends ? labels.Hide : labels.Show} {labels.Legends}
        </button>
      </div>
      <div className={showLegends ? "legendTableOpen" : "legendTableClose"}>
        <table className="dataTableDetails">
          <thead>
            <tr>
              <th>{"3.0"}</th>
              <th>{"3.1"}</th>
              <th>{labels.Definition.toUpperCase()}</th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((e: any) => (
              <tr key={e.def}>
                <td>{e.d30Icon}</td>
                <td>{e.d31Icon}</td>
                <td>{e.def}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Divider style={{ margin: "20px 0px" }} />
      </div>
    </div>
  );
}
