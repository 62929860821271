import { FeedbackProfile } from "../../models/feedback/FeedbackProfile";

export const FEEDBACK_PROFILE_BEGIN = "FEEDBACK_PROFILE_BEGIN";
export const FEEDBACK_PROFILE_SUCCESS = "FEEDBACK_PROFILE_SUCCESS";
export const FEEDBACK_PROFILE_FAILURE = "FEEDBACK_PROFILE_FAILURE";

interface feedbackProfileBegin {
  type: typeof FEEDBACK_PROFILE_BEGIN;
}

interface feedbackProfileSuccess {
  type: typeof FEEDBACK_PROFILE_SUCCESS;
  payload: FeedbackProfile;
}

interface feedbackProfileFailure {
  type: typeof FEEDBACK_PROFILE_FAILURE;
  payload: Error;
}

export type FeedbackProfileActionTypes =
  | feedbackProfileBegin
  | feedbackProfileSuccess
  | feedbackProfileFailure;
