import { WIFISettings } from "../../../../models/account/diagnostics/cm/WIFISettings";

import {
  WIFI_SETTINGS_BEGIN,
  WIFI_SETTINGS_SUCCESS,
  WIFI_SETTINGS_FAILURE,
  WIFISettingsActionTypes,
} from "../../../../types/account/diagnostics/cm/WIFISettings";

export function wifiSettingsBegin(): WIFISettingsActionTypes {
  return {
    type: WIFI_SETTINGS_BEGIN,
  };
}

export function wifiSettingsSuccess(
  wifiSettings: WIFISettings
): WIFISettingsActionTypes {
  return {
    type: WIFI_SETTINGS_SUCCESS,
    payload: wifiSettings,
  };
}

export function wifiSettingsFailure(error: Error): WIFISettingsActionTypes {
  return {
    type: WIFI_SETTINGS_FAILURE,
    payload: error,
  };
}
