import { isNull } from "../reusable/Util";
import LoaderGif from "../../resources/images/loader.webp";
import AppLabels from "../../constants/App_Labels";
import CustomAccordion from "../reusable/CustomAccordion";

const OLTDetails = (props: any) => {
  const { oltSummaryLoading, oltSummaryInfo } = props;
  const labels = AppLabels();

  return (
    <CustomAccordion
      accTitle={labels.lt_details.toUpperCase()}
      accBodyElement={
        <>
          <table className="dataTableDetails">
            <thead>
              <tr>
                <th id="nameCol">{labels.NAME.toUpperCase()}</th>
                <th id="valueCol">{labels.VALUE.toUpperCase()}</th>
              </tr>
            </thead>
            <tbody>
              {!oltSummaryLoading ? (
                oltSummaryInfo !== undefined ? (
                  <>
                    <tr>
                      <td>{labels.lt_card_admin_status.toUpperCase()}</td>
                      <td>
                        {isNull(oltSummaryInfo?.Slot?.LT?.eqptBoardAdminStatus)}
                      </td>
                    </tr>
                    <tr>
                      <td>{labels.lt_card_oper_status.toUpperCase()}</td>
                      <td>
                        {isNull(oltSummaryInfo?.Slot?.LT?.eqptBoardOperStatus)}
                      </td>
                    </tr>
                    <tr>
                      <td>{labels.lt_card_oper_error.toUpperCase()}</td>
                      <td>
                        {isNull(oltSummaryInfo?.Slot?.LT?.eqptBoardOperError)}
                      </td>
                    </tr>
                    <tr>
                      <td>{labels.lt_card_last_restart_cause.toUpperCase()}</td>
                      <td>
                        {isNull(
                          oltSummaryInfo?.Slot?.LT?.eqptBoardLastRestartCause
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{labels.lt_card_avail_status.toUpperCase()}</td>
                      <td>
                        {isNull(oltSummaryInfo?.Slot?.LT?.eqptBoardAvailStatus)}
                      </td>
                    </tr>
                    <tr>
                      <td>{labels.lt_card_power_status.toUpperCase()}</td>
                      <td>
                        {isNull(oltSummaryInfo?.Slot?.LT?.eqptSlotPowerStatus)}
                      </td>
                    </tr>
                    <tr>
                      <td>{labels.lt_card_last_restart_time.toUpperCase()}</td>
                      <td>
                        {isNull(
                          oltSummaryInfo?.Slot?.LT?.eqptBoardLastRestartTime
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {labels.port_tx_optical_signal_level.toUpperCase()}
                      </td>
                      <td>
                        {isNull(
                          oltSummaryInfo?.Port
                            ?.gponPonOltsideOpInfoTxOpticalSignalLevel
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {labels.port_rx_optical_signal_level.toUpperCase()}
                      </td>
                      <td>
                        {isNull(
                          oltSummaryInfo?.Ont
                            ?.gponOntOpticSupDailyOltsideRxOpticalSignalLevel
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{labels.last_change_time.toUpperCase()}</td>
                      <td>{isNull(oltSummaryInfo?.Port?.ifLastChange)}</td>
                    </tr>
                  </>
                ) : (
                  <tr className="inlineError">
                    <td className="inlineError" colSpan={2}>
                      Data is not Available
                    </td>
                  </tr>
                )
              ) : (
                <img
                  className="loaderImg"
                  style={{ height: "30px" }}
                  src={LoaderGif}
                />
              )}
            </tbody>
          </table>
        </>
      }
    />
  );
};

export default OLTDetails;
