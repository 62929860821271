import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import CustomStepper from "../reusable/CustomStepper";
import { TabPanel } from "../../screens/account-page/AccountPageTabs";
import {
  GetActiveAccountDetails,
  GetEmailInfo,
  GetTechInfo,
  GetWorkOrderDetails,
  GetWorkOrdersList,
  GetWifiVisibilityDetails
} from "../../get-api/GetResponse";
import CustInfoStep from "./certificationSteps/CustInfoStep";
import HSICertification from "./certificationSteps/HSICertification";
import WifiCertification from "./certificationSteps/WifiCertification";
import ReviewCerti from "./certificationSteps/ReviewCerti";
import CertiSubmitStep from "./certificationSteps/CertiSubmitStep";
import {
  ResetActiveCerti,
  SaveWifiBypassFlag,
} from "../../store/actions/account/certifications/ActiveCertificationAction";
import { WORK_TYPE_CONFIG } from "../../constants/CertificationsConfig";
import EmailAuditClient from "../../network/httpclient/EmailAudit/EmailAuditClient";
import {
  checkWiFiFailCondition,
  checkHSIFailCondition,
  ccAddress,
  isIgniteAccount,
  isLegacyAccount,
  getAccessLevelByRoles
} from "../reusable/Util";
import ConfigConst from "../../constants/ConfigConst";
import CertificationClient from "../../network/httpclient/certification/CertificationClient";

const CertificationProcess = (props: any) => {
  const dispatch = useDispatch();
  const { handleCancel, goListing, openInprogressCerti } = props;
  const { techId, lanId: sentby, roles, data: techData } = GetTechInfo();
  const { productList, orderCategory, data: woDetails } = GetWorkOrderDetails();
  const {
    cbpId,
    accountNumber,
    data: account,
    certiData,
    EmailFormData,
  } = GetActiveAccountDetails();
  const { isLoading, status } = GetEmailInfo();
  const { data: workOrders } = GetWorkOrdersList();
  const isLegacyAcc: any = isLegacyAccount();
  const {data}= GetWifiVisibilityDetails();
  const [workOrderFlag, setworkOrderFlag] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [enableStepClick, setEnableStepClick] = useState(false);

  const allowedWorkTypes = WORK_TYPE_CONFIG.worktypeAllowed;

  const wifiFailCondition = checkWiFiFailCondition(
    certiData?.data?.wifiCertificate
  );
  const hsiFailCondition = checkHSIFailCondition(
    certiData?.data?.hsiCertificate
  );
  useEffect(() => {
    const generateEmailData = {
      componentType:
        hsiFailCondition && wifiFailCondition
          ? "Whole Home Certification & Wifi Certification"
          : hsiFailCondition
          ? "Whole Home Certification"
          : wifiFailCondition && "Wifi Certification",
      refKey: certiData?.id,
      sentTo: JSON.stringify([
        techData?.supervisorsEmail,
        ccAddress(techData?.companyId),
      ]),
      sentby: sentby,
      techid: techId,
    };
    if (
      techData?.supervisorsEmail?.length > 0 &&
      (wifiFailCondition || hsiFailCondition)
    ) {
      if (
        isLoading === false &&
        status === "OK" &&
        certiData?.data?.status === "COMPLETE" &&
        EmailFormData !== undefined
      ) {
        dispatch(EmailAuditClient.postForm(generateEmailData));
      }
    }
  }, [status, isLoading, certiData?.data?.status]);
  useEffect(() => {
    dispatch(CertificationClient.getWifiCertEnable());
  }, []);
  useEffect(() => {
    if (getAccessLevelByRoles(roles).includes(ConfigConst.ST)) {
      if(certiData?.data?.wifiCertificate?.isApplicable){
        dispatch(SaveWifiBypassFlag(true));
        setworkOrderFlag(true);
      }
      else if(!data){
        setworkOrderFlag(false);
      }
      else{
      if (
        WORK_TYPE_CONFIG.worktypeAllowed.includes(woDetails?.workType) &&
        !isLegacyAcc
      ) {
        dispatch(SaveWifiBypassFlag(true));
        setworkOrderFlag(true);
      }
      if (
        isIgniteAccount(account) &&
        (orderCategory === "SO" || orderCategory === "WO")
      ) {
        dispatch(SaveWifiBypassFlag(true));
        setworkOrderFlag(true);
      }
      if (orderCategory === "SO" || orderCategory === "WO") {
        productList?.some((po: any) => {
          if (po?.includes("PRD: WSTV")) {
            dispatch(SaveWifiBypassFlag(false));
            setworkOrderFlag(false);
          }
        });
      }
    }
  }
}, [workOrders, accountNumber, cbpId, workOrderFlag, orderCategory,data, certiData?.data?.wifiCertificate?.isApplicable]);

  let steps = [
    {
      stepId: 1,
      stepDesc: "Customer Information",
      isActive: true,
      isCompleted: false,
    },
    {
      stepId: 2,
      stepDesc: "Whole Home Certification",
      isActive: false,
      isCompleted: false,
    },
    {
      stepId: 3,
      stepDesc: "Review & Submit",
      isActive: false,
      isCompleted: false,
    },
  ];
  let steps2 = [
    {
      stepId: 1,
      stepDesc: "Customer Information",
      isActive: true,
      isCompleted: false,
    },
    {
      stepId: 2,
      stepDesc: "Whole Home Certification",
      isActive: false,
      isCompleted: false,
    },
    {
      stepId: 3,
      stepDesc: "WiFi Certification",
      isActive: false,
      isCompleted: false,
    },
    {
      stepId: 4,
      stepDesc: "Review & Submit",
      isActive: false,
      isCompleted: false,
    },
  ];

  const clearActiveCerti = () => {
    dispatch(ResetActiveCerti());
  };
  useEffect(() => {
    if (
      certiData?.data !== undefined &&
      certiData?.data?.workflow?.completedSteps?.indexOf(1) > -1 &&
      certiData.data?.status !== "COMPLETE"
    ) {
      setEnableStepClick(true);
    }
  }, [certiData]);
  const hsiStepsData = useMemo(() => {
    let computedHSISteps;
    workOrderFlag ? (computedHSISteps = steps2) : (computedHSISteps = steps);
    computedHSISteps?.map((step: any) => {
      if (step.stepId === currentStep) {
        step.isActive = true;
      } else step.isActive = false;
      if (
        certiData?.data !== undefined &&
        certiData?.data?.workflow?.completedSteps?.indexOf(step.stepId) > -1
      ) {
        step.isCompleted = true;
      } else step.isCompleted = false;
    });

    return computedHSISteps;
  }, [currentStep, certiData, workOrderFlag]);
  useEffect(() => {
    handleNext(openInprogressCerti);
  }, [openInprogressCerti]);
  const handleNext = (stepNum: number) => {
    if (workOrderFlag === true) {
      if (currentStep !== 5) {
        setCurrentStep(stepNum + 1);
      }
    } else {
      if (currentStep !== 4) {
        setCurrentStep(stepNum + 1);
      }
    }
  };
  const handlePrev = () => {
    if (currentStep !== 1) {
      setCurrentStep(currentStep - 1);
    }
  };
  const handleStepClick = (stepNum: number) => {
    if (enableStepClick) {
      setCurrentStep(stepNum);
    }
  };

  return (
    <div className="certiStepsCont">
      <div className="certiStepsHeader">
        <h3>
          {workOrderFlag ? "4 STEP CERTIFICATION" : "3 STEP CERTIFICATION"}
        </h3>
        <div className="certiStepsWrapper">
          {hsiStepsData?.map((step, index) => {
            return (
              <CustomStepper
                key={index}
                stepId={step.stepId}
                stepDesc={step.stepDesc}
                activeStatus={step.isActive}
                completedStatus={step.isCompleted}
                handleStepClick={handleStepClick}
              />
            );
          })}
        </div>
        <TabPanel value={currentStep} index={1}>
          <CustInfoStep
            handleCancel={handleCancel}
            clearActiveCerti={clearActiveCerti}
            handlePrev={handlePrev}
            handleNext={handleNext}
            currentStep={currentStep}
            enableStepClicks={() => setEnableStepClick(true)}
            disableStepClicks={() => setEnableStepClick(false)}
          />
        </TabPanel>
        <TabPanel value={currentStep} index={2}>
          <HSICertification
            handleCancel={handleCancel}
            handlePrev={handlePrev}
            handleNext={handleNext}
            currentStep={currentStep}
          />
        </TabPanel>
        {workOrderFlag ? (
          <>
            <TabPanel value={currentStep} index={3}>
              <WifiCertification
                handleCancel={handleCancel}
                handlePrev={handlePrev}
                handleNext={handleNext}
                currentStep={currentStep}
              />
            </TabPanel>
            <TabPanel value={currentStep} index={4}>
              <ReviewCerti
                handleCancel={handleCancel}
                handlePrev={handlePrev}
                handleNext={handleNext}
                currentStep={currentStep}
              />
            </TabPanel>
            <TabPanel value={currentStep} index={5}>
              <CertiSubmitStep
                goListing={goListing}
                handlePrev={handlePrev}
                handleNext={handleNext}
                currentStep={currentStep}
                disableStepClicks={() => setEnableStepClick(false)}
              />
            </TabPanel>
          </>
        ) : (
          <>
            <TabPanel value={currentStep} index={3}>
              <ReviewCerti
                handleCancel={handleCancel}
                handlePrev={handlePrev}
                handleNext={handleNext}
                currentStep={currentStep}
              />
            </TabPanel>

            <TabPanel value={currentStep} index={5}>
              <CertiSubmitStep
                goListing={goListing}
                handlePrev={handlePrev}
                handleNext={handleNext}
                currentStep={currentStep}
                disableStepClicks={() => setEnableStepClick(false)}
              />
            </TabPanel>
          </>
        )}
      </div>
    </div>
  );
};

export default CertificationProcess;
