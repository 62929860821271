import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  isPrecheckDone: false,
};

const slice = createSlice({
  name: "precheckDiagnosticData",
  initialState,
  reducers: {
    setPrecheckDiagnosticData: (state, action) => {
      state.data = action.payload;
    },
    setIsPrecheckDone: (state, action) => {
      state.isPrecheckDone = action.payload;
    },
    resetPrecheck: (state) => {
      state.isPrecheckDone = false;
      state.data = [];
    },
  },
});

export const { setPrecheckDiagnosticData, setIsPrecheckDone, resetPrecheck } = slice.actions;
export default slice.reducer;
