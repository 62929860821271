import { createSlice } from "@reduxjs/toolkit";
import ConfigConst from "../../../constants/ConfigConst";
import { DrawerViewType } from "../reduxTypes";

const slice = createSlice({
  name: "mapDrawer",
  initialState: {
    drawerView: ConfigConst.standard as DrawerViewType,
    drawerTabIndex: 0,
    commonSearchBarVal: "",
    logicalViewTabIndex: 0,
  },
  reducers: {
    setDrawerView: (state, action) => {
      state.drawerView = action.payload;
    },
    setDrawerTabIndex: (state, action) => {
      state.drawerTabIndex = action.payload;
    },
    setCommonSearchBarVal: (state, { payload }) => {
      state.commonSearchBarVal = payload;
    },
    setLogicalViewTabIndex: (state, action) => {
      state.logicalViewTabIndex = action.payload;
    },
  },
});

export const {
  setDrawerTabIndex,
  setDrawerView,
  setCommonSearchBarVal,
  setLogicalViewTabIndex,
} = slice.actions;
export default slice.reducer;
