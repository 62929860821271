export default Object.freeze({
  id: "id",
  standard: "standard",
  hybrid: "hybrid",
  default: "default",
  darkContent: "dark-content",
  lightContent: "light-content",
  OK: "OK",
  ERROR: "ERROR",
  geometry: "geometry",
  Feature: "Feature",
  FeatureCollection: "FeatureCollection",
  polygon: "nodeBoundary",
  line: "LineString",
  node: "node",
  nodeSite: "nodeSite",
  fiberCable: "fiberCableGeometry",
  spliceCases: "fiberSpliceCase",
  TRUE: "TRUE",
  taps: "tap",
  Point: "Point",
  splitter: "splitter",
  powerBlock: "powerBlock",
  rfCable: "rfCable",
  terminator: "terminator",
  powerSupply: "powerSupply",
  ispSite: "ispSite",
  rfDrop: "rfDrop",
  rfdropaddress: "rfDropAddress",
  mdu: "mdu",
  mduPolyline: "mduPolyline",
  supportStructure: "supportStructure",
  detailMark: "detailMark", //supportStructure's sub component
  cableDip: "cableDip", //supportStructure's sub component
  pole: "pole", //supportStructure's sub component
  poleStrand: "poleStrand", //supportStructure's sub component
  rfSplice: "rfSplice",
  activeEquipment: "activeEquipment",
  portInfo: "PORT INFO",
  area: "area",
  networkMaintenance: "networkMaintenance",
  buriedDrop: "buriedDrop",
  construction: "construction",
  Fibre: "Fibre",
  FibreRBS: "Fibre (RBS)",
  NetworkMaintenanceRepair: "Network Maintenance Repair",
  MDU: "MDU",
  PowerSupply: "Power Supply",
  CustomerService: "Customer Service",
  LeakageRepairs: "Leakage Repairs",
  ConstructionProjects: "Construction Projects",
  BuriedDrop: "Buried Drop",
  type: "type",
  data: "data",
  imageStringPrefix: "data:image/svg+xml;charset=utf-8,",
  annotationBlock: "annotationblock",
  mHz: "MHz",
  addresses: "Addresses",
  all: "all",
  all_no_cw: "all w/o cw",
  Yes: "Yes",
  summary: "summary",
  deviceDiag: "deviceDiag",
  d30: "DOCSIS30",
  d31: "DOCSIS31",
  fail: "fail",
  pass: "pass",
  marginal: "Marginal Pass",
  usScQAM: "usScQAM",
  dsScQAM: "dsScQAM",
  ofdma: "ofdma",
  ofdm: "ofdm",
  rfCommonBlock: "TELCO_DEFAULT_SYMBOL",
  rfEOLBlock: "RF_EOL_BLOCK",
  rfPorts: "RF Ports",
  fd: "FD",
  rn: "RN",
  rfAmpBlockDwg: "rf_amp_block.dwg",
  info13: "INFO13",
  rfAmpBlock16Dwg: "rf_amp_block16.dwg",
  rfNodeBlockDwg: "rf_node_block.dwg",
  rfNodeBlock15Dwg: "rf_node_block15.dwg",
  rfNodeBlock16Dwg: "rf_node_block16.dwg",
  CM: "CM",
  errorCode: "errorCode",
  coordinate: "coordinate",
  statusType: "statusType",
  statusCode: "statusCode",
  eventId: "eventId",
  cpat: "cpatEvent",
  searchCoordinates: "searchCoordinates",
  searchedAddress: "searchedAddress",
  mduFiberEquipment: "mduFiberEquipment",
  mduActive: "mduActive",
  samkeyAddress: "samkeyAddress",
  mduCADText: "mduCADText",
  mduPassive: "mduPassive",
  mduFiberCable: "mduFiberCable",
  mduRFCable: "mduRFCable",
  mduPowerSupply: "mduPowerSupply",
  mduTap: "mduTap",
  address: "address",
  base64StringPrefix: "data:image/png;base64,",
  mduStrandPoint: "mduStrandPoint",
  mduStrandSpecialCable: "mduStrandSpecialCable",
  mduStrandCable: "mduStrandCable",
  powerLevel: "Power Level",
  fiberStorageAndRiser: "fiberStorageAndRiser",
  fiberAddrConnectionPoint: "fiberAddrConnectionPoint",
  powerInserter: "powerInserter",
  mduRaster: "mduRaster",
  siteComponents: "siteComponents",
  in: "IN",
  out: "OUT",
  riser: "RISER",
  storageLoop: "STORAGE_LOOP",
  success: "SUCCESS",
  topology: "topology",
  circle: "circle",
  diamond: "diamond",
  rensNum: "rensNum",
  ponNode: "ponNode",
  wirelessSite: "wirelessSite",
  smallCell: "smallCell",
  clamshell: "clamshell",
  rbCircuit: "rbCircuit",
  click: "clickTask",
  Smart: "Smart",
  Address: "Address",
  ONTSN: "ONT SN",
  OLT: "OLT",
  NODE: "NODE",
  info: "info",
  comment: "comment",
  mduCADHatch: "mduCADHatch",
  mduCADSymbol: "mduCADSymbol",
  mduCADCircle: "mduCADCircle",
  dateTimeFormatForComments: "MM/dd/yyyy, hh:mm:ss aa",
  RF: "RF",
  fiberAndRF: "fiberAndRF",
  GPON_NODE: "GPON_NODE",
  DOCSIS_NODE: "DOCSIS_NODE",
  memoComment: "memoComment",
  other: "other",
  create: "create",
  update: "update",
  delete: "delete",
  olt: "olt",
  expandedTable: "expandedtable",
  expandedCustomTable: "expandedcustomtable",
  opticalTap: "opticalTap",
  LCP: "localConvergencePoint",
  phub: "phub",
  mduStrobeTable: "MDU_STROBE_TABLE",
  wdsPlotStyle: "Color_2",
  accessToAll: "accessToAll",
  allExceptIntServTech: "allExceptIntServTech", //used for switch to allow/disallow topology view from internal service techs
  wdsLayer0: "0",
  wdsLayer2: "2",
  wdsLayerDraft: "DRAFT",
  wdsLayerLbac: "LB_A_C_P3-412",
  wdsLayerLbMiscSymbol: "LB_MISC_SYMBOL_DIVISION",
  wdsLayerLbMiscText: "LB_MISC_TEXT",
  wdsLayerLbRoadRow: "LB_ROAD_ROW",
  wdsLayerMduFloorPlan: "MDU_FLOOR_PLAN",
  wdsLayerMduPanelDesign: "MDU_PANEL_DESIGN",
  wdsLayerMduPanelDesignText: "MDU_PANEL_DESIGN_TEXT",
  wdsLayerMduPanelStrobe: "MDU_PANEL_STROBE",
  wdsLayerMduSymbol: "MDU_SYMBOL",
  wdsLayerMdBorder: "MD_BORDER",
  wdsLayerMdFlrText: "MD_FLR_TEXT",
  wdsLayerMdKeyText: "MD_KEY_TEXT",
  wdsLayerMdPanelStrobe: "MD_PANEL_STROBE",
  wdsLayerMdPanelSymbol: "MD_PANEL_SYMBOL",
  wdsLayerMdPanelTap: "MD_PANEL_TAP",
  wdsLayerMdPanelText: "MD_PANEL_TEXT",
  wdsLayerMdRiserStrobe: "MD_RISER_STROBE",
  wdsLayerMdRiserSymbol: "MD_RISER_SYMBOL",
  wdsLayerTemp: "TEMP",
  wdsLayerMdFlrPanel: "MD_FLR_PANEL",
  wdsLayerMdRiserText: "MD_RISER_TEXT",
  wdsLayerMduNotes: "MDU_NOTES",
  wdsLayerMduPanelDesignSymbol: "MDU_PANEL_DESIGN_SYMBOL",
  wdsLayerMduPanelLabel: "MDU_PANEL_LABEL",
  and: "and",
  or: "or",
  attach: "attach",
  diagnostics: "diagnostics",
  nika: "nika",
  workOrders: "workOrders",
  returnSegment: "Return Segment",
  vhub: "vhub",
  ps: "PS",
  enabledElementsForTrace: [
    "rfSplice", //for RF trace
    "splitter", //for RF trace
    "terminator", //for RF trace
    "activeEquipment", //for RF trace
    "tap", //for RF trace
    "powerBlock", //for RF trace
    "powerInserter", //for RF trace
    "powerSupply", //for powertrace
  ],
  rensElement: "rensElement",
  downstream: "downstream",
  upstream: "upstream",
  km: "KM",
  m: "M",
});
