import { FaSearch } from "react-icons/fa";
import DiagContainer from "../../../../components/reusable/DiagContainer";
import { Divider } from "@material-ui/core";
import SimpleDataTable from "../../reusable/SimpleDataTable";
import ExceptionBox from "../../../../components/reusable/ExceptionBox";
import { useDispatch } from "react-redux";
import TopologyLabels from "../../../constants/topologyLabels";
import {
  GetLocatorElementData,
  GetWirelessSiteSearchProps,
} from "../../../util/reduxFunctions/getTopologyState";
import { useState } from "react";
import { getWirelessSiteSearch } from "../../../store/slices/search/wirelessSiteSearch";
import { findEleFromLocatorEle } from "../../../util/utilFuncs";
import appConst from "../../../constants/appConst";
import {
  setMapCenter,
  setSelectedMapElement,
} from "../../../store/slices/mapItems/mapDetails";
import { addToLocatorEle } from "../../../store/slices/mapItems/locatorElements";

const WirelessSiteSearch = () => {
  const dispatch = useDispatch();
  const labels = TopologyLabels();
  const { wirelessSiteData, wirelessSiteLoading, wirelessSiteError } =
    GetWirelessSiteSearchProps();
  const { locatorEle } = GetLocatorElementData();
  const [wirelessSite, setWirelessSite] = useState("");

  const wirelessSiteConfig = [labels.networkID, labels.type, labels.name];

  const searchWirelessSite = () => {
    if (wirelessSite) {
      dispatch(getWirelessSiteSearch(wirelessSite));
    }
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      searchWirelessSite();
    }
  };

  const makeEleActive = (eleData: any) => {
    dispatch(
      setMapCenter({
        lat: eleData?.geometry?.lat,
        lng: eleData?.geometry?.lng,
      })
    );
    dispatch(setSelectedMapElement(eleData));
  };

  const handleWirelessSiteClick = (ele: any) => {
    if (ele) {
      const eleData = findEleFromLocatorEle(
        "networkId",
        appConst.wirelessSite,
        ele,
        locatorEle?.[appConst.wirelessSite]
      );
      if (eleData) {
        makeEleActive(eleData);
      } else {
        dispatch(
          addToLocatorEle({
            eleType: appConst.wirelessSite,
            eleData: ele,
          })
        );
        let activeEle = {
          geometry: {
            lat: ele?.geometry?.coordinates?.[1],
            lng: ele?.geometry?.coordinates?.[0],
          },
          properties: {
            id: ele?.networkId,
            type: appConst.wirelessSite,
            data: ele,
          },
        };
        makeEleActive(activeEle);
      }
    }
  };

  return (
    <div className="renSearchCont">
      <section>
        <input
          type="text"
          value={wirelessSite}
          placeholder="Enter Wireless Site Number"
          onChange={(val: any) => {
            setWirelessSite(val.target.value);
          }}
          onKeyPress={(event: any) => handleKeyPress(event)}
        />
        <button onClick={() => searchWirelessSite()}>
          <FaSearch />
        </button>
      </section>
      <div className="searchResult">
        <DiagContainer
          isLoading={wirelessSiteLoading}
          data={wirelessSiteData}
          error={wirelessSiteError}
          sectionName={"Wireless Site Search"}
        >
          <Divider />
          {wirelessSiteData?.length > 0 ? (
            <SimpleDataTable
              headers={wirelessSiteConfig}
              tBody={wirelessSiteData?.map((ele: any, i: number) => {
                if (ele) {
                  return (
                    <tr key={i}>
                      <td
                        className="rensNID"
                        onClick={() => handleWirelessSiteClick(ele)}
                      >
                        {ele?.networkId}
                      </td>
                      <td>{ele?.type}</td>
                      <td>{ele?.name}</td>
                    </tr>
                  );
                }
              })}
            />
          ) : (
            <ExceptionBox headerText={labels.noDataFound} />
          )}
        </DiagContainer>
      </div>
    </div>
  );
};

export default WirelessSiteSearch;
