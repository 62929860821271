import { useEffect, useState } from "react";
import { Search } from "react-bootstrap-icons";
import { useDispatch } from "react-redux";
import AppLabels from "../../../../constants/App_Labels";
import { getMultipleAccData } from "../../../../store/slices/ping/multiDeviceSearchAcc";
import { getMultipleMACData } from "../../../../store/slices/ping/multiDeviceSearchMAC";
import { getMultipleONTData } from "../../../../store/slices/ping/multiDeviceSearchONT";
import { GetTelemetryProps } from "../../../../topology/util/reduxFunctions/getTopologyState";
import CustomErrorBox from "../../reusable/CustomErrorBox";
interface Props {
  onNodeOpen: () => void;
  onSearchPopupHide: () => void;
}

const MDS = ({ onNodeOpen, onSearchPopupHide }: Props) => {
  const labels = AppLabels();
  const [errorBoxFlag, setErrorBoxFlag] = useState(false);
  const [errorMACBoxFlag, setErrorMACBoxFlag] = useState(false);
  const [boxFlag, setBoxFlag] = useState(false);
  const dispatch = useDispatch();
  const { modemType } = GetTelemetryProps();
  const [errorMsg, setErrorMsg] = useState("");
  const [isExceedingLines, setIsExceedingLines] = useState(false);
  const [isExAccLines, setIsExAccLines] = useState(false);
  const [isExLines, setIsExLines] = useState(false);
  const [errorBoxBody, setErrorBoxBody] = useState(<div></div>);
  const [errorMACBoxBody, setErrorMACBoxBody] = useState(<div></div>);
  const [hasSearched, setHasSearched] = useState(false);
  const [hasMACSearched, setHasMACSearched] = useState(false);
  const [hasONTSearched, setHasONTSearched] = useState(false);
  const [activeMDPH, setActiveMDPH] = useState("Search By CM MAC");
  const [activeMAPH, setActiveMAPH] = useState("Search By FA Id");
  const [searchONT, setSearchONT] = useState("");
  const [searchMDValue, setSearchMDValue] = useState("");
  const [historyMDDisplay, setHistoryMDisplay] = useState(false);
  const [searchMAValue, setSearchMAValue] = useState("");
  const [searchStreetNoValue, setSearchStreetNoValue] = useState("");
  const [searchStreetNameValue, setSearchStreetNameValue] = useState("");
  const [historyMADisplay, setHistoryMADisplay] = useState(false);
  const [values, setValues] = useState<string[]>([]);
  const [inputValues, setInputValues] = useState<string[]>([]);
  const [inValues, setInValues] = useState<string[]>([]);
  const displayInputError = (errorMsg: any) => {
    setErrorBoxBody(errorMsg);
    setErrorBoxFlag(true);
  };
  const displayMACInputError = (errorMACMsg: any) => {
    setErrorMACBoxBody(errorMACMsg);
    setErrorMACBoxFlag(true);
  };
  const setInputError = (errorMACMsg: any) => {
    // setBoxBody(errorMACMsg);
    setBoxFlag(true);
  };
  const handleStreetNoSearch = (e: any) => {
    setSearchStreetNoValue(e.target.value);
  };
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      setTimeout(() => {
        updateInputMDValues();
        setSearchMDValue(searchMDValue + "\n");
      }, 0);
    }
  };
  const handleONTKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      setTimeout(() => {
        updateValues();
        setSearchONT(searchONT + "\n");
      }, 0);
    }
  };
  const handleStreetPress = (e: any) => {
    setSearchStreetNoValue(e.target.value);
  };
  const handleStreetNamePress = (e: any) => {
    setSearchStreetNameValue(e.target.value);
  };
  const handleStreetSearch = async () => {
    let url = `/#/multiDeviceByAddress?streetNo=${searchStreetNoValue}&streetName=${searchStreetNameValue}`;
    let newWindow = window.open(url, "_blank")!;
    newWindow.focus();
  };

  const handleSearch = async () => {
    if (inputValues?.length > 20) {
      displayMACInputError("You have entered more than 20 lines.");
    } else {
      return new Promise((resolve) => {
        setHasMACSearched(true);
        let routeUrl = `/#/searchByMacAddress`;
        let windowRoute = window.open(routeUrl, "_blank")!;
        if (windowRoute) {
          windowRoute.focus();
          windowRoute?.addEventListener("load", () => {
            windowRoute.postMessage(
              { searchMDValue: inputValues },
              windowRoute.origin
            );
          });
        }
        // windowRoute.focus();
      });
    }
  };
  // }, [history?.location?.search]);
  useEffect(() => {
    if (hasMACSearched) {
      dispatch(getMultipleMACData(inputValues));
      setHasMACSearched(false);
    } else if (hasSearched) {
      dispatch(getMultipleAccData(inValues));
      setHasSearched(false);
    }
  }, [modemType]);
  useEffect(() => {
    if (hasONTSearched === true) {
      dispatch(getMultipleONTData(values));
      setHasONTSearched(false);
    }
  }, [hasONTSearched]);

  const handlePress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      setTimeout(() => {
        updateInputValues();
        setSearchMAValue(searchMAValue + "\n");
      }, 0);
    }
  };
  const updateInputValues = () => {
    let lines = searchMAValue.split("\n").filter(Boolean);
    if (lines.length > 20) {
      lines = lines.slice(0, 20);
      displayInputError("You have entered more than 20 lines.");
    } else {
      setErrorBoxFlag(false); // If not more than 20 lines, set flag to false
    }
    setInValues(lines); // Update the state variable
  };

  const updateInputMDValues = () => {
    let lines = searchMDValue.split("\n").filter(Boolean);
    if (lines.length > 20) {
      lines = lines.slice(0, 20);
      displayMACInputError("You have entered more than 20 lines.");
    }
    setInputValues(lines);
  };
  const updateValues = () => {
    let lines = searchONT.split("\n").filter(Boolean);
    if (lines.length > 20) {
      lines = lines.slice(0, 20);
      setInputError("You have entered more than 20 lines.");
    }
    setValues(lines);
  };
  const handleBlur = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLTextAreaElement>
  ) => {
    if (e.target instanceof HTMLTextAreaElement) {
      const input = e.target.value;
      const processedInput = input
        .split("\n")
        .map((line: any) => line.replace(/[^\d]/g, ""))
        .filter((line: any) => line.length === 9 || line.length === 12)
        .join("\n");

      setSearchMAValue(processedInput);

      updateInputValues();
    }
  };
  const handleMDBlur = () => {
    updateInputMDValues();
  };
  const handleONTBlur = () => {
    updateValues();
  };
  const handleAccSearch = async () => {
    if (inValues?.length > 20) {
      displayInputError("You have entered more than 20 lines.");
    } else {
      return new Promise((resolve) => {
        setHasSearched(true);

        let routeUrl = `/#/searchByAccountNumber`;
        let windowRoute = window.open(routeUrl, "_blank")!;
        if (windowRoute) {
          windowRoute.focus();
          windowRoute?.addEventListener("load", () => {
            windowRoute.postMessage(
              { searchMAValue: inValues },
              windowRoute.origin
            );
          });
        }
        // windowRoute.focus();
      });
    }
  };
  const handleONTSNSearch = async () => {
    if (values?.length > 20) {
      setInputError("You have entered more than 20 lines.");
    } else {
      return new Promise((resolve) => {
        setHasONTSearched(true);

        let routeUrl = `/#/searchByONTSN`;
        let windowRoute = window.open(routeUrl, "_blank")!;
        if (windowRoute) {
          windowRoute.focus();
          windowRoute?.addEventListener("load", () => {
            windowRoute.postMessage(
              { searchONTValue: values },
              windowRoute.origin
            );
          });
        }
        // windowRoute.focus();
      });
    }
  };
  const handleMDSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let newValue = e.target.value;

    let lines = newValue.split("\n");

    lines = lines.map((line) => {
      if (line.length > 12) {
        line = line.substring(0, 12);
      }

      if (!/^[A-Za-z0-9]{9,12}$/.test(line)) {
        line = line.replace(/[^A-Za-z0-9]/g, "").substring(0, 12);
      }
      setIsExceedingLines(lines.length > 20);
      return line;
    });

    newValue = lines.join("\n");

    setSearchMDValue(newValue);

    const textarea = e.target;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };
  const handleONTSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let newValue = e.target.value;

    let lines = newValue.split("\n");

    lines = lines.map((line) => {
      if (line.length > 12) {
        line = line.substring(0, 12);
      }

      if (!/^[A-Za-z0-9]{9,12}$/.test(line)) {
        line = line.replace(/[^A-Za-z0-9]/g, "").substring(0, 12);
      }
      setIsExLines(lines.length > 20);
      return line;
    });

    newValue = lines.join("\n");

    setSearchONT(newValue);

    const textarea = e.target;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };
  const handleMASearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const rawInput = e.target.value;
    const lines = rawInput.split("\n");

    // Limit each line to 12 characters
    const limitedLines = lines.map((line) => {
      return line.replace(/[^\d]/g, "").substring(0, 12);
    });
    setIsExAccLines(limitedLines.length > 20);
    setSearchMAValue(limitedLines.join("\n"));

    // setSearchMAValue(e.target.value);
    const textarea = e.target;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  return (
    <>
      <div>
        {" "}
        <label
          style={{ fontWeight: 500 }}
        >{`SEARCH BY MULTI MAC / ACCOUNT`}</label>
        <hr className="tabDivider" />
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto auto",
          alignItems: "center",
        }}
      >
        <div
          style={{
            paddingRight: "20px",
          }}
        >
          <label style={{ fontWeight: 400, color: "red" }}>
            {`${labels.macMultiSearch}`}
          </label>
        </div>
        {/* <div
          style={{
            paddingLeft: "5px",
          }}
        > */}
        <label style={{ fontWeight: 400, color: "red" }}>
          {`${labels.accountMultiSearch}`}
        </label>
        {/* </div> */}
        <div
          style={{
            paddingRight: "36px",
          }}
        >
          <label style={{ fontWeight: 400, color: "red" }}>
            {`ONT SN Search`}
          </label>
        </div>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto auto",
          alignItems: "center",
          paddingLeft: "5px",
        }}
      >
        <div className="searchInputCont">
          <div className="mdSearchInputBox" tabIndex={0} onBlur={handleMDBlur}>
            <textarea
              id="mdSearchInput"
              tabIndex={0}
              className="custSearchInput"
              rows={5}
              onBlur={handleMDBlur}
              name="searchMDVal"
              value={searchMDValue}
              onChange={(e: any) => handleMDSearch(e)}
              onKeyPress={(e: any) => handleKeyPress(e)}
              placeholder={activeMDPH}
              onClick={() => setHistoryMDisplay(true)}
              autoComplete="off"
              style={{ overflowY: "hidden" }}
            />

            {/* <input
      id="mdSearchInput"
      className="custSearchInput"
      type="text"
      name="searchMDVal"
      value={searchMDValue}
      onChange={handleMDSearch}
      onKeyPress={(e: any) => handleKeyPress(e)}
      placeholder={activeMDPH}
      onClick={() => setHistoryMDisplay(true)}
     
      autoComplete="off"
    /> */}
            {/* <InputHistoryBox
      storageKey="searchHistory"
      historyDisplay={historyMDDisplay}
      setHistoryDisplay={setHistoryMDisplay}
      searchValue={searchMDValue}
      onOptionClick={(sValue: any) => {
        setSearchMDValue(sValue);
      }}
      inputId="mdsInput"
    /> */}
            <br></br>
            <label className="subtextLabel">(Max 20 Per Search)</label>
          </div>
        </div>

        <div className="searchInputCont">
          <div className="mdSearchInputBox" tabIndex={1} onBlur={handleMDBlur}>
            <textarea
              tabIndex={1}
              id="mdSearchInput"
              className="custSearchInput"
              rows={5}
              name="searchMAVal"
              value={searchMAValue}
              onBlur={handleBlur}
              onChange={handleMASearch}
              onKeyPress={(e: any) => handlePress(e)}
              placeholder={activeMAPH}
              onClick={() => setHistoryMADisplay(true)}
              autoComplete="off"
              style={{ overflowY: "hidden" }}
            />
            {/* <InputHistoryBox
      storageKey="searchHistory"
      historyDisplay={historyMADisplay}
      setHistoryDisplay={setHistoryMADisplay}
      searchValue={searchMDValue}
      onOptionClick={(sValue: any) => {
        setSearchMAValue(sValue);
      }}
      inputId="mAInput"
    />{" "} */}
            <br></br>
            <label className="subtextLabel">(Max 20 Per Search)</label>
          </div>
        </div>

        <div className="searchInputCont">
          <div className="mdSearchInputBox" tabIndex={2} onBlur={handleMDBlur}>
            <textarea
              id="mdSearchInput"
              tabIndex={0}
              className="custSearchInput"
              rows={5}
              onBlur={handleONTBlur}
              name="searchONT"
              value={searchONT}
              onChange={(e: any) => handleONTSearch(e)}
              onKeyPress={(e: any) => handleONTKeyPress(e)}
              placeholder={"Search By ONT SN"}
              onClick={() => setHistoryMDisplay(true)}
              autoComplete="off"
              style={{ overflowY: "hidden" }}
            />
            <br></br>
            <label className="subtextLabel">(Max 20 Per Search)</label>
          </div>
        </div>
      </div>
      <br></br>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto auto",
          alignItems: "center",
          paddingLeft: "35px",
        }}
      >
        <div className="searchInputCont">
          <button
            className="customMDSearchBtn"
            title={"Search"}
            disabled={isExceedingLines}
            onClick={handleSearch}
          >
            <Search />
          </button>
        </div>

        <div
          className="searchInputCont"
          style={{
            paddingLeft: "12px",
          }}
        >
          <button
            className="customMDSearchBtn"
            title={"Search"}
            disabled={isExAccLines}
            onClick={handleAccSearch}
          >
            <Search />
          </button>
        </div>
        <div
          className="searchInputCont"
          style={{
            paddingLeft: "16px",
          }}
        >
          <button
            className="customMDSearchBtn"
            title={"Search"}
            disabled={isExLines}
            onClick={handleONTSNSearch}
          >
            <Search />
          </button>
        </div>
        <br></br>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            alignItems: "center",
            paddingLeft: "35px",
          }}
        ></div>
        <div className="checkBoxCont">
          <CustomErrorBox
            showVal={errorMACBoxFlag}
            bodyElement={
              <div>
                <span>{labels.multiErr}</span>
                <ul>{errorMACBoxBody}</ul>
              </div>
            }
            handleClose={() => setErrorMACBoxFlag(false)}
          />
        </div>
        <div className="checkBoxCont">
          <CustomErrorBox
            showVal={errorBoxFlag}
            bodyElement={
              <div>
                <span>{labels.multiErr}</span>
                <ul>{errorBoxBody}</ul>
              </div>
            }
            handleClose={() => setErrorBoxFlag(false)}
          />
        </div>
        <div className="checkBoxCont">
          <CustomErrorBox
            showVal={boxFlag}
            bodyElement={
              <div>
                <span>{labels.multiErr}</span>
                <ul>{errorBoxBody}</ul>
              </div>
            }
            handleClose={() => setBoxFlag(false)}
          />
        </div>
      </div>
      <div>
        <label style={{ fontWeight: 500 }}>{`SEARCH BY ADDRESS`}</label>
        <hr className="tabDivider" />
        <div className="searchInputCont">
          <div className="row">
            <label className="textLabel">
              {`STREET #*`} :
              <input
                className="custNoPingSearchInput"
                type="text"
                name="searchVal"
                value={searchStreetNoValue}
                onChange={handleStreetNoSearch}
                onKeyPress={(e: any) => handleStreetPress(e)}
                placeholder={"0000"}
                autoComplete="off"
                style={{ paddingLeft: "3px" }}
              />
            </label>
          </div>
          <div className="row">
            <label className="textLabel">
              {"STREET NAME*"} :
              <input
                id="dataInput"
                className="custPingSearchInput"
                type="text"
                name="searchVal"
                value={searchStreetNameValue}
                onChange={handleStreetNamePress}
                onKeyPress={(e: any) => handleStreetNamePress(e)}
                placeholder={"Street Name"}
                autoComplete="off"
                style={{ paddingLeft: "3px" }}
              />
            </label>
          </div>

          <button className="customSearchBtn" onClick={handleStreetSearch}>
            <Search />
          </button>
        </div>
      </div>
    </>
  );
};

export default MDS;
