import { Console } from "console";
import AppLabels from "../../constants/App_Labels";
import CustomAccordion from "../reusable/CustomAccordion";
import {
  getCheckValue,
  getStatusOfCheck,
  getStatusOfCheckHeader,
  getThreshold,
  isObject,
  isNull,
  openNewWindow,
  secToTime,
} from "../reusable/Util";
import CustomPopOver from "../reusable/CustomPopOver";

interface Props {
  stbCMData: any;
  stbInterfaceData: any;
  cmNodeInfo: any;
}

const STBCMInfo = ({ stbCMData, stbInterfaceData, cmNodeInfo }: Props) => {
  const labels = AppLabels();
  return (
    <CustomAccordion
      accTitle={labels.cmDetails.toUpperCase()}
      accBodyElement={
        <table className="dataTableDetails">
          <thead>
            <th>NAME</th>
            <th>VALUE</th>
            <th>STATUS</th>
          </thead>
          <tbody>
            <tr>
              <td>{labels.CM_MAC?.toUpperCase()}</td>
              <td>{isNull(stbCMData?.cmMac)}</td>
              <td></td>
            </tr>
            <tr>
              <td>{labels.CMTS_IP?.toUpperCase()}</td>
              <td
                className="nodeLink"
                onClick={() => {
                  openNewWindow(
                    `/#/node/cmts?nodeId=${
                      cmNodeInfo?.nodeId ? cmNodeInfo?.nodeId : "-"
                    }&mac=${
                      cmNodeInfo?.mac_domain ? cmNodeInfo?.mac_domain : "-"
                    }&usPort=${
                      cmNodeInfo?.port_group_name_normalized
                        ? "Upstream " + cmNodeInfo?.port_group_name_normalized
                        : "-"
                    }&cmtsIp=${stbCMData?.cmtsIP ? stbCMData?.cmtsIP : "-"}`
                  );
                }}
              >
                {isNull(stbCMData?.cmtsIP)}
              </td>
              <td></td>
            </tr>
            <tr>
              <td>{labels.eCMInterfaceStatus?.toUpperCase()}</td>
              <td className="cellNewLine">
                {isNull(
                  stbInterfaceData?.map((stbIF: any) => {
                    let interfaceStatus = stbIF?.inOperation
                      ? labels.up
                      : labels.down;
                    return interfaceStatus + "\n";
                  })
                )}
              </td>
              <td></td>
            </tr>
            <tr>
              <td>{labels.eCMIP?.toUpperCase()}</td>
              <td>{isNull(stbCMData?.mgmtIPv6)}</td>
              <td></td>
            </tr>
            <tr>
              <td>{labels.pcInterfaceType?.toUpperCase()}</td>
              <td className="cellNewLine">
                {isNull(
                  stbInterfaceData?.map((stbIF: any) => {
                    return stbIF?.name + "\n";
                  })
                )}
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                {labels.CM?.toUpperCase() + " " + labels.Uptime?.toUpperCase()}
              </td>
              <td>{isNull(secToTime(stbCMData?.uptime))}</td>
              <td></td>
            </tr>
            <tr>
              <td>
                {getStatusOfCheckHeader(
                  stbCMData.cmOnlineStatus,
                  labels.CM_STATUS.toUpperCase()
                )}
              </td>
              {stbCMData?.hasOwnProperty("cmOnlineStatus") ? (
                <>
                  <td className="cellNewLine">
                    {isNull(getCheckValue(stbCMData?.cmOnlineStatus))}
                  </td>
                  <CustomPopOver
                    popOverData={getThreshold(stbCMData?.cmOnlineStatus)}
                    originComp={getStatusOfCheck(stbCMData?.cmOnlineStatus)}
                    index="popOver"
                  />
                </>
              ) : (
                <>
                  <td>N/A</td>
                  <td></td>
                </>
              )}
            </tr>
            <tr>
              <td>
                {getStatusOfCheckHeader(
                  stbCMData.cmtsCmOnlineStatus,
                  labels.CMTS_CM_Online_Status.toUpperCase()
                )}
              </td>
              {stbCMData?.hasOwnProperty("cmtsCmOnlineStatus") ? (
                <>
                  <td className="cellNewLine">
                    {isNull(getCheckValue(stbCMData?.cmtsCmOnlineStatus))}
                  </td>
                  <CustomPopOver
                    popOverData={getThreshold(stbCMData?.cmtsCmOnlineStatus)}
                    originComp={getStatusOfCheck(stbCMData?.cmtsCmOnlineStatus)}
                    index="popOver"
                  />
                </>
              ) : (
                <>
                  <td>N/A</td>
                  <td></td>
                </>
              )}
            </tr>
            <tr>
              <td>{labels.CMTSName?.toUpperCase()}</td>
              <td>{isNull(stbCMData?.cmtsName)}</td>
              <td></td>
            </tr>
            <tr>
              <td>{labels.CMTS_UPTIME?.toUpperCase()}</td>
              <td>{isNull(secToTime(stbCMData?.cmtsUptime))}</td>
              <td></td>
            </tr>
            <tr>
              <td>{labels.CMTS_CM_Percent_Time_Online?.toUpperCase()}</td>
              <td>{isNull(stbCMData?.onlinePercentage)}%</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      }
    />
  );
};

export default STBCMInfo;
