export const GET_ALL_REASONS_BEGIN = "GET_ALL_REASONS_BEGIN";
export const GET_ALL_REASONS_SUCCESS = "GET_ALL_REASONS_SUCCESS";
export const GET_ALL_REASONS_FAILURE = "GET_ALL_REASONS_FAILURE";

interface GetAllReasonsBegin {
  type: typeof GET_ALL_REASONS_BEGIN;
}

interface GetAllReasonsSuccess {
  type: typeof GET_ALL_REASONS_SUCCESS;
  payload: any;
}

interface GetAllReasonsFailure {
  type: typeof GET_ALL_REASONS_FAILURE;
  payload: Error;
}

export type GetAllReasonsActionTypes =
  | GetAllReasonsBegin
  | GetAllReasonsSuccess
  | GetAllReasonsFailure;
