import React, { useEffect, useState } from "react";
import appConst from "../../../../constants/appConst";
import { GetNodeSiteDetailsProps } from "../../../../util/reduxFunctions/getTopologyState";

interface Props {
  gMap: any;
}

const PoleStrand = ({ gMap }: Props) => {
  const { activeNode, allNodeData } = GetNodeSiteDetailsProps();
  /* const [annoFeatures, setAnnoFeatures] = useState<Array<any>>([]); */
  const poleStrandData = activeNode?.poleStrand?.data;

  useEffect(() => {
    if (gMap && allNodeData) {
      allNodeData?.map(
        (e: any) =>
          e?.poleStrand?.data && gMap?.data.addGeoJson(e?.poleStrand?.data)
      );
    }
  }, [poleStrandData]);
  /* 
  useEffect(() => {
    if (gMap && annoFeatures !== undefined && annoFeatures?.length > 0) {
      const tempGeoJson = {
        type: appConst?.FeatureCollection,
        features: annoFeatures,
      };
      gMap?.data.addGeoJson(tempGeoJson);
    }
  }, [annoFeatures]); */

  useEffect(() => {
    if (
      gMap &&
      allNodeData &&
      poleStrandData !== undefined &&
      poleStrandData?.features?.length > 0
    ) {
      let allFeatures = [] as any;
      allNodeData?.map(
        (e: any) =>
          e?.poleStrand?.data?.features &&
          allFeatures.push(...e?.poleStrand?.data?.features)
      );
      const tempGeoJson = {
        type: appConst?.FeatureCollection,
        features: allFeatures,
      };
      gMap?.data.addGeoJson(tempGeoJson);
    }
  }, [gMap]);

  /* const getAnnoFeatures = (data: any) => {
    let tempFeatures: any = [];
    if (data) {
      data?.features?.map((e: any, i: number) => {
        const feature = {
          featureId: e?.featureId + "psAnno" + i,
          geometry: e.properties?.data?.annoGeometry,
          properties: e.properties,
          type: appConst.Feature,
        };
        tempFeatures.push(feature);
      });
    }
    return tempFeatures;
  }; */

  return <></>;
};
export default React.memo(PoleStrand);
