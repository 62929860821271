import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../middleware/apiActions";
import { MDU_CAD_HATCH } from "../../../middleware/apiEndpoints";
import storeConst from "../../../storeConst";
import { decrApiCall, incrApiCall } from "../mapDetails";

const slice = createSlice({
  name: "mduCADHatch",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    getMduCADHatchBegin: (mduCADHatch) => {
      mduCADHatch.isLoading = true;
      mduCADHatch.status = "";
    },
    getMduCADHatchSuccess: (mduCADHatch, action) => {
      mduCADHatch.isLoading = false;
      mduCADHatch.data = action.payload;
      mduCADHatch.status = storeConst.OK;
    },
    getMduCADHatchFailure: (mduCADHatch, action) => {
      mduCADHatch.isLoading = false;
      mduCADHatch.error = action.payload;
      mduCADHatch.data = undefined;
      mduCADHatch.status = storeConst.ERROR;
    },
  },
});

const { getMduCADHatchBegin, getMduCADHatchSuccess, getMduCADHatchFailure } =
  slice.actions;
export default slice.reducer;

export const getMduCADHatch = (owningDrawing: any) => (dispatch: any) => {
  dispatch(
    apiCallBegan({
      url: `${MDU_CAD_HATCH}/${owningDrawing}`,
      method: "get",
      onStart: getMduCADHatchBegin.type,
      onSuccess: getMduCADHatchSuccess.type,
      onError: getMduCADHatchFailure.type,
      onStartDispatch: incrApiCall.type,
      onSuccessDispatch: decrApiCall.type,
      onErrorDispatch: decrApiCall.type,
    })
  );
};
