import { WIFIRadios } from "../../../../models/account/diagnostics/cm/WIFIRadios";

export const WIFI_RADIOS_BEGIN = "WIFI_RADIOS_BEGIN";
export const WIFI_RADIOS_SUCCESS = "WIFI_RADIOS_SUCCESS";
export const WIFI_RADIOS_FAILURE = "WIFI_RADIOS_FAILURE";

interface WIFIRadiosBegin {
  type: typeof WIFI_RADIOS_BEGIN;
}

interface WIFIRadiosSuccess {
  type: typeof WIFI_RADIOS_SUCCESS;
  payload: WIFIRadios;
}

interface WIFIRadiosFailure {
  type: typeof WIFI_RADIOS_FAILURE;
  payload: Error;
}

export type WIFIRadiosActionTypes =
  | WIFIRadiosBegin
  | WIFIRadiosSuccess
  | WIFIRadiosFailure;
