import { CSSProperties, isValidElement } from "react";
import { AiOutlineExpand } from "react-icons/ai";
import ReactDOMServer from "react-dom/server";

import CustomAccordion from "../../../../components/reusable/CustomAccordion";
import appConst from "../../../constants/appConst";
import ExportAsCsv from "./ExportAsCsv";
import { NewTabCompType } from "../newTab/ExpandedCustomTable";
import colors from "../../../config/colors";

export interface TCTypes {
  th: string;
  thStyle?: CSSProperties;
  tdName: string; //name of object attribute to map on UI
  tdStyle?: CSSProperties;
}
export interface TDTypes {
  id: number | string;
  [key: string]: any;
}

interface NewTabPropsType {
  compType: NewTabCompType;
  [key: string]: any;
}

export interface TableProps {
  title: string;
  tableStyle?: CSSProperties;
  columns: TCTypes[];
  data: TDTypes[];
  /**used for export data when paginating table */
  fullData?: Array<object>;
  footerComp?: JSX.Element | JSX.Element[];
  /** @default true */
  showExportBtn?: boolean;
  /** @default true */
  showExpandBtn?: boolean;
  newTabTitle?: string;
  /**to use custom new tab, make necessary changes in ExpandedCustomTable.tsx */
  newTabProps?: NewTabPropsType;
  /**not necessary to use "tabDataCont" className in topology view since the main parent class of the drawer is already using this className @default false */
  useTabDataContClass?: boolean;
  headerDetails?: string;
}

export default function Table({
  title,
  tableStyle,
  columns,
  data,
  fullData,
  footerComp,
  showExportBtn = true,
  showExpandBtn = true,
  newTabTitle,
  newTabProps,
  useTabDataContClass = false,
  headerDetails,
}: TableProps) {
  const expandTableInNewTab = () => {
    const getProps = () => ({
      title,
      tableStyle,
      columns,
      data: serializeData(data, columns),
      fullData: serializeData(fullData, columns),
      showExportBtn,
      showExpandBtn: false,
    });

    const message = newTabProps ? newTabProps : getProps();
    const urlName = newTabProps
      ? appConst.expandedCustomTable
      : appConst.expandedTable;

    const url = window?.open(`/#/` + urlName);
    url?.addEventListener("load", () => {
      if (newTabTitle) url.document.title = newTabTitle;
      url.postMessage(message, url.origin);
    });
  };

  if (!data || data?.length < 1) return null;

  return (
    <div className={`activeMapEleCont ${useTabDataContClass && "tabDataCont"}`}>
      <CustomAccordion
        accTitle={title}
        footerComp={footerComp}
        headerComp={
          <>
            <ExportAsCsv
              data={fullData ? fullData : data}
              columns={columns}
              title={title}
              showExportBtn={showExportBtn}
            />
            {showExpandBtn && (
              <AiOutlineExpand
                className="basicTableHeaderIcon"
                onClick={expandTableInNewTab}
              />
            )}
          </>
        }
        headerTitleComp={
          headerDetails && (
            <span style={{ color: colors.link }}>{headerDetails}</span>
          )
        }
        accBodyElement={
          <div style={tableStyle}>
            <table className="dataTableDetails">
              <thead>
                <tr>
                  {columns?.map((column) => (
                    <th key={column.th} style={column.thStyle}>
                      {column.th}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data?.map((TD: any) => (
                  <tr
                    key={TD.id}
                    style={{
                      background: TD.isHighlight && TD.highlightColor,
                    }}
                  >
                    {columns?.map((column, i: number) => (
                      <td key={i} style={column.tdStyle}>
                        {TD?.[column?.tdName]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        }
      />
    </div>
  );
}

const getAsString = (value: any) => {
  return isValidElement(value)
    ? ReactDOMServer.renderToString(<>{value}</>)
    : value;
};

const serializeData = (data: any, columns: any) => {
  const tempData = data?.map((d: any) => {
    const tr = columns?.reduce(
      (acc: any, column: any) => ({
        ...acc,
        [column.tdName]: getAsString(d[column.tdName]),
      }),
      {}
    );
    return { id: d.id, ...tr };
  });

  return tempData;
};
