import * as React from "react";
import { useDispatch } from "react-redux";
import {
  getNodeTeleGloabalStatus,
  getTelemetryInfo,
} from "../../components/reusable/NodeUtil";
import AppLabels from "../../constants/App_Labels";
import ConfigConst from "../../constants/ConfigConst";
import { MultiAddressConfig } from "../../constants/MultiAddressConfig";
import { multiDeviceDataSetType } from "../../constants/MultiDeviceNodeConfig";
import {
  GetAddressInfoDetails,
  GetNodeInfoDetails,
  GetTelemetryDetails,
} from "../../get-api/GetResponse";
import NodeInfoDetailsClient from "../../network/httpclient/nodeinfo/NodeInfoDetailsClient";
import { saveConfigData } from "../../store/ducksPattern/userPrefConfig";
import { TELE_ABORT_KEY } from "../../topology/store/middleware/abortKeys";
import { addAbortKey } from "../../topology/store/middleware/apiClient";
import {
  getAddressModemsList,
  setAddressViewType,
  setDiagLoading,
  setDiagType,
  setIsCWEnabled,
  setModemType,
  stopDiagExecution,
} from "../../topology/store/slices/mapItems/diagnostics/addressTelemetry";
import storeConst from "../../topology/store/storeConst";
import { MultiDeviceAddressTab } from "../multi-device-address-page/MultiDeviceAddressTab";
import { MultiDeviceAddressBanner } from "../multi-device-node-page/MultiDeviceAddressBanner";

export const MultiDeviceAddressPage = (props: any) => {
  const { location: search } = props;
  const dispatch = useDispatch();
  const labels = AppLabels();
  const searchParams = new URLSearchParams(search.search);
  const streetNo = searchParams.get("streetNo");
  const streetName = searchParams.get("streetName");

  const { ducksConfigData } = GetTelemetryDetails();
  const { nodedata, currNodedata } = GetNodeInfoDetails();
  const {
    allAddressDiagIsLoading,
    allDiagAddressStatus,
    isAddressAborted,
    mlAddressData,
    viewAddressType,
    diagAddressStartTime,
    diagAddressEndTime,
  } = GetAddressInfoDetails();
  const nodeTeleStatus = getNodeTeleGloabalStatus(
    allDiagAddressStatus,
    allAddressDiagIsLoading,
    isAddressAborted
  );
  const [dataSetName, setDataSetName] = React.useState("");
  const [bannerInfo, setBannerInfo] = React.useState<any>({
    streetNum: streetNo,
    streetName: streetName,
    modemListInfo: "",
    // deviceType: deviceType?.toUpperCase() ? deviceType?.toUpperCase() : "",
    active: "",
    assigned: "",
    responsive: "",
    unresponsive: "",
    startTime: "",
    endTime: "",
  });
  const [expand, setExpand] = React.useState(true);
  const [tableAttributes, setTableAttributes] = React.useState<any>([]);

  let dataSetNames: any = multiDeviceDataSetType?.map((e: any) => {
    return e?.dataSetName;
  });

  const handleToggle = () => {
    setExpand((prev) => !prev);
  };
  React.useEffect(() => {
    dispatch(saveConfigData(MultiAddressConfig));
  }, []);
  const runTelemetry = () => {
    addAbortKey(TELE_ABORT_KEY);
    setBannerInfo({
      streetNum: streetNo,
      streetName: streetName,
      active: undefined,
      assigned: undefined,
      responsive: undefined,
      unresponsive: undefined,
      startTime: undefined,
      endTime: undefined,
    });
    if (
      nodeTeleStatus === ConfigConst.complete.toLowerCase() ||
      nodeTeleStatus === ConfigConst.stopped.toLowerCase()
    ) {
      //   const activeId = currNodedata?.smt
      //     ? currNodedata?.smtId
      //     : smtId
      //     ? smtId
      //     : currNodedata?.nodeId
      //     ? currNodedata?.nodeId
      //     : nodeId;
      const type = currNodedata?.smt ? "smtid" : "shubid";
      dispatch(setIsCWEnabled(false));
      dispatch(
        getAddressModemsList({
          streetNo: streetNo ? streetNo : "",
          streetName: streetName ? streetName : "",
          modemType: "cm,emta,ecmstb,oldstb",
        })
      );
    } else {
      dispatch(setDiagLoading(true));
      //   const activeId = currNodedata?.smt
      //     ? currNodedata?.smtId
      //     : smtId
      //     ? smtId
      //     : currNodedata?.nodeId
      //     ? currNodedata?.nodeId
      //     : nodeId;
      const type = currNodedata?.smt ? "smtid" : "shubid";

      dispatch(
        getAddressModemsList({
          streetNo: streetNo ? streetNo : "",
          streetName: streetName ? streetName : "",
          modemType: "cm,emta,ecmstb,oldstb",
        })
      );
    }
  };

  const stopTelemetry = () => {
    dispatch(stopDiagExecution());
  };

  React.useLayoutEffect(() => {
    // remove the navbar from the DOM
    const navbar = document.getElementsByClassName("drawerCloseSec")[0];
    const header = document.getElementsByClassName(
      "headerSec"
    )[0] as HTMLElement;
    const main = document.getElementsByClassName(
      "main-section"
    )[0] as HTMLElement;
    if (navbar && header && main) {
      // remove the navbar from the DOM completely and adjust the header and main sections margin-left to 0
      if (header?.firstChild) {
        const headerFirstChild = header?.firstChild as HTMLElement;
        headerFirstChild.style.width = "100%";
      }
      header.style.marginLeft = "auto";
      main.style.marginLeft = "auto";
      navbar.remove();
      dispatch(setIsCWEnabled(false));
      dispatch(setModemType("cm,emta,ecmstb,oldstb"));
    }
    if (search) {
      const dataSetType = searchParams.get("dataSet");
      const viewTypeParam = searchParams.get("viewType");
      dispatch(setIsCWEnabled(false));
      if (dataSetType && dataSetType !== "") {
        dispatch(setDiagType(dataSetType?.toLowerCase()?.trim()));
        setDataSetName(dataSetNames[0]);
      } else {
        dispatch(setDiagType(storeConst.all_no_cw));
        setDataSetName(dataSetNames[0]);
      }
      if (viewTypeParam && viewTypeParam !== "") {
        dispatch(setAddressViewType(viewTypeParam?.toUpperCase()?.trim()));
      }
    }
  }, []);

  React.useEffect(() => {
    if (viewAddressType !== undefined && viewAddressType !== "") {
      ducksConfigData?.userPreferences?.node?.telemetry?.views?.map(
        (v: any) => {
          setTableAttributes(v?.attributes);
        }
      );
    }
  }, [viewAddressType]);

  React.useEffect(() => {
    if (
      mlAddressData?.length > 0 &&
      nodeTeleStatus === ConfigConst.complete &&
      !isAddressAborted
    ) {
      const modemListInfo = getTelemetryInfo(mlAddressData);

      setBannerInfo({
        streetNum: streetNo,
        streetName: streetName,
        modemListInfo: modemListInfo,
        active: modemListInfo?.active,
        assigned: modemListInfo?.assigned,
        responsive: modemListInfo?.responsive,
        unresponsive: modemListInfo?.unresponsive,
        startTime: diagAddressStartTime,
        endTime: diagAddressEndTime,
      });
    } else if (allAddressDiagIsLoading) {
      setBannerInfo({
        streetNum: streetNo,
        streetName: streetName,
        modemListInfo: "",
        active: undefined,
        assigned: undefined,
        responsive: undefined,
        unresponsive: undefined,
        startTime: undefined,
        endTime: undefined,
      });
    }
  }, [mlAddressData]);
  React.useEffect(() => {
    if (mlAddressData)
      dispatch(
        NodeInfoDetailsClient.getInfoNodeDetails(
          mlAddressData ? mlAddressData?.[0]?.returnSegment : ""
        )
      );
  }, [mlAddressData?.[0]?.returnSegment]);
  //   React.useEffect(() => {
  //     if (smtId || nodeId) dispatch(SetActiveNodeInfoDetails(nodedata));
  //   }, [nodedata]);
  React.useEffect(() => {
    runTelemetry();
  }, [currNodedata?.nodeType]);
  return (
    <div>
      {
        <div className="emtaLandingPage">
          <div className="gponBannerContainer">
            <MultiDeviceAddressBanner data={bannerInfo} expand={expand} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
                marginLeft: "auto",
                marginRight: "2%",
              }}
            >
              {/* <span
                style={{
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                {labels.DATA_SET?.toUpperCase()}
              </span>
              &nbsp;
              <CustomDropDown
                selectedOption={dataSetName}
                options={dataSetNames}
                searchFlag={false}
                checkboxFlag={false}
                setSelectedOption={(value: any) => {
                  setDataSetName(value);
                  //dispatch(SetTeleDatasetType(value));
                  dispatch(setDiagType(value === storeConst.summary));
                }}
              /> */}
              {/* <div style={{ padding: "0px 20px" }}>
                {mlBasicData !== undefined && mlBasicData?.length !== 0 && (
                  <>
                    {!allBasicDiagIsLoading ? (
                      <button
                        className="refreshCertiBtn"
                        onClick={runTelemetry}
                      >
                        {labels.START?.toUpperCase()} <FaRegPlayCircle />
                      </button>
                    ) : (
                      <>
                        <button
                          className="refreshCertiBtn"
                          onClick={() => stopTelemetry()}
                        >
                          {labels.STOP?.toUpperCase()} <FaRegStopCircle />
                        </button>
                      </>
                    )}
                  </>
                )}
              </div> */}
            </div>

            {/* <div className="gponBannerIconsContainer">
              <div
                className="gponCollapseContainer"
                style={{
                  rotate: expand ? "0deg" : "180deg",
                }}
              >
                <IoIosArrowDropup
                  color="black"
                  size={25}
                  onClick={handleToggle}
                />
              </div>
            </div> */}
          </div>
          <div className="gponBodyContainer" style={{ padding: "4px" }}>
            <MultiDeviceAddressTab
              tableAttr={tableAttributes}
              data={mlAddressData}
              dataSetType={multiDeviceDataSetType}
              dataSetNames={dataSetNames}
              nodeId={currNodedata?.nodeType ? currNodedata?.nodeType : ""}
              streetName={streetName}
              streetNo={streetNo}
              exportDeviceId={
                currNodedata?.nodeId ? currNodedata?.nodeType : ""
              }
            />
          </div>
        </div>
      }
    </div>
  );
};
