import React, { FormEvent, useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import "../../../../design-tokens/file-manager-page/FileManagerPage.scss";
import AppLabels from "../../../../constants/App_Labels";
import CustomIcons from "../../../../components/reusable/CustomIcons";
import FileTypeIcons from "../../../../components/reusable/FileTypeIcons";
import { formatBytesToSize } from "../../../../components/reusable/Util";
import ErrorBox from "../../../../components/reusable/ErrorBox";
import colors from "../../../config/colors";
import TopologyLabels from "../../../constants/topologyLabels";

interface Props {
  title?: string;
  sizeLimit?: string;
  mandatoryTitle?: React.ReactNode;
  onFileChange: any;
  file: any;
  allowedTypes?: string[];
  multiple?: boolean;
  description?: string;
  setDescription?: any;
}

const CustomDropFileInput = (props: Props) => {
  const labels = AppLabels();
  const topologyLabels = TopologyLabels();
  const { multiple = false, sizeLimit = "5 MBs" } = props;
  const [fileList, setFileList] = useState<any[]>(props.file);
  const [fileTypeFlag, setFileTypeFlag] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const allowedTypes = props?.allowedTypes
    ? props?.allowedTypes
    : [
        "pdf",
        "doc",
        "docx",
        "xls",
        "xlsx",
        "jpg",
        "jpeg",
        "png",
        "ppt",
        "pptx",
      ];
  const notAllowedTypeError = `${labels.File_Type_Not_Allowed} ${allowedTypes}`;
  const getFileExtension = (filename: string) => {
    const parts = filename.split(".");
    const extension = parts[parts.length - 1];
    return extension.toLowerCase();
  };

  function isFileTypeAllowed(file: File, allowedTypes: string[]) {
    return allowedTypes.includes(getFileExtension(file.name));
  }

  const onDragEnter = () => wrapperRef.current?.classList?.add("dragover");
  const onDragLeave = () => wrapperRef.current?.classList?.remove("dragover");
  const onDrop = () => wrapperRef.current?.classList?.remove("dragover");

  const onFileDrop = (e: any) => {
    setFileTypeFlag(false);
    let updatedList: any[] = [];
    if (e.target.files) {
      Array.from(e.target.files).forEach((file: any) => {
        if (file && isFileTypeAllowed(file, allowedTypes)) {
          const reader = new FileReader();
          reader.onloadend = () => {
            if (!multiple) {
              updatedList = [
                {
                  file: reader.result,
                  name: file.name,
                  size: file.size,
                  description: props?.description,
                },
              ];
            } else {
              updatedList.push({
                file: reader.result,
                name: file.name,
                size: file.size,
                description: props?.description,
              });
            }
            setFileList(updatedList);
            props.onFileChange(updatedList);
          };
          reader.readAsDataURL(file);
        } else {
          setFileTypeFlag(true);
        }
      });
    }
  };

  const fileRemove = (file: any) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    props.onFileChange(updatedList);
  };
  const onCommentsChange = (e: FormEvent<HTMLInputElement>) => {
    props?.setDescription(e?.currentTarget?.value);
  };
  return (
    <>
      <div
        ref={wrapperRef}
        className="drop-file-input"
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div className="drop-file-input__label">
          <p style={{ fontSize: "14px", color: colors.primary }}>
            {labels.File_Upload_Warning_Msg} {sizeLimit}
          </p>
          <CustomIcons name="upload" size={70} />
          <span>
            <br />
            {labels.Drag_Drop}
          </span>
          <span>
            <br />
            {labels.or}
          </span>
          <span>
            <br />
            {labels.Browse_Files}
          </span>
        </div>
        <input type="file" value="" onChange={onFileDrop} />
      </div>
      {fileList.length > 0 ? (
        <div className="drop-file-preview">
          <p className="drop-file-preview__title">{labels.Ready_to_Upload}</p>

          {fileList.map((item: any, index) => (
            <div key={index}>
              <div className="drop-file-preview__item">
                <FileTypeIcons name={getFileExtension(item.name)} size={60} />
                <div className="drop-file-preview__item__info">
                  <p>{item.name}</p>
                  <p>{formatBytesToSize(item.size)}</p>
                </div>
                <span className="drop-file-preview__item__del">
                  <IoClose
                    color={`var(--pureBlack)`}
                    onClick={() => fileRemove(item)}
                  />
                </span>
              </div>
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <label style={{ fontWeight: 500 }}>
                  {topologyLabels?.comments}:
                </label>
                <input
                  value={props?.description}
                  placeholder="Add Comments Here"
                  onChange={onCommentsChange}
                  style={{
                    borderRadius: 5,
                    borderWidth: 0.3,
                    borderColor: `var(--greyMedium)`,
                    textAlign: "left",
                    fontSize: "16px",
                    width: "510px",
                    marginBottom: "0.5rem",
                    marginLeft: "1em",
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      ) : null}
      <ErrorBox
        open={fileTypeFlag}
        error={notAllowedTypeError}
        handleClose={() => setFileTypeFlag(false)}
      />
    </>
  );
};

export default CustomDropFileInput;
