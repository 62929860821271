import { useEffect, useRef, useState } from "react";
import AccountPageTabs from "./AccountPageTabs";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import AccountBasicInfo from "../../components/account/AccountBasicInfo";
import AccountBasicInfoSM from "../../components/account/AccountBasicInfoSM";
import {
  SetSelectedSA,
  SetSelectedServiceAddress,
} from "../../store/actions/account/accountInfo/ActiveAccountAction";
import {
  hasWXLEorWXBB
} from "../../components/reusable/Util";
import {
  podsSuccess,podsBegin
} from "../../store/actions/account/diagnostics/pods/PodAction";
import { ResetSearchAccState } from "../../store/actions/account/accountInfo/AccountDetailsAction";
import { SetWorkOrderId } from "../../store/ducksPattern/TechProfile";
import "../../design-tokens/account-page/AccountPage.scss";
import PodsClient from "../../network/httpclient/pods/PodsClient";
import TECHClient from "../../network/httpclient/technician/TECHClient";
import SetAccountDevices from "../../components/account/SetAccountDevices";
import SetAllDeviceStatus from "../../components/account/SetALLDeviceStatus";
import { AddAccount } from "../../store/actions/account/AllAccountsAction";
import xleVisiblilityClient from "../../network/httpclient/accountInfo/xleVisiblilityClient";
import {
  isUpdated,
  isAccAvailable,
  isNull,
} from "../../components/reusable/Util";
import { GetTechInfo,GetXLEGWDetails,GetCellularDetails,GetBatteryDetails } from "../../get-api/GetResponse";
import AccountEmailBanner from "../../components/account/AccountEmailBanner";
import React from "react";
import { POD } from "../../store/models/account/diagnostics/pods/POD";
const AccountPageDetails = (props: any) => {
  const {
    location: { search },
    macAddress,
    device,
  } = props;
  const { data: techData, activeWorkOrder: woID } = GetTechInfo();
  const { gwSummarydata,gwLoading,gwDeviceError}= GetXLEGWDetails();
  const {
    data,
    savedSA,
    searchData,
    selectedServiceAddress,
    accState,
    allAccounts,
    podList,
    podError,
    podsLoading,
    flagXLE,sortedDevices
  } = useSelector(
    (state: any) => ({
      allAccounts: state.AllAccounts.accounts,
      data: state.AccountState.ActiveAccountDetails.data,
      searchData: state.AccountState.AccountDetails.data,
      savedSA: state.AccountState.ActiveAccountDetails.selectedSA,
      selectedServiceAddress:
        state.AccountState.ActiveAccountDetails.selectedServiceAddress,
      accState: state.AccountState,
      podList: state.AccountState.PodInfo.data,
      podError: state.AccountState.PodInfo.error,
      podsLoading: state.AccountState.PodInfo.isLoading, flagXLE: state.slices?.xleVisible,
      sortedDevices: state.AccountState.ActiveAccountDetails.sortedDevices,
    }),
    shallowEqual
  );
 
  
  const { cellulardata,isLoading,status,Error}= GetCellularDetails();
  const { batterydata,batteryLoading,batteryStatus,batteryError}= GetBatteryDetails();
  let podW3ID = "";
  const query = new URLSearchParams(search);
  const dispatch = useDispatch();
  const [workOrderID, setWorkOrderID] = useState<string | null>("");
  const [sADdSelectedValue, setSaDdSelectedValue] = useState(
    selectedServiceAddress
  );
  const [expand, setExpand] = useState(true);
  const podRetryCount = useRef<number>(0);

  useEffect(() => {
    if (search && search.includes("workorderId")) {
      setWorkOrderID(query.get("workorderId"));
    }
  }, [query]);
  useEffect(() => {
    if (workOrderID !== "" && workOrderID !== null) {
      dispatch(SetWorkOrderId(workOrderID));
    }
  }, [workOrderID]);
  const handleToggle = () => {
    setExpand((prev) => !prev);
  };
  useEffect(() => {
    setSaDdSelectedValue(selectedServiceAddress);
  }, [selectedServiceAddress]);

  useEffect(() => {
    if (searchData !== undefined) {
      dispatch(ResetSearchAccState());
    }
    if (!isAccAvailable(allAccounts, data?.accountNumber)) {
      dispatch(AddAccount(data?.accountNumber, accState));
    }
    if (techData === undefined) {
      dispatch(TECHClient.getTechniciansProfile());
    }
    if (
      data.brand === "Rogers" &&
      !isUpdated(allAccounts, data?.accountNumber)
    ) {
      refreshPods();
    }
  }, [data]);
  

  useEffect(() => {
    if(flagXLE?.data&&sortedDevices&&hasWXLEorWXBB(sortedDevices)){
 
      dispatch(
        xleVisiblilityClient.getXleGateway(  sortedDevices.filter((d: any) => d.deviceCategory === "CM" && d.macAddress!='').map((d: any) => d.macAddress)     
      ));  
      
    }
  },[flagXLE?.data,sortedDevices,dispatch]);
  useEffect(() => {
    if(gwSummarydata?.[0]?.backup_cpe_mac){
    dispatch(
      xleVisiblilityClient.getXleCellular( gwSummarydata?.[0]?.backup_cpe_mac)     
    );
    dispatch(
      xleVisiblilityClient.getBattery( gwSummarydata?.[0]?.backup_cpe_mac)     
    );
  }
  },[gwSummarydata?.[0]?.backup_cpe_mac]);


  const serviceAddress: any = [];
  const selectedSA: any = [];
  data?.serviceAddress?.map((sa: any) => {
    let address = "";
    if (sa.apartmentNumber === "") {
      address =
        "" +
        sa.streetNumber +
        " " +
        sa.streetName +
        " " +
        sa.streetType +
        ", " +
        sa.city +
        ", " +
        sa.province +
        " " +
        sa.postalCode;
    } else {
      address =
        "" +
        sa.apartmentNumber +
        "-" +
        sa.streetNumber +
        " " +
        sa.streetName +
        " " +
        sa.streetType +
        ", " +
        sa.city +
        ", " +
        sa.province +
        " " +
        sa.postalCode;
    }
    serviceAddress.push(address);
    if (address === selectedServiceAddress) {
      selectedSA.push(sa);
    }
  });
  useEffect(() => {
    if (selectedSA !== undefined && selectedSA?.length !== 0) {
      if (
        savedSA !== undefined &&
        savedSA?.samKey !== selectedSA?.[0]?.samKey
      ) {
        dispatch(SetSelectedSA(selectedSA?.[0]));
      } else if (savedSA === undefined) {
        dispatch(SetSelectedSA(selectedSA?.[0]));
      }
    }
  }, [selectedSA]);

  SetAccountDevices();
  SetAllDeviceStatus();
  if (
    selectedSA?.[0]?.hasOwnProperty("subscription") &&
    selectedSA?.[0]?.subscription !== ""
  ) {
    selectedSA?.[0]?.subscription?.map((sub: any) => {
      if (
        sub?.hasOwnProperty("planInfoList") &&
        sub?.planInfoList?.hasOwnProperty("planInfo")
      ) {
        sub?.planInfoList?.planInfo?.map((pInfo: any) => {
          if (pInfo.productName === "HSI") podW3ID = sub.w3Id;
        });
      }
    });
  }

  const handleAddressSelect = (e: any) => {
    setSaDdSelectedValue(e);
    dispatch(SetSelectedServiceAddress(e.toString()));
  };

  const refreshPods = () => {
    if (podW3ID !== "" && podW3ID !== undefined) {
      dispatch(PodsClient.getPods(podW3ID));
    }
    // xleDispatch();
  };

  useEffect(() => {
    if(cellulardata?.[0] &&!podsLoading &&!isLoading)
  { 
    dispatch(podsBegin( ));
    const api2DataMap:POD[] =[];
    let updatedPodList;
    const newItem: POD = {
      deviceId: gwSummarydata?.[0]?.backup_cpe_mac ,
      deviceFriendlyName: "XLE",
      connectionState: cellulardata?.[0]?.connection_status,
      statistics: {
        rssi: cellulardata?.[0]?.rssi?.toString(),
      },
      serialNumber:batterydata?.[0]?.serial_number,
      cellulardata: cellulardata?.[0]
    };
    api2DataMap.push(newItem);

    if(podList !=undefined){
     
    // Remove any existing XLE  with the same deviceId for refresh
        updatedPodList = podList?.filter(
          (item:any) => item.deviceFriendlyName !== "XLE"
        );
     
      api2DataMap.push(...updatedPodList);
    
    }
    dispatch(podsSuccess(api2DataMap ));
  }

    }, [cellulardata?.[0]?.connection_status,podsLoading, isLoading]); 
  // useEffect(() => {
  //   if (
  //     data.brand === "Rogers" &&
  //     !isUpdated(allAccounts, data?.accountNumber)
  //   ) {
  //     if (podW3ID !== "" && podW3ID !== undefined) {
  //       const statisticsData = podList?.[0]?.hasOwnProperty("statistics")
  //         ? podList?.[0]?.hasOwnProperty("statistics")
  //         : false;
  //       if (
  //         !podsLoading &&
  //         statisticsData === false &&
  //         podRetryCount.current < 3
  //       ) {
  //         if (podRetryCount.current === 0) {
  //           getPodListData();
  //           podRetryCount.current++;
  //         } else {
  //           const podCall = setInterval(() => {
  //             getPodListData();
  //             podRetryCount.current++;
  //           }, 10000);

  //           return () => {
  //             clearInterval(podCall);
  //           };
  //         }
  //       }
  //     }
  //   }
  // });

  return (
    <>
      {expand ? (
        <div className="custCountainer">
          <AccountBasicInfo
            data={data}
            sADdSelectedValue={sADdSelectedValue}
            handleAddressSelect={handleAddressSelect}
            serviceAddress={serviceAddress}
            selectedSA={savedSA}
            handleToggle={handleToggle}
          />
        </div>
      ) : (
        <div className="custCountainerSm">
          <AccountBasicInfoSM
            data={data}
            sADdSelectedValue={sADdSelectedValue}
            handleAddressSelect={handleAddressSelect}
            serviceAddress={serviceAddress}
            selectedSA={savedSA}
            handleToggle={handleToggle}
          />
        </div>
      )}
      <AccountPageTabs
        serviceAddress={serviceAddress}
        macAddress={macAddress}
        device={device}
        handleAddressSelect={handleAddressSelect}
        selectedSA={selectedSA}
        data={data}
        refreshPods={refreshPods}
        isPodRequired={podW3ID}
      />
      <AccountEmailBanner />
    </>
  );
};
export default AccountPageDetails;
