import { InfoWindow } from "@react-google-maps/api";

import TopologyLabels from "../../../../constants/topologyLabels";
import { formatCamelCase } from "../../../../util/utilFuncs";
import { TCTypes, TDTypes } from "../../../reusable/table/Table";

interface Props {
  activeClusterData?: any;
  showCluster?: any;
  setShowCluster?: any;
  onMarkerPress?: any;
}

const ActiveNikaClusterTable = ({
  activeClusterData,
  showCluster,
  setShowCluster,
  onMarkerPress,
}: Props) => {
  const labels = TopologyLabels();
  const typeDataList = activeClusterData?.nikaTypeData;

  const tableData = [] as TDTypes[];

  typeDataList?.map((e: any) => {
    const propData = e?.properties?.data;
    tableData.push({
      program: (
        <a className="idLink" onClick={() => onMarkerPress(propData)}>
          {formatCamelCase(
            propData?.projectType ? propData?.projectType : e?.properties?.type
          )}
        </a>
      ),
      projectType: propData?.projectType,
      id: propData?.id,
      repairType: propData?.repairType,
    } as TDTypes);
  });

  const tableColumns = [
    { th: labels.Program, thStyle: { minWidth: 110 }, tdName: "program" },
    {
      th: labels.ProjectType,
      thStyle: { minWidth: 100 },
      tdName: "projectType",
    },
    { th: labels.ID, thStyle: { minWidth: 80 }, tdName: "id" },
    { th: labels.RepairType, thStyle: { minWidth: 80 }, tdName: "repairType" },
  ] as TCTypes[];

  return (
    <>
      {showCluster && typeDataList && typeDataList?.length > 0 && (
        <InfoWindow
          position={{
            lat: activeClusterData?.lat,
            lng: activeClusterData?.lng,
          }}
          onCloseClick={() => setShowCluster(false)}
          options={{
            zIndex: 1000,
            pixelOffset: new google.maps.Size(0, -12),
          }}
        >
          <div style={{ marginTop: 10 }}>
            <table className="dataTableDetails">
              <thead>
                <tr>
                  {tableColumns?.map((column, i: number) => (
                    <th key={i} style={column.thStyle}>
                      {column.th}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData?.map((TD: any) => (
                  <tr key={TD.id}>
                    {tableColumns?.map((column, i: number) => (
                      <td key={i} style={column.tdStyle}>
                        {TD?.[column?.tdName]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </InfoWindow>
      )}
    </>
  );
};

export default ActiveNikaClusterTable;
