import { Divider } from "@material-ui/core";
import AppLabels from "../../../constants/App_Labels";
import { getEmailFormatDate } from "../../reusable/Util";

interface Props {
  woID: string;
  techId: any;
  techName: string;
  seniorReferralDetails: any;
  address: any;
  accountNumber: any;
  orderType: any;
}

const PrepareSREmailFormat = (props: Props) => {
  const labels = AppLabels();

  return (
    <div
      style={{
        display: "inline-block",
        fontSize: "14pt",
        fontWeight: 500,
        fontFamily: "Ted Next",
      }}
    >
      <div style={{ justifyContent: "center", display: "flex" }}>
        <span style={{ fontSize: "25pt", textAlign: "center" }}>
          {"Senior Referral Request"}
        </span>
      </div>

      <Divider style={{ margin: "0px 0px 30px 0px" }} />
      <p>
        {props.techName +
          " has recently submitted a scheduled request for a Senior Technician"}
      </p>

      <u>Customer Info:</u>
      <div
        style={{
          marginBottom: "30px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {"Customer Name"}:
        </span>
        <span>{props.seniorReferralDetails?.customerName}</span>
        <br />

        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {"Customer Contact"}:
        </span>
        <span>{props.seniorReferralDetails?.contactNumber}</span>
        <br />

        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {"Addresss"}:
        </span>
        <span>
          {props?.address.apartmentNumber !== "" ? (
            props?.address.apartmentNumber + "-"
          ) : (
            <></>
          )}
          {props?.address.streetNumber} {props?.address.streetName}{" "}
          {props?.address.streetType},
        </span>
        <span>
          {props?.address.city}, {props?.address.province}
        </span>
        <span>{props?.address.postalCode}</span>
        <br />

        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {labels.ACCOUNT_NUMBER}:
        </span>
        <span>{props?.accountNumber}</span>
      </div>

      <u>Referral Info:</u>
      <div
        style={{
          marginBottom: "30px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {"Referral Order Number"}:
        </span>
        <span>{props.woID}</span>
        <br />

        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {"Referral Date"}:
        </span>
        <span>{getEmailFormatDate()}</span>
        <br />

        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {"Order Type"}:
        </span>
        <span>{props?.orderType?.orderType}</span>
        <br />

        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {"Impacted Services"}:
        </span>
        {/* <span>{props?.seniorReferralDetails?.impactedServices}</span> */}
        <span>
          <br />
          {props?.seniorReferralDetails?.impactedServices?.map(
            (service: any, i: number) => (
              <li key={i}>{service}</li>
            )
          )}
        </span>
        <br />

        {props?.seniorReferralDetails?.seniorOrderDetails != "" && (
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
            }}
          >
            <span
              style={{
                fontWeight: 700,
                marginRight: "10px",
              }}
            >
              {"Notes to Senior"}:
            </span>
            <span style={{ wordBreak: "break-word" }}>
              {props?.seniorReferralDetails?.seniorOrderDetails}
            </span>
          </div>
        )}

        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {"Return Date"}:
        </span>
        <span>{props?.seniorReferralDetails?.date.toString()}</span>
        <br />

        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {"Return Time Window"}:
        </span>
        <span>{props?.seniorReferralDetails?.timeWindow}</span>
        <br />
      </div>

      <u>Tap Details:</u>
      <div
        style={{
          marginBottom: "30px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {"TAP"}:
        </span>
        <span>{props.seniorReferralDetails?.tapDetails}</span>
        <br />

        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {"CSE"}:
        </span>
        <span>{props.seniorReferralDetails?.cseDetails}</span>
        <br />

        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {"HUM"}:
        </span>
        <span>{props.seniorReferralDetails?.humDetails}</span>
        <br />

        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {"SNR"}:
        </span>
        <span>{props.seniorReferralDetails?.snrDetails}</span>
        <br />
      </div>
    </div>
  );
};

export default PrepareSREmailFormat;
