import { FlapHistory } from "../../../models/node/telemetry/FlapHistory";

export const FLAP_HISTORY_BEGIN = "FLAP_HISTORY_BEGIN";
export const FLAP_HISTORY_SUCCESS = "FLAP_HISTORY_SUCCESS";
export const FLAP_HISTORY_FAILURE = "FLAP_HISTORY_FAILURE";

interface FlapHistoryBegin {
  type: typeof FLAP_HISTORY_BEGIN;
}
interface FlapHistorySuccess {
  type: typeof FLAP_HISTORY_SUCCESS;
  payload: FlapHistory;
}
interface FlapHistoryFailure {
  type: typeof FLAP_HISTORY_FAILURE;
  payload: Error;
}


export type FlapHistoryActionTypes =
  | FlapHistoryBegin
  | FlapHistorySuccess
  | FlapHistoryFailure;
