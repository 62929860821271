import AppLabels from "../../../../../constants/App_Labels";

interface Props {
  hazardsSituations: any;
  setHazardsSituations: any;
  workingfromHeight: any;
  setWorkingfromHeight: any;
  fallProtectionSystem: any;
  setFallProtectionSystem: any;
  anchorage: any;
  setAnchorage: any;
  liveElectricity: any;
  setLiveElectricity: any;
  electricityControls: any;
  setElectricityControls: any;
  confinedSpace: any;
  setConfinedSpace: any;
  confinedSpaceControls: any;
  setConfinedSpaceControls: any;
}

export default function HazardsView(props: Props) {
  const labels = AppLabels();

  const onHazardSituationsStateChange = (checked: boolean, index: number) => {
    props.hazardsSituations[index].value = checked;
    props.setHazardsSituations([...props.hazardsSituations]);
  };

  const onHeightsStateChange = (checked: boolean, index: number) => {
    props.workingfromHeight[index].value = checked;
    props.setWorkingfromHeight([...props.workingfromHeight]);
  };

  const onFallProtectionSystemStateChange = (
    checked: boolean,
    index: number
  ) => {
    props.fallProtectionSystem[index].value = checked;
    props.setFallProtectionSystem([...props.fallProtectionSystem]);
  };

  const onAnchorageStateChange = (checked: boolean, index: number) => {
    props.anchorage[index].value = checked;
    props.setAnchorage([...props.anchorage]);
  };

  const onLiveElectricityStateChange = (checked: boolean, index: number) => {
    props.liveElectricity[index].value = checked;
    props.setLiveElectricity([...props.liveElectricity]);
  };

  const onLiveElectricityControlsStateChange = (
    checked: boolean,
    index: number
  ) => {
    props.electricityControls[index].value = checked;
    props.setElectricityControls([...props.electricityControls]);
  };

  const onConfinedSpaceStateChange = (checked: boolean, index: number) => {
    props.confinedSpace[index].value = checked;
    props.setConfinedSpace([...props.confinedSpace]);
  };

  const onConfinedSpaceControlsStateChange = (
    checked: boolean,
    index: number
  ) => {
    props.confinedSpaceControls[index].value = checked;
    props.setConfinedSpaceControls([...props.confinedSpaceControls]);
  };

  return (
    <div>
      {props.hazardsSituations?.map((e: any, i: number) => (
        <div key={i}>
          <div key={i} className="formCheckboxContainer">
            <label>{e.label}</label>
            <input
              type="checkbox"
              checked={e.value}
              style={{ width: "25px", height: "25px" }}
              onChange={(event) =>
                onHazardSituationsStateChange(event.target.checked, i)
              }
            />
          </div>
          <div style={{ marginLeft: 15 }}>
            {i === 0 && e.value ? (
              <>
                <p className="formHeadingTitle">{labels.WorkingFromHeights}</p>
                {props.workingfromHeight?.map((e: any, i: number) => (
                  <div key={i} className="formCheckboxContainer">
                    <label>{e.label}</label>
                    <input
                      type="checkbox"
                      checked={e.value}
                      style={{ width: "25px", height: "25px" }}
                      onChange={(event) =>
                        onHeightsStateChange(event.target.checked, i)
                      }
                    />
                  </div>
                ))}
                <p className="formHeadingTitle">
                  {labels.Fall_Protection_Plan}
                </p>
                {props.fallProtectionSystem?.map((e: any, i: number) => (
                  <div key={i} className="formCheckboxContainer">
                    <label>{e.label}</label>
                    <input
                      type="checkbox"
                      checked={e.value}
                      style={{ width: "25px", height: "25px" }}
                      onChange={(event) =>
                        onFallProtectionSystemStateChange(
                          event.target.checked,
                          i
                        )
                      }
                    />
                  </div>
                ))}
                {props.fallProtectionSystem?.[0]?.value ||
                props.fallProtectionSystem?.[1]?.value ? (
                  <>
                    <p className="formHeadingTitle">{labels.Anchorage_Point}</p>
                    {props.anchorage?.map((e: any, i: number) => (
                      <div key={i} className="formCheckboxContainer">
                        <label>{e.label}</label>
                        <input
                          type="checkbox"
                          checked={e.value}
                          style={{ width: "25px", height: "25px" }}
                          onChange={(event) =>
                            onAnchorageStateChange(event.target.checked, i)
                          }
                        />
                      </div>
                    ))}
                  </>
                ) : null}
              </>
            ) : i === 1 && e.value ? (
              <>
                <p className="formHeadingTitle">{labels.LiveElectricity}</p>
                {props.liveElectricity?.map((e: any, i: number) => (
                  <div key={i} className="formCheckboxContainer">
                    <label>{e.label}</label>
                    <input
                      type="checkbox"
                      checked={e.value}
                      style={{ width: "25px", height: "25px" }}
                      onChange={(event) =>
                        onLiveElectricityStateChange(event.target.checked, i)
                      }
                    />
                  </div>
                ))}
                <p className="formHeadingTitle">{labels.Controls}</p>
                {props.electricityControls?.map((e: any, i: number) => (
                  <div key={i} className="formCheckboxContainer">
                    <label>{e.label}</label>
                    <input
                      type="checkbox"
                      checked={e.value}
                      style={{ width: "25px", height: "25px" }}
                      onChange={(event) =>
                        onLiveElectricityControlsStateChange(
                          event.target.checked,
                          i
                        )
                      }
                    />
                  </div>
                ))}
              </>
            ) : i === 2 && e.value ? (
              <>
                <p className="formHeadingTitle">{labels.ConfinedSpace}</p>
                {props.confinedSpace?.map((e: any, i: number) => (
                  <div key={i} className="formCheckboxContainer">
                    <label>{e.label}</label>
                    <input
                      type="checkbox"
                      checked={e.value}
                      style={{ width: "25px", height: "25px" }}
                      onChange={(event) =>
                        onConfinedSpaceStateChange(event.target.checked, i)
                      }
                    />
                  </div>
                ))}
                <p className="formHeadingTitle">{labels.Controls}</p>
                {props.confinedSpaceControls?.map((e: any, i: number) => (
                  <div key={i} className="formCheckboxContainer">
                    <label>{e.label}</label>
                    <input
                      type="checkbox"
                      checked={e.value}
                      style={{ width: "25px", height: "25px" }}
                      onChange={(event) =>
                        onConfinedSpaceControlsStateChange(
                          event.target.checked,
                          i
                        )
                      }
                    />
                  </div>
                ))}
              </>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
}
