import TopologyLabels from "../../../constants/topologyLabels";
import CustomTabs from "../../reusable/CustomTabs";
import ClickLegends from "./ClickLegends";
import ClickAllOrdersTable from "./ClickAllOrdersTable";
import ClickOrdersFilters from "./filter/ClickOrdersFilters";

export default function ClickOrdersScreen() {
  const labels = TopologyLabels();

  const nodeTabs = [
    {
      eventKey: 0,
      title: labels.Filters.toUpperCase(),
      compToRend: (
        <>
          <ClickLegends />
          <ClickOrdersFilters />
        </>
      ),
    },
    {
      eventKey: 1,
      title: labels.List.toUpperCase(),
      compToRend: <ClickAllOrdersTable />,
    },
  ];

  return (
    <CustomTabs
      defaultKey={0}
      tabs={nodeTabs}
      childrenStyle={{ marginTop: 10 }}
    />
  );
}
