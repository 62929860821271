interface Props {
  mlData: any;
}
const initialParams = {
  active: 0,
  normal: 0,
  warning: 0,
  critical: 0,
};
const percentParams = {
  normalPerc: 0.0,
  warningPerc: 0.0,
  criticalPerc: 0.0,
};
const odmPercentParams = {
  normalPercent: 0.0,
  warningPercent: 0.0,
  criticalPercent: 0.0,
};
const fieldsCount = {
  certTxStatus: initialParams,
  certUSSnrStatus: initialParams,
  certRxStatus: initialParams,
  certDSSnrStatus: initialParams,
  certCMTSRxStatus: initialParams,
  certUSPreCerStatus: initialParams,
  certUSPostCCerStatus: initialParams,
};
const percentFields = {
  certTxStatus: percentParams,
  certUSSnrStatus: percentParams,
  certRxStatus: percentParams,
  certDSSnrStatus: percentParams,
  certCMTSRxStatus: percentParams,
  certUSPreCerStatus: percentParams,
  certUSPostCCerStatus: percentParams,
};
const odmFields = {
  odm: odmPercentParams,
  odmUS: odmPercentParams,
  odmDS: odmPercentParams,
};

const docsisUSFields = ["certTxStatus", "certUSSnrStatus", "certCMTSRxStatus"];
const docsisDSFields = ["certRxStatus", "certDSSnrStatus"];
const codewordsFields = ["certUSPreCerStatus", "certUSPostCCerStatus"];
const fields = [...docsisUSFields, ...docsisDSFields, ...codewordsFields];

const NodeScoreCount = (mlData: any, fieldsCount: any) => {
  mlData?.map((e: any) => {
    const docsis = e.deviceDiag?.data?.docsis;
    const codewords = e.deviceDiag?.data?.codewords;
    const docsisUSObj = docsis?.docsis30?.upstream;
    const docsisDSObj = docsis?.docsis30?.downstream;
    fieldsCount = prepareJson(docsisUSFields, docsisUSObj, fieldsCount);
    fieldsCount = prepareJson(docsisDSFields, docsisDSObj, fieldsCount);
    fieldsCount = prepareJson(codewordsFields, codewords, fieldsCount);
  });
  return fieldsCount;
};

function NodePercent(nodeCount: any, percentFields: any) {
  fields?.map((field: string) => {
    percentFields[field as keyof typeof percentFields].normalPerc =
      nodeCount[field].active === 0
        ? 0
        : (nodeCount[field].normal / nodeCount[field].active) * 100;
    percentFields[field as keyof typeof percentFields].warningPerc =
      nodeCount[field].active === 0
        ? 0
        : (nodeCount[field].warning / nodeCount[field].active) * 100;
    percentFields[field as keyof typeof percentFields].criticalPerc =
      nodeCount[field].active === 0
        ? 0
        : (nodeCount[field].critical / nodeCount[field].active) * 100;
  });
  return percentFields;
}

function NodeODMCalculation(nodePercentage: any, odmFields: any) {
  odmFields = calculateODM(nodePercentage, odmFields);
  odmFields = calculateUSODM(nodePercentage, odmFields);
  odmFields = calculateDSODM(nodePercentage, odmFields);
  return odmFields;
}

export const NodeScoreCalculation = (props: Props) => {
  const { mlData } = props;
  const percentFields1 = JSON.parse(JSON.stringify(percentFields)); //Freezing object here
  const fieldsCount1 = JSON.parse(JSON.stringify(fieldsCount));
  const odmFields1 = JSON.parse(JSON.stringify(odmFields));
  const nodeCount = NodeScoreCount(mlData, fieldsCount1);
  const nodePercentage = NodePercent(nodeCount, percentFields1);
  const nodeOfdm = NodeODMCalculation(nodePercentage, odmFields1);
  return nodeOfdm;
};

const prepareJson = (fields: any, dataObj: any, fieldsCount: any) => {
  if (dataObj == undefined) return fieldsCount;
  else {
    fields?.map((field: any, index: number) => {
      let tempField = fieldsCount[field];
      switch (dataObj?.[field]) {
        case "NORMAL":
          tempField = {
            ...tempField,
            ...{ active: tempField.active + 1, normal: tempField.normal + 1 },
          };
          break;
        case "WARNING":
          tempField = {
            ...tempField,
            ...{ active: tempField.active + 1, warning: tempField.warning + 1 },
          };
          break;
        case "CRITICAL":
          tempField = {
            ...tempField,
            ...{
              active: tempField.active + 1,
              critical: tempField.critical + 1,
            },
          };
          break;
        case "UNKNOWN":
          break;
      }
      fieldsCount[field] = tempField;
    });
    return fieldsCount;
  }
};
const calculateODM = (nodePercentage: any, odmFields: any) => {
  odmFields["odm"]["normalPercent"] =
    (Math.pow(nodePercentage["certUSPostCCerStatus"]["normalPerc"] / 100, 2) +
      Math.pow(nodePercentage["certUSPreCerStatus"]["normalPerc"] / 100, 2) +
      Math.pow(nodePercentage["certUSSnrStatus"]["normalPerc"] / 100, 2) +
      nodePercentage["certTxStatus"]["normalPerc"] / 100 +
      nodePercentage["certRxStatus"]["normalPerc"] / 100 +
      nodePercentage["certDSSnrStatus"]["normalPerc"] / 100 +
      nodePercentage["certCMTSRxStatus"]["normalPerc"] / 100) /
    7;
  odmFields["odm"]["normalPercent"] = getNumberorFloat(
    odmFields["odm"]["normalPercent"] * 100
  );
  odmFields["odm"]["warningPercent"] =
    (nodePercentage["certUSSnrStatus"]["warningPerc"] / 100 +
      nodePercentage["certTxStatus"]["warningPerc"] / 100 +
      nodePercentage["certRxStatus"]["warningPerc"] / 100 +
      nodePercentage["certDSSnrStatus"]["warningPerc"] / 100) /
    4;
  odmFields["odm"]["warningPercent"] = getNumberorFloat(
    odmFields["odm"]["warningPercent"] * 100
  );

  odmFields["odm"]["criticalPercent"] =
    (nodePercentage["certUSPreCerStatus"]["criticalPerc"] / 100 +
      nodePercentage["certUSPostCCerStatus"]["criticalPerc"] / 100 +
      nodePercentage["certUSSnrStatus"]["criticalPerc"] / 100 +
      nodePercentage["certTxStatus"]["criticalPerc"] / 100 +
      nodePercentage["certRxStatus"]["criticalPerc"] / 100 +
      nodePercentage["certDSSnrStatus"]["criticalPerc"] / 100 +
      nodePercentage["certCMTSRxStatus"]["criticalPerc"] / 100) /
    7;
  odmFields["odm"]["criticalPercent"] = getNumberorFloat(
    odmFields["odm"]["criticalPercent"] * 100
  );

  return odmFields;
};

const calculateUSODM = (nodePercentage: any, odmFields: any) => {
  odmFields["odmUS"]["normalPercent"] =
    (Math.pow(nodePercentage["certUSPreCerStatus"]["normalPerc"] / 100, 2) +
      Math.pow(nodePercentage["certUSPostCCerStatus"]["normalPerc"] / 100, 2) +
      Math.pow(nodePercentage["certUSSnrStatus"]["normalPerc"] / 100, 2) +
      nodePercentage["certTxStatus"]["normalPerc"] / 100 +
      nodePercentage["certCMTSRxStatus"]["normalPerc"] / 100) /
    5;
  odmFields["odmUS"]["normalPercent"] = getNumberorFloat(
    odmFields["odmUS"]["normalPercent"] * 100
  );

  odmFields["odmUS"]["warningPercent"] =
    (nodePercentage["certUSSnrStatus"]["warningPerc"] / 100 +
      nodePercentage["certTxStatus"]["warningPerc"] / 100) /
    2;
  odmFields["odmUS"]["warningPercent"] = getNumberorFloat(
    odmFields["odmUS"]["warningPercent"] * 100
  );

  odmFields["odmUS"]["criticalPercent"] =
    (nodePercentage["certUSPreCerStatus"]["criticalPerc"] / 100 +
      nodePercentage["certUSPostCCerStatus"]["criticalPerc"] / 100 +
      nodePercentage["certUSSnrStatus"]["criticalPerc"] / 100 +
      nodePercentage["certTxStatus"]["criticalPerc"] / 100 +
      nodePercentage["certCMTSRxStatus"]["criticalPerc"] / 100,
    2) / 5;
  odmFields["odmUS"]["criticalPercent"] = getNumberorFloat(
    odmFields["odmUS"]["criticalPercent"] * 100
  );

  return odmFields;
};
const calculateDSODM = (nodePercentage: any, odmFields: any) => {
  odmFields["odmDS"]["normalPercent"] =
    (nodePercentage["certRxStatus"]["normalPerc"] / 100 +
      nodePercentage["certDSSnrStatus"]["normalPerc"] / 100) /
    2;
  odmFields["odmDS"]["normalPercent"] = getNumberorFloat(
    odmFields["odmDS"]["normalPercent"] * 100
  );
  odmFields["odmDS"]["warningPercent"] =
    (nodePercentage["certRxStatus"]["warningPerc"] / 100 +
      nodePercentage["certDSSnrStatus"]["warningPerc"] / 100) /
    2;
  odmFields["odmDS"]["warningPercent"] = getNumberorFloat(
    odmFields["odmDS"]["warningPercent"] * 100
  );

  odmFields["odmDS"]["criticalPercent"] =
    (nodePercentage["certRxStatus"]["criticalPerc"] / 100 +
      nodePercentage["certDSSnrStatus"]["criticalPerc"] / 100) /
    2;
  odmFields["odmDS"]["criticalPercent"] = getNumberorFloat(
    odmFields["odmDS"]["criticalPercent"] * 100
  );

  return odmFields;
};
const getNumberorFloat = (number: any) => {
  return Number.isInteger(number) ? number : number.toFixed(1);
};
