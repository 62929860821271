import { useEffect } from "react";
import { useDispatch } from "react-redux";
import DiagContainer from "../../reusable/DiagContainer";
import AppLabels from "../../../constants/App_Labels";
import ExceptionBox from "../../reusable/ExceptionBox";
import { changeTimeFormat, getHealthColors } from "../../reusable/Util";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { getNodeCertificationHistory } from "../../../store/slices/nodecertifications/nodeCertificationsHist";
import {
  GetNodeCertificationHist,
  GetNodeInfoDetails,
} from "../../../get-api/GetResponse";
import CustomAccordion from "../../reusable/CustomAccordion";
import { MdRefresh } from "react-icons/md";

const NodeCertificationHistory = (props: any) => {
  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "#000000",
      boxShadow: theme.shadows[4],
      fontSize: 12,
    },
  }))(Tooltip);
  const labels = AppLabels();
  const dispatch = useDispatch();
  const { currNodedata } = GetNodeInfoDetails();
  const { nodeCertHistData, nodeCertHistIsLoading, nodeCertHistError } =
    GetNodeCertificationHist();

  useEffect(() => {
    if (nodeCertHistData === undefined)
      dispatch(getNodeCertificationHistory(currNodedata?.nodeId));
  }, []);

  const refreshCertHistory = () => {
    dispatch(getNodeCertificationHistory(currNodedata?.nodeId));
  };

  return (
    <>
      <CustomAccordion
        accTitle={labels.Certification_History.toUpperCase()}
        accBodyElement={
          <>
            <DiagContainer
              isLoading={nodeCertHistIsLoading}
              data={nodeCertHistData}
              error={nodeCertHistError}
              sectionName={labels.Certification_History}
              handleRefresh={() => refreshCertHistory}
            >
              <button
                className="refreshCertiBtn"
                onClick={() => refreshCertHistory()}
              >
                Refresh <MdRefresh />
              </button>
              {nodeCertHistData !== undefined &&
              nodeCertHistData?.length === 0 ? (
                <div className="nodeCertdiagError">
                  <ExceptionBox
                    headerText="No Data to display"
                    bodyText="Certifications related to Node are reflected here."
                    primaryBtn={false}
                    secBtn={false}
                  />
                </div>
              ) : (
                <table className="nodeCertTable">
                  <tr>
                    <th className="nodeCertBorder">
                      {labels.Certified + " " + labels.By}
                    </th>
                    <th className="nodeCertBorder">{labels.Time_and_Date}</th>
                    <th>
                      {labels.Overall},{labels.US},{labels.DS}
                    </th>
                  </tr>
                  <tbody>
                    {nodeCertHistData !== undefined ? (
                      nodeCertHistData?.map((history: any, i: number) => {
                        return (
                          <tr key={i}>
                            {history ? (
                              <>
                                <td
                                  className="nodeCertTdSC recoTestTdFC emailId"
                                  onClick={() => {
                                    let newWindow = window.open(
                                      `#/certification-history`
                                    );
                                    newWindow?.addEventListener("load", () => {
                                      newWindow?.postMessage(
                                        { nodeCertHistData, history, index: i },
                                        newWindow.origin
                                      );
                                    });
                                  }}
                                >
                                  <LightTooltip
                                    className="legendToolTip"
                                    title={history?.certifiedBy}
                                  >
                                    <span>{history?.certifiedBy}</span>
                                  </LightTooltip>
                                </td>
                                <td className="nodeCertTdSC nodeCertBorder">
                                  {changeTimeFormat(
                                    Number(history?.certCreatedTime)
                                  )}
                                </td>
                                <td className="nodeCertTdSC">
                                  <div className="nodeScoreChipsWrapper">
                                    {getHealthColors([
                                      history?.overallScore,
                                      history?.overallUSScore,
                                      history?.overallDSScore,
                                    ])}
                                  </div>
                                </td>
                              </>
                            ) : (
                              <>No data</>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              )}
            </DiagContainer>
          </>
        }
      />
    </>
  );
};
export default NodeCertificationHistory;
