import { Marker } from "@react-google-maps/api";
import { CPAT_CONFIG, mapElementStyles } from "../../../config/mapItemsConfig";
import { GetCpatEventsProps } from "../../../util/reduxFunctions/getTopologyState";
import "../../../styles/drawer/cpat/Cpat.scss";
import {
  getCommonLeakageLevel,
  getIngressLevel,
} from "../../../util/utilFuncs";
import colors from "../../../config/colors";
import TopologySymbols from "../../reusable/TopologySymbols";
import { renderToString } from "react-dom/server";
import appConst from "../../../constants/appConst";

interface Props {
  handleCpatMarkerClick?: any;
}

export default function CpatMarkers({ handleCpatMarkerClick }: Props) {
  const { data, isEventsVisible } = GetCpatEventsProps();
  let symbolColor = colors.greyMedium as string;
  let level: any = -1;
  function getShape(midLeak: any, lteLeak: any, ingress: any) {
    if (
      ((midLeak >= CPAT_CONFIG.minMidLeakage &&
        midLeak <= CPAT_CONFIG.maxMidLeakage) ||
        (lteLeak >= CPAT_CONFIG.minLteLeakage &&
          lteLeak <= CPAT_CONFIG.maxLteLeakge)) &&
      ingress >= CPAT_CONFIG.minIngress &&
      ingress <= CPAT_CONFIG.maxIngress
    ) {
      const leakLevel = getCommonLeakageLevel(midLeak, lteLeak);
      const ingressLevel: any = getIngressLevel(ingress);
      level = leakLevel;
      if (leakLevel < ingressLevel) {
        level = ingressLevel;
      }
      symbolColor = colors.greyMedium;
    } else if (
      (midLeak >= CPAT_CONFIG.minMidLeakage &&
        midLeak <= CPAT_CONFIG.maxMidLeakage) ||
      (lteLeak >= CPAT_CONFIG.minLteLeakage &&
        lteLeak <= CPAT_CONFIG.maxLteLeakge)
    ) {
      level = getCommonLeakageLevel(midLeak, lteLeak);
      symbolColor = colors.green;
    } else if (
      ingress >= CPAT_CONFIG.minIngress &&
      ingress <= CPAT_CONFIG.maxIngress
    ) {
      level = getIngressLevel(ingress);
      symbolColor = colors.skyBlue;
    }
  }

  return (
    <>
      {data &&
        data?.features?.map((cpatData: any) => {
          const cpatIconConfig = getShape(
            cpatData?.properties?.data?.leakMid,
            cpatData?.properties?.data?.leakLTE,
            cpatData?.properties?.data?.ingress
          );
          const cmMarker = renderToString(
            <TopologySymbols
              name={mapElementStyles?.cpatEvent?.iconName}
              color={symbolColor}
              size={mapElementStyles?.cpatEvent?.iconSize?.[level]}
              opacity={mapElementStyles?.cpatEvent?.opacity}
            />
          );
          if (isEventsVisible) {
            return (
              <Marker
                key={cpatData?.featureId}
                position={{
                  lat: cpatData?.geometry?.coordinates?.[1],
                  lng: cpatData?.geometry?.coordinates?.[0],
                }}
                icon={{
                  url:
                    "data:image/svg+xml;charset=utf-8," +
                    encodeURIComponent(cmMarker),
                  anchor: new google.maps.Point(
                    mapElementStyles?.cpatEvent?.anchor?.x,
                    mapElementStyles?.cpatEvent?.anchor?.y
                  ),
                }}
                draggable
                onClick={() => {
                  handleCpatMarkerClick(cpatData, appConst.cpat);
                }}
              />
            );
          }
        })}
    </>
  );
}
