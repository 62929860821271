import { useTranslation } from "react-i18next";

export default function TopologyLabels() {
  const { t, i18n } = useTranslation();
  return Object.freeze({
    Google_map_key_is_not_available: t("Google map key is not available."),
    searchPH: t("Search Node"),
    Boundaries: t("boundaries"),
    fiberCable: t("fiberCable"),
    spliceCase: t("spliceCase"),
    tap: t("tap"),
    splitter: t("splitter"),
    powerBlock: t("powerBlock"),
    rfCable: t("rfCable"),
    terminator: t("terminator"),
    spliceCases: t("spliceCases"),
    taps: t("taps"),
    splitters: t("splitters"),
    powerBlocks: t("powerBlocks"),
    rfCables: t("rfCables"),
    terminators: t("terminators"),
    powerSupply: t("powerSupply"),
    cableDrops: t("cableDrops"),
    addressNum: t("addressNo"),
    mdu: t("mdu"),
    supportStructure: t("supportStructure"),
    detailMark: t("detailMark"),
    cableDip: t("cableDip"),
    pole: t("pole"),
    poleStrand: t("poleStrand"),
    rfSplice: t("rfSplice"),
    noSelectedEle: t("No Element Selected"),
    selectEleMSG: t("Please select an element on the map"),
    activeItem: t("Active Map Item"),
    port: t("port"),
    street: t("street"),
    streetType: t("streetType"),
    streetName: t("streetName"),
    city: t("city"),
    province: t("province"),
    activeEquipment: t("activeEquipment"),
    NM: t("NM"), //NetworkMaintenanceRepair
    CP: t("CP"), //ConstructionProjects
    BD: t("BD"), //BuriedDrop
    Fbr: t("Fbr"), //fiber
    LR: t("LR"), //LeakageRepairs
    MDU: t("MDU"),
    PS: t("PS"), //PowerSupply
    CS: t("CS"), //CustomerService
    EE: t("EE"), //Everything else
    nodeIDError: t("nodeIDError"),
    RadiusMustBeBetween: t("RadiusMustBeBetween"),
    meters: t("meters"),
    YouMustSelectAtLeastOneType: t("YouMustSelectAtLeastOneType"),
    NodeNumber: t("NodeNumber"),
    RadiusInMeters: t("RadiusInMeters"),
    RadiusInM: t("RadiusInM"),
    SEARCH: t("SEARCH"),
    NodePHUB: t("NodePHUB"),
    ProjectType: t("ProjectType"),
    JobType: t("JobType"),
    RepairType: t("RepairType"),
    StreetNumber: t("StreetNumber"),
    Compass: t("Compass"),
    CurrentStatus: t("CurrentStatus"),
    Age: t("Age"),
    ZoomAccuracy: t("ZoomAccuracy"),
    Program: t("Program"),
    NikaOrders: t("NikaOrders"),
    to: t("to"),
    NetMaint: t("NetMaint"),
    Construction: t("Construction"),
    BuriedDrops: t("BuriedDrops"),
    ID: t("ID"),
    NoSearchHistory: t("NoSearchHistory"),
    PerformSearchToSeeTheResults: t("PerformSearchToSeeTheResults"),
    portName: t("Port Name"),
    f1: t("F1"),
    f2: t("F2"),
    f3: t("F3"),
    f4: t("F4"),
    f5: t("F5"),
    f6: t("F6"),
    f7: t("F7"),
    f8: t("F8"),
    r1: t("R1"),
    r2: t("R2"),
    portSequence: t("Port Sequence"),
    portDirection: t("Port Direction"),
    networkID: t("Network ID"),
    no_port_info: t("No Ports Info to display"),
    portInfo: t("Port Info"),
    annotationBlock: t("Annotation Block"),
    no_address_info: t("No Address Info to display"),
    Comment: t("Comment"),
    Address: t("Address"),
    Distance_To_Here: t("Distance To Here"),
    Measure_Distance: t("Measure Distance"),
    Clear_measurements: t("Clear measurements"),
    Search_Geographic_Location: t(
      "Search a location by geographic coordinates"
    ),
    Uppercase_Search: t("SEARCH"),
    Search_Coordinates: t("Search by Coordinates"),
    Coordinates_Error: t("Invalid coordinates. Please check for any errors."),
    Standard: t("Standard"),
    Silver: t("Silver"),
    Retro: t("Retro"),
    Dark: t("Dark"),
    Night: t("Night"),
    Aubergine: t("Aubergine"),
    full: t("full"),
    basic: t("basic"),
    stop: t("stop"),
    DevicesShown: t("Devices Shown"),
    DevicesHidden: t("Devices Hidden"),
    invalidNodeNumber: t("Please Type Valid Node Number"),
    startTime: t("Start Time"),
    endTime: t("End Time"),
    responsive: t("Responsive"),
    unresponsive: t("Unresponsive"),
    active: t("Active"),
    totalAssigned: t("Total Assigned"),
    plottedMap: t("Plotted On Map"),
    notPlottedMap: t("Not Plotted On Map"),
    RadiusIn: t("RadiusIn"),
    M: t("M"),
    type: t("type"),
    code: t("code"),
    event: t("event"),
    startDate: t("startDate"),
    dateRange: t("dateRange"),
    endDate: t("endDate"),
    filters: t("filter"),
    levels: t("levels"),
    midLeakage: t("midLeakage"),
    lteLeakage: t("lteLeakage"),
    ingressPoint: t("ingressPoint"),
    coverage: t("coverage"),
    cpatEvents: t("cpatEvents"),
    unassigned: t("unassigned"),
    assigned: t("assigned"),
    closed: t("closed"),
    archived: t("archived"),
    radius: t("radius"),
    YouMustSelectAtLeastOneFilter: t("YouMustSelectAtLeastOneFilter"),
    midLeakagePlaceHolder: t("midLeakagePlaceHolder"),
    lteLeakagePlaceHolder: t("lteLeakagePlaceHolder"),
    ingressPointPlaceHolder: t("ingressPointPlaceHolder"),
    radiusRange: t("radiusRange"),
    cpatDateValidation: t("cpatDateValidation"),
    submit: t("Submit"),
    cmMac: t("CM Mac"),
    model: t("Model"),
    uptime: t("UpTime"),
    returnSegment: t("ReturnSegment"),
    offline: t("OffLine"),
    registeredTime: t("Registered Time"),
    notPlottedOnMap: t("Modems not plotted on map"),
    noLocationInfo: t("No location info found"),
    advanceFilter: t("Advance Filter"),
    flapbetween: t("Flaps between"),
    flapCount: t("Flap Count"),
    inf: t("inf"),
    pwr: t("pwr"),
    regFlaps: t("REG TIME/FLAPS"),
    partialService: t("Partial Service"),
    microReflections: t("microReflections"),
    issue: t("issue"),
    count: t("count"),
    freq: t("freq"),
    homeAddress: t("homeAddress"),
    addresses: t("addresses"),
    apply: t("Apply") as string,
    node_ID: t("Node ID"),
    eventNumber: t("eventNumber"),
    node: t("node"),
    address: t("address"),
    detectionDate: t("detectionDate"),
    status: t("status"),
    midFreq: t("midFreq"),
    lteFreq: t("lteFreq"),
    ingressFreq: t("ingressFreq"),
    ingress: t("ingress"),
    assignedUnassigned: t("assignedUnassigned"),
    ApartmentNo: t("ApartmentNo"),
    StreetNo: t("StreetNo"),
    NoCoordinatesFound: t("NoCoordinatesFound"),
    SearchByAddress: t("SearchByAddress"),
    SearchGeoLocationByAddress: t("SearchGeoLocationByAddress"),
    PostalCode: t("PostalCode"),
    isRequiredForAddressSearch: t("isRequiredForAddressSearch"),
    FilterResultsByAnyValue: t("FilterResultsByAnyValue"),
    advSearch: t("advSearch"),
    searched: t("searched"),
    NodeOverview: t("NodeOverview"),
    PHUB: t("PHUB"),
    SMT: t("SMT"),
    USPEERS: t("USPEERS"),
    CMTSIP: t("CMTSIP"),
    CMTSNAME: t("CMTSNAME"),
    MACDOMAIN: t("MACDOMAIN"),
    HomesPassed: t("HomesPassed"),
    FullModemCount: t("FullModemCount"),
    IgniteModemCount: t("IgniteModemCount"),
    LegacyModemCount: t("LegacyModemCount"),
    USPorts: t("USPorts"),
    Architecture: t("Architecture"),
    OFDM_CHANCONFIG: t("OFDM_CHANCONFIG"),
    OFDMA_CHANCONFIG: t("OFDMA_CHANCONFIG"),
    LowerFreq: t("LowerFreq"),
    UpperFreq: t("UpperFreq"),
    Width: t("Width"),
    MinMod: t("MinMod"),
    MaxMod: t("MaxMod"),
    GetDiagnostics: t("GetDiagnostics"),
    GetOrders: t("GetOrders"),
    GetMicroReflections: t("GetMicroReflections"),
    Radius: t("Radius"),
    SearchbyNodeId: t("SearchbyNodeId"),
    SearchbyRadius: t("SearchbyRadius"),
    searchBy: t("searchBy"),
    cpat: t("cpat"),
    fiberStorageAndRiser: t("fiberStorageAndRiser"),
    fiberAddrConnectionPoint: t("fiberAddrConnectionPoint"),
    powerInserter: t("powerInserter"),
    siteComponents: t("siteComponents"),
    category: t("category"),
    name: t("name"),
    detectionDateLabel: t("detectionDateLabel"),
    Note: t("Note"),
    SubscriberAddressesMsg: t("SubscriberAddressesMsg"),
    ModemOnline: t("ModemOnline"),
    DownstreadOutOfSpec: t("DownstreadOutOfSpec"),
    UpstreamOutOfSpec: t("UpstreamOutOfSpec"),
    UpstreamDownstreamOutOfSpec: t("UpstreamDownstreamOutOfSpec"),
    NoSNMPResponse: t("NoSNMPResponse"),
    UpstreamDownstreamInSpec: t("UpstreamDownstreamInSpec"),
    ModemOffline: t("ModemOffline"),
    Legends: t("Legends"),
    Hide: t("Hide"),
    Show: t("Show"),
    Definition: t("Definition"),
    elementSearch: t("elementSearch"),
    rensNum: t("rensNum"),
    sType: t("sType"),
    rfDeviceType: t("rfDeviceType"),
    nodeNotFoundInSpatial: t("nodeNotFoundInSpatial"),
    Icon: t("Icon"),
    clickStatusFilterMsg: t("clickStatusFilterMsg"),
    clickOrderFilterMsg: t("clickOrderFilterMsg"),
    ShowHideOrders: t("ShowHideOrders"),
    DaysBack: t("DaysBack"),
    DateRange: t("DateRange"),
    SelectDateRange: t("SelectDateRange"),
    Status: t("Status"),
    Tech: t("Tech"),
    CompletionCodes: t("CompletionCodes"),
    TimeCreated: t("TimeCreated"),
    TimeModified: t("TimeModified"),
    RemedyImtId: t("RemedyImtId"),
    WorkType: t("WorkType"),
    PonHub: t("PonHub"),
    PonNap: t("PonNap"),
    PonNode: t("PonNode"),
    Company: t("Company"),
    Revision: t("Revision"),
    ClickOrder: t("ClickOrder"),
    noDataFound: t("noDataFound"),
    info: t("Info"),
    comments: t("Comments"),
    comment: t("Comment"),
    total: t("Total"),
    all: t("All"),
    mine: t("Mine"),
    add: t("Add"),
    edit: t("Edit"),
    cancel: t("Cancel"),
    delete: t("Delete"),
    deleteCommentQuestion: t("deleteCommentQuestion"),
    ClickOrders: t("ClickOrders"),
    Filters: t("Filters"),
    Details: t("Details"),
    pleaseAddComment: t("pleaseAddComment"),
    filterOptionFailMsg: t("filterOptionFailMsg"),
    somethingWrongMsg: t("somethingWrongMsg"),
    refreshMsg: t("refreshMsg"),
    dueToTheReasonMsg: t("dueToTheReasonMsg"),
    tryRefreshMsg: t("tryRefreshMsg"),
    unableToPullMsg: t("unableToPullMsg"),
    sectionMsg: t("sectionMsg"),
    from: t("from"),
    ErrorCode: t("ErrorCode"),
    Refresh: t("Refresh"),
    noDataMsg: t("noDataMsg"),
    typeComment: t("typeComment"),
    viewLess: t("viewLess"),
    viewMore: t("viewMore"),
    allComments: t("allComments"),
    myComments: t("myComments"),
    Fiber: t("Fiber"),
    radioFreqRF: t("radioFreqRF"),
    FiberAndRF: t("FiberAndRF"),
    addMemo: t("addMemo"),
    editMemo: t("editMemo"),
    deleteMemo: t("deleteMemo"),
    deleteMemoWarning: t("deleteMemoWarning"),
    pleaseAddMemo: t("pleaseAddMemo"),
    update: t("Update"),
    typeMemoHere: t("typeMemoHere"),
    max1000CharsAllowed: t("max1000CharsAllowed"),
    other: t("Other"),
    noCommentFound: t("noCommentFound"),
    addAComment: t("addAComment"),
    googleDirectionMsg: t("googleDirectionMsg"),
    Value: t("Value"),
    polygonId: t("polygonId"),
    polygonType: t("polygonType"),
    SType: t("SType"),
    siteDescription: t("siteDescription"),
    Designation: t("Designation"),
    Manufacturer: t("Manufacturer"),
    Attr11: t("Attr11"),
    Attr12: t("Attr12"),
    NumPorts: t("NumPorts"),
    Clamshell: t("Clamshell"),
    UPS: t("UPS"),
    Wattage: t("Wattage"),
    sysType: t("sysType"),
    OHUG: t("OHUG"),
    profileName: t("profileName"),
    InterfaceFk: t("InterfaceFk"),
    deviceDesc: t("deviceDesc"),
    Model: t("Model"),
    deviceClass: t("deviceClass"),
    Volts: t("Volts"),
    Volts1: t("Volts1"),
    Volts2: t("Volts2"),
    Volts3: t("Volts3"),
    Volts4: t("Volts4"),
    Volts5: t("Volts5"),
    Amps: t("Amps"),
    Amps1: t("Amps1"),
    Amps2: t("Amps2"),
    Amps3: t("Amps3"),
    Amps4: t("Amps4"),
    Amps5: t("Amps5"),
    AMPClass: t("AMPClass"),
    AMPDrawId: t("AMPDrawId"),
    CTB: t("CTB"),
    CSO: t("CSO"),
    SymbolName: t("SymbolName"),
    OLT: t("OLT"),
    Description: t("Description"),
    SiteName: t("SiteName"),
    Province: t("Province"),
    Town: t("Town"),
    BuildingId: t("BuildingId"),
    BuildingType: t("BuildingType"),
    ISPCode: t("ISPCode"),
    ISPABuildingFk: t("ISPABuildingFk"),
    ISPABuildingFloorFk: t("ISPABuildingFloorFk"),
    Manufacture: t("Manufacture"),
    MaxPorts: t("MaxPorts"),
    MountPoint: t("MountPoint"),
    Part: t("Part"),
    RackId: t("RackId"),
    RackName: t("RackName"),
    RackType: t("RackType"),
    RePres: t("RePres"),
    rpats: t("rpats"),
    SCPres: t("SCPres"),
    SectionFCode: t("SectionFCode"),
    Attr1: t("Attr1"),
    Catalogue: t("Catalogue"),
    Clli: t("Clli"),
    ShowHideEvents: t("ShowHideEvents"),
    DrawerAutoClose: t("DrawerAutoClose"),
    ON: t("ON"),
    OFF: t("OFF"),
    Comments: t("Comments"),
    description: t("description"),
    OLTName: t("OLTName"),
    ExportAsCSV: t("ExportAsCSV"),
    TrayNo: t("TrayNo"),
    LeftCableName: t("LeftCableName"),
    LeftNo: t("LeftNo"),
    LeftTubeStrandColor: t("LeftTubeStrandColor"),
    Slot: t("Slot"),
    State: t("State"),
    RightTubeStrandColor: t("RightTubeStrandColor"),
    RightNo: t("RightNo"),
    RightCableName: t("RightCableName"),
    Usage: t("Usage"),
    SpliceMap: t("SpliceMap"),
    GetSpliceMap: t("GetSpliceMap"),
    passProperDataMsg: t("passProperDataMsg"),
    Splitters: t("Splitters"),
    spatialDesignLocation: t("spatialDesignLocation"),
    addressPort: t("addressPort"),
    portSelector: t("portSelector"),
    powertrace: t("powertrace"),
    locateToPowerSupply: t("locateToPowerSupply"),
    OLTCards: t("OLTCards"),
    ExpandAll: t("ExpandAll"),
    CollapseAll: t("CollapseAll"),
    opticalTap: t("opticalTap"),
    LCP: t("LCP"),
    drawingName: t("drawingName"),
    NoEnclosureIDExists: t("NoEnclosureIDExists"),
    List: t("List"),
    orderCategoryDesc: t("orderCategoryDesc"),
    orderCategoryName: t("orderCategoryName"),
    orderTypeDesc: t("orderTypeDesc"),
    returnComments: t("returnComments"),
    files: t("Files"),
    attachmentList: t("attachmentList"),
    download: t("download"),
    fileName: t("fileName"),
    date: t("date"),
    noAttachmentFound: t("noAttachmentFound"),
    uploadFile: t("uploadFile"),
    orderType: t("Order Type"),
    workOrders: t("Work Orders"),
    asOf: t("asOf"),
    workOrder: t("workOrder"),
    accountNumber: t("accountNumber"),
    id: t("id"),
    shubId: t("shubId"),
    smtId: t("smtId"),
    customerName: t("customerName"),
    customerContactNumber: t("customerContactNumber"),
    specialInstructions: t("specialInstructions"),
    appointmentTime: t("appointmentTime"),
    callCreated: t("callCreated"),
    callAssigned: t("callAssigned"),
    deleteAttachmentHeader: t("deleteAttachmentHeader"),
    deleteAttachmentDescription: t("deleteAttachmentDescription"),
    downstream: t("downstream"),
    upstream: t("upstream"),
    trace: t("trace"),
    parentNode: t("parentNode"),
    fwdChannel: t("fwdChannel"),
    rtnChannel: t("rtnChannel"),
    cso: t("cso"),
    vhub: t("vhub"),
    psId: t("psId"),
    rfNode: t("rfNode"),
    siteType: t("siteType"),
    vhubChildrenClamshells: t("vhubChildrenClamshells"),
    coordinates: t("coordinates"),
    fullWoCodewords: t("fullWoCodewords"),
    locator: t("locator"),
    wirelessSite: t("wirelessSite"),
    smallCell: t("smallCell"),
    rbCircuit: t("rbCircuit"),
    rensSearchMsg: t("rensSearchMsg"),
    siteId: t("siteId"),
    circuitId: t("circuitId"),
    buildingId: t("buildingId"),
    ringName: t("ringName"),
    mduDiagram: t("mduDiagram"),
    owningDrawing: t("owningDrawing"),
    logicalView: t("logicalView"),
    ds: t("ds"),
    us: t("us"),
    totalNodes: t("totalNodes"),
    tapId: t("tapId"),
    tapValue: t("tapValue"),
    olt: t("olt"),
    tableView: t("tableView"),
    circuit: t("circuit"),
    elementName: t("elementName"),
    cableType: t("cableType"),
    segmentType: t("segmentType"),
    length: t("length"),
    cumulativeLength: t("cumulativeLength"),
    distance: t("distance"),
    kms: t("kms"),
    nodeBoundaries: t("nodeBoundaries"),
    ispSites: t("ispSites"),
  });
}
