import { UserPrefConfigState } from "../../states/preferences/UserPrefConfigState";
import {
  UserPrefConfigActionTypes,
  SAVE_CONFIG_DATA,
  UPDATE_CONFIG_DATA,
  UPDATE_CONFIG_PINS,
} from "../../types/preferences/UserPrefConfig";

const initialState: UserPrefConfigState = {
  configData: undefined,
};

export default function UserPrefConfigReducer(
  state = initialState,
  action: UserPrefConfigActionTypes
): UserPrefConfigState {
  switch (action.type) {
    case SAVE_CONFIG_DATA:
      return {
        ...state,
        configData: {
          userPreferences: {
            node: { telemetry: { views: [...action.payload] } },
          },
        },
      };
    case UPDATE_CONFIG_DATA:
      let tempOthViews =
        state.configData?.userPreferences?.node?.telemetry?.views;
      const idx = tempOthViews.findIndex((obj: any) => {
        return (
          obj?.viewTypeKey?.toUpperCase() === action?.viewType?.toUpperCase()
        );
      });
      let tempViews = tempOthViews[idx];
      let tempFields = tempViews?.attributes?.map((col: any) => {
        let colObj = col;
        if (action.payload?.indexOf(colObj.columnKey) !== -1) {
          if (colObj.isVisible) {
            colObj = { ...colObj, isVisible: false };
          } else {
            colObj = { ...colObj, isVisible: true };
          }
          return { ...colObj };
        } else {
          return { ...colObj };
        }
      });

      tempViews.attributes = tempFields;
      tempOthViews[idx] = tempViews;

      return {
        ...state,
        configData: {
          ...{
            userPreferences: {
              node: {
                telemetry: { views: tempOthViews },
              },
            },
          },
        },
      };

    case UPDATE_CONFIG_PINS:
      let tempOthViewS =
        state.configData?.userPreferences?.node?.telemetry?.views;
      const idX = tempOthViewS.findIndex(
        (obj: any) =>
          obj?.viewTypeKey?.toUpperCase() === action?.viewType?.toUpperCase()
      );
      let tempViewS = tempOthViewS[idX];

      let tempFieldS = tempViewS?.fields?.map((col: any) => {
        let colObj = col;

        if (action.payload?.indexOf(colObj.name) !== -1) {
          if (colObj.isPinned) {
            colObj = { ...colObj, isPinned: false };
          } else {
            colObj = { ...colObj, isPinned: true };
          }
          return { ...colObj };
        } else {
          return { ...colObj };
        }
      });

      tempViewS.fields = tempFieldS;
      tempOthViewS[idX] = tempViewS;

      return {
        ...state,
        configData: {
          ...{
            userPreferences: {
              node: {
                telemetry: { views: tempOthViewS },
              },
            },
          },
        },
      };

    default:
      return state;
  }
}
