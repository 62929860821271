import { useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  GetWorkOrderDetails
} from "../../get-api/GetResponse";
import { Divider } from "@material-ui/core";
import { IoIosArrowDropup } from "react-icons/io";
import CopyToClipboard from "../../components/reusable/CopyToClipboard";
import ExternalAppMenu from "../../components/reusable/ExternalAppMenu";
import AppLabels from "../../constants/App_Labels";
import AccDetailsClient from "../../network/httpclient/accountInfo/AccDetailsClient";
import { useGponNodeClient } from "../../network/httpclient/gpon/GponNodeClient";
import { SetAccountType } from "../../store/actions/account/accountInfo/ActiveAccountAction";
import { ResetGponNodes } from "../../store/actions/gpon/GponNodeSummaryAction";
import { extractW3IdOfHSI, isNull, isUpdated , replacePatternData} from "../reusable/Util";
import AccountLevelSection from "./AccountLevelSection";

const AccountBasicInfo = (props: any) => {
  const {
    data,
    sADdSelectedValue,
    selectedSA,
    handleAddressSelect,
    serviceAddress,
    handleToggle,
  } = props;
  const labels = AppLabels();
  const { allAccounts, serviceAreaInfo,ontSummaryDetails } = useSelector(
    (state: any) => ({
      allAccounts: state.AllAccounts.accounts,
      serviceAreaInfo: state.AccountState.ServiceAreaInfo.data,
      ontSummaryDetails:state.AccountState.ontSummary.data,
    }),
    shallowEqual
  );
  const [topologyName, setTopologyName] = useState("N/A");
  const dispatch = useDispatch();
  const nodeInfoApi = useGponNodeClient();
  const { data: woDetails } = GetWorkOrderDetails();
  const handleNodeLink = async (gponNodeId: string) => {
    dispatch(ResetGponNodes());

    const apiResponse = await nodeInfoApi.getNodesInfo(gponNodeId, "Maestro");

    if (apiResponse?.resultList?.length > 0) {
      // open new window with node details
      let newWindow = window.open(
        `/#/node?gponId=${apiResponse.ponNode}`,
        "_blank"
      )!;
      newWindow.onload = () => {
        newWindow.document.title = "GPON-" + apiResponse?.ponNode;
      };
    } else {
      dispatch(ResetGponNodes);
      alert("Node not found");
    }
  };

  const handleShubLink = async (nodeId: string) => {
    let newWindow = window.open(
      `${window.location.origin}#/` + `${"node?nodeId=" + nodeId}`,
      "_blank"
    )!;
    newWindow.onload = () => {
      newWindow.document.title = "N-" + nodeId;
    };
  };
  const handleOLTMainLink = async (oltName: String, ponPort: String) => {
      let newWindow = window.open(
        `/#/pon?ponOltName=${oltName}&ponPort=${replacePatternData(ponPort,'-').toString()}&ponNode=${ serviceAreaInfo?.serviceAddressInfo?.generalAddressInfo
          ?.gponNode}`,
        "_blank"
      )!;
      newWindow.onload = () => {
        newWindow.document.title = "PON-" + oltName;
      };
  
  };
  const handleOLTLink = async (oltName: String) => {
      let newWindow = window.open(
        `/#/ponOlt=${oltName}`,
        "_blank"
      )!;
      newWindow.onload = () => {
        newWindow.document.title = "PON-" + oltName;
      };
  
  };
  useEffect(() => {
    if (selectedSA !== undefined && selectedSA?.length !== 0) {
      if (selectedSA?.hasOwnProperty("topology")) {
        setTopologyName(selectedSA.topology);
        dispatch(SetAccountType(selectedSA.topology));
        if (
          selectedSA.topology === "GPON" &&
          !isUpdated(allAccounts, data?.accountNumber)
        ) {
          dispatch(AccDetailsClient.getServiceAreaInfo(selectedSA.samKey));
        }
      }
    }
  }, [selectedSA]);
  return (
    <div className="custInfo">
      <section className="accDetails">
        <div className="subAccDetails">
          <h4 id="custName">
            {isNull(data?.firstName)} {isNull(data?.lastName)}
          </h4>
          <p>
            <span>{labels.ACCOUNT_TYPE?.toUpperCase()}</span>
            <span>{isNull(data?.accountType?.toUpperCase())}</span>
            <span className="accTopology">{topologyName}</span>
          </p>
          <p>
            <span>{(labels.ACCOUNT_ID + " ")?.toUpperCase()}</span>
            <span>{isNull(data?.accountNumber)}</span>
          </p>
          {topologyName === "GPON" ? (
            // <p>
            //   <span>{(labels.node_id + " ")?.toUpperCase()}</span>
            //   <span
            //     className="clearAllLink"
            //     onClick={() => {
            //       handleNodeLink(
            //         serviceAreaInfo?.serviceAddressInfo?.generalAddressInfo
            //           ?.gponNode
            //       );
            //     }}
            //   >
            //     {isNull(
            //       serviceAreaInfo?.serviceAddressInfo?.generalAddressInfo
            //         ?.gponNode
            //     )}
            //   </span>
            // </p>
            <>
            <p>
                <span>{(labels.olt_name + " ")?.toUpperCase()}</span>
                <span
                  className="clearAllLink"
                  onClick={() => {
                    handleOLTLink(
                      ontSummaryDetails?.oltName
                    );
                  } }
                >
                  {isNull(
                    ontSummaryDetails?.oltName
                  )}
                </span>
              </p>
              <p>
              <span>{("PON PORT" + " ")?.toUpperCase()}</span>
              <span
                className="clearAllLink"
                onClick={() => {
                  handleOLTMainLink(
                    ontSummaryDetails?.oltName, ontSummaryDetails?.ponPort
                  );
                } }
              >
                {isNull(
                 ontSummaryDetails?.ponPort
                )}
              </span>
            </p>
            </>
          ) : (
            <p className="nodeDetailsCont">
              <span className="nDetailsLabel">
                {(labels.node_id + " ")?.toUpperCase()}
              </span>
              <span
                className="clearAllLink"
                onClick={() => {selectedSA?.shubId?.toUpperCase() !='PON' ?
                  handleShubLink(selectedSA?.shubId):
                  handleNodeLink(woDetails?.ponNode);
                }}
              >
                {isNull(selectedSA?.shubId?.toUpperCase() !='PON'?selectedSA?.shubId:
                  woDetails?.ponNode)}
              </span>
              <Divider orientation="vertical" />
              <span className="nDetailsLabel">
                {(labels.SMT_ID + " ")?.toUpperCase()}
              </span>
              <span
                className="clearAllLink"
                onClick={() => {selectedSA?.smtId?.toUpperCase() !='PON' ?
                  handleShubLink(
                  selectedSA?.smtId):handleNodeLink(woDetails?.ponNap);
                }}
              >
                {isNull(selectedSA?.smtId?.toUpperCase() !='PON' ?
                  selectedSA?.smtId:woDetails?.ponNap)}
              </span>
            </p>
          )}
        </div>
        <div className="subAccDetails">
          <p>
            <span>{(labels.CBP_ID + " ")?.toUpperCase()}</span>
            {data.accountNumber?.length === 9 ? (
              <span>{isNull(data?.cbpId)}</span>
            ) : (
              <>N/A</>
            )}
          </p>
          {extractW3IdOfHSI(selectedSA) &&
          data?.brand?.toUpperCase() !== "FIDO" ? (
            <span>
              <span>{(labels.HHID + " ")?.toUpperCase()} </span>
              <span>{isNull(extractW3IdOfHSI(selectedSA))}</span>{" "}
              {/* w3id = HHID */}
            </span>
          ) : null}
          <div>
            <span>{labels.SERVICE_ADDRESS.toUpperCase()}</span>
            <DropdownButton
              title={sADdSelectedValue}
              onSelect={handleAddressSelect}
              size="sm"
              className="sADropd"
              id="sAddressDd"
            >
              {serviceAddress?.map((sa: any, i: number) => {
                return (
                  <Dropdown.Item key={i} eventKey={sa}>
                    {sa}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
            <CopyToClipboard dataString={sADdSelectedValue} />
          </div>
        </div>
      </section>
      <section className="accDetailsActions">
        <AccountLevelSection />
        <div className="appMenuCont">
          <ExternalAppMenu />
          <IoIosArrowDropup size={25} onClick={() => handleToggle()} />
        </div>
      </section>
    </div>
  );
};

export default AccountBasicInfo;
