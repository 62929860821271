import { useEffect, useState } from "react";
import { Dropdown, DropdownButton, Form } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import AppLabels from "../../../../constants/App_Labels";
import "../../../../design-tokens/forms/Noise.scss";
import ValidateClient from "../../../../network/httpclient/form/ValidateClient";
import { POD } from "../../../../store/models/account/diagnostics/pods/POD";
import RedAsterisk from "../../../reusable/RedAsterisk";

interface Props {
  formSubmissionNo: any;
  detailsObj: any;
  setDetailsObj: any;
  initialData: any;
  setInitialData: any;

  acknowledgment: any;
  ackUpdate: any;
  stbTextInputArray: any;
  setStbTextInputArray: any;
  paidPodTextInputArray: any;
  setPaidPodTextInputArray: any;
  freePodTextInputArray: any;
  setFreePodTextInputArray: any;
}

const FirstHalf = ({
  formSubmissionNo,
  detailsObj,
  setDetailsObj,
  initialData,
  setInitialData,
  acknowledgment,
  ackUpdate,
  stbTextInputArray,
  setStbTextInputArray,
  paidPodTextInputArray,
  setPaidPodTextInputArray,
  freePodTextInputArray,
  setFreePodTextInputArray,
}: Props) => {
  const labels = AppLabels();
  const { validateData, validateStatus, validateRequestType } = useSelector(
    (state: any) => ({
      validateData: state.AccountState.validateData.data,
      validateStatus: state.AccountState.validateData.status,
      validateRequestType: state.AccountState.validateData.requestType,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const [bannerVisible, setBannerVisible] = useState(false);
  const [data, setData] = useState({});
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setDetailsObj((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onCheckBoxStateChange = (checked: boolean, chkName: string) => {
    initialData
      .filter((opt: any) => opt.key === chkName)
      .map((opt: any) => (opt.active = checked));

    initialData.map((el: any) => {
      if (el?.key === "STB") {
        setDetailsObj((prevState: any) => ({
          ...prevState,
          isSTBchecked: el?.active ? true : false,
        }));
      } else if (el?.key === "Paid Pod") {
        setDetailsObj((prevState: any) => ({
          ...prevState,
          isPaidPodChecked: el?.active ? true : false,
        }));
      } else if (el?.key === "Free Pod") {
        setDetailsObj((prevState: any) => ({
          ...prevState,
          isFreePodChecked: el?.active ? true : false,
        }));
      }
    });

    setInitialData([...initialData]);
  };

  const { podList } = useSelector(
    (state: any) => ({
      podList: state.AccountState.PodInfo.data,
    }),
    shallowEqual
  );
  const [pods, setPods] = useState<POD[]>(podList);

  const isFreePodChecked = () => {
    return initialData.find((opt: any) => opt.key === "Free Pod").active; // initialData[index].active = checked;
  };

  const isSTBchecked = () => {
    return initialData.find((opt: any) => opt.key === "STB").active; // initialData[index].active = checked;
  };

  const isequipList = () => {
    const l = [];
    l.push(initialData.find((opt: any) => opt.active === "true")?.label); // initialData[index].active = checked;
  };

  const isPaidPodChecked = () => {
    return initialData.find((opt: any) => opt.key === "Paid Pod").active; // initialData[index].active = checked;
  };

  const isGen1active = () => {
    return podList?.[0]?.model?.includes("XE1");
  };

  const isGen2active = () => {
    return podList?.[0]?.model?.includes("XE2");
  };

  const handleRadioDropdownChange = (name: any, value: number) => {
    setDetailsObj({ ...detailsObj, [name]: value });
  };

  const handleArrayChange = (e: any, index: any) => {
    const { name, value } = e.target;
    detailsObj[name][index] = value;
    setDetailsObj({
      ...detailsObj,
      [name]: [...detailsObj[name]],
    });
  };
  const isFreeReasoningneeded = () => {
    return detailsObj.freepodreasoning === "Other";
  };

  const isGen1Selected = () => {
    return podList?.[0]?.model?.includes("XE1");
  };

  const ispodTypeEnabledGen1 = () => {
    return detailsObj.podtype === "XE1";
  };

  const ispodTypeEnabledGen2 = () => {
    return detailsObj.podtype === "XE2";
  };

  const isGen2Selected = () => {
    return podList?.[0]?.model?.includes("XE2");
  };

  const isstbquantity1 = () => {
    if (isSTBchecked() === true) {
      return detailsObj.stbquantity === "1";
    }
  };
  const isstbquantity2 = () => {
    if (isSTBchecked()) {
      return detailsObj.stbquantity === "2";
    }
  };

  const isstbquantity3 = () => {
    if (isSTBchecked()) {
      return detailsObj.stbquantity === "3";
    }
  };

  const isstbquantity4 = () => {
    if (isSTBchecked()) {
      return detailsObj.stbquantity === "4";
    }
  };

  const isstbquantity5 = () => {
    if (isSTBchecked()) {
      return detailsObj.stbquantity === "5";
    }
  };

  const isstbquantity6 = () => {
    if (isSTBchecked()) {
      return detailsObj.stbquantity === "6";
    }
  };
  const isstbquantity7 = () => {
    if (isSTBchecked()) {
      return detailsObj.stbquantity === "7";
    }
  };

  const isstbquantity8 = () => {
    if (isSTBchecked()) {
      return detailsObj.stbquantity === "8";
    }
  };
  const isstbquantity9 = () => {
    if (isSTBchecked()) {
      return detailsObj.stbquantity === "9";
    }
  };
  const isstbquantity10 = () => {
    if (isSTBchecked()) {
      return detailsObj.stbquantity === "10";
    }
  };
  const isfreepodquantity1 = () => {
    if (isFreePodChecked()) {
      return detailsObj.freepodquantity === "1";
    }
  };
  const isfreepodquantity2 = () => {
    if (isFreePodChecked()) {
      return detailsObj.freepodquantity === "2";
    }
  };

  const isfreepodquantity3 = () => {
    if (isFreePodChecked()) {
      return detailsObj.freepodquantity === "3";
    }
  };

  const isfreepodquantity4 = () => {
    if (isFreePodChecked()) {
      return detailsObj.freepodquantity === "4";
    }
  };

  const ispaidpodquantity1 = () => {
    if (isPaidPodChecked()) {
      return detailsObj.paidpodquantity === "1";
    }
  };
  const ispaidpodquantity2 = () => {
    if (isPaidPodChecked()) {
      return detailsObj.paidpodquantity === "2";
    }
  };

  const ispaidpodquantity3 = () => {
    if (isPaidPodChecked()) {
      return detailsObj.paidpodquantity === "3";
    }
  };

  const ispaidpodquantity4 = () => {
    if (isPaidPodChecked()) {
      return detailsObj.paidpodquantity === "4";
    }
  };

  const checkifquantitymatches12 = () => {
    if (
      detailsObj.quantitytextbox1 !== "" &&
      detailsObj.quantitytextbox2 !== ""
    ) {
      return detailsObj.quantitytextbox1 === detailsObj.quantitytextbox2;
    }
  };

  const checkifquantitymatches34 = () => {
    if (
      detailsObj.quantitytextbox3 !== "" &&
      detailsObj.quantitytextbox4 !== ""
    ) {
      return detailsObj.quantitytextbox3 === detailsObj.quantitytextbox4;
    }
  };
  const checkifquantitymatches56 = () => {
    if (
      detailsObj.quantitytextbox5 !== "" &&
      detailsObj.quantitytextbox6 !== ""
    ) {
      return detailsObj.quantitytextbox5 === detailsObj.quantitytextbox6;
    }
  };
  const checkifquantitymatches78 = () => {
    if (
      detailsObj.quantitytextbox7 !== "" &&
      detailsObj.quantitytextbox8 !== ""
    ) {
      return detailsObj.quantitytextbox7 === detailsObj.quantitytextbox8;
    }
  };

  const checkifquantitymatches2526 = () => {
    if (
      detailsObj.quantitytextbox25 !== "" &&
      detailsObj.quantitytextbox26 !== ""
    ) {
      return detailsObj.quantitytextbox25 === detailsObj.quantitytextbox26;
    }
  };

  const checkifquantitymatches2728 = () => {
    if (
      detailsObj.quantitytextbox27 !== "" &&
      detailsObj.quantitytextbox28 !== ""
    ) {
      return detailsObj.quantitytextbox27 === detailsObj.quantitytextbox28;
    }
  };

  const checkifquantitymatches2930 = () => {
    if (
      detailsObj.quantitytextbox29 !== "" &&
      detailsObj.quantitytextbox30 !== ""
    ) {
      return detailsObj.quantitytextbox29 === detailsObj.quantitytextbox30;
    }
  };

  const checkifquantitymatches3132 = () => {
    if (
      detailsObj.quantitytextbox31 !== "" &&
      detailsObj.quantitytextbox32 !== ""
    ) {
      return detailsObj.quantitytextbox31 === detailsObj.quantitytextbox32;
    }
  };

  const checkifquantitymatches3334 = () => {
    if (
      detailsObj.quantitytextbox33 !== "" &&
      detailsObj.quantitytextbox34 !== ""
    ) {
      return detailsObj.quantitytextbox33 === detailsObj.quantitytextbox34;
    }
  };

  const checkifquantitymatches3536 = () => {
    if (
      detailsObj.quantitytextbox35 !== "" &&
      detailsObj.quantitytextbox36 !== ""
    ) {
      return detailsObj.quantitytextbox35 === detailsObj.quantitytextbox36;
    }
  };
  const checkifquantitymatches910 = () => {
    if (
      detailsObj.quantitytextbox9 !== "" &&
      detailsObj.quantitytextbox10 !== ""
    ) {
      return detailsObj.quantitytextbox9 === detailsObj.quantitytextbox10;
    }
  };
  const checkifquantitymatches1112 = () => {
    if (
      detailsObj.quantitytextbox11 !== "" &&
      detailsObj.quantitytextbox12 !== ""
    ) {
      return detailsObj.quantitytextbox11 === detailsObj.quantitytextbox12;
    }
  };
  const checkifquantitymatches1314 = () => {
    if (
      detailsObj.quantitytextbox13 !== "" &&
      detailsObj.quantitytextbox14 !== ""
    ) {
      return detailsObj.quantitytextbox13 === detailsObj.quantitytextbox14;
    }
  };
  const checkifquantitymatches1516 = () => {
    if (
      detailsObj.quantitytextbox15 !== "" &&
      detailsObj.quantitytextbox16 !== ""
    ) {
      return detailsObj.quantitytextbox15 === detailsObj.quantitytextbox16;
    }
  };
  const checkifquantitymatches1718 = () => {
    if (
      detailsObj.quantitytextbox17 !== "" &&
      detailsObj.quantitytextbox18 !== ""
    ) {
      return detailsObj.quantitytextbox17 === detailsObj.quantitytextbox18;
    }
  };
  const checkifquantitymatches1920 = () => {
    if (
      detailsObj.quantitytextbox19 !== "" &&
      detailsObj.quantitytextbox20 !== ""
    ) {
      return detailsObj.quantitytextbox19 === detailsObj.quantitytextbox20;
    }
  };
  const checkifquantitymatches2021 = () => {
    if (
      detailsObj.quantitytextbox20 !== "" &&
      detailsObj.quantitytextbox21 !== ""
    ) {
      return detailsObj.quantitytextbox20 === detailsObj.quantitytextbox21;
    }
  };
  const checkifquantitymatches2122 = () => {
    if (
      detailsObj.quantitytextbox21 !== "" &&
      detailsObj.quantitytextbox22 !== ""
    ) {
      return detailsObj.quantitytextbox21 === detailsObj.quantitytextbox22;
    }
  };

  const checkifquantitymatches2324 = () => {
    if (
      detailsObj.quantitytextbox23 !== "" &&
      detailsObj.quantitytextbox24 !== ""
    ) {
      return detailsObj.quantitytextbox23 === detailsObj.quantitytextbox24;
    }
  };

  const sendValidateSTB1 = () => {
    let requestPayload = {
      productCode: "PSTR",
      serialNumber: detailsObj.quantitytextbox1,
    };
    const base64EncObj = { data: btoa(JSON.stringify(requestPayload)) };
    dispatch(
      ValidateClient.sendValidateNoti(
        "Validation Request for STB1",
        base64EncObj
      )
    );
  };

  const sendValidateSTB2 = () => {
    let requestPayload = {
      productCode: "PSTR",
      serialNumber: detailsObj.quantitytextbox3,
    };
    const base64EncObj = { data: btoa(JSON.stringify(requestPayload)) };
    dispatch(
      ValidateClient.sendValidateNoti(
        "Validation Request for STB2",
        base64EncObj
      )
    );
  };

  const sendValidateSTB3 = () => {
    let requestPayload = {
      productCode: "PSTR",
      serialNumber: detailsObj.quantitytextbox5,
    };
    const base64EncObj = { data: btoa(JSON.stringify(requestPayload)) };
    dispatch(
      ValidateClient.sendValidateNoti(
        "Validation Request for STB3",
        base64EncObj
      )
    );
  };

  const sendValidateSTB4 = () => {
    let requestPayload = {
      productCode: "PSTR",
      serialNumber: detailsObj.quantitytextbox7,
    };
    const base64EncObj = { data: btoa(JSON.stringify(requestPayload)) };
    dispatch(
      ValidateClient.sendValidateNoti(
        "Validation Request for STB4",
        base64EncObj
      )
    );
  };

  const sendValidateSTB5 = () => {
    let requestPayload = {
      productCode: "PSTR",
      serialNumber: detailsObj.quantitytextbox25,
    };
    const base64EncObj = { data: btoa(JSON.stringify(requestPayload)) };
    dispatch(
      ValidateClient.sendValidateNoti(
        "Validation Request for STB5",
        base64EncObj
      )
    );
  };

  const sendValidateSTB6 = () => {
    let requestPayload = {
      productCode: "PSTR",
      serialNumber: detailsObj.quantitytextbox27,
    };
    const base64EncObj = { data: btoa(JSON.stringify(requestPayload)) };
    dispatch(
      ValidateClient.sendValidateNoti(
        "Validation Request for STB 6",
        base64EncObj
      )
    );
  };

  const sendValidateSTB7 = () => {
    let requestPayload = {
      productCode: "PSTR",
      serialNumber: detailsObj.quantitytextbox29,
    };
    const base64EncObj = { data: btoa(JSON.stringify(requestPayload)) };
    dispatch(
      ValidateClient.sendValidateNoti(
        "Validation Request for STB 7",
        base64EncObj
      )
    );
  };

  const sendValidateSTB8 = () => {
    let requestPayload = {
      productCode: "PSTR",
      serialNumber: detailsObj.quantitytextbox31,
    };
    const base64EncObj = { data: btoa(JSON.stringify(requestPayload)) };
    dispatch(
      ValidateClient.sendValidateNoti(
        "Validation Request for STB 8",
        base64EncObj
      )
    );
  };

  const sendValidateSTB9 = () => {
    let requestPayload = {
      productCode: "PSTR",
      serialNumber: detailsObj.quantitytextbox33,
    };
    const base64EncObj = { data: btoa(JSON.stringify(requestPayload)) };
    dispatch(
      ValidateClient.sendValidateNoti(
        "Validation Request for STB 9",
        base64EncObj
      )
    );
  };

  const sendValidateSTB10 = () => {
    let requestPayload = {
      productCode: "PSTR",
      serialNumber: detailsObj.quantitytextbox35,
    };
    const base64EncObj = { data: btoa(JSON.stringify(requestPayload)) };
    dispatch(
      ValidateClient.sendValidateNoti(
        "Validation Request for STB 10",
        base64EncObj
      )
    );
  };

  const sendValidateFreePod1 = () => {
    let requestPayload = {
      productCode: "WXE0",
      serialNumber: detailsObj.quantitytextbox9,
    };
    const base64EncObj = { data: btoa(JSON.stringify(requestPayload)) };
    dispatch(
      ValidateClient.sendValidateNoti(
        "Validation Request for Free Pod 1",
        base64EncObj
      )
    );
  };

  const sendValidateFreePod2 = () => {
    let requestPayload = {
      productCode: "WXE0",
      serialNumber: detailsObj.quantitytextbox11,
    };
    const base64EncObj = { data: btoa(JSON.stringify(requestPayload)) };
    dispatch(
      ValidateClient.sendValidateNoti(
        "Validation Request for Free Pod 2",
        base64EncObj
      )
    );
  };

  const sendValidateFreePod3 = () => {
    let requestPayload = {
      productCode: "WXE0",
      serialNumber: detailsObj.quantitytextbox13,
    };
    const base64EncObj = { data: btoa(JSON.stringify(requestPayload)) };
    dispatch(
      ValidateClient.sendValidateNoti(
        "Validation Request for Free Pod 3",
        base64EncObj
      )
    );
  };

  const sendValidateFreePod4 = () => {
    let requestPayload = {
      productCode: "WXE0",
      serialNumber: detailsObj.quantitytextbox15,
    };
    const base64EncObj = { data: btoa(JSON.stringify(requestPayload)) };
    dispatch(
      ValidateClient.sendValidateNoti(
        "Validation Request for Free Pod 1",
        base64EncObj
      )
    );
  };

  const sendValidatePaidPod1 = () => {
    let requestPayload = {
      productCode: "WXE0",
      serialNumber: detailsObj.quantitytextbox17,
    };
    const base64EncObj = { data: btoa(JSON.stringify(requestPayload)) };
    dispatch(
      ValidateClient.sendValidateNoti(
        "Validation Request for Paid Pod 1",
        base64EncObj
      )
    );
  };

  const sendValidatePaidPod2 = () => {
    let requestPayload = {
      productCode: "WXE0",
      serialNumber: detailsObj.quantitytextbox19,
    };
    const base64EncObj = { data: btoa(JSON.stringify(requestPayload)) };
    dispatch(
      ValidateClient.sendValidateNoti(
        "Validation Request for Paid Pod 2",
        base64EncObj
      )
    );
  };

  const onVerifyClick = (type: any, serialNumber: any, index: any) => {
    const base64EncObj = {
      data: btoa(
        JSON.stringify({
          productCode:
            type === "STB"
              ? "PSTR"
              : detailsObj?.podtype === "XE2"
              ? "WXE0"
              : "WXP0",
          serialNumber: serialNumber,
        })
      ),
    };
    dispatch(
      ValidateClient.sendValidateNoti(
        `${type}-${serialNumber}-${index}`,
        base64EncObj
      )
    );
  };

  const sendValidatePaidPod3 = () => {
    let requestPayload = {
      productCode: "WXE0",
      serialNumber: detailsObj.quantitytextbox21,
    };
    const base64EncObj = { data: btoa(JSON.stringify(requestPayload)) };
    dispatch(
      ValidateClient.sendValidateNoti(
        "Validation Request for Paid Pod 3",
        base64EncObj
      )
    );
  };

  const sendValidatePaidPod4 = () => {
    let requestPayload = {
      productCode: "WXE0",
      serialNumber: detailsObj.quantitytextbox23,
    };
    const base64EncObj = { data: btoa(JSON.stringify(requestPayload)) };
    dispatch(
      ValidateClient.sendValidateNoti(
        "Validation Request for Paid Pod 4",
        base64EncObj
      )
    );
  };

  useEffect(() => {
    // check if serial number is valid or not
    if (validateStatus !== "") {
      const requestTypeValues = validateRequestType?.split("-"); //0-menu type, 1- serial number, 2-index

      const verifySnIndex = requestTypeValues?.[2];
      const isSerialNumberValid =
        validateStatus === labels.OK
          ? validateData?.toLowerCase() === "valid"
            ? true
            : false
          : false;

      if (verifySnIndex >= 0) {
        switch (requestTypeValues?.[0]) {
          case labels.STB:
            const stbData = [...stbTextInputArray];
            stbData[verifySnIndex] = {
              ...stbTextInputArray[verifySnIndex],
              lastVerifiedSn: detailsObj?.[stbData?.[verifySnIndex]?.key1],
              isSnValid: isSerialNumberValid,
            };
            setStbTextInputArray(stbData);
            break;
          case labels.PAID_POD:
            const paidPodData = [...paidPodTextInputArray];
            paidPodData[verifySnIndex] = {
              ...paidPodTextInputArray[verifySnIndex],
              lastVerifiedSn: detailsObj?.[paidPodData?.[verifySnIndex]?.key1],
              isSnValid: isSerialNumberValid,
            };
            setPaidPodTextInputArray(paidPodData);
            break;
          case labels.FREE_POD:
            const data = [...freePodTextInputArray];
            data[verifySnIndex] = {
              ...freePodTextInputArray[verifySnIndex],
              lastVerifiedSn: detailsObj?.[data?.[verifySnIndex]?.key1],
              isSnValid: isSerialNumberValid,
            };
            setFreePodTextInputArray(data);
            break;

          default:
            break;
        }
      }
      if (validateStatus === labels.ERROR) setBannerVisible(true);
    }
  }, [validateStatus]);

  return (
    <div>
      {formSubmissionNo !== "" && (
        <Form.Group>
          <label className="txtFieldLabel">{"Form Submission No"}</label>
          <Form.Control value={formSubmissionNo} disabled={true} />
        </Form.Group>
      )}

      <Form.Group>
        <label className="txtFieldLabel">
          <span style={{ color: "red" }}>
            {" "}
            Warning: You must complete and close the original work order in
            Click before submitting this form. Failure to do so will result in
            request fall out.”{" "}
          </span>
        </label>
      </Form.Group>
      <div>
        <label className="titleLabel">{"Equipment Menu"}:*</label>
        {initialData?.map((e: any, i: any) => (
          <div key={i} className="checkboxContainer">
            <label>{e.label}</label>
            <input
              type="checkbox"
              checked={e.active}
              style={{ width: "25px", height: "25px" }}
              onChange={(event) =>
                onCheckBoxStateChange(event.target.checked, e.key)
              }
            />
          </div>
        ))}
      </div>

      {!isSTBchecked() && !isFreePodChecked() && isPaidPodChecked() && (
        <Form.Group>
          <label className="txtFieldLabel">{"Existing pod model"}</label>
          <Form.Control value={podList?.[0]?.model} />
        </Form.Group>
      )}

      {isSTBchecked() && isFreePodChecked() && !isPaidPodChecked() && (
        <Form.Group>
          <label className="txtFieldLabel">{"Existing pod model"}</label>
          <Form.Control value={podList?.[0]?.model} />
        </Form.Group>
      )}

      {isSTBchecked() && !isFreePodChecked() && isPaidPodChecked() && (
        <Form.Group>
          <label className="txtFieldLabel">{"Existing pod model"}</label>
          <Form.Control value={podList?.[0]?.model} />
        </Form.Group>
      )}

      {!isSTBchecked() && isFreePodChecked() && !isPaidPodChecked() && (
        <Form.Group>
          <label className="txtFieldLabel">{"Existing pod model"}</label>
          <Form.Control value={podList?.[0]?.model} />
        </Form.Group>
      )}

      {!isSTBchecked() && isFreePodChecked() && isPaidPodChecked() && (
        <Form.Group>
          <label className="txtFieldLabel">{"Existing pod model"}</label>
          <Form.Control value={podList?.[0]?.model} />
        </Form.Group>
      )}

      {isSTBchecked() && isFreePodChecked() && isPaidPodChecked() && (
        <Form.Group>
          <label className="txtFieldLabel">{"Existing pod model"}</label>
          <Form.Control value={podList?.[0]?.model} />
        </Form.Group>
      )}

      {!isSTBchecked() && isPaidPodChecked() && !isFreePodChecked() && (
        <span className="DropdownContainer">
          <label className="txtFieldLabel">
            {"Pod Type"}
            {<RedAsterisk />}
          </label>
          <DropdownButton
            title={detailsObj?.podtype}
            onSelect={(v: any) => handleRadioDropdownChange("podtype", v)}
            size="sm"
            id="dropdownBtn"
            style={{ width: "30px" }}
          >
            <Dropdown.Item key={0} eventKey={"XE1"}>
              XE1
            </Dropdown.Item>
            <Dropdown.Item key={1} eventKey={"XE2"}>
              XE2
            </Dropdown.Item>
          </DropdownButton>
        </span>
      )}
      <Form.Group>
        {podList?.[0]?.model?.includes("XE1") &&
          !isSTBchecked() &&
          isPaidPodChecked() &&
          !isFreePodChecked() &&
          detailsObj.podtype === "XE2" && (
            <label className="txtFieldLabel">
              <span>
                ERROR MESSAGE: The existing Pod is Gen 1. This form is for new
                additions only. To replace XE1 pods with XE2 pods, please
                continue to reach out to RSG. Please use Gen1 Pods to add (if
                the existing pod is Gen1) and Please use Gen 2 Pods to add (if
                existing Pod is Gen2) &nbsp;
              </span>
            </label>
          )}
        {podList?.[0]?.model?.includes("XE2") &&
          !isSTBchecked() &&
          isPaidPodChecked() &&
          !isFreePodChecked() &&
          detailsObj.podtype === "XE1" && (
            <label className="txtFieldLabel">
              <span>
                ERROR MESSAGE: The existing Pod is Gen 2. This form is for new
                additions only. To replace XE1 pods with XE2 pods, please
                continue to reach out to RSG. Please use Gen1 Pods to add (if
                the existing pod is Gen1) and Please use Gen 2 Pods to add (if
                existing Pod is Gen2) &nbsp;
              </span>
            </label>
          )}
      </Form.Group>

      {!isSTBchecked() && !isPaidPodChecked() && isFreePodChecked() && (
        <span className="DropdownContainer">
          <label className="txtFieldLabel">
            {"Pod Type"}
            {<RedAsterisk />}
          </label>
          <DropdownButton
            title={detailsObj?.podtype}
            onSelect={(v: any) => handleRadioDropdownChange("podtype", v)}
            size="sm"
            id="dropdownBtn"
            style={{ width: "30px" }}
          >
            <Dropdown.Item key={0} eventKey={"XE1"}>
              XE1
            </Dropdown.Item>
            <Dropdown.Item key={1} eventKey={"XE2"}>
              XE2
            </Dropdown.Item>
          </DropdownButton>
        </span>
      )}
      <Form.Group>
        {podList?.[0]?.model?.includes("XE1") &&
          !isSTBchecked() &&
          !isPaidPodChecked() &&
          isFreePodChecked() &&
          ispodTypeEnabledGen2() && (
            <label className="txtFieldLabel">
              <span>
                ERROR MESSAGE: The existing Pod is Gen 1. This form is for new
                additions only. To replace XE1 pods with XE2 pods, please
                continue to reach out to RSG. Please use Gen1 Pods to add (if
                the existing pod is Gen1) and Please use Gen 2 Pods to add (if
                existing Pod is Gen2) &nbsp;
              </span>
            </label>
          )}
      </Form.Group>

      <Form.Group>
        {podList?.[0]?.model?.includes("XE2") &&
          !isSTBchecked() &&
          !isPaidPodChecked() &&
          isFreePodChecked() &&
          ispodTypeEnabledGen1() && (
            <label className="txtFieldLabel">
              <span>
                ERROR MESSAGE: The existing Pod is Gen 2. This form is for new
                additions only. To replace XE1 pods with XE2 pods, please
                continue to reach out to RSG. Please use Gen1 Pods to add (if
                the existing pod is Gen1) and Please use Gen 2 Pods to add (if
                existing Pod is Gen2) &nbsp;
              </span>
            </label>
          )}
      </Form.Group>

      {!isSTBchecked() && isPaidPodChecked() && isFreePodChecked() && (
        <span className="DropdownContainer">
          <label className="txtFieldLabel">
            {"Pod Type"}
            {<RedAsterisk />}
          </label>
          <DropdownButton
            title={detailsObj?.podtype}
            onSelect={(v: any) => handleRadioDropdownChange("podtype", v)}
            size="sm"
            id="dropdownBtn"
            style={{ width: "30px" }}
          >
            <Dropdown.Item key={0} eventKey={"XE1"}>
              XE1
            </Dropdown.Item>
            <Dropdown.Item key={1} eventKey={"XE2"}>
              XE2
            </Dropdown.Item>
          </DropdownButton>
        </span>
      )}
      <Form.Group>
        {podList?.[0]?.model?.includes("XE1") &&
          !isSTBchecked() &&
          isPaidPodChecked() &&
          isFreePodChecked() &&
          ispodTypeEnabledGen2() && (
            <label className="txtFieldLabel">
              <span>
                ERROR MESSAGE: The existing Pod is Gen 1. This form is for new
                additions only. To replace XE1 pods with XE2 pods, please
                continue to reach out to RSG. Please use Gen1 Pods to add (if
                the existing pod is Gen1) and Please use Gen 2 Pods to add (if
                existing Pod is Gen2) &nbsp;
              </span>
            </label>
          )}
      </Form.Group>

      <Form.Group>
        {podList?.[0]?.model?.includes("XE2") &&
          !isSTBchecked() &&
          isPaidPodChecked() &&
          isFreePodChecked() &&
          ispodTypeEnabledGen1() && (
            <label className="txtFieldLabel">
              <span>
                ERROR MESSAGE: The existing Pod is Gen 2. This form is for new
                additions only. To replace XE1 pods with XE2 pods, please
                continue to reach out to RSG. Please use Gen1 Pods to add (if
                the existing pod is Gen1) and Please use Gen 2 Pods to add (if
                existing Pod is Gen2) &nbsp;
              </span>
            </label>
          )}
      </Form.Group>

      {isSTBchecked() && isPaidPodChecked() && isFreePodChecked() && (
        <span className="DropdownContainer">
          <label className="txtFieldLabel">
            {"Pod Type"}
            {<RedAsterisk />}
          </label>
          <DropdownButton
            title={detailsObj?.podtype}
            onSelect={(v: any) => handleRadioDropdownChange("podtype", v)}
            size="sm"
            id="dropdownBtn"
            style={{ width: "30px" }}
          >
            <Dropdown.Item key={0} eventKey={"XE1"}>
              XE1
            </Dropdown.Item>
            <Dropdown.Item key={1} eventKey={"XE2"}>
              XE2
            </Dropdown.Item>
          </DropdownButton>
        </span>
      )}
      <Form.Group>
        {isGen1Selected() &&
          isSTBchecked() &&
          isPaidPodChecked() &&
          isFreePodChecked() &&
          ispodTypeEnabledGen2() && (
            <label className="txtFieldLabel">
              <span>
                ERROR MESSAGE: The existing Pod is Gen 1. This form is for new
                additions only. To replace XE1 pods with XE2 pods, please
                continue to reach out to RSG. Please use Gen1 Pods to add (if
                the existing pod is Gen1) and Please use Gen 2 Pods to add (if
                existing Pod is Gen2) &nbsp;
              </span>
            </label>
          )}
      </Form.Group>

      <Form.Group>
        {isGen2Selected() &&
          isSTBchecked() &&
          isPaidPodChecked() &&
          isFreePodChecked() &&
          ispodTypeEnabledGen1() && (
            <label className="txtFieldLabel">
              <span>
                ERROR MESSAGE: The existing Pod is Gen 2. This form is for new
                additions only. To replace XE1 pods with XE2 pods, please
                continue to reach out to RSG. Please use Gen1 Pods to add (if
                the existing pod is Gen1) and Please use Gen 2 Pods to add (if
                existing Pod is Gen2) &nbsp;
              </span>
            </label>
          )}
      </Form.Group>

      {isSTBchecked() && !isPaidPodChecked() && isFreePodChecked() && (
        <span className="DropdownContainer">
          <label className="txtFieldLabel">
            {"Pod Type"}
            {<RedAsterisk />}
          </label>
          <DropdownButton
            title={detailsObj?.podtype}
            onSelect={(v: any) => handleRadioDropdownChange("podtype", v)}
            size="sm"
            id="dropdownBtn"
            style={{ width: "30px" }}
          >
            <Dropdown.Item key={0} eventKey={"XE1"}>
              XE1
            </Dropdown.Item>
            <Dropdown.Item key={1} eventKey={"XE2"}>
              XE2
            </Dropdown.Item>
          </DropdownButton>
        </span>
      )}
      <Form.Group>
        {isGen1Selected() &&
          isSTBchecked() &&
          isPaidPodChecked() &&
          isFreePodChecked() &&
          ispodTypeEnabledGen2() && (
            <label className="txtFieldLabel">
              <span>
                ERROR MESSAGE: The existing Pod is Gen 1. This form is for new
                additions only. To replace XE1 pods with XE2 pods, please
                continue to reach out to RSG. Please use Gen1 Pods to add (if
                the existing pod is Gen1) and Please use Gen 2 Pods to add (if
                existing Pod is Gen2) &nbsp;
              </span>
            </label>
          )}
      </Form.Group>

      <Form.Group>
        {isGen2Selected() &&
          isSTBchecked() &&
          isPaidPodChecked() &&
          isFreePodChecked() &&
          ispodTypeEnabledGen1() && (
            <label className="txtFieldLabel">
              <span>
                ERROR MESSAGE: The existing Pod is Gen 2. This form is for new
                additions only. To replace XE1 pods with XE2 pods, please
                continue to reach out to RSG. Please use Gen1 Pods to add (if
                the existing pod is Gen1) and Please use Gen 2 Pods to add (if
                existing Pod is Gen2) &nbsp;
              </span>
            </label>
          )}
      </Form.Group>

      {isSTBchecked() && isPaidPodChecked() && !isFreePodChecked() && (
        <span className="DropdownContainer">
          <label className="txtFieldLabel">
            {"Pod Type"}
            {<RedAsterisk />}
          </label>
          <DropdownButton
            title={detailsObj?.podtype}
            onSelect={(v: any) => handleRadioDropdownChange("podtype", v)}
            size="sm"
            id="dropdownBtn"
            style={{ width: "30px" }}
          >
            <Dropdown.Item key={0} eventKey={"XE1"}>
              XE1
            </Dropdown.Item>
            <Dropdown.Item key={1} eventKey={"XE2"}>
              XE2
            </Dropdown.Item>
          </DropdownButton>
        </span>
      )}
      <Form.Group>
        {isGen1Selected() &&
          isSTBchecked() &&
          isPaidPodChecked() &&
          !isFreePodChecked() &&
          ispodTypeEnabledGen2() && (
            <label className="txtFieldLabel">
              <span>
                ERROR MESSAGE: The existing Pod is Gen 1. This form is for new
                additions only. To replace XE1 pods with XE2 pods, please
                continue to reach out to RSG. Please use Gen1 Pods to add (if
                the existing pod is Gen1) and Please use Gen 2 Pods to add (if
                existing Pod is Gen2) &nbsp;
              </span>
            </label>
          )}
      </Form.Group>

      <Form.Group>
        {isGen2Selected() &&
          isSTBchecked() &&
          isPaidPodChecked() &&
          !isFreePodChecked() &&
          ispodTypeEnabledGen1() && (
            <label className="txtFieldLabel">
              <span>
                ERROR MESSAGE: The existing Pod is Gen 2. This form is for new
                additions only. To replace XE1 pods with XE2 pods, please
                continue to reach out to RSG. Please use Gen1 Pods to add (if
                the existing pod is Gen1) and Please use Gen 2 Pods to add (if
                existing Pod is Gen2) &nbsp;
              </span>
            </label>
          )}
      </Form.Group>

      {isSTBchecked() && !isPaidPodChecked() && !isFreePodChecked() && (
        <span className="DropdownContainer">
          <label className="txtFieldLabel">{"STB Quantity"}</label>
          <DropdownButton
            title={detailsObj?.stbquantity}
            onSelect={(v: any) => handleRadioDropdownChange("stbquantity", v)}
            size="sm"
            id="dropdownBtn"
            style={{ width: "30px" }}
          >
            <Dropdown.Item key={0} eventKey={"1"}>
              1
            </Dropdown.Item>
            <Dropdown.Item key={1} eventKey={"2"}>
              2
            </Dropdown.Item>
            <Dropdown.Item key={2} eventKey={"3"}>
              3
            </Dropdown.Item>
            <Dropdown.Item key={3} eventKey={"4"}>
              4
            </Dropdown.Item>
            <Dropdown.Item key={4} eventKey={"5"}>
              5
            </Dropdown.Item>
            <Dropdown.Item key={5} eventKey={"6"}>
              6
            </Dropdown.Item>
            <Dropdown.Item key={6} eventKey={"7"}>
              7
            </Dropdown.Item>
            <Dropdown.Item key={7} eventKey={"8"}>
              8
            </Dropdown.Item>
            <Dropdown.Item key={8} eventKey={"9"}>
              9
            </Dropdown.Item>
            <Dropdown.Item key={9} eventKey={"10"}>
              10
            </Dropdown.Item>
          </DropdownButton>
        </span>
      )}

      <Form.Group>
        {isstbquantity1() && (
          <>
            <label className="txtFieldLabel">
              {"Enter SN for STB 1 "}

              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox1}
              name="quantitytextbox1"
            />

            <label className="txtFieldLabel">
              {"Re Enter SN for STB 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox2}
              name="quantitytextbox2"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox1, 0)
              }
              disabled={stbTextInputArray[0].isSnValid ? true : false}
            >
              {"Validate STB 1"}
            </button>

            <Form.Group>
              {!checkifquantitymatches12() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[0].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
          </>
        )}
      </Form.Group>

      <Form.Group>
        {isstbquantity2() && (
          <>
            <label className="txtFieldLabel">
              {"Enter Serial Number for STB 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              type="text"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox1}
              name="quantitytextbox1"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox2}
              name="quantitytextbox2"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox1, 0)
              }
              disabled={stbTextInputArray[0].isSnValid ? true : false}
            >
              {"Validate STB 1"}
            </button>
            <Form.Group>
              {!checkifquantitymatches12() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[0].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number STB 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox3}
              name="quantitytextbox3"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox4}
              name="quantitytextbox4"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox3, 1)
              }
              disabled={stbTextInputArray[1].isSnValid ? true : false}
            >
              {"Validate STB 2"}
            </button>
            <Form.Group>
              {!checkifquantitymatches34() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[1].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
          </>
        )}
      </Form.Group>

      <Form.Group>
        {isstbquantity3() && (
          <>
            <label className="txtFieldLabel">
              {"Enter Serial Number for STB 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox1}
              name="quantitytextbox1"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox2}
              name="quantitytextbox2"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox1, 0)
              }
              disabled={stbTextInputArray[0].isSnValid ? true : false}
            >
              {"Validate STB 1"}
            </button>
            <Form.Group>
              {!checkifquantitymatches12() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
              <Form.Group>
                {isSTBchecked() && !stbTextInputArray[0].isSnValid && (
                  <label className="txtFieldLabel">
                    <span>
                      ERROR MESSAGE: The serial number you entered was not found
                      in the inventory system. Please try another piece of
                      equipment &nbsp;
                    </span>
                  </label>
                )}
              </Form.Group>
            </Form.Group>
            <label className="txtFieldLabel">
              {" Enter Serial Number STB 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox3}
              name="quantitytextbox3"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox4}
              name="quantitytextbox4"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox3, 1)
              }
              disabled={stbTextInputArray[1].isSnValid ? true : false}
            >
              {"Validate STB 2"}
            </button>
            <Form.Group>
              {!checkifquantitymatches34() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[1].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <label className="txtFieldLabel">
              {" Enter Serial Number STB 3"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox5}
              name="quantitytextbox5"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 3"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox6}
              name="quantitytextbox6"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox5, 2)
              }
              disabled={stbTextInputArray[2].isSnValid ? true : false}
            >
              {"Validate STB 3"}
            </button>
            <Form.Group>
              {!checkifquantitymatches56() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[2].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
          </>
        )}
      </Form.Group>

      <Form.Group>
        {isstbquantity4() && (
          <>
            <label className="txtFieldLabel">
              {"Enter Serial Number for STB 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox1}
              name="quantitytextbox1"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox2}
              name="quantitytextbox2"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox1, 0)
              }
              disabled={stbTextInputArray[0].isSnValid ? true : false}
            >
              {"Validate STB 1"}
            </button>
            <Form.Group>
              {!checkifquantitymatches12() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[0].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <label className="txtFieldLabel">
              {" Enter Serial Number STB 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox3}
              name="quantitytextbox3"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox4}
              name="quantitytextbox4"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox3, 1)
              }
              disabled={stbTextInputArray[1].isSnValid ? true : false}
            >
              {"Validate STB 2"}
            </button>
            <Form.Group>
              {!checkifquantitymatches12() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[1].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number STB 3"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox5}
              name="quantitytextbox5"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 3"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox6}
              name="quantitytextbox6"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox5, 2)
              }
              disabled={stbTextInputArray[2].isSnValid ? true : false}
            >
              {"Validate STB 3"}
            </button>
            <Form.Group>
              {!checkifquantitymatches56() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[2].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <label className="txtFieldLabel">
              {" Enter Serial Number STB 4"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox7}
              name="quantitytextbox7"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 4"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox8}
              name="quantitytextbox8"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox7, 3)
              }
              disabled={stbTextInputArray[3].isSnValid ? true : false}
            >
              {"Validate STB 4"}
            </button>
            <Form.Group>
              {!checkifquantitymatches78() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[3].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
          </>
        )}
      </Form.Group>

      <Form.Group>
        {isstbquantity5() && (
          <>
            <label className="txtFieldLabel">
              {"Enter Serial Number for STB 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox1}
              name="quantitytextbox1"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox2}
              name="quantitytextbox2"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox1, 0)
              }
              disabled={stbTextInputArray[0].isSnValid ? true : false}
            >
              {"Validate STB 1"}
            </button>
            <Form.Group>
              {!checkifquantitymatches12() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[0].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <label className="txtFieldLabel">
              {" Enter Serial Number STB 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox3}
              name="quantitytextbox3"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox4}
              name="quantitytextbox4"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox3, 1)
              }
              disabled={stbTextInputArray[1].isSnValid ? true : false}
            >
              {"Validate STB 2"}
            </button>
            <Form.Group>
              {!checkifquantitymatches12() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[1].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number STB 3"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox5}
              name="quantitytextbox5"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 3"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox6}
              name="quantitytextbox6"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox5, 2)
              }
              disabled={stbTextInputArray[2].isSnValid ? true : false}
            >
              {"Validate STB 3"}
            </button>
            <Form.Group>
              {!checkifquantitymatches56() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[2].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number STB 4"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox7}
              name="quantitytextbox7"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 4"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox8}
              name="quantitytextbox8"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox7, 3)
              }
              disabled={stbTextInputArray[3].isSnValid ? true : false}
            >
              {"Validate STB 4"}
            </button>
            <Form.Group>
              {!checkifquantitymatches78() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[3].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number STB 5"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox25}
              name="quantitytextbox25"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 5"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox26}
              name="quantitytextbox26"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox25, 4)
              }
              disabled={stbTextInputArray[4].isSnValid ? true : false}
            >
              {"Validate STB 5"}
            </button>
            <Form.Group>
              {!checkifquantitymatches2526() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[4].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
          </>
        )}
      </Form.Group>

      <Form.Group>
        {isstbquantity6() && (
          <>
            <label className="txtFieldLabel">
              {"Enter Serial Number for STB 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox1}
              name="quantitytextbox1"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox2}
              name="quantitytextbox2"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox1, 0)
              }
              disabled={stbTextInputArray[0].isSnValid ? true : false}
            >
              {"Validate STB 1"}
            </button>
            <Form.Group>
              {!checkifquantitymatches12() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[0].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <label className="txtFieldLabel">
              {" Enter Serial Number STB 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox3}
              name="quantitytextbox3"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox4}
              name="quantitytextbox4"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox3, 1)
              }
              disabled={stbTextInputArray[1].isSnValid ? true : false}
            >
              {"Validate STB 2"}
            </button>
            <Form.Group>
              {!checkifquantitymatches12() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[1].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number STB 3"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox5}
              name="quantitytextbox5"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 3"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox6}
              name="quantitytextbox6"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox5, 2)
              }
              disabled={stbTextInputArray[2].isSnValid ? true : false}
            >
              {"Validate STB 3"}
            </button>
            <Form.Group>
              {!checkifquantitymatches56() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[2].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number STB 4"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox7}
              name="quantitytextbox7"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 4"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox8}
              name="quantitytextbox8"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox7, 3)
              }
              disabled={stbTextInputArray[3].isSnValid ? true : false}
            >
              {"Validate STB 4"}
            </button>
            <Form.Group>
              {!checkifquantitymatches78() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[3].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number STB 5"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox25}
              name="quantitytextbox25"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 5"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox26}
              name="quantitytextbox26"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox25, 4)
              }
              disabled={stbTextInputArray[4].isSnValid ? true : false}
            >
              {"Validate STB 5"}
            </button>
            <Form.Group>
              {!checkifquantitymatches2526() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[4].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number STB 6"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox27}
              name="quantitytextbox27"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 6"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox28}
              name="quantitytextbox28"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox27, 5)
              }
              disabled={stbTextInputArray[5].isSnValid ? true : false}
            >
              {"Validate STB 6"}
            </button>
            <Form.Group>
              {!checkifquantitymatches2728() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[5].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
          </>
        )}
      </Form.Group>

      <Form.Group>
        {isstbquantity7() && (
          <>
            <label className="txtFieldLabel">
              {"Enter Serial Number for STB 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox1}
              name="quantitytextbox1"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox2}
              name="quantitytextbox2"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox1, 0)
              }
              disabled={stbTextInputArray[0].isSnValid ? true : false}
            >
              {"Validate STB 1"}
            </button>
            <Form.Group>
              {!checkifquantitymatches12() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[0].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <label className="txtFieldLabel">
              {" Enter Serial Number STB 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox3}
              name="quantitytextbox3"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox4}
              name="quantitytextbox4"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox3, 1)
              }
              disabled={stbTextInputArray[1].isSnValid ? true : false}
            >
              {"Validate STB 2"}
            </button>
            <Form.Group>
              {!checkifquantitymatches12() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[1].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number STB 3"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox5}
              name="quantitytextbox5"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 3"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox6}
              name="quantitytextbox6"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox5, 2)
              }
              disabled={stbTextInputArray[2].isSnValid ? true : false}
            >
              {"Validate STB 3"}
            </button>
            <Form.Group>
              {!checkifquantitymatches56() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[2].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number STB 4"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox7}
              name="quantitytextbox7"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 4"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox8}
              name="quantitytextbox8"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox7, 3)
              }
              disabled={stbTextInputArray[3].isSnValid ? true : false}
            >
              {"Validate STB 4"}
            </button>
            <Form.Group>
              {!checkifquantitymatches78() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[3].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number STB 5"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox25}
              name="quantitytextbox25"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 5"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox26}
              name="quantitytextbox26"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox25, 4)
              }
              disabled={stbTextInputArray[4].isSnValid ? true : false}
            >
              {"Validate STB 5"}
            </button>

            <Form.Group>
              {!checkifquantitymatches2526() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[4].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number STB 6"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox27}
              name="quantitytextbox27"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 6"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox28}
              name="quantitytextbox28"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox27, 5)
              }
              disabled={stbTextInputArray[5].isSnValid ? true : false}
            >
              {"Validate STB 6"}
            </button>
            <Form.Group>
              {!checkifquantitymatches2728() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[5].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number STB 7"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox29}
              name="quantitytextbox29"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 7"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox30}
              name="quantitytextbox30"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox29, 6)
              }
              disabled={stbTextInputArray[6].isSnValid ? true : false}
            >
              {"Validate STB 7"}
            </button>
            <Form.Group>
              {!checkifquantitymatches2930() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[6].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
          </>
        )}
      </Form.Group>

      <Form.Group>
        {isstbquantity8() && (
          <>
            <label className="txtFieldLabel">
              {"Enter Serial Number for STB 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox1}
              name="quantitytextbox1"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox2}
              name="quantitytextbox2"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox1, 0)
              }
              disabled={stbTextInputArray[0].isSnValid ? true : false}
            >
              {"Validate STB 1"}
            </button>
            <Form.Group>
              {!checkifquantitymatches12() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[0].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <label className="txtFieldLabel">
              {" Enter Serial Number STB 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox3}
              name="quantitytextbox3"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox4}
              name="quantitytextbox4"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox3, 1)
              }
              disabled={stbTextInputArray[1].isSnValid ? true : false}
            >
              {"Validate STB 2"}
            </button>
            <Form.Group>
              {!checkifquantitymatches12() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[1].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number STB 3"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox5}
              name="quantitytextbox5"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 3"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox6}
              name="quantitytextbox6"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox5, 2)
              }
              disabled={stbTextInputArray[2].isSnValid ? true : false}
            >
              {"Validate STB 3"}
            </button>
            <Form.Group>
              {!checkifquantitymatches56() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[2].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number STB 4"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox7}
              name="quantitytextbox7"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 4"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox8}
              name="quantitytextbox8"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox7, 3)
              }
              disabled={stbTextInputArray[3].isSnValid ? true : false}
            >
              {"Validate STB 4"}
            </button>
            <Form.Group>
              {!checkifquantitymatches78() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[3].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number STB 5"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox25}
              name="quantitytextbox25"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 5"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox26}
              name="quantitytextbox26"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox25, 4)
              }
              disabled={stbTextInputArray[4].isSnValid ? true : false}
            >
              {"Validate STB 5"}
            </button>
            <Form.Group>
              {!checkifquantitymatches2526() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[4].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number STB 6"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox27}
              name="quantitytextbox27"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 6"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox28}
              name="quantitytextbox28"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox27, 5)
              }
              disabled={stbTextInputArray[5].isSnValid ? true : false}
            >
              {"Validate STB 6"}
            </button>
            <Form.Group>
              {!checkifquantitymatches2728() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[5].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number STB 7"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox29}
              name="quantitytextbox29"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 7"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox30}
              name="quantitytextbox30"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox29, 6)
              }
              disabled={stbTextInputArray[6].isSnValid ? true : false}
            >
              {"Validate STB 7"}
            </button>
            <Form.Group>
              {!checkifquantitymatches2930() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[6].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <label className="txtFieldLabel">
              {" Enter Serial Number STB 8"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox31}
              name="quantitytextbox31"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 8"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox32}
              name="quantitytextbox32"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox31, 7)
              }
              disabled={stbTextInputArray[7].isSnValid ? true : false}
            >
              {"Validate STB 8"}
            </button>
            <Form.Group>
              {!checkifquantitymatches3132() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[7].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
          </>
        )}
      </Form.Group>

      <Form.Group>
        {isstbquantity9() && (
          <>
            <label className="txtFieldLabel">
              {"Enter Serial Number for STB 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox1}
              name="quantitytextbox1"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox2}
              name="quantitytextbox2"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox1, 0)
              }
              disabled={stbTextInputArray[0].isSnValid ? true : false}
            >
              {"Validate STB 1"}
            </button>
            <Form.Group>
              {!checkifquantitymatches12() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[0].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <label className="txtFieldLabel">
              {" Enter Serial Number STB 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox3}
              name="quantitytextbox3"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox4}
              name="quantitytextbox4"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox3, 1)
              }
              disabled={stbTextInputArray[1].isSnValid ? true : false}
            >
              {"Validate STB 2"}
            </button>
            <Form.Group>
              {!checkifquantitymatches12() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[1].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number STB 3"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox5}
              name="quantitytextbox5"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 3"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox6}
              name="quantitytextbox6"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox5, 2)
              }
              disabled={stbTextInputArray[2].isSnValid ? true : false}
            >
              {"Validate STB 3"}
            </button>
            <Form.Group>
              {!checkifquantitymatches56() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[2].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number STB 4"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox7}
              name="quantitytextbox7"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 4"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox8}
              name="quantitytextbox8"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox7, 3)
              }
              disabled={stbTextInputArray[3].isSnValid ? true : false}
            >
              {"Validate STB 4"}
            </button>
            <Form.Group>
              {!checkifquantitymatches78() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[3].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number STB 5"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox25}
              name="quantitytextbox25"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 5"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox26}
              name="quantitytextbox26"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox25, 4)
              }
              disabled={stbTextInputArray[4].isSnValid ? true : false}
            >
              {"Validate STB 5"}
            </button>
            <Form.Group>
              {!checkifquantitymatches2526() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[4].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number STB 6"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox27}
              name="quantitytextbox27"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 6"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox28}
              name="quantitytextbox28"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox27, 5)
              }
              disabled={stbTextInputArray[5].isSnValid ? true : false}
            >
              {"Validate STB 6"}
            </button>
            <Form.Group>
              {!checkifquantitymatches2728() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[5].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number STB 7"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox29}
              name="quantitytextbox29"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 7"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox30}
              name="quantitytextbox30"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox29, 6)
              }
              disabled={stbTextInputArray[6].isSnValid ? true : false}
            >
              {"Validate STB 7"}
            </button>
            <Form.Group>
              {!checkifquantitymatches2930() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[6].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <label className="txtFieldLabel">
              {" Enter Serial Number STB 8"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox31}
              name="quantitytextbox31"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 8"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox32}
              name="quantitytextbox32"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox31, 7)
              }
              disabled={stbTextInputArray[7].isSnValid ? true : false}
            >
              {"Validate STB 8"}
            </button>
            <Form.Group>
              {!checkifquantitymatches3132() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[7].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <label className="txtFieldLabel">
              {" Enter Serial Number STB 9"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox33}
              name="quantitytextbox33"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 9"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox34}
              name="quantitytextbox34"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox33, 8)
              }
              disabled={stbTextInputArray[8].isSnValid ? true : false}
            >
              {"Validate STB 9"}
            </button>
            <Form.Group>
              {!checkifquantitymatches3334() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[8].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
          </>
        )}
      </Form.Group>

      <Form.Group>
        {isstbquantity10() && (
          <>
            <label className="txtFieldLabel">
              {"Enter Serial Number for STB 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox1}
              name="quantitytextbox1"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox2}
              name="quantitytextbox2"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox1, 0)
              }
              disabled={stbTextInputArray[0].isSnValid ? true : false}
            >
              {"Validate STB 1"}
            </button>
            <Form.Group>
              {!checkifquantitymatches12() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[0].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <label className="txtFieldLabel">
              {" Enter Serial Number STB 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox3}
              name="quantitytextbox3"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox4}
              name="quantitytextbox4"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox3, 1)
              }
              disabled={stbTextInputArray[1].isSnValid ? true : false}
            >
              {"Validate STB 2"}
            </button>
            <Form.Group>
              {!checkifquantitymatches12() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[1].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number STB 3"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox5}
              name="quantitytextbox5"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 3"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox6}
              name="quantitytextbox6"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox5, 2)
              }
              disabled={stbTextInputArray[2].isSnValid ? true : false}
            >
              {"Validate STB 3"}
            </button>
            <Form.Group>
              {!checkifquantitymatches56() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[2].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number STB 4"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox7}
              name="quantitytextbox7"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 4"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox8}
              name="quantitytextbox8"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox7, 3)
              }
              disabled={stbTextInputArray[3].isSnValid ? true : false}
            >
              {"Validate STB 4"}
            </button>
            <Form.Group>
              {!checkifquantitymatches78() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[3].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <label className="txtFieldLabel">
              {" Enter Serial Number STB 5"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox25}
              name="quantitytextbox25"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 5"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox26}
              name="quantitytextbox26"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox25, 4)
              }
              disabled={stbTextInputArray[4].isSnValid ? true : false}
            >
              {"Validate STB 5"}
            </button>
            <Form.Group>
              {!checkifquantitymatches2526() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[4].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number STB 6"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox27}
              name="quantitytextbox27"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 6"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox28}
              name="quantitytextbox28"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox27, 5)
              }
              disabled={stbTextInputArray[5].isSnValid ? true : false}
            >
              {"Validate STB 6"}
            </button>
            <Form.Group>
              {!checkifquantitymatches2728() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[5].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number STB 7"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox29}
              name="quantitytextbox29"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 7"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox30}
              name="quantitytextbox30"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox29, 6)
              }
              disabled={stbTextInputArray[6].isSnValid ? true : false}
            >
              {"Validate STB 7"}
            </button>
            <Form.Group>
              {!checkifquantitymatches2930() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[6].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <label className="txtFieldLabel">
              {" Enter Serial Number STB 8"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox31}
              name="quantitytextbox31"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 8"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox32}
              name="quantitytextbox32"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox31, 7)
              }
              disabled={stbTextInputArray[7].isSnValid ? true : false}
            >
              {"Validate STB 8"}
            </button>
            <Form.Group>
              {!checkifquantitymatches3132() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[7].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <label className="txtFieldLabel">
              {" Enter Serial Number STB 9"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox33}
              name="quantitytextbox33"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 9"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox34}
              name="quantitytextbox34"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox33, 8)
              }
              disabled={stbTextInputArray[8].isSnValid ? true : false}
            >
              {"Validate STB 9"}
            </button>
            <Form.Group>
              {!checkifquantitymatches3334() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[8].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <label className="txtFieldLabel">
              {" Enter Serial Number STB 10"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox35}
              name="quantitytextbox35"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number STB 10"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={16}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox36}
              name="quantitytextbox36"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.STB, detailsObj?.quantitytextbox35, 9)
              }
              disabled={stbTextInputArray[9].isSnValid ? true : false}
            >
              {"Validate STB 10"}
            </button>
            <Form.Group>
              {!checkifquantitymatches3536() && isSTBchecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isSTBchecked() && !stbTextInputArray[9].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
          </>
        )}
      </Form.Group>

      {isSTBchecked() && isPaidPodChecked() && !isFreePodChecked() && (
        <span className="DropdownContainer">
          <label className="txtFieldLabel">{"STB Quantity"}</label>
          <DropdownButton
            title={detailsObj?.stbquantity}
            onSelect={(v: any) => handleRadioDropdownChange("stbquantity", v)}
            size="sm"
            id="dropdownBtn"
            style={{ width: "30px" }}
          >
            <Dropdown.Item key={0} eventKey={"1"}>
              1
            </Dropdown.Item>
            <Dropdown.Item key={1} eventKey={"2"}>
              2
            </Dropdown.Item>
            <Dropdown.Item key={2} eventKey={"3"}>
              3
            </Dropdown.Item>
            <Dropdown.Item key={3} eventKey={"4"}>
              4
            </Dropdown.Item>
            <Dropdown.Item key={4} eventKey={"5"}>
              5
            </Dropdown.Item>
            <Dropdown.Item key={5} eventKey={"6"}>
              6
            </Dropdown.Item>
            <Dropdown.Item key={6} eventKey={"7"}>
              7
            </Dropdown.Item>
            <Dropdown.Item key={7} eventKey={"8"}>
              8
            </Dropdown.Item>
            <Dropdown.Item key={8} eventKey={"9"}>
              9
            </Dropdown.Item>
            <Dropdown.Item key={9} eventKey={"10"}>
              10
            </Dropdown.Item>
          </DropdownButton>
        </span>
      )}
      {isSTBchecked() && !isPaidPodChecked() && isFreePodChecked() && (
        <span className="DropdownContainer">
          <label className="txtFieldLabel">{"STB Quantity"}</label>
          <DropdownButton
            title={detailsObj?.stbquantity}
            onSelect={(v: any) => handleRadioDropdownChange("stbquantity", v)}
            size="sm"
            id="dropdownBtn"
            style={{ width: "30px" }}
          >
            <Dropdown.Item key={0} eventKey={"1"}>
              1
            </Dropdown.Item>
            <Dropdown.Item key={1} eventKey={"2"}>
              2
            </Dropdown.Item>
            <Dropdown.Item key={2} eventKey={"3"}>
              3
            </Dropdown.Item>
            <Dropdown.Item key={3} eventKey={"4"}>
              4
            </Dropdown.Item>
            <Dropdown.Item key={4} eventKey={"5"}>
              5
            </Dropdown.Item>
            <Dropdown.Item key={5} eventKey={"6"}>
              6
            </Dropdown.Item>
            <Dropdown.Item key={6} eventKey={"7"}>
              7
            </Dropdown.Item>
            <Dropdown.Item key={7} eventKey={"8"}>
              8
            </Dropdown.Item>
            <Dropdown.Item key={8} eventKey={"9"}>
              9
            </Dropdown.Item>
            <Dropdown.Item key={9} eventKey={"10"}>
              10
            </Dropdown.Item>
          </DropdownButton>
        </span>
      )}
      {isSTBchecked() && isPaidPodChecked() && isFreePodChecked() && (
        <span className="DropdownContainer">
          <label className="txtFieldLabel">{"STB Quantity"}</label>
          <DropdownButton
            title={detailsObj?.stbquantity}
            onSelect={(v: any) => handleRadioDropdownChange("stbquantity", v)}
            size="sm"
            id="dropdownBtn"
            style={{ width: "30px" }}
          >
            <Dropdown.Item key={0} eventKey={"1"}>
              1
            </Dropdown.Item>
            <Dropdown.Item key={1} eventKey={"2"}>
              2
            </Dropdown.Item>
            <Dropdown.Item key={2} eventKey={"3"}>
              3
            </Dropdown.Item>
            <Dropdown.Item key={3} eventKey={"4"}>
              4
            </Dropdown.Item>
            <Dropdown.Item key={4} eventKey={"5"}>
              5
            </Dropdown.Item>
            <Dropdown.Item key={5} eventKey={"6"}>
              6
            </Dropdown.Item>
            <Dropdown.Item key={6} eventKey={"7"}>
              7
            </Dropdown.Item>
            <Dropdown.Item key={7} eventKey={"8"}>
              8
            </Dropdown.Item>
            <Dropdown.Item key={8} eventKey={"9"}>
              9
            </Dropdown.Item>
            <Dropdown.Item key={9} eventKey={"10"}>
              10
            </Dropdown.Item>
          </DropdownButton>
        </span>
      )}

      {!isSTBchecked() && isFreePodChecked() && isPaidPodChecked() && (
        <span className="DropdownContainer">
          <label className="txtFieldLabel">{"Free pod quantity"}</label>
          <DropdownButton
            title={detailsObj?.freepodquantity}
            onSelect={(v: any) =>
              handleRadioDropdownChange("freepodquantity", v)
            }
            size="sm"
            id="dropdownBtn"
            style={{ width: "30px" }}
          >
            <Dropdown.Item key={0} eventKey={"1"}>
              1
            </Dropdown.Item>
            <Dropdown.Item key={1} eventKey={"2"}>
              2
            </Dropdown.Item>
            <Dropdown.Item key={2} eventKey={"3"}>
              3
            </Dropdown.Item>
            <Dropdown.Item key={3} eventKey={"4"}>
              4
            </Dropdown.Item>
          </DropdownButton>
        </span>
      )}

      {isSTBchecked() && isFreePodChecked() && !isPaidPodChecked() && (
        <span className="DropdownContainer">
          <label className="txtFieldLabel">{"Free pod quantity"}</label>
          <DropdownButton
            title={detailsObj?.freepodquantity}
            onSelect={(v: any) =>
              handleRadioDropdownChange("freepodquantity", v)
            }
            size="sm"
            id="dropdownBtn"
            style={{ width: "30px" }}
          >
            <Dropdown.Item key={0} eventKey={"1"}>
              1
            </Dropdown.Item>
            <Dropdown.Item key={1} eventKey={"2"}>
              2
            </Dropdown.Item>
            <Dropdown.Item key={2} eventKey={"3"}>
              3
            </Dropdown.Item>
            <Dropdown.Item key={3} eventKey={"4"}>
              4
            </Dropdown.Item>
          </DropdownButton>
        </span>
      )}

      {!isSTBchecked() && isFreePodChecked() && !isPaidPodChecked() && (
        <span className="DropdownContainer">
          <label className="txtFieldLabel">{"Free pod quantity"}</label>
          <DropdownButton
            title={detailsObj?.freepodquantity}
            onSelect={(v: any) =>
              handleRadioDropdownChange("freepodquantity", v)
            }
            size="sm"
            id="dropdownBtn"
            style={{ width: "30px" }}
          >
            <Dropdown.Item key={0} eventKey={"1"}>
              1
            </Dropdown.Item>
            <Dropdown.Item key={1} eventKey={"2"}>
              2
            </Dropdown.Item>
            <Dropdown.Item key={2} eventKey={"3"}>
              3
            </Dropdown.Item>
            <Dropdown.Item key={3} eventKey={"4"}>
              4
            </Dropdown.Item>
          </DropdownButton>
        </span>
      )}

      <Form.Group>
        {isfreepodquantity1() && (
          <>
            <label className="txtFieldLabel">
              {"Enter Serial Number"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox9}
              name="quantitytextbox9"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox10}
              name="quantitytextbox10"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.FREE_POD, detailsObj?.quantitytextbox9, 0)
              }
              disabled={freePodTextInputArray[0].isSnValid ? true : false}
            >
              {"Validate Free Pod 1"}
            </button>
            <Form.Group>
              {!checkifquantitymatches910() && isFreePodChecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isFreePodChecked() && !freePodTextInputArray[0].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
          </>
        )}
      </Form.Group>

      <Form.Group>
        {isfreepodquantity2() && (
          <>
            <label className="txtFieldLabel">
              {"Enter Serial Number for Free Pod 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox9}
              name="quantitytextbox9"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number Free Pod 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox10}
              name="quantitytextbox10"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.FREE_POD, detailsObj?.quantitytextbox9, 0)
              }
              disabled={freePodTextInputArray[0].isSnValid ? true : false}
            >
              {"Validate Free Pod 1"}
            </button>

            <Form.Group>
              {!checkifquantitymatches910() && isFreePodChecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isFreePodChecked() && !freePodTextInputArray[0].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number Free Pod 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbo11}
              name="quantitytextbox11"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number Free Pod 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox12}
              name="quantitytextbox12"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.FREE_POD, detailsObj?.quantitytextbox11, 1)
              }
              disabled={freePodTextInputArray[1].isSnValid ? true : false}
            >
              {"Validate Free Pod 2"}
            </button>
            <Form.Group>
              {!checkifquantitymatches1112() && isFreePodChecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isFreePodChecked() && !freePodTextInputArray[1].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
          </>
        )}
      </Form.Group>

      <Form.Group>
        {isfreepodquantity3() && (
          <>
            <label className="txtFieldLabel">
              {"Enter Serial Number for Free Pod 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox9}
              name="quantitytextbox9"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number Free Pod 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox10}
              name="quantitytextbox10"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.FREE_POD, detailsObj?.quantitytextbox9, 0)
              }
              disabled={freePodTextInputArray[0].isSnValid ? true : false}
            >
              {"Validate Free Pod 1"}
            </button>
            <Form.Group>
              {!checkifquantitymatches910() && isFreePodChecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isFreePodChecked() && !freePodTextInputArray[0].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <label className="txtFieldLabel">
              {" Enter Serial Number Free Pod 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox11}
              name="quantitytextbox11"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number Free Pod 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox12}
              name="quantitytextbox12"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.FREE_POD, detailsObj?.quantitytextbox11, 1)
              }
              disabled={freePodTextInputArray[1].isSnValid ? true : false}
            >
              {"Validate Free Pod 2"}
            </button>
            <Form.Group>
              {!checkifquantitymatches1112() && isFreePodChecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isFreePodChecked() && !freePodTextInputArray[1].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number Free Pod 3"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox13}
              name="quantitytextbox13"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number Free Pod 3"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox14}
              name="quantitytextbox14"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.FREE_POD, detailsObj?.quantitytextbox13, 2)
              }
              disabled={freePodTextInputArray[2].isSnValid ? true : false}
            >
              {"Validate Free Pod 3"}
            </button>
            <Form.Group>
              {!checkifquantitymatches1314() && isFreePodChecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isFreePodChecked() && !freePodTextInputArray[2].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
          </>
        )}
      </Form.Group>

      <Form.Group>
        {isfreepodquantity4() && (
          <>
            <label className="txtFieldLabel">
              {"Enter Serial Number for Free Pod 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox9}
              name="quantitytextbox9"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number Free Pod 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox10}
              name="quantitytextbox10"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.FREE_POD, detailsObj?.quantitytextbox9, 0)
              }
              disabled={freePodTextInputArray[0].isSnValid ? true : false}
            >
              {"Validate Free Pod 1"}
            </button>
            <Form.Group>
              {!checkifquantitymatches910() && isFreePodChecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isFreePodChecked() && !freePodTextInputArray[0].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number Free Pod 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox11}
              name="quantitytextbox11"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number Free Pod 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox12}
              name="quantitytextbox12"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.FREE_POD, detailsObj?.quantitytextbox11, 1)
              }
              disabled={freePodTextInputArray[1].isSnValid ? true : false}
            >
              {"Validate Free Pod 2"}
            </button>
            <Form.Group>
              {!checkifquantitymatches1112() && isFreePodChecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isFreePodChecked() && !freePodTextInputArray[1].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <label className="txtFieldLabel">
              {" Enter Serial Number Free Pod 3"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox13}
              name="quantitytextbox13"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number Free Pod 3"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox14}
              name="quantitytextbox14"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.FREE_POD, detailsObj?.quantitytextbox13, 2)
              }
              disabled={freePodTextInputArray[2].isSnValid ? true : false}
            >
              {"Validate Free Pod 3"}
            </button>
            <Form.Group>
              {!checkifquantitymatches1314() && isFreePodChecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isFreePodChecked() && !freePodTextInputArray[2].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <label className="txtFieldLabel">
              {" Enter Serial Number Free Pod 4"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox15}
              name="quantitytextbox15"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number Free Pod 4"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox16}
              name="quantitytextbox16"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.FREE_POD, detailsObj?.quantitytextbox15, 3)
              }
              disabled={freePodTextInputArray[3].isSnValid ? true : false}
            >
              {"Validate Free Pod 4"}
            </button>
            <Form.Group>
              {!checkifquantitymatches1516() && isFreePodChecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isFreePodChecked() && !freePodTextInputArray[3].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
          </>
        )}
      </Form.Group>

      {isSTBchecked() && isFreePodChecked() && isPaidPodChecked() && (
        <span className="DropdownContainer">
          <label className="txtFieldLabel">{"Free pod quantity"}</label>
          <DropdownButton
            title={detailsObj?.freepodquantity}
            onSelect={(v: any) =>
              handleRadioDropdownChange("freepodquantity", v)
            }
            size="sm"
            id="dropdownBtn"
            style={{ width: "30px" }}
          >
            <Dropdown.Item key={0} eventKey={"1"}>
              1
            </Dropdown.Item>
            <Dropdown.Item key={1} eventKey={"2"}>
              2
            </Dropdown.Item>
            <Dropdown.Item key={2} eventKey={"3"}>
              3
            </Dropdown.Item>
            <Dropdown.Item key={3} eventKey={"4"}>
              4
            </Dropdown.Item>
          </DropdownButton>
        </span>
      )}
      {!isSTBchecked() && !isFreePodChecked() && isPaidPodChecked() && (
        <span className="DropdownContainer">
          <label className="txtFieldLabel">{"Paid pod quantity"}</label>
          <DropdownButton
            title={detailsObj?.paidpodquantity}
            onSelect={(v: any) =>
              handleRadioDropdownChange("paidpodquantity", v)
            }
            size="sm"
            id="dropdownBtn"
            style={{ width: "30px" }}
          >
            <Dropdown.Item key={0} eventKey={"1"}>
              1
            </Dropdown.Item>
            <Dropdown.Item key={1} eventKey={"2"}>
              2
            </Dropdown.Item>
            <Dropdown.Item key={2} eventKey={"3"}>
              3
            </Dropdown.Item>
            <Dropdown.Item key={3} eventKey={"4"}>
              4
            </Dropdown.Item>
          </DropdownButton>
        </span>
      )}

      <Form.Group>
        {ispaidpodquantity1() && (
          <>
            <label className="txtFieldLabel">
              {"Enter Serial Number"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox17}
              name="quantitytextbox17"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox18}
              name="quantitytextbox18"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.PAID_POD, detailsObj?.quantitytextbox17, 0)
              }
              disabled={paidPodTextInputArray[0].isSnValid ? true : false}
            >
              {"Validate Paid Pod 1"}
            </button>
            <Form.Group>
              {!checkifquantitymatches1718() && isPaidPodChecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isPaidPodChecked() && !paidPodTextInputArray[0].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
          </>
        )}
      </Form.Group>

      <Form.Group>
        {ispaidpodquantity2() && (
          <>
            <label className="txtFieldLabel">
              {"Enter Serial Number for Paid Pod 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox17}
              name="quantitytextbox17"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number Paid Pod 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox18}
              name="quantitytextbox18"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.PAID_POD, detailsObj?.quantitytextbox17, 0)
              }
              disabled={paidPodTextInputArray[0].isSnValid ? true : false}
            >
              {"Validate Paid Pod 1"}
            </button>
            <Form.Group>
              {!checkifquantitymatches1718() && isPaidPodChecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isPaidPodChecked() && !paidPodTextInputArray[0].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <label className="txtFieldLabel">
              {" Enter Serial Number Paid Pod 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox19}
              name="quantitytextbox19"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number Paid Pod 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox20}
              name="quantitytextbox20"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.PAID_POD, detailsObj?.quantitytextbox19, 1)
              }
              disabled={paidPodTextInputArray[1].isSnValid ? true : false}
            >
              {"Validate Paid Pod 2"}
            </button>
            <Form.Group>
              {!checkifquantitymatches1920() && isPaidPodChecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isPaidPodChecked() && !paidPodTextInputArray[1].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
          </>
        )}
      </Form.Group>

      <Form.Group>
        {ispaidpodquantity3() && (
          <>
            <label className="txtFieldLabel">
              {"Enter Serial Number for Paid Pod 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox17}
              name="quantitytextbox17"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number Paid Pod 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox18}
              name="quantitytextbox18"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.PAID_POD, detailsObj?.quantitytextbox17, 0)
              }
              disabled={paidPodTextInputArray[0].isSnValid ? true : false}
            >
              {"Validate Paid Pod 1"}
            </button>
            <Form.Group>
              {!checkifquantitymatches1718() && isPaidPodChecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isPaidPodChecked() && !paidPodTextInputArray[0].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number Paid Pod 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox19}
              name="quantitytextbox19"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number Paid Pod 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox20}
              name="quantitytextbox20"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.PAID_POD, detailsObj?.quantitytextbox19, 1)
              }
              disabled={paidPodTextInputArray[1].isSnValid ? true : false}
            >
              {"Validate Paid Pod 2"}
            </button>
            <Form.Group>
              {!checkifquantitymatches1920() && isPaidPodChecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isPaidPodChecked() && !paidPodTextInputArray[1].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number Paid Pod 3"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox21}
              name="quantitytextbox21"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number Paid Pod 3"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox22}
              name="quantitytextbox22"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.PAID_POD, detailsObj?.quantitytextbox21, 2)
              }
              disabled={paidPodTextInputArray[2].isSnValid ? true : false}
            >
              {"Validate Paid Pod 3"}
            </button>
            <Form.Group>
              {!checkifquantitymatches2122() && isPaidPodChecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isPaidPodChecked() && !paidPodTextInputArray[2].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
          </>
        )}
      </Form.Group>

      <Form.Group>
        {ispaidpodquantity4() && (
          <>
            <label className="txtFieldLabel">
              {"Enter Serial Number for Paid Pod 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox17}
              name="quantitytextbox17"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number Paid Pod 1"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox18}
              name="quantitytextbox18"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.PAID_POD, detailsObj?.quantitytextbox17, 0)
              }
              disabled={paidPodTextInputArray[0].isSnValid ? true : false}
            >
              {"Validate Paid Pod 1"}
            </button>
            <Form.Group>
              {!checkifquantitymatches1718() && isPaidPodChecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isPaidPodChecked() && !paidPodTextInputArray[0].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <label className="txtFieldLabel">
              {" Enter Serial Number Paid Pod 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox19}
              name="quantitytextbox19"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number Paid Pod 2"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox20}
              name="quantitytextbox20"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.PAID_POD, detailsObj?.quantitytextbox19, 1)
              }
              disabled={paidPodTextInputArray[1].isSnValid ? true : false}
            >
              {"Validate Paid Pod 2"}
            </button>
            <Form.Group>
              {!checkifquantitymatches1920() && isPaidPodChecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isPaidPodChecked() && !paidPodTextInputArray[1].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>

            <label className="txtFieldLabel">
              {" Enter Serial Number Paid Pod 3"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox21}
              name="quantitytextbox21"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number Paid Pod 3"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox22}
              name="quantitytextbox22"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.PAID_POD, detailsObj?.quantitytextbox21, 2)
              }
              disabled={paidPodTextInputArray[2].isSnValid ? true : false}
            >
              {"Validate Paid Pod 3"}
            </button>
            <Form.Group>
              {!checkifquantitymatches2122() && isPaidPodChecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isPaidPodChecked() && !paidPodTextInputArray[2].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <label className="txtFieldLabel">
              {" Enter Serial Number Paid Pod 4"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox23}
              name="quantitytextbox23"
            />

            <label className="txtFieldLabel">
              {"Re Enter Serial Number Paid Pod 4"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e: any) => {
                e.preventDefault();
                return false;
              }}
              rows={1}
              maxLength={12}
              onChange={handleChange}
              value={detailsObj?.quantitytextbox24}
              name="quantitytextbox24"
            />
            <button
              onClick={() =>
                onVerifyClick(labels.PAID_POD, detailsObj?.quantitytextbox23, 3)
              }
              disabled={paidPodTextInputArray[3].isSnValid ? true : false}
            >
              {"Validate Paid Pod 4"}
            </button>
            <Form.Group>
              {!checkifquantitymatches2324() && isPaidPodChecked() && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number does not match &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
            <Form.Group>
              {isPaidPodChecked() && !paidPodTextInputArray[3].isSnValid && (
                <label className="txtFieldLabel">
                  <span>
                    ERROR MESSAGE: The serial number you entered was not found
                    in the inventory system. Please try another piece of
                    equipment! Please call Rssg &nbsp;
                  </span>
                </label>
              )}
            </Form.Group>
          </>
        )}
      </Form.Group>

      {isSTBchecked() && !isFreePodChecked() && isPaidPodChecked() && (
        <span className="DropdownContainer">
          <label className="txtFieldLabel">{"Paid pod quantity"}</label>
          <DropdownButton
            title={detailsObj?.paidpodquantity}
            onSelect={(v: any) =>
              handleRadioDropdownChange("paidpodquantity", v)
            }
            size="sm"
            id="dropdownBtn"
            style={{ width: "30px" }}
          >
            <Dropdown.Item key={0} eventKey={"1"}>
              1
            </Dropdown.Item>
            <Dropdown.Item key={1} eventKey={"2"}>
              2
            </Dropdown.Item>
            <Dropdown.Item key={2} eventKey={"3"}>
              3
            </Dropdown.Item>
            <Dropdown.Item key={3} eventKey={"4"}>
              4
            </Dropdown.Item>
          </DropdownButton>
        </span>
      )}

      {!isSTBchecked() && isFreePodChecked() && isPaidPodChecked() && (
        <span className="DropdownContainer">
          <label className="txtFieldLabel">{"Paid pod quantity"}</label>
          <DropdownButton
            title={detailsObj?.paidpodquantity}
            onSelect={(v: any) =>
              handleRadioDropdownChange("paidpodquantity", v)
            }
            size="sm"
            id="dropdownBtn"
            style={{ width: "30px" }}
          >
            <Dropdown.Item key={0} eventKey={"1"}>
              1
            </Dropdown.Item>
            <Dropdown.Item key={1} eventKey={"2"}>
              2
            </Dropdown.Item>
            <Dropdown.Item key={2} eventKey={"3"}>
              3
            </Dropdown.Item>
            <Dropdown.Item key={3} eventKey={"4"}>
              4
            </Dropdown.Item>
          </DropdownButton>
        </span>
      )}

      {isSTBchecked() && isFreePodChecked() && isPaidPodChecked() && (
        <span className="DropdownContainer">
          <label className="txtFieldLabel">{"Paid pod quantity"}</label>
          <DropdownButton
            title={detailsObj?.paidpodquantity}
            onSelect={(v: any) =>
              handleRadioDropdownChange("paidpodquantity", v)
            }
            size="sm"
            id="dropdownBtn"
            style={{ width: "30px" }}
          >
            <Dropdown.Item key={0} eventKey={"1"}>
              1
            </Dropdown.Item>
            <Dropdown.Item key={1} eventKey={"2"}>
              2
            </Dropdown.Item>
            <Dropdown.Item key={2} eventKey={"3"}>
              3
            </Dropdown.Item>
            <Dropdown.Item key={3} eventKey={"4"}>
              4
            </Dropdown.Item>
          </DropdownButton>
        </span>
      )}
      {!isSTBchecked() && isFreePodChecked() && !isPaidPodChecked() && (
        <span className="DropdownContainer">
          <label className="txtFieldLabel">{"Free Pod Reasoning"}</label>
          <DropdownButton
            title={detailsObj?.freepodreasoning}
            onSelect={(v: any) =>
              handleRadioDropdownChange("freepodreasoning", v)
            }
            size="sm"
            id="dropdownBtn"
            style={{ width: "30px" }}
          >
            <Dropdown.Item key={0} eventKey={"Wifi Satisfaction Guarantee"}>
              Wifi Satisfaction Guarantee
            </Dropdown.Item>
            <Dropdown.Item key={1} eventKey={"Other"}>
              Other
            </Dropdown.Item>
          </DropdownButton>
        </span>
      )}
      <Form.Group>
        {isFreeReasoningneeded() && isFreePodChecked() && (
          <>
            <label className="txtFieldLabel">
              {"Mandatory Comments"}
              {<RedAsterisk />}
            </label>
            <Form.Control
              as="textarea"
              rows={3}
              maxLength={200}
              onChange={handleChange}
              value={detailsObj?.freepodreasoningcomments}
              name="freepodreasoningcomments"
            />
          </>
        )}
      </Form.Group>

      {isSTBchecked() && !isFreePodChecked() && !isPaidPodChecked() && (
        <label className="txtFieldLabel">
          <span> Pricing plan for STB: $10/month</span>

          <br />
          <span>
            Pricing plan for Ignite Wifi Pod(Gen 2) Rental: $5/month per pod(2
            for $10){" "}
          </span>
        </label>
      )}

      {!isSTBchecked() && !isFreePodChecked() && isPaidPodChecked() && (
        <label className="txtFieldLabel">
          <span style={{ color: "red" }}> Pricing plan for STB:</span>
          <span>$10/month </span>
          <br />
          <span style={{ color: "red" }}>
            Pricing plan for Ignite Wifi Pod(Gen 2) Rental:{" "}
          </span>
          <span> $5/month per pod(2 for $10)</span>
        </label>
      )}
      {isSTBchecked() && !isFreePodChecked() && isPaidPodChecked() && (
        <label className="txtFieldLabel">
          <span style={{ color: "red" }}> Pricing plan for STB:</span>
          <span>$10/month </span>
          <br />
          <span style={{ color: "red" }}>
            Pricing plan for Ignite Wifi Pod(Gen 2) Rental:{" "}
          </span>
          <span> $5/month per pod(2 for $10)</span>
        </label>
      )}

      <label className="labelTxt">
        {"Customer's Acknowledgememnt"}
        <RedAsterisk />
      </label>
      <div className="checkboxContainer">
        <label>{acknowledgment === true ? "Yes" : "No"}</label>
        <input
          type="checkbox"
          checked={acknowledgment}
          style={{ width: "25px", height: "25px" }}
          onChange={(event) => ackUpdate(event.target.checked)}
        />
      </div>
    </div>
  );
};

export default FirstHalf;
