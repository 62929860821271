import { MultiDeviceNodeTeleTab } from "./MultiDeviceNodeTeleTab";

export function MultiDeviceNodeTab(props: any) {
  const {
    data,
    selectdSmt,
    tableAttr,
    dataSetNames,
    dataSetType,
    exportDeviceId,
    exportDeviceType,
  } = props;

  return (
    <>
      <MultiDeviceNodeTeleTab
        tableAttr={tableAttr}
        data={data}
        selectdSmt={selectdSmt}
        startDiag={props.startDiag}
        dataSetType={dataSetType}
        dataSetNames={dataSetNames}
        exportDeviceId={exportDeviceId}
        exportDeviceType={exportDeviceType}
        refreshNodeModemList={props.refreshNodeModemList}
        handleFlapHistory={props?.handleFlapHistory}
        selectedMac={props?.selectedMac}
      />
    </>
  );
}
