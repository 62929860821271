export const GET_ALL_CERTIFICATIONS_BEGIN = "GET_ALL_CERTIFICATIONS_BEGIN";
export const GET_ALL_CERTIFICATIONS_SUCCESS = "GET_ALL_CERTIFICATIONS_SUCCESS";
export const GET_ALL_CERTIFICATIONS_FAILURE = "GET_ALL_CERTIFICATIONS_FAILURE";

interface GetAllCertificationsBegin {
  type: typeof GET_ALL_CERTIFICATIONS_BEGIN;
}

interface GetAllCertificationsSuccess {
  type: typeof GET_ALL_CERTIFICATIONS_SUCCESS;
  payload: any;
}

interface GetAllCertificationsFailure {
  type: typeof GET_ALL_CERTIFICATIONS_FAILURE;
  payload: Error;
}

export type GetAllCertificationsActionTypes =
  | GetAllCertificationsBegin
  | GetAllCertificationsSuccess
  | GetAllCertificationsFailure;
