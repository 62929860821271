import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import {
  isNull,
  getThreshold,
  getStatusOfCheck,
  getCheckValue,
  isEmptyObject,
  isObject,
  getStatusOfCheckHeader,
} from "../../reusable/Util";
import { useState } from "react";
import AppLabels from "../../../constants/App_Labels";
import CustomPopOver from "../../reusable/CustomPopOver";

const DocsisChInfo = (props: any) => {
  const labels = AppLabels();
  const { docsis30dsChannels } = props;
  const [summaryAcc, setSummaryAcc] = useState(true);
  const [detailsAcc, setDetailsAcc] = useState(false);

  const handleAccToggle = (accName: string) => {
    if (accName === "summary") setSummaryAcc(!summaryAcc);
    else if (accName === "details") setDetailsAcc(!detailsAcc);
  };

  return (
    <div className="ofdmChannelCont">
      <div className="ofdmChSummary">
        <div className="dsChDataAcc" onClick={() => handleAccToggle("summary")}>
          <p>{labels.SUMMARY.toUpperCase()}</p>
          {summaryAcc ? (
            <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
          ) : (
            <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
          )}
        </div>
        {summaryAcc ? (
          <table className="dataTableDetails">
            <thead>
              <tr>
                <th>{labels.NAME.toUpperCase()}</th>
                <th>{labels.VALUE.toUpperCase()}</th>
                <th>{labels.STATUS.toUpperCase()}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {getStatusOfCheckHeader(
                    docsis30dsChannels?.maxRxPower,
                    labels.DOWN_RX_MAX.toUpperCase()
                  )}
                </td>
                {docsis30dsChannels?.hasOwnProperty("maxRxPower") ? (
                  <>
                    <td>
                      {isObject(docsis30dsChannels?.maxRxPower)
                        ? isNull(getCheckValue(docsis30dsChannels?.maxRxPower))
                        : ""}
                    </td>
                    <CustomPopOver
                      popOverData={
                        isObject(docsis30dsChannels?.maxRxPower)
                          ? isNull(getThreshold(docsis30dsChannels?.maxRxPower))
                          : ""
                      }
                      originComp={
                        isObject(docsis30dsChannels?.maxRxPower)
                          ? isNull(
                            getStatusOfCheck(docsis30dsChannels?.maxRxPower)
                          )
                          : ""
                      }
                      index="popOver"
                    />
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td>
                  {getStatusOfCheckHeader(
                    docsis30dsChannels?.minRxPower,
                    labels.DOWN_RX_MIN.toUpperCase()
                  )}
                </td>

                {docsis30dsChannels?.hasOwnProperty("minRxPower") ? (
                  <>
                    <td>
                      {isObject(docsis30dsChannels?.minRxPower)
                        ? isNull(getCheckValue(docsis30dsChannels?.minRxPower))
                        : ""}
                    </td>
                    <CustomPopOver
                      popOverData={
                        isObject(docsis30dsChannels?.minRxPower)
                          ? isNull(getThreshold(docsis30dsChannels?.minRxPower))
                          : ""
                      }
                      originComp={
                        isObject(docsis30dsChannels?.minRxPower)
                          ? isNull(
                            getStatusOfCheck(docsis30dsChannels?.minRxPower)
                          )
                          : ""
                      }
                      index="popOver"
                    />
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td>{labels.DOWN_RX_DELTA.toUpperCase()}</td>
                <td>{isNull(docsis30dsChannels?.rxPowerDelta)}</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  {getStatusOfCheckHeader(
                    docsis30dsChannels?.averageRxPower,
                    labels.DOWN_RX_AVG.toUpperCase()
                  )}
                </td>

                {docsis30dsChannels?.hasOwnProperty("averageRxPower") ? (
                  <>
                    <td>
                      {isObject(docsis30dsChannels?.averageRxPower)
                        ? isNull(
                          getCheckValue(docsis30dsChannels?.averageRxPower)
                        )
                        : ""}
                    </td>
                    <CustomPopOver
                      popOverData={
                        isObject(docsis30dsChannels?.averageRxPower)
                          ? isNull(
                            getThreshold(docsis30dsChannels?.averageRxPower)
                          )
                          : ""
                      }
                      originComp={
                        isObject(docsis30dsChannels?.averageRxPower)
                          ? isNull(
                            getStatusOfCheck(
                              docsis30dsChannels?.averageRxPower
                            )
                          )
                          : ""
                      }
                      index="popOver"
                    />
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td>
                  {getStatusOfCheckHeader(
                    docsis30dsChannels?.maxSnr,
                    labels.DOWN_SNR_MAX.toUpperCase()
                  )}
                </td>

                {docsis30dsChannels?.hasOwnProperty("maxSnr") ? (
                  <>
                    <td>
                      {isObject(docsis30dsChannels?.maxSnr)
                        ? isNull(getCheckValue(docsis30dsChannels?.maxSnr))
                        : ""}
                    </td>
                    <CustomPopOver
                      popOverData={
                        isObject(docsis30dsChannels?.maxSnr)
                          ? isNull(getThreshold(docsis30dsChannels?.maxSnr))
                          : ""
                      }
                      originComp={
                        isObject(docsis30dsChannels?.maxSnr)
                          ? isNull(getStatusOfCheck(docsis30dsChannels?.maxSnr))
                          : ""
                      }
                      index="popOver"
                    />
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td>
                  {getStatusOfCheckHeader(
                    docsis30dsChannels?.minSnr,
                    labels.DOWN_SNR_MIN.toUpperCase()
                  )}
                </td>

                {docsis30dsChannels?.hasOwnProperty("minSnr") ? (
                  <>
                    <td>
                      {isObject(docsis30dsChannels?.minSnr)
                        ? isNull(getCheckValue(docsis30dsChannels?.minSnr))
                        : ""}
                    </td>
                    <CustomPopOver
                      popOverData={
                        isObject(docsis30dsChannels?.minSnr)
                          ? isNull(getThreshold(docsis30dsChannels?.minSnr))
                          : ""
                      }
                      originComp={
                        isObject(docsis30dsChannels?.minSnr)
                          ? isNull(getStatusOfCheck(docsis30dsChannels?.minSnr))
                          : ""
                      }
                      index="popOver"
                    />
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td>{labels.DOWN_SNR_DELTA.toUpperCase()}</td>
                <td>{isNull(docsis30dsChannels?.snrDelta)}</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  {getStatusOfCheckHeader(
                    docsis30dsChannels?.averageSnr,
                    labels.DOWN_SNR_AVG.toUpperCase()
                  )}
                </td>
                {docsis30dsChannels?.hasOwnProperty("averageSnr") ? (
                  <>
                    <td>
                      {isObject(docsis30dsChannels?.averageSnr)
                        ? isNull(getCheckValue(docsis30dsChannels?.averageSnr))
                        : ""}
                    </td>
                    <CustomPopOver
                      popOverData={
                        isObject(docsis30dsChannels?.averageSnr)
                          ? isNull(getThreshold(docsis30dsChannels?.averageSnr))
                          : ""
                      }
                      originComp={
                        isObject(docsis30dsChannels?.averageSnr)
                          ? isNull(
                            getStatusOfCheck(docsis30dsChannels?.averageSnr)
                          )
                          : ""
                      }
                      index="popOver"
                    />
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td>{labels.DOWN_FRQ_MAX.toUpperCase()}</td>
                <td>{isNull(docsis30dsChannels?.maxFrequency)}</td>
                <td></td>
              </tr>
              <tr>
                <td>{labels.DOWN_FRQ_MIN.toUpperCase()}</td>
                <td>{isNull(docsis30dsChannels?.minFrequency)}</td>
                <td></td>
              </tr>
              <tr>
                <td>{labels.DOWN_FRQ_DELTA.toUpperCase()}</td>
                <td>{isNull(docsis30dsChannels?.frequencyDelta)}</td>
                <td></td>
              </tr>
              <tr>
                <td>{labels.MODULATION.toUpperCase()}</td>
                {docsis30dsChannels?.hasOwnProperty("channels") &&
                  docsis30dsChannels?.channels?.length >= 0 ? (
                  <td>
                    {isNull(docsis30dsChannels?.channels?.[0]?.modulation)}
                  </td>
                ) : (
                  <></>
                )}
                <td></td>
              </tr>
            </tbody>
          </table>
        ) : (
          <></>
        )}
      </div>
      <div className="ofdmChDetails">
        <div className="dsChDataAcc" onClick={() => handleAccToggle("details")}>
          <p>{labels.CHANNELS.toUpperCase()}</p>
          {detailsAcc ? (
            <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
          ) : (
            <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
          )}
        </div>
        {detailsAcc ? (
          <table className="dataTableDetails">
            <thead>
              <tr>
                <th id="nameCol">{labels.FREQUENCY.toUpperCase()}</th>
                <th id="valueCol">{labels.RX_POWER.toUpperCase()}</th>
                <th id="valueCol">{labels.SNR.toUpperCase()}</th>
                <th id="valueCol">{labels.STATUS.toUpperCase()}</th>
              </tr>
            </thead>
            <tbody>
              {docsis30dsChannels?.hasOwnProperty("channels") &&
                docsis30dsChannels?.channels?.length >= 0 ? (
                docsis30dsChannels?.channels
                  ?.slice()
                  ?.sort((a: any, b: any) =>
                    a?.frequency > b?.frequency ? 1 : -1
                  )
                  ?.map((cb: any, index: number) => {
                    return (
                      <tr>
                        <td>{isNull(cb?.frequency)}</td>
                        {cb?.hasOwnProperty("rx_power") ? (
                          <td>
                            {isNull(getCheckValue(isEmptyObject(cb?.rx_power)))}
                          </td>
                        ) : (
                          <td></td>
                        )}
                        {cb?.hasOwnProperty("snr") ? (
                          <td>
                            {isNull(getCheckValue(isEmptyObject(cb?.snr)))}
                          </td>
                        ) : (
                          <td></td>
                        )}
                        {cb?.hasOwnProperty("status") ? (
                          <td>
                            {isNull(getCheckValue(isEmptyObject(cb?.status)))}
                          </td>
                        ) : (
                          <td></td>
                        )}
                      </tr>
                    );
                  })
              ) : (
                <></>
              )}
            </tbody>
          </table>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default DocsisChInfo;
