import { createSlice } from "@reduxjs/toolkit";
import storeConst from "../storeConst";

const slice = createSlice({
  name: "validateData",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    requestType: "",
    status: "",
  },
  reducers: {
    sendValidateBegin: (valData, action) => {
      valData.isLoading = true;
      valData.status = "";
      valData.requestType = action.payload;
    },
    sendValidateSuccess: (valData, action) => {
      valData.isLoading = false;
      valData.data = action.payload;
      valData.status = storeConst.OK;
    },
    sendValidateFailure: (valData, action) => {
      valData.isLoading = false;
      valData.error = action.payload;
      valData.data = undefined;
      valData.status = storeConst.ERROR;
    },
    sendValidateReset: (valData) => {
      valData.isLoading = false;
      valData.error = undefined;
      valData.data = undefined;
      valData.requestType = "";
      valData.status = "";
    },
  },
});

export const {
  sendValidateBegin,
  sendValidateSuccess,
  sendValidateFailure,
  sendValidateReset,
} = slice.actions;
export default slice.reducer;
