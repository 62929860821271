import CustomAccordion from "../../../../../../../components/reusable/CustomAccordion";
import MapEleDataDisplay from "../../../../../reusable/MapEleDataDisplay";
import MDUTapAddress from "./MDUTapAddress";

interface Props {
  eleConfig: any;
  eleData: any;
}

const MduTapDetails = ({ eleConfig, eleData }: Props) => {
  return (
    <div className="activeMapEleCont">
      {eleData !== undefined &&
        eleConfig !== undefined &&
        eleConfig?.length !== 0 && (
          <>
            <CustomAccordion
              accTitle={eleConfig?.title?.toUpperCase()}
              accBodyElement={
                <>
                  {eleData && (
                    <MapEleDataDisplay
                      tableConfig={eleConfig}
                      eleData={eleData}
                    />
                  )}
                </>
              }
            />
            {/*  <MDUTapAddress /> */}
          </>
        )}
    </div>
  );
};

export default MduTapDetails;
