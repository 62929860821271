export const batchSize = 5;

export const commonAttr = [
  {
    columnKey: "address",
    columnName: "Address",
    jsonDataMapping: "address",
    getDataFrom: "listOfModems",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "smt",
    columnName: "SMT",
    jsonDataMapping: "smt",
    getDataFrom: "listOfModems",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "cm_mac",
    columnName: "CM MAC",
    jsonDataMapping: "cmMac",
    getDataFrom: "listOfModems",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "model",
    columnName: "Model",
    jsonDataMapping: "model",
    getDataFrom: "listOfModems",
    isPinned: false,
    isVisible: true,
    position: 0,
    styleClassName: "largeValCol",
  },
];

export const summaryAttr = [
  {
    columnKey: "uptime",
    columnName: "Uptime",
    jsonDataMapping: "cmUptime",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const summaryCommonAttr = [
  {
    columnKey: "reg_time",
    columnName: "Reg Time",
    jsonDataMapping: "cmtsUptime",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "reg_status",
    columnName: "Reg Status",
    jsonDataMapping: "cmtsCmOnlineStatus",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];
export const summaryAttr2 = [
  {
    columnKey: "online_times",
    columnName: "Online Times",
    jsonDataMapping: "onlineTimes",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "online_percent",
    columnName: "Online %",
    jsonDataMapping: "onlinePercentage",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ip_address",
    columnName: "IP Address",
    jsonDataMapping: "mgmtIP",
    getDataFrom: "summary",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const docsisAttr = [
  {
    columnKey: "us_interfaces",
    columnName: "US Interface",
    jsonDataMapping: "usInterface",
    displayAttribute: "usInterfaceDisplay",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "node",
    columnName: "NODE",
    jsonDataMapping: "node",
    getDataFrom: "docsis",
    haveMultipleValues: true,
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];
export const flapsAttr = [
  // {
  //   columnKey: "flap",
  //   columnName: "Flap",
  //   jsonDataMapping: "flapsCount",
  //   getDataFrom: "flaps",
  //   isPinned: false,
  //   isVisible: true,
  //   position: 0,
  // },
  {
    columnKey: "inf",
    columnName: "Inf",
    jsonDataMapping: "insertionFails",
    getDataFrom: "flaps",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  // {
  //   columnKey: "hits",
  //   columnName: "Hits",
  //   jsonDataMapping: "hits",
  //   getDataFrom: "flaps",
  //   isPinned: false,
  //   isVisible: true,
  //   position: 0,
  // },
  // {
  //   columnKey: "miss",
  //   columnName: "Miss",
  //   jsonDataMapping: "miss",
  //   getDataFrom: "flaps",
  //   isPinned: false,
  //   isVisible: true,
  //   position: 0,
  // },
  {
    columnKey: "pwr",
    columnName: "Pwr",
    jsonDataMapping: "powerAdjustments",
    getDataFrom: "flaps",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  // {
  //   columnKey: "first_flap_time",
  //   columnName: "First Flap Time",
  //   jsonDataMapping: "createTime",
  //   getDataFrom: "flaps",
  //   isPinned: false,
  //   isVisible: true,
  //   position: 0,
  //   styleClassName: "largeValCol",
  // },
  // {
  //   columnKey: "last_flap_time",
  //   columnName: "Last Flap Time",
  //   jsonDataMapping: "lastFlapTime",
  //   getDataFrom: "flaps",
  //   isPinned: false,
  //   isVisible: true,
  //   position: 0,
  //   styleClassName: "largeValCol",
  // },
];
export const subflapsAttr = [
  {
    columnKey: "flap",
    columnName: "Flap",
    jsonDataMapping: "flapsCount",
    getDataFrom: "flaps",
    isPinned: false,
    isVisible: false,
    position: 0,
  },
  {
    columnKey: "inf",
    columnName: "Inf",
    jsonDataMapping: "insertionFails",
    getDataFrom: "flaps",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "hits",
    columnName: "Hits",
    jsonDataMapping: "hits",
    getDataFrom: "flaps",
    isPinned: false,
    isVisible: false,
    position: 0,
  },
  {
    columnKey: "miss",
    columnName: "Miss",
    jsonDataMapping: "miss",
    getDataFrom: "flaps",
    isPinned: false,
    isVisible: false,
    position: 0,
  },
  {
    columnKey: "misses",
    columnName: "Miss %",
    jsonDataMapping: "misses",
    getDataFrom: "flaps",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "pwr",
    columnName: "Pwr",
    jsonDataMapping: "powerAdjustments",
    getDataFrom: "flaps",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "first_flap_time",
    columnName: "First Flap Time",
    jsonDataMapping: "createTime",
    getDataFrom: "flaps",
    isPinned: false,
    isVisible: true,
    position: 0,
    styleClassName: "largeValCol",
  },
  {
    columnKey: "last_flap_time",
    columnName: "Last Flap Time",
    jsonDataMapping: "lastFlapTime",
    getDataFrom: "flaps",
    isPinned: false,
    isVisible: true,
    position: 0,
    styleClassName: "largeValCol",
  },
];
export const upStream30SubAttr1 = [
  {
    columnKey: "us_snr_min",
    columnName: "US SNR min",
    jsonDataMapping: "usMinSnr",
    displayAttribute: "usMinSnrDisplay",
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "us_snr_max",
    columnName: "US SNR max",
    jsonDataMapping: "usMaxSnr",
    displayAttribute: "usMaxSnrDisplay",
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const upStream30SubAttr2 = [
  {
    columnKey: "us_tx_min",
    columnName: "US Tx min",
    jsonDataMapping: "usMinTxPower",
    displayAttribute: "usMinTxPowerDisplay",
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "us_tx_max",
    columnName: "US Tx max",
    jsonDataMapping: "usMaxTxPower",
    displayAttribute: "usMaxTxPowerDisplay",
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const upStream30Attr = [
  {
    columnKey: "us_ps",
    columnName: "US PS",
    jsonDataMapping: "us31PartialSvcState",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "us_tx_total",
    columnName: "US Tx Total",
    jsonDataMapping: "usTxPowerTotal",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  ...upStream30SubAttr2,

  {
    columnKey: "us_tx_delta",
    columnName: "US Tx ∆",
    jsonDataMapping: "usTxPowerDelta",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  ...upStream30SubAttr1,
  {
    columnKey: "us_snr_delta",
    columnName: "US SNR ∆",
    jsonDataMapping: "usSNRDelta",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const upstream30SubAttr3 = [
  {
    columnKey: "us_tx",
    columnName: "US Tx",
    jsonDataMapping: "usTx",
    //exportcsvAttribute: "usTxCsv",
    displayAttribute: "usTxDisplay",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "us_snr",
    columnName: "US SNR",
    jsonDataMapping: "usSnr",
    displayAttribute: "usSnrDisplay",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const upStream30Attr2 = [
  {
    columnKey: "us_freq",
    columnName: "US Freq",
    jsonDataMapping: "usFreq",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  ...upstream30SubAttr3,
];
export const downstream30SubAttr1 = [
  {
    columnKey: "ds_rx_min",
    columnName: "DS Rx min",
    jsonDataMapping: "minRxPower",
    displayAttribute: "minRxPowerDisplay",
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ds_rx_max",
    columnName: "DS Rx max",
    jsonDataMapping: "maxRxPower",
    displayAttribute: "maxRxPowerDisplay",
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];
export const downstream30SubAttr2 = [
  {
    columnKey: "ds_snr_min",
    columnName: "DS SNR min",
    jsonDataMapping: "dsMinSnr",
    displayAttribute: "dsMinSnrDisplay",
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ds_snr_max",
    columnName: "DS SNR max",
    jsonDataMapping: "dsMaxSnr",
    displayAttribute: "dsMaxSnrDisplay",
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];
export const downStream30Attr = [
  {
    columnKey: "ds_ps",
    columnName: "DS PS",
    jsonDataMapping: "ds31PartialSvcState",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  ...downstream30SubAttr1,
  {
    columnKey: "ds_rx_delta",
    columnName: "DS Rx ∆",
    jsonDataMapping: "rxPowerDelta",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  ...downstream30SubAttr2,
  {
    columnKey: "ds_snr_delta",
    columnName: "DS SNR ∆",
    jsonDataMapping: "dsSnrDeltaValue",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const downstream30SubAttr3 = [
  {
    columnKey: "ds_rx",
    columnName: "DS Rx",
    jsonDataMapping: "dsRx",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    displayAttribute: "dsRxDisplay",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ds_snr",
    columnName: "DS SNR",
    jsonDataMapping: "dsSnr",
    haveMultipleValues: true,
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    displayAttribute: "dsSnrDisplay",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const downstream30Attr2 = [
  {
    columnKey: "ds_freq",
    columnName: "DS Freq",
    jsonDataMapping: "dsFreq",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  ...downstream30SubAttr3,
];
export const upStream31SubAttr1 = [
  {
    columnKey: "ofdma_tx",
    columnName: "OFDMA TX",
    jsonDataMapping: "txPowerValue",
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    displayAttribute: "txPowerValueDisplay",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];
export const upStream31SubAttr2 = [
  {
    columnKey: "ofdma_pct",
    columnName: "OFDMA 2 PCT",
    jsonDataMapping: "merThrValValue",
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    displayAttribute: "merThrValValueDisplay",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];
export const upStream31Attr = [
  ...upStream31SubAttr1,
  {
    columnKey: "ofdma_mean",
    columnName: "OFDMA mean",
    jsonDataMapping: "merMean",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ofdma_std",
    columnName: "OFDMA STD",
    jsonDataMapping: "merStdDev",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  ...upStream31SubAttr2,
];

export const upStream31Attr2 = [
  {
    columnKey: "ofdma_status",
    columnName: "OFDMA Status",
    jsonDataMapping: "usChannelStatus",
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];
export const downStream31SubAttr1 = [
  {
    columnKey: "ofdm_rx_min",
    columnName: "OFDM Rx min",
    jsonDataMapping: "dsMinRxPowerValue",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    displayAttribute: "dsMinRxPowerValueDisplay",
    isPinned: false,
    isVisible: true,
    position: 0,
    styleClassName: "medValCol",
  },
  {
    columnKey: "ofdm_rx_max",
    columnName: "OFDM Rx max",
    jsonDataMapping: "dsMaxRxPowerValue",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    displayAttribute: "dsMaxRxPowerValueDisplay",
    isPinned: false,
    isVisible: true,
    position: 0,
    styleClassName: "medValCol",
  },
];
export const downStream31SubAttr2 = [
  {
    columnKey: "ofdm_pct",
    columnName: "OFDM 2 PCT",
    jsonDataMapping: "dsThrValValue",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    displayAttribute: "dsThrValValueDisplay",
    isPinned: false,
    isVisible: true,
    position: 0,
    styleClassName: "medValCol",
  },
];

export const downStream31SubAttr3 = [
  {
    columnKey: "ofdm_1_rx_min",
    columnName: "OFDM Rx min",
    jsonDataMapping: "dsMinRxPowerValue1",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    displayAttribute: "dsMinRxPowerValue1Display",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ofdm_1_rx_max",
    columnName: "OFDM Rx max",
    jsonDataMapping: "dsMaxRxPowerValue1",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    displayAttribute: "dsMaxRxPowerValue1Display",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const downStream31SubAttr4 = [
  {
    columnKey: "ofdm_1_pct",
    columnName: "OFDM 2 PCT",
    jsonDataMapping: "dsThrValValue1",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    displayAttribute: "dsThrValValue1Display",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];
export const downStream31Attr = [
  {
    columnKey: "ofdm_channel_ID",
    columnName: "OFDM Channel ID",
    jsonDataMapping: "ofdmChannelID",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
    styleClassName: "medValCol",
  },
  ...downStream31SubAttr1,
  {
    columnKey: "ofdm_rx_delta",
    columnName: "OFDM Rx ∆",
    jsonDataMapping: "dsRxPowerDelta",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
    styleClassName: "medValCol",
  },
  {
    columnKey: "ofdm_mean",
    columnName: "OFDM mean",
    jsonDataMapping: "dsMerMean",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
    styleClassName: "medValCol",
  },
  {
    columnKey: "ofdm_std",
    columnName: "OFDM STD",
    jsonDataMapping: "dsMerStdDev",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
    styleClassName: "medValCol",
  },
  ...downStream31SubAttr2,
];

export const downStream31Attr1 = [
  ...downStream31SubAttr3,
  {
    columnKey: "ofdm_1_rx_delta",
    columnName: "OFDM Rx ∆",
    jsonDataMapping: "dsRxPowerDelta1",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ofdm_1_mean",
    columnName: "OFDM mean",
    jsonDataMapping: "dsMerMean1",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ofdm_1_std",
    columnName: "OFDM STD",
    jsonDataMapping: "dsMerStdDev1",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  ...downStream31SubAttr4,
];

export const downStream31Attr2 = [
  {
    columnKey: "ofdm_status",
    columnName: "OFDM Status",
    jsonDataMapping: "dsStatusValue",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];
export const usCodeWordsSubAttr = [
  {
    columnKey: "us_delta_pre_cwe_pct",
    columnName: "(Δ) Pre CCER %",
    jsonDataMapping: "usPreCWEDelta",
    haveMultipleValues: true,
    getDataFrom: "codewords",
    displayAttribute: "usPreCWEDeltaDisplay",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "us_delta_post_cwe_pct",
    columnName: "(Δ) Post CER %",
    jsonDataMapping: "usPostCWEDelta",
    haveMultipleValues: true,
    getDataFrom: "codewords",
    displayAttribute: "usPostCWEDeltaDisplay",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];
export const usCodeWordsAttr = [
  {
    columnKey: "us_delta_time",
    columnName: "(Δ) Time",
    jsonDataMapping: "codewordsTimeDelta",
    getDataFrom: "codewords",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  ...usCodeWordsSubAttr,
  {
    columnKey: "us_delta_total_cw",
    columnName: "(Δ) Total Codewords",
    jsonDataMapping: "usTotalCwDelta",
    haveMultipleValues: true,
    getDataFrom: "codewords",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "us_pre_cwe_pct",
    columnName: "Pre CCER %",
    jsonDataMapping: "usPreCWEPer",
    haveMultipleValues: true,
    getDataFrom: "codewords",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "us_post_cwe_pct",
    columnName: "Post CER %",
    jsonDataMapping: "usPostCWEPer",
    haveMultipleValues: true,
    getDataFrom: "codewords",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];
export const dsCodeWordsSubAttr = [
  {
    columnKey: "ds_delta_pre_cwe_pct",
    columnName: "(Δ) Pre CCER %",
    jsonDataMapping: "dsPreCWEDelta",
    haveMultipleValues: true,
    getDataFrom: "codewords",
    displayAttribute: "dsPreCWEDeltaDisplay",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ds_delta_post_cwe_pct",
    columnName: "(Δ) Post CER %",
    jsonDataMapping: "dsPostCWEDelta",
    haveMultipleValues: true,
    getDataFrom: "codewords",
    displayAttribute: "dsPostCWEDeltaDisplay",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];
export const dsCodeWordsAttr = [
  {
    columnKey: "ds_delta_time",
    columnName: "(Δ) Time",
    jsonDataMapping: "codewordsTimeDelta",
    getDataFrom: "codewords",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  ...dsCodeWordsSubAttr,
  {
    columnKey: "ds_delta_total_cw",
    columnName: "(Δ) Total Codewords",
    jsonDataMapping: "dsTotalCwDelta",
    haveMultipleValues: true,
    getDataFrom: "codewords",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ds_pre_cwe_pct",
    columnName: "Pre CCER %",
    jsonDataMapping: "dsPreCWEPer",
    haveMultipleValues: true,
    getDataFrom: "codewords",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ds_post_cwe_pct",
    columnName: "Post CER %",
    jsonDataMapping: "dsPostCWEPer",
    haveMultipleValues: true,
    getDataFrom: "codewords",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];
export const ofdmaCodeWordsSubAttr = [
  {
    columnKey: "ofdma_delta_post_cwe_pct",
    columnName: "(Δ) Post CER %",
    jsonDataMapping: "ofdmaPostCWEDelta",
    haveMultipleValues: true,
    getDataFrom: "codewords",
    displayAttribute: "ofdmaPostCWEDeltaDisplay",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];
export const ofdmaChannelId =[{
  columnKey: "ofdma_channel_ID",
  columnName: "Channel ID",
  jsonDataMapping: "ofdma_Channel_ID",
  haveMultipleValues: true,
  getDataFrom: "docsis",
  isPinned: false,
  isVisible: true,
  position: 0,
}];
export const ofdmChannelId =[  {
  columnKey: "ofdm_Channel_ID",
  columnName: "OFDM Channel ID",
  jsonDataMapping: "ofdm_Channel_ID",
  haveMultipleValues: true,
  getDataFrom: "docsis",
  isPinned: false,
  isVisible: true,
  position: 0,
  styleClassName: "medValCol",
}];
export const ofdmaCodeWordsAttr = [
  {
    columnKey: "ofdma_delta_time",
    columnName: "(Δ) Time",
    jsonDataMapping: "codewordsTimeDelta",
    getDataFrom: "codewords",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ofdma_channel_ID",
    columnName: "Channel ID",
    jsonDataMapping: "ofdma_Channel_ID",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ofdma_delta_pre_cwe_pct",
    columnName: "(Δ) Pre CCER %",
    jsonDataMapping: "ofdmaPreCWEDelta",
    haveMultipleValues: true,
    getDataFrom: "codewords",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  ...ofdmaCodeWordsSubAttr,
  {
    columnKey: "ofdma_delta_total_cw",
    columnName: "(Δ) Total Codewords",
    jsonDataMapping: "ofdmaTotalCwDelta",
    haveMultipleValues: true,
    getDataFrom: "codewords",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ofdma_pre_cwe_pct",
    columnName: "Pre CCER %",
    jsonDataMapping: "ofdmaPreCWEPer",
    haveMultipleValues: true,
    getDataFrom: "codewords",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ofdma_post_cwe_pct",
    columnName: "Post CER %",
    jsonDataMapping: "ofdmaPostCWEPer",
    haveMultipleValues: true,
    getDataFrom: "codewords",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const ofdmCodeWordsSubAttr = [
  {
    columnKey: "ofdm_delta_post_cwe_pct",
    columnName: "(Δ) Post CER %",
    jsonDataMapping: "ofdmPostCWEDelta",
    haveMultipleValues: true,
    getDataFrom: "codewords",
    displayAttribute: "ofdmPostCWEDeltaDisplay",
    specInfo: {
      inSpec: true,
      outSpec: true,
      marginal: true,
      others: true,
    },
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];

export const ofdmCodeWordsAttr = [
  {
    columnKey: "ofdm_delta_time",
    columnName: "(Δ) Time",
    jsonDataMapping: "codewordsTimeDelta",
    getDataFrom: "codewords",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ofdm_Channel_ID",
    columnName: "Channel_ID",
    jsonDataMapping: "ofdm_Channel_ID",
    haveMultipleValues: true,
    getDataFrom: "docsis",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ofdm_delta_pre_cwe_pct",
    columnName: "(Δ) Pre CCER %",
    jsonDataMapping: "ofdmPreCWEDelta",
    haveMultipleValues: true,
    getDataFrom: "codewords",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  ...ofdmCodeWordsSubAttr,
  {
    columnKey: "ofdm_delta_total_cw",
    columnName: "(Δ) Total Codewords",
    jsonDataMapping: "ofdmTotalCwDelta",
    haveMultipleValues: true,
    getDataFrom: "codewords",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ofdm_pre_cwe_pct",
    columnName: "Pre CCER %",
    jsonDataMapping: "ofdmPreCWEPer",
    haveMultipleValues: true,
    getDataFrom: "codewords",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
  {
    columnKey: "ofdm_post_cwe_pct",
    columnName: "Post CER %",
    jsonDataMapping: "ofdmPostCWEPer",
    haveMultipleValues: true,
    getDataFrom: "codewords",
    isPinned: false,
    isVisible: true,
    position: 0,
  },
];
export const selectOption= [
  {
      columnKey: "selectAll",
      columnName: "(Select All)",
      isVisible: true, 
  }
]
export const teleViewConfig= (istechRole:any)=>  
 {console.log(istechRole,"istechRole") ;
  return [
  {
    viewTypeKey: "full",
    viewName: "ALL",
    specAvailAttr: [
      ...upStream30SubAttr1,
      ...upStream30SubAttr2,
      ...downstream30SubAttr1,
      ...downstream30SubAttr2,
      ...upStream31SubAttr1,
      ...upStream31SubAttr2,
      ...downStream31SubAttr1,
      ...downStream31SubAttr2,
    ],
    attributes: [
      ...selectOption,
      ...commonAttr,
      ...summaryAttr,
      ...summaryCommonAttr,
      ...upStream30Attr,
      ...downStream30Attr,
      ...upStream31Attr,
      ...downStream31Attr,
      ...summaryAttr2,
      ...(istechRole ? subflapsAttr : flapsAttr),
      ...docsisAttr,
    ],
  },
  {
    viewTypeKey: "flaps",
    viewName: "FLAPS",
    attributes: [...selectOption,...commonAttr, ...summaryCommonAttr,   ...(istechRole ? subflapsAttr : flapsAttr)],
  },
  {
    viewTypeKey: "basic",
    viewName: "BASIC",
    attributes: [
      ...selectOption,
      ...commonAttr,
      ...summaryAttr,
      ...summaryCommonAttr,
      ...summaryAttr2,
    ],
  },
  {
    viewTypeKey: "full_us_sc_qam",
    viewName: "US SC QAM",
    specAvailAttr: [
      ...upStream30SubAttr1,
      ...upStream30SubAttr2,
      ...upstream30SubAttr3,
      ...usCodeWordsSubAttr,
    ],
    attributes: [
      ...selectOption,
      ...commonAttr,
      ...summaryCommonAttr,
      ...upStream30Attr,
      ...upStream30Attr2,
      ...usCodeWordsAttr,
    ],
  },
  {
    viewTypeKey: "full_ds_sc_qam",
    viewName: "DS SC QAM",
    specAvailAttr: [
      ...downstream30SubAttr1,
      ...downstream30SubAttr2,
      ...downstream30SubAttr3,
      ...dsCodeWordsSubAttr,
    ],
    attributes: [
      ...selectOption,
      ...commonAttr,
      ...summaryCommonAttr,
      ...downStream30Attr,
      ...downstream30Attr2,
      ...dsCodeWordsAttr,
    ],
  },
  {
    viewTypeKey: "full_ofdma",
    viewName: "OFDMA",
    specAvailAttr: [
      ...upStream31SubAttr1,
      ...upStream31SubAttr2,
      ...ofdmaCodeWordsSubAttr,
    ],
    attributes: [
      ...selectOption,
      ...commonAttr,
      ...summaryCommonAttr,
      ...upStream31Attr,
      ...upStream31Attr2,
      ...ofdmaCodeWordsAttr,
    ],
  },
  {
    viewTypeKey: "full_ofdm",
    viewName: "OFDM",
    specAvailAttr: [
      ...downStream31SubAttr1,
      ...downStream31SubAttr3,
      ...downStream31SubAttr4,
      ...ofdmCodeWordsSubAttr,
    ],
    attributes: [
      ...selectOption,
      ...commonAttr,
      ...summaryCommonAttr,
      ...downStream31Attr1,
      ...downStream31Attr2,
      ...ofdmCodeWordsAttr,
    ],
  },
  {
    viewTypeKey: "us_interfaces",
    viewName: "US INTERFACE",
    attributes: [
      ...selectOption,
      ...commonAttr,
      ...summaryAttr,
      ...summaryCommonAttr,
      ...docsisAttr, 
      
    ],
  },
  //latest NO CW
  {
    viewTypeKey: "full_no_cw_us_sc_qam",
    viewName: "US SC QAM",
    specAvailAttr: [
      ...upStream30SubAttr1,
      ...upStream30SubAttr2,
      ...upstream30SubAttr3,
    ],
    attributes: [
      ...selectOption,
      ...commonAttr,
      ...summaryCommonAttr,
      ...upStream30Attr,
      ...upStream30Attr2,
    ],
  },
  {
    viewTypeKey: "full_no_cw_ds_sc_qam",
    viewName: "DS SC QAM",
    specAvailAttr: [
      ...downstream30SubAttr1,
      ...downstream30SubAttr2,
      ...downstream30SubAttr3,
    ],
    attributes: [
      ...selectOption,
      ...commonAttr,
      ...summaryCommonAttr,
      ...downStream30Attr,
      ...downstream30Attr2,
    ],
  },
  {
    viewTypeKey: "full_no_cw_ofdma",
    viewName: "OFDMA",
    specAvailAttr: [...upStream31SubAttr1, ...upStream31SubAttr2],
    attributes: [
      ...selectOption,
      ...commonAttr,
      ...summaryCommonAttr,
      ...ofdmaChannelId,
      ...upStream31Attr,
      ...upStream31Attr2,
    ],
  },
  {
    viewTypeKey: "full_no_cw_ofdm",
    viewName: "OFDM",
    specAvailAttr: [
      ...downStream31SubAttr1,
      ...downStream31SubAttr3,
      ...downStream31SubAttr4,
    ],
    attributes: [
      ...selectOption,
      ...commonAttr,
      ...summaryCommonAttr,
      ...ofdmChannelId,
      ...downStream31Attr1,
      ...downStream31Attr2,
    ],
  },
];
 }


export interface IView {
  title: string;
  attribute: string; // column name
  statusAttr?: string; // read status from this object attribute
  isMultiValueGrid?: boolean;
  isVerticalGrid?: boolean;
  sort?: boolean;
  disableSearch?: boolean;
  disableSort?: boolean;
  width?: number | string;
  align?: "left" | "right" | "center";
  render?: (text: any, record: any, index: number) => React.ReactNode;
  sorter?: (a: any, b: any) => number;
  externalLink?: boolean;
  disableError?: boolean;
  maxWidth?: number | string;
  style?: React.CSSProperties;
}

export const certificationTableConfig: IView[] = [
  {
    title: "Address",
    attribute: "address",
    width: "10vw",
    disableSearch: false,
  },
  {
    title: "SMT",
    attribute: "smt",
    disableSearch: false,
  },
  {
    title: "CM MAC",
    attribute: "cmMac",
    disableSearch: false,
  },
  {
    title: "Model",
    attribute: "model",
    disableSearch: false,
  },

  {
    title: "Status",
    attribute: "certStatus",
    statusAttr: "certStatus",
    disableSearch: false,
  },

  {
    title: "US Freq",
    attribute: "usFreq",
    disableSearch: true,
    disableSort: true,
  },
  {
    title: "US Tx",
    attribute: "usTx",
    statusAttr: "certTxStatus",
    disableSearch: true,
    disableSort: true,
  },
  {
    title: "US SNR",
    attribute: "usSnr",
    statusAttr: "certUSSnrStatus",
    disableSearch: true,
    disableSort: true,
  },
  {
    title: "CMTS Rx",
    attribute: "cmtsRx",
    statusAttr: "certCMTSRxStatus",
    disableSearch: true,
    disableSort: true,
  },
  {
    title: "Pre CCER %",
    attribute: "usPreCWEDelta",
    statusAttr: "certUSPreCerStatus",
    disableSearch: true,
    disableSort: true,
  },
  {
    title: "Post CER %",
    attribute: "usPostCWEDelta",
    statusAttr: "certUSPostCCerStatus",
    disableSearch: true,
    disableSort: true,
  },

  {
    title: "DS Freq",
    attribute: "dsFreq",
    disableSearch: true,
    disableSort: true,
  },
  {
    title: "DS RX",
    attribute: "dsRx",
    statusAttr: "certRxStatus",
    disableSearch: true,
    disableSort: true,
  },
  {
    title: "DS SNR",
    attribute: "dsSnr",
    statusAttr: "certDSSnrStatus",
    disableSearch: true,
    disableSort: true,
  },
];

export const ponOltTableConfig: IView[] = [
  {
    title: "Slot",
    attribute: "slotName",
    disableSearch: false,
    externalLink: false,
    disableSort: true,
    disableError: false,
  },
  {
    title: "Pon Port",
    attribute: "portName",
    disableSearch: false,
    externalLink: false,
    disableSort: true,
    disableError: false,
  },
  {
    title: "Pon Node",
    attribute: "ponSplitterNodeId",
    disableSearch: false,
    externalLink: true,
    disableError: false,
  },
  {
    title: "ONT Count",
    attribute: "ontCount",
    disableSearch: false,
    externalLink: false,
    disableError: true,
  },
];
export const PonTableConfig: IView[] = [
  {
    title: "Address",
    attribute: "address",
    disableSearch: false,
  },
  {
    title: "Serial Number",
    attribute: "ontSerialNumber",
    disableSearch: false,
    externalLink: true,
  },
  {
    title: "Vendor",
    attribute: "ontVendor",
    disableSearch: false,
  },
  {
    title: "Model",
    attribute: "ontModel",
    disableSearch: false,
  },
  // {
  //   title: "OLT",
  //   attribute: "oltName",
  //   disableSearch: false,
  //   externalLink: true,
  // },
  {
    title: "PON",
    attribute: "ponPort",
    disableSearch: true,
    disableSort: true,
  },
  {
    title: "Change Time",
    attribute: "lastChange",
    disableSearch: true,
    disableSort: true,
  },
  {
    title: "State",
    attribute: "reportingState",
    disableSearch: false,
  },
  {
    title: "Oper",
    attribute: "operStatus",
    disableSearch: false,
  },
  {
    title: "Admin",
    attribute: "adminStatus",
    disableSearch: false,
  },
  {
    title: "OPT Dist",
    attribute: "opticalDistance",
    disableSearch: false,
  },
  {
    title: "OPT TX",
    attribute: "opticalTx",
    disableSearch: false,
  },
  {
    title: "OPT RX",
    attribute: "opticalRx",
    disableSearch: false,
  },
  {
    title: "ETH Status",
    attribute: "etherPortCurrentStatus",
    disableSearch: false,
  },
  {
    title: "ETH Oper",
    attribute: "etherPortOperStatus",
    disableSearch: false,
  },
  {
    title: "ETH Admin",
    attribute: "etherPortAdminStatus",
    disableSearch: false,
  },
  {
    title: "ETH Config Status",
    attribute: "etherPortConfigIndicator",
    disableSearch: false,
  },
  {
    title: "SW Version",
    attribute: "swVersion",
    disableSearch: false,
  },
];

export const gponTableConfig: IView[] = [
  {
    title: "Address",
    attribute: "address",
    disableSearch: false,
  },
  {
    title: "Serial Number",
    attribute: "ontSerialNumber",
    disableSearch: false,
    externalLink: true,
  },
  {
    title: "Vendor",
    attribute: "ontVendor",
    disableSearch: false,
  },
  {
    title: "Model",
    attribute: "ontModel",
    disableSearch: false,
  },
  {
    title: "OLT",
    attribute: "oltName",
    disableSearch: false,
    externalLink: true,
  },
  {
    title: "PON",
    attribute: "ponPort",
    disableSearch: true,
    disableSort: true,
  },
  {
    title: "Change Time",
    attribute: "lastChange",
    disableSearch: true,
    disableSort: true,
  },
  {
    title: "State",
    attribute: "reportingState",
    disableSearch: false,
  },
  {
    title: "Oper",
    attribute: "operStatus",
    disableSearch: false,
  },
  {
    title: "Admin",
    attribute: "adminStatus",
    disableSearch: false,
  },
  {
    title: "OPT Dist",
    attribute: "opticalDistance",
    disableSearch: false,
  },
  {
    title: "OPT TX",
    attribute: "opticalTx",
    disableSearch: false,
  },
  {
    title: "OPT RX",
    attribute: "opticalRx",
    disableSearch: false,
  },
  {
    title: "ETH Status",
    attribute: "etherPortCurrentStatus",
    disableSearch: false,
  },
  {
    title: "ETH Oper",
    attribute: "etherPortOperStatus",
    disableSearch: false,
  },
  {
    title: "ETH Admin",
    attribute: "etherPortAdminStatus",
    disableSearch: false,
  },
  {
    title: "ETH Config Status",
    attribute: "etherPortConfigIndicator",
    disableSearch: false,
  },
  {
    title: "SW Version",
    attribute: "swVersion",
    disableSearch: false,
  },
];

export const gponOltTableConfig: IView[] = [
  {
    title: "Slot",
    attribute: "slotName",
    disableSearch: false,
    externalLink: false,
    disableSort: true,
    disableError: false,
  },
  {
    title: "Pon Port",
    attribute: "portName",
    disableSearch: false,
    externalLink: false,
    disableSort: true,
    disableError: false,
  },
  {
    title: "Pon Node",
    attribute: "ponSplitterNodeId",
    disableSearch: false,
    externalLink: true,
    disableError: false,
  },
  {
    title: "ONT Count",
    attribute: "ontCount",
    disableSearch: false,
    externalLink: false,
    disableError: true,
  },
];

export const odmCertTableConfig: IView[] = [
  {
    title: "Address",
    attribute: "address",
    width: "10vw",
    disableSearch: false,
  },
  {
    title: "SMT",
    attribute: "smt",
    disableSearch: false,
  },
  {
    title: "CM MAC",
    attribute: "cmMac",
    disableSearch: false,
  },
  {
    title: "Model",
    attribute: "model",
    disableSearch: false,
  },
  {
    title: "CM Score",
    attribute: "cmOdmScore",
    statusAttr: "odmCertStatus",
    disableSearch: false,
    disableSort: false,
  },
  {
    title: "OFDMA Score",
    attribute: "ofdmaOdmScore",
    disableSearch: false,
    disableSort: false,
  },

  {
    attribute: "ofdmaChannelIdOdm",
    title: "Channel ID",
    disableSearch: true,
    disableSort: true,
    isMultiValueGrid: true,
    isVerticalGrid: true,
    // jsonDataMapping: "ofdmaChannelId",
    // haveMultipleValues: true,
    // getDataFrom: "codewords",
    // isPinned: false,
    // isVisible: true,
    // position: 0,
  },
  {
    attribute: "txPowerValueDisplayOdm",
    title: "OFDMA TX",
    disableSearch: true,
    disableSort: true,
    isMultiValueGrid: true,
    isVerticalGrid: true,
    // jsonDataMapping: "txPowerValue",
    // getDataFrom: "docsis",
    // specInfo: {
    //   inSpec: true,
    //   outSpec: true,
    //   marginal: true,
    //   others: true,
    // },
    // displayAttribute: "txPowerValueDisplay",
    // isPinned: false,
    // isVisible: true,
    // position: 0,
  },
  {
    attribute: "merThrValValueDisplayOdm",
    title: "OFDMA PCT",
    disableSearch: true,
    disableSort: true,
    isMultiValueGrid: true,
    isVerticalGrid: true,
    // jsonDataMapping: "merThrValValue",
    // getDataFrom: "docsis",
    // specInfo: {
    //   inSpec: true,
    //   outSpec: true,
    //   marginal: true,
    //   others: true,
    // },
    // displayAttribute: "merThrValValueDisplay",
    // isPinned: false,
    // isVisible: true,
    // position: 0,
  },
  {
    attribute: "usOdmScore",
    title: "US Score",
    disableSearch: false,
    disableSort: false,
    // jsonDataMapping: "usTx",
    // //exportcsvAttribute: "usTxCsv",
    // displayAttribute: "usTxDisplay",
    // haveMultipleValues: true,
    // getDataFrom: "docsis",
    // specInfo: {
    //   inSpec: true,
    //   outSpec: true,
    //   marginal: true,
    //   others: true,
    // },
    // isPinned: false,
    // isVisible: true,
    // position: 0,
  },
  {
    title: "US Freq",
    attribute: "usFreqOdm",
    isMultiValueGrid: true,
    isVerticalGrid: true,
    disableSearch: true,
    disableSort: true,
  },
  {
    title: "US Tx",
    attribute: "usTxOdm",
    isMultiValueGrid: true,
    isVerticalGrid: true,
    disableSearch: true,
    disableSort: true,
  },
  {
    title: "US SNR",
    attribute: "usSnrOdm",
    isMultiValueGrid: true,
    isVerticalGrid: true,
    disableSearch: true,
    disableSort: true,
  },
  {
    attribute: "usPostCWEDeltaOdm",
    title: "(Δ) Post CER %",
    isMultiValueGrid: true,
    isVerticalGrid: true,
    disableSearch: true,
    disableSort: true,
    // jsonDataMapping: "usPostCWEDelta",
    // haveMultipleValues: true,
    // getDataFrom: "codewords",
    // displayAttribute: "usPostCWEDeltaDisplay",
    // specInfo: {
    //   inSpec: true,
    //   outSpec: true,
    //   marginal: true,
    //   others: true,
    // },
    // isPinned: false,
    // isVisible: true,
    // position: 0,
  },
  {
    title: "OFDM Score",
    attribute: "ofdmOdmScore",
    disableSearch: false,
    disableSort: false,
  },
  {
    attribute: "ofdmChannelIdOdm",
    title: "OFDM Channel ID",
    isMultiValueGrid: true,
    isVerticalGrid: true,
    disableSearch: true,
    disableSort: true,
    // jsonDataMapping: "ofdmChannelID",
    // haveMultipleValues: true,
    // getDataFrom: "docsis",
    // isPinned: false,
    // isVisible: true,
    // position: 0,
    // styleClassName: "medValCol",
  },

  // {
  //   attribute: "dsRxPowerDelta",
  //   title: "OFDM Rx ∆",
  //   disableSearch: true,
  //   disableSort: true,
  //   // jsonDataMapping: "dsRxPowerDelta",
  //   // haveMultipleValues: true,
  //   // getDataFrom: "docsis",
  //   // isPinned: false,
  //   // isVisible: true,
  //   // position: 0,
  //   // styleClassName: "medValCol",
  // },
  {
    attribute: "dsRxPowerDelta1Odm",
    title: "OFDM Rx ∆",
    isMultiValueGrid: true,
    isVerticalGrid: true,
    disableSearch: true,
    disableSort: true,
    // jsonDataMapping: "dsRxPowerDelta1",
    // haveMultipleValues: true,
    // getDataFrom: "docsis",
    // isPinned: false,
    // isVisible: true,
    // position: 0,
  },
  {
    attribute: "dsThrValValue1Odm",
    title: "OFDM PCT",
    isMultiValueGrid: true,
    isVerticalGrid: true,
    disableSearch: true,
    disableSort: true,
    // jsonDataMapping: "dsThrValValue1",
    // haveMultipleValues: true,
    // getDataFrom: "docsis",
    // specInfo: {
    //   inSpec: true,
    //   outSpec: true,
    //   marginal: true,
    //   others: true,
    // },
    // displayAttribute: "dsThrValValue1Display",
    // isPinned: false,
    // isVisible: true,
    // position: 0,
  },
  // {
  //   attribute: "dsThrValValue",
  //   title: "OFDM PCT",
  //   // jsonDataMapping: "dsThrValValue",
  //   // haveMultipleValues: true,
  //   // getDataFrom: "docsis",
  //   // specInfo: {
  //   //   inSpec: true,
  //   //   outSpec: true,
  //   //   marginal: true,
  //   //   others: true,
  //   // },
  //   // displayAttribute: "dsThrValValueDisplay",
  //   // isPinned: false,
  //   // isVisible: true,
  //   // position: 0,
  //   // styleClassName: "medValCol",
  // },
  {
    attribute: "ofdmPostCWEDeltaDisplayOdm",
    title: "(Δ) Post CER %",
    isMultiValueGrid: true,
    isVerticalGrid: true,
    disableSearch: true,
    disableSort: true,
    // jsonDataMapping: "ofdmPostCWEDelta",
    // haveMultipleValues: true,
    // getDataFrom: "codewords",
    // displayAttribute: "ofdmPostCWEDeltaDisplay",
    // specInfo: {
    //   inSpec: true,
    //   outSpec: true,
    //   marginal: true,
    //   others: true,
    // },
    // isPinned: false,
    // isVisible: true,
    // position: 0,
  },
  // {
  //   title: "Pre CCER %",
  //   attribute: "usPreCWEDelta",
  //   statusAttr: "certUSPreCerStatus",
  //   disableSearch: true,
  //   disableSort: true,
  // },
  // {
  //   title: "Post CER %",
  //   attribute: "usPostCWEDelta",
  //   statusAttr: "certUSPostCCerStatus",
  //   disableSearch: true,
  //   disableSort: true,
  // },
  {
    attribute: "dsOdmScore",
    title: "DS Score",
    disableSearch: false,
    disableSort: false,
  },

  {
    title: "DS Freq",
    attribute: "dsFreq",
    disableSearch: true,
    disableSort: true,
    isMultiValueGrid: true,
  },
  {
    title: "DS RX",
    attribute: "dsRxOdm",
    // statusAttr: "certRxStatus",
    disableSearch: true,
    disableSort: true,
    isMultiValueGrid: true,
  },
  {
    title: "DS SNR",
    attribute: "dsSnrOdm",
    // statusAttr: "certDSSnrStatus",
    disableSearch: true,
    disableSort: true,
    isMultiValueGrid: true,
  },
  {
    attribute: "dsPostCWEDeltaOdm",
    title: "(Δ) Post CER %",
    // statusAttr: "certDSSnrStatus",
    isMultiValueGrid: true,
    disableSearch: true,
    disableSort: true,
    // jsonDataMapping: "dsPostCWEDelta",
    // haveMultipleValues: true,
    // getDataFrom: "codewords",
    // displayAttribute: "dsPostCWEDeltaDisplay",
    // specInfo: {
    //   inSpec: true,
    //   outSpec: true,
    //   marginal: true,
    //   others: true,
    // },
    // isPinned: false,
    // isVisible: true,
    // position: 0,
  },
];

export const dataSetType = [
  {
    dataSetName: "FULL",
    subDataSetViews: [
      { columnName: "ALL", columnKey: "full", isVisible: true },
      { columnKey: "flaps", columnName: "FLAPS", isVisible: true },
      { columnKey: "full_us_sc_qam", columnName: "US SC QAM", isVisible: true },
      { columnKey: "full_ds_sc_qam", columnName: "DS SC QAM", isVisible: true },
      { columnKey: "full_ofdma", columnName: "OFDMA", isVisible: true },
      { columnKey: "full_ofdm", columnName: "OFDM", isVisible: true },
      { columnKey: "us_interfaces", columnName: "US INTERFACE", isVisible: true },
    ],
  },
  {
    dataSetName: "FULL W/O CW",
    subDataSetViews: [
      { columnKey: "full", columnName: "ALL", isVisible: true },
      { columnKey: "flaps", columnName: "FLAPS", isVisible: true },
      {
        columnKey: "full_no_cw_us_sc_qam",
        columnName: "US SC QAM",
        isVisible: true,
      },
      {
        columnKey: "full_no_cw_ds_sc_qam",
        columnName: "DS SC QAM",
        isVisible: true,
      },
      { columnKey: "full_no_cw_ofdma", columnName: "OFDMA", isVisible: true },
      { columnKey: "full_no_cw_ofdm", columnName: "OFDM", isVisible: true },
      { columnKey: "us_interfaces", columnName: "US INTERFACE", isVisible: true },
      //"CUSTOM", TBD in next release
    ],
  },
  {
    dataSetName: "BASIC",
    subDataSetViews: [
      { columnKey: "basic", columnName: "BASIC", isVisible: true },
    ],
  },
];

export const specAvailAttr = [
  //...upStream30SubAttr1,
  ...upStream30SubAttr2,
  ...upstream30SubAttr3,
  //...downstream30SubAttr1,
  //...downstream30SubAttr2,
];
