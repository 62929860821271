import AppLabels from "../../constants/App_Labels";
import {
  changeTimeFormat,
  secToTime,
  getValueByStrMapping,
  isNull,
  getAccessLevelByRoles,
} from "./Util";
import LoaderGif from "../../resources/images/loader.webp";
import "../../design-tokens/telemetry/NodeTelemetryTab.scss";
import ErrorIcon from "../../resources/images/icons/ErrorIcon.svg";
import React from "react";
import { GetTechInfo } from "../../get-api/GetResponse";
import storeConst from "../../topology/store/storeConst";
import ConfigConst from "../../constants/ConfigConst";

export const getBackgroundColourForTable = (count: number): string => {
  return count % 2 === 0 ? "#e0e0e0" : "";
};
export const getDeviceType = (deviceType: any) => {
  if (typeof deviceType !== "string") {
    return null;
  }

  if (deviceType.includes("EMTA_NCS") || deviceType.includes("EMTA_SIP")) {
    return "EMTA";
  }

  if (deviceType.includes("ECMSTB")) {
    return "ECM";
  }

  if (deviceType.includes("OLDSTB")) {
    return "STB";
  }

  return deviceType;
};

interface Address {
  aptNumber?: string;
  streetNumber?: string;
  streetName?: string;
  streetType?: string;
}
export const getTelemetryHomeAddress = (addr: Address | undefined) => {
  if (!addr) return "";

  const { aptNumber, streetNumber, streetName, streetType } = addr;

  const haveApartment = aptNumber ? `${aptNumber}-` : "";
  const haveStreetNumber = streetNumber ? `${streetNumber} ` : "";
  const haveStreetName = streetName ? `${streetName} ` : "";
  const haveStreetType = streetType ? `${streetType} ` : "";

  return `${haveApartment}${haveStreetNumber}${haveStreetName}${haveStreetType}`.trim();
};

export function conversionMapValuesToArray(nodeUpstream: any[]) {
  const values = Array.from(new Map(Object.entries(nodeUpstream)).values());
  let array: any = [];

  values.forEach((e) => {
    if (Array.isArray(e)) {
      e.forEach((val) => {
        array.push(val);
      });
    }
  });
  return array;
}

export const calculateCMUptime = (
  status: string,
  header: string,
  regTime: number
) => {
  if (
    "Inprogress"?.toLowerCase() === status?.toLowerCase() ||
    "Offline"?.toLowerCase() === status?.toLowerCase() ||
    ("Registered"?.toLowerCase() === status?.toLowerCase() &&
      header !== null &&
      header !== undefined)
  ) {
    return "";
  } else {
    return secToTime(regTime);
  }
};

export const isCMOperational = (status: string, regTime: number) => {
  if (
    status !== null &&
    status !== undefined &&
    regTime !== null &&
    regTime !== undefined
  ) {
    if ("Registered"?.toLowerCase() === status?.toLowerCase()) {
      return secToTime(regTime);
    } else {
      return "";
    }
  } else return "";
};

export const UTCtoLocalTime = (UTCTime: any) => {
  // Display options for Flap History Reset Time
  const dateOptions = {
    month: "short",
    day: "2-digit",
    year: "numeric",
  } as const;

  const myDate = new Date(UTCTime).toLocaleDateString("en-US", dateOptions);
  const myTime = new Date(UTCTime).toLocaleTimeString("en-US");
  return myTime + " - " + myDate;
};

export const checkFlaps = (nodeModemsData: any) => {
  let macListString: Array<string> = [];
  if (nodeModemsData !== undefined && nodeModemsData?.length !== 0) {
    nodeModemsData.map((d: any, index: number) => {
      if (
        d?.deviceDiag?.data !== undefined &&
        d?.deviceDiag?.status === "OK" &&
        d?.deviceDiag?.data?.flaps !== null &&
        d?.deviceDiag?.data?.flaps !== undefined
      ) {
        let flaps = d?.deviceDiag?.data?.flaps;
        if (
          (flaps?.counts && Number(flaps?.counts) > 0) ||
          (flaps?.insertionFails && Number(flaps?.insertionFails) > 0) ||
          (flaps?.hits && Number(flaps?.hits) > 0) ||
          (flaps?.miss && Number(flaps?.miss) > 0) ||
          (flaps?.powerAdjustments && Number(flaps?.powerAdjustments) > 0)
        ) {
          macListString.push(d.macAddress);
        }
      }
    });
  }
  return macListString;
};

export function getNodeTeleState(
  nodeTeleStatus: string,
  nodeTeleStartTime: any,
  nodeTeleStopTime: any
) {
  const labels = AppLabels();
  return (
    <div className="fullScreenBox">
      <span className="label">{labels?.STATUS} :</span>
      {nodeTeleStatus === ConfigConst.loading.toLowerCase() ? (
        <img className="nodeTeleDataLoader" src={LoaderGif} alt="LoaderGif" />
      ) : (
        nodeTeleStatus?.toUpperCase()
      )}
      <span className="label">Start Time : </span>
      {isNull(nodeTeleStartTime)}
      {/* {nodeTeleStartTime > 0 ? changeTimeFormat(nodeTeleStartTime) : ""} */}
      <span className="label">End Time :</span>
      {/* {nodeTeleStopTime > 0 ? changeTimeFormat(nodeTeleStopTime) : ""} */}
      {nodeTeleStatus === ConfigConst.loading.toLowerCase() ? (
        <img className="nodeTeleDataLoader" src={LoaderGif} alt="LoaderGif" />
      ) : (
        isNull(nodeTeleStopTime)
      )}
    </div>
  );
}

export function getTelemetryInfo(nodeDevicesList: any) {
  let assigned = nodeDevicesList?.length;
  let unresponsive = 0;
  let responsive = 0;
  let devices = nodeDevicesList?.map((d: any, index: number) => {
    if (d?.deviceDiag?.data) {
      if (
        d?.deviceDiag?.data?.summary === null ||
        d?.deviceDiag?.data?.summary === undefined ||
        d?.deviceDiag?.data?.summary?.uptime <= 0 ||
        d?.deviceDiag?.data?.summary?.errorCode
      ) {
        unresponsive += 1;
      } else {
        responsive += 1;
      }
    }
  });
  let active = ((100 * responsive) / assigned).toFixed(2);
  return { active, assigned, responsive, unresponsive };
}
export function getMultiNodeBasicInfoByDeviceType(accountSummaryResponse: any) {
  const deviceSummary: { [deviceType: string]: any } = {};

  accountSummaryResponse?.forEach((d: any) => {
    let deviceType = d?.deviceType;

    // Modify deviceType based on conditions
    if (deviceType?.includes("EMTA")) {
      deviceType = "EMTA";
    } else if (deviceType?.includes("OLDSTB")) {
      deviceType = "STB";
    } else if (deviceType?.includes("ECMSTB")) {
      deviceType = "ECM";
    }

    const data = d?.accountSummaryResponse?.data;
    const error = d?.accountSummaryResponse?.error;

    if (!deviceSummary[deviceType]) {
      deviceSummary[deviceType] = {
        active: 0,
        assigned: 0,
        responsive: 0,
        unresponsive: 0,
        deviceType: deviceType,
      };
    }

    if (data) {
      data.forEach((item: any) => {
        // console.log(item.regStatus, " item.regStatus");

        if (nodeStatus(item)) {
          deviceSummary[deviceType].unresponsive++;
        } else {
          deviceSummary[deviceType].responsive++;
        }
        deviceSummary[deviceType].assigned++;
      });
    } else if (error && error.errorCode) {
      deviceSummary[deviceType].unresponsive++;
    }
  });

  for (let key in deviceSummary) {
    deviceSummary[key].active = (
      (100 * deviceSummary[key].responsive) /
      deviceSummary[key].assigned
    ).toFixed(2);
  }

  return deviceSummary;
}

export function getBasicDeviceType(deviceType: any): string {
  if (!deviceType) return "";

  const types = deviceType.split(",");

  const transformedTypes = types.map((type: string) => {
    type = type.toUpperCase();

    if (type.includes("EMTA")) {
      return "EMTA";
    } else if (type.includes("OLDSTB")) {
      return "STB";
    } else if (type.includes("ECMSTB")) {
      return "ECM";
    } else if (type.includes("CM")) {
      return "CM";
    }

    return type;
  });

  return transformedTypes.join(",");
}
export function nodeStatus(item: any) {
  if (
    !item.regStatus ||
    item.regStatus?.errorCode ||
    item?.regStatus?.cmOnlineStatus?.toUpperCase() === "OFFLINE" ||
    item?.summary?.qamChannelStatus?.value?.toUpperCase() === "OFFLINE" ||
    item?.summary?.uptime <= 0 ||
    item?.regStatus?.qamChannelStatus?.value?.toUpperCase() === "OFFLINE" ||
    item?.regStatus?.uptime <= 0 ||
    item?.regStatus?.regStatus?.cmtsStatus?.toUpperCase() === "OFFLINE"
  ) {
    return true;
  } else return false;
}
export function getMultiDeviceInfo(accountSummaryResponse: any) {
  let len = 0;

  accountSummaryResponse?.map((v: any) => {
    if (v?.accountSummaryResponse?.data != null) {
      v?.accountSummaryResponse?.data?.map((data: any) => {
        len = len + 1;
      });
    } else if (v?.accountSummaryResponse?.error !== null) {
      len = len + 1;
    }
  });

  let assigned = len;
  let unresponsive = 0;
  let responsive = 0;

  accountSummaryResponse?.forEach((d: any) => {
    const data = d?.accountSummaryResponse?.data;
    const error = d?.accountSummaryResponse?.error;

    if (data) {
      let allResponsive = true;

      data.forEach((item: any) => {
        if (nodeStatus(item)) {
          allResponsive = false;
          unresponsive++;
        } else {
          responsive++;
        }
      });

      // if (allResponsive) {
      //   console.log(responsive, ": responsive");
      //   responsive++;
      // }
    } else if (error && error.errorCode) {
      unresponsive++;
    } else {
      unresponsive++;
    }
  });

  let active = ((100 * responsive) / assigned).toFixed(2);
  return { active, assigned, responsive, unresponsive };
}

export function getSpecAvailFlag(column: any, specAvailAttr: any) {
  let specFlag = false;

  specAvailAttr?.map((attr: any) => {
    if (column?.jsonDataMapping === attr?.jsonDataMapping) {
      specFlag = true;
    }
  });

  return specFlag;
}

export function getSpecAttributes(views: any, viewType: string) {
  let attributes: any[] = [];
  views?.map((v: any) => {
    if (v?.viewTypeKey?.toUpperCase() === viewType.toUpperCase()) {
      attributes = v?.specAvailAttr;
    }
  });
  return attributes;
}

export function renderSpecContent(data: any, attributes: any) {
  let status = data[attributes?.jsonDataMapping + "Status"];
  let value = data[attributes?.jsonDataMapping];
  return renderSpecColor(status, value);
}

export function filterSpecData(
  data: any,
  by: String,
  specName: String,
  flag: Boolean
) {
  let status = "";
  switch (specName) {
    case "IN-SPEC":
      status = "Pass";
      break;
    case "OUT-SPEC":
      status = "Fail";
      break;
    case "MARGINAL":
      status = "Marginal";
      break;
    case "OTHERS":
      status = "Others";
  }
  return flag
    ? data
    : status !== "Others"
    ? data?.filter((device: any) => device?.[by + "Status"] !== status)
    : data?.filter((device: any) => device?.[by + "Status"] !== undefined);
}

export const getDocsisCodewordsObjScQam = (
  docsischannels: any,
  codewordChannels: any
) => {
  let finalDocsis: any[] = [];
  let finalcodewords: any[] = [];
  if (docsischannels !== undefined && codewordChannels !== undefined) {
    for (var dChannel of docsischannels) {
      for (var cChannel of codewordChannels) {
        if (cChannel?.frequency === dChannel?.frequency) {
          finalDocsis.push(dChannel);
          finalcodewords.push(cChannel);
        }
      }
    }
  } else {
    if (docsischannels !== undefined && codewordChannels === undefined)
      return { finalDocsis: docsischannels, finalcodewords: undefined };
    else if (docsischannels === undefined && codewordChannels !== undefined)
      return { finalDocsis: undefined, finalcodewords: codewordChannels };
    else return { finalDocsis: undefined, finalcodewords: undefined };
  }

  return { finalDocsis, finalcodewords };
};

export const getCodewordsObjScQam = (
  docsischannels: any,
  codewordChannels: any
) => {
  let finalcodewords = [].concat.apply(
    [],
    docsischannels?.map((docsisChannel: any) => {
      let index = codewordChannels?.findIndex(
        (codewordChannel: any) =>
          codewordChannel?.frequency === docsisChannel?.frequency
      );
      if (index !== undefined && index !== -1) return codewordChannels[index];
      else return {};
    })
  );
  return finalcodewords;
};

export const getOfdmDocsisValueFromMap = (
  ofdmDocsischannels: any,
  valuePath: string,
  ofdmCodewordChannels: any
) => {
  let value = "";
  ofdmDocsischannels?.map((docsisChannel: any) => {
    value += getValueFromMap([docsisChannel], valuePath);
    if (ofdmCodewordChannels) {
      ofdmCodewordChannels?.map((prof: any) => {
        const profiles = prof?.hasOwnProperty("profiles")
          ? prof?.profiles
          : prof;
        profiles?.map((profile: any) => {
          if (profile?.channelID.includes(docsisChannel?.channelId))
            value += "\n";
        });
      });
    } else {
      value += "\n";
    }
  });
  return value;
};

export const getOfdmCodewordsValueFromMap = (
  ofdmCodewordChannels: any,
  valuePath: string,
  ofdmDocsischannels: any
) => {
  let value: any[] = [];
  ofdmDocsischannels?.map((docsisChannel: any) => {
    ofdmCodewordChannels?.map((prof: any) => {
      const profiles = prof?.hasOwnProperty("profiles") ? prof?.profiles : prof;
      profiles?.map((profile: any, index: number) => {
        let profChannelId = profile?.channelID;
        let docsisChannelId = docsisChannel?.channelId;
        let actualIndex = profChannelId?.indexOf(docsisChannelId);
        if (actualIndex !== -1) {
          value = [].concat.apply(value, [
            getValueFromMap([profile], valuePath),
          ]);
        }
      });
    });
  });
  return value.join("\n");
};

export const checkNullInTelemetry = (data: any) => {
  if (data === "null" || data === null || data === "" || data === undefined)
    return "";
  else return data;
};

export const getOfdmaDocsisStatus = (data: any) => {
  if (
    data !== undefined &&
    checkNullInTelemetry(data)?.toString().toLowerCase().indexOf("up") === -1
  ) {
    return (
      <div className="dsValue">
        <span>
          {data}
          <img style={{ height: "14px" }} src={ErrorIcon} alt="Error" />
        </span>
      </div>
    );
  }
};

export const getOfdmDocsisStatus = (
  ofdmDocsischannels: any,
  valuePath: string,
  ofdmCodewordChannels: any
) => {
  let component: any = [];

  ofdmDocsischannels?.map((docsisChannel: any) => {
    getValueFromMap([docsisChannel], valuePath)
      .toString()
      .toLowerCase()
      .indexOf("up") === -1
      ? component.push(
          <div
            className="dsValue"
            key={`${docsisChannel?.channelId}_${Math.random()}`}
          >
            <span>
              {getValueFromMap([docsisChannel], valuePath)}
              <img style={{ height: "14px" }} src={ErrorIcon} alt="Error" />
            </span>
          </div>
        )
      : component.push(<></>);
    ofdmCodewordChannels?.map((prof: any) => {
      prof?.profiles?.map((profile: any, index: number) => {
        if (profile?.channelID.includes(docsisChannel?.channelId))
          if (index < prof?.profiles?.length - 1) component.push(<br />);
      });
    });
    return (
      <React.Fragment
        key={`ofdm-docsis-status-${docsisChannel?.channelId + Math.random()}`}
      >
        {component}
      </React.Fragment>
    );
  });
  return (
    <React.Fragment key={`ofdm-dsdsd-status-${Math.random()}`}>
      {component}
    </React.Fragment>
  );
};
export const getValueFromMap = (
  arrayObj: any,
  valuePath: string,
  separator: string = "\n"
) => {
  if (arrayObj !== undefined) {
    return arrayObj
      ?.map((channel: any) => {
        return getValueByStrMapping(channel, valuePath);
      })
      .join(separator);
  }
};
export const getOfdmDocsisColorFromMap = (
  ofdmDocsischannels: any,
  valuePath: string,
  statusPath: string,
  ofdmCodewordChannels: any
) => {
  if (ofdmDocsischannels !== undefined) {
    return (
      <div key={`ofdm-docsis-color-${Math.random()}`}>
        {ofdmDocsischannels?.map((docsisChannel: any) => {
          return (
            <React.Fragment
              key={`ofdm-docsis-color-${
                docsisChannel?.channelId + Math.random()
              }`}
            >
              {renderSpecColor(
                getValueByStrMapping(docsisChannel, statusPath),
                getValueByStrMapping(docsisChannel, valuePath)
              )}

              {ofdmCodewordChannels?.map((prof: any) => {
                const profiles = prof?.hasOwnProperty("profiles")
                  ? prof?.profiles
                  : prof;
                return (
                  <React.Fragment key={`ofdm-docsis-color-${Math.random()}`}>
                    {profiles?.map((profile: any, index: number) => {
                      if (
                        profile?.channelID.includes(docsisChannel?.channelId) &&
                        index !== profiles?.length - 1
                      ) {
                        return (
                          <React.Fragment
                            key={`ofdmDocsisColorFromMap${
                              index + Math.random()
                            }`}
                          >
                            <br />
                          </React.Fragment>
                        );
                      } else {
                        return (
                          <React.Fragment
                            key={`ofdmDocsisColorFromMap${
                              index + Math.random()
                            }`}
                          ></React.Fragment>
                        );
                      }
                    })}
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          );
        })}
      </div>
    );
  } else {
    return (
      <React.Fragment
        key={`ofdmDocsisColorFromMap${Math.random()}`}
      ></React.Fragment>
    );
  }
};
export const getValueColorFromMap = (
  arrayObj: any,
  valuePath: string,
  statusPath: string
) => {
  if (arrayObj !== undefined) {
    return (
      <div key={`getValueColorFromMap${Math.random()}`}>
        {arrayObj?.map((channel: any) => {
          return (
            <React.Fragment key={`getValueColorFromMap${Math.random()}`}>
              {renderSpecColor(
                getValueByStrMapping(channel, statusPath),
                getValueByStrMapping(channel, valuePath)
              )}
            </React.Fragment>
          );
          // return renderSpecColor(
          //   getValueByStrMapping(channel, statusPath),
          //   getValueByStrMapping(channel, valuePath)
          // );
        })}
      </div>
    );
  }
};
export const getProfilesValueFromMap = (arrayObj: any, valuePath: any, separator = "\n") => {
  if (arrayObj === undefined) return []; 
  const profilesWithStatus = arrayObj.flatMap((channel: any) => {
    return channel?.profiles?.map((profile: any) => {
      const profileId = profile?.profileId;
      const modulationSuffix = profile?.modulation?.replace('qam', ''); 
      const profileString = `${channel?.channelId}-${profileId}-${modulationSuffix}Q`;

      return { profileString, active: profile?.active };
    });
  });

  return profilesWithStatus;
};

  export const getOFDMAProfilesValueFromMap = (arrayObj:any, valuePath:any, separator = "\n") => {
  if (arrayObj === undefined) return [];
  const profilesWithStatus = arrayObj?.map((channel:any) => {

      return channel?.iucList
        ?.map((profile:any) => {

          const iuc = profile?.iuc;
          const modulationSuffix = profile?.modulation?.replace('qam', ''); 
       
          const profileString= `${channel?.channelId}-${iuc}-${modulationSuffix}Q`;
          return { profileString, active: profile?.active };
        });
      });
    
      return profilesWithStatus;
  }
export const getOFDMValueColorFromMap = (
  arrayObj: any,
  valuePath: string,
  statusPath: string
) => {
  if (arrayObj !== undefined) {
    return (
      <div key={`getValueColorFromMap${Math.random()}`}>
        {arrayObj?.map((channel: any, index: number) => {
          return (
            <>
              {index > 0 ? " / " : ""}
              {renderOFDMSpecColor(
                getValueByStrMapping(channel, statusPath),
                getValueByStrMapping(channel, valuePath)
              )}
            </>
          );
        })}
      </div>
    );
  }
};

export const getOfdmValueColorFromMap = (
  arrayObj: any,
  valuePath: string,
  statusPath: string
) => {
  if (arrayObj !== undefined) {
    return (
      <div key={`${Math.random()}_getOfdmValue`}>
        {arrayObj?.map((prof: any) => {
          const profiles = prof?.hasOwnProperty("profiles")
            ? prof?.profiles
            : prof;
          return profiles.map((profile: any, index: number) => {
            return renderSpecColor(
              getValueByStrMapping(profile, statusPath),
              getValueByStrMapping(profile, valuePath)
            );
          });
        })}
      </div>
    );
  }
};

export const renderSpecColor = (status: string, value: string) => {
  switch (status) {
    case "Pass":
      return (
        <div
          key={`_inspeccolor${new Date().getTime()}_${Math.random()}`}
          className="inSpecState"
        >
          {checkNullInTelemetry(value)}
        </div>
      );
    case "Fail":
      return (
        <div
          key={`_rcolor${new Date().getTime()}_${Math.random()}`}
          className="outSpecState"
        >
          {checkNullInTelemetry(value)}
        </div>
      );
    case "Marginal Pass":
      return (
        <div
          key={`_marginal_${new Date().getTime()}_${Math.random()}`}
          className="marginal"
        >
          {checkNullInTelemetry(value)}
        </div>
      );
  }
};
export const renderOFDMSpecColor = (status: string, value: string) => {
  switch (status) {
    case "Pass":
      return (
        <span
          key={`_inspeccolor${new Date().getTime()}_${Math.random()}`}
          className="inSpecState"
        >
          {checkNullInTelemetry(value)}
        </span>
      );
    case "Fail":
      return (
        <span
          key={`_rcolor${new Date().getTime()}_${Math.random()}`}
          className="outSpecState"
        >
          {checkNullInTelemetry(value)}
        </span>
      );
    case "Marginal Pass":
      return (
        <span
          key={`_marginal_${new Date().getTime()}_${Math.random()}`}
          className="marginal"
        >
          {checkNullInTelemetry(value)}
        </span>
      );
  }
};

export const getValueFromMapSpec = (
  arrayObj: any,
  valuePath: string,
  statusPath: string,
  specFlag: boolean
) => {
  if (arrayObj !== undefined) {
    return arrayObj
      ?.map((channel: any) => {
        let booleanCondition = specFlag
          ? getValueByStrMapping(channel, statusPath) === "Pass"
          : getValueByStrMapping(channel, statusPath) !== "Pass";
        if (booleanCondition) return getValueByStrMapping(channel, valuePath);
      })
      .join("\n");
  }
};

export const getSpecChannels = (
  arrayObj: any,
  specInfo: any,
  attrName: string
) => {
  if (
    specInfo?.inSpec &&
    specInfo?.outSpec &&
    specInfo?.marginal &&
    specInfo?.others
  )
    return arrayObj;

  if (specInfo === undefined) return arrayObj;
  let modifiedArray: any[] = [];
  for (const [key, value] of Object.entries(specInfo)) {
    switch (key) {
      case "inSpec":
        arrayObj?.map((channel: any) => {
          if (
            value &&
            getValueByStrMapping(channel, attrName + ".status") === "Pass"
          )
            modifiedArray.push(channel);
        });
        break;
      case "outSpec":
        arrayObj?.map((channel: any) => {
          if (
            value &&
            getValueByStrMapping(channel, attrName + ".status") === "Fail"
          )
            modifiedArray.push(channel);
        });
        break;
      case "marginal":
        arrayObj?.map((channel: any) => {
          if (
            value &&
            getValueByStrMapping(channel, attrName + ".status") ===
              "Marginal Pass"
          )
            modifiedArray.push(channel);
        });
        break;
      case "others":
        if (value && arrayObj === undefined) {
          return arrayObj;
        }

        break;
    }
  }
  return modifiedArray;
};

export const getOfdmSpecChannels = (
  arrayObj: any,
  specInfo: any,
  attrName: string
) => {
  if (
    specInfo?.inSpec &&
    specInfo?.outSpec &&
    specInfo?.marginal &&
    specInfo?.others
  )
    return arrayObj;

  if (specInfo === undefined) return arrayObj;
  let modifiedArray: any[] = [];
  for (const [key, value] of Object.entries(specInfo)) {
    switch (key) {
      case "inSpec":
        arrayObj?.map((prof: any) => {
          let modifiedProf: any[] = [];
          prof?.profiles.map((profile: any, index: number) => {
            if (
              value &&
              getValueByStrMapping(profile, attrName + ".status") === "Pass"
            )
              modifiedProf.push(profile);
          });
          if (modifiedProf.length > 0) modifiedArray.push(modifiedProf);
        });
        break;
      case "outSpec":
        arrayObj?.map((prof: any) => {
          let modifiedProf: any[] = [];
          prof?.profiles.map((profile: any, index: number) => {
            if (
              value &&
              getValueByStrMapping(profile, attrName + ".status") === "Fail"
            )
              modifiedProf.push(profile);
          });
          if (modifiedProf.length > 0) modifiedArray.push(modifiedProf);
        });
        break;
      case "marginal":
        arrayObj?.map((prof: any) => {
          let modifiedProf: any[] = [];
          prof?.profiles.map((profile: any, index: number) => {
            if (
              value &&
              getValueByStrMapping(profile, attrName + ".status") ===
                "Marginal Pass"
            )
              modifiedProf.push(profile);
          });
          if (modifiedProf.length > 0) modifiedArray.push(modifiedProf);
        });
        break;
      case "others":
        if (value && arrayObj === undefined) {
          return arrayObj;
        }
        break;
    }
  }
  return modifiedArray;
  //}
};

export const getSpecValue = (data: any, specInfo: any) => {
  if (
    specInfo?.inSpec &&
    specInfo?.outSpec &&
    specInfo?.marginal &&
    specInfo?.others
  )
    return data?.value === undefined ? "" : data?.value;

  if (specInfo === undefined)
    return data?.value === undefined ? "" : data?.value;

  for (const [key, flag] of Object.entries(specInfo)) {
    switch (key) {
      case "inSpec":
        if (flag && data?.status === "Pass") return data?.value;
        //else return "";
        break;
      case "outSpec":
        if (flag && data?.status === "Fail") return data?.value;
        //else return "";
        break;
      case "marginal":
        if (flag && data?.status === "Marginal Pass") return data?.value;
        //else return "";
        break;
      case "others":
        if (flag && data?.status === undefined) return "";
        //else return "";
        break;
    }
  }
};

export const getThresholdFromMap = (data: any) => {
  let keys: string[] = [];
  if (data !== undefined) keys = Object.keys(data);

  return {
    value: data?.[keys[0]],
    threshold: data?.[keys[1]].pass,
    status: data?.[keys[2]],
  };
};

export function groupArrayOfObjects(list: any, key: any) {
  return list.reduce(function (rv: any, x: any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export class AvgUSFrequencies {
  frequency: number;
  usTx: number;
  usSNR: number;
  usSNRStatus?: string;
  usTXStatus?: string;

  constructor(
    frequency: number,
    usTx: number,
    usSNR: number,
    usSNRStatus?: string,
    usTXStatus?: string
  ) {
    this.frequency = frequency;
    this.usTx = usTx;
    this.usSNR = usSNR;
    this.usSNRStatus = usSNRStatus;
    this.usTXStatus = usTXStatus;
  }
}

export class AvgDSFrequencies {
  frequency: number;
  dsRx: number;
  dsSNR: number;
  dsRxStatus?: string;
  dsSNRStatus?: string;

  constructor(
    frequency: number,
    dsRx: number,
    dsSNR: number,
    dsRxStatus?: string,
    dsSNRStatus?: string
  ) {
    this.frequency = frequency;
    this.dsRx = dsRx;
    this.dsSNR = dsSNR;
    this.dsSNRStatus = dsSNRStatus;
    this.dsRxStatus = dsRxStatus;
  }
}

export class AvgOFDMAFrequencies {
  channelId: string;
  maxModulation: string;
  ofdmaTx: number;
  ofdmaPCT: number;
  ofdmaTxStatus?: string;
  ofdmPCTStatus?: string;

  constructor(
    channelId: string,
    maxModulation: string,
    ofdmaTx: number,
    ofdmaPCT: number,
    ofdmaTxStatus?: string,
    ofdmPCTStatus?: string
  ) {
    this.channelId = channelId;
    this.maxModulation = maxModulation;
    this.ofdmaTx = ofdmaTx;
    this.ofdmaPCT = ofdmaPCT;
    this.ofdmaTxStatus = ofdmaTxStatus;
    this.ofdmPCTStatus = ofdmPCTStatus;
  }
}

export class AvgOFDMFrequencies {
  channelId: string;
  maxModulation: string;
  ofdmRxMin: number;
  ofdmRxMax: number;
  ofdmPCT: number;
  ofdmRxMinStatus?: string;
  ofdmRxMaxStatus?: string;
  ofdmPCTStatus?: string;

  constructor(
    channelId: string,
    maxModulation: string,
    ofdmRxMin: number,
    ofdmRxMax: number,
    ofdmPCT: number,
    ofdmRxMinStatus?: string,
    ofdmRxMaxStatus?: string,
    ofdmPCTStatus?: string
  ) {
    this.channelId = channelId;
    this.maxModulation = maxModulation;
    this.ofdmRxMin = ofdmRxMin;
    this.ofdmRxMax = ofdmRxMax;
    this.ofdmPCT = ofdmPCT;
    this.ofdmRxMinStatus = ofdmRxMinStatus;
    this.ofdmRxMaxStatus = ofdmRxMaxStatus;
    this.ofdmPCTStatus = ofdmPCTStatus;
  }
}

export function calculatingNodeAveragesData(data: any, setNodeAvgData: any) {
  let avgUS: AvgUSFrequencies[] = [];
  let avgDS: AvgDSFrequencies[] = [];
  let avgOFDM: AvgOFDMFrequencies[] = [];
  let avgOFDMA: AvgOFDMAFrequencies[] = [];

  Object.entries(data)?.forEach((element: any) => {
    const channelsUS =
      element[1]?.deviceDiag?.data?.docsis?.docsis30?.upstream?.channels || [];
    const channelsDS =
      element[1]?.deviceDiag?.data?.docsis?.docsis30?.downstream?.channels ||
      [];

    const ofdmChannels =
      element[1]?.deviceDiag?.data?.docsis?.ofdm?.downstreamChannels || [];
    const ofdmaChannels =
      element[1]?.deviceDiag?.data?.docsis?.ofdm?.upstream?.channels || [];
    const codeWords =
      element[1]?.deviceDiag?.data?.codewords?.ofdmaUsChannels?.[0]?.iucList ||
      [];

    channelsUS?.forEach((element: any) => {
      avgUS?.push(
        new AvgUSFrequencies(
          element?.frequency,
          element?.txPower?.value,
          element?.snr.value
        )
      );
    });

    channelsDS?.forEach((element: any) => {
      avgDS?.push(
        new AvgDSFrequencies(
          element?.frequency,
          element?.rx_power?.value,
          element?.snr.value
        )
      );
    });

    ofdmChannels?.forEach((element: any) => {
      avgOFDM?.push(
        new AvgOFDMFrequencies(
          element?.channelId,
          element?.maxModulation,
          element?.minRxPower?.value,
          element?.maxRxPower?.value,
          element?.mer?.thrVal.value
        )
      );
    });

    ofdmaChannels?.forEach((element: any, index: any) => {
      avgOFDMA?.push(
        new AvgOFDMAFrequencies(
          codeWords[index]?.channelID,
          element?.maxModulation,
          element?.txPower?.value,
          element?.mer?.thrVal.value
        )
      );
    });

    const freqGroupByData = groupArrayOfObjects(avgUS, "frequency");
    const freqDSGroupByData = groupArrayOfObjects(avgDS, "frequency");
    const channelOFDMGroupByData = groupArrayOfObjects(avgOFDM, "channelId");
    const channelOFDMAGroupByData = groupArrayOfObjects(avgOFDMA, "channelId");

    let frequenciesAvg: any = [];
    let freqDSAvg: any = [];
    let ofdmChannelAvg: any = [];
    let ofdmaChannelAvg: any = [];

    Object.entries(freqGroupByData)?.forEach((e) => {
      const freq: any = e[1];
      const avgFrequencies = new AvgUSFrequencies(
        Number(e[0]),
        Number(
          (
            freq?.reduce((accumulator: any, obj: any) => {
              return accumulator + Number(obj?.usTx);
            }, 0) / freq.length
          )?.toFixed(2)
        ),
        Number(
          (
            freq?.reduce((accumulator: any, obj: any) => {
              return accumulator + Number(obj?.usSNR);
            }, 0) / freq.length
          )?.toFixed(2)
        )
      );

      frequenciesAvg.push(avgFrequencies);
    });

    Object.entries(freqDSGroupByData)?.forEach((e) => {
      const freq: any = e[1];

      const avgFrequencies = new AvgDSFrequencies(
        Number(e[0]),
        Number(
          (
            freq?.reduce((accumulator: any, obj: any) => {
              return accumulator + Number(obj?.dsRx);
            }, 0) / freq.length
          )?.toFixed(2)
        ),
        Number(
          (
            freq?.reduce((accumulator: any, obj: any) => {
              return accumulator + Number(obj?.dsSNR);
            }, 0) / freq.length
          )?.toFixed(2)
        )
      );

      freqDSAvg.push(avgFrequencies);
    });

    Object.entries(channelOFDMGroupByData)?.forEach((e) => {
      const freq: any = e[1];

      const avgFrequencies = new AvgOFDMFrequencies(
        e[0],
        freq?.reduce((accumulator: any, obj: any) => {
          accumulator["maxModulation"] = obj["maxModulation"];
          return accumulator;
        }, {})?.maxModulation,
        Number(
          (
            freq?.reduce((accumulator: any, obj: any) => {
              return accumulator + Number(obj?.ofdmRxMin);
            }, 0) / freq.length
          )?.toFixed(2)
        ),
        Number(
          (
            freq?.reduce((accumulator: any, obj: any) => {
              return accumulator + Number(obj?.ofdmRxMax);
            }, 0) / freq.length
          )?.toFixed(2)
        ),
        Number(
          (
            freq?.reduce((accumulator: any, obj: any) => {
              return accumulator + Number(obj?.ofdmPCT);
            }, 0) / freq.length
          )?.toFixed(2)
        )
      );

      ofdmChannelAvg.push(avgFrequencies);
    });

    Object.entries(channelOFDMAGroupByData)?.forEach((e) => {
      const freq: any = e[1];

      const avgFrequencies = new AvgOFDMAFrequencies(
        e[0],
        freq?.reduce((accumulator: any, obj: any) => {
          accumulator["maxModulation"] = obj["maxModulation"];
          return accumulator;
        }, {})?.maxModulation,
        Number(
          (
            freq?.reduce((accumulator: any, obj: any) => {
              return accumulator + Number(obj?.ofdmaTx);
            }, 0) / freq.length
          )?.toFixed(2)
        ),
        Number(
          (
            freq?.reduce((accumulator: any, obj: any) => {
              return accumulator + Number(obj?.ofdmaPCT);
            }, 0) / freq.length
          )?.toFixed(2)
        )
      );

      ofdmaChannelAvg.push(avgFrequencies);
    });

    setNodeAvgData({
      upStreamFreq: frequenciesAvg,
      downStreamFreq: freqDSAvg,
      ofdmaFreq: ofdmaChannelAvg,
      ofdmFreq: ofdmChannelAvg,
    });
  });
}

export const getTechRoles = (
  techData: any,
  role = ConfigConst.MT as string
) => {
  return (
    // techData?.roles?.includes("other_user") ||
    techData?.roles?.includes("admin") ||
    //techData?.roles?.includes("maintenance_tech") ||
    // techData?.roles?.includes("manager") ||
    techData?.roles?.includes("business_admin") ||
    // techData?.roles?.includes("maintenance_tech_manager")
    getAccessLevelByRoles(techData?.roles).includes(role)
  );
};
export const getModemsData = (
  cmMac: any,
  nodeModemsList: any,
  nodeInfo: any
) => {
  let modemDetails: any = nodeModemsList?.find(
    (m: any) => m?.macAddress === cmMac
  );
  let dataObj = {
    cmData: {
      serialNumber: modemDetails?.serialNumber,
      macAddress: modemDetails?.macAddress,
      model: modemDetails?.model,
      serviceArea: modemDetails?.serviceArea,
      serviceAddress: modemDetails?.serviceAddress,
      returnSegment: modemDetails?.returnSegment,
      deviceType: modemDetails?.deviceType,
      cmtsIp: modemDetails?.cmtsIp,
      phub: nodeInfo?.phub,
      cmtsName: modemDetails?.cmtsName,
      deviceDiag: {
        data: modemDetails?.deviceDiag?.data,
        status: modemDetails?.deviceDiag?.status,
      },
    },
    nodeData: {
      nodeId: nodeInfo?.nodeId,
      macDomain: nodeInfo?.macDomain,
    },
  };
  return dataObj;
};

export const getNodeTeleGloabalStatus = (
  allDiagStatus: any,
  allDiagIsLoading: any,
  isAborted: any
) => {
  if (allDiagIsLoading) return ConfigConst.loading.toLowerCase();
  else if (allDiagStatus === storeConst.OK)
    return ConfigConst.complete.toLowerCase();
  else if (isAborted) return ConfigConst.stopped.toLowerCase();
  else return allDiagStatus;
};

export const addCertificateData = (actualData: any, newPayload: any) => {
  let finalData = actualData;
  if (actualData?.length > 0) {
    actualData?.map((data: any, i: number) => {
      if (data?.id === newPayload?.id) {
        let tempData = data;
        return {
          ...tempData,
          ...{ mlData: JSON.parse(atob(newPayload?.certData)) },
        };
      } else {
        return data;
      }
    });
  } else {
    finalData = [
      {
        ...newPayload,
        ...{ mlData: JSON.parse(atob(newPayload?.certData)) },
      },
    ];
  }
  return finalData;
};

export const isFullDiagWithCW = (teleData: any) => {
  if (teleData && teleData?.length > 0) {
    return teleData?.some(
      (e: any) =>
        e.deviceDiag?.data?.docsis !== undefined &&
        e.deviceDiag?.data?.docsis !== null &&
        e.deviceDiag?.data?.docsis !== "" &&
        e.deviceDiag?.data?.codewords !== undefined &&
        e.deviceDiag?.data?.codewords !== null &&
        e.deviceDiag?.data?.codewords !== ""
    );
  }
};
export const parseNodeData=(
  data:any
)=>{
  const num= data?.split(',');
  if(num?.length>0){
const parseNum=num.map((num:any,index:any)=>{
  return ((index+1)%3 ===0 && index!== num.length -1 )? num + '\n':num;
});
return parseNum.join(',');
  }
}

export const getStbTypeValue = (typeValue: string) => {
  switch (typeValue) {
    case "2":
      return "SARA";
    case "5":
      return "RTN";
    default:
      return "";
  }
};
export const getCmScore = (docsis: any, codewords: any) => {
  let cmScore = 0;
  if (docsis !== undefined && docsis?.odmScores !== undefined) {
    let odmScrDocsisObj = docsis?.odmScores;
    if (odmScrDocsisObj) {
      cmScore =
        handleNan(odmScrDocsisObj?.usScqamScore) +
        handleNan(odmScrDocsisObj?.dsScqamScore) +
        handleNan(odmScrDocsisObj?.ofdmScore) +
        handleNan(odmScrDocsisObj?.ofdmaScore) +
        handleNan(codewords?.usCerPostCWDeltaScores) +
        handleNan(codewords?.dsCerPostCWDeltaScores);
    }
  }
  return cmScore;
};

export const getOdmCertStatus = (cmScore: any, docsis: any) => {
  if (!isNaN(cmScore)) {
    if (cmScore > 75) {
      return "critical";
    } else if (
      cmScore <= 75 &&
      docsis !== undefined &&
      docsis?.odmScores !== undefined
    ) {
      return "normal";
    } else {
      return "";
    }
  }
};

export const getOfdmaScore = (docsis: any) => {
  let ofdmaScore = 0;
  if (docsis !== undefined && docsis?.odmScores !== undefined) {
    let odmScrDocsisObj = docsis?.odmScores;
    if (odmScrDocsisObj) {
      ofdmaScore = odmScrDocsisObj?.ofdmaScore;
    }
  }
  return ofdmaScore;
};

export const getOfdmScore = (docsis: any) => {
  let ofdmScore = 0;
  if (docsis !== undefined && docsis?.odmScores !== undefined) {
    let odmScrDocsisObj = docsis?.odmScores;
    if (odmScrDocsisObj) {
      ofdmScore = odmScrDocsisObj?.ofdmScore;
    }
  }
  return ofdmScore;
};

export const getUsOdmScore = (docsis: any, codewords: any) => {
  let usScore = 0;
  if (docsis != undefined && docsis?.odmScores != undefined) {
    let odmScrDocsisObj = docsis?.odmScores;
    if (odmScrDocsisObj) {
      usScore =
        odmScrDocsisObj?.usScqamScore + odmScrDocsisObj?.usCerPostCWDeltaScore;
    }
  }
  return getNumberorFloat(usScore);
};

export const getDsOdmScore = (docsis: any, codewords: any) => {
  let dsScore = 0;
  if (docsis != undefined && docsis?.odmScores != undefined) {
    let odmScrDocsisObj = docsis?.odmScores;
    if (odmScrDocsisObj) {
      dsScore =
        odmScrDocsisObj?.dsScqamScore + odmScrDocsisObj?.dsCerPostCWDeltaScore;
    }
  }
  return getNumberorFloat(dsScore);
};

export const getOdmRangeColorFromMap = (
  arrayObj: any,
  valuePath: string,
  statusPath: string
) => {
  if (arrayObj !== undefined) {
    return (
      <div key={`getOdmRangeColorFromMap${Math.random()}`}>
        {arrayObj?.map((channel: any) => {
          const cellStatus =
            getValueByStrMapping(channel, statusPath)?.indexOf(";") !== -1
              ? getValueByStrMapping(channel, statusPath)?.replace(";", "")
              : "";
          return (
            <span
              style={{
                padding: "4px",
                whiteSpace: "pre-wrap",
                backgroundColor: cellStatus ?? "",
              }}
            >
              <React.Fragment
                key={`arr_getOdmRangeColorFromMap${Math.random()}`}
              >
                {renderOdmRangeColor(getValueByStrMapping(channel, valuePath))}
              </React.Fragment>
            </span>
          );
        })}
      </div>
    );
  }
};

export const getOdmOfdmValueColorFromMap = (
  arrayObj: any,
  valuePath: string,
  statusPath: string
) => {
  if (arrayObj !== undefined) {
    return (
      <div key={`${Math.random()}_getOdmOfdmValue`}>
        {arrayObj
          ?.map((prof: any) => {
            const profiles = prof?.hasOwnProperty("profiles")
              ? prof?.profiles
              : prof;
            return profiles?.map((profile: any, index: number) => {
              const cellStatus =
                getValueByStrMapping(profile, statusPath).indexOf(";") !== -1
                  ? getValueByStrMapping(profile, statusPath).replace(";", "")
                  : "";
              return (
                <span
                  style={{
                    padding: "4px",
                    whiteSpace: "pre-wrap",
                    backgroundColor: cellStatus ?? "",
                  }}
                >
                  <React.Fragment
                    key={`arr_getOdmOfdmValueColorFromMap${Math.random()}`}
                  >
                    {renderOdmRangeColor(
                      getValueByStrMapping(profile, valuePath)
                    )}
                  </React.Fragment>
                </span>
              );
            });
          })
          .reduce((memo: any, it: any) => memo.concat(it), [])}
      </div>
    );
  }
};

export const getOdmOfdmDocsisValFromMap = (
  ofdmDocsischannels: any,
  valuePath: string,
  ofdmCodewordChannels: any,
  statusPath: string
) => {
  let ofdmData: any = [];
  ofdmDocsischannels?.map((docsisChannel: any) => {
    let ofdmaCh = {
      val: getValueFromMap([docsisChannel], valuePath),
      color:
        getValueByStrMapping([docsisChannel], statusPath)?.indexOf(";") !== -1
          ? getValueByStrMapping([docsisChannel], statusPath)?.replace(";", "")
          : "",
    };
    if (ofdmCodewordChannels) {
      ofdmCodewordChannels?.map((prof: any) => {
        const profiles = prof?.hasOwnProperty("profiles")
          ? prof?.profiles
          : prof;
        profiles?.map((profile: any) => {
          if (
            profile?.channelID?.includes(docsisChannel?.channelId) &&
            profile?.channelID?.indexOf("profile-0") !== -1
          ) {
            ofdmData.push(ofdmaCh);
          } else if (profile?.channelID?.includes(docsisChannel?.channelId)) {
            ofdmData.push({ val: "-", color: "" });
          }
        });
      });
    }
  });

  if (ofdmData !== undefined && ofdmData?.length > 0) {
    return (
      <div key={`${Math.random()}_getOdmOdmOfdmVal`}>
        {ofdmData?.map((prof: any) => {
          return (
            <span
              style={{
                padding: "4px",
                whiteSpace: "pre-wrap",
              }}
            >
              <React.Fragment
                key={`arr_getOdmOfdmValueColorFromMap${Math.random()}`}
              >
                {prof?.val}
              </React.Fragment>
            </span>
          );
        })}
      </div>
    );
  }
};

export const getOdmOfdmCWValFromMap = (
  ofdmCodewordChannels: any,
  valuePath: string,
  ofdmDocsischannels: any
) => {
  let value: any[] = [];
  ofdmDocsischannels?.map((docsisChannel: any) => {
    ofdmCodewordChannels?.map((prof: any) => {
      const profiles = prof?.hasOwnProperty("profiles") ? prof?.profiles : prof;
      profiles?.map((profile: any, index: number) => {
        let profChannelId = profile?.channelID;
        let docsisChannelId = docsisChannel?.channelId;
        let actualIndex = profChannelId?.indexOf(docsisChannelId);
        if (actualIndex !== -1) {
          value = [].concat.apply(value, [
            getValueFromMap([profile], valuePath),
          ]);
        }
      });
    });
  });

  if (value !== undefined) {
    return (
      <div key={`${Math.random()}_getOdmOdmOfdmVal`}>
        {value?.map((prof: any) => {
          return (
            <span
              style={{
                padding: "4px",
                whiteSpace: "pre-wrap",
              }}
            >
              <React.Fragment
                key={`arr_getOdmOfdmValColorFromMap${Math.random()}`}
              >
                {checkNullInTelemetry(prof)?.length > 0 &&
                  checkNullInTelemetry(prof)?.substring(0, 2)}
              </React.Fragment>
            </span>
          );
        })}
      </div>
    );
  }
};

export const renderOdmRangeColor = (value: string) => {
  return (
    <div key={`_inspeccolor${new Date().getTime()}_${Math.random()}`}>
      {checkNullInTelemetry(value)}
    </div>
  );
};

export const handleNan = (val: string) => {
  if (isNaN(parseFloat(val))) {
    return 0;
  } else {
    return parseFloat(val);
  }
};

export const getNumberorFloat = (number: any) => {
  return Number.isInteger(number) ? number : handleNan(number).toFixed(1);
};
