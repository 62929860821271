import AppLabels from "../../constants/App_Labels";

export const emptyCode = {
  key: "",
  shortDesc: "",
  desc: "",
};

export const codesObject = {
  faultCode: emptyCode,
  networkCode: emptyCode,
  activityCode: emptyCode,
  causeCode: emptyCode,
  preventionCode: emptyCode,
  additCode: [],
  comment: "",
};

export const faultCodes = () => {
  const labels = AppLabels();
  return [
    {
      key: "1A",
      shortDesc: labels.Og_Activity_Nff,
      description: labels.Performed_Outage_Activity_No_Fault_Found,
    },
    {
      key: "1B",
      shortDesc: labels.Service_Ref_Nff,
      description: labels.Performed_Service_Referral_Activity_No_Fault_Found,
    },
    {
      key: "1C",
      shortDesc: labels.Dm_Mtce_Nff,
      description: labels.Performed_Dem_Mtce_Activity_No_Fault_Found,
    },
    {
      key: "1D",
      shortDesc: labels.Pm_Mtce_Nff,
      description: labels.Performed_Preventative_Mtce_Activity_No_Fault_Found,
    },
    {
      key: "1E",
      shortDesc: labels.No_Fwd_Signal,
      description: labels.No_Forward_Signal,
    },
    {
      key: "1F",
      shortDesc: labels.No_Rtn_Signal,
      description: labels.No_Return_Signal,
    },
    {
      key: "1G",
      shortDesc: labels.No_Fwd_Ret_Signal,
      description: labels.No_Fwd_Ret_Signal,
    },
    {
      key: "1H",
      shortDesc: labels.Fwd_Signal_Level_Oos,
      description: labels.Forward_Signal_Levels_Out_Of_Spec,
    },
    {
      key: "1I",
      shortDesc: labels.Rtn_Sigan_Level_Oos,
      description: labels.Return_Signal_Levels_Out_Of_Spec,
    },
    {
      key: "1J",
      shortDesc: labels.Fwd_Ret_Signal_Level_Oos,
      description: labels.Forward_Return_Signal_Levels_Out_Of_Spec,
    },
    {
      key: "1K",
      shortDesc: labels.Impair_Dst_Fwd_S,
      description: labels.Visible_Impaired_Distorted_Fwd_Signal,
    },
    {
      key: "1L",
      shortDesc: labels.Signal_Leak_Oos,
      description: labels.Signal_Leakage_Out_Of_Spec,
    },
    {
      key: "1M",
      shortDesc: labels.Rtn_Noise_Ingress,
      description: labels.Return_Noise_Ingress,
    },
    {
      key: "1N",
      shortDesc: labels.General_Equipment_Housing_Damage,
      description: labels.General_Equipment_Housing_Damage,
    },
    {
      key: "1O",
      shortDesc: labels.Smt_Alarm,
      description: labels.Smt_Alarm,
    },
    {
      key: "1P",
      shortDesc: labels.He_Provi_Source,
      description: labels.Head_End_Provisioning_Source_Problem,
    },
    {
      key: "1Q",
      shortDesc: labels.Power_Supply_In_Standby,
      description: labels.Power_Supply_In_Standby,
    },
    {
      key: "1R",
      shortDesc: labels.Power_Supply_Fault,
      description: labels.Power_Supply_Fault,
    },
    {
      key: "1Z",
      shortDesc: labels.Other_Fault_Fnd,
      description: labels.Other_Fault_Found,
    },
    {
      key: "",
      shortDesc: "",
      desc: "",
    },
  ];
};

export const networkCodes = () => {
  const labels = AppLabels();
  return [
    {
      key: "2A",
      shortDesc: labels.Head_End,
      description: labels.Head_End_Provisioning_Source_Problem,
    },
    {
      key: "2B",
      shortDesc: labels.Fibre_Cable,
      description: labels.Fibre_Cable,
    },
    {
      key: "2C",
      shortDesc: labels.Fiber_Connector,
      description: labels.Fibre_Connector,
    },
    {
      key: "2D",
      shortDesc: labels.Node_Rf_I_F_Cable,
      description: labels.Node_Rf_Interface_Cable_Board,
    },
    {
      key: "2E",
      shortDesc: labels.Node_Optical_Rec,
      description: labels.Node_Optical_Receiver,
    },
    {
      key: "2F",
      shortDesc: labels.Node_Optical_Trans,
      description: labels.Node_Optical_Transmitter,
    },
    {
      key: "2G",
      shortDesc: labels.Node_Rf_Mod,
      description: labels.Node_Rf_Module,
    },
    {
      key: "2H",
      shortDesc: labels.Node_Housing,
      description: labels.Node_Housing,
    },
    {
      key: "2I",
      shortDesc: labels.Trunk_Cable,
      description: labels.Trunk_Cable,
    },
    {
      key: "2J",
      shortDesc: labels.Trunk_Amplifier,
      description: labels.Trunk_Amplifier,
    },
    {
      key: "2K",
      shortDesc: labels.Trunk_Cn_Cu_Sp_Pi,
      description: labels.Trunk_Connector_Coupler_Splitters_Power_Inserters,
    },
    {
      key: "2L",
      shortDesc: labels.Trunk_Prm_Pwr_Sply,
      description: labels.Trunk_Primary_Power_Supply_Ac,
    },
    {
      key: "2M",
      shortDesc: labels.Trunk_Upd_Pwr_Sply,
      description: labels.Trunk_Backup_Ups_Power_Supply_Dc,
    },
    {
      key: "2N",
      shortDesc: labels.Dist_Cable,
      description: labels.Distribution_Cable,
    },
    {
      key: "2O",
      shortDesc: labels.Dist_Line_Extender,
      description: labels.Distribution_Lx_Amp,
    },
    {
      key: "2P",
      shortDesc: labels.Dist_Connec_Cplrs_Split,
      description: labels.Distribution_Connectors_Couplers_Splitters,
    },
    {
      key: "2Q",
      shortDesc: labels.Mulittap_Plate,
      description: labels.Mulitap_Plate,
    },
    {
      key: "2R",
      shortDesc: labels.Multitap_Housing,
      description: labels.Multitap_Housing,
    },
    {
      key: "2S",
      shortDesc: labels.Drop_Cable,
      description: labels.Drop_Cable,
    },
    {
      key: "2T",
      shortDesc: labels.Drop_Amplifier,
      description: labels.Drop_Amplifier,
    },
    {
      key: "2U",
      shortDesc: labels.Drop_Connector,
      description: labels.Drop_Conectors,
    },
    {
      key: "2V",
      shortDesc: labels.Cse,
      description: labels.Cse_Splitters_Grd_Blocks_Splices,
    },
    {
      key: "2W",
      shortDesc: labels.Int_Cab_Spltter_Wallp,
      description: labels.Internal_Cabling_Splitter_Wallplate,
    },
    {
      key: "2X",
      shortDesc: labels.Rogers_Htd,
      description: labels.Rogers_Htd_Modem_Dstb_Voip,
    },
    {
      key: "2Y",
      shortDesc: labels.Customer_Equip,
      description: labels.Customer_Equipment_Tv_Pc_Phone,
    },
    {
      key: "2Z",
      shortDesc: labels.Other_Fault_Loc,
      description: labels.Other_Fault_Location,
    },
    {
      key: "6A",
      shortDesc: labels.Lobby_Cam_R_O,
      description: labels.Lobby_Camera_Rogers_Owned,
    },
    {
      key: "6B",
      shortDesc: labels.Lobby_Cam_C_O,
      description: labels.Lobby_Camera_Customer_Owned,
    },
    {
      key: "6C",
      shortDesc: labels.Traffic_Light,
      description: labels.Traffic_Light,
    },
    {
      key: "",
      shortDesc: "",
      desc: "",
    },
  ];
};

export const activityCodes = () => {
  const labels = AppLabels();
  return [
    {
      key: "3A",
      shortDesc: labels.Replaced_Net_Asset,
      description: labels.Replaced_Network_Asset,
    },
    {
      key: "3B",
      shortDesc: labels.Repaired_Net_Asset,
      description: labels.Repaired_Network_Asset,
    },
    {
      key: "3C",
      shortDesc: labels.Tightened_Equip,
      description: labels.Tightened_Equipment,
    },
    {
      key: "3D",
      shortDesc: labels.Ran_Temp_Line,
      description: labels.Ran_Temp_Line,
    },
    {
      key: "3E",
      shortDesc: labels.Adj_Signal_Level,
      description: labels.Adjusted_Signal_Levels,
    },
    {
      key: "3F",
      shortDesc: labels.ReSetup_ReAlign,
      description: labels.ReSet_Up_ReAlignment,
    },
    {
      key: "3G",
      shortDesc: labels.Cable_Spliced,
      description: labels.Cable_Spliced,
    },
    {
      key: "3H",
      shortDesc: labels.Inst_Noise_Filter,
      description: labels.Installed_Noise_Filter,
    },
    {
      key: "3I",
      shortDesc: labels.Installed_Lkg_Pad,
      description: labels.Installed_Leakage_Pad,
    },
    {
      key: "3J",
      shortDesc: labels.Inst_Drop_Amp,
      description: labels.Installed_Drop_Amp,
    },
    {
      key: "3K",
      shortDesc: labels.OnSite_Verify,
      description: labels.OnSite_Verification_Performed,
    },
    {
      key: "3L",
      shortDesc: labels.Cable_Located,
      description: labels.Cable_Located,
    },
    {
      key: "3M",
      shortDesc: labels.Worked_On_S_P,
      description: labels.Worked_On_Special_Project,
    },
    {
      key: "3N",
      shortDesc: labels.Assisted_Tech,
      description: labels.Assisted_Another_Technician,
    },
    {
      key: "3O",
      shortDesc: labels.Pwrg_Pwr_Supply,
      description: labels.Used_Truck_For_Powering_Power_Supply,
    },
    {
      key: "3P",
      shortDesc: labels.Met_W_Contractor,
      description: labels.Met_With_Contractor,
    },
    {
      key: "3Q",
      shortDesc: labels.Id_Esc_Fault_Fnd,
      description: labels.Identified_Escalated_Faults_Found,
    },
    {
      key: "3R",
      shortDesc: labels.Exchange_Transponder,
      description: labels.Exchange_Transponder,
    },
    {
      key: "3S",
      shortDesc: labels.Installed_Portable_Generator,
      description: labels.Installed_Portable_Generator,
    },
    {
      key: "3T",
      shortDesc: labels.Generator_Missing_Or_Vandalized,
      description: labels.Generator_Missing_Or_Vandalized,
    },
    {
      key: "3Z",
      shortDesc: labels.Oth_Activity_Perf,
      description: labels.Other_Actvity_Performed,
    },
    {
      key: "",
      shortDesc: "",
      desc: "",
    },
  ];
};

export const causeCodes = () => {
  const labels = AppLabels();
  return [
    {
      key: "4A",
      shortDesc: labels.Acc_Pprt_Damage,
      description: labels.Accidental_Property_Damage,
    },
    {
      key: "4B",
      shortDesc: labels.Vandalism,
      description: labels.Vandalism,
    },
    {
      key: "4C",
      shortDesc: labels.Env_Conditions,
      description: labels.Environmental_Conditions,
    },
    {
      key: "4D",
      shortDesc: labels.Ex_Equip_Degrad,
      description: labels.Expected_Equipment_Degration,
    },
    {
      key: "4E",
      shortDesc: labels.Moisture,
      description: labels.Moisture,
    },
    {
      key: "4F",
      shortDesc: labels.Plant_Install_Nts,
      description: labels.Plant_Not_Installed_To_Spec,
    },
    {
      key: "4G",
      shortDesc: labels.Plant_Locate_Nts,
      description: labels.Plant_Not_Located_To_Spec,
    },
    {
      key: "4H",
      shortDesc: labels.Plant_Funct_Nts,
      description: labels.Plant_Not_Functioning_To_Spec,
    },
    {
      key: "4I",
      shortDesc: labels.Plant_Design_Nts,
      description: labels.Plant_Not_Designed_To_Spec,
    },
    {
      key: "4J",
      shortDesc: labels.Pre_Plnt_Repr_Nts,
      description: labels.Previous_Plant_Repair_Not_To_Spec,
    },
    {
      key: "4K",
      shortDesc: labels.Recent_Consnts,
      description: labels.Recent_Rebuild_No_To_Spec,
    },
    {
      key: "4L",
      shortDesc: labels.Recent_Rebuild_Nts,
      description: labels.Recent_Rebuild_No_To_Spec,
    },
    {
      key: "4M",
      shortDesc: labels.Rec_Inst_Equip_Fail,
      description: labels.Recent_Installed_Equip_Not_To_Spec,
    },
    {
      key: "4N",
      shortDesc: labels.Comp_Alignmnt_Oos,
      description: labels.Component_Alignment_Not_To_Spec,
    },
    {
      key: "4O",
      shortDesc: labels.Loose_Conn,
      description: labels.Loose_Connectors,
    },
    {
      key: "4P",
      shortDesc: labels.Corroded_Conn,
      description: labels.Corroded_Connections,
    },
    {
      key: "4Q",
      shortDesc: labels.Grdng_Short_Fault,
      description: labels.Grounding_Short_Fault,
    },
    {
      key: "4R",
      shortDesc: labels.Cut_Cable,
      description: labels.Cut_Cable,
    },
    {
      key: "4S",
      shortDesc: labels.Crack_Damaged_Cable,
      description: labels.Cracked_Damaged_Cable,
    },
    {
      key: "4T",
      shortDesc: labels.Cable_Bending_Oos,
      description: labels.Cable_Bending_Out_Of_Spec,
    },
    {
      key: "4U",
      shortDesc: labels.Prim_Pwr_Og_Oos,
      description: labels.Primary_Power_Outage_Surge_Out_Of_Spec,
    },
    {
      key: "4V",
      shortDesc: labels.Bkup_Pwr_Og_Oos,
      description: labels.Backup_Power_Outage_Surge_Out_Of_Spec,
    },
    {
      key: "4W",
      shortDesc: labels.Blow_Fuse_Ac_Brkr,
      description: labels.Blown_Fuse_Tripeed_Ac_Breaker,
    },
    {
      key: "4X",
      shortDesc: labels.Cause_Unknown,
      description: labels.Cause_Unknown,
    },
    {
      key: "4Z",
      shortDesc: labels.Oth_Cause_Determ,
      description: labels.Other_Cause_Determined,
    },
    {
      key: "",
      shortDesc: "",
      desc: "",
    },
  ];
};

export const preventionCodes = () => {
  const labels = AppLabels();
  return [
    {
      key: "5A",
      shortDesc: labels.Bet_Prot_Of_Equip,
      description: labels.Better_Protection_Of_Equipment,
    },
    {
      key: "5B",
      shortDesc: labels.Waterproof_Equip,
      description: labels.Waterproof_Equipment,
    },
    {
      key: "5C",
      shortDesc: labels.Early_Prev_Diagnos,
      description: labels.Early_Preventative_Diagnostic,
    },
    {
      key: "5D",
      shortDesc: labels.Accurate_Loc_Activ,
      description: labels.Accurate_Locate_Ativity,
    },
    {
      key: "5E",
      shortDesc: labels.Bet_Dsgn_Of_Plant,
      description: labels.Better_Design_Of_Plant,
    },
    {
      key: "5F",
      shortDesc: labels.Better_Grounding,
      description: labels.Bettr_Grouding,
    },
    {
      key: "5G",
      shortDesc: labels.Better_Maint_Batt,
      description: labels.Better_Battery_Maintenance,
    },
    {
      key: "5H",
      shortDesc: labels.Bet_Loc_Of_Equip,
      description: labels.Better_Location_Placement_Of_Equipment,
    },
    {
      key: "5I",
      shortDesc: labels.Ftr_Service_Tech,
      description: labels.First_Time_Right_By_Serv_Tech,
    },
    {
      key: "5J",
      shortDesc: labels.Ftr_By_Constr,
      description: labels.First_Time_Right_By_Construction,
    },
    {
      key: "5K",
      shortDesc: labels.Ftr_By_Maint,
      description: labels.First_Time_Right_By_Maintenance,
    },
    {
      key: "5L",
      shortDesc: labels.Ftr_By_Maint_Contr,
      description: labels.First_Time_Right_By_Maintenance_Contractors,
    },
    {
      key: "5M",
      shortDesc: labels.Not_A_Mtce_Issue,
      description: labels.Not_A_Mtce_Issue_Unnecessary_Mtce_Order,
    },
    {
      key: "5Z",
      shortDesc: labels.Other_Prevention,
      description: labels.Other_Prevention,
    },
    {
      key: "",
      shortDesc: "",
      desc: "",
    },
  ];
};

export const additionalCodes = () => {
  const labels = AppLabels();
  return [
    { key: "RE", shortDesc: labels.Re, description: labels.Ref_To_Engineering },
    { key: "RS", shortDesc: labels.Rs, description: labels.Ref_To_Service },
    { key: "RH", shortDesc: labels.Rh, description: labels.Ref_To_Head_End },
    {
      key: "RC",
      shortDesc: labels.Rc,
      description: labels.Ref_To_Construction,
    },
    { key: "RR", shortDesc: labels.Rr, description: labels.ReGenerate },
    { key: "NS", shortDesc: labels.Rn, description: labels.Refer_To_Noise },
    {
      key: "Y1",
      shortDesc: labels.Tracking_Code_1,
      description: labels.Hot_To_Cold,
    },
    {
      key: "Y2",
      shortDesc: labels.Tracking_Code_2,
      description: labels.Tracking_Code_2,
    },
    {
      key: "Y3",
      shortDesc: labels.Tracking_Code_3,
      description: labels.Incorrect_Referral,
    },
    {
      key: "Y4",
      shortDesc: labels.Tracking_Code_4,
      description: labels.Tracking_Code_4,
    },
    {
      key: "Y5",
      shortDesc: labels.Tracking_Code_5,
      description: labels.Tracking_Code_5,
    },
    {
      key: "OC",
      shortDesc: labels.Recep_Prob_OutageCanc_Via_Mdi,
      description: labels.Recep_Prob_OutageCanc_Via_Mdi,
    },
    {
      key: "ON",
      shortDesc: labels.Recep_Prob_Outage_Not_Verif,
      description: labels.Recep_Prob_Outage_Not_Verif,
    },
    {
      key: "OV",
      shortDesc: labels.Recep_Prob_Outage_Verified,
      description: labels.Recep_Prob_Outage_Verified,
    },
    {
      key: "",
      shortDesc: "",
      desc: "",
    },
  ];
};

export const codesList = () => {
  return {
    fCodesList: faultCodes(),
    nCodesList: networkCodes(),
    aCodesList: activityCodes(),
    pCodesList: preventionCodes(),
    addCodesList: additionalCodes(),
    cCodesList: causeCodes(),
  };
};
