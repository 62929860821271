import { Divider } from "@material-ui/core";
import AppLabels from "../../../constants/App_Labels";
import ConfigConst from "../../../constants/ConfigConst";
import "../../../design-tokens/forms/EPR.scss";
import CustomCarousel from "../../reusable/CustomCarousel";
import {
  changeDateFormat,
  changeTimeFormat,
  isLegacyAccount,
} from "../../reusable/Util";

interface Props {
  data: any;
}

const ViewNoiseScreen = ({ data }: Props) => {
  const formData = data?.formData;
  const labels = AppLabels() as any;
  // console.log(data?.workOrderId);
  const workOrderID =
    data?.workOrderId === "" || data?.workOrderId === undefined
      ? labels.No_Work_Order
      : data?.workOrderId;

  return (
    <div className="viewNoiseContainer">
      <div>
        <label className="titleLabel">{labels.Work_Order_ID}:</label>
        <label className="textLabel">{workOrderID}</label>
      </div>
      {formData?.orderType && (
        <div>
          <label className="titleLabel">{labels.Order_Type}:</label>
          <label className="textLabel">{formData?.orderType}</label>
        </div>
      )}
      <div>
        <label className="titleLabel">{"Order Date"}:</label>
        <label className="textLabel">
          {changeTimeFormat(data?.updatedDate)}
        </label>
      </div>
      <div>
        <label className="titleLabel">{"Created By"}:</label>
        <label className="textLabel">{data?.createdBy}</label>
      </div>
      <Divider style={{ margin: "10px 0px 20px 0px" }} />
      {formData?.formSubmissionNo != "" && (
        <div>
          <label className="titleLabel">{"Form Submission Number"}:</label>
          <label className="textLabel">{formData?.formSubmissionNo}</label>
        </div>
      )}
      <div>
        <label className="titleLabel">{"TPIA"}:</label>
        <label className="textLabel">{formData?.tpia}</label>
      </div>
      {formData?.CMMac.length !== 0 && (
        <div>
          <label className="titleLabel">
            {labels.CABLE_MODEM + " " + labels.MAC_ADDRESS}:
          </label>
          {formData?.CMMac?.map((e: any, i: number) => (
            <div style={{ margin: "-10px 0px 0px 10px" }} key={i}>
              <label className="textLabel">{e}</label>
            </div>
          ))}
        </div>
      )}
      {formData?.STBMac.length !== 0 && (
        <div>
          <label className="titleLabel">
            {"STB" + " " + labels.MAC_ADDRESS}:
          </label>
          {formData?.STBMac?.map((e: any, i: number) => (
            <div style={{ margin: "-10px 0px 0px 10px" }} key={i}>
              <label className="textLabel">{e}</label>
            </div>
          ))}
        </div>
      )}
      {formData?.EMTAMac.length !== 0 && (
        <div>
          <label className="titleLabel">
            {"EMTA" + " " + labels.MAC_ADDRESS}:
          </label>
          {formData?.EMTAMac?.map((e: any, i: number) => (
            <div style={{ margin: "-10px 0px 0px 10px" }} key={i}>
              <label className="textLabel">{e}</label>
            </div>
          ))}
        </div>
      )}
      {isLegacyAccount() ? (
        <div>
          <label className="titleLabel">{labels.ACCOUNT_NUMBER}:</label>
          <label className="textLabel">{formData?.accountNumber}</label>
        </div>
      ) : (
        <>
          <div>
            <label className="titleLabel">{labels.ACCOUNT_NUMBER}:</label>
            <label className="textLabel">{formData?.accountNumber}</label>
          </div>
          <div>
            <label className="titleLabel">{labels.HHID}:</label>
            <label className="textLabel">{formData?.hhid}</label>
          </div>
        </>
      )}
      <div>
        <label className="titleLabel">{labels.technician_Name}:</label>
        <label className="textLabel">{formData?.techName}</label>
      </div>
      <div>
        <label className="titleLabel">{labels.technician_Id}:</label>
        <label className="textLabel">{formData?.technicianId}</label>
      </div>
      <div>
        <label className="titleLabel">
          {formData?.unitOrSuiteNumber === "" ? "House" : "Street" + " Number"}:
        </label>
        <label className="textLabel">{formData?.streetNumber}</label>
      </div>
      <div>
        <label className="titleLabel">{"Street Name"}:</label>
        <label className="textLabel">{formData?.streetName}</label>
      </div>
      {formData?.streetDirection !== "" && (
        <div>
          <label className="titleLabel">{"Street Direction"}:</label>
          <label className="textLabel">{formData?.streetDirection}</label>
        </div>
      )}
      {formData?.unitOrSuiteNumber !== "" && (
        <div>
          <label className="titleLabel">{"Unit Or Suite Number"}:</label>
          <label className="textLabel">{formData?.unitOrSuiteNumber}</label>
        </div>
      )}
      <div>
        <label className="titleLabel">{labels.City}:</label>
        <label className="textLabel">{formData?.city}</label>
      </div>
      <div>
        <label className="titleLabel">{"Node Number"}:</label>
        <label className="textLabel">{formData?.nodeNumber}</label>
      </div>
      {formData?.cbpId !== "" && (
        <div>
          <label className="titleLabel">{"CBPID"}:</label>
          <label className="textLabel">{formData?.cbpId}</label>
        </div>
      )}
      <div>
        <label className="titleLabel">{"Manager Name"}:</label>
        <label className="textLabel">{formData?.managerName}</label>
      </div>
      <div>
        <label className="titleLabel">{labels.Hot_To_Cold}:</label>
        <label className="textLabel">{formData?.hotToCold}</label>
      </div>
      <div>
        <label className="titleLabel">{"Bell Modem"}:</label>
        <label className="textLabel">{formData?.bellModem}</label>
      </div>
      {formData?.f2Information_PrePadding !== "" && (
        <div>
          <label className="titleLabel">{"f2 Information PrePadding"}:</label>
          <label className="textLabel">
            {formData?.f2Information_PrePadding}
          </label>
        </div>
      )}
      <div>
        <label className="titleLabel">{"Filtered Or Padded"}:</label>
        <label className="textLabel">{formData?.filteredOrPadded}</label>
      </div>
      {formData?.filteredOrPadded === labels.Yes && (
        <div>
          <label className="titleLabel">
            {"Filtered Or Padded Information"}:
          </label>
          <label className="textLabel">{formData?.filterOrPadInfo}</label>
        </div>
      )}
      {formData?.f2Information_PostPadding !== "" && (
        <div>
          <label className="titleLabel">{"f2 Information PostPadding"}:</label>
          <label className="textLabel">
            {formData?.f2Information_PostPadding}
          </label>
        </div>
      )}
      <div>
        <label className="titleLabel">{"Above 10 Units"}:</label>
        <label className="textLabel">{formData?.above10Units}</label>
      </div>
      {formData?.above10Units === labels.Yes && (
        <>
          <div>
            <label className="titleLabel">
              {"Above 10 Units (Affected Units)"}:
            </label>
            {formData?.above10UnitsList?.map((e: any, i: number) => (
              <div style={{ margin: "-10px 0px 0px 10px" }} key={i}>
                <label className="textLabel">{e}</label>
              </div>
            ))}
          </div>
          {formData?.above10UnitsComments !== "" && (
            <div>
              <label className="titleLabel">{"Above 10 Units Comments"}:</label>
              <label className="textLabel">
                {formData?.above10UnitsComments}
              </label>
            </div>
          )}
        </>
      )}
      <div>
        <label className="titleLabel">{labels.PRIORITY}:</label>
        <label className="textLabel">{formData?.priority}</label>
      </div>
      <div>
        <label className="titleLabel">{"Legacy Or Ignite Or Fido"}:</label>
        <label className="textLabel">{formData?.legacyOrIgniteOrFide}</label>
      </div>
      {formData?.screenShots.length !== 0 && (
        <div>
          <label className="titleLabel">{"ScreenShots"}:</label>
          <div className="carouselContainer">
            <CustomCarousel images={formData?.screenShots} />
          </div>
        </div>
      )}
      {formData?.comments !== "" && (
        <div>
          <label className="titleLabel">{"Comments"}:</label>
          <label className="textLabel">{formData?.comments}</label>
        </div>
      )}
      {formData?.distroEmails.length !== 0 && (
        <div>
          <label className="titleLabel">{"Distribution Email(s)"}:</label>
          {formData?.distroEmails?.map((e: any, i: number) => (
            <div style={{ margin: "-10px 0px 0px 10px" }} key={i}>
              <label className="textLabel">{e}</label>
            </div>
          ))}
        </div>
      )}
      {formData?.rpaStatus !== "" && (
        <div>
          <label className="titleLabel">{"RPA Status"}:</label>
          <label className="textLabel">{formData?.rpaStatus}</label>
        </div>
      )}
    </div>
  );
};

export default ViewNoiseScreen;
