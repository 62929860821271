
import { FeedbackUpdateState} from "../../states/feedback/FeedbackUpdateState"
import {
  UPDATE_FEEDBACK_BEGIN,
  UPDATE_FEEDBACK_SUCCESS,
  UPDATE_FEEDBACK_FAILURE,
  UpdateFeedbackActionTypes
} from "../../types/feedback/FeedbackUpdate"

const initialState: FeedbackUpdateState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function FeedbackUpdateReducer(
  state = initialState,
  action: UpdateFeedbackActionTypes
): FeedbackUpdateState  {
  switch (action.type) {
    case UPDATE_FEEDBACK_BEGIN:
      return {
        isLoading: true,
      };
    case UPDATE_FEEDBACK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case UPDATE_FEEDBACK_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}
