import { useState } from "react";
import { Button, FormControl, InputGroup, Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import SearchIcon from "@material-ui/icons/Search";
import { useDispatch } from "react-redux";

import CustomErrorBox from "../../reusable/CustomErrorBox";
import AppLabels from "../../../../constants/App_Labels";
import {
  setMapCenter,
  setSearchedAddress,
  setSearchedElement,
} from "../../../store/slices/mapItems/mapDetails";
import appConst from "../../../constants/appConst";
import { getActiveEleMarker } from "../../../util/utilFuncs";

interface Props {
  showCoordSearchModal: boolean;
  setShowCoordSearchModal: (arg0: boolean) => void;
}

export default function SearchByCoordinates({
  showCoordSearchModal,
  setShowCoordSearchModal,
}: Props) {
  const labels = AppLabels();
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [errorBoxFlag, setErrorBoxFlag] = useState(false);
  const [errorBoxBody, setErrorBoxBody] = useState(<div></div>);

  const onSearch = () => {
    const latLngArr = value.split(", ");
    const lat = Number(latLngArr?.[0]);
    const lng = Number(latLngArr?.[1]);

    if (
      lat <= 90 &&
      lat >= -90 &&
      lng >= -180 &&
      lng <= 180 &&
      typeof lat == "number" &&
      typeof lng == "number"
    ) {
      setShowCoordSearchModal(false);
      dispatch(setMapCenter({ lat, lng }));
      setValue("");

      let activeElement = getActiveEleMarker({ lat, lng });
      dispatch(setSearchedAddress(activeElement));
    } else {
      setErrorBoxFlag(true);
      setErrorBoxBody(
        <div>
          <span>{labels.Coordinates_Error}</span>
        </div>
      );
    }
  };

  const onKeyboardKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") onSearch();
  };

  return (
    <>
      <Modal
        show={showCoordSearchModal}
        id="searchPopUp"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header className="searchPopHead">
          <header>{labels.Uppercase_Search}</header>
          <button
            className="sPopUpClose"
            onClick={() => setShowCoordSearchModal(false)}
          >
            <IoClose color="white" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <label>{labels.Search_Geographic_Location}</label>
          <InputGroup className="searchInput" style={{ marginTop: 30 }}>
            <span className="searchInputLabel">{labels.SEARCH_BTN_TEXT}</span>
            <div className="searchInputContainer">
              <FormControl
                name="universalSearch"
                placeholder={"43.67812896229174, -79.33322046307663"}
                className="searchInput-Text"
                id="searchBoxInput"
                value={value}
                onChange={(e: any) => setValue(e.target.value)}
                onKeyPress={(e: any) => onKeyboardKeyPress(e)}
                autoComplete="off"
              />
            </div>
            <Button id="searchAccBtn" onClick={onSearch}>
              <SearchIcon />
            </Button>
          </InputGroup>
        </Modal.Body>
        <div className="coordinatesErrorBox">
          <CustomErrorBox
            showVal={errorBoxFlag}
            bodyElement={errorBoxBody}
            handleClose={() => setErrorBoxFlag(false)}
          />
        </div>
        <Modal.Footer>
          <button
            className="sPopCloseBtn"
            onClick={() => setShowCoordSearchModal(false)}
          >
            {labels.CLOSE_BTN_TEXT}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
