import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import "../../design-tokens/rpd-page/RPDPage.scss";
import RPDSWUpgradeMacTop from "../../components/rpd/rpdSWUpgradeBasedMac/RPDSWUpgradeMacTop";
import RPDSWUpgradeMacDetails from "../../components/rpd/rpdSWUpgradeBasedMac/RPDSWUpgradeMacDetails";
import LoaderGif from "../../resources/images/loader.webp";
import RPDExceptionBox from "../../components/reusable/RPDExceptionBox";
import AppLabels from "../../constants/App_Labels";
import { GetRPDSWUpgradeMacInfo, GetTechInfo } from "../../get-api/GetResponse";
import OrderSuccessBox from "../../components/rpd/OrderSuccessBox";
import ErrorBox from "../../components/reusable/ErrorBox";

const RPDSWUpgradeBasedMacTab = () => {
  const dispatch = useDispatch();
  const [showConfirm, setShowConfirm] = useState(false);
  const labels = AppLabels();

  const handleConfirm = () => {
    setShowConfirm(false);
  };
  const rpdSWUpgradeMacInfo = GetRPDSWUpgradeMacInfo();
  const techData = GetTechInfo()?.data;

  return (
    <>
      <div>
        <RPDSWUpgradeMacTop
          techData={techData}
          isLoading={rpdSWUpgradeMacInfo?.isLoading}
          isMacLoading={rpdSWUpgradeMacInfo?.isMacRPDLoading}
        />
      </div>
      <div className="historyResultCont">
        {rpdSWUpgradeMacInfo?.isLoading == true ||
        rpdSWUpgradeMacInfo?.isSWUpgLoading == true ||
        rpdSWUpgradeMacInfo?.isMacRPDLoading == true ? (
          <div className="loader">
            <img style={{ height: "60px" }} src={LoaderGif} />
          </div>
        ) : rpdSWUpgradeMacInfo?.swUpgradeData !== undefined ? (
          <div className="diagErrorCont">
            <ErrorBox
              open={showConfirm}
              error={labels.rpdCreateOrderSWUpgradeSuccess}
              setOpen={setShowConfirm}
              handleClose={handleConfirm}
            />

            <OrderSuccessBox
              headerText={labels.rpdCreateOrderSWUpgradeSuccess}
              bodyText={
                labels.orderStatusCheck +
                rpdSWUpgradeMacInfo?.swUpgradeData.orderid
              }
              primaryBtn={false}
              secBtn={false}
              orderid={rpdSWUpgradeMacInfo?.swUpgradeData.orderid}
              rpdOrderInfo={rpdSWUpgradeMacInfo}
            />
          </div>
        ) : rpdSWUpgradeMacInfo?.swUpgradeError !== undefined ? (
          <div className="diagErrorCont">
            <ErrorBox
              open={showConfirm}
              error={labels.rpdSWUpgradeError}
              setOpen={setShowConfirm}
              handleClose={handleConfirm}
            />
            <RPDExceptionBox
              exBoxType="error"
              headerText={labels.rpdSWUpgradeError}
              errCode={rpdSWUpgradeMacInfo?.swUpgradeError?.errorCode}
              ErrorMSG={rpdSWUpgradeMacInfo?.swUpgradeError?.errorMessage}
              srcSystem={rpdSWUpgradeMacInfo?.swUpgradeError?.sourceSystem}
              bodyText={rpdSWUpgradeMacInfo?.swUpgradeError?.responseFromSource}
              primaryBtn={false}
              secBtn={false}
            />
          </div>
        ) : rpdSWUpgradeMacInfo?.data !== undefined ? (
          <RPDSWUpgradeMacDetails
            isLoading={rpdSWUpgradeMacInfo?.isLoading}
            activationData={rpdSWUpgradeMacInfo?.data}
            isActivationLoading={rpdSWUpgradeMacInfo?.isSWUpgLoading}
            rpdMac={rpdSWUpgradeMacInfo?.data?.macAddress}
            macDomain={rpdSWUpgradeMacInfo?.data?.macDomain}
            cmtsPort={rpdSWUpgradeMacInfo?.data?.cmtsPort}
            cmtsName={rpdSWUpgradeMacInfo?.data?.cmtsName}
            mailId={rpdSWUpgradeMacInfo?.mail}
            relatedId={rpdSWUpgradeMacInfo?.nptId}
            device={rpdSWUpgradeMacInfo?.data?.DeviceType}
            swVersion={rpdSWUpgradeMacInfo?.data?.running_sw_image_name}
            phub={rpdSWUpgradeMacInfo?.data?.PHUB}
          />
        ) : rpdSWUpgradeMacInfo?.error !== undefined ? (
          <div className="diagErrorCont">
            <RPDExceptionBox
              exBoxType="error"
              headerText={labels.noNPTData}
              errCode={rpdSWUpgradeMacInfo?.error?.errorCode}
              ErrorMSG={rpdSWUpgradeMacInfo?.error?.errorMessage}
              srcSystem={rpdSWUpgradeMacInfo?.error?.sourceSystem}
              bodyText={rpdSWUpgradeMacInfo?.error?.responseFromSource}
              primaryBtn={false}
              secBtn={false}
            />
          </div>
        ) : rpdSWUpgradeMacInfo?.macRPDData !== undefined ? (
          <RPDSWUpgradeMacDetails
            isLoading={rpdSWUpgradeMacInfo?.isLoading}
            activationData={rpdSWUpgradeMacInfo?.data}
            isActivationLoading={rpdSWUpgradeMacInfo?.isSWUpgLoading}
            rpdMac={rpdSWUpgradeMacInfo?.macRPDData?.macAddress}
            macDomain={rpdSWUpgradeMacInfo?.macRPDData?.macDomain}
            cmtsPort={rpdSWUpgradeMacInfo?.macRPDData?.cmtsPort}
            cmtsName={rpdSWUpgradeMacInfo?.macRPDData?.cmtsName}
            mailId={rpdSWUpgradeMacInfo?.mail}
            relatedId={rpdSWUpgradeMacInfo?.nptId}
            device={rpdSWUpgradeMacInfo?.macRPDData?.DeviceType}
            swVersion={rpdSWUpgradeMacInfo?.macRPDData?.running_sw_image_name}
            phub={rpdSWUpgradeMacInfo?.macRPDData?.PHUB}
          />
        ) : rpdSWUpgradeMacInfo?.macRPDError !== undefined ? (
          <div className="diagErrorCont">
            <RPDExceptionBox
              exBoxType="error"
              headerText={labels.noNPTData}
              errCode={rpdSWUpgradeMacInfo?.macRPDError?.errorCode}
              ErrorMSG={rpdSWUpgradeMacInfo?.macRPDError?.errorMessage}
              srcSystem={rpdSWUpgradeMacInfo?.macRPDError?.sourceSystem}
              bodyText={rpdSWUpgradeMacInfo?.macRPDError?.responseFromSource}
              primaryBtn={false}
              secBtn={false}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default RPDSWUpgradeBasedMacTab;
