import AppLabels from "../../../constants/App_Labels";
import { changeDateFormat } from "../../reusable/Util";
// in this component we applied inline CSS due to export purpose
//please do not follow the same in other components as its not a good practice
interface Props {
  woID: string;
  woType: string;
  workDetails: any;
}

const DisplayEmailPreviewEPR = (props: Props) => {
  const labels = AppLabels() as any;

  return (
    <div
      style={{
        display: "inline-block",
        fontWeight: 500,
        fontFamily: "Ted Next",
      }}
    >
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {labels.Work_Order_ID}:
        </span>
        <span>{props.woID}</span>
      </div>

      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {" "}
          {"Last 4 Digit "}
          {labels.Account_No}:
        </span>
        <span>
          {props?.workDetails?.accountNumber &&
            props?.workDetails?.accountNumber % 10000}
        </span>
      </div>

      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {labels.Customer_has_opted_out_of_the_Email_option}:
        </span>
        <span>{props?.workDetails?.emailOptOut ? labels.Yes : labels.No}</span>
      </div>
      {!props?.workDetails?.emailOptOut && (
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              marginRight: "10px",
            }}
          >
            {labels.Copy_of_this_Email_Sent_to}:
          </span>
          <ul>
            {props?.workDetails?.customerEmails?.map((e: any, i: number) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </div>
      )}

      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {labels.Date}:
        </span>
        <span>{changeDateFormat(props?.workDetails?.date)}</span>
      </div>

      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {labels.TECH_ID}:
        </span>
        <span>{props?.workDetails?.technicianId}</span>
      </div>
      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {labels.Equipment_Pickup_Details_and_Serial_Numbers}:
        </span>
        <span style={{ wordBreak: "break-word" }}>
          {props?.workDetails?.equipmentPickupDetailsandSerialNumbers}
        </span>
      </div>

      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {"Customer Equiment Acknowledgment "}:
        </span>
        <span>
          {props?.workDetails?.acknowledgment ? labels.Yes : labels.No}
        </span>
      </div>
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {labels.Customers_Signature}:
        </span>
        <div>
          <img
            src={props?.workDetails?.signatureImage}
            style={{ height: "150px", width: "400px" }}
            alt="signature"
          />
        </div>
      </div>
    </div>
  );
};

export default DisplayEmailPreviewEPR;
