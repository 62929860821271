import RLogo from "../../resources/images/R-Logo2.png";
import AppLabels from "../../constants/App_Labels";
import packageJson from "../../../package.json";

const LogoBanner = () => {
  const labels = AppLabels();

  return (
    <div>
      <div className="logo-container">
        <img alt="logoImage" src={RLogo} className="logo" />
        <span className="appName">
          {labels.APP_NAME_LABEL}{" "}
          <span className="appVersionName">v{packageJson?.version} <span style={{fontSize: "18px", }}> | </span></span>
        </span>
      </div>
    </div>
  );
};

export default LogoBanner;
