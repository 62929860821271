import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../topology/store/middleware/apiActions";
import {
  NETWORK_SERVICE_URL,
  GPON_OLT_NAME_SEARCH_DETAILS,
} from "../../middleware/apiEndpoints";
import storeConst from "../../storeConst";
import {
  combineOltCountData,
  convertStreamDataToJson,
  newOltCountData,
} from "../../../topology/util/utilFuncs";
import { TELE_ABORT_KEY } from "../../../topology/store/middleware/abortKeys";
import { GPON_ONT_COUNT_FLUX } from "../../middleware/apiEndpoints";
import {
  abortRequest,
  addAbortKey,
} from "../../../topology/store/middleware/apiClient";
import { changeTimeFormat } from "../../../components/reusable/Util";
import { OLTOntCountType } from "../../../screens/gpon-page/OLTOntCountType";

let oltOntCountData: any = [];
const batchSize = (window as any).config?.TELEMETRY_BATCH_SIZE ?? 5;
const slice = createSlice({
  name: "oltNameSearchDetails",
  initialState: {
    isLoading: false,
    resultList: undefined,
    oltName: "",
    error: undefined,
    status: "",
    isOntCountLoading: false,
    ontCountData: undefined,
    ontCountError: undefined,
    ontCountStatus: "",
    apiCallCounter: 0,
    isAborted: false,
    diagStartTime: undefined,
    diagEndTime: undefined,
  },
  reducers: {
    // action => action handler
    oltNameDetailsBegin: (oltDetails) => {
      oltDetails.isLoading = true;
      let startTime: any = changeTimeFormat(Date.now());
      oltDetails.diagStartTime = startTime;
    },
    oltNameDetailsSuccess: (oltDetails, action) => {
      oltDetails.isLoading = false;
      oltDetails.resultList = action?.payload?.map((d: any) => {
        let nDevice = {
          ...d,
          ontDevice: "",
        };
        return nDevice;
      });
      oltDetails.oltName = action.payload.params;
      oltDetails.status = storeConst.OK;
    },
    oltNameDetailsFailure: (oltDetails, action) => {
      oltDetails.isLoading = false;
      oltDetails.error = action.payload;
      oltDetails.resultList = undefined;
      oltDetails.oltName = "";
      oltDetails.status = storeConst.ERROR;
      let endTime: any = changeTimeFormat(Date.now());
      oltDetails.diagEndTime = endTime;
    },
    updateOltDetails: (oltDetails, action) => {
      oltDetails.resultList = action.payload;
    },
    resetOltNameDetails: (oltDetails) => {
      oltDetails.isLoading = false;
      oltDetails.error = undefined;
      oltDetails.resultList = undefined;
      oltDetails.oltName = "";
      oltDetails.status = "";
      oltDetails.isOntCountLoading = false;
      oltDetails.ontCountData = undefined;
      oltDetails.ontCountError = undefined;
      oltDetails.ontCountStatus = "";
      oltDetails.apiCallCounter = 0;
      oltDetails.isAborted = false;
      oltDetails.diagStartTime = undefined;
      oltDetails.diagEndTime = undefined;
    },

    ////////stream
    getStreamDataBegin: (ontCountObj) => {
      ontCountObj.apiCallCounter++;
      ontCountObj.isOntCountLoading = true;
      ontCountObj.ontCountStatus = "";
      ontCountObj.ontCountError = undefined;

      let currResultList: any = JSON.parse(
        JSON.stringify(ontCountObj.resultList)
      );
      ontCountObj.resultList = currResultList?.map((olt: any) => {
        return { ...olt, ...{ ontDevice: { ...{ isCountLoading: true } } } };
      });
    },
    getStreamDataSuccess: (ontCountObj, { payload }) => {
      let currResultList: any = JSON.parse(
        JSON.stringify(ontCountObj.resultList)
      );

      let dataChunk = convertStreamDataToJson(payload);

      if (dataChunk?.length > 0 && currResultList?.length > 0) {
        oltOntCountData = newOltCountData(currResultList, dataChunk);

        let combinedData = [];
        if (oltOntCountData?.length >= Number(batchSize)) {
          combinedData = combineOltCountData(currResultList, oltOntCountData);

          if (combinedData) {
            ontCountObj.resultList = combinedData;
            oltOntCountData = [];
          }
        }
        if (currResultList?.length === dataChunk?.length && combinedData) {
          ontCountObj.isOntCountLoading = false;
          ontCountObj.apiCallCounter--;
          if (oltOntCountData?.length > 0)
            ontCountObj.resultList = combineOltCountData(
              currResultList,
              oltOntCountData
            );
          ontCountObj.ontCountStatus = storeConst.OK;
          ontCountObj.ontCountError = undefined;
          let endTime: any = changeTimeFormat(Date.now());
          ontCountObj.diagEndTime = endTime;
        }
      }
    },
    getStreamDataFailure: (ontCountObj, { payload }) => {
      ontCountObj.isOntCountLoading = false;
      ontCountObj.apiCallCounter--;
      ontCountObj.ontCountStatus = storeConst.ERROR;
      ontCountObj.ontCountError = payload;
      let currResultList: any = JSON.parse(
        JSON.stringify(ontCountObj.resultList)
      );
      ontCountObj.resultList = currResultList?.map((olt: any) => {
        return { ...olt, ...{ ontDevice: { ...{ isCountLoading: false } } } };
      });
      let endTime: any = changeTimeFormat(Date.now());
      ontCountObj.diagEndTime = endTime;
    },
    setAborted: (ontCountObj, { payload }) => {
      ontCountObj.isAborted = payload;
    },
  },
});

export const {
  oltNameDetailsBegin,
  oltNameDetailsSuccess,
  oltNameDetailsFailure,
  updateOltDetails,
  resetOltNameDetails,
  getStreamDataBegin,
  getStreamDataSuccess,
  getStreamDataFailure,
  setAborted,
} = slice.actions;

export default slice.reducer;

//action creators
export const getOltNameSearchDetails = (oltName: any) =>
  apiCallBegan({
    baseURL: NETWORK_SERVICE_URL,
    url: `${GPON_OLT_NAME_SEARCH_DETAILS}${oltName}`,
    onStart: oltNameDetailsBegin.type,
    onSuccess: oltNameDetailsSuccess.type,
    onError: oltNameDetailsFailure.type,
  });

interface DiagRequestType {
  nodeList: any;
}

export const getStreamData =
  ({ nodeList }: DiagRequestType) =>
  (dispatch: any, getState: any) => {
    dispatch(
      apiCallBegan({
        baseURL: NETWORK_SERVICE_URL,
        url: GPON_ONT_COUNT_FLUX,
        method: "post",
        data: JSON.stringify({
          nodeList: nodeList,
        }),
        responseType: "stream",
        abortKey: TELE_ABORT_KEY,
        onStart: getStreamDataBegin.type,
        onSuccess: getStreamDataSuccess.type,
        onError: getStreamDataFailure.type,
      })
    );
  };

export const stopDiagExecution = () => (dispatch: any) => {
  dispatch(setAborted(true));
  abortRequest(TELE_ABORT_KEY);
};
