import { TowerView } from "../../../models/account/towerview/TowerView";

export const TOWER_VIEW_BEGIN = "TOWER_VIEW_BEGIN";
export const TOWER_VIEW_SUCCESS = "TOWER_VIEW_SUCCESS";
export const TOWER_VIEW_FAILURE = "TOWER_VIEW_FAILURE";
export const TOWER_VIEW_RESET = "TOWER_VIEW_RESET";

interface towerViewBegin {
  type: typeof TOWER_VIEW_BEGIN;
}

interface towerViewSuccess {
  type: typeof TOWER_VIEW_SUCCESS;
  payload: TowerView;
}

interface towerViewFailure {
  type: typeof TOWER_VIEW_FAILURE;
  payload: Error;
}

interface towerViewReset {
  type: typeof TOWER_VIEW_RESET;
}

export type TowerViewActionTypes =
  | towerViewBegin
  | towerViewSuccess
  | towerViewFailure
  | towerViewReset;
