import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { getAccessLevelByRoles } from "../../components/reusable/Util";
import WorkOrders from "../../components/workorder/WorkOrders";
import ConfigConst from "../../constants/ConfigConst";
import "../../design-tokens/dashbord-page/DashbordWidget.scss";

interface Props {
  techData: any;
}

const DashbordWidget = ({ techData }: Props) => {
  return (
    <section className="widgetSectionMT">
      <div className="widgetRefresh">
        <span className="widgetLabel">YOUR WORK ITEMS</span>
      </div>
      <Tabs defaultActiveKey="workOrders">
        <Tab className="tabLink" eventKey="workOrders" title="WORK ORDERS">
          <WorkOrders />
        </Tab>
      </Tabs>
    </section>
  );
};

export default DashbordWidget;
