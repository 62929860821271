import { FeedbackCommentsState } from "../../states/feedback/FeedbackCommentsState"
import {
  FEEDBACK_COMMENTS_BEGIN,
  FEEDBACK_COMMENTS_SUCCESS,
  FEEDBACK_COMMENTS_FAILURE,
  FeedbackCommentsActionTypes,
} from "../../types/feedback/FeedbackComments"

const initialState: FeedbackCommentsState = {
  isLoading: false,
  data: {},
  error: undefined,
  status: "",
};

export function FeedbackCommentsReducer(
  state = initialState,
  action: FeedbackCommentsActionTypes
): FeedbackCommentsState  {
  switch (action.type) {
    case FEEDBACK_COMMENTS_BEGIN:
      return {
        ...state,isLoading: true,
      };
    case FEEDBACK_COMMENTS_SUCCESS:
      return {
        ...state,isLoading:false,data:{...state.data,...action.payload}
      };
    case FEEDBACK_COMMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}
