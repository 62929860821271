import { partOptionDropDown } from "./RfCalculatorConfig";

const signalLevel = "signalLevel";
const spigotLevel = "spigotLevel";

export const handleNan = (val: string) => {
  /* 
    Makes sure we aren't trying to add NaN values
  */
  if (isNaN(parseFloat(val))) {
    return 0;
  } else {
    return parseFloat(val);
  }
};

const calculateAllFreq = (
  partTypeObj: any,
  qty: string,
  tapSignal: number,
  prevFreq: any
) => {
  if (partTypeObj !== undefined) {
    return {
      signalLevel:
        partTypeObj?.freq &&
        partTypeObj?.freq?.signalLevel &&
        partTypeObj?.freq?.signalLevel?.map((el: any) => {
          let newObj;
          let mhz42 = Math.abs(
            handleNan(qty) * handleNan(partTypeObj?.value) +
              handleNan(el?.value) +
              getPropertyValue(prevFreq, 0, signalLevel) -
              tapSignal
          );
          let mhz111 =
            handleNan(qty) * handleNan(el?.value) -
            getPropertyValue(prevFreq, 1, signalLevel);

          let mhz860 =
            handleNan(qty) * handleNan(el?.value) -
            getPropertyValue(prevFreq, 2, signalLevel);
          if (el.name === "42Mhz") {
            newObj = {
              name: el?.name,
              value: mhz42,
            };
          } else if (el.name === "111Mhz") {
            newObj = {
              name: el?.name,
              value: mhz111,
            };
          } else if (el.name === "860Mhz") {
            newObj = {
              name: el?.name,
              value: mhz860,
            };
          }
          return newObj;
        }),
    };
  }
};

export const getSelPartValue = (
  partIdx: number,
  qty: string,
  tapSignal?: number,
  prevFreq?: any
) => {
  let freqValue = {
    signalLevel: [
      { name: "42Mhz", value: 0 },
      { name: "111Mhz", value: 0 },
      { name: "860Mhz", value: 0 },
    ],
    spigotLevel: [
      { name: "42Mhz", value: 0 },
      { name: "111Mhz", value: 0 },
      { name: "860Mhz", value: 0 },
    ],
  };
  let partConfigData = partOptionDropDown[partIdx];

  const tapSignalVal = tapSignal && partConfigData?.isTapSignal ? tapSignal : 0;

  let updatedValues = calculateAllFreq(
    partConfigData,
    qty,
    tapSignalVal,
    prevFreq
  );

  freqValue = getSelPartFreqencyValue(partConfigData, updatedValues);

  return freqValue;
};

const getPropertyValue = (prevFreq: any, index: number, freqType: string) => {
  if (freqType === "signalLevel") {
    return prevFreq &&
      prevFreq?.signalLevel &&
      prevFreq?.signalLevel[index] &&
      prevFreq?.signalLevel[index]?.value
      ? handleNan(prevFreq?.signalLevel[index]?.value)
      : 0;
  } else if (freqType === "spigotLevel") {
    return prevFreq &&
      prevFreq?.spigotLevel &&
      prevFreq?.spigotLevel[index] &&
      prevFreq?.spigotLevel[index]?.value
      ? handleNan(prevFreq?.spigotLevel[index]?.value)
      : 0;
  } else {
    return 0;
  }
};

const getSpigotLevelFreqval = (
  currentObj: any,
  updatedValues: any,
  index: number
) => {
  let spigotFreqVal = 0;

  if (currentObj && currentObj?.freq?.spigotLevel) {
    if (updatedValues && updatedValues?.signalLevel) {
      spigotFreqVal =
        getPropertyValue(updatedValues, index, signalLevel) +
        getPropertyValue(currentObj?.freq, index, spigotLevel) -
        getPropertyValue(currentObj?.freq, index, signalLevel);
    }
  }
  return spigotFreqVal;
};

export const getSelPartFreqencyValue = (
  partTypeObj: any,
  updatedValues: any
) => {
  let newVal: any;

  newVal = {
    signalLevel: [
      {
        name: "42Mhz",
        value: Math.abs(
          getPropertyValue(updatedValues, 0, signalLevel)
        )?.toFixed(2),
      },
      {
        name: "111Mhz",
        value: getPropertyValue(updatedValues, 1, signalLevel)?.toFixed(2),
      },
      {
        name: "860Mhz",
        value: getPropertyValue(updatedValues, 2, signalLevel)?.toFixed(2),
      },
    ],
    spigotLevel: [
      {
        name: "42Mhz",
        value: getSpigotLevelFreqval(partTypeObj, updatedValues, 0)?.toFixed(2),
      },
      {
        name: "111Mhz",
        value: getSpigotLevelFreqval(partTypeObj, updatedValues, 1)?.toFixed(2),
      },
      {
        name: "860Mhz",
        value: getSpigotLevelFreqval(partTypeObj, updatedValues, 2)?.toFixed(2),
      },
    ],
  };
  return newVal;
};

export const rfCsvHeaders = [
  {
    label: "Signal Level \n Tap",
    key: "signalTap",
  },
  {
    label: "Part",
    key: "partType",
  },
  {
    label: "Quantity",
    key: "qty",
  },
  {
    label: "Signal Level \n 42 Mhz",
    key: "sigLevel1",
  },
  {
    label: "Signal Level \n 111 Mhz",
    key: "sigLevel2",
  },
  {
    label: "Signal Level \n 860 Mhz",
    key: "sigLevel3",
  },
  // {
  //   label: "Signal Level \n 1200 Mhz",
  //   key: "sigLevel4",
  // },
  {
    label: "Spigot Level \n 42 Mhz",
    key: "spigotLevel1",
  },
  {
    label: "Spigot Level \n 111 Mhz",
    key: "spigotLevel2",
  },
  {
    label: "Spigot Level \n 860 Mhz",
    key: "spigotLevel3",
  },
  // {
  //   label: "Spigot Level \n 1200 Mhz",
  //   key: "spigotLevel4",
  // },
];

export const rfCsvData = (tableRows: any, tapSignal: any): any[] => {
  let csvData: any[] = [];
  if (tableRows?.length > 0) {
    tableRows?.forEach((ele: any, index: number) => {
      if (index === 0) {
        csvData.push({
          signalTap: tapSignal,
          partType: partOptionDropDown[ele?.partType]?.key,
          qty: ele?.qty,
          sigLevel1: Math.abs(handleNan(ele?.freq?.signalLevel[0]?.value)),
          sigLevel2: Math.abs(handleNan(ele?.freq?.signalLevel[1]?.value)),
          sigLevel3: Math.abs(handleNan(ele?.freq?.signalLevel[2]?.value)),
          spigotLevel1: Math.abs(handleNan(ele?.freq?.spigotLevel[0]?.value)),
          spigotLevel2: Math.abs(handleNan(ele?.freq?.spigotLevel[1]?.value)),
          spigotLevel3: Math.abs(handleNan(ele?.freq?.spigotLevel[2]?.value)),
        });
      } else {
        csvData.push({
          partType: partOptionDropDown[ele?.partType]?.key,
          qty: ele?.qty,
          sigLevel1: Math.abs(handleNan(ele?.freq?.signalLevel[0]?.value)),
          sigLevel2: Math.abs(handleNan(ele?.freq?.signalLevel[1]?.value)),
          sigLevel3: Math.abs(handleNan(ele?.freq?.signalLevel[2]?.value)),
          spigotLevel1: Math.abs(handleNan(ele?.freq?.spigotLevel[0]?.value)),
          spigotLevel2: Math.abs(handleNan(ele?.freq?.spigotLevel[1]?.value)),
          spigotLevel3: Math.abs(handleNan(ele?.freq?.spigotLevel[2]?.value)),
        });
      }
    });
  }

  return csvData;
};
