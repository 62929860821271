import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GetEmailInfo } from "../../get-api/GetResponse";
import { sendEmailReset } from "../../store/ducksPattern/sendEmailData";
import ResponseSnackBar from "../reusable/ResponseSnackBar";

const AccountEmailBanner = () => {
  const [bannerVisible, setBannerVisible] = useState(false);
  const dispatch = useDispatch();
  const emailInfo = GetEmailInfo();

  useEffect(() => {
    if (!emailInfo.isLoading && emailInfo?.status !== "") {
      setBannerVisible(true);
    }
  }, [emailInfo?.status]);

  const handleClose = () => {
    setBannerVisible(false);
    dispatch(sendEmailReset());
  };
  return (
    <ResponseSnackBar
      open={bannerVisible}
      requestType={emailInfo?.requestType}
      handleClose={handleClose}
      responseType={emailInfo?.status}
    />
  );
};

export default AccountEmailBanner;
