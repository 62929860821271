import { GroundOverlay } from "@react-google-maps/api";
import React, { useEffect, useRef } from "react";
import { renderToString } from "react-dom/server";
import { zoomLevelRenderMarker } from "../../../../config/mapConfig";
import { mapElementStyles } from "../../../../config/mapItemsConfig";
import appConst from "../../../../constants/appConst";
import { useFeaturesData } from "../../../../util/customHooks/topologyHooks";
import { GetGoogleMaps } from "../../../../util/reduxFunctions/getTopologyState";
import { getImageOverlayBounds } from "../../../../util/utilFuncs";
import TopologySymbols from "../../../reusable/TopologySymbols";

interface Props {
  gMap: any;
  isVisible: boolean;
  zoomLevel: any;
  featuresAll: any;
}
const AddressConnectionPoint = ({
  gMap,
  isVisible,
  zoomLevel,
  featuresAll,
}: Props) => {
  const { currentViewBDY } = GetGoogleMaps();
  const markerWM = useRef(0);
  const featureWM = useFeaturesData(featuresAll, currentViewBDY, zoomLevel);

  useEffect(() => {
    if (
      gMap &&
      zoomLevel >= zoomLevelRenderMarker &&
      featureWM !== undefined &&
      featureWM?.newFeatures?.length > 0 &&
      featureWM?.allFeatures?.length !== markerWM?.current
    ) {
      const tempGeoJson = {
        type: appConst?.FeatureCollection,
        features: featureWM?.newFeatures,
      };
      gMap?.data.addGeoJson(tempGeoJson);
      markerWM.current = featureWM?.allFeatures?.length;
    }
  }, [featureWM]);

  return (
    <>
      {zoomLevel >= zoomLevelRenderMarker &&
        isVisible &&
        featureWM?.allFeatures?.map((feature: any) => {
          const eleImageOverlay = getImageOverlayBounds(
            feature?.geometry?.coordinates?.[1],
            feature?.geometry?.coordinates?.[0],
            mapElementStyles?.fiberAddrConnectionPoint?.iconSize,
            mapElementStyles?.fiberAddrConnectionPoint?.iconSize
          );
          return (
            <GroundOverlay
              key={feature?.featureId}
              url={
                appConst.imageStringPrefix +
                encodeURIComponent(
                  renderToString(
                    <TopologySymbols
                      name={
                        mapElementStyles?.fiberAddrConnectionPoint?.iconName
                      }
                      color={mapElementStyles?.fiberAddrConnectionPoint?.color}
                      size={
                        mapElementStyles?.fiberAddrConnectionPoint?.iconSize
                      }
                    />
                  )
                )
              }
              bounds={
                new google.maps.LatLngBounds(
                  new google.maps.LatLng(
                    eleImageOverlay?.southBound as any,
                    eleImageOverlay?.westBound
                  ),
                  new google.maps.LatLng(
                    eleImageOverlay?.northBound as any,
                    eleImageOverlay?.eastBound
                  )
                )
              }
            />
          );
        })}
    </>
  );
};
export default React.memo(AddressConnectionPoint);
