import { ONTIP} from "../../../../models/account/diagnostics/ont/ONTIP";

export const ONT_IP_BEGIN = "ONT_IP_BEGIN";
export const ONT_IP_SUCCESS = "ONT_IP_SUCCESS";
export const ONT_IP_FAILURE = "ONT_IP_FAILURE";

interface ONTIPBegin {
  type: typeof ONT_IP_BEGIN;
}

interface ONTIPSuccess {
  type: typeof ONT_IP_SUCCESS;
  payload: ONTIP;
}

interface ONTIPFailure {
  type: typeof ONT_IP_FAILURE;
  payload: Error;
}

export type ONTIPActionTypes =
  | ONTIPBegin
  | ONTIPSuccess
  | ONTIPFailure;
