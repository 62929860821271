import { Card } from "react-bootstrap";
import { useState } from "react";
import CustomTab from "../reusable/CustomTab";
import { TabPanel } from "../../screens/account-page/AccountPageTabs";
import { isNull } from "../reusable/Util";
import ODUCellInfo from "./ODUCellInfo";
import AppLabels from "../../constants/App_Labels";
import CustomAccordion from "../reusable/CustomAccordion";

const ODUWirelessInfo = (props: any) => {
  const { deviceData } = props;
  const [tabSelIndex, setTabSelIndex] = useState(0);
  let wirelessCells: any = [];
  if (deviceData?.hasOwnProperty("cells")) {
    deviceData?.cells?.map((cell: any) => {
      wirelessCells.push(cell);
    });
  }
  const labels = AppLabels();
  return (
    <CustomAccordion
      accTitle={labels.WIRELESS_INFO.toUpperCase()}
      accBodyElement={
        <>
          {deviceData !== undefined ? (
            <div className="diagCoData">
              <p>
                <span>TECHNOLOGY : </span>
                <span>{isNull(deviceData.technology)}</span>
              </p>
            </div>
          ) : (
            <></>
          )}
          <Card className="dsChTabsCard">
            <Card.Header>
              {deviceData !== undefined && wirelessCells?.length !== 0 ? (
                wirelessCells?.map((c: any, index: number) => {
                  return (
                    <CustomTab
                      tabType="horizontal"
                      index={index}
                      value={tabSelIndex}
                      handleSelect={(i: any) => setTabSelIndex(i)}
                      label={`CELL ${index + 1}`}
                    />
                  );
                })
              ) : (
                <></>
              )}
            </Card.Header>
            <Card.Body>
              {deviceData !== undefined && wirelessCells?.length !== 0 ? (
                wirelessCells?.map((c: any, index: number) => {
                  return (
                    <TabPanel value={tabSelIndex} index={index}>
                      <ODUCellInfo cellInfo={c} />
                    </TabPanel>
                  );
                })
              ) : (
                <></>
              )}
            </Card.Body>
          </Card>
        </>
      }
    />
  );
};

export default ODUWirelessInfo;
