import {
    UPDATE_FEEDBACK_BEGIN,
    UPDATE_FEEDBACK_FAILURE,
    UPDATE_FEEDBACK_SUCCESS,
    UpdateFeedbackActionTypes
  } from "../../types/feedback/FeedbackUpdate"

export function UpdateFeedbackBegin(): UpdateFeedbackActionTypes {
    return {
      type: UPDATE_FEEDBACK_BEGIN,
    };
  }
  
  export function UpdateFeedbackSuccess(
    activeCerti: any
  ): UpdateFeedbackActionTypes {
    return {
      type: UPDATE_FEEDBACK_SUCCESS,
      payload: activeCerti,
    };
  }
  
  export function UpdateFeedbackFailure(
    error: Error
  ): UpdateFeedbackActionTypes {
    return {
      type: UPDATE_FEEDBACK_FAILURE,
      payload: error,
    };
  }