import { parseDate } from "../../reusable/Util";
import Userdummy from "../../../resources/images/userdummy.png";

const CommentItem = (props: any) => {
  const comment = props.comment.body.split("-//")?.[0];
  const getName = () => {
    const data = props.comment.body.split("-//");
    if (data?.length > 1) {
      const name = data[1].split("@")?.[0];
      const arrName = name.split(".");
      return arrName?.[0] + " " + arrName[1];
    } else return props.comment.author.displayName;
  };
  return (
    <div className="comment-card">
      <div className="comment-card-upper">
        <img className="feedback-photo" src={Userdummy} />
        <div className="comment-text">
          <b>{getName()}</b>
          <p>{comment}</p>
        </div>
      </div>
      <div className="comment-card-date">
        <p>{parseDate(props.comment.created)}</p>
      </div>
    </div>
  );
};

export default CommentItem;
