import { MdRefresh } from "react-icons/md";
import CustomPopOver from "./CustomPopOver";
const ReloadButton = (props: any) => {
  return (
    <CustomPopOver
      popOverData="Refresh Data"
      originComp={
        <button
          className={`refreshCertiBtn ${props.className}`}
          onClick={() => props.refreshData()}
          disabled={props.disabled}
        >
          <span>Refresh</span>
          <MdRefresh />
        </button>
      }
      index="popOver"
    />
  );
};

export default ReloadButton;
