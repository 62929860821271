interface Props {
  headers: any;
  tBody: any;
}
const SimpleDataTable = ({ headers, tBody }: Props) => {
  return (
    <table className="channelDataTable">
      <thead>
        <tr>
          {headers?.map((h: any, i: number) => {
            return <th key={i}>{h?.toUpperCase()}</th>;
          })}
        </tr>
      </thead>
      <tbody>{tBody}</tbody>
    </table>
  );
};

export default SimpleDataTable;
