import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React from "react";
import { Form } from "react-bootstrap";
import "../../../../design-tokens/forms/EPR.scss";
import RedAsterisk from "../../../reusable/RedAsterisk";
import { useEffect, useState } from "react";
interface Props {
  labels: any;
  date: any;
  setDate: any;
  techId: any;
  accNumber: any;
  emailOptOut: any;
  setDetails: any;
  customerEmails: any;
  equipmentPickupDetailsandSerialNumbers: any;
  setEquipmentPickupDetailsandSerialNumbers: any;
  acknowledgment: any;
  signatureImage: any;
}

const OrderDetails = ({
  labels,
  date,
  setDate,
  techId,
  accNumber,
  emailOptOut,
  setDetails,
  customerEmails,
  equipmentPickupDetailsandSerialNumbers,
  setEquipmentPickupDetailsandSerialNumbers,
  acknowledgment,
  signatureImage,
}: Props) => {
  // const checkFlags = () => {
  //   if (
  //     checkEmailOptFlag() &&
  //     acknowledgment != false &&
  //     signatureImage != ""
  //   ) {
  //     return true;
  //   } else return false;
  // };
  // const checkEmailOptFlag = () => {
  //   if (!props?.emailOptOut) {
  //     return props?.customerEmails != "" && isEmailsValid;
  //   } else {
  //     return emailOptOut;
  //   }
  // };
  useEffect(() => {
    setDetails({
      accountNumber: accNumber,
      customerEmails: customerEmails,
      emailOptOut: emailOptOut,
      date: date,
      technicianId: techId,
      equipmentPickupDetailsandSerialNumbers:
        equipmentPickupDetailsandSerialNumbers,
      acknowledgment: acknowledgment,
      signatureImage: signatureImage,
    });
  }, [
    techId,
    accNumber,
    customerEmails,
    emailOptOut,
    date,
    equipmentPickupDetailsandSerialNumbers,
    acknowledgment,
    signatureImage,
  ]);
  return (
    <>
      <label className="titleLabel">{labels.Order_Details}</label>
      <div className="datePickerContainer">
        <div className="itemContainer">
          <label className="labelTxt">
            {labels.Date}
            <RedAsterisk />
          </label>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              value={date}
              onChange={(date) => setDate(date)}
              format="MM/dd/yyyy"
              inputProps={{ readOnly: true }}
              showTodayButton={true}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
      <Form.Group>
        <label className="txtFieldLabel">{labels.TECH_ID}</label>
        <Form.Control value={techId} disabled={true} />
      </Form.Group>
      {/* <Form.Group>
        <label className="txtFieldLabel">
          {props.labels.Order_Number}
          <RedAsterisk />
        </label>
        <Form.Control
          onChange={(v) => props.setOrderNumber(v.target.value)}
          maxLength={100}
          value={props.orderNumber}
          placeholder={`${props.labels.Enter} ${props.labels.Order_Number}...`}
        />
      </Form.Group> */}
      <Form.Group>
        <label className="txtFieldLabel">
          {labels.Equipment_Pickup_Details_and_Serial_Numbers}
        </label>
        <Form.Control
          onChange={(v) =>
            setEquipmentPickupDetailsandSerialNumbers(v.target.value)
          }
          as="textarea"
          rows={4}
          maxLength={200}
          value={equipmentPickupDetailsandSerialNumbers}
          placeholder={`${labels.Enter} ${labels.Equipment_Pickup_Details_and_Serial_Numbers}...`}
        />
        <p>
          {labels.Total_Characters_Left}:{" "}
          {200 - equipmentPickupDetailsandSerialNumbers.length}
        </p>
      </Form.Group>
    </>
  );
};

export default OrderDetails;
