import React from "react";
import { Divider } from "@material-ui/core";

import "../../../../design-tokens/forms/SR.scss";
import AppLabels from "../../../../constants/App_Labels";
import CustomCarousel from "../../../reusable/CustomCarousel";

interface Props {
  data: any;
}

export default function ViewSeniorDetails({ data }: Props) {
  const formData = data?.formData;
  const labels = AppLabels() as any;

  return (
    <>
      <div className="viewSRContainer">
        <div>
          <label className="titleLabel">{labels.Referral_Status}:</label>
          <label className="textLabel">{formData?.referralStatus}</label>
        </div>
        <div>
          <label className="titleLabel">{labels.Senior_Tech_IDs}:</label>
          <label className="textLabel">{formData?.seniorTechID}</label>
        </div>
        <div>
          <label className="titleLabel">{labels.Senior_Tech_Names}:</label>
          <label className="textLabel">{formData?.seniorTechName}</label>
        </div>
        <div>
          <label className="titleLabel">
            {labels.Senior_Tech_Email_Addresses}:
          </label>
        </div>
        {formData?.emails?.map((e: any, i: number) => (
          <div key={i}>
            <label className="textLabel">{e}</label>
          </div>
        ))}
        {formData?.images && (
          <div style={{ marginTop: "15px", marginBottom: "25px" }}>
            <label className="titleLabel">{labels.Attached_Images}:</label>
            <div className="carouselContainer">
              <CustomCarousel images={formData?.images} />
            </div>
          </div>
        )}
        <Divider style={{ margin: "15px 0px 20px 0px" }} />
        <div>
          <label className="titleLabel">
            {labels.Is_the_customer_satisfied_with_the_outcome}:
          </label>
          <label className="textLabel">{formData?.customerSatisfaction}</label>
        </div>
        <div>
          <label className="titleLabel">
            {labels.Senior_Tech_Notes_And_Root_Cause_Details}:
          </label>
          <label className="textLabel">{formData?.seniorTechNotes}</label>
        </div>
      </div>
      <Divider style={{ margin: "40px 0px 40px 0px" }} />
    </>
  );
}
