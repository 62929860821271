import { CMFlaps } from "../../../../models/account/diagnostics/cm/CMFlaps";

export const CM_FLAPS_INFO_BEGIN = "CM_FLAPS_INFO_BEGIN";
export const CM_FLAPS_INFO_SUCCESS = "CM_FLAPS_INFO_SUCCESS";
export const CM_FLAPS_INFO_FAILURE = "CM_FLAPS_INFO_FAILURE";

interface CMFlapsInfoBegin {
  type: typeof CM_FLAPS_INFO_BEGIN;
}

interface CMFlapsInfoSuccess {
  type: typeof CM_FLAPS_INFO_SUCCESS;
  payload: CMFlaps;
}

interface CMFlapsInfoFailure {
  type: typeof CM_FLAPS_INFO_FAILURE;
  payload: Error;
}

export type CMFlapsInfoActionTypes =
  | CMFlapsInfoBegin
  | CMFlapsInfoSuccess
  | CMFlapsInfoFailure;
