import CustomAccordion from "../../../../../components/reusable/CustomAccordion";
import TopologyLabels from "../../../../constants/topologyLabels";
import "../../../../styles/drawer/nika/Nika.scss";
import { GetNodeInfoDetails } from "../../../../../get-api/GetResponse";
import appConst from "../../../../constants/appConst";
import {
  GetAvailableTR,
  isNull,
  openNewWindow,
} from "../../../../../components/reusable/Util";
import {
  GetNodeSiteDetailsProps,
  GetTopoGeneralProps,
} from "../../../../util/reduxFunctions/getTopologyState";
import { useDispatch } from "react-redux";
import {
  setMapCenter,
  setSelectedMapElement,
} from "../../../../store/slices/mapItems/mapDetails";
import { IoMdLocate } from "react-icons/io";
import {
  combineAllNodeEle,
  findActiveSMTEle,
} from "../../../../util/utilFuncs";
import { useEffect } from "react";
import { setSmtSearchFlag } from "../../../../store/slices/topoGeneral";

interface Props {
  elementData: any;
}

export default function NodeConfigTable({ elementData }: Props) {
  const labels = TopologyLabels();
  const dispatch = useDispatch();
  const { nodeInfo } = GetNodeInfoDetails();
  const { allNodeData } = GetNodeSiteDetailsProps();
  const { isSMTSearch, smtID, smtSearchFlag } = GetTopoGeneralProps();

  let allActiveEle: any = combineAllNodeEle(
    appConst.activeEquipment,
    allNodeData
  );

  const getChannelConfig = (profile: Array<any>, chType: string) =>
    profile?.map((e: any) => (
      <div key={e?.name}>
        <span style={{ fontWeight: "bold" }}>
          {chType?.toUpperCase()} - {e?.cmtsChanId}
        </span>
        <div>
          {labels.LowerFreq}: {e?.freqLower}
        </div>
        <div>
          {labels.UpperFreq}: {e?.freqUpper}
        </div>
        <div>
          {labels.Width}: {e?.width}
        </div>
        <div>
          {labels.MinMod}: {e?.minModulation}
        </div>
        <div>
          {labels.MaxMod}: {e?.maxModulation}
        </div>
      </div>
    ));

  const locateSMT = (smtVal: string) => {
    if (smtVal && allActiveEle && allActiveEle?.length > 0) {
      let activeSmtEle = findActiveSMTEle(smtVal, allActiveEle);
      if (activeSmtEle) {
        dispatch(setMapCenter(activeSmtEle?.geometry));
        dispatch(setSelectedMapElement(activeSmtEle));
        if (smtSearchFlag) dispatch(setSmtSearchFlag(false));
      }
    }
  };

  const getSMTList = (smtVal: any) => {
    if (smtVal) {
      let smtList = smtVal?.split(",");
      if (smtList && smtList?.length > 0) {
        return smtList?.map((smt: any, i: number) => {
          let activeSmtEle = findActiveSMTEle(smt?.trim(), allActiveEle);

          return activeSmtEle ? (
            <span key={i + "smtId"}>
              {smt?.trim()}
              <IoMdLocate
                size={15}
                color="var(--link)"
                onClick={() => locateSMT(smt?.trim())}
              />
            </span>
          ) : (
            <span key={i + "smtId"}>{smt?.trim()}</span>
          );
        });
      } else return "";
    }
  };

  const onCmtsIpClick = (nodeInfo: any) => {
    openNewWindow(
      `/#/node/cmts?nodeId=${nodeInfo?.nodeId}&mac=${nodeInfo?.macDomain}&usPort=${nodeInfo?.usPort}&cmtsIp=${nodeInfo?.cmtsIp}`
    );
  };

  useEffect(() => {
    if (isSMTSearch && smtSearchFlag && smtID !== "") {
      locateSMT(smtID);
    }
  }, [allActiveEle]);

  return (
    <div className="activeMapEleCont">
      {nodeInfo && nodeInfo?.nodeId === elementData?.data?.nodeNum && (
        <CustomAccordion
          accTitle={labels.NodeOverview.toUpperCase()}
          accBodyElement={
            <table className="dataTableDetails">
              <thead>
                <tr>
                  <td>{labels?.name.toUpperCase()}</td>
                  <td>{"Value".toUpperCase()}</td>
                </tr>
              </thead>
              <tbody>
                <GetAvailableTR name={labels.PHUB} value={nodeInfo?.phub} />
                <tr>
                  <td>{labels.SMT}</td>
                  <td>
                    <p className="smtListCell">
                      {getSMTList(nodeInfo?.serviceAreas)}
                    </p>
                  </td>
                </tr>
                <GetAvailableTR
                  name={labels.USPEERS}
                  value={nodeInfo?.usAssociatedNodes?.join(", ")}
                />
                <tr>
                  <td>{labels.CMTSIP}</td>
                  <td>
                    <span
                      className="nodeLink"
                      style={{ fontWeight: Number(`var(--topoTDFN)`) }}
                      onClick={() => onCmtsIpClick(nodeInfo)}
                    >
                      {isNull(nodeInfo?.cmtsIp)}
                    </span>
                  </td>
                </tr>
                <GetAvailableTR
                  name={labels.CMTSNAME}
                  value={nodeInfo?.cmtsName}
                />
                <GetAvailableTR
                  name={labels.MACDOMAIN}
                  value={nodeInfo?.macDomain}
                />
                <GetAvailableTR
                  name={labels.HomesPassed}
                  value={nodeInfo?.homesPassed}
                />
                <GetAvailableTR
                  name={labels.FullModemCount}
                  value={nodeInfo?.fullCount}
                />
                <GetAvailableTR
                  name={labels.IgniteModemCount}
                  value={nodeInfo?.igniteCount}
                />
                <GetAvailableTR
                  name={labels.LegacyModemCount}
                  value={`${
                    Number(nodeInfo?.hsiCount) - Number(nodeInfo?.igniteCount)
                  }`}
                />
                <GetAvailableTR
                  name={labels.USPorts}
                  value={nodeInfo?.usPort}
                />
                <GetAvailableTR
                  name={labels.Architecture}
                  value={nodeInfo?.arch}
                />
                <tr>
                  <td style={{ verticalAlign: "middle" }}>
                    {labels.OFDM_CHANCONFIG}
                  </td>
                  <td>
                    {getChannelConfig(nodeInfo?.ofdmProfiles, appConst.ofdm)}
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: "middle" }}>
                    {labels.OFDMA_CHANCONFIG}
                  </td>
                  <td>
                    {getChannelConfig(nodeInfo?.ofdmaProfiles, appConst.ofdma)}
                  </td>
                </tr>
              </tbody>
            </table>
          }
        />
      )}
    </div>
  );
}
