import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { IPTVNetworkState } from "../../../store/states/account/diagnostics/iptv/IPTVNetworkState";
import {
  iptvNetworkBegin,
  iptvNetworkFailure,
  iptvNetworkSuccess,
} from "../../../store/actions/account/diagnostics/iptv/IPTVNetworkAction";
import { IPTVCertiNetworkState } from "../../../store/states/account/certifications/iptv/IPTVCertiNetworkState";
import {
  IptvCertiNetworkBegin,
  IptvCertiNetworkFailure,
  IptvCertiNetworkSuccess,
} from "../../../store/actions/account/certifications/iptv/IPTVCertiNetworkAction";

import { IptvDevice } from "../../../store/models/account/diagnostics/iptv/IptvDevice";
import { IptvEthernet } from "../../../store/models/account/diagnostics/iptv/IptvEthernet";
import { IptvNetwork } from "../../../store/models/account/diagnostics/iptv/IptvNetwork";
import { IptvWIFI } from "../../../store/models/account/diagnostics/iptv/IptvWIFI";
import { getOUIChecked } from "../../../components/reusable/Util";
import {
  DecrementApiCallCounter,
  IncrementApiCallCounter,
  UpdateActiveCertiDevices,
} from "../../../store/actions/account/certifications/ActiveCertiDevicesAction";
import {
  DecrementDiagApiCallCounter,
  IncrementDiagApiCallCounter,
  UpdateActiveDiagDevices,
} from "../../../store/actions/account/diagnostics/ActiveDiagDevicesActions";
interface Props {
  oui: string;
  serialNum: string;
  hhid?: string;
  macAddress?: string;
}
export default class IPTVInfoClient {
  static getIptvDevice =
    (props: Props): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let qParam = getOUIChecked(
        props.oui,
        props.serialNum,
        props.hhid,
        props.macAddress
      );
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/iptv/device",
        params: qParam,
      };

      dispatch(IncrementDiagApiCallCounter(props.serialNum));

      HttpClient.get<IptvDevice>(httpParameters)
        .then((iptvDevice) => {
          dispatch(
            UpdateActiveDiagDevices(props.serialNum, "device", iptvDevice)
          );
          dispatch(DecrementDiagApiCallCounter(props.serialNum));
          return iptvDevice;
        })
        .catch((error) => {
          dispatch(UpdateActiveDiagDevices(props.serialNum, "device", error));
          dispatch(DecrementDiagApiCallCounter(props.serialNum));
          return error;
        });
    };

  static getIptvNetwork =
    (
      props: Props
    ): ThunkAction<void, IPTVNetworkState, unknown, Action<string>> =>
    async (dispatch) => {
      let qParam = getOUIChecked(
        props.oui,
        props.serialNum,
        props.hhid,
        props.macAddress
      );
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/iptv/network",
        params: qParam,
      };

      dispatch(iptvNetworkBegin());
      dispatch(IncrementDiagApiCallCounter(props.serialNum));

      HttpClient.get<IptvNetwork>(httpParameters)
        .then((iptvNetwork) => {
          dispatch(iptvNetworkSuccess(iptvNetwork));
          dispatch(
            UpdateActiveDiagDevices(props.serialNum, "network", iptvNetwork)
          );
          dispatch(DecrementDiagApiCallCounter(props.serialNum));
          return iptvNetwork;
        })
        .catch((error) => {
          dispatch(iptvNetworkFailure(error));
          dispatch(UpdateActiveDiagDevices(props.serialNum, "network", error));
          dispatch(DecrementDiagApiCallCounter(props.serialNum));
          return error;
        });
    };

  static getIptvEthernet =
    (props: Props): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let qParam = getOUIChecked(
        props.oui,
        props.serialNum,
        props.hhid,
        props.macAddress
      );
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/iptv/ethernet",
        params: qParam,
      };

      dispatch(IncrementDiagApiCallCounter(props.serialNum));

      HttpClient.get<IptvEthernet>(httpParameters)
        .then((iptvEthernet) => {
          dispatch(
            UpdateActiveDiagDevices(props.serialNum, "ethernet", iptvEthernet)
          );
          dispatch(DecrementDiagApiCallCounter(props.serialNum));
          return iptvEthernet;
        })
        .catch((error) => {
          dispatch(UpdateActiveDiagDevices(props.serialNum, "ethernet", error));
          dispatch(DecrementDiagApiCallCounter(props.serialNum));
          return error;
        });
    };

  static getIptvWifi =
    (props: Props): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let qParam = getOUIChecked(
        props.oui,
        props.serialNum,
        props.hhid,
        props.macAddress
      );
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/iptv/wifi",
        params: qParam,
      };

      dispatch(IncrementDiagApiCallCounter(props.serialNum));

      HttpClient.get<IptvWIFI>(httpParameters)
        .then((iptvWIFI) => {
          dispatch(UpdateActiveDiagDevices(props.serialNum, "wifi", iptvWIFI));
          dispatch(DecrementDiagApiCallCounter(props.serialNum));
          return iptvWIFI;
        })
        .catch((error) => {
          dispatch(UpdateActiveDiagDevices(props.serialNum, "wifi", error));
          dispatch(DecrementDiagApiCallCounter(props.serialNum));
          return error;
        });
    };
  static getIptvCertiDevice =
    (props: Props): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let qParam = getOUIChecked(
        props.oui,
        props.serialNum,
        props.hhid,
        props.macAddress
      );
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/iptv/device",
        params: qParam,
      };

      dispatch(IncrementApiCallCounter(props.serialNum));

      HttpClient.get<IptvDevice>(httpParameters)
        .then((iptvDevice) => {
          dispatch(
            UpdateActiveCertiDevices(props.serialNum, "device", iptvDevice)
          );
          dispatch(DecrementApiCallCounter(props.serialNum));
          return iptvDevice;
        })
        .catch((error) => {
          dispatch(UpdateActiveCertiDevices(props.serialNum, "device", error));
          dispatch(DecrementApiCallCounter(props.serialNum));
          return error;
        });
    };

  static getIptvCertiNetwork =
    (
      props: Props
    ): ThunkAction<void, IPTVCertiNetworkState, unknown, Action<string>> =>
    async (dispatch) => {
      let qParam = getOUIChecked(
        props.oui,
        props.serialNum,
        props.hhid,
        props.macAddress
      );
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/iptv/network",
        params: qParam,
      };

      dispatch(IptvCertiNetworkBegin());
      dispatch(IncrementApiCallCounter(props.serialNum));

      HttpClient.get<IptvNetwork>(httpParameters)
        .then((iptvNetwork) => {
          dispatch(IptvCertiNetworkSuccess(iptvNetwork));
          dispatch(
            UpdateActiveCertiDevices(props.serialNum, "network", iptvNetwork)
          );
          dispatch(DecrementApiCallCounter(props.serialNum));
          return iptvNetwork;
        })
        .catch((error) => {
          dispatch(IptvCertiNetworkFailure(error));
          dispatch(UpdateActiveCertiDevices(props.serialNum, "network", error));
          dispatch(DecrementApiCallCounter(props.serialNum));
          return error;
        });
    };

  static getIptvCertiEthernet =
    (props: Props): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let qParam = getOUIChecked(
        props.oui,
        props.serialNum,
        props.hhid,
        props.macAddress
      );
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/iptv/ethernet",
        params: qParam,
      };

      dispatch(IncrementApiCallCounter(props.serialNum));

      HttpClient.get<IptvEthernet>(httpParameters)
        .then((iptvEthernet) => {
          dispatch(
            UpdateActiveCertiDevices(props.serialNum, "ethernet", iptvEthernet)
          );
          dispatch(DecrementApiCallCounter(props.serialNum));
          return iptvEthernet;
        })
        .catch((error) => {
          dispatch(
            UpdateActiveCertiDevices(props.serialNum, "ethernet", error)
          );
          dispatch(DecrementApiCallCounter(props.serialNum));
          return error;
        });
    };

  static getIptvCertiWifi =
    (props: Props): ThunkAction<void, any, unknown, Action<string>> =>
    async (dispatch) => {
      let qParam = getOUIChecked(
        props.oui,
        props.serialNum,
        props.hhid,
        props.macAddress
      );
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/iptv/wifi",
        params: qParam,
      };

      dispatch(IncrementApiCallCounter(props.serialNum));

      HttpClient.get<IptvWIFI>(httpParameters)
        .then((iptvWIFI) => {
          dispatch(UpdateActiveCertiDevices(props.serialNum, "wifi", iptvWIFI));
          dispatch(DecrementApiCallCounter(props.serialNum));
          return iptvWIFI;
        })
        .catch((error) => {
          dispatch(UpdateActiveCertiDevices(props.serialNum, "wifi", error));
          dispatch(DecrementApiCallCounter(props.serialNum));
          return error;
        });
    };
}
