import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import CustomDropDown from "../../components/reusable/CustomDropDown";
import CustomPopUp from "../../components/reusable/CustomPopUp";
import DiagContainer from "../../components/reusable/DiagContainer";
import {
  calculatingNodeAveragesData,
  getNodeTeleGloabalStatus,
} from "../../components/reusable/NodeUtil";
import { isNull } from "../../components/reusable/Util";
import ClearFlaps from "../../components/telemetry/ClearFlaps";
import ColumnDropDown from "../../components/telemetry/ColumnDropDown";
import NodeAverages from "../../components/telemetry/NodeAverages";
import TelemetryActionMenu from "../../components/telemetry/TelemetryActionMenu";
import { getStbTeleActiveDataSet } from "../../config/STBTelemetryConfig";
import AppLabels from "../../constants/App_Labels";
import ConfigConst from "../../constants/ConfigConst";
import {
  GetNodeInfoDetails,
  GetTelemetryAverages,
  GetTelemetryDetails,
} from "../../get-api/GetResponse";
import NodeInfoDetailsClient from "../../network/httpclient/nodeinfo/NodeInfoDetailsClient";
import { SetNodeDevicesError } from "../../store/actions/node/telemetry/NodeDevicesInfoActions";
import {
  updateConfigDataCheckbox,
  updateConfigDataReposition,
} from "../../store/ducksPattern/userPrefConfig";
import { setViewType } from "../../topology/store/slices/mapItems/diagnostics/telemetry";
import storeConst from "../../topology/store/storeConst";
import { GetTelemetryProps } from "../../topology/util/reduxFunctions/getTopologyState";
import STBAverages from "./STBAverages";
import STBTeleDevicesTable from "./STBTeleDevicesTable";
import { isFullDiagPresent } from "../../topology/util/utilFuncs";

interface Props {
  screenType: string;
  reloadNodeInfo: any;
}

const STBTeleTab = ({ screenType, reloadNodeInfo }: Props) => {
  const dispatch = useDispatch();
  const labels = AppLabels();
  const { currNodedata } = GetNodeInfoDetails();
  const {
    mlData,
    mlIsLoading,
    mlError,
    allDiagIsLoading,
    allDiagStatus,
    isAborted,
    allDiagError,
    diagType,
    modemType,
  } = GetTelemetryProps();
  const { ducksConfigData } = GetTelemetryDetails();
  const nodeTeleStatus = getNodeTeleGloabalStatus(
    allDiagStatus,
    allDiagIsLoading,
    isAborted
  );
  const { nodeAveragesStatusData, nodeAveragesStatusLoading } =
    GetTelemetryAverages();
  const address = ConfigConst.address.toLowerCase();
  const [activeDataSet, setActiveDataSet] = useState<any>();
  const [viewTypeOptions, setViewTypeOptions] = useState<any>([]);
  const [teleViewType, setTeleViewType] = useState<any>("");
  const [selectAttrVal, setSelectAttrVal] = useState<any>([]);
  const [tableAttributes, setTableAttributes] = useState<any>([]);
  const [tableData, setTableData] = useState();
  const [dragOver, setDragOver] = useState("");
  const [popUpFlag, setPopUpFlag] = useState(false);
  const [popUpHeader, setPopUpHeader] = useState("");
  const [popUpBody, setPopUpBody] = useState(<div></div>);
  const [averagesPopUp, setAveragesPopUp] = useState(false);
  const [avgPopUpBody, setAvgPopUpBody] = useState(<div></div>);
  const [nodeAvgData, setNodeAvgData] = useState({});
  const [teleData, setTeleData] = useState(mlData);

  const isFullDiagAvail = isFullDiagPresent(teleData);
  const handleDragStart = (event: any) => {
    const { id } = event.target;
    const idx = tableAttributes.findIndex(
      (obj: any) => obj.jsonDataMapping === id
    );
    event.dataTransfer.setData("colIdx", idx);
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };
  const handleDragEnter = (event: any) => {
    const { id } = event.target;
    const identifier =
      id === "null" || id === null || id === "" ? dragOver : id;
    setDragOver((state) => {
      state = identifier;
      return state;
    });
  };

  const handleOnDrop = (event: any) => {
    const { id } = event.target;
    const identifier =
      id === "null" || id === null || id === "" ? dragOver : id;
    const droppedColIdx = tableAttributes.findIndex(
      (obj: any) => obj.jsonDataMapping == identifier
    );
    const draggedColIdx = event.dataTransfer.getData("colIdx");
    const tempCols = [...tableAttributes];
    const tempCols1 = [...tableAttributes];
    if (droppedColIdx !== -1 && draggedColIdx !== -1) {
      if (droppedColIdx < draggedColIdx) {
        tempCols[droppedColIdx] = tempCols1[draggedColIdx];
        tempCols[droppedColIdx + 1] = tempCols1[droppedColIdx];

        tempCols1.splice(draggedColIdx, 1);
        for (let i = droppedColIdx + 2; i <= tempCols1.length; i++) {
          tempCols[i] = tempCols1[i - 1];
        }
      } else if (draggedColIdx < droppedColIdx) {
        tempCols[droppedColIdx] = tempCols1[draggedColIdx];
        tempCols[droppedColIdx - 1] = tempCols1[droppedColIdx];
        tempCols1.splice(draggedColIdx, 1);
        for (let i = droppedColIdx - 2; i >= 0; i--) {
          tempCols[i] = tempCols1[i];
        }
      }
      if (teleViewType) {
        dispatch(
          updateConfigDataReposition({ viewVal: teleViewType, tempCols })
        );
      }
    }
    setDragOver((state) => {
      state = "";
      return state;
    });
  };

  const handleCheckboxClick = (option: string) => {
    if (teleViewType)
      dispatch(updateConfigDataCheckbox({ viewVal: teleViewType, option }));
  };

  useEffect(() => {
    if (!isAborted && allDiagError) {
      setPopUpFlag(true);
      setPopUpHeader(labels.ERROR.toUpperCase());
      setPopUpBody(
        <span className="telemertyinfoBoxSpan">{labels.NO_DATA}</span>
      );
    }
  }, [allDiagError]);

  const filterBySMT = (filterKey: string) => {
    if (filterKey?.length > 0 && filterKey !== labels.ALL?.toUpperCase()) {
      return mlData
        ?.filter((d: any) => isNull(d))
        .filter(
          (d: any) =>
            isNull(d.serviceArea) &&
            d.serviceArea
              ?.toLowerCase()
              ?.includes(filterKey?.trim()?.toLowerCase())
        );
    }
    return mlData;
  };

  useEffect(() => {
    let filterData;
    filterData = filterBySMT(currNodedata);
    setTeleData(filterData);
  }, [currNodedata, mlData]);

  useEffect(() => {
    if (
      nodeTeleStatus === ConfigConst.complete.toLowerCase()
    ) {
      calculatingNodeAveragesData(teleData, setNodeAvgData);
    }
  }, [nodeTeleStatus, teleData]);

  useEffect(() => {
    if (
      nodeAvgData !== undefined &&
      Object.keys(nodeAvgData)?.length > 0 &&
      activeDataSet?.dataSetName?.toLowerCase() !==
        ConfigConst.BASIC?.toLowerCase()
    ) {
      dispatch(
        NodeInfoDetailsClient.getNodeAvergesStatus(
          nodeAvgData,
          currNodedata?.smt ? currNodedata?.smtId : currNodedata?.nodeId
        )
      );
    }
  }, [nodeAvgData]);

  useEffect(() => {
    if (teleViewType !== undefined && teleViewType !== "") {
      ducksConfigData?.userPreferences?.node?.telemetry?.views?.map(
        (v: any) => {
          if (v?.viewTypeKey?.toLowerCase() === teleViewType?.toLowerCase()) {
            setTableAttributes(v?.attributes);
            setSelectAttrVal(v?.attributes);
          }
        }
      );
    }
  }, [teleViewType, ducksConfigData]);

  useEffect(() => {
    if (diagType !== undefined && diagType !== "") {
      let tempDiagVal =
        diagType === storeConst.all_no_cw || diagType === storeConst.all
          ? ConfigConst.FULL_NO_CW.toUpperCase()
          : ConfigConst.BASIC.toUpperCase();

      let activeDataSet = getStbTeleActiveDataSet(screenType, tempDiagVal);
      if (activeDataSet && activeDataSet?.dataSetName) {
        setActiveDataSet(activeDataSet);
        setViewTypeOptions(activeDataSet?.subDataSetViews);
        dispatch(setViewType(activeDataSet?.subDataSetViews?.[0]?.columnKey));
        setTeleViewType(activeDataSet?.subDataSetViews?.[0]?.columnKey);
      }
    }
  }, [diagType, activeDataSet]);

  return (
    <div className="stbTeleTabCont">
      {/* {activeDataSet?.dataSetName !== ConfigConst.BASIC.toUpperCase() && ( */}
      <div className="nodeFilterCont">
        <div className="nodeFilters nodeFilterContainer">
          {/* {viewTypeOptions?.length !== 0 && (
            <CustomDropDown
              disabled={
                activeDataSet?.dataSetName?.toUpperCase() ===
                ConfigConst.BASIC?.toUpperCase()
              }
              ddTitle={labels.VIEW_TYPE?.toUpperCase()}
              selectedOption={teleViewType}
              options={viewTypeOptions}
              searchFlag={false}
              checkboxFlag={false}
              setSelectedOption={(value: any) => {
                setTeleViewType(value);
                dispatch(setViewType(value));
              }}
            />
          )} */}
          {viewTypeOptions?.length !== 0 && (
            <ColumnDropDown
              disabled={
                activeDataSet?.dataSetName?.toUpperCase() ===
                ConfigConst.BASIC?.toUpperCase()
              }
              ddTitle={labels.VIEW_TYPE?.toUpperCase()}
              selectedOption={
                viewTypeOptions?.filter(
                  (obj: any) =>
                    obj?.columnKey?.toUpperCase() ===
                    teleViewType?.toUpperCase()
                )?.[0]?.columnName
              }
              options={viewTypeOptions}
              searchFlag={false}
              checkboxFlag={false}
              setSelectedOption={(value: any) => {
                setTeleViewType(value);
                dispatch(setViewType(value));
              }}
            />
          )}

          {teleViewType && activeDataSet?.fieldSelectionAllowed && (
            <ColumnDropDown
              ddTitle={labels.fields?.toUpperCase()}
              selectedOption=""
              options={
                currNodedata?.smt
                  ? tableAttributes?.filter(
                      (e: any) =>
                        e?.columnKey !== address && e?.columnKey !== "smt"
                    )
                  : tableAttributes?.filter(
                      (e: any) => e?.columnKey !== address
                    )
              }
              searchFlag={true}
              checkboxFlag={true}
              setSelectedOption={(value: any) => {}}
              selectAttrVal={selectAttrVal}
              handleCheckBox={handleCheckboxClick}
              handleDragStart={handleDragStart}
              handleDragOver={handleDragOver}
              handleOnDrop={handleOnDrop}
              handleDragEnter={handleDragEnter}
              dragOver={dragOver}
            />
          )}
        </div>
        {teleViewType && activeDataSet?.averagesAllowed && (
          <>
            {modemType === ConfigConst.ecmstb ? (
              <NodeAverages
                setAveragesPopUp={setAveragesPopUp}
                setAvgPopUpBody={setAvgPopUpBody}
                nodeTeleStatus={nodeTeleStatus}
                nodeAvgStatusData={nodeAveragesStatusData}
                nodeAveragesStatusLoading={nodeAveragesStatusLoading}
                isFullDiagPresent={isFullDiagAvail}
              />
            ) : modemType === ConfigConst.oldstb ? (
              <STBAverages
                setAveragesPopUp={setAveragesPopUp}
                setAvgPopUpBody={setAvgPopUpBody}
                nodeTeleStatus={nodeTeleStatus}
                nodeAvgData={mlData}
                isFullDiagPresent={isFullDiagAvail}
              />
            ) : (
              <></>
            )}
          </>
        )}
        <div className="designMaintainer" />
        {teleViewType?.toUpperCase() === labels.FLAPS?.toUpperCase() && (
          <ClearFlaps
            deviceType={
              modemType
                ? modemType?.toUpperCase()
                : ConfigConst.ecmstb.toUpperCase()
            }
            selectedMac=""
          />
        )}
        {mlData !== undefined && mlData?.length !== 0 && (
          <TelemetryActionMenu
            menuType="vertical"
            viewDropdown={teleViewType}
            columnData={tableAttributes}
            rowData={tableData}
            nodeDevicesList={teleData}
            nodeId={currNodedata?.nodeId}
            deviceType={modemType?.toUpperCase()}
            isMultiDevice={false}
          />
        )}
      </div>
      {/* )} */}
      <div>
        <DiagContainer
          data={mlData}
          isLoading={mlIsLoading}
          error={mlError}
          sectionName={labels.NODE_TELEMETRY_INFO?.toUpperCase()}
          handleRefresh={reloadNodeInfo}
        >
          <STBTeleDevicesTable
            tableAttributes={tableAttributes}
            nodeDevicesList={teleData}
            onDataChange={setTableData}
            handleDragStart={handleDragStart}
            handleDragOver={handleDragOver}
            handleOnDrop={handleOnDrop}
            handleDragEnter={handleDragEnter}
            dragOver={dragOver}
          />
        </DiagContainer>
        <CustomPopUp
          showVal={popUpFlag}
          headerText={popUpHeader}
          bodyElement={popUpBody}
          handleClose={() => {
            setPopUpFlag(false);
            dispatch(SetNodeDevicesError(false));
          }}
        />
        <CustomPopUp
          showVal={averagesPopUp}
          headerText={labels.NODE_AVERAGES}
          bodyElement={avgPopUpBody}
          handleClose={() => {
            setAveragesPopUp(false);
          }}
        />
      </div>
    </div>
  );
};

export default STBTeleTab;
