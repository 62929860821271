import CustomAccordion from "../../../../../../../components/reusable/CustomAccordion";
import appConst from "../../../../../../constants/appConst";
import PortsListTable from "../../../../../drawer/elements/actives/PortsListTable";
import MapEleDataDisplay from "../../../../../reusable/MapEleDataDisplay";

interface Props {
  eleConfig: any;
  eleData: any;
}

const MduActiveEquipment = ({ eleConfig, eleData }: Props) => {
  return (
    <div className="activeMapEleCont">
      {eleData !== undefined &&
        eleConfig !== undefined &&
        eleConfig?.length !== 0 && (
          <>
            <CustomAccordion
              accTitle={eleConfig?.title?.toUpperCase()}
              accBodyElement={
                <>
                  {eleData && (
                    <MapEleDataDisplay
                      tableConfig={eleConfig}
                      eleData={eleData}
                    />
                  )}
                </>
              }
            />
            <CustomAccordion
              accTitle={appConst?.powerLevel?.toUpperCase()}
              accBodyElement={
                <PortsListTable elementData={{ data: eleData }} />
              }
            />
          </>
        )}
    </div>
  );
};

export default MduActiveEquipment;
