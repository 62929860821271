import { BiCurrentLocation } from "react-icons/bi";
import { useDispatch } from "react-redux";

import TopologyLabels from "../../../constants/topologyLabels";
import { usePowertraceStates } from "../../../util/reduxFunctions/getTopologyState";
import {
  setMapCenter,
  setSelectedMapElement,
} from "../../../store/slices/mapItems/mapDetails";

interface Props {
  showPopover: boolean;
  setShowPopover: (arg0: boolean) => void;
}

export default function LocateToPowerSupply({
  showPopover,
  setShowPopover,
}: Props) {
  const labels = TopologyLabels();
  const dispatch = useDispatch();
  const { isActive, psInfo } = usePowertraceStates();

  const onPowerSupplyLocateClick = () => {
    setShowPopover(!showPopover);
    const activeElement = {
      geometry: psInfo?.coord,
      properties: psInfo,
    };
    dispatch(
      setMapCenter({ lat: psInfo?.coord?.lat, lng: psInfo?.coord?.lng })
    );
    dispatch(setSelectedMapElement(activeElement));
  };

  return (
    <>
      {isActive && psInfo !== undefined && (
        <section
          onClick={onPowerSupplyLocateClick}
          className="popoverItemsCont"
        >
          {labels.locateToPowerSupply}
          <BiCurrentLocation />
        </section>
      )}
    </>
  );
}
