import { IHPQsipData } from "../../../../models/account/diagnostics/ihp/IHPQsipData";

export const IHP_QSIP_DATA_BEGIN = "IHP_QSIP_DATA_BEGIN";
export const IHP_QSIP_DATA_SUCCESS = "IHP_QSIP_DATA_SUCCESS";
export const IHP_QSIP_DATA_FAILURE = "IHP_QSIP_DATA_FAILURE";

interface IhpQsipDataBegin {
  type: typeof IHP_QSIP_DATA_BEGIN;
}

interface IhpQsipDataSuccess {
  type: typeof IHP_QSIP_DATA_SUCCESS;
  payload: IHPQsipData;
}

interface IhpQsipDataFailure {
  type: typeof IHP_QSIP_DATA_FAILURE;
  payload: Error;
}

export type IhpQsipDataActionTypes =
  | IhpQsipDataBegin
  | IhpQsipDataSuccess
  | IhpQsipDataFailure;
