import { useEffect, useRef, useState } from "react";
import { Stage, Layer, Image } from "react-konva";
import { KonvaEventObject } from "konva/lib/Node";

import GeoEditMenu, { IOnAddTextInput } from "./menu/GeoEditMenu";
import Rectangles, { IGeoRectShape } from "./shapes/Rectangles";
import Stars, { IGeoStarShape, calcStarRadius } from "./shapes/Stars";
import Circles, { IGeoCircleShape, calcCircleRadius } from "./shapes/Circles";
import Texts, { IGeoTxt } from "./shapes/Texts";
import PenLines, { IGeoPenLineShape } from "./shapes/PenLines";
import Arrows, { IGeoArrowShape, calcArrowPoints } from "./shapes/Arrows";
import Lines, { IGeoLineShape, calcLinePoints } from "./shapes/Lines";
import GeoEdits, { IOnGeoEdits } from "./GeoEdits";
import Triangles, {
  IGeoTriangleShape,
  calcTriangleRadius,
} from "./shapes/Triangle";
import Hexagons, {
  IGeoHexagonsShape,
  calcHexagonsRadius,
} from "./shapes/Hexagons";
import Images, { IGeoImagesShape } from "./shapes/Images";
import Pies, { IGeoPieShape, calcPieAngle, calcPieRadius } from "./shapes/Pies";
import { readClipboardImage } from "../../../util/utilFuncs";
import { ITechProfile } from "../../../../store/ducksPattern/TechProfile";

interface MyClipboardItem {
  new (items: Record<string, Blob>): any;
}

export interface IGeoEditShape {
  id: string;
  x: number;
  y: number;
  rotation?: number;
  isDragging: boolean;
  opacity: number;
  stroke?: string;
}
export interface IDrawPosition {
  x: number;
  y: number;
}

export type DrawingType =
  | "rectangle"
  | "circle"
  | "star"
  | "pen"
  | "text"
  | "arrow"
  | "line"
  | "triangle"
  | "hexagon"
  | "image"
  | "pie"
  | "none";

interface SelectedShape {
  shape: DrawingType;
  id: string;
}
export interface ICommonDrawingProps {
  color: string;
  drawingType: DrawingType;
  setDrawingType: (drawing: DrawingType) => void;
  drawStartPosi: IDrawPosition | undefined;
  drawEndPosi: IDrawPosition | undefined;
  strokeWidth: number;
  opacity: number;
  fill: string;
  isSelected: ({ id, shape }: { id: string; shape: DrawingType }) => boolean;
  getDash: ({ id, shape }: { id: string; shape: DrawingType }) => Array<any>;
  selectedShape: SelectedShape;
}

export interface IAddToHistoryInput {
  rects?: IGeoRectShape[];
  circs?: IGeoCircleShape[];
  sts?: IGeoStarShape[];
  txts?: IGeoTxt[];
  penLns?: IGeoPenLineShape[];
  arws?: IGeoArrowShape[];
  lns?: IGeoLineShape[];
  tris?: IGeoTriangleShape[];
  hexa?: IGeoHexagonsShape[];
  imgs?: IGeoImagesShape[];
  pis?: IGeoPieShape[];
}

interface IHistory {
  rectangles: IGeoRectShape[];
  circles: IGeoCircleShape[];
  stars: IGeoStarShape[];
  texts: IGeoTxt[];
  penLines: IGeoPenLineShape[];
  arrows: IGeoArrowShape[];
  lines: IGeoLineShape[];
  triangles: IGeoTriangleShape[];
  hexagons: IGeoHexagonsShape[];
  images: IGeoImagesShape[];
  pies: IGeoPieShape[];
}

interface IEditProps extends IOnGeoEdits {
  id: string;
}

interface Props {
  bgImage?: string;
  latLng?: { latitude: number; longitude: number };
  nodeId?: string;
  techData?: ITechProfile["data"];
  owningDrawing?: string;
}

export default function GeoEditCanvas({
  bgImage,
  latLng,
  nodeId,
  techData,
  owningDrawing,
}: Props) {
  const stageRef = useRef<any>(null);
  const [bgImageObj, setBgImageObj] = useState<HTMLImageElement | null>(null);
  const [rectangles, setRectangles] = useState<IGeoRectShape[]>([]);
  const [circles, setCircles] = useState<IGeoCircleShape[]>([]);
  const [stars, setStars] = useState<IGeoStarShape[]>([]);
  const [texts, setTexts] = useState<IGeoTxt[]>([]);
  const [penLines, setPenLines] = useState<IGeoPenLineShape[]>([]);
  const [penPoints, setPenPoints] = useState<number[]>([]);
  const [arrows, setArrows] = useState<IGeoArrowShape[]>([]);
  const [lines, setLines] = useState<IGeoLineShape[]>([]);
  const [triangles, setTriangles] = useState<IGeoTriangleShape[]>([]);
  const [hexagons, setHexagons] = useState<IGeoHexagonsShape[]>([]);
  const [pies, setPies] = useState<IGeoPieShape[]>([]);
  const [images, setImages] = useState<IGeoImagesShape[]>([]);
  const [drawingType, setDrawingType] = useState<DrawingType>("pen");
  const [drawStartPosi, setDrawStartPosi] = useState<IDrawPosition>();
  const [drawEndPosi, setDrawEndPosi] = useState<IDrawPosition>();
  const [history, setHistory] = useState<IHistory[]>([]);
  const [historyStep, setHistoryStep] = useState<number>(0);
  const [color, setColor] = useState("#0000FF");
  const [strokeWidth, setStrokeWidth] = useState(15);
  const [opacity, setOpacity] = useState(10);
  const [fill, setFill] = useState("transparent");
  const [txtSize, setTxtSize] = useState(30);
  const [txtWidth, setTxtWidth] = useState(300);
  const [txtColor, setTxtColor] = useState("#08F8FD");
  const [editProps, setEditProps] = useState<IEditProps>({
    fill,
    stroke: color,
    strokeWidth,
    activeItem: "none",
    id: "",
    opacity,
  });
  const [selectedShape, setSelectedShape] = useState<SelectedShape>();
  const cdp = {
    color,
    drawingType,
    setDrawingType,
    drawStartPosi,
    drawEndPosi,
    strokeWidth,
    opacity,
    fill,
    getDash: ({ id, shape }) =>
      editProps?.activeItem === shape && editProps?.id === id ? [5, 5] : [],
    selectedShape,
    isSelected: ({ id, shape }) =>
      selectedShape?.shape === shape && selectedShape?.id === id,
  } as ICommonDrawingProps; //commonDrawingProps
  const bgImageName = "backgroundImage";

  //create background image from base64
  useEffect(() => {
    if (bgImage) {
      const img = new window.Image();
      img.onload = () => setBgImageObj(img);
      img.src = bgImage;
    }
  }, [bgImage]);

  const handleStageMouseDown = (e: KonvaEventObject<MouseEvent>) => {
    const position = e.target.getStage()?.getPointerPosition();
    if (position) {
      setEditProps((pv) => ({ ...pv, activeItem: "none" }));
      setDrawStartPosi(position);
      setDrawEndPosi(position);
      if (drawingType === "pen") setPenPoints([position.x, position.y]);
    }

    // if clicking outside shape - remove trasformer
    if (e.target === stageRef.current || e.target.name() === bgImageName) {
      setSelectedShape(undefined);
    }
  };
  const handleStageMouseMove = (e: KonvaEventObject<MouseEvent>) => {
    const pos = e.target.getStage()?.getPointerPosition();
    if (pos) {
      setDrawEndPosi(pos);
      if (drawingType === "pen") setPenPoints((pv) => [...pv, pos.x, pos.y]);
    }
  };
  const handleStageMouseUp = () => {
    switch (drawingType) {
      case "rectangle":
        addRect();
        break;
      case "circle":
        addCircle();
        break;
      case "star":
        addStar();
        break;
      case "pen":
        addPenLine();
        break;
      case "arrow":
        addArrow();
        break;
      case "line":
        addLine();
        break;
      case "triangle":
        addTriangle();
        break;
      case "hexagon":
        addHexagon();
        break;
      case "pie":
        addPie();
        break;
      default:
        break;
    }

    setDrawStartPosi(undefined);
    setDrawEndPosi(undefined);
  };

  const addToHistory = ({
    rects = rectangles,
    circs = circles,
    sts = stars,
    txts = texts,
    penLns = penLines,
    arws = arrows,
    lns = lines,
    tris = triangles,
    hexa = hexagons,
    imgs = images,
    pis = pies,
  }: IAddToHistoryInput) => {
    const updatedHistory = history.slice(0, historyStep + 1);
    setHistory([
      ...updatedHistory,
      {
        rectangles: rects,
        circles: circs,
        stars: sts,
        texts: txts,
        penLines: penLns,
        arrows: arws,
        lines: lns,
        triangles: tris,
        hexagons: hexa,
        images: imgs,
        pies: pis,
      },
    ]);
    setHistoryStep(updatedHistory.length);
  };

  const addLine = () => {
    if (drawStartPosi && drawEndPosi) {
      const newLines = [
        ...lines,
        {
          id: String(lines.length + 1),
          x: drawStartPosi.x,
          y: drawStartPosi.y,
          points: calcLinePoints(drawStartPosi, drawEndPosi),
          stroke: color,
          strokeWidth,
          isDragging: false,
          opacity,
          rotation: 0,
        },
      ];

      addToHistory({ lns: newLines });
      setLines(newLines);
    }
  };
  const addPenLine = () => {
    if (drawStartPosi && drawEndPosi) {
      const newLines = [
        ...penLines,
        {
          id: String(penLines.length + 1),
          points: penPoints,
          stroke: color,
          strokeWidth,
          isDragging: false,
          opacity,
        },
      ];
      addToHistory({ penLns: newLines });
      setPenLines(newLines);
    }
  };
  const addCircle = () => {
    if (drawStartPosi && drawEndPosi) {
      const radius = calcCircleRadius(drawStartPosi, drawEndPosi);
      const newCircles = [
        ...circles,
        {
          id: String(circles.length + 1),
          x: drawStartPosi.x,
          y: drawStartPosi.y,
          radius,
          isDragging: false,
          stroke: color,
          strokeWidth,
          fill,
          opacity,
        },
      ];
      addToHistory({ circs: newCircles });
      setCircles(newCircles);
    }
  };
  const addRect = () => {
    if (drawStartPosi && drawEndPosi) {
      const newRects = [
        ...rectangles,
        {
          id: String(rectangles?.length + 1),
          x: drawStartPosi.x,
          y: drawStartPosi.y,
          width: Math.round(drawEndPosi.x - drawStartPosi.x),
          height: Math.round(drawEndPosi.y - drawStartPosi.y),
          rotation: 0,
          isDragging: false,
          stroke: color,
          strokeWidth,
          fill,
          opacity,
        },
      ];
      addToHistory({ rects: newRects });
      setRectangles(newRects);
    }
  };
  const addStar = () => {
    if (drawStartPosi && drawEndPosi) {
      const { innerRadius, outerRadius } = calcStarRadius(
        drawStartPosi,
        drawEndPosi
      );
      const newStars = [
        ...stars,
        {
          id: String(stars?.length + 1),
          x: drawStartPosi.x,
          y: drawStartPosi.y,
          rotation: 0,
          isDragging: false,
          innerRadius,
          outerRadius,
          fill,
          stroke: color,
          strokeWidth,
          opacity,
          starPoints: 5,
        },
      ];
      addToHistory({ sts: newStars });
      setStars(newStars);
    }
  };
  const addText = ({ text }: IOnAddTextInput) => {
    const newTxts = [
      ...texts,
      {
        id: String(texts?.length + 1),
        x: 100,
        y: 100,
        text,
        rotation: 0,
        isDragging: false,
        size: txtSize,
        txtColor,
        strokeWidth,
        opacity,
        width: txtWidth,
      },
    ];
    addToHistory({ txts: newTxts });
    setTexts(newTxts);
  };
  const addArrow = () => {
    if (drawStartPosi && drawEndPosi) {
      const newArrows = [
        ...arrows,
        {
          id: String(arrows.length + 1),
          x: drawStartPosi.x,
          y: drawStartPosi.y,
          points: calcArrowPoints(drawStartPosi, drawEndPosi),
          rotation: 0,
          isDragging: false,
          stroke: color,
          strokeWidth,
          opacity,
        },
      ];
      addToHistory({ arws: newArrows });
      setArrows(newArrows);
    }
  };
  const addTriangle = () => {
    if (drawStartPosi && drawEndPosi) {
      const radius = calcTriangleRadius(drawStartPosi, drawEndPosi);

      const newTriangles = [
        ...triangles,
        {
          id: String(triangles.length + 1),
          x: drawStartPosi.x,
          y: drawStartPosi.y,
          sides: 3,
          radius,
          isDragging: false,
          stroke: color,
          strokeWidth,
          fill,
          opacity,
          rotation: 0,
        },
      ];

      addToHistory({ tris: newTriangles });
      setTriangles(newTriangles);
    }
  };
  const addHexagon = () => {
    if (drawStartPosi && drawEndPosi) {
      const radius = calcHexagonsRadius(drawStartPosi, drawEndPosi);

      const newHexagons = [
        ...hexagons,
        {
          id: String(hexagons.length + 1),
          x: drawStartPosi.x,
          y: drawStartPosi.y,
          sides: 8,
          radius,
          isDragging: false,
          stroke: color,
          strokeWidth,
          fill,
          opacity,
          rotation: 22,
        },
      ];

      addToHistory({ tris: newHexagons });
      setHexagons(newHexagons);
    }
  };
  const addPie = () => {
    if (drawStartPosi && drawEndPosi) {
      const radius = calcPieRadius(drawStartPosi, drawEndPosi);
      const angle = calcPieAngle(drawStartPosi, drawEndPosi);

      const newPies = [
        ...pies,
        {
          id: String(pies.length + 1),
          x: drawStartPosi.x,
          y: drawStartPosi.y,
          radius,
          angle,
          isDragging: false,
          stroke: color,
          strokeWidth,
          fill,
          opacity,
          rotation: 0,
        },
      ];

      addToHistory({ pis: newPies });
      setPies(newPies);
    }
  };

  const onAddImage = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*, .svg";
    input.onchange = (event) => {
      const fileInput = event.target as HTMLInputElement;
      if (fileInput.files && fileInput.files.length > 0) {
        const reader = new FileReader();
        reader.onload = () => {
          const image = new window.Image();
          image.onload = () => addImage(image);
          image.src = reader.result as string;
        };
        reader.readAsDataURL(fileInput.files[0]);
      }
    };
    input.click();
  };
  const addImage = async (image: HTMLImageElement) => {
    const newImage = [
      ...images,
      {
        id: String(images.length + 1),
        x: 50,
        y: 50,
        width: Math.round(image.width),
        height: Math.round(image.height),
        image,
        opacity,
        rotation: 0,
        isDragging: false,
      },
    ];
    setDrawingType("image");
    addToHistory({ imgs: newImage });
    setImages(newImage);
  };

  const onMenuUndoClick = () => {
    if (historyStep > 0) {
      const prvStep = historyStep - 1;
      const prvState = history[prvStep];
      setRectangles(prvState.rectangles);
      setCircles(prvState.circles);
      setStars(prvState.stars);
      setTexts(prvState.texts);
      setPenLines(prvState.penLines);
      setArrows(prvState.arrows);
      setLines(prvState.lines);
      setTriangles(prvState.triangles);
      setHexagons(prvState.hexagons);
      setImages(prvState.images);
      setPies(prvState.pies);
      setPenPoints([]);
      setHistoryStep(prvStep);
    }
  };
  const onMenuRedoClick = () => {
    if (historyStep < history.length - 1) {
      const nextStep = historyStep + 1;
      const nextState = history[nextStep];
      setRectangles(nextState.rectangles);
      setCircles(nextState.circles);
      setStars(nextState.stars);
      setTexts(nextState.texts);
      setPenLines(nextState.penLines);
      setArrows(nextState.arrows);
      setLines(nextState.lines);
      setTriangles(nextState.triangles);
      setHexagons(nextState.hexagons);
      setImages(nextState.images);
      setPies(nextState.pies);
      setPenPoints([]);
      setHistoryStep(nextStep);
    }
  };

  const copyCanvasToClipboard = async () => {
    const currentRef = stageRef.current;
    const MyClipboardItemClass = (window as any)
      .ClipboardItem as MyClipboardItem;

    if (currentRef) {
      const uri = (currentRef as any).toDataURL({ pixelRatio: 3 });
      if (uri) {
        fetch(uri)
          .then((res) => res.blob())
          .then((blob) => {
            const clipboardItemInput = new MyClipboardItemClass({
              "image/png": blob,
            });
            (navigator.clipboard as any).write([clipboardItemInput]);
          });
      }
    }
  };

  const onEdit = (props: IOnGeoEdits) => {
    const id = editProps.id;
    //remove transformer to avoid shape breaking
    setSelectedShape(undefined);

    const editDetails = () => {
      const getVals = (pv: any) =>
        pv.map((i: any) =>
          i.id === id
            ? {
                ...i,
                ...Object.keys(props).reduce((acc: any, key) => {
                  if (props[key] !== undefined) acc[key] = props[key];
                  return acc;
                }, {}),
                isDragging: false,
              }
            : i
        );

      const updateHistory = (st: keyof IHistory) => {
        return history.map((item) =>
          item[st]
            ? {
                ...item,
                [st]: item[st].map((s: any) =>
                  s.id === id
                    ? {
                        ...s,
                        ...Object.keys(props).reduce((acc: any, key) => {
                          if (props[key] !== undefined) acc[key] = props[key];
                          return acc;
                        }, {}),
                        isDragging: false,
                      }
                    : s
                ),
              }
            : item
        );
      };

      switch (editProps.activeItem) {
        case "circle":
          setCircles((pv) => getVals(pv));
          setHistory(updateHistory("circles"));
          break;
        case "rectangle":
          setRectangles((pv) => getVals(pv));
          setHistory(updateHistory("rectangles"));
          break;
        case "arrow":
          setArrows((pv) => getVals(pv));
          setHistory(updateHistory("arrows"));
          break;
        case "line":
          setLines((pv) => getVals(pv));
          setHistory(updateHistory("lines"));
          break;
        case "star":
          setStars((pv) => getVals(pv));
          setHistory(updateHistory("stars"));
          break;
        case "pen":
          setPenLines((pv) => getVals(pv));
          setHistory(updateHistory("penLines"));
          break;
        case "text":
          setTexts((pv) => getVals(pv));
          setHistory(updateHistory("texts"));
          break;
        case "triangle":
          setTriangles((pv) => getVals(pv));
          setHistory(updateHistory("triangles"));
          break;
        case "hexagon":
          setHexagons((pv) => getVals(pv));
          setHistory(updateHistory("hexagons"));
          break;
        case "pie":
          setPies((pv) => getVals(pv));
          setHistory(updateHistory("pies"));
          break;
        case "image":
          setImages((pv) => getVals(pv));
          setHistory(updateHistory("images"));
          break;
        default:
          break;
      }
      setEditProps((pv) => ({ ...pv, ...props }));
    };

    if (selectedShape) setTimeout(() => editDetails(), 300);
    else editDetails();
  };
  const onDelete = () => {
    const id = editProps.id;
    const updateHistory = (st: keyof IHistory) => {
      let updHis = [...history];

      const indexToRemove = updHis.findIndex((item) =>
        item[st]?.some(
          (s: any, i: number) => s.id === id && item[st].length === i + 1
        )
      );
      if (indexToRemove !== -1) updHis.splice(indexToRemove, 1);

      updHis = updHis.map((item) =>
        item[st]
          ? { ...item, [st]: (item[st] as any).filter((s: any) => s.id !== id) }
          : item
      );

      return updHis;
    };

    switch (editProps.activeItem) {
      case "circle":
        setCircles((pv) => pv.filter((c) => c.id !== id));
        setHistory(updateHistory("circles"));
        break;
      case "rectangle":
        setRectangles((pv) => pv.filter((r) => r.id !== id));
        setHistory(updateHistory("rectangles"));
        break;
      case "arrow":
        setArrows((pv) => pv.filter((a) => a.id !== id));
        setHistory(updateHistory("arrows"));
        break;
      case "line":
        setLines((pv) => pv.filter((l) => l.id !== id));
        setHistory(updateHistory("lines"));
        break;
      case "star":
        setStars((pv) => pv.filter((s) => s.id !== id));
        setHistory(updateHistory("stars"));
        break;
      case "pen":
        setPenLines((pv) => pv.filter((p) => p.id !== id));
        setHistory(updateHistory("penLines"));
        break;
      case "text":
        setTexts((pv) => pv.filter((t) => t.id !== id));
        setHistory(updateHistory("texts"));
        break;
      case "triangle":
        setTriangles((pv) => pv.filter((t) => t.id !== id));
        setHistory(updateHistory("triangles"));
        break;
      case "hexagon":
        setHexagons((pv) => pv.filter((h) => h.id !== id));
        setHistory(updateHistory("hexagons"));
        break;
      case "pie":
        setPies((pv) => pv.filter((h) => h.id !== id));
        setHistory(updateHistory("pies"));
        break;
      case "image":
        setImages((pv) => pv.filter((i) => i.id !== id));
        setHistory(updateHistory("images"));
        break;
      default:
        break;
    }

    if (historyStep > 0) setHistoryStep(historyStep - 1);
    setEditProps((pv) => ({ ...pv, activeItem: "none" }));
  };

  // useEffect to handle keyboard presses and clipboard paste
  useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === "z") onMenuUndoClick();
      if (event.ctrlKey && event.key === "y") onMenuRedoClick();
      if (event.ctrlKey && event.key === "c") await copyCanvasToClipboard();
      // if (event.ctrlKey && event.key === "s") exportCanvas();
      if (event.key === "Delete") onDelete();
    };

    const handlePaste = (event: ClipboardEvent) =>
      readClipboardImage(event).then((image) => addImage(image));

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("paste", handlePaste);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("paste", handlePaste);
    };
  }, [onMenuUndoClick, onMenuRedoClick, onDelete]);

  const onCircleClick = ({ id }: { id: string }) => {
    const shape: DrawingType = "circle";
    setSelectedShape({ id, shape });

    const index = circles.findIndex((i) => i.id === id);
    setEditProps((pv) => ({ ...pv, ...circles[index], activeItem: shape }));
  };
  const onRectClick = ({ id }: { id: string }) => {
    const shape: DrawingType = "rectangle";
    setSelectedShape({ id, shape });

    const index = rectangles.findIndex((i) => i.id === id);
    setEditProps((pv) => ({ ...pv, ...rectangles[index], activeItem: shape }));
  };
  const onArrowClick = ({ id }: { id: string }) => {
    const shape: DrawingType = "arrow";
    setSelectedShape({ id, shape });

    const index = arrows.findIndex((i) => i.id === id);
    setEditProps((pv) => ({ ...pv, ...arrows[index], activeItem: shape }));
  };
  const onLineClick = ({ id }: { id: string }) => {
    const shape: DrawingType = "line";
    setSelectedShape({ id, shape });

    const index = lines.findIndex((i) => i.id === id);
    setEditProps((pv) => ({ ...pv, ...lines[index], activeItem: shape }));
  };
  const onStarClick = ({ id }: { id: string }) => {
    const shape: DrawingType = "star";
    setSelectedShape({ id, shape });

    const index = stars.findIndex((i) => i.id === id);
    setEditProps((pv) => ({ ...pv, ...stars[index], activeItem: shape }));
  };
  const onTextClick = ({ id }: { id: string }) => {
    const shape: DrawingType = "text";
    setSelectedShape({ id, shape });

    const index = texts.findIndex((i) => i.id === id);
    setEditProps((pv) => ({ ...pv, ...texts[index], activeItem: shape }));
  };
  const onPenLineClick = ({ id }: { id: string }) => {
    const shape: DrawingType = "pen";
    setSelectedShape({ id, shape });

    const index = penLines.findIndex((i) => i.id === id);
    setEditProps((pv) => ({ ...pv, ...penLines[index], activeItem: shape }));
  };
  const onTriangleClick = ({ id }: { id: string }) => {
    const shape: DrawingType = "triangle";
    setSelectedShape({ id, shape });

    const index = triangles.findIndex((i) => i.id === id);
    setEditProps((pv) => ({ ...pv, ...triangles[index], activeItem: shape }));
  };
  const onHexagonsClick = ({ id }: { id: string }) => {
    const shape: DrawingType = "hexagon";
    setSelectedShape({ id, shape });

    const index = hexagons.findIndex((i) => i.id === id);
    setEditProps((pv) => ({ ...pv, ...hexagons[index], activeItem: shape }));
  };
  const onPiesClick = ({ id }: { id: string }) => {
    const shape: DrawingType = "pie";
    setSelectedShape({ id, shape });

    const index = pies.findIndex((i) => i.id === id);
    setEditProps((pv) => ({ ...pv, ...pies[index], activeItem: shape }));
  };
  const onImageClick = ({ id }: { id: string }) => {
    const shape: DrawingType = "image";
    setSelectedShape({ id, shape });

    const index = images.findIndex((i) => i.id === id);
    setEditProps((pv) => ({ ...pv, ...images[index], activeItem: shape }));
  };

  return (
    <div>
      <div className="flexedRowDiv">
        <div className="geoEditCanvasCont">
          <Stage
            ref={stageRef}
            width={window.innerWidth * 0.91}
            height={window.innerHeight - 55}
            onMouseDown={handleStageMouseDown}
            onMouseUp={handleStageMouseUp}
            onMouseMove={handleStageMouseMove}
          >
            <Layer>
              {bgImageObj && (
                <Image
                  name={bgImageName}
                  image={bgImageObj}
                  width={window.innerWidth * 0.91}
                  height={window.innerHeight - 55}
                />
              )}
              <Images
                cdp={cdp}
                images={images}
                setImages={setImages}
                onClick={onImageClick}
              />
              <Rectangles
                rectangles={rectangles}
                setRectangles={setRectangles}
                cdp={cdp}
                onClick={onRectClick}
              />
              <Circles
                circles={circles}
                setCircles={setCircles}
                cdp={cdp}
                onCircleClick={onCircleClick}
              />
              <Stars
                stars={stars}
                setStars={setStars}
                cdp={cdp}
                onClick={onStarClick}
              />
              <Triangles
                cdp={cdp}
                triangles={triangles}
                setTriangles={setTriangles}
                onClick={onTriangleClick}
              />
              <Hexagons
                cdp={cdp}
                hexagons={hexagons}
                setHexagons={setHexagons}
                onClick={onHexagonsClick}
              />
              {/* <Pies
                cdp={cdp}
                pies={pies}
                setPies={setPies}
                onClick={onPiesClick}
              /> */}
              <Arrows
                arrows={arrows}
                setArrows={setArrows}
                cdp={cdp}
                onClick={onArrowClick}
              />
              <Lines
                cdp={cdp}
                lines={lines}
                setLines={setLines}
                onClick={onLineClick}
              />
              <PenLines
                cdp={cdp}
                lines={penLines}
                setLines={setPenLines}
                penPoints={penPoints}
                onClick={onPenLineClick}
              />
              <Texts
                texts={texts}
                cdp={cdp}
                setTexts={setTexts}
                onClick={onTextClick}
              />
            </Layer>
          </Stage>
        </div>
        <GeoEditMenu
          onMenuUndoClick={onMenuUndoClick}
          onMenuRedoClick={onMenuRedoClick}
          drawingType={drawingType}
          setDrawingType={setDrawingType}
          onAddText={addText}
          stroke={color}
          setStroke={setColor}
          onAddImage={onAddImage}
          strokeWidth={strokeWidth}
          setStrokeWidth={setStrokeWidth}
          opacity={opacity}
          setOpacity={setOpacity}
          fill={fill}
          setFill={setFill}
          txtSize={txtSize}
          setTxtSize={setTxtSize}
          txtWidth={txtWidth}
          setTxtWidth={setTxtWidth}
          txtColor={txtColor}
          setTxtColor={setTxtColor}
          stageRef={stageRef}
          latLng={latLng}
          nodeId={nodeId}
          techData={techData}
          owningDrawing={owningDrawing}
        />
      </div>
      <GeoEdits {...editProps} onEdit={onEdit} onDelete={onDelete} />
    </div>
  );
}

export const getOpacity = (val: number) => val / 10;
