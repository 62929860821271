import AppLabels from "../../constants/App_Labels";
import ConfigConst from "../../constants/ConfigConst";
import {
  convertPascalCase,
  getEmailFormatDate,
  isNull,
} from "../reusable/Util";

interface Props {
  body: any;
  hsiFailCondition: boolean;
  wifiFailCondition: boolean;
  techName: string;
}

const PrepareEmailFormatCert = (props: Props) => {
  const labels = AppLabels();
  let emailGreeting = "";
  let emailHeader = "";

  if (props?.hsiFailCondition && props?.wifiFailCondition) {
    emailGreeting +=
      "Below is information regarding the failed Certification submitted by " +
      props.techName +
      " on ";
    emailHeader += "Failed Whole Home and WiFi Certification";
  } else {
    if (props?.hsiFailCondition) {
      emailGreeting +=
        "Below is information regarding the failed codewords submitted by " +
        props.techName +
        " on ";
      emailHeader += "Failed Whole Home Certification";
    } else {
      emailGreeting +=
        "The following auto notification has been submitted due to a failed TechInsight Certification Record that has been submitted by " +
        props.techName +
        " on ";
      emailHeader += "Failed Wifi Certification";
    }
  }
  return (
    <div
      style={{
        display: "inline-block",
        fontSize: "14pt",
        fontWeight: 500,
        fontFamily: "Ted Next",
      }}
    >
      <div style={{ justifyContent: "center", display: "flex" }}>
        <span style={{ fontSize: "28.5pt", textAlign: "center" }}>
          {emailHeader}
        </span>
      </div>
      <p>{labels.Hello},</p>
      <p>
        {emailGreeting}
        {getEmailFormatDate()}
      </p>
      {props.body}
      <p>{"\n" + labels.Thank_you}</p>
    </div>
  );
};

export default PrepareEmailFormatCert;
