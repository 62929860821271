import { RecoEngine } from "../../../models/account/troubleshoot/RecoEngine";

export const RECO_ENGINE_BEGIN = "RECO_ENGINE_BEGIN";
export const RECO_ENGINE_SUCCESS = "RECO_ENGINE_SUCCESS";
export const RECO_ENGINE_FAILURE = "RECO_ENGINE_FAILURE";

interface recoEngineBegin {
  type: typeof RECO_ENGINE_BEGIN;
}

interface recoEngineSuccess {
  type: typeof RECO_ENGINE_SUCCESS;
  payload: RecoEngine;
}

interface recoEngineFailure {
  type: typeof RECO_ENGINE_FAILURE;
  payload: Error;
}

export type RecoEngineActionTypes =
  | recoEngineBegin
  | recoEngineSuccess
  | recoEngineFailure;
