export const UPDATE_FEEDBACK_BEGIN = "UPDATE_FEEDBACK_BEGIN";
export const UPDATE_FEEDBACK_SUCCESS = "UPDATE_FEEDBACK_SUCCESS";
export const UPDATE_FEEDBACK_FAILURE = "UPDATE_FEEDBACK_FAILURE";

interface UpdateFeedbackBegin {
    type: typeof UPDATE_FEEDBACK_BEGIN;
  }
  interface UpdateFeedbackSuccess {
    type: typeof UPDATE_FEEDBACK_SUCCESS;
    payload: any;
  }
  interface UpdateFeedbackFailure {
    type: typeof UPDATE_FEEDBACK_FAILURE;
    payload: Error;
  }

  export type UpdateFeedbackActionTypes =
  | UpdateFeedbackBegin
  | UpdateFeedbackSuccess
  | UpdateFeedbackFailure