import ASTBDiagnostics from "../astb/ASTBDiagnostics";
import CmDiagnostics from "../cable-modem/CmDiagnostics";
import GWDiagnostics from "../gateway/GWDiagnostics";
import IHPDiagnostics from "../homephone/IHPDiagnostics";
import IPTVDiagnostics from "../iptv/IPTVDiagnostics";
import ODUDiagnostics from "../odu/ODUDiagnostics";
import ONTDiagnostics from "../ont/ONTDiagnostics";
import STBDiagnostics from "../stb/STBDiagnostics";
import GWDiagnosticsPON from "../gateway/GWDiagnosticsPON";

const DeviceDiagnostics = (props: any) => {
  const {
    selectedDevice,
    selectedSA,
    accType,
    deviceDiagActions,
    selDiagAction,
    handleActionClick,
    gwMAC
  } = props;
  let stbDevices = ["STB", "DESTB", "GSTB", "HSTB", "IPSTB"];
  return (
    <div className="detailDiagCont">
      {selectedDevice.deviceCategory === "CM" ? (
        accType === "DOCSIS" ? (
          <CmDiagnostics
            selectedDevice={selectedDevice}
            selectedSA={selectedSA}
            deviceDiagActions={deviceDiagActions}
            selDiagAction={selDiagAction}
            handleActionClick={handleActionClick}
          />
        ): accType === "GPON" ? (
                 <GWDiagnosticsPON
                   selectedDevice={selectedDevice}
                   selectedSA={selectedSA}
                   deviceDiagActions={deviceDiagActions}
                   selDiagAction={selDiagAction}
                   handleActionClick={handleActionClick}
                 />
               ) : accType === "FWA" ? ( 
          <GWDiagnostics
            selectedDevice={selectedDevice}
            selectedSA={selectedSA}
            deviceDiagActions={deviceDiagActions}
            selDiagAction={selDiagAction}
            handleActionClick={handleActionClick}
          />
        ) : (
          <></>
        )
      ) : (
        <></>
      )}

      {selectedDevice.deviceCategory === "IPTV" ? (
        <IPTVDiagnostics
          selectedDevice={selectedDevice}
          selDiagAction={selDiagAction}
          handleActionClick={handleActionClick}
        />
      ) : (
        <></>
      )}
      {selectedDevice.deviceCategory === "IEMTA" ||
      selectedDevice.deviceCategory === "EMTA" ? (
        <IHPDiagnostics
          selectedDevice={selectedDevice}
          deviceDiagActions={deviceDiagActions}
          selDiagAction={selDiagAction}
          handleActionClick={handleActionClick}
        />
      ) : (
        <></>
      )}
      {selectedDevice.deviceCategory === "ONT" ? (
        <ONTDiagnostics
          selectedDevice={selectedDevice}
          selectedSA={selectedSA}
          deviceDiagActions={deviceDiagActions}
          selDiagAction={selDiagAction}
          gwMAC={gwMAC}
          handleActionClick={handleActionClick}
        />
      ) : (
        <></>
      )}
      {selectedDevice.deviceCategory === "ODU" ? (
        <ODUDiagnostics
          selectedDevice={selectedDevice}
          deviceDiagActions={deviceDiagActions}
          selDiagAction={selDiagAction}
          handleActionClick={handleActionClick}
        />
      ) : (
        <></>
      )}
      {selectedDevice.deviceCategory === "ASTB" ? (
        <ASTBDiagnostics
          selectedDevice={selectedDevice}
          deviceDiagActions={deviceDiagActions}
          selDiagAction={selDiagAction}
          handleActionClick={handleActionClick}
        />
      ) : (
        <></>
      )}
      {stbDevices?.indexOf(selectedDevice.deviceCategory) > -1 ? (
        <STBDiagnostics
          selectedDevice={selectedDevice}
          selectedSA={selectedSA}
          deviceDiagActions={deviceDiagActions}
          selDiagAction={selDiagAction}
          handleActionClick={handleActionClick}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default DeviceDiagnostics;
