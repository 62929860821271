import { FeedbackProfile } from "../../models/feedback/FeedbackProfile";

export const TECHNICIAN_FEEDBACK_BEGIN = "TECHNICIAN_FEEDBACK_BEGIN";
export const TECHNICIAN_FEEDBACK_SUCCESS = "TECHNICIAN_FEEDBACK_SUCCESS";
export const TECHNICIAN_FEEDBACK_FAILURE = "TECHNICIAN_FEEDBACK_FAILURE";

interface technicianFeedbackBegin {
  type: typeof TECHNICIAN_FEEDBACK_BEGIN;
}

interface technicianFeedbackSuccess {
  type: typeof TECHNICIAN_FEEDBACK_SUCCESS;
  payload: FeedbackProfile;
}

interface technicianFeedbackFailure {
  type: typeof TECHNICIAN_FEEDBACK_FAILURE;
  payload: Error;
}

export type TechnicianFeedbackActionTypes =
  | technicianFeedbackBegin
  | technicianFeedbackSuccess
  | technicianFeedbackFailure;
