import { Divider } from "@material-ui/core";
import * as React from "react";
import AppLabels from "../../constants/App_Labels";
import Loader from "../../resources/images/loader.webp";

export const MultiDeviceNodeBanner: React.FC<any> = (props) => {
  const { data, expand, emtaType = false, emtaSearchVal } = props;
  const labels = AppLabels();

  interface IKeyValue {
    text: string;
    value: string | number | JSX.Element;
  }

  const KeyValuesTitle = (props: IKeyValue) => {
    const { text, value } = props;
    return (
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          alignItems: "center",
        }}
      >
        <span style={{ fontWeight: 600 }}>{text}:</span>
        <span style={{ marginLeft: "5px" }}>{value}</span>
      </span>
    );
  };
  const flexRowStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "20px",
    alignItems: "center",
    justifyContent: "space-between",
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        gap: "20px",
        fontSize: "14px",
      }}
    >
      {emtaType ? (
        <section
          style={{
            display: "flex",
            flexDirection: expand ? "column" : "row",
            gap: "6px",
          }}
        >
          <div>
            <span style={{ fontWeight: 600 }}>
              {labels.olt_name.toUpperCase()}:
            </span>{" "}
            &nbsp;
            <span>{emtaSearchVal ?? labels.NA}</span>
          </div>
        </section>
      ) : (
        <>
          <section
            style={{
              display: "flex",
              flexDirection: expand ? "column" : "row",
              gap: "6px",
            }}
          >
            <div>
              {data?.isSmt
                ? data?.smtId && (
                    <>
                      <span style={{ fontWeight: 600 }}>
                        {labels.SMT_ID.toUpperCase()}:
                      </span>{" "}
                      &nbsp;
                      <span>{data?.smtId ?? labels.NA}</span>
                    </>
                  )
                : data?.nodeId && (
                    <>
                      <span style={{ fontWeight: 600 }}>
                        {labels.NODE_ID.toUpperCase()}:
                      </span>{" "}
                      &nbsp;
                      <span>{data?.nodeId ?? labels.NA}</span>
                    </>
                  )}
              &nbsp;
            </div>
            <Divider orientation="vertical" hidden={expand} />
            <div style={flexRowStyle}>
              <KeyValuesTitle text="PHUB" value={data?.phub} />

              {data?.isSmt && (
                <KeyValuesTitle
                  text={labels.NODE_ID.toUpperCase()}
                  value={data?.nodeId ?? labels.NA}
                />
              )}
            </div>
          </section>
          <section style={{ paddingLeft: "100px" }}>
            <table
              style={{
                borderCollapse: "collapse",
                width: "100%",
                // textAlign: "left",
                paddingLeft: "50px",
              }}
            >
              <thead>
                <tr>
                  <th style={{ fontWeight: 600, width: "56px" }}>Devices</th>
                  <th style={{ fontWeight: 600, width: "66px" }}>Assigned</th>
                  <th style={{ fontWeight: 600, width: "78px" }}>Responsive</th>
                  <th style={{ fontWeight: 600, width: "93px" }}>
                    Unresponsive
                  </th>
                  <th style={{ fontWeight: 600, width: "87px" }}>Active(%)</th>
                </tr>
              </thead>
              <tbody>
                {data?.modemListInfo ? (
                  Object.keys(data?.modemListInfo)
                    .sort()
                    .map((key: string) => {
                      const devices = data.modemListInfo[key];
                      return (
                        <tr>
                          <td
                            style={{
                              fontWeight: 600,
                              width: "56px",
                              textAlign: "left",
                            }}
                          >
                            {key}
                          </td>
                          <td style={{ width: "66px", textAlign: "center" }}>
                            {devices?.assigned ?? (
                              <img src={Loader} alt="loading" height="10px" />
                            )}
                          </td>
                          <td style={{ width: "78px", textAlign: "center" }}>
                            {devices?.responsive ?? (
                              <img src={Loader} alt="loading" height="10px" />
                            )}
                          </td>
                          <td style={{ width: "70px", textAlign: "center" }}>
                            {devices?.unresponsive ?? (
                              <img src={Loader} alt="loading" height="10px" />
                            )}
                          </td>
                          <td style={{ width: "70px", paddingLeft: "10px" }}>
                            {devices?.active ?? (
                              <img src={Loader} alt="loading" height="10px" />
                            )}
                          </td>
                        </tr>
                      );
                    })
                ) : (
                  <tr>
                    <td style={{ width: "60px" }}>
                      <img src={Loader} alt="loading" height="20px" />
                    </td>
                    <td style={{ width: "68px" }}>
                      <img src={Loader} alt="loading" height="20px" />
                    </td>
                    <td style={{ width: "78px" }}>
                      <img src={Loader} alt="loading" height="20px" />
                    </td>
                    <td style={{ width: "70px" }}>
                      <img src={Loader} alt="loading" height="20px" />
                    </td>
                    <td style={{ width: "75px" }}>
                      <img src={Loader} alt="loading" height="20px" />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </section>
          {expand && (
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                alignItems: "flex-start",
                // paddingLeft: "150px",
              }}
            >
              <KeyValuesTitle
                text="Start"
                value={
                  data?.startTime ?? (
                    <img src={Loader} alt="loading" height="20px" />
                  )
                }
              />
              <KeyValuesTitle
                text="End"
                value={
                  data?.endTime ?? (
                    <img src={Loader} alt="loading" height="20px" />
                  )
                }
              />
            </span>
          )}
        </>
      )}
    </div>
  );
};
