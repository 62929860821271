import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { format } from "date-fns";

import {
  GetClickOrdersProps,
  GetGoogleMaps,
  GetTopoConfigsProps,
  GetTopoGeneralProps,
} from "../../../../util/reduxFunctions/getTopologyState";
import appConst from "../../../../constants/appConst";
import { isStringNumber } from "../../../../util/utilFuncs";
import TopologyLabels from "../../../../constants/topologyLabels";
import { CLICK_CONFIG as CC } from "../../../../config/mapItemsConfig";
import InlineErrorMessage from "../../../../../components/reusable/InlineErrorMessage";
import ChipGroup from "../../../../../components/reusable/ChipGroup";
import { getClickOrders } from "../../../../store/slices/mapItems/clickOrders";
import Status from "./Status";
import Inputs from "./Inputs";
import Orders from "./Orders";
import "../../../../styles/drawer/Click.scss";
import ExceptionHandlerWrapper from "../../../reusable/ExceptionHandlerWrapper";
import { getTopoConfig } from "../../../../store/slices/topologyConfig";

export default function ClickOrdersFilters() {
  const labels = TopologyLabels();
  const dispatch = useDispatch();
  const { currentMapCenter } = GetGoogleMaps();
  const { activeNodeId } = GetTopoGeneralProps();
  const { clickStatusFlt, clickOrderFlt } = GetClickOrdersProps();
  const { status, isLoading, error, data } = GetTopoConfigsProps();

  const [errorMsg, setErrorMsg] = useState("");
  const [radiusValue, setRadiusValue] = useState(String(CC.dfltRadiusVal));
  const [searchTypeIndex, setSearchTypeIndex] = useState(1);
  const [DRS, setDRS] = useState<any>();
  const [nodeId, setNodeId] = useState(activeNodeId);

  const searchTypeData = [
    { title: labels.node.toUpperCase(), description: labels.SearchbyNodeId },
    { title: labels.Radius.toUpperCase(), description: labels.SearchbyRadius },
  ];

  const onSearchPress = () => {
    if (
      searchTypeIndex === 1 &&
      (!isStringNumber(radiusValue) ||
        Number(radiusValue) < CC.minRadius ||
        Number(radiusValue) > CC.maxRadius)
    ) {
      setErrorMsg(
        `${labels.RadiusMustBeBetween} ${CC.minRadius} ${labels.to} ${CC.maxRadius} ${labels.meters}`
      );
    } else if (
      searchTypeIndex === 0 &&
      !activeNodeId &&
      (!isStringNumber(nodeId) || nodeId?.length !== 6)
    ) {
      setErrorMsg(`${labels.nodeIDError} `);
    } else {
      //use selected chip filters
      let status = [] as Array<any>;
      clickStatusFlt?.map((e: any) => e.checked && status.push(e.label));
      let orderType = [] as Array<any>;
      clickOrderFlt?.map((oc: any) =>
        oc?.orderTypes?.map((e: any) => e.checked && orderType.push(e.label))
      );
      let filterParams = {
        startDate: getTimeInUnix().startDate,
        endDate: getTimeInUnix().endDate,
        status,
        orderType,
      };

      if (filterParams?.status?.length < 1) {
        setErrorMsg(labels.clickStatusFilterMsg);
      } else if (filterParams?.orderType?.length < 1) {
        setErrorMsg(labels.clickOrderFilterMsg);
      } else {
        setErrorMsg("");

        const nodeParams = {
          filterParams,
          searchParams: {
            method: appConst.node,
            nodeId: activeNodeId ? activeNodeId : nodeId,
          },
        };
        const areaParams = {
          filterParams,
          searchParams: {
            method: appConst.area,
            centerLat: currentMapCenter?.latitude,
            centerLng: currentMapCenter?.longitude,
            radius: Number(radiusValue),
          },
        };
        if (searchTypeIndex === 0) dispatch(getClickOrders(nodeParams));
        if (searchTypeIndex === 1) dispatch(getClickOrders(areaParams));
      }
    }
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") onSearchPress();
  };

  const getDRFormat = (dateStart = "startDate") => {
    const date =
      dateStart === "startDate" ? DRS?.[0]?.startDate : DRS?.[0]?.endDate;

    if (DRS) {
      return format(
        new Date(
          Number(date?.getFullYear()),
          Number(date?.getMonth()),
          Number(date?.getDate())
        ),
        "yyyy-MM-dd"
      );
    } else return null;
  };

  const getTimeInUnix = () => {
    //get time in unix/epoch milliseconds
    const startDate = new Date(getDRFormat("startDate") + " 00:00").getTime();
    const endDate = new Date(getDRFormat("endDate") + " 23:59").getTime();

    return { startDate, endDate };
  };

  return (
    <>
      <div className="nikaTypeSelectionCont">
        <ChipGroup
          chips={searchTypeData}
          activeChipIndex={searchTypeIndex}
          onIndexChange={setSearchTypeIndex}
        />
        <div className="diagOptionsCont">
          <Button
            onClick={onSearchPress}
            className="stepperSubmitBtn"
            style={{ height: 30, borderRadius: 25 }}
          >
            {labels.GetOrders.toUpperCase()}
          </Button>
        </div>
      </div>
      <InlineErrorMessage errorMessage={errorMsg} />
      {searchTypeIndex === 0 && !activeNodeId && (
        <div className="clickSearchFilter">
          <input
            type="text"
            title={`${labels.node_ID}`}
            value={nodeId}
            placeholder={`${labels.type} ${labels.node_ID}...`}
            onChange={(val: any) => {
              setNodeId(val.target.value);
            }}
          />
        </div>
      )}
      <Inputs
        setDRS={setDRS}
        radiusValue={radiusValue}
        setRadiusValue={setRadiusValue}
        handleKeyPress={handleKeyPress}
        getDRFormat={getDRFormat}
        searchTypeIndex={searchTypeIndex}
      />
      <ExceptionHandlerWrapper
        headerMsg={labels.filterOptionFailMsg}
        isLoading={isLoading}
        status={status}
        error={error}
        data={data?.clickConfig?.status}
        onRefresh={() => dispatch(getTopoConfig())}
      >
        <Status />
        <Orders />
      </ExceptionHandlerWrapper>
    </>
  );
}
