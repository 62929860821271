import SearchIcon from "@material-ui/icons/Search";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import NeighborhoodTable from "./NodeTable";
import NodeClient from "../../network/httpclient/node/NodeClient";
import DiagContainer from "../reusable/DiagContainer";

import "../../design-tokens/account-page/NeighbourhoodTab.scss";

import {
  getAccType,
  getFormatedAddress,
  getHomeAddress,
  nodeId,
} from "../reusable/Util";
import {
  ResetNodes,
  SaveNodes,
} from "../../store/actions/account/node/NodeSummaryAction";

const NodeTab = (props: any) => {
  const { accData, nodesData, nodesLoading, nodesError, nodesDevicesInfo } =
    useSelector(
      (state: any) => ({
        accData: state.AccountState.ActiveAccountDetails.data,
        nodesData: state.AccountState.NodesList.data,
        nodesLoading: state.AccountState.NodesList.isLoading,
        nodesError: state.AccountState.NodesList.error,
        nodesDevicesInfo: state.AccountState.NodesDevices.nodeSummaryList,
      }),
      shallowEqual
    );
  const { serviceAreaInfo } = useSelector(
    (state: any) => ({
      serviceAreaInfo: state.AccountState.ServiceAreaInfo.data,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [errorMSG, setErrorMSG] = useState("");
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [nodeView, setNodeView] = useState("tableView");
  const handleSearch = (e: any) => {
    let sv = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
    setSearchValue(sv);
    setErrorDisplay(false);
    setErrorMSG("");
  };
  const refreshNodeNetwork = () => {
    if (
      serviceAreaInfo?.serviceAddressInfo?.generalAddressInfo?.gponNode !==
      undefined
    ) {
      dispatch(
        NodeClient.getNodesInfo(
          serviceAreaInfo?.serviceAddressInfo?.generalAddressInfo?.gponNode,
          getAccType(accData?.accountNumber)
        )
      );
      setSearchValue(serviceAreaInfo);
    }
  };
  const searchNodes = () => {
    let valueFlag = nodeId(searchValue);
    if (searchValue !== undefined && searchValue !== "" && valueFlag === "") {
      setNodeView("tableView");
      dispatch(ResetNodes());
      dispatch(
        NodeClient.getNodesInfo(searchValue, getAccType(accData?.accountNumber))
      );
    } else {
      setErrorDisplay(true);
      setErrorMSG(valueFlag);
    }
  };
  useEffect(() => {
    if (
      serviceAreaInfo?.serviceAddressInfo?.generalAddressInfo?.gponNode !==
        undefined &&
      nodesData === undefined
    ) {
      dispatch(
        NodeClient.getNodesInfo(
          serviceAreaInfo?.serviceAddressInfo?.generalAddressInfo?.gponNode,
          getAccType(accData?.accountNumber)
        )
      );
    }
    setSearchValue(
      serviceAreaInfo?.serviceAddressInfo?.generalAddressInfo?.gponNode
    );
  }, []);
  useEffect(() => {
    if (nodesData !== undefined && nodesData?.length !== 0) {
      let nodesList = nodesData?.resultList;
      if (nodesList !== undefined && nodesList?.length !== 0) {
        let nodesDevices = nodesList?.map((d: any) => {
          let homeAddress = getHomeAddress(d.servicedAddress);
          let nDevice = {
            snNumber: d.ontSerialNumber,
            ontSerialNumber: d.ontSerialNumber,
            serialNumber: d.ontSerialNumber,
            homeAddress: homeAddress,
            ontModel: d.ontModel,
            data: {
              summary: {
                isLoading: false,
                data: undefined,
                status: "",
              },
            },
          };
          return nDevice;
        });
        if (
          nodesDevices !== undefined &&
          nodesDevices?.length !== 0 &&
          nodesDevicesInfo?.length === 0
        ) {
          dispatch(SaveNodes(nodesDevices));
          nodesList?.map((d: any) => {
            if (d !== undefined && d?.hasOwnProperty("servicedAddress")) {
              let gAddress = getFormatedAddress(d?.servicedAddress);
              dispatch(NodeClient.getNodeGeoCode(d.ontSerialNumber, gAddress));
            }
          });
        }
      }
    }
  }, [nodesData]);

  return (
    <>
      <div className="areaViewCont">
        <section className="filterCont">
          <InputGroup className="searchNeigh">
            <FormControl
              placeholder="Type NODE ID"
              className="searchInput-Neigh"
              value={searchValue}
              onChange={handleSearch}
            />
            <Button id="searchNeighBtn" onClick={() => searchNodes()}>
              <SearchIcon />
            </Button>
          </InputGroup>
          {errorDisplay ? <span className="failState">{errorMSG}</span> : <></>}
          <div className="neighViewCont"></div>
        </section>
      </div>
      <div>
        {nodeView === "tableView" ? (
          <DiagContainer
            data={nodesData}
            isLoading={nodesLoading}
            error={nodesError}
            sectionName="Node"
            handleRefresh={refreshNodeNetwork}
          >
            <NeighborhoodTable />
          </DiagContainer>
        ) : nodeView === "mapView" ? null : (
          <></>
        )}
      </div>
    </>
  );
};

export default NodeTab;
