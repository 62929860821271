import { createSlice } from "@reduxjs/toolkit";
import {
  NETWORK_SERVICE_URL,
  UTILITY_TOOL_URI,
} from "../../../middleware/apiEndpoints";
import { apiCallBegan } from "../../../middleware/apiActions";
import storeConst from "../../../storeConst";

const slice = createSlice({
  name: "utilityToolAppSaveConfig",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    // action => action handler
    getUtilToolAppSaveConfigBegin: (utilAppSaveConfig) => {
      utilAppSaveConfig.isLoading = true;
      utilAppSaveConfig.status = "";
    },
    getUtilToolAppSaveConfigSuccess: (utilAppSaveConfig, action) => {
      utilAppSaveConfig.isLoading = false;
      utilAppSaveConfig.data = action.payload;
      utilAppSaveConfig.status = storeConst.OK;
    },
    getUtilToolAppSaveConfigFailure: (utilAppSaveConfig, action) => {
      utilAppSaveConfig.isLoading = false;
      utilAppSaveConfig.error = action.payload;
      utilAppSaveConfig.data = undefined;
      utilAppSaveConfig.status = storeConst.ERROR;
    },
  },
});

//should not be exposed to outside
const {
  getUtilToolAppSaveConfigBegin,
  getUtilToolAppSaveConfigSuccess,
  getUtilToolAppSaveConfigFailure,
} = slice.actions;
export default slice.reducer;

//action creators
export const saveUtilAppConfig = (data: any) =>
  apiCallBegan({
    baseURL: `${NETWORK_SERVICE_URL}`,
    url: `${UTILITY_TOOL_URI}`,
    data: data,
    method: "post",
    onStart: getUtilToolAppSaveConfigBegin.type,
    onSuccess: getUtilToolAppSaveConfigSuccess.type,
    onError: getUtilToolAppSaveConfigFailure.type,
  });
