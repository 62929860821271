import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import IHPClient from "../../network/httpclient/ihp/IHPClient";
import DiagContainer from "../reusable/DiagContainer";
import ReloadData from "../reusable/ReloadData";
import AppLabels from "../../constants/App_Labels";
import DataDisplayTable from "../reusable/DataDisplayTable";
import { GLOBAL_CONST } from "../../constants/Global";
import CustomAccordion from "../reusable/CustomAccordion";
import { isNull } from "../reusable/Util";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import ExceptionBox from "../reusable/ExceptionBox";

const IHPFeaturesData = (props: any) => {
  const labels = AppLabels();
  const { homeNum } = props;
  const dispatch = useDispatch();
  const { featuresLoading, featuresInfo, featuresError } = useSelector(
    (state: any) => ({
      featuresInfo: state.AccountState.IHPFeaturesInfo.data,
      featuresLoading: state.AccountState.IHPFeaturesInfo.isLoading,
      featuresError: state.AccountState.IHPFeaturesInfo.error,
    }),
    shallowEqual
  );
  const refreshData = () => {
    dispatch(IHPClient.getIHPFeatures(GLOBAL_CONST.telPrefix + homeNum));
  };
  const [linesAcc, setLinesAcc] = useState(true);

  const handleAccToggle = (accName: string) => {
    if (accName === "features") setLinesAcc(!linesAcc);
  };
  return (
    <>
      <div className="refreshCont">
        <ReloadData refreshData={refreshData} />
      </div>
      <div className="cmSummaryCont">
        <DiagContainer
          isLoading={featuresLoading}
          data={featuresInfo}
          error={featuresError}
          sectionName="Features Data"
        >
          <CustomAccordion
            accTitle={labels.SUMMARY.toUpperCase()}
            accBodyElement={
              <>
                {featuresInfo ? (
                  <table className="dataTableDetails">
                    <thead>
                      <th>{labels.NAME.toUpperCase()}</th>
                      <th>{labels.VALUE.toUpperCase()}</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ width: "50%" }}> LINE STATUS</td>
                        <td>{isNull(featuresInfo.lineStatus)}</td>
                      </tr>
                      <tr>
                        <td>TYPE</td>
                        <td>{isNull(featuresInfo.type)}</td>
                      </tr>
                      <tr>
                        <td>DESIGNATED SWITCH</td>
                        <td>{isNull(featuresInfo.designatedSwitch)}</td>
                      </tr>
                      <tr>
                        <td>OUTBOUND CALLER ID</td>
                        <td>{isNull(featuresInfo.outboundCallerId)}</td>
                      </tr>
                      <tr>
                        <td>PIC CODE</td>
                        <td>{isNull(featuresInfo.picCode)}</td>
                      </tr>
                      <tr>
                        <td>LTG NUMBER</td>
                        <td>{isNull(featuresInfo.ltgNumber)}</td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <>
                    <table className="dataTableDetails">
                      <tbody>
                        <div className="diagErrorCont">
                          <ExceptionBox
                            headerText="No Data to display"
                            bodyText="Please try refreshing or contact the Tech Insight admin if data is missing."
                            primaryBtn={true}
                            secBtn={false}
                            handleRefresh={() => refreshData()}
                          />
                        </div>
                      </tbody>
                    </table>
                  </>
                )}

                {featuresInfo &&
                featuresInfo?.hasOwnProperty("features") &&
                featuresInfo?.features?.length !== 0 ? (
                  <div className="ofdmChDetails">
                    <div
                      className="dsChDataAcc"
                      onClick={() => handleAccToggle("features")}
                    >
                      <p style={{ marginBottom: "0px" }}>FEATURES</p>
                      {linesAcc ? (
                        <IoIosArrowDropup color="rgba(0, 0, 0, 0.5)" />
                      ) : (
                        <IoIosArrowDropdown color="rgba(0, 0, 0, 0.5)" />
                      )}
                    </div>
                    {featuresInfo.features && linesAcc ? (
                      <DataDisplayTable
                        dataObject={featuresInfo.features}
                        tableName="featuresSummary"
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </>
            }
          />
        </DiagContainer>
      </div>
    </>
  );
};

export default IHPFeaturesData;
