import { GponNodesInfo } from "../../models/gpon/GponNodesInfo";
import {
  GPON_NODE_LIST_BEGIN,
  GPON_NODE_LIST_SUCCESS,
  GPON_NODE_LIST_FAILURE,
  RESET_GPON_NODES_INFO,
  GponNodesInfoActionTypes,
} from "../../types/gpon/GponNodeInfo";
export function GponNodesInfoBegin(): GponNodesInfoActionTypes {
  return {
    type: GPON_NODE_LIST_BEGIN,
  };
}

export function GponNodesInfoSuccess(
  NodesInfo: GponNodesInfo
): GponNodesInfoActionTypes {
  return {
    type: GPON_NODE_LIST_SUCCESS,
    payload: NodesInfo,
  };
}

export function GponNodesInfoFailure(error: Error): GponNodesInfoActionTypes {
  return {
    type: GPON_NODE_LIST_FAILURE,
    payload: error,
  };
}

export function GponNodesInfoReset(): GponNodesInfoActionTypes {
  return {
    type: RESET_GPON_NODES_INFO,
  };
}
