import { useEffect } from "react";
import HistoryComponent from "./HistoryComponent";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import FeedbackClient from "../../network/httpclient/feedback/FeedbackClient";
import DiagContainer from "../reusable/DiagContainer";
import { GetTechInfo } from "../../get-api/GetResponse";

const FeedbackHistory = (props: any) => {
  const dispatch = useDispatch();
  const techData = GetTechInfo()?.data;

  const {
    newDataStatus,
    data,
    isLoading,
    error,
    myData,
    myIsLoading,
    myError,
  } = useSelector(
    (state: any) => ({
      newDataStatus: state.FeedbackCreationInfo.status,
      data: state.FeedbackInfo.data,
      isLoading: state.FeedbackInfo.isLoading,
      error: state.FeedbackInfo.error,
      myData: state.TechnicianFeedbackInfo.data,
      myStatus: state.TechnicianFeedbackInfo.status,
      myIsLoading: state.TechnicianFeedbackInfo.isLoading,
      myError: state.TechnicianFeedbackInfo.error,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (newDataStatus === "OK") {
      if (props.tab === "YourFeedback")
        dispatch(FeedbackClient.getTechnicianFeedback(techData.lanId));
      else dispatch(FeedbackClient.getFeedbackProfile());
    }
  }, [newDataStatus]);

  useEffect(() => {
    if (props.tab === "YourFeedback")
      dispatch(FeedbackClient.getTechnicianFeedback(techData.lanId));
    else dispatch(FeedbackClient.getFeedbackProfile());
  }, []);

  const CreateFeedbackElements = () => {    
    if (props.tab === "YourFeedback") {
      return myData?.issues?.map((item: any) => {
        return (<HistoryComponent
          key={item.key}
          setIdComments={props.setIdComments}
          setIssueToBeEditted={props.setIssueToBeEditted}
          setEdit={props.setEdit}
          showForm={props.showForm}
          setComments={props.setComments}
          issue={item}
          tab={props.tab}
          />)
        });
    } else {
      return data?.issues?.map((item: any) => (
        <HistoryComponent
          key={item.key}
          setIdComments={props.setIdComments}
          setIssueToBeEditted={props.setIssueToBeEditted}
          setEdit={props.setEdit}
          showForm={props.showForm}
          setComments={props.setComments}
          issue={item}
          tab={props.tab}
        />
      ));
    }
  };

  return (
    <div className="feedback-container">
      <DiagContainer
        isLoading={isLoading && myIsLoading}
        data={data && myData}
        error={error && myError}
        sectionName="Feedback"
        handleRefresh={() => {
          dispatch(FeedbackClient.getFeedbackProfile());
          dispatch(FeedbackClient.getTechnicianFeedback(techData.lanId));
        }}
      >
        <CreateFeedbackElements />
      </DiagContainer>
      <div style={{ padding: "50px" }}></div>
    </div>
  );
};

export default FeedbackHistory;
