import { useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { TESTTYPE_CONFIG } from "../../constants/CertificationsConfig";
import { Device } from "../../store/models/account/accountInfo/Device";
import IPTVDeviceInfo from "../iptv/IPTVDeviceInfo";
import { addDeviceStatus } from "../reusable/StoreCertificationObj";
import { filterDevicesByTopology, removeDeviceStatus } from "../reusable/Util";

export default function PerformWHT(WHTFlag: boolean) {
  const { sortedDevices, accType, diagDevicesData } = useSelector(
    (state: any) => ({
      sortedDevices: state.AccountState.ActiveAccountDetails.sortedDevices,
      accType: state.AccountState.ActiveAccountDetails.accountType,
      diagDevicesData: state.AccountState.DiagDevicesInfo.diagDevices,
    }),
    shallowEqual
  );

  const WHTDeviceCat = TESTTYPE_CONFIG.WHT.deviceAllowed;
  const [updatedDevices, setUpdatedDevices] = useState<Device[]>([]);
  const [skippedDevices, setSkippedDevices] = useState<Device[]>([]);
  const diagDevicesRef = useRef(updatedDevices);

  useEffect(() => {
    if (WHTFlag) {
      if (sortedDevices !== undefined && sortedDevices?.length !== 0) {
        let filteredDevices = sortedDevices.filter(
          (d: any) => WHTDeviceCat?.indexOf(d.deviceCategory) > -1
        );
        let computedDevices = filterDevicesByTopology(filteredDevices, accType);
        filteredDevices = removeDeviceStatus(computedDevices?.filteredDevices);
        setSkippedDevices(computedDevices?.skippedDevices);
        diagDevicesRef.current = filteredDevices;
      }
    }
  }, [WHTFlag]);

  useEffect(() => {
    let computedDevices = diagDevicesRef.current;
    if (
      diagDevicesData !== undefined &&
      diagDevicesData?.length !== 0 &&
      computedDevices !== undefined &&
      computedDevices?.length !== 0
    ) {
      computedDevices = computedDevices?.map((d: any) => {
        let certiDeviceData =
          d.deviceCategory !== "IEMTA"
            ? diagDevicesData?.filter(
              (device: any) => device.deviceKey === d.serialNumber
            )
            : diagDevicesData?.filter(
              (device: any) => device.deviceKey === "IEMTA" + d.serialNumber
            );
        if (!certiDeviceData?.[0]?.isLoading) {
          let cDevice = addDeviceStatus(d, certiDeviceData?.[0]?.data);
          d = { ...d, ...cDevice };
          return d;
        } else {
          let cDevice = { deviceStatus: "loading" };
          d = { ...d, ...cDevice };
          return d;
        }
      });
    }
    if (skippedDevices !== undefined && skippedDevices?.length !== 0) {
      computedDevices = computedDevices.concat(skippedDevices);
    }
    setUpdatedDevices(computedDevices);
  }, [diagDevicesData]);

  return { devices: updatedDevices };
}
