import AppLabels from "../../constants/App_Labels";
import CmViewData from "../../data/cablemodem/CmViewData";
import DataCard from "../../topology/components/reusable/DataCard";
import SimpleDataTable from "../../topology/components/reusable/SimpleDataTable";
import CertiExceptionCont from "../certification/CertiExceptionCont";
import ExceptionBox from "../reusable/ExceptionBox";
import { convertCMData, getValueByStrMapping, isNull } from "../reusable/Util";
interface Props {
  cmData: any;
}

const CmFlapsTab = ({ cmData }: Props) => {
  const labels = AppLabels();
  const cmDataConfig = CmViewData();
  let flapsConfig: any = cmDataConfig?.flaps;

  return (
    <CertiExceptionCont dataObj={cmData?.flaps} sectionName={labels.FLAPS}>
      <div>
        <DataCard
          cardHeader={labels.FLAPS}
          cardBody={
            <SimpleDataTable
              headers={cmDataConfig?.flaps?.headers}
              tBody={Object.keys(flapsConfig?.dataKeys)?.map(
                (key: any, i: number) => {
                  let attValue: any = getValueByStrMapping(
                    cmData,
                    flapsConfig?.dataKeys[key]?.dataKey
                  );
                  let computedValue = convertCMData(key, attValue);
                  return (
                    <tr key={i}>
                      <td>
                        {flapsConfig?.dataKeys[key]?.label?.toUpperCase()}
                      </td>
                      <td>{isNull(computedValue)}</td>
                    </tr>
                  );
                }
              )}
            />
          }
        />
      </div>
    </CertiExceptionCont>
  );
};

export default CmFlapsTab;
