import { Button, Modal } from "react-bootstrap";
import TopologyLabels from "../../constants/topologyLabels";

interface props {
  isShown: any;
  setDeleteModal: any;
  headerText?: any;
  alertMessage?: any;
  onDeleteClick?: any;
}

const DeleteAlertModal = ({
  isShown,
  setDeleteModal,
  headerText,
  alertMessage,
  onDeleteClick,
}: props) => {
  const labels = TopologyLabels();
  return (
    <>
      <Modal
        style={{ position: "absolute" }}
        show={isShown}
        size="lg"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header className="searchPopHead">
          {headerText}
          <Button bsPrefix="close" onClick={() => setDeleteModal(false)}>
            &#10005;
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="popUpCont">
            <div
              style={{
                textAlign: "center",
                fontWeight: 600,
                marginBottom: "9px",
              }}
            >
              {alertMessage}
            </div>
            <div className="popUpSubmitCancelButton">
              <button
                className="popUpCancelButton"
                style={{ marginRight: "20px" }}
                onClick={() => setDeleteModal(false)}
              >
                {labels.cancel}
              </button>
              <button
                className="popUpSubmitButton"
                onClick={() => onDeleteClick()}
              >
                {labels.delete}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteAlertModal;
