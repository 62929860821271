import { DeviceActionState } from "../../../states/account/deviceActions/DeviceActionState";

import {
  ZAP_BEGIN,
  ZAP_SUCCESS,
  ZAP_FAILURE,
  ZAP_RESET_STATUS,
  ZapActionTypes,
} from "../../../types/account/deviceActions/Zap";

const initialState: DeviceActionState = {
  isLoading: false,
  error: undefined,
  status: "",
};

export function ZapReducer(
  state = initialState,
  action: ZapActionTypes
): DeviceActionState {
  switch (action.type) {
    case ZAP_BEGIN:
      return {
        isLoading: true,
      };
    case ZAP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: "OK",
      };
    case ZAP_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        status: "ERROR",
      };
    case ZAP_RESET_STATUS:
      return {
        ...state,
        status: "",
      };
    default:
      return state;
  }
}
