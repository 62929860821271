import {
  formatCamelCase,
  getValueByStrMapping,
  isNull,
} from "../../../components/reusable/Util";
import appConst from "../../constants/appConst";

interface props {
  tableConfig: any;
  dataList: any;
}
const FailedAttDisplayTable = ({ tableConfig, dataList }: props) => {
  return (
    <table className="dataTableDetails">
      <thead>
        <tr>
          {tableConfig?.hasOwnProperty("dataKeys") &&
          Object.keys(tableConfig?.dataKeys)?.length !== 0 ? (
            Object.keys(tableConfig?.dataKeys)?.map((h: any, i: number) => {
              return (
                <th
                  key={i}
                  style={{ fontWeight: 650 }}
                  className={
                    Object.keys(tableConfig?.dataKeys)?.length <= 2
                      ? "fixedCol"
                      : ""
                  }
                >
                  {formatCamelCase(h)}
                </th>
              );
            })
          ) : (
            <></>
          )}
        </tr>
      </thead>
      <tbody>
        {dataList?.length > 0 &&
          dataList?.map((data: any, i: number) => {
            return (
              <tr key={i}>
                {Object.keys(tableConfig?.dataKeys)?.map(
                  (key: any, j: number) => {
                    let attObj = getValueByStrMapping(
                      data,
                      tableConfig?.dataKeys[key]
                    );
                    return (
                      <td
                        key={j}
                        style={{ fontWeight: 600 }}
                        className={
                          attObj?.status?.toLowerCase() === appConst.fail
                            ? "failedText"
                            : attObj?.status === appConst.marginal
                            ? "marginalText"
                            : ""
                        }
                      >
                        {typeof attObj === "object"
                          ? isNull(attObj?.value)
                          : isNull(attObj)}
                      </td>
                    );
                  }
                )}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default FailedAttDisplayTable;
