import { useEffect } from "react";
import appConst from "../../../constants/appConst";

import { GetNodeSiteDetailsProps } from "../../../util/reduxFunctions/getTopologyState";

interface Props {
  gMap?: google.maps.Map;
}
const FiberCables = ({ gMap }: Props) => {
  const { activeNode, allNodeData } = GetNodeSiteDetailsProps();
  const fiberData = activeNode?.fiberCableGeometry?.data;

  useEffect(() => {
    if (gMap && fiberData !== undefined && fiberData?.features?.length > 0) {
      gMap?.data.addGeoJson(fiberData);
    }
  }, [fiberData]);

  useEffect(() => {
    if (
      gMap &&
      allNodeData &&
      fiberData !== undefined &&
      fiberData?.features?.length > 0
    ) {
      let allFeatures = [] as any;
      allNodeData?.map(
        (e: any) =>
          e?.fiberCableGeometry?.data?.features &&
          allFeatures.push(...e?.fiberCableGeometry?.data?.features)
      );
      const tempGeoJson = {
        type: appConst?.FeatureCollection,
        features: allFeatures,
      };
      gMap?.data.addGeoJson(tempGeoJson);
    }
  }, [gMap]);

  return <></>;
};

export default FiberCables;
