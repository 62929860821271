import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IoMdLocate } from "react-icons/io";
import CustomAccordion from "../../../../../components/reusable/CustomAccordion";
import TopologyLabels from "../../../../constants/topologyLabels";
import { GetAvailableTR } from "../../../../../components/reusable/Util";
import {
  combineAllNodeEle,
  findRENSElebyID,
  isPSAvailable,
  openNewWin,
} from "../../../../util/utilFuncs";
import { getCustomElement } from "../../../../store/slices/mapItems/node/nodeSiteDetail";
import appConst from "../../../../constants/appConst";
import {
  OLT_URI,
  VHUB_SEARCH_URI,
} from "../../../../store/middleware/apiEndpoints";
import {
  GetNodeSiteDetailsProps,
  GetRENSearchProps,
  GetTopoGeneralProps,
  GetVhubSearchProps,
} from "../../../../util/reduxFunctions/getTopologyState";
import {
  setMapCenter,
  setSelectedMapElement,
} from "../../../../store/slices/mapItems/mapDetails";
import GoogleMapDirectionsBtn from "../../../../../components/reusable/GoogleMapDirectionsBtn";
import { GetGponNodeDetails } from "../../../../../get-api/GetResponse";
import { useVhubData } from "../../../../util/customHooks/topologyHooks";
import { getVhubSearch } from "../../../../store/slices/mapItems/vhub/vhubSearch";
import { getRENSearch } from "../../../../store/slices/search/renSearch";
import { addToLocatorEle } from "../../../../store/slices/mapItems/locatorElements";
import FiberTraceButtons from "../../trace/fiber/FiberTraceButtons";

interface Props {
  elementData: any;
  isPrimaryNodeTab: boolean;
}

export default function NodeSiteDetailsTable({
  elementData,
  isPrimaryNodeTab,
}: Props) {
  const labels = TopologyLabels();
  const dispatch = useDispatch();
  const { isLoading, data, status } = GetVhubSearchProps();
  const [oltName, setOLTName] = useState(undefined);
  const [vhubFlag, setVhubFlag] = useState(false);
  const [powerSupplyFlag, setPowerSupplyFlag] = useState(false);
  const { activeNode, allNodeData } = GetNodeSiteDetailsProps();
  const gponNodeList = GetGponNodeDetails()?.nodeSummaryList?.nodeSummaryList;
  const { activeNodeId, activeNodeType } = GetTopoGeneralProps();
  const eleData = elementData?.data;
  let allPSEle: any = combineAllNodeEle(appConst.powerSupply, allNodeData);
  const { rensSearchData, rensSearchLoading, rensSearchStatus } =
    GetRENSearchProps();

  //get OLT name if its a GPON account
  useEffect(() => {
    if (
      !oltName &&
      activeNodeType === appConst.GPON_NODE &&
      eleData?.nodeNum === activeNodeId
    ) {
      for (let i = 0; i < gponNodeList?.length; i++) {
        const currOLTName = gponNodeList?.[i]?.data?.summary?.data?.oltName;
        if (currOLTName) {
          setOLTName(currOLTName);
          break;
        }
      }
    }
  }, [gponNodeList]);

  useEffect(() => {
    if (
      data &&
      data !== undefined &&
      vhubFlag &&
      !isLoading &&
      status === appConst.OK
    ) {
      const geoData = {
        lat: data?.features?.[0]?.geometry?.coordinates?.[1],
        lng: data?.features?.[0]?.geometry?.coordinates?.[0],
      };
      const activeElement = {
        geometry: geoData,
        properties: {
          id: data?.features?.[0]?.featureId,
          type: appConst?.vhub,
          data: data?.features?.[0]?.properties?.data,
        },
      };
      dispatch(setSelectedMapElement(activeElement));
      dispatch(setMapCenter(geoData));
      setVhubFlag(false);
    }
  }, [data]);

  useEffect(() => {
    if (
      !rensSearchLoading &&
      rensSearchData !== undefined &&
      rensSearchStatus === appConst.OK &&
      powerSupplyFlag
    ) {
      dispatch(
        addToLocatorEle({
          eleType: appConst?.powerSupply,
          eleData: rensSearchData?.[0],
        })
      );
      let activeEle = {
        geometry: {
          lat: rensSearchData?.[0]?.geoData?.coordinates?.[1],
          lng: rensSearchData?.[0]?.geoData?.coordinates?.[0],
        },
        properties: {
          id: rensSearchData?.[0]?.networkId,
          type: appConst.rensElement,
          data: rensSearchData?.[0],
        },
      };
      dispatch(
        setMapCenter({
          lat: activeEle?.geometry?.lat,
          lng: activeEle?.geometry?.lng,
        })
      );
      dispatch(setSelectedMapElement(activeEle));
      setPowerSupplyFlag(false);
    }
  }, [rensSearchStatus]);

  const oltCoord = activeNode?.olt?.data?.features?.[0]?.geometry?.coordinates;
  const parentIdFlag = eleData?.parent === null || eleData.parent === undefined;
  const tableTitle = eleData?.nodeNum
    ? elementData?.type?.toUpperCase() + " - " + eleData?.nodeNum
    : elementData?.type?.toUpperCase();

  const locateRENS = (rensId: any) => {
    if (rensId) {
      let selePSEle = findRENSElebyID(rensId, allPSEle);
      if (selePSEle) {
        dispatch(setMapCenter(selePSEle?.geometry));
        dispatch(setSelectedMapElement(selePSEle));
      } else {
        dispatch(getRENSearch(rensId));
        setPowerSupplyFlag(true);
      }
    }
  };

  // const onOltNamePress = () => {
  //   openNewWin({
  //     link: "/node?oltName=" + oltName,
  //     tabTitle: "OLT-" + oltName,
  //   });
  // };
  const onOltNamePress = () => {
    openNewWin({
      link: "/ponOlt=" + oltName,
      tabTitle: "PON-" + oltName,
    });
  };
  const onOltLocateMe = () => {
    if (oltCoord) {
      dispatch(setMapCenter({ lat: oltCoord?.[1], lng: oltCoord?.[0] }));
    } else
      dispatch(
        getCustomElement({
          url: OLT_URI + oltName,
          elementName: appConst.olt,
          storedData: { nodeCoord: elementData?.coord },
        })
      );
  };

  const locateVhub = (vhubId: any) => {
    if (vhubId) {
      dispatch(getVhubSearch(vhubId));
      setVhubFlag(true);
    }
  };
  return (
    <>
      {eleData && (
        <div className="activeMapEleCont">
          <CustomAccordion
            accTitle={tableTitle}
            headerComp={
              <>
                <FiberTraceButtons activeMapElement={elementData} />
                <GoogleMapDirectionsBtn
                  lat={elementData?.coord?.lat}
                  lng={elementData?.coord?.lng}
                />
              </>
            }
            accBodyElement={
              <table className="dataTableDetails">
                <thead>
                  <tr>
                    <td>{labels?.name.toUpperCase()}</td>
                    <td>{labels?.Value.toUpperCase()}</td>
                  </tr>
                </thead>
                <tbody>
                  {oltName && (
                    <tr className="customTRCell">
                      <td>{labels.OLTName}</td>
                      <td>
                        <section>
                          <span onClick={onOltNamePress}>{oltName}</span>
                          <IoMdLocate onClick={onOltLocateMe} />
                        </section>
                      </td>
                    </tr>
                  )}
                  <GetAvailableTR
                    name={labels.networkID}
                    value={eleData?.networkId}
                  />
                  {!parentIdFlag && (
                    <>
                      <tr>
                        <td>{labels.parentNode}</td>
                        <td>
                          <span className="locatorVal">
                            {eleData?.parent}
                            <IoMdLocate
                              size={15}
                              color="var(--link)"
                              onClick={() => locateVhub(eleData?.parent)}
                            />
                          </span>
                        </td>
                      </tr>
                      <GetAvailableTR
                        name={labels.fwdChannel}
                        value={eleData?.fwdChannel}
                      />
                      <GetAvailableTR
                        name={labels.rtnChannel}
                        value={eleData?.rtnChannel}
                      />
                    </>
                  )}
                  <GetAvailableTR
                    name={labels.polygonId}
                    value={eleData?.polygonId}
                  />
                  <GetAvailableTR
                    name={labels.polygonType}
                    value={eleData?.polygonType}
                  />
                  <GetAvailableTR name={labels.SType} value={eleData?.sType} />
                  <GetAvailableTR
                    name={labels.sType}
                    value={eleData?.siteType}
                  />
                  <GetAvailableTR
                    name={labels.siteDescription}
                    value={eleData?.siteDescription}
                  />
                  <GetAvailableTR
                    name={labels.Designation}
                    value={eleData?.designation}
                  />
                  <GetAvailableTR
                    name={labels.Manufacturer}
                    value={eleData?.manufacturer}
                  />
                  <GetAvailableTR
                    name={labels.Attr11}
                    value={eleData?.attr11}
                  />
                  <GetAvailableTR
                    name={labels.Attr12}
                    value={eleData?.attr12}
                  />
                  <GetAvailableTR
                    name={labels.NumPorts}
                    value={eleData?.numPorts}
                  />
                  <GetAvailableTR
                    name={labels.Clamshell}
                    value={eleData?.clamshell}
                  />
                  <tr>
                    <td>{labels.UPS}</td>
                    <td>
                      <span className="locatorVal">
                        {eleData?.UPS}
                        {eleData?.UPS && (
                          <IoMdLocate
                            size={15}
                            color="var(--link)"
                            onClick={() => locateRENS(eleData?.UPS)}
                          />
                        )}
                      </span>
                    </td>
                  </tr>
                  <GetAvailableTR
                    name={labels.Wattage}
                    value={eleData?.wattage}
                  />
                  <GetAvailableTR
                    name={labels.sysType}
                    value={eleData?.sysType}
                  />
                  <GetAvailableTR name={labels.OHUG} value={eleData?.ohug} />
                  <GetAvailableTR
                    name={labels.profileName}
                    value={eleData?.profileName}
                  />
                  <GetAvailableTR
                    name={labels.InterfaceFk}
                    value={eleData?.interfaceFk}
                  />
                  <GetAvailableTR
                    name={labels.deviceDesc}
                    value={eleData?.deviceDesc}
                  />
                  <GetAvailableTR name={labels.Model} value={eleData?.model} />
                  <GetAvailableTR
                    name={labels.deviceClass}
                    value={eleData?.deviceClass}
                  />
                  <GetAvailableTR name={labels.Volts} value={eleData?.volts} />
                  <GetAvailableTR
                    name={labels.Volts1}
                    value={eleData?.volts1}
                  />
                  <GetAvailableTR
                    name={labels.Volts2}
                    value={eleData?.volts2}
                  />
                  <GetAvailableTR
                    name={labels.Volts3}
                    value={eleData?.volts3}
                  />
                  <GetAvailableTR
                    name={labels.Volts4}
                    value={eleData?.volts4}
                  />
                  <GetAvailableTR
                    name={labels.Volts5}
                    value={eleData?.volts5}
                  />
                  <GetAvailableTR name={labels.Amps} value={eleData?.amps} />
                  <GetAvailableTR name={labels.Amps1} value={eleData?.amps1} />
                  <GetAvailableTR name={labels.Amps2} value={eleData?.amps2} />
                  <GetAvailableTR name={labels.Amps3} value={eleData?.amps3} />
                  <GetAvailableTR name={labels.Amps4} value={eleData?.amps4} />
                  <GetAvailableTR name={labels.Amps5} value={eleData?.amps5} />
                  <GetAvailableTR
                    name={labels.AMPClass}
                    value={eleData?.ampClass}
                  />
                  <GetAvailableTR
                    name={labels.AMPDrawId}
                    value={eleData?.ampDrawId}
                  />
                  <GetAvailableTR name={labels.CTB} value={eleData?.ctb} />
                  <GetAvailableTR name={labels.CSO} value={eleData?.cso} />
                  <GetAvailableTR
                    name={labels.SymbolName}
                    value={eleData?.symbolName}
                  />
                </tbody>
              </table>
            }
          />
        </div>
      )}
    </>
  );
}
