interface Props {
  ppe: any;
  setPpe: any;
}

export default function GeneralPPEView(props: Props) {
  const onCheckBoxStateChange = (checked: boolean, index: number) => {
    props.ppe[index].value = checked;
    props.setPpe([...props.ppe]);
  };

  return (
    <div>
      {props.ppe?.map((e: any, i: number) => (
        <div key={i} className="formCheckboxContainer">
          <label>{e.label}</label>
          <input
            type="checkbox"
            checked={e.value}
            style={{ width: "25px", height: "25px" }}
            onChange={(event) => onCheckBoxStateChange(event.target.checked, i)}
          />
        </div>
      ))}
    </div>
  );
}
