import { RHPDocsisEventInfo } from "../../../../models/account/diagnostics/ihp/RHPDocsisEventInfo";
import {
  RHP_DOCSIS_EVENT_INFO_BEGIN,
  RHP_DOCSIS_EVENT_INFO_SUCCESS,
  RHP_DOCSIS_EVENT_INFO_FAILURE,
  RHPDocsisEventInfoActionTypes,
} from "../../../../types/account/diagnostics/ihp/RHPDocsisEvent";

export function rhpDocsisEventInfoBegin(): RHPDocsisEventInfoActionTypes {
  return {
    type: RHP_DOCSIS_EVENT_INFO_BEGIN,
  };
}

export function rhpDocsisEventInfoSuccess(
  docsisEventsInfo: RHPDocsisEventInfo[]
): RHPDocsisEventInfoActionTypes {
  return {
    type: RHP_DOCSIS_EVENT_INFO_SUCCESS,
    payload: docsisEventsInfo,
  };
}

export function rhpDocsisEventInfoFailure(
  error: Error
): RHPDocsisEventInfoActionTypes {
  return {
    type: RHP_DOCSIS_EVENT_INFO_FAILURE,
    payload: error,
  };
}
