import {
  NOTIFICATION_LIST_BEGIN,
  NOTIFICATION_LIST_FAILURE,
  NOTIFICATION_LIST_SUCCESS,
  NotificationListActionTypes,
  NOTIFICATION_IS_READ,
} from "../../types/notification/NotificationList";
import { NotificationListState } from "../../states/notification/NotificationListState";
import { NotificationInfo } from "../../models/notification/NotificationInfo";

const initialState: NotificationListState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function NotificationListReducer(
  state = initialState,
  action: NotificationListActionTypes
): NotificationListState {
  switch (action.type) {
    case NOTIFICATION_LIST_BEGIN:
      return {
        isLoading: true,
      };
    case NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case NOTIFICATION_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };
    case NOTIFICATION_IS_READ:
      return {
        ...state,
        isLoading: false,
        data: state?.data?.map((n: NotificationInfo) => {
          if(n?.id === action?.payload){
            return{ ...n, ...{ isRead: true} };
          } 
          else{
            return{ ...n};
          }
        }),
        status: "ok",
      };

    default:
      return state;
  }
}
