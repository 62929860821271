import AppLabels from "../../../constants/App_Labels";
import CustomCarousel from "../../reusable/CustomCarousel";
import { getEmailFormatDate } from "../../reusable/Util";

interface Props {
  techId: any;
  feedbackGroup: string;
  feedbackType: string;
  accountNumber: any;
  woID: string;
  feedbackDetails: any;
  previewFlag: boolean;
}

const PrepareFBEmailFormat = (props: Props) => {
  const labels = AppLabels();

  return (
    <div
      style={{
        display: "inline-block",
        fontSize: "14pt",
        fontWeight: 500,
        fontFamily: "Ted Next",
      }}
    >
      <div style={{ justifyContent: "center", display: "flex" }}>
        <span style={{ fontSize: "28.5pt", textAlign: "center" }}>
          {/* {labels.FEEDBACK_EMAIL_PREVIEW} */}
        </span>
      </div>
      {/* //FbEmailGreetings */}
      <p>{labels.Hello} Team, </p>
      <p>{labels.FbEmailGreetings}</p>
      <p>{labels.EmailInfo}</p>
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {labels.Coachback_Group}:
        </span>
        <span>{props?.feedbackGroup}</span>
      </div>
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {labels.COACHBACK_TYPE}:
        </span>
        <span>{props?.feedbackType}</span>
      </div>
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {labels.ACCOUNT_NUMBER}:
        </span>
        <span>{props?.accountNumber}</span>
      </div>
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {labels.Work_Order_ID}:
        </span>
        <span>{props.woID}</span>
      </div>
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {labels.Your_Tech_ID}:
        </span>
        <span>{props?.techId}</span>
      </div>
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {labels.Date_Of_Work_Order}:
        </span>
        <span>{getEmailFormatDate()}</span>
      </div>
      {props?.feedbackDetails?.feedbackMsg != "" && (
        <div
          style={{
            marginBottom: "10px",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              marginRight: "10px",
            }}
          >
            {labels.Coachback_details}:
          </span>
          <span style={{ wordBreak: "break-word" }}>
            {props?.feedbackDetails?.feedBackMsg}
          </span>
        </div>
      )}
      {props?.feedbackDetails?.files != undefined && (
        <div
          style={{
            marginBottom: "10px",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              marginRight: "10px",
            }}
          >
            {labels.Attach_Images} only: {`${labels.Attach_Img_MaxSize}`}
          </span>
          {props?.previewFlag === true && (
            <div className="carouselContainer">
              <CustomCarousel images={props?.feedbackDetails?.files} />
            </div>
          )}
          {props?.previewFlag === false && (
            <div
              className="carouselContainer"
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                maxWidth: "100%",
              }}
            >
              {props?.feedbackDetails?.files !== undefined &&
              props?.feedbackDetails?.files !== ""
                ? props?.feedbackDetails?.files?.map((n: any) => {
                    return (
                      <>
                        <img
                          src={n?.image}
                          style={{
                            maxHeight: "700px",
                            maxWidth: "700px",
                            margin: "1rem",
                            objectFit: "scale-down",
                          }}
                        />
                        <br />
                      </>
                    );
                  })
                : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PrepareFBEmailFormat;
