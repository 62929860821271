import { Tab, Tabs } from "react-bootstrap";
import { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getDeviceDetails,
  getDeviceLoading,
  isNull,
  renderDeviceStatus,
} from "../reusable/Util";
import ODUClient from "../../network/httpclient/odu/ODUClient";
import ODUSummaryInfo from "./ODUSummaryInfo";
import WirelessSubscriberInfo from "./wirelessSubscriber/WirelessSubscriberInfo";
import ODUNetworkInterfaces from "./ODUNetworkInterfaces";
import FailedAttributesOdu from "../FailedAttributes/FailedAttributesOdu";
import { SetDeviceStatus } from "../../store/actions/account/accountInfo/ActiveAccountAction";

const ODUDiagnostics = (props: any) => {
  const dispatch = useDispatch();
  const {
    selectedDevice,
    selDiagAction,
    deviceDiagActions,
    handleActionClick,
  } = props;
  const [wsTabFlag, setWsTabFlag] = useState(false);
  const [niTabFlag, setNiTabFlag] = useState(false);
  const [key, setKey] = useState(selDiagAction);
  const { diagDevicesData } = useSelector(
    (state: any) => ({
      diagDevicesData: state.AccountState.DiagDevicesInfo.diagDevices,
    }),
    shallowEqual
  );
  let oduLoading = getDeviceLoading(
    selectedDevice.serialNumber,
    diagDevicesData
  );
  let oduSummaryDetails = getDeviceDetails(
    selectedDevice.serialNumber,
    "summary",
    diagDevicesData
  );
  let oduWirelessDetails = getDeviceDetails(
    selectedDevice.serialNumber,
    "wirelessInfo",
    diagDevicesData
  );
  let oduSummaryStatus =
    oduSummaryDetails?.hasOwnProperty("errorCode") &&
    oduSummaryDetails?.errorCode != 1009
      ? "ERROR"
      : oduSummaryDetails?.hasOwnProperty("errorCode") &&
        oduSummaryDetails?.errorCode == 1009
      ? "Fail"
      : "OK";
  let oduWirelessStatus = oduWirelessDetails?.hasOwnProperty("errorCode")
    ? "ERROR"
    : "OK";
  useEffect(() => {
    if (deviceDiagActions?.length !== 0) {
      deviceDiagActions?.map((action: any) => {
        if (action.tab === selDiagAction) {
          if (
            action.tab === "wirelessSub" &&
            !action.isOpen &&
            selectedDevice !== undefined
          ) {
            action.isOpen = true;
          } else if (
            action.tab === "networkInterfaces" &&
            !action.isOpen &&
            selectedDevice !== undefined
          ) {
            action.isOpen = true;
            dispatch(
              ODUClient.getODUNetworkInterfaces(
                selectedDevice.oui,
                selectedDevice.serialNumber
              )
            );
          }
        }
        if (action.tab === "wirelessSub" && action.isOpen) {
          setWsTabFlag(true);
        } else if (action.tab === "networkInterfaces" && action.isOpen) {
          setNiTabFlag(true);
        }
      });
    }
    setKey(selDiagAction);
  }, [selDiagAction]);
  useEffect(() => {
    if (oduSummaryDetails !== undefined && oduSummaryDetails?.length === 0) {
      dispatch(
        ODUClient.getODUSummary(selectedDevice.oui, selectedDevice.serialNumber)
      );
    }
    if (oduWirelessDetails !== undefined && oduWirelessDetails?.length === 0) {
      dispatch(
        ODUClient.getODUWirelessInfo(
          selectedDevice.oui,
          selectedDevice.serialNumber
        )
      );
    }
    setWsTabFlag(false);
    setNiTabFlag(false);
  }, [selectedDevice]);
  useEffect(() => {
    if (oduWirelessDetails?.length !== 0) {
      dispatch(
        SetDeviceStatus(
          selectedDevice?.serialNumber,
          selectedDevice?.deviceCategory,
          oduWirelessDetails?.hasOwnProperty("overallStatus")
            ? oduWirelessDetails?.overallStatus?.toLowerCase()
            : "fail"
        )
      );
    }
  }, [oduLoading]);
  return (
    <div className="detailDiagCont">
      <section className="deviceData">
        <div className="deviceStatusCont">
          <p>
            <span>MODEL : </span>
            <span>{isNull(selectedDevice.deviceModel)}</span>
          </p>
          <p className="deviceOverallStat">
            <span>STATUS : </span>
            {!oduLoading ? (
              renderDeviceStatus(oduWirelessDetails, oduWirelessStatus)
            ) : (
              <span style={{ fontStyle: "italic" }}>Fetching...</span>
            )}
          </p>
        </div>
        <p>
          <span>SERIAL NUMBER : </span>
          <span>{isNull(selectedDevice.serialNumber)}</span>
        </p>
        <p>
          {oduWirelessDetails &&
          oduWirelessDetails?.overallStatus === "Fail" ? (
            <FailedAttributesOdu oduWirelessInfo={oduWirelessDetails} />
          ) : (
            <></>
          )}
        </p>
      </section>
      <section className="detailDiagTabs">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k: any) => handleActionClick(k)}
        >
          <Tab eventKey="oduSUMMARY" title="ODU SUMMARY">
            <ODUSummaryInfo
              selectedDevice={selectedDevice}
              oduSummaryInfo={oduSummaryDetails}
              oduSummaryStatus={oduSummaryStatus}
              oduLoading={oduLoading}
              oduWirelessInfo={oduWirelessDetails}
              oduWirelessStatus={oduWirelessStatus}
            />
          </Tab>
          {niTabFlag ? (
            <Tab eventKey="networkInterfaces" title="NETWORK INTERFACES">
              <ODUNetworkInterfaces selectedDevice={selectedDevice} />
            </Tab>
          ) : (
            <></>
          )}
          {wsTabFlag ? (
            <Tab eventKey="wirelessSub" title="WIRELESS SUBSCRIBER INFO">
              <WirelessSubscriberInfo selectedDevice={selectedDevice} />
            </Tab>
          ) : (
            <></>
          )}
        </Tabs>
      </section>
    </div>
  );
};

export default ODUDiagnostics;
