import { createSlice } from "@reduxjs/toolkit";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "VhubDetails",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    VhubDetailsBegin: (VhubDetails) => {
      VhubDetails.isLoading = true;
    },
    VhubDetailsSuccess: (VhubDetails, action) => {
      VhubDetails.isLoading = false;
      VhubDetails.data = action.payload;
      VhubDetails.status = storeConst.OK;
    },
    VhubDetailsFailure: (VhubDetails, action) => {
      VhubDetails.isLoading = false;
      VhubDetails.error = action.payload;
      VhubDetails.data = undefined;
      VhubDetails.status = storeConst.ERROR;
    },

    resetVhubDetails: (VhubDetails) => {
      VhubDetails.isLoading = false;
      VhubDetails.error = undefined;
      VhubDetails.data = undefined;
      VhubDetails.status = "";
    },
  },
});

export const {
  VhubDetailsBegin,
  VhubDetailsSuccess,
  VhubDetailsFailure,
  resetVhubDetails,
} = slice.actions;

export default slice.reducer;
