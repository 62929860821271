import { Tab, Tabs } from "react-bootstrap";
import { FaCircle } from "react-icons/fa";
import { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getDeviceDetails,
  getDeviceLoading,
  isNull,
  renderDeviceStatus,
} from "../reusable/Util";
import IHPClient from "../../network/httpclient/ihp/IHPClient";
import IHPSummary from "./IHPSummary";
import AppLabels from "../../constants/App_Labels";
import ETAData from "./ETAData";
import QLRNData from "./QLRNData";
import QSIPData from "./QSIPData";
import IHPFeaturesData from "./IHPFeaturesData";
import { GLOBAL_CONST } from "../../constants/Global";
import FailedAttributesRhp from "../FailedAttributes/FailedAttributesRhp";
import { SetDeviceStatus } from "../../store/actions/account/accountInfo/ActiveAccountAction";
import RHPDocsisEvents from "./RHPDocsisEvents";
import RHPPKTCableEvents from "./RHPPKTCableEvents";
import CableModemClient from "../../network/httpclient/cablemodem/CableModemClient";

const IHPDiagnostics = (props: any) => {
  const labels = AppLabels();
  const dispatch = useDispatch();
  const {
    selectedDevice,
    selDiagAction,
    handleActionClick,
    deviceDiagActions,
  } = props;
  const [key, setKey] = useState(selDiagAction);
  const [etaTabFlag, setEtaTabFlag] = useState(false);
  const [qlrnTabFlag, setQlrnTabFlag] = useState(false);
  const [qsipTabFlag, setQsipTabFlag] = useState(false);
  const [featureTabFlag, setFeatureTabFlag] = useState(false);
  const [docsisEventsTabFlag, setDocsisEventsTabFlag] = useState(false);
  const [pcEventTabFlag, setPcEventTabFlag] = useState(false);
  const [sipEventTabFlag, setSipEventTabFlag] = useState(false);

  const {
    diagDevicesData,
    etaInfo,
    qlrnInfo,
    selectedSA,
    qsipInfo,
    featuresInfo,
    docsisEventsInfo,
    // flapsInfo,
  } = useSelector(
    (state: any) => ({
      docsisEventsInfo: state.AccountState?.ihpDocsisEvents?.data, // create a redux store for ihp docsis
      // flapsInfo: state.AccountState.ihpFlapsDetails.data, // create a redux store for ihp flaps
      etaInfo: state.AccountState.IHPInsideWiringInfo.data,
      selectedSA: state.AccountState.ActiveAccountDetails.selectedSA,
      qlrnInfo: state.AccountState.IHPQrlnDataInfo.data,
      qsipInfo: state.AccountState.IHPQsipDataInfo.data,
      featuresInfo: state.AccountState.IHPFeaturesInfo.data,
      diagDevicesData: state.AccountState.DiagDevicesInfo.diagDevices,
    }),
    shallowEqual
  );
  let ihpLoading = getDeviceLoading(
    selectedDevice.deviceCategory === "IEMTA"
      ? "IEMTA" + selectedDevice.serialNumber
      : selectedDevice.serialNumber,
    diagDevicesData
  );
  let ihpSummaryDetails = getDeviceDetails(
    selectedDevice.deviceCategory === "IEMTA"
      ? "IEMTA" + selectedDevice.serialNumber
      : selectedDevice.serialNumber,
    "summary",
    diagDevicesData
  );
  let ihpSummaryStatus =
    (ihpSummaryDetails?.hasOwnProperty("errorCode") &&
      ihpSummaryDetails?.errorCode != 1009) ||
      ihpSummaryDetails === ""
      ? "ERROR"
      : ihpSummaryDetails?.hasOwnProperty("errorCode") &&
        ihpSummaryDetails?.errorCode == 1009
        ? "Fail"
        : "OK";
  let rhpDocsisDetails = getDeviceDetails(
    selectedDevice.deviceCategory === "IEMTA"
      ? "IEMTA" + selectedDevice.serialNumber
      : selectedDevice.serialNumber,
    "docsis",
    diagDevicesData
  );
  let rhpDocsisStatus =
    (rhpDocsisDetails?.hasOwnProperty("errorCode") &&
      rhpDocsisDetails?.errorCode != 1009) ||
      rhpDocsisDetails === ""
      ? "ERROR"
      : rhpDocsisDetails?.hasOwnProperty("errorCode") &&
        rhpDocsisDetails?.errorCode == 1009
        ? "Fail"
        : "OK";
  let rhpInterfaceDetails = getDeviceDetails(
    selectedDevice.deviceCategory === "IEMTA"
      ? "IEMTA" + selectedDevice.serialNumber
      : selectedDevice.serialNumber,
    "interfaceSummary",
    diagDevicesData
  );
  let rhpCmSummaryDetails = getDeviceDetails(
    selectedDevice.serialNumber,
    "cmSummary",
    diagDevicesData
  );
  let rhpPCEventDetails = getDeviceDetails(
    selectedDevice.deviceCategory === "IEMTA"
      ? "IEMTA" + selectedDevice.serialNumber
      : selectedDevice.serialNumber,
    "pceventlog",
    diagDevicesData
  );
  let rhpPCEventStatus =
    (rhpPCEventDetails?.hasOwnProperty("errorCode") &&
      rhpPCEventDetails?.errorCode != 1009) ||
      rhpPCEventDetails === ""
      ? "ERROR"
      : rhpPCEventDetails?.hasOwnProperty("errorCode") &&
        rhpPCEventDetails?.errorCode == 1009
        ? "Fail"
        : "OK";

  let cmNodeDetails = getDeviceDetails(
    selectedDevice.serialNumber,
    "nodeInfo",
    diagDevicesData
  );
  // getting phone number
  let homeNum: any[] = [];

  selectedSA?.subscription?.map((sub: any) => {
    if (sub?.hasOwnProperty("deviceInfo")) {
      sub?.deviceInfo?.map((deviceInfo: any) => {
        if (
          deviceInfo?.deviceCategory === "EMTA" ||
          deviceInfo?.deviceCategory === "IEMTA"
        )
          if (deviceInfo?.hasOwnProperty("phoneNumber")) {
            homeNum?.push(deviceInfo.phoneNumber);
          }
      });
    }
  });
  useEffect(() => {
    if (deviceDiagActions?.length !== 0) {
      deviceDiagActions?.map((action: any) => {
        if (action.tab === selDiagAction) {
          if (
            action.tab === "etaDATA" &&
            !action.isOpen &&
            selectedDevice !== undefined
          ) {
            action.isOpen = true;
            if (etaInfo === undefined) {
              if (selectedDevice.deviceType !== "IMTA") {
                dispatch(
                  IHPClient.getIHPInsideWiring(
                    selectedDevice?.macaddress,
                    selectedDevice?.atamac,
                    GLOBAL_CONST.portNumber
                  )
                );
              } else {
                dispatch(
                  IHPClient.getIHPInsideWiring(
                    selectedDevice.cmmac,
                    selectedDevice.atamac,
                    GLOBAL_CONST.portNumber
                  )
                );
              }
            }
          } else if (
            action.tab === "DE" &&
            !action.isOpen &&
            selectedDevice !== undefined
          ) {
            action.isOpen = true;
            if (docsisEventsInfo === undefined) {
              dispatch(IHPClient.getRHPDocsisEvents(selectedDevice.macAddress));
            }
            // if (flapsInfo === undefined) {
            //   dispatch(
            //     IHPClient.getIHPFlapsData(selectedDevice.macAddress)
            //   );
            // }
          } else if (
            action.tab === "qlrnDATA" &&
            !action.isOpen &&
            selectedDevice !== undefined
          ) {
            action.isOpen = true;
            if (qlrnInfo === undefined) {
              dispatch(
                IHPClient.getIHPQrlnData(GLOBAL_CONST.telPrefix + homeNum)
              );
            }
          } else if (
            action.tab === "qsipDATA" &&
            !action.isOpen &&
            selectedDevice !== undefined
          ) {
            action.isOpen = true;
            if (qsipInfo === undefined) {
              dispatch(
                IHPClient.getIHPQsipData(GLOBAL_CONST.telPrefix + homeNum)
              );
            }
          } else if (
            action.tab === "featuresDATA" &&
            !action.isOpen &&
            selectedDevice !== undefined
          ) {
            action.isOpen = true;
            if (featuresInfo === undefined) {
              dispatch(
                IHPClient.getIHPFeatures(GLOBAL_CONST.telPrefix + homeNum)
              );
            }
          } else if (
            action.tab === "PCE" &&
            !action.isOpen &&
            selectedDevice !== undefined
          ) {
            action.isOpen = true;
            if (
              rhpPCEventDetails === undefined ||
              (rhpPCEventDetails !== undefined &&
                rhpPCEventDetails?.length === 0)
            ) {
              if (selectedDevice.deviceCategory !== "IEMTA") {
                dispatch(
                  IHPClient.getPCEventLog(
                    selectedDevice.serialNumber,
                    selectedDevice?.macaddress,
                    selectedDevice?.atamac
                  )
                );
              } else {
                dispatch(
                  IHPClient.getPCEventLog(
                    selectedDevice.serialNumber,
                    selectedDevice?.cmmac,
                    selectedDevice?.atamac
                  )
                );
              }
            }
          }
        }
        if (action.tab === "etaDATA" && action.isOpen) {
          setEtaTabFlag(true);
        }
        if (action.tab === "qlrnDATA" && action.isOpen) {
          setQlrnTabFlag(true);
        }
        if (action.tab === "qsipDATA" && action.isOpen) {
          setQsipTabFlag(true);
        }
        if (action.tab === "featuresDATA" && action.isOpen) {
          setFeatureTabFlag(true);
        }
        if (action.tab === "DE" && action.isOpen) {
          setDocsisEventsTabFlag(true);
        }
        if (
          action.tab === "PCE" &&
          action.isOpen &&
          action?.label?.toUpperCase() === "PACKET CABLE EVENTS"
        ) {
          setPcEventTabFlag(true);
        }
        if (
          action.tab === "PCE" &&
          action.isOpen &&
          action?.label?.toUpperCase() === "SIP"
        ) {
          setSipEventTabFlag(true);
        }
      });
    }
    setKey(selDiagAction);
  }, [selDiagAction]);
  useEffect(() => {
    setKey(selDiagAction);
  }, [selDiagAction]);

  useEffect(() => {
    if (selectedDevice.deviceType !== "IMTA") {
      if (ihpSummaryDetails !== undefined && ihpSummaryDetails?.length === 0) {
        dispatch(
          IHPClient.getIHPSummary(
            selectedDevice.deviceCategory,
            selectedDevice.serialNumber,
            selectedDevice.macAddress,
            ""
          )
        );
      }
      if (rhpDocsisDetails !== undefined && rhpDocsisDetails?.length === 0) {
        dispatch(
          IHPClient.getIHPDocsis(
            selectedDevice.serialNumber,
            selectedDevice.macAddress
          )
        );
      }
      if (
        rhpInterfaceDetails !== undefined &&
        rhpInterfaceDetails?.length === 0
      ) {
        dispatch(
          IHPClient.getRHPInterface(
            selectedDevice.serialNumber,
            selectedDevice.macAddress
          )
        );
      }
      if (
        rhpCmSummaryDetails !== undefined &&
        rhpCmSummaryDetails?.length === 0
      ) {
        dispatch(
          IHPClient.getCMSummary(
            selectedDevice.serialNumber,
            selectedDevice.macAddress
          )
        );
      }
      if (cmNodeDetails !== undefined && cmNodeDetails?.length === 0) {
        dispatch(
          CableModemClient.getCMNodeDetails(
            selectedDevice.serialNumber,
            isNull(selectedSA.shubId)
          )
        );
      }
    } else {
      if (ihpSummaryDetails !== undefined && ihpSummaryDetails?.length === 0) {
        dispatch(
          IHPClient.getIHPSummary(
            selectedDevice.deviceCategory,
            selectedDevice.serialNumber,
            selectedDevice.cmmac,
            selectedDevice.atamac
          )
        );
      }
      if (
        rhpCmSummaryDetails !== undefined &&
        rhpCmSummaryDetails?.length === 0
      ) {
        dispatch(
          IHPClient.getCMSummary(
            selectedDevice.serialNumber,
            selectedDevice.cmmac
          )
        );
      }
      if (cmNodeDetails !== undefined && cmNodeDetails?.length === 0) {
        dispatch(
          CableModemClient.getCMNodeDetails(
            selectedDevice.serialNumber,
            isNull(selectedSA.shubId)
          )
        );
      }
    }
  }, [selectedDevice]);
  useEffect(() => {
    if (
      selectedDevice.deviceCategory !== "IEMTA" &&
      rhpDocsisDetails?.length !== 0
    ) {
      dispatch(
        SetDeviceStatus(
          selectedDevice?.serialNumber,
          selectedDevice?.deviceCategory,
          rhpDocsisDetails?.hasOwnProperty("overallStatus")
            ? rhpDocsisDetails?.overallStatus?.toLowerCase()
            : "fail"
        )
      );
    } else if (
      selectedDevice.deviceCategory === "IEMTA" &&
      ihpSummaryDetails?.length !== 0
    ) {
      dispatch(
        SetDeviceStatus(
          selectedDevice?.serialNumber,
          selectedDevice?.deviceCategory,
          (ihpSummaryDetails?.hasOwnProperty("errorCode") &&
            ihpSummaryDetails?.errorCode != 1009) ||
            ihpSummaryDetails === ""
            ? "Skipped"
            : ihpSummaryDetails?.hasOwnProperty("errorCode") &&
              ihpSummaryDetails?.errorCode == 1009
              ? "Fail"
              : "pass"
        )
      );
    } else if (
      selectedDevice.deviceCategory === "IEMTA" &&
      ihpSummaryDetails?.length === 0
    ) {
      dispatch(
        SetDeviceStatus(
          selectedDevice?.serialNumber,
          selectedDevice?.deviceCategory,
          "Skipped"
        )
      );
    }
  }, [ihpSummaryDetails]);
  return (
    <div className="detailDiagCont">
      <section className="deviceData">
        <div className="deviceStatusCont">
          <p>
            <span>{labels.SERIAL_NUMBER.toUpperCase()} : </span>
            <span>{isNull(selectedDevice.serialNumber)}</span>
          </p>
          <p className="deviceOverallStat">
            <span>{labels.STATUS.toUpperCase()} : </span>
            {!ihpLoading ? (
              ihpSummaryStatus === "OK" ? (
                renderDeviceStatus(rhpDocsisDetails, rhpDocsisStatus)
              ) : ihpSummaryStatus === "ERROR" ? (
                <span
                  className="deviceStatusTxtCont"
                  style={{ color: "#E77C40" }}
                >
                  <FaCircle />
                  <span style={{ fontWeight: 700 }}>SKIPPED</span>
                </span>
              ) : (
                <span
                  className="deviceStatusTxtCont"
                  style={{ color: "#BF371F" }}
                >
                  <FaCircle />
                  <span style={{ fontWeight: 700 }}>FAIL</span>
                </span>
              )
            ) : (
              <span style={{ fontStyle: "italic" }}>Fetching...</span>
            )}
          </p>
        </div>
        <p>
          {rhpDocsisDetails && rhpDocsisDetails?.overallStatus === "Fail" ? (
            <FailedAttributesRhp rhpDocsisInfo={rhpDocsisDetails} />
          ) : (
            <></>
          )}
        </p>
      </section>
      <section className="detailDiagTabs">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k: any) => handleActionClick(k)}
        >
          <Tab eventKey="ihpSUMMARY" title="EMTA SUMMARY">
            <IHPSummary
              selectedDevice={selectedDevice}
              ihpLoading={ihpLoading}
              summaryInfo={ihpSummaryDetails}
              summaryStatus={ihpSummaryStatus}
              docsisInfo={rhpDocsisDetails}
              interfaceInfo={rhpInterfaceDetails}
              cmSummaryInfo={rhpCmSummaryDetails}
              cmNodeDetails={cmNodeDetails}
              selectedSA={selectedSA}
            />
          </Tab>
          {docsisEventsTabFlag && (
            <Tab eventKey="DE" title="DOCSIS EVENT LOGS">
              <RHPDocsisEvents selectedDevice={selectedDevice} />
            </Tab>
          )}
          {etaTabFlag ? (
            <Tab eventKey="etaDATA" title="INSIDE WIRING">
              <ETAData selectedDevice={selectedDevice} />
            </Tab>
          ) : (
            <></>
          )}
          {qlrnTabFlag ? (
            <Tab eventKey="qlrnDATA" title="QLRN DATA">
              <QLRNData homeNum={homeNum} selectedDevice={selectedDevice} />
            </Tab>
          ) : (
            <></>
          )}
          {qsipTabFlag ? (
            <Tab eventKey="qsipDATA" title="QSIP DATA">
              <QSIPData homeNum={homeNum} selectedDevice={selectedDevice} />
            </Tab>
          ) : (
            <></>
          )}
          {featureTabFlag ? (
            <Tab eventKey="featuresDATA" title="FEATURE VERIFICATION">
              <IHPFeaturesData
                homeNum={homeNum}
                selectedDevice={selectedDevice}
              />
            </Tab>
          ) : (
            <></>
          )}
          {pcEventTabFlag && (
            <Tab eventKey="PCE" title="PACKET CABLE EVENT LOGS">
              <RHPPKTCableEvents
                selectedDevice={selectedDevice}
                ihpLoading={ihpLoading}
                pceEventInfo={rhpPCEventDetails}
                pceEventStatus={rhpPCEventStatus}
              />
            </Tab>
          )}
          {sipEventTabFlag && (
            <Tab eventKey="PCE" title="SIP LOGS">
              <RHPPKTCableEvents
                selectedDevice={selectedDevice}
                ihpLoading={ihpLoading}
                pceEventInfo={rhpPCEventDetails}
                pceEventStatus={rhpPCEventStatus}
              />
            </Tab>
          )}
        </Tabs>
      </section>
    </div>
  );
};

export default IHPDiagnostics;
