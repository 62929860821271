import { Card } from "react-bootstrap";
import { useState } from "react";
import { TabPanel } from "../../screens/account-page/AccountPageTabs";
import CustomTab from "../reusable/CustomTab";
import { isEmptyObject } from "../reusable/Util";
import DataDisplayTable from "../reusable/DataDisplayTable";
import AppLabels from "../../constants/App_Labels";
import CustomAccordion from "../reusable/CustomAccordion";
const Wifi2GData = (props: any) => {
  const { wifiSettingsData, wifiRadioData } = props;
  const [tabSelIndex, setTabSelIndex] = useState(0);
  const labels = AppLabels();
  return (
    <CustomAccordion
      accTitle={labels.WIFI_TPF}
      accBodyElement={
        <>
          {wifiSettingsData !== undefined && wifiRadioData !== undefined ? (
            <Card className="dsChTabsCard">
              <Card.Header>
                <CustomTab
                  tabType="horizontal"
                  index={0}
                  value={tabSelIndex}
                  handleSelect={(i: any) => setTabSelIndex(i)}
                  label="RADIO"
                />
                <CustomTab
                  tabType="horizontal"
                  index={1}
                  value={tabSelIndex}
                  handleSelect={(i: any) => setTabSelIndex(i)}
                  label="SETTINGS"
                />
              </Card.Header>
              <Card.Body>
                <TabPanel value={tabSelIndex} index={0}>
                  {wifiRadioData?.hasOwnProperty("radio2g") &&
                  isEmptyObject(wifiRadioData.radio2g) !== 0 ? (
                    <DataDisplayTable
                      dataObject={wifiRadioData.radio2g}
                      tableName="radio2g"
                    />
                  ) : (
                    <></>
                  )}
                </TabPanel>
                <TabPanel value={tabSelIndex} index={1}>
                  {wifiSettingsData?.hasOwnProperty("wifi2g") &&
                  isEmptyObject(wifiSettingsData.wifi2g) !== 0 ? (
                    <DataDisplayTable
                      dataObject={wifiSettingsData.wifi2g}
                      tableName="wifi2g"
                    />
                  ) : (
                    <></>
                  )}
                </TabPanel>
              </Card.Body>
            </Card>
          ) : (
            <>NO DATA AVAILABLE...</>
          )}
        </>
      }
    />
  );
};

export default Wifi2GData;
