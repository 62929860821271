import { Divider } from "@material-ui/core";

import "../../../design-tokens/forms/LOP.scss";
import AppLabels from "../../../constants/App_Labels";

export default function ViewLetterOfPermissionScreen({ data }: any) {
  const formData = data?.formData;
  const labels = AppLabels() as any;

  return (
    <div className="viewLOPContainer">
      <div>
        <label className="titleLabel">{labels.Work_Order_ID}:</label>
        <label className="textLabel">{data?.workOrderId}</label>
      </div>
      <div>
        <label className="titleLabel">{labels.Order_Type}:</label>
        <label className="textLabel">{formData?.orderType}</label>
      </div>
      <Divider style={{ margin: "10px 0px 20px 0px" }} />
      <div>
        <label className="titleLabel">{labels.Work_Required_Involves}:</label>
      </div>
      {formData?.workRequired?.map((e: any, i: number) => (
        <div key={i}>
          <label className="textLabel">{labels?.[e]}</label>
        </div>
      ))}
      <Divider style={{ margin: "15px 0px 20px 0px" }} />
      {formData?.summaryWorkRequired != "" && (
        <>
          <div>
            <label className="titleLabel">{labels.Work_Summary}:</label>
            <label className="textLabel">{formData?.summaryWorkRequired}</label>
          </div>
          <Divider style={{ margin: "10px 0px 20px 0px" }} />
        </>
      )}
      <div>
        <label className="titleLabel">{labels.Work_Approved}:</label>
        <label className="textLabel">
          {formData?.workApprobation === true ? labels.Yes : labels.No}
        </label>
      </div>
      <Divider style={{ margin: "10px 0px 20px 0px" }} />
      <label className="titleLabel">{labels.Authorized_Signature}:</label>
      <div className="imgContainer">
        <img
          src={formData?.signature}
          style={{ height: "50%", width: "50%" }}
        />
        <div className="dashedLine" />
      </div>
      <Divider style={{ margin: "20px 0px 20px 0px" }} />
      <div>
        <label className="titleLabel">
          {labels.Copy_of_this_Email_Sent_to}:
        </label>
      </div>
      {formData?.emails?.map((e: any, i: number) => (
        <div key={i}>
          <label className="textLabel">{e}</label>
        </div>
      ))}
      <Divider style={{ margin: "15px 0px 20px 0px" }} />
    </div>
  );
}
