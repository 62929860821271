import { useEffect, useState } from "react";
import CustomPopOver from "../reusable/CustomPopOver";
import FullScreenPopUp from "../reusable/FullScreenPopUp";
import NeighFullDiagView from "./NeighFullDiagView";
import { ReactComponent as HomePin } from "../../resources/images/icons/homePin.svg";
import { ReactComponent as RouterPin } from "../../resources/images/icons/routerPin.svg";
import { checkCMStatus } from "../reusable/Util";

const NeighMapMarker = (props: any) => {
  const { isCustomer, neighData, address } = props;
  const [openNeighData, setOpenNeighData] = useState(false);
  const [neighDeviceStatus, setNeighDeviceStatus] = useState("");
  const handleMapPinClick = () => {
    setOpenNeighData(true);
  };
  useEffect(() => {
    let cmStatus = checkCMStatus(neighData);
    setNeighDeviceStatus(cmStatus);
  }, [neighData]);
  return (
    <>
      <div
        className={isCustomer ? "homeCont" : "markerCont"}
        onClick={() => handleMapPinClick()}
      >
        <CustomPopOver
          position="top"
          popOverData={address}
          originComp={
            isCustomer ? (
              <HomePin
                fill={
                  neighDeviceStatus === "pass"
                    ? "#00985f"
                    : neighDeviceStatus === "fail"
                    ? "#dc3545"
                    : "#808284"
                }
              />
            ) : (
              <RouterPin
                fill={
                  neighDeviceStatus === "pass"
                    ? "#00985f"
                    : neighDeviceStatus === "fail"
                    ? "#dc3545"
                    : "#808284"
                }
              />
            )
          }
          index="popOver"
        />
      </div>
      <FullScreenPopUp
        open={openNeighData}
        handleClose={() => setOpenNeighData(false)}
        childComponent={
          <NeighFullDiagView
            summayData={neighData?.cmData?.summary}
            docsisData={neighData?.cmData?.docsis}
            flapsData={neighData?.cmData?.flaps}
          />
        }
        pageName={address}
      />
    </>
  );
};

export default NeighMapMarker;
