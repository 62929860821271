import {
  GET_ALL_CERTIFICATIONS_BEGIN,
  GET_ALL_CERTIFICATIONS_FAILURE,
  GET_ALL_CERTIFICATIONS_SUCCESS,
  GetAllCertificationsActionTypes,
} from "../../../types/account/certifications/AllCertification";

export function GetAllCertificationsBegin(): GetAllCertificationsActionTypes {
  return {
    type: GET_ALL_CERTIFICATIONS_BEGIN,
  };
}

export function GetAllCertificationsSuccess(
  allCertifications: any
): GetAllCertificationsActionTypes {
  return {
    type: GET_ALL_CERTIFICATIONS_SUCCESS,
    payload: allCertifications,
  };
}

export function GetAllCertificationsFailure(
  error: any
): GetAllCertificationsActionTypes {
  return {
    type: GET_ALL_CERTIFICATIONS_FAILURE,
    payload: error,
  };
}
