import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import "../../design-tokens/history-page/HistoryPage.scss";
import HistoryFilters from "../../components/history/forms/FormsHistoryFilters";
import HistoryTable from "../../components/history/forms/FormsHistoryTable";
import FormsHistoryClient from "../../network/httpclient/history/FormsHistoryClient";
import { formsHistoryReset } from "../../store/actions/history/FormsHistoryAction";
import { getDate } from "../../components/reusable/Util";
import ConfigConst from "../../constants/ConfigConst";
import { GetTechInfo } from "../../get-api/GetResponse";

interface Props {
  accountID?: string;
  cbpid?: string;
  fromAccount?: boolean;
}

const FormsHistoryTab = (props: Props) => {
  const dispatch = useDispatch();
  const accountID = props.accountID ? props.accountID : "";
  const cbpid = props.cbpid ? props.cbpid : "";
  const techData = GetTechInfo()?.data;
  const lanid = GetTechInfo()?.lanId;

  const canSearchForTechId =
    techData?.roles.includes(ConfigConst.MANAGER) ||
    techData?.roles.includes(ConfigConst.ADMIN);

  const [currentScreen, setCurrentScreen] = useState(0);
  const [offset, setOffset] = useState(0);
  const [filterParams, setFilterParams] = useState({
    techId: "",
    techLanId: lanid,
    accountId: accountID,
    cbpid: cbpid,
    workOrderId: "",
    fromDate: getDate("yesterday"),
    toDate: getDate("now"),
    formType: "",
    limit: 25,
    offset: 0,
    nodeNumber: "",
  });

  useEffect(() => {
    if (data === undefined) {
      refreshHistory(0);
    }
  }, []);

  const { data, isLoading } = useSelector(
    (state: any) => ({
      data: state.FormsHistoryInfo.data,
      isLoading: state.FormsHistoryInfo.isLoading,
    }),
    shallowEqual
  );

  const handleFilters = (
    techId: any,
    techLanId: any,
    workOrderId: any,
    accountId: any,
    cbpid: any,
    formType: any,
    fromDate: any,
    toDate: any,
    nodeNumber: any
  ) => {
    setFilterParams({
      ...filterParams,
      techId: techId,
      techLanId: techLanId,
      accountId: accountId,
      cbpid: cbpid,
      workOrderId: workOrderId,
      fromDate: fromDate,
      toDate: toDate,
      formType: formType,
      limit: 25,
      offset: 0,
      nodeNumber: nodeNumber,
    });

    dispatch(formsHistoryReset(undefined));
    setCurrentScreen(currentScreen + 1);
    setOffset(0);
    dispatch(
      FormsHistoryClient.getHistory(
        techId,
        techLanId,
        accountId,
        cbpid,
        workOrderId,
        fromDate,
        toDate,
        formType,
        25,
        0,
        nodeNumber
      )
    );
  };

  function getDataFromIndex(index: Number) {
    var selectedData: any = [];
    if (data !== undefined && data?.indexes !== undefined) {
      data?.indexes?.map((item: any) => {
        if (index === item.index) {
          selectedData = item.data;
        }
      });
    }

    //return data which matches with active account ID only
    if (props.fromAccount && accountID !== "") {
      selectedData = selectedData
        ?.map(
          (e: any) => (e.accountNumber === accountID || e.cbpid === cbpid) && e
        )
        ?.filter((e: any) => e);
    }
    return selectedData;
  }

  const refreshHistory = (offset: any) => {
    dispatch(
      FormsHistoryClient.getHistory(
        filterParams.techId,
        filterParams.techLanId,
        filterParams.accountId,
        filterParams.cbpid,
        filterParams.workOrderId,
        filterParams.fromDate,
        filterParams.toDate,
        filterParams.formType,
        filterParams.limit,
        offset,
        filterParams.nodeNumber
      )
    );
  };

  return (
    <>
      <div>
        <HistoryFilters
          techData={techData}
          onApplyFilters={handleFilters}
          accountID={accountID}
          cbpid={cbpid}
          fromAccount={props.fromAccount}
          filterParams={filterParams}
        />
      </div>
      <div className="historyResultCont">
        {/* <HistoryTable
          allForms={getDataFromIndex(offset)}
          isLoading={isLoading}
          techData={techData}
          page={currentScreen}
          totalElements={getDataFromIndex(offset)?.length}
          handleChangePage={(offset: any) => {
            if (getDataFromIndex(offset)?.length === 0) {
              refreshHistory(offset);
            }
            setOffset(offset);
          }}
        /> */}
        <HistoryTable
          allForms={getDataFromIndex(offset)}
          isLoading={isLoading}
          techData={techData}
          page={currentScreen}
          totalElements={data?.totalElements}
          handleChangePage={(offset: any) => {
            if (getDataFromIndex(offset)?.length === 0) {
              refreshHistory(offset);
            }
            setOffset(offset);
          }}
        />
      </div>
    </>
  );
};
export default FormsHistoryTab;
