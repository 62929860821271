import { CMTSIPDetails } from "../../../models/node/nodeinfo/CMTSNodeDetails";
import {
  CMTSIPActionTypes,
  CMTS_IP_BEGIN,
  CMTS_IP_SUCCESS,
  CMTS_IP_FAILURE,
  SET_CMTS_IP_DETAILS,
  RESET_CMTS_IP,
} from "../../../types/node/nodeinfo/CMTSIPDetails";

export function CMTSIPBegin(): CMTSIPActionTypes {
  return {
    type: CMTS_IP_BEGIN,
  };
}

export function CMTSIPSuccess(
  CMTSIPDetails: CMTSIPDetails[]
): CMTSIPActionTypes {
  return {
    type: CMTS_IP_SUCCESS,
    payload: CMTSIPDetails,
  };
}

export function CMTSIPFailure(CMTSIPDetails: any): CMTSIPActionTypes {
  return {
    type: CMTS_IP_FAILURE,
    payload: CMTSIPDetails,
  };
}

export function SetCMTSIPDetails(
  CMTSIPDetails: CMTSIPDetails
): CMTSIPActionTypes {
  return {
    type: SET_CMTS_IP_DETAILS,
    payload: CMTSIPDetails,
  };
}

export function CMTSIPReset(): CMTSIPActionTypes {
  return {
    type: RESET_CMTS_IP,
  };
}
