import { Card } from "react-bootstrap";
import { useState, useEffect } from "react";
import CustomTab from "../../reusable/CustomTab";
import { TabPanel } from "../../../screens/account-page/AccountPageTabs";
import OFDMChannelData from "./OFDMChannelData";
import DocsisChInfo from "./DocsisChInfo";
import AppLabels from "../../../constants/App_Labels";
import CustomAccordion from "../../reusable/CustomAccordion";
import CmDSTab from "../../../components/modem/CmDSTab";

const CmDownStreamCh = (props: any) => {
  const labels = AppLabels();
  const { cmDocsisInfo } = props;

  const [tabSelIndex, setTabSelIndex] = useState(0);
  let ofdmDownStreamChannels: any = [];
  if (
    cmDocsisInfo?.hasOwnProperty("ofdm") &&
    cmDocsisInfo?.ofdm?.hasOwnProperty("downstreamChannels")
  ) {
    cmDocsisInfo?.ofdm?.downstreamChannels?.map((dsC: any) => {
      ofdmDownStreamChannels?.push(dsC);
    });
  }
  const [count, setIndex] = useState(
    cmDocsisInfo.docsisVersion === "DOCSIS31"
      ? ofdmDownStreamChannels?.length + 1
      : 1
  );
  // useEffect(() => {
  //   cmDocsisInfo.docsisVersion === "DOCSIS31"
  //     ? ofdmDownStreamChannels?.map((ch: any) => {
  //         setIndex(ofdmDownStreamChannels?.length + 1);
  //         console.log(ofdmDownStreamChannels?.length + 1, "count");
  //       })
  //     : setIndex(2);
  // }, []);

  return (
    <CustomAccordion
      accTitle={labels.DOWNSTREAM_CHANNELS.toUpperCase()}
      accBodyElement={
        <>
          {cmDocsisInfo !== undefined ? (
            <Card className="dsChTabsCard">
              <Card.Header>
                <CustomTab
                  tabType="horizontal"
                  key={0}
                  index={0}
                  value={tabSelIndex}
                  handleSelect={(i: any) => setTabSelIndex(i)}
                  label="DOCSIS 3.0"
                />
                {cmDocsisInfo.docsisVersion === "DOCSIS31" ? (
                  ofdmDownStreamChannels?.map((ch: any, index: number) => {
                    return (
                      <CustomTab
                        tabType="horizontal"
                        key={index + 1}
                        index={index + 1}
                        value={tabSelIndex}
                        handleSelect={(i: any) => setTabSelIndex(i)}
                        label={`OFDM ${index + 1}`}
                      />
                    );
                  })
                ) : (
                  <></>
                )}
                <CustomTab
                  tabType="horizontal"
                  key={count}
                  index={count}
                  value={tabSelIndex}
                  handleSelect={(i: any) => setTabSelIndex(i)}
                  label="SPECTRUM"
                />
              </Card.Header>
              <Card.Body>
                {cmDocsisInfo?.docsisVersion === "DOCSIS31" &&
                  ofdmDownStreamChannels?.length !== 0 ? (
                  ofdmDownStreamChannels?.map((ch: any, index: number) => {
                    return (
                      <TabPanel value={tabSelIndex} index={index + 1} key={index}>
                        <OFDMChannelData ofdmChannel={ch} />
                      </TabPanel>
                    );
                  })
                ) : (
                  <></>
                )}

                {cmDocsisInfo?.hasOwnProperty("docsis30") &&
                  cmDocsisInfo?.docsis30?.hasOwnProperty("downstream") ? (
                  <TabPanel value={tabSelIndex} index={0}>
                    <DocsisChInfo
                      docsis30dsChannels={cmDocsisInfo.docsis30.downstream}
                    />
                  </TabPanel>
                ) : (
                  <></>
                )}
                <TabPanel value={tabSelIndex} index={count}>
                  <CmDSTab cmData={cmDocsisInfo} flag={true} />
                </TabPanel>
              </Card.Body>
            </Card>
          ) : (
            <>NO DATA AVAILABLE...</>
          )}
        </>
      }
    />
  );
};

export default CmDownStreamCh;
