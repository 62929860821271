import { WIFIRadios } from "../../../../models/account/diagnostics/cm/WIFIRadios";

import {
  WIFI_RADIOS_BEGIN,
  WIFI_RADIOS_SUCCESS,
  WIFI_RADIOS_FAILURE,
  WIFIRadiosActionTypes,
} from "../../../../types/account/diagnostics/cm/WIFIRadios";

export function wifiRadiosBegin(): WIFIRadiosActionTypes {
  return {
    type: WIFI_RADIOS_BEGIN,
  };
}

export function wifiRadiosSuccess(
  wifiRadio: WIFIRadios
): WIFIRadiosActionTypes {
  return {
    type: WIFI_RADIOS_SUCCESS,
    payload: wifiRadio,
  };
}

export function wifiRadiosFailure(error: Error): WIFIRadiosActionTypes {
  return {
    type: WIFI_RADIOS_FAILURE,
    payload: error,
  };
}
