import { BiLinkExternal } from "react-icons/bi";

import appConst from "../../../constants/appConst";
import CustomPopOver from "../../../../components/reusable/CustomPopOver";
import TopologyLabels from "../../../constants/topologyLabels";
import colors from "../../../config/colors";
import { FaRegFileImage } from "react-icons/fa";

interface Props {
  activeMapElement: any;
  onClick: any;
}

export default function RasterImageBtn({ activeMapElement, onClick }: Props) {
  const labels = TopologyLabels();

  return (
    <>
      {activeMapElement?.type === appConst.mdu && (
        <CustomPopOver
          popOverData= {labels.owningDrawing}
          index="popOver"
          originComp={
            <FaRegFileImage
                className="basicTableHeaderIcon"
                onClick={onClick}
            />
          }
        />
      )}
    </>
  );
}
