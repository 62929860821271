import { useState, useEffect, useMemo } from "react";
import { GrHistory } from "react-icons/gr";

interface Props {
  storageKey: string;
  historyDisplay: boolean;
  setHistoryDisplay: any;
  searchValue: string;
  onOptionClick: any;
  inputId: string;
}

const InputHistoryBox = ({
  storageKey,
  historyDisplay,
  setHistoryDisplay,
  searchValue,
  onOptionClick,
  inputId,
}: Props) => {
  let suggestions: any = localStorage.getItem(storageKey);
  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    if (historyDisplay) getSearchHistory();
  }, [historyDisplay]);

  const optionData = useMemo(() => {
    let computedOptions = options;
    if (searchValue !== "") {
      computedOptions = computedOptions.filter((option: string) =>
        option?.toLowerCase()?.includes(searchValue?.toLowerCase())
      );
      if (computedOptions?.length === 0) {
        setHistoryDisplay(false);
      } else {
        setHistoryDisplay(true);
      }
    }
    return computedOptions;
  }, [searchValue, options]);

  const getSearchHistory = () => {
    if (suggestions === null) {
      localStorage.setItem(storageKey, JSON.stringify([]));
    } else {
      suggestions = JSON.parse(suggestions);
      setOptions(suggestions.reverse());
    }
  };

  const clearSearchHistory = () => {
    localStorage.setItem(storageKey, JSON.stringify([]));
    getSearchHistory();
  };

  const setFocusOnInput = () => {
    document.getElementById(inputId)?.focus();
  };

  return (
    <div>
      {historyDisplay && (
        <div className="historyContainer">
          <div className="searchContHead">
            <span>Recently searched:</span>
            <span className="clearAllLink" onClick={() => clearSearchHistory()}>
              Clear all
            </span>
          </div>
          <div className="searchOptionCont">
            {optionData?.length !== 0 ? (
              optionData?.map((s: any, i: number) => {
                return (
                  <div
                    onClick={() => {
                      onOptionClick(s);
                      setFocusOnInput();
                    }}
                    key={i}
                    tabIndex={0}
                    className="option"
                  >
                    <GrHistory />
                    <span className="historyVal">{s}</span>
                  </div>
                );
              })
            ) : (
              <div className="option">
                <span className="historyVal">No Search History Found...</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default InputHistoryBox;
