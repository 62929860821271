import { useEffect } from "react";
import { mapElementStyles } from "../../config/mapItemsConfig";

import appConst from "../../constants/appConst";
import { setMapCenter } from "../../store/slices/mapItems/mapDetails";
import { getPngImages } from "../../store/slices/symbols";
import {
  getDataForSymbolRequest,
  getDataForSymbolRequestForLocator,
  getRensSearchType,
  getPowertraceDataForSymbolRequest,
  isSymbolImageAvailable,
  getFiberPointsData,
} from "../utilFuncs";
import {
  GetClamshellSearchProps,
  GetLocatorElementData,
  GetOLTSearchProps,
  GetRENSearchProps,
  GetRbCircuitSearchProps,
  GetSmallCellSearchProps,
  GetVhubSearchProps,
  GetWirelessSiteSearchProps,
  useFiberUpstreamTraceState,
  usePowertraceStates,
} from "../reduxFunctions/getTopologyState";

export const useSymbolsRequestHandler = (
  activeNodeSite: any,
  activeNode: any,
  allNodeData: any,
  pngImages: any,
  dispatch: any
) => {
  const {
    data: vhubSearchData,
    isLoading: vhubIsLoading,
    status: vhubSearchStatus,
  } = GetVhubSearchProps();
  const {
    elements: powertraceElements,
    elementsStatus: powertraceElementsStatus,
    elementsIsLoading: powertraceElementsIsLoading,
  } = usePowertraceStates();
  const {
    data: fiberUsElements,
    status: fiberUsElementsStatus,
    isLoading: fiberUsElementsIsLoading,
  } = useFiberUpstreamTraceState();

  // Locator Element Search symbol States
  const { rensSearchData, rensSearchLoading, rensSearchStatus } =
    GetRENSearchProps();
  const { wirelessSiteData, wirelessSiteLoading, wirelessSiteStatus } =
    GetWirelessSiteSearchProps();
  const { smallCellData, smallCellLoading, smallCellStatus } =
    GetSmallCellSearchProps();
  const { clamshellData, clamshellLoading, clamshellStatus } =
    GetClamshellSearchProps();
  const { rbCircuitData, rbCircuitLoading, rbCircuitStatus } =
    GetRbCircuitSearchProps();
  const { oltData, oltLoading, oltStatus } = GetOLTSearchProps();

  //symbol Handling for nodeSite
  useEffect(() => {
    if (allNodeData && allNodeData?.length > 0) {
      let newFeatures: any = [];
      allNodeData?.map((site: any) => {
        site?.node?.data?.features?.map((siteDetail: any) => {
          let nodeData = siteDetail?.properties?.data;
          if (nodeData) {
            let symbolName =
              nodeData?.symbolName?.replace("-", "")?.toUpperCase() +
              "-" +
              nodeData?.rotation
                ? nodeData?.rotation
                : "0.0" +
                  "-" +
                  mapElementStyles?.nodeSite?.color?.replace("#", "") +
                  ".png";
            if (!isSymbolImageAvailable(symbolName, appConst.node, pngImages)) {
              newFeatures.push(siteDetail);
            }
          }
        });
      });
      if (newFeatures && newFeatures?.length > 0) {
        const requestData = getDataForSymbolRequest(
          pngImages,
          appConst.nodeSite,
          newFeatures
        );
        if (requestData?.length > 0) {
          dispatch(getPngImages(requestData, appConst.node));
        }
      }
    }
  }, [activeNodeSite?.status]);

  //Symbol handling for taps
  useEffect(() => {
    if (
      activeNode?.tap?.isLoading === false &&
      activeNode?.tap?.status === appConst.OK
    ) {
      const requestData = getDataForSymbolRequest(
        pngImages,
        appConst.taps,
        activeNode?.tap?.data?.features
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.taps));
      }
    }
  }, [activeNode?.tap?.status]);

  //Symbol handling for splicecases
  useEffect(() => {
    if (
      activeNode?.fiberSpliceCase?.isLoading === false &&
      activeNode?.fiberSpliceCase?.status === appConst.OK
    ) {
      const requestData = getDataForSymbolRequest(
        pngImages,
        appConst.spliceCases,
        activeNode?.fiberSpliceCase?.data?.features
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.spliceCases));
      }
    }
  }, [activeNode?.fiberSpliceCase?.status]);

  //Symbol handling for Storage and Riser
  useEffect(() => {
    if (
      activeNode?.fiberStorageAndRiser?.isLoading === false &&
      activeNode?.fiberStorageAndRiser?.status === appConst.OK
    ) {
      const requestData = getDataForSymbolRequest(
        pngImages,
        appConst.fiberStorageAndRiser,
        activeNode?.fiberStorageAndRiser?.data?.features
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.fiberStorageAndRiser));
      }
    }
  }, [activeNode?.fiberStorageAndRiser?.status]);

  //Symbol handling for Power Inserter
  useEffect(() => {
    if (
      activeNode?.powerInserter?.isLoading === false &&
      activeNode?.powerInserter?.status === appConst.OK
    ) {
      const requestData = getDataForSymbolRequest(
        pngImages,
        appConst.powerInserter,
        activeNode?.powerInserter?.data?.features
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.powerInserter));
      }
    }
  }, [activeNode?.powerInserter?.status]);

  //Symbol handling for rfsplice
  useEffect(() => {
    if (
      activeNode?.rfSplice?.isLoading === false &&
      activeNode?.rfSplice?.status === appConst.OK
    ) {
      const requestData = getDataForSymbolRequest(
        pngImages,
        appConst.rfSplice,
        activeNode?.rfSplice?.data?.features
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.rfSplice));
      }
    }
  }, [activeNode?.rfSplice?.status]);

  //Symbol handling for activeEquipment
  useEffect(() => {
    if (
      activeNode?.activeEquipment?.isLoading === false &&
      activeNode?.activeEquipment?.status === appConst.OK
    ) {
      const requestData = getDataForSymbolRequest(
        pngImages,
        appConst.activeEquipment,
        activeNode?.activeEquipment?.data?.features
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.activeEquipment));
      }
    }
  }, [activeNode?.activeEquipment?.status]);

  //Symbol handling for terminator
  useEffect(() => {
    if (
      activeNode?.terminator?.isLoading === false &&
      activeNode?.terminator?.status === appConst.OK
    ) {
      const requestData = getDataForSymbolRequest(
        pngImages,
        appConst.terminator,
        activeNode?.terminator?.data?.features
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.terminator));
      }
    }
  }, [activeNode?.terminator?.status]);

  //Symbol handling for splitter
  useEffect(() => {
    if (
      activeNode?.splitter?.isLoading === false &&
      activeNode?.splitter?.status === appConst.OK
    ) {
      const requestData = getDataForSymbolRequest(
        pngImages,
        appConst.splitter,
        activeNode?.splitter?.data?.features
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.splitter));
      }
    }
  }, [activeNode?.splitter?.status]);

  //Symbol handling for Optical Taps
  useEffect(() => {
    if (
      activeNode?.opticalTap?.isLoading === false &&
      activeNode?.opticalTap?.status === appConst.OK
    ) {
      const requestData = getDataForSymbolRequest(
        pngImages,
        appConst.opticalTap,
        activeNode?.opticalTap?.data?.features
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.opticalTap));
      }
    }
  }, [activeNode?.opticalTap?.status]);

  //Symbol handling for powerBlock
  useEffect(() => {
    if (
      activeNode?.powerBlock?.isLoading === false &&
      activeNode?.powerBlock?.status === appConst.OK
    ) {
      const requestData = getDataForSymbolRequest(
        pngImages,
        appConst.powerBlock,
        activeNode?.powerBlock?.data?.features
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.powerBlock));
      }
    }
  }, [activeNode?.powerBlock?.status]);

  //Symbol handling for powerSupply
  useEffect(() => {
    if (
      activeNode?.powerSupply?.isLoading === false &&
      activeNode?.powerSupply?.status === appConst.OK
    ) {
      const requestData = getDataForSymbolRequest(
        pngImages,
        appConst.powerSupply,
        activeNode?.powerSupply?.data?.features
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.powerSupply));
      }
    }
  }, [activeNode?.powerSupply?.status]);

  //Symbol handling for DetailMark
  useEffect(() => {
    if (
      activeNode?.detailMark?.isLoading === false &&
      activeNode?.detailMark?.status === appConst.OK
    ) {
      const requestData = getDataForSymbolRequest(
        pngImages,
        appConst.detailMark,
        activeNode?.detailMark?.data?.features
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.detailMark));
      }
    }
  }, [activeNode?.detailMark?.status]);

  //Symbol handling for Pole
  useEffect(() => {
    if (
      activeNode?.pole?.isLoading === false &&
      activeNode?.pole?.status === appConst.OK
    ) {
      const requestData = getDataForSymbolRequest(
        pngImages,
        appConst.pole,
        activeNode?.pole?.data?.features
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.pole));
      }
    }
  }, [activeNode?.pole?.status]);

  //Symbol handling for CabkeDip
  useEffect(() => {
    if (
      activeNode?.cableDip?.isLoading === false &&
      activeNode?.cableDip?.status === appConst.OK
    ) {
      const requestData = getDataForSymbolRequest(
        pngImages,
        appConst.cableDip,
        activeNode?.cableDip?.data?.features
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.cableDip));
      }
    }
  }, [activeNode?.cableDip?.status]);

  //Symbol handling for olt
  useEffect(() => {
    if (
      activeNode?.olt?.isLoading === false &&
      activeNode?.olt?.status === appConst.OK
    ) {
      const features = activeNode?.olt?.data?.features;
      const requestData = getDataForSymbolRequest(
        pngImages,
        appConst.olt,
        features
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.olt));
      }
    }
  }, [activeNode?.olt?.status]);

  //Symbol handling for lcp
  useEffect(() => {
    if (
      activeNode?.[appConst.LCP]?.isLoading === false &&
      activeNode?.[appConst.LCP]?.status === appConst.OK
    ) {
      const features = activeNode?.[appConst.LCP]?.data?.features;
      const requestData = getDataForSymbolRequest(
        pngImages,
        appConst.LCP,
        features
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.LCP));
      }
    }
  }, [activeNode?.[appConst.LCP]?.status]);

  //Symbol handling for VHUB
  useEffect(() => {
    if (
      activeNode?.vhub?.isLoading === false &&
      activeNode?.vhub?.status === appConst.OK
    ) {
      const features = activeNode?.vhub?.data?.features;
      const requestData = getDataForSymbolRequest(
        pngImages,
        appConst.vhub,
        features
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.vhub));
      }
    }
  }, [activeNode?.vhub?.status]);

  useEffect(() => {
    if (vhubIsLoading === false && vhubSearchStatus === appConst.OK) {
      const requestData = getDataForSymbolRequest(
        pngImages,
        appConst.vhub,
        vhubSearchData?.features
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.vhub));
      }
    }
  }, [vhubSearchStatus]);

  //symbol handling for powertrace elements
  useEffect(() => {
    if (
      powertraceElementsIsLoading === false &&
      powertraceElementsStatus === appConst.OK
    ) {
      const pointsData = powertraceElements?.filter(
        (element: any) => element?.geometry?.type == appConst.Point
      );
      const data = getPowertraceDataForSymbolRequest(pngImages, pointsData);
      Object.keys(data)?.map((e) => {
        if (data?.[e]?.length > 0) {
          dispatch(getPngImages(data?.[e], e));
        }
      });
    }
  }, [powertraceElementsStatus]);

  /*  useEffect(() => {
    if (!rensSearchLoading && rensSearchStatus === appConst.OK) {
      const eleType = getRensSearchType(rensSearchData?.[0]?.sType);
      const requestData = getDataForSymbolRequestForLocator(
        pngImages,
        eleType,
        rensSearchData
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, eleType));
      }
    }
  }, [rensSearchStatus]);
 */
  useEffect(() => {
    if (!wirelessSiteLoading && wirelessSiteStatus === appConst.OK) {
      const requestData = getDataForSymbolRequestForLocator(
        pngImages,
        appConst.wirelessSite,
        wirelessSiteData
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.wirelessSite));
      }
    }
  }, [wirelessSiteStatus]);

  useEffect(() => {
    if (!smallCellLoading && smallCellStatus === appConst.OK) {
      const requestData = getDataForSymbolRequestForLocator(
        pngImages,
        appConst.smallCell,
        smallCellData
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.smallCell));
      }
    }
  }, [smallCellStatus]);

  useEffect(() => {
    if (!clamshellLoading && clamshellStatus === appConst.OK) {
      const requestData = getDataForSymbolRequestForLocator(
        pngImages,
        appConst.clamshell,
        clamshellData
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.clamshell));
      }
    }
  }, [clamshellStatus]);

  useEffect(() => {
    if (rbCircuitLoading === false && rbCircuitStatus === appConst.OK) {
      const requestData = getDataForSymbolRequestForLocator(
        pngImages,
        appConst.rbCircuit,
        rbCircuitData
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.rbCircuit));
      }
    }
  }, [rbCircuitStatus]);

  useEffect(() => {
    if (oltLoading === false && oltStatus === appConst.OK) {
      const requestData = getDataForSymbolRequest(
        pngImages,
        appConst.olt,
        oltData.features
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.olt));
      }
    }
  }, [oltStatus]);

  //symbol handling for fiber upstream trace elements
  useEffect(() => {
    if (
      fiberUsElementsIsLoading === false &&
      fiberUsElementsStatus === appConst.OK
    ) {
      const pointsData = getFiberPointsData(fiberUsElements);
      const data = getPowertraceDataForSymbolRequest(pngImages, pointsData);
      Object.keys(data)?.map((e) => {
        if (data?.[e]?.length > 0) {
          dispatch(getPngImages(data?.[e], e));
        }
      });
    }
  }, [fiberUsElementsStatus]);

  //Symbol handling for ISP Sites
  useEffect(() => {
    if (
      activeNode?.ispSite?.isLoading === false &&
      activeNode?.ispSite?.status === appConst.OK
    ) {
      const requestData = getDataForSymbolRequest(
        pngImages,
        appConst.ispSite,
        activeNode?.ispSite?.data?.features
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.ispSite));
      }
    }
  }, [activeNode?.ispSite?.status]);
};
