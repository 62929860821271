import { KonvaEventObject } from "konva/lib/Node";
import { Circle, Transformer, Wedge } from "react-konva";
import { useRef, useEffect } from "react";

import {
  ICommonDrawingProps,
  IDrawPosition,
  IGeoEditShape,
  getOpacity,
} from "../GeoEditCanvas";

export interface IGeoPieShape extends IGeoEditShape {
  angle: number;
  radius: number;
  strokeWidth: number;
  fill: string | undefined;
}

interface Props {
  pies: IGeoPieShape[];
  setPies: (pie: IGeoPieShape[]) => void;
  cdp: ICommonDrawingProps;
  onClick: ({ id }: { id: string }) => void;
}

export default function Pies({ pies, setPies, cdp, onClick }: Props) {
  const { drawStartPosi, drawEndPosi } = cdp;

  return (
    <>
      {pies?.map((pie) => (
        <Shape
          key={pie.id}
          shape={pie}
          onClick={onClick}
          shapes={pies}
          setShapes={setPies}
          cdp={cdp}
        />
      ))}
      {cdp.drawingType === "pie" && drawStartPosi && drawEndPosi && (
        <Wedge
          angle={calcPieAngle(drawStartPosi, drawEndPosi)}
          radius={calcPieRadius(drawStartPosi, drawEndPosi)}
          x={drawStartPosi.x}
          y={drawStartPosi.y}
          stroke={cdp.color}
          strokeWidth={cdp.strokeWidth}
          fill={cdp.fill}
          opacity={getOpacity(cdp.opacity)}
        />
      )}
    </>
  );
}

interface ShapeProps {
  shape: IGeoPieShape;
  shapes: IGeoPieShape[];
  setShapes: (rect: IGeoPieShape[]) => void;
  cdp: ICommonDrawingProps;
  onClick: ({ id }: { id: string }) => void;
}
const Shape = ({ shape, shapes, setShapes, cdp, onClick }: ShapeProps) => {
  const shapeRef = useRef<any>(null);
  const trRef = useRef<any>(null);
  const { selectedShape, isSelected: isSSelected } = cdp;
  const isSelected = isSSelected({ id: shape.id, shape: "pie" });
  const { x, y } = shape;

  const updateAngle = (newX: number, newY: number) => {
    const newAngle = Math.round(
      Math.atan2(newY - y, newX - x) * (180 / Math.PI)
    );

    // Update your Wedge's angle by updating the shapes array
    const updatedShapes = shapes.map((s) => {
      if (s.id === shape.id) {
        return {
          ...s,
          angle: newAngle,
        };
      }
      return s;
    });
    setShapes(updatedShapes);
  };

  const handleDragStart = (e: KonvaEventObject<DragEvent>) => {
    cdp.setDrawingType("none");
    const id = e.target.id();
    setShapes(shapes.map((s) => ({ ...s, isDragging: s.id === id })));
  };

  const handleDragEnd = () =>
    setShapes(
      shapes.map((s) => {
        // Update position of Wedge here
        if (s.id === shape.id) {
          const node = shapeRef.current;
          return {
            ...s,
            x: node.x(),
            y: node.y(),
            isDragging: false,
          };
        } else {
          return { ...s, isDragging: false };
        }
      })
    );

  useEffect(() => {
    if (isSelected && trRef.current) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
    if (!isSelected && shapeRef.current) {
      shapeRef.current.scaleX(1);
      shapeRef.current.scaleY(1);
    }
  }, [isSelected]);

  const handleTREnd = () => {
    const node = shapeRef.current;
    if (node) {
      const scaleX = node.scaleX();
      node.scaleX(1);
      node.scaleY(1);

      if (selectedShape?.id && selectedShape.shape === "pie") {
        setShapes(
          shapes.map((s) =>
            s.id === selectedShape.id
              ? {
                  ...s,
                  x: node.x(),
                  y: node.y(),
                  radius: Math.round((node.width() * scaleX) / 2),
                  rotation: node.rotation(),
                }
              : s
          )
        );
      }

      trRef.current.getLayer().batchDraw();
    }
  };

  return (
    <>
      <Wedge
        ref={shapeRef}
        angle={shape.angle}
        radius={shape.radius}
        x={shape.x}
        y={shape.y}
        stroke={shape.stroke}
        strokeWidth={shape.strokeWidth}
        fill={shape.fill}
        rotation={shape.rotation}
        opacity={getOpacity(shape.opacity)}
        scaleX={shape.isDragging ? 1.1 : 1}
        scaleY={shape.isDragging ? 1.1 : 1}
        draggable
        onMouseDown={handleDragStart}
        onDragEnd={handleDragEnd}
        onClick={() => onClick({ id: shape.id })}
      />
      {selectedShape && isSelected && (
        <>
          <Transformer
            ref={trRef as any}
            onTransformEnd={handleTREnd}
            enabledAnchors={[
              "top-left",
              "top-right",
              "bottom-left",
              "bottom-right",
            ]}
          />
          <Circle
            x={shape.x + shape.radius * Math.cos((shape.angle * Math.PI) / 180)}
            y={shape.y + shape.radius * Math.sin((shape.angle * Math.PI) / 180)}
            radius={5}
            fill="white"
            draggable
            onDragMove={(e) => {
              const node = e.target;
              updateAngle(node.x(), node.y());
            }}
          />
        </>
      )}
    </>
  );
};

export const calcPieAngle = (
  startPosi: IDrawPosition,
  endPosi: IDrawPosition
) => {
  const dx = endPosi.x - startPosi.x;
  const dy = endPosi.y - startPosi.y;
  return Math.round((Math.atan2(dy, dx) * 180) / Math.PI);
};

export const calcPieRadius = (
  startPosi: IDrawPosition,
  endPosi: IDrawPosition
) =>
  Math.round(
    Math.sqrt((endPosi.x - startPosi.x) ** 2 + (endPosi.y - startPosi.y) ** 2)
  );
