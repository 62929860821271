import {
  NODE_MODEMS_LIST_BEGIN,
  NODE_MODEMS_LIST_FAILURE,
  NODE_MODEMS_LIST_SUCCESS,
  NodeModemsListActionTypes,
} from "../../../types/node/telemetry/NodeModemsList";

export function NodeModemsListBegin(): NodeModemsListActionTypes {
  return {
    type: NODE_MODEMS_LIST_BEGIN,
  };
}

export function NodeModemsListSuccess(
  NodeModemsList: any
): NodeModemsListActionTypes {
  return {
    type: NODE_MODEMS_LIST_SUCCESS,
    payload: NodeModemsList,
  };
}

export function NodeModemsListFailure(error: Error): NodeModemsListActionTypes {
  return {
    type: NODE_MODEMS_LIST_FAILURE,
    payload: error,
  };
}
