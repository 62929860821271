import { Divider } from "@material-ui/core";
import { useState, useMemo, useEffect } from "react";
import { FiFilter } from "react-icons/fi";
import { IoSearch } from "react-icons/io5";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useDispatch } from "react-redux";

import CustomDropDown from "../../../../components/reusable/CustomDropDown";
import DiagContainer from "../../../../components/reusable/DiagContainer";
import InlineErrorMessage from "../../../../components/reusable/InlineErrorMessage";
import AppLabels from "../../../../constants/App_Labels";
import TopologyLabels from "../../../constants/topologyLabels";
import TopologyData from "../../../data/TopologyData";
import {
  setMapCenter,
  setSearchedAddress,
} from "../../../store/slices/mapItems/mapDetails";
import { getAddressSearch } from "../../../store/slices/search/addressSearch";
import { GetAddressSearchProps } from "../../../util/reduxFunctions/getTopologyState";
import { getActiveEleMarker, getOutputAddress } from "../../../util/utilFuncs";

const EleAddressSearch = () => {
  const appLabels = AppLabels();
  const labels = TopologyLabels();
  const dispatch = useDispatch();
  const provOptions = TopologyData()?.provOptions;
  const { data, isLoading, error } = GetAddressSearchProps();
  const [aptNum, setAptNum] = useState("");
  const [streetNum, setStreetNum] = useState("");
  const [streetName, setStreetName] = useState("");
  const [streetType, setStreetType] = useState("");
  const [province, setProvince] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [filterSearchVal, setFilterSearchVal] = useState("");
  const [isSearchInitiated, setIsSearchInitiated] = useState(false);

  const address =
    (aptNum !== "" ? aptNum.toUpperCase() + "-" : "") +
    (streetNum !== "" ? streetNum.toUpperCase() + " " : "") +
    (streetName !== "" ? streetName.toUpperCase() : "") +
    (streetType !== "" ? " " + streetType.toUpperCase() : "") +
    (province !== "" ? ", " + province.toUpperCase() : "") +
    (postalCode !== "" ? " " + postalCode.toUpperCase() : "");

  //set map center if only one record
  useEffect(() => {
    if (isSearchInitiated && data?.length === 1 && data?.[0]?.returnSegment) {
      onResultsItemPress(data?.[0]);
    }
  }, [data]);

  const onSubmit = () => {
    if (streetName?.trim() === "") {
      setErrorMsg(labels.streetName + " " + labels.isRequiredForAddressSearch);
    } else if (streetNum === "") {
      setErrorMsg(labels.StreetNo + " " + labels.isRequiredForAddressSearch);
    } else {
      setErrorMsg("");
      setIsSearchInitiated(true);
      dispatch(
        getAddressSearch({
          searchedAddress: address,
          queryParams: {
            streetName: streetName?.trim(),
            streetNum,
            aptNum,
            postalCode,
            streetType,
            province,
          },
        })
      );
    }
  };

  const onResultsItemPress = (address: any) => {
    let activeElement = getActiveEleMarker(address);
    dispatch(setSearchedAddress(activeElement));
    dispatch(setMapCenter({ lat: address?.lat, lng: address?.lng }));
  };

  const addressData = useMemo(() => {
    let computedData = data;
    if (filterSearchVal !== "" && computedData?.length > 0) {
      computedData = computedData?.filter((address: any) => {
        let fullAddress = getOutputAddress(address);
        return fullAddress
          ?.toLowerCase()
          .includes(filterSearchVal?.toLowerCase());
      });
    }
    return computedData;
  }, [filterSearchVal, data]);

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  return (
    <div style={{ marginTop: 10 }}>
      <label style={{ fontWeight: 600 }}>
        {`${labels.Note}: ${labels.SubscriberAddressesMsg}`}
      </label>
      <div className="addressSearchCont">
        <div className="adSearchInputCont">
          <section>
            <span>
              <label>{labels.ApartmentNo}:</label>
              <input
                type="text"
                value={aptNum}
                onChange={(event: any) => setAptNum(event.target.value?.trim())}
                onKeyPress={(event: any) => handleKeyPress(event)}
              />
            </span>
            <span>
              <label>{labels.StreetNo}*:</label>
              <input
                type="text"
                value={streetNum}
                onChange={(event: any) =>
                  setStreetNum(event.target.value?.trim())
                }
                onKeyPress={(event: any) => handleKeyPress(event)}
              />
            </span>
          </section>
          <section>
            <span>
              <label>{labels.streetName}*:</label>
              <input
                type="text"
                value={streetName}
                onChange={(event: any) => setStreetName(event.target.value)}
                onKeyPress={(event: any) => handleKeyPress(event)}
              />
            </span>
            <span>
              <label>{labels.streetType}:</label>
              <input
                type="text"
                value={streetType}
                onChange={(event: any) =>
                  setStreetType(event.target.value?.trim())
                }
                onKeyPress={(event: any) => handleKeyPress(event)}
              />
            </span>
          </section>
          <section>
            <span>
              <label>{labels.PostalCode}:</label>
              <input
                type="text"
                value={postalCode}
                onChange={(event: any) =>
                  setPostalCode(event.target.value?.trim())
                }
                onKeyPress={(event: any) => handleKeyPress(event)}
              />
            </span>
            <span>
              <label>{labels.province}:</label>
              <CustomDropDown
                selectedOption={province}
                options={provOptions}
                searchFlag={false}
                checkboxFlag={false}
                setSelectedOption={(value: any) => {
                  setProvince(value);
                }}
              />
            </span>
          </section>
          <section className="addSearchBtnCont">
            <p>
              {labels.address?.toUpperCase()}:{address}
            </p>
            <InlineErrorMessage errorMessage={errorMsg} />
            <button className="addSearchBtnCont" onClick={() => onSubmit()}>
              {labels.SEARCH} <IoSearch />
            </button>
          </section>
        </div>
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <>
          <div className="addSearchFilterCont">
            <div className="addSearchFilter">
              <FiFilter />
              <input
                type="text"
                className="addFilterInput"
                placeholder={labels.FilterResultsByAnyValue + "..."}
                onChange={(e) => setFilterSearchVal(e.target.value)}
              />
            </div>
            <p>
              <span>{appLabels.SEARCH_RESULTS}</span>
              <span>{addressData?.length}</span>
            </p>
          </div>
          <DiagContainer
            isLoading={isLoading}
            data={data}
            error={error}
            sectionName={"Address Search"}
            handleRefresh={onSubmit}
          >
            <div className="addSearchResultCont">
              {addressData?.map((address: any, i: number) => {
                return (
                  <p
                    className="addressLink"
                    onClick={() => onResultsItemPress(address)}
                    key={i}
                  >
                    {getOutputAddress(address)}
                    <MdKeyboardArrowRight />
                  </p>
                );
              })}
            </div>
          </DiagContainer>
        </>
      </div>
    </div>
  );
};

export default EleAddressSearch;
