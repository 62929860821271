import { useState } from "react";
import "../../design-tokens/feedback/Feedback.scss";
import CustomPopOver from "../reusable/CustomPopOver";
import { BsBoxArrowUpRight } from "react-icons/bs";
import ExternalLinksPopUp from "./ExternalLinksPopUp";

const ExternalLinkContainer = () => {
  const [modal, setModal] = useState(false);
  const openModal = () => {
    setModal(true);
  };

  const handleSearchClose = () => {
    setModal(false);
  };

  return (
    <div className="search">
      <CustomPopOver
        position="bottom"
        popOverData="External Links"
        originComp={
          <button className="headerIcon sBtn" onClick={openModal}>
            <span className="externalLinkIcon">
              <BsBoxArrowUpRight
                size={20}
                viewBox="0 0 20 20"
                strokeWidth="1"
              />
            </span>
          </button>
        }
        index="popOver"
      />
      <ExternalLinksPopUp show={modal} onHide={handleSearchClose} />
    </div>
  );
};

export default ExternalLinkContainer;
