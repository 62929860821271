import { combineReducers } from "redux";
import techProfile from "./TechProfile";
import topoReducer from "../../topology/store/reducer";
import addressTelemetry from "../../topology/store/slices/mapItems/diagnostics/addressTelemetry";
import NodeBasictelemetry from "../../topology/store/slices/mapItems/diagnostics/nodeBasicTelemetry";
import multiAcc from "../slices/ping/multiDeviceSearchAcc";
import multiMAC from "../slices/ping/multiDeviceSearchMAC";
import multiONT from "../slices/ping/multiDeviceSearchONT";
import pingData from "../slices/ping/pingData";
import traceRouteData from "../slices/ping/traceRoute";
import workOrderDetails from "../slices/workOrder/workOrderDetails";
import workOrderStatusUpdate from "../slices/workOrder/workOrderStatusUpdate";
import nodeReducer from "./node/nodeReducer";
import acctReducer from "./account/acctReducer";
import redirectSource from "../slices/redirectSource";
import PONFluxtelemetry from "../../network/httpclient/gpon/PONFluxClient";
import PONPorttelemetry from "../../network/httpclient/gpon/PONPortClient";
import xleVisible  from "../slices/accountInfo/xleVisible";

const reducerIndex = combineReducers({
  techProfile,
  topologyState: topoReducer,
  woDetails: workOrderDetails,
  woUpdateStatus: workOrderStatusUpdate,
  nodeReducer,
  acctReducer,
  addressTelemetry,
  pingData,
  traceRouteData,
  multiAcc,
  multiMAC,
  multiONT,
  NodeBasictelemetry,
  PONFluxtelemetry,
  PONPorttelemetry,
  redirectSource,
  xleVisible: xleVisible,
});

export default reducerIndex;
