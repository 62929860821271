import { IPTVNetworkState } from "../../../../states/account/diagnostics/iptv/IPTVNetworkState";
import {
  IPTV_NETWORK_BEGIN,
  IPTV_NETWORK_SUCCESS,
  IPTV_NETWORK_FAILURE,
  IptvNetworkActionTypes,
} from "../../../../types/account/diagnostics/iptv/IPTVNetwork";

const initialState: IPTVNetworkState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: "",
};

export function IPTVNetworkReducer(
  state = initialState,
  action: IptvNetworkActionTypes
): IPTVNetworkState {
  switch (action.type) {
    case IPTV_NETWORK_BEGIN:
      return {
        isLoading: true,
      };
    case IPTV_NETWORK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: "OK",
      };
    case IPTV_NETWORK_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: "ERROR",
      };

    default:
      return state;
  }
}
