import {NodesState} from '../../../states/account/node/NodesState';
import {
  NODE_LIST_BEGIN,
  NODE_LIST_FAILURE,
  NODE_LIST_SUCCESS,
  NodesInfoActionTypes,
} from '../../../types/account//node/NodesList';

const initialState: NodesState = {
  isLoading: false,
  data: undefined,
  error: undefined,
  status: '',
};

export function NodeReducer(
  state = initialState,
  action: NodesInfoActionTypes,
): NodesState {
  switch (action.type) {
    case NODE_LIST_BEGIN:
      return {
        isLoading: true,
      };
    case NODE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 'OK',
      };
    case NODE_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: undefined,
        status: 'ERROR',
      };

    default:
      return state;
  }
}
