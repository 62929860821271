import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { useEffect, useRef } from "react";

export default function CustomErrorBox(props: any) {
  const { showVal, bodyElement, handleClose } = props;
  const wrapperRef: any = useRef([]);

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    const { current: wrap } = wrapperRef;
    if (wrap && wrap?.length !== 0 && !wrap?.contains(event.target)) {
      handleClose();
    }
  };

  return (
    <Collapse ref={wrapperRef} in={showVal}>
      <Alert
        className="errorAlert"
        severity="error"
        action={
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        }
      >
        <div className="errorMsg">{bodyElement}</div>
      </Alert>
    </Collapse>
  );
}
