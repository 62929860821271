import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Dropdown, DropdownButton, Form } from "react-bootstrap";
import { Divider } from "@material-ui/core";

import AppLabels from "../../../../constants/App_Labels";
import { GetFormInfo } from "../../../../get-api/GetResponse";
import EmailEntry from "../../../reusable/EmailEntry";
import ImageUpload from "../../../reusable/ImageUpload";
import ActivityIndicator from "../../../reusable/ActivityIndicator";
import ConfigConst from "../../../../constants/ConfigConst";
import FormClient from "../../../../network/httpclient/form/FormClient";
import ResponseSnackBar from "../../../reusable/ResponseSnackBar";
import FormsHistoryClient from "../../../../network/httpclient/history/FormsHistoryClient";
import { getDate } from "../../../reusable/Util";
import RedAsterisk from "../../../reusable/RedAsterisk";

const TextInput = ({ label, placeholder, value, onChange, rows }: any) => (
  <Form.Group>
    <label className="txtFieldLabel">
      {label}
      <RedAsterisk />
    </label>
    <Form.Control
      onChange={(e) => onChange(e.target.value)}
      as="textarea"
      rows={rows ? rows : 1}
      maxLength={500}
      value={value}
      placeholder={placeholder}
    />
  </Form.Group>
);

const DropdownMenu = ({ label, data, value, onSelect }: any) => (
  <div className="dropdownContainer">
    <label className="labelTxt">
      {label}
      <RedAsterisk />
    </label>
    <DropdownButton
      title={value}
      onSelect={(v: any) => onSelect(v)}
      size="sm"
      id="dropdownBtn"
    >
      {data.map((e: any, i: number) => (
        <Dropdown.Item key={i} eventKey={e}>
          {e}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  </div>
);

interface Props {
  data: any;
  setOpenForm: any;
  isSeniorFormComplete: boolean;
}

export default function SeniorTechDetailsScreen({
  data,
  setOpenForm,
  isSeniorFormComplete,
}: Props) {
  const formData = data?.formData;
  const labels = AppLabels();
  const dispatch = useDispatch();
  const formInfo = GetFormInfo();
  const referralStatusData = [ConfigConst.Resolved, ConfigConst.Unresolved];
  const customerSatisfactionData = [ConfigConst.Yes, ConfigConst.No];

  const [referralStatus, setReferralStatus] = useState(
    formData?.referralStatus ? formData?.referralStatus : labels.Select
  );
  const [seniorTechID, setSeniorTechID] = useState(formData?.seniorTechID);
  const [seniorTechName, setSeniorTechName] = useState(
    formData?.seniorTechName
  );
  const [emails, setEmails] = useState(
    formData?.emails ? formData?.emails : [""]
  );
  const [customerSatisfaction, setCustomerSatisfaction] = useState(
    formData?.customerSatisfaction
      ? formData?.customerSatisfaction
      : labels.Select
  );
  const [seniorTechNotes, setSeniorTechNotes] = useState(
    formData?.seniorTechNotes
  );
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [images, setImages] = useState<File[]>(
    formData?.images ? formData?.images : ""
  );
  const [bannerVisible, setBannerVisible] = useState(false);

  //show banner for 2 sec then close the entire modal
  useEffect(() => {
    if (
      bannerVisible &&
      !formInfo.isLoading &&
      formInfo?.status === ConfigConst.OK
    ) {
      setTimeout(() => {
        //get latest forms history
        dispatch(
          FormsHistoryClient.getHistory(
            "",
            "",
            data?.accountNumber,
            "",
            "",
            getDate("yesterday"),
            getDate("now"),
            "",
            25,
            0,
            ""
          )
        );
        setOpenForm(false);
      }, 2000);
    } else if (!bannerVisible && formInfo.isLoading) {
      setBannerVisible(true);
    }
  }, [formInfo.isLoading]);

  const onSubmit = () => {
    const generateData = {
      formName: ConfigConst.SENIOR_REFERRAL,
      workorderId: data?.workOrderId,
      accountNumber: data?.accountNumber,
      formData: JSON.stringify({
        ...formData,
        referralStatus,
        seniorTechID,
        seniorTechName,
        emails,
        customerSatisfaction,
        seniorTechNotes,
        images,
      }),
    };
    dispatch(FormClient.updateForm(generateData, data?.id));
  };

  const disableSubmit = () => {
    if (
      !formInfo.isLoading &&
      isEmailValid &&
      referralStatus !== labels.Select &&
      seniorTechID &&
      seniorTechName &&
      emails?.length > 0 &&
      customerSatisfaction !== labels.Select &&
      seniorTechNotes
    ) {
      return false;
    } else return true;
  };

  return (
    <>
      <DropdownMenu
        label={labels.Referral_Status}
        data={referralStatusData}
        value={referralStatus}
        onSelect={setReferralStatus}
      />
      <TextInput
        label={labels.Senior_Tech_IDs}
        placeholder={labels.Senior_Tech_IDs_Placeholder}
        value={seniorTechID}
        onChange={setSeniorTechID}
      />
      <TextInput
        label={labels.Senior_Tech_Names}
        placeholder={labels.Senior_Tech_Names_Placeholder}
        value={seniorTechName}
        onChange={setSeniorTechName}
      />
      <EmailEntry
        title={
          (
            <RedAsterisk textToLeft={labels.Senior_Tech_Email_Addresses} />
          ) as unknown as string
        }
        email={emails}
        setEmail={setEmails}
        setIsEmailValid={setIsEmailValid}
      />
      <ImageUpload
        title={labels.Attach_Images}
        image={images}
        onImageChange={setImages}
      />
      <DropdownMenu
        label={labels.Is_the_customer_satisfied_with_the_outcome}
        data={customerSatisfactionData}
        value={customerSatisfaction}
        onSelect={setCustomerSatisfaction}
      />
      <TextInput
        label={labels.Senior_Tech_Notes_And_Root_Cause_Details}
        placeholder={`${labels.Enter} ${labels.Senior_Tech_Notes_And_Root_Cause_Details}...`}
        value={seniorTechNotes}
        onChange={setSeniorTechNotes}
        rows={3}
      />
      <div className="submitBtnContainer">
        {formInfo?.isLoading ? (
          <ActivityIndicator
            style={{ width: "20%", height: "20%" }}
            animationAlignement="none"
          />
        ) : (
          <Button
            onClick={onSubmit}
            disabled={disableSubmit()}
            className="submitButton"
          >
            {isSeniorFormComplete ? labels.Update.toUpperCase() : labels.SUBMIT}
          </Button>
        )}
      </div>
      <Divider style={{ margin: "30px 0px 30px 0px" }} />
      <ResponseSnackBar
        open={bannerVisible}
        requestType={labels.Senior_Referral_Form}
        handleClose={setBannerVisible}
        responseType={formInfo?.status}
      />
    </>
  );
}
