import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "settings",
  initialState: {
    drawerAutoClose: false,
  },
  reducers: {
    setDrawerAutoClose: (state, { payload }) => {
      state.drawerAutoClose = payload;
    },
  },
});

export const { setDrawerAutoClose } = slice.actions;
export default slice.reducer;
