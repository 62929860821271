import AppLabels from "../../../constants/App_Labels";
// in this component we applied inline CSS due to export purpose
//please do not follow the same in other components as its not a good practice
interface Props {
  woID: string;
  //workDetails: any;
  woType: string;
  aspDets: any;
  equipment: string;
}

const DisplayEmailPreviewASP = (props: Props) => {
  const labels = AppLabels() as any;

  const checkquantitySTB = (stb: String) => {
    let sample = "";
    if (props.equipment.includes("STB") && stb !== undefined) {
      sample = sample + "STB:" + props?.aspDets?.stbquantity;
    }
    return sample;
  };

  const checkquantityFreePod = (fp: String) => {
    let sample = "";
    if (props?.equipment?.includes("Free Pod") && fp !== undefined) {
      sample = sample + "Free Pod:" + props?.aspDets?.freepodquantity;
    }
    return sample;
  };

  const checkquantityPaidPod = (pp: String) => {
    let sample = "";
    if (props?.equipment?.includes("Paid Pod") && pp !== undefined) {
      sample = sample + "Paid Pod:" + props?.aspDets?.paidpodquantity;
    }
    return sample;
  };
  /* const checkquantity = (fp: String ,pp: String, stb:String) => {
    let sample=""
    if(pp !== undefined && fp === undefined && stb === undefined || pp !== undefined && fp === "" && stb === "") 
    {
     sample=sample+"Paid Pod:"+props?.aspDets?.paidpodquantity
    }
    else if(pp === undefined && fp !== undefined && stb === undefined || pp === "" && fp !== undefined && stb === "")
    {
      sample=sample+"Free Pod:"+props?.aspDets?.freepodquantity
    }
    else if(pp === undefined && fp === undefined && stb !== undefined || pp === "" && fp === "" && stb !== undefined) 
    {
      sample=sample+"STB:"+props?.aspDets?.stbquantity
    }
    else if(stb!== undefined && pp!== undefined && fp === undefined || stb!== undefined && pp!== undefined && fp === "" ) 
    {
      sample=sample+"STB:"+props?.aspDets?.stbquantity+","+"Paid Pod:"+props?.aspDets?.paidpodquantity
    }
    else if(stb !== undefined && fp !== undefined && pp === undefined || stb !== undefined && fp !== undefined && pp === "") 
    {
      sample=sample+"STB:"+props?.aspDets?.stbquantity+","+"Free Pod:"+props?.aspDets?.freepodquantity
    }
    else if(fp !== undefined && pp !== undefined && stb === undefined || fp !== undefined && pp !== undefined && stb === "" ) 
    {
      sample=sample+"Paid Pod:"+props?.aspDets?.paidpodquantity+","+"Free Pod:"+props?.aspDets?.freepodquantity
    }
    else if(fp !== undefined && pp !== undefined && stb !== undefined || fp !== "" && pp !== "" && stb !== "" ) 
    {
      sample=sample+"Paid Pod:"+props?.aspDets?.paidpodquantity+","+"Free Pod:"+props?.aspDets?.freepodquantity+","+"STB:"+props?.aspDets?.stbquantity
    }
    return sample
  };

*/

  const serialNumberSTBCheck = (stb: String) => {
    let sample = "";
    if (props?.equipment?.includes("STB")) {
      if (stb === undefined) {
        sample = "";
      } else if (stb === "1") {
        sample = sample + "STB:" + props?.aspDets?.quantitytextbox1 + ",";
      } else if (stb === "2") {
        sample =
          sample +
          "STB:" +
          props?.aspDets?.quantitytextbox1 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox3 +
          ",";
      } else if (stb === "3") {
        sample =
          sample +
          "STB:" +
          props?.aspDets?.quantitytextbox1 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox3 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox5 +
          ",";
      } else if (stb === "4") {
        sample =
          sample +
          "STB:" +
          props?.aspDets?.quantitytextbox1 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox3 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox5 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox7 +
          ",";
      } else if (stb === "5") {
        sample =
          sample +
          "STB:" +
          props?.aspDets?.quantitytextbox1 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox3 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox5 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox7 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox25 +
          ",";
      } else if (stb === "6") {
        sample =
          sample +
          "STB:" +
          props?.aspDets?.quantitytextbox1 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox3 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox5 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox7 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox25 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox27 +
          ",";
      } else if (stb === "7") {
        sample =
          sample +
          "STB:" +
          props?.aspDets?.quantitytextbox1 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox3 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox5 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox7 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox25 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox27 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox29 +
          ",";
      } else if (stb === "8") {
        sample =
          sample +
          "STB:" +
          props?.aspDets?.quantitytextbox1 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox3 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox5 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox7 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox25 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox27 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox29 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox31 +
          ",";
      } else if (stb === "9") {
        sample =
          sample +
          "STB:" +
          props?.aspDets?.quantitytextbox1 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox3 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox5 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox7 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox25 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox27 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox29 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox31 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox33 +
          ",";
      } else if (stb === "10") {
        sample =
          sample +
          "STB:" +
          props?.aspDets?.quantitytextbox1 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox3 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox5 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox7 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox25 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox27 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox29 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox31 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox33 +
          "," +
          "STB:" +
          props?.aspDets?.quantitytextbox35 +
          ",";
      }
      return sample;
    }
  };

  const serialNumberPaidPodCheck = (pp: String) => {
    let sample = "";
    if (props?.equipment?.includes("Paid Pod")) {
      if (pp == undefined) {
        sample = "";
      } else if (pp === "1") {
        sample = sample + "Paid Pod:" + props?.aspDets?.quantitytextbox17 + ",";
      } else if (pp === "2") {
        sample =
          sample +
          "Paid Pod:" +
          props?.aspDets?.quantitytextbox17 +
          "," +
          "Paid Pod:" +
          props?.aspDets?.quantitytextbox19 +
          ",";
      } else if (pp === "3") {
        sample =
          sample +
          "Paid Pod:" +
          props?.aspDets?.quantitytextbox17 +
          "," +
          "Paid Pod:" +
          props?.aspDets?.quantitytextbox19 +
          "," +
          "Paid Pod:" +
          props?.aspDets?.quantitytextbox21 +
          ",";
      } else if (pp === "4") {
        sample =
          sample +
          "Paid Pod:" +
          props?.aspDets?.quantitytextbox17 +
          "," +
          "Paid Pod:" +
          props?.aspDets?.quantitytextbox19 +
          "," +
          "Paid Pod:" +
          props?.aspDets?.quantitytextbox21 +
          "," +
          "Paid Pod:" +
          props?.aspDets?.quantitytextbox23 +
          ",";
      }
      return sample;
    }
  };

  const serialNumberFreePodCheck = (fp: String) => {
    let sample = "";
    if (props?.equipment?.includes("Free Pod")) {
      if (fp === undefined) {
        sample = "";
      } else if (fp === "1") {
        sample = sample + "Free Pod:" + props?.aspDets?.quantitytextbox9 + ",";
      } else if (fp === "2") {
        sample =
          sample +
          "Free Pod:" +
          props?.aspDets?.quantitytextbox9 +
          "," +
          "Free Pod:" +
          props?.aspDets?.quantitytextbox11 +
          ",";
      } else if (fp === "3") {
        sample =
          sample +
          "Free Pod:" +
          props?.aspDets?.quantitytextbox9 +
          "," +
          "Free Pod:" +
          props?.aspDets?.quantitytextbox11 +
          "," +
          "Free Pod:" +
          props?.aspDets?.quantitytextbox13 +
          ",";
      } else if (fp === "4") {
        sample =
          sample +
          "Free Pod:" +
          props?.aspDets?.quantitytextbox9 +
          "," +
          "Free Pod:" +
          props?.aspDets?.quantitytextbox11 +
          "," +
          "Free Pod:" +
          props?.aspDets?.quantitytextbox13 +
          "," +
          "Free Pod:" +
          props?.aspDets?.quantitytextbox15 +
          ",";
      }
      return sample;
    }
  };

  return (
    <div
      style={{
        display: "inline-block",
        fontWeight: 500,
        fontFamily: "Ted Next",
      }}
    >
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          {labels.Work_Order_ID}:
        </span>
        <span>{props.woID}</span>
      </div>
      {props.woType !== "" && (
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              marginRight: "10px",
            }}
          >
            {labels.Order_Type}:
          </span>
          <span>{props.woType}</span>
        </div>
      )}

      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          Last 4 Digit Account Number
        </span>
        <span>
          {props?.aspDets?.accountNumber &&
            (props?.aspDets?.accountNumber.length == 9 ||
              props?.aspDets?.accountNumber.length == 12) &&
            props?.aspDets?.accountNumber.substr(
              props?.aspDets?.accountNumber.length - 4,
              props?.aspDets?.accountNumber.length
            )}
        </span>
      </div>

      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          Pod Model
        </span>
        <span>{props?.aspDets?.podtype}</span>
      </div>

      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          Quantity for Free Pod, Paid Pod and STB as follows:
        </span>
        {checkquantitySTB(props?.aspDets?.stbquantity) +
          checkquantityFreePod(props?.aspDets?.freepodquantity) +
          checkquantityPaidPod(props?.aspDets?.paidpodquantity)}
      </div>

      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          Serial Number for STB:
        </span>
        <span>{serialNumberSTBCheck(props?.aspDets?.stbquantity)}</span>
      </div>

      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          Serial Number for Free Pod:
        </span>
        <span>{serialNumberFreePodCheck(props?.aspDets?.freepodquantity)}</span>
      </div>

      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            marginRight: "10px",
          }}
        >
          Serial Number for Paid Pod:
        </span>
        <span>{serialNumberPaidPodCheck(props?.aspDets?.paidpodquantity)}</span>
      </div>

      {props?.aspDets?.distroEmails?.length !== 0 && (
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              marginRight: "10px",
            }}
          >
            Distribution Email(s)
          </span>
          <ul>
            {props?.aspDets?.distroEmails?.map((e: any, i: number) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </div>
      )}

      <div
        style={{
          marginBottom: "10px",
        }}
      ></div>
      <div
        style={{
          marginBottom: "10px",
        }}
      ></div>
    </div>
  );
};

export default DisplayEmailPreviewASP;
