import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CustomAccordion from "../../../../components/reusable/CustomAccordion";
import GoogleMapDirectionsBtn from "../../../../components/reusable/GoogleMapDirectionsBtn";
import { GetAvailableTR, isNull } from "../../../../components/reusable/Util";
import TopologyLabels from "../../../constants/topologyLabels";
import AccDetailsClient from "../../../../network/httpclient/accountInfo/AccDetailsClient";
import { openNewWin } from "../../../util/utilFuncs";
import { useEffect } from "react";

interface Props {
  elementData: any;
}

export default function WorkOrdersTable({ elementData }: Props) {
  const labels = TopologyLabels();
  const dispatch = useDispatch();
  const { accInfo } = useSelector(
    (state: any) => ({
      accInfo: state.AccountState.AccountDetails?.data,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (
      accInfo !== undefined &&
      accInfo?.cbpId === elementData?.data?.accountNumber
    ) {
      openNewWin({
        link: "account?accountNumber=" + accInfo?.accountNumber,
        tabTitle: "Account-" + accInfo?.accountNumber,
      });
    }
  }, [accInfo]);
  const onAccountNumberClick = async (accNumber: string) => {
    if (accNumber !== undefined && accNumber?.length === 9) {
      dispatch(AccDetailsClient.getAccDetails(accNumber, "cbpId"));
    } else if (accNumber !== undefined && accNumber?.length === 12) {
      dispatch(AccDetailsClient.getAccDetails(accNumber, "accountNumber"));
      openNewWin({
        link: "account?accountNumber=" + accNumber,
        tabTitle: "Account-" + accNumber,
      });
    }
  };
  return (
    <>
      {elementData && (
        <div className="activeMapEleCont">
          <CustomAccordion
            accTitle={
              labels?.workOrder?.toUpperCase() +
              " - " +
              elementData?.data?.status?.toUpperCase()
            }
            headerComp={
              <GoogleMapDirectionsBtn
                lat={elementData?.coord?.lat}
                lng={elementData?.coord?.lng}
              />
            }
            accBodyElement={
              <table className="dataTableDetails">
                <thead>
                  <tr>
                    <td>{labels?.name.toUpperCase()}</td>
                    <td>{labels?.Value.toUpperCase()}</td>
                  </tr>
                </thead>
                <tbody>
                  {elementData?.data?.accountNumber && (
                    <tr className="customTRCell">
                      <td>{labels.accountNumber?.toUpperCase()}</td>
                      <td>
                        <section>
                          <span
                            onClick={() => {
                              onAccountNumberClick(
                                elementData?.data?.accountNumber
                              );
                            }}
                          >
                            {isNull(elementData?.data?.accountNumber)}
                          </span>
                        </section>
                      </td>
                    </tr>
                  )}
                  <GetAvailableTR
                    name={labels.id.toUpperCase()}
                    value={elementData?.data?.id}
                  />
                  <GetAvailableTR
                    name={labels.shubId.toUpperCase()}
                    value={elementData?.data?.shubId}
                  />
                  <GetAvailableTR
                    name={labels.smtId.toUpperCase()}
                    value={elementData?.data?.smtId}
                  />
                  <GetAvailableTR
                    name={labels.address.toUpperCase()}
                    value={elementData?.data?.address}
                  />
                  <GetAvailableTR
                    name={labels.customerName.toUpperCase()}
                    value={elementData?.data?.customerName}
                  />
                  <GetAvailableTR
                    name={labels.customerContactNumber.toUpperCase()}
                    value={elementData?.data?.customerContactNumber}
                  />
                  <GetAvailableTR
                    name={labels.specialInstructions.toUpperCase()}
                    value={elementData?.data?.specialInstructions}
                  />
                  <GetAvailableTR
                    name={labels.appointmentTime.toUpperCase()}
                    value={elementData?.data?.appointmentTime}
                  />
                  <GetAvailableTR
                    name={labels.callCreated.toUpperCase()}
                    value={elementData?.data?.callCreated}
                  />
                  <GetAvailableTR
                    name={labels.callAssigned.toUpperCase()}
                    value={elementData?.data?.callAssigned}
                  />
                </tbody>
              </table>
            }
          />
        </div>
      )}
    </>
  );
}
