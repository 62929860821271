import { useEffect, useState } from "react";
import { MdModeEdit, MdDelete } from "react-icons/md";
import { useDispatch } from "react-redux";
import { format } from "date-fns";

import appConst from "../../../constants/appConst";
import TopologyLabels from "../../../constants/topologyLabels";
import DeleteAlertModal from "../../reusable/DeleteAlertModal";
import AddMessageModal from "../../reusable/AddMessageModal";
import ExceptionHandlerWrapper from "../../reusable/ExceptionHandlerWrapper";
import {
  resetCrudData,
  updateDeleteMemo,
} from "../../../store/slices/mapItems/memo/memo";
import { GetMemoProps } from "../../../util/reduxFunctions/getTopologyState";
import {
  deleteMemoSuccessData,
  updateMemoSuccessData,
} from "../../../store/slices/mapItems/node/nodeSiteDetail";

interface props {
  elementData: any;
}

const SelectedMemo = ({ elementData }: props) => {
  const labels = TopologyLabels();
  const dispatch = useDispatch();
  const { memoCrudStatus, memoCrudData, memoCrudType } = GetMemoProps();

  const [deleteModal, setDeleteModal] = useState(false);
  const [isUpdateMemoPopupVisible, setUpdateMemoPopupVisible] = useState(false);
  const [currentData, setCurrentData] = useState(elementData);
  const data = currentData?.properties?.data;
  const geometry = currentData?.geometry?.coordinates;
  const [memoInputTxt, setMemoInputTxt] = useState(
    data?.comment ? data?.comment : ""
  );

  useEffect(() => {
    setMemoInputTxt(elementData?.properties?.data?.comment);
    setCurrentData(elementData);
  }, [elementData]);

  useEffect(() => {
    if (
      memoCrudStatus !== undefined &&
      memoCrudStatus !== "" &&
      (memoCrudType === appConst.update || memoCrudType === appConst.delete) &&
      memoCrudStatus === appConst.OK
    ) {
      if (memoCrudType === appConst.update) {
        const updatedData = memoCrudData?.data?.features?.[0];
        dispatch(
          updateMemoSuccessData({
            data: updatedData,
            rootNodeSiteId: currentData?.properties?.rootNodeSiteId,
          })
        );

        setMemoInputTxt(updatedData?.properties?.data?.comment);
        setCurrentData({
          ...currentData,
          properties: {
            ...currentData?.properties,
            data: updatedData?.properties?.data,
          },
        });
      } else if (memoCrudType === appConst.delete) {
        dispatch(
          deleteMemoSuccessData({
            data: currentData,
            rootNodeSiteId: currentData?.properties?.rootNodeSiteId,
          })
        );
        setCurrentData("");
      }
    }
    return () => {
      dispatch(resetCrudData());
    };
  }, [memoCrudStatus]);

  const onDeleteClick = () => {
    setDeleteModal(false);
    dispatch(
      updateDeleteMemo(appConst.delete, data?.networkId, data?.commentId, {})
    );
  };

  const onUpdateClick = (memo: any) => {
    setUpdateMemoPopupVisible(false);
    dispatch(
      updateDeleteMemo(appConst.update, data?.networkId, data?.commentId, {
        latitude: geometry?.[1],
        longitude: geometry?.[0],
        comment: memo,
      })
    );
  };

  return (
    <>
      <ExceptionHandlerWrapper
        data={currentData}
        status={currentData === "" ? "ERROR" : "OK"}
      >
        <div className="nodeCommentCardDetail">
          <div className="memoHeader">
            <span>{data?.updateBy?.split("@")?.[0]}</span>
            <span>
              {data?.updateDate && (
                <span>
                  {format(data?.updateDate, appConst.dateTimeFormatForComments)}
                </span>
              )}
            </span>
          </div>
          <div className="memoDescription" id={`view-${data?.id}`}>
            <span>{data?.comment}</span>
          </div>

          <div className="commentsFeatures">
            <div className="editDeleteButton">
              <button
                className="editButton"
                onClick={() => {
                  setUpdateMemoPopupVisible(true);
                }}
              >
                <MdModeEdit />
              </button>
              <button
                className="deleteButton"
                onClick={() => {
                  setDeleteModal(true);
                }}
              >
                <MdDelete />
              </button>
            </div>
          </div>
        </div>
      </ExceptionHandlerWrapper>

      <AddMessageModal
        isShown={isUpdateMemoPopupVisible}
        leftBtnText={labels.cancel}
        rightBtnText={labels.update}
        headerText={labels.editMemo}
        placeholderTxt={`${labels.typeMemoHere} (${labels.max1000CharsAllowed})`}
        errorMessage={labels.pleaseAddMemo}
        inputTxt={memoInputTxt}
        setInputTxt={setMemoInputTxt}
        onLeftClick={() => setUpdateMemoPopupVisible(false)}
        onRightClick={onUpdateClick}
      />
      <DeleteAlertModal
        isShown={deleteModal}
        setDeleteModal={setDeleteModal}
        onDeleteClick={onDeleteClick}
        headerText={labels.deleteMemo}
        alertMessage={labels.deleteMemoWarning}
      />
    </>
  );
};

export default SelectedMemo;
