import { STBSummary } from "../../../../models/account/diagnostics/stb/STBSummary";

export const STB_CERTI_SUMMARY_BEGIN = "STB_CERTI_SUMMARY_BEGIN";
export const STB_CERTI_SUMMARY_SUCCESS = "STB_CERTI_SUMMARY_SUCCESS";
export const STB_CERTI_SUMMARY_FAILURE = "STB_CERTI_SUMMARY_FAILURE";

interface StbCertiSummaryBegin {
  type: typeof STB_CERTI_SUMMARY_BEGIN;
}

interface StbCertiSummarySuccess {
  type: typeof STB_CERTI_SUMMARY_SUCCESS;
  payload: STBSummary;
}

interface StbCertiSummaryFailure {
  type: typeof STB_CERTI_SUMMARY_FAILURE;
  payload: Error;
}

export type StbCertiSummaryActionTypes =
  | StbCertiSummaryBegin
  | StbCertiSummarySuccess
  | StbCertiSummaryFailure;
