import { useState } from "react";

import AppLabels from "../../constants/App_Labels";
import "../../design-tokens/account-page/FailedAttributes.scss";
import { renderAttrTable } from "../reusable/Util";
import { getCmMarginalPassAttributes } from "../../config/FailedAttributes";
import CustomAccordion from "../reusable/CustomAccordion";
import NextBestAction from "../diagnostics/NextBestAction";
import CustomPopUp from "../reusable/CustomPopUp";

const MarginalPassAttributesCm = (props: any) => {
  const { cmDocsisInfo } = props;
  const labels = AppLabels();

  const [popUpFlag, setPopUpFlag] = useState(false);
  const [renderedItem, setRenderedItem] = useState<{ name: any; value: any; label: any; } | null>(null);
  const arr = getCmMarginalPassAttributes(cmDocsisInfo);

  const renderPopUp = (el: { name: string; value: any; label: any }) => {
    return (
      <>
        <CustomAccordion
          accTitle={labels.marginallyPassedAttributes?.toUpperCase()}
          accBodyElement={renderAttrTable(el)}
        />
        <CustomAccordion
          accTitle={labels.nextBestActions?.toUpperCase()}
          accBodyElement={<NextBestAction data={el} status={"Marginal Pass"} />}
        />
      </>
    );
  };

  return (
    <div className="faCmLink">
      <p>
        <span style={{ fontWeight: 600 }}> {labels.marginallyPassedAttributes?.toUpperCase()} : </span>
        {arr && (
          arr?.map((el, index) => {
            return (
              <span
                key={index}
                className="tooltipText"
                onClick={() => {
                  setRenderedItem(el);
                  setPopUpFlag(true);
                }}>
                {el?.name},{" "}
              </span>
            );
          })
        )}
      </p>
      <CustomPopUp
        className="nbaPopUp"
        showVal={popUpFlag}
        headerText={labels.failedAttributes}
        bodyElement={renderedItem ? renderPopUp(renderedItem) : <></>}
        handleClose={() => {
          setPopUpFlag(false)
        }}
        buttonDisabled={true}
      />
    </div>
  );
};
export default MarginalPassAttributesCm;
