import { Snackbar } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { CSSProperties } from "react";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface Props {
  open: boolean;
  setOpen?: any;
  handleClose: any;
  error: string;
  style?: CSSProperties;
}

const ErrorBox = ({ open, handleClose, error, style, setOpen }: Props) => {
  const renderAlert = () => {
    return (
      <Alert onClose={handleClose} severity="error">
        {error}.
      </Alert>
    );
  };
  return (
    <div className="snackBarCont">
      <Snackbar
        className="snackBar"
        style={style}
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        {renderAlert()}
      </Snackbar>
    </div>
  );
};

export default ErrorBox;
