import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { TowerViewState } from "../../../store/states/account/towerview/TowerViewState";
import {
  towerViewBegin,
  towerViewFailure,
  towerViewSuccess,
} from "../../../store/actions/account/towerview/TowerViewAction";
import { TowerView } from "../../../store/models/account/towerview/TowerView";

export default class TowerViewClient {
  static getTowerViewDetail =
    (
      eNBId: String
    ): ThunkAction<void, TowerViewState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "diag/wireless/tower/" + eNBId,
      };
      dispatch(towerViewBegin());

      HttpClient.get<TowerView>(httpParameters)
        .then((towerViewDetail) => {
          dispatch(towerViewSuccess(towerViewDetail));
          return towerViewDetail;
        })
        .catch((error) => {
          dispatch(towerViewFailure(error));
          return error;
        });
    };
}
