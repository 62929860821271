import * as React from "react";

import { gponTableConfig } from "../../constants/TelePrefConfig";
import { getTelemetryHomeAddress } from "../../components/reusable/NodeUtil";
import { GetGponNodeDetails } from "../../get-api/GetResponse";
import { GponNodeSummary } from "../../store/models/gpon/GponNodeSummary";
import { GponTelemetryTable } from "./components/GponTelemetryTable";
import AppLabels from "../../constants/App_Labels";
import { ExportCsv } from "./components/ExportCsv";
import CustomIcons from "../../components/reusable/CustomIcons";

type Props = {
  children?: React.ReactNode;
  nodeSummary?: any;
  open?: boolean;
  setOpen?: (open: boolean) => void;
};

export const GponTable = (props: Props) => {
  const { open, setOpen } = props;
  const labels = AppLabels();
  const { nodeInfo, nodeSummaryList } = GetGponNodeDetails();

  const [tableData, setTableData] = React.useState<GponNodeSummary[]>([]);
  const [filteredData, setFilteredData] = React.useState<any[]>([]);
  const [disabled, setDisabled] = React.useState(true);

  const onClickExternalLink = (rowValue: any, column: string) => {
    if (
      column?.trim()?.toLowerCase() === "ontSerialNumber".trim().toLowerCase()
    ) {
      let newWindow = window.open(`${window.location.origin}#/`, "_blank")!;
      newWindow?.addEventListener("load", () => {
        newWindow?.postMessage(
          { rowValue, column: "ontSerialNumber" },
          newWindow.origin
        );
      });
    }

    if (column?.trim()?.toLowerCase() === "oltName".trim().toLowerCase()) {
      // let newWindow = window.open(
      //   `${window.location.origin}#/` + `${"node?oltName=" + rowValue}`,
      //   "_blank"
      // )!;
      // newWindow.onload = () => {
      //   newWindow.document.title = "OLT-" + rowValue;
      // };
      // newWindow?.addEventListener("load", () => {
      //   newWindow?.postMessage(
      //     { rowValue, column: "oltName" },
      //     newWindow.origin
      //   );
      // });
      let newWindow = window.open(
        `/#/ponOlt=${rowValue}`,
        "_blank"
      )!;
      newWindow.onload = () => {
        newWindow.document.title = "PON-" + rowValue;
      };
      newWindow?.addEventListener("load", () => {
        newWindow?.postMessage(
          { rowValue, column: "oltName" },
          newWindow.origin
        );
      });
    }
  };

  const getTableData = React.useCallback(async () => {
    if (nodeSummaryList?.nodeSummaryList !== undefined) {
      let appendToTableData = await nodeSummaryList?.nodeSummaryList.map(
        (node: any) => {
          return {
            address: getTelemetryHomeAddress(node.servicedAddress),
            ontSerialNumber: node.ontSerialNumber,
            ontVendor: node.ontVendor,
            ontModel: node.ontModel,
            servicedAddress: node.servicedAddress,
            // spread the summary data to the node object to be used in the table
            ...node.data.summary.data,
          };
        }
      );
      setTableData(appendToTableData);
      setFilteredData(appendToTableData);
    }
  }, [nodeSummaryList?.nodeSummaryList]);

  React.useEffect(() => {
    if (nodeSummaryList?.nodeSummaryList !== undefined) {
      // wait for the nodeSummaryList to be populated before enabling the export button
      const isListPopulated = nodeSummaryList?.nodeSummaryList.every(
        (node: any) => node.data.summary.status?.trim() !== ""
      );
      if (isListPopulated) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  }, [nodeSummaryList?.nodeSummaryList]);

  const handleExportColumnData = (columnData: any) => {
    let headers: any = [];
    columnData.forEach((column: any) => {
      headers.push({
        label: column.title,
        key: column.attribute,
      });
    });
    return headers;
  };

  const handleExportRowData = (rowData: any) => {
    rowData.forEach((row: any) => {
      gponTableConfig.forEach((column: any) => {
        let key = column.attribute;
        if (row[key] instanceof Object) {
          row[key] = row[key].value;
        } else if (
          row?.hasOwnProperty("errorCode") &&
          (row[key] === undefined || row[key] === null)
        ) {
          row[key] = "ERROR";
        } else if (key?.trim()?.toLowerCase() === "lastchange") {
          const date = new Date(row[key]) ?? "";
          row[key] =
            date?.toISOString()?.split("T")?.[0] +
            ", " +
            date?.toLocaleTimeString();
        } else {
          row[key] = row[key] === undefined ? "" : row[key]?.toString();
        }
      });
    });
    return rowData;
  };

  const getDefaultFileName = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    let time = today.toLocaleTimeString().split(":");
    return (
      "PON" +
      "_" +
      nodeInfo?.data?.ponNode +
      "_" +
      mm +
      "-" +
      dd +
      "-" +
      yyyy +
      "_" +
      time[0] +
      "_" +
      time[1]
    );
  };

  React.useEffect(() => {
    getTableData();
  }, [getTableData]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {open && !disabled && (
        <div className="abc">
          <ExportCsv
            show={open}
            headerText={labels.Export}
            cancelText={labels.CANCEL}
            handleClose={() => {
              if (setOpen) {
                setOpen(false);
              }
            }}
            rowData={JSON.parse(JSON.stringify(filteredData))}
            columnData={gponTableConfig}
            handleCustom={true}
            handleColumnData={handleExportColumnData}
            handleRowData={handleExportRowData}
            getDefaultLibFileName={getDefaultFileName}
          />
        </div>
      )}
      <GponTelemetryTable
        tableHeader={gponTableConfig}
        tableData={tableData.length > 0 ? tableData : []}
        onClickExternalLink={onClickExternalLink}
        setFilterData={(data: any) => setFilteredData(data)}
      />
    </div>
  );
};
