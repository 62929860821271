import { useDispatch } from "react-redux";
import { Badge } from "@material-ui/core";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { BiLinkExternal } from "react-icons/bi";

import appConst from "../../../../constants/appConst";
import CustomPopOver from "../../../../../components/reusable/CustomPopOver";
import TopologyLabels from "../../../../constants/topologyLabels";
import { mapElementStyles } from "../../../../config/mapItemsConfig";
import "../../../../styles/drawer/fiber/FiberTrace.scss";
import { useFiberUpstreamTraceState } from "../../../../util/reduxFunctions/getTopologyState";
import { setShowRightClickMenu } from "../../../../store/slices/mapItems/mapDetails";
import {
  getFiberUptreamTraceElements,
  setFiberUpstreamTraceBasicInfo,
  setFiberUpstreamTraceIsActive,
  setFiberUpstreamTraceShowPopup,
} from "../../../../store/slices/mapItems/trace/fiber/fiberUpstreamTrace";
import colors from "../../../../config/colors";
import TopologySymbols from "../../../reusable/TopologySymbols";
import { convertFiberTreeDataToArray } from "../../../../util/utilFuncs";
import { paths } from "../../../../../routes/Routes";

interface Props {
  activeMapElement: any;
}

export default function FiberTraceButtons({ activeMapElement }: Props) {
  const dispatch = useDispatch();
  const labels = TopologyLabels();

  const {
    isActive: isFiberUsActive,
    networkId: fiberUsNetworkId,
    data: fiberUsData,
  } = useFiberUpstreamTraceState();
  const activeNetworkId = activeMapElement?.data?.networkId;
  const isFiberUsSelected =
    isFiberUsActive && activeNetworkId === fiberUsNetworkId;

  // TODO remove this method when enabling otdr
  const onUpstreamClick = () => {
    dispatch(setShowRightClickMenu(false));

    if (isFiberUsSelected) {
      dispatch(setFiberUpstreamTraceIsActive(false));
    } else if (activeNetworkId !== fiberUsNetworkId) {
      dispatch(setFiberUpstreamTraceIsActive(true));
      dispatch(
        getFiberUptreamTraceElements(
          activeNetworkId,
          activeMapElement?.rootNodeSiteId,
          activeMapElement?.type,
          activeMapElement?.data
        )
      );
    } else if (
      activeNetworkId === fiberUsNetworkId &&
      fiberUsData !== undefined
    ) {
      dispatch(setFiberUpstreamTraceIsActive(true));
    }
  };

  // TODO remove this method when enabling otdr
  const openNewTabForTableView = () => {
    const otdr = convertFiberTreeDataToArray(fiberUsData, -1);

    const url = window?.open(`/#` + paths.fiberTableView);
    url?.addEventListener("load", () => {
      url.document.title = "Fiber US Trace (BETA)";
      url.postMessage(
        {
          traceType: appConst.upstream,
          networkId: fiberUsNetworkId,
          fiberData: otdr?.updatedData,
          otdrInfo: { otdrDistanceUnit: appConst.m },
        },
        url.origin
      );
    });
  };

  /*  // TODO enable this method when enabling otdr
  const onUpstreamClick = () => { 
    dispatch(setShowRightClickMenu(false));

    const node = activeMapElement?.data?.nodeNum;
    dispatch(
      setFiberUpstreamTraceBasicInfo({
        clickedEleNetworkId: activeNetworkId,
        networkId: fiberUsNetworkId,
        rootNodeSiteId: node ? node : activeMapElement?.rootNodeSiteId,
        type: activeMapElement?.type,
        elementInfo: activeMapElement?.data,
      })
    );
    dispatch(setFiberUpstreamTraceShowPopup(true));
  }; */

  /*  const handleFiberUsOnOff = () => {
    dispatch(setFiberUpstreamTraceIsActive(!isFiberUsSelected));
  }; */

  return (
    <>
      {activeMapElement?.type === appConst.node && activeMapElement?.coord && (
        <span
          className={
            isFiberUsSelected ? "activefiberTraceBtn" : "inActivefiberTraceBtn"
          }
        >
          <CustomPopOver
            position="bottom"
            popOverData={`${labels.Fiber} ${labels.upstream} ${labels.trace}`}
            index="popOverUSTrce"
            originComp={
              <span onClick={onUpstreamClick}>
                <TopologySymbols
                  name={"fiberUpstream"}
                  size={16}
                  color={
                    isFiberUsSelected
                      ? mapElementStyles?.trace?.upstream?.line?.strokeColor
                      : colors.greyMedium
                  }
                />
              </span>
            }
          />
          <span style={{ fontSize: "13px" }}>BETA</span>
          {isFiberUsSelected && (
            <CustomPopOver
              popOverData={`${labels.Fiber} ${labels.upstream} ${labels.tableView}`}
              index="popOver"
              originComp={
                <span onClick={openNewTabForTableView}>
                  <BiLinkExternal size={18} />
                </span>
              }
            />
          )}
          {/* <span style={{ marginRight: 8 }}>
          <Badge
            invisible={!fiberUsData}
            badgeContent={
              <CustomPopOver
                position="top"
                popOverData={"Show/Hide Fiber US Data"}
                index="popOverFiberUS"
                originComp={
                  <span onClick={handleFiberUsOnOff}>
                    {isFiberUsSelected ? (
                      <FiEye style={{ width: 12, height: 12 }} />
                    ) : (
                      <FiEyeOff style={{ width: 12, height: 12 }} />
                    )}
                  </span>
                }
              />
            }
          >
            <CustomPopOver
              position="bottom"
              popOverData={`${labels.Fiber} ${labels.upstream} ${labels.trace}`}
              index="popOverUSTrce"
              originComp={
                <span onClick={onUpstreamClick}>
                  <TopologySymbols
                    name={"fiberUpstream"}
                    color={
                      isFiberUsSelected
                        ? mapElementStyles?.trace?.upstream?.line?.strokeColor
                        : colors.greyMedium
                    }
                    size={16}
                  />
                </span>
              }
            />
          </Badge>
        </span> */}
        </span>
      )}
    </>
  );
}
