import { GroundOverlay } from "@react-google-maps/api";
import React, { useEffect, useRef } from "react";
import { zoomLevelRenderMarker } from "../../../../config/mapConfig";
import { mapElementStyles } from "../../../../config/mapItemsConfig";
import appConst from "../../../../constants/appConst";
import { useFeaturesData } from "../../../../util/customHooks/topologyHooks";
import { GetGoogleMaps } from "../../../../util/reduxFunctions/getTopologyState";
import { getMapEleImageObj } from "../../../../util/utilFuncs";

interface Props {
  gMap: any;
  isVisible: boolean;
  pngImages: any;
  zoomLevel: any;
  featuresAll: any;
}

const Terminator = ({
  gMap,
  pngImages,
  isVisible,
  zoomLevel,
  featuresAll,
}: Props) => {
  const { currentViewBDY } = GetGoogleMaps();
  const markerWM = useRef(0);
  const featureWM = useFeaturesData(featuresAll, currentViewBDY, zoomLevel);

  useEffect(() => {
    if (
      gMap &&
      zoomLevel >= zoomLevelRenderMarker &&
      featureWM !== undefined &&
      featureWM?.newFeatures?.length > 0 &&
      featureWM?.allFeatures?.length !== markerWM?.current
    ) {
      const tempGeoJson = {
        type: appConst?.FeatureCollection,
        features: featureWM?.newFeatures,
      };
      gMap?.data.addGeoJson(tempGeoJson);
      markerWM.current = featureWM?.allFeatures?.length;
    }
  }, [featureWM]);
  return (
    <>
      {zoomLevel >= zoomLevelRenderMarker &&
        isVisible &&
        featureWM?.allFeatures?.map((feature: any) => {
          const propertiesData = feature?.properties?.data;
          const type = feature?.properties?.type;

          const eleImageOverlay: any = getMapEleImageObj(
            pngImages,
            propertiesData?.symbolName,
            propertiesData?.rotation,
            mapElementStyles?.[type],
            feature?.geometry?.coordinates?.[1],
            feature?.geometry?.coordinates?.[0]
          );
          if (eleImageOverlay?.symbol !== undefined) {
            return (
              <GroundOverlay
                key={feature?.featureId}
                url={`${appConst.base64StringPrefix}${eleImageOverlay?.symbol}`}
                bounds={
                  new google.maps.LatLngBounds(
                    new google.maps.LatLng(
                      eleImageOverlay?.southBound,
                      eleImageOverlay?.westBound
                    ),
                    new google.maps.LatLng(
                      eleImageOverlay?.northBound,
                      eleImageOverlay?.eastBound
                    )
                  )
                }
              />
            );
          }
        })}
    </>
  );
};

export default React.memo(Terminator);
