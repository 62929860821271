import { createSlice } from "@reduxjs/toolkit";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "rpdSwVersion",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
    nodeId: "",
    macAddress: "",
  },
  reducers: {
    rpdInfoSwBegin: (rpd) => {
      rpd.isLoading = true;
    },
    rpdInfoSwSuccess: (rpd, action) => {
      rpd.isLoading = false;
      rpd.data = action.payload;
      rpd.status = storeConst.OK;
      rpd.error = undefined;
    },
    rpdInfoSwFailure: (rpd, action) => {
      rpd.isLoading = false;
      rpd.error = action.payload;
      rpd.data = undefined;
      rpd.status = storeConst.ERROR;
    },
    setSwNodeId: (rpd, action) => {
      rpd.nodeId = action.payload;
    },
    setSwMacAddress: (rpd, action) => {
      rpd.macAddress = action.payload;
    },
    rpdInfoSwReset: (rpd) => {
      rpd.isLoading = false;
      rpd.error = undefined;
      rpd.data = undefined;
      rpd.status = "";
      rpd.macAddress = "";
      rpd.nodeId = "";
    },
  },
});

export const {
  rpdInfoSwBegin,
  rpdInfoSwSuccess,
  rpdInfoSwFailure,
  setSwNodeId,
  setSwMacAddress,
  rpdInfoSwReset,
} = slice.actions;
export default slice.reducer;
