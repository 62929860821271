import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { FaCircle } from "react-icons/fa";
import { isBoolValue, isNull } from "../reusable/Util";
import AppLabels from "../../constants/App_Labels";
import CustomAccordion from "../reusable/CustomAccordion";
import { checkRSSIValue, getRSSIValue } from "../reusable/Util";
import CustomPopOver from "../reusable/CustomPopOver";
import ExceptionBox from "../reusable/ExceptionBox";
import DiagApiCallCont from "../reusable/DiagApiCallCont";
import PodsClient from "../../network/httpclient/pods/PodsClient";
import { useDispatch } from "react-redux";
import React from "react";
import ReloadData from "../reusable/ReloadData";
import { Alert } from "@material-ui/lab";

const PodsDiagnostics = (props: any) => {
  const labels = AppLabels();
  const dispatch = useDispatch();

  const { selPOD, selPodIndex, podStatus, podsLoading, podW3ID } = props;
  const [key, setKey] = useState("POD");

  const selPodData = selPOD !== undefined && selPOD[selPodIndex];
  let status = !podStatus?.hasOwnProperty("errorCode") ? "OK" : "ERROR";

  const renderPodsStatus = (podStatus: string) => {
    if (podStatus !== "N/A" && podStatus !== "") {
      let rssi_val = parseInt(podStatus);
      if (rssi_val <= -55 && rssi_val >= -70)
        return (
          <span style={{ color: "#28A745" }}>
            <FaCircle />
            <span style={{ fontWeight: 700 }}>PASS</span>
          </span>
        );
      else
        return (
          <span style={{ color: "#BF371F" }}>
            <FaCircle />
            <span style={{ fontWeight: 700 }}>FAIL</span>
          </span>
        );
    }
  };

  const refreshData = () => {
    dispatch(PodsClient.getPods(podW3ID));
  };

  return (
    <>
      <div className="detailDiagCont">
        <section className="deviceData">
          <div className="deviceStatusCont">
            <p>
              <span>{labels.MODEL.toUpperCase()} : </span>
              <span>{isNull(selPodData?.model)}</span>
            </p>
            <p className="deviceOverallStat">
              <span>STATUS : </span>
              {isNull(selPodData?.hasOwnProperty("statistics")) &&
              isNull(selPodData?.statistics?.rssi) &&
              selPodData?.statistics?.hasOwnProperty("rssi") ? (
                renderPodsStatus(selPodData?.statistics?.rssi)
              ) : (
                <></>
              )}
            </p>
          </div>
          <p>
            <span>{labels.SERIAL_NUMBER.toUpperCase()} : </span>
            <span>{isNull(selPodData?.serialNumber)}</span>
          </p>
        </section>
        <section className="detailDiagTabs">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k: any) => setKey(k)}
          >
            <Tab eventKey="POD" title="POD DETAILS">
              <div className="refreshCont">
                <ReloadData refreshData={refreshData} />
              </div>
              <div className="cmSummaryCont">
                <p>
                  <span>
                    {selPOD &&
                      selPOD?.length > 0 &&
                      !selPodData?.hasOwnProperty("statistics") &&
                      selPodData?.hasOwnProperty("connectionState") &&
                      selPodData?.connectionState?.toUpperCase() ===
                        "CONNECTED" && (
                        <Alert severity="warning">{labels.podWarningMsg}</Alert>
                      )}
                  </span>
                </p>
                <DiagApiCallCont
                  isLoading={podsLoading}
                  data={selPOD}
                  sectionName="Summary"
                  status={status}
                  handleRefresh={() => refreshData()}
                >
                  {selPOD !== undefined && selPOD?.length > 0 && (
                    <>
                      {selPodData?.hasOwnProperty("statistics") ? (
                        <CustomAccordion
                          accTitle={labels.STATISTICS.toUpperCase()}
                          accBodyElement={
                            <table className="dataTableDetails">
                              <thead>
                                <th id="nameCol">
                                  {labels.NAME.toUpperCase()}
                                </th>
                                <th id="valueCol">
                                  {labels.VALUE.toUpperCase()}
                                </th>
                              </thead>
                              <tbody>
                                {Object?.keys(selPodData?.statistics)?.map(
                                  (key: any, i: number) => {
                                    return (
                                      <tr>
                                        <td>{key.toUpperCase()}</td>
                                        {key.toUpperCase() === "RSSI" ? (
                                          <td>
                                            <CustomPopOver
                                              popOverData={checkRSSIValue(
                                                isNull(
                                                  selPodData?.statistics?.rssi
                                                )?.toUpperCase()
                                              )}
                                              originComp={getRSSIValue(
                                                isNull(
                                                  selPodData?.statistics?.rssi
                                                )?.toUpperCase()
                                              )}
                                              index="popOver"
                                            />
                                          </td>
                                        ) : (
                                          <>
                                            <td>
                                              {isNull(
                                                isBoolValue(
                                                  selPodData?.statistics[key]
                                                )
                                              )}
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          }
                        />
                      ) : (
                        <></>
                      )}
                      <CustomAccordion
                        accTitle={labels.SUMMARY.toUpperCase()}
                        accBodyElement={
                          <table className="dataTableDetails">
                            <thead>
                              <th id="nameCol">{labels.NAME.toUpperCase()}</th>
                              <th id="valueCol">
                                {labels.VALUE.toUpperCase()}
                              </th>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{labels.DEVICE_ID.toUpperCase()}</td>
                                <td>{isNull(selPodData?.deviceId)}</td>
                              </tr>
                              <tr>
                                <td>{labels.SERIAL_NUMBER.toUpperCase()}</td>
                                <td>{isNull(selPodData?.serialNumber)}</td>
                              </tr>
                              <tr>
                                <td>{labels.DEVICE_NAME.toUpperCase()}</td>
                                <td>
                                  {isNull(
                                    selPodData?.deviceFriendlyName
                                  ).toUpperCase()}
                                </td>
                              </tr>
                              <tr>
                                <td>{labels.MODEL.toUpperCase()}</td>
                                <td>{isNull(selPodData?.model)}</td>
                              </tr>
                              <tr>
                                <td>{labels.DEVICE_TYPE.toUpperCase()}</td>
                                <td>{isNull(selPodData?.deviceType)}</td>
                              </tr>
                              <tr>
                                <td>{labels.FIRMWARE_VERSION.toUpperCase()}</td>
                                <td>{isNull(selPodData?.firmwareVersion)}</td>
                              </tr>
                              <tr>
                                <td>
                                  {labels.CONNECTION_STATUS.toUpperCase()}
                                </td>
                                <td>
                                  {isNull(
                                    selPodData?.connectionState
                                  ).toUpperCase()}
                                </td>
                              </tr>
                              {/*  <tr>
                            <td>{labels.HEALTH_STATUS.toUpperCase()}</td>
                            <td>
                              {selPodData?.hasOwnProperty("health") ? (
                                isNull(selPodData?.health?.status).toUpperCase()
                              ) : (
                                <></>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>{labels.HEALTH_SCORE.toUpperCase()}</td>
                            <td>
                              {selPodData?.hasOwnProperty("health") ? (
                                isNull(selPodData?.health?.score)
                              ) : (
                                <></>
                              )}
                            </td>
                          </tr>*/}
                            </tbody>
                          </table>
                        }
                      />
                    </>
                  )}
                </DiagApiCallCont>
                {selPOD === undefined ? (
                  <div className="diagErrorCont">
                    <ExceptionBox
                      headerText="No Data to display in Pod Details."
                      bodyText="Please try refreshing."
                      handleRefresh={refreshData}
                    />
                  </div>
                ) : (
                  selPOD !== undefined &&
                  selPOD?.length === 0 && (
                    <ExceptionBox
                      headerText="No Data to display in Pod Details."
                      handleRefresh={refreshData}
                    />
                  )
                )}
              </div>
            </Tab>
          </Tabs>
        </section>
      </div>
    </>
  );
};

export default PodsDiagnostics;
