import {
  GetConfigsProps,
  GetNikaOrderProps,
} from "../../../util/reduxFunctions/getTopologyState";
import Filters from "./Filters";
import NikaTableOrders from "./NikaTableOrders";

export default function NikaMainScreen() {
  const { data } = GetNikaOrderProps();
  const { data: configData } = GetConfigsProps();

  return (
    <div
    // style={{ overflowY: "auto", height: "calc(100vh - 120px)" }}
    >
      <Filters />
      <NikaTableOrders data={data} configData={configData} />
    </div>
  );
}
