import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { HttpClient } from "../HttpClient";
import { NetworkConfig } from "../NetworkConfig";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { RecoEngineState } from "../../../store/states/account/troubleshoot/RecoEngineState";
import {
  recoEngineBegin,
  recoEngineFailure,
  recoEngineSuccess,
} from "../../../store/actions/account/troubleshoot/RecoEngineAction";
import { RecoEngine } from "../../../store/models/account/troubleshoot/RecoEngine";

export default class RecoEngineClient {
  static getRecoEngine =
    (
      macAddress: string,
      brandName: string
    ): ThunkAction<void, RecoEngineState, unknown, Action<string>> =>
    async (dispatch) => {
      let httpParameters: IHttpClientRequestParameters<any> = {
        url: NetworkConfig.ACCOUNT_SERVICE_URL + "splunk/reco/" + macAddress,
        params: { brand: brandName },
      };

      dispatch(recoEngineBegin());

      HttpClient.get<RecoEngine>(httpParameters)
        .then((recoEngine) => {
          dispatch(recoEngineSuccess(recoEngine));
          return recoEngine;
        })
        .catch((error) => {
          dispatch(recoEngineFailure(error));
          return error;
        });
    };
}
