import { Form } from "react-bootstrap";

interface Props {
  comments: any;
  setComments: any;
}

export default function Comments(props: Props) {
  const handleCommentChange = (e: any, index: number) => {
    let tempArray = [...props.comments];
    tempArray[index].value = e.target.value;
    props.setComments(tempArray);
  };

  return (
    <div>
      {props.comments?.map((comment: any, index: number) => (
        <div key={index}>
          <Form.Group className="selector">
            <Form.Label>{comment.label}</Form.Label>
            <Form.Control
              name={props.comments[index].key}
              onChange={(e) => handleCommentChange(e, index)}
              as="textarea"
              rows={2}
              className="description"
              id="description"
              maxLength={1000}
              value={
                props.comments[index].value ? props.comments[index].value : ""
              }
              placeholder=""
            />
          </Form.Group>
        </div>
      ))}
    </div>
  );
}
