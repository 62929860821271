import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../middleware/apiActions";
import { MDU_CAD_ATTRIBUTE } from "../../../middleware/apiEndpoints";
import storeConst from "../../../storeConst";
import { decrApiCall, incrApiCall } from "../mapDetails";

const slice = createSlice({
  name: "mduCADAttribute",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    getMduCADAttributeBegin: (mduCADAttribute) => {
      mduCADAttribute.isLoading = true;
      mduCADAttribute.status = "";
    },
    getMduCADAttributeSuccess: (mduCADAttribute, action) => {
      mduCADAttribute.isLoading = false;
      mduCADAttribute.data = action.payload;
      mduCADAttribute.status = storeConst.OK;
    },
    getMduCADAttributeFailure: (mduCADAttribute, action) => {
      mduCADAttribute.isLoading = false;
      mduCADAttribute.error = action.payload;
      mduCADAttribute.data = undefined;
      mduCADAttribute.status = storeConst.ERROR;
    },
  },
});

const {
  getMduCADAttributeBegin,
  getMduCADAttributeSuccess,
  getMduCADAttributeFailure,
} = slice.actions;
export default slice.reducer;

export const getMduCADAttribute = (owningDrawing: any) => (dispatch: any) => {
  dispatch(
    apiCallBegan({
      url: `${MDU_CAD_ATTRIBUTE}/${owningDrawing}`,
      method: "get",
      onStart: getMduCADAttributeBegin.type,
      onSuccess: getMduCADAttributeSuccess.type,
      onError: getMduCADAttributeFailure.type,
      onStartDispatch: incrApiCall.type,
      onSuccessDispatch: decrApiCall.type,
      onErrorDispatch: decrApiCall.type,
    })
  );
};
