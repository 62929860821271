import { CSSProperties, useEffect, useState } from "react";

import "../../../../styles/drawer/fiber/FiberTrace.scss";
import TopologyLabels from "../../../../constants/topologyLabels";
import Table, {
  TCTypes,
  TDTypes,
} from "../../../../components/reusable/table/Table";
import ExceptionBox from "../../../../../components/reusable/ExceptionBox";
import colors from "../../../../config/colors";
import appConst from "../../../../constants/appConst";
import { toKms } from "../../../../util/utilFuncs";

interface Props {
  title: String;
  data: any;
  otdrInfo?: any;
  showExpandBtn?: boolean;
  tableStyle?: CSSProperties;
}
export default function FiberTableData({
  data,
  otdrInfo,
  showExpandBtn = true,
  tableStyle,
  title,
}: Props) {
  const labels = TopologyLabels();
  const TW = "100%";

  const [tableData, setTableData] = useState<TDTypes[]>([]);

  const tableColumns = [
    {
      th: labels.circuit,
      tdName: "circuit",
    },
    { th: labels.elementName, tdName: "elementName" },
    { th: labels.cableType, tdName: "cableType" },
    { th: labels.segmentType, tdName: "constructionType" },
    {
      th: `${labels.length} (${otdrInfo?.otdrDistanceUnit})`,
      tdName: "gisLength",
    },
    {
      th: `${labels.cumulativeLength} (${otdrInfo?.otdrDistanceUnit})`,
      tdName: "cumulativeLength",
    },
  ] as TCTypes[];

  useEffect(() => {
    if (data) {
      let arrayData: TDTypes[] = [];
      for (let i = 0; i < data?.length; i++) {
        const item = data?.[i];
        const isOtdrAvailable = findArrayElementByNetworkId(
          otdrInfo?.allOtdrs,
          item?.networkId
        );
        arrayData?.push({
          id: item?.id,
          circuit: item?.circuitName,
          elementName: item?.elementName,
          cableType: item?.cableType,
          constructionType: item?.constructionType,
          gisLength:
            otdrInfo?.otdrDistanceUnit === appConst.km
              ? toKms(item?.gisLength)
              : item?.gisLength,
          cumulativeLength:
            otdrInfo?.otdrDistanceUnit === appConst.km
              ? toKms(item?.cumulativeLength)
              : item?.cumulativeLength,
          isHighlight: isOtdrAvailable,
          highlightColor: `${colors.otdrHighlight}`,
        });
      }
      setTableData(arrayData);
    }
  }, [data]);

  return (
    <>
      {tableData?.length !== 0 ? (
        <Table
          title={`${title}`}
          data={tableData}
          columns={tableColumns}
          showExpandBtn={showExpandBtn}
        />
      ) : (
        <div className="diagErrorCont">
          <ExceptionBox
            headerText={labels.NoSearchHistory}
            bodyText={labels.PerformSearchToSeeTheResults}
          />
        </div>
      )}
    </>
  );
}

function findArrayElementByNetworkId(array: Array<any>, networkId: string) {
  return array?.find((element) => {
    return element?.networkId === networkId;
  });
}
