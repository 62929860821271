import { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import AppLabels from "../../constants/App_Labels";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import ProfileManagementClient from "../../network/httpclient/profileManagement/PofileManagementClient";
import LoaderGif from "../../resources/images/loader.webp";
import ExceptionBox from "../reusable/ExceptionBox";
import { isNull } from "../reusable/Util";

const ProfileManagementModal = (props: any) => {
  const dispatch = useDispatch();
  const labels = AppLabels();
  const [username, setUsername] = useState("");
  const [guid, setGuid] = useState("");
  const [awaitRelink, setAwaitRelink] = useState(false);
  const [resultDisplay, setResultDisplay] = useState(false);
  const [resultType, setResultType] = useState("");
  const handleClose = () => {
    props.setShow(false);
    setResultDisplay(false);
    setResultType("");
  };

  const {
    activeAccountInfo,
    unlinkLoading,
    unlinkStatus,
    linkLoading,
    linkStatus,
    userData,
    userDataError,
    userDataLoading,
    userDataStatus,
    unlinkError,
    linkError,
  } = useSelector(
    (state: any) => ({
      activeAccountInfo: state.AccountState.ActiveAccountDetails.data,
      unlinkLoading: state.AccountState.UnlinkBanInfo.isLoading,
      unlinkStatus: state.AccountState.UnlinkBanInfo.status,
      unlinkError: state.AccountState.UnlinkBanInfo.error,
      linkLoading: state.AccountState.LinkBanInfo.isLoading,
      linkStatus: state.AccountState.LinkBanInfo.status,
      linkError: state.AccountState.LinkBanInfo.Error,
      userData: state.AccountState.BanSearchInfo.data,
      userDataError: state.AccountState.BanSearchInfo.error,
      userDataLoading: state.AccountState.BanSearchInfo.isLoading,
      userDataStatus: state.AccountState.BanSearchInfo.status,
    }),
    shallowEqual
  );
  const ban = activeAccountInfo?.consolidatedBan;
  const handleBanSearchRefresh = () => {
    dispatch(ProfileManagementClient.banSearch(ban));
  };
  const handleRelink = () => {
    setAwaitRelink(true);
    setResultDisplay(true);
    setResultType("Relink");
    dispatch(
      ProfileManagementClient.unlinkBan(userData?.guid, userData?.alias, {})
    );
  };
  const handleRemove = () => {
    setResultDisplay(true);
    setResultType("Remove");
    dispatch(
      ProfileManagementClient.unlinkBan(userData?.guid, userData?.alias, {})
    );
  };

  useEffect(() => {
    if (userData !== null && userData !== undefined && username === "") {
      setUsername(userData.userName);
      setGuid(userData.guid);
    }
  }, [userDataStatus]);
  useEffect(() => {
    if (awaitRelink && unlinkStatus === "OK") {
      dispatch(ProfileManagementClient.linkBan(guid, ban, {}));
      setAwaitRelink(false);
    }
  }, [unlinkStatus]);
  return (
    <>
      <Modal dialogClassName="modalExternalAppMenu" show={props.show} size="lg">
        <Modal.Header className="searchPopHead">
          <header>{labels.PROFILE_MANAGEMENT.toUpperCase()}</header>
          <button className="sPopUpClose" onClick={handleClose}>
            <IoClose color="white" />
          </button>
        </Modal.Header>
        <Modal.Body>
          {userDataLoading ? (
            <img className="loaderImg" src={LoaderGif} />
          ) : userDataStatus === "OK" &&
            (userData?.userName === undefined ||
              userData?.userName === null ||
              userData?.userName === "") ? (
            <div className="diagErrorCont">
              <ExceptionBox
                headerText={`No user associated with ban # ${ban}`}
                primaryBtn={false}
                secBtn={true}
              />
            </div>
          ) : userDataStatus === "ERROR" ? (
            <div className="diagErrorCont">
              <ExceptionBox
                exBoxType="error"
                errSecName="Profile Management"
                errCode={isNull(userDataError?.errorCode)}
                ErrorMSG={isNull(userDataError?.errorMessage)}
                srcSystem={
                  userDataError?.hasOwnProperty("sourceSystem")
                    ? userDataError?.sourceSystem
                    : "Request"
                }
                primaryBtn={true}
                handleRefresh={handleBanSearchRefresh}
                secBtn={false}
              />
            </div>
          ) : (
            <div className="loginFixContainer">
              <span className="profManTitle">
                {labels.USERNAME} <b>{userData?.userName} </b>
                {labels.ASSOCIATED_WITH}
                {ban}
              </span>
              <hr
                style={{
                  width: "100%",
                  alignSelf: "center",
                  color: "black",
                  paddingBottom: "10px",
                }}
              />
              <div className="profManInnerContainer">
                <span className="profManText">
                  {labels.PROF_MAN_RELINK}
                  <b>
                    {` ${labels.RELINK}`} {userData?.userName}
                  </b>
                </span>
                <Button className="loginFixButtons" onClick={handleRelink}>
                  {labels.RELINK}
                </Button>
              </div>
              <div className="profManInnerContainer">
                <span className="profManText">
                  {labels.PROF_MAN_REMOVE}
                  <b>
                    {` ${labels.PERMANENTLY_REMOVE}`} {userData?.userName}
                  </b>
                </span>
                <Button className="loginFixButtons" onClick={handleRemove}>
                  {labels.REMOVE}
                </Button>
              </div>
              {resultDisplay ? (
                <div className="mobileLogInResult">
                  {unlinkLoading || linkLoading ? (
                    <img className="loaderImg" src={LoaderGif} />
                  ) : linkStatus === "OK" && resultType === "Relink" ? (
                    <p className="succes">{labels.LINK_SUCCESS}</p>
                  ) : unlinkStatus === "OK" && resultType === "Remove" ? (
                    <p className="succes">{labels.UNLINK_SUCCESS}</p>
                  ) : linkStatus === "ERROR" && resultType === "Relink" ? (
                    <div className="diagErrorCont">
                      <ExceptionBox
                        exBoxType="error"
                        errSecName="Login Fix"
                        errCode={isNull(linkError?.errorCode)}
                        ErrorMSG={isNull(linkError?.errorMessage)}
                        srcSystem={
                          linkError?.hasOwnProperty("sourceSystem")
                            ? linkError?.sourceSystem
                            : "Request"
                        }
                        primaryBtn={false}
                        secBtn={false}
                      />
                    </div>
                  ) : unlinkStatus === "ERROR" && resultType === "Remove" ? (
                    <div className="diagErrorCont">
                      <ExceptionBox
                        exBoxType="error"
                        errSecName="Login Fix"
                        errCode={isNull(unlinkError?.errorCode)}
                        ErrorMSG={isNull(unlinkError?.errorMessage)}
                        srcSystem={
                          unlinkError?.hasOwnProperty("sourceSystem")
                            ? unlinkError?.sourceSystem
                            : "Request"
                        }
                        primaryBtn={false}
                        secBtn={false}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
          <hr
            style={{
              width: "100%",
              alignSelf: "center",
              color: "black",
              padding: "10px",
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProfileManagementModal;
