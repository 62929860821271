import { Tab, Tabs } from "react-bootstrap";
import { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import colors from "../../topology/config/colors";
import {
  extractW3IdOfHSI,
  getDeviceDetails,
  getDeviceLoading,
  isNull,
  renderStatus,
} from "../reusable/Util";
import IPTVInfoClient from "../../network/httpclient/iptv/IPTVInfoClient";
import IPTVSummaryInfo from "./IPTVSummaryInfo";
import FailedAttributesIptv from "../FailedAttributes/FailedAttributesIptv";
import { SetDeviceStatus } from "../../store/actions/account/accountInfo/ActiveAccountAction";

const IPTVDiagnostics = (props: any) => {
  const dispatch = useDispatch();
  const { selectedDevice, selDiagAction, handleActionClick } = props;
  const { diagDevicesData, selectedSA } = useSelector(
    (state: any) => ({
      diagDevicesData: state.AccountState.DiagDevicesInfo.diagDevices,
      selectedSA: state.AccountState.ActiveAccountDetails.selectedSA,
    }),
    shallowEqual
  );
  const isDeviceOffline = (apiResponse: any) => {
    const offlineCodes = ["1009", "1205"];
    return offlineCodes?.includes(apiResponse?.errorCode)
  }
  const params = selectedDevice?.useRpil
    ? {
      oui: selectedDevice.oui,
      serialNum: selectedDevice.serialNumber,
      hhid: extractW3IdOfHSI(selectedSA),
      macAddress: selectedDevice.macAddress,
    }
    : { oui: selectedDevice.oui, serialNum: selectedDevice.serialNumber };
  let iptvLoading = getDeviceLoading(
    selectedDevice.serialNumber,
    diagDevicesData
  );
  let iptvDeviceDetails = getDeviceDetails(
    selectedDevice.serialNumber,
    "device",
    diagDevicesData
  );
  let iptvNetworkDetails = getDeviceDetails(
    selectedDevice.serialNumber,
    "network",
    diagDevicesData
  );
  let iptvEthernetDetails = getDeviceDetails(
    selectedDevice.serialNumber,
    "ethernet",
    diagDevicesData
  );
  let iptvWifiDetails = getDeviceDetails(
    selectedDevice.serialNumber,
    "wifi",
    diagDevicesData
  );
  let iptvDeviceStatus =
    !iptvDeviceDetails?.hasOwnProperty("errorCode")
      ? "OK"
      // : "ERROR";
      : isDeviceOffline(iptvDeviceDetails)
        ? "FAIL" // Device fails
        : "ERROR";  // Device is skipped
  let iptvNetworkStatus =
    !iptvNetworkDetails?.hasOwnProperty("errorCode")
      ? "OK"
      : isDeviceOffline(iptvNetworkDetails)
        ? "FAIL" // Device fails
        : "ERROR";  // Device is skipped
  let connectionType = "";
  if (
    iptvNetworkDetails !== undefined &&
    iptvNetworkDetails?.hasOwnProperty("lanConnectionType")
  ) {
    connectionType = iptvNetworkDetails.lanConnectionType;
  }
  const [key, setKey] = useState(selDiagAction);
  useEffect(() => {
    setKey(selDiagAction);
  }, [selDiagAction]);
  useEffect(() => {
    if (iptvDeviceDetails !== undefined && iptvDeviceDetails?.length === 0) {
      dispatch(IPTVInfoClient.getIptvDevice(params));
    }
    if (iptvNetworkDetails !== undefined && iptvNetworkDetails?.length === 0) {
      dispatch(IPTVInfoClient.getIptvNetwork(params));
    }
  }, [selectedDevice]);
  useEffect(() => {
    if (connectionType?.toLowerCase() === "wifi") {
      if (iptvWifiDetails !== undefined && iptvWifiDetails?.length === 0) {
        dispatch(IPTVInfoClient.getIptvWifi(params));
      }
    } else if (connectionType?.toLowerCase() === "ethernet") {
      if (
        iptvEthernetDetails !== undefined &&
        iptvEthernetDetails?.length === 0
      ) {
        dispatch(IPTVInfoClient.getIptvEthernet(params));
      }
    }
  }, [connectionType]);
  useEffect(() => {
    let deviceStatus = "";
    if (
      !iptvLoading &&
      iptvNetworkDetails?.length !== 0 &&
      !iptvNetworkDetails?.hasOwnProperty("errorCode")
    ) {
      if (connectionType?.toLowerCase() === "wifi") {
        deviceStatus = iptvWifiDetails?.signalStrength?.status?.toLowerCase();
      } else if (connectionType?.toLowerCase() === "ethernet") {
        deviceStatus =
          iptvEthernetDetails?.ethernetStatus?.status?.toLowerCase();
      }
      dispatch(
        SetDeviceStatus(
          selectedDevice?.serialNumber,
          selectedDevice?.deviceCategory,
          deviceStatus !== undefined ? deviceStatus : "Skipped"
        )
      );
    } else if (
      !iptvLoading &&
      iptvNetworkDetails?.length !== 0 &&
      iptvNetworkDetails?.hasOwnProperty("errorCode") &&
      !isDeviceOffline(iptvNetworkDetails)
    ) {
      dispatch(
        SetDeviceStatus(
          selectedDevice?.serialNumber,
          selectedDevice?.deviceCategory,
          "Skipped"
        )
      );
    } else if (
      !iptvLoading &&
      iptvNetworkDetails?.length !== 0 &&
      iptvNetworkDetails?.hasOwnProperty("errorCode") &&
      isDeviceOffline(iptvNetworkDetails)
    ) {
      dispatch(
        SetDeviceStatus(
          selectedDevice?.serialNumber,
          selectedDevice?.deviceCategory,
          "Fail"
        )
      );
    }
  }, [iptvLoading]);
  const renderDeviceStatus = () => {
    const getConnectionStatus = (details: any, statusProp: any) => {
      if (details !== undefined && details?.hasOwnProperty(statusProp)) {
        const status = details[statusProp]?.status;
        if (status === "Pass") {
          return renderStatus(colors.success, "PASS");
        } else if (status === "Fail") {
          return renderStatus(colors.danger, "FAIL");
        } else {
          return status || renderStatus(colors.skip, "SKIPPED");
        }
      } else {
        return renderStatus(colors.skip, "SKIPPED");
      }
    };

    if (connectionType?.toLowerCase() === "wifi") {
      return getConnectionStatus(iptvWifiDetails, "signalStrength");
    } else if (connectionType?.toLowerCase() === "ethernet") {
      return getConnectionStatus(iptvEthernetDetails, "ethernetStatus");
    } else if (iptvNetworkStatus?.toLowerCase() === "error") {
      return (renderStatus(colors.skip, "SKIPPED"));
    } else if (iptvNetworkStatus?.toLowerCase() === "fail") {
      return (renderStatus(colors.danger, "FAIL"));
    }
  };
  return (
    <div className="detailDiagCont">
      <section className="deviceData">
        <div className="deviceStatusCont">
          <p>
            <span>MODEL : </span>
            <span>{isNull(selectedDevice.deviceModel)}</span>
          </p>
          <p className="deviceOverallStat">
            <span>STATUS : </span>
            {!iptvLoading ? (
              renderDeviceStatus()
            ) : (
              <span style={{ fontStyle: "italic" }}>Fetching...</span>
            )}
          </p>
        </div>
        <p>
          <span>SERIAL NUMBER : </span>
          <span>{isNull(selectedDevice.serialNumber)}</span>
        </p>
        <p>
          {(iptvWifiDetails !== undefined &&
            iptvWifiDetails?.hasOwnProperty("signalStrength") &&
            iptvWifiDetails?.signalStrength?.status === "Fail") ||
            (iptvEthernetDetails !== undefined &&
              iptvEthernetDetails?.hasOwnProperty("ethernetStatus") &&
              iptvEthernetDetails?.ethernetStatus?.status === "Fail") ? (
            <FailedAttributesIptv
              ethernetData={iptvEthernetDetails}
              wifiData={iptvWifiDetails}
            />
          ) : (
            <></>
          )}
        </p>
      </section>
      <section className="detailDiagTabs">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k: any) => handleActionClick(k)}
        >
          <Tab eventKey="iptvSUMMARY" title="IPTV SUMMARY">
            <IPTVSummaryInfo
              connectionType={connectionType}
              params={params}
              iptvLoading={iptvLoading}
              iptvDeviceInfo={iptvDeviceDetails}
              iptvDeviceStatus={iptvDeviceStatus}
              iptvNetworkInfo={iptvNetworkDetails}
              iptvEthernetInfo={iptvEthernetDetails}
              iptvWifiInfo={iptvWifiDetails}
            />
          </Tab>
        </Tabs>
      </section>
    </div>
  );
};

export default IPTVDiagnostics;
