import { AppBar, Box, Tab, Tabs } from "@material-ui/core";
import { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";

import "../../design-tokens/rpd-page/RPDPage.scss";
import { GetTechInfo } from "../../get-api/GetResponse";
import TECHClient from "../../network/httpclient/technician/TECHClient";
import RPDSearchTab from "../rpd-page/RPDSearchTab";
import RPDRegistrationTab from "../rpd-page/RPDRegistrationTab";
import RPDSwapTab from "../rpd-page/RPDSwapTab";
import RPDActivationTab from "../rpd-page/RPDActivationTab";
import RPDProvisioningTab from "../rpd-page/RPDProvisioningTab";
import RPDSWUpgradeTab from "../rpd-page/RPDSWUpgradeTab";
import { setOrderId } from "../../store/ducksPattern/rpd/rpdOrderInfo";
import RccapPage from "../rpd-page/RccapPage";
import VccapPage from "../rpd-page/VccapPage";
import RPDOrderDetailTab from "../rpd-page/RPDOrderDetailTab";
import VccapSWupgradeTab from "../rpd-page/VccapSWupgradeTab";
import VccapUATportTab from "../rpd-page/VccapUATportTab";
import VccapRpdConfigTab from "../rpd-page/VccapRpdConfigTab";
import CASAVideoExecTab from "../rpd-page/CASAVideoExecTab";
import AppLabels from "../../constants/App_Labels";
import ConfigConst from "../../constants/ConfigConst";
import ExceptionBox from "../../components/reusable/ExceptionBox";
import LoaderGif from "../../resources/images/loader.webp";
import RPDClient from "../../network/httpclient/rpd/RPDClient";
import ScreenLocker from "../../components/reusable/ScreenLocker";
import { getAccessLevelByRoles } from "../../components/reusable/Util";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const RPDPage = (props: any) => {
  const {
    location: { search },
  } = props;
  const techData = GetTechInfo()?.data;
  const isLoading = GetTechInfo()?.isLoading;
  const labels = AppLabels();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [isRPDAvailable, setIsRPDAvailable] = useState(false);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const query = new URLSearchParams(search);
  const [orderSearchValue, setOrderSearchValue] = useState<string | null>("");
  const [orderSearchType, setOrderSearchType] = useState("");

  useEffect(() => {
    if (search && search.includes("orderId")) {
      setOrderSearchType("Order");
      setOrderSearchValue(query.get("orderId"));
    }
  }, [query]);

  useEffect(() => {
    if (orderSearchType == "Order" && orderSearchValue !== null) {
      dispatch(setOrderId(orderSearchValue));
      dispatch(RPDClient.getOrderInfo(orderSearchValue));
      setValue(1);
    }
  }, [orderSearchValue]);

  // const profileRef = useRef(false);

  const profileApi = useCallback(() => {
    // Already called in the App.tsx
    // if (techData === undefined && !profileRef.current) {
    //   dispatch(TECHClient.getTechniciansProfile());
    //   profileRef.current = true;
    if (techData !== undefined) {
      // techData?.roles?.map((item: any) => {
      //   if (
      //     item === ConfigConst.MAINTENANCE_TECH_MANAGER ||
      //     item.includes(ConfigConst.MAINTENANCE_TECH) ||
      //     item.includes(ConfigConst.PROVISIONING_TECH) ||
      //     item.includes(ConfigConst.PROVISIONING_TECH_MANAGER) ||
      //     item === ConfigConst.ADMIN
      //   ) {
      //     setIsRPDAvailable(true);
      //   }
      // });
      if (
        getAccessLevelByRoles(techData?.roles).includes(ConfigConst.MT) ||
        getAccessLevelByRoles(techData?.roles).includes(
          ConfigConst.PROVISION_T
        ) ||
        techData?.roles?.includes(ConfigConst.ADMIN)
      ) {
        setIsRPDAvailable(true);
      }
    }
  }, [dispatch, techData]);

  useEffect(() => {
    profileApi();
  }, [profileApi]);

  return (
    <div>
      {/* <ScreenLocker password="Rogers@123"> */}
      {isLoading ? (
        <div className="loader">
          <img style={{ height: "60px" }} src={LoaderGif} />
        </div>
      ) : isRPDAvailable ? (
        <div>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab
                style={{ textTransform: "none" }}
                label="rCCAP"
                {...a11yProps(0)}
              />
              {/* <Tab
                style={{ textTransform: "none" }}
                label="vCCAP"
                {...a11yProps(1)}
              /> */}
              <Tab
                style={{ textTransform: "none" }}
                label={labels.rpdOrderInfo}
                {...a11yProps(1)}
              />
            </Tabs>
          </AppBar>

          <TabPanel value={value} index={0}>
            <RccapPage />
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
            <VccapPage />
          </TabPanel> */}
          <TabPanel value={value} index={1}>
            <RPDOrderDetailTab />
          </TabPanel>
        </div>
      ) : (
        <div className="diagErrorCont">
          <ExceptionBox
            headerText={labels.rpdAuthenticationError}
            bodyText={labels.rpdAuthenticationMsg}
            primaryBtn={false}
            secBtn={false}
          />
        </div>
      )}
      {/* </ScreenLocker> */}
    </div>
  );
};
export default RPDPage;
