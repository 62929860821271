import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { handleFilterSelection } from "../../../store/slices/mapItems/node/nodeSiteDetail";
import SelectionChip from "../../reusable/SelectionChip";
import { TOPOLOGY_FILTERS_KEY } from "../../../constants/storageConst";
import ToggleSwitch from "../../reusable/ToggleSwitch";
import { GetNodeSiteDetailsProps } from "../../../util/reduxFunctions/getTopologyState";
import { topoElementIsAvailable } from "../../../util/utilFuncs";
import appConst from "../../../constants/appConst";

interface Props {
  topoEleFilters: any;
  setTopoEleFilters: (arg0: any) => void;
}

const MapDataFilter = ({ topoEleFilters, setTopoEleFilters }: Props) => {
  const dispatch = useDispatch();
  const { activeNode, activeNodePolyType } = GetNodeSiteDetailsProps();

  useEffect(() => {
    if (topoEleFilters && topoEleFilters?.length > 0) {
      topoEleFilters?.map((fc: any, fci: number) => {
        const fcItems = fc?.items?.length;
        let fcActiveItems = 0;
        fc?.items?.map((filter: any) => {
          if (filter?.isActive) {
            fcActiveItems++;
            if (
              activeNodePolyType &&
              activeNodePolyType?.toLowerCase() !== appConst.phub
            ) {
              const isEleAvailable = topoElementIsAvailable(
                activeNode,
                filter?.key
              );
              if (!isEleAvailable) {
                dispatch(handleFilterSelection(filter?.key));
              }
            }
          }
        });
        if (fcActiveItems === fcItems) topoEleFilters[fci].isAllSelected = true;
        else topoEleFilters[fci].isAllSelected = false;
      });
    }
  }, [topoEleFilters]);

  const onChipsPress = (
    checked: boolean,
    categoryIndex: number,
    itemIndex: number
  ) => {
    const item = topoEleFilters?.[categoryIndex]?.items?.[itemIndex];
    if (item) {
      item.isActive = checked;
      setTopoEleFilters([...topoEleFilters]);
      /* if (checked) dispatch(handleFilterSelection(item?.key)); */
    }
    saveToLocalStorage();
  };

  const handleToggleSwitch = (
    switchValue: boolean,
    filterCategoryIndex: number
  ) => {
    topoEleFilters[filterCategoryIndex].isAllSelected = switchValue;
    if (switchValue) {
      topoEleFilters?.[filterCategoryIndex]?.items?.map((filter: any) => {
        filter.isActive = true;
      });
    } else if (!switchValue) {
      topoEleFilters?.[filterCategoryIndex]?.items?.map((filter: any) => {
        filter.isActive = false;
      });
    }
    setTopoEleFilters([...topoEleFilters]);
    saveToLocalStorage();
  };

  const saveToLocalStorage = () => {
    const keysToStore = [] as any;
    topoEleFilters?.map((cat: any) =>
      cat?.items?.map((e: any) => e.isActive && keysToStore.push(e.key))
    );
    localStorage.setItem(TOPOLOGY_FILTERS_KEY, JSON.stringify(keysToStore));
  };

  return (
    <>
      {topoEleFilters?.map((fc: any, fi: number) => (
        <div className="filterGroupCont" key={fc?.key}>
          <div className="filterSecHeader">
            <label className="filterSecLabel">{fc?.label}</label>
            <ToggleSwitch
              switchValue={fc?.isAllSelected}
              setSwitchValue={(switchValue: boolean) =>
                handleToggleSwitch(switchValue, fi)
              }
            />
          </div>
          <div className="selectionChipsCont">
            {fc?.items?.map((ci: any, cii: number) => (
              <SelectionChip
                key={ci.key}
                style={{ marginRight: 10, marginBottom: 10 }}
                label={ci.label}
                checked={ci.isActive}
                setChecked={(checked) => onChipsPress(checked, fi, cii)}
              />
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default MapDataFilter;
