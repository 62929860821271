import {
  GetCommentsProps,
  GetNodeSiteDetailsProps,
  GetTopoGeneralProps,
  useFetchElementFiles,
} from "../../../util/reduxFunctions/getTopologyState";
import ActiveMapElement from "../ActiveMapElement";
import TopologyLabels from "../../../constants/topologyLabels";
import CustomTabs from "../../reusable/CustomTabs";
import NodeCommentsView from "../nodeComments/NodeCommentsView";
import appConst from "../../../constants/appConst";
import { useDispatch } from "react-redux";
import { getComments } from "../../../store/slices/mapItems/comments/comments";
import ElementAttachments from "../attachments/ElementAttachments";
import { getAllFiles } from "../../../store/slices/mapItems/elementAttachments";

export default function NodeDetailsView() {
  const labels = TopologyLabels();
  const dispatch = useDispatch();
  const { allNodeData } = GetNodeSiteDetailsProps();
  const { activeNodeId } = GetTopoGeneralProps();
  const ND = allNodeData?.findIndex((e:any) => activeNodeId === e?.nodeSiteId);
  const features = ND != -1 && allNodeData?.[ND]?.node?.data?.features?.[0];
  const fData = features?.properties?.data;
  const elementData = {
    id: fData?.networkId,
    type: features?.properties?.type,
    data: fData,
    coord: {
      lat: fData?.siteCoords?.coordinates?.[1],
      lng: fData?.siteCoords?.coordinates?.[0],
    },
  };
  const { eleCommentsData } = GetCommentsProps();
  const { fetchData } = useFetchElementFiles();
  const data = eleCommentsData?.[elementData?.data?.networkId]?.data;
  const handleSelect = (key: any) => {
    if (key == appConst?.comment) {
      if (data === undefined && elementData?.data) {
        dispatch(getComments(elementData?.data?.networkId));
      }
    }
    if (key == appConst?.attach) {
      if (fetchData === undefined && elementData?.data) {
        dispatch(getAllFiles(elementData?.data?.networkId));
      }
    }
  };

  const nodeTabs = [
    {
      eventKey: appConst.info,
      title: labels.info,
      compToRend: (
        <ActiveMapElement isPrimaryNodeTab={true} elementData={elementData} />
      ),
    },
    {
      eventKey: appConst.comment,
      title: labels.comments,
      compToRend: <NodeCommentsView activeMapElement={elementData} />,
    },
    {
      eventKey: appConst.attach,
      title: labels.files,
      compToRend: <ElementAttachments activeMapElement={elementData} />,
    },
  ];

  return (
    <div style={{ marginBottom: 10 }}>
      <CustomTabs
        defaultKey={appConst.info}
        tabs={nodeTabs}
        onSelect={handleSelect}
      />
    </div>
  );
}
