import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "mapInfoWindowMenu",
  initialState: {
    originCoord: undefined,
    destinationCoord: undefined,
    totalDistance: 0,
    distancePoints: [],
    distancePolylines: [],
  },
  reducers: {
    setOriginCoord: (menu, action) => {
      menu.originCoord = action.payload;
    },
    setDestinationCoord: (menu, action) => {
      menu.destinationCoord = action.payload;
    },
    setTotalDistance: (menu, action) => {
      menu.totalDistance = action.payload;
    },
    setDistancePoints: (menu, action) => {
      menu.distancePoints = action.payload;
    },
    setDistancePolylines: (menu, action) => {
      menu.distancePolylines = action.payload;
    },
  },
});

export const {
  setOriginCoord,
  setDestinationCoord,
  setTotalDistance,
  setDistancePoints,
  setDistancePolylines,
} = slice.actions;
export default slice.reducer;
