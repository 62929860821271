import { IptvNetwork } from "../../../../models/account/diagnostics/iptv/IptvNetwork";

export const IPTV_CERTI_NETWORK_BEGIN = "IPTV_CERTI_NETWORK_BEGIN";
export const IPTV_CERTI_NETWORK_SUCCESS = "IPTV_CERTI_NETWORK_SUCCESS";
export const IPTV_CERTI_NETWORK_FAILURE = "IPTV_CERTI_NETWORK_FAILURE";

interface IptvCertiNetworkBegin {
  type: typeof IPTV_CERTI_NETWORK_BEGIN;
}

interface IptvCertiNetworkSuccess {
  type: typeof IPTV_CERTI_NETWORK_SUCCESS;
  payload: IptvNetwork;
}

interface IptvCertiNetworkFailure {
  type: typeof IPTV_CERTI_NETWORK_FAILURE;
  payload: Error;
}

export type IptvCertiNetworkActionTypes =
  | IptvCertiNetworkBegin
  | IptvCertiNetworkSuccess
  | IptvCertiNetworkFailure;
