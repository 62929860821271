import {
    SAVE_NEW_FEEDBACK_BEGIN,
    SAVE_NEW_FEEDBACK_FAILURE,
    SAVE_NEW_FEEDBACK_SUCCESS,
    NewFeedbackActionTypes
  } from "../../types/feedback/FeedbackCreation"

export function SaveNewFeedbackBegin(): NewFeedbackActionTypes {
    return {
      type: SAVE_NEW_FEEDBACK_BEGIN,
    };
  }
  
  export function SaveNewFeedbackSuccess(
    activeCerti: any
  ): NewFeedbackActionTypes {
    return {
      type: SAVE_NEW_FEEDBACK_SUCCESS,
      payload: activeCerti,
    };
  }
  
  export function SaveNewFeedbackFailure(
    error: Error
  ): NewFeedbackActionTypes {
    return {
      type: SAVE_NEW_FEEDBACK_FAILURE,
      payload: error,
    };
  }