import { createSlice } from "@reduxjs/toolkit";
import appConst from "../../../../constants/appConst";
import {
  addNodeNum,
  arrangeNodeData,
  getPolyAreaCords,
  getSSEleData,
  FormatAllNodeData
} from "../../../../util/utilFuncs";
import { apiCallBegan } from "../../../middleware/apiActions";
import {
  CLAMSHELLID_URI,
  LCP_URI,
  MEMO_AREA_URI,
  NODE_ACTIVE_RF_EQUIPMENTS_URI,
  NODE_ADDRESS_NO_URI,
  NODE_ANNOTATION_BLOCK_URI,
  NODE_CABLE_RF_DROP_URI,
  NODE_FIBER_ADDRESS_CONNECTION_POINT,
  NODE_FIBER_CABLE_URI,
  NODE_FIBER_SPLICE_CASE,
  NODE_FIBER_STORAGE_AND_RISER,
  NODE_ISP_SITE_URI,
  NODE_MDU_URI,
  NODE_POWER_BLOCK_URI,
  NODE_POWER_INSERTER,
  NODE_POWER_SUPPLY_URI,
  NODE_RF_CABLE_URI,
  NODE_RF_SPLICE_URI,
  NODE_SITE_COMPONENTS,
  NODE_SPLITTER_URI,
  NODE_SUPPORT_STRUCTURE_URI,
  NODE_TAP_URI,
  NODE_TERMINATOR_URI,
  NODE_URI,
  OPTICAL_TAP_URI,
} from "../../../middleware/apiEndpoints";
import storeConst from "../../../storeConst";
import {
  setSelectedMapElement,
  setMapCenter,
  setHighlightedPolygon,
} from "../mapDetails";
import { supportStrucElem } from "../../../../config/mapItemsConfig";
import ConfigConst from "../../../../../constants/ConfigConst";


const slice = createSlice({
  name: "nodeSiteDetail",
  initialState: {
    isTopoEleLoading: false,
    inProgressApiCalls: 0,
    allNodeData: [] as any,
    activeNodeSiteId: undefined,
    activeNodeIndex: undefined,
    activeNodePolyCords: undefined,
    activeNodePolyType: undefined,
  },
  reducers: {
    getNodeSiteBegin: (nsd) => {
      nsd.inProgressApiCalls++;
      nsd.isTopoEleLoading = true;
      const nodeIndex = nsd.allNodeData.findIndex(
        (node:any) => node?.nodeSiteId === nsd?.activeNodeSiteId
      );

      if (nodeIndex === -1) {
        nsd.allNodeData.push({
          nodeSiteId: nsd.activeNodeSiteId,
          isPrimaryNode: true,
          [appConst.node]: {
            isLoading: true,
            data: undefined,
            error: undefined,
            status: "",
          },
        });
      } else {
        nsd.allNodeData[nodeIndex] = {
          ...nsd.allNodeData[nodeIndex],
          nodeSiteId: nsd.activeNodeSiteId,
          isPrimaryNode: true,
          [appConst.node]: {
            isLoading: true,
            data: undefined,
            error: undefined,
            status: "",
          },
        };
      }
    },
    getNodeSiteSuccess: (nsd, { payload }) => {
      nsd.inProgressApiCalls--;
      if (nsd.inProgressApiCalls === 0) nsd.isTopoEleLoading = false;

      const nodeIndex: any = nsd?.activeNodeIndex;
      const nodeSiteID: any = nsd?.activeNodeSiteId;
      const nodeSites = payload?.data?.features;
      let activeNSIndex = nodeSites?.findIndex(
        (ns: any) =>
          ns?.properties?.data?.designation === nodeSiteID ||
          ns?.properties?.data?.designation?.includes(nodeSiteID)
      );
      let nodeData = arrangeNodeData(payload?.data, undefined, nodeSiteID);
      nodeSites?.map((ns: any, i: number) => {
        const newNodeData = addNodeNum(
          nodeData,
          nsd.allNodeData?.[nodeIndex]?.nodeSiteId,
          i
        );
        let nsID = ns?.properties?.data?.designation;
        if (payload?.nodeType === ConfigConst.pon && i === 0) {
          const activeNode: any = nsd.allNodeData?.[nodeIndex]?.node;
          activeNode.isLoading = false;
          activeNode.data = {
            features: newNodeData,
          };
          activeNode.status = storeConst.OK;
        } else if (
          payload?.nodeType === ConfigConst.docsis &&
          (nsID === nodeSiteID ||
            (activeNSIndex === -1 && i === 0) ||
            nsID?.includes(nodeSiteID))
        ) {
          const activeNode: any = nsd.allNodeData?.[nodeIndex]?.node;
          activeNode.isLoading = false;
          activeNode.data = {
            features: newNodeData,
          };
          activeNode.status = storeConst.OK;
        } else {
          nsd.allNodeData.push({
            nodeSiteId: nsID,
            node: {
              isLoading: false,
              data: {
                features: newNodeData,
              },
              error: undefined,
              status: "OK",
            },
          });
        }
      });
    },
    getNodeSiteFailure: (nsd, { payload }) => {
      nsd.inProgressApiCalls--;
      if (nsd.inProgressApiCalls === 0) nsd.isTopoEleLoading = false;
      const nodeIndex: any = nsd?.activeNodeIndex;
      const activeNode: any = nsd.allNodeData?.[nodeIndex]?.node;
      activeNode.isLoading = false;
      activeNode.error = payload?.data;
      activeNode.data = undefined;
      activeNode.status = storeConst.ERROR;
    },
    getCSNodeSitesBegin: (nsd) => {
      nsd.inProgressApiCalls++;
      nsd.isTopoEleLoading = true;
      const nodeIndex = nsd.allNodeData.findIndex(
        (node:any) => node?.nodeSiteId === nsd?.activeNodeSiteId
      );

      if (nodeIndex === -1) {
        nsd.allNodeData.push({
          nodeSiteId: nsd.activeNodeSiteId,
          [appConst.node]: {
            isLoading: true,
            data: undefined,
            error: undefined,
            status: "",
          },
        });
      } else {
        nsd.allNodeData[nodeIndex] = {
          nodeSiteId: nsd.activeNodeSiteId,
          [appConst.node]: {
            isLoading: true,
            data: undefined,
            error: undefined,
            status: "",
          },
        };
      }
    },
    getCSNodeSitesSuccess: (nsd, { payload })  => {
      nsd.inProgressApiCalls--;
      if (nsd.inProgressApiCalls === 0) nsd.isTopoEleLoading = false;
      const nodeIndex: any = nsd?.activeNodeIndex;
      const nodeSiteID: any = nsd?.activeNodeSiteId;
      const nodeSites = payload?.features;
      let activeNSIndex = nodeSites?.findIndex(
        (ns: any) => ns?.properties?.data?.designation === nodeSiteID
      );   
     FormatAllNodeData(nodeSites,nsd.allNodeData );
      nodeSites?.map((ns: any, i: number) => {
        let nsID = ns?.properties?.data?.designation;
        if (nsID === nodeSiteID || (activeNSIndex === -1 && i === 0)) {
          nsd.allNodeData[nodeIndex] = {
            ...nsd.allNodeData[nodeIndex],
            nodeSiteId: nsID,
            [appConst.node]: {
              isLoading: false,
              data: { features: [ns] },
              error: undefined,
              status: storeConst.OK,
            },
          };
        } else {
          nsd.allNodeData.push({
            nodeSiteId: nsID,
            node: {
              isLoading: false,
              data: {
                features: [ns],
              },
              error: undefined,
              status: "OK",
            },
          });
        }
      });
    },
    getCSNodeSitesFailure: (nsd, action) => {
      nsd.inProgressApiCalls--;
      if (nsd.inProgressApiCalls === 0) nsd.isTopoEleLoading = false;
      const nodeIndex: any = nsd?.activeNodeIndex;
      const activeNode: any = nsd.allNodeData?.[nodeIndex]?.node;
      activeNode.isLoading = false;
      activeNode.error = action.payload;
      activeNode.data = undefined;
      activeNode.status = storeConst.ERROR;
    },
    getNodeElementBegin: (nsd, { payload }) => {
      nsd.inProgressApiCalls++;
      if (!nsd.isTopoEleLoading) nsd.isTopoEleLoading = true;
      if (nsd.activeNodeIndex !== -1) {
        const nodeIndex: any = nsd?.activeNodeIndex;
        const activeNode: any = JSON.parse(
          JSON.stringify(nsd.allNodeData[nodeIndex])
        );
        let eleDataObj: any = {};
        if (payload.elementName !== appConst.supportStructure) {
          eleDataObj = {
            [payload.elementName]: {
              isLoading: true,
              data: undefined,
              error: undefined,
              status: "",
            },
          };
        } else {
          supportStrucElem?.map((ss: string) => {
            eleDataObj = {
              ...eleDataObj,
              ...{
                [ss]: {
                  isLoading: true,
                  data: undefined,
                  error: undefined,
                  status: "",
                },
              },
            };
          });
        }
        nsd.allNodeData[nodeIndex] = {
          ...activeNode,
          ...eleDataObj,
        };
      }
    },
    getNodeElementSuccess: (nsd, { payload }) => {
      nsd.inProgressApiCalls--;
      if (nsd.inProgressApiCalls === 0) nsd.isTopoEleLoading = false;
      const nodeIndex: any = nsd?.activeNodeIndex;
      const activeNode: any = nsd.allNodeData[nodeIndex];
      if (payload.elementName !== appConst.supportStructure) {
        const activeElement = activeNode?.[payload.elementName];
        activeElement.isLoading = false;
        activeElement.data = arrangeNodeData(
          { ...payload.data, storedData: payload?.storedData },
          payload.id,
          nsd?.activeNodeSiteId
        );
        activeElement.status = storeConst.OK;
      } else {
        let eleDataObj: any = {};
        supportStrucElem?.map((ss: string) => {
          let ssData = getSSEleData(ss, payload.data);
          eleDataObj = {
            ...eleDataObj,
            ...{
              [ss]: {
                isLoading: false,
                data: arrangeNodeData(
                  ssData,
                  payload.id,
                  nsd?.activeNodeSiteId
                ),
                status: storeConst.OK,
              },
            },
          };
        });
        nsd.allNodeData[nodeIndex] = {
          ...activeNode,
          ...eleDataObj,
        };
      }
    },
    getNodeElementFailure: (nsd, { payload }) => {
      nsd.inProgressApiCalls--;
      if (nsd.inProgressApiCalls === 0) nsd.isTopoEleLoading = false;
      const nodeIndex: any = nsd?.activeNodeIndex;
      const activeNode: any = nsd.allNodeData[nodeIndex];
      if (payload.elementName !== appConst.supportStructure) {
        const activeElement = activeNode?.[payload.elementName];
        activeElement.isLoading = false;
        activeElement.error = payload.data;
        activeElement.data = undefined;
        activeElement.status = storeConst.ERROR;
      } else {
        let eleDataObj: any = {};
        supportStrucElem?.map((ss: string) => {
          eleDataObj = {
            ...eleDataObj,
            ...{
              [ss]: {
                isLoading: false,
                data: undefined,
                error: payload.data,
                status: storeConst.ERROR,
              },
            },
          };
        });
        nsd.allNodeData[nodeIndex] = {
          ...activeNode,
          ...eleDataObj,
        };
      }
    },
    getSiteComponentsBegin: (nsd) => {
      nsd.inProgressApiCalls++;
      nsd.isTopoEleLoading = true;
      if (nsd.activeNodeIndex !== -1) {
        const nodeIndex: any = nsd?.activeNodeIndex;
        const activeNode: any = nsd.allNodeData[nodeIndex];
        const activeElement = activeNode?.[appConst.siteComponents];
        if (activeElement) {
          activeElement.isLoading = true;
          activeElement.data = undefined;
          activeElement.error = undefined;
          activeElement.status = "";
        } else {
          nsd.allNodeData[nodeIndex] = {
            ...activeNode,
            [appConst.siteComponents]: {
              isLoading: true,
              data: undefined,
              error: undefined,
              status: "",
            },
          };
        }
      }
    },
    getSiteComponentsSuccess: (nsd, action) => {
      nsd.inProgressApiCalls--;
      if (nsd.inProgressApiCalls === 0) nsd.isTopoEleLoading = false;
      const nodeIndex: any = nsd?.activeNodeIndex;
      const activeNode: any = nsd.allNodeData[nodeIndex];
      const activeElement = activeNode?.[appConst.siteComponents];
      if (activeElement !== undefined) {
        activeElement.isLoading = false;
        activeElement.data = action.payload;
        activeElement.status = storeConst.OK;
      }
    },
    getSiteComponentsFailure: (nsd, action) => {
      nsd.inProgressApiCalls--;
      if (nsd.inProgressApiCalls === 0) nsd.isTopoEleLoading = false;
      const nodeIndex: any = nsd?.activeNodeIndex;
      const activeNode: any = nsd.allNodeData[nodeIndex];
      const activeElement = activeNode?.[appConst.siteComponents];
      activeElement.isLoading = false;
      activeElement.error = action.payload;
      activeElement.data = undefined;
      activeElement.status = storeConst.ERROR;
    },
    getNodeSiteInfoBegin: (nsd) => {
      nsd.inProgressApiCalls++;
    },
    getNodeSiteInfoSuccess: (nsd, action) => {
      nsd.inProgressApiCalls--;
      if (nsd.inProgressApiCalls === 0) nsd.isTopoEleLoading = false;
      const nodeIndex: any = nsd?.activeNodeIndex;
      const activeNode: any = nsd.allNodeData?.[nodeIndex]?.node;
      let nodeData = arrangeNodeData(
        action.payload,
        undefined,
        nsd?.activeNodeSiteId
      );
      activeNode.data = nodeData;
    },
    getNodeSiteInfoFailure: (nsd) => {
      nsd.inProgressApiCalls--;
      if (nsd.inProgressApiCalls === 0) nsd.isTopoEleLoading = false;
    },
    setActiveNodeSiteId: (nsd, action) => {
      nsd.activeNodeSiteId = action.payload;
    },
    setActiveNodeIndex: (nsd, action) => {
      nsd.activeNodeIndex = action.payload;
    },
    setActiveNodePolyCords: (nsd, action) => {
      nsd.activeNodePolyCords = action.payload;
    },
    setActiveNodePolyType: (nsd, action) => {
      nsd.activeNodePolyType = action.payload;
    },
    createMemoSuccessData: (nsd, action) => {
      const data = action.payload?.data;
      const rootNodeSiteId = action.payload?.rootNodeSiteId;
      const index = nsd.allNodeData?.findIndex(
        (e: any) => e.nodeSiteId === String(rootNodeSiteId)
      );
      if (index !== -1) {
        const rootNodeData: any = nsd.allNodeData[index];
        const memoCommentData = rootNodeData?.[appConst.memoComment];
        const features = memoCommentData?.data?.features;
        features.push({
          ...data,
          properties: {
            ...data?.properties,
            featureId: data?.properties?.data?.networkId,
            rootNodeSiteId: rootNodeSiteId,
          },
        });
      }
    },
    updateMemoSuccessData: (nsd, action) => {
      const data = action.payload?.data;
      const rootNodeSiteId = action.payload?.rootNodeSiteId;
      const index = nsd.allNodeData?.findIndex(
        (e: any) => e.nodeSiteId === String(rootNodeSiteId)
      );
      if (index !== -1) {
        const rootNodeData: any = nsd.allNodeData[index];
        const memoCommentData = rootNodeData?.[appConst.memoComment];
        const features = memoCommentData?.data?.features;
        const commentIndex = features?.findIndex(
          (e: any) =>
            e.properties?.data?.networkId === data?.properties?.data?.networkId
        );
        features[commentIndex] = {
          ...data,
          properties: {
            ...data?.properties,
            featureId: data?.properties?.data?.networkId,
            rootNodeSiteId: rootNodeSiteId,
          },
        };
      }
    },
    deleteMemoSuccessData: (nsd, action) => {
      const data = action.payload?.data;
      const rootNodeSiteId = action.payload?.rootNodeSiteId;
      const index = nsd.allNodeData?.findIndex(
        (e: any) => e.nodeSiteId === String(rootNodeSiteId)
      );
      if (index !== -1) {
        const rootNodeData: any = nsd.allNodeData[index];
        const memoCommentData: any = rootNodeData?.[appConst.memoComment];
        const features: any = memoCommentData?.data?.features;
        const newList = features?.filter(
          (e: any) =>
            e.properties?.data?.networkId !== data?.properties?.data?.networkId
        );
        memoCommentData.data = { ...memoCommentData?.data, features: newList };
      }
    },
  },
});
export const {
  setActiveNodeSiteId,
  setActiveNodeIndex,
  setActiveNodePolyType,
  createMemoSuccessData,
  updateMemoSuccessData,
  deleteMemoSuccessData,
} = slice.actions;

//should not be exposed to outside
const {
  getNodeSiteBegin,
  getNodeSiteSuccess,
  getNodeSiteFailure,
  getCSNodeSitesBegin,
  getCSNodeSitesSuccess,
  getCSNodeSitesFailure,
  getNodeElementBegin,
  getNodeElementSuccess,
  getNodeElementFailure,
  getSiteComponentsBegin,
  getSiteComponentsSuccess,
  getSiteComponentsFailure,
  getNodeSiteInfoBegin,
  getNodeSiteInfoSuccess,
  getNodeSiteInfoFailure,
  setActiveNodePolyCords,
} = slice.actions;
export default slice.reducer;

//action creators
export const getNodeSiteDetail =
  (nodeId: any) => (dispatch: any, getState: any) => {
    const { activeNodeSiteId, allNodeData } =
      getState().slices.topologyState.nodeSiteDetail;
    const { activeMainSearchType } =
      getState().slices.topologyState.topoGeneral;
    const nodeIndex = allNodeData?.findIndex(
      (node: any) => node.nodeSiteId === nodeId
    );

    if (activeNodeSiteId !== nodeId) dispatch(setActiveNodeSiteId(nodeId));
    if (nodeIndex === -1) {
      dispatch(
        setActiveNodeIndex(allNodeData.length > 0 ? allNodeData.length : 0)
      );
    } else {
      if (activeNodeSiteId !== nodeId) dispatch(setActiveNodeIndex(nodeIndex));
    }
    if (
      nodeIndex === -1 ||
      allNodeData?.[nodeIndex]?.[appConst.node]?.status === storeConst.ERROR
    ) {
      let nodeType =
        activeMainSearchType?.type === appConst.GPON_NODE
          ? ConfigConst.pon
          : ConfigConst.docsis;
      let reqPayload = {
        nodeId: nodeId,
        nodeType: nodeType?.toUpperCase(),
      };
      dispatch(
        apiCallBegan({
          url: NODE_URI,
          params: reqPayload,
          onStart: getNodeSiteBegin.type,
          onSuccess: getNodeSiteSuccess.type,
          onError: getNodeSiteFailure.type,
          props: { nodeType },
        })
      );
    } else {
      dispatch(setCenterAndCallApis());
    }
  };

export const getNodeSiteByClamshellId =
  (clamshellId: any) => (dispatch: any, getState: any) => {
    const { activeNodeSiteId, allNodeData } =
      getState().slices.topologyState.nodeSiteDetail;
    const nodeIndex = allNodeData?.findIndex(
      (node: any) => node.nodeSiteId === clamshellId
    );

    if (activeNodeSiteId !== clamshellId)
      dispatch(setActiveNodeSiteId(clamshellId));
    if (nodeIndex === -1) {
      dispatch(
        setActiveNodeIndex(allNodeData.length > 0 ? allNodeData.length : 0)
      );
    } else {
      if (activeNodeSiteId !== clamshellId)
        dispatch(setActiveNodeIndex(nodeIndex));
    }
    if (
      nodeIndex === -1 ||
      allNodeData?.[nodeIndex]?.[appConst.node]?.status === storeConst.ERROR
    ) {
      let reqPayload = {
        clamshellId: clamshellId,
      };
      dispatch(
        apiCallBegan({
          url: CLAMSHELLID_URI,
          params: reqPayload,
          onStart: getNodeSiteBegin.type,
          onSuccess: getNodeSiteSuccess.type,
          onError: getNodeSiteFailure.type,
          props: { nodeType: ConfigConst?.docsis },
        })
      );
    } else {
      dispatch(setCenterAndCallApis());
    }
  };

export const getClamShellDetail =
  (data: any) => (dispatch: any, getState: any) => {
    const { activeNodeSiteId, allNodeData } =
      getState().slices.topologyState.nodeSiteDetail;

    // get polygon coordinates and save in redux
    const coordsArray: any = [];
    data?.geometry?.map((coords: any) => {
      coordsArray.push([coords?.lng, coords?.lat]);
    });
    let nodePolyCords = getPolyAreaCords(coordsArray);
    dispatch(setActiveNodePolyCords(nodePolyCords));

    const nodeId = data?.properties?.data?.polygonName;
    const nodeIndex = allNodeData?.findIndex(
      (node: any) => node.nodeSiteId === nodeId
    );
    if (activeNodeSiteId !== nodeId) dispatch(setActiveNodeSiteId(nodeId));
    if (nodeIndex === -1) {
      dispatch(
        setActiveNodeIndex(allNodeData.length > 0 ? allNodeData.length : 0)
      );
      dispatch(
        apiCallBegan({
          url: NODE_URI,
          data: nodePolyCords,
          method: "post",
          onStart: getCSNodeSitesBegin.type,
          onSuccess: getCSNodeSitesSuccess.type,
          onError: getCSNodeSitesFailure.type,
        })
      );
    } else {
      if (activeNodeSiteId !== nodeId) dispatch(setActiveNodeIndex(nodeIndex));
    }
    dispatch(setCenterAndCallApis(true));
  };

export const getNodeSiteComponents =
  (nodeNetworkId: any) => (dispatch: any, getState: any) => {
    const { activeNodeIndex, allNodeData } =
      getState().slices.topologyState.nodeSiteDetail;
    const activeNode = allNodeData?.[activeNodeIndex];
    if (
      !activeNode?.[appConst.siteComponents] ||
      activeNode?.[appConst.siteComponents]?.status === storeConst.ERROR
    ) {
      dispatch(
        apiCallBegan({
          url: NODE_SITE_COMPONENTS + nodeNetworkId,
          onStart: getSiteComponentsBegin.type,
          onSuccess: getSiteComponentsSuccess.type,
          onError: getSiteComponentsFailure.type,
        })
      );
    }
  };

export const setCenterAndCallApis =
  (isFromClamShell = false): any =>
  (dispatch: any, getState: any) => {
    const { mapFilters } = getState().slices.topologyState.mapDetails;
    const { activeNodeIndex, allNodeData, activeNodePolyCords } =
      getState().slices.topologyState.nodeSiteDetail;

    const activeNode = allNodeData?.[activeNodeIndex];
    const nodeSiteData = activeNode?.[appConst.node]?.data;
    const activeNodePolyType =
      nodeSiteData?.features?.[0]?.properties?.data?.polygonType;
    if (activeNodePolyType) {
      dispatch(setActiveNodePolyType(activeNodePolyType));
    }
    if (!isFromClamShell) {
      const data = nodeSiteData?.features?.[0]?.properties?.data;
      const activeElement = {
        geometry: {
          lat: data?.siteCoords?.coordinates?.[1],
          lng: data?.siteCoords?.coordinates?.[0],
        },
        properties: {
          id: nodeSiteData?.features?.[0]?.properties?.featureId,
          type: nodeSiteData?.features?.[0]?.properties?.type,
          data: nodeSiteData?.features?.[0]?.properties?.data,
        },
      };
      dispatch(setHighlightedPolygon(nodeSiteData?.features?.[0]));
      dispatch(setSelectedMapElement(activeElement));
      dispatch(
        setMapCenter({
          lat: data?.siteCoords?.coordinates?.[1],
          lng: data?.siteCoords?.coordinates?.[0],
        })
      );
    }

    const makeElementApiCall = (
      elementName: string,
      url: string,
      id = "networkId"
    ) => {
      //Make API call if filter is checked and (element doesnt exist or its a failed api response)
      if (
        nodePolyCords?.topRight !== undefined &&
        nodePolyCords?.bottomLeft !== undefined &&
        mapFilters?.[elementName] &&
        (!activeNode?.[elementName] ||
          activeNode?.[elementName]?.status === storeConst.ERROR)
      ) {
        dispatch(
          apiCallBegan({
            url,
            data: nodePolyCords,
            method: "post",
            onStart: getNodeElementBegin.type,
            onSuccess: getNodeElementSuccess.type,
            onError: getNodeElementFailure.type,
            props: { elementName, id },
          })
        );
      }
    };

    let nodePolyCords = getPolyAreaCords(
      nodeSiteData?.features?.[0]?.geometry?.coordinates?.[0]
    );

    if (isFromClamShell) nodePolyCords = activeNodePolyCords;
    if (nodePolyCords?.topRight && nodePolyCords?.bottomLeft) {
      dispatch(setActiveNodePolyCords(nodePolyCords));
    }

    if (activeNodePolyType?.toLowerCase() !== appConst.phub) {
      makeElementApiCall(appConst.fiberCable, NODE_FIBER_CABLE_URI);
      makeElementApiCall(appConst.spliceCases, NODE_FIBER_SPLICE_CASE);
      makeElementApiCall(appConst.taps, NODE_TAP_URI);
      makeElementApiCall(appConst.splitter, NODE_SPLITTER_URI);
      makeElementApiCall(appConst.powerBlock, NODE_POWER_BLOCK_URI);
      makeElementApiCall(appConst.rfCable, NODE_RF_CABLE_URI);
      makeElementApiCall(appConst.terminator, NODE_TERMINATOR_URI);
      makeElementApiCall(appConst.powerSupply, NODE_POWER_SUPPLY_URI);
      makeElementApiCall(appConst.rfDrop, NODE_CABLE_RF_DROP_URI);
      makeElementApiCall(appConst.rfdropaddress, NODE_ADDRESS_NO_URI);
      makeElementApiCall(appConst.mdu, NODE_MDU_URI);
      makeElementApiCall(appConst.supportStructure, NODE_SUPPORT_STRUCTURE_URI);
      makeElementApiCall(appConst.rfSplice, NODE_RF_SPLICE_URI);
      makeElementApiCall(
        appConst.activeEquipment,
        NODE_ACTIVE_RF_EQUIPMENTS_URI
      );
      makeElementApiCall(appConst.annotationBlock, NODE_ANNOTATION_BLOCK_URI);
      makeElementApiCall(
        appConst.fiberStorageAndRiser,
        NODE_FIBER_STORAGE_AND_RISER
      );
      makeElementApiCall(
        appConst.fiberAddrConnectionPoint,
        NODE_FIBER_ADDRESS_CONNECTION_POINT
      );
      makeElementApiCall(appConst.powerInserter, NODE_POWER_INSERTER);
      makeElementApiCall(appConst.memoComment, MEMO_AREA_URI);
      makeElementApiCall(appConst.opticalTap, OPTICAL_TAP_URI);
      makeElementApiCall(appConst.LCP, LCP_URI);
      makeElementApiCall(appConst.ispSite, NODE_ISP_SITE_URI);
    }
  };

export const handleFilterSelection =
  (filterType: any): any =>
  (dispatch: any, getState: any) => {
    const { activeNodeIndex, allNodeData, activeNodePolyCords } =
      getState().slices.topologyState.nodeSiteDetail;

    const activeNode = allNodeData?.[activeNodeIndex];
    const nodeSiteData = activeNode?.[appConst.node]?.data;

    if (activeNodePolyCords || nodeSiteData !== undefined) {
      const makeElementApiCall = (
        elementName: string,
        url: string,
        id = "networkId"
      ) => {
        if (activeNode?.[elementName]?.data === undefined) {
          dispatch(
            apiCallBegan({
              url,
              data: activeNodePolyCords,
              method: "post",
              onStart: getNodeElementBegin.type,
              onSuccess: getNodeElementSuccess.type,
              onError: getNodeElementFailure.type,
              props: { elementName, id },
            })
          );
        }
      };

      switch (filterType) {
        case appConst.fiberCable:
          makeElementApiCall(appConst.fiberCable, NODE_FIBER_CABLE_URI);
          break;
        case appConst.spliceCases:
          makeElementApiCall(appConst.spliceCases, NODE_FIBER_SPLICE_CASE);
          break;
        case appConst.taps:
          makeElementApiCall(appConst.taps, NODE_TAP_URI);
          break;
        case appConst.splitter:
          makeElementApiCall(appConst.splitter, NODE_SPLITTER_URI);
          break;
        case appConst.powerBlock:
          makeElementApiCall(appConst.powerBlock, NODE_POWER_BLOCK_URI);
          break;
        case appConst.rfCable:
          makeElementApiCall(appConst.rfCable, NODE_RF_CABLE_URI);
          break;
        case appConst.terminator:
          makeElementApiCall(appConst.terminator, NODE_TERMINATOR_URI);
          break;
        case appConst.powerSupply:
          makeElementApiCall(appConst.powerSupply, NODE_POWER_SUPPLY_URI);
          break;
        case appConst.rfDrop:
          makeElementApiCall(appConst.rfDrop, NODE_CABLE_RF_DROP_URI);
          break;
        case appConst.rfdropaddress:
          makeElementApiCall(appConst.rfdropaddress, NODE_ADDRESS_NO_URI);
          break;
        case appConst.mdu:
          makeElementApiCall(appConst.mdu, NODE_MDU_URI);
          break;
        case appConst.supportStructure:
          makeElementApiCall(
            appConst.supportStructure,
            NODE_SUPPORT_STRUCTURE_URI
          );
          break;
        case appConst.rfSplice:
          makeElementApiCall(appConst.rfSplice, NODE_RF_SPLICE_URI);
          break;
        case appConst.activeEquipment:
          makeElementApiCall(
            appConst.activeEquipment,
            NODE_ACTIVE_RF_EQUIPMENTS_URI
          );
          break;
        case appConst.annotationBlock:
          makeElementApiCall(
            appConst.annotationBlock,
            NODE_ANNOTATION_BLOCK_URI
          );
          break;
        case appConst.fiberStorageAndRiser:
          makeElementApiCall(
            appConst.fiberStorageAndRiser,
            NODE_FIBER_STORAGE_AND_RISER
          );
          break;
        case appConst.fiberAddrConnectionPoint:
          makeElementApiCall(
            appConst.fiberAddrConnectionPoint,
            NODE_FIBER_ADDRESS_CONNECTION_POINT
          );
          break;
        case appConst.powerInserter:
          makeElementApiCall(appConst.powerInserter, NODE_POWER_INSERTER);
          break;
        case appConst.memoComment:
          makeElementApiCall(appConst.memoComment, MEMO_AREA_URI);
          break;
        case appConst.opticalTap:
          makeElementApiCall(appConst.opticalTap, OPTICAL_TAP_URI);
          break;
        case appConst.LCP:
          makeElementApiCall(appConst.LCP, LCP_URI);
          break;
        case appConst.ispSite:
          makeElementApiCall(appConst.ispSite, NODE_ISP_SITE_URI);
          break;
        default:
          break;
      }
    }
  };

interface CutomEleType {
  elementName: string;
  url: string;
  storedData?: any; //any other data you would like to store in element
}
export const getCustomElement =
  ({ elementName, url, storedData }: CutomEleType): any =>
  (dispatch: any, getState: any) => {
    const { activeNodeIndex, allNodeData } =
      getState().slices.topologyState.nodeSiteDetail;

    const activeNode = allNodeData?.[activeNodeIndex];
    const nodeSiteData = activeNode?.[appConst.node]?.data;

    if (nodeSiteData !== undefined) {
      if (activeNode?.[elementName]?.data === undefined) {
        dispatch(
          apiCallBegan({
            url,
            onStart: getNodeElementBegin.type,
            onSuccess: getNodeElementSuccess.type,
            onError: getNodeElementFailure.type,
            props: { elementName, id: "networkId", storedData },
          })
        );
      }
    }
  };

export const getNodeSiteInfo =
  (nodeId: any) => (dispatch: any, getState: any) => {
    let nodeType = ConfigConst.docsis;
    let reqPayload = {
      nodeId: nodeId,
      nodeType: nodeType?.toUpperCase(),
    };
    dispatch(
      apiCallBegan({
        url: NODE_URI,
        params: reqPayload,
        onStart: getNodeSiteInfoBegin.type,
        onSuccess: getNodeSiteInfoSuccess.type,
        onError: getNodeSiteInfoFailure.type,
      })
    );
  };
