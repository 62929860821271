import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import ExceptionBox from "../../components/reusable/ExceptionBox";
import AppLabels from "../../constants/App_Labels";
import "../../design-tokens/pingPage/pingPage.scss";
import LoaderGif from "../../resources/images/loader.webp";

import { GetPingDetails } from "../../get-api/GetResponse";
import { getStreamIpData } from "../../store/slices/ping/pingData";

const PingPage = (props: any) => {
  const { location: search } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const labels = AppLabels();
  const [dataPing, setPingData] = useState(null);
  const searchParams = new URLSearchParams(search.search);
  const numberOfPing = searchParams.get("numberOfPing");
  const ipAddress = searchParams.get("ipAddress");
  const [dataArray, setdataArray] = useState<any>();
  const { data, status, error } = GetPingDetails();
  const [responses, setResponses] = useState<string[]>([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [formattedData, setFormattedData] = useState("");
  const [statistics, setStatistics] = useState({});
  const [pingResponses, setPingResponses] = useState<string>("");
  const [statsObj, setStatsObj] = useState<any | null>(null);

  const getPingResponseWithColor = (responses: string[]): string[] => {
    return responses.map((line: string) => {
      const timeMatch = line.match(/(time=(\d+(\.\d+)?) ms)/);
      if (timeMatch && parseFloat(timeMatch[2]) >= 500) {
        const highlightedTime = `<span style="background-color: yellow;">${timeMatch[1]}</span>`;
        return line.replace(timeMatch[1], highlightedTime);
      }
      return line;
    });
  };

  useEffect(() => {
    if (data) {
      const cleanedData = data.replace(/data:/g, "").trim();
      const dataArray = cleanedData.split("\n");

      const statisticsStartIndex = dataArray.findIndex((line: any) =>
        line.includes("statistics")
      );

      const responseData = dataArray.slice(0, statisticsStartIndex);
      const coloredResponses = getPingResponseWithColor(responseData);
      setPingResponses(coloredResponses.join("\n"));

      if (statisticsStartIndex > -1) {
        const statisticsArray = dataArray
          .slice(statisticsStartIndex)
          .filter((line: any) => line.trim() !== "");
        const packetStatsLine = statisticsArray.slice(1, 3).join(" ");

        setStatsObj(packetStatsLine);
      }
    }
  }, [data]);

  useEffect(() => {
    // dispatch(getStreamIpData("172.16.19.56", "10"));
    dispatch(getStreamIpData(ipAddress, numberOfPing));
  }, []);

  return (
    <div className="tabsCont">
      {data != undefined ? (
        <>
          <>
            <section className="InputCont">
              <div className="SearchCont">
                <div className="SeleCont">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label style={{ fontSize: "24px", fontWeight: "bold" }}>
                      PING ADDRESS: {ipAddress}
                    </label>

                    <label style={{ fontSize: "18px", fontWeight: 600 }}>
                      NO. OF PINGS: {numberOfPing}
                    </label>
                  </div>
                </div>
              </div>
            </section>
          </>

          {/* // ) : data !== undefined ? ( */}
          <section className="InputCont">
            <div className="SearchCont">
              <div className="container">
                <div className="SeleCont">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label style={{ fontSize: "18px", fontWeight: 500 }}>
                      PING {ipAddress} ({ipAddress}) 56 (84) bytes of data.
                    </label>
                    <hr className="tabDivider" />

                    <div className="content">
                      <div
                        style={{
                          whiteSpace: "pre",
                          display: "block",
                          fontSize: "15px",
                          lineHeight: "0.8",
                        }}
                        dangerouslySetInnerHTML={{ __html: pingResponses }}
                      ></div>
                    </div>

                    <br />
                    <label style={{ fontSize: "18px", fontWeight: 500 }}>
                      {ipAddress} Ping Statistics
                    </label>
                    <hr className="tabDivider" />

                    {statsObj && (
                      <div
                        style={{
                          whiteSpace: "pre",
                          display: "block",
                          fontSize: "15px",
                        }}
                      >
                        {statsObj}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : error || status === "ERROR" ? (
        <div className="diagErrorCont">
          <ExceptionBox
            headerText={"No Data to display for Ping: " + ipAddress}
            exBoxType="error"
            errCode={error?.errorCode}
            ErrorMSG={error?.errorMessage}
            srcSystem={error?.sourceSystem + " " + error?.responseFromSource}
            bodyText={error?.responseFromSource}
            primaryBtn={false}
            secBtn={false}
          />
        </div>
      ) : (
        <img className="loaderImg" src={LoaderGif} />
      )}
    </div>
  );
};
export default PingPage;
