import {
    SAVE_NEW_COMMENT_BEGIN,
    SAVE_NEW_COMMENT_FAILURE,
    SAVE_NEW_COMMENT_SUCCESS,
    NewCommentActionTypes
  } from "../../types/feedback/FeedbackCommentCreation"

export function SaveNewCommentBegin(): NewCommentActionTypes {
    return {
      type: SAVE_NEW_COMMENT_BEGIN,
    };
  }
  
  export function SaveNewCommentSuccess(
    activeCerti: any
  ): NewCommentActionTypes {
    return {
      type: SAVE_NEW_COMMENT_SUCCESS,
      payload: activeCerti,
    };
  }
  
  export function SaveNewCommentFailure(
    error: Error
  ): NewCommentActionTypes {
    return {
      type: SAVE_NEW_COMMENT_FAILURE,
      payload: error,
    };
  }