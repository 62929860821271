
  
  export const HARD_ZAP_BEGIN = "HARD_ZAP_BEGIN";
  export const HARD_ZAP_SUCCESS = "HARD_ZAP_SUCCESS";
  export const HARD_ZAP_FAILURE = "HARD_ZAP_FAILURE";
  export const HARD_ZAP_RESET_STATUS = "HARD_ZAP_RESET_STATUS";
  interface HardZapBegin {
      type: typeof HARD_ZAP_BEGIN
  }
  interface HardZapSuccess {
      type: typeof HARD_ZAP_SUCCESS
  };
  
  interface HardZapFailure {
      type: typeof HARD_ZAP_FAILURE
      payload: Error
  };
  interface HardZapReset {
      type: typeof HARD_ZAP_RESET_STATUS
  };
  export type HardZapActionTypes = HardZapBegin | HardZapSuccess | HardZapFailure | HardZapReset