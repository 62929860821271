import FailedAttributesCm from "./FailedAttributesCm";
import FailedAttributesIptv from "./FailedAttributesIptv";
import FailedAttributesOnt from "./FailedAttributesOnt";
import FailedAttributesOdu from "./FailedAttributesOdu";
import FailedAttributesStb from "./FailedAttributesStb";
import FailedAttributesRhp from "./FailedAttributesRhp";
import MarginalPassAttributesCm from "./MarginalPassAttributesCm";

const FailedAttributesCertSum = (props: any) => {
  const { selectedDevice, deviceDetails } = props;

  return (
    <>
      {selectedDevice?.deviceCategory === "CM" ? (
        <>
          <FailedAttributesCm cmDocsisInfo={deviceDetails?.docsis} />
          <MarginalPassAttributesCm cmDocsisInfo={deviceDetails?.docsis} />
        </>
      ) : selectedDevice?.deviceCategory === "IPTV" ? (
        <FailedAttributesIptv
          ethernetData={deviceDetails?.ethernet}
          wifiData={deviceDetails?.wifi}
        />
      ) : selectedDevice?.deviceCategory === "ONT" ? (
        <FailedAttributesOnt ontSummaryInfo={deviceDetails?.summary} />
      ) : selectedDevice?.deviceCategory === "ODU" ? (
        <FailedAttributesOdu oduWirelessInfo={deviceDetails?.wirelessInfo} />
      ) : selectedDevice?.deviceCategory === "STB" ? (
        <>
          {((deviceDetails?.summary &&
            deviceDetails?.summary?.overallStatus === "Pass") ||
            deviceDetails?.summary === undefined) &&
          deviceDetails?.docsis &&
          deviceDetails?.docsis?.overallStatus === "Fail" ? (
            <FailedAttributesStb
              stbDocsisInfo={deviceDetails?.docsis}
              type="stbDocsisInfo"
            />
          ) : (
            <></>
          )}
          {deviceDetails?.summary &&
          deviceDetails?.summary?.overallStatus === "Fail" &&
          ((deviceDetails?.docsis &&
            deviceDetails?.docsis?.overallStatus === "Pass") ||
            deviceDetails?.docsis === undefined) ? (
            <FailedAttributesStb
              stbSummaryInfo={deviceDetails?.summary}
              type="stbSummaryInfo"
            />
          ) : (
            <></>
          )}
          {deviceDetails?.summary &&
          deviceDetails?.summary?.overallStatus === "Fail" &&
          deviceDetails?.docsis &&
          deviceDetails?.docsis?.overallStatus === "Fail" ? (
            <FailedAttributesStb
              stbSummaryInfo={deviceDetails?.summary}
              stbDocsisInfo={deviceDetails?.docsis}
              type="both"
            />
          ) : (
            <></>
          )}
        </>
      ) : selectedDevice?.deviceCategory === "DESTB" ? (
        <>
          {((deviceDetails?.summary &&
            deviceDetails?.summary?.overallStatus === "Pass") ||
            deviceDetails?.summary === undefined) &&
          deviceDetails?.docsis &&
          deviceDetails?.docsis?.overallStatus === "Fail" ? (
            <FailedAttributesStb
              stbDocsisInfo={deviceDetails?.docsis}
              type="stbDocsisInfo"
            />
          ) : (
            <></>
          )}
          {deviceDetails?.summary &&
          deviceDetails?.summary?.overallStatus === "Fail" &&
          ((deviceDetails?.docsis &&
            deviceDetails?.docsis?.overallStatus === "Pass") ||
            deviceDetails?.docsis === undefined) ? (
            <FailedAttributesStb
              stbSummaryInfo={deviceDetails?.summary}
              type="stbSummaryInfo"
            />
          ) : (
            <></>
          )}
          {deviceDetails?.summary &&
          deviceDetails?.summary?.overallStatus === "Fail" &&
          deviceDetails?.docsis &&
          deviceDetails?.docsis?.overallStatus === "Fail" ? (
            <FailedAttributesStb
              stbSummaryInfo={deviceDetails?.summary}
              stbDocsisInfo={deviceDetails?.docsis}
              type="both"
            />
          ) : (
            <></>
          )}
        </>
      ) : selectedDevice?.deviceCategory === "EMTA" ? (
        <FailedAttributesRhp rhpDocsisInfo={deviceDetails?.docsis} />
      ) : (
        <></>
      )}
    </>
  );
};

export default FailedAttributesCertSum;
