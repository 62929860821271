import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../middleware/apiActions";
import { MDU_ACTIVE } from "../../../middleware/apiEndpoints";
import storeConst from "../../../storeConst";
import { decrApiCall, incrApiCall } from "../mapDetails";

const slice = createSlice({
  name: "mduActives",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
  },
  reducers: {
    getMduActivesBegin: (mduActives) => {
      mduActives.isLoading = true;
      mduActives.status = "";
    },
    getMduActivesSuccess: (mduActives, action) => {
      mduActives.isLoading = false;
      mduActives.data = action.payload;
      mduActives.status = storeConst.OK;
    },
    getMduActivesFailure: (mduActives, action) => {
      mduActives.isLoading = false;
      mduActives.error = action.payload;
      mduActives.data = undefined;
      mduActives.status = storeConst.ERROR;
    },
  },
});

const { getMduActivesBegin, getMduActivesSuccess, getMduActivesFailure } =
  slice.actions;
export default slice.reducer;

export const getMduActives = (owningDrawing: any) => (dispatch: any) => {
  dispatch(
    apiCallBegan({
      url: `${MDU_ACTIVE}/${owningDrawing}`,
      method: "get",
      onStart: getMduActivesBegin.type,
      onSuccess: getMduActivesSuccess.type,
      onError: getMduActivesFailure.type,
      onStartDispatch: incrApiCall.type,
      onSuccessDispatch: decrApiCall.type,
      onErrorDispatch: decrApiCall.type,
    })
  );
};
