import { createSlice } from "@reduxjs/toolkit";
import { changeTimeFormat } from "../../../components/reusable/Util";
import appConst from "../../../../../sqv-web/src/topology/constants/appConst";
import {
  convertStreamDataToJson,
  isCoordsAvaileable,
  isNumeric,
  mergeDatasets,
} from "../../../../../sqv-web/src/topology/util/utilFuncs";
import { TELE_ABORT_KEY } from "../../../../../sqv-web/src/topology/store/middleware/abortKeys";
import { apiCallBegan } from "../../../../../sqv-web/src/topology/store/middleware/apiActions";
import { NetworkConfig } from "../NetworkConfig";
import { DiagExecutionType } from "../../../../../sqv-web/src/topology/store/reduxTypes";
import storeConst from "../../../../../sqv-web/src/topology/store/storeConst";
import { UniversalSearchTypes } from "../../../../../sqv-web/src/topology/store/reduxTypes";
import { setActiveMainSearchType } from "../../../../../sqv-web/src/topology/store/slices/topoGeneral";

let telemetryData: any = [];
const batchSize = (window as any).config?.TELEMETRY_BATCH_SIZE ?? 5;
const slice = createSlice({
  name: "PONPorttelemetry",
  initialState: {
    oltIsLoading: false, //ml = modemsList
    oltData: undefined,
    oltError: undefined,
    oltStatus: "",
    nodeId: undefined,
    advFCond: appConst.or.toUpperCase(),
    activeProfile: appConst.default.toUpperCase(),
    advancedFilters: undefined,
    allIsLoading: false,
    isDevicesVisible: false,
    isAborted: false,
    apiCallCounter: 0, //used for stop telemetry button
    diagStartTime: undefined,
    diagEndTime: undefined,
    diagType: storeConst.all as DiagExecutionType, //diagnostics type, eg (all, summary)
    viewType: storeConst.all,
    allDiagStatus: "",
    allDiagError: undefined,
   
  },
  reducers: {
    
    setNodeId: (tl, { payload }) => {
      tl.nodeId = payload;
    },
   
    getONTPonPortBegin: (tl) => {
        let startTime: any = changeTimeFormat(Date.now());
        tl.diagStartTime = startTime;
        tl.apiCallCounter++;
        tl.oltIsLoading = true;
        tl.oltStatus = "";
        tl.isAborted = false;
      
        tl.diagEndTime = undefined;
        tl.allDiagStatus = "";
      },
      getONTPonPortSuccess: (tl, { payload }) => {    
        let endTime: any = changeTimeFormat(Date.now());
              tl.oltIsLoading= false;
              tl.allIsLoading = false;
              tl.apiCallCounter--;
              tl.diagEndTime = endTime;
              tl.oltData = payload;
              tl.allDiagStatus = storeConst.OK;
              tl.allDiagError = undefined;
      
  
  },
      getONTPonPortFailure: (tl, { payload }) => {
        let endTime: any = changeTimeFormat(Date.now());
        tl.oltIsLoading = false;
        tl.apiCallCounter--;
        tl.oltError = payload;
        tl.oltData = undefined;
        tl.oltStatus = storeConst.ERROR;
      }, 
    setAllDiagLoading: (tl, { payload }) => {
      let currMlData: any = JSON.parse(JSON.stringify(tl.oltData));
      tl.allIsLoading = payload;
      if (!payload && telemetryData?.length > 0) {
        tl.oltData = mergeDatasets(currMlData, telemetryData);
        telemetryData = [];
      }
    },
   
  },
});

export const {
  setAllDiagLoading,
  setNodeId,

} = slice.actions;

const {

    getONTPonPortBegin,
    getONTPonPortSuccess,
    getONTPonPortFailure
} = slice.actions; //should not be exposed to outside
export default slice.reducer;


interface DiagRequestType {
    oltName?: String;
  node?: string;
  accType?: string;
  ponPort?: String;

}



  export const getOLTPONData =
  ({
    oltName,

  }: DiagRequestType) =>
  (dispatch: any) => {
    
    const diagUrl = "diag/network/oltName/" +oltName
    //    dispatch(
    //     setActiveMainSearchType({
    //       type: appConst.GPON_NODE as UniversalSearchTypes,
    //     })
    //   );
    dispatch(
      apiCallBegan({
        baseURL: NetworkConfig.NETWORK_SERVICE_URL,
        url: diagUrl,
        method: "get",    
        // responseType: "stream",
        abortKey: TELE_ABORT_KEY,
        onStart: getONTPonPortBegin.type,
        onSuccess: getONTPonPortSuccess.type,
        onError: getONTPonPortFailure.type,
        
      })
    );
  };