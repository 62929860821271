import { createSlice } from "@reduxjs/toolkit";
import storeConst from "../../storeConst";

const slice = createSlice({
  name: "nptSummaryMac",
  initialState: {
    isLoading: false,
    data: undefined,
    error: undefined,
    status: "",
    nptId: "",
  },
  reducers: {
    nptSummaryMacBegin: (rpd) => {
      rpd.isLoading = true;
    },
    nptSummaryMacSuccess: (rpd, action) => {
      rpd.isLoading = false;
      rpd.data = action.payload;
      rpd.error = undefined;
      rpd.status = storeConst.OK;
    },
    nptSummaryMacFailure: (rpd, action) => {
      rpd.isLoading = false;
      rpd.error = action.payload;
      rpd.data = undefined;
      rpd.status = storeConst.ERROR;
    },
    setNPTId: (rpd, action) => {
      rpd.nptId = action.payload;
    },
    nptSummaryMacReset: (rpd) => {
      rpd.isLoading = false;
      rpd.error = undefined;
      rpd.data = undefined;
      rpd.status = "";
      rpd.nptId = "";
    },
  },
});

export const {
  nptSummaryMacBegin,
  nptSummaryMacSuccess,
  nptSummaryMacFailure,
  setNPTId,
  nptSummaryMacReset,
} = slice.actions;
export default slice.reducer;
