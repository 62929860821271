import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import appConst from "../../../constants/appConst";
import { setMapCenter } from "../../../store/slices/mapItems/mapDetails";
import { GetCpatEventsProps } from "../../../util/reduxFunctions/getTopologyState";
import CPATFilters from "./CPATFilters";
import CPATTable from "./CPATTable";

export default function CpatMainScreen() {
  const dispatch = useDispatch();
  const { data, status } = GetCpatEventsProps();
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  useEffect(() => {
    if (status === appConst.OK && isButtonClicked) {
      if (data?.features?.length !== 0) {
        const siteCoords = data?.features?.[0]?.geometry?.coordinates;
        dispatch(
          setMapCenter({
            lat: siteCoords?.[1],
            lng: siteCoords?.[0],
          })
        );
      }
    }
  }, [data, isButtonClicked]);
  return (
    <div className="cpatTabCont">
      <CPATFilters setIsButtonClicked={setIsButtonClicked} />
      <CPATTable />
    </div>
  );
}
