import NodePage from "./NodePage";
import TopologyHome from "../../topology/screens/TopologyHome";
import appConst from "../../topology/constants/appConst";
import { GetNodeGeneralProps } from "../../get-api/GetResponse";
import { PONONTPage } from "../gpon-page/PONONTPage";
import { useEffect, useState } from "react";
export default function NodeTabs(props: any) {
  const { nodeTopologyTab } = GetNodeGeneralProps();
  const [mountTopology, setMountTopology] = useState(false);
console.log("node tabs")
  useEffect(() => {
    if (nodeTopologyTab === appConst.topology && !mountTopology)
      setMountTopology(true);
  }, [nodeTopologyTab]);

  const getContClassName = (t: string) =>
    nodeTopologyTab === t ? "activeNodeTabSwitch" : "inactiveNodeTabSwitch";

  return (
    <div>
      <div className={getContClassName(appConst.node)}>
        <PONONTPage {...props} />
      </div>
      {mountTopology && (
        <div className={getContClassName(appConst.topology)}>
          <TopologyHome {...props} />
        </div>
      )}
    </div>
  );
}

