import { Divider } from "@material-ui/core";
import { useEffect, useState } from "react";
import { CircleFill, DiamondFill } from "react-bootstrap-icons";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceArea,
} from "recharts";
import AppLabels from "../../constants/App_Labels";
import ConfigConst from "../../constants/ConfigConst";
import colors from "../../topology/config/colors";
import appConst from "../../topology/constants/appConst";
import {
  CHART_DYNAMIC_Y_AXIS_GAP,
  CHART_FIXED_Y_AXIS_GAP,
  CHART_Y_AXIS_TYPE,
} from "../../topology/constants/storageConst";
import CustomDropDown from "../reusable/CustomDropDown";
import {
  getChartTicks,
  getColorForChartDot,
  getShapeNameForChartDot,
} from "../reusable/Util";

export const customUSChartLegend = (chartType: string, ofdmChs: any) => {
  return (
    <div className="legendCont">
      <span className="scQamCh">
        <CircleFill color={`var(--link)`} />
        SCQAM-{chartType === "power" ? "TX" : "SNR"}
      </span>
      <span className="ofdmCh">
        <DiamondFill color={`var(--purple)`} />
        OFDMA-{chartType === "power" ? "TX" : "2PCT"}
      </span>
    </div>
  );
};

export const CustomChartDot = (props: any) => {
  const { cx, cy, name, payload, value } = props;
  if (value) {
    let iconSize = 6;
    let iconShape = getShapeNameForChartDot(name);
    let iconColor = getColorForChartDot(name, payload);
    if (iconShape === appConst.diamond) {
      iconSize = 7;
      return (
        <svg
          x={cx - iconSize / 2}
          y={cy - iconSize / 2}
          height={iconSize}
          width={iconSize}
          viewBox="0 0 10 10"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0"
            y="5"
            width="7"
            height="7"
            transform="rotate(-45 0 5)"
            fill={iconColor}
          />
        </svg>
      );
    }
    return (
      <svg
        x={cx - iconSize / 2}
        y={cy - iconSize / 2}
        height={iconSize}
        width={iconSize}
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
      >
        <circle cx="10" cy="10" r="10" />
      </svg>
    );
  } else return <></>;
};

export const CustomChartTooltip = (props: any) => {
  const { active, payload, label } = props;
  if (active && payload && payload.length) {
    return (
      <div className="chartToolTipCont">
        <p className="ttTitle">FREQ: {label}</p>
        <span className="ttAttrValue">
          {payload[0]?.name}: {payload[0]?.value}
        </span>
      </div>
    );
  }

  return null;
};
interface Props {
  cardLabel?: any;
  chartRanges: any;
  scQamChs: any;
  ofdmaChs: any;
}

const CmUsChart = ({ cardLabel, chartRanges, scQamChs, ofdmaChs }: Props) => {
  const labels = AppLabels();
  const axisTypes = ["Fixed", "Dynamic"];
  const yAxisDynamicDDOpt = ["2", "5", "7"];
  const yAxisFixedDDOpt = ["-10/+10", "-15/+15", "-20/+20"];
  const savedYAxisType: any = localStorage.getItem(CHART_Y_AXIS_TYPE);

  const [chartData, setChartData] = useState(scQamChs ? scQamChs : []);
  const [activeAxisType, setActiveAxisType] = useState(
    savedYAxisType ? savedYAxisType : "Dynamic"
  );
  const [activeYAxisDDOpt, setActiveYAxisDDOpt] = useState(yAxisDynamicDDOpt);
  const [activeYAxisGap, setActiveYAxisGap] = useState("2");

  const [powerTicks, setPowerTicks] = useState([]);
  const [noiseTicks, setNoiseTicks] = useState([]);

  const savedFixedYAxisGap: any = localStorage.getItem(CHART_FIXED_Y_AXIS_GAP);
  const savedDynamicYAxisGap: any = localStorage.getItem(
    CHART_DYNAMIC_Y_AXIS_GAP
  );

  useEffect(() => {
    if (ofdmaChs) {
      ofdmaChs?.map((ch: any) => {
        setChartData([
          ...[
            {
              frequency: ch?.freqUpperBoundary,
              ofdmaTx: ch?.txPower,
              ofdmaPct: ch?.mer?.thrVal,
            },
            {
              frequency: ch?.freqLowerBoundary,
              ofdmaTx: ch?.txPower,
              ofdmaPct: ch?.mer?.thrVal,
            },
          ],
          ...chartData,
        ]);
      });
    }
    /* const savedYAxisGap: any = localStorage.getItem(CHART_DYNAMIC_Y_AXIS_GAP);
    if (savedYAxisGap) setYAxisGap(parseInt(savedYAxisGap)); */
  }, []);

  useEffect(() => {
    if (chartRanges && activeYAxisGap && activeAxisType) {
      if (activeAxisType === "Dynamic") {
        changeChartRanges(
          chartRanges?.minVals?.power,
          chartRanges?.maxVals?.power,
          chartRanges?.minVals?.noise,
          chartRanges?.maxVals?.noise,
          activeYAxisGap
        );
      } else if (activeAxisType === "Fixed") {
        let minPower = "";
        let maxPower = "";
        let yAxisGap = "";
        switch (activeYAxisGap) {
          case "-10/+10":
            minPower = "-10";
            maxPower = "10";
            yAxisGap = "5";
            break;
          case "-15/+15":
            minPower = "-15";
            maxPower = "15";
            yAxisGap = "5";
            break;
          case "-20/+20":
            minPower = "-20";
            maxPower = "20";
            yAxisGap = "10";
            break;
        }
        changeChartRanges(
          parseFloat(minPower),
          parseFloat(maxPower),
          chartRanges?.minVals?.noise,
          chartRanges?.maxVals?.noise,
          yAxisGap
        );
      }
    }
  }, [activeYAxisGap, chartRanges]);

  useEffect(() => {
    if (activeAxisType) {
      switch (activeAxisType) {
        case "Fixed":
          setActiveYAxisDDOpt(yAxisFixedDDOpt);
          if (savedFixedYAxisGap && savedFixedYAxisGap !== "-5/+5")
            setActiveYAxisGap(savedFixedYAxisGap);
          else {
            setActiveYAxisGap(yAxisFixedDDOpt[0]);
            localStorage.setItem(CHART_FIXED_Y_AXIS_GAP, yAxisFixedDDOpt[0]);
          }
          break;
        case "Dynamic":
          setActiveYAxisDDOpt(yAxisDynamicDDOpt);
          if (savedDynamicYAxisGap) setActiveYAxisGap(savedDynamicYAxisGap);
          else {
            setActiveYAxisGap(yAxisDynamicDDOpt[0]);
            localStorage.setItem(
              CHART_DYNAMIC_Y_AXIS_GAP,
              yAxisDynamicDDOpt[0]
            );
          }
          break;
      }
    }
  }, [activeAxisType]);

  const changeChartRanges = (
    minPower: any,
    maxPower: any,
    minNoise: any,
    maxNoise: any,
    yAxisGapVal: any
  ) => {
    let pTicks: any = getChartTicks(minPower, maxPower, parseInt(yAxisGapVal));
    let nTicks: any = getChartTicks(minNoise, maxNoise, parseInt(yAxisGapVal));

    if (pTicks && pTicks?.length > 0) setPowerTicks(pTicks);
    if (nTicks && nTicks?.length > 0) setNoiseTicks(nTicks);
  };

  const changeYAxisGap = (yAxisDDVal: any) => {
    setActiveYAxisGap(yAxisDDVal);
    if (activeAxisType === "Fixed") {
      localStorage.setItem(CHART_FIXED_Y_AXIS_GAP, yAxisDDVal);
    } else {
      localStorage.setItem(CHART_DYNAMIC_Y_AXIS_GAP, yAxisDDVal);
    }
  };

  const changeAxisType = (axisType: string) => {
    if (axisType) {
      setActiveAxisType(axisType);
      localStorage.setItem(CHART_Y_AXIS_TYPE, axisType);
    }
  };
  return (
    <>
      {chartData && chartData?.length > 0 && (
        <section className="chChartCont">
          <p className="cmChDetailHead">
            <b>{cardLabel}</b>
            <div className="chartActionCont">
              <CustomDropDown
                ddTitle="Type"
                selectedOption={activeAxisType}
                options={axisTypes}
                searchFlag={false}
                checkboxFlag={false}
                setSelectedOption={(value: any) => {
                  changeAxisType(value);
                }}
              />
              <CustomDropDown
                ddTitle="Y-Axis"
                selectedOption={`${activeYAxisGap}`}
                options={activeYAxisDDOpt}
                searchFlag={false}
                checkboxFlag={false}
                setSelectedOption={(value: any) => {
                  changeYAxisGap(value);
                }}
              />
            </div>
          </p>
          <Divider />
          <div className="chChartBody">
            <p>{labels.power.toUpperCase()}</p>
            <ResponsiveContainer
              width="100%"
              height="100%"
              minHeight="30vh"
              minWidth="50vw"
            >
              <LineChart width={1000} height={400} data={chartData}>
                <Line
                  type="monotone"
                  dataKey="txPower.value"
                  stroke={`var(--link)`}
                  name={ConfigConst?.scQamTx?.toUpperCase()}
                  dot={<CustomChartDot />}
                />
                <Line
                  type="monotone"
                  dataKey="ofdmaTx.value"
                  stroke={`var(--purple)`}
                  name={ConfigConst?.ofdmaTx?.toUpperCase()}
                  dot={<CustomChartDot />}
                />
                <ReferenceArea
                  x1={ofdmaChs?.[0]?.freqLowerBoundary}
                  x2={ofdmaChs?.[0]?.freqUpperBoundary}
                  fill={`var(--purple)`}
                  fillOpacity={0.3}
                />
                <CartesianGrid stroke={colors.black70} strokeDasharray="3 3" />
                <XAxis
                  dataKey="frequency"
                  type="number"
                  domain={[
                    chartRanges?.minVals?.freq,
                    chartRanges?.maxVals?.freq,
                  ]}
                />
                <YAxis
                  type="number"
                  ticks={powerTicks}
                  domain={[powerTicks[0], powerTicks[powerTicks?.length - 1]]}
                />
                <Tooltip content={<CustomChartTooltip />} />
                <Legend content={customUSChartLegend("power", ofdmaChs)} />
              </LineChart>
            </ResponsiveContainer>
            <Divider />
            <p>
              {labels.SNR.toUpperCase()}/{labels.secondPCT.toUpperCase()}
            </p>
            <ResponsiveContainer
              width="100%"
              height="100%"
              minHeight="30vh"
              minWidth="50vw"
            >
              <LineChart width={1000} height={400} data={chartData}>
                <Line
                  type="monotone"
                  dataKey="snr.value"
                  stroke={`var(--link)`}
                  name={ConfigConst?.scQamSnr?.toUpperCase()}
                  dot={<CustomChartDot />}
                />
                <Line
                  type="monotone"
                  dataKey="ofdmaPct.value"
                  stroke={`var(--purple)`}
                  name={ConfigConst?.ofdmaPct?.toUpperCase()}
                  dot={<CustomChartDot />}
                />
                <ReferenceArea
                  x1={ofdmaChs?.[0]?.freqLowerBoundary}
                  x2={ofdmaChs?.[0]?.freqUpperBoundary}
                  fill={`var(--purple)`}
                  fillOpacity={0.3}
                />
                <CartesianGrid stroke={colors.black70} strokeDasharray="3 3" />
                <XAxis
                  dataKey="frequency"
                  type="number"
                  domain={[
                    chartRanges?.minVals?.freq,
                    chartRanges?.maxVals?.freq,
                  ]}
                />
                <YAxis
                  type="number"
                  ticks={noiseTicks}
                  domain={[noiseTicks[0], noiseTicks[noiseTicks?.length - 1]]}
                />
                <Tooltip content={<CustomChartTooltip />} />
                <Legend content={customUSChartLegend("noise", ofdmaChs)} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </section>
      )}
    </>
  );
};

export default CmUsChart;
