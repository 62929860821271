import { useState } from "react";
import { Carousel } from "react-bootstrap";
import { GrNext, GrPrevious } from "react-icons/gr";
import '../../design-tokens/header/NotificationBox.scss';

const CustomCarousel = (props: any) => {
  const { images } = props;
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: any, e: any) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      {images !== undefined && images?.length !== 0 ? (
        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          controls={images?.length > 1 ? true : false}
          interval={null}
          prevIcon={<GrPrevious size={30}/>}
          nextIcon={<GrNext size={30}/>}
          indicators={images?.length > 1 ? true : false}
          className="ntfCarousel"
        >
          {images?.map((img: any, i: number) => {
            return (
              <Carousel.Item key={i}>
                <img
                  className="d-block m1-auto mr-auto"
                  src={img?.image}
                  alt="First slide"
                  style={{pointerEvents: "none"}}
                />
              </Carousel.Item>
            );
          })}
        </Carousel>
      ) : (
        <></>
      )}
    </>
  );
};

export default CustomCarousel;
