import { combineReducers } from "redux";
import mduReducer from "./mduReducer";
import configs from "./slices/configs";
import googleMaps from "./slices/googleMaps";
import mapDrawer from "./slices/mapDrawer";
import mapInfoWindowMenu from "./slices/mapInfoWindowMenu";
import clickOrders from "./slices/mapItems/clickOrders";
import comments from "./slices/mapItems/comments/comments";
import cpatEvents from "./slices/mapItems/cpat/cpatEvents";
import telemetry from "./slices/mapItems/diagnostics/telemetry";
import mapDetails from "./slices/mapItems/mapDetails";
import mapPolygons from "./slices/mapItems/mapPolygons";
import mrGroupedIssues from "./slices/mapItems/microreflections/mrGroupedIssues";
import samkeyAddress from "./slices/mapItems/microreflections/samkeyAddress";
import nikaOrder from "./slices/mapItems/nika/nikaOrder";
import nodeSiteDetail from "./slices/mapItems/node/nodeSiteDetail";
import nodeTapAddress from "./slices/mapItems/node/tap/nodeTapAddress";
import addressSearch from "./slices/search/addressSearch";
import renSearch from "./slices/search/renSearch";
import symbols from "./slices/symbols";
import topoGeneral from "./slices/topoGeneral";
import topologyConfig from "./slices/topologyConfig";
import memo from "./slices/mapItems/memo/memo";
import settings from "./slices/drawer/settings";
import gponSpliceMap from "./slices/drawer/gponSpliceMap";
import gponSplitters from "./slices/drawer/passive/gponSplitters";
import gponUsages from "./slices/drawer/passive/gponUsages";
import powertrace from "./slices/mapItems/powertrace/powertrace";
import oltCards from "./slices/drawer/olt/oltCards";
import utilToolAppData from "./slices/drawer/utiltool/UtilityToolAppConfig";
import elementAttachments from "./slices/mapItems/elementAttachments";
import rfDownstreamTrace from "./slices/mapItems/trace/rf/rfDownstreamTrace";
import rfUpstreamTrace from "./slices/mapItems/trace/rf/rfUpstreamTrace";
import vhubSearch from "./slices/mapItems/vhub/vhubSearch";
import vhubClamshellSearch from "./slices/mapItems/vhub/vhubClamshellSearch";
import locatorElements from "./slices/mapItems/locatorElements";
import wirelessSiteSearch from "./slices/search/wirelessSiteSearch";
import smallCellSearch from "./slices/search/smallCellSearch";
import clamshellSearch from "./slices/search/clamshellSearch";
import rbCircuitSearch from "./slices/search/rbCircuitSearch";
import redlineSlice from "./slices/redlineSlice";
import oltSearch from "./slices/search/oltSearch";
import fiberUpstreamTrace from "./slices/mapItems/trace/fiber/fiberUpstreamTrace";

export default combineReducers({
  googleMaps,
  mapPolygons,
  mapDetails,
  nodeSiteDetail,
  nodeTapAddress,
  nikaOrder,
  mapDrawer,
  telemetry,
  cpatEvents,
  mduElements: mduReducer,
  mapInfoWindowMenu,
  mrGroupedIssues,
  samkeyAddress,
  addressSearch,
  renSearch,
  symbols,
  configs,
  clickOrders,
  topologyConfig,
  topoGeneral,
  comments,
  memo,
  settings,
  gponSpliceMap,
  gponSplitters,
  gponUsages,
  powertrace,
  oltCards,
  utilToolAppData,
  elementAttachments,
  rfDownstreamTrace,
  rfUpstreamTrace,
  vhubSearch,
  vhubClamshellSearch,
  locatorElements,
  wirelessSiteSearch,
  smallCellSearch,
  clamshellSearch,
  rbCircuitSearch,
  redlineSlice,
  oltSearch,
  fiberUpstreamTrace,
});
