const NodeTableHead = (props: any) => {
  let columns = [
    "Home Address",
    "Serial Number",
    "Model",
    "Olt Name",
    "Pon Port",
    "Last Change Time",
    "Operational Status",
    "Provisional Status",
    "Optical TX",
    "Optical RX",
    "Admin Status",
    "Optical Distance",
    "Ethernet Current Status",
    "Ethernet Operational Status",
    "Ethernet Admin Status",
    "Ethernet Config Indicator",
    "SW Version",
  ];
  return (
    <thead className="nodeTabHead">
      <tr>
        {columns?.map((c: any) => {
          return (
            <th>
              <span>{c}</span>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default NodeTableHead;
