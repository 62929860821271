import { ONTProfile} from "../../../../models/account/diagnostics/ont/ONTProfile";

export const ONT_Profile_BEGIN = "ONT_Profile_BEGIN";
export const ONT_Profile_SUCCESS = "ONT_Profile_SUCCESS";
export const ONT_Profile_FAILURE = "ONT_Profile_FAILURE";

interface ONTProfileBegin {
  type: typeof ONT_Profile_BEGIN;
}

interface ONTProfileSuccess {
  type: typeof ONT_Profile_SUCCESS;
  payload: ONTProfile;
}

interface ONTProfileFailure {
  type: typeof ONT_Profile_FAILURE;
  payload: Error;
}

export type ONTProfileActionTypes =
  | ONTProfileBegin
  | ONTProfileSuccess
  | ONTProfileFailure;
