import { useState, useEffect, useRef, CSSProperties } from "react";
import { Card, Modal } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CustAccICon from "../../resources/images/icons/custAccIcon.svg";
import {
  SetSelectedServiceAddress,
  SetActiveAccountDetails,
  AccReset,
  EnableWHTFlag,
  SetAccountSearchValue,
  SetDeviceSearchValue,
} from "../../store/actions/account/accountInfo/ActiveAccountAction";
import { ResetSearchAccState } from "../../store/actions/account/accountInfo/AccountDetailsAction";
import { IoClose } from "react-icons/io5";
import { Divider } from "@material-ui/core";
import ErrorBox from "../reusable/ErrorBox";
import AppLabels from "../../constants/App_Labels";
import {
  isAccAvailable,
  isNull,
  isNodeAvailable,
  setCurrentWindowName,
  openElectroline,
} from "../reusable/Util";
import {
  SetActiveAccountKey,
  UpdateAccount,
} from "../../store/actions/account/AllAccountsAction";
import {
  SetActiveKey,
  UpdateNode,
} from "../../store/actions/node/AllNodesAction";
import {
  SetActiveNodeInfoDetails,
  NodeReset,
} from "../../store/actions/node/nodeinfo/ActiveNodeInfoAction";
import { ResetNodeInfoState } from "../../store/actions/node/nodeinfo/NodeInfoDetailsAction";

import { Checkbox, FormControlLabel } from "@material-ui/core";
import { FaCheckSquare } from "react-icons/fa";
import { ImCheckboxUnchecked } from "react-icons/im";
import SearchBoxMiniNode from "./SearchBoxMiniNode";
import {
  GetNodeInfoDetails,
  GetOltNameSearchDetails,
  GetEmtaTNDetails,
} from "../../get-api/GetResponse";
import { resetOltNameDetails } from "../../../src/store/slices/gpon/oltNameSearchDetails";
import { GponNodesInfoReset } from "../../store/actions/gpon/GponNodeInfoAction";
import { setDiagType } from "../../topology/store/slices/mapItems/diagnostics/telemetry";
import ConfigConst from "../../constants/ConfigConst";

export const setHistoryDisplay = (search: any) => {
  if (search !== "" && search !== undefined) {
    let sHistory: any = localStorage.getItem("searchHistory");
    let CurrsearchHistory = JSON.parse(sHistory);
    if (
      CurrsearchHistory === null ||
      CurrsearchHistory === undefined ||
      CurrsearchHistory?.length === 0
    ) {
      localStorage.setItem("searchHistory", JSON.stringify([search]));
    } else {
      if (CurrsearchHistory.indexOf(search) > -1) {
        CurrsearchHistory.splice(CurrsearchHistory.indexOf(search), 1);
      }
      CurrsearchHistory.push(search);
      localStorage.setItem("searchHistory", JSON.stringify(CurrsearchHistory));
    }
  }
};

interface Props {
  selectedValue?: any;
  searchTypeChange?: any;
  searchValue?: any;
  deviceKey?: any;
  workOrderID?: any;
  wHTFlag?: any;
  handleWHTChecked?: any;
  errorBoxStyle?: CSSProperties;
  searchHistory?: any;
}

const SearchBoxMini = ({
  selectedValue,
  searchTypeChange,
  searchValue,
  deviceKey,
  workOrderID,
  wHTFlag,
  handleWHTChecked,
  errorBoxStyle,
  searchHistory,
}: Props) => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const labels = AppLabels();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("This is Error Message");
  const [show, setShow] = useState(false);

  const handleDGClose = () => {
    setShow(false);
    dispatch(ResetSearchAccState());
    /*Changes added by node Team*/
    dispatch(GponNodesInfoReset());
    dispatch(resetOltNameDetails());
    dispatch(ResetNodeInfoState());
    /*node Team changes completed*/
    history.replace(`/`);
  };
  const handleDGShow = () => setShow(true);
  const handleClose = () => {
    setOpen(false);
    dispatch(ResetSearchAccState());
    /*Changes added by node Team*/
    dispatch(GponNodesInfoReset());
    dispatch(resetOltNameDetails());
    dispatch(ResetNodeInfoState());
    /*node Team changes completed*/
    history.replace(`/`);
  };
  const [serviceAddress, setServiceAddress] = useState<String[]>([]);
  const {
    searchAccStatus,
    currdata,
    data,
    currAccState,
    allAccounts,
    gponNodeData,
    gponNodeStatus,
    accSearchValue,
    deviceSearchValue,
  } = useSelector(
    (state: any) => ({
      searchAccStatus: state.AccountState.AccountDetails.status,
      currdata: state.AccountState.ActiveAccountDetails.data,
      data: state.AccountState.AccountDetails.data,
      currAccState: state.AccountState,
      allAccounts: state.AllAccounts.accounts,
      gponNodeStatus: state.GponState.GponNodeInfo.status,
      gponNodeData: state.GponState.GponNodeInfo.data,
      accSearchValue:
        state.AccountState.ActiveAccountDetails.accountSearchValue,
      deviceSearchValue:
        state.AccountState.ActiveAccountDetails.deviceSearchValue,
    }),
    shallowEqual
  );
  const {
    searchNodeStatus,
    currNodedata,
    nodedata,
    currNodeState,
    allNodes,
    searchType,
  } = GetNodeInfoDetails();
  const { oltResultData, oltStatus } = GetOltNameSearchDetails();
  const { emtaTNStatus, emtaTNdata } = GetEmtaTNDetails();

  const openNodePage = (node: any) => {
    let currDataId = currNodedata?.smt
      ? currNodedata?.smtId
      : currNodedata?.nodeId;
    let urlString = "";
    const isEmta = window && window.location.hash.includes("emta");

    let id = node?.smt ? node?.smtId : node?.nodeId;
    let windowName = isEmta
      ? "EMTA- " + id
      : node?.smt
      ? "S-" + node?.smtId
      : "N-" + node?.nodeId;
    const modemType = isEmta ? "emta" : "";
    setCurrentWindowName(windowName);

    if (modemType.length > 0) {
      urlString = window.location.hash.includes("emta")
        ? `${window.location.hash.replace("#", "")}`
        : `/node`;
    } else {
      urlString = node?.smt ? `/node?smtId=${id}` : `/node?nodeId=${id}`;
    }

    if (currNodedata !== undefined && currNodeState !== undefined) {
      dispatch(UpdateNode(currDataId, currNodeState));
    }

    if (isNodeAvailable(allNodes, id)) {
      if (currDataId === id) {
        return history.replace(`${urlString}`);
      } else {
        dispatch(SetActiveKey(id));
        return history.replace(`${urlString}`);
      }
    } else {
      dispatch(NodeReset());
      dispatch(setDiagType(searchParams?.get("dataSet") || "all"));
      dispatch(SetActiveNodeInfoDetails(node));
      dispatch(SetActiveKey(id));
      return history.replace(`${urlString}`);
    }
  };

  const openOltPage = (oltData: any) => {
    if (searchValue !== undefined) {
      let windowName = oltData !== undefined ? "OLT-" + searchValue : "OLT-";
      setCurrentWindowName(windowName);
      let urlString = oltData !== undefined ? "/node?oltName" : "/";
      let currsearchHist = JSON.stringify(history);
      const {
        location: { search },
      } = JSON.parse(currsearchHist);
      if (search?.includes("oltName")) {
        history.push(`${urlString}=${searchValue}`);
      }
    }
  };

  /*node related changes completed*/

  const openAccPage = (sa: String) => {
    let updateDeviceSearchValue = deviceSearchValue ? deviceSearchValue : "";
    const iscmMac = window && window.location.hash.includes("mac");
    const urlParamMac: string = iscmMac
      ? window.location.hash.substring(
          window.location.hash.indexOf("mac=") + 4,
          window.location.hash.length
        )
      : "";

    let urlString: any =
      deviceKey !== undefined && deviceKey !== ""
        ? `/account?accountNumber=${data.accountNumber}&deviceKey=${
            urlParamMac ? urlParamMac : deviceKey
          }`
        : `/account?accountNumber=${data.accountNumber}`;

    if (currdata !== undefined && currAccState !== undefined) {
      dispatch(UpdateAccount(currdata?.accountNumber, currAccState));
    }
    if (isAccAvailable(allAccounts, data?.accountNumber)) {
      if (currdata?.accountNumber === data?.accountNumber) {
        history.push(`${urlString}`);
      } else {
        dispatch(SetActiveAccountKey(data?.accountNumber));
        history.push(`${urlString}`);
      }
    } else {
      dispatch(AccReset());
      dispatch(SetActiveAccountDetails(data));
      dispatch(SetActiveAccountKey(data?.accountNumber));
      dispatch(SetSelectedServiceAddress(sa));

      if (
        (deviceKey && deviceKey !== "") ||
        (urlParamMac && urlParamMac !== "")
      ) {
        dispatch(SetAccountSearchValue(urlParamMac ? urlParamMac : deviceKey));
        dispatch(SetDeviceSearchValue(updateDeviceSearchValue));
      } else if (searchValue && searchValue !== "") {
        dispatch(SetAccountSearchValue(searchValue));
        dispatch(SetDeviceSearchValue(updateDeviceSearchValue));
      } else if (accSearchValue && accSearchValue !== "") {
        dispatch(SetAccountSearchValue(accSearchValue));
        dispatch(SetDeviceSearchValue(updateDeviceSearchValue));
      }
      if (wHTFlag) {
        dispatch(EnableWHTFlag(wHTFlag));
      }
      if (workOrderID !== undefined && workOrderID !== "") {
        history.push(`${urlString}&workorderId=${workOrderID}`);
      } else {
        history.push(`${urlString}`);
      }
    }
  };
  useEffect(() => {
    if (searchAccStatus === "OK" && data !== "") {
      setHistoryDisplay(searchValue);
      let addresses: String[] = [];
      data.serviceAddress?.map((sa: any) => {
        let address = "";
        if (sa.apartmentNumber === "") {
          address =
            "" +
            sa.streetNumber +
            " " +
            sa.streetName +
            " " +
            sa.streetType +
            ", " +
            sa.city +
            ", " +
            sa.province +
            " " +
            sa.postalCode;
        } else {
          address =
            "" +
            sa.apartmentNumber +
            "-" +
            sa.streetNumber +
            " " +
            sa.streetName +
            " " +
            sa.streetType +
            ", " +
            sa.city +
            ", " +
            sa.province +
            " " +
            sa.postalCode;
        }
        addresses.push(address);
      });
      setServiceAddress(addresses);
      handleDGShow();
      if (addresses && addresses.length === 1) {
        openAccPage(addresses[0]);
      }
    } else if (
      searchAccStatus === "ERROR" &&
      data === undefined &&
      searchValue.length === 9 &&
      selectedValue === "Account Number"
    ) {
      searchTypeChange("cbpId");
    } else if (
      searchAccStatus === "ERROR" &&
      data === undefined &&
      searchValue.length === 12 &&
      selectedValue === "Account Number"
    ) {
      /* 12 digit numeric entry could also be mac address */
      searchTypeChange("macAddress");
    } else if (
      searchAccStatus === "ERROR" &&
      data === undefined &&
      selectedValue === "cbpId"
    ) {
      setError(
        "Sorry! There is no Account matching your input. Please check and try again."
      );
      setOpen(true);
    } else if (searchAccStatus === "ERROR" && data === undefined) {
      setError(
        "Sorry! There is no Account matching your input. Please check and try again."
      );
      setOpen(true);
    } else if (searchAccStatus === "OK" && data === "") {
      setError(
        "Sorry! There is no Account matching your input. Please check and try again."
      );
      setOpen(true);
    }
  }, [searchAccStatus]);
  /*Changes added by Node Team to show popup of node after succesful search */

  useEffect(() => {
    if (searchNodeStatus === "OK" && JSON.stringify(nodedata) !== '[""]') {
      setHistoryDisplay(searchValue);
      handleDGShow();
      if (searchType === ConfigConst?.electroline) {
        if (nodedata[0]?.electrolineServer != undefined)
          openElectroline(nodedata[0]?.electrolineServer);
        else {
          setError(
            "Sorry! This is RPHY node and it does not have electroline server."
          );
          setOpen(true);
        }
      }
    }
  }, [nodedata]);

  useEffect(() => {
    if (searchNodeStatus === "OK" && JSON.stringify(nodedata) !== '[""]') {
      setHistoryDisplay(searchValue);
      handleDGShow();
      if (
        nodedata &&
        nodedata.length === 1 &&
        searchType !== ConfigConst?.electroline
      ) {
        openNodePage(nodedata[0]);
      }
    } else if (searchNodeStatus === "ERROR" && nodedata === undefined) {
      setError(
        "Sorry! There is no Node matching your input. Please check and try again."
      );
      setOpen(true);
    } else if (
      searchNodeStatus === "OK" &&
      JSON.stringify(nodedata) === '[""]'
    ) {
      setError(
        "Sorry! There is no Node matching your input. Please check and try again."
      );
      setOpen(true);
    } else if (gponNodeStatus === "OK" && gponNodeData !== undefined) {
      setHistoryDisplay(searchValue);
    } else if (gponNodeStatus === "ERROR" && gponNodeData === undefined) {
      setError(
        "Sorry! There is no Gpon Node matching your input. Please check and try again."
      );
      setOpen(true);
    }
  }, [searchNodeStatus, gponNodeStatus]);

  useEffect(() => {
    if (oltStatus === "OK" && JSON.stringify(oltResultData) !== '[""]') {
      setHistoryDisplay(searchValue);
    } else if (oltStatus === "ERROR" && oltResultData === undefined) {
      setError(
        "Sorry! There is no OLT matching your input. Please check and try again."
      );
      setOpen(true);
    } else if (oltStatus === "OK" && JSON.stringify(oltResultData) === '[""]') {
      setError(
        "Sorry! There is no OLT matching your input. Please check and try again."
      );
      setOpen(true);
    }
  }, [oltStatus]);
  /*Changes by Node Team end */

  useEffect(() => {
    if (emtaTNStatus === "OK" && JSON.stringify(emtaTNdata) !== '{""}') {
      setHistoryDisplay(searchValue);
    } else if (emtaTNStatus === "ERROR" && emtaTNdata === undefined) {
      setError(
        "Sorry! There is no Device matching your input. Please check and try again."
      );
      setOpen(true);
    } else if (emtaTNStatus === "OK" && JSON.stringify(emtaTNdata) === '{""}') {
      setError(
        "Sorry! There is no Device matching your input. Please check and try again."
      );
      setOpen(true);
    }
  }, [emtaTNStatus]);
  return (
    <>
      {searchAccStatus === "OK" ? (
        <Modal
          show={show}
          onHide={handleDGClose}
          backdrop="static"
          keyboard={false}
          centered
          id="sAPopUp"
        >
          <Modal.Header className="searchPopHead">
            <header>{labels.SEARCH_RESULTS}</header>
            <button className="sPopUpClose" onClick={handleDGClose}>
              <IoClose color="white" />
            </button>
          </Modal.Header>
          <Modal.Body>
            <FormControlLabel
              className="WHTCheck"
              control={
                <Checkbox
                  checkedIcon={<FaCheckSquare color="#da291c" />}
                  icon={<ImCheckboxUnchecked color="#da291c" />}
                  checked={wHTFlag}
                  onChange={handleWHTChecked}
                />
              }
              label="Run Whole Home Test"
            />

            <div className="SearchResultCont">
              {serviceAddress?.map((sa: any, i: number) => {
                return (
                  <Card
                    key={i}
                    className="resultCard"
                    onClick={() => openAccPage(sa)}
                  >
                    <span className="resultCardIcon">
                      <img src={CustAccICon} alt="accIcon" />
                    </span>
                    <span className="resultCardDetails">
                      <h5>
                        {isNull(data.firstName)} {isNull(data.lastName)}
                      </h5>
                      <h5>
                        {labels.ACCOUNT_NUMBER.toUpperCase()}:&nbsp;
                        <span>{data.accountNumber}</span>
                      </h5>
                      <h5>
                        {labels.SERVICE_ADDRESS.toUpperCase()}:&nbsp;
                        <span>{sa}</span>
                      </h5>
                    </span>
                  </Card>
                );
              })}
            </div>
          </Modal.Body>
          <Divider orientation="horizontal" />
          <Modal.Footer>
            <button className="sPopCloseBtn" onClick={handleDGClose}>
              {labels.CLOSE_BTN_TEXT}
            </button>
          </Modal.Footer>
        </Modal>
      ) : (
        searchType !== ConfigConst?.electroline &&
        searchNodeStatus === "OK" &&
        nodedata !== undefined &&
        JSON.stringify(nodedata) != "" &&
        JSON.stringify(nodedata) !== '[""]' && (
          <SearchBoxMiniNode
            show={show}
            handleDGClose={handleDGClose}
            nodedata={nodedata}
            openNodePage={openNodePage}
          />
        )
      )}
      <ErrorBox
        open={open}
        error={error}
        handleClose={handleClose}
        style={errorBoxStyle}
      />
    </>
  );
};
export default SearchBoxMini;
