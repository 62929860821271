import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { FaCircle } from "react-icons/fa";
import { isBoolValue, isNull } from "../reusable/Util";
import AppLabels from "../../constants/App_Labels";
import CustomAccordion from "../reusable/CustomAccordion";
import { checkRSSIValue, getRSSIValue} from "../reusable/Util";
import CustomPopOver from "../reusable/CustomPopOver";
import ExceptionBox from "../reusable/ExceptionBox";
import DiagApiCallCont from "../reusable/DiagApiCallCont";
import xleVisiblilityClient from "../../network/httpclient/accountInfo/xleVisiblilityClient";
import { useDispatch } from "react-redux";
import { GetXLEGWDetails,GetBatteryDetails } from "../../get-api/GetResponse";
import ReloadData from "../reusable/ReloadData";
import { Alert } from "@material-ui/lab";

const XLEPodDiagnostics = (props: any) => {
  const labels = AppLabels();
  const dispatch = useDispatch();
  const { gwSummarydata}= GetXLEGWDetails();
  const { selPOD, selPodIndex, podStatus, podsLoading, podW3ID } = props;
  const [key, setKey] = useState("POD");
  const { batterydata,batteryLoading,batteryStatus}= GetBatteryDetails();
  const selPodData = selPOD !== undefined && selPOD[selPodIndex];
  let status = !podStatus?.hasOwnProperty("errorCode") ? "OK" : "ERROR";

  const renderPodsStatus = (podStatus: string) => {
    if (podStatus !== "N/A" && podStatus !== "") {
      let rssi_val = parseInt(podStatus);
      if (rssi_val <= -55 && rssi_val >= -70)
        return (
          <span style={{ color: "#28A745" }}>
            <FaCircle />
            <span style={{ fontWeight: 700 }}>PASS</span>
          </span>
        );
      else
        return (
          <span style={{ color: "#BF371F" }}>
            <FaCircle />
            <span style={{ fontWeight: 700 }}>FAIL</span>
          </span>
        );
    }
  };

  const refreshData = () => {
    if(gwSummarydata?.[0]?.backup_cpe_mac){

        dispatch(
          xleVisiblilityClient.getXleCellular( gwSummarydata?.[0]?.backup_cpe_mac)     
        );
        dispatch(
          xleVisiblilityClient.getBattery( gwSummarydata?.[0]?.backup_cpe_mac)     
        );
      }
    
  };

  return (
    <>
      <div className="detailDiagCont">
        <section className="deviceData">
          <div className="deviceStatusCont">
            <p>
              <span>{labels.MODEL.toUpperCase()} : </span>
              <span>{isNull(selPodData?.model)}</span>
            </p>
            <p className="deviceOverallStat">
              <span>STATUS : </span>
              {isNull(selPodData?.hasOwnProperty("statistics")) &&
              isNull(selPodData?.statistics?.rssi) &&
              selPodData?.statistics?.hasOwnProperty("rssi") ? (
                renderPodsStatus(selPodData?.statistics?.rssi)
              ) : (
                <></>
              )}
            </p>
          </div>
          <p>
            <span>{labels.SERIAL_NUMBER.toUpperCase()} : </span>
            <span>{isNull(batterydata?.[0]?.serial_number)}</span>
          </p>
        </section>
        <section className="detailDiagTabs">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k: any) => setKey(k)}
          >
            <Tab eventKey="POD" title="POD DETAILS">
              <div className="refreshCont">
                <ReloadData refreshData={refreshData} />
              </div>
              <div className="cmSummaryCont">
                <p>
                  <span>
                    {selPOD &&
                      selPOD?.length > 0 &&
                      !selPodData?.hasOwnProperty("statistics") &&
                      selPodData?.hasOwnProperty("connectionState") &&
                      selPodData?.connectionState?.toUpperCase() ===
                        "CONNECTED" && (
                        <Alert severity="warning">{labels.podWarningMsg}</Alert>
                      )}
                  </span>
                </p>
                <DiagApiCallCont
                  isLoading={podsLoading}
                  data={selPOD}
                  sectionName="Summary"
                  status={status}
                  handleRefresh={() => refreshData()}
                >
                  {selPOD !== undefined && selPOD?.length > 0 && (
                    <>
                      {selPodData?.hasOwnProperty("statistics") ? (
                        <CustomAccordion
                          accTitle={labels.STATISTICS.toUpperCase()}
                          accBodyElement={
                            <table className="dataTableDetails">
                              <thead>
                                <th id="nameCol">
                                  {labels.NAME.toUpperCase()}
                                </th>
                                <th id="valueCol">
                                  {labels.VALUE.toUpperCase()}
                                </th>
                              </thead>
                              <tbody>
                                {Object?.keys(selPodData?.statistics)?.map(
                                  (key: any, i: number) => {
                                    return (
                                      <tr>
                                        <td>{key.toUpperCase() === "RSSI" ?"CELL RSSI":key.toUpperCase() }</td>
                                        {key.toUpperCase() === "RSSI" ? (
                                          <td>
                                            <CustomPopOver
                                              popOverData={checkRSSIValue(
                                                isNull(
                                                  selPodData?.statistics?.rssi
                                                )?.toUpperCase()
                                              )}
                                              originComp={getRSSIValue(
                                                isNull(
                                                  selPodData?.statistics?.rssi
                                                )?.toUpperCase()
                                              )}
                                              index="popOver"
                                            />
                                          </td>
                                        ) : (
                                          <>
                                            <td>
                                              {isNull(
                                                isBoolValue(
                                                  selPodData?.statistics[key]
                                                )
                                              )}
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          }
                        />
                      ) : (
                        <></>
                      )}
                        {(selPodData?.hasOwnProperty("cellulardata") ? (
                      <CustomAccordion
                        accTitle={"CELLULAR".toUpperCase()}
                        accBodyElement={
                          <table className="dataTableDetails">
                            <thead>
                              <th id="nameCol">{labels.NAME.toUpperCase()}</th>
                              <th id="valueCol">
                                {labels.VALUE.toUpperCase()}
                              </th>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{"last_signal_report_cpe_timestamp".toUpperCase()}</td>
                                <td>{isNull(selPodData?.cellulardata?.last_signal_report_cpe_timestamp)}</td>
                              </tr>
                              <tr>
                                <td>{"last_signal_report_process_timestamp".toUpperCase()}</td>
                                <td>{isNull(selPodData?.cellulardata?.last_signal_report_process_timestamp)}</td>
                              </tr>
                              <tr>
                                <td>{"rsrq".toUpperCase()}</td>
                                <td>
                                  {isNull(
                                    selPodData?.cellulardata?.rsrq)}
                                </td>
                              </tr>
                              <tr>
                                <td>{"snr".toUpperCase()}</td>
                                <td>{isNull(selPodData?.cellulardata?.snr)}</td>
                              </tr>
                              <tr>
                                <td>{labels.CONNECTION_STATUS.toUpperCase()}</td>
                                <td>{isNull(selPodData?.cellulardata?.connection_status)}</td>
                              </tr>
                              <tr>
                                <td>{"rsrp".toUpperCase()}</td>
                                <td>{isNull(selPodData?.cellulardata?.rsrp)}</td>
                              </tr>
                              <tr>
                                <td>
                                  {"signal_strength".toUpperCase()}
                                </td>
                                <td>
                                  {isNull(
                                    selPodData?.cellulardata?.signal_strength
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {"enabled".toUpperCase()}
                                </td>
                                <td>
                                  {(
                                     selPodData?.cellulardata?.enabled?.toString()
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        }
                      />
                    ):(
                        <ExceptionBox
                        headerText="No Data to display in Pod Details."
                        bodyText="Please try refreshing."
                        handleRefresh={refreshData}
                      />
                    ))}
                    </>
                  )}
                </DiagApiCallCont>
                {selPOD === undefined ? (
                  <div className="diagErrorCont">
                    <ExceptionBox
                      headerText="No Data to display in Pod Details."
                      bodyText="Please try refreshing."
                      handleRefresh={refreshData}
                    />
                  </div>
                ) : (
                  selPOD !== undefined &&
                  selPOD?.length === 0 && (
                    <ExceptionBox
                      headerText="No Data to display in Pod Details."
                      handleRefresh={refreshData}
                    />
                  )
                )}
             
              <DiagApiCallCont
                  isLoading={batteryLoading}
                  data={batterydata}
                  sectionName="Battery"
                  status={batteryStatus}
                //   handleRefresh={() => refreshData()}
                >
                  {batterydata !== undefined && batterydata?.[0] ? (
                    <>
                     
                      <CustomAccordion
                        accTitle={labels.Battery.toUpperCase()}
                        accBodyElement={
                          <table className="dataTableDetails">
                            <thead>
                              <th id="nameCol">{labels.NAME.toUpperCase()}</th>
                              <th id="valueCol">
                                {labels.VALUE.toUpperCase()}
                              </th>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{"charge remaining".toUpperCase()}</td>
                                <td>{isNull(batterydata?.[0]?.charge_remaining)}</td>
                              </tr>
                              <tr>
                                <td>{"minutes remaining".toUpperCase()}</td>
                                <td>{isNull(batterydata?.[0]?.minutes_remaining)}</td>
                              </tr>
                              <tr>
                                <td>{"charging".toUpperCase()}</td>
                                <td>
                                  {(
                                    batterydata?.[0]?.charging?.toString())}
                                </td>
                              </tr>
                              <tr>
                                <td>{"health".toUpperCase()}</td>
                                <td>{isNull(batterydata?.[0]?.health)}</td>
                              </tr>
                              <tr>
                                <td>{"serial_number".toUpperCase()}</td>
                                <td>{isNull(batterydata?.[0]?.serial_number)}</td>
                              </tr>
                              <tr>
                                <td>{"current_usage_seconds".toUpperCase()}</td>
                                <td>{isNull(batterydata?.[0]?.current_usage_seconds)}</td>
                              </tr>
                              <tr>
                                <td>
                                  {"battery_capacity".toUpperCase()}
                                </td>
                                <td>
                                  {isNull(
                                    batterydata?.[0]?.battery_capacity)}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {"last_battery_report_process_timestamp".toUpperCase()}
                                </td>
                                <td>
                                  {isNull(
                                    batterydata?.[0]?.last_battery_report_process_timestamp)}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {"last_battery_report_cpe_timestamp".toUpperCase()}
                                </td>
                                <td>
                                  {isNull(
                                    batterydata?.[0]?.last_battery_report_cpe_timestamp)}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {"status".toUpperCase()}
                                </td>
                                <td>
                                  {isNull(
                                    batterydata?.[0]?.status)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        }
                      />
                    </>
                  
                ) : (
                    <></>
                  )}
                </DiagApiCallCont>
                </div>
            </Tab>
          </Tabs>
        </section>
      </div>
    </>


  );
};

export default XLEPodDiagnostics;
