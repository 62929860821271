import { ServiceAreaInfo } from "../../../models/account/accountInfo/ServiceAreaInfo";

export const SERVICE_AREA_BEGIN = "SERVICE_AREA_BEGIN";
export const SERVICE_AREA_SUCCESS = "SERVICE_AREA_SUCCESS";
export const SERVICE_AREA_FAILURE = "SERVICE_AREA_FAILURE";

interface ServiceAreaInfoBegin {
  type: typeof SERVICE_AREA_BEGIN;
}

interface ServiceAreaInfoSuccess {
  type: typeof SERVICE_AREA_SUCCESS;
  payload: ServiceAreaInfo;
}

interface ServiceAreaInfoFailure {
  type: typeof SERVICE_AREA_FAILURE;
  payload: Error;
}

export type ServiceAreaInfoActionTypes =
  | ServiceAreaInfoBegin
  | ServiceAreaInfoSuccess
  | ServiceAreaInfoFailure;
