import { Card } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ODUClient from "../../network/httpclient/odu/ODUClient";
import DiagContainer from "../reusable/DiagContainer";
import { isNull } from "../reusable/Util";
import ReloadData from "../reusable/ReloadData";
import ExceptionBox from "../reusable/ExceptionBox";

const ODUNetworkInterfaces = (props: any) => {
  const { selectedDevice } = props;
  const dispatch = useDispatch();
  const { oduNILoading, oduNInterfaces, oduNIError } = useSelector(
    (state: any) => ({
      oduNILoading: state.AccountState.ODUNInterfaceInfo.isLoading,
      oduNInterfaces: state.AccountState.ODUNInterfaceInfo.data,
      oduNIError: state.AccountState.ODUNInterfaceInfo.error,
    }),
    shallowEqual
  );
  const refreshData = () => {
    dispatch(
      ODUClient.getODUNetworkInterfaces(
        selectedDevice.oui,
        selectedDevice.serialNumber
      )
    );
  };
  return (
    <>
      <div className="refreshCont">
        <ReloadData refreshData={refreshData} />
      </div>
      <DiagContainer
        isLoading={oduNILoading}
        data={oduNInterfaces}
        error={oduNIError}
        handleRefresh={() => refreshData()}
      >
        {oduNInterfaces !== undefined ? (
          <div className="gwDevicesCont">
            <Card className="dataCard">
              <Card.Body>
                <table className="dataTableDetails">
                  {oduNInterfaces !== undefined &&
                  oduNInterfaces?.length !== 0 ? (
                    <>
                      <thead>
                        <th>NAME</th>
                        <th>ALIAS</th>
                        <th>STATUS</th>
                        <th>ENABLE</th>
                        <th>CURRENT BIT RATE</th>
                        <th>ATTACHED MAC</th>
                      </thead>
                      <tbody>
                        {oduNInterfaces?.map((ni: any, i: number) => {
                          return (
                            <tr>
                              <td>{isNull(ni.name)}</td>
                              <td>{isNull(ni.alias)}</td>
                              <td>{isNull(ni.status)}</td>
                              <td>{isNull(ni.enable)}</td>
                              <td>{isNull(ni.currentBitRate)}</td>
                              <td>{isNull(ni.attachedMac)}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </>
                  ) : (
                    <div className="diagErrorCont">
                      <ExceptionBox
                        exBoxType="error"
                        errSecName="ODU Network Interfaces"
                        errCode={isNull(oduNIError?.errorCode)}
                        srcSystem={
                          oduNIError?.hasOwnProperty("sourceSystem")
                            ? oduNIError?.sourceSystem
                            : "Request"
                        }
                        ErrorMSG={isNull(oduNIError?.errorMessage)}
                        primaryBtn={true}
                        secBtn={true}
                        handleRefresh={() => refreshData()}
                      />
                    </div>
                  )}
                </table>
              </Card.Body>
            </Card>
          </div>
        ) : (
          <></>
        )}
      </DiagContainer>
    </>
  );
};

export default ODUNetworkInterfaces;
