import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const CustomPopOver = (props: any) => {
  const { popOverData, originComp, index, position } = props;
  return (
    <>
      <OverlayTrigger
        placement={position ? position : "top"}
        overlay={
          <Tooltip
            id={`tooltip-${index}`}
            style={{
              zIndex: 100100,
            }}
          >
            {popOverData}
          </Tooltip>
        }
      >
        {originComp}
      </OverlayTrigger>
    </>
  );
};

export default CustomPopOver;
