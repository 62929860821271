import { useEffect, useState } from "react";
import "../../design-tokens/rpd-page/RPDPage.scss";
import VCCAPSWupgradeTop from "../../components/rpd/vccapSWupgrade/VCCAPSWupgradeTop";
import VCCAPSWupgradeDetails from "../../components/rpd/vccapSWupgrade/VCCAPSWupgradeDetails";
import { GetVccapSWUpgradeInfo } from "../../get-api/GetResponse";
import LoaderGif from "../../resources/images/loader.webp";
import ExceptionBox from "../../components/reusable/ExceptionBox";
import AppLabels from "../../constants/App_Labels";
import { GetTechInfo } from "../../get-api/GetResponse";
import OrderSuccessBox from "../../components/rpd/OrderSuccessBox";
import OrderSuccessPopUp from "../../components/rpd/OrderSuccessPopUp";
import ErrorBox from "../../components/reusable/ErrorBox";

const VccapSWupgradeTab = () => {
  const rpdSwapInfo = GetVccapSWUpgradeInfo();
  const techData = GetTechInfo()?.data;
  const [showConfirm, setShowConfirm] = useState(false);
  const labels = AppLabels();

  const handleConfirm = () => {
    setShowConfirm(false);
  };

  useEffect(() => {
    if (rpdSwapInfo?.data !== undefined)
      if (rpdSwapInfo?.error === undefined) setShowConfirm(true);

    if (rpdSwapInfo?.data === undefined)
      if (rpdSwapInfo?.error !== undefined) setShowConfirm(true);
  }, [rpdSwapInfo?.data]);

  return (
    <>
      <div>
        <VCCAPSWupgradeTop
          oldMacAdd={rpdSwapInfo?.macAddress}
          techData={techData}
          isLoading={rpdSwapInfo?.isLoading}
        />
      </div>
      <div className="historyResultCont">
        {rpdSwapInfo.isLoading ? (
          <div className="loader">
            <img style={{ height: "60px" }} src={LoaderGif} />
          </div>
        ) : rpdSwapInfo?.data === undefined &&
          rpdSwapInfo?.error !== undefined ? (
          <div className="diagErrorCont">
            <ErrorBox
              open={showConfirm}
              error={labels.rpdVccapSWUpgFail}
              setOpen={setShowConfirm}
              handleClose={handleConfirm}
            />
            <ExceptionBox
              exBoxType="error"
              headerText={labels.rpdVccapSWUpgFail}
              errCode={rpdSwapInfo?.error.errorCode}
              ErrorMSG={rpdSwapInfo?.error.errorMessage}
              srcSystem={rpdSwapInfo?.error.sourceSystem}
              bodyText={rpdSwapInfo?.error.responseFromSource}
              primaryBtn={false}
              secBtn={false}
            />
          </div>
        ) : rpdSwapInfo?.data !== undefined &&
          rpdSwapInfo?.error === undefined ? (
          <div className="diagErrorCont">
            <ErrorBox
              open={showConfirm}
              error={
                labels.rpdVccapSWUpgSuccess +
                " " +
                labels.order_id +
                rpdSwapInfo?.data.orderid
              }
              setOpen={setShowConfirm}
              handleClose={handleConfirm}
            />
            {/* <OrderSuccessPopUp
              showVal={showConfirm}
              headerText="Order Status"
              bodyHeadText="Order Id: 654321"
              bodyText={labels.rpdVccapSWUpgSuccess}
              confirmText="OK"
              handleConfirm={handleConfirm}
              handleClose={() => setShowConfirm(false)}
              popUpType="pass"
              orderId="654321"
            /> */}
            {/* <ExceptionBox
              headerText={labels.rpdVccapSWUpgSuccess}
              bodyText={labels.orderId + rpdSwapInfo?.data.orderid}
              primaryBtn={false}
              secBtn={false}
            /> */}
            <OrderSuccessBox
              headerText={labels.rpdVccapSWUpgSuccess}
              bodyText={labels.orderStatusCheck + rpdSwapInfo?.data.orderid}
              primaryBtn={false}
              secBtn={false}
              orderid={rpdSwapInfo?.data.orderid}
              rpdOrderInfo={rpdSwapInfo}
            />
          </div>
        ) : (
          // <OrderSuccessPopUp
          //   showVal={showConfirm}
          //   headerText="Order Status"
          //   bodyHeadText="Order Id: 654321"
          //   bodyText={labels.rpdVccapSWUpgSuccess}
          //   confirmText="OK"
          //   handleConfirm={handleConfirm}
          //   handleClose={() => setShowConfirm(false)}
          //   popUpType="pass"
          //   orderId="654321"
          // />
          <></>
        )}
      </div>
    </>
  );
};
export default VccapSWupgradeTab;
