import { useEffect } from "react";
import { useDispatch } from "react-redux";
import appConst from "../../constants/appConst";
import { getPngImages } from "../../store/slices/symbols";
import {
  GetMduActivesProps,
  GetMduCADCircleProps,
  GetMduCADSymbolProps,
  GetMduFiberEquipmentsProps,
  GetMduPassivesProps,
  GetMduPowerSupplyProps,
  GetMduSupportStructureProps,
  GetMduTapProps,
  GetSymbolsProps,
} from "../reduxFunctions/getTopologyState";
import { getDataForSymbolRequest } from "../utilFuncs";
import { mduBlockedSymbols } from "../../config/mapConfig";

export const useMduSymbolsRequestHandler = () => {
  const { pngImages } = GetSymbolsProps();
  const { mduPassivesIsLoading, mduPassivesdata, mduPassivesStatus } =
    GetMduPassivesProps();
  const { mduActiveIsLoading, mduActivesdata, mduActiveStatus } =
    GetMduActivesProps();
  const { mduTapIsLoading, mduTapdata, mduTapStatus } = GetMduTapProps();
  const {
    mduFiberEquipmentsIsLoading,
    mduFiberEquipmentsdata,
    mduFiberEquipmentsStatus,
  } = GetMduFiberEquipmentsProps();
  const { mduPowerSupplyIsLoading, mduPowerSupplydata, mduPowerSupplyStatus } =
    GetMduPowerSupplyProps();
  const {
    mduSupportStructureIsLoading,
    mduSupportStructuredata,
    mduSupportStructureStatus,
  } = GetMduSupportStructureProps();
  const { mduCADSymbolIsLoading, mduCADSymbolData, mduCADSymbolStatus } =
    GetMduCADSymbolProps();
  const { mduCADCircleIsLoading, mduCADCircleData, mduCADCircleStatus } =
    GetMduCADCircleProps();
  const dispatch = useDispatch();

  // symbol handling for MDU Passives
  useEffect(() => {
    if (mduPassivesIsLoading === false && mduPassivesStatus === appConst.OK) {
      const requestData = getDataForSymbolRequest(
        pngImages,
        appConst.mduPassive,
        mduPassivesdata?.features
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.mduPassive));
      }
    }
  }, [mduPassivesdata]);

  // symbol handling for MDU Actives
  useEffect(() => {
    if (mduActiveIsLoading === false && mduActiveStatus === appConst.OK) {
      const requestData = getDataForSymbolRequest(
        pngImages,
        appConst.mduActive,
        mduActivesdata?.features
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.mduActive));
      }
    }
  }, [mduActivesdata]);

  // symbol handling for MDU Taps
  useEffect(() => {
    if (mduTapIsLoading === false && mduTapStatus === appConst.OK) {
      const requestData = getDataForSymbolRequest(
        pngImages,
        appConst.mduTap,
        mduTapdata?.features
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.mduTap));
      }
    }
  }, [mduTapdata]);

  // symbol handling for MDU Fiber Equipments
  useEffect(() => {
    if (
      mduFiberEquipmentsIsLoading === false &&
      mduFiberEquipmentsStatus === appConst.OK
    ) {
      const requestData = getDataForSymbolRequest(
        pngImages,
        appConst.mduFiberEquipment,
        mduFiberEquipmentsdata?.features
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.mduFiberEquipment));
      }
    }
  }, [mduFiberEquipmentsdata]);

  // symbol handling for MDU Power Supply
  useEffect(() => {
    if (
      mduPowerSupplyIsLoading === false &&
      mduPowerSupplyStatus === appConst.OK
    ) {
      const requestData = getDataForSymbolRequest(
        pngImages,
        appConst.mduPowerSupply,
        mduPowerSupplydata?.features
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.mduPowerSupply));
      }
    }
  }, [mduPowerSupplydata]);

  // symbol handling for MDU Support Structure
  useEffect(() => {
    if (
      mduSupportStructureIsLoading === false &&
      mduSupportStructureStatus === appConst.OK
    ) {
      const tempFeatures: any = [];
      mduSupportStructuredata?.features?.map((e: any, index: any) => {
        if (e?.properties?.type === appConst.mduStrandPoint) {
          tempFeatures?.push(e);
        }
      });
      const requestData = getDataForSymbolRequest(
        pngImages,
        appConst.mduStrandPoint,
        tempFeatures
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.mduStrandPoint));
      }
    }
  }, [mduSupportStructuredata]);

  // symbol handling for MDU CAD Symbols
  useEffect(() => {
    if (mduCADSymbolIsLoading === false && mduCADSymbolStatus === appConst.OK) {
      const requestData = getDataForSymbolRequest(
        pngImages,
        appConst.mduCADSymbol,
        mduCADSymbolData?.features
      );
      if (requestData?.length > 0) {
        const filteredData = requestData.filter((cadSymbolData: any) => {
          return !mduBlockedSymbols?.includes(String(cadSymbolData?.name));
        });
        dispatch(getPngImages(filteredData, appConst.mduCADSymbol));
      }
    }
  }, [mduCADSymbolData]);

  // symbol handling for MDU CAD Circle
  useEffect(() => {
    if (mduCADCircleIsLoading === false && mduCADCircleStatus === appConst.OK) {
      const requestData = getDataForSymbolRequest(
        pngImages,
        appConst.mduCADCircle,
        mduCADCircleData?.features
      );
      if (requestData?.length > 0) {
        dispatch(getPngImages(requestData, appConst.mduCADCircle));
      }
    }
  }, [mduCADCircleData]);
};
