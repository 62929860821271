import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import AccDetailsClient from "../../network/httpclient/accountInfo/AccDetailsClient";
import FullPageLoader from "../reusable/FullPageLoader";
import SearchBoxMini from "./SearchBoxMini";
import { formatCamelCase, getAccessLevelByRoles } from "../reusable/Util";
import ExceptionBox from "../reusable/ExceptionBox";
import SearchPopUp from "./SearchPopUp";
import { ResetSearchAccState } from "../../store/actions/account/accountInfo/AccountDetailsAction";
import { getWorkOrderDetails } from "../../store/slices/workOrder/workOrderDetails";
import { GetWorkOrdersList, GetTechInfo } from "../../get-api/GetResponse";
import WorkOrdersClient from "../../network/httpclient/workorders/WorkOrdersClient";
import ConfigConst from "../../constants/ConfigConst";

const SearchByUrl = (props: any) => {
  const {
    location: { search },
  } = props;
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const query = new URLSearchParams(search);
  const [accSearchValue, setAccSearchValue] = useState<string | null>("");
  const [accSearchType, setAccSearchType] = useState("");
  const [searchHistory, setSearchHistory] = useState([]);
  const [workOrderID, setWorkOrderID] = useState<string | null>("");
  const [deviceKey, setDeviceKey] = useState<string | null>();
  const { data: woListData, isLoading: woListLoading, workOrderDetails, woDetailsLoading } = GetWorkOrdersList();
  const techData = GetTechInfo()?.data;

  const [wHTFlag, setWHTFlag] = useState(false);
  useEffect(() => {
    if (search && search.includes("accountNumber")) {
      setAccSearchType("accountNumber");
      setAccSearchValue(query.get("accountNumber"));
    } else if (search && search.includes("cbpId")) {
      setAccSearchType("cbpId");
      setAccSearchValue(query.get("cbpId"));
    } else if (search && search.includes("macAddress")) {
      setAccSearchType("macAddress");
      setAccSearchValue(query.get("macAddress"));
    } else if (search && search.includes("tn")) {
      setAccSearchType("tn");
      setAccSearchValue(query.get("tn"));
    } else if (search && search.includes("ontSerialNumber")) {
      setAccSearchType("ontSerialNumber");
      setAccSearchValue(query.get("ontSerialNumber"));
    }
    if (search && search.includes("workorderId")) {
      setWorkOrderID(query.get("workorderId"));
    }
    if (search && search.includes("deviceKey")) {
      setDeviceKey(query.get("deviceKey"));
    }
  }, [query]);

  useEffect(() => {
    if (accSearchType !== "" && accSearchValue !== null) {
      dispatch(AccDetailsClient.getAccDetails(accSearchValue, accSearchType));
    }
  }, [accSearchValue]);

  useEffect(() => {
    if (workOrderID !== "") {
      if (workOrderDetails === undefined && woDetailsLoading === false) {
        dispatch(getWorkOrderDetails(workOrderID));
      }
      // For techs coming from Click link, pull WO List data
      if (woListData === undefined && woListLoading === false && techData?.techId !== undefined) {
        dispatch(
          WorkOrdersClient.getTechWorkOrders(
            techData?.techId,
            techData?.name,
            getAccessLevelByRoles(techData?.roles).includes(ConfigConst.MT)
          )
        );
      }
    }
  }, [workOrderID, techData?.techId, woListLoading, woDetailsLoading]);

  useEffect(() => {
    let suggestions: any = localStorage.getItem("searchHistory");
    if (suggestions === null) {
      localStorage.setItem("searchHistory", JSON.stringify([]));
    } else {
      suggestions = JSON.parse(suggestions);
      setSearchHistory(suggestions);
    }
    if (accSearchValue === null || accSearchValue === "") {
      setModalShow(true);
    }
  }, []);
  const handleSearchClose = () => {
    setModalShow(false);
    dispatch(ResetSearchAccState());
  };
  const handleWHTChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWHTFlag(event.target.checked);
  };
  return (
    <div className="searchContainer">
      {accSearchValue === null || accSearchValue === "" ? (
        <>
          <div className="diagErrorCont">
            <ExceptionBox
              headerText="Account data is not available."
              bodyText="Once you search an account it will reflect here."
              primaryBtn={false}
              secBtn={false}
            />
          </div>
          <SearchPopUp show={modalShow} onHide={handleSearchClose} />
        </>
      ) : (
        <SearchBoxMini
          selectedValue={formatCamelCase(accSearchType)}
          searchValue={accSearchValue}
          deviceKey={deviceKey}
          workOrderID={workOrderID}
          searchHistory={searchHistory}
          handleWHTChecked={handleWHTChecked}
          wHTFlag={wHTFlag}
        />
      )}
      <FullPageLoader />
    </div>
  );
};
export default SearchByUrl;
