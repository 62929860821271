import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { formatMacAddress } from "../../../components/reusable/Util";
import {
    AccountDetailsBegin,
    AccountDetailsFailure,
    AccountDetailsSuccess
} from "../../../store/actions/account/accountInfo/AccountDetailsAction";
import { AccountDetails } from "../../../store/models/account/accountInfo/AccountDetails";
import { HttpClient } from "../HttpClient";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import { NetworkConfig } from "../NetworkConfig";


export const AcDetailsClient = () => {
  const dispatch = useDispatch();
  const getAccInfo = useCallback(
    async (searchValue: string,searchType: string) => {
                const qParam = [];
                switch (searchType) {
                  case "cbpId":
                    qParam.push({ cbpId: searchValue });
                    break;
                  case "accountNumber":
                    qParam.push({ accountNumber: searchValue });
                    break;
                  case "macAddress":
                    let sV = formatMacAddress(searchValue);
                    qParam.push({ macAddress: sV });
                    break;
                  case "tn":
                    qParam.push({ tn: searchValue });
                    break;
                  case "ontSerialNumber":
                    qParam.push({ ontSerialNumber: searchValue });
                    break;
                  default:
                    qParam.push({ cbpId: searchValue });
                    break;
                }
                    let httpParameters: IHttpClientRequestParameters<any> = {
                        url: NetworkConfig.ACCOUNT_SERVICE_URL + "subscriptions",
                        params: qParam?.[0],
                      };
      dispatch(AccountDetailsBegin());

      const result = await HttpClient.get<AccountDetails>(httpParameters)
        .then((AccountDetails) => {
          dispatch(AccountDetailsSuccess(AccountDetails));
          return AccountDetails;
        })
        .catch((error) => {
          dispatch(AccountDetailsFailure(error));
          return error;
        });
      return result;
    },
    [dispatch]
  );

  return {
    getAccInfo,
  };
};
